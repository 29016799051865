// import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
// import { DialogActions, DialogContent, Paper } from "@mui/material";
// import {
//   forwardRef,
//   useContext,
//   useEffect,
//   useImperativeHandle,
//   useState,
// } from "react";

// import { Input } from "@/components/ui/input";
// import { Label } from "@/components/ui/label";
// import { cn } from "@/lib/utils";
// import { useDispatch, useSelector } from "react-redux";
// import { CustomAlertContext } from "../../../App";
// import {
//   TRAINING_TYPES,
//   getTrainingType,
// } from "../../../common/trainingTypeHelper";
// import AddExerciseModal from "../../../pages/Exercises/AddExerciseModal";
// import { modifyExerciseObject } from "../../../redux/dataSlice";
// import { SpurfitCircularProgress } from "../../Dashboard/dashboard";
// import VideoPlayerModel from "../../VideoPlayer/VideoPlayer";
// import CheckBox, {
//   CheckBoxLabel,
//   RenderProperty,
//   TimeInput,
// } from "../AddProperitesHelpers";
// import EmptyViewAddProerties from "../EmptyViewAddProerties";
// import AddIntensity from "./AddIntensityDialog";
// import { EnduranceContext } from "./EnduranceBuilder";
// import CustomSelect from "./util/CustomSelect";
// import PropertyHeader from "./util/PropertyHeader";
// import Targets from "./util/Targets";

// const AddEnduranceProperties = forwardRef(
//   (
//     {
//       exercise,
//       updateExerciseProperty,
//       roundIndex,
//       index,
//       rounds,
//       incrementRound,
//       decrementRound,
//       selectedExerciseInfo,
//       updateExerciseToParticularRound,
//       isEnduranceWorkout,
//       setPoolLengthInAllRounds,
//       workoutKind,
//       ...props
//     },
//     ref
//   ) => {
//     const isRun = workoutKind === "run";
//     const isSwim = workoutKind === "swim";
//     const isBike = workoutKind === "bike";

//     const [showUpdateExerciseModal, setShowUpdateExerciseModal] =
//       useState(false);
//     const [videoInfo, setVideoInfo] = useState(null);
//     const [selectedItem, setSelectedItem] = useState();
//     const [isLoading, setIsLoading] = useState(false);
//     const [roundIdx, setRoundIdx] = useState(0);
//     const [idx, setIdx] = useState(0);
//     const [format, setFormat] = useState("");

//     // reps
//     //TODO - need to handle for EnduranceContext
//     const workoutContext = useContext(EnduranceContext);
//     const [reps, setReps] = useState(exercise?.reps || {});
//     const [repsMax, setRepsMax] = useState(exercise?.repsMax || {});
//     const [repsF, setRepsF] = useState(exercise?.repsF || {});
//     const [repsInputType, setRepsInputType] = useState(
//       exercise?.repsMax ? "range" : "perset"
//     );

//     // weight
//     const [weight, setWeight] = useState(exercise?.weight || {});
//     const [weightMax, setWeightMax] = useState(exercise?.weightMax || {});
//     const [weightF, setWeightF] = useState(exercise?.weightF || {});
//     const [weightInputType, setWeightInputType] = useState(
//       exercise?.weightMax ? "range" : "perset"
//     );
//     const [weightUnits, setWeightUnits] = useState(
//       exercise?.weightUnits || "Pounds"
//     );

//     // height
//     const [height, setHeight] = useState(exercise?.height || {});
//     const [heightMax, setHeightMax] = useState(exercise?.heightMax || {});
//     const [heightF, setHeightF] = useState(exercise?.heightF || {});
//     const [heightInputType, setHeightInputType] = useState(
//       exercise?.heightMax ? "range" : "perset"
//     );
//     const [heightUnits, setHeightUnits] = useState(
//       exercise?.heightUnits || "Inches"
//     );

//     // distance
//     const [distance, setDistance] = useState(
//       exercise?.distance || isSwim ? { 1: 25 } : {}
//     );
//     const [distanceMax, setDistanceMax] = useState(exercise?.distanceMax || {});
//     const [distanceF, setDistanceF] = useState(exercise?.distanceF || {});
//     const [distanceInputType, setDistanceInputType] = useState(
//       exercise?.distanceMax ? "range" : "perset"
//     );
//     const [distanceUnits, setDistanceUnits] = useState(
//       isSwim ? "Yards" : exercise?.distanceUnits || "Kilometers"
//     );

//     // calorie
//     const [calorie, setCalorie] = useState(exercise?.calorie || {});
//     const [calorieMax, setCalorieMax] = useState(exercise?.calorieMax || {});
//     const [calorieF, setCalorieF] = useState(exercise?.calorieF || {});
//     const [calorieInputType, setCalorieInputType] = useState(
//       exercise?.calorieMax ? "range" : "fixed"
//     );

//     // heart rate
//     const [heartRate, setHeartRate] = useState(exercise?.heartRate || {});
//     const [heartRateMax, setHeartRateMax] = useState(
//       exercise?.heartRateMax || {}
//     );
//     const [heartRateF, setHeartRateF] = useState(exercise?.heartRateF || {});
//     const [heartRateInputType, setHeartRateInputType] = useState(
//       exercise?.heartRateMax ? "range" : "fixed"
//     );
//     const [heartRateType, setHeartRateType] = useState("above");

//     // pace
//     const [pace, setPace] = useState(exercise?.pace || {});
//     const [paceMax, setPaceMax] = useState(exercise?.paceMax || {});
//     const [paceUnit, setPaceUnit] = useState(exercise?.paceUnit || "min/mi");
//     const [paceInputType, setPaceInputType] = useState("range");

//     // duration
//     const [duration, setDuration] = useState(exercise?.duration || {});
//     const [durationMax, setDurationMax] = useState(exercise?.durationMax || {});
//     const [durationF, setDurationF] = useState(exercise?.durationF || {});
//     const [durationInputType, setDurationInputType] = useState(
//       exercise?.durationMax ? "range" : "fixed"
//     );

//     const [intensity, setIntensity] = useState(exercise?.intensity || {});
//     const [strokeType, setStrokeType] = useState(exercise?.strokeType);
//     const [equipment, setEquipment] = useState(exercise?.equipment);

//     const [power, setPower] = useState(exercise?.power || {});
//     const [powerType, setPowerType] = useState(exercise?.powerType || "above");

//     const [poolLength, setPoolLength] = useState(props?.poolLength || 25);

//     const [parimaryTarget, setPrimaryTarget] = useState(
//       exercise?.primaryTarget
//     );
//     const [secondaryTarget, setSecondaryTarget] = useState(
//       exercise?.secondaryTarget
//     );

//     const [swimLap, setSwimLap] = useState(exercise?.swimLap || 1);
//     const [lapButtonPress, setLapButtonPress] = useState(
//       exercise?.lapButtonPress ?? false
//     );

//     // record from user
//     const [recordFromUser, setRecordFromUser] = useState({
//       reps: false,
//       weight: false,
//       height: false,
//       distance: false,
//       calorie: false,
//       heartRate: false,
//       duration: false,
//       swimLap: false,
//       // time: false,
//     });

//     // notes
//     const [notes, setNotes] = useState(exercise?.movementInstr || "");

//     const [seconds, setSeconds] = useState(exercise?.duration / 60 || 0);
//     const [minutes, setMinutes] = useState(exercise?.duration % 60 || 0);

//     const [showProperties, setShowProperties] = useState({
//       weight: false,
//       height: false,
//       distance: isSwim ? true : false,
//       calorie: false,
//       heartRate: false,
//       duration: false,
//       pace: false,
//       intensity: false,
//       strokeType: false,
//       equipment: false,
//       primaryTarget: false,
//       secondaryTarget: false,
//       swimLap: false,
//       lapButtonPress: false,
//       // time: false,
//     });

//     const [disabledProperties, setDisabledProperties] = useState({});
//     const [value, setValue] = useState("");
//     const { displayAlert } = useContext(CustomAlertContext);

//     const [showVideoDialog, setShowVideoDialog] = useState(false);
//     const exercisesObj = useSelector((state) => state.data.exerciseObject);
//     const dispatch = useDispatch();

//     const options = isRun
//       ? [
//           { label: "Distance", value: "distance" },
//           { label: "Duration", value: "duration" },
//           { label: "Heart rate", value: "heartRate" },
//           { label: "Calories", value: "calorie" },
//           { label: "Pace", value: "pace" },
//           { label: "Intensity", value: "intensity" },
//           { label: "Lap Button Press", value: "lapButtonPress" },
//         ]
//       : isSwim
//       ? [
//           { label: "Pool Length", value: "poolLength" },
//           // { label: "Distance", value: "distance" },
//           { label: "Laps", value: "swimLap" },
//           { label: "Duration", value: "duration" },
//           { label: "Stroke Type", value: "strokeType" },
//           { label: "Equipment", value: "equipment" },
//         ]
//       : [
//           { label: "Duration", value: "duration" },
//           { label: "Distance", value: "distance" },
//           { label: "Calories", value: "calorie" },
//           { label: "Lap Button Press", value: "lapButtonPress" },
//           { label: "Heart Rate", value: "heartRate" },
//           { label: "Power", value: "power" },
//           { label: "Primary Target", value: "primaryTarget" },
//           { label: "Secondary Target", value: "secondaryTarget" },
//           { label: "Lap button press", value: "lapButtonPress" },
//         ];

//     const enabled = isRun
//       ? ["Distance", "Duration", "Intensity", "Lap Button Press"]
//       : isSwim
//       ? ["Pool Length", "Swim Lap", "Duration", "Stroke Type", "Equipment"]
//       : [
//           "Duration",
//           "Distance",
//           // "Calorie",
//           // "Heart rate",
//           "Lap Button Press",
//           "Power",
//           "Primary Target",
//           "Secondary Target",
//           "Lap Button Press",
//         ];

//     const stateMap = {
//       reps: [reps, setReps],
//       repsMax: [repsMax, setRepsMax],
//       repsF: [repsF, setRepsF],
//       weight: [weight, setWeight],
//       weightMax: [weightMax, setWeightMax],
//       weightF: [weightF, setWeightF],
//       height: [height, setHeight],
//       heightMax: [heightMax, setHeightMax],
//       heightF: [heightF, setHeightF],
//       distance: [distance, setDistance],
//       distanceMax: [distanceMax, setDistanceMax],
//       distanceF: [distanceF, setDistanceF],
//       calorie: [calorie, setCalorie],
//       calorieMax: [calorieMax, setCalorieMax],
//       calorieF: [calorieF, setCalorieF],
//       duration: [duration, setDuration],
//       heartRate: [heartRate, setHeartRate],
//       heartRateMax: [heartRateMax, setHeartRateMax],

//       pace: [pace, setPace],
//       paceMax: [paceMax, setPaceMax],

//       swimLap: [swimLap, setSwimLap],
//       // timeHr: [timeHr, setTimeHr],
//       // timeMin: [timeMin, setTimeMin],
//       // timeSec: [timeSec, setTimeSec],
//       // time: [time, setTime],
//       // timeMax: [timeMax, setTimeMax],
//       // timeF: [timeF, setTimeF],
//       durationMax: [durationMax, setDurationMax],
//       durationF: [durationF, setDurationF],
//       intensity: [intensity, setIntensity],
//       strokeType: [strokeType, setStrokeType],
//       equipment: [equipment, setEquipment],
//       poolLength: [poolLength, setPoolLength],
//       power: [power, setPower],
//       powerType: [powerType, setPowerType],
//     };

//     const checkEmptyEntries = (obj) => {
//       if (!obj || Object.entries(obj).length === 0) return true;
//       return Object.values(obj).every((x) => x === "" || x === undefined);
//     };

//     const updateExercisePropertyHelper = (
//       roundIndex,
//       index,
//       propertyName,
//       inputValues,
//       rounds,
//       inputType
//     ) => {
//       if (!checkEmptyEntries(inputValues)) {
//         const updatedProperty = getUpdatedProperty(inputValues, rounds);
//         if (inputType === "range" && !checkEmptyEntries(inputValues)) {
//           let maxStates = stateMap[`${propertyName}Max`];
//           let updatedMaxProperty = getUpdatedProperty(maxStates[0], rounds);
//           if (checkEmptyEntries(updatedMaxProperty)) {
//             displayAlert({
//               message: `Please min and max value for ${propertyName} range.`,
//               type: "error",
//             });
//             return;
//           }
//           updateExerciseProperty(
//             roundIndex,
//             index,
//             `${propertyName}Max`,
//             updatedMaxProperty
//           );
//           maxStates[1](updatedMaxProperty);
//         } else {
//           if (workoutContext?.removeOneProperty) {
//             workoutContext.removeOneProperty(
//               roundIndex,
//               index,
//               `${propertyName}Max`
//             );
//             stateMap[`${propertyName}Max`][1]({});
//           }
//         }
//         updateExerciseProperty(
//           roundIndex,
//           index,
//           propertyName,
//           updatedProperty
//         );
//         stateMap[propertyName][1](updatedProperty);
//       }
//     };
//     const onSave = (roundIdx, index) => {
//       if (
//         (distance?.[1] && distance[1] !== "") ||
//         (duration?.[1] && duration[1] !== "") ||
//         (power?.[1] && power[1] !== "")
//       ) {
//         // console.log("duration[1]",duration)
//         // console.log("distance", distance[1])
//         // console.log("power", power  )
//         updateExercisePropertyHelper(
//           roundIdx,
//           index,
//           "reps",
//           reps,
//           rounds,
//           repsInputType
//         );
//         updateExercisePropertyHelper(roundIdx, index, "repsF", repsF, rounds);
//         updateExercisePropertyHelper(
//           roundIdx,
//           index,
//           "weight",
//           weight,
//           rounds,
//           weightInputType
//         );
//         updateExercisePropertyHelper(
//           roundIdx,
//           index,
//           "weightF",
//           weightF,
//           rounds
//         );
//         updateExerciseProperty(roundIdx, index, "weightUnits", weightUnits);
//         updateExercisePropertyHelper(
//           roundIdx,
//           index,
//           "height",
//           height,
//           rounds,
//           heightInputType
//         );
//         updateExercisePropertyHelper(
//           roundIdx,
//           index,
//           "heightF",
//           heightF,
//           rounds
//         );
//         updateExerciseProperty(roundIdx, index, "heightUnits", heightUnits);
//         updateExercisePropertyHelper(
//           roundIdx,
//           index,
//           "calorie",
//           calorie,
//           rounds,
//           calorieInputType
//         );
//         updateExercisePropertyHelper(
//           roundIdx,
//           index,
//           "calorieF",
//           calorieF,
//           rounds
//         );
//         updateExercisePropertyHelper(
//           roundIdx,
//           index,
//           "distance",
//           distance,
//           rounds,
//           distanceInputType
//         );
//         updateExercisePropertyHelper(
//           roundIdx,
//           index,
//           "distanceF",
//           distanceF,
//           rounds
//         );
//         updateExercisePropertyHelper(
//           roundIdx,
//           index,
//           "heartRate",
//           heartRate,
//           rounds,
//           heartRateInputType
//         );

//         // updateExerciseProperty(roundIdx, index, "pace", pace);
//         // updateExerciseProperty(roundIdx, index, "paceMax", paceMax);

//         // updateExerciseProperty(roundIdx, index, "paceUnit", paceUnit);
//         updateExerciseProperty(roundIdx, index, "distanceUnits", distanceUnits);
//         updateExerciseProperty(roundIdx, index, "duration", duration);
//         updateExerciseProperty(roundIdx, index, "heartRateType", heartRateType);

//         updateExerciseProperty(roundIdx, index, "durationMax", durationMax);
//         updateExerciseProperty(roundIdx, index, "durationF", durationF);
//         // updateExerciseProperty(roundIdx, index, "durationUnits", durationUnits);
//         updateExerciseProperty(roundIdx, index, "rounds", rounds);
//         // updateExerciseProperty(roundIdx, index, "roundsF", roundsF);

//         updateExerciseProperty(
//           roundIdx,
//           index,
//           "recordFromUser",
//           recordFromUser
//         );
//         updateExerciseProperty(roundIdx, index, "movementInstr", notes);

//         updateExerciseProperty(roundIdx, index, "intensity", intensity);
//         updateExerciseProperty(roundIdx, index, "strokeType", strokeType);
//         updateExerciseProperty(roundIdx, index, "equipment", equipment);

//         updateExerciseProperty(roundIdx, index, "power", power);
//         // updateExerciseProperty(roundIdx, index, "power", power);
//         updateExerciseProperty(roundIdx, index, "powerType", powerType);
//         updateExerciseProperty(
//           roundIdx,
//           index,
//           "primaryTarget",
//           parimaryTarget
//         );
//         updateExerciseProperty(
//           roundIdx,
//           index,
//           "secondaryTarget",
//           secondaryTarget
//         );

//         updateExerciseProperty(
//           roundIdx,
//           index,
//           "lapButtonPress",
//           lapButtonPress
//         );
//         updateExerciseProperty(roundIdx, index, "swimLap", swimLap);
//         if (isSwim && props?.poolLength && swimLap) {
//           let distance = props?.poolLength * swimLap;
//           updateExerciseProperty(roundIdx, index, "distance", { 1: distance });
//         }

//         Object.entries(showProperties).map(([key, value]) => {
//           if (!value && workoutContext?.removeOneProperty) {
//             workoutContext.removeOneProperty(roundIdx, index, key);
//             if (stateMap?.[key]?.[1]) {
//               stateMap[key][1]({});
//             }
//           }
//         });
//       } else {
//         return;
//       }
//     };

//     useImperativeHandle(ref, () => ({
//       saveData() {
//         onSave(roundIdx, idx);
//       },
//     }));

//     const onRoundsChange = () => {
//       let updatedReps = getUpdatedProperty(reps, rounds);
//       let updatedRepsMax = getUpdatedProperty(repsMax, rounds);
//       let updatedRepsF = getUpdatedProperty(repsF, rounds);

//       let updatedHeight = getUpdatedProperty(height, rounds);
//       let updatedHeightMax = getUpdatedProperty(heightMax, rounds);
//       let updatedHeightF = getUpdatedProperty(heightF, rounds);

//       let updatedWeight = getUpdatedProperty(weight, rounds);
//       let updatedWeightMax = getUpdatedProperty(weightMax, rounds);
//       let updatedWeightF = getUpdatedProperty(weightF, rounds);

//       let updatedCalories = getUpdatedProperty(calorie, rounds);
//       let updatedCaloriesMax = getUpdatedProperty(calorieMax, rounds);
//       let updatedCaloriesF = getUpdatedProperty(calorieF, rounds);

//       let updatedDistance = getUpdatedProperty(distance, rounds);
//       let updatedDistanceMax = getUpdatedProperty(distanceMax, rounds);
//       let updatedDistanceF = getUpdatedProperty(distanceF, rounds);

//       let updatedDuration = getUpdatedProperty(duration, rounds);
//       let updateHeartRate = getUpdatedProperty(heartRate, rounds);
//       let updateHeartRateMax = getUpdatedProperty(heartRateMax, rounds);

//       // let updatedTime = getUpdatedProperty(time, rounds);
//       // let updatedTimeMax = getUpdatedProperty(timeMax, rounds);
//       // let updatedTimeF = getUpdatedProperty(timeF, rounds);
//       // let updatedTimeUnits = getUpdatedProperty(timeUnits, rounds);
//       let updatedDurationMax = getUpdatedProperty(durationMax, rounds);
//       let updatedDurationF = getUpdatedProperty(durationF, rounds);
//       // let updatedDurationUnits = getUpdatedProperty(durationUnits, rounds);
//       // let updatedRounds = getUpdatedProperty(rounds, rounds);
//       // let updatedRoundsF = getUpdatedProperty(roundsF, rounds);

//       setReps(updatedReps);
//       setRepsMax(updatedRepsMax);
//       setRepsF(updatedRepsF);

//       setHeight(updatedHeight);
//       setHeightMax(updatedHeightMax);
//       setHeightF(updatedHeightF);

//       setWeight(updatedWeight);
//       setWeightMax(updatedWeightMax);
//       setWeightF(updatedWeightF);

//       setCalorie(updatedCalories);
//       setCalorieMax(updatedCaloriesMax);
//       setCalorieF(updatedCaloriesF);

//       setDistance(updatedDistance);
//       setDistanceMax(updatedDistanceMax);
//       setDistanceF(updatedDistanceF);

//       // setTime(updatedTime);
//       // setTimeMax(updatedTimeMax);
//       // setTimeF(updatedTimeF);

//       setDuration(updatedDuration);
//       setDurationMax(updatedDurationMax);
//       setDurationF(updatedDurationF);
//       setHeartRate(updateHeartRate);
//       setHeartRateMax(updateHeartRateMax);
//     };

//     const getUpdatedProperty = (property, rounds) => {
//       let updatedProperty = {};
//       let prevValue = -1;
//       for (let i = 1; i <= rounds; i++) {
//         if (property?.[i]) {
//           updatedProperty[i] = property[i];
//           prevValue = property[i];
//           let j = i - 1;
//           while ((!updatedProperty[j] || updatedProperty[j] === "") && j > 0) {
//             updatedProperty[j] = prevValue;
//             j--;
//           }
//         } else if (prevValue !== -1) {
//           updatedProperty[i] = prevValue;
//         } else {
//           updatedProperty[i] = "";
//         }
//       }
//       return updatedProperty;
//     };

//     const getEmptyState = (state) => {
//       if (!state || Object.entries(state).length === 0) {
//         let newState = {};
//         for (let i = 1; i <= rounds; i++) {
//           newState[i] = "";
//         }
//         return newState;
//       } else if (Object.entries(state) < rounds - 1) {
//         let newState = { ...state };
//         for (let i = Object.entries(state).length + 1; i <= rounds; i++) {
//           newState[i] = "";
//         }
//         return newState;
//       } else {
//         return state;
//       }
//     };

//     useEffect(() => {
//       // onSave(roundIdx, idx);
//       console.log("line606", selectedExerciseInfo);
//       setIsLoading(true);
//       setTimeout(() => {
//         setAgain(selectedExerciseInfo?.exercise);
//         setIsLoading(false);
//       }, 100);
//     }, [exercise, selectedExerciseInfo]);

//     const setAgain = (exercise) => {
//       setRoundIdx(roundIndex);
//       setIdx(index);
//       setReps(getEmptyState(exercise?.reps));
//       setRepsMax(exercise?.repsMax || getEmptyState(exercise?.repsMax));
//       setRepsF(exercise?.repsF || getEmptyState(exercise?.repsF));
//       setRepsInputType(exercise?.repsMax ? "range" : "perset");

//       setWeight(exercise?.weight || getEmptyState(exercise?.weight));
//       setWeightMax(exercise?.weightMax || getEmptyState(exercise?.weightMax));
//       setWeightF(exercise?.weightF || getEmptyState(exercise?.weightF));
//       setWeightUnits(exercise?.weightUnits || "Pounds");
//       setWeightInputType(exercise?.weightMax ? "range" : "perset");

//       setHeight(exercise?.height || getEmptyState(exercise?.height));
//       setHeightMax(exercise?.heightMax || getEmptyState(exercise?.heightMax));
//       setHeightF(exercise?.heightF || getEmptyState(exercise?.heightF));
//       setHeightUnits(exercise?.heightUnits || "Inches");
//       setHeightInputType(exercise?.heightMax ? "range" : "perset");

//       setCalorie(exercise?.calorie || getEmptyState(exercise?.calorie));
//       setCalorieMax(
//         exercise?.calorieMax || getEmptyState(exercise?.calorieMax)
//       );
//       setCalorieF(exercise?.calorieF || getEmptyState(exercise?.calorieF));
//       setCalorieInputType(
//         exercise?.calorieMax ? "range" : isEnduranceWorkout ? "fixed" : "perset"
//       );

//       if (isSwim) {
//         setDistance(exercise?.distance || { 1: 25 });
//       } else {
//         setDistance(exercise?.distance || getEmptyState(exercise?.distance));
//       }
//       setDistanceMax(
//         exercise?.distanceMax || getEmptyState(exercise?.distanceMax)
//       );
//       setDistanceF(exercise?.distanceF || getEmptyState(exercise?.distanceF));

//       setDistanceUnits(
//         isSwim ? "Yards" : exercise?.distanceUnits || "Kilometers"
//       );
//       setDistanceInputType(
//         exercise?.distanceMax
//           ? "range"
//           : isEnduranceWorkout
//           ? "fixed"
//           : "perset"
//       );

//       setDuration(exercise?.duration || getEmptyState(exercise?.duration));

//       setHeartRate(exercise?.heartRate || getEmptyState(exercise?.heartRate));
//       setHeartRateMax(
//         exercise?.heartRateMax || getEmptyState(exercise?.heartRateMax)
//       );

//       setHeartRateInputType(exercise?.heartRateMax ? "range" : "fixed");
//       setHeartRateType(exercise?.heartRateType || "above");

//       setPace(exercise?.pace || getEmptyState(exercise?.pace));
//       setPaceMax(exercise?.paceMax || getEmptyState(exercise?.paceMax));
//       setPaceInputType(exercise?.paceMax ? "range" : "fixed");
//       setPaceUnit(exercise?.paceUnit || "min/mi");

//       setDurationMax(
//         exercise?.durationMax || getEmptyState(exercise?.durationMax)
//       );
//       setDurationF(exercise?.durationF || getEmptyState(exercise?.durationF));
//       setDurationInputType(
//         exercise?.durationMax
//           ? "range"
//           : isEnduranceWorkout
//           ? "fixed"
//           : "perset"
//       );
//       setStrokeType(
//         exercise?.strokeType || getEmptyState(exercise?.strokeType)
//       );
//       setEquipment(exercise?.equipment || getEmptyState(exercise?.equipment));
//       setPower(exercise?.power || getEmptyState(exercise?.power));
//       setPowerType(exercise?.powerType || "above");
//       setPoolLength(props?.poolLength || 25);

//       setPrimaryTarget(exercise?.primaryTarget);
//       setSecondaryTarget(exercise?.secondaryTarget);

//       setSwimLap(exercise?.swimLap || 1);

//       setLapButtonPress(exercise?.lapButtonPress ?? false);

//       //add for time - hr min sec
//       // setTime(exercise?.time || getEmptyState(exercise?.time));

//       setRecordFromUser({
//         reps: exercise?.recordFromUser?.reps ? true : false,
//         weight: exercise?.recordFromUser?.weight ? true : false,
//         height: exercise?.recordFromUser?.height ? true : false,
//         distance: exercise?.recordFromUser?.distance ? true : false,
//         calorie: exercise?.recordFromUser?.calorie ? true : false,
//         duration: exercise?.recordFromUser?.duration ? true : false,
//         heartRate: exercise?.recordFromUser?.heartRate ? true : false,
//         // time: exercise?.recordFromUser?.time ? true : false,
//       });

//       setNotes(exercise?.movementInstr || "");

//       let properties = { ...showProperties };
//       options.map((option) => {
//         if (
//           (option["value"] == "time" && exercise?.["duration"]) ||
//           exercise?.[option["value"]]
//         ) {
//           properties[option["value"]] = true;
//         } else {
//           properties[option["value"]] = false;
//         }
//       });
//       if (isSwim) {
//         properties["swimLap"] = true;
//       }
//       Object.keys(properties).forEach(function (key, index) {
//         if (properties?.[key] === true) {
//           if (key === "distance" || key === "power" || key === "duration") {
//             setValue(key);
//           }
//         }
//       });

//       setShowProperties(properties);
//       setDisabledPropertiesInitially(properties);
//     };

//     const setDisabledPropertiesInitially = (showProperties) => {
//       let disabled = {};
//       Object.entries(showProperties).map(([key, value]) => {
//         if (
//           key === "intensity" ||
//           key === "strokeType" ||
//           key === "equipment" ||
//           key === "primaryTarget" ||
//           key === "secondaryTarget"
//         ) {
//         } else {
//           disabled[key] = !value;
//         }
//       });
//       // if all the keys are false for this workout kind, then enable all
//       switch (workoutKind) {
//         case "run":
//           if (
//             !showProperties["distance"] &&
//             !showProperties["duration"] &&
//             !showProperties["heartRate"] &&
//             !showProperties["calorie"] &&
//             !showProperties["lapButtonPress"]
//           ) {
//             disabled = {};
//           }
//           break;
//         case "swim":
//           if (
//             !showProperties["distance"] &&
//             !showProperties["duration"] &&
//             !showProperties["swimLap"]
//           ) {
//             disabled = {};
//           }
//           break;
//         case "bike":
//           if (
//             !showProperties["distance"] &&
//             !showProperties["duration"] &&
//             !showProperties["calorie"] &&
//             !showProperties["heartRate"] &&
//             !showProperties["power"] &&
//             !showProperties["lapButtonPress"]
//           ) {
//             disabled = {};
//           }
//           break;
//       }
//       setDisabledProperties(disabled);
//     };

//     useEffect(() => {
//       if (reps && rounds !== Object.keys(reps).length) {
//         onRoundsChange();
//       }
//     }, [rounds]);

//     if (!selectedExerciseInfo?.exercise || !exercise?.movementName)
//       return <EmptyViewAddProerties />;

//     const selectShowProperties = (key, selected) => {
//       switch (workoutKind) {
//         case "run":
//           // [distance, duration, heart rate, calorie] --> any one can only be selected at a time
//           if (
//             key === "distance" ||
//             key === "duration" ||
//             key === "heartRate" ||
//             key === "calorie" ||
//             key === "lapButtonPress"
//           ) {
//             if (selected) {
//               let res = {
//                 ...showProperties,
//                 distance: false,
//                 duration: false,
//                 heartRate: false,
//                 calorie: false,
//                 lapButtonPress: false,
//                 [key]: selected,
//               };
//               setDisabledProperties({
//                 distance: true,
//                 duration: true,
//                 heartRate: true,
//                 calorie: true,
//                 lapButtonPress: true,
//                 [key]: !selected,
//               });
//               setShowProperties(res);
//             } else {
//               setDisabledProperties({});
//               setShowProperties({
//                 ...showProperties,
//                 [key]: selected,
//               });
//             }
//           } else {
//             setShowProperties({
//               ...showProperties,
//               [key]: selected,
//             });
//           }
//           break;

//         case "swim":
//           // [distance, duration] --> any one can only be selected at a time
//           if (key === "distance" || key === "duration" || key === "swimLap") {
//             if (selected) {
//               setShowProperties({
//                 ...showProperties,
//                 distance: false,
//                 duration: false,
//                 swimLap: false,
//                 [key]: selected,
//               });
//               setDisabledProperties({
//                 distance: true,
//                 duration: true,
//                 swimLap: true,
//                 [key]: !selected,
//               });
//             } else {
//               setDisabledProperties({});
//               setShowProperties({
//                 ...showProperties,
//                 [key]: selected,
//               });
//             }
//           } else {
//             setShowProperties({
//               ...showProperties,
//               [key]: selected,
//             });
//           }
//           break;

//         case "bike":
//           // [distance, duration, calorie, heart rate, power] --> any one can only be selected at a time
//           if (
//             key === "distance" ||
//             key === "duration" ||
//             key === "calorie" ||
//             key === "heartRate" ||
//             key === "power" ||
//             key === "lapButtonPress"
//           ) {
//             if (selected) {
//               setShowProperties({
//                 ...showProperties,
//                 distance: false,
//                 duration: false,
//                 calorie: false,
//                 heartRate: false,
//                 power: false,
//                 lapButtonPress: false,
//                 [key]: selected,
//               });
//               setDisabledProperties({
//                 distance: true,
//                 duration: true,
//                 calorie: true,
//                 heartRate: true,
//                 power: true,
//                 lapButtonPress: true,
//                 [key]: !selected,
//               });
//             } else {
//               setDisabledProperties({});
//               setShowProperties({
//                 ...showProperties,
//                 [key]: selected,
//               });
//             }
//           } else {
//             setShowProperties({
//               ...showProperties,
//               [key]: selected,
//             });
//           }
//           break;
//       }
//     };
//     return isLoading ? (
//       <div
//         style={{
//           width: isEnduranceWorkout ? "100vw" : "calc((100vw - 110px)/4)",
//           height: "calc(100vh - 110px)",
//           borderRadius: "12px",
//           position: "fixed",
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "center",
//         }}
//       >
//         <SpurfitCircularProgress />
//       </div>
//     ) : (
//       <Paper
//         sx={{
//           width: isEnduranceWorkout
//             ? "calc((100vw - 240px)/3)"
//             : "calc((100vw - 110px)/4)",
//           height: "calc(100vh - 110px)",
//           borderRadius: "12px",
//           position: "fixed",
//         }}
//       >
//         <div
//           style={{
//             height: "56px",
//             padding: "0 24px",
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "flex-start",
//             borderBottom: " 1px solid #E5E5E5",
//           }}
//           className="exercise-library-title"
//         >
//           {exercise?.movementName}{" "}
//           {!isEnduranceWorkout && (
//             <InfoOutlinedIcon
//               style={{ marginLeft: "8px", cursor: "pointer" }}
//               onClick={() => {
//                 setShowVideoDialog(true);
//               }}
//             />
//           )}
//         </div>
//         <DialogContent
//           sx={{
//             height: "calc(100vh - 260px)",
//             overflowY: "scroll",
//           }}
//         >
//           <div
//             style={{
//               display: "flex",
//               flexDirection: "column",
//               gap: "24px",
//             }}
//           >
//             {enabled.includes("Pool Length") && (
//               <div>
//                 <Label className="text-[#242424]" htmlFor="poolLength">
//                   Pool Length
//                 </Label>
//                 <div className="flex items-center gap-2">
//                   <Input
//                     id="poolLength"
//                     value={props?.poolLength}
//                     onChange={(e) => {
//                       // if there is - in the input, then don't update the state
//                       if (e.target.value.includes("-")) return;

//                       // setPoolLength(e.target.value);
//                       setPoolLengthInAllRounds(e.target.value);
//                     }}
//                     type="number"
//                     // the number should always be multiple of 25
//                     min={1}
//                     className={cn(
//                       "focus-visible:ring-[#8330e9]",
//                       "w-[100px] mt-1 text-font-14"
//                     )}
//                   />

//                   <span className="text-font14 text-gray-500">Yards</span>
//                 </div>
//               </div>
//             )}

//             {enabled.includes("Swim Lap") && (
//               <div
//                 className="flex flex-col gap-2 "
//                 style={{
//                   pointerEvents: disabledProperties?.swimLap ? "none" : "auto",
//                   opacity: disabledProperties?.swimLap ? 0.5 : 1,
//                 }}
//               >
//                 <PropertyHeader
//                   value={showProperties?.swimLap}
//                   onClick={() =>
//                     selectShowProperties("swimLap", !showProperties?.swimLap)
//                   }
//                   label={"Swim Lap"}
//                 />
//                 {showProperties?.swimLap && (
//                   <div className="flex items-center gap-2">
//                     <Input
//                       value={swimLap}
//                       onChange={(e) => {
//                         setSwimLap(e.target.value);
//                       }}
//                       type="number"
//                       min={1}
//                       className="w-[100px] mt-1 text-font-14 focus-visible:ring-white-pure"
//                     />
//                     <span className="text-font14 text-gray-500">Laps</span>
//                   </div>
//                 )}
//               </div>
//             )}

//             {enabled.includes("Distance") && (
//               <RenderProperty
//                 label={"Distance"}
//                 isDisabled={disabledProperties?.distance}
//                 incrementRound={incrementRound}
//                 decrementRound={decrementRound}
//                 rounds={rounds}
//                 state={distance}
//                 setState={setDistance}
//                 maxState={distanceMax}
//                 setMaxState={setDistanceMax}
//                 stateF={distanceF}
//                 setStateF={setDistanceF}
//                 showF={false}
//                 inputType={distanceInputType}
//                 setInputType={setDistanceInputType}
//                 isEnduranceWorkout={isEnduranceWorkout}
//                 selectedUnit={distanceUnits}
//                 setSelectedUnit={setDistanceUnits}
//                 unitOptions={isSwim ? ["Yards"] : distanceUnitOptions}
//                 key={"distance"}
//                 showProperty={showProperties?.distance}
//                 setShowProperty={(value) => {
//                   selectShowProperties("distance", value);
//                 }}
//               />
//             )}

//             {enabled.includes("Calorie") && (
//               <RenderProperty
//                 label={"Calorie (cal)"}
//                 isDisabled={disabledProperties?.calorie}
//                 incrementRound={incrementRound}
//                 decrementRound={decrementRound}
//                 rounds={rounds}
//                 state={calorie}
//                 setState={setCalorie}
//                 maxState={calorieMax}
//                 setMaxState={setCalorieMax}
//                 stateF={calorieF}
//                 setStateF={setCalorieF}
//                 showF={false}
//                 isEnduranceWorkout={isEnduranceWorkout}
//                 inputType={calorieInputType}
//                 setInputType={setCalorieInputType}
//                 showProperty={showProperties?.calorie}
//                 key={"calorie"}
//                 setShowProperty={(value) => {
//                   selectShowProperties("calorie", value);
//                 }}
//               />
//             )}

//             {enabled.includes("Heart rate") && (
//               <RenderProperty
//                 label={"Heart Rate (bpm)"}
//                 isDisabled={disabledProperties?.heartRate}
//                 incrementRound={incrementRound}
//                 decrementRound={decrementRound}
//                 rounds={rounds}
//                 state={heartRate}
//                 setState={setHeartRate}
//                 maxState={heartRateMax}
//                 setMaxState={setHeartRateMax}
//                 stateF={heartRateF}
//                 setStateF={setHeartRateF}
//                 showF={false}
//                 inputType={heartRateInputType}
//                 setInputType={setHeartRateInputType}
//                 showProperty={showProperties?.heartRate}
//                 key={"heartRate"}
//                 isEnduranceWorkout={true}
//                 type={heartRateType}
//                 setType={setHeartRateType}
//                 setShowProperty={(value) => {
//                   selectShowProperties("heartRate", value);
//                 }}
//               />
//             )}

//             {enabled.includes("Power") && (
//               <RenderProperty
//                 label={"Power"}
//                 isDisabled={disabledProperties?.power}
//                 incrementRound={incrementRound}
//                 decrementRound={decrementRound}
//                 rounds={rounds}
//                 state={power}
//                 setState={setPower}
//                 showF={false}
//                 showProperty={showProperties?.power}
//                 key={"power"}
//                 type={powerType}
//                 setType={setPowerType}
//                 isEnduranceWorkout={true}
//                 setShowProperty={(value) => {
//                   selectShowProperties("power", value);
//                 }}
//               />
//             )}

//             <div
//               style={{
//                 display: "flex",
//                 flexDirection: "column",
//                 alignItems: "flex-start",
//                 gap: "8px",
//                 pointerEvents: disabledProperties?.duration ? "none" : "auto",
//                 opacity: disabledProperties?.duration ? 0.5 : 1,
//               }}
//             >
//               <div
//                 style={{
//                   display: "flex",
//                   alignItems: "center",
//                   gap: 4,
//                 }}
//                 key={"duration"}
//               >
//                 <CheckBox
//                   value={showProperties?.duration}
//                   onClick={() =>
//                     // setShowProperties({
//                     //   ...showProperties,
//                     //   duration: !showProperties?.duration,
//                     // })
//                     selectShowProperties("duration", !showProperties?.duration)
//                   }
//                 />
//                 <CheckBoxLabel
//                   label={`Duration ${format !== "" ? `(${format})` : ""}`}
//                 />
//               </div>
//               {showProperties?.duration && (
//                 <div
//                   style={{
//                     alignItems: "baseline",
//                     display: "flex",
//                     width: "100%",
//                     overflowX: "scroll",
//                     gap: "12px",
//                   }}
//                 >
//                   <TimeInput
//                     setFormat={setFormat}
//                     incrementRound={incrementRound}
//                     decrementRound={decrementRound}
//                     rounds={rounds}
//                     minutes={minutes}
//                     seconds={seconds}
//                     setMinutes={setMinutes}
//                     setSeconds={setSeconds}
//                     duration={duration}
//                     setDuration={(value) => {
//                       console.log("duration value: ", value);
//                       setDuration(value);
//                     }}
//                     index={0}
//                     isSingle={isEnduranceWorkout}
//                   />
//                 </div>
//               )}
//             </div>

//             {enabled.includes("Lap Button Press") && (
//               <div>
//                 <PropertyHeader
//                   value={showProperties?.lapButtonPress}
//                   isDisabled={disabledProperties?.lapButtonPress}
//                   onClick={() => {
//                     selectShowProperties(
//                       "lapButtonPress",
//                       !showProperties?.lapButtonPress
//                     );
//                     setLapButtonPress(!showProperties?.lapButtonPress);
//                   }}
//                   label={"Lap Button Press"}
//                   onlyLabel={true}
//                 />
//               </div>
//             )}

//             {enabled.includes("Stroke Type") && (
//               <div className="flex flex-col gap-2 ">
//                 <PropertyHeader
//                   value={showProperties?.strokeType}
//                   onClick={() =>
//                     setShowProperties({
//                       ...showProperties,
//                       strokeType: !showProperties?.strokeType,
//                     })
//                   }
//                   label={"Stroke Type"}
//                 />
//                 {showProperties?.strokeType && (
//                   <CustomSelect
//                     placeholder={"Stroke Type"}
//                     triggerClassName={"w-[200px] focus:ring-[#8330e9]"}
//                     onValueChange={(value) => {
//                       setStrokeType(value);
//                     }}
//                     value={strokeType}
//                     options={[
//                       { label: "Any Style", value: "any" },
//                       { label: "Backstroke", value: "BACKSTROKE" },
//                       { label: "Breaststroke", value: "BREASTSTROKE" },
//                       { label: "Fly", value: "BUTTERFLY" },
//                       { label: "Free", value: "FREESTYLE" },
//                       { label: "Individual Medley", value: "IM" },
//                       { label: "Mixed", value: "MIXED" },
//                     ]}
//                   />
//                 )}
//               </div>
//             )}

//             {enabled.includes("Equipment") && (
//               <div className="flex flex-col gap-2 ">
//                 <PropertyHeader
//                   value={showProperties?.equipment}
//                   onClick={() =>
//                     setShowProperties({
//                       ...showProperties,
//                       equipment: !showProperties?.equipment,
//                     })
//                   }
//                   label={"Equipment"}
//                 />
//                 {showProperties?.equipment && (
//                   <CustomSelect
//                     triggerClassName={"w-[200px] focus:ring-[#8330e9]"}
//                     placeholder={"Equipment"}
//                     onValueChange={(value) => {
//                       setEquipment(value);
//                     }}
//                     value={equipment}
//                     options={[
//                       { label: "Fins", value: "SWIM_FINS" },
//                       { label: "Kickboard", value: "SWIM_KICKBOARD" },
//                       { label: "Paddles", value: "SWIM_PADDLES" },
//                       { label: "Pull Buoy", value: "SWIM_PULL_BUOY" },
//                       { label: "Snorkel", value: "SWIM_SNORKEL" },
//                       { label: "None", value: "NONE" },
//                     ]}
//                   />
//                 )}
//               </div>
//             )}

//             {enabled.includes("Intensity") && (
//               <div>
//                 <div
//                   style={{
//                     display: "flex",
//                     alignItems: "center",
//                     gap: 4,
//                   }}
//                   key={"intensity"}
//                 >
//                   <CheckBox
//                     value={showProperties?.intensity}
//                     onClick={() =>
//                       setShowProperties({
//                         ...showProperties,
//                         intensity: !showProperties?.intensity,
//                       })
//                     }
//                   />
//                   <CheckBoxLabel label={"Intensity"} />
//                 </div>
//                 {showProperties?.intensity && (
//                   <AddIntensity
//                     prevIntensity={exercise?.intensity}
//                     onAdd={(intensity) => {
//                       setIntensity(intensity);
//                     }}
//                   />
//                 )}
//               </div>
//             )}

//             {enabled?.includes("Primary Target") && (
//               <div className="flex flex-col gap-2 ">
//                 <PropertyHeader
//                   value={showProperties?.primaryTarget}
//                   onClick={() =>
//                     setShowProperties({
//                       ...showProperties,
//                       primaryTarget: !showProperties?.primaryTarget,
//                     })
//                   }
//                   isDisabled={disableIntensity}
//                   label={"Primary Target"}
//                 />
//                 {showProperties?.primaryTarget && (
//                   <Targets
//                     prevTargets={exercise?.primaryTarget}
//                     onAdd={(targets) => {
//                       console.log("endurance: targets: ", targets);
//                       setPrimaryTarget(targets);
//                     }}
//                   />
//                 )}
//               </div>
//             )}
//             {enabled?.includes("Secondary Target") && (
//               <div className="flex flex-col gap-2 ">
//                 <PropertyHeader
//                   value={showProperties?.secondaryTarget}
//                   onClick={() =>
//                     setShowProperties({
//                       ...showProperties,
//                       secondaryTarget: !showProperties?.secondaryTarget,
//                     })
//                   }
//                   isDisabled={disableIntensity}
//                   label={"Secondary Target"}
//                 />
//                 {showProperties?.secondaryTarget && (
//                   <Targets
//                     prevTargets={exercise?.secondaryTarget}
//                     onAdd={(targets) => {
//                       setSecondaryTarget(targets);
//                     }}
//                   />
//                 )}
//               </div>
//             )}
//             <div
//               style={{
//                 display: "flex",
//                 flexDirection: "column",
//                 alignItems: "flex-start",
//                 gap: "8px",
//               }}
//             >
//               <div className="property-name">Notes</div>
//               <textarea
//                 style={{
//                   width: "100%",
//                   fontFamily: "DM Sans",
//                   borderRadius: "12px",
//                   border: "1px solid #CCC",
//                   padding: "14px 16px",
//                   resize: "none",
//                   outline: "none",
//                 }}
//                 placeholder="instructions"
//                 value={notes}
//                 onChange={(e) => {
//                   setNotes(e.target.value);
//                 }}
//               />
//             </div>
//           </div>
//         </DialogContent>
//         <DialogActions
//           sx={{
//             justifyContent: "center",
//             p: "24px",
//           }}
//         >
//           <button
//             style={{
//               width: "100%",
//             }}
//             className="button-new"
//             onClick={() => {
//               displayAlert({
//                 message: "Details updated",
//                 type: "success",
//               });
//               onSave(roundIdx, idx);
//             }}
//           >
//             Apply Changes
//           </button>
//         </DialogActions>

//         {showVideoDialog && (
//           <VideoPlayerModel
//             videoInfo={exercisesObj[exercise?.movementID]}
//             setOpenDialog={setShowVideoDialog}
//             openDialog={showVideoDialog}
//             handleUpdateExercise={() => {
//               setShowUpdateExerciseModal(true);
//             }}
//             setSelectedItem={setSelectedItem}
//           />
//         )}
//         {showUpdateExerciseModal && (
//           <AddExerciseModal
//             open={showUpdateExerciseModal}
//             setOpen={setShowUpdateExerciseModal}
//             info={{ ...selectedItem, id: undefined }}
//             libraryType={"1"}
//             handleShowAlert={(error, type) => {
//               console.log(type, error);
//             }}
//             setVideoInfo={setVideoInfo}
//             updateExerciseToParticularRound={(e) => {
//               // update exercise with the updated exercise
//               updateExerciseToParticularRound(roundIndex, index, e);
//               dispatch(
//                 modifyExerciseObject({
//                   ...exercisesObj,
//                   [e.id]: e,
//                 })
//               );
//             }}
//           />
//         )}
//       </Paper>
//     );
//   }
// );

// export default AddEnduranceProperties;

// const heightUnitOptions = ["Inches", "Feet", "Centimeters", "Meters"];
// const weightUnitOptions = [
//   "Pounds",
//   "Kilograms",
//   "RepMaxFraction",
//   "BodyWeight",
//   "RPE",
// ];
// const distanceUnitOptions = ["Meters", "Kilometers", "Yards", "Miles"];

// const paceUnitOptions = ["min/mi", "min/km"];



import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { DialogActions, DialogContent, Paper } from "@mui/material";
import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { cn } from "@/lib/utils";
import { useDispatch, useSelector } from "react-redux";
import { CustomAlertContext } from "../../../App";
import {
  TRAINING_TYPES,
  getTrainingType,
} from "../../../common/trainingTypeHelper";
import AddExerciseModal from "../../../pages/Exercises/AddExerciseModal";
import { modifyExerciseObject } from "../../../redux/dataSlice";
import { SpurfitCircularProgress } from "../../Dashboard/dashboard";
import VideoPlayerModel from "../../VideoPlayer/VideoPlayer";
import CheckBox, {
  CheckBoxLabel,
  RenderProperty,
  TimeInput,
} from "../AddProperitesHelpers";
import EmptyViewAddProerties from "../EmptyViewAddProerties";
import AddIntensity from "./AddIntensityDialog";
import { EnduranceContext } from "./EnduranceBuilder";
import CustomSelect from "./util/CustomSelect";
import PropertyHeader from "./util/PropertyHeader";
import Targets from "./util/Targets";

const AddEnduranceProperties = forwardRef(
  (
    {
      exercise,
      updateExerciseProperty,
      roundIndex,
      index,
      rounds,
      incrementRound,
      decrementRound,
      selectedExerciseInfo,
      updateExerciseToParticularRound,
      isEnduranceWorkout,
      setPoolLengthInAllRounds,
      workoutKind,
      ...props
    },
    ref
  ) => {
    const isRun = workoutKind === "run";
    const isSwim = workoutKind === "swim";
    const isBike = workoutKind === "bike";

    const [showUpdateExerciseModal, setShowUpdateExerciseModal] =
      useState(false);
    const [videoInfo, setVideoInfo] = useState(null);
    const [selectedItem, setSelectedItem] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [roundIdx, setRoundIdx] = useState(0);
    const [idx, setIdx] = useState(0);
    const [format, setFormat] = useState("");

    // reps
    //TODO - need to handle for EnduranceContext
    const workoutContext = useContext(EnduranceContext);
    const [reps, setReps] = useState(exercise?.reps || {});
    const [repsMax, setRepsMax] = useState(exercise?.repsMax || {});
    const [repsF, setRepsF] = useState(exercise?.repsF || {});
    const [repsInputType, setRepsInputType] = useState(
      exercise?.repsMax ? "range" : "perset"
    );

    // weight
    const [weight, setWeight] = useState(exercise?.weight || {});
    const [weightMax, setWeightMax] = useState(exercise?.weightMax || {});
    const [weightF, setWeightF] = useState(exercise?.weightF || {});
    const [weightInputType, setWeightInputType] = useState(
      exercise?.weightMax ? "range" : "perset"
    );
    const [weightUnits, setWeightUnits] = useState(
      exercise?.weightUnits || "Pounds"
    );

    // height
    const [height, setHeight] = useState(exercise?.height || {});
    const [heightMax, setHeightMax] = useState(exercise?.heightMax || {});
    const [heightF, setHeightF] = useState(exercise?.heightF || {});
    const [heightInputType, setHeightInputType] = useState(
      exercise?.heightMax ? "range" : "perset"
    );
    const [heightUnits, setHeightUnits] = useState(
      exercise?.heightUnits || "Inches"
    );

    // distance
    const [distance, setDistance] = useState(
      exercise?.distance || isSwim ? { 1: 25 } : {}
    );
    const [distanceMax, setDistanceMax] = useState(exercise?.distanceMax || {});
    const [distanceF, setDistanceF] = useState(exercise?.distanceF || {});
    const [distanceInputType, setDistanceInputType] = useState(
      exercise?.distanceMax ? "range" : "perset"
    );
    const [distanceUnits, setDistanceUnits] = useState(
      isSwim ? "Yards" : exercise?.distanceUnits || "Kilometers"
    );

    // calorie
    const [calorie, setCalorie] = useState(exercise?.calorie || {});
    const [calorieMax, setCalorieMax] = useState(exercise?.calorieMax || {});
    const [calorieF, setCalorieF] = useState(exercise?.calorieF || {});
    const [calorieInputType, setCalorieInputType] = useState(
      exercise?.calorieMax ? "range" : "fixed"
    );

    // heart rate
    const [heartRate, setHeartRate] = useState(exercise?.heartRate || {});
    const [heartRateMax, setHeartRateMax] = useState(
      exercise?.heartRateMax || {}
    );
    const [heartRateF, setHeartRateF] = useState(exercise?.heartRateF || {});
    const [heartRateInputType, setHeartRateInputType] = useState(
      exercise?.heartRateMax ? "range" : "fixed"
    );
    const [heartRateType, setHeartRateType] = useState("above");

    // pace
    const [pace, setPace] = useState(exercise?.pace || {});
    const [paceMax, setPaceMax] = useState(exercise?.paceMax || {});
    const [paceUnit, setPaceUnit] = useState(exercise?.paceUnit || "min/mi");
    const [paceInputType, setPaceInputType] = useState("range");

    // duration
    const [duration, setDuration] = useState(exercise?.duration || {});
    const [durationMax, setDurationMax] = useState(exercise?.durationMax || {});
    const [durationF, setDurationF] = useState(exercise?.durationF || {});
    const [durationInputType, setDurationInputType] = useState(
      exercise?.durationMax ? "range" : "fixed"
    );

    const [intensity, setIntensity] = useState(exercise?.intensity || {});
    const [strokeType, setStrokeType] = useState(exercise?.strokeType);
    const [equipment, setEquipment] = useState(exercise?.equipment);

    const [power, setPower] = useState(exercise?.power || {});
    const [powerType, setPowerType] = useState(exercise?.powerType || "above");

    const [poolLength, setPoolLength] = useState(props?.poolLength || 25);

    const [parimaryTarget, setPrimaryTarget] = useState(
      exercise?.primaryTarget
    );
    const [secondaryTarget, setSecondaryTarget] = useState(
      exercise?.secondaryTarget
    );

    const [swimLap, setSwimLap] = useState(exercise?.swimLap || 1);
    const [lapButtonPress, setLapButtonPress] = useState(
      exercise?.lapButtonPress ?? false
    );

    // record from user
    const [recordFromUser, setRecordFromUser] = useState({
      reps: false,
      weight: false,
      height: false,
      distance: false,
      calorie: false,
      heartRate: false,
      duration: false,
      swimLap: false,
      // time: false,
    });

    // notes
    const [notes, setNotes] = useState(exercise?.movementInstr || "");

    const [seconds, setSeconds] = useState(exercise?.duration / 60 || 0);
    const [minutes, setMinutes] = useState(exercise?.duration % 60 || 0);

    const [showProperties, setShowProperties] = useState({
      weight: false,
      height: false,
      distance: isSwim ? true : false,
      calorie: false,
      heartRate: false,
      duration: false,
      pace: false,
      intensity: false,
      strokeType: false,
      equipment: false,
      primaryTarget: false,
      secondaryTarget: false,
      swimLap: false,
      lapButtonPress: false,
      // time: false,
    });

    const [disabledProperties, setDisabledProperties] = useState({});
    const [value, setValue] = useState("");
    const { displayAlert } = useContext(CustomAlertContext);

    const [showVideoDialog, setShowVideoDialog] = useState(false);
    const exercisesObj = useSelector((state) => state.data.exerciseObject);
    const dispatch = useDispatch();

    const options = isRun
      ? [
          { label: "Distance", value: "distance" },
          { label: "Duration", value: "duration" },
          { label: "Heart rate", value: "heartRate" },
          { label: "Calories", value: "calorie" },
          { label: "Pace", value: "pace" },
          { label: "Intensity", value: "intensity" },
          { label: "Lap Button Press", value: "lapButtonPress" },
        ]
      : isSwim
      ? [
          { label: "Pool Length", value: "poolLength" },
          // { label: "Distance", value: "distance" },
          { label: "Laps", value: "swimLap" },
          { label: "Duration", value: "duration" },
          { label: "Stroke Type", value: "strokeType" },
          { label: "Equipment", value: "equipment" },
        ]
      : [
          { label: "Duration", value: "duration" },
          { label: "Distance", value: "distance" },
          { label: "Calories", value: "calorie" },
          { label: "Lap Button Press", value: "lapButtonPress" },
          { label: "Heart Rate", value: "heartRate" },
          { label: "Power", value: "power" },
          { label: "Primary Target", value: "primaryTarget" },
          { label: "Secondary Target", value: "secondaryTarget" },
          { label: "Lap button press", value: "lapButtonPress" },
        ];

    const enabled = isRun
      ? ["Distance", "Duration", "Intensity", "Lap Button Press"]
      : isSwim
      ? ["Pool Length", "Swim Lap", "Duration", "Stroke Type", "Equipment"]
      : [
          "Duration",
          "Distance",
          // "Calorie",
          // "Heart rate",
          "Lap Button Press",
          "Power",
          "Primary Target",
          "Secondary Target",
          "Lap Button Press",
        ];

    const stateMap = {
      reps: [reps, setReps],
      repsMax: [repsMax, setRepsMax],
      repsF: [repsF, setRepsF],
      weight: [weight, setWeight],
      weightMax: [weightMax, setWeightMax],
      weightF: [weightF, setWeightF],
      height: [height, setHeight],
      heightMax: [heightMax, setHeightMax],
      heightF: [heightF, setHeightF],
      distance: [distance, setDistance],
      distanceMax: [distanceMax, setDistanceMax],
      distanceF: [distanceF, setDistanceF],
      calorie: [calorie, setCalorie],
      calorieMax: [calorieMax, setCalorieMax],
      calorieF: [calorieF, setCalorieF],
      duration: [duration, setDuration],
      heartRate: [heartRate, setHeartRate],
      heartRateMax: [heartRateMax, setHeartRateMax],

      pace: [pace, setPace],
      paceMax: [paceMax, setPaceMax],

      swimLap: [swimLap, setSwimLap],
      // timeHr: [timeHr, setTimeHr],
      // timeMin: [timeMin, setTimeMin],
      // timeSec: [timeSec, setTimeSec],
      // time: [time, setTime],
      // timeMax: [timeMax, setTimeMax],
      // timeF: [timeF, setTimeF],
      durationMax: [durationMax, setDurationMax],
      durationF: [durationF, setDurationF],
      intensity: [intensity, setIntensity],
      strokeType: [strokeType, setStrokeType],
      equipment: [equipment, setEquipment],
      poolLength: [poolLength, setPoolLength],
      power: [power, setPower],
      powerType: [powerType, setPowerType],
    };

    const checkEmptyEntries = (obj) => {
      if (!obj || Object.entries(obj).length === 0) return true;
      return Object.values(obj).every((x) => x === "" || x === undefined);
    };

    const updateExercisePropertyHelper = (
      roundIndex,
      index,
      propertyName,
      inputValues,
      rounds,
      inputType
    ) => {
      if (!checkEmptyEntries(inputValues)) {
        const updatedProperty = getUpdatedProperty(inputValues, rounds);
        if (inputType === "range" && !checkEmptyEntries(inputValues)) {
          let maxStates = stateMap[`${propertyName}Max`];
          let updatedMaxProperty = getUpdatedProperty(maxStates[0], rounds);
          if (checkEmptyEntries(updatedMaxProperty)) {
            displayAlert({
              message: `Please min and max value for ${propertyName} range.`,
              type: "error",
            });
            return;
          }
          updateExerciseProperty(
            roundIndex,
            index,
            `${propertyName}Max`,
            updatedMaxProperty
          );
          maxStates[1](updatedMaxProperty);
        } else {
          if (workoutContext?.removeOneProperty) {
            workoutContext.removeOneProperty(
              roundIndex,
              index,
              `${propertyName}Max`
            );
            stateMap[`${propertyName}Max`][1]({});
          }
        }
        updateExerciseProperty(
          roundIndex,
          index,
          propertyName,
          updatedProperty
        );
        stateMap[propertyName][1](updatedProperty);
      }
    };
    const onSave = (roundIdx, index) => {
      if (
        (distance?.[1] && distance[1] !== "") ||
        (duration?.[1] && duration[1] !== "") ||
        (power?.[1] && power[1] !== "")
      ) {
        // console.log("duration[1]",duration)
        // console.log("distance", distance[1])
        // console.log("power", power  )
        updateExercisePropertyHelper(
          roundIdx,
          index,
          "reps",
          reps,
          rounds,
          repsInputType
        );
        updateExercisePropertyHelper(roundIdx, index, "repsF", repsF, rounds);
        updateExercisePropertyHelper(
          roundIdx,
          index,
          "weight",
          weight,
          rounds,
          weightInputType
        );
        updateExercisePropertyHelper(
          roundIdx,
          index,
          "weightF",
          weightF,
          rounds
        );
        updateExerciseProperty(roundIdx, index, "weightUnits", weightUnits);
        updateExercisePropertyHelper(
          roundIdx,
          index,
          "height",
          height,
          rounds,
          heightInputType
        );
        updateExercisePropertyHelper(
          roundIdx,
          index,
          "heightF",
          heightF,
          rounds
        );
        updateExerciseProperty(roundIdx, index, "heightUnits", heightUnits);
        updateExercisePropertyHelper(
          roundIdx,
          index,
          "calorie",
          calorie,
          rounds,
          calorieInputType
        );
        updateExercisePropertyHelper(
          roundIdx,
          index,
          "calorieF",
          calorieF,
          rounds
        );
        updateExercisePropertyHelper(
          roundIdx,
          index,
          "distance",
          distance,
          rounds,
          distanceInputType
        );
        updateExercisePropertyHelper(
          roundIdx,
          index,
          "distanceF",
          distanceF,
          rounds
        );
        updateExercisePropertyHelper(
          roundIdx,
          index,
          "heartRate",
          heartRate,
          rounds,
          heartRateInputType
        );

        // updateExerciseProperty(roundIdx, index, "pace", pace);
        // updateExerciseProperty(roundIdx, index, "paceMax", paceMax);

        // updateExerciseProperty(roundIdx, index, "paceUnit", paceUnit);
        updateExerciseProperty(roundIdx, index, "distanceUnits", distanceUnits);
        updateExerciseProperty(roundIdx, index, "duration", duration);
        updateExerciseProperty(roundIdx, index, "heartRateType", heartRateType);

        updateExerciseProperty(roundIdx, index, "durationMax", durationMax);
        updateExerciseProperty(roundIdx, index, "durationF", durationF);
        // updateExerciseProperty(roundIdx, index, "durationUnits", durationUnits);
        updateExerciseProperty(roundIdx, index, "rounds", rounds);
        // updateExerciseProperty(roundIdx, index, "roundsF", roundsF);

        updateExerciseProperty(
          roundIdx,
          index,
          "recordFromUser",
          recordFromUser
        );
        updateExerciseProperty(roundIdx, index, "movementInstr", notes);

        updateExerciseProperty(roundIdx, index, "intensity", intensity);
        updateExerciseProperty(roundIdx, index, "strokeType", strokeType);
        updateExerciseProperty(roundIdx, index, "equipment", equipment);

        updateExerciseProperty(roundIdx, index, "power", power);
        // updateExerciseProperty(roundIdx, index, "power", power);
        updateExerciseProperty(roundIdx, index, "powerType", powerType);
        updateExerciseProperty(
          roundIdx,
          index,
          "primaryTarget",
          parimaryTarget
        );
        updateExerciseProperty(
          roundIdx,
          index,
          "secondaryTarget",
          secondaryTarget
        );

        updateExerciseProperty(
          roundIdx,
          index,
          "lapButtonPress",
          lapButtonPress
        );
        updateExerciseProperty(roundIdx, index, "swimLap", swimLap);
        if (isSwim && props?.poolLength && swimLap) {
          let distance = props?.poolLength * swimLap;
          updateExerciseProperty(roundIdx, index, "distance", { 1: distance });
        }

        Object.entries(showProperties).map(([key, value]) => {
          if (!value && workoutContext?.removeOneProperty) {
            workoutContext.removeOneProperty(roundIdx, index, key);
            if (stateMap?.[key]?.[1]) {
              stateMap[key][1]({});
            }
          }
        });
      } else {
        return;
      }
    };

    useImperativeHandle(ref, () => ({
      saveData() {
        onSave(roundIdx, idx);
      },
    }));

    const onRoundsChange = () => {
      let updatedReps = getUpdatedProperty(reps, rounds);
      let updatedRepsMax = getUpdatedProperty(repsMax, rounds);
      let updatedRepsF = getUpdatedProperty(repsF, rounds);

      let updatedHeight = getUpdatedProperty(height, rounds);
      let updatedHeightMax = getUpdatedProperty(heightMax, rounds);
      let updatedHeightF = getUpdatedProperty(heightF, rounds);

      let updatedWeight = getUpdatedProperty(weight, rounds);
      let updatedWeightMax = getUpdatedProperty(weightMax, rounds);
      let updatedWeightF = getUpdatedProperty(weightF, rounds);

      let updatedCalories = getUpdatedProperty(calorie, rounds);
      let updatedCaloriesMax = getUpdatedProperty(calorieMax, rounds);
      let updatedCaloriesF = getUpdatedProperty(calorieF, rounds);

      let updatedDistance = getUpdatedProperty(distance, rounds);
      let updatedDistanceMax = getUpdatedProperty(distanceMax, rounds);
      let updatedDistanceF = getUpdatedProperty(distanceF, rounds);

      let updatedDuration = getUpdatedProperty(duration, rounds);
      let updateHeartRate = getUpdatedProperty(heartRate, rounds);
      let updateHeartRateMax = getUpdatedProperty(heartRateMax, rounds);

      // let updatedTime = getUpdatedProperty(time, rounds);
      // let updatedTimeMax = getUpdatedProperty(timeMax, rounds);
      // let updatedTimeF = getUpdatedProperty(timeF, rounds);
      // let updatedTimeUnits = getUpdatedProperty(timeUnits, rounds);
      let updatedDurationMax = getUpdatedProperty(durationMax, rounds);
      let updatedDurationF = getUpdatedProperty(durationF, rounds);
      // let updatedDurationUnits = getUpdatedProperty(durationUnits, rounds);
      // let updatedRounds = getUpdatedProperty(rounds, rounds);
      // let updatedRoundsF = getUpdatedProperty(roundsF, rounds);

      setReps(updatedReps);
      setRepsMax(updatedRepsMax);
      setRepsF(updatedRepsF);

      setHeight(updatedHeight);
      setHeightMax(updatedHeightMax);
      setHeightF(updatedHeightF);

      setWeight(updatedWeight);
      setWeightMax(updatedWeightMax);
      setWeightF(updatedWeightF);

      setCalorie(updatedCalories);
      setCalorieMax(updatedCaloriesMax);
      setCalorieF(updatedCaloriesF);

      setDistance(updatedDistance);
      setDistanceMax(updatedDistanceMax);
      setDistanceF(updatedDistanceF);

      // setTime(updatedTime);
      // setTimeMax(updatedTimeMax);
      // setTimeF(updatedTimeF);

      setDuration(updatedDuration);
      setDurationMax(updatedDurationMax);
      setDurationF(updatedDurationF);
      setHeartRate(updateHeartRate);
      setHeartRateMax(updateHeartRateMax);
    };

    const getUpdatedProperty = (property, rounds) => {
      let updatedProperty = {};
      let prevValue = -1;
      for (let i = 1; i <= rounds; i++) {
        if (property?.[i]) {
          updatedProperty[i] = property[i];
          prevValue = property[i];
          let j = i - 1;
          while ((!updatedProperty[j] || updatedProperty[j] === "") && j > 0) {
            updatedProperty[j] = prevValue;
            j--;
          }
        } else if (prevValue !== -1) {
          updatedProperty[i] = prevValue;
        } else {
          updatedProperty[i] = "";
        }
      }
      return updatedProperty;
    };

    const getEmptyState = (state) => {
      if (!state || Object.entries(state).length === 0) {
        let newState = {};
        for (let i = 1; i <= rounds; i++) {
          newState[i] = "";
        }
        return newState;
      } else if (Object.entries(state) < rounds - 1) {
        let newState = { ...state };
        for (let i = Object.entries(state).length + 1; i <= rounds; i++) {
          newState[i] = "";
        }
        return newState;
      } else {
        return state;
      }
    };

    useEffect(() => {
      // onSave(roundIdx, idx);
      console.log("line606", selectedExerciseInfo);
      setIsLoading(true);
      setTimeout(() => {
        setAgain(selectedExerciseInfo?.exercise);
        setIsLoading(false);
      }, 100);
    }, [exercise, selectedExerciseInfo]);

    const setAgain = (exercise) => {
      setRoundIdx(roundIndex);
      setIdx(index);
      setReps(getEmptyState(exercise?.reps));
      setRepsMax(exercise?.repsMax || getEmptyState(exercise?.repsMax));
      setRepsF(exercise?.repsF || getEmptyState(exercise?.repsF));
      setRepsInputType(exercise?.repsMax ? "range" : "perset");

      setWeight(exercise?.weight || getEmptyState(exercise?.weight));
      setWeightMax(exercise?.weightMax || getEmptyState(exercise?.weightMax));
      setWeightF(exercise?.weightF || getEmptyState(exercise?.weightF));
      setWeightUnits(exercise?.weightUnits || "Pounds");
      setWeightInputType(exercise?.weightMax ? "range" : "perset");

      setHeight(exercise?.height || getEmptyState(exercise?.height));
      setHeightMax(exercise?.heightMax || getEmptyState(exercise?.heightMax));
      setHeightF(exercise?.heightF || getEmptyState(exercise?.heightF));
      setHeightUnits(exercise?.heightUnits || "Inches");
      setHeightInputType(exercise?.heightMax ? "range" : "perset");

      setCalorie(exercise?.calorie || getEmptyState(exercise?.calorie));
      setCalorieMax(
        exercise?.calorieMax || getEmptyState(exercise?.calorieMax)
      );
      setCalorieF(exercise?.calorieF || getEmptyState(exercise?.calorieF));
      setCalorieInputType(
        exercise?.calorieMax ? "range" : isEnduranceWorkout ? "fixed" : "perset"
      );

      if (isSwim) {
        setDistance(exercise?.distance || { 1: 25 });
      } else {
        setDistance(exercise?.distance || getEmptyState(exercise?.distance));
      }
      setDistanceMax(
        exercise?.distanceMax || getEmptyState(exercise?.distanceMax)
      );
      setDistanceF(exercise?.distanceF || getEmptyState(exercise?.distanceF));

      setDistanceUnits(
        isSwim ? "Yards" : exercise?.distanceUnits || "Kilometers"
      );
      setDistanceInputType(
        exercise?.distanceMax
          ? "range"
          : isEnduranceWorkout
          ? "fixed"
          : "perset"
      );

      setDuration(exercise?.duration || getEmptyState(exercise?.duration));

      setHeartRate(exercise?.heartRate || getEmptyState(exercise?.heartRate));
      setHeartRateMax(
        exercise?.heartRateMax || getEmptyState(exercise?.heartRateMax)
      );

      setHeartRateInputType(exercise?.heartRateMax ? "range" : "fixed");
      setHeartRateType(exercise?.heartRateType || "above");

      setPace(exercise?.pace || getEmptyState(exercise?.pace));
      setPaceMax(exercise?.paceMax || getEmptyState(exercise?.paceMax));
      setPaceInputType(exercise?.paceMax ? "range" : "fixed");
      setPaceUnit(exercise?.paceUnit || "min/mi");

      setDurationMax(
        exercise?.durationMax || getEmptyState(exercise?.durationMax)
      );
      setDurationF(exercise?.durationF || getEmptyState(exercise?.durationF));
      setDurationInputType(
        exercise?.durationMax
          ? "range"
          : isEnduranceWorkout
          ? "fixed"
          : "perset"
      );
      setStrokeType(
        exercise?.strokeType || getEmptyState(exercise?.strokeType)
      );
      setEquipment(exercise?.equipment || getEmptyState(exercise?.equipment));
      setPower(exercise?.power || getEmptyState(exercise?.power));
      setPowerType(exercise?.powerType || "above");
      setPoolLength(props?.poolLength || 25);

      setPrimaryTarget(exercise?.primaryTarget);
      setSecondaryTarget(exercise?.secondaryTarget);

      setSwimLap(exercise?.swimLap || 1);

      setLapButtonPress(exercise?.lapButtonPress ?? false);

      //add for time - hr min sec
      // setTime(exercise?.time || getEmptyState(exercise?.time));

      setRecordFromUser({
        reps: exercise?.recordFromUser?.reps ? true : false,
        weight: exercise?.recordFromUser?.weight ? true : false,
        height: exercise?.recordFromUser?.height ? true : false,
        distance: exercise?.recordFromUser?.distance ? true : false,
        calorie: exercise?.recordFromUser?.calorie ? true : false,
        duration: exercise?.recordFromUser?.duration ? true : false,
        heartRate: exercise?.recordFromUser?.heartRate ? true : false,
        // time: exercise?.recordFromUser?.time ? true : false,
      });

      setNotes(exercise?.movementInstr || "");

      let properties = { ...showProperties };
      options.map((option) => {
        if (
          (option["value"] == "time" && exercise?.["duration"]) ||
          exercise?.[option["value"]]
        ) {
          properties[option["value"]] = true;
        } else {
          properties[option["value"]] = false;
        }
      });
      if (isSwim) {
        properties["swimLap"] = true;
      }
      Object.keys(properties).forEach(function (key, index) {
        if (properties?.[key] === true) {
          if (key === "distance" || key === "power" || key === "duration") {
            setValue(key);
          }
        }
      });

      setShowProperties(properties);
      setDisabledPropertiesInitially(properties);
    };

    const setDisabledPropertiesInitially = (showProperties) => {
      let disabled = {};
      Object.entries(showProperties).map(([key, value]) => {
        if (
          key === "intensity" ||
          key === "strokeType" ||
          key === "equipment" ||
          key === "primaryTarget" ||
          key === "secondaryTarget"
        ) {
        } else {
          disabled[key] = !value;
        }
      });
      // if all the keys are false for this workout kind, then enable all
      switch (workoutKind) {
        case "run":
          if (
            !showProperties["distance"] &&
            !showProperties["duration"] &&
            !showProperties["heartRate"] &&
            !showProperties["calorie"] &&
            !showProperties["lapButtonPress"]
          ) {
            disabled = {};
          }
          break;
        case "swim":
          if (
            !showProperties["distance"] &&
            !showProperties["duration"] &&
            !showProperties["swimLap"]
          ) {
            disabled = {};
          }
          break;
        case "bike":
          if (
            !showProperties["distance"] &&
            !showProperties["duration"] &&
            !showProperties["calorie"] &&
            !showProperties["heartRate"] &&
            !showProperties["power"] &&
            !showProperties["lapButtonPress"]
          ) {
            disabled = {};
          }
          break;
      }
      setDisabledProperties(disabled);
    };

    useEffect(() => {
      if (reps && rounds !== Object.keys(reps).length) {
        onRoundsChange();
      }
    }, [rounds]);

    if (!selectedExerciseInfo?.exercise || !exercise?.movementName)
      return <EmptyViewAddProerties />;

    const selectShowProperties = (key, selected) => {
      switch (workoutKind) {
        case "run":
          // [distance, duration, heart rate, calorie] --> any one can only be selected at a time
          if (
            key === "distance" ||
            key === "duration" ||
            key === "heartRate" ||
            key === "calorie" ||
            key === "lapButtonPress"
          ) {
            if (selected) {
              let res = {
                ...showProperties,
                distance: false,
                duration: false,
                heartRate: false,
                calorie: false,
                lapButtonPress: false,
                [key]: selected,
              };
              setDisabledProperties({
                distance: true,
                duration: true,
                heartRate: true,
                calorie: true,
                lapButtonPress: true,
                [key]: !selected,
              });
              setShowProperties(res);
            } else {
              setDisabledProperties({});
              setShowProperties({
                ...showProperties,
                [key]: selected,
              });
            }
          } else {
            setShowProperties({
              ...showProperties,
              [key]: selected,
            });
          }
          break;

        case "swim":
          // [distance, duration] --> any one can only be selected at a time
          if (key === "distance" || key === "duration" || key === "swimLap") {
            if (selected) {
              setShowProperties({
                ...showProperties,
                distance: false,
                duration: false,
                swimLap: false,
                [key]: selected,
              });
              setDisabledProperties({
                distance: true,
                duration: true,
                swimLap: true,
                [key]: !selected,
              });
            } else {
              setDisabledProperties({});
              setShowProperties({
                ...showProperties,
                [key]: selected,
              });
            }
          } else {
            setShowProperties({
              ...showProperties,
              [key]: selected,
            });
          }
          break;

        case "bike":
          // [distance, duration, calorie, heart rate, power] --> any one can only be selected at a time
          if (
            key === "distance" ||
            key === "duration" ||
            key === "calorie" ||
            key === "heartRate" ||
            key === "power" ||
            key === "lapButtonPress"
          ) {
            if (selected) {
              setShowProperties({
                ...showProperties,
                distance: false,
                duration: false,
                calorie: false,
                heartRate: false,
                power: false,
                lapButtonPress: false,
                [key]: selected,
              });
              setDisabledProperties({
                distance: true,
                duration: true,
                calorie: true,
                heartRate: true,
                power: true,
                lapButtonPress: true,
                [key]: !selected,
              });
            } else {
              setDisabledProperties({});
              setShowProperties({
                ...showProperties,
                [key]: selected,
              });
            }
          } else {
            setShowProperties({
              ...showProperties,
              [key]: selected,
            });
          }
          break;
      }
    };
    return isLoading ? (
      <div
        style={{
          width: isEnduranceWorkout ? "100vw" : "calc((100vw - 110px)/4)",
          height: "calc(100vh - 110px)",
          borderRadius: "12px",
          position: "fixed",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <SpurfitCircularProgress />
      </div>
    ) : (
      <Paper
        sx={{
          width: isEnduranceWorkout
            ? "calc((100vw - 240px)/3)"
            : "calc((100vw - 110px)/4)",
          height: "calc(100vh - 110px)",
          borderRadius: "12px",
          position: "fixed",
        }}
      >
        <div
          style={{
            height: "56px",
            padding: "0 24px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            borderBottom: " 1px solid #E5E5E5",
          }}
          className="exercise-library-title"
        >
          {exercise?.movementName}{" "}
          {!isEnduranceWorkout && (
            <InfoOutlinedIcon
              style={{ marginLeft: "8px", cursor: "pointer" }}
              onClick={() => {
                setShowVideoDialog(true);
              }}
            />
          )}
        </div>
        <DialogContent
          sx={{
            height: "calc(100vh - 260px)",
            overflowY: "scroll",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "24px",
            }}
          >
            {enabled.includes("Pool Length") && (
              <div>
                <Label className="text-[#242424]" htmlFor="poolLength">
                  Pool Length
                </Label>
                <div className="flex items-center gap-2">
                  <Input
                    id="poolLength"
                    value={props?.poolLength}
                    onChange={(e) => {
                      // if there is - in the input, then don't update the state
                      if (e.target.value.includes("-")) return;

                      // setPoolLength(e.target.value);
                      setPoolLengthInAllRounds(e.target.value);
                    }}
                    type="number"
                    // the number should always be multiple of 25
                    min={1}
                    className={cn(
                      "focus-visible:ring-[#8330e9]",
                      "w-[100px] mt-1 text-font-14"
                    )}
                  />

                  <span className="text-font14 text-gray-500">Yards</span>
                </div>
              </div>
            )}

            {enabled.includes("Swim Lap") && (
              <div
                className="flex flex-col gap-2 "
                style={{
                  pointerEvents: disabledProperties?.swimLap ? "none" : "auto",
                  opacity: disabledProperties?.swimLap ? 0.5 : 1,
                }}
              >
                <PropertyHeader
                  value={showProperties?.swimLap}
                  onClick={() =>
                    selectShowProperties("swimLap", !showProperties?.swimLap)
                  }
                  label={"Swim Lap"}
                />
                {showProperties?.swimLap && (
                  <div className="flex items-center gap-2">
                    <Input
                      value={swimLap}
                      onChange={(e) => {
                        setSwimLap(e.target.value);
                      }}
                      type="number"
                      min={1}
                      className="w-[100px] mt-1 text-font-14 focus-visible:ring-white-pure"
                    />
                    <span className="text-font14 text-gray-500">Laps</span>
                  </div>
                )}
              </div>
            )}

            {enabled.includes("Distance") && (
              <RenderProperty
                label={"Distance"}
                isDisabled={disabledProperties?.distance}
                incrementRound={incrementRound}
                decrementRound={decrementRound}
                rounds={rounds}
                state={distance}
                setState={setDistance}
                maxState={distanceMax}
                setMaxState={setDistanceMax}
                stateF={distanceF}
                setStateF={setDistanceF}
                showF={false}
                inputType={distanceInputType}
                setInputType={setDistanceInputType}
                isEnduranceWorkout={isEnduranceWorkout}
                selectedUnit={distanceUnits}
                setSelectedUnit={setDistanceUnits}
                unitOptions={isSwim ? ["Yards"] : distanceUnitOptions}
                key={"distance"}
                showProperty={showProperties?.distance}
                setShowProperty={(value) => {
                  selectShowProperties("distance", value);
                }}
              />
            )}

            {enabled.includes("Calorie") && (
              <RenderProperty
                label={"Calorie (cal)"}
                isDisabled={disabledProperties?.calorie}
                incrementRound={incrementRound}
                decrementRound={decrementRound}
                rounds={rounds}
                state={calorie}
                setState={setCalorie}
                maxState={calorieMax}
                setMaxState={setCalorieMax}
                stateF={calorieF}
                setStateF={setCalorieF}
                showF={false}
                isEnduranceWorkout={isEnduranceWorkout}
                inputType={calorieInputType}
                setInputType={setCalorieInputType}
                showProperty={showProperties?.calorie}
                key={"calorie"}
                setShowProperty={(value) => {
                  selectShowProperties("calorie", value);
                }}
              />
            )}

            {enabled.includes("Heart rate") && (
              <RenderProperty
                label={"Heart Rate (bpm)"}
                isDisabled={disabledProperties?.heartRate}
                incrementRound={incrementRound}
                decrementRound={decrementRound}
                rounds={rounds}
                state={heartRate}
                setState={setHeartRate}
                maxState={heartRateMax}
                setMaxState={setHeartRateMax}
                stateF={heartRateF}
                setStateF={setHeartRateF}
                showF={false}
                inputType={heartRateInputType}
                setInputType={setHeartRateInputType}
                showProperty={showProperties?.heartRate}
                key={"heartRate"}
                isEnduranceWorkout={true}
                type={heartRateType}
                setType={setHeartRateType}
                setShowProperty={(value) => {
                  selectShowProperties("heartRate", value);
                }}
              />
            )}

            {enabled.includes("Power") && (
              <RenderProperty
                label={"Power"}
                isDisabled={disabledProperties?.power}
                incrementRound={incrementRound}
                decrementRound={decrementRound}
                rounds={rounds}
                state={power}
                setState={setPower}
                showF={false}
                showProperty={showProperties?.power}
                key={"power"}
                type={powerType}
                setType={setPowerType}
                isEnduranceWorkout={true}
                setShowProperty={(value) => {
                  selectShowProperties("power", value);
                }}
              />
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "8px",
                pointerEvents: disabledProperties?.duration ? "none" : "auto",
                opacity: disabledProperties?.duration ? 0.5 : 1,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 4,
                }}
                key={"duration"}
              >
                <CheckBox
                  value={showProperties?.duration}
                  onClick={() =>
                    // setShowProperties({
                    //   ...showProperties,
                    //   duration: !showProperties?.duration,
                    // })
                    selectShowProperties("duration", !showProperties?.duration)
                  }
                />
                <CheckBoxLabel
                  label={`Duration ${format !== "" ? `(${format})` : ""}`}
                />
              </div>
              {showProperties?.duration && (
                <div
                  style={{
                    alignItems: "baseline",
                    display: "flex",
                    width: "100%",
                    overflowX: "scroll",
                    gap: "12px",
                  }}
                >
                  <TimeInput
                    setFormat={setFormat}
                    incrementRound={incrementRound}
                    decrementRound={decrementRound}
                    rounds={rounds}
                    minutes={minutes}
                    seconds={seconds}
                    setMinutes={setMinutes}
                    setSeconds={setSeconds}
                    duration={duration}
                    setDuration={(value) => {
                      console.log("duration value: ", value);
                      setDuration(value);
                    }}
                    index={0}
                    isSingle={isEnduranceWorkout}
                  />
                </div>
              )}
            </div>

            {enabled.includes("Lap Button Press") && (
              <div>
                <PropertyHeader
                  value={showProperties?.lapButtonPress}
                  isDisabled={disabledProperties?.lapButtonPress}
                  onClick={() => {
                    selectShowProperties(
                      "lapButtonPress",
                      !showProperties?.lapButtonPress
                    );
                    setLapButtonPress(!showProperties?.lapButtonPress);
                  }}
                  label={"Lap Button Press"}
                  onlyLabel={true}
                />
              </div>
            )}

            {enabled.includes("Stroke Type") && (
              <div className="flex flex-col gap-2 ">
                <PropertyHeader
                  value={showProperties?.strokeType}
                  onClick={() =>
                    setShowProperties({
                      ...showProperties,
                      strokeType: !showProperties?.strokeType,
                    })
                  }
                  label={"Stroke Type"}
                />
                {showProperties?.strokeType && (
                  <CustomSelect
                    placeholder={"Stroke Type"}
                    triggerClassName={"w-[200px] focus:ring-[#8330e9]"}
                    onValueChange={(value) => {
                      setStrokeType(value);
                    }}
                    value={strokeType}
                    options={[
                      { label: "Any Style", value: "any" },
                      { label: "Backstroke", value: "BACKSTROKE" },
                      { label: "Breaststroke", value: "BREASTSTROKE" },
                      { label: "Fly", value: "BUTTERFLY" },
                      { label: "Free", value: "FREESTYLE" },
                      { label: "Individual Medley", value: "IM" },
                      { label: "Mixed", value: "MIXED" },
                    ]}
                  />
                )}
              </div>
            )}

            {enabled.includes("Equipment") && (
              <div className="flex flex-col gap-2 ">
                <PropertyHeader
                  value={showProperties?.equipment}
                  onClick={() =>
                    setShowProperties({
                      ...showProperties,
                      equipment: !showProperties?.equipment,
                    })
                  }
                  label={"Equipment"}
                />
                {showProperties?.equipment && (
                  <CustomSelect
                    triggerClassName={"w-[200px] focus:ring-[#8330e9]"}
                    placeholder={"Equipment"}
                    onValueChange={(value) => {
                      setEquipment(value);
                    }}
                    value={equipment}
                    options={[
                      { label: "Fins", value: "SWIM_FINS" },
                      { label: "Kickboard", value: "SWIM_KICKBOARD" },
                      { label: "Paddles", value: "SWIM_PADDLES" },
                      { label: "Pull Buoy", value: "SWIM_PULL_BUOY" },
                      { label: "Snorkel", value: "SWIM_SNORKEL" },
                      { label: "None", value: "NONE" },
                    ]}
                  />
                )}
              </div>
            )}

            {enabled.includes("Intensity") && (
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 4,
                  }}
                  key={"intensity"}
                >
                  <CheckBox
                    value={showProperties?.intensity}
                    onClick={() =>
                      setShowProperties({
                        ...showProperties,
                        intensity: !showProperties?.intensity,
                      })
                    }
                  />
                  <CheckBoxLabel label={"Intensity"} />
                </div>
                {showProperties?.intensity && (
                  <AddIntensity
                    prevIntensity={exercise?.intensity}
                    onAdd={(intensity) => {
                      setIntensity(intensity);
                    }}
                  />
                )}
              </div>
            )}

            {enabled?.includes("Primary Target") && (
              <div className="flex flex-col gap-2 ">
                <PropertyHeader
                  value={showProperties?.primaryTarget}
                  onClick={() =>
                    setShowProperties({
                      ...showProperties,
                      primaryTarget: !showProperties?.primaryTarget,
                    })
                  }
                  label={"Primary Target"}
                />
                {showProperties?.primaryTarget && (
                  <Targets
                    prevTargets={exercise?.primaryTarget}
                    onAdd={(targets) => {
                      console.log("endurance: targets: ", targets);
                      setPrimaryTarget(targets);
                    }}
                  />
                )}
              </div>
            )}
            {enabled?.includes("Secondary Target") && (
              <div className="flex flex-col gap-2 ">
                <PropertyHeader
                  value={showProperties?.secondaryTarget}
                  onClick={() =>
                    setShowProperties({
                      ...showProperties,
                      secondaryTarget: !showProperties?.secondaryTarget,
                    })
                  }
                  label={"Secondary Target"}
                />
                {showProperties?.secondaryTarget && (
                  <Targets
                    prevTargets={exercise?.secondaryTarget}
                    onAdd={(targets) => {
                      setSecondaryTarget(targets);
                    }}
                  />
                )}
              </div>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "8px",
              }}
            >
              <div className="property-name">Notes</div>
              <textarea
                style={{
                  width: "100%",
                  fontFamily: "DM Sans",
                  borderRadius: "12px",
                  border: "1px solid #CCC",
                  padding: "14px 16px",
                  resize: "none",
                  outline: "none",
                }}
                placeholder="instructions"
                value={notes}
                onChange={(e) => {
                  setNotes(e.target.value);
                }}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            p: "24px",
          }}
        >
          <button
            style={{
              width: "100%",
            }}
            className="button-new"
            onClick={() => {
              displayAlert({
                message: "Details updated",
                type: "success",
              });
              onSave(roundIdx, idx);
            }}
          >
            Apply Changes
          </button>
        </DialogActions>

        {showVideoDialog && (
          <VideoPlayerModel
            videoInfo={exercisesObj[exercise?.movementID]}
            setOpenDialog={setShowVideoDialog}
            openDialog={showVideoDialog}
            handleUpdateExercise={() => {
              setShowUpdateExerciseModal(true);
            }}
            setSelectedItem={setSelectedItem}
          />
        )}
        {showUpdateExerciseModal && (
          <AddExerciseModal
            open={showUpdateExerciseModal}
            setOpen={setShowUpdateExerciseModal}
            info={{ ...selectedItem, id: undefined }}
            libraryType={"1"}
            handleShowAlert={(error, type) => {
              console.log(type, error);
            }}
            setVideoInfo={setVideoInfo}
            updateExerciseToParticularRound={(e) => {
              // update exercise with the updated exercise
              updateExerciseToParticularRound(roundIndex, index, e);
              dispatch(
                modifyExerciseObject({
                  ...exercisesObj,
                  [e.id]: e,
                })
              );
            }}
          />
        )}
      </Paper>
    );
  }
);

export default AddEnduranceProperties;

const heightUnitOptions = ["Inches", "Feet", "Centimeters", "Meters"];
const weightUnitOptions = [
  "Pounds",
  "Kilograms",
  "RepMaxFraction",
  "BodyWeight",
  "RPE",
];
const distanceUnitOptions = ["Meters", "Kilometers", "Yards", "Miles"];

const paceUnitOptions = ["min/mi", "min/km"];

