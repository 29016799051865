import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
} from "@/components/ui/dialog";
import Delete from "../../assets/workouts/DeleteIcon.svg";
import React, { useContext, useState } from "react";
import FolderToSelectDialog from "./FolderToSelectDialog";
import EditProgramInfo from "./EditProgramInfo";
import DropDownMenu from "./DropDownMenu";
import { ProgramsContext } from "./ProgramsContext";
import { MoreVertical } from "lucide-react";
import CalendarIcon from "@/pages/library/assests/CalendarIcon";
import DumblePurple from "@/pages/library/assests/DumblePurple";
import { Layers } from "lucide-react";
import { CalendarDays } from "lucide-react";
import { Rows3 } from "lucide-react";
import AddProgram from "./AddProgram";
import { Files } from "lucide-react";
import EmptyProgramImg from "@/assets/library/NoProgram.png";
import MenuOptions from "../Workouts/MenuOptions";

function FolderHeader({ name, isAdminFolder }) {
  const [addProgramDialogeOpen, setAddProgramDialogeOpen] = useState(false);
  const {
    unsavedChanges,
    folderSelected,
    coachID,
    setDialogToSelectFolder,
    dialogToSelectFolder,
    selectedProgram,
    folderDelete,
    onProgramSelect,
    createNewFolder,
    selectedTab,
    addProgram,
  } = useContext(ProgramsContext);

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const buttonStyle = {
    fontWeight: 600,
    color: "#FFFFFF",
    borderRadius: "12px",
    padding: "4px 9px",
    cursor: "pointer",
    marginBottom: "7px",
    background: "linear-gradient(90deg, #464feb 10.42%, #8330e9 100%)",
  };

  function onContinue() {
    setShowConfirmDialog(false);
    folderDelete(folderSelected?.id);
  }

  function onDiscard() {
    setShowConfirmDialog(false);
    return;
  }

  return (
    <>
      <div className="w-full border-b">
        <div className="w-full h-[52px] flex items-center justify-between px-3 border-b">
          <div className="flex items-center gap-3">
            <p className="font-DMSans text-font16">{name}</p>

            {!isAdminFolder && folderSelected?.coachID === coachID && (
              <EditProgramInfo
                selectedProgramData={folderSelected}
                isFolder={true}
              />
            )}
          </div>

          {!isAdminFolder && folderSelected?.coachID === coachID && (
            <div>
                <MenuOptions
              isHoriz={false}
              className={"mr-5"}
              options={["Delete Folder"]}
              optionIcons={[<img src={Delete} alt="Delete" />]}
              // menuItemStyles={[{ color: "#FF3A29" }]}
              onOptionClick={(option) => {
                if (option === "Delete Folder") {
                  if (
                    folderSelected?.programs?.length > 0 ||
                    folderSelected?.containers?.length > 0
                  ) {
                    setShowConfirmDialog(true);
                    return;
                  }
                  folderDelete(folderSelected?.id);
                }
              }}
              iconBtnStyles={{
                borderRadius: "8px",
              }}
            />
            </div>
          )}
        </div>

        <div className="w-full flex justify-between  gap-3 px-3 py-8 border-b-[1px] border-[#e2e8f0]  ">
          <div className="flex flex-col w-[50%]">
            <div className="program-conatiner-body-details-card w-full max-h-[83px]">
              <div>
                <p className="program-conatiner-body-details-card-title">
                  Total Files
                </p>
                <p className="program-conatiner-body-details-card-description">
                  {folderSelected?.programs?.length +
                    folderSelected?.containers?.length || 0}
                </p>
              </div>
              <Files className="h-[30px] w-[30px]" />
            </div>
          </div>
          {/* <div className="flex flex-col w-[50%]">
            <div
              style={{ background: "#FAFAFF" }}
              className="program-conatiner-body-details-card max-h-[83px] "
            >
              <div>
                <p className="program-conatiner-body-details-card-title">
                  Total Programs
                </p>
                <p className="program-conatiner-body-details-card-description">
                  {folderSelected?.programs?.length || 0}
                </p>
              </div>
              <CalendarDays className="w-[30px] h-[30px]" />
            </div>
          </div> */}
        </div>
        <div className="flex flex-col gap-3 items-center justify-center mt-[7rem]">
          {/* <Rows3 className="h-[90px] w-[90px] text-[#5e42ea]" /> */}
          <img src={EmptyProgramImg} className="w-[100px] h-[100px]" alt="" />
          <p
            className="text-gradient"
            style={{
              fontSize: "1.2rem",
              fontWeight: "600",
            }}
          >
            Select Any Phase Or Programe To View Or Click To Add Program
          </p>
          <div className="flex gap-10 ">
            <div>
              <AddProgram
                isTemplate={selectedTab === "templates"}
                folderHeaderProgram={true}
                selectedProgramType={"basic"}
                addProgram={addProgram}
                createNewFolder={createNewFolder}
                isCreateOpen={addProgramDialogeOpen}
                setIsCreateOpen={setAddProgramDialogeOpen}
                isIcon={false}
                selectedFolderHeader={folderSelected}
                key={"from_folder"}
              />
            </div>
          </div>
        </div>
      </div>
      <ConfirmDialog
        isOpen={showConfirmDialog}
        onContinue={onContinue}
        onDiscard={onDiscard}
      />
    </>
  );
}

export default FolderHeader;

const ConfirmDialog = ({ isOpen, onDiscard, onContinue }) => {
  return (
    <Dialog open={isOpen}>
      <DialogPortal>
        <DialogOverlay className="bg-black-pure bg-opacity-40 z-[9999]" />
      </DialogPortal>
      <DialogContent
        hideClose={true}
        className="sm:max-w-md z-[9999] font-DMSans outline-none"
      >
        <DialogHeader>
          <DialogTitle className="font-DMSans text-font18">
            Confirm Delete
          </DialogTitle>
        </DialogHeader>

        <div className="">
          <p className="font-DMSans text-font14">
            There are programs in the folders. Are you sure you want to delete
            them?
          </p>
        </div>
        <DialogFooter className={"flex w-full items-center gap-3"}>
          <DialogClose>
            <p
              className="font-DMSans cursor-pointer text-[#ED6C02] text-font14"
              onClick={onDiscard}
            >
              Discard
            </p>
          </DialogClose>

          <DialogClose>
            <button
              className="button-new text-font14 h-[38px] w-[100px]"
              onClick={onContinue}
            >
              Continue
            </button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
