import React, { useState } from 'react';
import {Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis} from "recharts";
import CustomTooltip from "@/components/ClientsView/chatdashbaord/components/CustomTooltip.jsx";
 
import RenderLineChart from './chatdashbaord/RenderLineChart';
import CloseIcon from "@mui/icons-material/Close";
import CustomSelect from '../WorkoutComponent/Endurance/util/CustomSelect';

function StrengthDashboardGraphs({chartView}) {
  const [propertySelect, setPropertySelect] = useState("Total Reps");
 
  const PropertiesOption = [
    { value: "Total Reps", label: "Reps" },
    { value: "Total Weight", label: "Weight" },
  ];
    return (
      <div>
        <div className="chart-scroll-view">
          {/* <div class="text-center">Chart will display here</div> */}

          <div className="chartV1">
            {/* <CloseIcon
                  className="closev1"
                  fontSize="18"
                  color="gray"
                  onClick={() => _closechart(index)}
                /> */}
            <CustomSelect
              className="closev1"
              triggerClassName={"w-[120px] focus:outline-none focus:ring-none"}
              options={PropertiesOption}
              value={propertySelect}
              onValueChange={(value) => setPropertySelect(value)}
            />

            <div className="p-2">
              {/* <RenderBarChart
                data={chartView}
                xKey={"date"}
                yKey={propertySelect}
                title={"Strenght"}
                type={"BAR"}
                label={propertySelect}
              /> */}
              <RenderLineChart
                data={chartView}
                xKey={"date"}
                yKey={propertySelect}
                title={"Strenght"}
                label={propertySelect}
              />
            </div>
          </div>
        </div>
      </div>
    );
}

export default StrengthDashboardGraphs;

 

const RenderBarChart = ({data, xKey, yKey, formatter, title, type, label}) => {
  // if not data then show no data message
  if (!data || data.length === 0) {
    return (
      <div className="flex items-center justify-center h-[320px] w-[290px] m-2 border bg-white-pure rounded-md text-lg text-gray-500">
        <p className={'text-center'}>
          No Data available for the selected date range for {title}.
        </p>
      </div>
    );
  }
  return (
    <BarChart width={300} height={320} data={data}>
      <defs>
        <linearGradient id="colorUv" x1="1" y1="1" x2="0" y2="0">
          <stop offset="30%" stopColor="#464feb" stopOpacity={0.9} />
          <stop offset="95%" stopColor="#8330e9" stopOpacity={0.9} />
        </linearGradient>
      </defs>
      <CartesianGrid strokeDasharray="3 3" fill={"white"} />
      <XAxis dataKey={xKey} fontSize={10} />
      <YAxis
        padding={{ top: 50 }}
        axisLine={false}
        tickLine={false}
        fontSize={12}
        tickFormatter={formatter ?? undefined}
        label={
          label
            ? {
                value: label,
                angle: -90,
                position: "insideLeft",
                offset: 10,
                style: { fill: "black" },
              }
            : undefined
        }
      />
      <Tooltip
        content={<CustomTooltip/>}
      />
      <Legend
        verticalAlign="top"
        height={36}
        payload={[{ value: title, type: type, id: "value" }]}
      />
      <Bar dataKey={yKey} fill="url(#colorUv)" radius={[10, 10, 0, 0]} />
    </BarChart>
  );
}
 












{
  //   chartView.map((view, index) => {
  //     return (
  //       <div className="chartV1">
  //         <CloseIcon
  //           className="closev1"
  //           fontSize="18"
  //           color="gray"
  //           onClick={() => _closechart(index)}
  //         />
  //         <div className="p-2">
  //           {/* {view?.type == "BAR" ? ( */}
  //           <RenderBarChart
  //             data={view}
  //             xKey={"xVal"}
  //             yKey={"value"}
  //             title={"Strenght"}
  //             type={"BAR"}
  //             //   formatter={(value) =>
  //             //     view.title == "Distance by Week"
  //             //       ? formatDistance(value)
  //             //       : value
  //             //   }
  //             label={"Reps"}
  //           />
  //           {/* ) : (
  //                         <RenderLineChart
  //                           data={view.chart.data}
  //                           xKey={"xVal"}
  //                           yKey={"value"}
  //                           title={view.title}
  //                           type={view.type}
  //                           formatter={(value) =>
  //                             view.title == "Longest Workout - Distance"
  //                               ? formatDistance(value)
  //                               : view.title == "Longest Workout - Duration"
  //                               ? secondsToTime(value)
  //                               : value
  //                           }
  //                           label={
  //                             (view.title == view.title) ==
  //                             "Longest Workout - Distance"
  //                               ? "KM"
  //                               : undefined
  //                           }
  //                         />
  //                       )} */}
  //         </div>
  //       </div>
  //     );
  //   });
}