import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, MenuItem, Select } from "@mui/material";
import { Chart } from "chart.js/auto";

import { Line } from "react-chartjs-2";
import moment from "moment";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import "./charts.css";

const StepsChart = ({ isOpen, setIsOpen, data }) => {
  const [startIndex, setStartIndex] = useState(0);
  const [currentMonth, setCurrentMonth] = useState(moment().format("MMM' YY")); // Default to the latest month

  const [oneWeekData, setOneWeekData] = useState([]);
  const [oneMonthData, setOneMonthData] = useState([]);
  const [threeMonthData, setThreeMonthData] = useState([]);
  const [sixMonthData, setSixMonthData] = useState([]);
  const [oneYearData, setOneYearData] = useState([]);
  const [allData, setAllData] = useState([]);

  const [oneWeekLabels, setOneWeekLabels] = useState([]);
  const [oneMonthLabels, setOneMonthLabels] = useState([]);
  const [threeMonthLabels, setThreeMonthLabels] = useState([]);
  const [sixMonthLabels, setSixMonthLabels] = useState([]);
  const [oneYearLabels, setOneYearLabels] = useState([]);
  const [allLabels, setAllLabels] = useState([]);

  const [selectedOption, setSelectedOption] = useState("1 Month");

  const handleDurationChange = (e) => {
    const duration = e.target.value;
    setSelectedOption(duration);
    switch (duration) {
      case "1 Week":
        setChartData((prevChartData) => ({
          ...prevChartData,
          labels: oneWeekLabels,
          datasets: [
            {
              ...prevChartData.datasets[0],
              data: oneWeekData,
            },
          ],
        }));
        break;
      case "1 Month":
        setChartData((prevChartData) => ({
          ...prevChartData,
          labels: oneMonthLabels,
          datasets: [
            {
              ...prevChartData.datasets[0],
              data: oneMonthData,
            },
          ],
        }));
        break;
      case "3 Months":
        setChartData((prevChartData) => ({
          ...prevChartData,
          labels: threeMonthLabels,
          datasets: [
            {
              ...prevChartData.datasets[0],
              data: threeMonthData,
            },
          ],
        }));
        break;
      case "6 Months":
        setChartData((prevChartData) => ({
          ...prevChartData,
          labels: sixMonthLabels,
          datasets: [
            {
              ...prevChartData.datasets[0],
              data: sixMonthData,
            },
          ],
        }));
        break;
      case "1 Year":
        setChartData((prevChartData) => ({
          ...prevChartData,
          labels: oneYearLabels,
          datasets: [
            {
              ...prevChartData.datasets[0],
              data: oneYearData,
            },
          ],
        }));
        break;
      case "All":
        setChartData((prevChartData) => ({
          ...prevChartData,
          labels: allLabels,
          datasets: [
            {
              ...prevChartData.datasets[0],
              data: allData,
            },
          ],
        }));
        break;
      default:
        break;
    }
  };

  const handleShiftLeft = () => {
    const prevMonth = moment(currentMonth, "MMM' YY").subtract(1, "months");
    setCurrentMonth(prevMonth.format("MMM' YY"));
    setStartIndex(0); // Reset startIndex when changing the month
  };

  const handleShiftRight = () => {
    if (currentMonth === moment().format("MMM' YY")) {
      return;
    }
    const nextMonth = moment(currentMonth, "MMM' YY").add(1, "months");
    setCurrentMonth(nextMonth.format("MMM' YY"));
    setStartIndex(0); // Reset startIndex when changing the month
  };

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Steps",
        data: [],
        fill: false,
        borderColor: "#0E9CFF",
        tension: 0.4,
        backgroundColor: "#ffffff",
      },
    ],
  });

  useEffect(() => {
    if (!data || data.length === 0) {
      return;
    }

    data.sort((a, b) => new Date(a.dateAssigned) - new Date(b.dateAssigned));

    const latestOneWeekData = [];
    const latestOneMonthData = [];
    const latestThreeMonthData = [];
    const latestSixMonthData = [];
    const latestOneYearData = [];
    const latestAllData = [];

    const latestOneWeekLabels = [];
    const latestOneMonthLabels = [];
    const latestThreeMonthLabels = [];
    const latestSixMonthLabels = [];
    const latestOneYearLabels = [];
    const latestAllLabels = [];

    data.forEach((item) => {
      if (item?.activitiesFinished?.stepCount) {
        const currentDate = moment(item.dateAssigned);
        const oneWeekAgo = moment().subtract(1, "week");
        const oneMonthAgo = moment().subtract(1, "month");
        const threeMonthsAgo = moment().subtract(3, "months");
        const sixMonthsAgo = moment().subtract(6, "months");
        const oneYearAgo = moment().subtract(1, "year");

        if (currentDate.isAfter(oneWeekAgo)) {
          latestOneWeekData.push(item?.activitiesFinished?.stepCount);
          latestOneWeekLabels.push(currentDate.format("Do MMM' YY"));
        }

        if (currentDate.isAfter(oneMonthAgo)) {
          latestOneMonthData.push(item?.activitiesFinished?.stepCount);
          latestOneMonthLabels.push(currentDate.format("Do MMM' YY"));
        }

        if (currentDate.isAfter(threeMonthsAgo)) {
          latestThreeMonthData.push(item?.activitiesFinished?.stepCount);
          latestThreeMonthLabels.push(currentDate.format("Do MMM' YY"));
        }

        if (currentDate.isAfter(sixMonthsAgo)) {
          latestSixMonthData.push(item?.activitiesFinished?.stepCount);
          latestSixMonthLabels.push(currentDate.format("Do MMM' YY"));
        }

        if (currentDate.isAfter(oneYearAgo)) {
          latestOneYearData.push(item?.activitiesFinished?.stepCount);
          latestOneYearLabels.push(currentDate.format("Do MMM' YY"));
        }

        latestAllData.push(item?.activitiesFinished?.stepCount);
        latestAllLabels.push(currentDate.format("Do MMM' YY"));
      }
    });

    setOneWeekData(latestOneWeekData);
    setOneMonthData(latestOneMonthData);
    setThreeMonthData(latestThreeMonthData);
    setSixMonthData(latestSixMonthData);
    setOneYearData(latestOneYearData);
    setAllData(latestAllData);

    setOneWeekLabels(latestOneWeekLabels);
    setOneMonthLabels(latestOneMonthLabels);
    setThreeMonthLabels(latestThreeMonthLabels);
    setSixMonthLabels(latestSixMonthLabels);
    setOneYearLabels(latestOneYearLabels);
    setAllLabels(latestAllLabels);

    setChartData((prevChartData) => ({
      ...prevChartData,
      labels: latestOneMonthLabels,
      datasets: [
        {
          ...prevChartData.datasets[0],
          data: latestOneMonthData,
        },
      ],
    }));
  }, [data]);

  // useEffect(() => {
  //   if (!data || data.length === 0) {
  //     return;
  //   }

  //   data.sort((a, b) => new Date(a.dateAssigned) - new Date(b.dateAssigned));

  //   const filteredData = data.filter(
  //     (item) =>
  //       item?.activitiesFinished?.stepCount &&
  //       moment(item?.dateAssigned).format("MMM' YY") === currentMonth
  //   );

  //   const dateLabels = filteredData.map((item) =>
  //     moment(item?.dateAssigned).format("Do MMM' YY")
  //   );
  //   const stepsData = filteredData.map(
  //     (item) => item?.activitiesFinished?.stepCount
  //   );

  //   setChartData((prevChartData) => ({
  //     ...prevChartData,
  //     labels: dateLabels,
  //     datasets: [
  //       {
  //         ...prevChartData.datasets[0],
  //         data: stepsData,
  //       },
  //     ],
  //   }));
  // }, [data, currentMonth]);

  const options = {
    scales: {
      y: {
        title: {
          display: false,
          text: "Steps",
        },
        ticks: {
          stepSize: 500,
          // max: 100,
        },
        grid: {
          display: true,
          borderDash: [5, 15],
        },
      },
      x: {
        title: {
          display: false,
          text: "Date",
        },
        type: "category",
        grid: {
          display: false,
        },
        ticks: {
          maxRotation: 0,
          minRotation: 0,
        },
        // pan: {
        //   enabled: true,
        //   mode: "x",
        // },
        // zoom: {
        //   enabled: true,
        //   mode: "x",
        // },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    elements: {
      point: {
        radius: 5,
      },
    },
    borderWidth: 2,
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        width: "600px",
        maxHeight: "600px",
        padding: "0px",
        sx: {
          borderRadius: "20px",
          padding: "0px",
          boxShadow: "0px 16px 48px 0px rgba(0, 0, 0, 0.24)",
        },
      }}
    >
      <DialogContent sx={{ padding: "0px 0px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "12px 24px",
            borderBottom: "1px solid #EBEBEB",
            width: "100%",
          }}
        >
          <p
            style={{
              fontFamily: "DM Sans",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "29px",
            }}
          >
            Steps
          </p>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Select
              variant="standard"
              disableUnderline
              sx={{
                fontFamily: "DM Sans",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "17px",
                color: "#0E9CFF",
                marginRight: "10px",
              }}
              value={selectedOption}
              onChange={handleDurationChange}
            >
              {durationOption.map((option) => (
                <MenuItem value={option}>{option}</MenuItem>
              ))}
            </Select>
            {/* <button
              onClick={handleShiftLeft}
              style={{
                marginRight: "10px",
                cursor: "pointer",
                padding: "5px",
                borderRadius: "48%",
                transition: "background-color 0.3s",
              }}
              className="weights-button left"
            >
              <ChevronLeftIcon />
            </button>
            <button
              onClick={handleShiftRight}
              style={{
                marginRight: "10px",
                cursor: "pointer",
                padding: "5px",
                borderRadius: "48%",
                transition: "background-color 0.3s",
              }}
              className="weights-button right"
            >
              <ChevronRightIcon />
            </button> */}
            <button
              onClick={() => setIsOpen(false)}
              style={{
                cursor: "pointer",
                padding: "5px",
                borderRadius: "48%",
                transition: "background-color 0.3s",
              }}
              className="weights-button close"
            >
              <CloseIcon />
            </button>
          </div>
        </div>
        <div style={{ padding: "20px 15px 20px 30px" }}>
          {/* <p className="font-DMSans font-xs font-semibold">{currentMonth}</p> */}
          <Line data={chartData} options={options} />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default StepsChart;
const durationOption = [
  "1 Week",
  "1 Month",
  "3 Months",
  "6 Months",
  "1 Year",
  "All",
];
