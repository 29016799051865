import { useContext, useState } from "react";
import { ProgramsContext } from "../../pages/library/programsCF/createProgramCF";
import { getCalendarImg } from "../Calendar/functions";
import EditIcon from "../../pages/library/assests/EditIcon";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DumbleWhite from "../../pages/library/assests/DumbleWhite";
import PlusRoundIcon from "../../pages/library/assests/PlusRoundIcon";
import PlusIcon from "../../pages/library/assests/PlusIcon";

const WeekContainer = ({
  workOutDay,
  programDetails,
  dayIndex,
  setShowWorkOutBuilder,
  setWorkOutFor,
  removeDayWorkout,
  mealCard
}) => {
  const {
    showModal,
    left,
    addedWorkouts,
    selectedCard,
    setShowModal,
    setLeft,
    setAddedWorkouts,
    setSelectedCard,
    setUnsavedChanges,
  } = useContext(ProgramsContext);
  const calendarImg =
    selectedCard === workOutDay
      ? getCalendarImg("partial")
      : getCalendarImg("pending");
  let names = [];

  function setModalAlignment(day) {
    if ((day - 1) % 7 >= 4) {
      setLeft(false);
    } else if ((day - 1) % 7 < 4) {
      setLeft(true);
    }
  }

  function handleDragEnd(result) {
    // Check if the drag was successful
    if (!result.destination) {
      return;
    }

    // Get the source and destination indexes
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    // Reorder the workouts in the addedWorkouts object
    const newAddedWorkouts = { ...addedWorkouts };
    const draggedWorkout = newAddedWorkouts[workOutDay][sourceIndex];
    newAddedWorkouts[workOutDay].splice(sourceIndex, 1);
    newAddedWorkouts[workOutDay].splice(destinationIndex, 0, draggedWorkout);

    // Update the state with the new addedWorkouts object
    setAddedWorkouts(newAddedWorkouts);
  }

  const [switchChecked, setSwitchChecked] = useState(
    addedWorkouts?.[workOutDay] && addedWorkouts?.[workOutDay]?.length == 0
  );

  const colors = ["#FFF0E5", "#E8E6FE", "#EEFDE8"];

  // console.log("added workout", addedWorkouts);

  return (
    <div className="week-card-conatiner" onClick={() => {}}>
      <>
        <div className="week-card-conatiner-header">
          <p className="week-card-conatiner-header-title">{dayIndex}</p>
          <>
            {!(addedWorkouts?.[workOutDay]?.length > 0) && !mealCard && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "4px",
                }}
              >
                <label class="switch">
                  <input
                    type="checkbox"
                    checked={switchChecked}
                    onChange={() => setSwitchChecked(!switchChecked)}
                  />
                  <span class="slider round"></span>
                </label>
                <p
                  style={{
                    color: "#424242",
                    fontFamily: "DM Sans",
                    fontSize: "13px",
                    fontWeight: 700,
                    lineHeight: "normal",
                  }}
                >
                  REST
                </p>
              </div>
            )}
            {addedWorkouts?.[workOutDay]?.length > 0 && (
              <div
                onClick={() => {
                  setUnsavedChanges(true);
                  setModalAlignment(workOutDay);
                  setSelectedCard(workOutDay);
                  setShowModal(true);
                }}
              >
                <EditIcon />
              </div>
            )}
          </>
        </div>
        {/* </div> */}
        {names.length == 0 ? (
          <div
            style={{
              overflowY: `${
                addedWorkouts?.[workOutDay]?.length > 9 ? "scroll" : ""
              }`,
              scrollbarWidth: "0",
            }}
            className="week-card-conatiner-draggable"
          >
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="card-list">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{
                      fontSize: "12px",
                      minHeight: "calc(100% + 8px)",
                      display: "flex",
                      justifyContent: "center",
                      gap: "8px",
                      padding: "0 8px 8px 8px",
                      backgroundColor: "transparent",
                    }}
                    className="flex flex-col text-blue-dark font-semibold"
                  >
                    {Object.entries(addedWorkouts?.[workOutDay] || [])?.map(
                      (workout, idx) => {
                        return (
                          <Draggable
                            key={workout[0]}
                            draggableId={workout[0]}
                            index={idx}
                          >
                            {(provided) => (
                              <div
                                {...provided.draggableProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  // width: "13vw",
                                  // height: "4vh",
                                  padding: "12px 8px",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  background: colors[idx % 3],
                                  borderRadius: "8px",
                                  alignItems: "center",
                                }}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                              >
                                <div>
                                  <p className="week-card-conatiner-draggable-text">
                                    {workout?.[1]?.name}
                                  </p>
                                  {workout?.[1]?.runTime > 0 && (
                                    <p className="week-card-conatiner-draggable-description">
                                      {workout?.[1]?.runTime} MINS
                                    </p>
                                  )}
                                </div>
                                <DumbleWhite />
                              </div>
                            )}
                          </Draggable>
                        );
                      }
                    )}
                    {addedWorkouts.length > 10 && (
                      <div style={{ width: "100%" }} className="text-center">
                        more...
                      </div>
                    )}
                    {!(addedWorkouts?.[workOutDay]?.length > 0) &&
                      !switchChecked && (
                        <div
                          onClick={(e) => {
                            setModalAlignment(workOutDay);
                            setSelectedCard(workOutDay);
                            setShowModal(true);
                          }}
                          style={{
                            width: "100%",
                            minHeight: "inherit",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "88px",
                          }}
                        >
                          <PlusRoundIcon />
                        </div>
                      )}
                    {switchChecked && (
                      <div className="week-card-conatiner-draggable-rest">
                        <p>Rest</p>
                      </div>
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        ) : (
          <div
            style={{
              width: "13vw",
              height: "96px",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <PlusIcon />
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default WeekContainer;
