import ExportAsPDF from "@/components/ExportAsPDF";
import React, { useEffect, useState } from "react";
import * as htmlToImage from "html-to-image";
import { Download } from "lucide-react";

import { jsPDF } from "jspdf";

const ExportOneMeal = () => {
  const [recipeItems, setRecipeItems] = useState([]);

  let mealPlanToExport = mealSchedule.mealPlans[0].dayMealPlan.plandetails;
  let meals = mealPlanToExport.meals;
  let dayIndex = mealSchedule.mealPlans[0].dayIdx;
  let mealPlanName = mealSchedule.mealPlans[0].dayMealPlan.reference_name;

  const exportAsPDF = async () => {
    htmlToImage
      .toPng(document.getElementById("myPage"), { quality: 0.95 })
      .then(function (dataUrl) {
        var link = document.createElement("a");
        link.download = "my-image-name.jpeg";
        const pdf = new jsPDF();
        const imgProps = pdf.getImageProperties(dataUrl);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(dataUrl, "PNG", 0, 0, pdfWidth, pdfHeight);
        pdf.save("download.pdf");
      });
  };

  const getRecipeItemsForSingleMeal = (meal) => {
    let recipeItems = new Set();
    meal.foods.forEach((food) => {
      if (food.recipe_id && food.recipeItem) {
        recipeItems.add(JSON.stringify(food.recipeItem));
      }
    });

    setRecipeItems(Array.from(recipeItems, (item) => JSON.parse(item)));
  };

  useEffect(() => {
    getRecipeItemsForSingleMeal(meals[0]);
  }, []);

  return (
    <>
      <div className="flex items-center justify-between w-full bg-indigo-600 text-white-pure p-4">
        <h1 className="font-DMSans text-2xl font-bold">Export Meal</h1>
        <button onClick={exportAsPDF}>
          {" "}
          <Download />
        </button>
      </div>
      <section id="myPage" className="min-h-screen w-full ">
        <div className="px-4 mt-4">
          <p>
            <span className="">
              Day {dayIndex} - {mealPlanName}
            </span>
          </p>
        </div>
        <div className="p-4 bg-white rounded-lg w-1/4 m-auto w-full">
          {meals?.map((meal, index) => {
            let foods = meal.foods;
            return (
              <div className="border w-full rounded-lg overflow-hidden shadow-md mb-4">
                <p className="bg-indigo-700 text-white-pure p-2 text-lg font-semibold">
                  {meal.name}
                </p>
                <div>
                  {foods.map((food, index) => {
                    return (
                      <div className="flex justify-between p-2 border-b border-gray-200">
                        <div>
                          <p className="font-medium">{food.name}</p>
                          <p className="text-sm text-gray-500">
                            {food.measurement_count}{" "}
                            {food.measurement_description} • {food.calories}{" "}
                            calories
                          </p>
                        </div>
                        <div className="flex flex-col items-end">
                          <div className="flex items-center gap-2">
                            {/* display macros */}
                            <p className="font-DMSans font-medium">
                              <span className="text-font14 font-normal">
                                Protein:&nbsp;
                              </span>
                              {food.protein}g
                            </p>
                            •
                            <p className="font-DMSans font-medium">
                              <span className="text-font14 font-normal">
                                Carbs:&nbsp;
                              </span>
                              {food.carbs}g
                            </p>
                            •
                            <p className="font-DMSans font-medium">
                              <span className="text-font14 font-normal">
                                Fat:&nbsp;
                              </span>
                              {food.fat}g
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>

        <div className="space-y-4 p-4">
          <p className="font-DMSans text-2xl font-bold mb-4">Recipes:</p>

          {recipeItems.map((recipe, index) => {
            // render recipe, directions and ingredients
            return (
              <div className="border w-full rounded-lg overflow-hidden shadow-lg mb-4">
                <p className="bg-indigo-700 text-white-pure p-2 text-lg font-semibold">
                  {recipe.recipe_name}
                </p>
                {recipe.recipe_description && (
                  <div className="p-4">
                    <p className="mb-2">{recipe.recipe_description}</p>
                  </div>
                )}
                <div className="p-4 bg-gray-100">
                  <p className="font-semibold mb-2">Ingredients:</p>
                  {recipe.ingredients.map((ingredient, index) => {
                    return (
                      <div className="mb-1">
                        <p>{ingredient.ingredient_description}</p>
                      </div>
                    );
                  })}
                </div>
                <div className="p-4">
                  <p className="font-semibold mb-2">Directions:</p>
                  {recipe.directions.map((direction, index) => {
                    return (
                      <div className="mb-1">
                        <p>
                          {direction.direction_number}.{" "}
                          {direction.direction_description}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default ExportOneMeal;

export const mealSchedule = {
  id: "21501dae-657b-4aa0-803c-af5c899a6bb9",
  name: "New Meal Schedule",
  creationDate: "2024-06-12T09:40:49.000Z",
  tags: null,
  description: "",
  coachID: "PXqGTqMrfblJ",
  duration: null,
  isTemplate: false,
  mealPlans: [
    {
      id: "65f08687-335e-4ef2-9232-598f8fcbfd19",
      mealScheduleID: "21501dae-657b-4aa0-803c-af5c899a6bb9",
      dayMealPlanID: "5691a323-10394-4415-a11112-10112b031067b1011",
      dayIdx: 1,
      dayMealPlan: {
        id: "5691a323-10394-4415-a11112-10112b031067b1011",
        reference_name: "for day 1",
        display_name: "",
        diet_type: null,
        meal_plan_type: "FoodBased",
        description: "",
        image: "",
        plandetails: {
          meals: [
            {
              name: "Breakfast",
              foods: [
                {
                  fat: "17.57",
                  name: "Bacon and Egg Fat Bomb",
                  size: "40.000",
                  unit: "g",
                  carbs: "0.79",
                  global: true,
                  food_id: "16661845",
                  protein: "6.71",
                  calories: "188",
                  measurement_count: 1,
                  measurement_description: "piece",
                },
              ],
              macros: {
                fat: 17.57,
                carbs: 0.79,
                protein: 6.71,
              },
              calories: 188,
              optionals: [
                [
                  {
                    fat: "2.16",
                    name: "1% Fat Buttermilk",
                    size: 245,
                    unit: "g",
                    carbs: "11.74",
                    global: true,
                    food_id: "815",
                    protein: "8.11",
                    calories: "98",
                    measurement_count: 1,
                    measurement_description: "cup",
                  },
                  {
                    fat: "2.16",
                    name: "1% Fat Buttermilk",
                    size: 245,
                    unit: "g",
                    carbs: "11.74",
                    global: true,
                    food_id: "815",
                    protein: "8.11",
                    calories: "98",
                    measurement_count: 1,
                    measurement_description: "cup",
                  },
                ],
                [
                  {
                    fat: "0.33",
                    name: "Baby Food Beef and Egg Noodles",
                    size: 16,
                    unit: "g",
                    carbs: "1.25",
                    global: true,
                    food_id: "2860",
                    protein: "0.40",
                    calories: "10",
                    measurement_count: 1,
                    measurement_description: "tablespoon",
                  },
                  {
                    fat: "24.14",
                    name: "Bacon and Egg Croissant Sandwich",
                    size: 113,
                    unit: "g",
                    carbs: "29.82",
                    global: true,
                    food_id: "4979",
                    protein: "14.63",
                    calories: "398",
                    measurement_count: 1,
                    measurement_description: "croissant",
                  },
                ],
              ],
            },
            {
              name: "Lunch",
              foods: [
                {
                  fat: "2.16",
                  name: "1% Fat Buttermilk",
                  size: "245.000",
                  unit: "g",
                  carbs: "11.74",
                  global: true,
                  food_id: "815",
                  protein: "8.11",
                  calories: "98",
                  measurement_count: 1,
                  measurement_description: "cup",
                },
                {
                  fat: "3.71",
                  name: "Teriyaki Chicken with Rice and Vegetable (Diet Frozen Meal)",
                  size: "312.000",
                  unit: "g",
                  carbs: "48.58",
                  global: true,
                  food_id: "2918",
                  protein: "21.90",
                  calories: "324",
                  measurement_count: 1,
                  measurement_description: "serving (312g)",
                },
                {
                  fat: "0.17",
                  name: "Baby Food Rice and Vegetables Turkey",
                  size: "16.000",
                  unit: "g",
                  carbs: "1.40",
                  global: true,
                  food_id: "2871",
                  protein: "0.37",
                  calories: "9",
                  measurement_count: 1,
                  measurement_description: "tablespoon",
                },
              ],
              macros: {
                fat: 6.04,
                carbs: 61.72,
                protein: 30.38,
              },
              calories: 431,
              optionals: [
                [
                  {
                    fat: "2.16",
                    name: "1% Fat Buttermilk",
                    size: 245,
                    unit: "g",
                    carbs: "11.74",
                    global: true,
                    food_id: "815",
                    protein: "8.11",
                    calories: "98",
                    measurement_count: 1,
                    measurement_description: "cup",
                  },
                  {
                    fat: "2.16",
                    name: "1% Fat Buttermilk",
                    size: 245,
                    unit: "g",
                    carbs: "11.74",
                    global: true,
                    food_id: "815",
                    protein: "8.11",
                    calories: "98",
                    measurement_count: 1,
                    measurement_description: "cup",
                  },
                  {
                    fat: "0.25",
                    name: "100% Fruit Juice Blend (with Added Vitamin C)",
                    size: 250,
                    unit: "g",
                    carbs: "32.32",
                    global: true,
                    food_id: "5586",
                    protein: "0.85",
                    calories: "132",
                    measurement_count: 1,
                    measurement_description: "cup",
                  },
                ],
              ],
            },
            {
              name: "Evening Snack",
              foods: [
                {
                  fat: "2.36",
                  name: "Samosa Pea Soup",
                  size: "259.550",
                  unit: "g",
                  carbs: "21.92",
                  image:
                    "https://m.ftscrt.com/static/recipe/b2c4e8e3-8924-4220-999f-6eabdafd17e7.jpg",
                  global: true,
                  protein: "7.72",
                  calories: "135",
                  recipe_id: "1476702",
                  recipeItem: {
                    rating: "5",
                    recipe_id: "1476702",
                    directions: [
                      {
                        direction_number: "1",
                        direction_description:
                          "Heat vegetable oil in a pot over medium heat.  Add onions and saute for 4 minutes or until softened.",
                      },
                      {
                        direction_number: "2",
                        direction_description:
                          "Add ginger, garlic, curry paste and fennel seeds and saute for 1 minute or until fragrant.",
                      },
                      {
                        direction_number: "3",
                        direction_description:
                          "Add split peas and saute another minute, then add stock and turmeric and bring to a boil. Simmer gently over low heat for 1 hour or until split peas are soft.",
                      },
                      {
                        direction_number: "4",
                        direction_description:
                          "Transfer soup to a blender or use a stick blender and process until smooth. Add peas and carrots. Reheat when needed.",
                      },
                    ],
                    recipe_url:
                      "https://www.fatsecret.com/recipes/samosa-pea-soup/Default.aspx",
                    ingredients: [
                      {
                        food_id: "36474",
                        food_name: "Peas and Carrots (Frozen)",
                        serving_id: "34357",
                        ingredient_url:
                          "https://www.fatsecret.com/calories-nutrition/usda/peas-and-carrots-(frozen)?portionid=34357&portionamount=1.000",
                        number_of_units: "1.000",
                        ingredient_description: "1 cup peas and carrots",
                        measurement_description: "cup",
                      },
                      {
                        food_id: "33904",
                        food_name: "Turmeric (Ground)",
                        serving_id: "29644",
                        ingredient_url:
                          "https://www.fatsecret.com/calories-nutrition/usda/turmeric-(ground)?portionid=29644&portionamount=1.000",
                        number_of_units: "1.000",
                        ingredient_description: "1 tsp turmeric",
                        measurement_description: "tsp",
                      },
                      {
                        food_id: "35001",
                        food_name: "Chicken Stock",
                        serving_id: "31988",
                        ingredient_url:
                          "https://www.fatsecret.com/calories-nutrition/usda/chicken-stock?portionid=31988&portionamount=6.000",
                        number_of_units: "6.000",
                        ingredient_description: "6 cups chicken stock",
                        measurement_description: "cup (8 fl oz)",
                      },
                      {
                        food_id: "38295",
                        food_name: "Split Peas (Mature Seeds)",
                        serving_id: "37643",
                        ingredient_url:
                          "https://www.fatsecret.com/calories-nutrition/usda/split-peas-(mature-seeds)?portionid=37643&portionamount=1.000",
                        number_of_units: "1.000",
                        ingredient_description:
                          "1 cup yellow split peas, rinsed",
                        measurement_description: "cup",
                      },
                      {
                        food_id: "33880",
                        food_name: "Fennel Seed",
                        serving_id: "29591",
                        ingredient_url:
                          "https://www.fatsecret.com/calories-nutrition/usda/fennel-seed?portionid=29591&portionamount=0.500",
                        number_of_units: "0.500",
                        ingredient_description: "1/2 tsp fennel seed, cracked",
                        measurement_description: "tsp",
                      },
                      {
                        food_id: "51978",
                        food_name: "Red Curry Paste",
                        serving_id: "0",
                        ingredient_url:
                          "https://www.fatsecret.com/calories-nutrition/thai-kitchen/red-curry-paste?portionid=0&portionamount=1.000",
                        number_of_units: "1.000",
                        ingredient_description: "1 tbsp mild curry paste",
                        measurement_description: "serving",
                      },
                      {
                        food_id: "36383",
                        food_name: "Garlic",
                        serving_id: "34169",
                        ingredient_url:
                          "https://www.fatsecret.com/calories-nutrition/usda/garlic?portionid=34169&portionamount=2.000",
                        number_of_units: "2.000",
                        ingredient_description: "2 tsps garlic, chopped",
                        measurement_description: "tsp",
                      },
                      {
                        food_id: "36384",
                        food_name: "Ginger",
                        serving_id: "34172",
                        ingredient_url:
                          "https://www.fatsecret.com/calories-nutrition/usda/ginger?portionid=34172&portionamount=2.000",
                        number_of_units: "2.000",
                        ingredient_description: "2 tsps ginger, grated",
                        measurement_description: "tsp",
                      },
                      {
                        food_id: "36442",
                        food_name: "Onions",
                        serving_id: "34288",
                        ingredient_url:
                          "https://www.fatsecret.com/calories-nutrition/usda/onions?portionid=34288&portionamount=1.500",
                        number_of_units: "1.500",
                        ingredient_description: "1 1/2 cups onion, chopped",
                        measurement_description: "cup, chopped",
                      },
                      {
                        food_id: "7048",
                        food_name: "Vegetable Oil",
                        serving_id: "26998",
                        ingredient_url:
                          "https://www.fatsecret.com/calories-nutrition/generic/vegetable-oil?portionid=26998&portionamount=1.000",
                        number_of_units: "1.000",
                        ingredient_description: "1 tbsp vegetable oil",
                        measurement_description: "tablespoon",
                      },
                    ],
                    recipe_name: "Samosa Pea Soup",
                    recipe_types: ["Soup", "Lunch"],
                    recipe_images: [
                      "https://m.ftscrt.com/static/recipe/b2c4e8e3-8924-4220-999f-6eabdafd17e7.jpg",
                    ],
                    serving_sizes: {
                      fat: "2.36",
                      iron: "9",
                      fiber: "7.4",
                      sugar: "3.40",
                      sodium: "662",
                      calcium: "3",
                      protein: "7.72",
                      calories: "135",
                      potassium: "351",
                      trans_fat: "0",
                      vitamin_a: "10",
                      vitamin_c: "5",
                      cholesterol: "0",
                      carbohydrate: "21.92",
                      serving_size: "1 serving",
                      saturated_fat: "0.342",
                      monounsaturated_fat: "0.756",
                      polyunsaturated_fat: "1.100",
                    },
                    cooking_time_min: "60",
                    grams_per_portion: "259.550",
                    recipe_categories: null,
                    number_of_servings: "8",
                    recipe_description:
                      "A tasty pea soup with curry and vegetables.",
                    preparation_time_min: "10",
                  },
                  measurement_count: 1,
                  measurement_description: "serving",
                },
              ],
              macros: {
                fat: 2.36,
                carbs: 21.92,
                protein: 7.72,
              },
              calories: 135,
            },
            {
              name: "Dinner",
              foods: [
                {
                  fat: "15",
                  name: "Paneer and Potato Curry",
                  unit: "g",
                  carbs: "20",
                  global: true,
                  protein: "10",
                  calories: "250",
                  recipe_id: "41",
                  recipeItem: {
                    id: 41,
                    directions: [
                      {
                        direction_number: "1",
                        direction_description:
                          "Heat oil in a pan over medium heat. Add cumin seeds and let them sizzle.",
                      },
                      {
                        direction_number: "2",
                        direction_description:
                          "Add chopped onions and sauté until they turn golden brown.",
                      },
                      {
                        direction_number: "3",
                        direction_description:
                          "Add minced garlic and grated ginger, and cook for another minute until fragrant.",
                      },
                      {
                        direction_number: "4",
                        direction_description:
                          "Add pureed tomatoes and cook until the oil separates from the mixture.",
                      },
                      {
                        direction_number: "5",
                        direction_description:
                          "Stir in turmeric powder, red chili powder, and salt.",
                      },
                      {
                        direction_number: "6",
                        direction_description:
                          "Add diced potatoes and enough water to cover them. Cover the pan and simmer until the potatoes are cooked through.",
                      },
                      {
                        direction_number: "7",
                        direction_description:
                          "Gently add the paneer cubes and simmer for a few more minutes until heated through.",
                      },
                      {
                        direction_number: "8",
                        direction_description:
                          "Sprinkle garam masala and give it a final stir.",
                      },
                      {
                        direction_number: "9",
                        direction_description:
                          "Garnish with fresh cilantro leaves before serving.",
                      },
                      {
                        direction_number: "10",
                        direction_description:
                          "Serve the paneer and potato curry hot with rice or roti.",
                      },
                    ],
                    ingredients: [
                      {
                        ingredient_description: "200g paneer, cubed",
                      },
                      {
                        ingredient_description:
                          "2 medium potatoes, peeled and diced",
                      },
                      {
                        ingredient_description: "1 large onion, finely chopped",
                      },
                      {
                        ingredient_description: "2 tomatoes, pureed",
                      },
                      {
                        ingredient_description: "2 cloves garlic, minced",
                      },
                      {
                        ingredient_description: "1 teaspoon ginger, grated",
                      },
                      {
                        ingredient_description: "1 teaspoon cumin seeds",
                      },
                      {
                        ingredient_description: "1 teaspoon turmeric powder",
                      },
                      {
                        ingredient_description:
                          "1 teaspoon red chili powder (adjust to taste)",
                      },
                      {
                        ingredient_description: "1 teaspoon garam masala",
                      },
                      {
                        ingredient_description:
                          "2 tablespoons oil (such as vegetable or canola oil)",
                      },
                      {
                        ingredient_description: "Salt to taste",
                      },
                      {
                        ingredient_description:
                          "Fresh cilantro leaves for garnish",
                      },
                    ],
                    recipe_name: "Paneer and Potato Curry",
                    serving_sizes: {
                      fat: "15",
                      fiber: "4",
                      protein: "10",
                      calories: "250",
                      carbohydrate: "20",
                      serving_size: "1 serving",
                    },
                    number_of_servings: 4,
                  },
                  measurement_count: 1,
                  measurement_description: "serving",
                },
                {
                  fat: "10",
                  name: "Kidney Bean Curry",
                  unit: "g",
                  carbs: "30",
                  global: true,
                  protein: "12",
                  calories: "250",
                  recipe_id: "21",
                  recipeItem: {
                    id: 21,
                    directions: [
                      {
                        direction_number: "1",
                        direction_description:
                          "Heat oil in a pan over medium heat. Add cumin seeds and let them sizzle.",
                      },
                      {
                        direction_number: "2",
                        direction_description:
                          "Add minced garlic and ginger, and sauté for a minute until fragrant.",
                      },
                      {
                        direction_number: "3",
                        direction_description:
                          "Add chopped onions and cook until they turn golden brown.",
                      },
                      {
                        direction_number: "4",
                        direction_description:
                          "Add chopped tomatoes and cook until they soften and release their juices.",
                      },
                      {
                        direction_number: "5",
                        direction_description:
                          "Stir in turmeric powder, red chili powder, and salt.",
                      },
                      {
                        direction_number: "6",
                        direction_description:
                          "Add cooked kidney beans and mix well to coat them with the spices and vegetables.",
                      },
                      {
                        direction_number: "7",
                        direction_description:
                          "Cover the pan and let the curry simmer for about 10-15 minutes, stirring occasionally.",
                      },
                      {
                        direction_number: "8",
                        direction_description:
                          "Sprinkle garam masala and give it a final stir.",
                      },
                      {
                        direction_number: "9",
                        direction_description:
                          "Garnish with fresh cilantro leaves before serving.",
                      },
                      {
                        direction_number: "10",
                        direction_description:
                          "Serve the kidney bean curry hot with rice or naan bread for a hearty and flavorful meal.",
                      },
                    ],
                    ingredients: [
                      {
                        ingredient_description: "2 cups cooked kidney beans",
                      },
                      {
                        ingredient_description: "1 large onion, finely chopped",
                      },
                      {
                        ingredient_description: "2 medium tomatoes, chopped",
                      },
                      {
                        ingredient_description: "2 cloves garlic, minced",
                      },
                      {
                        ingredient_description: "1 teaspoon ginger, minced",
                      },
                      {
                        ingredient_description: "1 teaspoon cumin seeds",
                      },
                      {
                        ingredient_description: "1 teaspoon turmeric powder",
                      },
                      {
                        ingredient_description:
                          "1 teaspoon red chili powder (adjust to taste)",
                      },
                      {
                        ingredient_description: "1 teaspoon garam masala",
                      },
                      {
                        ingredient_description:
                          "2 tablespoons oil (such as vegetable or canola oil)",
                      },
                      {
                        ingredient_description: "Salt to taste",
                      },
                      {
                        ingredient_description:
                          "Fresh cilantro leaves for garnish",
                      },
                    ],
                    recipe_name: "Kidney Bean Curry",
                    serving_sizes: {
                      fat: "10",
                      fiber: "8",
                      protein: "12",
                      calories: "250",
                      carbohydrate: "30",
                      serving_size: "1 serving",
                    },
                    number_of_servings: 4,
                  },
                  measurement_count: 1,
                  measurement_description: "serving",
                },
                {
                  fat: "10",
                  name: "Banana Pancakes",
                  unit: "g",
                  carbs: "25",
                  global: true,
                  protein: "8",
                  calories: "200",
                  recipe_id: "522",
                  recipeItem: {
                    id: 522,
                    directions: [
                      {
                        direction_number: "1",
                        direction_description:
                          "In a bowl, mash the ripe banana until smooth.",
                      },
                      {
                        direction_number: "2",
                        direction_description:
                          "Add eggs, vanilla extract, and cinnamon to the mashed banana. Mix well.",
                      },
                      {
                        direction_number: "3",
                        direction_description:
                          "If desired, add oats to the batter and stir until combined.",
                      },
                      {
                        direction_number: "4",
                        direction_description:
                          "Heat oil or butter in a non-stick pan over medium heat.",
                      },
                      {
                        direction_number: "5",
                        direction_description:
                          "Pour about 1/4 cup of the batter onto the pan for each pancake.",
                      },
                      {
                        direction_number: "6",
                        direction_description:
                          "Cook for 2-3 minutes on each side or until golden brown.",
                      },
                      {
                        direction_number: "7",
                        direction_description:
                          "Serve the banana pancakes warm with your favorite toppings such as maple syrup, fresh fruits, or yogurt.",
                      },
                    ],
                    ingredients: [
                      {
                        ingredient_description: "1 ripe banana",
                      },
                      {
                        ingredient_description: "2 eggs",
                      },
                      {
                        ingredient_description: "1/4 teaspoon vanilla extract",
                      },
                      {
                        ingredient_description: "1/4 teaspoon cinnamon",
                      },
                      {
                        ingredient_description: "1/4 cup oats (optional)",
                      },
                      {
                        ingredient_description:
                          "1 tablespoon oil or butter for cooking",
                      },
                    ],
                    recipe_name: "Banana Pancakes",
                    serving_sizes: {
                      fat: "10",
                      fiber: "3",
                      protein: "8",
                      calories: "200",
                      carbohydrate: "25",
                      serving_size: "2 pancakes",
                    },
                    number_of_servings: 2,
                  },
                  measurement_count: 2,
                  measurement_description: "pancakes",
                },
              ],
              macros: {
                fat: 35,
                carbs: 75,
                protein: 30,
              },
              calories: 700,
            },
          ],
          macros: {
            fat: 60.97,
            carbs: 159.43,
            protein: 74.81,
          },
          calories: 1454,
        },
        coachId: "PXqGTqMrfblJ",
        isTemplate: false,
        isCoachMealPlan: false,
      },
    },
    {
      id: "ff1484c0-80e6-4930-bd94-35eeb2cbfef8",
      mealScheduleID: "21501dae-657b-4aa0-803c-af5c899a6bb9",
      dayMealPlanID: "1167136775-01144-4568-b872-121311321314a01",
      dayIdx: 2,
      dayMealPlan: {
        id: "1167136775-01144-4568-b872-121311321314a01",
        reference_name: "10001 - Ingredients test",
        display_name: "",
        diet_type: "Non-Veg",
        meal_plan_type: "FoodBased",
        description: "Checking for instructions",
        image: "",
        plandetails: {
          meals: [
            {
              name: "Breakfast",
              foods: [
                {
                  fat: "2.40",
                  name: "1% Fat Milk (Calcium Fortified)",
                  size: "247.000",
                  unit: "g",
                  carbs: "12.33",
                  global: true,
                  food_id: "custom_c54e67ba-0787-4a9d-9ffd-d145dfa7e6fe",
                  protein: "8.32",
                  calories: "104.090897",
                  measurement_count: 1,
                  measurement_description: "cup",
                },
                {
                  fat: "22.39",
                  name: '"Spaghetti" Casserole',
                  size: "221.374",
                  unit: "g",
                  carbs: "6.06",
                  image:
                    "https://m.ftscrt.com/static/recipe/2f693abf-fa21-4565-98bf-3fce77d5402f.jpg",
                  global: true,
                  protein: "29.22",
                  calories: "339",
                  recipe_id: "9924001",
                  ingredients: [
                    {
                      food_id: "33892",
                      food_name: "Black Pepper",
                      serving_id: "29615",
                      ingredient_url:
                        "https://www.fatsecret.com/calories-nutrition/usda/black-pepper?portionid=29615&portionamount=0.250",
                      number_of_units: "0.250",
                      ingredient_description: "1/4 tsp black pepper",
                      measurement_description: "tsp",
                    },
                    {
                      food_id: "102340",
                      food_name: "Extra Virgin Olive Oil",
                      serving_id: "0",
                      ingredient_url:
                        "https://www.fatsecret.com/calories-nutrition/great-value/extra-virgin-olive-oil?portionid=0&portionamount=3.000",
                      number_of_units: "3.000",
                      ingredient_description: "3 tbsps extra virgin olive oil",
                      measurement_description: "serving",
                    },
                    {
                      food_id: "44150",
                      food_name: "Mushrooms",
                      serving_id: "0",
                      ingredient_url:
                        "https://www.fatsecret.com/calories-nutrition/wegmans/mushrooms?portionid=0&portionamount=1.000",
                      number_of_units: "1.000",
                      ingredient_description: "5 medium mushrooms",
                      measurement_description: "serving",
                    },
                    {
                      food_id: "6288738",
                      food_name: "Zucchini",
                      serving_id: "0",
                      ingredient_url:
                        "https://www.fatsecret.com/calories-nutrition/heb/zucchini?portionid=0&portionamount=2.000",
                      number_of_units: "2.000",
                      ingredient_description: "2 zucchinis",
                      measurement_description: "serving",
                    },
                    {
                      food_id: "29387",
                      food_name: "Marinara Sauce",
                      serving_id: "0",
                      ingredient_url:
                        "https://www.fatsecret.com/calories-nutrition/raos-homemade/marinara-sauce?portionid=0&portionamount=3.000",
                      number_of_units: "3.000",
                      ingredient_description: "1 1/2 cups marinara sauce",
                      measurement_description: "serving",
                    },
                    {
                      food_id: "48838",
                      food_name: "Sea Salt",
                      serving_id: "0",
                      ingredient_url:
                        "https://www.fatsecret.com/calories-nutrition/morton/sea-salt?portionid=0&portionamount=2.000",
                      number_of_units: "2.000",
                      ingredient_description: "1/2 tsp sea salt",
                      measurement_description: "serving",
                    },
                    {
                      food_id: "555939",
                      food_name:
                        "Organic Grass Fed Ground Beef (93% Lean / 7% Fat)",
                      serving_id: "0",
                      ingredient_url:
                        "https://www.fatsecret.com/calories-nutrition/wegmans/organic-grass-fed-ground-beef-(93%25-lean---7%25-fat)?portionid=0&portionamount=5.330",
                      number_of_units: "5.330",
                      ingredient_description: "1 lb ground beef 93/7",
                      measurement_description: "serving",
                    },
                    {
                      food_id: "2061826",
                      food_name: "Tofu Shirataki Spaghetti",
                      serving_id: "0",
                      ingredient_url:
                        "https://www.fatsecret.com/calories-nutrition/570259_5473928_2111241046_Tofu+Shirataki+Spaghetti?portionid=0&portionamount=2.000",
                      number_of_units: "2.000",
                      ingredient_description: "8 oz tofu shirataki spaghetti",
                      measurement_description: "serving",
                    },
                    {
                      food_id: "7277114",
                      food_name: "Shredded Mozzarella",
                      serving_id: "0",
                      ingredient_url:
                        "https://www.fatsecret.com/calories-nutrition/andrew-and-everett/shredded-mozzarella?portionid=0&portionamount=6.000",
                      number_of_units: "6.000",
                      ingredient_description: "1 1/2 cups shredded mozzarella",
                      measurement_description: "serving",
                    },
                    {
                      food_id: "2428262",
                      food_name: "Chopped Garlic",
                      serving_id: "0",
                      ingredient_url:
                        "https://www.fatsecret.com/calories-nutrition/spice-world/chopped-garlic?portionid=0&portionamount=3.000",
                      number_of_units: "3.000",
                      ingredient_description: "1 tbsp garlic, chopped",
                      measurement_description: "serving",
                    },
                  ],
                  measurement_count: 1,
                  measurement_description: "ml",
                },
              ],
              macros: {
                fat: 24.79,
                carbs: 18.39,
                protein: 37.54,
              },
              calories: 443.090897,
              optionals: [
                [
                  {
                    fat: "2.16",
                    name: "1% Fat Buttermilk",
                    size: 245,
                    unit: "g",
                    carbs: "11.74",
                    global: true,
                    food_id: "815",
                    protein: "8.11",
                    calories: "98",
                    measurement_count: 1,
                    measurement_description: "cup",
                  },
                  {
                    fat: "2.37",
                    name: "1% Fat Milk",
                    size: 244,
                    unit: "g",
                    carbs: "12.18",
                    global: true,
                    food_id: "803",
                    protein: "8.22",
                    calories: "102",
                    measurement_count: 1,
                    measurement_description: "cup",
                  },
                  {
                    fat: "0.25",
                    name: "100% Fruit Juice Blend (with Added Vitamin C)",
                    size: 250,
                    unit: "g",
                    carbs: "32.32",
                    global: true,
                    food_id: "5586",
                    protein: "0.85",
                    calories: "132",
                    measurement_count: 1,
                    measurement_description: "cup",
                  },
                ],
                [
                  {
                    fat: "0.25",
                    name: "100% Fruit Juice Blend (with Added Vitamin C)",
                    size: 250,
                    unit: "g",
                    carbs: "32.32",
                    global: true,
                    food_id: "5586",
                    protein: "0.85",
                    calories: "132",
                    measurement_count: 1,
                    measurement_description: "cup",
                  },
                  {
                    fat: "2.40",
                    name: "1% Fat Milk (Calcium Fortified)",
                    size: 247,
                    unit: "g",
                    carbs: "12.33",
                    global: true,
                    food_id: "797",
                    protein: "8.32",
                    calories: "104",
                    measurement_count: 1,
                    measurement_description: "cup",
                  },
                ],
              ],
            },
            {
              name: "Morning Snack",
              foods: [],
              macros: {
                fat: 0,
                carbs: 0,
                protein: 0,
              },
              calories: 0,
              optional: [],
            },
            {
              name: "Dinner",
              foods: [
                {
                  fat: "0.56",
                  name: "100% Wild Rice (Fat Not Added in Cooking)",
                  size: "164.000",
                  unit: "g",
                  carbs: "34.69",
                  global: true,
                  food_id: "4519",
                  protein: "6.48",
                  calories: "164",
                  measurement_count: 1,
                  measurement_description: "cup, cooked",
                },
                {
                  fat: "0.17",
                  name: "Baby Food Rice and Vegetables Turkey",
                  size: "16.000",
                  unit: "g",
                  carbs: "1.40",
                  global: true,
                  food_id: "2871",
                  protein: "0.37",
                  calories: "9",
                  measurement_count: 1,
                  measurement_description: "tablespoon",
                },
              ],
              macros: {
                fat: 0.7300000000000001,
                carbs: 36.09,
                protein: 6.8500000000000005,
              },
              calories: 173,
              optionals: [
                [
                  {
                    fat: "100",
                    name: "Soya",
                    unit: "gm",
                    carbs: "1010",
                    global: true,
                    food_id: "230",
                    protein: "100",
                    calories: "5340",
                    measurement_description: "gm",
                  },
                  {
                    fat: "5",
                    name: "egg",
                    size: 1,
                    unit: "gm",
                    carbs: "12",
                    global: true,
                    food_id: "203",
                    protein: "12",
                    calories: "141",
                    measurement_count: 1,
                    measurement_description: "gm",
                  },
                  {
                    fat: "1",
                    name: "Tea",
                    size: 1,
                    unit: "quantity",
                    carbs: "1",
                    global: true,
                    food_id: "232",
                    protein: "1",
                    calories: "17",
                    measurement_count: 1,
                    measurement_description: "quantity",
                  },
                ],
              ],
            },
          ],
          macros: {
            fat: 25.52,
            carbs: 54.48,
            protein: 44.39,
          },
          calories: 616.090897,
        },
        coachId: "PXqGTqMrfblJ",
        isTemplate: false,
        isCoachMealPlan: false,
      },
    },
    {
      id: "30149e44-fc49-498a-a5e8-7b4c6e975821",
      mealScheduleID: "21501dae-657b-4aa0-803c-af5c899a6bb9",
      dayMealPlanID: "b12b528610-101106-41012b-91233-2213a13256912a11",
      dayIdx: 3,
      dayMealPlan: {
        id: "b12b528610-101106-41012b-91233-2213a13256912a11",
        reference_name: "New meal her",
        display_name: "",
        diet_type: null,
        meal_plan_type: "FoodBased",
        description: "",
        image: "",
        plandetails: {
          meals: [
            {
              name: "Breakfast",
              foods: [
                {
                  fat: "6.86",
                  name: '"Breaded" Chicken Shake & Bake',
                  size: "55.875",
                  unit: "g",
                  carbs: "0",
                  image:
                    "https://m.ftscrt.com/static/recipe/eff54290-ce75-4c2f-82c0-3a887c035314.jpg",
                  global: true,
                  protein: "15.12",
                  calories: "128",
                  recipe_id: "10454341",
                  measurement_count: 1,
                  measurement_description: "serving",
                },
                {
                  fat: "22.39",
                  name: '"Spaghetti" Casserole',
                  size: "221.374",
                  unit: "g",
                  carbs: "6.06",
                  image:
                    "https://m.ftscrt.com/static/recipe/2f693abf-fa21-4565-98bf-3fce77d5402f.jpg",
                  global: true,
                  protein: "29.22",
                  calories: "339",
                  recipe_id: "9924001",
                  measurement_count: 1,
                  measurement_description: "serving",
                },
                {
                  fat: "13.19",
                  name: "'My'Hop Whole Wheat Pancakes",
                  size: "138.400",
                  unit: "g",
                  carbs: "33.50",
                  image:
                    "https://m.ftscrt.com/static/recipe/5892f92b-0afd-4645-b2a4-b8f5964fac4c.jpg",
                  global: true,
                  protein: "9.12",
                  calories: "299",
                  recipe_id: "7375430",
                  measurement_count: 1,
                  measurement_description: "serving",
                },
              ],
              macros: {
                fat: 42.44,
                carbs: 39.56,
                protein: 53.459999999999994,
              },
              calories: 766,
            },
          ],
          macros: {
            fat: 42.44,
            carbs: 39.56,
            protein: 53.459999999999994,
          },
          calories: 766,
        },
        coachId: "PXqGTqMrfblJ",
        isTemplate: false,
        isCoachMealPlan: false,
      },
    },
    {
      id: "810e2a7c-d39e-4dbc-be8b-cffd5b81af76",
      mealScheduleID: "21501dae-657b-4aa0-803c-af5c899a6bb9",
      dayMealPlanID: "412a32026-10797-4734-851310-1308a509391102",
      dayIdx: 4,
      dayMealPlan: {
        id: "412a32026-10797-4734-851310-1308a509391102",
        reference_name: "My DOOd",
        display_name: "",
        diet_type: null,
        meal_plan_type: "FoodBased",
        description: "",
        image: "",
        plandetails: {
          meals: [
            {
              name: "Breakfast",
              foods: [
                {
                  fat: "2.16",
                  name: "1% Fat Buttermilk",
                  size: "245.000",
                  unit: "g",
                  carbs: "11.74",
                  global: true,
                  food_id: "815",
                  protein: "8.11",
                  calories: "98",
                  measurement_count: 1,
                  measurement_description: "cup",
                },
              ],
              macros: {
                fat: 2.16,
                carbs: 11.74,
                protein: 8.11,
              },
              calories: 98,
            },
          ],
          macros: {
            fat: 2.16,
            carbs: 11.74,
            protein: 8.11,
          },
          calories: 98,
        },
        coachId: "PXqGTqMrfblJ",
        isTemplate: false,
        isCoachMealPlan: false,
      },
    },
    {
      id: "514b11f6-97f0-4178-baf0-8eb2f6dc2d54",
      mealScheduleID: "21501dae-657b-4aa0-803c-af5c899a6bb9",
      dayMealPlanID: "4812a6838-3380-4953-9a06-10513101252ba9138",
      dayIdx: 5,
      dayMealPlan: {
        id: "4812a6838-3380-4953-9a06-10513101252ba9138",
        reference_name: "dfadfd",
        display_name: "",
        diet_type: null,
        meal_plan_type: "MacrosBased",
        description: "dafdfd",
        image: "",
        plandetails: {
          meals: [
            {
              name: "Breakfast",
              calories: "663.33",
            },
            {
              name: "Lunch",
              calories: 500,
            },
            {
              name: "Evening Snack",
              calories: "163.34",
            },
            {
              name: "Dinner",
              calories: "663.33",
            },
          ],
          macros: {
            fat: 0,
            carbs: 497.5,
            protein: 0,
          },
          calories: 1990,
        },
        coachId: "PXqGTqMrfblJ",
        isTemplate: false,
        isCoachMealPlan: false,
      },
    },
    {
      id: "64a4dfaf-06fb-4104-9e3c-f2673639cfd1",
      mealScheduleID: "21501dae-657b-4aa0-803c-af5c899a6bb9",
      dayMealPlanID: "111013ab24-6088-4291-89911-9572014691385",
      dayIdx: 6,
      dayMealPlan: {
        id: "111013ab24-6088-4291-89911-9572014691385",
        reference_name: "Meal with my recipe",
        display_name: "",
        diet_type: "Veg",
        meal_plan_type: "FoodBased",
        description: "",
        image: "",
        plandetails: {
          meals: [
            {
              name: "Breakfast",
              foods: [
                {
                  fat: "20",
                  name: "Paneer Tikka Masala",
                  unit: "g",
                  carbs: "15",
                  global: true,
                  protein: "18",
                  calories: "350",
                  recipe_id: "20",
                  recipeItem: {
                    id: 20,
                    directions: [
                      {
                        direction_number: "1",
                        direction_description:
                          "In a bowl, mix together yogurt, turmeric powder, red chili powder, garam masala, and salt to make the marinade.",
                      },
                      {
                        direction_number: "2",
                        direction_description:
                          "Add paneer cubes to the marinade and let them sit for about 20-30 minutes.",
                      },
                      {
                        direction_number: "3",
                        direction_description:
                          "Heat oil in a pan over medium heat. Add cumin seeds and let them sizzle.",
                      },
                      {
                        direction_number: "4",
                        direction_description:
                          "Add chopped onions and sauté until they turn golden brown.",
                      },
                      {
                        direction_number: "5",
                        direction_description:
                          "Add minced garlic and grated ginger, and cook for another minute until fragrant.",
                      },
                      {
                        direction_number: "6",
                        direction_description:
                          "Pour in the tomato puree and cook for a few minutes until the raw smell disappears.",
                      },
                      {
                        direction_number: "7",
                        direction_description:
                          "Add marinated paneer cubes along with the marinade to the pan. Stir gently to coat the paneer with the tomato-onion mixture.",
                      },
                      {
                        direction_number: "8",
                        direction_description:
                          "Cover the pan and let the paneer simmer in the sauce for about 10-15 minutes, stirring occasionally.",
                      },
                      {
                        direction_number: "9",
                        direction_description: "Adjust seasoning if needed.",
                      },
                      {
                        direction_number: "10",
                        direction_description:
                          "Garnish with fresh cilantro leaves before serving.",
                      },
                      {
                        direction_number: "11",
                        direction_description:
                          "Serve the paneer tikka masala hot with rice or naan bread for a delicious and satisfying meal.",
                      },
                    ],
                    ingredients: [
                      {
                        ingredient_description: "250g paneer, cubed",
                      },
                      {
                        ingredient_description: "2 large tomatoes, pureed",
                      },
                      {
                        ingredient_description: "1 large onion, finely chopped",
                      },
                      {
                        ingredient_description: "2 cloves garlic, minced",
                      },
                      {
                        ingredient_description: "1 teaspoon ginger, grated",
                      },
                      {
                        ingredient_description: "1/2 cup plain yogurt",
                      },
                      {
                        ingredient_description:
                          "2 tablespoons oil (such as vegetable or canola oil)",
                      },
                      {
                        ingredient_description: "1 teaspoon cumin seeds",
                      },
                      {
                        ingredient_description: "1 teaspoon turmeric powder",
                      },
                      {
                        ingredient_description:
                          "1 teaspoon red chili powder (adjust to taste)",
                      },
                      {
                        ingredient_description: "1 teaspoon garam masala",
                      },
                      {
                        ingredient_description: "Salt to taste",
                      },
                      {
                        ingredient_description:
                          "Fresh cilantro leaves for garnish",
                      },
                    ],
                    recipe_name: "Paneer Tikka Masala",
                    serving_sizes: {
                      fat: "20",
                      fiber: "4",
                      protein: "18",
                      calories: "350",
                      carbohydrate: "15",
                      serving_size: "1 serving",
                    },
                    number_of_servings: 4,
                  },
                  measurement_count: 1,
                  measurement_description: "serving",
                },
                {
                  fat: "10",
                  name: "Kidney Bean Curry",
                  unit: "g",
                  carbs: "30",
                  global: true,
                  protein: "12",
                  calories: "250",
                  recipe_id: "21",
                  recipeItem: {
                    id: 21,
                    directions: [
                      {
                        direction_number: "1",
                        direction_description:
                          "Heat oil in a pan over medium heat. Add cumin seeds and let them sizzle.",
                      },
                      {
                        direction_number: "2",
                        direction_description:
                          "Add minced garlic and ginger, and sauté for a minute until fragrant.",
                      },
                      {
                        direction_number: "3",
                        direction_description:
                          "Add chopped onions and cook until they turn golden brown.",
                      },
                      {
                        direction_number: "4",
                        direction_description:
                          "Add chopped tomatoes and cook until they soften and release their juices.",
                      },
                      {
                        direction_number: "5",
                        direction_description:
                          "Stir in turmeric powder, red chili powder, and salt.",
                      },
                      {
                        direction_number: "6",
                        direction_description:
                          "Add cooked kidney beans and mix well to coat them with the spices and vegetables.",
                      },
                      {
                        direction_number: "7",
                        direction_description:
                          "Cover the pan and let the curry simmer for about 10-15 minutes, stirring occasionally.",
                      },
                      {
                        direction_number: "8",
                        direction_description:
                          "Sprinkle garam masala and give it a final stir.",
                      },
                      {
                        direction_number: "9",
                        direction_description:
                          "Garnish with fresh cilantro leaves before serving.",
                      },
                      {
                        direction_number: "10",
                        direction_description:
                          "Serve the kidney bean curry hot with rice or naan bread for a hearty and flavorful meal.",
                      },
                    ],
                    ingredients: [
                      {
                        ingredient_description: "2 cups cooked kidney beans",
                      },
                      {
                        ingredient_description: "1 large onion, finely chopped",
                      },
                      {
                        ingredient_description: "2 medium tomatoes, chopped",
                      },
                      {
                        ingredient_description: "2 cloves garlic, minced",
                      },
                      {
                        ingredient_description: "1 teaspoon ginger, minced",
                      },
                      {
                        ingredient_description: "1 teaspoon cumin seeds",
                      },
                      {
                        ingredient_description: "1 teaspoon turmeric powder",
                      },
                      {
                        ingredient_description:
                          "1 teaspoon red chili powder (adjust to taste)",
                      },
                      {
                        ingredient_description: "1 teaspoon garam masala",
                      },
                      {
                        ingredient_description:
                          "2 tablespoons oil (such as vegetable or canola oil)",
                      },
                      {
                        ingredient_description: "Salt to taste",
                      },
                      {
                        ingredient_description:
                          "Fresh cilantro leaves for garnish",
                      },
                    ],
                    recipe_name: "Kidney Bean Curry",
                    serving_sizes: {
                      fat: "10",
                      fiber: "8",
                      protein: "12",
                      calories: "250",
                      carbohydrate: "30",
                      serving_size: "1 serving",
                    },
                    number_of_servings: 4,
                  },
                  measurement_count: 1,
                  measurement_description: "serving",
                },
                {
                  fat: "15",
                  name: "Paneer and Potato Curry",
                  unit: "g",
                  carbs: "20",
                  global: true,
                  protein: "10",
                  calories: "250",
                  recipe_id: "41",
                  recipeItem: {
                    id: 41,
                    directions: [
                      {
                        direction_number: "1",
                        direction_description:
                          "Heat oil in a pan over medium heat. Add cumin seeds and let them sizzle.",
                      },
                      {
                        direction_number: "2",
                        direction_description:
                          "Add chopped onions and sauté until they turn golden brown.",
                      },
                      {
                        direction_number: "3",
                        direction_description:
                          "Add minced garlic and grated ginger, and cook for another minute until fragrant.",
                      },
                      {
                        direction_number: "4",
                        direction_description:
                          "Add pureed tomatoes and cook until the oil separates from the mixture.",
                      },
                      {
                        direction_number: "5",
                        direction_description:
                          "Stir in turmeric powder, red chili powder, and salt.",
                      },
                      {
                        direction_number: "6",
                        direction_description:
                          "Add diced potatoes and enough water to cover them. Cover the pan and simmer until the potatoes are cooked through.",
                      },
                      {
                        direction_number: "7",
                        direction_description:
                          "Gently add the paneer cubes and simmer for a few more minutes until heated through.",
                      },
                      {
                        direction_number: "8",
                        direction_description:
                          "Sprinkle garam masala and give it a final stir.",
                      },
                      {
                        direction_number: "9",
                        direction_description:
                          "Garnish with fresh cilantro leaves before serving.",
                      },
                      {
                        direction_number: "10",
                        direction_description:
                          "Serve the paneer and potato curry hot with rice or roti.",
                      },
                    ],
                    ingredients: [
                      {
                        ingredient_description: "200g paneer, cubed",
                      },
                      {
                        ingredient_description:
                          "2 medium potatoes, peeled and diced",
                      },
                      {
                        ingredient_description: "1 large onion, finely chopped",
                      },
                      {
                        ingredient_description: "2 tomatoes, pureed",
                      },
                      {
                        ingredient_description: "2 cloves garlic, minced",
                      },
                      {
                        ingredient_description: "1 teaspoon ginger, grated",
                      },
                      {
                        ingredient_description: "1 teaspoon cumin seeds",
                      },
                      {
                        ingredient_description: "1 teaspoon turmeric powder",
                      },
                      {
                        ingredient_description:
                          "1 teaspoon red chili powder (adjust to taste)",
                      },
                      {
                        ingredient_description: "1 teaspoon garam masala",
                      },
                      {
                        ingredient_description:
                          "2 tablespoons oil (such as vegetable or canola oil)",
                      },
                      {
                        ingredient_description: "Salt to taste",
                      },
                      {
                        ingredient_description:
                          "Fresh cilantro leaves for garnish",
                      },
                    ],
                    recipe_name: "Paneer and Potato Curry",
                    serving_sizes: {
                      fat: "15",
                      fiber: "4",
                      protein: "10",
                      calories: "250",
                      carbohydrate: "20",
                      serving_size: "1 serving",
                    },
                    number_of_servings: 4,
                  },
                  measurement_count: 1,
                  measurement_description: "serving",
                },
                {
                  fat: "12",
                  name: "Paneer and Potato Tikki",
                  unit: "g",
                  carbs: "15",
                  global: true,
                  protein: "8",
                  calories: "200",
                  recipe_id: "42",
                  recipeItem: {
                    id: 42,
                    directions: [
                      {
                        direction_number: "1",
                        direction_description:
                          "In a large bowl, combine grated paneer, mashed potatoes, bread crumbs, chopped onions, green chilies, chopped cilantro, ginger-garlic paste, cumin powder, coriander powder, turmeric powder, and salt.",
                      },
                      {
                        direction_number: "2",
                        direction_description:
                          "Mix everything together until well combined.",
                      },
                      {
                        direction_number: "3",
                        direction_description:
                          "Divide the mixture into equal portions and shape them into round patties or tikkis.",
                      },
                      {
                        direction_number: "4",
                        direction_description:
                          "Heat oil in a pan over medium heat. Fry the tikkis until golden brown and crispy on both sides.",
                      },
                      {
                        direction_number: "5",
                        direction_description:
                          "Remove the tikkis from the pan and drain excess oil on a paper towel.",
                      },
                      {
                        direction_number: "6",
                        direction_description:
                          "Serve the paneer and potato tikkis hot with mint chutney or ketchup.",
                      },
                    ],
                    ingredients: [
                      {
                        ingredient_description: "200g paneer, grated",
                      },
                      {
                        ingredient_description:
                          "2 medium potatoes, boiled and mashed",
                      },
                      {
                        ingredient_description: "1/2 cup bread crumbs",
                      },
                      {
                        ingredient_description: "1/4 cup finely chopped onions",
                      },
                      {
                        ingredient_description:
                          "2 green chilies, finely chopped",
                      },
                      {
                        ingredient_description: "1/4 cup chopped cilantro",
                      },
                      {
                        ingredient_description:
                          "1 teaspoon ginger-garlic paste",
                      },
                      {
                        ingredient_description: "1/2 teaspoon cumin powder",
                      },
                      {
                        ingredient_description: "1/2 teaspoon coriander powder",
                      },
                      {
                        ingredient_description: "1/4 teaspoon turmeric powder",
                      },
                      {
                        ingredient_description: "Salt to taste",
                      },
                      {
                        ingredient_description: "Oil for frying",
                      },
                    ],
                    recipe_name: "Paneer and Potato Tikki",
                    serving_sizes: {
                      fat: "12",
                      fiber: "3",
                      protein: "8",
                      calories: "200",
                      carbohydrate: "15",
                      serving_size: "1 serving",
                    },
                    number_of_servings: 6,
                  },
                  measurement_count: 1,
                  measurement_description: "serving",
                },
                {
                  fat: "12",
                  name: "Chicken Tomato Skillet",
                  unit: "g",
                  carbs: "10",
                  global: true,
                  protein: "25",
                  calories: "300",
                  recipe_id: "46",
                  recipeItem: {
                    id: 46,
                    directions: [
                      {
                        direction_number: "1",
                        direction_description:
                          "Season chicken breasts with salt and pepper.",
                      },
                      {
                        direction_number: "2",
                        direction_description:
                          "Heat olive oil in a skillet over medium heat. Add chicken breasts and cook until browned on both sides and cooked through. Remove from skillet and set aside.",
                      },
                      {
                        direction_number: "3",
                        direction_description:
                          "In the same skillet, add sliced onions and minced garlic. Sauté until onions are softened and translucent.",
                      },
                      {
                        direction_number: "4",
                        direction_description:
                          "Add diced tomatoes, dried basil, dried oregano, salt, and pepper to the skillet. Stir to combine.",
                      },
                      {
                        direction_number: "5",
                        direction_description:
                          "Return chicken breasts to the skillet and simmer for a few minutes until heated through and flavors are well combined.",
                      },
                      {
                        direction_number: "6",
                        direction_description:
                          "Serve the chicken tomato skillet hot with a side of steamed vegetables or rice.",
                      },
                    ],
                    ingredients: [
                      {
                        ingredient_description:
                          "4 boneless, skinless chicken breasts",
                      },
                      {
                        ingredient_description: "1 tablespoon olive oil",
                      },
                      {
                        ingredient_description: "1 large onion, sliced",
                      },
                      {
                        ingredient_description: "2 cloves garlic, minced",
                      },
                      {
                        ingredient_description: "1 can (14 oz) diced tomatoes",
                      },
                      {
                        ingredient_description: "1 teaspoon dried basil",
                      },
                      {
                        ingredient_description: "1 teaspoon dried oregano",
                      },
                      {
                        ingredient_description: "Salt and pepper to taste",
                      },
                    ],
                    recipe_name: "Chicken Tomato Skillet",
                    serving_sizes: {
                      fat: "12",
                      fiber: "3",
                      protein: "25",
                      calories: "300",
                      carbohydrate: "10",
                      serving_size: "1 serving",
                    },
                    number_of_servings: 4,
                  },
                  measurement_count: 1,
                  measurement_description: "serving",
                },
              ],
              macros: {
                fat: 69,
                carbs: 90,
                protein: 73,
              },
              calories: 1350,
            },
          ],
          macros: {
            fat: 69,
            carbs: 90,
            protein: 73,
          },
          calories: 1350,
        },
        coachId: "PXqGTqMrfblJ",
        isTemplate: false,
        isCoachMealPlan: false,
      },
    },
  ],
};
