import {
  ASSIST_MODIFY_WORKOUTS,
  CREATE_MEAL_PLAN,
  CREATE_WORKOUT_PLAN,
  GENERATE_MEAL_PLAN,
  GENERATE_RECIPE,
  GENERATE_SINGLE_WORKOUT,
  HELP_US_BE_BETTER,
} from "../../../constants";

const getPlaceholderText = (selectedOption) => {
  switch (selectedOption) {
    case CREATE_MEAL_PLAN:
      return "Example: Give me a 1-week nutrition plan with 1850 calories per day. The individual is looking to bulk up muscle and cut down on fat.";
    case CREATE_WORKOUT_PLAN:
      return "Example: Generate a week long fat burning and strength building plan for beginner.";
    case GENERATE_SINGLE_WORKOUT:
      return "Example: Generate a fat burning and strength building workout for beginner.";
    case GENERATE_RECIPE:
      return "Example: Give me recipes with the following ingredients paneer, green peppers, onions.";
    case GENERATE_MEAL_PLAN:
      return "Example: Give a meal plan for a person who consumes 2000 calories a day. The person is looking to bulk up muscle and cut down on fat.";
    case HELP_US_BE_BETTER:
      return "Please let us know how can we improve.";
    default:
      return "Write something here...";
  }
};

const doesResultMatchesTheJSONStructure = (result, selectedOption) => {
  switch (selectedOption) {
    case CREATE_MEAL_PLAN:
      return verifyMealPlanJSON(result);
    case CREATE_WORKOUT_PLAN:
      return verifyWorkoutPlanJSON(result);
    default:
      return true;
  }
};

const verifyMealPlanJSON = (result) => {
  if (result["week-1"]) {
    let week1 = result["week-1"];

    week1?.forEach((day) => {
      if (!day.day || !day.calories || !day.macros || !day.meals) {
        return false;
      }
    });

    return true;
  }
  return false;
};

const verifyWorkoutPlanJSON = (result) => {
  if (result["week-1"]) {
    let week1 = result["week-1"];

    Object.keys(week1).forEach((day) => {
      if (!week1[day].Workout) {
        return false;
      }

      let workout = week1[day].Workout;
      if (!workout.title || !workout["Part-1"] || !workout["Part-2"]) {
        return false;
      }

      Object.keys(workout).forEach((part) => {
        if (part !== "title") {
          let exercises = workout[part].exercises;
          if (!exercises) {
            return false;
          }
        }
      });
    });
    return true;
  }
};

export { getPlaceholderText, doesResultMatchesTheJSONStructure };
