import { store } from "@/redux/store";
import axios from "axios";

export const getActivityDataForDateRange = async (
  athleteID,
  startDate,
  endDate
) => {
  try {
    let res = await axios({
      method: "GET",
      url: `${
        store.getState().auth.apiURL
      }CF/api/garmin/getGarminSummaryByDateRangeAtheleteID`,
      params: {
        athleteId: athleteID,
        startDate: startDate,
        endDate: endDate,
      },
    });
    return res?.data;
  } catch (e) {
    return null;
  }
};


export const getMomentDataForDateRange = async (atheleteID, trackedMovementID) => {
  try {
    let res = await axios({
      method: "GET",
      url: `${store.getState().auth.apiURL}CF/api/getMovementHistoryAthelete`,
      params: {
        atheleteID,
        trackedMovementID,
      },
    });
    return res?.data;
  } catch (e) {
    return null;
  }
};

