export const getVideoId = (url) => {
  if(url)
  if (url.includes("youtube.com") || url.includes("youtu.be") || youtubeID(url)) {
    return youtubeID(url);
  } else if (url.includes("vimeo.com")) {
    return vimeoID(url);
  }
  return "";
};

function youtubeID(url) {
  var regExp =
    /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
  var match = url.match(regExp);
  console.log({ match });
  return match?.[1] ?? false;
}

function vimeoID(embedLink) {
  //https://stackoverflow.com/questions/2916544/parsing-a-vimeo-id-using-javascript
  const regex =
    /(https?:\/\/)?(www\.)?(player\.)?vimeo\.com\/?(showcase\/)*([0-9))([a-z]*\/)*([0-9]{6,11})[?]?.*/;
  const match = embedLink.match(regex);
  if (match && match?.length == 7) {
    return match[6];
  } else {
    return null;
  }
}
