import target from '../../assets/target.svg'; // Absolute Import is Required;
import events from '../../assets/events.svg';
import calendar from '../../assets/calendar.svg';
import React from 'react';

export const CalendarDays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export const CalendarDaysIndex = {
  Sunday: 1,
  Monday: 2,
  Tuesday: 3,
  Wednesday: 4,
  Thursday: 5,
  Friday: 6,
  Saturday: 7,
};

export const COLUMNS = [
  {
    Header: 'Sunday',
    accessor: 'Sunday',
  },
  {
    Header: 'Monday',
    accessor: 'Monday',
  },
  {
    Header: 'Tuesday',
    accessor: 'Tuesday',
  },
  {
    Header: 'Wednesday',
    accessor: 'Wednesday',
  },
  {
    Header: 'Thursday',
    accessor: 'Thursday',
  },
  {
    Header: 'Friday',
    accessor: 'Friday',
  },
  {
    Header: 'Saturday',
    accessor: 'Saturday',
  },
  // {
  //   Header: 'Sunday',
  //   accessor: 'Sunday',
  // },
];

export const DATA = [
  // THIS IS THE BUDDY DATA
  {
    Monday: {
      train: 'Cardio',
      day: '1',
    },
    Tuesday: {
      train: 'Interval Training',
      day: '2',
    },
    Wednesday: {
      train: 'Muscle Training',
      day: '3',
    },
    Thursday: {
      train: 'Rest Day',
      day: '4',
    },
    Friday: {
      train: 'Cardio',
      day: '5',
    },
    Saturday: {
      train: 'Cardio',
      day: '6',
    },
    Sunday: {
      train: 'Cardio',
      day: '7',
    },
  },
  {
    Monday: {
      train: 'Cardio',
      day: '1',
    },
    Tuesday: {
      train: 'Interval Training',
      day: '2',
    },
    Wednesday: {
      train: 'Muscle Training',
      day: '3',
    },
    Thursday: {
      train: 'Rest Day',
      day: '4',
    },
    Friday: {
      train: 'Cardio',
      day: '5',
    },
    Saturday: {
      train: 'Cardio',
      day: '6',
    },
    Sunday: {
      train: '',
      day: '7',
    },
  },
];

export const STEPS_INFO = [
  {
    img: target,
    text: <strong>Goals:&nbsp;</strong>,
  },
  {
    img: events,
    text: <strong>Program:&nbsp;</strong>,
  },
  {
    img: calendar,
    text: <strong>End date:&nbsp;</strong>,
  },
];

export const BASIC_INFO = [
  {
    label: '140',
    value: 'steps',
  },

  {
    label: '85%',
    value: 'Adherence',
  },

  {
    label: '140',
    value: 'Total Workouts',
  },

  {
    label: '2430',
    value: '12h 45 mins',
  },

  {
    label: '12h 45 mins',
    value: 'Total Workout time',
  },
];
