import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import moment from "moment";
import Layout from "../../../components/layout/Layout";
import DialogBox from "../../../components/Dialog/Dialog";
import ExercisesList from "../../../components/CreateWorkoutComp/ExercisesList";
import CreateWorkoutComp from "../../../components/CreateWorkoutComp/CreatWorkOut";

export default function ShowWorkouts(props) {
  // www.getfitaiserver.com/api/particularmodularworkout,
  // const propsState = props?.location?.state?.state;
  // console.log('props -> ', propsState);
  const [workoutList, setworkoutList] = useState(
    props?.location?.state?.state?.ModuleDetails[0] ||
      props?.location?.state?.ModuleDetails[0] ||
      []
  );
  const [data] = useState(
    props?.location?.state?.state?.data || props?.location?.state?.data
  );
  const [creatOutBuilder, setCreatOutBuilder] = useState(false);
  let getPosition = (string, subString, index) => {
    return string.split(subString, index).join(subString).length;
  };
  const { isImport, date, userUUID } = queryString.parse(
    props?.location?.search
  );

  const PrimaryKey = useLocation().pathname.slice(
    getPosition(useLocation().pathname, "/", 3) + 1,
    useLocation().pathname.length - 1
  );
  console.log("data from showworkout: ", props);
  // console.log(PrimaryKey);

  // useEffect(async () => {
  //   await axios({
  //     method: 'get',
  //     url: `${APIURL}api/particularmodularworkout`,
  //     params: {
  //       primaryKey: 'ZzNkLZzFsdsdstySj',
  //     },
  //   })
  //     .then((res) => {
  //       setworkoutList(res.data.result);
  //       console.log('exercise -> ', res);
  //     })
  //     .catch((err) => {
  //       alert(err);
  //     });
  // }, []);
  return (
    <Layout>
      <ExercisesList
        isImport={isImport}
        workOutList={workoutList}
        setWorkOutList={setworkoutList}
        showOnly={true}
        isEdit={true}
        handleClick={setCreatOutBuilder}
        data={{
          moduleDate: date ? date : moment(new Date())?.format("YYYY-MM-DD"),
          userUUID,
          ...data,
        }}
      />
      <DialogBox
        fullScreen={true}
        maxWidth={"sm"}
        open={creatOutBuilder}
        onClose={() => setCreatOutBuilder(!creatOutBuilder)}
      >
        <CreateWorkoutComp
          onClose={() => setCreatOutBuilder(!creatOutBuilder)}
          trainerUUID={data?.TrainerUUID}
          data={{
            moduleDate: date ? date : moment(new Date())?.format("YYYY-MM-DD"),
            userUUID,
            ...data,
          }}
          isExistingWorkout={true}
        />
      </DialogBox>
    </Layout>
  );
}
