import React from "react";

const FunnelIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30 11H10L18 20.46V27L22 29V20.46L30 11Z"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M30 11H10L18 20.46V27L22 29V20.46L30 11Z"
        stroke="url(#paint0_linear_248_14546)"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect
        x="1"
        y="1"
        width="38"
        height="38"
        rx="7"
        stroke="url(#paint1_linear_248_14546)"
        stroke-width="2"
      />
      <defs>
        <linearGradient
          id="paint0_linear_248_14546"
          x1="10"
          y1="20"
          x2="30"
          y2="20"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.104167" stop-color="#464FEB" />
          <stop offset="1" stop-color="#8330E9" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_248_14546"
          x1="0"
          y1="20"
          x2="40"
          y2="20"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.104167" stop-color="#464FEB" />
          <stop offset="1" stop-color="#8330E9" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default FunnelIcon;
