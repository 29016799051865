import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import AnalysisAnimation from "@/assets/analysis2.gif";
import {
  Area,
  Bar,
  CartesianGrid,
  ComposedChart,
  Line,
  LineChart,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { Label } from "@/components/ui/label";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { cn } from "@/lib/utils";
import {
  colourTokens,
  Tokens,
} from "@/features/Garmin/FitFileAnalyzer/styles/tokens";
import {
  toHHMMSS,
  useWindowSize,
} from "@/features/Garmin/FitFileAnalyzer/utils";
import CustomSelect from "../WorkoutComponent/Endurance/util/CustomSelect";
import { SpurfitCircularProgress } from "../Dashboard/dashboard";

const CustomTooltip = ({ active, payload, label }) => {
  let listItems = payload.map((item, index) => {
    if (item.name == "Elevation") {
      return (
        <li
          key={item.name}
          style={{ listStyle: "none", display: "flex", alignItems: "center" }}
        >
          <div
            style={{
              width: 8,
              height: 8,
              borderRadius: "50%",
              marginRight: 8,
              display: "block",
              backgroundColor: item.color,
            }}
          />
          {item.name}:{" "}
          <strong>
            {Math.round(item.value * 1000)} {item.unit || ""}
          </strong>
        </li>
      );
    } else {
      return (
        <li
          key={`${item.name}_${index}`}
          style={{ listStyle: "none", display: "flex", alignItems: "center" }}
        >
          <div
            style={{
              width: 8,
              height: 8,
              borderRadius: "50%",
              marginRight: 8,
              display: "block",
              backgroundColor: item.color,
            }}
          />
          {item.name}:{" "}
          <strong>
            {item.name === "Pace"
              ? item.value.toFixed(2)
              : Math.round((item.value * 100) / 100)}{" "}
            {item.unit || ""}
          </strong>
        </li>
      );
    }
  });
  if (active && payload && payload.length) {
    let timerTime = payload[0].payload.elapsed_time;
    return (
      <div className="p-4 bg-white-pure rounded-md shadow-md border border-gray-600">
        <span className="text-gray-600 text-xs mb-2 block">
          {toHHMMSS(timerTime)}
        </span>
        <hr className="mb-1 border-gray-500"></hr>
        {listItems}
      </div>
    );
  }

  return null;
};

const item = { hidden: { opacity: 0, y: 24 }, show: { opacity: 1, y: 0 } };

const container = {
  hidden: { opacity: 0 },
  show: { opacity: 1, transition: { staggerChildren: 0.15 } },
};

export default function ChartForCompareWorkouts({
  data,
  recs,
  selectedLap,
  selectedWindows,
  setSelectedWindows,
  simplifiedDataForSelectedWorkout,
  simplifiedDataForWorkoutFromCalender,
  workoutFromCalenderName,
  selectedWorkoutName,
  loading,
}) {
  const size = useWindowSize();
  const [resolution, setResolution] = React.useState(200);
  const [brush, setBrush] = React.useState({ startIndex: 0, endIndex: 1000 });
  const [xAxisDataKey, setXAxisDataKey] = React.useState("distance");
   

  React.useEffect(() => {
    if (size.width > 500) {
      setResolution(500);
    } else if (size.width > 1000) {
      setResolution(1000);
    } else if (size.width > 2000) {
      setResolution(2000);
    } else {
      setResolution(200);
    }
  }, [size]);

  return (
    <motion.div
      variants={container}
      initial="hidden"
      animate="show"
      className="overflow-hidden rounded-[16px] rounded-b-none shadow-sm bg-white-pure"
    >
      <div className="flex w-full gap-10 items-center shadow-lg  p-5 border-b rounded-t-[16px]  ">
        <h2
          className="font-DMSans text-font16 font-bold "
          style={{
            color: colourTokens?.workoutFromCalender,
          }}
        >
          {workoutFromCalenderName}
        </h2>
        <h2
          className="font-DMSans text-font16 font-bold "
          style={{
            color: colourTokens?.selectedWorkout,
          }}
        >
          {selectedWorkoutName}
        </h2>
      </div>
     
      <ChartCard
        data={simplifiedDataForSelectedWorkout}
        data2={simplifiedDataForWorkoutFromCalender}
        datakey={"heart_rate"}
        name={"Heart rate"}
        unit={"bpm"}
        color1={Tokens.power}
        color2={Tokens.cadence}
        brushStartIndex={brush.startIndex}
        brushEndIndex={brush.endIndex}
        xAxisDataKey={xAxisDataKey}
        windowedPeaks={selectedWindows}
        loading={loading}
      />
    </motion.div>
  );
}

const ChartCard = ({
  data,
  data2,
  datakey,
  name,
  color1,
  xAxisDataKey,
}) => {
  const [selectedForOverlay, setOverlayOptions] = React.useState(
    null || datakey
  );
  const [selectedData, setSelectedData] = React.useState(null);
  const [distanceMax, setDistanceMax] = useState("");

  const overlayOptions = [
    { value: "power", label: "Power" },
    { value: "speed", label: "Speed" },
    { value: "cadence", label: "Cadence" },
    { value: "heart_rate", label: "Heart rate" },
  ];

  useEffect(() => {
    let dataLength;
    let data2Length;
    if (data?.length > 0) {
      dataLength = data[length - 1]?.distance;
    }
    if (data2?.length > 0) {
      data2Length = data2[length - 1]?.distance;
    }
    if (data2Length > dataLength) {
      setDistanceMax(`${data2Length}`);
    } else {
      setDistanceMax(`${dataLength}`);
    }
  }, [data, data2]);

  const selectedOptionData = {
    power: {
      datakey: "power",
      name: "Power",
      color: Tokens.power,
      unit: " W",
    },
    speed: {
      datakey: "enhanced_speed",
      name: "Speed",
      color: Tokens.speed,
      unit: " kph",
    },
    cadence: {
      datakey: "cadence",
      name: "Cadence",
      color: Tokens.cadence,
      unit: " rpm",
    },
    heart_rate: {
      datakey: "heart_rate",
      name: "Heart rate",
      color: Tokens.heartrate,
      unit: "bpm",
    },
  };

  return (
    <motion.div
      variants={item}
      style={{ width: "100%", height: "400px" }}
      className="p-6 bg-white-pure"
    >
      {data2?.length > 0 && data?.length > 0 && (
        <div className="flex items-center justify-between pb-4">
          <div className="mr-5">
            <h1 className="text-xl font-semibold ">
              {selectedOptionData?.[selectedForOverlay]?.name || name}
            </h1>
          </div>

          <div className="flex flex-1 items-center justify-end gap-1 min-w-fit">
            <CustomSelect
              triggerClassName={"w-[200px]"}
              onValueChange={(value) => {
                if (value === "clear") {
                  setOverlayOptions(null);
                  setSelectedData(null);
                } else {
                  setOverlayOptions(value);
                  setSelectedData(selectedOptionData[value]);
                }
              }}
              placeholder={
                selectedOptionData?.[selectedForOverlay]?.name || datakey
              }
              options={overlayOptions}
            />
          </div>
        </div>
      )}
      
      {data2?.length > 0 && data?.length > 0 && (
        <ResponsiveContainer debounce={0.2} height={"100%"}>
          <LineChart syncId={"stacked"} margin={{ top: 24, bottom: 44 }}>
            <YAxis
              dataKey={selectedForOverlay}
              domain={["auto", "dataMax"]}
              width={80}
              stroke={"#ccc"}
              tickCount={5}
              axisLine={false}
              tickSize={0}
              mirror={true}
              tick={{ fontSize: 14, dy: -12, dx: -2 }}
            />

            <Tooltip
              cursor={{ stroke: "black" }}
              content={<CustomTooltip />}
              isAnimationActive={false}
            />
            <CartesianGrid
              vertical={false}
              strokeDasharray="4"
              strokeOpacity={0.9}
            />

            <defs>
              <linearGradient
                id={`${color1}_gradient`}
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop offset="20%" stopColor={color1} stopOpacity={1} />
                <stop offset="95%" stopColor={color1} stopOpacity={0} />
              </linearGradient>
            </defs>
            {/* line for the workout that is selceted */}
            <Line
              name={selectedOptionData?.[selectedForOverlay]?.name}
              xAxisId={"selectedWorkout"}
              isAnimationActive={false}
              data={data}
              dataKey={selectedForOverlay}
              stroke={colourTokens?.selectedWorkout}
              fill={"rgba(131, 48, 233, .1)"}
              dot={false}
              activeDot={{ r: 4 }}
            />
            {/* line for the workout from calender */}
            <Line
              name={selectedOptionData?.[selectedForOverlay]?.name}
              data={data2}
              xAxisId={"benchMark"}
              isAnimationActive={false}
              dataKey={selectedForOverlay}
              stroke={colourTokens?.workoutFromCalender}
              fill={"rgba(121, 48, 233, .1)"}
              dot={false}
              activeDot={{ r: 4 }}
            />

            <XAxis
              xAxisId={"benchMark"}
              dataKey={`${xAxisDataKey}`}
              interval={"preserveStartEnd"}
              padding={{ left: 40 }}
              minTickGap={200}
              tickCount={4}
              domain={[0, distanceMax]}
              tick={{
                fontSize: 14,
                fill: "#CCC",
                color: "#FFF",
              }}
              tickFormatter={(val) => {
                if (val !== "undefined" && val >= 0) {
                  if (xAxisDataKey === "elapsed_time") {
                    return toHHMMSS(val);
                  }

                  return val.toFixed(2) + " km";
                }
              }}
            />
            <XAxis
              xAxisId={"selectedWorkout"}
              hide={true}
              dataKey={`${xAxisDataKey}`}
              interval={"preserveStartEnd"}
              padding={{ left: 40 }}
              minTickGap={200}
              tickCount={4}
              domain={[0, `${data[length - 1]?.distance}`]}
              tick={{
                fontSize: 14,
                fill: "#CCC",
                color: "#FFF",
              }}
            />
          </LineChart>
        </ResponsiveContainer>
      )}
    </motion.div>
  );
};
