import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { InputWithLabel } from "../../profile/InputWithLabel";
import colors from "../../../common/theme/appModern/colors";
import mixpanel from "mixpanel-browser";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useSelector } from "react-redux";

const DuplicateProgram = ({
  setShowDuplicateModal,
  showDuplicateModal,
  programName,
  setProgramName,
  description,
  setDescription,
  duplicateProgram,
  selectedProgram,
  daysMessages,
  daysTargets,
  canEdit,
  saveAsTemplate,
}) => {
  const coachID = useSelector((state) => state.auth.trainerUUID);

  return (
    <Dialog
      open={showDuplicateModal}
      PaperProps={{
        sx: {
          // padding: "24px",
          width: "320px",
          borderRadius: "20px",
        },
      }}
    >
      <DialogContent sx={{ padding: 0 }}>
        <div className="add-program-modal-head">
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <p className="add-program-modal-head-title">
              Create duplicate program
            </p>
          </div>
          <div onClick={() => setShowDuplicateModal(false)}>
            <CloseRoundedIcon sx={{ cursor: "pointer" }} />
          </div>
        </div>
        <div className="add-program-modal-body">
          <div className="full-width-container">
            <label className="label-new">Program Name*</label>
            <input
              autoFocus
              placeholder="Program Name"
              value={programName}
              onChange={(e) => setProgramName(e.target.value)}
              className="inputfiled-new"
            />
          </div>
          <textarea
            id="programs description"
            className="textarea-new"
            placeholder="Description (Optional)"
            type="text"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            rows={3}
          />
          <button
            // style={{ ...buttonStyle }}
            disabled={programName.length < 2}
            style={{ marginTop: "8px" }}
            className="button-new"
            onClick={() => {
              mixpanel.track("Trainer_DuplicateProgram_Start");
              duplicateProgram(
                selectedProgram.id,
                programName,
                description,
                daysMessages,
                daysTargets,
                saveAsTemplate
              );
              setShowDuplicateModal(false);
            }}
          >
            Save
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DuplicateProgram;
