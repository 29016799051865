import { Dialog } from "@mui/material";
import React, { createContext, useCallback, useEffect, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { SpurfitCircularProgress } from "../Dashboard/dashboard";
import TopBar from "./TopBar";
import AddProperties from "./AddPropertiesDialog";
import AddWarmUpProperties from "./AddWarmupProperties";
import FollowAlongBuilder from "./FollowAlongBuilder";
import SelectExercises from "./SelectExercises";
import WorkoutInfoDialog from "./WorkoutInfoDialog";
import { getUpdatedProperty } from "./helpers";
import { useSelector } from "react-redux";
import WorkoutContentPage from "./WorkoutContentPage";
import EnduranceBuilder from "./Endurance/EnduranceBuilder";
import { bikeRounds, runRounds, swimRounds } from "./Endurance/utils";

export const WorkoutModalContext = createContext();
const WorkoutBuilderModal = ({
  open,
  setOpen,
  setIsOpenForWorKoutInfo,
  data,
  isImport,
  isCalendar,
  isUpdate,
  isAssign,
  extraData,
  setSelectedToShow,
  setNewCheckedItems,
  PropsData,
  isFromClientView,
  reloadProfileForWorkoutBuilderModal,
  onSuccess,
  onSuccessOfEditWokroutFromWorkout,
  fromWorkout,
  creatFromWorkout,
  onSuccessOfNewWorkoutForm,
}) => {
  //   const location = useLocation();

  const [tempRound, setTempRound] = useState({});
  const [rounds, setRounds] = useState(
    data?.workoutContent?.rounds || [
      {
        round: {
          numRounds: 1,
          measurements: [],
        },
      },
    ]
  );

  const [showAddPropertiesModal, setShowAddPropertiesModal] = useState(true);
  const [selectedExerciseInfo, setSelectedExerciseInfo] = useState({});
  const [isStructured, setIsStructured] = useState(null);
  const [isVideoUpdate, setIsVideoUpdate] = useState(false);
  const [allSelectedExercisesObj, setAllSelectedExercisesObj] = useState({});
  const [showWorkoutInfoDialog, setShowWorkoutInfoDialog] = useState(false);
  const [reorderRound, setReorderRound] = useState(-1);
  const [workoutData, setWorkoutData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [workoutKind, setWorkoutKind] = useState("");
  const [roundIdx, setRoundIdx] = useState(-1);
  const [idx, setIdx] = useState(-1);

  const [workoutInfo, setWorkoutInfo] = useState({
    name: "",
    description: "",
  });
  const [videoData, setVideoData] = useState();
  const [isDraggingSection, setIsDraggingSection] = useState(false);
  const [triggerRecheck, setTriggerRecheck] = useState(0);

  const exercisesObj = useSelector((state) => state.data.exerciseObject);

  const incrementRound = useCallback(
    (roundIndex) => {
      setRounds((prevRounds) => {
        const updatedRounds = [...prevRounds];
        updatedRounds[roundIndex].round.numRounds += 1;
        return updatedRounds;
      });
    },
    [setRounds]
  );
  // console.log("isFromClientView", isFromClientView, workoutKind);
  const incrementWarmUpRoundForAnExercise = useCallback(
    (roundIndex, exerciseIndex) => {
      setRounds((prevRounds) => {
        const updatedRounds = [...prevRounds];
        let numRounds =
          updatedRounds[roundIndex].round.measurements[exerciseIndex]
            .measurement.numRounds || 1;

        updatedRounds[roundIndex].round.measurements[
          exerciseIndex
        ].measurement.numRounds = numRounds + 1;
        return updatedRounds;
      });
    },
    [setRounds]
  );

  const decrementWarmUpRoundForAnExercise = useCallback(
    (roundIndex, exerciseIndex) => {
      setRounds((prevRounds) => {
        const updatedRounds = [...prevRounds];
        updatedRounds[roundIndex].round.measurements[
          exerciseIndex
        ].measurement.numRounds -= 1;
        return updatedRounds;
      });
    },
    [setRounds]
  );

  const decrementRound = useCallback(
    (roundIndex) => {
      setRounds((prevRounds) => {
        const updatedRounds = [...prevRounds];
        updatedRounds[roundIndex].round.numRounds -= 1;
        return updatedRounds;
      });
    },
    [setRounds]
  );

  const updateExerciseProperty = useCallback(
    (roundIndex, exerciseIndex, property, value) => {
      setRounds((prevRounds) => {
        const updatedRounds = [...prevRounds];
        if (
          updatedRounds?.[roundIndex]?.round?.measurements?.[exerciseIndex]
            ?.measurement
        ) {
          updatedRounds[roundIndex].round.measurements[
            exerciseIndex
          ].measurement[property] = value;
        }
        return updatedRounds;
      });
    },
    [setRounds]
  );

  const removeOneProperty = useCallback(
    (roundIndex, exerciseIndex, property) => {
      setRounds((prevRounds) => {
        const updatedRounds = [...prevRounds];
        if (
          updatedRounds?.[roundIndex]?.round?.measurements?.[exerciseIndex]
            ?.measurement?.[property]
        ) {
          delete updatedRounds[roundIndex].round.measurements[exerciseIndex]
            .measurement[property];
        }
        if (
          updatedRounds?.[roundIndex]?.round?.measurements?.[exerciseIndex]
            ?.measurement?.[`${property}F`]
        ) {
          delete updatedRounds[roundIndex].round.measurements[exerciseIndex]
            .measurement[`${property}F`];
        }
        if (
          updatedRounds?.[roundIndex]?.round?.measurements?.[exerciseIndex]
            ?.measurement?.[`${property}Units`]
        ) {
          delete updatedRounds[roundIndex].round.measurements[exerciseIndex]
            .measurement[`${property}Units`];
        }

        return updatedRounds;
      });
    },
    [setRounds]
  );

  useEffect(() => {
    // console.log("data ha kya", data);
    if (data) {
      setWorkoutData(data);
      let workoutContent = data?.workoutContent;

      setWorkoutInfo({
        ...data,
        workoutContent: undefined,
      });

      setWorkoutKind(data?.workoutKind);
      let rounds = workoutContent?.rounds;
      if (rounds) {
        rounds = JSON.parse(JSON.stringify(rounds));
        setRounds(rounds);
      }
    }

    // let workoutInfo = location?.state?.workoutInfo;
    // if (workoutInfo) {
    //   setWorkoutInfo(workoutInfo);
    // }

    let isStructured = undefined;
    let videoUpdate = undefined;
    let isVideoUpdate = videoUpdate === "true" || videoUpdate === true;
    setIsVideoUpdate(isVideoUpdate);

    if (isStructured === "false" || isStructured === false) {
      setIsStructured(false);
    } else {
      setIsStructured(true);
    }
  }, []);

  useEffect(() => {
    getDataFromLocalStorage();
  }, [workoutInfo]);

  useEffect(() => {
    // console.log("workout info", workoutInfo);
    if (rounds?.[0]?.round?.measurements?.[0]?.measurement) {
      setSelectedExerciseInfo({
        roundIndex: 0,
        exerciseIndex: 0,
        exercise: rounds[0]?.round?.measurements?.[0]?.measurement,
        exerciseId:
          rounds[0]?.round?.measurements?.[0]?.measurement?.movementID,
      });
    } else {
      setSelectedExerciseInfo({});
    }
    // console.log("setting selected exercise info", rounds);
  }, [
    rounds?.[0]?.round?.measurements?.[0]?.measurement != undefined &&
      Object.entries(selectedExerciseInfo).length === 0,
  ]);

  const saveDataToLocalStorage = () => {
    if (isLoading) return;
    // if any changes are made then store them in local storage
    // two cases:
    // 1. new workout
    // 2. update workout

    let isWorkoutContentChanged = false;
    let isNewWorkout = false;
    if (location?.state?.state?.isUpdate || location?.state?.isUpdate) {
      // check if any changes are made in workout info or workout content
      let curWorkoutInfo =
        location?.state?.state?.data || location?.state?.workoutInfo;
      let workoutContent = location?.state?.state?.data?.workoutContent;

      if (workoutContent) {
        let prevRounds =
          location?.state?.state?.data?.workoutContent?.rounds ||
          location?.state?.workoutInfo?.workoutContent?.rounds;

        if (JSON.stringify(prevRounds) !== JSON.stringify(rounds)) {
          isWorkoutContentChanged = true;
        }
      }
    } else {
      isNewWorkout = true;
    }

    if (isNewWorkout || isWorkoutContentChanged) {
      let data = {
        ...location?.state?.state?.data,
        workoutKind: workoutKind,
        workoutContent: {
          rounds,
        },
      };

      if (workoutInfo.name && workoutInfo.name !== "") {
        data.name = workoutInfo.name;
      }
      if (workoutInfo.description && workoutInfo.description !== "") {
        data.description = workoutInfo.description;
      }
      localStorage.setItem("workoutData", JSON.stringify(data));
    }
  };

  const history = useHistory();
  useEffect(() => {
    const handleBeforeUnload = () => {
      if (isLoading) return;
      // if any changes are made then store them in local storage
      // two cases:
      // 1. new workout
      // 2. update workout

      let isWorkoutContentChanged = false;
      let isNewWorkout = false;
      if (isUpdate) {
        // check if any changes are made in workout info or workout content
        let curWorkoutInfo = data;
        let workoutContent = data?.workoutContent;

        if (workoutContent) {
          let prevRounds = data?.workoutContent?.rounds;

          if (JSON.stringify(prevRounds) !== JSON.stringify(rounds)) {
            isWorkoutContentChanged = true;
          }
        }
      } else {
        isNewWorkout = true;
      }

      if (isNewWorkout || isWorkoutContentChanged) {
        let localData = {
          ...data,
          workoutContent: {
            rounds,
          },
        };

        if (workoutInfo.name && workoutInfo.name !== "") {
          localData.name = workoutInfo.name;
        }
        if (workoutInfo.description && workoutInfo.description !== "") {
          localData.description = workoutInfo.description;
        }
        localStorage.setItem("workoutData", JSON.stringify(localData));
      }

      // console.log("Navigating away...");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    const unblock = history.block((location, action) => {
      handleBeforeUnload();
      return true;
    });

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      unblock();
    };
  }, [history, rounds, isLoading]);

  const [shouldScrollToError, setShouldScrollToError] = useState(false);
  const [showErrorWarning, setShowErrorWarning] = useState(false);

  const handleSaveClick = useCallback(() => {
    setShouldScrollToError(true);
    setShowErrorWarning(true);
  }, []);

  useEffect(() => {
    if (shouldScrollToError) {
      // Reset the flag after a longer delay to ensure scroll has time to complete
      const timer = setTimeout(() => {
        setShouldScrollToError(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [shouldScrollToError]);

  const getDataFromLocalStorage = () => {
    let localStorageData = localStorage.getItem("workoutData");
    if (localStorageData && workoutInfo?.name) {
      localStorageData = JSON.parse(localStorageData);
      if (localStorageData?.name === workoutInfo?.name) {
        setRounds(localStorageData?.workoutContent?.rounds);
        setWorkoutKind(data?.workoutKind);
      }
    }
  };

  const getExercisFormatted = (exercise) => {
    let measurementInfo = {
      measurement: {
        movementName: exercise.name,
        reps: exercise.reps,
        repsMax: exercise.repsMax,
        weightMax: exercise.weightMax,
        heightMax: exercise.heightMax,
        distanceMax: exercise.distanceMax,
        calorieMax: exercise.calorieMax,
        repsF: exercise.repsF,
        height: exercise.height,
        heightF: exercise.heightF,
        heightUnits: exercise.heightUnits,
        weight: exercise.weight,
        weightF: exercise.weightF,
        weightUnits: exercise.weightUnits,
        distance: exercise.distance,
        distanceF: exercise.distanceF,
        distanceUnits: exercise.distanceUnits,
        duration: exercise.duration,
        durationF: exercise.durationF,
        calorie: exercise.calorie,
        calorieF: exercise.calorieF,
        isMaxLoad: exercise.isMaxLoad,
        isMaxReps: exercise.isMaxReps,
        movementID: exercise.id,
        recordFromUser: exercise?.recordFromUser,
        imageLink: exercise?.imageLink,
        videoLink: exercise?.videoURL,
        movementInstr: exercise.movementInstructions || exercise?.movementInstr,
        break: exercise?.break,
      },
    };
    return measurementInfo;
  };

  const addExerciseToParticularRound = useCallback(
    (roundIndex, exerciseIndex, e) => {
      let exercise = getExercisFormatted(e);
      setRounds((prevRounds) => {
        const updatedRounds = [...prevRounds];
        updatedRounds[roundIndex].round.measurements.splice(
          exerciseIndex,
          0,
          exercise
        );

        return updatedRounds;
      });
      setAllSelectedExercisesObj((prevObj) => {
        const updatedObj = { ...prevObj };
        updatedObj[exercise?.measurement?.movementID] =
          updatedObj[exercise?.measurement?.movementID] || [];
        if (
          !updatedObj[exercise?.measurement?.movementID].includes(roundIndex)
        ) {
          updatedObj[exercise?.measurement?.movementID].push(roundIndex);
          console.log("updatedObj", updatedObj);
        }
        return updatedObj;
      });
    },
    [setRounds]
  );

  const updateExerciseToParticularRound = (roundIndex, exerciseIndex, e) => {
    let exercise = getExercisFormatted(e);
    setRounds((prevRounds) => {
      const updatedRounds = [...prevRounds];
      updatedRounds[roundIndex].round.measurements[exerciseIndex] = exercise;

      return updatedRounds;
    });
    setAllSelectedExercisesObj((prevObj) => {
      const updatedObj = { ...prevObj };
      updatedObj[exercise?.measurement?.movementID] =
        updatedObj[exercise?.measurement?.movementID] || [];
      if (!updatedObj[exercise?.measurement?.movementID].includes(roundIndex)) {
        updatedObj[exercise?.measurement?.movementID].push(roundIndex);
        console.log("updatedObj", updatedObj);
      }
      return updatedObj;
    });
  };

  function removeParticularExercise(roundIndex, exerciseIndex, exerciseId) {
    console.log("onRemove", roundIndex, exerciseId, "  -> ", rounds);

    console.log("exerciseIndex ===> ", exerciseIndex);

    setAllSelectedExercisesObj((prevObj) => {
      const updatedObj = { ...prevObj };
      updatedObj[exerciseId] = updatedObj[exerciseId]?.filter(
        (round) => round !== roundIndex
      );
      console.log("updated obj", updatedObj);
      return updatedObj;
    });

    let newMeasurements = [];
    rounds?.[roundIndex]?.round?.measurements?.forEach((exercise, index) => {
      if (index !== exerciseIndex) {
        newMeasurements.push(exercise);
      }
    });

    setRounds((prevRounds) => {
      const updatedRounds = [...prevRounds];
      updatedRounds[roundIndex].round.measurements = newMeasurements;
      return updatedRounds;
    });
    if (
      selectedExerciseInfo?.exerciseIndex === exerciseIndex &&
      selectedExerciseInfo?.roundIndex === roundIndex
    ) {
      setSelectedExerciseInfo({});
    }
    console.log("rounds", rounds);
  }

  const onReorderDragEnd2 = (result) => {
    if (!result.destination) return;
    let newRound = { ...tempRound };
    let newMeasurements = [...newRound.measurements];
    let [removed] = newMeasurements.splice(result.source.index, 1);
    newMeasurements.splice(result.destination.index, 0, removed);
    newRound.measurements = newMeasurements;

    // also check if first or last movement is break then remove it
    if (newRound.measurements[0]?.measurement?.movementName === "BREAK") {
      newRound.measurements.splice(0, 1);
    }
    if (
      newRound.measurements[newRound.measurements.length - 1]?.measurement
        ?.movementName === "BREAK"
    ) {
      newRound.measurements.splice(newRound.measurements.length - 1, 1);
    }
    // if there is consecutive break then remove one
    newRound.measurements.forEach((m, index) => {
      let measurement = m.measurement;
      if (measurement.movementName === "BREAK") {
        let nextMeasurement = newRound.measurements[index + 1]?.measurement;

        if (nextMeasurement?.movementName === "BREAK") {
          newRound.measurements.splice(index + 1, 1);
        }
      }
    });

    setTempRound(newRound);
  };

  const onDropInDifferentSection = (result) => {
    if (!result.destination) return;

    // destination section index
    let destinationSectionIndex = +result.destination.droppableId.split("-")[1];

    let newRound = { ...tempRound };
    let newMeasurements = [...newRound.measurements];
    let [removed] = newMeasurements.splice(result.source.index, 1);
    newRound.measurements = newMeasurements;

    // set removed exercise to destination section
    let destinationSection = rounds[destinationSectionIndex]?.round;
    destinationSection.measurements.splice(
      result.destination.index,
      0,
      removed
    );

    // also check if first or last movement is break then remove it
    if (newRound.measurements[0]?.measurement?.movementName === "BREAK") {
      newRound.measurements.splice(0, 1);
    }
    if (
      newRound.measurements[newRound.measurements.length - 1]?.measurement
        ?.movementName === "BREAK"
    ) {
      newRound.measurements.splice(newRound.measurements.length - 1, 1);
    }

    // if there is consecutive break then remove one
    newRound.measurements.forEach((m, index) => {
      let measurement = m.measurement;
      if (measurement.movementName === "BREAK") {
        let nextMeasurement = newRound.measurements[index + 1]?.measurement;
      }
    });

    setRounds((prevRounds) => {
      const updatedRounds = [...prevRounds];
      updatedRounds[destinationSectionIndex].round = destinationSection;
      updatedRounds[reorderRound].round = newRound;
      return updatedRounds;
    });

    setTempRound(newRound);
  };

  const addPropertiesRef = React.useRef();
  const addPropertiesWarmUpRef = React.useRef();
  const onSelectedExerciseChange = () => {
    // if selected exercise is warm up then open warm up properties dialog
    if (
      rounds?.[selectedExerciseInfo?.roundIndex]?.round?.type === "Warm Up" ||
      rounds?.[selectedExerciseInfo?.roundIndex]?.round?.type === "Mixed Sets"
    ) {
      addPropertiesWarmUpRef.current?.saveData();
    } else {
      addPropertiesRef.current?.saveData();
    }
  };
  useEffect(() => {
    // console.log("all data", data, isImport, isCalendar, isUpdate, extraData);
  }, []);

  return (
    <Dialog open={open} fullScreen className="relative">
      <WorkoutModalContext.Provider
        value={{
          rounds,
          setShowAddPropertiesModal,
          removeOneProperty,
          selectedExerciseInfo,
          allSelectedExercisesObj,
          setAllSelectedExercisesObj,
          removeParticularExercise,
          incrementRound,
          decrementRound,
          tempRound,
          setTempRound,
          reorderRound,
          setReorderRound,
          isProgramsFlow: undefined,
          onSelectedExerciseChange,
          isDraggingSection,
          setIsDraggingSection,
          handleSaveClick,
          shouldScrollToError,
          triggerRecheck,
          setTriggerRecheck,
        }}
      >
        {!isEnduranceWorkout(workoutKind) && (
          <div
            // style={{
            //   minHeight: "100vh",
            //   width: "100vw",
            // }}
            style={{
              background: "#F9F9F9",
            }}
          >
            {isLoading && (
              <div
                style={{
                  height: "100vh",
                  width: "100vw",
                  backgroundColor: "rgba(0,0,0,0.3)",
                  borderRadius: "0px 0px 16px 16px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "fixed",
                  top: 0,
                  left: 0,
                  zIndex: 1000,
                }}
              >
                <SpurfitCircularProgress />
              </div>
            )}
            <TopBar
              data={data}
              workoutInfo={workoutInfo}
              setWorkoutInfo={setWorkoutInfo}
              rounds={rounds}
              isUpdate={isUpdate}
              isAssign={isAssign}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              setShowWorkoutInfoDialog={setShowWorkoutInfoDialog}
              isStructured={isStructured}
              videoData={videoData}
              isVideoUpdate={isVideoUpdate}
              comingFromModal={true}
              isImport={isImport}
              setWorkoutBuilderModal={setOpen}
              setSelectedToShow={setSelectedToShow}
              setNewCheckedItems={setNewCheckedItems}
              setIsOpenForWorKoutInfo={setIsOpenForWorKoutInfo}
              PropsData={PropsData} //this is when you are reaching this page from clientsView
              setOpenForWorkoutBuilderModal={setOpen}
              isFromClientView={isFromClientView}
              reloadProfileForWorkoutBuilderModal={
                reloadProfileForWorkoutBuilderModal
              }
              onSuccess={onSuccess}
              onSelectedExerciseChange={onSelectedExerciseChange}
              saveDataToLocalStorage={saveDataToLocalStorage}
              fromWorkout={fromWorkout}
              onSuccessOfEditWokroutFromWorkout={
                onSuccessOfEditWokroutFromWorkout
              }
              creatFromWorkout={creatFromWorkout}
              onSuccessOfNewWorkoutForm={onSuccessOfNewWorkoutForm}
            />

            <div
              style={{
                paddingTop: "100px",
                paddingLeft: "65px",
                paddingRight: "65px",
                width: "100%",
              }}
            >
              {isStructured && (
                <DragDropContext
                  onDragStart={(result) => {
                    console.log("On Drag Start: ", result);
                    // if draggable id contains rounds then it's a section getting dragged
                    if (result?.draggableId?.includes("round")) {
                      setIsDraggingSection(true);
                      return;
                    }
                  }}
                  onDragUpdate={(result) => {}}
                  onDragEnd={(result) => {
                    console.log("On Drag End: ", result);
                    // if draggable id contains rounds then it's a section getting dragged
                    if (result?.draggableId?.includes("round")) {
                      let newRounds = [...rounds];
                      let [removed] = newRounds.splice(result.source.index, 1);
                      newRounds.splice(result.destination.index, 0, removed);
                      setRounds(newRounds);
                      setIsDraggingSection(false);
                      return;
                    }

                    // if destination and source droppeable id are same then call reorder function
                    if (
                      result.source.droppableId?.includes("section") &&
                      result.destination?.droppableId?.includes("section")
                    ) {
                      if (
                        result.destination?.droppableId ===
                        result.source?.droppableId
                      ) {
                        onReorderDragEnd2(result);
                      } else {
                        onDropInDifferentSection(result);
                      }
                      return;
                    } else if (
                      result.destination === null ||
                      result.destination?.droppableId ===
                        result.source?.droppableId
                    ) {
                      return;
                    }

                    let id = result?.draggableId;
                    console.log("Exercise", exercisesObj[id]);

                    let exerciseIndex = result?.destination?.index;
                    let roundIndex =
                      +result?.destination?.droppableId.split("-")[1];
                    console.log("roundIndex", roundIndex);
                    let isTABATA =
                      result?.destination?.droppableId.includes("TABATA");
                    let exercise = { ...exercisesObj[id] };
                    console.log("isTABATA", isTABATA, exercise);
                    if (isTABATA) {
                      let numRounds = rounds[roundIndex]?.round?.numRounds;
                      let durationObj = {};
                      for (let i = 1; i <= numRounds; i++) {
                        durationObj[i] = 20;
                      }
                      console.log("durationObj", durationObj);
                      exercise.duration = {
                        ...durationObj,
                      };

                      exercise.break = {
                        duration: 10,
                      };
                    }
                    console.log("exercise", exercise);
                    if (exercise) {
                      addExerciseToParticularRound(
                        roundIndex,
                        exerciseIndex,
                        exercise
                      );
                    }
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "24px",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        width: "21%",
                      }}
                    >
                      <SelectExercises
                        addExerciseToParticularRound={
                          addExerciseToParticularRound
                        }
                        rounds={rounds?.length}
                        disabled={reorderRound !== -1}
                      />
                    </div>

                    <div
                      style={{
                        minWidth: "40.9%",
                        flex: 1,
                      }}
                    >
                      <WorkoutContentPage
                        {...{
                          workoutInfo,
                          rounds,
                          setRounds,
                          setSelectedExerciseInfo,
                        }}
                        comingFromModal={true}
                        shouldScrollToError={shouldScrollToError}
                        triggerRecheck={triggerRecheck}
                        showErrorWarning={showErrorWarning}
                      />
                    </div>

                    <div
                      style={{
                        width: "25%",
                        position: "relative",
                      }}
                    >
                      {showAddPropertiesModal && (
                        <>
                          {
                            // check round type is Warm Up
                            rounds?.[selectedExerciseInfo?.roundIndex]?.round
                              ?.type === "Warm Up" ||
                            rounds?.[selectedExerciseInfo?.roundIndex]?.round
                              ?.type === "Mixed Sets" ? (
                              <AddWarmUpProperties
                                key={
                                  selectedExerciseInfo?.exerciseId +
                                  "addProperties" +
                                  selectedExerciseInfo?.roundIndex +
                                  "addProperties" +
                                  selectedExerciseInfo?.exerciseIndex
                                }
                                ref={addPropertiesWarmUpRef}
                                open={showAddPropertiesModal}
                                setOpen={setShowAddPropertiesModal}
                                exercise={
                                  rounds[selectedExerciseInfo.roundIndex]?.round
                                    ?.measurements?.[
                                    selectedExerciseInfo.exerciseIndex
                                  ]?.measurement
                                }
                                updateExerciseProperty={updateExerciseProperty}
                                roundIndex={selectedExerciseInfo.roundIndex}
                                index={selectedExerciseInfo.exerciseIndex}
                                rounds={
                                  rounds?.[selectedExerciseInfo.roundIndex]
                                    ?.round?.measurements?.[
                                    selectedExerciseInfo.exerciseIndex
                                  ]?.measurement?.numRounds || 1
                                }
                                incrementRound={() =>
                                  incrementWarmUpRoundForAnExercise(
                                    selectedExerciseInfo.roundIndex,
                                    selectedExerciseInfo.exerciseIndex
                                  )
                                }
                                decrementRound={() =>
                                  decrementWarmUpRoundForAnExercise(
                                    selectedExerciseInfo.roundIndex,
                                    selectedExerciseInfo.exerciseIndex
                                  )
                                }
                                getUpdatedProperty={getUpdatedProperty}
                                selectedExerciseInfo={selectedExerciseInfo}
                                updateExerciseToParticularRound={
                                  updateExerciseToParticularRound
                                }
                                comingFromModal={true}
                              />
                            ) : (
                              <AddProperties
                                ref={addPropertiesRef}
                                key={
                                  selectedExerciseInfo?.exerciseId +
                                  "addProperties" +
                                  selectedExerciseInfo?.roundIndex +
                                  "addProperties" +
                                  selectedExerciseInfo?.exerciseIndex
                                }
                                open={showAddPropertiesModal}
                                roundIdx={roundIdx}
                                setRoundIdx={setRoundIdx}
                                idx={idx}
                                setIdx={setIdx}
                                setOpen={setShowAddPropertiesModal}
                                exercise={
                                  rounds[selectedExerciseInfo.roundIndex]?.round
                                    ?.measurements?.[
                                    selectedExerciseInfo.exerciseIndex
                                  ]?.measurement
                                }
                                updateExerciseProperty={updateExerciseProperty}
                                roundIndex={selectedExerciseInfo.roundIndex}
                                index={selectedExerciseInfo.exerciseIndex}
                                rounds={
                                  rounds?.[selectedExerciseInfo.roundIndex]
                                    ?.round?.numRounds
                                }
                                incrementRound={() =>
                                  incrementRound(
                                    selectedExerciseInfo.roundIndex
                                  )
                                }
                                decrementRound={() =>
                                  decrementRound(
                                    selectedExerciseInfo.roundIndex
                                  )
                                }
                                getUpdatedProperty={getUpdatedProperty}
                                selectedExerciseInfo={selectedExerciseInfo}
                                updateExerciseToParticularRound={
                                  updateExerciseToParticularRound
                                }
                                comingFromModal={true}
                                setTriggerRecheck={setTriggerRecheck}
                              />
                            )
                          }
                        </>
                      )}
                    </div>
                  </div>
                </DragDropContext>
              )}
            </div>

            {isStructured === false && (
              <FollowAlongBuilder
                setVideoData={setVideoData}
                preleminaryData={workoutData}
                isStructured={isStructured}
                isVideoUpdate={isVideoUpdate}
              />
            )}
            {/* {!isStructured && !workoutData.isVideoUpdate && <FollowAlongBuilder setVideoData = {setVideoData}/>} */}
          </div>
        )}

        {isEnduranceWorkout(workoutKind) && (
          <EnduranceBuilder
            isBike={workoutInfo === "bike"}
            isRun={workoutInfo === "run"}
            isSwim={workoutInfo === "swim"}
            isAssign={isAssign}
            data={data}
            isUpdate={isUpdate}
            workoutInfo={workoutInfo}
            isProgramsFlow={true}
            workoutContent={
              rounds?.[0]?.round?.measurements?.length > 0
                ? { rounds }
                : workoutKind === "swim"
                ? { rounds: swimRounds }
                : workoutKind === "bike"
                ? { rounds: bikeRounds }
                : { rounds: runRounds }
            }
            comingFromModal={true}
            setWorkoutBuilderModal={setOpen}
            setNewCheckedItems={setNewCheckedItems}
            setSelectedToShow={setSelectedToShow}
            PropsData={PropsData} //this is when you are reaching this page from clientsView
            setOpenForWorkoutBuilderModal={setOpen}
            isFromClientView={isFromClientView}
            reloadProfileForWorkoutBuilderModal={
              reloadProfileForWorkoutBuilderModal
            }
            setIsOpenForWorKoutInfo={setIsOpenForWorKoutInfo}
            onSuccess={onSuccess}
            fromWorkout={fromWorkout}
            onSuccessOfEditWokroutFromWorkout={
              onSuccessOfEditWokroutFromWorkout
            }
            creatFromWorkout={creatFromWorkout}
            onSuccessOfNewWorkoutForm={onSuccessOfNewWorkoutForm}
          />
        )}
        {showWorkoutInfoDialog && (
          <WorkoutInfoDialog
            workoutInfo={workoutInfo}
            setWorkoutInfo={setWorkoutInfo}
            isOpen={showWorkoutInfoDialog}
            setIsOpen={setShowWorkoutInfoDialog}
            isEdit={true}
            isUpdate={true}
          />
        )}
      </WorkoutModalContext.Provider>
    </Dialog>
  );
};

export default WorkoutBuilderModal;

const isEnduranceWorkout = (workoutKind) => {
  return (
    workoutKind === "run" || workoutKind === "bike" || workoutKind === "swim"
  );
};
