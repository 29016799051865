import { store } from "@/redux/store";
import axios from "axios";

const genericErrMsg = Error("Something went wrong!");
export const getTrainerDashboard = async ({ coachID }) => {
  try {
    const res = await axios({
      method: "get",
      url: `${
        store.getState().auth.apiURL
      }CF/api/CoachWeb/Get/trainerDashboardAll`,
      params: {
        coachID,
      },
    });

    const { data, status } = res;

    if (status === 200 && data && data.result) {
      return res.data.result;
    }

    throw Error(res?.statusText) || genericErrMsg;
  } catch (err) {
    throw new Error(err);
  }
};

export const deleteLead = async (row) => {
  try {
    const res = await axios({
      method: "post",
      url: `${store.getState().auth.apiURL}CF/api/LeadGen/deleteLead`,
      data: {
        id: row?.id,
      },
    });
    const { data, status } = res;
    if (status === 200 && data && data.result) {
      return res.data.result;
    }
    throw Error(res?.statusText) || genericErrMsg;
  } catch (err) {
    throw new Error(err);
  }
};

export const deleteAtheleteByID = async (id) => {
  try {
    const res = await axios({
      method: "post",
      url: `${
        store.getState().auth.apiURL
      }CF/api/CoachWeb/Delete/deleteAtheleteByID`,
      data: {
        atheleteID: id,
      },
    });
  } catch (err) {
    console.log(err);
  }
};

export const reassignProgramAndMealPlanOnPlanStart = async (data) => {
  try {
    const res = await axios({
      method: "post",
      url: `${
        store.getState().auth.apiURL
      }CF/api/reassignProgramAndMealPlanOnPlanStart`,
      data,
    });
    const { data, status } = res;
    if (status === 200 && data && data.result) {
      return res.data.result;
    }
  } catch (err) {
    console.log(err);
  }
};

export const assignPhasedProgramDashboard = async (payload) => {
  try {
    const res = await axios({
      method: "post",
      url: `${
        store.getState().auth.apiURL
      }CF/api/programPhases/addPhasedProgramDashboard`,
      data: payload,
    });
    const { data, status } = res;
    if (status === 200 && data && data.result) {
      return res.data.result;
    }
  } catch (err) {
    console.log(err);
  }
};
