import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
} from "@/components/ui/dialog";

const ConfirmDialog = ({ isOpen, onDiscard, onSave, onContinue }) => {
  return (
    <Dialog open={isOpen}>
      <DialogPortal>
        <DialogOverlay className="bg-black-pure bg-opacity-40 z-[9999]" />
      </DialogPortal>
      <DialogContent
        hideClose={true}
        className="sm:max-w-md z-[9999] font-DMSans outline-none"
      >
        <DialogHeader>
          <DialogTitle className="font-DMSans text-font18">
            Unsaved Changes
          </DialogTitle>
        </DialogHeader>

        <div className="">
          <p className="font-DMSans text-font14">
            You have unsaved changes in your current program. Do you want to
            wanna save or continue editing?
          </p>
        </div>
        <DialogFooter className={"flex w-full items-center gap-3"}>
          <DialogClose>
            <p
              className="font-DMSans cursor-pointer text-[#ED6C02] text-font14"
              onClick={onDiscard}
            >
              Discard
            </p>
          </DialogClose>
          <DialogClose>
            <p
              className="font-DMSans cursor-pointer text-[#0288D1] text-font14"
              onClick={onSave}
            >
              Save
            </p>
          </DialogClose>
          <DialogClose>
            <button
              className="button-new text-font14 h-[38px] w-[100px]"
              onClick={onContinue}
            >
              Continue
            </button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmDialog;
