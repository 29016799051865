import React, { useRef, useEffect, useState } from "react";

const VideoThumbnail = ({ videoUrl }) => {
  return (
    <video
      style={{
        height: "100%",
        width: "100%",
        boxShadow: "2px 6px 37px 0px rgba(0, 0, 0, 0.05)",
        borderRadius: "4.329px",
      }}
    >
      <source src={videoUrl} type="video/mp4" />
    </video>
  );
};

export default VideoThumbnail;
