import { CloseRounded } from "@mui/icons-material";
import { Dialog, DialogActions } from "@mui/material";
import React, { useState } from "react";

import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";
import { SpurfitCircularProgress } from "../../../components/Dashboard/dashboard";
import TextArea from "../../../components/InputComponents/TextArea";
import { createWorkout, updateWorkout } from "../programsCF/helpers";

const UpdateCardioDialog = ({ isOpen, setIsOpen, onUpdateCardio, info }) => {
  const coachID = useSelector((state) => state.auth?.trainerUUID);
  const [cardioDescription, setCardioDescription] = useState(
    info?.description || ""
  );
  const [loading, setLoading] = useState(false);

  const onSave = async () => {
    setLoading(true);
    let newCardioWorkout = { ...cardioWorkout };

    newCardioWorkout.description = cardioDescription;
    if (cardioDescription.length > 0) {
      if (!info || info.id === "cardio_workout" || info?.rest) {
        let newID = `cardio_workout_${uuidv4()}`;

        newCardioWorkout.id = newID;
        await createWorkout({
          workout: {
            workoutInfo: {
              id: newID,
              description: cardioDescription,
              name: "cardio",
              numRounds: 1,
              coachID,
            },
            workoutContent: cardioWorkout.workoutContent,
          },
        });
      } else {
        await updateWorkout({
          workoutID: info?.id,
          workoutInfo: {
            description: cardioDescription,
            name: "cardio",
            numRounds: 1,
            coachID,
            // isCoachWorkout: false,
          },
          workoutContent: cardioWorkout.workoutContent,
        });
      }
    }

    onUpdateCardio(newCardioWorkout);
    setIsOpen(false);
    setLoading(false);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "24px",
        },
      }}
    >
      {loading && (
        <div className="absolute h-full w-full flex items-center justify-center">
          <SpurfitCircularProgress />
        </div>
      )}
      <div className="add-program-modal-head">
        <div style={{ display: "flex", alignItems: "flex-end", gap: "8px" }}>
          <p className="add-program-modal-head-title">Cardio Instruction</p>
        </div>
        <div
          onClick={() => {
            setIsOpen(false);
          }}
          className="cursor-pointer"
        >
          <CloseRounded
            sx={{
              fontSize: "24px",
              color: "#000",
              cursor: "pointer",
            }}
          />
        </div>
      </div>
      <div className="add-program-modal-body">
        <TextArea
          id={"cardioDescription"}
          value={cardioDescription}
          onChange={(e) => setCardioDescription(e.target.value)}
          label={"Instructions"}
          placeholder={"Do 1 hour walk... or Do a 30 min jog..."}
        />
      </div>
      <DialogActions
        sx={{
          justifyContent: "center",
        }}
      >
        {cardioDescription.length > 0 ? (
          <button className="button-new" onClick={onSave}>
            {info?.description ? "Update" : "Save"}
          </button>
        ) : (
          <button
            style={{
              borderWidth: 2,
              borderRadius: 100,
              width: "148px",
              height: "44px",
            }}
            className="button-new-outlined"
            onClick={onSave}
          >
            Skip
          </button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default UpdateCardioDialog;

const cardioWorkout = {
  id: "cardio_workout",
  name: "cardio",
  numRounds: 1,
  runTime: 0,
  restTime: 0,
  visibility: false,
  benchMark: false,
  expectedCalories: 0,
  equipment: null,
  bodyPart: null,
  description: null,
  instructions: null,
  coachID: "PXqGTqMrfblJ",
  workoutType: "NOSCORE",
  workoutKind: null,
  tag: null,
  dateCreated: null,
  workoutContent: {
    rounds: [
      {
        round: {
          name: "",
          numRounds: 1,
          measurements: [
            {
              measurement: {
                movementName: "Do cardio of your choice",
              },
            },
          ],
        },
      },
    ],
  },
  videoLinks: null,
  isCoachWorkout: true,
};
