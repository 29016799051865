import React, { useEffect, useState } from "react";
import OverviewTab from "./OverviewTab";
import SummarSection2 from "../DescriptionComp/SummarSection2";
import Notes from "../Notes/Notes";
import { makeStyles } from "@mui/styles";
import UserForms from "./UserForms";
import Checkbox from "@mui/material/Checkbox";

import {
  Autocomplete,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  FormGroup,
  CircularProgress,
} from "@mui/material";
import { MultilineInputComponent } from "@/pages/trainerSignup";
import { getFormByathleteID } from "@/ApiServices/ClientDetails/Api";

function AssignedForms({
  userUUID,
  trainerUUID,
  formsAssigned,
  formAnswered,
  isAdminUserDetail,
  setForms,
  form
}) {
  
   
  const [loading, setLoading] = useState(false);
  const [selectedForm, setSelectedForm] = useState({});

  // useEffect(() => {
  //   console.log("FETCHING DATA TO GET RETRIEVE", userUUID);
  //   setLoading(true);
  //   if (userUUID) {
  //     getForm();
  //   }
  // }, []);

  // async function getForm() {
  //   const res = await getFormByathleteID(userUUID);
  //   console.log(res, "response from api");
  //   if (res && res.length > 0) {
  //     setForms(res);
  //   }
  //   setLoading(false);
  // }
  const selectForm = (currentForm) => {
    console.log(currentForm);
    setSelectedForm(currentForm);
  };
  const renderStepContent = (field) => {
    console.log(field, "fields");
    switch (field.type) {
      case "Single Select":
        return (
          <section className="usection-container">
            {/* <div className="section-header">
                            <div className="section-header-text">{field.value}</div>
                        </div> */}
            <div
              style={{
                width: "100%",
              }}
              className="usection-body"
            >
              <div
                style={{
                  fontSize: 16,
                  color: "#424242",
                }}
                className="text-sm tracking-wide font-DMSans"
              >
                {field.value}
              </div>
              <RadioGroup
                row
                defaultValue={field.answer}
                aria-labelledby="demo-row-radio-buttons-group-label"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
                control={
                  <Radio
                    disabled={true}
                    sx={{
                      "&.MuiButtonBase-root-MuiRadio-root": {
                        color: "#8330e9",
                      },
                      "& .MuiSvgIcon-root": {
                        color: "#8330e9",
                      },
                      color: "#8330e9",
                    }}
                  />
                }
                name={field.id}
              >
                <FormControlLabel
                  className="font-DMSans tracking-wide text-sm"
                  key={field.answer}
                  value={field.answer}
                  control={<Radio color="royalBlue" />}
                  label={field.answer}
                  disabled
                />
                {/* {field.possibleAnswers.map((option) => (
                                    <FormControlLabel
                                        className="font-DMSans tracking-wide text-sm"
                                        key={option}
                                        value={option}
                                        control={<Radio color="royalBlue" />}
                                        label={option}
                                        disabled
                                    />
                                ))} */}
              </RadioGroup>
              {/* <ShowError
                            error={formik.touched[field.id] && formik.errors[field.id]}
                            errorMessage={formik.errors[field.id]}
                        /> */}
            </div>
          </section>
        );
      case "Multi Select":
        return (
          <section className="usection-container">
            <div style={{ width: "100%" }} className="usection-body">
              <div
                style={{ fontSize: 16, color: "#424242" }}
                className="text-sm tracking-wide font-DMSans"
              >
                {field.value}
              </div>
              <FormGroup>
                {field.answer.map((option) => (
                  <FormControlLabel
                    key={option}
                    disabled
                    control={
                      <Checkbox
                        disabled
                        checked
                        sx={{
                          "& .MuiSvgIcon-root": {
                            color: "rgba(0, 0, 0, 0.38)",
                          },
                        }}
                      />
                    }
                    label={option}
                    onChange={(e) =>
                      handleMultiSelectChange(
                        field.id,
                        e.target.checked,
                        option
                      )
                    }
                    sx={{
                      "& .Mui-checked": {
                        color: "rgba(0, 0, 0, 0.38)", // Color when checked
                      },
                    }}
                  />
                ))}
              </FormGroup>
            </div>
          </section>
        );
      case "Multi-line type":
        return (
          <section className="usection-container">
            <div style={{ width: "100%" }} className="usection-body">
              <div
                style={{ fontSize: 16, color: "#424242" }}
                className="text-sm tracking-wide font-DMSans"
              >
                {field.value}
              </div>
              <MultilineInputComponent
                label=""
                placeholder=""
                type="text"
                disabled={true}
                value={field.answer}
                error={field.value && field.value}
                inputStyle={{ color: "rgba(0, 0, 0, 0.38)" }}
              />
            </div>
          </section>
        );
      default:
        return <TextField fullWidth />;
    }
  };
  return (
    <>
      {loading ? (
        <div
          style={{
            boxShadow: "0px 2px 5px -1px rgba(0,0,0,0.13)",
          }}
          className=" bg-white-pure rounded-xl w-full overflow-y-auto"
        >
          <div
            className={"w-full overflow-y-auto"}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vw",
            }}
          >
            <CircularProgress size={20} sx={{ color: "#6242EA" }} />
          </div>
        </div>
      ) : (
        <div className="flex items-start gap-[8px] w-full overflow-y-auto mb-4 pb-4">
          <UserForms
            isAdminUserDetail={isAdminUserDetail}
            data={form}
            userUUID={userUUID}
            setSelectedForm={selectForm}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#FFFFFF",
              width: "100%",
              minWidth: 400,
            }}
          >
            <div
              style={{
                padding: "12px 24px",
                borderBottom: "1px solid #EBEBEB",
              }}
            >
              <p
                style={{
                  fontFamily: "DM Sans",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  lineHeight: "29px",
                }}
              >
                Forms Data
              </p>
            </div>

            {!selectedForm ||
              !selectedForm?.responses ||
              !selectedForm?.responses?.length ? (
              <p
                style={{
                  fontFamily: "DM Sans",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "29px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "10px 0px",
                }}
              >
                Answers not found!
              </p>
            ) : (
              <div className="pb-6 overflow-y-auto mb-4 max-height[330px]">
                {selectedForm.responses.map((field) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      backgroundColor: "#FFFFFF",
                      borderRadius: "10px",
                      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.03)",
                      height: "fit-content",
                      width: "100%",
                      padding: "8px",
                      minWidth: 300,
                    }}
                  >
                    {renderStepContent(field)}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default AssignedForms;
