import React, { useState } from "react";
import { Collapse } from "@mui/material";
import { AddChannel } from "../../assets";
import { DownIcon } from "../../assets";
import {
  KeyboardArrowDownRounded,
  KeyboardArrowRightRounded,
} from "@mui/icons-material";

import "./TeamChannelList.css";

const ChannelList = (props) => {
  const {
    children,
    error = false,
    loading,
    setCreateType,
    setIsCreating,
    setIsEditing,
    type,
    isChannelsOpen,
    setIsChannelsOpen,
  } = props;

  const handleToggleCollapse = () => {
    console.log("handleToggleCollapse", props);
    if (
      isChannelsOpen !== undefined &&
      setIsChannelsOpen !== undefined &&
      setIsChannelsOpen !== null &&
      isChannelsOpen !== null
    ) {
      setIsChannelsOpen(!isChannelsOpen);
    }
  };

  if (error) {
    return type === "team" ? (
      <div className="team-channel-list">
        <p className="team-channel-list__message">
          Connection error, please wait a moment and try again.
        </p>
      </div>
    ) : null;
  }

  if (loading) {
    return (
      <div className="team-channel-list">
        <p className="team-channel-list__message loading">
          {type === "team" ? "Channels" : "Messages"} loading....
        </p>
      </div>
    );
  }

  return (
    <div className="team-channel-list">
      <div className="team-channel-list-line"></div>
      <div className="team-channel-list__header">
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            gap: 6,
          }}
          onClick={handleToggleCollapse}
        >
          <p className="team-channel-list__header__title">
            {isChannelsOpen ? (
              <KeyboardArrowDownRounded />
            ) : (
              <KeyboardArrowRightRounded />
            )}
            {type === "team" ? "Channels" : "Direct Messages"}
          </p>
        </div>
        <div className="team-channel-list__header__add">
          <AddChannel
            {...{ setCreateType, setIsCreating, setIsEditing }}
            type={type === "team" ? "team" : "messaging"}
          />
        </div>
      </div>
      <Collapse
        in={isChannelsOpen}
        timeout={500}
        sx={{
          transitionDuration: "500ms",
          transitionTimingFunction: "ease",
          height: "100%",
          overflowY: "scroll",
        }}
      >
        <div className="team-channel-list_members">{children}</div>
      </Collapse>
    </div>
  );
};

export const TeamChannelList = React.memo(ChannelList);
