
import React from 'react'
import Form from '../FormControl/Form'
import Stack from '@mui/material/Stack'
import { getCoachLeadForm } from '@/ApiServices/FormControl/Api'
import { useParams } from 'react-router-dom';
import { leadPresetData } from '@/constants';

const AddNewLeads = () => {

    const { id } = useParams();

    const [fields, setFields] = React.useState([])

    const handleSubmit = (data) => {
        console.log(data)
    }

    React.useState(() => {
        if (id)
            getLeadForm()
    }, [id])

    async function getLeadForm() {
        const coachForm = await getCoachLeadForm(id)
        if (coachForm && coachForm.result && coachForm?.result?.leadsQuestionnaire?.length) {
            // setFields([
            //     ...leadPresetData,
            //     ...(coachForm?.result?.leadsQuestionnaire?.map((item, index) => ({
            //         ...item,
            //         id: leadPresetData.length + index
            //     })) || [])
            // ]);
            setFields(coachForm?.result?.leadsQuestionnaire)

        } else {
            setFields([...leadPresetData])
        }
    }

    return (
        <Stack alignItems={'center'} margin={'5% auto 0 auto'}>
            <Stack
                width={'100%'}
                maxWidth={'450px'}
                height={'85vh'}
                sx={{ border: "1px solid #E5E7EB", borderRadius: 3, padding: '2rem 0' }}
                alignItems={'center'}
            >
                <Form fieldsData={fields}
                    handleClose={handleSubmit}
                    isFromCopyClipboard={true}
                />
            </Stack>
        </Stack>
    )
}

export default AddNewLeads