import React, { useState } from "react";
import { TextField, IconButton } from "@mui/material";
import { Clear } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";

function SearchBar({
  request,
  cancel,
  height,
  width,
  bgcol,
  fontSize,
  icon,
  placeholder,
  style,
}) {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    request(event.target.value);
  };

  const handleClear = () => {
    setSearchTerm("");
    cancel();
  };

  return (
    <TextField
      variant="standard"
      sx={{
        width: width || "auto",
        marginBottom: "auto",
        marginTop: "auto",
        mx: "10px",
        display: "flex",
        alignItems: "center",
        ...style,
      }}
      autoComplete="off"
      placeholder={placeholder || "Search anything"}
      value={searchTerm}
      onChange={handleSearch}
      InputProps={{
        style: {
          height: height,
          width: width || "100%",
          borderRadius: 50,
          padding: height != "40px" ? "2px 0px 0px 0px" : 0,
          background: bgcol,
          fontSize: fontSize,
          fontFamily: "DM Sans",
        },
        startAdornment: (
          <IconButton disabled>
            <SearchIcon />
            {/* <img
              style={{ marginLeft: "8px", marginRight: "2px" }}
              src={icon ? icon : SearchIcon}
            /> */}
          </IconButton>
        ),
        endAdornment: searchTerm && (
          <IconButton onClick={handleClear} disabled={!searchTerm}>
            <Clear fontSize="small" />
          </IconButton>
        ),
        disableUnderline: true,
      }}
    />
  );
}

export default SearchBar;

// import React, { useState } from "react";
// import { Search, Clear } from "@mui/icons-material";

// function SearchBar({ onSearch }) {
//   const [searchTerm, setSearchTerm] = useState("");

//   const handleSearch = (event) => {
//     setSearchTerm(event.target.value);
//     onSearch(event.target.value);
//   };

//   const handleClear = () => {
//     setSearchTerm("");
//     onSearch("");
//   };

//   return (
//     <div
//       style={{
//         border: "1px solid black",
//         borderRadius: 50,
//         height: "32px",
//         width: "193px",
//         marginTop: "auto",
//         marginBottom: "auto",
//         overflow: "hidden",
//       }}
//     >
//       <Search style={{ marginLeft: "15px", marginRight: "6px" }} />
//       <input
//         type="text"
//         placeholder="Search..."
//         style={{ outline: "none", border: "none" }}
//         value={searchTerm}
//         onChange={handleSearch}
//       />
//       {searchTerm && <Clear />}
//     </div>
//   );
// }

// export default SearchBar;
