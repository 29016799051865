import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FilterIcon from "../../../assets/workoutBuilder/filterIcon.svg";
import FilterActiveIcon from "../../../assets/workouts/ActiveFunnel.svg";
import WhitePlusIcon from "../assests/WhitePlusIcon";
function ProgramsList({
  programs,
  loaderNew,
  activeProgram,
  row,
  unsavedChanges,
  triggerChildFunction,
  getProgramDetails,
  adminID,
  handleConfirm,
  setaddProgramConfirmation,
  setShowModal,
  value,
  setValue,
  subbordinateCoachPrograms,
  setUnsavedChanges,
  setShowContinueEditing,
  setPrograms,
}) {
  console.log("programs", programs);
  const [activeTab, setActiveTab] = useState(0);

  const [templatePrograms, setTemplatePrograms] = useState([]);
  const [programPrograms, setProgramPrograms] = useState([]);
  const [openConfirmationModal, setOpenConfirmDialog] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [unsavedOnAddShow, setUnsavedOnAddShow] = useState(false);
  const [
    subbordinateCoachesToBeDisplayed,
    setSubbordinateCoachesToBeDisplayed,
  ] = useState([]);
  const [subbordinateCoachesNames, setSubbordinateCoachesNames] = useState([]);
  const isAdmin = useSelector((state) => state.auth?.data?.isAdmin);
  const [showFilteredIcon, setShowFilteredIcon] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setActiveTab(newValue);
  };
  const handleOnClickFilter = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleShowingFilteredData = (name) => {
    const filteredData = subbordinateCoachPrograms.filter(
      (item) => item?.coach?.name === name
    );
    console.log("filtered data", filteredData);
    setSubbordinateCoachesToBeDisplayed(filteredData);
    getProgramDetails(filteredData[0]?.id);
  };
  useEffect(() => {
    console.log("use effect called");
    if (programs) {
      const templatePrograms = programs.filter((program) => program.isTemplate);
      const programPrograms = programs.filter((program) => !program.isTemplate);
      setTemplatePrograms(templatePrograms);
      setProgramPrograms(programPrograms);
    }
    if (subbordinateCoachPrograms) {
      setSubbordinateCoachesToBeDisplayed(subbordinateCoachPrograms);
      const uniqueNamesSet = new Set();
      console.log("subb ordinate data", subbordinateCoachPrograms);
      // Iterate over the array of objects
      subbordinateCoachPrograms.forEach((obj) => {
        // Check if the 'name' property exists in the object
        if (obj.hasOwnProperty("coach") && obj.coach.hasOwnProperty("name")) {
          // Add the 'name' to the Set
          uniqueNamesSet.add(obj.coach.name);
        }
      });

      // Convert the Set back to an array
      const uniqueNamesArray = Array.from(uniqueNamesSet);
      console.log("unique", uniqueNamesArray);
      setSubbordinateCoachesNames(uniqueNamesArray);
    }
  }, [programs]);
  useEffect(() => {
    console.log("use effect called", activeProgram, programs);
    // let programPrograms = programs.filter((program) => !program.isTemplate);
    let programPrograms = programs.filter((program) => {
      return !program.isTemplate && program.coachID != adminID;
    });
    console.log("use effect", programPrograms);
    getProgramDetails(programPrograms[0]?.id);
  }, []);
  return (
    <>
      <Tabs
        value={activeTab}
        onChange={handleChange}
        aria-label="basic tabs example"
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Tab
          style={{
            fontSize: "12px",
            // padding y 0 and x 8px
            padding: "0 8px",
          }}
          label="My Programs"
          {...a11yProps(0)}
        />
        <Tab
          style={{
            fontSize: "12px",
            padding: "0 8px",
          }}
          label="Templates"
          {...a11yProps(1)}
        />
        {isAdmin && (
          <Tab
            style={{
              fontSize: "12px",
              padding: "0 8px",
            }}
            label="Others"
            {...a11yProps(2)}
          />
        )}
        {activeTab === 2 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "auto",
              position: "relative",
            }}
          >
            <button
              style={{
                width: "30px",
                height: "30px",
                cursor: "pointer",
              }}
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleOnClickFilter}
            >
              {showFilteredIcon ? (
                <img src={FilterActiveIcon} alt />
              ) : (
                <img src={FilterIcon} alt />
              )}
            </button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {subbordinateCoachesNames.map((name, index) => (
                <MenuItem
                  style={{
                    fontSize: "12px",
                  }}
                  key={index}
                  onClick={() => {
                    handleShowingFilteredData(name);
                    setAnchorEl(null);
                    setShowFilteredIcon(true);
                  }}
                >
                  {name}
                </MenuItem>
              ))}
              <MenuItem
                style={{
                  color: "red",
                  fontSize: "12px",
                }}
                onClick={() => {
                  setSubbordinateCoachesToBeDisplayed(
                    subbordinateCoachPrograms
                  );
                  getProgramDetails(subbordinateCoachPrograms[0].id);
                  setAnchorEl(null);
                  setShowFilteredIcon(false);
                }}
              >
                Clear Filter
              </MenuItem>
            </Menu>
          </div>
        )}
      </Tabs>

      <CustomTabPanel
        style={{
          width: "100%",
          height: "calc(100vh - 231px)",
          overflowY: "scroll",
        }}
        value={activeTab}
        index={0}
      >
        {programPrograms?.map((data, index) => {
          const days =
            !loaderNew && data.id == activeProgram ? row * 7 : data.duration;
          // data.duration = days;
          const weeks = Math.ceil(days / 7);
          console.log("programPrograms data", data);
          let isAdminProgram = data.coachID === adminID;
          if (isAdminProgram) {
            return <></>;
          }
          return (
            <div
              className={
                data.id == activeProgram
                  ? "program-card-container-selected"
                  : "program-card-container"
              }
              onClick={() => {
                if (data.id != activeProgram)
                  if (unsavedChanges) {
                    console.log("true");
                    setSelectedProgram(data);
                    setOpenConfirmDialog(true);
                  } else getProgramDetails(data.id);
              }}
            >
              <div
                className={
                  data.id == activeProgram
                    ? "channel-preview__wrapper_indicator__selected"
                    : "channel-preview__wrapper_indicator"
                }
                style={{
                  marginLeft: -10,
                }}
              ></div>
              <div
                style={{
                  width: "72px",
                  height: "48px",
                  backgroundColor: "#F2F2F2",
                  borderRadius: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* <NoImage /> */}
                <NoImageFound name={data.name} />
              </div>
              <div className="program-card-container-text">
                <p className="program-card-container-text-heading">
                  {data.name}
                  {adminID && data?.coachID === adminID && (
                    <span
                      style={{
                        fontSize: "8px",
                        fontWeight: 700,
                        color: "#09A033",
                        padding: "5px",
                        lineHeight: "10px",
                        fontFamily: "DM Sans",
                        backgroundColor: "#F7F7F7",
                        borderRadius: "10px",
                        marginLeft: "5px",
                      }}
                    >
                      {"ADMIN"}
                    </span>
                  )}
                </p>
                <p className="program-card-container-text-discription">
                  {weeks} week{weeks > 1 ? "s" : ""}
                </p>
              </div>
            </div>
          );
        })}
      </CustomTabPanel>

      {programPrograms && (
        <CustomTabPanel
          style={{
            width: "100%",
            height: "calc(100vh - 200px)",
            overflowY: "scroll",
          }}
          value={activeTab}
          index={1}
        >
          {templatePrograms?.map((data, index) => {
            const days =
              !loaderNew && data.id == activeProgram ? row * 7 : data.duration;
            // data.duration = days;
            const weeks = Math.ceil(days / 7);
            return (
              <div
                className={
                  data.id == activeProgram
                    ? "program-card-container-selected"
                    : "program-card-container"
                }
                onClick={() => {
                  if (data.id != activeProgram)
                    if (unsavedChanges) {
                      console.log("true");
                      setSelectedProgram(data);
                      setOpenConfirmDialog(true);
                    } else getProgramDetails(data.id);
                }}
              >
                <div
                  className={
                    data.id == activeProgram
                      ? "channel-preview__wrapper_indicator__selected"
                      : "channel-preview__wrapper_indicator"
                  }
                  style={{
                    marginLeft: -10,
                  }}
                ></div>
                <div
                  style={{
                    width: "72px",
                    height: "48px",
                    backgroundColor: "#F2F2F2",
                    borderRadius: "8px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <NoImage /> */}
                  <NoImageFound name={data.name} />
                </div>
                <div className="program-card-container-text">
                  <p className="program-card-container-text-heading">
                    {data.name}
                    {adminID && data?.coachID === adminID && (
                      <span
                        style={{
                          fontSize: "8px",
                          fontWeight: 700,
                          color: "#09A033",
                          padding: "5px",
                          lineHeight: "10px",
                          fontFamily: "DM Sans",
                          backgroundColor: "#F7F7F7",
                          borderRadius: "10px",
                          marginLeft: "5px",
                        }}
                      >
                        {"ADMIN"}
                      </span>
                    )}
                  </p>
                  <p className="program-card-container-text-discription">
                    {weeks} week{weeks > 1 ? "s" : ""}
                  </p>
                </div>
              </div>
            );
          })}
        </CustomTabPanel>
      )}
      <CustomTabPanel
        style={{
          width: "100%",
          height: "calc(100vh - 200px)",
          overflowY: "scroll",
        }}
        value={activeTab}
        index={2}
      >
        {subbordinateCoachesToBeDisplayed?.map((data, index) => {
          const days = data.duration;
          // data.duration = days;
          const weeks = Math.ceil(days / 7);
          return (
            <div
              className={
                data.id == activeProgram
                  ? "program-card-container-selected"
                  : "program-card-container"
              }
              onClick={() => {
                if (data.id != activeProgram)
                  if (unsavedChanges) {
                    console.log("true");
                    setOpenConfirmDialog(true);
                    // triggerChildFunction();
                    getProgramDetails(data.id);
                  } else {
                    getProgramDetails(data.id);
                  }
              }}
            >
              <div
                className={
                  data.id == activeProgram
                    ? "channel-preview__wrapper_indicator__selected"
                    : "channel-preview__wrapper_indicator"
                }
                style={{
                  marginLeft: -10,
                }}
              ></div>
              <div
                style={{
                  width: "72px",
                  height: "48px",
                  backgroundColor: "#F2F2F2",
                  borderRadius: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* <NoImage /> */}
                <NoImageFound name={data.name} />
              </div>
              <div className="program-card-container-text">
                <p className="program-card-container-text-heading">
                  {data.name}
                </p>
                <p className="program-card-container-text-discription">
                  {weeks} week{weeks > 1 ? "s" : ""}
                  <span
                    style={{
                      fontSize: "8px",
                      fontWeight: 700,
                      color: "#09A033",
                      padding: "5px",
                      lineHeight: "10px",
                      fontFamily: "DM Sans",
                      backgroundColor: "#F7F7F7",
                      borderRadius: "10px",
                      marginLeft: "5px",
                    }}
                  >
                    {data?.coach?.name}
                  </span>
                </p>
              </div>
            </div>
          );
        })}
      </CustomTabPanel>

      {(activeTab == 0 || activeTab == 1) && (
        <div
          className="add-program-fav-container"
          onClick={() => {
            let trainerDetails = JSON.parse(
              localStorage.getItem("trainerCredentials")
            );
            let unsavedData = localStorage.getItem("unsavedPrograms");
            if (unsavedChanges || unsavedData) {
              setOpenConfirmDialog(true);
              setUnsavedOnAddShow(true);

              // triggerChildFunction();
              // handleConfirm();
              // setaddProgramConfirmation(true);
              // setShowModal(true);
            } else {
              setShowModal(true);
            }
          }}
        >
          <WhitePlusIcon />
        </div>
      )}

      {openConfirmationModal && (
        <CustomModal
          isOpen={openConfirmationModal}
          close={setOpenConfirmDialog}
          onConfirm={() => {
            if (unsavedOnAddShow) {
              setShowModal(true);
            } else {
              getProgramDetails(selectedProgram.id);
            }
            setUnsavedChanges(false);
            setUnsavedOnAddShow(false);
            setShowContinueEditing(null);
          }}
          onCancel={() => setOpenConfirmDialog(false)}
          saveProgram={async () => {
            let res = await triggerChildFunction(true);
            setOpenConfirmDialog(false);
            if (unsavedOnAddShow) {
              setUnsavedOnAddShow(false);
              setShowModal(true);
              if (programs[0]?.id === 999) {
                setPrograms((prev) => {
                  let previous = [...(prev ?? [])];
                  previous[0] = res.data.result;
                  return previous;
                });
              }
            } else {
              getProgramDetails(selectedProgram?.id);
            }
            setUnsavedChanges(false);
          }}
        />
      )}
    </>
  );
}

export default ProgramsList;

const NoImageFound = ({ name }) => {
  const extractNames = (name) => {
    const names = name.split(" ");
    const firstName = names[0];
    const lastName = names.length > 1 ? names[1] : names[0];

    return { firstName, lastName };
  };

  const { firstName, lastName } = extractNames(name);

  return (
    <>
      <div
        style={{
          fontWeight: "700",
          fontFamily: "DM Sans",
          textTransform: "uppercase",
        }}
      >
        {firstName.split("")[0]}
        {lastName.split("")[0]}
      </div>
    </>
  );
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomModal({ onConfirm, onCancel, isOpen, close, saveProgram }) {
  const handleClose = (value) => {
    close(false);
    if (value === "continue") {
      onCancel();
    } else if (value === "discard") {
      onConfirm();
    } else {
      saveProgram();
    }
  };

  return (
    <Dialog open={isOpen}>
      <DialogTitle>Unsaved Changes</DialogTitle>
      <DialogContent>
        <p className="font-DMSans text-font14">
          You have unsaved changes in your current program. Do you want to wanna
          save or continue editing?
        </p>
      </DialogContent>
      <DialogActions>
        <Button color="warning" onClick={() => handleClose("discard")}>
          Discard
        </Button>
        <Button onClick={() => handleClose("save")} color="info">
          Save
        </Button>
        <button
          className="button-new"
          style={{
            width: 100,
          }}
          onClick={() => handleClose("continue")}
          color="primary"
        >
          Continue
        </button>
      </DialogActions>
    </Dialog>
  );
}
