import { store } from "@/redux/store";
import axios from "axios";

export const pausePlan = async (atheleteID) => {
  let res = await axios({
    method: "POST",
    url: `${store.getState().auth.apiURL}CF/api/LivEzy/pauseClient`,
    data: {
      atheleteID: atheleteID,
    },
  });

  return res;
};

export const resumePlan = async (atheleteID) => {
  let res = await axios({
    method: "POST",
    url: `${store.getState().auth.apiURL}CF/api/LivEzy/resumeClient`,
    data: {
      atheleteID: atheleteID,
    },
  });

  return res;
};

export const pasteWorkoutOnAssignedDate = async (data) => {
  let res = await axios({
    method: "POST",
    url: `${store.getState().auth.apiURL}CF/api/pasteWorkoutOnDate`,
    data: data,
  });

  return res.status === 200;
};

export const pasteDayOnAssignedDate = async (data) => {
  try {
    let res = await axios({
      method: "POST",
      url: `${store.getState().auth.apiURL}CF/api/workouts/pasteDayOnDate`,
      data: data,
    });

    return res.status === 200;
  } catch (error) {
    return false;
  }
};

export const getGarminSummaryByDateRange = async (
  athleteID,
  startDate,
  endDate
) => {
  try {
    let res = await axios({
      method: "GET",
      url: `${
        store.getState().auth.apiURL
      }CF/api/garmin/getGarminSummaryByDateRangeAtheleteID`,
      params: {
        athleteId: athleteID,
        startDate,
        endDate,
      },
    });

    return res.data;
  } catch (error) {
    return [];
  }
};
export const getFormByathleteID = async (athleteID) => {
  try {
    let res = await axios({
      method: "GET",
      url: `${
        store.getState().auth.apiURL
      }CF/api/customForms/getAllResponsesForAthlete`,
      params: {
        athleteId: athleteID,
      },
    });

    return res.data;
  } catch (error) {
    return [];
  }
};

export const getAllAtheletesOneCoach = async (coachID) => {
  try {
    let res = await axios({
      method: "GET",
      url: `${
        store.getState().auth.apiURL
      }CF/api/CoachWeb/Get/getAllAtheletesOneCoach`,
      params: {
        coachID: coachID,
      },
    });

    return res.data.result[0].atheletes;
  } catch (error) {
    return [];
  }
};

export const getSignedUrlClientExerciseVideos = async (url) => {
  try {
    let res = await axios({
      method: "POST",
      url: `${
        store.getState().auth.apiURL
      }api/getSignedUrlClientExerciseVideos`,
      data: {
        url: url,
      },
    });

    return res.data?.result;
  } catch (error) {
    return [];
  }
};
