import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { cn } from "@/lib/utils";

const CustomSelect = ({
  placeholder,
  options,
  value,
  onValueChange,
  className,
  contentClassName,
}) => {
  return (
    <Select value={value} onValueChange={onValueChange}>
      <SelectTrigger className={cn("w-[180px]", className)}>
        <SelectValue placeholder={placeholder ?? "Theme"} />
      </SelectTrigger>
      <SelectContent className={contentClassName}>
        {options.map((option, index) => {
          return (
            <SelectItem key={index} value={option.value}>
              {option.label}
            </SelectItem>
          );
        })}
      </SelectContent>
    </Select>
  );
};

export default CustomSelect;
