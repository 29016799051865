import React, { useState } from "react";
import { Resizable } from "re-resizable";
import { Draggable } from "react-beautiful-dnd";

const OneBlock = ({
  block,
  onResize,
  index,
  setIsResizing,
  selectedUnit,
  ratio,
}) => {
  const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #fff",
    background: "#9A95F2",
  };

  return (
    <Resizable
      size={{
        width: block.width * ratio,
        height: block?.exercise?.[selectedUnit]?.max * 2,
      }}
      enable={{
        top: false,
        right: true,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
      style={style}
      defaultSize={{
        width: 200,
        height: 200,
      }}
      onResizeStop={(e, direction, ref, d) => {
        setIsResizing(false);
        onResize(e, direction, ref, d);
      }}
      onResizeStart={() => setIsResizing(true)}
    >
      <div

      // {...provided.dragHandleProps}
      ></div>
    </Resizable>
    // <Draggable
    //   draggableId={index.toString()}
    //   index={index}
    //   isDragDisabled={isResizing}
    // >
    //   {(provided) => (
    //     <div
    //     ref={provided.innerRef}
    //     {...provided.draggableProps}
    //     >
    //     <Resizable
    //       size={block}
    //       style={style}
    //       defaultSize={{
    //         width: 200,
    //         height: 200,
    //       }}
    //       onResizeStop={(e, direction, ref, d) => {
    //         setIsResizing(false);
    //         onResize(e, direction, ref, d);
    //       }}
    //       onResizeStart={() => setIsResizing(true)}
    //     >
    //       <div

    //         {...provided.dragHandleProps}
    //       >
    //         00 {index}
    //       </div>
    //     </Resizable>
    //     </div>
    //   )}
    // </Draggable>
  );
};

export default OneBlock;
