import { DialogHeader } from "@/components/ui/dialog";
 
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { FolderSearch } from "lucide-react";
import React, { useContext, useEffect, useState } from "react";
import { CloseRounded, RoundedCorner } from "@mui/icons-material";
import { Folder } from "lucide-react";
import { NutritionContext } from "./NutritionContext";
import CustomSelect from "../WorkoutComponent/Endurance/util/CustomSelect";

function SelectFolderDialoge({ open, setOpen, selectedItem, folders }) {
  const {addItemToTheFolder} = useContext(NutritionContext)
 
  const [selected, setSelected] = useState("");

  const handleAddingToFolder = async (folder) => {
   const mealPlanIds=[selectedItem?.id]
   const res = addItemToTheFolder(folder, mealPlanIds, selectedItem);
   setOpen(false)
    
     
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "24px",
          width: "500px",
        },
      }}
    >
      <DialogHeader className="border-b-2 border-gray-200 flex flex-row justify-between p-1 items-center">
        <DialogTitle className="text-font18">Select A Folder</DialogTitle>
        <CloseRounded className="mr-2" onClick={() => setOpen(false)} />
      </DialogHeader>
      <DialogContent className=" w-[100%] font-DMSans h-[400px] overflow-y-scroll">
        <div className="flex flex-col gap-1 items-center justify-center">
        
          
          <label className="text-[14px] font-[500] self-start  pl-[12px] text-[#424242]">
            Select Any Folder
          </label>
          <CustomSelect
            isFolder={true}
            triggerClassName={
              "w-full text-font14 focus:ring-transparent focus:border-indigo-500 border-[1.5px]  "
            }
            onValueChange={(folder) => {
              setSelected(folder?.id);
              handleAddingToFolder(folder);
            }}
            options={folders}
            placeholder={"----"}
          />
          {folders?.length === 0 && <p className="text-[14px] font-[500] gradient-text mt-2">
            There Is No Folder Availabel Make A Folder First</p>}
           
        </div>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
}

export default SelectFolderDialoge;
