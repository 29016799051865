import { useContext, useState } from "react";
import { CopilotContext } from "../copilot";
import { colorsArray } from "../../../constants";
import { Typography } from "@mui/material";

import PencilIcon from "../../../assets/copilot/pencilIcon.svg";

export const WorkoutCard = ({
  day,
  workout,
  index,
  workoutName,
  showActions,
  responseIndex,
}) => {
  let exercises = "";

  exercises =
    workout &&
    workout.Workout !== "Rest Day" &&
    Object.entries(workout?.Workout || {})?.reduce((names, [key, value]) => {
      if (key === "title") {
        return names;
      }
      return (
        names + value?.exercises?.map((exercise) => exercise.name).join(", ")
      );
    }, "");

  const [showFullText, setShowFullText] = useState(false);
  const toggleShowFullText = (e) => {
    e.stopPropagation();
    setShowFullText(!showFullText);
  };

  const maxLength = 81;
  const displayText = showFullText
    ? exercises
    : exercises.length > maxLength
    ? exercises.slice(0, maxLength) + "..."
    : exercises;

  const { onEditWorkout } = useContext(CopilotContext);

  return (
    <div
      style={{
        borderRadius: "8px",
        width: "235px",
        minHeight: "90px",
        boxShadow:
          "0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 4px 8px 0px rgba(0, 0, 0, 0.14)",
        textAlign: "left",
        paddingLeft: "16px",
        // marginBottom: "9px",
        fontFamily: "DM Sans",
        position: "relative",
        overflow: "hidden",
        backgroundColor: "#ffffff",
        paddingRight: "5px",
        marginBottom: "9px",
        cursor: "pointer",
      }}
      onClick={() => {
        onEditWorkout(index, responseIndex, showActions);
      }}
    >
      <div
        style={{
          position: "absolute",
          left: 0,
          height: "100%",
          width: "5px",
          backgroundColor: colorsArray[index % colorsArray.length],
        }}
      />
      {day && (
        <Typography
          sx={{
            fontSize: "8px",
            fontWeight: 700,
            color: "#09A033",
            paddingTop: "14px",
            lineHeight: "10px",
            fontFamily: "DM Sans",
          }}
        >
          {day.toUpperCase().replace("-", " ")}
        </Typography>
      )}

      {showActions && (
        <img
          style={{
            height: "12px",
            width: "12px",
            position: "absolute",
            right: "9px",
            top: "8px",
            cursor: "pointer",
          }}
          src={PencilIcon}
          onClick={() => {
            onEditWorkout(index, responseIndex, true);
          }}
        />
      )}

      <Typography
        sx={{
          fontSize: "12px",
          fontWeight: 500,
          color: "#000",
          paddingTop: "1px",
          lineHeight: "16px",
          fontFamily: "DM Sans",
        }}
      >
        {workoutName}
      </Typography>
      <Typography
        sx={{
          color: "rgba(36, 36, 36, 0.75)",
          fontSize: "10px",
          marginTop: "4px",
          fontFamily: "DM Sans",
          fontWeight: 400,
          display: "-webkit-box",
          "-webkit-line-clamp": 2,
          "-webkit-box-orient": "vertical",
          overflow: "hidden",
        }}
      >
        {displayText}
      </Typography>
      {exercises.length > maxLength && (
        <button
          onClick={toggleShowFullText}
          style={{
            marginTop: "6px",
            color: "#2353EB",
            fontSize: "10px",
            marginBottom: "13px",
            fontFamily: "DM Sans",
          }}
        >
          {showFullText ? "See less" : "See more"}
        </button>
      )}
    </div>
  );
};
