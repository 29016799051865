import { store } from "@/redux/store";
import axios from "axios";

export const getFoodByCoachID = async (coachID) => {
  try {
    const res = await axios({
      method: "post",
      url: `${store.getState().auth.apiURL}CF/api/getFoodByUserId`,
      data: {
        userId: coachID,
      },
    });
    return res?.data?.foods ?? [];
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const getAllCoachFoods = async (coachID, adminID) => {
  let coachFoods = coachID ? await getFoodByCoachID(coachID) : [];
  let adminFoods = adminID ? await getFoodByCoachID(adminID) : [];
  return [...coachFoods, ...adminFoods];
};

export const createFolder = async (data) => {
  let res = await axios({
    method: "post",
    url: `${store.getState().auth.apiURL}CF/api/folders/createMealPlanFolder`,
    data: data,
  });
  console.log("this data is", res);
  return res?.data;
};

export const getAllTheFolders = async (data) => {
  let res = await axios({
    method: "get",
    url: `${store.getState().auth.apiURL}CF/api/folders/getAllMealPlanFolders`,
    params: {
      coachID: data,
    },
  });
  console.log("this data is", res);
  return res?.data;
};

export const updateFolderInfo = async (data) => {
  let res = await axios({
    method: "post",
    url: `${store.getState().auth.apiURL}CF/api/folders/updateMealPlanFolder`,
    data: data,
  });
  console.log("this data is", res);
  return res?.data;
};

export const deleteFolder = async (data) => {
  let res = await axios({
    method: "post",
    url: `${store.getState().auth.apiURL}CF/api/folders/deleteMealPlanFolder`,
    data: data,
  });
  console.log("this data is", res);
  return res?.data;
};

export const addItemToFolder = async (data) => {
  let res = await axios({
    method: "post",
    url: `${
      store.getState().auth.apiURL
    }CF/api/folders/connectMealPlanToFolder`,
    data: data,
  });
  console.log("this data is", res);
  return res?.data;
};

export const removeItemFromFolder = async (data) => {
  let res = await axios({
    method: "post",
    url: `${
      store.getState().auth.apiURL
    }CF/api/folders/disconnectMealPlanFromFolder`,
    data: data,
  });
  console.log("this data is", res);
  return res?.data;
};

export const shiftItemBetweenFolder = async (data) => {
  let res = await axios({
    method: "post",
    url: `${
      store.getState().auth.apiURL
    }CF/api/folders/transferMealPlansToFolder`,
    data: data,
  });
  console.log("this data is", res);
  return res?.data;
};
