import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Box,
  Dialog,
  DialogContent,
  LinearProgress,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { useSelector } from "react-redux";

export default function ExploreFoodModal({
  open,
  setOpen,
  setSelectedFood,
  setOpenAddFoodModal,
}) {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const [searchedFood, setSearchedFood] = useState([]);
  const [searchedTerm, setSearchedTerm] = useState("");
  const [Loading, setLoading] = useState(false);

  const searchFood = () => {
    setLoading(true);
    axios({
      method: "POST",
      url: `${APIURL}CF/api/getAllFoods`,
      data: {
        recordsPerPage: 10,
        page: 1,
        searchQuery: searchedTerm,
      },
    })
      .then((res) => {
        console.log("fooddata", res.data.foods);
        setSearchedFood(res.data.foods);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        sx: {
          minWidth: "500px",
          minHeight: "400px",
        },
      }}
    >
      <CloseIcon
        onClick={() => setOpen(false)}
        sx={{
          position: "absolute",
          top: "10px",
          right: "10px",
          cursor: "pointer",
        }}
      />
      <DialogContent>
        <Box>
          <input
            type="text"
            placeholder="Search"
            style={{
              marginTop: "20px",
              width: "100%",
              height: "40px",
              borderRadius: "20px",
              paddingLeft: "20px",
              paddingRight: "20px",
              outline: "none",
              border: "1px solid #EDEBE9",
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                console.log("searchedTerm", searchedTerm);
                searchFood();
              }
            }}
            onChange={(e) => {
              setSearchedTerm(e.target.value);
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            overflowY: "scroll",
          }}
        >
          {Loading && (
            <Box sx={{ width: "100%", mt: "10px" }}>
              <LinearProgress
                color="info"
                sx={{
                  borderRadius: "10px",
                  backgroundColor: "#6F8099",
                }}
              />
            </Box>
          )}
          {searchedFood?.length <= 0 ? (
            <Typography
              sx={{
                mt: "120px",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "20px",
                fontFamily: "DM Sans",
                textAlign: "center",
              }}
            >
              Search for a Food Item in the Spur.fit Database
            </Typography>
          ) : (
            searchedFood.map((food) => {
              console.log("food", food);
              return (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "10px",
                    height: "80px",
                    padding: "15px 20px",
                    "&:hover": {
                      backgroundColor: "#F5F5F5",
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => {
                    setSelectedFood(food);
                    setOpen(false);
                    setOpenAddFoodModal(true);
                  }}
                >
                  <Avatar
                    sx={{
                      marginRight: "20px",
                    }}
                  >
                    {food?.food_name?.charAt(0)}
                  </Avatar>
                  <Typography>{food.food_name}</Typography>
                </Box>
              );
            })
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
