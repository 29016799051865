import React, { useState } from "react";
import { withWindowDimensions } from "../../utils/functions";
import { SpurfitCircularProgress } from "../Dashboard/dashboard";
import useTodosHook from "./useTodosHook";
import SelfAddedTodo from "./SelfAddedTodo";
import OtherTabsList from "./OtherTabsList";

const TAB = {
  SELF_ADDED: "self_added",
  PAUSE_AND_ACTIVE: "pause_and_active",
  PHASE_START: "phase_start",
  EXPIRY: "expiry",
};

const TodoList = (props) => {
  const { trainerUUID } = props;
  const [todos, loading, handleTodoAction] = useTodosHook({
    trainerUUID,
  });

  const [tab, setTab] = useState(TAB.SELF_ADDED);
  const [selectAll, setSelectAll] = useState(false);

  const getButtonClass = (buttonTab) => {
    const baseClass =
      "font-DMSans rounded-full text-[12px] font-bold text-center px-3 py-1 transition-all whitespace-nowrap button-large";
    const activeClass =
      "bg-gradient-to-r from-[#464FEB] to-[#8330E9] text-white-pure hover:opacity-90";
    const inactiveClass =
      "bg-gradient-to-r from-[#464FEB20] to-[#8330E920] text-[#464FEB] hover:from-[#464FEB30] hover:to-[#8330E930]";
    return `${baseClass} ${tab === buttonTab ? activeClass : inactiveClass}`;
  };

  const deleteFinished = () => {
    const finishedTodos = todos.filter((todo) => todo.isFinished);
    finishedTodos.forEach((todo) => {
      handleTodoAction({
        type: "delete",
        todoId: todo.id,
      });
    });
  };

  const deleteAll = () => {
    todos.forEach((todo) => {
      handleTodoAction({
        type: "delete",
        todoId: todo.id,
      });
    });
    setSelectAll(false);
  };

  const finishAll = async () => {
    handleTodoAction({
      type: "finishAll",
    });
    setSelectAll(false);
  };

  const renderTabContent = () => {
    switch (tab) {
      case TAB.SELF_ADDED:
        return (
          <SelfAddedTodo
            trainerUUID={trainerUUID}
            selectAll={selectAll}
            setSelectAll={setSelectAll}
          />
        );
      case TAB.PAUSE_AND_ACTIVE:
      case TAB.PHASE_START:
      case TAB.EXPIRY:
        return <OtherTabsList todoList={todos} todoType={tab} />;
      default:
        return (
          <SelfAddedTodo
            trainerUUID={trainerUUID}
            selectAll={selectAll}
            setSelectAll={setSelectAll}
          />
        );
    }
  };

  return (
    <div className="">
      <div
        className="flex justify-between items-center bg-white-pure"
        style={{
          padding: "12px 24px",
          borderBottom: "1px solid #EBEBEB",
        }}
      >
        <p
          style={{
            fontFamily: "DM Sans",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "29px",
          }}
        >
          Your Todo List
        </p>
        {todos.length > 0 && tab === TAB.SELF_ADDED && (
          <div className="flex items-center gap-3">
            {selectAll ? (
              <>
                <button
                  onClick={() => deleteAll()}
                  className="font-DMSans text-font14 text-red-600 font-semibold "
                >
                  Delete All
                </button>
                <button
                  onClick={() => finishAll()}
                  className="font-DMSans text-font14 text-black-pure font-semibold "
                >
                  Finish All
                </button>
              </>
            ) : (
              <button
                onClick={() => deleteFinished()}
                className="font-DMSans text-font14 text-red-600 font-semibold "
              >
                Delete Finished
              </button>
            )}
          </div>
        )}
      </div>
      {loading && (
        <div
          style={{
            backgroundColor: "rgba(225, 225, 225, 0.5)",
            zIndex: "100",
          }}
          className="absolute flex justify-center items-center w-full h-full -mt-14"
        >
          <SpurfitCircularProgress />
        </div>
      )}
      <div className="flex justify-end items-center gap-3 mt-[8px] mr-4">
        <button
          className={getButtonClass(TAB.SELF_ADDED)}
          onClick={() => setTab(TAB.SELF_ADDED)}
        >
          Self Added
        </button>
        <button
          className={getButtonClass(TAB.PAUSE_AND_ACTIVE)}
          onClick={() => setTab(TAB.PAUSE_AND_ACTIVE)}
        >
          Pause & Active
        </button>
        <button
          className={getButtonClass(TAB.PHASE_START)}
          onClick={() => setTab(TAB.PHASE_START)}
        >
          Phase
        </button>
        <button
          className={getButtonClass(TAB.EXPIRY)}
          onClick={() => setTab(TAB.EXPIRY)}
        >
          Plan Expiry
        </button>
      </div>
      {renderTabContent()}
    </div>
  );
};

export default withWindowDimensions(TodoList);
