
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import OverviewTab from "./OverviewTab";
import SummarSection2 from "../DescriptionComp/SummarSection2";
import Notes from "../Notes/Notes";
import { makeStyles } from "@mui/styles";
import UserForms from "./UserForms";
import Checkbox from "@mui/material/Checkbox";

import {
    Autocomplete,
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField,
    FormGroup,
    CircularProgress,
} from "@mui/material";
import { MultilineInputComponent } from "@/pages/trainerSignup";
import { getFormByathleteID } from "@/ApiServices/ClientDetails/Api";
import { convertOneDay } from "../copilot/workoutAssignFunctions";

const ViewFieldType = ({ field }) => {

    console.log({ rrr: field })

    const renderStepContent = (field) => {
        console.log({ red: field })
        switch (field.type) {
            case "Single Select":
                return (
                    <section className="usection-container" style={{ margin: '.7rem 0' }}>
                        <div
                            className="usection-body"
                        >
                            <div
                                style={{
                                    fontSize: 16,
                                }}
                                className="text-sm tracking-wide font-DMSans"
                            >
                                {field.question}
                            </div>
                            <RadioGroup
                                row
                                defaultValue={field.answer}
                                InputProps={{
                                    sx: {
                                        color: '#000',
                                    },
                                    readOnly: true,
                                }}
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",

                                }}
                                name={field.id}
                            >
                                <FormControlLabel
                                    className="font-DMSans tracking-wide text-sm"
                                    key={field.answer}
                                    value={field.answer}
                                    control={<Radio />}
                                    label={field.answer}
                                />
                            </RadioGroup>
                        </div>
                    </section>
                );
            case "Multi Select":
                return (
                    <section className="usection-container" style={{ margin: '.7rem 0' }}>
                        <div style={{ width: "100%" }} className="usection-body">
                            <div
                                style={{ fontSize: 16, color: "#424242" }}
                                className="text-sm tracking-wide font-DMSans"
                            >
                                {field.question}
                            </div>
                            <FormGroup>
                                {field.answer.map((option) => (
                                    <FormControlLabel
                                        key={option}
                                        control={
                                            <Checkbox
                                                checked
                                            />
                                        }
                                        label={option}
                                    />
                                ))}
                            </FormGroup>
                        </div>
                    </section>
                );
            case "Multi-line type":
                return (
                    <section className="usection-container" style={{ margin: '.7rem 0' }}>
                        <div style={{ width: "100%" }} className="usection-body">
                            <div
                                style={{ fontSize: 16, color: "#424242" }}
                                className="text-sm tracking-wide font-DMSans"
                            >
                                {field.question}
                            </div>
                            <MultilineInputComponent
                                label=""
                                placeholder=""
                                type="text"
                                disabled={true}
                                value={field.answer}
                                error={field.value && field.value}
                                // inputStyle={{ color: "rgba(0, 0, 0, 0.38)" }}
                                sx={{
                                    '& .MuiInputBase-input.Mui-disabled': {
                                        WebkitTextFillColor: '#000',
                                    },
                                    '& .MuiInputLabel-root.Mui-disabled': {
                                        color: 'rgba(0, 0, 0, 0.38)',
                                    },
                                }}
                            />
                        </div>
                    </section>
                );
            default:
                return (
                    <section className="usection-container" style={{ margin: '.7rem 0' }}>
                        <div style={{ width: "100%" }} className="usection-body">
                            <div
                                style={{ fontSize: 16, color: "#424242" }}
                                className="text-sm tracking-wide font-DMSans"
                            >
                                {field.question}
                            </div>
                            <TextField
                                fullWidth

                                label=""
                                placeholder=""
                                type="text"
                                disabled={true}
                                value={field.answer}
                                error={field.value && field.value}
                                inputStyle={{ color: "rgba(0, 0, 0, 0.38)" }}
                                sx={{
                                    '& .MuiInputBase-input.Mui-disabled': {
                                        WebkitTextFillColor: '#000',
                                    },
                                    '& .MuiInputLabel-root.Mui-disabled': {
                                        color: 'rgba(0, 0, 0, 0.38)',
                                    },
                                }}
                            />
                        </div>
                    </section>
                )
        }
    };



    return (
        // <Stack padding={'.2rem 0rem'} spacing={1}>
        //     <Typography variant='body2' fontWeight={'bold'}> {question}</Typography>
        //     <Typography variant='body2' sx={{ border: "1px solid #E5E7EB", borderRadius: 2, padding: '.6rem .2rem' }}> {answer}</Typography>
        // </Stack>

        <Stack>
            {renderStepContent(field)}
        </Stack>
    )
}

export default ViewFieldType

