import React, { useContext, useEffect, useState } from "react";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import NItem from "../NItem";
import {Avatar, Box, LinearProgress, Tab, Tabs, Tooltip, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import FolderListForMealPlan from "../FolderListForMealPlan";
import { Salad } from "lucide-react";
import { FOOD_BASED } from "@/constants";
import { NutritionContext } from "../NutritionContext";

const MealPlanList = (props) => {
  const {
    title = "",
    listData = [],
    defaultNItem = {},
    setdefaultNItem = () => {},
    handleAddModal = () => {},
    searchLoading,
  } = props;

  const {
    setSelectedTabInMeaLPlan,
    templateFolders,
    folders,
    setFolderSelected,
    setTemplateMealPlans,
    templateMealPlans,
    myMealPlans,
    setMyMealPlans,
    selectedTabInMeaLPlan,
    setOpenFolderId,
  } = useContext(NutritionContext);
  const [displaySearch, setDisplaySearch] = useState(false);
  const [searchedTerm, setSearchedTerm] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [pageNo, setPageNo] = useState(1);

 

  const isAdmin = useSelector((state) => state.auth?.data?.isAdmin);
  const adminID = useSelector((state) => state.auth.data?.adminID);

  const handleChange = (event, newValue) => {
   
    setSelectedTabInMeaLPlan(newValue)
    setActiveTab(newValue);
  };


   useEffect(() => {
       if (selectedTabInMeaLPlan == 0) {
         if (folders?.length > 0) {
           setFolderSelected(folders[0]);
           setOpenFolderId(folders[0]?.id);
           setdefaultNItem({});
         } else if (myMealPlans?.length > 0) {
           setdefaultNItem(myMealPlans[0]);
            setFolderSelected({});
            setOpenFolderId("");
         } else {
           setdefaultNItem({});
         }
       } else if (selectedTabInMeaLPlan == 1) {
           if (templateFolders?.length > 0) {
             setFolderSelected(templateFolders[0]);
             setOpenFolderId(templateFolders[0]?.id);
             setdefaultNItem({});
           }else if(templateMealPlans?.length > 0) {
           setdefaultNItem(templateMealPlans[0]);
           setFolderSelected({});
           setOpenFolderId("");
         } else {
           setdefaultNItem({});
         }
       }
    
   }, [activeTab]);

 

 

  return (
    <>
      <div className="relative h-full">
        <div
          style={{
            borderBottom: " 1px solid #ebebeb",
            paddingBottom: "8px",
            marginBottom: "8px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span className="text-font16 font-semibold">{title}</span>
            {!displaySearch ? (
              <SearchRoundedIcon
                className="cursor-pointer"
                onClick={() => {
                  setDisplaySearch(!displaySearch);
                }}
              />
            ) : (
              <CloseRoundedIcon
                className="cursor-pointer"
                onClick={() => {
                  setDisplaySearch(!displaySearch);
                  setSearchedTerm("");
                  props?.cancelSearch();
                }}
              />
            )}
          </div>

          {displaySearch && (
            <input
              type="text"
              placeholder="Search"
              style={{
                marginTop: "20px",
                width: "100%",
                height: "40px",
                borderRadius: "20px",
                paddingLeft: "20px",
                paddingRight: "20px",
                outline: "none",
                border: "1px solid #EDEBE9",
              }}
              autoFocus
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  props?.paginationQuery(searchedTerm);
                }
              }}
              onChange={(e) => {
                if (pageNo > 1) {
                  setPageNo(1);
                }
                let value = e.target.value;

                props?.paginationQuery(value);

                setSearchedTerm(value);
              }}
            />
          )}
          {searchLoading && <LinearProgress />}
        </div>

        <Tabs
          value={activeTab}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="My Meal Plans" {...a11yProps(0)} />
          <Tab label="Templates" {...a11yProps(1)} />
        </Tabs>
        <div
          className="relative h-full overflow-auto flex flex-col"
          style={{
            paddingBottom: displaySearch ? "150px" : "100px",
            gap: "8px",
            paddingTop: "8px",
          }}
        >
          <CustomTabPanel
            style={{
              paddingTop: "16px",
              width: "100%",
              height: "calc(100vh - 200px)",
              overflowY: "scroll",
            }}
            value={activeTab}
            index={0}
          >
            <FolderListForMealPlan folders={folders} />
            <ShowList
              listData={myMealPlans}
              defaultNItem={defaultNItem}
              setdefaultNItem={setdefaultNItem}
              defaultTab={activeTab}
              adminID={adminID}
              showAdminData={false}
              setFolderSelected={setFolderSelected}
            />
          </CustomTabPanel>
          <CustomTabPanel
            style={{
              paddingTop: "16px",
              width: "100%",
              height: "calc(100vh - 200px)",
              overflowY: "scroll",
            }}
            value={activeTab}
            index={1}
          >
            <FolderListForMealPlan folders={templateFolders} />
            <ShowList
              listData={templateMealPlans}
              defaultNItem={defaultNItem}
              setdefaultNItem={setdefaultNItem}
              defaultTab={activeTab}
              adminID={adminID}
              setFolderSelected={setFolderSelected}
            />
          </CustomTabPanel>
        </div>

        {(activeTab === 0 || activeTab === 1) && (
          <div
            style={{
              position: "absolute",
              top: "auto",
              bottom: "10px",
              right: "0px",
              borderRadius: "100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "44px",
              width: "44px",
              background:
                "linear-gradient(90deg, #464FEB 10.42%, #8330E9 100%)",
              cursor: "pointer",
            }}
            onClick={() => {
              // console.log("hello");
              handleAddModal(activeTab === 1 ? true : false);
            }}
          >
            <AddRoundedIcon
              style={{
                color: "white",
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default MealPlanList;

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const ShowList = ({
  listData,
  defaultNItem,
  setdefaultNItem,
  defaultTab,
  adminID,
  showAdminData,
  setFolderSelected,
}) => {
  return (
    <>
      {listData?.map((itf, iif) => {
        if (showAdminData === false && itf?.coachId === adminID) return <> </>;
        return (
          <NItemForFolder
            itf={itf}
            defaultNItem={defaultNItem}
            defaultTab={3}
            setFolderSelected={setFolderSelected}
            setdefaultNItem={(itm) => {
              setdefaultNItem(itm);
            }}
            isAdmin={adminID && itf?.coachId === adminID}
          />
        );
      })}
    </>
  );
};


const NItemForFolder = (props) => {
  const {
    defaultNItem = {},
    itf = {},
    setdefaultNItem = () => {},
    defaultTab,
    isAdmin,
    isOthersTab,
    setFolderSelected,
  } = props;

  const {setOpenFolderId}=useContext(NutritionContext)

  const adminID = useSelector((state) => state.auth?.data?.adminID);
  let isAdminItem = adminID === (itf?.coachId || itf?.coachID);
  console.log("isAdminItem", { isAdminItem, adminID, itf });

  const getInitials = (string) => {
    const names = string?.split(" ");
    if (_.isEmpty(names)) return;

    let initials = names[0].substring(0, 1).toUpperCase();
    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  const renderAvatar = () => {
    const getImage = () => {
      switch (defaultTab) {
        case 1:
          return itf?.image || itf?.food_image;
        case 2:
          return itf?.recipe_images?.[0];
        case 3:
          return itf?.image;
        case 4:
          return itf?.image;
        default:
          return "";
      }
    };

    let currentImage = getImage();

    if (currentImage) {
      return <Avatar alt="Remy Sharp" src={currentImage} />;
    } else {
      return (
        <Avatar
          sx={{
            height: "40px",
            width: "40px",
          }}
        >
          {getInitials(
            itf?.food_name ||
              itf?.recipe_name ||
              itf?.reference_name ||
              itf?.display_name ||
              itf?.name
          )}
        </Avatar>
      );
    }
  };

  const renderDetails = () => {
    const getDetails = () => {
      switch (defaultTab) {
        case 1:
          let serving =
            itf?.servings?.[0] ||
            itf?.servings?.serving?.[0] ||
            itf?.serving?.[0];
          return (
            setToOneFixedDecimal(serving?.metric_serving_amount) +
              " " +
              serving?.metric_serving_unit || ""
          );
        case 2:
          return itf?.serving_sizes?.serving_size || "";
        case 3:
          return "";
      }
    };
    if (defaultTab === 4 || defaultTab === 5) {
      return (
        <div className="flex flex-row items-center">
          <span className="text-font12">{itf?.serving}</span>
          {itf.link ? (
            <>
              <div
                className="bg-[#000] h-[3px] w-[3px] mx-2"
                style={{
                  borderRadius: 50,
                }}
              />
              <a href={itf.link} className="text-font12">
                {"Link"}
              </a>
            </>
          ) : null}
        </div>
      );
    } else {
      return <span className="text-font12">{getDetails()}</span>;
    }
  };

  const renderCalories = () => {
    const getCalories = () => {
      switch (defaultTab) {
        case 1:
          let serving =
            itf?.servings?.[0] ||
            itf?.servings?.serving?.[0] ||
            itf?.serving?.[0];
          let calories = serving?.calories;
          if (calories) return Math.round(calories);
          else {
            let carbs = +serving?.carbohydrate;
            let protein = +serving?.protein;
            let fat = +serving?.fat;

            if (carbs && protein && fat) {
              return Math.round(carbs * 4 + protein * 4 + fat * 9);
            }
          }
          return 0;
        case 2:
          return Math.round(itf?.serving_sizes?.calories || 0);
        case 3:
          return Math.round(itf?.plandetails?.calories || 0);
        default:
          return 0;
      }
    };
    let currentCalories = getCalories();
    if (currentCalories === 0) return null;
    const mealPlanType = itf?.meal_plan_type;
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {/* {defaultTab != 3 && (
          <div
            className="bg-[#000] h-[3px] w-[3px] mx-2"
            style={{
              borderRadius: 50,
            }}
          />
        )} */}
        <span
          style={{
            fontSize: 10,
            fontWeight: 600,
            color: "#6F8099",
          }}
          className="text-font12"
        >{`${getCalories()} Cals`}</span>
        {defaultTab === 3 && (
          <Tooltip
            title={
              mealPlanType === FOOD_BASED
                ? "Food Based Meal"
                : "Macro Based Meal"
            }
          >
            <Typography
              sx={{
                border: "1px solid #6F8099",
                fontSize: "8px",
                fontWeight: 500,
                color: "#6F8099",
                height: "12px",
                width: "14px",
                textAlign: "center",
                ml: 1,
              }}
            >
              {mealPlanType === FOOD_BASED ? "FB" : "MB"}
            </Typography>
          </Tooltip>
        )}
      </Box>
    );
  };

  const renderFromLibrary = () => {
    if (itf?.fromLibrary) {
      return (
        <div
          className="p-[2px] h-[15px] w-[15px] items-center justify-center flex ml-[10px]"
          style={{
            border: "1px solid #d9d9d9",
            borderRadius: 2,
          }}
        >
          <span className="text-font12 text-gray-600">{"F"}</span>
        </div>
      );
    }
  };

  return (
    <div
      className={
        itf?.id === defaultNItem?.id
          ? "program-card-container-selected"
          : "program-card-container"
      }
      // style={{
      //   backgroundColor: itf === defaultNItem ? "#6F809950" : "white",
      // }}
      onClick={() => {
        setFolderSelected({})
        setOpenFolderId("")
        setdefaultNItem(itf);
      }}
    >
      <div
        className={
          itf === defaultNItem
            ? "channel-preview__wrapper_indicator__selected"
            : "channel-preview__wrapper_indicator"
        }
        style={{
          marginLeft: -10,
        }}
      ></div>
      <div
        style={{
          width: "25px",
          height: "25px",
          backgroundColor: "#F2F2F2",
          borderRadius: "8px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {<Salad/>}
      </div>
      <div
        style={{
          width: "100%",
        }}
        className="program-card-container-text"
      >
        <p className="program-card-container-text-heading text-[14px]">
          {itf?.name ||
            itf.food_name ||
            itf.recipe_name ||
            itf?.reference_name ||
            itf.display_name}
          {isAdminItem && (
            <span
              style={{
                fontSize: "8px",
                fontWeight: 700,
                color: "#09A033",
                padding: "5px",
                lineHeight: "10px",
                fontFamily: "DM Sans",
                backgroundColor: "#F7F7F7",
                borderRadius: "10px",
                marginLeft: "5px",
              }}
            >
              {"ADMIN"}
            </span>
          )}
        </p>

        <p
          className="program-card-container-text-discription"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {renderDetails()}
          {renderCalories()}
          {renderFromLibrary()}
        </p>
        {isOthersTab && (
          <span
            style={{
              fontSize: "8px",
              fontWeight: 700,
              color: "#09A033",
              padding: "5px",
              lineHeight: "10px",
              fontFamily: "DM Sans",
              backgroundColor: "#F7F7F7",
              borderRadius: "10px",
              marginLeft: "5px",
            }}
          >
            {itf?.coach?.name || "-"}
          </span>
        )}
      </div>
    </div>
  );
};

