import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { ChevronDown, ChevronRight, Import } from "lucide-react";
import { useEffect, useState } from "react";
import { getMaxPhaseOrderIndex, sortPhases } from "../helper";
import { SpurfitCircularProgress } from "@/components/Dashboard/dashboard";
import SearchBar from "@/components/Dashboard/SearchBar";

const ImportPhaseModal = ({
  programs,
  templates,
  importPhaseToPhaseContainer,
  program,
  folders,
  templateFolders,
}) => {
  const [openedProgram, setOpenedProgram] = useState(null);
  const [showPhases, setShowPhases] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const [allProgramsToShow, setAllProgramsToShow] = useState([]);

  const handleProgramClick = (program) => {
    setOpenedProgram(program);
    setShowPhases(true);
  };

  const handleBack = () => {
    setShowPhases(false);
    setOpenedProgram(null);
  };

  const handleImport = async (phase) => {
    let latestPhaseOrderIdx = getMaxPhaseOrderIndex(program?.programs);
    importPhaseToPhaseContainer({
      phaseID: phase.id,
      isTemplate: phase.isTemplate,
      phaseOrderIdx: latestPhaseOrderIdx + 1,
      phaseContainerID: program.id,
    });
    setOpen(false);
  };

  const onSearch = (searchTerm) => {
    if (searchTerm === "") {
      let all = [
        ...(programs || []),
        ...(templates || []),
        ...getAllFolderProgramsAndPhases(),
      ];
      all = all.filter((p) => p?.programs?.length > 0);
      setAllProgramsToShow(all);
      return;
    }

    let all = [
      ...(programs || []),
      ...(templates || []),
      ...getAllFolderProgramsAndPhases(),
    ];
    all = all.filter((p) => p?.programs?.length > 0);
    all = all.filter((p) =>
      p?.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setAllProgramsToShow(all);
  };

  const getAllFolderProgramsAndPhases = () => {
    let all = [];
    folders?.forEach((folder) => {
      let containers = folder?.containers || [];
      let programs = folder?.programs || [];

      let allPrograms = [...programs, ...containers];
      all = [...all, ...allPrograms];
    });

    templateFolders?.forEach((folder) => {
      let containers = folder?.containers || [];
      let programs = folder?.programs || [];

      let allPrograms = [...programs, ...containers];
      all = [...all, ...allPrograms];
    });

    return all;
  };

  useEffect(() => {
    let all = [
      ...(programs || []),
      ...(templates || []),
      ...getAllFolderProgramsAndPhases(),
    ];

    // all = all.filter((p) => p?.programs?.length > 0);
    setAllProgramsToShow(all);
  }, [programs, templates, folders, templateFolders]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <div className="font-semibold text-[14px] cursor-pointer border-[#6241EA] border-2 px-[8px] py-[4px] rounded-md text-[#6241EA]">
          Import Phase
        </div>
      </DialogTrigger>
      <DialogContent className="p-1 ">
        {/* {loading && (
          <div className="absolute top-0 left-0 w-full h-full bg-black-pure/80 flex items-center justify-center z-[99999]">
            <SpurfitCircularProgress />
          </div>
        )} */}
        <DialogHeader>
          <DialogTitle className="font-DMSans text-font20 mb-1 px-2 py-4 border-b">
            Import Phases
          </DialogTitle>
          <div className="space-y-4 py-2 px-4 h-[450px] overflow-y-auto">
            {showPhases ? (
              <>
                <button
                  onClick={handleBack}
                  className="mb-3 font-DMSans text-font12 text-gray-600 hover:underline"
                >
                  Back to Programs
                </button>

                <p className="font-DMSans text-font12 text-gray-600">
                  Select the phases you want to import
                </p>
                <div className="px-2 grid grid-cols-3 gap-2 max-h-[300px] overflow-y-auto">
                  {sortPhases(openedProgram?.programs)?.map((phase) => (
                    <div
                      key={phase.id}
                      className="flex items-center gap-2 py-1 bg-[#fefefe] border border-gray-200 rounded-md px-3 cursor-pointer hover:bg-[#6241EA]/10 transition-colors duration-200 group"
                      onClick={() => handleImport(phase)}
                    >
                      <div style={{ width: "calc(100% - 30px)" }}>
                        <label
                          htmlFor={phase?.id}
                          className="font-DMSans text-font12"
                        >
                          {phase?.name}
                        </label>

                        <p className="font-DMSans text-font12">
                          {Math.ceil(phase?.duration / 4)} weeks
                        </p>
                      </div>
                      <Import
                        size={16}
                        className="hidden group-hover:block text-[#6241EA] transition-colors duration-200 ml-auto"
                      />
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <>
                <SearchBar
                  request={onSearch}
                  cancel={() => onSearch("")}
                  placeholder="Search Programs"
                  bgcol={"#F9F9F9"}
                  width={"100%"}
                  inputStyle={{
                    border: "1px solid #E5E5E5",
                    borderRadius: "50px",
                  }}
                  conatinerStyle={{
                    width: "100%",
                  }}
                />
                <div className="mt-2 max-h-[360px] overflow-y-auto overflow-x-hidden">
                  {allProgramsToShow?.map((program) => {
                    const isProgramWithPhases = program?.programs?.length > 0;
                    if (!isProgramWithPhases) return null;

                    return (
                      <div
                        key={program.id}
                        onClick={() => handleProgramClick(program)}
                        className="relative cursor-pointer transform transition-transform duration-200 hover:bg-[#6241EA]/10 hover:translate-x-1 px-4 py-2 rounded-md"
                      >
                        <div className="flex items-center justify-between">
                          <p className="text-font14 font-DMSans">
                            {program?.name}
                          </p>
                          <ChevronRight className="transform transition-transform duration-200" />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </DialogHeader>
        {showPhases && (
          <DialogFooter
            className="w-full flex items-center justify-center"
            style={{
              justifyContent: "center",
            }}
          >
            <p className="font-DMSans text-font12 text-gray-600 mt-auto">
              *Selected phase will be copied and added to the end of the
              selected program.*
            </p>
          </DialogFooter>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ImportPhaseModal;
