import { Button } from "@/components/ui/button";
import NoPrograms from "@/features/Programs/NoPrograms";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import axios from "axios";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import SpinnerComponent from "../../components/layout/spinner";
import AddSubscriptionDialog from "./AddSubscriptionDialog";

export default function Subscription({
  trainerData,
  subscriptions,
  setSubscriptions,
  getTrainerData,
}) {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const [loading, setLoading] = useState(false);
  // const [subscriptions, setSubscriptions] = useState([]);

  // for options in each row
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows = useMemo(
    () =>
      subscriptions?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [page, rowsPerPage]
  );

  const handleOptionClick = (option, subscriptionID) => {
    switch (option) {
      case "pause":
        pauseSubscription(subscriptionID);
        break;
      case "resume":
        resumeSubscription(subscriptionID);
        break;
      case "cancel":
        cancelSubscription(subscriptionID);
        break;
      default:
        break;
    }
    setAnchorEl(null);
  };

  const headerCellStyle = {
    fontWeight: 600,
    fontSize: ["14px", "14px", "14px"],
    color: "black",
    borderBottom: "1px solid #E7E7E7",
    borderTop: "1px solid #E7E7E7",
    padding: "5px 20px",
    backgroundColor: "#F3F3F3",
    width: "15%",
  };

  const bodyCellStyle = {
    fontWeight: 500,
    fontSize: ["14px", "14px", "14px"],
    color: "#071148",
    borderBottomColor: "rgb(224, 224, 224)",
    padding: "5px 20px",
    width: "15%",
  };
  const currencySymbols = {
    KRW: "₩",
    USD: "$",
    CAD: "$",
    INR: "₹",
    AED: "د.إ",
    EUR: "€",
    GBP: "£",
    SGD: "$",
  };

  const pauseSubscription = (subscriptionID) => {
    axios({
      method: "post",
      // url: `${APIURL}CF/api/stripeconnect/subscriptions/${subscriptionID}/pause`,
      url: `${APIURL}CF/api/stripeconnect/subscriptions/pauseSubscriptionPaymentLinks`,
      params: {
        tableID: subscriptionID,
      },
    })
      .then((res) => {
        toast.success("Subscription paused. Successfully!");
        updateStatus(subscriptionID, res.data.updatedSubscription.status);
      })
      .catch((err) => {
        toast.error("Not paused. Try again!");
      });
  };

  const resumeSubscription = (subscriptionID) => {
    axios({
      method: "post",
      // url: `${APIURL}CF/api/stripeconnect/subscriptions/${subscriptionID}/resume`,
      url: `${APIURL}CF/api/stripeconnect/subscriptions/resumeSubscriptionPaymentLinks`,
      params: {
        tableID: subscriptionID,
      },
    })
      .then((res) => {
        updateStatus(subscriptionID, res.data.resumedSubscription.status);
        toast.success("Subscription resumed. Successfully!");
      })
      .catch((err) => {
        toast.error("Not resumed. Try again!");
      });
  };

  const cancelSubscription = (subscriptionID) => {
    axios({
      method: "post",
      // url: `${APIURL}CF/api/stripeconnect/subscriptions/${subscriptionID}/cancel`,
      url: `${APIURL}CF/api/stripeconnect/subscriptions/cancelSubscriptionPaymentLinks`,
      params: {
        tableID: subscriptionID,
      },
    })
      .then((res) => {
        updateStatus(subscriptionID, res.data.canceledSubscription.status);
        toast.success("Subscription canceld. Successfully!");
      })
      .catch((err) => {
        toast.error("Not canceld. Try again!");
      });
  };

  useEffect(() => {
    // setLoading(true);
    // axios({
    //   method: "get",
    //   url: `${APIURL}CF/api/CoachWeb/Get/getAllProductsByCoach`,
    //   params: {
    //     coachID: store.getState().auth.trainerUUID,
    //   },
    // })
    //   .then((res) => {
    //     let data = res.data.result;
    //     const extractedData = data.flatMap((item) =>
    //       item.productSubscriptions.map((subscription) => ({
    //         subscriptionVerified: subscription.subscriptionVerified,
    //         username: subscription.athelete.userName,
    //         name: item.name,
    //         price: item.price,
    //         period: item.period,
    //         interval: item.interval,
    //         status: subscription.status,
    //         id: subscription.id,
    //       }))
    //     );
    //     setSubscriptions(extractedData);
    //     setLoading(false);
    //   })
    //   .catch((err) => {
    //     console.log("error while getting products: ", err);
    //     setLoading(false);
    //   });
    // axios({
    //   method: "get",
    //   url: `${APIURL}CF/api/stripeconnect/trainer/${
    //     store.getState().auth.trainerUUID
    //   }`,
    // })
    //   .then((res) => {
    //     console.log("stripe connect response: ", res.data);
    //     let subscriptions = [];
    //     res.data?.trainerProducts?.map((item) => {
    //       item.coachSubscriptions.map((subscription) => {
    //         subscriptions.push({
    //           name: item.prodName,
    //           status: subscription.status,
    //           ...subscription,
    //         });
    //       });
    //     });
    //     setSubscriptions(subscriptions);
    //   })
    //   .catch((err) => {
    //     console.log("strip connect error: ", err);
    //   })
    //   .finally(() => {
    //     setLoading(false);
    //   });
    // let subscriptions = [];
    // trainerData.trainerProducts?.map((item) => {
    //   item.coachSubscriptions.map((subscription) => {
    //     subscriptions.push({
    //       name: item.prodName,
    //       status: subscription.status,
    //       ...subscription,
    //     });
    //   });
    // });
    // console.log("subscriptions: ", subscriptions);
    // setSubscriptions(subscriptions);
    // setTimeout(() => {
    //   setLoading(false);
    // }, 500);
  }, []);

  const updateStatus = (id, newStatus) => {
    const updatedSubscriptions = subscriptions.map((subscription) => {
      if (subscription.id === id) {
        return {
          ...subscription,
          status: newStatus,
        };
      }
      return subscription;
    });
    setSubscriptions(updatedSubscriptions);
  };

  const history = useHistory();

  return (
    <>
      {loading ? (
        <div
          style={{
            height: "70vh",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <SpinnerComponent />
        </div>
      ) : (
        <Box>
          <TableContainer
            elevation={0}
            component={Paper}
            style={{
              marginBottom: "50px",
              // maxHeight: "600px",
              overflowY: "scroll",
            }}
          >
            <Table className="w-full h-full">
              <TableHead>
                <TableCell sx={headerCellStyle} align="left">
                  Client
                </TableCell>
                <TableCell sx={headerCellStyle} align="left">
                  Pricing Plan
                </TableCell>
                <TableCell sx={headerCellStyle} align="left">
                  Start Date
                </TableCell>
                <TableCell sx={headerCellStyle} align="right">
                  Actions
                </TableCell>
              </TableHead>

              {subscriptions?.length === 0 ? (
                <div className="w-full h-full flex justify-center items-center ml-[150%] mt-[42%]">
                  <NoPrograms
                    subs={true}
                    setShowSubscriptionModal={setShowSubscriptionModal}
                    showSubscriptionModal={showSubscriptionModal}
                  />
                </div>
              ) : (
                <TableBody>
                  {visibleRows?.map((subscription) => (
                    <TableRow key={subscription.id}>
                      <TableCell
                        sx={bodyCellStyle}
                        align="left"
                        component="th"
                        scope="row"
                      >
                        {subscription?.athlete?.name}
                      </TableCell>
                      <TableCell sx={bodyCellStyle} align="left">
                        {subscription?.name}
                      </TableCell>
                      <TableCell sx={bodyCellStyle} align="left">
                        {subscription?.startDate
                          ? moment(subscription.startDate).format(
                              "Do MMMM YYYY"
                            )
                          : ""}
                      </TableCell>
                      {/* Additional TableCell components */}
                      <TableCell sx={bodyCellStyle} align="right">
                        <div className="flex items-center justify-end gap-2">
                          {subscription?.status === "PAUSED" && (
                            <Button
                              className="bg-[#8330E9] text-white-pure hover:bg-[#8330E9]/90"
                              onClick={() => {
                                handleOptionClick("resume", subscription.id);
                              }}
                            >
                              Resume
                            </Button>
                          )}

                          {subscription?.status !== "PAUSED" &&
                            subscription?.status !== "unpaid" && (
                              <Button
                                className="bg-[#8330E9] text-white-pure hover:bg-[#8330E9]/90"
                                onClick={() => {
                                  handleOptionClick("pause", subscription.id);
                                }}
                              >
                                Pause
                              </Button>
                            )}
                          <Button
                            className="border border-red-700 bg-background hover:bg-accent text-red-700 hover:text-red-600"
                            onClick={() => {
                              handleOptionClick("cancel", subscription.id);
                            }}
                          >
                            Cancel
                          </Button>
                        </div>
                        {/* <IconButton
                          sx={{
                            width: "38px",
                            height: "33px",
                          }}
                          onClick={(event) =>
                            handleMoreClick(event, subscription)
                          }
                        >
                          <img
                            src={MoreIcon}
                            alt="more icon in table"
                            style={{ cursor: "pointer" }}
                          />
                        </IconButton>
                        <Menu
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleMoreClose}
                        >
                          <MenuItem
                            disabled={activeItem?.status != "PAUSED"}
                            onClick={() =>
                              handleOptionClick("resume", activeItem?.id)
                            }
                          >
                            Resume
                          </MenuItem>
                          <MenuItem
                            disabled={
                              activeItem?.status === "PAUSED" ||
                              activeItem?.status === "unpaid" ||
                              activeItem?.status !== "paid"
                            }
                            onClick={() =>
                              handleOptionClick("cancel", activeItem?.id)
                            }
                          >
                            Cancel
                          </MenuItem>
                          <MenuItem
                            disabled={activeItem?.status != "paid"}
                            onClick={() => {
                              handleOptionClick("pause", activeItem?.id);
                            }}
                          >
                            Pause
                          </MenuItem>
                        </Menu> */}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={subscriptions?.length}
            s
            rowsPerPage={rowsPerPage} // rowsPerPage
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          {showSubscriptionModal && (
            <AddSubscriptionDialog
              open={showSubscriptionModal}
              setOpen={setShowSubscriptionModal}
              product={trainerData?.trainerProducts}
              activeTab={"SUBSCRIPTIONS"}
              getTrainerData={getTrainerData}
            />
          )}
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </Box>
      )}
    </>
  );
}
