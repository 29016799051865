import { ArrowForwardIosRounded, CloseRounded } from "@mui/icons-material";
import { Avatar, Backdrop, Box } from "@mui/material";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import { SpurfitCircularProgress } from "../../components/Dashboard/dashboard";
import { SearchIcon } from "../../pages/getStreamChat/assets";
import CustomSelect from "../EnduranceWorkoutBuilder/utils/CustomSelect";

const AssignMealInRangeToUser = ({
  isOpen,
  setIsOpen,
  selectedMeal,
  setSelectedMeal,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  onBack,
  onNext,
  onClose,
  onSave,
  loading,
  planDuration,
}) => {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const trainerUUID = useSelector((state) => state.auth.trainerUUID);
  const [allMeals, setAllMeals] = useState([]);
  const [tempMeals, setTempMeals] = useState([]);
  const [searchedTerm, setSearchedTerm] = useState("");

  const [selectedDuration, setSelectedDuration] = useState(180);

  const getAllMeals = async () => {
    await axios({
      method: "get",
      url: `${APIURL}CF/api/meal/getMealsByCoachId`,
      params: {
        coachId: trainerUUID,
      },
    })
      .then((res) => {
        console.log("res ====>>> ", res);
        const filteredRows = res.data.meals.filter((row) => {
          return row?.plandetails?.calories > -1;
        });
        setAllMeals(filteredRows);
        setTempMeals(filteredRows);
        // setIsLoading(false);
      })
      .catch((err) => {
        // setIsLoading(false);
        console.log(["err ===>>> ", err]);
      });
  };

  const setDuration = () => {
    if (+planDuration === 90) {
      setSelectedDuration(90);
    } else if (+planDuration === 180) {
      setSelectedDuration(180);
    } else if (+planDuration === 365) {
      setSelectedDuration(365);
    }
  };

  useEffect(() => {
    setDuration();
    getAllMeals();
  }, [trainerUUID]);

  const searchMeal = (searchedVal) => {
    setSearchedTerm(searchedVal);

    let filteredMeals = allMeals?.filter((meal) => {
      return meal.reference_name
        .toLowerCase()
        .includes(searchedVal.toLowerCase());
    });
    setTempMeals(filteredMeals);
  };

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 2 }}
      open={isOpen}
      onClick={() => {
        setIsOpen(false);
        onClose();
      }}
    >
      {loading && (
        <div
          className="absolute w-full h-full flex items-center justify-center"
          style={{
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: 9999,
          }}
        >
          <SpurfitCircularProgress />
        </div>
      )}
      <Box
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 3,
        }}
        style={{
          overflowY: "inherit",
          position: "absolute",
          background: "#fff",
          boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.12)",
          borderRadius: "12px",
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div>
          <div className="add-program-modal-head">
            <p className="add-program-modal-head-title">Assign Meal User </p>
            <div
              onClick={() => {
                // setIsOpen(false);
                // onClose();
                onBack();
              }}
              style={{
                color: "#000",
                cursor: "pointer",
              }}
            >
              <CloseRounded color="#000" />
            </div>
          </div>

          <div className="add-program-modal-body">
            <div className="full-width-container">
              <div>
                <div
                  className="channel-search__input__wrapper"
                  style={{
                    minWidth: "500px",
                    maxWidth: "500px",
                    display: "flex",
                    flexWrap: "wrap",
                    height: "auto",
                    justifyContent: "flex-start",
                  }}
                >
                  {selectedMeal?.reference_name ? (
                    <div
                      className="flex items-center gap-1 px-3 py-2 rounded-full bg-white-pure"
                      onClick={() => {
                        setSelectedMeal({});
                      }}
                    >
                      <p
                        style={{
                          color: "#333",
                        }}
                      >
                        {selectedMeal?.reference_name}
                      </p>

                      <CloseRounded
                        style={{
                          color: "#989898",
                          cursor: "pointer",
                          fontSize: "16px",
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        width: "100%",
                      }}
                      className="channel-search__input__icon"
                    >
                      <SearchIcon />
                      <input
                        className="channel-search__input__text"
                        onChange={(e) => searchMeal(e.target.value)}
                        placeholder="Search Meals"
                        type="text"
                        value={searchedTerm}
                      />
                    </div>
                  )}
                </div>
              </div>

              {selectedMeal?.reference_name && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <label
                      htmlFor="inputField"
                      style={{
                        display: "block",
                        color: "#000",
                        fontSize: 10,
                        marginLeft: 12,
                      }}
                    >
                      Meal start date
                    </label>
                    <DatePicker
                      dateFormat="dd MMM yyyy"
                      minDate={moment()
                        .set({
                          hour: 0,
                          minute: 0,
                          second: 0,
                          millisecond: 0,
                        })
                        .toDate()}
                      selected={startDate}
                      onChange={(date) => {
                        // if end date is less than start date then set end date to start date
                        if (moment(date).isAfter(endDate)) {
                          setEndDate(date);
                        }
                        setStartDate(date);
                      }}
                      customInput={
                        <input
                          type="text"
                          style={{
                            width: "100%",
                            height: "40px",
                            padding: "10px",
                            outline: "none",
                            fontFamily: "DM Sans",
                            cursor: "pointer",
                            color: "#000",
                          }}
                        />
                      }
                    />
                  </div>
                  <div>
                    <label
                      style={{
                        display: "block",
                        color: "#000",
                        fontSize: 10,
                        marginLeft: 12,
                      }}
                    >
                      Meal Duration
                    </label>
                    <CustomSelect
                      contentClassName="z-[99999]"
                      className="text-black-pure focus:ring-transparent"
                      options={[
                        { label: "3 months", value: 90 },
                        { label: "6 months", value: 180 },
                        { label: "1 year", value: 365 },
                      ]}
                      value={selectedDuration}
                      onValueChange={(value) => {
                        setSelectedDuration(value);
                        setEndDate(
                          moment(startDate).add(value, "days").toDate()
                        );
                      }}
                    />
                  </div>
                </div>
              )}
            </div>

            {/* Meals List */}
            {!selectedMeal?.reference_name && (
              <div
                style={{
                  height: "300px",
                  width: "100%",
                  overflowY: "auto",
                  borderTop: "1px solid #E5E5E5",
                }}
                className="flex flex-col"
              >
                {tempMeals?.map((meal) => {
                  return (
                    <div
                      className={` flex items-center justify-between px-3 py-2 rounded-lg cursor-pointer
                    ${
                      selectedMeal?.reference_name === meal?.reference_name
                        ? "bg-violet-200"
                        : " bg-white-pure"
                    }

                    hover:bg-violet-100
                   `}
                      onClick={() => {
                        setSelectedMeal(meal);
                      }}
                    >
                      <div className=" flex items-center gap-2">
                        <Avatar
                          sx={{
                            height: "40px",
                            width: "40px",
                          }}
                        >
                          {getInitials(meal?.reference_name)}
                        </Avatar>
                        <p
                          style={{
                            color: "#333",
                          }}
                          className="text-sm font-medium"
                        >
                          {meal?.reference_name}
                        </p>
                      </div>
                      <ArrowForwardIosRounded className="text-black-pure text-font20" />
                    </div>
                  );
                })}
              </div>
            )}

            <div className="flex justify-end items-center w-full">
              {onSave ? (
                <button
                  disabled={!selectedMeal?.reference_name}
                  className="button-new"
                  onClick={onSave}
                >
                  Save
                </button>
              ) : selectedMeal?.reference_name ? (
                <button className="button-new" onClick={onNext}>
                  Next
                </button>
              ) : (
                <button
                  style={{
                    borderWidth: 2,
                    borderRadius: 100,
                    width: "148px",
                    height: "44px",
                  }}
                  className="button-new-outlined"
                  onClick={onNext}
                >
                  Skip
                </button>
              )}
            </div>
          </div>
        </div>
      </Box>
    </Backdrop>
  );
};

export default AssignMealInRangeToUser;
const getInitials = (string) => {
  const names = string?.split(" ");
  if (_.isEmpty(names)) return;

  let initials = names[0].substring(0, 1).toUpperCase();
  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};
