import { useState, useEffect } from "react";
import {
  getNotes,
  addNotes,
  updateNotes,
  deleteNotes,
} from "../../ApiServices/ClientProfileApi/Api";

const useNotesHook = ({ userUUID, trainerUUID }) => {
  const [notes, setNotes] = useState([]);
  const [errorMessage, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const getNotesApi = async () => {
    setLoading(true);
    try {
      const result = await getNotes({ userUUID, trainerUUID });
      setNotes(result);
    } catch (err) {
      console.log(err);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const addNotesApi = async ({ note }) => {
    setLoading(true);
    try {
      const result = await addNotes({ userUUID, trainerUUID, note });
      if (result) await getNotesApi();
    } catch (err) {
      console.log(err);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const editNotesApi = async ({ note, PrimaryKey }) => {
    setLoading(true);
    try {
      const result = await updateNotes({ PrimaryKey, note });
      if (result) await getNotesApi();
    } catch (err) {
      console.log(err);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const deleteNotesApi = async ({ PrimaryKey }) => {
    setLoading(true);
    try {
      const result = await deleteNotes({ PrimaryKey });
      if (result) await getNotesApi();
    } catch (err) {
      console.log(err);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const fetchData = ({ type, note, PrimaryKey }) => {
    switch (type) {
      case "add":
        addNotesApi({ note });
        break;
      case "edit":
        editNotesApi({ note, PrimaryKey });
        break;
      case "delete":
        deleteNotesApi({ PrimaryKey });
        break;
    }
  };

  useEffect(() => {
    getNotesApi();
  }, [trainerUUID]);

  return [notes, errorMessage, loading, fetchData];
};

export default useNotesHook;
