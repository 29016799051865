import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, MenuItem, Select } from "@mui/material";
import { Line } from "react-chartjs-2";
import moment from "moment";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import "./charts.css";

const WeightsChart = ({ isOpen, setIsOpen, data }) => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Weight",
        data: [],
        fill: false,
        borderColor: "#0E9CFF",
        tension: 0.4,
        backgroundColor: "#ffffff",
      },
    ],
  });

  const [selectedOption, setSelectedOption] = useState("1 Month");

  const [startIndex, setStartIndex] = useState(0);
  const [oneWeekData, setOneWeekData] = useState([]);
  const [oneMonthData, setOneMonthData] = useState([]);
  const [threeMonthData, setThreeMonthData] = useState([]);
  const [sixMonthData, setSixMonthData] = useState([]);
  const [oneYearData, setOneYearData] = useState([]);
  const [allData, setAllData] = useState([]);

  const [oneWeekLabels, setOneWeekLabels] = useState([]);
  const [oneMonthLabels, setOneMonthLabels] = useState([]);
  const [threeMonthLabels, setThreeMonthLabels] = useState([]);
  const [sixMonthLabels, setSixMonthLabels] = useState([]);
  const [oneYearLabels, setOneYearLabels] = useState([]);
  const [allLabels, setAllLabels] = useState([]);

  useEffect(() => {
    // data is console.log
    // console.log("test data from the charts are here:", testData);
    if (!data || data.length === 0) {
      return;
    }

    const displayedData = data.slice(startIndex, startIndex + 5);

    // Sort data by date in ascending order
    data.sort((a, b) => new Date(a.dateRecorded) - new Date(b.dateRecorded));

    // Get the first day of the month
    const firstDayOfMonth = moment(data[data.length - 1].dateRecorded).startOf(
      "month"
    );
    console.log("first day of month", firstDayOfMonth);

    const dateLabels = [];
    const weightData = [];

    const latestOneWeekData = [];
    const latestOneMonthData = [];
    const latestThreeMonthData = [];
    const latestSixMonthData = [];
    const latestOneYearData = [];
    const latestAllData = [];

    const latestOneWeekLabels = [];
    const latestOneMonthLabels = [];
    const latestThreeMonthLabels = [];
    const latestSixMonthLabels = [];
    const latestOneYearLabels = [];
    const latestAllLabels = [];

    data.forEach((item) => {
      let modifiedDate = moment(item.dateRecorded).format("DD MMM YYYY");
      if (item?.weight?.value) {
        dateLabels.push(modifiedDate);
        weightData.push(item?.weight?.value);

        // Add data to the respective arrays based on time range
        const currentDate = moment(item.dateRecorded);
        const oneWeekAgo = moment().subtract(1, "week");
        const oneMonthAgo = moment().subtract(1, "month");
        const threeMonthsAgo = moment().subtract(3, "months");
        const sixMonthsAgo = moment().subtract(6, "months");
        const oneYearAgo = moment().subtract(1, "year");

        if (currentDate.isAfter(oneWeekAgo)) {
          latestOneWeekData.push(item?.weight?.value);
          latestOneWeekLabels.push(modifiedDate);
        }
        if (currentDate.isAfter(oneMonthAgo)) {
          latestOneMonthData.push(item?.weight?.value);
          latestOneMonthLabels.push(modifiedDate);
        }
        if (currentDate.isAfter(threeMonthsAgo)) {
          latestThreeMonthData.push(item?.weight?.value);
          latestThreeMonthLabels.push(modifiedDate);
        }
        if (currentDate.isAfter(sixMonthsAgo)) {
          latestSixMonthData.push(item?.weight?.value);
          latestSixMonthLabels.push(modifiedDate);
        }
        if (currentDate.isAfter(oneYearAgo)) {
          latestOneYearData.push(item?.weight?.value);
          latestOneYearLabels.push(modifiedDate);
        }
        latestAllData.push(item?.weight?.value);
        latestAllLabels.push(modifiedDate);
      }
    });

    console.log("weight data ha ye", weightData);

    dateLabels.forEach((item) => {
      console.log("item ha ye", item);
      const date = moment(item);
    });
    setChartData((prevChartData) => ({
      ...prevChartData,
      labels: latestOneMonthLabels,
      datasets: [
        {
          ...prevChartData.datasets[0],
          data: latestOneMonthData,
        },
      ],
    }));

    setOneWeekData(latestOneWeekData);
    setOneMonthData(latestOneMonthData);
    setThreeMonthData(latestThreeMonthData);
    setSixMonthData(latestSixMonthData);
    setOneYearData(latestOneYearData);
    setAllData(latestAllData);

    setOneWeekLabels(latestOneWeekLabels);
    setOneMonthLabels(latestOneMonthLabels);
    setThreeMonthLabels(latestThreeMonthLabels);
    setSixMonthLabels(latestSixMonthLabels);
    setOneYearLabels(latestOneYearLabels);
    setAllLabels(dateLabels);
  }, [data]);

  const handleShiftLeft = () => {
    if (startIndex - 5 >= 0) {
      setStartIndex(startIndex - 5);
    }
  };

  const handleShiftRight = () => {
    if (startIndex + 5 <= data.length) {
      setStartIndex(startIndex + 5);
    }
  };

  const options = {
    scales: {
      y: {
        title: {
          display: false,
          text: "Weight",
        },
        ticks: {
          stepSize: 25,
          max: 100,
        },
        grid: {
          display: true,
          borderDash: [5, 15],
        },
      },
      x: {
        title: {
          display: false,
          text: "Date",
        },
        type: "category",
        grid: {
          display: false,
        },
        ticks: {
          maxRotation: 0,
          minRotation: 0,
        },
        // pan: {
        //   enabled: true,
        //   mode: "x",
        // },
        // zoom: {
        //   enabled: true,
        //   mode: "x",
        // },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    elements: {
      point: {
        radius: 5,
      },
    },
    borderWidth: 2,
  };

  const handleDurationChange = (e) => {
    let value = e.target.value;
    setSelectedOption(value);
    let labels = [];
    let data = [];
    switch (value) {
      case "1 Week":
        labels = oneWeekLabels;
        data = oneWeekData;
        break;
      case "1 Month":
        labels = oneMonthLabels;
        data = oneMonthData;
        break;
      case "3 Months":
        labels = threeMonthLabels;
        data = threeMonthData;
        break;
      case "6 Months":
        labels = sixMonthLabels;
        data = sixMonthData;
        break;
      case "1 Year":
        labels = oneYearLabels;
        data = oneYearData;
        break;
      case "All":
        labels = allLabels;
        data = allData;
        break;
      default:
        labels = oneMonthLabels;
        data = oneMonthData;
    }
    setChartData((prevChartData) => ({
      ...prevChartData,
      labels: labels,
      datasets: [
        {
          ...prevChartData.datasets[0],
          data: data,
        },
      ],
    }));
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        width: "600px",
        maxHeight: "600px",
        padding: "0px",
        sx: {
          borderRadius: "20px",
          padding: "0px",
          boxShadow: "0px 16px 48px 0px rgba(0, 0, 0, 0.24)",
        },
      }}
    >
      <DialogContent sx={{ padding: "0px 0px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "12px 24px",
            borderBottom: "1px solid #EBEBEB",
            width: "100%",
          }}
        >
          <p
            style={{
              fontFamily: "DM Sans",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "29px",
            }}
          >
            Body Weight
          </p>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Select
              variant="standard"
              disableUnderline
              sx={{
                fontFamily: "DM Sans",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "17px",
                color: "#0E9CFF",
                marginRight: "10px",
              }}
              value={selectedOption}
              onChange={handleDurationChange}
            >
              {durationOption.map((option) => (
                <MenuItem value={option}>{option}</MenuItem>
              ))}
            </Select>
            {/* <button
              onClick={handleShiftLeft}
              style={{
                marginRight: "10px",
                cursor: "pointer",
                padding: "5px",
                borderRadius: "48%",
                transition: "background-color 0.3s",
              }}
              className="weights-button left"
            >
              <ChevronLeftIcon />
            </button>
            <button
              onClick={handleShiftRight}
              style={{
                marginRight: "10px",
                cursor: "pointer",
                padding: "5px",
                borderRadius: "48%",
                transition: "background-color 0.3s",
              }}
              className="weights-button right"
            >
              <ChevronRightIcon />
            </button> */}
            <button
              onClick={() => setIsOpen(false)}
              style={{
                cursor: "pointer",
                padding: "5px",
                borderRadius: "48%",
                transition: "background-color 0.3s",
              }}
              className="weights-button close"
            >
              <CloseIcon />
            </button>
          </div>
        </div>
        <div style={{ padding: "20px 15px 20px 30px" }}>
          <Line data={chartData} options={options} />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default WeightsChart;

const durationOption = [
  "1 Week",
  "1 Month",
  "3 Months",
  "6 Months",
  "1 Year",
  "All",
];
