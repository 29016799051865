import { ShareOutlined } from "@mui/icons-material";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import FiberManualRecordRoundedIcon from "@mui/icons-material/FiberManualRecordRounded";
import {
  Alert,
  Avatar,
  Box,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import axios from "axios";
import * as htmlToImage from "html-to-image";
import _ from "lodash";
import { CopyPlus, Download } from "lucide-react";
import React, { forwardRef, useContext, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AssignUserIcon from "../../../assets/workouts/AssignUserIcon.svg";
import DeleteIcon from "../../../assets/workouts/DeleteIcon.svg";
import { FOOD_BASED } from "../../../constants";
import MenuOptions from "../../../features/Workouts/MenuOptions";

import AddNotesDialogeForPrograms from "@/features/Programs/AddNotesDialogeForPrograms";
import SelectUserToShareImage from "../../SelectUsersToShareImage";
import AddFoodModal from "../AddFoodModal";
import AddMacrosBasedFood from "../AddMealPlans/AddMacrosBasedFood";
import AddMealsModal, {
  setToOneFixedDecimal,
} from "../AddMealPlans/AddMealsModal";
import EditMealInfoModal from "../AddMealPlans/EditMealInfoModal";
import AddRecipieModal from "../AddRecipieModal";
import AssignedMealPlanUsersDialog from "../AssignedMealPlanUsersDialog";
import AssignMeals from "../AssignMeals";
import DuplicateMealPlan from "../DuplicateMealPlan";
import MealSheduleWeek from "../MealSheduleWeek";
import { NutritionContext } from "../NutritionContext";
import AddingStepsModal from "./AddingStepsModal";
import SelectFolderDialoge from "../SelectFolderDialoge";
import { CustomAlertContext } from "@/App";

const getInitials = function (string) {
  let names = string?.split(" ");
  if (!_.isEmpty(names)) {
    let initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  }
};

const NItemDetails = forwardRef(
  (
    {
      item = {},
      defaultTab,
      handleMacrosModal,
      unsavedChanges,
      setUnsavedChanges,
      getMealPlansSchedule,
      getMealScheduleByID,
      setItem,
      getMealPlans,
      setListData,
      selectedType,
      isTemplate,
      setShowUpdateMealScheduleModal,
      childRef,
      checkForUnsavedMealPlans,
      getTrainerRecipes,
      setNutritionData,
      getCoachRecipes,
      setLoadingSearchedItem,
    },
    ref
  ) => {
    const APIURL = useSelector((state) => state.auth.apiURL);
    console.log("item ha kya", item, getTrainerRecipes);

    const [showAlert, setShowAlert] = useState({
      show: false,
      message: "",
      type: "",
    });
    const [showSelectUsers, setShowSelectUsers] = useState(false);
    const [image, setImage] = useState(null);
    const [capturing, setCapturing] = useState(false);
    const [openAssignModal, setOpenAssignModal] = useState(false);
    const [getMealPlanSubscribers, setGetMealPlanSubscribers] = useState([]);
    const [showSubscribers, setShowSubscribers] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [showDuplicateMealDialog, setShowDuplicateMealDialog] =
      useState(false);

    const [copyData, setCopyData] = useState({});
    const [openAddingSteps, setOpenAddingSteps] = useState(false);
    const [titleForAddingSteps, setTitleForAddingSteps] = useState(null);
    const coachID = useSelector((state) => state?.auth?.trainerUUID);
    const isAdmin = useSelector((state) => state.auth?.data?.isAdmin);
    const [isUpdateLocalFoodOpen, setIsUpdateLocalFoodOpen] = useState(false);
    const [showEditRecipeModal, setShowEditRecipeModal] = useState(false);
    const [openNotesDialoge, setOpenNotesDialoge] = useState(false);
    const {
      selectedRecipeType,
      selectedFoodType,
      deleteRecipe,
      deleteLocalFood,
      updateLocalFood,
      setShowEditMealInfoModal,
      showEditMealInfoModal,
      showDialogToFolderSelect,
      setShowDialogToFolderSelect,
      folders,
      templateFolders,
      openAddMealModal,
      setOpenAddMealModal,
      selectedTabInMeaLPlan,
      defaultNItem,
      setTemplateFolders,
      setFolders,
      setdefaultNItem,
      setIsLoading,
      adminID,
      tempDataForFolders, 
      tempDataForTemplateFolders
      
    } = useContext(NutritionContext);
    const { displayAlert } = useContext(CustomAlertContext);

    const addMealPlanRef = React.useRef();
    const history = useHistory();
    const setPreviousMealPlan = () => {
      if (addMealPlanRef.current) {
        addMealPlanRef.current.retriveUnsavedMealPlanFromLocalStorage();
      }
    };

    React.useImperativeHandle(ref, () => ({
      setOpenAddMealModal,
      setPreviousMealPlan,
    }));

    const getMealPlanSubscibers = async () => {
      await axios({
        url: `${APIURL}CF/api/getMealPlanSubscribers`,
        method: "get",
        params: {
          mealPlanID: item.id,
        },
      })
        .then((res) => {
          // format data as per our need
          let result = res.data.result?.map((item) => {
            let cur = item?.workouts?.[0];
            return {
              id: cur?.atheleteID,
              athleteName: cur?.athelete?.name,
              dateAssigned: cur?.dateAssigned,
            };
          });
          setGetMealPlanSubscribers(result);
        })
        .catch((err) => {
          console.log("err", err);
        });
    };

const isTemplateOption = item?.isTemplate || isTemplate;
const isEditable = item?.coachId === coachID;

const options = [
  "Assign To Users",
  isTemplateOption ? "Copy as Meal Plan" : "Copy To Meal Plan",
  (isAdmin && isTemplateOption) ? "Duplicate Template" : null,
  isEditable ? "Delete Meal" : null,
  isEditable && typeof defaultNItem?.mealPlanFolderId !== "string" ? "Add To Folder" : null,
].filter(Boolean);

const optionIcons = [
  <img src={AssignUserIcon} />,
  <ContentCopyRoundedIcon />,
  isAdmin && isTemplateOption ? <ContentCopyRoundedIcon /> : null,
  isEditable ? <img src={DeleteIcon} alt="Delete" /> : null,
  isEditable && typeof defaultNItem?.mealPlanFolderId !== "string" ? <CopyPlus /> : null,
].filter(Boolean);

const menuItemStyles = [
  { color: "#000" }, // Default color for all items
  
].filter(Boolean);


    useEffect(() => {
      if (defaultTab === 3 && item.id) {
        if(adminID && item?.coachId === adminID){
          setCanEdit(false);
          return
        }
        else if (
          (item?.isTemplate && item?.coachId === coachID) ||
          item?.trainerUUID === coachID ||
          !item?.isTemplate
        ) {
          console.log("can edit", {
            item,
          });
          setCanEdit(true);
        } else {
          console.log("cannot edit", {
            item,
          });
          setCanEdit(false);
        }
        getMealPlanSubscibers();
      }
    }, [defaultTab, item]);

    const getName = () => {
      console.log(
        "default tab",
        defaultTab,
        typeof defaultTab,
        defaultTab === 3
      );
      switch (defaultTab) {
        case 1:
          console.log("item", item);
          return item?.food_name || item?.name;
        case 2:
          return item?.recipe_name;
        case 3:
          return item?.reference_name || item?.display_name;
        case 4:
          return item?.name;
        default:
          return "";
      }
    };

    const getSummary = () => {
      let totalWeight;
      let protein;
      let proteinPercentage;
      let carbs;
      let carbsPercentage;
      let fat;
      let fatPercentage;
      let calories;
      let metric;

      switch (defaultTab) {
        case 1:
          let serving =
            item?.servings?.serving?.[0] ||
            item?.servings?.[0] ||
            item?.serving?.[0] ||
            {};
          totalWeight = serving?.metric_serving_amount;

          protein = serving?.protein;
          carbs = serving?.carbohydrate || serving?.carbs;
          fat = serving?.fat;

          calories = (protein * 4 + carbs * 4 + fat * 9)?.toFixed(2);

          proteinPercentage = protein ? ((protein * 4) / calories) * 100 : 0;
          carbsPercentage = carbs ? ((carbs * 4) / calories) * 100 : 0;
          fatPercentage = fat ? ((fat * 9) / calories) * 100 : 0;

          metric = serving?.serving_description
            ? "per " + serving?.serving_description
            : totalWeight > 0
            ? "per" + " " + setToOneFixedDecimal(totalWeight) + " gm"
            : "";

          return {
            protein,
            carbs,
            fat,
            calories,
            metric,
            proteinPercentage,
            carbsPercentage,
            fatPercentage,
          };
        case 2:
          return {
            protein: item?.serving_sizes?.protein,
            carbs: item?.serving_sizes?.carbohydrate,
            fat: item?.serving_sizes?.fat,
            calories: item?.serving_sizes?.calories,
            metric: item?.serving_sizes?.serving_size,
            proteinPercentage:
              ((item?.serving_sizes?.protein * 4) /
                item?.serving_sizes?.calories) *
              100,
            carbsPercentage:
              ((item?.serving_sizes?.carbohydrate * 4) /
                item?.serving_sizes?.calories) *
              100,
            fatPercentage:
              ((item?.serving_sizes?.fat * 9) / item?.serving_sizes?.calories) *
              100,
          };
        case 3:
          totalWeight = 0;
          let planDetails = item?.plandetails;

          planDetails?.meals?.forEach((meal) => {
            meal?.foods?.forEach((food) => {
              totalWeight += +food?.size;
            });
          });

          calories = planDetails?.calories || 0;

          protein = planDetails?.macros?.protein;
          proteinPercentage = protein
            ? (((protein * 4) / calories) * 100).toFixed(2)
            : 0;

          carbs = planDetails?.macros?.carbs;
          carbsPercentage = carbs
            ? (((carbs * 4) / calories) * 100).toFixed(2)
            : 0;

          fat = planDetails?.macros?.fat;
          fatPercentage = fat ? (((fat * 9) / calories) * 100).toFixed(2) : 0;

          return {
            protein,
            carbs,
            fat,
            calories,
            proteinPercentage,
            carbsPercentage,
            fatPercentage,
          };
        case 4:

        default:
          return {};
      }
    };

    const getMedia = () => {
      switch (defaultTab) {
        case 1:
          return item?.food_image ? [item?.food_image] : null;
        case 2:
          return item?.recipe_images;
        case 3:
          return item?.image ? [item?.image] : null;
        default:
          return "";
      }
    };

    const getMeals = () => {
      if (defaultTab === 3) {
        return item?.plandetails?.meals;
      }
    };

    const transformMeal = (mealData) => {
      console.log("chanig rtraa");
      let toProgramsCreate = {};
      let i = 1;
      mealData?.mealPlans?.map((meal, key) => {
        let dayIdx = meal.dayIdx;
        toProgramsCreate[dayIdx] = [];

        // meal?.map((dayMeal) => {
        toProgramsCreate[dayIdx].push(meal);
        // });
      });
      console.log(toProgramsCreate);
      return toProgramsCreate;
    };

    let summary = getSummary();
    let medias = getMedia();
    let meals = getMeals();
    let mealData = transformMeal(item);
    let mealType = item?.meal_plan_type;

    console.log("medias", medias);

    const deleteItem = (mealID,mealItem) => {
   
      if (getMealPlanSubscribers?.length > 0) {
        setShowAlert({
          show: true,
          message: "Remove all subscribers before deleting the meal",
          type: "error",
        });
        setTimeout(() => {
          setShowAlert({ show: false, message: "", type: "" });
        }, 3000);

        return;
      }
          setIsLoading(true);
      if (!mealID) {
        // remove first item from listdata
        setListData((prev) => {
          let list = [...prev];
          list.shift();
          return list;
        });
        setIsLoading(false);
        return;
      }
      axios({
        method: "post",
        url: `${APIURL}CF/api/meal/DeleteMealById`,
        data: {
          mealID,
        },
      })
        .then((res) => {
          getMealPlans();
           if (mealItem?.mealPlanFolderId) {
            setIsLoading(true)
             if (mealItem?.isTemplate) {
               setTemplateFolders((prev) => {
                 let newFolderData = prev.map((folder) => {
                   if (folder?.id === mealItem?.mealPlanFolderId) {
                     if (folder?.mealPlans) {
                       const mealPlans = [...folder?.mealPlans];
                       let upadtedMealPlans = mealPlans.filter(
                         (mealPlan) => mealPlan?.id !== mealItem?.id
                       );
                       folder.mealPlans = upadtedMealPlans;
                     }
                     return folder
                   }
                   return folder
                 });
                  return newFolderData;
               });
             } else {
               setFolders((prev) => {
                 let newFolderData = prev.map((folder) => {
                   if (folder?.id === mealItem?.mealPlanFolderId) {
                     if (folder?.mealPlans) {
                       const mealPlans = [...folder?.mealPlans];
                       let upadtedMealPlans = mealPlans.filter(
                         (mealPlan) => mealPlan?.id !== mealItem?.id
                       );
                       folder.mealPlans = upadtedMealPlans;
                     }
                     return folder;
                   }
                   return folder;
                 });
                 return newFolderData;
               });
             }
             setdefaultNItem({})
           }
           displayAlert({
            message: "Meal deleted successfully",
            type: "success",
          });
        
          setdefaultNItem({})
       setIsLoading(false);
        })
        .catch((err) => {
          console.log("error in deleting meal", err);
          if (err?.response?.data?.errorCode == "P2003") {
            setShowAlert({
              show: true,
              message: err?.response?.data?.message,
              type: "error",
            });
          } else {
            setShowAlert({
              show: true,
              type: "error",
              message: "Something went wrong",
            });
          }
        })
        .finally(() => {
           setIsLoading(false);
          setTimeout(() => {
            setShowAlert({ show: false, message: "", type: "" });
          }, 3000);
        });
    };

    const handleCapture = async () => {
      try {
        // Use html-to-image to capture the content of the target element
        let element = document.getElementById("recipe-content-saved");
        // let clone = element.cloneNode(true); // Clone the element, including its children

        // let images = clone.getElementsByTagName("img");

        // for (let i = images.length - 1; i >= 0; i--) {
        //   images[i].parentNode.removeChild(images[i]);
        // }
        const imageDataUrl = await htmlToImage.toPng(element);

        // Convert data URL to Blob
        const blob = await fetch(imageDataUrl).then((res) => res.blob());

        setImage(blob);
      } catch (error) {
        console.error("Error capturing or uploading image:", error);
      }
    };

    if (defaultTab == 4)
      return (
        <MealSheduleWeek
          getMealPlansSchedule={getMealPlansSchedule}
          getMealScheduleByID={getMealScheduleByID}
          unsavedChanges={unsavedChanges}
          setUnsavedChanges={setUnsavedChanges}
          mealId={item.id}
          mealName={getName()}
          mealDesc={item.description}
          mealsData={mealData}
          setItem={setItem}
          isTemplate={isTemplate}
          item={item}
          ref={childRef}
          setShowUpdateMealScheduleModal={setShowUpdateMealScheduleModal}
        />
      );
    else
      return (
        <Stack>
          <div id="recipe-content-saved">
            <Box
              sx={{
                gap: "10px",
                padding: "25px 20px",
                borderBottom: "1px solid #E5E5E5",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                {!capturing && (
                  <>
                    {medias && medias?.length > 0 && medias[0] != "" ? (
                      <Avatar alt="img" src={medias[0]} />
                    ) : (
                      <Avatar>{getInitials(getName())}</Avatar>
                    )}
                  </>
                )}
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: 600,
                  }}
                >
                  {getName()}
                </Typography>
                {defaultTab === 3 && canEdit && (
                  <EditRoundedIcon
                    className="cursor-pointer"
                    onClick={() => setShowEditMealInfoModal(true)}
                  />
                )}
                {showEditMealInfoModal && (
                  <EditMealInfoModal
                    isOpen={showEditMealInfoModal}
                    setIsOpen={setShowEditMealInfoModal}
                    item={item}
                    onSuccess={() => {
                      getMealPlans(item.id);
                    }}
                  />
                )}
                {openAddingSteps && (
                  <AddingStepsModal
                    open={openAddingSteps}
                    setIsOpen={setOpenAddingSteps}
                    title={titleForAddingSteps}
                    data={item}
                    getTrainerRecipes={getTrainerRecipes}
                    setItem={setItem}
                    setNutritionData={setNutritionData}
                    getCoachRecipes={getCoachRecipes}
                  />
                )}

                {defaultTab == 1 && (
                  <Box
                    sx={{
                      ml: "auto",
                      height: "50px",
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    {selectedFoodType == "trainer" && (
                      <>
                        <EditRoundedIcon
                          sx={{
                            cursor: "pointer",
                          }}
                          onClick={() => setIsUpdateLocalFoodOpen(true)}
                        />
                        <DeleteRoundedIcon
                          sx={{
                            cursor: "pointer",
                          }}
                          onClick={() => deleteLocalFood(item.id)}
                        />
                      </>
                    )}
                  </Box>
                )}
                {isUpdateLocalFoodOpen && (
                  <AddFoodModal
                    isOpen={isUpdateLocalFoodOpen}
                    setIsOpen={setIsUpdateLocalFoodOpen}
                    updateLocalFood={updateLocalFood}
                    currentLocalFoodData={item}
                  />
                )}
                {defaultTab == 2 && (
                  <Box
                    sx={{
                      ml: "auto",
                      height: "50px",
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <>
                      {selectedRecipeType !== "global" && (
                        <EditRoundedIcon
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => setShowEditRecipeModal(true)}
                        />
                      )}
                      <ShareOutlined
                        onClick={async () => {
                          setCapturing(true);
                          // await new Promise((resolve) =>
                          //   setTimeout(resolve, 100)
                          // );
                          await handleCapture();
                          setShowSelectUsers(true);
                        }}
                        sx={{ cursor: "pointer", ml: "auto" }}
                      />
                      <SelectUserToShareImage
                        recipe={item}
                        isOpen={showSelectUsers}
                        setIsOpen={setShowSelectUsers}
                        onClose={() => {
                          setCapturing(false);
                          setImage(null);
                        }}
                        image={image}
                      />
                    </>
                    {selectedRecipeType == "trainer" && (
                      <DeleteRoundedIcon
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() => deleteRecipe(item.id)}
                      />
                    )}
                  </Box>
                )}

                {defaultTab === 3 && (
                  <Box
                    sx={{
                      ml: "auto",
                    }}
                    className="flex items-center "
                  >
                    {getMealPlanSubscribers?.length > 0 && (
                      <p
                        className="cursor-pointer font-DMSans text-[14px] font-[500] ml-3"
                        onClick={() => {
                          setShowSubscribers(true);
                        }}
                      >
                        Subscribers
                      </p>
                    )}
                    {/* share */}

                    <p
                      style={{
                        fontFamily: "DM Sans",
                        fontWeight: "500",
                        fontSize: "14px",
                        color: "black",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "flex-end",
                        marginLeft: "0.75rem",
                      }}
                      onClick={() => setOpenNotesDialoge(true)}
                    >
                      Notes{" "}
                      {item?.notes?.length > 0 ? (
                        <span className="ml-1 w-[22px] text-center text-[13px] font-[400] bg-gray-300 rounded-full">
                          {item?.notes?.length}
                        </span>
                      ) : (
                        ""
                      )}
                    </p>
                    <Download
                      size={18}
                      className="cursor-pointer ml-3"
                      onClick={async () => {
                        history.push("/export", {
                          mealPlan: item,
                        });
                      }}
                    />

                    <MenuOptions
                      isHoriz={false}
                      style={{ padding: "0px" }}
                      options={ options}
                      optionIcons={ optionIcons}
                      menuItemStyles={menuItemStyles }
                      onOptionClick={(option) => {
                        console.log("option", option);
                        if (option === "Delete Meal") {
                          deleteItem(item.id, item);
                        } else if (option === "Assign To Users") {
                          setOpenAssignModal(true);
                        } else if (option === "Copy as Meal Plan") {
                          setShowDuplicateMealDialog(true);
                        } else if (option === "Copy To Meal Plan") {
                          setShowDuplicateMealDialog(true);
                          setCopyData({
                            isMealPlan: true,
                            duplicate: true,
                          });
                        } else if (option === "Duplicate Template") {
                          setShowDuplicateMealDialog(true);
                          setCopyData({
                            isMealPlan: false,
                            duplicate: true,
                            isTemplate: true,
                          });
                        } else if (option === "Add To Folder") {
                          setShowDialogToFolderSelect(true);
                        }
                      }}
                    />
                  </Box>
                )}
              </Box>

              {(item?.description || item?.descriptions) && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mt: 1,
                  }}
                >
                  <Typography
                    sx={{
                      textTransform: "Uppercase",
                      color: "rgb(70, 79, 235)",
                      fontFamily: "DM Sans",
                      fontSize: "12px",
                      fontWeight: 600,
                    }}
                  >
                    Description:
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 14,
                      color: "#242424",
                      fontFamily: "DM Sans",
                    }}
                  >
                    {item?.description ? item?.description : item?.descriptions}
                  </Typography>
                </Box>
              )}
            </Box>
            <Box
              sx={{
                padding: "25px 20px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 600,
                    mb: "15px",
                    fontFamily: "DM Sans",
                  }}
                >
                  Nutrition Facts
                </Typography>
                {defaultTab == 3 && canEdit && (
                  <EditRoundedIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => setOpenAddMealModal(true)}
                    fontSize={"small"}
                  />
                )}
              </Box>
              <Typography
                sx={{
                  textTransform: "Uppercase",
                  color: "rgb(70, 79, 235)",
                  fontFamily: "DM Sans",
                  fontSize: "12px",
                  fontWeight: 600,
                  mb: "4px",
                }}
              >
                Nutrition info
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 3,
                }}
              >
                <Typography
                  sx={{
                    color: "#242424",
                    fontFamily: "DM Sans",
                    fontSize: "18px",
                    fontWeight: 500,
                    lineHeight: "20px",
                  }}
                >
                  <span
                    style={{
                      fontWeight: 700,
                    }}
                  >
                    {" "}
                    {setToOneFixedDecimal(summary.calories)}{" "}
                  </span>{" "}
                  cals
                </Typography>
                <Typography
                  sx={{
                    color: "#242424",
                    fontFamily: "DM Sans",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "20px",
                  }}
                >
                  {summary.metric}
                </Typography>
              </Box>
              <Stack direction={"row"} spacing={3} mt={3}>
                <NutritionMetric
                  label="Protein"
                  value={summary.protein}
                  percentage={"" + summary.proteinPercentage}
                />
                <NutritionMetric
                  label="Fat"
                  value={summary.fat}
                  percentage={"" + summary.fatPercentage}
                />
                <NutritionMetric
                  label="Carbs"
                  value={summary.carbs}
                  percentage={"" + summary.carbsPercentage}
                />
              </Stack>
            </Box>

            {/* Media */}

            {medias && medias.length > 0 && medias?.[0] != "" && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "25px 20px",
                }}
              >
                <Typography
                  sx={{
                    textTransform: "Uppercase",
                    color: "#242424",
                    fontFamily: "DM Sans",
                    fontSize: "12px",
                    color: "rgb(70, 79, 235)",
                    fontWeight: 600,
                  }}
                >
                  Media
                </Typography>
                <Stack direction={"row"} gap={1}>
                  {!capturing &&
                    medias?.map((media, index) => {
                      return (
                        <Box
                          sx={{
                            width: "250px",
                            height: "250px",
                            backgroundColor: "#F2F2F2",
                            borderRadius: "5px",
                          }}
                          key={index}
                        >
                          <img
                            src={media}
                            style={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "5px",
                              objectFit: "cover",
                            }}
                          />
                        </Box>
                      );
                    })}
                </Stack>
              </Box>
            )}
            {item?.receipeVideos && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "25px 20px",
                }}
              >
                <Typography
                  sx={{
                    textTransform: "Uppercase",
                    color: "#242424",
                    fontFamily: "DM Sans",
                    fontSize: "12px",
                    color: "rgb(70, 79, 235)",
                    fontWeight: 600,
                  }}
                >
                  Media
                </Typography>
                <div>
                  <ReactPlayer
                    width={"400px"}
                    height={"200px"}
                    controls={true}
                    className="react-player"
                    url={item?.receipeVideos}
                  />
                </div>
              </Box>
            )}

            {/* Ingredients */}
            {defaultTab === 2 && (
              <>
                <Divider />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "25px 20px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        textTransform: "Uppercase",
                        color: "#242424",
                        fontFamily: "DM Sans",
                        fontSize: "12px",
                        color: "rgb(70, 79, 235)",
                        fontWeight: 600,
                        marginRight: "10px",
                      }}
                    >
                      Ingredients
                    </Typography>
                    {selectedRecipeType !== "global" && (
                      <p
                        style={{
                          fontFamily: "DM Sans",
                          fontSize: "13px",
                          fontWeight: 500,
                          display: "flex",
                          cursor: "pointer",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          setOpenAddingSteps(true);
                          setTitleForAddingSteps("Ingredients");
                        }}
                      >
                        Edit
                        <EditRoundedIcon
                          style={{
                            cursor: "pointer",
                            fontSize: "13px",
                            marginLeft: "5px",
                          }}
                        />
                      </p>
                    )}
                  </div>

                  <Box>
                    {item?.ingredients?.map((ingredient) => {
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            mt: 1,
                            mb: 1,
                          }}
                        >
                          <FiberManualRecordRoundedIcon
                            sx={{
                              color: "rgb(131, 48, 233)",
                              fontSize: "6px",
                              mr: 2,
                            }}
                          />
                          <Typography
                            sx={{
                              color: "#242424",
                              fontFamily: "DM Sans",
                              fontSize: "14px",
                              fontWeight: 500,
                              lineHeight: "20px",
                            }}
                          >
                            {ingredient?.ingredient_description}
                          </Typography>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              </>
            )}

            {/* Directions */}
            {defaultTab === 2 && (
              <>
                <Divider />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "25px 20px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "rgb(70, 79, 235)",
                        fontFamily: "DM Sans",
                        fontSize: "14px",
                        fontWeight: 500,
                        lineHeight: "20px",
                        marginRight: "10px",
                      }}
                    >
                      How to prepare
                    </Typography>
                    {selectedRecipeType !== "global" && (
                      <p
                        style={{
                          fontFamily: "DM Sans",
                          fontSize: "13px",
                          fontWeight: 500,
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setOpenAddingSteps(true);
                          setTitleForAddingSteps("Directions");
                        }}
                      >
                        Edit
                        <EditRoundedIcon
                          style={{
                            fontSize: "13px",
                            cursor: "pointer",
                            marginLeft: "5px",
                          }}
                        />
                      </p>
                    )}
                  </div>

                  {item?.directions?.map((direction) => {
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          padding: "25px 20px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            height: "100%",
                            mr: 2,
                          }}
                        >
                          <Avatar
                            sx={{
                              height: "20px",
                              width: "20px",
                              fontSize: "10px",
                            }}
                          >
                            {direction?.direction_number}
                          </Avatar>
                        </Box>

                        <Typography
                          sx={{
                            color: "#242424",
                            fontFamily: "DM Sans",
                            fontSize: "14px",
                            fontWeight: 500,
                            lineHeight: "20px",
                          }}
                        >
                          {direction?.direction_description}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              </>
            )}
          </div>
          {/* Meals  */}
          {defaultTab === 3 && (
            <>
              <Divider />

              <Stack
                sx={{
                  padding: "25px 20px",
                }}
                gap={"20px"}
              >
                {meals && meals.length > 0 ? (
                  meals?.map((meal) => {
                    return <ShowOneMeal meal={meal} mealType={mealType} />;
                  })
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      mt: 2,
                      flex: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#242424",
                        fontFamily: "DM Sans",
                        fontSize: "14px",
                        fontWeight: 500,
                        lineHeight: "20px",
                        mb: 1,
                      }}
                    >
                      No meals added
                    </Typography>
                    <button
                      className="button-new"
                      onClick={() => setOpenAddMealModal(true)}
                    >
                      Add Meals
                    </button>
                  </Box>
                )}
              </Stack>
            </>
          )}
          {openAddMealModal &&
            (item?.meal_plan_type === FOOD_BASED ? (
              <AddMealsModal
                isOpen={openAddMealModal}
                setIsOpen={(status) => {
                  if (status == false && checkForUnsavedMealPlans) {
                    checkForUnsavedMealPlans();
                  }
                  setOpenAddMealModal(status);
                }}
                item={item}
                getMealPlans={getMealPlans}
                isUpdate={!(meals?.length > 0)}
                isTemplate={isTemplate}
                // ref={addMealInMealPlanRef}
                ref={addMealPlanRef}
              />
            ) : (
              <AddMacrosBasedFood
                isOpen={openAddMealModal}
                setIsOpen={setOpenAddMealModal}
                item={item}
                getMealPlans={getMealPlans}
                meals={meals}
                isTemplate={isTemplate}
              />
            ))}
          {showAlert?.show && (
            <Alert
              sx={{
                position: "fixed",
                bottom: "10%",
              }}
              severity={showAlert?.type}
            >
              {showAlert?.message}
            </Alert>
          )}

          {openAssignModal && (
            <AssignMeals
              isOpen={openAssignModal}
              setIsOpen={setOpenAssignModal}
              item={item}
            />
          )}
          {showDuplicateMealDialog && (
            <DuplicateMealPlan
              isOpen={showDuplicateMealDialog}
              setIsOpen={setShowDuplicateMealDialog}
              meal={item}
              key={item.id}
              onSuccess={(id) => {
                getMealPlans(id);
              }}
              copyData={copyData}
            />
          )}

          {showEditRecipeModal && (
            <AddRecipieModal
              isOpen={showEditRecipeModal}
              setIsOpen={setShowEditRecipeModal}
              getCoachRecipes={getTrainerRecipes}
              setNutritionData={setNutritionData}
              setdefaultNItem={setItem}
              setLoadingSearchedItem={setLoadingSearchedItem}
              data={item}
              isEdit={true}
            />
          )}

          {showSubscribers && item.id && (
            <AssignedMealPlanUsersDialog
              isOpen={showSubscribers}
              setIsOpen={setShowSubscribers}
              mealPlanAssignedUsers={getMealPlanSubscribers}
              setMealPlanAssignedUsers={setGetMealPlanSubscribers}
              mealPlanID={item.id}
            />
          )}
          {openNotesDialoge && (
            <AddNotesDialogeForPrograms
              open={openNotesDialoge}
              setIsOpen={setOpenNotesDialoge}
              tittle={"Notes"}
              dataOfMealPlan={item}
              isNotesForMealPlan={true}
              setDataOfMealPlan={setItem}
            />
          )}
          {showDialogToFolderSelect && (
            <SelectFolderDialoge
              open={showDialogToFolderSelect}
              setOpen={setShowDialogToFolderSelect}
              folders={selectedTabInMeaLPlan == 0 ? tempDataForFolders : tempDataForTemplateFolders}
              selectedItem={item}
            />
          )}
        </Stack>
      );
  }
);

export default NItemDetails;

export const ShowOneMeal = ({ meal, mealType }) => {
  const protein = parseFloat(meal?.macros?.protein);
  const fat = parseFloat(meal?.macros?.fat);
  const carbs = parseFloat(meal?.macros?.carbs);

  const formattedProtein = isNaN(protein) ? "0" : protein.toFixed(1);
  const formattedFat = isNaN(fat) ? "0" : fat.toFixed(1);
  const formattedCarbs = isNaN(carbs) ? "0" : carbs.toFixed(1);

  const finishedMacros = meal?.finishedMacros;
  const formattedFinalProtein =
    finishedMacros && !isNaN(+finishedMacros?.protein)
      ? (+finishedMacros?.protein).toFixed(0)
      : "0";
  const formattedFinalFat =
    finishedMacros && !isNaN(+finishedMacros?.fat)
      ? (+finishedMacros?.fat).toFixed(0)
      : "0";
  const formattedFinalCarbs =
    finishedMacros && !isNaN(+finishedMacros?.carbs)
      ? (+finishedMacros?.carbs).toFixed(0)
      : "0";
  return (
    <Stack
      sx={{
        width: "100%",
        padding: "0px 1.5rem",
        borderBottom: "3px solid #EDEDED",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "1rem",
        }}
      >
        <Typography
          sx={{
            color: "#242424",
            fontFamily: "DM Sans",
            fontSize: "16px",
            fontWeight: 600,
            textTransform: "capitalize",
          }}
        >
          {meal?.name}
        </Typography>
        <div className="flex items-center gap-2">
          {meal?.finishedMacros && (
            <Typography
              sx={{
                color: "#242424",
                fontFamily: "DM Sans",
                fontSize: "14px",
                fontWeight: 500,
                borderRight: "1px solid #EDEDED",
                backgroundColor: "#E7FDED",
                px: "12px",
                borderRadius: "5px",
              }}
            >
              <span
                style={{
                  fontSize: "12px",
                  fontWeight: 600,
                  color: "#6B6B6B",
                  marginRight: "5px",
                }}
              >
                P {formattedFinalProtein} • F {formattedFinalFat} • C{" "}
                {formattedFinalCarbs}
              </span>
              <span style={{ fontWeight: "600" }}>
                {setToOneFixedDecimal(finishedMacros?.calories)} cals
              </span>
            </Typography>
          )}

          {meal?.finishedMacros && <Divider orientation="vertical" flexItem />}

          <Typography
            sx={{
              color: "#242424",
              fontFamily: "DM Sans",
              fontSize: "14px",
              fontWeight: 500,
            }}
          >
            {mealType === FOOD_BASED && (
              <span
                style={{
                  fontSize: "12px",
                  fontWeight: 600,
                  color: "#6B6B6B",
                  marginRight: "5px",
                }}
              >
                P {formattedProtein} • F {formattedFat} • C {formattedCarbs}
              </span>
            )}
            <span
              style={{
                fontWeight: "600",
              }}
            >
              {setToOneFixedDecimal(meal.calories)} cals
            </span>
          </Typography>
        </div>
      </Box>
      {mealType === FOOD_BASED && (
        <Box>
          {meal?.foods?.map((food, index) => {
            return (
              <ShowOneFood
                food={food}
                length={meal?.foods?.length}
                foodIdx={index}
              />
            );
          })}

          {meal?.optionals?.map((foods, outerIndex) => {
            return (
              <Box
                sx={{
                  position: "relative",
                }}
              >
                <Divider sx={{ my: 3, backgroundColor: "#ccc" }} flexItem />
                <Typography
                  sx={{
                    position: "absolute",
                    top: "-10px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    backgroundColor: "#fff",
                    px: 1,
                    fontFamily: "DM Sans",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "black",
                  }}
                >
                  OR
                </Typography>
                {foods?.map((food, innerIndex) => {
                  return (
                    <ShowOneFood
                      key={innerIndex} // Adding a key is a good practice
                      food={food}
                      length={foods?.length}
                      foodIdx={innerIndex}
                    />
                  );
                })}
              </Box>
            );
          })}
        </Box>
      )}

      {meal?.feedback && (
        <div className={"font-DMSans text-font12 text-[#6B6B6B] font-normal"}>
          Feedback: {meal?.feedback}
        </div>
      )}
    </Stack>
  );
};

export const ShowOneFood = ({ food, length, foodIdx }) => {
  return (
    <Stack
      mb={2}
      alignItems={"flex-start"}
      direction={"row"}
      justifyContent={"space-between"}
      style={{
        borderBottom: foodIdx == length - 1 ? "" : "1px solid #EDEDED",
        paddingBottom: "1rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Avatar
          sx={{
            borderRadius: "8px",
            height: "48px",
            width: "48px",
          }}
        >
          {getInitials(food?.name)}
        </Avatar>
        <Box>
          <Typography
            sx={{
              color: "#242424",
              fontFamily: "DM Sans",
              fontSize: "16px",
              fontWeight: 600,
              ml: 1,
            }}
          >
            {food?.name} {food?.qty ? `(x${food?.qty})` : ""}
          </Typography>
          {((food?.size && food?.size > 0) || food.quantity) && (
            <Typography
              sx={{
                color: "#242424",
                fontFamily: "DM Sans",
                fontSize: "14px",
                fontWeight: 500,
                ml: 1,
              }}
            >
              {food?.size ? setToOneFixedDecimal(food?.size) : food.quantity}{" "}
              {food?.unit}
            </Typography>
          )}
        </Box>
      </Box>

      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {food?.fat ? (
          <Typography
            sx={{
              color: "#242424",
              fontFamily: "DM Sans",
              fontSize: "14px",
              fontWeight: 500,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginLeft: "2rem",
            }}
          >
            <span style={{ fontWeight: 700 }}>
              {setToOneFixedDecimal(food?.fat)}
            </span>{" "}
            <span
              style={{
                color: "#424242",
                fontWeight: "400",
                fontSize: "14px",
              }}
            >
              Fat
            </span>
          </Typography>
        ) : null}
        {food?.carbs ? (
          <Typography
            sx={{
              color: "#242424",
              fontFamily: "DM Sans",
              fontSize: "14px",
              fontWeight: 500,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginLeft: "2rem",
            }}
          >
            <span style={{ fontWeight: 700 }}>
              {setToOneFixedDecimal(food?.carbs)}
            </span>{" "}
            <span
              style={{
                color: "#424242",
                fontWeight: "400",
                fontSize: "14px",
              }}
            >
              Carbs
            </span>
          </Typography>
        ) : null}
        {food?.protein ? (
          <Typography
            sx={{
              color: "#242424",
              fontFamily: "DM Sans",
              fontSize: "14px",
              fontWeight: 500,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginLeft: "2rem",
            }}
          >
            <span style={{ fontWeight: 700 }}>
              {setToOneFixedDecimal(food?.protein)}
            </span>{" "}
            <span
              style={{
                color: "#424242",
                fontWeight: "400",
                fontSize: "14px",
              }}
            >
              Protein
            </span>
          </Typography>
        ) : null}
        {food?.calories ? (
          <Typography
            sx={{
              color: "#242424",
              fontFamily: "DM Sans",
              fontSize: "14px",
              fontWeight: 500,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginLeft: "2rem",
            }}
          >
            <span style={{ fontWeight: 700 }}>
              {setToOneFixedDecimal(food?.calories)}
            </span>{" "}
            <span
              style={{
                color: "#424242",
                fontWeight: "400",
                fontSize: "14px",
              }}
            >
              Calories
            </span>
          </Typography>
        ) : null}
      </div>
    </Stack>
  );
};

export const NutritionMetric = ({ label, value, percentage }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "120px",
        width: "100px",
      }}
    >
      <Box
        sx={{
          height: "100px",
          width: "100px",
          borderRadius: "50%",
          backgroundColor: "#F2F2F2",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <CircularProgress
          variant="determinate"
          value={percentage}
          size={100}
          thickness={2}
          sx={{
            color: "#8330e9",
            position: "absolute",
            left: 0,
            top: 0,
            zIndex: 1,
          }}
        />
        <Typography
          sx={{
            color: "#242424",
            fontFamily: "DM Sans",
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "20px",
            mt: 2,
          }}
        >
          <span
            style={{
              fontWeight: 700,
            }}
          >
            {value ? parseFloat(value).toFixed(1) : 0}
          </span>
          g
        </Typography>
        {percentage && percentage > 0 && isFinite(percentage) ? (
          <Box>
            <Typography
              sx={{
                color: "#242424",
                fontFamily: "DM Sans",
                fontSize: "10px",
                fontWeight: 400,
                lineHeight: "20px",
              }}
            >
              {" " +
                (parseFloat(percentage).toFixed(1) + "").split(".")[0] +
                "." +
                (parseFloat(percentage).toFixed(1) + "")
                  .split(".")[1]
                  ?.substring(0, 1)}
              %
            </Typography>
          </Box>
        ) : (
          <Box>
            <Typography
              sx={{
                color: "#242424",
                fontFamily: "DM Sans",
                fontSize: "10px",
                fontWeight: 400,
                lineHeight: "20px",
              }}
            >
              N/A
            </Typography>
          </Box>
        )}
      </Box>
      <Typography
        sx={{
          color: "#242424",
          fontFamily: "DM Sans",
          fontSize: "12px",
          fontWeight: 700,
          lineHeight: "20px",
          alignSelf: "center",
        }}
      >
        {label}
      </Typography>
    </Box>
  );
};
