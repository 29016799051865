import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";
import axios from "axios";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { InputComponent, ShowError } from "../../pages/trainerSignup";

import { CustomAlertContext } from "@/App";
import { phoneExtensions } from "../../utils/contants";
//   import ReviewAndSave from "./ReviewAndSave";

const AddLeads = ({
  isOpen,
  setIsOpen,
  addLeadSuccess,
  isEditOpen,
  setIsEditOpen,
  leadsData,
  setLeadsData,
  setLeadEditedData,
  leadEditedData,
}) => {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const [weightUnit, setWeightUnit] = useState("lbs");
  const [heightUnit, setHeightUnit] = useState("cms");
  const [gender, setGender] = useState("");

  const trainerUUID = useSelector((state) => state.auth.trainerUUID);
  const { displayAlert } = useContext(CustomAlertContext);
  const [phoneExtension, setPhoneExtension] = useState("+1");

  let validationSchema = Yup.object().shape({
    name: Yup.string().required("Please enter your name"),
    email: Yup.string()
      .email("Please enter a valid email")
      .required("Please enter your email"),
    age: Yup.number(),
    height: Yup.number().when(["feet", "inches", "heightUnit"], {
      is: (feet, inches, currentHeightUnit) =>
        currentHeightUnit === "in" && (!feet || !inches),
      then: Yup.number(),
    }),
    weight: Yup.number(),
    contactNumber: Yup.number(),
    feet: Yup.number(),
    inches: Yup.number(),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      age: "",
      height: "",
      weight: "",
      contactNumber: "",
      feet: 0,
      inches: 0,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let data = {
        coachID: trainerUUID,
        name: values.name,
        email: values.email,
        contactNumber:
          phoneExtension.slice(1) + "-" + values.contactNumber.toString(),
        answeredForm: {
          age: values.age,
          height:
            heightUnit == "in"
              ? values.feet + "-ft " + values.inches + "-in"
              : values.height + "-cm",
          weight: values.weight + weightUnit,
          gender: gender,
        },
      };
      // this is for the editing the values
      if (isEditOpen.isOpen) {
        let filteredLeads = leadsData.map((item) => {
          if (item.id === isEditOpen.id) {
            let updatedData = { ...data };
            updatedData.id = isEditOpen.id;
            updatedData.dateCreated = leadEditedData.dateCreated;
            return updatedData;
          }
          return item;
        });
        let body = {
          id: isEditOpen.id,
          data: data,
        };
        console.log("this is for check", body);
        updateLeads(body);
        setLeadsData(filteredLeads);
        setIsOpen(false);
        setIsEditOpen({ id: "", isOpen: false });
        formik.resetForm();
        return;
      }

      formik.resetForm();
      addLeads(data);
      setIsOpen(false);
    },
  });

  useEffect(() => {
    formik.setFieldValue("name", leadEditedData?.name);
    formik.setFieldValue("email", leadEditedData?.email);
    formik.setFieldValue("age", leadEditedData?.age);
    formik.setFieldValue("height", leadEditedData?.height);
    formik.setFieldValue("weight", leadEditedData?.weight);
    formik.setFieldValue("contactNumber", leadEditedData?.contactNumber);
    formik.setFieldValue("goals", leadEditedData?.goals);
  }, [leadEditedData]);

  const addLeads = async (data) => {
    try {
      const response = await axios({
        method: "post",
        url: `${APIURL}CF/api/LeadGen/addLeadDirectly`,
        data: data,
      });
      console.log("added leads successfully", response);
      addLeadSuccess();
      return response.data; // Return the response data
    } catch (error) {
      console.log("error in adding leads", error);
    } finally {
    }
  };

  const updateLeads = async (data) => {
    try {
      const res = await axios({
        method: "post",
        url: "https://getfitaiserver.com/CF/api/LeadGen/updateLeadInvited",
        data: data,
      });
      if (res) {
        displayAlert({
          message: "Lead updated successfully",
          type: "success",
        });
      }
    } catch (error) {
      displayAlert({
        message: "There is some Error",
        type: "Error",
      });
    }
  };

  const resetAllStates = () => {
    setIsOpen(false);
    formik.resetForm();
  };

  return (
    <>
      <Dialog
        sx={{
          backdropFilter: "blur(5px)",
        }}
        PaperProps={{
          sx: { borderRadius: "16px" },
        }}
        fullWidth
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
          resetAllStates();
        }}
      >
        <div className="add-program-modal-head">
          {!isEditOpen.isOpen && (
            <p className="add-program-modal-head-title">Add Lead</p>
          )}
          {isEditOpen.isOpen && (
            <p className="add-program-modal-head-title">Edit Lead</p>
          )}
          <CloseRoundedIcon
            sx={{
              position: "absolute",
              right: 24,
              top: 24,
              cursor: "pointer",
            }}
            onClick={() => {
              setIsOpen(false);
              resetAllStates();
            }}
          />
        </div>
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", gap: 2 }}
        >
          <div
            style={{
              gap: 16,
              width: "100%",
            }}
            className="flex items-start"
          >
            <InputComponent
              id={"name"}
              label={"Client name *"}
              placeholder={"Client name"}
              type="name"
              {...formik.getFieldProps("name")}
              error={formik.touched.name && formik.errors.name}
              errorMessage={formik.errors.name}
            />
            <InputComponent
              id={"clientEmail"}
              label={"Client Email *"}
              placeholder={"Client Email"}
              type="email"
              {...formik.getFieldProps("email")}
              error={formik.touched.email && formik.errors.email}
              errorMessage={formik.errors.email}
            />
          </div>
          <div className="flex gap-[16px]">
            <div className="w-[50%]">
              <div
                style={{
                  fontSize: 14,
                  color: "#424242",
                  marginLeft: "12px",
                }}
                className="text-sm tracking-wide font-DMSans"
              >
                Contact
              </div>
              <div
                style={{
                  width: "100%",
                  border: "1px solid #CCC",
                  borderRadius: "12px",
                  backgroundColor: "#fff",
                  overflow: "hidden",
                }}
                className="flex flex-row "
              >
                <Select
                  style={{
                    // paddingRight: "10px",
                    paddingLeft: "12px",
                    height: "46px",
                  }}
                  SelectDisplayProps={{
                    style: {
                      backgroundColor: "#fff",
                      color: "#828282",
                    },
                  }}
                  IconComponent={ExpandMoreRoundedIcon}
                  variant="standard"
                  disableUnderline
                  value={phoneExtension}
                  onChange={(e) => setPhoneExtension(e.target.value)}
                  renderValue={(selected) => {
                    let selectedCountry = phoneExtensions.find(
                      (country) => country.extension == selected
                    );
                    if (selectedCountry) {
                      return `${selectedCountry.countryCode} ${selectedCountry.extension}`;
                    }
                  }}
                  name="phoneExtension"
                >
                  <MenuItem
                    sx={{
                      fontFamily: "DM Sans",
                      fontSize: "14px",
                    }}
                    disabled
                    value={"select"}
                  >
                    Select Country
                  </MenuItem>
                  {phoneExtensions.map((option) => (
                    <MenuItem
                      sx={{
                        color: "#828282",
                        fontFamily: "DM Sans",
                        fontSize: "14px",
                      }}
                      key={option.extension}
                      value={option.extension}
                    >
                      {`${option.country} ${option.extension}`}
                    </MenuItem>
                  ))}
                </Select>
                <input
                  type="number"
                  className="text-input"
                  placeholder={"123-456-789"}
                  onChange={formik.handleChange}
                  name="contactNumber"
                  min="0"
                />
              </div>
              <ShowError
                error={
                  formik.touched.contactNumber && formik.errors.contactNumber
                }
                errorMessage={formik.errors.contactNumber}
              />
            </div>
            <div className="w-[50%]">
              <div
                style={{
                  fontSize: 14,
                  color: "#424242",
                  marginLeft: "12px",
                }}
                className="text-sm tracking-wide font-DMSans"
              >
                Gender
              </div>
              <div>
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  onChange={(e) => setGender(e.target.value)}
                >
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontFamily: "DM Sans",
                        fontSize: "14px",
                      },
                    }}
                    value="male"
                    control={<Radio color="royalBlue" />}
                    label="Male"
                  />
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontFamily: "DM Sans",
                        fontSize: "14px",
                      },
                    }}
                    value="female"
                    control={<Radio color="royalBlue" />}
                    label="Female"
                  />
                  <FormControlLabel
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontFamily: "DM Sans",
                        fontSize: "14px",
                      },
                    }}
                    value="other"
                    control={<Radio color="royalBlue" />}
                    label="Other"
                  />
                </RadioGroup>
              </div>
            </div>
          </div>
          <div
            style={{
              gap: 16,
              width: "100%",
            }}
            className="flex items-start"
          >
            <InputComponent
              id={"age"}
              label={"Age"}
              placeholder={"Age"}
              type="number"
              {...formik.getFieldProps("age")}
              error={formik.touched.age && formik.errors.age}
              errorMessage={formik.errors.age}
              containerStyle={{
                width: "33%",
              }}
            />

            <div className={"w-[33%]"}>
              <div
                style={{
                  fontSize: 14,
                  color: "#424242",
                  marginLeft: "12px",
                }}
                className="text-sm tracking-wide font-DMSans"
              >
                Height
              </div>
              <div
                style={{
                  width: "100%",
                  border: "1px solid #CCC",
                  borderRadius: "12px",
                  backgroundColor: "#fff",
                  overflow: "hidden",
                }}
                className="flex flex-row mt-[8px]"
              >
                {heightUnit == "in" ? (
                  <>
                    <div className="relative flex items-baseline">
                      <input
                        type="number"
                        className="text-input"
                        placeholder={"6"}
                        onChange={formik.handleChange}
                        name="feet"
                        style={{
                          minWidth: "30px",
                        }}
                        min="0"
                        value={formik.values.feet}
                      />
                      <span
                        style={{ color: "#424242" }}
                        className="md:ml-1 mt-[10px] font-DMSans"
                      >
                        ft
                      </span>
                    </div>
                    <div className="relative flex items-baseline ml-2">
                      <input
                        type="number"
                        className="text-input"
                        placeholder={"0"}
                        onChange={formik.handleChange}
                        name="inches"
                        value={formik.values.inches}
                        style={{
                          minWidth: "40px",
                        }}
                        min="0"
                      />
                      <span
                        style={{ color: "#424242" }}
                        className="md:ml-1 mt-[10px] font-DMSans"
                      >
                        in
                      </span>
                    </div>
                  </>
                ) : (
                  <input
                    type="number"
                    className="text-input"
                    placeholder={"175"}
                    onChange={formik.handleChange}
                    name="height"
                    min="0"
                    value={formik.values.height}
                  />
                )}
                <Select
                  style={{
                    marginRight: "10px",
                    marginLeft: "10px",
                    height: "46px",
                  }}
                  MenuProps={{
                    style: { zIndex: "2147483000 !important" },
                  }}
                  SelectDisplayProps={{
                    style: {
                      backgroundColor: "#fff",
                      color: "#828282",
                    },
                  }}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  IconComponent={ExpandMoreRoundedIcon}
                  variant="standard"
                  disableUnderline
                  value={heightUnit}
                  onChange={(e) => setHeightUnit(e.target.value)}
                  name="heightUnit"
                >
                  <MenuItem
                    sx={{
                      color: "#828282",
                      fontFamily: "DM Sans",
                      fontSize: "14px",
                    }}
                    value="cms"
                  >
                    cms
                  </MenuItem>
                  <MenuItem
                    sx={{
                      color: "#828282",
                      fontFamily: "DM Sans",
                      fontSize: "14px",
                    }}
                    value="in"
                  >
                    ft-in
                  </MenuItem>
                </Select>
              </div>
              <ShowError
                error={formik.touched.height && formik.errors.height}
                errorMessage={formik.errors.height}
              />
            </div>

            <div className={"w-[33%]"}>
              <div
                style={{
                  fontSize: 14,
                  color: "#424242",
                  marginLeft: "12px",
                }}
                className="text-sm tracking-wide font-DMSans"
              >
                Weight
              </div>
              <div
                style={{
                  width: "100%",
                  border: "1px solid #CCC",
                  borderRadius: "12px",
                  backgroundColor: "#fff",
                  overflow: "hidden",
                }}
                className="flex flex-row mt-[8px]"
              >
                <input
                  type="number"
                  className="text-input"
                  placeholder={"110"}
                  onChange={formik.handleChange}
                  name="weight"
                  min="0"
                  value={formik.values.weight}
                />
                <Select
                  style={{
                    marginRight: "10px",
                    height: "46px",
                  }}
                  SelectDisplayProps={{
                    style: {
                      backgroundColor: "#fff",
                      color: "#828282",
                    },
                  }}
                  IconComponent={ExpandMoreRoundedIcon}
                  variant="standard"
                  disableUnderline
                  value={weightUnit}
                  onChange={(e) => setWeightUnit(e.target.value)}
                  name="weightUnit"
                >
                  <MenuItem
                    sx={{
                      color: "#828282",
                      fontFamily: "DM Sans",
                      fontSize: "14px",
                    }}
                    value="lbs"
                  >
                    lbs
                  </MenuItem>
                  <MenuItem
                    sx={{
                      color: "#828282",
                      fontFamily: "DM Sans",
                      fontSize: "14px",
                    }}
                    value="kg"
                  >
                    kg
                  </MenuItem>
                </Select>
              </div>
              <ShowError
                error={formik.touched.weight && formik.errors.weight}
                errorMessage={formik.errors.weight}
              />
            </div>
          </div>
          {/* <InputComponent
              id={"age"}
              label={"Age"}
              placeholder={"Age"}
              type="number"
              {...formik.getFieldProps("age")}
              error={formik.touched.age && formik.errors.age}
              errorMessage={formik.errors.age}
              containerStyle={{
                width: "33%",
              }}
            /> */}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", paddingBottom: "16px" }}>
          <button className="button-new" onClick={formik.handleSubmit}>
            Save
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddLeads;
