import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import "./Input.css";

function Input({
  label,
  type = "text",
  value = "",
  onChange,
  id,
  containerStyle,
  className,
  name,
  ...props
}) {
  const [inputValue, setInputValue] = useState(value);
  const inputId = id || uuidv4();

  function handleInputChange(event) {
    onChange && onChange(event);
    setInputValue(event.target.value);
  }

  useEffect(() => {
    // if value changes and it is different from the current inputValue
    if (value !== inputValue) {
      setInputValue(value);
    }
  }, [value]);

  return (
    <div
      style={{
        ...containerStyle,
      }}
      className={`input-container ${className}`}
    >
      <label htmlFor={inputId} {...props}>
        {label}
      </label>
      <input
        type={type}
        id={inputId}
        value={inputValue}
        name={name}
        onChange={handleInputChange}
        {...props}
      />
    </div>
  );
}

export default Input;
