import moment from "moment";
import { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { getLivezyClientsDashboard } from "../../ApiServices/Analytics/Api";
import { GenericCell, HeaderCell } from "../../components/Dashboard/helpers";
import { Typography } from "@mui/material";
import { date } from "yup";
const LivezyAnalytics = () => {
  const [totalLivezyUsers, setTotalLivezyUsers] = useState(0);
  const [loadingLivezyUsers, setLoadingLivezyUsers] = useState(false);
  const [livezyUsersData, setLivezyUsersData] = useState([]);

  const getData = async () => {
    setLoadingLivezyUsers(true);
    let livezyUsersData = await getLivezyClientsDashboard();
    livezyUsersData = livezyUsersData.filter(
      (item) =>
        item?.coachName !== "Spurfit Test" && item?.name != "Demo Client"
    );
    setLivezyUsersData(livezyUsersData);
    setTotalLivezyUsers(livezyUsersData.length);
    setLoadingLivezyUsers(false);
  };
  useEffect(() => {
    getData();
  }, []);
  const livezyUsers = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      headerAlign: "center",
      minWidth: 120,
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Name" />,
      renderCell: (params) => <GenericCell label={params?.row?.name} />,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Email" />,
      renderCell: (params) => <GenericCell label={params?.row?.email} />,
    },
    {
      field: "coachName",
      headerName: "Coach Name",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Coach Name" />,
      renderCell: (params) => <GenericCell label={params?.row?.coachName} />,
    },
    {
      field: "lastLogin",
      headerName: "Last Login",
      flex: 1,
    //   type: "date",
      sortComparator: (v1, v2) =>
        new Date(v1).getDate() - new Date(v2).getDate(),
      minWidth: 120,
      valueGetter: (params) => params?.row?.lastLoggedinAt,
      headerAlign: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Last Login" />,
      renderCell: (params) => (
        <GenericCell
          label={
            params?.row?.lastLoggedinAt
              ? moment(params?.row?.lastLoggedinAt).format("MMM Do' YY")
              : "-"
          }
        />
      ),
    },
  ];
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        width: "100%",
        padding: "20px",
      }}
    >
      <div
        style={{
          borderBottom: "1px solid #E5E5E5",
          paddingBottom: "10px",
        }}
        className="flex items-center gap-5"
      >
        <Typography
          sx={{
            fontSize: "18px",
            lineHeight: "24px",
            fontFamily: "DM Sans",
            fontStyle: "italic",
            color: "#333333",
            fontWeight: 500,
          }}
        >
          Spur.fit Livezy Analytics
        </Typography>

        <h3
          style={{
            fontWeight: "500",
            fontStyle: "DM Sans",
          }}
        >
          Total Users: {totalLivezyUsers}
        </h3>
      </div>
      <div
        style={{
          height: "calc(100vh - 100px)",
          backgroundColor: "#fff",
        }}
      >
        {/* change below instead of LIVEZY_USERS set the data that we get from the livezy users */}
        <ShowTable
          data={livezyUsersData}
          columns={livezyUsers}
          loading={loadingLivezyUsers}
          getRowId={(row) => row.id}
        />
      </div>
    </div>
  );
};

export default LivezyAnalytics;
const ShowTable = ({ data, columns, loading, getRowId }) => {
  return (
    <DataGrid
      getRowId={getRowId}
      rows={data}
      columns={columns}
      loading={loading}
      pageSizeOptions={[10, 25, 50, 100]}
      disableRowSelectionOnClick
      slots={{ toolbar: GridToolbar }}
      sx={{
        marginTop: "10px",
        minHeight: "100px",
        backgroundColor: "#fff",
        border: "none",
        ".MuiDataGrid-columnSeparator": {
          display: "none",
        },
        "&.MuiDataGrid-root": {
          border: "none",
          backgroundColor: "#fff",
        },
        "&.MuiDataGrid-columnHeaderCheckbox": {
          border: "none",
        },
        "& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell": {
          backgroundColor: "#fff",
          fontWeight: 700,
        },
        "& .super-app-theme--cell": {
          outline: "none",
        },
        "& .super-app-theme--header": {
          outline: "none",
          fontSize: "13px",
          fontWeight: 500,
          lineHeight: "20px",
          color: "#333333",
          fontFamily: "DM Sans",
        },
      }}
    />
  );
};
