import { SpurfitCircularProgress } from "@/components/Dashboard/dashboard";
import { CloseRounded } from "@mui/icons-material";
import { Backdrop } from "@mui/material";
import axios from "axios";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import Select from "react-select";
import { CustomAlertContext } from "../../../App";
import SpinnerComponent from "../../../components/layout/spinner";
import { SearchIcon } from "../../getStreamChat/assets";
import SmallCloseIcon from "../assests/SmallCloseIcon";
import { UserIcon } from "../assests/UserIcon";

function AssignProgramModal({
  programName,
  activeProgram,
  showModal,
  setShowModal,
  fromProgramTable,
  mealCard,
  setShowAlert,
  showNewAlert,
  onSuccess,
}) {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const [searched, setSearched] = useState("");
  const [userData, setUserData] = useState([]);
  const [tempUserData, setTempUserData] = useState([]);
  const [activeUser, setActiveUser] = useState([]);
  const [startDate, setStartDate] = useState(new Date().setHours(0, 0, 0, 0));
  const [selectedDay, setSelectedDay] = useState({ value: 1, label: "Day 1" });
  const [loading, setLoading] = useState(false);
  const [usersForPage, setUsersForPage] = useState([]);
  const [page, setPage] = useState(1);

  const menuPortalRef = useRef(null);

  const { displayAlert } = useContext(CustomAlertContext);
  const days = [];

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 9999, // set a higher z-index value
      position: "absolute",
      // maxHeight: "150px",
      // overflowY: "auto",
      fontFamily: "DM Sans",
      top: 0,
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 99999,
      position: "absolute",
      top: "69%",
      left: "58.5%",
    }),
    control: (provided, state) => ({
      ...provided,
      fontFamily: "DM Sans",
      height: 40,
      minHeight: 40,
      borderWidth: 0,
      "&:hover": {
        borderColor: "##071148",
      },
      "&:focus": {
        border: "2px solid ##071148",
        boxShadow: "0 0 0 2px ##071148",
      },
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      padding: "0 6px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
      borderWith: "2px",
      fontFamily: "DM Sans",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
    }),

    option: (provided, state) => ({
      ...provided,
      fontFamily: "DM Sans",
      backgroundColor: state.isSelected ? "white" : "white",
      color: state.isSelected ? "black" : "#424242",
      "&:hover": {
        backgroundColor: state.isSelected ? "#e3e3e3" : "#e3e3e3",
      },
    }),
  };

  for (let i = 1; i <= 365; i++) {
    let dayNumber = i;
    if (i % 7 === 1) {
      // Add a new option for the start of each week
      let weekNumber = Math.ceil(i / 7);
      days.push({
        value: "week",
        label: `Week ${weekNumber}`,
        isDisabled: true,
      });
    }
    days.push({ value: i, label: `Day ${dayNumber}` });
  }

  let pageSize = 6;
  const fetchUsers = () => {
    setLoading(true);
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const usersForPage = userData.slice(startIndex, endIndex);
    const removedSelectedUser = userData.filter(
      (obj) => !activeUser.includes(obj.id)
    );
    setUsersForPage(removedSelectedUser);
    setLoading(false);
  };

  const handleNextPage = () => {
    setPage(page + 1);
  };

  const handlePrevPage = () => {
    setPage(page - 1);
  };

  const selectAll = () => {
    let selected = [];
    userData.forEach((item) => {
      selected.push(item.id);
    });
    setActiveUser(selected);
  };
  const unselectAll = () => {
    setActiveUser([]);
  };

  const searchUsers = (searchedVal) => {
    setSearched(searchedVal);
    console.log(tempUserData);
    if (searchedVal !== "") {
      const filteredRows = tempUserData.filter((row) => {
        return row.name.toLowerCase().includes(searchedVal.toLowerCase());
      });
      if (filteredRows.length > 0) {
        setUserData(filteredRows);
      }
    } else {
      setUserData(tempUserData);
    }
  };

  const cancelSearchUsers = () => {
    setSearched("");
    setUserData(tempUserData);
  };

  const assignCROSSFITProgram = () => {
    axios({
      method: "post",
      url: `${APIURL}CF/api/assignProgramSegmentedMultiple`,
      data: {
        programID: activeProgram,
        userUUIDs: activeUser,
        startDate: `${moment(startDate)
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ss")}Z`,
        startProgDay: parseInt(selectedDay.value),
      },
    })
      .then(async (res) => {
        setLoading(false);
        setShowModal(false);
        // toast("Program successfully assigned", "success");
        showNewAlert("Program successfully assigned");
      })
      .catch((err) => {});
  };

  console.log("activebro: ", activeProgram, activeUser);

  const assignCROSSFITMealSchedule = () => {
    axios({
      method: "post",
      url: `${APIURL}CF/api/assignMealScheduleSegmentedMultiple`,
      data: {
        mealScheduleID: activeProgram,
        userUUIDs: activeUser,
        startDate: `${moment(startDate)
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ss")}Z`,
        startProgDay: parseInt(selectedDay.value),
      },
    })
      .then(async (res) => {
        setLoading(false);
        setActiveUser([]);
        setShowModal(false);
        // toast("Meal schedule successfully assigned", "success");
        setShowAlert({
          show: true,
          message: "Meal schedule successfully assigned",
        });
        setTimeout(() => {
          setShowAlert({
            show: false,
            message: "",
          });
        }, 3000);

        onSuccess();
      })
      .catch((err) => {});
  };

  const assignProgramToUser = () => {
    if (!activeUser || activeUser.length == 0) {
      displayAlert("Please select a user to assign this program");
      return;
    }
    setLoading(true);

    const assign = assignCROSSFITProgram;
    assign();
  };

  const assignMealScheduleToUser = () => {
    if (!activeUser || activeUser.length == 0) {
      displayAlert("Please select a user to assign this program");
      return;
    }
    setLoading(true);

    const assign = assignCROSSFITMealSchedule;
    assign();
  };

  const getProgramsUsers = () => {
    let trainerDetails = JSON.parse(localStorage.getItem("trainerCredentials"));
    setLoading(true);
    axios({
      method: "get",
      url: `${APIURL}CF/api/CoachWeb/Get/getAllAtheletesOneCoach`,
      params: {
        coachID: trainerDetails?.password,
      },
    }).then(async (res) => {
      console.log("Dashboard query on assign: ", res);
      const filteredData = res.data.result?.[0]?.atheletes?.filter(
        (item) => item.active == true
      );

      setUserData(filteredData);
      setTempUserData(filteredData);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchUsers();
  }, [userData, page]);

  const handleDayChange = (newValue, actionMeta) => {
    setSelectedDay(newValue);
  };

  const formatOptionLabel = ({ value, label }) => {
    if (label.startsWith("Week")) {
      return <div style={{ fontWeight: "bold" }}>{label}</div>;
    }
    return <div>{label}</div>;
  };

  const addUserStyle = {
    height: "33px",
    fontSize: "14px",
    background: "#071148",
    borderRadius: "8px",
    color: "#fff",
    textTransform: "none",
    marginBottom: 24,
    paddingLeft: 18,
    paddingRight: 16,
  };

  useEffect(() => {
    getProgramsUsers();
  }, []);

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 2 }}
      open={showModal}
    >
      {loading && (
        <div className="absolute w-full h-full flex items-center justify-center bg-black-pure/30 z-[9999]">
          <SpurfitCircularProgress />
        </div>
      )}
      <div
        style={{
          overflowY: "inherit",
          position: "absolute",
          background: "#fff",
          boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.12)",
          borderRadius: "12px",
        }}
        ref={menuPortalRef}
      >
        <div className="relative">
          <div className="add-program-modal-head">
            <p className="add-program-modal-head-title">Assign User </p>
            <div
              onClick={() => {
                setShowModal(false);
                setActiveUser([]);
              }}
              className="cursor-pointer"
            >
              <CloseRounded
                sx={{
                  fontSize: "24px",
                  color: "#000",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>

          <div className="add-program-modal-body">
            <div
              className="full-width-container"
              style={{
                paddingBottom: "20px",
              }}
            >
              <div>
                <div
                  className="channel-search__input__wrapper"
                  style={{
                    minWidth: "500px",
                    maxWidth: "500px",
                    display: "flex",
                    flexWrap: "wrap",
                    height: "auto",
                    justifyContent: "flex-start",
                  }}
                >
                  {tempUserData.length > 0 &&
                    tempUserData.map((item) => {
                      console.log("mapp ,", activeUser.includes(item.id));
                      return (
                        <>
                          {activeUser.includes(item.id) && (
                            <div
                              style={{
                                color: "#333",
                              }}
                              className="searched-selected"
                            >
                              <div className="selected-user-icon-div">
                                <UserIcon />
                              </div>
                              <p
                                style={{
                                  color: "#333",
                                }}
                              >
                                {item.name}
                              </p>
                              <div
                                onClick={() => {
                                  setActiveUser((prevState) => {
                                    return prevState.filter(
                                      (uuid) => uuid !== item.id
                                    );
                                  });
                                }}
                              >
                                <SmallCloseIcon />
                              </div>
                            </div>
                          )}
                        </>
                      );
                    })}
                  <div className="channel-search__input__icon w-full">
                    <SearchIcon />
                    <input
                      className="channel-search__input__text"
                      onChange={(e) => searchUsers(e.target.value)}
                      placeholder="Search"
                      type="text"
                      value={searched}
                    />
                  </div>
                </div>
                {searched.length > 0 && (
                  <div className="search-result-view">
                    {usersForPage.length > 0 ? (
                      usersForPage.map((item) => {
                        return (
                          <div
                            className="search-result-view-item-container"
                            onClick={() => {
                              setActiveUser((prevState) => {
                                if (true) {
                                  return [...prevState, item.id];
                                } else {
                                  return prevState.filter(
                                    (uuid) => uuid !== item.id
                                  );
                                }
                              });
                              setSearched("");
                            }}
                          >
                            <div
                              className="channel-preview__item_avatar"
                              style={{
                                backgroundColor: "rgb(255, 229, 211)",
                              }}
                            >
                              <UserIcon />
                            </div>
                            <p
                              style={{
                                color: "#333",
                              }}
                            >
                              {item.name}
                            </p>
                          </div>
                        );
                      })
                    ) : (
                      <SpinnerComponent />
                    )}
                  </div>
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <label
                  htmlFor="inputField"
                  style={{
                    display: "block",
                    color: "#000",
                    fontSize: 10,
                    marginLeft: 12,
                  }}
                >
                  Program Start Date
                </label>
                <DatePicker
                  dateFormat="dd MMM yyyy"
                  minDate={moment()
                    .set({
                      hour: 0,
                      minute: 0,
                      second: 0,
                      millisecond: 0,
                    })
                    .toDate()}
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  customInput={
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        height: "40px",
                        padding: "10px",
                        outline: "none",
                        fontFamily: "DM Sans",
                        cursor: "pointer",
                        color: "#000",
                      }}
                    />
                  }
                />
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor="inputField"
                  style={{
                    display: "block",
                    fontSize: 10,
                    marginLeft: 12,
                    color: "#000",
                  }}
                >
                  Starting Day
                </label>
                <Select
                  styles={customStyles}
                  // menuPortalTarget={menuPortalRef.current}
                  menuPortalTarget={
                    fromProgramTable ? document.body : menuPortalRef.current
                  }
                  options={days}
                  value={selectedDay}
                  onChange={handleDayChange}
                  formatOptionLabel={formatOptionLabel}
                  placeholder="Select day"
                />
              </div>
            </div>
            <div className="w-full flex items-center justify-center">
              <button
                className="button-new"
                onClick={() => {
                  if (!mealCard) assignProgramToUser();
                  else assignMealScheduleToUser();
                }}
              >
                Assign User
              </button>
            </div>
          </div>
        </div>
      </div>
    </Backdrop>
  );
}

export default AssignProgramModal;
