import { CloseRounded } from "@mui/icons-material";
import { Dialog, DialogActions, DialogContent, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SpurfitCircularProgress } from "../Dashboard/dashboard";
import Input from "../InputComponents/Input";

const AddClientEvents = ({
  isOpen,
  setIsOpen,
  onAddEvent,
  athleteID,
  existingEvent,
}) => {
  const APIURL = useSelector((state) => state.auth.apiURL);
  let tempEventName = "";
  const [eventName, setEventName] = useState(existingEvent?.event?.name || "");
  const [eventDate, setEventDate] = useState(moment());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (existingEvent) {
      setEventName(existingEvent?.event?.name || "");
      setEventDate(moment(existingEvent?.eventDate));
      console.log("existing event", existingEvent?.event?.name);
    }
  }, [existingEvent]);

  const addEvent = async () => {
    setLoading(true);
    let event = {
      name: eventName,
      date: moment(eventDate).format("DD-MM-YYYY"),
    };
    let addedEvent = existingEvent?.event?.name
      ? await updateEventForAthlete()
      : await addEventToForAthlete();
    if (event) {
      onAddEvent(addedEvent);
    }

    setEventName("");
    setEventDate(moment());
    setLoading(false);
    setIsOpen(false);
  };

  const addEventToForAthlete = async () => {
    let res = await axios({
      method: "post",
      url: `${APIURL}CF/api/athleteEvents/addAthleteEvent`,
      data: {
        athleteID: athleteID,
        eventDate: moment(eventDate).format("YYYY-MM-DD") + "T00:00:00.000Z",
        event: {
          name: eventName,
        },
      },
    });

    if (res.data.result) {
      return res.data.result;
    }
    return null;
  };

  const updateEventForAthlete = async () => {
    let res = await axios({
      method: "post",
      url: `${APIURL}CF/api/athleteEvents/updateAthleteEvent`,
      data: {
        eventID: existingEvent.id,
        atheleteID: athleteID,
        eventDate: moment(eventDate).format("YYYY-MM-DD") + "T00:00:00.000Z",
        event: {
          name: eventName,
        },
      },
    });

    if (res.data.result) {
      return res.data.result;
    }
    return null;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        maxWidth="xs"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: "24px",
          },
        }}
      >
        {loading && (
          <div className="w-full h-full flex justify-center items-center absolute">
            <SpurfitCircularProgress />
          </div>
        )}
        <div className="add-program-modal-head">
          <div style={{ display: "flex", alignItems: "flex-end", gap: "8px" }}>
            <p className="add-program-modal-head-title">
              {existingEvent?.event?.name ? "Update Event" : "Add Event"}
            </p>
          </div>
          <div
            onClick={() => {
              setIsOpen(false);
            }}
            className="cursor-pointer"
          >
            <CloseRounded
              sx={{
                fontSize: "24px",
                color: "#000",
                cursor: "pointer",
              }}
            />
          </div>
        </div>
        <DialogContent>
          <Input
            label={"Event Name *"}
            placeholder={"Enter Event Name"}
            value={eventName}
            onChange={(e) => setEventName(e.target.value)}
          />

          <div className="flex flex-col mt-2">
            <label
              style={{
                color: "#424242",
                fontFamily: "DM Sans",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "20px",
                textAlign: "left",
                paddingLeft: "12px",
                marginBottom: "8px",
              }}
            >
              Event Date *
            </label>
            <DatePicker
              sx={{
                border: "none",
                outline: "none",

                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#ccc",
                    borderRadius: "12px",
                    outline: "none",
                  },
                  "&:hover fieldset": {
                    borderColor: "#4339f2",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#4339f2",
                    boxShadow: "0px 0px 0px 3px rgba(67, 57, 242, 0.25)",
                  },
                },
              }}
              value={eventDate}
              onChange={(newValue) => setEventDate(newValue)}
              renderInput={(params) => (
                <TextField variant="standard" {...params} />
              )}
            />
          </div>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
          }}
        >
          <button
            disabled={eventName === ""}
            className="button-new"
            onClick={addEvent}
          >
            {existingEvent?.event?.name ? "Update Event" : "Add Event"}
          </button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};

export default AddClientEvents;
