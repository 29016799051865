export const updateFolders = (
  folders,
  selectedProgramData,
  programID,
  name,
  description
) => {
  return folders?.map((folder) => {
    if (folder?.id === selectedProgramData?.programFolderId) {
      if (folder?.containers) {
        folder.containers = folder.containers.map((phaseContainer) => {
          if (phaseContainer?.id === programID) {
            return { ...phaseContainer, name, description };
          }
          return phaseContainer;
        });
      }
    }
    return folder;
  });
};

export const updateProgramFoldersState = (
  setFolders,
  setOriginalState,
  originalState,
  programID,
  name,
  description,
  selectedProgramData
) => {
  setFolders((prev) =>
    updateFolders(prev, selectedProgramData, programID, name, description)
  );
  setOriginalState((prev) => ({
    ...prev,
    folders: updateFolders(
      originalState.folders,
      selectedProgramData,
      programID,
      name,
      description
    ),
  }));
};

export const updateTemplateFoldersState = (
  setTemplateFolders,
  setOriginalState,
  originalState,
  programID,
  name,
  description,
  selectedProgramData
) => {
  setTemplateFolders((prev) =>
    updateFolders(prev, selectedProgramData, programID, name, description)
  );
  setOriginalState((prev) => ({
    ...prev,
    templateFolders: updateFolders(
      originalState.templateFolders,
      selectedProgramData,
      programID,
      name,
      description
    ),
  }));
};

export const updateTemplatesList = (
  setTemplates,
  templates,
  programID,
  name,
  description
) => {
  setTemplates(
    templates.map((template) =>
      template.id === programID ? { ...template, name, description } : template
    )
  );
};

export const updateProgramsList = (
  setPrograms,
  programs,
  programID,
  name,
  description
) => {
  setPrograms(
    programs.map((program) =>
      program.id === programID ? { ...program, name, description } : program
    )
  );
};
