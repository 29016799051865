import { Dialog, DialogActions, DialogContent } from "@mui/material";
import React, { useState } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Input from "../../InputComponents/Input";

const EditMealNameModal = ({ isOpen, setIsOpen, item, setItem }) => {
  const [mealName, setMealName] = useState(item?.name);

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      fullWidth
      maxWidth="xs"
      PaperProps={{
        style: {
          borderRadius: "20px",
        },
      }}
    >
      <div className="add-program-modal-head">
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <p className="add-program-modal-head-title">Edit Meal Name </p>
        </div>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <CloseRoundedIcon />
        </div>
      </div>
      <DialogContent>
        <Input
          label="Meal Name"
          value={mealName}
          onChange={(e) => {
            setMealName(e.target.value);
          }}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <button
          className="button-new"
          onClick={() => {
            setItem({ ...item, name: mealName });
            setIsOpen(false);
          }}
        >
          Save
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default EditMealNameModal;
