import { Box, Menu, MenuItem, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";

import CameraIcon from "../../assets/trainerProfile/cameraIcon.svg";
import ClientIcon from "../../assets/trainerProfile/clientImg.svg";
import EmailIcon from "../../assets/trainerProfile/emailIcon.svg";
import ExperienceIcon from "../../assets/trainerProfile/experienceImg.svg";
import FacebookIcon from "../../assets/trainerProfile/fbIcon.svg";
import InstaIcon from "../../assets/trainerProfile/instaIcon.svg";
import MoreVertIcon from "../../assets/trainerProfile/moreVerticalIcon.svg";
import PhoneIcon from "../../assets/trainerProfile/phoneIcon.svg";
import PlaceholderImage from "../../assets/trainerProfile/placeholder.svg";
import PlusIcon from "../../assets/trainerProfile/plusIcon.svg";
import TwitterIcon from "../../assets/trainerProfile/twitterIcon.svg";
import WorkoutIcon from "../../assets/trainerProfile/workoutPlan.svg";

import axios from "axios";
import { toast } from "react-toastify";
import SpinnerComponent from "../../components/layout/spinner";
import ClientTestimonialModal from "../profile/ClientTestimonialModal";
import EditInfoModal from "../profile/EditInfoModal";
import ImageUploadWithCrop from "../profile/ImageUploadWithCrop";
import TopWorkoutsModal from "../profile/TopWorkoutsModal";
import VideoPlayer from "../profile/VideoPlayer";
import VideoThumbnail from "../profile/VideoThumbnail";

import EditBio from "@/pages/profile/EditBio.jsx";
import { useDispatch, useSelector } from "react-redux";
import { setTrainerData } from "../../redux/authSlice";
import "./styles.css";

export default function TrainerProfile() {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const [trainerInfo, setTrainerInfo] = useState("");
  const [loading, setLoading] = useState(false);
  const [topWorkoutsSelectModalOpen, setTopWorkoutsSelectModalOpen] =
    useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editBioModalOpen, setEditBioModalOpen] = useState(false);
  const [openUploadTestimonialModal, setOpenUploadTestimonialModal] =
    useState(false);
  const [selectedVideo, setSelectedVideo] = useState("");
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [inProgress, setInProgress] = useState(false);

  const [selectedTestimonial, setSelectedTestimonial] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const dispatch = useDispatch();

  const getProfileData = async () => {
    setLoading(true);
    let trainerDetails = JSON.parse(localStorage.getItem("trainerCredentials"));
    await axios({
      method: "get",
      url: `${APIURL}CF/api/CoachWeb/Get/oneTrainerProfile`,
      params: {
        coachID: trainerDetails?.password,
      },
    })
      .then((res) => {
        setTrainerInfo(res.data.result);
        setLoading(false);
        dispatch(setTrainerData(res.data.result));
      })
      .catch((err) => {
        console.log(
          "Error occurred while fetching trainer profile data: ",
          err
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getProfileData();
  }, []);

  const viewPublicProfile = () => {
    let trainerDetails = JSON.parse(localStorage.getItem("trainerCredentials"));
    const link = `${window.origin}/profile/${trainerDetails?.password}`;
    window.open(link, "_blank");
  };

  const currencySymbols = {
    KRW: "₩",
    USD: "$",
    CAD: "$",
    INR: "₹",
    AED: "د.إ",
    EUR: "€",
    GBP: "£",
    SGD: "$",
  };

  function isImage(file) {
    const extensions = ["jpg", "jpeg", "png", "gif"];
    return extensions.some((extension) =>
      file.toLowerCase().endsWith(extension)
    );
  }

  function isVideo(file) {
    const extensions = [
      "mp4",
      "mov",
      "avi",
      "webm",
      "ogg",
      "flv",
      "wmv",
      "mkv",
    ];
    return extensions.some((extension) => file.endsWith(extension));
  }

  // upload profile pic
  const uploadProfilePic = async (profilePic) => {
    setInProgress(true);

    const formData = new FormData();
    formData.append("image", profilePic);

    let trainerDetails = JSON.parse(localStorage.getItem("trainerCredentials"));

    await axios({
      method: "post",
      url: `${APIURL}api/uploadtrainerprofilepic?trainerUUID=${trainerDetails?.password}`,
      data: formData,
    })
      .then(async (res) => {
        toast.success("Successfully Uploaded!");
        getProfileData();
        setInProgress(false);
        setOpenDialog(false);
      })
      .catch((err) => {
        toast.error("Incorrect Image dimension. Need to be square");
        setInProgress(false);
        setOpenDialog(false);
      })
      .finally(() => {
        setMoreOpen(false);
      });
  };

  // upload Custom Logo

  const [customLogo, setCustomLogo] = useState(false);
  const [moreOpen, setMoreOpen] = useState(false);
  const handleMoreClick = (event) => {
    setMoreOpen(!moreOpen);
  };

  const uploadCustomLogo = async (profilePic) => {
    setInProgress(true);
    const imageData = new FormData();
    imageData.append("image", profilePic);
    let trainerDetails = JSON.parse(localStorage.getItem("trainerCredentials"));
    await axios({
      method: "post",
      url: `${APIURL}CF/api/CoachWeb/Create/updateProfileLogo?coachID=${trainerDetails?.password}`,
      data: imageData,
    })
      .then(async (res) => {
        toast.success("Successfully Uploaded!");
        getProfileData();
        setInProgress(false);
        setCustomLogo(false);
      })
      .catch((err) => {
        toast.error("Incorrect Image dimension. Need to be square");
        console.log(err);
        setInProgress(false);
        setCustomLogo(false);
      });
  };

  const spinnerStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 9999,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <Layout>
      {(inProgress || loading) && (
        <div style={{ ...spinnerStyle }}>
          <SpinnerComponent />
        </div>
      )}
      {!loading && (
        <TopWorkoutsModal
          trainerInfo={trainerInfo}
          getProfileData={getProfileData}
          open={topWorkoutsSelectModalOpen}
          setOpen={setTopWorkoutsSelectModalOpen}
        />
      )}
      {!loading && (
        <EditInfoModal
          trainerInfo={trainerInfo}
          open={editModalOpen}
          setOpen={setEditModalOpen}
          getProfileData={getProfileData}
        />
      )}

      {!loading && (
        <ImageUploadWithCrop
          open={openDialog}
          setOpen={setOpenDialog}
          uploadProfilePic={uploadProfilePic}
        />
      )}

      {!loading && (
        <ImageUploadWithCrop
          title={"Upload Custom Logo"}
          open={customLogo}
          setOpen={setCustomLogo}
          uploadProfilePic={uploadCustomLogo}
        />
      )}

      {!loading && (
        <ClientTestimonialModal
          open={openUploadTestimonialModal}
          setOpen={setOpenUploadTestimonialModal}
          getProfileData={getProfileData}
        />
      )}

      <VideoPlayer
        videoUrl={selectedVideo}
        open={videoModalOpen}
        setOpen={setVideoModalOpen}
      />

      <Box
        className="zoomedBody"
        sx={{
          height: "773px",
          backgroundColor: "#ffffff",
          borderRadius: "20px",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <Paper
          elevation={0}
          sx={{
            backgroundImage: `url(${
              trainerInfo?.coverimageKey
                ? trainerInfo?.coverimageKey + "?" + Date.now()
                : PlaceholderImage
            })`,
            boxShadow: "inset 0 0 0 2000px rgba(0, 0, 0, 0.3)",
            height: "396px",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            position: "relative",
          }}
        >
          <Box sx={{ position: "absolute", top: "204px", left: "50px" }}>
            <img
              style={{
                height: "265px",
                width: "265px",
                borderRadius: "50%",
                border: "4px solid #fff",
              }}
              src={`${trainerInfo?.imageKey}?${Date.now()}`}
              alt="trainer"
            />

            <div
              style={{
                position: "absolute",
                bottom: 5,
                right: "20px",
                backgroundColor: "rgba(0, 0, 0, 0.76)",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "64px",
                width: "64px",
              }}
            >
              <img
                src={CameraIcon}
                alt="camera"
                style={{
                  backdropFilter: "blur(5.5px)",
                  hegiht: "30px",
                  width: "30px",
                  cursor: "pointer",
                }}
                onClick={() => setOpenDialog(true)}
              />
            </div>
          </Box>

          <button
            style={{
              fontFamily: "DM Sans",
              fontSize: "22px",
              fontWeight: 700,
              color: "#fff",
              backgroundColor: "rgba(255, 255, 255, 0.31)",
              backdropFilter: "blur(7px)",
              borderRadius: "90px",
              padding: "10px 20px",
              position: "absolute",
              right: "50px",
              bottom: "37px",
            }}
            onClick={viewPublicProfile}
          >
            Public Profile
          </button>
        </Paper>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: "111px",
            mx: "50px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "DM Sans",
              fontSize: "48px",
              fontWeight: 700,
              color: "#000",
            }}
          >
            {trainerInfo.name ??
              (trainerInfo?.firstName && !trainerInfo?.lastName
                ? trainerInfo.firstName
                : trainerInfo?.firstName
                ? trainerInfo?.firstName + " " + trainerInfo.lastName
                : "")}
          </Typography>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <button
              style={{
                fontFamily: "DM Sans",
                fontSize: "18px",
                fontWeight: 500,
                color: "#2B2D3A",
                height: "44px",
                padding: "10px 14px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "88px",
                border: "1px solid #40414F",
              }}
              onClick={() => setEditModalOpen(true)}
            >
              Edit Profile
            </button>
            <div style={{ position: "relative" }}>
              <img
                src={MoreVertIcon}
                alt="more"
                id="more-button"
                style={{ marginLeft: "35.93px", cursor: "pointer" }}
                onClick={handleMoreClick}
              />
              {/*
              Check issues with Menu from MUI Library
              <Menu
                id={update-menu}
                anchorEl={moreAnchorEl}
                open={isMoreOpen}
                onClose={handleMoreClose}
              >
                <MenuItem
                  onClick={() => {
                    setCustomLogo(true);
                  }}
                >
                  Upload Custom Logo
                </MenuItem>
              </Menu> */}
              {moreOpen && (
                <Paper
                  sx={{
                    position: "absolute",
                    top: "50px",
                    right: "-20px",
                  }}
                  elevation={4}
                >
                  <MenuItem
                    onClick={() => {
                      setCustomLogo(true);
                      setMoreOpen(false);
                    }}
                  >
                    Upload Custom Logo
                  </MenuItem>
                </Paper>
              )}
            </div>
          </Box>
        </Box>
        <Box sx={{ display: "flex", mx: "50px" }}>
          <Typography
            sx={{
              color: "#7239EA",
              fontFamily: "DM Sans",
              fontSize: "18px",
              fontWeight: 500,
              backgroundColor: "#F4EFFF",
              padding: "10px",
              borderRadius: "125px",
              marginTop: "15px",
            }}
          >
            {trainerInfo?.trainerTag || "Fitness Coach"}
          </Typography>
        </Box>

        <Box sx={{ mx: "50px", mt: "50px", display: "flex" }}>
          <Box sx={{ display: "flex" }}>
            <img
              src={EmailIcon}
              alt="email"
              style={{ height: "24px", width: "24px", marginRight: "12px" }}
            />
            <Typography
              sx={{
                fontFamily: "DM Sans",
                fontSize: "18px",
                fontWeight: 500,
                color: "#333338",
              }}
            >
              {trainerInfo.email}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", ml: "69px" }}>
            <img
              src={PhoneIcon}
              alt="phone"
              style={{ height: "24px", width: "24px", marginRight: "12px" }}
            />
            <Typography
              sx={{
                fontFamily: "DM Sans",
                fontSize: "18px",
                fontWeight: 500,
                color: "#333338",
              }}
            >
              {trainerInfo.contactNumber}
            </Typography>
          </Box>

          <Box sx={{ display: "flex", ml: "auto", gap: 4 }}>
            {trainerInfo?.socailHandles?.fb && (
              <div
                onClick={() =>
                  window.open(trainerInfo?.socailHandles?.fb, "_blank")
                }
                className="flex items-center cursor-pointer"
              >
                <img
                  src={FacebookIcon}
                  alt=""
                  style={{ height: 40, width: 40 }}
                />
              </div>
            )}

            {trainerInfo?.socailHandles?.twitter && (
              <div
                onClick={() =>
                  window.open(trainerInfo?.socailHandles?.twitter, "_blank")
                }
                className="flex items-center cursor-pointer"
              >
                <img
                  src={TwitterIcon}
                  alt=""
                  style={{ height: 40, width: 40 }}
                />
              </div>
            )}

            {trainerInfo?.socailHandles?.insta && (
              <div
                onClick={() =>
                  window.open(trainerInfo?.socailHandles?.insta, "_blank")
                }
                className="flex items-center cursor-pointer"
              >
                <img src={InstaIcon} alt="" style={{ height: 40, width: 40 }} />{" "}
              </div>
            )}

            {/*    linkedin*/}
            {trainerInfo?.socailHandles?.linkedin && (
              <div
                onClick={() =>
                  window.open(trainerInfo?.socailHandles?.linkedin, "_blank")
                }
                className="flex items-center cursor-pointer"
              >
                <img
                  src={
                    "https://upload.wikimedia.org/wikipedia/commons/thumb/8/81/LinkedIn_icon.svg/1200px-LinkedIn_icon.svg.png"
                  }
                  alt=""
                  style={{ height: 40, width: 40 }}
                />
              </div>
            )}
            {/*    linktree*/}
            {trainerInfo?.socailHandles?.linktree && (
              <div
                onClick={() =>
                  window.open(trainerInfo?.socailHandles?.linktree, "_blank")
                }
                className="flex items-center cursor-pointer"
              >
                <img
                  src={
                    "https://img.utdstc.com/icon/0d4/e93/0d4e9331c3b8346858e1e5c4f77e9dfd92dccf8c38db0b280dba00076e5d5dc0:200"
                  }
                  alt=""
                  style={{ height: 40, width: 40 }}
                />
              </div>
            )}
          </Box>
        </Box>
      </Box>

      <Box
        name={"About"}
        className="zoomedBody"
        sx={{
          // height: "652px",
          boxShadow: "2px 6px 37px 0px rgba(0, 0, 0, 0.05)",
          borderRadius: "20px",
          backgroundColor: "#fff",
          mt: "40px",
          pl: "40px",
          pr: "50px",
          pb: "30px",
        }}
      >
        <div className="flex items-center gap-3 mt-[40px]">
          <Typography
            sx={{
              fontFamily: "DM Sans",
              fontSize: "36px",
              fontWeight: 700,
              color: "#121314",
            }}
          >
            About
          </Typography>
          <EditBio
            getProfileData={getProfileData}
            trainerInfo={trainerInfo}
            onBioChange={(bio) => setTrainerInfo({ ...trainerInfo, bio })}
          />
        </div>

        {trainerInfo?.bio && (
          <div
            style={{
              fontSize: "22px",
              fontFamily: "DM Sans",
              marginTop: "30px",
              color: "#515156",
            }}
            dangerouslySetInnerHTML={{ __html: trainerInfo?.bio }}
          ></div>
        )}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "95px",
            mt: "25px",
          }}
        >
          {trainerInfo?.experience && (
            <Box
              sx={{
                display: "flex",
                allignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{
                  height: "64px",
                  width: "64px",
                  borderRadius: "50%",
                  background: "#D3E0FF",
                  padding: "16px",
                  marginRight: "20px",
                }}
                src={ExperienceIcon}
              />
              <Typography
                sx={{
                  fontFamily: "DM Sans",
                  fontSize: "26px",
                  color: "#121314",
                  fontWeight: 700,
                  alignSelf: "center",
                }}
              >
                <span style={{ color: "#3239ED" }}>
                  {trainerInfo.experience}{" "}
                </span>{" "}
                Years of Experience
              </Typography>
            </Box>
          )}

          {trainerInfo?.numberOfClients && (
            <Box
              sx={{
                display: "flex",
                allignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{
                  height: "64px",
                  width: "64px",
                  borderRadius: "50%",
                  background: "#D3E0FF",
                  padding: "16px",
                  marginRight: "20px",
                }}
                src={ClientIcon}
              />
              <Typography
                sx={{
                  fontFamily: "DM Sans",
                  fontSize: "26px",
                  color: "#121314",
                  fontWeight: 700,
                  alignSelf: "center",
                }}
              >
                <span style={{ color: "#3239ED" }}>
                  {trainerInfo.numberOfClients}
                  {"+"}
                </span>{" "}
                Clients Training
              </Typography>
            </Box>
          )}

          {trainerInfo?.totalPlannedWorkouts && (
            <Box
              sx={{
                display: "flex",
                allignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{
                  height: "64px",
                  width: "64px",
                  borderRadius: "50%",
                  background: "#D3E0FF",
                  padding: "16px",
                  marginRight: "20px",
                }}
                src={WorkoutIcon}
              />
              <Typography
                sx={{
                  fontFamily: "DM Sans",
                  fontSize: "26px",
                  color: "#121314",
                  fontWeight: 700,
                  alignSelf: "center",
                }}
              >
                <span style={{ color: "#3239ED" }}>
                  {trainerInfo.totalPlannedWorkouts}
                  {"+"}
                </span>{" "}
                Workout Plans
              </Typography>
            </Box>
          )}
        </Box>
      </Box>

      <Box
        name="Top Workout Plans"
        className="zoomedBody"
        sx={{
          // height: "735px",
          borderRadius: "20px",
          boxShadow: "2px 6px 37px 0px rgba(0, 0, 0, 0.05)",
          backgroundColor: "#fff",
          mt: "40px",
          pl: "40px",
          pr: "50px",
        }}
      >
        <Box
          sx={{
            mt: "38.93px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontFamily: "DM Sans",
              fontWeight: 700,
              fontSize: "36px",
              color: "#121314",
            }}
          >
            Top Workout Plans
          </Typography>

          <button
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "88px",
              padding: "10px 14px",
              fontFamily: "DM Sans",
              fontSize: "18px",
              fontWeight: 500,
              color: "#2B2D3A",
              border: "1px solid #40414F",
              height: "41.568px",
            }}
            onClick={() => setTopWorkoutsSelectModalOpen(true)}
          >
            Add New{" "}
            <img
              src={PlusIcon}
              alt="add"
              style={{ height: "24px", width: "24px", ml: "5px" }}
            />
          </button>
        </Box>

        <Box
          name="topWorkoutPlanCards"
          sx={{
            mt: "11.43px",
            display: "flex",
            flexDirection: "column",
            mb: "40px",
          }}
        >
          {trainerInfo?.topPrograms?.map((item, index) => {
            const program = item?.program;
            return (
              <Box
                name="topWorkoutPlanCard"
                sx={{
                  height: "170px",
                  borderRadius: "8px",
                  border: "1px solid #E1E1E6",
                  background: "#FFF",
                  mt: "30px",
                  px: "30px",
                }}
                key={index}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "end",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: "24px",
                      fontFamily: "DM Sans",
                      color: "#333338",
                      mt: "23px",
                    }}
                  >
                    {program?.name}
                  </Typography>

                  <Typography
                    sx={{
                      fontFamily: "DM Sans",
                      fontSize: "16px",
                      fontWeight: 500,
                      color: "#21C34E",
                      padding: "6px 10px",
                      backgroundColor: "#DCFFE6",
                      borderRadius: "125px",
                      height: "33px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {item?.level || " Expert's Plan"}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    height: "75px",
                  }}
                >
                  {program?.duration && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(231, 232, 236, 0.63)",
                        padding: " 9px 28px",
                        borderRadius: "5px",
                        height: "75px",
                        mt: "20px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "DM Sans",
                          fontSize: "24px",
                          fontWeight: 700,
                          color: "#333338",
                        }}
                      >
                        {program?.duration}
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "DM Sans",
                          fontSize: "20px",
                          fontWeight: 500,
                          color: "#56565B",
                        }}
                      >
                        days
                      </Typography>
                    </Box>
                  )}

                  <Typography
                    sx={{
                      color: "#171719",
                      fontFamily: "DM Sans",
                      fontWeight: 700,
                      fontSize: "36px",
                      ml: "auto",
                      mt: "auto",
                    }}
                  >
                    {currencySymbols?.[item?.priceCode]}
                    {item?.price}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>

      <Box
        name="client testimonials"
        className="zoomedBody"
        sx={{
          mt: "40px",
          mb: "40px",
          pl: "40px",
          pr: "50px",
          backgroundColor: "#fff",
          borderRadius: "20px",
          boxShadow: "2px 6px 37px 0px rgba(0, 0, 0, 0.05)",
        }}
      >
        <Box
          name="testimonials header"
          sx={{
            mt: "38.93px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontFamily: "DM Sans",
              fontWeight: 700,
              fontSize: "36px",
              color: "#121314",
            }}
          >
            Client Testimonials
          </Typography>

          <button
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "88px",
              padding: "10px 14px",
              fontFamily: "DM Sans",
              fontSize: "18px",
              fontWeight: 500,
              color: "#2B2D3A",
              border: "1px solid #40414F",
              height: "41.568px",
            }}
            onClick={() => setOpenUploadTestimonialModal(true)}
          >
            Add New
            <img
              src={PlusIcon}
              alt="add"
              style={{ height: "24px", width: "24px", ml: "5px" }}
            />
          </button>
        </Box>

        <Box
          name="testimonial cards"
          sx={{
            mt: "37px",
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(366px, 1fr))",
            gap: "70px", // Set the desired vertical gap between items
            paddingBottom: "50px",
          }}
        >
          {trainerInfo?.clientTestimonialKeys?.map((media, index) => {
            console.log(
              "media: ",
              media,
              isVideo(Object.values(media)[0]),
              isImage(Object.values(media)[0])
            );
            return (
              <>
                {isVideo(Object.values(media)[0]) ? (
                  <div
                    className="media-container"
                    style={{
                      height: "206px",
                      width: "366px",
                      borderRadius: "4.329px", // "4.329px 4.329px  7.566px 7.566px", <- when you add title
                      boxShadow: "2px 6px 37px 0px rgba(0, 0, 0, 0.05)",
                      backgroundColor: "rgba(0, 0, 0, 0.40)",
                      cursor: "pointer",
                      position: "relative",
                    }}
                    onClick={() => {
                      setVideoModalOpen(true);
                      setSelectedVideo(Object.values(media)[0]);
                    }}
                  >
                    <VideoThumbnail videoUrl={Object.values(media)[0]} />

                    <img
                      className="hover-image"
                      src={MoreVertIcon}
                      alt="more"
                      onClick={(event) => {
                        event.stopPropagation();
                        setAnchorEl(event.currentTarget);
                        setSelectedTestimonial(media);
                      }}
                      style={{
                        cursor: "pointer",
                        padding: "10px",
                        position: "absolute",
                        right: "4px",
                        top: "4px",
                        backgroundColor: "rgba(255, 255, 255, 0.40)",
                        borderRadius: "50%",
                        height: "36px",
                        width: "36px",
                        transform: "rotate(90deg)",
                      }}
                    />

                    <Menu
                      id={`item-actions-${Object.values(media)[0]}`}
                      anchorEl={anchorEl}
                      open={selectedTestimonial === media && Boolean(anchorEl)}
                      onClose={(event) => {
                        event.stopPropagation();
                        setAnchorEl(null);
                        setSelectedTestimonial(null);
                      }}
                    >
                      <MenuItem
                        sx={{
                          fontSize: "14px",
                          fontFamily: "DM Sans",
                          fontWeight: 500,
                          lineHeight: "20px",
                        }}
                        onClick={() => console.log("delete testimonial")}
                      >
                        Delete
                      </MenuItem>
                    </Menu>
                  </div>
                ) : isImage(Object.values(media)[0]) ? (
                  <div
                    className="media-container"
                    style={{
                      height: "206px",
                      width: "366px",
                      borderRadius: "4.329px 4.329px ", // "4.329px 4.329px  7.566px 7.566px", <- when you add title
                      boxShadow: "2px 6px 37px 0px rgba(0, 0, 0, 0.05)",
                      position: "relative",
                    }}
                  >
                    <img
                      style={{
                        height: "100%",
                        width: "100%",
                        borderRadius: "9px",
                        boxShadow: "2px 6px 37px 0px rgba(0, 0, 0, 0.05)",
                        zIndex: 1,
                        objectFit: "contain",
                      }}
                      src={Object.values(media)[0]}
                    />

                    <img
                      className="hover-image"
                      src={MoreVertIcon}
                      alt="more"
                      onClick={(event) => {
                        event.stopPropagation();
                        setAnchorEl(event.currentTarget);
                        setSelectedTestimonial(media);
                      }}
                      style={{
                        cursor: "pointer",
                        padding: "10px",
                        position: "absolute",
                        right: "4px",
                        top: "4px",
                        backgroundColor: "rgba(255, 255, 255, 0.40)",
                        borderRadius: "50%",
                        height: "36px",
                        width: "36px",
                        transform: "rotate(90deg)",
                      }}
                    />

                    <Menu
                      id={`item-actions-${Object.values(media)[0]}`}
                      anchorEl={anchorEl}
                      open={selectedTestimonial === media && Boolean(anchorEl)}
                      onClose={(event) => {
                        event.stopPropagation();
                        setAnchorEl(null);
                        setSelectedTestimonial(null);
                      }}
                    >
                      <MenuItem
                        sx={{
                          fontSize: "14px",
                          fontFamily: "DM Sans",
                          fontWeight: 500,
                          lineHeight: "20px",
                        }}
                        onClick={(event) => {
                          event.stopPropagation();
                          console.log("delete testimonial");
                        }}
                      >
                        Delete
                      </MenuItem>
                    </Menu>
                  </div>
                ) : (
                  <></>
                )}
              </>
            );
          })}
        </Box>
      </Box>

      {/* <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      /> */}
    </Layout>
  );
}
