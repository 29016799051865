import { cn } from "@/lib/utils";
import { LTTB } from "downsample";
import FitParser from "fit-file-parser";
import { Dialog, DialogContent } from "@mui/material";
import { ArrowLeft } from "lucide-react";
import React, { useEffect, useState } from "react";
import ChartForCompareWorkouts from "./ChartForCompareWorkouts";
import axios from "axios";
import APIURL from "@/APIDetails";
import { colourTokens } from "@/features/Garmin/FitFileAnalyzer/styles/tokens";
import { SpurfitCircularProgress } from "../Dashboard/dashboard";

function CompareWorkoutDialoge({
  open,
  setOpen,
  selectedWorkoutToCompare,
  workoutFromCalender,
  userInfo,
}) {
  const [loading, setLoading] = useState(false);
  const [selectedWorkoutMatrixes, setSelectedWorkoutMatrixes] = useState({});
  const [workoutFromCalenderMatrixes, setWorkoutFromCalenderMatrixes] =
    useState({});

  const [data, setData] = useState({});

  const [simplified, setSimplified] = useState([]);
  const [simplified2, setSimplified2] = useState([]);
  const [lapData,setLapData]=useState([])
 const [lapData2,setLapData2]=useState([])
  
  const compareMatrixes = [
    
    "rTSS",
    "Distance",
    "Time",
    "Calories",
    "Fatigue",
    "Effort",
    "Elevation Gain",
    "Avg Heart Rate",
    "Max Heart Rate",
    "Avg Cadence",
    "Max Cadence",
    "Avg Speed",
    "Max Speed",
    "Avg Pace",
    "Max Pace",
  ];

  useEffect(() => {
    getAxiosDataAsArrayBuffer();
  }, []);
  const getAxiosDataAsArrayBuffer = async () => {
    setLoading(true);
    // setSummaryData(location.state?.data);

    let url = selectedWorkoutToCompare?.fileUrl;

    const responseForSelectedWorkout = await axios({
      url: `${APIURL}CF/api/garmin/getS3FileByUrl`,
      method: "POST",
      data: { url },
    });
    const responseForWorkoutFromCalender = await axios({
      url: `${APIURL}CF/api/garmin/getS3FileByUrl`,
      method: "POST",
      data: { url: workoutFromCalender?.fileUrl },
    });

    var fitParser = new FitParser({
      force: true,
      speedUnit: "km/h",
      lengthUnit: "km",
      temperatureUnit: "kelvin",
      elapsedRecordField: true,
      mode: "both",
    });

    // Parse your file for selected workout
    fitParser.parse(
      responseForSelectedWorkout.data.result.data,
      function (error, data) {
        // Handle result of parse method
        if (error) {
          console.log(error);
        } else {
          console.log("Fit file parsed Data: ", {
            data,
            recs: data?.records,
          });
          // onChange(data, file);

          let records = data?.records;

          let calculatingPaceBySpeed = true;
          let gettingEnhancedCadence = true;
          let dataPrepForLTTB = records?.map((record, index) => {
            // In addition to the x and y keys (so LTTB func doesn't fail,
            // also let it smooth out additional data points for this FIT file.
            let updatedRec = { x: record.elapsed_time, y: 0, ...record };
            if (location?.state?.data.activityType === "RUNNING") {
              if (updatedRec.cadence) {
                // convert spm to rpm
                updatedRec.cadence = record.cadence * 2;
              } else if (updatedRec.enhanced_cadence) {
                updatedRec.cadence = record.enhanced_cadence * 2;
              }
            }
            let val = updatedRec.speed;
            if (isNaN(val)) {
              calculatingPaceBySpeed = false;
            }
            if (!calculatingPaceBySpeed) {
              val = updatedRec.enhanced_speed;
              updatedRec["speed"]=updatedRec?.enhanced_speed
            }
            if (val && !isNaN(val)) {
              updatedRec.pace = 60 / val;
              if (updatedRec.pace >= 15 && index - 1 > 0) {
                let previousRecPace = records[index - 1]?.speed
                  ? 60 / records[index - 1].speed
                  : 10;
                updatedRec.pace = previousRecPace;
              }
            } else {
              updatedRec.pace = 0;
            }
            return updatedRec;
          });

          let simplified = LTTB(dataPrepForLTTB, 200);

          setSimplified(simplified);

          setData({
            fileName: "1712649246543.fit",
            data: data,
          });
          setLapData(data?.laps)
        }
      }
    );
    // Parse your file for the workout from calender
    fitParser.parse(
      responseForWorkoutFromCalender.data.result.data,
      function (error, data) {
        // Handle result of parse method
        if (error) {
          console.log(error);
        } else {
          console.log("Fit file parsed Data: ", {
            data,
            recs: data?.records,
          });
          // onChange(data, file);

          let records = data?.records;

          let calculatingPaceBySpeed = true;
          let gettingEnhancedCadence = true;
          let dataPrepForLTTB = records?.map((record, index) => {
            // In addition to the x and y keys (so LTTB func doesn't fail,
            // also let it smooth out additional data points for this FIT file.
            let updatedRec = { x: record.elapsed_time, y: 0, ...record };
            if (location?.state?.data.activityType === "RUNNING") {
              if (updatedRec.cadence) {
                // convert spm to rpm
                updatedRec.cadence = record.cadence * 2;
              } else if (updatedRec.enhanced_cadence) {
                updatedRec.cadence = record.enhanced_cadence * 2;
              }
            }
            let val = updatedRec.speed;
            if (isNaN(val)) {
              calculatingPaceBySpeed = false;
            }
            if (!calculatingPaceBySpeed) {
              val = updatedRec.enhanced_speed;
              updatedRec["speed"]=updatedRec?.enhanced_speed

            }
            if (val && !isNaN(val)) {
              updatedRec.pace = 60 / val;
              if (updatedRec.pace >= 15 && index - 1 > 0) {
                let previousRecPace = records[index - 1]?.speed
                  ? 60 / records[index - 1].speed
                  : 10;
                updatedRec.pace = previousRecPace;
              }
            } else {
              updatedRec.pace = 0;
            }
            return updatedRec;
          });

          let simplified = LTTB(dataPrepForLTTB, 200);

          setSimplified2(simplified);

 
          setLapData2(data?.laps)
        }
      }
    );

    setLoading(false);
  };

  useEffect(() => {
    setSelectedWorkoutMatrixes(extractMetrics(selectedWorkoutToCompare));
    setWorkoutFromCalenderMatrixes(extractMetrics(workoutFromCalender));
  }, [selectedWorkoutToCompare, workoutFromCalender]);

  function extractMetrics(data) {
    const getValue = (path, defaultValue = "-") => {
      let value = path.reduce(
        (acc, key) =>
          acc && acc[key] !== undefined && acc[key] !== null
            ? acc[key]
            : defaultValue,
        data
      );
      if (typeof value === "number") {
        let newValue = value.toFixed(2);
        return newValue;
      }
      return value;
    };

    const metrics = {
      rTSS: getValue(["computedMetrics", "front_block_metrics", "rTSS"]),
      Distance: formatDistance(getValue(["summary", "distanceInMeters"])) || "-",
      Time: formatTime(getValue(["summary", "durationInSeconds"])),
      Calories: `${getValue(["summary", "activeKilocalories"])} c`,
      Fatigue: getValue(["computedMetrics", "fatigue_level"]),
      Effort: getValue(["computedMetrics", "effort_level"]),
      Elevation_Gain: `${roundOff(getValue([
        "summary",
        "totalElevationGainInMeters",
      ]))} m`,
      Avg_Heart_Rate: `${roundOff(getValue([
        "summary",
        "averageHeartRateInBeatsPerMinute",
      ])) }  bpm`,
      Max_Heart_Rate: `${roundOff(getValue([
        "summary",
        "maxHeartRateInBeatsPerMinute",
      ]))}  bpm`,
      Avg_Cadence: `${roundOff(getValue([
        "summary",
        "averageRunCadenceInStepsPerMinute",
      ]))}  rpm`,
      Max_Cadence: `${roundOff(getValue([
        "summary",
        "maxRunCadenceInStepsPerMinute",
      ]))}  rpm`,
      Avg_Speed: `${getValue([
        "summary",
        "averageSpeedInMetersPerSecond",
      ])}  kmph`,
      Max_Speed: `${getValue(["summary", "maxSpeedInMetersPerSecond"])}  kmph`,
      Avg_Pace: `${getValue([
        "summary",
        "averagePaceInMinutesPerKilometer",
      ])}  min/km`,
      Max_Pace: `${getValue([
        "summary",
        "maxPaceInMinutesPerKilometer",
      ])}  min/km`,
    };

    return metrics;
  }

  const roundOff=(number)=>{
    if(!number) return null
    let newNumber=Number(number)
    return newNumber?.toFixed(0)
  }

  return (
    <Dialog
      open={open}
      sx={{ padding: "0px" }}
      onClose={() => setOpen(false)}
      fullScreen
    >
      <div
        style={{
          height: "77px",
          width: "100vw",
          paddingLeft: "30px",
          paddingRight: "32px",
          backgroundColor: "#f8f8f8",
          position: "relative",
        }}
      >
        <div
          className="add-program-modal-head"
          style={{
            height: "100%",
            width: "100%",
            backgroundColor: "#FFFFFF",
            borderRadius: "0px 0px 16px 16px",
            display: "flex",
            alignItems: "center",

            boxShadow: "0px 2px 5px -1px rgba(0,0,0,0.13)",
          }}
        >
          <div className="flex items-center">
            <ArrowLeft
              sx={{
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                left: 38,
                cursor: "pointer",
              }}
              onClick={() => setOpen(false)}
            />

            {
              <>
                {" "}
                <p
                  style={{
                    marginLeft: "32px",
                  }}
                  className="add-program-modal-head-title"
                >
                  {userInfo?.name}
                </p>
                <p
                  style={{
                    marginLeft: "32px",
                    alignSelf: "end",
                  }}
                  className="add-program-modal-head-title"
                ></p>
              </>
            }
          </div>
        </div>
      </div>

      <DialogContent sx={{ 
        paddingBottom:"25px",
        paddingLeft: "30px",
        paddingRight: "32px",
        backgroundColor: "#f8f8f8",
       }}
      className="pb-[20px]">
           {loading ? (
        <div className="w-full h-full absolute items-center justify-center bg-black-pure/10"> 
        <SpurfitCircularProgress/>
        </div>
       
      ) : 
      <> 
        <div className="  pt-5  ">
          <ChartForCompareWorkouts
            data={data?.data}
            simplifiedDataForSelectedWorkout={simplified}
            simplifiedDataForWorkoutFromCalender={simplified2}
            selectedWorkoutName={
              selectedWorkoutToCompare?.summary?.activityName
            }
            workoutFromCalenderName={workoutFromCalender?.summary?.activityName}
            loading={loading}
          />
        </div>
      
        <div className="flex gap-3  pt-[20px] w-full bg-white-pure ">
           
          <div className="w-[15%] mt-[144px] flex  flex-col   ">
            {compareMatrixes?.map((matrix) => {
          return   (
          <div className="h-[33px] text-[16px] font-[600] pl-5 text-black text-left justify-center items-center font-DMSans">
            <p className="">
              {matrix}
              </p>
              </div>
              )
             
            })}
            <div className="h-[85.6px] text-left pl-5">
              <p className="font-[300] text-[22px] mt-[10px]">Laps</p>
              <p className="font-bold mt-[16px]">Splits </p>
            </div>
            <div>
              {lapData?.length > lapData2?.length ?
              lapData?.map((_,index)=>(
                <div className="h-[33px] text-[16px] pl-5 font-[600] text-black text-left justify-center items-center font-DMSans">
                  <p>{index+1}</p>
                </div>
              )):
              lapData2?.map((_,index)=>(
                <div className="h-[33px] text-[16px] font-[600] pl-5 text-black text-left justify-center items-center font-DMSans">
                  <p>{index+1}</p>
                </div>
              ))
              }
            </div>
          </div>

          
       <div className="w-[85%] flex gap-5"> 
        
          <GarminWorkoutCompareCard
            matrixs={workoutFromCalenderMatrixes}
            name={workoutFromCalender?.summary?.activityName}
            time={workoutFromCalender?.dateRecorded}
            key={"selectedWorkoutFromCalender"}
            color={colourTokens?.workoutFromCalender}
            lapData={lapData2}
            loading={loading}
          />

          <GarminWorkoutCompareCard
            matrixs={selectedWorkoutMatrixes}
            name={selectedWorkoutToCompare?.summary?.activityName}
            time={selectedWorkoutToCompare?.dateRecorded}
            key={"selectedWorkoutByUser"}
            color={colourTokens?.selectedWorkout}
            lapData={lapData}
            loading={loading}
          />
          </div>
           
        </div>
        </>
}
      </DialogContent>
    </Dialog>
  );
}

export default CompareWorkoutDialoge;

const GarminWorkoutCompareCard = ({ matrixs, name, time, color,lapData}) => {
  console.log("lapData",lapData)
  let lapOptions=["Time","Distance","Heart Rate"]
  return (
    <> 
    
    <div className="h-full w-[50%] border-2  rounded-sm ">
      {/* header of the table */}
      <div className={`flex flex-col gap-2 items-center border-b-2 h-[140px]  `}>
        <img
          src="https://img.icons8.com/?size=100&id=IVpG4Gj9cCnd&format=png&color=000000"
          alt="running"
          className={`h-[40px] w-[40px] p-[5px] m-2 mt-4 border border-[${color}] rounded-full`}
          style={{ borderColor: color }}
        />
        <div className="h-12 w-full   flex items-center justify-center font-DMSans">
          <p>{name}</p>
        </div>
        <div className="text-[13px] font-[400] text-gray-400">
          {formatDate(time)}
        </div>
      </div>
      {/* middle part of the table */}

      {Object.keys(matrixs || {})?.length > 0 &&
        Object.keys(matrixs)?.map((matrix, index) => {
          return (
            <div
              className={cn(
                "h-[33px] w-full border-b-2 flex items-center  justify-center text-[14px] font-[400]",
                {
                  "bg-gray-100": index % 2 === 0,
                }
              )}
            >
              <p className="  font-DMSans ">{matrixs?.[matrix]}</p>
            </div>
          );
        })}
        {/* lap heading */}
        <div className="flex items-baseline justify-center ">
        {
          lapOptions.map((option) =>{
            return(
              <div className="w-[33%] text-center font-bold DMSans border-b-2 mt-[60px]">
              <p>{option}</p>
              </div>
            )
          })
        }
        </div>
        {/* lap data */}
         
   
     {lapData?.length > 0 &&   <div className="">
          {
            lapData?.length > 0 &&
            lapData?.map((lap,index)=>{
              return(
                 
                 <div   
                 className={cn(
                  "h-[33px] w-full border-b-2 flex items-center text-center justify-center text-[14px] font-[400]",
                  {
                    "bg-gray-100": index % 2 === 0,
                    "border-b-0":index === lapData?.length-1, 
                  }
                )}>
                  <p className="w-[33%] font-DMSans">{ formatTime(lap?.total_elapsed_time)  ||"-"}</p>
                  <p className="w-[33%] font-DMSans">{(lap?.total_distance)?.toFixed(2)||"-"}</p>
                  <p className="w-[33%] font-DMSans">{(lap?.avg_heart_rate)|| "-"}</p>
                 </div>
           
              )
            })
          }

        </div>}
    </div>
    </>
  );
};

const formatTime = (seconds) => {
  if (!seconds) return null;
  let hours = Math.floor(seconds / 3600);
  let minutes = Math.floor((seconds % 3600) / 60);
  let remainingSeconds = Math.round(seconds % 60);

  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
};

const formatDistance = (meters) => {
  if (!meters || isNaN(meters)) return null;
  let data = (meters / 1000).toFixed(2);
console.log("meters",meters,data)
  return `${data} km`;
};

const formatTimeDynamically = (minutesPerKilometer) => {
  if (!minutesPerKilometer) return "-";
  let minutes = Math.floor(minutesPerKilometer);
  let seconds = Math.round((minutesPerKilometer - minutes) * 60);

  return `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;
};

const convertMPSToKPH = (mps) => {
  let val = mps * 3.6;
  return val.toFixed(2);
};

function formatDate(dateString) {
  const date = new Date(dateString);
  const today = new Date();

  const options = { month: "short", day: "numeric", year: "numeric" };
  const formattedDate = date.toLocaleDateString("en-US", options);

  const timeOptions = { hour: "numeric", minute: "numeric", hour12: true };
  const formattedTime = date
    .toLocaleTimeString("en-US", timeOptions)
    .toLowerCase();

  const isToday =
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear();

  return isToday
    ? `today @ ${formattedTime}`
    : `${formattedDate} @ ${formattedTime}`;
}
