import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
} from "@mui/material";
import { useState } from "react";
import Dropzone from "react-dropzone";

import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import CloseIcon from "../../assets/trainerProfile/closeIcon.svg";
import VideoUploadIcon from "../../assets/trainerProfile/videoUploadImage.svg";
import VideoThumbnail from "./VideoThumbnail";

export default function ClientTestimonialModal({
  open,
  setOpen,
  getProfileData,
}) {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const [uploadedTestimonial, setUploadedTestimonial] = useState(null);
  const [progress, setProgress] = useState(0);

  const uploadTestimonialFile = async (file) => {
    let trainerDetails = JSON.parse(localStorage.getItem("trainerCredentials"));

    const formData = new FormData();
    let route = "";
    if (file.type.startsWith("image")) {
      route = `${APIURL}CF/api/CoachWeb/Create/updateClientTestimonialSingleImage?coachID=${trainerDetails?.password}`;
      formData.append("image", file);
    } else if (file.type.startsWith("video")) {
      route = `${APIURL}CF/api/CoachWeb/Create/updateClientTestimonialSingleVideo?coachID=${trainerDetails?.password}`;
      formData.append("video", file);
    } else {
      return;
    }

    const config = {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgress(percentCompleted);
        console.log(`Upload progress: ${percentCompleted}%`);
      },
      headers: {
        "Cache-Control": "no-cache",
      },
    };

    try {
      const response = await axios.post(route, formData, config);
      toast.success("Uploaded Successfully!");
    } catch (error) {
      if (error?.response && error.response.status == 413) {
        toast.error("file size too large!");
      } else {
        toast.error("Unable to upload. Try again!");
      }
    } finally {
    }
  };

  function formatFileSize(bytes) {
    if (bytes < 1024) return bytes + " bytes";
    else if (bytes < 1024 * 1024) return (bytes / 1024).toFixed(2) + " KB";
    else if (bytes < 1024 * 1024 * 1024)
      return (bytes / (1024 * 1024)).toFixed(2) + " MB";
    else return (bytes / (1024 * 1024 * 1024)).toFixed(2) + " GB";
  }

  return (
    <Dialog
      open={open}
      maxWidth="md"
      PaperProps={{
        sx: {
          maxHeight: "786px",
          width: "723px",
          borderRadius: "16px",
          pb: "30px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          position: "relative",
          justifyContent: "space-between",

          mt: "12px",
        }}
      >
        <DialogTitle
          sx={{
            ml: "9px",
            fontSize: "24px",
            fontFamily: "DM Sans",
            fontWeight: 700,
            color: "#121314",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Manage Client Testimonials
        </DialogTitle>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            // position: "absolute",
            // top: "23px",
            // right: "30px",
            mr: "30px",
          }}
        >
          <button
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "linear-gradient(to right, #464FEB, #8330E9)",
              borderRadius: "80px",
              color: "#fff",
              fontFamily: "DM Sans",
              fontWeight: "400",
              fontSize: "18px",
              padding: "6px 14px",
              height: "35px",
              marginRight: "20px",
            }}
            onClick={() => {
              getProfileData();
              setUploadedTestimonial(null);
              setOpen(false);
            }}
          >
            Save
          </button>
          <img
            src={CloseIcon}
            style={{
              cursor: "pointer",
              width: "28px",
              height: "28px",
            }}
            onClick={() => {
              if (uploadedTestimonial != null) {
                getProfileData();
              }
              setUploadedTestimonial(null);
              setOpen(false);
            }}
          />
        </Box>
      </Box>

      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            height: "297px",
            width: "659px",
            borderRadius: "20px",
            overflow: "hidden",
            border: "1px solid #464FEB",
            backgroundColor: "#fff",
          }}
        >
          <Dropzone
            onDrop={(acceptedFiles) => {
              setUploadedTestimonial(acceptedFiles[0]);
              uploadTestimonialFile(acceptedFiles[0]);
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "30px",
                  }}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <div
                    style={{
                      borderRadius: "88px",
                      padding: "19px 18px 18px 19px",
                      backgroundColor: "#EDEDEF",
                      height: "88px",
                      width: "88px",
                    }}
                  >
                    <img
                      style={{
                        height: "51px",
                        width: "51px",
                      }}
                      src={VideoUploadIcon}
                    />
                  </div>
                  <Typography
                    sx={{
                      color: "#333338",
                      textAlign: "center",
                      fontSize: "20px",
                      fontWeight: 700,
                      fontFamily: "DM Sans",
                      mt: "20px",
                    }}
                  >
                    Drag & drop testimonial videos/images you want to upload
                  </Typography>
                  <Typography
                    sx={{
                      color: "#757579",
                      fontSize: "18px",
                      fontWeight: 500,
                      fontFamily: "DM Sans",
                    }}
                  >
                    Max file size: 15mb
                  </Typography>

                  <button
                    style={{
                      backgroundColor: "#545567",
                      boxShadow:
                        "0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 4px 8px 0px rgba(0, 0, 0, 0.14)",
                      borderRadius: "52px",
                      color: "#fff",
                      height: "44px",
                      width: "148px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "16px",
                      fontWeight: 500,
                      lineHeight: "22px",
                      letterSpacing: "-0.43px",
                      fontFamily: "DM Sans",
                      textAlign: "center",
                      marginTop: "28px",
                    }}
                  >
                    Browse
                  </button>
                </div>
              </section>
            )}
          </Dropzone>
        </Box>
        {uploadedTestimonial && (
          <Box
            sx={{
              mt: "30px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "659px",
              ml: "12px",
            }}
          >
            <Box sx={{ display: "flex" }}>
              {uploadedTestimonial.type.startsWith("image") ? (
                <img
                  style={{
                    width: "116px",
                    height: "79.432px",
                    objectFit: "cover",
                    borderRadius: "3px",
                  }}
                  src={URL.createObjectURL(uploadedTestimonial)}
                />
              ) : (
                <Box
                  sx={{
                    width: "116px",
                    height: "79.432px",
                    borderRadius: "3px",
                  }}
                >
                  <VideoThumbnail
                    videoUrl={URL.createObjectURL(uploadedTestimonial)}
                  />
                </Box>
              )}

              <Box sx={{ ml: "20px", height: "79.432px" }}>
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: 700,
                    fontFamily: "DM Sans",
                    color: "#333338",
                  }}
                >
                  {uploadedTestimonial.name}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 400,
                    fontFamily: "DM Sans",
                    color: "#888",
                    mt: "15px",
                  }}
                >
                  {formatFileSize(uploadedTestimonial.size)}
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <LinearProgress
                style={{
                  width: "154px",
                  height: "9px",
                  borderRadius: "5px",
                  marginRight: "10px",
                  backgroundColor: "#D9D9D9",
                }}
                variant="determinate"
                color="royalBlue"
                value={progress}
              />
              <Typography
                sx={{
                  color: "#464FEB",
                  fontSize: "18px",
                  fontWeight: 500,
                  fontFamily: "DM Sans",
                  width: "100px",
                }}
              >
                {progress}%
              </Typography>
            </Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}
