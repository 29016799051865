import { CustomAlertContext } from "@/App";
import NoPrograms from "@/features/Programs/NoPrograms";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import {
  Box,
  Dialog,
  DialogContent,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { Trash, UserPlus } from "lucide-react";
import moment from "moment";
import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import SpinnerComponent from "../../components/layout/spinner";
import { store } from "../../redux/store";
import AddSubscriptionDialog from "./AddSubscriptionDialog";

export default function Products({
  trainerData,
  getTrainerData,
  showAddProductModal,
  setShowAddProductModal,
}) {
  const APIURL = useSelector((state) => state.auth.apiURL);
  // States for add product modal
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [startDateChecked, setStartDateChecked] = useState({
    1: true,
    2: false,
    3: false,
  });
  const [products, setProducts] = useState(null);
  const [showEditProductModal, setShowEditProductModal] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [price, setPrice] = useState("");
  const [paymentType, setPaymentType] = useState("Recurring");
  const [currency, setCurrency] = useState("INR");
  const [currencySymbol, setCurrencySymbol] = useState("₹");
  const [editModalData, setEditModalData] = useState(null);
  const [allowSelfCancellation, setAllowSelfCancellation] = useState(false);
  const [productDuration, setProductDuration] = useState(
    "Renews until cancel the subscription"
  );
  const [image, setImage] = useState();
  const [imageUploadedUrl, setImageUploadedUrl] = useState(null);
  const [paymentInterval, setPaymentInterval] = useState("");
  const [period, setPeriod] = useState("week");
  // const [trainerData, setTrainerData] = useState([]);
  const [statusData, setStatusData] = useState(null);

  // state for show products
  const [loading, setLoading] = useState(false);
  const [allProducts, setAllProducts] = useState([]);
  const [loadingConnect, setLoadingConnect] = useState(false);

  // for options in each row
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [addSubsModal, setAddSubsModal] = useState(false);
  // searching functionality

  const { displayAlert } = useContext(CustomAlertContext);

  const history = useHistory();

  const location = useLocation();
  const { id } = useParams();
  const handleMoreClick = (event, item) => {
    // console.log("item ha kya",item);
    setSelectedItem(item);
    setAnchorEl(event.currentTarget);
    setEditModalData(item);
  };

  const handleMoreClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = async (option, prod) => {
    if (option === "create_subscription") {
      // setAddSubsModal(true);
      setShowSubscriptionModal(true);
    } else {
      // delete the product call will be made here.
      console.log("prod is what", prod);
      try {
        let res = await axios({
          method: "post",
          url: `${APIURL}CF/api/stripeconnect/deleteProduct`,
          data: {
            productTableID: prod?.id,
            coachID: store.getState().auth.trainerUUID,
          },
        });
        console.log("deleted sucessfully", res?.data?.result);
      } catch (error) {
        console.log("there is some problem deleting the product", error);
      } finally {
        getTrainerData();
      }
    }
    setAnchorEl(null);
  };

  const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    "& .MuiSwitch-track": {
      borderRadius: 22 / 2,
      "&::before, &::after": {
        content: '""',
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: 16,
        height: 16,
      },
      "&::before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      "&::after": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "none",
      width: 16,
      height: 16,
      margin: 2,
      color: "white",
    },
  }));
  const allowSelfCancellationLabel = () => (
    <label
      className="flex items-center text-black"
      style={{
        fontFamily: "DM Sans",
        fontWeight: "500",
        fontSize: "15px",
      }}
    >
      Allow self cancellation&nbsp;
      <HelpRoundedIcon
        sx={{
          color: "#c3c6cb",
          width: "18px",
          height: "18px",
        }}
      />{" "}
    </label>
  );

  const handleSelfCancellation = (event) => {
    console.log("checked or not", event.target.checked);
    setAllowSelfCancellation(event.target.checked);
  };

  const handleChangeChecked = (objKey) => {
    if (objKey === 1) {
      setStartDateChecked({
        1: true,
        2: false,
        3: false,
      });
    } else if (objKey === 2) {
      setStartDateChecked({
        1: false,
        2: true,
        3: false,
      });
    } else {
      setStartDateChecked({
        1: false,
        2: false,
        3: true,
      });
    }
  };
  const durationFormat = {
    week: "week",
    day: "day",
    month: "month",
    year: "year",
  };
  const CURRENCY_OPTIONS = [
    "AED", // United Arab Emirates Dirham
    "AFN", // Afghan Afghani
    "ALL", // Albanian Lek
    "AMD", // Armenian Dram
    "ANG", // Netherlands Antillean Guilder
    "AOA", // Angolan Kwanza
    "ARS", // Argentine Peso
    "AUD", // Australian Dollar
    "AWG", // Aruban Florin
    "AZN", // Azerbaijani Manat
    "BAM", // Bosnia-Herzegovina Convertible Mark
    "BBD", // Barbadian Dollar
    "BDT", // Bangladeshi Taka
    "BGN", // Bulgarian Lev
    "BHD", // Bahraini Dinar
    "BIF", // Burundian Franc
    "BMD", // Bermudian Dollar
    "BND", // Brunei Dollar
    "BOB", // Bolivian Boliviano
    "BRL", // Brazilian Real
    "BSD", // Bahamian Dollar
    "BTN", // Bhutanese Ngultrum
    "BWP", // Botswana Pula
    "BYN", // Belarusian Ruble
    "BZD", // Belize Dollar
    "CAD", // Canadian Dollar
    "CDF", // Congolese Franc
    "CHF", // Swiss Franc
    "CLP", // Chilean Peso
    "CNY", // Chinese Yuan
    "COP", // Colombian Peso
    "CRC", // Costa Rican Colón
    "CUP", // Cuban Peso
    "CVE", // Cape Verdean Escudo
    "CZK", // Czech Koruna
    "DJF", // Djiboutian Franc
    "DKK", // Danish Krone
    "DOP", // Dominican Peso
    "DZD", // Algerian Dinar
    "EGP", // Egyptian Pound
    "ERN", // Eritrean Nakfa
    "ETB", // Ethiopian Birr
    "EUR", // Euro
    "FJD", // Fijian Dollar
    "FKP", // Falkland Islands Pound
    "FOK", // Faroese Króna
    "GBP", // British Pound Sterling
    "GEL", // Georgian Lari
    "GGP", // Guernsey Pound
    "GHS", // Ghanaian Cedi
    "GIP", // Gibraltar Pound
    "GMD", // Gambian Dalasi
    "GNF", // Guinean Franc
    "GTQ", // Guatemalan Quetzal
    "GYD", // Guyanaese Dollar
    "HKD", // Hong Kong Dollar
    "HNL", // Honduran Lempira
    "HRK", // Croatian Kuna
    "HTG", // Haitian Gourde
    "HUF", // Hungarian Forint
    "IDR", // Indonesian Rupiah
    "ILS", // Israeli New Shekel
    "IMP", // Isle of Man Pound
    "INR", // Indian Rupee
    "IQD", // Iraqi Dinar
    "IRR", // Iranian Rial
    "ISK", // Icelandic Króna
    "JEP", // Jersey Pound
    "JMD", // Jamaican Dollar
    "JOD", // Jordanian Dinar
    "JPY", // Japanese Yen
    "KES", // Kenyan Shilling
    "KGS", // Kyrgystani Som
    "KHR", // Cambodian Riel
    "KID", // Kiribati Dollar
    "KMF", // Comorian Franc
    "KPW", // North Korean Won
    "KRW", // South Korean Won
    "KWD", // Kuwaiti Dinar
    "KYD", // Cayman Islands Dollar
    "KZT", // Kazakhstani Tenge
    "LAK", // Laotian Kip
    "LBP", // Lebanese Pound
    "LKR", // Sri Lankan Rupee
    "LRD", // Liberian Dollar
    "LSL", // Lesotho Loti
    "LYD", // Libyan Dinar
    "MAD", // Moroccan Dirham
    "MDL", // Moldovan Leu
    "MGA", // Malagasy Ariary
    "MKD", // Macedonian Denar
    "MMK", // Myanma Kyat
    "MNT", // Mongolian Tugrik
    "MOP", // Macanese Pataca
    "MRU", // Mauritanian Ouguiya
    "MUR", // Mauritian Rupee
    "MVR", // Maldivian Rufiyaa
    "MWK", // Malawian Kwacha
    "MXN", // Mexican Peso
    "MYR", // Malaysian Ringgit
    "MZN", // Mozambican Metical
    "NAD", // Namibian Dollar
    "NGN", // Nigerian Naira
    "NIO", // Nicaraguan Córdoba
    "NOK", // Norwegian Krone
    "NPR", // Nepalese Rupee
    "NZD", // New Zealand Dollar
    "OMR", // Omani Rial
    "PAB", // Panamanian Balboa
    "PEN", // Peruvian Nuevo Sol
    "PGK", // Papua New Guinean Kina
    "PHP", // Philippine Peso
    "PKR", // Pakistani Rupee
    "PLN", // Polish Zloty
    "PYG", // Paraguayan Guarani
    "QAR", // Qatari Rial
    "RON", // Romanian Leu
    "RSD", // Serbian Dinar
    "RUB", // Russian Ruble
    "RWF", // Rwandan Franc
    "SAR", // Saudi Riyal
    "SBD", // Solomon Islands Dollar
    "SCR", // Seychellois Rupee
    "SDG", // Sudanese Pound
    "SEK", // Swedish Krona
    "SGD", // Singapore Dollar
    "SHP", // Saint Helena Pound
    "SLL", // Sierra Leonean Leone
    "SOS", // Somali Shilling
    "SRD", // Surinamese Dollar
    "SSP", // South Sudanese Pound
    "STN", // São Tomé and Príncipe Dobra
    "SYP", // Syrian Pound
    "SZL", // Swazi Lilangeni
    "THB", // Thai Baht
    "TJS", // Tajikistani Somoni
    "TMT", // Turkmenistani Manat
    "TND", // Tunisian Dinar
    "TOP", // Tongan Pa'anga
    "TRY", // Turkish Lira
    "TTD", // Trinidad and Tobago Dollar
    "TVD", // Tuvaluan Dollar
    "TWD", // New Taiwan Dollar
    "TZS", // Tanzanian Shilling
    "UAH", // Ukrainian Hryvnia
    "UGX", // Ugandan Shilling
    "USD", // United States Dollar
    "UYU", // Uruguayan Peso
    "UZS", // Uzbekistan Som
    "VES", // Venezuelan Bolívar Soberano
    "VND", // Vietnamese Dong
    "VUV", // Vanuatu Vatu
    "WST", // Samoan Tala
    "XAF", // CFA Franc BEAC
    "XCD", // East Caribbean Dollar
    "XDR", // Special Drawing Rights
    "XOF", // CFA Franc BCEAO
    "XPF", // CFP Franc
    "YER", // Yemeni Rial
    "ZAR", // South African Rand
    "ZMW", // Zambian Kwacha
    "ZWL", // Zimbabwean Dollar
  ];

  const CURRENCY_SYMBOLS = {
    KRW: "₩",
    USD: "$",
    CAD: "C$",
    INR: "₹",
    AED: "د.إ",
    EUR: "€",
    GBP: "£",
    SGD: "S$",
  };
  const PRODUCT_DURATIONS = ["Renews until cancel the subscription"];

  const payment_type_options = ["Recurring", "One time"];
  const clearCreateProductModalState = () => {
    setProductName("");
    setProductDescription("");
    setPrice("");
    setCurrency("INR");
    setPaymentInterval("");
    setPeriod("week");
    setImage("");
    setImageUploadedUrl("");
    setPaymentInterval("");
    setAllowSelfCancellation(false);
    setStartDateChecked({
      1: true,
      2: false,
      3: false,
    });
    setPaymentType("Recurring");
  };

  const addNewProduct = async () => {
    let data = {
      name: productName,
      description: productDescription ? productDescription : "",
      price: parseInt(price) || 0,
      currency: currency,
      interval: parseInt(paymentInterval) || 0,
      period: period,
      coachID: store.getState().auth.trainerUUID,
    };
    await axios({
      method: "post",
      url: `${APIURL}CF/api/CoachWeb/Create/addNewProduct`,
      data: data,
    })
      .then((res) => {
        console.log("added new product successfully");
        loadAllProducts();
        setShowAddProductModal(false);
        clearCreateProductModalState();
      })
      .catch((err) => {
        console.log("error occured in add new product", err);
        setShowAddProductModal(false);
        clearCreateProductModalState();
      });
  };

  const addStripeProduct = async () => {
    if (
      isEmpty(productName) ||
      isEmpty(productDescription) ||
      isEmpty(price) ||
      isEmpty(currency) ||
      isEmpty(paymentType) ||
      isEmpty(paymentInterval) ||
      isEmpty(period)
    ) {
      displayAlert({
        type: "error",
        message: "Please fill all the fields",
      });
      return;
    }

    await axios({
      method: "post",
      url: `${APIURL}CF/api/stripeconnect/prod`,
      data: {
        p_name: productName,
        p_desc: productDescription,
        coachId: store.getState().auth.trainerUUID,
        unitAmount: price,
        currency: currency.toLocaleLowerCase(),
        billingScheme: paymentType,
        intervalCount: paymentInterval,
        interval: period,
      },
    })
      .then((res) => {
        console.log("added stripe product");
        getTrainerData();
      })
      .catch((err) => {
        console.log("error occured in add stripe product", err);
      })
      .finally(() => {
        setShowAddProductModal(false);
        clearCreateProductModalState();
      });
  };

  const loadAllProducts = async () => {
    setLoading(true);
    await axios({
      method: "get",
      url: `${APIURL}CF/api/CoachWeb/Get/getAllProductsByCoach`,
      params: {
        coachID: store.getState().auth.trainerUUID,
      },
    })
      .then((res) => {
        setAllProducts(res.data.result);
        setLoading(false);
      })
      .catch((err) => {
        console.log("error while getting products: ", err);
        setLoading(false);
      });
  };

  const headerCellStyle = {
    fontWeight: 600,
    fontSize: ["14px", "14px", "14px"],
    color: "black",
    borderBottom: "1px solid #E7E7E7",
    borderTop: "1px solid #E7E7E7",
    padding: "5px 20px",
    backgroundColor: "#F3F3F3",
    width: "15%",
  };

  const bodyCellStyle = {
    fontWeight: 500,
    fontSize: ["14px", "14px", "14px"],
    color: "#071148",
    borderBottomColor: "rgb(224, 224, 224)",
    padding: "5px 20px",
    width: "15%",
  };
  const currencySymbols = {
    KRW: "₩",
    USD: "$",
    CAD: "$",
    INR: "₹",
    AED: "د.إ",
    EUR: "€",
    GBP: "£",
    SGD: "$",
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            height: "70vh",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <SpinnerComponent />
        </div>
      ) : (
        <Box>
          <TableContainer
            elevation={0}
            component={Paper}
            style={{
              fontFamily: "DM Sans",
              maxHeight: "600px",
              overflowY: "scroll",
            }}
          >
            <Table className="w-full h-full">
              <TableHead>
                <TableCell sx={headerCellStyle} align="left">
                  Product Name
                </TableCell>
                <TableCell sx={headerCellStyle} align="left">
                  Price
                </TableCell>
                <TableCell sx={headerCellStyle} align="left">
                  Billing Cycle
                </TableCell>
                <TableCell sx={headerCellStyle} align="left">
                  Created At
                </TableCell>
                <TableCell sx={headerCellStyle} align="left">
                  Subscribers
                </TableCell>
                <TableCell sx={headerCellStyle} align="right">
                  Actions
                </TableCell>
              </TableHead>
              {trainerData?.length === 0 ? (
                <div className="w-full h-full flex justify-center items-center ml-[250%] mt-[60%]">
                  <NoPrograms
                    prod={true}
                    setShowAddProductModal={setShowAddProductModal}
                  />
                </div>
              ) : (
                <TableBody>
                  {trainerData?.map((product) => {
                    let name = product.prodName;
                    let cost =
                      currencySymbols[
                        product?.prices?.[0]?.currency.toUpperCase()
                      ] +
                      "" +
                      product?.prices?.[0]?.amount +
                      "/" +
                      product?.prices?.[0]?.intervalCount +
                      " " +
                      product?.prices?.[0]?.interval;

                    if (cost.includes("null") || cost.includes("undefined"))
                      return <></>;
                    return (
                      <TableRow>
                        <TableCell
                          sx={bodyCellStyle}
                          align="left"
                          component="th"
                          scope="row"
                        >
                          {name}
                        </TableCell>
                        <TableCell sx={bodyCellStyle} align="left">
                          {product?.prices?.[0]?.amount}{" "}
                          {product?.prices?.[0]?.currency.toUpperCase()}
                        </TableCell>
                        <TableCell sx={bodyCellStyle} align="left">
                          {product?.prices?.[0]?.intervalCount}&nbsp;
                          {product?.prices?.[0]?.interval}
                        </TableCell>
                        <TableCell sx={bodyCellStyle} align="left">
                          {moment(product?.createdAt).format("DD MMM YYYY")}
                        </TableCell>
                        <TableCell sx={bodyCellStyle} align="left">
                          {product?.coachSubscriptions?.length}
                        </TableCell>
                        <TableCell sx={bodyCellStyle} align="right">
                          <div className="flex items-center justify-end gap-3">
                            <div
                              onClick={() => {
                                handleOptionClick("delete", product);
                              }}
                              className="cursor-pointer"
                            >
                              <Trash size={18} color="#FF0000" />
                            </div>

                            <div
                              onClick={() => {
                                let tempArray = [];
                                tempArray.push(product);
                                setProducts(tempArray);
                                handleOptionClick("create_subscription");
                              }}
                              className="cursor-pointer"
                            >
                              <UserPlus size={18} />
                            </div>
                          </div>
                          {/* <IconButton
                            sx={{
                              width: "30px",
                              height: "30px",
                              borderRadius: "12px",
                            }}
                            onClick={(event) => handleMoreClick(event, product)}
                          >
                            <img
                              src={MoreIcon}
                              alt="more icon in table"
                              style={{ cursor: "pointer" }}
                            />
                          </IconButton>
                          <Menu
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleMoreClose}
                          >
                            <MenuItem
                              style={{
                                fontSize: "12px",
                                fontWeight: "500",
                                fontFamily: "DM Sans",
                              }}
                              onClick={() =>
                                handleOptionClick("delete", product)
                              }
                            >
                              Delete Product
                            </MenuItem>
                            <MenuItem
                              style={{
                                fontSize: "12px",
                                fontWeight: "500",
                                fontFamily: "DM Sans",
                              }}
                              onClick={() => {
                                let tempArray = [];
                                tempArray.push(selectedItem);
                                setProducts(tempArray);
                                handleOptionClick("create_subscription");
                              }}
                            >
                              Add Subscribers
                            </MenuItem>
                          </Menu> */}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}
            </Table>
          </TableContainer>

          {/* Add product modal  */}
          <Dialog
            style={{
              overflowY: "unset",
            }}
            PaperProps={{
              sx: {
                paddingX: "10px",
                height: "435px",
                width: "940px",
                borderRadius: "12px",
                overflowY: "unset",
              },
            }}
            maxWidth={false}
            open={showAddProductModal}
            onClose={() => setShowAddProductModal(false)}
          >
            <CloseRoundedIcon
              sx={{
                cursor: "pointer",
                position: "absolute",
                top: "5px",
                right: "5px",
              }}
              onClick={() => {
                clearCreateProductModalState();
                setShowAddProductModal(false);
              }}
              className=" text-[#b6b6b6] hover:text-[black]"
            />
            <DialogContent
              style={{
                marginTop: "10px",
                overflowY: "unset",
              }}
            >
              {/* name, description and image */}
              <div className="w-full flex justify-between">
                <div className="left-add-prod w-[70%]">
                  <label
                    htmlFor="product-name"
                    className="text-black font-medium  mb-[5px]"
                  >
                    Product name*
                  </label>
                  <br />
                  <input
                    type="text"
                    id="product-name"
                    placeholder="Like Basic Plan or Monthly Elite Training"
                    className="w-[100%] rounded-md border-[#EAEAEA] border-[1px] px-[12px] py-[5px] font-normal text-[black] text-sm"
                    style={{
                      outline: "none",
                      fontSize: "14px",
                      marginTop: "5px",
                    }}
                    onChange={(e) => setProductName(e.target.value)}
                  />

                  {/* Description */}
                  <div style={{ marginTop: "15px" }}>
                    <label
                      htmlFor="inputField"
                      className="text-black font-medium mb-[5px]"
                    >
                      Description*
                    </label>
                    <textarea
                      id="product descriptions"
                      placeholder={
                        "What's included in this product? Type of training(hybrid/online/in-person), nutrition coaching, video calls or messaging , groups , etc. "
                      }
                      className="w-[100%] rounded-md border-[#EAEAEA] border-[1px] px-[12px] py-[5px] font-normal text-[black] h-[120px]"
                      style={{
                        outline: "none",
                        fontSize: "14px",
                        marginTop: "5px",
                      }}
                      onChange={(e) => setProductDescription(e.target.value)}
                    />
                  </div>
                </div>
                <div className="right-add-prod w-[23%] cursor-pointer mr-[0.5%]">
                  <label className="text-black font-medium mb-[5px]">
                    Product image
                  </label>

                  {imageUploadedUrl ? (
                    <div className=" flex-col h-[196px] w-full flex justify-center items-center relative bg-slate-400">
                      <img
                        alt="sucessfully uploaded image showing"
                        src={imageUploadedUrl}
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "5%",
                          border: "4px solid #fff",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                  ) : (
                    <div className="h-[196px] w-full flex justify-center items-center border-dashed border-[#EAEAEA] border-[2px] relative text-[#c6c5c5] hover:text-[black]">
                      <div className="flex  z-10 cursor-pointer flex-col items-center">
                        <CloudUploadOutlinedIcon
                          style={{
                            width: "40px",
                            height: "auto",
                          }}
                        />
                        <span class="text-font12 ">Click here to upload</span>
                        <input
                          type="file"
                          name="productimage"
                          onChange={(e) => {
                            setImage(e.target.files[0]);
                            setImageUploadedUrl(
                              URL.createObjectURL(e?.target?.files[0])
                            );
                          }}
                          style={{
                            display: "block",
                            height: "100%",
                            width: "100%",
                            position: "absolute",
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            opacity: 0,
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="seperator-add-prod h-[1.5px] bg-[#EAEAEA] my-4 w-full"></div>

              {/* payment type, price, time period, duration */}
              <div style={{ marginTop: "10px" }} className="flex items-center">
                {/* Payment type */}
                <div className="flex flex-col">
                  <label
                    className="text-black font-medium mb-[3px]"
                    style={{
                      fontFamily: "DM Sans",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    Payment Type
                  </label>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={(e) => setPaymentType(e.target.value)}
                    aria-placeholder={"Payment type *"}
                    sx={{
                      background: "#fff",
                      marginRight: "20px",
                      width: "120px",
                      height: "30px",
                      fontWeight: "500",
                      border: "1px solid #EAEAEA",
                      outline: "none",
                      color: "#424242",
                    }}
                    value={paymentType}
                    defaultValue={"Recurring"}
                  >
                    {payment_type_options.map((payTypes) => (
                      <MenuItem
                        style={{
                          width: "100%",
                          fontSize: "14px",
                          outline: "none",
                          alignItems: "start",
                          color: "#424242",
                        }}
                        value={payTypes}
                      >
                        {payTypes}
                      </MenuItem>
                    ))}
                  </Select>
                </div>

                {/* Pricing  */}
                <div className="flex flex-col">
                  {/* Price Input  */}
                  <label
                    className="text-black font-medium mb-[3px]"
                    style={{
                      fontFamily: "DM Sans",
                      fontSize: "14px",
                      fontWeight: "600",
                    }}
                  >
                    Pricing
                  </label>
                  <div className="flex items-center">
                    <input
                      placeholder={"200"}
                      onChange={(e) => setPrice(e.target.value)}
                      value={price}
                      type={"number"}
                      style={{
                        width: "90px",
                        borderTop: "2px solid #E3E3E3",
                        borderBottom: "2px solid #E3E3E3",
                        borderLeft: "2px solid #E3E3E3",
                        paddingLeft: "8px",
                        borderTopLeftRadius: "4px",
                        borderBottomLeftRadius: "4px",
                      }}
                    />
                    {/* Currency select Dropdown */}
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={(e) => setCurrency(e.target.value)}
                      aria-placeholder={"Workout type *"}
                      sx={{
                        background: "#fff",
                        marginRight: "10px",
                        // width: "60px",
                        height: "30px",
                        fontWeight: "500",
                        border: "1px solid #EAEAEA",
                        outline: "none !important",
                        color: "#424242",
                        padding: "0px 0px",
                        paddingRight: "0px",
                        // lineHeight: "20px",
                        // fontSize:"12px",
                        borderTopLeftRadius: "0px",
                        borderBottomLeftRadius: "0px",
                      }}
                      MenuProps={{
                        autoWidth: true, // Restrict width to content width
                        style: { width: "fit-content" }, // Set width to fit content
                      }}
                      IconComponent={null}
                      value={currency}
                      defaultValue={"USD"}
                    >
                      {CURRENCY_OPTIONS.map((currency) => (
                        <MenuItem
                          style={{ width: "100%", fontSize: "14px" }}
                          value={currency}
                        >
                          {currency}
                        </MenuItem>
                      ))}
                    </Select>
                    {paymentType === "Recurring" && (
                      <p className="mr-[10px] text-[#b6b6b6] font-medium">
                        every
                      </p>
                    )}
                  </div>
                </div>

                {/* interval input  */}
                {paymentType === "Recurring" && (
                  <input
                    placeholder={"1"}
                    className="rounded-md border-[#EAEAEA] border-[1px]  font-normal text-[black] text-sm px-[5px] "
                    onChange={(e) => setPaymentInterval(e.target.value)}
                    value={paymentInterval}
                    type={"number"}
                    style={{
                      background: "#fff",
                      marginRight: "10px",
                      width: "80px",
                      height: "30px",
                      fontWeight: "500",
                      border: "1px solid #EAEAEA",
                      outline: "none",
                      color: "#424242",
                      marginTop: "auto",
                    }}
                  />
                )}

                {/* Period Select Dropdown */}
                {paymentType === "Recurring" && (
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={(e) => setPeriod(e.target.value)}
                    sx={{
                      background: "#fff",
                      marginRight: "20px",
                      width: "120px",
                      height: "30px",
                      fontWeight: "500",
                      border: "1px solid #EAEAEA",
                      outline: "none",
                      color: "#424242",
                      marginTop: "auto",
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          // height: 250,
                          // width: 250,
                        },
                      },
                    }}
                    value={period}
                    defaultValue={"week"}
                  >
                    <MenuItem
                      style={{ width: "100%", fontSize: "14px" }}
                      value="day"
                    >
                      Day
                    </MenuItem>
                    <MenuItem
                      style={{ width: "100%", fontSize: "14px" }}
                      value="week"
                    >
                      Week
                    </MenuItem>
                    <MenuItem
                      style={{ width: "100%", fontSize: "14px" }}
                      value="month"
                    >
                      Month
                    </MenuItem>
                    <MenuItem
                      style={{ width: "100%", fontSize: "14px" }}
                      value="year"
                    >
                      Year
                    </MenuItem>
                  </Select>
                )}
                {/* Product Duration */}
                {paymentType === "Recurring" && (
                  <div className="flex flex-col">
                    <label
                      className="text-black font-medium mb-[3px]"
                      style={{
                        fontFamily: "DM Sans",
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                    >
                      Product Duration
                    </label>
                    <p
                      className="flex items-center"
                      style={{
                        background: "#fff",
                        height: "30px",
                        fontSize: "13px",
                        fontWeight: "500",
                        outline: "none",
                        color: "#424242",
                        padding: "0px 5px",
                        borderRadius: "4px",
                        backgroundColor: "#EAEAEA",
                      }}
                    >
                      Recurring until cancel the subscription
                    </p>
                  </div>
                )}
              </div>

              <div className="seperator-add-prod h-[1px] bg-[#EAEAEA] my-4 w-full"></div>

              <div className="flex w-full justify-center">
                <button
                  style={{
                    fontSize: "14px",
                    background: "#6142EA",
                    borderRadius: "8px",
                    color: "#fff",
                    textTransform: "none",
                    padding: "6px 12px",
                    marginBottom: "14px",
                  }}
                  onClick={() => addStripeProduct()}
                >
                  ADD PRODUCT
                </button>
              </div>
            </DialogContent>
          </Dialog>
          {/* Create subscription modal */}
          {showSubscriptionModal && (
            <AddSubscriptionDialog
              open={showSubscriptionModal}
              setOpen={setShowSubscriptionModal}
              product={products}
              activeTab={"PRODUCTS"}
              getTrainerData={() => getTrainerData()}
            />
          )}
        </Box>
      )}
    </>
  );
}

const isEmpty = (item) => {
  return !item || item === "" || item === null || item === undefined;
};
