import * as htmlToImage from "html-to-image";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";
import { Box, Dialog, DialogContent, Stack } from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "../../../assets/closeicon.svg";
import axios from "axios";
import { useSelector } from "react-redux";
import { ShareOutlined } from "@mui/icons-material";
import SelectUserToShareImage from "../../SelectUsersToShareImage";

const RecipeView = ({ isOpen, setIsOpen, recipe }) => {
  let serving_sizes = recipe?.serving_sizes;
  let protein = serving_sizes?.protein;
  let fat = serving_sizes?.fat;
  let carbs = serving_sizes?.carbohydrate;
  let calories = serving_sizes?.calories;
  let no_of_servings = recipe?.number_of_servings;

  let ingredients = recipe?.ingredients;
  const caochID = useSelector((state) => state.auth.trainerUUID);
  const [showSelectUsers, setShowSelectUsers] = useState(false);
  const [image, setImage] = useState(null);

  const userID = "DtWuJxZSkubd";
  const targetRef = React.useRef();

  const handleCapture = async () => {
    try {
      // Use html-to-image to capture the content of the target element
      let element = document.getElementById("recipe-content");
      const imageDataUrl = await htmlToImage.toPng(element);

      // Convert data URL to Blob
      const blob = await fetch(imageDataUrl).then((res) => res.blob());

      setImage(blob);
    } catch (error) {
      console.error("Error capturing or uploading image:", error);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      aria-labelledby="recipe-view-dialog"
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: {
          borderRadius: "20px",
          minWidth: "400px",
        },
      }}
      id="recipe-view-dialog"
    >
      <div id="recipe-content">
        <Stack
          direction="row"
          spacing={2}
          justifyContent={"space-between"}
          padding={"20px 24px"}
        >
          <p
            style={{
              fontWeight: 500,
              fontSize: 14,
              fontFamily: "DM Sans",
            }}
          >
            {recipe?.recipe_name}
          </p>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              marginTop: "-20px",
              marginLeft: "-10px",
              cursor: "pointer",
            }}
          >
            <ShareOutlined
              className="cursor-pointer"
              onClick={() => {
                handleCapture();
                setShowSelectUsers(true);
              }}
            />
            {/* <CloseIcon
              className="cursor-pointer"
              onClick={() => setIsOpen(false)}
            /> */}
            <img src={CloseIcon} alt="close" onClick={() => setIsOpen(false)} />
          </Box>
        </Stack>
        <DialogContent ref={targetRef}>
          <Box margin={"0px 0px 22px 0px"}>
            <p
              style={{
                fontSize: 14,
                fontWeight: 500,
                color: "#242424",
                fontFamily: "DM Sans",
              }}
            >
              Macros per serving
            </p>
            <Stack direction="row" spacing={"32px"}>
              <Box>
                <p
                  style={{
                    fontSize: 10.5,
                    color: "#828282",
                    fontFamily: "DM Sans",
                  }}
                >
                  Protiens
                </p>
                <p
                  style={{
                    fontSize: 17,
                    fontFamily: "DM Sans",
                  }}
                >
                  {protein} g
                </p>
              </Box>
              <Box>
                <p
                  style={{
                    fontSize: 10.5,
                    color: "#828282",
                    fontFamily: "DM Sans",
                  }}
                >
                  Fats
                </p>
                <p
                  style={{
                    fontSize: 17,
                    fontFamily: "DM Sans",
                  }}
                >
                  {fat} g
                </p>
              </Box>
              <Box>
                <p
                  style={{
                    fontSize: 10.5,
                    color: "#828282",
                    fontFamily: "DM Sans",
                  }}
                >
                  Carbs
                </p>
                <p
                  style={{
                    fontSize: 17,
                    fontFamily: "DM Sans",
                  }}
                >
                  {carbs} g
                </p>
              </Box>
            </Stack>
          </Box>
          <Box>
            <Box>
              <p
                style={{
                  fontSize: 12,
                  color: "#828282",
                  fontFamily: "DM Sans",
                }}
              >
                Servings:{" "}
                <span
                  style={{
                    fontWeight: 500,
                    color: "#242424",
                    fontFamily: "DM Sans",
                  }}
                >
                  {no_of_servings}
                </span>
              </p>

              <p
                style={{
                  fontSize: 12,
                  color: "#828282",
                  fontFamily: "DM Sans",
                }}
              >
                Calories per serving: Approximately{" "}
                <span
                  style={{
                    fontWeight: 500,
                    color: "#242424",
                    fontFamily: "DM Sans",
                  }}
                >
                  {calories}
                </span>{" "}
                calories
              </p>
            </Box>

            <Box sx={{ mt: 2 }} name="ingredients">
              <p
                style={{
                  fontSize: 14,
                  fontWeight: 500,
                  color: "#242424",
                  fontFamily: "DM Sans",
                }}
              >
                Ingredients
              </p>
              <Box>
                {Object.entries(ingredients).map(([key, value], index) => {
                  return (
                    <Box key={index}>
                      <p
                        style={{
                          fontSize: 12,
                          color: "#828282",
                          fontFamily: "DM Sans",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span style={{ width: 20 }}>{key}.</span> {value}
                      </p>
                    </Box>
                  );
                })}
              </Box>
            </Box>

            <Box sx={{ mt: 2 }} name="instructions">
              <p
                style={{
                  fontSize: 14,
                  fontWeight: 500,
                  color: "#242424",
                  fontFamily: "DM Sans",
                }}
              >
                Instructions
              </p>
              <Box>
                {Object.entries(recipe?.directions)?.map(
                  ([key, value], index) => {
                    return (
                      <Box key={index}>
                        <p
                          style={{
                            fontSize: 12,
                            color: "#828282",
                            fontFamily: "DM Sans",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span style={{ width: 20 }}>{key}.</span> {value}
                        </p>
                      </Box>
                    );
                  }
                )}
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </div>
      {showSelectUsers && (
        <SelectUserToShareImage
          isOpen={showSelectUsers}
          setIsOpen={setShowSelectUsers}
          onClose={() => {
            setShowSelectUsers(false);
          }}
          recipe={recipe}
          image={image}
        />
      )}
    </Dialog>
  );
};

export default RecipeView;
