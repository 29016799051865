import { Dialog, DialogContent } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Chart } from "chart.js/auto";

import { Line } from "react-chartjs-2";
import moment from "moment";

const FirstValidChart = ({ isOpen, setIsOpen, data }) => {
  const [allMeasurementsChartData, setAllMeasurementsChartData] = useState({});
  const [validKey, setValidKey] = useState("");

  useEffect(() => {
    let allChartData = {};
    data?.sort((a, b) => new Date(a.dateRecorded) - new Date(b.dateRecorded));
    let dateLabelForKey = {};
    let allOtherMeasurements = {};
    data?.forEach((item) => {
      if (item?.measurements) {
        Object.keys(item.measurements).forEach((key) => {
          if (item?.measurements[key]?.value) {
            if (item?.dateRecorded) {
              let modifiedDate = moment(item.dateRecorded).format("Do MM' YY");

              if (!dateLabelForKey[key]) {
                dateLabelForKey[key] = [];
              }
              dateLabelForKey[key].push(modifiedDate);
            }
            if (!allOtherMeasurements[key]) {
              allOtherMeasurements[key] = [];
            }
            allOtherMeasurements[key].push(item.measurements[key].value);
          }
        });
      }
      if (item?.measurements?.mainMeasurements) {
        Object.keys(item?.measurements?.mainMeasurements).forEach((key) => {
          if (item?.measurements?.mainMeasurements[key]?.value) {
            if (item?.dateRecorded) {
              let modifiedDate = moment(item.dateRecorded).format("Do MM' YY");

              if (!dateLabelForKey[key]) {
                dateLabelForKey[key] = [];
              }
              dateLabelForKey[key].push(modifiedDate);
            }
            if (!allOtherMeasurements[key]) {
              allOtherMeasurements[key] = [];
            }
            allOtherMeasurements[key].push(
              item.measurements?.mainMeasurements[key].value
            );
          }
        });
      }
    });
    console.log("dateLabels", dateLabelForKey);
    console.log("allOtherMeasurements", allOtherMeasurements);

    Object.keys(allOtherMeasurements).forEach((key) => {
      allChartData[key] = {
        labels: dateLabelForKey[key],
        datasets: [
          {
            label: key,
            data: allOtherMeasurements[key],
            fill: false,
            borderColor: "#0E9CFF",
            tension: 0,
            backgroundColor: "#ffffff",
          },
        ],
      };
    });
    console.log("allChartData", allChartData);
    setAllMeasurementsChartData(allChartData);
    let validKey = getKeyWithFirstMeasurementWithData(allChartData);
    setValidKey(validKey);
  }, [data]);

  // Function to generate random colors
  function getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  const getKeyWithFirstMeasurementWithData = (allMeasurementsChartData) => {
    let key = "";
    Object.keys(allMeasurementsChartData).forEach((item) => {
      if (allMeasurementsChartData[item]?.labels?.length && key === "") {
        key = item;
      }
    });
    return key;
  };

  if (validKey === "") {
    return <div></div>;
  }

  return (
    <div>
      <Line
        width="350"
        key={validKey}
        data={allMeasurementsChartData[validKey]}
        options={{
          scales: {
            y: {
              // title: {
              //   display: true,
              //   text: measurementTextMap[validKey],
              // },
              ticks: {
                display: false, // Hide y-axis values
              },
              grid: {
                display: false, // Hide vertical grid lines
              },
            },
            x: {
              title: {
                display: true,
              },
              ticks: {
                display: false, // Hide y-axis values
              },
              grid: {
                display: false, // Hide vertical grid lines
              },
            },
          },
          plugins: {
            legend: {
              display: false, // Hide the legend (dataset visibility toggle)
            },
          },
          elements: {
            point: {
              radius: 5,
            },
          },
          borderWidth: 2,
        }}
      />
    </div>
  );
};

export default FirstValidChart;

const measurementTextMap = {
  hips: "Hips",
  neck: "Neck",
  chest: "Chest",
  waist: "Waist",
  leftCalf: "Left Calf",
  shoulder: "Shoulder",
  leftBicep: "Left Bicep",
  leftThigh: "Left Thigh",
  rightCalf: "Right Calf",
  rightBicep: "Right Bicep",
  rightThigh: "Right Thigh",
  leftForearm: "Left Forearm",
  rightForearm: "Right Forearm",
  fat: "Body Fat Percentage", // Assuming you want to include this measurement
};

export const isThereAnyValidMeasurement = (data) => {
  let allChartData = {};
  data?.sort((a, b) => new Date(a.dateRecorded) - new Date(b.dateRecorded));
  let dateLabelForKey = {};
  let allOtherMeasurements = {};
  data?.forEach((item) => {
    if (item?.measurements) {
      Object.keys(item.measurements).forEach((key) => {
        if (item?.measurements[key]?.value) {
          if (item?.dateRecorded) {
            let modifiedDate = moment(item.dateRecorded).format("Do MMM' YY");

            if (!dateLabelForKey[key]) {
              dateLabelForKey[key] = [];
            }
            dateLabelForKey[key].push(modifiedDate);
          }
          if (!allOtherMeasurements[key]) {
            allOtherMeasurements[key] = [];
          }
          allOtherMeasurements[key].push(item.measurements[key].value);
        }
      });
    }
    if (item?.measurements?.mainMeasurements) {
      Object.keys(item?.measurements?.mainMeasurements).forEach((key) => {
        if (item?.measurements?.mainMeasurements[key]?.value) {
          if (item?.dateRecorded) {
            let modifiedDate = moment(item.dateRecorded).format("Do MM' YY");

            if (!dateLabelForKey[key]) {
              dateLabelForKey[key] = [];
            }
            dateLabelForKey[key].push(modifiedDate);
          }
          if (!allOtherMeasurements[key]) {
            allOtherMeasurements[key] = [];
          }
          allOtherMeasurements[key].push(
            item.measurements?.mainMeasurements[key].value
          );
        }
      });
    }
  });
  console.log("dateLabels", dateLabelForKey);
  console.log("allOtherMeasurements", allOtherMeasurements);

  Object.keys(allOtherMeasurements).forEach((key) => {
    allChartData[key] = {
      labels: dateLabelForKey[key],
      datasets: [
        {
          label: key,
          data: allOtherMeasurements[key],
          fill: false,
          tension: 0.1,
        },
      ],
    };
  });
  console.log("allChartData", allChartData);
  let validKey = getKeyWithFirstMeasurementWithData(allChartData);
  return validKey !== "";
};

const getKeyWithFirstMeasurementWithData = (allMeasurementsChartData) => {
  let key = "";
  Object.keys(allMeasurementsChartData).forEach((item) => {
    if (allMeasurementsChartData[item]?.labels?.length) {
      key = item;
    }
  });
  return key;
};
