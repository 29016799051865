import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import "./style.css";

function TextArea({ value = "", onChange, id, numRows, ...props }) {
  const [textareaValue, setTextareaValue] = useState(value);
  const textareaId = id || uuidv4();

  useEffect(() => {
    // if value changes and it is different from the current textareaValue
    if (value !== textareaValue) {
      setTextareaValue(value);
    }
  }, [value]);

  function handleTextareaChange(event) {
    onChange && onChange(event);
    setTextareaValue(event.target.value);
  }

  return (
    <textarea
      className="textarea-generic"
      id={textareaId}
      value={textareaValue}
      onChange={handleTextareaChange}
      rows={numRows}
      {...props}
    />
  );
}

export default TextArea;
