import React, { createContext, useEffect, useState } from "react";
import Sidebar from "../SideBar";
import TopBar from "../TopBar";
import Copilot from "../copilot/copilot";
import { useLocation } from "react-router-dom";

export default function SpurfitPublicLayout({ children }) {
  const [copilotOpen, setCopilotOpen] = useState(false);
  const [route, setRoute] = useState("/home");

  const location = useLocation();
  useEffect(() => {
    setRoute(window.location.pathname);
  }, [location.pathname]);

  return (
    <div
      style={{
        minHeight: "100vh",
        width: "100vw",
        backgroundColor: "#F8F8F8",
        marginTop: -12,
      }}
    >
      <Sidebar isPublic={true} />
      {children}
    </div>
  );
}
