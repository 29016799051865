// export const phoneExtensions = [
//     { country: "Argentina", extension: "+54" },
//     { country: "Australia", extension: "+61" },
//     { country: "Austria", extension: "+43" },
//     { country: "Belgium", extension: "+32" },
//     { country: "Bolivia", extension: "+591" },
//     { country: "Brazil", extension: "+55" },
//     { country: "Bulgaria", extension: "+359" },
//     { country: "Canada", extension: "+1" },
//     { country: "Chile", extension: "+56" },
//     { country: "Colombia", extension: "+57" },
//     { country: "Costa Rica", extension: "+506" },
//     { country: "Croatia", extension: "+385" },
//     { country: "Cyprus", extension: "+357" },
//     { country: "Czech Republic", extension: "+420" },
//     { country: "Denmark", extension: "+45" },
//     { country: "Estonia", extension: "+372" },
//     { country: "Finland", extension: "+358" },
//     { country: "France", extension: "+33" },
//     { country: "Germany", extension: "+49" },
//     { country: "Greece", extension: "+30" },
//     { country: "Hong Kong SAR China", extension: "+852" },
//     { country: "Hungary", extension: "+36" },
//     { country: "Iceland", extension: "+354" },
//     { country: "India", extension: "+91" },
//     { country: "Indonesia", extension: "+62" },
//     { country: "Ireland", extension: "+353" },
//     { country: "Israel", extension: "+972" },
//     { country: "Italy", extension: "+39" },
//     { country: "Japan", extension: "+81" },
//     { country: "Latvia", extension: "+371" },
//     { country: "Liechtenstein", extension: "+423" },
//     { country: "Lithuania", extension: "+370" },
//     { country: "Luxembourg", extension: "+352" },
//     { country: "Malta", extension: "+356" },
//     { country: "Mexico ", extension: "+52" },
//     { country: "Netherlands", extension: "+31" },
//     { country: "New Zealand", extension: "+64" },
//     { country: "Norway", extension: "+47" },
//     { country: "Paraguay", extension: "+595" },
//     { country: "Peru", extension: "+51" },
//     { country: "Philippines", extension: "+63" },
//     { country: "Poland", extension: "+48" },
//     { country: "Portugal", extension: "+351" },
//     { country: "Romania", extension: "+40" },
//     { country: "Singapore", extension: "+65" },
//     { country: "Slovakia", extension: "+421" },
//     { country: "Slovenia", extension: "+386" },
//     { country: "Spain", extension: "+34" },
//     { country: "Sweden", extension: "+46" },
//     { country: "Switzerland", extension: "+41" },
//     { country: "Thailand", extension: "+66" },
//     { country: "Trinidad & Tobago", extension: "+1-868" },
//     { country: "United Arab Emirates", extension: "+971" },
//     { country: "United Kingdom", extension: "+44" },
//     { country: "United States", extension: "+1" },
//     { country: "Uruguay", extension: "+598" },
//     { country: "South Africa", extension: "+27" },
//   ];

export const phoneExtensions = [
  { country: "Argentina", extension: "+54", countryCode: "AR" },
  { country: "Australia", extension: "+61", countryCode: "AU" },
  { country: "Austria", extension: "+43", countryCode: "AT" },
  { country: "Belgium", extension: "+32", countryCode: "BE" },
  { country: "Bolivia", extension: "+591", countryCode: "BO" },
  { country: "Brazil", extension: "+55", countryCode: "BR" },
  { country: "Bulgaria", extension: "+359", countryCode: "BG" },
  { country: "Chile", extension: "+56", countryCode: "CL" },
  { country: "Colombia", extension: "+57", countryCode: "CO" },
  { country: "Costa Rica", extension: "+506", countryCode: "CR" },
  { country: "Croatia", extension: "+385", countryCode: "HR" },
  { country: "Cyprus", extension: "+357", countryCode: "CY" },
  { country: "Czech Republic", extension: "+420", countryCode: "CZ" },
  { country: "Denmark", extension: "+45", countryCode: "DK" },
  { country: "Estonia", extension: "+372", countryCode: "EE" },
  { country: "Finland", extension: "+358", countryCode: "FI" },
  { country: "France", extension: "+33", countryCode: "FR" },
  { country: "Germany", extension: "+49", countryCode: "DE" },
  { country: "Greece", extension: "+30", countryCode: "GR" },
  { country: "Hong Kong SAR China", extension: "+852", countryCode: "HK" },
  { country: "Hungary", extension: "+36", countryCode: "HU" },
  { country: "Iceland", extension: "+354", countryCode: "IS" },
  { country: "India", extension: "+91", countryCode: "IN" },
  { country: "Indonesia", extension: "+62", countryCode: "ID" },
  { country: "Ireland", extension: "+353", countryCode: "IE" },
  { country: "Israel", extension: "+972", countryCode: "IL" },
  { country: "Italy", extension: "+39", countryCode: "IT" },
  { country: "Japan", extension: "+81", countryCode: "JP" },
  { country: "Latvia", extension: "+371", countryCode: "LV" },
  { country: "Liechtenstein", extension: "+423", countryCode: "LI" },
  { country: "Lithuania", extension: "+370", countryCode: "LT" },
  { country: "Luxembourg", extension: "+352", countryCode: "LU" },
  { country: "Malta", extension: "+356", countryCode: "MT" },
  { country: "Mexico ", extension: "+52", countryCode: "MX" },
  { country: "Netherlands", extension: "+31", countryCode: "NL" },
  { country: "New Zealand", extension: "+64", countryCode: "NZ" },
  { country: "Norway", extension: "+47", countryCode: "NO" },
  { country: "Paraguay", extension: "+595", countryCode: "PY" },
  { country: "Peru", extension: "+51", countryCode: "PE" },
  { country: "Philippines", extension: "+63", countryCode: "PH" },
  { country: "Poland", extension: "+48", countryCode: "PL" },
  { country: "Portugal", extension: "+351", countryCode: "PT" },
  { country: "Romania", extension: "+40", countryCode: "RO" },
  { country: "Singapore", extension: "+65", countryCode: "SG" },
  { country: "Slovakia", extension: "+421", countryCode: "SK" },
  { country: "Slovenia", extension: "+386", countryCode: "SI" },
  { country: "Spain", extension: "+34", countryCode: "ES" },
  { country: "Sweden", extension: "+46", countryCode: "SE" },
  { country: "Switzerland", extension: "+41", countryCode: "CH" },
  { country: "Thailand", extension: "+66", countryCode: "TH" },
  { country: "Trinidad & Tobago", extension: "+1-868", countryCode: "TT" },
  { country: "United Arab Emirates", extension: "+971", countryCode: "AE" },
  { country: "United Kingdom", extension: "+44", countryCode: "GB" },
  { country: "United States", extension: "+1", countryCode: "US" },
  { country: "Uruguay", extension: "+598", countryCode: "UY" },
  { country: "South Africa", extension: "+27", countryCode: "ZA" },
];
