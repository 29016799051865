import { store } from "@/redux/store";
import axios from "axios";
import moment from "moment";
const genericErrMsg = Error("Something went wrong!");

export const getClientProfileSummary = async (userUUID) => {
  try {
    const res = await axios.post(
      `${
        store.getState().auth.apiURL
      }CF/api/clientProfileSummary?userUUID=${userUUID}`
    );

    const { data, status } = res;

    if (status === 200 && data) {
      return res.data;
    }

    throw Error(res?.statusText) || genericErrMsg;
  } catch (err) {
    throw new Error(err);
  }
};

export const getMultiClientProfileSummary = async (userUUIDs) => {
  try {
    const res = await axios({
      method: "post",
      url: `${
        store.getState().auth.apiURL
      }CF/api/clientProfileSummaryPageMultiple`,
      data: {
        userUUIDs,
      },
    });

    const { data, status } = res;

    if (status === 200 && data) {
      return res.data.result;
    }

    throw Error(res?.statusText) || genericErrMsg;
  } catch (err) {
    throw new Error(err);
  }
};

export const getMultiClientProfile = async (userUUIDs) => {
  try {
    const res = await axios({
      method: "post",
      url: `${store.getState().auth.apiURL}CF/api/clientProfileMultiple`,
      data: {
        userUUIDs,
      },
    });

    const { data, status } = res;

    if (status === 200 && data) {
      return res.data.result;
    }

    throw Error(res?.statusText) || genericErrMsg;
  } catch (err) {
    throw new Error(err);
  }
};

export const getClientProfileByPage = async (calStartDate, userUUID) => {
  if (!userUUID) return {};

  try {
    const res = await axios({
      method: "post",
      url: `${store.getState().auth.apiURL}CF/api/clientProfileByPage`,
      data: {
        date: moment(calStartDate).format("YYYY-MM-DD"),
      },
      params: {
        userUUID: userUUID,
      },
    });

    const { data, status } = res;

    if (status === 200 && data) {
      return res.data?.result;
    }

    throw Error(res?.statusText) || genericErrMsg;
  } catch (err) {
    return {};
  }
};

export const getTraineeDetails = async (userUUID) => {
  try {
    const res = await axios.post(
      `${store.getState().auth.apiURL}CF/api/clientProfile?userUUID=${userUUID}`
    );

    const { data, status } = res;

    if (status === 200 && data && data.result) {
      return res.data.result;
    }

    throw Error(res?.statusText) || genericErrMsg;
  } catch (err) {
    throw new Error(err);
  }
};

export const getClientProfileByDateRange = async (
  userUUID,
  startDate,
  endDate
) => {
  try {
    const res = await axios({
      method: "post",
      url: `${store.getState().auth.apiURL}CF/api/clientProfileByDateRange`,
      data: {
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
      },
      params: {
        userUUID: userUUID,
      },
    });
    return res?.data?.result;
  } catch (error) {
    return {};
  }
};

export const getUserWorkOutStats = async (id) => {
  try {
    const url = `${
      store.getState().auth.apiURL
    }CF/api/CoachWeb/Get/getAtheleteGlobalStats?atheleteID=${id}`;
    const res = await axios.get(url);

    const { data, status } = res;

    if (status === 200 && data && data.result) {
      return res.data.result;
    }
    throw Error(res?.statusText) || genericErrMsg;
  } catch (err) {
    throw new Error(err);
  }
};

export const getNotes = async ({ userUUID, trainerUUID }) => {
  try {
    const res = await axios.get(
      `${
        store.getState().auth.apiURL
      }api/getnotes?userUUID=${userUUID}&&trainerUUID=${trainerUUID}`
    );

    const { data, status } = res;

    if (status === 200 && data && data.notes) {
      return res.data.notes;
    }

    throw Error(res?.statusText) || genericErrMsg;
  } catch (err) {
    throw new Error(err);
  }
};

export const addNotes = async ({ userUUID, trainerUUID, note = "" }) => {
  const date = moment(new Date())
    .set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    })
    .format("YYYY-MM-DD");
  try {
    const res = await axios.post(`${store.getState().auth.apiURL}api/addnote`, {
      userUUID,
      trainerUUID,
      note,
      date,
    });

    const { data, status } = res;

    if (status === 200 && data && data.status === "Success") {
      return true;
    }

    throw Error(res?.statusText) || genericErrMsg;
  } catch (err) {
    throw new Error(err);
  }
};

export const updateNotes = async ({ PrimaryKey, note = "" }) => {
  try {
    const res = await axios.post(
      `${store.getState().auth.apiURL}api/updatenote`,
      {
        primaryKey: PrimaryKey,
        note,
      }
    );

    const { data, status } = res;

    if (status === 200 && data && data.status === "Success") {
      return true;
    }

    throw Error(res?.statusText) || genericErrMsg;
  } catch (err) {
    throw new Error(err);
  }
};

export const deleteNotes = async ({ PrimaryKey }) => {
  try {
    const res = await axios.post(
      `${store.getState().auth.apiURL}api/deletenote`,
      {
        primaryKey: PrimaryKey,
      }
    );

    const { data, status } = res;

    if (status === 200 && data && data.status === "Success") {
      return true;
    }

    throw Error(res?.statusText) || genericErrMsg;
  } catch (err) {
    throw new Error(err);
  }
};

// getTodos,
//   addTodo,
//   updateTodo,
//   deleteTodo,

export const getTodos = async ({ trainerUUID }) => {
  try {
    const res = await axios({
      method: "get",
      url: `${store.getState().auth.apiURL}CF/api/TrainerTODO/getTrainerTODOs`,
      params: {
        coachID: trainerUUID,
      },
    });

    const { data, status } = res;

    if (status === 200 && data && data.result) {
      return res.data.result;
    }

    throw Error(res?.statusText) || genericErrMsg;
  } catch (err) {
    throw new Error(err);
  }
};

export const addTodo = async ({ trainerUUID, todo = "" }) => {
  try {
    const res = await axios({
      method: "post",
      url: `${
        store.getState().auth.apiURL
      }CF/api/TrainerTODO/insertTrainerTODO`,
      data: {
        coachID: trainerUUID,
        todo,
      },
    });

    const { data, status } = res;

    if (status === 200 && data) {
      return true;
    }

    throw Error(res?.statusText) || genericErrMsg;
  } catch (err) {
    throw new Error(err);
  }
};

export const updateTodo = async ({ todoID, todo, ...props }) => {
  try {
    const res = await axios({
      method: "post",
      url: `${
        store.getState().auth.apiURL
      }CF/api/TrainerTODO/updateTrainerTODO`,
      data: {
        todoID,
        todo,
        ...props,
      },
    });

    const { data, status } = res;

    if (status === 200 && data) {
      return true;
    }

    throw Error(res?.statusText) || genericErrMsg;
  } catch (err) {
    throw new Error(err);
  }
};

export const deleteTodo = async ({ todoId }) => {
  try {
    const res = await axios({
      method: "post",
      url: `${
        store.getState().auth.apiURL
      }CF/api/TrainerTODO/deleteTrainerTODO`,
      data: {
        todoID: todoId,
      },
    });

    const { data, status } = res;

    if (status === 200 && data) {
      return true;
    }

    throw Error(res?.statusText) || genericErrMsg;
  } catch (err) {
    throw new Error(err);
  }
};
