import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CloseIcon from "@mui/icons-material/Close";
import RadioButtonCheckedRoundedIcon from "@mui/icons-material/RadioButtonCheckedRounded";
import RadioButtonUncheckedRoundedIcon from "@mui/icons-material/RadioButtonUncheckedRounded";
import axios from "axios";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CustomAlertContext } from "../../App";
import SkippingRopes from "../../assets/skippingRopes.svg";
import DialogBox from "../Dialog/Dialog";

const NewWorkoutDialog = ({
  newWorkoutDialog,
  setnewWorkoutDialog,
  data,
  isCalendar,
  setPayloadData,
  setCreatOutBuilder,
  trainerUUID,
  userUUID,
  moduleDate,
}) => {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const [payload, setPayload] = useState(data);
  const [isApiRunning, setIsApiRunning] = useState(false);
  const { displayAlert } = useContext(CustomAlertContext);

  useEffect(() => setPayload(payload), [data]);
  const history = useHistory();

  const getmoduleDuration = (item) => {
    if (isEmpty(item)) {
      return 0;
    }
    const array = item?.map((item) => (item?.duration ? item?.duration : 0));
    const count = array?.reduce((total, x) => parseInt(total) + parseInt(x));
    return count / 60;
  };

  const handleClick = async () => {
    let currentDate = moment(new Date())
      .set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
      .format("YYYY-MM-DD");
    if (isCalendar) {
      const data = {
        trainerUUID,
        userUUID,
        calories: payload?.calories || payload?.Calories || 60,
        moduleDate: moduleDate || currentDate,
        moduleDetails: payload?.moduleDetails,
        moduleDuration: getmoduleDuration(payload?.moduleDetails),
        name: payload?.name || payload?.Name,
        tag: payload?.tag || payload?.Tag,
      };
      setPayloadData(data);
      setCreatOutBuilder(true);
      setnewWorkoutDialog(!newWorkoutDialog);
      return;
    }

    let dateVal = data?.moduleDate || data?.ModuleDate;

    const attach = async () => {
      await axios({
        method: "post",
        url: `${APIURL}api/modules`,
        data: {
          calories: payload?.calories || payload?.Calories || 60,
          modulesUUID: payload?.moduleDetails || payload?.ModuleDetails,
          moduleDuration: getmoduleDuration(payload?.moduleDetails),
          name: payload?.name || payload?.Name,
          tag: payload?.tag || payload?.Tag,
          trainerUUID: payload?.trainerUUID || payload?.TrainerUUID,
          userUUID: payload?.userUUID,
          primaryKey: payload?.primaryKey || payload?.PrimaryKey,
          moduleDate: dateVal,
        },
      })
        .then((res) => {
          history.push(`/client/${data?.userUUID}/`);
          displayAlert("Sent");
        })
        .catch((err) => {
          displayAlert("Network error, please try again");
        })
        .finally(() => {
          setIsApiRunning(false);
        });
    };
    setIsApiRunning(true);

    await axios({
      method: "post",
      url: `${APIURL}api/modularworkouts`,
      data: {
        trainerUUID: payload?.trainerUUID,
        userUUID: payload?.userUUID,
        calories: payload?.calories || payload?.Calories || 60,
        moduleDate: moment(new Date())
          .set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          })
          .format("YYYY-MM-DD"),
        moduleDetails: payload?.moduleDetails || payload?.ModuleDetails,
        moduleDuration: getmoduleDuration(payload?.moduleDetails),
        name: payload?.name || payload?.Name,
        tag: payload?.tag || payload?.Tag,
      },
    })
      .then(async (res) => {
        const { data, status } = res;
        if (payload?.userUUID || userUUID) {
          await attach();
        } else {
          history.goBack();
        }
      })
      .catch((err) => {
        console.log("err", err);
        displayAlert("Network error, please try again");
      })
      .finally(() => {
        setIsApiRunning(false);
      });
  };

  return (
    <DialogBox
      fullWidth={false}
      open={newWorkoutDialog}
      onClose={() => setnewWorkoutDialog(!newWorkoutDialog)}
    >
      <div className="p-12">
        <div className="absolute top-3 right-5">
          <CloseIcon
            className="cursor-pointer"
            fontSize={"large"}
            onClick={() => {
              setnewWorkoutDialog(!newWorkoutDialog);
            }}
          />
        </div>
        <div className="flex flex-col items-center justify-center">
          <div>
            <img className="w-[50px] h-auto" src={SkippingRopes} alt="" />
          </div>
          <div className="text-primaryBlue text-[26px] mb-7 text-center mt-3">
            Create new workout
          </div>
          <form>
            <div className="flex justify-around">
              <div>
                <input
                  className="outline-none w-[300px] rounded-xl border-primaryBlue border-2 p-2 px-3 font-medium text-primaryBlue placeholder:font-normal"
                  type="text"
                  value={payload?.name}
                  placeholder="Workout Name"
                  onChange={(e) => {
                    setPayload({ ...payload, name: e.target.value });
                  }}
                />
                <p className="text-[12px] italic text-primaryBlue px-3 font-normal">
                  E.g. Workout 1 Abs
                </p>
              </div>
              <div className="text-sm px-8 text-primaryBlue ">
                <div className="mb-2">Workout Type</div>
                <div className="flex gap-8">
                  <div
                    className="gap-1 cursor-pointer flex align-middle"
                    onClick={() => setPayload({ ...payload, tag: "Regular" })}
                  >
                    {payload?.tag == "Regular" ? (
                      <RadioButtonCheckedRoundedIcon />
                    ) : (
                      <RadioButtonUncheckedRoundedIcon />
                    )}
                    Regular
                  </div>
                  <div
                    className="gap-1 cursor-pointer flex items-center justify-center align-middle"
                    onClick={() => setPayload({ ...payload, tag: "Circuit" })}
                  >
                    {payload?.tag == "Circuit" ? (
                      <RadioButtonCheckedRoundedIcon />
                    ) : (
                      <RadioButtonUncheckedRoundedIcon />
                    )}
                    Circuit
                  </div>
                </div>
              </div>
            </div>
          </form>
          <button
            className="mt-8 gap-2 bg-primaryBlue text-white-pure px-6 py-2 rounded-xl"
            onClick={() => {
              if (isEmpty(payload?.tag) || isEmpty(payload?.name)) return;
              handleClick();
            }}
          >
            <CheckRoundedIcon /> Confirm
          </button>
        </div>
      </div>
    </DialogBox>
  );
};

export default NewWorkoutDialog;
