import React, { useState } from "react";
import { Box, Modal } from "@mui/material";
import SpinnerComponent from "../../components/layout/spinner";
import Button from "../../components/Button/button";
import { InputWithLabel } from "../../pages/profile/InputWithLabel";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 820,
  height: "60%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  display: "grid",
  borderRadius: 4,
};

const buttonStyle = {
  width: "fit-content",
  height: "40px",
  fontSize: "14px",
  background: "#071148",
  borderRadius: "8px",
  color: "#fff",
  textTransform: "none",
};

const MacrosModal = (props) => {
  const { openModal = false, handleClose = () => {}, defaultTab } = props;
  const [btnLoader, setBtnLoader] = useState(false);
  const [specifyType, setSpecifyType] = useState("");
  const [autoCalc, setAutoCalc] = useState("Carbs");
  const [calories, setCalories] = useState("");
  const [protein, setProtien] = useState("");
  const [fat, setFat] = useState("");
  const [carbs, setCarbs] = useState("");
  const [mealType, setMealType] = useState({});
  const [cals, setCals] = useState("");

  const mealTypes = [
    {
      id: 1,
      label: "On waking up",
    },
    {
      id: 2,
      label: "Early morning",
    },
    {
      id: 3,
      label: "Breakfast",
    },
    {
      id: 4,
      label: "After Breakfast",
    },
    {
      id: 5,
      label: "Mid-morning",
    },
    {
      id: 6,
      label: "Morning snack",
    },
    {
      id: 7,
      label: "Lunch",
    },
    {
      id: 8,
      label: "Evening snack",
    },
    {
      id: 9,
      label: "Dinner",
    },
    {
      id: 10,
      label: "After Dinner",
    },
    {
      id: 11,
      label: "Bed time",
    },
    {
      id: 12,
      label: "Before workouts",
    },
    {
      id: 13,
      label: "During workouts",
    },
    {
      id: 14,
      label: "After workouts",
    },
    {
      id: 15,
      label: "Non-training day",
    },
  ];

  // display modal header
  const renderheader = () => {
    return (
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center">
          <CloseIcon
            className="cursor-pointer"
            onClick={() => {
              handleClose(false);
            }}
          />
          <span class="text-font16 font-bold ml-[10px]">{"Edit Macros"}</span>
        </div>
        <Button
          style={{ ...buttonStyle }}
          onClick={
            btnLoader
              ? null
              : (e) => {
                  e.stopPropagation();
                  console.log("add item");
                }
          }
        >
          {btnLoader ? (
            <SpinnerComponent />
          ) : (
            <>
              <span class="text-font14">{"Done"}</span>
            </>
          )}
        </Button>
      </div>
    );
  };

  const renderMealPlanMacros = () => {
    return (
      <div className="flex flex-col overflow-hidden">
        {renderheader()}
        <div className="h-[1px] w-full bg-[#d9d9d9] my-[10px]" />
        <form className="mt-[10px] flex flex-col overflow-scroll">
          <div className="flex  flex-row items-center">
            <div className="flex flex-col flex-1">
              <div
                style={{
                  fontSize: 14,
                }}
                className="text-sm font-bold text-gray-700 tracking-wide"
              >
                Specify in
              </div>

              <select
                style={{
                  border: "1px solid #071148",
                  borderRadius: 5,
                  minWidth: 50,
                  padding: 7,
                }}
                value={specifyType}
                onChange={(event) => setSpecifyType(event.target.value)}
              >
                <option value="Select Option">Select Option</option>
                <option value="Protiens">Protiens</option>
                <option value="Grams">Grams</option>
              </select>
            </div>
            <div className="ml-2 flex-1">
              <div
                style={{
                  fontSize: 14,
                }}
                className="text-sm font-bold text-gray-700 tracking-wide"
              >
                Calories
              </div>
              <InputWithLabel
                style={{
                  width: "100%",
                }}
                state={calories}
                setState={setCalories}
                placeholder="Calories"
              />
            </div>
            <div className="ml-2 flex flex-col flex-1">
              <div
                style={{
                  fontSize: 14,
                }}
                className="text-sm font-bold text-gray-700 tracking-wide"
              >
                Auto Calculate
              </div>

              <select
                style={{
                  border: "1px solid #071148",
                  borderRadius: 5,
                  minWidth: 50,
                  padding: 7,
                }}
                value={autoCalc}
                onChange={(event) => setAutoCalc(event.target.value)}
              >
                <option value="Select Option">Select Option</option>
                <option value="Protiens">Protiens</option>
                <option value="Fat">Fat</option>
                <option value="Carbs">Carbs</option>
              </select>
            </div>
          </div>

          <div className="flex justify-between items-center">
            <div className="mt-5 flex flex-col">
              <div className="flex justify-between items-center">
                <div
                  style={{
                    fontSize: 14,
                  }}
                  className="text-sm font-bold text-gray-700 tracking-wide"
                >
                  Protein
                </div>
              </div>
              <InputWithLabel
                disabled={autoCalc === "Protiens"}
                state={protein}
                setState={setProtien}
                placeholder="Protien"
              />
            </div>
            <div className="mt-5 flex flex-col mx-2">
              <div className="flex justify-between items-center">
                <div
                  style={{
                    fontSize: 14,
                  }}
                  className="text-sm font-bold text-gray-700 tracking-wide"
                >
                  Fat
                </div>
              </div>
              <InputWithLabel
                disabled={autoCalc === "Fat"}
                state={fat}
                setState={setFat}
                placeholder="Fat"
              />
            </div>
            <div className="mt-5 flex flex-col">
              <div className="flex justify-between items-center">
                <div
                  style={{
                    fontSize: 14,
                  }}
                  className="text-sm font-bold text-gray-700 tracking-wide"
                >
                  Carbs
                </div>
              </div>
              <InputWithLabel
                disabled={autoCalc === "Carbs"}
                state={carbs}
                setState={setCarbs}
                placeholder="Carbs"
              />
            </div>
          </div>

          <div className="h-[1px] w-full bg-[#d9d9d9] my-[10px]" />

          <div className="mb-[10px]">
            <span class="text-font16 font-semibold">Meal Categories</span>
          </div>

          <div className="flex flex-col">
            <div className="flex-row flex items-center">
              <select
                style={{
                  border: "1px solid #071148",
                  borderRadius: 5,
                  minWidth: 50,
                  padding: 7,
                }}
                value={mealType}
                onChange={(event) => setMealType(event.target.value)}
              >
                <option value="Select Option">Select Option</option>
                {mealTypes?.map((item, index) => {
                  return <option value={item.label}>{item.label}</option>;
                })}
              </select>

              <div className="mx-[10px]">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["MobileTimePicker"]}>
                    <DemoItem label="">
                      <MobileTimePicker
                        defaultValue={dayjs("2022-04-17T15:30")}
                      />
                    </DemoItem>
                  </DemoContainer>
                </LocalizationProvider>
              </div>

              <div>
                <InputWithLabel
                  style={{
                    width: "100%",
                  }}
                  state={cals}
                  setState={setCals}
                  placeholder="Reference Name"
                />
              </div>

              <div
                className="cursor-pointer"
                onClick={() => {
                  console.log("delete item");
                }}
              >
                <DeleteOutlineIcon />
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  };

  return (
    <Modal
      open={openModal}
      onClose={() => {
        handleClose(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>{renderMealPlanMacros()}</Box>
    </Modal>
  );
};

export default MacrosModal;
