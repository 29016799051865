import AddRoundedIcon from "@mui/icons-material/AddRounded";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import NItem from "../NItem";
import MealPlanList from "./MealPlanList";
import MealScheduleList from "./MealScheduleList";
import { useSelector } from "react-redux";
import FolderListForMealPlan from "../FolderListForMealPlan";

const NList = (props) => {
  const {
    title = "",
    listData = [],
    defaultNItem = {},
    setdefaultNItem = () => {},
    defaultTab,
    handleAddModal = () => {},
    setUnsavedChanges = () => {},
    unsavedChanges,
    getMealScheduleByID,
    searchLoading,
    onTypeChange,
    selectedType,
    subbordinateCoachMealSchedules,
  } = props;

  const [displaypopup, setDisplaypopup] = useState(false);
  const [displaySearch, setDisplaySearch] = useState(false);
  const [searchedTerm, setSearchedTerm] = useState("");
  const [tempId, setTempId] = useState();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const gymAffiliation = useSelector(
    (state) => state.auth?.data?.gymAffliation
  );
  let isLivezy = gymAffiliation?.toLowerCase() === "livezy";

  useEffect(() => {
    if ((defaultTab == 2 || defaultTab == 1) && selectedType === "trainer") {
      props?.paginationQuery(1, searchedTerm);
      return;
    }
    const delayDebounceFn = setTimeout(() => {
      console.log("searchedTerm", searchedTerm);
      if (defaultTab <= 2) {
        props?.paginationQuery(1, searchedTerm);
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchedTerm]);

  const { pageNo, setPageNo } = props;
  // useEffect(() => {
  //   if (!listData[0]?.id) {
  //     setdefaultNItem(listData[0]);
  //   }
  // }, [listData]);

  const handleItemClick = (itm, id) => {
    getMealScheduleByID(itm, id);
    setdefaultNItem(itm);
    setUnsavedChanges(false);
  };

  if (defaultTab === 4) {
    return <MealScheduleList {...props} />;
  }

  if (defaultTab === 3) {
    return <MealPlanList {...props} />
  }

  return (
    <>
      <div className="relative h-full">
        <div
          style={{
            borderBottom: " 1px solid #ebebeb",
            paddingBottom: "8px",
            marginBottom: "8px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span className="text-font16 font-semibold">{title}</span>
            {defaultTab === 1 && !isLivezy && (
              <Select
                value={selectedType}
                onChange={(e) => {
                  onTypeChange(e.target.value);
                }}
                variant="standard"
                disableUnderline
                SelectDisplayProps={{
                  style: {
                    backgroundColor: "#fff",
                    fontFamily: "DM Sans",
                    fontSize: "16px",
                  },
                }}
              >
                <MenuItem
                  sx={{
                    fontFamily: "DM sans",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "20px",
                  }}
                  value={"global"}
                >
                  Global
                </MenuItem>
                <MenuItem
                  sx={{
                    fontFamily: "DM sans",
                    fontSize: "13px",
                    fontWeight: "400",
                    lineHeight: "20px",
                  }}
                  value={"trainer"}
                >
                  My Foods
                </MenuItem>
              </Select>
            )}
            {defaultTab === 2 && (
              <Select
                value={selectedType}
                onChange={(e) => {
                  onTypeChange(e.target.value);
                }}
                variant="standard"
                disableUnderline
                SelectDisplayProps={{
                  style: {
                    backgroundColor: "#fff",
                    fontFamily: "DM Sans",
                    fontSize: "16px",
                  },
                }}
              >
                <MenuItem
                  sx={{
                    fontFamily: "DM sans",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "20px",
                  }}
                  value={"global"}
                >
                  Global
                </MenuItem>
                <MenuItem
                  sx={{
                    fontFamily: "DM sans",
                    fontSize: "13px",
                    fontWeight: "400",
                    lineHeight: "20px",
                  }}
                  value={"trainer"}
                >
                  My Recipes
                </MenuItem>
              </Select>
            )}
            {!displaySearch ? (
              <SearchRoundedIcon
                className="cursor-pointer"
                onClick={() => {
                  setDisplaySearch(!displaySearch);
                }}
              />
            ) : (
              <CloseRoundedIcon
                className="cursor-pointer"
                onClick={() => {
                  setDisplaySearch(!displaySearch);
                  setSearchedTerm("");
                  props?.cancelSearch();
                }}
              />
            )}
          </div>

          {displaySearch && (
            <input
              type="text"
              placeholder="Search"
              style={{
                marginTop: "20px",
                width: "100%",
                height: "40px",
                borderRadius: "20px",
                paddingLeft: "20px",
                paddingRight: "20px",
                outline: "none",
                border: "1px solid #EDEBE9",
              }}
              autoFocus
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (defaultTab > 2) {
                    props?.paginationQuery(searchedTerm);
                  } else {
                    props?.paginationQuery(1, searchedTerm);
                  }
                }
              }}
              onChange={(e) => {
                if (pageNo > 1) {
                  setPageNo(1);
                }
                let value = e.target.value;
                if (
                  defaultTab > 2 ||
                  (defaultTab == 2 && selectedType == "trainer")
                ) {
                  props?.paginationQuery(value);
                }
                setSearchedTerm(value);
              }}
            />
          )}
          {searchLoading && <LinearProgress />}
        </div>

        <div
          className="relative h-full overflow-auto flex flex-col"
          style={{
            paddingBottom: displaySearch ? "150px" : "100px",
            gap: "8px",
          }}
        >
          {defaultTab == 2 &&
            selectedType == "trainer" &&
            !searchLoading &&
            listData.length == 0 && (
              <div className="font-DMSans text-font14 my-auto">
                Use Copilot to generate recipes
              </div>
            )}
          {listData?.map((itf, iif) => {
            console.log("what is list data", itf);
            return (
              <NItem
                itf={itf}
                defaultNItem={defaultNItem}
                defaultTab={defaultTab}
                setdefaultNItem={(itm) => {
                  if (defaultTab == 4) {
                    if (unsavedChanges) {
                      setTempId(itm);
                      setOpenConfirmationModal(true);
                    } else {
                      handleItemClick(itm, itm.id);
                    }
                  } else {
                    setdefaultNItem(itm);
                  }
                }}
              />
            );
          })}
        </div>
        {defaultTab < 3 &&
          ((defaultTab == 1 && selectedType == "global") ||
            defaultTab != 1) && (
            <div
              style={{
                position: "absolute",
                background: "#fff",
                borderTop: "1px solid #E5E5E5",
                bottom: 0,
                right: 10,
                padding: 8,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100% ",
              }}
            >
              <ArrowBackIosNewRoundedIcon
                className="cursor-pointer"
                onClick={() => {
                  if (pageNo > 1) {
                    setPageNo(pageNo - 1);
                    if (searchedTerm != "") {
                      props?.paginationQuery(pageNo + 1, searchedTerm);
                    } else {
                      props?.paginationQuery(pageNo - 1);
                    }
                  }
                }}
              />
              <span class="text-font14 font-semibold">
                {pageNo}/
                {defaultTab === 1
                  ? 100
                  : selectedType == "trainer"
                  ? Math.ceil(listData.length / 10)
                  : 50}
              </span>
              <ArrowForwardIosRoundedIcon
                className="cursor-pointer"
                onClick={() => {
                  setPageNo(pageNo + 1);
                  if (searchedTerm != "") {
                    props?.paginationQuery(pageNo + 1, searchedTerm);
                  } else {
                    props?.paginationQuery(pageNo + 1);
                  }
                }}
              />
            </div>
          )}
        <div
          style={{
            position: "absolute",
            bottom: 10,
            right: 10,
          }}
        >
          {displaypopup ? (
            <div
              className="absolute bg-[white] flex flex-col top-[-85px] right-0 w-[200px] h-[fit-content] p-2"
              style={{
                boxShadow: "1px 1px 10px 0px  rgba(0, 0, 0, 0.2)",
                borderRadius: "5",
              }}
            >
              <div
                className="m-1 cursor-pointer"
                onClick={() => {
                  setDisplaypopup(false);
                  handleAddModal("Add Food Item");
                }}
              >
                <span class="text-font14">Add Food Item</span>
              </div>
              <div
                className="m-1 cursor-pointer"
                onClick={() => {
                  setDisplaypopup(false);
                  handleAddModal("Explore Food Database");
                }}
              >
                <span class="text-font14">Explore Food Database</span>
              </div>
            </div>
          ) : null}
          {(defaultTab == 2 ? selectedType === "trainer" : true) &&
            (defaultTab == 1 ? selectedType === "trainer" : true) && (
              <div
                style={{
                  position: "absolute",
                  top: "auto",
                  bottom: defaultTab == 2 ? "35px" : "10px",
                  right: "0px",
                  borderRadius: "100px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "40px",
                  width: "40px",
                  background:
                    "linear-gradient(90deg, #464FEB 10.42%, #8330E9 100%)",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleAddModal();
                }}
              >
                <AddRoundedIcon
                  style={{
                    color: "white",
                  }}
                />
              </div>
            )}
        </div>
      </div>
      <CustomModal
        onConfirm={() => handleItemClick(tempId, tempId.id)}
        onCancel={() => setOpenConfirmationModal(false)}
        isOpen={openConfirmationModal}
        close={setOpenConfirmationModal}
      />
    </>
  );
};

export default NList;

function CustomModal({ onConfirm, onCancel, isOpen, close }) {
  const handleOpen = () => {
    close(true);
  };

  const handleClose = (value) => {
    close(false);
    if (value === "no") {
      onCancel();
    } else {
      onConfirm();
    }
  };

  return (
    <Dialog open={isOpen} onClose={() => handleClose("cancel")}>
      <DialogTitle>Unsaved Changes</DialogTitle>
      <DialogContent>
        <p>You have added meal plans. Are you sure you want to delete them?</p>
      </DialogContent>
      <DialogActions>
        <button
          className="button-new"
          style={{
            width: 100,
          }}
          onClick={() => handleClose("yes")}
          color="primary"
        >
          Yes
        </button>
        <Button
          style={{ color: "#b21e42" }}
          onClick={() => handleClose("no")}
          color="primary"
        >
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}
