import {CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis} from "recharts";
import React from "react";
import CustomTooltip from "./components/CustomTooltip";

const RenderLineChart = ({data, xKey, yKey, formatter, title, type, label}) => {

  // if not data then show no data message
  if (!data || data.length === 0) {
  return (
    <div className="flex items-center justify-center h-[320px] w-[290px] m-2 border bg-white-pure rounded-md text-lg text-gray-500">
        <p className={'text-center'}>
          No Data available for the selected date range for {title}.
        </p>
    </div>
  );
}

  return <LineChart
    width={300} height={320} data={data}
    margin={{top: 5, right: 10, left: 0, bottom: 5}}>

    <defs>
      <linearGradient id="colorUv" x1="1" y1="1" x2="0" y2="0">
        <stop offset="30%" stopColor="#464feb" stopOpacity={0.9}/>
        <stop offset="95%" stopColor="#8330e9" stopOpacity={0.9}/>
      </linearGradient>
    </defs>

    <CartesianGrid strokeDasharray="3 3" fill={'white'}/>
    <XAxis dataKey={xKey} fontSize={10}/>
    <YAxis padding={{top: 50}} axisLine={false} tickLine={false}
           fontSize={12}
           tickFormatter={formatter ?? undefined}
          label={label ? { value: label, angle: -90, position: 'insideLeft', offset: 10,style: { fill: 'black' }   }: undefined}
    />
    <Tooltip
  content={<CustomTooltip/>}
    />
    <Legend verticalAlign="top" height={36}
            payload={[{ value: title, type: type, id: title }]}
    />
    <Line type="monotone" dataKey={yKey}  stroke="url(#colorUv)" />
  </LineChart>
}
export default RenderLineChart