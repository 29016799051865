import { CopyAllRounded } from "@mui/icons-material";
import BinIcon from "../../pages/library/assests/BinIcon";
import WeekContainer from "./WeekContainer";
import WeekMealContainer from "./WeekMealContainer";
import { useContext } from "react";
import { MealsContext } from "../Nutrition/MealSheduleWeek";

const WeekList = ({
  row,
  column,
  columnPosition,
  programDetails,
  setShowWorkOutBuilder,
  setWorkOutFor,
  removeDayWorkout,
  showModal,
  setShowModal,
  setLeft,
  removeWeeks,
  removeParticularWeek,
  mealCard,
  setUnsavedChanges,
  handleDuplicateWeek,
  copiedDay,
  setCopiedDay,
  handlePasteDay,
  canEdit,
}) => {
  const emptyRows = new Array(row)?.fill([]);
  const emptyCols = new Array(column)?.fill([]);

  const { lastAssignedDay, mealScheduleAssignedUsers } =
    useContext(MealsContext);
  let lastAssignedWeek = Math.ceil(lastAssignedDay / column);
  return (
    <>
      {emptyRows.map((val, indexRow) => {
        let shouldDelete =
          !mealScheduleAssignedUsers?.length > 0 ||
          (mealScheduleAssignedUsers?.length > 0 &&
            indexRow + 1 > lastAssignedWeek);

        return (
          <div className="weeks-conatiner">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
            >
              <h6 className="weeks-title">Week {indexRow + 1}</h6>
              {/* {row > 1 && emptyRows.length == indexRow + 1 && ( */}
              {canEdit && (
                <div className="flex">
                  {shouldDelete && (
                    <div
                      onClick={() => {
                        // removeWeeks();
                        removeParticularWeek(indexRow);
                      }}
                    >
                      <BinIcon />
                    </div>
                  )}
                  <CopyAllRounded
                    sx={{
                      fontSize: "20px",
                      cursor: "pointer",
                      marginLeft: "16px",
                    }}
                    onClick={() => handleDuplicateWeek(indexRow)}
                  />
                </div>
              )}
              {/* )} */}
            </div>
            <div
              className="flex showScrollbar"
              style={{ width: "100%", gap: "16px", overflowX: "scroll" }}
            >
              {emptyCols.map((val, index) =>
                mealCard ? (
                  <WeekMealContainer
                    workOutDay={column * (indexRow + 1 - 1) + (index + 1)}
                    programDetails={programDetails}
                    dayIndex={`Day ${
                      column * (indexRow + 1 - 1) + (index + 1)
                    }`}
                    setShowWorkOutBuilder={setShowWorkOutBuilder}
                    setWorkOutFor={setWorkOutFor}
                    removeDayWorkout={removeDayWorkout}
                    showModal={showModal}
                    setShowModal={setShowModal}
                    setLeft={setLeft}
                    mealDetails={programDetails}
                    setUnsavedChanges={setUnsavedChanges}
                    copiedDay={copiedDay}
                    setCopiedDay={setCopiedDay}
                    handlePasteDay={handlePasteDay}
                    canEdit={canEdit}
                  />
                ) : (
                  <WeekContainer
                    workOutDay={column * (indexRow + 1 - 1) + (index + 1)}
                    programDetails={programDetails}
                    dayIndex={`Day ${
                      column * (indexRow + 1 - 1) + (index + 1)
                    }`}
                    setShowWorkOutBuilder={setShowWorkOutBuilder}
                    setWorkOutFor={setWorkOutFor}
                    removeDayWorkout={removeDayWorkout}
                    showModal={showModal}
                    setShowModal={setShowModal}
                    setLeft={setLeft}
                    mealCard={mealCard}
                  />
                )
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default WeekList;
