import { Dialog, DialogContent } from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import moment from "moment";
import { ChevronLeftRounded, ChevronRightRounded } from "@mui/icons-material";

const ZoomedImageModal = ({ imageUrl, onClose, allImages }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    setCurrentIndex(allImages.indexOf(imageUrl));
  }, [imageUrl, allImages]);

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        height: "100vh",
        width: "100vw",
        backgroundColor: "rgba(0,0,0,0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999,
        //blurr
        backdropFilter: "blur(3px)",
      }}
      onClick={onClose}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          padding: 16,
          cursor: "pointer",
        }}
        onClick={onClose}
      >
        <CloseRoundedIcon
          sx={{
            color: "#fff",
          }}
        />
      </div>

      <img
        style={{
          height: "90vh",
          width: "auto",
          objectFit: "contain",
        }}
        src={allImages[currentIndex] || imageUrl}
        alt="progress"
        onClick={(e) => e.stopPropagation()}
      />

      <button
        style={{
          position: "absolute",
          top: "50%",
          right: 20,
          transform: "translateY(-50%)",
          backgroundColor: "transparent",
          border: "none",
          outline: "none",
          cursor: "pointer",
          padding: 14,
          backgroundColor: "#fff",
          borderRadius: "50%",
          opacity: currentIndex === allImages.length - 1 ? 0.5 : 1,
        }}
        onClick={(e) => {
          e.stopPropagation();
          if (currentIndex === allImages.length - 1) return;
          setCurrentIndex(currentIndex + 1);
        }}
      >
        <ChevronRightRounded />
      </button>

      <button
        style={{
          position: "absolute",
          top: "50%",
          left: 20,
          transform: "translateY(-50%)",
          backgroundColor: "transparent",
          border: "none",
          outline: "none",
          cursor: "pointer",
          padding: 14,
          backgroundColor: "#fff",
          borderRadius: "50%",
          opacity: currentIndex === 0 ? 0.5 : 1,
        }}
        onClick={(e) => {
          e.stopPropagation();
          if (currentIndex === 0) return;
          setCurrentIndex(currentIndex - 1);
        }}
      >
        <ChevronLeftRounded />
      </button>
    </div>
  );
};

const AllImagesModal = ({ isOpen, setIsOpen, bodyStatsData }) => {
  const [zoomedImage, setZoomedImage] = useState(null);
  const [allImages, setAllImages] = useState([]);

  useEffect(() => {
    let images = [];

    bodyStatsData.forEach((item) => {
      if (!item.progressImages || item.progressImages.length <= 0) return;
      item.progressImages.forEach((image) => {
        images.push(image);
      });
    });
    setAllImages(images);
  }, [bodyStatsData]);

  return (
    <>
      <Dialog
        open={isOpen}
        sx={{
          backdropFilter: "blur(3px)",
        }}
        PaperProps={{
          sx: {
            minWidth: "320px",
            borderRadius: "20px",
          },
        }}
      >
        <div className="add-program-modal-head">
          <p className="add-program-modal-head-title">Client images</p>
          <div
            style={{
              cursor: "pointer",
            }}
            onClick={() => setIsOpen(false)}
          >
            <CloseRoundedIcon />
          </div>
        </div>
        <DialogContent sx={{ p: 0 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column-reverse",
              padding: "24px",
              gap: "16px",
            }}
          >
            {bodyStatsData.map((item) => {
              let date = moment(item.dateRecorded).format("Do MMM' YY");
              let progressImages = item?.progressImages ?? [];
              if (progressImages.length === 0) return null;
              return (
                <div>
                  <p
                    style={{
                      fontFamily: "DM Sans",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    {date}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "500px",
                      overflowX: "auto",
                      gap: "16px",
                    }}
                  >
                    {progressImages.map((image) => {
                      return (
                        <img
                          style={{
                            height: 150,
                            width: 150,
                            objectFit: "contain",
                            cursor: "pointer",
                          }}
                          src={image}
                          alt="progress"
                          onClick={() => setZoomedImage(image)}
                        />
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </DialogContent>
      </Dialog>
      {zoomedImage && (
        <ZoomedImageModal
          imageUrl={zoomedImage}
          onClose={() => setZoomedImage(null)}
          allImages={allImages}
        />
      )}
    </>
  );
};

export default AllImagesModal;
