import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import EditModalIcon from "../../assets/copilot/editModalIcon.svg";
import ExitIcon from "../../assets/copilot/exitIcon.svg";
import TextDescriptionIcon from "../../assets/copilot/textDescriptionIcon.svg";
import { GENERATE_SINGLE_WORKOUT } from "../../constants";
import { ProgramsContext } from "../../pages/library/programsCF/createProgramCF";
import { useGetSpurfitExercisesQuery } from "../../services/apiSlice";
import { TrackingActions } from "./CopilotTracking";
import { CopilotContext } from "./copilot";
import useOutsideClick from "./useOutsideClick";
import WorkoutBuilderModal from "../WorkoutComponent/WorkoutBuilderModal";
import { original } from "@reduxjs/toolkit";
export default function EditWorkouts({
  open,
  setOpen,
  data,
  nonConvertedData,
  setData,
  editWorkoutDay,
  shouldEdit,
  selectedOption,
  onResponseChange,
  showEditButton,
  addedWworkouts,
  setAddedWworkouts,
  handleDeleteWorkout,
  programAssignedUsers,
  programAssignedChannels,
  originalDay,
  replacementData,
  setShowWorkoutBuilderModal,
  isEndurance,
  ...props
}) {
  const [editingId, setEditingId] = useState(null);
  const [curDayWorkout, setCurDayWorkout] = useState(null);
  const [editingRow, setEditingRow] = useState(null);
  const [editingSection, setEditingSection] = useState(null);
  const [editableFields, setEditableFields] = useState({});
  const [loadingResult, setLoadingResult] = useState(false);

  const history = useHistory();
  const isSingleWorkout =
    selectedOption === GENERATE_SINGLE_WORKOUT || props?.isSingleWorkout;

  const handleCellDoubleClick = (id) => {
    setEditingId(id);
  };
  console.log("edit data", props);
  useEffect(() => {
    let res = JSON.parse(data);
    if (isSingleWorkout) {
      setCurDayWorkout(res.Workout);
      return;
    } else {
      let workout = res["week-1"][`Day-${editWorkoutDay}`].Workout;
      setCurDayWorkout(workout);
    }
  }, [data]);

  const formatProperty = (property) => {
    let res = "";
    if (property) {
      res = Object.entries(property)
        .map((item) => {
          if (isNaN(+item[1])) return item[1];
          // return fixed number of decimal points if it's a float
          let num = +item[1];
          if (num % 1 !== 0) {
            return num.toFixed(2);
          } else {
            return num;
          }
        })
        .join(", ");
    }
    return res;
  };

  function generateObjectOfObjects(count) {
    const result = {};

    for (let i = 1; i <= count; i++) {
      result[i] = "";
    }

    return result;
  }

  function updatePropertyOnNumRoundsChange(property, rounds) {
    let newProperty = {};
    if (!property) return property;

    // check if properties have more rounds or less rounds
    // if more rounds then remove the extra rounds
    // if less rounds then add the extra rounds
    // if same rounds then do nothing
    let propertyRounds = Object.keys(property).length;
    if (propertyRounds > rounds) {
      // remove extra rounds
      for (let i = 1; i <= rounds; i++) {
        newProperty[i] = property[i];
      }
    } else if (propertyRounds < rounds) {
      // add extra rounds
      let lastProperty = property[1];
      for (let i = 1; i <= rounds; i++) {
        if (property[i]) {
          newProperty[i] = property[i];
          lastProperty = property[i];
        } else {
          newProperty[i] = lastProperty;
        }
      }
    } else {
      // same rounds
      newProperty = property;
    }

    return newProperty;
  }

  const copilotContext = useContext(CopilotContext);

  const saveData = () => {
    if (!shouldEdit) return;
    let res = JSON.parse(data);
    if (isSingleWorkout) {
      res = curDayWorkout;
      let workout = { Workout: res };
      setData(JSON.stringify(workout));
      onResponseChange(JSON.stringify(workout));
      copilotContext?.secondaryActionTracking(
        TrackingActions.UPDATE_SINGLE_WORKOUT,
        JSON.stringify(res)
      );
    } else {
      res["week-1"][`Day-${editWorkoutDay}`].Workout = curDayWorkout;
      copilotContext?.secondaryActionTracking(
        TrackingActions.UPDATE_WORKOUTPLAN,
        JSON.stringify(res)
      );

      setData(JSON.stringify(res));
      onResponseChange(JSON.stringify(res));
    }
  };

  const onClose = () => {
    setOpen(false);
    saveData();
  };

  // update numRounds of all the parts
  const updateRoundsOfOnePart = (part, numRounds) => {
    let res = JSON.parse(data);

    if (isSingleWorkout) {
      res?.Workout?.[part]?.exercises?.forEach((exercise) => {
        if (exercise.reps) {
          exercise.reps = updatePropertyOnNumRoundsChange(
            exercise?.reps,
            numRounds
          );
        }
        if (exercise.weights) {
          exercise.weights = updatePropertyOnNumRoundsChange(
            exercise?.weights,
            numRounds
          );
        }
        if (exercise.heights) {
          exercise.heights = updatePropertyOnNumRoundsChange(
            exercise?.heights,
            numRounds
          );
        }
        if (exercise.duration) {
          exercise.duration = updatePropertyOnNumRoundsChange(
            exercise?.duration,
            numRounds
          );
        }
        if (exercise.distance) {
          exercise.distance = updatePropertyOnNumRoundsChange(
            exercise?.distance,
            numRounds
          );
        }
      });

      setCurDayWorkout(res?.Workout);
      setData(JSON.stringify(res));
    } else {
      res["week-1"][`Day-${editWorkoutDay}`].Workout[part].exercises.forEach(
        (exercise) => {
          if (exercise.reps) {
            exercise.reps = updatePropertyOnNumRoundsChange(
              exercise?.reps,
              numRounds
            );
          }
          if (exercise.weights) {
            exercise.weights = updatePropertyOnNumRoundsChange(
              exercise?.weights,
              numRounds
            );
          }
          if (exercise.heights) {
            exercise.heights = updatePropertyOnNumRoundsChange(
              exercise?.heights,
              numRounds
            );
          }
          if (exercise.duration) {
            exercise.duration = updatePropertyOnNumRoundsChange(
              exercise?.duration,
              numRounds
            );
          }
          if (exercise.distance) {
            exercise.distance = updatePropertyOnNumRoundsChange(
              exercise?.distance,
              numRounds
            );
          }
        }
      );

      setCurDayWorkout(res["week-1"][`Day-${editWorkoutDay}`].Workout);
      setData(JSON.stringify(res));
    }
  };

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          borderRadius: "16px",
          minWidth: "758px",
          height: "444px",
          width: "auto",
          padding: "17px 22px",
          position: "relative",
          maxWidth: ["100%", "90%", "80%", "70%"],
        },
      }}
    >
      {curDayWorkout && (
        <Box
          sx={{
            position: "relative",
            paddingTop: isSingleWorkout && !showEditButton ? "50px" : "0px",
          }}
        >
          {(!isSingleWorkout || showEditButton) && (
            <>
              <Typography
                sx={{
                  height: "18px",
                  width: "30px",
                  borderRadius: "4px",
                  background: "rgba(9, 160, 51, 0.10)",
                  color: "#09A033",
                  fontSize: "8px",
                  fontFamily: "DM Sans",
                  fontWeight: 700,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  visibility: showEditButton ? "hidden" : "visible",
                }}
              >
                {`DAY ${editWorkoutDay}`}
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "DM Sans",
                  fontWeight: 500,
                  fontColor: "#000",
                  lineHeight: "18px",
                  mt: showEditButton ? "4px" : "9px",
                }}
              >
                {curDayWorkout === "Rest Day"
                  ? curDayWorkout
                  : curDayWorkout?.title || curDayWorkout?.Name}
              </Typography>{" "}
            </>
          )}
          {showEditButton && (
            <>
              <button
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  position: "absolute",
                  top: "17px",
                  right: "156px",
                  cursor: "pointer",
                  borderRadius: "78px",
                  background:
                    "linear-gradient(90deg, #464feb 10.42%, #8330e9 100%)",
                  color: "#ffffff",
                  padding: "4px 8px",
                  fontSize: "12px",
                  fontWeight: "400",
                  marginRight: "10px",
                }}
                // onClick={async () => {
                //   await props.onEdit();
                //   history.push(`/workoutBuilder`, {
                //     state: {
                //       data: nonConvertedData,
                //       isImport: props?.isImport,
                //       isCalendar: props?.isCalendar,
                //       isUpdate: true,
                //       extraData: {
                //         userUUID: `${props?.userUUID}`,
                //   moduleDate: `${moment(props?.date, "DD-MM-YYYY")
                //     .startOf("day")
                //     .format("YYYY-MM-DDTHH:mm:ss")}Z`,
                //   trackingID: props?.workoutID,
                // },
                //     },
                //   });
                // }}
                onClick={() => {
                  props?.setIsNewWorkoutFalse();
                  setOpen(false);
                  setShowWorkoutBuilderModal(true);
                }}
              >
                <img
                  src={EditModalIcon}
                  alt="Edit Workout Button"
                  style={{
                    marginRight: "3px",
                  }}
                />
                Edit
              </button>
              <Button
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  position: "absolute",
                  top: "17px",
                  right: "84px",
                  cursor: "pointer",
                  borderRadius: "78px",
                  color: "royalblue",
                  padding: "4px 8px",
                  fontSize: "12px",
                  fontWeight: "600",
                  marginRight: "10px",
                }}
                onClick={() => {
                  props?.onReplacePress();
                }}
              >
                Replace
              </Button>
              {(((programAssignedChannels?.length > 0 ||
                programAssignedUsers?.length > 0) &&
                replacementData.workoutDay > originalDay) ||
                !(
                  programAssignedChannels?.length > 0 ||
                  programAssignedUsers?.length > 0
                )) && (
                <Button
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    position: "absolute",
                    top: "17px",
                    right: "20px",
                    cursor: "pointer",
                    borderRadius: "78px",
                    color: "#b21e42",
                    padding: "4px 8px",
                    fontSize: "12px",
                    fontWeight: "600",
                    marginRight: "10px",
                  }}
                  onClick={() => {
                    handleDeleteWorkout(nonConvertedData?.id);
                  }}
                >
                  Delete
                </Button>
              )}
            </>
          )}

          <img
            src={ExitIcon}
            onClick={onClose}
            style={{
              width: "30px",
              height: "30px",
              position: "absolute",
              top: "15px",
              right: "1px",
              cursor: "pointer",
            }}
          />

          {/* scale up and scale down one day workout  */}
          {/* {curDayWorkout !== "Rest Day" && (
            <Box>
              <Button
                sx={{
                  fontFamily: "DM Sans",
                  fontSize: "14px",
                }}
                onClick={async () => {
                  setLoadingResult(true);
                  let scaleUp = await ScaleUpSingleWorkout();
                  let res = JSON.parse(data);
                  let query =
                    "Scale up the following workout: " +
                    `${JSON.stringify(res["week-1"][`Day-${editWorkoutDay}`])}
                  `;
                  console.log("query for scsale up: ", query);
                  scaleUp.llmChainScaleWorkout
                    .run(query)
                    .then((result) => {
                      console.log(result);
                      res["week-1"][`Day-${editWorkoutDay}`] =
                        JSON.parse(result);
                      setCurDayWorkout(JSON.parse(result).Workout);
                      setData(JSON.stringify(res));
                    })
                    .catch((err) => {
                      console.log("error scaling up: ", err);
                    })
                    .finally(() => {
                      setLoadingResult(false);
                    });
                }}
              >
                Scale up
              </Button>
              <Button
                sx={{
                  fontFamily: "DM Sans",
                  fontSize: "14px",
                }}
                onClick={async () => {
                  setLoadingResult(true);
                  let scaleDown = await ScaleDownSingleWorkout();
                  let res = JSON.parse(data);
                  let query =
                    "Scale down the following workout: " +
                    `${JSON.stringify(res["week-1"][`Day-${editWorkoutDay}`])}
                `;
                  console.log("query for scale down: ", query);
                  scaleDown.llmChainScaleWorkout
                    .run(query)
                    .then((result) => {
                      console.log(result);
                      res["week-1"][`Day-${editWorkoutDay}`] =
                        JSON.parse(result);
                      setCurDayWorkout(JSON.parse(result).Workout);
                      setData(JSON.stringify(res));
                    })
                    .catch((err) => {
                      console.log("error scaling down: ", err);
                    })
                    .finally(() => {
                      setLoadingResult(false);
                    });
                }}
              >
                Scale Down
              </Button>
            </Box>
          )} */}
          {loadingResult && (
            <Box
              sx={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                backgroundColor: "transparent",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: "2",
              }}
            >
              <CircularProgress sx={{ color: "#6242EA" }} size="24px" />
            </Box>
          )}

          {curDayWorkout !== "Rest Day" ? (
            <>
              <TableContainer
                sx={{
                  mt: "17px",
                  border: "1px solid #E1DFDD",
                  backgroundColor: "#F5F5F5",
                  width: "100%",
                }}
                component={Paper}
                elevation={0}
              >
                <Table
                  sx={{
                    width: "100%",
                    overflow: "hidden",
                  }}
                  aria-label="edit table"
                >
                  <TableHead
                    sx={{
                      backgroundColor: "#F5F5F5",
                      borderRadius: "11px",
                    }}
                  >
                    <TableRow
                      sx={{ height: "31.863px", backgroundColor: "#F5F5F5" }}
                    >
                      <TableCell
                        sx={{
                          padding: "7.081px 10.621px",
                          minWidth: "35px",
                          borderRight: "1px solid #E1DFDD",
                          color: "#242424",
                          fontSize: "12.391px",
                          fontFamily: "DM Sans",
                          height: "31.863px",
                        }}
                      ></TableCell>
                      <TableCell
                        sx={{
                          padding: "7.081px 10.621px",
                          minWidth: "165.512px",
                          borderRight: "1px solid #E1DFDD",
                          height: "31.863px",

                          // display: "flex",
                          // alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            color: "#242424",
                            fontSize: "12.391px",
                            fontFamily: "DM Sans",
                          }}
                        >
                          <img
                            src={TextDescriptionIcon}
                            style={{
                              width: "17.702px",
                              height: "17.702px",
                              marginRight: "7px",
                            }}
                          />{" "}
                          Exercise
                        </Box>
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "7.081px 10.621px",
                          minWidth: "90.28px",
                          borderRight: "1px solid #E1DFDD",
                          height: "31.863px",
                          color: "#242424",
                          fontSize: "12.391px",
                          fontFamily: "DM Sans",
                        }}
                        align="center"
                      >
                        Sets
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "7.081px 10.621px",
                          minWidth: "90.28px",
                          borderRight: "1px solid #E1DFDD",
                          height: "31.863px",
                          color: "#242424",
                          fontSize: "12.391px",
                          fontFamily: "DM Sans",
                        }}
                        align="center"
                      >
                        Reps
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "7.081px 10.621px",
                          minWidth: "90.28px",
                          borderRight: "1px solid #E1DFDD",
                          height: "31.863px",
                          color: "#242424",
                          fontSize: "12.391px",
                          fontFamily: "DM Sans",
                        }}
                        align="center"
                      >
                        Weight (
                        {
                          <Select
                            variant="standard"
                            disableUnderline
                            SelectDisplayProps={{
                              style: {
                                fontFamily: "DM Sans",
                                backgroundColor: "transparent",
                                color: "#242424",
                                fontSize: "12.391px",
                              },
                            }}
                            value={
                              curDayWorkout?.["Part-1"]?.exercises?.[0]?.units
                                ?.weights === "kilograms" ||
                              curDayWorkout?.["Part-1"]?.exercises?.[0]?.units
                                ?.weights === "kg"
                                ? "kg"
                                : "lbs"
                            }
                            onChange={(e) => {
                              const newWeightUnits = e.target.value;
                              console.log("weight changing", e);
                              setCurDayWorkout((prevWorkout) => {
                                // Create a deep copy of the current workout
                                const updatedWorkout = JSON.parse(
                                  JSON.stringify(prevWorkout)
                                );

                                // Iterate through each part
                                for (const partKey in updatedWorkout) {
                                  if (
                                    updatedWorkout.hasOwnProperty(partKey) &&
                                    partKey.includes("Part")
                                  ) {
                                    const part = updatedWorkout[partKey];

                                    // Update weight units for the part
                                    if (part.exercises) {
                                      part.exercises.forEach((exercise) => {
                                        if (
                                          exercise.units &&
                                          exercise.units.weights
                                        ) {
                                          exercise.units.weights =
                                            newWeightUnits;
                                        }

                                        // Convert weights if units are changed
                                        if (exercise.weights) {
                                          const updatedWeights = {};
                                          for (const weightKey in exercise.weights) {
                                            if (
                                              exercise.weights.hasOwnProperty(
                                                weightKey
                                              )
                                            ) {
                                              const originalWeight =
                                                exercise.weights[weightKey];

                                              if (newWeightUnits === "lbs") {
                                                updatedWeights[weightKey] =
                                                  Math.ceil(
                                                    originalWeight * 2.20462
                                                  );
                                              } else {
                                                const convertedWeight =
                                                  originalWeight / 2.20462;

                                                // Check if the weight is an integer
                                                updatedWeights[weightKey] =
                                                  convertedWeight % 1 === 0
                                                    ? convertedWeight.toFixed(0) // Remove decimal if it's an integer
                                                    : convertedWeight.toFixed(
                                                        1
                                                      );
                                              }
                                            }
                                          }
                                          exercise.weights = updatedWeights;
                                        }
                                      });
                                    }
                                  }
                                }

                                return updatedWorkout;
                              });
                            }}
                          >
                            <MenuItem
                              sx={{
                                fontFamily: "DM Sans",
                                fontSize: "12.391px",
                              }}
                              value="lbs"
                            >
                              LBS
                            </MenuItem>
                            <MenuItem
                              sx={{
                                fontFamily: "DM Sans",
                                fontSize: "12.391px",
                              }}
                              value="kg"
                            >
                              KG
                            </MenuItem>
                          </Select>
                        }
                        )
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "7.081px 10.621px",
                          minWidth: "93px",
                          borderRight: "1px solid #E1DFDD",
                          height: "31.863px",
                          color: "#242424",
                          fontSize: "12.391px",
                          fontFamily: "DM Sans",
                        }}
                        align="center"
                      >
                        Time{" "}
                        {!isEndurance &&
                          `(${
                            curDayWorkout?.["Part-1"]?.exercises?.[0]?.units
                              ?.duration ?? "Sec"
                          })`}
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "7.081px 10.621px",
                          minWidth: "100px",
                          height: "31.863px",
                          color: "#242424",
                          fontSize: "12.391px",
                          fontFamily: "DM Sans",
                        }}
                        align="center"
                      >
                        Distance (
                        {curDayWorkout?.["Part-1"]?.exercises?.[0]?.units
                          ?.distance || "m"}
                        )
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody
                    sx={{
                      backgroundColor: "#ffff",
                    }}
                  >
                    {Object.entries(curDayWorkout).map(
                      ([part, workout], index) => {
                        let serialNumber = 1;

                        const exercises = workout?.exercises?.map(
                          (row, rowIdx) => {
                            console.log("row set hone se pehle", row);
                            let rounds = 0;
                            if (row?.reps) {
                              rounds = Object.entries(row?.reps).length;
                            } else if (row?.weights) {
                              rounds = Object.entries(row?.weights).length;
                            } else if (row?.duration) {
                              rounds = Object.entries(row?.duration).length;
                            } else if (row?.distance) {
                              rounds = Object.entries(row?.distance).length;
                            }

                            const formattedSerialNumber = serialNumber
                              .toString()
                              .padStart(2, "0");
                            serialNumber++;

                            let isEditable =
                              editingId ===
                              row?.name + "" + rowIdx + "" + index;

                            return (
                              <CustomTableRow
                                editingRow={editingRow}
                                handleCellDoubleClick={handleCellDoubleClick}
                                row={row}
                                setEditableFields={setEditableFields}
                                setEditingRow={setEditingRow}
                                editingSection={editingSection}
                                setEditingSection={setEditingSection}
                                key={rowIdx}
                                part={part}
                                rowIdx={rowIdx}
                                rounds={rounds}
                                isEditable={isEditable}
                                formattedSerialNumber={formattedSerialNumber}
                                generateObjectOfObjects={
                                  generateObjectOfObjects
                                }
                                editableFields={editableFields}
                                formatProperty={formatProperty}
                                editingId={editingId}
                                setEditingId={setEditingId}
                                curDayWorkout={curDayWorkout}
                                setCurDayWorkout={setCurDayWorkout}
                                index={index}
                                shouldEdit={shouldEdit}
                                updateRoundsOfOnePart={(rounds) => {
                                  updateRoundsOfOnePart(part, rounds);
                                }}
                                isSingleWorkout={
                                  selectedOption === GENERATE_SINGLE_WORKOUT
                                }
                                nonConvertedData={nonConvertedData}
                                isEndurance={isEndurance}
                              />
                            );
                          }
                        );

                        return (
                          <React.Fragment key={part}>
                            {exercises}
                            {index != 0 &&
                              index !=
                                Object.entries(curDayWorkout).length - 1 && (
                                <TableRow>
                                  <TableCell
                                    colSpan={7}
                                    style={{ height: "32px" }}
                                  >
                                    {/* <TableCell
                                      sx={{
                                        textAlign: "center",
                                        padding: "7.081px 10.621px",

                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                      colSpan={7}
                                    >
                                      <Typography
                                        sx={{
                                          color: "#242424",
                                          fontWeight: 700,
                                          fontFamily: "DM Sans",
                                          fontStyle: "italic",
                                          backgroundColor: "#E7E7EB",
                                          padding: "0px 5px",
                                          borderRadius: "3px",
                                          lineHeight: "21.242px",
                                        }}
                                      >
                                        Break - {convertSeconds(180)}
                                      </Typography>
                                    </TableCell> */}
                                  </TableCell>
                                </TableRow>
                              )}
                          </React.Fragment>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  mt: "28px",
                }}
              >
                Here's an overview of workout plan for your client. Take a look
                at the table to stay updated and assist them in achieving their
                fitness goals. Feel free to edit the table if needed, ensuring
                each client's workout plan is tailored to their individual
                needs.
              </Typography>
            </>
          ) : (
            <Box>
              <Typography variant="h6" sx={{ textAlign: "center" }}>
                No workout for this day
              </Typography>
            </Box>
          )}
        </Box>
      )}
      {/* {showWorkoutBuilderModal && (
        <WorkoutBuilderModal
          open={showWorkoutBuilderModal}
          setOpen={setShowWorkoutBuilderModal}
          data={nonConvertedData}
          isImport={props?.isImport}
          isCalendar={props?.isCalendar}
          isUpdate={true}
          extraData={{
            userUUID: `${props?.userUUID}`,
            moduleDate: `${moment(props?.date, "DD-MM-YYYY")
              .startOf("day")
              .format("YYYY-MM-DDTHH:mm:ss")}Z`,
            trackingID: props?.workoutID,
          }}
        />
      )} */}
    </Dialog>
  );
}

const CustomTableRow = ({
  row,
  editingRow,
  setEditingRow,
  setEditableFields,
  handleCellDoubleClick,
  isEditable,
  editableFields,
  rowIdx,
  rounds,
  generateObjectOfObjects,
  formatProperty,
  formattedSerialNumber,
  part,
  editingId,
  setEditingId,
  curDayWorkout,
  setCurDayWorkout,
  index,
  shouldEdit,
  updateRoundsOfOnePart,
  isSingleWorkout,
  editingSection,
  setEditingSection,
  nonConvertedData,
  isEndurance,
}) => {
  const rowRef = useRef(null);
  const cell1Ref = useRef(null);
  const cell2Ref = useRef(null);
  const cell3Ref = useRef(null);
  const cell4Ref = useRef(null);
  const cell5Ref = useRef(null);
  const cell6Ref = useRef(null);
  const cell7Ref = useRef(null);
  // console.log("row ha kya", row);
  const [seconds, setSeconds] = useState(
    Math.floor(editingRow?.duration?.[1] % 60) || ""
  );
  const [minutes, setMinutes] = useState(
    Math.floor(editingRow?.duration?.[1] % 60) || ""
  );
  let weightInKg;
  const [numRounds, setNumRounds] = useState(rounds || 1);
  useEffect(() => {
    setNumRounds(rounds || 1);
  }, [rounds]);

  useEffect(() => {
    if (isEditable) {
      setSeconds(Math.floor(editingRow?.duration?.[1] % 60));
      setMinutes(Math.floor(editingRow?.duration?.[1] / 60));
    }
  }, [editingRow]);

  const handleOutsideClick = () => {
    if (editingSection === `${row?.name}@${index}`) {
      setEditingSection(null);
      updateRoundsOfOnePart(numRounds);
      // let timeoutId = setTimeout(() => {
      //   // check if numRounds updated

      //   clearTimeout(timeoutId);
      // }, 0);
    }
    if (row?.name + "" + rowIdx + "" + index === editingId) {
      let previousRounds = rounds;
      if (row?.name === "Rest") {
        let durationObj = {};
        for (let i = 0; i < numRounds; i++) {
          durationObj[i + 1] = +minutes * 60 + +seconds;
        }
        let res = { ...editingRow, duration: durationObj };
        setEditingRow({
          ...editingRow,
          duration: durationObj,
        });

        setCurDayWorkout({
          ...curDayWorkout,
          [part]: {
            ...curDayWorkout[part],
            exercises: curDayWorkout[part].exercises.map((exercise, idx) => {
              if (idx === rowIdx) {
                return res;
              } else {
                return exercise;
              }
            }),
          },
        });

        setEditingId(null);
        setEditingRow({});
        return;
      }

      setCurDayWorkout({
        ...curDayWorkout,
        [part]: {
          ...curDayWorkout[part],
          exercises: curDayWorkout[part].exercises.map((exercise, idx) => {
            if (idx === rowIdx) {
              return editingRow;
            } else {
              return exercise;
            }
          }),
        },
      });
      setEditingId(null);
      setEditingRow({});
    }
  };

  useEffect(() => {
    if (numRounds < 1) return;
    let newEditingRow = { ...editingRow };

    if (editingRow?.reps) {
      newEditingRow = {
        ...newEditingRow,
        reps: createRoundsObject(editingRow, "reps", numRounds),
      };
    }

    if (editingRow?.weights) {
      newEditingRow = {
        ...newEditingRow,
        weights: createRoundsObject(editingRow, "weights", numRounds),
      };
    }

    if (editingRow?.heights) {
      newEditingRow = {
        ...newEditingRow,
        heights: createRoundsObject(editingRow, "heights", numRounds),
      };
    }

    if (editingRow?.duration) {
      newEditingRow = {
        ...newEditingRow,
        duration: createRoundsObject(editingRow, "duration", numRounds),
      };
    }

    if (editingRow?.distance) {
      newEditingRow = {
        ...newEditingRow,
        distance: createRoundsObject(editingRow, "distance", numRounds),
      };
    }

    setEditingRow(newEditingRow);
  }, [numRounds]);

  function createRoundsObject(obj, key, numRounds) {
    let newObj = {};
    let prev = 0;
    for (let i = 1; i <= numRounds; i++) {
      if (!obj?.[key]?.[i]) {
        newObj[i] = prev;
      } else {
        prev = obj?.[key]?.[i];
        newObj[i] = obj?.[key]?.[i];
      }
    }
    return newObj;
  }

  useOutsideClick(rowRef, handleOutsideClick, [
    cell1Ref,
    cell2Ref,
    cell3Ref,
    cell4Ref,
    cell5Ref,
    cell6Ref,
    cell7Ref,
  ]);
  const coachID = useSelector((state) => state?.auth?.trainerUUID);

  const APIURL = useSelector((state) => state.auth.apiURL);

  const {
    data: exerciseList,
    error: spurfitExercisesError,
    isLoading: spurfitExercisesLoading,
  } = useGetSpurfitExercisesQuery({ APIURL, coachID });
  console.log("Exercise List", exerciseList);
  let lastIndex = Object.keys(curDayWorkout[part].exercises).length - 1;
  if (
    nonConvertedData?.workoutContent?.rounds?.[index - 1]?.round?.type ==
    "Mixed Sets"
  ) {
    rowIdx = 0;
    lastIndex = 0;
  }

  return row?.name === "Rest" ? (
    <TableRow
      ref={rowRef}
      onDoubleClick={() => {
        if (row?.name + "" + rowIdx + "" + index === editingId || !shouldEdit)
          return;
        handleCellDoubleClick(row?.name + "" + rowIdx + "" + index);
        setEditingRow({ ...row, rounds: numRounds });
        setEditableFields({
          name: row?.name ? true : false,
          rounds: rounds ? true : false,
          reps: row?.reps ? true : false,
          weights: row?.weights ? true : false,
          duration: row?.duration ? true : false,
          distance: row?.distance ? true : false,
        });
      }}
      key={`${part}-${row.name}`}
      sx={{
        borderLeft: "2px solid #464FEB96",
        borderBottom: rowIdx == lastIndex && "2px solid #464FEB96",
        borderRight: "2px solid #464FEB96",
      }}
    >
      <TableCell colSpan={7} style={{ height: "32px" }}>
        <TableCell
          sx={{
            textAlign: "center",
            padding: "7.081px 10.621px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          colSpan={7}
        >
          {isEditable ? (
            // Take input in two fields one for minutes and one for seconds and then convert it to seconds and set the duration
            <Box
              sx={{
                display: "flex",
                border: "1px solid #E1DFDD",
                padding: "0px 5px",
              }}
            >
              <input
                type="number"
                value={minutes}
                placeholder="mm"
                onChange={(e) => {
                  setMinutes(e.target.value);
                }}
                style={{
                  width: "30px",
                  height: "30px",
                }}
              />
              <Typography
                sx={{
                  color: "#242424",
                  fontWeight: 700,
                  fontFamily: "DM Sans",
                  padding: "0px 5px",
                  borderRadius: "3px",
                  mx: "5px",
                }}
              >
                :
              </Typography>
              <input
                type="number"
                value={seconds}
                placeholder="ss"
                onChange={(e) => {
                  setSeconds(e.target.value);
                }}
                style={{
                  width: "30px",
                  height: "30px",
                }}
              />
            </Box>
          ) : (
            <Typography
              sx={{
                color: "#242424",
                fontWeight: 700,
                fontFamily: "DM Sans",
                fontStyle: "italic",
                backgroundColor: "#E7E7EB",
                padding: "0px 5px",
                borderRadius: "3px",
                lineHeight: "21.242px",
              }}
            >
              Break - {convertSeconds(row?.duration?.[1])}
            </Typography>
          )}
        </TableCell>
      </TableCell>
    </TableRow>
  ) : (
    <>
      {rowIdx == 0 && (
        <TableRow
          sx={{
            borderTop: rowIdx == 0 && "2px solid #464FEB96",
            borderTopRightRadius: rowIdx == 0 && "10px",
            borderTopLeftRadius: rowIdx == 0 && "10px",
            borderLeft: "2px solid #464FEB96",
            borderRight: "2px solid #464FEB96",
          }}
          onDoubleClick={() => {
            if (!shouldEdit) return;
            setNumRounds(rounds);
            setEditingSection(row?.name + "@" + index);
          }}
        >
          <TableCell
            sx={{
              width: "35px",
              borderRight: "1px solid #F5F5F5",
              borderBottom: "1px solid #F5F5F5",
            }}
          ></TableCell>
          <TableCell
            ref={cell1Ref}
            sx={{
              width: "35px",
              borderRight: "1px solid #F5F5F5",
              borderBottom: "1px solid #F5F5F5",
              color: "#242424",
              fontSize: "12.391px",
              fontFamily: "DM Sans",
              padding: "7.081px 10.621px",
              fontWeight: 600,
            }}
            align="center"
          >
            {nonConvertedData?.workoutContent?.rounds?.[index - 1]?.round
              ?.type === "Superset" &&
            nonConvertedData?.workoutContent?.rounds?.[index - 1]?.round
              ?.measurements?.length > 1
              ? "Super Set"
              : nonConvertedData?.workoutContent?.rounds?.[index - 1]?.round
                  ?.type === "Mixed Sets" &&
                nonConvertedData?.workoutContent?.rounds?.[index - 1]?.round
                  ?.measurements?.length === 1
              ? "Mixed Sets"
              : nonConvertedData?.workoutContent?.rounds?.[index - 1]?.round
                  ?.type !== "Superset" &&
                nonConvertedData?.workoutContent?.rounds?.[index - 1]?.round
                  ?.type !== "Mixed Sets"
              ? nonConvertedData?.workoutContent?.rounds?.[index - 1]?.round
                  ?.type
              : ""}
          </TableCell>
          <TableCell
            // ref={cell3Ref}
            ref={rowRef}
            sx={{
              minWidth: "90.28px",

              borderRight: "1px solid #F5F5F5",
              borderBottom: "1px solid #F5F5F5",
              color: "#242424",
              fontSize: "12.391px",
              fontFamily: "DM Sans",
              padding: "7.081px 10.621px",
            }}
            align="center"
          >
            {editingSection === `${row?.name}@${index}` ? (
              <input
                id={`${row?.name}@${index}`}
                ref={cell3Ref}
                type="number"
                min={1}
                style={{
                  width: "50px",
                  border: "1px solid #E1DFDD",
                  borderRadius: "5px",
                  color: "#242424",
                  fontSize: "12.391px",
                  fontFamily: "DM Sans",
                  padding: "7.081px 10.621px",
                  outline: "none",
                }}
                value={numRounds}
                onChange={(e) => {
                  let value = e.target.value;
                  setEditingRow({
                    ...editingRow,
                    rounds: value,
                  });
                  setNumRounds(value);
                }}
                onKeyDown={(e) => {
                  // press enter key
                  if (e.keyCode === 13) {
                    handleOutsideClick();
                  }
                }}
                // onBlur={handleCellBlur}
              />
            ) : (
              numRounds
            )}
          </TableCell>
          <TableCell
            sx={{
              borderRight: "1px solid #F5F5F5",
              borderBottom: "1px solid #F5F5F5",
            }}
          ></TableCell>
          <TableCell
            sx={{
              borderRight: "1px solid #F5F5F5",
              borderBottom: "1px solid #F5F5F5",
            }}
          ></TableCell>
          <TableCell
            sx={{
              borderRight: "1px solid #F5F5F5",
              borderBottom: "1px solid #F5F5F5",
            }}
          ></TableCell>
          <TableCell
            sx={{
              borderRight: "1px solid #F5F5F5",
              borderBottom: "1px solid #F5F5F5",
            }}
          ></TableCell>
        </TableRow>
      )}
      <TableRow
        ref={rowRef}
        onDoubleClick={() => {
          if (row?.name === "Rest" || !shouldEdit) return;
          if (row?.name + "" + rowIdx + "" + index === editingId) return;
          handleCellDoubleClick(row?.name + "" + rowIdx + "" + index);
          setEditingRow({ ...row, rounds: numRounds });
          setEditableFields({
            name: row?.name ? true : false,
            rounds: numRounds ? true : false,
            reps: row?.reps ? true : false,
            weights: row?.weights ? true : false,
            duration: row?.duration ? true : false,
            distance: row?.distance ? true : false,
          });
        }}
        key={`${part}-${row.name}`}
        sx={{
          // borderTop: rowIdx == 0 && "2px solid #464FEB96",
          // borderTopRightRadius: rowIdx == 0 && "10px",
          // borderTopLeftRadius: rowIdx == 0 && "10px",
          // borderTop:"2px solid #464FEB96",
          borderLeft: "2px solid #464FEB96",
          borderBottom: rowIdx == lastIndex && "2px solid #464FEB96",
          borderRight: "2px solid #464FEB96",
        }}
      >
        <TableCell
          ref={cell1Ref}
          sx={{
            width: "35px",
            borderRight: "1px solid #F5F5F5",
            borderBottom: "1px solid #F5F5F5",
            color: "#242424",
            fontSize: "12.391px",
            fontFamily: "DM Sans",
            padding: "7.081px 10.621px",
          }}
          align="center"
        >
          {formattedSerialNumber}
        </TableCell>
        <TableCell
          ref={cell2Ref}
          sx={{
            minWidth: "165.512px",

            borderRight: "1px solid #F5F5F5",
            borderBottom: "1px solid #F5F5F5",
            color: "#242424",
            fontSize: "12.391px",
            fontFamily: "DM Sans",
            padding: "7.081px 10.621px",
          }}
          component="th"
          scope="row"
        >
          {isEditable && editableFields?.name ? (
            <Autocomplete
              disablePortal
              freeSolo
              id="combo-box-demo"
              options={exerciseList ?? []}
              getOptionLabel={(option) => {
                return option?.name || editingRow?.name;
              }}
              defaultValue={editingRow?.name}
              onChange={(event, value) => {
                event.stopPropagation();
                setEditingRow({
                  ...editingRow,
                  name: value?.name,
                  movementID: value?.id,
                  imageLink: value?.imageLink,
                  videoUrl: value?.videoURL,
                });
              }}
              sx={{
                // width: "260px",
                zIndex: 100,
                fontSize: "12px",
                color: "rgba(51, 51, 56, 0.50)",
                "& li": {
                  fontSize: "12px",
                },
                mt: "12px",
              }}
              renderOption={(props, option) => {
                return (
                  <MenuItem
                    {...props}
                    key={option.key}
                    sx={{
                      fontSize: "12px",
                      bgcol: "red",
                      fontFamily: "DM Sans",
                      lineHeight: "16px",
                      color: "#000",
                      zIndex: 100,
                    }}
                  >
                    {option?.name}
                  </MenuItem>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label=""
                  placeholder="Exercise Name"
                  variant="standard"
                  size="small"
                  sx={{
                    fontSize: "12px",
                    borderBottomColor: "#E1E1E1",
                    "&:hover": { borderBottomColor: "#E1E1E1" },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    sx: { fontSize: "12px", lineHeight: "22px" },
                  }}
                />
              )}
            />
          ) : (
            row.name
          )}
        </TableCell>
        <TableCell
          ref={cell3Ref}
          sx={{
            minWidth: "90.28px",

            borderRight: "1px solid #F5F5F5",
            borderBottom: "1px solid #F5F5F5",
            color: "#8b8885",
            fontSize: "12.391px",
            fontFamily: "DM Sans",
            padding: "7.081px 10.621px",
          }}
          align="center"
        >
          {false && editableFields?.rounds ? (
            <input
              type="number"
              min={1}
              style={{
                width: "50px",
                border: "1px solid #E1DFDD",
                borderRadius: "5px",
                color: "#242424",
                fontSize: "12.391px",
                fontFamily: "DM Sans",
                padding: "7.081px 10.621px",
                outline: "none",
              }}
              value={editingRow?.rounds || numRounds}
              onChange={(e) => {
                let value = e.target.value;
                setEditingRow({
                  ...editingRow,
                  rounds: value,
                });
                setNumRounds(value);
              }}
              // onBlur={handleCellBlur}
            />
          ) : (
            <></>
          )}
        </TableCell>
        <TableCell
          ref={cell4Ref}
          sx={{
            // minWidth: "90.28px",
            width: row?.reps ? "auto" : "90.28px",
            borderRight: "1px solid #F5F5F5",
            borderBottom: "1px solid #F5F5F5",
            color: "#242424",
            fontSize: "12.391px",
            fontFamily: "DM Sans",
            padding: "7.081px 10.621px",
          }}
          align="center"
          onClick={() => {
            if (isEditable && !editableFields?.reps) {
              setEditableFields({
                ...editableFields,
                reps: true,
              });
            }
          }}
        >
          {isEditable && editableFields?.reps ? (
            <Box sx={{ display: "flex" }}>
              {(row?.reps
                ? Object.keys(editingRow?.reps)
                : Object.keys(generateObjectOfObjects(numRounds))
              ).map((key, index) => {
                return (
                  <input
                    key={index}
                    style={{
                      width: "50px",
                      // height: "20px",
                      border: "1px solid #E1DFDD",
                      borderRadius: "5px",
                      marginRight: "2px",
                      color: "#242424",
                      fontSize: "12.391px",
                      fontFamily: "DM Sans",
                      padding: "7.081px 10.621px",
                      outline: "none",
                    }}
                    value={editingRow?.reps?.[key]}
                    onChange={(e) => {
                      setEditingRow({
                        ...editingRow,
                        reps: {
                          ...editingRow?.reps,
                          [key]: e.target.value,
                        },
                      });
                    }}
                    // onBlur={handleCellBlur}
                  />
                );
              })}
            </Box>
          ) : (
            formatProperty(row?.reps)
          )}
        </TableCell>
        <TableCell
          ref={cell5Ref}
          sx={{
            minWidth: "90.28px",
            borderRight: "1px solid #F5F5F5",
            borderBottom: "1px solid #F5F5F5",
            color: "#242424",
            fontSize: "12.391px",
            fontFamily: "DM Sans",
            padding: "7.081px 10.621px",
          }}
          align="center"
          onClick={() => {
            if (isEditable && !editableFields?.weights) {
              setEditableFields({
                ...editableFields,
                weights: true,
              });
            }
          }}
        >
          {isEditable && editableFields?.weights ? (
            <Box sx={{ display: "flex" }}>
              {(row?.weights
                ? Object.keys(editingRow?.weights)
                : Object.keys(generateObjectOfObjects(numRounds))
              ).map((key, index) => {
                return (
                  <input
                    key={index}
                    style={{
                      width: "50px",
                      border: "1px solid #E1DFDD",
                      borderRadius: "5px",
                      marginRight: "2px",
                      color: "#242424",
                      fontSize: "12.391px",
                      fontFamily: "DM Sans",
                      padding: "7.081px 10.621px",
                      outline: "none",
                    }}
                    value={editingRow?.weights?.[key]}
                    onChange={(e) => {
                      setEditingRow({
                        ...editingRow,
                        weights: {
                          ...editingRow?.weights,
                          [key]: e.target.value,
                        },
                      });
                    }}
                    // onBlur={handleCellBlur}
                  />
                );
              })}
            </Box>
          ) : (
            formatProperty(row?.weights)
          )}
        </TableCell>
        <TableCell
          ref={cell6Ref}
          sx={{
            minWidth: row?.duration ? "auto" : "93px",
            borderRight: "1px solid #F5F5F5",
            borderBottom: "1px solid #F5F5F5",
            color: "#242424",
            fontSize: "12.391px",
            fontFamily: "DM Sans",
            padding: "7.081px 10.621px",
          }}
          align="center"
          onClick={() => {
            if (isEditable && !editableFields?.duration) {
              setEditableFields({
                ...editableFields,
                duration: true,
              });
            }
          }}
        >
          {isEditable && editableFields?.duration ? (
            <Box sx={{ display: "flex" }}>
              {(row?.duration
                ? Object.keys(editingRow?.duration)
                : Object.keys(generateObjectOfObjects(numRounds))
              )?.map((key, index) => {
                return (
                  <input
                    key={index}
                    style={{
                      width: "50px",
                      border: "1px solid #E1DFDD",
                      borderRadius: "5px",
                      marginRight: "2px",
                      color: "#242424",
                      fontSize: "12.391px",
                      fontFamily: "DM Sans",
                      padding: "7.081px 10.621px",
                      outline: "none",
                    }}
                    value={editingRow?.duration?.[key]}
                    onChange={(e) => {
                      setEditingRow({
                        ...editingRow,
                        duration: {
                          ...editingRow?.duration,
                          [key]: e.target.value,
                        },
                      });
                    }}
                    // onBlur={handleCellBlur}
                  />
                );
              })}
            </Box>
          ) : isEndurance ? (
            formatDuration(row?.duration)
          ) : (
            formatProperty(row?.duration)
          )}
        </TableCell>
        <TableCell
          ref={cell7Ref}
          sx={{
            minWidth: row?.distance ? "auto" : "93px",
            borderRight: "1px solid #F5F5F5",
            borderBottom: "1px solid #F5F5F5",
            color: "#242424",
            fontSize: "12.391px",
            fontFamily: "DM Sans",
            padding: "7.081px 10.621px",
          }}
          align="center"
          onClick={() => {
            if (isEditable && !editableFields?.distance) {
              setEditableFields({
                ...editableFields,
                distance: true,
              });
            }
          }}
        >
          {isEditable && editableFields?.distance ? (
            <Box sx={{ display: "flex" }}>
              {(row?.distance
                ? Object.keys(editingRow?.distance)
                : Object.keys(generateObjectOfObjects(numRounds))
              )?.map((key, index) => {
                return (
                  <input
                    key={index}
                    style={{
                      width: "50px",
                      border: "1px solid #E1DFDD",
                      borderRadius: "5px",
                      marginRight: "2px",
                      color: "#242424",
                      fontSize: "12.391px",
                      fontFamily: "DM Sans",
                      padding: "7.081px 10.621px",
                      outline: "none",
                    }}
                    value={editingRow?.distance?.[key]}
                    onChange={(e) => {
                      setEditingRow({
                        ...editingRow,
                        distance: {
                          ...editingRow?.distance,
                          [key]: e.target.value,
                        },
                      });
                    }}
                    // onBlur={handleCellBlur}
                  />
                );
              })}
            </Box>
          ) : (
            formatProperty(row?.distance)
          )}
        </TableCell>
      </TableRow>
      {nonConvertedData?.workoutContent?.rounds?.[index - 1]?.round?.type ==
      "Mixed Sets" ? (
        <TableRow>
          <TableCell colSpan={7} style={{ height: "32px" }}></TableCell>
        </TableRow>
      ) : null}
    </>
  );
};

function convertSeconds(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  let result = "";

  if (hours !== 0) {
    result += hours + " hr ";
  }

  if (minutes !== 0) {
    result += minutes + " min ";
  }

  if (remainingSeconds !== 0) {
    result += remainingSeconds + " secs";
  }

  return result.trim();
}

const formatDuration = (duration) => {
  if (!duration?.[1]) {
    return "";
  }

  // convert to minutes hh:mm:ss
  let value = duration?.[1];
  const hours = Math.floor(+value / 3600);
  const minutes = Math.floor((+value % 3600) / 60);
  const seconds = +value % 60;

  return `${hours}:${minutes}:${seconds}`;
};
