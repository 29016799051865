import { CloseRounded } from "@mui/icons-material";
import { Dialog, DialogContent } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { useSelector } from "react-redux";
import { SpurfitCircularProgress } from "../Dashboard/dashboard";

const UpdateMealSchedule = ({
  open,
  setIsOpen,
  setNutritionData,
  trainerUUID,
  setdefaultNItem,
  setdefaultNItemForMeal,
  getMealScheduleByID,
  isTemplate,
  defaultNItem,
  nutritionData,
  setTempData,
}) => {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const [mealName, setmealName] = useState(
    defaultNItem?.name ? defaultNItem.name : ""
  );
  const [mealDescription, setmealDescription] = useState(
    defaultNItem?.description ? defaultNItem.description : ""
  );
  const [isLoading, setIsLoading] = useState(false);
  const handleUpdateMeal = async () => {
    setIsLoading(true);
    try {
      let res = await axios({
        method: "post",
        url: `${APIURL}CF/api/mealSchedules/updateMealScheduleInfo`,
        data: {
          id: defaultNItem?.id,
          name: mealName,
          description: mealDescription,
        },
      });
      if (res?.data?.result) {
        console.log("update meal schedule result", res.data.result);
        const updatedArray = nutritionData.map((obj) => {
          // If the object's id matches, update the name and description
          if (obj.id === defaultNItem?.id) {
            return { ...obj, name: mealName, description: mealDescription };
          }
          return obj; // Otherwise, return the object as it is
        });
        console.log("nutrition data", defaultNItem);
        setNutritionData(updatedArray);
        setdefaultNItem(updatedArray[0]);
        getMealScheduleByID(updatedArray, updatedArray[0].id);
        setTempData(updatedArray);
      }
      setIsLoading(false);
      setIsOpen(false);
    } catch (err) {
      console.log("Not able to update the meal schedule.", err);
    }
  };
  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          width: "320px",
          borderRadius: "20px",
        },
      }}
      onClose={() => setIsOpen(false)}
    >
      {isLoading ? (
        <DialogContent>
          <SpurfitCircularProgress />
        </DialogContent>
      ) : (
        <>
          <DialogContent sx={{ padding: 0 }}>
            <div className="add-program-modal-head">
              <p className="add-program-modal-head-title">
                {isTemplate
                  ? "Update Template Meal Schedule"
                  : "Update Meal Schedule"}
              </p>
              <div className="cursor-pointer" onClick={() => setIsOpen(false)}>
                <CloseRounded
                  sx={{
                    fontSize: "24px",
                    color: "#000",
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
            <div className="add-program-modal-body">
              <div className="full-width-container">
                <label className="label-new">
                  {isTemplate ? "Meal Template Name*" : "Meal Name*"}
                </label>
                <input
                  placeholder={isTemplate ? "Meal Template Name" : "Meal Name"}
                  value={mealName}
                  onChange={(e) => setmealName(e.target.value)}
                  className="inputfiled-new"
                />
              </div>
              <textarea
                id="meal description"
                className="textarea-new"
                placeholder="Description (Optional)"
                type="text"
                onChange={(e) => {
                  setmealDescription(e.target.value);
                }}
                style={{
                  color: "black",
                }}
                rows={3}
                value={mealDescription}
              />
              <button
                style={{ marginTop: "8px" }}
                className="button-new"
                onClick={handleUpdateMeal}
              >
                {isTemplate ? "Update Template" : "Update Meal "}
              </button>
            </div>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

export default UpdateMealSchedule;
