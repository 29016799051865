import React from "react";
import Nutritions from "./NutritionEntry";
import { NutritionProvider } from "@/components/Nutrition/NutritionContext";

function NutritionPage(props) {
  return (
    <NutritionProvider>
      <Nutritions />
    </NutritionProvider>
  );
}

export default NutritionPage;
