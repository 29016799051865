import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";

import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Input } from "@/components/ui/input";
import CustomSelect from "@/components/WorkoutComponent/Endurance/util/CustomSelect";
import DurationInput from "./utils/DurationInput";
import SpecifyIntensity from "./utils/SpecifyIntensity";
import Targets from "@/components/WorkoutComponent/Endurance/util/Targets.jsx";
import { CheckCircle, Pencil, PencilLine } from "lucide-react";
import { Textarea } from "@/components/ui/textarea";
import _ from "lodash";
import { getIntensity } from "./utils/helpers";

const AddProperty = forwardRef(
  (
    {
      selectedData,
      selectedExercise,
      setSelectedData,
      selectedOption,
      setSelectedOption,
      selectedUnit,
      ratio,
      setRatio,
      workoutKind,
      selectedAthlete,
    },
    ref
  ) => {
    const [currentExercise, setCurrentExercise] = useState(selectedExercise);
    const [durationInputInSeconds, setDurationInputInSeconds] = useState(0);
    const [distance, setDistance] = useState(0);
    const [distanceUnit, setDistanceUnit] = useState("Meters");
    const [isLapButtonPressEnabled, setIsLapButtonPressEnabled] =
      useState(false);

    const [name, setName] = useState(selectedExercise?.name);
    const [editingName, setEditingName] = useState(false);

    const [notes, setNotes] = useState(selectedExercise?.movementInstr);

    const [isDistance, setIsDistance] = useState(false);

    const [intensityInfo, setIntensityInfo] = useState(null);

    useEffect(() => {
      if (selectedExercise && selectedExercise !== currentExercise) {
        // if (selectedExercise?.distance) {
        //   setSelectedOption("distance");
        // } else if (selectedExercise?.power) {
        //   setSelectedOption("power");
        // } else if (selectedExercise?.durationInSeconds) {
        //   setSelectedOption("duration");
        // }
        setCurrentExercise(selectedExercise);
        setDurationInputInSeconds(selectedExercise?.durationInSeconds);
        setDistance(selectedExercise?.distance);
        setDistanceUnit(selectedExercise?.distanceUnit || "Meters");
        setIsDistance(selectedExercise?.specifiedProperty === "distance");

        setSelectedOption(selectedExercise?.specifiedProperty ?? "duration");

        setIsLapButtonPressEnabled(selectedExercise?.lapButtonPress);
        setName(selectedExercise?.name);
        setEditingName(false);

        setNotes(selectedExercise?.movementInstr ?? "");
      }
    }, [selectedExercise]);

    useEffect(() => {
      let max = selectedExercise?.[selectedUnit]?.max;
      let min = selectedExercise?.[selectedUnit]?.min;

      setIntensityInfo({
        max: max,
        min: min,
      });
    }, [selectedExercise, selectedUnit]);

    const convertDistanceToKilometers = (distance, unit) => {
      switch (unit) {
        case "Meters":
          return distance / 1000;
        case "Miles":
          return distance * 1.60934;
        case "Kilometers":
        default:
          return distance;
      }
    };

    const onDistanceChange = (value) => {
      setDistance(value);
      if (value <= 0) return;
      let width = selectedData?.width;
      // increase the with in ratio of 100px = 1km
      let distance = value;
      let distanceUnit = selectedExercise?.distanceUnit;
      let distanceInKm = convertDistanceToKilometers(distance, distanceUnit);
      let newWidth = distanceInKm * 100;

      let newDuration = selectedExercise?.durationInSeconds;
      // update the duration based on the distance duration ratio
      if (newDuration) {
        newDuration =
          (newDuration /
            convertDistanceToKilometers(
              selectedExercise?.distance,
              distanceUnit
            )) *
          distanceInKm;
        // round off duration
        newDuration = Math.round(newDuration);
        setDurationInputInSeconds(newDuration);
      }
      setSelectedData({
        ...selectedData,
        width: newWidth,
        exercise: {
          ...selectedExercise,
          distance: value,
          distanceUnit: distanceUnit,
          durationInSeconds: newDuration,
        },
      });
    };

    const onDistanceUnitChange = (value) => {
      setDistanceUnit(value);

      let distance = selectedExercise?.distance;
      let distanceInKm = convertDistanceToKilometers(distance, value);
      let newWidth = distanceInKm * 100;

      let newDuration = selectedExercise?.durationInSeconds;
      // update the duration based on the distance duration ratio
      if (newDuration) {
        newDuration = (newDuration / selectedExercise?.distance) * distanceInKm;
        // round off duration
        newDuration = Math.round(newDuration);
        setDurationInputInSeconds(newDuration);
      }

      setSelectedData({
        ...selectedData,
        width: newWidth,
        exercise: {
          ...selectedExercise,
          distance: distance,
          distanceUnit: value,
          durationInSeconds: newDuration,
        },
      });
    };

    const debouncedOnAdd = useCallback(
      _.debounce((data) => {
        console.log("setting intensity", data);
        setIntensityInfo({
          min: data.value,
          max: data.maxValue,
        });
        console.log("selectedData", selectedData);
      }, 300), // Adjust the debounce delay as needed
      [setSelectedData, selectedData, selectedExercise, selectedUnit]
    );

    const getIntensityText = () => {
      let thresholdValues = selectedAthlete?.thresholdValues;
      let prevIntensity = getIntensity(
        {
          [selectedUnit]: intensityInfo,
        },
        selectedUnit
      );
      //selectedExercise?.intensity;

      console.log(
        `selectedUnit: ${selectedUnit}, selectedExercise: ${selectedExercise}`
      );

      if (!prevIntensity || !thresholdValues) return null;
      console.log("exersice", prevIntensity?.type);
      if (prevIntensity?.type) {
        let text = ``;
        if (thresholdValues) {
          // remove percentage from the text
          text = text.replace("%", "");
        }
        switch (prevIntensity?.type) {
          case "pace":
            return `${text} ${formatTime(prevIntensity?.value)} to ${formatTime(
              prevIntensity?.maxValue
            )} ${prevIntensity?.unit}`;
          case "cadence":
            return `${text} ${prevIntensity?.value} to ${prevIntensity?.maxValue} spm`;
          case "heartRateZone":
            return `${text} ${prevIntensity?.value}`;
          case "customHeartRate":
            return `${text} ${prevIntensity?.value} to ${prevIntensity?.maxValue} bpm`;
          case "thresholdPace":
            let thresholdPace = thresholdValues?.run_thresholds?.ftPace?.value;
            return calculateValuesFromThreshold(
              text,
              prevIntensity,
              thresholdPace,
              "min/km"
            );
          case "maxCustomHeartRate":
            let maxCustomHeartRate =
              thresholdValues?.maxHR?.value ?? thresholdValues?.maxHR;
            return calculateValuesFromThreshold(
              text,
              prevIntensity,
              maxCustomHeartRate,
              "bpm"
            );
          case "thresholdCustomHeartRate":
            let thresholdHeartRate =
              workoutKind === "run"
                ? thresholdValues?.run_thresholds?.ftHR?.value
                : thresholdValues?.bike_thresholds?.ftHR?.value;
            return calculateValuesFromThreshold(
              text,
              prevIntensity,
              thresholdHeartRate,
              "bpm"
            );
          // case "restingCustomHeartRate":
          //   return calculateValuesFromThreshold(text, prevIntensity, thresholdValues?.thresholdHeartRate, "bpm");
          case "FTP":
            let thresholdPower =
              thresholdValues?.bike_thresholds?.ftPower?.value;
            return calculateValuesFromThreshold(
              text,
              prevIntensity,
              thresholdPower,
              "W"
            );
          default:
            return null;
        }
      }
      return null;
    };

    const debouncedGetIntensityText = useCallback(
      _.debounce(getIntensityText, 300), // Adjust the debounce delay as needed
      [selectedAthlete, selectedExercise, selectedUnit, workoutKind]
    );

    const calculateValuesFromThreshold = (
      text,
      intensity,
      thresholdValue,
      unit
    ) => {
      if (!thresholdValue)
        return `${text} ${intensity.value} to ${intensity.maxValue} ${unit}`;

      let low = +thresholdValue * (+intensity.value / 100);
      let high = +thresholdValue * (+intensity.maxValue / 100);

      // if pace then format time
      if (intensity.type === "thresholdPace") {
        let originalPace = thresholdValue;
        let originalSpeed = 1 / originalPace;
        let percentageSpeed = originalSpeed * (+intensity.value / 100);
        high = 1 / percentageSpeed;

        percentageSpeed = originalSpeed * (+intensity.maxValue / 100);
        low = 1 / percentageSpeed;

        low = formatTime(low);
        high = formatTime(high);
      } else {
        low = Math.round(low);
        high = Math.round(high);
      }

      return `${text} ${low} to ${high} ${unit}`;
    };

    const limitDecimals = (value) => {
      try {
        if (!value || !isNaN(+value)) return value;
        // if not having decimals then return
        if (value % 1 === 0) return value;
        return Number(value).toFixed(2);
      } catch (error) {
        return value;
      }
    };

    const formatTime = (minutes) => {
      let mins = Math.floor(minutes);
      let secs = minutes % 1 ? Math.round((minutes % 1) * 60) : 0;
      return `${mins}:${secs < 10 ? "0" + secs : secs}`;
    };

    const applyChanges = () => {
      const currentMin = selectedExercise[selectedUnit]?.min;
      const currentMax = selectedExercise[selectedUnit]?.max;

      if (
        selectedExercise?.movementInstr !== notes ||
        currentMin !== intensityInfo.min ||
        currentMax !== intensityInfo.max
      ) {
        setSelectedData({
          ...selectedData,
          exercise: {
            ...selectedExercise,
            movementInstr: notes,
            [selectedUnit]: {
              ...(selectedExercise[selectedUnit] ?? {}),
              min: intensityInfo.min,
              max: intensityInfo.max,
            },
          },
        });
      }
    };

    // debounced function to add notes
    const debouncedAddNotes = useCallback(
      _.debounce((data) => {
        setSelectedData({
          ...selectedData,
          exercise: {
            ...selectedExercise,
            movementInstr: data,
          },
        });
        console.log("selectedData", selectedData);
      }, 300), // Adjust the debounce delay as needed
      [setSelectedData, selectedData, selectedExercise]
    );

    const baseOptions = [
      { label: "Warm Up", value: "WARMUP" },
      { label: "Rest", value: "REST" },
      { label: "Recovery", value: "RECOVERY" },
      { label: "Cooldown", value: "COOLDOWN" },
    ];

    const conditionalOptions =
      workoutKind === "run"
        ? [{ label: "Run", value: "Run" }]
        : workoutKind === "bike"
        ? [{ label: "Bike", value: "Bike" }]
        : workoutKind === "swim"
        ? [{ label: "Swim", value: "Swim" }]
        : [];

    const options = [...conditionalOptions, ...baseOptions];

    useImperativeHandle(ref, () => ({
      applyChanges,
    }));

    return (
      <div className="w-full h-full pb-4">
        {selectedExercise ? (
          <>
            <div
              name="header"
              className="flex items-center w-full h-[56px] px-6 border-b border-[#E5E5E5]"
            >
              {editingName ? (
                <div className="flex items-center border rounded-xl overflow-hidden">
                  <CustomSelect
                    isOpen={true}
                    triggerClassName="w-full h-[44px] focus-visible:ring-0 focus-visible:ring-offset-0 focus-visible:border-[#4339F2] border-none"
                    value={selectedExercise?.category}
                    onValueChange={(value) => {
                      setName(value);
                      setEditingName(false);
                      setSelectedData({
                        ...selectedData,
                        exercise: {
                          ...selectedExercise,
                          category: value,
                          type: value,
                          name: options.find((option) => option.value === value)
                            ?.label,
                        },
                      });
                    }}
                    options={options}
                  />
                  {/* <Input
                    key={selectedExercise?.id}
                    placeholder="Enter a name"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    className="h-[44px] border-none w-full focus-visible:ring-0 focus-visible:ring-offset-0 focus-visible:border-[#4339F2]"
                  />
                  <CheckCircle
                    size={32}
                    onClick={() => {
                      setEditingName(false);
                      setSelectedData({
                        ...selectedData,
                        exercise: {
                          ...selectedExercise,
                          name: name,
                        },
                      });
                    }}
                    className="px-2 cursor-pointer"
                  /> */}
                </div>
              ) : (
                <div className="w-full flex items-center gap-2">
                  <p className="text-font16 font-bold font-DMSans text-[#242424]">
                    {selectedExercise?.name}{" "}
                  </p>
                  <Pencil
                    size={32}
                    onClick={() => setEditingName(true)}
                    className="px-2 cursor-pointer"
                  />
                </div>
              )}
            </div>

            <div className="flex flex-col px-[24px] pt-[24px] gap-[24px]">
              {/* <CustomSelect
                triggerClassName="w-full h-[48px] focus:ring-0 focus:ring-offset-0 focus:border-[#4339F2] border-[1.5px] rounded-[12px]"
                onValueChange={(value) => {
                  if (value === "distance") {
                    setIsDistance(true);
                  } else {
                    setIsDistance(false);
                  }
                  console.log("setting specified property", value);
                  // update in selectedData also
                  setSelectedData({
                    ...selectedData,
                    exercise: {
                      ...selectedExercise,
                      specifiedProperty: value,
                    },
                  });
                }}
                options={[
                  { label: "Distance", value: "distance" },
                  { label: "Duration", value: "duration" },
                ]}
                value={isDistance ? "distance" : "duration"}
              /> */}

              <div className=" flex items-center py-1 gap-1">
                <Switch
                  checked={isLapButtonPressEnabled}
                  onCheckedChange={(value) => {
                    setIsLapButtonPressEnabled(value);
                    if (value) {
                      setSelectedOption(null);
                    } else {
                      setSelectedOption("distance");
                    }
                    console.log("setting lap button press", value);
                    setSelectedData({
                      ...selectedData,
                      exercise: {
                        ...selectedExercise,
                        lapButtonPress: value,
                      },
                    });
                  }}
                />
                <span className="font-DMSans text-font16 font-medium text-[#242424] ">
                  Specify Lap Button Press
                </span>
              </div>
              <div className="flex flex-col justify-center items-start gap-2 self-stretch">
                <p className="font-DMSans text-font16 text-black-pure font-bold">
                  Specify{" "}
                </p>
                <div className="flex items-center justify-between self-stretch">
                  <RadioGroup
                    className="flex w-full items-center justify-between self-stretch gap-2"
                    value={selectedOption}
                    onValueChange={(value) => {
                      setSelectedOption(value);
                      console.log("setting specified property", value);
                      // update specified property in selectedData also
                      setSelectedData({
                        ...selectedData,
                        exercise: {
                          ...selectedExercise,
                          specifiedProperty: value,
                        },
                      });
                    }}
                  >
                    <div className="flex items-center gap-2 py-1">
                      <RadioGroupItem value="distance" id="distance" />
                      <Label
                        htmlFor="distance"
                        className="font-DMSans text-font16 font-medium text-[#242424]"
                      >
                        Distance
                      </Label>
                    </div>
                    {/* <div className="flex items-center gap-2 py-1">
                                        <RadioGroupItem value="power" id="power"/>
                                        <Label
                                            htmlFor="power"
                                            className="font-DMSans text-font16 font-medium text-[#242424]"
                                        >
                                            Power
                                        </Label>
                                    </div> */}
                    <div className="flex items-center gap-2 py-1">
                      <RadioGroupItem value="duration" id="duration" />
                      <Label
                        htmlFor="duration"
                        className="font-DMSans text-font16 font-medium text-[#242424]"
                      >
                        Duration
                      </Label>
                    </div>
                  </RadioGroup>
                </div>
                {/* input and unit select */}
                {selectedOption && (
                  <>
                    {selectedOption === "duration" ? (
                      <DurationInput
                        durationInSeconds={durationInputInSeconds}
                        setDurationInSeconds={(value) => {
                          setDurationInputInSeconds(value);
                          if (!value) return;
                          let newWidth = selectedData?.width;
                          let duration = selectedExercise?.durationInSeconds;
                          let newDuration = value;
                          let distance = selectedExercise?.distance;
                          // update the distance based on the duration distance ratio
                          if (distance) {
                            distance = (distance / duration) * newDuration;
                            distance = distance.toFixed(2);
                            setDistance(distance);
                            newWidth = distance * 100;
                          }
                          // setDurationInputInSeconds(value);
                          console.log("setting duration", value);
                          setSelectedData({
                            ...selectedData,
                            width: newWidth,
                            exercise: {
                              ...selectedExercise,
                              durationInSeconds: value,
                              distance: distance,
                              distanceUnit: distanceUnit,
                            },
                          });
                        }}
                      />
                    ) : (
                      <div className="flex w-full items-center gap-2">
                        <Input
                          className="h-[48px] rounded-[12px] w-full focus-visible:ring-0 focus-visible:ring-offset-0 focus-visible:border-[#4339F2]"
                          placeholder="10"
                          type="number"
                          value={
                            selectedOption === "distance"
                              ? distance
                              : selectedOption === "power"
                              ? parseFloat(selectedExercise?.power)
                              : ""
                          }
                          onChange={(e) => {
                            if (selectedOption === "distance") {
                              let value = e.target.value;
                              onDistanceChange(value);
                            }
                          }}
                        />

                        {selectedOption === "distance" ? (
                          <CustomSelect
                            triggerClassName="w-full h-[48px] focus:ring-0 focus:ring-offset-0 focus:border-[#4339F2] border-[1.5px] rounded-[12px]"
                            onValueChange={(value) =>
                              onDistanceUnitChange(value)
                            }
                            options={distanceOptions}
                            value={distanceUnit}
                          />
                        ) : selectedOption === "power" ? (
                          <p className="w-full h-10 py-2 px-3 border border-[#E5E5E5] rounded-[12px] text-center text-font16 font-medium text-[#242424]">
                            W
                          </p>
                        ) : null}
                      </div>
                    )}
                  </>
                )}
              </div>
              <SpecifyIntensity
                onAdd={debouncedOnAdd}
                intensityInfo={intensityInfo}
                selectedExercise={selectedExercise}
                selectedUnit={selectedUnit}
                selectedAthlete={selectedAthlete}
                intensityText={getIntensityText()}
              />
              {/* {getIntensityText() && (
              <p className="font-DMSans text-font16 text-black-pure font-normal">
                {getIntensityText()}
              </p>
            )} */}
              {workoutKind === "bike" && (
                <div>
                  <p className="font-DMSans text-font16 text-black-pure font-bold">
                    Specify Secondary Target
                  </p>
                  <Targets
                    key={selectedExercise?.secondaryTarget}
                    prevTargets={selectedExercise?.secondaryTarget}
                    onAdd={(data) => {
                      setSelectedData({
                        ...selectedData,
                        exercise: {
                          ...selectedExercise,
                          secondaryTarget: data,
                        },
                      });
                    }}
                  />
                </div>
              )}
            </div>

            {/* add notes */}
            <div className="flex flex-col gap-2 px-[24px] pt-[24px]">
              <p className="font-DMSans text-font16 text-black-pure font-bold">
                Add Notes
              </p>
              <Textarea
                value={notes}
                onChange={(e) => {
                  setNotes(e.target.value);
                  // debouncedAddNotes(e.target.value);
                }}
                placeholder="Add notes"
                className="h-[96px] border-[1.5px] rounded-[12px] focus-visible:ring-0 focus-visible:ring-offset-0 focus-visible:border-[#4339F2]"
              />
            </div>

            <div className="p-[24px]">
              <button
                className="bg-gradient-to-r from-[#464FEB] to-[#8330E9] text-white-pure font-DMSans rounded-full text-font16 font-bold text-center w-full h-[44px] "
                onClick={applyChanges}
              >
                Apply Changes
              </button>
            </div>
          </>
        ) : (
          <div className="flex items-center justify-center w-full h-[56px] px-6 border-b border-[#E5E5E5]">
            <p className="text-font16 font-bold font-DMSans text-[#242424]">
              Select a block
            </p>
          </div>
        )}
      </div>
    );
  }
);

export default AddProperty;

const distanceOptions = [
  { label: "Meters", value: "Meters" },
  { label: "Kilometers", value: "Kilometers" },
  { label: "Miles", value: "Miles" },
];
