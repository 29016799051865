import { Dialog, DialogContent, DialogActions } from "@mui/material";
import React, { useContext, useState } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Input from "../../components/InputComponents/Input";
import { duplicateWorkout } from "./helper";
import { SpurfitCircularProgress } from "../../components/Dashboard/dashboard";
import TextArea from "../../components/InputComponents/TextArea";
import { useSelector } from "react-redux";
import { workoutContext } from "./WorkoutContex";


const DuplicateWorkoutDialog = ({
  isOpen,
  setIsOpen,
  workout,
 
  getWorkouts,
}) => {
  const {displayAlert}=useContext(workoutContext)
  const [title, setTitle] = useState(
    workout?.name ? workout?.name + " (copy)" : ""
  );
  const [description, setDescription] = useState(workout?.description || "");
  const [loading, setLoading] = useState(false);
  const coachID = useSelector((state) => state.auth?.trainerUUID);

  const closeDialog = () => {
    setLoading(false);
    setIsOpen(false);
  };

  return (
    <Dialog
      open={isOpen}
      sx={{
        backdropFilter: "blur(3px)",
        backgroundColor: "transparent",
      }}
      PaperProps={{
        sx: {
          minWidth: "320px",
          borderRadius: "20px",
        },
      }}
    >
      <DialogContent sx={{ padding: 0 }}>
        <div className="add-program-modal-head">
          <p className="add-program-modal-head-title">Duplicate Workout</p>
          <div
            style={{
              cursor: "pointer",
            }}
            onClick={() => setIsOpen(false)}
          >
            <CloseRoundedIcon />
          </div>
        </div>
        <div className="add-program-modal-body">
          <div
            className="full-width-container"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Input
              label={"Workout Name *"}
              value={title}
              onChange={(e) => {
                console.log("onChange", e.target.value);
                setTitle(e.target.value);
              }}
              placeholder={"Workout Name"}
              containerStyle={{ width: "100%" }}
            />
          </div>
          <div
            style={{
              width: "100%",
              background: "#FFFFFF",
              borderRadius: "8px",
            }}
            className="text-blue-main"
          >
            <TextArea
              placeholder={"Description (Optional)"}
              onChange={(event) => {
                setDescription(event.target.value);
              }}
              value={description}
              numRows={3}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", p: 0 }}>
        <button
          disabled={!title || title.length < 2 || loading}
          className="button-new mb-[24px]"
          onClick={() => {
            setLoading(true);
            console.log("coachID", coachID);
            duplicateWorkout(
              workout,
              title,
              description,
              getWorkouts,
              displayAlert,
              closeDialog,
              coachID
            );
          }}
        >
          {!loading ? "Add Workout" : <SpurfitCircularProgress size={24} />}
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default DuplicateWorkoutDialog;
