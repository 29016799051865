import React from "react";
import { StatsCard } from "../DescriptionComp/ClientProfileSummary";
import moment from "moment";
import { useSelector } from "react-redux";

const MembershipDetails = ({ userInfo }) => {
  const coaches = useSelector((state) => state.client.coaches);
  const coach = coaches?.find((coach) => coach.id === userInfo?.coachID);
  const calculateAge = (birthdate) => {
    const birthMoment = moment(birthdate);
    const age = moment().diff(birthMoment, "years");
    return age + " years";
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#FFFFFF",
        borderRadius: "10px",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.03)",
        height: "fit-content",
        width: "100%",
        minWidth: 300,
      }}
    >
      <div
        style={{
          padding: "12px 24px",
          borderBottom: "1px solid #EBEBEB",
        }}
      >
        <p
          style={{
            fontFamily: "DM Sans",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "29px",
          }}
        >
          Membership Details
        </p>
      </div>
      {/* Client ID
CLient Name 
Email
Phone number
Membership 
Renewal?
Referred by
Buddy User id
Veg/Non Veg
Age
Gender */}
      <StatsCard
        title={"Coach Name"}
        value={coach?.name || "-"}
        icon={
          <img
            width="20px"
            height={"20px"}
            src="https://img.icons8.com/?size=100&id=98957&format=png&color=000000"
            alt="deadlift--v1"
          />
        }
      />
      <StatsCard
        title={"Client ID"}
        value={userInfo?.clientID_LivEzy}
        icon={
          <img
            width="20px"
            height={"20px"}
            src="https://img.icons8.com/?size=100&id=58125&format=png&color=000000"
            alt="deadlift--v1"
          />
        }
      />

      <StatsCard
        title={"Email"}
        value={userInfo?.email}
        icon={
          <img
            width="20px"
            height={"20px"}
            src="https://img.icons8.com/?size=100&id=mtfWz20b5AxB&format=png&color=000000"
            alt="deadlift--v1"
          />
        }
      />

      <StatsCard
        title={"Phone number"}
        value={userInfo?.contactNumber}
        icon={
          <img
            width="20px"
            height={"20px"}
            src="https://img.icons8.com/?size=100&id=2olGSGqpqGWD&format=png&color=000000"
            alt="deadlift--v1"
          />
        }
      />

      <StatsCard
        title={"Membership"}
        value={userInfo?.planName_LivEzy}
        icon={
          <img
            width="20px"
            height={"20px"}
            src="https://img.icons8.com/?size=100&id=SqNJJRGFZzys&format=png&color=000000"
            alt="deadlift--v1"
          />
        }
      />

      <StatsCard
        title={"Renewal"}
        value={userInfo?.renewal_LivEzy === "true" ? "Yes" : "No"}
        icon={
          <img
            width="20px"
            height={"20px"}
            src="https://img.icons8.com/?size=100&id=cprPnPRezEzC&format=png&color=000000"
            alt="deadlift--v1"
          />
        }
      />

      <StatsCard
        title={"Referred by"}
        value={userInfo?.referral_LivEzy}
        icon={
          <img
            width="20px"
            height={"20px"}
            src="https://img.icons8.com/?size=100&id=kid6pkQ5QsYS&format=png&color=000000"
            alt="deadlift--v1"
          />
        }
      />

      <StatsCard
        title={"Buddy User id"}
        value={userInfo?.buddyClientID_LivEzy}
        icon={
          <img
            width="20px"
            height={"20px"}
            src="https://img.icons8.com/?size=100&id=whKuLaQa1cG1&format=png&color=000000"
            alt="deadlift--v1"
          />
        }
      />

      <StatsCard
        title={"Veg/Non Veg"}
        value={userInfo?.dietaryPreferences_LivEzy}
        icon={
          <img
            width="20px"
            height={"20px"}
            src="https://img.icons8.com/?size=100&id=UzLL3zhorD8H&format=png&color=000000"
            alt="deadlift--v1"
          />
        }
      />

      <StatsCard
        title={"Age"}
        value={userInfo?.birthDate ? calculateAge(userInfo?.birthDate) : "-"}
        icon={
          <img
            width="20px"
            height={"20px"}
            src="https://img.icons8.com/?size=100&id=7zkG0i0pLOGd&format=png&color=000000"
            alt="deadlift--v1"
          />
        }
      />

      <StatsCard
        title={"Gender"}
        value={userInfo?.gender}
        icon={
          <img
            width="20px"
            height={"20px"}
            src="https://img.icons8.com/?size=100&id=70834&format=png&color=000000"
            alt="deadlift--v1"
          />
        }
      />
    </div>
  );
};

export default MembershipDetails;
