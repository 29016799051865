import React, { useContext, useEffect, useRef, useState } from "react";
import { ProgramsContext } from "./ProgramsContext";

import { useSelector } from "react-redux";
import { AddWorkoutsModal } from "./AddWorkoutsModal";
import { Trash, Copy, ClipboardPaste, X } from "lucide-react";

import AutoMessageDialog from "@/pages/library/programsCF/AutoMessageDialog";
import AddActivityDialog from "@/components/DescriptionComp/AddActivityDialog";
import AddCardioDialog from "@/pages/library/programsCF/AddCardioDialog";
import ShowWorkoutDialog from "@/pages/library/programsCF/ShowWorkoutDialog";
import { ReplaceWorkout } from "./ReplaceWorkoutsModal";

import ProgramWeeksLoadingUI from "./ProgramWeeksLoadingUI";
import { DayCard } from "./DayCard";
import WorkoutBuilderModal from "@/components/WorkoutComponent/WorkoutBuilderModal";
import moment from "moment";
import { Button } from "@mui/material";

const ProgramWeeks = ({
  weeksArray,
  daysArray,
  weeks,
  daysMessages,
  disableActions,
  ...props
}) => {
  const {
    selectedProgramData,
    setUnsavedChanges,
    selectedCard,
    setSelectedCard,
    showAddWorkoutModal,
    setShowAddWorkoutModal,
    addedWorkouts,
    setAddedWorkouts,
    setDaysMessages,
    setDaysTargets,
    daysTargets,
    showAutoMessageDialog,
    setShowAutoMessageDialog,
    showAddActivityModal,
    setShowAddActivityModal,
    showAddCardio,
    setShowAddCardio,
    saveProgramOnEditWorkout,
    showWorkout,
    setShowWorkout,
    selectedToShow,
    setSelectedToShow,
    originalDays,
    originalWeeks,
    programSubscribers,
    programChannels,
    deleteOneWeek,
    duplicateOneWeek,
    handleDeleteLastWeekProgramWithSubscribers,
    copiedDay,
    setCopiedDay,
    handlePaste,
    selectedProgram,
    loadingSelectedProgram,
    copiedWorkout,
    setCopiedWorkout,
    handlePasteOneWorkout,
    handleReplaceOneWorkout,
    deleteOneWorkoutFromADay,
    setSelectedProgramData,
    pastedData,
    setPastedData,
    copiedWeekNo,
    setCopiedWeekNo,
    handlePasteOneWeek,
  } = useContext(ProgramsContext);
  const programsStates = useSelector((state) => state.programs.programsStates);

  const [showReplaceWorkout, setShowReplaceWorkout] = useState(false);
  const [selectedWorkoutData, setSelectedWorkoutData] = useState({});
  const [showWorkoutBuilderModal, setShowWorkoutBuilderModal] = useState(false);
  const [workoutInfoData, setWorkoutInfoData] = useState({});
  const addWorkoutRef = useRef();
  const addRest = () => {
    let rest = {
      rest: true,
    };
    setAddedWorkouts((prev) => {
      return {
        ...prev,
        [selectedCard]: [rest],
      };
    });
    setUnsavedChanges(true);
  };

  const deleteFullDay = (day) => {
    setAddedWorkouts((prev) => {
      let res = { ...prev };
      if (res.hasOwnProperty(day)) {
        delete res[day];
      }
      return res;
    });

    setDaysMessages((prev) => {
      let res = { ...prev };
      if (res.hasOwnProperty(day)) {
        delete res[day];
      }
      return res;
    });

    setDaysTargets((prev) => {
      let res = { ...prev };
      if (res.hasOwnProperty(day)) {
        delete res[day];
      }
      return res;
    });

    setUnsavedChanges(true);
  };

  const onOptionSelect = (option) => {
    if (option === "Add Rest") {
      addRest();
    } else if (option === "Add Cardio") {
      setShowAddCardio(true);
    } else if (option === "Add Workout" || option === "Edit Workout") {
      setShowAddWorkoutModal(true);
    } else if (option === "Auto Messages") {
      setShowAutoMessageDialog(true);
    } else if (option === "Add Activity") {
      setShowAddActivityModal(true);
    } else if (option === "Remove Cardio") {
      let updatedAddedWorkouts = { ...addedWorkouts };
      let cardioIndex = updatedAddedWorkouts?.[selectedCard]?.findIndex(
        (w) => w?.name === "cardio"
      );
      if (cardioIndex > -1) {
        updatedAddedWorkouts[selectedCard].splice(cardioIndex, 1);
      }

      setAddedWorkouts(updatedAddedWorkouts);
    } else if (option === "Remove Rest") {
      setAddedWorkouts((prev) => {
        let res = prev || {};
        if (res?.[selectedCard]?.[0]?.rest) {
          delete res[selectedCard];
        }
        return res;
      });
    }
  };

  if (loadingSelectedProgram) {
    return <ProgramWeeksLoadingUI />;
  }

  const setNewCheckedItems = (item) => {
    if (addWorkoutRef?.current) {
      addWorkoutRef?.current.addWorkoutToCheckList(item);
    }
    console.log("item ", item);
  };
  return (
    <div
      ref={props?.scrollDownRef}
      className="w-full overflow-y-scroll"
      style={{
        height: "calc(100vh - 360px)",
      }}
    >
      {weeksArray.map((week, index) => {
        let weekDays = daysArray.slice(index * 7, (index + 1) * 7);
        return (
          <div key={index} className="flex flex-col gap-2 mx-2 relative">
            {/* if copied week is current week then show overlay with cross icon on top right to cancel copy */}
            {copiedWeekNo === week && (
              <div className="absolute w-full h-full flex items-center justify-center bg-black-pure/80 z-50 rounded-lg">
                <div className="absolute top-2 right-2">
                  <X
                    size={20}
                    color="#fff"
                    className="cursor-pointer"
                    onClick={() => setCopiedWeekNo(null)}
                  />
                </div>

                <p className="text-white-pure text-center font-DMSans font-semibold text-lg">
                  Week Copied
                </p>
              </div>
            )}
            <div className="flex items-center justify-between mt-2">
              <p className="font-DMSans text-font14 font-semibold ">
                Week {index + 1}
              </p>

              {!disableActions && (
                <div className={`flex items-center gap-3`}>
                  {/* delete week */}
                  {programSubscribers?.length > 0 ? (
                    week >= originalWeeks && (
                      <button
                        onClick={() => {
                          //delete current week
                          if (week === originalWeeks) {
                            handleDeleteLastWeekProgramWithSubscribers(
                              selectedProgramData?.id
                            );
                          } else {
                            deleteOneWeek(week);
                          }
                        }}
                      >
                        <Trash size={16} color="#e44e4e" />
                      </button>
                    )
                  ) : (
                    <button
                      onClick={() => {
                        //delete current week
                        deleteOneWeek(week);
                      }}
                    >
                      <Trash size={16} color="#e44e4e" />
                    </button>
                  )}
                  {/* copy week */}
                  {/* {!selectedProgram?.cFProgramPhasesContainerId && ( */}
                  {copiedWeekNo ? (
                    <button
                      onClick={() => {
                        handlePasteOneWeek(copiedWeekNo - 1, week - 1);
                      }}
                    >
                      <ClipboardPaste size={16} color="#000" />
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        //copy current week
                        if (programSubscribers?.length > 0) {
                          duplicateOneWeek(week);
                        } else {
                          setCopiedWeekNo(week);
                        }
                      }}
                    >
                      <Copy size={16} color="#000" />
                    </button>
                  )}
                  {/* )} */}
                </div>
              )}
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(7, 1fr)",
                gap: "1rem",
              }}
              className=" overflow-scroll"
            >
              {weekDays.map((day) => {
                let workout = addedWorkouts[day];
                let message = daysMessages[day];
                let target = daysTargets[day];

                let isCardio = (workout ?? []).find(
                  (w) => w?.name === "cardio"
                );
                const isRest = workout?.[0]?.rest;
                let isWorkoutOtherThanCardio =
                  workout?.length > 1 ||
                  (!isCardio && workout?.length > 0 && !isRest);

                const isTodayEmpty =
                  (!workout || workout.length == 0) && !message && !target;
                const isAssignedDay =
                  day <= originalDays && programSubscribers?.length > 0;
                let lastDayOfWeek = weeks * 7;
                const shouldShowCopy =
                  !programSubscribers?.length ||
                  programSubscribers?.length === 0 ||
                  originalDays < lastDayOfWeek;

                return (
                  <DayCard
                    day={day}
                    copiedDay={copiedDay}
                    setCopiedDay={setCopiedDay}
                    handlePaste={handlePaste}
                    handlePasteOneWorkout={handlePasteOneWorkout}
                    isTodayEmpty={isTodayEmpty}
                    copiedWorkout={copiedWorkout}
                    setCopiedWorkout={setCopiedWorkout}
                    disableActions={disableActions}
                    shouldShowCopy={shouldShowCopy}
                    workout={workout}
                    message={message}
                    target={target}
                    isAssignedDay={isAssignedDay}
                    deleteFullDay={deleteFullDay}
                    setSelectedCard={setSelectedCard}
                    isWorkoutOtherThanCardio={isWorkoutOtherThanCardio}
                    isCardio={isCardio}
                    isRest={isRest}
                    onOptionSelect={onOptionSelect}
                    setSelectedToShow={setSelectedToShow}
                    setShowAddCardio={setShowAddCardio}
                    setShowWorkout={setShowWorkout}
                    setSelectedWorkoutData={setSelectedWorkoutData}
                    setShowAutoMessageDialog={setShowAutoMessageDialog}
                    setShowAddActivityModal={setShowAddActivityModal}
                    handleReplaceOneWorkout={handleReplaceOneWorkout}
                    pastedData={pastedData}
                    setPastedData={setPastedData}
                  />
                );
              })}
            </div>
          </div>
        );
      })}

      {showAddWorkoutModal && (
        <AddWorkoutsModal
          ref={addWorkoutRef}
          programName={selectedProgramData?.name}
          programId={selectedProgramData?.id}
          programsStates={programsStates}
          dataToSave={{
            daysArray,
            weeksArray,
            addedWorkouts,
            daysMessages,
            daysTargets,
            selectedProgram,
            selectedProgramData,
            weeks,
          }}
          selectedDay={selectedCard}
          setShowWorkoutBuilderModal={setShowWorkoutBuilderModal}
          setWorkoutInfo={setWorkoutInfoData}
        />
      )}

      {showAutoMessageDialog && (
        <AutoMessageDialog
          isOpen={showAutoMessageDialog}
          setIsOpen={setShowAutoMessageDialog}
          selectedCard={selectedCard}
          programName={selectedProgramData?.name}
          programId={selectedProgramData?.id}
          addedWorkouts={addedWorkouts}
          setAddedWorkouts={setAddedWorkouts}
          previousMessages={daysMessages?.[selectedCard] || {}}
          setSelectedCard={setSelectedCard}
          daysMessages={daysMessages}
          onDone={(dayMessages, dayNum) => {
            setDaysMessages((prev) => {
              console.log("day messages: ", {
                ...prev,
                [dayNum]: dayMessages,
              });
              return { ...prev, [dayNum]: dayMessages };
            });
            setUnsavedChanges(true);
          }}
        />
      )}

      {showAddActivityModal && (
        <AddActivityDialog
          isOpen={showAddActivityModal}
          setIsOpen={setShowAddActivityModal}
          assignedActivities={daysTargets?.[selectedCard]}
          key={"programs"}
          isUpdate={daysTargets?.[selectedCard] ? true : false}
          onRemoveActivity={() => {
            setDaysTargets((prev) => {
              let res = { ...(prev || {}), [selectedCard]: undefined };
              console.log("activity: ", res);
              return res;
            });
            setUnsavedChanges(true);
          }}
          onSaveActivity={(activity) => {
            setDaysTargets((prev) => {
              let res = { ...(prev || {}), [selectedCard]: activity };
              console.log("activity: ", res);
              return res;
            });
            setUnsavedChanges(true);
          }}
          comingFrom={"PROGRAMS"}
        />
      )}

      {showAddCardio && (
        <AddCardioDialog
          key={selectedCard}
          isOpen={showAddCardio}
          setIsOpen={setShowAddCardio}
          day={selectedCard}
          info={
            selectedCard === selectedWorkoutData?.workoutDay && selectedToShow
          }
          onAddCardio={(cardio) => {
            setAddedWorkouts((prev) => {
              if (
                selectedToShow?.name === "cardio" &&
                selectedWorkoutData?.workoutIndex
              ) {
                let index = selectedWorkoutData?.workoutIndex;
                let day = selectedWorkoutData?.workoutDay;
                let res = prev || {};
                res[day][index] = cardio;
                return res;
              }
              const newWorkouts = Array.isArray(prev?.[selectedCard])
                ? [...prev[selectedCard]]
                : [];

              if (newWorkouts.length > 0 && newWorkouts[0].rest) {
                newWorkouts[0] = cardio;
              } else {
                let cardioIndex = newWorkouts.findIndex(
                  (w) => w?.name === "cardio"
                );
                if (cardioIndex > -1) {
                  newWorkouts[cardioIndex] = cardio;
                } else {
                  newWorkouts.push(cardio);
                }
              }

              const updatedState = { ...prev, [selectedCard]: newWorkouts };
              return updatedState;
            });
            setUnsavedChanges(true);
          }}
        />
      )}

      {showWorkout && (
        <ShowWorkoutDialog
          isOpen={showWorkout}
          setIsOpen={setShowWorkout}
          Workout={selectedToShow}
          onEdit={async () => {
            // save workout
            setUnsavedChanges(false);
            await saveProgramOnEditWorkout();
          }}
          onReplacePress={(workout, replaceIndex) => {
            setShowReplaceWorkout(true);
            setShowWorkout(false);
          }}
          addedWworkouts={addedWorkouts}
          setAddedWworkouts={setAddedWorkouts}
          editWorkoutDay={"jatin"}
          replacementData={selectedWorkoutData}
          programAssignedChannels={programChannels}
          programAssignedUsers={programSubscribers}
          handleDeleteWorkout={() => {
            deleteOneWorkoutFromADay(
              selectedWorkoutData?.workoutDay,
              selectedWorkoutData?.workoutIndex
            );
            setShowWorkout(false);
          }}
          originalDay={originalDays}
          setShowWorkoutBuilderModal={setShowWorkoutBuilderModal}
          setIsNewWorkoutFalse={() => {
            setWorkoutInfoData({});
          }}
        />
      )}
      {showWorkoutBuilderModal && (
        <WorkoutBuilderModal
          open={showWorkoutBuilderModal}
          setOpen={setShowWorkoutBuilderModal}
          data={
            workoutInfoData?.isNewWorkout
              ? { ...workoutInfoData?.workoutInfo }
              : selectedToShow
          }
          setSelectedToShow={(data) => {
            let workouts = [...addedWorkouts?.[selectedCard]];
            workouts?.map((item, index) => {
              if (item?.id == data?.id) {
                workouts[index] = data;
              }
            });
            setAddedWorkouts((prev) => {
              return {
                ...prev,
                [selectedCard]: workouts,
              };
            });
          }}
          isImport={undefined}
          isCalendar={undefined}
          isUpdate={workoutInfoData?.isNewWorkout ? false : true}
          extraData={{
            userUUID: `${props?.userUUID}`,
            moduleDate: `${moment(props?.date, "DD-MM-YYYY")
              .startOf("day")
              .format("YYYY-MM-DDTHH:mm:ss")}Z`,
            trackingID: props?.workoutID,
          }}
          setNewCheckedItems={setNewCheckedItems}
        />
      )}
      {showReplaceWorkout && (
        <ReplaceWorkout
          programName={selectedProgramData?.name}
          programId={selectedProgramData?.id}
          programsStates={programsStates}
          dataToSave={{}}
          showModal={showReplaceWorkout}
          setShowModal={setShowReplaceWorkout}
          replacementData={selectedWorkoutData}
          onReplaceWorkout={(workout) => {
            setAddedWorkouts((prev) => {
              let res = prev || {};
              let day = selectedWorkoutData?.workoutDay;
              let index = selectedWorkoutData?.workoutIndex;
              res[day][index] = workout;
              return res;
            });
            setSelectedWorkoutData(undefined);
            setUnsavedChanges(true);
            setShowReplaceWorkout(false);
          }}
        />
      )}
      {/* <button
        onClick={() => {
          console.log(newCheckedItems);
        }}
      >
        show data
      </button> */}
    </div>
  );
};

export default ProgramWeeks;
