import { store } from "@/redux/store";
import axios from "axios";
import moment from "moment";

export const getAllProgramsByCoachID = async (coachID, source) => {
  let res = await axios({
    method: "get",
    url: `${store.getState().auth.apiURL}CF/api/getAllProgramsByCoach`,
    params: {
      coachID: coachID,
    },
    cancelToken: source?.token,
  });

  return res?.data?.result || [];
};

export const getAllProgramsWithPhasesByCoachID = async (coachID) => {
  let res = await axios({
    method: "get",
    url: `${
      store.getState().auth.apiURL
    }CF/api/programs/getProgramPhasesContainer`,
    params: {
      coachID: coachID,
    },
  });

  return res?.data?.result || [];
};

export const getAllProgram = async (coachID, adminID, signal) => {
  const source = axios.CancelToken.source();
  signal?.addEventListener("abort", () => {
    source.cancel("Operation canceled by the user.");
  });

  let coachPrograms = await getAllProgramsByCoachID(coachID, source);
  let coachProgramsWithPhases = await getAllProgramsWithPhasesByCoachID(
    coachID
  );
  let adminPrograms = [];
  let adminProgramsByCoach = [];
  if (adminID) {
    adminPrograms = await getAllProgramsByCoachID(adminID, source);
    adminProgramsByCoach = await getAllProgramsWithPhasesByCoachID(adminID);
  }
  const tempCoachPrograms = coachPrograms.filter(
    (item) => item?.cFProgramPhasesContainerId === null
  );

  return [
    ...coachProgramsWithPhases,
    ...adminProgramsByCoach,
    ...tempCoachPrograms,
    ...adminPrograms,
  ];
};

export const assignPrograms = async (programID, userIDs, startDate) => {
  await axios({
    method: "post",
    url: `${store.getState().auth.apiURL}CF/api/assignProgramSegmentedMultiple`,
    data: {
      programID: programID,
      userUUIDs: userIDs,
      startDate: `${moment(startDate)
        .startOf("day")
        .format("YYYY-MM-DD")}T00:00:00.000Z`,
      startProgDay: parseInt(1),
    },
  });
};

export const deleteAssignedProgram = async (
  athleteId,
  programId,
  dateAssigned
) => {
  let res = await axios({
    method: "POST",
    url: `${
      store.getState().auth.apiURL
    }CF/api/deleteAssignedProgramByProgramID`,
    data: {
      athleteId,
      programId,
      dateAssigned,
    },
  });
};

export const deleteAssignedProgramMultiple = async (
  athleteIds,
  programId,
  dateAssigned
) => {
  let res = await axios({
    method: "POST",
    url: `${
      store.getState().auth.apiURL
    }CF/api/deleteAssignedProgramByProgramIDMultiple`,
    data: {
      athleteIds,
      programId,
      dateAssigned,
    },
  });
};

export const getProgramByAdminID = async (adminID) => {
  let res = await axios({
    method: "get",
    url: `${
      store.getState().auth.apiURL
    }CF/api/programs/getProgramsForAllCoachesUnderAdmin`,
    params: {
      adminID: adminID,
    },
  });

  return res?.data?.result || [];
};

export const getFoldersByAdminID = async (adminID) => {
  try {
    let res = await axios({
      method: "get",
      url: `${
        store.getState().auth.apiURL
      }CF/api/folders/getFoldersForAllCoachesUnderAdmin`,
      params: {
        adminID: adminID,
      },
    });

    return res?.data?.result || [];
  } catch (error) {
    return [];
  }
};

export const getProgramsByCoachIDs = async (coachIDs) => {
  try {
    let res = await axios({
      method: "post",
      url: `${
        store.getState().auth.apiURL
      }CF/api/programs/getProgramsForAllCoaches`,
      data: {
        coachIDs: coachIDs,
      },
    });

    return res?.data?.result || [];
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const getProgramByID = async (programID) => {
  try {
    let res = await axios({
      method: "get",
      url: `${store.getState().auth.apiURL}CF/api/getProgramByID`,
      params: {
        programID: programID,
      },
    });

    return res?.data?.result || {};
  } catch (error) {
    return {};
  }
};

export const getProgramSubscribers = async (programID) => {
  let res = await axios({
    method: "get",
    url: `${store.getState().auth.apiURL}CF/api/getUsersForProgramID`,
    params: {
      programID: programID,
    },
  });

  return res?.data?.result || [];
};

export const getPhasedProgramSubscribers = async (programID) => {
  let res = await axios({
    method: "get",
    url: `${
      store.getState().auth.apiURL
    }CF/api/programPhases/getSubscribersForPhasedProgram`,
    params: {
      phaseContainerID: programID,
    },
  });

  return res?.data?.result || [];
};

export const deleteProgram = async (programID) => {
  let res = await axios({
    method: "post",
    url: `${store.getState().auth.apiURL}CF/api/deletemodularprogram`,
    data: {
      programID: programID,
    },
  });
  return res?.data?.message === "Deleted succesfully" ? true : false;
};

export const duplicateProgram = async (
  programID,
  name,
  description,
  coachID,
  containerID,
  isTemplate
) => {
  let res = await axios({
    method: "post",
    url: `${store.getState().auth.apiURL}CF/api/duplicateProgram`,
    data: {
      programID: programID,
      programName: name,
      description,
      isTemplate: isTemplate,
      coachID: coachID,
      containerID: containerID,
    },
  });

  return res?.data?.result;
};

export const deleteLastWeekWithSubscribers = async (workoutIDs) => {
  const res = await axios({
    method: "post",
    url: `${
      store.getState().auth.apiURL
    }CF/api/deleteLastWeekProgramWithSubscribers`,
    data: {
      cFProgramsWithWorkoutsIds: workoutIDs,
    },
  });

  return res?.data?.result;
};

export const deleteLastWeekOfAssignedProgram = async (
  workoutIDs,
  programID,
  duration
) => {
  const res = await axios({
    method: "post",
    url: `${
      store.getState().auth.apiURL
    }CF/api/deleteLastWeekOfAssignedProgram`,
    data: {
      programID,
      cFProgramsWithWorkoutsIds: workoutIDs,
      duration,
    },
  });

  return res.status === 200;
};

export const updateProgramInfo = async (
  programID,
  name,
  description,
  notes
) => {
  let res = await axios({
    method: "post",
    url: `${store.getState().auth.apiURL}CF/api/programs/updateProgramInfo`,
    data: {
      id: programID,
      name: name,
      description: description,
      notes: notes,
    },
  });

  return res?.data?.result;
};

export const updateProgramPhaseContainer = async (data) => {
  let res = await axios({
    method: "post",
    url: `${
      store.getState().auth.apiURL
    }CF/api/programs/updateProgramPhaseContainer`,
    data: data,
  });

  return res?.data?.result;
};

export const saveProgramData = async (data) => {
  let res = await axios({
    method: "POST",
    url: `${store.getState().auth.apiURL}CF/api/saveProgram`,
    data: data,
  });

  return res;
};

export const uploadPdf = async (data) => {
  let res = await axios({
    method: "POST",
    url: `${store.getState().auth.apiURL}CF/api/uploadPdfToS3`,
    data: data,
  });

  return res?.data?.result;
};

export const assignPhases = async (data) => {
  let res = await axios({
    method: "POST",
    url: `${
      store.getState().auth.apiURL
    }CF/api/programPhases/assignPhasesSegmentedMultiple`,
    data: data,
  });

  return res?.data?.result;
};

export const createFolder = async (data) => {
  let res = await axios({
    method: "post",
    url: `${store.getState().auth.apiURL}CF/api/folders/createProgramFolder`,
    data: data,
  });
  return res?.data;
};

export const getAllFolder = async (coachID, adminID) => {
  let trainer = await axios({
    method: "get",
    url: `${store.getState().auth.apiURL}CF/api/folders/getAllProgramFolders`,
    params: {
      coachID: coachID,
    },
  }).catch((err) => {
    console.log(err);
  });

  let admin = adminID
    ? await axios({
        method: "get",
        url: `${
          store.getState().auth.apiURL
        }CF/api/folders/getAllProgramFolders`,
        params: {
          coachID: adminID,
        },
      }).catch((err) => {
        console.log(err);
      })
    : [];

  let all = [...(trainer?.data ?? []), ...(admin?.data ?? [])];

  return all;
};

export const connectItemToFolder = async (data) => {
  let res = await axios({
    method: "post",
    url: `${
      store.getState().auth.apiURL
    }CF/api/folders/connectItemsToProgramFolder`,
    data: data,
  });

  return res;
};

export const updateFolderDetails = async (data) => {
  let res = await axios({
    method: "post",
    url: `${store.getState().auth.apiURL}CF/api/folders/updateProgramFolder`,
    data: data,
  });

  return res;
};

export const deleteFolder = async (data) => {
  let res = await axios({
    method: "post",
    url: `${store.getState().auth.apiURL}CF/api/folders/deleteProgramFolder`,
    data: data,
  });

  return res;
};

export const disconnectItemFromFolder = async (data) => {
  let res = await axios({
    method: "post",
    url: `${
      store.getState().auth.apiURL
    }CF/api/folders/disconnectItemsFromProgramFolder`,
    data: data,
  });

  return res;
};

export const shiftingFromFolder = async (data) => {
  if (data.hasOwnProperty("programIDs")) {
    let res = await axios({
      method: "post",
      url: `${
        store.getState().auth.apiURL
      }CF/api/folders/transferProgramsToFolder`,
      data: data,
    });

    return res;
  } else {
    let res = await axios({
      method: "post",
      url: `${
        store.getState().auth.apiURL
      }CF/api/folders/transferContainersToFolder`,
      data: data,
    });

    return res;
  }
};

export const importPhaseToContainer = async (data) => {
  let res = await axios({
    method: "post",
    url: `${
      store.getState().auth.apiURL
    }CF/api/programs/importProgramInPhaseContainer`,
    data: data,
  });

  return res;
};

export const duplicatePhasedProgram = async (data) => {
  let res = await axios({
    method: "post",
    url: `${
      store.getState().auth.apiURL
    }CF/api/programs/duplicatePhasedPrograms`,
    data: data,
  });
  return res;
};

export const saveProgramDataAndConnectToFolder = async (data) => {
  let res = await axios({
    method: "POST",
    url: `${
      store.getState().auth.apiURL
    }CF/api/folders/connectAndSaveItemWithProgram`,
    data: data,
  });

  return res;
};

export const savePhaseContainerAndConnectToFolder = async (data) => {
  let res = await axios({
    method: "POST",
    url: `${
      store.getState().auth.apiURL
    }CF/api/folders/connectAndSaveItemWithPhaseContainer`,
    data: data,
  });

  return res;
};

export const deleteAssignedPrograms = async (selectedPrograms) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${
        store.getState().auth.apiURL
      }CF/api/deleteAssignedProgramforUsers`,
      data: {
        programTrackerIDs: selectedPrograms,
      },
    });
    console.log("deleted successfully");
    return { success: true, message: "Deleted successfully" };
  } catch (err) {
    console.log("error in deleting");
    return { success: false, message: "Error in deleting" };
  }
};

export const deleteAssignedPhasedPrograms = async (selectedPrograms) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${
        store.getState().auth.apiURL
      }CF/api/programPhases/unasssignPhasedPrograms`,
      data: {
        phaseTrackerIDs: selectedPrograms,
      },
    });
    console.log("deleted successfully");
    return { success: true, message: "Deleted successfully" };
  } catch (err) {
    console.log("error in deleting");
    return { success: false, message: "Error in deleting" };
  }
};

export const unassignPhasedProgramsByPhaseContainerID = async (
  containerID,
  atheleteID
) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${
        store.getState().auth.apiURL
      }CF/api/programPhases/unassignPhasedProgramsByPhaseContainerID`,
      data: {
        phaseContainerID: containerID,
        atheleteID: atheleteID,
      },
    });
    console.log("deleted successfully");
    return { success: true, message: "Deleted successfully" };
  } catch (err) {
    console.log("error in deleting");
    return { success: false, message: "Error in deleting" };
  }
};
