import React from "react";

import appleLogo from "../assets/appstore.png";
import playstore from "../assets/googleplay.png";
import { Container } from "@mui/material";

const NotFound = () => (
  <Container>
    <div style={{ marginTop: 150 }}></div>

    {/* <h1 style={{ textAlign: "center", fontSize: 20 }}>
      {" "}
      Your plan is live now!{" "}
    </h1> */}
    <h3 style={{ textAlign: "center", marginTop: 50 }}>
      Download the
      <span style={{ color: "#FF9800" }}> Spur.fit app </span>to start training
    </h3>

    <div style={{ display: "flex", flexDirection: "row", marginTop: 50 }}>
      <a href="https://apps.apple.com/us/app/spur-fit/id1516471995">
        <img
          src={appleLogo}
          // onClick={handleVideoModal}
          alt="Microsoft"
          style={{
            width: 200,
            height: 57,
            display: "block",
            textAlign: "center",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: 20,
          }}
        />
      </a>
      <a href="https://play.google.com/store/apps/details?id=com.getfitainative.fitnessassistant&hl=en_US&gl=US">
        <img
          src={playstore}
          // onClick={handleVideoModal}
          alt="Microsoft"
          style={{
            width: 200,
            height: 77,
            marginTop: 10,
            display: "block",
            textAlign: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
      </a>
    </div>
    <h3 style={{ textAlign: "center", marginTop: 50 }}>
      Please remember to login using the same email on the mobile as well to
      seamlessly view your plans
    </h3>
  </Container>
);
export default NotFound;
