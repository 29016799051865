import React, { useContext } from "react";
import {
  getPropertyToShow,
  getTimePropertyToShow,
} from "../../../components/WorkoutComponent/helpers";
import { m } from "framer-motion";
import { Video } from "lucide-react";
import VideoPlayerDialog from "./VideoPlayerDialog";

const Exercise = ({ measurement, isEndurance, thresholdValues, isRun }) => {
  const getRecordFromUserProperties = () => {
    if (
      measurement?.recordFromUser &&
      typeof measurement?.recordFromUser === "object"
    ) {
      let res = Object.entries(measurement?.recordFromUser).map(
        ([key, value]) => {
          if (value) {
            return `${key}`;
          }
        }
      );
      return res.filter((item) => item).join(" | ");
    }
  };

  const getIntensityText = () => {
    let prevIntensity = measurement?.intensity;
    if (!prevIntensity) return null;
    console.log("exersice", prevIntensity?.type);
    if (prevIntensity?.type) {
      let text = nameForKey[prevIntensity?.type] + `: `;
      if (thresholdValues) {
        // remove percentage from the text
        text = text.replace("%", "");
      }
      switch (prevIntensity?.type) {
        case "pace":
          return `${text} ${formatTime(prevIntensity?.value)} to ${formatTime(
            prevIntensity?.maxValue
          )} ${prevIntensity?.unit}`;
        case "cadence":
          return `${text} ${prevIntensity?.value} to ${prevIntensity?.maxValue} spm`;
        case "heartRateZone":
          return `${text} ${prevIntensity?.value}`;
        case "customHeartRate":
          return `${text} ${prevIntensity?.value} to ${prevIntensity?.maxValue} bpm`;
        case "thresholdPace":
          let thresholdPace = thresholdValues?.run_thresholds?.ftPace?.value;
          return calculateValuesFromThreshold(
            text,
            prevIntensity,
            thresholdPace,
            ""
          );
        case "maxCustomHeartRate":
          let maxCustomHeartRate = thresholdValues?.maxHR;
          return calculateValuesFromThreshold(
            text,
            prevIntensity,
            maxCustomHeartRate,
            "bpm"
          );
        case "thresholdCustomHeartRate":
          let thresholdHeartRate = isRun
            ? thresholdValues?.run_thresholds?.ftHR?.value
            : thresholdValues?.bike_thresholds?.ftHR?.value;
          return calculateValuesFromThreshold(
            text,
            prevIntensity,
            thresholdHeartRate,
            "bpm"
          );
        // case "restingCustomHeartRate":
        //   return calculateValuesFromThreshold(text, prevIntensity, thresholdValues?.thresholdHeartRate, "bpm");
        case "FTP":
          let thresholdPower = thresholdValues?.bike_thresholds?.ftPower?.value;
          return calculateValuesFromThreshold(
            text,
            prevIntensity,
            thresholdPower,
            "W"
          );
        default:
          return null;
      }
    }
    return null;
  };

  const calculateValuesFromThreshold = (
    text,
    intensity,
    thresholdValue,
    unit
  ) => {
    if (!thresholdValue)
      return `${text} ${intensity.value} to ${intensity.maxValue} ${unit}`;
    let low = +thresholdValue * (+intensity.value / 100);
    let high = +thresholdValue * (+intensity.maxValue / 100);

    // if pace then format time
    if (intensity.type === "thresholdPace") {
      let originalPace = thresholdValue;
      let originalSpeed = 1 / originalPace;
      let percentageSpeed = originalSpeed * (+intensity.value / 100);
      high = 1 / percentageSpeed;

      percentageSpeed = originalSpeed * (+intensity.maxValue / 100);
      low = 1 / percentageSpeed;

      low = formatTime(low);
      high = formatTime(high);
    }

    return `${text} ${low} to ${high} ${unit}`;
  };

  const getPrimaryTargetText = () => {
    let prevPrimaryTarget = measurement?.primaryTarget;
    if (!prevPrimaryTarget) return null;

    if (prevPrimaryTarget?.type) {
      let text =
        `Primary Target: ` + nameForKey[prevPrimaryTarget?.type] + `: `;
      switch (prevPrimaryTarget?.type) {
        case "speed":
          return `${text} ${prevPrimaryTarget?.value} to ${prevPrimaryTarget?.value} mph`;
        case "cadence":
          return `${text} ${prevPrimaryTarget?.value} to ${prevPrimaryTarget?.maxValue} rpm`;
        case "heartRateZone":
          return `${text} ${prevPrimaryTarget?.value}`;
        case "customHeartRate":
          return `${text} ${prevPrimaryTarget?.value} to ${prevPrimaryTarget?.maxValue} bpm`;
        case "powerZone":
          return `${text} ${prevPrimaryTarget?.value} W`;
        case "customPower":
          return `${text} ${prevPrimaryTarget?.value} to ${prevPrimaryTarget?.maxValue} W`;
        case "thresholdSpeed":
          return `${text} ${prevPrimaryTarget?.value} to ${prevPrimaryTarget?.maxValue} %`;
        case "thresholdCustomHeartRate":
          return `${text} ${prevPrimaryTarget?.value} to ${prevPrimaryTarget?.maxValue} %`;
        case "thresholdCustomPower":
          return `${text} ${prevPrimaryTarget?.value} to ${prevPrimaryTarget?.maxValue} %`;
        default:
          return null;
      }
    }
    return null;
  };

  const getSecondaryTargetText = () => {
    let prevSecondaryTarget = measurement?.secondaryTarget;
    if (!prevSecondaryTarget) return null;

    if (prevSecondaryTarget?.type) {
      let text =
        `Secondary Target: ` + nameForKey[prevSecondaryTarget?.type] + `: `;
      switch (prevSecondaryTarget?.type) {
        case "speed":
          return `${text} ${prevSecondaryTarget?.value} to ${prevSecondaryTarget?.value} mph`;
        case "cadence":
          return `${text} ${prevSecondaryTarget?.value} to ${prevSecondaryTarget?.maxValue} rpm`;
        case "heartRateZone":
          return `${text} ${prevSecondaryTarget?.value}`;
        case "customHeartRate":
          return `${text} ${prevSecondaryTarget?.value} to ${prevSecondaryTarget?.maxValue} bpm`;
        case "powerZone":
          return `${text} ${prevSecondaryTarget?.value} W`;
        case "customPower":
          return `${text} ${prevSecondaryTarget?.value} to ${prevSecondaryTarget?.maxValue} W`;
        case "thresholdSpeed":
          return `${text} ${prevSecondaryTarget?.value} to ${prevSecondaryTarget?.maxValue} %`;
        case "thresholdCustomHeartRate":
          return `${text} ${prevSecondaryTarget?.value} to ${prevSecondaryTarget?.maxValue} %`;
        case "thresholdCustomPower":
          return `${text} ${prevSecondaryTarget?.value} to ${prevSecondaryTarget?.maxValue} %`;
        default:
          return null;
      }
    }
    return null;
  };

  const enduranceImages = {
    "Warm Up": "https://i.ibb.co/2P8JXyX/3629142.jpg",
    Run: "https://i.ibb.co/54hGDWD/Firefly-Running-Person-93528.jpg",
    Cooldown:
      "https://cdn.vectorstock.com/i/1000v/88/28/man-doing-quadriceps-stretch-cool-down-exercise-vector-49078828.jpg",
    "Cool Down":
      "https://cdn.vectorstock.com/i/1000v/88/28/man-doing-quadriceps-stretch-cool-down-exercise-vector-49078828.jpg",
    Rest: "https://img.freepik.com/premium-vector/brunette-girl-sport-top-short-leggins-running-shoes-standing-drinking-water-young-sportswoman-having-rest-daily-workout-healthy-active-lifestyle-cartoon-white_189145-177.jpg",
    Recovery:
      "https://us.123rf.com/450wm/denayunebgt/denayunebgt2202/denayunebgt220200308/182364333-workout-gym-people-exercising-lifting-dumbbells-and-weight-jogging-on-treadmill-sport-wellness-or.jpg?ver=6",
  };

  return (
    <div className={"exercise-items"}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          alignSelf: "stretch",
          gap: "8px",
          position: "relative",
          padding: "8px",
        }}
      >
        <img
          src={
            measurement?.imageLink ?? enduranceImages[measurement?.movementName]
          }
          style={{ width: 48, height: 48, borderRadius: "8px" }}
        />
        <div className="info7">
          <div className="testchannel">{measurement.movementName}</div>
          {measurement?.finishedVideoLink && (
            <VideoPlayerDialog videoLink={measurement?.finishedVideoLink} />
          )}
          <div className="days">{getRecordFromUserProperties()}</div>
        </div>
        <div className="allProperties">
          <RenderOneProperty
            value={measurement?.reps}
            maxValue={measurement?.repsMax}
            property="reps"
          />
          <RenderOneProperty
            unit={"bpm"}
            value={measurement?.heartRate}
            maxValue={measurement?.heartRateMax}
            property="heartRate"
            type={measurement?.heartRateType}
            isEndurance={isEndurance}
          />

          <RenderOneProperty
            unit={measurement?.weightUnits}
            displayUnits={weightDisplayUnits}
            value={measurement?.weight}
            maxValue={measurement?.weightMax}
            property="weight"
            isEndurance={isEndurance}
          />

          <RenderOneProperty
            unit={measurement?.heightUnits}
            displayUnits={heightDisplayUnits}
            value={measurement?.height}
            maxValue={measurement?.heightMax}
            property="height"
            isEndurance={isEndurance}
          />

          <RenderOneProperty
            unit={measurement?.distanceUnits}
            displayUnits={distanceDisplayUnits}
            value={measurement?.distance}
            maxValue={measurement?.distanceMax}
            property="distance"
            isEndurance={isEndurance}
          />
          <RenderOneProperty
            unit={measurement?.paceUnit}
            value={measurement?.pace}
            maxValue={measurement?.paceMax}
            property="pace"
            isEndurance={isEndurance}
          />

          <RenderOneProperty
            unit={"cal"}
            value={measurement?.calorie}
            maxValue={measurement?.calorieMax}
            property="calorie"
            isEndurance={isEndurance}
          />

          <RenderOneProperty
            unit="sec"
            value={measurement?.duration}
            property="duration"
            isEndurance={isEndurance}
          />

          {measurement?.strokeType && (
            <RenderOneProperty
              property={"strokeType"}
              value={{ 1: strokeName[measurement?.strokeType] }}
              unit={" "}
              isEndurance={isEndurance}
            />
          )}

          {measurement?.equipment && (
            <RenderOneProperty
              property={"equipment"}
              value={{ 1: equipmentName[measurement?.equipment] }}
              unit={" "}
              isEndurance={isEndurance}
            />
          )}
        </div>
      </div>
      {getIntensityText() && (
        <div className="flex flex-col w-full px-4 pb-2 text-font12">
          {getIntensityText()}
          {!getPrimaryTargetText() && getSecondaryTargetText() && (
            <div className="flex w-full text-font12">
              {getSecondaryTargetText()}
            </div>
          )}
        </div>
      )}

      {getPrimaryTargetText() && (
        <div className="flex flex-col w-full px-4 pb-2 text-font12">
          {getPrimaryTargetText()}
          {getSecondaryTargetText() && (
            <div className="flex w-full text-font12">
              {getSecondaryTargetText()}
            </div>
          )}
        </div>
      )}

      {measurement?.movementInstr && (
        <div
          style={{
            padding: "8px",
            color: "#616161",
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: "DM Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "normal",
          }}
        >
          Instructions:{" "}
          <span
            style={{
              fontWeight: 400,
              fontFamily: "DM Sans",
              color: "#242424",
            }}
          >
            {measurement?.movementInstr}
          </span>
        </div>
      )}
    </div>
  );
};

export default Exercise;

const RenderOneProperty = ({
  unit,
  value,
  property,
  displayUnits,
  maxValue,
  type,
  isEndurance,
}) => {
  console.log(value, property);

  if (!value) return <></>;

  return (
    <div className="property">
      <div className="reps2">
        {type && <span>{type}&nbsp;</span>}
        <span>
          {property == "duration"
            ? getTimePropertyToShow(value, isEndurance)
            : getPropertyToShow(value, maxValue, isEndurance)}
        </span>
        <span className="span">{` `}</span>
        <span className="reps3">
          {unit ? (displayUnits ? displayUnits[unit] : unit) : property}
        </span>
      </div>
    </div>
  );
};

let weightDisplayUnits = {
  Pounds: "LBS",
  Kilograms: "KGS",
  Poods: "PODS",
  RepMaxFraction: "%1RM",
  BodyWeight: "BW",
  RPE: "RPE",
};
let heightDisplayUnits = {
  Inches: "Inch",
  Feet: "Ft",
  Centimeters: "cm",
  Meters: "m",
};
let distanceDisplayUnits = {
  Meters: "m",
  Kilometers: "KM",
  Feet: "Ft",
  Yards: "Yds",
  Miles: "mi",
  Inches: "Inch",
};
const nameForKey = {
  pace: "Pace",
  cadence: "Cadence",
  heartRateZone: "Heart Rate Zone",
  customHeartRate: "Custom Heart Rate",
  speed: "Speed",
  powerZone: "Power Zone",
  customPower: "Custom Power",
  thresholdPace: "% Threshold Pace",
  maxCustomHeartRate: "% Max Heart Rate",
  thresholdCustomHeartRate: "% Threshold Heart Rate",
  restingCustomHeartRate: "% Resting Heart Rate",
  thresholdSpeed: "% Threshold Speed",
  thresholdCustomPower: "% Threshold Custom Power",
};

const strokeName = {
  BUTTERFLY: "Fly",
  BACKSTROKE: "Back stroke",
  BREASTSTROKE: "Breast stroke",
  FREESTYLE: "Free",
  IM: "Individual Medley",
  MIXED: "Mixed",
};

const equipmentName = {
  SWIM_FINS: "Fins",
  SWIM_KICKBOARD: "Kickboard",
  SWIM_PADDLES: "Paddles",
  SWIM_PULL_BUOY: "Pull Buoy",
  SWIM_SNORKEL: "Snorkel",
  NONE: "None",
};

const formatTime = (minutes) => {
  let mins = Math.floor(minutes);
  let secs = minutes % 1 ? Math.round((minutes % 1) * 60) : 0;
  return `${mins}:${secs < 10 ? "0" + secs : secs}`;
};
