export const getPropertyToShow = (property, maxProperty) => {
  //this function concatinates the object values to a comma seperated string
  if (!property) return "";
  const numProperties = Object.keys(property).length;
  if (numProperties === 0) return "";

  if (maxProperty) {
    return property?.[1] + " - " + maxProperty?.[1];
  }

  return property?.[1];
};

export const getTimePropertyToShow = (property) => {
  // give time in seconds and get time in hh:mm:ss format
  if (!property) return "";
  const numProperties = Object.keys(property).length;
  if (numProperties === 0) return "";

  let seconds = property?.[1];
  if (!property?.[1] || property?.[1] === "") {
    // find the first number in the property
    Object.values(property).map((val) => {
      if (val && !isNaN(+val)) {
        seconds = +val;
      }
    });
  }
  let hour = Math.floor(seconds / 3600);
  let minute = Math.floor((seconds % 3600) / 60);
  let second = seconds % 60;

  return `${String(hour).padStart(2, "0")}:${String(minute).padStart(
    2,
    "0"
  )}:${String(second).padStart(2, "0")}`;
};

export const runRounds = [
  {
    round: {
      numRounds: 1,
      measurements: [
        {
          measurement: {
            type: "substep",
            movementName: "Warm Up",
            substepIndex: 0,
            weightUnits: "Pounds",
            heightUnits: "Inches",
            distanceUnits: "Meters",
          },
        },
      ],
    },
  },
  {
    round: {
      numRounds: 1,
      measurements: [
        {
          measurement: {
            type: "substep",
            movementName: "Run",
            substepIndex: 0,
            weightUnits: "Pounds",
            heightUnits: "Inches",
            distanceUnits: "Meters",
          },
        },
      ],
    },
  },
  {
    round: {
      numRounds: 1,
      measurements: [
        {
          measurement: {
            type: "substep",
            movementName: "Cooldown",
            substepIndex: 0,
            weightUnits: "Pounds",
            heightUnits: "Inches",
            distanceUnits: "Meters",
          },
        },
      ],
    },
  },
];

export const swimRounds = [
  {
    round: {
      numRounds: 1,
      measurements: [
        {
          measurement: {
            type: "substep",
            movementName: "Warm Up",
            substepIndex: 0,
            weightUnits: "Pounds",
            heightUnits: "Inches",
            distanceUnits: "Meters",
          },
        },
      ],
      poolLength: 25,
    },
  },
  {
    round: {
      numRounds: 1,
      measurements: [
        {
          measurement: {
            type: "substep",
            movementName: "Swim",
            substepIndex: 0,
            weightUnits: "Pounds",
            heightUnits: "Inches",
            distanceUnits: "Meters",
          },
        },
      ],
      poolLength: 25,
    },
  },
  {
    round: {
      numRounds: 1,
      measurements: [
        {
          measurement: {
            type: "substep",
            movementName: "Cooldown",
            substepIndex: 0,
            weightUnits: "Pounds",
            heightUnits: "Inches",
            distanceUnits: "Meters",
          },
        },
      ],
      poolLength: 25,
    },
  },
];

export const bikeRounds = [
  {
    round: {
      numRounds: 1,
      measurements: [
        {
          measurement: {
            type: "substep",
            movementName: "Warm Up",
            substepIndex: 0,
            weightUnits: "Pounds",
            heightUnits: "Inches",
            distanceUnits: "Meters",
          },
        },
      ],
    },
  },
  {
    round: {
      numRounds: 1,
      measurements: [
        {
          measurement: {
            type: "substep",
            movementName: "Bike",
            substepIndex: 0,
            weightUnits: "Pounds",
            heightUnits: "Inches",
            distanceUnits: "Meters",
          },
        },
      ],
    },
  },
  {
    round: {
      numRounds: 1,
      measurements: [
        {
          measurement: {
            type: "substep",
            movementName: "Cooldown",
            substepIndex: 0,
            weightUnits: "Pounds",
            heightUnits: "Inches",
            distanceUnits: "Meters",
          },
        },
      ],
    },
  },
];
