import { store } from "@/redux/store";
import axios from "axios";

export const sendTrainerInvite = async (trainers) => {
  try {
    let res = await axios({
      method: "post",
      url: `${
        store.getState().auth.apiURL
      }CF/api/CoachWeb/Create/AdminInviteTrainer`,
      data: trainers,
    });

    return res;
  } catch (error) {
    return false;
  }
};

export const deleteTrainerInvite = async (email) => {
  try {
    let res = await axios({
      method: "post",
      url: `${
        store.getState().auth.apiURL
      }CF/api/CoachWeb/delete/deleteAdminInvitedTrainer?email=${email}`,
    });
    return res.status === 200;
  } catch (error) {
    return false;
  }
};

export const getAdminDashboardData = async (coachID) => {
  try {
    let res = await axios({
      method: "get",
      url: `${store.getState().auth.apiURL}CF/api/AdminCoachDashboard`,
      params: { coachID: coachID },
    });

    return res.data?.result;
  } catch (error) {
    return false;
  }
};

export const assignAthleteToCoach = async (data) => {
  try {
    let res = await axios({
      method: "post",
      url: `${store.getState().auth.apiURL}CF/api/LivEzy/updateCoachForAthlete`,
      data: data,
    });

    return res;
  } catch (error) {
    return false;
  }
};
