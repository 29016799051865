import IOSSwitch from "@/components/Switch/index.jsx";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import axios from "axios";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CustomAlertContext } from "../../../App";
import { store } from "../../../redux/store";
import { NutritionInput } from "../AddRecipieModal";
import { NutritionContext } from "../NutritionContext";

const SEPECIFY_IN_GRAMS = "Grams";
const SEPECIFY_IN_PERCENTAGE = "Percentage";

const AUTO_CALCULATE_CARBS = "Carbs";
const AUTO_CALCULATE_PROTEIN = "Protein";
const AUTO_CALCULATE_FAT = "Fat";

function AddMacrosBasedFood({
  isOpen,
  setIsOpen,
  item,
  getMealPlans,
  onSuccess,
  isTemplate = false,
  userUUID,
  selectedDate,
  isCalendar,
}) {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const [unit, setUnit] = useState(SEPECIFY_IN_GRAMS);
  const [calories, setCalories] = useState(0);
  const [autoCalculate, setAutoCalculate] = useState(AUTO_CALCULATE_CARBS);
  const [protein, setProtein] = useState(0);
  const [fat, setFat] = useState(0);
  const [carbs, setCarbs] = useState(0);

  const [proteinPercentage, setProteinPercentage] = useState(0);
  const [fatPercentage, setFatPercentage] = useState(0);
  const [carbsPercentage, setCarbsPercentage] = useState(0);
  const [loading, setLoading] = useState(false);

  const { displayAlert } = useContext(CustomAlertContext);

  const { setFolderSelected = () => {}, setOpenFolderId = () => {} } =
    useContext(NutritionContext) || {};
  const [meals, setMeals] = useState([
    { name: "Breakfast", calories: 0 },
    { name: "Lunch", calories: 0 },
    { name: "Dinner", calories: 0 },
  ]);
  const [isRetriving, setIsRetriving] = useState(item?.id ? true : false);
  const [options, setOptions] = useState(mealOptions);
  const [typedCategory, setTypedCategory] = useState("");
  const [shouldAutoCalculate, setShouldAutoCalculate] = useState(false);

  const unitOptions = [SEPECIFY_IN_GRAMS, SEPECIFY_IN_PERCENTAGE];
  const autoCalculateOptions = [
    AUTO_CALCULATE_CARBS,
    AUTO_CALCULATE_PROTEIN,
    AUTO_CALCULATE_FAT,
  ];

  const autoCalculateSelected = () => {
    // based on autoCalculate state calculate the value of selected field using other two and calories
    if (!shouldAutoCalculate) return;
    switch (autoCalculate) {
      case AUTO_CALCULATE_CARBS:
        let c = Math.round((calories - protein * 4 - fat * 9) / 4);
        let carbsPercentage = c ? ((c * 4) / calories) * 100 : 0;
        c = Math.max(c, 0);
        setCarbsPercentage(carbsPercentage.toFixed(1));
        setCarbs(c);
        break;
      case AUTO_CALCULATE_PROTEIN:
        let p = Math.round((calories - carbs * 4 - fat * 9) / 4);
        let proteinPercentage = p ? ((p * 4) / calories) * 100 : 0;
        p = Math.max(p, 0);
        setProteinPercentage(proteinPercentage.toFixed(1));
        setProtein(p);
        break;
      case AUTO_CALCULATE_FAT:
        let f = Math.round((calories - protein * 4 - carbs * 4) / 9);
        let fatPercentage = f ? ((f * 9) / calories) * 100 : 0;
        f = Math.max(f, 0);
        setFatPercentage(fatPercentage.toFixed(1));
        setFat(f);
        break;
      default:
        break;
    }
  };

  const retriveMeals = async () => {
    if (!item?.id) return;
    setIsRetriving(true);
    let plandetails = item?.plandetails;
    if (!plandetails?.meals) return;

    setCalories(plandetails?.calories);
    setProtein(plandetails?.macros?.protein);
    setFat(plandetails?.macros?.fat);
    setCarbs(plandetails?.macros?.carbs);

    let percentage = convertToPercentage(plandetails?.macros);
    setProteinPercentage(percentage?.proteinPercentage);
    setFatPercentage(percentage?.fatPercentage);
    setCarbsPercentage(percentage?.carbsPercentage);

    setMeals(plandetails?.meals);
    setTimeout(() => {
      setIsRetriving(false);
    }, 1000);
  };

  useEffect(() => {
    retriveMeals();
  }, []);

  useEffect(() => {
    console.log("item from macros modal: ", item);
    if (!isRetriving) {
      autoCalculateSelected();
    }
  }, [calories, protein, fat, carbs]);

  useEffect(() => {
    if (!isRetriving) {
      const numMeals = meals.length;
      let calPerMeal = Math.floor(calories / numMeals);
      let extras = calories - calPerMeal * numMeals;

      // Distribute extras equally between first n meals
      let newMeals = meals.map((meal, index) => {
        if (index < extras) {
          return {
            ...meal,
            calories: calPerMeal + 1,
          };
        } else {
          return {
            ...meal,
            calories: calPerMeal,
          };
        }
      });

      // Update state with new meal calorie values
      setMeals(newMeals);
    }
    console.log("meals: ", meals);
  }, [calories]);

  const pushToUser = async (id) => {
    try {
      await axios({
        method: "post",
        url: `${APIURL}CF/api/meal/assignMeal`,
        data: {
          atheleteID: userUUID,
          mealId: id,
          dateAssigned: moment(selectedDate, "DD-MM-YYYY")
            ?.set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
            ?.format("YYYY-MM-DD"),
        },
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const createMeal = async (data) => {
    try {
      let res = await axios({
        method: "post",
        url: `${APIURL}CF/api/meal/create`,
        data: data,
      });
      if (isCalendar) {
        await pushToUser(res.data.meal.id);
        getMealPlans();
      } else {
        await getMealPlans(res.data.meal.id);
      }
    } catch (error) {
    } finally {
      setOpenFolderId("");
      setFolderSelected({});
      setIsOpen(false);
    }
  };

  const updateMeal = (data) => {
    // remove nulls from updated item
    Object.keys(data).forEach((key) => data[key] == null && delete data[key]);
    axios({
      method: "post",
      url: `${APIURL}CF/api/meal/update?id=${item?.id}`,
      data: data,
    })
      .then((res) => {
        console.log("meal updated: ", res);
        getMealPlans(res.data.meal.id);
        onSuccess && onSuccess(res?.data?.meal);
      })
      .catch((err) => {
        console.log("meal update error: ", err);
      })
      .finally(() => {
        setIsOpen(false);
      });
  };

  const onSave = () => {
    // if not carbs fats and protein are not specified then return
    if (calories === 0 || protein === 0 || fat === 0 || carbs === 0) {
      displayAlert({
        type: "error",
        message: "Please fill calories, carbs, fats and protein",
      });
      return;
    }

    let macros = {
      protein: +protein,
      fat: +fat,
      carbs: +carbs,
    };
    let totalCalories = +calories;

    // in meals arrange the data in priority of meal name like breakfast, lunch, dinner, snacks
    meals.sort((a, b) => {
      const indexA = mealOptions.indexOf(a.name);
      const indexB = mealOptions.indexOf(b.name);
      return indexA - indexB;
    });

    let plandetails = {
      meals,
      macros,
      calories: totalCalories,
    };

    let data = {
      ...item,
      coachId: store.getState().auth.trainerUUID,
      plandetails,
      isTemplate,
    };
    console.log("plan details: ", data);
    setLoading(true);
    if (item?.id && !item?.isNew) {
      updateMeal(data);
    } else {
      if (data?.isNew) {
        delete data["isNew"];
      }
      createMeal(data);
    }
  };

  const convertToPercentage = ({ protein, carbs, fat }) => {
    //  convert protein fat and carbs to percentage
    let totalCalories = protein * 4 + carbs * 4 + fat * 9;

    let proteinPercentage = protein ? ((protein * 4) / totalCalories) * 100 : 0;
    let carbsPercentage = carbs ? ((carbs * 4) / totalCalories) * 100 : 0;
    let fatPercentage = fat ? ((fat * 9) / totalCalories) * 100 : 0;

    return {
      proteinPercentage: proteinPercentage.toFixed(1),
      carbsPercentage: carbsPercentage.toFixed(1),
      fatPercentage: fatPercentage.toFixed(1),
    };
  };

  let isGrams = unit === SEPECIFY_IN_GRAMS;
  return (
    <Dialog
      onClick={(e) => e.stopPropagation()}
      open={isOpen}
      onClose={() => setIsOpen(false)}
      PaperProps={{
        sx: {
          borderRadius: "20px",
        },
      }}
      maxWidth="sm"
      fullWidth
    >
      <div className="add-program-modal-head">
        <p className="add-program-modal-head-title">Edit Macros</p>
        <CloseRoundedIcon
          sx={{
            position: "absolute",
            right: 24,
            top: 24,
            cursor: "pointer",
          }}
          onClick={() => setIsOpen(false)}
        />
      </div>
      <DialogContent>
        <Stack gap={1}>
          <Stack gap={2} direction={"row"} alignItems={"flex-end"}>
            <SelectionComponent
              label={"Specify in"}
              value={unit}
              setValue={setUnit}
              options={unitOptions}
              sx={{ flex: 1 }}
            />

            <NutritionInput
              height={"40px"}
              label={"Calories"}
              placeholder={"100"}
              value={calories === 0 ? "" : calories}
              onChange={(value) => {
                console.log("cal cange", value);
                setCalories(value);
              }}
              type={"number"}
              style={{ minWidth: "32%" }}
            />

            <SelectionComponent
              label={"Auto Calculate"}
              value={autoCalculate}
              setValue={setAutoCalculate}
              options={autoCalculateOptions}
              sx={{ flex: 1 }}
              isSwitchOn={shouldAutoCalculate}
              onSwitchChange={(value) => {
                setShouldAutoCalculate(value);
                if (value) {
                  autoCalculateSelected();
                }
              }}
            />
          </Stack>
          <Stack gap={2} direction={"row"}>
            <NutritionInput
              height={"40px"}
              disabled={
                shouldAutoCalculate && autoCalculate === AUTO_CALCULATE_PROTEIN
              }
              placeholder={"100"}
              label={"Protein"}
              value={protein === 0 ? "" : isGrams ? protein : proteinPercentage}
              onChange={(value) => {
                if (isGrams) {
                  let percentage = ((value * 4) / calories) * 100;
                  setProteinPercentage(percentage.toFixed(1));
                  setProtein(value);
                } else {
                  setProteinPercentage(value);
                  let grams = (value * calories) / 400;
                  setProtein(grams.toFixed(1));
                }
              }}
              style={{ minWidth: "32%" }}
              right={isGrams ? "g" : "%"}
            />
            <NutritionInput
              height={"40px"}
              disabled={
                shouldAutoCalculate && autoCalculate === AUTO_CALCULATE_FAT
              }
              placeholder={"100"}
              label={"Fat"}
              value={fat === 0 ? "" : isGrams ? fat : fatPercentage}
              onChange={(value) => {
                if (isGrams) {
                  let percentage = ((value * 9) / calories) * 100;
                  setFatPercentage(percentage.toFixed(1));
                  setFat(value);
                } else {
                  setFatPercentage(value);
                  let grams = (value * calories) / 900;
                  setFat(grams.toFixed(1));
                }
              }}
              type={"number"}
              style={{ minWidth: "32%" }}
              right={isGrams ? "g" : "%"}
            />
            <NutritionInput
              height={"40px"}
              disabled={
                shouldAutoCalculate && autoCalculate === AUTO_CALCULATE_CARBS
              }
              placeholder={"100"}
              label={"Carbs"}
              value={carbs === 0 ? "" : isGrams ? carbs : carbsPercentage}
              onChange={(value) => {
                if (isGrams) {
                  let percentage = ((value * 4) / calories) * 100;
                  setCarbsPercentage(percentage.toFixed(1));
                  setCarbs(value);
                } else {
                  setCarbsPercentage(value);
                  let grams = (value * calories) / 400;
                  setCarbs(grams.toFixed(1));
                }
              }}
              type={"number"}
              style={{ minWidth: "32%" }}
              right={isGrams ? "g" : "%"}
            />
          </Stack>

          <Divider sx={{ my: 2 }} />
        </Stack>
        <Stack gap={1}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: 14,
              color: "#000000",
            }}
          >
            Meal Categories
          </Typography>
          <Stack gap={1}>
            {meals?.length > 0 &&
              meals?.map((meal, mealIdx) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 2,
                    }}
                    key={mealIdx}
                  >
                    <SelectionComponentForMeal
                      key={mealIdx}
                      label={"MealOptions"}
                      value={meal.name}
                      onChange={(value) => {
                        let updatedMeals = [...meals];
                        updatedMeals[mealIdx].name = value;
                        setMeals(updatedMeals);
                      }}
                      options={options}
                      setOptions={setOptions}
                      typedCategory={typedCategory}
                      setTypedCategory={setTypedCategory}
                      selectedOptions={mealOptions.filter((m) => {
                        const mealNames = meals.map((m) => m.name);
                        return mealNames.includes(m);
                      })}
                      sx={{ flex: 1 }}
                    />

                    <NutritionInput
                      height={"40px"}
                      disabled={mealIdx === meals.length - 1}
                      label={"Calories"}
                      value={meal.calories}
                      right={"cals"}
                      onChange={(value) => {
                        const parsedValue = parseInt(value);

                        if (isNaN(parsedValue)) {
                          value = "0"; // set NaN value as zero
                        }
                        const lastMealIndex = meals.length - 1;
                        const lastMeal = meals[lastMealIndex];

                        const updatedMeals = meals.map((m, index) => {
                          if (m.name === meal.name) {
                            return {
                              ...m,
                              calories: parseInt(value),
                            };
                          }
                          return m;
                        });

                        const totalCalories = updatedMeals.reduce(
                          (total, m) => total + parseInt(m.calories),
                          0
                        );

                        const remainingCalories =
                          parseInt(calories) - totalCalories;

                        const updatedLastMeal = {
                          ...lastMeal,
                          calories:
                            parseInt(lastMeal.calories) + remainingCalories,
                        };

                        const updatedMealsWithLastMeal = [
                          ...updatedMeals.slice(0, lastMealIndex),
                          updatedLastMeal,
                        ];

                        setMeals(updatedMealsWithLastMeal);
                      }}
                      type={"number"}
                      style={{ minWidth: "32%" }}
                    />
                    <DeleteRoundedIcon
                      sx={{ cursor: "pointer", my: "auto", mt: "30px" }}
                      onClick={() => {
                        setMeals(meals?.filter((m) => m.name !== meal.name));
                      }}
                    />
                  </Box>
                );
              })}
          </Stack>

          <Box>
            <Button
              variant={"text"}
              sx={{
                color: "#000000",
                textTransform: "none",
              }}
              onClick={() => {
                setMeals((prev) => {
                  return [...prev, { name: "", calories: 0 }];
                });
                autoCalculateSelected();
              }}
            >
              <AddRoundedIcon /> Add More Category
            </Button>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <button
          style={{ marginBottom: "8px" }}
          className="button-new"
          onClick={onSave}
        >
          {loading ? (
            <CircularProgress
              sx={{
                color: "#fff",
              }}
              size={20}
              thickness={4}
            />
          ) : isCalendar ? (
            "Save and Assign"
          ) : (
            "Save"
          )}
        </button>
      </DialogActions>
    </Dialog>
  );
}

const SelectionComponentForMeal = ({
  label,
  value,
  setValue,
  options,
  sx,
  onChange,
  selectedOptions,
  setOptions,
  typedCategory,
  setTypedCategory,
}) => {
  return (
    <Box sx={sx}>
      <label className="label-new">{label}</label>
      <Box sx={{ width: "100%" }}>
        <Select
          sx={{
            width: "100%",
            height: "40px",
            borderRadius: "12px",
          }}
          MenuProps={{
            PaperProps: {
              style: {
                minWidth: "auto", // set the width of the options menu to 200px
              },
            },
          }}
          value={value}
          onChange={(e) => {
            onChange && onChange(e.target.value);
            setValue && setValue(e.target.value);
          }}
        >
          {options.map((option) => (
            <MenuItem
              disabled={selectedOptions?.includes(option)}
              sx={{
                fontSize: "12px",
                minWidth: "auto",
              }}
              value={option}
            >
              {option}
            </MenuItem>
          ))}

          <>
            <input
              className="p-2 outline-none border border-gray-300 "
              type="text"
              placeholder="New Category"
              onChange={(e) => {
                setTypedCategory(e.target.value);
              }}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
              value={typedCategory}
              onKeyDown={(e) => {
                e.stopPropagation();
                if (e.key === "Enter") {
                  if (typedCategory === "") return;
                  setOptions([...options, typedCategory]);
                  onChange && onChange(typedCategory);
                  setValue && setValue(typedCategory);
                  setTypedCategory("");
                }
              }}
            />
          </>
        </Select>
      </Box>
    </Box>
  );
};

const SelectionComponent = ({
  label,
  value,
  setValue,
  options,
  sx,
  onChange,
  selectedOptions,
  isSwitchOn,
  onSwitchChange,
}) => {
  return (
    <Box sx={sx}>
      {onSwitchChange && (
        <IOSSwitch
          height={18}
          width={34}
          checked={isSwitchOn}
          onChange={(e) => onSwitchChange(e.target.checked)}
        />
      )}

      <label className="label-new">{label}</label>
      <Box
        sx={{
          width: "100%",
          pointerEvents: onSwitchChange && !isSwitchOn ? "none" : "auto",
          opacity: onSwitchChange && !isSwitchOn ? 0.5 : 1,
        }}
      >
        <Select
          sx={{
            width: "100%",
            height: "40px",
            borderRadius: "12px",
          }}
          MenuProps={{
            PaperProps: {
              style: {
                minWidth: "auto", // set the width of the options menu to 200px
              },
            },
          }}
          value={value}
          onChange={(e) => {
            onChange && onChange(e.target.value);
            setValue && setValue(e.target.value);
          }}
        >
          {options.map((option) => (
            <MenuItem
              disabled={selectedOptions?.includes(option)}
              sx={{
                fontSize: "12px",
                minWidth: "auto",
              }}
              value={option}
            >
              {option}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Box>
  );
};

export default AddMacrosBasedFood;

const mealOptions = [
  "On Waking Up",
  "Early Morning",
  "Breakfast",
  "After Breakfast",
  "Mid-morning",
  "Morning Snack",
  "Lunch",
  "Evening Snack",
  "Dinner",
  "After Dinner",
  "Bed Time",
  "Before Workout",
  "During Workout",
  "Between Workouts",
  "After Workout",
  "Non-Training Day",
];
