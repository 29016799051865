import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  programsStates: {},
};

const programsSlice = createSlice({
  name: "programs",
  initialState,
  reducers: {
    createProgramsStates: (state, action) => {
      state.programsStates = action.payload;
    },
    removeProgramsStates: (state, action) => {
      state.programsStates = {};
    },
  },
});

export const { createProgramsStates, removeProgramsStates } =
  programsSlice.actions;

export default programsSlice.reducer;
