import React from "react";
import { ArrowLeft, Pen } from "lucide-react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { cn } from "@/lib/utils";

import CustomSelect from "./utils/CustomSelect";

const TopBar = ({
  workoutInfo,
  setEditWorkoutInfo,
  onSaveWorkout,
  isUpdate,
  assignInfo,
  allAthletes,
  selectedAthlete,
  setSelectedAthlete,
}) => {
  const history = useHistory();
  return (
    <div className="flex w-full h-[76px] items-center justify-between rounded-b-3xl shadow-md sticky top-0 bg-white-pure px-[32px] z-50">
      <div className="flex gap-4 items-center">
        <ArrowLeft
          className="cursor-pointer"
          onClick={() => history.goBack()}
        />
        <p className="text-[22px] font-medium text-[#231F20]">
          {workoutInfo?.name}
        </p>
        <Pen
          onClick={() => setEditWorkoutInfo(true)}
          color="#828282"
          className="cursor-pointer"
        />
      </div>
      <div
        className={`flex gap-4 items-center ${
          assignInfo?.isCalendar ? "" : ""
        }`}
      >
        <Select
          value={selectedAthlete ? selectedAthlete : null}
          onValueChange={(value) => {
            if (value === "clear") {
              setSelectedAthlete(undefined);
            } else {
              setSelectedAthlete(value);
            }
          }}
        >
          <SelectTrigger className={cn("w-[180px] focus:ring-transparent")}>
            <SelectValue placeholder={"Select Athelete"} />
          </SelectTrigger>
          <SelectContent>
            {selectedAthlete && (
              <SelectItem
                value={"clear"}
                className="text-red-600 focus:text-red-600 "
              >
                Clear Selection
              </SelectItem>
            )}
            {allAthletes
              .map((athlete) => ({
                label: athlete.name,
                value: athlete,
              }))
              .map((option, index) => {
                return (
                  <SelectItem key={index} value={option.value}>
                    {option.label}
                  </SelectItem>
                );
              })}
          </SelectContent>
        </Select>
        {/* <CustomSelect
          options={allAthletes.map((athlete) => ({
            label: athlete.name,
            value: athlete,
          }))}
          placeholder="Select Athlete"
          value={selectedAthlete ? selectedAthlete : null}
          onValueChange={(value) => {
            if (value === selectedAthlete) {
              setSelectedAthlete(undefined);
            } else {
              setSelectedAthlete(value);
            }
          }}
          className={"w-[180px] focus:ring-transparent"}
        /> */}
        <button
          className="button-new shadow-md hover:shadow-lg sheen"
          onClick={onSaveWorkout}
        >
          {assignInfo?.isCalendar
            ? "Save and Assign"
            : isUpdate
            ? "Update Workout"
            : "Save Workout"}
        </button>
      </div>
    </div>
  );
};

export default TopBar;
