import { Box, Divider, Stack } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { ShowRest } from "../Round";
import AddExerciseIllustration from "../../../assets/workoutBuilder/addExerciseIllustration.svg";
import Exercise from "../Exercise";
import { EnduranceContext } from "./EnduranceBuilder";

const EnduranceRoundReorderView = ({
  round,
  index,
  selectedExerciseInfo,
  setSelectedExerciseInfo,
  setShowAddPropertiesModal,
  updateCurrentRound,
  addBreak,
  canReorder,
  setCanReorder,
}) => {
  const { tempRound, setTempRound, reorderRound, setReorderRound } =
    useContext(EnduranceContext);
  const [isDragging, setIsDragging] = useState(false);

  const modifyRound = (round) => {
    //check each measurement and if it contains break property then add break as new movement after that
    let newMeasurements = [];
    round?.measurements?.forEach((m) => {
      let measurement = m.measurement;
      if (measurement.break) {
        newMeasurements.push({
          measurement: {
            ...measurement,
            break: undefined,
          },
        });
        newMeasurements.push({
          measurement: {
            movementName: "BREAK",
            break: measurement.break,
          },
        });
      } else {
        newMeasurements.push({ measurement });
      }
    });
    let newRound = { ...round };
    newRound.measurements = newMeasurements;
    return newRound;
  };

  const incrementOrDecrementBreakBy30Seconds = (index, increment) => {
    let newRound = { ...tempRound };
    let newMeasurements = [...newRound.measurements];
    let measurement = newMeasurements[index].measurement;
    if (increment) {
      measurement.break.duration += 15;
    } else {
      measurement.break.duration -= 15;
    }
    newMeasurements[index].measurement = measurement;
    newRound.measurements = newMeasurements;
    setTempRound(newRound);
  };

  const resetModifiedRound = () => {
    let newRound = { ...tempRound };
    let newMeasurements = [];

    newRound.measurements.forEach((m, index) => {
      let measurement = m.measurement;
      if (measurement.movementName !== "BREAK") {
        let nextMeasurement = newRound.measurements[index + 1]?.measurement;
        let newMeasurement = { ...measurement };
        if (nextMeasurement && nextMeasurement.break) {
          newMeasurement.break = nextMeasurement.break;
        }
        newMeasurements.push({ measurement: newMeasurement });
      }
    });
    newRound.measurements = newMeasurements;
    updateCurrentRound(newRound);
  };

  useEffect(() => {
    let modifiedRound = modifyRound(round);
    console.log("modifiedRound", modifiedRound);
    setTempRound(modifiedRound);
  }, [round]);

  return (
    <div
      style={{
        paddingBottom: isDragging ? "100px" : "50px",
      }}
    >
      <Stack
        sx={{
          padding: "24px 16px",
        }}
        gap={"16px"}
      >
        {tempRound?.measurements?.length > 0 ? (
          tempRound?.measurements?.map((m, exerciseIndex) => {
            let measurement = m.measurement;
            const onSelect = () => {
              setShowAddPropertiesModal(false);
              setShowAddPropertiesModal(true);
            };
            let isNextItemBreak = false;
            if (
              measurement.movementName === "BREAK" ||
              tempRound.measurements[exerciseIndex + 1]?.measurement
                ?.movementName === "BREAK"
            ) {
              isNextItemBreak = true;
            }

            return (
              <>
                <Draggable
                  key={exerciseIndex}
                  draggableId={`section-${index} exercise-${exerciseIndex}`}
                  index={exerciseIndex}
                  isDragDisabled={measurement?.movementName === "BREAK"}
                >
                  {(provided, snapshot) => {
                    if (snapshot.isDragging) {
                      setIsDragging(true);
                    } else {
                      setIsDragging(false);
                    }
                    return (
                      <Stack
                        gap={"16px"}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {measurement?.movementName !== "BREAK" ? (
                          <Exercise
                            key={exerciseIndex}
                            selected={
                              selectedExerciseInfo?.exerciseIndex ===
                                exerciseIndex &&
                              selectedExerciseInfo?.roundIndex === index
                            }
                            measurement={measurement}
                            selectedExerciseInfo={selectedExerciseInfo}
                            onSelect={onSelect}
                            isDragEnabled={true}
                          />
                        ) : (
                          <>
                            {tempRound.measurements.length !==
                              exerciseIndex + 1 &&
                              measurement?.break && (
                                <ShowRest
                                  measurement={measurement}
                                  incrementOrDecrementBreakBy30Seconds={
                                    incrementOrDecrementBreakBy30Seconds
                                  }
                                  index={exerciseIndex}
                                />
                              )}
                          </>
                        )}
                        {/* {tempRound.measurements.length !== exerciseIndex + 1 &&
                      isNextItemBreak === false && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "relative",
                            height: "32px",
                            width: "100%",
                          }}
                        >
                          <button
                            style={{
                              borderRadius: "20px",
                              height: "32px",
                              cursor: "pointer",
                              zIndex: 1,
                              backgroundColor: "white",
                            }}
                            onClick={() => addBreak(exerciseIndex)}
                            className="button-new-outlined-rounded"
                          >
                            <p className="rounded-btn-text">Add Rest</p>
                          </button>
                          <Divider
                            absolute
                            sx={{
                              top: "50%",
                              left: "50%",
                              height: "1px",
                              transform: "translate(-50%, -50%)",
                              zIndex: 0,
                            }}
                          />
                        </Box>
                      )} */}

                        {provided.placeholder}
                      </Stack>
                    );
                  }}
                </Draggable>
              </>
            );
          })
        ) : (
          <div>
            <img
              src={AddExerciseIllustration}
              alt="add exercise illustration"
              style={{ width: "100%", height: "100%" }}
            />
            <p
              style={{
                color: "#424242",
                fontFeatureSettings: "'clig' off, 'liga' off",
                fontFamily: "DM Sans",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "20px",
                marginTop: "16px",
                width: "100%",
                textAlign: "center",
              }}
            >
              Drag and Drop exercise from left panel
            </p>
          </div>
        )}
      </Stack>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          bottom: 10,
        }}
      >
        <button
          style={{
            borderRadius: "20px",
            height: "32px",
            cursor: "pointer",
            zIndex: 1,
            backgroundColor: "white",
            border: "2px solid #8330e9",
          }}
          onClick={() => {
            setCanReorder(!canReorder);
            if (canReorder) {
              setReorderRound(-1);
            }
            resetModifiedRound();
          }}
          className="button-new-outlined-rounded"
        >
          <p
            style={{
              color: "#8330e9",
            }}
            className="rounded-btn-text"
          >
            Save
          </p>
        </button>
      </div>
    </div>
  );
};

export default EnduranceRoundReorderView;

function getStyle(style, snapshot) {
  if (!snapshot.isDropAnimating) {
    return style;
  }
  const { moveTo, curve, duration } = snapshot.dropAnimation;
  // move to the right spot
  const translate = `translate(${moveTo.x}px, ${moveTo.y}px)`;
  // add a bit of turn for fun
  const rotate = "rotate(0.5turn)";

  // patching the existing style
  return {
    ...style,
    transform: `${translate} ${rotate}`,
    // slowing down the drop because we can
    transition: `all ${curve} ${duration + 1}s`,
  };
}
