import { deleteAssignedMealPlan } from "@/ApiServices/Meals/Api";
import axios from "axios";
import {
  ClipboardCheck,
  CopyX,
  Files,
  RefreshCcw,
  Trash2,
  Watch,
} from "lucide-react";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useHistory } from "react-router-dom";
import addButton from "../../assets/addBtnSchedule.svg";
import WorkoutPlanIcon from "../../assets/copilot/ScheduleWorkoutIcon.svg";
import activityImg from "../../assets/copilot/activity.png";
import mealImg from "../../assets/copilot/dietPlanIcon.svg";
import { SpurfitCircularProgress } from "../Dashboard/dashboard";
import { updateActivity } from "../DescriptionComp/util";
import styles from "./calendar.module.css";

import { pasteDayOnAssignedDate } from "@/ApiServices/ClientDetails/Api";
import { resyncGarminWorkout } from "@/ApiServices/Workouts/Api";
import { cn } from "@/lib/utils";
import { ClientsViewContext } from "@/pages/clientsView";
import { clearCopiedDay, copyDay } from "@/redux/clientsSlice";
import { Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ShowAssignedWorkoutDialoge from "../ClientsView/ShowAssignedWorkoutDialoge";
import AdminUserDetail from "@/pages/adminUserDetail/adminUserDetail";
import UnavailableUi from "./UnavailableUi";
import { object } from "yup";
import { current } from "@reduxjs/toolkit";

const ShowAssignedPlans = ({
  workouts,
  meals,
  activities,
  events,
  status,
  primaryKey,
  moduleDate,
  userUUID,
  isThresholdAvailable,
  currentDate,
  setSelectedDate,
  setShowWorkOutBuilder,
  setSelectedActivity,
  selectedCalendarType,
  currentEnduranceData,
  currentMonthEnduranceData,
  setProfileData,
  index,
  userInfo,
  isDragging,
  loadingDay,
  isAdminUserDetail,
  profileDataFromClientsView,
  setProfileDataFromClientsView,
  reloadProfile,
  setWorkouts,
  todayDateRef,
}) => {
  const clientsViewContext = useContext(ClientsViewContext);
  const APIURL = useSelector((state) => state.auth.apiURL);
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const copiedWorkout = useSelector((state) => state.client?.copiedWorkout);
  const copiedDay = useSelector((state) => state.client?.copiedDay);
  const [isEmptyOne, setIsEmptyOne] = useState(false);
  const [showDialoge, setShowDialoge] = useState(false);
  const [syncingGarminWorkouts, setSyncingGarminWorkouts] = useState({});
  const [unavailabilityData, setUnavailabilityData] = useState();
 

  console.log({ unavailabilityData });

  
  // the below function add the ... in the name if it is large in length
  const truncateLargeName = (name) => {
    if (selectedCalendarType === "Weekly") {
      // if (name.length > 15) {
      //   return `${name.substring(0, 15)}...`;
      // }
      return name;
    } else {
      if (name.length > 8) {
        return `${name.substring(0, 8)}...`;
      }
      return name;
    }
  };
  const getCurrentMonthData = async () => {
    const currentDate = moment();
    const calStartDate = currentDate.startOf("month");
    await axios({
      method: "post",
      url: `${APIURL}CF/api/clientProfileByPage`,
      data: {
        date: moment(calStartDate).format("YYYY-MM-DD"),
      },
      params: {
        userUUID: userUUID,
      },
    })
      .then((res) => {
        setProfileData(res.data.result);
      })
      .catch((err) => {
        console.log("error fetching client profile by page: ", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getPastedMonthData = async (pastedDate) => {
    const calStartDate = moment(pastedDate).startOf("month");
    await axios({
      method: "post",
      url: `${APIURL}CF/api/clientProfileByPage`,
      data: {
        date: moment(calStartDate).format("YYYY-MM-DD"),
      },
      params: {
        userUUID: userUUID,
      },
    })
      .then((res) => {
        setProfileData(res.data.result);
      })
      .catch((err) => {
        console.log("error fetching client profile by page: ", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // const pasteWorkout = async (assignDate) => {
  //   let data = {
  //     atheleteID: userUUID,
  //     workoutID: copiedWorkout?.workoutId,
  //     assignDate: `${moment(assignDate, "YYYY-MM-DD").format(
  //       "YYYY-MM-DD"
  //     )}T00:00:00.000Z`,
  //   };
  //   setLoading(true);
  //   let res = await pasteWorkoutOnAssignedDate(data);
  //   await getCurrentMonthData();

  //   setLoading(false);
  // };

  const pasteDay = async (assignDate) => {
    let data = {
      atheleteID: userUUID,
      copiedDay: copiedDay,
      assignDate: `${moment(assignDate, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      )}T00:00:00.000Z`,
    };

    setLoading(true);
    let res = await pasteDayOnAssignedDate(data);
    await reloadProfile(userUUID);

    setLoading(false);
  };

  async function handleCopyWorkout(e) {
    e.preventDefault();
    e.stopPropagation();
    await pasteDay(moduleDate);
    dispatch(clearCopiedDay());
  }

  const deleteIndividualWorkout = async (
    assignedWorkoutID,
    primaryKeyArray,
    workoutKind
  ) => {
    await axios({
      method: "post",
      url: `${APIURL}CF/api/deleteAssignedWorkoutAthelete`,
      data: {
        assignedWorkoutID: assignedWorkoutID,
        newOrderWorkoutIDs: primaryKeyArray,
        isEnduranceWorkout:
          workoutKind == "run" ||
          workoutKind === "bike" ||
          workoutKind === "swim",
      },
    })
      .then((res) => {
        // console.log("deleteAssignedWorkoutAthelete: ", res);
      })
      .catch((err) => {
        console.log("Network error, please try again", err);
      })
      .finally(() => { });
  };
  const deleteFullDay = async ({
    primaryKeys,
    meal,
    activity,
    workoutKinds,
  }) => {
    setLoading(true);
    // delete meal
    let mealAssignedId = meal?.[0]?.item?.id;
    if (mealAssignedId) {
      let deleteMeal = await deleteAssignedMealPlan(mealAssignedId);
    }
    // update activity
    if (Object?.keys(activity?.[0]?.activitiesAssigned ?? {})?.length > 0) {
      let updatedActivity = {
        activitiesAssigned: {},
      };
      let update = await updateActivity(updatedActivity, activity?.[0]?.id);
    }
    // delete workout
    // delete using deleteIndividualWorkout

    if (primaryKeys.length > 0) {
      await Promise.all(
        primaryKeys?.map(async (primaryKey, index) => {
          let updatedPrimaryKey = primaryKeys.filter((pk) => pk !== primaryKey);
          primaryKeys = [...updatedPrimaryKey];
          let workoutKind = workoutKinds?.[primaryKey];
          await deleteIndividualWorkout(
            primaryKey,
            updatedPrimaryKey,
            workoutKind
          );
        })
      );
    }

    // set that day to empty
    await reloadProfile(userUUID);

    setLoading(false);
  };

  const resyncEnduranceWorkout = async (workout, workoutTrackerId) => {
    setSyncingGarminWorkouts((prev) => ({
      ...prev,
      [workout.id]: true,
    }));

    let res = await resyncGarminWorkout({
      workout: workout?.workoutAssigned,
      dateAssigned: moduleDate,
      userUUID: userUUID,
      atheleteID: userUUID,
      workoutTrackerId: workoutTrackerId,
    });

    // update workout
    if (res?.result) {
      let updatedWorkouts = [...workouts];
      let updatedWorkout = updatedWorkouts.find((w) => w.id === workout.id);
      updatedWorkout.garminWorkout = [
        {
          scheduleId: res?.result,
        },
      ];

      setWorkouts(updatedWorkouts);
    }

    setSyncingGarminWorkouts((prev) => ({
      ...prev,
      [workout.id]: false,
    }));
  };

  useEffect(() => {
    if (activities) {
      Object?.values(activities)?.forEach((item) => {
        if (
          item?.activitiesAssigned?.unavailableDateAndReason &&
          Object.keys(item?.activitiesAssigned?.unavailableDateAndReason)
            ?.length
        ) {
          setUnavailabilityData(
            item?.activitiesAssigned?.unavailableDateAndReason
          );
        }
      });
    }
  }, [activities]);

  function isUnavailabilityDateInRange({ startDate, endDate, currentDate }) {
    const start = moment(startDate);
    const end = moment(endDate);
    const current = moment(currentDate);
    return current.isBetween(start, end, null, "[]");
  }

  return loading || loadingDay ? (
    <div
      className={`${selectedCalendarType == "Semi Monthly"
        ? styles.assignedDataTwoWeek
        : selectedCalendarType == "Monthly"
          ? styles.assignedDataMonthly
          : styles.assignedDataOneWeek
        }`}
    >
      <SpurfitCircularProgress />
    </div>
  ) : (
    <>
      {unavailabilityData &&
        unavailabilityData?.reason &&
        !isAdminUserDetail &&
        isUnavailabilityDateInRange({
          startDate: unavailabilityData?.startDate,
          endDate: unavailabilityData?.endDate,
          currentDate,
        }) ? (
        <UnavailableUi unavailabilityData={unavailabilityData} />
      ) : (
        <>
          {showDialoge && (
            <ShowAssignedWorkoutDialoge
              open={showDialoge}
              setShowDialoge={setShowDialoge}
              primaryKey={primaryKey}
              isCalendar={"true"}
              date={moduleDate}
              userUUID={userUUID}
              userInfo={userInfo}
              isThresholdAvailable={isThresholdAvailable}
              meals={meals}
              assignedhabits={activities}
              enduranceData={currentEnduranceData}
              currentMonthEnduranceData={currentMonthEnduranceData}
              profileDataFromClientsView={profileDataFromClientsView}
              setProfileDataFromClientsView={setProfileDataFromClientsView}
              updateEnduranceData={
                clientsViewContext?.updateSingleEnduranceFinishedData
              }
            />
          )}

          <Droppable droppableId={`droppable_${moduleDate}`} key={moduleDate}>
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={`${selectedCalendarType == "Semi Monthly"
                  ? styles.assignedDataTwoWeek
                  : selectedCalendarType == "Monthly"
                    ? styles.assignedDataMonthly
                    : styles.assignedDataOneWeek
                  }`}
                style={{
                  background: snapshot.isDraggingOver ? "#f3f4f6" : "",
                }}
                // style={{
                //   width: '90%',
                //   height: '95px',
                //   display:"flex",
                //   alignItems:"center",
                //   ...provided.droppableProps.style,
                // }}
                onClick={() => {
                  if (isDragging) {
                    return;
                  }
                  if (
                    workouts?.length > 0 ||
                    meals?.length > 0 ||
                    (!workouts && currentEnduranceData?.length > 0) ||
                    (activities?.length > 0 &&
                      activities?.[0]?.activitiesAssigned &&
                      Object.keys(activities?.[0]?.activitiesAssigned).length >
                      0)
                  ) {
                    setShowDialoge(true);

                    // history.push(
                    //   `/library/showWorkoutsCF/${primaryKey}?isCalendar=${true}&date=${moduleDate}&userUUID=${userUUID}&ita=${isThresholdAvailable}`,
                    //   {
                    //     meals,
                    //     habits: activities,
                    //     enduranceData: currentEnduranceData,
                    //   }
                    // );
                  }
                }}
              >
                {(selectedCalendarType == "Semi Monthly" ||
                  selectedCalendarType == "Monthly") &&
                  !isAdminUserDetail &&
                  !copiedDay?.date && (
                    <>
                      {!workouts &&
                        meals?.length === 0 &&
                        !currentEnduranceData &&
                        (!activities ||
                          !activities[0]?.activitiesAssigned ||
                          Object.keys(activities[0]?.activitiesAssigned)
                            .length === 0) ? (
                        <div className="w-full h-full flex items-center justify-center">
                          <img
                            src={addButton}
                            alt=""
                            className={styles.addButtonTwoWeekEmpty}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              let momentCurrentDate = moment(
                                currentDate,
                                "YYYY-MM-DD"
                              );
                              if (momentCurrentDate.isValid()) {
                                let formattedCD =
                                  momentCurrentDate.format("DD-MM-YYYY");
                                setSelectedDate(formattedCD);
                              }
                              setShowWorkOutBuilder(true);
                              setSelectedActivity(activities);
                            }}
                          />
                        </div>
                      ) : (
                        <div
                          className={styles.deladdDiv}
                          style={{
                            position: "absolute",
                            top: "-20px",
                            right: "-16.5px",
                            display: "none",
                          }}
                        >
                          <Files
                            size={18}
                            onClick={(e) => {
                              e.stopPropagation();
                              let workoutIDs = [];
                              workouts?.forEach((workout) => {
                                workoutIDs.push(workout.workoutAssigned.id);
                              });

                              let copiedDayData = {
                                date: moduleDate,
                                meals,
                                activities,
                                workoutIDs,
                                events,
                              };

                              dispatch(copyDay(copiedDayData));
                            }}
                          />

                          <Trash2
                            size={18}
                            color="red"
                            onClick={(e) => {
                              e.stopPropagation();
                              let primaryKeys = [];
                              workouts?.forEach((workout) => {
                                primaryKeys.push(workout.id);
                              });
                              console.log("PRIMARY KEY TEMP", primaryKeys);
                              // let primaryKeys = JSON.stringify(tempPrimaryKey);
                              let tempWorkoutKinds = {};
                              workouts?.forEach((item) => {
                                tempWorkoutKinds[item?.id] =
                                  item?.workoutAssigned?.workoutKind;
                              });
                              console.log(
                                "workouts ------------->",
                                workouts,
                                tempWorkoutKinds
                              );

                              deleteFullDay({
                                primaryKeys,
                                meal: meals,
                                activity: activities,
                                workoutKinds: tempWorkoutKinds,
                              });
                            }}
                          />
                          <img
                            src={addButton}
                            alt="add button"
                            className={styles.addButtonTwoWeek}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              let momentCurrentDate = moment(
                                currentDate,
                                "YYYY-MM-DD"
                              );
                              if (momentCurrentDate.isValid()) {
                                let formattedCD =
                                  momentCurrentDate.format("DD-MM-YYYY");
                                setSelectedDate(formattedCD);
                              }
                              setShowWorkOutBuilder(true);
                              setSelectedActivity(activities);
                            }}
                          />
                        </div>
                      )}
                    </>
                    // <span className={styles.twoWeekSpan}>
                    // </span>
                  )}
                {copiedDay?.date && selectedCalendarType !== "Weekly" && (
                  <div
                    style={{
                      position: "absolute",
                      top: "-20px",
                      right: "-16.5px",
                    }}
                    className="flex gap-2 items-center"
                  >
                    <div
                      onClick={(e) => {
                        handleCopyWorkout(e);
                      }}
                    >
                      <ClipboardCheck size={16} />
                    </div>

                    {
                      // if copied date is current date then show the cross button
                      copiedDay?.date === moduleDate && (
                        <CopyX
                          size={16}
                          onClick={(e) => {
                            e.stopPropagation();
                            dispatch(clearCopiedDay());
                          }}
                          className="cursor-pointer"
                        />
                      )
                    }
                  </div>
                )}

                {(selectedCalendarType == "Semi Monthly" ||
                  selectedCalendarType == "Monthly") && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        overflow: "scroll",
                        height: selectedCalendarType == "Monthly" ? "75px" :"157px",
                        alignItems: "baseline",
                        scrollbarWidth: "none",
                      }}
                    >
                      {workouts &&
                        workouts.map((workout, index) => {
                          let workoutName = workout.workoutAssigned.name;
                          let garminConnected =
                            workout?.garminWorkout?.[0]?.scheduleId;
                          let workoutTrackerId = workout?.id;

                          console.log("workout from assigned: ", workout);
                          let isEnduranceWorkout = [
                            "run",
                            "swim",
                            "bike",
                          ].includes(workout?.workoutAssigned?.workoutKind);
                          if (workout && status.length != 0) {
                            if (
                              currentEnduranceData &&
                              currentEnduranceData?.length > 0 &&
                              isEnduranceWorkout
                            ) {
                              workoutName =
                                currentEnduranceData[0]?.summary?.activityName;
                              status[index] = "done";
                            }
                          }

                          return (
                            <Draggable
                              key={index}
                              draggableId={`workout@-${JSON.stringify(
                                "name"
                              )}_index@${index}_ModuleDate@${moduleDate}_PrimaryKey@${primaryKey}_workoutId@${workout.id
                                }`}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  className={`${status?.[index] == "not done"
                                    ? styles.due
                                    : status?.[index] == "done"
                                      ? styles.done
                                      : styles.workout
                                    } ${selectedCalendarType === "Semi Monthly"
                                      ? "twoWeekChip"
                                      : ""
                                    }`}
                                  key={index}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={{
                                    ...provided.draggableProps.style,
                                    marginTop: "4px",
                              
                                    left: "auto !important",
                                    top: "auto !important",
                                    zIndex: "99999999",
                                    maxWidth: "130px",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <ConditionalTooltip
                                    condition={
                                      isEnduranceWorkout && !garminConnected
                                    }
                                    title="Click to resync Garmin Workout"
                                  >
                                    {isEnduranceWorkout ? (
                                      garminConnected ? (
                                        <Watch
                                          size={18}
                                          className="mr-1"
                                          color="#32CD32"
                                        />
                                      ) : (
                                        <div
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            resyncEnduranceWorkout(
                                              workout,
                                              workoutTrackerId
                                            );
                                          }}
                                          className={cn("cursor-pointer")}
                                        >
                                          {syncingGarminWorkouts[workout.id] ? (
                                            <SpurfitCircularProgress size={14} />
                                          ) : (
                                            <RefreshCcw
                                              size={18}
                                              className="mr-1"
                                              color="red"
                                            />
                                          )}
                                        </div>
                                      )
                                    ) : (
                                      <img
                                        src={WorkoutPlanIcon}
                                        alt="workout-icon"
                                      />
                                    )}
                                    <p
                                      className={
                                        selectedCalendarType === "Monthly"
                                          ? "truncate"
                                          : ""
                                      }
                                      style={{
                                        textAlign: "center",
                                      }}
                                    >
                                      {workoutName ??
                                        workout.workoutAssigned.name}
                                    </p>
                                  </ConditionalTooltip>
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                      {currentEnduranceData &&
                        currentEnduranceData.length > 0 &&
                        !isEnduranceWorkoutAssigned(workouts) &&
                        currentEnduranceData.map((workout, index) => {
                          const { id, summary } = workout; //
                          console.log(
                            "what is workout here",
                            workout,
                            currentDate
                          );
                          // if (workout && status.length != 0) {
                          //   console.log(
                          //     "workout status",
                          //     workout,
                          //     currentEnduranceData,
                          //     status[index],
                          //     currentDate
                          //   );
                          //   if (
                          //     currentEnduranceData &&
                          //     currentEnduranceData?.length > 0 &&
                          //     ["run", "swim", "bike"].includes(
                          //       workout?.workoutAssigned?.workoutKind
                          //     )
                          //   ) {
                          //     console.log("ye print hua", currentDate);
                          //     status[index] = "done";
                          //   }
                          // }
                          return (
                            <Draggable
                              key={id} // Using unique 'id' as the key
                              draggableId={`workout@-${JSON.stringify(
                                "name"
                              )}_id@${id}`} // Adjust as necessary
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  className={styles.done}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={{
                                    
                                    marginTop: "4px",
                                    maxWidth: "130px",
                                  }}
                                >
                                  <img src={WorkoutPlanIcon} alt="workout-icon" />
                                  <p
                                    className={
                                      selectedCalendarType === "Monthly"
                                        ? "truncate"
                                        : ""
                                    }
                                    style={{
                                      textAlign: "left",
                                    }}
                                  >
                                    {summary?.activityName}
                                  </p>{" "}
                                  {/* Assuming truncateLargeName is defined */}
                                </div>
                              )}
                            </Draggable>
                          );
                        })}

                      {events &&
                        events.map((event, index) => (
                          <div
                            className={`${styles.event} truncate ...`}
                            key={index}
                          >
                            <img
                              src={"https://img.icons8.com/badges/48/event.png"}
                              alt=""
                            />
                            <p>{event.event.name}</p>
                          </div>
                        ))}

                      {meals &&
                        meals.map((meal, index) => (
                          <Draggable
                            key={`meal-${index}`}
                            draggableId={`meal@${JSON.stringify(
                              meal
                            )}@ModuleDate_${moduleDate}`}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                className={`${styles.meal}`}
                                key={index}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  left: "auto !important",
                                  top: "auto !important",
                                }}
                              >
                                {selectedCalendarType == "Semi Monthly" ||
                                  selectedCalendarType == "Monthly" ? (
                                  <div
                                    className={styles.meal}
                                    style={{
                                      padding:
                                        selectedCalendarType === "Monthly"
                                          ? "2px 6px"
                                          : "4px 12px 4px 6px",
                                      maxWidth: "130px",
                                    }}
                                  >
                                    <img src={mealImg} alt="meal-icon" />
                                    <p
                                      className={
                                        selectedCalendarType === "Monthly"
                                          ? "truncate"
                                          : ""
                                      }
                                    >
                                      {meal.Name}
                                    </p>
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      width: "100%",
                                      display: "flex",
                                    }}
                                  >
                                    <img src={mealImg} alt="meal-icon" />
                                    <p>{meal.Name}</p>
                                  </div>
                                )}
                              </div>
                            )}
                          </Draggable>
                        ))}
                      {(activities ?? []).length > 0 &&
                        activities?.[0]?.activitiesAssigned &&
                        Object.keys(activities?.[0]?.activitiesAssigned ?? {})
                          .length > 0 && (
                          <div className={`${styles.activity} `}>
                            <img src={activityImg} alt="activity-icon" />
                            <p>Activity</p>
                          </div>
                        )}
                    </div>
                  )}
                {selectedCalendarType === "Weekly" && (
                  <>
                    {workouts &&
                      workouts.map((workout, index) => {
                        let workoutName = workout.workoutAssigned.name;
                        let garminConnected =
                          workout?.garminWorkout?.[0]?.scheduleId;
                        let workoutTrackerId = workout?.id;

                        let isEnduranceWorkout = [
                          "run",
                          "swim",
                          "bike",
                        ].includes(workout?.workoutAssigned?.workoutKind);
                        if (workout && status.length != 0) {
                          if (
                            currentEnduranceData &&
                            currentEnduranceData?.length > 0 &&
                            isEnduranceWorkout
                          ) {
                            workoutName =
                              currentEnduranceData[0]?.summary?.activityName;
                            status[index] = "done";
                          }
                        }
                        return (
                          <Draggable
                            key={index}
                            draggableId={`workout-@${JSON.stringify(
                              "name"
                            )}_index@${index}_ModuleDate@${moduleDate}_PrimaryKey@${primaryKey}_workoutId@${workout.id
                              }`}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                className={`${status?.[index] == "not done"
                                  ? styles.due
                                  : status?.[index] == "done"
                                    ? styles.done
                                    : styles.workout
                                  } ${selectedCalendarType === "Semi Monthly"
                                    ? "twoWeekChip"
                                    : ""
                                  }`}
                                key={index}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  marginTop: "4px",
                                  position: "static",
                                  maxWidth: "100%",
                                  left: "auto !important",
                                  top: "auto !important",
                                  //  height: "auto",
                                  // transform: snapshot.isDragging ? "trans" : "",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {isEnduranceWorkout ? (
                                  garminConnected ? (
                                    <Watch
                                      size={18}
                                      className="mr-1"
                                      color="#32CD32"
                                    />
                                  ) : (
                                    <div
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        resyncEnduranceWorkout(
                                          workout,
                                          workoutTrackerId
                                        );
                                      }}
                                      className={cn("cursor-pointer")}
                                    >
                                      {syncingGarminWorkouts[workout.id] ? (
                                        <SpurfitCircularProgress size={14} />
                                      ) : (
                                        <RefreshCcw
                                          size={18}
                                          className="mr-1"
                                          color="red"
                                        />
                                      )}
                                    </div>
                                  )
                                ) : (
                                  <img
                                    src={WorkoutPlanIcon}
                                    alt="workout-icon"
                                  />
                                )}
                                <p>
                                  {workoutName ?? workout.workoutAssigned.name}
                                </p>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                    {currentEnduranceData &&
                      currentEnduranceData.length > 0 &&
                      !isEnduranceWorkoutAssigned(workouts) &&
                      currentEnduranceData.map((workout, index) => {
                        const { id, summary } = workout; //
                        return (
                          <Draggable
                            key={id} // Using unique 'id' as the key
                            draggableId={`workout@-${JSON.stringify(
                              "name"
                            )}_id@${id}`} // Adjust as necessary
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                className={styles.done}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  marginTop: "4px",
                                }}
                              >
                                <img src={WorkoutPlanIcon} alt="workout-icon" />
                                <p>{summary?.activityName}</p>
                                {/* Assuming truncateLargeName is defined */}
                              </div>
                            )}
                          </Draggable>
                        );
                      })}

                    {events &&
                      events.map((event, index) => (
                        <div
                          className={`${styles.event} truncate ...`}
                          key={index}
                        >
                          <img
                            src={"https://img.icons8.com/badges/48/event.png"}
                            alt=""
                          />
                          <p>{event.event.name}</p>
                        </div>
                      ))}

                    {meals &&
                      meals.map((meal, index) => (
                        <Draggable
                          key={`meal-${index}`}
                          draggableId={`meal@${JSON.stringify(
                            meal
                          )}@ModuleDate_${moduleDate}`}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              className={`${styles.meal}`}
                              key={index}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              {selectedCalendarType == "Semi Monthly" ? (
                                <div className={styles.meal}>
                                  <img src={mealImg} alt="meal-icon" />
                                  <p>{meal.Name}</p>
                                </div>
                              ) : (
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                  }}
                                >
                                  <img src={mealImg} alt="meal-icon" />
                                  <p>{meal.Name}</p>
                                </div>
                              )}
                            </div>
                          )}
                        </Draggable>
                      ))}
                    {(activities ?? []).length > 0 &&
                      activities?.[0]?.activitiesAssigned &&
                      Object.keys(activities?.[0]?.activitiesAssigned ?? {})
                        .length > 0 && (
                        <div className={`${styles.activity} `}>
                          <img src={activityImg} alt="activity-icon" />
                          <p>Activity</p>
                        </div>
                      )}
                  </>
                )}
                {selectedCalendarType === "Weekly" &&
                  !copiedDay?.date &&
                  !isAdminUserDetail && (
                    <>
                      {!workouts &&
                        meals?.length === 0 &&
                        !currentEnduranceData &&
                        (!activities ||
                          !activities[0]?.activitiesAssigned ||
                          Object.keys(activities[0]?.activitiesAssigned)
                            .length === 0) ? (
                        <img
                          src={addButton}
                          alt=""
                          className={styles.addButtonOneWeekEmpty}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            let momentCurrentDate = moment(
                              currentDate,
                              "YYYY-MM-DD"
                            );
                            if (momentCurrentDate.isValid()) {
                              // setSelectedDay(momentCurrentDate);
                              let formattedCD =
                                momentCurrentDate.format("DD-MM-YYYY");
                              setSelectedDate(formattedCD);
                            }
                            setShowWorkOutBuilder(true);
                            setSelectedActivity(activities);
                          }}
                        />
                      ) : (
                        <div
                          style={{
                            width: 100,
                            marginLeft: "auto",
                          }}
                        >
                          <div className={styles.addButtonOneWeek}>
                            <Files
                              size={18}
                              onClick={(e) => {
                                e.stopPropagation();
                                let workoutIDs = [];
                                workouts?.forEach((workout) => {
                                  workoutIDs.push(workout.workoutAssigned.id);
                                });

                                let copiedDayData = {
                                  date: moduleDate,
                                  meals,
                                  activities,
                                  workoutIDs,
                                  events,
                                };

                                dispatch(copyDay(copiedDayData));
                              }}
                            />

                            <Trash2
                              style={{ marginRight: "5px", marginLeft: "4px" }}
                              size={18}
                              color="red"
                              onClick={(e) => {
                                e.stopPropagation();
                                let primaryKeys = [];
                                workouts?.forEach((workout) => {
                                  primaryKeys.push(workout.id);
                                });
                                console.log("PRIMARY KEY TEMP", primaryKeys);
                                let tempWorkoutKinds = {};
                                workouts?.forEach((item) => {
                                  tempWorkoutKinds[item?.id] =
                                    item?.workoutAssigned?.workoutKind;
                                });

                                deleteFullDay({
                                  primaryKeys,
                                  meal: meals,
                                  activity: activities,
                                  workoutKinds: tempWorkoutKinds,
                                });
                              }}
                            />
                            <img
                              src={addButton}
                              alt="add button"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                let momentCurrentDate = moment(
                                  currentDate,
                                  "YYYY-MM-DD"
                                );
                                if (momentCurrentDate.isValid()) {
                                  let formattedCD =
                                    momentCurrentDate.format("DD-MM-YYYY");
                                  setSelectedDate(formattedCD);
                                }
                                setShowWorkOutBuilder(true);
                                setSelectedActivity(activities);
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  )}
                {selectedCalendarType === "Weekly" && copiedDay?.date && (
                  <div className="flex gap-2 items-center">
                    <div
                      style={
                        {
                          // position: "absolute",
                          // top: "-20px",
                          // right: "-16.5px",
                        }
                      }
                      onClick={(e) => handleCopyWorkout(e)}
                    >
                      <ClipboardCheck
                        size={16}
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    </div>

                    {
                      // if copied date is current date then show the cross button
                      copiedDay?.date === moduleDate && (
                        <CopyX
                          size={16}
                          onClick={(e) => {
                            e.stopPropagation();
                            dispatch(clearCopiedDay());
                          }}
                          className="cursor-pointer"
                        />
                      )
                    }
                  </div>
                )}

                {/* {!isEmpty(placeholderProps) && snapshot.isDraggingOver && (
                <div
                  // className="placeholder"
                  style={{
                    top: placeholderProps.clientY,
                    left: placeholderProps.clientX,
                    height: placeholderProps.clientHeight,
                    width: placeholderProps.clientWidth
                  }}
                />
              )} */}
                {provided.placeholder && snapshot.isDraggingOver && (
                  <div className="w-full h-[5px] "></div>
                )}
                <div style={{ display: "none" }}>{provided.placeholder}</div>
              </div>
            )}
          </Droppable>
        </>
      )}
    </>
  );
};

export default ShowAssignedPlans;
const isEnduranceWorkoutAssigned = (allWorkoutData) => {
  return (
    allWorkoutData?.some((workoutData) => {
      return ["run", "bike", "swim"].includes(
        workoutData?.workoutAssigned?.workoutKind
      );
    }) ?? false
  );
};

const ConditionalTooltip = ({ children, condition, title }) => {
  return (
    <>
      {condition ? (
        <Tooltip
          title={title}
          style={{ display: "flex", gap: "4px", maxWidth: "110px" }}
        >
          {children}
        </Tooltip>
      ) : (
        children
      )}
    </>
  );
};
