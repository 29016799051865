import { colorsArray } from "../../../constants";

export const ExerciseCard = ({ exercises }) => {
  let exerciseItems = [];
  exercises.forEach((exercise) => {
    let sets = 0;
    for (let prop in exercise) {
      if (
        exercise.hasOwnProperty(prop) &&
        (prop === "duration" || prop === "distance" || prop === "eps")
      ) {
        sets = Object.keys(exercise[prop]).length;
      }
    }
    exerciseItems.push({ name: exercise.name, set: sets });
  });

  return (
    <div
      style={{
        // borderLeft: "5px solid #00D43B",
        borderRadius: "8px",
        width: "235px",
        minHeight: "90px",
        boxShadow:
          "0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 4px 8px 0px rgba(0, 0, 0, 0.14)",
        textAlign: "left",
        paddingLeft: "11px",
        marginBottom: "9px",
        fontFamily: "DM Sans",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          position: "absolute",
          left: 0,
          height: "100px",
          width: "5px",
          backgroundColor: colorsArray[0],
        }}
      />
      <div
        style={{
          paddingTop: "14px",
          fontSize: "12px",
          fontWeight: 500,
          lineHeight: "16px",
        }}
      >
        Exercises
      </div>
      {exerciseItems.map((exercise) => (
        <div style={{ color: "#242424BF", fontSize: "10px", marginTop: "4px" }}>
          {exercise.name} - {exercise.set} sets
        </div>
      ))}
    </div>
  );
};
