import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import LoginImage from "../../assets/LoginPageImage.svg";
import BluishEffect from "../../assets/login/BluishEffect.svg";
import Logo from "../../assets/livezy.png";
import toast from "../../components/Toast/toast";
import SpinnerComponent from "../../components/layout/spinner";
import CustomTheme from "../../themeProps";
import { APIURLLivezy } from "@/APIDetails";

const ForgotPasswordLivezy = () => {
  const [activityLoaderOn, setActivityLoader] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [email, setEmail] = useState("");
  const history = useHistory();

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const forgetpassword = async () => {
    if (!email) {
      toast("Please enter email");
      return;
    }
    setActivityLoader(true);
    try {
      await axios({
        method: "post",
        url: `${APIURLLivezy}CF/api/LivEzy/forgetpassword`,
        data: {
          email: email,
        },
      })
        .then((res) => {
          toast(
            "Email sent successfully! Please check link in email to reset password"
          );
          history.go(-1);
          setActivityLoader(false);
        })
        .catch((err) => {
          toast("Invalid email. Please try again");
          setActivityLoader(false);
        });
    } catch (error) {
      console.log("error ===>>> ", error);
      toast("Invalid email. Please try again");
      setActivityLoader(false);
    }
  };

  // display banner
  const renderBanner = () => {
    return (
      <div
        style={{
          width: screenWidth <= 1017 ? "100%" : "50%",
          display: screenWidth <= 1017 && "none",
        }}
        className="relative lg:flex items-end justify-end h-screen"
      >
        <div
          style={{
            height: "calc(100% - 48px)",
            width: "calc(100% - 48px)",
            margin: 24,
            borderRadius: 24,
            position: "absolute",
            background: "rgba(0, 0, 0, 0.40)",
          }}
        ></div>
        <img
          className="object-cover"
          style={{
            height: "calc(100% - 48px)",
            width: "calc(100% - 48px)",
            margin: 24,
            borderRadius: 24,
          }}
          src={LoginImage}
          alt=""
        />

        <div
          className="flex flex-col absolute items-center justify-center"
          style={{
            bottom: "104px",
            width: "100%",
            zIndex: 100,
          }}
        >
          <span
            style={{
              color: "#fff",
              fontSize: "48px",
            }}
            className="mx-[64px] font-DMSans  font-bold justify-self-center font-display  xl:text-font20"
          >
            Better engagement with lessor effort
          </span>
        </div>
      </div>
    );
  };

  //   display form to reset password
  const renderForm = () => {
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
        }}
        className="lg:w-screen min-h-screen items-center justify-center"
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            flexDirection: "column",
          }}
          className=" lg:bg-white-pure flex items-center"
        >
          <img
            src={Logo}
            style={{
              width: 113.25,
              height: 48,
              resizeMode: "contain",
              marginTop: "80px",
            }}
            alt=""
            className="self-center"
          />

          <div
            style={{
              width: "100%",
            }}
            className="mt-[112px] px-12 sm:px-40 md:px-48 lg:px-24 xl:px-24 xl:max-w-3xl self-center"
          >
            <h3 className="text-center self-center text-font24 text-black-900 font-display font-semibold font-DMSans">
              Forgot Password?
            </h3>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "8px",
                marginBottom: "16px",
              }}
            >
              <span
                className="font-DMSans text-font16 text-center self-center"
                style={{
                  color: "#424242",
                  fontWeight: 400,
                }}
              >
                {"No worries, we’ll send you reset instructions."}
              </span>
            </div>
            <div className=" flex flex-col items-center">
              <form style={{ width: "343px", zIndex: 1 }}>
                <div className="mt-[16px]">
                  <div
                    style={{
                      fontSize: 14,
                      color: "#424242",
                      marginLeft: "12px",
                    }}
                    className="text-sm tracking-wide font-DMSans"
                  >
                    Email Address*
                  </div>
                  <input
                    style={{
                      borderRadius: "12px",
                      backgroundColor: "#fff",
                    }}
                    className="h-[48px] px-4 w-full text-lg mt-[8px] font-DMSans border border-gray-300 focus:outline-none focus:border-indigo-500"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="mike@spur.fit"
                  />
                </div>
                <div className="my-10">
                  <button
                    style={{
                      width: "100%",
                    }}
                    className="button-new "
                    onClick={
                      activityLoaderOn
                        ? null
                        : (e) => {
                            e.preventDefault();
                            forgetpassword();
                          }
                    }
                  >
                    {activityLoaderOn ? (
                      <SpinnerComponent />
                    ) : (
                      <span
                        style={{
                          fontSize: 14,
                        }}
                      >
                        {"Reset Password"}
                      </span>
                    )}
                  </button>
                </div>
              </form>

              <div
                style={{
                  zIndex: 1,
                }}
                className="font-DMSans text-sm font-display text-blue-light text-center"
              >
                <button
                  type="button"
                  style={{
                    color: CustomTheme.palette.gray,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    history.go(-1);
                  }}
                >
                  <ArrowBackIcon />
                  <span
                    className="text-center self-center font-display"
                    style={{
                      color: "#424242",
                    }}
                  >
                    {" Back to Log in"}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <img
          src={BluishEffect}
          style={{
            position: "absolute",
            opacity: 0.6,
            bottom: 0,
            zIndex: 0,
          }}
        />
      </div>
    );
  };

  return (
    <>
      {/* <ToastContainer /> */}
      <div
        className="lg:flex"
        style={{
          flex: 1,
        }}
      >
        {/* render form */}
        {renderForm()}
        {/* render banner image */}
        {renderBanner()}
      </div>
    </>
  );
};

export default ForgotPasswordLivezy;
