import React from 'react';

export const CloseThreadIcon = ({ closeThread, setPinsOpen }) => (
  <div
    onClick={(e) => {
      closeThread(e);
      setPinsOpen(false);
    }}
    className='close-thread-icon'
  >
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M18 6L6 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M6 6L18 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  </div>
);
