import axios from "axios";
import moment from "moment";
import { store } from "../../redux/store";

function generateWorkoutInfo(oneDay) {
  return {
    name: oneDay?.title,
    numRounds: 1,
    coachID: store.getState().auth.trainerUUID,
    workoutType: "NOSCORE",
    workoutCategory: [],
    dateCreated: `${moment().startOf("day").format("YYYY-MM-DDTHH:mm:ss")}Z`,
    isCoachWorkout: false,
  };
}

function generateMeasurements(exercises, exerciseIDs) {
  let measurements = [];
  let round = {
    name: "",
    roundTime: 0,
  };
  console.log("exercises");
  exercises.forEach((exercise, index) => {
    let rounds = 0;
    if (exercise?.reps) {
      rounds = Object.entries(exercise?.reps).length;
    } else if (exercise?.weights) {
      rounds = Object.entries(exercise?.weights).length;
    } else if (exercise?.duration) {
      rounds = Object.entries(exercise?.duration).length;
    } else if (exercise?.distance) {
      rounds = Object.entries(exercise?.distance).length;
    }

    round.numRounds = rounds;
    const key = exercise.name
      .toLowerCase()
      .replace(/[\s.,\/#!$%\^&\*;:{}=\-_`~()]/g, "");

    if (exercise?.name == "Rest" && measurements?.[index - 1]?.measurement) {
      measurements[index - 1].measurement = {
        ...measurements[index - 1].measurement,
        break: {
          duration: exercise?.duration?.[1],
        },
      };
    }

    measurements.push({
      measurement: {
        movementName: exercise?.name == "Rest" ? "BREAK" : exercise?.name,
        movementID: exercise?.movementID || exerciseIDs[key]?.id,
        reps: exercise?.reps,
        weights: exercise?.weights,
        heights: exercise?.heights,
        duration: exercise?.duration,
        distance: exercise?.distance,
        calories: exercise?.calories,
        heightUnits: exercise?.units?.heights,
        weightUnits: exercise?.units?.weights,
        distanceUnits: exercise?.units?.distance,
        videoLink:
          exercise?.videoUrl ||
          exerciseIDs[key]?.videoLink ||
          exerciseIDs[key]?.videoURL ||
          "",
        imageLink: exercise?.imageLink || exerciseIDs[key]?.imageLink || "",
        isMaxLoad: false,
        isMaxReps: false,
        recordFromUser: {
          reps: false,
          heights: false,
          weights: false,
          duration: false,
          distance: false,
          calories: false,
        },
      },
    });
  });

  return { round, measurements };
}

function conversionFunction(
  data,
  selectedUser,
  promises,
  assignDate,
  exerciseIDs
) {
  Object.entries(data["week-1"]).forEach(([day, w], index) => {
    if (
      w === "Rest Day" ||
      w.Workout === "Rest Day" ||
      w?.Workout?.title === "Rest Day"
    ) {
      return;
    }
    let oneDay = w.Workout;

    let workoutInfo = generateWorkoutInfo(oneDay);

    let workoutContent = {};
    let rounds = [];
    Object.entries(oneDay)?.forEach(([part, e]) => {
      if (part == "title") return;

      let { round, measurements } = generateMeasurements(
        e.exercises,
        exerciseIDs
      );
      round.measurements = measurements;
      rounds.push({ round });
    });

    workoutContent = { rounds };
    let res = { workoutInfo, workoutContent };
    let dateToAssign = moment(assignDate)
      .add(index, "days")
      .format("DD-MM-YYYY");
    console.log("dateToAssign", dateToAssign, workoutContent);
    promises.push(createWorkout(res, selectedUser, dateToAssign));
  });
}

function convertOneDay(data, promises, exerciseIDs, workoutName) {
  if (
    data === "Rest Day" ||
    data.Workout === "Rest Day" ||
    data?.Workout?.title === "Rest Day"
  ) {
    return;
  }
  let oneDay = data.Workout;

  let workoutInfo = generateWorkoutInfo(oneDay);
  workoutInfo.name = workoutName;
  workoutInfo.isCoachWorkout = true;

  let workoutContent = {};
  let rounds = [];
  Object.entries(oneDay)?.forEach(([part, e]) => {
    if (part == "title") return;

    let { round, measurements } = generateMeasurements(
      e.exercises,
      exerciseIDs
    );
    round.measurements = measurements;
    rounds.push({ round });
  });

  workoutContent = { rounds };
  let res = { workoutInfo, workoutContent };

  promises.push(createWorkout(res));
}
function convertToKilograms(property, unit) {
  const convertSingleWeight = (weight, unit) => {
    if (weight && unit) {
      switch (unit.toLowerCase()) {
        case "kilograms":
        case "kg":
          return weight;
        case "lbs":
        case "pounds":
          return weight * 0.453592;
        case "bodyweight":
          return weight; // Assuming bodyweight is already in kilograms
        case "repsmaxfraction":
          return weight * 100; // Assuming weight represents a fraction of one's maximum lift
        case "rpe":
          return weight; // Assuming RPE is not a weight unit
        default:
          return "";
      }
    }
  };

  if (typeof property === "object") {
    const convertedProperty = {};
    for (const [key, weight] of Object.entries(property)) {
      convertedProperty[key] = convertSingleWeight(weight, unit);
    }
    return convertedProperty;
  } else {
    return convertSingleWeight(property, unit);
  }
}
function convertItemToOriginalForm(outputItem) {
  let originalItem;

  console.log("output item ha kya", outputItem);
  if (outputItem?.workoutContent?.rounds?.length === 0) {
    originalItem = {
      Workout: "Rest Day",
    };
    return originalItem;
  }

  let oneDay = {
    Workout: {
      title: outputItem.name,
    },
  };

  //console.log(outputItem.workoutContent)
  outputItem?.workoutContent?.rounds?.forEach((roundData, roundIndex) => {
    let round = roundData.round;
    let measurements = round.measurements;

    //console.log(round)
    //console.log(measurements)
    measurements.forEach((measurementObj) => {
      let measurement = measurementObj.measurement;
      if (measurement.movementName === "BREAK") {
        return;
      }
      let weightsInKg = convertToKilograms(
        measurement?.weight,
        measurement?.weightUnits
      );
      console.log("weights in kg", weightsInKg);
      let exercise = {
        name: measurement.movementName,
        movementID: measurement?.movementID,
        reps: measurement.reps,
        weights: weightsInKg,
        heights: measurement.height,
        duration: measurement.duration,
        distance: measurement.distance,
        calories: measurement.calories,
        units: {
          heights: measurement.heightUnits,
          weights: "kilograms",
          distance: measurement.distanceUnits,
        },
      };

      //   console.log(exercise);
      let partKey = `Part-${roundIndex + 1}`;
      if (!oneDay.Workout[partKey]) {
        oneDay.Workout[partKey] = {
          exercises: [],
        };
      }

      oneDay.Workout[partKey].exercises.push(exercise);

      if (measurement?.break?.duration) {
        let duration = {};

        for (let i = 1; i <= round.numRounds; i++) {
          duration[i] = measurement?.break?.duration;
        }

        let newBreak = {
          name: "Rest",
          duration,
          units: {
            duration: "seconds",
          },
        };
        oneDay.Workout[partKey].exercises.push(newBreak);
      }
    });
  });

  originalItem = oneDay;

  return originalItem;
}

function getWorkoutInOriginalFormGivenRange(data, startDate, endDate) {
  let start = moment(startDate);
  let end = moment(endDate);

  let workouts = {
    "week-1": {},
  };

  let date = start;
  let index = 0;
  while (date <= end) {
    let day = date.format("dddd");
    let formattedDay = date.format("YYYY-MM-DD");
    console.log("date: ", formattedDay);
    if (data?.[formattedDay]) {
      workouts["week-1"][`Day-${index + 1}`] = convertItemToOriginalForm(
        data[formattedDay]
      );
    } else {
      workouts["week-1"][`Day-${index + 1}`] = {
        Workout: "Rest Day",
      };
    }
    date = date.add(1, "days");
    index++;
  }
  return workouts;
}

const pushWorkoutToUser = async (userUUID, modulesUUID, startDate) => {
  let url = `${store.getState().auth.apiURL}CF/api/modules`;

  let data = {
    userUUID: userUUID,
    modulesUUID: modulesUUID,
    moduleDate: `${moment(startDate, "DD-MM-YYYY")
      .startOf("day")
      .format("YYYY-MM-DDTHH:mm:ss")}Z`,
  };

  await axios({
    method: "post",
    url: url,
    data: data,
  })
    .then((res) => {
      console.log("posted workout: ", res.data);
    })
    .catch((err) => {
      console.log("error assigning workout to user " + userUUID + " :", err);
    });
};

const createWorkout = async (d, selectedUser, startDate) => {
  await axios({
    method: "post",
    url: `${
      store.getState().auth.apiURL
    }CF/api/CoachWeb/Create/addWorkoutWithAllDetailsJSON`,
    data: { workout: d },
  })
    .then(async (res) => {
      if (!selectedUser || !selectedUser.id) return;
      await pushWorkoutToUser(selectedUser.id, res.data?.result?.id, startDate);
    })
    .catch((err) => {});
};

export {
  conversionFunction,
  convertItemToOriginalForm,
  convertOneDay,
  createWorkout,
  getWorkoutInOriginalFormGivenRange,
  pushWorkoutToUser,
};
