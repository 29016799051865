import { SpurfitCircularProgress } from "@/components/Dashboard/dashboard";
import { CloseRounded } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import axios from "axios";
import { Plus, Trash2 } from "lucide-react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const AddingStepsModal = ({
  title,
  open,
  setIsOpen,
  data,
  getTrainerRecipes,
  setItem,
  setNutritionData,
  getCoachRecipes,
  setLoadingSearchedItem,
}) => {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [ingredients, setIngredients] = useState({});
  const [directions, setDirections] = useState({});
  const [ingCount, setIngCount] = useState(
    Object.keys(data?.ingredients || {}).length || 1
  );
  const [dirCount, setDirCount] = useState(
    Object.keys(data?.directions || {}).length || 1
  );

  function convertDirections(initialDirections) {
    const finalDirections = {};
    if (initialDirections) {
      initialDirections.forEach((direction) => {
        finalDirections[direction.direction_number] =
          direction.direction_description;
      });
    }
    return { directions: finalDirections };
  }

  function convertIngredients(initialIngredients) {
    const finalIngredients = {};
    if (initialIngredients) {
      initialIngredients.forEach((ingredient, index) => {
        finalIngredients[index + 1] = ingredient.ingredient_description;
      });
    }
    return { ingredients: finalIngredients };
  }

  useEffect(() => {
    if (data?.ingredients?.length > 0) {
      const convertedIng = convertIngredients(data?.ingredients).ingredients;
      setIngredients(convertedIng);
    }
    if (data?.directions?.length > 0) {
      const convertedDir = convertDirections(data?.directions).directions;
      setDirections(convertedDir);
    }
    {
      if (title === "Ingredients" && data?.ingredients?.length === 0) {
        setIngredients({ 1: "" });
      } else if (data?.directions?.length === 0) {
        setDirections({ 1: "" });
      }
    }
  }, []);

  const addIngApiCall = async () => {
    try {
      setLoading(true);
      let updatedServing = {};
      updatedServing.description = data?.description;
      updatedServing.receipeVideos = data?.receipeVideos;
      updatedServing.recipe_name = data?.recipe_name;
      updatedServing.serving_sizes = { ...data?.serving_sizes };
      updatedServing.ingredients = { ...ingredients };
      updatedServing.directions = { ...directions };
      const res = await axios.post(`${APIURL}CF/api/updateReceipe`, {
        id: data?.id,
        serving: updatedServing,
      });
      console.log(
        "response after updating the local recipe by adding either ingredient or directions",
        res?.data?.data
      );
      if (res?.data?.data) {
        let recipe = res?.data?.data?.serving;
        let directions = Object.entries(recipe?.directions || {}).map(
          ([key, value]) => {
            return {
              direction_number: key,
              direction_description: value,
            };
          }
        );

        let ingredients = Object.entries(recipe?.ingredients || []).map(
          ([key, value]) => {
            return {
              ingredient_description: value,
            };
          }
        );

        let updatedRecipe = {
          ...recipe,
          directions,
          ingredients,
          id: res?.data?.data?.id,
        };
        console.log("what is updated recipe", updatedRecipe);
        let trainerRecipes = await getCoachRecipes();
        if (trainerRecipes.length > 0) {
          const specificId = res?.data?.data?.id;
          const foundItem = trainerRecipes.find(
            (item) => item.id === specificId
          );

          if (foundItem) {
            const restOfItems = trainerRecipes.filter(
              (item) => item.id !== specificId
            );
            // Add the foundItem at the beginning of the array
            trainerRecipes = [foundItem, ...restOfItems];
            setNutritionData(trainerRecipes);
            setItem(foundItem);
          } else {
            console.log("Item with specific ID not found");
          }
        }

        let timeout = setTimeout(() => {
          setLoadingSearchedItem(false);
        }, 1000);

        return () => {
          clearTimeout(timeout);
        };
      }
    } catch (err) {
      console.log("there is some problem in the update recipe call", err);
    } finally {
      setLoading(false);
      setIsOpen(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => setIsOpen(false)}
      PaperProps={{
        sx: {
          width: "1000px",
          height: "600px",
          borderRadius: "12px",
          maxHeight: "800px",
        },
      }}
    >
      <DialogTitle
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid #f2f2f2",
          fontFamily: "DM Sans",
        }}
      >
        <h3>{title}</h3>
        <CloseRounded
          sx={{
            top: "24px",
            right: "24px",
            cursor: "pointer",
          }}
          onClick={() => setIsOpen(false)}
        />
      </DialogTitle>
      <DialogContent
        style={{
          padding: "10px",
          overflowY: "scroll",
        }}
      >
        {loading ? (
          <div className="w-full h-full">
            <SpurfitCircularProgress />
          </div>
        ) : (
          <>
            {title === "Ingredients"
              ? Object.keys(ingredients).map((item, index) => {
                  console.log("item ing", item, index);
                  return (
                    <div
                      className="flex justify-center items-center mb-3 mt-3"
                      key={index}
                    >
                      <input
                        type="text"
                        value={ingredients[item]}
                        placeholder={`Add ${title}...`}
                        style={{
                          width: "92%",
                          height: "30px",
                          background: "#F2F2F2",
                          padding: "20px 10px",
                          borderRadius: "8px",
                          fontSize: "16px",
                          marginRight: "10px",
                          fontFamily: "DM Sans",
                        }}
                        onChange={(e) => {
                          const tempObj = { ...ingredients };
                          tempObj[item] = e.target.value;
                          setIngredients(tempObj);
                        }}
                        // onBlur={(e) => {
                        //   const tempObj = { ...ingredients };
                        //   tempObj[item] = e.target.value;
                        //   setIngredients(tempObj);
                        // }}
                      />
                      <Trash2
                        style={{
                          cursor: "pointer",
                        }}
                        color="#ff0000"
                        onClick={() => {
                          const tempObj = { ...ingredients };
                          delete tempObj[item]; // Delete the item with the specified ID
                          // Update IDs of remaining items
                          const updatedObj = Object.fromEntries(
                            Object.entries(tempObj).map(([key, value], idx) => [
                              idx + 1,
                              value,
                            ])
                          );
                          setIngredients(updatedObj);
                        }}
                      />
                    </div>
                  );
                })
              : Object.keys(directions).map((item, index) => (
                  <div
                    className="flex justify-center items-center mb-3 mt-3"
                    key={index}
                  >
                    <input
                      type="text"
                      value={directions[item]}
                      placeholder={`Add ${title}...`}
                      style={{
                        width: "92%",
                        height: "30px",
                        background: "#F2F2F2",
                        padding: "20px 10px",
                        borderRadius: "8px",
                        fontSize: "16px",
                        marginRight: "10px",
                        fontFamily: "DM Sans",
                      }}
                      onChange={(e) => {
                        const tempObj = { ...directions };
                        tempObj[item] = e.target.value;
                        setDirections(tempObj);
                      }}
                      // onBlur={(e) => {
                      //   const tempObj = { ...directions };
                      //   tempObj[item] = e.target.value;
                      //   setDirections(tempObj);
                      // }}
                    />
                    <Trash2
                      color="#ff0000"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        const tempObj = { ...directions };
                        delete tempObj[item]; // Delete the item with the specified ID
                        // Update IDs of remaining items
                        const updatedObj = Object.fromEntries(
                          Object.entries(tempObj).map(([key, value], idx) => [
                            idx + 1,
                            value,
                          ])
                        );
                        setDirections(updatedObj);
                      }}
                    />
                  </div>
                ))}

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
                fontSize: "16px",
              }}
            >
              <Plus color="#8330e9" />
              <p
                style={{
                  color: "#8330e9",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (title === "Ingredients") {
                    const tempCount = ingCount + 1;
                    setIngCount(tempCount);
                    setIngredients((prev) => ({
                      ...prev,
                      [tempCount]: "",
                    }));
                  } else {
                    const tempCount = dirCount + 1;
                    setDirCount(tempCount);
                    setDirections((prev) => ({
                      ...prev,
                      [tempCount]: "",
                    }));
                  }
                }}
              >
                Add more {title}
              </p>
            </div>
          </>
        )}
      </DialogContent>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "white",
          padding: "16px 0px",
        }}
      >
        <button className="button-new" onClick={() => addIngApiCall()}>
          Add {title}
        </button>
      </div>
    </Dialog>
  );
};

export default AddingStepsModal;
