import React from "react";
import { Typography, Divider } from "@mui/material";
import {
  CREATE_WORKOUT_PLAN,
  CREATE_MEAL_PLAN,
  ASSIST_MODIFY_WORKOUTS,
  ADD_AND_MANAGE_CLIENTS,
  ANALYSE_CLIENT_MEDICAL_PROFILE,
  GET_INSIGHTS_ON_CLIENT_WORKOUTS,
  KNOW_MORE,
  HELP_US_BE_BETTER,
  GENERATE_SINGLE_WORKOUT,
  GENERATE_RECIPE,
  GENERATE_MEAL_PLAN,
} from "../../constants";
import { useLocation } from "react-router-dom";
import mixpanel from "mixpanel-browser";

const SpurfitOptions = ({
  showWelcomeMsg,
  setShowWelcomeMsg,
  setSelectedOption,
  clearStates,
  selectedOption,
  WorkoutPlanIcon,
  DietPlanIcon,
  EditIcon,
  ClientIcon,
  AnalyseIcon,
  InsightIcon,
  InfoIcon,
  BulbIcon,
}) => {
  const location = useLocation();
  const { pathname } = location;
  const isWorkouts = pathname.includes("workouts");
  const isRecipe = pathname.includes("nutrition/2");
  const isMealPlan = pathname.includes("nutrition/3");
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        borderRadius: "8px",
        padding: "6px",
        alignItems: "flex-start",
        fontFamily: "DM Sans",
        boxShadow:
          "0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 4px 8px 0px rgba(0, 0, 0, 0.14)",
        marginBottom: "5px",
        width: "288px",
        alignSelf: "center",
      }}
      id="copilot-options"
    >
      <>
        <Typography
          style={{
            textAlign: "left",
            fontSize: "14px",
            lineHeight: "20px",
            fontWeight: 500,
            color: "#242424",
            fontFamily: "DM Sans",
            padding: "8px 4px 4px 4px",
          }}
        >
          Try with Spurfit Copilot
        </Typography>

        {isMealPlan ? (
          <Option
            selectedOption={selectedOption}
            title={GENERATE_MEAL_PLAN}
            icon={DietPlanIcon}
            onClick={() => {
              if (showWelcomeMsg) {
                setShowWelcomeMsg(false);
              }
              setSelectedOption(GENERATE_MEAL_PLAN);
              clearStates();
            }}
          />
        ) : isWorkouts ? (
          <Option
            selectedOption={selectedOption}
            title={GENERATE_SINGLE_WORKOUT}
            icon={WorkoutPlanIcon}
            onClick={() => {
              if (showWelcomeMsg) {
                setShowWelcomeMsg(false);
              }
              setSelectedOption(GENERATE_SINGLE_WORKOUT);
              clearStates();
            }}
          />
        ) : isRecipe ? (
          <Option
            onClick={() => {
              if (showWelcomeMsg) {
                setShowWelcomeMsg(false);
              }
              setSelectedOption(GENERATE_RECIPE);
              clearStates();
            }}
            selectedOption={selectedOption}
            title={GENERATE_RECIPE}
            icon={DietPlanIcon}
          />
        ) : (
          <>
            <Option
              onClick={() => {
                if (showWelcomeMsg) {
                  setShowWelcomeMsg(false);
                }
                setSelectedOption(CREATE_WORKOUT_PLAN);
                mixpanel.track("Trainer_AskCopilot_CreateWorkout");
                clearStates();
              }}
              selectedOption={selectedOption}
              title={CREATE_WORKOUT_PLAN}
              icon={WorkoutPlanIcon}
            />

            {/* <Option
              onClick={() => {
                if (showWelcomeMsg) {
                  setShowWelcomeMsg(false);
                }
                mixpanel.track("Trainer_AskCopilot_CreateMealPlan");
                setSelectedOption(CREATE_MEAL_PLAN);
                clearStates();
              }}
              selectedOption={selectedOption}
              title={CREATE_MEAL_PLAN}
              icon={DietPlanIcon}
            /> */}

            <Option
              onClick={() => {
                if (showWelcomeMsg) {
                  setShowWelcomeMsg(false);
                }
                setSelectedOption(ASSIST_MODIFY_WORKOUTS);
                clearStates();
              }}
              selectedOption={selectedOption}
              title={ASSIST_MODIFY_WORKOUTS}
              icon={EditIcon}
            />

            {/* <Option
              onClick={() => {
                if (showWelcomeMsg) {
                  setShowWelcomeMsg(false);
                }
                setSelectedOption(ADD_AND_MANAGE_CLIENTS);
                clearStates();
              }}
              selectedOption={selectedOption}
              title={ADD_AND_MANAGE_CLIENTS}
              icon={ClientIcon}
            />

            <Option
              onClick={() => {
                if (showWelcomeMsg) {
                  setShowWelcomeMsg(false);
                }
                setSelectedOption(ANALYSE_CLIENT_MEDICAL_PROFILE);
                clearStates();
              }}
              selectedOption={selectedOption}
              title={ANALYSE_CLIENT_MEDICAL_PROFILE}
              icon={AnalyseIcon}
            />

            <Option
              onClick={() => {
                if (showWelcomeMsg) {
                  setShowWelcomeMsg(false);
                }
                setSelectedOption(GET_INSIGHTS_ON_CLIENT_WORKOUTS);
                clearStates();
              }}
              selectedOption={selectedOption}
              title={GET_INSIGHTS_ON_CLIENT_WORKOUTS}
              icon={InsightIcon}
            /> */}

            <Option
              onClick={() => {
                if (showWelcomeMsg) {
                  setShowWelcomeMsg(false);
                }
                setSelectedOption(KNOW_MORE);
                clearStates();
              }}
              selectedOption={selectedOption}
              title={KNOW_MORE}
              icon={InfoIcon}
            />
          </>
        )}
        <Divider
          sx={{ width: "275px", height: "1px", mb: "2px" }}
          color="#CFCFCF"
        />

        <Option
          onClick={() => {
            if (showWelcomeMsg) {
              setShowWelcomeMsg(false);
            }
            setSelectedOption(HELP_US_BE_BETTER);
            clearStates();
          }}
          selectedOption={selectedOption}
          title={HELP_US_BE_BETTER}
          icon={BulbIcon}
        />

        {/* <Option
          onClick={() => setSelectedOption(CREATE_SINGLE_WORKOUT)}
          selectedOption={selectedOption}
          title={CREATE_SINGLE_WORKOUT}
        />

        <Option
          onClick={() => setSelectedOption(CREATE_TRAINING_SET)}
          selectedOption={selectedOption}
          title={CREATE_TRAINING_SET}
        /> */}
      </>
    </div>
  );
};

export default SpurfitOptions;

export const Option = ({ onClick, title, selectedOption, icon }) => (
  <div
    onClick={onClick}
    className="hover-effect"
    style={{
      width: "286px",
      display: "flex",
      alignSelf: "center",
      alignItems: "center",
      cursor: "pointer",
      padding: "6px 4px 6px 6px",
      height: "36px",
      // background:
      //   selectedOption === title
      //     ? "linear-gradient(to right, rgba(70, 79, 235, 0.1), rgba(131, 48, 233, 0.1))"
      //     : "rgba(255, 255, 255, 0.1)",
    }}
  >
    <img
      src={icon}
      style={{
        height: title === CREATE_MEAL_PLAN ? "18px" : "20px",
        width: title === CREATE_MEAL_PLAN ? "18px" : "20px",
        marginRight: title === CREATE_MEAL_PLAN ? "6px" : "4px",
        marginLeft: "6px",
      }}
      alt="add"
    />
    <span
      style={{
        fontSize: "14px",
        lineHeight: "20px",
        fontWeight: 400,
        fontFamily: "DM Sans",
        color: "#242424",
        fontStyle: "normal",
      }}
    >
      {title}
    </span>
  </div>
);
