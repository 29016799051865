import { getAllAffiliatesDashboard } from "@/ApiServices/Analytics/Api";
import { GenericCell, HeaderCell } from "@/components/Dashboard/helpers";
import { useEffect, useState } from "react";
import ShowTable from "./ShowTable";

const AllAffiliateAnalytics = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const getData = async () => {
    setLoading(true);
    let data = await getAllAffiliatesDashboard();

    setData(data);
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);

  const affiliateColumns = [
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      headerAlign: "center",
      minWidth: 120,
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Email" />,
      renderCell: (params) => <GenericCell label={params?.row?.email} />,
    },
    {
      field: "signed up",
      headerName: "Signed Up",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => (params?.row?.hasSignedUp ? "Yes" : "No"),
      renderHeader: () => <HeaderCell label="Signed Up" />,
      renderCell: (params) => (
        <GenericCell label={params?.row?.hasSignedUp ? "Yes" : "No"} />
      ),
    },
    {
      field: "coachName",
      headerName: "Coach Name",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Coach Name" />,
      renderCell: (params) => <GenericCell label={params?.row?.coachName} />,
    },
    {
      field: "coachID",
      headerName: "coachID",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => params?.row?.coachID,
      renderHeader: () => <HeaderCell label="Coach ID" />,
      renderCell: (params) => <GenericCell label={params?.row?.coachID} />,
    },
  ];

  return (
    <ShowTable
      data={data}
      columns={affiliateColumns}
      getRowId={(row) => row.id}
      loading={loading}
      key={1}
    />
  );
};

export default AllAffiliateAnalytics;
