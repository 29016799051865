export const Tokens = {
  speed: "#1E88E5",
  heartrate: "#EB4643",
  elevation: "#FFC107",
  power: "#FB8C00",
  cadence: "#C050DE",
  // dark color for light background
  stridelength: "#4CAF50",
};

export const colourTokens = {
  selectedWorkout: "#FB8C00",
  workoutFromCalender: "#1E88E5",
};
