import { store } from "@/redux/store";
import axios from "axios";

const getExerciseByCoachID = async (coachID) => {
  try {
    let res = await axios({
      url: `${
        store.getState().auth.apiURL
      }CF/api/CoachWeb/Get/getAllMovements?coachID=${coachID}`,
    });
    return res.data.result;
  } catch (error) {
    console.log(error);
  }
  return [];
};

export const getAllExercise = async (coachID, adminID) => {
  let coachExercises = await getExerciseByCoachID(coachID);
  let adminExercises = adminID ? await getExerciseByCoachID(adminID) : [];
  return [...coachExercises, ...adminExercises];
};

export const getAllExerciseForStrengthWorkout = async (atheleteID) => {
  try {
    let res = await axios({
      method: "GET",
      url: `${store.getState().auth.apiURL}CF/api/getAllTheMomentsByTheUser`,
      params: {
        atheleteID,
      },
    });
    return res?.data;
  } catch (e) {
    return null;
  }
  return [];
};

export const getGlobalExercisesForCoach = async (coachID) => {
  try {
    let res = await axios({
      url: `${
        store.getState().auth.apiURL
      }CF/api/CoachWeb/Get/getGlobalMovementLibrary?coachID=${coachID}`,
    });
    return res.data.result;
  } catch (error) {
    console.log(error);
  }
  return [];
};
