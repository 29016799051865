import React from "react";

const PrimaryCloseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        d="M11.2998 5.20117L4.70014 11.8008"
        stroke="#4339F2"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.7002 5.19922L11.2999 11.7989"
        stroke="#4339F2"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default PrimaryCloseIcon;
