import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import SpinnerComponent from "../layout/spinner";
// import { navigate } from 'gatsby';
import isEmpty from "lodash/isEmpty";
import { useHistory, useLocation } from "react-router-dom";
import {
  getClientProfileSummary,
  getTraineeDetails,
} from "../../ApiServices/ClientProfileApi/Api";
import Calendar from "../Calendar/Calendar";
// import PastCalendarModule from '../../components/Calendar/PastCalendarModule';
import { Box, Typography } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import mixpanel from "mixpanel-browser";
import { useSelector } from "react-redux";
import { CustomAlertContext } from "../../App";
import { chatClient, chatInit } from "../../utils/chat";
import Arrow from "../Calendar/Arrow";
import NewWorkoutDialog from "../CreateWorkoutComp/NewWorkoutDialog.js";
import AllImagesModal from "../Dashboard/AllImagesModal";
import DialogBox from "../Dialog/Dialog";
import Notes from "../Notes/Notes";
import ProfileImage from "../ProfileImage";
import WorkoutInfoDialog from "../WorkoutComponent/WorkoutInfoDialog";
import AddActivityDialog from "./AddActivityDialog.js";
import AddWorkoutAndMealModal from "./AddWorkoutAndMealModal";
import AssignCardioModal from "./AssignCardioModal.js";
import ClientProfileSummary from "./ClientProfileSummary.js";
import CreateFoodModal from "./CreateFoodModal";
import CreateMacrosModal from "./CreateMacrosModal";
import CreateMealPlanModal from "./CreateMealPlanModal";
import ImportMealModal from "./ImportMealModal";
import AllMeasurementsChart from "./charts/allMeasurements";
import SleepChart from "./charts/seelp";
import StepsChart from "./charts/steps";
import WeightsChart from "./charts/weights";
import { createActivity, updateActivity } from "./util.js";

export default function ClientProfile() {
  let getPosition = (string, subString, index) => {
    return string.split(subString, index).join(subString).length;
  };

  const userUUID = useLocation().pathname.slice(
    getPosition(useLocation().pathname, "/", 2) + 1,
    useLocation().pathname.length - 1
  );

  // IN DEV
  // const userUUID = useLocation().pathname.slice(8, useLocation().pathname.length - 1);
  // IN PROD
  // const userUUID = useLocation().pathname.slice(35, urlTest.length);
  const [userRelatedData, setUserRelatedData] = useState({});
  const [userMealPlan, setUserMealPlan] = useState({});
  const [userActivity, setUserActivity] = useState({});
  const [userData, setUserData] = useState({});
  const [channelId, setchannelId] = useState({});
  const [isSpinnerActive, setSpinnerStatus] = useState(true);
  const [userImage, setUserImage] = useState("");
  const [trainerCredentials, setTrainerCredentials] = useState();
  const [newWorkoutModel, setNewWorkoutModel] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [disableArrow, setDisableArrow] = useState("");
  const [showWorkOutBuilder, setShowWorkOutBuilder] = useState(false);
  const [showAddActivityModal, setShowAddActivityModal] = useState(false);
  const [creatOutBuilder, setCreatOutBuilder] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [payload, setPayload] = useState("");
  const [stats, setStats] = useState();
  const [selectedActivity, setSelectedActivity] = useState({});
  // const [startDate, setStartDate] = useState("");
  // const [endDate, setEndDate] = useState("");
  const [calStartDate, setCalStartDate] = useState(
    moment().startOf("month").toDate()
  );
  const [movements, setMovements] = useState(0);
  const { displayAlert } = useContext(CustomAlertContext);

  const [openImportMealModal, setOpenImportMealModal] = useState(false);
  const [openCreateMealModal, setOpenCreateMealModal] = useState(false);
  const [openCreateMacrosModal, setOpenCreateMacrosModal] = useState(false);
  const [openCreateFoodModal, setOpenCreateFoodModal] = useState(false);
  const [mealData, setMealData] = useState([]);
  const [mealDetails, setmealDetails] = useState([]);
  const [updateData, setUpdateData] = useState(false);
  const [openWorkoutInfoModal, setOpenWorkoutInfoModal] = useState(false);
  const [bodyStatsData, setBodyStatsData] = useState({});
  const [wearableData, setWearableData] = useState({});
  const [showAllImages, setShowAllImages] = useState(false);
  const [showWeightsChart, setShowWeightsChart] = useState(false);
  const [showMeasurementChart, setShowMeasurementChart] = useState(false);
  const [stepCount, setStepCount] = useState(0);
  const [sleepHours, setSleepHours] = useState(0);
  const [showSleepChart, setShowSleepChart] = useState(false);
  const [showStepsChart, setShowStepsChart] = useState(false);
  const [activeTab, setActiveTab] = useState("Schedule");
  const [showAddCardioModal, setShowAddCardioModal] = useState(false);
  const [existingCardioInfo, setExistingCardioInfo] = useState(null);
  const [currentClientProfile, setCurrentClientProfile] = useState({});
  const coachID = useSelector((state) => state.auth.trainerUUID);

  const history = useHistory();

  const buttonStyle = {
    color: "#ffffff",
    marginTop: "1vh",
    marginBottom: "1vh",
    paddingTop: "1vw",
    paddingBottom: "1vw",
    borderRadius: "10px",
    minWidth: 311,
  };

  const getWeekFunction = (direction) => {
    setDisableArrow("");
    let updatedMonth;
    if (direction === "left") {
      updatedMonth = moment(calStartDate).subtract(1, "months").toDate();
    } else {
      updatedMonth = moment(calStartDate).add(1, "months").toDate();
    }
    setCalStartDate(updatedMonth);
    setTimeout(() => {
      setMovements(movements + 1);
    }, 200);
  };
  const init = async () => {
    if (selectedDate) {
      let payload = {
        moduleDetails: [],
        calories: "",
        moduleDate: moment(selectedDate)
          ?.set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          })
          ?.format("YYYY-MM-DD"),
        name: "",
        moduleDuration: "",
        tag: "",
        trainerUUID: JSON.parse(`${trainerCredentials}`)?.password,
        userUUID,
      };
      setPayload(payload);
    }
  };
  useEffect(() => {
    init();
  }, [selectedDate]);

  const update = async () => {
    setUserData({});
    setTrainerCredentials(localStorage.getItem("trainerCredentials"));
    mixpanel.init("147a208f5832549c876d94601401033e", {
      //debug: true,{"name": trainerDetails?.name, "email": trainerDetails?.email}
      api_host: "https://api.mixpanel.com",
      ignore_dnt: true,
    });
    mixpanel.identify(trainerCredentials?.password);
    mixpanel.people.set_once({
      Name: trainerCredentials?.Name,
      Email: trainerCredentials?.Email,
    });
    mixpanel.track("Trainer_ClientView");
    // await axios({
    //   method: "get",
    //   url: `${APIURL}api/traineeinfo`,
    //   params: {
    //     userUUID: `${userUUID}`,
    //   },
    // })
    //   .then((res) => {
    //     setUserDetails(res.data.result);
    //     console.log("trainee info: ", res.data);
    //   })
    //   .catch((err) => {
    //     displayAlert("Trainee info failed to fetch", err);
    //   });

    try {
      getStreamChannelId();
      const summaryData = await getClientProfileSummary(userUUID);
      const res = await getTraineeDetails(userUUID);
      setCurrentClientProfile(res);
      // const res2 = await getUserWorkOutStats(userUUID);
      setBodyStatsData(res?.bodyStatsData);
      setWearableData(res?.wearableData);
      setUserDetails(summaryData?.userInfo);
      setUserImage(summaryData?.userInfo?.userImageKey);
      // const [res,res2] = await Promise.allSettled([getTraineeDetails(userUUID), getUserWorkOutStats(userUUID)]);

      setUserData(summaryData);
      setSpinnerStatus(false);
      let DATA = [];
      let currentMonthmodules = res.currentMonthmodules;
      Object.entries(currentMonthmodules)?.map(([key, value]) => {
        value?.forEach((item) => {
          DATA.push({
            ModuleDate: key,
            Complete: item?.finished,
            Name: item?.workoutAssigned?.name,
            PrimaryKey: item?.id,
            WorkoutId: item?.workoutAssigned?.id,
          });
        });
      });
      console.log("user related data : ", res.mealData);
      setUserActivity(res.activityData);
      setUserRelatedData(DATA);
      setUserMealPlan(res.mealData);

      // if (isEmpty(pastweekmodules)) {
      //   setDisableArrow("left");
      // }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    update();
  }, [updateData]);

  const reloadProfile = async () => {
    setSpinnerStatus(true);
    try {
      const res = await getTraineeDetails(userUUID);
      setCurrentClientProfile(res);
      setBodyStatsData(res?.bodyStatsData);
      setWearableData(res?.wearableData);

      let DATA = [];
      let currentMonthmodules = res.currentMonthmodules;
      Object.entries(currentMonthmodules)?.map(([key, value]) => {
        value?.forEach((item) => {
          DATA.push({
            ModuleDate: key,
            Complete: item?.finished,
            Name: item?.workoutAssigned?.name,
            PrimaryKey: item?.id,
            WorkoutId: item?.workoutAssigned?.id,
          });
        });
      });

      setUserRelatedData(DATA);
      setUserMealPlan(res.mealData);
      setUserActivity(res.activityData);
      setSpinnerStatus(false);
    } catch (err) {
      console.log(err);
      setSpinnerStatus(false);
    }
  };

  const getStreamChannelId = async () => {
    try {
      if (!chatClient?.userID) {
        await chatInit(); //CONNECTING TO GETSTREAM IO
      }
      const filter = {
        type: "messaging",
        members: {
          $eq: [
            JSON.parse(localStorage.getItem("trainerCredentials"))?.password,
            userUUID,
          ],
        },
      };
      const sort = [{ last_message_at: -1 }];
      const channels = await chatClient.queryChannels(filter, sort, {
        watch: true, // this is the default
        state: true,
      });
      console.log("channels: ", channels);
      if (channels && channels.length > 0) setchannelId(channels[0].id);
    } catch (err) {
      console.log("error in getting channel id: ", err);
    }
  };

  const getStepsFromWearableData = () => {
    let steps = 0;
    if (
      wearableData?.[0]?.dateAssigned &&
      moment(wearableData?.[0]?.dateAssigned).format("DD-MM-YYYY") ===
        moment().format("DD-MM-YYYY")
    ) {
      steps = wearableData?.[0]?.activitiesFinished?.stepCount;
      setStepCount(steps);
    }
    return steps;
  };

  const getSleepFromWearableData = () => {
    let sleep = 0;
    if (
      wearableData?.[0]?.dateAssigned &&
      moment(wearableData?.[0]?.dateAssigned).format("DD-MM-YYYY") ===
        moment().format("DD-MM-YYYY")
    ) {
      sleep = wearableData?.[0]?.activitiesFinished?.sleep;
      setSleepHours(sleep);
    }
    return sleep;
  };

  useEffect(() => {
    getSleepFromWearableData();
    getStepsFromWearableData();
  }, [wearableData]);

  const formatTime = (seconds) => {
    // const seconds = 23456;
    let timeArr = [];
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    let result = "";
    if (hours > 0) {
      result = hours + " hr" + (hours > 1 ? "s" : "") + " ";
      timeArr.push(result);
    }
    if (minutes > 0) {
      result = minutes + " min" + (minutes > 1 ? "s" : "") + " ";
      timeArr.push(result);
    }
    // if (remainingSeconds > 0) {
    //   result = remainingSeconds + " sec";
    //   timeArr.push(result);
    // }
    return timeArr;
  };

  const renderPerformanceCard = (title, value, unit, icon) => (
    <div
      style={{
        background:
          "linear-gradient(to right, rgba(70, 79, 235, 0.4), rgba(131, 48, 233, 0.4))",
      }}
      className="flex items-center gap-3  p-2 py-3 w-[200px] rounded-lg"
    >
      <div className="p-1.5">
        <img
          src={icon}
          alt="calendar"
          className="ml-2"
          style={{
            height: 40,
            width: 40,
          }}
        />
      </div>
      {title === "Time" ? (
        <div className="flex flex-col text-white-pure justify-center">
          <p
            className="text-font14 "
            style={{
              color: "#000",
            }}
          >
            {title}
          </p>
          <div
            style={{
              color: "#000",
            }}
            className="flex "
          >
            {formatTime(value)?.map((item) => {
              const t = item.split(" ");
              return (
                <p
                  style={{
                    color: "#000",
                  }}
                  className="flex flex-row mr-1 items-baseline text-base text-font20 text-secondaryOrange"
                >
                  {t[0]}
                  <span className="text-font12"> {t[1]}</span>
                </p>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="flex flex-col text-white-pure justify-center">
          <p
            className="text-font14"
            style={{
              color: "#000",
            }}
          >
            {title}
          </p>
          <p
            className="text-base text-font20 text-secondaryOrange"
            style={{
              color: "#000",
            }}
          >
            {value}
            <span
              className="text-font12"
              style={{
                color: "#000",
              }}
            >
              {" "}
              {unit}
            </span>
          </p>
        </div>
      )}
    </div>
  );

  if (isSpinnerActive && isEmpty(userData)) return <SpinnerComponent />;
  if (isSpinnerActive) return <SpinnerComponent />;
  const onActivitiesSave = async (data, userUUID, selectedDate) => {
    setSpinnerStatus(true);
    let payload = {
      dateAssigned:
        moment(selectedDate, "DD-MM-YYYY").format("YYYY-MM-DD") +
        "T00:00:00.000Z",
      atheleteID: userUUID,
      activitiesAssigned: data,
    };

    let isUpdate = false;
    let activityId = "";
    if (selectedActivity?.id) {
      isUpdate = true;
      activityId = selectedActivity?.id;
      let activitiesAssigned = selectedActivity?.activitiesAssigned;
      payload = {
        ...payload,
        activitiesAssigned: {
          ...payload.activitiesAssigned,
          ...activitiesAssigned,
        },
      };
    }

    if (isUpdate) {
      await updateActivity(payload, activityId);
    } else {
      await createActivity(payload);
    }
    const res = await getTraineeDetails(userUUID);
    setUserActivity(res.activityData);
    setSpinnerStatus(false);
    setSelectedActivity({});
  };

  return (
    <>
      <div
        className="flex sm:flex-col  w-full justify-between "
        style={{
          borderRadius: "20px",
          background: "#FFF",
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.03)",
          marginBottom: "15px",
          paddingLeft: "32px",
          paddingRight: "32px",
          paddingTop: "12px",
          paddingBottom: "12px",
        }}
      >
        <div className="flex  items-center  mb-2">
          <ProfileImage
            src={
              userImage ||
              "https://img.icons8.com/color/48/circled-user-male-skin-type-7--v1.png"
            }
            name={userDetails?.name}
          />
          <div className="ml-3 text-primaryBlue font-medium">
            <p
              className="text-font20 font-DMSans"
              style={{
                color: "#231F20",
              }}
            >
              {userDetails?.name}
            </p>
            <p
              className="text-font14 font-DMSan"
              style={{
                color: "#424242",
              }}
            >
              {userDetails?.email}
            </p>
            <p
              className="text-secondaryOrange text-font12"
              style={{
                color: "#000",
              }}
            >
              {userData?.planStaticData?.StartDate
                ? moment(userData?.planStaticData?.StartDate).format(
                    "DD MMMM YYYY"
                  )
                : ""}
            </p>
          </div>
        </div>
        <Tabs
          TabIndicatorProps={{
            sx: {
              display: "none",
            },
          }}
          value={activeTab}
          onChange={(e, value) => setActiveTab(value)}
          aria-label="client-profile-tabs"
        >
          <Tab
            label={<TabHeader title={"Summary"} activeTab={activeTab} />}
            value="Summary"
            style={{
              marginRight: "50px",
            }}
          />
          <Tab
            label={<TabHeader title={"Schedule"} activeTab={activeTab} />}
            value="Schedule"
          />
        </Tabs>
      </div>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          marginTop: 10,
        }}
      >

        <div
          className="flex gap-2 items-center p-1 cursor-pointer"
          onClick={() => setOpenDialog(!openDialog)}
        >
          <img src={addNotes} alt="notes" />
          <p className="text-sm">Add notes</p>
        </div>
        {bodyStatsData && (
          <>
            <div
              onClick={() => setShowWeightsChart(true)}
              className="flex gap-2 items-center p-1 text-sm cursor-pointer"
            >
              <img
                style={{
                  height: 24,
                  width: 24,
                }}
                src={WeightIcon}
                alt="bodyweight"
              />
              <p lassName="text-sm">Weight: </p>
              <span> {getLatestWeightFromBodyStats()} kg </span>
            </div>


            <div
              onClick={() => setShowAllImages(true)}
              className="flex gap-2 items-center p-1 cursor-pointer text-sm"
            >
              <img
                style={{
                  height: 24,
                  width: 24,
                }}
                src={CameraIcon}
                alt="images"
              />
              <p lassName="text-sm">Photos: </p>
              {getAllImageCountFromBodyStats()}
            </div>

            <div
              onClick={() => {
                setShowMeasurementChart(true);
              }}
              className="flex gap-2 items-center p-1 cursor-pointer text-sm"
            >
              <img
                style={{
                  height: 24,
                  width: 24,
                }}
                src={"https://img.icons8.com/wired/64/000000/8330E9/ruler.png"}
                alt="images"
              />
              <p lassName="text-sm">Measurements </p>
            </div>

            <div
              onClick={() => setShowStepsChart(true)}
              className="flex gap-2 items-center p-1 text-sm cursor-pointer"
            >
              <img
                style={{
                  height: 24,
                  width: 24,
                }}
                src={"https://img.icons8.com/ios/50/8330E9/walking--v1.png"}
                alt="images"
              />
              <p lassName="text-sm">Steps: </p>
              <span> {stepCount} </span>
            </div>
            <div
              onClick={() => {
                setShowSleepChart(true);
              }}
              className="flex gap-2 items-center p-1 text-sm cursor-pointer"
            >
              <img
                style={{
                  height: 24,
                  width: 24,
                }}
                src={"https://img.icons8.com/ios/50/8330E9/occupied-bed.png"}
                alt="images"
              />
              <p lassName="text-sm">Sleep: </p>
              <span> {sleepHours} hr</span>
            </div>
          </>
        )}
      </div> */}
      {showAllImages && (
        <AllImagesModal
          isOpen={showAllImages}
          setIsOpen={setShowAllImages}
          bodyStatsData={bodyStatsData}
        />
      )}
      <div className="w-full flex flex-col">
        <div>
          {/* tabs were here previously */}
          <CustomTabPanel
            sx={{
              padding: "0px",
            }}
            value={"Summary"}
            activeTab={activeTab}
          >
            <ClientProfileSummary data={userData} userImage={userImage} />
          </CustomTabPanel>
          {/* changes by jatin */}
          <CustomTabPanel
            style={{
              padding: "0px",
              marginTop: 10,
            }}
            value={"Schedule"}
            activeTab={activeTab}
          >
            <div
              className="client-profile-schedule"
              style={{
                width: "100%",
                backgroundColor: "#FFFFFF",
                borderRadius: "10px",
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.03)",
                marginBottom: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  alignItems: "center",
                  borderBottom: "2px solid #E5E5E5",
                  padding: "15px 0px",
                }}
              >
                <Arrow
                  getWeekFunction={getWeekFunction}
                  disableArrow={disableArrow}
                >
                  {moment(calStartDate).format("MMMM YYYY")}
                </Arrow>
              </div>

              <Calendar
                DATA={userRelatedData}
                mealData={userMealPlan}
                activityData={userActivity}
                setShowWorkOutBuilder={setShowWorkOutBuilder}
                setSelectedDate={setSelectedDate}
                movements={movements}
                calStartDate={calStartDate}
                setDisableArrow={setDisableArrow}
                userData={userData}
                userUUID={userUUID}
                setSelectedActivity={setSelectedActivity}
                profileData={currentClientProfile}
              />

              {/* {isEmpty(userRelatedData) && (
            <EmptyCalender
              DATA={userRelatedData}
              setShowWorkOutBuilder={setShowWorkOutBuilder}
              setSelectedDate={setSelectedDate}
              propsPassing={propsPassing}
              movements={movements}
              arrowClicked={arrowClicked}
              startDate={startDate}
              endDate={endDate}
              setDisableArrow={setDisableArrow}
              userData={userData}
            />
          )} */}
            </div>
          </CustomTabPanel>
        </div>
        {/* <ProgressPictureCard
          planStaticData={userData?.planStaticData}
          profileInfo={userData?.profileInfo}
        /> */}
      </div>
      {openDialog && (
        <DialogBox
          maxWidth={"sm"}
          open={openDialog}
          onClose={() => setOpenDialog(!openDialog)}
        >
          <Notes
            onClose={setOpenDialog}
            userUUID={userUUID}
            trainerUUID={JSON.parse(`${trainerCredentials}`)?.password}
          />
        </DialogBox>
      )}
      {showAddCardioModal && (
        <AssignCardioModal
          isOpen={showAddCardioModal}
          setIsOpen={setShowAddCardioModal}
          userUUID={userUUID}
          startDate={selectedDate}
          info={existingCardioInfo}
          userProfile={currentClientProfile}
          onClose={() => {
            setShowAddCardioModal(false);
            setExistingCardioInfo(null);
          }}
          onAssign={(data) => {
            // setUpdateData(!updateData);
            setExistingCardioInfo(null);
            reloadProfile();
          }}
        />
      )}
      {showWorkOutBuilder && (
        <>
          <AddWorkoutAndMealModal
            showWorkOutBuilder={showWorkOutBuilder}
            setShowWorkOutBuilder={setShowWorkOutBuilder}
            setNewWorkoutModel={setNewWorkoutModel}
            setShowAddCardioModal={setShowAddCardioModal}
            newWorkoutModel={newWorkoutModel}
            userUUID={userUUID}
            selectedDate={selectedDate}
            setOpenImportMealModal={setOpenImportMealModal}
            setOpenCreateMealModal={setOpenCreateMealModal}
            setOpenWorkoutInfoModal={setOpenWorkoutInfoModal}
            setShowAddActivityModal={setShowAddActivityModal}
            setExistingCardioInfo={setExistingCardioInfo}
            workoutData={userRelatedData}
            clientProfile={currentClientProfile}
          />
        </>
      )}
      {showAddActivityModal && (
        <AddActivityDialog
          isOpen={showAddActivityModal}
          setIsOpen={setShowAddActivityModal}
          assignedActivities={selectedActivity?.activitiesAssigned}
          onSaveActivity={(data) => {
            onActivitiesSave(data, userUUID, selectedDate);
          }}
        />
      )}
      {openWorkoutInfoModal && (
        <WorkoutInfoDialog
          isOpen={openWorkoutInfoModal}
          setIsOpen={setOpenWorkoutInfoModal}
          data={{
            isCalendar: true,
            userUUID: userUUID,
            date: `${moment(selectedDate, "DD-MM-YYYY")
              .startOf("day")
              .format("YYYY-MM-DDTHH:mm:ss")}Z`,
          }}
        />
  
      )}
      {openImportMealModal && (
        <ImportMealModal
          showModal={openImportMealModal}
          setShowModal={setOpenImportMealModal}
          setmealDetails={setmealDetails}
          selectedDate={selectedDate}
          setShowWorkOutBuilder={setShowWorkOutBuilder}
          userUUID={userUUID}
          setUpdateData={setUpdateData}
          updateData={updateData}
        />
      )}
      {openCreateMealModal && (
        <CreateMealPlanModal
          isOpen={openCreateMealModal}
          setIsOpen={setOpenCreateMealModal}
          setNutritionData={setMealData}
          openMacros={setOpenCreateMacrosModal}
          openFood={setOpenCreateFoodModal}
        />
      )}
      {openCreateMacrosModal && (
        <CreateMacrosModal
          isOpen={openCreateMacrosModal}
          setIsOpen={setOpenCreateMacrosModal}
          setMealData={setMealData}
          item={mealData}
          selectedDate={selectedDate}
          userUUID={userUUID}
          setUpdateData={setUpdateData}
          updateData={updateData}
        />
      )}
      {openCreateFoodModal && (
        <CreateFoodModal
          isOpen={openCreateFoodModal}
          setIsOpen={setOpenCreateFoodModal}
          setMealData={setMealData}
          item={mealData}
          selectedDate={selectedDate}
          userUUID={userUUID}
          setUpdateData={setUpdateData}
          updateData={updateData}
        />
      )}

      {/* {creatOutBuilder && (
        <DialogBox
          fullScreen={true}
          maxWidth={"sm"}
          open={creatOutBuilder}
          onClose={() => setCreatOutBuilder(!creatOutBuilder)}
        >
          <CreateWorkoutComp
            onClose={() => setCreatOutBuilder(!creatOutBuilder)}
            data={payload}
            trainerUUID={JSON.parse(`${trainerCredentials}`)?.password}
            userUUID={userUUID}
          />
        </DialogBox>
      )} */}
      {newWorkoutModel && (
        <NewWorkoutDialog
          newWorkoutDialog={newWorkoutModel}
          setnewWorkoutDialog={setNewWorkoutModel}
          data={payload}
          trainerUUID={JSON.parse(`${trainerCredentials}`)?.password}
          userUUID={userUUID}
          isCalendar={true}
          setPayloadData={setPayload}
          setCreatOutBuilder={setCreatOutBuilder}
          moduleDate={moment(selectedDate, "DD-MM-YYYY")
            ?.set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
            ?.format("YYYY-MM-DD")}
        />
      )}
      {showWeightsChart && (
        <WeightsChart
          isOpen={showWeightsChart}
          setIsOpen={setShowWeightsChart}
          data={bodyStatsData}
        />
      )}
      {showMeasurementChart && (
        <AllMeasurementsChart
          isOpen={showMeasurementChart}
          setIsOpen={setShowMeasurementChart}
          data={bodyStatsData}
        />
      )}
      {showSleepChart && (
        <SleepChart
          isOpen={showSleepChart}
          setIsOpen={setShowSleepChart}
          data={wearableData}
        />
      )}

      {showStepsChart && (
        <StepsChart
          isOpen={showStepsChart}
          setIsOpen={setShowStepsChart}
          data={wearableData}
          isSteps={true}
        />
      )}
    </>
  );
}

export const TabHeader = ({ title, count, activeTab }) => {
  let isActiveTab = activeTab === title;

  return (
    <Box
      sx={{
        display: "flex",
        textTransform: "none",
        color: isActiveTab ? "#242424" : "#424242",
        position: "relative",
        textAlign: "center",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          fontSize: "16px",
          fontFamily: "DM Sans",
          fontWeight: isActiveTab ? "700" : "500",
          lineHeight: "22px",
        }}
      >
        {title}
      </Typography>
      {count && (
        <Typography
          sx={{
            padding: "0px 4px",
            fontSize: "16px",
            fontFamily: "DM Sans",
            fontWeight: 500,
            lineHeight: "22px",
            backgroundColor: isActiveTab
              ? "rgba(131, 48, 233, 0.20)"
              : "rgba(66, 66, 66, 0.10)",
            marginLeft: "4px",
            borderRadius: "4px",
          }}
        >
          {count}
        </Typography>
      )}
      {isActiveTab && (
        <div
          style={{
            height: "3px",
            width: "100%",
            position: "absolute",
            bottom: -10,
            borderRadius: "4px",
            background: "linear-gradient(90deg, #464FEB, #8330E9)",
            transition: "all 0.3s ease-in-out",
          }}
        />
      )}
    </Box>
  );
};

function CustomTabPanel(props) {
  const { children, value, activeTab, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== activeTab}
      id={`simple-tabpanel-${activeTab}`}
      aria-labelledby={`simple-tab-${activeTab}`}
      {...other}
    >
      {value === activeTab && <>{children}</>}
    </div>
  );
}
