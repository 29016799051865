import React from "react";

const MoreIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12.0376 12.8701C12.5554 12.8701 12.9751 12.4504 12.9751 11.9326C12.9751 11.4149 12.5554 10.9951 12.0376 10.9951C11.5198 10.9951 11.1001 11.4149 11.1001 11.9326C11.1001 12.4504 11.5198 12.8701 12.0376 12.8701Z"
        stroke="#333333"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.9375 12.8701C19.4553 12.8701 19.875 12.4504 19.875 11.9326C19.875 11.4149 19.4553 10.9951 18.9375 10.9951C18.4197 10.9951 18 11.4149 18 11.9326C18 12.4504 18.4197 12.8701 18.9375 12.8701Z"
        stroke="#333333"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.9375 12.8701C5.45527 12.8701 5.875 12.4504 5.875 11.9326C5.875 11.4149 5.45527 10.9951 4.9375 10.9951C4.41973 10.9951 4 11.4149 4 11.9326C4 12.4504 4.41973 12.8701 4.9375 12.8701Z"
        stroke="#333333"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default MoreIcon;
