import React, { useState } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import MoreVertIcon from "@mui/icons-material/MoreVert";

function MenuOptions({
  customIcon,
  options,
  optionIcons,
  onOptionClick,
  iconBtnStyles,
  isHoriz = false,
  menuItemStyles = [], // Array of custom styles for each menu item
  ...props
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    if (e) {
      e.stopPropagation();
    }
    setAnchorEl(null);
  };

  const handleOptionClick = (option) => {
    onOptionClick(option);
    handleClose();
  };

  return (
    <>
      <IconButton
        sx={{
          ...iconBtnStyles,
        }}
        variant="square"
        onClick={handleClick}
      >
        {customIcon ? (
          customIcon
        ) : (
          <>
            {isHoriz ? (
              <MoreHorizIcon fontSize="small" />
            ) : (
              <MoreVertIcon fontSize="small" />
            )}
          </>
        )}
      </IconButton>
      <Menu
        slotProps={{
          paper: {
            sx: {
              borderRadius: "12px",
              maxHeight: "290px",
            },
            ...props
          },
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        
      >
        {options.map((option, index) => (
          <MenuItem
            sx={{
              color: "#000",
              fontFeatureSettings: "'clig' off, 'liga' off",
              fontFamily: "DM Sans",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "22px",
              ...menuItemStyles[index], // Apply custom style for each menu item
            }}
            key={option}
            onClick={(e) => {
              e.stopPropagation();
              handleOptionClick(option);
            }}
          >
            {optionIcons[index] && (
              <span style={{ marginRight: "8px" }}>{optionIcons[index]}</span>
            )}
            {option}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default MenuOptions;
