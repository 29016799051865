import React from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

const DropDownMenu = ({
  triggerElement,
  options = [],
  onSelect,
  className,
  onOpen,
}) => {
  return (
    <DropdownMenu
      onOpenChange={(open) => {
        if (open && onOpen) {
          onOpen();
        }
      }}
    >
      <DropdownMenuTrigger className="outline-none">
        {triggerElement}
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className={`${className ?? ""} font-DMSans text-font12`}
      >
        {options.map((option, index) => (
          <DropdownMenuItem
            className="font-DMSans cursor-pointer"
            key={index}
            onClick={(e) => {
              e.stopPropagation();
              onSelect(option);
            }}
          >
            {option}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default DropDownMenu;
