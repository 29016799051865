import {
  Autocomplete,
  Dialog,
  DialogContent,
  Divider,
  MenuItem,
  TextField,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import React, { useEffect, useRef, useState } from "react";
import useOutsideClick from "../../../components/copilot/useOutsideClick";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import { useGetSpurfitExercisesQuery } from "../../../services/apiSlice";
import { decrementCurrentRound, incrementCurrentRound } from "./helpers";
import { display } from "styled-system";
import { useSelector } from "react-redux";

const SmartBuilder = ({
  isOpen,
  setIsOpen,
  data,
  setData,
  weeks,
  addWeeks,
  workoutsToCreate,
  setWorkoutsToCreate,
}) => {
  const [selectedDay, setSelectedDay] = useState(1);
  const [weekNum, setWeekNum] = useState(weeks);

  const [loading, setLoading] = useState(false);

  const [currentData, setCurrentData] = useState({ ...data });
  const [editingId, setEditingId] = useState(null);

  console.log("Smart data: ", { data, weeks });

  let currentWeekStartDayNumber = (weeks - 1) * 7 + 1;
  let currentWeekEndDayNumber = weeks * 7 + 1;

  const onWeekChange = (weekNum) => {
    // duplicate the data for each day in next with from last week
    // and add it to the current week
    let currentWeekStartDayNumber = (weekNum - 1) * 7 + 1;
    let currentWeekEndDayNumber = weekNum * 7 + 1;
    let newData = JSON.parse(JSON.stringify(currentData));
    for (let i = 0; i < 7; i++) {
      console.log("day", currentWeekEndDayNumber + i);
      if (
        currentData[currentWeekStartDayNumber + i] &&
        currentData[currentWeekStartDayNumber + i].length > 0 &&
        !currentData?.[currentWeekStartDayNumber + i]?.[0]?.rest
      ) {
        newData[currentWeekEndDayNumber + i] = [
          ...currentData[currentWeekStartDayNumber + i],
        ];
      }
    }
    console.log("new Data: ", newData);
    setCurrentData(newData);
  };

  const onWeekRemove = (weekNum) => {
    // remove the data for each day in next with from last week
    // and add it to the current week
    let currentWeekStartDayNumber = (weekNum - 1) * 7 + 1;
    let currentWeekEndDayNumber = weekNum * 7 + 1;
    let newData = { ...currentData };
    for (let i = 0; i <= 7; i++) {
      console.log("day", currentWeekEndDayNumber + i);

      delete newData[currentWeekEndDayNumber + i];
    }
    console.log("new Data: ", newData);
    setCurrentData(newData);
  };

  let array = Array.from({ length: weekNum - weeks + 2 }, (_, i) => i + 1);

  useEffect(() => {
    // set selectedDay to first date which has data in current week
    for (let i = currentWeekStartDayNumber; i <= currentWeekEndDayNumber; i++) {
      if (data[i] && data[i].length > 0 && !data?.[i]?.[0]?.rest) {
        setSelectedDay(i);
        break;
      }
    }
    onWeekChange(weeks);
  }, []);

  const load = () => {
    setLoading(true);
    let timeOut = setTimeout(() => {
      setLoading(false);
    }, 100);
    return () => {
      clearTimeout(timeOut);
    };
  };

  const onSave = () => {
    console.log("week", weekNum, weeks);
    for (let i = weeks; i <= weekNum; i++) {
      console.log("adding week", i);
      addWeeks(i);
    }
    setData(currentData);
    setIsOpen(false);
  };

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="add-week-dialog-title"
      maxWidth="md"
      fullWidth
      PaperProps={{
        style: {
          borderRadius: "20px",
        },
      }}
    >
      <div className="add-program-modal-head">
        <p className="add-program-modal-head-title">Smart Builder </p>
        <div
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            onSave();
          }}
        >
          <CloseRoundedIcon />
        </div>
      </div>
      <DialogContent>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flex: 1,
            gap: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              paddingRight: "20px",
              borderRight: "1px solid #000000",
              height: "100%",
            }}
          >
            {/* render all the days of current week. Data contains all the days data and day number is key */}
            {Object.keys(data).map((day) => {
              if (
                day != "" &&
                day >= currentWeekStartDayNumber &&
                day < currentWeekEndDayNumber
              ) {
                let haveData = false;
                if (data[day].length > 0 && !data?.[day]?.[0]?.rest) {
                  haveData = true;
                }
                return (
                  <p
                    key={day}
                    style={{
                      fontFamily: "DM Sans",
                      fontSize: "16px",
                      fontWeight: "500",
                      color: haveData ? "#000000" : "#BDBDBD",
                      margin: "0px",
                      background:
                        selectedDay == day
                          ? `linear-gradient(
                            90deg,
                            rgba(70, 79, 235, 0.1) 10.42%,
                            rgba(131, 48, 233, 0.1) 100%
                          )`
                          : "",
                      borderRadius: "3px",
                      padding: "5px 10px",
                      cursor: "pointer",
                      textAlign: "center",
                    }}
                    onClick={() => {
                      if (!haveData) return;
                      setSelectedDay(day);
                      load();
                    }}
                  >
                    Day {day}
                  </p>
                );
              }
            })}
          </div>

          {!loading &&
            array.map((week, index) => {
              let day = Number(selectedDay) + (week - 1) * 7;
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "DM Sans",
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#000000",
                      textAlign: "center",
                    }}
                  >
                    Day {day}
                  </p>
                  {currentData?.[day] &&
                    currentData?.[day]?.length > 0 &&
                    currentData?.[day]?.map((Workout, workoutIdx) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "streatch",
                            justifyContent: "space-between",
                            padding: "10px 20px",
                            border: "1px solid #000000",
                          }}
                        >
                          <p
                            style={{
                              fontFamily: "DM Sans",
                              fontSize: "13px",
                              fontWeight: "500",
                              color: "#000000",
                              textAlign: "center",
                              borderBottom: "1px solid #BDBDBD",
                              marginBottom: "4px",
                            }}
                          >
                            {Workout.name}
                          </p>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "10px",
                            }}
                          >
                            {Workout?.workoutContent?.rounds?.map((r, rIdx) => {
                              let round = r.round;
                              let measurements = round.measurements;
                              let numRounds = round.numRounds;

                              return (
                                <div>
                                  {rIdx != 0 && <Divider sx={{ mb: "12px" }} />}
                                  <div
                                    style={{
                                      gap: "16px",
                                      display: "flex",
                                      alignItems: "center",
                                      marginBottom: "12px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        height: "30px",
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "4px",
                                        borderRadius: "8px",
                                        border: "1px solid #CCC",
                                        background: "#FFF",
                                      }}
                                      className="flex items-center"
                                    >
                                      <RemoveRoundedIcon
                                        sx={{
                                          height: "30px",
                                          width: "30px",
                                          padding: "10px",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          if (round.numRounds > 1) {
                                            let updatedData =
                                              decrementCurrentRound(
                                                currentData,
                                                setCurrentData,
                                                day,
                                                workoutIdx,
                                                rIdx,
                                                numRounds,
                                                measurements
                                              );
                                            setWorkoutsToCreate({
                                              ...workoutsToCreate,
                                              [`d:${day}-idx:${workoutIdx}`]:
                                                updatedData[day][workoutIdx],
                                            });
                                          }
                                        }}
                                      />
                                      <p
                                        style={{
                                          color: "#424242",
                                          fontFeatureSettings:
                                            "'clig' off, 'liga' off",
                                          fontFamily: "DM Sans",
                                          fontSize: "12px",
                                          fontStyle: "normal",
                                          fontWeight: 500,
                                          lineHeight: "normal",
                                        }}
                                      >
                                        {round.numRounds}{" "}
                                        <span
                                          style={{
                                            color: "#828282",
                                            fontFeatureSettings:
                                              "'clig' off, 'liga' off",
                                            fontFamily: "DM Sans",
                                            fontSize: "12px",
                                            fontStyle: "normal",
                                            fontWeight: 500,
                                            lineHeight: "normal",
                                          }}
                                        >
                                          Set{round.numRounds > 1 ? "s" : ""}
                                        </span>{" "}
                                      </p>{" "}
                                      {
                                        <AddRoundedIcon
                                          sx={{
                                            height: "30px",
                                            width: "30px",
                                            padding: "10px",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            let updatedData =
                                              incrementCurrentRound(
                                                currentData,
                                                setCurrentData,
                                                day,
                                                workoutIdx,
                                                rIdx,
                                                numRounds,
                                                measurements,
                                                setWorkoutsToCreate
                                              );
                                            setWorkoutsToCreate({
                                              ...workoutsToCreate,
                                              [`d:${day}-idx:${workoutIdx}`]:
                                                updatedData[day][workoutIdx],
                                            });
                                          }}
                                        />
                                      }
                                    </div>
                                  </div>
                                  {measurements.map((m, mIdx) => {
                                    let measurement = m.measurement;
                                    return (
                                      <RenderOneExercise
                                        exercise={measurement}
                                        index={index}
                                        workoutIdx={workoutIdx}
                                        day={day}
                                        currentData={currentData}
                                        setCurrentData={setCurrentData}
                                        editingId={editingId}
                                        setEditingId={setEditingId}
                                        rIdx={rIdx}
                                        mIdx={mIdx}
                                        workoutName={Workout.name}
                                        workoutsToCreate={workoutsToCreate}
                                        setWorkoutsToCreate={
                                          setWorkoutsToCreate
                                        }
                                      />
                                    );
                                  })}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                </div>
              );
            })}

          {!loading && (
            <div>
              <AddRoundedIcon
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  onWeekChange(weekNum + 1);
                  setWeekNum(weekNum + 1);
                }}
              />

              <RemoveRoundedIcon
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  onWeekRemove(weekNum);
                  setWeekNum(weekNum - 1);
                }}
              />
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SmartBuilder;

const RenderOneExercise = ({
  exercise,
  index,
  workoutIdx,
  day,
  currentData,
  setCurrentData,
  editingId,
  setEditingId,
  rIdx,
  mIdx,
  workoutName,
  workoutsToCreate,
  setWorkoutsToCreate,
}) => {
  const currentExerciseRef = useRef(null);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  let currentId =
    exercise.movementID +
    "@" +
    day +
    "@" +
    index +
    "@" +
    rIdx +
    "@" +
    mIdx +
    "@" +
    workoutName;

  const [currentExercise, setCurrentExercise] = useState(exercise);

  const handleOutsideClick = () => {
    if (currentId === editingId) {
      // check if current exercise is equal to the exercise in currentData
      // if not then update the currentData
      let newData = { ...currentData };
      if (
        Object.is(
          newData[day][workoutIdx].workoutContent.rounds[rIdx].round
            .measurements[mIdx].measurement,
          currentExercise
        )
      ) {
        console.log("same");
      } else {
        console.log("not same");
        newData[day][workoutIdx].workoutContent.rounds[rIdx].round.measurements[
          mIdx
        ].measurement = currentExercise;

        setWorkoutsToCreate({
          ...workoutsToCreate,
          [`d:${day}-idx:${workoutIdx}`]: newData[day][workoutIdx],
        });
      }
      setEditingId(null);
    }
  };

  useOutsideClick(currentExerciseRef, handleOutsideClick, [
    ref1,
    ref2,
    currentExerciseRef,
  ]);

  const onDoubleClick = () => {
    setEditingId(currentId);
  };

  const coachID = useSelector((state) => state?.auth?.trainerUUID);
  const APIURL = useSelector((state) => state.auth.apiURL);
  const {
    data: exerciseList,
    error: spurfitExercisesError,
    isLoading: spurfitExercisesLoading,
  } = useGetSpurfitExercisesQuery(APIURL);

  return (
    <div
      style={{
        border: "1px solid #F5F5F5",
        padding: "7.081px 10.621px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
      ref={currentExerciseRef}
      onDoubleClick={onDoubleClick}
    >
      {editingId == currentId ? (
        <div ref={ref1}>
          <Autocomplete
            disablePortal
            freeSolo
            id="combo-box-demo"
            options={exerciseList}
            getOptionLabel={(option) => {
              return option?.name || currentExercise.movementName;
            }}
            defaultValue={currentExercise.movementName}
            onChange={(event, value) => {
              event.stopPropagation();
              if (!value) return;
              console.log("selected value: ", value);
              setCurrentExercise({
                ...currentExercise,
                movementName: value?.name,
                movementID: value?.id,
                videoLink: value?.videoURL,
                imageLink: value?.imageLink,
              });
            }}
            sx={{
              // width: "260px",
              zIndex: 100,
              fontSize: "12px",
              color: "rgba(51, 51, 56, 0.50)",
              "& li": {
                fontSize: "12px",
              },
              mt: "12px",
            }}
            renderOption={(props, option) => {
              return (
                <MenuItem
                  {...props}
                  key={option.key}
                  sx={{
                    fontSize: "12px",
                    bgcol: "red",
                    fontFamily: "DM Sans",
                    lineHeight: "16px",
                    color: "#000",
                    zIndex: 100,
                  }}
                >
                  {option?.name}
                </MenuItem>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label=""
                placeholder="Exercise Name"
                variant="standard"
                size="small"
                sx={{
                  fontSize: "12px",
                  borderBottomColor: "#E1E1E1",
                  "&:hover": { borderBottomColor: "#E1E1E1" },
                }}
                InputProps={{
                  ...params.InputProps,
                  sx: { fontSize: "12px", lineHeight: "22px" },
                }}
              />
            )}
          />
        </div>
      ) : (
        <p
          style={{
            color: "#242424",
            fontSize: "12.391px",
            fontFamily: "DM Sans",
          }}
        >
          {currentExercise.movementName}
        </p>
      )}
      {currentExercise?.reps && (
        <div>
          {editingId == currentId ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color: "#242424",
                fontSize: "12.391px",
                fontFamily: "DM Sans",
              }}
            >
              Reps:
              {Object.keys(currentExercise?.reps).map((key) => {
                return (
                  <input
                    type="number"
                    value={currentExercise?.reps[key]}
                    style={{
                      width: "30px",
                      border: "1px solid #E1DFDD",
                      borderRadius: "5px",
                      marginRight: "2px",
                      color: "#242424",
                      fontSize: "12.391px",
                      fontFamily: "DM Sans",
                      outline: "none",
                      textAlign: "center",
                    }}
                    onChange={(e) => {
                      setCurrentExercise({
                        ...currentExercise,
                        reps: {
                          ...currentExercise.reps,
                          [key]: e.target.value,
                        },
                      });
                    }}
                  />
                );
              })}
            </div>
          ) : (
            <p
              style={{
                color: "#242424",
                fontSize: "12.391px",
                fontFamily: "DM Sans",
              }}
            >
              Reps: {formatProperty(currentExercise?.reps)}
            </p>
          )}
        </div>
      )}
      {currentExercise?.weight && (
        <div>
          {editingId == currentId ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color: "#242424",
                fontSize: "12.391px",
                fontFamily: "DM Sans",
              }}
            >
              Weights:
              {Object.keys(currentExercise?.weight).map((key) => {
                return (
                  <input
                    type="number"
                    value={currentExercise?.weight[key]}
                    style={{
                      width: "30px",
                      border: "1px solid #E1DFDD",
                      borderRadius: "5px",
                      marginRight: "2px",
                      color: "#242424",
                      fontSize: "12.391px",
                      fontFamily: "DM Sans",
                      outline: "none",
                      textAlign: "center",
                    }}
                    onChange={(e) => {
                      setCurrentExercise({
                        ...currentExercise,
                        weight: {
                          ...currentExercise.weight,
                          [key]: e.target.value,
                        },
                      });
                    }}
                  />
                );
              })}
            </div>
          ) : (
            <p
              style={{
                color: "#242424",
                fontSize: "12.391px",
                fontFamily: "DM Sans",
              }}
            >
              Weights: {formatProperty(currentExercise?.weight)}
            </p>
          )}
        </div>
      )}

      {currentExercise?.height && (
        <div>
          {editingId == currentId ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color: "#242424",
                fontSize: "12.391px",
                fontFamily: "DM Sans",
              }}
            >
              Heights:
              {Object.keys(currentExercise?.height).map((key) => {
                return (
                  <input
                    type="number"
                    value={currentExercise?.height[key]}
                    style={{
                      width: "30px",
                      border: "1px solid #E1DFDD",
                      borderRadius: "5px",
                      marginRight: "2px",
                      color: "#242424",
                      fontSize: "12.391px",
                      fontFamily: "DM Sans",
                      outline: "none",
                      textAlign: "center",
                    }}
                    onChange={(e) => {
                      setCurrentExercise({
                        ...currentExercise,
                        height: {
                          ...currentExercise.height,
                          [key]: e.target.value,
                        },
                      });
                    }}
                  />
                );
              })}
            </div>
          ) : (
            <p
              style={{
                color: "#242424",
                fontSize: "12.391px",
                fontFamily: "DM Sans",
              }}
            >
              Weights: {formatProperty(currentExercise?.height)}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

const formatProperty = (property) => {
  if (!property || Object.entries(property).length == 0) return null;
  let formattedProperty = [];
  Object.keys(property).map((key) => {
    formattedProperty.push(property[key]);
  });
  return formattedProperty.join(", ");
};
