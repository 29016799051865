import React from 'react';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import "./style.css"

function ContactUsButton(props) {

    function handleClickContactUs(){
        window.$chatwoot.toggle()
    }

    return (
        <div className='h-[40px] w-[150px]  fixed bottom-3 right-5    items-center borderGradient' onClick={handleClickContactUs}>
            <div className='h-full w-full px-3 py-2 bg-[#ffffff] rounded-full'>
            <button className='flex text-sm font-[500]  '>
                <TextsmsOutlinedIcon className='mr-[3px] text-sm  '/>
                 Contact Us</button>
            </div>
             
        </div>
    );
}

export default ContactUsButton;