import {
  deleteTrainerInvite,
  getAdminDashboardData,
  sendTrainerInvite,
} from "@/ApiServices/AdminDashboard/Api.js";
import { getLivezyClientsDashboard } from "@/ApiServices/Analytics/Api.js";
import {
  deleteAtheleteByID,
  getTrainerDashboard,
} from "@/ApiServices/Dashboard/Api.js";
import { CustomAlertContext } from "@/App.jsx";
import { FirstCell, HeaderCell } from "@/components/Dashboard/helpers.js";
import Layout from "@/components/layout/Layout.js";
import DropDownMenu from "@/features/Programs/DropDownMenu.jsx";
import ShowPendingTable from "@/pages/adminDahboard/ShowPendingTable.jsx";
import TrainerDetails from "@/pages/adminDahboard/TrainerDetails.jsx";
import TrainerDetailsLoading from "@/pages/adminDahboard/TrainerDetailsLoading.jsx";
import { setSubbordinateCoachIDs } from "@/redux/authSlice.js";
import { Typography } from "@mui/material";
import { MoreHorizontal } from "lucide-react";
import moment from "moment/moment.js";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminUserDetail from "../adminUserDetail/adminUserDetail.jsx";
import TrainersList from "./TrainersList.jsx";
import AssignAthleteToCoach from "@/components/Dashboard/AssignAthleteToCoach.js";
import { setCoaches } from "@/redux/clientsSlice.js";

const NewAdminDashboard = () => {
  const [trainers, setTrainers] = useState([]);
  const [allAthelets, setAllAthelets] = useState([]);
  const [originalAthelets, setOriginalAthelets] = useState([]);

  const [pendingTrainers, setPendingTrainers] = useState([]);
  const coachID = useSelector((state) => state.auth?.trainerUUID);
  const adminData = useSelector((state) => state.auth?.data);
  const [loading, setLoading] = useState(true);

  const [selectedTrainer, setSelectedTrainer] = useState(null);
  const [allTrainersData, setAllTrainersData] = useState([]);
  const [originalTrainers, setOriginalTrainers] = useState([]);
  const [loadingTrainerData, setLoadingTrainerData] = useState(true);
  const [showPending, setShowPending] = useState(false);

  const [selectedTab, setSelectedTab] = useState("trainers");

  const [selectedAthlete, setSelectedAthlete] = useState(null);

  const [assignAthleteToCoach, setAssignAthleteToCoach] = useState(false);

  const gymAffiliation = useSelector(
    (state) => state.auth?.data?.gymAffliation
  );
  const isLivezy = useSelector((state) => state.auth?.isLivezy);
  const { displayAlert } = useContext(CustomAlertContext);

  const dispatch = useDispatch();

  const getDataForAdminDashboard = async () => {
    if (!coachID) return;
    setLoading(true);
    let res = await getAdminDashboardData(coachID);
    if (res?.coaches) {
      dispatch(setCoaches(res.coaches));
    }
    if (isLivezy) {
      let atheletes = await getLivezyClientsDashboard();
      // filter client with email including @demo.com
      let filteredAtheletes = [];

      atheletes.forEach((athelete) => {
        if (
          typeof athelete?.email === "string" &&
          (athelete?.email?.includes("@demo.com") ||
            athelete?.email?.includes("democlient@spur.fit"))
        ) {
          deleteAtheleteByID(athelete?.id);
        } else {
          filteredAtheletes.push(athelete);
        }
      });

      setAllAthelets(filteredAtheletes);
      setOriginalAthelets(filteredAtheletes);
    }
    let trainers = res?.coaches ?? [];
    let pendingTrainers = res?.pending ?? [];

    pendingTrainers = pendingTrainers.filter((item) => !item?.hasSignedUp);

    setTrainers(trainers);
    setOriginalTrainers(trainers);
    console.log("trainers from api", trainers);
    setPendingTrainers(pendingTrainers);

    if (trainers?.length > 0 && !showPending) {
      setSelectedTrainer(trainers[0]);
      getTrainerDashboardData({ coachID: trainers[0].id });
    } else if (trainers?.length === 0) {
      setLoadingTrainerData(false);
    }

    let coachIDs = trainers.map((item) => item.id);
    dispatch(setSubbordinateCoachIDs(coachIDs));
    setLoading(false);
  };

  const onBoardingOptions = [
    "Questionaire filled",
    "Questionnaire filled",
    "Physique pictures received",
    "Plan sent",
    "Intro call done",
  ];

  const getTrainerDashboardData = async ({ coachID }) => {
    setLoadingTrainerData(true);
    try {
      if (!coachID) return;
      if (!allTrainersData[coachID]) {
        let res = await getTrainerDashboard({ coachID });
        let active = [];
        let onboarding = [];

        if (res) {
          res?.forEach((item) => {
            if (onBoardingOptions.includes(item.onboardingStatus_LivEzy)) {
              onboarding.push(item);
            } else if (item.IsActive) {
              active.push(item);
            }
          });
        }

        setAllTrainersData({
          ...allTrainersData,
          [coachID]: {
            active,
            onboarding,
          },
        });
      }
    } catch (e) {
      console.log("Error in getting trainer dashboard data", e);
    } finally {
      setLoadingTrainerData(false);
    }
  };

  const reloadSourceAndTargetTrainerData = async (sourceUUID, targetUUID) => {
    setLoadingTrainerData(true);
    let data = await getTrainerDashboard({ coachID: sourceUUID });

    let targetData = await getTrainerDashboard({ coachID: targetUUID });
    console.log("target data", targetData, data);
    setAllTrainersData({
      ...allTrainersData,
      [sourceUUID]: data,
      [targetUUID]: targetData,
    });
    setLoadingTrainerData(false);
  };

  const handleSearchTrainers = (searchText) => {
    if (searchText === "") {
      setTrainers(originalTrainers);
    } else {
      const filteredTrainers = originalTrainers.filter((trainer) => {
        return trainer.name.toLowerCase().includes(searchText.toLowerCase());
      });
      setTrainers(filteredTrainers);
    }
  };

  const handleSearchAthletes = (searchText) => {
    const searchValueLower = searchText.toLowerCase();

    if (searchText === "") {
      setAllAthelets(originalAthelets);
    } else {
      const filteredAthletes = originalAthelets.filter((athlete) => {
        return ["name", "email", "contactNumber", "clientID_LivEzy"].some(
          (field) => {
            const value = field
              .split(".")
              .reduce((o, key) => o && o[key], athlete);
            return value && value.toLowerCase().includes(searchValueLower);
          }
        );
      });
      setAllAthelets(filteredAthletes);
    }
  };

  const handleSearch = (searchText) => {
    if (selectedTab === "trainers") {
      handleSearchTrainers(searchText);
    } else {
      handleSearchAthletes(searchText);
    }
  };

  const handleFilterAtheletes = (option) => {
    if (option === "clear") {
      setAllAthelets(originalAthelets);
    } else {
      if (option === "Delay onboarding") {
        // assuming the athelete will have coachAssignedDate
        const filteredAtheletes = originalAthelets.filter((athelete) => {
          let coachAssignedDate = moment(athelete?.coachAssignedDate_LivEzy);
          // if assigned date is more than 3 days ago
          return moment().diff(coachAssignedDate, "days") > 3;
        });

        setAllAthelets(filteredAtheletes);
        return;
      }
      const filteredAtheletes = originalAthelets.filter((athelete) => {
        return athelete?.onboardingStatus_LivEzy === option;
      });
      setAllAthelets(filteredAtheletes);
    }
  };

  useEffect(() => {
    getDataForAdminDashboard();
  }, [coachID]);

  const resendInvite = async (email) => {
    if (!email || typeof email != "string") return;
    setLoadingTrainerData(true);
    let trainers = [];
    let temp = {};
    let trimmedEmail = email.trim();
    temp["adminUUID"] = adminData?.id;
    temp["adminName"] = adminData?.name;
    temp["trainerEmail"] = trimmedEmail;
    temp["gymAffliation"] = adminData?.gymAffliation;
    trainers.push(temp);
    let res = await sendTrainerInvite(trainers);
    if (res) {
      await getDataForAdminDashboard();
      displayAlert({
        type: "success",
        message: "Invite resent successfully!",
      });
    }
    setLoadingTrainerData(false);
  };

  const cancelInvite = async (email) => {
    if (!email || typeof email != "string") return;
    setLoadingTrainerData(true);
    let res = await deleteTrainerInvite(email);
    if (res) {
      await getDataForAdminDashboard();
      displayAlert({
        type: "success",
        message: "Invite cancelled successfully!",
      });
    }
    setLoadingTrainerData(false);
  };

  const pendingColumn = [
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 120,
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label={"Email"} key={"email"} />,
      renderCell: (params) => (
        <FirstCell
          onClick={() => {}}
          params={params}
          val={params?.row?.email}
          key={"Email"}
        />
      ),
    },
    {
      field: "Invite Date",
      headerName: "Invite Date",
      flex: 1,
      minWidth: 120,
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <HeaderCell label={"Invite Date"} key={"Invite Date"} />
      ),
      renderCell: (params) => (
        <Typography
          sx={{
            fontSize: "13px",
            lineHeight: "20px",
            fontFamily: "DM Sans",
            color: "#333333",
            fontWeight: 400,
          }}
        >
          {params?.row?.dateCreated
            ? moment(params?.row?.dateCreated).format("MMM Do' YY")
            : "-"}
        </Typography>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 120,
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <Typography
          sx={{
            fontSize: "13px",
            lineHeight: "20px",
            fontFamily: "DM Sans",
            color: "#333333",
            fontWeight: 500,
          }}
        >
          Action
        </Typography>
      ),
      renderCell: (params) => (
        <div>
          <DropDownMenu
            triggerElement={<MoreHorizontal size={20} />}
            options={["Resend Invite", "Cancel Invite"]}
            onSelect={(option) => {
              switch (option) {
                case "Resend Invite":
                  resendInvite(params.row.email);
                  break;
                case "Cancel Invite":
                  cancelInvite(params.row.email);
                  break;
                default:
                  break;
              }
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <Layout>
      <div
        style={{
          height: "calc(100vh - 100px)",
          width: "100%",
          backgroundColor: "#fff",
          borderRadius: "12px",
        }}
        className={"flex items-center"}
      >
        {/*left pane*/}
        <div
          style={{
            width: "25%",
            height: "100%",
            borderRight: "1px solid #e5e7eb",
          }}
          className={"relative"}
        >
          <TrainersList
            isLivezy={isLivezy}
            trainers={trainers}
            atheletes={allAthelets}
            selected={selectedTrainer}
            selectedAthlete={selectedAthlete}
            handleFilterAtheletes={handleFilterAtheletes}
            onSelect={(trainer) => {
              console.log("all trainers data", allTrainersData);
              setSelectedTrainer(trainer);
              if (trainer) {
                getTrainerDashboardData({ coachID: trainer.id });
              }
              if (showPending) setShowPending(false);
            }}
            onAthleteSelect={(athlete) => {
              setSelectedAthlete(athlete);
            }}
            onTransferAthlete={(athlete) => {
              setSelectedAthlete(athlete);
              setAssignAthleteToCoach(true);
            }}
            loading={loading}
            handleSearch={handleSearch}
            onPendingClick={() => {
              setShowPending(!showPending);
              setSelectedTrainer(null);
            }}
            isPending={showPending}
            getDataForAdminDashboard={getDataForAdminDashboard}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        </div>
        {/*right pane*/}
        <div
          className={""}
          style={{
            width: "75%",
            height: "100%",
            borderRight: "1px solid #e5e7eb",
          }}
        >
          {showPending ? (
            loadingTrainerData ? (
              <TrainerDetailsLoading />
            ) : (
              <ShowPendingTable
                data={pendingTrainers}
                columns={pendingColumn}
                loading={loading}
                getRowId={(row) => row.id}
              />
            )
          ) : selectedAthlete ? (
            <AdminUserDetail id={selectedAthlete.id} key={selectedAthlete.id} />
          ) : (
            <TrainerDetails
              selectedTrainer={selectedTrainer}
              data={allTrainersData[selectedTrainer?.id]}
              loading={loadingTrainerData}
              reloadSourceAndTargetTrainerData={
                reloadSourceAndTargetTrainerData
              }
              key={selectedTrainer?.id}
            />
          )}
        </div>

        {assignAthleteToCoach && (
          <AssignAthleteToCoach
            isOpen={assignAthleteToCoach}
            setIsOpen={setAssignAthleteToCoach}
            selectedUser={selectedAthlete}
            getTrainerDashboard={(targetUUID) => {
              let sourceUUID = selectedAthlete?.coachID;
              setAllTrainersData({
                ...allTrainersData,
                [sourceUUID]: null,
                [targetUUID]: null,
              });

              // set athelete coach to targetUUID
              setSelectedAthlete({
                ...selectedAthlete,
                coachID: targetUUID,
              });

              // update in athelete list
              let atheleteIndex = allAthelets.findIndex(
                (item) => item.id === selectedAthlete.id
              );

              let tempAtheletes = [...allAthelets];
              tempAtheletes[atheleteIndex] = {
                ...tempAtheletes[atheleteIndex],
                coachID: targetUUID,
              };

              setAllAthelets(tempAtheletes);

              let tempOriginalAtheletes = [...originalAthelets];
              tempOriginalAtheletes[atheleteIndex] = {
                ...tempOriginalAtheletes[atheleteIndex],
                coachID: targetUUID,
              };
            }}
          />
        )}
      </div>
    </Layout>
  );
};

export default NewAdminDashboard;
