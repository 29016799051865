import "./styles.css";
import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ReactPlayer from "react-player";
import EditMainIcon from "../../pages/library/assests/EditMainIcon";
import BinIcon from "../../pages/library/assests/BinIcon";
import BlackBinIcon from "../../pages/library/assests/BlackBinIcon";

const VideoPlayerModel = ({
  openDialog,
  setOpenDialog,
  videoInfo,
  handleUpdateExercise,
  handleDeleteExercise,
  setSelectedItem,
}) => {
  const [info, setInfo] = useState(videoInfo);

  useEffect(() => {
    console.log("videoInfo --> ", videoInfo);
    setInfo(videoInfo);
  }, [videoInfo]);

  function isVimeoLink(url) {
    try {
      return url?.includes("vimeo.com");
    } catch (error) {
      console.log("error in isVimeoLink -->", error);
    }
  }

  function getVimeoId(embedLink) {
    try {
      const regex =
        /(https?:\/\/)?(www\.)?(player\.)?vimeo\.com\/?(showcase\/)*([0-9))([a-z]*\/)*([0-9]{6,11})[?]?.*/;
      const match = embedLink.match(regex);
      if (match && match?.length == 7) {
        return match[6];
      } else {
        return null;
      }
    } catch (error) {
      console.log("error in getVimeoId -->", error);
    }
  }

  function getVimeoUrl(url) {
    try {
      let VimeoId = getVimeoId(url);
      console.log("vimeo Id -->", { VimeoId });
      return `https://player.vimeo.com/video/${VimeoId}?api=1&autoplay=0`;
    } catch (error) {
      console.log("error in getVimeoUrl -->", error);
    }
  }

  function formatProperties(input) {
    try {
      if (typeof input === "object") {
        return Object.values(input).join(", ");
      } else {
        return input;
      }
    } catch (error) {
      console.log("error in formatProperties -->", error);
    }
  }

  return (
    <Dialog
      maxWidth={"lg"}
      open={openDialog}
      onClose={() => setOpenDialog(!openDialog)}
      PaperProps={{
        sx: {
          minHeight: "50vh",
          maxHeight: "100vh",
          minWidth: "596px",
          borderRadius: "20px",
        },
      }}
    >
      <DialogContent sx={{ padding: 0 }}>
        <div className="add-program-modal-head">
          <p className="add-program-modal-head-title">
            {info?.Name ?? info?.name}
          </p>
          <div
            style={{
              display: "flex",
              gap: "16px",
            }}
          >
            {handleUpdateExercise && (
              <div
                onClick={() => {
                  // setOpenDialog(false);
                  setSelectedItem(info);
                  handleUpdateExercise();
                }}
              >
                <EditMainIcon />
              </div>
            )}
            {handleDeleteExercise && (
              <div onClick={handleDeleteExercise}>
                <BlackBinIcon />
              </div>
            )}
            <CloseIcon
              sx={{
                cursor: "pointer",
              }}
              onClick={() => setOpenDialog(false)}
            />
          </div>
        </div>
        <div
          className="add-program-modal-body"
          style={{
            borderRadius: "12px",
            overflow: "hidden",
          }}
        >
          {/* <video
          controls
          className={"w-[250px] md:w-[501px]"}
          style={{
            height: "50vh",
            borderRadius: "10px",
          }}
          src={videoInfo?.VideoURL ?? videoInfo?.videoURL}
        /> */}
          {!info?.videoURL ||
          info?.videoURL == "" ||
          info?.videoURL === "-@-" ? (
            <img
              loading="lazy"
              width="400px"
              height="350px"
              src={
                info?.imageLink ||
                "https://storage.needpix.com/rsynced_images/coming-3605857_1280.jpg"
              }
            ></img>
          ) : isVimeoLink(info?.VideoURL ?? info?.videoURL) ? (
            <iframe
              loading="lazy"
              title="Vimeo video player"
              style={{ minHeight: "250px" }}
              src={getVimeoUrl(info?.VideoURL ?? info?.videoURL)}
              width="100%"
              height="100%"
              frameborder="0"
              allowfullscreen="allowfullscreen"
            ></iframe>
          ) : (
            // <Vimeo
            //   // style={{ minHeight: "200px" }}
            //   video={getVimeoId(info?.VideoURL ?? info?.videoURL)}
            //   autoplay
            // />
            <ReactPlayer
              width={"100%"}
              controls={true}
              className="react-player"
              url={info?.VideoURL ?? info?.videoURL}
            />
          )}
        </div>
        <div style={{ padding: "0 24px 24px 24px", width: "100%" }}>
          {/* <Typography
            sx={{
              fontSize: 14,
              color: "#FF9B04",
            }}
          >
            {formatProperties(
              videoInfo?.Equipment ??
                videoInfo?.equipment ??
                videoInfo?.equipmentList ??
                "No equipments"
            )}
            &nbsp; | &nbsp;
            {formatProperties(
              videoInfo?.Category ?? videoInfo?.categoryKind ?? "No category"
            )}
          </Typography> */}

          <div
            style={{
              alignItems: "center",
              gap: "16px",
            }}
          >
            {info?.bodyPart && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  marginTop: "8px",
                }}
              >
                <p className="vedio-modal-container-info-text">BodyPart: </p>{" "}
                {typeof info?.bodyPart === "object" ? (
                  Object.values(info?.bodyPart).map((data) => (
                    <p className="vedio-modal-container-info-text-box">
                      {data}
                    </p>
                  ))
                ) : (
                  <p className="vedio-modal-container-info-text-box">
                    {formatProperties(info?.bodyPart)}
                  </p>
                )}
              </div>
            )}
            {info?.subBodyPart && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  marginTop: "8px",
                }}
              >
                <p className="vedio-modal-container-info-text">SubBodyPart: </p>{" "}
                {typeof info?.subBodyPart === "object" ? (
                  Object.values(info?.subBodyPart).map((data) => (
                    <p className="vedio-modal-container-info-text-box">
                      {data}
                    </p>
                  ))
                ) : (
                  <p className="vedio-modal-container-info-text-box">
                    {formatProperties(info?.subBodyPart)}
                  </p>
                )}
              </div>
            )}
            {info?.modality && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  marginTop: "8px",
                }}
              >
                <p className="vedio-modal-container-info-text">Modality: </p>{" "}
                {typeof info?.modality === "object" ? (
                  Object.values(info?.modality).map((data) => (
                    <p className="vedio-modal-container-info-text-box">
                      {data}
                    </p>
                  ))
                ) : (
                  <p className="vedio-modal-container-info-text-box">
                    {formatProperties(info?.modality)}
                  </p>
                )}
              </div>
            )}
            {info?.subModality && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  marginTop: "8px",
                }}
              >
                <p className="vedio-modal-container-info-text">SubModality: </p>{" "}
                {typeof info?.subModality === "object" ? (
                  Object.values(info?.subModality).map((data) => (
                    <p className="vedio-modal-container-info-text-box">
                      {data}
                    </p>
                  ))
                ) : (
                  <p className="vedio-modal-container-info-text-box">
                    {formatProperties(info?.subModality)}
                  </p>
                )}
              </div>
            )}
          </div>

          <Typography fontSize={12} fontWeight={500} mt={"43px"}>
            {info?.Instructions ?? info?.instructions}
          </Typography>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default VideoPlayerModel;
