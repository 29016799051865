
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isUserEditForm: false,
    dataTags: null,
    description: null,
    formType: null,
    id: null,
};

const formSlice = createSlice({
    name: "userForms",
    initialState,
    reducers: {
        getFormData: (state, action) => {
            if (action.payload) {
                console.log({ action: action.payload })
                const { tags, description, formType, id } = action.payload
                state.description = description || "",
                    state.formType = formType || "",
                    state.dataTags = tags || "",
                    state.id = id || ""
            }
        },
        setEditForm: (state, action) => {
            state.isUserEditForm = action.payload
        },
        resetData: (state) => {
            console.log({ BedSingleIcon: 'fghgjgggjgjg' })
            state.isUserEditForm = false,
                state.dataTags = null,
                state.description = null,
                state.formType = null,
                state.id = null
        }
    },
});

export const { getFormData, setEditForm, resetData } =
    formSlice.actions;

export default formSlice.reducer;

export const getSingleFormDataFunc = (data) => async (dispatch) => {
    console.log({data})
    dispatch(getFormData(data))
}

export const setUserEditFormFunc = (isEditForm) => async (dispatch) => {
    dispatch(setEditForm(isEditForm))

}

export const resetUserFormFunc = () => async (dispatch) => {
    dispatch(resetData())
}
