export const options = [
  {
    id: 11,
    href: "/coach/forms/application",
    type: "Multi-line type",
    indexNumber: 0,
  },
  {
    id: 12,
    href: "/coach/forms/application",
    type: "Single Select",
    indexNumber: 1,
  },
  // {
  //   id: 13,
  //   href: "/coach/forms/application",
  //   // icon: (
  //   // <svg width="16" height="16" display="inline-block" xmlns="http://www.w3.org/2000/svg" fill="none">
  //   //   <mask id="a21" width="16" height="16" x="0" y="0" maskUnits="userSpaceOnUse" style={{ maskType: 'alpha' }}>
  //   // 	<path fill="#D9D9D9" d="M0 0h16v16H0z"></path>
  //   //   </mask>
  //   //   <g mask="url(#a21)">
  //   // 	<path fill="#5521B5" d="M8.309 12 9.74 3.273h1.023L9.332 12H8.309Zm-3.95-2.335.166-1.023h6.614l-.167 1.023H4.36ZM5.24 12l1.432-8.727h1.022L6.263 12H5.241Zm-.375-5.37.166-1.022h6.614l-.167 1.023H4.866Z"></path>
  //   //   </g>
  //   // </svg>
  //   // ),
  //   type: "Weight",
  // },
  // {
  //   id: 14,
  //   href: "/coach/forms/application",
  //   // icon: (
  //   // <svg width="16" height="16" display="inline-block" xmlns="http://www.w3.org/2000/svg" fill="none">
  //   //   <mask id="a22" width="16" height="16" x="0" y="0" maskUnits="userSpaceOnUse" style={{ maskType: 'alpha' }}>
  //   // 	<path fill="#D9D9D9" d="M0 0h16v16H0z"></path>
  //   //   </mask>
  //   //   <g mask="url(#a22)">
  //   // 	<path fill="#5521B5" d="M8.309 12 9.74 3.273h1.023L9.332 12H8.309Zm-3.95-2.335.166-1.023h6.614l-.167 1.023H4.36ZM5.24 12l1.432-8.727h1.022L6.263 12H5.241Zm-.375-5.37.166-1.022h6.614l-.167 1.023H4.866Z"></path>
  //   //   </g>
  //   // </svg>
  //   // ),
  //   type: "Height",
  // },
  {
    id: 15,
    href: "/coach/forms/application",
    // icon: (
    //   <svg
    //     width="16"
    //     height="16"
    //     display="inline-block"
    //     xmlns="http://www.w3.org/2000/svg"
    //     fill="none"
    //   >
    //     <path
    //       fill="#5521B5"
    //       d="M6.983 10.65 11.8 5.833l-.717-.716-4.1 4.1L5 7.233l-.717.717 2.7 2.7ZM3 14a.96.96 0 0 1-.7-.3.96.96 0 0 1-.3-.7V3c0-.267.1-.5.3-.7.2-.2.433-.3.7-.3h10c.267 0 .5.1.7.3.2.2.3.433.3.7v10c0 .267-.1.5-.3.7-.2.2-.433.3-.7.3H3Zm0-1h10V3H3v10ZM3 3v10V3Z"
    //     ></path>
    //   </svg>
    // ),
    type: "Date",
  },
  {
    id: 16,
    href: "/coach/forms/application",
    type: "Multi Select",
  },
  {
    id: 17,
    href: "/coach/forms/application",
    type: "Number",
  },
  {
    id: 18,
    href: "/coach/forms/application",
    type: "Single-line text",
  },
];

export const singleSelect = (
  <svg
    width="16"
    height="16"
    display="inline-block"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <mask
      id="a19"
      width="16"
      height="16"
      x="0"
      y="0"
      maskUnits="userSpaceOnUse"
      style={{ maskType: "alpha" }}
    >
      <path fill="#D9D9D9" d="M0 0h16v16H0z"></path>
    </mask>
    <g clipPath="url(#b20)">
      <path
        fill="#5521B5"
        d="M8 10.325c.65 0 1.2-.225 1.65-.675.45-.45.675-1 .675-1.65 0-.65-.225-1.2-.675-1.65-.45-.45-1-.675-1.65-.675-.65 0-1.2.225-1.65.675-.45.45-.675 1-.675 1.65 0 .65.225 1.2.675 1.65.45.45 1 .675 1.65.675ZM8 13c-.683 0-1.33-.131-1.938-.394a5.071 5.071 0 0 1-1.593-1.075 5.07 5.07 0 0 1-1.075-1.594A4.838 4.838 0 0 1 3 8c0-.692.131-1.342.394-1.95a4.997 4.997 0 0 1 1.075-1.587c.454-.45.985-.807 1.593-1.07A4.838 4.838 0 0 1 8 3c.692 0 1.342.131 1.95.394a5.05 5.05 0 0 1 1.588 1.069c.45.45.806.979 1.068 1.587C12.87 6.658 13 7.308 13 8c0 .683-.131 1.33-.394 1.938a5.125 5.125 0 0 1-1.069 1.593c-.45.454-.979.813-1.587 1.075A4.869 4.869 0 0 1 8 13Zm0-.75c1.183 0 2.188-.415 3.012-1.244.825-.829 1.238-1.831 1.238-3.006 0-1.183-.412-2.188-1.238-3.013C10.188 4.162 9.183 3.75 8 3.75c-1.175 0-2.177.412-3.006 1.237C4.164 5.813 3.75 6.817 3.75 8c0 1.175.415 2.177 1.244 3.006.829.83 1.831 1.244 3.006 1.244Z"
      ></path>
    </g>
    <defs>
      <clipPath id="b20">
        <path fill="#fff" d="M2 2h12v12H2z"></path>
      </clipPath>
    </defs>
  </svg>
);
export const multiline = (
  <svg
    width="16"
    height="16"
    display="inline-block"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
  >
    <mask
      id="a18"
      width="16"
      height="16"
      x="0"
      y="0"
      maskUnits="userSpaceOnUse"
      style={{ maskType: "alpha" }}
    >
      <path fill="#D9D9D9" d="M0 0h16v16H0z"></path>
    </mask>
    <path
      fill="#5521B5"
      d="M2 12v-1h8v1H2Zm0-3.5v-1h12v1H2ZM2 5V4h12v1H2Z"
    ></path>
  </svg>
);

export const multiSelect = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {" "}
      <g id="Interface / Checkbox_Check">
        <path
          id="Vector"
          d="M8 12L11 15L16 9M4 16.8002V7.2002C4 6.08009 4 5.51962 4.21799 5.0918C4.40973 4.71547 4.71547 4.40973 5.0918 4.21799C5.51962 4 6.08009 4 7.2002 4H16.8002C17.9203 4 18.4796 4 18.9074 4.21799C19.2837 4.40973 19.5905 4.71547 19.7822 5.0918C20 5.5192 20 6.07899 20 7.19691V16.8036C20 17.9215 20 18.4805 19.7822 18.9079C19.5905 19.2842 19.2837 19.5905 18.9074 19.7822C18.48 20 17.921 20 16.8031 20H7.19691C6.07899 20 5.5192 20 5.0918 19.7822C4.71547 19.5905 4.40973 19.2842 4.21799 18.9079C4 18.4801 4 17.9203 4 16.8002Z"
          stroke="#5521B5"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
      </g>
    </g>
  </svg>
);

export const numberSelect = (
  <svg
    width="16"
    height="16"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    className="sc-htoDjs eQsZcg"
    style={{ display: "inline-block" }}
  >
    <defs>
      <mask
        id="a18"
        width="16"
        height="16"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#D9D9D9" d="M0 0h16v16H0z"></path>
      </mask>
    </defs>
    <g mask="url(#a18)">
      <path
        fill="#5521B5"
        d="M8.309 12 9.74 3.273h1.023L9.332 12H8.309Zm-3.95-2.335.166-1.023h6.614l-.167 1.023H4.36ZM5.24 12l1.432-8.727h1.022L6.263 12H5.241Zm-.375-5.37.166-1.022h6.614l-.167 1.023H4.866Z"
      ></path>
    </g>
  </svg>
);
export const DateSelect = (
  <svg
    width="16"
    height="16"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    className="sc-htoDjs eQsZcg"
    style={{ display: "inline-block" }}
  >
    <path
      fill="#5521B5"
      d="M19 2h-1V1h-2v1H8V1H6v1H5c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 18H5V8h14v12zm0-14H5V4h14v2z"
    />
  </svg>
);
