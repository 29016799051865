import { useEffect, useState } from "react";

export const toHHMMSS = (secs) => {
  var sec_num = parseInt(secs, 10);
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor(sec_num / 60) % 60;
  var seconds = sec_num % 60;

  return [hours, minutes, seconds]
    .map((v) => (v < 10 ? "0" + v : v))
    .filter((v, i) => v !== "00" || i > 0)
    .join(":");
};

export const convertMinPerKmToSecPerMeter = (minPerKm) => {
  try {
    minPerKm = parseFloat(minPerKm);
    if (!minPerKm || isNaN(minPerKm)) return "-";
    // Convert minutes to seconds
    const secPerKm = minPerKm * 60;

    // Convert kilometers to meters and calculate sec/m
    const secPerMeter = secPerKm / 1000;

    return secPerMeter;
  } catch (error) {
    console.error("Error in convertMinPerKmToSecPerMeter", error);
    return "-";
  }
};

// Hook
export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}
