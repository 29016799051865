import { Box } from "@mui/material";
import React from "react";

export default function SocialMediaInputField({
  label,
  value,
  onChange,
  img,
  style,
}) {
  return (
    <Box
      sx={{
        display: "flex",
        height: "70px",
        borderRadius: "7px",
        border: "1px solid #A7AAAE",
        padding: "10px 16px",
        alignItems: "center",
      }}
    >
      <img
        style={{
          height: "48px",
          width: "48px",
          paddingRight: "16px",
          borderRight: "1px solid #A7AAAE",
          marginRight: "16px",
          objectFit: "contain",
        }}
        src={img}
        alt="social media icon"
      />

      <input
        placeholder={label}
        style={{
          width: "100%",
          height: "100%",
          borderRadius: "7px",
          outline: "none",
        }}
        onChange={onChange}
        value={value}
      />
    </Box>
  );
}
