import { Skeleton } from "@/components/ui/skeleton";
import React from "react";

const ProgramWeeksLoadingUI = () => {
  let weeksArray = Array.from({ length: 1 }, (_, i) => i + 1);
  let daysArray = Array.from({ length: 5 }, (_, i) => i + 1); // Define daysArray here

  return (
    <div className="w-full h-full">
      {weeksArray.map((week, index) => {
        let weekDays = daysArray.slice(index * 7, (index + 1) * 7);
        return (
          <div key={index} className="flex flex-col gap-2 mx-2">
            <Skeleton className="w-55 h-[20px] rounded-md mt-1" />
            <div className="flex items-center justify-between mt-2">
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(5, 1fr)",
                  gap: "1rem",
                }}
                className=" overflow-scroll"
              >
                {weekDays.map((day) => {
                  return (
                    <Skeleton
                      key={`${day}-day`}
                      className="min-w-[190px] min-h-[150px] border rounded-md p-2 flex flex-col relative"
                    />
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ProgramWeeksLoadingUI;
