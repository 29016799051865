import React from "react";
import PlaceholderImage from "@/assets/EnduranceWorkoutBuilder/Placeholder.svg";
import PlaceholderBlock from "./PlaceholderBlock";

const PlaceholderBlocks = () => {
  return (
    <div className="flex w-full items-end ">
      <img src={PlaceholderImage} alt="Placeholder" />
      {/* <PlaceholderBlock width={200} height={100} name="Warm Up" />
      <PlaceholderBlock width={400} height={200} name="Main Set" />
      <PlaceholderBlock width={200} height={100} name="Cool Down" /> */}
    </div>
  );
};

export default PlaceholderBlocks;
