// dataSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [], // Initial data state
  exerciseObject: {}, // Initial exerciseObject state
  unreadMessages: 0, // Initial unreadMessages state
  tasksPending: 0, // Initial tasksPending state
  workouts: [], // Initial workouts state
  WorkoutLibraryDndData: [], //intial workout Library data
  showInverseTss: false,
  isExpanded: false,
  trainerDashboardData: [],
  allActiveAthletes: [],
  recheckForUnsavedWorkout:false
};

const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    addData: (state, action) => {
      state.data.push(action.payload);
    },
    modifyExerciseObject: (state, action) => {
      state.exerciseObject = action.payload;
    },
    setUnreadMessages: (state, action) => {
      state.unreadMessages = action.payload;
    },
    setPendingTasks: (state, action) => {
      state.tasksPending = action.payload;
    },
    setWorkoutsData: (state, action) => {
      state.workouts = action.payload;
    },
    setWorkoutLibaryData: (state, action) => {
      state.WorkoutLibraryDndData = action.payload;
    },
    toggleInverseTss: (state) => {
      state.showInverseTss = !state.showInverseTss;
    },
    toggleLayoutWidth: (state, action) => {
      state.isExpanded = action.payload;
    },
    setTrainerDashboardData: (state, action) => {
      state.trainerDashboardData = action.payload;
    },
    setAllActiveAthletes: (state, action) => {
      state.allActiveAthletes = action.payload;
    },
    toggleRecheckForUnsavedWorkout : (state, action) => {
      state.recheckForUnsavedWorkout =  !state.recheckForUnsavedWorkout
    },
  },
});

export const {
  addData,
  modifyExerciseObject,
  setUnreadMessages,
  setPendingTasks,
  setWorkoutsData,
  setWorkoutLibaryData,
  toggleInverseTss,
  toggleLayoutWidth,
  setTrainerDashboardData,
  setAllActiveAthletes,
  toggleRecheckForUnsavedWorkout,
} = dataSlice.actions;

export default dataSlice.reducer;
