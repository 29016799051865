import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  MenuItem,
  Paper,
  Radio,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import colors from "../../common/theme/appModern/colors";
import Layout from "../../components/layout/Layout";
import { CustomInputComponent } from "../../components/utils/CustomInputComponent";

import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { store } from "../../redux/store";
import { useSelector } from "react-redux";

export default function CreateSubscription({ location }) {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const [loading, setLoading] = useState(false);
  const [showClientsModal, setShowClientsModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [tempUsers, setTempUsers] = useState([]);
  const [selectedClient, setSelectedClient] = useState({});
  const [allProducts, setAllProducts] = useState([]);
  const [productQuantity, setProductQuantity] = useState(1);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [showSelectProductModal, setShowSelectProductModal] = useState(false);
  const [trainerData, setTrainerData] = useState([]);
  const [country, setCountry] = useState("IN");

  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const history = useHistory();

  useEffect(() => {
    getTrainerData();
    // axios({
    //   method: "get",
    //   url: `${APIURL}CF/api/CoachWeb/Get/getAllProductsByCoach`,
    //   params: {
    //     coachID: store.getState().auth.trainerUUID,
    //   },
    // })
    //   .then((res) => {
    //     setAllProducts(res.data.result);
    //     setLoading(false);
    //     if (location?.state?.productID) {
    //       const selectedProduct = res.data.result.find(
    //         (product) => product.id === location.state.productID
    //       );
    //       setSelectedProduct(selectedProduct);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log("error while getting products: ", err);
    //     setLoading(false);
    //   });
  }, []);

  const getProgramsUsers = async () => {
    let trainerDetails = JSON.parse(localStorage.getItem("trainerCredentials"));

    setLoading(true);
    await axios({
      method: "get",
      url: `${APIURL}CF/api/CoachWeb/Get/getAllAtheletesOneCoach`,
      params: {
        coachID: trainerDetails?.password,
      },
    }).then(async (res) => {
      const filteredData = res.data.result?.[0]?.atheletes?.filter(
        (item) => item.active == true
      );

      setUsers(filteredData);
      setTempUsers(filteredData);
      setLoading(false);
    });
  };

  useEffect(() => {
    getProgramsUsers();
  }, []);

  function isValidEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  const startSubscription = () => {
    if (!isValidPhoneNumber(phoneNumber) || !isValidEmail(email)) {
      toast.error("Please enter valid email and phone number");
      return;
    }

    let data = {
      atheleteID: selectedClient?.UserUUID,
      atheleteName: selectedClient?.name,
      trainerName: JSON.parse(localStorage.getItem("trainerCredentials"))?.name,
      notifyPhone: phoneNumber,
      notifyEmail: email,
      subscriptionLength: productQuantity,
      productID: selectedProduct?.id,
      //   subscriptionDate: Date.now(),
    };

    axios({
      method: "post",
      url: `${APIURL}CF/api/CoachWeb/Create/addNewSubscription`,
      data: {
        ...data,
      },
    })
      .then((res) => {
        toast.success("Successfully added subscription!");
        history.push("/stripeConnect/success");
      })
      .catch((err) => {
        toast.error("Unexpected error occured!");
      });
  };

  const createNewSubscription = () => {
    if (!isValidPhoneNumber(phoneNumber) || !isValidEmail(email)) {
      toast.error("Please enter valid email and phone number");
      return;
    }
    let trainerDetails = JSON.parse(localStorage.getItem("trainerCredentials"));
    // setLoading(true);
    let data = {
      price_id: selectedProduct?.prices?.[0]?.priceId,
      coachId: store.getState().auth.trainerUUID,
      coachName: trainerDetails?.name,
      athleteId: selectedClient?.UserUUID,
      atheleteName: selectedClient?.name,
      cus_name: selectedClient?.name,
      cus_email: email,
      cus_contact: phoneNumber,
      cus_country: country,
    };

    axios({
      method: "post",
      // url: `${APIURL}CF/api/stripeconnect/subscriptions`,
      url: `${APIURL}CF/api/stripeconnect/CreateSubscriptions`,
      data: data,
    })
      .then((res) => {
        toast.success("Successfully added subscription!");
        history.push("/stripeConnect/success");
      })
      .catch((err) => {
        toast.error("Unexpected error occured!");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const buttonStyle = {
    height: "33px",
    fontSize: "14px",
    background: "#071148",
    borderRadius: "8px",
    color: "#fff",
    textTransform: "none",

    "&:hover": {
      background: "#071148",
    },
  };

  const headerCellStyle = {
    fontWeight: 700,
    fontSize: ["14px", "16px", "18px"],
    color: "#071148",
    borderBottomColor: "#ffffff",
  };

  const bodyCellStyle = {
    fontWeight: 500,
    fontSize: ["14px", "16px", "18px"],
    color: "#071148",
    borderBottomColor: "#07114866",
  };

  const currencySymbols = {
    KRW: "₩",
    USD: "$",
    CAD: "$",
    INR: "₹",
    AED: "د.إ",
    EUR: "€",
    GBP: "£",
    SGD: "$",
  };

  const durationFormat = {
    weekly: "week",
    daily: "day",
    monthly: "month",
    yearly: "year",
  };

  const getTrainerData = async () => {
    setLoading(true);
    await axios({
      method: "get",
      url: `${APIURL}CF/api/stripeconnect/trainer/${
        store.getState().auth.trainerUUID
      }`,
    })
      .then((res) => {
        setTrainerData(res.data);
        console.log("stripe connect response: ", res.data);
      })
      .catch((err) => {
        console.log("strip connect error: ", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Layout>
      <Container maxWidth="xl">
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "20px",
            mt: "5vh",
            mb: "42px",
            color: "#071148",
          }}
        >
          Create Subscription
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {selectedClient?.name ? (
            <Card sx={{ maxWidth: "fit-content" }}>
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontSize: "20px", color: "#071148" }}>
                  {selectedClient?.name}
                </Typography>
                <Box
                  sx={{ marginTop: "16px" }}
                  className="flex flex-col items-center justify-between"
                >
                  <Box className="flex items-end justify-between">
                    <CustomInputComponent
                      style={{ width: "48%", mr: "8px" }}
                      label={"email"}
                      state={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />

                    <Box sx={{ width: "48%", mr: "8px" }}>
                      <label
                        htmlFor="inputField"
                        style={{
                          display: "block",
                          fontSize: 10,
                          marginLeft: 12,
                        }}
                      >
                        Phone
                      </label>
                      <PhoneInput
                        style={{
                          width: "100%",
                          height: "41px",
                          borderRadius: "5px",
                          border: "1px solid #071148",
                          padding: "10px",
                        }}
                        placeholder="Enter phone number"
                        defaultCountry="IN"
                        value={phoneNumber}
                        onChange={setPhoneNumber}
                        focusInputOnCountrySelection
                      />
                    </Box>

                    <Box name="countryinput" sx={{ width: "48%", mr: "8px" }}>
                      <label
                        htmlFor="inputField"
                        style={{
                          display: "block",
                          fontSize: 10,
                          marginLeft: 12,
                        }}
                      >
                        country
                      </label>

                      <Select
                        sx={{
                          height: "41px",
                        }}
                        onChange={(e) => {
                          setCountry(e.target.value);
                        }}
                        value={country?.country}
                      >
                        {countryCodes.map((country) => (
                          <MenuItem value={country.code}>
                            {country.country}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </Box>
                  <Button
                    sx={{ ...buttonStyle, marginTop: "34px" }}
                    onClick={() => setShowClientsModal(true)}
                  >
                    Change Client
                  </Button>
                </Box>

                <Divider
                  sx={{ width: "100%", mt: "12px", mb: "12px" }}
                  variant="fullWidth"
                />

                {selectedProduct?.prices?.length > 0 ? (
                  <Box className="w-full flex flex-col items-center justify-center">
                    <Box className="flex items-center justify-between w-full">
                      <Typography sx={{ color: "#071148", fontSize: "16px" }}>
                        {selectedProduct?.prodName}
                      </Typography>
                      <Box className="flex items-center">
                        {/* <CustomInputComponent
                          label={"quantity"}
                          state={productQuantity}
                          onChange={(e) => setProductQuantity(e.target.value)}
                          style={{ width: "90px" }}
                        /> */}

                        <Typography sx={{ mt: "12px", color: "#071148" }}>
                          {currencySymbols[
                            selectedProduct?.prices?.[0]?.currency.toUpperCase()
                          ] +
                            "" +
                            selectedProduct?.prices?.[0]?.amount +
                            "/" +
                            selectedProduct?.prices?.[0]?.intervalCount +
                            " " +
                            selectedProduct?.prices?.[0]?.interval}
                        </Typography>
                      </Box>
                    </Box>
                    <Button
                      sx={{ ...buttonStyle, marginTop: "34px" }}
                      onClick={() => setShowSelectProductModal(true)}
                    >
                      Change Product
                    </Button>
                    <Typography
                      sx={{ fontSize: "12px", mt: "12px", color: "#071148" }}
                    >
                      Subscription will start from the day of payment!
                    </Typography>
                  </Box>
                ) : (
                  <Button
                    onClick={() => {
                      !isValidPhoneNumber(phoneNumber) || !isValidEmail(email)
                        ? toast.info(
                            "Enter email and phone number to select Product"
                          )
                        : setShowSelectProductModal(true);
                    }}
                    sx={{ ...buttonStyle, mt: "42px" }}
                  >
                    Select Product
                  </Button>
                )}
              </CardContent>
            </Card>
          ) : (
            <Button
              sx={{ ...buttonStyle, marginBottom: "34px" }}
              onClick={() => setShowClientsModal(true)}
            >
              Select Client
            </Button>
          )}

          {selectedClient?.name && selectedProduct?.prodName && (
            <Button
              onClick={createNewSubscription}
              sx={{ ...buttonStyle, mt: "24px" }}
            >
              Start Subscription
            </Button>
          )}
        </Box>
      </Container>

      {/* Select Product */}
      <Dialog
        PaperProps={{
          sx: {
            paddingX: "50px",
            height: ["350px", "500px", "550px"],
            width: ["500px", "700px", "858px"],
          },
        }}
        open={showSelectProductModal}
      >
        <CloseRoundedIcon
          sx={{
            cursor: "pointer",
            position: "absolute",
            top: "28px",
            right: "28px",
            color: colors.blueMain,
          }}
          onClick={() => setShowSelectProductModal(false)}
        />

        <DialogTitle
          textAlign={"center"}
          sx={{
            fontSize: "20px",
            fontWeight: 500,
            paddingTop: "42px",
            color: colors.blueMain,
          }}
        >
          Select Product
        </DialogTitle>

        <DialogContent>
          <TableContainer
            elevation={0}
            component={Paper}
            style={{ maxWidth: "fit-content" }}
          >
            <Table>
              <TableHead>
                <TableCell sx={headerCellStyle} align="left"></TableCell>{" "}
                <TableCell sx={headerCellStyle} align="left">
                  Pricing Plan
                </TableCell>
                <TableCell sx={headerCellStyle} align="right">
                  Active Subscription
                </TableCell>
                <TableCell sx={headerCellStyle} align="right">
                  Cost
                </TableCell>
              </TableHead>
              <TableBody>
                {trainerData?.trainerProducts?.map((product) => {
                  let name = product.prodName;
                  let cost =
                    currencySymbols[
                      product?.prices?.[0]?.currency.toUpperCase()
                    ] +
                    "" +
                    product?.prices?.[0]?.amount +
                    "/" +
                    product?.prices?.[0]?.intervalCount +
                    " " +
                    product?.prices?.[0]?.interval;

                  if (cost.includes("null") || cost.includes("undefined"))
                    return <></>;
                  return (
                    <TableRow
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        setSelectedProduct(product);
                      }}
                    >
                      <TableCell sx={bodyCellStyle}>
                        <Radio checked={selectedProduct === product} />
                      </TableCell>
                      <TableCell
                        sx={bodyCellStyle}
                        align="left"
                        component="th"
                        scope="row"
                      >
                        {name}
                      </TableCell>
                      <TableCell sx={bodyCellStyle} align="right">
                        {""}
                      </TableCell>
                      <TableCell sx={bodyCellStyle} align="right">
                        {cost}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            sx={{
              ...buttonStyle,
              marginBottom: "34px",
            }}
            onClick={() => setShowSelectProductModal(false)}
          >
            <DoneRoundedIcon fontWeight={"700"} />
            <span style={{ marginLeft: 10 }}>Done</span>
          </Button>
        </DialogActions>
      </Dialog>

      {/* Select Client  */}
      <Dialog
        PaperProps={{
          sx: {
            paddingX: "50px",
            height: ["350px", "500px", "550px"],
            width: ["300px", "500px", "700px", "858px"],
          },
        }}
        open={showClientsModal}
      >
        <CloseRoundedIcon
          sx={{
            cursor: "pointer",
            position: "absolute",
            top: "28px",
            right: "28px",
            color: colors.blueMain,
          }}
          onClick={() => setShowClientsModal(false)}
        />

        <DialogTitle
          textAlign={"center"}
          sx={{
            fontSize: "20px",
            fontWeight: 500,
            paddingTop: "42px",
            color: colors.blueMain,
          }}
        >
          Select Client
        </DialogTitle>
        <DialogContent>
          <Box>
            {users?.map((user) => (
              <Box
                onClick={() => {
                  setSelectedClient(user);
                  setEmail(user?.Email);
                }}
                sx={{
                  cursor: "pointer",
                  paddingY: "8px",
                  borderBottom: "1px solid #071148",
                }}
              >
                <Radio checked={selectedClient == user} />
                {user?.name || user?.name}
              </Box>
            ))}
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            sx={{
              ...buttonStyle,
              marginBottom: "34px",
            }}
            onClick={() => {
              setShowClientsModal(false);
            }}
          >
            <DoneRoundedIcon fontWeight={"700"} />
            <span style={{ marginLeft: 10 }}>Done</span>
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Layout>
  );
}

const countryCodes = [
  { country: "Argentina", code: "AR" },
  { country: "Australia", code: "AU" },
  { country: "Austria", code: "AT" },
  { country: "Belgium", code: "BE" },
  { country: "Bolivia", code: "BO" },
  { country: "Brazil", code: "BR" },
  { country: "Bulgaria", code: "BG" },
  { country: "Canada", code: "CA" },
  { country: "Chile", code: "CL" },
  { country: "Colombia", code: "CO" },
  { country: "Costa Rica", code: "CR" },
  { country: "Croatia", code: "HR" },
  { country: "Cyprus", code: "CY" },
  { country: "Czech Republic", code: "CZ" },
  { country: "Denmark", code: "DK" },
  { country: "Dominican Republic", code: "DO" },
  { country: "Estonia", code: "EE" },
  { country: "Finland", code: "FI" },
  { country: "France", code: "FR" },
  { country: "Germany", code: "DE" },
  { country: "Greece", code: "GR" },
  { country: "Hong Kong SAR China", code: "HK" },
  { country: "Hungary", code: "HU" },
  { country: "Iceland", code: "IS" },
  { country: "India", code: "IN" },
  { country: "Indonesia", code: "ID" },
  { country: "Ireland", code: "IE" },
  { country: "Israel", code: "IL" },
  { country: "Italy", code: "IT" },
  { country: "Japan", code: "JP" },
  { country: "Latvia", code: "LV" },
  { country: "Liechtenstein", code: "LI" },
  { country: "Lithuania", code: "LT" },
  { country: "Luxembourg", code: "LU" },
  { country: "Malta", code: "MT" },
  { country: "Mexico ", code: "MX" },
  { country: "Netherlands", code: "NL" },
  { country: "New Zealand", code: "NZ" },
  { country: "Norway", code: "NO" },
  { country: "Paraguay", code: "PY" },
  { country: "Peru", code: "PE" },
  { country: "Poland", code: "PL" },
  { country: "Portugal", code: "PT" },
  { country: "Romania", code: "RO" },
  { country: "Singapore", code: "SG" },
  { country: "Slovakia", code: "SK" },
  { country: "Slovenia", code: "SI" },
  { country: "Spain", code: "ES" },
  { country: "Sweden", code: "SE" },
  { country: "Switzerland", code: "CH" },
  { country: "Thailand", code: "TH" },
  { country: "Trinidad & Tobago", code: "TT" },
  { country: "United Arab Emirates", code: "AE" },
  { country: "United Kingdom", code: "GB" },
  { country: "United States", code: "US" },
  { country: "Uruguay", code: "UY" },
];
