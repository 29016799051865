import React from "react";
import { styled } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const StyledMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
  },
}));

function AddMenu(props) {
  const { anchorEl, handleClose, options } = props;

  const handleMenuItemClick = (event, index) => {
    options[index].onClick(event);
    handleClose();
  };

  return (
    <StyledMenu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      {options.map((option, index) => (
        <MenuItem
          sx={{
            fontSize: "12px",
            fontFamily: "DM Sans",
            fontWeight: 500,
          }}
          key={option.label}
          onClick={(event) => handleMenuItemClick(event, index)}
        >
          {option.label}
        </MenuItem>
      ))}
    </StyledMenu>
  );
}

export default AddMenu;
