import React, { useEffect, useState } from "react";
import {
  PDFViewer,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import { mealSchedule } from "./ExportOneMeal";
import SpurfitLogo from "@/assets/spurfit.png";
import LivezyLogo from "@/assets/livezy.png";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
// Create styles
Font.register({
  family: "DMSans",
  src: "https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap",
});
const styles = StyleSheet.create({
  page: {
    paddingHorizontal: 10,
    paddingVertical: 20,
    marginBottom: 10,
  },
  title: {
    fontSize: 20,
    marginLeft: 10,
    fontWeight: "bold",
  },
  container: {
    flex: 1,
    padding: 10,
    backgroundColor: "#fff",
  },
  mealContainer: {
    borderWidth: 1,
    borderRadius: 10,
    overflow: "hidden",
    marginBottom: 10,
  },
  mealHeader: {
    backgroundColor: "#4338ca",
    padding: 10,
  },
  mealHeaderText: {
    color: "#fff",
    fontSize: 16,
    fontWeight: "bold",
  },
  foodContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 10,
    // borderBottomWidth: 1,
    // borderBottomColor: "#d3d3d3",
  },
  foodText: {
    fontSize: 14,
    fontWeight: "500",
    width: "50%",
  },
  foodSubText: {
    fontSize: 12,
    color: "#808080",
  },
  macrosContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  macrosText: {
    fontSize: 12,
    fontWeight: "500",
  },
  recipeContainer: {
    borderWidth: 1,
    borderRadius: 10,
    overflow: "hidden",
    marginBottom: 10,
  },
  recipeHeader: {
    backgroundColor: "#4338ca",
    padding: 10,
  },
  recipeHeaderText: {
    color: "#fff",
    fontSize: 16,
    fontWeight: "bold",
  },
  descriptionContainer: {
    padding: 10,
  },
  descriptionText: {
    fontSize: 14,
    marginBottom: 10,
  },
  ingredientsContainer: {
    padding: 10,
  },
  ingredientsText: {
    fontWeight: "bold",
    marginBottom: 10,
    fontSize: 14,
  },
  directionsContainer: {
    padding: 10,
  },
  directionsText: {
    fontWeight: "bold",
    marginBottom: 10,
    fontSize: 14,
  },
});

// Create Document Component
const MyDocument = ({ recipeItems, mealPlan, isLivezy }) => {
  // let mealPlanToExport = mealSchedule.mealPlans[0].dayMealPlan.plandetails;
  let meals = mealPlan?.plandetails?.meals;
  // let dayIndex = mealSchedule.mealPlans[0].dayIdx;
  let mealPlanName = mealPlan.reference_name;

  return (
    <Document>
      <Page size="A4" style={styles.page} wrap={false}>
        <Text style={styles.title}>{mealPlanName}</Text>
        <View style={styles.container}>
          {meals?.map((meal, index) => {
            let foods = meal.foods;
            return (
              <View style={styles.mealContainer} key={index}>
                <View style={styles.mealHeader}>
                  <Text style={styles.mealHeaderText}>{meal.name}</Text>
                </View>
                {foods.map((food, index) => (
                  <View style={styles.foodContainer} key={index}>
                    <View>
                      <Text style={styles.foodText}>{food.name}</Text>
                      <Text style={styles.foodSubText}>
                        {formatNumber(food.measurement_count)}{" "}
                        {food.measurement_description} •{" "}
                        {formatNumber(food.calories)} calories
                      </Text>
                    </View>
                    <View style={styles.macrosContainer}>
                      <Text style={styles.macrosText}>
                        Protein: {formatNumber(food.protein)}g&nbsp;
                      </Text>
                      <Text>•&nbsp;</Text>
                      <Text style={styles.macrosText}>
                        Carbs: {formatNumber(food.carbs)}g&nbsp;
                      </Text>
                      <Text>•&nbsp;</Text>
                      <Text style={styles.macrosText}>
                        Fat: {formatNumber(food.fat)}g
                      </Text>
                    </View>
                  </View>
                ))}
              </View>
            );
          })}
        </View>

        {recipeItems?.length > 0 && (
          <View
            style={{
              padding: 10,
            }}
          >
            <Text style={{ color: "#000", marginBottom: 10, fontWeight: 600 }}>
              Recipes:
            </Text>
            {recipeItems.map((recipe, index) => (
              <View style={styles.recipeContainer} key={index}>
                <View style={styles.recipeHeader}>
                  <Text style={styles.recipeHeaderText}>
                    {recipe.recipe_name}
                  </Text>
                </View>
                {recipe.recipe_description && (
                  <View style={styles.descriptionContainer}>
                    <Text style={styles.descriptionText}>
                      {recipe.recipe_description}
                    </Text>
                  </View>
                )}
                <View style={styles.ingredientsContainer}>
                  <Text style={styles.ingredientsText}>Ingredients:</Text>
                  {recipe.ingredients.map((ingredient, index) => (
                    <Text key={index} style={{ fontSize: 14 }}>
                      • {ingredient.ingredient_description}
                    </Text>
                  ))}
                </View>
                <View style={styles.directionsContainer}>
                  <Text style={styles.directionsText}>Directions:</Text>
                  {recipe.directions.map((direction, index) => (
                    <Text key={index} style={{ fontSize: 14 }}>
                      {direction.direction_number}.{" "}
                      {direction.direction_description}
                    </Text>
                  ))}
                </View>
              </View>
            ))}
          </View>
        )}

        <View
          style={{
            position: "absolute",
            bottom: -5,
            right: 20,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text style={{ fontSize: 12, textAlign: "center" }}>Powered By</Text>
          {isLivezy ? (
            <Image
              src={LivezyLogo}
              style={{
                height: 38,
                width: 80,
                objectFit: "contain",
                marginBottom: 8,
              }}
            />
          ) : (
            <Image
              src={SpurfitLogo}
              style={{
                height: 45,
                width: 80,
              }}
            />
          )}
        </View>
      </Page>
    </Document>
  );
};

const ExportMeal = () => {
  const [recipeItems, setRecipeItems] = useState(null);
  const [mealPlan, setMealPlan] = useState(null);
  const location = useLocation();

  const isLivezy = useSelector((state) => state.auth.isLivezy);

  let mealPlanToExport = mealSchedule.mealPlans[0].dayMealPlan.plandetails;
  let meals = mealPlanToExport.meals;
  const getRecipeItemsForSingleMeal = (meals) => {
    let recipeItems = [];
    meals.forEach((meal) => {
      meal.foods.forEach((food) => {
        if (food.recipe_id && food.recipeItem) {
          recipeItems.push(food.recipeItem);
        }
      });
    });
    // double the recipe items to show in the pdf
    // recipeItems = [...recipeItems, ...recipeItems];
    setRecipeItems(recipeItems);
  };

  useEffect(() => {
    if (location?.state) {
      console.log("location.state", location.state);
      let mealPlan = location.state?.mealPlan;
      let meals = mealPlan?.plandetails?.meals;
      getRecipeItemsForSingleMeal(meals);
      setMealPlan(location.state?.mealPlan);
    }
  }, [location?.state]);
  return recipeItems ? (
    <PDFViewer
      style={{
        width: "100%",
        height: "100vh",
      }}
    >
      <MyDocument
        recipeItems={recipeItems}
        mealPlan={mealPlan}
        isLivezy={isLivezy}
      />
    </PDFViewer>
  ) : (
    <div>Loading...</div>
  );
};

export default ExportMeal;

const formatNumber = (number) => {
  // if not a number return 0
  if (isNaN(+number)) return 0;
  // if the number is having decimal points then limit it to 2 decimal points else return the number
  return +number % 1 !== 0 ? (+number).toFixed(2) : number;
};
