import { computeData } from "@/ApiServices/Garmin/Api";
import axios from "axios";
import FitParser from "fit-file-parser";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import GarminWorkoutCard from "./GarminWorkoutCard";

const GarminStatsCard = ({
  data,
  index,
  setCompareSearchWorkout,
  compareSearchWorkout,
  setWorkoutFromCalenderForCompare,
}) => {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const gradientTextStyle = {
    background: "linear-gradient(90deg, #464FEB 10.42%, #8330E9 100%)",
    WebkitBackgroundClip: "text" /* For Safari */,
    backgroundClip: "text",
    color: "transparent",
    fontWeight: "600",
  };

  const gradientBorderStyle = {
    border: "2px solid #8330E9",
    borderColor: "#8330E9",
    borderRadius: "8px",
  };
  const history = useHistory();
  const [loadingCompute, setLoadingCompute] = React.useState(false);
  const [fitData, setFitData] = React.useState(null);

  const [effort, setEffort] = React.useState(null);
  const [fatigue, setFatigue] = React.useState(null);

  const compute = async () => {
    setLoadingCompute(true);
    const summaryId = data.summaryID;
    if (!summaryId) return;
    const res = await computeData(summaryId);
    console.log("computeData res:", res);

    if (res?.loadSummary?.normalized_auc_mean_hr) {
      setEffort(Math.round(res.loadSummary.normalized_auc_mean_hr * 100));
    }
    if (res?.fatigueSummary?.total_peak_fatigue) {
      setFatigue(res.fatigueSummary.total_peak_fatigue.toFixed(2));
    }

    setLoadingCompute(false);
  };

  const getAxiosDataAsArrayBuffer = async () => {
    const url = data.fileUrl;
    if (!url) return;
    const response = await axios({
      url: `${APIURL}CF/api/garmin/getS3FileByUrl`,
      method: "POST",
      data: { url },
    });

    var fitParser = new FitParser({
      force: true,
      speedUnit: "km/h",
      lengthUnit: "km",
      temperatureUnit: "kelvin",
      elapsedRecordField: true,
      mode: "both",
    });

    // Parse your file
    fitParser.parse(response.data.result.data, function (error, data) {
      // Handle result of parse method
      if (error) {
        console.log(error);
      } else {
        console.log("Fit file parsed Data: ", { data });
        // onChange(data, file);
        setFitData({
          fileName: "1712649246543.fit",
          data: data,
        });

        if (data?.records?.length > 0) {
          const records = data.records;

          let isHeartRateAvailable = false;
          let isSpeedAvailable = false;
          records?.forEach((rec) => {
            if (rec?.heart_rate > 0) {
              isHeartRateAvailable = true;
            }
            if (rec?.speed > 0) {
              isSpeedAvailable = true;
            }

            if (isHeartRateAvailable && isSpeedAvailable) {
              return;
            }
          });

          // if (isHeartRateAvailable && isSpeedAvailable) {
          //   compute();
          // }
        }
      }
    });
  };
  useEffect(() => {
    getAxiosDataAsArrayBuffer();
  }, []);

  return (
    <GarminWorkoutCard
      data={data}
      fitData={fitData}
      index={index}
      setCompareSearchWorkout={setCompareSearchWorkout}
      compareSearchWorkout={compareSearchWorkout}
      setWorkoutFromCalenderForCompare={setWorkoutFromCalenderForCompare}
    />
  );

  return (
    <div className="flex flex-col w-full shadow-lg border p-2 rounded-xl gap-2 relative bg-white-pure">
      <div className="flex w-full justify-between px-4">
        <p className="font-DMSans text-gray-600 text-font16 capitalize px-2 pt-2 pb-1">
          {data.summary?.activityName}
        </p>
        <div
          className="font-DMSans border rounded-lg border-gray-600 px-[12px] py-[6px] text-font14 cursor-pointer"
          onClick={() => {
            history.push(`/analyze?url=${data.fileUrl}`, {
              data: data?.summary,
            });
          }}
          style={gradientBorderStyle}
        >
          <p style={gradientTextStyle}>Analyze</p>
        </div>

        <button
          className="absolute top-2 right-32 rounded-lg p-1 font-DMSans text-font12 font-normal text-violet-500 border border-violet-500 hover:bg-violet-500 hover:text-white-pure transition-all duration-200 ease-in-out"
          onClick={compute}
        >
          {loadingCompute ? "Computing..." : "Compute"}
        </button>
      </div>

      <div className="flex gap-2 flex-wrap px-1">
        {data.summary?.durationInSeconds && (
          <GarminStat
            label="Duration"
            icon={icons.duration}
            value={formatTime(data.summary?.durationInSeconds)}
            unit=""
          />
        )}

        {data.summary?.distanceInMeters && (
          <GarminStat
            label="Distance"
            icon={icons.distance}
            value={formatDistance(data.summary?.distanceInMeters)}
            unit="km"
          />
        )}

        {data.summary?.activeKilocalories && (
          <GarminStat
            label="Calories"
            icon={icons.calorie}
            value={data.summary?.activeKilocalories}
            unit="kCal"
          />
        )}
        {data.summary?.totalElevationGainInMeters && (
          <GarminStat
            label="Elevation"
            icon={icons.elevation}
            value={formatDecimal(data.summary?.totalElevationGainInMeters)}
            unit="meter"
          />
        )}

        <GarminStatWithMaxNAvg
          label="Heart Rate"
          icon={icons.heartRate}
          unit="bpm"
          max={data.summary?.maxHeartRateInBeatsPerMinute}
          avg={data.summary?.averageHeartRateInBeatsPerMinute}
        />

        <GarminStatWithMaxNAvg
          label="pace"
          icon={icons.pace}
          unit="min/km"
          max={formatPace(data.summary?.maxPaceInMinutesPerKilometer)}
          avg={formatPace(data.summary?.averagePaceInMinutesPerKilometer)}
        />

        <GarminStatWithMaxNAvg
          label="Cadence"
          icon={icons.cadence}
          unit="spm"
          max={formatDecimal(data.summary?.maxRunCadenceInStepsPerMinute)}
          avg={Math.round(data.summary?.averageRunCadenceInStepsPerMinute ?? 0)}
        />

        <GarminStatWithMaxNAvg
          label="Speed"
          icon={icons.speed}
          unit="km/h"
          max={formatSpeed(data.summary?.maxSpeedInMetersPerSecond)}
          avg={formatSpeed(data.summary?.averageSpeedInMetersPerSecond)}
        />
      </div>
      {/* coputed data */}
      {loadingCompute ? (
        <p className="font-DMSans text-gray-600 text-font16 capitalize px-2 pt-2 pb-1">
          Fetching computed data...
        </p>
      ) : (
        <div>
          <p className="font-DMSans text-gray-600 text-font16 capitalize px-2 pt-2 pb-1">
            Computed Data
          </p>
          {/* Effort  */}
          {effort && (
            <div className="font-DMSans text-gray-600 text-font14 font-normal px-2 ">
              <span>Effort: </span> {effort}
            </div>
          )}

          {fatigue && (
            <div className="font-DMSans text-gray-600 text-font14 font-normal px-2 ">
              <span>Fatigue: </span> {fatigue}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default GarminStatsCard;

const GarminStat = ({ icon, value, unit, label }) => {
  if (value === undefined) return null;
  return (
    <div className="flex flex-col items-center mb-2 h-[110px] border rounded-md p-2 relative overflow-hidden">
      <img src={icon} alt="icon" className="size-6" />
      <p className="font-DMSans font-normal  text-font12 text-black-pure">
        {label}
      </p>
      <p className="font-DMSans font-semibold text-black-pure text-font14 mt-2">
        {value} {unit}
      </p>
    </div>
  );
};

const GarminStatWithMaxNAvg = ({ icon, unit, label, max, avg }) => {
  if (!max || !avg) return null;
  return (
    <div className="flex flex-col items-center mb-2 h-[110px] border rounded-md p-2 relative overflow-hidden">
      <img src={icon} alt="icon" className="size-6" />

      <p className="font-DMSans font-normal  text-font12 text-black-pure capitalize">
        {label}
      </p>
      <p className="font-DMSans font-normal text-black-pure text-font14 mt-2">
        Max:{" "}
        <span className="font-semibold">
          {max} {unit}
        </span>
      </p>
      <p className="font-DMSans font-normal text-black-pure text-font14">
        Avg:{" "}
        <span className="font-semibold">
          {avg} {unit}
        </span>
      </p>
    </div>
  );
};

const sportImg = {
  RUNNING: "https://i.ibb.co/54hGDWD/Firefly-Running-Person-93528.jpg",
  CYCLING:
    "https://i.ibb.co/PwLk11y/Firefly-a-person-cycling-for-fitness-sport-and-health-vector-image-32983.jpg",
  LAP_SWIMMING:
    "https://i.ibb.co/4Yqq3nw/Firefly-a-man-swimming-for-preparing-for-ironman-race-sport-and-health-vector-image-32983.jpg",
};

const icons = {
  duration: "https://img.icons8.com/8330e9/ios/50/time_2.png",
  distance: "https://img.icons8.com/wired/64/8330e9/point-objects.png",
  pace: "https://img.icons8.com/8330e9/ios/50/speed.png",
  calorie: "https://img.icons8.com/8330e9/ios/50/fire-element--v1.png",
  heartRate: "https://img.icons8.com/8330e9/ios/50/heart-with-pulse--v1.png",
  cadence: "https://img.icons8.com/8330e9/windows/32/shoes.png",
  speed: "https://img.icons8.com/8330e9/ios/50/speed.png",
  elevation:
    "https://img.icons8.com/8330e9/external-outline-black-m-oki-orlando/32/external-elevation-geography-outline-outline-black-m-oki-orlando.png",
};

const formatTime = (seconds) => {
  if (!seconds) return null;
  let hours = Math.floor(seconds / 3600);
  let minutes = Math.floor((seconds % 3600) / 60);
  let remainingSeconds = seconds % 60;

  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
};
const formatDistance = (meters) => {
  if (!meters) return null;
  return (meters / 1000).toFixed(2);
};

const formatPace = (minPerKm) => {
  if (!minPerKm) return null;
  let minutes = Math.floor(minPerKm);
  let seconds = Math.round((minPerKm % 1) * 60);
  return `${minutes}:${seconds.toString().padStart(2, "0")}`;
};

const formatSpeed = (metersPerSec) => {
  if (!metersPerSec) return null;
  // conver to km/h
  return (metersPerSec * 3.6).toFixed(2);
};

const formatDecimal = (val) => {
  if (!val) return null;
  // if the value is not in decimal format
  if (val % 1 === 0) {
    return val;
  }
  return val.toFixed(2);
};
