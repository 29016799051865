import AddRoundedIcon from "@mui/icons-material/AddRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { store } from "../../redux/store";
import { NutritionInput } from "../Nutrition/AddRecipieModal";

const SEPECIFY_IN_GRAMS = "Grams";
const SEPECIFY_IN_PERCENTAGE = "Percentage";

const AUTO_CALCULATE_CARBS = "Carbs";
const AUTO_CALCULATE_PROTEIN = "Protein";
const AUTO_CALCULATE_FAT = "Fat";

function CreateMacrosModal({
  isOpen,
  setIsOpen,
  item,
  getMealPlans,
  setMealData,
  selectedDate,
  userUUID,
  updateData,
  setUpdateData,
}) {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const [unit, setUnit] = useState(SEPECIFY_IN_GRAMS);
  const [calories, setCalories] = useState(0);
  const [autoCalculate, setAutoCalculate] = useState(AUTO_CALCULATE_CARBS);
  const [protein, setProtein] = useState(0);
  const [fat, setFat] = useState(0);
  const [carbs, setCarbs] = useState(0);
  const [meals, setMeals] = useState([
    { name: "Breakfast", calories: 0 },
    { name: "Lunch", calories: 0 },
    { name: "Dinner", calories: 0 },
  ]);
  const [isRetriving, setIsRetriving] = useState(item?.id ? true : false);

  const unitOptions = [SEPECIFY_IN_GRAMS, SEPECIFY_IN_PERCENTAGE];
  const autoCalculateOptions = [
    AUTO_CALCULATE_CARBS,
    AUTO_CALCULATE_PROTEIN,
    AUTO_CALCULATE_FAT,
  ];

  const autoCalculateSelected = () => {
    // based on autoCalculate state calculate the value of selected field using other two and calories
    console.log("autoCalculate ===>>> ", autoCalculate);
    switch (autoCalculate) {
      case AUTO_CALCULATE_CARBS:
        console.log("AUTO_CALCULATE_CARBS ===>>> ", calories, protein, fat);
        let c = (calories - protein * 4 - fat * 9) / 4;
        setCarbs(c);
        break;
      case AUTO_CALCULATE_PROTEIN:
        let p = (calories - carbs * 4 - fat * 9) / 4;
        setProtein(p);
        break;
      case AUTO_CALCULATE_FAT:
        let f = (calories - protein * 4 - carbs * 4) / 9;
        setFat(f);
        break;
      default:
        break;
    }
  };

  const retriveMeals = async () => {
    if (!item?.id) return;
    setIsRetriving(true);
    let plandetails = item?.plandetails;
    if (!plandetails?.meals) return;

    setCalories(plandetails?.calories);
    setProtein(plandetails?.macros?.protein);
    setFat(plandetails?.macros?.fat);
    setCarbs(plandetails?.macros?.carbs);
    setMeals(plandetails?.meals);
    setTimeout(() => {
      setIsRetriving(false);
    }, 1000);
  };

  useEffect(() => {
    retriveMeals();
  }, []);

  useEffect(() => {
    console.log("item from macros modal: ", item);
    if (!isRetriving) {
      autoCalculateSelected();
    }
  }, [calories, protein, fat, carbs]);

  useEffect(() => {
    if (!isRetriving) {
      setMeals(
        meals.map((meal) => {
          return {
            ...meal,
            calories: (calories / meals.length).toFixed(2),
          };
        })
      );
    }
    console.log("meals: ", meals);
  }, [calories]);

  const createMeal = (data) => {
    axios({
      method: "post",
      url: `${APIURL}CF/api/meal/create`,
      data: data,
    })
      .then((res) => {
        console.log("meal created: ", res);
        pushMealPlan(res.data.meal.id);
      })
      .catch((err) => {
        console.log("meal creation error: ", err);
      })
      .finally(() => {
        setIsOpen(false);
      });
  };

  const updateMeal = (data) => {
    // remove nulls from updated item
    Object.keys(data).forEach((key) => data[key] == null && delete data[key]);

    axios({
      method: "post",
      url: `${APIURL}CF/api/meal/update?id=${item?.id}`,
      data: data,
    })
      .then((res) => {
        console.log("meal updated: ", res);
        // getMealPlans();
      })
      .catch((err) => {
        console.log("meal update error: ", err);
      })
      .finally(() => {
        setIsOpen(false);
      });
  };

  const pushMealPlan = async (id) => {
    await axios({
      method: "post",
      url: `${APIURL}CF/api/meal/assignMeal`,
      data: {
        atheleteID: userUUID,
        mealId: id,
        dateAssigned: moment(selectedDate, "DD-MM-YYYY")
          ?.set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          })
          ?.format("YYYY-MM-DD"),
      },
    })
      .then((res) => {
        console.log("res ====>>> success ", res);
        setUpdateData(!updateData);
      })
      .catch((err) => {
        // setIsLoading(false);
        console.log(["err ===>>> ", err]);
      });
  };

  const onSave = () => {
    let macros = {
      protein: +protein,
      fat: +fat,
      carbs: +carbs,
    };
    let totalCalories = +calories;

    // in meals arrange the data in priority of meal name like breakfast, lunch, dinner, snacks
    meals.sort((a, b) => {
      if (a.name === "Breakfast") return -1;
      if (b.name === "Breakfast") return 1;
      if (a.name === "Lunch") return -1;
      if (b.name === "Lunch") return 1;
      if (a.name === "Dinner") return -1;
      if (b.name === "Dinner") return 1;
      if (a.name === "Snacks") return -1;
      if (b.name === "Snacks") return 1;
    });

    let plandetails = {
      meals,
      macros,
      calories: totalCalories,
    };

    let data = {
      ...item,
      coachId: store.getState().auth.trainerUUID,
      plandetails,
    };
    console.log("plan details: ", data);
    if (item?.id) {
      updateMeal(data);
    } else {
      createMeal(data);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
        setMealData([]);
      }}
      PaperProps={{
        sx: {
          borderRadius: "20px",
        },
      }}
      maxWidth="sm"
      fullWidth
    >
      <div className="add-program-modal-head">
        <p className="add-program-modal-head-title">Edit Macros</p>
        <CloseRoundedIcon
          sx={{
            position: "absolute",
            right: 24,
            top: 24,
            cursor: "pointer",
          }}
          onClick={() => setIsOpen(false)}
        />
      </div>
      <DialogContent>
        <Stack gap={1}>
          <Stack gap={2} direction={"row"}>
            <SelectionComponent
              label={"Specify in"}
              value={unit}
              setValue={setUnit}
              options={unitOptions}
              sx={{ flex: 1 }}
            />

            <NutritionInput
              height={"40px"}
              label={"Calories"}
              value={calories}
              onChange={(value) => {
                console.log("cal cange", value);
                setCalories(value);
              }}
              type={"number"}
              style={{ minWidth: "32%" }}
            />

            <SelectionComponent
              label={"Auto Calculate"}
              value={autoCalculate}
              setValue={setAutoCalculate}
              options={autoCalculateOptions}
              sx={{ flex: 1 }}
            />
          </Stack>
          <Stack gap={2} direction={"row"}>
            <NutritionInput
              height={"40px"}
              disabled={autoCalculate === AUTO_CALCULATE_PROTEIN}
              label={"Protein"}
              value={protein}
              onChange={(value) => setProtein(value)}
              style={{ minWidth: "32%" }}
              right={unit === SEPECIFY_IN_GRAMS ? "g" : "%"}
            />
            <NutritionInput
              height={"40px"}
              disabled={autoCalculate === AUTO_CALCULATE_FAT}
              label={"Fat"}
              value={fat}
              onChange={(value) => setFat(value)}
              type={"number"}
              style={{ minWidth: "32%" }}
              right={unit === SEPECIFY_IN_GRAMS ? "g" : "%"}
            />
            <NutritionInput
              height={"40px"}
              disabled={autoCalculate === AUTO_CALCULATE_CARBS}
              label={"Carbs"}
              value={carbs}
              onChange={(value) => setCarbs(value)}
              type={"number"}
              style={{ minWidth: "32%" }}
              right={unit === SEPECIFY_IN_GRAMS ? "g" : "%"}
            />
          </Stack>

          <Divider sx={{ my: 2 }} />
        </Stack>
        <Stack gap={1}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: 14,
              color: "#000000",
            }}
          >
            Meal Categories
          </Typography>
          <Stack gap={1}>
            {meals?.length > 0 &&
              meals?.map((meal, mealIdx) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    <SelectionComponent
                      label={"Meal"}
                      value={meal.name}
                      onChange={(value) => {
                        setMeals(
                          meals?.map((m) => {
                            if (m.name === meal.name) {
                              return {
                                ...m,
                                name: value,
                              };
                            }
                            return m;
                          })
                        );
                      }}
                      options={mealOptions}
                      selectedOptions={mealOptions.filter((m) => {
                        const mealNames = meals.map((m) => m.name);
                        return mealNames.includes(m);
                      })}
                      sx={{ flex: 1 }}
                    />

                    <NutritionInput
                      height={"40px"}
                      disabled={mealIdx === meals.length - 1}
                      label={"Calories"}
                      value={meal.calories}
                      right={"cals"}
                      onChange={(value) => {
                        const parsedValue = parseFloat(value);

                        if (isNaN(parsedValue)) {
                          value = "0"; // set NaN value as zero
                        }
                        const lastMealIndex = meals.length - 1;
                        const lastMeal = meals[lastMealIndex];

                        const updatedMeals = meals.map((m, index) => {
                          if (m.name === meal.name) {
                            return {
                              ...m,
                              calories: parseFloat(value),
                            };
                          }
                          return m;
                        });

                        const totalCalories = updatedMeals.reduce(
                          (total, m) => total + parseFloat(m.calories),
                          0
                        );

                        const remainingCalories =
                          parseFloat(calories) - totalCalories;

                        const updatedLastMeal = {
                          ...lastMeal,
                          calories: (
                            parseFloat(lastMeal.calories) + remainingCalories
                          ).toFixed(2),
                        };

                        const updatedMealsWithLastMeal = [
                          ...updatedMeals.slice(0, lastMealIndex),
                          updatedLastMeal,
                        ];

                        setMeals(updatedMealsWithLastMeal);
                      }}
                      type={"number"}
                      style={{ minWidth: "32%" }}
                    />
                    <DeleteRoundedIcon
                      sx={{ cursor: "pointer", my: "auto", mt: "30px" }}
                      onClick={() => {
                        setMeals(meals?.filter((m) => m.name !== meal.name));
                      }}
                    />
                  </Box>
                );
              })}
          </Stack>

          <Box>
            <Button
              variant={"text"}
              sx={{
                color: "#000000",
                textTransform: "none",
              }}
              onClick={() => {
                setMeals([...meals, { name: "", calories: 0 }]);
                autoCalculateSelected();
              }}
            >
              <AddRoundedIcon /> Add More Category
            </Button>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <button
          style={{ marginBottom: "8px" }}
          className="button-new"
          onClick={onSave}
        >
          Save and Push
        </button>
      </DialogActions>
    </Dialog>
  );
}

const SelectionComponent = ({
  label,
  value,
  setValue,
  options,
  sx,
  onChange,
  selectedOptions,
}) => {
  return (
    <Box sx={sx}>
      <label className="label-new">{label}</label>
      <Box sx={{ width: "100%" }}>
        <Select
          sx={{
            width: "100%",
            height: "40px",
            borderRadius: "12px",
          }}
          MenuProps={{
            PaperProps: {
              style: {
                minWidth: "auto", // set the width of the options menu to 200px
              },
            },
          }}
          value={value}
          onChange={(e) => {
            onChange && onChange(e.target.value);
            setValue && setValue(e.target.value);
          }}
        >
          {options.map((option) => (
            <MenuItem
              disabled={selectedOptions?.includes(option)}
              sx={{
                fontSize: "12px",
                minWidth: "auto",
              }}
              value={option}
            >
              {option}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Box>
  );
};

export default CreateMacrosModal;

const mealOptions = [
  "On Waking Up",
  "Early Morning",
  "Breakfast",
  "After Breakfast",
  "Mid-morning",
  "Morning Snack",
  "Lunch",
  "Evening Snack",
  "Dinner",
  "After Dinner",
  "Bed Time",
  "Before Workout",
  "During Workout",
  "Between Workouts",
  "After Workout",
  "Non-Training Day",
  "Meal 1",
  "Meal 2",
  "Meal 3",
  "Meal 4",
  "Meal 5",
  "Meal 6",
  "Snack 1",
  "Snack 2",
  "Snack 3",
  "Snack 4",
];
