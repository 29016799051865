import { store } from "@/redux/store";
import axios from "axios";

export const getChannelPrograms = async (channelID) => {
  let res = await axios({
    method: "get",
    url: `${store.getState().auth.apiURL}CF/api/channelProgramTracker/get`,
    params: {
      id: channelID,
    },
  });
  return res?.data?.result || null;
};

export const getChannelProgramsWithProgram = async (channelID) => {
  let res = await axios({
    method: "get",
    url: `${
      store.getState().auth.apiURL
    }CF/api/channelProgramTracker/getWithProgram`,
    params: {
      id: channelID,
    },
  });
  return res?.data?.result || null;
};

export const getAllChannelWithProgramID = async (programID) => {
  let res = await axios({
    method: "get",
    url: `${
      store.getState().auth.apiURL
    }CF/api/channelProgramTracker/getAllChannelWithProgramID`,
    params: {
      programID: programID,
    },
  });
  return res?.data?.result || null;
};

export const deleteChannelProgram = async (channelID) => {
  let res = await axios({
    method: "post",
    url: `${store.getState().auth.apiURL}CF/api/channelProgramTracker/delete`,
    data: {
      id: channelID,
    },
  });
  return res?.data?.result || null;
};
