import React, { useEffect, useState } from "react";

import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import CustomCheckBox from "./CheckBox";

const SpecifyIntensity = ({
  selectedExercise,
  intensityInfo,
  onAdd,
  selectedUnit,
  selectedAthlete,
  intensityText,
}) => {
  const [selectedType, setSelectedType] = useState("");
  const [pace, setPace] = useState("");
  const [maxPace, setMaxPace] = useState("");
  const [paceUnit, setPaceUnit] = useState("min/km");

  const [cadence, setCadence] = useState("");
  const [maxCadence, setMaxCadence] = useState("");

  const [heartRateZone, setHeartRateZone] = useState("");

  const [customHeartRate, setCustomHeartRate] = useState("");
  const [maxCustomHeartRate, setMaxCustomHeartRate] = useState("");

  const [showIntensity, setShowIntensity] = useState(true);
  // const [isChecked, setIsChecked]=useState(false);
  // const {
  //     isThresholdPercentChecked,
  //     setIsThresholdPercentChecked,
  //     isChecked,
  //     setIsChecked,
  //     setValue,
  //     value
  // } = useContext(EnduranceContext);
  let isThresholdPercentChecked = true;
  let isChecked = false;

  useEffect(() => {
    if (selectedUnit) {
      let selectedType = getSelectedType(selectedUnit);
      if (selectedType) {
        setSelectedType(selectedType);
        let max = intensityInfo?.max;
        let min = intensityInfo?.min;
        // set value of selected type
        switch (selectedType) {
          case "thresholdPace":
            setPace(min);
            setMaxPace(max);
            break;
          case "thresholdCustomHeartRate":
            setCustomHeartRate(min);
            setMaxCustomHeartRate(max);
            break;
          case "maxCustomHeartRate":
            setCustomHeartRate(min);
            setMaxCustomHeartRate(max);
            break;
          default:
            break;
        }
      }
    }
  }, [intensityInfo]);

  const isValidToSave = () => {
    // return true;
    switch (selectedType) {
      case "pace":
      case "thresholdPace":
        return pace !== "" && maxPace && paceUnit !== "";
      case "cadence":
        return cadence !== "" && maxCadence !== "";
      case "heartRateZone":
        return heartRateZone !== "";
      case "customHeartRate":
      case "maxCustomHeartRate":
      case "thresholdCustomHeartRate":
      case "restingCustomHeartRate":
        return customHeartRate !== "" && maxCustomHeartRate !== "";
      default:
        return false;
    }
  };

  const showInputBasedOnType = () => {
    switch (selectedType) {
      case "pace":
      case "thresholdPace":
        return (
          <PaceInput
            value={pace}
            setValue={setPace}
            maxValue={maxPace}
            setMaxValue={setMaxPace}
            unit={paceUnit}
            setUnit={setPaceUnit}
            isChecked={isThresholdPercentChecked}
          />
        );

      case "cadence":
        return (
          <CadenceInput
            value={cadence}
            setValue={setCadence}
            maxValue={maxCadence}
            setMaxValue={setMaxCadence}
            isChecked={isThresholdPercentChecked}
          />
        );

      case "heartRateZone":
        return (
          !isChecked && (
            <HeartRateZone value={heartRateZone} setValue={setHeartRateZone} />
          )
        );

      case "customHeartRate":
      case "maxCustomHeartRate":
      case "thresholdCustomHeartRate":
      case "restingCustomHeartRate":
        return (
          <CustomHeartRateInput
            value={customHeartRate}
            setValue={setCustomHeartRate}
            maxValue={maxCustomHeartRate}
            setMaxValue={setMaxCustomHeartRate}
            isChecked={isThresholdPercentChecked}
          />
        );
      default:
        return null;
    }
  };

  const onSave = () => {
    let intensity = {};
    switch (selectedType) {
      case "pace":
      case "thresholdPace":
        intensity = {
          type: selectedType,
          value: pace,
          maxValue: maxPace,
          unit: paceUnit,
        };
        break;
      case "cadence":
        intensity = {
          type: "cadence",
          value: cadence,
          maxValue: maxCadence,
        };
        break;
      case "heartRateZone":
        intensity = {
          type: "heartRateZone",
          value: heartRateZone,
        };
        break;
      case "customHeartRate":
      case "maxCustomHeartRate":
      case "thresholdCustomHeartRate":
      case "restingCustomHeartRate":
        intensity = {
          type: selectedType,
          value: customHeartRate,
          maxValue: maxCustomHeartRate,
        };
        break;
      default:
        break;
    }
    if (isChecked) {
      intensity.thresholdPercent = true;
    }
    if (isValidToSave()) {
      onAdd(intensity);
    }
  };

  // call save when all inputs are valid
  useEffect(() => {
    if (isValidToSave()) {
      onSave();
    }
  }, [
    pace,
    maxPace,
    paceUnit,
    cadence,
    maxCadence,
    heartRateZone,
    customHeartRate,
    maxCustomHeartRate,
  ]);

  const percentageOptions = [
    { label: "% Threshold Pace", value: "thresholdPace" },
    { label: "% Maximum Heart Rate", value: "maxCustomHeartRate" },
    { label: "% Threshold Heart Rate", value: "thresholdCustomHeartRate" },
  ];

  const options = [
    { label: "Pace", value: "pace" },
    { label: "Cadence", value: "cadence" },
    { label: "Heart Rate Zone", value: "heartRateZone" },
    { label: "Custom Heart Rate", value: "customHeartRate" },
  ];

  return (
    <div>
      <div className="flex items-center gap-2">
        {/* <CustomCheckBox value={showIntensity} onClick={() => setShowIntensity(!showIntensity)} /> */}
        <p className="font-DMSans text-font16 text-black-pure font-bold">
          Specify{" "}
          {
            percentageOptions?.find((option) => option.value === selectedType)
              ?.label
          }
        </p>
      </div>
      {showIntensity && (
        <div className="flex flex-col gap-3">
          {/* <div>
                    <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label"
                        >Type</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            row
                            value={value}
                            onChange={(e) => {
                                setValue(e.target.value)
                                setSelectedType("")
                                if (e.target.value === "percent") {
                                    setIsChecked(true)
                                    setIsThresholdPercentChecked(true)
                                } else {
                                    setIsChecked(false)
                                    setIsThresholdPercentChecked(false)
                                }
                                console.log(value, isChecked, isThresholdPercentChecked)
                            }}>

                            <FormControlLabel value="range" control={<Radio color="royalBlue" />} label="Range" />
                            <FormControlLabel value="percent" control={<Radio color="royalBlue" />} label="Percent" />
                        </RadioGroup>
                    </FormControl>
                </div> */}
          <div className="flex  items-center justify-between gap-2">
            <div className="flex flex-col gap-2">
              {/* <Select
                            value={selectedType}
                            onValueChange={(value) => setSelectedType(value)}
                            id="type"

                            className="z-[9999]"

                        >
                            <SelectTrigger
                                className="w-[220px]  h-[48px] text-[14px] focus:border-[#4339F2]  focus:ring-0 focus:ring-offset-0  border-[1.5px] rounded-[12px]">
                                <SelectValue className="text-font12" placeholder="No Target" />
                            </SelectTrigger>
                            <SelectContent className="z-[9999] text-font14">

                                {(isThresholdPercentChecked ? percentageOptions : options).map(
                                    (option, index) => (
                                        <SelectItem value={option.value} key={index}
                                        >
                                            {option.label}
                                        </SelectItem>
                                    )
                                )}
                            </SelectContent>
                        </Select> */}
            </div>

            {/* <div className="flex mt-[16px]">
            <CheckBox
              value={isThresholdPercentChecked}
              onClick={() => {
                setIsThresholdPercentChecked(!isThresholdPercentChecked);
                setIsChecked(!isChecked);
              }}
            />
            <div className="checkbox-label">Threshold %</div>
          </div> */}
          </div>
          {showInputBasedOnType()}
        </div>
      )}

      <p className="font-DMSans text-font14 text-black-pure font-bold ml-1 mt-1">
        {intensityText}
      </p>
    </div>
  );
};
const PaceInput = ({
  value,
  setValue,
  maxValue,
  setMaxValue,
  unit,
  setUnit,
  isChecked,
}) => {
  const [minutes, setMinutes] = React.useState(
    value ? Math.floor(value) : undefined
  );
  const [seconds, setSeconds] = React.useState(
    value ? Math.floor((value % 1) * 60) : undefined
  );

  const [maxMinutes, setMaxMinutes] = React.useState(Math.floor(maxValue ?? 0));
  const [maxSeconds, setMaxSeconds] = React.useState(
    Math.floor(((maxValue ?? 0) % 1) * 60)
  );

  React.useEffect(() => {
    // convert everything to minutes and set the value
    setValue(+minutes + +seconds / 60);
    console.log("pace: ", {
      minutes,
      seconds,
    });
  }, [minutes, seconds]);

  React.useEffect(() => {
    //   convert everything to minutes and set the value
    setMaxValue(+maxMinutes + +maxSeconds / 60);
    console.log("pace: ", {
      maxMinutes,
      maxSeconds,
    });
  }, [maxMinutes, maxSeconds]);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-2">
        <div className="flex items-center gap-2">
          {isChecked ? (
            <Input
              className="h-[48px] focus-visible:ring-0 focus-visible:ring-offset-0 focus-visible:border-[#4339F2] border-[1.5px] rounded-[12px] text-font16"
              placeholder="45"
              value={isNaN(value) ? "" : value}
              onChange={(e) => {
                let val = e.target.value;
                if (val > 200) return;
                setValue(e.target.value);
              }}
            />
          ) : (
            <PaceInputBox
              min={minutes}
              sec={seconds}
              setMin={setMinutes}
              setSec={setSeconds}
            />
          )}

          <span className="font-DMSans">to</span>

          {isChecked ? (
            <Input
              className="h-[48px] focus-visible:ring-0 focus-visible:ring-offset-0 focus-visible:border-[#4339F2] border-[1.5px] rounded-[12px] text-font16"
              placeholder="67"
              value={isNaN(maxValue) || maxValue === 0 ? undefined : maxValue}
              onChange={(e) => {
                let val = e.target.value;
                if (val > 200) return;
                console.log("maxValue: ", e.target.value);
                setMaxValue(e.target.value);
              }}
            />
          ) : (
            <PaceInputBox
              min={maxMinutes}
              sec={maxSeconds}
              setMin={setMaxMinutes}
              setSec={setMaxSeconds}
            />
          )}
        </div>
        {!isChecked && (
          <Select
            id="paceUnit"
            value={unit}
            onValueChange={(value) => setUnit(value)}
          >
            <SelectTrigger className="w-[180px] focus:ring-0 focus:ring-offset-0 focus:border-[#4339F2] border-[1.5px] rounded-[12px]">
              <SelectValue placeholder="" />
            </SelectTrigger>
            <SelectContent className="z-[9999]">
              <SelectItem value="min/mi">min/mi</SelectItem>
              <SelectItem value="min/km">min/km</SelectItem>
            </SelectContent>
          </Select>
        )}
      </div>
    </div>
  );
};

const PaceInputBox = ({ min, sec, setMin, setSec }) => {
  const minRef = React.useRef();
  const secRef = React.useRef();
  const [focus, setFocus] = useState(false);
  return (
    <div
      className={`flex items-center border hideIncrement p-1 rounded-[12px] group ${
        focus ? "border-[#4339F2]" : "border-gray-300"
      }`}
    >
      <Input
        className="h-[48px] focus-visible:ring-transparent text-font14 border-none group"
        type="number"
        ref={minRef}
        placeholder="mm"
        value={min ? min : undefined}
        onFocus={(e) => {
          e.target.select();
          setFocus(true);
        }}
        onBlur={() => setFocus(false)}
        onChange={(e) => {
          let val = e.target.value;
          if (val > 59) {
            val = 59;
          }
          setMin(val);
          if (val.length.toString() >= 2) {
            secRef.current.focus();
          }
        }}
      />
      <span className="font-DMSans">:</span>
      <Input
        className="h-[48px] focus-visible:ring-transparent text-font14 border-none group"
        type="number"
        ref={secRef}
        placeholder="ss"
        value={sec ? sec : undefined}
        onFocus={(e) => {
          e.target.select();
          setFocus(true);
        }}
        onBlur={() => setFocus(false)}
        onChange={(e) => {
          let val = e.target.value;
          if (val > 59) {
            val = 59;
          }
          setSec(val);
        }}
      />
    </div>
  );
};

const CadenceInput = ({
  value,
  setValue,
  maxValue,
  setMaxValue,
  isChecked,
}) => {
  // input cadence range
  return (
    <div className="flex flex-col gap-2">
      {/* <Label htmlFor="cadence" className="font-DMSans">
        Cadence
      </Label> */}
      <div className="flex gap-2 items-center justify-center ">
        <Input
          className="h-[48px] focus-visible:ring-0 focus-visible:ring-offset-0 focus-visible:border-[#4339F2] border-[1.5px] rounded-[12px] text-font16"
          type="number"
          id="cadence"
          placeholder="70"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          max="200"
        />
        <span className="font-DMSans">to</span>
        <Input
          className=" h-[48px] focus-visible:ring-0 focus-visible:ring-offset-0 focus-visible:border-[#4339F2] border-[1.5px] rounded-[12px] text-font16"
          type="number"
          id="maxCadence"
          value={maxValue}
          placeholder="90"
          onChange={(e) => setMaxValue(e.target.value)}
        />
        {!isChecked && <span className="font-DMSans">spm</span>}
      </div>
      {/* unit = spm */}
    </div>
  );
};

const heartRateZoneOptions = [
  "Heart Rate Zone 1 (93-111 bpm)",
  "Heart Rate Zone 2 (111-130 bpm)",
  "Heart Rate Zone 3 (130-148 bpm)",
  "Heart Rate Zone 4 (148-167 bpm)",
  "Heart Rate Zone 5 (167-185 bpm)",
];
const HeartRateZone = ({ value, setValue }) => {
  return (
    <div className="flex flex-col gap-2">
      {/* <Label htmlFor="heartRateZone" className="font-DMSans text-font14">
        Heart Rate Target
      </Label> */}
      <Select
        id="heartRateZone"
        value={value}
        onValueChange={(value) => setValue(value)}
      >
        <SelectTrigger className=" focus:ring-0 focus:ring-offset-0 border-[1.5px] rounded-[12px]">
          <SelectValue className="text-font14" placeholder="Select Zone" />
        </SelectTrigger>
        <SelectContent className="z-[9999]">
          {heartRateZoneOptions.map((option, index) => (
            <SelectItem className="text-font14" value={index + 1}>
              {option}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
};

const CustomHeartRateInput = ({
  value,
  setValue,
  maxValue,
  setMaxValue,
  isChecked,
}) => {
  // custom heart rate range in bpm
  return (
    <div className="flex flex-col gap-2">
      {/* <Label htmlFor="customHeartRate" className="font-DMSans text-font14">
        Target
      </Label> */}
      <div className="flex gap-2 items-center justify-center ">
        <Input
          className="h-[48px] focus-visible:ring-0 focus-visible:ring-offset-0 focus-visible:border-[#4339F2] border-[1.5px] rounded-[12px] text-font14"
          type="number"
          id="customHeartRate"
          max="999"
          value={value}
          placeholder="65"
          onChange={(e) => {
            let value = e.target.value;
            // if value if greater than 3 digits, then only take first 3 digits
            if (value.length > 3) {
              return;
            }
            if (isChecked && value > 200) {
              return;
            }
            setValue(value);
          }}
        />
        <span className="font-DMSans">to</span>
        <Input
          className="h-[48px] focus-visible:ring-0 focus-visible:ring-offset-0 focus-visible:border-[#4339F2] border-[1.5px] rounded-[12px]text-font14"
          type="number"
          id="maxCustomHeartRate"
          max="999"
          placeholder="85"
          value={maxValue}
          onChange={(e) => {
            let value = e.target.value;
            // if value if greater than 3 digits, then only take first 3 digits
            if (value.length > 3) {
              return;
            }
            if (isChecked && value > 200) {
              return;
            }
            setMaxValue(e.target.value);
          }}
        />
        {!isChecked && <span className="font-DMSans text-font14">bpm</span>}
      </div>
    </div>
  );
};

export default SpecifyIntensity;

const getSelectedType = (exerciseSelectedType) => {
  switch (exerciseSelectedType) {
    case "precentThresholdPaceRangeInMinPerKm":
      return "thresholdPace";

    case "precentThresholdHeartRateRangeInBpm":
      return "thresholdCustomHeartRate";

    case "precentMaximumHeartRateRangeInBpm":
      return "maxCustomHeartRate";
    case "percentFTPRangeInW":
      return "";
  }
};
