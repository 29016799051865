import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

import { CloseRounded } from "@mui/icons-material";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import React, { useContext, useState } from "react";
import Input from "../../InputComponents/Input";
import { CustomAlertContext } from "../../../App";
import { SelectMeasurementUnit } from "./util";

const EditAddedFood = ({
  isOpen,
  setIsOpen,
  item,
  setItem,
  onClose,
  isAdding,
}) => {
  const [foodName, setFoodName] = useState(item?.name || "");
  const [protein, setProtein] = useState(item?.protein || "");
  const [carbs, setCarbs] = useState(item?.carbs || "");
  const [fat, setFat] = useState(item?.fat || "");
  const [calories, setCalories] = useState(item?.calories || "");
  const [weightInGrams, setWeightInGrams] = useState(item?.size || "");
  const [measurement, setMeasurement] = useState(item?.measurement_count || 1);
  const [measurementUnit, setMeasurementUnit] = useState(
    item?.measurement_description || "serving"
  );
  const { displayAlert } = useContext(CustomAlertContext);

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        onClose();
        setIsOpen(false);
      }}
      maxWidth={"sm"}
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "20px",
        },
      }}
    >
      <div className="add-program-modal-head">
        <p className="add-program-modal-head-title">
          {isAdding ? "Add" : "Edit"} Food / Recipe
        </p>
        <div
          onClick={() => {
            onClose();
            setIsOpen(false);
          }}
          className="cursor-pointer"
        >
          <CloseRounded
            sx={{
              fontSize: "24px",
              color: "#000",
              cursor: "pointer",
            }}
          />
        </div>
      </div>
      <DialogContent className="flex flex-col gap-3">
        <Input
          label="Food Name*"
          value={foodName}
          onChange={(e) => setFoodName(e.target.value)}
          placeholder="Enter Food Name"
        />

        <div className="flex items-center justify-between gap-2">
          <Input
            label="Calories*"
            value={calories}
            onChange={(e) => setCalories(e.target.value)}
            placeholder="200"
          />
          <Input
            label="Weight in Grams"
            value={weightInGrams}
            onChange={(e) => setWeightInGrams(e.target.value)}
            placeholder="100"
          />

          <CustomInput
            label={`Measurement (${measurementUnit})*`}
            value={measurement}
            onChange={(e) => setMeasurement(e.target.value)}
            placeholder="1"
            measurementUnit={measurementUnit}
            setMeasurementUnit={setMeasurementUnit}
          />
        </div>

        <div className="flex items-center justify-between gap-2">
          <Input
            label="Protein"
            value={protein}
            onChange={(e) => setProtein(e.target.value)}
            placeholder="Enter Protein"
          />
          <Input
            label="Carbs"
            value={carbs}
            onChange={(e) => setCarbs(e.target.value)}
            placeholder="Enter Carbs"
          />
          <Input
            label="Fat"
            value={fat}
            onChange={(e) => setFat(e.target.value)}
            placeholder="Enter Fat"
          />
        </div>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", mb: 1 }}>
        <button
          className="button-new"
          onClick={() => {
            if (
              foodName === "" ||
              calories === "" ||
              measurement === ""
            ) {
              displayAlert({
                type: "error",
                message: "Please fill all the required fields",
              });
              return;
            }
            let newID = "custom_" + uuidv4();
            let mUnit = measurementUnit;
            if (
              typeof measurementUnit === "string" &&
              measurementUnit.toLowerCase().includes("serving")
            ) {
              mUnit = "serving";
            }
            setItem({
              ...item,
              ...(item?.recipe_id ? { recipe_id: newID } : { food_id: newID }),
              name: foodName,
              protein,
              carbs,
              fat,
              calories,
              size: weightInGrams,
              measurement_count: measurement,
              measurement_description: measurementUnit,
            });
            setIsOpen(false);
          }}
        >
          {isAdding ? "Add" : "Update"}
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default EditAddedFood;

function CustomInput({
  label,
  type = "text",
  value = "",
  onChange,
  id,
  containerStyle,
  measurementUnit,
  setMeasurementUnit,

  ...props
}) {
  const [inputValue, setInputValue] = useState(value);
  const inputId = id || uuidv4();

  function handleInputChange(event) {
    onChange && onChange(event);
    setInputValue(event.target.value);
  }

  return (
    <div
      style={{
        ...containerStyle,
      }}
      // className="input-container"
      className="flex w-full flex-col"
    >
      <label
        htmlFor={inputId}
        style={{
          color: "#424242",
          fontFamily: "DM Sans",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "20px",
          textAlign: "left",
          paddingLeft: "12px",
          marginBottom: "8px",
        }}
      >
        {label}
      </label>
      <CustomDiv className="flex items-center justify-between gap-2">
        <input
          type={type}
          id={inputId}
          value={inputValue}
          onChange={handleInputChange}
          style={{
            outline: "none",
            padding: "0px 16px",
          }}
        />
        <SelectMeasurementUnit
          value={measurementUnit}
          isCustom={true}
          onSuccess={(value) => {
            setMeasurementUnit(value);
          }}
        />
      </CustomDiv>
    </div>
  );
}

const CustomDiv = styled.div`
  width: 100%;
  display: flex;
  height: 48px;
  align-items: center;
  gap: 2px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid #ccc;
  background: #fff;
  color: #000;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  outline: none;
  transition: border-color 0.2s ease-in-out;
  mrgin-top: 8px;

  &:focus {
    color: #000;
    font-family: "DM Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    padding-left: 12px;
    border: 1.5px solid #4339f2;
    box-shadow: 0px 0px 0px 3px rgba(67, 57, 242, 0.25);
    background-color: #fff;
  }
`;
