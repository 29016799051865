import { DialogHeader } from "@/components/ui/dialog";
 
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
 
import React, { useContext, useEffect, useState } from "react";
import { CloseRounded, RoundedCorner } from "@mui/icons-material";
 
import CustomSelect from "@/components/WorkoutComponent/Endurance/util/CustomSelect";
import { workoutContext } from "./WorkoutContex";

function SelectFolderDialogInWorkout({
  open,
  setOpen,
  selectedProgram,
  folders,
  addWorkoutToFolder,
}) {
  const {
    setFolders,
    moveItemToFolder,
    setFolderSelected,
    setOpenFolderId,
    setOriginalData,
    setTempFolderData,
    setTempData,
    setWorkoutSelected,
    originalData,
  } = useContext(workoutContext);
  const [selected, setSelected] = useState("");

  const handleAddingToFolder = async (Id) => {
    let data = {
      folderID: Id,
      workoutIDs: [selectedProgram?.id],
    };

    let res = await moveItemToFolder(data);
    if (res) {
      setFolders((prev) => {
        const data = [...prev];
        if (data?.length > 0) {
          const newData = data.map((folder) => {
            if (folder?.id === Id) {
              if (folder?.workouts) {
                const workout = { ...selectedProgram };
                if (workout?.workoutFolderId) {
                  workout.workoutFolderId = Id;
                } else {
                  workout["workoutFolderId"] = Id;
                }
                setWorkoutSelected(workout);
                folder.workouts?.push(workout);
              } else {
                let workoutArr = [];
                const workout = { ...selectedProgram };
                if (workout?.workoutFolderId) {
                  workout.workoutFolderId = Id;
                } else {
                  workout["workoutFolderId"] = Id;
                }
                setWorkoutSelected(workout);
                workoutArr.push(workout);
                folder["workouts"] = workoutArr;
              }

              setFolderSelected(folder);
              return folder;
            }
            return folder;
          });
          setTempFolderData(newData);
          return newData;
        }
      });

      setOriginalData((prev) => {
        const tempdata = [...prev];

        let newdata = tempdata?.filter(
          (workout) => workout?.id !== selectedProgram?.id
        );
        setTempData(newdata);

        return newdata;
      });
      setOpenFolderId(Id);
    }
  };

  const handleWorkoutAddingToFolder = async(selectedWorkout) => {
     let data = {
       folderID: selectedProgram?.id,
       workoutIDs: [selectedWorkout?.id],
     };
     let res = await moveItemToFolder(data);
  if(res){
     setFolders((prev) => {
       const data = [...prev];
       if (data?.length > 0) {
         const newData = data.map((folder) => {
           if (folder?.id === selectedProgram?.id) {
             if (folder?.workouts) {
               const workout = { ...selectedWorkout };
               if (workout?.workoutFolderId) {
                 workout.workoutFolderId = selectedProgram?.id;
               } else {
                 workout["workoutFolderId"] = selectedProgram?.id;
               }
               setWorkoutSelected(workout);
               folder.workouts?.push(workout);
             } else {
               let workoutArr = [];
               const workout = { ...selectedWorkout };
               if (workout?.workoutFolderId) {
                 workout.workoutFolderId = selectedProgram?.id;
               } else {
                 workout["workoutFolderId"] = selectedProgram?.id;
               }
               setWorkoutSelected(workout);
               workoutArr.push(workout);
               folder["workouts"] = workoutArr;
             }

             setFolderSelected(folder);
             return folder;
           }
           return folder;
         });
         setTempFolderData(newData);
         return newData;
       }
     });

     setOriginalData((prev) => {
       const tempdata = [...prev];

       let newdata = tempdata?.filter(
         (workout) => workout?.id !== selectedWorkout?.id
       );
       setTempData(newdata);

       return newdata;
     });
     setOpenFolderId(selectedProgram?.id);
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "24px",
        },
      }}
    >
      <DialogHeader className="border-b-2 border-gray-200 flex flex-row justify-between p-1 items-center">
        {!addWorkoutToFolder && (
          <DialogTitle className="text-font18">Select Any Folder</DialogTitle>
        )}
        {addWorkoutToFolder && (
          <DialogTitle className="text-font18">Select Any Workout</DialogTitle>
        )}
        <CloseRounded className="mr-2" onClick={() => setOpen(false)} />
      </DialogHeader>
      <DialogContent className=" w-[100%] font-DMSans h-[400px] overflow-y-scroll">
        <div className="flex flex-col gap-1 items-center justify-center">
          {addWorkoutToFolder ? (
            <label className="text-[14px] font-[500] self-start  pl-[12px] text-[#424242]">
              Select Any Workout
            </label>
          ) : (
            <label className="text-[14px] font-[500] self-start  pl-[12px] text-[#424242]">
              Select Any Folder
            </label>
          )}
          {addWorkoutToFolder ? (
            <CustomSelect
              isFolder={true}
              triggerClassName={
                "w-full text-font14 focus:ring-transparent focus:border-indigo-500 border-[1.5px]  "
              }
              onValueChange={(workout) => {
                setSelected(workout?.id);
                handleWorkoutAddingToFolder(workout);
              }}
              options={originalData}
              placeholder={"----"}
            />
          ) : (
            <CustomSelect
              isFolder={true}
              triggerClassName={
                "w-full text-font14 focus:ring-transparent focus:border-indigo-500 border-[1.5px]  "
              }
              onValueChange={(folder) => {
                setSelected(folder?.id);
                handleAddingToFolder(folder?.id);
              }}
              options={folders}
              placeholder={"----"}
            />
          )}
        </div>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
}

export default SelectFolderDialogInWorkout;
