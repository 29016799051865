import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { getPropertyToShow, getTimePropertyToShow } from "./utils";
import AddIntensity from "./AddIntensityDialog";

const EnduranceExercise = ({
  selected,
  measurement,
  onSelect,
  selectedExerciseInfo,
  setSelectedExerciseInfo,
  isDragEnabled,
  roundIndex,
  exerciseIndex,
  setExerciseIndex,
  exerciseId,
  removeSubstep,
  updateMovementName,
  addIntensityToMeasurement,
  workoutKind,
}) => {
  const menuItems = [
    ...(workoutKind === "run"
      ? ["Run"]
      : workoutKind === "bike"
      ? ["Bike"]
      : ["Swim"]),

    "Rest",
    "Recover",
    "Cooldown",
    "Warm Up",
  ];
  // const [menuItems, setMenuItems] = useState(mItems);
  const [selectedMenuItem, setSelectedMenuItem] = useState(
    measurement?.movementName
  );

  const getRecordFromUserProperties = () => {
    if (
      measurement?.recordFromUser &&
      typeof measurement?.recordFromUser === "object"
    ) {
      let res = Object.entries(measurement?.recordFromUser).map(
        ([key, value]) => {
          if (value) {
            return `${key}`;
          }
        }
      );
      return res.filter((item) => item).join(" | ");
    }
  };

  const formatTime = (minutes) => {
    let mins = Math.floor(minutes);
    let secs = minutes % 1 ? Math.round((minutes % 1) * 60) : 0;
    return `${mins}:${secs < 10 ? "0" + secs : secs}`;
  };

  const getIntensityText = () => {
    let prevIntensity = measurement?.intensity;
    if (!prevIntensity) return null;
    console.log("enduranceExercise", prevIntensity?.type);
    if (prevIntensity?.type) {
      let text = nameForKey[prevIntensity?.type] + `: `;
      switch (prevIntensity?.type) {
        case "pace":
          return `${text} ${formatTime(prevIntensity?.value)} to ${formatTime(
            prevIntensity?.maxValue
          )} ${prevIntensity?.unit}`;
        case "cadence":
          return `${text} ${prevIntensity?.value} to ${prevIntensity?.maxValue} spm`;
        case "heartRateZone":
          return `${text} ${prevIntensity?.value}`;
        case "customHeartRate":
          return `${text} ${prevIntensity?.value} to ${prevIntensity?.maxValue} bpm`;
        default:
          return null;
      }
    }
    return null;
  };

  const getPrimaryTargetText = () => {
    let prevPrimaryTarget = measurement?.primaryTarget;
    if (!prevPrimaryTarget) return null;

    if (prevPrimaryTarget?.type) {
      let text =
        `Primary Target: ` + nameForKey[prevPrimaryTarget?.type] + `: `;
      switch (prevPrimaryTarget?.type) {
        case "speed":
          return `${text} ${prevPrimaryTarget?.value} to ${prevPrimaryTarget?.maxValue} mph`;
        case "cadence":
          return `${text} ${prevPrimaryTarget?.value} to ${prevPrimaryTarget?.maxValue} rpm`;
        case "heartRateZone":
          return `${text} ${prevPrimaryTarget?.value}`;
        case "customHeartRate":
          return `${text} ${prevPrimaryTarget?.value} to ${prevPrimaryTarget?.maxValue} bpm`;
        case "powerZone":
          return `${text} ${prevPrimaryTarget?.value} W`;
        case "customPower":
          return `${text} ${prevPrimaryTarget?.value} to ${prevPrimaryTarget?.maxValue} W`;
        case "thresholdSpeed":
          return `${text} ${prevPrimaryTarget?.value} to ${prevPrimaryTarget?.maxValue} %`;
        case "thresholdCustomHeartRate":
          return `${text} ${prevPrimaryTarget?.value} to ${prevPrimaryTarget?.maxValue} %`;
        case "thresholdCustomPower":
          return `${text} ${prevPrimaryTarget?.value} to ${prevPrimaryTarget?.maxValue} %`;
        default:
          return null;
      }
    }
    return null;
  };

  const getSecondaryTargetText = () => {
    let prevSecondaryTarget = measurement?.secondaryTarget;
    if (!prevSecondaryTarget) return null;

    if (prevSecondaryTarget?.type) {
      let text =
        `Secondary Target: ` + nameForKey[prevSecondaryTarget?.type] + `: `;
      switch (prevSecondaryTarget?.type) {
        case "speed":
          return `${text} ${prevSecondaryTarget?.value} to ${prevSecondaryTarget?.maxValue} mph`;
        case "cadence":
          return `${text} ${prevSecondaryTarget?.value} to ${prevSecondaryTarget?.maxValue} rpm`;
        case "heartRateZone":
          return `${text} ${prevSecondaryTarget?.value}`;
        case "customHeartRate":
          return `${text} ${prevSecondaryTarget?.value} to ${prevSecondaryTarget?.maxValue} bpm`;
        case "powerZone":
          return `${text} ${prevSecondaryTarget?.value} W`;
        case "customPower":
          return `${text} ${prevSecondaryTarget?.value} to ${prevSecondaryTarget?.maxValue} W`;
        case "thresholdSpeed":
          return `${text} ${prevSecondaryTarget?.value} to ${prevSecondaryTarget?.maxValue} %`;
        case "thresholdCustomHeartRate":
          return `${text} ${prevSecondaryTarget?.value} to ${prevSecondaryTarget?.maxValue} %`;
        case "thresholdCustomPower":
          return `${text} ${prevSecondaryTarget?.value} to ${prevSecondaryTarget?.maxValue} %`;
        default:
          return null;
      }
    }
    return null;
  };
  const enduranceImages = {
    "Warm Up": "https://i.ibb.co/2P8JXyX/3629142.jpg",
    Run: "https://i.ibb.co/54hGDWD/Firefly-Running-Person-93528.jpg",
    Cooldown:
      "https://cdn.vectorstock.com/i/1000v/88/28/man-doing-quadriceps-stretch-cool-down-exercise-vector-49078828.jpg",
    Rest: "https://img.freepik.com/premium-vector/man-drinking-water-stay-hydrated-flat-simple-vector-illustrations-white-background_1062857-291.jpg",
    Recover:
      "https://assets.clevelandclinic.org/transform/97c96e45-79c4-4243-805e-351990c08ef2/postWorkoutRecovery-1146620841-770x553-1_jpg",
  };
  return (
    <div
      style={{
        cursor: "pointer",
        overflow: "visible",
      }}
      className={selected ? "exercise-items-selected " : "exercise-items"}
      onClick={() => onSelect()}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          alignSelf: "stretch",
          justifyContent: "space-between",
          gap: "8px",
          position: "relative",
          padding: "8px",
        }}
      >
        {isDragEnabled && (
          <DragIndicatorIcon
            sx={{
              fontSize: "24px",
              color: "##828282",
            }}
          />
        )}

        {/* dropdown */}
        <div>
          <Stack
            sx={{
              padding: "24px 16px",
              display: "flex",
              flexDirection: "row",
            }}
            gap={"16px"}
          >
            <img
              src={enduranceImages[measurement?.movementName]}
              style={{ width: 48, height: 48, borderRadius: "8px" }}
            />
            <Select
              value={selectedMenuItem}
              onValueChange={(value) => {
                setSelectedMenuItem(value);
                updateMovementName(roundIndex, exerciseIndex, value);
              }}
            >
              <SelectTrigger className="w-[180px] focus:ring-violet-500">
                <SelectValue placeholder="Run" />
              </SelectTrigger>
              <SelectContent className="z-[99999]">
                {menuItems.map((item) => (
                  <SelectItem key={item} value={item}>
                    {item}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </Stack>
          <div className="days">{getRecordFromUserProperties()}</div>
        </div>
        <div className="w-fit flex items-center gap-1 ml-auto">
          <RenderOneProperty
            unit={measurement?.distanceUnits}
            displayUnits={distanceDisplayUnits}
            value={measurement?.distance}
            maxValue={measurement?.distanceMax}
            property="distance"
          />

          <RenderOneProperty
            unit={"cal"}
            value={measurement?.calorie}
            maxValue={measurement?.calorieMax}
            property="calorie"
          />

          <RenderOneProperty
            unit={"bpm"}
            value={measurement?.heartRate}
            maxValue={measurement?.heartRateMax}
            property="heartRate"
            type={measurement?.heartRateType}
          />

          {measurement?.power?.[1] && (
            <RenderOneProperty
              unit={"W"}
              value={measurement?.power}
              property="power"
              type={measurement?.powerType}
            />
          )}

          <RenderOneProperty
            unit={measurement?.paceUnit}
            value={measurement?.pace}
            maxValue={measurement?.paceMax}
            property="pace"
          />

          <RenderOneProperty
            unit=" "
            value={measurement?.duration}
            property="duration"
          />

          {measurement?.strokeType && (
            <RenderOneProperty
              property={"strokeType"}
              value={{ 1: strokeName[measurement?.strokeType] }}
              unit={" "}
            />
          )}

          {measurement?.equipment && (
            <RenderOneProperty
              property={"equipment"}
              value={{ 1: equipmentName[measurement?.equipment] }}
              unit={" "}
            />
          )}

          {selected && <div className="active-bar" />}
          {!isDragEnabled && (
            <RemoveCircleOutlineRoundedIcon
              style={{
                color: "#ff3a29",
                cursor: "pointer",
                fontSize: "20px",
              }}
              onClick={(e) => {
                e.stopPropagation();
                removeSubstep(roundIndex, exerciseIndex);
              }}
            />
          )}
        </div>
      </div>
      {getIntensityText() && (
        <div className="flex w-full pl-6 pr-4 pb-2">{getIntensityText()}</div>
      )}
      {getPrimaryTargetText() && (
        <div className="flex flex-col w-full pl-6 pr-4  pb-2 text-font12">
          {getPrimaryTargetText()}
          {getSecondaryTargetText() && (
            <div className="flex w-full text-font12">
              {getSecondaryTargetText()}
            </div>
          )}
        </div>
      )}

      {measurement?.movementInstr && (
        <div
          style={{
            padding: "8px",
            color: "#616161",
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: "DM Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "normal",
          }}
        >
          Instructions:{" "}
          <span
            style={{
              fontWeight: 400,
              fontFamily: "DM Sans",
              color: "#242424",
            }}
          >
            {measurement?.movementInstr}
          </span>
        </div>
      )}
    </div>
  );
};

export default EnduranceExercise;

const RenderOneProperty = ({
  unit,
  value,
  property,
  displayUnits,
  maxValue,
  type,
}) => {
  if (!value) return <></>;

  return (
    <div className="property">
      <div className="reps2">
        {type && <span>{type}&nbsp;</span>}
        <span>
          {property == "duration"
            ? getTimePropertyToShow(value)
            : getPropertyToShow(value, maxValue)}
        </span>
        <span className="span">{` `}</span>
        <span className="reps3">
          {unit ? (displayUnits ? displayUnits[unit] : unit) : property}
        </span>
      </div>
    </div>
  );
};

let distanceDisplayUnits = {
  Meters: "m",
  Kilometers: "KM",
  Feet: "Ft",
  Yards: "Yds",
  Miles: "mi",
  Inches: "Inch",
};

const nameForKey = {
  pace: "Pace",
  cadence: "Cadence",
  heartRateZone: "Heart Rate Zone",
  customHeartRate: "Custom Heart Rate",
  speed: "Speed",
  powerZone: "Power Zone",
  customPower: "Custom Power",
};

const strokeName = {
  BUTTERFLY: "Fly",
  BACKSTROKE: "Back stroke",
  BREASTSTROKE: "Breast stroke",
  FREESTYLE: "Free",
  IM: "Individual Medley",
  MIXED: "Mixed",
};

const equipmentName = {
  SWIM_FINS: "Fins",
  SWIM_KICKBOARD: "Kickboard",
  SWIM_PADDLES: "Paddles",
  SWIM_PULL_BUOY: "Pull Buoy",
  SWIM_SNORKEL: "Snorkel",
  NONE: "None",
};
