import axios from "axios";
import mixpanel from "mixpanel-browser";
import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CustomAlertContext } from "../../App";
import SpinnerComponent from "../../components/layout/spinner";
import { Eye, EyeOff } from "lucide-react";

const ResetPassword = ({}) => {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const history = useHistory();
  const isBrowser = typeof window !== "undefined";
  const urlSearchParams = new URLSearchParams(
    isBrowser && window.location.search
  );
  const params = Object.fromEntries(urlSearchParams.entries());

  const buttonStyle = {
    color: "#ffffff",
    borderRadius: "10px",
    backgroundColor: "#FF9800",
    padding: "7px 30px",
    width: "100%",
    padding: 10,
  };

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);
  const { displayAlert } = useContext(CustomAlertContext);

  // States to manage password visibility
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const saveNewPassowrd = async () => {
    let trainerDetails = JSON.parse(localStorage.getItem("trainerCredentials"));
    mixpanel.track("Trainer_ResetPassword");
    if (newPassword !== confirmPassword) {
      displayAlert("New password does not match with Confirm password");
      return false;
    }

    setLoader(true);

    await axios({
      method: "post",
      url: `${APIURL}api/resettrainerpassword`,
      data: {
        password: newPassword,
        resetToken: params.key,
        trainerUUID: params.id,
      },
    })
      .then(async (res) => {
        await autoLogin(newPassword, res.data.email);
        setLoader(false);
      })
      .catch((err) => {
        displayAlert("Invalid email or old password");
        setLoader(false);
      });
  };

  const autoLogin = (pass, email) => {
    axios({
      method: "post",
      url: `${APIURL}api/authtrainer`,
      data: {
        trainerUUID: pass,
        trainerEmail: email,
      },
    })
      .then(async (res) => {
        if (res.data.authorize) {
          let mainPassword = pass;
          let password = res.data.trainerUUID;
          let name = res?.data?.name;

          mixpanel.identify(res.data.trainerUUID);
          mixpanel.people.set_once({
            Name: res?.data?.name,
            Email: res.data?.trainerEmail,
          });

          let trainerDetails = JSON.stringify({
            email,
            password,
            mainPassword,
            name,
          });
          await localStorage.setItem("isLoggedIn", true); //route the user to the page
          localStorage.setItem("trainerCredentials", trainerDetails);
          history.push("/home");
        } else {
          displayAlert("Invalid credentials. Please reload and try again");
        }
      })
      .catch((err) => {
        displayAlert("Network Error. Please try again");
      });
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center min-h-screen">
        <div
          className="flex col"
          style={{
            justifyContent: "center",
            width: "100%",
            height: "100vh",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h1 className="mb-5" style={{ fontSize: 25 }}>
            {" "}
            Change Password{" "}
          </h1>
          <div
            style={{
              // width: "500px",
              background: "#F7F7F7",
              justifyContent: "center",
              padding: "1.5em",
              borderRadius: 5,
            }}
            className="w-[100%] md:w-[500px]"
          >
            <form>
              <div className="row mb-5">
                <div className="col">
                  <label>New Password</label>
                  <div className="relative">
                    {" "}
                    {/* Add a container for positioning */}
                    <input
                      type={showNewPassword ? "text" : "password"}
                      className="form-control"
                      style={{
                        width: "100%",
                        height: "50px",
                        border: "1px solid #ccc",
                        borderRadius: 8,
                        padding: "5px 10px",
                      }}
                      minLength={8}
                      maxLength={25}
                      required
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <button
                      type="button"
                      className="absolute top-1/2 right-3 transform -translate-y-1/2"
                      onClick={() => setShowNewPassword(!showNewPassword)}
                    >
                      {showNewPassword ? (
                        <Eye size={20} />
                      ) : (
                        <EyeOff size={20} />
                      )}
                    </button>
                  </div>
                </div>
              </div>

              <div className="row mb-5">
                <div className="col">
                  <label>Confirm Password</label>
                  <div className="relative">
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      className="form-control"
                      style={{
                        width: "100%",
                        height: "50px",
                        border: "1px solid #ccc",
                        borderRadius: 8,
                        padding: "5px 10px",
                      }}
                      minLength={8}
                      maxLength={25}
                      required
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <button
                      type="button"
                      className="absolute top-1/2 right-3 transform -translate-y-1/2"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    >
                      {showConfirmPassword ? (
                        <Eye size={20} />
                      ) : (
                        <EyeOff size={20} />
                      )}
                    </button>
                  </div>
                </div>
              </div>

              <div className="row mb-5">
                <div className="col">
                  {!loader && (
                    <button
                      className="button-new"
                      onClick={() => saveNewPassowrd()}
                    >
                      Save
                    </button>
                  )}

                  {error && (
                    <p style={{ textAlign: "center", marginTop: 10 }}>
                      {error}
                    </p>
                  )}

                  {loader && <SpinnerComponent />}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
