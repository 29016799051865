import axios from "axios";
import React, { useContext, useState } from "react";
import APIURL from "../../APIDetails";
import { CustomAlertContext } from "../../App";
import SpinnerComponent from "../../components/layout/spinner";
import ResetPasswordConfirmation from "./ResetPasswordConfirmation";
import "./style.css";

const UserResetPassword = ({}) => {
  const isBrowser = typeof window !== "undefined";
  const urlSearchParams = new URLSearchParams(
    isBrowser && window.location.search
  );
  const params = Object.fromEntries(urlSearchParams.entries());

  console.log({ params });

  const buttonStyle = {
    color: "#ffffff",
    borderRadius: "10px",
    backgroundColor: "#FF9800",
    padding: "7px 30px",
    width: "100%",
    padding: 10,
  };

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const { displayAlert } = useContext(CustomAlertContext);

  const saveNewPassowrd = async () => {
    if (newPassword !== confirmPassword) {
      displayAlert({
        type: "error",
        message: "New password does not match with Confirm password",
      });
      return false;
    }

    setLoader(true);

    await axios({
      method: "post",
      url: `${APIURL}api/resetuserpassword`,
      data: {
        password: newPassword,
        resetToken: params.key,
        userUUID: params.id,
      },
    })
      .then(async (res) => {
        setSuccess(true);
        setLoader(false);
        setNewPassword("");
        setConfirmPassword("");
      })
      .catch((err) => {
        displayAlert({
          type: "error",
          message: "Invalid email or old password",
        });
        setLoader(false);
      });
  };

  return (
    <>
      {success ? (
        <ResetPasswordConfirmation />
      ) : (
        <div class="flex">
          <div
            class="flex col"
            style={{
              justifyContent: "center",
              width: "100%",
              height: "100vh",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <h1 class="mb-5" style={{ fontSize: 25 }}>
              {" "}
              Change Password{" "}
            </h1>
            <div className="password-container">
              <form>
                <div class="row mb-5">
                  <div class="col">
                    <label>New Password</label>
                    <input
                      type="password"
                      class="form-control"
                      style={{
                        width: "100%",
                        height: "50px",
                        border: "1px solid #ccc",
                        borderRadius: 8,
                        padding: "5px",
                      }}
                      minLength={8}
                      maxLength={25}
                      required
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  </div>
                </div>

                <div class="row mb-5">
                  <div class="col">
                    <label>Confirm Password</label>
                    <input
                      type="password"
                      class="form-control"
                      style={{
                        width: "100%",
                        height: "50px",
                        border: "1px solid #ccc",
                        borderRadius: 8,
                        padding: "5px",
                      }}
                      minLength={8}
                      maxLength={25}
                      required
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </div>
                </div>

                <div class="row mb-5">
                  <div class="flex items-center w-full justify-center">
                    {!loader && (
                      <button
                        className="button-new"
                        onClick={() => saveNewPassowrd()}
                      >
                        Save
                      </button>
                    )}

                    {error && (
                      <p style={{ textAlign: "center", marginTop: 10 }}>
                        {error}
                      </p>
                    )}

                    {success && (
                      <p
                        style={{
                          textAlign: "center",
                          marginTop: 10,
                          fontSize: 12,
                          color: "green",
                        }}
                      >
                        You can use the new password to login in your mobile
                        app.
                      </p>
                    )}

                    {loader && <SpinnerComponent />}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserResetPassword;
