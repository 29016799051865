import React from "react";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import MoreVertRounded from "@mui/icons-material/MoreVertRounded";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useSelector } from "react-redux";

const SPUR_FIT = 0;
const TRAINER = 1;

export const ExerciseListItem = ({
  item,
  index,
  isDeleteEnabled,
  libraryType,
  selectedForDeletion,
  setSelectedForDeletion,
  openDialog,
  setOpenDialog,
  selectedCard,
  setSelectedCard,
  setVideoInfo,
  setShowUpdateExerciseModal,
  handleCloseMore,
  selectedItem,
  setSelectedItem,
  deleteSingleExercise,
  placeholderImages,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMoreClick = (event, item) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedCard(item);
    console.log("item --> ", item);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const adminID = useSelector((state) => state.auth?.data?.adminID);
  return (
    <div
      className="exercise-list-container-item"
      onClick={
        isDeleteEnabled && libraryType === TRAINER
          ? (e) => {
            e.stopPropagation();
            console.log("item --> ", item);
            let id = item.id;
            selectedForDeletion.includes(id)
              ? setSelectedForDeletion(
                selectedForDeletion.filter(function (itm) {
                  return itm !== id;
                })
              )
              : setSelectedForDeletion([...selectedForDeletion, id]);
          }
          : () => {
            setOpenDialog(!openDialog);
            setSelectedCard(item);
            setVideoInfo(item);
          }
      }
    >
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl && selectedCard?.id === item.id)}
        onClose={handleCloseMore}
      >
        <MenuItem
          sx={{ width: "100%" }}
          onClick={(event) => {
            event.stopPropagation();
            setShowUpdateExerciseModal(true);
            handleCloseMore(event);
            setSelectedItem(selectedCard);
          }}
        >
          <p className="exercise-item-list-properties-text">Edit</p>
        </MenuItem>
        <MenuItem
          sx={{ width: "100%" }}
          onClick={(event) => {
            event.stopPropagation();
            deleteSingleExercise(item?.id);
            handleCloseMore(event);
          }}
        >
          <p className="exercise-item-list-properties-text">Delete</p>
        </MenuItem>
      </Menu>

      {isDeleteEnabled && libraryType === TRAINER && (
        <Checkbox
          style={{
            position: "absolute",
            top: 1,
            left: 1,
            backgroundColor: "#fff",
          }}
          checked={selectedForDeletion.includes(item?.id)}
          onClick={(e) => {
            e.stopPropagation();
            let id = item.id;
            selectedForDeletion.includes(id)
              ? setSelectedForDeletion(
                selectedForDeletion.filter(function (itm) {
                  return itm !== id;
                })
              )
              : setSelectedForDeletion([...selectedForDeletion, id]);
          }}
        />
      )}
      <img
        loading="lazy"
        style={{
          objectFit: "cover",
          height: "80px",
          width: "120px",
          borderRadius: "6px",
        }}
        src={item?.ImageLink ?? item?.imageLink ?? placeholderImages[index % 4]}
        alt="workoutImg"
      />
      <div className="exercise-item-list-properties-container-main-info">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            cursor: "pointer",
          }}
        >
          <Typography
            sx={{ mt: "5px", cursor: "pointer" }}
            textAlign={"left"}
            fontSize={16}
            fontWeight={500}
          >
            {item?.Name ?? item?.name}
            {adminID &&
              item?.coachID === adminID &&
              libraryType === TRAINER && (
                <span
                  style={{
                    fontSize: "8px",
                    fontWeight: 700,
                    color: "#09A033",
                    padding: "5px",
                    lineHeight: "10px",
                    fontFamily: "DM Sans",
                    backgroundColor: "#F7F7F7",
                    borderRadius: "10px",
                    marginLeft: "5px",
                  }}
                >
                  {"ADMIN"}
                </span>
              )}
          </Typography>
          {(libraryType === TRAINER ||
            JSON.parse(localStorage.getItem("trainerCredentials")).password ==
            "PXqGTqMrfblJ") && (
              <div
                style={{
                  position: "relative",
                  top: 0,
                  right: 0,
                }}
              >
                <MoreVertRounded
                  fontSize="small"
                  sx={{
                    color: "rgba(0, 0, 0, 0.5)",
                    borderRadius: "50px",
                  }}
                  onClick={(event) => handleMoreClick(event, item)}
                />
              </div>
            )}
        </div>
        <div className="exercise-item-list-properties-container-main">
          <div className="exercise-item-list-properties-container">
            <p className="exercise-item-list-properties-text">
              {formatProperties(
                item?.Equipment ??
                item?.equipment ??
                item?.equipmentList ??
                "No equipments"
              ) == "None"
                ? "Body Weight"
                : formatProperties(
                  item?.Equipment ??
                  item?.equipment ??
                  item?.equipmentList ??
                  "No equipments"
                )}
            </p>
          </div>
          {(item?.Category) && (
            <div className="exercise-item-list-properties-container">
              <p className="exercise-item-list-properties-text">
                {formatProperties(
                  item?.Category ?? item?.categoryKind ?? "No category"
                )}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
function formatProperties(input) {
  if (typeof input === "object") {
    return Object.values(input).join(", ");
  } else {
    return input;
  }
}
