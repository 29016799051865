import React from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

const AllUsersSelect = ({ selected, setSelectedClient, allAtheletes }) => {
  console.log({ allAtheletes });
  return (
    <Select
      value={selected}
      onValueChange={(value) => setSelectedClient(value)}
      className="ml-auto"
    >
      <SelectTrigger className="w-[180px] focus:ring-transparent focus:border-indigo-500 border-2">
        <SelectValue placeholder="Theme" />
      </SelectTrigger>
      <SelectContent>
        {allAtheletes?.map((athelete) => (
          <SelectItem key={athelete.id} value={athelete}>
            {athelete.Name}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

export default AllUsersSelect;
