import { SpurfitCircularProgress } from "@/components/Dashboard/dashboard";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import moment from "moment";
import { useSelector } from "react-redux";

const SubscriptionDetail = ({
  isRazorpay,
  subscriptionDetails,
  isSubscriptionStatusUnkonwn,
  latestBilling,
  loading,
  onPause,
  onResume,
  onCancel,
  onRetryPayment,
}) => {
  let subscriptionDetail = subscriptionDetails?.[0];
  let startDate = moment(
    subscriptionDetail?.lastPaymentAt || subscriptionDetail?.startAt * 1000
  ).format("Do MMM YYYY");
  console.log(subscriptionDetail);
  let lastPaymentDate = moment(
    subscriptionDetail?.lastPaymentAt || subscriptionDetails?.currentStart
  ).format("Do MMM YYYY");
  let nextPaymentDate = moment(
    subscriptionDetail?.nextPaymentAt || subscriptionDetail?.currentEnd * 1000
  ).format("Do MMM YYYY");
  let status = isRazorpay
    ? subscriptionDetail?.isCancelled
      ? "cancelled"
      : subscriptionDetail?.status
    : subscriptionDetail?.isPaused
    ? "paused"
    : subscriptionDetail?.isCancelled
    ? "cancelled"
    : "active";

  let amount = latestBilling?.amountPaid;
  let currency = latestBilling?.currency;

  const getPlanName = (amount) => {
    if (amount === 1000) {
      return "Sliver Plan";
    } else if (amount === 100000) {
      return "Gold Plan";
    } else if (amount === 1500) {
      return "Platinum Plan";
    }
  };

  const coachData = useSelector((state) => state.auth.data);
  return (
    <>
      {/* <CurrentSubscriptionCard
        planName={getPlanName(amount)}
        price={amount}
        onCancel={onCancel}
        onPause={onPause}
        onResume={onResume}
        subscriptionDetails={subscriptionDetails}
        isRazorpay={isRazorpay}
        latestBilling={latestBilling}
        isLoading={loading}
      /> */}

      <div className="w-full flex flex-col mt-4 mb-5">
        <div className="w-full font-DMSans font-semibold border-b-4 text-[#8330e9] border-[#8330e9] pl-2">
          Subscriptions & Payments
        </div>

        <div className="w-full flex flex-col mt-5 p-2">
          {/* Account Info */}
          <p className="font-DMSans font-normal text-font16">Account Info</p>
          <div className="w-full flex gap-10 mt-2">
            <p className="font-DMSans text-font14">{coachData?.name}</p>
            <p className=" font-DMSans text-font14">
              <span className="font-semibold">Email: </span>
              {coachData?.email}
            </p>

            {coachData?.contactNumber && (
              <p className="font-DMSans text-font14">
                <span className="font-semibold">Phone: </span>
                {coachData?.contactNumber}
              </p>
            )}

            {coachData?.countryCode && (
              <p className="font-DMSans text-font14">
                <span className="font-semibold">Country: </span>
                {coachData?.countryCode}
              </p>
            )}
          </div>
        </div>

        <div className="w-full flex flex-col mt-5 p-2">
          {/* Active Subscription */}

          <div className="flex w-full justify-between">
            <div>
              <p className="font-DMSans font-normal text-font16">
                Active Subscription
              </p>
              <p className="font-DMSans text-font14">{getPlanName(amount)}</p>
            </div>
            {isSubscriptionStatusUnkonwn ? (
              <div className="flex flex-wrap items-center gap-2">
                <p className="font-DMSans text-font14 text-red-600">
                  Payment processing failed. Please check your email and if not
                  recieved email please contact us.
                </p>

                {subscriptionDetail?.status === "unpaid" && (
                  <Button
                    className="text-white-pure bg-[#8330e9] font-semibold rounded-xl"
                    onClick={onRetryPayment}
                  >
                    Retry Payment
                  </Button>
                )}
              </div>
            ) : (
              <div
                className={cn(
                  "flex gap-4 items-center",
                  loading ? "opacity-50 cursor-not-allowed" : ""
                )}
              >
                {loading ? <SpurfitCircularProgress /> : null}
                {status === "cancelled" || !isRazorpay ? null : status ===
                  "paused" ? (
                  <Button
                    className="text-[#8330e9] font-semibold"
                    onClick={onResume}
                  >
                    Resume
                  </Button>
                ) : (
                  <Button
                    className="text-white-pure bg-[#8330e9] font-semibold rounded-xl"
                    onClick={onPause}
                  >
                    Pause
                  </Button>
                )}
                <Button
                  variant="outline"
                  className={cn(
                    "text-[#de3a3a] font-semibold border-[#de3a3a] rounded-xl",
                    status === "cancelled" && "opacity-50 cursor-not-allowed"
                  )}
                  disabled={status === "cancelled"}
                  onClick={onCancel}
                >
                  {status === "cancelled" ? "Cancelled" : "Cancel"}
                </Button>
              </div>
            )}
          </div>
          <div className="w-full flex gap-10 justify-between mt-3">
            <p className="font-DMSans text-font14">
              <p className="font-semibold">Start Date: </p>
              {subscriptionDetail?.status === "unpaid" ? "-" : startDate}
            </p>
            <p className="font-DMSans text-font14">
              <p className="font-semibold">Last Payment Date: </p>
              {subscriptionDetail?.status === "unpaid" ? "-" : lastPaymentDate}
            </p>
            <p className="font-DMSans text-font14">
              <p className="font-semibold">
                {status === "cancelled" ? "Cancelling on" : "Next Payment Date"}
              </p>
              {subscriptionDetail?.status === "unpaid" ? "-" : nextPaymentDate}
            </p>
            <p className="font-DMSans text-font14">
              <p className="font-semibold">Status: </p>
              {subscriptionDetail?.status === "unpaid" ? "Unpaid" : status}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

const symbols = {
  INR: "₹",
  USD: "$",
  EUR: "€",
};

export default SubscriptionDetail;
