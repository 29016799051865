import axios from "axios";
import React, { useContext, useEffect, useState, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import APIURL from "../../APIDetails";
import {
  Box,
  Container,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from "@mui/material";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import { parsePhoneNumber } from "libphonenumber-js";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import { useParams } from "react-router-dom";

import { CustomAlertContext } from "../../App";
import Layout from "../../components/layout/Layout";
import EditInfoModal from "./EditInfoModal";
import VideoThumbnail from "./VideoThumbnail";
import VideoPlayer from "./VideoPlayer";
import "./profile.css";

import PlaceholderImage from "../../assets/trainerProfile/placeholder.svg";
import CameraIcon from "../../assets/trainerProfile/cameraIcon.svg";
import MoreVertIcon from "../../assets/trainerProfile/moreVerticalIcon.svg";
import EmailIcon from "../../assets/trainerProfile/emailIcon.svg";
import PhoneIcon from "../../assets/trainerProfile/phoneIcon.svg";
import InstaIcon from "../../assets/trainerProfile/instaIcon.svg";
import TwitterIcon from "../../assets/trainerProfile/twitterIcon.svg";
import FacebookIcon from "../../assets/trainerProfile/fbIcon.svg";
import PlusIcon from "../../assets/trainerProfile/plusIcon.svg";
import ExperienceIcon from "../../assets/trainerProfile/experienceImg.svg";
import ClientIcon from "../../assets/trainerProfile/clientImg.svg";
import WorkoutIcon from "../../assets/trainerProfile/workoutPlan.svg";
import BodyImage from "../../assets/trainerProfile/Body.svg";
import SpinnerComponent from "../../components/layout/spinner";
import SpurfitPublicLayout from "../../components/layout/SpurfitPublicLayout";

const currencySymbols = {
  KRW: "₩",
  USD: "$",
  CAD: "$",
  INR: "₹",
  AED: "د.إ",
  EUR: "€",
  GBP: "£",
  SGD: "$",
};

const Profile = () => {
  const [trainerInfo, setTrainerInfo] = useState("");
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
  const [topWorkoutsSelectModalOpen, setTopWorkoutsSelectModalOpen] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState("");
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const videoFileInputRef = useRef(null);

  const { displayAlert } = useContext(CustomAlertContext);
  const { id, name } = useParams();

  useEffect(() => {
    console.log({ id, name });
    getProfileData();
  }, []);

  const getProfileData = async () => {
    setLoading(true);
    await axios({
      method: "get",
      url: `${APIURL}CF/api/CoachWeb/Get/oneTrainerProfile`,
      params: {
        coachID: id,
      },
    })
      .then((res) => {
        console.log("Profile daata: ", res.data.result);
        setTrainerInfo(res.data.result);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        displayAlert(err);
      });
  };

  function getCountryCodeFromPhoneNumber(phoneNumber) {
    let parsedPhoneNumber;
    try {
      parsedPhoneNumber = parsePhoneNumber(phoneNumber);
    } catch (err) {
      return null;
    }

    if (!parsedPhoneNumber) {
      return null; // return null if the phone number is invalid
    }

    return parsedPhoneNumber.country;
  }

  function getCountryFlagFromPhoneNumber(phoneNumber) {
    let countryCode = getCountryCodeFromPhoneNumber(phoneNumber);
    return countryCode != null ? getUnicodeFlagIcon(countryCode) : "";
  }

  const shareProfile = () => {
    let trainerDetails = JSON.parse(localStorage.getItem("trainerCredentials"));
    const link = `https://trainer.spur.fit/profile/${trainerDetails?.password}`;
    navigator.clipboard.writeText(link);
    toast.success("copied to clipboard");
  };

  function isImage(file) {
    const extensions = ["jpg", "jpeg", "png", "gif"];
    return extensions.some((extension) => file.endsWith(extension));
  }

  function isVideo(file) {
    const extensions = [
      "mp4",
      "mov",
      "avi",
      "webm",
      "ogg",
      "flv",
      "wmv",
      "mkv",
    ];
    return extensions.some((extension) => file.endsWith(extension));
  }

  return (
    <SpurfitPublicLayout>
      <Layout isPublic={true}>
        {loading ? (
          <SpinnerComponent />
        ) : (
          <>
            <VideoPlayer
              videoUrl={selectedVideo}
              open={videoModalOpen}
              setOpen={setVideoModalOpen}
            />
            <Box
              className="zoomedBody"
              sx={{
                height: "773px",
                backgroundColor: "#ffffff",
                borderRadius: "20px",
                position: "relative",
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
                mt: "-77px",
              }}
            >
              <Paper
                elevation={0}
                sx={{
                  backgroundImage: `url(${trainerInfo?.coverimageKey || PlaceholderImage
                    })`,
                  boxShadow: "inset 0 0 0 2000px rgba(0, 0, 0, 0.3)",
                  height: "396px",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  position: "relative",
                }}
              >
                <Box sx={{ position: "absolute", top: "204px", left: "50px" }}>
                  <img
                    style={{
                      height: "265px",
                      width: "265px",
                      borderRadius: "50%",
                      border: "4px solid #fff",
                    }}
                    src={`${trainerInfo?.imageKey}?${Date.now()}`}
                    alt="trainer"
                  />
                </Box>
              </Paper>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: "111px",
                  mx: "50px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "DM Sans",
                    fontSize: "48px",
                    fontWeight: 700,
                    color: "#000",
                  }}
                >
                  {trainerInfo?.firstName
                    ? trainerInfo?.firstName + " " + trainerInfo.lastName
                    : trainerInfo.name}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", mx: "50px" }}>
                <Typography
                  sx={{
                    color: "#7239EA",
                    fontFamily: "DM Sans",
                    fontSize: "18px",
                    fontWeight: 500,
                    backgroundColor: "#F4EFFF",
                    padding: "10px",
                    borderRadius: "125px",
                    marginTop: "15px",
                  }}
                >
                  {trainerInfo?.trainerTag || "Fitness Coach"}
                </Typography>
              </Box>

              <Box sx={{ mx: "50px", mt: "50px", display: "flex" }}>
                <Box sx={{ display: "flex" }}>
                  <img
                    src={EmailIcon}
                    alt="email"
                    style={{
                      height: "24px",
                      width: "24px",
                      marginRight: "12px",
                    }}
                  />
                  <Typography
                    sx={{
                      fontFamily: "DM Sans",
                      fontSize: "18px",
                      fontWeight: 500,
                      color: "#333338",
                    }}
                  >
                    {trainerInfo.email}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", ml: "69px" }}>
                  <img
                    src={PhoneIcon}
                    alt="phone"
                    style={{
                      height: "24px",
                      width: "24px",
                      marginRight: "12px",
                    }}
                  />
                  <Typography
                    sx={{
                      fontFamily: "DM Sans",
                      fontSize: "18px",
                      fontWeight: 500,
                      color: "#333338",
                    }}
                  >
                    {trainerInfo.contactNumber}
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", ml: "auto", gap: 4 }}>
                  {trainerInfo?.socailHandles?.fb && (
                    <div
                      onClick={() =>
                        window.open(trainerInfo?.socailHandles?.fb, "_blank")
                      }
                      className="flex items-center cursor-pointer"
                    >
                      <img
                        src={FacebookIcon}
                        alt=""
                        style={{ height: 40, width: 40, }}
                      />
                    </div>
                  )}

                  {trainerInfo?.socailHandles?.twitter && (
                    <div
                      onClick={() =>
                        window.open(trainerInfo?.socailHandles?.twitter, "_blank")
                      }
                      className="flex items-center cursor-pointer"
                    >
                      <img
                        src={TwitterIcon}
                        alt=""
                        style={{ height: 40, width: 40 }}
                      />
                    </div>
                  )}

                  {trainerInfo?.socailHandles?.insta && (
                    <div
                      onClick={() =>
                        window.open(trainerInfo?.socailHandles?.insta, "_blank")
                      }
                      className="flex items-center cursor-pointer"
                    >
                      <img
                        src={InstaIcon}
                        alt=""
                        style={{ height: 40, width: 40, }}
                      />{" "}
                    </div>
                  )}

                  {/*    linkedin*/}
                  {trainerInfo?.socailHandles?.linkedin &&
                    <div
                      onClick={() =>
                        window.open(trainerInfo?.socailHandles?.linkedin, "_blank")
                      }
                      className="flex items-center cursor-pointer"
                    >
                      <img
                        src={'https://upload.wikimedia.org/wikipedia/commons/thumb/8/81/LinkedIn_icon.svg/1200px-LinkedIn_icon.svg.png'}
                        alt=""
                        style={{ height: 40, width: 40, }}
                      />
                    </div>
                  }
                  {/*    linktree*/}
                  {trainerInfo?.socailHandles?.linktree &&
                    <div
                      onClick={() =>
                        window.open(trainerInfo?.socailHandles?.linktree, "_blank")
                      }
                      className="flex items-center cursor-pointer"
                    >
                      <img
                        src={'https://img.utdstc.com/icon/0d4/e93/0d4e9331c3b8346858e1e5c4f77e9dfd92dccf8c38db0b280dba00076e5d5dc0:200'}
                        alt=""
                        style={{ height: 40, width: 40 }}
                      />
                    </div>
                  }
                </Box>
              </Box>
            </Box>

            <Box
              name={"About"}
              sx={{
                // height: "652px",
                boxShadow: "2px 6px 37px 0px rgba(0, 0, 0, 0.05)",
                borderRadius: "20px",
                backgroundColor: "#fff",
                mt: "40px",
                pl: "40px",
                pr: "50px",
                pb: "30px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "DM Sans",
                  fontSize: "36px",
                  fontWeight: 700,
                  color: "#121314",
                  mt: "40px",
                }}
              >
                About
              </Typography>
              {/* <Typography
                sx={{
                  mt: "30px",
                  fontFamily: "DM Sans",
                  fontSize: "22px",
                  fontWeight: 500,
                  color: "#515156",
                }}
              >
                {trainerInfo.bio}
              </Typography> */}
              {
                trainerInfo?.bio && <div style={{
                  fontSize: '22px',
                  fontFamily: 'DM Sans',
                  marginTop: '30px',
                  color: '#515156'
                }} dangerouslySetInnerHTML={{ __html: trainerInfo?.bio }}></div>
              }

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "95px",
                  mt: "25px",
                }}
              >
                {trainerInfo?.experience && (
                  <Box
                    sx={{
                      display: "flex",
                      allignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        height: "64px",
                        width: "64px",
                        borderRadius: "50%",
                        background: "#D3E0FF",
                        padding: "16px",
                        marginRight: "20px",
                      }}
                      src={ExperienceIcon}
                    />
                    <Typography
                      sx={{
                        fontFamily: "DM Sans",
                        fontSize: "26px",
                        color: "#121314",
                        fontWeight: 700,
                        alignSelf: "center",
                      }}
                    >
                      <span style={{ color: "#3239ED" }}>
                        {trainerInfo.experience}{" "}
                      </span>{" "}
                      Years of Experience
                    </Typography>
                  </Box>
                )}

                {trainerInfo?.numberOfClients && (
                  <Box
                    sx={{
                      display: "flex",
                      allignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        height: "64px",
                        width: "64px",
                        borderRadius: "50%",
                        background: "#D3E0FF",
                        padding: "16px",
                        marginRight: "20px",
                      }}
                      src={ClientIcon}
                    />
                    <Typography
                      sx={{
                        fontFamily: "DM Sans",
                        fontSize: "26px",
                        color: "#121314",
                        fontWeight: 700,
                        alignSelf: "center",
                      }}
                    >
                      <span style={{ color: "#3239ED" }}>
                        {trainerInfo.numberOfClients}
                        {"+"}
                      </span>{" "}
                      Clients Training
                    </Typography>
                  </Box>
                )}

                {trainerInfo?.totalPlannedWorkouts && (
                  <Box
                    sx={{
                      display: "flex",
                      allignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        height: "64px",
                        width: "64px",
                        borderRadius: "50%",
                        background: "#D3E0FF",
                        padding: "16px",
                        marginRight: "20px",
                      }}
                      src={WorkoutIcon}
                    />
                    <Typography
                      sx={{
                        fontFamily: "DM Sans",
                        fontSize: "26px",
                        color: "#121314",
                        fontWeight: 700,
                        alignSelf: "center",
                      }}
                    >
                      <span style={{ color: "#3239ED" }}>
                        {trainerInfo.totalPlannedWorkouts}
                        {"+"}
                      </span>{" "}
                      Workout Plans
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>

            <Box
              name="Top Workout Plans"
              sx={{
                // height: "735px",
                borderRadius: "20px",
                boxShadow: "2px 6px 37px 0px rgba(0, 0, 0, 0.05)",
                backgroundColor: "#fff",
                mt: "40px",
                pl: "40px",
                pr: "50px",
              }}
            >
              <Box
                sx={{
                  mt: "38.93px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "DM Sans",
                    fontWeight: 700,
                    fontSize: "36px",
                    color: "#121314",
                  }}
                >
                  Top Workout Plans
                </Typography>
              </Box>

              <Box
                name="topWorkoutPlanCards"
                sx={{
                  mt: "11.43px",
                  display: "flex",
                  flexDirection: "column",
                  mb: "40px",
                }}
              >
                {trainerInfo?.topPrograms?.map((item, index) => {
                  const program = item?.program;
                  return (
                    <Box
                      name="topWorkoutPlanCard"
                      sx={{
                        height: "170px",
                        borderRadius: "8px",
                        border: "1px solid #E1E1E6",
                        background: "#FFF",
                        mt: "30px",
                        px: "30px",
                      }}
                      key={index}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "end",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontSize: "24px",
                            fontFamily: "DM Sans",
                            color: "#333338",
                            mt: "23px",
                          }}
                        >
                          {program?.name}
                        </Typography>

                        <Typography
                          sx={{
                            fontFamily: "DM Sans",
                            fontSize: "16px",
                            fontWeight: 500,
                            color: "#21C34E",
                            padding: "6px 10px",
                            backgroundColor: "#DCFFE6",
                            borderRadius: "125px",
                            height: "33px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {item?.level || " Expert's Plan"}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          height: "75px",
                        }}
                      >
                        {program?.duration && (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: "rgba(231, 232, 236, 0.63)",
                              padding: " 9px 28px",
                              borderRadius: "5px",
                              height: "75px",
                              mt: "20px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: "DM Sans",
                                fontSize: "24px",
                                fontWeight: 700,
                                color: "#333338",
                              }}
                            >
                              {program?.duration}
                            </Typography>
                            <Typography
                              sx={{
                                fontFamily: "DM Sans",
                                fontSize: "20px",
                                fontWeight: 500,
                                color: "#56565B",
                              }}
                            >
                              days
                            </Typography>
                          </Box>
                        )}

                        <Typography
                          sx={{
                            color: "#171719",
                            fontFamily: "DM Sans",
                            fontWeight: 700,
                            fontSize: "36px",
                            ml: "auto",
                            mt: "auto",
                          }}
                        >
                          {currencySymbols?.[item?.priceCode]}
                          {item?.price}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>

            <Box
              name="client testimonials"
              sx={{
                mt: "40px",
                mb: "40px",
                pl: "40px",
                pr: "50px",
                backgroundColor: "#fff",
                borderRadius: "20px",
                boxShadow: "2px 6px 37px 0px rgba(0, 0, 0, 0.05)",
              }}
            >
              <Box
                name="testimonials header"
                sx={{
                  mt: "38.93px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "DM Sans",
                    fontWeight: 700,
                    fontSize: "36px",
                    color: "#121314",
                  }}
                >
                  Client Testimonials
                </Typography>
              </Box>

              <Box
                name="testimonial cards"
                sx={{
                  mt: "37px",
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fit, minmax(366px, 1fr))",
                  gap: "70px", // Set the desired vertical gap between items
                  paddingBottom: "50px",
                }}
              >
                {trainerInfo?.clientTestimonialKeys?.map((media, index) => {
                  return (
                    <>
                      {isVideo(Object.values(media)[0]) ? (
                        <div
                          className="media-container"
                          style={{
                            height: "206px",
                            width: "366px",
                            borderRadius: "4.329px", // "4.329px 4.329px  7.566px 7.566px", <- when you add title
                            boxShadow: "2px 6px 37px 0px rgba(0, 0, 0, 0.05)",
                            backgroundColor: "rgba(0, 0, 0, 0.40)",
                            cursor: "pointer",
                            position: "relative",
                          }}
                          onClick={() => {
                            setVideoModalOpen(true);
                            setSelectedVideo(Object.values(media)[0]);
                          }}
                        >
                          <VideoThumbnail videoUrl={Object.values(media)[0]} />
                        </div>
                      ) : isImage(Object.values(media)[0]) ? (
                        <div
                          className="media-container"
                          style={{
                            height: "206px",
                            width: "366px",
                            borderRadius: "4.329px 4.329px ", // "4.329px 4.329px  7.566px 7.566px", <- when you add title
                            boxShadow: "2px 6px 37px 0px rgba(0, 0, 0, 0.05)",
                            position: "relative",
                          }}
                        >
                          <img
                            style={{
                              height: "100%",
                              width: "100%",
                              borderRadius: "9px",
                              boxShadow: "2px 6px 37px 0px rgba(0, 0, 0, 0.05)",
                              zIndex: 1,
                            }}
                            src={Object.values(media)[0]}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  );
                })}
              </Box>
            </Box>
          </>
        )}
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          zindex="999999"
        />
      </Layout>
    </SpurfitPublicLayout>
  );
};

export default Profile;

const VideoCard = ({ videoUrl, videoModalOpen, setVideoModalOpen }) => {
  return (
    <div
      className="videoCard cursor-pointer"
      onClick={() => {
        setVideoModalOpen(true);
      }}
    >
      <VideoThumbnail videoUrl={videoUrl} />
    </div>
  );
};
