import React from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiDialog: {
      styleOverrides: {
        root: {
          zIndex: "100000 !important",
        },
        paper: {
          margin: "0 12px !important",
          borderRadius: 14,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          overflowY: "hidden",
        },
      },
    },
  },
});

const DialogBox = ({
  fullScreen,
  fullWidth,
  maxWidth,
  onClose,
  classes,
  children,
  open,
  dialogTitle,
}) => {
  const { root, paper, container } = classes || {};

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ root, paper, container }}
        PaperProps={{}}
      >
        {dialogTitle && (
          <DialogTitle style={{ padding: "25px" }}>{dialogTitle}</DialogTitle>
        )}
        <DialogContent>{children}</DialogContent>
      </Dialog>
    </ThemeProvider>
  );
};

DialogBox.propTypes = {
  fullScreen: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onClose: PropTypes.any,
  classes: PropTypes.any,
  children: PropTypes.node,
  open: PropTypes.bool,
  maxWidth: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", false]),
  dialogTitle: PropTypes.node,
};

DialogBox.defaultProps = {
  fullWidth: true,
  maxWidth: true,
};

export default DialogBox;
