import { getVideoId } from "@/pages/Exercises/exerciseUtil";
import { CloseRounded } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
import VideoUploadIcon from "../../assets/trainerProfile/videoUploadImage.svg";
import { SpurfitCircularProgress } from "../Dashboard/dashboard";
import Input from "../InputComponents/Input";
import TextArea from "../InputComponents/TextArea";

const VIDEO_TYPE_UPLOAD = "upload";
const VIDEO_TYPE_YOUTUBE = "youtube";
const VIDEO_TYPE_VIMEO = "vimeo";

const DOC_TYPE_UPLOAD = "upload";
const DOC_TYPE_LINK = "link";

function AddRecipieModal({
  isOpen,
  setIsOpen,
  getTrainerRecipes,
  getCoachRecipes,
  setNutritionData,
  setdefaultNItem,
  setLoadingSearchedItem,
  data,
  isEdit,
}) {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const [displayName, setDisplayName] = useState(data?.recipe_name || "");
  const [tags, setTags] = useState([]);
  const [servingName, setServingName] = useState("");
  const [cookingTime, setCookingTime] = useState("");
  const [description, setDescription] = useState(data?.description || "");
  const [protein, setProtein] = useState(data?.serving_sizes?.protein || null);
  const [fat, setFat] = useState(data?.serving_sizes?.fat || null);
  const [carbs, setCarbs] = useState(data?.serving_sizes?.carbohydrate || null);
  const [calories, setCalories] = useState(
    data?.serving_sizes?.calories || null
  );
  const [videoType, setVideoType] = useState(VIDEO_TYPE_YOUTUBE);
  const [recipeVideo, setRecipeVideo] = useState(data?.receipeVideos || null);
  const [attachmentType, setAttachmentType] = useState(DOC_TYPE_UPLOAD);
  const [attachments, setAttachments] = useState([]);
  const [serving_size, setServing_size] = useState(null);
  const trainerUUID = useSelector((state) => state?.auth?.trainerUUID);
  const [loading, setLoading] = useState(false);
  // Additional state or logic for calculating calories
  const [calculateCals, setCalculateCals] = useState(true);
  const [showNegativeValueAlert, setShowNegativeValueAlert] = useState(false);
  const [ingredients, setIngredients] = useState(data?.ingredients || []);
  const [directions, setDirections] = useState(data?.directions || []);
  const addRecipe = async () => {
    try {
      setLoading(true);
      const res = await axios({
        method: "POST",
        url: `${APIURL}CF/api/createReceipe?coachId=${trainerUUID}`,
        data: {
          name: displayName,
          description,
          receipeVideos: recipeVideo,
          serving: {
            recipe_name: displayName,
            ingredients: {},
            directions: {},
            description,
            receipeVideos: recipeVideo,
            serving_sizes: {
              calories,
              fat,
              carbohydrate: carbs,
              protein,
              serving_size: "1 serving",
            },
          },
        },
      });
      console.log("res from add receipe", res?.data);
      let trainerRecipes = await getCoachRecipes();

      if (trainerRecipes.length > 0) {
        const specificId = res?.data?.id;
        const foundItem = trainerRecipes.find((item) => item.id === specificId);

        if (foundItem) {
          const restOfItems = trainerRecipes.filter(
            (item) => item.id !== specificId
          );
          // Add the foundItem at the beginning of the array
          trainerRecipes = [foundItem, ...restOfItems];
          setNutritionData(trainerRecipes);
          setdefaultNItem(foundItem);
        } else {
          console.log("Item with specific ID not found");
        }
      }

      let timeout = setTimeout(() => {
        setLoadingSearchedItem(false);
      }, 1000);

      return () => {
        clearTimeout(timeout);
      };
    } catch (err) {
      console.log("Error in adding the recipe", err);
    } finally {
      setLoading(false);
      setIsOpen(false);
    }
  };
  const updateRecipe = async () => {
    try {
      setLoading(true);
      let updatedServing = {};
      updatedServing.description = description;
      updatedServing.receipeVideos = recipeVideo;
      updatedServing.recipe_name = displayName;
      updatedServing.serving_sizes = {
        calories,
        fat,
        carbohydrate: carbs,
        protein,
        serving_size: "1 serving",
      };
      updatedServing.ingredients = { ...ingredients };
      updatedServing.directions = { ...directions };
      const res = await axios.post(`${APIURL}CF/api/updateReceipe`, {
        id: data?.id,
        name: displayName,
        description: description,
        serving: updatedServing,
      });
      console.log(
        "response after updating the local recipe by adding either ingredient or directions",
        res?.data?.data
      );
      if (res?.data?.data) {
        let recipe = res?.data?.data?.serving;
        let directions = Object.entries(recipe?.directions || {}).map(
          ([key, value]) => {
            return {
              direction_number: key,
              direction_description: value,
            };
          }
        );

        let ingredients = Object.entries(recipe?.ingredients || []).map(
          ([key, value]) => {
            return {
              ingredient_description: value,
            };
          }
        );

        let updatedRecipe = {
          ...recipe,
          directions,
          ingredients,
          id: res?.data?.data?.id,
        };
        console.log("what is updated recipe", updatedRecipe);
        let trainerRecipes = await getCoachRecipes();
        if (trainerRecipes.length > 0) {
          const specificId = res?.data?.data?.id;
          const foundItem = trainerRecipes.find(
            (item) => item.id === specificId
          );

          if (foundItem) {
            const restOfItems = trainerRecipes.filter(
              (item) => item.id !== specificId
            );
            // Add the foundItem at the beginning of the array
            trainerRecipes = [foundItem, ...restOfItems];
            setNutritionData(trainerRecipes);
            setItem(foundItem);
          } else {
            console.log("Item with specific ID not found");
          }
        }

        let timeout = setTimeout(() => {
          setLoadingSearchedItem(false);
        }, 1000);

        return () => {
          clearTimeout(timeout);
        };
      }
    } catch (err) {
      console.log("there is some problem in the update recipe call", err);
    } finally {
      setLoading(false);
      setIsOpen(false);
    }
  };
  useEffect(() => {
    if (calculateCals) {
      // Calculate calories from macros
      setCalories(Math.round(protein * 4 + carbs * 4 + fat * 9));
    }
  }, [protein, fat, carbs]);
  function convertDirections(initialDirections) {
    const finalDirections = {};
    if (initialDirections) {
      initialDirections.forEach((direction) => {
        finalDirections[direction.direction_number] =
          direction.direction_description;
      });
    }
    return { directions: finalDirections };
  }

  function convertIngredients(initialIngredients) {
    const finalIngredients = {};
    if (initialIngredients) {
      initialIngredients.forEach((ingredient, index) => {
        finalIngredients[index + 1] = ingredient.ingredient_description;
      });
    }
    return { ingredients: finalIngredients };
  }
  useEffect(() => {
    if (isEdit) {
      const convertedIng = convertIngredients(data?.ingredients).ingredients;
      setIngredients(convertedIng);
      const convertedDir = convertDirections(data?.directions).directions;
      setDirections(convertedDir);
    }
  }, []);

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      PaperProps={{
        sx: {
          minWidth: "500px",
          minHeight: "400px",
          borderRadius: "12px",
          maxHeight: "85vh",
        },
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid #f2f2f2",
          position: "relative",
        }}
      >
        <DialogTitle>Add Recipe</DialogTitle>
        <CloseRounded
          sx={{
            top: "24px",
            right: "24px",
            cursor: "pointer",
            position: "absolute",
          }}
          onClick={() => setIsOpen(false)}
        />
      </div>
      {loading ? (
        <DialogContent>
          <SpurfitCircularProgress />
        </DialogContent>
      ) : (
        <DialogContent>
          <Stack gap={2}>
            {/* <NutritionInput
    label={"Reference Name*"}
    value={referenceName}
    onChange={(value) => setReferenceName(value)}
  /> */}
            <Input
              label={"Recipe Name *"}
              placeholder={"Recipe Name"}
              value={displayName}
              onChange={(value) => setDisplayName(value)}
            />
            {/* Tags  */}
            {/* <MultiSelect
    label={"serving name"}
    value={servingName}
    onChange={(value) => setServingName(value)}
    options={servingNames}
  /> */}

            {/* <Box
    sx={{
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    }}
  >
    <NutritionInput
      style={{
        minWidth: "48%",
      }}
      label={"Serving Name"}
      value={servingName}
      onChange={(value) => setServingName(value)}
    />

    <NutritionInput
      style={{
        minWidth: "48%",
      }}
      label={"Cooking Time"}
      value={cookingTime}
      onChange={(value) => setCookingTime(value)}
    />
  </Box> */}

            <TextArea
              placeholder="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              numRows={4}
            />

            <Divider />
            <Box>
              <Typography
                sx={{
                  fontFamily: "DM Sans",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "20px",
                  textAlign: "left",
                  paddingLeft: "12px",
                  marginBottom: "16px",
                }}
              >
                Nutrition & Serving info*
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "16px",
                }}
              >
                <Input
                  label={"Protein*"}
                  placeholder={"Protein"}
                  value={protein}
                  onChange={(e) => {
                    let value = e.target.value;
                    if (value >= 0) {
                      setProtein(value);
                      setShowNegativeValueAlert(false);
                    } else {
                      setShowNegativeValueAlert(true);
                    }
                  }}
                />

                <Input
                  label={"Fat*"}
                  placeholder={"Fat"}
                  value={fat}
                  onChange={(e) => {
                    let value = e.target.value;
                    if (value >= 0) {
                      setFat(value);
                      setShowNegativeValueAlert(false);
                    } else {
                      setShowNegativeValueAlert(true);
                    }
                  }}
                />

                <Input
                  label={"Carbs*"}
                  placeholder={"Carbs"}
                  value={carbs}
                  onChange={(e) => {
                    let value = e.target.value;
                    if (value >= 0) {
                      setCarbs(value);
                      setShowNegativeValueAlert(false);
                    } else {
                      setShowNegativeValueAlert(true);
                    }
                  }}
                />

                <Input
                  disabled={calculateCals}
                  label={"Calories*"}
                  placeholder={"Calories"}
                  value={calories}
                  onChange={(e) => setCalories(e.target.value)}
                />

                {/* <NutritionInput
                  style={{
                    minWidth: "20%",
                    marginRight: 3,
                  }}
                  placeholder={"Protein"}
                  value={protein}
                  onChange={(value) => {
                    if (value >= 0) {
                      setProtein(value);
                      setShowNegativeValueAlert(false);
                    } else {
                      setShowNegativeValueAlert(true);
                    }
                  }}
                />
                <NutritionInput
                  style={{
                    minWidth: "20%",
                    marginRight: 3,
                  }}
                  placeholder={"Fat"}
                  value={fat}
                  onChange={(value) => {
                    if (value >= 0) {
                      setFat(value);
                      setShowNegativeValueAlert(false);
                    } else {
                      setShowNegativeValueAlert(true);
                    }
                  }}
                />
                <NutritionInput
                  style={{
                    minWidth: "20%",
                    marginRight: 3,
                  }}
                  placeholder={"Carbs"}
                  value={carbs}
                  onChange={(value) => {
                    if (value >= 0) {
                      setCarbs(value);
                      setShowNegativeValueAlert(false);
                    } else {
                      setShowNegativeValueAlert(true);
                    }
                  }}
                />
                <NutritionInput
                  style={{
                    minWidth: "20%",
                  }}
                  placeholder={"Calories"}
                  value={calories}
                  onChange={(value) => setCalories(value)}
                  right={"calories"}
                /> */}
              </Box>
              {showNegativeValueAlert && (
                <p className="font-medium text-[12px] text-red-500 mt-2">
                  *Please do not enter negative values.
                </p>
              )}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  sx={{ "&.Mui-checked": { color: "#663fea" } }}
                  checked={calculateCals}
                  onChange={(e) => setCalculateCals(e.target.checked)}
                />
                <Typography
                  sx={{
                    fontFamily: "DM Sans",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "20px",
                    textAlign: "left",
                    paddingLeft: "0px",
                  }}
                >
                  Automatically calculate calories from macros
                </Typography>
              </Box>
            </Box>
            <Divider />

            <Box>
              <Typography
                sx={{
                  fontFamily: "DM Sans",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "20px",
                  textAlign: "left",
                  paddingLeft: "12px",
                  marginBottom: "16px",
                }}
              >
                Recipe Video
              </Typography>

              {/* <RadioGroup
      row
      aria-labelledby="demo-radio-buttons-group-label"
      defaultValue="upload"
      name="radio-buttons-group"
      sx={{
        paddingLeft: "12px",
      }}
      onChange={(event) => {
        setVideoType(event.target.value);
      }}
    >
      <FormControlLabel
        value="upload"
        control={<Radio />}
        label="Upload"
        sx={{
          fontSize: "14px",
        }}
      />
      <FormControlLabel
        value="youtube"
        control={<Radio />}
        label="Yoututbe"
        sx={{
          fontSize: "14px",
        }}
      />
      <FormControlLabel
        value="vimeo"
        control={<Radio />}
        label="Vimeo"
        sx={{
          fontSize: "14px",
        }}
      />
    </RadioGroup> */}

              <VideoUpload
                value={recipeVideo}
                setRecipeVideo={setRecipeVideo}
                videoType={videoType}
                url={data?.receipeVideos}
              />
            </Box>

            {/* <Divider /> */}
            {/* <Box>
    <Typography
      sx={{
        fontFamily: "DM Sans",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "20px",
        textAlign: "left",
        paddingLeft: "12px",
        marginBottom: "16px",
      }}
    >
      Attachments
    </Typography>

    <RadioGroup
      row
      aria-labelledby="demo-radio-buttons-group-label"
      defaultValue="upload"
      name="radio-buttons-group"
      sx={{
        paddingLeft: "12px",
      }}
      onChange={(event) => {
        setAttachmentType(event.target.value);
      }}
    >
      <FormControlLabel
        value="upload"
        control={<Radio />}
        label="Upload"
        sx={{
          fontSize: "14px",
        }}
      />
      <FormControlLabel
        value="link"
        control={<Radio />}
        label="Add Link"
        sx={{
          fontSize: "14px",
        }}
      />
    </RadioGroup>

    <Attachment
      attachment={attachments}
      setAttachments={setAttachments}
      attachmentType={attachmentType}
    />
  </Box> */}
          </Stack>
        </DialogContent>
      )}

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "white",
          padding: "16px 0px",
        }}
      >
        {isEdit ? (
          <button className="button-new" onClick={() => updateRecipe()}>
            Update Recipe
          </button>
        ) : (
          <button className="button-new" onClick={() => addRecipe()}>
            Add Recipe
          </button>
        )}
      </div>
    </Dialog>
  );
}

export const DescriptionInput = ({ label, value, onChange, placeholder }) => {
  return (
    <div
      style={{
        minWidth: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* <label
        style={{
          fontFamily: "DM Sans",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "20px",
          textAlign: "left",
          paddingLeft: "12px",
        }}
      >
        {label}
      </label> */}
      <textarea
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        style={{
          width: "100%",
          display: "flex",
          height: "100px",
          padding: "10px 16px",
          alignItems: "center",
          gap: "2px",
          alignSelf: "stretch",
          borderRadius: "12px",
          border: "1px solid #ebebeb",
          background: "#fff",
          color: "#000",
          fontFamily: "DM Sans",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "20px",
          outline: "none",
          transition: "border-color 0.2s ease-in-out",
        }}
      />
    </div>
  );
};

export const VideoUpload = ({ videoType, value, setRecipeVideo, url }) => {
  const [thumbnail, setThumbnail] = useState(null);
  const extractThumbnailFromUrl = (url) => {
    if (url != "") {
      let videoId = getVideoId(url);
      let imageUrl = "https://vumbnail.com/" + videoId + "_large.jpg";
      console.log("thumbnail url ha kya", imageUrl);
      setThumbnail(imageUrl);
    } else {
      setThumbnail(null);
    }
  };
  useEffect(() => {
    if (url) {
      extractThumbnailFromUrl(url);
    }
  }, []);
  return videoType === VIDEO_TYPE_UPLOAD ? (
    <Box
      sx={{
        height: "200px",
        width: "100%",
        borderRadius: "20px",
        overflow: "hidden",
        border: "1px solid #464FEB",
        backgroundColor: "#fff",
      }}
    >
      <Dropzone
        onDrop={(acceptedFiles) => {
          // set file
          setRecipeVideo(acceptedFiles[0]);
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "30px",
              }}
              {...getRootProps()}
            >
              <input {...getInputProps()} />

              <div
                style={{
                  borderRadius: "48px",
                  backgroundColor: "#EDEDEF",
                  height: "48px",
                  width: "48px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  style={{
                    height: "36px",
                    width: "36px",
                  }}
                  src={VideoUploadIcon}
                />
              </div>
              <Typography
                sx={{
                  color: "#333338",
                  textAlign: "center",
                  fontSize: "14px",
                  fontWeight: 700,
                  fontFamily: "DM Sans",
                  mt: "20px",
                }}
              >
                Drag & drop video you want to upload
              </Typography>
              <Typography
                sx={{
                  color: "#757579",
                  fontSize: "12px",
                  fontWeight: 500,
                  fontFamily: "DM Sans",
                }}
              >
                Max file size: 15mb
              </Typography>

              <button
                style={{
                  backgroundColor: "#545567",
                  boxShadow:
                    "0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 4px 8px 0px rgba(0, 0, 0, 0.14)",
                  borderRadius: "52px",
                  color: "#fff",
                  height: "30px",
                  width: "108px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "22px",
                  letterSpacing: "-0.43px",
                  fontFamily: "DM Sans",
                  textAlign: "center",
                  marginTop: "16px",
                }}
              >
                Browse
              </button>
            </div>
          </section>
        )}
      </Dropzone>
    </Box>
  ) : (
    <>
      <NutritionInput
        placeholder={"Youtube URL"}
        value={value}
        onChange={(value) => {
          setRecipeVideo(value);
          extractThumbnailFromUrl(value);
        }}
      />
      {thumbnail && (
        <div
          style={{
            height: "200px",
            width: "100%",
            borderRadius: "20px",
            overflow: "hidden",
            border: "1px solid #464FEB",
            backgroundColor: "#f7f7f7",
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
            alignItems: "center",
          }}
        >
          {thumbnail && (
            <img
              src={thumbnail}
              alt=""
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          )}
          {/* {thumbnail && (
          <div className="w-full h-full flex justify-center items-center">
            <div
              style={{
                borderRadius: "48px",
                backgroundColor: "#EDEDEF",
                height: "48px",
                width: "48px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{
                  height: "36px",
                  width: "36px",
                }}
                src={VideoUploadIcon}
              />
            </div>
            <p className="font-medium ">Add video link to display thumbnail</p>
          </div>
        )} */}
        </div>
      )}
    </>
  );
};

const Attachment = ({ attachment, setAttachments, attachmentType }) => {
  return attachmentType === DOC_TYPE_UPLOAD ? (
    <Box
      sx={{
        height: "100px",
        width: "100%",
        borderRadius: "20px",
        overflow: "hidden",
        border: "1px solid #464FEB",
        backgroundColor: "#fff",
        display: "flex",
        alignItems: "center",
        padding: "16px",
      }}
    >
      <Dropzone
        onDrop={(acceptedFiles) => {
          // set file
          setAttachments(acceptedFiles[0]);
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <section
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
              }}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <Box>
                <Typography
                  sx={{
                    color: "#333338",
                    textAlign: "center",
                    fontSize: "14px",
                    fontWeight: 700,
                    fontFamily: "DM Sans",
                  }}
                >
                  Drop you file or browse
                </Typography>
                <Typography
                  sx={{
                    color: "#757579",
                    fontSize: "12px",
                    fontWeight: 500,
                    fontFamily: "DM Sans",
                  }}
                >
                  Max file size: 15mb
                </Typography>
              </Box>

              <button
                style={{
                  backgroundColor: "#545567",
                  boxShadow:
                    "0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 4px 8px 0px rgba(0, 0, 0, 0.14)",
                  borderRadius: "52px",
                  color: "#fff",
                  height: "30px",
                  width: "108px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "22px",
                  letterSpacing: "-0.43px",
                  fontFamily: "DM Sans",
                  textAlign: "center",
                  marginTop: "16px",
                }}
              >
                Browse
              </button>
            </div>
          </section>
        )}
      </Dropzone>
    </Box>
  ) : (
    <NutritionInput
      label={"Link"}
      value={attachment}
      onChange={(value) => setAttachments(value)}
    />
  );
};

const MultiSelect = ({ label, value, onChange, options }) => {
  return (
    <Box>
      <Select
        multiple
        // native
        value={value}
        onChange={onChange}
        inputProps={{
          id: "select-multiple-native",
        }}
      >
        {options.map((option) => (
          <option key={option.id} value={option.label}>
            {option.label}
          </option>
        ))}
      </Select>
    </Box>
  );
};

export default AddRecipieModal;

export const NutritionInput = ({
  label,
  placeholder,
  value,
  onChange,
  style,
  right,
  disabled,
  height,
}) => {
  return (
    <div
      style={{
        minWidth: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        ...style,
      }}
    >
      {label && <label className="label-new">{label}</label>}
      <input
        disabled={disabled}
        placeholder={placeholder || label}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        style={{
          width: "100%",
          display: "flex",
          height: height || "48px",
          padding: "0px 16px",
          alignItems: "center",
          gap: "2px",
          alignSelf: "stretch",
          borderRadius: "12px",
          border: "1px solid #ebebeb",
          background: "#fff",
          color: "#000",
          fontFamily: "DM Sans",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "20px",
          outline: "none",
          transition: "border-color 0.2s ease-in-out",
        }}
      />
      {right && (
        <Typography
          sx={{
            color: "#757579",
            fontSize: "12px",
            fontWeight: 500,
            fontFamily: "DM Sans",
            position: "absolute",
            right: "12px",
            mt: "10px",
            top: "50%",
            transform: "translateY(-50%)",
          }}
        >
          {right}
        </Typography>
      )}
    </div>
  );
};

const servingNames = [
  {
    id: 1,
    label: "Bowl",
  },
  {
    id: 2,
    label: "Cup",
  },
  {
    id: 3,
    label: "Dash",
  },
  {
    id: 4,
    label: "Drop",
  },
  {
    id: 5,
    label: "Glass",
  },
  {
    id: 6,
    label: "Large",
  },
  {
    id: 7,
    label: "Medium",
  },
  {
    id: 8,
    label: "Mini",
  },
  {
    id: 9,
    label: "Ounce",
  },
  {
    id: 10,
    label: "Pack",
  },
  {
    id: 11,
    label: "Piece",
  },
  {
    id: 12,
    label: "Portion",
  },
  {
    id: 13,
    label: "Pouch",
  },
  {
    id: 14,
    label: "Regular",
  },
  {
    id: 15,
    label: "Scoop",
  },
  {
    id: 16,
    label: "Serving",
  },
  {
    id: 17,
    label: "Slice",
  },
  {
    id: 18,
    label: "Small",
  },
  {
    id: 19,
    label: "Tablet",
  },
  {
    id: 20,
    label: "Tbsp",
  },
  {
    id: 21,
    label: "Tsp",
  },
  {
    id: 22,
    label: "gm",
  },
  {
    id: 23,
    label: "ml",
  },
];
