import React, { useEffect, useState } from "react";

import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Label } from "@/components/ui/label";
import { Separator } from "@/components/ui/separator";
import { Input } from "@/components/ui/input";

const Targets = ({ prevTargets, onAdd }) => {
  const [selectedType, setSelectedType] = useState("");

  const [speed, setSpeed] = useState("");
  const [maxSpeed, setMaxSpeed] = useState("");

  const [cadence, setCadence] = useState("");
  const [maxCadence, setMaxCadence] = useState("");

  const [heartRateZone, setHeartRateZone] = useState("");

  const [customHeartRate, setCustomHeartRate] = useState("");
  const [maxCustomHeartRate, setMaxCustomHeartRate] = useState("");

  const [powerZone, setPowerZone] = useState("");

  const [customPower, setCustomPower] = useState("");
  const [maxCustomPower, setMaxCustomPower] = useState("");

  const [open, setOpen] = useState(false);
  // const { isThresholdPercentChecked,
  //   setIsThresholdPercentChecked,
  //   isChecked,
  //   setIsChecked,
  //   setValue,
  //   value}=useContext(EnduranceContext)

  const isThresholdPercentChecked = false;
  const isChecked = false;

  useEffect(() => {
    if (prevTargets) {
      setSelectedType(prevTargets.type);
      console.log("it is from the target", prevTargets.type);
      switch (prevTargets.type) {
        case "speed":
          setSpeed(prevTargets.value);
          setMaxSpeed(prevTargets.maxValue);
          break;
        case "cadence":
          setCadence(prevTargets.value);
          setMaxCadence(prevTargets.maxValue);
          break;
        case "heartRateZone":
          setHeartRateZone(prevTargets.value);
          break;
        case "customHeartRate":
          setCustomHeartRate(prevTargets.value);
          setMaxCustomHeartRate(prevTargets.maxValue);
          break;
        case "powerZone":
          setPowerZone(prevTargets.value);
          break;
        case "customPower":
          setCustomPower(prevTargets.value);
          setMaxCustomPower(prevTargets.maxValue);
          break;
        default:
          break;
      }
    }
  }, [open]);

  const isValidToSave = () => {
    switch (selectedType) {
      case "speed":
        return speed !== "" && maxSpeed !== "";
      case "cadence":
        return cadence !== "" && maxCadence !== "";
      case "heartRateZone":
        return heartRateZone !== "";
      case "customHeartRate":
        return customHeartRate !== "" && maxCustomHeartRate !== "";
      case "powerZone":
        return powerZone !== "";
      case "customPower":
        return customPower !== "" && maxCustomPower !== "";
      case "thresholdSpeed":
        return speed !== "" && maxSpeed !== "";
      case "thresholdCustomHeartRate":
        return customHeartRate !== "" && maxCustomHeartRate !== "";
      case "thresholdCustomPower":
        return customPower !== "" && maxCustomPower !== "";
      default:
        return false;
    }
  };

  const showInputBasedOnType = () => {
    switch (selectedType) {
      case "speed":
        return (
          <SpeedInput
            value={speed}
            setValue={setSpeed}
            maxValue={maxSpeed}
            setMaxValue={setMaxSpeed}
          />
        );

      case "cadence":
        return (
          <CadenceInput
            value={cadence}
            setValue={setCadence}
            maxValue={maxCadence}
            setMaxValue={setMaxCadence}
          />
        );

      case "heartRateZone":
        return (
          <HeartRateZone value={heartRateZone} setValue={setHeartRateZone} />
        );

      case "customHeartRate":
        return (
          <CustomHeartRateInput
            value={customHeartRate}
            setValue={setCustomHeartRate}
            maxValue={maxCustomHeartRate}
            setMaxValue={setMaxCustomHeartRate}
          />
        );

      case "powerZone":
        return <PowerZoneInput value={powerZone} setValue={setPowerZone} />;

      case "customPower":
        return (
          <CustomPowerInput
            value={customPower}
            setValue={setCustomPower}
            maxValue={maxCustomPower}
            setMaxValue={setMaxCustomPower}
          />
        );
      case "thresholdCustomPower":
        return (
          <CustomPowerInput
            value={customPower}
            setValue={setCustomPower}
            maxValue={maxCustomPower}
            setMaxValue={setMaxCustomPower}
            isChecked={isChecked}
          />
        );
      case "thresholdCustomHeartRate":
        return (
          <CustomHeartRateInput
            value={customHeartRate}
            setValue={setCustomHeartRate}
            maxValue={maxCustomHeartRate}
            setMaxValue={setMaxCustomHeartRate}
            isChecked={isChecked}
          />
        );
      case "thresholdSpeed":
        return (
          <SpeedInput
            value={speed}
            setValue={setSpeed}
            maxValue={maxSpeed}
            setMaxValue={setMaxSpeed}
            isChecked={isChecked}
          />
        );
      default:
        return null;
    }
  };

  const onSave = () => {
    let primaryTarget = {};
    switch (selectedType) {
      case "speed":
        primaryTarget = {
          type: "speed",
          value: speed,
          maxValue: maxSpeed,
        };
        break;
      case "cadence":
        primaryTarget = {
          type: "cadence",
          value: cadence,
          maxValue: maxCadence,
        };
        break;
      case "heartRateZone":
        primaryTarget = {
          type: "heartRateZone",
          value: heartRateZone,
        };
        break;
      case "customHeartRate":
        primaryTarget = {
          type: "customHeartRate",
          value: customHeartRate,
          maxValue: maxCustomHeartRate,
        };
        break;
      case "powerZone":
        primaryTarget = {
          type: "powerZone",
          value: powerZone,
        };
        break;
      case "customPower":
        primaryTarget = {
          type: "customPower",
          value: customPower,
          maxValue: maxCustomPower,
        };
        break;
      case "thresholdSpeed":
        primaryTarget = {
          type: "thresholdSpeed",
          value: speed,
          maxValue: maxSpeed,
        };
        break;
      case "thresholdCustomPower":
        primaryTarget = {
          type: "thresholdCustomPower",
          value: customPower,
          maxValue: maxCustomPower,
        };
        break;
      case "thresholdCustomHeartRate":
        primaryTarget = {
          type: "thresholdCustomHeartRate",
          value: customHeartRate,
          maxValue: maxCustomHeartRate,
        };
        break;
      default:
        break;
    }
    if (isValidToSave()) {
      console.log("endurance: target", primaryTarget);
      onAdd(primaryTarget);
    }
  };

  // call save when all inputs are valid
  useEffect(() => {
    if (isValidToSave()) {
      onSave();
    }
  }, [
    cadence,
    maxCadence,
    heartRateZone,
    customHeartRate,
    maxCustomHeartRate,
    powerZone,
    customPower,
    maxCustomPower,
    speed,
    maxSpeed,
  ]);

  const percentageOptions = [
    { label: "% Threshold Speed", value: "thresholdSpeed" },
    { label: "% Threshold Heart Rate", value: "thresholdCustomHeartRate" },
    { label: "% Threshold Power", value: "thresholdCustomPower" },
  ];

  const option = [
    { label: "Speed", value: "speed" },
    { label: "Bike Cadence", value: "cadence" },
    { label: "Heart Rate Zone", value: "heartRateZone" },
    { label: "Custom Heart Rate", value: "customHeartRate" },
    { label: "Power Zone", value: "powerZone" },
    { label: "Custom Power", value: "customPower" },
  ];

  return (
    <div>
      <div className="flex flex-col gap-3">
        <div className="flex flex-col gap-2">
          <div>
            {/*    <FormControl>*/}
            {/*<FormLabel id="demo-radio-buttons-group-label" */}
            {/*            >Type</FormLabel>*/}
            {/*      <RadioGroup*/}
            {/*      aria-labelledby="demo-radio-buttons-group-label"*/}
            {/*       row */}
            {/*      value={value}*/}
            {/*      onChange={(e)=>{ */}
            {/*       setValue(e.target.value)*/}
            {/*      */}
            {/*       if(e.target.value==="percent"){*/}
            {/*        setIsChecked(true)*/}
            {/*        setIsThresholdPercentChecked(true)*/}
            {/*       }else{*/}
            {/*        setIsChecked(false)*/}
            {/*        setIsThresholdPercentChecked(false)*/}
            {/*       }*/}
            {/*       console.log(e.target.value,isChecked,isThresholdPercentChecked,value)*/}
            {/*      }}>*/}
            {/*   */}
            {/*  <FormControlLabel value="range"   control={<Radio color="royalBlue"/>} label="Range" />*/}
            {/*  <FormControlLabel value="percent"   control={<Radio color="royalBlue"/>} label="Percent" />*/}
            {/*     </RadioGroup>*/}
            {/*    </FormControl> */}
          </div>
          <Select
            value={selectedType}
            onValueChange={(value) => setSelectedType(value)}
            id="type"
            className="z-[9999]"
          >
            <SelectTrigger className="w-[220px] focus:ring-transparent rounded-xl">
              <SelectValue className="text-font14" placeholder="No Target" />
            </SelectTrigger>
            <SelectContent className="z-[9999] text-font14">
              <SelectItem value="speed">Speed</SelectItem>
              <SelectItem value="cadence">Bike Cadence</SelectItem>
              <SelectItem value="heartRateZone">Heart Rate Zone</SelectItem>
              <SelectItem value="customHeartRate">Custom Heart Rate</SelectItem>
              <SelectItem value="powerZone">Power Zone</SelectItem>
              <SelectItem value="customPower">Custom Power</SelectItem>
            </SelectContent>
          </Select>
        </div>
        {showInputBasedOnType()}
      </div>
    </div>
  );
};

const SpeedInput = ({ value, setValue, maxValue, setMaxValue }) => {
  return (
    <div className="flex flex-col gap-2">
      <Label htmlFor="speed" className="font-DMSans text-font14">
        Speed
      </Label>
      <div className="flex gap-2 items-center justify-center">
        <Input
          className="focus-visible:ring-transparent text-font14"
          type="number"
          id="speed"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <span className="font-DMSans">to</span>
        <Input
          className="focus-visible:ring-transparent text-font14"
          type="number"
          id="maxSpeed"
          value={maxValue}
          onChange={(e) => setMaxValue(e.target.value)}
        />
        <span className="font-DMSans text-font14">mph</span>
      </div>
    </div>
  );
};

const CadenceInput = ({ value, setValue, maxValue, setMaxValue }) => {
  // input cadence range
  return (
    <div className="flex flex-col gap-2">
      <Label htmlFor="cadence" className="font-DMSans">
        Cadence
      </Label>
      <div className="flex gap-2 items-center justify-center">
        <Input
          className="focus-visible:ring-transparent text-font14"
          type="number"
          id="cadence"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <span className="font-DMSans">to</span>
        <Input
          className="focus-visible:ring-transparent text-font14"
          type="number"
          id="maxCadence"
          value={maxValue}
          onChange={(e) => setMaxValue(e.target.value)}
        />
        <span className="font-DMSans">rpm</span>
      </div>
      {/* unit = spm */}
    </div>
  );
};

const heartRateZoneOptions = [
  "Heart Rate Zone 1 (93-111 bpm)",
  "Heart Rate Zone 2 (111-130 bpm)",
  "Heart Rate Zone 3 (130-148 bpm)",
  "Heart Rate Zone 4 (148-167 bpm)",
  "Heart Rate Zone 5 (167-185 bpm)",
];
const HeartRateZone = ({ value, setValue }) => {
  return (
    <div className="flex flex-col gap-2">
      <Label htmlFor="heartRateZone" className="font-DMSans text-font14">
        Heart Rate Target
      </Label>
      <Select
        id="heartRateZone"
        value={value}
        onValueChange={(value) => setValue(value)}
      >
        <SelectTrigger className="w-[320px] focus:ring-transparent">
          <SelectValue className="text-font14" placeholder="Select Zone" />
        </SelectTrigger>
        <SelectContent className="z-[9999]">
          {heartRateZoneOptions.map((option, index) => (
            <SelectItem className="text-font14" value={index + 1}>
              {option}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
};

const CustomHeartRateInput = ({ value, setValue, maxValue, setMaxValue }) => {
  // custom heart rate range in bpm
  return (
    <div className="flex flex-col gap-2">
      <Label htmlFor="customHeartRate" className="font-DMSans text-font14">
        Target
      </Label>
      <div className="flex gap-2 items-center justify-center">
        <Input
          className="focus-visible:ring-transparent text-font14"
          type="number"
          id="customHeartRate"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <span className="font-DMSans">to</span>
        <Input
          className="focus-visible:ring-transparent text-font14"
          type="number"
          id="maxCustomHeartRate"
          value={maxValue}
          onChange={(e) => setMaxValue(e.target.value)}
        />
        <span className="font-DMSans text-font14">bpm</span>
      </div>
    </div>
  );
};

const powerZoneOptions = [
  "Power Zone 1 (0-110 W)",
  "Power Zone 2 (110-150 W)",
  "Power Zone 3 (150-180 W)",
  "Power Zone 4 (180-210 W)",
  "Power Zone 5 (210-240 W)",
  "Power Zone 6 (240-300 W)",
  "Power Zone 7 (over 300 W)",
];
const PowerZoneInput = ({ value, setValue }) => {
  return (
    <div className="flex flex-col gap-2">
      <Label htmlFor="powerZone" className="font-DMSans text-font14">
        Power Target
      </Label>
      <Select
        id="powerZone"
        value={value}
        onValueChange={(value) => setValue(value)}
      >
        <SelectTrigger className="w-[320px] focus:ring-transparent">
          <SelectValue className="text-font14" placeholder="Select Zone" />
        </SelectTrigger>
        <SelectContent className="z-[9999]">
          {powerZoneOptions.map((option, index) => (
            <SelectItem className="text-font14" value={index + 1}>
              {option}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
};

const CustomPowerInput = ({ value, setValue, maxValue, setMaxValue }) => {
  // custom power range in watts
  return (
    <div className="flex flex-col gap-2">
      <Label htmlFor="customPower" className="font-DMSans text-font14">
        Target
      </Label>
      <div className="flex gap-2 items-center justify-center">
        <Input
          className="focus-visible:ring-transparent text-font14"
          type="number"
          id="customPower"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <span className="font-DMSans">to</span>
        <Input
          className="focus-visible:ring-transparent text-font14"
          type="number"
          id="maxCustomPower"
          value={maxValue}
          onChange={(e) => setMaxValue(e.target.value)}
        />
        <span className="font-DMSans text-font14">watts</span>
      </div>
    </div>
  );
};
export default Targets;
