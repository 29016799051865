import { CloseRounded } from "@mui/icons-material";
import { Dialog, DialogActions } from "@mui/material";
import { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

// import { createWorkout, updateWorkout } from "../programsCF/helpers";
import axios from "axios";
import moment from "moment";
import { assignWorkout } from "../../ApiServices/Workouts/Api";
import {
  createWorkout,
  updateWorkout,
} from "../../pages/library/programsCF/helpers";
import { SpurfitCircularProgress } from "../Dashboard/dashboard";
import TextArea from "../InputComponents/TextArea";

const isCardio = (workoutId) => {
  if (typeof workoutId === "string") {
    return workoutId.includes("cardio_workout");
  }
  return false;
};

const AssignCardioModal = ({
  isOpen,
  setIsOpen,
  onAssign,
  info,
  userUUID,
  startDate,
  userProfile,
  onClose,
}) => {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const coachID = useSelector((state) => state.auth?.trainerUUID);
  const [cardioDescription, setCardioDescription] = useState(
    info?.description || ""
  );
  const [loading, setLoading] = useState(false);
  const [workoutIdsWithoutCardio, setWorkoutIdsWithoutCardio] = useState([]);
  const [workoutAssignedID, setWorkoutAssignedID] = useState(null);

  const deleteAssignedCardioWorkout = async (cardioID) => {
    await axios({
      method: "post",
      url: `${APIURL}CF/api/deleteAssignedWorkoutAthelete`,
      data: {
        assignedWorkoutID: workoutAssignedID,
        newOrderWorkoutIDs: workoutIdsWithoutCardio,
      },
    });
  };

  useEffect(() => {
    if (userProfile) {
      let date = moment(startDate, "DD-MM-YYYY").format("YYYY-MM-DD");
      let todaysWorkouts = userProfile?.currentMonthmodules?.[date];
      let workoutWithoutCardio;
      todaysWorkouts?.forEach((workout) => {
        if (!isCardio(workout?.workoutAssigned?.id)) {
          workoutWithoutCardio = [...(workoutWithoutCardio ?? []), workout];
        } else {
          setWorkoutAssignedID(workout?.id);
        }
      });
      let workoutIDs =
        workoutWithoutCardio?.map((workout) => workout?.id) ?? [];
      setWorkoutIdsWithoutCardio(workoutIDs);
    }
  }, [userProfile]);

  const assignCardio = async ({ workoutID }) => {
    let date = moment(startDate, "DD-MM-YYYY").toDate();
    await assignWorkout({
      workoutID,
      userUUID,
      startDate: date,
    })
      .then((res) => {
        console.log("assign cardio");
      })
      .catch((err) => {
        console.log("error in assign cardio");
      });
  };

  const onSave = async () => {
    setLoading(true);
    let newCardioWorkout = { ...cardioWorkout };

    newCardioWorkout.description = cardioDescription;
    if (cardioDescription.length > 0) {
      if (!info || info.id === "cardio_workout" || info?.rest) {
        let newID = `cardio_workout_${uuidv4()}`;

        newCardioWorkout.id = newID;
        await createWorkout({
          workout: {
            workoutInfo: {
              id: newID,
              description: cardioDescription,
              name: "cardio",
              numRounds: 1,
              coachID,
            },
            workoutContent: cardioWorkout.workoutContent,
          },
        });
        if (info?.id === "cardio_workout") {
          await deleteAssignedCardioWorkout(info?.id);
        }
        await assignCardio({ workoutID: newCardioWorkout.id });
      } else {
        await updateWorkout({
          workoutID: info?.id,
          workoutInfo: {
            description: cardioDescription,
            name: "cardio",
            numRounds: 1,
            coachID,
            // isCoachWorkout: false,
          },
          workoutContent: cardioWorkout.workoutContent,
        });
      }
    } else {
      await assignCardio({ workoutID: newCardioWorkout.id });
    }
    onAssign(newCardioWorkout);
    setIsOpen(false);
    setLoading(false);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => onClose()}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "24px",
        },
      }}
    >
      {loading && (
        <div className="absolute h-full w-full flex items-center justify-center">
          <SpurfitCircularProgress />
        </div>
      )}
      <div className="add-program-modal-head">
        <div style={{ display: "flex", alignItems: "flex-end", gap: "8px" }}>
          <p className="add-program-modal-head-title">Cardio Instruction</p>
        </div>
        <div
          onClick={() => {
            onClose();
          }}
          className="cursor-pointer"
        >
          <CloseRounded
            sx={{
              fontSize: "24px",
              color: "#000",
              cursor: "pointer",
            }}
          />
        </div>
      </div>
      <div className="add-program-modal-body">
        <TextArea
          id={"cardioDescription"}
          value={cardioDescription}
          onChange={(e) => setCardioDescription(e.target.value)}
          label={"Instructions"}
          placeholder={"Do 1 hour walk... or Do a 30 min jog..."}
        />
      </div>
      <DialogActions
        sx={{
          justifyContent: "center",
        }}
      >
        {cardioDescription.length > 0 ? (
          <button className="button-new" onClick={onSave}>
            {info?.description ? "Update" : "Save"}
          </button>
        ) : (
          <button
            style={{
              borderWidth: 2,
              borderRadius: 100,
              width: "148px",
              height: "44px",
            }}
            className="button-new-outlined"
            onClick={onSave}
          >
            Skip
          </button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AssignCardioModal;

const cardioWorkout = {
  id: "cardio_workout",
  name: "cardio",
  numRounds: 1,
  runTime: 0,
  restTime: 0,
  visibility: false,
  benchMark: false,
  expectedCalories: 0,
  equipment: null,
  bodyPart: null,
  description: null,
  instructions: null,
  coachID: "PXqGTqMrfblJ",
  workoutType: "NOSCORE",
  workoutKind: null,
  tag: null,
  dateCreated: null,
  workoutContent: {
    rounds: [
      {
        round: {
          name: "",
          numRounds: 1,
          measurements: [
            {
              measurement: {
                movementName: "Do cardio of your choice",
              },
            },
          ],
        },
      },
    ],
  },
  videoLinks: null,
  isCoachWorkout: true,
};
