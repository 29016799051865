import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DoneIcon from "@mui/icons-material/Done";
import RemoveRounedIcon from "@mui/icons-material/RemoveRounded";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import axios from "axios";
import { X } from "lucide-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTrainerData } from "../../redux/authSlice";

const SelectFeatForOptions = ['water']
const units = ["ltr", "oz", "ml"]

export default function AddActivityDialog({
  isOpen,
  setIsOpen,
  isUpdate,
  assignedActivities,
  onSaveActivity,
  onRemoveActivity,
  comingFrom,
}) {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const dispatch = useDispatch();
  const trainerUUID = useSelector((state) => state.auth.trainerUUID);
  const oneTrainerData = useSelector((state) => state.auth?.data);
  const [totalDays, setTotalDays] = useState(1);
  const [showWeekDropDown, setShowWeekDropDown] = useState(false);
  const [activityTypes, setActivityTypes] = useState([
    "dailyTarget",
    "mindfulness",
    "sleep",
  ]);
  const [checked, setChecked] = useState(false);
  const [activityOptions, setActivityOptions] = useState({
    dailyTarget: ["water", "steps", "sleep"],
    mindfulness: ["Prioritize self-care", "Celebrate the wins"],
    sleep: ["Digital detox before bed", "Practice a bedtime ritual"],
    atSettingCustom: [],
  });

  const [selectedActivityType, setSelectedActivityType] =
    useState("dailyTarget");
  const [tempCustomActivity, setTempCustomActivity] = useState("");
  const [selectedActivities, setSelectedActivities] = useState({});
  const [customActivityOptions, setCustomActivityOptions] = useState({});
  const [newlyAddedCustomHabitType, setNewlyAddedCustomHabitType] =
    useState("");
  const [isClickedAddCustomTab, setIsClickedAddCustomTab] = useState(false);
  const [sortedArray, setSortedArray] = useState([]);
  const [showWarning, setShowWarning] = useState(false);
  const [showWarningForAddActivity, setShowWarningForAddActivity] =
    useState(false);
  const [isSelectedActivityShow, setIsSelectedActivityShow] = useState(false);
  const [dayOrWeek, setDayOrWeek] = useState("day");
  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  // for dailyTarget activities
  const [values, setValues] = useState({
    water: { value: "", unit: "ltr" },
    steps: { value: "", unit: "" },
    sleep: { value: "", unit: "hrs" },
  });

  console.log({ values })

  console.log("this is the selected", selectedActivities);
  useEffect(() => {
    if (assignedActivities) {
      const { dailyTargets, habbits } = assignedActivities;
      const newSelectedActivities = {};
      const newValues = { ...values };

      if (habbits) {
        // set selectedActivities state
        Object.keys(habbits).forEach((key) => {
          newSelectedActivities[key] = habbits[key]?.map(
            (activity) => activity.todo
          );
        });
        console.log("newSelectedActivities", newSelectedActivities);
        setSelectedActivities(newSelectedActivities);
      }
      if (dailyTargets) {
        // set values state
        Object.keys(dailyTargets).forEach((key) => {
          newValues[key].value = dailyTargets[key].todo;
        });
        setValues(newValues);
      }
    }
  }, [assignedActivities]);

  const updateCustomActivities = async () => {
    try {
      const res = await axios({
        method: "POST",
        url: `${APIURL}CF/api/CoachWeb/Update/UpdateCoachFields`,
        data: {
          coachID: trainerUUID,
          customActivities: activityOptions,
        },
      });
      dispatch(setTrainerData(res?.data?.result));
      return res?.data?.result;
    } catch (error) { }
  };
  const handleAddOption = (option, selectedActivity) => {
    console.log("this is from handleAddOption", option);
    // if option is from dailyTarget
    if (activityOptions.dailyTarget.includes(option)) {
      let dailyTarget = selectedActivities.dailyTarget || [];
      if (dailyTarget.includes(option)) {
        setSelectedActivities({
          ...selectedActivities,
          dailyTarget: dailyTarget.filter((item) => item !== option),
        });
      } else {
        setSelectedActivities({
          ...selectedActivities,
          dailyTarget: [...dailyTarget, option],
        });
      }
    }

    // if option is from mindfulness
    if (activityOptions.mindfulness.includes(option)) {
      let mindfulness = selectedActivities.mindfulness || [];
      if (mindfulness.includes(option)) {
        setSelectedActivities({
          ...selectedActivities,
          mindfulness: mindfulness.filter((item) => item !== option),
        });
      } else {
        setSelectedActivities({
          ...selectedActivities,
          mindfulness: [...mindfulness, option],
        });
      }
    }

    // if option is from sleep
    if (activityOptions.sleep.includes(option)) {
      let sleep = selectedActivities.sleep || [];
      if (sleep.includes(option)) {
        setSelectedActivities({
          ...selectedActivities,
          sleep: sleep.filter((item) => item !== option),
        });
      } else {
        setSelectedActivities({
          ...selectedActivities,
          sleep: [...sleep, option],
        });
      }
    }
    console.log(
      "this is from handleAddOption",
      activityOptions,
      selectedActivityType,
      option
    );
    // if option is from some custom habit
    if (activityOptions[selectedActivityType]?.includes(option)) {
      console.log("this is from handleAddOption");
      let customActivity = selectedActivities[selectedActivityType] || [];
      console.log("this is from handleAddOption", customActivity);
      if (customActivity.includes(option)) {
        setSelectedActivities({
          ...selectedActivities,
          [selectedActivityType]: customActivity.filter(
            (item) => item !== option
          ),
        });
      } else {
        setSelectedActivities({
          ...selectedActivities,
          [selectedActivityType]: [...customActivity, option],
        });
      }
    }
    if (selectedActivity !== "" && selectedActivity !== undefined) {
      if (activityOptions[selectedActivity]?.includes(option)) {
        console.log("this is from handleAddOption");
        let customActivity = selectedActivities[selectedActivity] || [];
        console.log("this is from handleAddOption", customActivity);
        if (customActivity.includes(option)) {
          setSelectedActivities({
            ...selectedActivities,
            [selectedActivity]: customActivity.filter(
              (item) => item !== option
            ),
          });
        } else {
          setSelectedActivities({
            ...selectedActivities,
            [selectedActivity]: [...customActivity, option],
          });
        }
      } else if (activityOptions.hasOwnProperty(selectedActivity)) {
        let customActivity = selectedActivities[selectedActivity] || [];
        if (customActivity.includes(option)) {
          setSelectedActivities({
            ...selectedActivities,
            [selectedActivity]: customActivity.filter(
              (item) => item !== option
            ),
          });
        } else {
          setSelectedActivities({
            ...selectedActivities,
            [selectedActivity]: [...customActivity, option],
          });
        }
      }
    }
  };

  const isActivityOptionSelected = (option) => {
    if (activityOptions?.dailyTarget?.includes(option)) {
      return selectedActivities?.dailyTarget?.includes(option);
    }

    if (activityOptions?.mindfulness?.includes(option)) {
      return selectedActivities?.mindfulness?.includes(option);
    }

    if (activityOptions?.sleep?.includes(option)) {
      return selectedActivities?.sleep?.includes(option);
    }
    console.log(
      "what is option",
      option,
      selectedActivities[selectedActivityType]
    );
    if (activityOptions[selectedActivityType]?.includes(option)) {
      console.log(selectedActivities[selectedActivityType]?.includes(option));
      return selectedActivities[selectedActivityType]?.includes(option);
    }
  };

  const handleAddingCustomHabitToOptions = () => {
    //console.log("handleAddingCustomHabitToOptions",activityOptions)
    let tempActivityOptions = { ...activityOptions };
    if (tempCustomActivity === "") {
      setShowWarningForAddActivity(true);
      return;
    }
    tempActivityOptions[selectedActivityType] = [
      ...(tempActivityOptions[selectedActivityType] || []),
      tempCustomActivity,
    ];

    setActivityOptions(tempActivityOptions);

    let tempCustomActivityOptions = { ...customActivityOptions };
    console.log("temp cust", tempCustomActivityOptions);
    if (selectedActivityType in tempCustomActivityOptions) {
      console.log("yaay");
      tempCustomActivityOptions[selectedActivityType] = [
        ...tempCustomActivityOptions[selectedActivityType],
        tempCustomActivity,
      ];
    } else {
      console.log("naay");
      tempCustomActivityOptions[selectedActivityType] = [tempCustomActivity];
    }
    console.log("temp cust 2", tempCustomActivityOptions);
    setCustomActivityOptions(tempCustomActivityOptions);
    setTempCustomActivity("");
  };
  const onSave = async () => {
    let dailyTargets = {};
    let habbits = {};

    Object.keys(values).forEach((key) => {
      if (values[key]?.value && values[key].value !== "") {
        const valueAsNumber = parseInt(values[key].value);
        selectedActivities;
        if (!isNaN(valueAsNumber)) {
          dailyTargets[key] = {
            todo: valueAsNumber,
            completed: false,
          };
        }
      }
    });

    console.log("dailyTargets: ", dailyTargets);
    console.log("selectedActivities: ");

    Object.keys(selectedActivities).forEach((key) => {
      let currentActivity = [];
      selectedActivities[key]?.forEach((activity) => {
        currentActivity.push({
          todo: activity,
          completed: false,
        });
      });
      habbits[key] = currentActivity;
    });

    const activitiesAssigned = {
      dailyTargets,
      habbits,
    };

    console.log("activitiesAssigned: ", activitiesAssigned);
    // send activitiesAssigned to backend
    let day = dayOrWeek === "day" ? totalDays : +totalDays * 7;
    onSaveActivity(activitiesAssigned, day);
    const result = await updateCustomActivities();
    console.log("result from custom activity", result);
    setIsOpen(false);
  };

  const handleAddCustom = () => {
    if (newlyAddedCustomHabitType === "") {
      setShowWarning(true);
      return;
    }
    const updatedActivityTypes = [...activityTypes, newlyAddedCustomHabitType];
    setActivityTypes(updatedActivityTypes);

    const updatedActivityOptions = {
      ...activityOptions,
      [newlyAddedCustomHabitType]: [],
    };
    setActivityOptions(updatedActivityOptions);
    setSelectedActivityType(newlyAddedCustomHabitType);
    // Reset the state of isClickedAddCustomTab and newlyAddedCustomHabitType
    setIsClickedAddCustomTab(false);
    setNewlyAddedCustomHabitType("");
  };

  const handleCloseModal = async () => {
    const result = await updateCustomActivities();
    console.log("result from custom activity", result);
    setIsOpen(false);
    // setShowWeekDropDown(false);
  };

  useEffect(() => {
    if (oneTrainerData) {
      console.log("one trainer data", assignedActivities);
      if (assignedActivities) {
        if (Object.entries(assignedActivities)?.length === 0) {
          setShowWeekDropDown(true);
        } else if (
          Object.entries(assignedActivities?.dailyTargets)?.length === 0 &&
          Object.entries(assignedActivities?.habbits)?.length === 0
        ) {
          setShowWeekDropDown(true);
        } else {
          setShowWeekDropDown(false);
        }
      } else {
        setShowWeekDropDown(true);
      }
      // Copy the current state to avoid mutation
      let tempSortedArray = [];
      console.log(
        "nnn",
        Object.keys(assignedActivities?.dailyTargets || {})?.length
      );
      if (Object.keys(assignedActivities?.dailyTargets || {})?.length > 0) {
        tempSortedArray.push("dailyTarget");
      }
      tempSortedArray = [
        ...tempSortedArray,
        ...Object.keys(assignedActivities?.habbits || {})?.filter(
          (item) => assignedActivities?.habbits?.[item]?.length > 0
        ),
      ];
      let tempActivityOptions = { ...activityOptions };
      let tempActivityTypes = [...activityTypes]; // Assuming activityTypes is an array
      let tempCustomActivityOptions = {};
      if (oneTrainerData?.customActivities) {
        Object.keys(oneTrainerData.customActivities).forEach((key) => {
          if (!tempSortedArray?.includes(key)) {
            tempSortedArray?.push(key);
          }
          tempActivityTypes.push(key);
          // Update tempActivityOptions immutably
          tempActivityOptions = {
            ...tempActivityOptions,
            [key]: oneTrainerData.customActivities[key],
          };
          tempCustomActivityOptions[key] =
            oneTrainerData?.customActivities[key];
        });
      }
      activityTypes?.forEach((option) => {
        if (!tempSortedArray?.includes(option)) {
          tempSortedArray?.push(option);
        }
      });
      console.log("tt", tempActivityOptions, tempCustomActivityOptions);
      // Update state with the new values
      setSortedArray(tempSortedArray);
      setCustomActivityOptions(tempCustomActivityOptions);
      setActivityTypes(tempSortedArray);
      setActivityOptions(tempActivityOptions);
      if (tempSortedArray?.length > 0) {
        console.log("goes here");
        setSelectedActivityType(tempSortedArray[0]);
      }
      console.log(
        "tempp",
        tempActivityOptions,
        tempActivityTypes,
        tempCustomActivityOptions
      );
    }
  }, [oneTrainerData]);

  function handleDeleteIndividualActivity(
    activityOption,
    selectedActivityType
  ) {
    console.log("this is handleDelete", activityOption, selectedActivityType);
    let newActivityOptions = { ...activityOptions };
    const newData = newActivityOptions?.[selectedActivityType]?.filter(
      (data) => data !== activityOption
    );
    if (newData.length > 0) {
      newActivityOptions[selectedActivityType] = newData;
    } else {
      delete newActivityOptions[selectedActivityType];
    }
    //console.log("this is handleDelete",isActivityOptionSelected(activityOption))
    setActivityOptions(newActivityOptions);
    if (isActivityOptionSelected(activityOption)) {
      handleAddOption(activityOption);
    }
  }

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      PaperProps={{
        sx: {
          borderRadius: "20px",
          minWidth: "700px",
        },
      }}
    >
      <div className="add-program-modal-head">
        <p className="add-program-modal-head-title">Add Activity</p>
        <div onClick={handleCloseModal} className="cursor-pointer">
          <CloseRoundedIcon />
        </div>
      </div>
      <DialogContent
        style={{
          padding: "10px 24px 0px 24px",
        }}
      >
        <div>
          <div
            className={`cursor-pointer py-2 px-4 rounded-full underline text-blue-500 hover:bg-gray-300  mb-2   font-DMSans w-[33%]`}
            style={{
              backgroundColor:
                selectedActivityType === "" ? "#f1ebfd" : "#ffffff",
            }}
            onClick={() => {
              setSelectedActivityType("");
              setIsSelectedActivityShow(true);
            }}
          >
            Selected Activities
          </div>
          <div className="h-[1px] w-full bg-[#ebebeb] mb-2"></div>
        </div>

        <div className="flex">
          {/* the left div where all the acitivity types are scrollable */}
          <div className="w-[40%]">
            <div
              className="flex flex-col border-r border-gray-200 pr-4"
              style={{
                height: "280px",
                overflowY: "scroll",
              }}
            >
              {activityTypes?.map((activityType) => (
                <div
                  key={activityType}
                  onClick={() => {
                    setIsSelectedActivityShow(false);
                    setSelectedActivityType(activityType);
                  }}
                  className={`cursor-pointer py-3 px-4 rounded-md mb-2  font-DMSans`}
                  style={{
                    background:
                      selectedActivityType === activityType
                        ? "#f1ebfd"
                        : "#FFFFFF",
                    color:
                      selectedActivityType === activityType
                        ? "#000000"
                        : "#BDBDBD",
                    textTransform: "capitalize",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {activityType}
                  {selectedActivityType === activityType && (
                    <div className="filter-modal-sidebar-item-active-indicator"></div>
                  )}
                </div>
              ))}
            </div>
            {/* custom habit adding feature */}
            {isClickedAddCustomTab ? (
              <div
                className={`cursor-pointer py-2 px-4 rounded-full mb-2 mt-3 font-DMSans`}
                style={{
                  background: "#FFFFFF",
                  color: "black",
                  textTransform: "capitalize",
                }}
              >
                <div className="flex flex-col items-center">
                  <div>
                    <input
                      type="text"
                      placeholder="habit type.."
                      style={{
                        width: "100px",
                        outline: "none",
                      }}
                      onChange={(event) => {
                        setShowWarning(false);
                        setNewlyAddedCustomHabitType(event?.target?.value);
                      }}
                      value={newlyAddedCustomHabitType}
                    />
                    <DoneIcon onClick={handleAddCustom} />
                  </div>
                  {showWarning && (
                    <p className="text-red-500 text-[12px]">
                      Fill the input box first
                    </p>
                  )}
                </div>
              </div>
            ) : (
              <div
                onClick={() => {
                  setIsClickedAddCustomTab(true);
                  setSelectedActivityType("atSettingCustom");
                }}
                className={`cursor-pointer py-2 px-4 rounded-full mb-2 mt-3 font-DMSans`}
                style={{
                  background: "#FFFFFF",
                  color: "#5E42EA",
                  textTransform: "capitalize",
                  fontStyle: "DM Sans",
                  fontWeight: "500",
                  textDecoration: "underline",
                }}
              >
                + Add Custom
              </div>
            )}
          </div>

          {/* right block where the data is shown to add todo habbits items */}
          {!isSelectedActivityShow && (
            <div
              className="flex flex-col ml-4 pr-8 pl-4"
              style={{
                height: "280px",
                width: "60%",
              }}
            >
              {selectedActivityType === "dailyTarget" ? (
                <DailyTargetActivities
                  activityOption={activityOptions[selectedActivityType]}
                  values={values}
                  setValues={setValues}
                />
              ) : selectedActivityType != "mindfulness" &&
                selectedActivityType != "sleep" ? (
                <div>
                  <div
                    style={{
                      borderRadius: "12px",
                      marginBottom: "8px",
                    }}
                  >
                    <div
                      style={{
                        borderRadius: "12px",
                        background: "#f1f1f1",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="text"
                        placeholder="Add activity"
                        style={{
                          outline: "none",
                          background: "#f1f1f1",
                          margin: "1px 8px",
                          width: "100%",
                          fontSize: "16px",
                          fontFamily: "DM Sans",
                        }}
                        value={tempCustomActivity}
                        onChange={(e) => {
                          setShowWarningForAddActivity(false);
                          setTempCustomActivity(e.target.value);
                        }}
                      />
                      <button
                        style={{
                          background:
                            "linear-gradient(90deg, #464feb 10.42%, #8330e9 100%)",
                          borderTopRightRadius: "12px",
                          borderBottomRightRadius: "12px",
                          padding: "4px",
                        }}
                        onClick={handleAddingCustomHabitToOptions}
                      >
                        <AddRoundedIcon
                          style={{
                            color: "white",
                          }}
                        />
                      </button>
                    </div>
                    {showWarningForAddActivity && (
                      <p className="text-red-300 text-[12px] ml-1">
                        Please fill the input box first
                      </p>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      overflowY: "auto",
                      marginTop: "12px",
                      height: "200px",
                    }}
                  >
                    {activityOptions[selectedActivityType]?.map(
                      (activityOption) => (
                        <div
                          className=" flex justify-between  "
                          style={{
                            width: "60%",
                            borderBottom: "1px solid #ebebeb",
                            marginBottom: "12px",
                          }}
                        >
                          <div
                            key={activityOption}
                            className={`cursor-pointerfont-DMSans`}
                            style={{
                              background: "#FFFFFF",
                              color: isActivityOptionSelected(activityOption)
                                ? "#000000"
                                : "#BDBDBD",
                              display: "flex",
                              alignItems: "center",
                              // marginBottom: "12px",
                              // borderBottom:"1px solid #ebebeb",
                            }}
                            onClick={() => handleAddOption(activityOption)}
                          >
                            {isActivityOptionSelected(activityOption) ? (
                              <CheckBoxRoundedIcon
                                style={{
                                  color: "royalBlue",
                                  cursor: "pointer",
                                  marginRight: "3px",
                                }}
                              />
                            ) : (
                              <CheckBoxOutlineBlankRoundedIcon
                                style={{
                                  color: "#BDBDBD",
                                  cursor: "pointer",
                                  marginRight: "3px",
                                }}
                              />
                            )}
                            {activityOption}
                          </div>
                          {/* <div className="text-red-500 align-right]"
                         onClick={()=>{handleDeleteIndividualActivity(activityOption,selectedActivityType)}}>  <Trash2 className="h-[20px] w-[20px]"/></div> */}
                        </div>
                      )
                    )}
                  </div>
                </div>
              ) : (
                activityOptions[selectedActivityType]?.map((activityOption) => (
                  <div
                    key={activityOption}
                    className={`cursor-pointer py-2 px-4 rounded-full mb-2 font-DMSans`}
                    style={{
                      background: "#FFFFFF",
                      color: isActivityOptionSelected(activityOption)
                        ? "#000000"
                        : "#BDBDBD",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={() => handleAddOption(activityOption)}
                  >
                    {isActivityOptionSelected(activityOption) ? (
                      <RemoveRounedIcon
                        style={{ color: "#BDBDBD", cursor: "pointer" }}
                      />
                    ) : (
                      <AddRoundedIcon
                        style={{ color: "#BDBDBD", cursor: "pointer" }}
                      />
                    )}
                    {activityOption}
                  </div>
                ))
              )}
            </div>
          )}

          {/* right block for the selected Activities data */}

          {isSelectedActivityShow && (
            <div
              className="flex flex-col gap-2 ml-4 overflow-y-scroll px-4"
              style={{
                height: "280px",
                width: "50%",
              }}
            >
              {Object.keys(selectedActivities)?.map((key) => (
                <div key={key}>
                  <Typography variant="h6">
                    {selectedActivities?.[key]?.length > 0 ? key : null}
                  </Typography>
                  <div className="flex flex-col gap-1 flex-wrap">
                    {selectedActivities?.[key]?.map((activity, index) => (
                      <div
                        key={activity}
                        className={`cursor-pointer py-1   rounded-full  font-DMSans`}
                        style={{
                          background: "#FFFFFF",

                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <X
                          className="text-[#BDBDBD] h-[20px] w-[20px] mr-8"
                          onClick={() => {
                            handleAddOption(activity, key);
                          }}
                        />
                        {/* <RemoveRounedIcon style={{ color: "#BDBDBD", cursor: "pointer" }}/> */}

                        {activity}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "space-between",
          px: "24px",
        }}
      >
        <button
          className="button-new-outlined"
          style={{
            width: "100px",
            borderRadius: "20px",
          }}
          onClick={() => {
            if (isUpdate) {
              onRemoveActivity();
            }
            setIsOpen(false);
          }}
        >
          {isUpdate ? "Remove" : "Cancel"}
        </button>

        {showWeekDropDown && comingFrom != "PROGRAMS" && (
          <div className="flex items-center">
            <p
              style={{
                fontSize: "14px",
                fontWeight: "500",
                color: "black",
                marginRight: "5px",
              }}
            >
              Assign habit for{" "}
            </p>
            <Select onValueChange={(value) => setTotalDays(value)}>
              <SelectTrigger className="w-[70px] h-[32px] focus:ring-white-pure">
                <SelectValue placeholder="1" />
              </SelectTrigger>
              <SelectContent className="z-[9999]">
                {days.map((day) => (
                  <SelectItem key={day} value={String(day)}>
                    {day}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <p
              style={{
                fontSize: "14px",
                fontWeight: "500",
                color: "black",
                marginLeft: "5px",
              }}
            >
              <Select onValueChange={(value) => setDayOrWeek(value)}>
                <SelectTrigger className="w-[95px] h-[32px] focus:ring-white-pure">
                  <SelectValue placeholder="day" />
                </SelectTrigger>
                <SelectContent className="z-[9999]">
                  <SelectItem key={"day"} value={"day"}>
                    Days
                  </SelectItem>
                  <SelectItem key={"week"} value={"week"}>
                    Weeks
                  </SelectItem>
                </SelectContent>
              </Select>
            </p>
          </div>
        )}
        <button
          className="button-new"
          onClick={() => {
            onSave();
          }}
        >
          {isUpdate ? "Update" : "Add"}
        </button>
      </DialogActions>
    </Dialog>
  );
}

const DailyTargetActivities = ({ activityOption, values, setValues }) => {
  console.log({ values, setValues, activityOption })
  return (
    <div>
      {activityOption?.map((option, index) => (
        <div
          key={index}
          className={`cursor-pointer py-2 px-4 rounded-full mb-2 font-DMSans`}
          style={{
            background: "#FFFFFF",
            color: "#000000",
            display: "flex",
            alignItems: "center",
          }}
        >
          <p
            style={{
              textTransform: "capitalize",
            }}
            className="font-DMSans w-16"
          >
            {option}
          </p>
          <div className="flex items-center">
            <input
              type="number"
              value={values[option].value}
              onChange={(e) =>
                setValues({
                  ...values,
                  [option]: { ...values[option], value: e.target.value },
                })
              }
              className="w-[50px] h-8 border-b border-gray-200 ml-4 mr-2 text-center outline-none"
            />
            {
              SelectFeatForOptions.includes(option) ?
                <select
                  onChange={(e) => setValues({
                    ...values,
                    [option]: { ...values[option], unit: e.target.value },
                  })}
                >
                  {
                    units.map(un => <option value={un}>{un}</option>)
                  }
                </select>
                :
                <p>{values[option].unit}</p>
            }
          </div>
        </div>
      ))}
    </div>
  );
};

// <div
// key={activityOption}
// className={`cursor-pointer py-2 px-4 rounded-full mb-2 font-DMSans`}
// style={{
//   background: "#FFFFFF",
//   color: isActivityOptionSelected(activityOption)
//     ? "#000000"
//     : "#BDBDBD",
//   display: "flex",
//   alignItems: "center",
// }}
// onClick={() => handleAddOption(activityOption)}
// >
// {isActivityOptionSelected(activityOption) ? (
//   <RemoveRounedIcon
//     style={{ color: "#BDBDBD", cursor: "pointer" }}
//   />
// ) : (
//   <AddRoundedIcon
//     style={{ color: "#BDBDBD", cursor: "pointer" }}
//   />
// )}
// {activityOption}
// </div>
