import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import SearchIcon from "@mui/icons-material/Search";
import { Autocomplete, Dialog, DialogContent, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { store } from "../../redux/store";

const AddSubscriptionDialog = ({
  open,
  setOpen,
  product,
  activeTab,
  getTrainerData,
}) => {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const history = useHistory();
  const [genuineProducts, setGenuineProducts] = useState([]);
  const coachID = useSelector((state) => state.auth?.trainerUUID);
  const [searchTerm, setSearchTerm] = useState("");
  const [products, setProducts] = useState(product || []);
  const [selectedProduct, setSelectedProduct] = useState(product[0]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [allUsersData, setAllUsersData] = useState([]);
  const handleUserSelected = (event, newValue) => {
    console.log(
      "I am handling the search in the add subscription",
      event,
      newValue
    );
    setSearchTerm(event.target.value);
    setSelectedUser(newValue);
  };
  const currencySymbols = {
    KRW: "₩",
    USD: "$",
    CAD: "$",
    INR: "₹",
    AED: "د.إ",
    EUR: "€",
    GBP: "£",
    SGD: "$",
  };
  console.log("product ha kya", product);

  //   functions
  const handleProductChange = (event, newValue) => {
    console.log("event and new value", event, newValue);
    setSelectedProduct(newValue);
  };
  const getAllUsers = async () => {
    try {
      const res = await axios({
        method: "get",
        url: `${APIURL}CF/api/CoachWeb/Get/trainerDashboardAll`,
        params: {
          coachID,
        },
      });
      if (res) {
        console.log("all the users", res?.data?.result);
        setAllUsersData(res?.data?.result);
      }
    } catch (err) {
      console.log("there is some error getting all the users", err);
    }
  };
  const extractGenuineProducts = () => {
    let tempProds = [];
    products?.forEach((item) => {
      let name = item.prodName;
      let cost =
        currencySymbols[item?.prices?.[0]?.currency.toUpperCase()] +
        "" +
        item?.prices?.[0]?.amount +
        "/" +
        item?.prices?.[0]?.intervalCount +
        " " +
        item?.prices?.[0]?.interval;

      if (cost.includes("null") || cost.includes("undefined")) {
      } else {
        tempProds.push(item);
      }
    });
    console.log("temp prods are", tempProds);
    setGenuineProducts(tempProds);
  };

  const createNewSubscription = async () => {
    try {
      let trainerDetails = JSON.parse(
        localStorage.getItem("trainerCredentials")
      );
      // setLoading(true);
      let data = {
        price_id: selectedProduct?.prices?.[0]?.priceId,
        coachId: store.getState().auth.trainerUUID,
        coachName: trainerDetails?.name,
        athleteId: selectedUser?.UserUUID,
        atheleteName: selectedUser?.Name,
        cus_email: selectedUser?.Email,
      };

      const res = await axios.post(
        `${APIURL}CF/api/stripeconnect/CreateSubscriptions`,
        data
      );
      toast.success("Successfully added subscription!");
      getTrainerData();
      setOpen(false);
    } catch (err) {
      toast.error("Unexpected error occurred!");
    }
  };

  useEffect(() => {
    extractGenuineProducts();
    getAllUsers();
  }, []);
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      style={{
        fontFamily: "DM Sans",
      }}
      PaperProps={{
        sx: {
          borderRadius: "12px",
          overflowY: "unset",
          width: "800px",
        },
      }}
    >
      <div className="flex justify-between items-center w-full px-5 py-4">
        <p
          style={{
            fontWeight: "600",
            fontSize: "18px",
          }}
        >
          Add subscription &nbsp;&nbsp;
          {activeTab === "PRODUCTS" && (
            <span className="font-normal text-[16px] text-slate-400">
              {products[0]?.prodName}
            </span>
          )}
        </p>
        <CloseRoundedIcon
          sx={{
            cursor: "pointer",
            fontWeight: "600",
            fontSize: "24px",
          }}
          onClick={() => {
            //   setData(null);
            setOpen(false);
          }}
          className=" text-[#b6b6b6] hover:text-[black]"
        />
      </div>
      <div className="seperator-add-prod h-[1px] bg-[#EAEAEA] w-full"></div>
      <DialogContent>
        {/* search bar big */}
        <div className="w-full">
          <span
            id={"search-bar"}
            style={{ display: "flex", alignItems: "center", width: "100%" }}
          >
            <Autocomplete
              freeSolo
              id="free-solo-2-demo"
              getOptionLabel={(option) => option.Name}
              onChange={handleUserSelected}
              options={allUsersData}
              sx={{
                width: "100%",
                backgroundColor: "#f7f7f7",
                borderRadius: "12px",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#EAEAEA",
                    borderRadius: "12px",
                  },
                  "& input": {
                    color: "black",
                    "&:focus": {
                      outline: "none",
                    },
                  },
                },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="&nbsp;Search Users to assign"
                  InputLabelProps={{
                    style: {
                      color: "black",
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <SearchIcon
                        style={{
                          position: "absolute",
                          right: "16px",
                          color: "gray",
                          cursor: "pointer",
                        }}
                      />
                    ),
                    type: "search",
                  }}
                />
              )}
            />
          </span>
        </div>
        {/* Products to be shown */}
        {activeTab !== "PRODUCTS" &&
          genuineProducts &&
          genuineProducts.length > 0 && (
            <div className="w-full flex items-center mt-5">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={genuineProducts}
                onChange={handleProductChange}
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    // Target the root of the outlined input
                    "& fieldset": {
                      // Target the fieldset element
                      borderColor: "#EAEAEA",
                      borderRadius: "12px",
                    },
                    "& .MuiAutocomplete-clearIndicator": {
                      // Target the clear indicator icon
                      color: "gray", // Change the color to red or any other color
                    },
                  },
                }}
                getOptionLabel={(option) => option.prodName}
                renderInput={(params) => (
                  <TextField {...params} label="Search Products" />
                )}
              />
            </div>
          )}

        {/* Subscription highlighted text */}
        <div className="w-full flex items-center justify-start mt-3">
          <span
            className="font-semibold"
            style={{
              fontFamily: "DM Sans",
              fontSize: "12px",
              color: "#6142EA",
              marginLeft: "2px",
            }}
          >
            * Subscription will start from the day of payment!
          </span>
        </div>
        {/* start subscription button */}
        <div className="w-full flex justify-center items-center">
          <button
            disabled={!(selectedUser && selectedProduct)}
            className="button-in-start-subscription"
            onClick={() => createNewSubscription()}
          >
            START SUBSCRIPTION
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddSubscriptionDialog;
