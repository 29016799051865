import { ArrowForwardIosRounded, CloseRounded } from "@mui/icons-material";
import { Avatar, Backdrop, Box } from "@mui/material";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import Select from "react-select";
import { SpurfitCircularProgress } from "../../components/Dashboard/dashboard";
import { SearchIcon } from "../../pages/getStreamChat/assets";
import {
  getAllProgramsWithPhasesByCoachID,
  unassignPhasedProgramsByPhaseContainerID,
} from "@/ApiServices/Programs/Api";
import { CalendarDays, Layers } from "lucide-react";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import CustomSelect from "../EnduranceWorkoutBuilder/utils/CustomSelect";

const customStyles = {
  menu: (provided) => ({
    ...provided,
    zIndex: 9999, // set a higher z-index value
    position: "absolute",
    // maxHeight: "150px",
    // overflowY: "auto",
    fontFamily: "DM Sans",
    top: 0,
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 99999,
    position: "absolute",
    top: "69%",
    left: "58.5%",
  }),
  control: (provided, state) => ({
    ...provided,
    fontFamily: "DM Sans",
    height: 40,
    minHeight: 40,
    borderWidth: 0,
    "&:hover": {
      borderColor: "##071148",
    },
    "&:focus": {
      border: "2px solid ##071148",
      boxShadow: "0 0 0 2px ##071148",
    },
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    height: "40px",
    padding: "0 6px",
  }),

  input: (provided, state) => ({
    ...provided,
    margin: "0px",
    borderWith: "2px",
    fontFamily: "DM Sans",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "40px",
  }),

  option: (provided, state) => ({
    ...provided,
    fontFamily: "DM Sans",
    backgroundColor: state.isSelected ? "white" : "white",
    color: state.isSelected ? "black" : "#424242",
    "&:hover": {
      backgroundColor: state.isSelected ? "#e3e3e3" : "#e3e3e3",
    },
  }),
};
const AssignProgramToUser = ({
  isOpen,
  setIsOpen,
  selectedProgram,
  setSelectedProgram,
  startDate,
  setStartDate,
  selectedDay,
  setSelectedDay,
  onBack,
  onNext,
  onClose,
  onSave,
  loading,
  selectedUser,
  onUnassignSuccess,
}) => {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const trainerUUID = useSelector((state) => state.auth.trainerUUID);
  const [allPrograms, setAllPrograms] = useState([]);
  const [allPhasedPrograms, setAllPhasedPrograms] = useState([]);
  const [tempPrograms, setTempPrograms] = useState([]);
  const [tempPhasedPrograms, setTempPhasedPrograms] = useState([]);
  const [searchedTerm, setSearchedTerm] = useState("");
  const [previouslySelectedProgram, setPreviouslySelectedProgram] = useState(
    {}
  );

  const [unassigning, setUnassigning] = useState(false);

  const [selectedPhase, setSelectedPhase] = useState(null);

  const [selectedOption, setSelectedOption] = useState("All Phases");

  const days = [];

  for (let i = 1; i <= 365; i++) {
    let dayNumber = i;
    if (i % 7 === 1) {
      // Add a new option for the start of each week
      let weekNumber = Math.ceil(i / 7);
      days.push({
        value: "week",
        label: `Week ${weekNumber}`,
        isDisabled: true,
      });
    }
    days.push({ value: i, label: `Day ${dayNumber}` });
  }

  const getAllPrograms = async () => {
    // await axios({
    //   method: "get",
    //   url: `${APIURL}CF/api/getAllProgramsByCoach`,
    //   params: {
    //     coachID: trainerUUID,
    //   },
    // })
    //   .then((res) => {
    //     setAllPrograms(res.data.result);
    //     setTempPrograms(res.data.result);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

    try {
      let res = await getAllProgramsWithPhasesByCoachID(trainerUUID);
      setAllPhasedPrograms(res);
      setTempPhasedPrograms(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllPrograms();
  }, []);

  useEffect(() => {
    if (selectedUser) {
      setPreviouslySelectedProgram({
        name: selectedUser?.phasedProgramName,
        id: selectedUser?.phasedProgramID,
      });
    }
  }, [selectedUser]);

  const searchProgram = (searchedVal) => {
    setSearchedTerm(searchedVal);

    let filteredPrograms = allPrograms?.filter((program) => {
      return program.name.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setTempPrograms(filteredPrograms);

    let filteredPhasedPrograms = allPhasedPrograms?.filter((program) => {
      return program.name.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setTempPhasedPrograms(filteredPhasedPrograms);
  };

  const handleDayChange = (newValue, actionMeta) => {
    setSelectedDay(newValue);
  };

  const formatOptionLabel = ({ value, label }) => {
    if (label.startsWith("Week")) {
      return <div style={{ fontWeight: "bold" }}>{label}</div>;
    }
    return <div>{label}</div>;
  };

  const formatData = (updatedPrograms) => {
    let updatedData = {};
    let curProgStartDate = startDate;
    updatedPrograms.forEach((program, index) => {
      let startDay = selectedDay.value;
      let duration = program.duration;
      if (index == 0 && selectedOption !== "All Phase")
        duration = duration - (startDay - 1);

      let curProgEndDate = moment(curProgStartDate)
        .add(program.duration, "days")
        .toDate();
      updatedData[program.id] = {
        startProgDay:
          index == 0 && selectedOption !== "All Phase" ? startDay : 1,
        programID: program.id,
        atheleteIDs: [selectedUser?.UserUUID],
        startDate:
          moment(curProgStartDate).format("YYYY-MM-DD") + "T00:00:00.000Z",
        // endDate: curProgEndDate,
      };
      curProgStartDate = moment(curProgEndDate).add(1, "days").toDate();
    });
    console.log("updatedData", updatedData);
    return Object.values(updatedData);
  };

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 2 }}
      open={isOpen}
      onClick={() => {
        setIsOpen(false);
        onClose();
      }}
    >
      {loading && (
        <div
          className="absolute w-full h-full flex items-center justify-center"
          style={{
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: 9999,
          }}
        >
          <SpurfitCircularProgress />
        </div>
      )}
      <Box
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 3,
        }}
        style={{
          overflowY: "inherit",
          position: "absolute",
          background: "#fff",
          boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.12)",
          borderRadius: "12px",
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div>
          <div className="add-program-modal-head">
            <p className="add-program-modal-head-title">Assign Program User </p>
            <div
              onClick={() => {
                setIsOpen(false);
                onBack();
              }}
              style={{
                color: "#000",
                cursor: "pointer",
              }}
            >
              <CloseRounded color="#000" />
            </div>
          </div>

          <div className="add-program-modal-body">
            <div className="full-width-container">
              <div>
                <div
                  className="channel-search__input__wrapper"
                  style={{
                    minWidth: "500px",
                    maxWidth: "500px",
                    display: "flex",
                    flexWrap: "wrap",
                    height: "auto",
                    justifyContent: "flex-start",
                  }}
                >
                  {selectedProgram?.name ? (
                    <div
                      className="flex items-center gap-1 px-3 py-2 rounded-full bg-white-pure"
                      onClick={() => {
                        setSelectedProgram({});
                      }}
                    >
                      <p
                        style={{
                          color: "#333",
                        }}
                      >
                        {selectedProgram?.name}
                      </p>

                      <CloseRounded
                        style={{
                          color: "#989898",
                          cursor: "pointer",
                          fontSize: "16px",
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        width: "100%",
                      }}
                      className="channel-search__input__icon"
                    >
                      <SearchIcon />
                      <input
                        className="channel-search__input__text"
                        onChange={(e) => searchProgram(e.target.value)}
                        placeholder="Search Programs"
                        type="text"
                        value={searchedTerm}
                      />{" "}
                    </div>
                  )}
                </div>
              </div>

              {selectedProgram?.name && (
                <div className="flex flex-col items-center justify-center gap-2 mt-2">
                  {/* <div>
                    <label
                      htmlFor="inputField"
                      style={{
                        display: "block",
                        color: "#000",
                        fontSize: 10,
                        marginLeft: 12,
                      }}
                    >
                      Program start date
                    </label>
                    <DatePicker
                      dateFormat="dd MMM yyyy"
                      minDate={moment()
                        .set({
                          hour: 0,
                          minute: 0,
                          second: 0,
                          millisecond: 0,
                        })
                        .toDate()}
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      customInput={
                        <input
                          type="text"
                          style={{
                            width: "100%",
                            height: "40px",
                            padding: "10px",
                            outline: "none",
                            fontFamily: "DM Sans",
                            cursor: "pointer",
                            color: "#000",
                          }}
                        />
                      }
                    />
                  </div>
                  <div className="flex flex-col">
                    <label
                      htmlFor="inputField"
                      style={{
                        display: "block",
                        fontSize: 10,
                        marginLeft: 12,
                        color: "#000",
                      }}
                    >
                      Starting day
                    </label>
                    <Select
                      styles={customStyles}
                      options={days}
                      value={selectedDay}
                      onChange={handleDayChange}
                      formatOptionLabel={formatOptionLabel}
                      placeholder="Select day"
                    />
                  </div> */}

                  <RadioGroup
                    defaultValue="All Phases"
                    value={selectedOption}
                    onValueChange={(value) => {
                      // if value is custom phase
                      if (value === "custom-phases") {
                        // set selected phase to the first phase of the selected program
                        setSelectedPhase(selectedProgram?.programs[0]?.id);
                      }
                      setSelectedOption(value);
                    }}
                  >
                    <div className="flex items-center gap-4">
                      <div className="flex items-center space-x-2">
                        <RadioGroupItem value="All Phases" id="All Phases" />
                        <label
                          className="text-black-pure font-DMSans"
                          htmlFor="All Phases"
                        >
                          All Phases
                        </label>
                      </div>
                      <div className="flex items-center space-x-2">
                        <RadioGroupItem
                          value="custom-phases"
                          id="custom-phases"
                        />
                        <label
                          className="text-black-pure font-DMSans"
                          htmlFor="All Phases"
                        >
                          Custom Phase
                        </label>
                      </div>
                    </div>
                  </RadioGroup>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 8,
                    }}
                  >
                    {selectedOption === "custom-phases" && (
                      <div className="mb-[15px]">
                        <label
                          className="text-black-pure font-DMSans text-font12 ml-3"
                          htmlFor="custom-phases"
                        >
                          Select Start Phase
                        </label>
                        <CustomSelect
                          contentClassName="z-[9999]"
                          className={"text-black-pure focus:ring-transparent"}
                          value={selectedPhase}
                          onValueChange={(value) => {
                            console.log("value", value);
                            setSelectedPhase(value);
                          }}
                          options={(selectedProgram?.programs || [])
                            ?.sort((a, b) => a.phaseOrderIdx - b.phaseOrderIdx)
                            .map((item) => {
                              return {
                                label: item.name,
                                value: item.id,
                              };
                            })}
                        />
                      </div>
                    )}
                    <div className="ml-[12px]">
                      <label
                        htmlFor="inputField"
                        style={{
                          display: "block",
                          color: "#000",
                          fontSize: 10,
                          marginLeft: 12,
                        }}
                      >
                        Program Start Date
                      </label>
                      <DatePicker
                        dateFormat="dd MMM yyyy"
                        minDate={moment()
                          .set({
                            hour: 0,
                            minute: 0,
                            second: 0,
                            millisecond: 0,
                          })
                          .toDate()}
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        customInput={
                          <input
                            type="text"
                            style={{
                              width: "128px",
                              height: "40px",
                              padding: "10px",

                              outline: "none",
                              fontFamily: "DM Sans",
                              cursor: "pointer",
                              color: "#000",
                            }}
                          />
                        }
                      />
                    </div>
                    {selectedOption !== "All Phases" && (
                      <div className="flex flex-col">
                        <label
                          htmlFor="inputField"
                          style={{
                            display: "block",
                            fontSize: 10,
                            marginLeft: 12,
                            color: "#000",
                          }}
                        >
                          Starting Day
                        </label>
                        <Select
                          styles={customStyles}
                          // menuPortalTarget={menuPortalRef.current}
                          // menuPortalTarget={
                          //   fromProgramTable
                          //     ? document.body
                          //     : menuPortalRef.current
                          // }
                          options={days}
                          value={selectedDay}
                          onChange={handleDayChange}
                          formatOptionLabel={formatOptionLabel}
                          placeholder="Select day"
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>

            {!selectedProgram?.name && (
              <div
                style={{
                  height: "300px",
                  width: "100%",
                  overflowY: "auto",
                  borderTop: "1px solid #E5E5E5",
                }}
                className="flex flex-col"
              >
                {previouslySelectedProgram?.name && (
                  <>
                    <div className="flex items-center justify-between gap-1 px-3 py-2 rounded-full bg-white-pure border-b mb-1">
                      <p
                        style={{
                          color: "#333",
                        }}
                        className="font-DMSans text-font[14px]"
                      >
                        {previouslySelectedProgram?.name}
                      </p>

                      <button
                        className="font-DMSans text-font[14px] font-semibold text-black-pure"
                        onClick={async () => {
                          setUnassigning(true);
                          let res =
                            await unassignPhasedProgramsByPhaseContainerID(
                              previouslySelectedProgram?.id,
                              selectedUser?.UserUUID
                            );
                          setUnassigning(false);
                          if (res) {
                            onUnassignSuccess();
                          }
                        }}
                        disabled={unassigning}
                      >
                        {unassigning ? "Unsubscribing..." : "Unsubscribe"}
                      </button>
                    </div>
                    <p className="font-DMSans text-[10px] text-black-pure">
                      Selecting any program will replace the previously
                      assigned.
                    </p>
                  </>
                )}
                {tempPhasedPrograms?.map((program) => {
                  return (
                    <div
                      className={` flex items-center justify-between px-3 py-2 rounded-lg cursor-pointer
                    ${
                      selectedProgram?.name === program?.name
                        ? "bg-violet-200"
                        : " bg-white-pure"
                    }

                    hover:bg-violet-100
                   `}
                      onClick={() => {
                        setSelectedProgram(program);
                      }}
                    >
                      <div className="flex items-center gap-2">
                        <Layers color={"#656565"} />
                        <p
                          style={{
                            color: "#333",
                          }}
                          className="text-sm font-medium"
                        >
                          {program?.name}
                        </p>
                      </div>
                      <ArrowForwardIosRounded className="text-black-pure text-font20" />
                    </div>
                  );
                })}
                {tempPrograms?.map((program) => {
                  return (
                    <div
                      className={` flex items-center justify-between px-3 py-2 rounded-lg cursor-pointer
                    ${
                      selectedProgram?.name === program?.name
                        ? "bg-violet-200"
                        : " bg-white-pure"
                    }

                    hover:bg-violet-100
                   `}
                      onClick={() => {
                        setSelectedProgram(program);
                      }}
                    >
                      <div className="flex items-center gap-2">
                        <CalendarDays color={"#656565"} />
                        <p
                          style={{
                            color: "#333",
                          }}
                          className="text-sm font-medium"
                        >
                          {program?.name}
                        </p>
                      </div>
                      <ArrowForwardIosRounded className="text-black-pure text-font20" />
                    </div>
                  );
                })}
              </div>
            )}

            <div className="flex justify-end items-center w-full">
              {onSave ? (
                <button
                  disabled={!selectedProgram?.name}
                  className="button-new"
                  onClick={() => {
                    let selectedPrograms = selectedProgram?.programs;
                    if (selectedOption !== "All Phases") {
                      let selectedPhaseData = (
                        selectedProgram?.programs ?? []
                      )?.find((phase) => phase.id == selectedPhase);
                      selectedPrograms = selectedPrograms.filter(
                        (phase) =>
                          phase.phaseOrderIdx >= selectedPhaseData.phaseOrderIdx
                      );
                    }
                    onSave(formatData(selectedPrograms));
                  }}
                >
                  Save
                </button>
              ) : selectedProgram?.name ? (
                <button className="button-new" onClick={onNext}>
                  Next
                </button>
              ) : (
                <button
                  style={{
                    borderWidth: 2,
                    borderRadius: 100,
                    width: "148px",
                    height: "44px",
                  }}
                  className="button-new-outlined"
                  onClick={onNext}
                >
                  Skip
                </button>
              )}
            </div>
          </div>
        </div>
      </Box>
    </Backdrop>
  );
};

export default AssignProgramToUser;

const getInitials = (string) => {
  const names = string?.split(" ");
  if (_.isEmpty(names)) return;

  let initials = names[0].substring(0, 1).toUpperCase();
  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};
