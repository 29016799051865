import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PlusRoundIcon from "../../pages/library/assests/PlusRoundIcon";
import { StatsCard } from "../DescriptionComp/ClientProfileSummary";
import AddClientEvents from "./AddClientEvents";

const ClientEvents = ({ data, atheleteID, reloadParticularClientsProfile }) => {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const [showAddEvents, setShowAddEvents] = useState(false);
  const [events, setEvents] = useState([]);

  const [selectedEvent, setSelectedEvent] = useState(null);

  useEffect(() => {
    if (atheleteID) {
      getAllEvents(atheleteID);
    }
  }, [atheleteID]);

  const getAllEvents = async (atheleteID) => {
    await axios({
      url: `${APIURL}CF/api/athleteEvents/getAllAthleteEvents`,
      method: "get",
      params: {
        atheleteID: atheleteID,
      },
    })
      .then((res) => {
        if (res.data.result) {
          setEvents(res.data.result);
        }
      })
      .catch((err) => {
        console.log("error in events", err);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#FFFFFF",
        borderRadius: "10px",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.03)",
        height: "fit-content",
        width: "100%",
        minWidth: 300,
        marginTop: 24,
      }}
    >
      <div
        style={{
          padding: "12px 24px",
          borderBottom: "1px solid #EBEBEB",
        }}
        className="flex justify-between items-center"
      >
        <p
          style={{
            fontFamily: "DM Sans",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "29px",
          }}
        >
          Events
        </p>

        <div
          className="cursor-pointer"
          onClick={() => {
            setShowAddEvents(true);
            setSelectedEvent(null);
          }}
        >
          <PlusRoundIcon />
        </div>
      </div>
      <div className="flex flex-col overflow-scroll max-h-[300px]">
        {events?.map((event) => {
          return (
            <div
              className="w-full cursor-pointer"
              onClick={() => {
                setShowAddEvents(true);
                setSelectedEvent(event);
              }}
            >
              <StatsCard
                title={event?.event?.name}
                value={moment(event?.eventDate)?.format("Do MMM YY")}
              />
            </div>
          );
        })}
      </div>

      {showAddEvents && (
        <AddClientEvents
          athleteID={atheleteID}
          isOpen={showAddEvents}
          setIsOpen={setShowAddEvents}
          existingEvent={selectedEvent}
          onAddEvent={(event) => {
            let newEvents = [...events];
            if (selectedEvent) {
              let selectedIndex = newEvents.findIndex(
                (e) => e.id === selectedEvent.id
              );
              newEvents[selectedIndex] = event;
            } else {
              newEvents.push(event);
            }
            reloadParticularClientsProfile(atheleteID);
            setEvents(newEvents);
            setShowAddEvents(false);
            setSelectedEvent(null);
          }}
        />
      )}
    </div>
  );
};

export default ClientEvents;
