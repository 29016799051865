import React, { useContext } from 'react';
import { workoutContext } from './WorkoutContex';
 
 
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import { SpurfitCircularProgress } from '@/components/Dashboard/dashboard';
 import Run from "@/assets/run.png"
 import Dumbell from "@/assets/dumbell3.png"

function WorkoutList(props) {
    const {
      data,
      setWorkoutSelected,
      workoutSelected,
      unSelectFolder,
      originalData,
      setOriginalData,
      tempData,
      adminID,
      isLoadingForWorkout,
      
    } = useContext(workoutContext);
    return (
      <div>
        {isLoadingForWorkout && (
          <div className="fixed top-0 left-0 w-full h-full   flex items-center justify-center">
            <SpurfitCircularProgress />
          </div>
        )}
        {tempData?.map((workout) => {
          let isSelect = workoutSelected?.id === workout?.id;

          return (
            <>
              <div
                key={workout?.id}
                className={`flex flex-col py-1 px-2 cursor-pointer relative ${
                  isSelect
                    ? "folder-card-container-selected "
                    : "folder-card-container-unselected "
                } 
              
              `}
                onClick={(e) => {
                  setWorkoutSelected(workout);
                  console.log(
                    "this is ",
                    workout?.id,
                    workoutSelected?.id,
                    isSelect
                  );
                  unSelectFolder();
                }}
              >
                <div
                  className=" mx-1"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div
                    className={
                      isSelect
                        ? "channel-preview__wrapper_indicator__selected"
                        : "channel-preview__wrapper_indicator"
                    }
                    style={{
                      marginLeft: -7,
                    }}
                  ></div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: 700,
                        color: "#8330e9",

                        lineHeight: "10px",
                        fontFamily: "DM Sans",

                        visibility: "hidden",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                    >
                      {false ? (
                        <ArrowDropDownRoundedIcon
                          sx={{
                            fontSize: "35px",
                          }}
                        />
                      ) : (
                        <PlayArrowRoundedIcon
                          sx={{
                            fontSize: "20px",
                          }}
                        />
                      )}
                    </span>
                    <div className="min-w-[30px] min-h-[30px] max-w-[30px] max-h-[30px] flex items-center justify-center text-black  rounded-full mx-2 border p-1">
                      <img
                        src={workout?.workoutKind === null ? Dumbell: Run}
                        alt={"workout"}
                      />
                    </div>

                    <p className="font-DMSans text-font14 font-medium w-full break-words flex-wrap">
                      {workout?.name}
                    </p>
                    {adminID && workout?.coachID === adminID && (
                <span
                  style={{
                    fontSize: "8px",
                    fontWeight: 700,
                    color: "#09A033",
                    padding: "5px",
                    lineHeight: "10px",
                    fontFamily: "DM Sans",
                    backgroundColor: "#F7F7F7",
                    borderRadius: "10px",
                    marginLeft: "5px",
                  }}
                >
                  {"ADMIN"}
                </span>
              )}
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    );
}

export default WorkoutList;