import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import { useSelector } from "react-redux";
import ProgramsList from "./ProgramsList";
import { ProgramsProvider } from "./ProgramsContext";
import ProgramInfo from "./ProgramInfo";

const Programs = () => {
  return (
    <Layout>
      <ProgramsProvider>
        <div className=" bg-white-pure rounded-xl flex ">
          <ProgramsList />
          <ProgramInfo />
        </div>
      </ProgramsProvider>
    </Layout>
  );
};

export default Programs;
