import { store } from "@/redux/store";
import CloseIcon from "@mui/icons-material/Close";
import { LinearProgress, linearProgressClasses } from "@mui/material";
import axios from "axios";
import mixpanel from "mixpanel-browser";
import { useEffect, useState } from "react";
import { PopupButton, useCalendlyEventListener } from "react-calendly";
import { useDispatch, useSelector } from "react-redux";
import { getAllProgramsByCoachID } from "../../ApiServices/Programs/Api";
import { getWorkouts } from "../../ApiServices/Workouts/Api";
import { setTrainerData } from "../../redux/authSlice";
import { setToggleValue, toggle } from "../../redux/checkListSlice";
import { SpurfitCircularProgress } from "./dashboard";
import "./styles.css";

const ChecklistModal = ({ toggled, copilotOpen, handleAddNewClick, data }) => {
  const trainerUUID = useSelector((state) => state.auth.trainerUUID);
  useCalendlyEventListener({
    onProfilePageViewed: () => console.log("onProfilePageViewed"),
    onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
    onEventTypeViewed: () => console.log("onEventTypeViewed"),
    onEventScheduled: (e) => {
      updateBookedCall(trainerUUID);
    },
  });
  const dispatch = useDispatch();
  const tempListData = [
    {
      title: "Book a personalised demo",
      route: "",
      checked: false,
    },
    {
      title: "Add New client",
      route: null,
      checked: false,
    },
    { title: "Create Workout", route: "workouts", checked: false },
    { title: "Create Program", route: "programs", checked: false },
  ];

  const [listData, setListData] = useState(tempListData);
  const [listProgress, setListProgress] = useState(0);
  const [loading, setLoading] = useState(true);
  const [taskCompletedCount, setTaskCompletedCount] = useState(0);
  const [togglingList, setTogglingList] = useState(toggled);
  const checklistStatus = {};

  const trainerMail = useSelector((state) => state.auth.mail);
  const trainerName = useSelector((state) => state.auth.name);
  const oneTrainerData = useSelector((state) => state.auth.data);

  useCalendlyEventListener({
    onProfilePageViewed: () => console.log("onProfilePageViewed"),
    onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
    onEventTypeViewed: () => console.log("onEventTypeViewed"),
    onEventScheduled: async (e) => {
      setLoading(true);
      let updatedDemoCall = await updateBookedCall(trainerUUID);
      if (updatedDemoCall) {
        setListData((prev) => {
          let copyPrev = [...prev];
          copyPrev[0].checked = true;
          return copyPrev;
        });
        dispatch(setTrainerData(updatedDemoCall));
      }
    },
  });
  const getAllData = async () => {
    if (!data || data.length === 0 || !trainerUUID) {
      return;
    }
    setLoading(true);
    const trainerDashboardData = data;
    console.log(
      "trainer dashboard data",
      trainerDashboardData[0],
      trainerDashboardData.length,
      trainerDashboardData[0]?.Email,
      trainerMail,
      trainerName
    );
    const workoutData = await getWorkouts(trainerUUID);
    console.log("all wokout data", workoutData);
    const programsData = await getAllProgramsByCoachID(trainerUUID);
    console.log("all program data", programsData);
    let listLength = listData.length;
    let singleCompletion = 100 / listLength;
    let taskCompletedCountVariable = 0;
    let listProgressVariable = 0;
    // book personallizes demo logic
    if (
      oneTrainerData.demoCallDate != null &&
      oneTrainerData.demoCallDate != ""
    ) {
      tempListData[0].checked = true;
      checklistStatus[1] = "Book a personalised demo";
      listProgressVariable += singleCompletion;
      taskCompletedCountVariable++;
    }

    // add new client logic
    if (
      trainerDashboardData?.length <= 1 &&
      trainerDashboardData?.[0]?.Email === "democlient@demo.com"
    ) {
      listData[1].checked = false;
    } else {
      tempListData[1].checked = true;
      checklistStatus[2] = "Add New Client";
      listProgressVariable += singleCompletion;
      taskCompletedCountVariable++;
    }
    // create workout logic
    if (workoutData?.length > 0) {
      tempListData[2].checked = true;
      checklistStatus[3] = "Create Workout";
      listProgressVariable += singleCompletion;
      taskCompletedCountVariable++;
    } else {
      listData[2].checked = false;
    }
    // create program logic
    if (programsData?.length > 0) {
      tempListData[3].checked = true;
      checklistStatus[4] = "Create Program";
      listProgressVariable += singleCompletion;
      taskCompletedCountVariable++;
    } else {
      listData[3].checked = false;
    }
    if (taskCompletedCountVariable >= 1) {
      setTogglingList(false);
      dispatch(setToggleValue(false));
    } else {
      setTogglingList(true);
    }
    console.log("check list status", checklistStatus);
    console.log("trainerUUID", trainerUUID);
    if (Object.keys(checklistStatus).length > 0) {
      await updateChecklistStatus(trainerUUID, checklistStatus);
    }
    setListData(tempListData);
    setListProgress(listProgressVariable);
    setTaskCompletedCount(taskCompletedCountVariable);
    setLoading(false);
  };

  useEffect(() => {
    getAllData();
  }, [data, trainerUUID, oneTrainerData]);
  useEffect(() => {
    setTogglingList(toggled);
  }, [toggled]);
  return (
    <>
      {loading ? (
        <div
          className="checklist"
          style={{
            display: togglingList ? "flex" : "none",
            flexDirection: "column",
            width: "350px",
            height: "240px",
            transition: "all 0.3s ease",
            background:
              "linear-gradient(to bottom, white, white),radial-gradient(circle at top left, red, blue)",
            borderRadius: "20px",
            backgroundColor: "#FFF",
            boxShadow: "0px 8px 8px 3px rgba(0, 0, 0, 0.3)",
            position: "fixed",
            transition: "opacity 0.3s ease-in-out",
            bottom: "50px",
            left: "110px",
            zIndex: 100,
          }}
        >
          <SpurfitCircularProgress />
        </div>
      ) : (
        <div
          className="checklist"
          style={{
            display: togglingList ? "flex" : "none",
            flexDirection: "column",
            width: "350px",
            height: "240px",
            transition: "all 0.3s ease",
            background:
              "linear-gradient(to bottom, white, white),radial-gradient(circle at top left, red, blue)",
            borderRadius: "20px",
            backgroundColor: "#FFF",
            boxShadow: "0px 8px 8px 3px rgba(0, 0, 0, 0.3)",
            position: "fixed",
            transition: "opacity 0.3s ease-in-out",
            bottom: "50px",
            left: "110px",
            zIndex: 100,
          }}
        >
          <div
            className="checklist-top"
            style={{
              padding: "15px 14px 12px 17px",
            }}
          >
            <div className="checklist-title">
              <p
                style={{
                  fontSize: "1.2rem",
                  lineHeight: "1.5rem",
                  fontFamily: "DM Sans",
                  fontWeight: 500,
                }}
              >
                Get Started
              </p>
              <CloseIcon
                style={{
                  fontSize: "1.4rem",
                  cursor: "pointer",
                  fontWeight: "500",
                }}
                onClick={() => dispatch(toggle())}
              />
            </div>
            <small
              style={{
                fontSize: "0.9rem",
                lineHeight: "1.3rem",
                fontFamily: "DM Sans",
                fontWeight: 400,
              }}
            >
              {taskCompletedCount} of {listData.length} completed
            </small>
            <LinearProgress
              variant="determinate"
              value={listProgress}
              sx={{
                height: 7,
                borderRadius: 3,
                marginTop: "5px",
                [`&.${linearProgressClasses.colorPrimary}`]: {
                  backgroundColor: "#E5E7EB",
                },
                [`& .${linearProgressClasses.bar}`]: {
                  borderRadius: 3,
                  backgroundColor: "#673FEA",
                },
              }}
            />
          </div>
          <div
            className="checklist-main"
            style={{
              padding: "0px 14px 10px 12px",
            }}
          >
            {listData.map((item, index) => {
              if (item.title === "Add New client") {
                return (
                  <a
                    key={index}
                    className="listItem flex items-center justify-start "
                    style={{
                      padding: "5px 6px",
                      borderRadius: "8px",
                    }}
                    onClick={() => {
                      handleAddNewClick();
                      mixpanel.track("Trainer_Checklist_AddNewClient");
                      dispatch(toggle());
                    }}
                  >
                    <input
                      type="checkbox"
                      id={index}
                      style={{
                        marginRight: "10px",
                      }}
                      checked={item.checked}
                    />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <p
                        style={{
                          color: "#323130",
                          fontSize: "16px",
                          lineHeight: "22px",
                          fontFamily: "DM Sans",
                          fontWeight: 500,
                        }}
                      >
                        {item.title}
                      </p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 9 14"
                        width="10"
                        height="16"
                        fill="none"
                        style={{
                          transform: "rotate(180deg)",
                        }}
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M8 11.6L3.4 7L8 2.4L6.6 1L0.6 7L6.6 13L8 11.6Z"
                          fill="#9CA3AF"
                        ></path>
                      </svg>
                    </div>
                  </a>
                );
              } else if (item.title === "Book a personalised demo") {
                return (
                  <a
                    key={index}
                    className="listItem flex items-center justify-start "
                    style={{
                      padding: "5px 6px",
                      borderRadius: "8px",
                    }}
                    onClick={() =>
                      mixpanel.track("Trainer_Checklist_BookPersonalizedDemo")
                    }
                  >
                    <input
                      type="checkbox"
                      id={index}
                      style={{
                        marginRight: "10px",
                      }}
                      checked={item.checked}
                    />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <p
                        style={{
                          color: "#323130",
                          fontSize: "16px",
                          lineHeight: "25px",
                          fontFamily: "DM Sans",
                          fontWeight: 500,
                        }}
                      >
                        <PopupButton
                          url="https://calendly.com/rahulaluri/connectwithspurfit"
                          rootElement={document.getElementById("root")}
                          text="Book a personalized demo"
                          prefill={{
                            name: trainerName,
                            email: trainerMail,
                          }}
                        />
                      </p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 9 14"
                        width="10"
                        height="16"
                        fill="none"
                        style={{
                          transform: "rotate(180deg)",
                        }}
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M8 11.6L3.4 7L8 2.4L6.6 1L0.6 7L6.6 13L8 11.6Z"
                          fill="#9CA3AF"
                        ></path>
                      </svg>
                    </div>
                  </a>
                );
              } else {
                const currentPath = window.location.href;
                const routeToAdd = item.route;
                const pathSegments = currentPath.split("/");
                pathSegments[pathSegments.length - 1] = routeToAdd;
                const newPath = pathSegments.join("/");
                return (
                  <a
                    key={index}
                    href={newPath}
                    target="_blank"
                    className="listItem flex items-center justify-start "
                    style={{
                      padding: "5px 6px",
                      borderRadius: "8px",
                    }}
                    onClick={() => dispatch(toggle())}
                  >
                    <input
                      type="checkbox"
                      id={index}
                      style={{
                        marginRight: "10px",
                      }}
                      checked={item.checked}
                    />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <p
                        style={{
                          color: "#323130",
                          fontSize: "16px",
                          lineHeight: "25px",
                          fontFamily: "DM Sans",
                          fontWeight: 500,
                        }}
                      >
                        {item.title}
                      </p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 9 14"
                        width="10"
                        height="16"
                        fill="none"
                        style={{
                          transform: "rotate(180deg)",
                        }}
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M8 11.6L3.4 7L8 2.4L6.6 1L0.6 7L6.6 13L8 11.6Z"
                          fill="#9CA3AF"
                        ></path>
                      </svg>
                    </div>
                  </a>
                );
              }
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default ChecklistModal;
export const updateBookedCall = async (trainerUUID) => {
  try {
    const res = await axios({
      method: "POST",
      url: `${
        store.getState().auth.apiURL
      }CF/api/CoachWeb/Update/UpdateCoachFields`,
      data: {
        coachID: trainerUUID,
        demoCallDate: new Date(),
      },
    });
    return res?.data?.result;
  } catch (error) {}
};
export const updateChecklistStatus = async (trainerUUID, checklistStatus) => {
  try {
    const res = await axios({
      method: "POST",
      url: `${
        store.getState().auth.apiURL
      }CF/api/CoachWeb/Update/UpdateCoachFields`,
      data: {
        coachID: trainerUUID,
        checklistStatus: checklistStatus,
      },
    });
    return res?.data?.result;
  } catch (error) {}
};
