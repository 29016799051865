import { Dialog, DialogActions, DialogContent, Divider } from "@mui/material";
import React, { useState } from "react";
import Input from "../../components/InputComponents/Input";
import { createMessageTemplate, updateMessageTemplate } from "./Api";
import { CloseRounded } from "@mui/icons-material";
import TextArea from "../../components/InputComponents/TextArea";
import { useSelector } from "react-redux";
import { SpurfitCircularProgress } from "../../components/Dashboard/dashboard";

const CreateTemplateModal = ({
  isOpen,
  setIsOpen,
  selectedItem,
  onSuccess,
}) => {
  const [templateName, setTemplateName] = useState(
    selectedItem?.templateName || ""
  );
  const [templateMessage, setTemplateMessage] = useState(
    selectedItem?.templateMessage || ""
  );
  const [loading, setLoading] = useState(false);
  const coachID = useSelector((state) => state.auth.trainerUUID);
  const [isFilledAll, setIsFilledAll] = useState(true);
  const onSave = async () => {
    if (templateName == "" || templateMessage == "") {
      setIsFilledAll(false);
      return;
    }
    setLoading(true);

    if (selectedItem.id) {
      let res = await updateMessageTemplate(
        selectedItem.id,
        templateName,
        templateMessage
      );
    } else {
      let res = await createMessageTemplate(
        coachID,
        templateName,
        templateMessage
      );
    }

    onSuccess();
    setIsFilledAll(false);
    setLoading(false);
    setIsOpen(false);
  };

  return loading ? (
    <Dialog
      maxWidth={"sm"}
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "20px",
        },
      }}
    >
      <SpurfitCircularProgress />
    </Dialog>
  ) : (
    <Dialog
      maxWidth={"sm"}
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "20px",
        },
      }}
      onClose={() => {
        setIsOpen(false);
      }}
      open={isOpen}
    >
      <div className="add-program-modal-head">
        <p className="add-program-modal-head-title">Add Message Template</p>
        <div
          onClick={() => {
            setIsOpen(false);
          }}
          className="cursor-pointer"
        >
          <CloseRounded
            sx={{
              fontSize: "24px",
              color: "#000",
              cursor: "pointer",
            }}
          />
        </div>
      </div>
      <DialogContent>
        <Input
          className="mb-3"
          label="Template Name*"
          onChange={(event) => {
            setTemplateName(event.target.value);
          }}
          value={templateName}
        />
        <TextArea
          placeholder="Enter Template Message"
          onChange={(e) => {
            setTemplateMessage(e.target.value);
          }}
          value={templateMessage}
        />
        {!isFilledAll && (
          <p className="font-DMSans font-medium text-red-600 mt-2">
            *Please enter all the required fields
          </p>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <button className="button-new" onClick={onSave}>
          Save
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateTemplateModal;
