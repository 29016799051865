import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { DescriptionInput, NutritionInput } from "../AddRecipieModal";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { FOOD_BASED, MACOS_BASED } from "../../../constants";
import { updateMealPlan } from "../../../ApiServices/Meals/Api";
import { SpurfitCircularProgress } from "../../Dashboard/dashboard";
import { NutritionContext } from "../NutritionContext";

function EditMealInfoModal({
  isOpen,
  setIsOpen,
  setNutritionData,
  setdefaultNItem,
  isTemplate = false,
  item,
  forFolder,
  onSuccess = () => {},
}) {
  const [referenceName, setReferenceName] = useState(
    item?.reference_name || item?.name || ""
  );
  const { updateFolderDetails, setTemplateFolders, setFolders } =
    useContext(NutritionContext);
  const [description, setDescription] = useState(item?.description || "");
  const [dietType, setDietType] = useState(item?.diet_type || "");
  const [loading, setLoading] = useState(false);

  const onSave = async () => {
    setLoading(true);
    let updatedItem = {
      ...item,
      reference_name: referenceName,
      description,
      diet_type: dietType,
    };
    // remove nulls from updated item
    Object.keys(updatedItem).forEach(
      (key) => updatedItem[key] == null && delete updatedItem[key]
    );

    await updateMealPlan(updatedItem.id, updatedItem);
     onSuccess();
// if Meal plan is in the folder     
if(updatedItem?.mealPlanFolderId){
    if(updatedItem?.isTemplate){
     setTemplateFolders((prevFolders) => {
              const newData = prevFolders.map((folder) => {
                if (folder?.id === updatedItem?.mealPlanFolderId) {
                    if(folder?.mealPlans){
                    const updatedMealPlan=folder.mealPlans.map((mealPlan)=>{
                      if(mealPlan?.id ===  updatedItem?.id){
                        return updatedItem
                      }
                      return mealPlan
                     })
                     folder["mealPlans"] = updatedMealPlan;
                    }
                    return folder
                }
                return folder;
              });
              return newData;
            });
    }else{
        setFolders((prevFolders) => {
          const newData = prevFolders.map((folder) => {
            if (folder?.id === updatedItem?.mealPlanFolderId) {
              if (folder?.mealPlans) {
                const updatedMealPlan = folder.mealPlans.map((mealPlan) => {
                  if (mealPlan?.id === updatedItem?.id) {
                    return updatedItem;
                  }
                  return mealPlan;
                });
                folder["mealPlans"] = updatedMealPlan;
              }
              return folder;
            }
            return folder;
          });
          return newData;
        });
    }
  }
    setLoading(false);
    setIsOpen(false);
  };

  const handleUpdateFolderInfo = async (name, des) => {
    let res = await updateFolderDetails(name, des);
    if (res) {
      setReferenceName("");
      setDescription("");
      setIsOpen(false);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() =>{
         setIsOpen(false)
         setReferenceName("");
         setDescription("");}}
      PaperProps={{
        sx: {
          // padding: "24px",
          width: "520px",
          borderRadius: "20px",
        },
      }}
    >
      {loading && (
        <div className="absolute flex items-center justify-center w-full h-full">
          <SpurfitCircularProgress />
        </div>
      )}
      <div className="add-program-modal-head">
        <p className="add-program-modal-head-title">
          {!forFolder && (isTemplate ? "Edit Template" : "Edit Meal Plan")}
          {forFolder && "Update Folder Details"}
        </p>
        <CloseRoundedIcon
          sx={{
            cursor: "pointer",
          }}
          onClick={() => {
            setIsOpen(false);
          }}
        />
      </div>
      <DialogContent>
        <Stack spacing={2}>
          <NutritionInput
            label={"Reference Name*"}
            value={referenceName}
            onChange={(value) => setReferenceName(value)}
            style={{ width: "100%" }}
          />
          {/* <NutritionInput
              label={"Display Name*"}
              value={displayName}
              onChange={(value) => setDisplayName(value)}
              style={{ width: "100%" }}
            /> */}

          <DescriptionInput
            label={"Description*"}
            value={description}
            onChange={(value) => setDescription(value)}
          />

          {!forFolder && (
            <div
              style={{
                minWidth: "100%",
                display: "flex",
                flexDirection: "column",
                paddingLeft: "12px",
                paddingRight: "12px",
              }}
            >
              <label
                style={{
                  fontFamily: "DM Sans",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "20px",
                  textAlign: "left",
                }}
              >
                Diet Type
              </label>
              <RadioGroup
                row
                aria-labelledby="diettype-row-radio-buttons-group-label"
                name="diet-type-radio-buttons-group"
                value={dietType}
                onChange={(e) => setDietType(e.target.value)}
              >
                <FormControlLabel
                  value={"Veg"}
                  control={<Radio />}
                  label="Veg"
                />
                <FormControlLabel
                  value={"Non-Veg"}
                  control={<Radio />}
                  label={"Non-Veg"}
                />
                <FormControlLabel
                  value={"Vegan"}
                  control={<Radio />}
                  label={"Vegan"}
                />
              </RadioGroup>
            </div>
          )}
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          pb: "24px",
        }}
      >
        {!forFolder && (
          <button
            disabled={referenceName.length < 2}
            style={{ marginTop: "8px" }}
            className="button-new"
            onClick={() => {
              onSave();
            }}
          >
            Done
          </button>
        )}
        {forFolder && (
          <button
            disabled={referenceName.length < 2}
            style={{ marginTop: "8px" }}
            className="button-new"
            onClick={() => {
              handleUpdateFolderInfo(referenceName, description);
            }}
          >
            Update
          </button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default EditMealInfoModal;
