import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { createContext, forwardRef, useEffect, useState } from "react";
import AddUserIcon from "../../pages/library/assests/AddUserIcon";
import BowlIcon from "../../pages/library/assests/BowlIcon";
import CalendarIcon from "../../pages/library/assests/CalendarIcon";
import PlusIcon from "../../pages/library/assests/PlusIcon";
import WeekList from "../WeekList/WeekList";

import { ContentCopyRounded, CopyAllRounded } from "@mui/icons-material";
import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import MenuOptions from "../../features/Workouts/MenuOptions";
import AssignProgramModal from "../../pages/library/programsCF/AssignProgramModal";
import { store } from "../../redux/store";
import SpinnerComponent from "../layout/spinner";
import AssignedUsersDialog from "./AssignedUsersDialog";
import DuplicateMealSchedule from "./DuplicateMealSchedule";
import SelectMealsModal from "./SelectMealsModal";

import {
  createMealPlanWithID,
  updateMealSchedule,
} from "../../ApiServices/Meals/Api";
import EditIcon from "../../assets/workoutBuilder/editIcon.svg";
import DeleteIcon from "../../assets/workouts/DeleteIcon.svg";
import { SpurfitCircularProgress } from "../Dashboard/dashboard";
export const MealsContext = createContext();

const MealSheduleWeek = forwardRef(
  (
    {
      mealId,
      mealName,
      mealsData,
      mealsDesc,
      setItem,
      unsavedChanges,
      setUnsavedChanges,
      getMealPlansSchedule,
      getMealScheduleByID,
      isTemplate,
      item,
      setShowUpdateMealScheduleModal,
    },
    ref
  ) => {
    const APIURL = useSelector((state) => state.auth.apiURL);
    const [row, setRows] = useState(1);
    const [column, setColumn] = useState(7);
    const [columnPosition, setColumnPosition] = useState(1);
    const [mealDetails, setmealDetails] = useState(mealsData);
    const [showWorkOutBuilder, setShowWorkOutBuilder] = useState(false);
    const [workOutFor, setWorkOutFor] = useState();
    const [showModal, setShowModal] = useState(false);
    const [left, setLeft] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [selectedMeal, setselectedMeal] = useState([]);
    const [deleteDay, setDeleteDay] = useState(false);
    const [addedMeals, setaddedMeals] = useState(mealsData);
    const [selectedWeekIndex, setSelectedWeekIndex] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [showAssignModal, setShowAssignModal] = useState(false);
    const [unsavedChangesNew, setUnsavedChangesNew] = useState(
      unsavedChanges || false
    );
    const [showAlert, setShowAlert] = useState({
      show: false,
      message: "",
    });
    const [saveLoading, setSaveLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [updatedPlan, setUpdatePlan] = useState(false);
    const [weekToDelete, setWeekToDelete] = useState(null);
    const [copiedDay, setCopiedDay] = useState({
      day: null,
      data: null,
    });

    const [showDuplicateDialog, setShowDuplicateDialog] = useState(false);
    const [addAsTemplate, setAddAsTemplate] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [mealScheduleAssignedUsers, setMealScheduleAssignedUsers] = useState(
      []
    );
    const [showAssignedUsersDialog, setShowAssignedUsersDialog] =
      useState(false);
    const [lastAssignedDay, setLastAssignedDay] = useState(null); // this is last day when the meal is assigned in the saved meal schedule

    const [showPDFDialog, setShowPDFDialog] = useState(false);

    const coachID = useSelector((state) => state.auth.trainerUUID);

    const values = {
      lastAssignedDay,
      mealScheduleAssignedUsers,
      row,
    };

    const history = useHistory();

    const getMealScheduleAssignedUsers = async () => {
      if (!mealId) {
        setMealScheduleAssignedUsers([]);
        return;
      }
      await axios({
        method: "get",
        url: `${APIURL}CF/api/getUsersForMealScheduleID`,
        params: {
          mealScheduleID: mealId,
        },
      })
        .then((res) => {
          setMealScheduleAssignedUsers(res.data.result);
        })
        .catch((err) => {
          console.log("error in getting meal schedule assigned users");
        });
    };

    useEffect(() => {
      if (mealId) {
        getMealScheduleAssignedUsers();
      } else {
        setMealScheduleAssignedUsers([]);
      }
    }, [mealId]);

    useEffect(() => {
      let lastDay =
        Object.keys(mealsData || {})?.[
          Object.keys(mealsData || {}).length - 1
        ] ?? null;
      setLastAssignedDay(lastDay);
      if (unsavedChanges) {
      } else if (!updatedPlan) {
        setRows(1);
        setmealDetails(mealsData);
        console.log("updatedd");
      } else {
        setUpdatePlan(false);
      }

      if (
        (item?.isTemplate && item?.coachID === coachID) ||
        item?.trainerUUID === coachID ||
        !item?.isTemplate
      ) {
        setCanEdit(true);
      } else {
        setCanEdit(false);
      }
    }, [mealsData]);

    useEffect(() => {
      if (Object.keys(mealDetails).length > 0) {
        console.log("week data: ", Object.keys(mealDetails));
        console.log(
          "week data: ",
          Object.keys(mealDetails)?.[Object.keys(mealDetails).length - 1]
        );
        let weeksCount = Math.ceil(
          parseInt(
            Object.keys(mealDetails)?.[Object.keys(mealDetails).length - 1]
          ) / 7
        );

        let programDetails = [];
        for (let week = 1; week < weeksCount; week++) {
          for (let i = 1; i <= column; i++) {
            programDetails["Day" + (7 * week + i)] = {};
          }
        }
        console.log("weeks cohunt: ", weeksCount);
        // if(weeksCount == row){
        setRows(weeksCount);
        // }
      }
    }, [mealDetails]);

    useEffect(() => {
      setUnsavedChangesNew(unsavedChanges);
    }, [unsavedChanges]);

    useEffect(() => {
      // handle beforeunload
      window.addEventListener("beforeunload", saveMealScheduleToLocalStorage);

      let unblock = history.block((location, action) => {
        if (unsavedChanges) {
          saveMealScheduleToLocalStorage();
        }
      });

      return () => {
        window.removeEventListener(
          "beforeunload",
          saveMealScheduleToLocalStorage
        );
        unblock();
      };
    }, [addedMeals, mealDetails, history]);

    const removeDayWorkout = (value) => {
      setUpdatePlan(true);
      Object.keys(mealDetails).map((item) => {
        if (value == item) {
          mealDetails[item] = {};
        }
      });
      setmealDetails(mealDetails);
      selectedMeal.splice(selectedMeal.indexOf(value), 1);
      setselectedMeal(selectedMeal);
      setDeleteDay(!deleteDay);
    };

    const addWeeks = () => {
      let newRow = row + 1;
      for (let i = 1; i <= column; i++) {
        mealDetails[7 * (newRow - 1) + i] = {};
      }
      console.log("set row: ", row + 1);
      setRows(row + 1);
      console.log("mealdetails: ", mealDetails);
      setUpdatePlan(true);
      setmealDetails(mealDetails);
    };

    function convertToObject(input) {
      const mealPlans = [];

      Object.values(input).map((data) => {
        console.log(data);
        if (data[0]?.dayMealPlan?.id || data[0]?.id) {
          mealPlans.push({
            dayMealPlanID: data[0]?.dayMealPlan?.id ?? data[0].id,
            dayIdx: data[0].dayIdx,
          });
        }
      });

      console.log("logging", mealPlans);

      return mealPlans;
    }

    const handleSubmitMealSchedule = async (dontReload) => {
      if (mealId && mealScheduleAssignedUsers.length > 0) {
        await handleUpdateMealSchedule();
        getMealScheduleByID(null, mealId);
        return;
      }
      setSaveLoading(true);
      const mealPlans = convertToObject(mealDetails);
      await axios({
        method: "post",
        url: `${APIURL}CF/api/addNewMealSchedule`,
        data: {
          mealScheduleID: mealId,
          trainerUUID: store.getState().auth.trainerUUID,
          name: mealName,
          description: "",
          creationDate: `${moment().format("YYYY-MM-DDTHH:mm:ss")}Z`,
          mealPlans: mealPlans,
          isTemplate: isTemplate,
        },
      })
        .then(async (res) => {
          if (!dontReload) {
            if (!mealId) {
              getMealPlansSchedule();
            } else {
              await getMealScheduleByID(null, mealId);
              setUpdatePlan(true);
            }
          }
          console.log("saving meal: ", mealPlans);
          setUnsavedChanges(false);
          handleShowAlert("Meal schedule added successfully");
          setSaveLoading(false);
        })
        .catch((err) => {
          saveMealScheduleToLocalStorage();
          setSaveLoading(false);
        });
    };

    const handleUpdateMealSchedule = async () => {
      let data = {
        mealScheduleID: mealId,
        coachID: store.getState().auth.trainerUUID,
        name: mealName,
        description: "",
        createMeals: undefined,
        createAssignedWeeksDayIdxs: undefined,
        updateMeals: undefined,
        updatedDayIdx: undefined,
      };

      let lastDay = Object.keys(mealsData).sort((a, b) => b - a)[0];

      let createMeals = [];
      let updateMeals = [];
      let createAssignedWeeksDayIdxs = [];
      setSaveLoading(true);
      Object.entries(mealsData).map(([key, value]) => {
        let oldMeal = value[0];
        let newMeal = mealDetails[key]?.[0];

        if (newMeal) {
          if (newMeal?.dayMealPlanID !== oldMeal?.dayMealPlanID) {
            updateMeals.push({
              id: oldMeal?.id,
              dayMealPlanID: newMeal?.dayMealPlan?.id,
              dayIdx: newMeal?.dayIdx,
            });
          }
        }
      });

      Object.entries(mealDetails).map(([key, value]) => {
        let oldMeal = mealsData[key]?.[0];
        let newMeal = value[0];

        if (!oldMeal) {
          if (newMeal?.dayIdx) {
            createMeals.push({
              dayMealPlanID: newMeal?.dayMealPlan?.id,
              dayIdx: newMeal?.dayIdx,
            });

            if (newMeal?.dayIdx <= lastDay) {
              createAssignedWeeksDayIdxs.push(newMeal?.dayIdx);
            }

            if (newMeal?.dayIdx > lastDay) {
              data.updatedDayIdx = newMeal?.dayIdx;
            }
          }
        }
      });

      data.createMeals = createMeals.length > 0 ? createMeals : undefined;
      data.updateMeals = updateMeals?.length > 0 ? updateMeals : undefined;
      data.createAssignedWeeksDayIdxs = createAssignedWeeksDayIdxs;
      if (data.updatedDayIdx) {
        data.updatedDayIdx = +lastDay + 1;
      }
      try {
        let updated = await updateMealSchedule(data);
        if (updated) {
          await getMealScheduleByID(null, mealId);
        }
      } catch (error) {
        saveMealScheduleToLocalStorage();
      }
      setSaveLoading(false);
      setUnsavedChanges(false);
    };

    const handleShowAlert = (data) => {
      setShowAlert({
        show: true,
        message: data,
      });
      setTimeout(() => {
        setShowAlert({
          show: false,
          message: "",
        });
      }, 3000);
    };

    const duplicateMealSchedule = async ({ name, description, isTemplate }) => {
      setSaveLoading(true);
      const mealPlans = convertToObject(mealDetails);
      await axios({
        method: "post",
        url: `${APIURL}CF/api/addNewMealSchedule`,
        data: {
          trainerUUID: store.getState().auth.trainerUUID,
          name: name,
          description: description || "",
          creationDate: `${moment().format("YYYY-MM-DDTHH:mm:ss")}Z`,
          mealPlans: mealPlans,
          ...(isTemplate && { isTemplate }),
        },
      })
        .then(async (res) => {
          getMealPlansSchedule();
          handleShowAlert("Meal schedule duplicated successfully");
        })
        .catch((err) => {})
        .finally(() => {
          setSaveLoading(false);
          setAddAsTemplate(false);
        });
    };

    const removeWeeks = () => {
      let continued = false;
      let arr = [];
      for (let i = 1; i <= column; i++) {
        console.log("mealDetails: ", mealDetails);
        console.log("mealDetails: ", mealDetails[7 * (row - 1) + i]);
        // console.log("mealdatarow: ",mealDetails[7 * (row - 1) + i][0])
        arr.push(i);
        if (mealDetails[7 * (row - 1) + i]) {
          if (mealDetails[7 * (row - 1) + i][0]) {
            console.log("getting true");
            continued = true;
            setUnsavedChanges(true);
          }
        }
      }
      setSelectedWeekIndex(arr);
      console.log("here comes");
      if (!continued) {
        arr.map((data) => {
          console.log("deleting: ", mealDetails[7 * (row - 1) + data]);
          delete mealDetails[7 * (row - 1) + data];
          // delete addedMeals[7 * (row - 1) + i];
          console.log("after delete", mealDetails);
        });
        setRows(row - 1);
      } else {
        setOpenConfirmDialog(true);
        setSelectedWeekIndex(arr);
      }

      setmealDetails(mealDetails);
      setaddedMeals(addedMeals);
    };

    const removeParticularWeek = (weekNumber) => {
      let continued = false;
      let arr = [];
      let startDay = 7 * weekNumber + 1;
      let endDay = 7 * weekNumber + 7;

      setWeekToDelete(weekNumber);
      setUnsavedChanges(true);
      for (let i = startDay; i <= endDay; i++) {
        console.log("mealDetails: ", mealDetails);
        console.log("mealDetails: ", mealDetails[i]);
        // console.log("mealdatarow: ",mealDetails[7 * (row - 1) + i][0])
        arr.push(i);
        if (mealDetails[i]) {
          if (mealDetails[i][0]) {
            console.log("getting true");
            continued = true;
            setUnsavedChanges(true);
          }
        }
      }

      setSelectedWeekIndex(arr);
      console.log("here comes");
      if (!continued) {
        let sortedKeys = Object.keys(mealDetails).sort((a, b) => b - a);
        let lastDay = +sortedKeys[0];

        let newMealDetails = {};

        for (let i = 1; i < startDay; i++) {
          if (mealDetails[i]) {
            newMealDetails[i] = [
              {
                ...mealDetails[i][0],
                dayIdx: i,
              },
            ];
          }
        }

        // shift the remaining days
        for (let i = endDay + 1; i <= lastDay; i++) {
          if (mealDetails[i]) {
            newMealDetails[i - 7] = [
              {
                ...mealDetails[i][0],
                dayIdx: i - 7,
              },
            ];
          }
        }

        setWeekToDelete(null);
        setRows(row - 1);
        setmealDetails(newMealDetails);
      } else {
        setOpenConfirmDialog(true);
        setSelectedWeekIndex(arr);
      }

      setaddedMeals(addedMeals);
    };

    const handleConfirmDeleteWeek = () => {
      setUnsavedChangesNew(true);
      setUpdatePlan(true);
      let startDay = 7 * weekToDelete + 1;
      let endDay = 7 * weekToDelete + 7;

      let sortedKeys = Object.keys(mealDetails).sort((a, b) => b - a);
      let lastDay = +sortedKeys[0];

      let newMealDetails = {};

      for (let i = 1; i < startDay; i++) {
        if (mealDetails[i]) {
          newMealDetails[i] = [
            {
              ...mealDetails[i][0],
              dayIdx: i,
            },
          ];
        }
      }

      // shift the remaining days
      for (let i = endDay + 1; i <= lastDay; i++) {
        if (mealDetails[i]) {
          newMealDetails[i - 7] = [
            {
              ...mealDetails[i][0],
              dayIdx: i - 7,
            },
          ];
        }
      }

      setWeekToDelete(null);
      setRows(row - 1);

      setOpenConfirmDialog(false);

      setmealDetails(newMealDetails);
      setaddedMeals(addedMeals);
    };

    function uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(12)
      );
    }

    const handleDuplicateWeek = async (weekNumber) => {
      setUnsavedChanges(true);
      let startDay = 7 * weekNumber + 1;
      let endDay = 7 * weekNumber + 7;

      let sortedKeys = Object.keys(mealDetails).sort((a, b) => b - a);
      let lastDay = +sortedKeys[0];

      let mealDetailsCopy = { ...mealDetails };

      let newMealDetails = { ...mealDetailsCopy };

      // add items before the week
      // for (let i = 1; i < startDay; i++) {
      //   if (mealDetailsCopy[i] && mealDetailsCopy[i]?.[0]?.dayIdx) {
      //     newMealDetails[i] = [
      //       {
      //         ...mealDetailsCopy[i][0],
      //         dayIdx: i,
      //       },
      //     ];
      //   }
      // }

      // shift the remaining days
      // for (let i = endDay + 1; i <= lastDay; i++) {
      //   if (mealDetailsCopy[i] && mealDetailsCopy[i]?.[0]?.dayIdx)
      //     newMealDetails[i + 7] = [
      //       {
      //         ...mealDetailsCopy[i][0],
      //         dayIdx: i + 7,
      //       },
      //     ];
      // }
      setLoading(true);
      // duplicate the week
      let index = 1;
      for (let i = startDay; i <= endDay; i++) {
        if (mealDetailsCopy[i] && mealDetailsCopy[i]?.[0]?.dayIdx) {
          let dayIndex = row * 7 + (mealDetailsCopy[i]?.[0]?.dayIdx % 7);

          // adding for the duplicated week...
          let newID = uuidv4();
          let dayMealPlan = mealDetailsCopy[i][0].dayMealPlan;

          let updatedItem = { ...dayMealPlan };
          updatedItem.id = newID;
          updatedItem.isCoachMealPlan = false;

          // remove all null values
          Object.keys(updatedItem).map((key) => {
            if (updatedItem[key] === null) {
              delete updatedItem[key];
            }
          });

          let updatedRes = await createMealPlanWithID(updatedItem, newID);

          newMealDetails[dayIndex] = [
            {
              ...mealDetailsCopy[i][0],
              dayMealPlan: updatedItem,
              dayIdx: dayIndex,
            },
          ];
        }
      }

      setmealDetails(newMealDetails);
      setRows(row + 1);
      setLoading(false);
      setaddedMeals(addedMeals);
    };

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleDeleteMealSchedule = async () => {
      setSaveLoading(true);
      await axios({
        method: "post",
        url: `${APIURL}CF/api/deleteMealSchedule`,
        data: {
          mealScheduleID: mealId,
        },
      })
        .then(async (res) => {
          getMealPlansSchedule();
          handleShowAlert("Meal schedule deleted successfully");
        })
        .catch((err) => {});
      setSaveLoading(false);
    };

    const getCaloriesCount = () => {
      let calories = 0;
      Object.values(mealDetails)?.map((meals) => {
        console.log(
          "mealll : ",
          meals?.[0]?.dayMealPlan?.plandetails?.calories,
          " -> ",
          typeof meals?.[0]?.dayMealPlan?.plandetails?.calories
        );
        if (meals?.[0]?.dayMealPlan?.plandetails?.calories) {
          calories =
            calories + (+meals?.[0]?.dayMealPlan?.plandetails?.calories ?? 0);
        }
      });

      return Math.round(calories);
    };

    const handlePasteDay = async (day) => {
      setUnsavedChanges(true);
      setUpdatePlan(true);
      let mealDetailsCopy = { ...mealDetails };
      let newID = uuidv4();
      let dayMealPlan = copiedDay.data[0]?.dayMealPlan;

      let updatedItem = { ...dayMealPlan };
      updatedItem.id = newID;
      updatedItem.isCoachMealPlan = false;
      setLoading(true);
      let updatedRes = await createMealPlanWithID(updatedItem, newID);
      setLoading(false);

      mealDetailsCopy[day] = [
        {
          ...copiedDay.data[0],
          dayMealPlan: updatedItem,
          dayIdx: day,
        },
      ];
      setmealDetails(mealDetailsCopy);
    };

    const open = Boolean(anchorEl);
    const popupId = open ? "simple-popover" : undefined;
    const { id } = useParams();

    const saveMealScheduleToLocalStorage = () => {
      if (!unsavedChanges) return;
      let data = {
        addedMeals,
        mealDetails,
        mealName,
        mealId,
        isTemplate,
      };
      localStorage.setItem("unsavedMealSchedule", JSON.stringify(data));
    };

    const retriveUnsavedMealScheduleFromLocalStorage = () => {
      let data = JSON.parse(localStorage.getItem("unsavedMealSchedule"));
      if (data) {
        setaddedMeals(data.addedMeals);
        setmealDetails(data.mealDetails);
        setUnsavedChanges(true);
      }
    };

    React.useImperativeHandle(ref, () => ({
      retriveUnsavedMealScheduleFromLocalStorage,
      handleSubmitMealSchedule,
    }));

    return (
      <MealsContext.Provider value={values}>
        <div className="programs-container-header">
          <div className="flex">
            <p className="mr-1">{mealName}</p>
            <img
              style={{
                width: "20px",
                height: "20px",
                marginTop: "1px",
                cursor: "pointer",
              }}
              src={EditIcon}
              alt="edit button for updating meal schedule"
              onClick={() => {
                setShowUpdateMealScheduleModal(true);
              }}
            />
          </div>
          <div className="programs-container-header-icons">
            {canEdit && (
              <>
                {mealScheduleAssignedUsers.length > 0 && (
                  <button
                    onClick={() => {
                      if (mealScheduleAssignedUsers.length > 0) {
                        setShowAssignedUsersDialog(true);
                      }
                    }}
                  >
                    Subscribers
                  </button>
                )}
                {(mealsData.length > 0 || Object.keys(mealsData).length > 0) &&
                  !item?.isTemplate && (
                    <div onClick={() => setShowAssignModal(true)}>
                      <AddUserIcon />
                    </div>
                  )}
              </>
            )}
            {/* {mealId && (
            <div onClick={handleClick}>
              <MoreIcon />
            </div>
          )} */}
            {mealId && (
              <MenuOptions
                isHoriz={true}
                options={
                  canEdit
                    ? [
                        ...(!(mealScheduleAssignedUsers?.length > 0)
                          ? ["Delete Meal Schedule"]
                          : []),
                        "Duplicate Meal Schedule",
                        ...(item?.isTemplate ? ["Copy to meal schedule"] : []),
                      ]
                    : item?.isTemplate
                    ? ["Copy to meal schedule"]
                    : []
                }
                optionIcons={[
                  ...(!(mealScheduleAssignedUsers?.length > 0)
                    ? [<img src={DeleteIcon} alt="Delete" />]
                    : []),
                  <ContentCopyRounded />,
                  <CopyAllRounded />,
                ]}
                menuItemStyles={[
                  ...(!(mealScheduleAssignedUsers?.length > 0)
                    ? [{ color: "#FF3A29" }]
                    : []),
                  null,
                  null,
                  null,
                ]}
                onOptionClick={(option) => {
                  if (option === "Delete Meal Schedule") {
                    handleDeleteMealSchedule();
                  } else if (option === "Duplicate Meal Schedule") {
                    setShowDuplicateDialog(true);
                    if (item?.isTemplate && item?.coachID === coachID) {
                      setAddAsTemplate(true);
                    } else {
                      setAddAsTemplate(false);
                    }
                  } else if (option === "Copy to meal schedule") {
                    setShowDuplicateDialog(true);
                    setAddAsTemplate(false);
                  }
                }}
                iconBtnStyles={{
                  borderRadius: "8px",
                }}
              />
            )}
            {/* <Popover
            id={popupId}
            open={open}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            borderRadius={12}
            style={{
              padding: "4px",
              display: "flex",
              flexDirection: "column",
              borderRadius: "12px",
              // boxShadow: "0px 0px 8px 1px rgba(33, 41, 60, 0.1)",
            }}
          >
            {canEdit && (
              <>
                <p
                  onClick={handleDeleteMealSchedule}
                  className="more-dialog-items"
                >
                  Delete
                </p>
                <p
                  onClick={() => {
                    setShowDuplicateDialog(true);
                    if (item?.isTemplate && item?.coachID === coachID) {
                      setAddAsTemplate(true);
                    } else {
                      setAddAsTemplate(false);
                    }
                  }}
                  className="more-dialog-items"
                >
                  Duplicate
                </p>
              </>
            )}
            {item?.isTemplate && (
              <p
                onClick={() => {
                  setShowDuplicateDialog(true);
                  setAddAsTemplate(false);
                }}
                className="more-dialog-items"
              >
                Copy to meal schedule
              </p>
            )}
          </Popover> */}
          </div>
        </div>
        <div className="programs-container-body relative">
          {loading && (
            <div
              className="absolute flex items-center justify-center h-full"
              style={{
                zIndex: 999,
                background: "rgba(255,255,255,0.8)",
                width: "100%",
              }}
            >
              <SpurfitCircularProgress />
            </div>
          )}
          <div className="program-conatiner-body-details">
            <div className="program-conatiner-body-details-card">
              <div>
                <p className="program-conatiner-body-details-card-title">
                  Total Days
                </p>
                <p className="program-conatiner-body-details-card-description">
                  {row * 7} Days
                </p>
              </div>
              <CalendarIcon />
            </div>
            {/* <div
                style={{ background: "#FAFEFF" }}
                className="program-conatiner-body-details-card"
              >
                <div>
                  <p className="program-conatiner-body-details-card-title">
                    Average Duration
                  </p>
                  <p className="program-conatiner-body-details-card-description">
                    {30} mins
                  </p>
                </div>
                <ClockIcon />
              </div> */}
            <div
              style={{ background: "#FAFAFF" }}
              className="program-conatiner-body-details-card"
            >
              <div>
                <p className="program-conatiner-body-details-card-title">
                  Total Calories
                </p>
                <p className="program-conatiner-body-details-card-description">
                  {getCaloriesCount()}
                </p>
              </div>
              <BowlIcon />
            </div>
            <button
              className="button-new"
              style={{
                display:
                  mealDetails.length == 0 ||
                  !unsavedChanges ||
                  !unsavedChangesNew
                    ? "none"
                    : "block",
              }}
              onClick={() => handleSubmitMealSchedule()}
              disabled={
                mealDetails.length == 0 || !unsavedChanges || !unsavedChangesNew
              }
            >
              {saveLoading ? (
                <CircularProgress
                  sx={{
                    color: "#fff",
                  }}
                  size={20}
                  thickness={4}
                />
              ) : isTemplate || item?.isTemplate ? (
                "Save Template"
              ) : (
                "Save Schedule"
              )}
            </button>
          </div>
          <div>
            <WeekList
              column={column}
              row={row}
              columnPosition={columnPosition}
              programDetails={mealDetails}
              setShowWorkOutBuilder={setShowWorkOutBuilder}
              setWorkOutFor={setWorkOutFor}
              removeDayWorkout={removeDayWorkout}
              showModal={showModal}
              setShowModal={setShowModal}
              setLeft={setLeft}
              removeWeeks={removeWeeks}
              removeParticularWeek={removeParticularWeek}
              mealCard={true}
              setUnsavedChanges={setUnsavedChanges}
              handleDuplicateWeek={handleDuplicateWeek}
              copiedDay={copiedDay}
              setCopiedDay={setCopiedDay}
              handlePasteDay={handlePasteDay}
              canEdit={canEdit}
            />
            <button
              className="button-new-outlined"
              onClick={() => {
                addWeeks();
              }}
            >
              <PlusIcon /> Add Week
            </button>
          </div>
        </div>

        <AssignProgramModal
          showModal={showAssignModal}
          setShowModal={setShowAssignModal}
          activeProgram={mealId}
          programName={mealName}
          key={id}
          mealCard={true}
          setShowAlert={setShowAlert}
          onSuccess={() => {
            getMealScheduleAssignedUsers();
          }}
        />
        <SelectMealsModal
          setUnsavedChanges={setUnsavedChanges}
          showModal={showModal}
          setShowModal={setShowModal}
          workOutFor={workOutFor}
          mealDetails={mealDetails}
          setmealDetails={setmealDetails}
        />
        <Box
          sx={{
            position: "fixed",
            bottom: 100,
            left: 0,
            right: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {showAlert.show && (
            <Alert severity="success">{showAlert.message}</Alert>
          )}
        </Box>
        <CustomModal
          onConfirm={handleConfirmDeleteWeek}
          onCancel={() => {
            setOpenConfirmDialog(false);
            setUnsavedChanges(false);
          }}
          isOpen={openConfirmDialog}
          close={setOpenConfirmDialog}
        />
        {saveLoading && (
          <div
            style={{
              position: "absolute",
              top: "100px",
              width: "100%",
              height: "100%",
              background: "#fff",
              opacity: 0.8,
              paddingTop: "20%",
            }}
          >
            <SpinnerComponent />
          </div>
        )}
        {showDuplicateDialog && (
          <DuplicateMealSchedule
            isOpen={showDuplicateDialog}
            setIsOpen={setShowDuplicateDialog}
            mealName={mealName}
            handleDuplicate={duplicateMealSchedule}
            addAsTemplate={addAsTemplate}
          />
        )}
        {showAssignedUsersDialog && (
          <AssignedUsersDialog
            isOpen={showAssignedUsersDialog}
            setIsOpen={setShowAssignedUsersDialog}
            mealScheduleAssignedUsers={mealScheduleAssignedUsers}
            setMealScheduleAssignedUsers={setMealScheduleAssignedUsers}
          />
        )}
      </MealsContext.Provider>
    );
  }
);

function CustomModal({ onConfirm, onCancel, isOpen, close }) {
  const handleOpen = () => {
    close(true);
  };

  const handleClose = (value) => {
    close(false);
    if (value === "no") {
      onCancel();
    } else {
      onConfirm();
    }
  };

  return (
    <Dialog open={isOpen}>
      <DialogTitle>Unsaved Changes</DialogTitle>
      <DialogContent>
        <p>You have added meal plans. Are you sure you want to delete them?</p>
      </DialogContent>
      <DialogActions>
        <button
          className="button-new"
          style={{
            width: 100,
          }}
          onClick={() => handleClose("yes")}
          color="primary"
        >
          Yes
        </button>
        <Button
          style={{ color: "#b21e42" }}
          onClick={() => handleClose("no")}
          color="primary"
        >
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default MealSheduleWeek;
