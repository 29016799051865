import { MenuItem, Select } from "@mui/material";
import { useEffect, useRef, useState } from "react";

export const SelectMeasurementUnit = ({
  value,
  meal,
  setMeal,
  foodIndex,
  isCustom,
  onSuccess,
}) => {
  const [allMeasurementUnits, setAllMeasurementUnits] =
    useState(measurementUnits);

  const [measurementUnit, setMeasurementUnit] = useState(value ?? "serving");
  const [typedInput, setTypedInput] = useState("");
  const inputRef = useRef(null);

  const handleMeasurementUnitChange = (e) => {
    if (!e.target.value) return;
    setMeasurementUnit(e.target.value);
    if (isCustom) {
      onSuccess(e.target.value);
      return;
    }
    let foods = [...meal.foods];
    foods[foodIndex].measurement_description = e.target.value;
    setMeal({ ...meal, foods });
  };

  useEffect(() => {
    if (!measurementUnits.find((unit) => unit.value === value)) {
      setAllMeasurementUnits([...allMeasurementUnits, { label: value, value }]);
    }
    setMeasurementUnit(value);
  }, [value]);

  return (
    <Select
      variant="standard"
      disableUnderline
      value={measurementUnit}
      onChange={handleMeasurementUnitChange}
    >
      {allMeasurementUnits?.map((unit) => {
        return (
          <MenuItem key={unit?.value} value={unit?.value}>
            {unit?.label}
          </MenuItem>
        );
      })}
      <input
        ref={inputRef}
        className="font-DMSans outline-none border border-gray-400 mx-2 p-2 rounded mt-2"
        placeholder="custom unit"
        value={typedInput}
        onChange={(e) => setTypedInput(e.target.value)}
        onKeyDown={(e) => {
          e.stopPropagation();
          if (e.key === "Enter") {
            let inputValue = inputRef.current.value;

            setAllMeasurementUnits([
              ...allMeasurementUnits,
              { label: inputValue, value: inputValue },
            ]);
            setMeasurementUnit(inputValue);
            if (isCustom) {
              onSuccess(inputValue);
            }
            setTypedInput("");
          }
        }}
      />
    </Select>
  );
};

//   return allMeasurementUnits ? (
//     <Select
//       sx={{
//         height: "40px",
//         maxWidth: "95px",
//         backgroundColor: "#fff",
//       }}
//       inputProps={{
//         style: {
//           backgroundColor: "#fff",
//         },
//       }}
//       variant="standard"
//       labelId="demo-simple-select-label"
//       id="demo-simple-select"
//       disableUnderline
//       //   value={measurementUnit}
//       onChange={handleMeasurementUnitChange}
//     >
//       {allMeasurementUnits?.map((unit) => {
//         return (
//           <MenuItem key={unit?.value} value={unit?.value}>
//             {unit?.label}
//           </MenuItem>
//         );
//       })}
//       // add custom unit
{
  /* */
}
//     </Select>
//   ) : (
//     <></>
//   );
// };

const measurementUnits = [
  { label: "serving", value: "serving" },
  { label: "oz", value: "oz" },
  { label: "g", value: "g" },
  { label: "ml", value: "ml" },
  { label: "cup", value: "cup" },
];
