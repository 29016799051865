import React, { useState } from "react";
import moment from "moment";
import TrainerNotesIcon from "../../assets/trainerNotesIcon.svg";
import TrainerNotesOption from "../../assets/trainerNotesOption.svg";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
const NotesBlock = ({
  notes,
  fetchData,
  setShowTextFeild,
  setType,
  setPrimaryKey,
  setCurrentNoteData,
}) => {
  const { PrimaryKey, Note, Date } = notes;
  // --> THIS IS THE NOTES Text BLOCK WE CAN MAP AND POPULATE AS MANY TEXT BLOCK AS WE WANT;
  const [isHovered, setIsHovered] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div
      className="py-2 font-semibold"
      style={{
        borderBottom: "1px solid #D8D6D6",
        width: "98%",
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 3px",
        position: "relative",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <img
          style={{
            height: "20px",
            width: "20px",
            marginRight: "12px",
          }}
          src={TrainerNotesIcon}
          alt=""
        />
        <div>
          <p
            className="text-black-pure text-font14 font-DMSans"
            style={{
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "21px",
              marginBottom: "3px",
            }}
          >
            {Note}
          </p>
          <p
            className="text-black-pure font-normal text-font12 font-DMSans"
            style={{
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "21px",
            }}
          >
            {moment(Date).utc().format("Do MMM YYYY")}
          </p>
        </div>
      </div>

      <button
        style={{
          padding: "8px",
          borderRadius: "8px",
          background: isHovered ? "#F8F8F8" : "#FFFFFF",
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <img src={TrainerNotesOption} alt />
      </button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          // Add your styles here
          "& .MuiPaper-root": {
            zIndex: 100,
            background: "#FFFFFF",
            borderRadius: "12px",
            boxShadow: "0px 4px 24px 2px rgba(33, 41, 60, 0.16)",
            padding: "4px",
          },
        }}
      >
        <MenuItem
          onClick={() => {
            setShowTextFeild(true);
            setType("edit");
            setPrimaryKey(PrimaryKey);
            setCurrentNoteData(notes.Note);
          }}
          sx={{
            fontFamily: "DM Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "700",
            marginBottom:"4px",
          }}
        >
          Edit Notes
        </MenuItem>
        <MenuItem
          onClick={() => fetchData({ type: "delete", PrimaryKey })}
          sx={{
            fontFamily: "DM Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "700",
            color: "#FF3A29",
          }}
        >
          Delete Notes
        </MenuItem>
      </Menu>
    </div>
  );
};
export default NotesBlock;
