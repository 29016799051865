import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
// import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import CloseIcon from "../../assets/trainerProfile/closeIcon.svg";
// import Select from "react-select";

import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { useSelector } from "react-redux";
import CheckedIcon from "../../assets/trainerProfile/checked.svg";
import UncheckedIcon from "../../assets/trainerProfile/unchecked.svg";
import "./scroll.css";

const currencySymbols = {
  KRW: "₩",
  USD: "$",
  CAD: "$",
  INR: "₹",
  AED: "د.إ",
  EUR: "€",
  GBP: "£",
  SGD: "$",
};

const customStyles = {
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
    position: "absolute",
  }),
  control: (provided, state) => ({
    ...provided,
    height: 30,
    minHeight: 30,
    backgroundColor: "white",
    borderColor: "#071148",
    boxShadow: state.isFocused ? "0 0 0 1px #071148" : provided.boxShadow,
    "&:hover": {
      borderColor: "#071148",
    },
    display: "flex",
    alignItems: "center",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: "30px",
    padding: "0 6px",
  }),

  input: (provided, state) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "30px",
  }),

  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#071148" : "white",
    color: state.isSelected ? "white" : "#071148",
    "&:hover": {
      backgroundColor: state.isSelected ? "#071148" : "#f2f2f2",
      color: state.isSelected ? "white" : "#071148",
    },
  }),
};

const TopWorkoutsModal = ({ getProfileData, trainerInfo, open, setOpen }) => {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const [programs, setPrograms] = useState([]);
  const [selectedPrice, setSelectedPrice] = useState({});
  const [selectedPriceCode, setSelectedPriceCode] = useState({});
  const [currencies, setCurrencies] = useState([]);
  const [oldData, setOldData] = useState({});
  const [programChecked, setProgramChecked] = useState({});
  const [selectedLevel, setSelectedLevel] = useState({});

  const CURRENCY_OPTIONS = [
    { value: "KRW", label: "KRW" },
    { value: "USD", label: "USD" },
    { value: "CAD", label: "CAD" },
    { value: "INR", label: "INR" },
    { value: "AED", label: "Dinar" },
    { value: "EUR", label: "Euro" },
    { value: "GBP", label: "Pound" },
    { value: "SGD", label: "SGD" },
  ];

  useEffect(() => {
    let checked = {};
    let price = {};
    let priceCode = {};
    let level = {};

    trainerInfo?.topPrograms?.map((item) => {
      // checked.push(item?.programID || item?.program?.id);
      checked[item?.programID] = true;
      price[item?.programID] = item?.price;
      priceCode[item?.programID] = item?.priceCode;
      level[item?.programID] = item?.level || "Beginner";
    });
    setOldData({ checked, priceCode, price });
    setProgramChecked(checked);
    setSelectedPriceCode(priceCode);
    setSelectedPrice(price);
    setSelectedLevel(level);
    getProgramsData();
    console.log({ checked, priceCode, price });
  }, []);

  const getProgramsData = async () => {
    if (programs.length > 0) return;
    let trainerDetails = JSON.parse(localStorage.getItem("trainerCredentials"));
    axios({
      method: "get",
      url: `${APIURL}CF/api/getAllProgramsByCoach`,
      params: {
        coachID: trainerDetails?.password,
      },
    })
      .then(async (res) => {
        setPrograms(res.data.result);
      })
      .catch((err) => {
        // displayAlert("Network Error. Please try again");
      });
  };

  const formatTopProgramsData = () => {
    let trainerDetails = JSON.parse(localStorage.getItem("trainerCredentials"));
    let topPrograms = [];
    const programIDs = Object.keys(programChecked);
    console.log({ programIDs });
    for (let i = 0; i < programIDs.length; i++) {
      const programID = programIDs[i];
      const checked = programChecked?.[programID] === true;

      if (!checked || Object.keys(oldData?.checked)?.includes(programID))
        continue;
      const price = selectedPrice[programID] || 0;
      const code = selectedPriceCode[programID]?.value || "USD";
      const level = selectedLevel[programID] || "Beginner";

      let topProgram = {
        coachID: trainerDetails?.password,
        programID: programID,
        price: +price,
        priceCode: code,
        level: level,
      };
      console.log({ topProgram });
      topPrograms.push(topProgram);
    }
    return topPrograms;
  };

  const addTopPrograms = () => {
    axios({
      method: "post",
      url: `${APIURL}CF/api/CoachWeb/Create/addMultiTopPrograms`,
      data: {
        topPrograms: formatTopProgramsData(),
        removedPrograms: getDeletedTopProgramsID(),
      },
    })
      .then((res) => {
        getProfileData();
      })
      .catch((err) => {});
  };

  const onDone = () => {
    addTopPrograms();
    setOpen(false);
    clearStates();
  };

  const clearStates = () => {
    setSelectedPrice(oldData?.price || {});
    setSelectedPriceCode(oldData?.priceCode || {});
    setProgramChecked(oldData?.checked || {});
    setSelectedLevel(oldData?.level || {});
  };

  const getDeletedTopProgramsID = () => {
    console.log({ trainerInfo });
    let deleted = [];

    Object.keys(programChecked)?.map((id) => {
      if (
        programChecked[id] === false &&
        Object.keys(oldData?.checked)?.includes(id)
      ) {
        let res = trainerInfo?.topPrograms?.find(
          (item) => item.programID == id
        );
        deleted.push(res.id);
      }
    });
    return deleted;
  };

  const handleCurrencyChange = (selectedOption, programID) => {
    console.log("Selected Option", selectedOption, " programID", programID);
    setSelectedPriceCode((prevState) => {
      let priceCode = selectedOption;
      let newState = { ...prevState };
      newState[programID] = priceCode;
      console.log("new state: ", newState);
      return newState;
    });
  };

  const handleLevelChange = (selectedOption, programID) => {
    setSelectedLevel((prevState) => {
      let level = selectedOption;
      let newState = { ...prevState };
      newState[programID] = level;
      console.log("new state: level", newState);
      return newState;
    });
  };

  useEffect(() => {}, [selectedPrice, selectedPriceCode]);

  const handleProgramChecked = (programID, checked) => {
    setProgramChecked((prevState) => ({
      ...prevState,
      [programID]: checked,
    }));

    if (!checked) {
      setSelectedPrice((prevState) => {
        const newState = { ...prevState };
        delete newState[programID];
        return newState;
      });

      setSelectedPriceCode((prevState) => {
        const newState = { ...prevState };
        delete newState[programID];
        return newState;
      });
    }
  };

  return (
    <Dialog
      open={open}
      maxWidth="md"
      PaperProps={{
        sx: {
          // height: `min(50vh, 786px)`,
          maxHeight: `min(80vh, "786px")`,
          width: "723px",
          borderRadius: "16px",
          pb: "30px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          position: "relative",
          justifyContent: "space-between",

          mt: "12px",
        }}
      >
        <DialogTitle
          sx={{
            ml: "9px",
            fontSize: "24px",
            fontFamily: "DM Sans",
            fontWeight: 700,
            color: "#121314",
          }}
        >
          Manage Top Workout Plans
        </DialogTitle>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            // position: "absolute",
            // top: "23px",
            // right: "30px",
            mr: "30px",
          }}
        >
          {/* <button
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "linear-gradient(to right, #464FEB, #8330E9)",
              borderRadius: "80px",
              color: "#fff",
              fontFamily: "DM Sans",
              fontWeight: "400",
              fontSize: "18px",
              padding: "6px 14px",
              height: "35px",
              marginRight: "20px",
            }}
            onClick={onDone}
          >
            Save
          </button> */}
          <img
            src={CloseIcon}
            style={{
              cursor: "pointer",
              width: "28px",
              height: "28px",
            }}
            onClick={() => {
              clearStates();
              setOpen(false);
            }}
          />
        </Box>
      </Box>
      {/* <Scrollbar style={{ width: "100%", height: "100%" }}> */}
      <DialogContent
        sx={{
          px: "30px",
          mt: "30px",
          overflow: "scroll",
          maxHeight: "709px",
        }}
      >
        {programs?.map((program, index) => {
          return (
            <>
              <Paper
                style={{
                  border: "0.572px solid #E1E1E6",
                  borderRadius: "4.572px",
                  boxShadow:
                    "1.1431034803390503px 2.2862069606781006px 5.715517520904541px 0px #2860CB1A",
                  height: "105px",
                  padding: "11px 16px 13px 14px", // top right bottom left
                  marginBottom: "20px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <Checkbox
                    icon={
                      <img
                        src={UncheckedIcon}
                        style={{ height: "24px", width: "24px" }}
                      />
                    }
                    checkedIcon={
                      <img
                        src={CheckedIcon}
                        style={{ height: "24px", width: "24px" }}
                      />
                    }
                    checked={programChecked[program.id]}
                    onChange={(e) =>
                      handleProgramChecked(program.id, e.target.checked)
                    }
                    sx={{
                      my: "auto",
                      mr: "20px",
                    }}
                  />
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontFamily: "DM Sans",
                        fontWeight: 500,
                        color: "#333338",
                        mt: "4px",
                      }}
                    >
                      {program?.name}
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mt: "15px",
                      }}
                    >
                      {program?.duration && (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "rgba(231, 232, 236, 0.63)",
                            padding: "5.144px 16.003px",
                            borderRadius: "5px",
                            height: "39.28px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "DM Sans",
                              fontSize: "12px",
                              fontWeight: 500,
                              color: "#333338",
                            }}
                          >
                            {program?.duration}
                          </Typography>
                          <Typography
                            sx={{
                              fontFamily: "DM Sans",
                              fontSize: "10px",
                              fontWeight: 400,
                              color: "#56565B",
                            }}
                          >
                            days
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <Select
                    variant="standard"
                    disableUnderline
                    value={selectedLevel?.[program?.id] || "Beginner"}
                    onChange={(e) =>
                      handleLevelChange(e.target.value, program?.id)
                    }
                    IconComponent={DropDownArrow}
                    sx={{
                      height: "30px",
                      backgroundColor: "#DCFFE6",
                      padding: "5.144px 10px",
                      fontFamily: "DM Sans",
                      fontSize: "14px",
                      fontWeight: 500,
                      color: "#21C34E",
                      ".MuiSvgIcon-root ": {
                        fill: "#21C34E !important",
                      },
                      borderRadius: "163px",
                      mb: "15px",
                    }}
                  >
                    <MenuItem value="Beginner">Beginner</MenuItem>
                    <MenuItem value="Intermediate">Intermediate</MenuItem>
                    <MenuItem value="Advanced">Advance</MenuItem>
                  </Select>
                  <Box
                    className="flex "
                    sx={{
                      pointerEvents: programChecked[program.id]
                        ? "auto"
                        : "none",
                      borderRadius: "3px",
                      border: programChecked[program.id]
                        ? "1px solid #1468E7"
                        : "1px solid #9EA2B0",
                      width: "190px",
                      overflow: "hidden",
                      height: "36px",
                    }}
                  >
                    <Select
                      variant="standard"
                      disableUnderline
                      value={selectedPriceCode?.[program.id] || "USD"}
                      onChange={(e) =>
                        handleCurrencyChange(e.target.value, program?.id)
                      }
                      IconComponent={DropDownArrow}
                      sx={{
                        height: "36px",
                        backgroundColor: programChecked[program.id]
                          ? "#EDF4FF"
                          : "#EFF0F3",
                        padding: "9px 10px 9px 10px",
                        fontFamily: "DM Sans",
                        fontSize: "14px",
                        fontWeight: 500,
                        color: "#313132",
                        ".MuiSvgIcon-root ": {
                          fill: "#313132 !important",
                        },
                      }}
                    >
                      {CURRENCY_OPTIONS.map((option) => (
                        <MenuItem value={option.value}>{option.label}</MenuItem>
                      ))}
                    </Select>
                    <input
                      type="number"
                      id="inputField"
                      placeholder={"PRICE"}
                      className="placeholder-blue-main placeholder-opacity-50"
                      style={{
                        width: "100%",
                        height: "100%",
                        padding: "9px 10px",
                        outline: "none",
                        fontFamily: "DM Sans",
                        fontWeight: 700,
                        fontSize: "16px",
                        color: "#202020",
                      }}
                      value={selectedPrice?.[program.id]}
                      onChange={(e) =>
                        setSelectedPrice((prevState) => {
                          let price = e.target.value;
                          let newState = { ...prevState };
                          newState[program.id] = price;
                          return newState;
                        })
                      }
                    />
                  </Box>
                </Box>
              </Paper>
            </>
          );
        })}
      </DialogContent>
      <DialogActions justifyContent={"center"}>
        <button
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "linear-gradient(to right, #464FEB, #8330E9)",
            borderRadius: "80px",
            color: "#fff",
            fontFamily: "DM Sans",
            fontWeight: "400",
            fontSize: "18px",
            padding: "6px 14px",
            height: "35px",
            marginRight: "auto",
            marginLeft: "auto",
          }}
          onClick={onDone}
        >
          Save
        </button>
      </DialogActions>
      {/* </Scrollbar> */}
    </Dialog>
  );
};

export default TopWorkoutsModal;

const DropDownArrow = (props) => {
  return (
    <ExpandMoreRoundedIcon
      {...props}
      sx={{
        marginRight: "10px",
      }}
    />
  );
};
