import React, { useState } from "react";
import { TextField, Typography, Button, Stack, styled } from "@mui/material";
import "../style.css";
import { makeStyles } from "@mui/styles";
import Checkbox from "@mui/material/Checkbox";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownTwoToneIcon from "@mui/icons-material/KeyboardArrowDownTwoTone";
import KeyboardArrowUpTwoToneIcon from "@mui/icons-material/KeyboardArrowUpTwoTone";
import {
  singleSelect,
  multiline,
  multiSelect,
  numberSelect,
} from "../formdata";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import FormDialog from "./FormDialog";
import { useSelector, useDispatch } from "react-redux";
import { setUserEditFormFunc } from "@/redux/formSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "80%",
    flexDirection: "column",
  },
  card: {
    borderRadius: 15,
    border: "1px solid #E5E7EB",
    transition: "0.3s",
    width: "80%",
    marginTop: 16,
    padding: "1rem",
    margin: ".6rem 0",
  },
  cardContent: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
  },
  input: {
    fontSize: 12,
    margin: 0,
    width: "70%",
    backgroundColor: "#F9FAFB",
    borderRadius: 4,
  },
  button: {
    marginTop: theme.spacing(2),
  },
  marginT: {
    marginTop: 10,
  },
  popover: {
    // Adjust top property to ensure it's positioned on top
    top: -10,
    left: "0%", // Center the popover horizontally
    transform: "translate(-50%, 0)", // Center the popover vertically
  },
  buttonStyle: {
    border: "1px solid #E5E7EB",
    "&:hover": {
      backgroundColor: "#E5E7EB",
    },
    borderRadius: 2,
    padding: ".6rem .2rem",
    width: "2.5rem",
    minWidth: "auto",
  },
}));

// const styledButton = styled(Button)(({ theme }) => ({
//   border: "1px solid #E5E7EB",
//   "&:hover": {
//     backgroundColor: "#E5E7EB",
//   },
//   borderRadius: 2,
//   padding: ".6rem .2rem",
//   width: "2.5rem",
//   minWidth: "auto",
// }));

const FormField = ({
  field,
  onChange,
  addAnswer,
  removeField,
  removeAnswer,
  length,
  fields,
  idx,
  id,
  isLoading,
  emptyFields,
  handleCheckAllQuestions,
  showError,
}) => {
  const classes = useStyles();

  const { pathname, search } = useLocation();

  const dispatch = useDispatch();

  const { singleFormData, isUserEditForm } = useSelector(
    (store) => store.userForms
  );

  const handleChange = (event, key, index) => {
    onChange(
      field.id,
      key,
      key == "required" ? event.target.checked : event.target.value,
      index
    );
  };

  const handleOptionChange = (event, index_o) => {
    onChange(field.id, event.target.value);
  };

  const bottomUrl = pathname + search + "#" + (id + 1);
  const upUrl = pathname + search + "#" + (id - 1);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            boxShadow: "0px 2px 5px -1px rgba(0,0,0,0.13)",
            backgroundColor: "#ffffff",
            zIndex: 99,
          }}
          className=" rounded-xl w-full overflow-y-auto"
        >
          <div
            className={"w-full overflow-y-auto"}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vw",
              backgroundColor: "#ffffff",
            }}
          >
            <CircularProgress size={20} sx={{ color: "#6242EA" }} />
          </div>
        </div>
      ) : (
        <div
          className="form-field flex justify-center items-center w-full"
          id={id}
        >
          {field.type == "Single Select" || field.type == "Multi Select" ? (
            <div className={classes.card}>
              <div className="dflex">
                <div className="flex flex-row items-center justify-between font-12 pb-2">
                  <div className="flex flex-row items-center my-3">
                    <div className="bg-[#EDEBFE] mr-2  px-2 py-1 flex flex-row items-center justify-center gap-2">
                      {" "}
                      <span>
                        {" "}
                        {field.type == "Single Select"
                          ? singleSelect
                          : multiSelect}{" "}
                      </span>
                      <span style={{ fontSize: 12 }}> {idx + 1}</span>
                    </div>
                    <span>{field.type}</span>
                  </div>
                  <Stack direction={"row"} gap={1} my={3}>
                    <a href={upUrl}>
                      <Button
                        sx={{
                          border: "1px solid #E5E7EB",
                          "&:hover": {
                            backgroundColor: "#E5E7EB",
                          },
                          borderRadius: 2,
                          padding: ".6rem .2rem",
                          width: "2.5rem",
                          minWidth: "auto",
                        }}
                        disabled={idx == 0}
                      >
                        <KeyboardArrowUpTwoToneIcon />
                      </Button>
                    </a>
                    <a href={bottomUrl}>
                      <Button
                        size="small"
                        sx={{
                          border: "1px solid #E5E7EB",
                          "&:hover": {
                            backgroundColor: "#E5E7EB",
                          },
                          borderRadius: 2,
                          padding: ".6rem .2rem",
                          width: "2.5rem",
                          minWidth: "auto",
                        }}
                        disabled={idx == fields?.length - 1}
                      >
                        <KeyboardArrowDownTwoToneIcon />
                      </Button>
                    </a>
                  </Stack>
                </div>
                {showError && !field.value && emptyFields.includes(field.id) ? (
                  <Typography variant="caption" color={"red"}>
                    this field is required*
                  </Typography>
                ) : (
                  ""
                )}
                <TextField
                  placeholder="Write a Question"
                  variant="outlined"
                  key={field.id}
                  className={classes.input}
                  style={{
                    border:
                      showError &&
                      !field.value &&
                      emptyFields.includes(field.id)
                        ? "1px solid red"
                        : "none",
                  }}
                  name="name"
                  size="small"
                  value={field.value}
                  onChange={(event) => handleChange(event, "value")}
                />
                <div sx={{ marginLeft: "-2rem" }}>
                  <Checkbox
                    checked={field.required}
                    onChange={(event) => handleChange(event, "required")}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <span style={{ fontSize: 14 }}>
                    This question is required
                  </span>
                </div>
                {field?.possibleAnswers?.map((options, index_o) => (
                  <div className="flex items-center justify-start gap-5 pt-2">
                    <TextField
                      variant="outlined"
                      className={classes.input}
                      name="des"
                      style={{
                        border:
                          !options && showError ? "1px solid red" : "unset",
                      }}
                      size="small"
                      value={options}
                      onChange={(event) =>
                        handleChange(event, "possibleAnswers", index_o)
                      }
                    />
                    <CloseIcon
                      onClick={() => removeAnswer(field.id, index_o)}
                      style={{
                        textAlign: "center",
                        opacity: field.presetData ? 0 : 1,
                      }}
                    />
                  </div>
                ))}
                <div className="flex items-center justify-between pt-5 w-2/3">
                  <Button
                    sx={{
                      borderRadius: 2,
                      alignSelf: "center",
                      fontSize: ".9rem",
                      wordSpace: "nowrap",
                      padding: ".5rem 1.5rem",
                      color: "#FFFFFF",
                      background:
                        "linear-gradient(to right, rgb(70, 79, 235), rgb(131, 48, 233))",
                      textTransform: "capitalize",
                    }}
                    onClick={() => addAnswer(field.id)}
                    id="btn-addans-button"
                  >
                    Add Answer
                  </Button>
                  <Typography
                    onClick={() => removeField(field.id)}
                    variant="text"
                    color={"#6B7280"}
                    size="small"
                    pr={5}
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        color: "#374151",
                      },
                      display: field.presetData ? "none" : "block",
                    }}
                  >
                    Remove
                  </Typography>
                </div>
              </div>
            </div>
          ) : (
            <div className={classes.card}>
              <div className="dflex">
                <div className="flex flex-row items-center justify-between font-12 pb-2">
                  <div className="flex flex-row items-center my-3">
                    <div className="bg-[#EDEBFE] mr-2 px-2 py-1 flex flex-row items-center justify-center gap-2">
                      {" "}
                      <span>
                        {" "}
                        {field.type == "Number" ? numberSelect : multiline}{" "}
                      </span>
                      <span style={{ fontSize: 12 }}> {idx + 1}</span>
                    </div>
                    <span>{field.type}</span>
                  </div>
                  <Stack direction={"row"} gap={1} my={3}>
                    <a href={upUrl}>
                      <Button
                        sx={{
                          border: "1px solid #E5E7EB",
                          "&:hover": {
                            backgroundColor: "#E5E7EB",
                          },
                          borderRadius: 2,
                          padding: ".6rem .2rem",
                          width: "2.5rem",
                          minWidth: "auto",
                        }}
                        disabled={idx == 0}
                      >
                        <KeyboardArrowUpTwoToneIcon />
                      </Button>
                    </a>
                    <a href={bottomUrl}>
                      <Button
                        sx={{
                          border: "1px solid #E5E7EB",
                          "&:hover": {
                            backgroundColor: "#E5E7EB",
                          },
                          borderRadius: 2,
                          padding: ".6rem .2rem",
                          width: "2.5rem",
                          minWidth: "auto",
                        }}
                        disabled={idx == fields?.length - 1}
                      >
                        <KeyboardArrowDownTwoToneIcon />
                      </Button>
                    </a>
                  </Stack>
                </div>
                {showError && !field.value && emptyFields.includes(field.id) ? (
                  <Typography variant="caption" color={"red"}>
                    this field is required*
                  </Typography>
                ) : (
                  ""
                )}
                <div className="flex items-center justify-start gap-5">
                  <TextField
                    style={{
                      backgroundColor: "#F9FAFB",
                      width: "70%",
                      border:
                        showError &&
                        !field.value &&
                        emptyFields.includes(field.id)
                          ? "1px solid red"
                          : "none",
                    }}
                    placeholder="Write a Question"
                    variant="outlined"
                    key={field?.id}
                    className={classes.input}
                    name="name"
                    size="small"
                    value={field.value}
                    onChange={(event) => handleChange(event, "value")}
                  />
                  {/* <CloseIcon
                  onClick={() => removeAnswer(field.id, index_o)}
                  style={{
                    textAlign: "center",
                    opacity: field.presetData ? 0 : 1,
                  }}
                /> */}
                </div>
                <div className="flex items-center justify-between py-2">
                  <div>
                    <Checkbox
                      checked={
                        (field.required && field.presetData) || field.required
                      }
                      disabled={field.presetData}
                      onChange={(event) => handleChange(event, "required")}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <span style={{ fontSize: 14 }}>
                      This question is required
                    </span>
                  </div>
                  <div>
                    <Typography
                      onClick={() => removeField(field.id)}
                      variant="text"
                      color={"#6B7280"}
                      size="small"
                      pr={5}
                      sx={{
                        cursor: "pointer",
                        "&:hover": {
                          color: "#374151",
                        },
                        display: field.presetData ? "none" : "block",
                      }}
                    >
                      Remove
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <FormDialog
        open={isUserEditForm}
        onClose={() => dispatch(setUserEditFormFunc(false))}
        // singleFormData={singleFormData}
      />
    </>
  );
};

export default FormField;
