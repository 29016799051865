import Layout from "@/components/layout/Layout";
import React, { useEffect } from "react";

import {
  cancelSubscriptionRazorPay,
  cancelSubscriptionStripe,
  createSubscriptionRazorPay,
  createSubscriptionStripe,
  getBillingHistory,
  getCoachesCurrentSubscriptionDetails,
  pauseSubscriptionRazorPay,
  pauseSubscriptionStripe,
  resumeSubscriptionRazorPay,
  resumeSubscriptionStripe,
} from "@/ApiServices/CoachSubscriptions/Api";
import { useSelector } from "react-redux";
import BillingDetails from "./BillingDetails";
import PricingCard from "./PricingCard";
import SubscriptionDetail from "./SubscriptionDetail";
import { NotifyDetails } from "./notifyDetails";
import { Button } from "@/components/ui/button";

const CoachSubscription = () => {
  const coachID = useSelector((state) => state.auth.trainerUUID);
  const coachName = useSelector((state) => state.auth?.data?.name);
  const coachEmail = useSelector((state) => state.auth?.data?.email);
  const phoneNumber = useSelector((state) => state.auth?.data?.contactNumber);
  const countryCode = useSelector((state) => state.auth?.data?.countryCode);
  const trainingType = useSelector((state) => state.auth.data?.trainingType);

  const buyingWhiteLabel = useSelector(
    (state) => state.auth?.data?.buyingWhiteLabel
  );
  const athleteCount = useSelector((state) => state.auth?.athleteCount);

  const [notifyEmail, setNotifyEmail] = React.useState(coachEmail ?? "");
  const [notifyPhone, setNotifyPhone] = React.useState(phoneNumber ?? "");
  const [isNotifyOpen, setIsNotifyOpen] = React.useState(false);

  const [subscriptionDetails, setSubscriptionDetails] = React.useState({});
  const [billingHistory, setBillingHistory] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [selectedPlan, setSelectedPlan] = React.useState("");

  const shouldShowRazorpay = countryCode === "IN";
  const [isLoadingStatus, setIsLoadingStatus] = React.useState(false);

  const [planforCoach, setPlanforCoach] = React.useState(null);

  const [isCreatingSubscription, setIsCreatingSubscription] =
    React.useState(false);
  const [creatingIndex, setCreatingIndex] = React.useState(null);

  const getSubscriptionDetail = async () => {
    setLoading(true);
    let res = await getCoachesCurrentSubscriptionDetails(coachID);
    let billingHistory = await getBillingHistory(coachID);

    if (res?.result) {
      setSubscriptionDetails(res.result);
    }
    if (billingHistory?.result) {
      setBillingHistory(billingHistory.result);
    }
    setLoading(false);
  };

  const cancelSubscription = async () => {
    setIsLoadingStatus(true);
    if (shouldShowRazorpay) {
      let res = await cancelSubscriptionRazorPay({
        subscriptionID: subscriptionDetails?.[0]?.id,
        cancelNow: true,
      });

      if (res?.result?.isCancelled) {
        console.log("cancelSubscriptionRazorPay", res);
        // set status of subscription to "cancelled"
        let updatedSubscriptionDetail = { ...subscriptionDetails[0] };
        updatedSubscriptionDetail.isCancelled = true;
        setSubscriptionDetails([updatedSubscriptionDetail]);
      }
    } else {
      let res = await cancelSubscriptionStripe({
        subscriptionID: subscriptionDetails?.[0]?.subscriptionId,
        cancelNow: true,
      });

      if (res?.result?.isCancelled) {
        console.log("cancelSubscriptionStripe", res);
        // set status of subscription to "cancelled"
        let updatedSubscriptionDetail = { ...subscriptionDetails[0] };
        updatedSubscriptionDetail.isCancelled = true;
        setSubscriptionDetails([updatedSubscriptionDetail]);
      }
    }

    setIsLoadingStatus(false);
  };

  const pauseSubscription = async () => {
    setIsLoadingStatus(true);
    if (shouldShowRazorpay) {
      let res = await pauseSubscriptionRazorPay({
        subscriptionID: subscriptionDetails?.[0]?.id,
      });
      if (res.result?.status) {
        // set status of subscription to "paused"
        let updatedSubscriptionDetail = { ...subscriptionDetails[0] };
        updatedSubscriptionDetail.status = "paused";
        setSubscriptionDetails([updatedSubscriptionDetail]);
      }
    } else {
      let res = await pauseSubscriptionStripe({
        subscriptionID: subscriptionDetails?.[0]?.subscriptionId,
      });

      if (res.result?.status && res.result?.isPaused === true) {
        let updatedSubscriptionDetail = { ...subscriptionDetails[0] };

        updatedSubscriptionDetail.status = "paused";
        setSubscriptionDetails([updatedSubscriptionDetail]);
      }
    }
    setIsLoadingStatus(false);
  };

  const resumeSubscription = async () => {
    setIsLoadingStatus(true);
    if (shouldShowRazorpay) {
      let res = await resumeSubscriptionRazorPay({
        subscriptionID: subscriptionDetails?.[0]?.id,
      });
      if (res.result?.status) {
        // set status of subscription to "active"
        let updatedSubscriptionDetail = { ...subscriptionDetails[0] };
        updatedSubscriptionDetail.status = "active";
        setSubscriptionDetails([updatedSubscriptionDetail]);
      }
    } else {
      let res = await resumeSubscriptionStripe({
        subscriptionID: subscriptionDetails?.[0]?.subscriptionId,
      });

      if (
        res.result?.status &&
        res.result?.isPaused === false &&
        res.result?.isCancelled === false
      ) {
        let updatedSubscriptionDetail = { ...subscriptionDetails[0] };
        updatedSubscriptionDetail.status = "active";
        setSubscriptionDetails([updatedSubscriptionDetail]);
      }
    }
    setIsLoadingStatus(false);
  };

  useEffect(() => {
    getPlan();
    getSubscriptionDetail();
  }, []);

  const createSubscription = async (index) => {
    setCreatingIndex(index);
    setIsCreatingSubscription(true);
    if (shouldShowRazorpay) {
      if (!notifyEmail && !notifyPhone) {
        setIsNotifyOpen(true);
        return;
      }

      let razorPayPlanID = planforCoach.razorPayPlanID;

      //  TODO: Step-2 Remove this when we have have set the planIDs for all the plans
      if (selectedPlan === "silver") {
        razorPayPlanID = "plan_O06SaSXPbRK4yU";
      } else if (selectedPlan === "gold") {
        razorPayPlanID = "plan_NrHZlFalBd3wpn";
      } else if (selectedPlan === "platinum") {
        razorPayPlanID = "plan_NrHZlFalBd3wpn";
      }
      let res = await createSubscriptionRazorPay({
        coachID,
        notifyEmail,
        notifyPhone,
        razorPayPlanID: razorPayPlanID,
      });

      console.log("createSubscriptionRazorPay", res);
      let razpaySubscriptionID = res.razpaySubscriptionID;
      doRazorpayCall(razpaySubscriptionID);
    } else {
      let res = await createSubscriptionStripe({
        coachID: coachID,
      });
      console.log("createSubscriptionStripe", res);

      let url = planforCoach.link;
      // if (selectedPlan === "silver") {
      //   url = `https://buy.stripe.com/3csaHQ9VO6uies03cv`;
      // } else if (selectedPlan === "gold") {
      //   url = `https://buy.stripe.com/bIY03cfg87ymabKdRa`;
      // } else if (selectedPlan === "platinum") {
      //   url = `https://buy.stripe.com/bIY03cfg87ymabKdRa`;
      // }

      url += `?prefilled_email=${encodeURIComponent(
        coachEmail
      )}&client_reference_id=${res.referenceID}`;

      // window.open(url, "_blank");
      window.location.href = url;
    }

    setIsCreatingSubscription(false);
  };

  const isSubscribed =
    subscriptionDetails?.[0]?.status &&
    subscriptionDetails?.[0]?.status !== "cancelled" &&
    subscriptionDetails?.[0]?.status !== "canceled";
  const isProcessing = subscriptionDetails?.[0]?.isProcessing;

  const isSubscriptionStatusUnkonwn =
    subscriptionDetails?.[0]?.status !== "active" &&
    subscriptionDetails?.[0]?.status !== "paused" &&
    subscriptionDetails?.[0]?.status !== "cancelled" &&
    subscriptionDetails?.[0]?.status !== "canceled";

  const doRazorpayCall = (razpaySubscriptionID) => {
    const rzp_options = {
      key: "rzp_test_1TEbwT3HcCCbOy",
      subscription_id: razpaySubscriptionID,
      name: "Spur.fit",
      image: "https://trainer.spur.fit/assets/spurfit-IH-gtDUV.svg",
      callback_url: `${window.origin}/CoachPaymentSuccess?id=${razpaySubscriptionID}`,
      redirect: true,
      prefill: {
        name: coachName,
        email: coachEmail,
        // contact: phoneNumber,
      },
      notes: {
        note_key_1: "Tea. Earl Grey. Hot",
        note_key_2: "Make it so.",
      },
      theme: {
        color: "#8330e9",
      },
    };
    var rzp1 = new Razorpay(rzp_options);
    rzp1.open();
  };

  if (isProcessing) {
    return (
      <Layout>
        <div
          className="w-full flex justify-center items-center gap-4"
          style={{ height: "calc(100vh - 100px)" }}
        >
          <div
            className="w-full h-full bg-white-pure rounded-xl shadow-xl p-4 overflow-y-auto flex justify-center gap-4"
            style={{ height: "calc(100vh - 100px)" }}
          >
            <div className="flex flex-col items-center justify-center gap-4">
              <h1 className="font-DMSans text-font32 font-semibold text-[#8330e9]">
                Payment Processing
              </h1>
              {/* <img
                src="https://img.icons8.com/color/48/000000/ok--v1.png"
                alt="success"
              /> */}
              <p className="font-DMSans text-font16 text-muted-foreground">
                Your payment is being processed. It may take a few minutes to
                reflect in your account.
              </p>

              <Button
                className="bg-violet-500 text-white-pure border-violet-500 w-full mt-4"
                onClick={() => {
                  window.location.reload();
                }}
              >
                Refresh
              </Button>
            </div>
          </div>
        </div>
      </Layout>
    );
  }

  const getPlan = () => {
    // get the plan based on the athlete count and white label(buyingWhiteLabel)

    let isEndurance = trainingType === "ENDURANCE";
    let plan;

    let isRazorPay = countryCode === "IN";

    if (isRazorPay) {
      if (coachID === "GrwKuBFXAxwl") {
        plan = razorpayPlans[4];
      } else if (coachID === "fHzLMnWkXuXL") {
        plan = razorpayPlans[5];
      } else if (
        coachID === "wvVOGLnGSUtDYQHDRw" ||
        coachID === "IyRNsvRlTjIcYSHacK"
      ) {
        plan = razorpayPlans[8];
      } else if (isEndurance) {
        plan = buyingWhiteLabel ? razorpayPlans[7] : razorpayPlans[6];
      } else if (athleteCount <= 30) {
        plan = buyingWhiteLabel ? razorpayPlans[2] : razorpayPlans[0];
      } else {
        plan = buyingWhiteLabel ? razorpayPlans[3] : razorpayPlans[1];
      }
    } else {
      if (coachID === "e3abd233-a6fc-4e53-8615-453a08f14f9a") {
        plan = plans[8];
        setPlanforCoach(plan);
        return;
      } else if (coachID === "LcmTrYrnoxIF") {
        plan = plans[4];
        setPlanforCoach(plan);
        return;
      } else if (coachID === "KxwiDeScRcXl") {
        plan = plans[7];
      } else if (isEndurance) {
        plan = buyingWhiteLabel ? plans[6] : plans[5];
      } else if (athleteCount <= 30) {
        plan = buyingWhiteLabel ? plans[2] : plans[0];
      } else {
        plan = buyingWhiteLabel ? plans[3] : plans[1];
      }
    }
    setPlanforCoach(plan);
  };

  return (
    <Layout>
      <NotifyDetails
        isOpen={isNotifyOpen}
        email={notifyEmail}
        phone={notifyPhone}
        setEmail={setNotifyEmail}
        setPhone={setNotifyPhone}
        setIsOpen={setIsNotifyOpen}
        onSuccess={() => {
          setIsNotifyOpen(false);
          createSubscription();
        }}
      />
      <div
        className="w-full h-full bg-white-pure rounded-xl shadow-xl p-4 overflow-y-scroll flex flex-col items-center gap-4"
        style={{
          height: "calc(100vh - 100px)",
        }}
      >
        {!isSubscribed ? (
          <div className="w-full flex justify-center items-center gap-4">
            <PricingCard
              isCreatingSubscription={
                isCreatingSubscription && creatingIndex === 0
              }
              header={planforCoach?.name}
              price={planforCoach?.price}
              intervalInMonths={planforCoach?.intervalInMonths}
              topFeatures={[
                "Includes first 10 clients / month",
                "200 INR / client/ month for over 10 clients",
              ]}
              includes={[
                "AI-powered CoPilot for personalized meal and workout plans",
                "Compatible with wearable devices",
                "Stripe-enabled direct payments",
                "Integrated nutrition planning tools",
                "Multi-source video uploading capability",
                "Automated trigger-based in-app messaging",
              ]}
              onUpgrade={() => {
                //setSelectedPlan("silver");
                createSubscription(0);
              }}
            />

            {/* <PricingCard
              isCreatingSubscription={
                isCreatingSubscription && creatingIndex === 1
              }
              header="Platinum"
              price={"5 INR"}
              topFeatures={[
                "Includes first 10 clients / week",
                "200 INR / client/ month for over 10 clients",
              ]}
              includes={[
                "Extensive exercise library",
                "Easy to use workout builder",
                "Client progress tracking",
                "Workout scheduling",
                "Client communication",
                "Programs",
                "Nutrition plans",
                "Client assessments",
                "Client management",
                "Client billing",
                "Client reminders",
                "Client feedback",
              ]}
              onUpgrade={() => {
                setSelectedPlan("platinum");
                createSubscription(1);
              }}
            /> */}
          </div>
        ) : (
          <SubscriptionDetail
            isRazorpay={shouldShowRazorpay}
            subscriptionDetails={subscriptionDetails}
            latestBilling={billingHistory[0]}
            loading={loading || isLoadingStatus}
            isSubscriptionStatusUnkonwn={isSubscriptionStatusUnkonwn}
            onPause={pauseSubscription}
            onResume={resumeSubscription}
            onCancel={cancelSubscription}
            onRetryPayment={() => {
              createSubscription();
            }}
          />
        )}
        {!loading && billingHistory?.length > 0 && (
          <BillingDetails
            billingHistory={billingHistory}
            subscriptionDetails={subscriptionDetails}
            loading={loading}
            countryCode={countryCode}
          />
        )}
      </div>
    </Layout>
  );
};

export default CoachSubscription;

export const silverPlanContent = {
  header: "Silver",
  price: "$29.99",
  topFeatures: [
    "Includes first 10 clients / month",
    "$2/ client/ month for over 10 clients",
  ],
  includes: [
    "Extensive exercise library",
    "Easy to use workout builder",
    "Client progress tracking",
    "Workout scheduling",
    "Client communication",
    "Programs",
    "Nutrition plans",
    "Client assessments",
    "Client management",
    "Client billing",
    "Client reminders",
    "Client feedback",
  ],
};

export const platinumPlanContent = {
  header: "Platinum",
  price: "$99.99",
  topFeatures: [
    "Includes first 10 clients / month",
    "$2/ client/ month for over 10 clients",
  ],
  includes: [
    "Extensive exercise library",
    "Easy to use workout builder",
    "Client progress tracking",
    "Workout scheduling",
    "Client communication",
    "Programs",
    "Nutrition plans",
    "Client assessments",
    "Client management",
    "Client billing",
    "Client reminders",
    "Client feedback",
  ],
};

const plans = [
  {
    name: "Silver Pricing Plan",
    price: "$30",
    link: "https://buy.stripe.com/eVa17gc3W19Yfw4aEZ",
    coupon: "STARTER",
    clientCount: "< 30",
  },

  {
    name: "Gold Pricing Plan",
    price: "$59",
    link: "https://buy.stripe.com/eVa6rA1pig4SabKdRc",
    coupon: "PREMIUM",
    clientCount: "> 30",
  },

  {
    name: "Silver Plan with white labeling",
    price: "$30",
    link: "https://buy.stripe.com/8wM3fo7NG2e2fw45kH",
    coupon: "STARTERWB",
    clientCount: "< 30",
  },

  {
    name: "Gold Plan with white labeling",
    price: "$59",
    link: "https://buy.stripe.com/8wMcPYgkc9Gues028w",
    coupon: "PREMIUMWB",
    clientCount: "> 30",
  },

  {
    name: "Custom Plan - Jason",
    price: "$15",
    link: "https://buy.stripe.com/7sI4js7NGaKy2Ji6oN",
    coupon: "DISCOUNTEDWB",
    clientCount: "Based on UUID",
  },

  {
    name: "Endurance Coaches",
    price: "$20",
    link: "https://buy.stripe.com/7sI9DM2tmaKydnWbJ8",
    coupon: "ENDURANCE",
    clientCount: "Based on flag",
  },

  {
    name: "Endurance Coaches white labeling",
    price: "$20",
    link: "https://buy.stripe.com/fZedU23xq05U5Vu9B1",
    coupon: "ENDURANCEWB",
    clientCount: "Based on flag",
  },
  {
    name: "Custom Plan - KxwiDeScRcXl",
    price: "$267",
    intervalInMonths: 3,
    link: "https://buy.stripe.com/8wM3foec43i6gA8dRj",
    coupon: "TESTHDFC",
    clientCount: "Based on flag",
  },
  // Endurance Coaches white labeling 	$20	$40	https://buy.stripe.com/fZedU23xq05U5Vu9B1	ENDURANCEWB	Based on flag
  {
    name: "Custom Plan - Sarah",
    price: "$15",
    link: "https://buy.stripe.com/7sI4js7NGaKy2Ji6oN",
    clientCount: "Based on flag",
  },
];

// TODO: step-1: Add the planIDs for all the plans
const razorpayPlans = [
  {
    name: "Silver Pricing Plan",
    price: `2500 INR`,
    oneTime: null,
    razorPayPlanID: "",
    couponCode: "STARTER",
    clientCount: "< 30",
  },
  {
    name: "Gold Pricing Plan",
    price: `4000 INR`,
    oneTime: null,
    razorPayPlanID: "",
    couponCode: "PREMIUM",
    clientCount: "> 30",
  },
  {
    name: "Silver Plan with white labeling",
    price: `2500 INR`,
    oneTime: 3000,
    razorPayPlanID: "",
    couponCode: "STARTERWB",
    clientCount: "< 30",
  },
  {
    name: "Gold Plan with white labeling",
    price: `4000 INR`,
    oneTime: 3000,
    razorPayPlanID: "",
    couponCode: "PREMIUMWB",
    clientCount: "> 30",
  },
  {
    name: "Custom Plan - Karan",
    price: `1500 INR`,
    oneTime: null,
    razorPayPlanID: "",
    couponCode: "DISCOUNTEDSTARTER",
    logic: "Based on UUID",
  },
  {
    name: "Custom Plan - Gaurav",
    price: `2000 INR`,
    oneTime: null,
    razorPayPlanID: "",
    couponCode: "DISCOUNTEDPREMIUM",
    logic: "Based on UUID",
  },
  {
    name: "Endurance Coaches",
    price: `2000 INR`,
    oneTime: null,
    razorPayPlanID: "",
    couponCode: "ENDURANC",
    logic: "Based on Flag",
  },
  {
    name: "Endurance Coaches white labeling",
    price: `2000 INR`,
    oneTime: 3000,
    razorPayPlanID: "",
    couponCode: "ENDURANCEWB",
    logic: "Based on Flag",
  },
  {
    name: "Spur.fit Pro Plan",
    price: `2000 INR`,
    oneTime: null,
    razorPayPlanID: "plan_Nu4fomDpocQu51",
    couponCode: "TESTHDFC",
    logic: "Based on Flag",
  },
];
