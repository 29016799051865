import { CloseRounded } from "@mui/icons-material";
import moment from "moment";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import Select from "react-select";
import { SpurfitCircularProgress } from "../../components/Dashboard/dashboard";
import { getAllProgramsWithPhasesByCoachID } from "@/ApiServices/Programs/Api";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import CustomSelect from "../EnduranceWorkoutBuilder/utils/CustomSelect";

const customStyles = {
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
    position: "absolute",
    fontFamily: "DM Sans",
    top: 0,
  }),
  control: (provided) => ({
    ...provided,
    fontFamily: "DM Sans",
    borderWidth: 0,
  }),
  option: (provided, state) => ({
    ...provided,
    fontFamily: "DM Sans",
    backgroundColor: state.isSelected ? "white" : "white",
    color: state.isSelected ? "black" : "#424242",
    "&:hover": {
      backgroundColor: "#e3e3e3",
    },
  }),
};

const PlanStartForUser = ({
  athleteData,
  onSave,
  onClose,
  selectedOption,
  setSelectedOption,
  selectedPhase,
  setSelectedPhase,
  startDate,
  setStartDate,
  selectedDay,
  setSelectedDay,
}) => {
  const trainerUUID = useSelector((state) => state.auth.trainerUUID);
  const [preSelectedProgram, setPreSelectedProram] = useState(null);

  const getAllPrograms = async () => {
    try {
      let res = await getAllProgramsWithPhasesByCoachID(trainerUUID);
      let data = res?.filter((obj) => {
        return obj.id === athleteData?.phasedProgramID;
      });
      setPreSelectedProram(data);
    } catch (error) {
      console.log(error);
    }
  };

  const selectedData = preSelectedProgram?.[0];

  useEffect(() => {
    getAllPrograms();
  }, [athleteData]);

  useEffect(() => {
    if (selectedData?.programs && selectedData.programs.length > 0) {
      const sortedPrograms = selectedData.programs.sort(
        (a, b) => a.phaseOrderIdx - b.phaseOrderIdx
      );
      const firstOption = sortedPrograms[0];
      setSelectedPhase(firstOption.id);
    }
  }, [selectedData]);

  const days = Array.from({ length: 365 }, (_, i) => {
    const dayNumber = i + 1;
    if (dayNumber % 7 === 1) {
      const weekNumber = Math.ceil(dayNumber / 7);
      return [
        { value: "week", label: `Week ${weekNumber}`, isDisabled: true },
        { value: dayNumber, label: `Day ${dayNumber}` },
      ];
    }
    return { value: dayNumber, label: `Day ${dayNumber}` };
  }).flat();

  // useEffect(() => {
  //   const getAllPrograms = async () => {
  //     try {
  //      await getAllProgramsWithPhasesByCoachID(trainerUUID);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   getAllPrograms();
  // }, [trainerUUID]);

  const handleDayChange = (newValue) => {
    setSelectedDay(newValue);
  };

  const formatOptionLabel = ({ value, label }) => (
    <div style={label.startsWith("Week") ? { fontWeight: "bold" } : {}}>
      {label}
    </div>
  );

  return (
    <div>
      <div className="add-program-modal-head">
        <p className="add-program-modal-head-title">Plan start for User</p>
        <div
          onClick={() => {
            onClose();
          }}
          style={{
            color: "#000",
            cursor: "pointer",
          }}
        >
          <CloseRounded color="#000" />
        </div>
      </div>

      <div className="add-program-modal-body">
        <div className="full-width-container">
          {!selectedData?.name ? (
            <div
              style={{
                backgroundColor: "#fff",
                opacity: 0.5,
                zIndex: 100,
              }}
            >
              <SpurfitCircularProgress />
            </div>
          ) : (
            <div>
              <div
                className="channel-search__input__wrapper"
                style={{
                  minWidth: "500px",
                  maxWidth: "500px",
                  display: "flex",
                  flexWrap: "wrap",
                  height: "auto",
                  justifyContent: "flex-start",
                }}
              >
                {selectedData?.name ? (
                  <div className="flex items-center gap-1 px-3 py-2 rounded-full bg-white-pure">
                    <p
                      style={{
                        color: "#333",
                      }}
                    >
                      {selectedData?.name}
                    </p>
                  </div>
                ) : (
                  <div
                    style={{
                      width: "100%",
                    }}
                    className="channel-search__input__icon"
                  ></div>
                )}
              </div>
              <div className="add-program-modal-body">
                <div className="full-width-container">
                  <div className="flex flex-col items-center justify-center gap-2 mt-2">
                    <RadioGroup
                      defaultValue="All Phases"
                      value={selectedOption}
                      onValueChange={setSelectedOption}
                    >
                      <div className="flex items-center gap-4">
                        <div className="flex items-center space-x-2">
                          <RadioGroupItem value="All Phases" id="All Phases" />
                          <label
                            className="text-black-pure font-DMSans"
                            htmlFor="All Phases"
                          >
                            All Phases
                          </label>
                        </div>
                        <div className="flex items-center space-x-2">
                          <RadioGroupItem
                            value="custom-phases"
                            id="custom-phases"
                          />
                          <label
                            className="text-black-pure font-DMSans"
                            htmlFor="custom-phases"
                          >
                            Custom Phase
                          </label>
                        </div>
                      </div>
                    </RadioGroup>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: 8,
                      }}
                    >
                      {selectedOption === "custom-phases" && (
                        <div className="mb-[15px]">
                          <label
                            className="text-black-pure font-DMSans text-font12 ml-3"
                            htmlFor="custom-phases"
                          >
                            Select Start Phase
                          </label>
                          <CustomSelect
                            contentClassName="z-[9999]"
                            className={"text-black-pure focus:ring-transparent"}
                            value={selectedPhase}
                            onValueChange={(value) => {
                              console.log("value", value);
                              setSelectedPhase(value);
                            }}
                            options={(selectedData?.programs || [])
                              ?.sort(
                                (a, b) => a.phaseOrderIdx - b.phaseOrderIdx
                              )
                              .map((item) => {
                                return {
                                  label: item.name,
                                  value: item.id,
                                };
                              })}
                          />
                        </div>
                      )}
                      <div className="ml-[12px]">
                        <label
                          htmlFor="inputField"
                          style={{
                            display: "block",
                            color: "#000",
                            fontSize: 10,
                            marginLeft: 12,
                          }}
                        >
                          Program Start Date
                        </label>
                        <DatePicker
                          dateFormat="dd MMM yyyy"
                          minDate={moment().startOf("day").toDate()}
                          selected={startDate}
                          onChange={setStartDate}
                          customInput={
                            <input
                              type="text"
                              style={{
                                width: "128px",
                                height: "40px",
                                padding: "10px",
                                outline: "none",
                                fontFamily: "DM Sans",
                                cursor: "pointer",
                                color: "#000",
                              }}
                            />
                          }
                        />
                      </div>
                      {selectedOption !== "All Phases" && (
                        <div className="flex flex-col">
                          <label
                            htmlFor="inputField"
                            style={{
                              display: "block",
                              fontSize: 10,
                              marginLeft: 12,
                              color: "#000",
                            }}
                          >
                            Starting Day
                          </label>
                          <Select
                            styles={customStyles}
                            options={days}
                            value={selectedDay}
                            onChange={handleDayChange}
                            formatOptionLabel={formatOptionLabel}
                            placeholder="Select day"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="flex justify-end items-center w-full">
                  {onSave && (
                    <button className="button-new" onClick={onSave}>
                      Save
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlanStartForUser;
