import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import { Box, useMediaQuery } from "@mui/material";
import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  ChannelList,
  Chat,
  Streami18n,
  enTranslations,
  useChatContext,
} from "stream-chat-react";
import {
  getClientProfileSummary,
  getMultiClientProfile,
  getMultiClientProfileSummary,
  getTraineeDetails,
  getClientProfileByDateRange,
} from "../../ApiServices/ClientProfileApi/Api";
import BroadCast from "../../components/ClientsView/BroadCast";
import ClientDetails from "../../components/ClientsView/ClientDetails";
import ClientList from "../../components/ClientsView/ClientList";
import ClientsSearch from "../../components/ClientsView/ClientsSearch";
import Layout from "../../components/layout/Layout";
import { chatClient, chatInit } from "../../utils/chat";
import { getTrainerDashboard } from "../../utils/clientsView";
import { getStreamclient } from "../../utils/getStreamclients";
import { ChannelContainer } from "../getStreamChat/components/ChannelContainer/ChannelContainer";
import { TeamChannelList } from "../getStreamChat/components/TeamChannelList/TeamChannelList";
import { TeamChannelPreview } from "../getStreamChat/components/TeamChannelPreview/TeamChannelPreview";
import TemplatesDialog from "../../components/ClientsView/TemplatesDialog";
import moment from "moment";
import WorkoutLibrary from "@/components/ClientsView/WorkoutLibrary";
import { DragDropContext } from "react-beautiful-dnd";

const i18nInstance = new Streami18n({
  language: "en",
  translationsForLanguage: {
    ...enTranslations,
  },
});
const options = { state: true, watch: true, presence: false };
const sort = [];

export const ClientsViewContext = React.createContext();

const Index = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const clientParamId = queryParams.get("userUUID");
  const assigned = queryParams.get("assigned");
  const coachID = useSelector((state) => state?.auth?.trainerUUID);

  const [createType, setCreateType] = useState("");
  const [isCreating, setIsCreating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [ready, setReady] = useState(false);
  const [clientIds, setClientIds] = useState([]);
  const [filters, setFilter] = useState();
  const [dashData, setDashData] = useState({});
  const [clientsList, setClientsList] = useState([]);
  const [originalClientsList, setOriginalClientsList] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);

  const [clientsSummary, setClientsSummary] = useState({});
  const [clientsProfileData, setClientsProfileData] = useState({});
  const [clientsMealPlanData, setClientsMealPlanData] = useState({});
  const [clientsActivityData, setClientsActivityData] = useState({});
  const [clientProfileRaw, setClientProfileRaw] = useState({});

  const [isUsersOpen, setIsUsersOpen] = useState(true);
  const [isChannelsOpen, setIsChannelsOpen] = useState(true);
  const [isBroadcast, setIsBroadcast] = useState(false);
  const [openWorkoutInfoModal, setOpenWorkoutInfoModal] = useState(false);
  const [loading, setLoading] = useState(false);
  // a toogle state to switch between client list and client details
  const [showClientDetails, setShowClientDetails] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const isSmallerThanMd = useMediaQuery((theme) =>
    theme.breakpoints.down("md")
  );
  const [userUnreadMessage, setUserUnreadMessage] = useState({});
  const [userWiseChannel, setUserWiseChannel] = useState({});
  const [userChannelIDMap, setUserChannelIDMap] = useState({});
  const [userLastMessaged, setUserLastMessaged] = useState({});
  const [selectedClientsForBroadcast, setSelectedClientsForBroadcast] =
    useState([]);
  const ref = useRef();

  const [
    currentMonthEnduranceFinishedData,
    setCurrentMonthEnduranceFinishedData,
  ] = useState([]);

  const [showTemplates, setShowTemplates] = useState(false);

  const [unreadMarks, setUnreadMarks] = useState({});
  const [showWorkoutLibrary, setShowWorkoutLibrary] = useState(false);
  const [isExpandedView, setIsExpandedView] = useState(false);

  const gymAffiliation = useSelector(
    (state) => state.auth?.data?.gymAffliation
  );
  const isLivezy = gymAffiliation === "Livezy";

  const tabs = isSmallerThanMd
    ? [
        { label: "Chat", value: "chat" },
        { label: "Notes", value: "notes" },
        { label: "Overview", value: "overview" },
        { label: "Progress", value: "progress" },
      ]
    : [
        { label: "Chat", value: "chat" },
        { label: "Notes", value: "notes" },
        { label: "Summary", value: "summary" },
        { label: "Schedule", value: "schedule" },
      ];

  const [selectedTab, setSelectedTab] = useState(
    isLivezy ? "schedule" : "chat"
  );

  const { channel, channels, setActiveChannel } = useChatContext();
  const history = useHistory();

  const getLastMessageSentByUser = (userID, messages) => {
    let lastMessage = null;
    messages?.forEach((message) => {
      if (message?.user?.id === userID) {
        lastMessage = message;
      }
    });
    if (!lastMessage) return new Date("02-01-2020"); // hard coding a date for filter specific
    return new Date(lastMessage?.created_at);
  };

  const getUnreadCount = async () => {
    const userRes = await chatClient.queryChannels({
      members: { $in: [chatClient.userID] },
    });

    let unreadMessages = {};
    let userWiseChannel = {};
    let userChannelIDMap = {};

    let latestMessageSentByUser = {};

    userRes?.map((userChannel) => {
      let state = userChannel?.state;
      let messages = state?.messageSets?.[0]?.messages;
      let members = state?.members;
      if (Object.keys(members || {})?.length == 2) {
        let unreadCount = state?.read?.[coachID]?.unread_messages ?? 0;
        // inside memebers two entries will be there one for coachID and another for user id i want user id
        let userId = Object.keys(members).filter(
          (member) => member !== coachID
        )?.[0];
        let channelId = userChannel?.cid;

        userWiseChannel[userId] = userChannel;

        unreadMessages[userId] = unreadCount;
        userChannelIDMap = {
          ...userChannelIDMap,
          [channelId]: userId,
        };

        console.log(
          "latest message sent by user: " + userId + " : ",
          getLastMessageSentByUser(userId, messages)
        );
        let lastMessageDate = getLastMessageSentByUser(userId, messages);
        latestMessageSentByUser[userId] = lastMessageDate;
      }
    });
    console.log("unread counts: ", unreadMessages);
    setUserUnreadMessage(unreadMessages);
    setUserWiseChannel(userWiseChannel);
    setUserChannelIDMap(userChannelIDMap);
    setUserLastMessaged(latestMessageSentByUser);
  };

  const getUnreadCountFromEvent = async (event) => {
    let channelId = event?.channel_id;
    let userId = userChannelIDMap[channelId] ?? event?.user?.id;

    if (userId) {
      let unreadCount = event?.unread_count ?? 0;
      setUserUnreadMessage((prev) => {
        if (prev[userId] == unreadCount) return prev;
        return {
          ...prev,
          [userId]: unreadCount,
        };
      });
    }
  };

  const debounceUpdateUnreadCount = debounce(getUnreadCountFromEvent, 500);

  chatClient.on((event) => {
    if (event.type === "notification.message_new") {
      debounceUpdateUnreadCount(event);
    } else if (event.type === "message.new" || event.type === "message.read") {
      debounceUpdateUnreadCount(event);
    } else if (event.type === "message") {
      debounceUpdateUnreadCount(event);
    }
  });

  async function connectGetStream() {
    if (!chatClient?.userID) {
      await chatInit(); //CONNECTING TO GETSTREAM IO
    }
    getUnreadCount();
    let trainerDetails = await JSON.parse(
      localStorage.getItem("trainerCredentials")
    );

    setFilter([
      {
        type: "team",
        members: { $in: [trainerDetails?.password] },
        demo: "team",
      },
      {
        members: { $in: [trainerDetails?.password] },
        type: "messaging",
      },
    ]);

    setClientIds(getStreamclient(trainerDetails));
    let timeOut = setTimeout(() => {
      setReady(true);
      clearTimeout(timeOut);
    }, 1000);
  }

  const init = async (quietly) => {
    if (clientsList.length > 0) return;
    if (!quietly) {
      setLoading(true);
    }
    connectGetStream();
    const dashboardData = await getTrainerDashboard(coachID);
    setDashData(dashboardData);
    if (dashboardData?.result?.[0]) {
      let activeOnly = dashboardData.result?.filter((item) => {
        if (item.IsActive === 1 || item.IsActive === true) {
          if (
            dashboardData.result?.length > 1 &&
            item?.Email != "democlient@demo.com"
          ) {
            return true;
          } else if (dashboardData.result?.length == 1) {
            return true;
          }
        }
      });
      setClientsList(activeOnly);
      setOriginalClientsList(activeOnly);
      console.log("active only clients", activeOnly);

      let selectedUserTemp;
      let localSelectedtab = localStorage.getItem("selectedTab");
      if (clientParamId) {
        let activeIndex = activeOnly.findIndex(
          (obj, index) => obj.UserUUID === clientParamId
        );
        console.log("active index here", activeIndex);
        selectedUserTemp = activeOnly[activeIndex];
        setSelectedClient(activeOnly[activeIndex]);
        setSelectedTab("schedule");
      } else if (!quietly) {
        selectedUserTemp = activeOnly[0];
        setSelectedClient(activeOnly[0]);
      }

      if (localSelectedtab) {
        setSelectedTab(localSelectedtab);
      }

      let allClientsSummary = {};
      let clientsUnreadMessage = {};

      let allActiveUUIDs = activeOnly?.map((client) => client?.UserUUID);

      let summaries = await getMultiClientProfileSummary(allActiveUUIDs);
      Object.keys(summaries).forEach((userUUID, index) => {
        let summary = summaries[userUUID];
        if (userUUID) {
          allClientsSummary[userUUID] = summaries[userUUID];
          clientsUnreadMessage[userUUID] = summary?.userInfo?.markedAsUnread;
        }
      });

      setClientsSummary(allClientsSummary);
      setUnreadMarks(clientsUnreadMessage);

      if (!isSmallerThanMd) {
        let allProfileData = { ...clientsProfileData };
        let allMealPlanData = {};
        let allActivityData = {};
        let allClientProfileRaw = {};
        let enduranceFinishedData = {};

        let allTraineeDetails = await getMultiClientProfile(allActiveUUIDs);
        Object.keys(allTraineeDetails).forEach((userUUID, index) => {
          let profileData = allTraineeDetails[userUUID];
          if (userUUID && userUUID !== selectedUserTemp?.UserUUID) {
            enduranceFinishedData[userUUID] =
              profileData?.currentMonthEnduranceFinishedData;
            allClientProfileRaw[userUUID] = profileData;
            let DATA = [];
            let currentMonthmodules = profileData?.currentMonthmodules || {};
            Object.entries(currentMonthmodules)?.map(([key, value]) => {
              value?.forEach((item) => {
                DATA.push({
                  ModuleDate: key,
                  Complete: item?.finished,
                  Name: item?.workoutAssigned?.name,
                  PrimaryKey: item?.id,
                  WorkoutId: item?.workoutAssigned?.id,
                  workoutKind: item?.workoutAssigned?.workoutKind,
                });
              });
            });
            allProfileData[userUUID] = DATA;
            allMealPlanData[userUUID] = profileData?.mealData;
            allActivityData[userUUID] = profileData?.activityData;
          }
        });

        setClientsProfileData((prev) => {
          return {
            ...prev,
            ...allProfileData,
          };
        });
        setClientsMealPlanData((prev) => {
          return {
            ...prev,
            ...allMealPlanData,
          };
        });
        setClientsActivityData((prev) => {
          return {
            ...prev,
            ...allActivityData,
          };
        });
        setClientProfileRaw((prev) => {
          return {
            ...prev,
            ...allClientProfileRaw,
          };
        });
        setCurrentMonthEnduranceFinishedData((prev) => {
          return {
            ...prev,
            ...enduranceFinishedData,
          };
        });
      }
      setLoading(false);
    }
  };

  const getGroupChannels = async () => {
    console.log("all channels", channels);
  };

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      return;
    }
    let clientViewStates = JSON.stringify({
      clientsList,
      originalClientsList,
      clientsSummary,
      clientsProfileData,
      clientsMealPlanData,
      clientsActivityData,
      ready,
      filters,
      selectedClient,
      showClientDetails,
      isUsersOpen,
      isChannelsOpen,
      isCreating,
      isEditing,
      createType,
      selectedTab,
      currentMonthEnduranceFinishedData,
    });
  }, [
    clientsList,
    originalClientsList,
    clientsSummary,
    clientsProfileData,
    clientsMealPlanData,
    clientsActivityData,
    ready,
    filters,
    selectedClient,
    showClientDetails,
    isUsersOpen,
    isChannelsOpen,
    isCreating,
    isEditing,
    createType,
    selectedTab,
    currentMonthEnduranceFinishedData,
  ]);

  useEffect(() => {
    getGroupChannels();

    // User came from the back button
    //get data from local storage and set
    let clientViewStates = JSON.parse(localStorage.getItem("clientViewStates"));
    if (clientViewStates && clientViewStates?.selectedClient?.Name) {
      let selectedClient = clientViewStates?.selectedClient;
      let clientsList = clientViewStates?.clientsList;
      let originalClientsList = clientViewStates?.originalClientsList;
      let clientsSummary = clientViewStates?.clientsSummary;
      let clientsProfileData = clientViewStates?.clientsProfileData;
      let clientsMealPlanData = clientViewStates?.clientsMealPlanData;
      let clientsActivityData = clientViewStates?.clientsActivityData;
      let ready = clientViewStates?.ready;
      let filters = clientViewStates?.filters;
      let showClientDetails = clientViewStates?.showClientDetails;
      let isUsersOpen = true;
      let isChannelsOpen = clientViewStates?.isChannelsOpen;
      let isCreating = clientViewStates?.isCreating;
      let isEditing = clientViewStates?.isEditing;
      let createType = clientViewStates?.createType;
      let selectedTab = clientViewStates?.selectedTab;
      let currentMonthEnduranceFinishedData =
        clientViewStates?.currentMonthEnduranceFinishedData;

      setSelectedClient(selectedClient);
      setClientsList(clientsList);
      setOriginalClientsList(originalClientsList);
      setClientsSummary(clientsSummary);
      setClientsProfileData(clientsProfileData);
      setClientsMealPlanData(clientsMealPlanData);
      setClientsActivityData(clientsActivityData);
      setFilter(filters);
      setShowClientDetails(showClientDetails);
      setIsUsersOpen(isUsersOpen);
      setIsChannelsOpen(isChannelsOpen);
      setIsCreating(isCreating);
      setIsEditing(isEditing);
      setCreateType(createType);
      setCurrentMonthEnduranceFinishedData(currentMonthEnduranceFinishedData);
      if (clientParamId) {
        setSelectedTab("schedule");
      } else {
        setSelectedTab(selectedTab);
      }
      init(true);

      let timeOut = setTimeout(() => {
        // setReady(ready);
        clearTimeout(timeOut);
      }, 5000);
    } else {
      init();
    }
  }, []);

  const reloadParticularClientsProfile = async (
    clientUUID,
    startDate,
    endDate
  ) => {
    const profileData = await getClientProfileByDateRange(
      clientUUID,
      startDate,
      endDate
    );

    let uuid = profileData?.userInfo?.[0]?.id;
    if (clientUUID) {
      let enduranceFinishedData =
        profileData?.currentMonthEnduranceFinishedData;
      setCurrentMonthEnduranceFinishedData({
        ...(currentMonthEnduranceFinishedData ?? {}),
        [clientUUID]: enduranceFinishedData,
      });

      let DATA = [];
      let currentMonthmodules = profileData?.currentMonthmodules || {};
      Object.entries(currentMonthmodules)?.map(([key, value]) => {
        value?.forEach((item) => {
          DATA.push({
            ModuleDate: key,
            Complete: item?.finished,
            Name: item?.workoutAssigned?.name,
            PrimaryKey: item?.id,
            WorkoutId: item?.workoutAssigned?.id,
          });
        });
      });
      setClientsProfileData({
        ...clientsProfileData,
        [clientUUID]: DATA,
      });
      setClientsMealPlanData({
        ...clientsMealPlanData,
        [clientUUID]: profileData?.mealData,
      });
      setClientsActivityData({
        ...clientsActivityData,
        [clientUUID]: profileData?.activityData,
      });
      setClientProfileRaw((prev) => {
        return { ...(prev ?? {}), [clientUUID]: profileData };
      });

      return {
        profileData: DATA,
        mealData: profileData?.mealData,
        activityData: profileData?.activityData,
      };
    }
  };

  const reloadClientSummary = async (clientUUID) => {
    const summaryData = await getClientProfileSummary(clientUUID);

    let markedAsUnread = summaryData?.userInfo?.markedAsUnread;

    setUnreadMarks({
      ...unreadMarks,
      [clientUUID]: markedAsUnread,
    });

    setClientsSummary({
      ...clientsSummary,
      [clientUUID]: summaryData,
    });
  };

  const getClientProfileForSelectedCalendarType = () => {
    const storedCalendarType = localStorage.getItem("calendarType");
    if (!storedCalendarType || storedCalendarType === "Weekly") {
      const today = moment();
      let startDate = moment(today).startOf("week").add(1, "day");
      let endDate = moment(today).endOf("week").add(1, "day");

      let oneWeekDateRange = localStorage.getItem(
        `${selectedClient?.UserUUID}@oneWeekDateRange`
      );

      if (oneWeekDateRange && assigned === "true") {
        let [start, end] = oneWeekDateRange.split("$");
        if (
          startDate &&
          endDate &&
          moment(start).isSame(startDate) &&
          moment(end).isSame(endDate)
        ) {
        } else {
          startDate = moment(start).toDate();
          endDate = moment(end).toDate();
        }
      }

      reloadParticularClientsProfile(
        selectedClient?.UserUUID,
        startDate,
        endDate
      );
    } else if (storedCalendarType === "Semi Monthly") {
      let twoWeekDateRange = localStorage.getItem(
        `${selectedClient?.UserUUID}@twoWeekDateRange`
      );

      const today = moment();
      let startDate = moment(today).startOf("week");
      let endDate = moment(today).endOf("week").add(1, "week");

      if (twoWeekDateRange && assigned === "true") {
        let [start, end] = twoWeekDateRange.split("$");

        if (
          startDate &&
          endDate &&
          moment(start).isSame(startDate) &&
          moment(end).isSame(endDate)
        ) {
        } else {
          startDate = moment(start).toDate();
          endDate = moment(end).toDate();
        }
      }
      reloadParticularClientsProfile(
        selectedClient?.UserUUID,
        startDate,
        endDate
      );
    } else if (storedCalendarType === "Monthly") {
      const today = moment();
      let startDate = moment(today).startOf("month");
      let endDate = moment(today).endOf("month");

      let calendarStartDate = localStorage.getItem(
        selectedClient?.UserUUID + "@calStartDate"
      );

      if (calendarStartDate) {
        startDate = moment(calendarStartDate).startOf("month");
        endDate = moment(calendarStartDate).endOf("month");
      }

      reloadParticularClientsProfile(
        selectedClient?.UserUUID,
        startDate,
        endDate
      );
    }
    let timeout = setTimeout(() => {
      reloadClientSummary(selectedClient?.UserUUID);
      clearTimeout(timeout);
    }, 0);
  };

  useEffect(() => {
    if (selectedClient?.UserUUID) {
      getClientProfileForSelectedCalendarType();
    }
  }, [selectedClient]);

  const customChannelTeamFilter = (channels) => {
    return channels.filter((channel) => channel.type === "team");
  };

  useEffect(() => {
    if (channel && channel?.type !== "messaging" && selectedClient) {
      setSelectedClient(null);
    }
    if (channel && channel?.type !== "messaging" && isSmallerThanMd) {
      setShowClientDetails(true);
    }
  }, [channel]);

  const dispatch = useDispatch();

  const updateSingleEnduranceFinishedData = (clientUUID, date, index, data) => {
    let currentClientsData = currentMonthEnduranceFinishedData[clientUUID];
    let currentData = currentClientsData[date] ?? [];
    currentData[index] = data;
    currentClientsData[date] = currentData;
    setCurrentMonthEnduranceFinishedData({
      ...currentMonthEnduranceFinishedData,
      [clientUUID]: currentClientsData,
    });
  };

  return (
    <ClientsViewContext.Provider
      value={{
        updateSingleEnduranceFinishedData,
      }}
    >
      <DragDropContext
        onDragStart={(result) => ref.current.handleDragStart(result)}
        onDragEnd={(result) => ref.current.handleDragEnd(result)}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
     
            // gridTemplateColumns: ["1fr", "1fr", "25% 1fr"],
           // background:"black",
            height: "calc(100vh - 110px)",
            width: "100%",
            borderRadius: "12px",
            
            //overflow: "hidden",
          }}
        >
          {isSmallerThanMd && (
            <ArrowBackIosNewRoundedIcon
              onClick={() => {
                setShowClientDetails(false);
                if (channel) {
                  setActiveChannel(null);
                }
              }}
              style={{
                display: showClientDetails ? "block" : "none",
                position: "absolute",
                top: "20px",
                left: "30px",
                zIndex: "100",
                cursor: "pointer",
              }}
            />
          )}
          {isSmallerThanMd ? (
            <>
              {showClientDetails === false && !isCreating ? (
                <ListsMobile
                  {...{
                    clientsList,
                    selectedClient,
                    setSelectedClient,
                    showClientDetails,
                    setShowClientDetails,
                    isUsersOpen,
                    setIsUsersOpen,
                    isChannelsOpen,
                    setIsChannelsOpen,
                    isCreating,
                    setIsCreating,
                    isEditing,
                    setIsEditing,
                    createType,
                    setCreateType,
                    ready,
                    filters,
                    options,
                    sort,
                    isBroadcast,
                    setIsBroadcast,
                    selectedClientsForBroadcast,
                    setSelectedClientsForBroadcast,
                    userUnreadMessage,
                    userWiseChannel,
                  }}
                />
              ) : (channel === null ||
                  channel === undefined ||
                  channel?.type === "messaging") &&
                !isCreating ? (
                clientsList?.length > 0 ? (
                  <ClientDetails
                    key={selectedClient?.UserUUID + "mobile"}
                    summaryData={clientsSummary[selectedClient?.UserUUID]}
                    onBack={() => {
                      setShowClientDetails(false);
                    }}
                    showBackBtn={true}
                    selectedClient={selectedClient}
                    selectedTab={selectedTab}
                    setSelectedTab={(val) => {
                      setSelectedTab(val);
                      localStorage.setItem("selectedTab", val);
                    }}
                  />
                ) : (
                  <></>
                )
              ) : (
                <div
                  style={{
                    height: "calc(100vh - 110px)",
                    width: "100%",
                    "& .str-chat__channel-list": {
                      width: "100%",
                    },
                  }}
                >
                  <ChannelContainer
                    {...{
                      createType,
                      isCreating,
                      isEditing,
                      setIsCreating,
                      setIsEditing,
                    }}
                  />
                </div>
              )}
            </>
          ) : (
            <>
              {!showWorkoutLibrary && (
                <div
                  style={{
                    maxHeight: "calc(100vh - 90px)",
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "#fff",
                    width: "20%",
                    borderRadius: "8px",
                  }}
                >
                  <ClientsSearch
                    clients={clientsList}
                    clientsSummary={clientsSummary}
                    setClientsList={setClientsList}
                    originalClientsList={originalClientsList}
                    selectedClient={selectedClient}
                    setSelectedClient={setSelectedClient}
                    setShowClientDetails={setShowClientDetails}
                    userLastMessaged={userLastMessaged}
                    userUnreadMessage={userUnreadMessage}
                    unreadMarks={unreadMarks}
                    dashboardData={dashData}
                  />
                  {ready && (
                    <ClientList
                      clients={clientsList}
                      selectedClient={selectedClient}
                      setSelectedClient={setSelectedClient}
                      setShowClientDetails={setShowClientDetails}
                      isBoxOpen={isUsersOpen}
                      setIsBoxOpen={setIsUsersOpen}
                      userUnreadMessage={userUnreadMessage}
                      userWiseChannel={userWiseChannel}
                      unreadMarks={unreadMarks}
                      isBroadcast={isBroadcast}
                      setIsBroadcast={setIsBroadcast}
                      selectedClientsForBroadcast={selectedClientsForBroadcast}
                      setSelectedClientsForBroadcast={
                        setSelectedClientsForBroadcast
                      }
                      dashboardData
                      setShowTemplates={setShowTemplates}
                    />
                  )}
                  <TemplatesDialog
                    isOpen={showTemplates}
                    setIsOpen={setShowTemplates}
                  />
                  {!isLivezy && ready && (
                    <div
                      style={{
                        width: "100%",
                        paddingRight: "20px",
                        paddingLeft: "20px",
                        backgroundColor: "#fff",
                        height: isUsersOpen
                          ? isChannelsOpen
                            ? "40%"
                            : "100px"
                          : "100%",
                        // overflowY: "scroll",
                        borderRight: "1px solid #E5E5E5",
                      }}
                    >
                      <ChannelList
                        channelRenderFilterFn={customChannelTeamFilter}
                        setActiveChannelOnMount={false}
                        filters={filters[0]}
                        options={options}
                        sort={sort}
                        List={(listProps) => (
                          <TeamChannelList
                            {...listProps}
                            {...{
                              setCreateType,
                              setIsCreating,
                              setIsEditing,
                              isChannelsOpen,
                              setIsChannelsOpen,
                            }}
                            type="team"
                          />
                        )}
                        Preview={(previewProps) => (
                          <TeamChannelPreview
                            {...previewProps}
                            {...{ setIsCreating, setIsEditing }}
                            type="team"
                          />
                        )}
                      />
                    </div>
                  )}
                </div>
              )}

              {showWorkoutLibrary && (
                <WorkoutLibrary
                  setShowWorkoutLibrary={setShowWorkoutLibrary}
                  setOpenWorkoutInfoModal={setOpenWorkoutInfoModal}
                />
              )}

              {(channel === null ||
                channel === undefined ||
                channel?.type == "messaging") &&
              !isBroadcast &&
              !isCreating ? (
                clientsList?.length > 0 ? (
                  <ClientDetails
                    key={selectedClient?.UserUUID}
                    summaryData={clientsSummary[selectedClient?.UserUUID]}
                    isExpandedView={isExpandedView}
                    setIsExpandedView={setIsExpandedView}
                    garminActivityData={
                      currentMonthEnduranceFinishedData?.[
                        selectedClient?.UserUUID
                      ]
                    }
                    setSummaryData={setClientsSummary}
                    updateUserInfoOfOneClient={(info) => {
                      setClientsSummary({
                        ...clientsSummary,
                        [selectedClient?.UserUUID]: {
                          ...clientsSummary[selectedClient?.UserUUID],
                          userInfo: info,
                        },
                      });
                    }}
                    selectedClient={selectedClient}
                    setSelectedClient={setSelectedClient}
                    clientsList={clientsList}
                    clientsProfileData={
                      clientsProfileData?.[selectedClient?.UserUUID]
                    }
                    clientsMealPlanData={
                      clientsMealPlanData?.[selectedClient?.UserUUID]
                    }
                    clientsActivityData={
                      clientsActivityData?.[selectedClient?.UserUUID]
                    }
                    reloadParticularClientsProfile={
                      reloadParticularClientsProfile
                    }
                    reloadClientSummary={reloadClientSummary}
                    showBackBtn={false}
                    parentLoading={loading}
                    selectedTab={selectedTab}
                    setSelectedTab={(val) => {
                      setSelectedTab(val);
                      localStorage.setItem("selectedTab", val);
                    }}
                    unreadMarks={unreadMarks}
                    setUnreadMarks={setUnreadMarks}
                    profileData={clientProfileRaw?.[selectedClient?.UserUUID]}
                    setProfileData={(updatedData, shouldMerge) => {
                      setClientProfileRaw((prevProfile) => {
                        if (!selectedClient || !selectedClient.UserUUID) {
                          return prevProfile; // Return previous state if selectedClient or UserUUID is undefined
                        }
                        if (shouldMerge) {
                          let curUserPrevData =
                            prevProfile?.[selectedClient.UserUUID];
                          let updatedCurrentMonthModules = {
                            ...(curUserPrevData?.currentMonthmodules || {}),
                            ...(updatedData?.currentMonthmodules || {}),
                          };
                          let updatedMonthEnduranceFinishedData = {
                            ...(curUserPrevData?.currentMonthEnduranceFinishedData ||
                              {}),
                            ...(updatedData?.currentMonthEnduranceFinishedData ||
                              {}),
                          };

                          let updatedActivity = {
                            ...(curUserPrevData?.activityData || {}),
                            ...(updatedData?.activityData || {}),
                          };

                          let updatedMealData = {
                            ...(curUserPrevData?.mealData || {}),
                            ...(updatedData?.mealData || {}),
                          };
                          updatedData = {
                            currentMonthmodules: updatedCurrentMonthModules,
                            currentMonthEnduranceFinishedData:
                              updatedMonthEnduranceFinishedData,
                            mealData: updatedMealData,
                            activityData: updatedActivity,
                          };
                        }
                        const updatedProfile = {
                          ...prevProfile,
                          [selectedClient.UserUUID]: updatedData,
                        };
                        return updatedProfile;
                      });
                      let curMonthEnduranceFinishData = {
                        ...currentMonthEnduranceFinishedData,
                        ...(updatedData?.currentMonthEnduranceFinishedData ||
                          {}),
                      };
                      setCurrentMonthEnduranceFinishedData({
                        ...currentMonthEnduranceFinishedData,
                        [selectedClient.UserUUID]: curMonthEnduranceFinishData,
                      });
                    }}
                    showWorkoutLibrary={showWorkoutLibrary}
                    setShowWorkoutLibrary={setShowWorkoutLibrary}
                    setOpenWorkoutInfoModal={setOpenWorkoutInfoModal}
                    openWorkoutInfoModal={openWorkoutInfoModal}
                    calenderRef={ref}
                    activeUser={clientsList}
                  />
                ) : (
                  <></>
                )
              ) : isBroadcast ? (
                <BroadCast
                  selectedUsers={selectedClientsForBroadcast}
                  setSelectedUsers={setSelectedClientsForBroadcast}
                />
              ) : (
                <div
                  style={{
                    height: "calc(100vh - 110px)",
                    width: "100%",
                    "& .str-chat__channel-list": {
                      width: "100%",
                    },
                  }}
                >
                  <ChannelContainer
                    {...{
                      createType,
                      isCreating,
                      isEditing,
                      setIsCreating,
                      setIsEditing,
                    }}
                  />
                </div>
              )}
            </>
          )}
        </Box>
      </DragDropContext>
    </ClientsViewContext.Provider>
  );
};

const ClientsView = () => {
  return (
    <Layout>
      <Chat
        {...{ client: chatClient, i18nInstance }}
        theme={`team light`}
        customClasses={{}}
      >
        <Index />
      </Chat>
    </Layout>
  );
};

export default ClientsView;

const ListsMobile = ({
  clientsList,
  selectedClient,
  setSelectedClient,
  showClientDetails,
  setShowClientDetails,
  isUsersOpen,
  setIsUsersOpen,
  isChannelsOpen,
  setIsChannelsOpen,
  isCreating,
  setIsCreating,
  isEditing,
  setIsEditing,
  createType,
  setCreateType,
  ready,
  filters,
  options,
  sort,
  isBroadcast,
  setIsBroadcast,
  selectedClientsForBroadcast,
  setSelectedClientsForBroadcast,
  userUnreadMessage,
  userWiseChannel,
}) => {
  const customChannelTeamFilter = (channels) => {
    return channels.filter((channel) => channel.type === "team");
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#fff",
        height: "100%",
        overflowY: "scroll",
      }}
    >
      <ClientsSearch
        clients={clientsList}
        selectedClient={selectedClient}
        setSelectedClient={setSelectedClient}
        setShowClientDetails={setShowClientDetails}
      />
      <ClientList
        clients={clientsList}
        selectedClient={selectedClient}
        setSelectedClient={setSelectedClient}
        setShowClientDetails={setShowClientDetails}
        isBoxOpen={isUsersOpen}
        setIsBoxOpen={setIsUsersOpen}
        userUnreadMessage={userUnreadMessage}
        userWiseChannel={userWiseChannel}
        isBroadcast={isBroadcast}
        setIsBroadcast={setIsBroadcast}
        selectedClientsForBroadcast={selectedClientsForBroadcast}
        setSelectedClientsForBroadcast={setSelectedClientsForBroadcast}
      />

      <div
        style={{
          display: showClientDetails ? "none" : "flex",
          backgroundColor: "#fff",
          height: isUsersOpen ? (isChannelsOpen ? "30%" : "100px") : "100%",
        }}
      >
        {ready && (
          <ChannelList
            channelRenderFilterFn={customChannelTeamFilter}
            filters={filters?.[0]}
            options={options}
            setActiveChannelOnMount={false}
            sort={sort}
            List={(listProps) => (
              <TeamChannelList
                {...listProps}
                {...{
                  setCreateType,
                  setIsCreating,
                  setIsEditing,
                  isChannelsOpen,
                  setIsChannelsOpen,
                }}
                type="team"
              />
            )}
            Preview={(previewProps) => (
              <TeamChannelPreview
                {...previewProps}
                {...{ setIsCreating, setIsEditing }}
                type="team"
              />
            )}
          />
        )}
      </div>
    </div>
  );
};
