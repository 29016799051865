import React, { useEffect, useRef, useState } from "react";
import Layout from "../../components/layout/Layout";
import {
  Box,
  TextField,
  Card,
  CardContent,
  FormControl,
  Button,
} from "@mui/material";
import "./style.css";
import FormField from "./components/FormField";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { store } from "../../redux/store";
import { singleSelect, multiline, options, multiSelect } from "./formdata";
import { SpurfitCircularProgress } from "../../components/Dashboard/dashboard";
import { getFormById, updateForm } from "@/ApiServices/FormControl/Api";
import { getFormByathleteID } from "@/ApiServices/ClientDetails/Api";
import axios from "axios";
import APIURL from "@/APIDetails";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import moment from "moment";
import Avatar from "@mui/material/Avatar";
import Checkbox from "@mui/material/Checkbox";
import {
  Autocomplete,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormGroup,
  CircularProgress,
} from "@mui/material";
import { MultilineInputComponent } from "../trainerSignup";
import SearchBar from "@/components/Dashboard/SearchBar";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

var userData = [];

function FormSubmission({ formId, setIsSubmittedForm }) {
  const [loading, setLoading] = useState(false);
  const query = useQuery();
  const { id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const formName = queryParams.get("formName");
  console.warn(id, formName, "formId and formName");
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isCopy, setCopy] = useState(false);
  const [formData, setFormData] = useState([]);
  const [fields, setFields] = useState([]);
  const history = useHistory();
  const [form, setForms] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedForm, setSelectedForm] = useState([]);
  const [focused, setFocused] = useState(null);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [sortCounts, setSortCounts] = useState({});
  const [hoveredColumn, setHoveredColumn] = useState(null);
  const [showSortData, setShowSortData] = useState(false);

  useEffect(() => {
    console.log("FETCHING DATA TO GET RETRIEVE");
    setLoading(true);
    getUsers();
  }, []);

  async function getUsers() {
    axios({
      method: "get",
      url: `${APIURL}CF/api/customForms/getAllResponsesForForm?formId=${formId}`,
    }).then(async (res) => {
      console.warn("Dashboard query on assign: ", res.data);
      if (Object.keys(res.data).length > 0) {
        const key = Object.keys(res.data)[0];
        const value = res.data[key];
        if (Array.isArray(value)) {
          setUsers(value);
          userData = value;
          setIsSubmittedForm(value?.length)
          console.warn(`The value of ${key} is an array:`, value);
        } else {
          console.warn(`The value of ${key} is not an array:`, value);
        }
      } else {
        console.warn("The object is empty.");
      }
      setLoading(false);
    });
  }

  const renderStepContent = (field) => {
    console.log(field, "fields");
    switch (field.type) {
      case "Single Select":
        return (
          <section className="usection-container">
            {/* <div className="section-header">
													<div className="section-header-text">{field.value}</div>
											</div> */}
            <div
              style={{
                width: "100%",
              }}
              className="usection-body"
            >
              <div
                style={{
                  fontSize: 16,
                  color: "#424242",
                }}
                className="text-sm tracking-wide font-DMSans"
              >
                {field.value}
              </div>
              <RadioGroup
                row
                defaultValue={field.answer}
                aria-labelledby="demo-row-radio-buttons-group-label"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
                control={
                  <Radio
                    disabled={true}
                    sx={{
                      "&.MuiButtonBase-root-MuiRadio-root": {
                        color: "#8330e9",
                      },
                      "& .MuiSvgIcon-root": {
                        color: "#8330e9",
                      },
                      color: "#8330e9",
                    }}
                  />
                }
                name={field.id}
              >
                <FormControlLabel
                  className="font-DMSans tracking-wide text-sm"
                  key={field.answer}
                  value={field.answer}
                  control={<Radio color="royalBlue" />}
                  label={field.answer}
                  disabled
                />
                {/* {field.possibleAnswers.map((option) => (
																	<FormControlLabel
																			className="font-DMSans tracking-wide text-sm"
																			key={option}
																			value={option}
																			control={<Radio color="royalBlue" />}
																			label={option}
																			disabled
																	/>
															))} */}
              </RadioGroup>
              {/* <ShowError
													error={formik.touched[field.id] && formik.errors[field.id]}
													errorMessage={formik.errors[field.id]}
											/> */}
            </div>
          </section>
        );
      case "Multi Select":
        return (
          <section className="usection-container">
            <div style={{ width: "100%" }} className="usection-body">
              <div
                style={{ fontSize: 16, color: "#424242" }}
                className="text-sm tracking-wide font-DMSans"
              >
                {field.value}
              </div>
              <FormGroup>
                {field.answer.map((option) => (
                  <FormControlLabel
                    key={option}
                    disabled
                    control={
                      <Checkbox
                        disabled
                        checked
                        sx={{
                          "& .MuiSvgIcon-root": {
                            color: "rgba(0, 0, 0, 0.38)",
                          },
                        }}
                      />
                    }
                    label={option}
                    onChange={(e) =>
                      handleMultiSelectChange(
                        field.id,
                        e.target.checked,
                        option
                      )
                    }
                    sx={{
                      "& .Mui-checked": {
                        color: "rgba(0, 0, 0, 0.38)", // Color when checked
                      },
                    }}
                  />
                ))}
              </FormGroup>
            </div>
          </section>
        );
      case "Multi-line type":
        return (
          <section className="usection-container">
            <div style={{ width: "100%" }} className="usection-body">
              <div
                style={{ fontSize: 16, color: "#424242" }}
                className="text-sm tracking-wide font-DMSans"
              >
                {field.value}
              </div>
              <MultilineInputComponent
                label=""
                placeholder=""
                type="text"
                disabled={true}
                value={field.answer}
                error={field.value && field.value}
                inputStyle={{ color: "rgba(0, 0, 0, 0.38)" }}
              />
            </div>
          </section>
        );
      default:
        return <TextField fullWidth />;
    }
  };

  const request = (searchTerm) => {
    if (searchTerm === "") {
      setUsers(userData);
      return;
    }
    const _new = users.filter((user) => {
      return user?.athlete.userName
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase());
    });
    setUsers(_new);
    console.warn(userData, "req data");
  };

  const cancel = () => {
    console.warn(userData, "data");
    setUsers(userData);
  };

  useEffect(() => {
    if (sortColumn && showSortData) {
      const sorted = [...users].sort((a, b) => {
        if (sortColumn === "submittedAt") {
          let aDate = a?.submittedAt || "N/A";
          let bDate = b?.submittedAt || "N/A";

          let aDateFormatted = moment(aDate);
          let bDateFormatted = moment(bDate);

          if (moment(aDateFormatted).isBefore(bDateFormatted)) {
            return sortDirection === "asc" ? -1 : 1;
          }
          if (moment(aDateFormatted).isAfter(bDateFormatted)) {
            return sortDirection === "asc" ? 1 : -1;
          }
          return 0;
        }

        if (sortColumn === "Name") {
          let aName = a?.athlete.name?.toLowerCase();
          let bName = b?.athlete.name?.toLowerCase();
          console.warn("aName", aName, bName);
          if (aName < bName) {
            return sortDirection === "asc" ? -1 : 1;
          }
          if (aName > bName) {
            return sortDirection === "asc" ? 1 : -1;
          }
        }

        if (a[sortColumn] < b[sortColumn]) {
          return sortDirection === "asc" ? -1 : 1;
        }
        if (a[sortColumn] > b[sortColumn]) {
          return sortDirection === "asc" ? 1 : -1;
        }
        return 0;
      });
      if (sorted.length > 0 && sorted.length === users.length) {
        onSort(sorted);
      }
    }
  }, [sortColumn, sortDirection, showSortData]);

  const onSort = (sortedData) => {
    setUsers(sortedData);
  };

  /* HANDLE SORT BY NAME AND CREATEDAT */
  const handleSort = (column) => {
    console.warn(column, "column");
    if (!showSortData) setShowSortData(true);
    const count = sortCounts[column] || 0;
    let direction = "asc";
    if (count === 0) {
      direction = "asc";
    } else if (count === 1) {
      direction = "desc";
    } else {
      setSortColumn(null);
      setSortDirection(null);
      setSortCounts({});
      return;
    }
    setSortColumn(column);
    setSortDirection(direction);
    if (sortColumn === column) {
      setSortCounts({ ...sortCounts, [column]: count + 1 });
    } else {
      setSortCounts({ [column]: 1 });
    }
  };

  return (
    <div className="box">
      <div className="user-box">
        <List
          dense
          className="formuser"
          sx={{
            maxWidth: 360,
            paddingBottom: "20px",
            width: "100%",
            overflowY: "auto",
            borderRadius: "10px",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.03)",
            flexGrow: 1, // Allow the list to grow and take up remaining space
          }}
        >
          <SearchBar
            placeholder="Search User"
            style={{
              borderRadius: "80px",
              width: "100%",
              height: "100%",
              marginTop: 0,
              marginBottom: 0,
              boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.19)",
            }}
            iconStyle={{
              marginLeft: 5,
            }}
            bgcol={"#FFF"}
            request={request}
            cancel={cancel}
          />
          {loading ? (
            <div
              style={{
                height: "100%",
                width: "100%",
                backgroundColor: "rgba(0,0,0,0)",
                borderRadius: "0px 0px 16px 16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: 99,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress size={20} sx={{ color: "#6242EA" }} />
              </Box>
            </div>
          ) : !users || users.length == 0 ? (
            <p
              style={{
                fontFamily: "DM Sans",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "29px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px 0px",
              }}
            >
              No User found
            </p>
          ) : (
            <div>
              <table style={{ width: '100%', margin: '1rem auto' }}>
                <thead
                  className="py-3"
                  style={{
                    height: '100%',
                    width: "100%",
                    borderBottom: "1px solid #E5E5E5",
                    margin: '0 auto'

                  }}
                >
                  <tr
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: 'space-between',
                    }}
                  >
                    <th
                      onClick={() => handleSort("Name")}
                      onMouseEnter={() => setHoveredColumn("Name")}
                      onMouseLeave={() => setHoveredColumn(null)}
                      style={{
                        cursor: "pointer",
                        flex: 1,
                        paddingRight: "0px",
                        textAlign: "center",
                      }}
                      className="table-header"
                    >
                      Name
                      {sortColumn === "Name" && sortDirection === "asc" && (
                        <ArrowUpwardIcon
                          sx={{
                            color: "#605e5c",
                          }}
                          className="sort-arrow"
                        />
                      )}
                      {sortColumn === "Name" && sortDirection === "desc" && (
                        <ArrowDownwardIcon
                          sx={{
                            color: "#605e5c",
                          }}
                          className="sort-arrow"
                        />
                      )}
                      {hoveredColumn === "Name" && sortColumn !== "Name" && (
                        <ArrowUpwardIcon
                          sx={{
                            color: "#ccc",
                          }}
                          className="sort-arrow"
                        />
                      )}
                    </th>
                    <th
                      onClick={() => handleSort("submittedAt")}
                      onMouseEnter={() => setHoveredColumn("submittedAt")}
                      onMouseLeave={() => setHoveredColumn(null)}
                      style={{
                        cursor: "pointer",
                        flex: 1,
                        paddingRight: "0px",
                        textAlign: 'unset'
                      }}
                      className="table-header"
                    >
                      Created on
                      {sortColumn === "submittedAt" && sortDirection === "asc" && (
                        <ArrowUpwardIcon
                          sx={{
                            color: "#605e5c",
                          }}
                          className="sort-arrow"
                        />
                      )}
                      {sortColumn === "submittedAt" && sortDirection === "desc" && (
                        <ArrowDownwardIcon
                          sx={{
                            color: "#605e5c",
                          }}
                          className="sort-arrow"
                        />
                      )}
                      {hoveredColumn === "submittedAt" &&
                        sortColumn !== "submittedAt" && (
                          <ArrowUpwardIcon
                            sx={{
                              color: "#ccc",
                            }}
                            className="sort-arrow"
                          />
                        )}
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
          )}
          {users?.map((user) => {
            return (
              <ListItem
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  position: "relative",
                  margin: "5px 0px 5px 0px",
                  padding: "5px 5px 5px 5px",
                  borderRadius: "8px",
                  background: "transparent",
                  "& ul": {
                    padding: 20,
                  },
                }}
                key={user.id}
                secondaryAction={
                  <p className="text-black-pure font-normal text-font12 font-DMSans">
                    {moment(user.submittedAt)?.format("Do MMM YY")}
                  </p>
                }
                disablePadding
              >
                <ListItemButton
                  sx={{
                    background: "transparent",
                    "&:hover, &:focus-within": {
                      background:
                        "linear-gradient(90deg, rgba(70, 79, 235, 0.1) 10.42%, rgba(131, 48, 233, 0.1) 100%)",
                      borderRadius: "8px",
                    },
                  }}
                  onClick={() => setSelectedForm(user?.responses)}
                  onBlur={() => setFocused(null)}
                  onFocus={() => setFocused(user.id)}
                >
                  <div
                    className={`indicator ${focused === user.id ? "active" : ""
                      }`}
                  ></div>
                  <ListItemAvatar>
                    <Avatar
                      alt={user.athlete.name}
                      src={user.athlete.userImageKey}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    id={user.athlete.id}
                    primary={user.athlete.name}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </div>
      <div className="form-box">
        {!selectedForm || !selectedForm?.length ? (
          <div className="flex justify-center items-center">
            <p
              style={{
                fontFamily: "DM Sans",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "29px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "40px 0px",
              }}
            >
              Answers not found!
            </p>
          </div>
        ) : (
          <div className="p-6 overflow-y-auto mb-4 max-height[330px] w-full">
            {selectedForm.map((field) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "10px",
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.03)",
                  height: "fit-content",
                  width: "100%",
                  padding: "8px",
                  marginTop: "8px",
                }}
              >
                {renderStepContent(field)}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default FormSubmission;
