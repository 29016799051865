import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";

const NotFound = () => (
  <main
    aria-labelledby="pageTitle"
    class="flex items-center justify-center h-screen bg-white"
  >
    <div class="p-4 space-y-4">
      <div class="flex flex-col items-start space-y-3 sm:flex-row sm:space-y-0 sm:items-center sm:space-x-3">
        <p
          className="text-gradient"
          style={{
            fontSize: "10rem",
            fontWeight: "bold",
          }}
        >
          404
        </p>
        <div class="space-y-2">
          <h1 id="pageTitle" class="flex items-center space-x-2">
            <div
              style={{
                height: "2rem",
                width: "2rem",
              }}
            >
              <WarningAmberRoundedIcon
                sx={{
                  color: "#8330e9",
                }}
              />
            </div>
            <span class="text-xl font-medium text-gray-600 sm:text-2xl ">
              Oops! Something went wrong.
            </span>
          </h1>
          <p class="text-base font-normal ">Some error occured.</p>
          <p class="text-base font-normal">
            You may return to &nbsp;
            <a
              href={`${window.location.origin}/home`}
              class="text-blue-600 hover:underline dark:text-blue-500"
            >
              home page
            </a>
          </p>
        </div>
      </div>
      <div className="flex items-center justify-center gap-2">
        <a href={`${window.location.origin}/home`} className="button-new">
          Go to homepage
        </a>
        {/* reload page */}
        <button
          onClick={() => {
            window.location.reload();
          }}
          className="button-new"
        >
          Reload Page
        </button>
      </div>
    </div>
  </main>
);

export default NotFound;
