import { Input } from "@/components/ui/input";
import React from "react";

const EnduranceRangeInput = ({ value, maxValue, onChange, onMaxChange }) => {
  return (
    <div className="flex items-center justify-between">
      <Input
        type="number"
        value={value}
        onChange={onChange}
        placeholder="Min"
        className="min-w-16 focus-visible:ring-white-pure"
      />
      <span className="mx-2">to</span>
      <Input
        type="number"
        value={maxValue}
        onChange={onMaxChange}
        placeholder="Max"
        className="min-w-16 focus-visible:ring-white-pure"
      />
    </div>
  );
};

export default EnduranceRangeInput;
