import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Layout from "../../components/layout/Layout";
import { getAllMessageTemplate } from "./Api";
import CreateTemplateModal from "./CreateTemplateModal";

const MessageTemplate = () => {
  const [getAllMessages, setGetAllMessages] = useState([]);
  const [openCreateMessageModal, setOpenCreateMessageModal] = useState(false);
  const coachId = useSelector((state) => state.auth.trainerUUID);
  const [inputTemplateName, setInputTemplateName] = useState("");
  const [inputTemplateMessage, setInputTemplateMessage] = useState("");
  const [openUpdateMessageModal, setOpenUpdateMessageModal] = useState(false);
  useEffect(() => {
    getAllMessageTemplate(coachId);
  }, []);
  return (
    <Layout>
      <button
        style={{
          border: "2px solid black",
          padding: 3,
        }}
        onClick={() => setOpenCreateMessageModal(true)}
      >
        add button
      </button>
      <button
        style={{
          border: "2px solid black",
          padding: 3,
        }}
        onClick={() => {
          setOpenUpdateMessageModal(true);
        }}
      >
        update template
      </button>
      {openCreateMessageModal && (
        <CreateTemplateModal
          setOpenCreateMessageModal={setOpenCreateMessageModal}
          openCreateMessageModal={openCreateMessageModal}
          setInputTemplateName={setInputTemplateName}
          setInputTemplateMessage={setInputTemplateMessage}
          coachId={coachId}
          inputTemplateMessage={inputTemplateMessage}
          inputTemplateName={inputTemplateName}
          openUpdateMessageModal={openUpdateMessageModal}  
        />
      )}
    </Layout>
  );
};

export default MessageTemplate;
