import { CloseRounded } from "@mui/icons-material";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SearchIcon, UserIcon } from "../../pages/getStreamChat/assets";
import SmallCloseIcon from "../../pages/library/assests/SmallCloseIcon";
import { assignAthleteToCoach } from "@/ApiServices/AdminDashboard/Api";

const AssignAthleteToCoach = ({
  isOpen,
  setIsOpen,
  selectedUser,
  getTrainerDashboard,
}) => {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const isLivezy = useSelector((state) => state.auth.isLivezy);
  const coachID = useSelector((state) => state.auth.trainerUUID);
  const [loading, setLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [selectedCoach, setSelectedCoach] = useState(null);
  const [searched, setSearched] = useState("");
  const [showOptions, setShowOptions] = useState(false);

  const resetAllStates = () => {
    setSelectedCoach(null);
    setSearched("");
    setShowOptions(false);
  };

  const getAdminDashboard = async () => {
    setLoading(true);
    await axios({
      method: "get",
      url: `${APIURL}CF/api/AdminCoachDashboard`,
      params: { coachID: coachID },
    })
      .then((res) => {
        console.log("admin dashboard res: ", res.data);
        setDashboardData(res.data.result?.coaches);
        setOriginalData(res.data.result?.coaches);
      })
      .catch((err) => {
        console.log("admin dashboard error: ", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onAssign = async () => {
    setLoading(true);
    // if (!isLivezy) {
    await axios({
      method: "post",
      url: `${APIURL}CF/api/CoachWeb/Update/updateAtheleteProperties`,
      data: {
        athleteID: selectedUser.UserUUID ?? selectedUser.id,
        coachID: selectedCoach?.id,
      },
    })
      .then((res) => {
        getTrainerDashboard(selectedCoach?.id);
      })
      .catch((err) => {})
      .finally(() => {
        setIsOpen(false);
        resetAllStates();
      });
    // } else {
    //   let res = await assignAthleteToCoach({
    //     coachID: selectedCoach?.id,
    //     athleteID: selectedUser.UserUUID ?? selectedUser.id,
    //     coachEmail: selectedCoach?.email,
    //   });

    //   if (res) {
    //     getTrainerDashboard(selectedCoach?.id);
    //   }
    //   setIsOpen(false);
    //   resetAllStates();
    // }
    setLoading(false);
  };

  useEffect(() => {
    getAdminDashboard();
  }, [coachID]);

  const onSearch = (value) => {
    if (value === "") {
      setDashboardData(originalData);
    } else {
      const filteredData = originalData.filter((item) => {
        return item?.name?.toLowerCase().includes(value.toLowerCase());
      });
      setDashboardData(filteredData);
    }
  };

  const onCancel = () => {
    setDashboardData(originalData);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      maxWidth="sm"
      fullWidth
      sx={{
        backdropFilter: "blur(5px)",
      }}
      PaperProps={{
        sx: { borderRadius: "16px" },
      }}
    >
      <div className="add-program-modal-head">
        <p className="add-program-modal-head-title">
          Select trainer to assign.
        </p>
        <CloseRounded
          sx={{
            position: "absolute",
            right: 24,
            top: 24,
            cursor: "pointer",
          }}
          onClick={() => {
            setIsOpen(false);
            resetAllStates();
          }}
        />
      </div>
      <DialogContent
        sx={{
          p: 0,
          minHeight: "350px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {/* Select all subbordinate coaches. */}

        <div className="mt-4">
          <div
            className="channel-search__input__wrapper"
            style={{
              minWidth: "500px",
              maxWidth: "500px",
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
              height: "auto",
              justifyContent: "flex-start",
            }}
          >
            {selectedCoach && (
              <div
                style={{
                  color: "#333",
                }}
                className="searched-selected"
              >
                <div className="selected-user-icon-div">
                  <UserIcon />
                </div>
                <p
                  style={{
                    color: "#333",
                  }}
                >
                  {selectedCoach?.name ?? ""}
                </p>
                <div onClick={() => setSelectedCoach(null)}>
                  <SmallCloseIcon />
                </div>
              </div>
            )}
            {!selectedCoach && (
              <div
                style={{
                  width: "100%",
                }}
                className="channel-search__input__icon"
              >
                <SearchIcon />
                <input
                  className="channel-search__input__text"
                  onFocus={() => {
                    setShowOptions(true);
                  }}
                  // onBlur={() => {
                  //   setShowOptions(false);
                  // }}
                  onChange={(e) => {
                    onSearch(e.target.value);
                    setSearched(e.target.value);
                    setShowOptions(true);
                  }}
                  placeholder="Search coaches"
                  type="text"
                  style={{
                    width: "100%",
                  }}
                  value={searched}
                />
              </div>
            )}
          </div>
          {showOptions && (
            <div className="search-result-view">
              <div style={{ width: "100%" }}>
                <div className="label-new">Users</div>
                {dashboardData.length > 0 &&
                  dashboardData.map((item) => {
                    return (
                      <div
                        className="search-result-view-item-container"
                        style={{ width: "100%" }}
                        onClick={() => {
                          console.log("item: ", item);
                          setSelectedCoach(item);
                          setSearched("");
                          setShowOptions(false);
                        }}
                      >
                        <div
                          className="channel-preview__item_avatar"
                          style={{
                            backgroundColor: "rgb(255, 229, 211)",
                          }}
                        >
                          <UserIcon />
                        </div>
                        <p
                          style={{
                            color: "#333",
                          }}
                        >
                          {item.name}
                        </p>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "center",
        }}
      >
        <button
          disabled={!selectedCoach || loading}
          className="button-new"
          onClick={onAssign}
        >
          {loading ? "Assigning..." : "Assign"}
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignAthleteToCoach;
