import { getSignedUrlClientExerciseVideos } from "@/ApiServices/ClientDetails/Api";
import { SpurfitCircularProgress } from "@/components/Dashboard/dashboard";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Video } from "lucide-react";
import { useEffect, useState } from "react";
import ReactPlayer from "react-player";

const VideoPlayerDialog = ({ videoLink }) => {
  const [signedLink, setSignedLink] = useState(null);
  const [loading, setLoading] = useState(false);
  const getSignedLink = async (videoLink) => {
    setLoading(true);
    const signedLink = await getSignedUrlClientExerciseVideos(videoLink);
    if (signedLink) {
      setSignedLink(signedLink);
    }
    setLoading(false);
  };

  return (
    <Dialog
      onOpenChange={(isOpen) => {
        if (isOpen) {
          getSignedLink(videoLink);
        }
      }}
    >
      <DialogTrigger>
        <Video size="24" color="#676767" />
      </DialogTrigger>
      <DialogContent>
        <DialogHeader></DialogHeader>
        {loading ? (
          <SpurfitCircularProgress />
        ) : (
          <ReactPlayer
            width={"100%"}
            controls={true}
            className="react-player"
            url={signedLink}
          />
          // <video
          //   width="100%"
          //   height="240"
          //   controls
          //   style={{ width: "100%", height: "240px" }}
          // >
          //   <source src={signedLink} type="video/mp4" />
          //   Your browser does not support the video tag.
          // </video>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default VideoPlayerDialog;
