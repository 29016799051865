import {
  postMultipleUserForm,
  postSignleUserForm,
} from "@/ApiServices/FormControl/Api";
import SpinnerComponent from "@/components/layout/spinner";
import { Input } from "@/components/ui/input";
import TextField from "@mui/material/TextField/TextField";
import { CloseRounded } from "@mui/icons-material";
import { Backdrop } from "@mui/material";
import axios from "axios";
import Fuse from "fuse.js";
import _debounce from "lodash.debounce";
import moment from "moment";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import { Streami18n, enTranslations, useChatContext } from "stream-chat-react";
import { CustomAlertContext } from "../../../App";
import { SearchIcon } from "../../../pages/getStreamChat/assets";
import SmallCloseIcon from "../../../pages/library/assests/SmallCloseIcon";
import { UserIcon } from "../../../pages/library/assests/UserIcon";
import { chatClient, chatInit } from "../../../utils/chat";
import { getStreamclient } from "../../../utils/getStreamclients";
import InputAdornment from "@mui/material/InputAdornment";

const i18nInstance = new Streami18n({
  language: "en",
  translationsForLanguage: {
    ...enTranslations,
  },
});

function AssignUserModal({
  form,
  showModal,
  setShowModal,
  isAssignFromWorkoutBuilder,
  onAssign,
}) {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const [searched, setSearched] = useState("");
  const [userData, setUserData] = useState([]);
  const [tempUserData, setTempUserData] = useState([]);
  const [activeUser, setActiveUser] = useState([]);
  const [startDate, setStartDate] = useState(new Date().setHours(0, 0, 0, 0));
  const [selectedDay, setSelectedDay] = useState({
    value: 1,
    label: "Day 1",
  });
  const [loading, setLoading] = useState(false);
  const [usersForPage, setUsersForPage] = useState([]);
  const [page, setPage] = useState(1);
  const [selectedList, setSelectedList] = useState([]);

  const menuPortalRef = useRef(null);

  const { displayAlert } = useContext(CustomAlertContext);
  const days = [];

  const { client, setActiveChannel, channel } = useChatContext();

  const [allChannels, setAllChannels] = useState([]);
  const [teamChannels, setTeamChannels] = useState([]);
  const [teamFilteredChannels, setTeamFilteredChannels] = useState([]);
  const [directChannels, setDirectChannels] = useState([]);
  let trainerDetails = JSON.parse(localStorage.getItem("trainerCredentials"));

  const [focused, setFocused] = useState(undefined);
  const [focusedId, setFocusedId] = useState("");
  const [query, setQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [endDate, setEndDate] = useState(moment().add(1, "day").toDate());
  const [frequency, setFrequency] = useState(null);
  // console.warn(form)
  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "ArrowDown") {
        setFocused((prevFocused) => {
          if (prevFocused === undefined) return 0;
          return prevFocused === allChannels.length - 1 ? 0 : prevFocused + 1;
        });
      } else if (event.key === "ArrowUp") {
        setFocused((prevFocused) => {
          if (prevFocused === undefined) return 0;
          return prevFocused === 0 ? allChannels.length - 1 : prevFocused - 1;
        });
      } else if (event.keyCode === 13) {
        event.preventDefault();
        setActiveChannel(allChannels[focused]);
        setFocused(undefined);
        setFocusedId("");
        setQuery("");
      }
    },
    [allChannels, focused, setActiveChannel]
  );

  const connectClient = async () => {
    // console.warn("chat client");
    if (!chatClient?.userID) {
      await chatInit(); //CONNECTING TO GETSTREAM IO
    }
  };

  useEffect(() => {
    connectClient();
    if (query) {
      document.addEventListener("keydown", handleKeyDown);
    }
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [handleKeyDown, query]);

  useEffect(() => {
    if (!query) {
      // setTeamChannels([]);
      setDirectChannels([]);
    }
  }, [query]);

  useEffect(() => {
    if (focused >= 0) {
      setFocusedId(allChannels[focused]?.id);
    }
  }, [allChannels, focused]);

  const setChannel = (channel) => {
    setQuery("");
    setActiveChannel(channel);
  };

  const getChannels = async (text) => {
    setLoading(true);
    if (teamChannels.length == 0) {
      try {
        const data = await getStreamclient(trainerDetails);
        const channelResponse = client.queryChannels(
          {
            type: "team",
            // name: { $autocomplete: text },
            members: { $in: [trainerDetails.password] },
          },
          {},
          { limit: 5 }
        );

        const userResponse = client.queryUsers(
          {
            id: { $in: data },
            $and: [{ name: { $autocomplete: text } }],
          },
          { id: 1 },
          { limit: 5 }
        );

        const [channels, { users }] = await Promise.all([
          channelResponse,
          userResponse,
        ]);
        console.warn("team channels**", channels);
        if (channels.length) {
          setTeamChannels(channels);
          setTeamFilteredChannels(channels);
          // searchUsers(text)
        }
        if (users.length) {
          setDirectChannels(users);
        }
        setAllChannels(channels.concat(users));
      } catch (e) {
        setQuery("");
        console.warn(e, "channels");
      }
    }
    setLoading(false);
  };

  const getChannelsDebounce = _debounce(getChannels, 200, {
    trailing: true,
  });

  const onSearch = (event) => {
    event.preventDefault();
    setSearched(event.target.value);

    setLoading(true);
    setFocused(undefined);
    setQuery(event.target.value);
    if (!event.target.value) return;

    getChannelsDebounce(event.target.value);
  };

  for (let i = 1; i <= 365; i++) {
    let dayNumber = i;
    if (i % 7 === 1) {
      // Add a new option for the start of each week
      let weekNumber = Math.ceil(i / 7);
      days.push({
        value: "week",
        label: `Week ${weekNumber}`,
        isDisabled: true,
      });
    }
    days.push({ value: i, label: `Day ${dayNumber}` });
  }

  let pageSize = 6;
  const fetchUsers = () => {
    setLoading(true);
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const usersForPage = userData.slice(startIndex, endIndex);
    const removedSelectedUser = userData.filter(
      (obj) => !activeUser.includes(obj.id)
    );
    setUsersForPage(removedSelectedUser);
    setLoading(false);
    console.log({ usersForPage });
  };

  const searchUsers = (searchedVal) => {
    setSearched(searchedVal);
    // console.warn(tempUserData);

    const fuseOptions = {
      keys: ["name", "userName"], // Properties to search within
      threshold: 0.1, // Adjust the threshold for fuzzy matching
    };

    const fuseOptions2 = {
      keys: ["data.userName", "data.name"], // Properties to search within
      threshold: 0.1, // Adjust the threshold for fuzzy matching
    };

    if (searchedVal !== "") {
      const fuse = new Fuse(tempUserData, fuseOptions);
      const searchResults = fuse.search(searchedVal);

      const filteredRows = searchResults.map((result) => result.item);

      setUserData(filteredRows);

      console.warn("teamChannels ", tempUserData);
      console.warn("teamChannels2 ", teamChannels);

      const fuse2 = new Fuse(teamChannels.flat(), fuseOptions2);
      const searchResultschannel = fuse2.search(searchedVal);

      const filteredRowschannel = searchResultschannel.map(
        (result) => result.item
      );
      // console.warn(filteredRowschannel);
      // if (filteredRowschannel.length > 0) {
      setTeamFilteredChannels(filteredRowschannel);
      // }
    } else {
      setUserData(tempUserData);
    }
  };

  const postFormSignleUser = async () => {
    setIsLoading(true);
    let data = {
      athleteID: activeUser[0],
      formID: form?.id,
      assignmentStartDate: `${moment(startDate)
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ss")}Z`,
      assignmentEndDate: `${moment(endDate)
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ss")}Z`,
      frequency: `${frequency}`, //Integer as String.
    };

    const res = await postSignleUserForm(data);
    // console.warn(res)
    if (res && res.result && res.result.id) {
      displayAlert({
        message: "Form assigned successfully",
        type: "success",
      });
    } else {
      displayAlert({
        message: "Error assigning Form to user",
        type: "error",
      });
    }
    setShowModal(false);
  };
  const postFormMultipleUser = async () => {
    let data = {
      athleteIDs: activeUser,
      formID: form?.id,
      assignmentStartDate: `${moment(startDate)
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ss")}Z`,
      assignmentEndDate: `${moment(endDate)
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ss")}Z`,
      frequency: `${frequency}`, //Integer as String.
    };

    const res = await postMultipleUserForm(data);
    if (res && res.result && res.result.id) {
      displayAlert({
        message: "Form assigned successfully",
        type: "success",
      });
    } else {
      displayAlert({
        message: "Error assigning Form to user",
        type: "error",
      });
    }
    setShowModal(false);
  };

  const assignFormToUser = () => {
    // console.warn(activeUser, "activeuser");
    if (!activeUser || activeUser.length == 0) {
      displayAlert({
        type: "error",
        message: "Please select a user to assign this Form",
      });
      return;
    }
    setIsLoading(true);

    if (activeUser.length == 1) postFormSignleUser();
    else postFormMultipleUser();
    console.log("warn", activeUser);
  };

  const getUsers = () => {
    let trainerDetails = JSON.parse(localStorage.getItem("trainerCredentials"));
    setLoading(true);
    axios({
      method: "get",
      url: `${APIURL}CF/api/CoachWeb/Get/getAllAtheletesOneCoach`,
      params: {
        coachID: trainerDetails?.password,
      },
    }).then(async (res) => {
      console.log("Dashboard query on assign: ", res);
      const filteredData = res.data.result?.[0]?.atheletes?.filter(
        (item) => item.active == true
      );

      setUserData(filteredData);
      setTempUserData(filteredData);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchUsers();
    let timeOut = setTimeout(() => {
      // console.warn("channel***");
      getChannels("a");
    }, 50);

    return () => {
      clearTimeout(timeOut);
    };
  }, [userData, page, showModal]);

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 2 }}
      open={showModal}
    >
      <div
        style={{
          overflowY: "inherit",
          position: "absolute",
          background: "#fff",
          boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.12)",
          borderRadius: "12px",
        }}
        ref={menuPortalRef}
      >
        {isLoading && (
          <div
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 9999,
              background: "rgba(0,0,0,0.3)",
            }}
          >
            <SpinnerComponent />
          </div>
        )}
        <div
          style={{
            minWidth: "550px",
            maxWidth: "550px",
          }}
        >
          <div className="add-program-modal-head">
            <p className="add-program-modal-head-title">Assign to Users</p>
            <div
              onClick={() => {
                setShowModal(false);
                setActiveUser([]);
              }}
            >
              <CloseRounded
                sx={{
                  fontSize: "24px",
                  color: "#000",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
          {/* {loading ? ( */}
          <div className="add-program-modal-body">
            <div
              className="full-width-container"
              style={{
                paddingBottom: "20px",
              }}
            >
              <div>
                <div
                  className="channel-search__input__wrapper"
                  style={{
                    minWidth: "500px",
                    maxWidth: "500px",
                    width: "100%",
                    display: "flex",
                    flexWrap: "wrap",
                    height: "auto",
                    justifyContent: "flex-start",
                  }}
                >
                  {selectedList.length > 0 &&
                    selectedList.map((item, index) => {
                      return (
                        <div
                          style={{
                            color: "#333",
                          }}
                          className="searched-selected"
                        >
                          <div className="selected-user-icon-div">
                            {item.team ? "#" : <UserIcon />}
                          </div>
                          <p
                            style={{
                              color: "#333",
                            }}
                          >
                            {item.name}
                          </p>
                          <div
                            onClick={() => {
                              if (item.team) {
                                item.user.map((userid) => {
                                  setActiveUser((prevState) => {
                                    return prevState.filter(
                                      (uuid) => uuid !== userid
                                    );
                                  });
                                });
                              } else {
                                setActiveUser((prevState) => {
                                  return prevState.filter(
                                    (uuid) => uuid !== item.id
                                  );
                                });
                              }
                              delete selectedList[index];
                            }}
                          >
                            <SmallCloseIcon />
                          </div>
                        </div>
                      );
                    })}
                  <div
                    style={{
                      width: "100%",
                    }}
                    className="channel-search__input__icon"
                  >
                    <SearchIcon />
                    <input
                      className="channel-search__input__text"
                      onChange={(e) => {
                        onSearch(e);
                        searchUsers(e.target.value);
                      }}
                      placeholder="Search users or groups"
                      type="text"
                      style={{
                        width: "100%",
                      }}
                      value={searched}
                    />
                  </div>
                </div>
                {searched.length > 0 && (
                  <div className="search-result-view-divide">
                    <div>
                      <div className="label-new">Users</div>
                      {usersForPage.length > 0 &&
                        usersForPage.map((item) => {
                          return (
                            <div
                              className="search-result-view-item-container"
                              onClick={() => {
                                setSelectedList((prev) => [
                                  ...prev,
                                  {
                                    id: item.id,
                                    name: item.name || item.userName,
                                    team: false,
                                  },
                                ]);
                                setActiveUser((prevState) => [
                                  ...prevState,
                                  item.id,
                                ]);
                                setSearched("");
                              }}
                            >
                              <div
                                className="channel-preview__item_avatar"
                                style={{
                                  backgroundColor: "rgb(255, 229, 211)",
                                }}
                              >
                                <UserIcon />
                              </div>
                              <p
                                style={{
                                  color: "#333",
                                }}
                              >
                                {item.name || item.userName}
                              </p>
                            </div>
                          );
                        })}
                    </div>
                    <span className="filter-modal-divider"></span>
                    <div>
                      <div className="label-new">Groups</div>
                      {teamFilteredChannels.length > 0 &&
                        teamFilteredChannels.map((item) => {
                          return (
                            <div
                              className="search-result-view-item-container"
                              onClick={async () => {
                                setActiveChannel(item);
                                const sort = {
                                  user_id: -1,
                                };
                                const alreadyUser = item.state.members;
                                console.warn(
                                  item.state.members,
                                  alreadyUser,
                                  "item"
                                );
                                let listUser = [];
                                Object.entries(alreadyUser).forEach(
                                  ([key, value]) => {
                                    console.warn(key, value, "key item");
                                    if (key != trainerDetails.password) {
                                      listUser.push(key);
                                      setActiveUser((prevState) => [
                                        ...prevState,
                                        key,
                                      ]);
                                    }
                                  }
                                );
                                console.warn(listUser, "listUsers", item);
                                let selectData = {
                                  id: item.data.id,
                                  name: item.data.name || item.data.userName,
                                  team: true,
                                  user: listUser,
                                };
                                console.warn(selectData, "selectdata");
                                if (!selectedList.includes(selectData)) {
                                  setSelectedList((prev) => [
                                    ...prev,
                                    selectData,
                                  ]);
                                }
                                setSearched("");
                              }}
                            >
                              <div
                                className="channel-preview__item_avatar"
                                style={{
                                  backgroundColor: "#CCF8FE",
                                  color: "#000",
                                }}
                              >
                                #
                              </div>
                              <p
                                style={{
                                  color: "#333",
                                }}
                              >
                                {item.data.name || item.data.userName}
                              </p>
                            </div>
                          );
                        })}
                      {loading && <SpinnerComponent />}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "20px",
                  padding: "20px",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <label
                    htmlFor="startDate"
                    style={{
                      display: "block",
                      color: "#000",
                      fontSize: 12,
                      marginBottom: 5,
                    }}
                  >
                    Start date
                  </label>
                  <DatePicker
                    dateFormat="dd MMM yyyy"
                    minDate={moment().startOf("day").toDate()}
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    customInput={
                      <input
                        type="text"
                        required
                        style={{
                          width: "100%",
                          height: "40px",
                          outline: "none",
                          fontFamily: "DM Sans",
                          cursor: "pointer",
                          color: "#000",
                          boxSizing: "border-box",
                          textAlign: "start",
                        }}
                      />
                    }
                  />
                </div>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <label
                    htmlFor="frequency"
                    style={{
                      display: "block",
                      color: "#000",
                      fontSize: 12,
                      marginBottom: 5,
                    }}
                  >
                    Frequency(optional)
                  </label>
                  <TextField
                    placeholder="3"
                    value={frequency}
                    onChange={(e) => {
                      setFrequency(e.target.value);
                    }}
                    className="h-[40px] rounded-lg px-5 focus-visible:ring-transparent text-black-pure"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">days</InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <label
                    htmlFor="endDate"
                    style={{
                      display: "block",
                      color: "#000",
                      fontSize: 12,
                      marginBottom: 5,
                    }}
                  >
                    End date(optional)
                  </label>
                  <DatePicker
                    dateFormat="dd MMM yyyy"
                    minDate={moment().add(1, "day").startOf("day").toDate()}
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    customInput={
                      <input
                        type="text"
                        style={{
                          width: "100%",
                          height: "40px",
                          outline: "none",
                          fontFamily: "DM Sans",
                          cursor: "pointer",
                          color: "#000",
                          boxSizing: "border-box",
                          textAlign: "center",
                        }}
                      />
                    }
                  />
                </div>
              </div>
            </div>
            <div className="w-full flex items-center justify-center">
              <button
                className="button-new"
                onClick={() => {
                  assignFormToUser();
                }}
              >
                Assign User
              </button>
            </div>
          </div>
        </div>
      </div>
    </Backdrop>
  );
}

export default AssignUserModal;
