// Function to convert between units based on the selected unit
function convertUnits(food, quantity, selectedUnit) {
  if (selectedUnit === food.changeUnit) {
    return quantity * food.baseToChangeRatio; // Convert to change unit
  } else if (selectedUnit === food.baseUnit) {
    return quantity / food.baseToChangeRatio; // Convert to base unit
  } else {
    return "Invalid unit selected";
  }
}

export { convertUnits };
