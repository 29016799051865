import APIURL from "@/APIDetails";
import {
  addItemToFolder,
  createFolder,
  deleteFolder,
  getAllTheFolders,
  removeItemFromFolder,
  shiftItemBetweenFolder,
  updateFolderInfo,
} from "@/ApiServices/Foods/Api";
import { CustomAlertContext } from "@/App";
import { store } from "@/redux/store";
import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SpurfitCircularProgress } from "../Dashboard/dashboard";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getAllMealPlans } from "@/ApiServices/Meals/Api";
export const NutritionContext = createContext();

export const NutritionProvider = ({ children }) => {
  const coachID = useSelector((state) => state.auth?.trainerUUID);
  const adminID = useSelector((state) => state.auth.data?.adminID);

  const [isLoading, setIsLoading] = useState(false);
  const [folders, setFolders] = useState([]);
  const [templateFolders, setTemplateFolders] = useState([]);
  const [openFolderId, setOpenFolderId] = useState("");
  const [folderSelected, setFolderSelected] = useState({});
  const [selectedMealItem, setSelectedMealItem] = useState({});
  const [loadingSearchedItem, setLoadingSearchedItem] = useState(false);
  const [nutritionData, setNutritionData] = useState([]);
  const [defaultNItem, setdefaultNItem] = useState({});
  const [selectedFoodType, setSelectedFoodType] = useState("global");
  const [selectedRecipeType, setSelectedRecipeType] = useState("global");
  const [showEditMealInfoModal, setShowEditMealInfoModal] = useState(false);
  const [showDialogToFolderSelect, setShowDialogToFolderSelect] =
    useState(false);
  const { displayAlert } = useContext(CustomAlertContext);
  const [selectedTabInMeaLPlan, setSelectedTabInMeaLPlan] = useState(0);
  const [isTemplate, setIsTemplate] = useState(false);
  const [openAddMealModal, setOpenAddMealModal] = useState(false);
  const [templateMealPlans, setTemplateMealPlans] = useState([]);
  const [myMealPlans, setMyMealPlans] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [unsavedMealPlan, setUnsavedMealPlan] = useState(null);
  const [isShifting, setIsShifting] = useState("");
  const [itemToShift, setItemToShift] = useState({});
  const [tempDataForFolders, setTempDataForFolders] = useState([]);
  const [tempDataForTemplateFolders, setTempDataForTemplateFolders] = useState(
    []
  );
  const [foodData, setFoodData] = useState({
    trainerData: [],
    globalData: [],
  });
  const { id, selectedMealID } = useParams();
  useEffect(() => {
    if (id == 3) {
      getAllFolders();
    }
  }, [id]);

  useEffect(() => {
    // separate template and program meal plans
    if (id == 3) {
      if (nutritionData?.length > 0) {
        const templateMealPlans = nutritionData.filter(
          (mealPlan) => mealPlan.isTemplate
        );
        const mealPlans = nutritionData.filter(
          (mealPlan) => !mealPlan.isTemplate
        );
        setTemplateMealPlans(templateMealPlans);
        setMyMealPlans(mealPlans);
      } else {
        setTemplateMealPlans([]);
        setMyMealPlans([]);
      }
    }
  }, [nutritionData]);

  // all request related to meals
  const getMealPlans = async (id) => {
    setIsLoading(true);
    checkForUnsavedMealPlans();

    let allMealPlans = await getAllMealPlans(coachID, adminID);

    let mealPlanNotConnectedToFolders = allMealPlans?.filter(
      (meal) => typeof meal?.mealPlanFolderId !== "string"
    );

    let sorted = mealPlanNotConnectedToFolders;
    if (selectedMealID && selectedMealID !== "undefined") {
      // get the selected meal plan on top of the list and set it as default
      sorted = allMealPlans.filter((item) => item.id !== selectedMealID);
      sorted.unshift(allMealPlans.find((item) => item.id === selectedMealID));

      setdefaultNItem(sorted[0]);
    } else if (id) {
      allMealPlans.find((item) => {
        if (item.id === id) {
          setdefaultNItem(item);
        }
      });
    }
    // else {
    //   setdefaultNItem(allMealPlans[0]);
    // }

    setNutritionData(sorted);
    // this data is for the searching
    setTempData(sorted);
    setIsLoading(false);
  };

  const checkForUnsavedMealPlans = async () => {
    let unsavedMealPlan = localStorage.getItem("unsavedMealPlan");
    if (unsavedMealPlan) {
      let parsedUnsavedMealPlan = JSON.parse(unsavedMealPlan);
      setUnsavedMealPlan(parsedUnsavedMealPlan);
    }
  };

  const onFolderSelected = (folder) => {
    setFolderSelected(folder);
    setdefaultNItem({});
  };

  const getTrainerRecipes = async () => {
    try {
      const res = await axios({
        method: "post",
        url: `${APIURL}CF/api/getReceipeByUserId`,
        data: {
          userId: store.getState().auth.trainerUUID,
        },
      });

      const formattedRecipes = res?.data?.recipes?.map((r) => {
        let recipe = r?.serving;
        let directions = Object.entries(recipe?.directions || {}).map(
          ([key, value]) => {
            return {
              direction_number: key,
              direction_description: value,
            };
          }
        );

        let ingredients = Object.entries(recipe?.ingredients || []).map(
          ([key, value]) => {
            return {
              ingredient_description: value,
            };
          }
        );

        return {
          ...recipe,
          directions,
          ingredients,
          id: r?.id,
        };
      });

      return formattedRecipes;
    } catch (err) {
      console.log("error getting trainer recipes: ", err);
      return null;
    }
  };

  const getRecipeAfterDeletion = async () => {
    let trainerRecipes = await getTrainerRecipes();
    setNutritionData(trainerRecipes);
    if (trainerRecipes.length > 0) {
      setdefaultNItem(trainerRecipes[0]);
    }
    let timeout = setTimeout(() => {
      setLoadingSearchedItem(false);
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  };

  const deleteRecipe = async (id) => {
    setLoadingSearchedItem(true);
    try {
      const res = await axios({
        method: "post",
        url: `${APIURL}CF/api/deleteReceipe`,
        data: {
          id: id,
        },
      });

      console.log("recipe deleted successfully");
      await getRecipeAfterDeletion();
      return res;
    } catch (err) {
      console.log("error deleting recipe ");
      setLoadingSearchedItem(false);
      return null;
    }
  };

  const deleteLocalFood = async (id) => {
    setLoadingSearchedItem(true);
    try {
      const res = await axios({
        method: "post",
        url: `${APIURL}CF/api/deleteLocalFood`,
        data: {
          id: id,
        },
      });

      console.log("local food deleted successfully");
      let newLocalFoodDataWithDeletedItem = foodData.trainerData.filter(
        (obj) => obj.id !== id
      );
      setNutritionData(newLocalFoodDataWithDeletedItem);
      setdefaultNItem(newLocalFoodDataWithDeletedItem[0]);
      setFoodData((prev) => ({
        ...prev,
        trainerData: newLocalFoodDataWithDeletedItem || [],
      }));
      setLoadingSearchedItem(false);
      return res;
    } catch (err) {
      console.log("error deleting recipe ");
      setLoadingSearchedItem(false);
      return null;
    }
  };

  const updateLocalFood = async (data) => {
    setLoadingSearchedItem(true);
    try {
      const res = await axios({
        method: "post",
        url: `${APIURL}CF/api/updateFood`,
        data: data,
      });
      let updatedLocalFoodData = foodData.trainerData;
      let updatedFoodIndex = updatedLocalFoodData.findIndex(
        (obj) => obj.id === data.id
      );
      updatedLocalFoodData[updatedFoodIndex] = data;

      setNutritionData(updatedLocalFoodData);
      setdefaultNItem(updatedLocalFoodData[updatedFoodIndex]);
      setFoodData((prev) => ({
        ...prev,
        trainerData: updatedLocalFoodData || [],
      }));
      setLoadingSearchedItem(false);
    } catch (error) {
      console.log("Error in updating the local foods", error);
    }
  };

  const createNewFolder = async (name, description) => {
    setIsLoading(true);
    try {
      let data = {
        name,
        description,
        coachID,
      };
      if (selectedTabInMeaLPlan === 1) {
        data["isTemplate"] = true;
      } else if (selectedTabInMeaLPlan === 0) {
        data["isTemplate"] = false;
      }
      console.log("this is the", data);
      let res = await createFolder(data);

      if (res) {
        const newFolder = res;
        if (res?.isTemplate) {
          setTemplateFolders([newFolder, ...templateFolders]);
          setTempDataForTemplateFolders([newFolder, ...templateFolders]);
        } else {
          setFolders([newFolder, ...folders]);
          setTempDataForFolders([newFolder, ...folders]);
        }

        setFolderSelected(newFolder);
        setOpenFolderId(newFolder?.id);
        setdefaultNItem({});
        displayAlert({
          message: "Folder Created successfully",
          type: "success",
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      displayAlert({
        message: "There is some Error",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getAllFolders = async () => {
    try {
      let res1;
      
  
      if (adminID && coachID !== adminID) {
        let subordinateCoach = await getAllTheFolders(coachID);
        let admin = await getAllTheFolders(adminID);
        res1 = [...admin, ...subordinateCoach];
      }else{
res1 = await getAllTheFolders(coachID);
      }
      

      if (res1) {
        // filter folders on the bases for the templates
        let templateFolders = res1.filter(
          (folder) => folder?.isTemplate === true
        );
        if (templateFolders) {
          setTemplateFolders(templateFolders);
          setTempDataForTemplateFolders(templateFolders);
        }
        let normalFolders = res1.filter(
          (folder) => folder?.isTemplate !== true
        );
        if (normalFolders) {
          setFolders(normalFolders);
          setTempDataForFolders(normalFolders);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateFolderDetails = async (name, des) => {
    setIsLoading(true);
    try {
      let data = {
        folderID: folderSelected?.id,
        name: name,
        description: des,
      };

      const res = await updateFolderInfo(data);

      if (res) {
        setFolderSelected((prev) => {
          const prevData = { ...prev };

          if (prevData?.name) {
            prevData.name = name;
          }

          if (prevData?.description) {
            prevData.description = des;
          } else {
            prevData["description"] = des;
          }

          if (prevData?.isTemplate) {
            setTemplateFolders((prevFolders) => {
              const newData = prevFolders.map((folder) => {
                if (folder.id === folderSelected.id) {
                  return prevData;
                }
                return folder;
              });
              return newData;
            });
            setTempDataForTemplateFolders((prevFolders) => {
              const newData = prevFolders.map((folder) => {
                if (folder.id === folderSelected.id) {
                  return prevData;
                }
                return folder;
              });
              return newData;
            });
          } else {
            setFolders((prevFolders) => {
              const newData = prevFolders.map((folder) => {
                if (folder.id === folderSelected.id) {
                  return prevData;
                }
                return folder;
              });
              return newData;
            });
            setTempDataForFolders((prevFolders) => {
              const newData = prevFolders.map((folder) => {
                if (folder.id === folderSelected.id) {
                  return prevData;
                }
                return folder;
              });
              return newData;
            });
          }
          return prevData;
        });

        displayAlert({
          message: "Folder Updated successfully",
          type: "success",
        });
        setIsLoading(false);
        return true;
      }
    } catch (error) {
      console.log(error);
      displayAlert({
        message: "There is some error",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const deleteTheFolder = async () => {
    setIsLoading(true);
    try {
      let data = {
        id: folderSelected?.id,
        coachID: coachID,
      };

      const res = await deleteFolder(data);

      if (res) {
        if (folderSelected?.isTemplate) {
          setTemplateFolders((prevFolders) => {
            const newData = prevFolders.filter((folder) => {
              if (folder.id === folderSelected.id) {
                return false;
              }
              return folder;
            });
            return newData;
          });

          setTempDataForTemplateFolders((prevFolders) => {
            const newData = prevFolders.filter((folder) => {
              if (folder.id === folderSelected.id) {
                return false;
              }
              return folder;
            });
            return newData;
          });
        } else {
          setFolders((prevFolders) => {
            const newData = prevFolders.filter((folder) => {
              if (folder.id === folderSelected.id) {
                return false;
              }
              return folder;
            });
            return newData;
          });
          setTempDataForFolders((prevFolders) => {
            const newData = prevFolders.filter((folder) => {
              if (folder.id === folderSelected.id) {
                return false;
              }
              return folder;
            });
            return newData;
          });
        }

        setFolderSelected();

        displayAlert({
          message: "Folder Deleted successfully",
          type: "success",
        });
        setIsLoading(false);
        return true;
      }
    } catch (error) {
      console.log(error);
      displayAlert({
        message: "There is some error",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const addItemToTheFolder = async (
    selectedFolder,
    mealPlanIDs,
    selectedMealPlan
  ) => {
    setIsLoading(true);
    try {
      let data = {
        folderID: selectedFolder?.id,
        mealPlanIDs: mealPlanIDs,
      };

      const res = await addItemToFolder(data);

      if (res) {
        console.log("this is for the meal plan", res, folders, templateFolders);

        // this is for the my meal plan tab
        if (selectedTabInMeaLPlan == 0) {
          setFolders((prev) => {
            let oldData = [...tempDataForFolders];
            const newFolderData = oldData?.map((folder) => {
              if (folder?.id === selectedFolder?.id) {
                if (folder?.mealPlans) {
                  const mealPlanArr = folder.mealPlans;
                  let selectedMealPlanUpdate = { ...selectedMealPlan };
                  selectedMealPlanUpdate["mealPlanFolderId"] =
                    selectedFolder?.id;
                  mealPlanArr?.push(selectedMealPlanUpdate);
                  folder.mealPlans = mealPlanArr;
                } else {
                  let selectedMealPlanUpdate = { ...selectedMealPlan };
                  selectedMealPlanUpdate["mealPlanFolderId"] =
                    selectedFolder?.id;
                  const mealPlanArray = [selectedMealPlanUpdate];

                  folder["mealPlans"] = mealPlanArray;
                }
                return folder;
              }
              return folder;
            });
            setTempDataForFolders(newFolderData);
            return newFolderData;
          });
        } else if (selectedTabInMeaLPlan == 1) {
          setTemplateFolders((prev) => {
            let oldData = [...tempDataForTemplateFolders];
            const newFolderData = oldData?.map((folder) => {
              if (folder?.id === selectedFolder?.id) {
                if (folder?.mealPlans) {
                  const mealPlanArr = folder.mealPlans;
                  let selectedMealPlanUpdate = { ...selectedMealPlan };
                  selectedMealPlanUpdate["mealPlanFolderId"] =
                    selectedFolder?.id;
                  mealPlanArr?.push(selectedMealPlanUpdate);
                  folder.mealPlans = mealPlanArr;
                } else {
                  let selectedMealPlanUpdate = { ...selectedMealPlan };
                  selectedMealPlanUpdate["mealPlanFolderId"] =
                    selectedFolder?.id;
                  const mealPlanArray = [selectedMealPlanUpdate];
                  folder["mealPlans"] = mealPlanArray;
                }
                return folder;
              }
              return folder;
            });
            setTempDataForTemplateFolders(newFolderData);
            return newFolderData;
          });
        }

        setNutritionData((prev) => {
          const oldData = [...tempData];
          const newData = oldData?.filter(
            (meal) => meal?.id !== selectedMealPlan?.id
          );
          setTempData(newData);
          return newData;
        });

        setOpenFolderId(selectedFolder?.id);
        setFolderSelected(selectedFolder);

        displayAlert({
          message: "Item Added To Folder successfully",
          type: "success",
        });
        setIsLoading(false);
        return true;
      }
    } catch (error) {
      console.log(error);
      displayAlert({
        message: "There is some error",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const removeItemFromTheFolder = async (selectedFolder, selectedMealPlan) => {
    setIsLoading(true);
    try {
      let data = {
        folderID: selectedFolder?.id,
        mealPlanIDs: [selectedMealPlan?.id],
      };

      const res = await removeItemFromFolder(data);

      if (res) {
        // this is for the my meal plan tab
        if (selectedTabInMeaLPlan == 0) {
          setFolders((prev) => {
            let oldData = [...tempDataForFolders];
            const newFolderData = oldData?.map((folder) => {
              if (folder?.id === selectedFolder?.id) {
                if (folder?.mealPlans) {
                  const mealPlanArr = folder.mealPlans;
                  const newMealPlanArr = mealPlanArr?.filter(
                    (meal) => meal?.id !== selectedMealPlan?.id
                  );
                  folder.mealPlans = newMealPlanArr;
                  return folder;
                }
                return folder;
              }
              return folder;
            });
            return newFolderData;
          });
          setTempDataForFolders((prev) => {
            let oldData = [...prev];
            const newFolderData = oldData?.map((folder) => {
              if (folder?.id === selectedFolder?.id) {
                if (folder?.mealPlans) {
                  const mealPlanArr = folder.mealPlans;
                  const newMealPlanArr = mealPlanArr?.filter(
                    (meal) => meal?.id !== selectedMealPlan?.id
                  );
                  folder.mealPlans = newMealPlanArr;
                  return folder;
                }
                return folder;
              }
              return folder;
            });
            return newFolderData;
          });
        } else if (selectedTabInMeaLPlan == 1) {
          setTemplateFolders((prev) => {
            let oldData = [...tempDataForTemplateFolders];
            const newFolderData = oldData?.map((folder) => {
              if (folder?.id === selectedFolder?.id) {
                if (folder?.mealPlans) {
                  const mealPlanArr = folder.mealPlans;
                  const newMealPlanArr = mealPlanArr?.filter(
                    (meal) => meal?.id !== selectedMealPlan?.id
                  );
                  folder.mealPlans = newMealPlanArr;
                  return folder;
                }
                return folder;
              }
              return folder;
            });
            return newFolderData;
          });
          setTempDataForTemplateFolders((prev) => {
            let oldData = [...prev];
            const newFolderData = oldData?.map((folder) => {
              if (folder?.id === selectedFolder?.id) {
                if (folder?.mealPlans) {
                  const mealPlanArr = folder.mealPlans;
                  const newMealPlanArr = mealPlanArr?.filter(
                    (meal) => meal?.id !== selectedMealPlan?.id
                  );
                  folder.mealPlans = newMealPlanArr;
                  return folder;
                }
                return folder;
              }
              return folder;
            });
            return newFolderData;
          });
        }

        setNutritionData((prev) => {
          const oldData = [...tempData];
          let selectedMealPlanUpdate = { ...selectedMealPlan };
          selectedMealPlanUpdate["mealPlanFolderId"] = null;
          oldData?.push(selectedMealPlanUpdate);
          setTempData(oldData);
          return oldData;
        });

        setdefaultNItem({});
        displayAlert({
          message: "Item Removed From Folder successfully",
          type: "success",
        });
        setIsLoading(false);
        return true;
      }
    } catch (error) {
      console.log(error);
      displayAlert({
        message: "There is some error",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const transferItemBetWeenTheFolder = async (
    sourceFolder,
    targetFolder,
    selectedMealPlan
  ) => {
    setIsLoading(true);
    try {
      let data = {
        sourceFolderID: sourceFolder?.id,
        targetFolderID: targetFolder?.id,
        mealPlanIDs: [selectedMealPlan?.id],
      };

      const res = await shiftItemBetweenFolder(data);

      if (res) {
        // this is for the my meal plan tab
        if (selectedTabInMeaLPlan == 0) {
          setFolders((prev) => {
            let oldData = [...tempDataForFolders];
            const newFolderData = oldData?.map((folder) => {
              if (folder?.id === sourceFolder?.id) {
                if (folder?.mealPlans) {
                  const mealPlanArr = folder.mealPlans;
                  const newMealPlanArr = mealPlanArr?.filter(
                    (meal) => meal?.id !== selectedMealPlan?.id
                  );
                  folder.mealPlans = newMealPlanArr;
                  return folder;
                }
                return folder;
              } else if (folder?.id === targetFolder?.id) {
                if (folder?.mealPlans) {
                  const mealPlanArr = folder.mealPlans;
                  mealPlanArr?.push(selectedMealPlan);
                  folder.mealPlans = mealPlanArr;
                } else {
                  folder["mealPlans"] = [selectedMealPlan];
                }
                return folder;
              }
              return folder;
            });
            setTempDataForFolders(newFolderData);
            return newFolderData;
          });
        } else if (selectedTabInMeaLPlan == 1) {
          setTemplateFolders((prev) => {
            let oldData = [...tempDataForTemplateFolders];
            const newFolderData = oldData?.map((folder) => {
              if (folder?.id === sourceFolder?.id) {
                if (folder?.mealPlans) {
                  const mealPlanArr = folder.mealPlans;
                  const newMealPlanArr = mealPlanArr?.filter(
                    (meal) => meal?.id !== selectedMealPlan?.id
                  );
                  folder.mealPlans = newMealPlanArr;
                  return folder;
                }
                return folder;
              } else if (folder?.id === targetFolder?.id) {
                if (folder?.mealPlans) {
                  const mealPlanArr = folder.mealPlans;
                  mealPlanArr?.push(selectedMealPlan);
                  folder.mealPlans = mealPlanArr;
                } else {
                  folder["mealPlans"] = [selectedMealPlan];
                }
                return folder;
              }
              return folder;
            });
            setTempDataForTemplateFolders(newFolderData);
            return newFolderData;
          });
        }

        displayAlert({
          message: "Item Transferd From Folder Is successfully",
          type: "success",
        });
        setIsLoading(false);
        return true;
      }
    } catch (error) {
      console.log(error);
      displayAlert({
        message: "There is some error",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <NutritionContext.Provider
      value={{
        folders,
        setFolders,
        createNewFolder,
        updateLocalFood,
        loadingSearchedItem,
        setLoadingSearchedItem,
        nutritionData,
        setNutritionData,
        defaultNItem,
        setdefaultNItem,
        foodData,
        setFoodData,
        deleteLocalFood,
        deleteRecipe,
        setSelectedFoodType,
        selectedFoodType,
        selectedRecipeType,
        setSelectedRecipeType,
        openFolderId,
        setOpenFolderId,
        getAllFolders,
        setFolderSelected,
        folderSelected,
        onFolderSelected,
        showEditMealInfoModal,
        setShowEditMealInfoModal,
        updateFolderDetails,
        deleteTheFolder,
        showDialogToFolderSelect,
        setShowDialogToFolderSelect,
        addItemToTheFolder,
        setSelectedTabInMeaLPlan,
        selectedTabInMeaLPlan,
        templateFolders,
        setTemplateFolders,
        setIsLoading,
        isLoading,
        isTemplate,
        setIsTemplate,
        openAddMealModal,
        setOpenAddMealModal,
        setSelectedMealItem,
        selectedMealItem,
        templateMealPlans,
        setTemplateMealPlans,
        myMealPlans,
        setMyMealPlans,
        id,
        selectedMealID,
        getMealPlans,
        tempData,
        setTempData,
        checkForUnsavedMealPlans,
        unsavedMealPlan,
        setUnsavedMealPlan,
        removeItemFromTheFolder,
        transferItemBetWeenTheFolder,
        isShifting,
        setIsShifting,
        itemToShift,
        setItemToShift,
        setTempDataForFolders,
        tempDataForFolders,
        setTempDataForTemplateFolders,
        tempDataForTemplateFolders,
        adminID,
        coachID,
      }}
    >
      {isLoading && (
        <div className="fixed top-0 left-0 w-full h-full bg-black-pure bg-opacity-50 z-[99999] flex items-center justify-center">
          <SpurfitCircularProgress />
        </div>
      )}
      {children}
    </NutritionContext.Provider>
  );
};
