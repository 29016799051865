import { Box } from "@mui/material";
import React, { createContext, useCallback, useEffect, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AddProperties from "../AddPropertiesDialog";
import TopBar from "../TopBar";
import WorkoutInfoDialog from "../WorkoutInfoDialog";
import { getUpdatedProperty } from "../helpers";
import EnduranceContentPage from "./EnduranceContentPage";
import AddEnduranceProperties from "./AddEnduranceProperties";
import { cn } from "@/lib/utils";
import { SpurfitCircularProgress } from "@/components/Dashboard/dashboard";

export const EnduranceContext = createContext();

const EnduranceBuilder = ({
  isBike,
  isSwim,
  isRun,
  isAssign,
  isProgramsFlow,
  comingFromModal,
  setWorkoutBuilderModal,
  setNewCheckedItems,
  isUpdate,
  data,
  setSelectedToShow,
  PropsData,
  setOpenForWorkoutBuilderModal,
  isFromClientView,
  reloadProfileForWorkoutBuilderModal,
  setIsOpenForWorKoutInfo,
  onSuccess,
  isCalendar,
  fromWorkout,
  onSuccessOfEditWokroutFromWorkout,
  creatFromWorkout,
  onSuccessOfNewWorkoutForm,
  ...props
}) => {
  console.log("WorkoutBuilder: endurance: propsData: ", PropsData);
  const [showWorkoutInfoDialog, setShowWorkoutInfoDialog] = useState(false);
  const location = useLocation();

  const [showAddPropertiesModal, setShowAddPropertiesModal] = useState(true);
  const [selectedExerciseInfo, setSelectedExerciseInfo] = useState({});

  const [allSelectedExercisesObj, setAllSelectedExercisesObj] = useState({});

  const [isDraggingSection, setIsDraggingSection] = useState(false);

  const [workoutData, setWorkoutData] = useState();

  const [isStructured, setIsStructured] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [reorderRound, setReorderRound] = useState(-1);

  const [workoutKind, setWorkoutKind] = useState(
    props?.workoutInfo?.workoutKind
  );

  const [selectedMenuItem, setSelectedMenuItem] = useState("Run");

  const [tempRound, setTempRound] = useState({});

  const [rounds, setRounds] = useState(props?.workoutContent?.rounds ?? []);

  const [workoutInfo, setWorkoutInfo] = useState({
    name: "",
    description: "",
  });

  const addPropertiesRef = React.useRef();
  const addPropertiesWarmUpRef = React.useRef();

  const exercisesObj = useSelector((state) => state.data.exerciseObject);

  const updateExerciseProperty = useCallback(
    (roundIndex, exerciseIndex, property, value) => {
      setRounds((prevRounds) => {
        const updatedRounds = [...prevRounds];
        if (
          updatedRounds?.[roundIndex]?.round?.measurements?.[exerciseIndex]
            ?.measurement
        ) {
          updatedRounds[roundIndex].round.measurements[
            exerciseIndex
          ].measurement[property] = value;
        }

        return updatedRounds;
      });
    },
    [setRounds]
  );

  const incrementRound = useCallback(
    (roundIndex) => {
      setRounds((prevRounds) => {
        const updatedRounds = [...prevRounds];
        updatedRounds[roundIndex].round.numRounds =
          +updatedRounds[roundIndex].round.numRounds + 1;
        return updatedRounds;
      });
    },
    [setRounds]
  );

  const incrementWarmUpRoundForAnExercise = useCallback(
    (roundIndex, exerciseIndex) => {
      setRounds((prevRounds) => {
        const updatedRounds = [...prevRounds];
        let numRounds =
          updatedRounds[roundIndex].round.measurements[exerciseIndex]
            .measurement.numRounds || 1;

        updatedRounds[roundIndex].round.measurements[
          exerciseIndex
        ].measurement.numRounds = numRounds + 1;
        return updatedRounds;
      });
    },
    [setRounds]
  );

  const decrementWarmUpRoundForAnExercise = useCallback(
    (roundIndex, exerciseIndex) => {
      setRounds((prevRounds) => {
        const updatedRounds = [...prevRounds];
        updatedRounds[roundIndex].round.measurements[
          exerciseIndex
        ].measurement.numRounds -= 1;
        return updatedRounds;
      });
    },
    [setRounds]
  );

  const decrementRound = useCallback(
    (roundIndex) => {
      setRounds((prevRounds) => {
        const updatedRounds = [...prevRounds];
        updatedRounds[roundIndex].round.numRounds -= 1;
        return updatedRounds;
      });
    },
    [setRounds]
  );

  const removeOneProperty = useCallback(
    (roundIndex, exerciseIndex, property) => {
      setRounds((prevRounds) => {
        const updatedRounds = [...prevRounds];
        if (
          updatedRounds?.[roundIndex]?.round?.measurements?.[exerciseIndex]
            ?.measurement?.[property]
        ) {
          delete updatedRounds[roundIndex].round.measurements[exerciseIndex]
            .measurement[property];
        }
        if (
          updatedRounds?.[roundIndex]?.round?.measurements?.[exerciseIndex]
            ?.measurement?.[`${property}F`]
        ) {
          delete updatedRounds[roundIndex].round.measurements[exerciseIndex]
            .measurement[`${property}F`];
        }
        if (
          updatedRounds?.[roundIndex]?.round?.measurements?.[exerciseIndex]
            ?.measurement?.[`${property}Units`]
        ) {
          delete updatedRounds[roundIndex].round.measurements[exerciseIndex]
            .measurement[`${property}Units`];
        }

        return updatedRounds;
      });
    },
    [setRounds]
  );

  const setExercise = (exercise) => {
    setSelectedExerciseInfo(exercise);
  };

  const setMenuItem = (item) => {
    setSelectedMenuItem(item);
  };

  function removeParticularExercise(roundIndex, exerciseIndex, exerciseId) {
    setAllSelectedExercisesObj((prevObj) => {
      const updatedObj = { ...prevObj };
      updatedObj[exerciseId] = updatedObj[exerciseId]?.filter(
        (round) => round !== roundIndex
      );
      return updatedObj;
    });

    let newMeasurements = [];
    rounds?.[roundIndex]?.round?.measurements?.forEach((exercise, index) => {
      if (index !== exerciseIndex) {
        newMeasurements.push(exercise);
      }
    });

    setRounds((prevRounds) => {
      const updatedRounds = [...prevRounds];
      updatedRounds[roundIndex].round.measurements = newMeasurements;
      return updatedRounds;
    });
    if (
      selectedExerciseInfo?.exerciseIndex === exerciseIndex &&
      selectedExerciseInfo?.roundIndex === roundIndex
    ) {
      setSelectedExerciseInfo({});
    }
  }

  const onSelectedExerciseChange = () => {
    // if selected exercise is warm up then open warm up properties dialog
    if (
      rounds?.[selectedExerciseInfo?.roundIndex]?.round?.type === "Warm Up" ||
      rounds?.[selectedExerciseInfo?.roundIndex]?.round?.type === "Mixed Sets"
    ) {
      addPropertiesWarmUpRef.current?.saveData();
    } else {
      addPropertiesRef.current?.saveData();
    }
  };

  useEffect(() => {
    let data = location?.state?.state?.data;

    if (data) {
      setWorkoutData(data);
      let workoutContent = data?.workoutContent;

      setWorkoutInfo({
        ...data,
        workoutContent: undefined,
      });
      let rounds = workoutContent?.rounds || [];
      rounds = JSON.parse(JSON.stringify(rounds));
      setRounds(rounds);
    }

    let workoutInfo = location?.state?.workoutInfo || props?.workoutInfo;
    if (workoutInfo) {
      setWorkoutInfo(workoutInfo);
    }

    let isStructured =
      location?.state?.isStructured ?? location?.state?.state?.isStructured;

    if (isStructured === "false" || isStructured === false) {
      setIsStructured(false);
    } else {
      setIsStructured(true);
    }
  }, [location]);

  useEffect(() => {
    if (props?.workoutContent?.rounds?.length > 0) {
      setRounds(props?.workoutContent?.rounds);
    }
  }, [props?.workoutContent?.rounds]);

  useEffect(() => {
    getDataFromLocalStorage();
  }, [workoutInfo]);

  const updateExerciseToParticularRound = (roundIndex, exerciseIndex, e) => {
    let exercise = getExercisFormatted(e);
    setRounds((prevRounds) => {
      const updatedRounds = [...prevRounds];
      updatedRounds[roundIndex].round.measurements[exerciseIndex] = exercise;

      return updatedRounds;
    });
    setAllSelectedExercisesObj((prevObj) => {
      const updatedObj = { ...prevObj };
      updatedObj[exercise?.measurement?.movementID] =
        updatedObj[exercise?.measurement?.movementID] || [];
      if (!updatedObj[exercise?.measurement?.movementID].includes(roundIndex)) {
        updatedObj[exercise?.measurement?.movementID].push(roundIndex);
      }
      return updatedObj;
    });
  };

  useEffect(() => {
    if (rounds?.[0]?.round?.measurements?.[0]?.measurement) {
      setSelectedExerciseInfo({
        roundIndex: 0,
        exerciseIndex: 0,
        exercise: rounds[0]?.round?.measurements?.[0]?.measurement,
        exerciseId:
          rounds[0]?.round?.measurements?.[0]?.measurement?.movementID,
      });
    } else {
      setSelectedExerciseInfo({});
    }
  }, [
    rounds?.[0]?.round?.measurements?.[0]?.measurement != undefined &&
      Object.entries(selectedExerciseInfo).length === 0,
  ]);

  const history = useHistory();

  useEffect(() => {
    const handleBeforeUnload = () => {
      if (isLoading) return;
      // if any changes are made then store them in local storage
      // two cases:
      // 1. new workout
      // 2. update workout

      let isWorkoutContentChanged = false;
      let isNewWorkout = false;
      if (location?.state?.state?.isUpdate || location?.state?.isUpdate) {
        // check if any changes are made in workout info or workout content
        let curWorkoutInfo =
          location?.state?.state?.data || location?.state?.workoutInfo;
        let workoutContent = location?.state?.state?.data?.workoutContent;

        if (workoutContent) {
          let prevRounds =
            location?.state?.state?.data?.workoutContent?.rounds ||
            location?.state?.workoutInfo?.workoutContent?.rounds;

          if (JSON.stringify(prevRounds) !== JSON.stringify(rounds)) {
            isWorkoutContentChanged = true;
          }
        }
      } else {
        isNewWorkout = true;
      }

      if (isNewWorkout || isWorkoutContentChanged) {
        let data = {
          ...location?.state?.state?.data,
          workoutKind: workoutInfo?.workoutKind,
          isBlockWorkoutBuilder: true,
          workoutContent: {
            rounds,
          },
        };

        if (workoutInfo.name && workoutInfo.name !== "") {
          data.name = workoutInfo.name;
        }
        if (workoutInfo.description && workoutInfo.description !== "") {
          data.description = workoutInfo.description;
        }
        localStorage.setItem("workoutData", JSON.stringify(data));
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    const unblock = history.block((location, action) => {
      handleBeforeUnload();
      return true;
    });

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      unblock();
    };
  }, [history, workoutInfo, rounds, isLoading]);

  const getDataFromLocalStorage = () => {
    let data = localStorage.getItem("workoutData");
    if (data && workoutInfo?.name) {
      data = JSON.parse(data);
      if (data?.name === workoutInfo?.name) {
        setRounds(data?.workoutContent?.rounds);
      }
    }
  };

  const getExercisFormatted = (exercise) => {
    let measurementInfo = {
      measurement: {
        movementName: exercise.name,
        reps: exercise.reps,
        repsMax: exercise.repsMax,
        weightMax: exercise.weightMax,
        heightMax: exercise.heightMax,
        distanceMax: exercise.distanceMax,
        calorieMax: exercise.calorieMax,
        repsF: exercise.repsF,
        height: exercise.height,
        heightF: exercise.heightF,
        heightUnits: exercise.heightUnits,
        weight: exercise.weight,
        weightF: exercise.weightF,
        weightUnits: exercise.weightUnits,
        distance: exercise.distance,
        distanceF: exercise.distanceF,
        distanceUnits: exercise.distanceUnits,
        duration: exercise.duration,
        durationF: exercise.durationF,
        calorie: exercise.calorie,
        calorieF: exercise.calorieF,
        isMaxLoad: exercise.isMaxLoad,
        isMaxReps: exercise.isMaxReps,
        movementID: exercise.id,
        recordFromUser: exercise?.recordFromUser,
        imageLink: exercise?.imageLink,
        // videoLink: exercise?.videoURL,
        movementInstr: exercise.movementInstructions || exercise?.movementInstr,
        break: exercise?.break,
      },
    };
    return measurementInfo;
  };

  const addExerciseToParticularRound = useCallback(
    (roundIndex, exerciseIndex, e) => {
      let exercise = getExercisFormatted(e);
      setRounds((prevRounds) => {
        const updatedRounds = [...prevRounds];
        updatedRounds[roundIndex].round.measurements.splice(
          exerciseIndex,
          0,
          exercise
        );

        return updatedRounds;
      });
      setAllSelectedExercisesObj((prevObj) => {
        const updatedObj = { ...prevObj };
        updatedObj[exercise?.measurement?.movementID] =
          updatedObj[exercise?.measurement?.movementID] || [];
        if (
          !updatedObj[exercise?.measurement?.movementID].includes(roundIndex)
        ) {
          updatedObj[exercise?.measurement?.movementID].push(roundIndex);
        }
        return updatedObj;
      });
    },
    [setRounds]
  );

  const setPoolLengthInAllRounds = (poolLength) => {
    setRounds((prevRounds) => {
      const updatedRounds = [...prevRounds];
      updatedRounds.forEach((round) => {
        round.round.poolLength = poolLength;
      });

      return updatedRounds;
    });
  };

  function removeParticularExercise(roundIndex, exerciseIndex, exerciseId) {
    setAllSelectedExercisesObj((prevObj) => {
      const updatedObj = { ...prevObj };
      updatedObj[exerciseId] = updatedObj[exerciseId]?.filter(
        (round) => round !== roundIndex
      );
      return updatedObj;
    });

    let newMeasurements = [];
    rounds?.[roundIndex]?.round?.measurements?.forEach((exercise, index) => {
      if (index !== exerciseIndex) {
        newMeasurements.push(exercise);
      }
    });

    setRounds((prevRounds) => {
      const updatedRounds = [...prevRounds];
      updatedRounds[roundIndex].round.measurements = newMeasurements;
      return updatedRounds;
    });
    if (
      selectedExerciseInfo?.exerciseIndex === exerciseIndex &&
      selectedExerciseInfo?.roundIndex === roundIndex
    ) {
      setSelectedExerciseInfo({});
    }
  }

  const onReorderDragEnd2 = (result) => {
    if (!result.destination) return;

    let newRound = { ...tempRound };
    let newMeasurements = [...newRound.measurements];
    let [removed] = newMeasurements.splice(result.source.index, 1);
    newMeasurements.splice(result.destination.index, 0, removed);
    newRound.measurements = newMeasurements;

    // also check if first or last movement is break then remove it
    if (newRound.measurements[0]?.measurement?.movementName === "BREAK") {
      newRound.measurements.splice(0, 1);
    }
    if (
      newRound.measurements[newRound.measurements.length - 1]?.measurement
        ?.movementName === "BREAK"
    ) {
      newRound.measurements.splice(newRound.measurements.length - 1, 1);
    }
    // if there is consecutive break then remove one
    newRound.measurements.forEach((m, index) => {
      let measurement = m.measurement;
      if (measurement.movementName === "BREAK") {
        let nextMeasurement = newRound.measurements[index + 1]?.measurement;

        if (nextMeasurement?.movementName === "BREAK") {
          newRound.measurements.splice(index + 1, 1);
        }
      }
    });

    setTempRound(newRound);
  };

  const onDropInDifferentSection = (result) => {
    if (!result.destination) return;

    // destination section index
    let destinationSectionIndex = +result.destination.droppableId.split("-")[1];

    let newRound = { ...tempRound };
    let newMeasurements = [...newRound.measurements];
    let [removed] = newMeasurements.splice(result.source.index, 1);
    newRound.measurements = newMeasurements;

    // set removed exercise to destination section
    let destinationSection = rounds[destinationSectionIndex]?.round;
    destinationSection.measurements.splice(
      result.destination.index,
      0,
      removed
    );

    // also check if first or last movement is break then remove it
    if (newRound.measurements[0]?.measurement?.movementName === "BREAK") {
      newRound.measurements.splice(0, 1);
    }
    if (
      newRound.measurements[newRound.measurements.length - 1]?.measurement
        ?.movementName === "BREAK"
    ) {
      newRound.measurements.splice(newRound.measurements.length - 1, 1);
    }

    // if there is consecutive break then remove one
    newRound.measurements.forEach((m, index) => {
      let measurement = m.measurement;
      if (measurement.movementName === "BREAK") {
        let nextMeasurement = newRound.measurements[index + 1]?.measurement;
      }
    });

    setRounds((prevRounds) => {
      const updatedRounds = [...prevRounds];
      updatedRounds[destinationSectionIndex].round = destinationSection;
      updatedRounds[reorderRound].round = newRound;
      return updatedRounds;
    });

    setTempRound(newRound);
  };

  return (
    <>
      {isLoading && (
        <div
          style={{
            height: "100vh",
            width: "100vw",
            backgroundColor: "rgba(0,0,0,0.3)",
            borderRadius: "0px 0px 16px 16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 1000,
          }}
        >
          <SpurfitCircularProgress />
        </div>
      )}

      <div className={cn(comingFromModal && "pt-[80px]", "bg-[#f7f7f7]")}>
        <DragDropContext
          onDragEnd={(result) => {
            // if draggable id contains rounds then it's a section getting dragged
            if (result?.draggableId?.includes("round")) {
              let newRounds = [...rounds];
              let [removed] = newRounds.splice(result.source.index, 1);
              newRounds.splice(result.destination.index, 0, removed);
              setRounds(newRounds);
              setIsDraggingSection(false);
              return;
            }

            if (
              result.source.droppableId?.includes("section") &&
              result.destination?.droppableId?.includes("section")
            ) {
              if (
                result.destination?.droppableId === result.source?.droppableId
              ) {
                onReorderDragEnd2(result);
              }
              return;
            }
          }}
        >
          <EnduranceContext.Provider
            value={{
              rounds,
              setShowAddPropertiesModal,
              removeOneProperty,
              selectedExerciseInfo,
              allSelectedExercisesObj,
              setAllSelectedExercisesObj,
              removeParticularExercise,
              incrementRound,
              decrementRound,
              tempRound,
              setTempRound,
              reorderRound,
              setReorderRound,
              isProgramsFlow: isProgramsFlow,
              onSelectedExerciseChange,
              isDraggingSection,
              setIsDraggingSection,
              workoutKind: props?.workoutInfo?.workoutKind,
            }}
          >
            {props?.workoutInfo?.workoutKind && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "30px 70px",
                }}
              >
                <TopBar
                  data={data}
                  workoutInfo={workoutInfo}
                  setWorkoutInfo={setWorkoutInfo}
                  rounds={rounds}
                  isUpdate={location?.state?.state?.isUpdate || isUpdate}
                  isAssign={location?.state?.state?.isAssign ?? isAssign}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  setShowWorkoutInfoDialog={setShowWorkoutInfoDialog}
                  isStructured={true}
                  isEnduranceWorkout={true}
                  workoutKind={workoutInfo?.workoutKind}
                  comingFromModal={comingFromModal}
                  setWorkoutBuilderModal={setWorkoutBuilderModal}
                  setNewCheckedItems={setNewCheckedItems}
                  setSelectedToShow={setSelectedToShow}
                  isCalendar={isCalendar}
                  PropsData={PropsData} //this is when you are reaching this page from clientsView
                  setOpenForWorkoutBuilderModal={setOpenForWorkoutBuilderModal}
                  isFromClientView={isFromClientView}
                  reloadProfileForWorkoutBuilderModal={
                    reloadProfileForWorkoutBuilderModal
                  }
                  setIsOpenForWorKoutInfo={setIsOpenForWorKoutInfo}
                  onSuccess={onSuccess}
                  onSelectedExerciseChange={onSelectedExerciseChange}
                  fromWorkout={fromWorkout}
                  onSuccessOfEditWokroutFromWorkout={
                    onSuccessOfEditWokroutFromWorkout
                  }
                  creatFromWorkout={creatFromWorkout}
                  onSuccessOfNewWorkoutForm={onSuccessOfNewWorkoutForm}
                />
                <EnduranceContentPage
                  {...{
                    workoutInfo,
                    rounds,
                    setRounds,
                    // roundIndex,
                    selectedExerciseInfo,
                    setSelectedExerciseInfo,
                    selectedMenuItem,
                    setSelectedMenuItem,
                    setExercise,
                    workoutKind: workoutInfo?.workoutKind,
                  }}
                />

                {/* substeps */}
                <Box
                  sx={{
                    marginLeft: "20px",
                  }}
                >
                  {showAddPropertiesModal && (
                    <>
                      <AddEnduranceProperties
                        isEnduranceWorkout={true}
                        ref={addPropertiesRef}
                        workoutKind={workoutInfo?.workoutKind}
                        setPoolLengthInAllRounds={setPoolLengthInAllRounds}
                        poolLength={
                          rounds?.[selectedExerciseInfo?.roundIndex]?.round
                            ?.poolLength
                        }
                        key={
                          selectedExerciseInfo?.exerciseId +
                          "addProperties" +
                          selectedExerciseInfo?.roundIndex +
                          "addProperties" +
                          selectedExerciseInfo?.exerciseIndex
                        }
                        open={showAddPropertiesModal}
                        setOpen={setShowAddPropertiesModal}
                        exercise={
                          rounds[selectedExerciseInfo.roundIndex]?.round
                            ?.measurements?.[selectedExerciseInfo.exerciseIndex]
                            ?.measurement
                        }
                        updateExerciseProperty={updateExerciseProperty}
                        roundIndex={selectedExerciseInfo.roundIndex}
                        index={selectedExerciseInfo.exerciseIndex}
                        rounds={
                          rounds?.[selectedExerciseInfo.roundIndex]?.round
                            ?.numRounds
                        }
                        incrementRound={() =>
                          incrementRound(selectedExerciseInfo.roundIndex)
                        }
                        decrementRound={() =>
                          decrementRound(selectedExerciseInfo.roundIndex)
                        }
                        getUpdatedProperty={getUpdatedProperty}
                        selectedExerciseInfo={selectedExerciseInfo}
                        updateExerciseToParticularRound={
                          updateExerciseToParticularRound
                        }
                        setMenuItem={setMenuItem}
                        selectedMenuItem={selectedMenuItem}
                      />
                    </>
                  )}
                </Box>
              </div>
            )}
            {showWorkoutInfoDialog && (
              <WorkoutInfoDialog
                workoutInfo={workoutInfo}
                setWorkoutInfo={setWorkoutInfo}
                isOpen={showWorkoutInfoDialog}
                setIsOpen={setShowWorkoutInfoDialog}
                isEdit={true}
                isUpdate={true}
              />
            )}
          </EnduranceContext.Provider>
        </DragDropContext>
      </div>
    </>
  );
};

export default EnduranceBuilder;
