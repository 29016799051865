import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
} from "@/components/ui/dialog";

import React, { useContext, useState } from "react";
import { workoutContext } from "./WorkoutContex";
import { Files, MoreVertical } from "lucide-react";
import Delete from "../../assets/workouts/DeleteIcon.svg";

import EditProgramInfo from "../Programs/EditProgramInfo";
import DropDownMenu from "../Programs/DropDownMenu";
import EmptyProgramImg from "@/assets/library/NoProgram.png";
import EditFolderInfo from "./EditFolderInfo";
import SelectFolderDialogInWorkout from "./SelectFolderDialogInWorkout";
import MenuOptions from "./MenuOptions";

function FolderInfo(props) {
  const { folderSelected, folderDelete } = useContext(workoutContext);
  const [ShowConfirmationDialoge, setShowConfirmationDialoge] = useState(false);
  const [showAddWorkoutToFolder, setShowAddWorkoutToFolder] = useState(false);

  function onDiscard() {
    setShowConfirmationDialoge(false);
  }

  function onContinue() {
    setShowConfirmationDialoge(false);
    folderDelete(folderSelected?.id);
  }
  return (
    <>
      <div className="w-full ">
        <div className="w-full h-[52px] flex items-center justify-between px-3 border-b">
          <div className="flex items-center gap-3">
            <p className="font-DMSans text-font16">{folderSelected?.name}</p>

            <EditFolderInfo
              selectedProgramData={folderSelected}
              isFolder={true}
              key={folderSelected?.id}
            />
          </div>

          <div>
            <MenuOptions
              isHoriz={false}
              className={"mr-3"}
              options={["Delete Folder"]}
              optionIcons={[<img src={Delete} alt="Delete" />]}
              menuItemStyles={[{ color: "#FF3A29" }]}
              onOptionClick={(option) => {
                if (option === "Delete Folder") {
                  if (folderSelected?.workouts?.length > 0) {
                    setShowConfirmationDialoge(true);
                    return;
                  }
                  folderDelete(folderSelected?.id);
                }
              }}
              iconBtnStyles={{
                borderRadius: "8px",
              }}
            />
         
          </div>
        </div>

        <div className="w-full flex justify-between w-[100%] gap-3 px-3 py-8 border-b-[1px] border-[#e2e8f0]  ">
          <div className="flex flex-col w-[50%]">
            <div className="program-conatiner-body-details-card w-full max-h-[83px]">
              <div>
                <p className="program-conatiner-body-details-card-title">
                  Total Files
                </p>
                <p className="program-conatiner-body-details-card-description">
                  {folderSelected?.workouts?.length || 0}
                </p>
              </div>
              <Files className="h-[30px] w-[30px]" />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-3 items-center justify-center mt-[7rem]">
          {/* <Rows3 className="h-[90px] w-[90px] text-[#5e42ea]" /> */}
          <img src={EmptyProgramImg} className="w-[100px] h-[100px]" alt="" />
          {folderSelected?.workouts?.length > 0 ? (
            <p
              className="text-gradient"
              style={{
                fontSize: "1.2rem",
                fontWeight: "600",
              }}
            >
              Select Any Workout To View
            </p>
          ) : (
            <>
              <p
                className="text-gradient"
                style={{
                  fontSize: "1.2rem",
                  fontWeight: "600",
                }}
              >
                Folder Is Empty Add Workouts To The Folder
              </p>
              <button
                style={{
                  marginTop: "16px",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                className="button-new w-[170px]"
                onClick={() => setShowAddWorkoutToFolder(true)}
              >
                Add Workout
              </button>
            </>
          )}
        </div>
      </div>
      <ConfirmDialog
        isOpen={ShowConfirmationDialoge}
        onContinue={onContinue}
        onDiscard={onDiscard}
      />

      {showAddWorkoutToFolder && (
        <SelectFolderDialogInWorkout
          open={showAddWorkoutToFolder}
          setOpen={setShowAddWorkoutToFolder}
          selectedProgram={folderSelected}
          addWorkoutToFolder={true}
        />
      )}
    </>
  );
}

export default FolderInfo;

const ConfirmDialog = ({ isOpen, onDiscard, onSave, onContinue }) => {
  return (
    <Dialog open={isOpen}>
      <DialogPortal>
        <DialogOverlay className="bg-black-pure bg-opacity-40 z-[9999]" />
      </DialogPortal>
      <DialogContent
        hideClose={true}
        className="sm:max-w-md z-[9999] font-DMSans outline-none"
      >
        <DialogHeader>
          <DialogTitle className="font-DMSans text-font18">
            Delete Alert
          </DialogTitle>
        </DialogHeader>

        <div className="">
          <p className="font-DMSans text-font14">
            This folder contains Workouts. Are you sure you want to delete it?
          </p>
        </div>
        <DialogFooter className={"flex w-full items-center gap-3"}>
          <DialogClose>
            <p
              className="font-DMSans cursor-pointer text-[#ED6C02] text-font14"
              onClick={onDiscard}
            >
              Discard
            </p>
          </DialogClose>

          <DialogClose>
            <button
              className="button-new text-font14 h-[38px] w-[100px]"
              onClick={onContinue}
            >
              Continue
            </button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
