import React, { useState } from "react";
import { Metric, MetricLast, MetricSmall } from "../../GarminWorkoutCard";

import NotesIcon from "@/assets/garmin/Notes.svg";
import HeartIcon from "@/assets/garmin/Heart.svg";
import RefreshIcon from "@/assets/garmin/Refresh.svg";
import TimeIcon from "@/assets/garmin/Time.svg";
import EnergyIcon from "@/assets/garmin/Energy.svg";
import { cn } from "@/lib/utils";
import { convertMinPerKmToSecPerMeter } from "../utils";

const WorkoutMatrix = ({
  data,
  fitData,
  onLapSelect,
  selectedLap,
  computedMetrics,
  onPeakSelect,
}) => {
  const [selectedIcon, setSelectedIcon] = useState(0);
  const [selectedAll, setSelectedAll] = useState({});

  const [selectedLapIndices, setSelectedLapIndices] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState("Laps");

  const [selectedEntries, setSelectedEntries] = useState([]);

  // const iconsArray = [NotesIcon, HeartIcon, RefreshIcon, TimeIcon, EnergyIcon];

  const iconsArray = [
    {
      icon: NotesIcon,
      label: "Laps",
    },
    {
      icon: HeartIcon,
      label: "Peak Heart Rate",
    },
    {
      icon: RefreshIcon,
      label: "Peak Cadence",
    },
    {
      icon: TimeIcon,
      label: "Peak Pace",
    },
    // {
    //   icon: EnergyIcon,
    //   label: "Peak Power",
    // },
    {
      icon: "https://img.icons8.com/material-outlined/24/ruler.png",
      label: "Peak Pace By Distance",
    },
  ];

  const laps = fitData?.laps;
  const isRun = data?.activityType === "RUNNING";
  let effortLevel = computedMetrics?.effort_level;
  let fatigueLevel = computedMetrics?.fatigue_level;

  let ef = computedMetrics?.front_block_metrics?.ef_metric;
  let ngp = computedMetrics?.front_block_metrics?.ngp; // min/km
  let pahr = computedMetrics?.front_block_metrics?.pa_hr; // %
  let elgain = computedMetrics?.front_block_metrics?.elev_gain; // m
  let work = computedMetrics?.front_block_metrics?.work_kj; // kj
  let vi = computedMetrics?.front_block_metrics?.vi_pace; // %

  let minstats = computedMetrics?.min_stats;

  const isAllMetricsUnavailable =
    !ef && !ngp && !pahr && !elgain && !work && !vi;
  return (
    <div>
      <div className="border-b p-5">
        <h2 className="font-DMSans text-font16 font-bold">Entire Workout</h2>
      </div>
      <div className="p-5">
        <div className="flex items-center justify-evenly">
          <Metric
            label={"Duration"}
            value={formatTime(data?.durationInSeconds)}
            key={"duration"}
          />
          <Metric
            label={"Distance"}
            value={formatDistance(data?.distanceInMeters)}
            unit={"km"}
            key={"distance"}
          />

          {fatigueLevel && (
            <Metric
              label={"Fatigue"}
              value={fatigueLevel.toFixed(2)}
              key={"fatigue"}
            />
          )}
          {effortLevel && (
            <Metric
              label={"Effort"}
              value={Math.round(effortLevel)}
              key={"effort"}
            />
          )}
        </div>
        {!isAllMetricsUnavailable && (
          <div className="mt-5 border-t border-b py-4 flex  justify-center">
            <div>
              <MetricSmall
                label={"Work"}
                value={work ? work / 100 : ""}
                unit={"Kj"}
              />
              <MetricSmall
                label={"Ngp"}
                value={convertMinPerKmToSecPerMeter(ngp)}
                unit={"sec/m"}
              />
              <MetricSmall
                label={"Pa:Hr"}
                value={pahr ? pahr * 100 : ""}
                unit={"%"}
              />
            </div>
            <div>
              <MetricSmall label={"El.Gain"} value={elgain} unit={"m"} />
              {/* <MetricSmall label={"IF"} value={0.75} unit={""} /> */}
              <MetricSmall label={"VI"} value={vi} unit={""} />
              <MetricSmall label={"EF"} value={ef} unit={""} />
            </div>
            <div>
              {/* <MetricSmall label={"EF"} value={1.05} unit={""} /> */}
            </div>
          </div>
        )}
        <div className="my-4 border rounded-xl mx-3 flex flex-col items-center px-1">
          <div>
            <div className="flex items-center gap-4">
              <p className="font-DMSans text-[11px] font-light w-[60px] text-right text-[#424242]">
                {" "}
              </p>
              <div className="h-[20px] w-[1px] bg-[#E5E5E5]"></div>
              {/* <p className="font-DMSans w-[40px] text-center text-[11px] font-light text-[#424242]">
                {"MIN"}
              </p> */}

              <p className="font-DMSans w-[40px] text-center text-[11px] font-light text-[#424242]">
                {"AVG"}
              </p>

              <p className="font-DMSans w-[40px] text-center text-[11px] font-light text-[#424242]">
                {"MAX"}
              </p>
              <div className="h-[20px] w-[1px] bg-[#E5E5E5]"></div>
              <p className="font-DMSans text-[11px] font-light text-[#424242]">
                {""}
              </p>
            </div>
            {/* <MetricLast
              label={"Power"}
              min={minstats?.min_power}
              avg={115}
              max={280}
              unit={"W"}
              key={"power"}
            /> */}
            <MetricLast
              label={"Heart Rate"}
              min={minstats?.min_heart_rate?.toFixed(2)}
              avg={data?.averageHeartRateInBeatsPerMinute ?? "-"}
              max={data?.maxHeartRateInBeatsPerMinute ?? "-"}
              unit={"bpm"}
              key={"hr"}
            />

            <MetricLast
              label={"Cadence"}
              min={minstats?.min_cadence}
              avg={
                data?.averageRunCadenceInStepsPerMinute
                  ? Math.round(data?.averageRunCadenceInStepsPerMinute)
                  : "-"
              }
              max={data?.maxRunCadenceInStepsPerMinute ?? "-"}
              unit={"rpm"}
              key={"cadence"}
            />

            <MetricLast
              label={"Speed"}
              min={minstats?.min_speed?.toFixed(2)}
              avg={
                data?.averageSpeedInMetersPerSecond
                  ? convertMPSToKPH(data?.averageSpeedInMetersPerSecond)
                  : "-"
              }
              max={
                data?.maxSpeedInMetersPerSecond
                  ? convertMPSToKPH(data?.maxSpeedInMetersPerSecond)
                  : "-"
              }
              unit={"kmph"}
              key={"speed"}
            />

            <MetricLast
              label={"Pace"}
              min={formatTimeDynamically(minstats?.min_pace)}
              avg={
                data?.averagePaceInMinutesPerKilometer
                  ? formatTimeDynamically(
                      data?.averagePaceInMinutesPerKilometer
                    )
                  : "-"
              }
              max={
                data?.maxPaceInMinutesPerKilometer
                  ? formatTimeDynamically(data?.maxPaceInMinutesPerKilometer)
                  : "-"
              }
              unit={"min/km"}
              key={"pace"}
            />
          </div>
        </div>
      </div>
      <div className="border-t p-5">
        <div className="flex items-center justify-between bg-[#F8F8F8] rounded-full">
          {iconsArray.map((item, index) => (
            <div
              onClick={() => {
                setSelectedIcon(index);
                setSelectedLabel(item?.label);
              }}
              key={index}
              className={cn(
                "flex items-center p-2 gap-4 cursor-pointer  px-[16px]",
                selectedIcon === index && "bg-[#6360F9] rounded-full"
              )}
            >
              <img
                src={item?.icon}
                height={24}
                width={24}
                alt="icon"
                className={
                  selectedIcon === index ? "filter brightness-0 invert" : ""
                }
              />
            </div>
          ))}
        </div>

        <div>
          <p className="font-DMSans text-font16 font-bold text-black-pure text-center mt-2">
            {selectedLabel}
          </p>

          {selectedLabel === "Laps" ? (
            <div className="border border-[#E5E5E5] rounded-lg mt-4">
              <div className="flex items-center border-b">
                <div className="px-2 py-1 mr-2 border-r border-[#E5E5E5]">
                  <input
                    onChange={() => {
                      if (selectedLapIndices?.includes(-1)) {
                        setSelectedLapIndices([]);
                        onLapSelect([]);
                      } else {
                        let updatedIndices = [-1];
                        // add all laps
                        for (let i = 0; i < laps.length; i++) {
                          updatedIndices.push(i);
                        }
                        setSelectedLapIndices(updatedIndices);
                        onLapSelect(updatedIndices);
                      }
                    }}
                    type="checkbox"
                    className="h-4 w-4 "
                    checked={selectedLapIndices?.includes(-1)}
                  />
                </div>
                <div className="flex flex-1 justify-between">
                  <p className="font-DMSans text-[11px] text-[#424242]">
                    Entire Workout
                  </p>
                  <p className="font-DMSans text-[11px] text-[#424242] pr-2">
                    {formatTime(data?.durationInSeconds)}
                  </p>
                </div>
              </div>

              {laps.map((lap, index) => (
                <OneLap
                  key={index}
                  lap={lap}
                  number={index + 1}
                  selected={selectedLapIndices?.includes(index)}
                  onLapSelect={() => {
                    if (selectedLapIndices?.includes(index)) {
                      let updatedIndices = selectedLapIndices.filter(
                        (item) => item !== index
                      );
                      setSelectedLapIndices(updatedIndices);
                      onLapSelect(updatedIndices);
                    } else {
                      let updatedIndices = [...selectedLapIndices, index];
                      setSelectedLapIndices(updatedIndices);
                      onLapSelect(updatedIndices);
                    }
                  }}
                />
              ))}
            </div>
          ) : (
            <div className="border border-[#E5E5E5] rounded-lg mt-4">
              <Header
                value={
                  selectedLabel === "Peak Pace By Distance"
                    ? formatDistance(data?.distanceInMeters) + " min/km"
                    : selectedLabel === "Peak Pace"
                    ? formatToMinutes(data?.maxPaceInMinutesPerKilometer * 60) +
                      " min/km"
                    : selectedLabel === "Peak Heart Rate"
                    ? data?.maxHeartRateInBeatsPerMinute + " bpm"
                    : selectedLabel === "Peak Cadence"
                    ? data?.maxRunCadenceInStepsPerMinute + " rpm"
                    : selectedLabel === "Peak Power"
                    ? data?.maxPowerInWatts + " W"
                    : ""
                }
                onSelect={() => {}}
                selected={selectedAll?.[selectedLabel]}
                setSelectedAll={(value) => {
                  if (value === -1 && selectedAll?.[selectedLabel]) {
                    setSelectedAll({
                      ...selectedAll,
                      [selectedLabel]: false,
                    });
                    onPeakSelect(selectedLabel, []);
                    setSelectedEntries([]);
                  } else if (value == -1) {
                    setSelectedAll({
                      ...selectedAll,
                      [selectedLabel]: true,
                    });
                    let totalValues = Object.values(
                      computedMetrics?.[keyValuePairs[selectedLabel]] ?? {}
                    )?.map((item) => {
                      return [item.start_index, item.end_index];
                    });

                    onPeakSelect(selectedLabel, totalValues);
                    setSelectedEntries(
                      Object.keys(
                        computedMetrics?.[keyValuePairs[selectedLabel]] ?? {}
                      )?.map((item) => selectedLabel + "_" + item)
                    );
                  }
                }}
              />
              {computedMetrics?.[keyValuePairs[selectedLabel]] &&
                Object.entries(
                  computedMetrics?.[keyValuePairs[selectedLabel]]
                ).map(([key, value], index) => {
                  let formattedValue = null;
                  let errorBoundValue = null;
                  if (!value || Object.entries(value).length == 0) {
                    errorBoundValue = "";
                    formattedValue = "--";
                  } else {
                    errorBoundValue = value?.max_avg;
                    formattedValue = value?.max_avg.toFixed(2);

                    if (selectedLabel === "Peak Pace") {
                      formattedValue = formatTimeDynamically(errorBoundValue);
                    } else if (selectedLabel === "Peak Power") {
                      formattedValue = errorBoundValue?.toFixed(2);
                    } else if (selectedLabel === "Peak Pace By Distance") {
                      formattedValue = formatTimeDynamically(errorBoundValue);
                    } else if (selectedLabel === "Peak Heart Rate") {
                      formattedValue = errorBoundValue?.toFixed(2);
                    } else if (selectedLabel === "Peak Cadence") {
                      formattedValue = (
                        errorBoundValue * (isRun ? 2 : 1)
                      ).toFixed(2);
                    }
                  }
                  let label = `Peak ${formatTimeInMinAndSec(key)}`;
                  if (selectedLabel === "Peak Pace By Distance") {
                    label = `Peak ${formatDistanceInMandKm(key)}`;
                  }

                  return (
                    <OnePeak
                      key={`${key}_${index}`}
                      label={label}
                      value={formattedValue}
                      selected={selectedEntries.includes(
                        selectedLabel + "_" + key
                      )}
                      onPeakSelect={() => {
                        let selected = [...selectedEntries];
                        let keyValue = selectedLabel + "_" + key;
                        if (selected.includes(keyValue)) {
                          selected = selected.filter(
                            (item) => item !== keyValue
                          );
                        } else {
                          selected.push(keyValue);
                        }

                        let totalValues = selected?.map((item) => {
                          let originalKey = item.split("_")[1];
                          let originalLabel = item.split("_")[0];

                          if (originalLabel !== selectedLabel) return null;
                          let cur =
                            computedMetrics?.[keyValuePairs[selectedLabel]][
                              originalKey
                            ];
                          return [cur.start_index, cur.end_index];
                        });

                        // remove all null values
                        totalValues = totalValues.filter(
                          (item) => item !== null
                        );
                        onPeakSelect(selectedLabel, totalValues);
                        setSelectedEntries(selected);
                      }}
                      unit={unitsMap[selectedLabel]}
                    />
                  );
                })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WorkoutMatrix;

const OnePeak = ({ value, label, selected, onPeakSelect, unit }) => {
  return (
    <div className="flex items-center ">
      <div className=" px-2 mr-2 border-r border-[#E5E5E5]">
        <input
          onChange={onPeakSelect}
          type="checkbox"
          className="h-4 w-4 "
          checked={selected}
        />
      </div>
      <div className="flex flex-1 justify-between">
        <p className="font-DMSans text-[12px]">{label}</p>
        <p className="font-DMSans text-[12px] pr-2">
          {value}{" "}
          <span className="font-DMSans text-[11px] font-light text-[#424242]">
            {" "}
            {unit ?? ""}
          </span>
        </p>
      </div>
    </div>
  );
};

const formatTimeDynamically = (minutesPerKilometer) => {
  if (!minutesPerKilometer) return null;
  let minutes = Math.floor(minutesPerKilometer);
  let seconds = Math.round((minutesPerKilometer - minutes) * 60);

  return `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;
};

const Header = ({ value, onSelect, selected, setSelectedAll }) => {
  if (typeof value === "string" && value?.includes("undefined")) return null;
  return (
    <div className="flex items-center border-b">
      <div className="px-2 py-1 mr-2 border-r border-[#E5E5E5]">
        <input
          onChange={() => {
            setSelectedAll(-1);
            onSelect(-1);
          }}
          type="checkbox"
          className="h-4 w-4 "
          checked={selected}
        />
      </div>
      <div className="flex flex-1 justify-between">
        <p className="font-DMSans text-[11px] text-[#424242]">Entire Workout</p>
        <p className="font-DMSans text-[11px] text-[#424242] pr-2">{value}</p>
      </div>
    </div>
  );
};

const OneLap = ({ lap, number, selected, onLapSelect }) => {
  return (
    <div className="flex items-center ">
      <div className=" px-2 mr-2 border-r border-[#E5E5E5]">
        <input
          onChange={onLapSelect}
          type="checkbox"
          className="h-4 w-4 "
          checked={selected}
        />
      </div>
      <div className="flex flex-1 justify-between">
        <p className="font-DMSans text-[12px]">Lap #{number}</p>
        <p className="font-DMSans text-[12px] pr-2">
          {formatTime(lap?.total_elapsed_time)}
        </p>
      </div>
    </div>
  );
};

const formatToMinutes = (seconds) => {
  if (!seconds) return null;
  let minutes = Math.floor(seconds / 60);
  let remainingSeconds = Math.round(seconds % 60);

  return `${minutes.toString().padStart(2, "0")}:${remainingSeconds}`;
};

const formatTime = (seconds) => {
  if (!seconds) return null;
  seconds = Math.round(seconds);
  let hours = Math.floor(seconds / 3600);
  let minutes = Math.floor((seconds % 3600) / 60);
  let remainingSeconds = seconds % 60;

  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
};
const formatDistance = (meters) => {
  if (!meters) return null;
  return (meters / 1000).toFixed(2);
};

const formatDistanceInMandKm = (meters) => {
  if (!meters) return null;
  let km = Math.floor(meters / 1000);
  let remainingMeters = meters % 1000;

  if (km === 0) return `${remainingMeters} m`;
  if (remainingMeters === 0) return `${km} km`;

  return `${meters} m`;
};

const formatTimeInMinAndSec = (seconds) => {
  if (!seconds) return null;
  seconds = Math.round(seconds);
  let minutes = Math.floor(seconds / 60);
  let remainingSeconds = seconds % 60;

  if (minutes === 0)
    return `${remainingSeconds.toString().padStart(2, "0")} sec`;

  return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
    .toString()
    .padStart(2, "0")} min`;
};

const keyValuePairs = {
  "Peak Heart Rate": "heart_rate_windowed_peak",
  "Peak Cadence": "cadence_windowed_peak",
  "Peak Pace": "pace_windowed_peak",
  "Peak Power": "power_windowed_peak",
  "Peak Pace By Distance": "cadence_distance_peak",
};

const unitsMap = {
  "Peak Heart Rate": "bpm",
  "Peak Cadence": "rpm",
  "Peak Pace": "min/km",
  "Peak Power": "W",
  "Peak Pace By Distance": "min/km",
};

const convertMPSToKPH = (mps) => {
  let val = mps * 3.6;
  return val.toFixed(2);
};
