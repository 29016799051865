import { FewShotPromptTemplate, PromptTemplate } from "langchain/prompts";
import { ChatOpenAI } from "langchain/chat_models/openai";
import { LLMChain } from "langchain/chains";
import { OPEN_API_KEY } from "../../APIDetails";

export const ScaleUpSingleWorkout = async () => {
  let examples = [
    {
      input: ` Scale up the following workout: 
    {{"Workout":{{"Part-1":{{"exercises":[{{"name":"Barbell Squats","equipment":"Barbell","reps":{{"set 1":10,"set 2":10,"set 3":8}},"weights":{{"set 1":135,"set 2":155,"set 3":175}},"units":{{"weights":"lbs"}}}},{{"name":"Romanian Deadlifts","reps":{{"set 1":12,"set 2":12,"set 3":10}},"weights":{{"set 1":95,"set 2":105,"set 3":115}},"units":{{"weights":"lbs"}}}},{{"name":"Bulgarian Split Squats","reps":{{"set 1":10,"set 2":10,"set 3":8}},"weights":{{"set 1":30,"set 2":35,"set 3":40}},"units":{{"weights":"lbs"}}}},{{"name":"Rest","duration":{{"set 1":60,"set 2":60,"set 3":60}},"units":{{"duration":"seconds"}}}}]}},"Part-2":{{"exercises":[{{"name":"Leg Press","reps":{{"set 1":12,"set 2":12,"set 3":10}},"weights":{{"set 1":200,"set 2":220,"set 3":240}},"units":{{"weights":"lbs"}}}},{{"name":"Hamstring Curls","reps":{{"set 1":10,"set 2":10,"set 3":8}},"weights":{{"set 1":80,"set 2":90,"set 3":100}},"units":{{"weights":"lbs"}}}},{{"name":"Calf Raises","reps":{{"set 1":15,"set 2":15,"set 3":12}},"weights":{{"set 1":100,"set 2":120,"set 3":140}},"units":{{"reps":"repetitions","weights":"lbs"}}}},{{"name":"Rest","duration":{{"set 1":60,"set 2":60,"set 3":60}},"units":{{"duration":"seconds"}}}}]}}}}}}`,
      output: `{{"Workout":{{"Part-1":{{"exercises":[{{"name":"Barbell Squats","equipment":"Barbell","reps":{{"set 1":10,"set 2":10,"set 3":8,"set 4":8,"set 5":6}},"weights":{{"set 1":135,"set 2":155,"set 3":175,"set 4":185,"set 5":205}},"units":{{"weights":"lbs"}}}},{{"name":"Romanian Deadlifts","reps":{{"set 1":12,"set 2":12,"set 3":10,"set 4":10,"set 5":8}},"weights":{{"set 1":95,"set 2":105,"set 3":115,"set 4":125,"set 5":135}},"units":{{"weights":"lbs"}}}},{{"name":"Bulgarian Split Squats","reps":{{"set 1":10,"set 2":10,"set 3":8,"set 4":8,"set 5":6}},"weights":{{"set 1":30,"set 2":35,"set 3":40,"set 4":45,"set 5":50}},"units":{{"weights":"lbs"}}}},{{"name":"Rest","duration":{{"set 1":60,"set 2":60,"set 3":60,"set 4":60,"set 5":60}},"units":{{"duration":"seconds"}}}}]}},"Part-2":{{"exercises":[{{"name":"Leg Press","reps":{{"set 1":12,"set 2":12,"set 3":10,"set 4":10,"set 5":8}},"weights":{{"set 1":200,"set 2":220,"set 3":240,"set 4":260,"set 5":280}},"units":{{"weights":"lbs"}}}},{{"name":"Hamstring Curls","reps":{{"set 1":10,"set 2":10,"set 3":8,"set 4":8,"set 5":6}},"weights":{{"set 1":80,"set 2":90,"set 3":100,"set 4":110,"set 5":120}},"units":{{"weights":"lbs"}}}},{{"name":"Calf Raises","reps":{{"set 1":15,"set 2":15,"set 3":12,"set 4":12,"set 5":10}},"weights":{{"set 1":100,"set 2":120,"set 3":140,"set 4":160,"set 5":180}},"units":{{"reps":"repetitions","weights":"lbs"}}}},{{"name":"Rest","duration":{{"set 1":60,"set 2":60,"set 3":60,"set 4":60,"set 5":60}},"units":{{"duration":"seconds"}}}}]}}}}}}`,
    },

    {
      input: ` Scale up the following workout: 
        {{"Workout":{{"Part-1":{{"exercises":[{{"name":"Barbell Bench Press","reps":{{"set 1":8,"set 2":8,"set 3":6}},"weights":{{"set 1":135,"set 2":155,"set 3":175}},"units":{{"weights":"lbs"}}}},{{"name":"Bent-Over Barbell Rows","reps":{{"set 1":10,"set 2":10,"set 3":8}},"weights":{{"set 1":135,"set 2":155,"set 3":175}},"units":{{"weights":"lbs"}}}},{{"name":"Dumbbell Lunges","reps":{{"set 1":12,"set 2":12,"set 3":10}},"weights":{{"set 1":20,"set 2":25,"set 3":30}},"units":{{"weights":"lbs"}}}},{{"name":"Rest","duration":{{"set 1":60,"set 2":60,"set 3":60}},"units":{{"duration":"seconds"}}}}]}},"Part-2":{{"exercises":[{{"name":"Standing Barbell Curl","reps":{{"set 1":8,"set 2":8,"set 3":6}},"weights":{{"set 1":65,"set 2":75,"set 3":85}},"units":{{"weights":"lbs"}}}},{{"name":"Triceps Pushdowns","reps":{{"set 1":10,"set 2":10,"set 3":8}},"weights":{{"set 1":50,"set 2":60,"set 3":70}},"units":{{"weights":"lbs"}}}},{{"name":"Leg Press","reps":{{"set 1":12,"set 2":12,"set 3":10}},"weights":{{"set 1":180,"set 2":200,"set 3":220}},"units":{{"weights":"lbs"}}}},{{"name":"Rest","duration":{{"set 1":60,"set 2":60,"set 3":60}},"units":{{"duration":"seconds"}}}}]}}}}}}`,
      output: `{{"Workout":{{"Part-1":{{"exercises":[{{"name":"Barbell Bench Press","reps":{{"set 1":8,"set 2":8,"set 3":6}},"weights":{{"set 1":185,"set 2":205,"set 3":225}},"units":{{"weights":"lbs"}}}},{{"name":"Bent-Over Barbell Rows","reps":{{"set 1":10,"set 2":10,"set 3":8}},"weights":{{"set 1":185,"set 2":205,"set 3":225}},"units":{{"weights":"lbs"}}}},{{"name":"Dumbbell Lunges","reps":{{"set 1":12,"set 2":12,"set 3":10}},"weights":{{"set 1":30,"set 2":35,"set 3":40}},"units":{{"weights":"lbs"}}}},{{"name":"Rest","duration":{{"set 1":60,"set 2":60,"set 3":60}},"units":{{"duration":"seconds"}}}}]}},"Part-2":{{"exercises":[{{"name":"Standing Barbell Curl","reps":{{"set 1":8,"set 2":8,"set 3":6}},"weights":{{"set 1":85,"set 2":95,"set 3":105}},"units":{{"weights":"lbs"}}}},{{"name":"Triceps Pushdowns","reps":{{"set 1":10,"set 2":10,"set 3":8}},"weights":{{"set 1":70,"set 2":80,"set 3":90}},"units":{{"weights":"lbs"}}}},{{"name":"Leg Press","reps":{{"set 1":12,"set 2":12,"set 3":10}},"weights":{{"set 1":230,"set 2":250,"set 3":270}},"units":{{"weights":"lbs"}}}},{{"name":"Rest","duration":{{"set 1":60,"set 2":60,"set 3":60}},"units":{{"duration":"seconds"}}}}]}}}}}}`,
    },

    {
      input: ` Scale up the following workout: 
    {{"Workout":{{"Part-1":{{"exercises":[{{"name":"Barbell Rows","reps":{{"set 1":10,"set 2":10,"set 3":8}},"weights":{{"set 1":50,"set 2":60,"set 3":70}},"units":{{"weights":"lbs"}}}},{{"name":"Wide-Grip Pull-Ups","reps":{{"set 1":8,"set 2":8,"set 3":6}}}},{{"name":"Seated Cable Rows","reps":{{"set 1":12,"set 2":12,"set 3":10}},"weights":{{"set 1":80,"set 2":90,"set 3":100}},"units":{{"weights":"lbs"}}}},{{"name":"Rest","duration":{{"set 1":60,"set 2":75,"set 3":90}},"units":{{"duration":"seconds"}}}}]}},"Part-2":{{"exercises":[{{"name":"Deadlifts","reps":{{"set 1":8,"set 2":8,"set 3":6,"set 4":6,"set 5":5}},"weights":{{"set 1":100,"set 2":120,"set 3":140,"set 4":160,"set 5":180}},"units":{{"weights":"lbs"}}}},{{"name":"Lat Pulldowns","reps":{{"set 1":10,"set 2":10,"set 3":8}},"weights":{{"set 1":80,"set 2":90,"set 3":100}},"units":{{"weights":"lbs"}}}},{{"name":"Dumbbell Rows","reps":{{"set 1":12,"set 2":12,"set 3":10}},"weights":{{"set 1":30,"set 2":35,"set 3":40}},"units":{{"weights":"lbs"}}}},{{"name":"Rest","duration":{{"set 1":60,"set 2":75,"set 3":90}},"units":{{"duration":"seconds"}}}}]}}}}}}`,
      output: `{{"Workout":{{"Part-1":{{"exercises":[{{"name":"Barbell Rows","reps":{{"set 1":10,"set 2":10,"set 3":8,"set 4":8}},"weights":{{"set 1":70,"set 2":80,"set 3":90,"set 4":100}},"units":{{"weights":"lbs"}}}},{{"name":"Weighted Pull-Ups","reps":{{"set 1":8,"set 2":8,"set 3":6,"set 4":6}},"weights":{{"set 1":20,"set 2":25,"set 3":30,"set 4":35}},"units":{{"weights":"lbs"}}}},{{"name":"Seated Cable Rows","reps":{{"set 1":12,"set 2":12,"set 3":10,"set 4":10}},"weights":{{"set 1":100,"set 2":110,"set 3":120,"set 4":130}},"units":{{"weights":"lbs"}}}},{{"name":"Rest","duration":{{"set 1":60,"set 2":75,"set 3":90,"set 4":105}},"units":{{"duration":"seconds"}}}}]}},"Part-2":{{"exercises":[{{"name":"Deadlifts","reps":{{"set 1":8,"set 2":8,"set 3":6,"set 4":6}},"weights":{{"set 1":180,"set 2":200,"set 3":220,"set 4":240}},"units":{{"weights":"lbs"}}}},{{"name":"Weighted Lat Pulldowns","reps":{{"set 1":10,"set 2":10,"set 3":8,"set 4":8}},"weights":{{"set 1":100,"set 2":110,"set 3":120,"set 4":130}},"units":{{"weights":"lbs"}}}},{{"name":"Dumbbell Rows","reps":{{"set 1":12,"set 2":12,"set 3":10,"set 4":10}},"weights":{{"set 1":45,"set 2":50,"set 3":55,"set 4":60}},"units":{{"weights":"lbs"}}}},{{"name":"Rest","duration":{{"set 1":60,"set 2":75,"set 3":90,"set 4":105}},"units":{{"duration":"seconds"}}}}]}}}}}} `,
    },
  ];

  const example_formatter_template = `input: {input}
  output: {output}
  `;

  const examplePrompt = new PromptTemplate({
    inputVariables: ["input", "output"],
    template: example_formatter_template,
  });

  let prefix = `A workout consists of multiple training parts, each part is a training set. Maximum of 4 parts.
  Each training set consists of 2-3 exercises times a maximum of 4 sometimes, 
  typically with multiple sets and rest time between the sets. Make sure Each training part should be an Antagonistic superset or Antagonistic tri-set.
  your job is to scale up the given workout to the next immediate level of difficulty by increasing the volume load or by adding in advanced movements or modifying 
  current movments to advanced movements. The output should alway be a valid JSON`;

  let prompt = new FewShotPromptTemplate({
    /* These are the examples we want to insert into the prompt. */
    examples,
    /* This is how we want to format the examples when we insert them into the prompt. */
    examplePrompt,
    /* The prefix is some text that goes before the examples in the prompt. Usually, this consists of instructions. */
    prefix: prefix,
    /* The suffix is some text that goes after the examples in the prompt. Usually, this is where the user input will go */
    suffix: "input: {input}\noutput: ",
    /* The input variables are the variables that the overall prompt expects. */
    inputVariables: ["input"],
    /* The example_separator is the string we will use to join the prefix, examples, and suffix together with. */
    exampleSeparator: "\n",
    /* The template format is the formatting method to use for the template. Should usually be f-string. */
    templateFormat: "f-string",
  });

  const llm = new ChatOpenAI({
    temperature: 0,
    modelName: "gpt-3.5-turbo",
    openAIApiKey: OPEN_API_KEY,
  });

  const llmChainScaleWorkout = new LLMChain({
    llm: llm,
    prompt: prompt,
  });

  return { llmChainScaleWorkout };
};
