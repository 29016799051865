import { Link } from 'react-router-dom';
import React from 'react';

export default function NoEntry() {
  return (
    <div className="bg-gradient-to-r from-purple-300 to-blue-200">
      <div className="w-9/12 m-auto py-16 min-h-screen flex items-center justify-center">
        <div className="bg-white-pure shadow overflow-hidden sm:rounded-lg pb-8">
          <div className="border-t border-gray-200 text-center pt-8">
            <h1 className="text-6xl font-bold text-purple-400">OOPS!</h1>
            <h1 className="text-6xl font-medium py-8">Not Authorized</h1>
            <p className="text-2xl pb-8 px-12 font-medium">
              Oops! You are not authorized to access this page. Try Logging in.
            </p>
            <Link to="/">
            <button className="bg-gradient-to-r from-red-400 to-red-500 hover:from-red-500 hover:to-red-500 text-white-pure font-semibold px-6 py-3 rounded-md">
              Login
            </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
