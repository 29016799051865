import React from "react";

export const UserIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='25'
      viewBox='0 0 25 25'
      fill='none'>
      <path
        d='M20.5594 21.8334V19.8334C20.5594 18.7725 20.138 17.7551 19.3879 17.0049C18.6377 16.2548 17.6203 15.8334 16.5594 15.8334H8.55945C7.49858 15.8334 6.48117 16.2548 5.73102 17.0049C4.98088 17.7551 4.55945 18.7725 4.55945 19.8334V21.8334'
        stroke='black'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M12.5594 11.8334C14.7686 11.8334 16.5594 10.0425 16.5594 7.83337C16.5594 5.62423 14.7686 3.83337 12.5594 3.83337C10.3503 3.83337 8.55945 5.62423 8.55945 7.83337C8.55945 10.0425 10.3503 11.8334 12.5594 11.8334Z'
        stroke='black'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};