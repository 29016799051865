import { getAllUsersDashboard } from "@/ApiServices/Analytics/Api";
import { GenericCell, HeaderCell } from "@/components/Dashboard/helpers";
import React, { useEffect, useState } from "react";
import ShowTable from "./ShowTable";
import moment from "moment";

const AllAthletesAnalytics = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const getData = async () => {
    setLoading(true);
    let data = await getAllUsersDashboard();
    setData(data);
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);

  const atheletesColumns = [
    {
      field: "uuid",
      headerName: "UUID",
      flex: 1,
      headerAlign: "center",
      minWidth: 120,
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="UUID" />,
      renderCell: (params) => <GenericCell label={params?.row?.id} />,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      headerAlign: "center",
      minWidth: 120,
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Name" />,
      renderCell: (params) => <GenericCell label={params?.row?.name} />,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Email" />,
      renderCell: (params) => <GenericCell label={params?.row?.email} />,
    },
    {
      field: "coachName",
      headerName: "Coach Name",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Coach Name" />,
      renderCell: (params) => <GenericCell label={params?.row?.coachName} />,
    },
    {
      field: "garminConnected",
      headerName: "Garmin Connected",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Garmin Connected" />,
      renderCell: (params) => (
        <GenericCell label={params?.row?.garminConnected ? "Yes" : "No"} />
      ),
    },
    {
      field: "lastLogin",
      headerName: "Last Login",
      flex: 1,
      //   type: "date",
      sortComparator: (v1, v2) =>
        new Date(v1).getDate() - new Date(v2).getDate(),
      minWidth: 120,
      valueGetter: (params) => params?.row?.lastLoggedinAt,
      headerAlign: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Last Login" />,
      renderCell: (params) => (
        <GenericCell
          label={
            params?.row?.lastLoggedinAt
              ? moment(params?.row?.lastLoggedinAt).format("MMM Do' YY")
              : "-"
          }
        />
      ),
    },
  ];

  return (
    <ShowTable
      data={data}
      columns={atheletesColumns}
      getRowId={(row) => row.id}
      loading={loading}
      key={1}
    />
  );
};

export default AllAthletesAnalytics;
