import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import moment from "moment";
import { useContext, useState } from "react";

import checked from "../../assets/trainerProfile/checked.svg";
import unchecked from "../../assets/trainerProfile/unchecked.svg";

import axios from "axios";

import { useSelector } from "react-redux";
import { CustomAlertContext } from "../../App";

const AssignedUsersDialog = ({
  isOpen,
  setIsOpen,
  mealScheduleAssignedUsers,
  setMealScheduleAssignedUsers,
}) => {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);

  const { displayAlert } = useContext(CustomAlertContext);

  const deleteSelected = async () => {
    if (selected.length === 0) return;
    setLoading(true);

    await axios({
      method: "POST",
      url: `${APIURL}CF/api/deleteAssignedMealScheduleforUsers`,
      data: {
        mealScheduleTrackerIDs: selected,
      },
    })
      .then((res) => {
        console.log("deleted successfully");
        let newMealScheduleAssignedUsers = mealScheduleAssignedUsers.filter(
          (mealScheduleAssignedUser) =>
            !selected.includes(mealScheduleAssignedUser.id)
        );
        setMealScheduleAssignedUsers(newMealScheduleAssignedUsers);
        displayAlert({
          type: "success",
          message: "Deleted successfully",
        });
        if (newMealScheduleAssignedUsers.length === 0) setIsOpen(false);
      })
      .catch((err) => {
        console.log("error in deleting");

        displayAlert({
          type: "error",
          message: "Error in deleting",
        });
      })
      .finally(() => {
        setLoading(false);
      });

    setSelected([]);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: {
          borderRadius: "20px",
        },
      }}
    >
      <div className="add-program-modal-head">
        <div style={{ display: "flex", alignItems: "flex-end", gap: "8px" }}>
          <p className="add-program-modal-head-title">Remove subscribers</p>
        </div>
        <div
          onClick={() => {
            setIsOpen(false);
          }}
          style={{
            cursor: "pointer",
          }}
        >
          <CloseRoundedIcon />
        </div>
      </div>
      <DialogContent sx={{ padding: 0, overflowY: "scroll" }}>
        <div className="add-program-modal-body">
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                padding: "10px 24px 10px 44px",
                borderBottom: "1px solid #E0E0E0",
              }}
            >
              <span
                style={{ width: "40%", marginLeft: "16px", fontWeight: 600 }}
                className="select-workouts_item-description"
              >
                User Name
              </span>
              <span
                style={{ width: "30%", marginLeft: "8px", fontWeight: 600 }}
                className="select-workouts_item-description"
              >
                Date Assigned
              </span>
            </div>
            {mealScheduleAssignedUsers?.map((assignedMealSchedule, index) => {
              console.log("user: ", assignedMealSchedule);
              const userName = assignedMealSchedule?.athelete?.userName;
              let mealScheduleAssignedId = assignedMealSchedule?.id;
              let dateAssigned = assignedMealSchedule?.dateAssigned
                ? moment(assignedMealSchedule?.dateAssigned).format(
                    "MMMM Do' YY"
                  )
                : "";
              return (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    padding: "10px 24px",
                  }}
                >
                  {selected?.includes(mealScheduleAssignedId) ? (
                    <img
                      src={checked}
                      alt="checked"
                      style={{ width: "20px", height: "20px" }}
                      onClick={() => {
                        setSelected(
                          selected.filter(
                            (item) => item !== mealScheduleAssignedId
                          )
                        );
                      }}
                    />
                  ) : (
                    <img
                      src={unchecked}
                      alt="unchecked"
                      style={{ width: "20px", height: "20px" }}
                      onClick={() => {
                        setSelected([...selected, mealScheduleAssignedId]);
                      }}
                    />
                  )}
                  <span
                    style={{ width: "40%", marginLeft: "16px" }}
                    className="select-workouts_item-title"
                  >
                    {userName}
                  </span>
                  <span
                    style={{ width: "30%" }}
                    className="select-workouts_item-description"
                  >
                    {dateAssigned}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "center",
          paddingBottom: "16px",
        }}
      >
        <button
          disabled={loading || selected.length === 0}
          className="button-new"
          onClick={deleteSelected}
        >
          {loading ? "Removing..." : "Remove"}
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignedUsersDialog;
