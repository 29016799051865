import { store } from "@/redux/store";
import axios from "axios";

/* CREATE A NEW FORM WITH FORMTYPE, DESCRIPTION AND CREATEDAT */
export const createForm = async (data) => {
  try {
    const response = await axios({
      method: "post",
      url: `${store.getState().auth.apiURL}CF/api/customForms/insertNewForm`,
      data: data,
    });
    console.log("created from ", response);
    return response.data; // Return the response data
  } catch (error) {
    console.log("error in adding leads", error);
  }
};

/* UPDATE FORM DATA IN ALREADY CREATED FORM DATA IS FORM JSON */
export const updateForm = async (data) => {
  try {
    const response = await axios({
      method: "post",
      url: `${store.getState().auth.apiURL}CF/api/customForms/updateForm`,
      data: data,
    });
    console.log("created from ", response);
    return response; // Return the response data
  } catch (error) {
    console.log("error in adding leads", error);
  }
};

/* UPDATE LEADS FORM */
export const updateCoachLeadsForm = async (data) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${store.getState().auth.apiURL}CF/api/LeadGen/updateCoachLeadsForm`,
      data: data
    })
    return response
  } catch (error) {
    console.log('error from leads form', { error })
  }
}

/* GET FORM BY COACH ID */
export const getCoachForm = async (id) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${store.getState().auth.apiURL
        }CF/api/customForms/getCoachForms?coachID=${id}`,
    });
    console.log("created from ", response);
    return response.data; // Return the response data
  } catch (error) {
    console.log("error in adding leads", error);
  }
};

/* GET FORM BY FORMID */
export const getFormById = async (id) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${store.getState().auth.apiURL
        }CF/api/customForms/getFormbyID?id=${id}`,
    });
    console.log("created from ", response);
    return response.data; // Return the response data
  } catch (error) {
    console.log("error in adding leads", error);
  }
};
/* GET LEADS FORM BY FORMID */
export const getCoachLeadForm = async (id) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${store.getState().auth.apiURL
        }CF/api/LeadGen/getCoachLeadForm?coachID=${id}`,
    });
    console.log("created from ", response);
    return response.data; // Return the response data
  } catch (error) {
    console.log("error in adding leads", error);
  }
};

/* ASSIGN FORM TO SINGLE USER */
export const postSignleUserForm = async (data) => {
  try {
    const response = await axios({
      method: "post",
      url: `${store.getState().auth.apiURL}CF/api/customForms/assignFormToUser`,
      data: data,
    });
    console.log("created from ", response);
    return response.data; // Return the response data
  } catch (error) {
    console.log("error in adding leads", error);
    return error;
  }
};

/* ASSIGN FORM TO MULTIPLE USER */
export const postMultipleUserForm = async (data) => {
  try {
    const response = await axios({
      method: "post",
      url: `${store.getState().auth.apiURL
        }CF/api/customForms/MultipleAssignFormToUser`,
      data: data,
    });
    console.log("created from ", response);
    return response.data; // Return the response data
  } catch (error) {
    console.log("error in adding leads", error);
    return error;
  }
};

/* DELETE FORM BY ID */
export const deleteForm = async (data) => {
  try {
    const response = await axios({
      method: "post",
      url: `${store.getState().auth.apiURL
        }CF/api/customForms/deleteCoachFormTemplateByID`,
      data: data,
    });
    console.log("deleted from ", response);
    return response.data; // Return the response data
  } catch (error) {
    console.log("error in adding leads", error);
    return error;
  }
};

/* ASSIGN FORM TO MULTIPLE USER */
export const submitForm = async (data) => {
  try {
    const response = await axios({
      method: "post",
      url: `${store.getState().auth.apiURL
        }CF/api/customForms/UsersSubmitAnswers`,
      data: data,
    });
    console.log("created from ", response);
    return response; // Return the response data
  } catch (error) {
    console.log("error in adding leads", error);
    return error;
  }
};
