import React, { useContext, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  CircularProgress,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import {
  ASSIST_MODIFY_WORKOUTS,
  CREATE_MEAL_PLAN,
  CREATE_WORKOUT_PLAN,
  GENERATE_SINGLE_WORKOUT,
  GENERATE_RECIPE,
  HELP_US_BE_BETTER,
  ADD_AND_MANAGE_CLIENTS,
  KNOW_MORE,
  ANALYSE_CLIENT_MEDICAL_PROFILE,
  GET_INSIGHTS_ON_CLIENT_WORKOUTS,
  GENERATE_MEAL_PLAN,
} from "../../../constants";
import ClientIcon from "../../../assets/copilot/clients.svg";
import InsightIcon from "../../../assets/copilot/insight.svg";
import AnalyseIcon from "../../../assets/copilot/analyse.svg";
import { CopilotContext } from "../copilot";
import SparkleWhiteLogo from "../../../assets/copilot/chatSparkleWhite.svg";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { getPlaceholderText } from "./helper";
import { Option } from "../SpurfitOptions";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";

const FormComponent = ({
  users,
  getTriggerFunction,
  selectedOption,
  onAssignClick,
  goBack,
}) => {
  const [query, setQuery] = useState("");
  const [user, setUser] = useState(null);

  const [loading, setLoading] = useState(false);
  const [fetched, setFetched] = useState(false);
  const [showModifyOptions, setShowModifyOptions] = useState(false);
  const [selectedModifyOption, setSelectedModifyOption] = useState(null);

  const [isSecondPickerActive, setIsSecondPickerActive] = useState(false);
  const [maxRangeOfSecondPicker, setMaxRangeOfSecondPicker] = useState(
    moment()
  );
  const [fromDate, setFromDate] = useState(moment());
  const [toDate, setToDate] = useState(moment());

  const filterOptions = createFilterOptions({
    limit: 3,
  });

  const customFilterOptions = (options, state) => {
    if (state.inputValue && state.inputValue.trim() !== "") {
      return filterOptions(options, state);
    }
    return [];
  };

  const { setSelectedUser, updateAthleteLevelTag, levelTag, setLevelTag } =
    useContext(CopilotContext);
  const levelTags = [
    "Beginner",
    "Advanced Beginner",
    "Intermediate Beginner",
    "Novice Intermediate",
    "Intermediate",
    "Upper Intermediate",
    "Advanced Intermediate",
    "Advanced",
  ];

  let placeholder = getPlaceholderText(selectedOption);

  let shouldSelectUser =
    selectedOption == CREATE_WORKOUT_PLAN ||
    selectedOption == CREATE_MEAL_PLAN ||
    (selectedOption == ASSIST_MODIFY_WORKOUTS && !fetched && !loading);

  let canSelectUser =
    selectedOption == CREATE_WORKOUT_PLAN ||
    selectedOption == CREATE_MEAL_PLAN ||
    selectedOption == ASSIST_MODIFY_WORKOUTS;

  let shouldShowDateRangePicker =
    selectedOption === ASSIST_MODIFY_WORKOUTS && !fetched && !loading;

  const getButtonText = () => {
    switch (selectedOption) {
      case GENERATE_SINGLE_WORKOUT:
        return "Generate Workout";

      case CREATE_WORKOUT_PLAN:
        return "Create Workout";
      case CREATE_MEAL_PLAN:
        return "Create Meal Plan";
      case HELP_US_BE_BETTER:
        return "Send Feedback";
      case ASSIST_MODIFY_WORKOUTS:
        return fetched ? "Modify Workout" : "Fetch Workout";
      case GENERATE_RECIPE:
        return "Generate Recipe";
      case GENERATE_MEAL_PLAN:
        return "Generate Meal Plan";
      default:
        return "Send";
    }
  };

  const getHeaderText = () => {
    switch (selectedOption) {
      case CREATE_WORKOUT_PLAN:
      case CREATE_MEAL_PLAN:
        return "Please, fill in the below client details";
      case HELP_US_BE_BETTER:
        return "We would love to hear from you";
      case ASSIST_MODIFY_WORKOUTS:
        return fetched
          ? "What would you like to do?"
          : "Please, fill in the below details";
      case KNOW_MORE:
        return "Upcoming Features.";
      default:
        return "Please, fill in the below details";
    }
  };

  return (
    <div
      style={{
        alignSelf: "center",
        width: "280px",
        borderRadius: "8px",
        marginBottom: "33px",
        padding: "6px 6px 13.5px 6px;",
        fontFamily: "DM Sans",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        boxShadow:
          "0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 4px 8px 0px rgba(0, 0, 0, 0.14)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          pl: "8px",
          pt: "12px",
          gap: "8px",
        }}
      >
        <ArrowBackIosNewRoundedIcon
          titleAccess="Go Back"
          onClick={() => {
            goBack();
          }}
          style={{
            cursor: "pointer",
            color: "#424242",
            fontSize: "16px",
          }}
        />

        <Typography
          sx={{
            fontFamily: "DM Sans",
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "20px",
            textAlign: "left",

            width: "100%",
            color: "#242424",
          }}
        >
          {getHeaderText()}
        </Typography>
      </Box>
      {selectedOption === KNOW_MORE && <ComingSoonOptions />}
      {shouldSelectUser && (
        <Autocomplete
          disablePortal
          filterOptions={customFilterOptions}
          freeSolo
          id="combo-box-demo"
          options={users}
          value={user?.name}
          getOptionLabel={(option) => {
            return option?.name;
          }}
          onChange={(event, value) => {
            setUser(value);
            if (value?.levelTag) {
              setLevelTag(value?.levelTag);
            }
            setSelectedUser(value);
          }}
          sx={{
            width: "260px",
            fontSize: "12px",
            color: "rgba(51, 51, 56, 0.50)",
            "& li": {
              fontSize: "12px",
            },
            mt: "12px",
          }}
          renderOption={(props, option) => (
            <MenuItem
              {...props}
              key={option.key}
              sx={{
                fontSize: "12px",
                bgcol: "red",
                fontFamily: "DM Sans",
                lineHeight: "16px",
                color: "#000",
                zIndex: 100,
              }}
            >
              {option?.name}
            </MenuItem>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label=""
              placeholder="Client Name"
              variant="standard"
              size="small"
              sx={{
                fontSize: "12px",
                borderBottomColor: "#E1E1E1",
                "&:hover": { borderBottomColor: "#E1E1E1" },
              }}
              InputProps={{
                ...params.InputProps,
                sx: { fontSize: "12px", lineHeight: "22px" },
              }}
            />
          )}
        />
      )}
      {user && selectedOption == CREATE_WORKOUT_PLAN && (
        <Select
          variant="standard"
          disableUnderline
          SelectDisplayProps={{
            style: {
              backgroundColor: "#fff",
            },
          }}
          sx={{
            width: "260px",
            mt: "12px",
            fontSize: "12px",
            color: "rgba(51, 51, 56, 0.50)",
            fontFamily: "DM Sans",
          }}
          value={levelTag}
          onChange={(e) => {
            setLevelTag(e.target.value);
          }}
        >
          {levelTags.map((tag) => (
            <MenuItem
              sx={{
                fontSize: "12px",
                fontFamily: "DM Sans",
                lineHeight: "16px",
              }}
              value={tag}
            >
              {tag}
            </MenuItem>
          ))}
        </Select>
      )}

      {fetched && (
        <Stack
          sx={{
            width: "100%",
            display: "flex",
            px: "8px",
            mt: "12px",
            mb: 2,
          }}
        >
          <Box
            sx={{
              width: "100%",
            }}
          >
            <button
              style={{
                boxShadow: "2px 2px 8px rgba(0, 0, 0, 0.25)",
                fontWeight: 500,
                fontFamily: "DM Sans",
                borderRadius: "20px",
                fontSize: "12.533px",
                padding: "5px 15px",
                marginRight: "12px",
              }}
              onMouseOver={(e) =>
                (e.target.style.boxShadow = "4px 4px 12px rgba(0, 0, 0, 0.25)")
              }
              onMouseOut={(e) =>
                (e.target.style.boxShadow = "2px 2px 8px rgba(0, 0, 0, 0.25)")
              }
              onClick={() => {
                onAssignClick();
                setShowModifyOptions(false);
              }}
            >
              Assign
            </button>
            <button
              style={{
                boxShadow: "2px 2px 8px rgba(0, 0, 0, 0.25)",
                fontWeight: 500,
                fontFamily: "DM Sans",
                borderRadius: "20px",
                fontSize: "12.533px",
                padding: "5px 15px",
                color: showModifyOptions ? "#fff" : "#333338",
                background: showModifyOptions
                  ? "linear-gradient(to right, #464FEB, #8330E9)"
                  : "#fff",
              }}
              onMouseOver={(e) =>
                (e.target.style.boxShadow = "4px 4px 12px rgba(0, 0, 0, 0.25)")
              }
              onMouseOut={(e) =>
                (e.target.style.boxShadow = "2px 2px 8px rgba(0, 0, 0, 0.25)")
              }
              onClick={() => {
                setShowModifyOptions(!showModifyOptions);
              }}
            >
              Modify
            </button>
          </Box>
          {showModifyOptions && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                mt: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <Box
                  onClick={() => {
                    if (!loading) {
                      setSelectedModifyOption("scale down");
                      let trigger = getTriggerFunction(selectedOption);
                      trigger[1]("scale down");
                    }
                  }}
                  sx={{
                    fontSize: "12px",
                    fontFamily: "DM Sans",
                    background:
                      selectedModifyOption == "scale down" &&
                      "linear-gradient(to right, #464FEB, #8330E9)",
                    color: selectedModifyOption == "scale down" && "#fff",
                    border:
                      selectedModifyOption == "scale down"
                        ? "2px solid #8330E9"
                        : "2px solid #d2d2db",
                    borderTopLeftRadius: "8px",
                    borderBottomLeftRadius: `8px`,
                    padding: "2px 12px",
                    cursor: "pointer",
                  }}
                >
                  Scale Down
                </Box>
                <Box
                  onClick={() => {
                    if (!loading) {
                      setSelectedModifyOption("scale up");
                      let trigger = getTriggerFunction(selectedOption);
                      trigger[1]("scale up");
                    }
                  }}
                  sx={{
                    fontSize: "12px",
                    fontFamily: "DM Sans",
                    background:
                      selectedModifyOption == "scale up" &&
                      "linear-gradient(to right, #464FEB, #8330E9)",
                    color: selectedModifyOption == "scale up" && "#fff",
                    border:
                      selectedModifyOption == "scale up"
                        ? "2px solid #8330E9"
                        : "2px solid #d2d2db",
                    borderTopRightRadius: "8px",
                    borderBottomRightRadius: `8px`,
                    padding: "2px 12px",
                    cursor: "pointer",
                  }}
                >
                  Scale Up
                </Box>
              </Box>
            </Box>
          )}
        </Stack>
      )}
      {loading && (
        <CircularProgress
          sx={{
            fontSize: "12px",
            color: "#8330E9",
          }}
        />
      )}

      {selectedOption !== ASSIST_MODIFY_WORKOUTS &&
        selectedOption != KNOW_MORE &&
        !loading && (
          <TextField
            disabled={shouldSelectUser && !user}
            multiline
            maxRows={3}
            placeholder={placeholder}
            sx={{ width: "260px", mt: "14px" }}
            InputProps={{
              sx: {
                fontFamily: "DM Sans",
                fontSize: "12px",
                lineHeight: "22px",
                color: "#333338",
                fontWeight: "400px",
              },
            }}
            onChange={(e) => {
              setQuery(e.target.value);
            }}
          />
        )}

      {shouldShowDateRangePicker && (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Typography
            sx={{
              fontFamily: "DM Sans",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "20px",
              color: "#333338",
              textAlign: "left",
              width: "100%",
              mt: "14px",
              pl: "10px",
              pt: "12px",
              mb: "8px",
            }}
          >
            Select date range
          </Typography>
          <Box
            name="date-range-picker"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "260px",
              gap: 1,
            }}
          >
            <PickDate
              onChange={(date) => {
                setFromDate(date);
                const sixDaysFromNow = moment(date).add(6, "days");
                const minDate = moment.min(sixDaysFromNow, moment());
                console.log("minDate", minDate);
                setIsSecondPickerActive(true);
                setMaxRangeOfSecondPicker(minDate);
              }}
              value={moment()}
              isActive={true}
              maxDate={moment()}
            />
            <div>-</div>
            <PickDate
              onChange={(date) => {
                setToDate(date);
                let trigger = getTriggerFunction(selectedOption);
                trigger[0](query, fromDate, date, setLoading, setFetched);
              }}
              value={moment()}
              isActive={isSecondPickerActive}
              maxDate={maxRangeOfSecondPicker}
              minDate={fromDate}
            />
          </Box>
        </LocalizationProvider>
      )}

      {!fetched && selectedOption != KNOW_MORE && (
        <button
          disabled={canSelectUser && !user}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "linear-gradient(to right, #464FEB, #8330E9)",
            width: "260px",
            height: "34px",
            lineHeight: "34px",
            borderRadius: "78px",
            color: "#fff",
            marginTop: "14px",
            marginBottom: "14px",
            fontFamily: "DM Sans",
            fontWeight: "500",
            fontSize: "12.533px",
          }}
          onClick={() => {
            let trigger = getTriggerFunction(selectedOption);
            if (selectedOption === ASSIST_MODIFY_WORKOUTS) {
              if (fetched) {
                trigger[1](query);
              } else {
                trigger[0](query, fromDate, toDate, setLoading, setFetched);
              }
            } else {
              trigger(query);
            }
            if (selectedOption === CREATE_WORKOUT_PLAN) {
              updateAthleteLevelTag(user?.id, levelTag);
            }
          }}
        >
          {selectedOption != HELP_US_BE_BETTER && (
            <img
              src={SparkleWhiteLogo}
              style={{ marginRight: "6px", height: "16px", width: "16px" }}
            />
          )}
          {getButtonText()}
        </button>
      )}
    </div>
  );
};

export default FormComponent;

const PickDate = ({ onChange, value, isActive, maxDate, minDate }) => {
  return (
    <DatePicker
      sx={{
        height: "26px",
        fontFamily: "DM Sans",
        "& input": {
          fontFamily: "DM Sans",
          fontSize: "10px",
          padding: "6px",
          backgroundColor: "#FFFFFF",
        },
        "& .MuiInputLabel-root": {
          fontFamily: "DM Sans",
          fontSize: "10px",
          transform: "translate(0, 10px) scale(1)",
        },
        "& .MuiInputBase-root": {
          fontFamily: "DM Sans",
          borderRadius: "4px",
          outline: "none",
          "&:hover": {
            borderColor: "rgba(0, 0, 0, 0.23)",
          },
          "&.Mui-focused": {
            // borderColor: "#1976d2",
            fontFamily: "DM Sans",
          },
        },
      }}
      // value={assignDate}
      maxDate={maxDate}
      minDate={minDate}
      onChange={(newValue) => {
        onChange(newValue);
      }}
    />
  );
};

const ComingSoonOptions = () => {
  return (
    <div
      style={{
        paddingBottom: "12px",
      }}
    >
      <Option title={ADD_AND_MANAGE_CLIENTS} icon={ClientIcon} />
      <Option title={ANALYSE_CLIENT_MEDICAL_PROFILE} icon={AnalyseIcon} />
      <Option title={GET_INSIGHTS_ON_CLIENT_WORKOUTS} icon={InsightIcon} />
    </div>
  );
};
