import AddRoundedIcon from "@mui/icons-material/AddRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { store } from "../../redux/store";
import AddFoodItemModal from "../Nutrition/AddMealPlans/AddFoodItemModal";
import CustomMenu from "../Nutrition/CustomMenu";
import { NutritionMetric } from "../Nutrition/NItemDetails/NItemDetails";

function CreateFoodModal({
  isOpen,
  setIsOpen,
  item,
  getMealPlans,
  isUpdate,
  setMealData,
  selectedDate,
  userUUID,
  updateData,
  setUpdateData,
}) {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const [meals, setMeals] = useState([]);
  const [selectCategory, setSelectCategory] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("Breakfast");
  const [mealIndex, setMealIndex] = useState(0);
  const [showFoodItemModal, setShowFoodItemModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [foodItems, setFoodItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [allSelectedItems, setAllSelectedItems] = useState([]);
  const [totalMacros, setTotalMacros] = useState({
    calories: 0,
    protein: 0,
    carbs: 0,
    fat: 0,
    proteinPercentage: 0,
    carbsPercentage: 0,
    fatPercentage: 0,
  });
  const [saveLoading, setSaveLoading] = useState(false);
  const [searchedFoods, setSearchedFoods] = useState([]);
  const [searchedRecipes, setSearchedRecipes] = useState([]);

  const getFoodItems = async (searchTerm, pageNo, perPage) => {
    setIsLoading(true);

    try {
      const [foodsRes, recipesRes] = await Promise.all([
        axios.post(`${APIURL}CF/api/getAllFoods`, {
          recordsPerPage: perPage || 10,
          page: pageNo || 1,
          searchQuery: searchTerm || "",
        }),
        axios.post(`${APIURL}CF/api/getAllReceipes`, {
          recordsPerPage: perPage || 10,
          page: pageNo || 1,
          searchQuery: searchTerm || "",
        }),
      ]);

      const foods = foodsRes?.data?.foods || [];
      const recipes = recipesRes?.data?.recipes || [];

      setSearchedFoods(foods);
      setSearchedRecipes(recipes);

      const sortedFoodItems = [...foods, ...recipes].sort((a, b) => {
        const nameA = (a.food_name || a.recipe_name).toUpperCase();
        const nameB = (b.food_name || b.recipe_name).toUpperCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });

      setFoodItems(sortedFoodItems);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const getAllFoodAndRecipeIdsSeparately = async () => {
    const foodIds = [];
    const recipeIds = [];

    item?.plandetails?.meals?.forEach((meal) => {
      meal?.foods?.forEach((food) => {
        if (food?.food_id) {
          foodIds.push(food?.food_id);
        } else if (food?.recipe_id) {
          recipeIds.push(food?.recipe_id);
        }
      });
    });

    await Promise.all([
      axios({
        method: "get",
        url: `${APIURL}CF/api/getGlobalFoodsbyIDs`,
        params: {
          foodIds: foodIds,
        },
      }),
      axios({
        method: "get",
        url: `${APIURL}CF/api/getGlobalRecipebyIDs`,
        params: {
          recipeIds: recipeIds,
        },
      }),
    ])
      .then((res) => {
        const foods = res[0]?.data?.result || [];
        const recipes = res[1]?.data?.result || [];
        console.log("result: ", res);
        const allSelectedItems = [...foods, ...recipes];
        console.log("allSelectedItems", allSelectedItems);
        setAllSelectedItems(allSelectedItems);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const retriveMeals = () => {
    console.log("responseMeal", item);
    getAllFoodAndRecipeIdsSeparately();
    setMeals(item?.plandetails?.meals || []);
  };

  useEffect(() => {
    // retriveMeals();
    getFoodItems();
  }, []);

  useEffect(() => {
    getTotalMacros();
  }, [meals]);

  const getFoodFromId = (id) => {
    return allSelectedItems?.find(
      (item) => item?.food_id === id || item?.recipe_id === id
    );
  };

  const getAllMeasurementAndServing = (food) => {
    let serving = food?.servings?.serving || food?.servings;
    let servings = {};
    let measurements = serving?.map((item) => {
      const measurement = item?.measurement_description;
      const serving = item?.serving_description;
      if (measurement && measurement.length <= 18) {
        // Remove text inside parentheses
        const truncatedMeasurement = measurement.replace(
          /\s*\([^)]*\)\s*/g,
          ""
        );
        servings[truncatedMeasurement] = item;
        return { measurement: truncatedMeasurement, serving };
      } else {
        return null;
      }
    });

    const filteredMeasurements = measurements?.filter((item) => item !== null);
    const servingObj = filteredMeasurements?.reduce((acc, curr) => {
      acc[curr.measurement] = curr.serving;
      return acc;
    }, {});

    return [
      filteredMeasurements?.map((item) => item.measurement),
      servingObj,
      servings,
    ];
  };

  const logAllMeals = () => {
    console.log("all meals: ", meals);
  };

  const createMeal = (data) => {
    axios({
      method: "post",
      url: `${APIURL}CF/api/meal/create`,
      data: data,
    })
      .then((res) => {
        console.log("meal created: ", res.data);
        pushMealPlan(res.data.meal.id);
      })
      .catch((err) => {
        console.log("meal creation error: ", err);
      })
      .finally(() => {
        setIsOpen(false);
      });
  };

  const updateMeal = (data) => {
    // remove nulls from updated item
    Object.keys(data).forEach((key) => data[key] == null && delete data[key]);
    axios({
      method: "post",
      url: `${APIURL}CF/api/meal/update?id=${item?.id}`,
      data: data,
    })
      .then((res) => {
        console.log("meal updated: ", res);
        //   getMealPlans();
      })
      .catch((err) => {
        console.log("meal update error: ", err);
      })
      .finally(() => {
        setIsOpen(false);
      });
  };

  const pushMealPlan = async (id) => {
    await axios({
      method: "post",
      url: `${APIURL}CF/api/meal/assignMeal`,
      data: {
        atheleteID: userUUID,
        mealId: id,
        dateAssigned: moment(selectedDate, "DD-MM-YYYY")
          ?.set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          })
          ?.format("YYYY-MM-DD"),
      },
    })
      .then((res) => {
        console.log("res ====>>> success ", res);
        setUpdateData(!updateData);
      })
      .catch((err) => {
        // setIsLoading(false);
        console.log(["err ===>>> ", err]);
      });
  };

  const onSave = () => {
    // calculate macros for each meal and also calculate total macros of all meals  and save
    setSaveLoading(true);
    let totalMacros = {
      protein: 0,
      carbs: 0,
      fat: 0,
    };
    let totalCalories = 0;

    let newMeals = meals.map((meal) => {
      let foods = meal?.foods || [];
      let calories = 0,
        protein = 0,
        carbs = 0,
        fat = 0;
      foods.forEach((food) => {
        calories += +food?.calories;
        protein += +food?.protein;
        carbs += +food?.carbs;
        fat += +food?.fat;
      });
      meal.macros = {
        protein,
        carbs,
        fat,
      };
      meal.calories = calories;
      totalCalories += +calories;
      totalMacros.protein += +protein;
      totalMacros.carbs += +carbs;
      totalMacros.fat += +fat;

      return meal;
    });
    let plandetails = {
      meals: newMeals,
      macros: totalMacros,
      calories: totalCalories,
    };

    let newItem = {
      ...item,
      coachId: store.getState().auth.trainerUUID,
      plandetails,
    };
    console.log("newmeals: ", newMeals);
    // createMeal(newItem);
    if (item?.id) {
      updateMeal(newItem);
    } else {
      createMeal(newItem);
    }
  };

  const getTotalMacros = () => {
    let totalMacros = {
      calories: 0,
      protein: 0,
      carbs: 0,
      fat: 0,
      proteinPercentage: 0,
      carbsPercentage: 0,
      fatPercentage: 0,
    };
    console.log(meals);
    meals.forEach((meal) => {
      meal?.foods?.forEach((food) => {
        totalMacros.calories += +food?.calories;
        totalMacros.protein += +food?.protein;
        totalMacros.carbs += +food?.carbs;
        totalMacros.fat += +food?.fat;
      });
    });

    if (totalMacros.calories === 0) return setTotalMacros(totalMacros);

    totalMacros.proteinPercentage = Math.round(
      ((totalMacros.protein * 4) / totalMacros.calories) * 100
    );
    totalMacros.carbsPercentage = Math.round(
      ((totalMacros.carbs * 4) / totalMacros.calories) * 100
    );
    totalMacros.fatPercentage = Math.round(
      ((totalMacros.fat * 9) / totalMacros.calories) * 100
    );
    setTotalMacros(totalMacros);

    console.log("total macros: ", totalMacros);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        setMealData([]);
        setIsOpen(false);
      }}
      fullScreen
    >
      <div
        style={{ position: "relative", alignItems: "center" }}
        className="add-program-modal-head"
      >
        <CloseRoundedIcon
          sx={{
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            left: 24,
            cursor: "pointer",
          }}
          onClick={() => setIsOpen(false)}
        />
        <p
          style={{
            marginLeft: "32px",
          }}
          className="add-program-modal-head-title"
        >
          Add Meals
        </p>
        <button disabled={saveLoading} className="button-new" onClick={onSave}>
          {saveLoading ? (
            <CircularProgress
              sx={{
                color: "#fff",
              }}
              size={20}
              thickness={4}
            />
          ) : (
            "Save and Push"
          )}
        </button>
      </div>

      <DialogContent
        sx={{
          overflowY: "scroll",
        }}
      >
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Stack
            sx={{
              flex: 1,
              mr: 2,
            }}
          >
            {meals?.length !== 0 &&
              meals?.map((meal, mealIdx) => {
                return (
                  <Paper
                    sx={{
                      padding: 2,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      mb: 2,
                    }}
                    elevation={3}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: "18px",
                          textTransform: "capitalize",
                          fontFamily: "DM Sans",
                        }}
                      >
                        {meal.name}
                      </Typography>

                      <Box>
                        <CustomButton
                          onClick={() => {
                            setShowFoodItemModal(true);
                            setSelectedIndex(mealIdx);
                          }}
                          text={"Add More"}
                        />
                        <CustomMenu
                          options={["Remove"]}
                          onOptionClick={(option) => {
                            if (option === "Remove") {
                              let newMeals = [...meals];
                              newMeals.splice(mealIdx, 1);
                              setMeals(newMeals);
                            }
                          }}
                        />
                        {/* <Button
                            onClick={() => {
                              let newMeals = [...meals];
                              newMeals.splice(mealIdx, 1);
                              setMeals(newMeals);
                            }}
                          >
                            Remove
                          </Button> */}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        width: "100%",
                      }}
                    >
                      {meal?.foods?.map((food, index) => {
                        let currentFood = food?.food_id
                          ? getFoodFromId(food?.food_id)
                          : getFoodFromId(food?.recipe_id);

                        let allMeasurementNServing =
                          getAllMeasurementAndServing(currentFood);
                        let selectOptions = allMeasurementNServing?.[0];
                        let servingObj = allMeasurementNServing?.[1];
                        let allServings = allMeasurementNServing?.[2];

                        return (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              mt: 1,
                              width: "100%",
                            }}
                            key={index}
                          >
                            <Box
                              sx={{
                                flex: 1,
                              }}
                            >
                              <Typography
                                sx={{
                                  fontFamily: "DM Sans",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                }}
                              >
                                {food?.name}
                              </Typography>
                              <Typography
                                sx={{
                                  fontFamily: "DM Sans",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "#727272",
                                }}
                              >
                                {food?.size
                                  ? parseFloat(food.size)?.toFixed(1) +
                                    " " +
                                    food.unit
                                  : "0"}
                              </Typography>
                            </Box>
                            <Stack direction="row">
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-end",
                                  justifyContent: "flex-end",
                                  mr: 1,
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "DM Sans",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                  }}
                                >
                                  {setToOneFixedDecimal(food?.calories) +
                                    " " +
                                    "cal"}
                                </Typography>
                                <Typography
                                  sx={{
                                    fontFamily: "DM Sans",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "#727272",
                                  }}
                                >
                                  P {setToOneFixedDecimal(food?.protein)} • C{" "}
                                  {setToOneFixedDecimal(food?.carbs)} • F{" "}
                                  {setToOneFixedDecimal(food?.fat)}
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  border: "1px solid #D9D9D9",
                                  borderRadius: 2,
                                  px: 1,
                                  width: "150px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <input
                                  style={{
                                    height: "40px",
                                    outline: "none",
                                    width: "50px",
                                  }}
                                  type="number"
                                  value={food?.measurement_count}
                                  onChange={(e) => {
                                    let value = e.target.value;
                                    console.log("foods", food, "value", value);
                                    if (food?.recipe_id) {
                                      setMeals((prev) => {
                                        let recipeRatio =
                                          value /
                                          +currentFood?.serving_sizes?.serving_size?.split(
                                            " "
                                          )[0];
                                        prev[mealIdx].foods[index] = {
                                          ...prev[mealIdx].foods[index],
                                          protein:
                                            recipeRatio *
                                              currentFood?.serving_sizes
                                                ?.protein || 0,
                                          carbs:
                                            recipeRatio *
                                              currentFood?.serving_sizes
                                                ?.carbohydrate || 0,
                                          fat:
                                            recipeRatio *
                                              currentFood?.serving_sizes?.fat ||
                                            0,
                                          calories:
                                            recipeRatio *
                                              currentFood?.serving_sizes
                                                ?.calories || 0,
                                          size:
                                            recipeRatio *
                                              +currentFood?.grams_per_portion ||
                                            0,
                                          measurement_count: value,
                                        };
                                        return [...prev];
                                      });
                                      return;
                                    }

                                    if (
                                      !value ||
                                      (isNaN(value) && parseInt(value) <= 0)
                                    ) {
                                      setMeals((prev) => {
                                        prev[mealIdx].foods[index] = {
                                          ...prev[mealIdx].foods[index],
                                          measurement_count: "",
                                        };
                                        return [...prev];
                                      });
                                      return;
                                    }
                                    let currentMeal = meals[mealIdx];
                                    let foods = currentMeal?.foods || [];
                                    let currentServing =
                                      allServings[
                                        food?.measurement_description
                                      ];
                                    let currentMeasurementCount =
                                      removeSubstringAndParseInt(
                                        servingObj[
                                          currentServing
                                            ?.measurement_description
                                        ],
                                        currentServing?.measurement_description
                                      );
                                    let ratio =
                                      e.target.value / currentMeasurementCount;
                                    console.log(
                                      "currentServing: ",
                                      currentServing
                                    );
                                    foods[index] = {
                                      ...foods[index],
                                      protein: currentServing?.protein * ratio,
                                      carbs:
                                        currentServing?.carbohydrate * ratio,
                                      fat: currentServing?.fat * ratio,
                                      calories:
                                        currentServing?.calories * ratio,
                                      measurement_count: e.target.value,
                                      size:
                                        +currentServing?.metric_serving_amount *
                                        ratio,
                                    };
                                    currentMeal.foods = foods;
                                    // recalculating macros
                                    let macros = {
                                      protein: 0,
                                      carbs: 0,
                                      fat: 0,
                                    };
                                    let calories = 0;
                                    foods.forEach((food) => {
                                      macros.protein += +food?.protein;
                                      macros.carbs += +food?.carbs;
                                      macros.fat += +food?.fat;
                                      calories += +food?.calories;
                                    });
                                    currentMeal.macros = macros;
                                    currentMeal.calories = calories;
                                    setMeals([...meals]);
                                  }}
                                />
                                {food?.food_id ? (
                                  <Select
                                    sx={{
                                      height: "40px",
                                      maxWidth: "95px",
                                    }}
                                    inputProps={{
                                      style: {
                                        backgroundColor: "#fff",
                                      },
                                    }}
                                    variant="standard"
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    disableUnderline
                                    value={food?.measurement_description}
                                    onChange={(e) => {
                                      let currentMeal = meals[mealIdx];
                                      let foods = currentMeal?.foods || [];
                                      let currentServing =
                                        allServings[e.target.value];
                                      foods[index] = {
                                        ...foods[index],
                                        calories: currentServing?.calories,
                                        protein: currentServing?.protein,
                                        carbs: currentServing?.carbohydrate,
                                        fat: currentServing?.fat,
                                        size: currentServing?.metric_serving_amount,
                                        unit: currentServing?.metric_serving_unit,
                                        measurement_description: e.target.value,
                                        measurement_count:
                                          removeSubstringAndParseInt(
                                            servingObj[e.target.value],
                                            e.target.value
                                          ),
                                      };

                                      setMeals([...meals]);
                                    }}
                                  >
                                    {selectOptions?.map((item) => (
                                      <MenuItem
                                        className="hover-effect"
                                        sx={{
                                          width: "100%",
                                          fontFamily: "DM Sans",
                                          fontSize: "14px",
                                        }}
                                        value={item}
                                      >
                                        {item}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                ) : (
                                  <MenuItem
                                    sx={{
                                      "&:hover": {
                                        backgroundColor: "transparent",
                                      },
                                    }}
                                    value={food?.measurement_description}
                                  >
                                    {food?.measurement_description}
                                  </MenuItem>
                                )}
                              </Box>
                            </Stack>
                          </Box>
                        );
                      })}
                    </Box>
                  </Paper>
                );
              })}

            <Paper
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "25px 20px",
                mt: 2,
              }}
              elevation={3}
            >
              {selectCategory ? (
                <>
                  <Select
                    sx={{
                      height: "40px",
                      borderRadius: "12px",
                    }}
                    labelId="meal-category-label"
                    id="meal-category"
                    value={selectedCategory}
                    onChange={(e) => {
                      setSelectedCategory(e.target.value);
                    }}
                  >
                    {mealOptions?.map((item) => (
                      <MenuItem
                        disabled={meals.some((meal) => meal.name === item)}
                        sx={{ width: "100%", fontSize: "12px" }}
                        value={item}
                      >
                        {item}
                      </MenuItem>
                    ))}
                  </Select>

                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <CustomButton
                      onClick={() => {
                        setMeals([
                          ...meals,
                          {
                            name: selectedCategory,
                            foods: [],
                          },
                        ]);
                        setSelectCategory(false);
                        setMealIndex(mealIndex + 1);
                      }}
                      text={"Add"}
                    />
                    <CustomButton
                      onClick={() => {
                        setSelectCategory(false);
                        setSelectedCategory("Breakfast");
                      }}
                      text="Discard"
                    />
                  </Box>
                </>
              ) : (
                <Button
                  variant="text"
                  className="hover-effect"
                  sx={{
                    alignSelf: "center",
                    fontSize: "14px",
                    lineHeight: "20px",
                    fontWeight: 400,
                    fontFamily: "DM Sans",
                    color: "#242424",
                    fontStyle: "normal",
                    borderRadius: "12px",
                    padding: "10px",
                  }}
                  onClick={() => {
                    setSelectCategory(true);
                  }}
                >
                  <AddRoundedIcon /> Add meal Category
                </Button>
              )}
            </Paper>
          </Stack>

          <Stack>
            <Paper
              sx={{
                padding: "25px 20px",
              }}
              elevation={3}
            >
              <Typography
                sx={{
                  textTransform: "Uppercase",
                  color: "rgb(70, 79, 235)",
                  fontFamily: "DM Sans",
                  fontSize: "12px",
                  fontWeight: 600,
                }}
              >
                Nutrition Facts
              </Typography>
              <Typography
                sx={{
                  fontSize: "30px",
                  fontWeight: 700,
                  fontFamily: "DM Sans",
                }}
              >
                {setToOneFixedDecimal(totalMacros?.calories)}{" "}
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "#7C7C7C",
                  }}
                >
                  Cals
                </span>
              </Typography>
              <Stack direction={"row"} spacing={1} mt={2}>
                <NutritionMetric
                  label="Protein"
                  value={totalMacros?.protein}
                  percentage={totalMacros?.proteinPercentage}
                />
                <NutritionMetric
                  label="Fat"
                  value={totalMacros?.fat}
                  percentage={totalMacros?.fatPercentage}
                />
                <NutritionMetric
                  label="Carbs"
                  value={totalMacros?.carbs}
                  percentage={totalMacros?.carbsPercentage}
                />
              </Stack>
            </Paper>
          </Stack>
        </Stack>
      </DialogContent>

      {showFoodItemModal && (
        <AddFoodItemModal
          isOpen={showFoodItemModal}
          setIsOpen={setShowFoodItemModal}
          meals={meals}
          setMeals={setMeals}
          selectedIndex={selectedIndex}
          foodItems={foodItems}
          searchedFoods={searchedFoods}
          searchedRecipes={searchedRecipes}
          getFoodItems={getFoodItems}
          isLoading={isLoading}
          allSelectedItems={allSelectedItems}
          setAllSelectedItems={setAllSelectedItems}
        />
      )}
    </Dialog>
  );
}

export default CreateFoodModal;

function removeSubstringAndParseInt(str, substr) {
  let newStr = str.replace(substr, "");
  const num = parseInt(newStr);
  return isNaN(num) ? null : num;
}

export const setToOneFixedDecimal = (num) => {
  if (num === undefined || num === null || num === "") {
    return 0;
  }

  const parsedNum = parseFloat(num);

  if (isNaN(parsedNum)) {
    return 0;
  }

  return parsedNum.toFixed(1);
};

const CustomButton = ({ text, onClick, style }) => {
  return (
    <button
      onClick={onClick}
      className="hover-effect"
      style={{
        fontFamily: "DM Sans",
        padding: "5px 10px",
        borderRadius: 12,
        fontSize: 14,
        fontWeight: 600,
        ...style,
      }}
    >
      {text}
    </button>
  );
};

const mealOptions = [
  "On Waking Up",
  "Early Morning",
  "Breakfast",
  "After Breakfast",
  "Mid-morning",
  "Morning Snack",
  "Lunch",
  "Evening Snack",
  "Dinner",
  "After Dinner",
  "Bed Time",
  "Before Workout",
  "During Workout",
  "Between Workouts",
  "After Workout",
  "Non-Training Day",
  "Meal 1",
  "Meal 2",
  "Meal 3",
  "Meal 4",
  "Meal 5",
  "Meal 6",
  "Snack 1",
  "Snack 2",
  "Snack 3",
  "Snack 4",
];
