import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { CloseIcon } from "stream-chat-react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Stack,
  Typography,
  Box
} from "@mui/material";
import moment from "moment";

import DumbellIcon from "../../assets/clientProfile/dumbellGray.svg";
import MealIcon from "../../assets/clientProfile/mealGray.svg";
import ActivityIconGray from "../../assets/clientProfile/activityGray.svg";

import PlusRoundedIcon from "../../assets/clientProfile/plusIconGray.svg";
import ImportGradientIcon from "../../assets/clientProfile/importIconGradient.svg";
import { funcTodayOrFutureDate } from "@/utils/functions";
import Tooltip from '@mui/material/Tooltip';


const isCardio = (workoutId) => {
  if (typeof workoutId === "string") {
    return workoutId.includes("cardio_workout");
  }
  return false;
};

const AddWorkoutAndMealModal = ({
  showWorkOutBuilder,
  setShowWorkOutBuilder,
  userUUID,
  isThresholdAvailable,
  selectedDate,
  setOpenImportMealModal,
  setOpenCreateMealModal,
  setOpenWorkoutInfoModal,
  setShowAddActivityModal,
  setShowAddCardioModal,
  setExistingCardioInfo,
  clientProfile,
  setShowWorkoutLibrary,
  setIsClientAvailable

}) => {
  const history = useHistory();
  let formattedDate = moment(selectedDate, "DD-MM-YYYY").format("MMM DD");
  console.log({ formattedDate })

  useEffect(() => {
    if (clientProfile?.currentMonthmodules) {
      let date = moment(selectedDate, "DD-MM-YYYY").format("YYYY-MM-DD");
      let todaysWorkouts = clientProfile?.currentMonthmodules?.[date];
      let cardio = todaysWorkouts?.find((workout) =>
        isCardio(workout?.workoutAssigned?.id)
      );
      if (cardio?.workoutAssigned) {
        setExistingCardioInfo(cardio.workoutAssigned);
      }
    }
  }, [clientProfile]);


  console.log({ selectedDate })

  return (
    <Dialog
      open={showWorkOutBuilder}
      onClose={() => {
        setShowWorkOutBuilder(!showWorkOutBuilder);
      }}
      maxWidth={"lg"}
      PaperProps={{
        sx: {
          borderRadius: "20px",
        },
      }}
    >
      <div
        className="add-program-modal-head"
        style={{
          padding: "16px 24px",
        }}
      >
        <p
          className="add-program-modal-head-title"
          style={{
            fontSize: "20px",
            fontFamily: "DM Sans",
            fontWeight: "700",
          }}
        >
          {formattedDate}
        </p>
        <div
          onClick={() => {
            setShowWorkOutBuilder(!showWorkOutBuilder);
          }}
          style={{
            cursor: "pointer",
          }}
        >
          <CloseIcon />
        </div>
      </div>
      <div className="add-program-modal-body">
        <DialogContent
          sx={{ padding: "24px", display: "flex", flexDirection: "row" }}
        >
          <Stack
            direction={"row"}
            gap={2}
            divider={
              <Divider color={"#E5E5E5"} orientation="vertical" flexItem />
            }
          >
            {/* Add Workout  */}
            <div
              style={{
                borderRadius: 12,
                background: "#F8F8F8",
              }}
              className="flex flex-col justify-center items-center gap-[16px] p-[16px]"
            >
              <p className="text-black text-right font-roboto text-14 font-bold leading-normal">
                Workout
              </p>

              <img
                src={DumbellIcon}
                alt="exercise_icon"
                style={{
                  width: 64,
                  height: 64,
                }}
              />

              <div className="flex flex-col justify-center items-center gap-[8px]">
                <button
                  className="button-new gap-[4px] font-DMSans text-font16 font-bold leading-normal"
                  onClick={() => {
                    setShowWorkOutBuilder(false);
                    setOpenWorkoutInfoModal(true);
                  }}
                >
                  <img src={PlusRoundedIcon} />
                  Create
                </button>
                <button
                  className="button-new-outlined gap-[5px] font-DMSans text-font16 font-bold leading-normal"
                  style={{
                    borderWidth: 2,
                    borderRadius: 100,
                  }}
                  onClick={() => {
                    // history.push(
                    //   `/workouts?isImport=${true}&&date=${selectedDate}&&userUUID=${userUUID}&&ita=${isThresholdAvailable}`
                    // );

                    setShowWorkoutLibrary(true)
                    setShowWorkOutBuilder(!showWorkOutBuilder)
                  }}
                >
                  <img src={ImportGradientIcon} />
                  Import
                </button>
              </div>
            </div>
            {/* Add Meal  */}
            <div
              style={{
                borderRadius: 12,
                background: "#F8F8F8",
              }}
              className="flex flex-col justify-center items-center gap-[16px] p-[16px]"
            >
              <p className="text-black text-right font-roboto text-font16 font-bold leading-normal">
                Meal
              </p>

              <img
                src={MealIcon}
                alt="meal_icon"
                style={{
                  width: 64,
                  height: 64,
                }}
              />

              <div className="flex flex-col justify-center items-center gap-[8px]">
                <button
                  className="button-new gap-[4px] font-DMSans text-font16 font-bold leading-normal"
                  onClick={() => {
                    setOpenCreateMealModal(true);
                    setShowWorkOutBuilder(false);
                  }}
                >
                  <img src={PlusRoundedIcon} />
                  Create
                </button>
                <button
                  className="button-new-outlined gap-[5px] font-DMSans text-font16 font-bold leading-normal"
                  style={{
                    borderWidth: 2,
                    borderRadius: 100,
                  }}
                  onClick={() => {
                    setOpenImportMealModal(true);
                    setShowWorkOutBuilder(false);
                  }}
                >
                  <img src={ImportGradientIcon} />
                  Import
                </button>
              </div>
            </div>
            {/* Add activity  */}
            <div
              style={{
                borderRadius: 12,
                background: "#F8F8F8",
              }}
              className="flex flex-col justify-start items-center gap-[16px] p-[16px]"
            >
              <p className="text-black text-right font-roboto text-14 font-bold leading-normal">
                Habits
              </p>

              <img
                src={ActivityIconGray}
                alt="meal_icon"
                style={{
                  width: 64,
                  height: 64,
                }}
              />

              <div className="flex flex-col justify-center items-center gap-[8px]">
                <button
                  className="button-new gap-[4px] font-DMSans text-font16 font-bold leading-normal"
                  onClick={() => {
                    setShowAddActivityModal(true);
                    setShowWorkOutBuilder(false);
                  }}
                >
                  <img src={PlusRoundedIcon} />
                  Add
                </button>
              </div>
            </div>

            {/* Add Cardio */}
            <div
              style={{
                borderRadius: 12,
                background: "#F8F8F8",
              }}
              className="flex flex-col justify-start items-center gap-[16px] p-[16px]"
            >
              <p className="text-black text-right font-roboto text-14 font-bold leading-normal">
                Cardio
              </p>

              <img
                src={
                  "https://img.icons8.com/external-stick-figures-gan-khoon-lay/51/737373/external-cardio-gym-workout-equipments-stick-figures-gan-khoon-lay.png"
                }
                alt="meal_icon"
                style={{
                  width: 64,
                  height: 64,
                }}
              />

              <div className="flex flex-col justify-center items-center gap-[8px]">
                <button
                  className="button-new gap-[4px] font-DMSans text-font16 font-bold leading-normal"
                  onClick={() => {
                    setShowAddCardioModal(true);
                    setShowWorkOutBuilder(false);
                  }}
                >
                  <img src={PlusRoundedIcon} />
                  Add
                </button>
              </div>
            </div>
          </Stack>
        </DialogContent>
        {
          (funcTodayOrFutureDate(moment(selectedDate, "DD-MM-YYYY").format("YYYY-MM-DD"))) &&
          <DialogActions sx={{ width: '100%', justifyContent: 'flex-start', marginLeft: '2rem' }}>
            <Tooltip title={'Mark days on the calendar when your client isn’t available'} placement="top">

              <button onClick={() => {
                setIsClientAvailable(true);
                setShowWorkOutBuilder(false)
              }}
                className="button-new px-6 py-1 font-DMSans text-font14 font-bold leading-normal text-nowrap"
                style={{ width: 'fit-content' }}
              >Manage Client Availability
              </button>
            </Tooltip>
          </DialogActions>
        }
      </div>

    </Dialog>
  );
};

export default AddWorkoutAndMealModal;
