import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showBackButton: true,
  isNextFinish: false,
  isAlertDialog: false,
  tourRun: false,
  tourIndex: 0,
  pageStatus: {
    home: {
      skipped: false,
      finished: false,
    },
    chat: {
      skipped: false,
      finished: false,
    },
    library: {
      skipped: false,
      finished: false,
    },
    nutrition: {
      skipped: false,
      finished: false,
    },
  },
  isLibraryOpen: false,
  isNutritionOpen: false,
};

const popupStateSlice = createSlice({
  name: "popup",
  initialState,
  reducers: {
    setIsLibraryOpen: (state, action) => {
      state.isLibraryOpen = action.payload;
    },
    setIsNutritionOpen: (state, action) => {
      state.isNutritionOpen = action.payload;
    },
    setTourIndex: (state, action) => {
      state.tourIndex = action.payload;
    },
    setTourRun: (state, action) => {
      state.tourRun = action.payload;
    },
    setPageSkippedStatus: (state, action) => {
      console.log(action.payload);
      state.pageStatus = {
        ...state.pageStatus,
        [action.payload.page]: {
          ...state.pageStatus?.[action.payload.page],
          skipped: action.payload.status,
        },
      };
    },
    setPageFinishedStatus: (state, action) => {
      state.pageStatus = {
        ...state.pageStatus,
        [action.payload.page]: {
          ...state.pageStatus?.[action.payload.page],
          finished: action.payload.status,
        },
      };
    },
    setPageStatus: (state, action) => {
      state.pageStatus = action.payload;
    },
    setShowBackButton: (state, action) => {
      state.showBackButton = action.payload;
    },
    setIsNextFinish: (state, action) => {
      state.isNextFinish = action.payload;
    },
    setIsAlertFormDialog: (state, action) => {
      console.log({ one: 1, bool: action.payload })
      state.isAlertDialog = action.payload;
      console.log({ two: 2 })
    }
  },
});

export const {
  setIsLibraryOpen,
  setIsNutritionOpen,
  setTourIndex,
  setTourRun,
  setPageSkippedStatus,
  setPageFinishedStatus,
  setShowBackButton,
  setIsNextFinish,
  setPageStatus,
  setIsAlertFormDialog
} = popupStateSlice.actions;

export default popupStateSlice.reducer;
