import { memo, useEffect, useState } from "react";
import { GripVertical } from "lucide-react";
import { cn } from "@/lib/utils";
import DropDownMenu from "../Programs/DropDownMenu";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const OneExercise = ({
  exercise,
  index,
  onDeleteExercise,
  onExerciseSelect,
  isSelected,
  selectedIntensityType,
  onExerciseDuplicate,
}) => {
  const [intensityValues, setIntensityValues] = useState({});
  const [isDistanceSelected, setIsDistanceSelected] = useState(false);
  const [isLapButtonPressEnabled, setIsLapButtonPressEnabled] = useState(false);

  useEffect(() => {
    if (selectedIntensityType && exercise) {
      setIntensityValues(getIntensityValues(selectedIntensityType, exercise));
      setIsDistanceSelected(exercise?.specifiedProperty === "distance");
      setIsLapButtonPressEnabled(exercise?.lapButtonPress);
    }
  }, [selectedIntensityType, exercise]);

  return (
    <div
      className={cn("w-full rounded-xl bg-white-pure p-2 cursor-pointer", {
        "bg-gradient-to-r from-[#464feb1a] to-[#8330e91a]": isSelected,
      })}
      onClick={onExerciseSelect}
    >
      <div className={"flex items-center w-full rounded-xl gap-2"}>
        {isSelected && (
          <div
            name="active-bar"
            className="bg-gradient-to-r from-[#464FEB] to-[#8330E9] w-[3px] h-[32px] rounded-tl-sm rounded-tr-md rounded-br-md rounded-bl-sm"
          />
        )}
        <GripVertical className="w-[24px] h-[24px] text-[#828282]" />
        {/* <img
          src={"https://placehold.co/400"}
          height={48}
          width={48}
          className="object-cover rounded-xl"
        /> */}
        <div className="flex w-full items-center justify-between">
          <div>
            <p className="text-font16 font-medium text-black-pure">
              {exercise?.name}
            </p>
          </div>
          <div className="flex items-center gap-2">
            {!isDistanceSelected ? (
              <div className="flex h-10 px-4 py-1.5 items-center gap-2.5 rounded-xl border border-gray-300 bg-white-pure">
                <p className="text-gray-700 text-sm font-medium not-italic leading-normal font-DMSans">
                  {formatSecondsToHHMMSS(exercise?.durationInSeconds)}
                </p>
              </div>
            ) : (
              <div className="flex h-10 px-4 py-1.5 items-center gap-2.5 rounded-xl border border-gray-300 bg-white-pure">
                <p className="text-gray-700 text-sm font-medium not-italic leading-normal font-DMSans">
                  {(+exercise?.distance || 0)?.toFixed(2)}
                  <span className="text-[#828282] text-font14 font-medium font-DMSans">
                    &nbsp;{distanceUnitsMap[exercise?.distanceUnit]}
                  </span>
                </p>
              </div>
            )}

            <div className="flex h-10 px-4 py-1.5 items-center gap-2.5 rounded-xl border border-gray-300 bg-white-pure">
              <p className="text-gray-700 text-sm font-medium not-italic leading-normal font-DMSans">
                {intensityValues?.min} - {intensityValues?.max}
                <span className="text-[#828282] text-font14 font-medium font-DMSans">
                  &nbsp;{intensityValues?.unit}
                </span>
              </p>
            </div>

            <DropDownMenu
              triggerElement={
                <div className="h-10 px-2 py-1.5 bg-white-pure rounded-xl">
                  <MoreVertIcon />
                </div>
              }
              options={["Delete", "Duplicate"]}
              onSelect={(option) => {
                if (option === "Delete") {
                  onDeleteExercise();
                } else if (option === "Duplicate") {
                  onExerciseDuplicate();
                }
              }}
            />
          </div>
        </div>
      </div>
      {isLapButtonPressEnabled && (
        <p className="text-[#828282] text-font14 font-medium font-DMSans mt-2 ml-6">
          Press lap button to advance
        </p>
      )}
      {exercise?.movementInstr && (
        <p className="text-[#828282] text-font14 font-medium font-DMSans mt-2 ml-6">
          Note: {exercise?.movementInstr}
        </p>
      )}
    </div>
  );
};

export default memo(OneExercise);

const getIntensityValues = (selectedIntensityType, exercise) => {
  switch (selectedIntensityType) {
    case "precentThresholdPaceRangeInMinPerKm":
      return {
        min: exercise?.precentThresholdPaceRangeInMinPerKm?.min,
        max: exercise?.precentThresholdPaceRangeInMinPerKm?.max,
        unit: "% Pace",
        zone: exercise?.precentThresholdPaceRangeInMinPerKm?.heartRateZone,
      };
    case "precentThresholdHeartRateRangeInBpm":
      return {
        min: exercise?.precentThresholdHeartRateRangeInBpm?.min,
        max: exercise?.precentThresholdHeartRateRangeInBpm?.max,
        unit: "% THR",
        zone: exercise?.precentThresholdHeartRateRangeInBpm?.heartRateZone,
      };
    case "precentMaximumHeartRateRangeInBpm":
      return {
        min: exercise?.precentMaximumHeartRateRangeInBpm?.min,
        max: exercise?.precentMaximumHeartRateRangeInBpm?.max,
        unit: "% MHR",
        zone: exercise?.precentMaximumHeartRateRangeInBpm?.heartRateZone,
      };
    case "percentFTPRangeInW":
      return {
        min: exercise?.percentFTPRangeInW?.min,
        max: exercise?.percentFTPRangeInW?.max,
        unit: "% FTP",
        zone: exercise?.percentFTPRangeInW?.powerZone,
      };
  }
};

const distanceUnitsMap = {
  Kilometers: "km",
  Meters: "m",
  Miles: "mi",
  Yards: "yds",
};

const formatSecondsToHHMMSS = (seconds) => {
  const hours = Math.floor(seconds / 3600)
    .toString()
    .padStart(2, "0");
  const minutes = Math.floor((seconds % 3600) / 60)
    .toString()
    .padStart(2, "0");
  const remainingSeconds = Math.round(seconds % 60)
    .toString()
    .padStart(2, "0");
  return `${hours}:${minutes}:${remainingSeconds}`;
};

const convertDistanceFromOneUnitToAnother = (distance, from, to) => {
  const distanceInMeters = distance * distanceUnitToMeter[from];
  // if distance has more then two zeros after decimal point, then round it to two decimal points and if the twoo zeros are zeros only then convert it to intege
  if (distanceInMeters % 1 === 0) {
    return distanceInMeters / distanceUnitToMeter[to];
  }

  return distanceInMeters / distanceUnitToMeter[to];
};

const distanceUnitToMeter = {
  mi: 1609.34,
  m: 1,
  km: 1000,
  yds: 0.9144,
};
