import {
  connectItemToFolder,
  createFolder,
  deleteFolder,
  disconnectItemFromFolder,
  getAllFolder,
  shiftItemBetweenFolder,
  updateFolderDetails,
} from "@/ApiServices/Workouts/Api";
import React, { useContext, useEffect } from "react";
import { createContext, useState } from "react";
import { store } from "@/redux/store";
import { CustomAlertContext } from "@/App";
import { useSelector } from "react-redux";
import axios from "axios";
import { SpurfitCircularProgress } from "@/components/Dashboard/dashboard";
import Fuse from "fuse.js";
import WorkoutInfoDialog from "@/components/WorkoutComponent/WorkoutInfoDialog";
import { ShowCopilotContext } from "@/components/layout/SpurfitLayout";
import { boolean } from "yup";
import WorkoutBuilderModal from "@/components/WorkoutComponent/WorkoutBuilderModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const workoutContext = createContext();

export const WorkoutProvider = ({ children }) => {
  const { displayAlert } = useContext(CustomAlertContext);
   const { copilotOpen, setCopilotOpen } = useContext(ShowCopilotContext);
  const coachID = useSelector((state) => state.auth?.trainerUUID);
  const adminID = useSelector((state) => state.auth?.data?.adminID);
  const [showFolderTab, setShowFolderTab] = useState(false);
  const [folders, setFolders] = useState([]);
  const [tempFolderData, setTempFolderData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForWorkout,setIsLoadingForWorkout]=useState(false)
  const [openFolderId, setOpenFolderId] = useState("");
  const [folderSelected, setFolderSelected] = useState({});
  const [data, setData] = useState([]);
  const [workoutSelected, setWorkoutSelected] = useState({});
  const [dialogToSelectFolder, setDialogToSelectFolder] = useState(false);
  const [isShifting, setIsShifting] = useState("");
  const [itemToShift, setItemToShift] = useState({});
  const [originalData, setOriginalData] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [openWorkoutBuilder, setOpenWorkoutBuilder] = useState(false);
  const [showWorkoutBuilderModal,setShowWorkoutBuilderModal]=useState(false)
    const [allTags, setAllTags] = useState([]);
      const [selectedFilterOptions, setSelectedFilterOptions] = useState([]);
  const [workoutInLocalStorage,setWorkoutInLocalStorage]=useState(null)
   const [showDuplicateWorkoutModal, setShowDuplicateWorkoutModal] =
     useState(false);
     const [showContinueEditing, setShowContinueEditing] = useState(false);
    const checkForUnsavedWorkout=useSelector((state)=> state?.data.recheckForUnsavedWorkout)
     const history=useHistory()
  useEffect(() => {
    getAllTheFolders();
    
  }, []);

  useEffect(()=>{
    const workoutInLocalStoragedata = localStorage.getItem("workoutData");
  
    if(workoutInLocalStoragedata){
      setWorkoutInLocalStorage(workoutInLocalStoragedata)
      setShowContinueEditing(true)
    }else{
      setShowContinueEditing(false)
    }
  },[checkForUnsavedWorkout])
  

  const getAllTheFolders = async () => {
    setIsLoading(true);

    try {
      let result = await getAllFolder(coachID, adminID);
      if (result) {
        setFolders([...result]);
        setTempFolderData([...result]);
        if (result?.length > 0) {
          setFolderSelected(result[0]);
          setOpenFolderId(result[0]?.id)
        } 
        
        setIsLoading(false);
        return result;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const createNewFolder = async (name, description) => {
    let data = {
      name,
      coachID,
      description,
    };
    setIsLoading(true);
    try {
      let res = await createFolder(data);
      if (res) {
        let newFolder = { ...res };
        let updatedFolders = [newFolder, ...folders];

        setFolders(updatedFolders);
        setTempFolderData(updatedFolders);
        setFolderSelected(newFolder);
        setOpenFolderId(newFolder?.id);
        setWorkoutSelected({})
        displayAlert({
          message: "Folder Added successfully",
          type: "success",
        });
        setIsLoading(false);
        return res;
      }
    } catch (error) {
      displayAlert({
        message: "There is some error",
        type: "error",
      });
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const updateFolder = async (data) => {
    setIsLoading(true);
    console.log(data, "this is data for the");
    try {
      let res = await updateFolderDetails(data);
      if (res) {
        displayAlert({
          message: "Folder update successfully",
          type: "success",
        });
        setIsLoading(false);

        return true;
      }
    } catch (error) {
      displayAlert({
        message: "There is some error In Updating",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const folderDelete = async (folderID) => {
    setIsLoading(true);
    let data = {
      id: folderID,
      coachID: coachID,
    };

    try {
      let res = await deleteFolder(data);
      if (res) {
        displayAlert({
          message: "Folder delete successfully",
          type: "success",
        });

        setFolders((prev) => {
          let data = [...prev];
          let newFolderData = data?.filter((folder) => folder.id !== folderID);
          setTempFolderData(newFolderData);
          return newFolderData;
        });

        if (folders?.length > 1) {
          setFolderSelected((prev) => {
            if (prev.id === folders?.[0]?.id) {
              setOpenFolderId(folders?.[1]?.id);
              return folders?.[1];
            } else {
              setOpenFolderId(folders?.[0]?.id);
              return folders?.[0];
            }
          });
        } else {
          setFolderSelected({});
          if(originalData?.length >0){
            setWorkoutSelected(originalData[0])
          }
        }
      }

      setIsLoading(false);
    } catch (error) {
      displayAlert({
        message: "There is some error In Updating",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const moveItemToFolder = async (payload) => {
    setIsLoading(true);
    try {
      let res = await connectItemToFolder(payload);
      if (res) {
        displayAlert({
          message: "Item Is  Added To Folder Successfully",
          type: "success",
        });
        setDialogToSelectFolder(false);
        return true;
      }
    } catch (error) {
      displayAlert({
        message: "There is some error",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const deleteItemFromTheFolder = async (folder, item) => {
    setIsLoading(true);
    let data = {
      folderID: folder?.id,
      workoutIDs: [item?.id],
    };
    try {
      let res = await disconnectItemFromFolder(data);
      if (res) {
        setFolders((prev) => {
          let oldData = [...prev];
          const newFolderData = oldData?.map((folder) => {
            if (folder?.id === folderSelected?.id) {
              if (folder?.workouts) {
                const workoutArr = folder.workouts;
                const newWorkoutArr = workoutArr?.filter(
                  (workout) => workout?.id !== item?.id
                );
                folder.workouts = newWorkoutArr;
                return folder;
              }
              return folder;
            }
            return folder;
          });
          setTempFolderData(newFolderData);
          return newFolderData;
        });

        setOriginalData((prev) => {
          const prevTemplates = [...prev];
          let workoutData = { ...item };

          if (workoutData?.workoutFolderId) {
            workoutData.workoutFolderId = null;
          }
          prevTemplates.unshift(workoutData);
          setTempData(prevTemplates);
          return prevTemplates;
        });
        setWorkoutSelected({});
        displayAlert({
          message: "Removed From the Folder successfully",
          type: "success",
        });
        setIsLoading(false);

        return true;
      }
    } catch (error) {
      displayAlert({
        message: "There is some error In  removing from the Folder",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const transferItemBetWeenTheFolder = async (
    sourceFolder,
    targetFolder,
    item
  ) => {
    setIsLoading(true);
    try {
      let data = {
        sourceFolderID: sourceFolder?.id,
        targetFolderID: targetFolder?.id,
        workoutIDs: [item?.id],
      };

      const res = await shiftItemBetweenFolder(data);

      if (res) {
        setFolders((prev) => {
          let oldData = [...prev];
          const newFolderData = oldData?.map((folder) => {
            if (folder?.id === sourceFolder?.id) {
              if (folder?.workouts) {
                const workoutArr = folder.workouts;
                const newWorkoutArr = workoutArr?.filter(
                  (workout) => workout?.id !== item?.id
                );
                folder.workouts = newWorkoutArr;
                return folder;
              }
              return folder;
            } else if (folder?.id === targetFolder?.id) {
              if (folder?.workouts) {
                const workoutArr = folder.workouts;
                workoutArr?.push(item);
                folder.workouts = workoutArr;
              } else {
                folder["workouts"] = [item];
              }
              return folder;
            }
            return folder;
          });
          setTempFolderData(newFolderData);
          return newFolderData;
        });

        displayAlert({
          message: "Item Transferd From Folder Is successfully",
          type: "success",
        });
        setIsLoading(false);
        return true;
      }
    } catch (error) {
      console.log(error);
      displayAlert({
        message: "There is some error",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const unSelectFolder = () => {
    setFolderSelected({});
    setOpenFolderId("");
  };

  const deleteWorkout = async (item) => {
    setIsLoading(true);
    axios({
      method: "post",
      url: `${
        store.getState().auth.apiURL
      }CF/api/CoachWeb/Delete/deleteWorkoutWithAllDetailsJSON`,
      data: {
        workoutIDs: [item?.id],
      },
    })
      .then((res) => {
        displayAlert({
          message: "Workout deleted successfully",
          type: "success",
        });
        if (typeof item?.workoutFolderId === "string") {
          
          setFolders((prev) => {
            let prevData = [...prev];
            let newData = prevData?.map((folder) => {
              if (folder?.id === folderSelected?.id) {
                if (folder?.workouts) {
                  let oldWorkoutsData = [...(folder.workouts || [])];
                  let newWorkoutData = oldWorkoutsData?.filter(
                    (workout) => workout?.id !== item?.id
                  );
                  folder.workouts = newWorkoutData;
                  if(newWorkoutData?.length>0){
                    setWorkoutSelected(newWorkoutData[0])
                  }else{
                    setWorkoutSelected({})
                  }
                  return folder;
                }
                
                 
              }
              return folder;
            });
         
            setTempFolderData(newData);
            return newData;
          });
         
          setIsLoading(false);
          return;
        }
        setOriginalData((prev) => {
          let data = [...prev];
          let newWorkoutData = data?.filter(
            (workout) => workout.id !== item?.id
          );
          setTempData(newWorkoutData);
          if(newWorkoutData?.length > 0){
           setWorkoutSelected(newWorkoutData[0]);
          }else {
            setWorkoutSelected({})
          }
          return newWorkoutData;
        });
        
      })
      .catch((err) => {
        if (err.response.data.error === "P2003") {
          displayAlert({
            message: err?.response?.data?.message,
            type: "error",
          });
        } else {
          displayAlert({
            message: "An error occurred while deleting workouts.",
            type: "error",
          });
        }
      })
      .finally(() => setIsLoading(false));
  };

  const requestSearch = async (searchQuery) => {
    try {
      if (!searchQuery || searchQuery == "") {
        cancelSearch();
        return;
      }

      setIsLoading(true);

      const filteredWorkout =
        originalData?.filter((workout) =>
          workout?.name?.toLowerCase().includes(searchQuery.toLowerCase())
        ) || [];


      const filteredfolder =
        folders?.map((folder) =>{
          if(folder?.name?.toLowerCase().includes(searchQuery.toLowerCase())){
             return folder
          }
          else if(folder?.workouts?.length > 0){
                  let folderData={...folder}
           
        let filteredData=  folderData?.workouts?.filter((workout) =>
              workout?.name?.toLowerCase().includes(searchQuery.toLowerCase())
            )||[]
            if(filteredData?.length >0){
              folderData.workouts=filteredData
              return folderData
            }else{
              return null
            }
          }

          return null
           
           
    }).filter(Boolean) || [];

      setTempFolderData(filteredfolder);
      setTempData(filteredWorkout);

      setIsLoading(false);
    } catch (error) {
      console.error(error);
      displayAlert({
        message: "An error occurred while searching. Please try again later.",
        type: "error",
      });
      setIsLoading(false);
    }
  };

  const cancelSearch = async () => {
    try {
      setIsLoading(true);

      setTempData(originalData);
      setTempFolderData(folders);

      setIsLoading(false);
    } catch (error) {
      console.error(error);
      displayAlert({
        message: "An error occurred while searching. Please try again later.",
        type: "error",
      });
      setIsLoading(false);
    }
  };

  // setting workout data for follow along video workout
  function handleDataSettingInTable(res) {
    setData([res, ...data]);
    setOriginalData([res, ...originalData]);
  }

  let unsavedWorkout = JSON.parse(workoutInLocalStorage || "{}");
  const onResumeEditing = () => {
    if (
      !unsavedWorkout?.isBlockWorkoutBuilder &&
      (unsavedWorkout?.workoutKind === "run" ||
        unsavedWorkout?.workoutKind === "bike")
    ) {
    
      history.push("/EnduranceWorkoutBuilder", {
        retriveWorkout: true,
      });
    } else {
      setShowWorkoutBuilderModal(true)
      // history.push("/workoutBuilder", {
      //   state: {
      //     data: unsavedWorkout,
      //     isUpdate: unsavedWorkout?.id ? true : false,
      //   },
      // });
    }
  };

  return (
    <workoutContext.Provider
      value={{
        setShowFolderTab,
        showFolderTab,
        createNewFolder,
        folders,
        setFolders,
        openFolderId,
        setOpenFolderId,
        folderSelected,
        setFolderSelected,
        updateFolder,
        folderDelete,
        data,
        setData,
        workoutSelected,
        setWorkoutSelected,
        unSelectFolder,
        deleteWorkout,
        moveItemToFolder,
        setDialogToSelectFolder,
        dialogToSelectFolder,
        deleteItemFromTheFolder,
        transferItemBetWeenTheFolder,
        isShifting,
        setIsShifting,
        itemToShift,
        setItemToShift,
        originalData,
        setOriginalData,
        tempData,
        setTempData,
        requestSearch,
        cancelSearch,
        tempFolderData,
        setTempFolderData,
        setOpenWorkoutBuilder,
        openWorkoutBuilder,
        handleDataSettingInTable,
        showDuplicateWorkoutModal,
        setShowDuplicateWorkoutModal,
        displayAlert,
        setIsLoading,
        isLoading,
        setCopilotOpen,
        copilotOpen,
        setAllTags,
        allTags,
        setSelectedFilterOptions,
        selectedFilterOptions,
        isLoadingForWorkout,
        setIsLoadingForWorkout,
        adminID,
        coachID,
        showContinueEditing,
        setShowContinueEditing,
        onResumeEditing,
        workoutInLocalStorage,
        unsavedWorkout,
      }}
    >
   
      {isLoading && (
        <div className="fixed top-0 left-0 w-full h-full bg-black-pure bg-opacity-50 z-[99999] flex items-center justify-center">
          <SpurfitCircularProgress />
        </div>
      )}
 
              {showWorkoutBuilderModal && (
                  <WorkoutBuilderModal
                    open={showWorkoutBuilderModal}
                    setOpen={setShowWorkoutBuilderModal}
                    data={unsavedWorkout}
                    // reloadProfileForWorkoutBuilderModal={
                    //   reloadProfileForWorkoutBuilderModal
                    // }
                    // setIsOpenForWorKoutInfo={setIsOpen}
                    //  isAssign={true}
                    // isFromClientView={isFromClientView}
                    // enduranceInputType={enduranceInputType}
                    // isStructured={workoutInputType === "structured"}
                    // PropsData={props?.data}
                     //creatFromWorkout={true}
                    // onSuccessOfNewWorkoutForm={onSuccessOfNewWorkoutForm}
                  />
                )}

      {children}
    </workoutContext.Provider>
  );
};
