import { Dialog, DialogActions, DialogContent } from "@mui/material";

import { useState } from "react";
import ReactSelect, { components } from "react-select";

import { CloseRounded } from "@mui/icons-material";
import axios from "axios";
import { useSelector } from "react-redux";
import { SpurfitCircularProgress } from "../../components/Dashboard/dashboard";
import {
  useGetTrainersExercisesByCoachIDQuery,
  useGetTrainersExercisesQuery,
} from "../../services/apiSlice";
import NoVideo from "./assests/NoVideo";
import { getVideoId } from "./exerciseUtil";

const customStyles = {
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "white",
    borderColor: "#ebebeb",
    // boxShadow: state.isFocused ? "0 0 0 1px red" : provided.boxShadow,
    borderRadius: "12px",
    padding: "0px 4px",
    overflowY: "scroll",
    height: "48px",
    "&:hover": {
      border: "2px solid #02a0fc",
    },
    "&:focus": {
      borderColor: "transparent",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? "linear-gradient(90deg, #464feb 10.42%, #8330e9 100%)"
      : "fff",
    color: state.isSelected ? "#fff" : "#fff",
    "&:hover": {
      backgroundColor: state.isSelected ? "transparent" : "#e3e3e3",
      color: state.isSelected ? "#fff" : "#fff",
    },
  }),
};

const AddExerciseModal = ({
  open,
  setOpen,
  info,
  handleShowAlert,
  libraryType,
  setVideoInfo,
  updateExerciseToParticularRound,
  onSuccess = () => null,
}) => {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const reverseObjectToArray = (obj) => {
    if (!obj) {
      return [];
    }
    return Object.entries(obj).map(([key, value]) => ({
      key: parseInt(key),
      label: value,
      value,
    }));
  };

  const [name, setName] = useState(info?.name);
  const [videoLink, setVideoLink] = useState(info?.videoURL);
  const [equipment, setEquipment] = useState(
    reverseObjectToArray(info?.equipmentList) || []
  );

  // replacing skill level with categoryKind variable as we don't have skill level in diffrent type basic, complex, advanced
  const [skillLevel, setSkillLevel] = useState(info?.categoryKind || "");
  const [modality, setModality] = useState(
    reverseObjectToArray(info?.modality) || []
  );
  const [subModality, setSubModality] = useState(
    reverseObjectToArray(info?.subModality) || []
  );
  const [movementFunction, setMovementFunction] = useState(
    reverseObjectToArray(info?.movementFunction) || []
  );
  const [bodyPart, setBodyPart] = useState(
    reverseObjectToArray(info?.movementFunction) || []
  );
  const [subBodyPart, setSubBodyPart] = useState(
    reverseObjectToArray(info?.subBodyPart) || []
  );

  const [loading, setLoading] = useState(false);

  const formatArrayToObject = (arr) => {
    if (!arr) {
      return {};
    }
    return arr.reduce((acc, curr, index) => {
      acc[index + 1] = curr.value;
      return acc;
    }, {});
  };
  function convertShortsLinkToWatchLink(shortsLink) {
    if (!shortsLink) return shortsLink;
    const shortsRegex = /\/shorts\/([^/?]+)/;
    const match = shortsLink.match(shortsRegex);

    if (match && match[1]) {
      const videoId = match[1];
      const watchLink = `https://www.youtube.com/watch?v=${videoId}`;
      console.log("watch link", watchLink);
      return watchLink;
    } else {
      return shortsLink;
    }
  }
  const formatData = (isGlobal) => {
    let trainerDetails = JSON.parse(localStorage.getItem("trainerCredentials"));
    let checkedVideoLink = videoLink;
    if (typeof videoLink === "string") {
      checkedVideoLink = videoLink.trim();
    }
    console.log("video link", checkedVideoLink);
    let data = {
      coachID: isGlobal ? undefined : trainerDetails?.password,
      nameID: isGlobal ? undefined : info?.name || name,
      name: name,
      videoURL: convertShortsLinkToWatchLink(checkedVideoLink),
      videoID: getVideoId(checkedVideoLink),
      equipmentList: formatArrayToObject(equipment),
      bodyPart: formatArrayToObject(bodyPart),
      subBodyPart: formatArrayToObject(subBodyPart),
      movementFunction: formatArrayToObject(movementFunction),
      modality: formatArrayToObject(modality),
      subModality: formatArrayToObject(subModality),
      categoryKind: skillLevel,
    };
    const validURLRegex = /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?$/;

    if (
      checkedVideoLink === null ||
      checkedVideoLink === undefined ||
      checkedVideoLink === "" ||
      !validURLRegex.test(checkedVideoLink)
    ) {
      // data.videoURL = "https://www.youtube.com/watch?v=xqvCmoLULNY";
      // data.videoID = getVideoId("https://www.youtube.com/watch?v=xqvCmoLULNY");
      data.imageLink =
        "https://storage.needpix.com/rsynced_images/coming-3605857_1280.jpg";
    }

    if (info?.id) {
      data.movementID = info?.id;
      console.log("ID sent : ", data.movementID);
    }
    return data;
  };

  console.log("libb: ", libraryType);

  const clearStates = () => {
    setName("");
    setVideoLink("");
    setEquipment("");
    setSkillLevel("");
    setModality("");
    setSubModality("");
    setMovementFunction("");
    setBodyPart("");
    setSubBodyPart("");
  };
  const coachID = useSelector((state) => state.auth?.trainerUUID);

  const { refetch: refetchSpurfitExercises, isUninitialised } =
    useGetTrainersExercisesByCoachIDQuery({ coachID, APIURL });

  const addExercise = () => {
    // api/CF/coachWeb/Create/addMovement
    if (name === "") {
      // toast("Add exercise before submitting!", "error");
      handleShowAlert("Add exercise before submitting!", "error");
      return;
    }
    if (libraryType == 0) {
      axios({
        method: "post",
        url: `${APIURL}CF/api/CoachWeb/Create/addNewGlobalMovement`,
        data: formatData(true),
      })
        .then(async (res) => {
          if (!isUninitialised) {
            refetchSpurfitExercises();
          }

          setOpen(false);
          if (info) {
            handleShowAlert("Updated Successfully!", "success");
          } else {
            handleShowAlert("Added Successfully!", "success");
          }
          clearStates();
        })
        .catch((err) => {
          handleShowAlert("error occured", "error");
          console.log("err: ", err);
          clearStates();
        });
    } else {
      setLoading(true);
      axios({
        method: "post",
        url: `${APIURL}CF/api/CoachWeb/Create/addNewMovement`,
        data: formatData(),
      })
        .then(async (res) => {
          setVideoInfo && setVideoInfo(res.data.result);
          if (!isUninitialised) {
            refetchSpurfitExercises();
          }
          onSuccess(res.data.result);
          console.log(
            `Inside Modal => Here's is the exercise data  : `,
            res.data.result
          );
          updateExerciseToParticularRound &&
            updateExerciseToParticularRound(res.data.result);
          setOpen(false);
          if (info) {
            handleShowAlert("Updated Successfully!", "success");
          } else {
            handleShowAlert("Added Successfully!", "success");
          }
          clearStates();
        })
        .catch((err) => {
          handleShowAlert("error occured", "error");
          console.log("err: ", err);
          clearStates();
          setOpen(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      // fullScreen
      maxWidth="lg"
      PaperProps={{
        sx: {
          // minHeight: "80vh",
          // padding: "10px 40px",
          minWidth: "60vw",
          maxWidth: "60vw",
          borderRadius: "20px",
        },
      }}
    >
      {loading && (
        <div
          className="absolute flex h-full w-full justify-center items-center"
          style={{
            background: "rgba(0,0,0,0.5)",
            zIndex: 9999,
          }}
        >
          <SpurfitCircularProgress />
        </div>
      )}
      <DialogContent sx={{ padding: 0 }}>
        <div className="add-program-modal-head">
          <p className="add-program-modal-head-title">
            {info ? "Update Exercise" : "Create Exercise"}
          </p>
          <CloseRounded
            className="cursor-pointer"
            onClick={() => {
              clearStates();
              setOpen(false);
            }}
          />
        </div>
        <div className="full-width-container" style={{ padding: "24px" }}>
          <div
            className="flex mt-2"
            style={{ justifyContent: "space-between", flexDirection: "row" }}
          >
            <div
              style={{
                width: videoLink?.length > 0 ? "100%" : "100%",
                display: "flex",
                gap: "24px",
              }}
            >
              {videoLink?.length > 0 ? (
                <div className="add-exercise-modal-video">
                  <img
                    className="add-exercise-modal-video"
                    src={
                      `https://vumbnail.com/${getVideoId(
                        videoLink
                      )}_large.jpg` ||
                      "https://upload.wikimedia.org/wikipedia/commons/3/3f/Placeholder_view_vector.svg"
                    }
                    style={{ height: "100%", width: "100%", objectFit: "fill" }}
                  />
                </div>
              ) : (
                <div className="add-exercise-modal-video">
                  <NoVideo />
                  <p className="add-exercise-modal-video-text">
                    Add video link to display thumbnail
                  </p>
                </div>
              )}
              <div style={{ width: "60%" }}>
                <label className="label-new">Exercise*</label>

                <input
                  className="inputfiled-new"
                  style={{ width: "100%" }}
                  label={"Exercise*"}
                  placeholder={"Exercise Name"}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <label className="label-new">Video Link*</label>
                <input
                  className="inputfiled-new"
                  style={{ width: "100%", marginTop: "8px" }}
                  label={"video link*"}
                  placeholder={"Demo Video Link"}
                  value={videoLink}
                  onChange={(e) => setVideoLink(e.target.value)}
                />
              </div>
              {/* <div style={{ width: "100%", marginTop: "8px" }}>
              <label
                htmlFor="inputField"
                style={{ display: "block", fontSize: 10, marginLeft: 12 }}
              >
                Movement Function
              </label>
              <DropDownComponent
                placeholder={"Select movement function"}
                state={movementFunction}
                setState={setMovementFunction}
                options={movementFunctionList}
              />
            </div> */}
            </div>
          </div>
          <div className="flex mt-2">
            <div style={{ width: "50%", marginRight: 12 }}>
              <label className="label-new">Equipment</label>
              <DropDownComponent
                placeholder={"Select Equipment"}
                state={equipment}
                setState={setEquipment}
                options={EquipmentList}
              />
            </div>
            <div style={{ width: "50%" }}>
              <label className="label-new">Skill Level</label>
              <input
                className="inputfiled-new"
                label={"Skill Level"}
                placeholder={"(0-100)"}
                type={"text"}
                value={skillLevel}
                setState={setSkillLevel}
                onChange={(event) => {
                  let newValue = event.target.value;
                  newValue = newValue;

                  if (newValue >= 0 && newValue <= 100) {
                    setSkillLevel(newValue);
                  }
                }}
              />
            </div>
          </div>
          <div className="flex mt-2">
            <div style={{ width: "50%", marginRight: 12 }}>
              <label className="label-new">Modality</label>
              <DropDownComponent
                placeholder={"Select Modality"}
                state={modality}
                setState={setModality}
                options={ModalityList}
              />
            </div>
            <div style={{ width: "50%" }}>
              <label className="label-new">Sub Modality</label>
              <DropDownComponent
                placeholder={"Select Sub Modality"}
                state={subModality}
                setState={setSubModality}
                options={SubModalityList}
              />
            </div>
          </div>
          <div className="flex mt-2"></div>
          <div className="flex mt-2">
            <div style={{ width: "50%", marginRight: 12 }}>
              <label className="label-new">Body Parts</label>
              <DropDownComponent
                placeholder={"Select body part(s)"}
                state={bodyPart}
                setState={setBodyPart}
                options={BodyPartsList}
                menuPlacement={"top"}
              />
            </div>
            <div style={{ width: "50%" }}>
              <label className="label-new">Sub Body Parts</label>
              <DropDownComponent
                placeholder={"Select sub body part(s)"}
                state={subBodyPart}
                setState={setSubBodyPart}
                options={SubBodyPartList}
                menuPlacement={"top"}
              />
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center" }}>
        <button
          className="button-new"
          onClick={addExercise}
          disabled={name?.length < 2}
        >
          Save
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default AddExerciseModal;

const DropDownComponent = ({
  placeholder,
  state,
  setState,
  options,
  menuPlacement,
}) => {
  return (
    <ReactSelect
      styles={customStyles}
      options={options}
      placeholder={placeholder}
      isMulti
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      menuPortalTarget={document.body}
      components={{
        Option,
      }}
      onChange={(selected) => {
        setState(selected);
        console.log({ selected });
      }}
      allowSelectAll={true}
      value={state}
      menuPlacement={menuPlacement ?? "bottom"}
    />
  );
};
const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label className="label-new">{props.label}</label>
      </components.Option>
    </div>
  );
};

export const EquipmentList = [
  { label: "Body Weight", value: "Body Weight" },
  { label: "Dumbbell", value: "Dumbbell" },
  { label: "Bench", value: "Bench" },
  { label: "Kettlebell", value: "Kettlebell" },
  { label: "Resistance Band", value: "Resistance Band" },
  { label: "Pull-up bar", value: "Pull-up bar" },
  { label: "Barbell", value: "Barbell" },
  { label: "Weights", value: "Weights" },
  { label: "Squat rack", value: "Squat rack" },
  { label: "Plates", value: "Plates" },
  { label: "Bike", value: "Bike" },
  { label: "Helmet", value: "Helmet" },
  { label: "Cycling shoes", value: "Cycling shoes" },
  { label: "Water bottle", value: "Water bottle" },
  { label: "Suspension trainer", value: "Suspension trainer" },
  { label: "Plyo box", value: "Plyo box" },
  { label: "Elevated surface", value: "Elevated surface" },
  { label: "Calf Raise Machine", value: "Calf Raise Machine" },
  { label: "Smith Machine", value: "Smith Machine" },
  { label: "Wall or Spotter", value: "Wall or Spotter" },
  { label: "Exercise Mat", value: "Exercise Mat" },
  { label: "Stationary bike", value: "Stationary bike" },
  { label: "Indoor cycling bike", value: "Indoor cycling bike" },
  { label: "GHD machine", value: "GHD machine" },
  {
    label: "Glute-ham developer (GHD) machine",
    value: "Glute-ham developer (GHD) machine",
  },
  { label: "Dumbbell and bench", value: "Dumbbell and bench" },
  { label: "Parallettes", value: "Parallettes" },
  { label: "Rings", value: "Rings" },
  { label: "None (Bodyweight exercise)", value: "None (Bodyweight exercise)" },
  { label: "Sliding discs or towels", value: "Sliding discs or towels" },
  { label: "Optional dumbbells", value: "Optional dumbbells" },
  { label: "Elevated surface for feet", value: "Elevated surface for feet" },
  {
    label: "Cones or markers to designate running path",
    value: "Cones or markers to designate running path",
  },
  { label: "Low rings", value: "Low rings" },
  { label: "Slam ball", value: "Slam ball" },
  { label: "Jump rope", value: "Jump rope" },
  { label: "Weight sled", value: "Weight sled" },
  { label: "Deadlift platform or mats", value: "Deadlift platform or mats" },
  { label: "Lifting straps", value: "Lifting straps" },
  { label: "Weightlifting belt", value: "Weightlifting belt" },
  { label: "Spotter arms", value: "Spotter arms" },
  { label: "Collars", value: "Collars" },
  { label: "Ankle weights", value: "Ankle weights" },
  { label: "Machine", value: "Machine" },
];

export const ModalityList = [
  // { label: "Modality", value: "Modality" },
  { label: "Weightlifting", value: "Weightlifting" },
  {
    label: "Mono (Cardiovascular Endurance)",
    value: "Mono (Cardiovascular Endurance)",
  },
  { label: "Gymnastics", value: "Gymnastics" },
  { label: "Functional Movement", value: "Functional Movement" },
  { label: "Bodyweight", value: "Bodyweight" },
  { label: "Strongman", value: "Strongman" },
  { label: "Bodybuilding", value: "Bodybuilding" },
  { label: "weightlifting", value: "weightlifting" },
  { label: "functional Movement", value: "functional Movement" },
  { label: "gymnastics", value: "gymnastics" },
];

export const SubModalityList = [
  { label: "Bar gymnastics", value: "Bar gymnastics" },
  { label: "Body Building", value: "Body Building" },
  { label: "Bodybuilding", value: "Bodybuilding" },
  { label: "Bodyweight", value: "Bodyweight" },
  { label: "Carrying", value: "Carrying" },
  { label: "Core", value: "Core" },
  { label: "Cycle", value: "Cycle" },
  { label: "Functional Movement", value: "Functional Movement" },
  { label: "Handstand work", value: "Handstand work" },
  { label: "Jumping", value: "Jumping" },
  { label: "Body Weight", value: "Body Weight" },
  { label: "Olympic weightlifting", value: "Olympic weightlifting" },
  { label: "Plyometrics", value: "Plyometrics" },
  { label: "Power Lifting", value: "Power Lifting" },
  { label: "Ring gymnastics", value: "Ring gymnastics" },
  { label: "Row", value: "Row" },
  { label: "Run", value: "Run" },
  { label: "Throwing", value: "Throwing" },
  { label: "Upper body, arm isolation", value: "Upper body, arm isolation" },
  { label: "Weightlifting", value: "Weightlifting" },
];

export const movementFunctionList = [
  { label: "Squatting", value: "Squatting" },
  { label: "Pulling", value: "Pulling" },
  { label: "Pushing", value: "Pushing" },
  { label: "Hinging", value: "Hinging" },
  { label: "Core", value: "Core" },
  { label: "Rotational", value: "Rotational" },
  { label: "Jumping", value: "Jumping" },
  { label: "Total Body Conditioning", value: "Total Body Conditioning" },
  { label: "Cardiovascular fitness", value: "Cardiovascular fitness" },
  { label: "Lunging", value: "Lunging" },
  { label: "Calves", value: "Calves" },
  { label: "Full-Body", value: "Full-Body" },
  { label: "Cardiovascular endurance", value: "Cardiovascular endurance" },
  { label: "Carrying", value: "Carrying" },
  { label: "Balance and stability", value: "Balance and stability" },
  { label: "Shoulders", value: "Shoulders" },
  { label: "Squatting and Core", value: "Squatting and Core" },
  { label: "Hanging", value: "Hanging" },
  { label: "Running", value: "Running" },
  { label: "Rotational Movement", value: "Rotational Movement" },
  { label: "Knee extension", value: "Knee extension" },
  { label: "Shoulder raise", value: "Shoulder raise" },
  { label: "Squat and throw", value: "Squat and throw" },
  { label: "Handstand hold and walk", value: "Handstand hold and walk" },
  { label: "Core stabilization", value: "Core stabilization" },
];

export const BodyPartsList = [
  { label: "Lower body", value: "Lower body" },
  { label: "Upper Body", value: "Upper Body" },
  { label: "Whole Body", value: "Whole Body" },
  { label: "Upper body,core", value: "Upper body,core" },
  { label: "Lower body, Back", value: "Lower body, Back" },
  { label: "Core", value: "Core" },
  { label: "Arms (Biceps, Forearms)", value: "Arms (Biceps, Forearms)" },
  { label: "Arms (Triceps)", value: "Arms (Triceps)" },
  { label: "Shoulders", value: "Shoulders" },
  {
    label: "Upper body, Lower body, Core",
    value: "Upper body, Lower body, Core",
  },
  { label: "Lower body and Core", value: "Lower body and Core" },
  {
    label: "Upper body, Back (Latissimus Dorsi, Trapezius, Rhomboids)",
    value: "Upper body, Back (Latissimus Dorsi, Trapezius, Rhomboids)",
  },
  {
    label: "Upper body, Shoulders (Deltoids, Rotator Cuff)",
    value: "Upper body, Shoulders (Deltoids, Rotator Cuff)",
  },
  { label: "Lowerbody", value: "Lowerbody" },
  { label: "upper body", value: "upper body" },
];

export const SubBodyPartList = [
  { label: "Quadriceps", value: "Quadriceps" },
  { label: "Hamstrings", value: "Hamstrings" },
  { label: "Glutes", value: "Glutes" },
  { label: "Arms (Biceps)", value: "Arms (Biceps)" },
  { label: "Chest", value: "Chest" },
  {
    label: "Shoulders (Anterior Deltoids)",
    value: "Shoulders (Anterior Deltoids)",
  },
  {
    label: "Shoulders (Lateral Deltoids)",
    value: "Shoulders (Lateral Deltoids)",
  },
  {
    label: "Abdominals (Rectus Abdominis)",
    value: "Abdominals (Rectus Abdominis)",
  },
  {
    label: "Abdominals (Transverse Abdominis)",
    value: "Abdominals (Transverse Abdominis)",
  },
  { label: "Shoulders (Deltoids)", value: "Shoulders (Deltoids)" },
  { label: "Core", value: "Core" },
  { label: "Shoulders (Rotator Cuff)", value: "Shoulders (Rotator Cuff)" },
  { label: "Back (Latissimus Dorsi)", value: "Back (Latissimus Dorsi)" },
  { label: "Back (Trapezius)", value: "Back (Trapezius)" },
  { label: "Back (Rhomboids)", value: "Back (Rhomboids)" },
  { label: "Calves", value: "Calves" },
  { label: "Arms (Triceps)", value: "Arms (Triceps)" },
  {
    label: "Lower Back (Erector Spinae)",
    value: "Lower Back (Erector Spinae)",
  },
  { label: "Lower Back (Multifidus)", value: "Lower Back (Multifidus)" },
  { label: "Forearms", value: "Forearms" },
  { label: "Cardiovascular fitness", value: "Cardiovascular fitness" },
  { label: "Upper Body", value: "Upper Body" },
  { label: "Lower Body", value: "Lower Body" },
  {
    label: "Balance and stability (Ankle)",
    value: "Balance and stability (Ankle)",
  },
  {
    label: "Balance and stability (Knee)",
    value: "Balance and stability (Knee)",
  },
  {
    label: "Balance and stability (Hip)",
    value: "Balance and stability (Hip)",
  },
  {
    label: "Balance and stability (Spine)",
    value: "Balance and stability (Spine)",
  },
  { label: "Legs", value: "Legs" },
];
