import { Box, Stack, Divider, Select, MenuItem } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Exercise from "./Exercise";

import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import {
  formatSecondsToMMSS,
  onRoundsChange,
} from "../../../components/WorkoutComponent/helpers";

const Round = ({
  index,
  round,
  setRounds,
  isEndurance,
  workout,
  thresholdValues,
  isRun,
}) => {
  const isAMRAP = round?.type === "AMRAP";
  const isEMOM = round?.type === "EMOM";

  const isWarmUpOrMixedSets =
    round?.type === "Warm Up" || round?.type === "Mixed Sets";
  return (
    <>
      <Box
        sx={{
          minHeight: "100px",
          borderRadius: "12px",
          backgroundColor: "white",
          overflow: "hidden",
        }}
      >
        <div className="section-header">
          <div className="section-header-left">{round?.type}</div>

          <div
            style={{
              gap: "16px",
            }}
            className="section-header-right"
          >
            {round?.runTime && (isAMRAP || isEMOM) && (
              <div>
                {/* <label
                  style={{
                    color: "#828282",
                    fontFeatureSettings: "'clig' off, 'liga' off",
                    fontFamily: "DM Sans",
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "15px",
                    marginLeft: "4px",
                  }}
                >
                  Duration
                </label> */}

                <div
                  name="runTime"
                  style={{
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                    borderRadius: "8px",
                    border: "1px solid #CCC",
                    background: "#FFF",
                    width: "80px",
                    paddingLeft: "8px",
                    paddingRight: "8px",
                  }}
                  className="flex items-center justify-between"
                >
                  <input
                    type="number"
                    placeholder="MM"
                    style={{
                      width: "50%",
                      marginRight: 2,
                      outline: "none",
                      fontSize: 14,
                      fontFamily: "DM Sans",
                      fontWeight: 500,
                      fontStyle: "normal",
                      lineHeight: "21px",
                      color: "#242424",
                      textAlign: "center",
                    }}
                    value={Math.floor(+round?.runTime / 60)}
                  />
                  <p
                    style={{
                      marginBottom: "2px",
                      fontSize: "14px",
                      color: "#242424",
                    }}
                  >
                    :
                  </p>
                  <input
                    type="number"
                    placeholder="SS"
                    disabled={true}
                    style={{
                      width: "50%",
                      marginLeft: 2,
                      outline: "none",
                      fontSize: 14,
                      fontFamily: "DM Sans",
                      fontWeight: 500,
                      fontStyle: "normal",
                      lineHeight: "21px",
                      color: "#242424",
                      textAlign: "center",
                    }}
                    value={+round?.runTime % 60}
                  />
                </div>
              </div>
            )}
            {!isWarmUpOrMixedSets &&
              !(isEndurance && round?.numRounds === 1) && (
                <div
                  style={{
                    height: "40px",
                    display: "flex",
                    height: "40px",
                    alignItems: "center",
                    gap: "4px",
                    borderRadius: "8px",
                    border: "1px solid #CCC",
                    background: "#FFF",
                    paddingLeft: 12,
                    paddingRight: 12,
                  }}
                  className="flex items-center"
                >
                  <p
                    style={{
                      color: "#424242",
                      fontFeatureSettings: "'clig' off, 'liga' off",
                      fontFamily: "DM Sans",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "normal",
                    }}
                  >
                    {isEndurance && (
                      <span
                        style={{
                          color: "#828282",
                          fontFeatureSettings: "'clig' off, 'liga' off",
                          fontFamily: "DM Sans",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "normal",
                        }}
                      >
                        Repeat: &nbsp;
                      </span>
                    )}
                    {round?.numRounds}{" "}
                    {!isEndurance && (
                      <span
                        style={{
                          color: "#828282",
                          fontFeatureSettings: "'clig' off, 'liga' off",
                          fontFamily: "DM Sans",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "normal",
                        }}
                      >
                        Set
                        {round?.numRounds > 1 ? "s" : ""}
                      </span>
                    )}
                  </p>{" "}
                </div>
              )}
          </div>
        </div>

        <Stack
          sx={{
            padding: "24px 16px",
          }}
          gap={"16px"}
        >
          {round?.measurements?.length > 0 &&
            round?.measurements?.map((m, exerciseIndex) => {
              let measurement = m.measurement;
              if (measurement?.movementName === "BREAK") return null;
              return (
                <>
                  <Stack gap={"16px"}>
                    <Exercise
                      key={exerciseIndex}
                      measurement={measurement}
                      isEndurance={isEndurance}
                      workout={workout}
                      thresholdValues={thresholdValues}
                      isRun={isRun}
                    />

                    {round.measurements.length !== exerciseIndex + 1 && (
                      <ShowRest
                        measurement={measurement}
                        index={exerciseIndex}
                      />
                    )}
                  </Stack>
                </>
              );
            })}
        </Stack>
      </Box>
    </>
  );
};

export const ShowRest = ({ measurement }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        height: "32px",
        width: "100%",
      }}
    >
      {measurement?.break?.duration && (
        <button
          style={{
            borderRadius: "20px",
            width: "130px",
            zIndex: 1,
            backgroundColor: "white",
            height: "32px",
            gap: 8,
          }}
          className="button-new-outlined-rounded"
        >
          <p className="rounded-btn-text">
            {formatSecondsToMMSS(measurement?.break?.duration)}
          </p>
        </button>
      )}
      <Divider
        absolute
        sx={{
          top: "50%",
          left: "50%",
          height: "1px",
          transform: "translate(-50%, -50%)",
          zIndex: 0,
        }}
      />
    </Box>
  );
};

export default Round;
