import {
  Autocomplete,
  CircularProgress,
  Dialog,
  Slide,
  TextField,
} from "@mui/material";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

import { ArrowLeft } from "lucide-react";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { getMomentDataForDateRange } from "@/ApiServices/ChartsLibrary/Api";
import { getAllExerciseForStrengthWorkout } from "@/ApiServices/Exercise/Api";
import { useSelector } from "react-redux";

import { getMovementData } from "./dataForMovementHistory";
import StrengthDashboardGraphs from "./StrengthDashboardGraphs";
import { SpurfitCircularProgress } from "../Dashboard/dashboard";
import A from "@/pages/client";
import { CustomAlertContext } from "@/App";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function StrengthDashboard({
  openStrengthDashboard,
  handleStrengthDashboardClose,
  trainerUUID,
  userInfo,
  activeUser,
}) {
  const [user, setUser] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState("Last Month");
  const [toDate, setToDate] = useState(moment().toDate());
  const [fromDate, setFromDate] = useState(
    moment().subtract(1, "month").toDate()
  );
  const [loadingForExercise, setLoadingForExercise] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allExercise, setAllExercise] = useState([]);
  const [oneMovement, setOneMovement] = useState({});
  const [chartView, setChartView] = useState([]);
  const dateData = ["Last Week", "Last Month", "Last 3 Months", "Custom Date"];
  const coachID = useSelector((state) => state?.auth?.trainerUUID);
  const { displayAlert } = useContext(CustomAlertContext);
  const setUserInitially = () => {
    // find user in active user
    let user = activeUser.find((user) => user.UserUUID == userInfo?.id);
    setUser(user);
  };
  const handleClick = (event) => {
    setOpen(!open);
  };
  const handleDateRange = (rangeType) => {
    console.warn("handle date type", rangeType);
    setValue(rangeType);
    //   setSelectValue(rangeType)
    switch (rangeType) {
      case "Last Week":
        setToDate(moment().toDate());
        setFromDate(moment().subtract(7, "days").toDate());
        break;
      case "Last Month":
        setToDate(moment().toDate());
        console.warn(toDate, "todate");
        console.warn(fromDate, "fromdate");
        setFromDate(moment().subtract(1, "month").toDate());
        break;
      case "Last 3 Months":
        setToDate(moment().toDate());
        setFromDate(moment().subtract(3, "month").toDate());
        break;
      default:
        // For Custom Date, do nothing here
        break;
    }
  };
  const handleNameChange = (user) => {
    console.log(user);
    setUser(user);
  };
  useEffect(() => {
    setUserInitially();
  }, [activeUser, userInfo?.id]);

  useEffect(() => {
    async function getMoments() {
      setLoadingForExercise(true);
      try {
        const allExercises = await getAllExerciseForStrengthWorkout(
          user?.UserUUID
        );
        if (allExercises?.result) {
          setAllExercise(allExercises?.result);
          setLoadingForExercise(false);
        }
      } catch (error) {
        displayAlert({
          message: "Error in getting exercises done by user",
          type: "error",
        });
      } finally {
        setLoadingForExercise(false);
      }
    }
    getMoments();
  }, [user]);

  const handleApply = async () => {
    setLoading(true);
    setOpen(false);

    if (oneMovement?.trackedMovement?.id && user?.UserUUID) {
      const res = await retriveMomentData(
        user?.UserUUID,
        oneMovement?.trackedMovement?.id
      );
      console.log("this is the response of the momente history", res);

      let movementData = res?.result;
      let chartviewData = getMovementData(fromDate, toDate, movementData);
      console.log("chartView Data", chartviewData);
      setChartView(chartviewData);
    }
    setLoading(false);
  };

  const retriveMomentData = async (userId, momentId) => {
    let res = await getMomentDataForDateRange(userId, momentId);
    console.log(res, "FETCHING DATA TO GET RETRIEVE");

    if (res) {
      console.log(res, "if");
      // setActivityData(res);
      // setGarminData(res);
      // updateChartViewData(res);
      // if (!chartView || chartView.length == 0) {
      //   addDefaultChartView(res);
      // }
    }
    return res;
  };

  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <div
      onClick={onClick}
      style={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        width: "100%",
      }}
    >
      <CalendarMonthIcon style={{ fontSize: "16px", color: "#000" }} />
      <input
        className="react-datepicker__day"
        ref={ref}
        value={value}
        readOnly
        style={{
          width: "90%",
          height: "40px",
          outline: "none",
          color: "#000",
          textAlign: "center",
          fontSize: 14,
          background: "#fff",
          borderRadius: "4px",
        }}
      />
    </div>
  ));

  return (
    <Dialog
      fullScreen
      open={openStrengthDashboard}
      onClose={handleStrengthDashboardClose}
      TransitionComponent={Transition}
    >
      <div
        style={{
          height: "77px",
          width: "100vw",
          paddingLeft: "30px",
          paddingRight: "32px",
          backgroundColor: "#f8f8f8",
          position: "relative",
        }}
      >
        <div
          className="add-program-modal-head"
          style={{
            height: "100%",
            width: "100%",
            backgroundColor: "#FFFFFF",
            borderRadius: "0px 0px 16px 16px",
            display: "flex",
            alignItems: "center",
            boxShadow: "0px 2px 5px -1px rgba(0,0,0,0.13)",
          }}
        >
          <div className="flex items-center justify-evenly">
            <div className="flex items-center">
              <ArrowLeft
                sx={{
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                  left: 38,
                  cursor: "pointer",
                }}
                onClick={() => handleStrengthDashboardClose()}
              />
              <p
                style={{
                  marginLeft: "32px",
                }}
                className="add-program-modal-head-title"
              >
                <Autocomplete
                  value={user}
                  getOptionLabel={(option) => option.Name}
                  onChange={(_, newValue) => handleNameChange(newValue)}
                  onInputChange={(value) =>
                    console.log(value, "inoput change value")
                  }
                  defaultValue={user?.Name}
                  id="combo-box-demo"
                  options={activeUser}
                  // options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                  // PaperComponent={(props) => <CustomPaper {...props} />}
                  sx={{
                    width: 200,
                    fontSize: 10,
                    "& .MuiAutocomplete-listbox": {
                      maxHeight: "150px",
                      fontSize: "10px", // Change font size of dropdown options
                    },
                    "& .MuiInputBase-root": {
                      borderRadius: "8px",
                      border: "1px solid rgba(131, 48, 233, 0.20)",
                      height: 40,
                      padding: "0 14px",
                      backgroundColor: "#FFF",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: "8px",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "rgba(131, 48, 233, 0.20)",
                      },
                    },
                    "& .MuiInputLabel-root": {
                      lineHeight: "40px",
                      transform: "translate(14px, 10px) scale(1)",
                    },
                    "& .MuiAutocomplete-option": {
                      fontSize: "16px", // Change font size of dropdown options
                      "&:hover": {
                        backgroundColor: "rgb(246, 246, 254)", // Change background color on hover
                      },
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select User"
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          height: 40,
                          borderRadius: "8px",
                          border: "1px solid rgba(131, 48, 233, 0.20)",
                          fontSize: 14,
                        },
                      }}
                      InputLabelProps={{
                        ...params.InputLabelProps,
                        shrink: true,
                        style: {
                          fontSize: 14,
                          lineHeight: "40px",
                          top: "-10px",
                          transform: "translate(14px, 10px) scale(1)",
                        },
                      }}
                    />
                  )}
                />
              </p>
            </div>
            <div
              onClick={handleClick}
              style={{ width: 270 }}
              className="flex items-center chat-header-view"
            >
              <div style={{ width: "45%" }}>
                <DatePicker
                  maxDate={new Date()}
                  dateFormat="dd-MMM-yyy"
                  selected={fromDate}
                  disabled={value == "Custom Date" ? false : true}
                  onChange={(date) => setFromDate(date)}
                  customInput={<CustomInput />}
                />
              </div>
              <div
                style={{
                  width: "10%",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <label class="cursor-pointer  text-font14 text-center">
                  -{" "}
                </label>
              </div>
              <div style={{ width: "45%", paddingLeft: 10 }}>
                <DatePicker
                  className="chat-header-view"
                  maxDate={new Date()}
                  dateFormat="dd-MMM-yyy"
                  selected={toDate}
                  disabled={value == "Custom Date" ? false : true}
                  onChange={(date) => setToDate(date)}
                  customInput={<CustomInput />}
                />
              </div>
            </div>
            <div className="flex items-center">
              <Autocomplete
                value={value}
                getOptionLabel={(option) => option}
                onChange={(_, newValue) => handleDateRange(newValue)}
                id="date-range-dropdown"
                options={dateData}
                sx={{
                  width: 200,
                  fontSize: 10,
                  "& .MuiAutocomplete-listbox": {
                    maxHeight: "150px",
                    fontSize: "10px", // Change font size of dropdown options
                  },
                  "& .MuiInputBase-root": {
                    borderRadius: "8px",
                    border: "1px solid rgba(131, 48, 233, 0.20)",
                    height: 40,
                    padding: "0 14px",
                    backgroundColor: "#FFF",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: "8px",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "rgba(131, 48, 233, 0.20)",
                    },
                  },
                  "& .MuiInputLabel-root": {
                    lineHeight: "40px",
                    transform: "translate(14px, 10px) scale(1)",
                  },
                  "& .MuiAutocomplete-option": {
                    fontSize: "16px", // Change font size of dropdown options
                    "&:hover": {
                      backgroundColor: "rgb(246, 246, 254)", // Change background color on hover
                    },
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Date Range"
                    InputProps={{
                      ...params.InputProps,
                      style: {
                        height: 40,
                        borderRadius: "8px",
                        border: "1px solid rgba(131, 48, 233, 0.20)",
                        fontSize: 14,
                      },
                    }}
                    InputLabelProps={{
                      ...params.InputLabelProps,
                      shrink: true,
                      style: {
                        fontSize: 14,
                        lineHeight: "40px",
                        top: "-10px",
                        transform: "translate(14px, 10px) scale(1)",
                      },
                    }}
                  />
                )}
              />
            </div>

            <div className="ml-3">
              {loadingForExercise ? (
                <div className="w-[222px] h-[41px] border-2 rounded-md flex items-center justify-center">
                  <SpurfitCircularProgress />
                </div>
              ) : (
                <CustomSelect
                  triggerClassName={"w-full"}
                  isFolder={true}
                  options={allExercise}
                  placeholder={
                    allExercise?.length > 0
                      ? "Select Any Movement"
                      : "User has not done any exercise"
                  }
                  onValueChange={(movement) => setOneMovement(movement)}
                />
              )}
            </div>
          </div>
          <div className="flex gap-4">
            <button
              className="apply-input__button-gradient ml-2"
              onClick={handleApply}
              variant="contained"
            >
              Apply
            </button>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100vw",
          height: "calc(100vh - 100px)",
          paddingLeft: "30px",
          paddingRight: "32px",
          backgroundColor: "#f8f8f8",
          position: "relative",
          paddingTop: "12px",
        }}
      >
        <div
          style={{
            boxShadow: "0px 2px 5px -1px rgba(0,0,0,0.13)",
          }}
          className=" bg-white-pure rounded-xl"
        >
          {loading ? (
            loading ? (
              <div
                className={"w-full overflow-y-auto"}
                style={{
                  paddingLeft: "30px",
                  paddingRight: "32px",
                  paddingBlock: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "50vw",
                }}
              >
                <CircularProgress size={20} sx={{ color: "#6242EA" }} />
              </div>
            ) : (
              "Get Data"
            )
          ) : (
            <StrengthDashboardGraphs
              chartto={toDate}
              chartfrom={fromDate}
              // activityData={activityData}
              chartView={chartView}
              // setChartView={setChartView}
            />
          )}
        </div>
      </div>
    </Dialog>
  );
}

export default StrengthDashboard;

const CustomSelect = ({
  triggerClassName,
  value,
  onValueChange,
  options,
  placeholder,
  isFolder,
  isOpen,
}) => {
  return (
    <Select
      value={value}
      onValueChange={onValueChange}
      {...(isOpen !== undefined && { open: isOpen })}
    >
      <SelectTrigger className={triggerClassName || "w-80"}>
        <SelectValue
          className="text-font14"
          placeholder={placeholder || "Select"}
        />
      </SelectTrigger>
      <SelectContent className="z-[999999]">
        {/* this is for the normal custom select */}
        {!isFolder &&
          options.map((option, index) => (
            <SelectItem
              className="text-font14 "
              key={index}
              value={option.value}
            >
              {option.label}
            </SelectItem>
          ))}
        {isFolder &&
          options?.map((option, index) => (
            <SelectItem className="text-font14 " key={index} value={option}>
              {option?.trackedMovement?.name}
            </SelectItem>
          ))}
      </SelectContent>
    </Select>
  );
};
