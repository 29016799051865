import { store } from "@/redux/store";
import APIURL from "@/APIDetails";
import { getDeviceId } from "@/utils/authUtils";
import axios from "axios";

export const updateCoachDetails = async (payload) => {
  try {
    const res = await axios({
      method: "post",
      url: `${
        store.getState().auth.apiURL
      }CF/api/CoachWeb/Update/UpdateCoachFields`,
      data: payload,
    });
    const { data, status } = res;
    if (status === 200 && data) {
      return res.data;
    }
    return null;
  } catch (error) {}
};

export const logOutCoach = async (trainerUUID) => {
  try {
    const res = await axios({
      method: "post",
      url: `${store.getState().auth.apiURL}api/handleLogoutTrainerBrowser`,
      data: {
        deviceID: getDeviceId(),
        trainerUUID: trainerUUID,
      },
    });
  } catch (error) {}
};
