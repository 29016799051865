import Layout from "@/components/layout/Layout";
import axios from "axios";
import { LTTB } from "downsample";
import FitParser from "fit-file-parser";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import MapPlot from "./components/MapPlot";
import SummaryInfo from "./components/SummaryInfo";
import WorkoutMatrix from "./components/WorkoutMatrix";
import ChartStacked from "./components/chartStacked";

const Analyzed = () => {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const [data, setData] = useState(null);
  const [summaryData, setSummaryData] = useState(null);
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [records, setRecords] = useState([]);
  const [xAxisDataKey, setXAxisDataKey] = useState("elapsed_time");

  const [selectedLap, setSelectedLap] = useState({});
  const [simplified, setSimplified] = useState([]);

  const [selectedWindows, setSelectedWindows] = useState({
    // key : [[start, end], [start, end]]
  });

  // get url from query params
  const urlParams = new URLSearchParams(window.location.search);
  const computedMetrics = location?.state?.computedMetrics;

  const getAxiosDataAsArrayBuffer = async () => {
    setLoading(true);
    setSummaryData(location.state?.data);
    if (location?.state?.fitData) {
      setData(location.state?.fitData);
      setLoading(false);
      return;
    }

    const url = urlParams.get("url");

    const response = await axios({
      url: `${APIURL}CF/api/garmin/getS3FileByUrl`,
      method: "POST",
      data: { url },
    });

    var fitParser = new FitParser({
      force: true,
      speedUnit: "km/h",
      lengthUnit: "km",
      temperatureUnit: "kelvin",
      elapsedRecordField: true,
      mode: "both",
    });

    // Parse your file
    fitParser.parse(response.data.result.data, function (error, data) {
      // Handle result of parse method
      if (error) {
        console.log(error);
      } else {
        console.log("Fit file parsed Data: ", {
          data,
          recs: data?.records,
        });
        // onChange(data, file);

        let records = data?.records;

        let calculatingPaceBySpeed = true;
        let gettingEnhancedCadence = true;
        let dataPrepForLTTB = records?.map((record, index) => {
          // In addition to the x and y keys (so LTTB func doesn't fail,
          // also let it smooth out additional data points for this FIT file.
          let updatedRec = { x: record.elapsed_time, y: 0, ...record };
          if (location?.state?.data.activityType === "RUNNING") {
            if (updatedRec.cadence) {
              // convert spm to rpm
              updatedRec.cadence = record.cadence * 2;
            } else if (updatedRec.enhanced_cadence) {
              updatedRec.cadence = record.enhanced_cadence * 2;
            }
          }
          let val = updatedRec.speed;
          if (isNaN(val)) {
            calculatingPaceBySpeed = false;
          }
          if (!calculatingPaceBySpeed) {
            val = updatedRec.enhanced_speed;
          }
          if (val && !isNaN(val)) {
            updatedRec.pace = 60 / val;
            if (updatedRec.pace >= 15 && index - 1 > 0) {
              let previousRecPace = records[index - 1]?.speed
                ? 60 / records[index - 1].speed
                : 10;
              updatedRec.pace = previousRecPace;
            }
          } else {
            updatedRec.pace = 0;
          }
          return updatedRec;
        });

        let simplified = LTTB(dataPrepForLTTB, 200);
        setSimplified(simplified);

        setData({
          fileName: "1712649246543.fit",
          data: data,
        });
      }
    });

    setLoading(false);
    // const reader = new FileReader();
    // reader.readAsArrayBuffer(response.data.result.data);
    // reader.onload = (e) => {

    // };
  };

  // Handle dropzone change
  const handleChange = (data, file) => {
    setData({ fileName: file.name, data: data });

    // Log the data for inspection
    
  };

  // Reset data
  const startOver = () => {
    setData(null);
  };

  // Framer motion variants for subtle animation
  const variants = {
    hidden: { opacity: 0, y: 24 },
    show: { opacity: 1, y: 0 },
  };

  useEffect(() => {
    getAxiosDataAsArrayBuffer();
  }, []);

  const history = useHistory();

  const setStartAndEndIndexByLap = (lapIndices) => {
     
    if (lapIndices?.[0] === -1) {
      setRecords(data?.data?.records);
      let startTimestamp = data?.data?.records?.[0]?.timestamp;
      let endTimestamp =
        data?.data?.records?.[data?.data?.records?.length - 1].timestamp;

      // convert time stamp to seconds
      startTimestamp = new Date(startTimestamp).getTime() / 1000;
      endTimestamp = new Date(endTimestamp).getTime() / 1000;

      setSelectedWindows({
        ...selectedWindows,
        ["lap"]: [[startTimestamp, endTimestamp]],
      });
      return;
    } else if (lapIndices?.length == 0) {
      setSelectedWindows({
        ...selectedWindows,
        ["lap"]: [],
      });
    } else {
      let timeStamps = [];
      lapIndices.forEach((index) => {
        let records = data?.data?.laps?.[index]?.records;
        let startTimestamp = records?.[0]?.timestamp;
        let endTimestamp = records?.[records.length - 1]?.timestamp;

        // convert time stamp to seconds
        startTimestamp = new Date(startTimestamp).getTime() / 1000;
        endTimestamp = new Date(endTimestamp).getTime() / 1000;

        timeStamps.push([startTimestamp, endTimestamp]);
      });
      console.log("timeStamps: ", timeStamps);

      setSelectedWindows({
        ...selectedWindows,
        ["lap"]: timeStamps,
      });
    }
   
     
  };

  // const setStartAndEndIndexByLapForDistance = (lapIndices) => {
  //   if (lapIndices?.[0] === -1) {
  //     setRecords(data?.data?.records);
  //     let startDistance = data?.data?.records?.[0]?.distance;
  //     let endDistance =
  //       data?.data?.records?.[data?.data?.records?.length - 1].distance;

     

  //     setSelectedWindows({
  //       ...selectedWindows,
  //       ["lap"]: [[startDistance, endDistance]],
  //     });
  //     return;
  //   } else if (lapIndices?.length == 0) {
  //     setSelectedWindows({
  //       ...selectedWindows,
  //       ["lap"]: [],
  //     });
  //   } else {
  //     let distance = [];
  //     lapIndices.forEach((index) => {
  //       let records = data?.data?.laps?.[index]?.records;
  //       let startDistance = records?.[0]?.distance;
  //       let endDistance = records?.[records.length - 1]?.distance;

      

  //       distance.push([startDistance, endDistance]);
  //     });
  //     console.log("timeStamps: ", distance);

  //     setSelectedWindows({
  //       ...selectedWindows,
  //       ["lap"]: distance,
  //     });
  //   }
 
  // };

  return (
    <Layout>
      {loading ? (
        <p className="text-center">Loading...</p>
      ) : (
        <div
          className="flex gap-4"
          style={{
            height: "calc(100vh - 80px)",
            overflowY: "auto",
          }}
        >
          <div className="flex flex-col gap-6 lg:w-full">
            <div className="w-full rounded-lg shadow-md p-4 bg-white-pure flex relative">
              <SummaryInfo
                data={summaryData}
                computedMetrics={computedMetrics}
              />
              <p className="font-DMSans text-font14 absolute top-2 right-2">
                {moment(
                  new Date(summaryData?.startTimeInSeconds * 1000)
                ).format("DD/MM/YYYY hh:mm A")}
              </p>
            </div>
            <div className="rounded-lg overflow-hidden">
              {data?.data?.records && (
                <MapPlot
                  parsedData={data?.data?.records}
                  lapData={data?.data?.laps}
                />
              )}
            </div>
            {/* <AllCharts
              data={data?.data}
              records={records}
              selectedLap={selectedLap}
              selectedWindows={selectedWindows}
              setSelectedWindows={setSelectedWindows}
            /> */}

            <div>
              <ChartStacked
                data={data?.data}
                recs={records}
                selectedLap={selectedLap}
                selectedWindows={selectedWindows}
                setSelectedWindows={setSelectedWindows}
                simplified={simplified}
                xAxisDataKey={xAxisDataKey}
                setXAxisDataKey={setXAxisDataKey}
              />
            </div>
          </div>
          <div
            className="w-[400px] rounded-lg shadow-lg bg-white-pure"
            style={{
              height: "fit-content",
            }}
          >
            <WorkoutMatrix
              data={summaryData}
              fitData={data?.data}
              selectedLap={selectedLap}
              onLapSelect={setStartAndEndIndexByLap}
              computedMetrics={computedMetrics}
              onPeakSelect={(key, values) => {
                console.log({
                  ...selectedWindows,
                  [key]: values,
                });
                setSelectedWindows({
                  ...selectedWindows,
                  [key]: values,
                });
              }}
            />
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Analyzed;
