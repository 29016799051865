import React from "react";
import EmptyProgramImg from "@/assets/library/NoProgram.png";
import AddProgram from "./AddProgram";
import { useHistory } from "react-router-dom";

const NoProgramSelected = ({
  addProgram,
  selectedTab,
  subs,
  prod,
  setShowAddProductModal,
  setShowSubscriptionModal,
  showSubscriptionModal,
}) => {
  const history = useHistory();
  let isTemplate = selectedTab === "templates";
  return (
    <div
      className={`h-[calc(100vh - 110px)] ${
        subs || prod ? "w-[100%]" : "w-[75%]"
      } flex flex-col items-center justify-center gap-5`}
    >
      <img src={EmptyProgramImg} className="w-[128px] h-[128px]" alt="" />
      <div
        style={{
          textAlign: "center",
        }}
      >
        {prod && (
          <>
            <p className="text-bold">No Products Added</p>
            <p className="text-para">
              There are no products to display, click the below button to add a
              product
            </p>
          </>
        )}
        {subs && (
          <>
            <p className="text-bold">No Subscribers Added</p>
            <p className="text-para">
              There are no subscribers to display, click the below button to add
              a subscriber
            </p>
          </>
        )}
        {!(prod || subs) && (
          <>
            <p
              className="text-gradient"
              style={{
                fontSize: "1.2rem",
                fontWeight: "600",
              }}
            >
              Select any program to view or click the below button to add a
              program.
            </p>
          </>
        )}
      </div>
      {subs && (
        <button
          className="button-new"
          onClick={() => setShowSubscriptionModal(true)}
        >
          Add Subscribers
        </button>
      )}
      {prod && (
        <button
          className="button-new"
          onClick={() => setShowAddProductModal(true)}
        >
          Add Product
        </button>
      )}
      {!(subs || prod) && (
        <AddProgram
          addProgram={addProgram}
          isIcon={false}
          isTemplate={isTemplate}
          key={"add-program"}
        />
      )}
    </div>
  );
};

export default NoProgramSelected;
