import { CloseRounded } from "@mui/icons-material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Avatar, Box, Dialog, DialogContent } from "@mui/material";
import axios from "axios";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { createMealPlanWithID } from "../../ApiServices/Meals/Api";
import { SearchIcon } from "../../pages/getStreamChat/assets";
import { store } from "../../redux/store";

const SelectMealsModal = ({
  setUnsavedChanges,
  showModal,
  setShowModal,
  workOutFor,
  setmealDetails,
}) => {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const [searched, setSearched] = useState("");
  const [meals, setmeals] = useState([]);
  const [tempData, setTempData] = useState([]);

  const requestSearch = (searchedVal) => {
    console.log("value", searchedVal);
    setSearched(searchedVal);
    const filteredRows = tempData.filter((row) => {
      return row.reference_name
        .toLowerCase()
        .includes(searchedVal.toLowerCase());
    });
    setmeals(filteredRows);
    //  setRowsPerPage(filteredRows);
  };
  const cancelSearch = () => {
    setSearched("");
    setmeals(tempData);
  };
  function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(12)
    );
  }

  const handleItemSelect = (item) => {
    setmealDetails((prevState) => {
      // Create a copy of the state using the spread operator
      const newState = { ...prevState };
      let newID = uuidv4();
      let updatedItem = { ...item };
      updatedItem.id = newID;
      updatedItem.isCoachMealPlan = false;

      createMealPlanWithID(updatedItem, newID);

      // Add the new key-value pair to the newState
      newState[workOutFor] = [
        {
          dayMealPlan: updatedItem,
          dayIdx: workOutFor,
        },
      ];

      return newState;
    });
    setShowModal(false);
  };

  const getMealsByCoachId = async (mealid) => {
    await axios({
      method: "get",
      url: `${APIURL}CF/api/meal/getMealsByCoachId`,
      params: {
        coachId: store.getState().auth.trainerUUID,
      },
    })
      .then((res) => {
        console.log("res ====>>> ", res);
        const filteredRows = res.data.meals.filter((row) => {
          return row?.plandetails?.calories > -1;
        });
        setmeals(filteredRows);
        setTempData(filteredRows);
        // setIsLoading(false);
      })
      .catch((err) => {
        // setIsLoading(false);
        console.log(["err ===>>> ", err]);
      });
  };

  const getInitials = (string) => {
    const names = string?.split(" ");
    if (_.isEmpty(names)) return;

    let initials = names[0].substring(0, 1).toUpperCase();
    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  useEffect(() => {
    getMealsByCoachId();
  }, []);
  return (
    <Dialog
      open={showModal}
      sx={{
        backdropFilter: "blur(5px)",
      }}
      PaperProps={{
        sx: {
          minHeight: "70vh",
          maxHeight: "70vh",
          //   maxHeight: "90vh",
          borderRadius: "20px",
          display: "flex",
          minWidth: "40%",
        },
      }}
    >
      <DialogContent sx={{ padding: 0 }}>
        <div className="add-program-modal-head">
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <p className="add-program-modal-head-title">Select Meal</p>
            {/* {Object.entries(addedWorkouts?.[selectedCard] || []).length > 0 && ( */}
            {/* <p className="sub-text">
              {"programName"} - Day {"selectedCard"}
            </p> */}
            {/* )} */}
          </div>
          <div
            onClick={() => {
              setShowModal(false);
              setSearched("");
              cancelSearch();
            }}
          >
            <CloseRounded
              sx={{
                fontSize: "24px",
                color: "#000",
                cursor: "pointer",
              }}
            />
          </div>
        </div>
        <div className="add-program-modal-body">
          <div
            className="full-width-container"
            style={{
              paddingBottom: "20px",
            }}
          >
            <div
              className="channel-search__input__wrapper"
              style={{
                width: "100%",
              }}
            >
              <div className="channel-search__input__icon">
                <SearchIcon />
              </div>
              <input
                className="channel-search__input__text"
                onChange={(e) => requestSearch(e.target.value)}
                placeholder="Search"
                type="text"
                value={searched}
              />
            </div>
            <Box
              style={{
                display: "flex",
                gap: "8px",
              }}
            >
              <Box
                className="workout-list-container"
                sx={{
                  overflowY: "scroll",
                  maxHeight: "400px",
                  width: "100%",
                }}
              >
                {meals.length > 0 ? (
                  meals.map((data) => (
                    <div
                      className={"select-workouts_item-container"}
                      key={1}
                      onClick={() => {
                        handleItemSelect(data);
                      }}
                      style={{
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        className={"select-workouts_item-container"}
                        style={{ padding: 0, margin: 0 }}
                      >
                        <Avatar
                          sx={{
                            height: "40px",
                            width: "40px",
                          }}
                        >
                          {getInitials(data.reference_name)}
                        </Avatar>
                        <div className="select-workouts_item-text">
                          <span className="select-workouts_item-title">
                            {data.reference_name}
                            {/* <span style={{ fontWeight: 500, fontSize: "14px" }}>
                              |&nbsp;{item.workoutType}
                            </span> */}
                          </span>
                          <span className="select-workouts_item-description">
                            {data?.plandetails?.calories ?? 0} Cals
                          </span>
                        </div>
                      </div>
                      <ArrowForwardIosIcon />
                    </div>
                  ))
                ) : (
                  <p>No Meal Plans</p>
                )}
              </Box>
            </Box>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SelectMealsModal;
