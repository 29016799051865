import React, { useEffect, useRef, useState } from "react";
import Logo from "../../assets/dashboard/logo.svg";
import SpurfitLogo from "../../assets/dashboard/Spufit.svg";
import Home from "../../assets/dashboard/home.svg";
import HomeActive from "../../assets/dashboard/Home-active.svg";
import Chat from "../../assets/dashboard/message.svg";
import Library from "../../assets/dashboard/library.svg";
import Payments from "../../assets/dashboard/payments.svg";
import ChatActive from "../../assets/dashboard/chat-active.svg";
import LibraryActive from "../../assets/dashboard/library-active.svg";
import PaymentsActive from "../../assets/dashboard/payment-active.svg";
import Todo from "../../assets/todos/todo.png";
import TodoActive from "../../assets/todos/todoActive.png";
import { useHistory, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import mealImg from "../../assets/copilot/dietPlanIcon.svg";
import Nutrition from "../../assets/dashboard/nutrition.svg";
import NutritionActive from "../../assets/dashboard/nutrition-active.svg";
import ActiveUsers from "../../assets/dashboard/activeUsers.png";
import { mixPanelInit } from "../../utils/functions";
import mixpanel from "mixpanel-browser";
import "./index.css";
import { getTodos } from "../../ApiServices/ClientProfileApi/Api";
import {
  setPendingTasks,
  toggleInverseTss,
  toggleLayoutWidth,
} from "../../redux/dataSlice";
import { useDispatch } from "react-redux";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { toggle } from "../../redux/checkListSlice";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";
import {
  ChevronsLeft,
  ChevronsRight,
  ChevronDown,
  ChevronUp,
  NotepadText,
} from "lucide-react";
import { cn } from "@/lib/utils";

export default function Sidebar({ isPublic }) {
  const location = useLocation();
  const uuid = useSelector((state) => state.auth.trainerUUID);
  const timeZone = useSelector((state) => state.auth.timeZone);
  const countryCode = useSelector((state) => state.auth?.data?.countryCode);
  const TrainerData = useSelector((state) => state.auth.data);
  const isLibraryOpen = useSelector((state) => state.popupState.isLibraryOpen);
  const isNutritionOpen = useSelector(
    (state) => state.popupState.isNutritionOpen
  );
  const gymAffiliation = useSelector(
    (state) => state.auth?.data?.gymAffliation
  );
  const toggleValue = useSelector((state) => state.toggleCheckList.toggleValue);
  const optionsRef = useRef(null);

  const [openLibrary, setOpenLibrary] = useState(false);

  const [openLibraryItemHover, setOpenLibraryItemHover] = useState("");
  const [openNutrition, setOpenNutrition] = useState(false);
  const [openNutritionItemHover, setOpenNutritionItemHover] = useState("");
  const [todosCount, setTodosCount] = useState(0);
  const [brandLogo, setBrandLogo] = useState(
    TrainerData?.customLogo || SpurfitLogo
  );
  const [isExpanded, setIsExpanded] = useState(false);
  const pendingTasks = useSelector((state) => state.data.tasksPending);
  const unreadMessages = useSelector((state) => state.data.unreadMessages);
  let expandedViewsState = useSelector((state) => state.data.isExpanded);

  useEffect(() => {
    if (expandedViewsState) {
      setIsExpanded(true);
    } else {
      setIsExpanded(false);
    }
  }, [expandedViewsState]);
  const isLivezy = gymAffiliation === "Livezy";
  const isAdmin = useSelector((state) => state.auth?.data?.isAdmin);

  const dispatch = useDispatch();

  useEffect(() => {
    setBrandLogo(TrainerData?.customLogo || SpurfitLogo);
    mixPanelInit();
  }, [TrainerData?.customLogo]);

  const getTodosCount = async (uuid) => {
    let todos = await getTodos({ trainerUUID: uuid });
    todos = todos?.filter((todo) => !todo?.isFinished);
    dispatch(setPendingTasks(todos?.length));
    setTodosCount(todos?.length);
  };

  useEffect(() => {
    if (gymAffiliation === "Livezy") {
      if (uuid) {
        getTodosCount(uuid);
      }
    }
  }, [uuid]);

  const handleMouseEnter = () => {
    setOpenNutrition(true);
  };

  const handleMouseLeave = () => {
    setTimeout(() => {
      if (
        optionsRef.current &&
        !optionsRef.current.contains(document.activeElement)
      ) {
        setOpenNutrition(false);
      }
    }, 500);
  };

  const sidebarData = [
    {
      name: "home",
      icon: Home,
      label: "Home",
      iconActive: HomeActive,
      link: "/home",
    },
    {
      name: "chat",
      icon: Chat,
      label: "Messaging",
      iconActive: ChatActive,
      link: "/clientsView",
      unreadMessages: unreadMessages,
    },
    // {
    //   name: "library",
    //   icon: Library,
    //   iconActive: LibraryActive,
    // },
  ];
  const sidebarDataForExtendedView = [
    {
      name: "home",
      icon: Home,
      label: "Home",
      iconActive: HomeActive,
      link: "/home",
    },
    {
      name: "chat",
      icon: Chat,
      label: "Messaging",
      iconActive: ChatActive,
      link: "/clientsView",
      unreadMessages: unreadMessages,
    },
    // {
    //   name: "library",
    //   icon: Library,
    //   iconActive: LibraryActive,
    // },
  ];

  const libraryData = [
    {
      name: "Programs",
      link: "/programs",
    },
    {
      name: "Workouts",
      link: "/workouts",
    },
    { name: "Exercises", link: "/AllExercises" },
  ];

  const nutritionData = [
    ...(isLivezy
      ? isAdmin
        ? [
            {
              name: "Food Items",
              link: "/nutrition/1",
              id: "food",
            },
          ]
        : []
      : [
          {
            name: "Food Items",
            link: "/nutrition/1",
            id: "food",
          },
          {
            name: "Recipes",
            link: "/nutrition/2",
            id: "recipes",
          },
        ]),
    {
      name: "Meal plans",
      link: "/nutrition/3",
      id: "meal-plan",
    },
    ...(isLivezy
      ? []
      : [
          {
            name: "Meal Schedule",
            link: "/nutrition/4",
            id: "meal-scheduled",
          },
        ]),
  ];

  const formControl = [
    {
      name: "Form control",
      link: "formcontrol/",
      id: "form-control",
    },
  ];
  const history = useHistory();
  const PaymentsEnabledCountries = ["US"];
  return (
    <>
      {/* this is for the normal sidebar */}
      {!isExpanded && (
        <div
          style={{
            width: "96px",
            height: "100%",
            position: "fixed",
            backgroundColor: "#FFFFFF",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            zIndex: 99,
          }}
          onMouseLeave={() => {
            setOpenLibrary(false);
            setOpenNutrition(false);
            setOpenLibraryItemHover("");
            setOpenNutritionItemHover("");
          }}
        >
          <Link to="/home">
            <img
              style={{
                marginTop: "12px",
                width: "54px",
                height: "54px",
                borderRadius: "50%",
              }}
              src={brandLogo}
              alt="logo"
            />
          </Link>

          <div
            style={{
              height: "1px",
              width: "70px",
              backgroundColor: "#CCCCCC",
              marginTop: "10px",
              marginBottom: "24px",
            }}
          />

          {!isPublic &&
            sidebarData.map((data) => {
              if (isLivezy && data.name === "chat") return null;
              return (
                <Link
                  to={`${data?.link}`}
                  className={`nav_option relative ${
                    location.pathname == data.link ? "nav_option_active" : ""
                  }`}
                >
                  {data?.unreadMessages > 0 ? (
                    <sapn className="flex items-center justify-center absolute bg-red-500 rounded-full w-[22px] h-22 top-3 right-3 text-center text-[14px] font-semibold text-white-pure">
                      {data.unreadMessages}
                    </sapn>
                  ) : null}
                  <div className="flex flex-col items-center justify-center gap-[4px]">
                    <img
                      onMouseEnter={() => {
                        setOpenLibrary(false);
                        setOpenNutrition(false);
                        setOpenLibraryItemHover("");
                        setOpenNutritionItemHover("");
                      }}
                      onClick={() => {
                        if (data.name === "chat") {
                          mixpanel.track("Trainer_Chat");
                        }
                        history.push(data.link);
                      }}
                      style={{
                        width: "24px",
                        height: "24px",

                        cursor: "pointer",
                      }}
                      src={
                        location.pathname == data.link
                          ? data.iconActive
                          : data.icon
                      }
                      alt={data.name}
                      id={`sidebar-${data.name}`}
                    />
                    <span
                      style={{
                        background:
                          location.pathname === data.link
                            ? "linear-gradient(to right, #464FEB, #8330E9)"
                            : "none",
                        WebkitBackgroundClip:
                          location.pathname === data.link ? "text" : "none",
                        WebkitTextFillColor:
                          location.pathname === data.link
                            ? "transparent"
                            : "black",
                        backgroundClip:
                          location.pathname === data.link ? "text" : "none",
                        textFillColor:
                          location.pathname === data.link
                            ? "transparent"
                            : "black",
                      }}
                      className="  font-[500] text-[12px]  text-[#404040]"
                    >
                      {data?.label}
                    </span>
                  </div>
                </Link>
              );
            })}

          {!isPublic &&
            (uuid == "PXqGTqMrfblJ" ||
              (countryCode &&
                PaymentsEnabledCountries.includes(countryCode))) && (
              <Link
                to="/stripeConnect"
                onClick={() => {
                  mixpanel.track("Trainer_Payments");
                }}
                className={`nav_option ${
                  location.pathname == "/stripeConnect"
                    ? "nav_option_active"
                    : ""
                }`}
              >
                <div className="flex flex-col   items-center justify-center gap-[4px]">
                  <img
                    style={{
                      width: "24px",
                      height: "24px",
                      cursor: "pointer",
                    }}
                    src={
                      location.pathname == "/stripeConnect"
                        ? PaymentsActive
                        : Payments
                    }
                    alt={"payments"}
                  />
                  <span
                    style={{
                      background:
                        location.pathname == "/stripeConnect"
                          ? "linear-gradient(to right, #464FEB, #8330E9)"
                          : "none",
                      WebkitBackgroundClip:
                        location.pathname == "/stripeConnect" ? "text" : "none",
                      WebkitTextFillColor:
                        location.pathname == "/stripeConnect"
                          ? "transparent"
                          : "black",
                      backgroundClip:
                        location.pathname == "/stripeConnect" ? "text" : "none",
                      textFillColor:
                        location.pathname == "/stripeConnect"
                          ? "transparent"
                          : "black",
                    }}
                    className="font-[500] text-[12px] text-[#404040]"
                  >
                    Payments
                  </span>
                </div>
              </Link>
            )}
          {!isPublic && (
            <div
              onMouseEnter={() => {
                setOpenLibrary(true);
                setOpenNutrition(false);
              }}
              onClick={() => {
                setOpenLibrary(true);
                setOpenNutrition(false);
              }}
              style={{
                position: "relative",
              }}
              className={`nav_option ${
                location.pathname == "/workouts" ||
                location.pathname == "/programs" ||
                location.pathname == "/AllExercises"
                  ? "nav_option_active"
                  : ""
              }`}
            >
              <div className="flex flex-col items-center justify-center gap-[4px]">
                <img
                  style={{
                    width: "24px",
                    height: "24px",
                    cursor: "pointer",
                  }}
                  src={
                    location.pathname == "/workouts" ||
                    location.pathname == "/programs" ||
                    location.pathname == "/AllExercises"
                      ? LibraryActive
                      : Library
                  }
                  alt={"library"}
                  id="sidebar-library"
                />
                <span
                  style={{
                    background:
                      location.pathname == "/workouts" ||
                      location.pathname == "/programs" ||
                      location.pathname == "/AllExercises"
                        ? "linear-gradient(to right, #464FEB, #8330E9)"
                        : "none",
                    WebkitBackgroundClip:
                      location.pathname == "/workouts" ||
                      location.pathname == "/programs" ||
                      location.pathname == "/AllExercises"
                        ? "text"
                        : "none",
                    WebkitTextFillColor:
                      location.pathname == "/workouts" ||
                      location.pathname == "/programs" ||
                      location.pathname == "/AllExercises"
                        ? "transparent"
                        : "black",
                    backgroundClip:
                      location.pathname == "/workouts" ||
                      location.pathname == "/programs" ||
                      location.pathname == "/AllExercises"
                        ? "text"
                        : "none",
                    textFillColor:
                      location.pathname == "/workouts" ||
                      location.pathname == "/programs" ||
                      location.pathname == "/AllExercises"
                        ? "transparent"
                        : "black",
                  }}
                  className=" font-[500] text-[12px]    text-[#404040]"
                >
                  {isLivezy ? "Training" : "Library"}
                </span>
              </div>
              {(openLibrary || isLibraryOpen) && (
                <div
                  onMouseLeave={() => setOpenLibrary(false)}
                  style={{
                    position: "absolute",
                    width: "200px",
                    padding: "0 8px 8px 8px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    alignItems: "flex-start",
                    borderRadius: "0px 12px 12px 0px",
                    background: "#FFF",
                    boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.12)",
                    height: "fit-content",
                    // marginTop: "-54px",
                    top: "0px",
                    left: "90px",
                    // marginLeft: "60px",
                    zIndex: 999,
                  }}
                >
                  <p
                    style={{
                      padding: "11px 20px",
                      alignItems: "center",
                      display: "flex",
                      alignSelf: "stretch",
                      height: "72px",
                      gap: "4px",
                      color: "#727272",
                      textAlign: "center",
                      fontFamily: "DM Sans",
                      fontSize: "13px",
                      fontWeight: "700",
                      lineHeight: "20px" /* 153.846% */,
                      textTransform: "uppercase",
                      borderBottom: "1px solid #E5E5E5",
                    }}
                  >
                    {isLivezy ? "Training" : "LIBRARY"}
                  </p>
                  {libraryData.map((data) => {
                    if (isLivezy && data.name === "Workouts") return null;
                    if (isLivezy && !isAdmin && data.name === "Exercises")
                      return null;
                    return (
                      <Link
                        to={data.link}
                        onClick={() => {
                          mixpanel.track("Trainer_Library");
                        }}
                        style={{
                          width: "100%",
                        }}
                        id={`sidebar-library-${data.name}`}
                      >
                        <p
                          className="sidebar-menu-item"
                          onMouseEnter={() =>
                            setOpenLibraryItemHover(data.link)
                          }
                          onClick={() => {
                            setOpenLibrary(false);
                            // history.push(data.link);
                          }}
                          style={{
                            padding: "11px 20px",
                            alignItems: "center",
                            display: "flex",
                            alignSelf: "stretch",
                            height: "48px",
                            gap: "4px",
                            borderRadius: "8px",
                            cursor: "pointer",
                            background:
                              location.pathname == data.link
                                ? "linear-gradient(90deg, rgba(70, 79, 235, 0.10) 10.42%, rgba(131, 48, 233, 0.10) 100%)"
                                : data.link == openLibraryItemHover
                                ? "#e3e3e3"
                                : "#fff",
                          }}
                          id={`sidebar-library=${data.name}`}
                        >
                          {data.name}
                        </p>
                      </Link>
                    );
                  })}
                </div>
              )}
            </div>
          )}

          {!isPublic && (
            <div
              onMouseEnter={() => {
                setOpenNutrition(true);
                setOpenLibrary(false);
              }}
              onClick={() => {
                setOpenNutrition(true);
                setOpenLibrary(false);
              }}
              style={{
                position: "relative",
              }}
              className={`nav_option ${
                location?.pathname?.includes("/nutrition")
                  ? "nav_option_active"
                  : ""
              }`}
            >
              <div className="flex flex-col items-center justify-center gap-[4px]">
                <img
                  src={
                    location?.pathname?.includes("/nutrition")
                      ? NutritionActive
                      : Nutrition
                  }
                  style={{
                    width: "24px",
                    height: "24px",
                    // marginTop: "48px",
                    cursor: "pointer",
                  }}
                  id="sidebar-nutrition"
                />
                <span
                  style={{
                    background: location?.pathname?.includes("/nutrition")
                      ? "linear-gradient(to right, #464FEB, #8330E9)"
                      : "none",
                    WebkitBackgroundClip: location?.pathname?.includes(
                      "/nutrition"
                    )
                      ? "text"
                      : "none",
                    WebkitTextFillColor: location?.pathname?.includes(
                      "/nutrition"
                    )
                      ? "transparent"
                      : "black",
                    backgroundClip: location?.pathname?.includes("/nutrition")
                      ? "text"
                      : "none",
                    textFillColor: location?.pathname?.includes("/nutrition")
                      ? "transparent"
                      : "black",
                  }}
                  className="  font-[500] text-[12px]   text-[#404040]"
                >
                  Nutrition
                </span>
              </div>
              {(openNutrition || isNutritionOpen) && (
                <div
                  ref={optionsRef}
                  style={{
                    position: "absolute",
                    width: "200px",
                    padding: "0 8px 8px 8px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    alignItems: "flex-start",
                    borderRadius: "0px 12px 12px 0px",
                    background: "#FFF",
                    boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.12)",
                    height: "fit-content",
                    top: "0px",
                    left: "90px",
                    zIndex: 999,
                  }}
                  onMouseLeave={() => setOpenNutrition(false)}
                >
                  <p
                    style={{
                      padding: "11px 20px",
                      alignItems: "center",
                      display: "flex",
                      alignSelf: "stretch",
                      height: "72px",
                      gap: "4px",
                      color: "#727272",
                      textAlign: "center",
                      fontFamily: "DM Sans",
                      fontSize: "13px",
                      fontWeight: "700",
                      lineHeight: "20px" /* 153.846% */,
                      textTransform: "uppercase",
                      borderBottom: "1px solid #E5E5E5",
                    }}
                  >
                    Nutrition
                  </p>
                  {nutritionData.map((data) => (
                    <p
                      id={`sidebar-nutrition-${data.id}`}
                      onMouseEnter={() => setOpenNutritionItemHover(data.link)}
                      onClick={() => {
                        setOpenLibrary(false);
                        history.push(data.link);
                        mixpanel.track("Trainer_Nutrition");
                      }}
                      style={{
                        padding: "11px 20px",
                        alignItems: "center",
                        display: "flex",
                        alignSelf: "stretch",
                        height: "48px",
                        gap: "4px",
                        borderRadius: "8px",
                        cursor: "pointer",
                        background:
                          location.pathname == data.link
                            ? "linear-gradient(90deg, rgba(70, 79, 235, 0.10) 10.42%, rgba(131, 48, 233, 0.10) 100%)"
                            : data.link == openNutritionItemHover
                            ? "#e3e3e3"
                            : "#fff",
                      }}
                    >
                      {data.name}
                    </p>
                  ))}
                </div>
              )}
            </div>
          )}

          {!isPublic &&
            !isLivezy &&
            formControl.map((data) => (
              <div
                onClick={() => {}}
                style={{
                  position: "relative",
                }}
                className={`nav_option ${
                  location?.pathname?.includes("/formcontrol")
                    ? "nav_option_active"
                    : ""
                }`}
              >
                <Link to="/formcontrol">
                  <div className="flex items-center flex-col justify-center">
                    {/* <DynamicFormIcon
                      style={{
                        width: "24px",
                        height: "24px",
                        // marginTop: "48px",
                        cursor: "pointer",
                        color: "#707070",
                      }}
                    /> */}
                    <NotepadText
                      size={24}
                      style={{
                        color: location?.pathname?.includes("/formcontrol")
                          ? "#464FEB"
                          : "#707070",
                      }}
                    />
                    <p
                      className={cn(
                        "font-[500] text-[12px]   text-[#404040] mt-1",
                        {
                          "gradient-text":
                            location?.pathname?.includes("/formcontrol"),
                        }
                      )}
                    >
                      Forms
                    </p>
                  </div>
                </Link>
              </div>
            ))}

          {!isPublic && gymAffiliation === "Livezy" && (
            <Link
              to="/todos"
              className={`relative nav_option flex-col items-center justify-center${
                location.pathname == "/todos" ? "nav_option_active" : ""
              }`}
              onMouseEnter={() => {
                setOpenLibrary(false);
                setOpenNutrition(false);
              }}
            >
              {pendingTasks > 0 ? (
                <sapn className="flex items-center justify-center absolute bg-gray-300 rounded-full w-[22px] h-22 top-3 right-3 text-center text-[14px] font-semibold">
                  {pendingTasks}
                </sapn>
              ) : (
                ""
              )}
              <img
                style={{
                  width: "24px",
                  height: "24px",
                  cursor: "pointer",
                }}
                src={location.pathname == "/todos" ? TodoActive : Todo}
                alt={"todos"}
              />
              <p
                style={{
                  background:
                    location.pathname == "/todos"
                      ? "linear-gradient(to right, #464FEB, #8330E9)"
                      : "none",
                  WebkitBackgroundClip:
                    location.pathname == "/todos" ? "text" : "none",
                  WebkitTextFillColor:
                    location.pathname == "/todos" ? "transparent" : "black",
                  backgroundClip:
                    location.pathname == "/todos" ? "text" : "none",
                  textFillColor:
                    location.pathname == "/todos" ? "transparent" : "black",
                }}
                className="font-[500] text-[12px] mt-[4px]  text-[#404040]"
              >
                Todos
              </p>
            </Link>
          )}
          <button
            className="bg-white-pure text-white-pure mt-auto"
            onClick={() => {
              dispatch(toggleInverseTss());
            }}
            style={{
              height: 20,
              width: 20,
            }}
          >
            Click here
          </button>
          {!isLivezy && location.pathname == "/home" && (
            <div className="mt-auto mb-6 cursor-pointer">
              <TaskAltIcon
                style={{
                  color: toggleValue ? "#673FEA" : "black",
                  fontWeight: "bold",
                }}
                onClick={() => dispatch(toggle())}
              />
            </div>
          )}
          {!isExpanded && (
            <div
              className="absolute bottom-5 -right-[10px] h-[20px] w-[20px] bg-white-pure rounded-full"
              onClick={() => {
                dispatch(toggleLayoutWidth(true));
                setIsExpanded(true);
              }}
            >
              <ChevronsRight />
            </div>
          )}
        </div>
      )}

      {/* this for the expanded sidebar */}
      {isExpanded && (
        <div
          style={{
            width: "200px",
            //  paddingLeft: "1rem",
            // paddingRight: "1rem",
            height: "100%",
            position: "fixed",
            backgroundColor: "#FFFFFF",
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            gap: "2px",
            zIndex: 99,
          }}
          // onMouseLeave={() => {
          //   setOpenLibrary(false);
          //   setOpenNutrition(false);
          //   setOpenLibraryItemHover("");
          //   setOpenNutritionItemHover("");
          // }}
        >
          <div className="w-[256px] h-[77px] py-[21px] px-[30px]">
            <Link to="/home">
              <img
                style={{
                  width: "36px",
                  height: "36px",
                  borderRadius: "50%",
                }}
                src={brandLogo}
                alt="logo"
              />
            </Link>
          </div>
          <div
            style={{
              height: "0.8px",
              width: "168px",
              backgroundColor: "#CCCCCC",
              marginLeft: "16px",
              marginRight: "16px",
            }}
          />
          <div className=" flex flex-col h-[100%]">
            {!isPublic &&
              sidebarDataForExtendedView.map((data) => {
                if (isLivezy && data.name === "chat") return null;
                return (
                  <Link
                    to={`${data?.link}`}
                    className={`nav_optionExpanded hover:bg-[#F2F2F2]`}
                  >
                    <div
                      className="flex gap-[12px] items-center"
                      onClick={() => {
                        if (data.name === "chat") {
                          mixpanel.track("Trainer_Chat");
                        }
                        setOpenLibrary(false);
                        setOpenNutrition(false);
                        history.push(data.link);
                      }}
                    >
                      <img
                        style={{
                          width: "24px",
                          height: "24px",

                          cursor: "pointer",
                        }}
                        src={
                          location.pathname == data.link
                            ? data.iconActive
                            : data.icon
                        }
                        alt={data.name}
                        id={`sidebar-${data.name}`}
                      />
                      <span
                        style={{
                          background:
                            location.pathname === data.link
                              ? "linear-gradient(to right, #464FEB, #8330E9)"
                              : "none",
                          WebkitBackgroundClip:
                            location.pathname === data.link ? "text" : "none",
                          WebkitTextFillColor:
                            location.pathname === data.link
                              ? "transparent"
                              : "black",
                          backgroundClip:
                            location.pathname === data.link ? "text" : "none",
                          textFillColor:
                            location.pathname === data.link
                              ? "transparent"
                              : "black",
                        }}
                        className=" h-[20px] font-[500] text-[14px]  leading-[20px] text-[#404040]"
                      >
                        {data?.label}
                      </span>

                      {data?.unreadMessages > 0 ? (
                        <sapn className="flex items-center justify-center  ml-auto bg-red-500 rounded-full min-w-[22px] h-22 text-center text-[14px] font-semibold text-white-pure">
                          {data.unreadMessages}
                        </sapn>
                      ) : null}
                    </div>
                  </Link>
                );
              })}

            {!isPublic &&
              (uuid == "PXqGTqMrfblJ" ||
                (countryCode &&
                  PaymentsEnabledCountries.includes(countryCode))) && (
                <Link
                  to="/stripeConnect"
                  onClick={() => {
                    setOpenLibrary(false);
                    setOpenNutrition(false);
                    mixpanel.track("Trainer_Payments");
                  }}
                  className={"nav_optionExpanded hover:bg-[#F2F2F2]"}
                >
                  <div className="flex gap-[12px] items-center">
                    <img
                      style={{
                        width: "24px",
                        height: "24px",
                        cursor: "pointer",
                      }}
                      src={
                        location.pathname == "/stripeConnect"
                          ? PaymentsActive
                          : Payments
                      }
                      alt={"payments"}
                    />
                    <span
                      style={{
                        background:
                          location.pathname == "/stripeConnect"
                            ? "linear-gradient(to right, #464FEB, #8330E9)"
                            : "none",
                        WebkitBackgroundClip:
                          location.pathname == "/stripeConnect"
                            ? "text"
                            : "none",
                        WebkitTextFillColor:
                          location.pathname == "/stripeConnect"
                            ? "transparent"
                            : "black",
                        backgroundClip:
                          location.pathname == "/stripeConnect"
                            ? "text"
                            : "none",
                        textFillColor:
                          location.pathname == "/stripeConnect"
                            ? "transparent"
                            : "black",
                      }}
                      className="h-[20px] font-[500] text-[14px]  leading-[20px]  text-[#404040]"
                    >
                      Payments
                    </span>
                  </div>
                </Link>
              )}
            {!isPublic && (
              <div className="flex flex-col cursor-pointer">
                <div
                  onClick={() => {
                    setOpenLibrary(!openLibrary);
                    setOpenNutrition(false);
                  }}
                  // onMouseEnter={() => {
                  //   setOpenLibrary(true);
                  //   setOpenNutrition(false);
                  // }}
                  // className={`nav_option justify-start w-full px-1 items-start ${
                  //   location.pathname == "/workouts" ||
                  //   location.pathname == "/programs" ||
                  //   location.pathname == "/AllExercises"
                  //     ? "nav_option_active bg-transparent"
                  //     : ""
                  // }`}
                  className="nav_optionExpanded hover:bg-[#F2F2F2]"
                >
                  <div className="flex  items-center gap-[12px]">
                    <div className="flex  items-center gap-[12px] w-[148px]">
                      <img
                        style={{
                          width: "24px",
                          height: "24px",
                          cursor: "pointer",
                        }}
                        src={
                          location.pathname == "/workouts" ||
                          location.pathname == "/programs" ||
                          location.pathname == "/AllExercises"
                            ? LibraryActive
                            : Library
                        }
                        alt={"library"}
                        id="sidebar-library"
                      />
                      <span
                        style={{
                          background:
                            location.pathname == "/workouts" ||
                            location.pathname == "/programs" ||
                            location.pathname == "/AllExercises"
                              ? "linear-gradient(to right, #464FEB, #8330E9)"
                              : "none",
                          WebkitBackgroundClip:
                            location.pathname == "/workouts" ||
                            location.pathname == "/programs" ||
                            location.pathname == "/AllExercises"
                              ? "text"
                              : "none",
                          WebkitTextFillColor:
                            location.pathname == "/workouts" ||
                            location.pathname == "/programs" ||
                            location.pathname == "/AllExercises"
                              ? "transparent"
                              : "black",
                          backgroundClip:
                            location.pathname == "/workouts" ||
                            location.pathname == "/programs" ||
                            location.pathname == "/AllExercises"
                              ? "text"
                              : "none",
                          textFillColor:
                            location.pathname == "/workouts" ||
                            location.pathname == "/programs" ||
                            location.pathname == "/AllExercises"
                              ? "transparent"
                              : "black",
                        }}
                        className="h-[20px] font-[500] text-[14px]  leading-[20px]  text-[#404040]"
                      >
                        {isLivezy ? "Training" : "Library"}
                      </span>
                    </div>
                    {!(openLibrary || isLibraryOpen) && (
                      <ChevronDown className="h-[24] w-[24]" />
                    )}
                    {(openLibrary || isLibraryOpen) && (
                      <ChevronUp className="h-[24] w-[24]" />
                    )}
                  </div>
                </div>
                {(openLibrary || isLibraryOpen) && (
                  <div>
                    {libraryData.map((data) => {
                      if (isLivezy && data.name === "Workouts") return null;
                      if (isLivezy && !isAdmin && data.name === "Exercises")
                        return null;
                      return (
                        <Link
                          to={data.link}
                          onClick={() => {
                            mixpanel.track("Trainer_Library");
                          }}
                          id={`sidebar-library-${data.name}`}
                        >
                          <p
                            onMouseEnter={() =>
                              setOpenLibraryItemHover(data.link)
                            }
                            onClick={() => {
                              //setOpenLibrary(false);
                              history.push(data.link);
                            }}
                            style={{
                              // width: "240px",
                              height: "48px",
                              marginLeft: "8px",
                              marginRight: "8px",
                              borderRadius: "8px",
                              marginTop: "4px",
                              padding: "11px 30px 11px 30px",
                              cursor: "pointer",
                              fontWeight: "500",
                              fontFamily: "DM Sans",
                              fontSize: "13px",
                              lineHeight: "20px",
                              color: "#424242",
                              textAlign: "center",
                              background:
                                location.pathname == data.link
                                  ? "linear-gradient(90deg, rgba(70, 79, 235, 0.10) 10.42%, rgba(131, 48, 233, 0.10) 100%)"
                                  : data.link == openLibraryItemHover
                                  ? "#e3e3e3"
                                  : "#fff",
                            }}
                            className="hover:text-[#000000]"
                            id={`sidebar-library=${data.name}`}
                          >
                            {data.name}
                          </p>
                        </Link>
                      );
                    })}
                  </div>
                )}
              </div>
            )}

            {!isPublic && (
              <div className="flex flex-col ">
                <div
                  className="nav_optionExpanded hover:bg-[#F2F2F2]"
                  onClick={() => {
                    setOpenNutrition(!openNutrition);
                    setOpenLibrary(false);
                  }}
                >
                  <div className="flex ">
                    <div className="flex  items-center gap-[12px] w-[128px] cursor-pointer">
                      <div className="flex  items-center gap-[12px] w-[148px]">
                        <img
                          src={
                            location?.pathname?.includes("/nutrition")
                              ? NutritionActive
                              : Nutrition
                          }
                          style={{
                            width: "24px",
                            height: "24px",
                            cursor: "pointer",
                          }}
                          id="sidebar-nutrition"
                        />
                        <span
                          style={{
                            background: location?.pathname?.includes(
                              "/nutrition"
                            )
                              ? "linear-gradient(to right, #464FEB, #8330E9)"
                              : "none",
                            WebkitBackgroundClip: location?.pathname?.includes(
                              "/nutrition"
                            )
                              ? "text"
                              : "none",
                            WebkitTextFillColor: location?.pathname?.includes(
                              "/nutrition"
                            )
                              ? "transparent"
                              : "black",
                            backgroundClip: location?.pathname?.includes(
                              "/nutrition"
                            )
                              ? "text"
                              : "none",
                            textFillColor: location?.pathname?.includes(
                              "/nutrition"
                            )
                              ? "transparent"
                              : "black",
                          }}
                          className="h-[20px] font-[500] text-[14px]  leading-[20px]  text-[#404040]"
                        >
                          Nutrition
                        </span>
                      </div>
                      {!(openNutrition || isNutritionOpen) && (
                        <ChevronDown className="h-[24] w-[24]" />
                      )}
                      {(openNutrition || isNutritionOpen) && (
                        <ChevronUp className="h-[24] w-[24]" />
                      )}
                    </div>
                  </div>
                </div>
                {(openNutrition || isNutritionOpen) && (
                  <div
                    ref={optionsRef}
                    // onMouseLeave={() => setOpenNutrition(false)}
                  >
                    {nutritionData.map((data) => (
                      <p
                        id={`sidebar-nutrition-${data.id}`}
                        onMouseEnter={() =>
                          setOpenNutritionItemHover(data.link)
                        }
                        onClick={() => {
                          // setOpenLibrary(false);
                          history.push(data.link);
                          mixpanel.track("Trainer_Nutrition");
                        }}
                        style={{
                          //   width: "240px",
                          height: "48px",
                          marginLeft: "8px",
                          marginRight: "8px",
                          borderRadius: "8px",
                          marginTop: "4px",
                          padding: "11px 30px 11px 30px",
                          cursor: "pointer",
                          fontWeight: "500",
                          fontFamily: "DM Sans",
                          fontSize: "13px",
                          lineHeight: "20px",
                          color: "#424242",
                          textAlign: "center",
                          background:
                            location.pathname == data.link
                              ? "linear-gradient(90deg, rgba(70, 79, 235, 0.10) 10.42%, rgba(131, 48, 233, 0.10) 100%)"
                              : data.link == openNutritionItemHover
                              ? "#e3e3e3"
                              : "#fff",
                        }}
                      >
                        {data.name}
                      </p>
                    ))}
                  </div>
                )}
              </div>
            )}

            {!isPublic &&
              !isLivezy &&
              formControl.map((data) => (
                <Link
                  to="/formcontrol"
                  className={"nav_optionExpanded hover:bg-[#F2F2F2]"}
                >
                  <div className="flex gap-[12px] items-center">
                    <NotepadText
                      size={24}
                      style={{
                        color: location?.pathname?.includes("/formcontrol")
                          ? "#464FEB"
                          : "#707070",
                      }}
                    />
                    <span
                      style={{
                        background:
                          location.pathname == "/formcontrol"
                            ? "linear-gradient(to right, #464FEB, #8330E9)"
                            : "none",
                        WebkitBackgroundClip:
                          location.pathname == "/formcontrol" ? "text" : "none",
                        WebkitTextFillColor:
                          location.pathname == "/formcontrol"
                            ? "transparent"
                            : "black",
                        backgroundClip:
                          location.pathname == "/formcontrol" ? "text" : "none",
                        textFillColor:
                          location.pathname == "/formcontrol"
                            ? "transparent"
                            : "black",
                      }}
                      className="h-[20px] font-[500] text-[14px]  leading-[20px]  text-[#404040]"
                    >
                      Forms
                    </span>
                  </div>
                </Link>
              ))}

            {!isPublic && gymAffiliation === "Livezy" && (
              <Link
                to={`/todos`}
                className={`nav_optionExpanded hover:bg-[#F2F2F2] relative`}
              >
                <div
                  className="flex gap-[12px] items-center"
                  onMouseEnter={() => {
                    setOpenLibrary(false);
                    setOpenNutrition(false);
                  }}
                >
                  <img
                    style={{
                      width: "24px",
                      height: "24px",
                      cursor: "pointer",
                    }}
                    src={location.pathname == "/todos" ? TodoActive : Todo}
                    alt={"todos"}
                  />
                  <span
                    style={{
                      background:
                        location.pathname === "/todos"
                          ? "linear-gradient(to right, #464FEB, #8330E9)"
                          : "none",
                      WebkitBackgroundClip:
                        location.pathname === "/todos" ? "text" : "none",
                      WebkitTextFillColor:
                        location.pathname === "/todos"
                          ? "transparent"
                          : "black",
                      backgroundClip:
                        location.pathname === "/todos" ? "text" : "none",
                      textFillColor:
                        location.pathname === "/todos"
                          ? "transparent"
                          : "black",
                    }}
                    className=" h-[20px] font-[500] text-[14px]  leading-[20px] text-[#404040] relative"
                  >
                    To do{" "}
                  </span>
                  {pendingTasks > 0 ? (
                    <sapn className="flex items-center justify-center ml-auto bg-gray-300 rounded-full w-[22px] h-22 text-center text-[14px] font-semibold text-[#404040]">
                      {pendingTasks}
                    </sapn>
                  ) : null}
                </div>
              </Link>
            )}
            <button
              className="bg-white-pure text-white-pure mt-auto"
              onClick={() => {
                dispatch(toggleInverseTss());
              }}
              style={{
                height: 20,
                width: 20,
              }}
            >
              Click here
            </button>
            {!isLivezy && location.pathname == "/home" && (
              <div className="nav_optionExpanded   mt-auto  cursor-pointer flex gap-[12px]">
                <TaskAltIcon
                  style={{
                    color: toggleValue ? "#673FEA" : "black",
                    fontWeight: "bold",
                  }}
                  onClick={() => dispatch(toggle())}
                />
                <span className="h-[20px] font-[500] text-[14px]  leading-[20px]">
                  To do
                </span>
              </div>
            )}
            {isExpanded && (
              <div
                className="absolute bottom-5 -right-[10px] h-[20px] w-[20px] bg-white-pure rounded-full"
                onClick={() => {
                  dispatch(toggleLayoutWidth(false));
                  setIsExpanded(false);
                }}
              >
                <ChevronsLeft />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

 
 