import { store } from "@/redux/store";
import axios from "axios";

export const getAllMessageTemplate = async (coachID) => {
  try {
    let res = await axios({
      method: "get",
      url: `${
        store.getState().auth.apiURL
      }CF/api/messageTemplates/getCoachesMessageTemplate`,
      params: { coachID },
    });
    return res?.data?.result;
  } catch (e) {
    console.log(e);
  }
};

export const createMessageTemplate = async (
  coachID,
  templateName,
  templateMessage
) => {
  try {
    let res = await axios({
      method: "post",
      url: `${
        store.getState().auth.apiURL
      }CF/api/messageTemplates/createMessageTemplate`,
      data: { coachID, templateName, templateMessage },
    });
    return res?.data?.result;
  } catch (e) {
    console.log(e);
  }
};

export const updateMessageTemplate = async (
  id,
  templateName,
  templateMessage
) => {
  try {
    await axios({
      method: "post",
      url: `${
        store.getState().auth.apiURL
      }CF/api/messageTemplates/updateMessageTemplate`,
      data: { id, templateName, templateMessage },
    });
  } catch (e) {
    console.log(e);
  }
};

export const deleteMessageTemplate = async (id) => {
  try {
    await axios({
      method: "post",
      url: `${
        store.getState().auth.apiURL
      }CF/api/messageTemplates/deleteMessageTemplate`,
      data: { id },
    });
  } catch (e) {
    console.log(e);
  }
};
