import { AddTaskRounded } from "@mui/icons-material";
import React, { useState } from "react";
import CheckIcon from "../../assets/trainerProfile/checked.svg";
import UncheckedIcon from "../../assets/trainerProfile/unchecked.svg";
import TodoBlock from "./TodoBlock";
import useTodosHook from "./useTodosHook";

const SelfAddedTodo = (props) => {
  const { trainerUUID, selectAll, setSelectAll } = props;
  const [todos, errorMessage, loading, handleTodoAction] = useTodosHook({
    trainerUUID,
  });
  const [primaryKey, setPrimaryKey] = useState();
  const [todo, setTodo] = useState("");
  const [showTextField, setShowTextField] = useState(false);
  const [todoAction, setTodoAction] = useState("add");

  const handleAddTodo = () => {
    handleTodoAction({
      type: todoAction,
      todo,
      todoId: primaryKey,
    });
    setShowTextField(!showTextField);
    setTodo("");
    setTodoAction("add");
  };

  return (
    <>
      <div className="w-full flex justify-between items-center px-4 gap-3">
        <input
          placeholder="Add a new task"
          value={todo}
          onChange={(e) => setTodo(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleAddTodo();
            }
          }}
          className="w-full border rounded-lg h-[40px] px-2 font-DMSans text-font16 text-black-pure outline-none"
        />

        <div className="flex justify-end my-4 gap-4">
          <AddTaskRounded
            onClick={handleAddTodo}
            style={{ cursor: "pointer", color: "rgba(70, 79, 235, 1)" }}
          />
        </div>
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          maxHeight: "calc(100vh - 246px)",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        {todos && todos.length > 0 && (
          <div className="flex flex-col items-center justify-center w-full pb-10">
            <div className="flex items-center justify-between px-4 py-2 w-full">
              <p className="font-DMSans text-font16 text-main-primary font-semibold">
                Total Tasks{" "}
                <span className="font-DMSans text-font14 text-black-pure bg-gray-300 py-1 px-2 rounded-2xl h-21 w-21">
                  {todos.length}
                </span>
              </p>

              <p className="font-DMSans text-font16 text-main-primary font-semibold">
                Completed{" "}
                <span className="font-DMSans text-font14 text-black-pure bg-gray-300 py-1 px-2 rounded-2xl h-21 w-21">
                  {todos.filter((todo) => todo.isFinished).length} of{" "}
                  {todos.length}
                </span>
              </p>
            </div>

            <div
              className="grid gap-6 items-center w-[90%]"
              style={{
                gridTemplateColumns: "30px 1fr 100px 100px 100px",
                borderBottom: "1px solid #D8D6D6",
                padding: "15px 3px",
              }}
            >
              <div>
                <img
                  style={{
                    height: "20px",
                    width: "20px",
                    marginRight: "12px",
                    cursor: "pointer",
                  }}
                  src={selectAll ? CheckIcon : UncheckedIcon}
                  alt=""
                  onClick={() => {
                    setSelectAll(!selectAll);
                  }}
                />
              </div>
              <p
                className="text-black-pure text-font14 font-DMSans w-176 mr-4"
                style={{
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "21px",
                  marginBottom: "3px",
                }}
              >
                Name
              </p>
              <p
                className="text-black-pure text-font14 font-DMSans w-100 mr-2"
                style={{
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "21px",
                  marginBottom: "3px",
                }}
              >
                Date Added
              </p>
              <p
                className="text-black-pure text-font14 font-DMSans w-100 mr-2"
                style={{
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "21px",
                  marginBottom: "3px",
                }}
              >
                Status
              </p>
              <div></div>
            </div>
            {todos.map((item) => (
              <TodoBlock
                key={item?.id}
                todo={item}
                handleTodoAction={handleTodoAction}
                setShowTextField={setShowTextField}
                setTodoAction={setTodoAction}
                setPrimaryKey={setPrimaryKey}
                setTodo={setTodo}
                allSelected={selectAll}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default SelfAddedTodo;
