import React from "react";
import ClientProfile from "../../components/DescriptionComp/ClientProfile";
import Layout from "../../components/layout/Layout";

export default function A(props) {
  return (
    <Layout>
      {/* <div className="flex justify-center items-center"> */}
      <ClientProfile />
      {/* </div> */}
    </Layout>
  );
}
