import {
  getGarminConnect,
  getGarminConnectWellness,
} from "@/ApiServices/Garmin/Api";
import { store } from "@/redux/store";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const Garmin = () => {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const [atheleteID, setAtheleteID] = useState("");
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);

  const getUsers = async () => {
    setLoading(true);
    try {
      const res = await axios({
        method: "get",
        url: `${APIURL}CF/api/CoachWeb/Get/getAllAtheletesOneCoach`,
        params: {
          coachID: store.getState().auth.trainerUUID,
        },
      });

      const activeUsers = res.data.result?.[0]?.atheletes?.filter(
        (item) => item.active == true
      );

      setUsers(activeUsers);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleGarminConnect = async (atheleteID) => {
    setLoading(true);
    let res = await getGarminConnectWellness(atheleteID);
    if (res) {
      let url = res?.url;
      // open the url in this tab
      window.open(url, "_self");
    }
    setLoading(false);
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div className="w-full min-h-screen flex items-center justify-center bg-gray-100">
      <div className="p-8 bg-gray-50 rounded-md shadow-md w-64">
        <h2 className="text-2xl font-bold mb-4 text-gray-800">
          Garmin Wellness Connect
        </h2>
        <p className="mb-4 text-gray-600">
          Connect with your Garmin account to sync your data.
        </p>

        <div className="flex flex-col items-center">
          {users.map((user) => (
            <div
              key={user.id}
              className="flex items-center justify-between w-full bg-white rounded-md p-4 mt-4"
            >
              <div>
                <h3 className="text-lg font-semibold text-gray-800">
                  {user.name}
                </h3>
                <p className="text-gray-600">{user.email}</p>
              </div>
              <button
                className="bg-violet-500 text-white-pure px-4 py-2 rounded-md"
                onClick={() => handleGarminConnect(user.id)}
              >
                {loading ? "Loading..." : "Connect"}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Garmin;
