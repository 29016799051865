import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Chip,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
  TextField,
} from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import CheckedIcon from "../../assets/trainerProfile/checked.svg";
import UncheckedIcon from "../../assets/trainerProfile/unchecked.svg";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import HearRateRangeInput from "./Endurance/HeartRateRangeInput";
import PowerRangeInput from "./Endurance/util/PowerRangeInput";
import EnduranceRangeInput from "./Endurance/util/EnduranceRangeInput";
import CustomSelect from "./Endurance/util/CustomSelect";

export const TimeInput = ({
  incrementRound,
  decrementRound,
  rounds,
  duration,
  setDuration,
  index,
  isSingle,
}) => {
  const secondsRef = useRef(null);
  const [hours, setHours] = useState({});
  const [minutes, setMinutes] = useState({});
  const [seconds, setSeconds] = useState({});

  const minutesRef = useRef(null);
  const hoursRef = useRef(null);

  useEffect(() => {
    if (duration) {
      let hoursObj = {};
      let minutesObj = {};
      let secondsObj = {};
      Object.entries(duration)?.forEach(([key, value]) => {
        hoursObj[key] = Math.floor(+value / 3600);
        minutesObj[key] = Math.floor((+value % 3600) / 60);
        secondsObj[key] = +value % 60;
      });
      setHours(hoursObj);
      setMinutes(minutesObj);
      setSeconds(secondsObj);
    }
  }, [duration, index]);

  return (
    <div>
      {!isSingle && (
        <label
          htmlFor="inputField"
          style={{ display: "block", fontSize: 10, marginLeft: 12 }}
        >
          Set {index + 1}
        </label>
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            overflow: "hidden",
          }}
          className="flex placeholder:px-1 mr-2 rounded-lg border hideIncrement p-1"
        >
          <input
            type="number"
            placeholder="HH"
            min={0}
            style={{
              border: "none",
              outline: "none",
              width: 36,
              textAlign: "center",
            }}
            onFocus={(e) => e.target.select()}
            className="outline-none border-none inline-flex items-center focus:outline-none text-gray-700"
            value={hours?.[index + 1] ? hours?.[index + 1] : undefined}
            onChange={(e) => {
              if (e.target.value.length > 2) return;
              let val = parseInt(e.target.value);
              let Obj = { ...hours };
              Obj[index + 1] = isNaN(val) ? "" : val;
              setHours(Obj);
              let durationObj = { ...duration };
              durationObj[index + 1] = convertToSeconds(
                val,
                minutes?.[index + 1],
                seconds?.[index + 1]
              );
              setDuration(durationObj);

              // Set focus on minutes input field if two values are entered in hours field
              if (e.target.value.toString().length === 2) {
                minutesRef.current.focus();
              }
            }}
          />
          <div style={{ color: "#ccc", marginTop: "8px", marginBottom: "8px" }}>
            :
          </div>
          <input
            type="number"
            placeholder="MM"
            min={0}
            style={{
              border: "none",
              outline: "none",
              width: 36,
              textAlign: "center",
            }}
            className="outline-none border-none inline-flex items-center focus:outline-none text-gray-700"
            onFocus={(e) => e.target.select()}
            value={minutes?.[index + 1] ? minutes?.[index + 1] : undefined}
            ref={minutesRef}
            onChange={(e) => {
              if (e.target.value.length > 2) return;
              let val = parseInt(e.target.value);
              let Obj = { ...minutes };
              Obj[index + 1] = isNaN(val) ? "" : val;
              setMinutes(Obj);
              let durationObj = { ...duration };
              durationObj[index + 1] = convertToSeconds(
                hours?.[index + 1],
                val,
                seconds?.[index + 1]
              );
              setDuration(durationObj);

              // Set focus on seconds input field if two values are entered in minutes field
              if (e.target.value.toString().length === 2) {
                secondsRef.current.focus();
              }
            }}
          />
          <div style={{ color: "#ccc", marginTop: "8px", marginBottom: "8px" }}>
            :
          </div>
          <input
            type="number"
            placeholder="SS"
            min={0}
            style={{
              border: "none",
              outline: "none",
              width: 36,
              textAlign: "center",
            }}
            className="outline-none border-none inline-flex items-center focus:outline-none text-gray-700"
            value={seconds?.[index + 1] ? seconds?.[index + 1] : undefined}
            onFocus={(e) => e.target.select()}
            onChange={(e) => {
              if (e.target.value.length > 2) return;
              let val = parseInt(e.target.value);
              let Obj = { ...seconds };
              Obj[index + 1] = isNaN(val) ? "" : val;
              setSeconds(Obj);

              let durationObj = { ...duration };
              durationObj[index + 1] = convertToSeconds(
                hours?.[index + 1],
                minutes?.[index + 1],
                val
              );
              setDuration(durationObj);
            }}
            ref={secondsRef}
          />
        </div>
        {!isSingle && rounds === index + 1 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              width: "40px",
            }}
            onClick={() => incrementRound()}
          >
            <AddRoundedIcon
              sx={{
                fontSize: 16,
                color: "#464FEB",
                borderRadius: "50%",
                padding: "2px",
                alignSelf: "center",
              }}
            />
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: 10,
                textAlign: "center",
              }}
            >
              Add set
            </Typography>
          </Box>
        )}
      </Box>
      {!isSingle && rounds === index + 1 && rounds > 1 && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => decrementRound()}
        >
          <RemoveRoundedIcon
            sx={{
              fontSize: 20,
              color: "#464FEB",
              borderRadius: "50%",
              padding: "2px",
              cursor: "pointer",
            }}
          />
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: 10,
              textAlign: "center",
            }}
          >
            Remove set
          </Typography>
        </Box>
      )}
    </div>
  );
};

function convertToSeconds(hours, minutes, seconds) {
  return (
    Number(isNaN(hours) ? 0 : hours) * 60 * 60 +
    Number(isNaN(minutes) ? 0 : minutes) * 60 +
    Number(isNaN(seconds) ? 0 : seconds)
  );
}

export const RenderRadioGroup = ({
  inputType,
  setInputType,
  isHeartRate,
  setState,
}) => {
  return (
    <div>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        onChange={(e) => {
          setInputType(e.target.value);
        }}
        sx={{
          overflowX: "scroll",
          flexWrap: "nowrap",
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <FormControlLabel
          sx={{
            minWidth: "80px",
            "& .MuiFormControlLabel-label": {
              color: "#424242",
              fontFamily: "DM Sans",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: inputType === "range" ? 500 : 400,
              lineHeight: "21px",
            },
          }}
          value="range"
          checked={inputType === "range"}
          control={<Radio color="royalBlue" />}
          label="Range"
        />
        <FormControlLabel
          sx={{
            minWidth: "80px",
            "& .MuiFormControlLabel-label": {
              color: "#424242",
              fontFamily: "DM Sans",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: inputType === "fixed" ? 500 : 400,
              lineHeight: "21px",
            },
          }}
          value="fixed"
          checked={inputType === "fixed"}
          control={<Radio color="royalBlue" />}
          label="Fixed"
        />
        {!isHeartRate && (
          <FormControlLabel
            sx={{
              minWidth: "100px",
              "& .MuiFormControlLabel-label": {
                color: "#424242",
                fontFamily: "DM Sans",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: inputType === "perset" ? 500 : 400,
                lineHeight: "21px",
              },
            }}
            value="perset"
            checked={inputType === "perset"}
            control={<Radio color="royalBlue" />}
            label="Per Set"
          />
        )}
      </RadioGroup>
    </div>
  );
};

export const RenderProperty = ({
  label,
  isDisabled,
  incrementRound,
  decrementRound,
  rounds,
  state,
  setState,
  maxState,
  setMaxState,
  stateF,
  setStateF,
  showF,
  inputType,
  setInputType,
  unitOptions,
  selectedUnit,
  setSelectedUnit,
  showProperty,
  setShowProperty,
  isEnduranceWorkout,
  type,
  setType,
}) => {
  let isReps = label === "Reps";
  let isHeartRate = label === "Heart Rate (bpm)";
  let isPower = label === "Power";
  const isPace = label === "Pace";

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "8px",
        pointerEvents: isDisabled ? "none" : "auto",
        opacity: isDisabled ? 0.5 : 1,
      }}
    >
      {isReps && <div className="property-name">{label}</div>}

      {!isReps && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 4,
            }}
            key={label}
          >
            <CheckBox
              value={showProperty}
              onClick={() => setShowProperty(!showProperty)}
            />
            <CheckBoxLabel label={label} />
          </div>
          {!isEnduranceWorkout && showProperty && unitOptions && (
            <Select
              SelectDisplayProps={{
                style: {
                  color: "#242424",
                  fontFeatureSettings: "'clig' off, 'liga' off",
                  fontFamily: "DM Sans",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "21px",
                },
              }}
              variant="standard"
              disableUnderline
              IconComponent={ExpandMoreRoundedIcon}
              value={selectedUnit}
              onChange={(e) => {
                setSelectedUnit(e.target.value);
              }}
            >
              {unitOptions?.map((unit) => (
                <MenuItem
                  sx={{
                    color: "#242424",
                    fontFeatureSettings: "'clig' off, 'liga' off",
                    fontFamily: "DM Sans",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "21px",
                  }}
                  value={unit}
                >
                  {unit}
                </MenuItem>
              ))}
            </Select>
          )}
        </div>
      )}

      {(showProperty || isReps) && (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              flexWrap: "nowrap",
              width: "100%",
            }}
          >
            {!isEnduranceWorkout && (
              <RenderRadioGroup
                inputType={inputType}
                setInputType={setInputType}
                setState={setState}
                state={state}
                setStateMax={setMaxState}
                stateMax={maxState}
                isHeartRate={isHeartRate}
                key={inputType}
              />
            )}
          </div>
          {isPower ? (
            <PowerRangeInput
              value={state?.[1]}
              type={type}
              setType={setType}
              onSelect={(value, type) => {
                setType(type);
                let state = {};

                for (let i = 0; i < rounds; i++) {
                  state[i + 1] = value;
                }
                setState(state);
              }}
            />
          ) : isEnduranceWorkout && !isHeartRate ? (
            <div className="flex gap-1">
              {isPace ? (
                <EnduranceRangeInput
                  value={state?.[1]}
                  maxValue={maxState?.[1]}
                  onChange={(e) => {
                    let val = e.target.value;
                    setState({ 1: val });
                  }}
                  onMaxChange={(e) => {
                    let val = e.target.value;
                    setMaxState({ 1: val });
                  }}
                />
              ) : (
                <RenderPropertyType
                  incrementRound={incrementRound}
                  decrementRound={decrementRound}
                  repsInputType={inputType}
                  rounds={rounds}
                  state={state}
                  setState={setState}
                  maxState={maxState}
                  setMaxState={setMaxState}
                  stateF={stateF}
                  setStateF={setStateF}
                  showF={showF}
                />
              )}

              {showProperty && unitOptions && (
                <Select
                  SelectDisplayProps={{
                    style: {
                      color: "#242424",
                      fontFeatureSettings: "'clig' off, 'liga' off",
                      fontFamily: "DM Sans",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "21px",
                    },
                  }}
                  variant="standard"
                  disableUnderline
                  IconComponent={ExpandMoreRoundedIcon}
                  value={selectedUnit}
                  onChange={(e) => {
                    setSelectedUnit(e.target.value);
                  }}
                >
                  {unitOptions?.map((unit) => (
                    <MenuItem
                      sx={{
                        color: "#242424",
                        fontFeatureSettings: "'clig' off, 'liga' off",
                        fontFamily: "DM Sans",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "21px",
                      }}
                      value={unit}
                    >
                      {unit}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </div>
          ) : isHeartRate ? (
            <HearRateRangeInput
              value={state?.[1]}
              type={type}
              setType={setType}
              onSelect={(value, type) => {
                console.log("onSelect: ", type, value);
                setType(type);
                let state = {};

                for (let i = 0; i < rounds; i++) {
                  state[i + 1] = value;
                }
                setState(state);
              }}
            />
          ) : (
            <RenderPropertyType
              incrementRound={incrementRound}
              decrementRound={decrementRound}
              repsInputType={inputType}
              rounds={rounds}
              state={state}
              setState={setState}
              maxState={maxState}
              setMaxState={setMaxState}
              stateF={stateF}
              setStateF={setStateF}
              showF={showF}
            />
          )}
          {/* {} */}
        </>
      )}
    </div>
  );
};

const RenderPropertyType = ({
  state,
  setState,
  maxState,
  setMaxState,
  stateF,
  setStateF,
  repsInputType,
  rounds,
  incrementRound,
  decrementRound,
  showF,
}) => {
  const [values, setValues] = useState(state || {});
  useEffect(() => {
    setValues(state);
  }, [state]);
  return (
    <div
      style={{
        alignItems: "baseline",
        display: "flex",
        width: "100%",
        overflow: "scroll",
        gap: "16px",
      }}
    >
      {repsInputType === "range" ? (
        Array.from({ length: rounds }, (_, index) => (
          <RangeInput
            key={index + "rounds_"}
            incrementRound={incrementRound}
            decrementRound={decrementRound}
            rounds={rounds}
            state={state}
            setState={setState}
            maxState={maxState}
            setMaxState={setMaxState}
            index={index}
          />
        ))
      ) : repsInputType === "perset" ? (
        Array.from({ length: rounds }, (_, index) => (
          <DoubleInput
            incrementRound={incrementRound}
            decrementRound={decrementRound}
            rounds={rounds}
            m={values}
            setM={setState}
            f={stateF}
            setF={setStateF}
            index={index}
            showF={showF}
          />
        ))
      ) : (
        <DoubleInput
          incrementRound={incrementRound}
          decrementRound={decrementRound}
          rounds={rounds}
          m={state}
          setM={setState}
          f={stateF}
          setF={setStateF}
          index={""}
          showF={showF}
        />
      )}
    </div>
  );
};

const DoubleInput = ({
  incrementRound,
  decrementRound,
  rounds,
  m,
  setM,
  f,
  setF,
  index,
  showF,
  units,
}) => {
  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      {index !== "" && (
        <p
          htmlFor="inputField"
          style={{
            marginLeft: 12,
            color: "#424242",
            fontFamily: "DM Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "20px",
          }}
        >
          {`Set ${index + 1} ${units ? `(${units})` : ""}`}
        </p>
      )}

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid #071148",
            overflow: "hidden",
            borderRadius: "12px",
            height: "48px",
            fontWeight: 400,
            width: "65px",
          }}
          className="px-1 shadow relative"
        >
          <input
            type="number"
            placeholder={showF ? "M" : ""}
            min="0"
            style={{
              border: "none",
              outline: "none",
              minWidth: 36,
              lineHeight: "40px",
              textAlign: showF ? "center" : "left",
              padding: showF ? "0" : "0 10px",
              appearance: "textfield",
            }}
            className="outline-none border-none inline-flex items-center focus:outline-none  text-gray-700"
            value={m?.[index + 1]}
            onChange={(e) => {
              let val = e.target.value;
              let Obj = { ...m };
              Obj[index + 1] = isNaN(val) ? "" : val;
              // remove if trailing zeros are there and more than 1 number is there
              if (!isNaN(val)) {
                Obj[index + 1] = parseFloat(val);
              }
              setM(Obj);
            }}
          />
          {showF && (
            <>
              <span
                style={{ color: "#ccc", lineHeight: "40px" }}
                className="text-center"
              >
                /
              </span>
              <input
                type="number"
                placeholder="F"
                min="0"
                style={{
                  outline: "none",
                  border: "none",
                  minWidth: 36,
                  lineHeight: "40px",
                  textAlign: showF ? "center" : "left",
                  appearance: "textfield",
                }}
                className="outline-none border-none inline-flex items-center focus:outline-none  text-gray-700"
                value={f?.[index + 1]}
                onChange={(e) => {
                  let val = e.target.value;
                  let Obj = { ...f };
                  Obj[index + 1] = isNaN(val) ? "" : val;
                  // remove if trailing zeros are there and more than 1 number is there
                  if (val.length > 1) {
                    Obj[index + 1] = parseInt(val);
                  }
                  setF(Obj);
                }}
              />
            </>
          )}
        </div>
        {rounds === index + 1 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              width: "40px",
              marginLeft: "8px",
            }}
            onClick={() => incrementRound()}
          >
            <AddRoundedIcon
              sx={{
                fontSize: 16,
                color: "#071148",
                borderRadius: "50%",
                padding: "2px",
                alignSelf: "center",
              }}
            />
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: 10,
                textAlign: "center",
              }}
            >
              Add set
            </Typography>
          </Box>
        )}
      </Box>
      {rounds === index + 1 && rounds > 1 && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => decrementRound()}
        >
          <RemoveRoundedIcon
            sx={{
              fontSize: 20,
              color: "#071148",
              borderRadius: "50%",
              padding: "2px",
              cursor: "pointer",
            }}
          />
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: 10,
              textAlign: "center",
            }}
          >
            Remove set
          </Typography>
        </Box>
      )}
    </div>
  );
};

function RangeInput({
  rounds,
  incrementRound,
  decrementRound,
  state,
  setState,
  maxState,
  setMaxState,
  index,
}) {
  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      {index !== "" && (
        <p
          htmlFor="inputField"
          style={{
            marginLeft: 12,
            color: "#424242",
            fontFamily: "DM Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "20px",
          }}
        >
          {`Set ${index + 1}`}
        </p>
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid #071148",
              overflow: "hidden",
              borderRadius: "12px",
              height: "48px",
              fontWeight: 400,
              width: "90px",
            }}
            className="px-1 mr-2 shadow"
          >
            <input
              type="number"
              id="min-input"
              name="min"
              placeholder="0"
              inputMode="numeric"
              min="0"
              pattern="\d+"
              value={state?.[index + 1]}
              style={{
                width: "50%",
                outline: "none",
                border: "none",
                textAlign: "center",
                appearance: "textfield",
              }}
              onChange={(e) => {
                let val = e.target.value;
                // remove if trailing zeros are there and more than 1 number is there
                if (!isNaN(val)) {
                  val = parseInt(val);
                  console.log("value is", val);
                  setState({ ...state, [index + 1]: parseInt(val) });
                }
              }}
              required
            />
            <span>-</span>
            <input
              type="number"
              id="max-input"
              name="max"
              placeholder="0"
              pattern="\d+"
              value={maxState?.[index + 1]}
              min="0"
              style={{
                width: "50%",
                outline: "none",
                border: "none",
                textAlign: "center",
                appearance: "textfield",
              }}
              onChange={(e) => {
                let val = e.target.value;
                // remove if trailing zeros are there and more than 1 number is there
                if (val.length > 1) {
                  val = parseInt(val);
                }
                setMaxState({ ...maxState, [index + 1]: val });
              }}
              required
            />
          </div>
          {rounds === index + 1 && rounds > 1 && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => decrementRound()}
            >
              <RemoveRoundedIcon
                sx={{
                  fontSize: 20,
                  color: "#071148",
                  borderRadius: "50%",
                  padding: "2px",
                  cursor: "pointer",
                }}
              />
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: 10,
                  textAlign: "center",
                }}
              >
                Remove set
              </Typography>
            </Box>
          )}
        </Box>

        {rounds === index + 1 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              width: "40px",
            }}
            onClick={() => incrementRound()}
          >
            <AddRoundedIcon
              sx={{
                fontSize: 16,
                color: "#071148",
                borderRadius: "50%",
                padding: "2px",
                alignSelf: "center",
              }}
            />
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: 10,
                textAlign: "center",
              }}
            >
              Add set
            </Typography>
          </Box>
        )}
      </Box>
    </div>
  );
}
export const CheckBoxGroup = ({ state, setState, removeOneProperty }) => {
  const handleCheckBoxClick = (key) => {
    setState({
      ...state,
      [key]: !state[key],
    });
    removeOneProperty(key);
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        gap: 8,
      }}
    >
      {Object.entries(state).map(([key, value]) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 4,
          }}
          key={key}
        >
          <CheckBox
            value={value}
            onClick={() => handleCheckBoxClick(key)}
            checkedIcon={CheckedIcon} // Assuming CheckedIcon is imported
            uncheckedIcon={UncheckedIcon} // Assuming UncheckedIcon is imported
          />
          <CheckBoxLabel label={key} />
        </div>
      ))}
    </div>
  );
};
export const CheckBoxLabel = ({ label }) => {
  return <div className="checkbox-label">Specify {label}</div>;
};
export const CheckBoxLabelReps = ({ label }) => {
  return <div className="checkbox-label">{label}</div>;
};

const CheckBox = ({ value, onClick, checkedIcon, uncheckedIcon }) => {
  return (
    <div
      style={{
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <img
        style={{
          height: "24px",
          width: "24px",
          marginRight: "4px",
        }}
        src={value ? CheckedIcon : UncheckedIcon}
        alt={value ? "Checked" : "Unchecked"}
      />
    </div>
  );
};

export default CheckBox;
