import PlusRoundIcon from "@/pages/library/assests/PlusRoundIcon";
import DropDownMenu from "./DropDownMenu";
import RenderOneWorkout from "./RenderOneWorkout";

import RenderOneCard from "./RenderOneCard";
import ChatIcon from "../../assets/dashboard/message.svg";
import { Trash, Copy, ClipboardPaste, X } from "lucide-react";

export const DayCard = ({
  day,
  copiedDay,
  setCopiedDay,
  handlePaste,
  handlePasteOneWorkout,
  isTodayEmpty,
  copiedWorkout,
  disableActions,
  shouldShowCopy,
  setCopiedWorkout,
  workout,
  message,
  target,
  isAssignedDay,
  deleteFullDay,
  setSelectedCard,
  isWorkoutOtherThanCardio,
  isCardio,
  isRest,
  onOptionSelect,
  setSelectedToShow,
  setShowAddCardio,
  setShowWorkout,
  setSelectedWorkoutData,
  setShowAutoMessageDialog,
  setShowAddActivityModal,
  handleReplaceOneWorkout,
  pastedData,
  setPastedData,
}) => (
  <div
    key={`${day}-day`}
    className="min-w-[192px] min-h-[150px] border rounded-md p-2 flex flex-col relative"
  >
    {copiedDay?.day === day && (
      <div className="absolute top-0 right-0 z-50 bg-black-pure bg-opacity-50 p-1 rounded-md h-full w-full flex items-center justify-center">
        <p className="font-DMSans text-font14 text-[#fff]">Copied</p>
        <button
          className="absolute top-1 right-1 p-1 rounded-full bg-[#F2F2F2]"
          onClick={() => setCopiedDay({})}
        >
          <X size={16} color="#000" />
        </button>
      </div>
    )}
    <div className="flex items-center justify-between">
      <p className="font-DMSans text-font12 text-[#424242]">Day {day}</p>
      {/* <button
        onClick={() => {
          console.log("pasted data", pastedData);
        }}
      >
        Log paste data
      </button> */}
      {(copiedDay?.day || copiedWorkout?.day) && !pastedData?.[day] ? (
        <button
          onClick={() => {
            if (copiedDay?.day) handlePaste(day);
            else handlePasteOneWorkout(day);
          }}
        >
          <ClipboardPaste size={16} color="#000" />
        </button>
      ) : null}
      {!isTodayEmpty &&
        !copiedDay?.day &&
        !copiedWorkout?.day &&
        !disableActions && (
          <div className="flex items-center gap-1">
            {shouldShowCopy && (
              <button
                onClick={() => {
                  setPastedData({});
                  setCopiedDay({
                    day: day,
                    data: {
                      workouts: workout,
                      messages: message,
                      targets: target,
                    },
                  });
                }}
              >
                <Copy size={16} color="#000" />
              </button>
            )}
            {!isAssignedDay && (
              <div
                className="cursor-pointer"
                onClick={() => deleteFullDay(day)}
              >
                <Trash size={16} color="#e44e4e" />
              </div>
            )}

            <DropDownMenu
              onOpen={() => setSelectedCard(day)}
              triggerElement={<PlusRoundIcon />}
              options={
                isAssignedDay
                  ? [
                      ...(isWorkoutOtherThanCardio
                        ? ["Edit Workout"]
                        : ["Add Workout"]),
                      "Auto Messages",
                      "Add Activity",
                    ]
                  : [
                      ...(isWorkoutOtherThanCardio
                        ? ["Edit Workout"]
                        : ["Add Workout"]),
                      ...(isCardio ? ["Remove Cardio"] : ["Add Cardio"]),
                      ...(isRest ? ["Remove Rest"] : ["Add Rest"]),
                      "Auto Messages",
                      "Add Activity",
                    ]
              }
              onSelect={onOptionSelect}
            />
          </div>
        )}
    </div>
    <div className="flex flex-col h-full gap-2 mt-2 relative">
      {isTodayEmpty && !disableActions && (
        <div className="absolute h-full w-full flex items-center justify-center">
          <DropDownMenu
            onOpen={() => setSelectedCard(day)}
            triggerElement={<PlusRoundIcon />}
            options={[
              ...(isWorkoutOtherThanCardio
                ? ["Edit Workout"]
                : ["Add Workout"]),
              ...(isCardio ? ["Remove Cardio"] : ["Add Cardio"]),
              ...(isRest ? ["Remove Rest"] : ["Add Rest"]),
              "Auto Messages",
              "Add Activity",
            ]}
            onSelect={onOptionSelect}
          />
        </div>
      )}
      {workout?.map((w, index) => {
        let isCardio = w?.name === "cardio";
        let isRest = w?.rest;
        let isActivity = target;
        if (isActivity && isRest) {
          return <></>;
        }
        return (
          <RenderOneWorkout
            key={index}
            workout={w}
            index={index}
            copiedWorkout={copiedWorkout}
            isCopied={
              copiedWorkout?.day === day && copiedWorkout?.copyIndex === index
            }
            onCopyCancel={() => setCopiedWorkout({})}
            onCopy={() => {
              setCopiedWorkout({
                day: day,
                copyIndex: index,
                workout: w,
              });
            }}
            onPaste={() => {
              handleReplaceOneWorkout(day, index);
            }}
            isActivity={isActivity}
            onClick={() => {
              if (isRest) return;
              setSelectedToShow(w);
              setSelectedCard(day);
              if (isCardio) {
                setShowAddCardio(true);
              } else {
                setShowWorkout(true);
              }
              setSelectedWorkoutData({
                workoutDay: day,
                workoutIndex: index,
              });
            }}
          />
        );
      })}

      {message && (
        <RenderOneCard
          day={day}
          name={message?.[0]?.name || "Auto Messages"}
          icon={ChatIcon}
          num={day}
          onClick={() => {
            setSelectedCard(day);
            setShowAutoMessageDialog(true);
          }}
        />
      )}
      {target && (
        <RenderOneCard
          day={day}
          name={"Activity"}
          icon={"https://img.icons8.com/ios/50/functional-training.png"}
          num={day + 1}
          onClick={() => {
            setSelectedCard(day);
            setShowAddActivityModal(true);
          }}
        />
      )}
    </div>
  </div>
);
