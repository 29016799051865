import React from "react";
import EmptyImg from "./EmptyImg.svg";

const EmptyViewAddProerties = () => {
  return (
    <div
      style={{
        position: "fixed",
        width: "calc((100vw - 110px)/4)",
      }}
    >
      <div
        style={{
          minHeight: "472px",
          height: "100%",
          width: "100%",
          position: "relative",
          backgroundColor: "#FFFFFF",
          borderRadius: "12px",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            height: "56px",
            padding: "0 24px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            borderBottom: " 1px solid #E5E5E5",
          }}
          className="exercise-library-title"
        >
          Properties
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "auto",
            marginBottom: "auto",
            padding: "24px",
          }}
        >
          <img src={EmptyImg} />

          <div style={{ marginTop: "24px" }} className="exercise-library-title">
            No Exercise Selected
          </div>
          <div className="no-exercise-text">
            There are no exercise to display, add an exercise to view properties
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmptyViewAddProerties;
