import React, { useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";

const BlockAccess = ({ showClose }) => {
  console.log("showClose", showClose);
  const history = useHistory();
  const location = useLocation();

  // if location is not /coachSubscription
  if (location.pathname === "/coachSubscription") {
    return null;
  }
  // useEffect(() => {
  //   if (location.pathname !== "/coachSubscription") {
  //     history.push("/coachSubscription");
  //   }
  // }, [location]);

  return (
    <Dialog open={true}>
      <DialogContent hideClose={showClose}>
        <DialogHeader>
          <DialogTitle>Trial Period Expired</DialogTitle>
          <DialogDescription className="font-DMSans">
            Upgrade now to stop loosing your access
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <button
            className="button-new"
            onClick={() => {
              history.push("/coachSubscription");
            }}
          >
            Upgrade Plan
          </button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default BlockAccess;
