import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import React, { useEffect, useState } from "react";
import CheckIcon from "../../assets/trainerProfile/checked.svg";
import UncheckedIcon from "../../assets/trainerProfile/unchecked.svg";
import EditIcon from "../../assets/workoutBuilder/editIcon.svg";
import RemoveIcon from "../../assets/workouts/DeleteIcon.svg";

const TodoBlock = ({
  todo,
  handleTodoAction,
  setShowTextField,
  setTodoAction,
  setPrimaryKey,
  setTodo,
  allSelected,
}) => {
  const { id: primaryKey, dateCreated, dateFinished, isFinished } = todo;
  const todoText = todo.todo?.data || todo.todo;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [finished, setFinished] = useState(isFinished);
  const [finishedDate, setFinishedDate] = useState(dateFinished);

  useEffect(() => {
    setFinished(isFinished);
  }, [isFinished]);
  const open = Boolean(anchorEl);

  const handleTodoOptionsClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      className="py-2 font-semibold"
      style={{
        borderBottom: "1px solid #D8D6D6",
        width: "90%",
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 3px",
        position: "relative",
      }}
    >
      <div
        className="grid gap-6 items-center"
        style={{
          gridTemplateColumns: "30px 1fr 100px 100px 100px",
        }}
      >
        {/* 3 columns todo, dateCreated, finishedAd */}
        <img
          style={{
            height: "20px",
            width: "20px",
            marginRight: "12px",
            cursor: "pointer",
          }}
          key={primaryKey}
          src={finished || allSelected ? CheckIcon : UncheckedIcon}
          alt=""
          onClick={() => {
            if (allSelected) return;
            console.log("clicked for ", primaryKey);
            handleTodoAction({
              type: "finish",
              todoId: primaryKey,
              isFinished: !finished,
            });
            setFinished(!finished);
            setFinishedDate(!finished ? new Date() : null);
          }}
        />
        {/* todoText */}
        <div className="flex items-center gap-2 w-176 mr-4">
          <p
            className={`text-black-pure text-font14 font-DMSans ${
              finished ? "line-through" : ""
            }`}
            style={{
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "21px",
              marginBottom: "3px",
            }}
          >
            {todoText}
          </p>
        </div>
        {/* created at */}
        <div className="w-100 mr-2">
          <p
            className="text-black-pure font-normal text-font12 font-DMSans"
            style={{
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "21px",
            }}
          >
            {moment(dateCreated).utc().format("Do MMM YYYY")}
          </p>
        </div>

        {/* finished at */}
        <div className="w-100 mr-2">
          <p
            className={`text-black-pure font-normal text-font12 font-DMSans ${
              finishedDate ? "" : "text-gray-400"
            }`}
            style={{
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "21px",
            }}
          >
            {finishedDate
              ? "Finished" //moment(finishedDate).utc().format("Do MMM YYYY")
              : "Yet to finish"}
          </p>
        </div>
        <div className="flex items-center gap-2">
          <img
            className="cursor-pointer"
            onClick={() => {
              setShowTextField(true);
              setTodoAction("edit");
              setTodo(todoText);
              setPrimaryKey(primaryKey);
            }}
            src={EditIcon}
            alt=""
          />
          <img
            className="cursor-pointer"
            onClick={() =>
              handleTodoAction({ type: "delete", todoId: primaryKey })
            }
            src={RemoveIcon}
            alt=""
          />
        </div>
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleTodoOptionsClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          "& .MuiPaper-root": {
            zIndex: 100,
            background: "#FFFFFF",
            borderRadius: "12px",
            boxShadow: "0px 4px 24px 2px rgba(33, 41, 60, 0.16)",
            padding: "4px",
          },
        }}
      >
        <MenuItem
          onClick={() => {
            setShowTextField(true);
            setTodoAction("edit");
            setTodo(todoText);
            setPrimaryKey(primaryKey);
          }}
          sx={{
            fontFamily: "DM Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "700",
            marginBottom: "4px",
          }}
        >
          Edit Todo
        </MenuItem>
        <MenuItem
          onClick={() =>
            handleTodoAction({ type: "delete", todoId: primaryKey })
          }
          sx={{
            fontFamily: "DM Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "700",
            color: "#FF3A29",
          }}
        >
          Delete Todo
        </MenuItem>
      </Menu>
    </div>
  );
};

export default TodoBlock;
