import { store } from "@/redux/store";
import axios from "axios";

export const getTrainerDashboard = async (coachID) => {
  const url = `${
    store.getState().auth.apiURL
  }CF/api/CoachWeb/Get/trainerDashboardAll`;
  const params = {
    coachID: coachID,
  };

  try {
    const response = await axios.get(url, { params });
    return response.data;
  } catch (error) {
    console.log("error in getting trainer dashboard");
  }
};
