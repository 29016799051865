import moment from "moment";
import Box from "@mui/material/Box";
import Button from "../Button/button";
import React, { useState, useRef, useEffect } from "react";
import GhostLoader from "./GhostLoader";
import useNotesHook from "./useNotesHook";
import Notepad from "../../assets/Notepad.svg";
import TextField from "@mui/material/TextField";
import plusIcon from "../../assets/plusicon.svg";
import { styled } from "@mui/material/styles";
import { withWindowDimensions } from "../../utils/functions";
import { SpurfitCircularProgress } from "../Dashboard/dashboard";
import NotesBlock from "./NotesBlock";

const Root = styled("div")(({ theme }) => ({
  "& .MuiFilledInput-root": {
    background: "white",
    borderRadius: "16px",
    paddingRight: "30px",
    border: "2px solid #F9F9F9",
    height: "auto",
  },
}));

const Notes = (props) => {
  const { error, isMobileSized, userUUID, trainerUUID, setNotesCount } = props;
  const [notes, errorMessage, loading, fetchData] = useNotesHook({
    userUUID,
    trainerUUID,
  });
  const [primaryKey, setPrimaryKey] = useState();
  const [type, setType] = useState("add");
  const [showTextFeild, setShowTextFeild] = useState(false);
  const [note, setNote] = useState("");
  const [editCurrentNoteData, setCurrentNoteData] = useState({});
  useEffect(() => {
    if (notes?.length && setNotesCount) {
      setNotesCount(notes.length);
      console.log(notes);
    }
  }, [notes]);

  if (loading) return <SpurfitCircularProgress />;

  return (
    // remove the class name
    <div
    className="w-full"
    >
      <div
        className="flex justify-between items-center bg-white"
        style={{
          padding: "12px 24px",
          borderBottom: "1px solid #EBEBEB",
        }}
      >
        <p
          style={{
            fontFamily: "DM Sans",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "29px",
          }}
        >
          Trainer's Notes
        </p>
        {/* <p className="text-font14 font-DMSans rounded-full bg-gray-stone w-[32px] h-[32px] text-center items-center flex justify-center text-white-pure font-semibold ml-2 mr-4">
          {notes?.length || 0}
        </p> */}
        <img
          src={plusIcon}
          style={{
            height: isMobileSized ? "20px" : "30px",
            width: isMobileSized ? "20px" : "30px",
          }}
          alt="plus_icon"
          className="cursor-pointer"
          onClick={() => setShowTextFeild(true)}
        />
      </div>
      {showTextFeild && !(type === "edit") && (
        <div className="w-full">
          <Box
            sx={{
              maxWidth: "100%",
            }}
          >
            <Root>
              <TextField
                autoFocus
                variant="filled"
                rows={10}
                maxRows={10}
                fullWidth
                hiddenLabel
                placeholder=""
                id="fullWidth"
                multiline={true}
                inputProps={{ disableUnderline: true }}
                onChange={(e) => setNote(e.target.value)}
                helperText={errorMessage ? "" : ""}
                error={errorMessage ? true : false}
              />
            </Root>
          </Box>
          <div className="flex justify-end my-4">
            <Button
              onClick={() => {
                setShowTextFeild(!showTextFeild);
                setType("add");
              }}
              color="#ffffff"
              backgroundColor="rgba(70, 79, 235, 1)"
              style={{
                height: "40px",
                borderRadius: "24px",
              }}
            >
              <p className="p-2 font-DMSans">Cancel</p>
            </Button>
            &nbsp; &nbsp;
            <Button
              onClick={() => {
                fetchData({ type, note, PrimaryKey: primaryKey });
                setShowTextFeild(!showTextFeild);
                setType("add");
              }}
              color="#ffffff"
              backgroundColor="rgba(70, 79, 235, 1)"
              style={{
                height: "40px",
                borderRadius: "24px",
              }}
            >
              <p className="p-2 font-DMSans">Save</p>
            </Button>
          </div>
        </div>
      )}

      {showTextFeild && type === "edit" && (
        <div className="w-full">
          <Box
            sx={{
              maxWidth: "100%",
            }}
          >
            <Root>
              <TextField
                autoFocus
                variant="filled"
                rows={10}
                maxRows={10}
                fullWidth
                hiddenLabel
                placeholder=""
                id="fullWidth"
                multiline={true}
                inputProps={{ disableUnderline: true }}
                onChange={(e) => {
                  setNote(e.target.value);
                  setCurrentNoteData(e.target.value);
                }}
                helperText={errorMessage ? "" : ""}
                error={errorMessage ? true : false}
                value={editCurrentNoteData}
              />
            </Root>
          </Box>
          <div className="flex justify-end my-4">
            <Button
              onClick={() => {
                setShowTextFeild(!showTextFeild);
                setType("add");
              }}
              color="#ffffff"
              backgroundColor="rgba(70, 79, 235, 1)"
              style={{
                height: "40px",
                borderRadius: "24px",
              }}
            >
              <p className="p-2 font-DMSans">Cancel</p>
            </Button>
            &nbsp; &nbsp;
            <Button
              onClick={() => {
                fetchData({ type, note, PrimaryKey: primaryKey });
                setShowTextFeild(!showTextFeild);
                setType("add");
              }}
              color="#ffffff"
              backgroundColor="rgba(70, 79, 235, 1)"
              style={{
                height: "40px",
                borderRadius: "24px",
              }}
            >
              <p className="p-2 font-DMSans">Save</p>
            </Button>
          </div>
        </div>
      )}

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        {!showTextFeild &&
          notes &&
          notes.map((item) => (
            <NotesBlock
              notes={item}
              fetchData={fetchData}
              setShowTextFeild={setShowTextFeild}
              setType={setType}
              setPrimaryKey={setPrimaryKey}
              setCurrentNoteData={setCurrentNoteData}
            />
          ))}
      </div>
      {/* {!showTextFeild && (
        <div className="flex justify-center my-16">
          <Button onClick={() => onClose(true)} color="#ffffff" backgroundColor="#FF9800">
            <b className="px-16 py-2">Submit</b>
          </Button>
        </div>
      )} */}
    </div>
  );
};

// const NotesBlock = ({
//   notes,
//   fetchData,
//   setShowTextFeild,
//   setType,
//   setPrimaryKey,
// }) => {
//   const { PrimaryKey, Note, Date } = notes;
//   // --> THIS IS THE NOTES Text BLOCK WE CAN MAP AND POPULATE AS MANY TEXT BLOCK AS WE WANT;
//   const containerRef = useRef(null);

//   const handleMouseEnter = () => {
//     if (containerRef.current) {
//       containerRef.current.style.background = "#F2F2F2";
//     }
//   };

//   const handleMouseLeave = () => {
//     if (containerRef.current) {
//       containerRef.current.style.background = "#FFFFFF";
//     }
//   };
//   const handleButtonClick = () => {
//     const divElement = document.getElementById("trainerNotesOptions");
//     if (divElement) {
//       divElement.style.display =
//         divElement.style.display === "none" ? "block" : "none";
//     }
//   };
//   return (
//     <div
//       className="py-2 font-semibold"
//       style={{
//         borderBottom: "1px solid #D8D6D6",
//         width: "90%",
//         display: "flex",
//         justifyContent: "space-between",
//         padding: "15px 3px",
//       }}
//     >
//       <div
//         style={{
//           display: "flex",
//           alignItems: "center",
//         }}
//       >
//         <img
//           style={{
//             height: "20px",
//             width: "20px",
//             marginRight: "12px",
//           }}
//           src={TrainerNotesIcon}
//           alt=""
//         />
//         <div>
//           <p
//             className="text-black-pure text-font14 font-DMSans"
//             style={{
//               fontSize: "16px",
//               fontStyle: "normal",
//               fontWeight: "500",
//               lineHeight: "21px",
//               marginBottom: "3px",
//             }}
//           >
//             {Note}
//           </p>
//           <p
//             className="text-black-pure font-normal text-font12 font-DMSans"
//             style={{
//               fontSize: "14px",
//               fontStyle: "normal",
//               fontWeight: "400",
//               lineHeight: "21px",
//             }}
//           >
//             {moment(Date).utc().format("Do MMM YYYY")}
//           </p>
//         </div>
//       </div>

//       <button
//         style={{
//           padding: "8px",
//           borderRadius: "8px",
//         }}
//         ref={containerRef}
//         onMouseEnter={handleMouseEnter}
//         onMouseLeave={handleMouseLeave}
//         onClick={handleButtonClick}
//       >
//         <img src={TrainerNotesOption} alt />
//       </button>
//       {/* options that will toggle using the above buttons */}

//       <div
//         className="flex justify-between items-center text-main-primary mt-2"
//         id="trainerNotesOptions"
//         style={{
//           display:"none",
//         }}
//       >
//         <div className="flex">
//           <p
//             className="mr-2 cursor-pointer text-font14 font-DMSans"
//             onClick={() => {
//               setShowTextFeild(true);
//               setType("edit");
//               setPrimaryKey(PrimaryKey);
//             }}
//           >
//             Edit
//           </p>
//         </div>
//         <div
//           className="cursor-pointer text-font14 font-DMSans"
//           onClick={() => fetchData({ type: "delete", PrimaryKey })}
//         >
//           Delete
//         </div>
//       </div>
//     </div>
//   );
// };

export default withWindowDimensions(Notes);
