import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import toast from "../../components/Toast/toast";
import SpinnerComponent from "../../components/layout/spinner";
import { useHistory } from "react-router-dom";
import EyeIcon from "../../assets/login/EyeIcon.svg";
import BluishEffect from "../../assets/login/BluishEffect.svg";
import Logo from "../../assets/spurfit.svg";
import loginLogo from "../../assets/loginImgNew.png";
import LoginImage from "../../assets/LoginPageImage.svg";
import { useDispatch } from "react-redux";
import mixpanel from "mixpanel-browser";
import axios from "axios";
import APIURL from "../../APIDetails";
import { login } from "../../redux/authSlice";
import { inDevMode } from "../../appModeConfig";

import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

const TestLogin = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [activityLoaderOn, setActivityLoader] = useState(false);
  const [email, setEmail] = useState("");
  const [pass, setPassword] = useState("");
  const [wrongCredentials, setWrongCredentials] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [toggleShowPassword, setToggleShowPassword] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    if (
      localStorage.getItem("isLoggedIn") !== null &&
      JSON.parse(localStorage.getItem("isLoggedIn"))
    ) {
      let trainerDetails = JSON.parse(
        localStorage.getItem("trainerCredentials")
      );
      // navigate('/home');
      history.push("/home");
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const loginRequest = async () => {
    await axios({
      method: "post",
      url: `${APIURL}api/authtrainerLiveDebug`,
      data: {
        trainerUUID: pass,
        trainerEmail: email,
      },
    })
      .then(async (res) => {
        if (res.data.authorize) {
          localStorage.setItem(
            "trainingType",
            `${JSON.stringify(res.data.trainingType)}`
          );
          localStorage.setItem("isLoggedIn", true); //route the user to the page

          let mainPassword = pass;
          let password = res?.data?.trainerUUID || res?.data?.TrainerUUID;
          let name = res?.data?.name || res?.data?.Name;
          let trainerDetails = JSON.stringify({
            email,
            password,
            mainPassword,
            name,
          });
          mixpanel?.identify(password);
          mixpanel?.people?.set_once({
            Name: name,
            Email: email,
          });
          // Clarity identification
          if (!inDevMode) {
            window.clarity("identify", name);
          }
          history.push("/home");
          localStorage.setItem("trainerCredentials", trainerDetails);
          console.log("Login success");
          console.log("Trainers detail", res);
          dispatch(
            login({
              trainerUUID: res?.data?.trainerUUID ?? res?.data?.TrainerUUID,
              trainingType: res?.data?.trainingType ?? res?.data?.TrainingType,
              timeZone: res?.data?.TimeZone,
            })
          );
        } else {
          toast("Invalid credentials. Please try again");
          setWrongCredentials(true);
          setActivityLoader(false);
          setPassword("");
          setEmail("");
        }
      })
      .catch((err) => {
        setPassword("");
        setEmail("");
        setWrongCredentials(true);
        setActivityLoader(false);
        toast("Invalid credentials. Please try again");
        console.log("error : " + err.message);
      });
  };

  // display banner
  const renderBanner = () => {
    return (
      <div
        style={{
          width: screenWidth <= 1017 ? "100%" : "50%",
          display: screenWidth <= 1017 && "none",
        }}
        className="relative lg:flex items-end justify-end h-screen"
      >
        <div
          style={{
            height: "calc(100% - 48px)",
            width: "calc(100% - 48px)",
            margin: 24,
            borderRadius: 24,
            position: "absolute",
            background: "rgba(0, 0, 0, 0.40)",
          }}
        ></div>
        <img
          className="object-cover"
          style={{
            height: "calc(100% - 48px)",
            width: "calc(100% - 48px)",
            margin: 24,
            borderRadius: 24,
          }}
          src={LoginImage}
          alt=""
        />

        <div
          className="flex flex-col absolute items-center justify-center"
          style={{
            bottom: "104px",
            width: "100%",
            zIndex: 100,
          }}
        >
          <span
            style={{
              color: "#fff",
              fontSize: "48px",
            }}
            className="mx-[64px] font-DMSans  font-bold justify-self-center font-display  xl:text-font20"
          >
            Better engagement with lessor effort
          </span>
        </div>
      </div>
    );
  };

  // display login form
  const renderForm = () => {
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
          width: screenWidth <= 1017 ? "100%" : "50%",
        }}
        className="lg:w-screen min-h-screen items-center justify-center"
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            flexDirection: "column",
          }}
          className=" lg:bg-white-pure flex items-center"
        >
          <img
            src={Logo}
            style={{
              width: 113.25,
              height: 48,
              resizeMode: "contain",
              marginTop: "80px",
            }}
            alt=""
            className="self-center"
          />
          <div
            style={{
              width: "100%",
            }}
            className="mt-[112px] px-12 sm:px-40 md:px-48 lg:px-24 xl:px-24 xl:max-w-3xl self-center"
          >
            <h3 className="text-center self-center text-font24 text-black-900 font-display font-semibold font-DMSans">
              Welcome Trainer!
            </h3>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "8px",
                marginBottom: "16px",
              }}
            >
              <span
                className="font-DMSans text-font16 text-center self-center"
                style={{
                  color: "#424242",
                  fontWeight: 400,
                }}
              >
                {"Sign in with your registered email"}
              </span>
            </div>
            <div className=" flex flex-col items-center">
              <form style={{ width: "343px", zIndex: 1 }}>
                <div className="mt-[16px]">
                  <div
                    style={{
                      fontSize: 14,
                      color: "#424242",
                      marginLeft: "12px",
                    }}
                    className="text-sm tracking-wide font-DMSans"
                  >
                    Email Address*
                  </div>
                  <input
                    style={{
                      borderRadius: "12px",
                      backgroundColor: "#fff",
                    }}
                    className="h-[48px] px-4 w-full text-lg mt-[8px] font-DMSans border border-gray-300 focus:outline-none focus:border-indigo-500"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="mike@spur.fit"
                  />
                </div>
                <div className="mt-[16px]">
                  <div className="flex justify-between items-center">
                    <div
                      style={{
                        fontSize: 14,
                        color: "#424242",
                        marginLeft: "12px",
                      }}
                      className="text-sm tracking-wide font-DMSans"
                    >
                      Password*
                    </div>
                  </div>
                  <div className="relative mt-[8px]">
                    <input
                      style={{
                        borderRadius: "12px",
                        backgroundColor: "#fff",
                      }}
                      className="h-[48px] px-4 w-full text-lg  font-DMSans border border-gray-300 focus:outline-none focus:border-indigo-500"
                      type={toggleShowPassword ? "text" : "password"}
                      onChange={(e) => setPassword(e.target.value)}
                      value={pass}
                      placeholder="Spur.fit Code"
                    />
                    {/* <img
                      src={EyeIcon}
                      alt=""
                      className="absolute right-4 cursor-pointer"
                      style={{
                        top: "50%",
                        transform: "translateY(-50%)",
                      }}
                      onClick={() => setToggleShowPassword(!toggleShowPassword)}
                    /> */}
                    <div
                      className="absolute right-4 cursor-pointer"
                      style={{
                        top: "50%",
                        transform: "translateY(-50%)",
                      }}
                    >
                      {toggleShowPassword ? (
                        <VisibilityOutlinedIcon
                          onClick={() =>
                            setToggleShowPassword(!toggleShowPassword)
                          }
                          style={{
                            color: "#000",
                            fontSize: "20px",
                          }}
                        />
                      ) : (
                        <VisibilityOffOutlinedIcon
                          onClick={() =>
                            setToggleShowPassword(!toggleShowPassword)
                          }
                          style={{
                            color: "#000",
                            fontSize: "20px",
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      flexDirection: "row",
                    }}
                  >
                    {wrongCredentials ? (
                      <div className="mt-2">
                        <span
                          className="text-red-dark"
                          style={{
                            fontSize: 12,
                          }}
                        >
                          {"Invalid credentials. Please try again"}
                        </span>
                      </div>
                    ) : (
                      <div className="mt-2" />
                    )}
                    <div
                      className="mt-[14px]"
                      onClick={() => {
                        history.push("/forgotPassword");
                      }}
                    >
                      <span
                        style={{
                          color: "#4339F2",
                          fontSize: 14,
                        }}
                        className="font-DMSans text-xs font-display font-bold cursor-pointer self-end"
                      >
                        Forgot Password?
                      </span>
                    </div>
                  </div>
                </div>

                <div className="mt-[26px]">
                  <button
                    style={{
                      width: "100%",
                    }}
                    className="button-new "
                    onClick={
                      activityLoaderOn
                        ? null
                        : (e) => {
                            e.preventDefault();
                            loginRequest();
                            setActivityLoader(true);
                          }
                    }
                  >
                    {activityLoaderOn ? (
                      <SpinnerComponent />
                    ) : (
                      <span
                        style={{
                          fontSize: 14,
                        }}
                      >
                        {"Login"}
                      </span>
                    )}
                  </button>
                </div>
              </form>

              {/* <div className="text-sm font-display text-blue-light text-center">
              <button
                type="button"
                style={{
                  color: CustomTheme.palette.nightBlue,
                  cursor: "pointer",
                }}
                onClick={() => {
                  history.push("/changePassword");
                }}
              >
                <span
                  style={{
                    fontSize: 14,
                  }}
                >
                  {"Don’t have an account yet? "}
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Sign Up
                  </span>
                </span>
              </button>
            </div> */}
            </div>
          </div>
        </div>
        <img
          src={BluishEffect}
          style={{
            position: "absolute",
            opacity: 0.6,
            bottom: 0,
            zIndex: 0,
          }}
        />
      </div>
    );
  };

  return (
    <>
      <ToastContainer />
      <div
        className="lg:flex"
        style={{
          flex: 1,
        }}
      >
        {/* render form */}
        {renderForm()}
        {/* render banner image */}
        {renderBanner()}
      </div>
    </>
  );
};

export default TestLogin;
