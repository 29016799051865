import React, { useEffect, useState } from "react";

const RenderXAxis = ({ totalBlocksContainerRef, totalDistance, ratio }) => {
  const [totalWidth, setTotalWidth] = useState(0);
  const [unitCount, setUnitCount] = useState(0);

  useEffect(() => {
    if (totalBlocksContainerRef.current) {
      setTotalWidth(totalBlocksContainerRef.current.offsetWidth);
      // 1 unit == 100px
      let count = Math.ceil(totalBlocksContainerRef.current.offsetWidth / 100);
      if (count !== unitCount) {
        setUnitCount(count);
      }
    }
  }, [totalBlocksContainerRef?.current?.offsetWidth]);

  // Calculate the step to avoid overlapping
  const step = Math.ceil(1 / ratio);

  const length = Math.ceil(Math.max(totalDistance + 1, 10));

  return (
    <div className="inline-flex w-full">
      {Array.from({ length: length + 1 }).map((_, index) => {
        return (
          <div
            key={index}
            className="flex items-center justify-start h-[24px] font-DMSans text-[#ccc] text-font12 font-medium relative"
            style={{ minWidth: 100 * ratio, maxWidth: 100 * ratio }}
          >
            {index % step === 0 && <p className="absolute -left-1">{index}</p>}
          </div>
        );
      })}
    </div>
  );
};

export default RenderXAxis;
