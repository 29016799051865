import React from "react";
import {
  StatsCard,
  getLatestWeightFromBodyStats,
  getSleepFromWearableData,
  getStepsFromWearableData,
} from "../DescriptionComp/ClientProfileSummary";
import { ComplianceCard } from "../DescriptionComp/SummarSection2";
import { Box, useMediaQuery } from "@mui/material";
import moment from "moment";
import { useSelector } from "react-redux";
import ClientEvents from "./ClientEvents";

const OverviewTab = ({
  data,
  reloadParticularClientsProfile,
  isAdminUserDetail,
}) => {
  const bodyStatsData = data?.bodyStatsData;
  const totalWorkouts = data?.globalStats?.daysWorkedout;
  const currentStreak = data?.globalStats?.currentStreak;
  const adherence = data?.globalStats?.globalAdherence
    ? `${data?.globalStats?.globalAdherence}%`
    : null;

  const latestWeight = getLatestWeightFromBodyStats(bodyStatsData);
  let todaysActivityData = null;
  let yesterdaysActivityData = null;
  if (data?.activityData?.[0]?.dateAssigned) {
    todaysActivityData = data?.activityData?.filter(
      (item) =>
        moment(item?.dateAssigned).format("DD-MM-YYYY") ===
        moment().format("DD-MM-YYYY")
    );
    yesterdaysActivityData = data?.activityData?.filter(
      (item) =>
        moment(item?.dateAssigned).format("DD-MM-YYYY") ===
        moment().subtract(1, "days").format("DD-MM-YYYY")
    );
  }
  const steps = getStepsFromWearableData(todaysActivityData);
  const yesterdaysSteps = getStepsFromWearableData(yesterdaysActivityData);
  const sleep = getSleepFromWearableData(todaysActivityData);

  const isSmallerThanMd = useMediaQuery((theme) =>
    theme.breakpoints.down("md")
  );

  const gymAffiliation = useSelector(
    (state) => state.auth?.data?.gymAffliation
  );
  const isLivezy = gymAffiliation === "Livezy";
  const isDemoClient = data?.userInfo?.email?.includes("demo.com");
  console.log("data demo: ", data, isDemoClient);

  return isAdminUserDetail ? (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        // gap: "1rem",
        flexWrap: "wrap",
        minWidth: "400px",
      }}
    >
      {!isLivezy && (
        <div
          style={{
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.03)",
            marginBottom: "15px",
            height: "fit-content",
            width: "100%",
          }}
        >
          <div
            style={{
              padding: "12px 24px",
              borderBottom: "1px solid #EBEBEB",
            }}
          >
            <p
              style={{
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "29px",
              }}
            >
              Exercise Compliance
            </p>
          </div>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: ["10px 14px", "10px 14px", "16px 24px"],
            }}
          >
            <ComplianceCard
              title={"This Week"}
              value={data?.currentWeekCompliance}
            />
            <ComplianceCard
              title={"1 Week ago"}
              value={isDemoClient ? "78" : data?.lastWeekCompliance}
            />
            <ComplianceCard
              title={"2 Weeks ago"}
              value={data?.twoWeeksAgoCompliance}
            />
          </Box>
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#FFFFFF",
          borderRadius: "10px",
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.03)",
          height: "fit-content",
          width: "100%",
          minWidth: 300,
        }}
      >
        <div
          style={{
            padding: "12px 24px",
            borderBottom: "1px solid #EBEBEB",
          }}
        >
          <p
            style={{
              fontFamily: "DM Sans",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "29px",
            }}
          >
            Statistics
          </p>
        </div>
        <StatsCard
          title={"Total Workouts"}
          value={totalWorkouts}
          icon={
            <img
              width="20px"
              height={"20px"}
              src="https://img.icons8.com/ios/50/deadlift--v1.png"
              alt="deadlift--v1"
            />
          }
        />

        <StatsCard
          title={"Current Streak"}
          value={currentStreak}
          icon={
            <img
              width="20px"
              height={"20px"}
              src="https://img.icons8.com/ios/50/fire-element--v1.png"
              alt="fire-element--v1"
            />
          }
        />

        <StatsCard
          title={"Adherence"}
          value={adherence}
          icon={
            <img
              width="20px"
              height={"20px"}
              src="https://img.icons8.com/ios/50/checked--v1.png"
              alt="checked--v1"
            />
          }
        />

        <StatsCard
          title={"Body Weight"}
          value={latestWeight}
          icon={
            <img
              width="20px"
              height={"20px"}
              src="https://img.icons8.com/ios/50/weight.png"
              alt="weight"
            />
          }
        />

        <StatsCard
          title={"Today's Steps"}
          value={isDemoClient ? "3000" : Math.round(steps ?? 0)}
          icon={
            <img
              width="20px"
              height={"20px"}
              src="https://img.icons8.com/pastel-glyph/64/boot-print--v1.png"
              alt="boot-print--v1"
            />
          }
        />

        <StatsCard
          title={"Yesterday's Steps"}
          value={isDemoClient ? "2500" : Math.round(yesterdaysSteps ?? 0)}
          icon={
            <img
              width="20px"
              height={"20px"}
              src="https://img.icons8.com/pastel-glyph/64/boot-print--v1.png"
              alt="sleeping-in-bed--v1"
            />
          }
        />

        <StatsCard
          title={"Sleep"}
          value={sleep > 0 ? formatSleepHoursToHoursMin(sleep) : sleep}
          icon={
            <img
              width="20px"
              height={"20px"}
              src="https://img.icons8.com/ios/50/sleeping-in-bed--v1.png"
              alt="sleeping-in-bed--v1"
            />
          }
        />
      </div>
      <ClientEvents
        data={data}
        atheleteID={data?.userInfo?.id}
        reloadParticularClientsProfile={reloadParticularClientsProfile}
      />
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        // gap: "1rem",
        flexWrap: "wrap",
      }}
    >
      {!isLivezy && (
        <div
          style={{
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.03)",
            marginBottom: "15px",
            height: "fit-content",
            width: "100%",
          }}
        >
          <div
            style={{
              padding: "12px 24px",
              borderBottom: "1px solid #EBEBEB",
            }}
          >
            <p
              style={{
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "29px",
              }}
            >
              Exercise Compliance
            </p>
          </div>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: ["10px 14px", "10px 14px", "16px 24px"],
            }}
          >
            <ComplianceCard
              title={"This Week"}
              value={data?.currentWeekCompliance}
            />
            <ComplianceCard
              title={"1 Week ago"}
              value={isDemoClient ? "78" : data?.lastWeekCompliance}
            />
            <ComplianceCard
              title={"2 Weeks ago"}
              value={data?.twoWeeksAgoCompliance}
            />
          </Box>
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#FFFFFF",
          borderRadius: "10px",
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.03)",
          height: "fit-content",
          width: "100%",
          minWidth: 300,
        }}
      >
        <div
          style={{
            padding: "12px 24px",
            borderBottom: "1px solid #EBEBEB",
          }}
        >
          <p
            style={{
              fontFamily: "DM Sans",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "29px",
            }}
          >
            Statistics
          </p>
        </div>
        <StatsCard
          title={"Total Workouts"}
          value={totalWorkouts}
          icon={
            <img
              width="20px"
              height={"20px"}
              src="https://img.icons8.com/ios/50/deadlift--v1.png"
              alt="deadlift--v1"
            />
          }
        />

        <StatsCard
          title={"Current Streak"}
          value={currentStreak}
          icon={
            <img
              width="20px"
              height={"20px"}
              src="https://img.icons8.com/ios/50/fire-element--v1.png"
              alt="fire-element--v1"
            />
          }
        />

        <StatsCard
          title={"Adherence"}
          value={adherence}
          icon={
            <img
              width="20px"
              height={"20px"}
              src="https://img.icons8.com/ios/50/checked--v1.png"
              alt="checked--v1"
            />
          }
        />

        <StatsCard
          title={"Body Weight"}
          value={latestWeight}
          icon={
            <img
              width="20px"
              height={"20px"}
              src="https://img.icons8.com/ios/50/weight.png"
              alt="weight"
            />
          }
        />

        <StatsCard
          title={"Today's Steps"}
          value={isDemoClient ? "3000" : Math.round(steps ?? 0)}
          icon={
            <img
              width="20px"
              height={"20px"}
              src="https://img.icons8.com/pastel-glyph/64/boot-print--v1.png"
              alt="boot-print--v1"
            />
          }
        />

        <StatsCard
          title={"Yesterday's Steps"}
          value={isDemoClient ? "2500" : Math.round(yesterdaysSteps ?? 0)}
          icon={
            <img
              width="20px"
              height={"20px"}
              src="https://img.icons8.com/pastel-glyph/64/boot-print--v1.png"
              alt="sleeping-in-bed--v1"
            />
          }
        />

        <StatsCard
          title={"Sleep"}
          value={sleep > 0 ? formatSleepHoursToHoursMin(sleep) : sleep}
          icon={
            <img
              width="20px"
              height={"20px"}
              src="https://img.icons8.com/ios/50/sleeping-in-bed--v1.png"
              alt="sleeping-in-bed--v1"
            />
          }
        />
      </div>
      <ClientEvents
        data={data}
        atheleteID={data?.userInfo?.id}
        reloadParticularClientsProfile={reloadParticularClientsProfile}
      />
    </div>
  );
};

export default OverviewTab;

const formatSleepHoursToHoursMin = (sleepInHour) => {
  const hours = Math.floor(sleepInHour);
  const minutes = Math.floor((sleepInHour - hours) * 60);
  if (minutes === 0) return `${hours}hours`;
  return `${hours}hour ${minutes}min`;
};
