import React from "react";

const PlusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 5V19"
        stroke="url(#paint0_linear_218_10836)"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5 12H19"
        stroke="url(#paint1_linear_218_10836)"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_218_10836"
          x1="12"
          y1="12"
          x2="13"
          y2="12"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.104167" stopColor="#464FEB" />
          <stop offset="1" stopColor="#8330E9" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_218_10836"
          x1="5"
          y1="12.5"
          x2="19"
          y2="12.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.104167" stopColor="#464FEB" />
          <stop offset="1" stopColor="#8330E9" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default PlusIcon;
