import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Button,
  Box,
  Tooltip,
  Typography,
  Autocomplete,
} from "@mui/material";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import Input from "@/components/InputComponents/Input";
import { store } from "../../../redux/store";
import TextArea from "@/components/InputComponents/TextArea";
import { createForm } from "@/ApiServices/FormControl/Api";
import { useSelector, useDispatch } from "react-redux";
import { updateForm } from "@/ApiServices/FormControl/Api";
import { resetUserFormFunc, getSingleFormDataFunc } from "@/redux/formSlice";


const FormDialog = ({ open, onClose }) => {

  const { formType, description, dataTags, isUserEditForm, id } = useSelector((store) => store.userForms)

  console.log({ formType, description, dataTags, isUserEditForm })

  const [formName, setFormName] = useState("");
  const [formDescription, setFormDescription] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const [tags, setTags] = useState([]);

  const history = useHistory();
  const dispatch = useDispatch()

  const handleCreate = async () => {
    const coachID = store.getState().auth.trainerUUID;
    const body = {
      formType: formName,
      form: [],
      coachID: coachID,
      createdDate: new Date(),
      description: formDescription,
      tags: selectedTags.join(","),
    };
    const res = await createForm(body);
    if (res && res.result) {
      onClose();
      dispatch(getSingleFormDataFunc(res?.result))
      history.push(
        "/form/" + res.result.id + "?formName=" + res.result.formType
      );
    } else {
      console.warn("error to create form");
    }
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    const coachID = store.getState().auth.trainerUUID;
    const body = {
      formType: formName,
      tags: tags,
      description: formDescription,
      tags: selectedTags.join(","),
      id: id
    }
    const res = await updateForm(body)
    if (res?.status == 200) {
      onClose();
      dispatch(getSingleFormDataFunc(res?.data?.result))
      history.push(
        "/form/" + res?.data?.result.id + "?formName=" + res?.data?.result.formType
      )
    }
  }

  useEffect(() => {
    if (!isUserEditForm) {
      setFormName("")
      setFormDescription("")
      setSelectedTags([])
    } else {
      setFormName(formType)
      setFormDescription(description)
      setSelectedTags(dataTags?.split(','))
    }


  }, [isUserEditForm, description, formType, dataTags])

  return (
    <Dialog
      open={open}
      sx={{
        backdropFilter: "blur(3px)",
        backgroundColor: "transparent",
        zIndex: 999
      }}
      PaperProps={{
        sx: {
          minWidth: "320px",
          borderRadius: "20px",
        },
      }}
    // onClick={onClose}
    >
      <DialogContent sx={{ padding: 0 }}>
        <div className="add-program-modal-head">
          <p className="add-program-modal-head-title">{`${isUserEditForm ? 'Update' : 'Create'} Form`}</p>
          <div
            style={{
              cursor: "pointer",
            }}
            onClick={onClose}
          >
            <CloseRoundedIcon />
          </div>
        </div>
        <div className="add-program-modal-body">
          <div
            className="full-width-container"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Box className={"flex justify-between items-end"}>
              <Input
                label={"Form Name *"}
                value={formName}
                onChange={(e) => {
                  setFormName(e.target.value);
                }}
                placeholder={"Form Name"}
                containerStyle={{ width: "100%" }}
              />
            </Box>
            <div
              style={{
                width: "100%",
                background: "#FFFFFF",
                borderRadius: "8px",
              }}
              className="text-blue-main"
            >
              <TextArea
                placeholder={"Description (Optional)"}
                onChange={(event) => setFormDescription(event.target.value)}
                value={formDescription}
                numRows={3}
              />
            </div>
            <div>
              <Tooltip
                // open={selectedTags.length < 1}
                title={
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: 400,
                      fontFamily: "DM Sans",
                    }}
                    textAlign={"center"}
                  >
                    Press Enter to add tags.
                  </Typography>
                }
                placement="top"
              >
                <Autocomplete
                  freeSolo
                  multiple
                  id="tags-outlined"
                  options={tags}
                  getOptionLabel={(option) => option}
                  value={selectedTags}
                  onChange={(event, newValue) => {
                    setSelectedTags(newValue);
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#ebebeb",
                      },
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "1.5px solid #4339f2",
                      boxShadow: "0px 0px 0px 3px rgba(67, 57, 242, 0.25)",
                    },
                    borderRadius: "12px",
                  }}
                  limitTags={2}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Add Tags"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "12px",
                          outline: "no  ne",
                          border: "none",
                        },
                        "& .MuiOutlinedInput-root:focus": {
                          outline: "none",
                          border: "none",
                        },
                      }}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          setSelectedTags([
                            ...selectedTags,
                            event.target.value,
                          ]);
                        }
                      }}
                    />
                  )}
                />
              </Tooltip>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", p: 0 }}>
        <button
          disabled={!formName || formName.length < 2}
          className="button-new mb-[24px]"
          onClick={isUserEditForm ? handleSubmit : handleCreate}
        >
          {`${isUserEditForm ? 'Update' : 'Add'} Form`}
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default FormDialog;
