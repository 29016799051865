import React, { useEffect, useState } from "react";
import { Chat, Streami18n, enTranslations } from "stream-chat-react";

import "stream-chat-react/dist/css/index.css";
import "./App.css";

import Layout from "../../components/layout/Layout";
import { useChecklist } from "./ChecklistTasks";
import { ChannelContainer } from "./components/ChannelContainer/ChannelContainer";
import { ChannelListContainer } from "./components/ChannelListContainer/ChannelListContainer";

import SpinnerComponent from "../../components/layout/spinner";
import { chatClient, chatInit } from "../../utils/chat";
import { getStreamclient } from "../../utils/getStreamclients";

const urlParams = new URLSearchParams(window.location.search);
const theme = urlParams.get("theme") || "light";
const targetOrigin = window.location.origin || ""; //need to check

const i18nInstance = new Streami18n({
  language: "en",
  translationsForLanguage: {
    ...enTranslations,
  },
});

const options = { state: true, watch: true, presence: false };
const sort = [];

const Index = () => {
  const [createType, setCreateType] = useState("");
  const [isCreating, setIsCreating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [ready, setReady] = useState(false);
  const [clientIds, setClientIds] = useState([]);
  const [filters, setFilter] = useState();

  useChecklist(chatClient, targetOrigin);

  async function connectGetStream() {
    if (!chatClient?.userID) {
      await chatInit(); //CONNECTING TO GETSTREAM IO
    }
    let trainerDetails = await JSON.parse(
      localStorage.getItem("trainerCredentials")
    );

    setFilter([
      {
        type: "team",
        members: { $in: [trainerDetails?.password] },
        demo: "team",
      },
      {
        members: { $in: [trainerDetails?.password] },
        type: "messaging",
      },
    ]);

    setClientIds(getStreamclient(trainerDetails));
    // console.log("quering : ",channel.queryMembers({user_id:{'$in': [chatClient.userID]}}))

    setReady(true);
  }
  const init = async () => {
    await connectGetStream();

    const handleColorChange = (color) => {
      const root = document.documentElement;
      if (color.length && color.length === 7) {
        root.style.setProperty("--primary-color", `${color}E6`);
        root.style.setProperty("--primary-color-alpha", `${color}1A`);
      }
    };

    // const getChatInitiation = async () => {
    //   await chatInit();
    // }

    window.addEventListener("message", (event) =>
      handleColorChange(event.data)
    );
    return () => {
      chatClient.disconnectUser();
      window.removeEventListener("message", (event) =>
        handleColorChange(event.data)
      );
    };
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Layout>
      {!ready ? (
        <div className="container">
          <SpinnerComponent />
        </div>
      ) : (
        <div className="container">
          <Chat
            {...{ client: chatClient, i18nInstance }}
            theme={`team ${theme}`}
          >
            <ChannelListContainer
              {...{
                isCreating,
                filters,
                options,
                setCreateType,
                setIsCreating,
                setIsEditing,
                sort,
              }}
            />
            <ChannelContainer
              {...{
                createType,
                isCreating,
                isEditing,
                setIsCreating,
                setIsEditing,
              }}
            />
          </Chat>
        </div>
      )}
    </Layout>
  );
};

export default Index;
