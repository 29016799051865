import { Label } from "@/components/ui/label";
import { CloseRounded } from "@mui/icons-material";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SpurfitCircularProgress } from "../Dashboard/dashboard";
import Input from "../InputComponents/Input";
import "../InputComponents/Input/Input.css";

function AddTargetUserThresold({
  isOpen,
  setIsOpen,
  userUUID,
  reloadClientSummary,
  isUpdate,
  setIsUpdateUserThreshold,
  summaryData,
  setSummaryData,
  onSuccess,
}) {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const [loading, setLoading] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [minutes, setMinutes] = useState("");
  const [second, setSecond] = useState("");
  const [userdata, setUserData] = useState({
    rHr: {
      value: "",
    },
    maxHR: {
      value: "",
    },
    run_thresholds: {
      ftHR: {
        value: "",
      },

      ftPace: {
        value: "",
      },
      // maxPace: {
      //   value: "",
      // },
    },
    bike_thresholds: {
      ftHR: {
        value: "",
      },

      ftPower: {
        value: "",
      },
      // maxPower: {
      //   value: "",
      // },
      ftSpeed: {
        value: "",
      },
      // maxSpeed: {
      //   value: "",
      // },
    },
  });

  // here we are setting for existing threshold values
  useEffect(() => {
    if (summaryData?.userInfo?.thresholdValues) {
      const thresholdValues = { ...summaryData?.userInfo?.thresholdValues };
      setUserData(thresholdValues);
      reverseMinSec(thresholdValues);
    }
  }, []);

  function changeMinAndSecIntoMin() {
    const min = Number(minutes);
    const sec = Number(second);
    const mintue = min + sec / 60;
    //  updating the minute data in userdata
    // if(userdata?.run_thresholds?.ftPace){
    //   userdata.run_thresholds["ftPace"] = {"value":mintue};
    // }
    let updatedUserData = { ...userdata };
    updatedUserData.run_thresholds["ftPace"] = { value: mintue };
    setUserData(updatedUserData);

    return updatedUserData;
  }

  function reverseMinSec(thresholdValues) {
    console.log("reveserThresolds", thresholdValues);
    const totalMinutes = thresholdValues?.run_thresholds?.ftPace?.value;
    if (!totalMinutes) return;
    let newminute = Math.floor(totalMinutes); // Extract whole number part as minutes
    let newseconds = Math.round((totalMinutes - newminute) * 60); // Convert fractional part to seconds
    setMinutes(newminute);
    setSecond(newseconds);
  }

  const areValuesEmpty = () => {
    const valuesToCheck = [
      userdata?.rHr?.value,
      userdata?.maxHR?.value,
      userdata?.run_thresholds?.ftHR?.value,
      userdata?.run_thresholds?.ftPace?.value,
      // userdata?.run_thresholds?.maxPace?.value,
      userdata?.bike_thresholds?.ftHR?.value,
      userdata?.bike_thresholds?.ftPower?.value,
      // userdata?.bike_thresholds?.maxPower?.value,
      userdata?.bike_thresholds?.ftSpeed?.value,
      // userdata?.bike_thresholds?.maxSpeed?.value,
    ];

    return valuesToCheck.every((value) => value == null || value === "");
  };

  async function handleUpdate() {
    // checks for empty values
    if (areValuesEmpty()) {
      setShowWarning(true);
      return;
    }
    setLoading(true);
    let userdata = changeMinAndSecIntoMin();
    try {
      let res = await axios({
        method: "post",
        url: `${APIURL}CF/api/CoachWeb/Update/updateAtheleteProperties`,
        data: {
          athleteID: userUUID,
          thresholdValues: userdata,
        },
      });

      if (res?.status === 200) {
        onSuccess && onSuccess();
        console.log("updated");
      }
    } catch (error) {
      console.log(error);
    }
    setIsOpen(false);
    setLoading(false);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    const keys = name.split("."); // Split the name to access the nested keys
    setShowWarning(false);
    setUserData((prevState) => {
      // Create a copy of the current state
      const newState = { ...prevState };

      // Use a reference to traverse to the appropriate key
      let newData = newState;
      const key = keys[0];
      if (newData?.[key]) {
        newData[key]["value"] = value;
      } else {
        newData[key] = { value: value };
      }
      return newState;
    });
  };

  const handleChangeForRunThreshold = (e) => {
    const { name, value } = e.target;
    const keys = name.split(".");
    setShowWarning(false);
    let key = keys[1];
    let run_thresholds = {};
    run_thresholds[key] = { value: value };
    setUserData((prevState) => {
      const newState = { ...prevState };
      if (newState?.run_thresholds) {
        newState.run_thresholds[key] = { value: value };
      } else {
        newState.run_thresholds = run_thresholds;
      }
      return newState;
    });
  };

  const handleChangeForBikeThreshold = (e) => {
    const { name, value } = e.target;
    const keys = name.split(".");
    setShowWarning(false);
    let key = keys[1];
    let bike_thresholds = {};
    bike_thresholds[key] = { value: value };
    setUserData((prevState) => {
      const newState = { ...prevState };
      if (newState?.bike_thresholds) {
        newState.bike_thresholds[key] = { value: value };
      } else {
        newState.bike_thresholds = bike_thresholds;
      }
      return newState;
    });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "24px",
        },
      }}
    >
      {loading && (
        <div className="absolute h-full w-full flex items-center justify-center">
          <SpurfitCircularProgress />
        </div>
      )}
      <div className="add-program-modal-head">
        <div style={{ display: "flex", alignItems: "flex-end", gap: "8px" }}>
          <p className="add-program-modal-head-title">
            Update Threshold Values
          </p>
        </div>
        <div
          onClick={() => {
            setIsOpen(false);
            setIsUpdateUserThreshold && setIsUpdateUserThreshold(false);
          }}
          className="cursor-pointer"
        >
          <CloseRounded
            sx={{
              fontSize: "24px",
              color: "#000",
              cursor: "pointer",
            }}
          />
        </div>
      </div>
      <DialogContent>
        <div className="flex flex-col   justify-between gap-3">
          <div className="flex items-center justify-between">
            <Label className="font-DMSans text-[14px] text-[#424242] pl-[8px] md-[8px] w-[450px]">
              Resting Heart Rate (bpm) :
            </Label>
            <div className="w-30">
              <Input
                placeholder="65"
                name={"rHr.value"}
                value={userdata?.rHr?.value}
                onChange={(e) => handleChange(e)}
                containerStyle={{
                  height: "35px",
                  width: "80px",
                }}
                style={{
                  fontSize: "12px",
                  borderRadius: "5px",
                  textAlign: "center",
                }}
                className={"input-div"}
              />
            </div>
          </div>
          <div className="flex items-center justify-between">
            <Label className="font-DMSans text-[14px] text-[#424242] pl-[8px] md-[8px] w-[450px]">
              Max Heart Rate (bpm) :
            </Label>
            <div className="w-30">
              <Input
                placeholder="65"
                name={"maxHR.value"}
                value={userdata?.maxHR?.value}
                onChange={(e) => handleChange(e)}
                containerStyle={{
                  height: "35px",
                  width: "80px",
                }}
                style={{
                  fontSize: "12px",
                  borderRadius: "5px",
                  textAlign: "center",
                }}
                className={"input-div"}
              />
            </div>
          </div>

          {/* for running */}
          <div className="flex flex-col gap-3">
            <div className="text-[16px] font-[500] gradient-text">Run</div>

            <div>
              <div className="flex items-center  justify-between">
                <Label className="font-DMSans text-[14px] text-[#424242] pl-[8px] md-[8px] w-[450px]">
                  Threshold Heart Rate (bpm) :
                </Label>
                <div className="w-30">
                  <Input
                    placeholder="65"
                    name={"run_thresholds.ftHR.value"}
                    value={userdata?.run_thresholds?.ftHR?.value}
                    onChange={(e) => handleChangeForRunThreshold(e)}
                    containerStyle={{
                      height: "35px",
                      width: "80px",
                    }}
                    style={{
                      fontSize: "12px",
                      borderRadius: "5px",
                      textAlign: "center",
                    }}
                    className={"input-div"}
                  />
                </div>
              </div>

              <div className="flex items-center justify-between">
                <Label
                  htmlFor="pace"
                  className="font-DMSans text-[14px] text-[#424242] pl-[8px] md-[8px] w-[450px]"
                >
                  Threshold Pace (min/km) :
                </Label>
                <div className="w-30">
                  <PaceRunningInputBox
                    minutes={minutes}
                    setMinutes={setMinutes}
                    second={second}
                    setSecond={setSecond}
                  />
                </div>
              </div>
              {/* <div className="flex items-center  justify-between">
        <Label className="font-DMSans text-[14px] text-[#424242] pl-[8px] md-[8px] w-[450px]">
        Max Pace (min/km) :
            </Label>
            <div className="w-30"> 
        <Input
            placeholder="65"
            name={"run_thresholds.maxPace.value"}
            value={userdata?.run_thresholds?.maxPace?.value}
            onChange={(e) =>  handleChange(e)}
     containerStyle={{height:"35px",
           width:"90px"
           }}
           style={{fontSize:"12px",
            borderRadius:"5px"
            }}
            className={"input-div"}/> 
    
          </div>
        </div> */}
            </div>
          </div>

          {/* this is for bike */}
          <div>
            <div className="text-[16px] font-[500] gradient-text">Bike</div>
            <div>
              <div className="flex items-center justify-between">
                <Label className="font-DMSans text-[14px] text-[#424242] pl-[8px] md-[8px] w-[450px]">
                  Threshold Power (W) :
                </Label>
                <div className="w-30">
                  <Input
                    placeholder="65"
                    name={"bike_thresholds.ftPower.value"}
                    value={userdata?.bike_thresholds?.ftPower?.value}
                    onChange={(e) => handleChangeForBikeThreshold(e)}
                    containerStyle={{
                      height: "35px",
                      width: "80px",
                    }}
                    style={{
                      fontSize: "12px",
                      borderRadius: "5px",
                      textAlign: "center",
                    }}
                    className={"input-div"}
                  />
                </div>
              </div>
              <div className="flex items-center justify-between">
                <Label className="font-DMSans text-[14px] text-[#424242] pl-[8px] md-[8px] w-[450px]">
                  Threshold Speed (KM/H) :
                </Label>
                <div className="w-30">
                  <Input
                    placeholder="20"
                    name={"bike_thresholds.ftSpeed.value"}
                    value={userdata?.bike_thresholds?.ftSpeed?.value}
                    onChange={(e) => handleChangeForBikeThreshold(e)}
                    containerStyle={{
                      height: "35px",
                      width: "80px",
                    }}
                    style={{
                      fontSize: "12px",
                      borderRadius: "5px",
                      textAlign: "center",
                    }}
                    className={"input-div"}
                  />
                </div>
              </div>
              <div className="flex items-center  justify-between">
                <Label className="font-DMSans text-[14px] text-[#424242] pl-[8px] md-[8px] w-[450px]">
                  Threshold Heart Rate (bpm) :
                </Label>
                <div className="w-30">
                  <Input
                    placeholder="65"
                    name={"bike_thresholds.ftHR.value"}
                    value={userdata?.bike_thresholds?.ftHR?.value}
                    onChange={(e) => handleChangeForBikeThreshold(e)}
                    containerStyle={{
                      height: "35px",
                      width: "80px",
                    }}
                    style={{
                      fontSize: "12px",
                      textAlign: "center",
                      borderRadius: "5px",
                    }}
                    className={"input-div"}
                  />
                </div>
              </div>

              {/* <div className="flex items-center justify-between">
  <Label className="font-DMSans text-[14px] text-[#424242] pl-[8px] md-[8px] w-[450px]">
             Max Power(W) :
            </Label>
            <div className="w-30">
            <Input
            placeholder="65"
            name={"bike_thresholds.maxPower.value"}
            value={userdata?.bike_thresholds?.maxPower?.value}
            onChange={(e) =>  handleChange(e)}
      containerStyle={{height:"35px",
           width:"90px"
           }}
           style={{fontSize:"12px",
            borderRadius:"5px"
            }}
            className={"input-div"}/> 
         
            </div>
         
            </div> */}
            </div>
          </div>

          {showWarning && (
            <p className="text-red-400 text-sm font-light">
              Please fill all the fileds
            </p>
          )}
        </div>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "center" }}>
        <button
          className="button-new"
          onClick={() => {
            handleUpdate();
          }}
        >
          Update
        </button>
      </DialogActions>
    </Dialog>
  );
}

export default AddTargetUserThresold;

const PaceRunningInputBox = ({ minutes, second, setMinutes, setSecond }) => {
  return (
    <div className="border-[0.8px] border-solid border-[#ccc]    rounded-[7px] flex items-center   mt-[8px] w-[103px] ">
      <form className="flex items-center  h-[30px]">
        <input
          type="text"
          placeholder="mm"
          className="w-[40px] focus:outline-none pl-2 mr-2   text-[12px] "
          value={minutes}
          onChange={(e) => setMinutes(e.target.value)}
        />
        <p>:</p>
        <input
          type="text"
          placeholder="ss"
          className="w-[40px] focus:outline-none px-3    text-[12px]"
          value={second}
          onChange={(e) => setSecond(e.target.value)}
        />
      </form>
    </div>
  );
};
