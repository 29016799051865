//import react pro sidebar components
import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import { ArrowLeft } from "lucide-react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import moment from "moment";
import { CircularProgress, DialogContent } from "@mui/material";
import ArrowPopper from "./components/ArrowPopper";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import DatePicker from "react-datepicker";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { SpurfitCircularProgress } from "@/components/Dashboard/dashboard";
import { getActivityDataForDateRange } from "@/ApiServices/ChartsLibrary/Api.js";
import { getChartDataForSelectedType } from "./utils";
import Dashboard from "./Dashboard";
import chartData from "./chartData";
// import { styled } from '@mui/system';

// const CustomPaper = styled(Paper)(({ theme }) => ({
//   borderRadius: '8px',
//   border: '1px solid #CCC',
//   background: '#FFF',
//   marginTop: '4px', // Space between the input box and the dropdown
// }));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ChatDashboard({
  handleDashboardClose,
  openDashboard,
  trainerUUID,
  userInfo,
  activeUser,
}) {
  // get user uuid from url

  console.log(userInfo, trainerUUID, "openDashboard");
  const [selectedTag, setSelectedTag] = useState("Training Analytics");
  let filterTag = ["Training Analytics", "Garmin Summary"];
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState("Last Month");
  const [activityData, setActivityData] = useState([]);
  const [chartView, setChartView] = React.useState([]);
  const [list, setChartlist] = React.useState(chartData);
  const [user, setUser] = React.useState(null);
  const [fromDate, setFromDate] = useState(
    moment().subtract(1, "month").toDate()
    );
  const [toDate, setToDate] = useState(moment().toDate());
  const dateData = ["Last Week", "Last Month", "Last 3 Months", "Custom Date"];
  const [chartfrom, setChartfrom] = React.useState(
    moment().subtract(7, "days").format("D MMMM YYYY")
  );
  const [chartto, setchartto] = React.useState(moment().format("D MMMM YYYY"));
  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = (event) => {
    setOpen(!open);
  };

  const [loading, setLoading] = useState(false);
  const [garminData, setGarminData] = useState([]);

  const setUserInitially = () => {
    // find user in active user
    let user = activeUser.find((user) => user.UserUUID == userInfo?.id);
    setUser(user);
  };
  console.log("user selected", user);
  useEffect(() => {
    setUserInitially();
  }, [activeUser, userInfo?.id]);

  useEffect(() => {
    console.log("FETCHING DATA TO GET RETRIEVE", userInfo?.id);
    setLoading(true);
    if (userInfo?.id) {
      retriveActivityDataForGivenDateRange(fromDate, toDate, userInfo?.id);
    }
    setLoading(false);
  }, []);

	const addDefaultChartView= async(_activityData)=> {
		let destination = []
		for(let i=0; i<2;i++) {
			let sourceData = list[i]
			console.warn(list[i],i ,"i and list", _activityData)
			let res = getChartDataForSelectedType(
				_activityData,
				sourceData?.title,
				new Date(fromDate),
				new Date(toDate)
			);
			console.warn(res, sourceData?.title, "new destination data");
			let data = Object.keys(res).map((key) => {
				let xVal = "";
				let startDate = res?.[key]?.weekStartDate
					? moment(res?.[key]?.weekStartDate)
					: moment(res?.[key]?.date);
				xVal = startDate.format("D MMMM"); //+ ' - ' + endDate.format('D MMMM');
				console.log(key, 'key', res[key])
				if (
					["Peak Pace", "Peak HR", "Peak Speed", "Peak Cadence"].includes(
						sourceData?.title
					)
				) {
					xVal = key;
				}
				return { ...res[key], xVal: xVal };
			});
			sourceData.chart.data = data;
			sourceData.id = sourceData.id + "d";
			destination.push(sourceData)
      console.warn(destination)
		}	
		setChartView(destination)
	}

  const retriveActivityDataForGivenDateRange = async (
    fromDate,
    toDate,
    userId
  ) => {
    let res = await getActivityDataForDateRange(userId, fromDate, toDate);
    console.log(res, "FETCHING DATA TO GET RETRIEVE");
    if (res) {
      console.log(res, "if");
      setActivityData(res);
      setGarminData(res);
      updateChartViewData(res);
			if(!chartView ||chartView.length ==0 ) {
				addDefaultChartView(res)
			}
    }
    return res;
  };
  const columns = [
    "Name",
    "Date",
    "Distance (km)",
    "Time",
    "Calories",
    "Max Pace",
    "Effort Level",
    "Fatigue Level",
    "rTss",
    "tss",
    "invrTss",
  ];
  const handleDateRange = (rangeType) => {
    console.warn("handle date type", rangeType);
    setValue(rangeType);
    //   setSelectValue(rangeType)
    switch (rangeType) {
      case "Last Week":
        setToDate(moment().toDate());
        setFromDate(moment().subtract(7, "days").toDate());
        break;
      case "Last Month":
        setToDate(moment().toDate());
        console.warn(toDate, "todate");
        console.warn(fromDate, "fromdate");
        setFromDate(moment().subtract(1, "month").toDate());
        break;
      case "Last 3 Months":
        setToDate(moment().toDate());
        setFromDate(moment().subtract(3, "month").toDate());
        break;
      default:
        // For Custom Date, do nothing here
        break;
    }
  };
  const handleNameChange = (user) => {
    console.log(user);
    setUser(user);
    if (user?.UserUUID) {
      retriveActivityDataForGivenDateRange(fromDate, toDate, user?.UserUUID);
    }
  };

  const handleApply = async () => {
    setLoading(true);
    setOpen(false);
    // setchartto(moment(toDate).format("D MMMM YYYY"));
    // setChartfrom(moment(fromDate).format("D MMMM YYYY"));
    const res = await retriveActivityDataForGivenDateRange(
      fromDate,
      toDate,
      user?.UserUUID
    );
    if (selectedTag == "Training Analytics") {
      updateChartViewData(res);
    }
    setLoading(false);
  };

  const updateChartViewData = async (_activityData) => {
    console.warn(_activityData);
    const _chartView = chartView;
    _chartView.map((view) => {
      let res = getChartDataForSelectedType(
        _activityData,
        view?.title,
        new Date(fromDate),
        new Date(toDate)
      );
      let data = Object.keys(res).map((key) => {
        let xVal = "";
        let startDate = res?.[key]?.weekStartDate
          ? moment(res?.[key]?.weekStartDate)
          : moment(res?.[key]?.date);
        xVal = startDate.format("D MMMM"); //+ ' - ' + endDate.format('D MMMM');
        if (
          ["Peak Pace", "Peak HR", "Peak Speed", "Peak Cadence"].includes(
            view?.title
          )
        ) {
          xVal = key;
        }
        return { ...res[key], xVal: xVal};
      });
      view.chart.data = data;
    });
    // console.warn(_chartView)
    setChartView(_chartView);
  };

  /* HANDLING THE DRAG AND DROP EVENT
  1. IF BOTH DROPPABLE ID IS LIST AND DO NOT DO ANYTHING
  2. IF BOTH DROPPBLE ID VIEW THEN REMOVE SOURCE INDEX ITEM AND ADD INTO DESTINATION INDEX
  3. IF SOURCE AND DESTINATION DROPPBLE ARE DIFFERENT THEN ADD SOURCE INDEX ITEM DATA INTO DESTINATION DATA */
  const onDragEnd = (result) => {
    console.warn(result, "result of on drag end");
    if (!result.destination) {
      return;
    }
    // LIST SHOULD NOT DRAG
    if (
      result.source.droppableId == "chart-list" &&
      result.destination.droppableId == "chart-list"
    )
      return;

    const sourceIndex = result.source.index;
    console.warn(sourceIndex, "sourceIndex");
    const destinationIndex = result.destination.index;
    // DRAG AND DROP IN THE MAIN CHART VIEW
    if (result.source.droppableId == result.destination.droppableId) {
      const destination = [...chartView];
      let [remove] = destination.splice(sourceIndex, 1);
      destination.splice(destinationIndex, 0, remove);
      setChartView(destination);
    } else {
      // DRAG AND DROP FROM LIST TO CHART VIEW
      console.warn(sourceIndex, destinationIndex, "source and destination");
      const destination = [...chartView];
      let sourceData = list[sourceIndex];
      // if (sourceIndex == 0) {
      //   let res = weeklyAggDistance(activityData, new Date(fromDate), new Date(toDate));
      //   // get values and put key as name xVal in the values and make it array
      //   const getData = (res) => {}
      //   let data = Object.keys(res).map((key) => {
      //     let dateRangString = '';
      //     let startDate = moment(res?.[key]?.weekStartDate);
      //     let endDate = moment(res?.[key]?.weekEndDate);
      //     dateRangString = startDate.format('D MMMM') //+ ' - ' + endDate.format('D MMMM');
      //     return {...res[key], xVal: dateRangString}
      //   })
      //   sourceData.chart.data = data;
      //   console.log(res);
      // }
      console.warn(sourceData, "new destination data")
      let res = getChartDataForSelectedType(
        activityData,
        sourceData?.title,
        new Date(fromDate),
        new Date(toDate)
      );
			console.warn(res, sourceData?.title, "new destination data");
      let data = Object.keys(res).map((key) => {
        let xVal = "";
        let startDate = res?.[key]?.weekStartDate
          ? moment(res?.[key]?.weekStartDate)
          : moment(res?.[key]?.date);
        xVal = startDate.format("D MMMM"); //+ ' - ' + endDate.format('D MMMM');
        if (
          ["Peak Pace", "Peak HR", "Peak Speed", "Peak Cadence"].includes(
            sourceData?.title
          )
        ) {
          xVal = key;
        }
				
        return { ...res[key], xVal: xVal};
      });
      sourceData.chart.data = data;

      sourceData.id = sourceData.id + "d";
      destination.splice(destinationIndex, 0, sourceData);
      console.warn(destination, "new destination data");
      setChartView(destination);
      console.log(chartView, "new destination data");
    }
  };
  /* HANDLING THE CLOSE EVENT OF THE CHART */
  const closechart = (index) => {
    console.warn("CLOSE FIRED ********", index);
    const newChartView = [...chartView];
    newChartView.splice(index, 1);
    setChartView(newChartView);
  };
  console.log(user);

	const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <div onClick={onClick} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', width: '100%' }}>
        <CalendarMonthIcon style={{ fontSize: '16px', color: '#000' }} />
        <input
				    className="react-datepicker__day"
            ref={ref}
            value={value}
            readOnly
            style={{
                width: "90%",
                height: "40px",
                outline: "none",
                color: "#000",
                textAlign: "center",
                fontSize: 14,
                background: '#fff',
                borderRadius: '4px'
            }}
        />
    </div>
));

  return (
    <Dialog
      fullScreen
      open={openDashboard}
      onClose={handleDashboardClose}
      TransitionComponent={Transition}
    >
      <div
        style={{
          height: "77px",
          width: "100vw",
          paddingLeft: "30px",
          paddingRight: "32px",
          backgroundColor: "#f8f8f8",
          position: "relative",
        }}
      >
        <div
          className="add-program-modal-head"
          style={{
            height: "100%",
            width: "100%",
            backgroundColor: "#FFFFFF",
            borderRadius: "0px 0px 16px 16px",
            display: "flex",
            alignItems: "center",
            boxShadow: "0px 2px 5px -1px rgba(0,0,0,0.13)",
          }}
        >
          <div className="flex items-center justify-evenly">
						<div className="flex items-center">
							<ArrowLeft
								sx={{
									position: "absolute",
									top: "50%",
									transform: "translateY(-50%)",
									left: 38,
									cursor: "pointer",
								}}
								onClick={() => handleDashboardClose()}
							/>
							<p
								style={{
									marginLeft: "32px",
								}}
								className="add-program-modal-head-title"
							>
								<Autocomplete
									value={user}
									getOptionLabel={(option) => option.Name}
									onChange={(_, newValue) => handleNameChange(newValue)}
									onInputChange={(value) =>
										console.log(value, "inoput change value")
									}
									defaultValue={user?.Name}
									id="combo-box-demo"
									options={activeUser}
									// options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
									// PaperComponent={(props) => <CustomPaper {...props} />}
									sx={{
										width: 200,
										fontSize: 10,
										"& .MuiAutocomplete-listbox": {
											maxHeight: "150px",
											fontSize: "10px", // Change font size of dropdown options
										},
										"& .MuiInputBase-root": {
											borderRadius: "8px",
											border: "1px solid rgba(131, 48, 233, 0.20)",
											height: 40,
											padding: "0 14px",
											backgroundColor: "#FFF",
										},
										"& .MuiOutlinedInput-root": {
											"& fieldset": {
												borderRadius: "8px",
											},
											"&.Mui-focused fieldset": {
												borderColor: "rgba(131, 48, 233, 0.20)",
											},
										},
										"& .MuiInputLabel-root": {
											lineHeight: "40px",
											transform: "translate(14px, 10px) scale(1)",
										},
										"& .MuiAutocomplete-option": {
											fontSize: "16px", // Change font size of dropdown options
											"&:hover": {
												backgroundColor: "rgb(246, 246, 254)", // Change background color on hover
											},
										},
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											placeholder="Select User"
											InputProps={{
												...params.InputProps,
												style: {
													height: 40,
													borderRadius: "8px",
													border: "1px solid rgba(131, 48, 233, 0.20)",
													fontSize: 14,
												},
											}}
											InputLabelProps={{
												...params.InputLabelProps,
												shrink: true,
												style: {
													fontSize: 14,
													lineHeight: "40px",
													top: "-10px",
													transform: "translate(14px, 10px) scale(1)",
												},
											}}
										/>
									)}
								/>
							</p>
						</div>
            <div onClick={handleClick} style={{width:270}} className="flex items-center chat-header-view">
							<div style={{width:"45%"}}>
                <DatePicker
                    maxDate={new Date()}
                    dateFormat="dd-MMM-yyy"
                    selected={fromDate}
                    disabled={
                        value == "Custom Date"
                            ? false
                            : true
                    }
                    onChange={(date) => setFromDate(date)}
										customInput={<CustomInput />}
                />
							</div>
							<div style={{width:"10%", justifyContent:'center', alignItems:'center', display:'flex'}}> 
									<label class="cursor-pointer  text-font14 text-center">
										-{" "}
									</label>
							</div>
							<div style={{width:"45%", paddingLeft:10}}>
									<DatePicker
										className="chat-header-view"
										maxDate={new Date()}
										dateFormat="dd-MMM-yyy"
										selected={toDate}
										disabled={
												value == "Custom Date"
														? false
														: true
										}
										onChange={(date) => setToDate(date)}
										customInput={<CustomInput />}
								/>
							</div>
            </div>
            <div className="flex items-center">
                <Autocomplete
                    value={value}
                    getOptionLabel={(option) => option}
                    onChange={(_, newValue) =>
                        handleDateRange(newValue)
                    }
                    id="date-range-dropdown"
                    options={dateData}
                    sx={{
                        width: 200,
                        fontSize: 10,
                        "& .MuiAutocomplete-listbox": {
                            maxHeight: "150px",
                            fontSize: "10px", // Change font size of dropdown options
                        },
                        "& .MuiInputBase-root": {
                            borderRadius: "8px",
                            border: "1px solid rgba(131, 48, 233, 0.20)",
                            height: 40,
                            padding: "0 14px",
                            backgroundColor: "#FFF",
                        },
                        "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                                borderRadius: "8px",
                            },
                            "&.Mui-focused fieldset": {
                                borderColor:
                                    "rgba(131, 48, 233, 0.20)",
                            },
                        },
                        "& .MuiInputLabel-root": {
                            lineHeight: "40px",
                            transform:
                                "translate(14px, 10px) scale(1)",
                        },
                        "& .MuiAutocomplete-option": {
                            fontSize: "16px", // Change font size of dropdown options
                            "&:hover": {
                                backgroundColor:
                                    "rgb(246, 246, 254)", // Change background color on hover
                            },
                        },
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder="Select Date Range"
                            InputProps={{
                                ...params.InputProps,
                                style: {
                                    height: 40,
                                    borderRadius: "8px",
                                    border: "1px solid rgba(131, 48, 233, 0.20)",
                                    fontSize: 14,
                                },
                            }}
                            InputLabelProps={{
                                ...params.InputLabelProps,
                                shrink: true,
                                style: {
                                    fontSize: 14,
                                    lineHeight: "40px",
                                    top: "-10px",
                                    transform:
                                        "translate(14px, 10px) scale(1)",
                                },
                            }}
                        />
                    )}
                />
						</div>
            <div className="ml-3">
                <button
                    className="apply-input__button-gradient ml-2"
                    onClick={handleApply}
                    variant="contained"
                >
                    Apply
                </button>
            </div>
          </div>
          <div className="flex gap-4">
            {filterTag?.map((tag) => (
              <div
                className="chip"
                style={{
                  cursor: "pointer",
                  background:
                    selectedTag === tag
                      ? "linear-gradient(90deg, #464feb 10.42%, #8330e9 100%)"
                      : "#F7F7F7",
                  color: selectedTag === tag ? "#fff" : "#000",
                  minWidth: "76px",
                }}
                onClick={() => setSelectedTag(tag)}
              >
                {tag}{" "}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100vw",
          paddingLeft: "30px",
          paddingRight: "32px",
          backgroundColor: "#f8f8f8",
          position: "relative",
          paddingTop: "12px",
        }}
      >
        <div
          style={{
            boxShadow: "0px 2px 5px -1px rgba(0,0,0,0.13)",
          }}
          className=" bg-white-pure rounded-xl"
        >
          {loading ? (
            loading ? (
              <div
                className={"w-full overflow-y-auto"}
                style={{
                  paddingLeft: "30px",
                  paddingRight: "32px",
                  paddingBlock: "30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "50vw",
                }}
              >
                <CircularProgress size={20} sx={{ color: "#6242EA" }} />
              </div>
            ) : (
              "Get Data"
            )
          ) : selectedTag == "Training Analytics" ? (
            <Dashboard
              chartto={toDate}
              chartfrom={fromDate}
              activityData={activityData}
              chartView={chartView}
              setChartView={setChartView}
              onDragEnd={onDragEnd}
              closechart={closechart}
            />
          ) : (
            selectedTag === "Garmin Summary" && (
              <div
                className={"h-full w-full overflow-y-auto"}
                style={{
                  paddingLeft: "30px",
                  paddingRight: "32px",
                  paddingBlock: "30px",
                }}
              >
                <table className="table-auto w-full mt-4">
                  <thead>
                    {columns.map((col, index) => (
                      <th
                        key={index}
                        className="border border-gray-300 px-4 py-2 text-font14 font-DMSans"
                      >
                        {col}
                      </th>
                    ))}
                  </thead>
                  <tbody>
                    {garminData.map((data, index) => {
                      let name = data?.summary?.activityName;
                      let date = data?.dateRecorded
                        ? new Date(data.dateRecorded).toDateString()
                        : null;
                      let distance = formatDistance(
                        data?.summary?.distanceInMeters
                      );
                      let time = formatTime(data?.summary?.durationInSeconds);
                      let calories =
                        data?.summary?.activeKilocalories?.toFixed(2);
                      let maxPace = formatPace(
                        data?.summary?.maxPaceInMinutesPerKilometer
                      );
                      let effortLevel =
                        data?.computedMetrics?.effort_level?.toFixed(2);
                      let fatigueLevel =
                        data?.computedMetrics?.fatigue_level?.toFixed(2);
                      let rTss =
                        data?.computedMetrics?.front_block_metrics?.rTSS?.toFixed(
                          2
                        );
                      let tss =
                        data?.computedMetrics?.front_block_metrics?.tss?.toFixed(
                          2
                        );
                      let invrTss =
                        data?.computedMetrics?.front_block_metrics?.invrTSS?.toFixed(
                          2
                        );

                      return (
                        <tr key={index} className="border border-gray-300">
                          <td className="px-4 py-2 font-DMSans text-font14 border-r">
                            {name}
                          </td>
                          <td className="px-4 py-2 font-DMSans text-font14 border-r">
                            {date}
                          </td>
                          <td className="px-4 py-2 font-DMSans text-font14 border-r">
                            {distance}
                          </td>
                          <td className="px-4 py-2 font-DMSans text-font14 border-r">
                            {time}
                          </td>
                          <td className="px-4 py-2 font-DMSans text-font14 border-r">
                            {calories}
                          </td>
                          <td className="px-4 py-2 font-DMSans text-font14 border-r">
                            {maxPace}
                          </td>
                          <td className="px-4 py-2 font-DMSans text-font14 border-r">
                            {effortLevel}
                          </td>
                          <td className="px-4 py-2 font-DMSans text-font14 border-r">
                            {fatigueLevel}
                          </td>
                          <td className="px-4 py-2 font-DMSans text-font14 border-r">
                            {rTss}
                          </td>
                          <td className="px-4 py-2 font-DMSans text-font14 border-r">
                            {tss}
                          </td>
                          <td className="px-4 py-2 font-DMSans text-font14 border-r">
                            {invrTss}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )
          )}
        </div>
      </div>
    </Dialog>
  );
}

export default ChatDashboard;
const formatPace = (pace) => {
  if (!pace) return "0:0";
  let min = Math.floor(pace);
  let sec = Math.floor((pace - min) * 60);
  return `${min}:${sec}`;
};

const formatTime = (seconds) => {
  if (!seconds) return "0:0:0";
  let hours = Math.floor(seconds / 3600);
  let minutes = Math.floor((seconds % 3600) / 60);
  let sec = seconds % 60;
  return `${hours}:${minutes}:${sec}`;
};

const formatDistance = (meters) => {
  if (!meters) return "0";
  return (meters / 1000).toFixed(2);
};
