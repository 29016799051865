import {
  getAllWorkouts,
  getWorkoutTags,
  getWorkoutsbyCoach,
} from "@/ApiServices/Workouts/Api";
import { CustomAlertContext } from "@/App";
import { setWorkoutLibaryData } from "@/redux/dataSlice";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Box, Paper } from "@mui/material";
import Fuse from "fuse.js";
import { useContext, useEffect, useRef, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { CloseIcon } from "stream-chat-react";
import PlusRoundedIcon from "../../assets/clientProfile/plusIconGray.svg";
import FilterIcon from "../../assets/workoutBuilder/filterIcon.svg";
import ActiveFilterIcon from "../../assets/workouts/ActiveFunnel.svg";
import SearchBar from "../Dashboard/SearchBar";
import { SpurfitCircularProgress } from "../Dashboard/dashboard";
import useOutsideClick from "../copilot/useOutsideClick";
import ShowAssignedwWorkoutDialoge from "./ShowAssignedWorkoutDialoge";

function WorkoutLibrary({ setShowWorkoutLibrary, setOpenWorkoutInfoModal }) {
  const [loading, setLoading] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [workoutData, setWorkoutData] = useState([]);
  const [searchedData, setSearchedData] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [allTags, setAllTags] = useState([]);

  const [selectedTag, setSelectedTag] = useState(null);
  const [showWorkoutDetailDialog, setShowWorkoutDetailDialog] = useState(false);
  const [workoutToBeShown, setWorkoutToBeShown] = useState(null);
  const coachID = useSelector((state) => state.auth.trainerUUID);
  const adminID = useSelector((state) => state.auth?.data?.adminID);
  const { displayAlert } = useContext(CustomAlertContext);
  const gymAffiliation = useSelector(
    (state) => state.auth?.data?.gymAffliation
  );
  const popOverRef = useRef(null);

  const dispatch = useDispatch();
  const WorkoutLibraryStateInRedux = useSelector(
    (state) => state.data.WorkoutLibraryDndData
  );
  const isLivezy = gymAffiliation === "Livezy";

  const handleClickOutside = () => {
    setPopoverOpen(false);
  };

  useOutsideClick(popOverRef, handleClickOutside);

  const requestSearch = async (searchedVal) => {
    try {
      if (typeof searchedVal !== "string") {
        displayAlert({
          message: "Invalid input",
          type: "error",
        });
        throw new Error("Invalid input");
      }
      if (searchedVal.trim() === "") {
        setIsSearching(false);
        return;
      }

      const fuseOptions = {
        keys: ["name", "Name"], // Properties to search within
        threshold: 0.3, // Adjust the threshold for fuzzy matching
      };

      const fuse = new Fuse(workoutData, fuseOptions);
      const searchResults = fuse.search(searchedVal);

      setSearchedData(searchResults);
      setIsSearching(true);
    } catch (err) {
      console.log(err);
    }
  };

  function cancelSearch() {
    setIsSearching(false);
  }

  const getTags = async () => {
    let tags = await getWorkoutTags(coachID);
    // include run, bike and swim as tags
    tags = Array.from(new Set(tags.concat(["run", "bike", "swim"])));
    setAllTags(tags);
  };

  useEffect(() => {
    getTags();
    if (WorkoutLibraryStateInRedux?.length > 0) {
      setLoading(true);
      setWorkoutData([...WorkoutLibraryStateInRedux]);
      setOriginalData([...WorkoutLibraryStateInRedux]);
      getTags();
      setLoading(false);
      return;
    }
    const getWorkout = async () => {
      setLoading(true);

      try {
        let trainerWorkouts = isLivezy
          ? await getWorkoutsbyCoach(coachID)
          : await getAllWorkouts(coachID, adminID);
        const res = await Promise.all([trainerWorkouts]);

        if (res?.[0]) {
          setWorkoutData([...res?.[0]]);
          setOriginalData([...res?.[0]]);
          dispatch(setWorkoutLibaryData(res?.[0]));
          // getTagsOfAllWorkouts(res?.[0]);
        }
      } catch (err) {
        displayAlert({
          message: "An error occurred while fetching workouts.",
          type: "error",
        });
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    getWorkout();
  }, []);

  const filterWorkouts = (tag) => {
    if (!tag) {
      setWorkoutData(originalData);
      return;
    }
    let filteredWorkouts = originalData.filter((workout) => {
      if (
        workout?.tag &&
        typeof workout?.tag === "string" &&
        workout?.tag.trim() !== ""
      ) {
        let tags = workout.tag.split(",");
        if (workout.workoutKind === tag) {
          return true;
        }
        return tags.includes(tag);
      }
      return false;
    });
    setWorkoutData(filteredWorkouts);
  };

  return (
    <div
      style={{
        maxHeight: "calc(100vh - 90px)",
        height:"calc(100vh - 90px)",
        minHeight:"calc(100vh - 90px)",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#fff",
        width: "20%",
        borderRadius: "8px",
        height: "100%",
        position: "relative",
      }}
    >
      <>
        <Box
          style={{
            height: "56px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingLeft: "24px",
            borderBottom: "1px solid #E5E5E5",
          }}
        >
          <div
            style={{
              color: "#242424",
              fontFeatureSettings: '"clig" off, "liga" off',
              fontFamily: "DM Sans",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "22px",
            }}
          >
            Drag and Drop Workouts
          </div>

          <div
            onClick={() => {
              setShowWorkoutLibrary(false);
            }}
            style={{
              cursor: "pointer",
              marginRight: "4px",
            }}
          >
            <CloseIcon />
          </div>
        </Box>
        <div className="relative flex items-center gap-2 justify-between">
          <SearchBar
            placeholder="Search Workouts"
            style={{
              borderRadius: "80px",
              marginTop: 0,
              marginBottom: 0,
              boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.19)",
              margin: "16px 5px 10px 10px",
              width: "100%",
            }}
            iconStyle={{
              marginLeft: 5,
            }}
            bgcol={"#FFF"}
            request={requestSearch}
            cancel={cancelSearch}
          />{" "}
          {allTags?.length > 0 && (
            <img
              src={selectedTag != null ? ActiveFilterIcon : FilterIcon}
              className="h-[30px] w-[30px] mr-[10px] cursor-pointer mt-2"
              onClick={() => {
                // setIsFilterActive();
                setPopoverOpen(!popoverOpen);
              }}
            />
          )}
        </div>

        {popoverOpen && (
          <div
            style={{
              position: "absolute",
              top: 63,
              left: 0,
              width: "90%",
              zIndex: 1000,
            }}
            ref={popOverRef}
          >
            <Paper
              sx={{
                mx: "16px",
                paddingY: 1,
              }}
              elevation={8}
            >
              <div className="flex justify-between items-center px-2">
                <div
                  style={{
                    color: "#242424",
                    fontFeatureSettings: '"clig" off, "liga" off',
                    fontFamily: "DM Sans",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "22px",
                  }}
                >
                  Filter Tags
                </div>
                <div
                  onClick={() => {
                    setPopoverOpen(false);
                  }}
                  style={{
                    cursor: "pointer",
                    marginRight: "4px",
                  }}
                >
                  <CloseIcon />
                </div>
              </div>
              <div className="h-[0.8px] w-full bg-[#ccc] mt-2"></div>

              <div className="flex flex-col gap-1   p-2 h-[50vh] mx-2 mt-1  overflow-y-scroll">
                {allTags?.map((tag, index) => (
                  <div
                    className="flex items-center hover:bg-gray-100 cursor-pointer rounded-md p-2 transition-all duration-300 ease-in-out"
                    key={tag}
                    style={{
                      background:
                        selectedTag === tag &&
                        "linear-gradient(to right, rgba(70, 79, 235, 0.4), rgba(131, 48, 233, 0.4))",
                    }}
                    onClick={() => {
                      if (selectedTag === tag) {
                        setSelectedTag(null);
                        filterWorkouts(null);
                      } else {
                        filterWorkouts(tag);
                        setSelectedTag(tag);
                      }
                      // setPopoverOpen(false);
                    }}
                  >
                    <p className="font-DMSans text-font14  ">{tag}</p>
                  </div>
                ))}
              </div>
            </Paper>
          </div>
        )}

        {loading ? (
          <div className="  h-full w-full flex items-center justify-center bg-black-pure/5 z-50">
            <SpurfitCircularProgress />
          </div>
        ) : (
          <Droppable droppableId="droppable-1">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="flex flex-col gap-3 px-2 shadow-2xl overflow-y-scroll h-full"
              >
                {!isSearching &&
                  workoutData.map((workout, index) => (
                    <Draggable
                      draggableId={`workoutLib@${JSON.stringify(workout)}`}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div
                            className="shadow-md hover:shadow-lg"
                            style={{
                              width: "100%",
                              display: "flex",
                              borderRadius: "12px",
                              alignItems: "center",
                              gap: "8px",
                              justifyContent: "space-between",
                              background: "#fff",
                            }}
                            onClick={() => {
                              setWorkoutToBeShown(workout);
                              setShowWorkoutDetailDialog(true);
                            }}
                          >
                            <div
                              style={{
                                borderRadius: "12px 0px 0px 12px",
                                padding: "8px 8px",
                                backgroundColor: " white",
                              }}
                            ></div>

                            <div
                              style={{
                                padding: "8px 0 8px 8px",
                                flex: 1,
                              }}
                            >
                              <div className="exercise-title text-left">
                                {workout.name}
                              </div>
                            </div>

                            <div
                              style={{
                                borderRadius: "0px 12px 12px 0px",
                                padding: "8px 8px",
                                backgroundColor: "rgba(67, 57, 242, 0.10)",
                              }}
                            >
                              <DragIndicatorIcon
                                fontSize="medium"
                                sx={{
                                  color: "#424242",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                {isSearching &&
                  searchedData.length > 0 &&
                  searchedData.map((workout, index) => (
                    <Draggable
                      draggableId={`workoutLib@${JSON.stringify(
                        workout?.item
                      )}`}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              borderRadius: "12px",
                              alignItems: "center",
                              gap: "8px",
                              justifyContent: "space-between",
                              background: "#fff",
                            }}
                            onClick={() => {
                              setWorkoutToBeShown(workout?.item);
                              setShowWorkoutDetailDialog(true);
                            }}
                          >
                            <div className="flex-1 font-DMSans exercise-title text-left">
                              {workout?.item?.name}
                            </div>

                            <div
                              style={{
                                borderRadius: "0px 12px 12px 0px",
                                padding: "8px 8px",
                                backgroundColor: "rgba(67, 57, 242, 0.10)",
                              }}
                            >
                              <DragIndicatorIcon
                                fontSize="medium"
                                sx={{
                                  color: "#424242",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                {isSearching && searchedData.length == 0 && (
                  <button
                    className="button-new gap-[2px] w-full font-DMSans text-font14 font-bold leading-normal mx-auto"
                    onClick={() => {
                      setShowWorkoutLibrary(false);
                      setOpenWorkoutInfoModal(true);
                    }}
                  >
                    <img src={PlusRoundedIcon} />
                    Create New Workout
                  </button>
                )}

                {showWorkoutDetailDialog && (
                  <ShowAssignedwWorkoutDialoge
                    open={showWorkoutDetailDialog}
                    setShowDialoge={setShowWorkoutDetailDialog}
                    workoutToBeShown={workoutToBeShown}
                    isCalendar={false}
                  />
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        )}
      </>
    </div>
  );
}

export default WorkoutLibrary;
