import { Paper } from "@mui/material";
import React from "react";

const TemplateSuggestions = ({ suggestions, onTemplateSelect }) => {
  return (
    <Paper
      sx={{
        position: "absolute",
        zIndex: 1,
        width: "100%",
        bottom: "calc(100% + 10px)",
      }}
      elevation={3}
    >
      {suggestions.map((suggestion) => (
        <div
          className="font-DMSans text-font14 px-2 py-1 hover:bg-gray-200 cursor-pointer"
          onClick={() => onTemplateSelect(suggestion)}
          key={suggestion.id}
        >
          {suggestion.templateName}
        </div>
      ))}
    </Paper>
  );
};

export default TemplateSuggestions;
