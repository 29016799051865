import { Dialog, DialogContent, Divider } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import React, { useEffect, useState } from "react";
import { convertItemToOriginalForm } from "../../../components/copilot/workoutAssignFunctions";
import EditWorkouts from "../../../components/copilot/EditWorkouts";
import { SpurfitCircularProgress } from "../../../components/Dashboard/dashboard";

const ShowWorkoutDialog = ({
  isOpen,
  setIsOpen,
  Workout,
  onEdit,
  onReplacePress,
  addedWworkouts,
  setAddedWworkouts,
  editWorkoutDay,
  handleDeleteWorkout,
  programAssignedChannels,
  programAssignedUsers,
  originalDay,
  replacementData,
  setShowWorkoutBuilderModal,
  setIsNewWorkoutFalse,
}) => {
  const [response, setResponse] = useState("");
  const [loading, setLoading] = useState(true);
  const [nonConvertedData, setNonConvertedData] = useState(Workout);
  const [isEndurnace, setIsEndurance] = useState(false);

  useEffect(() => {
    let original = convertItemToOriginalForm(Workout);
    if (
      Workout?.workoutKind === "run" ||
      Workout?.workoutKind === "bike" ||
      Workout?.workoutKind === "swim"
    ) {
      setIsEndurance(true);
    }
    setResponse(JSON.stringify(original));
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 100);
  }, []);
  return loading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        backgroundColor: "rgba(0,0,0,0.5)",
        zIndex: 100,
      }}
    >
      <SpurfitCircularProgress />
    </div>
  ) : (
    <EditWorkouts
      open={isOpen}
      setOpen={setIsOpen}
      data={response}
      nonConvertedData={nonConvertedData}
      isSingleWorkout={true}
      loading={loading}
      showEditButton={true}
      onEdit={onEdit}
      onReplacePress={onReplacePress}
      addedWworkouts={addedWworkouts}
      setAddedWworkouts={setAddedWworkouts}
      editWorkoutDay={editWorkoutDay}
      handleDeleteWorkout={handleDeleteWorkout}
      programAssignedChannels={programAssignedChannels}
      programAssignedUsers={programAssignedUsers}
      originalDay={originalDay}
      replacementData={replacementData}
      setShowWorkoutBuilderModal={setShowWorkoutBuilderModal}
      setIsNewWorkoutFalse={setIsNewWorkoutFalse}
      isEndurance={isEndurnace}
    />
  );
  // return (
  //   <Dialog
  //     open={isOpen}
  //     onClose={() => setIsOpen(false)}
  //     aria-labelledby="show-workout-dialog-title"
  //     maxWidth="sm"
  //     fullWidth
  //     PaperProps={{
  //       style: {
  //         borderRadius: "20px",
  //       },
  //     }}
  //   >
  //     <div className="add-program-modal-head">
  //       <p className="add-program-modal-head-title">{Workout?.[1]?.name} </p>
  //       <div
  //         style={{
  //           cursor: "pointer",
  //         }}
  //         onClick={() => {
  //           setIsOpen(false);
  //         }}
  //       >
  //         <CloseRoundedIcon />
  //       </div>
  //     </div>
  //     <DialogContent>
  //       {Workout?.[1]?.workoutContent?.rounds?.map((r, index) => {
  //         let round = r.round;
  //         return (
  //           <>
  //             {index != 0 && (
  //               <Divider
  //                 sx={{
  //                   my: 1,
  //                 }}
  //               />
  //             )}
  //             <div>
  //               {round?.measurements?.map((m) => {
  //                 let measurement = m.measurement;
  //                 return <RenderOneExercise exercise={measurement} />;
  //               })}
  //             </div>
  //           </>
  //         );
  //       })}
  //     </DialogContent>
  //   </Dialog>
  // );
};

export default ShowWorkoutDialog;

const RenderOneExercise = ({ exercise }) => {
  const [currentExercise, setCurrentExercise] = useState(exercise);

  return (
    <div
      style={{
        border: "1px solid #F5F5F5",
        padding: "7.081px 10.621px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <p
        style={{
          color: "#242424",
          fontSize: "12.391px",
          fontFamily: "DM Sans",
        }}
      >
        {currentExercise.movementName}
      </p>

      {currentExercise?.reps && (
        <div>
          <p
            style={{
              color: "#242424",
              fontSize: "12.391px",
              fontFamily: "DM Sans",
            }}
          >
            Reps: {formatProperty(currentExercise?.reps)}
          </p>
        </div>
      )}
      {currentExercise?.weight && (
        <div>
          {" "}
          <p
            style={{
              color: "#242424",
              fontSize: "12.391px",
              fontFamily: "DM Sans",
            }}
          >
            Weights: {formatProperty(currentExercise?.weight)}
          </p>
        </div>
      )}
      {currentExercise?.height && (
        <div>
          <p
            style={{
              color: "#242424",
              fontSize: "12.391px",
              fontFamily: "DM Sans",
            }}
          >
            Weights: {formatProperty(currentExercise?.height)}
          </p>
        </div>
      )}
    </div>
  );
};

const formatProperty = (property) => {
  if (!property || Object.entries(property).length == 0) return null;
  let formattedProperty = [];
  Object.keys(property).map((key) => {
    formattedProperty.push(property[key]);
  });
  return formattedProperty.join(", ");
};
