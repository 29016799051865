import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  toggleValue: false,
}

export const checkListSlice = createSlice({
  name: 'toggleChecklist',
  initialState,
  reducers: {
    toggle: (state) => {
      state.toggleValue = !state.toggleValue;
    },
    setToggleValue: (state,action) =>{
      state.toggleValue = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { toggle,setToggleValue } = checkListSlice.actions

export default checkListSlice.reducer