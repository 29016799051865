import { CloseRounded } from "@mui/icons-material";
import { Box, Dialog, DialogActions, DialogContent } from "@mui/material";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import React, { useContext, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getAllWorkouts, getWorkoutsbyCoach } from "../../../ApiServices/Workouts/Api";
import { CustomAlertContext } from "../../../App";
import WorkoutInfoDialog from "../../../components/WorkoutComponent/WorkoutInfoDialog";
import SpinnerComponent from "../../../components/layout/spinner";
import {
  createProgramsStates,
  removeProgramsStates,
} from "../../../redux/programsSlice";
import { SearchIcon } from "../../getStreamChat/assets";
import DragIcon from "../assests/DragIcon";
import PlusIcon from "../assests/PlusIcon";
import { ProgramsContext } from "./createProgramCF";

export const ReplaceWorkout = ({
  programName,
  programId,
  programsStates,
  location, //this is not actual location it is a variable containing data
  dataToSave,
  orignialWeeks,
  selectedDay,
  showModal,
  setShowModal,
  replacementData,
  onReplaceWorkout,
}) => {
  const [workoutOuts, setWorkoutOuts] = useState("");
  const [searched, setSearched] = React.useState("");
  const [tempData, setTempData] = useState("");
  const [checkedItems, setCheckedItems] = useState([]);
  const [openAddWorkoutModal, setopenAddWorkoutModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newWeek, setNewWeek] = useState(false);
  const {
    addedWorkouts,
    selectedCard,
    setAddedWorkouts,
    setSelectedCard,
    workoutList,
    setWorkoutList,
  } = useContext(ProgramsContext);

  useEffect(() => {
    setNewWeek(isNewWeek());

    return () => {
      setNewWeek(false);
    };
  }, [selectedDay, orignialWeeks]);

  const isNewWeek = () => {
    if (orignialWeeks === -1) return true;
    let weekNo = Math.ceil(selectedDay / 7);
    console.log("weekNo from selectedDay", weekNo, orignialWeeks, selectedDay);
    return weekNo > orignialWeeks;
  };

  const coachID = useSelector((state) => state.auth.trainerUUID);
  const adminID = useSelector((state) => state.auth?.data?.adminID);

  const history = useHistory();

  const gymAffiliation = useSelector(
    (state) => state.auth?.data?.gymAffliation
  );
  const isLivezy = gymAffiliation === "Livezy";

  const init = async () => {
    setLoading(true);
    let allItems = addedWorkouts?.[selectedCard] || [];
    let filterRest = allItems?.filter((item) => !item.rest);
    setCheckedItems(filterRest ?? []);

    const trainerUUIDKey = await JSON.parse(
      localStorage.getItem("trainerCredentials")
    );
    if (workoutList?.length > 0) {
      setWorkoutOuts(workoutList);
      setTempData(workoutList);
    } else {
      let allWorkouts = isLivezy
        ? await getWorkoutsbyCoach(coachID)
        : await getAllWorkouts(coachID, adminID);
      let sortedData = allWorkouts.sort(function (a, b) {
        var dateA = new Date(a.dateCreated);
        var dateB = new Date(b.dateCreated);
        if (dateA.getTime() === dateB.getTime()) {
          // Sort by name if dates are equal
          return a.name.localeCompare(b.name);
        }
        return dateB - dateA;
      });

      setWorkoutOuts(sortedData);
      setTempData(sortedData);
      setWorkoutList(sortedData);
    }

    let newItems = [];

    let itm =
      addedWorkouts[replacementData.workoutDay][replacementData?.workoutIndex];
    newItems.push(itm);

    let newWorkoutInStorage = localStorage
      ?.getItem("createdWorkout")
      ?.split("@");
    if (newWorkoutInStorage && newWorkoutInStorage[0] == selectedCard) {
      if (JSON.parse(newWorkoutInStorage[1])) {
        newItems.push(JSON.parse(newWorkoutInStorage[1]));
      }
      localStorage.removeItem("createdWorkout");
    }
    setCheckedItems(newItems);

    console.log("CheckedItems: ", checkedItems);

    if (!Array.isArray(addedWorkouts?.[`${selectedCard}`])) {
      setAddedWorkouts({ ...addedWorkouts, [selectedCard]: [] });
    }
    localStorage.setItem("workoutDay", selectedCard);

    // let newworkoutadded = localStorage.getItem("newworkout")
    // console.log(newworkoutadded)
    if (programsStates?.workoutDay == selectedCard) {
      setCheckedItems(programsStates?.checkedItems);
      let addedWorkouts = programsStates?.addedWorkouts;
      setAddedWorkouts({
        ...addedWorkouts,
        [selectedCard]:
          programsStates?.checkedItems?.[replacementData?.workoutIndex],
      });
      dispatch(removeProgramsStates());
    }
    setLoading(false);
  };
  useEffect(() => {
    init();
  }, [selectedCard]);

  const getNewAddedWorkout = (data) => {
    console.log("handled: ", data);
    console.log("handled1: ", checkedItems);

    setCheckedItems((prevState) => {
      prevState.push(data);
      console.log("cardselected: ", localStorage.getItem("cardSelected"));
      return prevState;
    });
  };

  const requestSearch = (searchedVal) => {
    setSearched(searchedVal);
    const filteredRows = tempData.filter((row) => {
      return row.name.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setWorkoutOuts(filteredRows);
    //  setRowsPerPage(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    setWorkoutOuts(tempData);
  };

  function handleDragEnd(result) {
    // Check if the drag was successful
    if (!result.destination) {
      return;
    }

    // Get the source and destination indexes
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    // Reorder the workouts in the addedWorkouts object
    const newAddedWorkouts = { ...addedWorkouts };
    const draggedWorkout = newAddedWorkouts[selectedCard][sourceIndex];
    newAddedWorkouts[selectedCard].splice(sourceIndex, 1);
    newAddedWorkouts[selectedCard].splice(destinationIndex, 0, draggedWorkout);

    // Update the state with the new addedWorkouts object
    setAddedWorkouts(newAddedWorkouts);
  }

  console.log({ addedWorkouts: addedWorkouts?.[selectedCard] });

  const deleteSelectedWorkout = (item) => {
    const filteredIitems = checkedItems.filter((i) => i.id !== item.id);
    setCheckedItems(filteredIitems);
    // setAddedWorkouts({
    //   ...addedWorkouts,
    //   [selectedCard]: filteredIitems,
    // });
  };

  const dispatch = useDispatch();
  const { displayAlert } = useContext(CustomAlertContext);

  const Save = () => {
    // if (!newWeek) {
    //   let previousWorkouts = addedWorkouts?.[selectedCard] || [];
    //   if (checkedItems.length !== previousWorkouts.length) {
    //     displayAlert({
    //       message: `Please add same number of workouts as previously added in day ${selectedCard}, i.e. ${previousWorkouts.length} workouts`,
    //       type: "error",
    //     });
    //     return;
    //   } else {
    //     setAddedWorkouts({
    //       ...addedWorkouts,
    //       [selectedCard]: checkedItems,
    //     });
    //   }
    // } else {
    setAddedWorkouts({
      ...addedWorkouts,
      [selectedCard]: checkedItems,
    });
    // }

    setShowModal(false);
    setSelectedCard("");
    localStorage.removeItem("newworkout");
    localStorage.removeItem("cardSelected");
    cancelSearch();
  };

  return (
    <Dialog
      open={showModal}
      onClose={() => {
        setShowModal(false);
      }}
      sx={{
        backdropFilter: "blur(5px)",
      }}
      PaperProps={{
        sx: {
          //   maxHeight: "90vh",
          borderRadius: "20px",
          display: "flex",
          minWidth: "60%",
        },
      }}
    >
      {openAddWorkoutModal && (
        <WorkoutInfoDialog
          isOpen={openAddWorkoutModal}
          setIsOpen={setopenAddWorkoutModal}
          isProgramsFlow={true}
        />
      )}
      <DialogContent sx={{ padding: 0, overflow: "hidden" }}>
        <div className="add-program-modal-head">
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <p className="add-program-modal-head-title">Select Workouts</p>
            {Object.entries(addedWorkouts?.[selectedCard] || []).length > 0 && (
              <p className="sub-text">
                {programName} - Day {selectedCard}
              </p>
            )}
          </div>
          <div
            onClick={() => {
              onReplaceWorkout(checkedItems[0]);
            }}
            className="cursor-pointer"
          >
            <CloseRounded
              sx={{
                fontSize: "24px",
                color: "#000",
                cursor: "pointer",
              }}
            />
          </div>
        </div>
        <div className="add-program-modal-body">
          <div
            className="full-width-container"
            style={{
              paddingBottom: "20px",
            }}
          >
            <div
              className="channel-search__input__wrapper"
              style={{
                width: "100%",
              }}
            >
              <div className="channel-search__input__icon">
                <SearchIcon />
              </div>
              <input
                className="channel-search__input__text"
                onChange={(e) => requestSearch(e.target.value)}
                placeholder="Search"
                type="text"
                value={searched}
              />
            </div>
            {loading ? (
              <Box>
                <SpinnerComponent />
              </Box>
            ) : (
              <Box
                style={{
                  display: "flex",
                  gap: "8px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "50%",
                  }}
                >
                  <Box
                    className="workout-list-container"
                    sx={{
                      overflowY: "scroll",
                      maxHeight: "340px",
                      width: "100%",
                    }}
                  >
                    {workoutOuts &&
                      [
                        // ...checkedItems,
                        ...workoutOuts?.filter(
                          (item) =>
                            !checkedItems.some(
                              (checkedItem) => checkedItem?.id === item?.id
                            )
                        ),
                      ].map((item, index) => {
                        if (item?.name == "cardio") return;
                        let isSelected = checkedItems.some(
                          (checkedItem) => checkedItem?.id === item?.id
                        );
                        let exerciseCount = 0;
                        item?.workoutContent?.rounds?.forEach((r) => {
                          let round = r?.round;
                          exerciseCount += round?.measurements?.length;
                        });

                        return (
                          <div
                            className={`${
                              isSelected
                                ? "select-workouts_item-container-selected"
                                : "select-workouts_item-container"
                            } cursor-pointer`}
                            key={index}
                            onClick={() => {
                              if (!isSelected) {
                                setCheckedItems((prevState) => {
                                  return [item];
                                });

                                console.log("checkedItems ", checkedItems, [
                                  ...checkedItems,
                                  item,
                                ]);
                              }
                            }}
                          >
                            {isSelected ? (
                              <div
                              // onClick={() => {
                              //   setCheckedItems(
                              //     checkedItems.filter((i) => i.id !== item.id)
                              //   );
                              // }}
                              >
                                <CheckBoxIcon sx={{ color: "#6241ea" }} />
                              </div>
                            ) : (
                              <div
                              // onClick={() => {
                              //   setCheckedItems((prevState) => {
                              //     return [...prevState, item];
                              //   });

                              //   console.log("checkedItems ", checkedItems, [
                              //     ...checkedItems,
                              //     item,
                              //   ]);
                              //   setAddedWorkouts({
                              //     ...addedWorkouts,
                              //     [selectedCard]: checkedItems,
                              //   });
                              // }}
                              >
                                <CheckBoxOutlineBlankIcon />
                              </div>
                            )}
                            <div className="select-workouts_item-text">
                              <span className="select-workouts_item-title">
                                {item?.name} &nbsp;
                              </span>
                              <span className="select-workouts_item-description">
                                {exerciseCount} Exercises
                              </span>
                            </div>
                          </div>
                        );
                      })}
                  </Box>
                  <div
                    style={{
                      background: "#F2F2F2",
                      borderRadius: "12px",
                      display: "flex",
                      gap: "8px",
                      flexDirection: "column",
                      padding: "10px 0",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      style={{
                        fontFamily: "DM Sans",
                        fontSize: 14,
                        fontWeight: 500,
                      }}
                    >
                      Couldn't find the workout?
                    </p>
                    <button
                      onClick={() => {
                        // localStorage.setItem("cardSelected", selectedCard)
                        // localStorage.setItem("programid", programId)
                        dispatch(
                          createProgramsStates({
                            started: true,
                            finished: false,
                            workoutDay: selectedCard,
                            programId: programId,
                            programName: programName,
                            addedWorkouts: addedWorkouts,
                            checkedItems: checkedItems,
                            location: location,
                            ...dataToSave,
                          })
                        );

                        setopenAddWorkoutModal(true);
                      }}
                      style={{
                        width: "max-content",
                        margin: "auto",
                      }}
                      className="button-new-outlined-small"
                    >
                      <PlusIcon /> Add Workout
                    </button>
                  </div>
                </div>
                <div style={{ width: "1px", background: "grey" }}></div>
                <div style={{ width: "45%" }}>
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="card-list">
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            fontSize: "14px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                          }}
                        >
                          {(checkedItems || [])?.map((workout, idx) => {
                            if (workout?.name == "cardio") return;
                            let exerciseCount = 0;
                            workout?.workoutContent?.rounds?.forEach((r) => {
                              let round = r?.round;
                              exerciseCount += round?.measurements?.length;
                            });

                            return (
                              <Draggable
                                key={workout[0]}
                                draggableId={workout.id}
                                index={idx}
                              >
                                {(provided) => (
                                  <div
                                    {...provided.draggableProps}
                                    className={
                                      "selected-workouts_item-container"
                                    }
                                    style={{
                                      alignItems: "center",
                                    }}
                                    // {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        gap: "12px",
                                        minWidth: "95%",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div style={{}}>
                                        <DragIcon />
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <p className="select-workouts_item-title">
                                          {workout?.name}
                                        </p>
                                        <p className="select-workouts_item-description">
                                          {exerciseCount} Exercises
                                        </p>
                                      </div>
                                    </div>
                                    {/* <div
                                      onClick={() => {
                                        deleteSelectedWorkout(workout);
                                        setCheckedItems(
                                          checkedItems.filter(
                                            (i) => i.id !== workout.id
                                          )
                                        );
                                      }}
                                      className="cursor-pointer"
                                    >
                                      <CloseRounded
            sx={{
              fontSize: "24px",
              color: "#000",
              cursor: "pointer",
            }}
          />
                                    </div> */}
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                          {addedWorkouts.length > 10 && (
                            <div
                              style={{ width: "100%" }}
                              className="text-center"
                            >
                              more...
                            </div>
                          )}

                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </Box>
            )}
          </div>
        </div>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", paddingBottom: "24px" }}>
        <button
          className="button-new"
          onClick={() => {
            // Save();
            onReplaceWorkout(checkedItems[0]);
          }}
          disabled={checkedItems.length == 0}
        >
          Save
        </button>
      </DialogActions>
    </Dialog>
  );
};
