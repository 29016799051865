import React from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { TextField } from "@mui/material";

const CustomSelect = ({
  triggerClassName,
  value,
  onValueChange,
  options,
  placeholder,
  isFolder,
  isOpen,
}) => {
  return (
    <Select
      value={value}
      onValueChange={onValueChange}
      {...(isOpen !== undefined && { open: isOpen })}
    >
      <SelectTrigger className={triggerClassName || "w-80"}>
        <SelectValue
          className="text-font14"
          placeholder={placeholder || "Select"}
        />
      </SelectTrigger>
      <SelectContent className="z-[999999]">
        {/* this is for the normal custom select */}
        {!isFolder &&
          options.map((option, index) => (
            <SelectItem
              className="text-font14 "
              key={index}
              value={option.value}
            >
              {option.label}
            </SelectItem>
          ))}
        {isFolder &&
          options?.map((option, index) => (
            <SelectItem className="text-font14 " key={index} value={option}>
              {option?.name}
            </SelectItem>
          ))}
      </SelectContent>
    </Select>
  );
};

export default CustomSelect;
