import React, { useContext, useEffect, useState } from 'react';
import { DialogHeader } from "@/components/ui/dialog";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
 

import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
 
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { TextField } from "@mui/material";
 
import { CloseRounded } from '@mui/icons-material';
import SearchBar from '../WorkoutComponent/SearchBar';
import DatePicker from "react-datepicker";
import moment from 'moment';
import axios from 'axios';
import { store } from '@/redux/store';
import { SpurfitCircularProgress } from '../Dashboard/dashboard';
import { CustomAlertContext } from '@/App';

function SearchWorkoutDialogeForCompare({
  open : openDialoge,
  setOpen : setOpenDialoge,
  currentMonthEnduranceData,
  setSelectedWorkoutToCompare,
  onSuccess,
  date,
  userUUID
}) {
  const [selected, setSelected] = useState("");
  const [loading,setIsLoading]=useState(false)
  const [optionsForCustomSelect, setOptionsForCustomSelect] = useState([]);
  const [searchTerm,setSearchTerm]=useState("")
  const [searchedWorkouts,setSearchedWorkouts]=useState(optionsForCustomSelect||[])
  const [showWorkoutList,setShowWorkoutList]=useState(false)
  const [fromDate, setFromDate] = useState(
    moment().subtract(1, "month").toDate()
    );
  const [toDate, setToDate] = useState(moment().toDate());
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("Last Month");
  const dateData = ["Last Week", "Last Month", "Last 3 Months", "Custom Date"];
  useEffect(() => {
    let optionsForSelect = [];
    let options = { ...currentMonthEnduranceData };
    if (Object.keys(options || {})?.length > 0) {
      Object.keys(options)?.forEach((option) => {
        if (options?.[option]?.length === 1) {
          let data = options[option][0];
          optionsForSelect.push(data);
        } else if (options?.[option]?.length > 1) {
          let data = options[option] || [];
          data?.forEach((element) => {
            optionsForSelect.push(element);
          });
        }
      });
      setOptionsForCustomSelect(optionsForSelect);
      setSearchedWorkouts(optionsForSelect)
    }
  }, [currentMonthEnduranceData]);

    const { displayAlert } = useContext(CustomAlertContext);
 
  const request = (searchTerm) => {
    if (searchTerm === "") {
      setSearchedWorkouts([]) 
      setShowWorkoutList(false);
      return;
    }
    const newSearchedWorkout = optionsForCustomSelect?.filter((workout) => {
      return workout?.summary?.activityName?.toLowerCase().includes(searchTerm.toLowerCase());
    });
    setSearchedWorkouts(newSearchedWorkout);

 

    if (newSearchedWorkout.length > 0) {
      setShowWorkoutList(true);
    } else {
      setShowWorkoutList(false);
    }
  };

  const cancel = () => {
    setSearchedWorkouts(optionsForCustomSelect) 
  };

  const handleDateRange = (rangeType) => {
    console.warn("handle date type", rangeType);
    setValue(rangeType);
    //   setSelectValue(rangeType)
    switch (rangeType) {
      case "Last Week":
        setToDate(moment().toDate());
        setFromDate(moment().subtract(7, "days").toDate());
        break;
      case "Last Month":
        setToDate(moment().toDate());
        console.warn(toDate, "todate");
        console.warn(fromDate, "fromdate");
        setFromDate(moment().subtract(1, "month").toDate());
        break;
      case "Last 3 Months":
        setToDate(moment().toDate());
        setFromDate(moment().subtract(3, "month").toDate());
        break;
      default:
        // For Custom Date, do nothing here
        break;
    }
  };

  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <div onClick={onClick} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', width: '100%' }}>
        <CalendarMonthIcon style={{ fontSize: '16px', color: '#000' }} />
        <input
				    className="react-datepicker__day"
            ref={ref}
            value={value}
            readOnly
            style={{
                width: "90%",
                height: "40px",
                outline: "none",
                color: "#000",
                textAlign: "center",
                fontSize: 14,
                background: '#fff',
                borderRadius: '4px'
            }}
        />
    </div>
));

const handleClick = (event) => {
  setOpen(!open);
};

  const handleLoadMoreData =async ()=>{
    let atheleteID=userUUID
    let data={
      atheleteID:atheleteID,
      fromDate:fromDate,
      toDate:toDate
    }
    setIsLoading(true)
    try {
      let res=await axios({
        method:"post",
         url: `${store.getState().auth.apiURL}CF/api/garmin/getExtraGarminData`,
        data:data,
      })
  
      if(res){
      console.log("resfortheE",res)
      let data=res?.data?.result
      setSearchedWorkouts([...searchedWorkouts,...data])
      setOptionsForCustomSelect([...data,...optionsForCustomSelect])
      }
    } catch (error) {
      displayAlert({
        message: "Error in Loading more data",
        type: "error",
      });
    }finally{
      setIsLoading(false)
    }

  }

  const formateDateToShow=(date)=>{
     if(!date) return null
     let formatedDate=date?.split("T")
     return formatedDate?.[0]
  }


  return (
    <Dialog
      open={openDialoge}
      onClose={() => setOpenDialoge(false)}
       
      PaperProps={{
        sx: {
          borderRadius: "24px",
          width: "900px",
        },
      }}
    >
      <DialogHeader className="border-b-2 border-gray-200 flex flex-row justify-between p-1 items-center">
        <DialogTitle className="text-font18">Search Workout To Compare</DialogTitle>
        <CloseRounded className="mr-2" onClick={() => setOpenDialoge(false)} />
      </DialogHeader>
      <DialogContent className=" w-[100%] font-DMSans h-[420px]">
        {loading ? <div className='w-full h-full left-0 top-0 absolute bg-black-pure/20'>
          <SpurfitCircularProgress/>
          </div>
          
          :
         
        <div className="flex flex-col gap-1 items-center justify-center">

        <SearchBar
          placeholder={"Search for clients"}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          bgcol={"#f2f2f2"}
          request={request}
          cancel={cancel}
          style={{ flex: 1 }}
          width={"100%"}
          fontSize={"16px"}
          iconStyle={{ width: "20px", height: "20px" }}
        />
        <div className=' flex flex-col gap-1 overflow-y-scroll h-[300px] w-full  '> 
       {searchedWorkouts?.map((workout) => (
        <div className='flex justify-between  px-4 py-2 hover:bg-gray-100 rounded-lg cursor-pointer'
        onClick={()=>{
          setSelectedWorkoutToCompare(workout);
          onSuccess()
        }}>
        <p className='font-DMSans'>{workout?.summary?.activityName}</p> 
        <p className='font-DMSans'>{formateDateToShow(workout?.dateRecorded)||" "}</p>
        </div>
       ))}
       </div>

           
          <div className='my-auto flex justify-start'>
            
            <div onClick={handleClick} style={{width:310}} className="flex items-center z-auto ">
							<div style={{width:"40%",
                maxWidth:"40%"
              }}>
                <DatePicker
                    maxDate={new Date()}
                    dateFormat="dd-MMM-yyy"
                    selected={fromDate}
                    disabled={
                        value == "Custom Date"
                            ? false
                            : true
                    }
                    onChange={(date) => setFromDate(date)}
										customInput={<CustomInput />}
                />
							</div>
							<div style={{width:"5%", justifyContent:'center', alignItems:'center', display:'flex'}}> 
									<label class="cursor-pointer  text-font14 text-center">
										-{" "}
									</label>
							</div>
							<div style={{width:"50%", paddingLeft:10,
                 maxWidth:"50%"
              }}>
									<DatePicker
										className="chat-header-view"
										maxDate={new Date()}
										dateFormat="dd-MMM-yyy"
										selected={toDate}
										disabled={
												value == "Custom Date"
														? false
														: true
										}
										onChange={(date) => setToDate(date)}
										customInput={<CustomInput />}
								/>
							</div>
            </div>
            <div className="flex items-center">
                <Autocomplete
                    value={value}
                    getOptionLabel={(option) => option}
                    onChange={(_, newValue) =>
                        handleDateRange(newValue)
                    }
                    id="date-range-dropdown"
                    options={dateData}
                    sx={{
                        width: 180,
                        fontSize: 8,
                        fontFamily:"DMSans",
                        marginLeft:0,
                        paddingLeft:0,
                        "& .MuiAutocomplete-listbox": {
                            maxHeight: "150px",
                            fontSize: "10px", // Change font size of dropdown options
                            
                        },
                        "& .MuiInputBase-root": {
                            borderRadius: "8px",
                            border: "1px solid rgba(131, 48, 233, 0.20)",
                            height: 40,
                            padding: "0 14px",
                            backgroundColor: "#FFF",
                        },
                        "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                                borderRadius: "8px",
                            },
                            "&.Mui-focused fieldset": {
                                borderColor:
                                    "rgba(131, 48, 233, 0.20)",
                            },
                        },
                        "& .MuiInputLabel-root": {
                            lineHeight: "40px",
                            transform:
                                "translate(14px, 10px) scale(1)",
                        },
                        "& .MuiAutocomplete-option": {
                            fontSize: "16px", // Change font size of dropdown options
                            "&:hover": {
                                backgroundColor:
                                    "rgb(246, 246, 254)", // Change background color on hover
                            },
                        },
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder="Select Date Range"
                            InputProps={{
                                ...params.InputProps,
                                style: {
                                    height: 40,
                                    borderRadius: "8px",
                                    border: "1px solid rgba(131, 48, 233, 0.20)",
                                    fontSize: 14,
                                },
                            }}
                            InputLabelProps={{
                                ...params.InputLabelProps,
                                shrink: true,
                                style: {
                                    fontSize: 14,
                                    lineHeight: "40px",
                                    top: "-10px",
                                    transform:
                                        "translate(14px, 10px) scale(1)",
                                },
                            }}
                        />
                    )}
                />
						</div>
            <p className="text-[14px] font-[500] gradient-text  ml-5 cursor-pointer"
            onClick={()=>{
              handleLoadMoreData()
            }}>
               Confirm Selection
            </p>
            </div>
             
       
        </div>
}
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
}

export default SearchWorkoutDialogeForCompare;



 

const CustomSelect = ({
  triggerClassName,
  value,
  onValueChange,
  options,
  placeholder,
}) => {
  const arrOfOptions=[];
  // useEffect(() => {
  //   if (Object.keys(options || {})?.length > 0) {
  //     Object.keys(options)?.forEach((option)=>{
  //       if(options?.[option]?.length === 1){
  //         let data=options[option][0]
  //           arrOfOptions.push(data)
  //       }else if(options?.[option]?.length > 1){
  //          let data= options[option] || []
  //           data?.forEach((element) => {
  //             arrOfOptions.push(element)
  //           });
  //       }
  //     })
  //   }
  // }, [options]);
  return (
    <Select value={value} onValueChange={onValueChange}>
      <SelectTrigger className={triggerClassName || "w-80"}>
        <SelectValue
          className="text-font14"
          placeholder={placeholder || "Select"}
        />
      </SelectTrigger>
      <SelectContent className="z-[999999]">
        {options.length > 0 &&
          options?.map((option, index) => (
            <SelectItem className="text-font14 " key={index} value={option}>
              {option?.summary?.activityName}
            </SelectItem>
          ))}
      </SelectContent>
    </Select>
  );
};

 

 
