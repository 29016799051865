import moment from "moment";
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import AllImagesModal from "../Dashboard/AllImagesModal";
import WeightsChart from "./charts/weights";
import AllMeasurementsChart from "./charts/allMeasurements";
import FirstValidChart, {
  isThereAnyValidMeasurement,
} from "./charts/getFirstValidChart";
import { Typography } from "@mui/material";
import RenderStepsChart from "./charts/RenderStepsChart";
import StepsChart from "./charts/steps";

const SummarSection2 = ({ data, hideCompliance, isSmallerThanMd }) => {
  const [showWeightsChart, setShowWeightsChart] = useState(false);
  const [showAllMeasurementsChart, setShowAllMeasurementsChart] =
    useState(false);
  const [showAllImages, setShowAllImages] = useState(false);
  const [imagesToShow, setImagesToShow] = useState([]);
  const [showStepsChart, setShowStepsChart] = useState(false);

  const bodyStatsData = data?.bodyStatsData;
  const userName = data?.userInfo?.name;
  let programName =
    data?.programData?.[0]?.programAssigned?.name ??
    "No training program assigned.";
  const bodyweight = getLatestWeightFromBodyStats(data?.bodyStatsData);

  useEffect(() => {
    if (!bodyStatsData) return;
    let images = [];
    bodyStatsData.forEach((item) => {
      if (
        item?.progressImages &&
        item?.progressImages?.length > 0 &&
        images.length < 3
      ) {
        images.push(...item.progressImages);
      }
    });
    // slice image to 3
    if (images.length > 3) {
      images = images.slice(0, 3);
    }
    setImagesToShow(images);
  }, [data?.bodyStatsData]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#F8F8F8",
        width: "100%",
        minWidth: 400,
      }}
      className={`${
        hideCompliance ? (isSmallerThanMd ? "zoomedBody" : "") : "px-2  md:px-6"
      } `}
    >
      {/* users program  */}
      {/* <div>
        <p
          style={{
            fontFamily: "DM Sans",
            fontSize: "14px",
            fontWeight: 600,
          }}
        >
          {userName}'s Program
        </p>
        <p
          style={{
            fontFamily: "DM Sans",
            fontSize: "14px",
            fontWeight: 400,
            color: "#424242",
          }}
        >
          {programName}
        </p>
      </div>
      <hr color="#ececec" /> */}
      {/* This component is beight used in client profile as well as in new clients view in clients view we don't need to show exercise compliance in this part that is why condition is there */}
      {!hideCompliance && (
        <div
          style={{
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.03)",
            marginBottom: "15px",
          }}
        >
          <div
            style={{
              padding: "12px 24px",
              borderBottom: "1px solid #EBEBEB",
            }}
          >
            <p
              style={{
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "29px",
              }}
            >
              Exercise Compliance
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 24px",
            }}
          >
            <ComplianceCard
              title={"This Week"}
              value={data?.currentWeekCompliance}
            />
            <ComplianceCard
              title={"1 Week ago"}
              value={data?.lastWeekCompliance}
            />
            <ComplianceCard
              title={"2 Weeks ago"}
              value={data?.twoWeeksAgoCompliance}
            />
          </div>
        </div>
      )}

      <>
        <div
          style={{
            cursor: "pointer",
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.03)",
            marginBottom: "15px",
          }}
          onClick={() => {
            setShowWeightsChart(true);
          }}
        >
          <div
            style={{
              padding: "12px 24px",
              borderBottom: "1px solid #EBEBEB",
            }}
          >
            <p
              style={{
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "29px",
              }}
            >
              Body Weight
            </p>
          </div>

          {bodyweight ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px 0px",
              }}
            >
              <RenderWeightsChart data={data?.bodyStatsData} />
            </div>
          ) : (
            <p
              style={{
                fontFamily: "DM Sans",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "29px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px 0px",
              }}
            >
              No data uploaded yet!
            </p>
          )}
        </div>
      </>

      <div
        style={{
          cursor: "pointer",
          backgroundColor: "#FFFFFF",
          borderRadius: "10px",
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.03)",
          marginBottom: "15px",
        }}
        onClick={() => {
          setShowStepsChart(true);
        }}
      >
        <div
          style={{
            padding: "12px 24px",
            borderBottom: "1px solid #EBEBEB",
          }}
        >
          <p
            style={{
              fontFamily: "DM Sans",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "29px",
            }}
          >
            Steps
          </p>
        </div>

        <RenderStepsChart data={data?.activityData} />
      </div>

      {
        <>
          <div
            style={{
              cursor: "pointer",
              backgroundColor: "#FFFFFF",
              borderRadius: "10px",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.03)",
              marginBottom: "15px",
            }}
          >
            <div
              style={{
                padding: "12px 24px",
                borderBottom: "1px solid #EBEBEB",
              }}
            >
              <p
                style={{
                  fontFamily: "DM Sans",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  lineHeight: "29px",
                }}
              >
                Physique Pictures
              </p>
            </div>
            {/* <p
                style={{
                  fontFamily: "DM Sans",
                  fontSize: "14px",
                  fontWeight: 500,
                  cursor: "pointer",
                }}
                onClick={() => setShowAllImages(true)}
              >
                more...
              </p> */}
            {imagesToShow?.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "10px 24px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  {imagesToShow.map((image) => {
                    return (
                      <div
                        style={{
                          height: 64,
                          width: 64,
                          display: "inline-block",
                          borderRadius: "8px",
                          marginRight: "10px",
                        }}
                      >
                        <img
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                            borderRadius: "8px",
                          }}
                          src={image}
                          alt="progress"
                        />
                      </div>
                    );
                  })}
                </div>
                <button
                  style={{
                    backgroundColor: "#F8F8F8",
                    borderRadius: "8px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "19px 16px",
                  }}
                  onClick={() => setShowAllImages(true)}
                >
                  <span
                    style={{
                      backgroundImage: "linear-gradient(#464FEB, #8330E9)",
                      WebkitTextFillColor: "transparent",
                      WebkitBackgroundClip: "text",
                      fontFamily: "DM Sans",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "700",
                    }}
                    onClick={() => setShowAllImages(true)}
                  >
                    View More
                  </span>
                </button>
              </div>
            ) : (
              <p
                style={{
                  fontFamily: "DM Sans",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "29px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "10px 0px",
                }}
              >
                No images uploaded yet!
              </p>
            )}
          </div>
        </>
      }

      {isThereAnyValidMeasurement(data?.bodyStatsData) ? (
        <>
          <div
            style={{
              cursor: "pointer",
              backgroundColor: "#FFFFFF",
              borderRadius: "10px",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.03)",
              marginBottom: "15px",
            }}
          >
            <div
              style={{
                padding: "12px 24px",
                borderBottom: "1px solid #EBEBEB",
              }}
            >
              <p
                style={{
                  fontFamily: "DM Sans",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  lineHeight: "29px",
                }}
              >
                Measurements
              </p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "10px 24px",
              }}
            >
              <FirstValidChart data={bodyStatsData} />
              <button
                style={{
                  backgroundColor: "#F8F8F8",
                  borderRadius: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "19px 16px",
                }}
                onClick={() => setShowAllMeasurementsChart(true)}
              >
                <span
                  style={{
                    backgroundImage: "linear-gradient(#464FEB, #8330E9)",
                    WebkitTextFillColor: "transparent",
                    WebkitBackgroundClip: "text",
                    fontFamily: "DM Sans",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "700",
                  }}
                  onClick={() => setShowAllMeasurementsChart(true)}
                >
                  View More
                </span>
              </button>
            </div>
          </div>
        </>
      ) : (
        <div
          style={{
            cursor: "pointer",
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.03)",
            marginBottom: "15px",
          }}
        >
          <div
            style={{
              padding: "12px 24px",
              borderBottom: "1px solid #EBEBEB",
            }}
          >
            <p
              style={{
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "29px",
              }}
            >
              Measurements
            </p>
          </div>
          <p
            style={{
              fontFamily: "DM Sans",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "29px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px 0px",
            }}
          >
            No measurements uploaded yet!
          </p>
        </div>
      )}
      {showAllImages && (
        <AllImagesModal
          isOpen={showAllImages}
          setIsOpen={setShowAllImages}
          bodyStatsData={bodyStatsData}
        />
      )}

      {showWeightsChart && (
        <WeightsChart
          isOpen={showWeightsChart}
          setIsOpen={setShowWeightsChart}
          data={bodyStatsData}
        />
      )}

      {showAllMeasurementsChart && (
        <AllMeasurementsChart
          isOpen={showAllMeasurementsChart}
          setIsOpen={setShowAllMeasurementsChart}
          data={bodyStatsData}
        />
      )}
      {showStepsChart && (
        <StepsChart
          isOpen={showStepsChart}
          setIsOpen={setShowStepsChart}
          data={data?.activityData}
          key={data?.activityData?.length}
        />
      )}
    </div>
  );
};

export default SummarSection2;

export const ComplianceCard = ({ title, value }) => {
  return (
    <div
      style={{
        borderRight: title === "2 Weeks ago" ? "" : "1px solid #E5E5E5",
        width: "28%",
      }}
    >
      <Typography
        sx={{
          fontFamily: "DM Sans",
          fontSize: ["14px", "14px", "16px"],
          fontWeight: 400,
          color: "#424242",
          lineHeight: "22px",
          color: "#424242",
        }}
      >
        {title}
      </Typography>
      <p
        style={{
          fontFamily: "DM Sans",
          fontSize: "16px",
          fontWeight: 700,
          marginTop: 3,
          lineHeight: "24px",
          color: "black",
        }}
      >
        {value ?? 0} %
      </p>
    </div>
  );
};

const RenderWeightsChart = ({ data }) => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Weight",
        data: [],
        fill: false,
        borderColor: "#0E9CFF",
        tension: 0,
        backgroundColor: "#ffffff",
      },
    ],
  });

  useEffect(() => {
    if (!data || data.length === 0) {
      return;
    }

    // Sort data by date in ascending order
    data.sort((a, b) => new Date(a.dateRecorded) - new Date(b.dateRecorded));

    const dateLabels = [];
    const weightData = [];

    data.forEach((item) => {
      if (item?.weight?.value) {
        let modifiedDate = moment(item.dateRecorded).format("Do MMM' YY");
        dateLabels.push(modifiedDate);
        weightData.push(item?.weight?.value);
      }
    });

    setChartData((prevChartData) => ({
      ...prevChartData,
      labels: dateLabels,
      datasets: [
        {
          ...prevChartData.datasets[0],
          data: weightData,
        },
      ],
    }));
  }, [data]);
  const options = {
    scales: {
      y: {
        ticks: {
          display: false, // Hide y-axis values
        },
        grid: {
          display: false, // Hide vertical grid lines
        },
      },
      x: {
        ticks: {
          display: false, // Hide x-axis values
        },
        grid: {
          display: false, // Hide horizontal grid lines
        },
      },
    },
    plugins: {
      legend: {
        display: false, // Hide the legend (dataset visibility toggle)
      },
    },
    elements: {
      point: {
        radius: 5,
      },
    },
    borderWidth: 2,
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "350px",
      }}
    >
      <Line data={chartData} options={options} />
    </div>
  );
};

const RenderBodyFatPercentageChart = ({ data }) => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Weight",
        data: [],
        fill: false,
        borderColor: "rgb(70, 79, 235)",
        tension: 0.1,
      },
    ],
  });

  useEffect(() => {
    if (!data || data.length === 0) {
      return;
    }

    // Sort data by date in ascending order
    data.sort((a, b) => new Date(a.dateRecorded) - new Date(b.dateRecorded));

    const dateLabels = [];
    const fatData = [];

    data.forEach((item) => {
      if (item?.measurements?.mainMeasurements?.fat?.value) {
        let modifiedDate = moment(item.dateRecorded).format("Do MMM' YY");
        dateLabels.push(modifiedDate);
        fatData.push(item?.measurements?.mainMeasurements?.fat?.value);
      }
    });

    setChartData((prevChartData) => ({
      ...prevChartData,
      labels: dateLabels,
      datasets: [
        {
          ...prevChartData.datasets[0],
          data: fatData,
        },
      ],
    }));
  }, [data]);

  const options = {
    scales: {
      y: {
        title: {
          display: true,
          text: "Fat %",
        },
        ticks: {
          display: false, // Hide y-axis values
        },
        grid: {
          display: false, // Hide vertical grid lines
        },
      },
      x: {
        ticks: {
          display: false, // Hide x-axis values
        },
        grid: {
          display: false, // Hide horizontal grid lines
        },
      },
    },
    plugins: {
      legend: {
        display: false, // Hide the legend (dataset visibility toggle)
      },
    },
    borderWidth: 1,
  };

  return (
    <div
      style={{
        height: 100,
      }}
    >
      <Line data={chartData} options={options} />
    </div>
  );
};

const getLatestWeightFromBodyStats = (bodyStatsData) => {
  if (!bodyStatsData || bodyStatsData?.length === 0) return "";

  bodyStatsData.sort(
    (a, b) => new Date(b.dateRecorded) - new Date(a.dateRecorded)
  );
  let latestWeight = null;
  bodyStatsData?.forEach((item) => {
    if (!latestWeight && item?.weight?.value) {
      latestWeight = item?.weight?.value;
      if (item?.weight?.unit) {
        latestWeight += " " + item?.weight?.unit;
      }
      return;
    }
  });
  return latestWeight;
};
