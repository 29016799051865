import React, { useContext, useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Pencil } from "lucide-react";
import { Input } from "@/components/ui/input";
import { ClientsViewContext } from "@/pages/clientsView";
import moment from "moment";
import { updateFinishedWorkoutName } from "@/ApiServices/Garmin/Api";
import { CustomAlertContext } from "@/App";
import { SpurfitCircularProgress } from "@/components/Dashboard/dashboard";

const EditFinishedWorkoutName = ({ name, index, data }) => {
  const [workoutName, setWorkoutName] = useState(name || "");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const clientsViewContext = useContext(ClientsViewContext);
  const { displayAlert } = useContext(CustomAlertContext);

  useEffect(() => {
    setWorkoutName(name);
  }, [name]);

  const onSave = async () => {
    if (clientsViewContext?.updateSingleEnduranceFinishedData) {
      try {
        setLoading(true);
        let res = await updateFinishedWorkoutName(data?.summaryID, workoutName);

        let date = data?.dateRecorded;
        date = moment(date).format("YYYY-MM-DD");
        let updatedData = { ...data };
        updatedData.summary.activityName = workoutName;
        clientsViewContext.updateSingleEnduranceFinishedData(
          data.atheleteID,
          date,
          index,
          updatedData
        );

        displayAlert({
          message: "Workout name updated successfully",
          type: "success",
        });
      } catch (error) {
        console.log("error", error);
        displayAlert({
          message: "Error updating workout name",
          type: "error",
        });
      } finally {
        setLoading(false);
        setOpen(false);
      }
    }
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(isOpen) => setOpen(isOpen)}
      aria-label="Edit Workout Name"
    >
      <DialogTrigger>
        <Pencil size={16} />
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="text-font18">Update Name?</DialogTitle>
        </DialogHeader>
        <div className="pt-1">
          <Input
            value={workoutName}
            onChange={(e) => setWorkoutName(e.target.value)}
            placeholder="Enter workout name"
            className="focus-visible:ring-transparent border-2 focus-visible:border-indigo-500 w-full"
          />
        </div>
        <div className="w-full flex items-center justify-center">
          <button className="button-new" onClick={onSave} disabled={loading}>
            {loading ? <SpurfitCircularProgress /> : "Save"}
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default EditFinishedWorkoutName;
