import { useContext, useEffect, useState } from "react";
import { ProgramsContext } from "./ProgramsContext";

import SearchBar from "@/components/Dashboard/SearchBar";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { ChevronsLeft, ChevronsRight } from "lucide-react";
import AddProgram from "./AddProgram";
import ProgramList from "./ProgramList";

import { useSelector } from "react-redux";
import Filter from "./utils/Filter";
import ProgramFolderList from "./ProgramFolderList";
import WhitePlusIcon from "@/pages/library/assests/WhitePlusIcon";

const ProgramsList = () => {
  const {
    isProgramsListOpen,
    setIsProgramsListOpen,
    programs,
    templates,
    folders,
    subbordinateCoachPrograms,
    onSearch,
    onSearchCancel,
    adminID,
    isAdmin,
    addProgram,
    createNewFolder,
    selectedTab,
    setSelectedTab,
    subbordinateCoachesNames,
    subbordinateCoachFolders,
    handleFilter,
    setFolderSelected,
    templateFolders,
  } = useContext(ProgramsContext);
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const width = isProgramsListOpen ? "25%" : "0";

  const [selectedFilter, setSelectedFilter] = useState(null);

  return (
    <div
      className={`h-full transition-width duration-500 ease-in-out relative rounded-l-xl border-r-2 border-gray-stone`}
      style={{ width: width, minWidth: width, maxWidth: width }}
    >
      <div
        onClick={() => setIsProgramsListOpen((prev) => !prev)}
        className={`${
          isProgramsListOpen ? "absolute -right-3" : "absolute -right-4"
        } bg-white-pure rounded-full shadow-xl p-[0.5px] cursor-pointer`}
      >
        {!isProgramsListOpen ? <ChevronsRight /> : <ChevronsLeft />}
      </div>

      {/* {isProgramsListOpen && (
        <div className="w-full flex items-center py-2 mb-1 border-b ">
          <SearchBar
            request={onSearch}
            cancel={onSearchCancel}
            bgcol={"#F2F2F2"}
            width={"100%"}
            conatinerStyle={{ width: "100%" }}
          />
          {(isAdmin || adminID) && (
            <div className="cursor-pointer mr-">
              {showFilteredIcon ? (
                <img src={FilterActiveIcon} alt />
              ) : (
                <img src={FilterIcon} alt />
              )}
            </div>
          )}
        </div>
      )} */}
      <div className="flex justify-between items-center px-2 mt-2">
        <Tabs
          defaultValue="programs"
          className={`w-full h-full ${
            isProgramsListOpen ? "" : "overflow-hidden"
          }`}
          value={selectedTab}
          onValueChange={(value) => {
            setSelectedTab(value);
          }}
        >
          <TabsList className={`grid max-w-full grid-cols-3 mx-1 relative  `}>
            {/* <TabsTrigger
              className="font-DMSans sm:text-font12 md:text-font14 lg:text-font16"
              value="folders"
            >
              Folders
            </TabsTrigger> */}
            <TabsTrigger
              className="font-DMSans sm:text-font12 md:text-font14 lg:text-font16"
              value="programs"
            >
              Programs
            </TabsTrigger>
            <TabsTrigger
              className="font-DMSans sm:text-font12 md:text-font14 lg:text-font16"
              value="templates"
            >
              Templates
            </TabsTrigger>
            <TabsTrigger
              className="font-DMSans sm:text-font12 md:text-font14 lg:text-font16"
              value="others"
            >
              Others
              {/* {selectedTab == "others" && (
                <div className="ml-1">
                  <DropDownMenu
                    triggerElement={
                      <div className="">
                        <img
                          src={FilterIcon}
                          alt="filter"
                          className="w-[30px] h-[30px] cursor-pointer"
                        />
                      </div>
                    }
                    options={["Active", "Inactive"]}
                  />
                </div>
              )} */}
            </TabsTrigger>
          </TabsList>
          <div className="w-full flex items-center py-2 mb-1 border-b">
            <SearchBar
              request={onSearch}
              cancel={onSearchCancel}
              bgcol={"#F2F2F2"}
              width={"100%"}
              conatinerStyle={{ width: "100%" }}
            />
            {(isAdmin || adminID) && (
              <div className="cursor-pointer mr-">
                <Filter
                  selectedFilter={selectedFilter}
                  setSelectedFilter={setSelectedFilter}
                  handleFilter={handleFilter}
                  selectedTab={selectedTab}
                  subbordinateCoachesNames={subbordinateCoachesNames}
                />
              </div>
            )}
          </div>

          <TabsContent
            value="programs"
            className="overflow-y-scroll overflow-x-hidden"
            style={{ height: "calc(100vh - 220px)" }}
          >
            <div className="w-full h-full flex flex-col gap-3">
              <ProgramFolderList
                folders={folders}
                adminID={adminID}
                key={"folders"}
              />
              <ProgramList
                programs={programs}
                adminID={adminID}
                key={"programs"}
              />
            </div>
          </TabsContent>
          <TabsContent
            value="templates"
            className="overflow-y-scroll overflow-x-hidden"
            style={{ height: "calc(100vh - 220px)" }}
          >
            <div className=" w-full h-full flex flex-col gap-3">
              <ProgramFolderList
                folders={templateFolders}
                adminID={adminID}
                key={"templateFolders"}
              />
              <ProgramList
                programs={templates}
                adminID={adminID}
                key="templates"
              />
            </div>
          </TabsContent>
          <TabsContent
            value="others"
            className="overflow-y-scroll overflow-x-hidden"
            style={{ height: "calc(100vh - 220px)" }}
          >
            <div className="w-full h-full flex flex-col gap-3">
              <ProgramFolderList
                folders={subbordinateCoachFolders}
                isSubbordinate={true}
                key={"templateFolders"}
              />
              <ProgramList
                programs={subbordinateCoachPrograms}
                isSubbordinate={true}
                key="others"
              />
            </div>
          </TabsContent>
        </Tabs>
      </div>
      {selectedTab != "others" && (
        <div
          className={`${
            !isProgramsListOpen ? "hidden" : "block"
          } absolute bottom-1 right-1`}
        >
          <AddProgram
            isTemplate={selectedTab === "templates"}
            isFolder={selectedTab === "folders"}
            addProgram={addProgram}
            createNewFolder={createNewFolder}
            isCreateOpen={isCreateOpen}
            setIsCreateOpen={setIsCreateOpen}
            isIcon={true}
          />
        </div>
      )}
    </div>
  );
};

export default ProgramsList;
