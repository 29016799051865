import React from "react";
import CheckBox, { CheckBoxLabel } from "../../AddProperitesHelpers";

const PropertyHeader = ({ value, onClick, label, onlyLabel, isDisabled }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: 4,
        pointerEvents: isDisabled ? "none" : "auto",
        opacity: isDisabled ? 0.5 : 1,
      }}
      key={label}
    >
      <CheckBox value={value} onClick={onClick} />
      <CheckBoxLabel label={label} onlyLabel={onlyLabel} />
    </div>
  );
};

export default PropertyHeader;
