import { SpurfitCircularProgress } from '@/components/Dashboard/dashboard'

const Loader = () => {
    return (
        <div className='fixed h-screen w-full bg-black-pure/50 z-[9999] right-0 top-0'>
            <SpurfitCircularProgress size={24} />
        </div>
    )
}

export default Loader