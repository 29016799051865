import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { useLocation, useParams, useHistory } from "react-router-dom";
import Logo from "../../assets/spurfit.svg";
import Select from "@mui/material/Select";
import { useFormik } from "formik";
import { getFormById, submitForm } from "@/ApiServices/FormControl/Api";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import {
  Autocomplete,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  FormGroup,
  Typography,
  Stack,
  Box,
  InputAdornment,
} from "@mui/material";
import "./style.css";
import { MultilineInputComponent } from "../trainerSignup";
import { SpurfitCircularProgress } from "@/components/Dashboard/dashboard";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import WestIcon from "@mui/icons-material/West";
import axios from "axios";
import { useSelector } from "react-redux";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";

const dateStyles = {
  datePickerStyles: {
    border: "none",
    outline: "none",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#ccc",
        borderRadius: "12px",
        outline: "none",
      },
      "&:hover fieldset": {
        borderColor: "#4339f2",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#4339f2",
        boxShadow: "0px 0px 0px 3px rgba(67, 57, 242, 0.25)",
      },
    },
  },
};

function Form({ fieldsData, handleClose, isFromCopyClipboard = false }) {
  const history = useHistory();

  const { pathname } = useLocation();

  const APIURL = useSelector((state) => state.auth.apiURL);

  const trainerData = useSelector((state) => state.auth.data);

  const queryParams = new URLSearchParams(location.search);
  const formId = queryParams.get("formId");
  const formName = queryParams.get("formName");
  const formAssignedID = queryParams.get("formAssignedID");
  const atheteId = queryParams.get("atheteId");

  const theme = useTheme();
  const { id } = useParams();
  const [activeStep, setActiveStep] = React.useState(0);
  const [formValues, setFormValues] = useState([]);
  const [fields, setFields] = useState([]);
  const [deviceHeight, setDeviceHeight] = useState(window.innerHeight);
  const [errors, setErrors] = useState({});
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
  const [loading, setLoading] = useState(false);
  const [currentAnswer, setCurrentAnswer] = useState(null);
  const [submittingData, setSubmittingData] = useState({});
  const [changeUnits, setChangeUnits] = useState(false);
  const [heightInFeetAndInches, setHeightInFeetAndInches] = useState({
    feet: "",
    inch: "",
    cm: "",
  });

  const handleSubmit = async () => {
    const updatedFormValues = await handleNext();
    console.warn("submit called", updatedFormValues);
    const data = {
      athleteID: atheteId,
      athleteFormAssignmentID: formAssignedID,
      formID: formId,
      responses: updatedFormValues,
    };
    if (atheteId && formAssignedID) {
      setLoading(true);
      let res = await submitForm(data);
      setLoading(false);
      if (res.status === 200) {
        history.push("/form-success");
      }
    } else {
      history.push("/form-success");
    }
  };

  const handleSubmitFormData = async (e) => {
    e.preventDefault();
    const {
      "Your Name": name,
      "Your Email": email,
      ...newValue
    } = submittingData;

    const dataToSend = [];

    Object.keys(newValue).map((item) => {
      dataToSend.push({
        question: item,
        answer: newValue[item],
        type: fields?.find((field) => field?.value == item)?.type,
      });
    });

    const res = await axios({
      method: "post",
      url: `${APIURL}CF/api/LeadGen/insertNewLeadFromForm`,
      data: {
        coachID: id,
        name,
        email,
        answeredForm: dataToSend,
      },
    });

    if (res?.status == 200) {
      history.push("/form-success");
      setTimeout(() => {
        history.push("/home");
      }, 1500);
    }
  };

  const handleNext = async () => {
    return new Promise((resolve, reject) => {
      const currentStepFields = fields[activeStep];
      console.warn(currentStepFields.id, formValues);
      let hasError = false;
      const newErrors = {};

      if (currentStepFields.required && !currentAnswer) {
        newErrors[currentStepFields.id] = "This field is required";
        hasError = true;
      }

      if (hasError) {
        console.warn("inside error", newErrors);
        setErrors(newErrors);
      } else {
        setFormValues((prevValues) => {
          const updatedValues = [
            ...prevValues,
            { ...fields[activeStep], answer: currentAnswer },
          ];
          if (fields.length - 1 > activeStep) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }
          setCurrentAnswer(null);
          setErrors({});
          resolve(updatedValues);
          return updatedValues;
        });
      }
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleInputChange = (name, value) => {
    const filteredValue = name.value.toLowerCase().includes("weight")
      ? `${value} ${changeUnits ? "lbs" : "Kg"}`
      : value;
    setSubmittingData({
      ...submittingData,
      [name.value]: filteredValue,
    });
    setCurrentAnswer(filteredValue);
  };

  const handleMultiSelectChange = (name, value, option) => {
    let answer = currentAnswer ? currentAnswer : [];
    answer.push(option);
    setCurrentAnswer(answer);
    setSubmittingData({
      ...submittingData,
      [name.value]: answer,
    });
  };

  const handleHeightChange = (field, valueObject) => {
    const filteredValue =
      valueObject && !changeUnits
        ? `${valueObject?.feet}-${valueObject?.inch}`
        : valueObject?.cm;

    setSubmittingData({
      ...submittingData,
      [field.value]: filteredValue,
    });
    setCurrentAnswer(filteredValue);
  };

  useEffect(() => {
    if (fieldsData?.length) {
      setFields(fieldsData);
    }
  }, [fieldsData]);

  useEffect(() => {
    const handleResize = () => {
      setDeviceHeight(window.innerHeight);
      setDeviceWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // async function getForm() {
  //   setLoading(true);
  //   const forms = await getFormById(formId);
  //   console.warn(forms, "forms*******");
  //   if (forms && forms.result) {
  //     if (forms?.result?.form?.length > 0) {
  //       setFields(forms?.result?.form);
  //     }
  //   }
  //   setLoading(false);
  // }

  const renderStepContent = (step) => {
    const field = fields[step];
    switch (field.type) {
      case "Single Select":
        return (
          <section>
            <div
              style={{
                padding: ".6rem .4rem",
              }}
            >
              <Stack textAlign={"center"} m={"1.5rem 0 2.5rem 0"}>
                <Typography variant="h5" fontWeight={"bold"}>
                  {field?.value}
                </Typography>
              </Stack>
              <Stack
                sx={{
                  height: "40vh",
                  overflow: "auto",
                }}
              >
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  onChange={(e) => handleInputChange(field, e.target.value)}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    gap: 2,
                  }}
                  control={
                    <Radio
                      sx={{
                        "&.MuiButtonBase-root-MuiRadio-root": {
                          color: "#8330e9",
                        },
                        "& .MuiSvgIcon-root": {
                          color: "#8330e9",
                        },
                      }}
                    />
                  }
                  name={field.id}
                >
                  {field.possibleAnswers.map((option, idx) => (
                    <FormControlLabel
                      className="font-DMSans tracking-wide text-sm items-center w-full"
                      sx={{
                        border: "1px solid #E5E7EB",
                        padding: ".4rem .6rem",
                        borderRadius: 5,
                        fontWeight: 500,
                        textAlign: "center",
                      }}
                      key={`${option}-${idx}`}
                      value={option}
                      control={<Radio color="royalBlue" />}
                      label={option}
                    />
                  ))}
                </RadioGroup>
              </Stack>
              {/* <ShowError
                error={formik.touched[field.id] && formik.errors[field.id]}
                errorMessage={formik.errors[field.id]}
              /> */}
            </div>
          </section>
        );
      case "Multi Select":
        return (
          <section>
            <div style={{ width: "100%", padding: ".6rem .4rem" }}>
              <Stack textAlign={"center"} m={"1.5rem 0 2.5rem 0"}>
                <Typography variant="h5" fontWeight={"bold"}>
                  {field?.value}
                </Typography>
              </Stack>
              <Stack
                sx={{
                  height: "40vh",
                  overflow: "auto",
                }}
              >
                <FormGroup
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    gap: 2,
                  }}
                >
                  {field.possibleAnswers.map((option) => (
                    <FormControlLabel
                      key={option}
                      control={
                        <Checkbox
                          sx={{
                            "& .MuiSvgIcon-root": {
                              color: "#8330e9",
                            },
                          }}
                        />
                      }
                      label={option}
                      onChange={(e) =>
                        handleMultiSelectChange(field, e.target.checked, option)
                      }
                      className="font-DMSans tracking-wide text-sm items-center w-full"
                      sx={{
                        border: "1px solid #E5E7EB",
                        padding: ".4rem .6rem",
                        borderRadius: 5,
                        fontWeight: 500,
                        textAlign: "center",
                        "& .Mui-checked": {
                          color: "#8330e9", // Color when checked
                        },
                      }}
                    />
                  ))}
                </FormGroup>
              </Stack>
            </div>
          </section>
        );
      case "Multi-line type":
        return (
          <section>
            <div style={{ width: "100%", padding: ".6rem .4rem" }}>
              <Stack textAlign={"center"} m={"1.5rem 0 2.5rem 0"}>
                <Typography variant="h5" fontWeight={"bold"}>
                  {field?.value}
                </Typography>
              </Stack>
              <MultilineInputComponent
                key={field.id}
                value={submittingData[field.value]}
                sx={{
                  border: "1px solid #E5E7EB",
                  padding: ".4rem .6rem",
                  borderRadius: 5,
                  fontWeight: 500,
                  textAlign: "center",
                }}
                label=""
                placeholder=""
                type="text"
                error={field.value && field.value}
                onChange={(e) => handleInputChange(field, e.target.value)}
              />
            </div>
          </section>
        );
      case "Number Select":
        return (
          <section>
            <div style={{ width: "100%", padding: ".6rem .4rem" }}>
              {field.value.toLowerCase().includes("weight") && (
                <>
                  <Stack textAlign={"center"} m={"1.5rem 0 2.5rem 0"}>
                    <Typography variant="h5" fontWeight={"bold"}>
                      {field?.value}
                    </Typography>
                  </Stack>
                  <TextField
                    value={
                      submittingData[field.value]
                        ? submittingData[field.value].split(" ")[0]
                        : ""
                    }
                    type="number"
                    sx={{
                      padding: ".4rem .6rem",
                      borderRadius: 5,
                      fontWeight: 500,
                      textAlign: "center",
                      width: "100%",
                    }}
                    onChange={(e) => {
                      const input = e.target.value;
                      const regex = /^[0-9\b]+$/;
                      if (input == "" || regex.test(input))
                        handleInputChange(field, input);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {changeUnits ? "lbs" : "Kg"}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Typography
                    variant="caption"
                    fontWeight={"bold"}
                    margin={"1rem auto"}
                    display={"block"}
                    textAlign={"center"}
                    color={theme.palette.primary.main}
                    onClick={() => {
                      setChangeUnits((prev) => !prev);
                      setHeightInFeetAndInches({ inch: "", cm: "", feet: "" });
                    }}
                  >
                    {changeUnits
                      ? "switch to Kilograms (Kg)"
                      : "switch to Pounds (lbs)"}
                  </Typography>
                </>
              )}

              {field.value.toLowerCase().includes("height") && (
                <>
                  {!changeUnits ? (
                    <>
                      <Stack textAlign={"center"} m={"1.5rem 0 2.5rem 0"}>
                        <Typography variant="h5" fontWeight={"bold"}>
                          {field?.value}
                        </Typography>
                      </Stack>
                      <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <TextField
                          value={
                            heightInFeetAndInches?.feet
                              ? heightInFeetAndInches?.feet?.split(" ")[0]
                              : null
                          }
                          type="number"
                          sx={{
                            padding: ".4rem .6rem",
                            borderRadius: 5,
                            fontWeight: 500,
                            textAlign: "center",
                            width: "100%",
                          }}
                          onChange={(e) => {
                            const input = e.target.value;
                            console.log({ input });
                            const regex = /^[0-9\b]+$/;
                            if (input == "" || regex.test(input)) {
                              const valueObject = {
                                ...heightInFeetAndInches,
                                feet: `${input ? input : "0"} ft`,
                              };
                              setHeightInFeetAndInches(valueObject);
                              handleHeightChange(field, valueObject);
                            }
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {"ft"}
                              </InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          value={
                            heightInFeetAndInches?.inch
                              ? heightInFeetAndInches?.inch.split(" ")[0]
                              : null
                          }
                          type="number"
                          sx={{
                            padding: ".4rem .6rem",
                            borderRadius: 5,
                            fontWeight: 500,
                            textAlign: "center",
                            width: "100%",
                          }}
                          onChange={(e) => {
                            const input = e.target.value;
                            const regex = /^[0-9\b]+$/;
                            if (input == "" || regex.test(input)) {
                              const valueObject = {
                                ...heightInFeetAndInches,
                                inch: `${input ? input : "0"} in`,
                              };
                              setHeightInFeetAndInches(valueObject);
                              handleHeightChange(field, valueObject);
                            }
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {"in"}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Stack>
                    </>
                  ) : (
                    <>
                      <Stack textAlign={"center"} m={"1.5rem 0 2.5rem 0"}>
                        <Typography variant="h5" fontWeight={"bold"}>
                          {field?.value}
                        </Typography>
                      </Stack>
                      <TextField
                        value={
                          heightInFeetAndInches?.cm
                            ? heightInFeetAndInches?.cm.split(" ")[0]
                            : null
                        }
                        type="number"
                        sx={{
                          padding: ".4rem .6rem",
                          borderRadius: 5,
                          fontWeight: 500,
                          textAlign: "center",
                          width: "100%",
                        }}
                        onChange={(e) => {
                          const input = e.target.value;
                          const regex = /^[0-9\b]+$/;
                          if (input == "" || regex.test(input)) {
                            const valueObject = {
                              ...heightInFeetAndInches,
                              cm: `${input ? input : "0"} cm`,
                            };
                            setHeightInFeetAndInches(valueObject);
                            handleHeightChange(field, valueObject);
                          }
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {"cm"}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </>
                  )}
                  <Typography
                    variant="caption"
                    fontWeight={"bold"}
                    textAlign={"center"}
                    margin={"1rem auto"}
                    display={"block"}
                    color={theme.palette.primary.main}
                    onClick={() => {
                      setChangeUnits((prev) => !prev);
                      setHeightInFeetAndInches({ inch: "", cm: "", feet: "" });
                    }}
                  >
                    {!changeUnits
                      ? "switch to centimetres (cm)"
                      : "switch to feet and inches (ft/in)"}
                  </Typography>
                </>
              )}
              {!field.value.toLowerCase().includes("weight") &&
                !field.value.toLowerCase().includes("height") && (
                  <>
                    <Stack textAlign={"center"} m={"1.5rem 0 2.5rem 0"}>
                      <Typography variant="h5" fontWeight={"bold"}>
                        {field?.value}
                      </Typography>
                    </Stack>
                    <TextField
                      type="number"
                      value={submittingData[field.value]}
                      sx={{
                        padding: ".4rem .6rem",
                        borderRadius: 5,
                        fontWeight: 500,
                        textAlign: "center",
                        width: "100%",
                      }}
                      onChange={(e) => {
                        const input = e.target.value;
                        const regex = /^[0-9\b]+$/;
                        if (input == "" || regex.test(input))
                          handleInputChange(field, input);
                      }}
                    />
                  </>
                )}
            </div>
          </section>
        );
      case "Date":
        return (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <section>
              <div style={{ width: "100%", padding: ".6rem .4rem" }}>
                <Stack textAlign={"center"} m={"1.5rem 2.5rem 0"}>
                  <Typography variant="h5" fontWeight={"bold"}>
                    {field?.value}
                  </Typography>
                </Stack>
                <Stack textAlign={"center"} m={"1.5rem 2.5rem 0"}>
                  <DatePicker
                    value={
                      submittingData[field.value]
                        ? moment(submittingData[field.value], "DD-MM-YYYY")
                        : null
                    }
                    sx={dateStyles.datePickerStyles}
                    onChange={(newValue) =>
                      handleInputChange(
                        field,
                        moment(newValue).format("DD-MM-YYYY")
                      )
                    }
                    renderInput={(params) => (
                      <TextField fullWidth variant="standard" {...params} />
                    )}
                  />
                </Stack>
              </div>
            </section>
          </LocalizationProvider>
        );

      default:
        return (
          <section>
            <div style={{ width: "100%", padding: ".6rem .4rem" }}>
              <Stack textAlign={"center"} m={"1.5rem 0 2.5rem 0"}>
                <Typography variant="h5" fontWeight={"bold"}>
                  {field?.value}
                </Typography>
              </Stack>
              <TextField
                type="text"
                fullWidth
                value={submittingData[field.value]}
                key={field.id}
                label=""
                placeholder=""
                onChange={(e) => handleInputChange(field, e.target.value)}
              />
            </div>
          </section>
        );
    }
  };
  return (
    <div
      className="main"
      style={{
        minHeight: 0,
        height: 0,
      }}
    >
      <div
        style={{ position: "relative" }}
        class="width-large"
        id="client-layout-phonebox"
      >
        {loading && <SpurfitCircularProgress />}
        {fields && fields.length > 0 ? (
          <div className="m-auto w-full">
            <div
              className="flex h-full grow flex-col"
              style={{ height: deviceHeight - 135 }}
            >
              <div>
                <div>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <img
                          style={{
                            width: "54px",
                            height: "54px",
                            borderRadius: "50%",
                          }}
                          src={
                            trainerData?.customLogo
                              ? trainerData?.customLogo
                              : Logo
                          }
                          alt=""
                          className="logo-image"
                        />
                      </div>
                      <div className="font-DMSans text-font24 font-bold">
                        {formName}
                      </div>
                      {pathname && pathname.includes("form") && (
                        <div>
                          <CloseIcon
                            fontSize="28"
                            color="gray"
                            onClick={handleClose}
                          />
                        </div>
                      )}
                    </div>
                    {renderStepContent(activeStep)}
                    <ShowError
                      error={errors[fields[activeStep].id]}
                      errorMessage={errors[fields[activeStep].id]}
                    />
                  </div>
                </div>
              </div>
              <div>
                <MobileStepper
                  variant="dots"
                  steps={fields.length}
                  position="bottom"
                  activeStep={activeStep}
                  sx={{
                    "& .MuiMobileStepper-dotActive": {
                      backgroundColor: "#8330e9", // Change the dot color here
                    },
                    maxWidth: 400,
                    flexGrow: 1,
                    position: "absolute",
                    bottom: 20,
                  }}
                  nextButton={
                    <Button
                      sx={{
                        padding: ".7rem 1.5rem",
                        borderRadius: 5,
                        color: "#ffffff",
                        background:
                          "linear-gradient(360deg, #464feb 10.42%, #8330e9 100%), #fff !important",
                      }}
                      size="small"
                      onClick={
                        isFromCopyClipboard && activeStep == fields.length - 1
                          ? handleSubmitFormData
                          : activeStep == fields.length - 1
                          ? handleClose
                          : handleNext
                      }
                      // disabled={
                      //   !isFromCopyClipboard && activeStep === fields.length - 1
                      // }
                    >
                      {activeStep == fields.length - 1 ? "Submit" : "Next"}
                      {theme.direction === "rtl" ? (
                        <KeyboardArrowLeft />
                      ) : (
                        <KeyboardArrowRight />
                      )}
                    </Button>
                  }
                  backButton={
                    <Button
                      style={{ color: "#8330e9" }}
                      size="small"
                      onClick={handleBack}
                      disabled={activeStep === 0}
                    >
                      {theme.direction === "rtl" ? (
                        <KeyboardArrowRight />
                      ) : (
                        <WestIcon />
                      )}
                    </Button>
                  }
                ></MobileStepper>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Form;

export const ShowError = ({ error, errorMessage }) => {
  return error ? (
    <div
      style={{
        fontSize: 14,
        color: "#ff0000",
        marginLeft: "12px",
      }}
      className="text-sm tracking-wide font-DMSans"
    >
      {errorMessage}
    </div>
  ) : null;
};
