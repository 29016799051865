import React from "react";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import moment from "moment";
import { Skeleton } from "@/components/ui/skeleton";
import { cn } from "@/lib/utils";

const BillingDetails = ({ billingHistory, loading, countryCode }) => {
  // const getPlanName = (amount) => {
  //   if (amount === 1000) {
  //     return "Sliver";
  //   } else if (amount === 100000) {
  //     return "Gold";
  //   } else if (amount === 1500) {
  //     return "Platinum";
  //   }
  // };
  const locale = countryCode === "IN" ? "en-IN" : "en-US";

  return (
    <div
      className={cn(
        "w-full flex flex-col border rounded-xl",
        !loading ? "" : "mt-2"
      )}
      // style={{ height: "fit-content" }}
    >
      <div className="bg-gray-400 p-4 rounded-t-xl">
        <span className="font-DMSans font-normal text-font18 text-white-pure">
          Billing History
        </span>
      </div>
      {loading ? (
        <Skeleton
          className="w-full"
          style={{
            height: "calc(100vh - 420px)",
          }}
        />
      ) : (
        <Table className="min-w-fit">
          <TableHeader className="bg-gray-100">
            <TableRow className="py-2">
              <TableHead className="py-2">Amount</TableHead>
              <TableHead className="text-center">Plan Name</TableHead>
              <TableHead className="text-right">Charged Date</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {billingHistory.map((invoice) => (
              <TableRow key={invoice.invoiceID}>
                <TableCell>
                  {/* {symbols[invoice.currency]} */}
                  {new Intl.NumberFormat(locale).format(
                    invoice.amountPaid / 100
                  )}{" "}
                  {`${invoice.currency}`.toUpperCase()}
                </TableCell>
                <TableCell className="text-center">Spur.fit Pro</TableCell>
                <TableCell className="text-right">
                  {moment(invoice.chargedDate * 1000).format("Do MMM YYYY")}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          {/* <TableFooter>
          <TableRow>
            <TableCell colSpan={3}>Total</TableCell>
            <TableCell className="text-right">$2,500.00</TableCell>
          </TableRow>
        </TableFooter> */}
        </Table>
      )}
    </div>
  );
};

export default BillingDetails;

const symbols = {
  INR: "₹",
  USD: "$",
  EUR: "€",
};
