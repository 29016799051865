import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Avatar, useChatContext } from "stream-chat-react";

import "./TeamChannelPreview.css";

import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { Popover } from "@mui/material";
import { debounce } from "lodash";
import moment from "moment";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { getClientProfileSummary } from "../../../../ApiServices/ClientProfileApi/Api";
import { SpurfitCircularProgress } from "../../../../components/Dashboard/dashboard";
import { chatClient } from "../../../../utils/chat";
import { HashIcon, UserIcon } from "../../assets";
import { TeamTypingIndicator } from "../TeamTypingIndicator/TeamTypingIndicator";

export const TeamChannelPreview = (props) => {
  const { channel, setActiveChannel, setIsCreating, setIsEditing, type } =
    props;

  const location = useLocation();

  const searchPrarams = new URLSearchParams(location.search);

  const colors = [
    "#CCF8FE",
    "#FFE5D3",
    "#DAD7FE",
    "#E2FBD7",
    "#CCF8FE",
    "#FFE5D3",
    "#E2FBD7",
    "#DAD7FE",
    "#CCF8FE",
  ];

  let i = 0;

  const { channel: activeChannel, client } = useChatContext();

  const ChannelPreview = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "95%",
      }}
    >
      <p className="channel-preview__item">
        <HashIcon />
        {"  "} {channel?.data?.name || channel?.data?.id || "random"}
      </p>
      {channel.state.unreadCount > 0 && (
        <p className="channel-preview__item-undread">
          {channel.state.unreadCount}
        </p>
      )}
    </div>
  );

  const DirectPreview = () => {
    const members = Object.values(channel.state.members).filter(
      ({ user }) => user.id !== client.userID
    );
    const defaultName = "Johnny Blaze";
    const [loading, setLoading] = useState(false);
    const [clientDetails, setClientDetails] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const [usersData, setUsersData] = useState({});
    const popoverRef = useRef(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
      if (
        anchorEl &&
        popoverRef.current &&
        popoverRef.current.contains(event.target)
      ) {
        setAnchorEl(null);
      }
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    let clientId = members[0]?.user?.id;

    const getClientDetails = async (id) => {
      if (clientDetails?.userInfo?.email) return;
      if (usersData[id]) {
        setClientDetails(usersData[id]);
        return;
      }

      setLoading(true);
      const details = await getClientProfileSummary(id);
      setUsersData({
        ...usersData,
        [id]: details,
      });
      setLoading(false);
      setClientDetails(details);
    };

    const getLatestWeightFromBodyStats = (bodyStatsData) => {
      let latestWeight = 0;
      let unit = "kg";
      bodyStatsData?.forEach((item) => {
        if (!latestWeight && item?.weight?.value) {
          latestWeight = item?.weight?.value;
          unit = item?.weight?.unit;
          return;
        }
      });
      if (latestWeight > 0) {
        latestWeight += " " + unit;
      }
      return latestWeight;
    };

    const getLastAssignedWorkoutDate = () => {
      try {
        let date = moment(clientDetails?.lastWorkoutAssignedDate).format(
          "DD/MM/YYYY"
        );
        return date?.includes("Invalid") ? null : date;
      } catch (error) {
        return null;
      }
    };

    useEffect(() => {
      getClientDetails(clientId);
    }, []);
    if (!members.length || members.length === 1) {
      return (
        <div className="channel-preview__item single">
          {members[0]?.user.image ? (
            <Avatar
              image={members[0]?.user.image || undefined}
              name={members[0]?.user.name || members[0]?.user.id}
              size={32}
            />
          ) : (
            <div
              className="channel-preview__item_avatar"
              style={{
                backgroundColor: colors[i++ / 7],
              }}
            >
              <UserIcon />
            </div>
          )}
          <p className="channel-preview__item">
            {members[0]?.user.name || members[0]?.user.id || defaultName}
          </p>
          {channel.state.unreadCount > 0 && (
            <p className="channel-preview__item-undread">
              {channel.state.unreadCount}
            </p>
          )}
          <div onMouseEnter={handleClick} onMouseLeave={handleClose}>
            <InfoRoundedIcon fontSize="sm" />
          </div>
          <TeamTypingIndicator type="list" />
          <Popover
            ref={popoverRef}
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            slotProps={{
              paper: {
                sx: {
                  borderRadius: "12px",
                  maxHeight: "290px",
                  minWidth: "250px",
                  zIndex: "9999 !important",
                },
              },
            }}
          >
            {loading ? (
              <div className="flex items-center justify-center w-full h-[100px]">
                <SpurfitCircularProgress />
              </div>
            ) : (
              <div
                style={{
                  gap: 0,
                }}
                onMouseLeave={handleClose}
                className="popover-container"
              >
                <div>
                  <Link
                    to={`/client/${members[0]?.user?.id}/`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      padding: 0,
                      margin: 0,
                      fontSize: 16,
                    }}
                  >
                    {members[0]?.user.name ||
                      members[0]?.user.id ||
                      defaultName}
                  </Link>
                </div>
                <div
                  style={{
                    fontSize: 12,
                    fontFamily: "DM Sans",
                    marginBottom: "16px",
                  }}
                >
                  {clientDetails?.userInfo?.email}
                </div>

                {clientDetails?.programData?.[0]?.programAssigned?.name && (
                  <RenderOneProperty
                    label="Program"
                    value={
                      clientDetails?.programData?.[0]?.programAssigned?.name
                    }
                  />
                )}
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    rowGap: "10px",
                    columnGap: "16px",
                  }}
                >
                  {clientDetails?.globalStats?.daysWorkedout != undefined &&
                    clientDetails?.globalStats?.daysWorkedout != null && (
                      <RenderOneProperty
                        label="Workouts Completed"
                        value={clientDetails?.globalStats?.daysWorkedout}
                      />
                    )}
                  {clientDetails?.globalStats?.globalAdherence != undefined &&
                    clientDetails?.globalStats?.globalAdherence != null && (
                      <RenderOneProperty
                        label="Adherence"
                        value={
                          clientDetails?.globalStats?.globalAdherence + "%"
                        }
                      />
                    )}
                  {getLastAssignedWorkoutDate() && (
                    <RenderOneProperty
                      label="Last Workout Assigned"
                      value={getLastAssignedWorkoutDate()}
                    />
                  )}
                  {clientDetails?.bodyStatsData && (
                    <RenderOneProperty
                      label="Weight"
                      value={getLatestWeightFromBodyStats(
                        clientDetails?.bodyStatsData
                      )}
                    />
                  )}
                  {clientDetails?.activityData?.[0]?.activitiesFinished
                    ?.stepCount !== undefined && (
                    <RenderOneProperty
                      label="Steps"
                      value={
                        +clientDetails?.activityData?.[0]?.activitiesFinished
                          ?.stepCount
                      }
                    />
                  )}
                  {clientDetails?.activityData?.[0]?.activitiesFinished
                    ?.sleep != undefined && (
                    <RenderOneProperty
                      label="Sleep"
                      value={
                        +clientDetails?.activityData?.[0]?.activitiesFinished
                          ?.sleep + " hrs"
                      }
                    />
                  )}
                </div>
              </div>
            )}
          </Popover>
        </div>
      );
    }

    return (
      <div className="channel-preview__item multi">
        <span>
          <Avatar
            image={members[0]?.user.image || undefined}
            name={members[0]?.user.name || members[0]?.user.id}
            size={18}
          />
        </span>
        <div
          className="channel-preview__item_avatar"
          style={{
            backgroundColor: "rgb(255, 229, 211)",
          }}
        >
          <UserIcon />
        </div>
        <p>
          {members[0]?.user.name || members[0]?.user.id || defaultName},{" "}
          {members[1]?.user.name || members[1]?.user.id || defaultName}
        </p>
      </div>
    );
  };

  const debouncedSetCustomChannel = debounce(async () => {
    try {
      let channelId = searchPrarams.get("cid");

      if (channelId) {
        const channel = chatClient.channel("messaging", channelId);
        await channel.watch();
        setActiveChannel(channel);
      }
    } catch (error) {
      console.log("error in setCustomChannel", error);
    }
  }, 50);

  useEffect(() => {
    debouncedSetCustomChannel();
  }, []);

  return (
    <div
      className={
        channel?.id === activeChannel?.id
          ? "channel-preview__wrapper__selected"
          : "channel-preview__wrapper"
      }
      onClick={() => {
        setIsCreating(false);
        setIsEditing(false);
        setActiveChannel(channel);
      }}
    >
      <div
        className={
          channel?.id === activeChannel?.id
            ? "channel-preview__wrapper_indicator__selected"
            : "channel-preview__wrapper_indicator"
        }
      ></div>
      {type === "team" ? <ChannelPreview /> : <DirectPreview />}
    </div>
  );
};

const RenderOneProperty = ({ label, value }) => {
  if (!value) return null;
  return (
    <div>
      <div
        style={{
          fontFamily: "DM Sans",
          fontSize: 12,
          fontWeight: 400,
          color: "#424242",
        }}
      >
        {label}
      </div>

      <div
        style={{
          fontFamily: "DM Sans",
          fontSize: 15,
          fontWeight: 500,
          color: "#000",
        }}
      >
        {value}
      </div>
    </div>
  );
};
