import React from "react";

const Summary = ({ totalTime, totalDistance }) => {
  return (
    <div className="w-full h-full flex flex-col gap-4">
      <div className="w-full flex items-center justify-between border-b px-4 h-[56px]">
        <p className="text-font16 font-bold font-DMSans">Summary</p>
        <img
          width="40"
          height="40"
          src="https://img.icons8.com/ios/50/running--v1.png"
          alt="running--v1"
        />
      </div>
      <div className="px-4 ">
        <SummaryItem
          title="Total Time"
          value={formatTime(totalTime)}
          unit="s"
        />
        <SummaryItem
          title="Total Distance"
          value={totalDistance ? totalDistance?.toFixed(2) : ""}
          unit={"km"}
        />
        {/* <SummaryItem title="Total Score" value="25" unit="TSS" /> */}
      </div>
    </div>
  );
};

export default Summary;

const SummaryItem = ({ title, value, unit }) => (
  <div className="w-full py-4 flex items-center justify-between">
    <span className="font-DMSans text-[#424242] text-font16 font-normal">
      {title}:
    </span>{" "}
    <span className="text-black-pure text-font18 font-bold">
      {value} {unit}
    </span>
  </div>
);

const formatTime = (timeInSeconds) => {
  const hours = Math.floor(timeInSeconds / 3600);
  const minutes = Math.floor((timeInSeconds % 3600) / 60);
  const seconds = timeInSeconds % 60;

  const paddedMinutes = String(minutes).padStart(2, "0");
  const paddedSeconds = String(seconds.toFixed(0)).padStart(2, "0");

  if (hours === 0) return `${paddedMinutes}:${paddedSeconds}`;
  return `${hours}:${paddedMinutes}:${paddedSeconds}`;
};
