import moment from "moment";
const getDaysAgo = (date) => {
  const today = moment();
  const dateRecorded = moment(date);
  return today.diff(dateRecorded, "days");
};

export const getLatestProgressImageDateFromBodyStats = (bodyStatsData) => {
  bodyStatsData?.sort(
    (a, b) => new Date(b.dateRecorded) - new Date(a.dateRecorded)
  );
  let latestProgressImageDate = null;
  bodyStatsData?.forEach((item) => {
    if (
      !latestProgressImageDate &&
      item?.progressImages?.length > 0 &&
      item?.progressImages?.[0]
    ) {
      let dateRecorded = item?.dateRecorded;
      let daysAgo = getDaysAgo(dateRecorded);
      latestProgressImageDate = ""; // moment(dateRecorded).format('Do MMM YYYY');
      if (daysAgo == 0) {
        latestProgressImageDate += ` Today`;
      } else if (daysAgo == 1) {
        latestProgressImageDate += ` Yesterday`;
      } else if (daysAgo > 1) {
        latestProgressImageDate += ` ${daysAgo} days ago`;
      }

      return;
    }
  });
  console.log("latestProgressImageDate", latestProgressImageDate);
  return latestProgressImageDate;
};

export const getDaysLeft = async (userInfo, setDaysLeft, inDays) => {
  if (userInfo?.planEndDate_LivEzy) {
    let totalDays = moment(userInfo?.planEndDate_LivEzy).diff(moment(), "days");
    if (totalDays < 0) totalDays = 0;
    if (inDays) {
      setDaysLeft(totalDays);
    } else {
      let weeks = Math.floor(totalDays / 7);
      let remainingDays = totalDays % 7;
      let text = `${weeks} week ${remainingDays} days`;
      if (totalDays <= 0) text = "Expired";
      setDaysLeft(text);
    }
  } else {
    setDaysLeft("");
  }
  // if (userInfo?.resumeDate_LivEzy && userInfo?.timeLeft_LivEzy) {
  //   let resumedDate = moment(userInfo.resumeDate_LivEzy);
  //   let totalDays = resumedDate
  //     .add(userInfo?.timeLeft_LivEzy, "days")
  //     .diff(moment(), "days");
  //   if (totalDays < 0) totalDays = 0;
  //   let weeks = Math.floor(totalDays / 7);
  //   let remainingDays = totalDays % 7;

  //   let text = `${weeks} week ${remainingDays} days`;
  //   if (totalDays < 0) text = "Expired";
  //   setDaysLeft(text);
  // } else if (userInfo?.planStartDate_LivEzy && userInfo?.planDuration_LivEzy) {
  //   let planStartDate_LivEzy = moment(userInfo?.planStartDate_LivEzy).format(
  //     "DD-MM-YYYY"
  //   );

  //   let planDuration_LivEzy = userInfo?.planDuration_LivEzy;

  //   let totalDays = moment(planStartDate_LivEzy, "DD-MM-YYYY")
  //     .add(planDuration_LivEzy, "days")
  //     .diff(moment(), "days");
  //   let weeks = Math.floor(totalDays / 7);
  //   let remainingDays = totalDays % 7;

  //   let text = `${weeks} weeks ${remainingDays} day${
  //     remainingDays > 1 ? "s" : ""
  //   }`;
  //   if (totalDays < 0) text = "Expired";
  //   setDaysLeft(text);
  // } else {
  //   setDaysLeft("");
  // }
};

export const UserMetric = ({ label, value }) => {
  let isExpired = value === "Expired";
  return (
    <div
      className="flex flex-col"
      style={{
        width: "210px",
        padding: "12px 16px 12px 16px",
        borderRadius: "12px",
        borderColor: "#EBEBEB",
        borderWidth: "1px",
      }}
    >
      <p
        style={{
          fontFamily: "DM Sans",
          fontWeight: 500,
          fontSize: "13px",
          lineHeight: "21px",
          color: "#424242",
        }}
      >
        {label}
      </p>
      <p
        style={{
          fontFamily: "DM Sans",
          fontWeight: 700,
          fontSize: "16px",
          color: isExpired ? "#cb2416" : "#000000",
        }}
      >
        {value ?? "Not added"}
      </p>
    </div>
  );
};

export const getWeightFormatted = (latestWeight, targetWeight) => {
  let weightFormatted = "";
  if (latestWeight) {
    weightFormatted = `${latestWeight}`;
  }
  if (targetWeight && targetWeight != null) {
    if (typeof targetWeight == "string" && targetWeight.includes("-")) {
      targetWeight = targetWeight.replace("-", " ");
    } else {
      weightFormatted += `/${targetWeight} kg`;
    }
  }
  return weightFormatted;
};

export const getLastWorkoutDate = (data = {}) => {
  const lastWorkoutDate = data.LastWorkoutDate
    ? moment(data.LastWorkoutDate)
    : null;
  const garminLastWorkoutDate = data.garminLastWorkoutDate
    ? moment(data.garminLastWorkoutDate)
    : null;

  let maxDate = null;
  if (lastWorkoutDate && garminLastWorkoutDate) {
    maxDate = moment.max(lastWorkoutDate, garminLastWorkoutDate);
  } else if (lastWorkoutDate) {
    maxDate = lastWorkoutDate;
  } else if (garminLastWorkoutDate) {
    maxDate = garminLastWorkoutDate;
  }

  return maxDate ? maxDate.format("Do MMM' YY") : "";
};
