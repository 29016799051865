import { store } from "@/redux/store";
import axios from "axios";

const WORKOUTPLAN = "create_workoutplan";
const MEALPLAN = "create_mealplan";
const GENERATE_WORKOUT = "generate_workout";
const UPDATE_WORKOUTPLAN = "update_workoutplan";
const UPDATE_MEALPLAN = "update_mealplan";
const LIKE = "like";
const DISLIKE = "dislike";
const ASSIGN = "assign";
const REGENERATE = "regenerate_workout_plan";
const UPDATE_SINGLE_WORKOUT = "update__workout";
const REGENERATE_WORKOUT = "regenerate_workout";
const GENERATE_RECIPE = "generate_recipe";
const REGENERATE_RECIPE = "regenerate_recipe";
const SINGLE_MEALPLAN = "single_mealplan";

export const TrackingActions = {
  WORKOUTPLAN,
  MEALPLAN,
  SINGLE_MEALPLAN,
  UPDATE_WORKOUTPLAN,
  UPDATE_MEALPLAN,
  LIKE,
  DISLIKE,
  ASSIGN,
  REGENERATE,
  UPDATE_SINGLE_WORKOUT,
  GENERATE_WORKOUT,
  REGENERATE_WORKOUT,
  GENERATE_RECIPE,
  REGENERATE_RECIPE,
};

export const CreateConverstaionTracking = async ({
  actionName,
  prompt,
  aiResponse,
  updateTrackingData,
}) => {
  await axios({
    method: "post",
    url: `${store.getState().auth.apiURL}CF/api/createCoPilotConversation`,
    data: {
      coachId: "PXqGTqMrfblJ",
      action: actionName,
      prompt: {
        prompt: prompt,
      },
      aiResponse: {
        aiResponse: aiResponse,
      },
    },
  })
    .then((res) => {
      updateTrackingData(res.data);
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const UpdateConverstaionTracking = async ({
  id,
  secondaryActions,
  updateTrackingData,
}) => {
  await axios({
    method: "post",
    url: `${
      store.getState().auth.apiURL
    }CF/api/updateCoPilotConversation?id=${id}`,
    data: {
      coachId: "PXqGTqMrfblJ",
      secondaryActions: secondaryActions,
    },
  })
    .then((res) => {
      console.log(res);
      updateTrackingData(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const PerformSecondaryActionTracking = async ({
  id,
  secondaryActionName,
  secondaryActions,
  response,
  updateTrackingData,
}) => {
  if (!secondaryActions || !secondaryActions?.actions) {
    secondaryActions = {
      actions: [],
    };
  }
  secondaryActions.actions.push({
    action: secondaryActionName,
    response: response,
  });

  await UpdateConverstaionTracking({
    id: id,
    secondaryActions: secondaryActions,
    updateTrackingData: updateTrackingData,
  });
};
