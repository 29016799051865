import React from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { HelpCircle } from "lucide-react";

import Block1 from "@/assets/EnduranceWorkoutBuilder/Block1.svg";
import Block2 from "@/assets/EnduranceWorkoutBuilder/Block2.svg";
import Block3 from "@/assets/EnduranceWorkoutBuilder/Block3.svg";
import Block4 from "@/assets/EnduranceWorkoutBuilder/Block4.svg";
import Block5 from "@/assets/EnduranceWorkoutBuilder/Block5.svg";
import Block6 from "@/assets/EnduranceWorkoutBuilder/Block6.svg";
import CustomSelect from "./utils/CustomSelect";
import CustomTooltip from "./utils/CustomTooltip";
import { cn } from "@/lib/utils";
import { Tooltip } from "@mui/material";

const UnitBlocks = ({
  selectedUnit,
  setSelectedUnit,
  addOnClick,
  isBlocksInColumns,
  setIsBlocksInColumns,
}) => {
  const blocksArr = [Block3, Block2, Block1, Block4, Block5, Block6];
  const blockNames = [
    "Warm Up",
    "Active",
    "Cool Down",
    "Two Step Repeats",
    "Ramp Up",
    "Ramp Down",
  ];

  return (
    <div
      className={cn("shadow-lg rounded-xl bg-white-pure p-[16px] h-full", {
        "flex justify-between mt-5 max-w-full": isBlocksInColumns,
      })}
    >
      <div className={cn("flex items-center justify-between", {})}>
        <div className="font-DMSans font-bold text-font16 text-[#242424]">
          Click or drag the blocks to build workout
        </div>
        <Tooltip
          title={
            "Use these blocks to get started, then adjust duration, distance and intensity based on your sepecific workout and training methodology."
          }
          direction={"right"}
        >
          <HelpCircle color="#757575" className="cursor-pointer" />
        </Tooltip>
      </div>
      <Droppable
        droppableId="dontdrop"
        direction="horizontal"
        isDropDisabled={true}
      >
        {(provided) => (
          <div
            className={cn("flex gap-2 p-4 flex-wrap justify-evenly", {
              "p-0": isBlocksInColumns,
            })}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {blocksArr.map((block, index) => {
              return (
                <Draggable
                  draggableId={`unit-${index}`}
                  index={index}
                  key={index}
                >
                  {(provided) => (
                    <Tooltip title={blockNames[index]}>
                      <div
                        key={index}
                        className="flex items-end rounded-xl overflow-hidden"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        onClick={() => addOnClick(index)}
                      >
                        <img
                          src={block}
                          className="w-[40px] h-[40px] md:w-[50px] md:h-[50px] xl:w-[80px] xl:h-[80px]"
                          alt="block"
                        />
                      </div>
                    </Tooltip>
                  )}
                </Draggable>
              );
            })}
            {/* {existingBlocks.map((blocks, index) => {
              // create rectangle for each block
              return (
                <Draggable
                  draggableId={`unit-${index}`}
                  index={index}
                  key={index}
                >
                  {(provided) => (
                    <div
                      key={index}
                      className="flex items-end border"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {blocks.data.map((block, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              width: block.width / 10,
                              height: block.height / 10,
                              border: "solid 1px #ddd",
                              background: "#f0f0f0",
                            }}
                          >
                            {index}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </Draggable>
              );
            })} */}
          </div>
        )}
      </Droppable>

      <div
        className={cn("max-w-fit", {
          "flex items-center w-full justify-between gap-4": isBlocksInColumns,
        })}
      >
        <div className={"flex flex-col"}>
          <p className="font-bold text-font16">Unit</p>
          <CustomSelect
            className={
              "w-full focus:ring-0 focus:ring-offset-0 focus:border-[#4339F2] border-[1.5px] rounded-[12px] h-[48px] font-DMSans text-font16 font-medium text-[#242424]"
            }
            value={selectedUnit}
            onValueChange={(value) => setSelectedUnit(value)}
            options={[
              {
                label: "% Threshold Pace",
                value: "precentThresholdPaceRangeInMinPerKm",
              },
              {
                label: "% Threshold Heart Rate",
                value: "precentThresholdHeartRateRangeInBpm",
              },
              {
                label: "% Maximum Heart Rate",
                value: "precentMaximumHeartRateRangeInBpm",
              },
              // { label: '% FTP', value: 'percentFTPRangeInW' },
            ]}
          />
        </div>
        {/*<button*/}
        {/*  onClick={() => setIsBlocksInColumns(!isBlocksInColumns)}*/}
        {/*  className="w-fit px-2 mt-4 bg-[#4339F2] text-white-pure font-DMSans font-bold text-font16 py-2 rounded-xl"*/}
        {/*>*/}
        {/*  Switch to {isBlocksInColumns ? "Rows" : "Columns"}*/}
        {/*</button>*/}
      </div>
    </div>
  );
};

export default UnitBlocks;

export const existingBlocks = [
  // warmup
  {
    data: [
      {
        blockIdx: 0,
        exerciseIdx: 0,
        width: 200,
        height: 100,
        exercise: {
          type: "warmup",
          name: "Warm Up",
          distance: 2,
          distanceUnit: "Kilometers",
          durationInSeconds: 20 * 60,
          precentThresholdPaceRangeInMinPerKm: {
            min: 71,
            max: 80,
            heartRateZone: 1,
          },
          precentThresholdHeartRateRangeInBpm: {
            min: 70,
            max: 80,
            heartRateZone: 1,
          },
          precentMaximumHeartRateRangeInBpm: {
            min: 60,
            max: 68,
            heartRateZone: 1,
          },
          percentFTPRangeInW: { min: 71, max: 80, powerZone: 2 },
        },
      },
    ],
    repeat: 1,
  },
  // active
  {
    data: [
      {
        blockIdx: 1,
        exerciseIdx: 0,
        width: 800,
        height: 200,
        exercise: {
          name: "Active",
          type: "ACTIVE",
          distance: 8,
          distanceUnit: "Kilometers",
          durationInSeconds: 50 * 60,
          precentThresholdPaceRangeInMinPerKm: {
            min: 105,
            max: 115,
            heartRateZone: 2,
          },
          precentThresholdHeartRateRangeInBpm: {
            min: 105,
            max: 115,
            heartRateZone: "5C",
          },
          precentMaximumHeartRateRangeInBpm: {
            min: 89,
            max: 98,
            heartRateZone: "5C",
          },
          percentFTPRangeInW: { min: 105, max: 115, powerZone: 5 },
        },
      },
    ],
    repeat: 1,
  },
  // cooldown
  {
    data: [
      {
        blockIdx: 2,
        exerciseIdx: 0,
        width: 100,
        height: 100,
        exercise: {
          type: "cooldown",
          name: "Cool Down",
          distance: 1,
          distanceUnit: "Kilometers",
          durationInSeconds: 10 * 60,
          precentThresholdPaceRangeInMinPerKm: {
            min: 71,
            max: 80,
            heartRateZone: 1,
          },
          precentThresholdHeartRateRangeInBpm: {
            min: 71,
            max: 80,
            heartRateZone: 1,
          },
          precentMaximumHeartRateRangeInBpm: {
            min: 60,
            max: 68,
            heartRateZone: 1,
          },
          percentFTPRangeInW: { min: 71, max: 80, powerZone: 2 },
        },
      },
    ],
    repeat: 1,
  },
  // Two Step Repeats
  {
    data: [
      {
        blockIdx: 3,
        exerciseIdx: 0,
        width: 200,
        height: 200,
        exercise: {
          name: "Hard",
          type: "ACTIVE",
          distance: 2,
          distanceUnit: "Kilometers",
          durationInSeconds: 12 * 60,
          precentThresholdPaceRangeInMinPerKm: {
            min: 105,
            max: 115,
            heartRateZone: 2,
          },
          precentThresholdHeartRateRangeInBpm: {
            min: 105,
            max: 115,
            heartRateZone: "5C",
          },
          precentMaximumHeartRateRangeInBpm: {
            min: 89,
            max: 98,
            heartRateZone: "5C",
          },
          percentFTPRangeInW: { min: 105, max: 115, powerZone: 5 },
        },
      },
      {
        blockIdx: 3,
        exerciseIdx: 1,
        width: 100,
        height: 300,
        exercise: {
          name: "Easy",
          type: "ACTIVE",
          distance: 1,
          distanceUnit: "Kilometers",
          durationInSeconds: 8 * 60,
          precentThresholdPaceRangeInMinPerKm: {
            min: 75,
            max: 85,
            heartRateZone: 2,
          },
          precentThresholdHeartRateRangeInBpm: {
            min: 75,
            max: 85,
            heartRateZone: "5C",
          },
          precentMaximumHeartRateRangeInBpm: {
            min: 64,
            max: 72,
            heartRateZone: "5C",
          },
          percentFTPRangeInW: { min: 75, max: 85, powerZone: 5 },
        },
      },
    ],
    repeat: 4,
  },
  // Ramp Up
  {
    data: [
      {
        blockIdx: 4,
        exerciseIdx: 0,
        width: 100,
        height: 200,
        exercise: {
          name: "Active",
          type: "ACTIVE",
          distance: 1,
          distanceUnit: "Kilometers",
          durationInSeconds: 8 * 60,
          precentThresholdPaceRangeInMinPerKm: {
            min: 75,
            max: 85,
            heartRateZone: 2,
          },
          precentThresholdHeartRateRangeInBpm: {
            min: 75,
            max: 85,
            heartRateZone: "5C",
          },
          precentMaximumHeartRateRangeInBpm: {
            min: 64,
            max: 72,
            heartRateZone: "5C",
          },
          percentFTPRangeInW: { min: 75, max: 85, powerZone: 3 },
        },
      },
      {
        blockIdx: 4,
        exerciseIdx: 1,
        width: 100,
        height: 200,
        exercise: {
          name: "Active",
          type: "ACTIVE",
          distance: 1,
          distanceUnit: "Kilometers",
          durationInSeconds: 8 * 60,
          precentThresholdPaceRangeInMinPerKm: {
            min: 85,
            max: 95,
            heartRateZone: 2,
          },
          precentThresholdHeartRateRangeInBpm: {
            min: 85,
            max: 95,
            heartRateZone: "5C",
          },
          precentMaximumHeartRateRangeInBpm: {
            min: 72,
            max: 81,
            heartRateZone: "5C",
          },
          percentFTPRangeInW: { min: 85, max: 95, powerZone: 3 },
        },
      },
      {
        blockIdx: 4,
        exerciseIdx: 2,
        width: 100,
        height: 200,
        exercise: {
          name: "Active",
          type: "ACTIVE",
          distance: 1,
          distanceUnit: "Kilometers",
          durationInSeconds: 8 * 60,
          precentThresholdPaceRangeInMinPerKm: {
            min: 95,
            max: 105,
            heartRateZone: 2,
          },
          precentThresholdHeartRateRangeInBpm: {
            min: 95,
            max: 105,
            heartRateZone: "5C",
          },
          precentMaximumHeartRateRangeInBpm: {
            min: 81,
            max: 89,
            heartRateZone: "5C",
          },
          percentFTPRangeInW: { min: 95, max: 105, powerZone: 4 },
        },
      },
      {
        blockIdx: 4,
        exerciseIdx: 3,
        width: 100,
        height: 200,
        exercise: {
          name: "Active",
          type: "ACTIVE",
          distance: 1,
          distanceUnit: "Kilometers",
          durationInSeconds: 8 * 60,
          precentThresholdPaceRangeInMinPerKm: {
            min: 105,
            max: 115,
            heartRateZone: 2,
          },
          precentThresholdHeartRateRangeInBpm: {
            min: 105,
            max: 115,
            heartRateZone: "5C",
          },
          precentMaximumHeartRateRangeInBpm: {
            min: 89,
            max: 98,
            heartRateZone: "5C",
          },
          percentFTPRangeInW: { min: 105, max: 115, powerZone: 5 },
        },
      },
    ],
    repeat: 1,
  },
  // Ramp Down
  {
    data: [
      {
        blockIdx: 4,
        exerciseIdx: 0,
        width: 100,
        height: 200,
        exercise: {
          name: "Active",
          type: "ACTIVE",
          distance: 1,
          distanceUnit: "Kilometers",
          durationInSeconds: 8 * 60,
          precentThresholdPaceRangeInMinPerKm: {
            min: 105,
            max: 115,
            heartRateZone: 2,
          },
          precentThresholdHeartRateRangeInBpm: {
            min: 105,
            max: 115,
            heartRateZone: "5C",
          },
          precentMaximumHeartRateRangeInBpm: {
            min: 89,
            max: 98,
            heartRateZone: "5C",
          },
          percentFTPRangeInW: { min: 105, max: 115, powerZone: 3 },
        },
      },
      {
        blockIdx: 4,
        exerciseIdx: 1,
        width: 100,
        height: 200,
        exercise: {
          name: "Active",
          type: "ACTIVE",
          distance: 1,
          distanceUnit: "Kilometers",
          durationInSeconds: 8 * 60,
          precentThresholdPaceRangeInMinPerKm: {
            min: 95,
            max: 105,
            heartRateZone: 2,
          },
          precentThresholdHeartRateRangeInBpm: {
            min: 81,
            max: 89,
            heartRateZone: "4",
          },
          precentMaximumHeartRateRangeInBpm: {
            min: 81,
            max: 89,
            heartRateZone: "4",
          },
          percentFTPRangeInW: { min: 95, max: 105, powerZone: 4 },
        },
      },
      {
        blockIdx: 4,
        exerciseIdx: 2,
        width: 100,
        height: 200,
        exercise: {
          name: "Active",
          type: "ACTIVE",
          distance: 1,
          distanceUnit: "Kilometers",
          durationInSeconds: 8 * 60,
          precentThresholdPaceRangeInMinPerKm: {
            min: 85,
            max: 95,
            heartRateZone: 2,
          },
          precentThresholdHeartRateRangeInBpm: {
            min: 85,
            max: 95,
            heartRateZone: "5C",
          },
          precentMaximumHeartRateRangeInBpm: {
            min: 72,
            max: 81,
            heartRateZone: "5C",
          },
          percentFTPRangeInW: { min: 85, max: 95, powerZone: 4 },
        },
      },
      {
        blockIdx: 4,
        exerciseIdx: 3,
        width: 100,
        height: 200,
        exercise: {
          name: "Active",
          type: "ACTIVE",
          distance: 1,
          distanceUnit: "Kilometers",
          durationInSeconds: 8 * 60,
          precentThresholdPaceRangeInMinPerKm: {
            min: 75,
            max: 85,
            heartRateZone: 2,
          },
          precentThresholdHeartRateRangeInBpm: {
            min: 75,
            max: 85,
            heartRateZone: "1",
          },
          precentMaximumHeartRateRangeInBpm: {
            min: 64,
            max: 72,
            heartRateZone: "5C",
          },
          percentFTPRangeInW: { min: 75, max: 85, powerZone: 3 },
        },
      },
    ],
    repeat: 1,
  },
];
