import Input from "@/components/InputComponents/Input";
import TextArea from "@/components/InputComponents/TextArea";
import { X } from "lucide-react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input as InputCN } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import WhitePlusIcon from "@/pages/library/assests/WhitePlusIcon";
import CheckRounded from "@mui/icons-material/CheckRounded";
import {
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ProgramsContext } from "./ProgramsContext";
import { cn } from "@/lib/utils";

const AddProgram = ({
  selectedProgramType,
  isTemplate,
  addProgram,
  isIcon,
  isFolder,
  createNewFolder,
  setIsCreateOpen,
  folderHeaderProgram,
  folderHeaderPhase,
  selectedFolderHeader,
  isCreateOpen,
}) => {
  const [programName, setProgramName] = useState("");
  const [programDescription, setProgramDescription] = useState("");
  const [programType, setProgramType] = useState(
    selectedProgramType || "folder"
  );
  const [totalPhases, setTotalPhases] = useState(null);
  const [weeksPerPhase, setWeeksPerPhase] = useState(null);
  const [totalDurationOfPhase, setTotalDurationOfPhase] = useState(null);
  const [isFolderSelected, SetIsFolderSelected] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");
  const {
    folders,
    setFolderSelected,
    templateFolders,
    unsavedChanges,
    setShowConfirmDialog,
  } = useContext(ProgramsContext);
  const [open, setOpen] = useState(false);
  // useEffect(() => {
  //   setFolderSelected(false);
  // }, [programName]);

  useEffect(() => {
    setTotalDurationOfPhase(totalPhases * weeksPerPhase);
  }, [totalPhases, weeksPerPhase]);

  const resetAllData = () => {
    setProgramName("");
    setProgramDescription("");
    setProgramType("folder");
    setTotalPhases(null);
    setWeeksPerPhase(null);
    setTotalDurationOfPhase(null);
    setIsAdding(false);
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
        setIsCreateOpen(false);
      }}
      onOpenChange={(open) => {
        if (open && unsavedChanges) {
          setShowConfirmDialog(true);
          return;
        }
        if (selectedFolderHeader) {
          SetIsFolderSelected(true);
        }
        if (!open) {
          resetAllData();
        }
        if (open) {
          if (selectedProgramType) {
            setProgramType(selectedProgramType);
          }
        }
        setOpen(open);
      }}
    >
      <DialogTrigger>
        {isIcon ? (
          <div className="gradient-background rounded-full p-2">
            <WhitePlusIcon />
          </div>
        ) : (
          <button className="button-new w-full px-2">
            {!folderHeaderProgram &&
              !folderHeaderPhase &&
              (isTemplate ? "Add Template" : "Add Program")}
            {folderHeaderProgram && "Add Programs"}
            {folderHeaderPhase && "Add Phase"}
          </button>
        )}
      </DialogTrigger>

      <DialogPortal>
        <DialogOverlay className="bg-black-pure bg-opacity-40 z-[9999]" />
      </DialogPortal>

      <DialogContent className="sm:max-w-lg z-[9999] font-DMSans">
        <DialogHeader>
          <DialogTitle className="text-font18">
            {isTemplate ? "Create Template" : "Create"}
          </DialogTitle>
        </DialogHeader>
        {
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onChange={(e) => {
              setProgramType(e.target.value);
            }}
            sx={{
              overflowX: "scroll",
              flexWrap: "nowrap",
              display: "flex",
              gap: "2",
              justifyContent: "space-evenly",
              width: "100%",
            }}
          >
            {!folderHeaderProgram && !folderHeaderPhase && (
              <FormControlLabel
                sx={{
                  minWidth: "80px",
                  "& .MuiFormControlLabel-label": {
                    color: "#424242",
                    fontFamily: "DM Sans",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: programType === "folder" ? 500 : 400,
                    lineHeight: "21px",
                  },
                }}
                value="folder"
                checked={programType === "folder"}
                control={<Radio color="royalBlue" />}
                label="Folder"
              />
            )}

            <FormControlLabel
              sx={{
                minWidth: "80px",
                "& .MuiFormControlLabel-label": {
                  color: "#424242",
                  fontFamily: "DM Sans",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: programType === "basic" ? 500 : 400,
                  lineHeight: "21px",
                },
              }}
              value="basic"
              checked={programType === "basic"}
              control={<Radio color="royalBlue" />}
              label="Basic Program"
            />

            <FormControlLabel
              sx={{
                minWidth: "100px",
                "& .MuiFormControlLabel-label": {
                  color: "#424242",
                  fontFamily: "DM Sans",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: programType === "phase" ? 500 : 400,
                  lineHeight: "21px",
                },
              }}
              value="phase"
              checked={programType === "phase"}
              control={<Radio color="royalBlue" />}
              label="Program With Phases"
            />
          </RadioGroup>
        }
        {!(programType === "folder") && (
          <div className="grid gap-4">
            {programType === "basic" ? (
              <Input
                label={`${isTemplate ? "Template" : "Program"} Name*`}
                placeholder={isTemplate ? "Template Name" : "Program Name"}
                value={programName}
                onChange={(e) => setProgramName(e.target.value)}
              />
            ) : (
              <Input
                label={`${isTemplate ? "Template" : "Program"} Name*`}
                placeholder={isTemplate ? "Template Name" : "Program Name"}
                value={programName}
                onChange={(e) => setProgramName(e.target.value)}
              />
            )}
            {programType != "basic" && (
              <div>
                <p className="mb-2">Duration</p>
                <div className="flex justify-start items-center">
                  <InputCN
                    className="w-[120px] py-[8px] px-[10px] border-[1.5px] focus-visible:ring-white-pure font-normal"
                    value={totalPhases}
                    onChange={(e) => setTotalPhases(e.target.value)}
                    placeholder="Phases"
                    type="number"
                  />
                  {/* <Select onValueChange={(value) => setTotalPhases(value)}>
                    <SelectTrigger className="w-[120px] py-[8px] px-[10px] focus:ring-white-pure font-normal">
                      <SelectValue placeholder="Phases" />
                    </SelectTrigger>
                    <SelectContent className="z-[9999]">
                      <SelectGroup>
                        <SelectLabel>Phases</SelectLabel>
                        <SelectItem value="2">2 phases</SelectItem>
                        <SelectItem value="3">3 phases</SelectItem>
                        <SelectItem value="4">4 phases</SelectItem>
                        <SelectItem value="5">5 phases</SelectItem>
                        <SelectItem value="6">6 phases</SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </Select> */}
                  <span className="mx-2">of</span>
                  <InputCN
                    className="w-[120px] py-[8px] px-[10px] border-[1.5px] focus-visible:ring-white-pure font-normal"
                    value={weeksPerPhase}
                    onChange={(e) => setWeeksPerPhase(e.target.value)}
                    placeholder="Weeks"
                    type="number"
                  />
                  <span className="mx-2">=</span>
                  <div>{totalDurationOfPhase} weeks</div>
                </div>
              </div>
            )}
            <TextArea
              placeholder="Description (optional)"
              value={programDescription}
              onChange={(e) => setProgramDescription(e.target.value)}
              numRows={4}
            />
          </div>
        )}
        {programType === "folder" && (
          <div className="grid gap-4">
            <Input
              label={`Folder Name*`}
              placeholder="Folder Name"
              value={programName}
              onChange={(e) => setProgramName(e.target.value)}
            />

            <TextArea
              placeholder="Description (optional)"
              value={programDescription}
              onChange={(e) => setProgramDescription(e.target.value)}
              numRows={4}
            />
          </div>
        )}

        {!(programType === "folder") && (
          <div
            className={cn("flex flex-col gap-[8px]", {
              "pointer-events-none": folderHeaderProgram,
            })}
            // onClick={() => setOpen((prev) => !prev)}
          >
            <label className="text-[14px] font-[400]   pl-[12px] text-[#424242]">
              Select Any Folder
            </label>
            <CustomSelectInProgram
              triggerClassName={
                "w-full  text-font14 focus:ring-transparent focus:border-indigo-500 border-[1.5px]"
              }
              options={isTemplate ? templateFolders : folders}
              isFolder={true}
              value={selectedFolderHeader}
              placeholder={"--"}
              setIsAdding={setIsAdding}
              isAdding={isAdding}
              setNewFolderName={setNewFolderName}
              newFolderName={newFolderName}
              handleAddNewFolder={async () => {
                if (newFolderName.trim() !== "") {
                  const res = await createNewFolder(newFolderName);
                  console.log("resdf", res);
                  if (res?.id) {
                    SetIsFolderSelected(true);
                    setFolderSelected(res);
                    setNewFolderName("");
                  }

                  setIsAdding(false);
                }
              }}
              onValueChange={(value) => {
                // setOpen(false);
                SetIsFolderSelected(true);
                setFolderSelected(value);
              }}
            />
          </div>
        )}
        <DialogFooter
          className={
            "flex w-full items-center justify-center sm:justify-center"
          }
        >
          <DialogClose>
            {!(programType === "folder") && (
              <button
                disabled={
                  programName?.length < 2 ||
                  (programType === "phase" && totalDurationOfPhase < 1)
                  // (programType === "phase" ? totalDurationOfPhase < 1 : false)
                }
                className="button-new"
                onClick={() => {
                  addProgram(
                    programName,
                    programDescription,
                    programType,
                    totalPhases,
                    weeksPerPhase,
                    isFolderSelected
                  );

                  setProgramName("");
                  setProgramDescription("");
                  setProgramType("basic");
                }}
                style={{
                  padding: programType === "basic" ? "0px" : "0px 8px",
                  width: programType === "basic" ? "148px" : "168px",
                }}
              >
                Add{" "}
                {programType === "basic"
                  ? isTemplate
                    ? "Template"
                    : "Program"
                  : isTemplate
                  ? "Template"
                  : "Program Phase"}
              </button>
            )}

            {programType === "folder" && (
              <button
                disabled={programName?.length < 2}
                className="button-new"
                onClick={() => {
                  createNewFolder(programName, programDescription);
                  setProgramName("");
                  setProgramDescription("");
                  setProgramType("folder");
                }}
                style={{
                  padding: "0px",
                  width: "148px",
                }}
              >
                Add Folder
              </button>
            )}
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default AddProgram;

const CustomSelectInProgram = ({
  triggerClassName,
  value,
  onValueChange,
  options,
  placeholder,
  isFolder,
  isAdding,
  setIsAdding,
  newFolderName,
  setNewFolderName,
  handleAddNewFolder,
}) => {
  return (
    <Select value={value} onValueChange={onValueChange}>
      <SelectTrigger className={triggerClassName || "w-80"}>
        <SelectValue
          className="text-font14"
          placeholder={placeholder || "Select"}
        />
      </SelectTrigger>
      <SelectContent className="z-[999999]">
        {/* This is for the folder customSelect */}
        {isFolder && !isAdding ? (
          <div
            className="text-font14  px-7 py-2 mb-1 cursor-pointer bg-gray-300"
            value={"addFolder"}
            onClick={(e) => {
              e.stopPropagation();
              console.log("this is for the event", e);
              setIsAdding(true);
            }}
          >
            Add New Folder
          </div>
        ) : (
          isFolder && (
            <div>
              <TextField
                id="outlined-basic"
                variant="standard"
                value={newFolderName}
                // onFocus={(e)=>e.stopPropagation()}
                // autoFocus={true}
                onChange={(e) => {
                  e.stopPropagation();
                  setNewFolderName(e.target.value);
                }}
                onKeyDown={(e) => {
                  e.stopPropagation();
                  if (e.key === "Enter") {
                    handleAddNewFolder();
                  }
                }}
                style={{
                  width: "100%",
                  marginBottom: "8px",
                  padding: "0 8px",
                  zIndex: "9999999",
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleAddNewFolder();
                      }}
                    >
                      <CheckRounded />
                    </IconButton>
                  ),
                }}
              />
            </div>
          )
        )}
        {isFolder &&
          options.map((option, index) => (
            <SelectItem className="text-font14 " key={index} value={option}>
              {option.name}
            </SelectItem>
          ))}
      </SelectContent>
    </Select>
  );
};
