const OtherTabsList = ({ todoList, todoType }) => {
  const list = todoList?.filter((obj) => obj?.todo?.todoType === todoType);
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        maxHeight: "calc(100vh - 246px)",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <div className="flex items-center justify-between px-4 py-2 w-full">
        <p className="font-DMSans text-font16 text-main-primary font-semibold">
          Total Reminders{" "}
          <span className="font-DMSans text-font14 text-black-pure bg-gray-300 py-1 px-2 rounded-2xl h-21 w-21">
            {list.length}
          </span>
        </p>
      </div>
      {list?.map(({ todo }) => {
        return (
          <div
            className="mx-4 py-2 font-semibold"
            style={{
              borderBottom: "1px solid #D8D6D6",
              width: "90%",
              display: "flex",
              justifyContent: "space-between",
              padding: "15px 3px",
            }}
          >
            {todo?.data || todo}
          </div>
        );
      })}
    </div>
  );
};

export default OtherTabsList;
