import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import React, { useState } from "react";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { FOOD_BASED, MACOS_BASED } from "../../constants";
import { DescriptionInput, NutritionInput } from "../Nutrition/AddRecipieModal";

function CreateMealPlanModal({
  isOpen,
  setIsOpen,
  setNutritionData,
  openMacros,
  openFood,
}) {
  const [referenceName, setReferenceName] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [description, setDescription] = useState("");
  const [mealType, setMealType] = useState(FOOD_BASED);
  const [dietType, setDietType] = useState(null);

  const updateNutritionDataState = () => {
    setNutritionData({
      reference_name: referenceName,
      display_name: displayName,
      description,
      meal_plan_type: mealType,
      diet_type: dietType,
    });
    if (mealType == FOOD_BASED) openFood(true);
    else openMacros(true);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      PaperProps={{
        sx: {
          // padding: "24px",
          width: "520px",
          borderRadius: "20px",
        },
      }}
    >
      <div className="add-program-modal-head">
        <p className="add-program-modal-head-title">Add Meal Plan</p>
        <CloseRoundedIcon
          sx={{
            cursor: "pointer",
          }}
          onClick={() => {
            setIsOpen(false);
          }}
        />
      </div>
      <DialogContent>
        <Stack spacing={2}>
          <RadioGroup
            row
            aria-labelledby="mealplans-row-radio-buttons-group-label"
            name="meal-plan-radio-buttons-group"
            value={mealType}
            onChange={(e) => setMealType(e.target.value)}
          >
            <FormControlLabel
              value={FOOD_BASED}
              control={<Radio color="royalBlue" />}
              label="Food Based"
            />
            <FormControlLabel
              value={MACOS_BASED}
              control={<Radio color="royalBlue" />}
              label={"Macros Based"}
            />
          </RadioGroup>
          <NutritionInput
            placeholder={"Vegan low fat plan"}
            label={"Reference Name*"}
            value={referenceName}
            onChange={(value) => setReferenceName(value)}
            style={{ width: "100%" }}
          />
          {/* <NutritionInput
              label={"Display Name*"}
              value={displayName}
              onChange={(value) => setDisplayName(value)}
              style={{ width: "100%" }}
            /> */}

          <DescriptionInput
            label={"Description*"}
            value={description}
            onChange={(value) => setDescription(value)}
          />

          <div
            style={{
              minWidth: "100%",
              display: "flex",
              flexDirection: "column",
              paddingLeft: "12px",
              paddingRight: "12px",
            }}
          >
            <label
              style={{
                fontFamily: "DM Sans",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "20px",
                textAlign: "left",
              }}
            >
              Diet Type
            </label>
            <RadioGroup
              row
              aria-labelledby="diettype-row-radio-buttons-group-label"
              name="diet-type-radio-buttons-group"
              value={dietType}
              onChange={(e) => setDietType(e.target.value)}
            >
              <FormControlLabel value={"Veg"} control={<Radio color="royalBlue" />} label="Veg" />
              <FormControlLabel
                value={"Non-Veg"}
                control={<Radio color="royalBlue" />}
                label="Non-Veg"
              />
              <FormControlLabel
                value={"Vegan"}
                control={<Radio color="royalBlue" />}
                label="Vegan"
              />
            </RadioGroup>
          </div>
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          pb: "24px",
        }}
      >
        <button
          disabled={false}
          style={{ marginTop: "8px" }}
          className="button-new"
          onClick={() => {
            updateNutritionDataState();
            setIsOpen(false);
          }}
        >
          Next
        </button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateMealPlanModal;
