import { CloseRounded } from "@mui/icons-material";
import { Dialog, DialogActions } from "@mui/material";
import axios from "axios";
import Fuse from "fuse.js";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SearchIcon } from "../../pages/getStreamChat/assets";
import SmallCloseIcon from "../../pages/library/assests/SmallCloseIcon";
import { UserIcon } from "../../pages/library/assests/UserIcon";

const SelectUserToShareImage = ({
  isOpen,
  setIsOpen,
  recipe,
  image,
  onClose,
}) => {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const [userData, setUserData] = useState([]);
  const [tempUserData, setTempUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searched, setSearched] = useState("");
  const [selectedList, setSelectedList] = useState([]);
  const [activeUser, setActiveUser] = useState([]);

  const coachID = useSelector((state) => state.auth.trainerUUID);
  const gymAffiliation = useSelector(
    (state) => state.auth?.data?.gymAffliation
  );
  const isLivezy = gymAffiliation === "Livezy";

  const getUsers = () => {
    let trainerDetails = JSON.parse(localStorage.getItem("trainerCredentials"));
    setLoading(true);
    axios({
      method: "get",
      url: `${APIURL}CF/api/CoachWeb/Get/getAllAtheletesOneCoach`,
      params: {
        coachID: trainerDetails?.password,
      },
    }).then(async (res) => {
      const filteredData = res.data.result?.[0]?.atheletes?.filter(
        (item) => item.active == true
      );

      setUserData(filteredData);
      setTempUserData(filteredData);
    });
  };
  const searchUsers = (searchedVal) => {
    setSearched(searchedVal);
    console.log(tempUserData);

    const fuseOptions = {
      keys: ["name", "userName"], // Properties to search within
      threshold: 0.1, // Adjust the threshold for fuzzy matching
    };

    const fuseOptions2 = {
      keys: ["data.userName", "data.name"], // Properties to search within
      threshold: 0.1, // Adjust the threshold for fuzzy matching
    };

    if (searchedVal !== "") {
      const fuse = new Fuse(tempUserData, fuseOptions);
      const searchResults = fuse.search(searchedVal);

      const filteredRows = searchResults.map((result) => result.item);

      setUserData(filteredRows);

      console.log("teamChannels ", tempUserData);
    } else {
      setUserData(tempUserData);
    }
  };

  const onSearch = (event) => {
    event.preventDefault();
    setSearched(event.target.value);
  };

  const shareImageWithSelectedUsers = () => {
    // Create FormData and append the Blob as a file
    activeUser.forEach(async (userID) => {
      const formData = new FormData();
      formData.append("image", image, "captured_image.png");
      formData.append("coachID", coachID);
      formData.append("userID", userID);
      formData.append("recipeName", recipe?.recipe_name);

      // Use Axios to send the file to the server
      const response = await axios.post(
        `${APIURL}CF/api/sendRecipeMessage`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    });
    onClose();
    setIsOpen(false);
  };

  useEffect(() => {
    getUsers();

    return () => {
      setUserData([]);
      setTempUserData([]);
      setLoading(false);
      setSearched("");
      setSelectedList([]);
      setActiveUser([]);
    };
  }, []);

  const searchContainerRef = React.useRef();

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        if (onClose) onClose();
        setIsOpen(false);
      }}
      maxWidth="sm"
      fullWidth
      sx={{
        backgroundColor: "#fff",
      }}
      PaperProps={{
        style: {
          borderRadius: "20px",
          minWidth: "400px",
          minHeight: "400px",
          backgroundColor: "#fff",
        },
      }}
    >
      <div className="add-program-modal-head">
        <p className="add-program-modal-head-title">Select users to share</p>
        <div
          onClick={() => {
            onClose();
            setIsOpen(false);
          }}
          style={{
            color: "#000",
            cursor: "pointer",
          }}
        >
          <CloseRounded color="#000" />
        </div>
      </div>
      <div className="flex flex-col flex-1 p-[24px] gap-[16px]">
        <div
          className="full-width-container"
          style={{
            paddingBottom: "20px",
          }}
        >
          <div
            className="channel-search__input__wrapper"
            ref={searchContainerRef}
            style={{
              minWidth: "500px",
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
              height: "auto",
              justifyContent: "flex-start",
            }}
          >
            {selectedList.length > 0 &&
              selectedList.map((item, index) => {
                return (
                  <div
                    style={{
                      color: "#333",
                    }}
                    className="searched-selected"
                  >
                    <div className="selected-user-icon-div">
                      <UserIcon />
                    </div>
                    <p
                      style={{
                        color: "#333",
                      }}
                    >
                      {item?.name}
                    </p>
                    <div
                      onClick={() => {
                        setActiveUser((prevState) => {
                          return prevState.filter((uuid) => uuid !== item.id);
                        });

                        delete selectedList[index];
                      }}
                    >
                      <SmallCloseIcon />
                    </div>
                  </div>
                );
              })}
            <div
              style={{
                width: "100%",
              }}
              className="channel-search__input__icon"
            >
              <SearchIcon />
              <input
                className="channel-search__input__text"
                onChange={(e) => {
                  onSearch(e);
                  searchUsers(e.target.value);
                }}
                placeholder="Search users or groups"
                type="text"
                style={{
                  width: "100%",
                }}
                value={searched}
              />
            </div>
          </div>
          {searched.length > 0 && (
            <div
              className="search-result-view"
              style={{
                maxHeight: "200px",
                width: 554,
                marginTop: 10 + searchContainerRef?.current?.clientHeight,
              }}
            >
              <div className="label-new ">Users</div>
              {userData.length > 0 &&
                userData.map((item) => {
                  return (
                    <div
                      className="search-result-view-item-container w-full cursor-pointer"
                      onClick={() => {
                        setSelectedList((prev) => [
                          ...prev,
                          {
                            id: item.id,
                            name: item?.name || item?.userName,
                            team: false,
                          },
                        ]);
                        setActiveUser((prevState) => [...prevState, item.id]);
                        setSearched("");
                      }}
                    >
                      <div
                        className="channel-preview__item_avatar"
                        style={{
                          backgroundColor: "rgb(255, 229, 211)",
                        }}
                      >
                        <UserIcon />
                      </div>
                      <p
                        style={{
                          color: "#333",
                        }}
                      >
                        {item?.name || item?.userName}
                      </p>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </div>
      <DialogActions
        sx={{
          justifyContent: "center",
        }}
      >
        <button
          disabled={selectedList.length === 0}
          className="button-new"
          onClick={shareImageWithSelectedUsers}
        >
          Share
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectUserToShareImage;
