import { store } from "@/redux/store";
import axios from "axios";
import moment from "moment";
const genericErrMsg = Error("Something went wrong!");

export const getWorkoutByID = async (workoutID) => {
  try {
    let res = await axios({
      method: "get",
      url: `${store.getState().auth.apiURL}CF/api/getWorkoutWithAllDetails`,
      params: {
        workoutID: workoutID,
      },
    });
    return res.data.workout;
  } catch (err) {
    return null;
  }
};

export const getWorkouts = async (coachID) => {
  try {
    const url = `${store.getState().auth.apiURL}CF/api/getWorkoutsbyCoach`;

    const params = { coachID: coachID };

    const res = await axios({
      method: "get",
      url: url,
      params: params,
    });

    const { data, status } = res;

    if (status === 200 && data && data.result) {
      return res.data.result;
    }

    throw Error(res?.statusText) || genericErrMsg;
  } catch (err) {
    throw new Error(err);
  }
};

export const getWorkoutsbyCoach = async (coachID) => {
  try {
    const url = `${store.getState().auth.apiURL}CF/api/getWorkoutsbyCoach`;

    const params = { coachID: coachID };

    const res = await axios({
      method: "get",
      url: url,
      params: params,
    });

    const { data, status } = res;

    if (status === 200 && data && data.result) {
      return res.data.result;
    }
    return [];
  } catch (err) {
    return [];
  }
};

// get workout of coach and admin both
export const getAllWorkouts = async (coachID, adminID) => {
  let coachWorkouts = await getWorkoutsbyCoach(coachID);
  let adminWorkouts = adminID ? await getWorkoutsbyCoach(adminID) : [];
  try {
    const res = await Promise.all([coachWorkouts, adminWorkouts]);
    const [coach, admin] = res;
    return [...coach, ...admin];
  } catch (err) {
    throw new Error(err);
  }
};

export const assignWorkout = async ({
  workoutID,
  userUUID,
  startDate,
  isEnduranceWorkout,
}) => {
  try {
    const url = `${store.getState().auth.apiURL}CF/api/modules`;
    

    let reqData = {
      userUUID,
      modulesUUID: workoutID,
      moduleDate: `${moment(startDate)
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ss")}Z`,
      isEnduranceWorkout,
    };

    const res = await axios({
      method: "post",
      url: url,
      data: reqData,
    });

    const { data, status } = res;

    if (status === 200 && data && data.result) {
      return res.data.result;
    }

    throw Error(res?.statusText) || genericErrMsg;
  } catch (err) {
    throw new Error(err);
  }
};

export const updateWorkout = async (data) => {
  await axios({
    method: "post",
    url: `${
      store.getState().auth.apiURL
    }CF/api/CoachWeb/Update/updateWorkoutWithAllDetailsJSON`,
    data,
  });
};

export const getWorkoutTags = async (coachID) => {
  try {
    const url = `${store.getState().auth.apiURL}CF/api/workouts/workoutTags`;

    const params = { coachID: coachID };

    const res = await axios({
      method: "get",
      url: url,
      params: params,
    });

    const { data, status } = res;

    if (status === 200 && data && data.workoutTags) {
      return res.data.workoutTags;
    }
    return [];
  } catch (err) {
    return [];
  }
};

export const resyncGarminWorkout = async (data) => {
  try {
    const res = await axios({
      method: "post",
      url: `${store.getState().auth.apiURL}CF/api/garmin/pushWorkoutAgain`,
      data,
    });
    return res.data;
  } catch (err) {
    return null;
  }
};

export const createWorkout = async (d) => {
  let res = await axios({
    method: "post",
    url: `${
      store.getState().auth.apiURL
    }CF/api/CoachWeb/Create/addWorkoutWithAllDetailsJSON`,
    data: { workout: d },
  });
  return res.data?.result;
};

export const createFolder = async (data) => {
  let res = await axios({
    method: "post",
    url: `${store.getState().auth.apiURL}CF/api/folders/createWorkoutFolder`,
    data: data,
  });
  return res?.data;
};

export const getAllFolder = async (coachID, adminID) => {
  let trainer = await axios({
    method: "get",
    url: `${store.getState().auth.apiURL}CF/api/folders/getAllWorkoutFolders`,
    params: {
      coachID: coachID,
    },
  }).catch((err) => {
    console.log(err);
  });

  let admin = adminID
    ? await axios({
        method: "get",
        url: `${
          store.getState().auth.apiURL
        }CF/api/folders/getAllWorkoutFolders`,
        params: {
          coachID: adminID,
        },
      }).catch((err) => {
        console.log(err);
      })
    : [];

  let all = [...(trainer?.data ?? []), ...(admin?.data ?? [])];

  return all;
};


export const updateFolderDetails = async (data) => {
  let res = await axios({
    method: "post",
    url: `${store.getState().auth.apiURL}CF/api/folders/updateWorkoutFolder`,
    data: data,
  });

  return res;
};

export const deleteFolder = async (data) => {
  let res = await axios({
    method: "post",
    url: `${store.getState().auth.apiURL}CF/api/folders/deleteWorkoutFolder`,
    data: data,
  });

  return res;
};

export const connectItemToFolder = async (data) => {
  let res = await axios({
    method: "post",
    url: `${store.getState().auth.apiURL}CF/api/folders/connectWorkoutToFolder`,
    data: data,
  });

  return res;
};

export const disconnectItemFromFolder = async (data) => {
  let res = await axios({
    method: "post",
    url: `${
      store.getState().auth.apiURL
    }CF/api/folders/disconnectWorkoutFromFolder`,
    data: data,
  });

  return res;
};


export const shiftItemBetweenFolder = async (data) => {
  let res = await axios({
    method: "post",
    url: `${
      store.getState().auth.apiURL
    }CF/api/folders/transferWorkoutsToFolder`,
    data: data,
  });
  console.log("this data is", res);
  return res?.data;
};