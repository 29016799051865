 const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white-ghost rounded-xl shadow-lg p-2">
        <p className="label">{`${label}`}</p>
        <div>
          {payload.map((pld) => (
            <div style={{ display: "inline-block"}}>
              <div className={'text-black-blackish'}>{
                formatNumber(pld.value)

              }</div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return null;
};
export default CustomTooltip;

 function formatNumber(value) {
   const num = Number(value);
   if (isNaN(num)) {
     return value; // return original string if it's not a number
   } else {
     return Number.isInteger(num) ? num : num.toFixed(2);
   }
 }