import React, { useState, useEffect } from "react";
import Footer from "./Footer";
import Header from "./Header";
import NoEntry from "../NoEntry";
import LocalStorage from "../../localStorage";
import { isMobile } from "react-device-detect";
import Sidebar from "../SideBar";
import TopBar from "../TopBar";
import Copilot from "../copilot/copilot";
import ChecklistModal from "../Dashboard/ChecklistModal";
import { useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";

export const stateContext = React.createContext();
const Layout = ({ children, isPublic, dashboard, emptyClients }) => {
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [dynamicPadding, setPadding]=useState("96px")

  let isExpandedSideBar = useSelector((state) => state.data.isExpanded);
  const isSmallerThanMd = useMediaQuery((theme) =>
    theme.breakpoints.down("md")
  );
     
  useEffect(() => {
  if(isExpandedSideBar){
    setPadding("200px")
  }else{
    setPadding("96px")
  }
  }, [isExpandedSideBar]);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({ width: window.innerWidth, height: window.innerHeight });
    };
   

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {/* {screenSize.width < 768 ? (
        <ResponsiveHeader />
      ) : ( */}

      {/* )} */}
      {/* mt-2 remove for chat screen */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          paddingLeft: isSmallerThanMd ? "20px" : dynamicPadding,
          paddingRight: "20px",
          paddingTop: "90px",
          marginLeft: isSmallerThanMd ? "0px" : "14px",
          minHeight: "100%",
          marginTop: "12px",
        }}
      >
        {children}
      </div>
    </>
  );
};

export default Layout;
