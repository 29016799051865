import CircleCheckedFilled from "@mui/icons-material/CheckCircle";
import CircleUnchecked from "@mui/icons-material/RadioButtonUnchecked";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { TabContext } from "@mui/lab";
import { Alert, Checkbox, Tab, Tabs, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import Fuse from "fuse.js";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CustomAlertContext } from "../../App";
import AddIcon from "../../assets/dashboard/addIconWhite.svg";
import SearchBar from "../../components/Dashboard/SearchBar";
import { SpurfitCircularProgress } from "../../components/Dashboard/dashboard";
import { TabHeader } from "../../components/DescriptionComp/ClientProfile";
import InviteByReference from "../../components/TopBar/InviteByReference";
import Layout from "../../components/layout/Layout";
import NoAffiliateView from "./NoAffiliateView";
import "./style.css";

const AffiliateDashboard = () => {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const trainerUUID = useSelector((state) => state.auth.trainerUUID);
  const [signedUpTabOpen, setSignedUpTabOpen] = useState(true);
  const [orgSignedUpData, setOrgSignedUpData] = useState([]);
  const [orgPendingData, setOrgPendingData] = useState([]);
  const [signedUpData, setSignedUpData] = useState([]);
  const [pendingData, setPendingData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [affiliateInvitesData, setAffiliateInvitesData] = useState([]);
  const coachID = useSelector((state) => state.auth.trainerUUID);
  const [dashboardData, setDashboardData] = useState([]);
  const [showAddTrainer, setShowAddTrainer] = useState(false);
  const [activeTab, setActiveTab] = useState("Signed Up");
  const [copyUrl, setCopyUrl] = useState("");
  const [copyLinkAlert, setCopyLinkAlert] = useState(false);
  const [inviteSuccess, setInviteSuccess] = useState(false);
  const { displayAlert } = useContext(CustomAlertContext);

  const getAffiliateData = async () => {
    setLoading(true);
    try {
      let res = await axios({
        method: "post",
        url: `${APIURL}CF/api/affiliateCoaches/getSingedUpandPendingAffiliates`,
        data: {
          coachID: coachID,
        },
      });
      let result = res?.data?.result;
      console.log("result from affiliation", result);
      setAffiliateInvitesData(result);
      setOrgSignedUpData(result?.referenceSignUps || []);
      setSignedUpData(result?.referenceSignUps || []);
      let tempPendingData = result?.affliateInvites.filter(
        (item) => !item?.hasSignedUp
      );
      console.log("pending data not signed up", tempPendingData);
      setOrgPendingData(tempPendingData || []);
      setPendingData(tempPendingData || []);
      setDashboardData(result?.referenceSignUps || []);
      setLoading(false);
    } catch (err) {
      console.log("unable to get data of the affiliate", err);
    }
  };
  const resendInvite = async (email) => {
    await axios({
      method: "post",
      url: `${APIURL}CF/api/affiliateCoaches/affliateInviteEmail`,
      data: {
        email: email,
        coachID: trainerUUID,
      },
    })
      .then((res) => {
        console.log("added successfully", res);
        displayAlert({
          type: "success",
          message: "Invite sent successfully!",
        });
      })
      .catch((err) => {
        console.log("error", err);
        displayAlert({
          type: "error",
          message: "Error sending invite!",
        });
      })
      .finally(() => {
        setInviteSuccess(true);
      });
  };
  const requestSearch = (searchedVal) => {
    if (searchedVal == "" && activeTab == "Pending")
      return setPendingData(orgPendingData);

    if (searchedVal == "" && activeTab == "Signed Up")
      return setSignedUpData(orgSignedUpData);

    if (searchedVal != "" && activeTab == "Pending") {
      const fuseOptions = {
        keys: ["email"], // Properties to search within
        threshold: 0.1, // Adjust the threshold for fuzzy matching
      };

      const fuse = new Fuse(orgPendingData, fuseOptions);
      const searchResults = fuse.search(searchedVal);

      const filteredRows = searchResults.map((result) => result.item);
      setPendingData(filteredRows);
    } else {
      const fuseOptions = {
        keys: ["name"], // Properties to search within
        threshold: 0.1, // Adjust the threshold for fuzzy matching
      };

      const fuse = new Fuse(orgSignedUpData, fuseOptions);
      const searchResults = fuse.search(searchedVal);

      const filteredRows = searchResults.map((result) => result.item);
      setSignedUpData(filteredRows);
    }
  };

  const handleChange = (event, newValue) => {
    if (!newValue) return;
    // if (newValue == "Pending") {
    //   setDashboardData(pendingData);
    // } else {
    //   setDashboardData(signedUpData);
    // }
    setActiveTab(newValue);
  };
  const cancelSearch = () => {
    setDashboardData(affiliateInvitesData);
  };
  const SignedUpColumns = [
    {
      field: "Name",
      headerName: "Name",
      flex: 1,
      minWidth: 120,
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <Typography
          sx={{
            fontSize: "13px",
            lineHeight: "20px",
            fontFamily: "DM Sans",
            color: "#333333",
            fontWeight: 500,
          }}
        >
          Name
        </Typography>
      ),
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            minWidth: "120px",
            cursor: "pointer",
          }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              lineHeight: "20px",
              fontFamily: "DM Sans",
              color: "#323130",
            }}
          >
            {params?.row?.name}
          </Typography>
        </div>
      ),
    },
    {
      field: "Email",
      headerName: "Email",
      flex: 1,
      minWidth: 120,
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <Typography
          sx={{
            fontSize: "13px",
            lineHeight: "20px",
            fontFamily: "DM Sans",
            color: "#333333",
            fontWeight: 500,
          }}
        >
          Email
        </Typography>
      ),
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            minWidth: "120px",
            cursor: "pointer",
          }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              lineHeight: "20px",
              fontFamily: "DM Sans",
              color: "#323130",
            }}
          >
            {params?.row?.email}
          </Typography>
        </div>
      ),
    },
  ];
  const pendingColumns = [
    {
      field: "Email",
      headerName: "Email",
      flex: 1,
      minWidth: 120,
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <Typography
          sx={{
            fontSize: "13px",
            lineHeight: "20px",
            fontFamily: "DM Sans",
            color: "#333333",
            fontWeight: 500,
          }}
        >
          Email
        </Typography>
      ),
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            minWidth: "120px",
            cursor: "pointer",
          }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              lineHeight: "20px",
              fontFamily: "DM Sans",
              color: "#323130",
            }}
          >
            {params?.row?.email}
          </Typography>
        </div>
      ),
    },
    {
      field: "Date Sent",
      headerName: "Date Sent",
      flex: 1,
      minWidth: 120,
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <Typography
          sx={{
            fontSize: "13px",
            lineHeight: "20px",
            fontFamily: "DM Sans",
            color: "#333333",
            fontWeight: 500,
          }}
        >
          Date Sent
        </Typography>
      ),
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            minWidth: "120px",
            cursor: "pointer",
          }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              lineHeight: "20px",
              fontFamily: "DM Sans",
              color: "#323130",
            }}
          >
            {params?.row?.dateCreated
              ? moment(params.row.dateCreated).format("MMMM Do' YY")
              : ""}
          </Typography>
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "actions",
      flex: 1,
      width: 120,
      sortable: false,
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <Typography
          sx={{
            fontSize: "13px",
            lineHeight: "20px",
            fontFamily: "DM Sans",
            color: "#333333",
            fontWeight: 500,
          }}
        ></Typography>
      ),
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            paddingRight: "24px",
            width: "100%",
          }}
        >
          <button
            style={{
              fontSize: "13px",
              lineHeight: "16px",
              fontFamily: "DM Sans",
              color: "#605E5C",
            }}
            onClick={() => resendInvite(params?.row?.email)}
          >
            Resend Invite
          </button>
        </div>
      ),
    },
  ];
  useEffect(() => {
    getAffiliateData();
    const getCurrentURl = window.location.origin;
    const tempUrl = getCurrentURl + "/affiliateSignup/" + trainerUUID;
    setCopyUrl(tempUrl);
  }, [coachID, inviteSuccess]);
  return (
    <Layout>
      <div
        style={{
          height: "calc(100vh - 110px)",
          width: "100%",
          backgroundColor: "#fff",
          borderRadius: "12px",
        }}
      >
        <div className="flex items-center pt-3">
          <TabContext value={activeTab}>
            <Tabs
              id="dashboard-top-bar"
              sx={{
                paddingLeft: "12px",
                position: "relative",
                paddingTop: "9px",
              }}
              onChange={handleChange}
              aria-label="lab API tabs example"
            >
              <Tab
                label={
                  <TabHeader
                    title={"Signed Up"}
                    count={signedUpData?.length || null}
                    activeTab={activeTab}
                  />
                }
                value={"Signed Up"}
              />
              <Tab
                label={
                  <TabHeader
                    title={"Pending"}
                    count={pendingData?.length || null}
                    activeTab={activeTab}
                  />
                }
                value={"Pending"}
              />
            </Tabs>
          </TabContext>

          <button
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "linear-gradient(to right, #464FEB, #8330E9)",
              padding: "16px 10px",
              height: "32px",
              lineHeight: "34px",
              borderRadius: "78px",
              color: "#fff",
              marginLeft: "auto",
              fontFamily: "DM Sans",
              fontSize: "13px",
            }}
            onClick={() => setShowAddTrainer(true)}
          >
            <img
              src={AddIcon}
              alt="inviting trainer via affiliate"
              style={{
                height: "16px",
                width: "16px",
                marginRight: "4px",
                zIndex: 999,
              }}
            />
            Invite Trainer
          </button>

          <div
            style={{
              width: "1px",
              height: "32px",
              backgroundColor: "#E0E0E0",
              marginTop: "auto",
              marginBottom: "auto",
              marginLeft: "8px",
            }}
          />

          <SearchBar
            request={(searchVal) => requestSearch(searchVal)}
            cancel={cancelSearch}
            placeholder={"Search Trainers"}
            height={"32px"}
            width={"193px"}
            bgcol={"#F2F2F2"}
            fontSize={"13px"}
          />
        </div>

        <div
          style={{
            height: "100%",
          }}
        >
          {(activeTab === "Signed Up" && signedUpData.length === 0) ||
          (activeTab === "Pending" && pendingData.length === 0) ? (
            <NoAffiliateView setShowAddTrainer={setShowAddTrainer} />
          ) : (
            <ShowTable
              getRowId={(row) => row.id}
              data={activeTab === "Signed Up" ? signedUpData : pendingData}
              columns={
                activeTab === "Signed Up" ? SignedUpColumns : pendingColumns
              }
              loading={loading}
              key={"Affiliate"}
            />
          )}
        </div>

        {showAddTrainer && (
          <InviteByReference
            open={showAddTrainer}
            setOpen={setShowAddTrainer}
            isAffiliation={true}
            copyUrl={copyUrl}
            setCopyLinkAlert={setCopyLinkAlert}
            setInviteSuccess={setInviteSuccess}
          />
        )}

        {copyLinkAlert && (
          <Alert
            severity="success"
            sx={{
              position: "absolute",
              zIndex: "1000",
              top: "900px",
              left: "49%",
            }}
          >
            Link copied!
          </Alert>
        )}
      </div>
    </Layout>
  );
};
const ShowTable = ({ data, columns, loading, getRowId }) => {
  console.log("dashboard data", data);
  return (
    <DataGrid
      disableColumnMenu
      getRowId={getRowId}
      rows={data}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
          },
        },
      }}
      loading={loading}
      slots={{
        loadingOverlay: SpurfitCircularProgress,
        baseCheckbox: (props) => (
          <Checkbox
            icon={<CircleUnchecked />}
            checkedIcon={<CircleCheckedFilled />}
            indeterminateIcon={<RemoveCircleIcon />}
            {...props}
          />
        ),
      }}
      pageSizeOptions={[10]}
      checkboxSelection
      disableRowSelectionOnClick
      sx={{
        marginTop: "10px",
        minHeight: "100px",
        border: "none",
        ".MuiDataGrid-columnSeparator": {
          display: "none",
        },
        "&.MuiDataGrid-root": {
          border: "none",
        },
        "&.MuiDataGrid-columnHeaderCheckbox": {
          border: "none",
        },
      }}
    />
  );
};
export default AffiliateDashboard;
