import FolderInfo from "@/components/Nutrition/FolderInfo";
import { NutritionContext } from "@/components/Nutrition/NutritionContext";
import {
  KeyboardDoubleArrowLeftRounded,
  KeyboardDoubleArrowRightRounded,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getAllCoachFoods } from "../../ApiServices/Foods/Api";
import {
  getAllMealSchedules,
  getMealSchedulesByCoachIDs,
} from "../../ApiServices/Meals/Api";
import AddFoodModal from "../../components/Nutrition/AddFoodModal";
import AddMealModal from "../../components/Nutrition/AddMealModal";
import AddMealPlanModal from "../../components/Nutrition/AddMealPlans/AddMealPlanModal";
import AddRecipieModal from "../../components/Nutrition/AddRecipieModal";
import ExploreFoodModal from "../../components/Nutrition/ExploreFoodModal";
import MacrosModal from "../../components/Nutrition/MacrosModal";
import NItemDetails from "../../components/Nutrition/NItemDetails/NItemDetails";
import NList from "../../components/Nutrition/NList";
import UpdateMealSchedule from "../../components/Nutrition/UpdateMealSchedule";
import Copilot from "../../components/copilot/copilot";
import Layout from "../../components/layout/Layout";
import { ShowCopilotContext } from "../../components/layout/SpurfitLayout";
import SpinnerComponent from "../../components/layout/spinner";
import { GENERATE_MEAL_PLAN } from "../../constants";
import { store } from "../../redux/store";
import EmptyProgramImg from "../library/assests/NoProgram.png";

const Nutritions = () => {
  const {
    updateLocalFood,
    loadingSearchedItem,
    setLoadingSearchedItem,
    nutritionData,
    setNutritionData,
    defaultNItem,
    setdefaultNItem,
    foodData,
    setFoodData,
    deleteLocalFood,
    deleteRecipe,
    setSelectedFoodType,
    selectedFoodType,
    selectedRecipeType,
    setSelectedRecipeType,
    folderSelected,
    folders,
    templateFolders,
    setFolders,
    setTemplateFolders,
    isLoading,
    setIsLoading,
    isTemplate,
    setIsTemplate,
    getMealPlans,
    id,
    selectedMealID,
    tempData,
    setTempData,
    checkForUnsavedMealPlans,
    unsavedMealPlan,
    setUnsavedMealPlan,
    selectedTabInMeaLPlan,
    setTempDataForFolders,
    tempDataForFolders,
    setTempDataForTemplateFolders,
    tempDataForTemplateFolders,
  } = useContext(NutritionContext);
  const libraryTab = [
    {
      id: 1,
      label: "Food Items",
    },
    {
      id: 2,
      label: "Recipes",
    },
    {
      id: 3,
      label: "Meal Plans",
    },
    {
      id: 4,
      label: "Supplements",
    },
    {
      id: 5,
      label: "Supplement Plans",
    },
    {
      id: 6,
      label: "Meal Schedules",
    },
  ];

  const supplementPlanData = [
    {
      id: 1,
      food_name: "Performance Enhancer",
      desc: "Performance Enhancer - Sample",
      initials: "PE",
      serving: "2 Supplements",
      description:
        "This is a supplement plan for workout performance enhancement",
      supplementTimingData: [
        {
          id: 1,
          timeTitle: "Before Workout",
          img: "https://cdn-images.fitbudd.com/fitbud-b5c08/appstreet/1f2d288f-c0be-46ff-a22f-021119bb8a8b.png?crop=650%2C650%2C0%2C0&width=160",
          supplementName: "Pre-Workout",
          dosage: "1 scoop",
        },
        {
          id: 2,
          timeTitle: "During Workout",
          img: "https://cdn-images.fitbudd.com/fitbud-b5c08/appstreet/8c564bf7-09d0-4695-90e2-36d5e8ebc5f3.png?crop=650%2C650%2C0%2C0&width=160",
          supplementName: "BCAA",
          dosage: "1 scoop",
        },
      ],
    },
  ];

  const mealSchedules = [
    {
      id: 1,
      name: "Meal Schedule 1 - Sample",
      desc: "Meal Schedule 1 - Sample",
      duration: "1 week",
      weelkyData: [
        {
          id: 1,
          day: "Day 1",
          dayData: {
            name: "Low Carbs - Sample",
            weight: "1570 cals",
            description:
              "Let's reduce overall calories today while keeping protein intake on the higher side. Eat the required carbs, but don't overdo it.",
            nutritionData: [
              {
                nLabel: "Protien",
                weight: "173.4 g",
                percentage: "44%",
              },
              {
                nLabel: "Fat",
                weight: "58.4 g",
                percentage: "33%",
              },
              {
                nLabel: "Carbs",
                weight: "91 g",
                percentage: "23%",
              },
            ],
            mealPlan: [
              {
                id: 1,
                title: "Early Morning",
                time: "08:00 am",
                protienVal: 0,
                fatVal: 0,
                carbVal: 3,
                weight: "14 cals",
                mealItem: [
                  {
                    id: 1,
                    name: "Apple Cider",
                    dosage: "1 tbsp",
                    amount: "15 ml",
                    weight: "7 cals",
                    img: "https://www.edamam.com/food-img/324/32497d8cd6291a6f03acc032e1da7676.jpg",
                  },
                  {
                    id: 2,
                    name: "Lemon",
                    dosage: "1 tbsp",
                    amount: "15 ml",
                    weight: "7 cals",
                    img: "https://www.edamam.com/food-img/70a/70acba3d4c734d7c70ef4efeed85dc8f.jpg",
                  },
                ],
              },
              {
                id: 2,
                title: "Meal 1",
                time: "12:00 pm",
                protienVal: 56,
                fatVal: 11,
                carbVal: 54,
                weight: "538 cals",
                mealItem: [
                  {
                    id: 1,
                    name: "Eggs Whites",
                    dosage: "13 medium",
                    amount: "429 g",
                    weight: "208 cals",
                    img: "https://cdn-images.fitbudd.com/fitbud-b5c08/appstreet/031a418e-e8b6-4b51-acb3-85116bb66312.jpeg?crop=648%2C648%2C289%2C0&width=160",
                  },
                  {
                    id: 2,
                    name: "Rice cake",
                    dosage: "6 piece",
                    amount: "54 g",
                    weight: "210 cals",
                    img: "https://www.edamam.com/food-img/0c6/0c6ecebeb0f344ed6a82d67cc446cdda.jpg",
                  },
                  {
                    id: 3,
                    name: "Peanut Butter",
                    dosage: "1 tbsp",
                    amount: "16 g",
                    weight: "102 cals",
                    img: "https://cdn-images.fitbudd.com/fitbud-b5c08/appstreet/20b34c6d-013a-4c63-8a52-bbd44e150405.jpeg?crop=735%2C735%2C0%2C0&width=160",
                  },
                  {
                    id: 4,
                    name: "Mixed Greens",
                    dosage: "1 serving",
                    amount: "100 g",
                    weight: "18 cals",
                    img: "https://cdn-images.fitbudd.com/fitbud-b5c08/appstreet/20b34c6d-013a-4c63-8a52-bbd44e150405.jpeg?crop=735%2C735%2C0%2C0&width=160",
                  },
                ],
              },
              {
                id: 3,
                title: "Meal 2",
                time: "04:00 pm",
                protienVal: 71,
                fatVal: 41,
                carbVal: 12,
                weight: "696.5 cals",
                mealItem: [
                  {
                    id: 1,
                    name: "Salmon",
                    dosage: "1.5 serving",
                    amount: "300 g",
                    weight: "364.5 cals",
                    img: "https://www.edamam.com/food-img/9a0/9a0f38422e9f21dcedbc2dca0d8209ac.jpg",
                  },
                  {
                    id: 2,
                    name: "Avocado",
                    dosage: "1 piece",
                    amount: "200 g",
                    weight: "289 cals",
                    img: "https://www.edamam.com/food-img/984/984a707ea8e9c6bf5f6498970a9e6d9d.jpg",
                  },
                ],
              },
            ],
          },
        },
        {
          id: 2,
          day: "Day 2",
          dayData: {
            name: "Training Days - Sample",
            weight: "1892 cals",
            description:
              "Today, we are going to eat just the right amount of carbs to power us through workouts. Protein also needs to be on higher side. Watch that fat intake. Try to strike a balance and have fun!",
            nutritionData: [
              {
                nLabel: "Protien",
                weight: "178.4 g",
                percentage: "38%",
              },
              {
                nLabel: "Fat",
                weight: "53.5 g",
                percentage: "25%",
              },
              {
                nLabel: "Carbs",
                weight: "174.3 g",
                percentage: "37%",
              },
            ],
            mealPlan: [
              {
                id: 1,
                title: "Early Morning",
                time: "08:00 am",
                protienVal: 0,
                fatVal: 0,
                carbVal: 3,
                weight: "14 cals",
                mealItem: [
                  {
                    id: 1,
                    name: "Apple Cider",
                    dosage: "1 tbsp",
                    amount: "15 ml",
                    weight: "7 cals",
                    img: "https://www.edamam.com/food-img/324/32497d8cd6291a6f03acc032e1da7676.jpg",
                  },
                  {
                    id: 2,
                    name: "Lemon",
                    dosage: "1 tbsp",
                    amount: "15 ml",
                    weight: "7 cals",
                    img: "https://www.edamam.com/food-img/70a/70acba3d4c734d7c70ef4efeed85dc8f.jpg",
                  },
                ],
              },
              {
                id: 2,
                title: "Meal 1",
                time: "12:00 pm",
                protienVal: 56,
                fatVal: 11,
                carbVal: 54,
                weight: "538 cals",
                mealItem: [
                  {
                    id: 1,
                    name: "Eggs Whites",
                    dosage: "13 medium",
                    amount: "429 g",
                    weight: "208 cals",
                    img: "https://cdn-images.fitbudd.com/fitbud-b5c08/appstreet/031a418e-e8b6-4b51-acb3-85116bb66312.jpeg?crop=648%2C648%2C289%2C0&width=160",
                  },
                  {
                    id: 2,
                    name: "Rice cake",
                    dosage: "6 piece",
                    amount: "54 g",
                    weight: "210 cals",
                    img: "https://www.edamam.com/food-img/0c6/0c6ecebeb0f344ed6a82d67cc446cdda.jpg",
                  },
                  {
                    id: 3,
                    name: "Peanut Butter",
                    dosage: "1 tbsp",
                    amount: "16 g",
                    weight: "102 cals",
                    img: "https://cdn-images.fitbudd.com/fitbud-b5c08/appstreet/20b34c6d-013a-4c63-8a52-bbd44e150405.jpeg?crop=735%2C735%2C0%2C0&width=160",
                  },
                  {
                    id: 4,
                    name: "Mixed Greens",
                    dosage: "1 serving",
                    amount: "100 g",
                    weight: "18 cals",
                    img: "https://cdn-images.fitbudd.com/fitbud-b5c08/appstreet/20b34c6d-013a-4c63-8a52-bbd44e150405.jpeg?crop=735%2C735%2C0%2C0&width=160",
                  },
                ],
              },
              {
                id: 3,
                title: "Meal 2",
                time: "04:00 pm",
                protienVal: 71,
                fatVal: 41,
                carbVal: 12,
                weight: "696.5 cals",
                mealItem: [
                  {
                    id: 1,
                    name: "Salmon",
                    dosage: "1.5 serving",
                    amount: "300 g",
                    weight: "364.5 cals",
                    img: "https://www.edamam.com/food-img/9a0/9a0f38422e9f21dcedbc2dca0d8209ac.jpg",
                  },
                  {
                    id: 2,
                    name: "Avocado",
                    dosage: "1 piece",
                    amount: "200 g",
                    weight: "289 cals",
                    img: "https://www.edamam.com/food-img/984/984a707ea8e9c6bf5f6498970a9e6d9d.jpg",
                  },
                ],
              },
            ],
          },
        },
      ],
    },
  ];
  const APIURL = useSelector((state) => state.auth.apiURL);
  const [defaultTab, setDefaultTab] = useState(libraryTab[0]);

  const [defaultNItemForMeal, setdefaultNItemForMeal] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [showUpdateMealScheduleModal, setShowUpdateMealScheduleModal] =
    useState(false);

  const [openMacrosModal, setOpenMacrosModal] = useState(false);
  const [exploreFoodOpen, setExploreFoodOpen] = useState(false);
  const [SelectedFood, setSelectedFood] = useState({});
  const [pageNo, setPageNo] = useState(1);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [pushLocation, setPushLocation] = useState("");
  const [dataLoading, setDataLoading] = useState(false);
  const [searchedItem, setsearchedItem] = useState("");

  const [unsavedMealSchedule, setUnsavedMealSchedule] = useState(null);

  const [isRightSideOpen, setIsRightSideOpen] = useState(true);
  const isAdmin = useSelector((state) => state.auth?.data?.isAdmin);
  const subbordinateCoachIDs = useSelector(
    (state) => state.auth.subbordinateCoachIDs
  );
  const [subbordinateCoachMealSchedules, setSubbordinateCoachMealSchedules] =
    useState([]);
  const [recipesData, setRecipesData] = useState({
    trainerData: [],
    globalData: [],
  });

  const { copilotOpen, setCopilotOpen } = useContext(ShowCopilotContext);

  const coachID = useSelector((state) => state.auth.trainerUUID);
  const adminID = useSelector((state) => state.auth.data?.adminID);
  const isLivezy = useSelector((state) => state?.auth?.isLivezy);

  const childRef = React.useRef();
  const nItemDetailsRef = React.useRef();

  const history = useHistory();

  console.log("id: ", id);

  useEffect(() => {
    setNutritionData([]);
    setdefaultNItem({});
    setPageNo(1);
    setDefaultTab(id);
    if (id == 1) {
      // food items data
      getFoodItems();
    } else if (id == 2) {
      // recipes data
      getRecipes();
    } else if (id == 3) {
      getMealPlans();
      // meal plan data
    } else if (id == 4) {
      getMealPlansSchedule();
      // supplement data
    } else if (defaultTab.id === 5) {
      // supplement plan data
      setTimeout(() => {
        setNutritionData(supplementPlanData);
        setIsLoading(false);
      }, 2000);
    } else {
      // meal schedule data
      setTimeout(() => {
        setNutritionData(mealSchedules);
        setIsLoading(false);
      }, 2000);
    }
  }, [id]);

  // all request related to recipes
  const getAllRecipes = async (pageNo, searchedItem) => {
    setIsLoading(true);
    try {
      const res = await axios.post(`${APIURL}CF/api/getAllReceipes`, {
        recordsPerPage: 10,
        page: pageNo || 1,
        searchQuery: searchedItem || "",
      });
      console.log("res ====>>> ", res);
      setNutritionData(res.data.recipes);
      if (res?.data?.recipes?.length > 0) {
        setdefaultNItem(res.data.recipes[0]);
      }
      setIsLoading(false);
      return res.data.recipes;
    } catch (err) {
      setIsLoading(false);
      console.log(["err ===>>> ", err]);
      return null;
    }
  };

  const getTrainerRecipes = async () => {
    try {
      const res = await axios({
        method: "post",
        url: `${APIURL}CF/api/getReceipeByUserId`,
        data: {
          userId: store.getState().auth.trainerUUID,
        },
      });

      const formattedRecipes = res?.data?.recipes?.map((r) => {
        let recipe = r?.serving;
        let directions = Object.entries(recipe?.directions || {}).map(
          ([key, value]) => {
            return {
              direction_number: key,
              direction_description: value,
            };
          }
        );

        let ingredients = Object.entries(recipe?.ingredients || []).map(
          ([key, value]) => {
            return {
              ingredient_description: value,
            };
          }
        );

        return {
          ...recipe,
          directions,
          ingredients,
          id: r?.id,
        };
      });

      return formattedRecipes;
    } catch (err) {
      console.log("error getting trainer recipes: ", err);
      return null;
    }
  };

  const getRecipeAfterDeletion = async () => {
    let trainerRecipes = await getTrainerRecipes();
    setNutritionData(trainerRecipes);
    if (trainerRecipes.length > 0) {
      setdefaultNItem(trainerRecipes[0]);
    }
    let timeout = setTimeout(() => {
      setLoadingSearchedItem(false);
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  };

  const getRecipes = async () => {
    const trainerRecipes = await getTrainerRecipes();
    const globalRecipes = isLivezy ? [] : await getAllRecipes();
    if (isLivezy) {
      setNutritionData(trainerRecipes);
    }
    // sort recipes by recipe_name
    if (
      trainerRecipes &&
      trainerRecipes.length > 0 &&
      trainerRecipes[0].recipe_name
    ) {
      trainerRecipes.sort((a, b) => {
        if (a.recipe_name < b.recipe_name) {
          return -1;
        }
        if (a.recipe_name > b.recipe_name) {
          return 1;
        }
        return 0;
      });
    }

    if (
      globalRecipes &&
      globalRecipes.length > 0 &&
      globalRecipes[0].recipe_name
    ) {
      globalRecipes.sort((a, b) => {
        const aName =
          a.recipe_name[0] === "'" || a.recipe_name[0] === '"'
            ? a.recipe_name.slice(1)
            : a.recipe_name;
        const bName =
          b.recipe_name[0] === "'" || b.recipe_name[0] === '"'
            ? b.recipe_name.slice(1)
            : b.recipe_name;
        if (aName < bName) {
          return -1;
        }
        if (aName > bName) {
          return 1;
        }
        return 0;
      });
    }
    setSelectedRecipeType("global");
    setRecipesData({
      trainerData: trainerRecipes ?? [],
      globalData: globalRecipes ?? [],
    });
  };

  const onRecipeTypeChange = (type) => {
    setSelectedRecipeType(type);
    if (type === "trainer") {
      setNutritionData(recipesData.trainerData);
      if (recipesData.trainerData.length > 0) {
        setdefaultNItem(recipesData.trainerData[0]);
      }
    } else {
      setNutritionData(recipesData.globalData);
      if (recipesData.globalData.length > 0) {
        setdefaultNItem(recipesData.globalData[0]);
      }
    }
  };

  const searchRecipes = async (pageNo, searchedItem) => {
    if (selectedRecipeType === "trainer") {
      let trainerRecipes = recipesData.trainerData;
      if (searchedItem) {
        trainerRecipes = trainerRecipes.filter((recipe) => {
          return recipe.recipe_name
            .toLowerCase()
            .includes(searchedItem.toLowerCase());
        });
      }
      if (pageNo) {
        trainerRecipes = trainerRecipes.slice((pageNo - 1) * 10, pageNo * 10);
      }
      setNutritionData(trainerRecipes);
      if (trainerRecipes.length > 0) {
        setdefaultNItem(trainerRecipes[0]);
      }
    } else {
      setLoadingSearchedItem(true);
      await axios
        .post(`${APIURL}CF/api/getAllReceipes`, {
          recordsPerPage: 10,
          page: pageNo || 1,
          searchQuery: searchedItem || "",
        })
        .then(async (res) => {
          console.log("res ====>>> ", res);
          setNutritionData(res.data.recipes);
          setLoadingSearchedItem(false);
        })
        .catch((err) => {
          setLoadingSearchedItem(false);
          console.log(["err ===>>> ", err]);
        });
    }
  };

  const getAllFoodItems = async (pageNo, searchedItem) => {
    console.log("calling food items");
    setIsLoading(true);
    try {
      const res = await axios.post(`${APIURL}CF/api/getAllFoods`, {
        recordsPerPage: 10,
        page: pageNo || 1,
        searchQuery: searchedItem || "",
      });
      setIsLoading(false);
      return res?.data?.foods;
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const getFoodItems = async () => {
    const trainerFoodItems = await getAllCoachFoods(coachID, adminID);
    const globalFoodItems = isLivezy ? [] : await getAllFoodItems();
    if (isLivezy) {
      setNutritionData(trainerFoodItems);
      setSelectedFoodType("trainer");
      if (trainerFoodItems.length > 0) {
        setdefaultNItem(trainerFoodItems[0]);
      }
    } else {
      setNutritionData(globalFoodItems);
      setSelectedFoodType("global");
      if (globalFoodItems.length > 0) {
        setdefaultNItem(globalFoodItems[0]);
      }
    }
    setFoodData({
      trainerData: trainerFoodItems || [],
      globalData: globalFoodItems || [],
    });
  };

  const searchFoodItems = async (pageNo, searchedItem) => {
    console.log("calling food items");
    if (selectedFoodType == "trainer") {
      let trainerFoodItems = foodData.trainerData;
      if (searchedItem == "") {
        setNutritionData(foodData.trainerData);
      } else if (searchedItem) {
        trainerFoodItems = trainerFoodItems.filter((food) => {
          return food.name.toLowerCase().includes(searchedItem.toLowerCase());
        });
        setNutritionData(trainerFoodItems);
        if (trainerFoodItems.length > 0) {
          // the below state will highlight the top food
          setdefaultNItem(trainerFoodItems[0]);
        }
      }
    } else {
      if (!isLivezy) {
        setLoadingSearchedItem(true);
        await axios
          .post(`${APIURL}CF/api/getAllFoods`, {
            recordsPerPage: 10,
            page: pageNo || 1,
            searchQuery: searchedItem || "",
          })
          .then(async (res) => {
            console.log("res ====>>> ", res);
            // setNutritionData(res.data.foods);
            if (res?.data?.foods?.[0]) {
              setdefaultNItem(res.data.foods[0]);
            }
            setLoadingSearchedItem(false);
          })
          .catch((err) => {
            setLoadingSearchedItem(false);
            console.log(["err ===>>> ", err]);
          });
      }
    }
  };

  const onFoodItemTypeChange = (type) => {
    setSelectedFoodType(type);
    if (type === "trainer") {
      setNutritionData(foodData.trainerData);
      if (foodData.trainerData.length > 0) {
        setdefaultNItem(foodData.trainerData[0]);
      }
    } else {
      setNutritionData(foodData.globalData);
      if (foodData.globalData.length > 0) {
        setdefaultNItem(foodData.globalData[0]);
      }
    }
  };

  const setTrainerFoodItem = (trainerFoodItem) => {
    if (trainerFoodItem) {
      let updatedTrainerFood = [trainerFoodItem, ...nutritionData];
      setNutritionData(updatedTrainerFood);
      setFoodData((prev) => ({
        trainerData: updatedTrainerFood,
        ...prev,
      }));
      setdefaultNItem(updatedTrainerFood[0]);
    }
  };

  const getMealPlansSchedule = async () => {
    setIsLoading(true);
    setdefaultNItemForMeal({});

    let unsavedMealSchedule = localStorage.getItem("unsavedMealSchedule");
    if (unsavedMealSchedule) {
      let parsedUnsavedMealSchedule = JSON.parse(unsavedMealSchedule);
      setUnsavedMealSchedule(parsedUnsavedMealSchedule);
    }
    console.log("subbordinate coach id", subbordinateCoachIDs);

    if (isAdmin && subbordinateCoachIDs?.length > 0) {
      let subbordinateCoachMealSchedules = await getMealSchedulesByCoachIDs(
        subbordinateCoachIDs
      );
      setSubbordinateCoachMealSchedules(subbordinateCoachMealSchedules);
    }

    let data = await getAllMealSchedules(coachID, adminID);

    let sorted = data.sort(
      (a, b) => new Date(b.creationDate) - new Date(a.creationDate)
    );
    // if selectedMealID is present then get the meal schedule with that id on top of the list and set it as default
    if (selectedMealID && selectedMealID !== "undefined") {
      sorted = sorted.filter((item) => item?.id !== selectedMealID);
      sorted.unshift(data.find((item) => item?.id === selectedMealID));
    }

    setNutritionData(sorted);
    setTempData(sorted);
    setdefaultNItem(sorted[0]);
    getMealScheduleByID(sorted[0], sorted[0]?.id);
    setIsLoading(false);
  };

  const getMealScheduleByID = async (item, mealid) => {
    setUnsavedChanges(false);
    if (mealid) {
      console.log("deleted: brooo");
      setDataLoading(true);
      await axios({
        method: "get",
        url: `${APIURL}CF/api/getMealScheduleByID`,
        params: {
          mealScheduleID: mealid,
        },
      })
        .then((res) => {
          console.log("res ====>>> ", res);
          // setIsLoading(false);
          setDataLoading(false);
          setdefaultNItemForMeal(res.data.result);
        })
        .catch((err) => {
          // setIsLoading(false);
          setDataLoading(false);
          console.log(["err ===>>> ", err]);
        });
    } else {
      console.log("setting");
      setdefaultNItemForMeal(item);
    }
  };

  const paginationQuery = async (pageNo, searchedItem) => {
    console.log("firing pagination query", pageNo, " id", searchedItem);
    let pageId = +id;
    switch (pageId) {
      case 1:
        searchFoodItems(pageNo, searchedItem);
        break;
      case 2:
        searchRecipes(pageNo, searchedItem);
        break;
      case 3:
        getMealPlans(pageNo);
        break;
      default:
        break;
    }
  };

  const searchMeals = (searchedItem) => {
    if (!searchedItem) {
      setNutritionData(tempData);
      setFolders(tempDataForFolders)
      setTemplateFolders(tempDataForTemplateFolders)
      return;
    }
    console.log("searchedItem: ", searchedItem);
    const searchQuery = searchedItem.toLowerCase();
    let filteredData = tempData.filter((item) => {
      const itemName =
        item?.name?.toLowerCase() || item.reference_name.toLowerCase();
      let index = 0;

      for (let i = 0; i < searchQuery.length; i++) {
        const char = searchQuery[i];
        index = itemName.indexOf(char, index);
        if (index === -1) {
          return false;
        }
        index++;
      }

      return true;
    });

    if (selectedTabInMeaLPlan == 0) {
      let filteredFoldersWithMealPlans = tempDataForFolders
        ?.map((folder) => {
          if (folder?.name?.toLowerCase().includes(searchQuery)) {
            return folder;
          }
          let copyFolder = { ...folder };
          if (copyFolder?.mealPlans?.length > 0) {
            const filteredMealPlans =
              copyFolder.mealPlans?.filter((mealPlan) =>
                mealPlan?.reference_name?.toLowerCase().includes(searchQuery)
              ) || [];
            copyFolder.mealPlans = filteredMealPlans;
          }

          if (copyFolder?.mealPlans?.length > 0) {
            return copyFolder;
          }
          return null;
        })
        .filter(Boolean);

      setFolders(filteredFoldersWithMealPlans);
    } else if (selectedTabInMeaLPlan == 1) {
      let filteredFoldersWithTemplateMealPlans = tempDataForTemplateFolders
        ?.map((folder) => {
          if (folder?.name?.toLowerCase().includes(searchQuery)) {
            return folder;
          }
          let copyFolder = { ...folder };
          if (copyFolder?.mealPlans?.length > 0) {
            const filteredMealPlans =
              copyFolder.mealPlans?.filter((mealPlan) =>
                mealPlan?.reference_name?.toLowerCase().includes(searchQuery)
              ) || [];
            copyFolder.mealPlans = filteredMealPlans;
          }

          if (copyFolder?.mealPlans?.length > 0) {
            return copyFolder;
          }
          return null;
        })
        .filter(Boolean);

      setTemplateFolders(filteredFoldersWithTemplateMealPlans);
    }
    setNutritionData(filteredData);
  };
  const unblock = history.block((location, action) => {
    if (unsavedChanges) {
      // setPushLocation(location.pathname);
      // handleOpen();
      return true;
    } else {
      return true;
    }
  });

  useEffect(() => {
    return () => {
      unblock();
    };
  }, [unsavedChanges, history]);

  const handleOpen = () => {
    // setUnsavedChanges(true);
    setOpenConfirmDialog(true);
  };

  const handleCancel = () => {
    setOpenConfirmDialog(false);
  };

  const handleConfirm = () => {
    if (pushLocation != "") {
      unblock();
      setPushLocation("");
      history.push(pushLocation);
    } else {
      setUnsavedChanges(false);
      setOpenModal(true);
    }
  };

  const retriveUnsavedMeal = () => {
    const unsavedMealPlan = localStorage.getItem("unsavedMealPlan");
    if (unsavedMealPlan) {
      let parsedUnsavedMealPlan = JSON.parse(unsavedMealPlan);
      let id = parsedUnsavedMealPlan.id;

      if (!id) {
        // add item to nutrition data
        setNutritionData((prev) => [parsedUnsavedMealPlan.item, ...prev]);
        setdefaultNItem(parsedUnsavedMealPlan.item);
      } else {
        // get meal plan from mealID and set it as default
        let meal = nutritionData.find((item) => item.id == id);
        setdefaultNItem(meal);
      }
      if (nItemDetailsRef.current) {
        nItemDetailsRef.current.setOpenAddMealModal(true);

        let timeout = setTimeout(() => {
          nItemDetailsRef.current.setPreviousMealPlan();
          clearTimeout(timeout);
        }, 1000);
      }
      setUnsavedMealPlan(null);
    }
  };

  const retiveUnsavedMealSchedule = () => {
    if (childRef.current) {
      let mealId = unsavedMealSchedule.mealId;
      if (!mealId) {
        let isTemplate = unsavedMealSchedule.isTemplate;
        let data = {
          name: unsavedMealSchedule.mealName,
          creationDate: `${moment().format("YYYY-MM-DDTHH:mm:ss")}Z`,
          trainerUUID: coachID,
          isTemplate,
        };

        setNutritionData((prev) => [data, ...prev]);
        getMealScheduleByID(data);
      } else {
        // get meal from mealID and set it as default
        let meal = nutritionData.find((item) => item.id == mealId);
        setdefaultNItem(meal);
      }

      let timeout = setTimeout(() => {
        childRef.current.retriveUnsavedMealScheduleFromLocalStorage();
        clearTimeout(timeout);
      }, 1000);

      setUnsavedMealSchedule(null);
    }
  };

  return (
    <Layout>
      {((id == 4 && unsavedMealSchedule) || (id == 3 && unsavedMealPlan)) && (
        <div className="flex w-full bg-red-300 mx-1 px-3 mb-1 rounded-md font-DMSans">
          Unsaved changes in &nbsp;
          <span className="font-semibold">
            {id == 4
              ? unsavedMealSchedule.mealName
              : unsavedMealPlan?.item?.reference_name}
          </span>
          &nbsp; schedule
          <div className="ml-auto gap-4 flex items-center">
            <button
              className="font-DMSans font-semibold"
              onClick={() => {
                if (id == 3) {
                  retriveUnsavedMeal();
                } else {
                  retiveUnsavedMealSchedule();
                }
              }}
            >
              Continue
            </button>
            <button
              className="font-DMSans font-semibold"
              onClick={() => {
                if (id == 3) {
                  setUnsavedMealPlan(null);
                  localStorage.removeItem("unsavedMealPlan");
                } else {
                  setUnsavedMealSchedule(null);
                  localStorage.removeItem("unsavedMealSchedule");
                }
              }}
            >
              Discard
            </button>
          </div>
        </div>
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          gap: "8px",
          position: "relative",
        }}
      >
        <div
          style={{
            width: "100%",
          }}
        >
          {id == 4 &&
            !isLoading &&
            (isRightSideOpen ? (
              <div
                className="absolute top-1 left-[26%]  z-50   bg-white-pure cursor-pointer"
                style={{
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.1)",
                }}
                onClick={() => setIsRightSideOpen(!isRightSideOpen)}
              >
                <KeyboardDoubleArrowLeftRounded
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                />
              </div>
            ) : (
              <div
                className="absolute top-1 -left-2 z-50  bg-white-pure cursor-pointer"
                onClick={() => setIsRightSideOpen(!isRightSideOpen)}
                style={{
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.1)",
                }}
              >
                <KeyboardDoubleArrowRightRounded
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                />
              </div>
            ))}
          <div
            style={{
              backgroundColor: "#fff",
              borderRadius: "20px",
              fontFamily: "DM Sans",
            }}
          >
            {/* data list display */}
            {/* {isLoading && (
              <div className="w-[100%] absolute h-[100vh] bg-[rgba(255,255,255, 0.3)] z-[999] top-0 left-0">
                <div
                  className="absolute top-[30%] left-[45%] bg-[#eee] w-[200px] z-[999] p-[50px]"
                  style={{
                    borderRadius: 15,
                  }}
                >
                  <SpinnerComponent />
                </div>
              </div>
            ) } */}

            <Grid
              container
              // mb={10}
              pl={4}
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              {/* items list*/}
              <Grid
                item
                pt={4}
                pr={2}
                // lg={4}
                // md={4}
                // xs={4}
                style={{
                  height: "calc(100vh - 120px)",
                  overflowY: "hidden",
                  width: "25%",
                  position: "relative",
                  display: isRightSideOpen ? "block" : "none",
                }}
              >
                <NList
                  id={id}
                  title={
                    id == 2
                      ? "Recipes"
                      : `${
                          id == 1
                            ? "Food Items"
                            : id == 3
                            ? "Meal Plans"
                            : id == 4
                            ? "Meal Schedule"
                            : id == 5
                            ? "Supplement Plans"
                            : id == 6
                            ? "Schedules"
                            : ""
                        } (${nutritionData?.length})`
                  }
                  listData={nutritionData}
                  defaultNItem={defaultNItem}
                  getMealScheduleByID={getMealScheduleByID}
                  setdefaultNItemForMeal={setdefaultNItemForMeal}
                  setUnsavedChanges={setUnsavedChanges}
                  unsavedChanges={unsavedChanges}
                  defaultTab={+id}
                  subbordinateCoachMealSchedules={
                    subbordinateCoachMealSchedules
                  }
                  setdefaultNItem={(itm) => {
                    setdefaultNItem(itm);
                  }}
                  handleAddModal={(option) => {
                    if (id == 4 || id == 3 || id == 1) {
                      setOpenModal(true);
                      setIsTemplate(option);
                    } else if (
                      (id == 1 && option === "Add Food Item") ||
                      id != 1
                    ) {
                      if (unsavedChanges) setOpenConfirmDialog(true);
                      else setOpenModal(true);
                    } else {
                      setExploreFoodOpen(true);
                    }
                  }}
                  pageNo={pageNo}
                  setPageNo={setPageNo}
                  paginationQuery={id > 2 ? searchMeals : paginationQuery}
                  cancelSearch={() => {
                    setNutritionData(tempData);
                    if (selectedTabInMeaLPlan == 0) {
                      setFolders(tempDataForFolders);
                    } else if (selectedTabInMeaLPlan == 1) {
                      setTemplateFolders(tempDataForTemplateFolders);
                    }
                  }}
                  searchLoading={loadingSearchedItem}
                  onTypeChange={(type) => {
                    if (id == 1) {
                      onFoodItemTypeChange(type);
                    } else {
                      onRecipeTypeChange(type);
                    }
                  }}
                  selectedType={id == 1 ? selectedFoodType : selectedRecipeType}
                  saveMealSchedule={async () => {
                    if (childRef.current) {
                      let res = await childRef.current.handleSubmitMealSchedule(
                        true
                      );
                    }
                  }}
                />
              </Grid>
              {/* item details */}
              {!(id == 3) && (
                <Grid
                  item
                  style={{
                    borderLeft: "1px solid #d9d9d9",
                    height: "calc(100vh - 110px)",
                    overflowY: "scroll",
                    width: isRightSideOpen ? "75%" : "100%",
                  }}
                >
                  {!_.isEmpty(defaultNItem) ? (
                    id == 4 ? (
                      dataLoading ? (
                        <div
                          style={{
                            height: "100%",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <SpinnerComponent />
                        </div>
                      ) : (
                        <NItemDetails
                          checkForUnsavedMealPlans={checkForUnsavedMealPlans}
                          unsavedChanges={unsavedChanges}
                          setUnsavedChanges={setUnsavedChanges}
                          item={defaultNItemForMeal}
                          setItem={setdefaultNItem}
                          defaultTab={+id}
                          getMealPlansSchedule={getMealPlansSchedule}
                          getMealScheduleByID={getMealScheduleByID}
                          handleMacrosModal={(value) => {
                            setOpenMacrosModal(value);
                          }}
                          selectedType={"trainer"}
                          selectedRecipeType={selectedRecipeType}
                          isTemplate={isTemplate}
                          childRef={childRef}
                          ref={nItemDetailsRef}
                          setShowUpdateMealScheduleModal={
                            setShowUpdateMealScheduleModal
                          }
                          getTrainerRecipes={getRecipes}
                        />
                      )
                    ) : (
                      <>
                        {Object.keys(folderSelected || {})?.length > 0 &&
                          !Object.keys(defaultNItem || {})?.length > 0 && (
                            <FolderInfo />
                          )}
                        {
                          // Object.keys(folderSelected || {})?.length > 0 ||
                          Object.keys(defaultNItem || {})?.length > 0 && (
                            <NItemDetails
                              checkForUnsavedMealPlans={
                                checkForUnsavedMealPlans
                              }
                              item={defaultNItem}
                              setListData={setNutritionData}
                              defaultTab={+id}
                              handleMacrosModal={(value) => {
                                setOpenMacrosModal(value);
                              }}
                              getMealPlans={getMealPlans}
                              isTemplate={isTemplate}
                              childRef={childRef}
                              ref={nItemDetailsRef}
                              getTrainerRecipes={getRecipes}
                              setItem={(item) => {
                                setdefaultNItem(item);
                              }}
                              getCoachRecipes={getTrainerRecipes}
                              setNutritionData={setNutritionData}
                              setLoadingSearchedItem={setLoadingSearchedItem}
                            />
                          )
                        }
                      </>
                    )
                  ) : id == 4 || id == 3 || id == 2 ? (
                    <div
                      style={{
                        minHeight: "calc(100vh - 110px)",
                        minWidth: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "16px",
                        background: "#fff",
                        borderRadius: "20px",
                      }}
                      className="flex flex-col items-center justify-center "
                    >
                      <img
                        src={EmptyProgramImg}
                        className="w-[128px] h-[128px]"
                        alt=""
                      />
                      <div
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <p className="text-bold">
                          No Meal{" "}
                          {id == 3
                            ? "Plan "
                            : id == 2
                            ? "Recipes "
                            : "Schedule "}{" "}
                          Added
                        </p>
                        <p className="text-para">
                          There are no meal{" "}
                          {id == 3
                            ? "plan "
                            : id == 2
                            ? "recipes"
                            : "schedule "}{" "}
                          to display, click the below button to add it
                        </p>
                      </div>
                      <button
                        className="button-new"
                        onClick={() => setOpenModal(true)}
                      >
                        Add{" "}
                        {id == 3 ? "Plan " : id == 2 ? "Recipes" : "Schedule "}
                      </button>
                    </div>
                  ) : (
                    <div className="flex flex-col items-center justify-center">
                      <span>Select a Food Item to View</span>
                    </div>
                  )}
                </Grid>
              )}
              {id == 3 && (
                <Grid
                  item
                  style={{
                    borderLeft: "1px solid #d9d9d9",
                    height: "calc(100vh - 110px)",
                    overflowY: "scroll",
                    width: isRightSideOpen ? "75%" : "100%",
                  }}
                >
                  <>
                    {Object.keys(folderSelected || {})?.length > 0 &&
                      !(Object.keys(defaultNItem || {})?.length > 0) && (
                        <FolderInfo />
                      )}
                    {Object.keys(defaultNItem || {})?.length > 0 && (
                      <NItemDetails
                        checkForUnsavedMealPlans={checkForUnsavedMealPlans}
                        item={defaultNItem}
                        setListData={setNutritionData}
                        defaultTab={+id}
                        handleMacrosModal={(value) => {
                          setOpenMacrosModal(value);
                        }}
                        getMealPlans={getMealPlans}
                        isTemplate={isTemplate}
                        childRef={childRef}
                        ref={nItemDetailsRef}
                        getTrainerRecipes={getRecipes}
                        setItem={(item) => {
                          setdefaultNItem(item);
                        }}
                        getCoachRecipes={getTrainerRecipes}
                        setNutritionData={setNutritionData}
                        setLoadingSearchedItem={setLoadingSearchedItem}
                      />
                    )}
                  </>

                  {_.isEmpty(defaultNItem) &&
                    Object.keys(folderSelected || {})?.length === 0 && (
                      <div
                        style={{
                          minHeight: "calc(100vh - 110px)",
                          minWidth: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "16px",
                          background: "#fff",
                          borderRadius: "20px",
                        }}
                        className="flex flex-col items-center justify-center "
                      >
                        <img
                          src={EmptyProgramImg}
                          className="w-[128px] h-[128px]"
                          alt=""
                        />
                        <div
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <p className="text-bold">
                            No Meal{" "}
                            {id == 3
                              ? "Plan "
                              : id == 2
                              ? "Recipes "
                              : "Schedule "}{" "}
                            Added
                          </p>
                          <p className="text-para">
                            There are no meal{" "}
                            {id == 3
                              ? "plan "
                              : id == 2
                              ? "recipes"
                              : "schedule "}{" "}
                            to display, click the below button to add it
                          </p>
                        </div>
                        <button
                          className="button-new"
                          onClick={() => setOpenModal(true)}
                        >
                          Add{" "}
                          {id == 3
                            ? "Plan "
                            : id == 2
                            ? "Recipes"
                            : "Schedule "}
                        </button>
                      </div>
                    )}
                </Grid>
              )}
            </Grid>
          </div>

          {openModal &&
            (id == 1 ? (
              <AddFoodModal
                isOpen={openModal}
                setIsOpen={setOpenModal}
                setTrainerFoodItem={setTrainerFoodItem}
              />
            ) : id == 4 ? (
              <AddMealModal
                open={openModal}
                setIsOpen={setOpenModal}
                setNutritionData={setNutritionData}
                setdefaultNItem={setdefaultNItem}
                trainerUUID={store.getState().auth.trainerUUID}
                setdefaultNItemForMeal={setdefaultNItemForMeal}
                getMealScheduleByID={getMealScheduleByID}
                isTemplate={isTemplate}
              />
            ) : id == 3 ? (
              <AddMealPlanModal
                isOpen={openModal}
                setIsOpen={setOpenModal}
                setNutritionData={setNutritionData}
                setdefaultNItem={setdefaultNItem}
                isTemplate={isTemplate}
                openAddModal={() =>
                  nItemDetailsRef.current.setOpenAddMealModal(true)
                }
              />
            ) : (
              <AddRecipieModal
                isOpen={openModal}
                setIsOpen={setOpenModal}
                getTrainerRecipes={getRecipes}
                getCoachRecipes={getTrainerRecipes}
                setNutritionData={setNutritionData}
                setdefaultNItem={setdefaultNItem}
                setLoadingSearchedItem={setLoadingSearchedItem}
              />
            ))}

          {showUpdateMealScheduleModal && (
            <UpdateMealSchedule
              open={showUpdateMealScheduleModal}
              setIsOpen={setShowUpdateMealScheduleModal}
              nutritionData={nutritionData}
              setNutritionData={setNutritionData}
              setdefaultNItem={setdefaultNItem}
              trainerUUID={store.getState().auth.trainerUUID}
              setdefaultNItemForMeal={setdefaultNItemForMeal}
              getMealScheduleByID={getMealScheduleByID}
              isTemplate={isTemplate}
              defaultNItem={defaultNItem}
              setTempData={setTempData}
            />
          )}
          <MacrosModal
            openModal={openMacrosModal}
            handleClose={() => {
              setOpenMacrosModal(false);
            }}
          />

          {exploreFoodOpen && (
            <ExploreFoodModal
              open={exploreFoodOpen}
              setOpen={setExploreFoodOpen}
              setSelectedFood={setSelectedFood}
              setOpenAddFoodModal={setOpenModal}
            />
          )}
          <CustomModal
            onConfirm={handleConfirm}
            onCancel={handleCancel}
            isOpen={openConfirmDialog}
            close={setOpenConfirmDialog}
          />
        </div>
        {copilotOpen && (id == "2" || id == "3") && (
          <Copilot
            open={copilotOpen}
            setOpen={setCopilotOpen}
            reloadRecipes={() => {
              getRecipeAfterDeletion();
              setSelectedRecipeType("trainer");
            }}
            onSuccess={(selectedType, newMeal) => {
              if (selectedType === GENERATE_MEAL_PLAN) {
                // add new meal to meal plan
                let updatedMealPlans = [newMeal, ...nutritionData];
                setNutritionData(updatedMealPlans);
                setdefaultNItem(newMeal);
                setTempData(updatedMealPlans);
              }
            }}
            key={"nutrition"}
          />
        )}
      </Box>
    </Layout>
  );
};

function CustomModal({ onConfirm, onCancel, isOpen, close }) {
  const handleOpen = () => {
    close(true);
  };

  const handleClose = (value) => {
    close(false);
    if (value === "no") {
      onCancel();
    } else {
      onConfirm();
    }
  };

  return (
    <Dialog open={isOpen}>
      <DialogTitle>Unsaved Changes</DialogTitle>
      <DialogContent>
        <p>You have added meal plans. Are you sure you want to delete them?</p>
      </DialogContent>
      <DialogActions>
        <button
          className="button-new"
          style={{
            width: 100,
          }}
          onClick={() => handleClose("yes")}
          color="primary"
        >
          Yes
        </button>
        <Button
          style={{ color: "#b21e42" }}
          onClick={() => handleClose("no")}
          color="primary"
        >
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Nutritions;
