import React, { useContext } from "react";
import FolderList from "./FolderList";
// import {CircleX} from "lucide-react";
import CancelIcon from "@mui/icons-material/Cancel";
import FolderInfo from "./FolderInfo";
import WorkoutInfo from "./WorkoutInfo";
import { workoutContext } from "./WorkoutContex";
import Copilot from "@/components/copilot/copilot";
import { cn } from "@/lib/utils";
import NoWorkoutsView from "./NoWorkoutsView";
 

function WorkoutFolderPage({
  setShowFolderTab,
  getWorkout,
  filterWorkoutsByTags,
}) {
  const {
    folderSelected,
    workoutSelected,
    setCopilotOpen,
    copilotOpen,
    originalData,
    folders,
    setOpenWorkoutBuilder,
    workoutInLocalStorage,
    showContinueEditing,
    setShowContinueEditing,
    onResumeEditing,
    unsavedWorkout,
  } = useContext(workoutContext);
  return (
    <div className="flex flex-col"
    style={{
      height: "calc(100vh - 100px)",
    }}>
      {workoutInLocalStorage && showContinueEditing && (
            <div className="flex w-full bg-red-300 mx-1 px-3 mb-1 rounded-md font-DMSans">
              Unsaved changes in &nbsp;
              <span className="font-semibold">
                {" "}
                {unsavedWorkout?.name}
              </span>{" "}
              workout
              <div className="ml-auto gap-4 flex items-center">
                <button
                  className="font-DMSans font-semibold"
                  onClick={onResumeEditing}
                >
                  Continue
                </button>
                <button
                  className="font-DMSans font-semibold"
                  onClick={() => {
                    setShowContinueEditing(false);
                    localStorage.removeItem("workoutData");
                  }}
                >
                  Discard
                </button>
              </div>
            </div>
          )}
     
    <div
      className="h-full w-full bg-white-pure rounded-xl flex relative"
      style={{
        height: "calc(100vh - 100px)",
      }}
    >

 
      {/* <div onClick={() => setShowFolderTab(false)}>
        <CancelIcon className="absolute -top-3 -right-2 " />
      </div> */}

      <FolderList filterWorkoutsByTags={filterWorkoutsByTags} />
      <div className="flex rounded-xl w-full h-full bg-[#f8f8f8]">
        {(originalData?.length === 0 && folders?.length === 0) ? (
          <div
            className={cn("w-full pt-[100px] rounded-lg bg-white-pure", {
              "w-[75%]": copilotOpen,
            })}
          >
            <NoWorkoutsView setOpenWorkoutBuilder={setOpenWorkoutBuilder} />
          </div>
        ) : (
          <div
            className={cn("w-full rounded-lg bg-white-pure", {
              "w-[75%]": copilotOpen,
            })}
          >
            {Object.keys(folderSelected || {}).length > 0 &&
              Object.keys(workoutSelected || {}).length === 0 && <FolderInfo />}
            {Object.keys(workoutSelected || {}).length > 0 && (
              <WorkoutInfo getWorkout={getWorkout} />
            )}
          </div>
        )}

        {copilotOpen && (
          <div className="ml-2">
            <Copilot
              open={copilotOpen}
              setOpen={setCopilotOpen}
              getWorkouts={getWorkout}
            />
          </div>
        )}
      </div>
    </div>
    </div>
  );
}

export default WorkoutFolderPage;
