import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from "react";
import OneBlock from "./OneBlock";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import UnitBlocks, { existingBlocks } from "./UnitBlocks";
import { X } from "lucide-react";
import OneExercise from "./OneExercise";
import OneSection from "./OneSection";
import RenderXAxis from "./utils/RenderXAxis";
import RenderYAxis from "./utils/RenderYAxis";
import AddProperty from "./AddProperty";
import Summary from "./Summary";
import {
  convertSpurfitStructureToData,
  convertToSpurfitStructure,
} from "./utils/helpers";
import {
  assignWorkout,
  createWorkout,
  updateWorkout,
} from "@/ApiServices/Workouts/Api";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import PlaceholderBlocks from "./utils/PlaceholderBlocks";
import { cn } from "@/lib/utils";
import useWindowSize from "@/common/hooks/useWindowSize";
import { useHistory } from "react-router-dom";
import AlertWhileLeaving from "./utils/AlertWhileLeaving";

const TopPart = forwardRef(
  (
    {
      workoutInfo,
      isUpdate,
      workoutKind,
      setWorkoutInfo,
      assignInfo,
      selectedAthlete,
    },
    ref
  ) => {
    const location = useLocation();
    const history = useHistory();

    const addPropertyRef = useRef(null);

    const coachID = useSelector((state) => state.auth.trainerUUID);

    const [blocks, setBlocks] = useState([]);
    const [isResizing, setIsResizing] = useState(false);
    const [selectedOption, setSelectedOption] = useState("distance");

    const [totalDistance, setTotalDistance] = useState(0);
    const [totalTime, setTotalTime] = useState(0);

    const [isBlocksInColumns, setIsBlocksInColumns] = useState(false);

    const [ratio, setRatio] = useState(1);
    const [isSavedWorkout, setIsSavedWorkout] = useState(false);

    const [showUnsavedAlert, setShowUnsavedAlert] = useState(false);
    const [shouldUnblock, setShouldUnblock] = useState(false);

    const [selectedExercise, setSelectedExercise] = useState({
      blockIndex: 0,
      sectionIndex: 0,
      exerciseIndex: 0,
    });

    const [selectedUnit, setSelectedUnit] = useState(
      "precentThresholdPaceRangeInMinPerKm"
    );

    const totalBlocksContainerRef = useRef(null);
    const parentDivRef = useRef(null);

    const { width } = useWindowSize();

    const onDragEnd = (result) => {
      if (!result.destination) {
        return;
      }
      if (result.destination.droppableId === "dontdrop") {
        return;
      }

      // Handle unit block drag and drop
      if (result.draggableId?.includes("unit-")) {
        const unitBlockId = result.draggableId.split("-")[1];
        const unitBlock = structuredClone(existingBlocks[unitBlockId]);

        // if exercise name is Active then set it to Run if workoutKind is Run otherwise set it to Bike

        unitBlock.data = unitBlock.data.map((exercise) => {
          let newExercise = { ...exercise.exercise };
          if (exercise.exercise.type === "ACTIVE") {
            let type =
              workoutKind === "run"
                ? "Run"
                : workoutKind === "bike"
                ? "Bike"
                : "Swim";
            newExercise = {
              ...newExercise,
              type: type.toUpperCase(),
              name: type,
            };
          }
          return {
            ...exercise,
            exercise: newExercise,
          };
        });
        const newBlocks = [...blocks];
        newBlocks.splice(result.destination.index, 0, unitBlock);
        setBlocks(newBlocks);
        return;
      }

      // Handle exercise block drag and drop
      const items = Array.from(blocks);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      setBlocks(items);
    };

    const convertDistanceToKilometers = (distance, unit) => {
      switch (unit) {
        case "Meters":
          return distance / 1000;
        case "Miles":
          return distance * 1.60934;
        case "Kilometers":
        default:
          return distance;
      }
    };

    const convertKilometerToDistance = (distance, unit) => {
      switch (unit) {
        case "Meters":
          return distance * 1000;
        case "Miles":
          return distance / 1.60934;
        case "Kilometers":
        default:
          return distance;
      }
    };

    const handleResize = (
      e,
      direction,
      ref,
      d,
      index,
      blockIndex,
      isUpdate
    ) => {
      const newBlocks = [...blocks];
      const block = newBlocks[blockIndex]?.data?.[index];

      let exercise = block.exercise;

      let actualWidth = block.width + d.width / ratio;
      // Update exercise properties based on resized width
      if (exercise) {
        const totalWidth = block.width + d.width;

        let distance = actualWidth / 100; // 100px = 1km
        let distanceUnit = exercise?.distanceUnit;
        distance = convertKilometerToDistance(distance, distanceUnit);
        distance = distance.toFixed(2);

        let durationInSeconds =
          distance !== 0
            ? Math.ceil(
                (exercise.durationInSeconds / exercise.distance) * distance
              )
            : exercise.durationInSeconds;
        durationInSeconds = Math.round(durationInSeconds);
        exercise = { ...exercise, distance, durationInSeconds };
      }

      // we will consider the extended width keeping in mind the ratio  we have set

      newBlocks[blockIndex].data[index] = {
        ...newBlocks[blockIndex]?.data?.[index],
        exercise,
        width: actualWidth,
        height: block.height + d.height,
      };
      setBlocks(newBlocks);
    };

    // Calculate total distance and time based on block data
    useEffect(() => {
      let totalDistance = 0;
      let totalTime = 0;

      blocks?.forEach((block) => {
        let blockDistance = 0;
        let blockDurationInSeconds = 0;

        block?.data?.forEach((exercise) => {
          let distanceUnit = exercise?.exercise?.distanceUnit;
          let distance = +exercise?.exercise?.distance || 0;
          let durationInSeconds = +exercise?.exercise?.durationInSeconds || 0;

          blockDistance += convertDistanceToKilometers(distance, distanceUnit);
          blockDurationInSeconds += durationInSeconds;
        });

        if (block?.repeat) {
          blockDistance *= block.repeat;
          blockDurationInSeconds *= block.repeat;
        }

        totalDistance += blockDistance;
        totalTime += blockDurationInSeconds;
      });

      totalTime = Math.round(totalTime);
      setTotalDistance(totalDistance);
      setTotalTime(totalTime);
      // console.log("All blocks: ", blocks);
      resizeBlocks(totalDistance);
    }, [blocks, width]);

    const onSave = async () => {
      let workout = convertToSpurfitStructure(
        {
          ...workoutInfo,
          totalDuration: totalTime,
          totalDistance: totalDistance,
          selectedIntensityType: selectedUnit,
          type: workoutKind,
          workout: blocks,
        },
        coachID
      );
      let updatedWorkoutInfo = {
        ...workout,
        workoutKind,
        workoutContent: undefined,
        isBlockWorkoutBuilder: false,
      };
      let d = {
        workoutID: workoutInfo?.id,
        workoutInfo: updatedWorkoutInfo,
        workoutContent: workout?.workoutContent,
      };
      setIsSavedWorkout(true);
      let result;
      if (isUpdate) {
        result = await updateWorkout(d);
      } else {
        result = await createWorkout(d);
      }
      if (
        !isUpdate &&
        result?.id &&
        assignInfo?.userUUID &&
        assignInfo?.isCalendar
      ) {
        await assignWorkout({
          workoutID: result?.id,
          userUUID: assignInfo.userUUID,
          startDate: assignInfo.date,
          isEnduranceWorkout: true,
        });
      }
    };

    const recoverDataFromLocalStorage = () => {
      let workout = localStorage.getItem("workoutData");
      if (workout) {
        let parsedWorkout = JSON.parse(workout);
        let tempworkoutInfo = {
          ...parsedWorkout,
          workoutContent: undefined,
        };
        setWorkoutInfo(tempworkoutInfo);
        let savedStructure = convertSpurfitStructureToData(parsedWorkout);
        setSavedStructure(savedStructure);
      }
    };

    const setSavedStructure = useCallback(
      (workout) => {
        if (blocks.length !== 0) return;
        console.log("Setting saved structure");
        // the workout coming here is same the we created in onSave function
        // so we can set the blocks directly
        setBlocks(workout.workout);

        // set the selected unit
        setSelectedUnit(workout.selectedIntensityType);
      },
      [location?.state?.data]
    );

    useEffect(() => {
      if (location?.state?.data && blocks.length === 0) {
        let savedStructure = convertSpurfitStructureToData(
          location?.state?.data
        );
        setSavedStructure(savedStructure);
      }
    }, []);

    useImperativeHandle(ref, () => ({
      onSave,
      setSavedStructure,
      recoverDataFromLocalStorage,
    }));

    const getBlockType = (block) => {
      // get first exercise in block
      let exercise = block?.data[0]?.exercise;
      return exercise?.name;
      if (exercise?.type === "warmup") {
        return "Warm Up";
      } else if (exercise?.type === "work") {
        return "Active";
      } else if (exercise?.type === "cooldown") {
        return "Cool Down";
      }
    };

    const addUnitBlockOnClick = (index) => {
      let unitBlock = structuredClone(existingBlocks[index]);
      setBlocks([...blocks, unitBlock]);
    };

    // totalBlocksContainerRef if the continer size exceeds the viewport size
    // then we will resize the blocks to fit the container
    const resizeBlocks = (totalDistance) => {
      if (!parentDivRef.current) return;
      let containerWidth = parentDivRef.current.offsetWidth;

      // Calculate 90% of the container width
      let ninetyPercentWidth = containerWidth * 0.9;

      // if (totalDistance * 100 > ninetyPercentWidth) {
      let newRatio = ninetyPercentWidth / (totalDistance * 100);
      setRatio(newRatio);
      // }
    };

    useEffect(() => {
      const saveDataToLocalStorageInCaseOfCrash = () => {
        let workout = convertToSpurfitStructure(
          {
            ...workoutInfo,
            totalDuration: totalTime,
            totalDistance: totalDistance,
            selectedIntensityType: selectedUnit,
            type: workoutKind,
            workout: blocks,
            workoutInfo: workoutInfo,
          },
          coachID
        );
        localStorage.setItem("workoutData", JSON.stringify(workout));
      };

      const handleBeforeUnload = (e) => {
        if (!isSavedWorkout) {
          saveDataToLocalStorageInCaseOfCrash();
        } else {
          localStorage.removeItem("workoutData");
        }
      };

      window.addEventListener("beforeunload", handleBeforeUnload);

      const unblock = history.block(() => {
        if (isSavedWorkout) {
          return true;
        }
        if (!shouldUnblock) {
          setShowUnsavedAlert(true);
        } else {
          handleBeforeUnload();
        }
        return shouldUnblock;
      });

      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
        unblock();
      };
    }, [history, blocks, isSavedWorkout, shouldUnblock, workoutInfo]);

    return (
      <DragDropContext
        onDragEnd={onDragEnd}
        onDragStart={(e) => {
          if (isResizing) {
            e.preventDefault();
          }
        }}
      >
        {showUnsavedAlert && (
          <AlertWhileLeaving
            isOpen={showUnsavedAlert}
            setIsOpen={setShowUnsavedAlert}
            onCancel={() => {
              setShowUnsavedAlert(false);
            }}
            onConfirm={() => {
              setShowUnsavedAlert(false);
              setShouldUnblock(true);
              history.goBack();
            }}
          />
        )}
        <div
          className={cn({
            "grid grid-cols-4 items-center gap-6 mt-8": !isBlocksInColumns,
            "flex flex-col gap-6 mt-6": isBlocksInColumns,
          })}
        >
          <UnitBlocks
            selectedUnit={selectedUnit}
            setSelectedUnit={setSelectedUnit}
            addOnClick={addUnitBlockOnClick}
            isBlocksInColumns={isBlocksInColumns}
            setIsBlocksInColumns={setIsBlocksInColumns}
          />

          <div
            className="flex w-full bg-white-pure rounded-xl shadow-lg col-span-3 py-4 px-2 relative"
            ref={parentDivRef}
          >
            <button
              className="absolute top-2 right-2 bg-[#F2F2F2] text-[#828282] font-DMSans text-font12 font-medium leading-[18px] px-2 py-1 rounded-lg hover:bg-[#E5E5E5] hover:text-[#333333] transition-all"
              onClick={() => {
                setBlocks([]);
                setRatio(1);
              }}
            >
              Clear Blocks
            </button>

            <RenderYAxis totalBlocksContainerRef={totalBlocksContainerRef} />
            <div
              className="flex flex-col w-full pl-1 overflow-hidden"
              ref={totalBlocksContainerRef}
            >
              <Droppable droppableId="droppable" direction="horizontal">
                {(provided, snapshot) => (
                  <div
                    className="min-h-[200px] flex items-end h-full"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {blocks.length === 0 ? (
                      <div>
                        <PlaceholderBlocks />
                      </div>
                    ) : (
                      blocks?.map((blck, blckIndex) => {
                        const noOfRepeats = blck.repeat ?? 1;
                        return (
                          <Draggable
                            key={blckIndex} // Key is required for Draggable
                            isDragDisabled={isResizing}
                            draggableId={blckIndex.toString()}
                            index={blckIndex}
                          >
                            {(provided) => (
                              <div
                                className="group flex relative"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <X
                                  size={16}
                                  color="#828282"
                                  className="absolute -top-2 right-0 cursor-pointer hidden group-hover:block z-[9999]"
                                  onClick={() =>
                                    setBlocks(
                                      blocks.filter(
                                        (_, index) => index !== blckIndex
                                      )
                                    )
                                  }
                                />
                                <p className="absolute -top-3 left-1 font-DMSans text-font12 text-[#828282] not-italic leading-[18px] font-medium hidden group-hover:block z-[999]">
                                  {getBlockType(blck)}
                                </p>
                                {/* Repeat block based on repeat count */}
                                {Array.from({ length: noOfRepeats }).map(
                                  (_, repeatIndex) => (
                                    <div
                                      key={repeatIndex} // Key is required for repeat
                                      className="flex items-end relative pt-2 group"
                                    >
                                      {blck?.data?.map((block, index) => (
                                        <OneBlock
                                          key={`${blckIndex}-${index}`}
                                          index={index}
                                          block={block}
                                          selectedUnit={selectedUnit}
                                          onResize={(e, direction, ref, d) =>
                                            handleResize(
                                              e,
                                              direction,
                                              ref,
                                              d,
                                              index,
                                              blckIndex
                                            )
                                          }
                                          setIsResizing={setIsResizing}
                                          ratio={ratio}
                                        />
                                      ))}
                                    </div>
                                  )
                                )}
                              </div>
                            )}
                          </Draggable>
                        );
                      })
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
              <RenderXAxis
                totalDistance={totalDistance}
                totalBlocksContainerRef={totalBlocksContainerRef}
                ratio={ratio}
              />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-4 gap-6 mt-6">
          <div
            className="bg-white-pure shadow-lg rounded-xl"
            style={{ height: "fit-content" }}
          >
            <Summary totalTime={totalTime} totalDistance={totalDistance} />
          </div>
          <div className="col-span-2 flex flex-col gap-[24px]">
            {blocks?.map((blck, blckIndex) => {
              return (
                <OneSection
                  block={blck}
                  key={`${blckIndex} - ${Math.random()}`}
                  blockIndex={blckIndex}
                  selectedExercise={selectedExercise}
                  onExerciseSelect={(exerciseIndex) => {
                    setSelectedExercise({
                      blockIndex: blckIndex,
                      exerciseIndex: exerciseIndex,
                    });

                    addPropertyRef.current?.applyChanges();
                  }}
                  onExerciseDuplicate={(exerciseIndex) => {
                    const newBlocks = [...blocks];
                    const block = newBlocks[blckIndex];
                    const newBlock = { ...block };
                    const newExercise = structuredClone(
                      block.data[exerciseIndex].exercise
                    );
                    newBlock.data.splice(exerciseIndex + 1, 0, {
                      ...block.data[exerciseIndex],
                      exercise: newExercise,
                    });
                    newBlocks[blckIndex] = newBlock;
                    setBlocks(newBlocks);
                  }}
                  selectedIntensityType={selectedUnit}
                  onRepeatChange={(repeat) => {
                    const newBlocks = [...blocks];
                    newBlocks[blckIndex] = { ...newBlocks[blckIndex], repeat };
                    setBlocks(newBlocks);
                  }}
                  onDeleteBlock={() => {
                    setBlocks(blocks.filter((_, index) => index !== blckIndex));
                  }}
                  onDuplicateBlock={() => {
                    const newBlocks = [...blocks];
                    newBlocks.splice(blckIndex, 0, structuredClone(blck));
                    setBlocks(newBlocks);
                  }}
                  onAddSubstep={(exerciseIndex) => {
                    const newBlocks = [...blocks];
                    const block = newBlocks[blckIndex];
                    const newBlock = { ...block };
                    const newExercise = structuredClone(
                      block.data[exerciseIndex].exercise
                    );
                    newBlock.data.splice(exerciseIndex + 1, 0, {
                      ...block.data[exerciseIndex],
                      exercise: newExercise,
                    });
                    newBlocks[blckIndex] = newBlock;
                    setBlocks(newBlocks);
                  }}
                  deleteOneExercise={(exerciseIndex) => {
                    const newBlocks = [...blocks];
                    const block = newBlocks[blckIndex];
                    const newBlock = { ...block };
                    newBlock.data = newBlock.data.filter(
                      (_, index) => index !== exerciseIndex
                    );
                    newBlocks[blckIndex] = newBlock;
                    setBlocks(newBlocks);
                  }}
                />
              );
            })}
          </div>
          <div
            className="bg-white-pure shadow-lg min-h-[100px] rounded-xl"
            style={{ height: "fit-content" }}
          >
            <AddProperty
              key={
                blocks?.[selectedExercise?.blockIndex]?.data?.[
                  selectedExercise.exerciseIndex
                ]?.id
              }
              ref={addPropertyRef}
              selectedData={
                blocks?.[selectedExercise?.blockIndex]?.data?.[
                  selectedExercise.exerciseIndex
                ]
              }
              selectedExercise={
                blocks?.[selectedExercise?.blockIndex]?.data?.[
                  selectedExercise.exerciseIndex
                ]?.exercise
              }
              setSelectedData={(data) => {
                const newBlocks = [...blocks];
                newBlocks[selectedExercise.blockIndex].data[
                  selectedExercise.exerciseIndex
                ] = data;
                console.log("setting data", data);
                setBlocks(newBlocks);
              }}
              selectedUnit={selectedUnit}
              setSelectedUnit={setSelectedUnit}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              ratio={ratio}
              setRatio={setRatio}
              workoutKind={workoutKind}
              selectedAthlete={selectedAthlete}
            />
          </div>
        </div>
      </DragDropContext>
    );
  }
);

export default TopPart;
