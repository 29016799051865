import { Box } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import NoVideo from "../../pages/Exercises/assests/NoVideo";
import { getVideoId } from "../../pages/Exercises/exerciseUtil";
import Input from "../InputComponents/Input";

const FollowAlongBuilder = ({
  setVideoData,
  preleminaryData,
  isVideoUpdate,
}) => {
  const [videoLink, setVideoLink] = useState(null);
  const [inputType, setInputType] = useState("youtube");
  const [videoDuration, setVideoDuration] = useState(0);

  const GetDuration = async () => {
    if (inputType === "youtube") {
      const API_KEY = "AIzaSyDRTfM9aDRaXx4Nf8jOFREsds6iqn7_OHA";
      axios
        .get(
          `https://www.googleapis.com/youtube/v3/videos?part=contentDetails&id=${getVideoId(
            videoLink
          )}&key=${API_KEY}`
        )
        .then((response) => {
          console.log("Duration API call : ", response);
          const duration = response?.data?.items[0]?.contentDetails?.duration;
          const regex =
            /P(?:([\d.]+Y)?(\d+M)?(\d+D)?)?T((\d+H)?(\d+M)?(\d+S)?)?/; // Regular expression to match ISO 8601 duration
          const matches = duration.match(regex);

          const years = parseFloat(matches[1]) || 0;
          const months = parseFloat(matches[2]) || 0;
          const days = parseFloat(matches[3]) || 0;
          const hours = parseFloat(matches[5]) || 0;
          const minutes = parseFloat(matches[6]) || 0;
          const seconds = parseFloat(matches[7]) || 0;

          // Calculate the total duration in seconds
          const totalSeconds =
            years * 31536000 + // 1 year = 31536000 seconds
            months * 2592000 + // 1 month = 2592000 seconds
            days * 86400 + // 1 day = 86400 seconds
            hours * 3600 + // 1 hour = 3600 seconds
            minutes * 60 +
            seconds;

          setVideoDuration(totalSeconds);
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (inputType === "vimeo") {
      axios
        .get(
          `https://vimeo.com/api/oembed.json?url=${encodeURIComponent(
            videoLink
          )}`
        )
        .then((response) => {
          const duration = response.data.duration;
          setVideoDuration(duration);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const init = async () => {
    // await GetDuration()

    await setVideoData({
      id: preleminaryData?.id,
      videoURL: videoLink,
      thumbnailURL: `https://vumbnail.com/${getVideoId(videoLink)}_large.jpg`,
      duration: videoDuration,
    });
  };
  useEffect(() => {
    init();
  }, [videoLink]);

  useEffect(() => {
    setVideoLink(preleminaryData?.workoutContent?.videoURL);
  }, [preleminaryData?.workoutContent?.videoURL]);

  return (
    <div
      style={{ display: "flex", flexDirection: "row", padding: "30px 100px" }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          width: "100%",
          bgcolor: "white",
          margin: "0px 20px",
          padding: "25px 25px",
          borderRadius: "10px",
          boxShadow: "0 4px 10px 0 rgba(0, 0, 0, 0.04)",
          border: "1px solid #DDDDDD",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "16px",
            width: "100%",
            height: "100%",
            padding: "0px",
          }}
        >
          <h3>Add Youtube/Vimeo video</h3>

          {/* <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onChange={(e) => {
              setInputType(e.target.value);
            }}
            sx={{
              overflowX: "scroll",
              flexWrap: "nowrap",
              display: "flex",
              justifyContent: "space-between",
              width: "70%%",
            }}
          >
            <FormControlLabel
              sx={{
                minWidth: "80px",
                "& .MuiFormControlLabel-label": {
                  color: "#424242",
                  fontFamily: "DM Sans",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: inputType === "youtube" ? 500 : 400,
                  lineHeight: "21px",
                },
              }}
              value="youtube"
              checked={inputType === "youtube"}
              control={<Radio color="royalBlue" />}
              label="Youtube"
            />
            <FormControlLabel
              sx={{
                minWidth: "100px",
                "& .MuiFormControlLabel-label": {
                  color: "#424242",
                  fontFamily: "DM Sans",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: inputType === "vimeo" ? 500 : 400,
                  lineHeight: "21px",
                },
              }}
              value="vimeo"
              checked={inputType === "vimeo"}
              control={<Radio color="royalBlue" />}
              label="Vimeo"
            />
          </RadioGroup> */}
          {(true || videoLink || !isVideoUpdate) && (
            <Input
              label="Video Link"
              type="text"
              value={videoLink || preleminaryData?.workoutContent?.videoURL}
              onChange={(event) => {
                let value = event.target.value;
                if (value && typeof value === "string") {
                  value = value.trim();
                  setVideoLink(value);
                } else {
                  setVideoLink("");
                }
              }}
              id="followAlong-video-link"
              containerStyle={{ width: "100%" }}
              style={{ width: "100%" }}
            />
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "space-between",
          width: "50%",
          bgcolor: "white",
          margin: "0px 20px",
          padding: "25px 25px",
          borderRadius: "10px",
          boxShadow: "0 4px 10px 0 rgba(0, 0, 0, 0.04)",
          border: "1px solid #DDDDDD",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "start",
            gap: "10px",
            width: "100%",
          }}
        >
          <h3>Workout Thumbnail</h3>
          {videoLink ? (
            <div
              className="add-exercise-modal-video"
              style={{
                width: "100%",
              }}
            >
              <img
                className="add-exercise-modal-video"
                src={
                  `https://vumbnail.com/${getVideoId(videoLink)}_large.jpg` ||
                  "https://upload.wikimedia.org/wikipedia/commons/3/3f/Placeholder_view_vector.svg"
                }
                style={{ height: "100%", width: "100%", objectFit: "fill" }}
              />
            </div>
          ) : (
            <div
              className="add-exercise-modal-video"
              style={{ objectFit: "fill", width: "100%" }}
            >
              <NoVideo />
              <p className="add-exercise-modal-video-text">
                Add video link to display thumbnail
              </p>
            </div>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default FollowAlongBuilder;
