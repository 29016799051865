import { CheckRounded } from "@mui/icons-material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import {
  Box,
  Divider,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import axios from "axios";
import { NotebookPen } from "lucide-react";
import React, { useContext, useEffect, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import AddExerciseIllustration from "../../assets/workoutBuilder/addExerciseIllustration.svg";
import AddExerciseIllustrationMini from "../../assets/workoutBuilder/addExerciseIllustrationMini.svg";
import AddIcon from "../../assets/workoutBuilder/addIcon.svg";
import RemoveIcon from "../../assets/workoutBuilder/removeIcon.svg";
import { updateTrainerData } from "../../redux/authSlice";
import { store } from "../../redux/store";
import AddNotesDialog from "./AddNotesDialog";
import CustomMenu from "./CustomMenu";
import Exercise from "./Exercise";
import { formatSecondsToMMSS, onRoundsChange } from "./helpers";
import RoundReorderView from "./RoundReorderView";
import { WorkoutContext } from "./WorkoutBuilder";
import { WorkoutModalContext } from "./WorkoutBuilderModal";

const Round = ({
  index,
  round,
  setRounds,
  setSelectedExerciseInfo,
  comingFromModal,
  updateFirstErrorRef,
  triggerRecheck,
  showErrorWarning,
}) => {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const [canReorder, setCanReorder] = useState(false);
  const [showAddInputDialog, setShowAddInputDialog] = useState(false);
  const dispatch = useDispatch();
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [minutes, setMinutes] = useState(
    round?.runTime ? Math.floor(round?.runTime / 60) : null
  );
  const [seconds, setSeconds] = useState(
    round?.runTime ? round?.runTime % 60 : null
  );

  const trainerSectionType = useSelector(
    (state) => state.auth.data.sectionTypes
  );
  const defaultSectionType = [
    "Warm Up",
    "Regular",
    "Superset",
    "AMRAP",
    "EMOM",
    "TABATA",
    "Mixed Sets",
  ];
  const [sectionTypes, setSectionTypes] = useState(defaultSectionType);
  const [isAdding, setIsAdding] = useState(false);
  const [newSectionTypeName, setNewSectionTypeName] = useState("");
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const handleDragOver = (event) => {
    console.log("drag over");
    setIsDraggingOver(true);
  };

  const handleDragLeave = (event) => {
    setIsDraggingOver(false);
  };

  const handleNotesSave = (notes) => {
    if (typeof notes === "string") {
      const newRound = { ...round };
      newRound.instructions = notes;
      setRounds((prev) => {
        const newRounds = [...prev];
        newRounds[index] = { round: newRound };
        return newRounds;
      });
    }
  };

  const addBreak = (exerciseIndex) => {
    const newRound = { ...round };
    newRound.measurements[exerciseIndex].measurement.break = { duration: 60 };
    setRounds((prev) => {
      const newRounds = [...prev];
      newRounds[index] = { round: newRound };
      return newRounds;
    });
  };

  const incrementOrDecrementBreakBy30Seconds = (exerciseIndex, increment) => {
    const newRound = { ...round };
    let newBreak =
      newRound.measurements[exerciseIndex].measurement.break.duration || 0;
    if (increment) {
      newBreak += 15;
    } else {
      newBreak -= 15;
    }
    newRound.measurements[exerciseIndex].measurement.break =
      newBreak > 0
        ? {
            duration: newBreak,
          }
        : undefined;
    setRounds((prev) => {
      const newRounds = [...prev];
      newRounds[index] = { round: newRound };
      return newRounds;
    });
  };

  const addBreakTime = (exerciseIndex, minutes, seconds) => {
    const newRound = { ...round };
    const newBreak =
      newRound.measurements[exerciseIndex].measurement.break.duration || 0;
    newRound.measurements[exerciseIndex].measurement.break = {
      duration: +minutes * 60 + +seconds,
    };
    setRounds((prev) => {
      const newRounds = [...prev];
      newRounds[index] = { round: newRound };
      return newRounds;
    });
  };

  const duplicateRound = () => {
    // add the round just below the current round and shift the rest of the rounds down

    setRounds((prev) => {
      const newRounds = [...prev];
      let nextRound = JSON.parse(JSON.stringify(round));
      newRounds.splice(index + 1, 0, { round: nextRound });
      return newRounds;
    });
  };

  useEffect(() => {
    // if current round is Warm Up, then set numRounds to 1
    if (round?.type !== "Warm Up" && round?.type !== "Mixed Sets") {
      onRoundsChange(index, round, setRounds);
    }
  }, [round?.numRounds]);

  useEffect(() => {
    setMinutes(Math.floor(+round?.runTime / 60));
    setSeconds(+round?.runTime % 60);
    // console.log("runtime changed: ", round?.runTime);
  }, [round?.runTime]);

  useEffect(() => {
    if (trainerSectionType && Object.entries(trainerSectionType).length > 0) {
      setSectionTypes([...sectionTypes, ...Object.values(trainerSectionType)]);
    }
  }, []);

  const {
    setShowAddPropertiesModal,
    selectedExerciseInfo,
    incrementRound,
    decrementRound,
    reorderRound,
    setReorderRound,
    onSelectedExerciseChange,
    isDraggingSection,
  } = useContext(comingFromModal ? WorkoutModalContext : WorkoutContext);

  const addNewSectionType = (sectionTypes) => {
    // convert array to object with key as index+1 and value as section type
    let sectionTypesObj = {};
    sectionTypes.forEach((type, index) => {
      if (defaultSectionType.includes(type)) return;
      sectionTypesObj[index + 1] = type;
    });

    axios({
      method: "post",
      url: `${APIURL}CF/api/CoachWeb/Update/updateSectionTypes`,
      data: {
        coachID: store.getState().auth.trainerUUID,
        sectionTypes: sectionTypesObj,
      },
    })
      .then((res) => {
        console.log("section type added: ", res);

        // update in redux
        dispatch(updateTrainerData({ sectionTypes: sectionTypesObj }));
      })
      .catch((err) => {
        console.log("error adding section type: ", err);
      });
  };

  const updateCurrentRound = (modifiedRound) => {
    setRounds((prev) => {
      const newRounds = [...prev];
      newRounds[index] = { round: modifiedRound };
      return newRounds;
    });
  };

  const updateRuntimeOfRound = (minutes, seconds) => {
    const newRound = { ...round };
    newRound.runTime = +minutes * 60 + +seconds;
    setRounds((prev) => {
      const newRounds = [...prev];
      newRounds[index] = { round: newRound };
      return newRounds;
    });
  };

  useEffect(() => {
    if (reorderRound === index) {
      setCanReorder(true);
    } else {
      setCanReorder(false);
    }
  }, [reorderRound]);

  const isAMRAP = round?.type === "AMRAP";
  const isEMOM = round?.type === "EMOM";
  const isWarmUp = round?.type === "Warm Up";
  const isMixedSets = round?.type === "Mixed Sets";

  const onTypeChange = (e) => {
    console.log("Type changed: ", e.target.value);
    let type = e.target.value;
    if (type === undefined) {
      setIsSelectOpen(true);
      return;
    } else {
      setIsSelectOpen(false);
    }
    if (type === "TABATA") {
      // check if the exercise has duration and break. if it doesn't have add duration as 20 and break as 10
      const newRound = { ...round };
      newRound.measurements.forEach((m) => {
        let measurement = m.measurement;
        if (!measurement?.duration) {
          let numRounds = round?.numRounds || 1;
          let durationObj = {};
          for (let i = 1; i <= numRounds; i++) {
            durationObj[i] = 20;
          }
          measurement.duration = durationObj;
        }
        if (!measurement?.break) {
          measurement.break = { duration: 10 };
        }
      });
      newRound.type = type;
      setRounds((prev) => {
        const newRounds = [...prev];
        newRounds[index] = { round: newRound };
        return newRounds;
      });
    } else {
      const newRound = { ...round };
      newRound.type = e.target.value;
      setRounds((prev) => {
        const newRounds = [...prev];
        newRounds[index] = { round: newRound };
        return newRounds;
      });
    }
  };

  const secondsInputRef = React.useRef(null);
  return (
    <>
      <Box
        sx={{
          minHeight: "100px",
          borderRadius: "12px",
          backgroundColor: "white",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            position: "relative",
            alignItems: "flex-center",
            height: 84,
            paddingTop: "22px",
            paddingBottom: "22px",
          }}
          className="section-header"
        >
          <div
            style={{ gap: "8px", alignItems: "flex-end" }}
            className="section-header-left"
          >
            <Select
              SelectDisplayProps={{
                style: {
                  color: "#242424",
                  fontFeatureSettings: "'clig' off, 'liga' off",
                  fontFamily: "DM Sans",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "21px",
                },
              }}
              open={isSelectOpen || isAdding}
              onClose={(e, reason) => {
                let targetClassNames = e?.target?.className;
                if (
                  typeof targetClassNames === "string" &&
                  targetClassNames.includes("MuiBackdrop-root")
                ) {
                  setIsSelectOpen(false);
                  setIsAdding(false);
                  setNewSectionTypeName("");
                  return;
                }
                // if (isAdding) return;
              }}
              sx={{
                height: "40px",
                borderRadius: "8px",
              }}
              variant="outlined"
              color="royalBlue"
              IconComponent={ExpandMoreRoundedIcon}
              value={round?.type || "Section Type"}
              onChange={onTypeChange}
              // defaultValue={"Section Type"}
              onOpen={() => {
                setIsSelectOpen(true);
              }}
            >
              <MenuItem
                sx={{
                  color: "#242424",
                  fontFeatureSettings: "'clig' off, 'liga' off",
                  fontFamily: "DM Sans",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "21px",
                }}
                value={"Section Type"}
                disabled
              >
                Section Type
              </MenuItem>
              {sectionTypes.map((type) => (
                <MenuItem
                  sx={{
                    color: "#242424",
                    fontFeatureSettings: "'clig' off, 'liga' off",
                    fontFamily: "DM Sans",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "21px",
                  }}
                  value={type}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setIsAdding(false);
                    setNewSectionTypeName("");
                  }}
                >
                  {type}
                </MenuItem>
              ))}
              {!isAdding ? (
                <MenuItem
                  sx={{
                    color: "#242424",
                    fontFeatureSettings: "'clig' off, 'liga' off",
                    fontFamily: "DM Sans",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "21px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setIsAdding(true);
                  }}
                >
                  <AddRoundedIcon /> Add Section Type
                </MenuItem>
              ) : (
                <MenuItem onKeyDown={(e) => e.stopPropagation()}>
                  <TextField
                    id="outlined-basic"
                    variant="standard"
                    value={newSectionTypeName}
                    onChange={(e) => {
                      e.stopPropagation();
                      setNewSectionTypeName(e.target.value);
                    }}
                    style={{
                      width: "100%",
                      marginBottom: "8px",
                      padding: "0 8px",
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        addNewSectionType([
                          ...sectionTypes,
                          newSectionTypeName,
                        ]);
                        setSectionTypes((prev) => {
                          const newSectionTypes = [...prev];
                          newSectionTypes.push(newSectionTypeName);
                          return newSectionTypes;
                        });
                        setNewSectionTypeName("");
                        setIsAdding(false);
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <CheckRounded
                          onClick={() => {
                            addNewSectionType([
                              ...sectionTypes,
                              newSectionTypeName,
                            ]);
                            setSectionTypes((prev) => {
                              const newSectionTypes = [...prev];
                              newSectionTypes.push(newSectionTypeName);
                              return newSectionTypes;
                            });
                            setNewSectionTypeName("");
                            setIsAdding(false);
                          }}
                          style={{
                            cursor: "pointer",
                          }}
                        />
                      ),
                    }}
                  />
                </MenuItem>
              )}
            </Select>

            {(isAMRAP || isEMOM) && (
              <div>
                <label
                  style={{
                    color: "#828282",
                    fontFeatureSettings: "'clig' off, 'liga' off",
                    fontFamily: "DM Sans",
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "15px",
                    marginLeft: "4px",
                  }}
                >
                  Duration
                </label>

                <div
                  name="runTime"
                  style={{
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                    borderRadius: "8px",
                    border: "1px solid #CCC",
                    background: "#FFF",
                    width: "80px",
                    paddingLeft: "8px",
                    paddingRight: "8px",
                  }}
                  className="flex items-center justify-between"
                >
                  <input
                    type="number"
                    placeholder="MM"
                    style={{
                      width: "50%",
                      marginRight: 2,
                      outline: "none",
                      fontSize: 14,
                      fontFamily: "DM Sans",
                      fontWeight: 500,
                      fontStyle: "normal",
                      lineHeight: "21px",
                      color: "#242424",
                      textAlign: "center",
                    }}
                    value={minutes}
                    onChange={(e) => {
                      if (e.target.value.length > 2) {
                        return;
                      }
                      setMinutes(e.target.value);
                      updateRuntimeOfRound(e.target.value, seconds);
                      if (e.target.value.length === 2) {
                        secondsInputRef.current.focus();
                      }
                    }}
                  />
                  <p
                    style={{
                      marginBottom: "2px",
                    }}
                  >
                    :
                  </p>
                  <input
                    type="number"
                    placeholder="SS"
                    ref={secondsInputRef}
                    style={{
                      width: "50%",
                      marginLeft: 2,
                      outline: "none",
                      fontSize: 14,
                      fontFamily: "DM Sans",
                      fontWeight: 500,
                      fontStyle: "normal",
                      lineHeight: "21px",
                      color: "#242424",
                      textAlign: "center",
                    }}
                    value={seconds}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value.length > 2) return;
                      updateRuntimeOfRound(minutes, value);
                      if (value > 59) {
                        setSeconds(59);
                      } else {
                        setSeconds(value);
                      }
                    }}
                  />
                </div>
              </div>
            )}

            {/* {round?.instructions && (
                <p className="  text-[12px] font-normal md-1 w-[300px]   ">
                  <span className="font-[500]"> Instructions: </span> {round?.instructions}
                </p>
              )} */}
          </div>

          <div
            style={{
              gap: "16px",
              alignItems: "flex-end",
            }}
            className="section-header-right"
          >
            <div
              onClick={() => {
                setShowAddInputDialog(true);
              }}
              className="mb-2"
            >
              <NotebookPen className="text-[#4e4aea]" />
            </div>
            {!isAMRAP && !isWarmUp && !isMixedSets && (
              <>
                <div
                  name="numRounds"
                  style={{
                    height: "40px",
                    display: "flex",
                    height: "40px",
                    alignItems: "center",
                    gap: "4px",
                    borderRadius: "8px",
                    border: "1px solid #CCC",
                    background: "#FFF",
                  }}
                  className="flex items-center"
                >
                  <RemoveRoundedIcon
                    sx={{
                      height: "40px",
                      width: "40px",
                      padding: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (round?.numRounds > 1) {
                        decrementRound(index);
                      }
                    }}
                  />
                  <p
                    style={{
                      color: "#424242",
                      fontFeatureSettings: "'clig' off, 'liga' off",
                      fontFamily: "DM Sans",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "normal",
                    }}
                  >
                    {round?.numRounds}{" "}
                    <span
                      style={{
                        color: "#828282",
                        fontFeatureSettings: "'clig' off, 'liga' off",
                        fontFamily: "DM Sans",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "normal",
                      }}
                    >
                      Set{round?.numRounds > 1 ? "s" : ""}
                    </span>{" "}
                  </p>{" "}
                  <AddRoundedIcon
                    sx={{
                      height: "40px",
                      width: "40px",
                      padding: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      incrementRound(index);
                    }}
                  />
                </div>
              </>
            )}

            <CustomMenu
              options={
                // if number of exercises is 1, then don't show delete option
                round?.measurements?.length <= 1
                  ? ["Delete", "Duplicate", "Add Notes"]
                  : ["Delete", "Duplicate", "Reorder Exercises", "Add Notes"]
              }
              onOptionClick={(option) => {
                if (option === "Delete") {
                  console.log("Deleteing");
                  setRounds((prev) => {
                    const newRounds = [...prev];
                    const prevRound = newRounds[index - 1];

                    if (prevRound?.round?.restTime) {
                      newRounds[index - 1].round.restTime = 0;
                    }

                    newRounds.splice(index, 1);
                    console.log("New Rounds after delete", newRounds);
                    return newRounds;
                  });
                } else if (option === "Reorder Exercises") {
                  if (canReorder) {
                    setReorderRound(-1);
                  } else {
                    setReorderRound(index);
                  }
                  setCanReorder(!canReorder);
                } else if (option === "Duplicate") {
                  duplicateRound();
                } else if (option === "Add Notes") {
                  setShowAddInputDialog(true);
                }
              }}
            />
          </div>
          {isEMOM && (
            <p
              style={{
                color: "#828282",
                fontFeatureSettings: "'clig' off, 'liga' off",
                fontFamily: "DM Sans",
                fontSize: "10px",
                fontStyle: "normal",
                fontWeight: 400,

                position: "absolute",
                left: "28px",
                bottom: 0,
              }}
            >
              60 sec/section.
            </p>
          )}
        </div>

        <Droppable
          key={index}
          droppableId={`section-${index}-${round.type}`}
          index={index}
          isDropDisabled={
            isDraggingSection //|| (reorderRound !== -1 && reorderRound !== index)
          }
        >
          {(provided, snapshot) => {
            return (
              <div
                style={{
                  position: "relative",
                }}
                {...provided.droppableProps}
                ref={provided.innerRef}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
              >
                {/* <Typography>Rounds: {round?.numRounds}</Typography> */}
                {canReorder ? (
                  <RoundReorderView
                    addBreak={addBreak}
                    incrementOrDecrementBreakBy30Seconds={
                      incrementOrDecrementBreakBy30Seconds
                    }
                    round={round}
                    index={index}
                    setRounds={setRounds}
                    setCanReorder={setCanReorder}
                    canReorder={canReorder}
                    selectedExerciseInfo={selectedExerciseInfo}
                    setSelectedExerciseInfo={setSelectedExerciseInfo}
                    setShowAddPropertiesModal={setShowAddPropertiesModal}
                    key={index}
                    updateCurrentRound={updateCurrentRound}
                    comingFromModal={comingFromModal}
                  />
                ) : (
                  <Stack
                    sx={{
                      padding: "24px 16px",
                    }}
                    gap={"16px"}
                  >
                    {round?.measurements?.length > 0 ? (
                      round?.measurements?.map((m, exerciseIndex) => {
                        let measurement = m.measurement;
                        if (measurement?.movementName === "BREAK") return null;
                        const onSelect = () => {
                          // setShowAddPropertiesModal(false);
                          onSelectedExerciseChange();

                          let timeoutId = setTimeout(() => {
                            setSelectedExerciseInfo({
                              exercise: measurement,
                              exerciseIndex: exerciseIndex,
                              roundIndex: index,
                              exerciseId:
                                measurement?.movementID || measurement?.id,
                            });
                            // setShowAddPropertiesModal(true);

                            clearTimeout(timeoutId);
                          }, 100);
                        };
                        return (
                          <>
                            <Draggable
                              key={exerciseIndex}
                              draggableId={`section-${index} exercise-${exerciseIndex}`}
                              index={exerciseIndex}
                              isDragDisabled={!canReorder}
                            >
                              {(provided) => (
                                <Stack
                                  gap={"16px"}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <Exercise
                                    key={exerciseIndex}
                                    selected={
                                      selectedExerciseInfo?.exerciseIndex ===
                                        exerciseIndex &&
                                      selectedExerciseInfo?.roundIndex === index
                                    }
                                    measurement={measurement}
                                    selectedExerciseInfo={selectedExerciseInfo}
                                    onSelect={onSelect}
                                    isDragEnabled={canReorder}
                                    roundIndex={index}
                                    exerciseIndex={exerciseIndex}
                                    exerciseId={
                                      measurement?.movementID || measurement?.id
                                    }
                                    comingFromModal={comingFromModal}
                                    updateFirstErrorRef={updateFirstErrorRef}
                                    triggerRecheck={triggerRecheck}
                                    showErrorWarning={showErrorWarning}
                                  />

                                  {measurement?.break ? (
                                    <ShowRest
                                      measurement={measurement}
                                      incrementOrDecrementBreakBy30Seconds={
                                        incrementOrDecrementBreakBy30Seconds
                                      }
                                      index={exerciseIndex}
                                      addBreakTime={addBreakTime}
                                    />
                                  ) : (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        position: "relative",
                                        height: "32px",
                                        width: "100%",
                                      }}
                                    >
                                      <button
                                        style={{
                                          borderRadius: "20px",
                                          height: "32px",
                                          cursor: "pointer",
                                          zIndex: 1,
                                          backgroundColor: "white",
                                        }}
                                        onClick={() => addBreak(exerciseIndex)}
                                        className="button-new-outlined-rounded"
                                      >
                                        <p className="rounded-btn-text">
                                          Add Break
                                        </p>
                                      </button>
                                      <Divider
                                        absolute
                                        sx={{
                                          top: "50%",
                                          left: "50%",
                                          height: "1px",
                                          transform: "translate(-50%, -50%)",
                                          zIndex: 0,
                                        }}
                                      />
                                    </Box>
                                  )}
                                </Stack>
                              )}
                            </Draggable>
                          </>
                        );
                      })
                    ) : !snapshot.isDraggingOver ? (
                      <div>
                        <img
                          src={
                            index == 0
                              ? AddExerciseIllustration
                              : AddExerciseIllustrationMini
                          }
                          alt="add exercise illustration"
                          style={{
                            width: "100%",
                            height: index == 0 ? "100%" : "120px",
                          }}
                        />
                        <p
                          style={{
                            color: "#424242",
                            fontFeatureSettings: "'clig' off, 'liga' off",
                            fontFamily: "DM Sans",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "20px",
                            marginTop: "16px",
                            width: "100%",
                            textAlign: "center",
                          }}
                        >
                          Drag and Drop exercise from left panel
                        </p>
                      </div>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          height: index == 0 ? "100%" : "120px",
                        }}
                      />
                    )}
                  </Stack>
                )}

                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
        {showAddInputDialog && (
          <AddNotesDialog
            isOpen={showAddInputDialog}
            setIsOpen={setShowAddInputDialog}
            handleNotesSave={handleNotesSave}
            preNotes={round?.instructions}
          />
        )}
      </Box>
    </>
  );
};

export const ShowRest = ({
  measurement,
  index,
  incrementOrDecrementBreakBy30Seconds,
  addBreakTime,
}) => {
  const [minutes, setMinutes] = useState(
    Math.floor(measurement?.break?.duration / 60)
  );
  const [seconds, setSeconds] = useState(measurement?.break?.duration % 60);
  const [showRestInput, setShowRestInput] = useState(false);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        height: "32px",
        width: "100%",
      }}
      onDoubleClick={() => {
        setMinutes(Math.floor(measurement?.break?.duration / 60));
        setSeconds(measurement?.break?.duration % 60);
        setShowRestInput(true);
      }}
    >
      <button
        style={{
          borderRadius: "20px",
          width: "130px",
          zIndex: 1,
          backgroundColor: "white",
          height: "32px",
          gap: 8,
          position: "relative",
        }}
        className="button-new-outlined-rounded"
      >
        {showRestInput ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 8,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <input
                type="number"
                placeholder="MM"
                style={{
                  width: "30px",
                  marginRight: 2,
                  outline: "none",
                  fontSize: 14,
                  fontFamily: "DM Sans",
                  fontWeight: 500,
                  fontStyle: "normal",
                  lineHeight: "21px",
                  color: "#242424",
                  textAlign: "center",
                }}
                value={minutes}
                onChange={(e) => {
                  if (e.target.value.length > 2) {
                    return;
                  }
                  setMinutes(e.target.value);
                }}
              />

              <p
                style={{
                  marginBottom: "2px",
                }}
              >
                :
              </p>
              <input
                type="number"
                placeholder="SS"
                style={{
                  width: "30px",
                  marginLeft: 2,
                  outline: "none",
                  fontSize: 14,
                  fontFamily: "DM Sans",
                  fontWeight: 500,
                  fontStyle: "normal",
                  lineHeight: "21px",
                  color: "#242424",
                  textAlign: "center",
                }}
                value={seconds}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value.length > 2) return;
                  if (value > 59) {
                    setSeconds(59);
                  } else {
                    setSeconds(value);
                  }
                }}
              />
            </div>
            <CheckCircleOutlineRoundedIcon
              sx={{ cursor: "pointer" }}
              onClick={() => {
                addBreakTime(index, minutes, seconds);
                setShowRestInput(false);
              }}
            />
          </div>
        ) : (
          <>
            <img
              src={RemoveIcon}
              alt="remove icon"
              style={{ width: "24px", height: "24px", cursor: "pointer" }}
              onClick={() => {
                incrementOrDecrementBreakBy30Seconds(index, false);
              }}
            />
            <p className="rounded-btn-text">
              {formatSecondsToMMSS(measurement?.break?.duration)}
            </p>
            <img
              src={AddIcon}
              alt="add icon"
              style={{ width: "24px", height: "24px", cursor: "pointer" }}
              onClick={() => {
                incrementOrDecrementBreakBy30Seconds(index, true);
              }}
            />
          </>
        )}
      </button>
      <Divider
        absolute
        sx={{
          top: "50%",
          left: "50%",
          height: "1px",
          transform: "translate(-50%, -50%)",
          zIndex: 0,
        }}
      />
    </Box>
  );
};

export default Round;
