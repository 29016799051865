import React from "react";

const FormSubmitSuccess = () => {
  return (
    <div
      style={{
        height: "100vh",
      }}
      className=" flex flex-col items-center justify-center bg-white-pure rounded-xl shadow-xl"
    >
      <div className="flex items-center gap-2 flex-col-reverse md:flex-row">
        <h1 className="font-DMSans text-font32 font-semibold text-[#8330e9] text-center ">
          Form Submitted Successfully!
        </h1>
        <img
          src="https://img.icons8.com/color/48/000000/ok--v1.png"
          alt="success"
        />
      </div>
    </div>
  );
};

export default FormSubmitSuccess;
