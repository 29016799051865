import { Button, Divider, Stack, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import Round from "../Round";
import AddIcon from "@/assets/workoutBuilder/addIcon.svg";
import RemoveIcon from "@/assets/workoutBuilder/removeIcon.svg";
import { formatSecondsToMMSS } from "../helpers";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { EnduranceContext } from "./EnduranceBuilder";

import EnduranceRound from "./EnduranceRound";
import EnduranceExercise from "./EnduranceExercise";

const EnduranceContentPage = ({
  rounds,
  setRounds,
  setExercise,
  selectedExerciseInfo,
  setSelectedExerciseInfo,
  selectedMenuItem,
  setSelectedMenuItem,
  workoutKind,
}) => {
  console.log("workoutKind endurance content: ", workoutKind);
  const { isDraggingSection } = useContext(EnduranceContext);
  const [exerciseIndex, setExerciseIndex] = useState(0);
  const [selectedExercise, setSelectedExercise] = useState(null);

  const { exercises } = useContext(EnduranceContext);

  const isSwim = workoutKind === "swim";

  const AddRounds = () => {
    setRounds([
      ...rounds,
      {
        round: {
          numRounds: 1,
          measurements: [
            {
              measurement: {
                type: "substep",
                movementName:
                  workoutKind === "run"
                    ? "Run"
                    : workoutKind === "bike"
                    ? "Bike"
                    : "Swim",
                substepIndex: 0,
                weightUnits: "Pounds",
                heightUnits: "Inches",
                distanceUnits: "Meters",
              },
            },
          ],
          ...(isSwim ? { poolLength: 25 } : {}),
        },
      },
    ]);
  };

  const addRoundRestTime = (roundIndex, seconds) => {
    setRounds((prevRounds) => {
      const updatedRounds = [...prevRounds];
      updatedRounds[roundIndex].round.restTime = seconds;
      return updatedRounds;
    });
  };

  const addSubstep = (roundIndex) => {
    setRounds((prevRounds) => {
      const updatedRounds = [...prevRounds];
      const newSubstep = {
        measurement: {
          type: "substep",
          movementName:
            workoutKind === "run"
              ? "Run"
              : workoutKind === "bike"
              ? "Bike"
              : "Swim",

          substepIndex: updatedRounds[roundIndex].round.measurements.length,
        },
      };
      updatedRounds[roundIndex].round.measurements.push(newSubstep);
      console.log("updated rounds :", updatedRounds);
      return updatedRounds;
    });
  };

  const onSelect = () => {
    // setShowAddPropertiesModal(false);
    onSelectedExerciseChange();

    let timeoutId = setTimeout(() => {
      setSelectedExerciseInfo({
        exercise: measurement,
        exerciseIndex: exerciseIndex,
        roundIndex: index,
        exerciseId: measurement?.movementID || measurement?.id,
      });
      // setShowAddPropertiesModal(true);

      clearTimeout(timeoutId);
    }, 100);
  };

  return (
    <Stack
      sx={{
        // backgroundColor: "#fff",
        borderRadius: "12px",
        marginBottom: "500px",
      }}
    >
      <Droppable
        droppableId="my-droppable-section"
        isDropDisabled={!isDraggingSection}
      >
        {(provided, snapshot) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {rounds.map((round, index) => {
              return (
                <Draggable
                  key={"round-" + index}
                  draggableId={"round-" + index}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <EnduranceRound
                        key={index}
                        index={index}
                        // roundIndex={roundIndex}
                        round={round.round}
                        setRounds={setRounds}
                        setExercise={setExercise}
                        setSelectedExerciseInfo={setSelectedExerciseInfo}
                        selected={selectedExercise === index}
                        onAddSubstep={addSubstep}
                        workoutKind={workoutKind}
                      />
                      {
                        // rounds.length > 1 && index !== rounds.length - 1&&
                        <RenderRest
                          key={"rest" + index}
                          rounds={rounds}
                          setRounds={setRounds}
                          index={index}
                          onAddSubstep={addSubstep}
                        />
                      }
                    </div>
                  )}
                </Draggable>
              );
            })}
          </div>
        )}
      </Droppable>

      <div
        style={{
          display: "flex",
          justifyContent: "end",
          padding: "24px",
        }}
      >
        <div
          style={{
            backgroundColor: "#fff",
            borderRadius: "12px",
          }}
        >
          <button
            style={{
              cursor: "pointer",
              width: "148px",
              borderWidth: 2,
            }}
            className="button-new-outlined"
            onClick={AddRounds}
          >
            Add Step
          </button>
        </div>
      </div>

      <div style={{ width: "50%" }}>
        {selectedExercise !== null && (
          <div>
            <AddPropertiesDialog
              exercise={rounds[selectedExercise].round.exercise}
              onClose={() => setSelectedExercise(null)}
              selectedMenuItem={selectedMenuItem}
              setSelectedMenuItem={setSelectedMenuItem}
            />
            <EnduranceExercise
              exercise={rounds[selectedExercise].round.exercise}
            />
          </div>
        )}
      </div>
    </Stack>
  );
};

export default EnduranceContentPage;

const RenderRest = ({ rounds, setRounds, index, onAddSubstep }) => {
  let extraStyles = {};
  if (rounds.length !== index + 1) {
    extraStyles = { marginTop: "-6px", marginBottom: "-6px" };
  }
  return (
    <div
      style={{
        display: "flex",
        background: "transparent",
        alignItems: "center",
        justifyContent: "center",
        // padding: "8px 16px",
        position: "relative",
        // ...extraStyles,
      }}
      className="my-3"
    >
      {rounds[index].round?.restTime ? (
        <button className="button-new-outlined-rounded">
          <img
            src={RemoveIcon}
            alt="remove icon"
            style={{ width: "24px", height: "24px", cursor: "pointer" }}
            onClick={() => {
              incrementOrDecrementRestTimeBy30Seconds(index, false);
            }}
          />
          <p className="rounded-btn-text">
            {formatSecondsToMMSS(rounds[index].round?.restTime)}
          </p>{" "}
          <img
            src={AddIcon}
            alt="add icon"
            style={{ width: "24px", height: "24px", cursor: "pointer" }}
            onClick={() => {
              incrementOrDecrementRestTimeBy30Seconds(index, true);
            }}
          />
        </button>
      ) : (
        <></>
      )}
    </div>
  );
};
