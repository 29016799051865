import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getWorkouts } from "../ApiServices/Workouts/Api";
import { store } from "./store";
import APIURL from "../APIDetails";

const initialState = {
  workouts: [],
  status: "idle",
  error: null,
};

export const fetchWorkouts = createAsyncThunk(
  "workouts/fetchWorkouts",
  async () => {
    const coachID = store.getState().auth.trainerUUID;
    const url = `${APIURL}CF/api/getWorkoutsbyCoach`;

    const params = { coachID: coachID };

    const response = await axios({
      method: "get",
      url: url,
      params: params,
    });

    const data = response.data.result;

    let newWorkoutData = [];
    data?.map((workout) => {
      let workoutContent = workout?.workoutContent;
      if (Array.isArray(workoutContent)) {
        const measurementsWithKey = workoutContent?.map((measurement) => {
          const reps = measurement?.reps ? { 1: measurement.reps } : undefined;
          const weight = measurement?.weights
            ? { 1: measurement.weights }
            : undefined;
          const distance = measurement?.distance
            ? { 1: measurement.distance }
            : undefined;
          const duration =
            measurement?.duration && measurement?.duration != 0
              ? { 1: measurement?.duration }
              : undefined;

          return {
            measurement: {
              movementName: measurement?.exercise,
              imageLink: measurement?.imageKey,
              videoLink: measurement?.videoKey,
              movementID: measurement?.id,
              reps,
              weight,
              distance,
              duration,
            },
          };
        });

        let workoutContentObj = {
          rounds: [
            {
              round: {
                name: "",
                roundID: Math.random() + "-" + Math.random(),
                orderIdx: 0,
                numRounds: 1,
                roundTime: 0,
                measurements: measurementsWithKey,
              },
            },
          ],
        };

        let currentWorkout = { ...workout, workoutContent: workoutContentObj };
        newWorkoutData.push(currentWorkout);
        // updateWorkoutsToNewStructure(workout?.id, workoutContentObj);
        // console.log({ newWorkoutData });
      } else {
        newWorkoutData.push(workout);
      }
    });

    return newWorkoutData;
  }
);

const updateWorkoutsToNewStructure = (workoutID, workoutContent) => {
  axios({
    method: "post",
    url: `${APIURL}api/updateparticularmodularworkout`,
    data: {
      primaryKey: workoutID,
      moduleDetails: workoutContent,
    },
  }).then((res) => {
    console.log(res);
  });
};

const workoutsSlice = createSlice({
  name: "workouts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWorkouts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchWorkouts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.workouts = action.payload;
      })
      .addCase(fetchWorkouts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default workoutsSlice.reducer;
