export const getUpdatedProperty = (property, numRounds) => {
  if (!property?.[1] || Object.entries(property)?.length === numRounds)
    return property;
  let newProperty = {};
  let prevValue = -1;
  for (let i = 1; i <= numRounds; i++) {
    // property exist then newProperty = property other wise take the last value and if the first value is missing take the value from nearest neighbor
    if (property[i]) {
      newProperty[i] = property[i];
      prevValue = property[i];
    } else if (prevValue !== -1) {
      newProperty[i] = prevValue;
    } else {
      // find the nearest neighbor
      let nearestNeighbor = 0;
      for (let j = i; j <= numRounds; j++) {
        if (property[j]) {
          nearestNeighbor = property[j];
          break;
        }
      }
      newProperty[i] = nearestNeighbor;
      prevValue = nearestNeighbor;
    }
  }
  return newProperty;
};

export const getPropertyToShow = (property, maxProperty, isEndurance) => {
  if (isEndurance) {
    return getEndurancePropertyToShow(property, maxProperty);
  }
  //this function concatinates the object values to a comma seperated string
  if (!property) return "";
  const numProperties = Object.keys(property).length;
  if (numProperties === 0) return "";

  if (maxProperty) {
    return Object.values(property)
      .map((value, index) => {
        return `${value} - ${maxProperty[index + 1]}`;
      })
      .join(", ");
  }

  return Object.values(property).join(", ");
};

export const getTimePropertyToShow = (property, isEndurance) => {
  if (isEndurance) {
    // show only one value
    return getEnduranceTimePropertyToShow(property);
  }

  // give time in seconds and get time in hh:mm:ss format
  if (!property) return "";
  const numProperties = Object.keys(property).length;
  if (numProperties === 0) return "";

  return Object.values(property)
    .map((time) => {
      let hours = Math.floor(time / 3600);
      let minutes = Math.floor((time % 3600) / 60);
      let seconds = time % 60;
      return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
        2,
        "0"
      )}:${String(seconds).padStart(2, "0")}`;
    })
    .join(", ");
};

const getEndurancePropertyToShow = (property, maxProperty) => {
  //this function concatinates the object values to a comma seperated string
  if (!property) return "";
  const numProperties = Object.keys(property).length;
  if (numProperties === 0) return "";

  if (maxProperty) {
    return property?.[1] + " - " + maxProperty?.[1];
  }

  return property?.[1];
};

const getEnduranceTimePropertyToShow = (property) => {
  // give time in seconds and get time in hh:mm:ss format
  if (!property) return "";
  const numProperties = Object.keys(property).length;
  if (numProperties === 0) return "";

  let seconds = property?.[1];
  if (!property?.[1] || property?.[1] === "") {
    // find the first number in the property
    Object.values(property).map((val) => {
      if (val && !isNaN(+val)) {
        seconds = +val;
      }
    });
  }
  let hour = Math.floor(seconds / 3600);
  let minute = Math.floor((seconds % 3600) / 60);
  let second = seconds % 60;

  return `${String(hour).padStart(2, "0")}:${String(minute).padStart(
    2,
    "0"
  )}:${String(second).padStart(2, "0")}`;
};

export const onRoundsChange = (index, round, setRounds) => {
  // update all the properties to number of numberOfRounds
  const newRound = structuredClone(round);
  let numRounds = round.numRounds;
  newRound.measurements.forEach((m) => {
    // reps
    m.measurement.reps = getUpdatedProperty(m.measurement.reps, numRounds);
    m.measurement.repsMax = getUpdatedProperty(
      m.measurement.repsMax,
      numRounds
    );
    m.measurement.repsF = getUpdatedProperty(m.measurement.repsF, numRounds);

    // weight
    m.measurement.weight = getUpdatedProperty(m.measurement.weight, numRounds);
    m.measurement.weightMax = getUpdatedProperty(
      m.measurement.weightMax,
      numRounds
    );
    m.measurement.weightF = getUpdatedProperty(
      m.measurement.weightF,
      numRounds
    );

    // height
    m.measurement.height = getUpdatedProperty(m.measurement.height, numRounds);
    m.measurement.heightMax = getUpdatedProperty(
      m.measurement.heightMax,
      numRounds
    );
    m.measurement.heightF = getUpdatedProperty(
      m.measurement.heightF,
      numRounds
    );

    // distance
    m.measurement.distance = getUpdatedProperty(
      m.measurement.distance,
      numRounds
    );
    m.measurement.distanceMax = getUpdatedProperty(
      m.measurement.distanceMax,
      numRounds
    );
    m.measurement.distanceF = getUpdatedProperty(
      m.measurement.distanceF,
      numRounds
    );

    // calorie
    m.measurement.calorie = getUpdatedProperty(
      m.measurement.calorie,
      numRounds
    );
    m.measurement.calorieMax = getUpdatedProperty(
      m.measurement.calorieMax,
      numRounds
    );
    m.measurement.calorieF = getUpdatedProperty(
      m.measurement.calorieF,
      numRounds
    );

    // duration
    m.measurement.duration = getUpdatedProperty(
      m.measurement.duration,
      numRounds
    );
  });

  setRounds((prev) => {
    const newRounds = [...prev];
    newRounds[index] = { round: newRound };
    return newRounds;
  });
};

export const formatSecondsToMMSS = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  seconds %= 60;
  return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
    2,
    "0"
  )}`;
};
