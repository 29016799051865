import { store } from "@/redux/store";
import axios from "axios";

const DuplicatePrograms = async (
  key,
  name,
  description,
  setLoader,
  setReload,
  daysMessages,
  daysTargets,
  isTemplate,
  coachID
) => {
  //   axios({
  //     method: "get",
  //     url: `${store.getState().auth.apiURL}CF/api/getProgramByID`,
  //     params: {
  //       programID: key,
  //     },
  //   })
  //     .then(async (res) => {
  //       let data = res.data.result;
  //       duplicateProgram(
  //         data,
  //         name,
  //         description,
  //         setLoader,
  //         setReload,
  //         daysMessages,
  //         daysTargets,
  //         isTemplate,
  //         coachID
  //       );
  //     })
  //     .catch((err) => {});
  // };

  duplicateProgram(
    key,
    name,
    description,
    setLoader,
    setReload,
    isTemplate,
    coachID
  );
};
const duplicateProgram = (
  key,
  name,
  description,
  setLoader,
  setReload,
  isTemplate,
  coachID
) => {
  axios({
    method: "post",
    url: `${store.getState().auth.apiURL}CF/api/duplicateProgram`,
    data: {
      programID: key,
      programName: name,
      description,
      isTemplate: isTemplate,
      coachID: coachID,
    },
  })
    .then(async (res) => {
      setReload((prev) => !prev);
    })
    .catch((err) => {})
    .finally(() => {
      setLoader(false);
    });
};

const transformProgram = (programData) => {
  let toProgramsCreate = {};

  programData?.workouts?.map((workout) => {
    let dayIdx = workout.dayIdx;
    toProgramsCreate[dayIdx] = [];

    workout?.daysWorkouts.map((dayWorkout) => {
      toProgramsCreate[dayIdx].push(dayWorkout.workout);
    });
  });
  return toProgramsCreate;
};

const programsTransformation = (
  data,
  name,
  description,
  daysMessages,
  daysTargets,
  coachID
) => {
  const programs = {
    trainerUUID: coachID || data.coachID,
    name: name,
    description: description,
    duration: data.duration,
    creationDate: new Date(),
    daysWorkouts: [],
    workouts: [],
  };

  var maxDay = 0;
  var firstNewWorkoutDay = -1;
  const lastDayIndex = data ? Object.entries(data).length : null;
  let transformed = transformProgram(data);
  Object.entries(transformed).map((workout) => {
    if (workout?.[0] && workout?.[0] != "" && workout?.[1].length > 0) {
      let workoutDay = parseInt(workout?.[0]);
      maxDay = Math.max(maxDay, workoutDay);
      if (lastDayIndex != null && workoutDay > lastDayIndex) {
        if (firstNewWorkoutDay == -1) {
          firstNewWorkoutDay = workoutDay;
        } else {
          firstNewWorkoutDay = Math.min(firstNewWorkoutDay, workoutDay);
        }
      }

      let Index = -1;
      let key = "";
      if (Index != -1) {
        key = programs.workouts[Index].id;
      } else {
        key = uuidv4();
        programs.workouts.push({
          id: key,
          dayIdx: workoutDay,
          daysMessages: daysMessages[workoutDay],
          daysTargets: daysTargets[workoutDay],
        });
      }

      workout[1].map((item, index) => {
        programs.daysWorkouts.push({
          programWithWorkoutsID: key,
          workoutID: item.id,
          orderIdx: index,
        });
      });
    }
  });

  // loop through all days less than the maximum day
  for (let day = 1; day <= maxDay; day++) {
    let index = programs.workouts.findIndex(
      (workout) => workout.dayIdx === day
    );
    if (index === -1) {
      let key = uuidv4();
      programs.workouts.push({
        id: key,
        dayIdx: day,
      });
    }
  }
  return programs;
};

function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(12)
  );
}

export default DuplicatePrograms;
