import { Dialog, DialogContent } from "@mui/material";
import React from "react";

const AddWeekDialog = ({
  isOpen,
  setIsOpen,
  AddWeek,
  setShowSmartBuilder,
  setUnsavedChanges,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      aria-labelledby="add-week-dialog-title"
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: {
          borderRadius: "20px",
        },
      }}
    >
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <button
          style={{
            width: "auto",
            padding: "0 10px",
          }}
          className="button-new"
          onClick={() => {
            setShowSmartBuilder(true);
            setUnsavedChanges(true);
            setIsOpen(false);
          }}
        >
          Add with smart builder (recommended).
        </button>
        <button
          style={{
            width: "auto",
            padding: "0 20px",
          }}
          className="button-new"
          onClick={() => {
            AddWeek();
            setIsOpen(false);
          }}
        >
          Add with blank template.
        </button>
      </DialogContent>
    </Dialog>
  );
};

export default AddWeekDialog;
