import { store } from "@/redux/store";
import axios from "axios";
import { chatClient } from "./chat";

export const getStreamclient = async (trainerDetails) => {
  const res = await axios({
    method: "GET",
    url: `${
      store.getState().auth.apiURL
    }CF/api/CoachWeb/Get/getAllAtheletesOneCoach`,
    params: {
      coachID: trainerDetails?.password,
    },
  });
  const arrayClients = res?.data?.result?.[0]?.atheletes || [];
  console.log("clients", arrayClients);

  const activeClients = arrayClients.filter((_) => {
    return _.active;
  });

  const newClientIds = activeClients.map((_) => {
    return _.id;
  });
  const userRes = await chatClient.queryChannels({
    members: { $in: [chatClient.userID] },
  });

  console.log(newClientIds, userRes, "clients");
  return newClientIds;
};
