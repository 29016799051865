import AddRoundedIcon from "@mui/icons-material/AddRounded";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import { Box, Divider, Stack } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
import { Input } from "@/components/ui/input";
import CustomMenu from "../CustomMenu";
import { onRoundsChange } from "../helpers";
import { EnduranceContext } from "./EnduranceBuilder";
import EnduranceExercise from "./EnduranceExercise";
import EnduranceRoundReorderView from "./EnduranceRoundReorderView";

const EnduranceRound = ({
  selected,
  onSelect,
  index,
  // roundIndex,
  round,
  setRounds,
  setExercise,
  onAddSubstep,
  // selectedExerciseInfo,
  setSelectedExerciseInfo,
  workoutKind,
}) => {
  const [canReorder, setCanReorder] = useState(false);
  const dispatch = useDispatch();
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [minutes, setMinutes] = useState(
    round?.runTime ? Math.floor(round?.runTime / 60) : null
  );
  const [seconds, setSeconds] = useState(
    round?.runTime ? round?.runTime % 60 : null
  );

  const [isAdding, setIsAdding] = useState(false);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [exerciseIndex, setExerciseIndex] = useState({});

  const handleDragOver = (event) => {
    setIsDraggingOver(true);
  };

  const handleDragLeave = (event) => {
    setIsDraggingOver(false);
  };

  // const addSubStep = (exerciseIndex) => {
  //   setRounds((prev) => {
  //     const newRounds = [...prev];
  //     let nextRound = JSON.parse(JSON.stringify(round));
  //     newRounds.splice(index + 1, 0, { round: nextRound });
  //     return newRounds;
  //   });
  // };

  const removeSubstep = (roundIndex, exerciseIndex) => {
    setRounds((prevRounds) => {
      const updatedRounds = [...prevRounds];
      updatedRounds[roundIndex].round.measurements.splice(exerciseIndex, 1);
      return updatedRounds;
    });
  };

  const duplicateRound = () => {
    // add the round just below the current round and shift the rest of the rounds down

    setRounds((prev) => {
      const newRounds = [...prev];
      let nextRound = JSON.parse(JSON.stringify(round));
      newRounds.splice(index + 1, 0, { round: nextRound });
      return newRounds;
    });
  };

  useEffect(() => {
    // if current round is Warm Up, then set numRounds to 1
    if (round?.type !== "Warm Up" && round?.type !== "Mixed Sets") {
      onRoundsChange(index, round, setRounds);
    }
  }, [round?.numRounds]);

  useEffect(() => {
    setMinutes(Math.floor(+round?.runTime / 60));
    setSeconds(+round?.runTime % 60);
  }, [round?.runTime]);

  const {
    setShowAddPropertiesModal,
    selectedExerciseInfo,
    incrementRound,
    decrementRound,
    reorderRound,
    setReorderRound,
    onSelectedExerciseChange,
    isDraggingSection,
  } = useContext(EnduranceContext);

  const menuItems = ["Run", "Rest", "Recover", "Cooldown", "Warm Up"];
  const [selectedMenuItem, setSelectedMenuItem] = useState("Run");

  const [enduranceExercise, setEnduranceExercise] = useState({
    movementName: "",
  });

  const updateMovementName = (roundIndex, exerciseIndex, movementName) => {
    setRounds((prevRounds) => {
      const updatedRounds = [...prevRounds];
      updatedRounds[roundIndex].round.measurements[
        exerciseIndex
      ].measurement.movementName = movementName;

      return updatedRounds;
    });
  };

  const addIntensityToMeasurement = (roundIndex, exerciseIndex, intensity) => {
    setRounds((prevRounds) => {
      const updatedRounds = [...prevRounds];
      updatedRounds[roundIndex].round.measurements[
        exerciseIndex
      ].measurement.intensity = intensity;
      return updatedRounds;
    });
  };

  const updateCurrentRound = (modifiedRound) => {
    setRounds((prev) => {
      const newRounds = [...prev];
      newRounds[index] = { round: modifiedRound };
      return newRounds;
    });
  };

  const updateRuntimeOfRound = (minutes, seconds) => {
    const newRound = { ...round };
    newRound.runTime = +minutes * 60 + +seconds;
    setRounds((prev) => {
      const newRounds = [...prev];
      newRounds[index] = { round: newRound };
      return newRounds;
    });
  };

  useEffect(() => {
    if (reorderRound === index) {
      setCanReorder(true);
    } else {
      setCanReorder(false);
    }
  }, [reorderRound]);

  useEffect(() => {
    setExercise((prevExercise) => ({
      ...prevExercise,
      movementName:
        selectedMenuItem === "Warm Up"
          ? "Warm Up"
          : selectedMenuItem === "Run"
          ? "Run"
          : selectedMenuItem === "Cooldown"
          ? "Cooldown"
          : "",
    }));
  }, [selectedMenuItem]);

  const isAMRAP = round?.type === "AMRAP";
  const isEMOM = round?.type === "EMOM";
  const isWarmUp = round?.type === "Warm Up";
  const isMixedSets = round?.type === "Mixed Sets";

  // const onTypeChange = (e) => {
  //   console.log("Type changed: ", e.target.value);
  //   let type = e.target.value;
  //   if (type === undefined) {
  //     setIsSelectOpen(true);
  //     return;
  //   } else {
  //     setIsSelectOpen(false);
  //   }
  // };

  const secondsInputRef = React.useRef(null);
  return (
    <>
      <Box
        sx={{
          minHeight: "100px",
          borderRadius: "12px",
          backgroundColor: "white",
        }}
      >
        <div
          style={{
            position: "relative",
            alignItems: "flex-center",
            height: 84,
            paddingTop: "22px",
            paddingBottom: "22px",
          }}
          className="section-header"
        >
          <div
            style={{ gap: "8px", alignItems: "flex-end" }}
            className="section-header-left"
          >
            {/* <Input
              type="text"
              placeholder="Step Title"
              className="max-w-[200px] font-DMSans font-normal focus-visible:ring-violet-500"
              onChange={(e) => {
                setRounds((prev) => {
                  const newRounds = [...prev];
                  newRounds[index].round.type = e.target.value;
                  return newRounds;
                });
              }}
            /> */}

            {(isAMRAP || isEMOM) && (
              <div>
                <label
                  style={{
                    color: "#828282",
                    fontFeatureSettings: "'clig' off, 'liga' off",
                    fontFamily: "DM Sans",
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "15px",
                    marginLeft: "4px",
                  }}
                >
                  Duration
                </label>

                <div
                  name="runTime"
                  style={{
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                    borderRadius: "8px",
                    border: "1px solid #CCC",
                    background: "#FFF",
                    width: "80px",
                    paddingLeft: "8px",
                    paddingRight: "8px",
                  }}
                  className="flex items-center justify-between"
                >
                  <input
                    type="number"
                    placeholder="MM"
                    style={{
                      width: "50%",
                      marginRight: 2,
                      outline: "none",
                      fontSize: 14,
                      fontFamily: "DM Sans",
                      fontWeight: 500,
                      fontStyle: "normal",
                      lineHeight: "21px",
                      color: "#242424",
                      textAlign: "center",
                    }}
                    value={minutes}
                    onChange={(e) => {
                      if (e.target.value.length > 2) {
                        return;
                      }
                      setMinutes(e.target.value);
                      updateRuntimeOfRound(e.target.value, seconds);
                      if (e.target.value.length === 2) {
                        secondsInputRef.current.focus();
                      }
                    }}
                  />
                  <p
                    style={{
                      marginBottom: "2px",
                    }}
                  >
                    :
                  </p>
                  <input
                    type="number"
                    placeholder="SS"
                    ref={secondsInputRef}
                    style={{
                      width: "50%",
                      marginLeft: 2,
                      outline: "none",
                      fontSize: 14,
                      fontFamily: "DM Sans",
                      fontWeight: 500,
                      fontStyle: "normal",
                      lineHeight: "21px",
                      color: "#242424",
                      textAlign: "center",
                    }}
                    value={seconds}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value.length > 2) return;
                      updateRuntimeOfRound(minutes, value);
                      if (value > 59) {
                        setSeconds(59);
                      } else {
                        setSeconds(value);
                      }
                    }}
                  />
                </div>
              </div>
            )}
          </div>
          <div
            style={{
              gap: "16px",
              alignItems: "flex-end",
            }}
            className="section-header-right"
          >
            {round?.measurements?.length > 1 && (
              <>
                <span
                  style={{
                    color: "#828282",
                    fontFeatureSettings: "'clig' off, 'liga' off",
                    fontFamily: "DM Sans",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "normal",
                    marginBottom: "10px",
                  }}
                >
                  Repeat :
                </span>{" "}
                {!isAMRAP && !isWarmUp && !isMixedSets && (
                  <div
                    name="numRounds"
                    style={{
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                      borderRadius: "8px",
                      border: "1px solid #CCC",
                      background: "#FFF",
                    }}
                    className="flex items-center"
                  >
                    <RemoveRoundedIcon
                      sx={{
                        height: "40px",
                        width: "40px",
                        padding: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (round?.numRounds > 1) {
                          decrementRound(index);
                        }
                      }}
                    />
                    <p
                      style={{
                        color: "#424242",
                        fontFeatureSettings: "'clig' off, 'liga' off",
                        fontFamily: "DM Sans",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "normal",
                      }}
                    >
                      {round?.numRounds}{" "}
                    </p>{" "}
                    <AddRoundedIcon
                      sx={{
                        height: "40px",
                        width: "40px",
                        padding: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        incrementRound(index);
                      }}
                    />
                  </div>
                )}
              </>
            )}
            <CustomMenu
              options={
                // if number of exercises is 1, then don't show delete option
                round?.measurements?.length <= 1
                  ? ["Delete", "Duplicate"]
                  : ["Delete", "Duplicate", "Reorder Exercises"]
              }
              onOptionClick={(option) => {
                if (option === "Delete") {
                  setRounds((prev) => {
                    const newRounds = [...prev];
                    const prevRound = newRounds[index - 1];

                    if (prevRound?.round?.restTime) {
                      newRounds[index - 1].round.restTime = 0;
                    }

                    newRounds.splice(index, 1);
                    return newRounds;
                  });
                } else if (option === "Reorder Exercises") {
                  if (canReorder) {
                    setReorderRound(-1);
                  } else {
                    setReorderRound(index);
                  }
                  setCanReorder(!canReorder);
                } else if (option === "Duplicate") {
                  duplicateRound();
                }
              }}
            />
          </div>
          {isEMOM && (
            <p
              style={{
                color: "#828282",
                fontFeatureSettings: "'clig' off, 'liga' off",
                fontFamily: "DM Sans",
                fontSize: "10px",
                fontStyle: "normal",
                fontWeight: 400,

                position: "absolute",
                left: "28px",
                bottom: 0,
              }}
            >
              60 sec/section.
            </p>
          )}
        </div>

        <Droppable
          key={index}
          droppableId={`section-${index}-${round?.type}`}
          index={index}
          isDropDisabled={
            isDraggingSection //|| (reorderRound !== -1 && reorderRound !== index)
          }
        >
          {(provided, snapshot) => {
            return (
              <div
                style={{
                  position: "relative",
                  width: "calc(7/12 * 100vw)",
                  marginRight: "50px",
                }}
                {...provided.droppableProps}
                ref={provided.innerRef}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
              >
                {/* <Typography>Rounds: {round?.numRounds}</Typography> */}
                {canReorder ? (
                  <EnduranceRoundReorderView
                    round={round}
                    index={index}
                    setRounds={setRounds}
                    setCanReorder={setCanReorder}
                    canReorder={canReorder}
                    selectedExerciseInfo={selectedExerciseInfo}
                    setSelectedExerciseInfo={setSelectedExerciseInfo}
                    setShowAddPropertiesModal={setShowAddPropertiesModal}
                    key={index}
                    updateCurrentRound={updateCurrentRound}
                  />
                ) : (
                  <Stack
                    sx={{
                      padding: "24px 16px",
                    }}
                    gap={"16px"}
                  >
                    <div
                      style={{
                        cursor: "pointer",
                        overflow: "visible",
                      }}
                      className={
                        selected ? "exercise-items-selected" : "exercise-items"
                      }
                      // onClick={() => onSelect()}
                    >
                      {/* <Select
                        value={selectedMenuItem}
                        onChange={(e) => {
                          setSelectedMenuItem(e.target.value);
                          onTypeChange(e);
                        }}
                        sx={{
                          height: "40px",
                          borderRadius: "8px",
                          border: "1px solid #CCC",
                          background: "#FFF",
                          paddingLeft: "8px",
                          paddingRight: "8px",
                          width: "calc(5/12 * 100vw)",
                          background: "#008080",
                        }}
                      >
                        {menuItems.map((item) => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select> */}

                      {round?.measurements?.length > 0 ? (
                        round?.measurements?.map((m, exerciseIndex) => {
                          let measurement = m.measurement;
                          if (measurement?.movementName === "BREAK")
                            return null;
                          const onSelect = (event) => {
                            // event.stopPropagation();
                            setShowAddPropertiesModal(false);
                            onSelectedExerciseChange();

                            // let timeoutId = setTimeout(() => {
                            setSelectedExerciseInfo({
                              exercise: measurement,
                              exerciseIndex: exerciseIndex,
                              roundIndex: index,
                              exerciseId:
                                measurement?.movementID || measurement?.id,
                            });
                            setShowAddPropertiesModal(true);

                            //   clearTimeout(timeoutId);
                            // }, 100);
                          };
                          return (
                            <>
                              <Draggable
                                key={exerciseIndex}
                                draggableId={`section-${index} exercise-${exerciseIndex}`}
                                index={exerciseIndex}
                                isDragDisabled={!canReorder}
                              >
                                {(provided) => (
                                  <Stack
                                    gap={"16px"}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <EnduranceExercise
                                      key={`section-${index}-exercise-${exerciseIndex}-${measurement?.movementName}`}
                                      selected={
                                        selectedExerciseInfo?.exerciseIndex ===
                                          exerciseIndex &&
                                        selectedExerciseInfo?.roundIndex ===
                                          index
                                      }
                                      onSelect={onSelect}
                                      measurement={measurement}
                                      workoutKind={workoutKind}
                                      selectedExerciseInfo={
                                        selectedExerciseInfo
                                      }
                                      setSelectedExerciseInfo={
                                        setSelectedExerciseInfo
                                      }
                                      isDragEnabled={canReorder}
                                      roundIndex={index}
                                      exerciseIndex={exerciseIndex}
                                      setExerciseIndex={setExerciseIndex}
                                      exerciseId={
                                        measurement?.movementID ||
                                        measurement?.id
                                      }
                                      removeSubstep={removeSubstep}
                                      updateMovementName={updateMovementName}
                                      addIntensityToMeasurement={
                                        addIntensityToMeasurement
                                      }
                                    />

                                    {/* {() => removeSubstep(substepIndex)} */}
                                    {measurement?.break ? (
                                      <ShowRest
                                        measurement={measurement}
                                        incrementOrDecrementBreakBy30Seconds={
                                          incrementOrDecrementBreakBy30Seconds
                                        }
                                        index={exerciseIndex}
                                        addBreakTime={addBreakTime}
                                      />
                                    ) : (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          position: "relative",
                                          height: "32px",
                                          width: "100%",
                                        }}
                                      >
                                        <button
                                          style={{
                                            borderRadius: "20px",
                                            height: "32px",
                                            cursor: "pointer",
                                            zIndex: 1,
                                            backgroundColor: "white",
                                          }}
                                          onClick={() => onAddSubstep(index)}
                                          className="button-new-outlined-rounded"
                                        >
                                          <p className="rounded-btn-text">
                                            Add Substep
                                          </p>
                                        </button>
                                        <Divider
                                          absolute
                                          sx={{
                                            top: "50%",
                                            left: "50%",
                                            height: "1px",
                                            transform: "translate(-50%, -50%)",
                                            zIndex: 0,
                                          }}
                                        />
                                      </Box>
                                    )}
                                  </Stack>
                                )}
                              </Draggable>
                            </>
                          );
                        })
                      ) : (
                        //next card
                        <div
                          style={{
                            width: "100%",
                            height: index == 0 ? "100%" : "120px",
                          }}
                          className={
                            selected
                              ? "exercise-items-selected"
                              : "exercise-items"
                          }
                          onClick={onSelect}
                        />
                      )}
                    </div>
                  </Stack>
                )}

                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </Box>
    </>
  );
};

// export const ShowRest = ({
//   measurement,
//   index,
//   incrementOrDecrementBreakBy30Seconds,
//   addBreakTime,
// }) => {
//   const [minutes, setMinutes] = useState(
//     Math.floor(measurement?.break?.duration / 60)
//   );
//   const [seconds, setSeconds] = useState(measurement?.break?.duration % 60);
//   const [showRestInput, setShowRestInput] = useState(false);

//   return (
//     <Box
//       sx={{
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         position: "relative",
//         height: "32px",
//         width: "100%",
//       }}
//       onDoubleClick={() => {
//         setMinutes(Math.floor(measurement?.break?.duration / 60));
//         setSeconds(measurement?.break?.duration % 60);
//         setShowRestInput(true);
//       }}
//     >
//       <button
//         style={{
//           borderRadius: "20px",
//           width: "130px",
//           zIndex: 1,
//           backgroundColor: "white",
//           height: "32px",
//           gap: 8,
//           position: "relative",
//         }}
//         className="button-new-outlined-rounded"
//       >
//         {showRestInput ? (
//           <div
//             style={{
//               display: "flex",
//               alignItems: "center",
//               gap: 8,
//             }}
//           >
//             <div
//               style={{
//                 display: "flex",
//                 alignItems: "center",
//               }}
//             >
//               <input
//                 type="number"
//                 placeholder="MM"
//                 style={{
//                   width: "30px",
//                   marginRight: 2,
//                   outline: "none",
//                   fontSize: 14,
//                   fontFamily: "DM Sans",
//                   fontWeight: 500,
//                   fontStyle: "normal",
//                   lineHeight: "21px",
//                   color: "#242424",
//                   textAlign: "center",
//                 }}
//                 value={minutes}
//                 onChange={(e) => {
//                   if (e.target.value.length > 2) {
//                     return;
//                   }
//                   setMinutes(e.target.value);
//                 }}
//               />

//               <p
//                 style={{
//                   marginBottom: "2px",
//                 }}
//               >
//                 :
//               </p>
//               <input
//                 type="number"
//                 placeholder="SS"
//                 style={{
//                   width: "30px",
//                   marginLeft: 2,
//                   outline: "none",
//                   fontSize: 14,
//                   fontFamily: "DM Sans",
//                   fontWeight: 500,
//                   fontStyle: "normal",
//                   lineHeight: "21px",
//                   color: "#242424",
//                   textAlign: "center",
//                 }}
//                 value={seconds}
//                 onChange={(e) => {
//                   const value = e.target.value;
//                   if (value.length > 2) return;
//                   if (value > 59) {
//                     setSeconds(59);
//                   } else {
//                     setSeconds(value);
//                   }
//                 }}
//               />
//             </div>
//             <CheckCircleOutlineRoundedIcon
//               sx={{ cursor: "pointer" }}
//               onClick={() => {
//                 addBreakTime(index, minutes, seconds);
//                 setShowRestInput(false);
//               }}
//             />
//           </div>
//         ) : (
//           <>
//             <img
//               src={RemoveIcon}
//               alt="remove icon"
//               style={{ width: "24px", height: "24px", cursor: "pointer" }}
//               onClick={() => {
//                 incrementOrDecrementBreakBy30Seconds(index, false);
//               }}
//             />
//             <p className="rounded-btn-text">
//               {formatSecondsToMMSS(measurement?.break?.duration)}
//             </p>
//             <img
//               src={AddIcon}
//               alt="add icon"
//               style={{ width: "24px", height: "24px", cursor: "pointer" }}
//               onClick={() => {
//                 incrementOrDecrementBreakBy30Seconds(index, true);
//               }}
//             />
//           </>
//         )}
//       </button>
//       <Divider
//         absolute
//         sx={{
//           top: "50%",
//           left: "50%",
//           height: "1px",
//           transform: "translate(-50%, -50%)",
//           zIndex: 0,
//         }}
//       />
//     </Box>
//   );
// };

export default EnduranceRound;
