import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import APIURL from "../APIDetails";
import { store } from "./store";

export const fetchExercises = createAsyncThunk(
  "exerciseList/fetchExercises",
  async () => {
    const exerciseListRoute = `${APIURL}CF/api/CoachWeb/Get/getGlobalMovementLibrary?coachID=${
      store.getState().auth.trainerUUID
    }`;

    const response = await axios({
      method: "get",
      url: exerciseListRoute,
    });

    return response?.data?.result?.filter(
      (obj) =>
        obj.videoURL && obj.videoURL.trim() !== "" && isValidUrl(obj.videoURL)
    );
  }
);
const isValidUrl = (urlString) => {
  var urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // validate protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // validate fragment locator
  return !!urlPattern.test(urlString);
};
export const fetchTrainerExercises = createAsyncThunk(
  "exerciseList/fetchTrainerExercises",
  async () => {
    const coachID = store.getState().auth.trainerUUID;
    const response = await axios({
      method: "get",
      url: `${APIURL}CF/api/CoachWeb/Get/getAllMovements`,
      params: {
        coachID: coachID,
      },
    });
    console.log({ response: response.data.result });
    return response.data.result;
  }
);

const exerciseListSlice = createSlice({
  name: "exerciseList",
  initialState: {
    data: [],
    trainerData: [],
    status: "idle",
    trainerStatus: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchExercises.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchExercises.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchExercises.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchTrainerExercises.pending, (state) => {
        state.trainerStatus = "loading";
      })
      .addCase(fetchTrainerExercises.fulfilled, (state, action) => {
        state.trainerStatus = "succeeded";
        state.trainerData = action.payload;
      })
      .addCase(fetchTrainerExercises.rejected, (state, action) => {
        state.trainerStatus = "failed";
        state.error = action.error.message;
      });
  },
});

export default exerciseListSlice.reducer;
