import { getAllChannelWithProgramID } from "@/ApiServices/ChannelProgramTracker/Api";
import { getAllWorkouts } from "@/ApiServices/Workouts/Api";
import { createContext, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import {
  connectItemToFolder,
  createFolder,
  deleteFolder,
  deleteLastWeekOfAssignedProgram,
  deleteProgram,
  disconnectItemFromFolder,
  duplicatePhasedProgram,
  duplicateProgram,
  getAllFolder,
  getAllProgram,
  getFoldersByAdminID,
  getPhasedProgramSubscribers,
  getProgramByAdminID,
  getProgramByID,
  getProgramSubscribers,
  importPhaseToContainer,
  savePhaseContainerAndConnectToFolder,
  saveProgramData,
  saveProgramDataAndConnectToFolder,
  shiftingFromFolder,
  updateFolderDetails,
  updateProgramInfo,
  updateProgramPhaseContainer,
} from "../../ApiServices/Programs/Api";

import { CustomAlertContext } from "@/App";
import { SpurfitCircularProgress } from "@/components/Dashboard/dashboard";
import { usePrefetch } from "@/services/apiSlice";
import axios from "axios";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import ConfirmDialog from "./ConfirmDialog";
import {
  copyDaysMesssages,
  copyDaysTargets,
  getModifiedWorkoutForAssignedWeek,
  handlePasteSingleWorkout,
  handleReplaceSingleWorkout,
  modifyWorkoutOnPaste,
  transformForUpdates,
} from "./helper";

import {
  updateFolders,
  updateProgramFoldersState,
  updateTemplateFoldersState,
  updateTemplatesList,
  updateProgramsList,
} from "./utils/phaseContainerUtils";

export const ProgramsContext = createContext();

export const ProgramsProvider = ({ children }) => {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const coachID = useSelector((state) => state.auth?.trainerUUID);
  const adminID = useSelector((state) => state.auth?.data?.adminID);
  const isAdmin = useSelector((state) => state.auth?.data?.isAdmin);
  const subbordinateCoachIDs = useSelector(
    (state) => state.auth.subbordinateCoachIDs
  );
  const gymAffiliation = useSelector(
    (state) => state.auth?.data?.gymAffliation
  );

  const isLivezy = gymAffiliation === "livezy";

  const [programs, setPrograms] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [folders, setFolders] = useState([]);
  const [templateFolders, setTemplateFolders] = useState([]);
  const [folderSelected, setFolderSelected] = useState({});
  const [isShifting, setIsShifting] = useState("");
  const [itemToBeShift, setItemToBeShift] = useState(null);
  const [loadingPrograms, setLoadingPrograms] = useState(false);
  const [isProgramsListOpen, setIsProgramsListOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState({});
  const [selectedProgramWithPhases, setSelectedProgramWithPhases] =
    useState(null);
  const [dialogToSelectFolder, setDialogToSelectFolder] = useState(false);
  const [originalState, setOriginalState] = useState({});
  const [subbordinateCoachPrograms, setSubborinateCoachPrograms] = useState([]);
  const [subbordinateCoachFolders, setSubbordinateCoachFolders] = useState([]);

  const [selectedProgramData, setSelectedProgramData] = useState({});
  const [programSubscribers, setProgramSubscribers] = useState([{}]);
  const [programChannels, setProgramChannels] = useState([{}]);
  const [loadingSelectedProgram, setLoadingSelectedProgram] = useState(false);

  const [daysMessages, setDaysMessages] = useState({});
  const [daysTargets, setDaysTargets] = useState({});
  const [addedWorkouts, setAddedWorkouts] = useState({});
  const [totalWorkouts, setTotalWorkouts] = useState(0);

  const [daysArray, setDaysArray] = useState([]);
  const [weeksArray, setWeeksArray] = useState([]);
  const [isTemplate, setIsTemplate] = useState(false);

  const [weeks, setWeeks] = useState(0);
  const [originalWeeks, setOriginalWeeks] = useState(-1);
  const [originalDays, setOriginalDays] = useState(0);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [workoutList, setWorkoutList] = useState([{}]);
  const [selectedCard, setSelectedCard] = useState("");

  const [selectedTab, setSelectedTab] = useState("programs");

  const [copiedDay, setCopiedDay] = useState({ data: {} });
  const [copiedWeekNo, setCopiedWeekNo] = useState(null);

  const [duplicateAsTemplate, setDuplicateAsTemplate] = useState(false);

  const [showAddWorkoutModal, setShowAddWorkoutModal] = useState(false);
  const [showAutoMessageDialog, setShowAutoMessageDialog] = useState(false);
  const [showAddActivityModal, setShowAddActivityModal] = useState(false);
  const [addMenuOpen, setAddMenuOpen] = useState(false);
  const [showAddCardio, setShowAddCardio] = useState(false);
  const [showWorkout, setShowWorkout] = useState(false);
  const [selectedToShow, setSelectedToShow] = useState({});

  const [copiedWorkout, setCopiedWorkout] = useState({});
  const [isNewPhaseLoading, setIsNewPhaseLoading] = useState(false);
  const [unsavedProgram, setUnsavedProgram] = useState(null);
  const [subbordinateCoachesNames, setSubbordinateCoachesNames] = useState([]);
  const [
    subbordinateCoachesToBeDisplayed,
    setSubbordinateCoachesToBeDisplayed,
  ] = useState([{}]);
  const [selectedFilter, setSelectedFilter] = useState("");

  const [openPhaseId, setOpenPhaseId] = useState(null);
  const [openFolderId, setOpenFolderId] = useState(null);

  const [showDuplicateProgramModal, setShowDuplicateProgramModal] =
    useState(false);
  const [duplicateData, setDuplicateData] = useState(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [tempData, setTempData] = useState({});

  const { displayAlert } = useContext(CustomAlertContext);

  const programsStates = useSelector((state) => state.programs.programsStates);
  const [pastedData, setPastedData] = useState({});

  const { programId } = useParams();

  const location = useLocation();

  let preftechGlobalExercise = usePrefetch("getSpurfitExercises");

  // functions
  const restorePreviousProgram = async () => {
    let previouslySelectedProgram = programsStates.selectedProgram;
    setSelectedProgram(previouslySelectedProgram);
    await getProgram(previouslySelectedProgram?.id);
    getSubscribers(previouslySelectedProgram?.id);
    getProgramAssignedChannels(previouslySelectedProgram?.id);

    // set other data
    setSelectedCard(programsStates?.workoutDay);
    setSelectedProgramData(programsStates?.selectedProgramData);
    setAddedWorkouts(programsStates?.addedWorkouts);
    setDaysMessages(programsStates?.daysMessages);
    setDaysTargets(programsStates?.daysTargets);
    setWeeks(programsStates?.weeks);

    setDaysArray(programsStates?.daysArray);
    setWeeksArray(programsStates?.weeksArray);
    setShowAddWorkoutModal(true);
  };

  const checkForUnsavedPrograms = () => {
    try {
      let data = JSON.parse(localStorage.getItem("unsavedPrograms"));
      if (data) {
        setUnsavedProgram(data);
      }
    } catch (e) {}
  };

  const getPrograms = async (signal) => {
    setLoadingPrograms(true);
    checkForUnsavedPrograms();
    try {
      let { folders, templateFolders } = await getAllTheFolders();

      let res = await getAllProgram(coachID, adminID, signal);
      let originalStates = {};
      if (res) {
        // sort res by creation date
        res.sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate));
        const templatePrograms = res.filter(
          (program) => program?.isTemplate && program?.programFolderId === null
        );
        console.log("this is template res", templatePrograms);

        const programPrograms = res.filter((program) => {
          // Exclude templates
          if (program?.isTemplate) return false;

          // If isLivezy is true, filter by coachID
          if (isLivezy && program?.coachID !== coachID) return false;

          // Include programs with a programFolderId of null
          return program?.programFolderId === null;
        });

        if (
          location?.state?.workoutCreatedForThisProgram &&
          programsStates?.selectedProgram &&
          programsStates?.finished
        ) {
          restorePreviousProgram();
        } else if (programId) {
          const program = res.find((program) => program.id === programId);
          setSelectedProgram(program);
          if (program?.programs) {
            setSelectedProgramWithPhases(program);
            setOpenPhaseId(program?.id);
          } else {
            getProgram(program?.id); //this will get the particular programe
          }
          getSubscribers(program?.id);
          getProgramAssignedChannels(program?.id);
        } else {
          if (folders?.length <= 0) {
            if (programPrograms?.[0]) {
              setSelectedProgram(programPrograms[0]);

              getSubscribers(programPrograms[0]?.id);
              getProgramAssignedChannels(programPrograms[0]?.id);
              if (programPrograms[0]?.programs) {
                setSelectedProgramWithPhases(programPrograms[0]);
                setOpenPhaseId(programPrograms[0]?.id);
              } else {
                getProgram(programPrograms[0]?.id);
              }
            }
          }
        }
        setPrograms(programPrograms);
        setTemplates(templatePrograms);
        originalStates = {
          programs: programPrograms,
          templates: templatePrograms,
          folders,
          templateFolders,
        };
      }

      if (isAdmin || adminID) {
        let id = isAdmin ? coachID : adminID;

        let subbordinateCoachPrograms = await getProgramByAdminID(id);
        if (subbordinateCoachPrograms) {
          subbordinateCoachPrograms = subbordinateCoachPrograms.filter(
            (item) => {
              item.coachID != "TNLJlvvsIowSgSpEsI";
            }
          );
        }
        setSubborinateCoachPrograms(subbordinateCoachPrograms);

        originalStates.subbordinateCoachPrograms = subbordinateCoachPrograms;
        const uniqueNamesSet = new Set();

        subbordinateCoachPrograms.forEach((obj) => {
          if (obj.hasOwnProperty("coach") && obj.coach.hasOwnProperty("name")) {
            uniqueNamesSet.add(obj.coach.name);
          }
        });

        let subbordinateCoachesFolders = await getFoldersByAdminID(id);
        if (subbordinateCoachesFolders) {
          subbordinateCoachesFolders = subbordinateCoachesFolders.filter(
            (item) => item.coachID !== "TNLJlvvsIowSgSpEsI"
          );

          originalStates.subbordinateCoachFolders = subbordinateCoachesFolders;
        }
        setSubbordinateCoachFolders(subbordinateCoachesFolders);

        subbordinateCoachesFolders.forEach((obj) => {
          if (obj.hasOwnProperty("coach") && obj.coach.hasOwnProperty("name")) {
            uniqueNamesSet.add(obj.coach.name);
          }
        });

        const uniqueNamesArray = Array.from(uniqueNamesSet);
        setSubbordinateCoachesNames(uniqueNamesArray);
      }

      setOriginalState(originalStates);
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Fetch cancelled");
      } else {
        // handle other errors
      }
    } finally {
      setLoadingPrograms(false);
    }
  };

  const getSubscribers = async (programID, isPhasedProgram) => {
    let res;
    if (isPhasedProgram) {
      res = await getPhasedProgramSubscribers(programID);
    } else {
      res = await getProgramSubscribers(programID);
    }
    setProgramSubscribers(res);
  };

  const getProgramAssignedChannels = async (id) => {
    if (id) {
      let res = await getAllChannelWithProgramID(id);
      if (res) {
        setProgramChannels(res);
      } else {
        setProgramChannels([]);
      }
    } else {
      setProgramChannels([]);
    }
  };

  const handleFilterSubbordinateCoachPrograms = (name) => {
    let data = originalState?.subbordinateCoachPrograms;
    if (name != "clear") {
      data = data.filter((item) => item?.coach?.name === name);
    }
    setSubborinateCoachPrograms(data);
  };

  const handleFilter = (name) => {
    if (selectedTab === "others") {
      handleFilterSubbordinateCoachPrograms(name);
      return;
    }
    let data = originalState?.programs;
    if (selectedTab === "templates") {
      data = originalState?.templates;
    }
    if (name != "clear") {
      let isAdminProgramOrTemplate = name.includes("Admin");
      data = data.filter((item) => {
        if (isAdminProgramOrTemplate) {
          return item?.coachID === adminID;
        }
        return item?.coachID === coachID;
      });
    }
    if (selectedTab === "programs") {
      setPrograms(data);
    } else {
      setTemplates(data);
    }
  };

  const onSearchCancel = () => {
    setPrograms(originalState?.programs);
    setTemplates(originalState.templates);
    setSubborinateCoachPrograms(originalState.subbordinateCoachPrograms);
    setFolders(originalState.folders);
    setTemplateFolders(originalState.templateFolders);
    setSubborinateCoachPrograms(originalState.subbordinateCoachPrograms);
    setSubbordinateCoachFolders(originalState.subbordinateCoachFolders);
  };

  const onSearch = (searchQuery) => {
    if (!searchQuery || searchQuery == "") {
      onSearchCancel();
      return;
    }

    if (selectedTab === "others") {
      const filteredSubbordinateCoachPrograms =
        originalState.subbordinateCoachPrograms?.filter((program) =>
          program?.name?.toLowerCase().includes(searchQuery.toLowerCase())
        ) || [];
      setSubborinateCoachPrograms(filteredSubbordinateCoachPrograms);

      const filteredSubbordinateCoachFolders =
        originalState.subbordinateCoachFolders?.filter((folder) =>
          folder?.name?.toLowerCase().includes(searchQuery.toLowerCase())
        );
      setSubbordinateCoachFolders(filteredSubbordinateCoachFolders);
      return;
    }

    // Filter the programs based on the search query
    const filteredPrograms =
      originalState.programs?.filter((program) =>
        program?.name?.toLowerCase().includes(searchQuery.toLowerCase())
      ) || [];

    const filteredTemplates =
      originalState.templates?.filter((template) =>
        template?.name?.toLowerCase().includes(searchQuery.toLowerCase())
      ) || [];

    let filteredSubbordinateCoachPrograms = [];
    if (isAdmin && subbordinateCoachIDs?.length > 0) {
      filteredSubbordinateCoachPrograms =
        originalState.subbordinateCoachPrograms?.filter((program) =>
          program?.name?.toLowerCase().includes(searchQuery.toLowerCase())
        ) || [];
    }

    // now filter folder as well as the programs inside the folders on the bases of search query
    let filteredFoldersWithPrograms = originalState.folders
      ?.map((folder) => {
        if (folder?.name?.toLowerCase().includes(searchQuery.toLowerCase())) {
          return folder;
        }
        let copyFolder = { ...folder };
        if (copyFolder?.programs?.length > 0) {
          const filteredPrograms =
            copyFolder.programs?.filter((program) =>
              program?.name?.toLowerCase().includes(searchQuery.toLowerCase())
            ) || [];
          copyFolder.programs = filteredPrograms;
        } else if (copyFolder?.containers?.length > 0) {
          const filteredContainers =
            copyFolder.containers?.filter((program) =>
              program?.name?.toLowerCase().includes(searchQuery.toLowerCase())
            ) || [];
          copyFolder.containers = filteredContainers;
        }

        if (
          copyFolder?.programs?.length > 0 ||
          copyFolder?.containers?.length > 0
        ) {
          return copyFolder;
        }
        return null;
      })
      .filter(Boolean);

    let filteredTemplateFoldersWithPrograms = originalState.templateFolders
      ?.map((folder) => {
        if (folder?.name?.toLowerCase().includes(searchQuery.toLowerCase())) {
          return folder;
        }
        let copyFolder = { ...folder };
        if (copyFolder?.programs?.length > 0) {
          const filteredPrograms =
            copyFolder.programs?.filter((program) =>
              program?.name?.toLowerCase().includes(searchQuery.toLowerCase())
            ) || [];
          copyFolder.programs = filteredPrograms;
        } else if (copyFolder?.containers?.length > 0) {
          const filteredContainers =
            copyFolder.containers?.filter((program) =>
              program?.name?.toLowerCase().includes(searchQuery.toLowerCase())
            ) || [];
          copyFolder.containers = filteredContainers;
        }

        if (
          copyFolder?.programs?.length > 0 ||
          copyFolder?.containers?.length > 0
        ) {
          return copyFolder;
        }
        return null;
      })
      .filter(Boolean);

    // Update the state with the filtered programs
    setPrograms(filteredPrograms);
    setTemplates(filteredTemplates);
    setSubborinateCoachPrograms(filteredSubbordinateCoachPrograms);

    setFolders(filteredFoldersWithPrograms);
    setTemplateFolders(filteredTemplateFoldersWithPrograms);
  };

  const getProgram = async (programID) => {
    console.log("selected program", selectedProgram);
    setLoadingSelectedProgram(true);

    let res = await getProgramByID(programID);
    setSelectedProgramData(res);

    setLoadingSelectedProgram(false);
  };

  const onProgramSelect = async (program) => {
    setCopiedDay({ data: {} });
    setCopiedWeekNo(null);
    console.log("selected progam is what", program, selectedProgram);
    if (selectedProgram?.id === program?.id) return;

    if (unsavedChanges) {
      setShowConfirmDialog(true);
      setTempData(program);
      return;
    }

    setSelectedProgram(program);
    if ((selectedProgram?.programs && program?.isNew) || program?.programs) {
      setSelectedProgramWithPhases(program);
      setOpenPhaseId(program?.id);
      setSelectedProgram(program);
      setSelectedProgramData(program);
      setProgramSubscribers([]);
      setProgramChannels([]);
    } else if (program?.isNew) {
      setSelectedProgram(program);
      setSelectedProgramData(program);
    } else {
      if (
        selectedProgramWithPhases?.id &&
        selectedProgramWithPhases?.id !== program?.cFProgramPhasesContainerId
      ) {
        setSelectedProgramWithPhases(null);
        setOpenPhaseId(null);
      }
      await getProgram(program?.id);
      await getProgramAssignedChannels(program?.id);
    }
    let isPhasedProgram = program?.programs?.length > 0;
    await getSubscribers(program?.id, isPhasedProgram);

    setDuplicateAsTemplate(false);
    setUnsavedChanges(false);
    setLoadingSelectedProgram(false);
  };

  const onFolderSelected = (folder) => {
    setFolderSelected(folder);
  };

  const getWorkouts = async () => {
    let workouts = await getAllWorkouts(coachID, adminID);
    setWorkoutList(workouts);
  };

  const saveProgramDetails = async () => {
    console.log("Save program details");
  };

  const saveProgramOnEditWorkout = async (programID, data) => {
    // same as saveProgramDetails
    saveProgram();
  };

  const deleteProgramByID = async (programID) => {
    if (programSubscribers?.length > 0) {
      displayAlert({
        message: "Program has subscribers, cannot delete!",
        type: "error",
      });
      return;
    }
    setIsLoading(true);
    let res = await deleteProgram(programID);
    if (res) {
      displayAlert({
        message: "Program deleted successfully",
        type: "success",
      });

      console.log(
        "what is selcted program",
        selectedProgram,
        selectedProgramData,
        selectedProgramWithPhases,
        programs
      );
      if (selectedProgram?.id === programID) {
        if (selectedProgram?.isTemplate) {
          let updatedTemplates = templates.filter(
            (template) => template.id !== programID
          );

          // this check that if this program is from the folder and updating the folder state
          if (selectedProgram?.programFolderId) {
            setFolderSelected((prev) => {
              let prevData = { ...prev };
              if (folderSelected?.programs) {
                let newPrograms = prevData?.programs.filter(
                  (program) => program.id !== selectedProgram?.id
                );
                prevData.programs = newPrograms;
              }
              setTemplateFolders((prevs) => {
                let data = [...prevs];
                let newFolderData = data?.map((folder) => {
                  if (folder.id === prevData.id) {
                    return prevData;
                  } else {
                    return folder;
                  }
                });

                setOriginalState((preState) => {
                  return {
                    ...preState,
                    templateFolders: [...newFolderData],
                  };
                });

                return newFolderData;
              });
              return prevData;
            });
            setSelectedProgram({});
            setSelectedProgramData({});
            setProgramSubscribers([]);
            setProgramChannels([]);
            setIsLoading(false);
            return;
          }

          setTemplates(updatedTemplates);

          if (updatedTemplates?.length > 0) {
            setSelectedProgram(updatedTemplates[0]);
            getProgram(updatedTemplates[0]?.id);
            getSubscribers(updatedTemplates[0]?.id);
            getProgramAssignedChannels(updatedTemplates[0]?.id);
          } else {
            setSelectedProgram({});
            setSelectedProgramData({});
            setProgramSubscribers([]);
            setProgramChannels([]);
          }

          // update in original state
          let updatedOriginalTemplates = originalState.templates.filter(
            (template) => template.id !== programID
          );
          setOriginalState({
            ...originalState,
            templates: updatedOriginalTemplates,
          });
          setIsLoading(false);
          return;
        }
        // If program is belongs to the phase container
        if (selectedProgram?.cFProgramPhasesContainerId) {
          // this is for when program is inside the phaseContainer and that phaseContainer is inside the folder
          if (selectedProgramWithPhases?.programFolderId) {
            const updatedPrograms = selectedProgramWithPhases.programs.filter(
              (item) => item.id !== programID
            );
            if (selectedProgramWithPhases?.isTemplate) {
              setFolderSelected((prev) => {
                let prevData = { ...prev };
                if (folderSelected?.containers?.length > 0) {
                  let newPrograms = prevData?.containers.map((program) => {
                    if (program.id === selectedProgramWithPhases?.id) {
                      if (program?.programs) {
                        program.programs = updatedPrograms;
                      }
                      return program;
                    }
                    return program;
                  });
                  prevData.containers = newPrograms;
                }
                setTemplateFolders((prevs) => {
                  let data = [...prevs];
                  let newFolderData = data?.map((folder) => {
                    if (folder.id === prevData.id) {
                      return prevData;
                    } else {
                      return folder;
                    }
                  });

                  setOriginalState((preState) => {
                    return {
                      ...preState,
                      templateFolders: [...newFolderData],
                    };
                  });

                  return newFolderData;
                });
                return prevData;
              });
              setIsLoading(false);
            } else {
              setFolderSelected((prev) => {
                let prevData = { ...prev };
                if (folderSelected?.containers?.length > 0) {
                  let newPrograms = prevData?.containers.map((program) => {
                    if (program.id === selectedProgramWithPhases?.id) {
                      if (program?.programs) {
                        program.programs = updatedPrograms;
                      }
                      return program;
                    }
                    return program;
                  });
                  prevData.containers = newPrograms;
                }
                setFolders((prevs) => {
                  let data = [...prevs];
                  let newFolderData = data?.map((folder) => {
                    if (folder.id === prevData.id) {
                      return prevData;
                    } else {
                      return folder;
                    }
                  });

                  setOriginalState((preState) => {
                    return {
                      ...preState,
                      templateFolders: [...newFolderData],
                    };
                  });

                  return newFolderData;
                });
                return prevData;
              });
            }
            const tempSelectedProgramPhase = selectedProgramWithPhases;
            if (tempSelectedProgramPhase?.programs) {
              tempSelectedProgramPhase.programs = updatedPrograms;
            }

            setSelectedProgram(tempSelectedProgramPhase);
            setSelectedProgramData(tempSelectedProgramPhase);
            setIsLoading(false);
            return;
          }

          //If the phase container is to which program belongs is from the templates tab
          if (selectedTab === "templates") {
            const tempSelectedProgramWithPhase = templates.find(
              (item) => item?.id === selectedProgram?.cFProgramPhasesContainerId
            );

            if (tempSelectedProgramWithPhase) {
              const updatedPrograms =
                tempSelectedProgramWithPhase.programs.filter(
                  (item) => item.id !== programID
                );

              tempSelectedProgramWithPhase.programs = updatedPrograms;

              const updatedTemplatesWithModifiedPrograms = templates.map(
                (item) => {
                  if (item.id === tempSelectedProgramWithPhase.id) {
                    return tempSelectedProgramWithPhase;
                  }
                  return item;
                }
              );
              setSelectedProgram(tempSelectedProgramWithPhase);
              setSelectedProgramData(tempSelectedProgramWithPhase);
              setTemplates(updatedTemplatesWithModifiedPrograms);
            }

            // update in original state`
            let updatedOriginalTemplates = originalState.templates.map(
              (item) => {
                if (item.id === selectedProgram?.cFProgramPhasesContainerId) {
                  return {
                    ...item,
                    programs: item.programs.filter(
                      (item) => item.id !== programID
                    ),
                  };
                }
                return item;
              }
            );
            setOriginalState({
              ...originalState,
              templates: updatedOriginalTemplates,
            });
          } else {
            const tempSelectedProgramWithPhase = programs.find(
              (item) => item?.id === selectedProgram?.cFProgramPhasesContainerId
            );

            if (tempSelectedProgramWithPhase) {
              const updatedPrograms =
                tempSelectedProgramWithPhase.programs.filter(
                  (item) => item.id !== programID
                );

              tempSelectedProgramWithPhase.programs = updatedPrograms;

              const updatedProgramsWithModifiedPrograms = programs.map(
                (item) => {
                  if (item.id === tempSelectedProgramWithPhase.id) {
                    return tempSelectedProgramWithPhase;
                  }
                  return item;
                }
              );
              setSelectedProgram(tempSelectedProgramWithPhase);
              setSelectedProgramData(tempSelectedProgramWithPhase);
              setPrograms(updatedProgramsWithModifiedPrograms);
            }

            // update in original state`
            let updatedOriginalPrograms = originalState.programs.map((item) => {
              if (item.id === selectedProgram?.cFProgramPhasesContainerId) {
                return {
                  ...item,
                  programs: item.programs.filter(
                    (item) => item.id !== programID
                  ),
                };
              }
              return item;
            });
            setOriginalState({
              ...originalState,
              programs: updatedOriginalPrograms,
            });
          }

          setIsLoading(false);
          return;
        } else {
          let updatedPrograms = programs.filter(
            (program) => program.id !== programID
          );

          // this check that if this program is from the folder and updating the folder state
          if (selectedProgram?.programFolderId) {
            setFolderSelected((prev) => {
              let prevData = { ...prev };
              if (folderSelected?.programs) {
                let newPrograms = prevData?.programs.filter(
                  (program) => program.id !== selectedProgram?.id
                );
                prevData.programs = newPrograms;
              }
              setFolders((prevs) => {
                let data = [...prevs];
                let newFolderData = data?.map((folder) => {
                  if (folder.id === prevData.id) {
                    return prevData;
                  } else {
                    return folder;
                  }
                });

                setOriginalState((preState) => {
                  return {
                    ...preState,
                    folders: [...newFolderData],
                  };
                });
                return newFolderData;
              });
              return prevData;
            });
            setSelectedProgram({});
            setSelectedProgramData({});
            setProgramSubscribers([]);
            setProgramChannels([]);
            setIsLoading(false);
            return;
          }

          setPrograms(updatedPrograms);

          if (updatedPrograms?.length > 0) {
            setSelectedProgram(updatedPrograms[0]);
            getProgram(updatedPrograms[0]?.id);
            getSubscribers(updatedPrograms[0]?.id);
            getProgramAssignedChannels(updatedPrograms[0]?.id);
            if (updatedPrograms[0]?.programs) {
              setSelectedProgramWithPhases(updatedPrograms[0]);
              setOpenPhaseId(updatedPrograms[0]?.id);
            }
          } else {
            setSelectedProgram({});
            setSelectedProgramData({});
            setProgramSubscribers([]);
            setProgramChannels([]);
          }

          // update in original state
          let updatedOriginalPrograms = originalState.programs.filter(
            (item) => item.id !== programID
          );
          setOriginalState({
            ...originalState,
            programs: updatedOriginalPrograms,
          });
        }
      }
      if (
        selectedProgram?.programs &&
        !selectedProgram?.cFProgramPhasesContainerId
      ) {
        const updatedPrograms = selectedProgram.programs.filter(
          (item) => item.id !== programID
        );
        // this is for when program is inside the phaseContainer and that phaseContainer is inside the folder
        if (selectedProgram?.programFolderId) {
          if (selectedProgram?.isTemplate) {
            setFolderSelected((prev) => {
              let prevData = { ...prev };
              if (folderSelected?.containers?.length > 0) {
                let newPrograms = prevData?.containers.map((program) => {
                  if (program.id === selectedProgram?.id) {
                    if (program?.programs) {
                      program.programs = updatedPrograms;
                    }
                    return program;
                  }
                  return program;
                });
                prevData.containers = newPrograms;
              }
              setTemplateFolders((prevs) => {
                let data = [...prevs];
                let newFolderData = data?.map((folder) => {
                  if (folder.id === prevData.id) {
                    return prevData;
                  } else {
                    return folder;
                  }
                });

                setOriginalState((preState) => {
                  return {
                    ...preState,
                    templateFolders: [...newFolderData],
                  };
                });

                return newFolderData;
              });
              return prevData;
            });
            setIsLoading(false);
          } else {
            setFolderSelected((prev) => {
              let prevData = { ...prev };
              if (folderSelected?.containers?.length > 0) {
                let newPrograms = prevData?.containers.map((program) => {
                  if (program.id === selectedProgram?.id) {
                    if (program?.programs) {
                      program.programs = updatedPrograms;
                    }
                    return program;
                  }
                  return program;
                });
                prevData.containers = newPrograms;
              }
              setFolders((prevs) => {
                let data = [...prevs];
                let newFolderData = data?.map((folder) => {
                  if (folder.id === prevData.id) {
                    return prevData;
                  } else {
                    return folder;
                  }
                });

                setOriginalState((preState) => {
                  return {
                    ...preState,
                    templateFolders: [...newFolderData],
                  };
                });

                return newFolderData;
              });
              return prevData;
            });
          }
        }
        //this condition is for the situation when selected program is the phase container and user delete any phase inside that
        if (selectedTab === "templates") {
          const updatedProgramsInTemplatesWithModifiedPrograms = templates.map(
            (item) => {
              if (item.id === selectedProgram.id) {
                return { ...item, programs: updatedPrograms };
              }
              return item;
            }
          );

          let tempSelectedProgram = { ...selectedProgram };
          tempSelectedProgram.programs = [...updatedPrograms];
          setSelectedProgram(tempSelectedProgram);
          setSelectedProgramData(tempSelectedProgram);
          setTemplates(updatedProgramsInTemplatesWithModifiedPrograms);

          // update in original state`
          setOriginalState({
            ...originalState,
            templates: updatedProgramsInTemplatesWithModifiedPrograms,
          });
        } else {
          const updatedProgramsWithModifiedPrograms = programs.map((item) => {
            if (item.id === selectedProgram.id) {
              return { ...item, programs: updatedPrograms };
            }
            return item;
          });
          let tempSelectedProgram = { ...selectedProgram };
          tempSelectedProgram.programs = [...updatedPrograms];
          setSelectedProgram(tempSelectedProgram);
          setSelectedProgramData(tempSelectedProgram);
          setPrograms(updatedProgramsWithModifiedPrograms);

          setOriginalState({
            ...originalState,
            programs: updatedProgramsWithModifiedPrograms,
          });
        }

        setIsLoading(false);
        return;
      }

      // the below condition and code is for that situation when we are at another program phase as selected but we are deleting some another phase while the other program phase is selected
      let selectedProgramPhasesProgramsArray =
        selectedProgramWithPhases?.programs;

      if (
        selectedProgramPhasesProgramsArray &&
        selectedProgramPhasesProgramsArray.length > 0
      ) {
        const programExistsInProgramPhase =
          selectedProgramPhasesProgramsArray.some(
            (item) => item?.id === programID
          );

        if (programExistsInProgramPhase) {
          const tempProgramsInsidePhaseContainer =
            selectedProgramPhasesProgramsArray.filter(
              (item) => item.id !== programID
            );
          const tempSelectedProgramPhase = {
            ...selectedProgramWithPhases,
            programs: tempProgramsInsidePhaseContainer,
          };

          setSelectedProgram(tempSelectedProgramPhase);
          setSelectedProgramData(tempSelectedProgramPhase);
          setSelectedProgramWithPhases(tempSelectedProgramPhase);
          setOpenPhaseId(tempSelectedProgramPhase?.id);

          const updatedAllProgramsWithContainerPhaseProgramModified =
            programs.map((item) => {
              if (item.id === selectedProgramWithPhases.id) {
                return {
                  ...item,
                  programs: tempProgramsInsidePhaseContainer,
                };
              }
              return item;
            });

          setPrograms(updatedAllProgramsWithContainerPhaseProgramModified);
        }
      }
    } else {
      displayAlert({
        message: "Failed to delete program",
        type: "error",
      });
    }
    setIsLoading(false);
  };

  const deleteProgramWithPhases = async (containerID) => {
    try {
      if (programSubscribers?.length > 0) {
        displayAlert({
          message: "Program has subscribers, cannot delete!",
          type: "error",
        });
        return;
      }
      setLoadingPrograms(true);
      let res = await axios({
        method: "post",
        url: `${APIURL}CF/api/programs/deletePhaseContainer`,
        data: {
          containerID: containerID,
        },
      });
      console.log("res from deleting a whole program phase container", res);
      if (res) {
        displayAlert({
          message: "Program deleted successfully",
          type: "success",
        });
      }
      // update the state
      if (selectedProgram?.id === containerID) {
        if (selectedProgram?.isTemplate) {
          let updatedTemplates = templates.filter(
            (template) => template.id !== containerID
          );
          // this check that if this program is from the folder and updating the folder state
          if (selectedProgram?.programFolderId) {
            setFolderSelected((prev) => {
              let prevData = { ...prev };
              if (folderSelected?.programs) {
                let newContainer = prevData?.containers.filter(
                  (phase) => phase.id !== selectedProgram?.id
                );
                prevData.containers = newContainer;
              } else {
                let newPrograms = prevData?.programs.filter(
                  (program) => program.id !== selectedProgram?.id
                );
                prevData.programs = newPrograms;
              }
              setTemplateFolders((prevs) => {
                let data = [...prevs];
                let newFolderData = data?.map((folder) => {
                  if (folder.id === prevData.id) {
                    return prevData;
                  } else {
                    return folder;
                  }
                });

                setOriginalState((preState) => {
                  return {
                    ...preState,
                    templateFolders: [...newFolderData],
                  };
                });

                return newFolderData;
              });
              return prevData;
            });
            setSelectedProgram({});
            setSelectedProgramData({});
            setProgramSubscribers([]);
            setProgramChannels([]);
            setSelectedProgramWithPhases(null);
            setOpenPhaseId(null);
            return;
          }
          setTemplates(updatedTemplates);

          if (updatedTemplates?.length > 0) {
            setSelectedProgram(updatedTemplates[0]);
            getProgram(updatedTemplates[0]?.id);
            getSubscribers(updatedTemplates[0]?.id);
            getProgramAssignedChannels(updatedTemplates[0]?.id);
            if (updatedTemplates[0]?.programs) {
              setSelectedProgramWithPhases(updatedTemplates[0]);
              setOpenPhaseId(updatedTemplates[0]?.id);
            } else {
              setSelectedProgramWithPhases(null);
              setOpenPhaseId(null);
            }
          } else {
            setSelectedProgram({});
            setSelectedProgramData({});
            setProgramSubscribers([]);
            setProgramChannels([]);
          }
          // update in original state
          let updatedOriginalTemplates = originalState.templates.filter(
            (template) => template.id !== containerID
          );
          setOriginalState({
            ...originalState,
            templates: updatedOriginalTemplates,
          });
        }
        // in the else condition it is fixed that it is not a template
        else {
          let allProgramsWithoutDeletedContainer = programs?.filter(
            (item) => item?.id !== containerID
          );

          setPrograms(allProgramsWithoutDeletedContainer);
          // this check that if this program is from the folder and updating the folder state
          if (selectedProgram?.programFolderId) {
            setFolderSelected((prev) => {
              let prevData = { ...prev };
              if (folderSelected?.programs) {
                let newContainer = prevData?.containers.filter(
                  (phase) => phase.id !== selectedProgram?.id
                );
                prevData.containers = newContainer;
              } else {
                let newPrograms = prevData?.programs.filter(
                  (program) => program.id !== selectedProgram?.id
                );
                prevData.programs = newPrograms;
              }
              setFolders((prevs) => {
                let data = [...prevs];
                let newFolderData = data?.map((folder) => {
                  if (folder.id === prevData.id) {
                    return prevData;
                  } else {
                    return folder;
                  }
                });

                setOriginalState((preState) => {
                  return {
                    ...preState,
                    folders: [...newFolderData],
                  };
                });
                return newFolderData;
              });
              return prevData;
            });
            setSelectedProgram({});
            setSelectedProgramData({});
            setProgramSubscribers([]);
            setProgramChannels([]);
            setSelectedProgramWithPhases(null);
            setOpenPhaseId(null);
            return;
          }
          if (allProgramsWithoutDeletedContainer.length > 0) {
            setSelectedProgram(allProgramsWithoutDeletedContainer[0]);
            setSelectedProgramData(allProgramsWithoutDeletedContainer[0]);
            if (allProgramsWithoutDeletedContainer[0]?.programs) {
              setSelectedProgramWithPhases(
                allProgramsWithoutDeletedContainer[0]
              );
              setOpenPhaseId(allProgramsWithoutDeletedContainer[0]?.id);
            } else {
              setOpenPhaseId(null);
              setSelectedProgramWithPhases(null);
            }
          } else {
            setSelectedProgram({});
            setSelectedProgramData({});
            setProgramSubscribers([]);
            setProgramChannels([]);
            setSelectedProgramWithPhases(null);
            setOpenPhaseId(null);
          }

          // update in original state
          let updatedOriginalPrograms = originalState.programs.filter(
            (item) => item.id !== containerID
          );
          setOriginalState({
            ...originalState,
            programs: updatedOriginalPrograms,
          });
        }
      }
    } catch (error) {
      console.log(
        "Not able to delete the whole program phase container",
        error
      );
    } finally {
      setLoadingPrograms(false);
    }
  };
  const duplicateOneProgram = async (
    programID,
    name,
    description,
    coachID,
    containerID
  ) => {
    setIsLoading(true);
    let res = await duplicateProgram(
      programID,
      name,
      description,
      coachID,
      containerID,
      duplicateAsTemplate
    );
    let duplicateProgramResponse = res;
    console.log("coming here dupli", duplicateProgramResponse);
    if (res) {
      setFolderSelected({});
      setOpenFolderId("");
      displayAlert({
        message: "Program duplicated successfully",
        type: "success",
      });

      if (duplicateAsTemplate) {
        if (
          selectedProgramData?.isTemplate &&
          typeof selectedProgramData?.cFProgramPhasesContainerId !== "string"
        ) {
          let updatedTemplates = [res, ...templates];
          setTemplates(updatedTemplates);

          // update in original state
          let updatedOriginalTemplates = [res, ...originalState.templates];
          setOriginalState({
            ...originalState,
            templates: updatedOriginalTemplates,
          });
        }
      } else {
        if (
          duplicateProgramResponse &&
          duplicateProgramResponse.cFProgramPhasesContainerId !== null
        ) {
          let updatedPhaseContainerForPrograms = {};
          // Update the programs array
          const updatedPrograms = programs.map((item) => {
            if (item.id === containerID) {
              // Append duplicateProgram to the programs array of the item with a matching ID
              updatedPhaseContainerForPrograms = {
                ...item,
                programs: [...(item.programs || []), duplicateProgramResponse],
              };
              return {
                ...item,
                programs: [...(item.programs || []), duplicateProgramResponse], // Handle case where programs array is null or undefined
              };
            }
            return item;
          });

          // this condition is for the duplication of the phase from templates and that doesn't have link with folder
          if (
            selectedProgramWithPhases?.isTemplate &&
            typeof selectedProgramWithPhases?.programFolderId !== "string"
          ) {
            let updatedPhaseContainer = {};
            const updatedTemplates = templates.map((item) => {
              if (item.id === containerID) {
                // Append duplicateProgram to the programs array of the item with a matching ID
                updatedPhaseContainer = {
                  ...item,
                  programs: [
                    ...(item.programs || []),
                    duplicateProgramResponse,
                  ],
                };
                return {
                  ...item,
                  programs: [
                    ...(item.programs || []),
                    duplicateProgramResponse,
                  ], // Handle case where programs array is null or undefined
                };
              }
              return item;
            });
            getProgramByID(duplicateProgramResponse?.id);
            setTemplates(updatedTemplates);

            // update in original state
            const updatedOriginalPrograms = originalState.templates.map(
              (item) => {
                if (item.id === containerID) {
                  return {
                    ...item,
                    programs: [
                      ...(item?.programs || []),
                      duplicateProgramResponse,
                    ],
                  };
                }
                return item;
              }
            );

            setOriginalState({
              ...originalState,
              templates: updatedOriginalPrograms,
            });

            setSelectedProgram(updatedPhaseContainer);
            setSelectedProgramData(updatedPhaseContainer);
            setIsLoading(false);
            return;
          }

          // this is for when program is inside the phaseContainer and that phaseContainer is inside the folder
          if (selectedProgramWithPhases?.programFolderId) {
            if (selectedProgramWithPhases?.isTemplate) {
              setFolderSelected((prev) => {
                let prevData = { ...prev };
                if (folderSelected?.containers?.length > 0) {
                  let newPrograms = prevData?.containers.map((program) => {
                    if (program.id === selectedProgramWithPhases?.id) {
                      if (program?.programs) {
                        program.programs = [
                          ...(program.programs || []),
                          duplicateProgramResponse,
                        ];
                      }
                      return program;
                    }
                    return program;
                  });
                  prevData.containers = newPrograms;
                }
                setTemplateFolders((prevs) => {
                  let data = [...prevs];
                  let newFolderData = data?.map((folder) => {
                    if (folder.id === prevData.id) {
                      return prevData;
                    } else {
                      return folder;
                    }
                  });

                  setOriginalState((preState) => {
                    return {
                      ...preState,
                      templateFolders: [...newFolderData],
                    };
                  });

                  return newFolderData;
                });
                return prevData;
              });
              setIsLoading(false);
            } else {
              setFolderSelected((prev) => {
                let prevData = { ...prev };
                if (folderSelected?.containers?.length > 0) {
                  let newPrograms = prevData?.containers.map((program) => {
                    if (program.id === selectedProgramWithPhases?.id) {
                      if (program?.programs) {
                        program.programs = [
                          ...(program.programs || []),
                          duplicateProgramResponse,
                        ];
                      }
                      return program;
                    }
                    return program;
                  });
                  prevData.containers = newPrograms;
                }
                setFolders((prevs) => {
                  let data = [...prevs];
                  let newFolderData = data?.map((folder) => {
                    if (folder.id === prevData.id) {
                      return prevData;
                    } else {
                      return folder;
                    }
                  });

                  setOriginalState((preState) => {
                    return {
                      ...preState,
                      templateFolders: [...newFolderData],
                    };
                  });

                  return newFolderData;
                });
                return prevData;
              });
            }
            const tempSelectedProgramPhase = { ...selectedProgramWithPhases };
            // if (tempSelectedProgramPhase?.programs) {
            //   tempSelectedProgramPhase.programs = [
            //     ...(tempSelectedProgramPhase.programs || []),duplicateProgramResponse
            //   ];
            // }

            setSelectedProgram(tempSelectedProgramPhase);
            setSelectedProgramData(tempSelectedProgramPhase);

            setIsLoading(false);
            return;
          }

          getProgramByID(duplicateProgramResponse?.id);
          setPrograms(updatedPrograms);
          setSelectedProgram(updatedPhaseContainerForPrograms);
          setSelectedProgramData(updatedPhaseContainerForPrograms);

          // update in original state
          const updatedOriginalPrograms = originalState.programs.map((item) => {
            if (item.id === containerID) {
              return {
                ...item,
                programs: [...(item.programs || []), duplicateProgramResponse],
              };
            }
            return item;
          });

          setOriginalState({
            ...originalState,
            programs: updatedOriginalPrograms,
          });
        } else {
          let updatedPrograms = [res, ...programs];
          setPrograms(updatedPrograms);

          // update in original state
          let updatedOriginalPrograms = [res, ...originalState.programs];
          setOriginalState({
            ...originalState,
            programs: updatedOriginalPrograms,
          });
        }
      }
    } else {
      displayAlert({
        message: "Failed to duplicate program",
        type: "error",
      });
    }
    setIsLoading(false);
  };

  const deleteOneWeek = (week) => {
    // remove workouts, days messages, and days targets for the week
    let startDay = (week - 1) * 7 + 1;
    let endDay = week * 7;
    let newAddedWorkouts = { ...addedWorkouts };
    let newDaysMessages = { ...daysMessages };
    let newDaysTargets = { ...daysTargets };
    for (let i = startDay; i <= endDay; i++) {
      if (newAddedWorkouts[i]) {
        delete newAddedWorkouts[i];
      }
      if (newDaysMessages[i]) {
        delete newDaysMessages[i];
      }
      if (newDaysTargets[i]) {
        delete newDaysTargets[i];
      }
    }

    // shift the workouts, messages, and targets for the next weeks and keep the previous weeks as it is
    let newAddedWorkoutsCopy = { ...newAddedWorkouts };
    let newDaysMessagesCopy = { ...newDaysMessages };
    let newDaysTargetsCopy = { ...newDaysTargets };
    for (let i = endDay + 1; i <= weeks * 7; i++) {
      if (newAddedWorkoutsCopy[i]) {
        newAddedWorkouts[i - 7] = newAddedWorkoutsCopy[i];
        delete newAddedWorkouts[i];
      }
      if (newDaysMessagesCopy[i]) {
        newDaysMessages[i - 7] = newDaysMessagesCopy[i];
        delete newDaysMessages[i];
      }
      if (newDaysTargetsCopy[i]) {
        newDaysTargets[i - 7] = newDaysTargetsCopy[i];
        delete newDaysTargets[i];
      }
    }

    setAddedWorkouts(newAddedWorkouts);
    setDaysMessages(newDaysMessages);
    setDaysTargets(newDaysTargets);
    setUnsavedChanges(true);

    // remove last 7 days from daysArray
    let newDaysArray = [...daysArray];
    newDaysArray.splice(-7, 7);

    // remove last week from weeksArray
    let newWeeksArray = [...weeksArray];
    newWeeksArray.pop();

    setDaysArray(newDaysArray);
    setWeeksArray(newWeeksArray);

    setWeeks(weeks - 1);
  };

  const duplicateOneWeek = async (weekNo) => {
    setUnsavedChanges(true);
    setIsLoading(true);
    let modifiedWorkouts;

    modifiedWorkouts = await getModifiedWorkoutForAssignedWeek({
      weekNo,
      addedWorkouts,
      weeks,
    });

    setAddedWorkouts(modifiedWorkouts);
    let modifiedDaysMessages = copyDaysMesssages({
      prev: daysMessages,
      daysMessages,
      weekNo,
      originalWeeks,
      addedWorkouts,
    });
    setDaysMessages(modifiedDaysMessages);

    let modifiedDaysTargets = copyDaysTargets({
      prev: daysTargets,
      daysTargets,
      weekNo,
      originalWeeks,
      addedWorkouts,
    });
    setDaysTargets(modifiedDaysTargets);

    let newDaysArray = [...daysArray];
    let newWeeksArray = [...weeksArray];

    let startDay = 7 * weekNo + 1;
    let lastDay = 7 * weeks + 1;
    let newWeekDays = Array.from({ length: 7 }, (_, i) => lastDay + i);
    newDaysArray = [...newDaysArray, ...newWeekDays];
    newWeeksArray.push(weeks + 1);

    setDaysArray(newDaysArray);
    setWeeksArray(newWeeksArray);
    setWeeks(weeks + 1);
    setIsLoading(false);
  };

  const handlePasteOneWorkout = async (day) => {
    setUnsavedChanges(true);
    setIsLoading(true);
    let workout = copiedWorkout?.workout;
    let modifiedWorkouts = await handlePasteSingleWorkout(
      addedWorkouts,
      day,
      workout
    );
    setAddedWorkouts(modifiedWorkouts);
    setIsLoading(false);
  };

  const handleReplaceOneWorkout = async (day, pasteIndex) => {
    setUnsavedChanges(true);
    setIsLoading(true);
    let workout = copiedWorkout?.workout;
    let modifiedWorkouts = await handleReplaceSingleWorkout(
      addedWorkouts,
      day,
      pasteIndex,
      workout
    );
    setAddedWorkouts(modifiedWorkouts);

    setIsLoading(false);
  };

  const handlePaste = async (day) => {
    setUnsavedChanges(true);
    // check for workouts, activity, auto messages and targets and add them to the day
    let dayData = copiedDay?.data;
    console.log("paste", day, dayData);
    setIsLoading(true);
    if (dayData?.workouts) {
      let modifiedWorkouts = await modifyWorkoutOnPaste(
        addedWorkouts,
        dayData,
        day
      );
      setAddedWorkouts(modifiedWorkouts);
      setIsLoading(false);
    }

    if (dayData?.targets) {
      setDaysTargets((prev) => {
        let previous = prev?.[day] || {};
        let res = { ...prev, [day]: dayData?.targets };
        return res;
      });
    }

    if (dayData?.messages) {
      setDaysMessages((prev) => {
        let previous = prev?.[day] || {};
        let res = { ...prev, [day]: dayData?.messages };
        return res;
      });
    }
    setPastedData((prev) => {
      return {
        ...prev,
        [day]: true,
      };
    });
    setIsLoading(false);
  };

  const handlePasteOneWeek = async (copiedWeekNo, pasteWeekNo) => {
    setUnsavedChanges(true);
    setIsLoading(true);

    // go to each day of the copied week and paste the data
    let startDay = 7 * copiedWeekNo + 1;
    let endDay = 7 * copiedWeekNo + 7;
    let newAddedWorkouts = { ...addedWorkouts };
    let newDaysMessages = { ...daysMessages };
    let newDaysTargets = { ...daysTargets };
    for (let i = startDay; i <= endDay; i++) {
      let dayData = {
        workouts: addedWorkouts?.[i] ?? [],
        messages: daysMessages?.[i],
        targets: daysTargets?.[i],
      };
      let modifiedWorkouts = await modifyWorkoutOnPaste(
        newAddedWorkouts,
        dayData,
        7 * pasteWeekNo + (i - startDay) + 1
      );

      newAddedWorkouts = modifiedWorkouts;
      if (dayData?.targets) {
        newDaysTargets[7 * pasteWeekNo + (i - startDay) + 1] = dayData?.targets;
      }
      if (dayData?.messages) {
        newDaysMessages[7 * pasteWeekNo + (i - startDay) + 1] =
          dayData?.messages;
      }
    }

    setAddedWorkouts(newAddedWorkouts);
    setDaysMessages(newDaysMessages);
    setDaysTargets(newDaysTargets);
    setIsLoading(false);
    setCopiedWeekNo(null);
  };

  const handleDeleteLastWeekProgramWithSubscribers = async (programID) => {
    setIsLoading(true);
    if (selectedProgramData?.workouts?.length > 0) {
      let tempData = selectedProgramData?.workouts;
      let startDay = (weeks - 1) * 7 + 1;
      let endDay = weeks * 7;

      let workoutIDs = [];
      tempData.map((obj) => {
        if (obj?.dayIdx >= startDay && obj?.dayIdx <= endDay) {
          workoutIDs.push(obj?.id);
        }
      });
      // await deleteLastWeekWithSubscribers(workoutIDs);
      let duration = 7 * (weeks - 1);
      let res = await deleteLastWeekOfAssignedProgram(
        workoutIDs,
        programID,
        duration
      );
      if (res) {
        displayAlert({
          message: "Last week deleted successfully",
          type: "success",
        });
      } else {
        displayAlert({
          message: "Failed to delete last week",
          type: "error",
        });
      }
      getProgram(programID);
    }
    setIsLoading(false);
  };

  const deleteOneWorkoutFromADay = (workoutDay, workoutIndex) => {
    setUnsavedChanges(true);
    let newAddedWorkouts = { ...addedWorkouts };
    let newWorkouts = newAddedWorkouts[workoutDay];
    newWorkouts.splice(workoutIndex, 1);
    newAddedWorkouts[workoutDay] = newWorkouts;
    setAddedWorkouts(newAddedWorkouts);
  };

  // const updateContainerInfo = async (programID, name, description, notes) => {
  //   setIsLoading(true);
  //   try {
  //     let res = await updateProgramPhaseContainer({
  //       id: programID,
  //       name,
  //       description,
  //       notes,
  //     });
  //     if (res) {
  //       displayAlert({
  //         message: "Program phase container updated successfully",
  //         type: "success",
  //       });

  //       if (selectedProgramData?.programFolderId) {
  //         if (selectedTab === "programs") {
  //           setFolders((prev) => {
  //             let oldData = [...prev];
  //             let newData = oldData?.map((folder) => {
  //               if (folder?.id === selectedProgramData?.programFolderId) {
  //                 if (folder?.containers) {
  //                   const updatedContainer = folder.containers?.map(
  //                     (phaseContainer) => {
  //                       if (phaseContainer?.id === programID) {
  //                         return { ...phaseContainer, name, description };
  //                       }
  //                       return phaseContainer;
  //                     }
  //                   );
  //                   folder.containers = updatedContainer;
  //                 }
  //                 return folder;
  //               }
  //               return folder;
  //             });
  //             return newData;
  //           });

  //           let newData = originalState.folders.map((folder) => {
  //             if (folder?.id === selectedProgramData?.programFolderId) {
  //               if (folder?.containers) {
  //                 const updatedContainer = folder.containers?.map(
  //                   (phaseContainer) => {
  //                     if (phaseContainer?.id === programID) {
  //                       return { ...phaseContainer, name, description };
  //                     }
  //                     return phaseContainer;
  //                   }
  //                 );
  //                 folder.containers = updatedContainer;
  //               }
  //               return folder;
  //             }
  //             return folder;
  //           });
  //           setOriginalState((prev) => {
  //             return {
  //               ...prev,
  //               folders: newData,
  //             };
  //           });
  //         } else if (selectedTab === "templates") {
  //           setTemplateFolders((prev) => {
  //             let oldData = [...prev];
  //             let newData = oldData?.map((folder) => {
  //               if (folder?.id === selectedProgramData?.programFolderId) {
  //                 if (folder?.containers) {
  //                   const updatedContainer = folder.containers?.map(
  //                     (phaseContainer) => {
  //                       if (phaseContainer?.id === programID) {
  //                         return { ...phaseContainer, name, description };
  //                       }
  //                       return phaseContainer;
  //                     }
  //                   );
  //                   folder.containers = updatedContainer;
  //                 }
  //                 return folder;
  //               }
  //               return folder;
  //             });
  //             return newData;
  //           });

  //           let newData = originalState?.templateFolders?.map((folder) => {
  //             if (folder?.id === selectedProgramData?.programFolderId) {
  //               if (folder?.containers) {
  //                 const updatedContainer = folder.containers?.map(
  //                   (phaseContainer) => {
  //                     if (phaseContainer?.id === programID) {
  //                       return { ...phaseContainer, name, description };
  //                     }
  //                     return phaseContainer;
  //                   }
  //                 );
  //                 folder.containers = updatedContainer;
  //               }
  //               return folder;
  //             }
  //             return folder;
  //           });
  //           setOriginalState((prev) => {
  //             return {
  //               ...prev,
  //               templateFolders: newData,
  //             };
  //           });
  //         }
  //       } else if (selectedProgramData?.isTemplate) {
  //         let updatedTemplates = templates.map((template) => {
  //           if (template.id === programID) {
  //             return { ...template, name, description };
  //           }
  //           return template;
  //         });
  //         setTemplates(updatedTemplates);
  //       } else {
  //         let updatedPrograms = programs.map((program) => {
  //           if (program.id === programID) {
  //             return { ...program, name, description };
  //           }
  //           return program;
  //         });
  //         setPrograms(updatedPrograms);
  //       }
  //       // also update the selected program
  //       setSelectedProgram({ ...selectedProgram, name, description, notes });
  //     }
  //   } catch (error) {
  //     console.log("Error while updating the program phase container", error);
  //     displayAlert({
  //       message: "Failed to update program phase container",
  //       type: "error",
  //     });
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const updateContainerInfo = async (programID, name, description, notes) => {
    setIsLoading(true);
    try {
      let res = await updateProgramPhaseContainer({
        id: programID,
        name,
        description,
        notes,
      });

      if (res) {
        displayAlert({
          message: "Program phase container updated successfully",
          type: "success",
        });

        updateState(programID, name, description);
        setSelectedProgram({ ...selectedProgram, name, description, notes });
      }
    } catch (error) {
      console.error("Error while updating the program phase container", error);
      displayAlert({
        message: "Failed to update program phase container",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const updateState = (programID, name, description) => {
    if (selectedProgramData?.programFolderId) {
      if (selectedTab === "programs") {
        updateProgramFoldersState(
          setFolders,
          setOriginalState,
          originalState,
          programID,
          name,
          description,
          selectedProgramData
        );
      } else if (selectedTab === "templates") {
        updateTemplateFoldersState(
          setTemplateFolders,
          setOriginalState,
          originalState,
          programID,
          name,
          description,
          selectedProgramData
        );
      }
    } else if (selectedProgramData?.isTemplate) {
      updateTemplatesList(
        setTemplates,
        templates,
        programID,
        name,
        description
      );
    } else {
      updateProgramsList(setPrograms, programs, programID, name, description);
    }
  };

  const updateProgramInformation = async (
    programID,
    name,
    description,
    notes
  ) => {
    setIsLoading(true);
    let res = await updateProgramInfo(programID, name, description, notes);
    if (res) {
      displayAlert({
        message: "Program updated successfully",
        type: "success",
      });
      setSelectedProgram({ ...selectedProgram, name, description, notes });
      setSelectedProgramData({
        ...selectedProgramData,
        name,
        description,
        notes,
      });
      // if is template, update the template list
      if (selectedProgramData?.isTemplate) {
        let updatedTemplates = templates.map((template) => {
          if (template.id === programID) {
            return { ...template, name, description };
          }
          return template;
        });
        setTemplates(updatedTemplates);
      } else if (res?.cFProgramPhasesContainerId) {
        console.log("Comes here", selectedProgram);
        let updatedPrograms = programs.map((program) => {
          if (program.id === res?.cFProgramPhasesContainerId) {
            let tempPrograms;
            tempPrograms = program?.programs.map((item, index) => {
              if (item?.id === programID) {
                return { ...item, name, description };
              }
              return item;
            });

            return { ...program, programs: tempPrograms };
          }
          return program;
        });
        let tempUpdatedSelectedProgramWithPhases = updatedPrograms?.filter(
          (item) => item?.id === res?.cFProgramPhasesContainerId
        );
        // this will update the folders
        if (selectedProgramWithPhases?.programFolderId) {
          if (selectedTab === "programs") {
            setFolders((prev) => {
              let oldData = [...prev];
              let newData = oldData?.map((folder) => {
                if (folder?.id === selectedProgramWithPhases?.programFolderId) {
                  if (folder?.containers) {
                    const updatedContainer = folder.containers?.map(
                      (phaseContainer) => {
                        if (
                          phaseContainer?.id === selectedProgramWithPhases?.id
                        ) {
                          const updatedPhases = phaseContainer?.programs.map(
                            (item, index) => {
                              if (item?.id === programID) {
                                return { ...item, name, description };
                              }
                              return item;
                            }
                          );
                          phaseContainer.programs = updatedPhases;
                          return phaseContainer;
                        }
                        return phaseContainer;
                      }
                    );
                    folder.containers = updatedContainer;
                  }
                  return folder;
                }
                return folder;
              });
              setOriginalState((prev) => {
                return {
                  ...prev,
                  folders: newData,
                };
              });
              return newData;
            });
          } else if (selectedTab === "templates") {
            setTemplateFolders((prev) => {
              let oldData = [...prev];
              let newData = oldData?.map((folder) => {
                if (folder?.id === selectedProgramWithPhases?.programFolderId) {
                  if (folder?.containers) {
                    const updatedContainer = folder.containers?.map(
                      (phaseContainer) => {
                        if (
                          phaseContainer?.id === selectedProgramWithPhases?.id
                        ) {
                          const updatedPhases = phaseContainer?.programs.map(
                            (item, index) => {
                              if (item?.id === programID) {
                                return { ...item, name, description };
                              }
                              return item;
                            }
                          );
                          phaseContainer.programs = updatedPhases;
                          return phaseContainer;
                        }
                        return phaseContainer;
                      }
                    );
                    folder.containers = updatedContainer;
                  }
                  return folder;
                }
                return folder;
              });
              setOriginalState((prev) => {
                return {
                  ...prev,
                  templateFolders: newData,
                };
              });
              return newData;
            });
          }
          setIsLoading(false);
          return;
        }
        setSelectedProgramWithPhases({
          ...tempUpdatedSelectedProgramWithPhases[0],
        });
        setPrograms(updatedPrograms);
      } else {
        let updatedPrograms = programs.map((program) => {
          if (program.id === programID) {
            return { ...program, name, description };
          }
          return program;
        });
        setPrograms(updatedPrograms);
      }
    } else {
      displayAlert({
        message: "Failed to update program",
        type: "error",
      });
    }
    setIsLoading(false);
  };

  const addProgram = async (
    name,
    description,
    programType,
    totalPhases,
    weeksPerPhase,
    isFolderSelected
  ) => {
    console.log(
      "res ha kya ",
      programType,
      totalPhases,
      weeksPerPhase,
      folderSelected
    );
    if (programType === "phase") {
      setIsLoading(true);
      try {
        if (isFolderSelected) {
          let data = {
            folderID: folderSelected?.id,
            name: name,
            description: description,
            coachID: coachID,
            totalPhases,
            weeksPerPhase,
            isTemplate: selectedTab === "templates",
          };
          let response = await savePhaseContainerAndConnectToFolder(data);
          console.log("this is merged api", response);
          if (response) {
            if (selectedTab === "templates") {
              setTemplateFolders((prev) => {
                const oldData = [...prev];
                const newFolderData = oldData.map((folder) => {
                  if (folder?.id === folderSelected?.id) {
                    if (folder?.containers) {
                      folder.containers.push(response?.data?.result);
                    } else {
                      folder["containers"] = [response?.data?.result];
                    }
                    return folder;
                  }
                  return folder;
                });
                setOriginalState((prev) => {
                  return {
                    ...prev,
                    templateFolders: [...newFolderData],
                  };
                });
                return newFolderData;
              });
            } else {
              setFolders((prev) => {
                const oldData = [...prev];
                const newFolderData = oldData.map((folder) => {
                  if (folder?.id === folderSelected?.id) {
                    if (folder?.containers) {
                      folder.containers.push(response?.data?.result);
                    } else {
                      folder["containers"] = [response?.data?.result];
                    }
                    return folder;
                  }
                  return folder;
                });
                setOriginalState((prev) => {
                  return {
                    ...prev,
                    folders: [...newFolderData],
                  };
                });
                return newFolderData;
              });
            }

            if (response?.data?.result) {
              let newProgramWithPhases = response?.data?.result;
              setSelectedProgramWithPhases(newProgramWithPhases);
              setOpenPhaseId(newProgramWithPhases?.id);
              // if (newProgramWithPhases?.isTemplate) {
              //   let updatedTemplates = [newProgramWithPhases, ...templates];
              //   setTemplates(updatedTemplates);
              // } else {
              //   let updatedPrograms = [newProgramWithPhases, ...programs];
              //   setPrograms(updatedPrograms);
              // }
              setOpenFolderId(folderSelected?.id);
              setSelectedProgramData(newProgramWithPhases);
              setSelectedProgram(newProgramWithPhases);
              // by default keep one week
              setWeeks(1);

              // days array will have days from 1 to 7
              let daysArray = Array.from({ length: 7 }, (_, i) => i + 1);
              let weeksArray = Array.from({ length: 1 }, (_, i) => i + 1);
              setDaysArray(daysArray);
              setWeeksArray(weeksArray);

              setUnsavedChanges(false);
              // reset all the states
              setAddedWorkouts({});
              setDaysMessages({});
              setDaysTargets({});
              setTotalWorkouts(0);
              setProgramSubscribers([]);
              setProgramChannels([]);
            }
            return;
          }
        } else {
          let res = await axios({
            method: "post",
            url: `${APIURL}CF/api/programs/createProgramPhasesContainer`,
            data: {
              name: name,
              description: description,
              coachID: coachID,
              totalPhases,
              weeksPerPhase,
              isTemplate: selectedTab === "templates",
            },
          });
          if (res?.data?.result) {
            let newProgramWithPhases = res?.data?.result;
            if (selectedTab === "templates") {
              setOriginalState((prev) => {
                return {
                  ...prev,
                  templates: [newProgramWithPhases, ...prev?.templates],
                };
              });
            } else {
              setOriginalState((prev) => {
                return {
                  ...prev,
                  programs: [newProgramWithPhases, ...prev?.programs],
                };
              });
            }

            setFolderSelected({});
            setOpenFolderId("");
            setSelectedProgramWithPhases(newProgramWithPhases);
            setOpenPhaseId(newProgramWithPhases?.id);
            if (newProgramWithPhases?.isTemplate) {
              let updatedTemplates = [newProgramWithPhases, ...templates];
              setTemplates(updatedTemplates);
            } else {
              let updatedPrograms = [newProgramWithPhases, ...programs];
              setPrograms(updatedPrograms);
            }

            setSelectedProgramData(newProgramWithPhases);
            setSelectedProgram(newProgramWithPhases);
            // by default keep one week
            setWeeks(1);

            // days array will have days from 1 to 7
            let daysArray = Array.from({ length: 7 }, (_, i) => i + 1);
            let weeksArray = Array.from({ length: 1 }, (_, i) => i + 1);
            setDaysArray(daysArray);
            setWeeksArray(weeksArray);

            setUnsavedChanges(false);
            // reset all the states
            setAddedWorkouts({});
            setDaysMessages({});
            setDaysTargets({});
            setTotalWorkouts(0);
            setProgramSubscribers([]);
            setProgramChannels([]);
          }
        }
        console.log("this is merged api", resFromMoveItem);
        console.log("res ha kya ", res?.data?.result);
      } catch (err) {
        console.log(
          "There is some error while creating a Program with phases",
          err
        );

        saveCurrentStateToLocalStorage();
      } finally {
        setIsLoading(false);
      }
    } else {
      setSelectedProgramWithPhases(null);
      let isTemplate = selectedTab === "templates";
      // add program
      let newProgram = {
        id: uuidv4(),
        name,
        description,
        isTemplate,
        isNew: true,
        coachID,
      };

      if (isTemplate) {
        if (isFolderSelected) {
          setTemplateFolders((prev) => {
            let oldData = [...prev];
            const newFolders = oldData.map((folder) => {
              if (folder?.id === folderSelected?.id) {
                if (folder?.programs) {
                  newProgram["connectedFolder"] = folderSelected?.id;
                  folder.programs.push(newProgram);
                } else {
                  newProgram["connectedFolder"] = folderSelected?.id;
                  folder["programs"] = [newProgram];
                }
                return folder;
              }
              return folder;
            });
            return newFolders;
          });
          setOpenFolderId(folderSelected?.id);
        } else {
          let updatedTemplates = [newProgram, ...templates];
          setFolderSelected({});
          setOpenFolderId("");
          setTemplates(updatedTemplates);
        }
      } else {
        if (isFolderSelected) {
          setFolders((prev) => {
            let oldData = [...prev];
            const newFolders = oldData.map((folder) => {
              if (folder?.id === folderSelected?.id) {
                if (folder?.programs) {
                  newProgram["connectedFolder"] = folderSelected?.id;
                  folder.programs.push(newProgram);
                } else {
                  newProgram["connectedFolder"] = folderSelected?.id;
                  folder["programs"] = [newProgram];
                }
                return folder;
              }
              return folder;
            });
            return newFolders;
          });
          setOpenFolderId(folderSelected?.id);
        } else {
          setFolderSelected({});
          setOpenFolderId("");
          let updatedPrograms = [newProgram, ...programs];
          setPrograms(updatedPrograms);
        }
      }

      setSelectedProgramData(newProgram);
      setSelectedProgram(newProgram);
      // by default keep one week
      setWeeks(1);

      // days array will have days from 1 to 7
      let daysArray = Array.from({ length: 7 }, (_, i) => i + 1);
      let weeksArray = Array.from({ length: 1 }, (_, i) => i + 1);
      setDaysArray(daysArray);
      setWeeksArray(weeksArray);

      setUnsavedChanges(true);
      // reset all the states
      setAddedWorkouts({});
      setDaysMessages({});
      setDaysTargets({});
      setTotalWorkouts(0);
      setProgramSubscribers([]);
      setProgramChannels([]);
    }
  };

  const addNewPhase = async () => {
    console.log("add new phase called");
    let containerID = selectedProgramWithPhases?.id;
    // phase name neeeded to be caluculated
    // console.log("de de", selectedProgram, selectedProgram?.programs);
    let duration = selectedProgramWithPhases?.programs[0]?.duration || 1;

    let maxOrderIndex = selectedProgramWithPhases?.programs.reduce(
      (max, item) => (item?.phaseOrderIdx > max ? item : max),
      selectedProgramWithPhases?.programs[0]?.phaseOrderIdx
    );
    maxOrderIndex =
      maxOrderIndex?.phaseOrderIdx ||
      selectedProgramWithPhases?.programs?.length;
    let name = "Newly Added Phase";
    try {
      setIsNewPhaseLoading(true);
      let res = await axios({
        method: "post",
        url: `${APIURL}CF/api/programs/addNewPhase`,
        data: {
          containerID: containerID,
          coachID: coachID,
          duration: duration,
          name: name,
          phaseOrderIdx: maxOrderIndex + 1,
        },
      });
      console.log("res from add new phase", res?.data?.result);
      let resData = res?.data?.result;
      if (resData) {
        resData.cFProgramPhasesContainerId = containerID;
        console.log("res data", resData);
        let tempSelectedProgram = { ...selectedProgramWithPhases };
        tempSelectedProgram?.programs.push(resData);
        console.log("temp selected program", tempSelectedProgram);
        setSelectedProgram(tempSelectedProgram);
        setSelectedProgramData(tempSelectedProgram);
        setSelectedProgramWithPhases(tempSelectedProgram);
        setOpenPhaseId(tempSelectedProgram?.id);
      }
    } catch (err) {
      console.log("Error while adding phase", err);
    } finally {
      setIsNewPhaseLoading(false);
    }
  };

  const saveProgram = async () => {
    setIsLoading(true);
    // if update then update else save

    try {
      if (
        originalWeeks !== -1 &&
        !selectedProgramData?.isNew &&
        programSubscribers?.length > 0
      ) {
        await transformForUpdates({
          addedWorkouts,
          daysMessages,
          daysTargets,
          selectedProgramData,
          orignialWeeks: originalWeeks,
          originalDay: originalDays,
          row: weeks,
          coachID,
          displayAlert,
          isTemplate: selectedProgramData?.isTemplate,
          isSilent: false,
          getProgramDetails: getProgram,
          setUnsavedChanges,
          saveCurrentStateToLocalStorage,
        });
        setIsLoading(false);
        return;
      }

      let data = {
        coachID: coachID,
        name: selectedProgramData?.name,
        description: selectedProgramData?.description,
        duration: 7 * weeks,
        programID: selectedProgramData?.id,
        isTemplate: selectedProgramData?.isTemplate ? true : false,
        addedWorkouts: addedWorkouts,
        daysMessages: daysMessages,
        daysTargets: daysTargets,
        creationDate: new Date().toISOString(),
        totalWorkouts: totalWorkouts,
      };
      if (
        selectedProgram?.connectedFolder &&
        selectedProgramData?.connectedFolder
      ) {
        (data["folderID"] = selectedProgram?.connectedFolder),
          (data["programIDs"] = [selectedProgram?.id]);
        const res = await saveProgramDataAndConnectToFolder(data);
        if (res) {
          delete selectedProgram?.connectedFolder;
          delete selectedProgramData?.connectedFolder;
        }
      }
      const res = await saveProgramData(data);
      if (res?.status == 200) {
        try {
          if (selectedProgramData?.isTemplate) {
            setOriginalState((prev) => {
              return {
                ...prev,
                templates: [data, ...prev?.templates],
              };
            });
          } else {
            setOriginalState((prev) => {
              return {
                ...prev,
                programs: [data, ...prev?.programs],
              };
            });
          }

          displayAlert({
            message: "Program saved successfully",
            type: "success",
          });
        } catch (err) {
          saveCurrentStateToLocalStorage();
          console.log(
            "There is some error while connecting a Program with phases",
            err
          );
        } finally {
          setIsLoading(false);
        }

        setUnsavedChanges(false);

        // if is Template, update the template list
        if (selectedProgramData?.isTemplate) {
          let updatedTemplates = templates.map((template) => {
            if (template.id === selectedProgramData?.id) {
              return { ...template, ...data };
            }
            return template;
          });
          setTemplates(updatedTemplates);
        } else {
          let updatedPrograms = programs.map((program) => {
            if (program.id === selectedProgramData?.id) {
              return { ...program, ...data };
            }
            return program;
          });
          setPrograms(updatedPrograms);
        }

        await getProgram(selectedProgramData?.id);
        setIsLoading(false);
        // setSelectedProgramData({ ...selectedProgramData, ...data });

        // getProgramsData();
      } else {
        displayAlert({
          message: "Failed to save program",
          type: "error",
        });
      }

      return res;
    } catch (err) {
      setIsLoading(false);
      console.log("Error while saving the program", err);
      displayAlert({
        message: "Failed to save program",
        type: "error",
      });
    }
  };

  const createNewFolder = async (name, description) => {
    setIsLoading(true);
    let data = {
      name,
      coachID,
      description,
      isTemplate: selectedTab === "templates",
    };

    try {
      let res = await createFolder(data);
      if (res) {
        let newFolder = { ...res };
        let updatedFolders = [newFolder, ...folders];
        console.log("tihs is for check isTemplate", newFolder);
        if (newFolder?.isTemplate) {
          setTemplateFolders([newFolder, ...templateFolders]);
          setOriginalState((prev) => {
            return {
              ...prev,
              templateFolders: [newFolder, ...templateFolders],
            };
          });
        } else {
          setFolders(updatedFolders);
          setOriginalState((prev) => {
            return {
              ...prev,
              folders: updatedFolders,
            };
          });
        }
        setFolderSelected(newFolder);
        setOpenFolderId("");
        setSelectedProgram({});
        setOpenPhaseId(null);
        setSelectedProgramData({});
        setSelectedProgramWithPhases(null);
        displayAlert({
          message: "Folder Added successfully",
          type: "success",
        });
        setIsLoading(false);
        return res;
      }
    } catch (error) {
      displayAlert({
        message: "There is some error",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getAllTheFolders = async () => {
    setIsLoading(true);

    try {
      let result = await getAllFolder(coachID, adminID);
      if (result) {
        const templateFolderData = result?.filter((folder) => {
          if (folder?.isTemplate) return folder;
        });
        const ProgramFolderData = result?.filter(
          (folder) =>
            folder?.isTemplate === false &&
            (isLivezy ? folder?.coachID === coachID : true)
        );

        setFolders([...ProgramFolderData]);
        setTemplateFolders([...templateFolderData]);
        if (ProgramFolderData?.length > 0) {
          setFolderSelected(ProgramFolderData?.[0]);
        }
        setIsLoading(false);
        setOriginalState((prev) => {
          return {
            ...prev,
            folders: [...ProgramFolderData],
            templateFolders: [...templateFolderData],
          };
        });

        return {
          folders: ProgramFolderData,
          templateFolders: templateFolderData,
        };

        return ProgramFolderData;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const moveItemToFolder = async (payload) => {
    setIsLoading(true);
    try {
      let res = await connectItemToFolder(payload);
      if (res) {
        displayAlert({
          message: "Item Is  Added To Folder Successfully",
          type: "success",
        });
        setDialogToSelectFolder(false);
        return true;
      }
    } catch (error) {
      displayAlert({
        message: "There is some error",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const updateFolder = async (data) => {
    setIsLoading(true);
    try {
      let res = await updateFolderDetails(data);
      if (res) {
        displayAlert({
          message: "Folder update successfully",
          type: "success",
        });
        setIsLoading(false);

        return true;
      }
    } catch (error) {
      displayAlert({
        message: "There is some error In Updating",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const folderDelete = async (folderID) => {
    setIsLoading(true);
    let data = {
      id: folderID,
      coachID: coachID,
    };

    try {
      let res = await deleteFolder(data);
      if (res) {
        displayAlert({
          message: "Folder delete successfully",
          type: "success",
        });
        console.log("this is the original state", originalState);
        if (selectedTab === "templates") {
          setTemplateFolders((prev) => {
            let data = [...prev];
            let newFolderData = data?.filter(
              (folder) => folder.id !== folderID
            );
            return newFolderData;
          });

          setOriginalState((prev) => {
            let prevState = { ...prev };
            if (prevState?.templateFolders) {
              let data = [...prevState?.templateFolders];
              let newFolderData = data?.filter(
                (folder) => folder.id !== folderID
              );
              prevState.templateFolders = newFolderData;
            }
            return prevState;
          });
          if (templateFolders?.length > 0) {
            setFolderSelected((prev) => {
              if (prev.id === templateFolders?.[0]?.id) {
                return templateFolders?.[1];
              } else {
                return templateFolders?.[0];
              }
            });
          } else {
            setFolderSelected({});
          }
        } else {
          setFolders((prev) => {
            let data = [...prev];
            let newFolderData = data?.filter(
              (folder) => folder.id !== folderID
            );
            return newFolderData;
          });

          setOriginalState((prev) => {
            let prevState = { ...prev };
            if (prevState?.folders) {
              let data = [...prevState?.folders];
              let newFolderData = data?.filter(
                (folder) => folder.id !== folderID
              );
              prevState.folders = newFolderData;
            }
            return prevState;
          });

          if (folders?.length > 0) {
            setFolderSelected((prev) => {
              if (prev.id === folders?.[0]?.id) {
                return folders?.[1];
              } else {
                return folders?.[0];
              }
            });
          } else {
            setFolderSelected({});
          }
        }

        setIsLoading(false);
      }
    } catch (error) {
      displayAlert({
        message: "There is some error In Updating",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const deleteItemFromTheFolder = async (data) => {
    setIsLoading(true);
    try {
      let res = await disconnectItemFromFolder(data);
      if (res) {
        displayAlert({
          message: "Removed From the Folder successfully",
          type: "success",
        });
        setIsLoading(false);
        console.log("this is for the originalState", originalState);
        return true;
      }
    } catch (error) {
      displayAlert({
        message: "There is some error In  removing from the Folder",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const transferFromFolder = async (sourceFolderID, targetFolderID) => {
    setIsLoading(true);
    let data = {
      sourceFolderID: sourceFolderID,
      targetFolderID: targetFolderID,
    };
    if (itemToBeShift?.programs) {
      data["containerIDs"] = [itemToBeShift?.id];
    } else {
      data["programIDs"] = [itemToBeShift?.id];
    }
    console.log("this is for the checking shifting from the folder", data);
    try {
      let res = await shiftingFromFolder(data);

      if (res) {
        displayAlert({
          message: "Folder Transfered successfully",
          type: "success",
        });
        if (selectedTab === "templates") {
          setTemplateFolders((oldFolders) => {
            let folderData = [...oldFolders];
            const newFolderData = folderData.map((folder) => {
              if (folder?.id === sourceFolderID) {
                const data = { ...folder };
                if (itemToBeShift?.programs) {
                  let newData = data.containers.filter(
                    (phase) => phase.id !== itemToBeShift?.id
                  );
                  data.containers = newData;
                } else {
                  let newData = data.programs.filter(
                    (program) => program.id !== itemToBeShift?.id
                  );
                  data.programs = newData;
                }
                return data;
              } else if (folder?.id === targetFolderID) {
                if (itemToBeShift?.programs) {
                  if (folder?.containers) {
                    folder.containers.push(itemToBeShift);
                  } else {
                    folder["containers"] = [itemToBeShift];
                  }
                } else {
                  if (folder?.programs) {
                    folder.programs.push(itemToBeShift);
                  } else {
                    folder["programs"] = [itemToBeShift];
                  }
                }
                return folder;
              }
              return folder;
            });

            setOriginalState((prevState) => {
              return {
                ...prevState,
                templateFolders: newFolderData,
              };
            });
            return newFolderData;
          });
        } else {
          setFolders((oldFolders) => {
            let folderData = [...oldFolders];
            const newFolderData = folderData.map((folder) => {
              if (folder?.id === sourceFolderID) {
                const data = { ...folder };
                if (itemToBeShift?.programs) {
                  let newData = data.containers.filter(
                    (phase) => phase.id !== itemToBeShift?.id
                  );
                  data.containers = newData;
                } else {
                  let newData = data.programs.filter(
                    (program) => program.id !== itemToBeShift?.id
                  );
                  data.programs = newData;
                }
                return data;
              } else if (folder?.id === targetFolderID) {
                if (itemToBeShift?.programs) {
                  if (folder?.containers) {
                    folder.containers.push(itemToBeShift);
                  } else {
                    folder["containers"] = [itemToBeShift];
                  }
                } else {
                  if (folder?.programs) {
                    folder.programs.push(itemToBeShift);
                  } else {
                    folder["programs"] = [itemToBeShift];
                  }
                }
                return folder;
              }
              return folder;
            });

            setOriginalState((prevState) => {
              return {
                ...prevState,
                folders: newFolderData,
              };
            });

            return newFolderData;
          });
        }

        setIsShifting("");
        setFolderSelected({});
        setIsLoading(false);
        return true;
      }
    } catch (error) {
      displayAlert({
        message: "Phase/program is already present in folder Or There is error",
        type: "error",
      });
    } finally {
      setIsShifting("");
      setIsLoading(false);
    }
  };

  const onDiscard = () => {
    setUnsavedChanges(false);
    setShowConfirmDialog(false);
    setSelectedProgram(tempData);
    getProgram(tempData?.id);
    getSubscribers(tempData?.id);
    getProgramAssignedChannels(tempData?.id);
  };

  const onContinue = () => {
    setShowConfirmDialog(false);
  };

  const onSave = async () => {
    setShowConfirmDialog(false);
    await saveProgram();

    setSelectedProgram(tempData);
    getProgram(tempData?.id);
    getSubscribers(tempData?.id);
    getProgramAssignedChannels(tempData?.id);
  };

  const history = useHistory();

  const saveCurrentStateToLocalStorage = () => {
    let data = {
      selectedProgramWithPhases,
      selectedProgram,
      workoutDay: selectedCard,
      addedWorkouts,
      daysMessages,
      daysTargets,
      weeks,
      daysArray,
      weeksArray,
      programSubscribers,
      programChannels,
    };
    localStorage.setItem("unsavedPrograms", JSON.stringify(data));
  };

  const retriveProgramDataFromLocalStorage = async () => {
    try {
      let data = JSON.parse(localStorage.getItem("unsavedPrograms"));
      if (data) {
        setSelectedProgram(data?.selectedProgram);
        setSelectedProgramData(data?.selectedProgram);
        if (!data?.selectedProgram?.isNew) {
          await getProgram(data?.selectedProgram?.id);
        } else {
          // add to the programs list or templates list
          if (data?.selectedProgram?.isTemplate) {
            let updatedTemplates = [data?.selectedProgram, ...templates];
            setTemplates(updatedTemplates);
          } else {
            let updatedPrograms = [data?.selectedProgram, ...programs];
            setPrograms(updatedPrograms);
          }
        }
        getProgramSubscribers(data?.selectedProgram?.id);
        getProgramAssignedChannels(data?.selectedProgram?.id);

        setProgramSubscribers(data?.programSubscribers);
        setProgramChannels(data?.programChannels);
        // add 1 second delay
        let timeout = setTimeout(() => {
          setSelectedCard(data?.workoutDay);
          setAddedWorkouts(data?.addedWorkouts);
          setDaysMessages(data?.daysMessages);
          setDaysTargets(data?.daysTargets);
          setWeeks(data?.weeks);
          setDaysArray(data?.daysArray);
          setWeeksArray(data?.weeksArray);

          setUnsavedProgram(null);
          setUnsavedChanges(true);
          localStorage.removeItem("unsavedPrograms");
          if (data.selectedProgramWithPhases) {
            setSelectedProgramWithPhases(data.selectedProgramWithPhases);
            setOpenPhaseId(data.selectedProgramWithPhases?.id);
          } else {
            setSelectedProgramWithPhases(null);
            setOpenPhaseId(null);
          }
          clearTimeout(timeout);
        }, 1000);
      }
    } catch (e) {}
  };

  const importPhaseToPhaseContainer = async ({
    phaseID,
    isTemplate,
    phaseOrderIdx,
    phaseContainerID,
  }) => {
    //  programID, isTemplate, phaseOrderIdx, phaseContainerID
    setIsLoading(true);
    try {
      let res = await importPhaseToContainer({
        programID: phaseID,
        isTemplate,
        phaseOrderIdx,
        phaseContainerID,
        coachID,
      });
      if (res) {
        let result = res.data.result;
        if (result?.isTemplate) {
          let programFolderId = result?.programFolderId;
          if (programFolderId) {
            let updatedTemplateFolders = templateFolders.map((folder) => {
              if (folder.id === programFolderId) {
                let updatedContainers = folder.containers.map((container) => {
                  if (container.id === result.id) {
                    return result;
                  }
                  return container;
                });
                return { ...folder, containers: updatedContainers };
              }
              return folder;
            });

            setTemplateFolders(updatedTemplateFolders);
          } else {
            let updatedTemplates = templates.map((template) => {
              if (template.id === result.id) {
                return result;
              }
              return template;
            });
            setTemplates(updatedTemplates);
          }
        } else {
          let programFolderId = result?.programFolderId;
          if (programFolderId) {
            let updatedFolders = folders.map((folder) => {
              if (folder.id === programFolderId) {
                let updatedContainers = folder.containers.map((container) => {
                  if (container.id === result.id) {
                    return result;
                  }
                  return container;
                });
                return { ...folder, containers: updatedContainers };
              }
              return folder;
            });

            setFolders(updatedFolders);
          } else {
            // update the program with result.id
            let updatedPrograms = programs.map((program) => {
              if (program.id === result.id) {
                return result;
              }
              return program;
            });
            setPrograms(updatedPrograms);
          }
        }
        setSelectedProgram(result);
        setSelectedProgramData(result);
        setSelectedProgramWithPhases(result);
        displayAlert({
          message: "Phase imported successfully",
          type: "success",
        });
      }
    } catch (error) {
      displayAlert({
        message: "There is some error In Updating",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const duplicatePhasedContainer = async (
    containerID,
    name,
    isTemplate,
    description
  ) => {
    setIsLoading(true);
    try {
      let res = await duplicatePhasedProgram({
        containerID,
        coachID,
        isTemplate: isTemplate,
        name,
        description,
      });

      if (res?.data?.result) {
        setFolderSelected({});
        setOpenFolderId("");
        let updatedPrograms = [];
        if (isTemplate) {
          updatedPrograms = [res?.data?.result, ...templates];
          setTemplates(updatedPrograms);
          // update original state
          let updatedOriginalTemplates = [
            res?.data?.result,
            ...originalState?.templates,
          ];
          setOriginalState((prev) => {
            return {
              ...prev,
              templates: updatedOriginalTemplates,
            };
          });
        } else {
          updatedPrograms = [res?.data?.result, ...programs];
          setPrograms(updatedPrograms);

          // update original state
          let updatedOriginalPrograms = [
            res?.data?.result,
            ...originalState?.programs,
          ];
          setOriginalState((prev) => {
            return {
              ...prev,
              programs: updatedOriginalPrograms,
            };
          });
        }

        // if is template and selected tab is not template then go to templates and vice versa
        if (isTemplate && selectedTab !== "templates") {
          setSelectedTab("templates");
        } else if (!isTemplate && selectedTab !== "programs") {
          setSelectedTab("programs");
        }

        let timeout = setTimeout(() => {
          // set is as selected program
          setSelectedProgram(updatedPrograms[0]);
          setSelectedProgramData(updatedPrograms[0]);
          setSelectedProgramWithPhases(updatedPrograms[0]);
          setOpenPhaseId(updatedPrograms[0]?.id);
          setFolderSelected({});

          // scroll to the selected program
          let element = document.getElementById(updatedPrograms[0]?.id);
          element.scrollIntoView({ behavior: "smooth" });
          clearTimeout(timeout);
        }, 100);

        displayAlert({
          message: "Program duplicated successfully",
          type: "success",
        });
      }
    } catch (err) {
      displayAlert({
        message: "Failed to duplicate program",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onResumeEditing = () => {
    retriveProgramDataFromLocalStorage();
  };

  const prefetchExercises = () => {
    let timeout = setTimeout(() => {
      preftechGlobalExercise({ coachID, APIURL });
      clearTimeout(timeout);
    }, 0);
  };

  // useEffects
  useEffect(() => {
    const controller = new AbortController();
    getPrograms(controller.signal);
    getWorkouts();
    prefetchExercises();
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      if (unsavedChanges) {
        saveCurrentStateToLocalStorage();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    const unblock = history.block((location, action) => {
      handleBeforeUnload();
      return true;
    });

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      unblock();
    };
  }, [unsavedChanges, addedWorkouts, daysMessages, daysTargets]);

  return (
    <ProgramsContext.Provider
      value={{
        coachID,
        adminID,
        isAdmin,
        programs,
        setPrograms,
        folders,
        setFolders,
        templates,
        subbordinateCoachPrograms,
        loadingPrograms,
        isProgramsListOpen,
        setIsProgramsListOpen,
        selectedProgram,
        setSelectedProgram,
        onProgramSelect,
        selectedProgramData,
        onSearch,
        onSearchCancel,
        loadingSelectedProgram,
        daysMessages,
        daysTargets,
        addedWorkouts,
        totalWorkouts,
        daysArray,
        weeksArray,
        weeks,
        unsavedChanges,
        setWeeks,
        setAddedWorkouts,
        setDaysMessages,
        setDaysTargets,
        setTotalWorkouts,
        setDaysArray,
        setWeeksArray,
        setOriginalDays,
        setOriginalWeeks,
        setUnsavedChanges,
        workoutList,
        setWorkoutList,
        showAddWorkoutModal,
        setShowAddWorkoutModal,
        selectedCard,
        setSelectedCard,
        addMenuOpen,
        setAddMenuOpen,
        showAutoMessageDialog,
        setShowAutoMessageDialog,
        showAddActivityModal,
        setShowAddActivityModal,
        saveProgramDetails,
        isTemplate,
        setIsTemplate,
        showAddCardio,
        setShowAddCardio,
        saveProgramOnEditWorkout,
        showWorkout,
        setShowWorkout,
        selectedToShow,
        setSelectedToShow,
        programSubscribers,
        setProgramSubscribers,
        programChannels,
        setProgramChannels,
        isLoading,
        setIsLoading,
        deleteProgramByID,
        duplicateAsTemplate,
        setDuplicateAsTemplate,
        originalDays,
        originalWeeks,
        copiedDay,
        setCopiedDay,
        selectedTab,
        setSelectedTab,
        subbordinateCoachesToBeDisplayed,
        copiedWorkout,
        setCopiedWorkout,
        subbordinateCoachesNames,
        setFolderSelected,
        folderSelected,
        setSelectedProgramData,
        openPhaseId,
        setOpenPhaseId,
        openFolderId,
        setOpenFolderId,
        handleFilter,
        handlePaste,
        getSubscribers,
        duplicateOneProgram,
        deleteOneWeek,
        duplicateOneWeek,
        handleDeleteLastWeekProgramWithSubscribers,
        updateProgramInformation,
        updateContainerInfo,
        addProgram,
        saveProgram,
        handlePasteOneWorkout,
        handleReplaceOneWorkout,
        deleteOneWorkoutFromADay,
        pastedData,
        setPastedData,
        selectedProgramWithPhases,
        setSelectedProgramWithPhases,
        addNewPhase,
        setIsNewPhaseLoading,
        isNewPhaseLoading,
        deleteProgramWithPhases,
        setShowDuplicateProgramModal,
        showDuplicateProgramModal,
        duplicateData,
        setDuplicateData,

        copiedWeekNo,
        setCopiedWeekNo,
        handlePasteOneWeek,
        onFolderSelected,
        setDialogToSelectFolder,
        dialogToSelectFolder,
        createNewFolder,
        getAllTheFolders,
        moveItemToFolder,
        updateFolder,
        folderDelete,
        deleteItemFromTheFolder,
        transferFromFolder,
        setIsShifting,
        isShifting,
        setItemToBeShift,
        itemToBeShift,

        importPhaseToPhaseContainer,
        duplicatePhasedContainer,
        setTemplateFolders,
        templateFolders,
        setTemplates,
        setShowConfirmDialog,
        showConfirmDialog,
        setOriginalState,
        originalState,
        adminID,
        subbordinateCoachFolders,
      }}
    >
      {(isLoading || loadingPrograms) && (
        <div className="fixed top-0 left-0 w-full h-full bg-black-pure bg-opacity-50 z-[99999] flex items-center justify-center">
          <SpurfitCircularProgress />
        </div>
      )}
      <ConfirmDialog
        isOpen={showConfirmDialog}
        onContinue={onContinue}
        onDiscard={onDiscard}
        onSave={onSave}
      />
      {unsavedProgram?.selectedProgram?.name && (
        <div className="flex w-full bg-red-300 mx-1 px-3 mb-1 rounded-md font-DMSans">
          Unsaved changes in &nbsp;
          <span className="font-semibold">
            {unsavedProgram?.selectedProgram?.name}
          </span>
          &nbsp; program
          <div className="ml-auto gap-4 flex items-center">
            <button
              className="font-DMSans font-semibold"
              onClick={onResumeEditing}
            >
              Continue
            </button>
            <button
              className="font-DMSans font-semibold"
              onClick={() => {
                setUnsavedProgram(null);
                localStorage.removeItem("unsavedPrograms");
              }}
            >
              Discard
            </button>
          </div>
        </div>
      )}
      {children}
    </ProgramsContext.Provider>
  );
};
