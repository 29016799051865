import AddUserIcon from "@/pages/library/assests/AddUserIcon";
import { chatClient } from "@/utils/chat";
import { CirclePlus, MoreHorizontal, MoreVertical } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import { Chat, enTranslations, Streami18n } from "stream-chat-react";
import DropDownMenu from "./DropDownMenu";
import EditProgramInfo from "./EditProgramInfo";
import { ProgramsContext } from "./ProgramsContext";
import AssignPhasesToUserModal from "./utils/AssignPhasesToUserModal";
import AddNotesDialogeForPrograms from "./AddNotesDialogeForPrograms";
import FolderToSelectDialog from "./FolderToSelectDialog";
import DuplicateProgramsDialog from "./DuplicateProgramsDialog";
import { useSelector } from "react-redux";
import MenuOptions from "../Workouts/MenuOptions";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import Delete from "../../assets/workouts/DeleteIcon.svg";

const i18nInstance = new Streami18n({
  language: "en",
  translationsForLanguage: {
    ...enTranslations,
  },
});

const ProgramPhaseUi = ({
  isProgramsListOpen,
  isAdminProgram,
  setShowAssignedUsersDialog,
}) => {
  const {
    selectedProgramData,
    selectedProgram,
    setPrograms,
    deleteProgramWithPhases,
    onProgramSelect,
    updateContainerInfo,
    setDialogToSelectFolder,
    dialogToSelectFolder,
    coachID,
    duplicatePhasedContainer,
    folders,
    templateFolders,
    programSubscribers,
    setSelectedProgram,
    selectedTab,
    getSubscribers,
    originalState,
  } = useContext(ProgramsContext);
  const buttonStyle = {
    fontWeight: 600,
    color: "#FFFFFF",
    borderRadius: "12px",
    padding: "4px 9px",
    cursor: "pointer",
    marginBottom: "7px",
    background: "linear-gradient(90deg, #464feb 10.42%, #8330e9 100%)",
  };

  const isLivezy = useSelector((state) => state.auth.isLivezy);

  const [sortedPrograms, setSortedPrograms] = useState([]);
  const [openNotesDialoge, setOpenNotesDialoge] = useState(false);
  const compareSelectedProgramsPhases = (a, b) => {
    if (a?.phaseOrderIdx && b?.phaseOrderIdx) {
      return a?.phaseOrderIdx - b?.phaseOrderIdx;
    }

    const phaseA = parseInt(a?.name?.split(" ")[1]);
    const phaseB = parseInt(b?.name?.split(" ")[1]);
    return phaseA - phaseB;
  };

  const [showAssignModal, setShowAssignModal] = useState(false);
  const [showDuplicateProgramModal, setShowDuplicateProgramModal] =
    useState(false);
  const [copyAsTemplate, setCopyAsTemplate] = useState(false);
  const [duplicateTitle, setDuplicateTitle] = useState(
    "Create Duplicate Program"
  );

  useEffect(() => {
    console.log("program phase ui", selectedProgram);
    let sortedPrograms = [...selectedProgram?.programs]?.sort(
      compareSelectedProgramsPhases
    );
    setSortedPrograms(sortedPrograms);
    setPrograms((prev) => {
      return prev?.map((item) => {
        if (item?.id === selectedProgram?.id) {
          return {
            ...item,
            programs: sortedPrograms,
          };
        } else {
          return item;
        }
      });
    });
  }, [selectedProgram]);


  let programOptions=[
    "Duplicate Program",
    "Delete program",
    (selectedProgram?.isTemplate ? ["Copy to program"] : null),
    typeof selectedProgram?.programFolderId !== "string" ?
      "Add To Folder" : null,
  ].filter(Boolean)

  let programOptionIcon=
    [
      <ContentCopyRoundedIcon />,
    <img src={Delete} alt="Delete" />,
    (selectedProgram?.isTemplate ?  <ContentCopyRoundedIcon /> : null ),
    (typeof selectedProgram?.programFolderId !== "string" ? <CirclePlus/> : null)].filter(Boolean)
  
  // useEffect(() => {
  //   setSelectedProgram({});
  //    setSelectedProgramData({})
  // }, [selectedTab==="folders"]);
  return (
    <div
      style={{
        width: isProgramsListOpen ? "75%" : "100%",
      }}
      // transition smooth
      className="h-full transition-all duration-300 ease-in-out"
    >
      <div className="w-full h-[52px] flex items-center justify-between px-3 border-b">
        <div className="flex items-center gap-3">
          <p className="font-DMSans text-font16">{selectedProgram?.name}</p>
          {!isAdminProgram && (
            <EditProgramInfo
              selectedProgramData={selectedProgram}
              updateProgramInformation={updateContainerInfo}
              isProgramPhase={true}
            />
          )}
        </div>
        {selectedProgram?.coachID === coachID && (
          <div className="flex items-center gap-4">
            {programSubscribers?.length > 0 && (
              <div className="flex items-center">
                <button
                  className="font-DMSans text-font14 font-[500]"
                  onClick={() => {
                    if (programSubscribers.length > 0) {
                      setShowAssignedUsersDialog(true);
                    }
                  }}
                >
                  Subscribers
                </button>
              </div>
            )}
            <p
              style={{
                fontFamily: "DM Sans",
                fontWeight: "500",
                fontSize: "14px",
                color: "#424242",
                cursor: "pointer",
                display: "flex",
                alignItems: "flex-end",
              }}
              onClick={() => setOpenNotesDialoge(true)}
            >
              Notes{" "}
              {selectedProgram?.notes?.length > 0 ? (
                <span className="ml-1 w-[22px] text-center text-[13px] font-[400] bg-gray-300 rounded-full">
                  {selectedProgram?.notes?.length}
                </span>
              ) : (
                ""
              )}
            </p>
            
              <div
                onClick={() => {
                  setShowAssignModal(true);
                }}
                className="cursor-pointer"
              >
                <AddUserIcon className="h-[18px] w-[18px]" />
              </div>
            
            {showAssignModal && (
              <Chat {...{ client: chatClient, i18nInstance }}>
                <AssignPhasesToUserModal
                  showModal={showAssignModal}
                  setShowModal={setShowAssignModal}
                  data={selectedProgram}
                  key={selectedProgram?.id}
                  getSubscribers={getSubscribers}
                />
              </Chat>
            )}

            <MenuOptions
              isHoriz={false}
              className={"mr-3"}
              options={programOptions}
              optionIcons={programOptionIcon}
              
              onOptionClick={(option) => {
                if (option === "Duplicate Program") {
                  setShowDuplicateProgramModal(true);
                  setCopyAsTemplate(selectedProgram?.isTemplate);
                  setDuplicateTitle("Create Duplicate Program");
                } else if (option === "Delete program") {
                  deleteProgramWithPhases(selectedProgramData?.id);
                } else if (option === "Add To Folder") {
                  setDialogToSelectFolder(true);
                } else if (option === "Copy to program") {
                  setShowDuplicateProgramModal(true);
                  setCopyAsTemplate(false);
                  setDuplicateTitle("Copy Program to Program");
                }
              }}
              iconBtnStyles={{
                borderRadius: "8px",
              }}
            />
          </div>
        )}
        {selectedProgram?.coachID !== coachID &&
          selectedProgram?.isTemplate && (
            <MenuOptions
            isHoriz={false}
            className={"mr-3"}
            options={ ["Copy to program"]}
            optionIcons={
              [<ContentCopyRoundedIcon />]
             }
            
            onOptionClick={(option) => {
              if (option === "Copy to program") {
                setShowDuplicateProgramModal(true);
                setCopyAsTemplate(false);
                setDuplicateTitle("Copy Program to Program");
              }
            }}
            iconBtnStyles={{
              borderRadius: "8px",
            }}
          />
          )}
      </div>
      {openNotesDialoge && (
        <AddNotesDialogeForPrograms
          open={openNotesDialoge}
          setIsOpen={setOpenNotesDialoge}
          tittle={"Notes"}
          selectedProgramData={selectedProgram}
          updateProgramInformation={updateContainerInfo}
          isNotesForProgramsPhase={true}
        />
      )}
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "grid",
          flexDirection: "column",
          justifyContent: "center",
          gridTemplateColumns: "48% 48%",
          padding: "20px 10px",
          gap: "2%",
        }}
      >
        {sortedPrograms?.map((item, index) => {
          let totalWeeks = Math.ceil(item?.duration / 7);

          return (
            <div
              key={item?.id}
              className="program-conatiner-body-details-card mt-3"
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <p
                  className="program-conatiner-body-details-card-title"
                  style={{
                    marginBottom: "7px",
                  }}
                >
                  {totalWeeks} Week{totalWeeks > 1 ? "s" : ""}
                </p>
                <p className="program-conatiner-body-details-card-description">
                  {item?.name}
                </p>
              </div>
              <div
                className="flex flex-col items-end cursor-pointer"
                onClick={() => {
                  onProgramSelect(item);
                }}
              >
                <p
                  className="program-conatiner-body-details-card-title"
                  style={buttonStyle}
                >
                  Open
                </p>
                <p className="program-conatiner-body-details-card-description">
                  {totalWeeks} Week
                  {totalWeeks > 1 ? "s" : ""}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      {dialogToSelectFolder && (
        <FolderToSelectDialog
          open={dialogToSelectFolder}
          setOpen={setDialogToSelectFolder}
          selectedProgram={selectedProgram}
          folders={
            selectedTab === "templates"
              ? originalState?.templateFolders
              : originalState?.folders
          }
        />
      )}

      {showDuplicateProgramModal && (
        <DuplicateProgramsDialog
          isOpen={showDuplicateProgramModal}
          setIsOpen={setShowDuplicateProgramModal}
          selectedProgramData={selectedProgram}
          title={duplicateTitle}
          duplicateOneProgram={(
            id,
            name,
            description,
            coachID,
            containerID
          ) => {
            duplicatePhasedContainer(
              selectedProgram?.id,
              name,
              copyAsTemplate,
              description
            );
          }}
        />
      )}
    </div>
  );
};

export default ProgramPhaseUi;
