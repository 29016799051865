import React, { useCallback, useContext, useEffect, useState } from "react";
import { FileUploadButton, ImageDropzone } from "react-file-utils";
import {
  ChatAutoComplete,
  UploadsPreview,
  useChannelStateContext,
  useChatContext,
  useMessageContext,
  useMessageInputContext,
} from "stream-chat-react";

import MicNoneRoundedIcon from "@mui/icons-material/MicNoneRounded";
import "audio-react-recorder/dist/index.css";
import "./TeamMessageInput.css";

import { TeamTypingIndicator } from "../TeamTypingIndicator/TeamTypingIndicator";

import { GiphyContext } from "../ChannelContainer/ChannelInner";

import AudioReactRecorder, { RecordState } from "audio-react-recorder";
import {
  BoldIcon,
  ItalicsIcon,
  LightningBoltSmall,
  SendButton,
  SmileyFace,
  StrikeThroughIcon,
} from "../../assets";

import { StopRounded } from "@mui/icons-material";
import EmojiPicker from "emoji-picker-react";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
import TemplateSuggestions from "./TemplateSuggestions";

export const TeamMessageInput = (props) => {
  const oneTrainerData = useSelector((state) => state.auth.data);
  const coachId = useSelector((state) => state.auth.trainerUUID);
  const gymAffiliation = useSelector(
    (state) => state.auth?.data?.gymAffliation
  );

  const isLivezy = gymAffiliation === "Livezy";
  const { pinsOpen } = props;

  const { giphyState, setGiphyState } = useContext(GiphyContext);

  const { acceptedFiles, channel, maxNumberOfFiles, multipleUploads, thread } =
    useChannelStateContext();
  const { client } = useChatContext();
  const { updateMessage } = useMessageContext();

  const [boldState, setBoldState] = useState(false);
  const [codeState, setCodeState] = useState(false);
  const [italicState, setItalicState] = useState(false);
  const [strikeThroughState, setStrikeThroughState] = useState(false);
  const [isRecording, setIsRecording] = useState(RecordState.NONE);
  const [isRecordingCompleted, setIsRecordingCompleted] = useState(true);
  const [audioData, setAudioData] = useState("");
  const [audioUrlData, setAudioUrlData] = useState("");
  const [timer, setTimer] = useState("00:00");
  const [timerInterval, setTimerInterval] = useState(null);
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);

  const [showTemplateSuggestions, setShowTemplateSuggestions] = useState(false);
  const reduxTemplates = useSelector((state) => state.auth.templates);
  const [templates, setTemplates] = useState(reduxTemplates);
  const [suggestions, setSuggestions] = useState(reduxTemplates);

  let audioMessage = {};

  const startTimer = () => {
    let sec = 0;
    let min = 0;
    let timer = setInterval(() => {
      sec++;
      if (sec === 60) {
        min++;
        sec = 0;
      }
      setTimer(`${min < 10 ? `0${min}` : min}:${sec < 10 ? `0${sec}` : sec} `);
    }, 1000);
    setTimerInterval(timer);
    return timer;
  };

  const stopTimer = () => {
    clearInterval(timerInterval);
    setTimer("00:00");
  };

  const messageInput = useMessageInputContext();
  const startRecording = () => {
    setIsRecording(RecordState.START);
    setIsRecordingCompleted(false);
    startTimer();
  };

  const stopRecording = () => {
    setIsRecording(RecordState.STOP);
    stopTimer();
  };

  // const getAllTemplates = async () => {
  //   let idToBeSended;
  //   if (oneTrainerData?.isAdmin) {
  //     console.log("is admin");
  //     if (coachId) idToBeSended = coachId;
  //   } else if (oneTrainerData?.adminID) {
  //     console.log("admin id", oneTrainerData.adminID);
  //     idToBeSended = oneTrainerData?.adminID;
  //   } else {
  //     return;
  //   }
  //   let res = await getAllMessageTemplate(idToBeSended);
  //   console.log("getting all templates data", res);
  //   setTemplates(res);
  //   setSuggestions(res);
  // };
  const onData = (recordedData) => {
    console.log("Recording...");
  };

  useEffect(() => {
    console.log(
      "uploading status",
      Object.values(messageInput?.fileUploads || {})[0]?.state
    );
    if (
      Object.values(messageInput?.fileUploads || {})[0]?.state == "finished"
    ) {
      document.getElementById("send-button-stream").click();
    }
  }, [Object.values(messageInput?.fileUploads || {})[0]?.state]);

  useEffect(() => {
    setTemplates(reduxTemplates);
    setSuggestions(reduxTemplates);
  }, [reduxTemplates]);
  const debouncedOnStop = debounce(async (recordedData) => {
    console.log("recordedData is ", recordedData);
    console.log("client ", client.user.created_at);
    console.log("data ", messageInput.numberOfUploads);
    setAudioData(recordedData.url);
    setAudioUrlData(recordedData);
    await handleAudioSending(recordedData?.url, recordedData);
  }, 500); // Set the debounce delay (in milliseconds) as per your requirement

  const onStop = (recordedData) => {
    try {
      debouncedOnStop(recordedData);
    } catch (e) {}
  };
  const handleAudioSending = async (audioData, audioUrlData) => {
    if (audioData !== "") {
      // messageInput.
      setIsRecordingCompleted(true);
      const uri = new File([audioUrlData.blob], "voice.mp3", {
        type: "audio/mpeg",
        lastModified: Date.now(),
      });

      console.log("uri is ", uri);
      let newURI = [];
      newURI.push(uri);
      let uploadedfile = messageInput.uploadNewFiles(newURI);
    }
  };

  const resetIconState = () => {
    setBoldState(false);
    setCodeState(false);
    setItalicState(false);
    setStrikeThroughState(false);
  };

  const getPlaceholder = () => {
    if (channel.type === "team") {
      return `#${channel.data.name || channel.data.id || "random"}`;
    }

    const members = Object.values(channel.state.members).filter(
      ({ user }) => user.id !== client.userID
    );

    if (!members.length || members.length === 1) {
      return members[0]?.user.name || members[0]?.user.id || "Johnny Blaze";
    }

    return "the group";
  };
  const isFunction = (functionToCheck) => {
    return (
      functionToCheck &&
      {}.toString.call(functionToCheck) === "[object Function]"
    );
  };

  const onChange = useCallback(
    (e) => {
      const { value } = e.target;
      const deletePressed =
        e.nativeEvent?.inputType === "deleteContentBackward";
      if (showTemplateSuggestions && value.includes("@")) {
        let filterKeyword = value.split("@")[1];
        console.log("filterKeyword", filterKeyword);
        let filteredSuggestions = templates.filter((template) =>
          template.templateName
            .toLowerCase()
            .includes(filterKeyword.toLowerCase())
        );
        console.log("filteredSuggestions", filteredSuggestions);
        setSuggestions(filteredSuggestions);
      }

      if (
        messageInput.text.length === 1 &&
        deletePressed &&
        isFunction(setGiphyState)
      ) {
        setGiphyState(false);
      }

      if (
        !giphyState &&
        messageInput.text.startsWith("/giphy") &&
        !messageInput.numberOfUploads
      ) {
        e.target.value = value.replace("/giphy", "");
        setGiphyState(true);
      }

      if (boldState) {
        if (deletePressed) {
          e.target.value = `${value.slice(0, value.length - 2)}**`;
        } else {
          e.target.value = `**${value.replace(/\**/g, "")}**`;
        }
      } else if (codeState) {
        if (deletePressed) {
          e.target.value = `${value.slice(0, value.length - 1)}\``;
        } else {
          e.target.value = `\`${value.replace(/`/g, "")}\``;
        }
      } else if (italicState) {
        if (deletePressed) {
          e.target.value = `${value.slice(0, value.length - 1)}*`;
        } else {
          e.target.value = `*${value.replace(/\*/g, "")}*`;
        }
      } else if (strikeThroughState) {
        if (deletePressed) {
          e.target.value = `${value.slice(0, value.length - 2)}~~`;
        } else {
          e.target.value = `~~${value.replace(/~~/g, "")}~~`;
        }
      }

      messageInput.handleChange(e);
    },
    [
      boldState,
      codeState,
      giphyState,
      italicState,
      messageInput,
      setGiphyState,
      strikeThroughState,
    ]
  );

  const GiphyIcon = () => (
    <div className="giphy-icon__wrapper">
      <LightningBoltSmall />
      <p className="giphy-icon__text">GIPHY</p>
    </div>
  );

  return (
    <div
      className={`team-message-input__wrapper ${
        (!!thread || pinsOpen) && "thread-open"
      }`}
    >
      <div style={{ position: "absolute" }}></div>
      <ImageDropzone
        accept={acceptedFiles}
        handleFiles={messageInput.uploadNewFiles}
        multiple={multipleUploads}
        disabled={
          (maxNumberOfFiles !== undefined &&
            messageInput.numberOfUploads >= maxNumberOfFiles) ||
          giphyState
        }
      >
        <div className="team-message-input__input">
          <div className="">
            {/* <AttachmentPreviewList /> */}
            <UploadsPreview />
          </div>
          <div className="team-message-input__top">
            {giphyState && !messageInput.numberOfUploads && <GiphyIcon />}
            <div
              style={{
                maxHeight: "47px !important",
                minHeight: "47px !important",
                display: isRecording === RecordState.START ? "block" : "none",
              }}
            >
              <AudioReactRecorder
                canvasHeight={45}
                // canvasWidth={200}
                backgroundColor="rgb(255,255,255)"
                foregroundColor="#464feb"
                state={isRecording}
                onStop={onStop}
              />
              {/* Render time */}
              <p className="text-sm text-gray-400">{timer}</p>
            </div>

            {isRecording === RecordState.START ? (
              <></>
            ) : isLivezy ? (
              <div
                style={{ padding: 0, border: "none", position: "relative" }}
                className="team-message-input__wrapper str-chat__textarea textarea"
              >
                {showTemplateSuggestions && (
                  <TemplateSuggestions
                    suggestions={suggestions}
                    onTemplateSelect={(template) => {
                      let messageFinal = messageInput.text.split("@")[0];
                      messageInput.setText(
                        messageFinal + " " + template.templateMessage
                      );
                      setShowTemplateSuggestions(false);
                      setSuggestions(templates);
                    }}
                  />
                )}
                <textarea
                  className="rta__textarea str-chat__textarea__textarea str-chat__message-textarea"
                  name="message"
                  onChange={onChange}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      document.getElementById("send-button-stream").click();
                    }
                    // if key is @
                    if (e.key === "@") {
                      // set the state to open
                      setShowTemplateSuggestions(true);
                    }

                    // there can me some character after @ to search for the template
                    // if the character is space then close the template
                    if (setShowTemplateSuggestions && e.key === " ") {
                      setShowTemplateSuggestions(false);
                      setSuggestions(templates);
                    }

                    // delted @
                    if (
                      e.key === "Backspace" &&
                      e.target.value.slice(-1) === "@"
                    ) {
                      // set the state to open
                      setShowTemplateSuggestions(false);
                    }
                  }}
                  placeholder={`Message ${getPlaceholder()}`}
                  rows={3}
                  value={messageInput.text}
                />
              </div>
            ) : (
              <ChatAutoComplete
                onChange={onChange}
                placeholder={`Message ${getPlaceholder()}`}
                rows={3}
                style={{
                  height: "100px",
                }}
              />
            )}
          </div>
          <div className="team-message-input__bottom">
            <div className="team-message-input__icons">
              <div
                onClick={() => {
                  setIsEmojiPickerOpen(!isEmojiPickerOpen);
                }}
              >
                <SmileyFace />
              </div>
              {/* <LightningBolt {...{ giphyState, onCommandClick }} /> */}
              <div className="icon-divider"></div>
              <BoldIcon {...{ boldState, resetIconState, setBoldState }} />
              <ItalicsIcon
                {...{ italicState, resetIconState, setItalicState }}
              />
              <StrikeThroughIcon
                {...{
                  resetIconState,
                  strikeThroughState,
                  setStrikeThroughState,
                }}
              />
              {/* <CodeSnippet {...{ codeState, resetIconState, setCodeState }} /> */}
              <FileUploadButton
                handleFiles={(file) => {
                  messageInput.uploadNewFiles(file);
                }}
              />
              {/* <UploadFileIcon /> */}
              {isRecording === RecordState.START ? (
                <div onClick={stopRecording}>
                  <StopRounded />
                </div>
              ) : (
                <div onClick={startRecording}>
                  <MicNoneRoundedIcon fontSize={"medium"} />
                </div>
              )}
            </div>
            <div
              id="send-button-stream"
              className="team-message-input__button-gradient"
              role="button"
              aria-roledescription="button"
              onClick={(e) => {
                // handleAudioSending();
                messageInput.handleSubmit(e);
                setAudioData("");
              }}
            >
              <p>Send</p>
              <SendButton />
            </div>
          </div>
        </div>
      </ImageDropzone>
      <TeamTypingIndicator type="input" />
      {isEmojiPickerOpen && (
        <EmojiPicker
          style={{
            height: 400,
            position: "absolute",
            left: "30px",
            zIndex: 9999,
            bottom: "95%",
          }}
          onEmojiClick={(emoji, event) => {
            messageInput.insertText(" " + emoji.emoji);
            messageInput.textareaRef.current?.focus(); // returns focus back to the message input element
            setIsEmojiPickerOpen(false);
          }}
        />
      )}
    </div>
  );
};
