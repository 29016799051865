import { Dialog, DialogContent } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Chart } from "chart.js/auto";
import CloseIcon from "@mui/icons-material/Close";
import { Line } from "react-chartjs-2";
import moment from "moment";

const AllMeasurementsChart = ({ isOpen, setIsOpen, data }) => {
  const [allMeasurementsChartData, setAllMeasurementsChartData] = useState({});
  const [currentMonth, setCurrentMonth] = useState(moment().format("MMM 'YY"));
  useEffect(() => {
    let allChartData = {};
    data.sort((a, b) => new Date(a.dateRecorded) - new Date(b.dateRecorded));
    let dateLabelForKey = {};
    let allOtherMeasurements = {};
    data?.forEach((item) => {
      if (item?.measurements) {
        Object.keys(item.measurements).forEach((key) => {
          if (item?.measurements[key]?.value) {
            if (item?.dateRecorded) {
              let modifiedDate = moment(item.dateRecorded).format("DD");

              if (!dateLabelForKey[key]) {
                dateLabelForKey[key] = [];
              }
              dateLabelForKey[key].push(modifiedDate);
            }
            if (!allOtherMeasurements[key]) {
              allOtherMeasurements[key] = [];
            }
            allOtherMeasurements[key].push(item.measurements[key].value);
          }
        });
      }
      if (item?.measurements?.mainMeasurements) {
        Object.keys(item?.measurements?.mainMeasurements).forEach((key) => {
          if (item?.measurements?.mainMeasurements[key]?.value) {
            if (item?.dateRecorded) {
              let modifiedDate = moment(item.dateRecorded).format("DD");

              if (!dateLabelForKey[key]) {
                dateLabelForKey[key] = [];
              }
              dateLabelForKey[key].push(modifiedDate);
            }
            if (!allOtherMeasurements[key]) {
              allOtherMeasurements[key] = [];
            }
            allOtherMeasurements[key].push(
              item.measurements?.mainMeasurements[key].value
            );
          }
        });
      }
    });
    console.log("dateLabels", dateLabelForKey);
    console.log("allOtherMeasurements", allOtherMeasurements);

    Object.keys(allOtherMeasurements).forEach((key) => {
      allChartData[key] = {
        labels: dateLabelForKey[key],
        datasets: [
          {
            label: key,
            data: allOtherMeasurements[key],
            fill: false,
            borderColor: getRandomColor(),
            tension: 0.4,
          },
        ],
      };
    });
    console.log("allChartData", allChartData);
    setAllMeasurementsChartData(allChartData);
  }, [data]);

  // Function to generate random colors
  function getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        width: "600px",
        maxHeight: "600px",
        padding: "0px",
        sx: {
          borderRadius: "20px",
          padding: "0px",
          boxShadow: "0px 16px 48px 0px rgba(0, 0, 0, 0.24)",
        },
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "12px 24px",
          borderBottom: "1px solid #EBEBEB",
          width: "100%",
        }}
      >
        <p
          style={{
            fontFamily: "DM Sans",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "29px",
          }}
        >
          Charts
        </p>
        <div style={{ display: "flex", alignItems: "center" }}>
          <button
            onClick={() => setIsOpen(false)}
            style={{
              cursor: "pointer",
              padding: "5px",
              borderRadius: "48%",
              transition: "background-color 0.3s",
            }}
            className="weights-button close"
          >
            <CloseIcon />
          </button>
        </div>
      </div>
      <DialogContent
        sx={{
          padding: "0px 24px 10px 24px",
        }}
      >
        {Object.keys(allMeasurementsChartData).map((key) => {
          if (!allMeasurementsChartData[key]?.labels?.length) return null;
          return (
            <div
              style={{
                marginTop: "20px",
                marginBottom: "40px",
              }}
            >
              <p
                style={{
                  fontFamily: "DM Sans",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                {measurementTextMap[key]}
              </p>
              <Line
                key={key}
                data={allMeasurementsChartData[key]}
                options={{
                  scales: {
                    y: {
                      title: {
                        display: false,
                        text: measurementTextMap[key],
                      },
                      ticks: {
                        stepSize: 25,
                        max: 100,
                      },
                      grid: {
                        display: true,
                      },
                      // responsible for the dashed grid lines of y axis
                      border: {
                        dash: [6, 10],
                      },
                    },
                    x: {
                      title: {
                        display: true,
                        text: currentMonth,
                      },
                      grid: {
                        display: false,
                      },
                      ticks: {
                        maxRotation: 0,
                        minRotation: 0,
                      },
                    },
                  },
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  elements: {
                    point: {
                      radius: 5,
                    },
                  },
                  borderWidth: 2,
                }}
              />
            </div>
          );
        })}
      </DialogContent>
    </Dialog>
  );
};

export default AllMeasurementsChart;

const measurementTextMap = {
  hips: "Hips",
  neck: "Neck",
  chest: "Chest",
  waist: "Waist",
  leftCalf: "Left Calf",
  shoulder: "Shoulder",
  leftBicep: "Left Bicep",
  leftThigh: "Left Thigh",
  rightCalf: "Right Calf",
  rightBicep: "Right Bicep",
  rightThigh: "Right Thigh",
  leftForearm: "Left Forearm",
  rightForearm: "Right Forearm",
  fat: "Body Fat Percentage", // Assuming you want to include this measurement
  bmi: "BMI",
  bodyAge: "Body Age",
  skMuscle: "Skeletal Muscle",
  visceralFat: "Visceral Fat",
};
