import WorkoutInfoDialog from "@/components/WorkoutComponent/WorkoutInfoDialog";
import { useEffect, useRef, useState } from "react";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import TopBar from "./TopBar";
import TopPart from "./TopPart";
import Loader from "./utils/Loader";
import { useSelector } from "react-redux";
import { getTrainerDashboard } from "@/ApiServices/Dashboard/Api";
import { useDispatch } from "react-redux";
import {
  setAllActiveAthletes,
  setTrainerDashboardData,
} from "@/redux/dataSlice";
import { getAllAtheletesOneCoach } from "@/ApiServices/ClientDetails/Api";

const EnduranceWorkoutBuilder = () => {
  const location = useLocation();
  const history = useHistory();

  const [workoutInfo, setWorkoutInfo] = useState({});
  const [editWorkoutInfo, setEditWorkoutInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [workoutKind, setWorkoutKind] = useState("run");

  const coachID = useSelector((state) => state.auth.trainerUUID);
  const allActiveAthletes = useSelector(
    (state) => state.data.allActiveAthletes
  );

  const [allAthletes, setAllAthletes] = useState([]);
  const [selectedAthlete, setSelectedAthlete] = useState(undefined);

  const [assignInfo, setAssignInfo] = useState({});

  const topPartRef = useRef(null);
  const dispatch = useDispatch();

  const saveWorkout = async () => {
    if (topPartRef.current) {
      setLoading(true);
      await topPartRef.current?.onSave();
      setLoading(false);
      history.goBack();
    }
  };

  const extractExistingData = (state) => {
    let workoutInfo = {
      ...state.data,
      workoutContent: undefined,
    };
    // let savedStructure = convertSpurfitStructureToData(state?.data);
    if (state?.data?.workoutKind) {
      setWorkoutKind(state?.data?.workoutKind);
    }
    setWorkoutInfo(workoutInfo);

    // topPartRef.current?.setSavedStructure(savedStructure);
  };

  const getAllAthletes = async () => {
    if (allActiveAthletes && allActiveAthletes?.length > 0) {
      let activeClients = allActiveAthletes.filter(
        (user) => user.active == true
      );
      setAllAthletes(activeClients);
    } else {
      // get using api call
      let allAtheletes = await getAllAtheletesOneCoach(coachID);
      // filter active users
      dispatch(setAllActiveAthletes(allAtheletes ?? []));
      let activeClients = allAtheletes.filter((user) => user.active == true);
      setAllAthletes(activeClients);
    }
  };

  useEffect(() => {
    if (location?.state?.retriveWorkout) {
      topPartRef?.current?.recoverDataFromLocalStorage();
    }
    if (location?.state?.workoutInfo) {
      setWorkoutInfo(location?.state?.workoutInfo);
      setWorkoutKind(location?.state?.workoutInfo?.workoutKind);
    }
    if (location?.state?.isUpdate) {
      extractExistingData(location?.state);
    }

    if (location?.state) {
      let date = location?.state?.date;
      let isCalendar = location?.state?.isCalendar;
      let userInfo = location?.state?.userInfo;
      let userUUID = location?.state?.userUUID;

      setAssignInfo({
        date,
        isCalendar,
        userInfo,
        userUUID,
      });
    }

    getAllAthletes();
  }, [location?.state]);

  return (
    <div className="px-[65px] gap-3">
      {loading && <Loader />}
      <TopBar
        workoutInfo={workoutInfo}
        setEditWorkoutInfo={setEditWorkoutInfo}
        onSaveWorkout={saveWorkout}
        isUpdate={location?.state?.isUpdate}
        assignInfo={assignInfo}
        allAthletes={allAthletes}
        selectedAthlete={selectedAthlete}
        setSelectedAthlete={setSelectedAthlete}
      />
      <TopPart
        workoutInfo={workoutInfo}
        ref={topPartRef}
        key={workoutInfo?.id}
        isUpdate={location?.state?.isUpdate}
        workoutKind={workoutKind}
        setWorkoutInfo={setWorkoutInfo}
        assignInfo={assignInfo}
        selectedAthlete={selectedAthlete}
      />

      {editWorkoutInfo && (
        <WorkoutInfoDialog
          workoutInfo={workoutInfo}
          setWorkoutInfo={setWorkoutInfo}
          isEdit={true}
          isUpdate={true}
          isOpen={editWorkoutInfo}
          setIsOpen={setEditWorkoutInfo}
        />
      )}
    </div>
  );
};

export default EnduranceWorkoutBuilder;
