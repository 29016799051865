import { store } from "@/redux/store";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import gym from "../../assets/gym.svg";
import gymnastics from "../../assets/gymnastics.svg"; // ABSOLUTE IMPORT IS PENDING; Gymnastics
import resting from "../../assets/resting.svg";
import shoe from "../../assets/shoe.svg";
import {
  getTrainerBasedValue,
  TRAINING_TYPES,
} from "../../common/trainingTypeHelper";
import { CalendarDaysIndex } from "./constant";

const IMAGE_MAPPER = {
  Cardio: shoe,
  "Interval Training": gymnastics,
  "Muscle Training": gym,
};

export const getCalendarImg = (Complete) => {
  const exercise = _.sample(["Cardio", "Interval Training", "Muscle Training"]);
  switch (Complete) {
    case "done":
      return {
        style: "bg-green-dark",
        backgroundColor: "#CCFFE3",
        color: "black",
        img: IMAGE_MAPPER[exercise],
        status: "Complete",
      };
    case "partial":
      return {
        style: "bg-orange-tangerine",
        borderColor: "#ff9800",
        color: "orange-tangerine",
        img: IMAGE_MAPPER[exercise],
        status: "Tried",
        backgroundColor: "#ffc588",
      };
    case "not_done":
      return {
        style: "bg-red-dark",
        backgroundColor: "#FFCCCC",
        color: "black",
        img: IMAGE_MAPPER[exercise],
        status: "Incomplete",
      };
    case "pending":
      return {
        style: "bg-gray-warm",
        backgroundColor: "#EDEDED",
        color: "gray-warm",
        img: IMAGE_MAPPER[exercise],
        status: "Yet to do",
      };
    case "Rest Day":
      return {
        style: "bg-red-light",
        color: "red-light",
        img: resting,
        borderColor: "#FF9292",
        status: "Rest Day",
      };
    default:
      return {
        color: "",
        style: "",
        img: "",
        border: "border-yellow-500",
      };
  }
};

var getDaysArray = (startDate, endDate, steps = 1) => {
  const dateArray = [];
  let currentDate = new Date(`${startDate}`);
  while (currentDate <= new Date(endDate) && dateArray.length < 15) {
    const day = moment(currentDate).format("dddd");
    dateArray.push({
      day: [day],
      date: moment(currentDate).format("DD-MM-YYYY"),
    });
    // Use UTC date to prevent problems with time zones and DST
    currentDate.setUTCDate(currentDate.getUTCDate() + steps);
  }
  return dateArray;
};

export const getDaysArrayOfMonth = (date) => {
  const startOfMonth = moment(date).startOf("month").utc().toDate();
  const endOfMonth = moment(date).endOf("month").utc().toDate();
  const dateArray = [];
  const noOfDaysInMonth = moment(date).daysInMonth();
  let currentDate = new Date(startOfMonth);
  while (
    currentDate <= new Date(endOfMonth) &&
    dateArray.length <= noOfDaysInMonth
  ) {
    const day = moment(currentDate).format("dddd");
    dateArray.push({
      day: [day],
      date: moment(currentDate).format("DD-MM-YYYY"),
    });
    currentDate.setUTCDate(currentDate.getUTCDate() + 1);
  }
  return dateArray;
};

export const presentMonthData = async (
  DATA,
  mealData,
  activityData,
  startDate,
  userUUID
) => {
  var date = startDate ?? new Date();
  const mainData = {};

  let allDaysOfMonth = getDaysArrayOfMonth(date);
  const different = CalendarDaysIndex[allDaysOfMonth[0].day[0]] - 1;
  allDaysOfMonth.unshift(...new Array(different).fill("empty"));
  const weeksArray = _.chunk(allDaysOfMonth, 7);
  // console.log("mealData: ", mealData);
  weeksArray.forEach((week, index) => {
    week.forEach((item) => {
      if (!_.has(mainData, index)) {
        //create a array new week if not present
        mainData[index] = [];
      }

      const { day, date } = item;

      const d =
        !_.isEmpty(DATA) &&
        DATA?.filter((item) => {
          return moment(item.ModuleDate).format("DD-MM-YYYY") == date;
        });

      let formattedDate = moment(date, "DD-MM-YYYY").format("YYYY-MM-DD");

      const m = mealData?.[formattedDate.toString()];
      const foundActivity = activityData?.[formattedDate.toString()];

      if (d.length > 0) {
        const { ModuleDate, PrimaryKey, Complete, Name } = d[0];
        const ModuleDateValue = moment(ModuleDate).format("DD-MM-YYYY");
        let names = [];
        let primaryKey = [];
        let workoutIds = [];
        let workoutKind = {};

        d?.forEach((item) => {
          if (item?.Name) {
            names.push({ Name: item.Name, Done: item.Complete == true });
            primaryKey.push(item.PrimaryKey);
            workoutIds.push(item.WorkoutId);
            workoutKind[item?.PrimaryKey] = item?.workoutKind;
          }
        });

        mainData[index][day] = {
          Name: JSON.stringify(names),
          restDay: false,
          ModuleDate: ModuleDateValue,
          PrimaryKey: JSON.stringify(primaryKey),
          workoutIds: workoutIds,
          workoutKind: workoutKind,
          userUUID,
        };
      } else {
        mainData[index][day] = {
          restDay: true,
          ModuleDate: date,
          train: "",
          //  parseInt(today) < parseInt(moment(date).format('DD')) ? 'Rest Day' : '',
          // train: parseInt(today) >= parseInt(date) ? 'Rest Day' : '',
        };
      }

      if (m) {
        let meals = [];
        m?.forEach((item) => {
          let calories =
            item?.unsavedMeals?.calories ||
            item?.mealPlans?.plandetails?.calories ||
            0;
          let Name =
            item?.mealPlans?.reference_name || item?.unsavedMeals?.day || "";
          let assignedId = item?.id;
          let mealId = item?.mealId;
          meals.push({
            Name,
            calories,
            assignedId,
            mealId,
            dateAssigned: item?.dateAssigned,
            item,
          });
        });

        mainData[index][day] = {
          ...mainData[index][day],
          meals,
        };
      }

      if (foundActivity?.[0]) {
        console.log("found activity: ", foundActivity);
        mainData[index][day] = {
          ...mainData[index][day],
          activity: foundActivity,
        };
      }
    });
  });
  return Object.keys(mainData).map((v) => mainData[v]);
};

const presentWeekData = (DATA) => {
  const firstWeek = {};
  const SecoundWeek = {};
  const today = moment(new Date()).format("DD");
  const todaysDay = moment(new Date()).format("dddd");
  const different = CalendarDaysIndex[todaysDay];
  var date = new Date();

  date.setDate(date.getDate() - different);
  var _fourteenDay = new Date(date);
  _fourteenDay.setDate(_fourteenDay.getDate() + 16);
  const range = getDaysArray(date, _fourteenDay);
  const weekOne = range.slice(0, 8);
  const weekTwo = range.slice(7, 16);

  weekOne.forEach((item) => {
    const { day, date } = item;
    // First 7 days Data:
    const found = DATA?.find((item) => {
      // Use UTC date to prevent problems with time zones and DST
      return moment(item.ModuleDate).format("DD-MM-YYYY") == date;
    });

    const d = DATA?.filter((item) => {
      return moment(item.ModuleDate).format("DD-MM-YYYY") == date;
    });

    if (d.length > 0) {
      const { ModuleDate, PrimaryKey, UserUUID, Complete, Name } = d[0];
      const ModuleDateValue = moment(ModuleDate).format("DD-MM-YYYY");
      let names = [];
      let primaryKey = [];

      d?.forEach((item) => {
        names.push({ Name: item.Name, Done: item.Complete == true });
        primaryKey.push(item.PrimaryKey);
      });

      firstWeek[day] = {
        Name: JSON.stringify(names),
        restDay: false,
        ModuleDate: ModuleDateValue,
        PrimaryKey: getTrainerBasedValue({
          [TRAINING_TYPES.CROSSFIT]: JSON.stringify(primaryKey),
          [TRAINING_TYPES.SANDC]: PrimaryKey,
        }),
        UserUUID,
      };
    } else
      firstWeek[day] = {
        restDay: true,
        ModuleDate: date,
        train: "",
        //  parseInt(today) < parseInt(moment(date).format('DD')) ? 'Rest Day' : '',
        // train: parseInt(today) >= parseInt(date) ? 'Rest Day' : '',
      };
  });

  weekTwo.forEach((item) => {
    // Next Upcoming 7 days Data:
    const { day, date } = item;
    const found = DATA?.find((item) => {
      return moment(item.ModuleDate).format("DD-MM-YYYY") == date;
    });

    const d = DATA?.filter((item) => {
      return moment(item.ModuleDate).format("DD-MM-YYYY") == date;
    });

    if (d.length > 0) {
      const { ModuleDate, PrimaryKey, UserUUID, Complete, Name } = found;
      const ModuleDateValue = moment(ModuleDate).format("DD-MM-YYYY");

      let names = [];
      let primaryKey = [];
      let doneCount = 0;
      d?.forEach((item) => {
        names.push({ Name: item.Name, Done: item.Complete });
        primaryKey.push(item.PrimaryKey);
        if (item.Complete == true) doneCount++;
      });
      let done = doneCount == d.length ? "done" : "not_done";
      console.log("d ", d[0]?.ModuleDate, " ->", d, " Done Count:", doneCount);

      SecoundWeek[day] = {
        Name: JSON.stringify(names),
        ModuleDate: ModuleDateValue,
        PrimaryKey: getTrainerBasedValue({
          [TRAINING_TYPES.CROSSFIT]: JSON.stringify(primaryKey),
          [TRAINING_TYPES.SANDC]: PrimaryKey,
        }),
        UserUUID,
        train: done,
      };
    } else
      SecoundWeek[day] = {
        restDay: false,
        ModuleDate: date,
      };
  });

  return [firstWeek, SecoundWeek];
};

export const futureWeekData = (
  DATA,
  futureDateReference,
  setDisableArrow,
  userData
) => {
  const firstWeek = {};
  const SecoundWeek = {};
  let disableDate = moment(userData?.planStaticData?.endDate)
    .utc()
    .format("DD-MM-YYYY");

  const today = moment(new Date()).format("DD");
  const todaysDay = moment(new Date()).format("dddd");
  const different = CalendarDaysIndex[todaysDay];

  var date = new Date(futureDateReference);
  date.setDate(date.getDate() + 1);

  var _fourteenDay = new Date(futureDateReference);
  _fourteenDay.setDate(_fourteenDay.getDate() + 14);
  const range = getDaysArray(date, _fourteenDay);
  const weekOne = range.slice(0, 7);
  const weekTwo = range.slice(7, 14);

  weekOne.forEach((item) => {
    const { day, date } = item;

    if (disableDate == date) {
      setDisableArrow("right");
    }

    const found = DATA?.find((item) => {
      return moment(item.ModuleDate).format("DD-MM-YYYY") == date;
    });

    const d = DATA?.filter((item) => {
      return moment(item.ModuleDate).format("DD-MM-YYYY") == date;
    });

    if (found) {
      const { ModuleDate, PrimaryKey, UserUUID, Complete, Name } = found;
      const ModuleDateValue = moment(ModuleDate).format("DD-MM-YYYY");
      let names = [];
      let primaryKey = [];
      let doneCount = 0;
      d?.forEach((item) => {
        names.push({ Name: item.Name, Done: item.Complete });
        primaryKey.push(item.PrimaryKey);
        if (item.Complete == true) {
          doneCount++;
        }
      });
      console.log(
        "d past: ",
        d[0]?.ModuleDate,
        " ->",
        d,
        " Done Count:",
        doneCount
      );

      let done = "yet to do";

      if (doneCount > 0 && doneCount == d.length) {
        done = "done";
      } else {
        done = "pending";
      }

      firstWeek[day] = {
        Name: JSON.stringify(names),
        restDay: false,
        ModuleDate: ModuleDateValue,
        PrimaryKey: getTrainerBasedValue({
          [TRAINING_TYPES.CROSSFIT]: JSON.stringify(primaryKey),
          [TRAINING_TYPES.SANDC]: PrimaryKey,
        }),
        UserUUID,
        train: done,
      };
    } else
      firstWeek[day] = {
        restDay: true,
        ModuleDate: date,
        train: "",
        //  parseInt(today) < parseInt(moment(date).format('DD')) ? 'Rest Day' : '',
        // train: parseInt(today) >= parseInt(date) ? 'Rest Day' : '',
      };
  });

  weekTwo.forEach((item) => {
    const { day, date } = item;

    if (disableDate == date) {
      setDisableArrow("right");
    }

    const found = DATA?.find((item) => {
      return moment(item.ModuleDate).format("DD-MM-YYYY") == date;
    });
    const d = DATA?.filter((item) => {
      return moment(item.ModuleDate).format("DD-MM-YYYY") == date;
    });

    if (found) {
      const { ModuleDate, PrimaryKey, UserUUID, Complete, Name } = found;

      const ModuleDateValue = moment(ModuleDate).format("DD-MM-YYYY");
      let names = [];
      let primaryKey = [];
      let doneCount = 0;
      d?.forEach((item) => {
        names.push({ Name: item.Name, Done: item.Complete });
        primaryKey.push(item.PrimaryKey);
        if (item.Complete == true) doneCount++;
      });
      let done = doneCount == d.length ? "done" : "not_done";
      console.log("d ", d[0]?.ModuleDate, " ->", d, " Done Count:", doneCount);

      SecoundWeek[day] = {
        Name: JSON.stringify(names),
        ModuleDate: ModuleDateValue,
        PrimaryKey: getTrainerBasedValue({
          [TRAINING_TYPES.CROSSFIT]: JSON.stringify(primaryKey),
          [TRAINING_TYPES.SANDC]: PrimaryKey,
        }),
        UserUUID,
        train:
          new Date() >= new Date(ModuleDate) &&
          today != moment(new Date(ModuleDate)).format("DD")
            ? Complete === 0
              ? "not_done"
              : "done"
            : "pending",
      };
    } else
      SecoundWeek[day] = {
        restDay: false,
        ModuleDate: date,
      };
  });

  return [firstWeek, SecoundWeek];
};

export const pastWeekDataInfo = (
  DATA,
  pastDateReference,
  setDisableArrow,
  userData
) => {
  const firstWeek = {};
  const SecoundWeek = {};
  let disableDate = moment(userData?.planStaticData?.startDate)
    .utc()
    .format("DD-MM-YYYY");

  const today = moment(new Date()).format("DD");
  const todaysDay = moment(new Date()).format("dddd");
  const different = CalendarDaysIndex[todaysDay];

  var date = new Date(pastDateReference);
  date.setDate(date.getDate() - 1);

  var _fourteenDay = new Date(pastDateReference);
  _fourteenDay.setDate(_fourteenDay.getDate() - 14);
  const range = getDaysArray(_fourteenDay, date);
  const weekOne = range.slice(0, 7);
  const weekTwo = range.slice(7, 14);

  weekOne.forEach((item) => {
    const { day, date } = item;

    if (disableDate == date) {
      setDisableArrow("left");
    }

    const found = DATA?.find((item) => {
      return moment(item.ModuleDate).format("DD-MM-YYYY") == date;
    });

    const d = DATA?.filter((item) => {
      return moment(item.ModuleDate).format("DD-MM-YYYY") == date;
    });

    if (d.length > 0) {
      const { ModuleDate, PrimaryKey, UserUUID, Complete, Name } = found;
      const ModuleDateValue = moment(ModuleDate).format("DD-MM-YYYY");
      let names = [];
      let primaryKey = [];

      let doneCount = 0;
      d?.forEach((item) => {
        names.push({ Name: item.Name, Done: item.Complete });
        primaryKey.push(item.PrimaryKey);
        if (item.Complete == true) {
          doneCount++;
        }
      });
      console.log(
        "d past: ",
        d[0]?.ModuleDate,
        " ->",
        d,
        " Done Count:",
        doneCount
      );

      let done = "yet to do";

      if (doneCount > 0 && doneCount == d.length) {
        done = "done";
      } else {
        done = "not_done";
      }

      firstWeek[day] = {
        Name: JSON.stringify(names),
        restDay: false,
        ModuleDate: ModuleDateValue,
        PrimaryKey: getTrainerBasedValue({
          [TRAINING_TYPES.CROSSFIT]: JSON.stringify(primaryKey),
          [TRAINING_TYPES.SANDC]: PrimaryKey,
        }),
        UserUUID,
        train: done,
      };
    } else
      firstWeek[day] = {
        restDay: true,
        ModuleDate: date,
        train: "",
        //  parseInt(today) < parseInt(moment(date).format('DD')) ? 'Rest Day' : '',
        // train: parseInt(today) >= parseInt(date) ? 'Rest Day' : '',
      };
  });

  weekTwo.forEach((item) => {
    const { day, date } = item;

    if (disableDate == date) {
      setDisableArrow("left");
    }

    const found = DATA?.find((item) => {
      return moment(item.ModuleDate).format("DD-MM-YYYY") == date;
    });
    const d = DATA?.filter((item) => {
      return moment(item.ModuleDate).format("DD-MM-YYYY") == date;
    });

    if (found) {
      const { ModuleDate, PrimaryKey, UserUUID, Complete, Name } = found;
      const ModuleDateValue = moment(ModuleDate).format("DD-MM-YYYY");
      let names = [];
      let primaryKey = [];
      let doneCount = 0;
      d?.forEach((item) => {
        names.push({ Name: item.Name, Done: item.Complete });
        primaryKey.push(item.PrimaryKey);
        if (item.Complete == true) doneCount++;
      });
      let done = doneCount == d.length ? "done" : "not_done";
      console.log("d ", d[0]?.ModuleDate, " ->", d, " Done Count:", doneCount);

      SecoundWeek[day] = {
        Name: JSON.stringify(names),
        ModuleDate: ModuleDateValue,
        PrimaryKey: getTrainerBasedValue({
          [TRAINING_TYPES.CROSSFIT]: JSON.stringify(primaryKey),
          [TRAINING_TYPES.SANDC]: PrimaryKey,
        }),
        UserUUID,
        train: done,
      };
    } else
      SecoundWeek[day] = {
        restDay: false,
        ModuleDate: date,
      };
  });

  return [firstWeek, SecoundWeek];
};

export const getWeekData = (
  DATA,
  startDate,
  endDate,
  arrowClicked,
  setDisableArrow,
  userData
) => {
  let data = [];
  if (!arrowClicked) {
    data = presentWeekData(DATA);
  } else {
    if (arrowClicked == "right") {
      data = futureWeekData(DATA, endDate, setDisableArrow, userData);
    } else {
      data = pastWeekDataInfo(DATA, startDate, setDisableArrow, userData);
    }
  }
  return data;
};

export const getEmptyCalenderData = (
  DATA,
  startDate,
  endDate,
  arrowClicked,
  setDisableArrow,
  userData
) => {
  let data = [];
  if (!arrowClicked) {
    data = presentWeekDataEmptyCalender();
  } else {
    if (arrowClicked == "right") {
      data = futureWeekDataEmptyCalender(
        DATA,
        endDate,
        setDisableArrow,
        userData
      );
    } else {
      data = pastWeekDataInfoEmptyCalender(
        DATA,
        startDate,
        setDisableArrow,
        userData
      );
    }
  }
  return data;
};

const presentWeekDataEmptyCalender = () => {
  const firstWeek = {};
  const SecoundWeek = {};
  const today = moment(new Date()).format("DD");
  const todaysDay = moment(new Date()).format("dddd");
  const different = CalendarDaysIndex[todaysDay];
  var date = new Date();

  date.setDate(date.getDate() - different);
  var _fourteenDay = new Date(date);
  _fourteenDay.setDate(_fourteenDay.getDate() + 16);
  const range = getDaysArray(date, _fourteenDay);
  const weekOne = range.slice(0, 8);
  const weekTwo = range.slice(7, 16);

  weekOne.forEach((item) => {
    const { day, date } = item;
    // First 7 days Data:
    firstWeek[day] = {
      restDay: true,
      ModuleDate: date,
      train: "",
      //  parseInt(today) < parseInt(moment(date).format('DD')) ? 'Rest Day' : '',
      // train: parseInt(today) >= parseInt(date) ? 'Rest Day' : '',
    };
  });

  weekTwo.forEach((item) => {
    // Next Upcoming 7 days Data:
    const { day, date } = item;

    SecoundWeek[day] = {
      restDay: false,
      ModuleDate: date,
    };
  });

  return [firstWeek, SecoundWeek];
};

export const futureWeekDataEmptyCalender = (
  DATA,
  futureDateReference,
  setDisableArrow,
  userData
) => {
  const firstWeek = {};
  const SecoundWeek = {};
  let disableDate = moment(userData?.planStaticData?.endDate)
    .utc()
    .format("DD-MM-YYYY");

  const today = moment(new Date()).format("DD");
  const todaysDay = moment(new Date()).format("dddd");
  const different = CalendarDaysIndex[todaysDay];

  var date = new Date(futureDateReference);
  date.setDate(date.getDate() + 1);

  var _fourteenDay = new Date(futureDateReference);
  _fourteenDay.setDate(_fourteenDay.getDate() + 14);
  const range = getDaysArray(date, _fourteenDay);
  const weekOne = range.slice(0, 7);
  const weekTwo = range.slice(7, 14);

  weekOne.forEach((item) => {
    const { day, date } = item;

    if (disableDate == date) {
      setDisableArrow("right");
    }

    firstWeek[day] = {
      restDay: true,
      ModuleDate: date,
      train: "",
      //  parseInt(today) < parseInt(moment(date).format('DD')) ? 'Rest Day' : '',
      // train: parseInt(today) >= parseInt(date) ? 'Rest Day' : '',
    };
  });

  weekTwo.forEach((item) => {
    const { day, date } = item;

    if (disableDate == date) {
      setDisableArrow("right");
    }

    SecoundWeek[day] = {
      restDay: false,
      ModuleDate: date,
    };
  });

  return [firstWeek, SecoundWeek];
};

export const pastWeekDataInfoEmptyCalender = (
  DATA,
  pastDateReference,
  setDisableArrow,
  userData
) => {
  const firstWeek = {};
  const SecoundWeek = {};
  let disableDate = moment().format("DD-MM-YYYY");

  const today = moment(new Date()).format("DD");
  const todaysDay = moment(new Date()).format("dddd");
  const different = CalendarDaysIndex[todaysDay];

  var date = new Date(pastDateReference);
  date.setDate(date.getDate() - 1);

  var _fourteenDay = new Date(pastDateReference);
  _fourteenDay.setDate(_fourteenDay.getDate() - 14);
  const range = getDaysArray(_fourteenDay, date);
  const weekOne = range.slice(0, 7);
  const weekTwo = range.slice(7, 14);

  weekOne.forEach((item) => {
    const { day, date } = item;

    if (disableDate == date) {
      setDisableArrow("left");
    }

    firstWeek[day] = {
      restDay: true,
      ModuleDate: date,
      train: "",
    };
  });

  weekTwo.forEach((item) => {
    const { day, date } = item;

    if (disableDate == date) {
      setDisableArrow("left");
    }

    SecoundWeek[day] = {
      restDay: false,
      ModuleDate: date,
    };
  });

  return [firstWeek, SecoundWeek];
};

export const AssignMeal = async (
  newAssignedId,
  userUUID,
  meal,
  selectedDate
) => {
  let mealId = meal?.item?.mealId;
  if (mealId) {
    DeleteAssignedMeal(meal?.assignedId);
    await axios({
      method: "post",
      url: `${store.getState().auth.apiURL}CF/api/meal/assignMeal`,
      data: {
        id: newAssignedId,
        atheleteID: userUUID,
        mealId: mealId,
        dateAssigned: moment(selectedDate, "DD-MM-YYYY")
          ?.set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          })
          ?.format("YYYY-MM-DD"),
      },
    })
      .then((res) => {
        console.log("meal assigned successfully ", res);
      })
      .catch((err) => {
        console.log("error assigning meal: ", err);
      });
  } else {
    let unsavedMeals = meal?.item?.unsavedMeals;
    DeleteAssignedMeal(meal?.assignedId);
    await axios({
      method: "post",
      url: `${store.getState().auth.apiURL}CF/api/meal/assignUnsavedMeal`,
      data: {
        id: newAssignedId,
        atheleteID: userUUID,
        unsavedMeals,
        dateAssigned: moment(selectedDate, "DD-MM-YYYY")
          ?.set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          })
          ?.format("YYYY-MM-DD"),
      },
    })
      .then((res) => {
        console.log("meal assigned successfully ", res);
      })
      .catch((err) => {
        console.log("error assigning meal: ", err);
      });
  }
};

export const DeleteAssignedMeal = async (assignedId) => {
  await axios({
    method: "POST",
    url: `${store.getState().auth.apiURL}CF/api/meal/deleteAssignedMealPlan`,
    data: {
      id: assignedId,
    },
  })
    .then((res) => {
      console.log("meal deleted successfully ", res);
    })
    .catch((err) => {
      console.log("error deleting meal: ", err);
    });
};
