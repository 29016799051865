import React from "react";

export const BoldIcon = ({ boldState, resetIconState, setBoldState }) => (
  <div
    className={boldState ? 'base_icon_active' : 'bace_icon_inactive'}
    onClick={() => {
      const bold = boldState;
      resetIconState();
      setBoldState(!bold);
    }}>
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='10'
      height='12'
      viewBox='0 0 10 12'
      fill='none'>
      <path
        d='M0.534271 12V-3.05176e-05H5.35144C6.59716 -3.05176e-05 7.54573 0.291399 8.19716 0.874259C8.86002 1.44569 9.19145 2.18284 9.19145 3.0857C9.19145 3.83999 8.98574 4.4457 8.57431 4.90285C8.1743 5.34856 7.68287 5.65142 7.10001 5.81142C7.78573 5.94857 8.35145 6.29143 8.79716 6.84C9.24288 7.37715 9.46574 8.00572 9.46574 8.72572C9.46574 9.6743 9.12288 10.4572 8.43716 11.0743C7.75145 11.6915 6.78001 12 5.52286 12H0.534271ZM2.72857 5.03999H5.02572C5.64287 5.03999 6.11715 4.89713 6.44858 4.61142C6.78001 4.3257 6.94573 3.91999 6.94573 3.39427C6.94573 2.89141 6.78001 2.49712 6.44858 2.21141C6.12858 1.91426 5.64286 1.76569 4.99143 1.76569H2.72857V5.03999ZM2.72857 10.2172H5.18001C5.83144 10.2172 6.3343 10.0686 6.68858 9.77144C7.0543 9.46287 7.23716 9.0343 7.23716 8.48572C7.23716 7.92572 7.04859 7.48572 6.67144 7.16572C6.2943 6.84571 5.78572 6.68571 5.14572 6.68571H2.72857V10.2172Z'
        fill={"black"}
        fillOpacity={1}
      />
    </svg>
  </div>
);
