import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    triangle: {
        width: 0,
        height: 0,
        borderRight: '20px solid transparent',
        borderTop: '20px solid red',
        position: 'absolute',
        left: 0,
        margin: 0,
        padding: 0,
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
        maxWidth: 300,
    },
}));

const UnavailableUi = ({ unavailabilityData }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const { reason, startDate, endDate } = unavailabilityData

    return (
        <Stack sx={{ position: 'relative', height: '100%', width: '100%' }}>
            <div className={classes.triangle}></div>
            <Typography
                variant="body2"
                sx={{ backgroundColor: '#ddd' }}
                py={1}
                fontWeight="bold"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            >
                UNAVAILABLE
            </Typography>
            <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                    paper: classes.paper,
                }}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                disableRestoreFocus
            >
                <TitleText title={'Reason'} text={reason} />
            </Popover>
        </Stack>
    );
};

export default UnavailableUi;

export const TitleText = ({ title, text }) => {
    return (
        <Stack direction={'row'} gap={1} alignItems={'center'} justifyContent={'center'} p={1}>
            <Typography fontWeight={'bold'} variant='body2'>{title} : </Typography>
            <Typography variant='caption'>{text}</Typography>
        </Stack >
    )
}
