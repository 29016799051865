import { useHistory } from "react-router-dom";
import addButton from "../../assets/addBtnSchedule.svg";
import { COLUMNS } from "./constant";
import { AssignMeal, getCalendarImg, presentMonthData } from "./functions";
// import moment from 'moment';
import { deleteAssignedMealPlan } from "@/ApiServices/Meals/Api";
import { copyDay } from "@/redux/clientsSlice";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Tooltip } from "@mui/material";
import axios from "axios";
import { ClipboardCheck, Files, Trash, X } from "lucide-react";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { usePagination, useSortBy, useTable } from "react-table";
import { v4 as uuidv4 } from "uuid";
import WorkoutPlanIcon from "../../assets/copilot/ScheduleWorkoutIcon.svg";
import activityImg from "../../assets/copilot/activity.png";
import mealImg from "../../assets/copilot/dietPlanIcon.svg";
import { SpurfitCircularProgress } from "../Dashboard/dashboard";
import { updateActivity } from "../DescriptionComp/util";
import "./Styles.css";
import styles from "./calendar.module.css";

import { pasteWorkoutOnAssignedDate } from "@/ApiServices/ClientDetails/Api";
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from "@/components/ui/context-menu";
import { useSelector } from "react-redux";

const Calendar = ({
  DATA,
  mealData,
  activityData,
  setShowWorkOutBuilder,
  selectedDate,
  setSelectedDate,
  movements,
  calStartDate,
  userUUID,
  setSelectedActivity,
  loadingParent,
  profileData,
  garminActivityData,
}) => {
  const APIURL = useSelector((state) => state.auth.apiURL);

  const columns = useMemo(() => COLUMNS, []);
  const [data, setData] = useState([]);
  const calRef = useRef(null);
  const cellOffset = calRef.current?.offsetWidth / 7;
  const cellWidth = !isNaN(cellOffset) ? `${Math.floor(cellOffset)}px` : "100%";
  const [loading, setLoading] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [updatingWorkoutDate, setUpdatingWorkoutDate] = useState(false);
  const [eventsData, setEventsData] = useState(profileData?.eventData ?? {});
  const [copiedWorkout, setCopiedWorkout] = useState(null);

  const [enduranceData, setEnduranceData] = useState([]);

  useEffect(() => {
    initializeData(calStartDate);
  }, [movements, DATA, mealData]);

  useEffect(() => {
    setEventsData(profileData?.eventData ?? {});
  }, [profileData]);

  const initializeData = async (calStartDate) => {
    let curMonthData = [];
    let curMonthMealData = [];
    let curMonthActivityData = [];
    let result = [];
    setLoading(true);
    if (
      moment(calStartDate).format("YYYY-MM-DD") !==
      moment().startOf("month").format("YYYY-MM-DD")
    ) {
      await axios({
        method: "post",
        url: `${APIURL}CF/api/clientProfileByPage`,
        data: {
          date: moment(calStartDate).format("YYYY-MM-DD"),
        },
        params: {
          userUUID: userUUID,
        },
      })
        .then((res) => {
          let enduranceFinishedData = res.currentMonthEnduranceFinishedData;
          setEnduranceData(enduranceFinishedData);
          let currentMonthmodules = res.data.result.currentMonthmodules;
          curMonthMealData = res.data.result.mealData;
          curMonthActivityData = res.data.result.activityData;
          Object.entries(currentMonthmodules)?.map(([key, value]) => {
            value?.forEach((item) => {
              curMonthData.push({
                ModuleDate: key,
                Complete: item?.finished,
                Name: item?.workoutAssigned?.name,
                PrimaryKey: item?.id,
                WorkoutId: item?.workoutAssigned?.id,
                workoutKind: item?.workoutAssigned?.workoutKind,
              });
            });
          });
        })
        .catch((err) => {
          console.log("error fetching client profile by page: ", err);
        })
        .finally(() => {
          setLoading(false);
        });
      result = await presentMonthData(
        curMonthData,
        curMonthMealData,
        curMonthActivityData,
        calStartDate,
        userUUID
      );
    } else {
      result = await presentMonthData(
        DATA,
        mealData,
        activityData,
        calStartDate,
        userUUID
      );
      setLoading(false);
      console.log("Calendar.js edurenace data", garminActivityData);
      setEnduranceData(garminActivityData);
    }
    setData(result || []);
  };
  // the below function add the ... in the name if it is large in length
  const truncateLargeName = (name) => {
    const words = name.split(" ");
    if (words.length > 2) {
      return `${words.slice(0, 2).join(" ")}...`;
    }
    return name;
  };

  //const data = useMemo(() => resultSet, []);
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
        }),
      },
    },
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    tableInstance;

  const history = useHistory();

  const emptyFunction = () => { };

  const parsePrimaryKeys = (primaryKey) => {
    try {
      return JSON.parse(primaryKey);
    } catch (error) {
      return [];
    }
  };

  const getCurrentMonthData = async () => {
    let curMonthData = [];
    let curMonthMealData = [];
    let curMonthActivityData = [];
    let result = [];
    await axios({
      method: "post",
      url: `${APIURL}CF/api/clientProfileByPage`,
      data: {
        date: moment(calStartDate).format("YYYY-MM-DD"),
      },
      params: {
        userUUID: userUUID,
      },
    })
      .then((res) => {
        let currentMonthmodules = res.data.result.currentMonthmodules;
        curMonthMealData = res.data.result.mealData;
        curMonthActivityData = res.data.result.activityData;
        Object.entries(currentMonthmodules)?.map(([key, value]) => {
          value?.forEach((item) => {
            curMonthData.push({
              ModuleDate: key,
              Complete: item?.finished,
              Name: item?.workoutAssigned?.name,
              PrimaryKey: item?.id,
              WorkoutId: item?.workoutAssigned?.id,
              workoutKind: item?.workoutAssigned?.workoutKind,
            });
          });
        });
      })
      .catch((err) => {
        console.log("error fetching client profile by page: ", err);
      })
      .finally(() => {
        setLoading(false);
      });

    result = await presentMonthData(
      curMonthData,
      curMonthMealData,
      curMonthActivityData,
      calStartDate,
      userUUID
    );
    setData(result || []);
  };

  const deleteIndividualWorkout = async (
    assignedWorkoutID,
    primaryKeyArray,
    workoutKind
  ) => {
    await axios({
      method: "post",
      url: `${APIURL}CF/api/deleteAssignedWorkoutAthelete`,
      data: {
        assignedWorkoutID: assignedWorkoutID,
        newOrderWorkoutIDs: primaryKeyArray,
        isEnduranceWorkout:
          workoutKind == "run" ||
          workoutKind === "bike" ||
          workoutKind === "swim",
      },
    })
      .then((res) => {
        // console.log("deleteAssignedWorkoutAthelete: ", res);
      })
      .catch((err) => {
        console.log("Network error, please try again", err);
      })
      .finally(() => { });
  };

  const assignWorkout = async (workoutId, date, primaryKey) => {
    setUpdatingWorkoutDate(true);
    await axios({
      method: "post",
      url: `${APIURL}CF/api/modules`,
      data: {
        userUUID: userUUID,
        id: primaryKey,
        modulesUUID: workoutId,
        moduleDate: `${moment(date, "DD-MM-YYYY")
          .startOf("day")
          .format("YYYY-MM-DD")}T00:00:00.000Z`,
      },
    })
      .then((res) => {
        console.log("assignWorkout success ");
      })
      .catch((err) => {
        console.log("assignWorkout error ", err);
      })
      .finally(() => {
        setUpdatingWorkoutDate(false);
      });
  };

  const pasteWorkout = async (assignDate) => {
    let data = {
      atheleteID: userUUID,
      id: copiedWorkout?.primaryKey,
      workoutID: copiedWorkout?.workoutId,
      assignDate: `${moment(assignDate, "DD-MM-YYYY").format(
        "YYYY-MM-DD"
      )}T00:00:00.000Z`,
    };
    setLoading(true);
    let res = await pasteWorkoutOnAssignedDate(data);
    await getCurrentMonthData();
    setLoading(false);
  };

  function getStyle(style, snapshot) {
    if (!snapshot.isDropAnimating) {
      return style;
    }
    const { moveTo, curve, duration } = snapshot.dropAnimation;
    // move to the right spot
    const translate = `translate(${moveTo.x}px, ${moveTo.y}px)`;
    // add a bit of turn for fun
    // const rotate = "rotate(0.5turn)";

    // patching the existing style
    return {
      ...style,
      // transform: `${translate}`,
      // slowing down the drop because we can
      transition: `all ${curve} ${0.00001}s`,
    };
  }

  const deleteFullDay = async ({
    primaryKeys,
    meal,
    activity,
    workoutKinds,
  }) => {
    console.log(
      "primary key ha kya",
      primaryKeys,
      meal,
      activity,
      workoutKinds
    );
    setLoading(true);
    // delete meal
    let mealAssignedId = meal?.[0]?.item?.id;
    if (mealAssignedId) {
      let deleteMeal = await deleteAssignedMealPlan(mealAssignedId);
    }
    // update activity
    if (Object.keys(activity?.[0]?.activitiesAssigned ?? {})?.length > 0) {
      let updatedActivity = {
        activitiesAssigned: {},
      };
      let update = await updateActivity(updatedActivity, activity?.[0]?.id);
    }
    // delete workout
    // delete using deleteIndividualWorkout
    if (primaryKeys.length > 0) {
      await Promise.all(
        primaryKeys?.map(async (primaryKey, index) => {
          let updatedPrimaryKey = primaryKeys.filter((pk) => pk !== primaryKey);
          let workoutKind = workoutKinds?.[primaryKey];
          await deleteIndividualWorkout(
            primaryKey,
            updatedPrimaryKey,
            workoutKind
          );
        })
      );
    }

    // set that day to empty
    await getCurrentMonthData();

    setLoading(false);
  };
  const getPastedMonthData = async (pastedDate) => {
    const calStartDate = moment(pastedDate).startOf("month");
    await axios({
      method: "post",
      url: `${APIURL}CF/api/clientProfileByPage`,
      data: {
        date: moment(calStartDate).format("YYYY-MM-DD"),
      },
      params: {
        userUUID: userUUID,
      },
    })
      .then((res) => {
        setProfileData(res.data.result);
      })
      .catch((err) => {
        console.log("error fetching client profile by page: ", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderTableRow = (row, i) => {
    console.log("this is the row data", row);
    prepareRow(row);
    let rowCss = i === rows.length - 1 ? " border-b-0" : " border-b-2";
    return (
      <tr {...row.getRowProps()} className={`${rowCss} w-full`}>
        {row.cells.map((cell, index) => {
          let borderCss = index === 0 ? " border-l-0" : " border-l-2";
          let names = cell?.value?.Name ? JSON.parse(cell?.value?.Name) : [];
          let doneCount = 0;
          names.map((item) => {
            if (item.Done) {
              doneCount++;
            }
          });
          let isFuture = false;
          let isPast = false;
          let today = moment().startOf("day");
          if (cell?.value?.ModuleDate && cell?.value?.ModuleDate != "") {
            let date = moment(cell.value.ModuleDate, "DD-MM-YYYY").startOf(
              "day"
            );
            isFuture = date.diff(today);
            isPast = date.isBefore(today);
          }
          let done =
            doneCount > 0
              ? doneCount == names.length
                ? "done"
                : "partial"
              : "pending";

          let status =
            done === "done" || done === "partial"
              ? done
              : isFuture >= 0 && names.length > 0
                ? "pending"
                : isPast && names.length > 0
                  ? "not_done"
                  : "";
          const calendarImg = getCalendarImg(status);

          let statusColor = getCalendarImg(status)?.backgroundColor;

          const isEmptyCell = !cell?.value?.ModuleDate;
          const hasNames =
            cell?.value?.Name ||
            cell?.value?.meals ||
            Object.keys(cell?.value?.activity?.[0]?.activitiesAssigned || {})
              ?.length > 0;
          const calories = cell?.value?.meal?.calories ?? -1;

          let todaysFormattedDate = moment(
            cell?.value?.ModuleDate,
            "DD-MM-YYYY"
          ).format("YYYY-MM-DD");
          let todaysEvents = eventsData[todaysFormattedDate] ?? [];
          let currentEnduranceData = enduranceData?.[todaysFormattedDate];
          if (currentEnduranceData) {
            console.log(
              "what is current endurance data",
              currentEnduranceData,
              todaysFormattedDate
            );
            statusColor = "#CCFFE3";
          }
          console.log("empty cell with date", isEmptyCell, todaysFormattedDate);
          if (isEmptyCell)
            return (
              <Droppable
                droppableId={`droppable_${cell?.value?.ModuleDate}`}
                key={cell?.value?.ModuleDate}
              >
                {(provided, snapshot) => (
                  <td
                    {...cell.getCellProps()}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{
                      ...provided.droppableProps.style,
                      minWidth: cellWidth,
                      maxWidth: cellWidth,
                      // height: "auto",
                    }}
                    className={`${borderCss}`}
                  >
                    <div
                      className={`aspect-[1.24] calendarCell  h-full w-full group cellBox'}`}
                    ></div>
                    {provided.placeholder}
                  </td>
                )}
              </Droppable>
            );

          let primaryKeys = parsePrimaryKeys(cell?.value?.PrimaryKey);
          let workoutKinds = cell?.value?.workoutKind;
          console.log("workout kinds", workoutKinds);
          let workoutIds = cell?.value?.workoutIds;

          let more = 0;
          let workoutToShow = 2;
          if (cell?.value?.Name) {
            let namesCount = JSON.parse(cell?.value?.Name).length;
            let mealCout = cell?.value?.meals?.length ?? 0;
            let activityCount =
              (cell?.value?.activity ?? []).length > 0 &&
                cell?.value?.activity?.[0]?.activitiesAssigned &&
                Object.keys(cell?.value?.activity?.[0]?.activitiesAssigned ?? {})
                  .length > 0
                ? 1
                : 0;
            more = namesCount + mealCout + activityCount - 2;
            workoutToShow -= mealCout + activityCount;
          }

          let activity = cell?.value?.activity?.[0];
          let allActivities = cell?.value?.activity;
          let isModuleDateToday =
            cell?.value?.ModuleDate === moment().format("DD-MM-YYYY");
          console.log(
            "what is todays formatted date",
            cell.value,
            names,
            todaysFormattedDate
          );

          return (
            <Droppable
              droppableId={`droppable_${cell?.value?.ModuleDate}`}
              key={cell?.value?.ModuleDate}
            >
              {/* rendering each cell of the row */}
              {(provided, snapshot) => (
                <td
                  {...cell.getCellProps()}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{
                    ...provided.droppableProps.styles,
                    minWidth: cellWidth,
                    maxWidth: cellWidth,
                    // border:isModuleDateToday ?  "2px solid black ": borderCss ,
                  }}
                  className={
                    isModuleDateToday ? `${styles.todayDateBorder} ` : borderCss
                  }
                >
                  <div
                    className={`aspect-[1.24] calendarCell cursor-pointer h-full w-full group cellBox ${!hasNames &&
                      !isEmptyCell &&
                      !enduranceData?.[todaysFormattedDate] &&
                      "shouldHover"
                      } flex flex-col justify-between px-2 md:px-1 relative`}
                    onClick={() => {
                      !isDragging &&
                        (cell?.value?.Name ||
                          cell?.value?.meals ||
                          enduranceData[todaysFormattedDate])
                        ? history.push(
                          `/library/showWorkoutsCF/${cell?.value?.PrimaryKey
                          }?isCalendar=${true}&date=${cell?.value?.ModuleDate
                          }&userUUID=${userUUID}`,
                          {
                            meals: cell?.value?.meals,
                            habits: cell?.value?.activity,
                            enduranceData: currentEnduranceData,
                          }
                        )
                        : emptyFunction();
                    }}
                  >
                    <div
                      className={`flex items-center justify-between p-2 relative`}
                    >
                      {/* current date highligter */}
                      <div
                        style={{
                          background:
                            isModuleDateToday &&
                            "linear-gradient(to right, rgba(70, 79, 235, 0.4), rgba(131, 48, 233, 0.4))",
                          borderRadius: "50px",
                          height: 24,
                          width: 24,
                        }}
                        className="flex items-center justify-center text-blue-dark text-[14px] font-bold"
                      >
                        {cell?.value?.ModuleDate === 0
                          ? ""
                          : cell?.value?.ModuleDate.split("").splice(0, 2)}
                        {isModuleDateToday && (
                          <span
                            style={{
                              position: "absolute",
                              left: "40px",
                              color: "#6241ea",
                              fontSize: "12px",
                              fontWeight: "700",
                              fontFamily: "DM Sans",
                            }}
                          >
                            TODAY
                          </span>
                        )}
                      </div>

                      {hasNames && !copiedWorkout?.workoutId && (
                        <span
                          className={
                            "calAddBtn items-center justify-center hidden absolute h-[22px] w-[22px] top-2 right-2 gap-2 mr-5"
                          }
                        >
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              let copiedDayData = {
                                date: cell?.value?.ModuleDate,
                                meals: cell?.value?.meals,
                                activities: cell?.value?.activities,
                                workoutIDs: cell?.value?.workoutIDs,
                              };

                              dispatch(copyDay(copiedDayData));
                            }}
                          >
                            <Files size={18} />
                          </button>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              deleteFullDay({
                                primaryKeys,
                                meal: cell?.value?.meals,
                                activity: cell?.value?.activity,
                                workoutKinds,
                              });
                            }}
                          >
                            <Trash color="#C70000" size={18} />
                          </button>
                          <img
                            src={addButton}
                            alt="add button"
                            className="cursor-pointer"
                            onClick={
                              !isDragging
                                ? (e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  if (cell?.value?.ModuleDate) {
                                    setSelectedDate(cell?.value?.ModuleDate);
                                  }
                                  setShowWorkOutBuilder(true);
                                  setSelectedActivity(activity);
                                }
                                : emptyFunction()
                            }
                          />
                        </span>
                      )}
                      {copiedWorkout?.workoutId &&
                        copiedWorkout?.moduleDate !==
                        cell?.value?.ModuleDate && (
                          <div
                            className="absolute top-2 right-2 z-[999]"
                            onClick={(e) => {
                              e.stopPropagation();
                              pasteWorkout(cell?.value?.ModuleDate);
                            }}
                          >
                            <ClipboardCheck />
                          </div>
                        )}
                    </div>
                    {(cell?.value?.activity ?? []).length > 0 &&
                      cell?.value?.activity?.[0]?.activitiesAssigned &&
                      Object.keys(
                        cell?.value?.activity?.[0]?.activitiesAssigned ?? {}
                      ).length > 0 && (
                        <div className="flex justify-start p-2">
                          <div
                            className={`text-primaryBlue flex items-start truncate  rounded-full`}
                            style={{
                              background: "#EDEDED",
                              fontSize: "14px",
                              fontFamily: "DM Sans",
                              fontWeight: "500",
                              lineHeight: "20px",
                              color: "black",
                              padding: "4px 12px 4px 8px",
                            }}
                            onClick={() => {
                              history.push(
                                `/library/showWorkoutsCF/${cell?.value?.PrimaryKey
                                }?isCalendar=${true}&date=${cell?.value?.ModuleDate
                                }&userUUID=${userUUID}`,
                                {
                                  meals: cell?.value?.meals,
                                  habits: cell?.value?.activity,
                                }
                              );
                            }}
                          >
                            <img
                              src={activityImg}
                              alt="activity_icon"
                              style={{
                                height: "20px",
                                width: "20px",
                                marginRight: "4px",
                              }}
                            />
                            <div
                              className={`truncate rounded-full`}
                              style={{
                                fontSize: "14px",
                                fontFamily: "DM Sans",
                                fontWeight: "500",
                                lineHeight: "20px",
                                color: "black",
                              }}
                            >
                              Habits
                            </div>
                          </div>
                        </div>
                      )}
                    {cell?.value?.meals?.map((meal, index) => {
                      if (
                        (cell?.value?.Name?.length > 0 && index > 0) ||
                        (cell?.value?.Name?.length <= 0 && index > 1)
                      )
                        return <></>;
                      return (
                        <Draggable
                          key={`meal-${index}`}
                          draggableId={`meal@${JSON.stringify(
                            meal
                          )}@ModuleDate_${cell?.value?.ModuleDate}`}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                ...getStyle(
                                  provided.draggableProps.style,
                                  snapshot
                                ),
                                position: "static",
                                maxWidth: "100%",
                                alignItems: "start",
                              }}
                              className="flex justify-start p-2"
                            >
                              {/* macro based plan */}
                              <div
                                className={`flex items-center truncate `}
                                style={{
                                  // border: `1.5px solid #828282`,
                                  fontSize: "14px",
                                  fontFamily: "DM Sans",
                                  fontWeight: "500",
                                  lineHeight: "20px",
                                  color: "black",
                                  borderRadius: "24px",
                                  padding: "4px 12px 4px 8px",
                                  backgroundColor: "#EDEDED",
                                }}
                              >
                                <img
                                  src={mealImg}
                                  alt="exercise_icon"
                                  style={{
                                    height: "15px",
                                    width: "16px",
                                    marginRight: "4px",
                                  }}
                                />
                                {truncateLargeName(meal?.Name)}
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {cell?.value?.Name ? (
                      <div
                        style={{
                          position: "relative",
                          height: "auto",
                        }}
                        className={`rounded-b-lg flex flex-col justify-center items-start py-2`}
                      >
                        {names.map((name, index) => {
                          if (index >= workoutToShow) return <></>;
                          return (
                            <Draggable
                              key={index}
                              draggableId={`name@${JSON.stringify(
                                name
                              )}_index@${index}_ModuleDate@${cell?.value?.ModuleDate
                                }_PrimaryKey@${primaryKeys[index]}_workoutId@${workoutIds?.[index]
                                }`}
                              index={index}
                            >
                              {(provided, snapshot) => {
                                return (
                                  <div
                                    className="flex justify-end p-2"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      ...getStyle(
                                        provided.draggableProps.style,
                                        snapshot
                                      ),
                                      position: "static",
                                      maxWidth: "100%",
                                      alignItems: "start",
                                      height: "auto",
                                    }}
                                  >
                                    <ContextMenu>
                                      <ContextMenuTrigger
                                        disabled={copiedWorkout?.workoutId}
                                        className="max-w-full"
                                      >
                                        <div
                                          className={`flex items-center truncate  rounded-full relative`}
                                          style={{
                                            background: `${statusColor}`,
                                            fontSize: "14px",
                                            fontFamily: "DM Sans",
                                            fontWeight: "500",
                                            lineHeight: "20px",
                                            color: "black",
                                            padding: "4px 12px 4px 8px",
                                          }}
                                        >
                                          {copiedWorkout?.workoutId ===
                                            workoutIds?.[index] && (
                                              <div className="absolute top-0 left-0 h-full w-full bg-black-pure bg-opacity-60 text-white-pure text-center flex items-center justify-center">
                                                Copied
                                                <div
                                                  className="absolute top-1 right-1"
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    setCopiedWorkout(null);
                                                  }}
                                                >
                                                  <X size={16} />
                                                </div>
                                              </div>
                                            )}
                                          <div
                                            style={{ maxWidth: "100%" }}
                                            className="flex items-center justify-end"
                                          >
                                            <img
                                              src={
                                                name?.Name !== "cardio"
                                                  ? WorkoutPlanIcon
                                                  : "https://img.icons8.com/ios/50/running--v1.png"
                                              }
                                              alt="exercise_icon"
                                              style={{
                                                height: "20px",
                                                width: "20px",
                                                marginRight: "4px",
                                              }}
                                            />
                                            <div
                                              className={`truncate rounded-full`}
                                              style={{
                                                fontSize: "14px",
                                                fontFamily: "DM Sans",
                                                fontWeight: "500",
                                                lineHeight: "20px",
                                                color: "black",
                                              }}
                                            >
                                              {truncateLargeName(name.Name)}
                                            </div>
                                          </div>
                                        </div>
                                      </ContextMenuTrigger>
                                      <ContextMenuContent>
                                        <ContextMenuItem
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setCopiedWorkout({
                                              name: name?.Name,
                                              workoutId: workoutIds?.[index],
                                              primaryKey: primaryKeys?.[index],
                                              moduleDate:
                                                cell?.value?.ModuleDate,
                                            });
                                          }}
                                        >
                                          <Files /> Copy
                                        </ContextMenuItem>
                                      </ContextMenuContent>
                                    </ContextMenu>
                                  </div>
                                );
                              }}
                            </Draggable>
                          );
                        })}
                      </div>
                    ) : (
                      !cell?.value?.meals &&
                      Object.keys(
                        cell?.value?.activity?.[0]?.activitiesAssigned ?? {}
                      ).length == 0 &&
                      !enduranceData?.[todaysFormattedDate] && (
                        <span
                          className={
                            "flex-1 calAddBtn items-start justify-center hidden absolute top-0 right-0 left-0 bottom-0"
                          }
                        >
                          <img
                            src={addButton}
                            alt=""
                            className="m-auto cursor-pointer"
                            onClick={
                              !isDragging
                                ? (e) => {
                                  if (cell?.value?.ModuleDate) {
                                    setSelectedDate(cell?.value?.ModuleDate);
                                  }
                                  setShowWorkOutBuilder(true);
                                  setSelectedActivity({});
                                  e.preventDefault();
                                }
                                : emptyFunction()
                            }
                          />
                          {/* <button
                          onClick={() => {
                            console.log("endu endu",enduranceData,todaysFormattedDate);
                          }}
                          >see endu</button> */}
                        </span>
                      )
                    )}
                    {eventsData?.[todaysFormattedDate]?.length > 0 && (
                      <div className="flex justify-center  p-2 ">
                        <Tooltip
                          title={
                            eventsData[todaysFormattedDate]?.[0]?.event?.name
                          }
                        >
                          <div
                            className={`flex items-center text-[10px] truncate italic text-primaryBlue rounded-full text-sm px-2 py-[2px]`}
                            style={{
                              border: `1px solid #000`,
                              width: "fit-content",
                              alignSelf: "flex-end",
                            }}
                          >
                            <img
                              src={"https://img.icons8.com/badges/48/event.png"}
                              alt="activity_icon"
                              style={{
                                height: "15px",
                                width: "16px",
                                marginRight: "5px",
                              }}
                            />
                            {eventsData[todaysFormattedDate]?.[0]?.event?.name}
                            {eventsData[todaysFormattedDate]?.length - 1 > 0
                              ? "+ " +
                              eventsData[todaysFormattedDate]?.length -
                              1 +
                              "more"
                              : ""}
                          </div>
                        </Tooltip>
                      </div>
                    )}
                    {currentEnduranceData &&
                      currentEnduranceData.length > 0 &&
                      names.length === 0 && (
                        <div className="flex justify-center  p-2 ">
                          <div
                            className={`flex items-center truncate  rounded-full relative`}
                            style={{
                              backgroundColor: "#CCFFE3",
                              fontSize: "14px",
                              fontFamily: "DM Sans",
                              fontWeight: "500",
                              lineHeight: "20px",
                              color: "black",
                              padding: "4px 12px 4px 8px",
                            }}
                          >
                            <img
                              src={WorkoutPlanIcon}
                              alt="activity_icon"
                              style={{
                                height: "15px",
                                width: "16px",
                                marginRight: "5px",
                              }}
                            />
                            Endurance Workout
                          </div>
                        </div>
                      )}
                    {provided.placeholder}
                    {more > 0 && (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          position: "relative",
                          bottom: "10px",
                        }}
                      >
                        <div
                          style={{
                            border: "1px solid #CFCFCF",
                            padding: "1px",
                            borderRadius: "50%",
                          }}
                        >
                          <MoreHorizIcon />
                        </div>
                      </div>
                    )}
                  </div>
                </td>
              )}
            </Droppable>
          );
        })}
      </tr>
    );
  };

  return (
    <div className="relative w-full">
      {(loading || loadingParent) && (
        <div
          style={{
            backgroundColor: "#fff",
            opacity: 0.5,
            zIndex: 100,
          }}
          className=" absolute flex items-center justify-center h-full w-full"
        >
          <SpurfitCircularProgress />
        </div>
      )}
      {updatingWorkoutDate && (
        <div
          style={{
            zIndex: 100,
          }}
          className=" absolute flex items-center justify-center h-full w-full"
        >
          <SpurfitCircularProgress />
        </div>
      )}
      <table
        style={{
          display: "table",
          tableLayout: "fixed",
        }}
        {...getTableProps()}
        className="w-full"
        ref={calRef}
      >
        <thead
          style={{
            display: "table",
            tableLayout: "fixed",
          }}
        >
          {headerGroups.map((headerGroup) => (
            <tr
              style={{
                display: "table",
                tableLayout: "fixed",
                marginTop: "10px",
              }}
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column, index) => {
                let borderCss = "border-b-2";
                borderCss += index === 0 ? " border-l-0" : " border-l-2";
                return (
                  <th
                    {...column.getHeaderProps()}
                    className={`py-3 ${borderCss} border-t-2`}
                    style={{
                      minWidth: cellWidth,
                      maxWidth: cellWidth,
                      borderColor: "#F2F2F2",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "DM Sans",
                        fontSize: "14px",
                        fontWeight: "700",
                        lineHeight: "21px",
                        color: "#757575",
                        lineHeight: "16px",
                      }}
                    >
                      {column.render("Header").toUpperCase()}
                    </span>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <DragDropContext
          onDragStart={(result) => {
            setIsDragging(true);
          }}
          onDragEnd={(result) => {
            setIsDragging(false);
            let draggableId = result?.draggableId;
            let droppableId = result?.destination?.droppableId;

            if (draggableId?.includes("meal@")) {
              let meal = JSON.parse(draggableId.split("@")[1]);
              let sourceModuleDate = draggableId.split("@")[2].split("_")[1];
              let destinationModuleDate = droppableId.split("_")[1];
              if (sourceModuleDate === destinationModuleDate) {
                return;
              }

              let updatedData = [];
              let newAssignedId = uuidv4();
              data?.map((item) => {
                let updatedItem = {};
                Object.entries(item).map(([key, d]) => {
                  let day = { ...d };
                  if (day.ModuleDate === destinationModuleDate) {
                    let meals = day.meals || [];
                    meals.push({ ...meal, assignedId: newAssignedId });
                    day.meals = meals;
                    if (day.restDay) {
                      delete day.restDay;
                      delete day.train;
                    }
                    AssignMeal(
                      newAssignedId,
                      userUUID,
                      meal,
                      destinationModuleDate
                    );
                  } else if (day.ModuleDate === sourceModuleDate) {
                    let meals = day.meals || [];
                    let index = meals.findIndex(
                      (item) => item.assignedId === meal.assignedId
                    );
                    if (index > -1) {
                      meals.splice(index, 1);
                    }
                    if (meals.length === 0) {
                      delete day.meals;
                      day.restDay = true;
                      day.train = "";
                    } else {
                      day.meals = meals;
                    }
                  }
                  updatedItem[key] = day;
                  return day;
                });
                updatedData.push(updatedItem);
              });
              setData(updatedData);
              return;
            }

            const name = JSON.parse(
              draggableId.split("_")[0].split("@")[1]
            ).Name;
            const sourceModuleDate = draggableId.split("_")[2].split("@")[1];
            const PrimaryKey = draggableId.split("_")[3].split("@")[1];
            const workoutId = draggableId.split("_")[4].split("@")[1];

            const destinationModuleDate = droppableId.split("_")[1];

            if (sourceModuleDate === destinationModuleDate) {
              return;
            }
            let updatedData = [];
            let newPrimaryKey = uuidv4();
            data.map((it) => {
              let updatedItem = {};
              Object.entries(it).map(([key, d]) => {
                let day = { ...d };
                if (day.ModuleDate === destinationModuleDate) {
                  let names = day.Name ? JSON.parse(day.Name) : [];
                  let primaryKeys = parsePrimaryKeys(day.PrimaryKey);
                  let workoutIds = day.workoutIds || [];

                  names.push({
                    Name: name,
                    Done: false,
                  });

                  primaryKeys.push(newPrimaryKey);
                  workoutIds.push(workoutId);
                  day.Name = JSON.stringify(names);
                  day.PrimaryKey = JSON.stringify(primaryKeys);
                  day.workoutIds = workoutIds;

                  if (day.restDay) {
                    delete day.restDay;
                    delete day.train;
                  }
                  assignWorkout(
                    workoutId,
                    destinationModuleDate,
                    newPrimaryKey
                  );
                } else if (day.ModuleDate === sourceModuleDate) {
                  let names = day.Name ? JSON.parse(day.Name) : [];
                  let primaryKeys = parsePrimaryKeys(day.PrimaryKey);
                  let workoutIds = day.workoutIds;

                  let index = names.findIndex((item) => item.Name === name);
                  let pk = primaryKeys[index];
                  let workoutKind = day?.workoutKind?.[pk];
                  if (index > -1) {
                    names.splice(index, 1);
                    primaryKeys.splice(index, 1);
                    workoutIds.splice(index, 1);
                  }
                  if (names.length === 0) {
                    delete day.Name;
                    delete day.PrimaryKey;
                    delete day.workoutIds;
                    day.restDay = true;
                    day.train = "";
                  } else {
                    day.Name = JSON.stringify(names);
                    day.PrimaryKey = JSON.stringify(primaryKeys);
                    day.workoutIds = workoutIds;
                  }
                  deleteIndividualWorkout(pk, primaryKeys, workoutKind);
                }
                updatedItem[key] = day;
                return day;
              });

              updatedData.push(updatedItem);
            });

            setData(updatedData);
          }}
        >
          <tbody
            style={{
              height: "calc(100vh - 458px)",
              overflowY: "scroll",
              display: "block",
              tableLayout: "fixed",
              width: "100%",
            }}
            {...getTableBodyProps()}
          >
            {rows.map(renderTableRow)}
          </tbody>
        </DragDropContext>
      </table>
    </div>
  );
};

export default Calendar;
