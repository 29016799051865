import React, { useEffect, useState } from "react";
import { Dialog, DialogContent } from "@mui/material";
import { Chart } from "chart.js/auto";

import { Line } from "react-chartjs-2";
import moment from "moment";

const RenderStepsChart = ({ isOpen, setIsOpen, data }) => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Steps",
        data: [],
        fill: false,
        borderColor: "#0E9CFF",
        tension: 0.1,
      },
    ],
  });

  useEffect(() => {
    if (!data || data.length === 0) {
      return;
    }

    data.sort((a, b) => new Date(a.dateAssigned) - new Date(b.dateAssigned));

    const dateLabels = [];
    const stepsData = [];
    let lastDate = new Date();
    data.forEach((item) => {
      if (item?.activitiesFinished?.stepCount) {
        let modifiedDate = moment(item?.dateAssigned).format("Do MM' YY");
        dateLabels.push(modifiedDate);
        stepsData.push(item?.activitiesFinished?.stepCount);
        lastDate = item?.dateAssigned;
      }
    });

    // keep only the data for the last 7 days
    const last7Days = moment(lastDate).subtract(7, "days");
    const index = dateLabels.findIndex((item) =>
      moment(item, "Do MM' YY").isSame(last7Days, "day")
    );
    if (index !== -1) {
      dateLabels.splice(0, index);
      stepsData.splice(0, index);
    } else {
      dateLabels.splice(0, dateLabels.length - 7);
      stepsData.splice(0, stepsData.length - 7);
    }

    setChartData((prevChartData) => ({
      ...prevChartData,
      labels: dateLabels,
      datasets: [
        {
          ...prevChartData.datasets[0],
          data: stepsData,
        },
      ],
    }));
  }, [data]);

  const options = {
    scales: {
      y: {
        ticks: {
          display: false, // Hide y-axis values
        },
        grid: {
          display: false, // Hide vertical grid lines
        },
      },
      x: {
        ticks: {
          display: false, // Hide x-axis values
        },
        grid: {
          display: false, // Hide horizontal grid lines
        },
      },
    },
    plugins: {
      legend: {
        display: false, // Hide the legend (dataset visibility toggle)
      },
    },
    elements: {
      point: {
        radius: 5,
      },
    },
    borderWidth: 2,
  };

  return chartData?.labels?.length > 0 ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        maxHeight: "180px",
      }}
    >
      <Line data={chartData} options={options} width="350" height={180} />
    </div>
  ) : (
    <p
      style={{
        fontFamily: "DM Sans",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "29px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px 0px",
      }}
    >
      No data uploaded yet!
    </p>
  );
};

export default RenderStepsChart;
