import { CloseRounded } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import React, { useState } from 'react';
import Input from '../InputComponents/Input';
import { NotebookPen } from 'lucide-react';

 

function AddNotesDialog({
 isOpen,
  setIsOpen,  
  handleNotesSave, 
  preNotes
}) {
const [notes,setNotes]=useState(preNotes || "")

const handleSave=()=>{
   handleNotesSave(notes) 
   setIsOpen(false)
}

    return (
         <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      
      fullWidth
      PaperProps={{
        sx: {
          width:"500px",
          borderRadius: "24px",
        },
      }}
    >
         <div className="add-program-modal-head">
        <div style={{ display: "flex", alignItems: "flex-end", gap: "8px" }}>
          <p className="add-program-modal-head-title">
          Add Section Notes
          </p>
        </div>
        <div
          onClick={() => {
            setIsOpen(false);
           
         
          }}
          className="cursor-pointer"
        >
          <CloseRounded
            sx={{
              fontSize: "24px",
              color: "#000",
              cursor: "pointer",
            }}
          />
        </div>
      </div>

         <DialogContent>
        <div className="flex items-center justify-between ">
               <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "8px",
              }}
            >
              <div className="property-name ml-2">Notes</div>
              <textarea
                style={{
                  width: "450px",
                  height: "200px",
                  fontFamily: "DM Sans",
                  borderRadius: "12px",
                  border: "1px solid #CCC",
                  padding: "14px 16px",
                  resize: "none",
                  outline: "none",
                }}
                placeholder="instructions"
                 value={notes}
                onChange={(e) => {
                  
                  setNotes(e.target.value);
                }}
              />
            </div>
         
        </div>
         
      </DialogContent>
       <DialogActions sx={{ justifyContent: "center" }}>
        <button
          className="button-new"
          onClick={()=>handleSave()}
        >
     {preNotes ? "Update": "Save" }
        </button>
      </DialogActions>
    </Dialog>
    );
}

export default AddNotesDialog;