import React, { useEffect, useRef, useState } from "react";
import Layout from "../../components/layout/Layout";
import { makeStyles } from "@mui/styles";
import {
  Box,
  TextField,
  Card,
  CardContent,
  FormControl,
  Button,
} from "@mui/material";
import "./style.css";
import FormField from "./components/FormField";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { store } from "../../redux/store";
import { singleSelect, multiline, options, multiSelect, numberSelect, DateSelect } from "./formdata";
import { SpurfitCircularProgress } from "../../components/Dashboard/dashboard";
import { getFormById, updateForm, getCoachLeadForm, updateCoachLeadsForm } from "@/ApiServices/FormControl/Api";
import FormSubmission from "./FormSubmission";
import { leadPresetData } from "@/constants";
import FormPreviewDialog from "./components/FormPreviewDialog";
import { formButtonsData } from "@/constants";
import ActionsButtons from "./components/ActionsButtons";
import { toast } from "react-toastify";
import AlertExitDialog from "./components/AlertExitDialog";
import { useSelector } from "react-redux";
import { setUserEditFormFunc, resetUserFormFunc } from "@/redux/formSlice";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "70%",
    flexDirection: "column",
  },
  card: {
    borderRadius: 10,
    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
    transition: "0.3s",
    width: "100%",
    //   marginTop:20,
  },
  cardContent: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
  },
  input: {
    marginBottom: theme.spacing(5),
  },
  button: {
    marginTop: theme.spacing(2),
  },
  marginT: {
    marginTop: 10,
  },
}));

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function DynamicFormControl() {
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Form");
  const query = useQuery();
  const dispatch = useDispatch()
  const { id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const formName = queryParams.get("formName");
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = useState(false);
  const [isCopy, setCopy] = useState(false);
  const [formData, setFormData] = useState([]);
  const [fields, setFields] = useState([]);
  const [firstLoad, setFirstLoad] = useState(false);
  const [isSubmittedForm, setIsSubmittedForm] = useState(false);
  const [emptyFields, setEmptyFields] = useState([])
  const history = useHistory();
  let filterTab = ["Form", "Submissions"];
  const currentHostname = window.origin;

  let expandedViewsState = useSelector((state) => state.data.isExpanded);
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    return () => {
      dispatch(setUserEditFormFunc(false))
      dispatch(resetUserFormFunc())
    }
  }, [])

  const addField = (type) => {

    setFields([
      ...fields,
      {
        id: fields?.length + 1,
        type,
        value: "",
        possibleAnswers:
          type == "Single Select" || type == "Multi Select" ? ["", ""] : [],
        required: false,
      },
    ]);
  };

  const setSelectedForm = (form) => {
    console.warn(form, "form*****");
    setFields(form);
  };

  useEffect(() => {
    if (formName)
      getForm()
    else {
      getLeadForm()
    }
  }, [])

  async function getForm() {
    setLoading(true)
    const forms = await getFormById(id);
    console.warn(forms, "forms*******");
    if (forms && forms.result) {
      if (forms?.result?.form?.length > 0) {
        setFields(forms?.result?.form);
      } else {
        setFields([
          {
            id: 1,
            type: "Single Select",
            value: "",
            possibleAnswers: ["", ""],
            required: false,
          },
        ]);
      }
    }
    setLoading(false);
  }

  async function getLeadForm() {
    setLoading(true)
    const coachForm = await getCoachLeadForm(id)
    if (coachForm && coachForm.result && coachForm?.result?.leadsQuestionnaire?.length) {
      setFields(coachForm?.result?.leadsQuestionnaire)
    } else {
      setFields([...leadPresetData])
    }
    setLoading(false)
  }

  const handleFieldChange = (id, key, value, index) => {
    console.log({ key, value, index, id })
    if (key == "possibleAnswers") {
      console.warn("possibleANswers", [fields[0][key][index]], value);
      const myfields = fields.map((field) => {
        if (field.id == id) {
          field[key][index] = value;
        }
        return field;
      });
      console.warn(myfields, fields);
      setFields(myfields);
    } else {
      setFields(
        fields.map((field) =>
          field.id === id ? { ...field, [key]: value } : field
        )
      );
    }
  };

  const handleAddAnswer = (id) => {
    console.warn("add", id);
    const myfields = fields.map((field) => {
      console.log({ fields })
      if (field.id == id) {
        field["possibleAnswers"].push("");
      }
      return field;
    });
    setFields(myfields);
  };

  const handleRemoveAnswer = (id, index) => {
    console.warn("remove", id);
    const myfields = fields.map((field) => {
      if (field.id == id) {
        field["possibleAnswers"].splice(index, 1);
      }
      return field;
    });
    setFields(myfields);
  };

  const handleFieldRemove = (id) => {
    const updatedFields = fields.filter((field) => field.id !== id);

    const reIndexedFields = updatedFields.map((field, index) => ({
      ...field,
      id: index + 1,
    }));
    setFields(reIndexedFields);
  };

  const handleChange = (e) => {
    console.log(e, "error");
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  //check whether al/ questions are filled or not

  const handleCheckAllQuestions = () => {
    const emptyFieldIds = fields
      ?.filter(field => !field.value)
      .map(field => field.id);

    setEmptyFields(emptyFieldIds)

    const questionsTypesToCheck = ['Single Select', 'Multi Select']

    return fields?.every(field => field.value &&
      (!questionsTypesToCheck.includes(field?.type) ||
        (questionsTypesToCheck.includes(field?.type) && field?.possibleAnswers?.every(answer => answer)))
    );


  }

  /* HITTING API TO UPDATE FORM*/
  const handleSubmit = async (e) => {
    console.warn("here is handle sumbit");
    e.preventDefault();
    // Add your form submission logic here
    if (handleCheckAllQuestions()) {
      const body = {
        id: id,
        form: fields,
      };
      const res = await updateForm(body);
      if (res?.status == 200) {
        toast.success("Form Submitted SuccessFully.");
      }
      history.push("/formcontrol");
    }
    else {
      setShowError(true)
      toast.error("Please Fill All Questions Fields.");
    }
  };

  const handleUpdateLeadsForm = async (e) => {
    e.preventDefault()

    if (handleCheckAllQuestions()) {
      const data = {
        coachID: id,
        leadsQuestionnaire: fields
      }

      const res = await updateCoachLeadsForm(data)
      if (res?.status == 200) {
        toast.success("Form Submitted SuccessFully.");
        history.push("/formcontrol");
      }
    }
    else {
      setShowError(true)
      toast.error("Please Fill All Questions Fields.");
    }
  }

  const handleMenuItemClick = (index) => {

    // onSelect(option); // Call the onSelect function with the chosen option
    setSelectedIndex(index);
    addField(options[index].type);
    setMenuOpen(!menuOpen);
  };
  const handleCopy = () => {
    console.warn("inside copu");
    const textToCopy = `${currentHostname}/userform?formId=${id}&formName=${formName}`; // Replace 'Text to copy' with the text you want to copy
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        console.warn("Text copied successfully");
        setCopy(true);
        // You can add additional logic here, such as showing a success message
      })
      .catch((error) => {
        console.warn("Error copying text: ", error);
        // You can add additional logic here, such as showing an error message
      });
  };

  // for Preview Dialog 
  const [previewDialog, setOpenPreviewDialog] = useState(false)

  const handlePreviewEvent = () => {
    setOpenPreviewDialog(true)
  }

  const handleClosePreviewEvent = () => {
    setOpenPreviewDialog(false)
  }

  const checkForFunction = ({ e, name }) => {
    switch (name) {
      case 'save':
        return !formName ? handleUpdateLeadsForm(e) : handleSubmit(e)
      case 'preview':
        return handlePreviewEvent()
      case 'copy':
        return handleCopy()
    }
  }

  const getQuestionType = ({ option }) => {
    switch (option) {
      case 'Single Select':
        return singleSelect
      case 'Multi Select':
        return multiSelect
      case 'Date':
        return DateSelect
      case 'Multi-line text':
        return multiline
      case 'Single-line text':
        return multiline
      default:
        return numberSelect
    }
  }

  return (
    <>
      <Layout>
        <div
          style={{
            padding: "16px 20px px 20px",
            width: "100%",
            borderBottom: "1px solid #E5E5E5",
          }}
        >
          <div
            style={{
              borderRadius: 10,
              backgroundColor: "#fff",
              padding: "8px 24px 8px 8px",
              display: "flex",
              alignItems: "center",
              gap: 16,
              height: "56px",
              justifyContent: "space-between",
            }}
          >
            <div className="flex gap-4">
              {filterTab?.map((tab) => {
                const isDisabled = tab === 'Submissions' && (!formName || !isSubmittedForm);

                return (
                  <div
                    className="chip"
                    style={{
                      cursor: isDisabled ? "not-allowed" : "pointer",
                      background:
                        selectedTab === tab
                          ? "linear-gradient(90deg, #464feb 10.42%, #8330e9 100%)"
                          : isDisabled
                            ? "#e0e0e0"
                            : "#F7F7F7",
                      color: isDisabled ? "#9e9e9e" : selectedTab === tab ? "#fff" : "#000",
                      minWidth: "76px",
                      opacity: isDisabled ? 0.6 : 1,
                    }}
                    onClick={() => {
                      if (!isDisabled) {
                        setSelectedTab(tab);
                      }
                    }}
                  >
                    {tab}
                  </div>
                );
              })}
            </div>

            {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 16,
            }}
          >
            <button
              onClick={handleSubmit}
              className="add-btn-input__button-gradient"
            >
              {" "}
              Save Form
            </button>
          </div> */}
          </div>
        </div>

        {selectedTab == "Form" ? (
          <div
            className="flex flex-col justify-normal w-full overflow-auto bg-white-pure h-120"
            style={{
              borderRadius: "12px",
              height: "calc(100vh - 110px)",
              scrollBehavior: 'smooth'
            }}
            onClick={() => (menuOpen ? setMenuOpen(!menuOpen) : null)}
          >
            {loading && (
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  backgroundColor: "rgba(0,0,0,0.3)",
                  borderRadius: "0px 0px 16px 16px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  zIndex: 99,
                }}
              >
                <SpurfitCircularProgress />
              </div>
            )}
            <div className="flex flex-row mb-12">
              <div className={classes.root}>
                <form
                  className="w-full"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <FormControl
                    style={{
                      paddingLeft: 16,
                      paddingRight: 16,
                    }}
                    fullWidth
                  >
                    {fields.map((field, idx) => (
                      <FormField
                        key={field.id}
                        field={field}
                        idx={idx}
                        onChange={handleFieldChange}
                        addAnswer={handleAddAnswer}
                        removeField={handleFieldRemove}
                        removeAnswer={handleRemoveAnswer}
                        handleCheckAllQuestions={handleCheckAllQuestions}
                        fields={fields}
                        id={field.id}
                        isLoading={loading}
                        emptyFields={emptyFields}
                        showError={showError}
                      />
                    ))}
                    <div className="w-full mt-4 mx-auto" >
                      <div className="relative z-20 mx-auto">
                        {menuOpen && (
                          <div
                            className={`absolute overflow-hidden text-center left-1/2 transform -translate-x-1/2 w-1/2 py-1 f-sm font-normal rounded-md bg-white-pure text-gray-500 box-card-shadow shadow-menu ${!fields.length ? 'top-full mt-1' : '-top-1 -translate-y-full'
                              }`}
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="headlessui-menu-button-4"
                            tabIndex="-1"
                          >
                            {options.map((option, index) => (
                              <a
                                key={option.id}
                                style={{
                                  background:
                                    selectedIndex === index &&
                                    "rgb(246, 246, 254)",

                                }}
                                className="block py-2 pl-4 pr-6 font-medium bg-white text-gray-600"
                                id={`headlessui-menu-item-${option.id}`}
                                role="menuitem"
                                tabIndex="-1"
                                onClick={() => handleMenuItemClick(index)}
                                onMouseEnter={() => setSelectedIndex(index)}
                              >
                                <div className="flex items-center space-x-1">
                                  <div className="flex items-center space-x-2">
                                    <div className="f-xs bg-purple-100 font-medium text-purple-800 inline-flex items-center space-x-2 rounded-[4px] py-1 px-1" style={{ display: formName && option.type == 'Height' ? 'none' : 'block' }}>
                                      {getQuestionType({ option: option.type })}
                                    </div>
                                    <span className="f-sm font-medium text-gray-900" style={{ display: formName && option.type == 'Height' ? 'none' : 'block' }}>
                                      {option.type}
                                    </span>
                                  </div>
                                </div>
                              </a>
                            ))}
                          </div>
                        )}
                      </div>
                      <button
                        className="inline-flex f-sm items-center justify-center  px-5 rounded-lg whitespace-nowrap question-btn hover:#464feb bg-gray-200 w-full"
                        id="headlessui-menu-button-4"
                        style={{
                          alignSelf: "center",
                          fontSize: ".9rem",
                          wordSpace: "nowrap",
                          backgroundColor: "#E5E7EB",
                          padding: ".5rem 1.5rem",
                          border: "1px solid #E5E7EB",
                          textTransform: "capitalize",
                          width: '80%',
                          marginLeft: '10%',
                          background:
                            "linear-gradient(to right, rgb(70, 79, 235), rgb(131, 48, 233))",
                          color: '#ffffff',

                        }}
                        aria-haspopup="menu"
                        aria-expanded={menuOpen}
                        data-headlessui-state={menuOpen ? 'open' : ''}
                        aria-controls="headlessui-menu-items-3"
                        onClick={() => {
                          setMenuOpen(!menuOpen)
                          !fields?.length && setShowError(false)
                        }}
                      >
                        <span style={{ borderRadius: 2 }}>Add question</span>
                        <span className="pl-2">
                          <svg width="10" height="10" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ verticalAlign: 'middle' }}>
                            <rect x="7" width="2" height="16" fill="gray-900"></rect>
                            <rect x="16" y="7" width="2" height="16" transform="rotate(90 16 7)" fill="gray-900"></rect>
                          </svg>
                        </span>
                      </button>
                    </div>
                  </FormControl>
                </form>
              </div>
              <div style={{ width: '30%', display: 'flex', flexDirection: 'column', marginTop: 30, position: 'fixed', right: expandedViewsState ? '-2rem' : '-1rem' }}>
                {
                  formButtonsData.map((data) => <ActionsButtons
                    buttonName={data?.name == 'copy' ? !isCopy ? 'copy' : 'copied' : data?.name}

                    handClickEvent={(e) => checkForFunction({ e, name: data.name })}
                    sx={{
                      padding: '.4rem 2rem',
                      display: 'inline-block',
                      borderRadius: 2.3,
                      color: data.color,
                      background: data.background,
                      margin: '.2rem 0',
                      textTransform: "capitalize",
                      width: '60%',
                      '&:hover': {
                        backgroundColor: data.hover
                      }
                    }}
                    length={fields?.length}
                  />)
                }
              </div>
            </div>
          </div>
        ) : (
          <div>
            <FormSubmission formId={id} setSelectedForm={setSelectedForm} setIsSubmittedForm={setIsSubmittedForm} />
          </div>
        )}
      </Layout>
      <FormPreviewDialog
        open={previewDialog}
        handleClose={handleClosePreviewEvent}
        fields={fields}
      />
      <AlertExitDialog />
    </>
  );
}

export default DynamicFormControl;
