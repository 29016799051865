import React from "react";
import { Dialog, DialogContent } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ViewFieldType from "../ClientsView/ViewFieldType";

const ViewLeadsFormModal = ({ isOpen, handleClose, data }) => {
  return (
    <>
      <Dialog
        sx={{
          backdropFilter: "blur(5px)",
        }}
        PaperProps={{
          sx: { borderRadius: "16px" },
        }}
        fullWidth
        open={isOpen}
        onClose={() => {
          handleClose();
        }}
      >
        <div className="add-program-modal-head">
          <p className="add-program-modal-head-title">Lead Details</p>
          <CloseRoundedIcon
            sx={{
              position: "absolute",
              right: 24,
              top: 24,
              cursor: "pointer",
            }}
            onClick={() => {
              handleClose();
            }}
          />
        </div>
        {/* <DialogContent
                    sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                >
                    {
                        data?.answeredForm?.map(item => (
                            <ViewFieldType question={item?.question} answer={item?.answer} />
                        ))
                    }
                </DialogContent> */}
        <DialogContent>
          {data?.answeredForm?.length &&
            data?.answeredForm?.map((item) => <ViewFieldType field={item} />)}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ViewLeadsFormModal;
