import React from "react";

export const HashIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'>
    <path
      d='M3.33337 7.5H16.6667'
      stroke='black'
      stroke-width='1.5'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
    <path
      d='M3.33337 12.5H16.6667'
      stroke='black'
      stroke-width='1.5'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
    <path
      d='M8.33341 2.5L6.66675 17.5'
      stroke='black'
      stroke-width='1.5'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
    <path
      d='M13.3334 2.5L11.6667 17.5'
      stroke='black'
      stroke-width='1.5'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
  </svg>
);
