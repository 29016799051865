export const InputWithLabel = ({
  label,
  placeholder,
  style,
  state,
  setState,
  type,
  disabled,
  unit,
  inModal = false,
}) => {
  return (
    <div
      style={{
        width: "257px",
        background: "#FFFFFF",
        borderRadius: "8px",
        ...style,
      }}
      className="text-blue-main"
    >
      {label && (
        <label
          htmlFor="inputField"
          style={{ display: "block", fontSize: 10, marginLeft: 12 }}
        >
          {label}
        </label>
      )}
      <div
        style={{
          height: "40px",
          borderRadius: "5px",
          border: `1px solid ${inModal ? "#d9d9d9" : "#071148"}`,
          padding: "10px",
          outline: "none",
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <input
          type={type ?? "text"}
          id="inputField"
          placeholder={placeholder}
          disabled={disabled}
          className="placeholder-blue-main placeholder-opacity-50 border-transparent focus:border-transparent focus:ring-0"
          value={state}
          style={{
            width: "100%",
            outline: "none",
          }}
          onChange={(e) => setState(e.target.value)}
        />
        {unit && (
          <label
            htmlFor="inputField"
            style={{ display: "block", fontSize: 14 }}
          >
            {unit}
          </label>
        )}
      </div>
    </div>
  );
};
