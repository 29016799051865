import { CloseRounded } from "@mui/icons-material";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import React, { useState } from "react";
import Input from "../InputComponents/Input";
import TextArea from "../InputComponents/TextArea";

const DuplicateMealSchedule = ({
  isOpen,
  setIsOpen,
  mealName,
  handleDuplicate,
  addAsTemplate,
}) => {
  const [name, setName] = useState(mealName + " (copy)");
  const [description, setDescription] = useState("");

  const handleClose = () => {
    setIsOpen(false);
    setName("");
    setDescription("");
  };

  const handleSave = () => {
    handleDuplicate({
      name,
      description,
      ...(addAsTemplate && { isTemplate: true }),
    });
    handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        sx: {
          // padding: "24px",
          width: "320px",
          borderRadius: "20px",
        },
      }}
    >
      <DialogContent sx={{ padding: 0 }}>
        <div className="add-program-modal-head">
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <p className="add-program-modal-head-title">
              Create duplicate meal schedule
            </p>
          </div>
          <div onClick={() => setIsOpen(false)}>
            <CloseRounded sx={{ cursor: "pointer" }} />
          </div>
        </div>
        <div className="add-program-modal-body">
          <Input
            label={"Meal Name"}
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="text"
          />

          <TextArea
            label={"Description"}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            numRows={3}
          />
        </div>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "center",
        }}
      >
        <button className="button-new" onClick={handleSave}>
          Save
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default DuplicateMealSchedule;
