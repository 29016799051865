import { useEffect } from "react";

const useOutsideClick = (ref, callback, excludedRefs = []) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      const shouldExclude =
        excludedRefs.some((excludedRef) =>
          excludedRef.current?.contains(event.target)
        ) || ref.current?.contains(event.target);

      if (!shouldExclude) {
        callback();
      }
    };

    const handleEscapeKey = (event) => {
      if (event.keyCode === 27) {
        callback();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscapeKey);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [ref, callback, excludedRefs]);
};

export default useOutsideClick;
