//import react pro sidebar components
import { Sidebar, Menu, MenuItem, sidebarClasses } from "react-pro-sidebar";
import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import SearchBar from "@/components/Dashboard/SearchBar";
import "./style.css";
import CloseIcon from "@mui/icons-material/Close";
import ArrowPopper from "./components/ArrowPopper";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
// import {Bar, Line} from 'react-chartjs-2';
import chartData from "./chartData";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { getActivityDataForDateRange } from "@/ApiServices/ChartsLibrary/Api.js";
import Dialog from "@mui/material/Dialog";
import RenderBarChart from "@/components/ClientsView/chatdashbaord/RenderBarChart.jsx";
import RenderLineChart from "@/components/ClientsView/chatdashbaord/RenderLineChart.jsx";
import Slide from "@mui/material/Slide";
import { ChevronsLeft, ChevronsRight } from "lucide-react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function Dashboard({
  chartView,
  setChartView,
  chartfrom,
  activityData,
  onDragEnd,
  closechart,
}) {
  // get user uuid from url
  console.log(activityData, chartView, "FETCHING DATA TO GET RETRIEVE ***");
  const location = useLocation();
  const urlPrams = new URLSearchParams(location.search);
  const userUUID = urlPrams.get("userUUID");

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [list, setChartlist] = React.useState(chartData);
  // const [chartfrom, setChartfrom] = React.useState(
  //     moment().subtract(7, "days").format("D MMMM YYYY")
  // );
  // const [chartto, setchartto] = React.useState(
  //     moment().format("D MMMM YYYY")
  // );
  const [value, setValue] = React.useState(null);
  const dateData = ["Last Week", "Last Month", "Last 3 Months", "Custom Date"];
  const [arrowRef, setArrowRef] = React.useState(null);
  const [fromDate, setFromDate] = useState(
    moment().subtract(7, "days").toDate()
  );
  const [toDate, setToDate] = useState(moment().toDate());
  const [open, setOpen] = React.useState(false);
  const history = useHistory();

  const handleClick = (event) => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  /* HANDLING THE CLOSE EVENT OF THE CHART */
  const _closechart = (index) => {
    console.warn("CLOSE FIRED ********", index);
    closechart(index);
    // const newChartView = [...chartView];
    // newChartView.splice(index, 1);
    // setChartView(newChartView);
  };

  const request = (searchTerm) => {
    if (searchTerm === "") {
      setChartlist(chartData);
      return;
    }
    const newChart = chartData.filter((chart) => {
      return chart?.title?.toLowerCase().includes(searchTerm.toLowerCase());
    });
    setChartlist(newChart);
  };

  const cancel = () => {
    setChartlist(chartData);
  };

  const menuIconClick = () => {
    //condition checking to change state from true to false and vice versa
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
  };

  /*	THIS FUNCTION WILL CHANGE FROM AND TO DATE OF CHART DATA */
  // const updateChart = async() => {
  //     console.warn("inside update chart");
  //     try {
  //         setchartto(moment(toDate).format("D MMMM YYYY"));
  //         setChartfrom(moment(fromDate).format("D MMMM YYYY"));

  //         const res = await retriveActivityDataForGivenDateRange(fromDate, toDate);
  //         console.warn(res, "response from retrive")
  //         updateChartViewData()
  //         setOpen(false);
  //         console.error("setchartto update chart setChartfrom");
  //     } catch (e) {
  //         console.warn("error in update chart", e);
  //     }
  // };

  function handleGoback() {
    localStorage.setItem("selectedTab", "chat");
    history.goBack();
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div
        style={{
          display: "flex",
          justifyItems: "flex-start",
          flexDirection: "row",
          height: "100%",
          borderRadius: "0px 0px 16px 16px",
        }}
        id="header"
      >
        {/* collapsed props to change menu size using menucollapse state */}
        {/* <div className="tab">
					<div
						className="chart"
						style={{
							backgroundColor: menuCollapse ? "#fff" : "#F2F2F2",
						}}
						onClick={menuIconClick}
					>
						<div style={{ textAlign: "center" }}>
							<SignalCellularAltIcon />
						</div>
						<div
							style={{ fontSize: 10, textAlign: "center" }}
							variant="text"
						>
							Training Analytics
						</div>
					</div>
				</div> */}
        {/* <div
					onClick={() => handleDashboardClose()}
					class="absolute top-2 right-2 pointer-events-auto z-10 cursor-pointer"
				>
					<CloseIcon />
				</div> */}
        <div style={{ height: "100%", position: "relative" }}>
          <div
            onClick={menuIconClick}
            className={`${
              menuCollapse ? "absolute -right-6" : "absolute -right-4"
            } bg-white-pure rounded-full shadow-xl p-[0.5px] cursor-pointer z-20 mt-1`}
          >
            {!menuCollapse ? <ChevronsLeft /> : <ChevronsRight />}
          </div>
          <Sidebar
            rootStyles={{
              [`.${sidebarClasses.container}`]: {
                height: window.innerHeight + "px",
                borderLeft: "2px solid rgba(0, 0, 0, 0.05)",
                borderRight: "2px solid rgba(0, 0, 0, 0.05)",
              },
            }}
            collapsed={menuCollapse}
            toggled={false}
            width="270px"
            backgroundColor="white"
            collapsedWidth="0px"
          >
            <div style={{ padding: 20 }}> Training Analytics</div>
            <SearchBar
              placeholder={"Search for chart"}
              searchTerm={searchText}
              setsearchTerm={setSearchText}
              bgcol={"#f2f2f2"}
              request={request}
              cancel={cancel}
              style={{ flex: 1 }}
              width={"100%"}
              fontSize={"16px"}
              iconStyle={{ width: "20px", height: "20px" }}
            />
            <div class="p-2 pb-4 text-gray-950 text-font12 italic font-extrabold">
              Drag Charts to dashboard
            </div>
            <Droppable droppableId="chart-list" isDropDisabled={true}>
              {(provided, snapshot) => (
                <Menu
                  style={{ borderTop: "1px solid #cfd8e3" }}
                  iconShape="square"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {list.map((c, index) => {
                    return (
                      <Draggable
                        key={c.id + "i"}
                        draggableId={c.id + "i"}
                        index={index}
                      >
                        {(provided) => (
                          <MenuItem
                            rootStyles={{
                              borderBottom: "1px solid #cfd8e3",
                              fontSize: 12,
                            }}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            active={true}
                            style={{
                              ...provided.draggableProps.style,
                              opacity: snapshot.isDragging ? 0.5 : 1, // Ensure the item remains visible while dragging
                            }}
                          >
                            {c.icon} {c.title}
                          </MenuItem>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </Menu>
              )}
            </Droppable>
          </Sidebar>
        </div>
        <div className="chat-container">
          <div className="scroll-container">
            <div className="main-view">
              <Droppable droppableId="chart-view">
                {(provided) => (
                  <div
                    className="scroll-main"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    <div className="chart-scroll-view">
                      {/* <div class="text-center">Chart will display here</div> */}

                      {chartView.map((view, index) => {
                        return (
                          <div className="chartV1">
                            <CloseIcon
                              className="closev1"
                              fontSize="18"
                              color="gray"
                              onClick={() => _closechart(index)}
                            />
                            <Draggable
                              key={view.id}
                              draggableId={view.id}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  className="p-2"
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  {view.type == "BAR" ? (
                                    <RenderBarChart
                                      data={view.chart.data}
                                      xKey={"xVal"}
                                      yKey={"value"}
                                      title={view.title}
                                      type={view.type}
                                      formatter={(value)=> view.title == "Distance by Week"  ? formatDistance(value): value}
                                      label={view.title == "Distance by Week"  ? 'KM': undefined}
                                    />
                                  ) : (
                                    // <Line height={290} data={view.chart.data} options={view.chart.options}
                                    //       style={{width: 370, height: 290}}/>
                                    //     <LineChart width={370} height={290} data={view.chart.data}
                                    //   margin={{top: 5, right: 30, left: 20, bottom: 5}}>
                                    //   <CartesianGrid strokeDasharray="3 3"/>
                                    //   <XAxis dataKey="xVal"/>
                                    //   <YAxis/>
                                    //   <Tooltip/>
                                    //   <Legend/>
                                    //   <Line type="monotone" dataKey="value" stroke="#8884d8"/>
                                    // </LineChart>
                                    <RenderLineChart
                                      data={view.chart.data}
                                      xKey={"xVal"}
                                      yKey={"value"}
                                      title={view.title}
                                      type={view.type}
                                      formatter={(value)=> view.title == "Longest Workout - Distance" ? formatDistance(value): view.title == "Longest Workout - Duration" ? secondsToTime(value): value }
                                      label={view.title == view.title == "Longest Workout - Distance" ? 'KM': undefined}
                                    />
                                  )}
                                </div>
                              )}
                            </Draggable>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </Droppable>
            </div>
          </div>
        </div>
      </div>
    </DragDropContext>
  );
}

export default Dashboard;

const formatDistance = (meters) => {
  if (!meters) return "0";
  return (meters / 1000).toFixed(1);
};

function secondsToTime(seconds) {
  const hours = Math.floor(seconds / 3600);
  const remainingSeconds = seconds % 3600;
  const minutes = Math.floor(remainingSeconds / 60);
  const secondsLeft = remainingSeconds % 60;

  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = secondsLeft.toString().padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}