 
import React, { useContext, useEffect, useState } from "react";
import AddFolder from "./AddFolder";
import { workoutContext } from "./WorkoutContex";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import { ClipboardPaste, CopyX, Folder, FolderOpen, MoreVertical, Salad } from "lucide-react";
import WorkoutList from "./WorkoutList";
import { Collapse, Paper } from "@mui/material";
import DropDownMenu from "../Programs/DropDownMenu";
import WhitePlusIcon from "@/pages/library/assests/WhitePlusIcon";
import WorkoutInfoDialog from "@/components/WorkoutComponent/WorkoutInfoDialog";
import FilterWorkoutDialog from "./FilterWorkoutDialog";
import FilterActiveIcon from "../../assets/workouts/ActiveFunnel.svg";
import FilterIcon from "../../assets/workoutBuilder/filterIcon.svg";
import { SpurfitCircularProgress } from "@/components/Dashboard/dashboard";
import { ArrowDownWideNarrow  } from "lucide-react";
import { CloseIcon } from "stream-chat-react";
import { sortBy } from "lodash";
import { useDispatch } from "react-redux";
import { setWorkoutLibaryData } from "@/redux/dataSlice";
import { cn } from "@/lib/utils";
import Run from "@/assets/run.png"
import Dumbell from "@/assets/dumbell3.png"
import { TextField, IconButton } from "@mui/material";
import { Search, Clear } from "@mui/icons-material";
import SearchIcon from "../../assets/dashboard/searchIcon.svg";
 
 
function FolderList({ filterWorkoutsByTags }) {
  const {
    folders,
    setFolders,
    openFolderId,
    setOpenFolderId,
    folderSelected,
    setFolderSelected,
    workoutSelected,
    setWorkoutSelected,
    deleteItemFromTheFolder,
    transferItemBetWeenTheFolder,
    isShifting,
    setIsShifting,
    itemToShift,
    setItemToShift,
    originalData,
    setOriginalData,
    requestSearch,
    cancelSearch,
    tempFolderData,
    createNewFolder,
    setOpenWorkoutBuilder,
    openWorkoutBuilder,
    handleDataSettingInTable,
    allTags,
    selectedFilterOptions,
    isLoading,
    setTempData,
    tempData,
    setTempFolderData
  } = useContext(workoutContext);
  const [isDown, setIsDown] = useState(false);
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [showSortingDialog,setShowSortingDialoge]=useState(false)
  const [sortingMatrix,setSortingMatrix]=useState("")
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch=useDispatch()
  const sortingTag=["A-Z","Z-A","Date Created"]
  const handleToggleFolder = (folderId) => {
    setOpenFolderId((prevId) => (prevId === folderId ? null : folderId));
    setIsDown((prev) => !prev);
  };

  useEffect(()=>{
  if(folders?.length === 0 && tempFolderData?.length === 0){
    if(tempData?.length > 0){
      setWorkoutSelected(tempData[0])
    }
    
  }
  },[tempData,folders,tempFolderData])


  function sortData(data, sortBy) {
    let sortedData;

    if (sortBy === "A-Z") {
      sortedData = data.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
    } else if(sortBy === "Z-A"){
      sortedData = data.sort((a, b) => {
        if (a.name > b.name) return -1;
        if (a.name < b.name) return 1;
        return 0;
      });
      
    }
    else if (sortBy === "Date Created") {
      sortedData = data.sort((a, b) => {
        const dateA = new Date(a.dateCreated);
        const dateB = new Date(b.dateCreated);
        return dateB - dateA;
      });
    } else {
      return data; // If the sortBy field is not recognized, return the data as is
    }

    

    return sortedData;
  }
  
  
  const sortWorkouts=(tag)=>{
    handleClear()
    let data = [...originalData]
    let folderData=[...folders]
       
    const sortedDataForWorkout=sortData(data, tag);
    const sortDataForFolders=sortData(folderData,tag)
    setTempData(sortedDataForWorkout);
    setTempFolderData(sortDataForFolders)

  }

  const handleClear = () => {
    setSearchTerm("")
  };

  return (
    <div
      className={`h-full  w-[25%] relative rounded-l-xl border-r-2 border-gray-stone`}
    >
      <div className="w-full flex flex-col justify-center py-2 mb-1 border-b   gap-2">
        <SearchBar
          request={requestSearch}
          cancel={cancelSearch}
          bgcol={"#F2F2F2"}
          width={"100%"}
          conatinerStyle={{ width: "100%" }}
          setSearchTerm={setSearchTerm}
          searchTerm={searchTerm}
        />
        <div className="flex gap-6 flex-start ml-2">
          <div onClick={() => setShowSortingDialoge(true)} className="flex gap-1 items-center">
            <ArrowDownWideNarrow  className="text-[#7836ea] h-[25px] w-[25px]" />
            <span className="cursor-pointer font-[400] text-[14px]">Sort</span>
          </div>
          {allTags?.length > 0 && (
            <div className=" flex gap-1 items-center"
            onClick={() =>{
              handleClear() 
            setShowFilterDialog(true)
            }}>
            <img
              style={{
                height: "25px",
                width: "25px",

                cursor: "pointer",
              }}
              // onClick={() => setShowFilterDialog(true)}
              src={
                selectedFilterOptions.length > 0 ? FilterActiveIcon : FilterIcon
              }
              alt="Filter Icon"
            />
            <span  className="cursor-pointer font-[400] text-[14px]">Filter</span>
            </div>
          )}
        </div>

        {showFilterDialog && (
          <FilterWorkoutDialog
            isOpen={showFilterDialog}
            setIsOpen={setShowFilterDialog}
            filterOptions={allTags}
            filterWorkoutsByTag={filterWorkoutsByTags}
            selectedFilterOptions={selectedFilterOptions}
          />
        )}

        {showSortingDialog && (
          <div
            style={{
              position: "absolute",
              top: 63,
              left: 0,
              width: "90%",
              zIndex: 1000,
              height: "200px",
            }}
             
          >
            <Paper
              sx={{
                mx: "16px",
                paddingY: 1,
                height: "200px",
              }}
            >
              <div className="flex justify-between items-center px-2">
                <div
                  style={{
                    color: "#242424",
                    fontFeatureSettings: '"clig" off, "liga" off',
                    fontFamily: "DM Sans",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "22px",
                  }}
                >
                  Sort Workouts
                </div>
                <div
                  onClick={() => {
                    setShowSortingDialoge(false);
                  }}
                  style={{
                    cursor: "pointer",
                    marginRight: "4px",
                  }}
                >
                  <CloseIcon />
                </div>
              </div>
              <div className="h-[0.8px] w-full bg-[#ccc] mt-2"></div>

              <div className="flex flex-col gap-1   p-2 h-[50vh] mx-2 mt-1   ">
                {sortingTag?.map((tag, index) => (
                  <div
                    className="flex items-center hover:bg-gray-100 cursor-pointer rounded-md p-2 transition-all duration-300 ease-in-out"
                    key={tag}
                    style={{
                      background:
                        sortingMatrix === tag &&
                        "linear-gradient(to right, rgba(70, 79, 235, 0.4), rgba(131, 48, 233, 0.4))",
                    }}
                    onClick={() => {
                      if (sortingMatrix === tag) {
                        setSortingMatrix(null);
                        sortWorkouts(null);
                        setShowSortingDialoge(false);
                      } else {
                        sortWorkouts(tag);
                        setSortingMatrix(tag);
                        setShowSortingDialoge(false);
                      }
                    }}
                  >
                    <p className="font-DMSans text-font14  ">{tag}</p>
                  </div>
                ))}
              </div>
            </Paper>
          </div>
        )}
      </div>

      <div
        className="overflow-y-scroll overflow-x-hidden"
        style={{ height: "calc(100vh - 200px)" }}
      >
        {originalData?.length === 0 && folders?.length === 0 ? (
          <button
            style={{
              marginTop: "16px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            className="button-new w-[170px]"
            onClick={() => setOpenWorkoutBuilder(true)}
          >
            Add Workout/Folder
          </button>
        ) : (
          <>
            {tempFolderData?.map((folder) => {
              let isSelect =
                openFolderId === folder?.id || folderSelected?.id === folder?.id;

              return (
                <>
                  {isLoading && (
                    <div className="fixed top-0 left-0 w-full h-full   flex items-center justify-center">
                      <SpurfitCircularProgress />
                    </div>
                  )}
                  <div
                    key={folder?.id}
                    className={`flex flex-col py-1 px-2 cursor-pointer relative ${
                      isSelect
                        ? "folder-card-container-selected "
                        : "folder-card-container-unselected "
                    } 
              
              `}
                    onClick={async (e) => {
                      if (isShifting?.id) {
                        if (isShifting?.id !== folder?.id) {
                          const res = await transferItemBetWeenTheFolder(
                            isShifting,
                            folder,
                            itemToShift
                          );
                          if (res) {
                            setOpenFolderId(folder?.id);
                            setFolderSelected(folder);
                            setIsShifting({});
                            setItemToShift({});
                          }
                        } else {
                          setIsShifting({});
                        }

                        return;
                      }
                      setFolderSelected(folder);
                      setWorkoutSelected({});
                      handleToggleFolder(folder?.id);
                    }}
                  >
                    {isShifting?.id && isShifting?.id === folder?.id && (
                      <div className="absolute top-0 right-0 z-50 bg-black-pure bg-opacity-50 p-1 rounded-md  h-full w-full flex items-center justify-center">
                        <p className="font-DMSans text-font14 text-[#fff]">
                          Click To Cancel Transfer
                        </p>
                        <button
                          className="absolute top-1 right-1 p-1 rounded-full bg-[#F2F2F2]"
                          onClick={() => setIsShifting({})}
                        >
                          {" "}
                          <CopyX />
                        </button>
                      </div>
                    )}
                    <div
                      className=" mx-1"
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <div
                        className={
                          isSelect
                            ? "channel-preview__wrapper_indicator__selected"
                            : "channel-preview__wrapper_indicator"
                        }
                        style={{
                          marginLeft: -7,
                        }}
                      ></div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: 700,
                            color: "#8330e9",
                            // padding: "5px",
                            lineHeight: "10px",
                            fontFamily: "DM Sans",
                            // backgroundColor: "#ffffff",
                            // borderRadius: "10px",

                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                        >
                          {openFolderId === folder?.id ? (
                            <ArrowDropDownRoundedIcon
                              sx={{
                                fontSize: "35px",
                              }}
                            />
                          ) : (
                            <PlayArrowRoundedIcon
                              sx={{
                                fontSize: "20px",
                              }}
                            />
                          )}
                        </span>
                        <div className="w-[30px] h-[40px] flex items-center justify-center  rounded-lg mx-2">
                          {openFolderId === folder?.id ? (
                            <FolderOpen />
                          ) : (
                            <Folder />
                          )}
                        </div>

                        <p className="font-DMSans text-font14 font-medium w-full break-words flex-wrap">
                          {folder?.name}
                        </p>
                      </div>
                      {isShifting?.id && isShifting?.id !== folder?.id && (
                        <ClipboardPaste />
                      )}
                    </div>
                    {openFolderId === folder?.id &&
                      folder?.workouts?.length > 0 && (
                        <ExpandableCardForFolder
                          isFolderOpen={openFolderId}
                          totalFolders={folders}
                          folder={folder}
                          workoutSelected={workoutSelected}
                          deleteItemFromTheFolder={deleteItemFromTheFolder}
                          setWorkoutSelected={setWorkoutSelected}
                          setIsShifting={setIsShifting}
                          setItemToShift={setItemToShift}
                        />
                      )}
                  </div>
                </>
              );
            })}
            <WorkoutList />
          </>
        )}
      </div>
      <div
        className={`absolute bottom-3 right-1 gradient-background rounded-full p-2`}
        onClick={() => setOpenWorkoutBuilder(true)}
      >
        <WhitePlusIcon />
      </div>

      {/* <div className={`absolute bottom-1 right-1`}>
        <AddFolder isIcon={true} />
      </div> */}
      {openWorkoutBuilder && (
        <WorkoutInfoDialog
          isOpen={openWorkoutBuilder}
          setIsOpen={setOpenWorkoutBuilder}
          onSuccess={handleDataSettingInTable}
          creatFromWorkout={true}
          folders={folders}
          createNewFolder={createNewFolder}
          onSuccessOfNewWorkoutForm={(data)=>{
            
            setOpenWorkoutBuilder(false)
            // this is for when workout is formed along with a folder
            if( typeof data?.workoutFolderId === "string"){
              setFolders((prev) =>{
                let folderData=[...prev]
             let newFolderData=folderData?.map((folder)=>{
                  if(folder?.id === data?.workoutFolderId){
                    setFolderSelected(folder)
                    if(folder?.workouts?.length > 0){
                      folder?.workouts?.push(data)
                      return folder
                    }else{
                      let newWorkouts=[]
                      newWorkouts.push(data)
                      folder["workouts"]=newWorkouts
                      return folder
                    }
                  }
                  return folder
                })
                setTempFolderData(newFolderData)
                return newFolderData
                
              })
              setOpenFolderId(data?.workoutFolderId)
              return
            }
            let allTheWorkout=[data,...originalData]
            setOriginalData([data,...originalData])
            setTempData([data,...tempData])
            dispatch(setWorkoutLibaryData(allTheWorkout));
            setFolderSelected({})
            setOpenFolderId("")
            setWorkoutSelected(data)
          }}
        />
      )}
    </div>
  );
}

export default FolderList;



const ExpandableCardForFolder = ({
  isFolderOpen,
  deleteItemFromTheFolder,
  setIsShifting,
  totalFolders,
  setItemToShift,
  workoutSelected,
setWorkoutSelected,
  folder,
}) => {
  return (
    <div className="expandable-card bg-white-pure w-full rounded-b-md  mb-4 pb-2 px-2">
      <Collapse
        in={isFolderOpen}
        timeout={500}
        sx={{
          transitionDuration: "500ms",
          transitionTimingFunction: "ease",
          scrollbarWidth: "0px",
          background: "white",
          borderRadius: "12px",
          width: "100%",
        }}
      >
        {folder?.workouts?.length > 0 &&
          folder?.workouts.map((item, index) => {
            let isSelect = workoutSelected?.id === item?.id;
            return (
              <div
                key={index}
                className={`flex px-2 py-1 gap-3 cursor-pointer items-center   items-strech rounded-lg ${
                  index == 0 ? "mt-2" : "mt-1"
                }

              `}
                style={{
                  border: "1px solid #d0cdcd",
                  color: `${isSelect ? "white" : "black"}`,
                  background: `${isSelect ? "rgb(125 131 241)" : "white"}`,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setWorkoutSelected(item)
                }}
              >
                <div className="w-[30px] h-[30px] flex items-center justify-center   rounded-lg">
                  <img
                   
                  src={item?.workoutKind === null ? Dumbell: Run}
                 
               
                    alt={"workout"}
                    className={cn("text-black-pure",{"invert":item?.id === workoutSelected?.id})}
                  />
                </div>

                

                <div className="flex-col w-full">
                  <p className="font-DMSans text-font14 font-medium break-words flex-wrap">
                    {item?.name}
                  </p>
                </div>

                <div
                  className="ml-auto mr-1 items-center"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <DropDownMenu
                    className={"mr-24 w-21 cursor-pointer"}
                    triggerElement={<MoreVertical size={20} />}
                    options={
                      totalFolders?.length > 1
                        ? ["Shift To Another", `Remove From The Folder`]
                        : [`Remove From The Folder`]
                    }
                    onSelect={(option) => {
                      if (option === `Shift To Another`) { 
                        setIsShifting(folder);
                        setItemToShift(item);
                      }
                      if (option === "Remove From The Folder") {
                        deleteItemFromTheFolder(folder, item);
                      }
                    }}
                  />
                </div>
              </div>
            );
          })}
      </Collapse>
    </div>
  );
};




 
 

function SearchBar({
  request,
  cancel,
  height,
  width,
  bgcol,
  fontSize,
  icon,
  placeholder,
  style,
  iconStyle,
  inputStyle,
  id,
  conatinerStyle,
  setSearchTerm,
  searchTerm,
}) {
 

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    request(event.target.value);
    if (searchTerm === "") {
      console.log("this is from search box");
      cancel();
    }
  };

  const handleClear = () => {
    setSearchTerm("");
    cancel();
  };

  return (
    <span
      id={id || "search-bar"}
      style={{ display: "flex", alignItems: "center", ...conatinerStyle }}
    >
      <TextField
        variant="standard"
        sx={{
          width: width || "auto",
          marginBottom: "auto",
          marginTop: "auto",
          mx: "10px",
          display: "flex",
          alignItems: "center",
          ...style,
        }}
        autoComplete="off"
        placeholder={placeholder || "Search anything"}
        value={searchTerm}
        onChange={handleSearch}
        InputProps={{
          style: {
            height: height,
            width: width || "100%",
            borderRadius: 50,
            padding: height != "40px" ? "2px 0px 0px 0px" : 0,
            background: bgcol,
            fontSize: fontSize,
            fontFamily: "DM Sans",
            ...inputStyle,
          },
          startAdornment: (
            <IconButton disabled>
              <img
                style={{ marginLeft: "8px", marginRight: "2px", ...iconStyle }}
                src={icon ? icon : SearchIcon}
              />
            </IconButton>
          ),
          endAdornment: searchTerm && (
            <IconButton onClick={handleClear} disabled={!searchTerm}>
              <Clear fontSize="small" />
            </IconButton>
          ),
          disableUnderline: true,
        }}
      />
    </span>
  );
}
 


