import React, { useEffect, useState } from "react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

import FilterIcon from "@/assets/workoutBuilder/filterIcon.svg";
import FilterActiveIcon from "@/assets/workouts/ActiveFunnel.svg";
import { cn } from "@/lib/utils";

const Filter = ({
  selectedFilter,
  setSelectedFilter,
  subbordinateCoachesNames,
  handleFilter,
  selectedTab,
}) => {
  const [filterOptions, setFilterOptions] = useState(subbordinateCoachesNames);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (selectedTab === "others") {
      setFilterOptions(subbordinateCoachesNames);
    } else {
      let tab = selectedTab[0].toUpperCase() + selectedTab.slice(1);
      setFilterOptions(["Admin " + tab, "My " + tab]);
    }
    setSelectedFilter(null);
  }, [selectedTab]);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger>
        {selectedFilter ? (
          <img src={FilterActiveIcon} alt />
        ) : (
          <img src={FilterIcon} alt />
        )}
      </PopoverTrigger>
      <PopoverContent className="shadow-2xl p-0">
        {filterOptions.map((name) => (
          <div
            key={name}
            onClick={() => {
              setSelectedFilter(name);
              handleFilter(name);
              setOpen(false);
            }}
            className={cn("hover:bg-gray-200 cursor-pointer p-2", {
              "bg-gradient-to-r from-[#464feb1a] to-[#8330e91a]":
                selectedFilter === name,
            })}
          >
            {name}
          </div>
        ))}
        {/* option to clear filter */}
        <div
          onClick={() => {
            setSelectedFilter(null);
            handleFilter("clear");
            setOpen(false);
          }}
          className={"hover:bg-gray-200 cursor-pointer p-2 text-red-400"}
        >
          Clear Filter
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default Filter;
