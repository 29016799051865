import {Skeleton} from "@/components/ui/skeleton.jsx";

const TrainerDetailsLoading = () => {
    return (
        <div>
            <div className={'h-[48px] w-full flex items-center p-2 border-b'}>
                <Skeleton className="w-[200px] h-[40px]"/>
            </div>
            <div>
                {/*    table loading skeleton*/}
                <div className={'w-full p-2'}>
                    <Skeleton className={'h-[40px] w-full'}/>
                    <Skeleton
                        className="h-[300px] w-full mt-2 "
                    />
                </div>
            </div>
        </div>
    )
}

export default TrainerDetailsLoading;