import ArrowRightAltRoundedIcon from "@mui/icons-material/ArrowRightAltRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  Avatar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  LinearProgress,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import _, { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { setToOneFixedDecimal } from "./AddMealsModal";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Trash2 } from "lucide-react";

function AddFoodItemModal({
  isOpen,
  setIsOpen,
  meals,
  setMeals,
  selectedIndex,
  foodItems,
  getFoodItems,
  isLoading,
  allSelectedItems,
  setAllSelectedItems,
  searchedFoods,
  searchedRecipes,
  localSearchedFoods,
  localSearchedRecipes,
}) {
  const gymAffiliation = useSelector(
    (state) => state.auth?.data?.gymAffliation
  );
  const isLivezy = gymAffiliation === "Livezy";

  const [selectedFoodItems, setSelectedFoodItems] = useState([]);
  const [searchedTerm, setSearchedTerm] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [initialized, setInitialized] = useState(false);
  const [selectedType, setSelectedType] = useState(
    isLivezy ? "trainer" : "global"
  );

  const getFoodFromId = (id) => {
    console.log(selectedFoodItems, "_>", id);
    if (!id) return;
    return allSelectedItems?.find(
      (item) => item?.food_id === id || item?.recipe_id === id
    );
  };

  useEffect(() => {
    // check currentMeal foods and set selectedFoodItems by checking food_id
    console.log(
      "meals: ",
      meals[selectedIndex],
      "selectedIndex: ",
      selectedIndex
    );
    let currentMeal = meals[selectedIndex];
    let foods = currentMeal?.foods || [];
    let selectedItems = [];
    foods.forEach((food) => {
      selectedItems.push(food);
    });
    setSelectedFoodItems(selectedItems);
  }, [selectedIndex]);

  function removeSubstringAndParseInt(str, substr) {
    let newStr = str;
    if (substr && str && typeof str === "string" && str.includes(substr)) {
      newStr = str.replace(substr, "");
    }
    const num = parseInt(newStr);
    return isNaN(num) ? null : num;
  }

  const onSave = () => {
    let currentMeal = meals[selectedIndex];
    let foods = [];
    console.log("selectedFoodItems ===>>> ", selectedFoodItems);

    selectedFoodItems?.forEach((item) => {
      let servings =
        item?.servings?.serving || item?.servings || item?.serving || item;
      let index = 0;
      while (
        servings &&
        servings?.[index]?.measurement_description?.length > 18 &&
        index < servings?.length
      ) {
        index++;
      }
      let serving = servings?.[index] || servings?.[0];
      console.log(
        "servingsize: ",
        item?.serving_sizes?.serving_size?.split(" ")
      );
      if (
        currentMeal?.foods?.find(
          (food) => {
            if (food === item) return true;
            if (food?.food_id) {
              return (
                food?.food_id === item?.food_id || food?.food_id === item?.id
              );
            } else if (food?.recipe_id) {
              return food?.recipe_id === item?.recipe_id;
            } else if (food?.id) {
              return food?.id === item?.id;
            } else {
              return false;
            }
          }
          // food?.food_id === item?.food_id ||
          // food?.recipe_id === item?.recipe_id
        ) &&
        item?.name != undefined &&
        item?.measurement_count &&
        item?.measurement_description
      ) {
        foods.push(item);
        return;
      }
      foods.push({
        name: item?.food_name || item?.recipe_name || item?.name,
        ...(!item?.recipe_name && { food_id: item?.food_id || `${item?.id}` }),
        ...(item?.recipe_name && {
          recipe_id: item?.recipe_id || `${item?.id}`,
          recipeItem: item,
        }),
        size: serving?.metric_serving_amount || item?.grams_per_portion,
        unit: serving?.metric_serving_unit || "g",
        protein: serving?.protein || item?.serving_sizes?.protein,
        fat: serving?.fat || item?.serving_sizes?.fat,
        carbs:
          serving?.carbohydrate ||
          item?.serving_sizes?.carbohydrate ||
          serving?.carbs,
        calories: serving?.calories || item?.serving_sizes?.calories,
        measurement_description:
          serving?.baseUnit ||
          serving?.measurement_description ||
          item?.serving_sizes?.serving_size?.split(" ")[1] ||
          serving?.metric_serving_unit,
        measurement_count:
          serving?.baseUnit && serving?.baseUnit != serving?.metric_serving_unit
            ? 1
            : +item?.serving_sizes?.serving_size?.split(" ")[0] ||
              removeSubstringAndParseInt(serving?.serving_description) ||
              serving?.metric_serving_amount,
        image: item?.recipe_images?.[0] || item?.food_image || undefined,
        global: true,
        baseUnit: serving?.baseUnit,
        changeUnit:
          serving?.changeUnit && serving?.changeUnit !== "undefined"
            ? serving?.changeUnit
            : undefined,
        baseToChangeRatio: serving?.baseToChangeRatio,
      });
    });

    currentMeal.foods = foods;
    meals[selectedIndex] = currentMeal;
    setMeals([...meals]);
  };
  const handleDeleteFromSelectedItems = (foodItem) => {
    console.log("what is", foodItem);
    if (foodItem?.food_id || foodItem?.recipe_id) {
      setSelectedFoodItems((prev) => {
        return prev.filter((item) => {
          console.log("item :", item?.food_id, foodItem?.food_id);
          return (
            item?.food_id !== foodItem?.food_id ||
            item?.recipe_id !== foodItem?.recipe_id
          );
        });
      });
    } else {
      setSelectedFoodItems((prev) => {
        const index = prev.findIndex((item) => item?.id === foodItem?.id);
        if (index !== -1) {
          const updatedItems = [
            ...prev.slice(0, index),
            ...prev.slice(index + 1),
          ];
          return updatedItems;
        }
        return prev;
      });
    }
  };
  useEffect(() => {
    const deboundedGetFoodItems = debounce(getFoodItems, 1000);

    if (!initialized) {
      setInitialized(true);
    } else {
      deboundedGetFoodItems(searchedTerm);
    }

    return () => {
      deboundedGetFoodItems.cancel();
    };
  }, [searchedTerm]);

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          minHeight: "650px",
          maxHeight: "650px",
          borderRadius: "20px",
        },
      }}
    >
      <div className="add-program-modal-head">
        <p className="add-program-modal-head-title">Select Food Items</p>
        <CloseRoundedIcon
          sx={{
            cursor: "pointer",
          }}
          onClick={() => {
            setIsOpen(false);
          }}
        />
      </div>
      <div
        style={{
          width: "100%",
          height: `50px`,
          paddingLeft: "25px",
          paddingRight: "25px",
        }}
      >
        <input
          type="text"
          placeholder="Search"
          style={{
            marginTop: "20px",
            width: "100%",
            height: "40px",
            borderRadius: "20px",
            paddingLeft: "20px",
            paddingRight: "20px",
            outline: "none",
            border: "1px solid #EDEBE9",
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              getFoodItems(searchedTerm);
            }
          }}
          onChange={(e) => {
            setSearchedTerm(e.target.value);
          }}
        />
      </div>
      <DialogContent
        sx={{
          overflowY: "hidden",
        }}
      >
        {isLoading ? (
          <LinearProgress />
        ) : (
          <Box
            sx={{
              display: "flex",
              overflow: "hidden",
              flex: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "60%",
              }}
            >
              <Box
                sx={{ borderBottom: 1, borderColor: "divider" }}
                style={{
                  display: "flex",
                }}
              >
                <Tabs
                  value={activeTab}
                  onChange={(e, newValue) => {
                    setActiveTab(newValue);
                  }}
                  aria-label="basic tabs example"
                >
                  <Tab label="Food" {...a11yProps(0)} />
                  <Tab label="Recipe" {...a11yProps(1)} />
                </Tabs>
                {!isLivezy && (
                  <Select
                    style={{
                      marginLeft: "auto",
                    }}
                    value={selectedType}
                    onChange={(e) => {
                      setSelectedType(e.target.value);
                    }}
                    variant="standard"
                    disableUnderline
                    SelectDisplayProps={{
                      style: {
                        backgroundColor: "#fff",
                        fontFamily: "DM Sans",
                        fontSize: "14px",
                      },
                    }}
                  >
                    <MenuItem
                      sx={{
                        fontFamily: "DM sans",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "20px",
                      }}
                      value={"global"}
                    >
                      Global
                    </MenuItem>
                    <MenuItem
                      sx={{
                        fontFamily: "DM sans",
                        fontSize: "13px",
                        fontWeight: "400",
                        lineHeight: "20px",
                      }}
                      value={"trainer"}
                    >
                      {activeTab === 1 ? "My Recipes" : "My Foods"}
                    </MenuItem>
                  </Select>
                )}
              </Box>

              {selectedType === "trainer" ? (
                <>
                  <CustomTabPanel
                    style={{
                      width: "100%",
                    }}
                    value={activeTab}
                    index={0}
                  >
                    <ShowFoodItems
                      foodItems={localSearchedFoods}
                      setSelectedFoodItems={setSelectedFoodItems}
                      setAllSelectedItems={setAllSelectedItems}
                    />
                  </CustomTabPanel>
                  <CustomTabPanel
                    style={{
                      flex: 1,
                    }}
                    value={activeTab}
                    index={1}
                  >
                    <ShowFoodItems
                      foodItems={localSearchedRecipes}
                      setSelectedFoodItems={setSelectedFoodItems}
                      setAllSelectedItems={setAllSelectedItems}
                    />
                  </CustomTabPanel>
                </>
              ) : (
                <>
                  <CustomTabPanel
                    style={{
                      width: "100%",
                    }}
                    value={activeTab}
                    index={0}
                  >
                    <ShowFoodItems
                      foodItems={searchedFoods}
                      setSelectedFoodItems={setSelectedFoodItems}
                      setAllSelectedItems={setAllSelectedItems}
                    />
                  </CustomTabPanel>
                  <CustomTabPanel
                    style={{
                      flex: 1,
                    }}
                    value={activeTab}
                    index={1}
                  >
                    <ShowFoodItems
                      foodItems={searchedRecipes}
                      setSelectedFoodItems={setSelectedFoodItems}
                      setAllSelectedItems={setAllSelectedItems}
                    />
                  </CustomTabPanel>
                </>
              )}
            </Box>
            {/* {showFoodItems(
              foodItems,
              setSelectedFoodItems,
              setAllSelectedItems
            )} */}
            <Box
              sx={{
                borderLeft: "1px solid #727272",
                height: "400px",
                overflowY: "scroll",
              }}
              width={"40%"}
            >
              {selectedFoodItems?.map((foodItem) => {
                let serving =
                  foodItem?.servings?.serving?.[0] ||
                  foodItem?.servings?.[0] ||
                  foodItem?.serving?.[0] ||
                  foodItem?.serving_sizes;
                let cals = setToOneFixedDecimal(
                  serving?.calories || foodItem?.calories || 0
                );

                return (
                  <Box
                    className="hover-effect"
                    sx={{
                      display: "flex",
                      position: "relative",
                      cursor: "pointer",
                      width: "100%",
                      p: 2,
                      borderRadius: "10px",
                    }}
                  >
                    <Avatar
                      sx={{
                        height: "40px",
                        width: "40px",
                        mr: 2,
                      }}
                    >
                      {foodItem?.food_name
                        ? getInitials(foodItem?.food_name)
                        : foodItem?.recipe_name
                        ? getInitials(foodItem?.recipe_name)
                        : getInitials(foodItem?.name)}
                    </Avatar>

                    <Box sx={{ paddingRight: "18px" }}>
                      <Typography
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: 500,
                          fontSize: "12px",
                          lineHeight: "20px",
                        }}
                      >
                        {foodItem?.food_name ||
                          foodItem?.recipe_name ||
                          foodItem?.name}
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: 500,
                          fontSize: "10px",
                          lineHeight: "20px",
                          color: "#727272",
                        }}
                      >
                        {foodItem?.recipe_id ? "Recipe" : "Food"} • {cals} cals
                      </Typography>
                    </Box>

                    <div
                      className="absolute right-1 curser-pointer"
                      onClick={() => {
                        handleDeleteFromSelectedItems(foodItem);
                      }}
                    >
                      <Trash2 className="h-[20px] w-[20px] text-red-400" />
                    </div>
                    {/* <CloseRoundedIcon
                      sx={{
                        fontSize: "25px",
                        cursor: "pointer",
                        position: "absolute",
                        alignSelf:"center",
                        right: 0,
                      }}
                      onClick={() => {
                        handleDeleteFromSelectedItems(foodItem);
                      }}
                    /> */}
                  </Box>
                );
              })}
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          pb: "24px",
        }}
      >
        <button
          disabled={false}
          style={{ marginTop: "8px" }}
          className="button-new"
          onClick={() => {
            setIsOpen(false);
            onSave();
          }}
        >
          Save
        </button>
      </DialogActions>
    </Dialog>
  );
}

const getInitials = (string) => {
  const names = string?.split(" ");
  if (_.isEmpty(names)) return;

  let initials = names[0].substring(0, 1).toUpperCase();
  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};
export default AddFoodItemModal;

function ShowFoodItems({
  foodItems,
  setSelectedFoodItems,
  setAllSelectedItems,
}) {
  console.log("foodItems: ", foodItems);
  return (
    <Box
      sx={{
        overflowY: "scroll",
        height: "300px",
      }}
      width={"100%"}
    >
      {foodItems?.map((foodItem) => {
        let serving =
          foodItem?.servings?.serving?.[0] ||
          foodItem?.servings?.[0] ||
          foodItem?.serving?.[0] ||
          foodItem?.serving_sizes;
        let cals = serving?.calories || 0;
        return (
          <Box
            className="hover-effect"
            sx={{
              display: "flex",
              cursor: "pointer",
              alignItems: "center",
              py: 2,
              position: "relative",
              pr: 3,
              pl: 2,
              mr: 1,
              borderRadius: "10px",
            }}
            onClick={() => {
              setSelectedFoodItems((prev) => {
                return [...prev, foodItem];
              });
              setAllSelectedItems((prev) => {
                return [...prev, foodItem];
              });
            }}
          >
            <Avatar
              sx={{
                height: "40px",
                width: "40px",
                mr: 2,
              }}
            >
              {foodItem?.food_name
                ? getInitials(foodItem?.food_name)
                : foodItem?.recipe_name
                ? getInitials(foodItem?.recipe_name)
                : getInitials(foodItem?.name)}
            </Avatar>

            <Box>
              <Typography
                sx={{
                  fontFamily: "DM Sans",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "12px",
                  lineHeight: "20px",
                }}
              >
                {foodItem?.food_name || foodItem?.recipe_name || foodItem?.name}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "DM Sans",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "10px",
                  lineHeight: "20px",
                  color: "#727272",
                }}
              >
                {foodItem?.food_id ? "Food " : "Recipe"}• {cals} cals
              </Typography>
            </Box>
            <div className="  rounded-md flex p-2 absolute right-3">
              <AddCircleOutlineIcon className="text-[#484eeb]" />
            </div>
            {/* <ArrowRightAltRoundedIcon sx={{ position: "absolute", right: 3 }} /> */}
          </Box>
        );
      })}
    </Box>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
