import React, { useContext, useState } from "react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import Input from "@/components/InputComponents/Input";
import TextArea from "@/components/InputComponents/TextArea";
import { Pencil } from "lucide-react";
import { ProgramsContext } from "./ProgramsContext";

const EditProgramInfo = ({
  selectedProgramData,
  updateProgramInformation,
  isProgramPhase,
  isFolder,
}) => {
  const [programName, setProgramName] = useState(
    selectedProgramData?.name || ""
  );
  const [programDescription, setProgramDescription] = useState(
    selectedProgramData?.description || ""
  );

  const {
    setFolders,
    setFolderSelected,
    updateFolder,
    setTemplateFolders,
    setOriginalState,
    folderSelected,
  } = useContext(ProgramsContext);

  const handleNameUpdateOfFolder = async () => {
    let data = {
      folderID: selectedProgramData?.id,
      name: programName,
      description: programDescription,
    };
    let res = await updateFolder(data);
    console.log("idOfTheFoleder", selectedProgramData, res);
    if (res) {
      // this will update state when name update is from template
      if (selectedProgramData?.isTemplate) {
        setFolderSelected((prev) => {
          const data = { ...prev };
          if (data?.name) {
            data.name = programName;
            data["description"] = programDescription;
          }
          setTemplateFolders((folder) => {
            const oldFolders = [...folder];
            const newFolders = oldFolders.map((folder) => {
              if (folder?.id === data?.id) {
                return data;
              } else {
                return folder;
              }
            });
            return newFolders;
          });

          setOriginalState((prevState) => {
            console.log(prevState);
            const oldData = { ...prevState };
            if (oldData?.templateFolders) {
              let newData = oldData?.templateFolders?.map((folder) => {
                if (folder?.id === data?.id) {
                  return data;
                }
                return folder;
              });
              oldData.templateFolders = newData;
            }
            return oldData;
          });
          return data;
        });

        return;
      }

      setFolderSelected((prev) => {
        const data = { ...prev };
        if (data?.name) {
          data.name = programName;
          data["description"] = programDescription;
        }
        setFolders((folder) => {
          const oldFolders = [...folder];
          const newFolders = oldFolders.map((folder) => {
            if (folder?.id === data?.id) {
              return data;
            } else {
              return folder;
            }
          });
          return newFolders;
        });

        setOriginalState((pre) => {
          const olddata = { ...pre };
          if (olddata?.folders) {
            let newData = olddata?.folders?.map((folder) => {
              if (folder?.id === data?.id) {
                return data;
              }
              return folder;
            });
            olddata.folders = newData;
          }
          return olddata;
        });
        return data;
      });
    }
  };
  return (
    <Dialog
      onOpenChange={(isOpen) => {
        setProgramName(selectedProgramData?.name || "");
        setProgramDescription(selectedProgramData?.description || "");
      }}
    >
      <DialogTrigger asChild>
        <button>
          <Pencil size={18} color="#828282" />
        </button>
      </DialogTrigger>
      {/* overlay */}
      <DialogPortal>
        <DialogOverlay className="bg-black-pure bg-opacity-40 z-[9999]" />
      </DialogPortal>
      <DialogContent className="sm:max-w-[425px] z-[9999] font-DMSans">
        <DialogHeader>
          {!isFolder &&
            (isProgramPhase ? (
              <DialogTitle className="text-font18">
                Edit Program Phase Info*
              </DialogTitle>
            ) : (
              <DialogTitle className="text-font18">
                Edit Program Info*
              </DialogTitle>
            ))}
          {isFolder && (
            <DialogTitle className="text-font18">Edit Folder Info</DialogTitle>
          )}
        </DialogHeader>
        <div className="grid gap-4 py-4">
          {!isFolder && (
            <Input
              label="Program Name"
              value={programName}
              onChange={(e) => setProgramName(e.target.value)}
            />
          )}
          {isFolder && (
            <Input
              label="Folder Name*"
              value={programName}
              onChange={(e) => setProgramName(e.target.value)}
            />
          )}
          {!isFolder && (
            <TextArea
              placeholder="Program Description"
              value={programDescription}
              onChange={(e) => setProgramDescription(e.target.value)}
              numRows={4}
            />
          )}
          {isFolder && (
            <TextArea
              placeholder="Folder Description"
              value={programDescription}
              onChange={(e) => setProgramDescription(e.target.value)}
              numRows={4}
            />
          )}
        </div>
        <DialogFooter
          className={
            "flex w-full items-center justify-center sm:justify-center"
          }
        >
          <DialogClose>
            {!isFolder && (
              <button
                className="button-new"
                onClick={() => {
                  updateProgramInformation(
                    selectedProgramData?.id,
                    programName,
                    programDescription
                  );
                }}
                disabled={!programName || programName.length < 3}
              >
                Save
              </button>
            )}
            {isFolder && (
              <button
                className="button-new"
                onClick={() => {
                  handleNameUpdateOfFolder();
                }}
              >
                Update
              </button>
            )}
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default EditProgramInfo;
