import APIURL from "@/APIDetails";
import { updateMealPlan } from "@/ApiServices/Meals/Api";
import { CustomAlertContext } from "@/App";
import { SpurfitCircularProgress } from "@/components/Dashboard/dashboard";

import { CloseRounded } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import axios from "axios";
import { Plus, Trash2 } from "lucide-react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ProgramsContext } from "./ProgramsContext";

function AddNotesDialogeForPrograms({
  open,
  setIsOpen,
  tittle,
  updateProgramInformation,
  selectedProgramData,
  isNotesForProgramsPhase,
  isNotesForMealPlan,
  dataOfMealPlan,
  setDataOfMealPlan,
  isNotesForPrograms,
}) {
  const [loading, setLoading] = useState(false);
  const [allNotes, setAllNotes] = useState([""]); // Start with one empty note
  const { displayAlert } = useContext(CustomAlertContext);
  const textAreaRefs = useRef([]);

  const handleChange = (e, index) => {
    const updatedNotes = [...allNotes];
    updatedNotes[index] = e.target.value;
    setAllNotes(updatedNotes);
  };

  const adjustHeight = (element) => {
    element.style.height = "auto";
    element.style.height = `${element.scrollHeight}px`;
  };

  const adjustAllTextAreas = () => {
    textAreaRefs.current.forEach((ref) => {
      if (ref) {
        adjustHeight(ref);
      }
    });
  };

  useEffect(() => {
    let notes = [];
    if (isNotesForProgramsPhase || isNotesForPrograms) {
      notes = selectedProgramData?.notes || [];
    } else if (isNotesForMealPlan) {
      notes = dataOfMealPlan?.notes || [];
    }

    // Initialize with one empty note if no notes are available
    setAllNotes(notes.length > 0 ? notes : [""]);
  }, [
    selectedProgramData,
    dataOfMealPlan,
    isNotesForProgramsPhase,
    isNotesForPrograms,
    isNotesForMealPlan,
    open,
  ]);

  useEffect(() => {
    adjustAllTextAreas();
  }, [allNotes]);

  const handleDelete = (index) => {
    // Always allow deleting if it's the only note
    const updatedNotes = [...allNotes];
    updatedNotes.splice(index, 1);
    setAllNotes(updatedNotes.length > 0 ? updatedNotes : [""]);
  };

  const handleSave = async () => {
    const cleanedNotes = allNotes.filter((note) => note.trim() !== "");

    try {
      if (isNotesForProgramsPhase || isNotesForPrograms) {
        await updateProgramInformation(
          selectedProgramData?.id,
          selectedProgramData?.name,
          selectedProgramData?.description,
          cleanedNotes
        );
      } else if (isNotesForMealPlan) {
        setLoading(true);
        await updateMealPlan(dataOfMealPlan?.id, {
          notes: cleanedNotes,
        });
        setDataOfMealPlan((prev) => ({ ...prev, notes: cleanedNotes }));
        displayAlert({
          message: "Notes saved successfully!",
          type: "success",
        });
      }
    } catch (error) {
      displayAlert({
        message: "An error occurred while saving notes.",
        type: "error",
      });
    } finally {
      setLoading(false);
      setIsOpen(false);
    }
  };

  const handleAddNote = () => {
    setAllNotes((prevNotes) => [...prevNotes, ""]);
  };

  return (
    <Dialog
      open={open}
      onClose={() => setIsOpen(false)}
      PaperProps={{
        sx: {
          width: "1000px",
          height: "600px",
          borderRadius: "12px",
          maxHeight: "800px",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid #f2f2f2",
          fontFamily: "DM Sans",
        }}
      >
        <h3>{tittle}</h3>
        <CloseRounded
          sx={{
            top: "24px",
            right: "24px",
            cursor: "pointer",
          }}
          onClick={() => setIsOpen(false)}
        />
      </DialogTitle>
      <DialogContent
        sx={{
          padding: "10px",
          overflowY: "scroll",
        }}
      >
        {loading ? (
          <div className="w-full h-full">
            <SpurfitCircularProgress />
          </div>
        ) : (
          <>
            {allNotes.map((note, index) => (
              <div
                className="flex justify-center items-center mb-3 mt-3"
                key={index}
              >
                <textarea
                  ref={(el) => (textAreaRefs.current[index] = el)}
                  type="text"
                  value={note}
                  placeholder={`Add ${tittle}...`}
                  autoFocus={index === allNotes.length - 1}
                  style={{
                    width: "92%",
                    background: "#F2F2F2",
                    padding: "20px 10px",
                    borderRadius: "8px",
                    fontSize: "16px",
                    marginRight: "10px",
                    fontFamily: "DM Sans",
                    overflow: "hidden",
                    resize: "none", // Prevent resizing
                  }}
                  className="outline-none focus-within:border focus-within:border-violet-500"
                  onChange={(e) => handleChange(e, index)}
                  rows={1}
                />
                <Trash2
                  color="#ff0000"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => handleDelete(index)}
                />
              </div>
            ))}
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
                fontSize: "16px",
                cursor: "pointer", // Add cursor style
              }}
              onClick={handleAddNote} // Call handleAddNote on click
            >
              <Plus color="#8330e9" />
              <p
                style={{
                  color: "#8330e9",
                }}
              >
                Add more {tittle}
              </p>
            </div>
          </>
        )}
      </DialogContent>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "white",
          padding: "16px 0px",
        }}
      >
        <button className="button-new" onClick={handleSave}>
          Save Notes
        </button>
      </div>
    </Dialog>
  );
}

export default AddNotesDialogeForPrograms;
