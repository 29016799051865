import React from "react";
import NoWorkoutsImage from "../../assets/workouts/NoWorkouts.svg";

const NoWorkoutsView = ({
  setOpenWorkoutBuilder,
  message = "",
  subMessage,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "auto",
        marginBottom: "auto",
        padding: "24px",
      }}
    >
      <img
        style={{
          width: "128px",
          height: "128px",
        }}
        src={NoWorkoutsImage}
        alt="No workouts"
      />
      <div style={{ marginTop: "24px" }} className="exercise-library-title">
        {message ?? "No Workouts Added"}
      </div>
      <div className="no-exercise-text">
        {subMessage ??
          "There are no Workouts/Folders to display, click the below button to add a Workout/Folder"}
      </div>

      <button
        style={{
          marginTop: "16px",
        }}
        className="button-new w-[170px]"
        onClick={() => setOpenWorkoutBuilder(true)}
      >
        Add Workout/Folder
      </button>
    </div>
  );
};

export default NoWorkoutsView;
