import WorkoutInfoDialog from "@/components/WorkoutComponent/WorkoutInfoDialog";
import { DeleteRounded } from "@mui/icons-material";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Box, Stack, Typography } from "@mui/material";
import axios from "axios";
import moment from "moment";
import React, { forwardRef, useState } from "react";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

function FollowThroughView(
  {
    data,
    showBackButton,
    userUUID,
    deleteIndividualWorkout,
    setReload,
    updateWorkoutInfoForCalender,
    showEditBtn,
    fromWorkout,
    onSuccessOfEditWokroutFromWorkout,
    ...props
  },
  ref
) {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const history = useHistory();
  const [topHeight, setTopHeight] = useState("auto");
  const [loading, setLoading] = useState(false);
  const [showWorkoutInfoModal, setShowWorkoutInfoModal] = useState(false);
  const postWorkout = async () => {
    setLoading(true);
    let url = `${APIURL}CF/api/modules`;

    let d = {
      userUUID: `${userUUID}`,
      modulesUUID: `${data.id}`,
      moduleDate: `${moment(props?.date, "DD-MM-YYYY")
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ss")}Z`,
    };

    await axios({
      method: "post",
      url: url,
      data: d,
    })
      .then((res) => {
        console.log("posted workout: ", res.data);
        history.push(`/client/${userUUID}/`);
      })
      .catch((err) => {
        console.log("sdfjakl;sdjfakl;sdj");
        console.log("error in CF Post workout in section.js", err);
      })
      .finally(() => {
        setLoading(false);
      });
    setLoading(false);
  };

  React.useImperativeHandle(ref, () => ({
    setShowWorkoutInfoModal: () => setShowWorkoutInfoModal(true),
  }));

  return (
    <Stack
      sx={{
        borderRadius: "12px",
        marginBottom: "24px",
        border: !fromWorkout ? "1px solid #ccc" : undefined,
        padding: "5px",
        height: "100%",
        width: "100%",
        pb: "24px",
      }}
    >
      {!fromWorkout && (
        <Stack
          direction={"row"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          // mb={"10px"}
          className="section-header"
          sx={{
            background: "transparent",
            position: "relative",
          }}
          style={{
            padding: "35px 20px",
          }}
        >
          {showBackButton && (
            <ArrowBackIosNewRoundedIcon
              onClick={() => {
                history.goBack();
              }}
              style={{
                color: "#000",
                cursor: "pointer",
                marginRight: "8px",
                position: "absolute",
                left: "0",
              }}
            />
          )}
          <div className="section-header-left ml-2">
            <p
              className="testchannel"
              style={{
                marginRight: "auto",
                fontFamily: "DM Sans",
                fontSize: "18px",
                marginLeft: showBackButton ? "12px" : "0px",
              }}
            >
              {data?.name}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
              marginRight: "4px",
            }}
          >
            {props?.isImport ? (
              <>
                <button
                  className="button-new-outlined"
                  style={{ width: "60px" }}
                  onClick={postWorkout}
                >
                  Push
                </button>
                {showEditBtn && (
                  <button
                    className="button-new-outlined"
                    style={{ width: "60px" }}
                    onClick={() => {
                      history.push(`/workoutBuilder`, {
                        state: {
                          data: data,
                          isImport: props?.isImport,
                          isCalendar: props?.isCalendar,
                          isUpdate: true,
                          isStructured: false,
                          extraData: {
                            userUUID: `${userUUID}`,
                            moduleDate: `${moment(props?.date, "DD-MM-YYYY")
                              .startOf("day")
                              .format("YYYY-MM-DDTHH:mm:ss")}Z`,
                            trackingID: props?.workoutID,
                          },
                        },
                      });
                    }}
                  >
                    Edit
                  </button>
                )}
              </>
            ) : (
              <>
                {showEditBtn && !props?.isFinished && (
                  <button
                    className="button-new-outlined"
                    style={{ width: "60px" }}
                    onClick={() => {
                      setShowWorkoutInfoModal(true);
                      // history.push(`/workoutBuilder`, {
                      //   state: {
                      //     data: { ...data },
                      //     isImport: props?.isImport,
                      //     isCalendar: props?.isCalendar,
                      //     isUpdate: true,
                      //     isStructured: false,
                      //     isVideoUpdate: true,
                      //     extraData: {
                      //       userUUID: `${userUUID}`,
                      //       moduleDate: `${moment(props?.date, "DD-MM-YYYY")
                      //         .startOf("day")
                      //         .format("YYYY-MM-DDTHH:mm:ss")}Z`,
                      //       trackingID: props?.workoutID,
                      //     },
                      //   },
                      // });
                    }}
                  >
                    Edit
                  </button>
                )}
                {!props?.isFinished && !(props.canDelete === false) && (
                  <div className="delete-workouts-btn">
                    <DeleteRounded
                      style={{
                        color: "#ff3a29",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        deleteIndividualWorkout(props?.workoutID);
                      }}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </Stack>
      )}
      <Box
        sx={{
          height: topHeight,
        }}
        ref={props?.topRef}
        mb={"10px"}
      ></Box>
      <Stack
        gap={"12px"}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          padding: "0px 24px",
        }}
      >
        <ReactPlayer
          width={"55%"}
          height={"300px"}
          controls={true}
          className="react-player"
          url={data?.workoutContent?.videoURL}
        />
        <Stack
          sx={{
            borderRadius: "12px",
            border: "1px solid #ccc",
            padding: "16px",
            width: "40%",
            color: "#000",
          }}
        >
          <RenderProperty label="Workout Name" value={data?.name} />
          <RenderProperty
            label="Description"
            value={data?.workoutContent?.description}
          />
          {/* <RenderProperty
            label="Duration"
            value={data?.workoutContent?.duration}
          /> */}
        </Stack>
      </Stack>

      {showWorkoutInfoModal && (
        <WorkoutInfoDialog
          isOpen={showWorkoutInfoModal}
          setIsOpen={setShowWorkoutInfoModal}
          workoutInfo={data}
          isImport={props?.isImport}
          isCalendar={props?.isCalendar}
          // isUpdate={true}
          isStructured={false}
          isVideoUpdate={true}
          isUpdateFollowAlongWorkout={true}
          trackingID={props?.workoutID}
          setReload={setReload}
         fromWorkout={fromWorkout}
          updateWorkoutInfoForCalender={updateWorkoutInfoForCalender}
          onSuccessOfEditWokroutFromWorkout={onSuccessOfEditWokroutFromWorkout}
        />
      )}

      {props?.isFinished && (
        <Stack
          direction={"row"}
          alignItems={"flex-start"}
          px={"24px"}
          pt={"12px"}
        >
          <CheckCircleOutlineIcon
            style={{ color: "#00b559", fontSize: "20px" }}
          />
          <Typography
            sx={{
              fontFamily: "DM Sans",
              fontSize: "14px",
              fontWeight: 500,
              color: "#00b559",
            }}
          >
            &nbsp; Completed
          </Typography>
        </Stack>
      )}
    </Stack>
  );
}

export default forwardRef(FollowThroughView)  ;

const RenderProperty = ({ label, value }) => {
  return (
    <Stack
      direction={["column", "column", "row"]}
      sx={{
        width: "100%",
        padding: "5px 0px",
      }}
    >
      <p
        style={{
          fontFamily: "DM Sans",
          fontSize: "14px",
        }}
      >
        {label}: &nbsp;
      </p>
      <p
        style={{
          fontFamily: "DM Sans",
          fontSize: "14px",
          fontWeight: 500,
        }}
      >
        {value}
      </p>
    </Stack>
  );
};
