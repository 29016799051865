import React, { useEffect, useState } from "react";
import NItem from "../NItem";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { AddRounded, CloseRounded, SearchRounded } from "@mui/icons-material";
import { useSelector } from "react-redux";
import FilterIcon from "../../../assets/workoutBuilder/filterIcon.svg";
import FilterActiveIcon from "../../../assets/workouts/ActiveFunnel.svg";
const MealScheduleList = (props) => {
  const {
    title = "",
    listData = [],
    defaultNItem = {},
    setdefaultNItem = () => {},
    handleAddModal = () => {},
    setUnsavedChanges = () => {},
    searchLoading = false,
    unsavedChanges,
    getMealScheduleByID,
    saveMealSchedule,
    subbordinateCoachMealSchedules,
  } = props;

  const [tempId, setTempId] = useState();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [displaySearch, setDisplaySearch] = useState(false);
  const [searchedTerm, setSearchedTerm] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [showFilteredIcon, setShowFilteredIcon] = useState(false);
  const [templateMealSchedules, setTemplateMealSchedules] = useState([]);
  const [mealSchedules, setMealSchedules] = useState([]);

  const [activeTab, setActiveTab] = useState(0);
  const [
    subbordinateCoachesToBeDisplayed,
    setSubbordinateCoachesToBeDisplayed,
  ] = useState([]);
  const [subbordinateCoachesNames, setSubbordinateCoachesNames] = useState([]);
  const isAdmin = useSelector((state) => state.auth?.data?.isAdmin);
  const adminID = useSelector((state) => state.auth.data?.adminID);
  const gymAffiliation = useSelector(
    (state) => state.auth?.data?.gymAffliation
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const handleOnClickFilter = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleShowingFilteredData = (name) => {
    const filteredData = subbordinateCoachMealSchedules.filter(
      (item) => item?.coach?.name === name
    );
    console.log("filtered data", filteredData);
    setSubbordinateCoachesToBeDisplayed(filteredData);
    setdefaultNItem(filteredData[0]);
    getMealScheduleByID(filteredData[0], filteredData[0].id);
  };
  const isLivezy = gymAffiliation === "Livezy";

  useEffect(() => {
    // if (!listData[0]?.id) {
    //   setdefaultNItem(listData[0]);
    // }

    if (listData.length > 0) {
      // separate template and program meal schedules
      const templateMealSchedules = listData.filter(
        (mealSchedule) => mealSchedule?.isTemplate || mealSchedule?.id === 999
      );
      const tempMealSchedules = listData.filter(
        (mealSchedule) => !mealSchedule?.isTemplate || mealSchedule?.id === 999
      );
      setdefaultNItem(tempMealSchedules[0]);
      setTemplateMealSchedules(templateMealSchedules);
      setMealSchedules(tempMealSchedules);
    } else {
      setTemplateMealSchedules([]);
      setMealSchedules([]);
    }
  }, [listData]);

  const handleItemClick = (itm, id) => {
    console.log("item, id", itm, id);
    getMealScheduleByID(itm, id);
    setdefaultNItem(itm);
    setUnsavedChanges(false);
  };

  useEffect(() => {
    if (activeTab === 0 && mealSchedules.length > 0) {
      setdefaultNItem(mealSchedules[0]);
      getMealScheduleByID(mealSchedules[0], mealSchedules[0]?.id);
    } else if (activeTab === 1 && templateMealSchedules.length > 0) {
      setdefaultNItem(templateMealSchedules[0]);
      getMealScheduleByID(
        templateMealSchedules[0],
        templateMealSchedules[0]?.id
      );
    } else if (activeTab === 2 && subbordinateCoachMealSchedules.length > 0) {
      setdefaultNItem(subbordinateCoachMealSchedules[0]);
      getMealScheduleByID(
        subbordinateCoachMealSchedules[0],
        subbordinateCoachMealSchedules[0]?.id
      );
      setSubbordinateCoachesToBeDisplayed(subbordinateCoachMealSchedules);
      const uniqueNamesSet = new Set();
      console.log("subb ordinate data", subbordinateCoachMealSchedules);
      // Iterate over the array of objects
      subbordinateCoachMealSchedules.forEach((obj) => {
        // Check if the 'name' property exists in the object
        if (obj.hasOwnProperty("coach") && obj.coach.hasOwnProperty("name")) {
          // Add the 'name' to the Set
          uniqueNamesSet.add(obj.coach.name);
        }
      });

      // Convert the Set back to an array
      const uniqueNamesArray = Array.from(uniqueNamesSet);
      console.log("unique", uniqueNamesArray);
      setSubbordinateCoachesNames(uniqueNamesArray);
    }
  }, [activeTab]);

  return (
    <>
      <div className="relative h-full">
        {/* Title bar with search */}
        <div
          style={{
            borderBottom: " 1px solid #ebebeb",
            paddingBottom: "8px",
            marginBottom: "8px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span className="text-font16 font-semibold">{title}</span>
            {!displaySearch ? (
              <SearchRounded
                className="cursor-pointer"
                onClick={() => {
                  setDisplaySearch(!displaySearch);
                }}
              />
            ) : (
              <CloseRounded
                className="cursor-pointer"
                onClick={() => {
                  setDisplaySearch(!displaySearch);
                  setSearchedTerm("");
                  props?.cancelSearch();
                }}
              />
            )}
          </div>
          {displaySearch && (
            <input
              type="text"
              placeholder="Search"
              style={{
                marginTop: "20px",
                width: "100%",
                height: "40px",
                borderRadius: "20px",
                paddingLeft: "20px",
                paddingRight: "20px",
                outline: "none",
                border: "1px solid #EDEBE9",
              }}
              autoFocus
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  props?.paginationQuery(searchedTerm);
                }
              }}
              onChange={(e) => {
                if (pageNo > 1) {
                  setPageNo(1);
                }
                let value = e.target.value;

                props?.paginationQuery(value);

                setSearchedTerm(value);
              }}
            />
          )}
          {searchLoading && <LinearProgress />}
        </div>

        {!isLivezy && (
          <Tabs
            value={activeTab}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              style={{
                fontSize: "12px",
                padding: "0 8px",
              }}
              label="My Meal Schedules"
              {...a11yProps(0)}
            />
            <Tab
              style={{
                fontSize: "12px",
                padding: "0 8px",
              }}
              label="Templates"
              {...a11yProps(1)}
            />
            <Tab
              style={{
                fontSize: "12px",
                padding: "0 8px",
              }}
              label="Others"
              {...a11yProps(2)}
            />
            {activeTab === 2 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "auto",
                  position: "relative",
                }}
              >
                <button
                  style={{
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                  }}
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleOnClickFilter}
                >
                  {showFilteredIcon ? (
                    <img src={FilterActiveIcon} alt />
                  ) : (
                    <img src={FilterIcon} alt />
                  )}
                </button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  {subbordinateCoachesNames.map((name, index) => (
                    <MenuItem
                      style={{
                        fontSize: "12px",
                      }}
                      key={index}
                      onClick={() => {
                        handleShowingFilteredData(name);
                        setAnchorEl(null);
                        setShowFilteredIcon(true);
                      }}
                    >
                      {name}
                    </MenuItem>
                  ))}
                  <MenuItem
                    style={{
                      color: "red",
                      fontSize: "12px",
                    }}
                    onClick={() => {
                      setSubbordinateCoachesToBeDisplayed(
                        subbordinateCoachMealSchedules
                      );
                      setAnchorEl(null);
                      setShowFilteredIcon(false);
                      setdefaultNItem(subbordinateCoachMealSchedules[0]);
                      getMealScheduleByID(
                        subbordinateCoachMealSchedules[0],
                        subbordinateCoachMealSchedules[0].id
                      );
                    }}
                  >
                    Clear Filter
                  </MenuItem>
                </Menu>
              </div>
            )}
          </Tabs>
        )}
        {/* List of item */}

        <CustomTabPanel
          style={{
            paddingTop: "16px",
            width: "100%",
            height: "calc(100vh - 200px)",
            overflowY: "scroll",
          }}
          value={activeTab}
          index={0}
        >
          <ShowList
            listData={mealSchedules}
            defaultNItem={defaultNItem}
            unsavedChanges={unsavedChanges}
            setTempId={setTempId}
            setOpenConfirmationModal={setOpenConfirmationModal}
            handleItemClick={handleItemClick}
            adminID={adminID}
          />
        </CustomTabPanel>

        <CustomTabPanel
          style={{
            paddingTop: "16px",
            width: "100%",
            height: "calc(100vh - 200px)",
            overflowY: "scroll",
          }}
          value={activeTab}
          index={1}
        >
          <ShowList
            listData={templateMealSchedules}
            defaultNItem={defaultNItem}
            unsavedChanges={unsavedChanges}
            setTempId={setTempId}
            setOpenConfirmationModal={setOpenConfirmationModal}
            handleItemClick={handleItemClick}
            adminID={adminID}
          />
        </CustomTabPanel>

        <CustomTabPanel
          style={{
            paddingTop: "16px",
            width: "100%",
            height: "calc(100vh - 200px)",
            overflowY: "scroll",
          }}
          value={activeTab}
          index={2}
        >
          <ShowList
            listData={subbordinateCoachesToBeDisplayed}
            defaultNItem={defaultNItem}
            unsavedChanges={unsavedChanges}
            setTempId={setTempId}
            setOpenConfirmationModal={setOpenConfirmationModal}
            handleItemClick={handleItemClick}
            adminID={adminID}
            isOthersTab={true}
          />
        </CustomTabPanel>
        {(activeTab === 0 || isAdmin || !adminID) && (
          <div
            style={{
              position: "absolute",
              top: "auto",
              bottom: "10px",
              right: "0px",
              borderRadius: "100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "44px",
              width: "44px",
              background:
                "linear-gradient(90deg, #464FEB 10.42%, #8330E9 100%)",
              cursor: "pointer",
            }}
            onClick={() => {
              handleAddModal(activeTab === 1 ? true : false);
            }}
          >
            <AddRounded
              style={{
                color: "white",
              }}
            />
          </div>
        )}
      </div>
      <CustomModal
        onConfirm={() => handleItemClick(tempId, tempId.id)}
        onCancel={() => setOpenConfirmationModal(false)}
        isOpen={openConfirmationModal}
        close={setOpenConfirmationModal}
        saveMealSchedule={async () => {
          // let temp = JSON.parse(JSON.stringify(tempId));
          let res = saveMealSchedule();
          // let timeout = setTimeout(() => {
          handleItemClick(tempId, tempId.id);
          //   clearTimeout(timeout);
          // }, 1000);
        }}
      />
    </>
  );
};

export default MealScheduleList;

function ShowList({
  listData,
  defaultNItem,
  unsavedChanges,
  setTempId,
  setOpenConfirmationModal,
  handleItemClick,
  adminID,
  isOthersTab,
}) {
  console.log("defaultNItem", defaultNItem);
  return (
    <div
      className="relative h-full overflow-auto flex flex-col"
      style={{
        paddingBottom: "100px",
        gap: "8px",
      }}
    >
      {listData?.map((itf, iif) => {
        return (
          <NItem
            key={iif}
            itf={itf}
            defaultNItem={defaultNItem}
            setdefaultNItem={(itm) => {
              if (unsavedChanges) {
                setTempId(itm);
                setOpenConfirmationModal(true);
              } else {
                handleItemClick(itm, itm.id);
              }
            }}
            isAdmin={adminID && itf?.coachID === adminID}
            isOthersTab={isOthersTab}
          />
        );
      })}
    </div>
  );
}

function CustomModal({ onConfirm, onCancel, isOpen, close, saveMealSchedule }) {
  const handleClose = (value) => {
    close(false);
    if (value === "continue") {
      onCancel();
    } else if (value === "discard") {
      onConfirm();
    } else {
      saveMealSchedule();
    }
  };

  return (
    <Dialog open={isOpen}>
      <DialogTitle>Unsaved Changes</DialogTitle>
      <DialogContent>
        <p className="font-DMSans text-font14">
          You have unsaved changes in your current meal schedule. Do you want to
          wanna save or continue editing?
        </p>
      </DialogContent>
      <DialogActions>
        <Button color="warning" onClick={() => handleClose("discard")}>
          Discard
        </Button>
        <Button onClick={() => handleClose("save")} color="info">
          Save
        </Button>
        <button
          className="button-new"
          style={{
            width: 100,
          }}
          onClick={() => handleClose("continue")}
          color="primary"
        >
          Continue
        </button>
      </DialogActions>
    </Dialog>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
