import React from 'react'

const BlackBinIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
  <path d="M3 6.16797H5H21" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M19 6.16797V20.168C19 20.6984 18.7893 21.2071 18.4142 21.5822C18.0391 21.9573 17.5304 22.168 17 22.168H7C6.46957 22.168 5.96086 21.9573 5.58579 21.5822C5.21071 21.2071 5 20.6984 5 20.168V6.16797M8 6.16797V4.16797C8 3.63754 8.21071 3.12883 8.58579 2.75376C8.96086 2.37868 9.46957 2.16797 10 2.16797H14C14.5304 2.16797 15.0391 2.37868 15.4142 2.75376C15.7893 3.12883 16 3.63754 16 4.16797V6.16797" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  )
}

export default BlackBinIcon