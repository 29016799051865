import {
  Typography,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  RadioGroup,
  FormControlLabel,
  Radio,
  Paper,
  Chip,
} from "@mui/material";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import CheckBox, {
  CheckBoxGroup,
  CheckBoxLabel,
  RenderProperty,
  TimeInput,
} from "./AddProperitesHelpers";
import EmptyViewAddProerties from "./EmptyViewAddProerties";
import TextArea from "../InputComponents/TextArea";
import { WorkoutContext } from "./WorkoutBuilder";
import {
  TRAINING_TYPES,
  getTrainingType,
} from "../../common/trainingTypeHelper";
import { CustomAlertContext } from "../../App";
import { SpurfitCircularProgress } from "../Dashboard/dashboard";
import VideoPlayerModel from "../VideoPlayer/VideoPlayer";
import { useSelector } from "react-redux";
import AddExerciseModal from "../../pages/Exercises/AddExerciseModal";
import { useDispatch } from "react-redux";
import { modifyExerciseObject } from "../../redux/dataSlice";
import { WorkoutModalContext } from "./WorkoutBuilderModal";
import CustomSelect from "./Endurance/util/CustomSelect";

const AddWarmUpProperties = forwardRef(
  (
    {
      exercise,
      updateExerciseProperty,
      roundIndex,
      index,
      rounds,
      incrementRound,
      decrementRound,
      selectedExerciseInfo,
      updateExerciseToParticularRound,
      comingFromModal,
    },
    ref
  ) => {
    const [showUpdateExerciseModal, setShowUpdateExerciseModal] =
      useState(false);
    const [videoInfo, setVideoInfo] = useState(null);
    const [selectedItem, setSelectedItem] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [roundIdx, setRoundIdx] = useState(0);
    const [idx, setIdx] = useState(0);
    // reps
    const { removeOneProperty } = useContext(
      comingFromModal ? WorkoutModalContext : WorkoutContext
    );
    const [reps, setReps] = useState(exercise?.reps || {});
    const [repsMax, setRepsMax] = useState(exercise?.repsMax || {});
    const [repsF, setRepsF] = useState(exercise?.repsF || {});
    const [repsInputType, setRepsInputType] = useState(
      exercise?.repsMax ? "range" : "perset"
    );
    const [sideInfo, setSideInfo] = useState(exercise?.sideInfo || "None");

    // weight
    const [weight, setWeight] = useState(exercise?.weight || {});
    const [weightMax, setWeightMax] = useState(exercise?.weightMax || {});
    const [weightF, setWeightF] = useState(exercise?.weightF || {});
    const [weightInputType, setWeightInputType] = useState(
      exercise?.weightMax ? "range" : "perset"
    );
    const [weightUnits, setWeightUnits] = useState(
      exercise?.weightUnits || "Pounds"
    );

    // height
    const [height, setHeight] = useState(exercise?.height || {});
    const [heightMax, setHeightMax] = useState(exercise?.heightMax || {});
    const [heightF, setHeightF] = useState(exercise?.heightF || {});
    const [heightInputType, setHeightInputType] = useState(
      exercise?.heightMax ? "range" : "perset"
    );
    const [heightUnits, setHeightUnits] = useState(
      exercise?.heightUnits || "Inches"
    );

    // distance
    const [distance, setDistance] = useState(exercise?.distance || {});
    const [distanceMax, setDistanceMax] = useState(exercise?.distanceMax || {});
    const [distanceF, setDistanceF] = useState(exercise?.distanceF || {});
    const [distanceInputType, setDistanceInputType] = useState(
      exercise?.distanceMax ? "range" : "perset"
    );
    const [distanceUnits, setDistanceUnits] = useState(
      exercise?.distanceUnits || "Meters"
    );

    // calorie
    const [calorie, setCalorie] = useState(exercise?.calorie || {});
    const [calorieMax, setCalorieMax] = useState(exercise?.calorieMax || {});
    const [calorieF, setCalorieF] = useState(exercise?.calorieF || {});
    const [calorieInputType, setCalorieInputType] = useState(
      exercise?.calorieMax ? "range" : "perset"
    );

    // duration
    const [duration, setDuration] = useState(exercise?.duration || {});

    // record from user
    const [recordFromUser, setRecordFromUser] = useState({
      reps: false,
      weight: false,
      height: false,
      distance: false,
      calorie: false,
      duration: false,
    });

    // notes
    const [notes, setNotes] = useState(exercise?.movementInstr || "");

    const [seconds, setSeconds] = useState(exercise?.duration / 60 || 0);
    const [minutes, setMinutes] = useState(exercise?.duration % 60 || 0);

    const [showProperties, setShowProperties] = useState({
      weight: false,
      height: false,
      distance: false,
      calorie: false,
      duration: false,
    });

    let isSNC = getTrainingType() === TRAINING_TYPES.SANDC;
    const { displayAlert } = useContext(CustomAlertContext);

    const [showVideoDialog, setShowVideoDialog] = useState(false);
    const exercisesObj = useSelector((state) => state.data.exerciseObject);
    const dispatch = useDispatch();
    const options = isSNC
      ? [
          { label: "Weight", value: "weight" },
          { label: "Height", value: "height" },
          { label: "Duration", value: "duration" },
        ]
      : [
          // { label: "Reps", value: "reps" },
          { label: "Weight", value: "weight" },
          { label: "Height", value: "height" },
          { label: "Duration", value: "duration" },
          { label: "Distance", value: "distance" },
          { label: "Calorie", value: "calorie" },
        ];

    const enabled = isSNC
      ? ["Weight", "Height", "Duration"]
      : ["Weight", "Height", "Duration", "Distance", "Calorie"];

    const stateMap = {
      reps: [reps, setReps],
      repsMax: [repsMax, setRepsMax],
      repsF: [repsF, setRepsF],
      weight: [weight, setWeight],
      weightMax: [weightMax, setWeightMax],
      weightF: [weightF, setWeightF],
      height: [height, setHeight],
      heightMax: [heightMax, setHeightMax],
      heightF: [heightF, setHeightF],
      distance: [distance, setDistance],
      distanceMax: [distanceMax, setDistanceMax],
      distanceF: [distanceF, setDistanceF],
      calorie: [calorie, setCalorie],
      calorieMax: [calorieMax, setCalorieMax],
      calorieF: [calorieF, setCalorieF],
      duration: [duration, setDuration],
    };

    const checkEmptyEntries = (obj) => {
      if (!obj || Object.entries(obj).length === 0) return true;
      return Object.values(obj).every((x) => x === "" || x === undefined);
    };

    const updateExercisePropertyHelper = (
      roundIndex,
      index,
      propertyName,
      inputValues,
      rounds,
      inputType
    ) => {
      if (!checkEmptyEntries(inputValues)) {
        const updatedProperty = getUpdatedProperty(inputValues, rounds);
        if (inputType === "range" && !checkEmptyEntries(inputValues)) {
          let maxStates = stateMap[`${propertyName}Max`];
          let updatedMaxProperty = getUpdatedProperty(maxStates[0], rounds);
          if (checkEmptyEntries(updatedMaxProperty)) {
            displayAlert({
              message: `Please min and max value for ${propertyName} range.`,
              type: "error",
            });
            return;
          }
          updateExerciseProperty(
            roundIndex,
            index,
            `${propertyName}Max`,
            updatedMaxProperty
          );
          maxStates[1](updatedMaxProperty);
        } else {
          removeOneProperty(roundIndex, index, `${propertyName}Max`);
          stateMap[`${propertyName}Max`][1]({});
        }
        updateExerciseProperty(
          roundIndex,
          index,
          propertyName,
          updatedProperty
        );
        stateMap[propertyName][1](updatedProperty);
      }
    };

    const onSave = (roundIdx, index) => {
      updateExercisePropertyHelper(
        roundIdx,
        index,
        "reps",
        reps,
        rounds,
        repsInputType
      );
      updateExercisePropertyHelper(roundIdx, index, "repsF", repsF, rounds);
      updateExercisePropertyHelper(
        roundIdx,
        index,
        "weight",
        weight,
        rounds,
        weightInputType
      );
      updateExercisePropertyHelper(roundIdx, index, "weightF", weightF, rounds);
      updateExerciseProperty(roundIdx, index, "weightUnits", weightUnits);
      updateExercisePropertyHelper(
        roundIdx,
        index,
        "height",
        height,
        rounds,
        heightInputType
      );
      updateExercisePropertyHelper(roundIdx, index, "heightF", heightF, rounds);
      updateExerciseProperty(roundIdx, index, "heightUnits", heightUnits);
      updateExercisePropertyHelper(
        roundIdx,
        index,
        "calorie",
        calorie,
        rounds,
        calorieInputType
      );
      updateExercisePropertyHelper(
        roundIdx,
        index,
        "calorieF",
        calorieF,
        rounds
      );
      updateExercisePropertyHelper(
        roundIdx,
        index,
        "distance",
        distance,
        rounds,
        distanceInputType
      );
      updateExercisePropertyHelper(
        roundIdx,
        index,
        "distanceF",
        distanceF,
        rounds
      );
      if (sideInfo === "None") {
        updateExerciseProperty(roundIdx, index, "sideInfo", "");
      } else {
        updateExerciseProperty(roundIdx, index, "sideInfo", sideInfo);
      }
      updateExerciseProperty(roundIdx, index, "distanceUnits", distanceUnits);
      updateExerciseProperty(roundIdx, index, "duration", duration);

      updateExerciseProperty(roundIdx, index, "recordFromUser", recordFromUser);
      updateExerciseProperty(roundIdx, index, "movementInstr", notes);

      Object.entries(showProperties).map(([key, value]) => {
        if (!value) {
          removeOneProperty(roundIdx, index, key);
          stateMap[key][1]({});
        }
      });
    };

    useImperativeHandle(ref, () => ({
      saveData() {
        onSave(roundIdx, idx);
      },
    }));

    const onRoundsChange = () => {
      let updatedReps = getUpdatedProperty(reps, rounds);
      let updatedRepsMax = getUpdatedProperty(repsMax, rounds);
      let updatedRepsF = getUpdatedProperty(repsF, rounds);

      let updatedHeight = getUpdatedProperty(height, rounds);
      let updatedHeightMax = getUpdatedProperty(heightMax, rounds);
      let updatedHeightF = getUpdatedProperty(heightF, rounds);

      let updatedWeight = getUpdatedProperty(weight, rounds);
      let updatedWeightMax = getUpdatedProperty(weightMax, rounds);
      let updatedWeightF = getUpdatedProperty(weightF, rounds);

      let updatedCalories = getUpdatedProperty(calorie, rounds);
      let updatedCaloriesMax = getUpdatedProperty(calorieMax, rounds);
      let updatedCaloriesF = getUpdatedProperty(calorieF, rounds);

      let updatedDistance = getUpdatedProperty(distance, rounds);
      let updatedDistanceMax = getUpdatedProperty(distanceMax, rounds);
      let updatedDistanceF = getUpdatedProperty(distanceF, rounds);

      let updatedDuration = getUpdatedProperty(duration, rounds);

      setReps(updatedReps);
      setRepsMax(updatedRepsMax);
      setRepsF(updatedRepsF);

      setHeight(updatedHeight);
      setHeightMax(updatedHeightMax);
      setHeightF(updatedHeightF);

      setWeight(updatedWeight);
      setWeightMax(updatedWeightMax);
      setWeightF(updatedWeightF);

      setCalorie(updatedCalories);
      setCalorieMax(updatedCaloriesMax);
      setCalorieF(updatedCaloriesF);

      setDistance(updatedDistance);
      setDistanceMax(updatedDistanceMax);
      setDistanceF(updatedDistanceF);

      setDuration(updatedDuration);
    };

    const getUpdatedProperty = (property, rounds) => {
      let updatedProperty = {};
      let prevValue = -1;
      for (let i = 1; i <= rounds; i++) {
        if (property?.[i]) {
          updatedProperty[i] = property[i];
          prevValue = property[i];
          let j = i - 1;
          while ((!updatedProperty[j] || updatedProperty[j] === "") && j > 0) {
            updatedProperty[j] = prevValue;
            j--;
          }
        } else if (prevValue !== -1) {
          updatedProperty[i] = prevValue;
        } else {
          updatedProperty[i] = "";
        }
      }
      return updatedProperty;
    };

    const getEmptyState = (state) => {
      if (!state || Object.entries(state).length === 0) {
        let newState = {};
        for (let i = 1; i <= rounds; i++) {
          newState[i] = "";
        }
        return newState;
      } else if (Object.entries(state) < rounds - 1) {
        let newState = { ...state };
        for (let i = Object.entries(state).length + 1; i <= rounds; i++) {
          newState[i] = "";
        }
        return newState;
      } else {
        return state;
      }
    };

    useEffect(() => {
      // onSave(roundIdx, idx);
      setIsLoading(true);
      setTimeout(() => {
        setAgain(selectedExerciseInfo?.exercise);
        setIsLoading(false);
      }, 100);
    }, [exercise, selectedExerciseInfo]);

    const setAgain = (exercise) => {
      setRoundIdx(roundIndex);
      setIdx(index);
      setSideInfo(exercise?.sideInfo || "None");
      setReps(getEmptyState(exercise?.reps));
      setRepsMax(exercise?.repsMax || getEmptyState(exercise?.repsMax));
      setRepsF(exercise?.repsF || getEmptyState(exercise?.repsF));
      setRepsInputType(exercise?.repsMax ? "range" : "perset");

      setWeight(exercise?.weight || getEmptyState(exercise?.weight));
      setWeightMax(exercise?.weightMax || getEmptyState(exercise?.weightMax));
      setWeightF(exercise?.weightF || getEmptyState(exercise?.weightF));
      setWeightUnits(exercise?.weightUnits || "Pounds");
      setWeightInputType(exercise?.weightMax ? "range" : "perset");

      setHeight(exercise?.height || getEmptyState(exercise?.height));
      setHeightMax(exercise?.heightMax || getEmptyState(exercise?.heightMax));
      setHeightF(exercise?.heightF || getEmptyState(exercise?.heightF));
      setHeightUnits(exercise?.heightUnits || "Inches");
      setHeightInputType(exercise?.heightMax ? "range" : "perset");

      setCalorie(exercise?.calorie || getEmptyState(exercise?.calorie));
      setCalorieMax(
        exercise?.calorieMax || getEmptyState(exercise?.calorieMax)
      );
      setCalorieF(exercise?.calorieF || getEmptyState(exercise?.calorieF));
      setCalorieInputType(exercise?.calorieMax ? "range" : "perset");

      setDistance(exercise?.distance || getEmptyState(exercise?.distance));
      setDistanceMax(
        exercise?.distanceMax || getEmptyState(exercise?.distanceMax)
      );
      setDistanceF(exercise?.distanceF || getEmptyState(exercise?.distanceF));
      setDistanceUnits(exercise?.distanceUnits || "Meters");
      setDistanceInputType(exercise?.distanceMax ? "range" : "perset");

      setDuration(exercise?.duration || getEmptyState(exercise?.duration));

      setRecordFromUser({
        reps: exercise?.recordFromUser?.reps ? true : false,
        weight: exercise?.recordFromUser?.weight ? true : false,
        height: exercise?.recordFromUser?.height ? true : false,
        distance: exercise?.recordFromUser?.distance ? true : false,
        calorie: exercise?.recordFromUser?.calorie ? true : false,
        duration: exercise?.recordFromUser?.duration ? true : false,
      });

      setNotes(exercise?.movementInstr || "");

      let properties = { ...showProperties };
      options.map((option) => {
        if (
          (option["value"] == "time" && exercise?.["duration"]) ||
          exercise?.[option["value"]]
        ) {
          properties[option["value"]] = true;
        } else {
          properties[option["value"]] = false;
        }
      });
      setShowProperties(properties);
    };

    useEffect(() => {
      if (reps && rounds !== Object.keys(reps).length) {
        onRoundsChange();
      }
    }, [rounds]);

    if (!selectedExerciseInfo?.exercise || !exercise?.movementName)
      return <EmptyViewAddProerties />;

    return isLoading ? (
      <div
        style={{
          width: "calc((100vw - 110px)/4)",
          height: "calc(100vh - 110px)",
          borderRadius: "12px",
          position: "fixed",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <SpurfitCircularProgress />
      </div>
    ) : (
      <Paper
        sx={{
          width: "calc((100vw - 110px)/4)",
          height: "calc(100vh - 110px)",
          borderRadius: "12px",
          position: "fixed",
        }}
      >
        <div
          style={{
            height: "56px",
            padding: "0 24px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            borderBottom: " 1px solid #E5E5E5",
          }}
          className="exercise-library-title"
        >
          {exercise?.movementName}{" "}
          <InfoOutlinedIcon
            style={{ marginLeft: "8px", cursor: "pointer" }}
            onClick={() => {
              setShowVideoDialog(true);
            }}
          />
        </div>
        <DialogContent
          sx={{
            height: "calc(100vh - 260px)",
            overflowY: "scroll",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "24px",
            }}
          >
            <RenderProperty
              label={"Reps"}
              incrementRound={incrementRound}
              decrementRound={decrementRound}
              rounds={rounds}
              state={reps}
              setState={setReps}
              maxState={repsMax}
              setMaxState={setRepsMax}
              stateF={repsF}
              setStateF={setRepsF}
              showF={false}
              inputType={repsInputType}
              setInputType={setRepsInputType}
            />

            <div className="">
              <div className="property-name">Additional Info</div>
              <div className="mt-2 ml-1">
                <label className="text-font12">Side Info</label>
                <CustomSelect
                  triggerClassName={
                    "w-[150px] text-font14 focus:ring-transparent focus:border-indigo-500 border-[1.5px]"
                  }
                  value={sideInfo}
                  options={[
                    { label: "None", value: "None" },
                    { label: "Per Side", value: "Per Side" },
                    { label: "Per Leg", value: "Per Leg" },
                    { label: "Per Arm", value: "Per Arm" },
                  ]}
                  onValueChange={(value) => {
                    setSideInfo(value);
                  }}
                />
              </div>
            </div>

            {enabled.includes("Weight") && (
              <RenderProperty
                label={"Weight"}
                incrementRound={incrementRound}
                decrementRound={decrementRound}
                rounds={rounds}
                state={weight}
                setState={setWeight}
                maxState={weightMax}
                setMaxState={setWeightMax}
                stateF={weightF}
                setStateF={setWeightF}
                showF={false}
                inputType={weightInputType}
                setInputType={setWeightInputType}
                selectedUnit={weightUnits}
                setSelectedUnit={setWeightUnits}
                unitOptions={weightUnitOptions}
                key={"weight"}
                showProperty={showProperties?.weight}
                setShowProperty={(value) => {
                  setShowProperties({
                    ...showProperties,
                    weight: value,
                  });
                }}
              />
            )}

            {enabled.includes("Height") && (
              <RenderProperty
                label={"Height"}
                incrementRound={incrementRound}
                decrementRound={decrementRound}
                rounds={rounds}
                state={height}
                setState={setHeight}
                maxState={heightMax}
                setMaxState={setHeightMax}
                stateF={heightF}
                setStateF={setHeightF}
                showF={false}
                inputType={heightInputType}
                setInputType={setHeightInputType}
                selectedUnit={heightUnits}
                setSelectedUnit={setHeightUnits}
                unitOptions={heightUnitOptions}
                key={"height"}
                showProperty={showProperties?.height}
                setShowProperty={(value) => {
                  setShowProperties({
                    ...showProperties,
                    height: value,
                  });
                }}
              />
            )}

            {enabled.includes("Distance") && (
              <RenderProperty
                label={"Distance"}
                incrementRound={incrementRound}
                decrementRound={decrementRound}
                rounds={rounds}
                state={distance}
                setState={setDistance}
                maxState={distanceMax}
                setMaxState={setDistanceMax}
                stateF={distanceF}
                setStateF={setDistanceF}
                showF={false}
                inputType={distanceInputType}
                setInputType={setDistanceInputType}
                selectedUnit={distanceUnits}
                setSelectedUnit={setDistanceUnits}
                unitOptions={distanceUnitOptions}
                key={"distance"}
                showProperty={showProperties?.distance}
                setShowProperty={(value) => {
                  setShowProperties({
                    ...showProperties,
                    distance: value,
                  });
                }}
              />
            )}

            {enabled.includes("Calorie") && (
              <RenderProperty
                label={"Calorie"}
                incrementRound={incrementRound}
                decrementRound={decrementRound}
                rounds={rounds}
                state={calorie}
                setState={setCalorie}
                maxState={calorieMax}
                setMaxState={setCalorieMax}
                stateF={calorieF}
                setStateF={setCalorieF}
                showF={false}
                inputType={calorieInputType}
                setInputType={setCalorieInputType}
                showProperty={showProperties?.calorie}
                setShowProperty={(value) => {
                  setShowProperties({
                    ...showProperties,
                    calorie: value,
                  });
                }}
              />
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "8px",
              }}
            >
              <div className="property-name">Time</div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 4,
                }}
                key={"duration"}
              >
                <CheckBox
                  value={showProperties?.duration}
                  onClick={() =>
                    setShowProperties({
                      ...showProperties,
                      duration: !showProperties?.duration,
                    })
                  }
                />
                <CheckBoxLabel label={"duration"} />
              </div>
              {showProperties?.duration && (
                <div
                  style={{
                    alignItems: "baseline",
                    display: "flex",
                    width: "100%",
                    overflowX: "scroll",
                    gap: "12px",
                  }}
                >
                  {Array.from({ length: rounds }, (_, index) => (
                    <TimeInput
                      incrementRound={incrementRound}
                      decrementRound={decrementRound}
                      rounds={rounds}
                      minutes={minutes}
                      seconds={seconds}
                      setMinutes={setMinutes}
                      setSeconds={setSeconds}
                      duration={duration}
                      setDuration={setDuration}
                      index={index}
                    />
                  ))}
                </div>
              )}
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <div className="property-name">Record from user</div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: 8,
                  marginTop: "8px",
                }}
                name="record-from-user"
              >
                {Object.entries(recordFromUser)?.map(([key, value]) => {
                  return (
                    <div
                      className="chip"
                      style={{
                        cursor: "pointer",
                        background: value
                          ? "linear-gradient(90deg, #464feb 10.42%, #8330e9 100%)"
                          : "#F7F7F7",
                        color: value ? "#fff" : "#000",
                        minWidth: "76px",
                      }}
                      onClick={() => {
                        setRecordFromUser({
                          ...recordFromUser,
                          [key]: !value,
                        });
                      }}
                    >
                      {key}{" "}
                      {value ? (
                        <RemoveRoundedIcon fontSize="16" />
                      ) : (
                        <AddRoundedIcon fontSize="16" />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "8px",
              }}
            >
              <div className="property-name">Notes</div>
              <textarea
                style={{
                  width: "100%",
                  fontFamily: "DM Sans",
                  borderRadius: "12px",
                  border: "1px solid #CCC",
                  padding: "14px 16px",
                  resize: "none",
                  outline: "none",
                }}
                placeholder="instructions"
                value={notes}
                onChange={(e) => {
                  setNotes(e.target.value);
                }}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            p: "24px",
          }}
        >
          <button
            style={{
              width: "100%",
            }}
            className="button-new"
            onClick={() => {
              displayAlert({
                message: "Details updated",
                type: "success",
              });
              onSave(roundIdx, idx);
            }}
          >
            Apply Changes
          </button>
        </DialogActions>

        {showVideoDialog && (
          <VideoPlayerModel
            videoInfo={exercisesObj[exercise?.movementID]}
            setOpenDialog={setShowVideoDialog}
            openDialog={showVideoDialog}
            handleUpdateExercise={() => {
              setShowUpdateExerciseModal(true);
            }}
            setSelectedItem={setSelectedItem}
          />
        )}
        {showUpdateExerciseModal && (
          <AddExerciseModal
            open={showUpdateExerciseModal}
            setOpen={setShowUpdateExerciseModal}
            info={{ ...selectedItem, id: undefined }}
            libraryType={"1"}
            handleShowAlert={(error, type) => {
              console.log(type, error);
            }}
            setVideoInfo={setVideoInfo}
            updateExerciseToParticularRound={(e) => {
              console.log(`Here's is the exercise data : `, e);
              updateExerciseToParticularRound(roundIndex, index, e);
              dispatch(
                modifyExerciseObject({
                  ...exercisesObj,
                  [e.id]: e,
                })
              );
            }}
          />
        )}
      </Paper>
    );
  }
);

export default AddWarmUpProperties;

const heightUnitOptions = ["Inches", "Feet", "Centimeters", "Meters"];
const weightUnitOptions = [
  "Pounds",
  "Kilograms",
  "RepMaxFraction",
  "BodyWeight",
  "RPE",
];
const distanceUnitOptions = [
  "Meters",
  "Kilometers",
  "Feet",
  "Yards",
  "Miles",
  "Inches",
];
