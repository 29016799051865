import { CustomAlertContext } from "@/App";
import { LinearProgress } from "@mui/material";
import axios from "axios";
import moment from "moment";
import React, { forwardRef, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import ShowAssignedPlans from "./ShowAssignedPlans";
import styles from "./calendar.module.css";
import { AssignMeal } from "./functions";

const CalendarTwoWeek = forwardRef(
  (
    {
      profileData,
      userUUID,
      setSelectedDate,
      setSelectedActivity,
      setShowWorkOutBuilder,
      weekStartDate,
      weekEndDate,
      isHighlighted,
      selectedCalendarType,
      garminActivityData,
      setProfileData,
      loadingCalendar,
      userInfo,
      setProfileDataFromClientsView,
      profileDataFromClientsView,
      reloadProfile,
      isExpanded,
      isAdminUserDetail,
    },
    ref
  ) => {
    const APIURL = useSelector((state) => state.auth.apiURL);
    const weekDays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const [isDragging, setIsDragging] = useState(false);
    const [mealData, setMealData] = useState(profileData?.mealData || {});
    const [activityData, setActivityData] = useState(
      profileData?.activityData || {}
    );
    const [workoutData, setWorkoutData] = useState(
      profileData?.currentMonthmodules || {}
    );
    const [eventData, setEventData] = useState(profileData?.eventData || {});
    console.log({ eventData });
    const [currentTwoWeek, setCurrentTwoWeek] = useState([]);
    const [enduranceData, setEnduranceData] = useState([]);

    const [loadingDays, setLoadingDays] = useState({});
    const { displayAlert } = useContext(CustomAlertContext);

    function handleDragStart() {
      console.log("this is from drag start");
      setIsDragging(true);
    }

    async function handleDragEnd(result) {
      setIsDragging(false);
      let draggableId = result?.draggableId;
      let droppableId = result?.destination?.droppableId;
      console.log("result from draggable hellooooooooo", result);

      if (droppableId && droppableId !== "droppable-1") {
        if (draggableId?.includes("workoutLib@")) {
          let parts = draggableId.split("@");
          let result = parts.slice(1).join("@");
          const workout = JSON.parse(result);
          let newWorkoutId = uuidv4();
          let updatedWorkoutData = { ...workoutData };
          let destinationModuleDate = droppableId.split("_")[1];

          let isEndurance =
            workout?.workoutKind === "run" ||
            workout?.workoutKind === "bike" ||
            workout?.workoutKind === "swim";

          let updatedDestinationWorkout = [
            ...(workoutData?.[destinationModuleDate] ?? []),
          ];

          setLoadingDays({ ...loadingDays, [destinationModuleDate]: true });

          let assignedWorkout = await assignWorkoutForWorkoutLibrary(
            workout.id,
            destinationModuleDate,
            newWorkoutId,
            isEndurance
          );

          if (assignedWorkout === "P2002") {
            displayAlert({
              message: "You have already assigned this workout to this date",
              type: "error",
            });
          } else if (!assignedWorkout) {
            displayAlert({
              message: "Something went wrong, please try again",
              type: "error",
            });
          } else {
            let newWorkout = {
              ...assignedWorkout,
              workoutAssigned: { ...workout },
            };
            updatedDestinationWorkout.push({
              ...newWorkout,
              id: newWorkoutId,
            });
            updatedWorkoutData[destinationModuleDate] =
              updatedDestinationWorkout;
            setWorkoutData(updatedWorkoutData);

            const newState = { ...profileData };
            newState.currentMonthmodules = updatedWorkoutData;
            setProfileData(newState, true);
          }
          setLoadingDays({ ...loadingDays, [destinationModuleDate]: false });
        } else if (draggableId?.includes("meal@")) {
          let mealCopied = JSON.parse(draggableId.split("@")[1]);
          let sourceModuleDate = draggableId.split("@")[2].split("_")[1];
          let destinationModuleDate = droppableId.split("_")[1];
          if (sourceModuleDate === destinationModuleDate) {
            return;
          } else {
            let updatedMealData = { ...mealData };
            let updatedSourceMeals = [...(mealData?.[sourceModuleDate] ?? [])];
            let updatedDestinationMeals = [
              ...(mealData?.[destinationModuleDate] ?? []),
            ];
            let newAssignedId = uuidv4();
            let indexToRemove = updatedSourceMeals.findIndex(
              (item) => item.id === mealCopied.assignedId
            );
            let newCopiedMeal = {
              ...(updatedSourceMeals[indexToRemove] ?? {}),
            };
            updatedSourceMeals.splice(indexToRemove, 1);
            updatedMealData[sourceModuleDate] = updatedSourceMeals;
            updatedDestinationMeals.push({
              ...newCopiedMeal,
              id: newAssignedId,
            });
            updatedMealData[destinationModuleDate] = updatedDestinationMeals;
            setMealData(updatedMealData);

            AssignMeal(
              newAssignedId,
              userUUID,
              mealCopied,
              destinationModuleDate
            );
          }
        } else {
          // const name = JSON.parse(draggableId.split("_")[0].split("-")[1]).Name;
          const sourceModuleDate = draggableId.split("_")[2].split("@")[1];
          let PrimaryKey = draggableId.split("_")[3].split("@")[1];
          const workoutId = draggableId.split("_")[4].split("@")[1];
          const destinationModuleDate = droppableId.split("_")[1];
          PrimaryKey = JSON.parse(PrimaryKey);

          if (sourceModuleDate === destinationModuleDate) {
            return;
          } else {
            let newWorkoutId = uuidv4();
            let updatedWorkoutData = { ...workoutData };

            let updatedSourceWorkout = [
              ...(workoutData?.[sourceModuleDate] ?? []),
            ];
            let updatedDestinationWorkout = [
              ...(workoutData?.[destinationModuleDate] ?? []),
            ];
            let indexToRemove = updatedSourceWorkout.findIndex(
              (item) => item.id === workoutId
            );
            let workoutAssignedId =
              updatedSourceWorkout?.[indexToRemove]?.workoutAssigned?.id;

            let workoutKind =
              workoutData?.[sourceModuleDate]?.[indexToRemove]?.workoutAssigned
                ?.workoutKind;
            let isEndurance =
              workoutKind === "run" ||
              workoutKind === "bike" ||
              workoutKind === "swim";
            let newCopiedWorkout = {
              ...(updatedSourceWorkout[indexToRemove] ?? {}),
            };
            updatedSourceWorkout.splice(indexToRemove, 1);
            updatedWorkoutData[sourceModuleDate] =
              updatedSourceWorkout.length === 0
                ? undefined
                : updatedSourceWorkout;
            updatedDestinationWorkout.push({
              ...newCopiedWorkout,
              id: newWorkoutId,
            });
            updatedWorkoutData[destinationModuleDate] =
              updatedDestinationWorkout;
            setWorkoutData(updatedWorkoutData);

            assignWorkout(
              workoutAssignedId,
              destinationModuleDate,
              newWorkoutId,
              isEndurance
            );
            deleteIndividualWorkout(workoutId, PrimaryKey, isEndurance);
          }
        }
      } else {
        return;
      }
    }
    React.useImperativeHandle(ref, () => ({
      handleDragEnd,
      handleDragStart,
    }));

    const assignWorkoutForWorkoutLibrary = async (
      workoutId,
      date,
      primaryKey,
      isEndurance
    ) => {
      let userID = userUUID ?? userInfo?.id;
      try {
        let res = await axios({
          method: "post",
          url: `${APIURL}CF/api/assignWorkoutOnDrop`,
          data: {
            userUUID: userID,
            id: primaryKey,
            modulesUUID: workoutId,
            moduleDate: `${moment(date, "YYYY-MM-DD")
              .startOf("day")
              .format("YYYY-MM-DDTHH:mm:ss")}Z`,
            isEnduranceWorkout: isEndurance,
          },
        });

        return res?.data?.result;
      } catch (error) {
        console.log("error in assignWorkoutForWorkoutLibrary", error);
        return error?.response?.data?.code === "P2002" ? "P2002" : null;
      }
    };

    const prepareCurrentTwoWeek = () => {
      let start = moment(weekStartDate);
      let currTwoWeek = [];
      while (start.isBefore(weekEndDate)) {
        currTwoWeek.push(start.format("YYYY-MM-DD"));
        start.add(1, "day");
      }
      console.log("current week data:-", currTwoWeek);
      setCurrentTwoWeek(currTwoWeek);
    };
    // this function deletes the individual workout
    const deleteIndividualWorkout = async (
      assignedWorkoutID,
      primaryKeyArray,
      isEndurance
    ) => {
      await axios({
        method: "post",
        url: `${APIURL}CF/api/deleteAssignedWorkoutAthelete`,
        data: {
          assignedWorkoutID: assignedWorkoutID,
          newOrderWorkoutIDs: primaryKeyArray,
          isEnduranceWorkout: isEndurance,
        },
      })
        .then((res) => {
          console.log("deleteAssignedWorkoutAthelete: ", res);
        })
        .catch((err) => {
          console.log("Network error, please try again", err);
        });
    };
    const assignWorkout = async (workoutId, date, primaryKey, isEndurance) => {
      await axios({
        method: "post",
        url: `${APIURL}CF/api/modules`,
        data: {
          userUUID: userUUID,
          id: primaryKey,
          modulesUUID: workoutId,
          moduleDate: `${moment(date, "YYYY-MM-DD")
            .startOf("day")
            .format("YYYY-MM-DDTHH:mm:ss")}Z`,
          isEnduranceWorkout: isEndurance,
        },
      })
        .then((res) => {
          console.log("assignWorkout success ");
        })
        .catch((err) => {
          console.log("assignWorkout error ", err);
        });
    };

    useEffect(() => {
      console.log("profile data jatin", profileData);
      prepareCurrentTwoWeek();
    }, [weekStartDate, weekEndDate]);
    useEffect(() => {
      console.log("what is profile data jatin", profileData);
      setWorkoutData(profileData?.currentMonthmodules);
      setMealData(profileData?.mealData);
      setEventData(profileData?.eventData);
      setActivityData(profileData?.activityData);
      setEnduranceData(garminActivityData);
    }, [profileData]);

    return (
      <>
        {loadingCalendar && (
          <LinearProgress style={{ marginTop: 10 }} sx={{ color: "#6242EA" }} />
        )}
        <div
          className={styles.calendarV3}
        
          style={{
            height: isExpanded && "calc(100vh - 200px)",
          }}
        >
          {weekDays.map((day, index) => {
            return (
              <div className={styles.calendarV3Days} key={index}>
                {day}
              </div>
            );
          })}
          {currentTwoWeek.map((currentDate) => {
            let workouts = workoutData?.[currentDate];
            let m = mealData?.[currentDate];
            let meals = [];
            if (m) {
              m?.forEach((item) => {
                let calories =
                  item?.unsavedMeals?.calories ||
                  item?.mealPlans?.plandetails?.calories ||
                  0;
                let Name =
                  item?.mealPlans?.reference_name ||
                  item?.unsavedMeals?.day ||
                  "";
                let assignedId = item?.id;
                let mealId = item?.mealId;
                meals.push({
                  Name,
                  calories,
                  assignedId,
                  mealId,
                  dateAssigned: item?.dateAssigned,
                  item,
                });
              });
            }
            let activities = activityData?.[currentDate];
            let events = eventData?.[currentDate];
            let currentDateMoment = moment(currentDate);
            let currentDay = currentDateMoment.format("dddd");
            let currentDD = currentDateMoment.format("DD");
            let todayDate = moment().format("YYYY-MM-DD");
            let shouldHighLight = false;
            if (isHighlighted) {
              if (todayDate === currentDate) {
                shouldHighLight = true;
              } else {
                shouldHighLight = false;
              }
            }
            let tempPrimaryKey = [];
            workouts?.forEach((workout) => {
              tempPrimaryKey.push(workout.id);
            });
            console.log("PRIMARY KEY TEMP", tempPrimaryKey);
            let primaryKey = JSON.stringify(tempPrimaryKey);
            console.log("PRIMARY KEY", primaryKey);
            let status = [];
            workouts?.forEach((workout) => {
              if (currentDate <= todayDate && workout?.finished) {
                status.push("done");
              } else if (currentDate <= todayDate && !workout?.finished) {
                status.push("not done");
              }
            });
            console.log("what is todays formatted date", todayDate);
            let todaysFormattedDate = moment(currentDate).format("YYYY-MM-DD");
            let currentEnduranceData = enduranceData?.[todaysFormattedDate];

            console.log("current date wmae", {
              workouts,
              meals,
              activities,
              events,
            });
            return (
              <div>
                <div className={styles.currentDateTwoWeek}>
                  {shouldHighLight ? (
                    <div className={`pl-1 text-font16`}>
                      <p className={`gradient-text`}>TODAY</p>
                    </div>
                  ) : (
                    <p className={styles.currentDay}>{currentDD}</p>
                  )}
                </div>
                <div
                  className={`${styles.currentDateInfo} ${
                    shouldHighLight && styles.dayHighlightedBorder
                  }`}
                >
                  <ShowAssignedPlans
                    workouts={workouts}
                    setWorkouts={(data) => {
                      // currentmonthmodules will be workouts array for the current date
                      let updatedWorkoutData = { ...workoutData };
                      updatedWorkoutData[currentDate] = data;
                      setWorkoutData(updatedWorkoutData);
                    }}
                    setProfileDataFromClientsView={setProfileData}
                    profileDataFromClientsView={profileData}
                    meals={meals}
                    events={events}
                    activities={activities}
                    primaryKey={primaryKey}
                    moduleDate={currentDate}
                    userUUID={userUUID}
                    isThresholdAvailable={
                      userInfo?.thresholdValues ? true : false
                    }
                    userInfo={userInfo}
                    setSelectedDate={setSelectedDate}
                    setShowWorkOutBuilder={setShowWorkOutBuilder}
                    setSelectedActivity={setSelectedActivity}
                    currentDate={currentDate}
                    selectedCalendarType={selectedCalendarType}
                    currentEnduranceData={currentEnduranceData}
                    setProfileData={setProfileData}
                    status={status}
                    currentMonthEnduranceData={enduranceData}
                    isDragging={isDragging}
                    loadingDay={loadingDays[currentDate]}
                    reloadProfile={reloadProfile}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  }
);

export default CalendarTwoWeek;
