import { useEffect, useState } from "react";
import {
  Attachment,
  Channel,
  MessageInput,
  MessageList,
  Thread,
  Window,
  useChannelActionContext,
  useChannelStateContext,
  useChatContext,
  useMessageContext,
} from "stream-chat-react";
import { chatClient, chatInit } from "../../utils/chat";

import { Box, useMediaQuery } from "@mui/material";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import "stream-chat-react/dist/css/index.css";
import "../../App.css";
import { TeamMessageInput } from "../../pages/getStreamChat/components/TeamMessageInput/TeamMessageInput";
import { setTrainerData } from "../../redux/authSlice";
import { SpurfitCircularProgress } from "../Dashboard/dashboard";
import * as Sentry from "@sentry/react";

const ChatView = ({
  coachID,
  userUUID,
  userInfo,
  markAsUnread,
  isExpandedView,
}) => {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const isSmallerThanMd = useMediaQuery((theme) =>
    theme.breakpoints.down("md")
  );
  //   let coachID = useSelector((state) => state?.auth?.trainerUUID);
  //   let userUUID = "105067632829997666848";
  const [channel, setChannel] = useState(null);
  const [pinsOpen, setPinsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [chatInitCount, setChatInitCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const trainerUUID = useSelector((state) => state?.auth?.trainerUUID);
  const responseTimes = useSelector((state) => state.auth?.data?.responseTimes);

  const dispatch = useDispatch();
  const { setActiveChannel } = useChatContext();

  const maxChatInitCount = 3;

  const init = async () => {
    if (chatInitCount > maxChatInitCount) {
      setLoading(false);
      return;
    }
    try {
      if (!chatClient?.userID) {
        await chatInit(); //CONNECTING TO GETSTREAM IO
      }
      setChatInitCount((prev) => prev + 1);

      const channel = chatClient.channel("messaging", {
        members: [coachID, userUUID],
      });
      setChannel(channel);
      setActiveChannel(channel);
      console.log("channel id: ", channel);
      await channel.watch();
      setLoading(false);
    } catch (err) {
      if (err.code === 4) {
        // create user and try again
        console.log("creating user");
        await upsertChatUser();
        await chatInit(true);
        init();
      }
      console.log("error in getting channel id: " + chatInitCount + " ", err);
    }
  };

  const upsertChatUser = async () => {
    await axios({
      method: "post",
      url: `${APIURL}CF/api/upsertChatUser`,
      data: {
        id: userUUID,
        image: userInfo?.userImageKey,
        name: userInfo?.userName,
      },
    })
      .then((res) => {
        console.log("res: ", res);
      })
      .catch((err) => {
        console.log("error in upserting chat user: ", err);
      });
  };

  const gymAffiliation = useSelector(
    (state) => state.auth?.data?.gymAffliation
  );
  const isLivezy = gymAffiliation === "Livezy";

  useEffect(() => {
    try {
      markAsUnread(false);
    } catch (error) {
      Sentry.captureException(error);
    }
    return () => {};
  }, [gymAffiliation, loading]);

  useEffect(() => {
    if (coachID && userUUID) {
      setLoading(true);
      init();
    }

    return () => {
      // toggleBubbleVisibilityToShow();
    };
  }, [coachID, userUUID]);

  const updateAvgResponseTime = async (responseTimes) => {
    await axios({
      method: "post",
      url: `${APIURL}CF/api/CoachWeb/Update/UpdateResponseTimes`,
      data: {
        responseTimes: responseTimes,
        coachID: trainerUUID,
      },
    })
      .then((res) => {
        console.log("responseTimes updated successfully");
        dispatch(setTrainerData(res.data.result));
      })
      .catch((err) => {
        console.log("error while updating responseTimes", err);
      });
  };

  return (
    <>
      {!loading &&
      (channel !== null ||
        channel == undefined ||
        channel?.type === "messaging") ? (
        <>
          <Box
            sx={{
              height: isExpandedView
                ? "calc(100vh - 160px)"
                : "calc(100vh - 330px)",
              width: isSmallerThanMd ? "calc(100vw - 40px)" : "100%",
              "& .team-message-input__wrapper": {
                borderRadius: "0 !important",
              },
            }}
          >
            <Channel
              autoCompleteTriggerSettings={() => ({})}
              Attachment={(props) => {
                if (
                  props?.attachments?.[0]?.type === "voice-message" ||
                  props?.attachments?.[0]?.type === "audio"
                ) {
                  return (
                    <audio controls>
                      <source
                        src={props?.attachments?.[0]?.asset_url}
                        type="audio/mp4"
                      />
                      Your browser does not support the audio tag.
                    </audio>
                  );
                }

                return <Attachment {...props} />;
              }}
              channel={channel}
              Input={TeamMessageInput}
            >
              <Window>
                <MessageList
                  noGroupByUser={true}
                  {...(isLivezy ? { messageActions: (message) => [] } : {})}
                  formatDate={(date) => {
                    const now = new Date();
                    const inputDate = new Date(date);
                    const sameDay =
                      now.toDateString() === inputDate.toDateString();
                    const yesterday =
                      new Date(
                        now.setDate(now.getDate() - 1)
                      ).toDateString() === inputDate.toDateString();

                    if (sameDay) {
                      return (
                        "Today " +
                        inputDate.toLocaleTimeString("en-US", {
                          hour: "numeric",
                          minute: "numeric",
                        })
                      );
                    } else if (yesterday) {
                      return (
                        "Yesterday " +
                        inputDate.toLocaleTimeString("en-US", {
                          hour: "numeric",
                          minute: "numeric",
                        })
                      );
                    } else {
                      return inputDate.toLocaleDateString("en-US", {
                        month: "short",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                      });
                    }
                  }}
                />
                <CustomMessgeInput
                  channel={channel}
                  trainerUUID={trainerUUID}
                  responseTimes={responseTimes}
                  updateAvgResponseTime={updateAvgResponseTime}
                  isLivezy={isLivezy}
                />
              </Window>
              <Thread />
            </Channel>
          </Box>
        </>
      ) : (
        <div
          style={{
            height: "calc(100vh - 270px)",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SpurfitCircularProgress />
        </div>
      )}
    </>
  );
};

export default ChatView;

const CustomMessgeInput = ({
  channel,
  trainerUUID,
  responseTimes,
  updateAvgResponseTime,
  isLivezy,
}) => {
  const { sendMessage } = useChannelActionContext();
  const { messages, members } = useChannelStateContext();
  const { message: currentMessage } = useMessageContext();

  const overrideSubmitHandler = async (message) => {
    console.log("messages", messages, members);
    // search for the latest message not sent by current user
    const latestMessage = messages.reverse()?.[0];

    let timeDiffFromLastMessage = 0;
    if (latestMessage && latestMessage?.user?.id !== trainerUUID) {
      const todaysDate = new Date();
      const lastMessageDate = new Date(latestMessage?.created_at);
      const timeDiffInMs = todaysDate.getTime() - lastMessageDate.getTime();
      timeDiffFromLastMessage = (timeDiffInMs / (1000 * 60)).toFixed(2);

      let channelId = latestMessage?.cid;
      let newResponseTimes = { ...responseTimes } || {};

      if (!newResponseTimes[channelId]) {
        newResponseTimes[channelId] = {
          avgResponseTime: +timeDiffFromLastMessage,
          n: 1,
        };
      } else {
        let avgResponseTime = newResponseTimes[channelId]?.avgResponseTime || 0;
        let n = +newResponseTimes[channelId]?.n || 0;
        let newAvgResponseTime =
          (Number(avgResponseTime) * n + Number(timeDiffFromLastMessage)) /
          (n + 1);
        newResponseTimes[channelId] = {
          avgResponseTime: newAvgResponseTime,
          n: n + 1,
        };
        console.log("newResponseTimes", newResponseTimes);
      }

      console.log(
        "latestMessage",
        latestMessage,
        "timeDiffFromLastMessage",
        timeDiffFromLastMessage
      );
      updateAvgResponseTime(newResponseTimes);
    }
    if (sendMessage) {
      const sentMessage = await sendMessage(message);
    }
  };
  return <MessageInput overrideSubmitHandler={overrideSubmitHandler} grow />;
};
