import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const APIURL = "https://getfitaiserver.com/";
// import { store } from "../redux/store";

const getWorkoutsEndpoint = (coachID, APIURL) => {
  if (!coachID) return;
  const workoutsEndpoint = `${APIURL}CF/api/getWorkoutsbyCoach?coachID=${coachID}&ID=${coachID}`;
  console.log("getting workout endpoint: ", workoutsEndpoint);
  return workoutsEndpoint;
};

const trainersExerciseRoute = (coachID, APIURL) => {
  if (!coachID) return;
  return `${APIURL}CF/api/CoachWeb/Get/getAllMovements?coachID=${coachID}&ID=${coachID}`;
};

export const getFitApi = createApi({
  reducerPath: "getFitApi",
  baseQuery: fetchBaseQuery({
    baseUrl: APIURL,
    prepareHeaders: (headers, { getState }) => {
      // Add your JWT token to the headers
      const token = localStorage.getItem("accessToken");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getAllWorkouts: builder.query({
      query: ({ coachID, APIURL }) => getWorkoutsEndpoint(coachID, APIURL),
    }),
    getSpurfitExercises: builder.query({
      query: ({ APIURL, coachID }) =>
        `${APIURL}CF/api/CoachWeb/Get/getGlobalMovementLibrary?coachID=${coachID}&ID=${coachID}`,
      transformResponse: (response) => {
        return response.result;
      },
    }),
    getTrainersExercises: builder.query({
      query: ({ coachID, APIURL }) => trainersExerciseRoute(coachID, APIURL),
      transformResponse: (response) => {
        return response.result;
      },
    }),
    getTrainersExercisesByCoachID: builder.query({
      query: ({ coachID, APIURL }) => {
        if (coachID == null) {
          return Promise.reject("coachID is null or undefined");
        }
        return `${APIURL}CF/api/CoachWeb/Get/getAllMovements?coachID=${coachID}&ID=${coachID}`;
      },
      transformResponse: (response) => {
        return response.result;
      },
    }),
  }),
});

export const {
  useGetAllWorkoutsQuery,
  useGetSpurfitExercisesQuery,
  useGetTrainersExercisesQuery,
  useGetTrainersExercisesByCoachIDQuery,
  usePrefetch,
} = getFitApi;
