import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const ShowTable = ({ data, columns, loading, getRowId }) => {
  return (
    <DataGrid
      getRowId={getRowId}
      rows={data}
      columns={columns}
      loading={loading}
      pageSizeOptions={[10, 25, 50, 100]}
      disableRowSelectionOnClick
      slots={{ toolbar: GridToolbar }}
      sx={{
        marginTop: "10px",
        minHeight: "100px",
        backgroundColor: "#fff",
        border: "none",
        ".MuiDataGrid-columnSeparator": {
          display: "none",
        },
        "&.MuiDataGrid-root": {
          border: "none",
          backgroundColor: "#fff",
        },
        "&.MuiDataGrid-columnHeaderCheckbox": {
          border: "none",
        },
        "& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell": {
          backgroundColor: "#fff",
          fontWeight: 700,
        },
        "& .super-app-theme--cell": {
          outline: "none",
        },
        "& .super-app-theme--header": {
          outline: "none",
          fontSize: "13px",
          fontWeight: 500,
          lineHeight: "20px",
          color: "#333333",
          fontFamily: "DM Sans",
        },
      }}
    />
  );
};
export default ShowTable;
