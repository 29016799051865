import { RemoveCircleOutlineRounded } from "@mui/icons-material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import axios from "axios";
import { isNumber } from "lodash";
import moment from "moment";
import React, { forwardRef, useContext, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getAllCoachFoods } from "../../../ApiServices/Foods/Api";
import { store } from "../../../redux/store";
import CustomMenu from "../CustomMenu";
import { NutritionMetric } from "../NItemDetails/NItemDetails";
import { NutritionContext } from "../NutritionContext";
import AddCustomModal from "./AddCustomModal";
import AddFoodItemModal from "./AddFoodItemModal";
import AddOptionalFoodModal from "./AddOptionalFoodModal";
import EditAddedFood from "./EditAddedFoodModal";
import EditMealNameModal from "./EditMealNameModal";
import { convertUnits } from "./helpers";
import { SelectMeasurementUnit } from "./util";
import Fuse from "fuse.js";

const AddMealsModal = forwardRef(
  (
    {
      isOpen,
      setIsOpen,
      item,
      getMealPlans,
      isUpdate,
      onSuccess,
      isTemplate = false,
      userUUID,
      selectedDate,
      isCalendar,
    },
    ref
  ) => {
    const APIURL = useSelector((state) => state.auth.apiURL);
    const [meals, setMeals] = useState([]);
    const [selectCategory, setSelectCategory] = useState(false);
    const [selectedCategory, setSelectedCategory] =
      useState("Add meal Category");
    const [mealIndex, setMealIndex] = useState(0);
    const [showFoodItemModal, setShowFoodItemModal] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [foodItems, setFoodItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [allSelectedItems, setAllSelectedItems] = useState([]);
    const [totalMacros, setTotalMacros] = useState({
      calories: 0,
      protein: 0,
      carbs: 0,
      fat: 0,
      proteinPercentage: 0,
      carbsPercentage: 0,
      fatPercentage: 0,
    });
    const [saveLoading, setSaveLoading] = useState(false);
    const [isAddingCategory, setIsAddingCategory] = useState(false);
    const [typedCategory, setTypedCategory] = useState("");
    const [showEditFoodModal, setShowEditFoodModal] = useState(false);
    const [showEditModalFor, setShowEditModalFor] = useState(null);
    const [addingCustomFoodFor, setAddingCustomFoodFor] = useState(null);
    const [searchedFoods, setSearchedFoods] = useState([]);
    const [searchedRecipes, setSearchedRecipes] = useState([]);
    const [localSearchedFoods, setLocalSearchedFoods] = useState([]);
    const [localSearchedRecipes, setLocalSearchedRecipes] = useState([]);

    const [allLocalFoodItems, setAllLocalFoodItems] = useState([]);
    const [allLocalRecipeItems, setAllLocalRecipeItems] = useState([]);
    const [showAddCustomModal, setShowAddCustomModal] = useState(false);
    const [isEditAddedFoodOpenForOptional, setIsEditAddedFoodOpenForOptional] =
      useState(false);
    const [optionalEditIndex, setOptionalEditIndex] = useState(null);
    const [selectedOptionalIndex, setSelectedOptionalIndex] = useState(null);

    const [showAddOptionalFoodModal, setShowAddOptionalFoodModal] =
      useState(false);

    const [openMealNameModal, setOpenMealNameModal] = useState(false);
    const { setFolderSelected = () => {}, setOpenFolderId = () => {} } =
      useContext(NutritionContext) || {};

    const [options, setOptions] = useState(mealOptions);
    const [isShowCard, setIsShowCard] = useState(false);
    const gymAffiliation = useSelector(
      (state) => state.auth?.data?.gymAffliation
    );
    const isLivezy = gymAffiliation === "Livezy";
    const [showAddBtn, setShowAddBtn] = useState(true);
    let localFoodData;
    let localRecipeData;
    const getLocalSearchedFoodItems = async (searchTerm) => {
      if (searchTerm && allLocalFoodItems && allLocalFoodItems?.[0]) {
        const fuseOptions = {
          keys: ["name"], // Properties to search within
          threshold: 0.3, // Adjust the threshold for fuzzy matching
        };

        const fuse = new Fuse(allLocalFoodItems, fuseOptions);
        const searchResults = fuse.search(searchTerm);

        return searchResults.map((result) => result.item);
      } else {
        // set it with the original state
        return allLocalFoodItems;
      }
    };
    const getLocalSearchedRecipeItems = async (searchTerm) => {
      if (searchTerm && allLocalRecipeItems && allLocalRecipeItems?.[0]) {
        let searchedItemsCanBe = allLocalRecipeItems.filter((item) =>
          item.recipe_name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        return searchedItemsCanBe;
      } else {
        // set it with the original state
        return allLocalRecipeItems;
      }
    };
    const getLocalFoodItems = async () => {
      try {
        // const res = await axios({
        //   method: "post",
        //   url: `${APIURL}CF/api/getFoodByUserId`,
        //   data: {
        //     userId: store.getState().auth.trainerUUID,
        //   },
        // });
        const res = await getAllCoachFoods(
          store.getState().auth.trainerUUID,
          store.getState().auth.data.adminID
        );
        const result = res;
        console.log(
          "getting food items by trainer",
          res,
          store.getState().auth.data
        );
        setAllLocalFoodItems(result);
        return result;
      } catch (err) {
        console.log(err);
      }
    };
    const getLocalRecipes = async () => {
      try {
        const res = await axios({
          method: "post",
          url: `${APIURL}CF/api/getReceipeByUserId`,
          data: {
            userId: store.getState().auth.trainerUUID,
          },
        });

        const formattedRecipes = res?.data?.recipes?.map((r) => {
          let recipe = r?.serving;
          let directions = Object.entries(recipe?.directions || {}).map(
            ([key, value]) => {
              return {
                direction_number: key,
                direction_description: value,
              };
            }
          );

          let ingredients = Object.entries(recipe?.ingredients).map(
            ([key, value]) => {
              return {
                ingredient_description: value,
              };
            }
          );

          return {
            ...recipe,
            directions,
            ingredients,
            id: r?.id,
          };
        });

        setAllLocalRecipeItems(formattedRecipes);
        return formattedRecipes;
      } catch (err) {
        console.log("error getting trainer recipes: ", err);
        return null;
      }
    };
    const getFoodItems = async (searchTerm, pageNo, perPage) => {
      setIsLoading(true);

      try {
        let foods = [];
        let recipes = [];

        if (!isLivezy) {
          const [foodsRes, recipesRes] = await Promise.all([
            axios.post(`${APIURL}CF/api/getAllFoods`, {
              recordsPerPage: perPage || 10,
              page: pageNo || 1,
              searchQuery: searchTerm || "",
            }),
            axios.post(`${APIURL}CF/api/getAllReceipes`, {
              recordsPerPage: perPage || 10,
              page: pageNo || 1,
              searchQuery: searchTerm || "",
            }),
          ]);
          foods = foodsRes?.data?.foods || [];
          recipes = recipesRes?.data?.recipes || [];
        }

        if (allLocalFoodItems.length != 0 || allLocalRecipeItems.length != 0) {
          localFoodData = await getLocalSearchedFoodItems(searchTerm);
          localRecipeData = await getLocalSearchedRecipeItems(searchTerm);
        } else {
          localFoodData = await getLocalFoodItems();
          localRecipeData = await getLocalRecipes();
        }
        setLocalSearchedFoods(localFoodData);
        setLocalSearchedRecipes(localRecipeData);
        setSearchedFoods(foods);
        setSearchedRecipes(recipes);

        const sortedFoodItems = [...foods, ...recipes].sort((a, b) => {
          const nameA = (a.food_name || a.recipe_name).toUpperCase();
          const nameB = (b.food_name || b.recipe_name).toUpperCase();

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          return 0;
        });

        setFoodItems(sortedFoodItems);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        console.log(["err ===>>> ", err]);
      }
    };
    const getAllFoodAndRecipeIdsSeparately = async () => {
      const foodIds = [];
      const recipeIds = [];

      item?.plandetails?.meals?.forEach((meal) => {
        meal?.foods?.forEach((food) => {
          if (food?.food_id) {
            foodIds.push(food?.food_id);
          } else if (food?.recipe_id) {
            recipeIds.push(food?.recipe_id);
          }
        });
      });

      await Promise.all([
        axios({
          method: "get",
          url: `${APIURL}CF/api/getGlobalFoodsbyIDs`,
          params: {
            foodIds: foodIds,
          },
        }),
        axios({
          method: "get",
          url: `${APIURL}CF/api/getGlobalRecipebyIDs`,
          params: {
            recipeIds: recipeIds,
          },
        }),
      ])
        .then((res) => {
          const foods = res[0]?.data?.result || [];
          const recipes = res[1]?.data?.result || [];
          console.log("result: ", res);
          const allSelectedItems = [...foods, ...recipes];
          console.log("allSelectedItems", allSelectedItems);
          setAllSelectedItems(allSelectedItems);
        })
        .catch((err) => {
          console.log("err", err);
        });
    };

    const retriveMeals = () => {
      console.log("responseMeal", item);
      getAllFoodAndRecipeIdsSeparately();
      if (item?.plandetails?.meals) {
        // copy meals from item
        let copy = JSON.parse(JSON.stringify(item?.plandetails?.meals));
        setMeals(copy);
      } else {
        setMeals([]);
      }
    };

    const pushToUser = async (id) => {
      try {
        await axios({
          method: "post",
          url: `${APIURL}CF/api/meal/assignMeal`,
          data: {
            atheleteID: userUUID,
            mealId: id,
            dateAssigned: moment(selectedDate, "DD-MM-YYYY")
              ?.set({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0,
              })
              ?.format("YYYY-MM-DD"),
          },
        });
      } catch (error) {
        console.log("error", error);
      }
    };

    useEffect(() => {
      retriveMeals();
      getFoodItems();
    }, []);

    useEffect(() => {
      getTotalMacros();
    }, [meals]);

    const getFoodFromId = (id) => {
      return allSelectedItems?.find(
        (item) => item?.food_id === id || item?.recipe_id === id
      );
    };

    const getAllMeasurementAndServing = (food) => {
      let serving = food?.servings?.serving || food?.servings;
      let servings = {};
      let measurements = serving?.map((item) => {
        const measurement = item?.measurement_description;
        const serving = item?.serving_description;
        if (measurement && measurement.length <= 18) {
          // Remove text inside parentheses
          const truncatedMeasurement = measurement.replace(
            /\s*\([^)]*\)\s*/g,
            ""
          );
          servings[truncatedMeasurement] = item;
          return { measurement: truncatedMeasurement, serving };
        } else {
          return null;
        }
      });

      const filteredMeasurements = measurements?.filter(
        (item) => item !== null
      );
      const servingObj = filteredMeasurements?.reduce((acc, curr) => {
        acc[curr.measurement] = curr.serving;
        return acc;
      }, {});

      return [
        filteredMeasurements?.map((item) => item.measurement),
        servingObj,
        servings,
      ];
    };

    const createMeal = async (data) => {
      try {
        let res = await axios({
          method: "post",
          url: `${APIURL}CF/api/meal/create`,
          data: data,
        });
        if (isCalendar) {
          await pushToUser(res.data.meal.id);
          getMealPlans();
        } else {
          await getMealPlans(res.data.meal.id);
        }
      } catch (error) {
        saveUnsavedMealPlanToLocalStorage();
      } finally {
        setFolderSelected({});
        setOpenFolderId("");

        setIsOpen(false);
      }
    };

    const updateMeal = (data) => {
      // remove null values from data
      Object.keys(data).forEach((key) => data[key] == null && delete data[key]);
      axios({
        method: "post",
        url: `${APIURL}CF/api/meal/update?id=${item?.id}`,
        data: data,
      })
        .then((res) => {
          console.log("meal updated: ", res);
          getMealPlans(res.data.meal.id);
          onSuccess && onSuccess(res.data.meal);
        })
        .catch((err) => {
          console.log("meal update error: ", err);
          saveUnsavedMealPlanToLocalStorage();
        })
        .finally(() => {
          setIsOpen(false);
        });
    };

    const onSave = () => {
      // sort meals by name
      // meals.sort((a, b) => {
      //   const indexA = mealOptions.indexOf(a.name);
      //   const indexB = mealOptions.indexOf(b.name);
      //   return indexA - indexB;
      // });
      // calculate macros for each meal and also calculate total macros of all meals  and save
      setSaveLoading(true);
      let totalMacros = {
        protein: 0,
        carbs: 0,
        fat: 0,
      };
      let totalCalories = 0;

      let newMeals = meals.map((meal) => {
        let foods = meal?.foods || [];
        let calories = 0,
          protein = 0,
          carbs = 0,
          fat = 0;
        foods.forEach((food) => {
          calories += +food?.calories;
          protein += +food?.protein;
          carbs += +food?.carbs;
          fat += +food?.fat;
        });
        meal.macros = {
          protein,
          carbs,
          fat,
        };
        meal.calories = calories;
        totalCalories += +calories;
        totalMacros.protein += +protein;
        totalMacros.carbs += +carbs;
        totalMacros.fat += +fat;

        return meal;
      });
      let plandetails = {
        meals: newMeals,
        macros: totalMacros,
        calories: totalCalories,
      };

      let newItem = {
        ...item,
        coachId: store.getState().auth.trainerUUID,
        plandetails,
        isTemplate,
      };

      // remove null values from newItem
      Object.keys(newItem).forEach(
        (key) => newItem[key] == null && delete newItem[key]
      );

      console.log("newmeals: ", newMeals);
      // createMeal(newItem);
      if (item?.id && !item?.isNew) {
        updateMeal(newItem);
      } else {
        if (newItem?.isNew) {
          delete newItem["isNew"];
        }
        createMeal(newItem);
      }
      localStorage.removeItem("unsavedMealPlan");
    };

    const getTotalMacros = () => {
      let totalMacros = {
        calories: 0,
        protein: 0,
        carbs: 0,
        fat: 0,
        proteinPercentage: 0,
        carbsPercentage: 0,
        fatPercentage: 0,
      };
      console.log(meals);
      meals.forEach((meal) => {
        meal?.foods?.forEach((food) => {
          totalMacros.calories += +food?.calories;
          totalMacros.protein += +food?.protein;
          totalMacros.carbs += +food?.carbs;
          totalMacros.fat += +food?.fat;
        });
      });

      if (totalMacros.calories === 0) return setTotalMacros(totalMacros);

      totalMacros.proteinPercentage = Math.round(
        ((totalMacros.protein * 4) / totalMacros.calories) * 100
      );
      totalMacros.carbsPercentage = Math.round(
        ((totalMacros.carbs * 4) / totalMacros.calories) * 100
      );
      totalMacros.fatPercentage = Math.round(
        ((totalMacros.fat * 9) / totalMacros.calories) * 100
      );
      setTotalMacros(totalMacros);

      console.log("total macros: ", totalMacros);
    };

    const getMacrosOfAMeal = (meal) => {
      let macros = {
        calories: 0,
        protein: 0,
        carbs: 0,
        fat: 0,
      };
      meal?.foods?.forEach((food) => {
        macros.calories += +food?.calories;
        macros.protein += +food?.protein;
        macros.carbs += +food?.carbs;
        macros.fat += +food?.fat;
      });
      return macros;
    };

    const history = useHistory();
    useEffect(() => {
      // beforeunlod handler
      window.addEventListener(
        "beforeunload",
        saveUnsavedMealPlanToLocalStorage
      );

      const unblock = history.block((location, action) => {
        saveUnsavedMealPlanToLocalStorage();
      });
      return () => {
        window.removeEventListener(
          "beforeunload",
          saveUnsavedMealPlanToLocalStorage
        );
        unblock();
      };
    }, [meals, item, history]);

    const saveUnsavedMealPlanToLocalStorage = () => {
      // anything that is not saved to the database will be saved to local storage
      if (item?.plandetails?.meals) {
        // check item and meals
        if (JSON.stringify(item.plandetails.meals) !== JSON.stringify(meals)) {
          // save meals to local storage
          let data = {
            meals,
            item,
            id: item.id,
          };
          localStorage.setItem("unsavedMealPlan", JSON.stringify(data));
        }
      } else {
        let data = {
          meals,
          item,
        };
        localStorage.setItem("unsavedMealPlan", JSON.stringify(data));
      }
    };

    const retriveUnsavedMealPlanFromLocalStorage = () => {
      let data = JSON.parse(localStorage.getItem("unsavedMealPlan"));
      if (data) {
        setMeals(data.meals);
        if (data.item) {
          item = data.item;
        }
      }
    };

    React.useImperativeHandle(ref, () => ({
      retriveUnsavedMealPlanFromLocalStorage,
    }));

    const updateFoodQty = (value, mealIdx, foodIdx) => {
      let food = meals[mealIdx].foods[foodIdx];
      let previousQty = food.qty ?? 1;
      let ratio = value / previousQty;
      let updatedFood = {
        ...food,
        qty: value,
        calories: +food.calories * ratio,
        protein: +food.protein * ratio,
        carbs: +food.carbs * ratio,
        fat: +food.fat * ratio,
        size: +food.size * ratio,
      };
      let newMeals = [...meals];
      newMeals[mealIdx].foods[foodIdx] = updatedFood;
      setMeals(newMeals);
    };

    return (
      <Dialog
        onClick={(e) => {
          e.stopPropagation();
        }}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        fullScreen
      >
        <div
          style={{
            height: "77px",
            width: "100vw",
            paddingLeft: "30px",
            paddingRight: "32px",
            backgroundColor: "#f8f8f8",
          }}
        >
          <div
            className="add-program-modal-head"
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: "#FFFFFF",
              borderRadius: "0px 0px 16px 16px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              boxShadow: "0px 2px 5px -1px rgba(0,0,0,0.13)",
            }}
          >
            <div className="flex items-center">
              <CloseRoundedIcon
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  saveUnsavedMealPlanToLocalStorage();
                  setIsOpen(false);
                }}
              />
              <p
                style={{
                  marginLeft: "32px",
                }}
                className="add-program-modal-head-title"
              >
                {item?.reference_name} - Add Meals
              </p>
            </div>
            <button
              disabled={saveLoading}
              className="button-new"
              onClick={onSave}
            >
              {saveLoading ? (
                <CircularProgress
                  sx={{
                    color: "#fff",
                  }}
                  size={20}
                  thickness={4}
                />
              ) : isCalendar ? (
                "Save and Assign"
              ) : (
                "Save"
              )}
            </button>
          </div>
        </div>

        <DialogContent
          sx={{
            backgroundColor: "#f8f8f8",
          }}
        >
          <Stack direction={"row"} justifyContent={"space-between"}>
            <DragDropContext
              onDragEnd={(result) => {
                console.log("drag ended", result);
                if (!result.destination) return;
                let newMeals = [...meals];
                let [removed] = newMeals.splice(result.source.index, 1);
                newMeals.splice(result.destination.index, 0, removed);
                setMeals(newMeals);
              }}
            >
              <Droppable droppableId="droppable_meal">
                {(provided, snapshot) => (
                  <Stack
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    sx={{
                      flex: 1,
                      mr: 2,
                      overflowY: "scroll",
                      width: "90%",
                      padding: 1,
                      height: "calc(100vh - 100px)",
                    }}
                  >
                    {meals?.length !== 0 &&
                      meals?.map((meal, mealIdx) => {
                        let macros = getMacrosOfAMeal(meal);
                        let p = macros?.protein;
                        let c = macros?.carbs;
                        let f = macros?.fat;
                        let cals = macros?.calories;

                        return (
                          <Draggable
                            key={mealIdx}
                            draggableId={mealIdx.toString()}
                            index={mealIdx}
                          >
                            {(provided, snapshot) => (
                              <Paper
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  borderRadius: "12px",
                                  mb: "30px",
                                }}
                                elevation={3}
                              >
                                {/* <button
                                  onClick={() => {
                                    getMacrosOfAMeal(meal);
                                  }}
                                >
                                  Get macros
                                </button> */}
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    borderBottom: "1px solid #e5e5e5",
                                    width: "100%",
                                    padding: 2,
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: "18px",
                                      textTransform: "capitalize",
                                      fontFamily: "DM Sans",
                                    }}
                                  >
                                    {meal.name}
                                  </Typography>

                                  <Box className="flex items-center gap-2 ml-[10rem]">
                                    <RenderMetric label="P" value={p} />
                                    <RenderMetric label="F" value={f} />
                                    <RenderMetric label="C" value={c} />
                                    <RenderMetric label="Cals" value={cals} />
                                  </Box>
                                  <Box className="flex items-center gap-2">
                                    <CustomButton
                                      onClick={() => {
                                        // setShowEditFoodModal(true);
                                        setShowAddCustomModal(true);
                                        setAddingCustomFoodFor(mealIdx);
                                        setSelectedIndex(mealIdx);
                                      }}
                                      text={"Add Custom"}
                                    />
                                    {meal?.optional ||
                                    !meal?.foods.length ||
                                    meal?.foods.length == 0 ? null : (
                                      <CustomButton
                                        onClick={() => {
                                          setShowAddOptionalFoodModal(true);
                                          setSelectedIndex(mealIdx);
                                          setSelectedOptionalIndex(null);
                                        }}
                                        text={"Add optional"}
                                      />
                                    )}
                                    <CustomButton
                                      onClick={() => {
                                        setShowFoodItemModal(true);
                                        setSelectedIndex(mealIdx);
                                      }}
                                      text={"Add Food/Recipe"}
                                    />
                                    <CustomMenu
                                      options={["Edit Name", "Copy", "Remove"]}
                                      onOptionClick={(option) => {
                                        if (option === "Remove") {
                                          let newMeals = [...meals];
                                          newMeals.splice(mealIdx, 1);
                                          setMeals(newMeals);
                                        } else if (option === "Copy") {
                                          // *structured clone is used to create deep copy*
                                          let newMeals = structuredClone(meals);
                                          newMeals.push({
                                            ...meal,
                                            name: meal.name + " Copy",
                                          });
                                          setMeals(newMeals);
                                        } else if (option === "Edit Name") {
                                          setOpenMealNameModal(true);
                                          setSelectedIndex(mealIdx);
                                        }
                                      }}
                                    />
                                  </Box>
                                </Box>
                                <Box
                                  sx={{
                                    width: "100%",
                                    padding: 2,
                                  }}
                                >
                                  {meal?.foods?.map((food, index) => {
                                    let currentFood;
                                    let servingObj;
                                    let allServings;

                                    let selectOptions = [
                                      food?.baseUnit,
                                      food?.changeUnit,
                                    ];
                                    selectOptions = selectOptions.filter(
                                      (item) => item
                                    );

                                    let qty = !isNaN(+food?.measurement_count)
                                      ? +food?.measurement_count
                                      : 1;

                                    if (!food?.baseUnit) {
                                      currentFood = food?.food_id
                                        ? getFoodFromId(food?.food_id)
                                        : getFoodFromId(food?.recipe_id);

                                      let allMeasurementNServing =
                                        getAllMeasurementAndServing(
                                          currentFood
                                        );
                                      selectOptions =
                                        allMeasurementNServing?.[0];
                                      servingObj = allMeasurementNServing?.[1];
                                      allServings = allMeasurementNServing?.[2];
                                    }
                                    let shouldShowOptions = true;
                                    // don't show option if the unit doesn't exist in the selectOptions as we won't be able to convert it.
                                    if (
                                      !selectOptions?.includes(
                                        food?.measurement_description
                                      )
                                    ) {
                                      shouldShowOptions = false;
                                    }

                                    return (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                          mt: "9px",
                                          padding: 1,
                                          width: "100%",
                                        }}
                                        key={index}
                                      >
                                        <Box
                                          sx={{
                                            flex: 1,
                                          }}
                                        >
                                          <div className="flex items-center gap-2">
                                            <div>
                                              <Typography
                                                sx={{
                                                  fontFamily: "DM Sans",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                }}
                                              >
                                                {food?.name}{" "}
                                                {/* {!isLivezy && (
                                                  <EditRounded
                                                    fontSize="small"
                                                    className="ml-2 cursor-pointer text-gray-400"
                                                    onClick={() => {
                                                      setShowEditFoodModal(
                                                        true
                                                      );
                                                      let uniqeKey =
                                                        mealIdx + "-" + index;
                                                      setShowEditModalFor(
                                                        uniqeKey
                                                      );
                                                      setSelectedIndex(index);
                                                    }}
                                                  />
                                                )} */}
                                              </Typography>
                                              <Typography
                                                sx={{
                                                  fontFamily: "DM Sans",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "#727272",
                                                }}
                                              >
                                                {food?.size && !isNaN(food.size)
                                                  ? parseFloat(
                                                      food.size
                                                    )?.toFixed(1) +
                                                    " " +
                                                    food.unit
                                                  : ""}
                                              </Typography>
                                            </div>
                                          </div>
                                        </Box>
                                        <Stack direction="row">
                                          <Box
                                            sx={{
                                              display: "flex",
                                              flexDirection: "column",
                                              alignItems: "flex-end",
                                              justifyContent: "flex-end",
                                              mr: 1,
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                fontFamily: "DM Sans",
                                                fontWeight: 500,

                                                fontSize: "14px",
                                              }}
                                            >
                                              {setToOneFixedDecimal(
                                                food?.calories
                                              ) +
                                                " " +
                                                "cal"}
                                            </Typography>
                                            <Typography
                                              sx={{
                                                fontFamily: "DM Sans",
                                                fontWeight: 500,
                                                fontSize: "12px",
                                                color: "#727272",
                                              }}
                                            >
                                              P{" "}
                                              {setToOneFixedDecimal(
                                                food?.protein
                                              )}{" "}
                                              • C{" "}
                                              {setToOneFixedDecimal(
                                                food?.carbs
                                              )}{" "}
                                              • F{" "}
                                              {setToOneFixedDecimal(food?.fat)}
                                            </Typography>
                                          </Box>
                                          <Box
                                            sx={{
                                              border: "1px solid #D9D9D9",
                                              borderRadius: 2,
                                              px: 1,
                                              width: "150px",
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            {/* {OptionalFooInput(
                                            food,
                                            setMeals,
                                            currentFood,
                                            mealIdx,
                                            index,
                                            meals,
                                            allServings,
                                            servingObj
                                          )} */}
                                            <RenderInput
                                              key={index}
                                              food={food}
                                              setMeals={setMeals}
                                              currentFood={currentFood}
                                              mealIdx={mealIdx}
                                              index={index}
                                              meals={meals}
                                              allServings={allServings}
                                              servingObj={servingObj}
                                            />
                                            {food?.food_id &&
                                            typeof food?.food_id === "string" &&
                                            !food?.food_id?.includes(
                                              "custom"
                                            ) &&
                                            shouldShowOptions ? (
                                              <Select
                                                sx={{
                                                  height: "40px",
                                                  maxWidth: "95px",
                                                }}
                                                inputProps={{
                                                  style: {
                                                    backgroundColor: "#fff",
                                                  },
                                                }}
                                                variant="standard"
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                disableUnderline
                                                value={
                                                  food?.measurement_description
                                                }
                                                onChange={(e) => {
                                                  let value = e.target.value;

                                                  if (food?.baseUnit) {
                                                    let convertedQty =
                                                      convertUnits(
                                                        food,
                                                        qty,
                                                        value
                                                      );

                                                    // update measurement description and measurement count in food
                                                    let newMeals = [...meals];
                                                    newMeals[mealIdx].foods[
                                                      index
                                                    ].measurement_description =
                                                      value;
                                                    newMeals[mealIdx].foods[
                                                      index
                                                    ].measurement_count =
                                                      convertedQty;

                                                    setMeals(newMeals);
                                                  } else {
                                                    let currentMeal =
                                                      meals[mealIdx];
                                                    let foods =
                                                      currentMeal?.foods || [];
                                                    let currentServing =
                                                      allServings[
                                                        e.target.value
                                                      ];
                                                    foods[index] = {
                                                      ...foods[index],
                                                      calories:
                                                        currentServing?.calories,
                                                      protein:
                                                        currentServing?.protein,
                                                      carbs:
                                                        currentServing?.carbohydrate,
                                                      fat: currentServing?.fat,
                                                      size: currentServing?.metric_serving_amount,
                                                      unit: currentServing?.metric_serving_unit,
                                                      measurement_description:
                                                        e.target.value,
                                                      measurement_count:
                                                        removeSubstringAndParseInt(
                                                          servingObj[
                                                            e.target.value
                                                          ],
                                                          e.target.value
                                                        ),
                                                    };

                                                    setMeals([...meals]);
                                                  }
                                                }}
                                              >
                                                {selectOptions?.map((item) => (
                                                  <MenuItem
                                                    className="hover-effect"
                                                    sx={{
                                                      width: "100%",
                                                      fontFamily: "DM Sans",
                                                      fontSize: "14px",
                                                    }}
                                                    value={item}
                                                  >
                                                    {item}
                                                  </MenuItem>
                                                ))}
                                              </Select>
                                            ) : (
                                              <MenuItem
                                                sx={{
                                                  "&:hover": {
                                                    backgroundColor:
                                                      "transparent",
                                                  },
                                                }}
                                                value={
                                                  food?.measurement_description
                                                }
                                              >
                                                {food?.measurement_description ??
                                                  food?.unit ??
                                                  ""}
                                              </MenuItem>
                                            )}
                                          </Box>
                                          <RemoveCircleOutlineRounded
                                            color="error"
                                            sx={{
                                              height: "40px",
                                              width: "40px",
                                              padding: "10px",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              let newMeals = [...meals];
                                              newMeals[mealIdx].foods.splice(
                                                index,
                                                1
                                              );
                                              setMeals(newMeals);
                                            }}
                                          />
                                        </Stack>
                                      </Box>
                                    );
                                  })}
                                </Box>
                                {showAddCustomModal && (
                                  <AddCustomModal
                                    isOpen={showAddCustomModal}
                                    setIsOpen={setShowAddCustomModal}
                                    setItem={(updatedItem) => {
                                      let newMeals = [...meals];
                                      if (isEditAddedFoodOpenForOptional) {
                                        updatedItem.unit = "g";
                                        if (
                                          optionalEditIndex !== null &&
                                          optionalEditIndex !== undefined &&
                                          optionalEditIndex !== ""
                                        ) {
                                          newMeals[
                                            addingCustomFoodFor
                                          ].optionals[selectedOptionalIndex][
                                            optionalEditIndex
                                          ] = updatedItem;
                                        } else {
                                          newMeals[
                                            addingCustomFoodFor
                                          ].optionals[
                                            selectedOptionalIndex
                                          ].push(updatedItem);
                                          setOptionalEditIndex(null);
                                        }
                                        setIsEditAddedFoodOpenForOptional(
                                          false
                                        );
                                        setAddingCustomFoodFor(null);
                                        setOptionalEditIndex(null);
                                        setSelectedOptionalIndex(null);
                                      } else {
                                        updatedItem.unit = "g";
                                        newMeals[
                                          addingCustomFoodFor
                                        ].foods.push(updatedItem);
                                      }
                                      console.log("new meals", newMeals);
                                      setMeals(newMeals);
                                    }}
                                  />
                                )}
                                {showEditFoodModal &&
                                  (showEditModalFor ===
                                    mealIdx + "-" + selectedIndex ||
                                    addingCustomFoodFor === mealIdx) && (
                                    <EditAddedFood
                                      id={item?.id}
                                      isOpen={showEditFoodModal}
                                      setIsOpen={setShowEditFoodModal}
                                      isAdding={addingCustomFoodFor === mealIdx}
                                      onClose={() => {
                                        setShowEditFoodModal(false);
                                        setShowEditModalFor(null);
                                        setAddingCustomFoodFor(null);
                                        setOptionalEditIndex(null);
                                      }}
                                      item={
                                        optionalEditIndex != null
                                          ? meals[mealIdx]?.optionals[
                                              selectedOptionalIndex
                                            ][optionalEditIndex]
                                          : addingCustomFoodFor === mealIdx
                                          ? {}
                                          : meals[mealIdx].foods[selectedIndex]
                                      }
                                      setItem={(updatedItem) => {
                                        let newMeals = [...meals];
                                        if (
                                          isEditAddedFoodOpenForOptional &&
                                          addingCustomFoodFor === mealIdx
                                        ) {
                                          updatedItem.unit = "g";
                                          if (
                                            optionalEditIndex !== null &&
                                            optionalEditIndex !== undefined &&
                                            optionalEditIndex !== ""
                                          ) {
                                            newMeals[mealIdx].optionals[
                                              selectedOptionalIndex
                                            ][optionalEditIndex] = updatedItem;
                                          } else {
                                            newMeals[mealIdx].optionals.push(
                                              updatedItem
                                            );
                                            setOptionalEditIndex(null);
                                          }
                                          setMeals(newMeals);
                                          setIsEditAddedFoodOpenForOptional(
                                            false
                                          );
                                          setAddingCustomFoodFor(null);
                                          setSelectedOptionalIndex(null);
                                          return;
                                        } else {
                                          if (addingCustomFoodFor === mealIdx) {
                                            updatedItem.unit = "g";
                                            newMeals[mealIdx].foods.push(
                                              updatedItem
                                            );
                                          } else {
                                            newMeals[mealIdx].foods[
                                              selectedIndex
                                            ] = updatedItem;
                                          }
                                        }
                                        setMeals(newMeals);
                                        setAddingCustomFoodFor(null);
                                      }}
                                    />
                                  )}
                                {meal?.optional && (
                                  <RenderOptionalMeal
                                    editDisabled={true}
                                    foods={meal?.optional}
                                    isLivezy={isLivezy}
                                    setMeals={(updatedOptionalMeal) => {
                                      let newMeals = [...meals];
                                      newMeals[mealIdx].optional =
                                        updatedOptionalMeal;
                                      setMeals(newMeals);
                                    }}
                                    mealIdx={mealIdx}
                                    getFoodFromId={getFoodFromId}
                                    getAllMeasurementAndServing={
                                      getAllMeasurementAndServing
                                    }
                                    setToOneFixedDecimal={setToOneFixedDecimal}
                                    removeSubstringAndParseInt={
                                      removeSubstringAndParseInt
                                    }
                                    onAddMoreClick={() => {
                                      setShowAddOptionalFoodModal(true);
                                      setSelectedIndex(mealIdx);
                                    }}
                                    onRemoveOptional={() => {
                                      let newMeals = [...meals];
                                      newMeals[mealIdx].optional = undefined;
                                      setMeals(newMeals);
                                    }}
                                    onAddCustomClick={() => {
                                      setIsEditAddedFoodOpenForOptional(true);
                                      setShowAddCustomModal(true);
                                      setAddingCustomFoodFor(mealIdx);
                                      setSelectedIndex(mealIdx);
                                    }}
                                    onEditFoodClick={(foodIndex) => {
                                      setIsEditAddedFoodOpenForOptional(true);
                                      setOptionalEditIndex(foodIndex);
                                      setShowEditFoodModal(true);
                                      setAddingCustomFoodFor(mealIdx);
                                      setSelectedIndex(mealIdx);
                                    }}
                                    key={mealIdx}
                                  />
                                )}
                                {meal?.optionals?.map((optional, index) => {
                                  return (
                                    <RenderOptionalMeal
                                      optionalIndex={index}
                                      foods={optional}
                                      isLivezy={isLivezy}
                                      setMeals={(updatedOptionalMeal) => {
                                        let newMeals = [...meals];
                                        newMeals[mealIdx].optionals[index] =
                                          updatedOptionalMeal;
                                        setMeals(newMeals);
                                      }}
                                      mealIdx={mealIdx}
                                      getFoodFromId={getFoodFromId}
                                      getAllMeasurementAndServing={
                                        getAllMeasurementAndServing
                                      }
                                      setToOneFixedDecimal={
                                        setToOneFixedDecimal
                                      }
                                      removeSubstringAndParseInt={
                                        removeSubstringAndParseInt
                                      }
                                      onAddMoreClick={() => {
                                        setShowAddOptionalFoodModal(true);
                                        setSelectedOptionalIndex(index);
                                        setSelectedIndex(mealIdx);
                                      }}
                                      onRemoveOptional={() => {
                                        let newMeals = [...meals];
                                        // splice optional from meal
                                        newMeals[mealIdx].optionals.splice(
                                          index,
                                          1
                                        );
                                        setMeals(newMeals);
                                      }}
                                      onAddCustomClick={() => {
                                        setIsEditAddedFoodOpenForOptional(true);
                                        setShowAddCustomModal(true);
                                        setAddingCustomFoodFor(mealIdx);
                                        setSelectedIndex(mealIdx);
                                        setSelectedOptionalIndex(index);
                                      }}
                                      onEditFoodClick={(foodIndex) => {
                                        setIsEditAddedFoodOpenForOptional(true);
                                        setOptionalEditIndex(foodIndex);
                                        setShowEditFoodModal(true);
                                        setAddingCustomFoodFor(mealIdx);
                                        setSelectedIndex(mealIdx);
                                        setSelectedOptionalIndex(index);
                                      }}
                                      key={mealIdx}
                                    />
                                  );
                                })}
                              </Paper>
                            )}
                          </Draggable>
                        );
                      })}
                    {provided.placeholder}

                    <>
                      {!showAddBtn && (
                        <Select
                          style={{
                            background:
                              "linear-gradient(to right, #464FEB, #8330E9)",
                            display: "flex",
                            alignItems: "center",
                            borderRadius: "100px",
                            marginTop: "20px",
                            marginRight: "18px",
                            marginLeft: "18px",
                            height: "44px",
                            width: "250px",
                            color: "#fff",
                            justifyContent: "center",
                            fontFamily: "DM Sans",
                            fontWeight: 500,
                            fontSize: "16px",
                            alignSelf: "center",
                            border: "none",
                          }}
                          open={!showAddBtn}
                          labelId="meal-category-label"
                          id="meal-category"
                          placeholder="Add meal Category"
                          value={selectedCategory}
                          onClose={() => setShowAddBtn(true)}
                          onChange={(e) => {
                            setShowAddBtn(true);
                            setSelectedCategory(e.target.value);
                            setMeals([
                              ...meals,
                              {
                                name: e.target.value,
                                foods: [],
                              },
                            ]);
                            setSelectCategory(false);
                            setMealIndex(mealIndex + 1);
                          }}
                        >
                          <MenuItem value="Add meal Category" disabled>
                            Add meal Category
                          </MenuItem>
                          {options?.map((item) => (
                            <MenuItem
                              disabled={meals.some(
                                (meal) => meal.name === item
                              )}
                              sx={{
                                width: "100%",
                                fontSize: "14px",
                                backgroundColor: "white",
                                fontFamily: "DM Sans",
                              }}
                              value={item}
                            >
                              {item}
                            </MenuItem>
                          ))}

                          <>
                            <input
                              className="p-2 outline-none border border-gray-300 "
                              type="text"
                              placeholder="New Category"
                              onChange={(e) => {
                                setTypedCategory(e.target.value);
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                              }}
                              value={typedCategory}
                              onKeyDown={(e) => {
                                e.stopPropagation();
                                if (e.key === "Enter") {
                                  if (typedCategory === "") return;
                                  setOptions([...options, typedCategory]);
                                  setSelectedCategory(typedCategory);
                                  setMeals([
                                    ...meals,
                                    {
                                      name: typedCategory,
                                      foods: [],
                                    },
                                  ]);
                                  setSelectCategory(false);
                                  setMealIndex(mealIndex + 1);
                                  setIsAddingCategory(false);
                                  setShowAddBtn(true);
                                  setTypedCategory("");
                                }
                              }}
                            />
                          </>
                        </Select>
                      )}
                      {showAddBtn && (
                        <Button
                          variant="text"
                          style={{
                            background:
                              "linear-gradient(to right, #464FEB, #8330E9)",
                            display: "flex",
                            alignItems: "center",
                            borderRadius: "100px",
                            marginRight: "18px",
                            marginLeft: "18px",
                            height: "44px",
                            width: "250px",
                            color: "#fff",
                            justifyContent: "center",
                            fontFamily: "DM Sans",
                            fontWeight: 500,
                            fontSize: "16px",
                            alignSelf: "center",
                          }}
                          onClick={() => {
                            setShowAddBtn(!showAddBtn);
                          }}
                        >
                          <AddRoundedIcon />
                          Add meal Category
                        </Button>
                      )}
                    </>
                  </Stack>
                )}
              </Droppable>
            </DragDropContext>
            <Stack>
              <Paper
                sx={{
                  padding: "25px 20px",
                  borderRadius: "12px",
                }}
                elevation={3}
              >
                <Typography
                  sx={{
                    textTransform: "Uppercase",
                    color: "rgb(70, 79, 235)",
                    fontFamily: "DM Sans",
                    fontSize: "12px",
                    fontWeight: 600,
                  }}
                >
                  Nutrition Facts
                </Typography>
                <Typography
                  sx={{
                    fontSize: "30px",
                    fontWeight: 700,
                    fontFamily: "DM Sans",
                  }}
                >
                  {setToOneFixedDecimal(totalMacros?.calories)}{" "}
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: 500,
                      color: "#7C7C7C",
                    }}
                  >
                    Cals
                  </span>
                </Typography>
                <Stack direction={"row"} spacing={1} mt={2}>
                  <NutritionMetric
                    label="Protein"
                    value={totalMacros?.protein}
                    percentage={totalMacros?.proteinPercentage}
                  />
                  <NutritionMetric
                    label="Fat"
                    value={totalMacros?.fat}
                    percentage={totalMacros?.fatPercentage}
                  />
                  <NutritionMetric
                    label="Carbs"
                    value={totalMacros?.carbs}
                    percentage={totalMacros?.carbsPercentage}
                  />
                </Stack>
              </Paper>
            </Stack>
          </Stack>
        </DialogContent>

        {showFoodItemModal && (
          <AddFoodItemModal
            isOpen={showFoodItemModal}
            setIsOpen={setShowFoodItemModal}
            meals={meals}
            setMeals={setMeals}
            selectedIndex={selectedIndex}
            foodItems={foodItems}
            getFoodItems={getFoodItems}
            isLoading={isLoading}
            allSelectedItems={allSelectedItems}
            setAllSelectedItems={setAllSelectedItems}
            searchedFoods={searchedFoods}
            searchedRecipes={searchedRecipes}
            localSearchedFoods={localSearchedFoods}
            localSearchedRecipes={localSearchedRecipes}
          />
        )}
        {showAddOptionalFoodModal && (
          <AddOptionalFoodModal
            isOpen={showAddOptionalFoodModal}
            setIsOpen={setShowAddOptionalFoodModal}
            optionalIndex={selectedOptionalIndex}
            selectedIndex={selectedIndex}
            foodItems={foodItems}
            getFoodItems={getFoodItems}
            meals={meals}
            setMeals={setMeals}
            isLoading={isLoading}
            allSelectedItems={allSelectedItems}
            setAllSelectedItems={setAllSelectedItems}
            searchedFoods={searchedFoods}
            searchedRecipes={searchedRecipes}
            localSearchedFoods={localSearchedFoods}
            localSearchedRecipes={localSearchedRecipes}
          />
        )}
        {openMealNameModal && (
          <EditMealNameModal
            isOpen={openMealNameModal}
            setIsOpen={setOpenMealNameModal}
            item={meals[selectedIndex]}
            setItem={(updatedItem) => {
              let newMeals = [...meals];
              newMeals[selectedIndex] = updatedItem;
              setMeals(newMeals);
            }}
          />
        )}
      </Dialog>
    );
  }
);

export default AddMealsModal;

function RenderInput({
  food,
  setMeals,
  currentFood,
  mealIdx,
  index,
  meals,
  allServings,
  servingObj,
}) {
  const [inputValue, setInputValue] = useState(food?.measurement_count);
  useEffect(() => {
    setInputValue(food?.measurement_count);
  }, [food?.measurement_count]);

  return (
    <input
      className="truncate"
      style={{
        height: "40px",
        outline: "none",
        width: "50px",
        fontFamily: "DM Sans",
        background: "transparent",
      }}
      min="1"
      type="number"
      value={inputValue}
      onChange={(e) => {
        let value = e.target.value;
        if (value === 0) return;
        setInputValue(value);
        if (!value || value === "" || value <= 0 || isNaN(parseInt(value)))
          return;
        console.log("foods", food, "value", value);
        if (food?.recipe_id) {
          setMeals((prev) => {
            let measurement_count =
              currentFood?.serving_sizes?.serving_size?.split(" ")[0] ??
              food?.measurement_count;
            let protein = currentFood?.serving_sizes?.protein ?? food?.protein;
            let carbs = currentFood?.serving_sizes?.carbohydrate ?? food?.carbs;
            let fat = currentFood?.serving_sizes?.fat ?? food?.fat;
            let cals = currentFood?.serving_sizes?.calories ?? food?.calories;
            let size = currentFood?.grams_per_portion ?? food?.size;
            let recipeRatio = +value / +measurement_count;
            prev[mealIdx].foods[index] = {
              ...prev[mealIdx].foods[index],
              protein: recipeRatio * +protein || 0,
              carbs: recipeRatio * +carbs || 0,
              fat: recipeRatio * +fat || 0,
              calories: recipeRatio * +cals || 0,
              size: recipeRatio * +size || 0,
              measurement_count: value,
            };
            return [...prev];
          });
          return;
        }

        if (!value || (isNaN(value) && parseInt(value) <= 0)) {
          setMeals((prev) => {
            prev[mealIdx].foods[index] = {
              ...prev[mealIdx].foods[index],
              measurement_count: "",
            };
            return [...prev];
          });
          return;
        }
        let currentMeal = meals[mealIdx];
        let foods = currentMeal?.foods || [];
        let currentServing = allServings?.[food?.measurement_description];
        let modifiedMeasurementDescription =
          typeof currentServing?.measurement_description === "string" &&
          currentServing?.measurement_description?.includes("serving")
            ? "serving"
            : currentServing?.measurement_description;
        let currentMeasurementCount;
        if (servingObj?.[modifiedMeasurementDescription]) {
          currentMeasurementCount = removeSubstringAndParseInt(
            servingObj[modifiedMeasurementDescription],
            currentServing?.measurement_description
          );
        } else {
          currentMeasurementCount = parseFloat(food?.measurement_count ?? 0);
        }
        if (!currentMeasurementCount) return;
        let ratio = e.target.value / currentMeasurementCount;
        let foodSize;
        if (food?.size) {
          foodSize = parseFloat(food?.size ?? 0);
        }
        foods[index] = {
          ...foods[index],
          protein: +(currentServing?.protein ?? food?.protein) * ratio,
          carbs: +(currentServing?.carbohydrate ?? food?.carbs) * ratio,
          fat: +(currentServing?.fat ?? food?.fat) * ratio,
          calories: +(currentServing?.calories ?? food?.calories) * ratio,
          measurement_count: e.target.value,
          size: +(currentServing?.metric_serving_amount ?? foodSize) * ratio,
        };
        currentMeal.foods = foods;
        // recalculating macros
        let macros = {
          protein: 0,
          carbs: 0,
          fat: 0,
        };
        let calories = 0;
        foods.forEach((food) => {
          macros.protein += +food?.protein;
          macros.carbs += +food?.carbs;
          macros.fat += +food?.fat;
          calories += +food?.calories;
        });
        currentMeal.macros = macros;
        currentMeal.calories = calories;
        setMeals([...meals]);
      }}
    />
  );
}

function removeSubstringAndParseInt(str, substr) {
  const newStr = str;
  if (str && substr && typeof str === "string") {
    str.replace(substr, "");
  }
  const num = parseInt(newStr);
  return isNaN(num) ? null : num;
}

export const setToOneFixedDecimal = (num) => {
  if (num === undefined || num === null || num === "") {
    return 0;
  }

  const parsedNum = parseFloat(num);

  if (isNaN(parsedNum)) {
    return 0;
  }

  const fixedNum = parsedNum.toFixed(1);
  return fixedNum.endsWith(".0") ? parseInt(fixedNum) : fixedNum;
};

const CustomButton = ({ text, onClick, style }) => {
  return (
    <button
      onClick={onClick}
      className="hover-effect"
      style={{
        fontFamily: "DM Sans",
        padding: "5px 10px",
        borderRadius: 12,
        fontSize: 14,
        fontWeight: 600,
        ...style,
      }}
    >
      {text}
    </button>
  );
};

const mealOptions = [
  "On Waking Up",
  "Early Morning",
  "Breakfast",
  "After Breakfast",
  "Mid-morning",
  "Morning Snack",
  "Lunch",
  "Evening Snack",
  "Dinner",
  "After Dinner",
  "Bed Time",
  "Before Workout",
  "During Workout",
  "Between Workouts",
  "After Workout",
  "Non-Training Day",
];

const RenderOptionalMeal = ({
  optionalIndex,
  foods,
  setMeals,
  mealIdx,
  getFoodFromId,
  getAllMeasurementAndServing,
  setToOneFixedDecimal,
  removeSubstringAndParseInt,
  onAddMoreClick,
  onRemoveOptional,
  onAddCustomClick,
  onEditFoodClick,
  isLivezy,
  editDisabled,
}) => {
  let totalProtein = 0,
    totalCarbs = 0,
    totalFat = 0,
    totalCalories = 0;

  foods.forEach((food) => {
    totalProtein += +food?.protein;
    totalCarbs += +food?.carbs;
    totalFat += +food?.fat;
    totalCalories += +food?.calories;
  });

  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
      }}
    >
      <Divider sx={{ my: 3, backgroundColor: "black" }} flexItem />
      <Typography
        sx={{
          position: "absolute",
          top: "13px",
          left: "50%",
          transform: "translateX(-50%)",
          backgroundColor: "#fff",
          px: 1,
          fontFamily: "DM Sans",
          fontWeight: 500,
          fontSize: "14px",
          color: "black",
        }}
      >
        OR
      </Typography>
      <Box
        className="flex items-center justify-between w-full "
        sx={{ borderBottom: "1px solid #e5e5e5" }}
      >
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "18px",
            textTransform: "capitalize",
            fontFamily: "DM Sans",
            padding: 2,
          }}
        >
          Optional {isNumber(optionalIndex) ? optionalIndex + 1 : ""}
        </Typography>

        <Box className="flex items-center gap-2 mr-[2rem]">
          <RenderMetric label="P" value={setToOneFixedDecimal(totalProtein)} />
          <RenderMetric label="F" value={setToOneFixedDecimal(totalFat)} />
          <RenderMetric label="C" value={setToOneFixedDecimal(totalCarbs)} />
          <RenderMetric
            label="Cals"
            value={setToOneFixedDecimal(totalCalories)}
          />
        </Box>
        <Box className="flex items-center  ">
          {!editDisabled && (
            <>
              <CustomButton onClick={onAddCustomClick} text={"Add Custom"} />
              <CustomButton onClick={onAddMoreClick} text={"Add More"} />
            </>
          )}
          <CustomMenu
            options={["Remove"]}
            onOptionClick={(option) => {
              if (option === "Remove") {
                onRemoveOptional();
              }
            }}
          />
        </Box>
      </Box>

      {foods?.map((food, index) => {
        let currentFood;
        let servingObj;
        let allServings;

        let selectOptions = [food?.baseUnit, food?.changeUnit];

        let qty = !isNaN(+food?.measurement_count)
          ? +food?.measurement_count
          : 1;

        if (!food?.baseUnit) {
          currentFood = food?.food_id
            ? getFoodFromId(food?.food_id)
            : getFoodFromId(food?.recipe_id);

          let allMeasurementNServing = getAllMeasurementAndServing(currentFood);
          selectOptions = allMeasurementNServing?.[0];
          servingObj = allMeasurementNServing?.[1];
          allServings = allMeasurementNServing?.[2];

          if (!selectOptions || selectOptions.length == 0) {
            selectOptions = [food?.measurement_description];
          }
        }

        return (
          <Box
            sx={{
              display: "flex",
              // justifyContent: "space-between",
              //  backgroundColor:"black",
              alignItems: "center",
              mt: 1,
              width: "100%",
              paddingLeft: 2,
              paddingRight: 2,
              paddingBottom: 1,
              paddingTop: 1,
            }}
            key={index}
          >
            <Box
              sx={{
                flex: 1,
              }}
            >
              <div className="flex items-center gap-2">
                <div>
                  <Typography
                    sx={{
                      fontFamily: "DM Sans",
                      fontWeight: 500,
                      fontSize: "14px",
                    }}
                  >
                    {food?.name}{" "}
                    {/* {!isLivezy && !editDisabled && (
                      <EditRounded
                        fontSize="small"
                        className="ml-2 cursor-pointer text-gray-400"
                        onClick={() => {
                          onEditFoodClick(index);
                        }}
                      />
                    )} */}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "DM Sans",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "#727272",
                    }}
                  >
                    {food?.size
                      ? parseFloat(food.size)?.toFixed(1) + " " + food.unit
                      : ""}
                  </Typography>
                </div>
              </div>
            </Box>
            <Stack direction="row">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                  mr: 1,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "DM Sans",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  {setToOneFixedDecimal(food?.calories) + " " + "cal"}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "DM Sans",
                    fontWeight: 500,
                    fontSize: "12px",
                    color: "#727272",
                  }}
                >
                  P {setToOneFixedDecimal(food?.protein)} • C{" "}
                  {setToOneFixedDecimal(food?.carbs)} • F{" "}
                  {setToOneFixedDecimal(food?.fat)}
                </Typography>
              </Box>
              <Box
                sx={{
                  border: "1px solid #D9D9D9",
                  borderRadius: 2,
                  px: 1,
                  width: "150px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {/* {OptionalFooInput(food, foods, currentFood, index, setMeals, allServings, removeSubstringAndParseInt, servingObj)} */}
                <OptionalFooInput
                  food={food}
                  foods={foods}
                  currentFood={currentFood}
                  index={index}
                  setMeals={setMeals}
                  allServings={allServings}
                  removeSubstringAndParseInt={removeSubstringAndParseInt}
                  servingObj={servingObj}
                />

                {(food?.food_id && food?.food_id?.includes("custom")) ||
                (food?.recipe_id && food?.recipe_id?.includes("custom")) ? (
                  <SelectMeasurementUnit
                    value={food?.measurement_description}
                    meal={{ foods }}
                    foodIndex={index}
                    setMeal={(updatedMeal) => {
                      let foodsCopy = [...foods];
                      foodsCopy[index] = updatedMeal.foods[index];
                      setMeals(foodsCopy);
                    }}
                  />
                ) : food?.food_id && !food?.food_id?.includes("custom") ? (
                  <Select
                    sx={{
                      height: "40px",
                      maxWidth: "95px",
                    }}
                    inputProps={{
                      style: {
                        backgroundColor: "#fff",
                      },
                    }}
                    variant="standard"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    disableUnderline
                    value={food?.measurement_description}
                    onChange={(e) => {
                      let foodsCopy = [...foods] || [];

                      if (food?.baseUnit) {
                        let convertedQty = convertUnits(
                          food,
                          qty,
                          e.target.value
                        );

                        foodsCopy[index] = {
                          ...foodsCopy[index],
                          measurement_description: e.target.value,
                          measurement_count: convertedQty,
                        };
                        console.log({
                          food,
                          foodsCopy,
                          convertedQty,
                          qty,
                          eTargetValue: e.target.value,
                        });

                        setMeals(foodsCopy);
                      } else {
                        let currentServing = allServings[e.target.value];
                        foodsCopy[index] = {
                          ...foodsCopy[index],
                          calories: currentServing?.calories,
                          protein: currentServing?.protein,
                          carbs: currentServing?.carbohydrate,
                          fat: currentServing?.fat,
                          size: currentServing?.metric_serving_amount,
                          unit: currentServing?.metric_serving_unit,
                          measurement_description: e.target.value,
                          measurement_count: removeSubstringAndParseInt(
                            servingObj[e.target.value],
                            e.target.value
                          ),
                        };
                        setMeals(foodsCopy);
                      }
                    }}
                  >
                    {selectOptions?.map((item) => (
                      <MenuItem
                        className="hover-effect"
                        sx={{
                          width: "100%",
                          fontFamily: "DM Sans",
                          fontSize: "14px",
                        }}
                        value={item}
                      >
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                ) : (
                  <MenuItem
                    sx={{
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                    value={food?.measurement_description}
                  >
                    {food?.measurement_description ?? food?.unit ?? ""}
                  </MenuItem>
                )}
              </Box>
              <RemoveCircleOutlineRounded
                color="error"
                sx={{
                  height: "40px",
                  width: "40px",
                  padding: "10px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  let newFood = [...foods];
                  newFood.splice(index, 1);
                  setMeals(newFood);
                }}
              />
            </Stack>
          </Box>
        );
      })}
    </Box>
  );
};

const RenderMetric = ({ label, value }) => {
  const [myLabel, setMyLabel] = useState(label);
  const [myValue, setMyValue] = useState(value);

  useEffect(() => {
    console.log("useEffect", "label", label, "value", value);
    setMyLabel(label);
    setMyValue(value);
  }, [label, value]);
  return (
    <p className="font-DMSans text-font14 font-semibold">
      {myLabel}: &nbsp;
      {myLabel != "Cals" && (
        <span className="font-DMSans text-font14 font-normal">
          {setToOneFixedDecimal(myValue)} g
        </span>
      )}
      {myLabel == "Cals" && (
        <span className="font-DMSans text-font14 font-normal">
          {setToOneFixedDecimal(myValue)}
        </span>
      )}
    </p>
  );
};
function OptionalFooInput({
  food,
  foods,
  currentFood,
  index,
  setMeals,
  allServings,
  removeSubstringAndParseInt,
  servingObj,
}) {
  const [inputValue, setInputValue] = useState(food?.measurement_count);
  useEffect(() => {
    if (inputValue !== food?.measurement_count) {
      setInputValue(food?.measurement_count);
    }
  }, [food?.measurement_count]);

  return (
    <input
      style={{
        height: "40px",
        outline: "none",
        width: "50px",
        fontFamily: "DM Sans",
      }}
      min="1"
      type="number"
      value={inputValue}
      onChange={(e) => {
        let value = e.target.value;
        if (value === 0) return;
        setInputValue(value);
        if (!value || value === "" || value <= 0 || isNaN(parseInt(value)))
          return;
        if (!value || value === "" || isNaN(parseInt(value))) return;
        console.log("foods", food, "value", value);
        if (food?.recipe_id) {
          let prev = [...foods];
          let recipeRatio =
            +value / +currentFood?.serving_sizes?.serving_size?.split(" ")[0];

          prev[index] = {
            ...prev[index],
            protein: recipeRatio * +currentFood?.serving_sizes?.protein || 0,
            carbs: recipeRatio * +currentFood?.serving_sizes?.carbohydrate || 0,
            fat: recipeRatio * +currentFood?.serving_sizes?.fat || 0,
            calories: recipeRatio * +currentFood?.serving_sizes?.calories || 0,
            size: recipeRatio * +currentFood?.grams_per_portion || 0,
            measurement_count: +value,
          };

          setMeals(prev);
          return;
        }

        if (!value || (isNaN(value) && parseInt(value) <= 0)) {
          let prev = [...foods];
          prev[index] = {
            ...prev[index],
            measurement_count: "",
          };
          setMeals(prev);
          return;
        }

        let foodsCopy = [...foods];
        let currentServing = allServings?.[food?.measurement_description];
        let modifiedMeasurementDescription =
          typeof currentServing?.measurement_description === "string" &&
          currentServing?.measurement_description?.includes("serving")
            ? "serving"
            : currentServing?.measurement_description;
        let currentMeasurementCount;
        if (
          servingObj?.[modifiedMeasurementDescription] &&
          currentServing?.measurement_description
        ) {
          currentMeasurementCount = removeSubstringAndParseInt(
            servingObj[modifiedMeasurementDescription],
            currentServing?.measurement_description
          );
        } else {
          currentMeasurementCount = parseFloat(food?.measurement_count ?? 0);
        }
        let ratio = e.target.value / currentMeasurementCount;
        let foodSize;
        if (food?.size) {
          foodSize = parseFloat(food?.size ?? 0);
        }
        foodsCopy[index] = {
          ...foodsCopy[index],
          protein: +(currentServing?.protein ?? food?.protein) * ratio,
          carbs: +(currentServing?.carbohydrate ?? food?.carbs) * ratio,
          fat: +(currentServing?.fat ?? food?.fat) * ratio,
          calories: +(currentServing?.calories ?? food?.calories) * ratio,
          measurement_count: e.target.value,
          size: +(currentServing?.metric_serving_amount ?? foodSize) * ratio,
        };

        setMeals(foodsCopy);
      }}
    />
  );
}
