import React from "react";

const ActiveIndicator = ({ width }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "144"}
      height="3"
      viewBox={`0 0 ${width || "144"} 3`}
      fill="none"
    >
      <rect
        x="32"
        width={width - 64 || "80"}
        height="3"
        rx="1.5"
        fill="url(#paint0_linear_589_19277)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_589_19277"
          x1="32"
          y1="1.5"
          x2="112"
          y2="1.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.104167" stop-color="#464FEB" />
          <stop offset="1" stop-color="#8330E9" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ActiveIndicator;
