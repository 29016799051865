import { Collapse } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ProgramsContext } from "./ProgramsContext";

import { Skeleton } from "@/components/ui/skeleton";
import { cn } from "@/lib/utils";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import {
  CalendarDays,
  ClipboardPaste,
  CopyX,
  Folder,
  FolderOpen,
  Layers,
  MoreVertical,
} from "lucide-react";
import DropDownMenu from "./DropDownMenu";
import ImportPhaseModal from "./utils/ImportPhaseModal";

function ProgramFolderList({ folders, adminID, isSubbordinate }) {
  const {
    selectedProgram,
    setSelectedProgram,
    onProgramSelect,
    deleteProgramByID,
    addNewPhase,
    isNewPhaseLoading,
    setShowDuplicateProgramModal,
    setDuplicateData,
    openPhaseId,
    setOpenPhaseId,
    openFolderId,
    setOpenFolderId,
    onFolderSelected,
    selectedTab,
    setSelectedProgramData,
    deleteItemFromTheFolder,
    setFolderSelected,
    folderSelected,
    setFolders,
    setIsShifting,
    isShifting,
    transferFromFolder,
    setItemToBeShift,
    setSelectedProgramWithPhases,
    selectedProgramWithPhases,
    setTemplateFolders,
    setTemplates,
    setPrograms,
    setOriginalState,
    originalState,
  } = useContext(ProgramsContext);
  const [isDown, setIsDown] = useState(false);
  const handleToggleFolder = (folderId) => {
    setOpenFolderId((prevId) => (prevId === folderId ? null : folderId));
    setIsDown((prev) => !prev);
  };

  useEffect(() => {
    if (folders?.length > 0) {
      setFolderSelected(folders[0]);
      setSelectedProgram({});
      setOpenPhaseId("");
      setOpenFolderId("");
      setSelectedProgramWithPhases({});
    } else {
      setFolderSelected({});
      setSelectedProgram({});
      setSelectedProgramWithPhases({});
      setOpenPhaseId("");
      setOpenFolderId("");
    }
  }, [selectedTab]);

  return (
    <>
      {folders?.map((folder) => {
        let isSelect =
          openFolderId === folder?.id || folderSelected?.id === folder?.id;
        let programLength = folder?.programs?.length;
        let phaseLength = folder?.containers?.length;
        let totalLength = (phaseLength || 0) + (programLength || 0);
        return (
          <>
            <div
              key={folder?.id}
              className={`flex   flex-col cursor-pointer relative   px-2 ${
                isSelect
                  ? "folder-card-container-selected"
                  : "folder-card-container-unselected "
              } 
              
              `}
              onClick={async (e) => {
                if (isShifting) {
                  if (isShifting !== folder?.id) {
                    const res = await transferFromFolder(
                      isShifting,
                      folder?.id
                    );
                    if (res) {
                      setOpenFolderId(folder?.id);
                      setFolderSelected(folder);
                    }
                  } else {
                    setIsShifting("");
                  }

                  return;
                }
                handleToggleFolder(folder?.id);
                onFolderSelected(folder);
                setSelectedProgram({});
                setOpenPhaseId(null);
                setSelectedProgramData({});
                setSelectedProgramWithPhases(null);
              }}
            >
              {isShifting && isShifting === folder?.id && (
                <div className="absolute top-0 right-0 z-50 bg-black-pure bg-opacity-50 p-1 rounded-md  h-full w-full flex items-center justify-center">
                  <p className="font-DMSans text-font14 text-[#fff]">
                    Click To Cancel Transfer
                  </p>
                  <button
                    className="absolute top-1 right-1 p-1 rounded-full bg-[#F2F2F2]"
                    onClick={() => setIsShifting("")}
                  >
                    {" "}
                    <CopyX />
                  </button>
                </div>
              )}
              <div
                className="px-2 mx-2 py-1"
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  className={
                    isSelect
                      ? "channel-preview__wrapper_indicator__selected"
                      : "channel-preview__wrapper_indicator"
                  }
                  style={{
                    marginLeft: -5,
                  }}
                ></div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: 700,
                      color: "#8330e9",
                      // padding: "5px",
                      lineHeight: "10px",
                      fontFamily: "DM Sans",
                      // backgroundColor: "#ffffff",
                      // borderRadius: "10px",

                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    {openFolderId === folder?.id ? (
                      <ArrowDropDownRoundedIcon
                        sx={{
                          fontSize: "35px",
                        }}
                      />
                    ) : (
                      <PlayArrowRoundedIcon
                        sx={{
                          fontSize: "20px",
                        }}
                      />
                    )}
                  </span>
                  <div className="w-[30px] h-[40px] flex items-center justify-center  rounded-lg mx-2">
                    {openFolderId === folder?.id ? <FolderOpen /> : <Folder />}
                  </div>
                  {/* <div className="flex-col"> */}
                  <p className="font-DMSans text-font14 font-medium w-full flex-wrap break-words">
                    {folder?.name}
                  </p>
                  {isSubbordinate && (
                    <span
                      style={{
                        fontSize: "10px",
                        fontWeight: 700,
                        color: "#09A033",
                        padding: "5px",
                        lineHeight: "10px",
                        fontFamily: "DM Sans",
                        backgroundColor: "#F7F7F7",
                        borderRadius: "10px",
                        marginLeft: "5px",
                      }}
                    >
                      {folder?.coach?.name}
                    </span>
                  )}

                  {adminID && folder?.coachID === adminID && (
                    <span
                      style={{
                        fontSize: "10px",
                        fontWeight: 700,
                        color: "#09A033",
                        padding: "5px",
                        lineHeight: "10px",
                        fontFamily: "DM Sans",
                        backgroundColor: "#F7F7F7",
                        borderRadius: "10px",
                        marginLeft: "5px",
                      }}
                    >
                      {"ADMIN"}
                    </span>
                  )}
                </div>
                {isShifting && isShifting !== folder?.id && <ClipboardPaste />}
              </div>
              {(folder?.containers?.length > 0 ||
                folder?.programs?.length > 0) &&
                openFolderId === folder?.id && (
                  <ExpandableCardForFolder
                    onProgramSelect={onProgramSelect}
                    isPhaseOpen={openFolderId}
                    folder={folder}
                    deleteProgramByID={deleteProgramByID}
                    addNewPhase={addNewPhase}
                    selectedProgram={selectedProgram}
                    loading={isNewPhaseLoading}
                    setShowDuplicateProgramModal={setShowDuplicateProgramModal}
                    setDuplicateData={setDuplicateData}
                    openPhaseId={openPhaseId}
                    setOpenPhaseId={setOpenPhaseId}
                    deleteItemFromTheFolder={deleteItemFromTheFolder}
                    setFolders={setFolders}
                    folderSelected={folderSelected}
                    setFolderSelected={setFolderSelected}
                    setIsShifting={setIsShifting}
                    setItemToBeShift={setItemToBeShift}
                    setTemplateFolders={setTemplateFolders}
                    selectedTab={selectedTab}
                    setTemplates={setTemplates}
                    setPrograms={setPrograms}
                    totalFolders={folders}
                    isShifting={isShifting}
                    setOriginalState={setOriginalState}
                    selectedProgramWithPhases={selectedProgramWithPhases}
                    setSelectedProgram={setSelectedProgram}
                    setSelectedProgramData={setSelectedProgramData}
                    setSelectedProgramWithPhases={setSelectedProgramWithPhases}
                    originalState={originalState}
                    adminID={adminID}
                  />
                )}
            </div>
          </>
        );
      })}
    </>
  );
}

export default ProgramFolderList;

const ExpandableCardForFolder = ({
  onProgramSelect,
  deleteProgramByID,
  weeks,
  isPhaseOpen,
  folder,
  addNewPhase,
  selectedProgram,
  loading,
  setShowDuplicateProgramModal,
  setDuplicateData,
  openPhaseId,
  setOpenPhaseId,
  deleteItemFromTheFolder,
  folderSelected,
  setFolderSelected,
  setFolders,
  setIsShifting,
  setItemToBeShift,
  setTemplateFolders,
  selectedTab,
  setTemplates,
  setPrograms,
  totalFolders,
  isShifting,
  setOriginalState,
  selectedProgramWithPhases,
  setSelectedProgram,
  setSelectedProgramData,
  setSelectedProgramWithPhases,
  originalState,
  adminID,
}) => {
  const [isDown, setIsDown] = useState(false);
  const handleTogglePhase = (id) => {
    setOpenPhaseId((prevId) => (prevId === id ? null : id));
    setIsDown((prev) => !prev);
  };

  const handleRemoveItemFromFolder = async (item) => {
    let data = {
      folderID: folder?.id,
    };
    if (item?.programs) {
      data["containerIDs"] = [item?.id];
    } else {
      data["programIDs"] = [item?.id];
    }
    console.log("this is the data for the remove", data);
    let res = await deleteItemFromTheFolder(data);
    if (res) {
      if (selectedTab === "templates") {
        let updatedDataForTemplates = [];
        setFolderSelected((prev) => {
          let prevData = { ...prev };
          if (item?.programs) {
            let newContainer = prevData?.containers.filter(
              (phase) => phase.id !== item?.id
            );
            prevData.containers = newContainer;
          } else {
            let newPrograms = prevData?.programs.filter(
              (program) => program.id !== item?.id
            );
            prevData.programs = newPrograms;
          }
          setTemplateFolders((prevs) => {
            let data = [...originalState?.templateFolders];
            let newFolderData = data?.map((folder) => {
              if (folder.id === prevData.id) {
                return prevData;
              } else {
                return folder;
              }
            });

            setOriginalState((preState) => {
              return {
                ...preState,
                templateFolders: [...newFolderData],
              };
            });

            return newFolderData;
          });
          return prevData;
        });
        setTemplates((prev) => {
          const prevTemplates = [...originalState?.templates];
          let templatesdata = { ...item };

          if (templatesdata?.programFolderId) {
            templatesdata.programFolderId = null;
          }
          prevTemplates.unshift(templatesdata);
          updatedDataForTemplates = prevTemplates;

          return prevTemplates;
        });

        setOriginalState((preState) => {
          return {
            ...preState,

            templates: [...updatedDataForTemplates],
          };
        });
      } else {
        let updatedDataForPrograms = [];
        setFolderSelected((prev) => {
          let prevData = { ...prev };
          if (item?.programs) {
            let newContainer = prevData?.containers.filter(
              (phase) => phase.id !== item?.id
            );
            prevData.containers = newContainer;
          } else {
            let newPrograms = prevData?.programs.filter(
              (program) => program.id !== item?.id
            );
            prevData.programs = newPrograms;
          }
          setFolders((prevs) => {
            let data = [...originalState?.folders];
            let newFolderData = data?.map((folder) => {
              if (folder.id === prevData.id) {
                return prevData;
              } else {
                return folder;
              }
            });

            setOriginalState((preState) => {
              return {
                ...preState,
                folders: [...newFolderData],
              };
            });
            return newFolderData;
          });
          return prevData;
        });
        setPrograms((prev) => {
          const prevPrograms = [...originalState?.programs];

          let programsdata = { ...item };
          if (programsdata?.programFolderId) {
            programsdata.programFolderId = null;
          }
          prevPrograms.unshift(programsdata);
          updatedDataForPrograms = prevPrograms;
          return prevPrograms;
        });

        setOriginalState((preState) => {
          return {
            ...preState,
            programs: [...updatedDataForPrograms],
          };
        });
      }
      setSelectedProgram({});
      setOpenPhaseId(null);
      setSelectedProgramData({});
      setSelectedProgramWithPhases(null);
    }
  };

  return (
    <>
      <div className="expandable-card w-full bg-[#ffffff] mb-4 rounded-b-md">
        <div className="h-[1px]   bg-black-pure/50"></div>
        <Collapse
          in={isPhaseOpen}
          timeout={500}
          sx={{
            transitionDuration: "500ms",
            transitionTimingFunction: "ease",
            scrollbarWidth: "0px",
            marginTop: "5px",
            borderRadius: "12px",
            width: "100%",
            // marginLeft: "14%",
          }}
        >
          <>
            {folder?.containers?.map((item, index) => {
              let isPhaseSelected =
                selectedProgram?.id === item?.id ||
                selectedProgramWithPhases?.id === item?.id;
              return (
                <div
                  key={index}
                  className={`flex flex-col cursor-pointer rounded-lg mb-2 items-stretch gap-[4px] 
                   
                   ${
                     isPhaseSelected
                       ? "folder-card-container-selected"
                       : "folder-card-container-unselected"
                   }
                    `}
                  style={{
                    // border: "1px solid #d0cdcd",

                    background: `${
                      isPhaseSelected ? "rgb(125 131 241)" : "white"
                    }`,
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleTogglePhase(item?.id);
                    onProgramSelect(item);
                  }}
                >
                  {" "}
                  <div
                    className={`flex px-3 py-1  gap-3 cursor-pointer items-center rounded-lg`}
                  >
                    <p className="font-DMSans text-font12 flex items-center justify-between">
                      {item?.programs && (
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: 700,
                            color: "#8330e9",

                            lineHeight: "10px",
                            fontFamily: "DM Sans",

                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                          // onClick={(e) => {
                          //   handleTogglePhase(item?.id);
                          // }}
                        >
                          {openPhaseId === item?.id ? (
                            <ArrowDropDownRoundedIcon
                              className={cn({
                                "text-white-pure": isPhaseSelected,
                              })}
                              sx={{
                                fontSize: "35px",
                              }}
                            />
                          ) : (
                            <PlayArrowRoundedIcon
                              className={cn({
                                "text-white-pure": isPhaseSelected,
                              })}
                              sx={{
                                fontSize: "20px",
                              }}
                            />
                          )}
                        </span>
                      )}
                    </p>
                    <div
                      className={cn(
                        "w-[25px] h-[25px] flex items-center justify-center rounded-lg",
                        {
                          "text-white-pure": isPhaseSelected,
                        }
                      )}
                    >
                      <Layers />
                    </div>
                    <div
                      className={cn("flex-col w-[100%]", {
                        "text-white-pure": isPhaseSelected,
                      })}
                    >
                      <p className="font-DMSans text-font14 font-medium flex-wrap break-words">
                        {item?.name}
                      </p>
                      <p
                        className={cn(
                          "font-DMSans text-font12 text-gray-500 ",
                          {
                            "text-white-pure": isPhaseSelected,
                          }
                        )}
                      >
                        {item?.programs?.length} Phases
                      </p>
                    </div>

                    <div
                      className="ml-auto mr-1 items-center"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      {adminID ? (
                        folder?.coachID === adminID ? (
                          <></>
                        ) : (
                          <DropDownMenu
                            className={"mr-24 w-21 cursor-pointer"}
                            triggerElement={
                              <MoreVertical
                                size={20}
                                className={cn("text-black-pure", {
                                  "text-white-pure": isPhaseSelected,
                                })}
                              />
                            }
                            options={
                              totalFolders?.length > 1
                                ? ["Remove From Folder", "Shift To Another"]
                                : ["Remove From Folder"]
                            }
                            onSelect={(option) => {
                              if (option === "Remove From Folder") {
                                handleRemoveItemFromFolder(item);
                              }
                              if (option === "Shift To Another") {
                                setIsShifting(folder?.id);
                                setItemToBeShift(item);
                              }
                            }}
                          />
                        )
                      ) : (
                        <DropDownMenu
                          className={"mr-24 w-21 cursor-pointer"}
                          triggerElement={
                            <MoreVertical
                              size={20}
                              className={cn("text-black-pure", {
                                "text-white-pure": isPhaseSelected,
                              })}
                            />
                          }
                          options={
                            totalFolders?.length > 1
                              ? ["Remove From Folder", "Shift To Another"]
                              : ["Remove From Folder"]
                          }
                          onSelect={(option) => {
                            if (option === "Remove From Folder") {
                              handleRemoveItemFromFolder(item);
                            }
                            if (option === "Shift To Another") {
                              setIsShifting(folder?.id);
                              setItemToBeShift(item);
                            }
                          }}
                        />
                      )}
                    </div>
                  </div>
                  {item?.programs && openPhaseId === item?.id && (
                    <ExpandableCardForPhasesInFolder
                      onProgramSelect={onProgramSelect}
                      //   weeks={weeks}
                      isPhaseOpen={openPhaseId}
                      program={item}
                      deleteProgramByID={deleteProgramByID}
                      addNewPhase={addNewPhase}
                      selectedProgram={selectedProgram}
                      setDuplicateData={setDuplicateData}
                      setShowDuplicateProgramModal={
                        setShowDuplicateProgramModal
                      }
                      loading={loading}
                      adminID={adminID}
                    />
                  )}
                </div>
              );
            })}
          </>

          <>
            {folder?.programs?.map((item, index) => {
              let totalWeeks = Math.ceil(item?.duration / 7);

              let isPhaseSelected = selectedProgram?.id === item?.id;
              return (
                <div
                  key={index}
                  className={`flex flex-col   cursor-pointer rounded-lg mb-2  
                   
                   ${
                     isPhaseSelected
                       ? "folder-card-container-selected  items-stretch "
                       : "folder-card-container-unselected   items-stretch"
                   } `}
                  style={{
                    // border: "1px solid #d0cdcd",
                    color: `${isPhaseSelected ? "white" : "black"}`,
                    background: `${
                      isPhaseSelected ? "rgb(125 131 241)" : "#ffffff"
                    }`,
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onProgramSelect(item);
                  }}
                >
                  <div
                    className={`flex px-3 py-1  gap-3 cursor-pointer items-center rounded-lg`}
                  >
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: 700,
                        color: "#8330e9",
                        // padding: "5px",
                        lineHeight: "10px",
                        fontFamily: "DM Sans",
                        // backgroundColor: "#ffffff",
                        // borderRadius: "10px",
                        // marginLeft: "18px",
                        cursor: "pointer",
                        textDecoration: "underline",
                        visibility: "hidden",
                      }}
                      onClick={(e) => {
                        handleTogglePhase(item?.id);
                      }}
                    >
                      {openPhaseId === item?.id ? (
                        <ArrowDropDownRoundedIcon
                          sx={{
                            fontSize: "35px",
                          }}
                        />
                      ) : (
                        <PlayArrowRoundedIcon
                          sx={{
                            fontSize: "20px",
                          }}
                        />
                      )}
                    </span>
                    <div className="w-[25px] h-[25px] flex items-center justify-center   rounded-lg">
                      <CalendarDays />
                    </div>
                    <div className="flex-col  w-[100%] ">
                      <p className="font-DMSans text-font14 font-medium   flex-wrap break-words">
                        {item?.name}
                      </p>
                      <p
                        className={cn("font-DMSans text-font12 text-gray-500", {
                          "text-white-pure": isPhaseSelected,
                        })}
                      >
                        Week {totalWeeks || 1}
                      </p>
                    </div>
                    <div
                      className="ml-auto  mr-1 items-center"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      {adminID ? (
                        folder?.coachID === adminID ? (
                          <></>
                        ) : (
                          <DropDownMenu
                            className={" mr-24 w-21 cursor-pointer"}
                            triggerElement={<MoreVertical size={20} />}
                            options={
                              totalFolders?.length > 1
                                ? ["Remove From Folder", "Shift To Another"]
                                : ["Remove From Folder"]
                            }
                            onSelect={(option) => {
                              if (option === "Remove From Folder") {
                                handleRemoveItemFromFolder(item);
                              }
                              if (option === "Shift To Another") {
                                setIsShifting(folder?.id);
                                setItemToBeShift(item);
                              }
                            }}
                          />
                        )
                      ) : (
                        <DropDownMenu
                          className={" mr-24 w-21 cursor-pointer"}
                          triggerElement={<MoreVertical size={20} />}
                          options={
                            totalFolders?.length > 1
                              ? ["Remove From Folder", "Shift To Another"]
                              : ["Remove From Folder"]
                          }
                          onSelect={(option) => {
                            if (option === "Remove From Folder") {
                              handleRemoveItemFromFolder(item);
                            }
                            if (option === "Shift To Another") {
                              setIsShifting(folder?.id);
                              setItemToBeShift(item);
                            }
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        </Collapse>
      </div>
    </>
  );
};

export const ExpandableCardForPhasesInFolder = ({
  onProgramSelect,
  deleteProgramByID,
  weeks,
  isPhaseOpen,
  program,
  addNewPhase,
  selectedProgram,
  loading,
  setShowDuplicateProgramModal,
  setDuplicateData,
  adminID,
}) => {
  const {
    programs,
    folderSelected,
    folders,
    templateFolders,
    templates,
    importPhaseToPhaseContainer,
    coachID,
  } = useContext(ProgramsContext);

  const [phases, setPhases] = useState([]);
  useEffect(() => {
    if (program?.programs) {
      // sort the programs by phaseOrderIdx  and if the phaseOrderIdx is not present then by name of the phase and names can be Phase 1, Phase 2, Phase 3, etc.
      let sortedPrograms = [...(program?.programs || [])]?.sort((a, b) => {
        if (a?.phaseOrderIdx && b?.phaseOrderIdx) {
          return a?.phaseOrderIdx - b?.phaseOrderIdx;
        }
        const phaseA = parseInt(a?.name?.split(" ")[1]);
        const phaseB = parseInt(b?.name?.split(" ")[1]);
        return phaseA - phaseB;
      });
      setPhases(sortedPrograms);
    }
  }, [program, folderSelected, templates, folders, templateFolders, loading]);

  return (
    <div className="expandable-card bg-white-pure rounded-lg pb-1 mb-4 mx-2 px-2">
      <Collapse
        in={isPhaseOpen}
        timeout={500}
        sx={{
          transitionDuration: "500ms",
          transitionTimingFunction: "ease",
          scrollbarWidth: "0px",
          background: "white",
          borderRadius: "12px",
          width: "100%",
          //padding: "10px 12px",
        }}
      >
        {phases?.map((item, index) => {
          let totalWeeks = Math.ceil(item?.duration / 7);
          let leftRange, rightRange;
          if (index == 0) {
            leftRange = index + 1;
            rightRange = totalWeeks;
          } else {
            leftRange = index * totalWeeks + 1;
            rightRange = leftRange + totalWeeks - 1;
          }
          let isPhaseSelected = selectedProgram?.id === item?.id;
          return (
            <div
              key={index}
              className={cn(
                "flex px-2 py-1 gap-3   cursor-pointer w-full items-center rounded-lg border border-[#d0cdcd]",
                index == 0 ? "mt-2" : "mt-1",
                {
                  "bg-[#464feb]/70": isPhaseSelected,
                }
              )}
              onClick={(e) => {
                e.stopPropagation();
                onProgramSelect(item);
              }}
            >
              <div
                className={cn(
                  "w-[22px] h-[22px] flex items-center justify-center   rounded-lg",
                  {
                    "text-white-pure": isPhaseSelected,
                  }
                )}
              >
                <CalendarDays />
              </div>

              <div className="flex-col w-[60%]">
                <p
                  className={cn(
                    "font-DMSans text-font14 font-medium truncate ...",
                    {
                      "text-white-pure": isPhaseSelected,
                    }
                  )}
                >
                  {item?.name}
                </p>
                <p
                  className={cn("font-DMSans text-font12 text-gray-500", {
                    "text-white-pure": isPhaseSelected,
                  })}
                >
                  {totalWeeks} week{totalWeeks > 1 ? "s" : ""}
                </p>
              </div>

              <div
                className="ml-auto mr-1 items-center"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {adminID ? (
                  program?.coachID === adminID ? (
                    <></>
                  ) : (
                    <DropDownMenu
                      className={"mr-24 w-21 cursor-pointer"}
                      triggerElement={
                        <MoreVertical
                          size={20}
                          color={isPhaseSelected ? "white" : "black"}
                        />
                      }
                      options={["Duplicate Phase", `Delete Phase`]}
                      onSelect={(option) => {
                        if (option === `Delete Phase`) {
                          deleteProgramByID(item?.id);
                        } else if (option === "Duplicate Phase") {
                          setShowDuplicateProgramModal(true);
                          setDuplicateData(item);
                        }
                      }}
                    />
                  )
                ) : (
                  <DropDownMenu
                    className={"mr-24 w-21 cursor-pointer"}
                    triggerElement={
                      <MoreVertical
                        size={20}
                        color={isPhaseSelected ? "white" : "black"}
                      />
                    }
                    options={["Duplicate Phase", `Delete Phase`]}
                    onSelect={(option) => {
                      if (option === `Delete Phase`) {
                        deleteProgramByID(item?.id);
                      } else if (option === "Duplicate Phase") {
                        setShowDuplicateProgramModal(true);
                        setDuplicateData(item);
                      }
                    }}
                  />
                )}
              </div>
            </div>
          );
        })}
      </Collapse>
      {loading && (
        <div
          className="w-[100%] h-[60px] rounded-lg bg-[#fffcfa] flex justify-between items-center px-2 py-2 mt-2"
          style={{
            border: "1px solid #d0cdcd",
          }}
        >
          <div
            style={{
              fontWeight: "700",
              fontFamily: "DM Sans",
              textTransform: "uppercase",
              color: "black",
              width: "72px",
              height: "54px",
              maringRight: "5px",
            }}
          >
            <Skeleton className="w-[100%] h-[100%]" />
          </div>
          <div className="w-[70%]">
            <Skeleton className="w-[100%] h-[54px]" />
          </div>
        </div>
      )}
      {program?.coachID === coachID && (
        <div
          className="w-full px-[10px] py-[5px] flex justify-between items-center  mt-2 gap-2"
          onClick={(e) => e.stopPropagation()}
        >
          <ImportPhaseModal
            programs={programs}
            importPhaseToPhaseContainer={importPhaseToPhaseContainer}
            program={program}
            templates={templates}
            folders={folders}
            templateFolders={templateFolders}
          />
          <span
            className="ml-auto font-semibold bg-[#6241EA] border-2 border-[#6241EA] text-white-pure px-[8px] py-[3px] flex items-center rounded-md text-[14px] cursor-pointer"
            onClick={() => {
              addNewPhase();
            }}
          >
            + Add Phase
          </span>
        </div>
      )}
    </div>
  );
};
