import { CloseRounded } from "@mui/icons-material";
import { Dialog, DialogContent } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { CloseIcon } from "stream-chat-react";

const AddMealModal = ({
  open,
  setIsOpen,
  setNutritionData,
  trainerUUID,
  setdefaultNItem,
  setdefaultNItemForMeal,
  getMealScheduleByID,
  isTemplate,
}) => {
  const [mealName, setmealName] = useState("");
  const [mealDescription, setmealDescription] = useState();

  console.log("losec");

  const handleAddMeal = () => {
    let data = {
      name: mealName,
      description: mealDescription,
      creationDate: `${moment().format("YYYY-MM-DDTHH:mm:ss")}Z`,
      trainerUUID: trainerUUID,
      isTemplate,
    };
    setNutritionData((prev) => {
      return [data, ...prev];
    });
    getMealScheduleByID(data);
    setIsOpen(false);
  };

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          // padding: "24px",
          width: "320px",
          borderRadius: "20px",
        },
      }}
      onClose={() => {
        console.log("c");
        setIsOpen(false);
      }}
    >
      <DialogContent sx={{ padding: 0 }}>
        <div className="add-program-modal-head">
          <p className="add-program-modal-head-title">
            {isTemplate ? "Add Template Meal Schedule" : "Add Meal Schedule"}
          </p>
          <div
            className="cursor-pointer"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <CloseRounded
              sx={{
                fontSize: "24px",
                color: "#000",
                cursor: "pointer",
              }}
            />
          </div>
        </div>
        <div className="add-program-modal-body">
          <div className="full-width-container">
            <label className="label-new">
              {isTemplate ? "Meal Template Name*" : "Meal Name*"}
            </label>
            <input
              placeholder={isTemplate ? "Meal Template Name" : "Meal Name"}
              value={mealName}
              onChange={(e) => setmealName(e.target.value)}
              className="inputfiled-new"
            />
          </div>
          <textarea
            id="meal description"
            className="textarea-new"
            placeholder="Description (Optional)"
            type="text"
            onChange={(e) => {
              setmealDescription(e.target.value);
            }}
            rows={3}
            value={mealDescription}
          />
          <button
            disabled={mealName.length < 2}
            style={{ marginTop: "8px" }}
            className="button-new"
            onClick={handleAddMeal}
          >
            {isTemplate ? "Add Template" : "Add Meal"}
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddMealModal;
