import React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const Arrow = ({ disableArrow, getWeekFunction, children }) => {
  const style = { fill: "#040F2E", fontSize: "16px", cursor: "pointer" };
  return (
    <div className="flex justify-center items-center text-black-pure tex-[14px] font-bold">
      {disableArrow !== "left" && disableArrow !== "both" && (
        <ArrowBackIosIcon
          style={style}
          onClick={() => {
            getWeekFunction("left");
          }}
        />
      )}
      <h2
        className="min-w-[140px] text-center"
        style={{ fontFamily: "DM Sans", fontSize: "16px", fontWeight: "700",  }}
      >
        {children}
      </h2>
      {disableArrow !== "right" && disableArrow !== "both" && (
        <ArrowForwardIosIcon
          style={style}
          onClick={() => {
            getWeekFunction("right");
          }}
        />
      )}
    </div>
  );
};

export default Arrow;
