import {
  overallMaxCadenceWindowedPeakMaxAvg,
  overallMaxHeartRateWindowedPeakMaxAvg, overallMaxSpeedWindowedPeakBikeMaxAvg,
  overallMinPaceWindowedPeakMaxAvg,
  weeklyAggDistance,
  weeklyAggRTSS, weeklyMaxDistance, weeklyMaxDurationInSeconds, weeklyMinPaceWindowedPeak1200MaxAvg
} from "./helperCharts.js";

export function calculateWeeklyAggregateData(
  activityData,
  startDate,
  endDate,
  nestedKeyPath,
  activityType
) {
  // Validate input parameters
  validateActivityData(activityData);
  validateDate(startDate, "Start date");
  validateDate(endDate, "End date");
  validateNestedKeyPath(nestedKeyPath);

  // Ensure start date is before end date
  if (startDate >= endDate) {
    throw new Error("Start date must be before end date.");
  }

  // Initialize the result object
  const weeklyData = {};

  // Pre-calculate weeks within the date range
  const weeks = getWeeksInRange(startDate, endDate);

  // Iterate through activity data and group by week
  for (const activity of activityData) {
    // Check if activityType matches, if provided
    if (activityType && activity.summary.activityType !== activityType) {
      continue; // Skip to the next activity if activityType doesn't match
    }

    // Parse the dateRecorded field
    const dateRecorded = new Date(activity.dateRecorded);

    // Find the week the activity belongs to
    const week = findWeek(weeks, dateRecorded);
    if (week) {
      // Extract the nested value using the provided key path
      let nestedValue = activity;
      const keyPathParts = nestedKeyPath.split(".");
      for (const keyPart of keyPathParts) {
        nestedValue = nestedValue[keyPart];
        if (!nestedValue) {
          break; // Key path doesn't exist
        }
      }
      if (nestedValue) {
        // Add the nested value to the corresponding week in the result object
        if (weeklyData[week.weekNumber]) {
          weeklyData[week.weekNumber].value += nestedValue;
        } else {
          weeklyData[week.weekNumber] = {
            value: nestedValue,
            weekStartDate: week.weekStartDate,
            weekEndDate: week.weekEndDate,
            activityType: activity.summary.activityType,
          };
        }
      }
    }
  }

  // Return the result object
  return weeklyData;
}

export function calculateWeeklyMinMax(
  activityData,
  startDate,
  endDate,
  nestedKeyPath,
  operation,
  activityType
) {
  // Validate input parameters
  validateActivityData(activityData);
  validateDate(startDate, "Start date");
  validateDate(endDate, "End date");
  validateNestedKeyPath(nestedKeyPath);
  validateOperation(operation);

  // Ensure start date is before end date
  if (startDate >= endDate) {
    throw new Error("Start date must be before end date.");
  }

  // Initialize the result object
  const weeklyData = {};

  // Pre-calculate weeks within the date range
  const weeks = getWeeksInRange(startDate, endDate);

  // Iterate through activity data and group by week
  for (const activity of activityData) {
    // Check if activityType matches, if provided
    if (activityType && activity.summary.activityType !== activityType) {
      continue; // Skip to the next activity if activityType doesn't match
    }

    // Parse the dateRecorded field
    const dateRecorded = new Date(activity.dateRecorded);

    // Find the week the activity belongs to
    const week = findWeek(weeks, dateRecorded);
    if (week) {
      // Extract the nested value using the provided key path
      let nestedValue = activity;
      const keyPathParts = nestedKeyPath.split(".");
      for (const keyPart of keyPathParts) {
        nestedValue = nestedValue[keyPart];
        if (!nestedValue) {
          break; // Key path doesn't exist
        }
      }

      if (nestedValue) {
        // Update the minimum/maximum value for the week based on the operation
        if (weeklyData[week.weekNumber]) {
          if (operation === "min") {
            weeklyData[week.weekNumber].value = Math.min(
              weeklyData[week.weekNumber].value,
              nestedValue
            );
          } else if (operation === "max") {
            weeklyData[week.weekNumber].value = Math.max(
              weeklyData[week.weekNumber].value,
              nestedValue
            );
          }
        } else {
          weeklyData[week.weekNumber] = {
            value: nestedValue,
            weekStartDate: week.weekStartDate,
            weekEndDate: week.weekEndDate,
            activityType: activity.summary.activityType,
          };
        }
      }
    }
  }

  // Return the result object
  return weeklyData;
}

export function calculateWeeklyMinMaxJson(
  activityData,
  startDate,
  endDate,
  nestedKeyPath,
  operation,
  activityType
) {
  // Validate input parameters
  validateActivityData(activityData);
  validateDate(startDate, "Start date");
  validateDate(endDate, "End date");
  validateNestedKeyPath(nestedKeyPath);
  validateOperation(operation);

  // Ensure start date is before end date
  if (startDate >= endDate) {
    throw new Error("Start date must be before end date.");
  }

  // Initialize the result object
  const weeklyData = {};

  // Pre-calculate weeks within the date range
  const weeks = getWeeksInRange(startDate, endDate);

  // Iterate through activity data and group by week
  for (const activity of activityData) {
    // Check if activityType matches, if provided
    if (activityType && activity.summary.activityType !== activityType) {
      continue; // Skip to the next activity if activityType doesn't match
    }

    // Parse the dateRecorded field
    const dateRecorded = new Date(activity.dateRecorded);

    // Find the week the activity belongs to
    const week = findWeek(weeks, dateRecorded);
    if (week) {
      // Extract the nested JSON object using the provided key path
      let nestedJsonObject = activity;
      const keyPathParts = nestedKeyPath.split(".");
      for (const keyPart of keyPathParts) {
        nestedJsonObject = nestedJsonObject[keyPart];
        if (nestedJsonObject === undefined) {
          break; // Key path doesn't exist
        }
      }

      // If the nested object is a valid JSON object
      if (typeof nestedJsonObject === "object") {
        // Iterate through the keys of the nested JSON object
        for (const key in nestedJsonObject) {
          // Get the value for the current key
          const nestedValue = nestedJsonObject[key];

          // If the value is a number, update the minimum/maximum value for the week
          if (typeof nestedValue === "number") {
            if (weeklyData[week.weekNumber]) {
              if (operation === "min") {
                weeklyData[week.weekNumber][key] = Math.min(
                  weeklyData[week.weekNumber][key] || Infinity,
                  nestedValue
                );
              } else if (operation === "max") {
                weeklyData[week.weekNumber][key] = Math.max(
                  weeklyData[week.weekNumber][key] || -Infinity,
                  nestedValue
                );
              }
            } else {
              weeklyData[week.weekNumber] = {
                [key]: nestedValue,
                weekStartDate: week.weekStartDate,
                weekEndDate: week.weekEndDate,
                activityType: activity.summary.activityType,
              };
            }
          }
        }
      }
    }
  }

  // Return the result object
  return weeklyData;
}

export function calculateOverallMinMaxJson(
  activityData,
  nestedKeyPath,
  operation,
  activityType,
  auxiliaryPath = null
) {
  // Validate input parameters
  validateActivityData(activityData);
  validateNestedKeyPath(nestedKeyPath);
  validateOperation(operation);

  // Initialize the result object
  const overallData = {};

  // Iterate through the activity data
  for (const activity of activityData) {
    // Check if activityType matches, if provided
    if (activityType && activity.summary.activityType !== activityType) {
      continue; // Skip to the next activity if activityType doesn't match
    }

    // Extract the nested JSON object using the provided key path
    let nestedJsonObject = activity;
    const keyPathParts = nestedKeyPath.split(".");
    for (const keyPart of keyPathParts) {
      nestedJsonObject = nestedJsonObject[keyPart];
      if (!nestedJsonObject) {
        break; // Key path doesn't exist
      }
    }

    // If the nested object is a valid JSON object
    if (typeof nestedJsonObject === "object") {
      // Iterate through the keys of the nested JSON object
      for (const key in nestedJsonObject) {
        // Get the value for the current key
        let nestedValue = nestedJsonObject[key];

        // If an auxiliary path is provided, extract the value from it
        if (auxiliaryPath) {
          const parts = auxiliaryPath.split(".");
          for (const part of parts) {
            nestedValue = nestedValue[part];
            if (!nestedValue) {
              break; // Auxiliary path doesn't exist
            }
          }
        }

        if (nestedValue) {
          // If the nestedValue is a number, update the minimum/maximum value for the week
          if (typeof nestedValue === "number") {
            // Check if the key already exists in overallData
            if (overallData[key] !== undefined) {
              // Update the maximum value if necessary
              if (operation === "max" && nestedValue > overallData[key].value) {
                overallData[key] = {
                  value: nestedValue,
                  date: new Date(activity.dateRecorded), // Record the date of the maximum value
                  activityType: activity.summary.activityType,
                };
              } else if (
                operation === "min" &&
                nestedValue < overallData[key].value
              ) {
                overallData[key] = {
                  value: nestedValue,
                  date: new Date(activity.dateRecorded), // Record the date of the minimum value
                  activityType: activity.summary.activityType,
                };
              }
            } else {
              // First encounter of the key, store the value and date
              overallData[key] = {
                value: nestedValue,
                date: new Date(activity.dateRecorded),
                activityType: activity.summary.activityType,
              };
            }
          }
        }
      }
    }
  }

  // Return the result object
  return overallData;
}

// Helper export functions:
export function getWeeksInRange(startDate, endDate) {
  // Pre-calculate week numbers and start/end dates
  const weeks = [];
  let currentWeek = getWeekData(startDate);
  while (currentWeek.weekEndDate <= endDate) {
    weeks.push(currentWeek);
    //console.log(currentWeek.weekEndDate);
    currentWeek = getWeekData(
      new Date(currentWeek.weekEndDate.getTime() + 24 * 60 * 60 * 1000)
    );
  }
  return weeks;
}

export function findWeek(weeks, date) {
  // Efficiently find the week that the date belongs to
  for (const week of weeks) {
    if (date >= week.weekStartDate && date <= week.weekEndDate) {
      return week;
    }
  }
  return null;
}

export function getWeekData(date) {
  // Create a new Date object for the first day of the year
  const yearStart = new Date(date.getFullYear(), 0, 1);

  // Calculate the difference in milliseconds between the two dates
  const diff = date - yearStart;

  // Calculate the number of days between the two dates
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));

  // Get the week number using the calculated number of days
  const weekNumber = Math.ceil((days + yearStart.getDay()) / 7);

  // Calculate the start and end dates of the week
  const weekStartDate = new Date(
    date.getFullYear(),
    0,
    1 + (weekNumber - 1) * 7
  );
  const weekEndDate = new Date(
    weekStartDate.getTime() + 6 * 24 * 60 * 60 * 1000
  );

  return {weekNumber, weekStartDate, weekEndDate};
}

// Validation export functions:
export function validateActivityData(activityData) {
  if (!activityData || !Array.isArray(activityData)) {
    throw new Error("Invalid activity data. Must be an array.");
  }
}

export function validateDate(date, dateName) {
  if (!date || !(date instanceof Date)) {
    throw new Error(`Invalid ${dateName}. Must be a Date object.`);
  }
}

export function validateNestedKeyPath(nestedKeyPath) {
  if (!nestedKeyPath || typeof nestedKeyPath !== "string") {
    throw new Error("Invalid nested key path. Must be a string.");
  }
}

export function validateOperation(operation) {
  if (operation !== "min" && operation !== "max") {
    throw new Error("Invalid operation. Must be either 'min' or 'max'.");
  }
}

export const getChartDataForSelectedType = (activityData, title, fromDate, toDate) => {
  switch (title) {
    case 'Distance by Week':
      // functionality
      return weeklyAggDistance(activityData, fromDate, toDate);
    case 'TSS per week':
      // functionality
      return weeklyAggRTSS(activityData, fromDate, toDate);
    case 'Longest Workout - Distance':
      // functionality
      return weeklyMaxDistance(activityData, fromDate, toDate);
    case 'Longest Workout - Duration':
      // functionality
      return weeklyMaxDurationInSeconds(activityData, fromDate, toDate);
    case 'Power Profiles':
      // functionality
  break
    case 'Peak Pace':
      // functionality
      return overallMinPaceWindowedPeakMaxAvg(activityData, fromDate, toDate);

    case 'Peak HR':
      // functionality
      return overallMaxHeartRateWindowedPeakMaxAvg(activityData, fromDate, toDate);

    case 'Peak Power':
      // functionality
      break;
    case 'Peak Speed':
      // functionality
      return overallMaxSpeedWindowedPeakBikeMaxAvg(activityData, fromDate, toDate);

    case 'Peak Cadence':
      // functionality
      return overallMaxCadenceWindowedPeakMaxAvg(activityData, fromDate, toDate);

    case 'CTL vs FTL':
      // functionality
      break;
    case 'Peak 20 minute Run Pace':
      // functionality
      return weeklyMinPaceWindowedPeak1200MaxAvg(activityData, fromDate, toDate, "RUNNING");
    case 'Peak 20 minute cycling pace':
      // functionality
      return weeklyMinPaceWindowedPeak1200MaxAvg(activityData, fromDate, toDate, "CYCLING");
    default:
      // default functionality
      break;
  }
}

export const formatTime = (timeInSeconds) => {
  const hours = Math.floor(timeInSeconds / 3600);
  const minutes = Math.floor((timeInSeconds % 3600) / 60);
  const seconds = timeInSeconds % 60;

  if(hours > 0) {
    return `${hours}h ${minutes}m ${seconds}s`
  }
  if(minutes > 0) {

  }
}