const colors = {
  transparent: "transparent",
  labelColor: "#767676",
  lightBorder: "#f1f4f6",
  inactiveField: "#F7F8FB",
  inactiveButton: "#b7dbdd",
  inactiveIcon: "#EBEBEB",
  primaryHover: "#3C74FF",
  secondary: "#060f49",
  secondaryHover: "#112045",
  yellow: "#FFA740",
  yellowHover: "#F6C416",
  borderColor: "#E8EBF1",
  black: "#000000",
  white: "#ffffff",
  light: "#FAFBFF",
  gray: "#E4E4E4",
  primary: "#ff9800", //'#060f49'
  headingColor: "#0F2137",
  quoteText: "#343D48",
  menu: "#fff",
  textColor: "rgba(52, 61, 72, 0.8)",
  linkColor: "#2b9eff",
  shadow: "rgba(38, 78, 118, 0.1)",
  blueMain: "#071148",
  orange: " #FF9B04",
};

export default colors;
