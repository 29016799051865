import React, { memo, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { SpurfitCircularProgress } from "@/components/Dashboard/dashboard.js";
import { FirstCell, HeaderCell } from "@/components/Dashboard/helpers.js";
import { Menu, MenuItem, Typography } from "@mui/material";
import moment from "moment/moment.js";
import { MoreHorizRounded } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AssignAthleteToCoach from "@/components/Dashboard/AssignAthleteToCoach.js";
import TrainerDetailsLoading from "@/pages/adminDahboard/TrainerDetailsLoading.jsx";
import Questionaire from "@/components/ClientsView/Questionaire";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

const TrainerDetails = ({
  selectedTrainer,
  data,
  loading,
  reloadSourceAndTargetTrainerData,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [assignAthleteToCoach, setAssignAthleteToCoach] = useState(false);
  const [selectedTab, setSelectedTab] = useState("active");
  // const [loading, setLoading] = useState(false);

  const gymAffiliation = useSelector(
    (state) => state.auth?.data?.gymAffliation
  );
  const history = useHistory();
  const isLivezy = gymAffiliation === "Livezy";

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedItem(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedItem(null);
  };

  const trimText = (text, string) => {
    let maxLength = string.length;
    if (text.length > maxLength) {
      return `${text.slice(0, maxLength)}...`;
    }
    return text;
  };

  const calculateAge = (birthdate) => {
    const birthMoment = moment(birthdate);
    const age = moment().diff(birthMoment, "years");
    return age;
  };

  const columns = [
    {
      field: "Name",
      headerName: "Name",
      flex: 1,
      minWidth: 120,
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Trainee" />,
      renderCell: (params) => (
        <FirstCell
          params={params}
          onClick={() => {
            console.log("params ha kya", params);
            const win = window.open(
              `/admin/userDetail?id=${params?.id}`,
              "_blank"
            );
            win.focus();
            // history.push(`/admin/userDetail?id=${params?.id}`);
          }}
        />
      ),
    },
    {
      field: "ProgramName",
      headerName: "Program",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Program" />,
      renderCell: (params) => (
        <Typography
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
            whiteSpace: "normal",
            width: "100%",
            textOverflow: "ellipsis",
            textAlign: "center",
          }}
        >
          {params.row.ProgramName ?? ""}
        </Typography>
      ),
    },
    {
      field: "Adherence",
      headerName: "Compliance",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Compliance" />,
      renderCell: (params) => (
        <Typography
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
          }}
        >
          {params.row.Adherence ? params.row.Adherence + "%" : ""}
        </Typography>
      ),
    },
    {
      field: "LastWorkoutDate",
      headerName: "Last Workout Date",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Last Workout Date" />,

      renderCell: (params) => (
        <Typography
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
          }}
        >
          {params.row.LastWorkoutDate
            ? moment(params.row.LastWorkoutDate).format("MMMM Do' YY")
            : ""}
        </Typography>
      ),
    },
    {
      field: "LastPlannedWorkoutDate",
      headerName: "Last Plan Date",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <Typography
          sx={{
            fontSize: "13px",
            lineHeight: "20px",
            fontFamily: "DM Sans",
            color: "#333333",
            fontWeight: 500,
          }}
        >
          Last Plan Date
        </Typography>
      ),

      renderCell: (params) => (
        <Typography
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
          }}
        >
          {params.row.LastPlannedWorkoutDate
            ? moment(params.row.LastPlannedWorkoutDate).format("MMMM Do' YY")
            : ""}
        </Typography>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderHeader: () => (
        <HeaderCell label="Actions" style={{ textAlign: "center" }} />
      ),
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* <img
            src={MoreIcon}
            alt="more"
            onClick={(e) => handleClick(e, params?.row)}
            style={{ cursor: "pointer", padding: "10px" }}
          /> */}
          <MoreHorizRounded
            className="cursor-pointer"
            onClick={(e) => handleClick(e, params?.row)}
          />
          <Menu
            id={`item-actions-${params?.row?.UserUUID}`}
            anchorEl={anchorEl}
            open={selectedItem === params?.row && Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              sx={{
                fontSize: "14px",
                fontFamily: "DM Sans",
                fontWeight: 500,
                lineHeight: "20px",
              }}
              onClick={() => {
                setAssignAthleteToCoach(true);
              }}
            >
              Assign to Coach
            </MenuItem>
          </Menu>
        </div>
      ),
    },
  ];

  const LivEzyColumns = [
    {
      field: "Name",
      headerName: "Name",
      flex: 1,
      minWidth: 120,
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Trainee" />,
      renderCell: (params) => (
        <FirstCell
          params={params}
          onClick={() => {
            console.log("params ha kya", params);
            const win = window.open(
              `/admin/userDetail?id=${params?.id}`,
              "_blank"
            );
            win.focus();
          }}
        />
      ),
    },
    {
      field: "Plan Start",
      headerName: "Plan Start",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",

      renderHeader: () => <HeaderCell label="Plan Start" />,
      valueGetter: (params) => {
        return params.row?.planStartDate_LivEzy
          ? new Date(params.row.planStartDate_LivEzy)
          : "";
      },
      renderCell: (params) => (
        <Typography
          onClick={() => goToClient(params.row)}
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#333333",
            fontWeight: 400,
          }}
        >
          {params.row.planStartDate_LivEzy
            ? moment(params.row.planStartDate_LivEzy).format("MMMM Do' YY")
            : ""}
        </Typography>
      ),
    },
    {
      field: "clientID",
      headerName: "Client ID",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Client ID" />,
      valueGetter: (params) => {
        return params.row?.clientID_LivEzy ?? "";
      },
      renderCell: (params) => (
        <Typography
          onClick={() => goToClient(params.row)}
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
            whiteSpace: "normal",
            width: "100%",
            textOverflow: "ellipsis",
            textAlign: "center",
          }}
        >
          {params.row.clientID_LivEzy ?? ""}
        </Typography>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Email" />,
      valueGetter: (params) => {
        return params.row?.Email ?? "";
      },
      renderCell: (params) => (
        <Typography
          onClick={() => goToClient(params.row)}
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
            whiteSpace: "normal",
            width: "100%",
            textOverflow: "ellipsis",
            textAlign: "center",
          }}
        >
          {params.row.Email ?? ""}
        </Typography>
      ),
    },
    {
      field: "Phone",
      headerName: "Phone",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Phone" />,
      valueGetter: (params) => {
        return params.row?.contactNumber ?? "";
      },
      renderCell: (params) => (
        <Typography
          onClick={() => goToClient(params.row)}
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
            whiteSpace: "normal",
            width: "100%",
            textOverflow: "ellipsis",
            textAlign: "center",
          }}
        >
          {params.row.contactNumber ?? ""}
        </Typography>
      ),
    },
    {
      field: "Membership Details",
      headerName: "Compliance",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Membership Details" />,
      valueGetter: (params) => {
        return params.row?.planName_LivEzy ?? "";
      },
      renderCell: (params) => (
        <Typography
          style={{ overflow: "hidden", textOverflow: "ellipsis" }}
          onClick={() => goToClient(params.row)}
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
          }}
        >
          {trimText(params.row?.planName_LivEzy ?? "", "Membership Details")}
        </Typography>
      ),
    },
    {
      field: "renewal_LivEzy",
      headerName: "Renewal",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Renewal" />,
      valueGetter: (params) => {
        return params.row?.renewal_LivEzy ?? "";
      },
      renderCell: (params) => (
        <Typography
          onClick={() => goToClient(params.row)}
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
          }}
        >
          {params.row.renewal_LivEzy === "true" ? "Yes" : "No"}
        </Typography>
      ),
    },
    {
      field: "referredBy_LivEzy",
      headerName: "Referred By",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Referred By" />,
      valueGetter: (params) => {
        return params.row?.referredBy_LivEzy ?? "";
      },
      renderCell: (params) => (
        <Typography
          onClick={() => goToClient(params.row)}
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
          }}
        >
          {params.row.referredBy_LivEzy ?? ""}
        </Typography>
      ),
    },
    {
      field: "buddyClientID_LivEzy",
      headerName: "Buddy ID",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Buddy ID" />,
      valueGetter: (params) => {
        return params.row?.buddyClientID_LivEzy ?? "";
      },
      renderCell: (params) => (
        <Typography
          onClick={() => goToClient(params.row)}
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
          }}
        >
          {params.row.buddyClientID_LivEzy ?? ""}
        </Typography>
      ),
    },
    {
      field: "dietrypref",
      headerName: "Dietary preference",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => {
        return params.row?.dietaryPreferences_LivEzy ?? "";
      },
      renderHeader: () => <HeaderCell label="Dietary preference" />,
      renderCell: (params) => (
        <Typography
          onClick={() => goToClient(params.row)}
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
          }}
        >
          {params.row?.dietaryPreferences_LivEzy ?? ""}
        </Typography>
      ),
    },
    {
      field: "age",
      headerName: "Age",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Age" />,
      valueGetter: (params) => {
        return params.row?.birthDate ? calculateAge(params.row?.birthDate) : "";
      },
      renderCell: (params) => (
        <Typography
          onClick={() => goToClient(params.row)}
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
          }}
        >
          {(params.row?.birthDate && calculateAge(params.row?.birthDate)) || ""}
        </Typography>
      ),
    },
    {
      field: "gender",
      headerName: "gender",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Gender" />,
      valueGetter: (params) => {
        return params.row?.gender ?? "";
      },
      renderCell: (params) => (
        <Typography
          onClick={() => goToClient(params.row)}
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
          }}
        >
          {params.row.gender ?? ""}
        </Typography>
      ),
    },

    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      sortable: false,
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <HeaderCell label="Actions" style={{ textAlign: "center" }} />
      ),
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Questionaire key={params.row.UserUUID} userInfo={params.row} />
          <MoreHorizRounded
            className="cursor-pointer"
            onClick={(e) => handleClick(e, params?.row)}
          />
          <Menu
            id={`item-actions-${params?.row?.UserUUID}`}
            anchorEl={anchorEl}
            open={selectedItem === params?.row && Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              sx={{
                fontSize: "14px",
                fontFamily: "DM Sans",
                fontWeight: 500,
                lineHeight: "20px",
              }}
              onClick={() => {
                console.log("asign: ", params?.row?.UserUUID);
                setAssignAthleteToCoach(true);
              }}
            >
              Assign to Coach
            </MenuItem>
          </Menu>
        </div>
      ),
    },
  ];

  console.log({ data, loading });
  if (loading) {
    return <TrainerDetailsLoading />;
  }

  if (!data) {
    return null;
  }

  return (
    <div
      className={"w-full h-full"}
      style={{
        height: "calc(100vh - 110px)",
      }}
    >
      <div className={"w-full border-b p-3 h-[48px]"}>
        {selectedTrainer?.name && (
          <p className={"font-DMSans text-font18 font-semibold"}>
            {selectedTrainer?.name}'s clients
          </p>
        )}
      </div>
      <div className={"p-2"}>
        {isLivezy ? (
          <Tabs
            defaultValue="active"
            className="w-full"
            onValueChange={(value) => {
              setSelectedTab(value);
            }}
          >
            <TabsList className="w-full justify-between bg-white-pure">
              <div className="flex items-center">
                <TabsTrigger value="active">
                  <div className="font-DMSans font-normal text-[14px]">
                    Active
                    {selectedTab === "active" && (
                      <div
                        style={{
                          height: "3px",
                          width: "100%",
                          borderRadius: "4px",
                          background:
                            "linear-gradient(90deg, #464FEB, #8330E9)",
                        }}
                      />
                    )}
                  </div>
                </TabsTrigger>
                <TabsTrigger value="onboarding">
                  <div className="font-DMSans font-normal text-[14px]">
                    Onboarding
                    {selectedTab === "onboarding" && (
                      <div
                        style={{
                          height: "3px",
                          width: "100%",
                          borderRadius: "4px",
                          background:
                            "linear-gradient(90deg, #464FEB, #8330E9)",
                        }}
                      />
                    )}
                  </div>
                </TabsTrigger>
              </div>
            </TabsList>
            <TabsContent value="active">
              {data?.active && (
                <ShowTable
                  getRowId={(row) => row.UserUUID}
                  data={data?.active}
                  columns={isLivezy ? LivEzyColumns : columns}
                  loading={loading}
                  // key={trainers?.length}
                />
              )}
            </TabsContent>

            <TabsContent value="onboarding">
              {data?.onboarding && (
                <ShowTable
                  getRowId={(row) => row.UserUUID}
                  data={data?.onboarding}
                  columns={isLivezy ? LivEzyColumns : columns}
                  loading={loading}
                  // key={trainers?.length}
                />
              )}
            </TabsContent>
          </Tabs>
        ) : (
          data?.active && (
            <ShowTable
              getRowId={(row) => row.UserUUID}
              data={data?.active}
              columns={columns}
              loading={loading}
              // key={trainers?.length}
            />
          )
        )}
      </div>
      {assignAthleteToCoach && (
        <AssignAthleteToCoach
          isOpen={assignAthleteToCoach}
          setIsOpen={setAssignAthleteToCoach}
          selectedUser={selectedItem}
          getTrainerDashboard={(targetUUID) => {
            reloadSourceAndTargetTrainerData(selectedTrainer?.id, targetUUID);
          }}
        />
      )}
    </div>
  );
};
export default TrainerDetails;

const ShowTable = memo(({ data, columns, loading, getRowId, onCellClick }) => {
  console.log("data from show table", data);
  return (
    <DataGrid
      disableColumnMenu
      getRowId={getRowId}
      rows={data}
      columns={columns}
      onCellClick={onCellClick}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
          },
        },
      }}
      loading={loading}
      slots={{
        loadingOverlay: SpurfitCircularProgress,
        // baseCheckbox: (props) => (
        //   <Checkbox
        //     icon={<CircleUnchecked />}
        //     checkedIcon={<CircleCheckedFilled />}
        //     indeterminateIcon={<RemoveCircleIcon />}
        //     {...props}
        //   />
        // ),
      }}
      pageSizeOptions={[10]}
      disableRowSelectionOnClick
      sx={{
        marginTop: "10px",
        minHeight: "100px",
        border: "none",
        ".MuiDataGrid-columnSeparator": {
          display: "none",
        },
        "&.MuiDataGrid-root": {
          border: "none",
        },
        "&.MuiDataGrid-columnHeaderCheckbox": {
          border: "none",
        },
      }}
    />
  );
});
