import {
  CloseRounded,
  EmojiEmotionsOutlined,
  InfoOutlined,
} from "@mui/icons-material";
import {
  Autocomplete,
  Backdrop,
  Box,
  DialogActions,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
// import "emoji-mart/css/emoji-mart.css";
import EmojiPicker from "emoji-picker-react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SpurfitCircularProgress } from "../Dashboard/dashboard";

const AddTagsDialog = ({
  isOpen,
  setIsOpen,
  previousTags,
  userUUID,
  reloadClientSummary,
}) => {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const [selectedTags, setSelectedTags] = useState([]);
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const updateTags = async () => {
    setLoading(true);
    let response = await axios({
      method: "post",
      url: `${APIURL}CF/api/CoachWeb/Update/updateAtheleteProperties`,
      data: {
        athleteID: userUUID,
        userTags_LivEzy: {
          tags: selectedTags,
        },
      },
    });

    console.log({ response });
    if (response?.status === 200) {
      await reloadClientSummary(userUUID);
    }
    setIsOpen(false);
    setLoading(false);
  };

  useEffect(() => {
    setSelectedTags(previousTags);
  }, [previousTags]);

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isOpen}
      onClick={() => {
        setIsOpen(false);
      }}
    >
      {loading && (
        <div
          className="flex absolute w-full h-full justify-center items-center"
          style={{
            background: "rgba(0,0,0,0.5)",
            zIndex: 100,
          }}
        >
          <SpurfitCircularProgress />
        </div>
      )}
      <Box
        onClick={(e) => {
          e.stopPropagation();
        }}
        sx={{
          borderRadius: "20px",
          minWidth: "400px",
          backgroundColor: "#fff",
        }}
      >
        <div className="add-program-modal-head">
          <p className="add-program-modal-head-title flex items-center gap-2">
            Add Tags{" "}
            <Tooltip
              // open={selectedTags.length < 1}
              title={
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 400,
                    fontFamily: "DM Sans",
                  }}
                  textAlign={"center"}
                >
                  Tap enter to create a tag
                </Typography>
              }
              placement="top"
            >
              <InfoOutlined />
            </Tooltip>
          </p>
          <div
            onClick={() => {
              setIsOpen(false);
            }}
            style={{
              color: "#000",
              cursor: "pointer",
            }}
          >
            <CloseRounded color="#000" />
          </div>
        </div>
        <div className="add-program-modal-body">
          <Autocomplete
            freeSolo
            multiple
            id="tags-outlined"
            options={tags}
            getOptionLabel={(option) => option}
            value={selectedTags || []}
            onChange={(event, newValue) => {
              setSelectedTags(newValue);
            }}
            disabled={selectedTags?.length > 2}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#ebebeb",
                },
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "1.5px solid #4339f2",
                boxShadow: "0px 0px 0px 3px rgba(67, 57, 242, 0.25)",
              },
              borderRadius: "12px",
              width: "300px",
            }}
            limitTags={2}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  placeholder="Advanced Athlete"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "12px",
                      outline: "none",
                      border: "none",
                      paddingRight: "12px",
                    },
                    "& .MuiOutlinedInput-root:focus": {
                      outline: "none",
                      border: "none",
                    },
                  }}
                  disabled={selectedTags?.length > 2}
                  onChange={(event) => {
                    params.inputProps.onChange(event);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      let tags = selectedTags ? selectedTags : [];
                      setSelectedTags([...tags, event.target.value]);
                    }
                  }}
                  onFocus={() => {
                    if (showEmojiPicker) setShowEmojiPicker(false);
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <div
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                      >
                        <EmojiEmotionsOutlined
                          onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                        />
                        {showEmojiPicker && (
                          <EmojiPicker
                            style={{
                              position: "absolute",
                              top: "60px",
                              right: "0",
                              zIndex: 9999,
                            }}
                            onEmojiClick={(emoji, event) => {
                              params.inputProps.onChange({
                                target: {
                                  value: `${params.inputProps.value}${emoji.emoji}`,
                                },
                              });
                              setShowEmojiPicker(false);
                            }}
                          />
                        )}
                      </div>
                    ),
                  }}
                />
              );
            }}
          />

          {/* <EmojiPicker
          onEmojiClick={(event, emojiObject) => {
            let tags = selectedTags ? selectedTags : [];
            setSelectedTags([...tags, emojiObject.emoji]);
          }}
        /> */}
        </div>
        <DialogActions
          sx={{
            justifyContent: "center",
          }}
        >
          <button
            className="button-new"
            disabled={selectedTags && selectedTags?.length < 1}
            onClick={() => {
              updateTags();
            }}
          >
            Update Tags
          </button>
        </DialogActions>
      </Box>
    </Backdrop>
  );
};

export default AddTagsDialog;
