import { Clear } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  TextField,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AddIcon from "../../assets/dashboard/addIconWhite.svg";
import SearchIcon from "../../assets/dashboard/searchIcon.svg";
import Layout from "../../components/layout/Layout";
import { store } from "../../redux/store";
import AddSubscriptionDialog from "./AddSubscriptionDialog";
import Products from "./products";
import Subscription from "./subscription"; // Assuming Subscription component is imported

function Payments() {
  const currencySymbols = {
    KRW: "₩",
    USD: "$",
    CAD: "$",
    INR: "₹",
    AED: "د.إ",
    EUR: "€",
    GBP: "£",
    SGD: "$",
  };
  const APIURL = useSelector((state) => state.auth.apiURL);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [productsData, setProductsData] = useState([]);
  const [showAddProductModal, setShowAddProductModal] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [loadingConnect, setLoadingConnect] = useState(false);
  const [trainerData, setTrainerData] = useState({});
  const [statusData, setStatusData] = useState({});
  const [subscription, setSubscription] = useState({});
  const [subsData, setSubsData] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const { id } = useParams();
  const PRODUCTS = "Products";
  const SUBSCRIPTION = "Subscription";

  const [activeTab, setActiveTab] = useState(PRODUCTS);
  const history = useHistory();

  let activeComponent;

  const saveStatus = async (trainerData) => {
    await axios({
      method: "post",
      url: `${APIURL}CF/api/stripeconnect/savestatus`,
      data: {
        account_id: trainerData?.trainerStripeAccount?.[0]?.acc_id,
        id: trainerData?.trainerStripeAccount?.[0]?.id,
      },
    })
      .then((res) => {
        setStatusData(res?.data?.data);
        if (res?.data?.data?.details_submitted === true) {
          history.replace("/stripeConnect");
        }
      })
      .catch((err) => {
        console.log("error while saving status: ", err);
      });
  };

  const getTrainerData = async () => {
    setLoadingConnect(true);
    try {
      const res = await axios({
        method: "get",
        url: `${APIURL}CF/api/stripeconnect/trainer/${
          store.getState().auth.trainerUUID
        }`,
      });

      setTrainerData(res.data);
      let tempRes = res.data;
      console.log("trainer data from stipe payment page", res?.data);
      setProductsData(res?.data?.trainerProducts);
      let count = 0;
      tempRes?.trainerProducts?.map((product) => {
        let cost =
          currencySymbols[product?.prices?.[0]?.currency.toUpperCase()] +
          "" +
          product?.prices?.[0]?.amount +
          "/" +
          product?.prices?.[0]?.intervalCount +
          " " +
          product?.prices?.[0]?.interval;
        if (cost.includes("null") || cost.includes("undefined")) {
        } else {
          count++;
        }
      });
      setTotalProducts(count);
      setShowOptions(res?.data?.trainerStripeAccount?.[0]?.details_submitted);

      if (id == "success" || id == "refresh") {
        await saveStatus(res.data);
      }

      let subscriptions = [];
      for (const item of res.data.trainerProducts || []) {
        for (const subscription of item.coachSubscriptions) {
          subscriptions.push({
            name: item.prodName,
            status: subscription.status,
            ...subscription,
          });
        }
      }

      console.log("subscription array data", subscriptions);
      setSubsData(subscriptions);
      setSearchTerm("");
      setSubscription(subscriptions);
    } catch (err) {
      console.log("strip connect error: ", err);
    } finally {
      setLoadingConnect(false);
    }
  };

  const trainerConnect = async () => {
    setLoadingConnect(true);
    await axios({
      method: "get",
      url: `${APIURL}CF/api/stripeconnect/connect/${
        store.getState().auth.trainerUUID
      }`,
    })
      .then((res) => {
        window.open(`${res.data.data.url}`, "_self");
      })
      .catch((err) => {
        console.log("strip connect error: ", err);
      });
    setLoadingConnect(false);
  };
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const handleSearch = (event) => {
    let data = [];
    if (activeTab === PRODUCTS) {
      data = trainerData?.trainerProducts;
      let tempSearchTerm = event.target.value;
      setSearchTerm(tempSearchTerm);

      const filteredResults = data.filter((item) =>
        item?.prodName?.toLowerCase().includes(tempSearchTerm.toLowerCase())
      );
      console.log("filtered data", filteredResults);
      setSearchResults(filteredResults);
      setProductsData(filteredResults);
    } else {
      data = subscription;
      let tempSearchTerm = event.target.value;
      setSearchTerm(tempSearchTerm);

      const filteredResults = data.filter((item) =>
        item?.name?.toLowerCase().includes(tempSearchTerm.toLowerCase())
      );
      console.log("filtered data", filteredResults);
      setSearchResults(filteredResults);
      setSubsData(filteredResults);
    }
  };

  const handleClear = () => {
    setSearchTerm("");
    if (activeTab === PRODUCTS) {
      setProductsData(trainerData?.trainerProducts);
    } else {
      setSubsData(subscription);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await getTrainerData();
    };
    fetchData();
  }, []);

  switch (activeTab) {
    case PRODUCTS:
      activeComponent = (
        <Products
          trainerData={productsData}
          statusData={statusData}
          getTrainerData={getTrainerData}
          trainerConnect={trainerConnect}
          showAddProductModal={showAddProductModal}
          setShowAddProductModal={setShowAddProductModal}
        />
      );
      break;
    case SUBSCRIPTION:
      activeComponent = (
        <Subscription
          trainerData={trainerData}
          subscriptions={subsData}
          setSubscriptions={setSubscription}
          getTrainerData={getTrainerData}
        />
      );
      break;
    default:
      activeComponent = null;
  }

  const isRefresh = id === "refresh";
  const isSuccess = id === "success";
  return (
    <Layout>
      <Container
        sx={{
          backgroundColor: "#fff",
          borderRadius: "20px",
          minHeight: "85vh",
          paddingLeft: 0,
          paddingRight: 0,
          "@media (min-width: 600px)": {
            paddingLeft: 0,
            paddingRight: 0,
          },
        }}
        maxWidth="none"
      >
        {(trainerData?.trainerStripeAccount &&
          trainerData?.trainerStripeAccount?.length > 0 &&
          trainerData?.trainerStripeAccount?.[0]?.details_submitted) ||
        statusData?.details_submitted === true ? (
          <>
            {showOptions && (
              <Box
                className="mx-2 flex items-center justify-between"
                sx={{ marginTop: "1.5vh", marginBottom: "8px" }}
              >
                <div className="flex">
                  <Button
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: "8px",
                      color: "black",
                      padding: "3px 10px",
                      marginRight: "12px",
                      fontSize: "14px",
                      textTransform: "none",
                    }}
                    onClick={() => setActiveTab(PRODUCTS)}
                  >
                    <div>
                      <span
                        style={{
                          fontWeight: activeTab === PRODUCTS ? "500" : "400",
                        }}
                      >
                        Products
                      </span>
                      <span
                        style={{
                          background: "#8330E933",
                          padding: "0px 6px",
                          borderRadius: "5px",
                          marginLeft: "5px",
                          fontWeight: "400",
                        }}
                      >
                        {totalProducts || 0}
                      </span>
                    </div>
                    <div
                      style={{
                        marginTop: "2px",
                        height: "2px",
                        width: "100%",
                        background:
                          activeTab === PRODUCTS
                            ? "linear-gradient(90deg, #464FEB 10.42%, #8330E9 100%)"
                            : "white",
                      }}
                    ></div>
                  </Button>
                  <Button
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: "8px",
                      color: "black",
                      padding: "3px 10px",
                      marginRight: "12px",
                      fontSize: "14px",
                      textTransform: "none",
                    }}
                    onClick={() => setActiveTab(SUBSCRIPTION)}
                  >
                    <div>
                      <span
                        style={{
                          fontWeight:
                            activeTab === SUBSCRIPTION ? "500" : "400",
                        }}
                      >
                        Subscription
                      </span>
                      <span
                        style={{
                          background: "#8330E933",
                          padding: "0px 6px",
                          borderRadius: "5px",
                          marginLeft: "5px",
                          fontWeight: "400",
                        }}
                      >
                        {subscription?.length || 0}
                      </span>
                    </div>
                    <div
                      style={{
                        marginTop: "2px",
                        height: "2px",
                        width: "100%",
                        background:
                          activeTab === SUBSCRIPTION
                            ? "linear-gradient(90deg, #464FEB 10.42%, #8330E9 100%)"
                            : "white",
                      }}
                    ></div>
                  </Button>
                </div>
                <div className="flex items-center">
                  {activeTab === PRODUCTS && (
                    <Button
                      sx={{
                        //  width: "142px"
                        height: "32px",
                        alignItems: "center",
                        marginRight: "10px",
                        padding: "16px 10px",
                        fontSize: "12px",
                        background: "#071148",
                        borderRadius: "24px",
                        textTransform: "none",
                        color: "#fff",
                        background:
                          "linear-gradient(90deg, #464FEB 10.42%, #8330E9 100%)",
                      }}
                      onClick={() => setShowAddProductModal(true)}
                    >
                      <img
                        src={AddIcon}
                        alt="click to add product"
                        style={{
                          marginRight: "4px",
                        }}
                      />{" "}
                      Add Product
                    </Button>
                  )}
                  {activeTab === SUBSCRIPTION && (
                    <Button
                      sx={{
                        //  width: "142px",
                        height: "32px",
                        padding: "16px 10px",
                        marginRight: "10px",
                        fontSize: "12px",
                        background: "#071148",
                        borderRadius: "24px",
                        textTransform: "none",
                        color: "#fff",
                        background:
                          "linear-gradient(90deg, #464FEB 10.42%, #8330E9 100%)",
                      }}
                      onClick={() => setShowSubscriptionModal(true)}
                    >
                      <img
                        src={AddIcon}
                        alt="click to create subscription"
                        style={{
                          marginRight: "4px",
                        }}
                      />{" "}
                      Create Subscription
                    </Button>
                  )}
                  <span
                    id={"search-bar"}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <TextField
                      variant="standard"
                      sx={{
                        width: "180px",
                        height: "30px",
                        marginBottom: "auto",
                        marginTop: "auto",
                        mx: "10px",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#F2F2F2",
                        fontSize: "13px",
                        borderRadius: "13px",
                      }}
                      autoComplete="off"
                      placeholder={
                        activeTab === PRODUCTS
                          ? "Search products"
                          : "Search subscriptions"
                      }
                      value={searchTerm}
                      onChange={handleSearch}
                      InputProps={{
                        style: {
                          height: "30px",
                          width: "100%",
                          borderRadius: 50,
                          padding: "2px 0px 0px 0px",
                          background: "#F2F2F2",
                          fontSize: "13px",
                          fontFamily: "DM Sans",
                        },
                        startAdornment: (
                          <IconButton disabled>
                            <img
                              style={{
                                marginLeft: "8px",
                                marginRight: "2px",
                              }}
                              src={SearchIcon}
                            />
                          </IconButton>
                        ),
                        endAdornment: searchTerm && (
                          <IconButton
                            onClick={handleClear}
                            disabled={!searchTerm}
                          >
                            <Clear fontSize="small" />
                          </IconButton>
                        ),
                        disableUnderline: true,
                      }}
                    />
                  </span>
                </div>
              </Box>
            )}
            {activeComponent}
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "calc(100vh - 100px)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {(!id || statusData) && (
                <h1>
                  {isRefresh || statusData?.details_submitted === false
                    ? "Connection not completed. Try Again!"
                    : isSuccess && statusData?.details_submitted === true
                    ? "Successfully Connected"
                    : "Stripe Connect"}
                </h1>
              )}

              {loadingConnect && (
                <CircularProgress size="14px" sx={{ ml: 2 }} />
              )}
            </Box>
            {!(isSuccess && statusData?.details_submitted === true) && (
              <button
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "linear-gradient(to right, #464FEB, #8330E9)",
                  width: "260px",
                  height: "34px",
                  lineHeight: "34px",
                  borderRadius: "78px",
                  color: "#fff",
                  marginTop: "14px",
                  marginBottom: "14px",
                  fontFamily: "DM Sans",
                  fontWeight: "500",
                  fontSize: "12.533px",
                }}
                onClick={trainerConnect}
              >
                Connect To Stripe
              </button>
            )}
          </Box>
        )}
        {showSubscriptionModal && (
          <AddSubscriptionDialog
            open={showSubscriptionModal}
            setOpen={setShowSubscriptionModal}
            product={productsData}
            activeTab={"SUBSCRIPTIONS"}
            getTrainerData={() => getTrainerData()}
          />
        )}
      </Container>
    </Layout>
  );
}

export default Payments;
