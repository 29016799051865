import axios from "axios";
import { StreamChat } from "stream-chat";
// const stream = require("../getStream.config.json");
import { store } from "@/redux/store";
import stream from "../getStream.config.json";
export const chatClient = StreamChat.getInstance(stream.appKey, {
  enableInsights: true,
  enableWSFallback: true,
});
export var unread;

export const connectStreamUser = async (trainer) => {
  try {
    return axios({
      method: "post",
      url: `${store.getState().auth.apiURL}api/generatetoken`,
      data: {
        userUUID: trainer?.id,
        userName: trainer?.name,
      },
    })
      .then(async (res) => {
        localStorage.setItem("token", res.data.token);

        console.log("user token ", res.data.token);
        const user = await chatClient.connectUser(
          {
            id: trainer?.id,
            name: trainer?.name,
            image: trainer?.imageKey,
          },
          res.data.token
        );
        unread = user.me.total_unread_count ? user.me.total_unread_count : 0;
        console.log("user: Connected", user);
        return user;
      })
      .catch((err) => {
        console.log("Network error, please try again", err);
      });
  } catch (err) {
    console.log(err);
  }
};

export const chatInit = async () => {
  let trainerDetails = JSON.parse(
    await localStorage.getItem("trainerCredentials")
  );
  return await getTrainer(trainerDetails);
};

function getTrainer(trainerDetails) {
  return axios({
    method: "get",
    url: `${store.getState().auth.apiURL}CF/api/CoachWeb/Get/oneTrainerProfile`,
    params: {
      coachID: trainerDetails?.password,
    },
  })
    .then(async (res) => {
      return await connectStreamUser(res.data.result);
    })
    .catch((err) => {
      console.log(err);
    });
}

export function getunread() {
  return unread;
}
