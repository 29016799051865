import moment from "moment";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import EditFinishedWorkoutName from "./EditFinishedWorkoutName";
import { convertMinPerKmToSecPerMeter } from "./FitFileAnalyzer/utils";

const GarminWorkoutCard = ({
  data,
  fitData,
  index,
  setCompareSearchWorkout,
  compareSearchWorkout,
  setWorkoutFromCalenderForCompare,
}) => {
  const gradientTextStyle = {
    background: "linear-gradient(90deg, #464FEB 10.42%, #8330E9 100%)",
    WebkitBackgroundClip: "text" /* For Safari */,
    backgroundClip: "text",
    color: "transparent",
    fontWeight: "600",
  };

  const gradientBorderStyle = {
    border: "2px solid #8330E9",
    borderColor: "#8330E9",
    borderRadius: "8px",
  };

  const coachID = useSelector((state) => state.auth.trainerUUID);
  const showInverseTss = useSelector((state) => state.data.showInverseTss);

  let effortLevel = data?.computedMetrics?.effort_level;
  let fatigueLevel = data?.computedMetrics?.fatigue_level;

  let rtss = data?.computedMetrics?.front_block_metrics?.rTSS;
  let tss = data?.computedMetrics?.front_block_metrics?.tss;
  let invrtss = data?.computedMetrics?.front_block_metrics?.invrTSS;

  let ef = data?.computedMetrics?.front_block_metrics?.ef_metric;
  let ngp = data?.computedMetrics?.front_block_metrics?.ngp; // min/km
  let pahr = data?.computedMetrics?.front_block_metrics?.pa_hr; // %
  let elgain = data?.computedMetrics?.front_block_metrics?.elev_gain; // m
  let work = data?.computedMetrics?.front_block_metrics?.work_kj; // kj
  let vi = data?.computedMetrics?.front_block_metrics?.vi_pace; // %

  let minstats = data?.computedMetrics?.min_stats;

  const isAllMetricsUnavailable =
    !ef && !ngp && !pahr && !elgain && !work && !vi;

  const history = useHistory();
  return (
    <div className="w-full rounded-xl shadow-md bg-white-pure pb-4">
      <div className="border-b p-6 flex items-center justify-between">
        <div className="flex items-center gap-2">
          <h2 className="font-DMSans text-font16 font-bold">
            {" "}
            {data?.summary?.activityName}
          </h2>
          <EditFinishedWorkoutName
            name={data?.summary?.activityName}
            data={data}
            index={index}
          />
        </div>
        {data?.fileUrl && (
          <div className="flex gap-3">
            <div
              className="font-DMSans border rounded-xl border-gray-600 px-[12px] py-[6px] text-font14 cursor-pointer"
              onClick={() => {
                setCompareSearchWorkout(true);
                setWorkoutFromCalenderForCompare(data)
              }}
              style={gradientBorderStyle}
            >
              <p style={gradientTextStyle}>Compare</p>
            </div>
            <div
              className="font-DMSans border rounded-xl border-gray-600 px-[12px] py-[6px] text-font14 cursor-pointer"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "linear-gradient(to right, #464FEB, #8330E9)",

                borderRadius: "78px",
                color: "#fff",
                marginLeft: "auto",
                marginTop: "auto",
                marginBottom: "auto",
                fontFamily: "DM Sans",
                fontWeight: 400,
                fontSize: "13px",
                lineHeight: "22px",
              }}
              onClick={() => {
                history.push(`/analyze?url=${data.fileUrl}`, {
                  data: data?.summary,
                  computedMetrics: data?.computedMetrics,
                });
              }}
            >
              <p className="text-white-pure">Analyze</p>
            </div>
          </div>
        )}
      </div>
      <div className="px-4 py-5">
        <div className="rounded-xl p-4 bg-gradient-to-r from-[#F5FEF1] to-[#FFFFFF] border border-[#EBEBEB] relative">
          <div className=" flex max-w-fit font-DMSans text-font16 font-normal text-[#424242] border border-[#EBEBEB] rounded-lg px-4 py-1 bg-white-pure">
            {data?.summary?.activityName}
          </div>
          <p className="font-DMSans text-font14 font-normal absolute top-2 right-2">
            {moment(new Date(data?.summary?.startTimeInSeconds * 1000)).format(
              "DD/MM/YYYY hh:mm A"
            )}
          </p>
          {/* run icon */}
          <div className="flex items-center gap-5 mt-2">
            <img
              width="50"
              height="50"
              src="https://img.icons8.com/ios-filled/50/running.png"
              alt="running"
            />

            <div className="flex items-center gap-2">
              <p className="font-DMSans text-font24 font-bold">
                {formatTime(data.summary?.durationInSeconds)}
              </p>
              <p className="font-DMSans text-font16 font-normal text-[#424242]">
                Total Time
              </p>
            </div>

            <div className="flex items-center gap-2">
              <p className="font-DMSans text-font24 font-bold">
                {formatDistance(data.summary?.distanceInMeters)}
              </p>
              <p className="font-DMSans text-font16 font-normal text-[#424242]">
                kms
              </p>
            </div>

            {fatigueLevel && (
              <div className="flex items-center gap-2">
                <p className="font-DMSans text-font24 font-bold">
                  {fatigueLevel?.toFixed(2)}
                </p>

                <p className="font-DMSans text-font16 font-normal text-[#424242]">
                  Fatigue
                </p>
              </div>
            )}

            {/* {effortLevel && (
              <div className="flex items-center gap-2">
                <p className="font-DMSans text-font24 font-bold">
                  {Math.round(effortLevel)}
                </p>
                <p className="font-DMSans text-font16 font-normal text-[#424242]">
                  Effort
                </p>
              </div>
            )} */}

            {invrtss && (
              <div className="flex items-center gap-2">
                <p className="font-DMSans text-font24 font-bold">
                  {invrtss ? invrtss.toFixed(2) : "-"}
                </p>
                <p className="font-DMSans text-font16 font-normal text-[#424242]">
                  rTSS
                </p>
              </div>
            )}
            {/* {coachID === "ErzsyqqduVWs" && tss && (
              <div className="flex items-center gap-2">
                <p className="font-DMSans text-font24 font-bold">
                  {tss ? tss.toFixed(2) : "-"}
                </p>
                <p className="font-DMSans text-font16 font-normal text-[#424242]">
                  TSS
                </p>
              </div>
            )}

            {coachID === "ErzsyqqduVWs" && invrtss && (
              <div className="flex items-center gap-2">
                <p className="font-DMSans text-font24 font-bold">
                  {invrtss ? invrtss.toFixed(2) : "-"}
                </p>
                <p className="font-DMSans text-font16 font-normal text-[#424242]">
                  invrTSS
                </p>
              </div>
            )} */}
          </div>
        </div>
      </div>
      {!isAllMetricsUnavailable && (
        <div>
          <div className="p-5">
            <div className=" border-t border-b py-4 flex  justify-center">
              <div>
                <MetricSmall
                  label={"Work"}
                  value={work ? work / 100 : ""}
                  unit={"Kj"}
                />
                <MetricSmall
                  label={"Ngp"}
                  value={convertMinPerKmToSecPerMeter(ngp)}
                  unit={"sec/m"}
                />
                <MetricSmall
                  label={"Pa:Hr"}
                  value={pahr ? pahr * 100 : ""}
                  unit={"%"}
                />
              </div>
              <div>
                <MetricSmall label={"El.Gain"} value={elgain} unit={"m"} />
                {/* <MetricSmall label={"IF"} value={0.75} unit={""} /> */}
                <MetricSmall label={"VI"} value={vi} unit={""} />
                <MetricSmall label={"EF"} value={ef} unit={""} />
              </div>
              <div>
                {/* {effortLevel && (
                <MetricSmall
                  label={"Effort"}
                  value={Math.round(effortLevel)}
                  unit={""}
                />
              )}
              {fatigueLevel && (
                <MetricSmall
                  label={"Fatigue"}
                  value={fatigueLevel.toFixed(2)}
                  unit={""}
                />
              )} */}
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="my-4 mx-3 flex flex-col items-center">
        <div className="flex flex-col border rounded-xl px-1">
          <div className="flex items-center gap-4">
            <p className="font-DMSans text-[11px] font-light w-[60px] text-right text-[#424242]">
              {" "}
            </p>
            <div className="h-[20px] w-[1px] bg-[#E5E5E5]"></div>
            {/* <p className="font-DMSans w-[40px] text-center text-[11px] font-light text-[#424242]">
              {"MIN"}
            </p> */}

            <p className="font-DMSans w-[40px] text-center text-[11px] font-light text-[#424242]">
              {"AVG"}
            </p>

            <p className="font-DMSans w-[40px] text-center text-[11px] font-light text-[#424242]">
              {"MAX"}
            </p>
            <div className="h-[20px] w-[1px] bg-[#E5E5E5]"></div>
            <p className="font-DMSans text-[11px] font-light text-[#424242]">
              {""}
            </p>
          </div>
          {/* <MetricLast
            label={"Power"}
            min={0}
            avg={data?.summary?.averagePowerInWatts}
            max={280}
            unit={"W"}
            key={"power"}
          /> */}
          <MetricLast
            label={"Heart Rate"}
            min={minstats?.min_heart_rate?.toFixed(2)}
            avg={data?.summary?.averageHeartRateInBeatsPerMinute ?? "-"}
            max={data?.summary?.maxHeartRateInBeatsPerMinute ?? "-"}
            unit={"bpm"}
            key={"hr"}
          />

          <MetricLast
            label={"Cadence"}
            min={minstats?.min_cadence?.toFixed(2)}
            avg={
              data?.summary?.averageRunCadenceInStepsPerMinute
                ? Math.round(data?.summary?.averageRunCadenceInStepsPerMinute)
                : "-"
            }
            max={
              data?.summary?.maxRunCadenceInStepsPerMinute
                ? data?.summary?.maxRunCadenceInStepsPerMinute
                : "-"
            }
            unit={"rpm"}
            key={"cadence"}
          />

          <MetricLast
            label={"Speed"}
            min={minstats?.min_speed?.toFixed(2)}
            avg={
              data?.summary?.averageSpeedInMetersPerSecond
                ? convertMPSToKPH(data?.summary?.averageSpeedInMetersPerSecond)
                : "-"
            }
            max={
              data?.summary?.maxSpeedInMetersPerSecond
                ? convertMPSToKPH(data?.summary?.maxSpeedInMetersPerSecond)
                : "-"
            }
            unit={"kmph"}
            key={"speed"}
          />

          <MetricLast
            label={"Pace"}
            min={formatTimeDynamically(minstats?.min_pace)}
            avg={formatTimeDynamically(
              data?.summary?.averagePaceInMinutesPerKilometer
            )}
            max={formatTimeDynamically(
              data?.summary?.maxPaceInMinutesPerKilometer
            )}
            unit={"min/km"}
            key={"pace"}
          />
        </div>
      </div>
    </div>
  );
};

export default GarminWorkoutCard;

export const MetricLast = ({ label, min, avg, max, unit }) => {
  return (
    <div className="flex items-center gap-4">
      <p className="font-DMSans text-[12px] font-light w-[60px] text-right text-[#424242]">
        {label}
      </p>
      <div className="h-[20px] w-[1px] bg-[#E5E5E5]"></div>
      {/* <p className="font-DMSans w-[40px] text-center text-[12px] font-normal text-black-pure">
        {min}
      </p> */}

      <p className="font-DMSans w-[40px] text-center text-[12px] font-normal text-black-pure">
        {avg}
      </p>

      <p className="font-DMSans w-[40px] text-center text-[12px] font-normal text-black-pure">
        {max}
      </p>
      <div className="h-[20px] w-[1px] bg-[#E5E5E5]"></div>
      <p className="font-DMSans text-[12px] font-light text-[#424242]">
        {unit}
      </p>
    </div>
  );
};

export const MetricSmall = ({ label, value, unit }) => {
  if (typeof value === "number") value = value.toFixed(2);
  return (
    <div className="flex items-center gap-4">
      <p className="font-DMSans text-[12px] font-light w-[40px] text-right text-[#424242]">
        {label}
      </p>
      <div className="min-h-[20px] w-[1px] bg-[#E5E5E5]"></div>
      <p className="font-DMSans text-[12px] font-normal text-black-pure">
        {value}&nbsp;
        <span className="font-DMSans text-[12px] font-light text-[#424242]">
          {unit}
        </span>
      </p>
    </div>
  );
};

export const Metric = ({ label, value, unit }) => {
  return (
    <div>
      <p className="font-DMSans text-[13px] text-[#424242] font-normal">
        {label}
      </p>
      <p className="font-DMSans text-font16 text-black-pure font-bold">
        {value}{" "}
        {unit && <span className="font-DMSans text-[13px]"> {unit}</span>}
      </p>
    </div>
  );
};

const formatTime = (seconds) => {
  if (!seconds) return null;
  let hours = Math.floor(seconds / 3600);
  let minutes = Math.floor((seconds % 3600) / 60);
  let remainingSeconds = seconds % 60;

  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
};
const formatDistance = (meters) => {
  if (!meters) return null;
  return (meters / 1000).toFixed(2);
};
const formatTimeDynamically = (minutesPerKilometer) => {
  if (!minutesPerKilometer) return "-";
  let minutes = Math.floor(minutesPerKilometer);
  let seconds = Math.round((minutesPerKilometer - minutes) * 60);

  return `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;
};

const convertMPSToKPH = (mps) => {
  let val = mps * 3.6;
  return val.toFixed(2);
};
