import { Dialog, DialogContent, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CloseIcon } from "stream-chat-react";
import {
  BodyPartsList,
  EquipmentList,
  ModalityList,
  SubBodyPartList,
  SubModalityList,
  movementFunctionList,
} from "../../pages/Exercises/AddExerciseModal";
import PrimaryCloseIcon from "@/pages/Exercises/assests/PrimaryCloseIcon";
import AddIcon from "@/pages/Exercises/assests/AddIcon";

import { filter } from "lodash";

const FilterExerciseModal = ({
  openModal,
  setOpenModal,
  selectedFilters,
  setSelectedFilters,
  tempFilter,
  setTempFilter,
  libraryType = 0,
  allTrainerExercises,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [sidebarConst, setSidebarConst] = useState([
    "Type of Exercise",
    "Equipment",
    "Target Area",
  ]);
  const [tag, setTags] = useState([]);

  const handleTags = (tagsArray) => {
    // Create a map to track unique tags by their value property
    const uniqueTagsMap = new Map(tagsArray.map((tag) => [tag.value, tag]));
    // Convert the map values back to an array
    const uniqueTags = Array.from(uniqueTagsMap.values());
    // Update the state with the unique tags
    setTags(uniqueTags);
  };

  useEffect(() => {
    setActiveIndex(0);
    // console.log("typeAllTrainerExercise", allTrainerExercises);
    if (libraryType === 1) {
      if (!sidebarConst?.includes("Tags")) {
        setSidebarConst([...sidebarConst, "Tags"]);
      }
      if (allTrainerExercises?.length > 0) {
        let tagsArray = [];
        allTrainerExercises.map((exercise) => {
          console.log("storeExerciseListModalr", exercise?.name);
          if (exercise?.tags) {
            tagsArray.push({
              label: `${exercise?.tags}`,
              value: `${exercise?.tags}`,
            });
          }
        });
        handleTags(tagsArray);
      }
    } else {
      setSidebarConst(["Type of Exercise", "Equipment", "Target Area"]);
      setTags([]);
    }
  }, [libraryType, allTrainerExercises]);

  const bodayContent = [
    {
      1: {
        key: "modality",
        name: "Modality Type",
        value: ModalityList,
      },
      2: {
        key: "subModality",
        name: "Sub-Modality Type",
        value: SubModalityList,
      },
    },
    {
      1: {
        key: "equipmentList",
        name: "",
        value: EquipmentList,
      },
    },
    {
      1: {
        key: "bodyPart",
        name: "Body Part",
        value: BodyPartsList,
      },
      2: {
        key: "subBodyPart",
        name: "Sub-Body Part",
        value: SubBodyPartList,
      },
    },
    {
      1: {
        key: "tags",
        name: "Tags",
        value: tag,
      },
    },
  ];

  const clearFilter = () => {
    setSelectedFilters([
      {
        name: "modality",
        label: "Modality",
        value: [],
      },
      {
        name: "subModality",
        label: "Sub Modality",
        value: [],
      },
      {
        name: "equipmentList",
        label: "Equipment List",
        value: [],
      },
      {
        name: "bodyPart",
        label: "Body Part",
        value: [],
      },
      {
        name: "subBodyPart",
        label: "Sub Body Part",
        value: [],
      },
      {
        name: "movementFunction",
        label: "Movement Function",
        value: [],
      },
      {
        name: "tags",
        label: "Tags",
        value: [],
      },
    ]);
    setTempFilter([]);
  };

  const addItemToData = (targetId, newItem) => {
    // The id of the object you want to update

    // Find the index of the object with the given 'id'
    console.log("target: ", targetId);
    console.log("target: ", newItem);
    const objectIndex = selectedFilters.findIndex((obj) => {
      console.log("obj: ", obj);
      return obj?.name === targetId.key;
    });

    console.log("get index: ", objectIndex);

    // If the object with the given 'id' is found, update its 'items' array
    if (objectIndex !== -1) {
      // Clone the 'data' state to avoid mutating it directly
      const newData = [...selectedFilters];

      console.log(newData[objectIndex]);

      // Clone the 'items' array of the target object
      const newItemsArray = [...newData[objectIndex].value];

      // Push the new item into the cloned 'items' array
      newItemsArray.push(newItem);

      // Update the 'items' array of the target object
      newData[objectIndex].value = newItemsArray;

      // Update the 'data' state with the modified data
      setSelectedFilters(newData);
    } else {
      const newData = [...selectedFilters];

      // Push the new item into the cloned 'data' array
      newData.push(newItem);

      // Update the 'data' state with the modified data
      setSelectedFilters(newData);
    }
  };

  const removeItemFromData = (targetId, itemToRemove) => {
    // Find the index of the object with the given 'targetId'
    const objectIndex = selectedFilters.findIndex(
      (obj) => obj?.name === targetId.key
    );

    // If the object with the given 'targetId' is found, update its 'value' array
    if (objectIndex !== -1) {
      // Clone the 'selectedFilters' state to avoid mutating it directly
      const newData = [...selectedFilters];

      // Clone the 'value' array of the target object
      const newItemsArray = [...newData[objectIndex].value];

      // Use the filter method to remove the 'itemToRemove' from 'newItemsArray'
      const filteredItemsArray = newItemsArray.filter(
        (item) => item !== itemToRemove
      );

      // Update the 'value' array of the target object with the filtered array
      newData[objectIndex].value = filteredItemsArray;

      // Update the 'selectedFilters' state with the modified data
      setSelectedFilters(newData);
    }
  };
  // selectedFilters.map((data) => {
  //   console.log("filter: ", data.name);
  // });

  return (
    <Dialog
      maxWidth={"lg"}
      open={openModal}
      //   onClose={() => setOpenDialog(!openDialog)}
      PaperProps={{
        sx: {
          minHeight: "50vh",
          maxHeight: "100vh",
          minWidth: "596px",
          borderRadius: "20px",
        },
      }}
    >
      <DialogContent sx={{ padding: 0 }}>
        <div className="add-program-modal-head">
          <p className="add-program-modal-head-title">Filter</p>
          <div
            onClick={() => {
              setOpenModal(false);
            }}
          >
            <CloseIcon />
          </div>
        </div>
        <div className="filter-modal-container">
          <div className="filter-modal-sidebar">
            {sidebarConst.map((data, index) => (
              <div
                onClick={() => setActiveIndex(index)}
                className={
                  index == activeIndex
                    ? " filter-modal-sidebar-item-active"
                    : "filter-modal-sidebar-item"
                }
              >
                <p>{data}</p>
                {index == activeIndex && (
                  <div className="filter-modal-sidebar-item-active-indicator"></div>
                )}
              </div>
            ))}
            <Divider />
            <div
              onClick={() => {
                if (libraryType === 1) {
                  setActiveIndex(5);
                } else {
                  setActiveIndex(4);
                }
              }}
              className={
                libraryType === 1
                  ? 5 == activeIndex
                    ? "filter-modal-sidebar-item-active"
                    : "filter-modal-sidebar-item"
                  : 4 == activeIndex
                  ? "filter-modal-sidebar-item-active"
                  : "filter-modal-sidebar-item"
              }
            >
              <p>Applied Filters</p>
              {libraryType === 0
                ? 4 == activeIndex && (
                    <div className="filter-modal-sidebar-item-active-indicator"></div>
                  )
                : 5 == activeIndex && (
                    <div className="filter-modal-sidebar-item-active-indicator"></div>
                  )}
            </div>
          </div>
          <div className="filter-modal-divider"></div>
          <div className="filter-modal-body">
            <div className="filter-modal-body-container">
              {activeIndex < 4 &&
                Object.values(bodayContent[activeIndex]).map((content) => (
                  <>
                    <p className="filter-modal-body-container-heading">
                      {content.name}
                    </p>
                    <div className="filter-modal-body-container-items">
                      {content.value.map((data) => {
                        let selected = tempFilter.includes(data.value);
                        // console.log("opss: ",data)
                        return (
                          <div
                            onClick={() => {
                              if (selected) {
                                setTempFilter((prev) =>
                                  prev.filter((item) => item !== data.value)
                                );
                                removeItemFromData(content, data.value);
                              } else {
                                setTempFilter((prev) => [...prev, data.value]);
                                addItemToData(content, data.value);
                              }
                            }}
                            className={
                              selected
                                ? "filter-modal-body-container-item-active"
                                : "filter-modal-body-container-item"
                            }
                          >
                            <p className="filter-modal-body-container-item-text">
                              {data.label}
                            </p>
                            {selected ? <PrimaryCloseIcon /> : <AddIcon />}
                          </div>
                        );
                      })}
                    </div>
                  </>
                ))}
              {/* {libraryType === 1 &&
                activeIndex < 5 &&
                Object.values(bodayContent[activeIndex]).map((content) => (
                  <>
                    <p className="filter-modal-body-container-heading">
                      {content.name}
                    </p>
                    <div className="filter-modal-body-container-items">
                      {content.value.map((data) => {
                        let selected = tempFilter.includes(data.value);
                        // console.log("opss: ",data)
                        return (
                          <div
                            onClick={() => {
                              if (selected) {
                                setTempFilter((prev) =>
                                  prev.filter((item) => item !== data.value)
                                );
                                removeItemFromData(content, data.value);
                              } else {
                                setTempFilter((prev) => [...prev, data.value]);
                                addItemToData(content, data.value);
                              }
                            }}
                            className={
                              selected
                                ? "filter-modal-body-container-item-active"
                                : "filter-modal-body-container-item"
                            }
                          >
                            <p className="filter-modal-body-container-item-text">
                              {data.label}
                            </p>
                            {selected ? <PrimaryCloseIcon /> : <AddIcon />}
                          </div>
                        );
                      })}
                    </div>
                  </>
                ))} */}
              {libraryType === 1 &&
                activeIndex == 5 &&
                selectedFilters.map((content) => (
                  <>
                    <p className="filter-modal-body-container-heading">
                      {content?.value?.length > 0 && content?.label}
                    </p>
                    <div className="filter-modal-body-container-items">
                      {content?.value?.map((data) => {
                        // console.log("opss: ",data)
                        return (
                          <div
                            onClick={() => {
                              setTempFilter((prev) =>
                                prev.filter((item) => item !== data)
                              );
                              removeItemFromData({ key: content.name }, data);
                            }}
                            className={
                              "filter-modal-body-container-item-active"
                            }
                          >
                            <p className="filter-modal-body-container-item-text">
                              {data}
                            </p>
                            {<PrimaryCloseIcon />}
                          </div>
                        );
                      })}
                    </div>
                  </>
                ))}
              {libraryType === 0 &&
                activeIndex == 4 &&
                selectedFilters.map((content) => (
                  <>
                    <p className="filter-modal-body-container-heading">
                      {content.value.length > 0 && content.label}
                    </p>
                    <div className="filter-modal-body-container-items">
                      {content.value.map((data) => {
                        // console.log("opss: ",data)
                        return (
                          <div
                            onClick={() => {
                              setTempFilter((prev) =>
                                prev.filter((item) => item !== data)
                              );
                              removeItemFromData({ key: content.name }, data);
                            }}
                            className={
                              "filter-modal-body-container-item-active"
                            }
                          >
                            <p className="filter-modal-body-container-item-text">
                              {data}
                            </p>
                            {<PrimaryCloseIcon />}
                          </div>
                        );
                      })}
                    </div>
                  </>
                ))}
            </div>
            {activeIndex < 4 && (
              <div className="filter-modal-body-actions mt-auto">
                <button className="button-new-ghost" onClick={clearFilter}>
                  Clear All
                </button>
                <button
                  className="button-new"
                  onClick={() => setOpenModal(false)}
                >
                  Apply
                </button>
              </div>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default FilterExerciseModal;
