import React, { useState } from "react";
import { TextField, IconButton } from "@mui/material";
import { Search, Clear } from "@mui/icons-material";
import SearchIcon from "../../assets/dashboard/searchIcon.svg";

function SearchBar({
  request,
  cancel,
  height,
  width,
  bgcol,
  fontSize,
  icon,
  placeholder,
  style,
  iconStyle,
  inputStyle,
  id,
  conatinerStyle,
}) {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    request(event.target.value);
    if (searchTerm === "") {
      console.log("this is from search box");
      cancel();
    }
  };

  const handleClear = () => {
    setSearchTerm("");
    cancel();
  };

  return (
    <span
      id={id || "search-bar"}
      style={{ display: "flex", alignItems: "center", ...conatinerStyle }}
    >
      <TextField
        variant="standard"
        sx={{
          width: width || "auto",
          marginBottom: "auto",
          marginTop: "auto",
          mx: "10px",
          display: "flex",
          alignItems: "center",
          ...style,
        }}
        autoComplete="off"
        placeholder={placeholder || "Search anything"}
        value={searchTerm}
        onChange={handleSearch}
        InputProps={{
          style: {
            height: height,
            width: width || "100%",
            borderRadius: 50,
            padding: height != "40px" ? "2px 0px 0px 0px" : 0,
            background: bgcol,
            fontSize: fontSize,
            fontFamily: "DM Sans",
            ...inputStyle,
          },
          startAdornment: (
            <IconButton disabled>
              <img
                style={{ marginLeft: "8px", marginRight: "2px", ...iconStyle }}
                src={icon ? icon : SearchIcon}
              />
            </IconButton>
          ),
          endAdornment: searchTerm && (
            <IconButton onClick={handleClear} disabled={!searchTerm}>
              <Clear fontSize="small" />
            </IconButton>
          ),
          disableUnderline: true,
        }}
      />
    </span>
  );
}

export default SearchBar;
