import BarChartIcon from '@mui/icons-material/BarChart';
import LineAxisIcon from '@mui/icons-material/LineAxis';
import React, { useRef, useState } from 'react';

const gradient = {
    // Create a gradient with two color stops
    // Adjust colors and positions as needed
    // '0' represents the start, and '1' represents the end
    // You can add more color stops if needed
    // Note: This gradient will be applied to all datasets
    backgroundColor: [
		'rgba(70, 79, 235,1)',
        'rgba(131, 48, 233, 1)'
    ],
	borderColor: 'rgba(70, 79, 235,1)'
};

const barChart = [
    {
        id: "1",
        title: "Heart Rate",
        data: [10, 20, 30, 40, 50],
        backgroundColor: 'rgba(54, 162, 235, 0.5)', // Dataset 1 color
        borderColor: 'rgba(54, 162, 235, 1)', // Dataset 1 border color
    },
    {
        id: "2",
        title: "Speed",
        data: [20, 30, 40, 50, 60],
        backgroundColor: '#FFA740', // Dataset 2 color
        borderColor: '#FFA740', // Dataset 2 border color
    },
    {
        id: "3",
        title: "Weight",
        data: [30, 40, 50, 60, 70],
        backgroundColor: 'rgba(75, 192, 192, 0.3)', // Dataset 3 color
        borderColor: 'rgba(75, 192, 192, 1)', // Dataset 3 border color
    },
];

const chartData = [
	{
	  "id": "1",
	  "title" : "Distance by Week",
	  "type" : "BAR",
	  "icon" :<BarChartIcon/>,
	  "chart" : {
		"data": {
			labels: ['12/05/2024', '12/05/2024', '12/05/2024', '12/05/2024', '12/05/2024'],
			datasets: [
				{
					label: 'Distance by Week',
					data: [10, 20, 30, 40, 50],
					backgroundColor: (context) => {
						const ctx = context.chart.ctx;
						const gradient = ctx.createLinearGradient(0, 0, 0, 200);
						gradient.addColorStop(0, "rgba(70, 79, 235,1)");
						gradient.addColorStop(1, "rgba(131, 48, 233, 1)");
						return gradient;
					},
					borderColor: gradient.borderColor
				},
			],
		},
		"options" :{
			scales: {
				x: {
					type: 'category', // Specify the scale type as 'category' for the x-axis
					grid: {
						display: false // Hide grid lines on x-axis
					}
				},
				y: {
					beginAtZero: true,
					grid: {
						display: false // Hide grid lines on x-axis
					}
				},
			},
		}
	  }
	},
	{
	  "id": "2",
	  "title" : "TSS per week",
	  "type" : "BAR",
	  "icon" :<BarChartIcon/>,
	  "chart" : {
		"data": {
			labels: ['12/05/2024', '12/05/2024', '12/05/2024', '12/05/2024', '12/05/2024'],
			datasets: [
				{
					label: 'TSS per week',
					data: [10, 20, 30, 40, 50],
					backgroundColor: (context) => {
						const ctx = context.chart.ctx;
						const gradient = ctx.createLinearGradient(0, 0, 0, 200);
						gradient.addColorStop(0, "rgba(70, 79, 235,1)");
						gradient.addColorStop(1, "rgba(131, 48, 233, 1)");
						return gradient;
					},
					borderColor: gradient.borderColor,
					borderWidth: 1,
				},
			],
		},
		"options" :{
			scales: {
				x: {
					type: 'category', // Specify the scale type as 'category' for the x-axis
				},
				y: {
					beginAtZero: true,
				},
			},
		}
	  }
	},
	{
	  "id": "3",
	  "title" : "Longest Workout - Distance",
	  "type" : "LINE",
	  "icon" :<LineAxisIcon/>,
	  "chart" : {
		"data": {
			labels: ['12/05/2024', '12/05/2024', '12/05/2024', '12/05/2024', '12/05/2024'],
			datasets: [
				{
					label: '"Longest Workout - Distance',
					data: [10, 20, 30, 40, 50],
					backgroundColor: (context) => {
						const ctx = context.chart.ctx;
						const gradient = ctx.createLinearGradient(0, 0, 0, 200);
						gradient.addColorStop(0, "rgba(70, 79, 235,1)");
						gradient.addColorStop(1, "rgba(131, 48, 233, 1)");
						return gradient;
					},
					borderColor: gradient.borderColor,
					borderWidth: 1,
				},
			],
		},
		"options" :{
			scales: {
				x: {
					type: 'category', // Specify the scale type as 'category' for the x-axis
				},
				y: {
					beginAtZero: true,
				},
			},
		}
	  }
	},
	{
	  "id": "4",
	  "title" : "Longest Workout - Duration",
	  "type" : "LINE",
	  "icon" :<LineAxisIcon/>,
	  "chart" : {
		"data": {
			labels: ['12/05/2024', '12/05/2024', '12/05/2024', '12/05/2024', '12/05/2024'],
			datasets: [
				{
					label: 'Longest Workout - Duration',
					data: [10, 20, 30, 40, 50],
					backgroundColor: (context) => {
						const ctx = context.chart.ctx;
						const gradient = ctx.createLinearGradient(0, 0, 0, 200);
						gradient.addColorStop(0, "rgba(70, 79, 235,1)");
						gradient.addColorStop(1, "rgba(131, 48, 233, 1)");
						return gradient;
					},
					borderColor: gradient.borderColor,
					borderWidth: 1,
				},
			],
		},
		"options" :{
			scales: {
				x: {
					type: 'category', // Specify the scale type as 'category' for the x-axis
				},
				y: {
					beginAtZero: true,
				},
			},
		}
	  }
	},
	// {
	//   "id": "5",
	//   "title" : "Power Profiles",
	//   "type" : "BAR",
	//   "icon" :<BarChartIcon/>,
	//   "chart" : {
	// 	"data": {
	// 		labels: ['12/05/2024', '12/05/2024', '12/05/2024', '12/05/2024', '12/05/2024'],
	// 		datasets: [
	// 			{
	// 				label: 'Power Profiles',
	// 				data: [10, 20, 30, 40, 50],
	// 				backgroundColor: (context) => {
	// 					const ctx = context.chart.ctx;
	// 					const gradient = ctx.createLinearGradient(0, 0, 0, 200);
	// 					gradient.addColorStop(0, "rgba(70, 79, 235,1)");
	// 					gradient.addColorStop(1, "rgba(131, 48, 233, 1)");
	// 					return gradient;
	// 				},
	// 				borderColor: gradient.borderColor
	// 			},
	// 		],
	// 	},
	// 	"options" :{
	// 		scales: {
	// 			x: {
	// 				type: 'category', // Specify the scale type as 'category' for the x-axis
	// 			},
	// 			y: {
	// 				beginAtZero: true,
	// 			},
	// 		},
	// 	}
	//   }
	// },
	{
	  "id": "6",
	  "title" : "Peak Pace",
	  "type" : "LINE",
	  "icon" :<LineAxisIcon/>,
	  "chart" : {
		"data": {
			labels: ['12/05/2024', '12/05/2024', '12/05/2024', '12/05/2024', '12/05/2024'],
			datasets: [
				{
					label: 'Peak Pace',
					data: [10, 20, 30, 40, 50],
					backgroundColor: (context) => {
						const ctx = context.chart.ctx;
						const gradient = ctx.createLinearGradient(0, 0, 0, 200);
						gradient.addColorStop(0, "rgba(70, 79, 235,1)");
						gradient.addColorStop(1, "rgba(131, 48, 233, 1)");
						return gradient;
					},
					borderColor: gradient.borderColor,
					borderWidth: 1,
				},
			],
		},
		"options" :{
			scales: {
				x: {
					type: 'category', 
					grid: {
						display: false // Hide grid lines on x-axis
					}// Specify the scale type as 'category' for the x-axis
				},
				y: {
					beginAtZero: true,
					grid: {
						display: false // Hide grid lines on x-axis
					}
				},
			},
		}
	  }
	},
	{
	  "id": "7",
	  "title" : "Peak HR",
	  "type" : "LINE",
	  "icon" :<LineAxisIcon/>,
	  "chart" : {
		"data": {
			labels: ['12/05/2024', '12/05/2024', '12/05/2024', '12/05/2024', '12/05/2024'],
			datasets: [
				{
					label: 'Peak HR',
					data: [10, 20, 30, 40, 50],
					backgroundColor: (context) => {
						const ctx = context.chart.ctx;
						const gradient = ctx.createLinearGradient(0, 0, 0, 200);
						gradient.addColorStop(0, "rgba(70, 79, 235,1)");
						gradient.addColorStop(1, "rgba(131, 48, 233, 1)");
						return gradient;
					},
					borderColor: gradient.borderColor,
					borderWidth: 1,
				},
			],
		},
		"options" :{
			scales: {
				x: {
					type: 'category', 
					grid: {
						display: false // Hide grid lines on x-axis
					}// Specify the scale type as 'category' for the x-axis
				},
				y: {
					beginAtZero: true,
					grid: {
						display: false // Hide grid lines on x-axis
					}
				},
			},
		}
	  }
	},
	// {
	//   "id": "8",
	//   "title" : "Peak Power",
	//   "type" : "LINE",
	//   "icon" :<LineAxisIcon/>,
	//   "chart" : {
	// 	"data": {
	// 		labels: ['12/05/2024', '12/05/2024', '12/05/2024', '12/05/2024', '12/05/2024'],
	// 		datasets: [
	// 			{
	// 				label: 'Peak Powe',
	// 				data: [10, 20, 30, 40, 50],
	// 				backgroundColor: (context) => {
	// 					const ctx = context.chart.ctx;
	// 					const gradient = ctx.createLinearGradient(0, 0, 0, 200);
	// 					gradient.addColorStop(0, "rgba(70, 79, 235,1)");
	// 					gradient.addColorStop(1, "rgba(131, 48, 233, 1)");
	// 					return gradient;
	// 				},
	// 				borderColor: gradient.borderColor,
	// 				borderWidth: 1,
	// 			},
	// 		],
	// 	},
	// 	"options" :{
	// 		scales: {
	// 			x: {
	// 				type: 'category',
	// 				grid: {
	// 					display: false // Hide grid lines on x-axis
	// 				} // Specify the scale type as 'category' for the x-axis
	// 			},
	// 			y: {
	// 				beginAtZero: true,
	// 				grid: {
	// 					display: false // Hide grid lines on x-axis
	// 				}
	// 			},
	// 		},
	// 	}
	//   }
	// },
	{
	  "id": "9",
	  "title" : "Peak Speed",
	  "type" : "LINE",
	  "icon" : <LineAxisIcon/>,
	  "chart" : {
		"data": {
			labels: ['12/05/2024', '12/05/2024', '12/05/2024', '12/05/2024', '12/05/2024'],
			datasets: [
				{
					label: 'Peak Speed',
					data: [10, 20, 30, 40, 50],
					backgroundColor: (context) => {
						const ctx = context.chart.ctx;
						const gradient = ctx.createLinearGradient(0, 0, 0, 200);
						gradient.addColorStop(0, "rgba(70, 79, 235,1)");
						gradient.addColorStop(1, "rgba(131, 48, 233, 1)");
						return gradient;
					},
					borderColor: gradient.borderColor
				},
			],
		},
		"options" :{
			scales: {
				x: {
					type: 'category', 
					grid: {
						display: false // Hide grid lines on x-axis
					}// Specify the scale type as 'category' for the x-axis
				},
				y: {
					beginAtZero: true,
					grid: {
						display: false // Hide grid lines on x-axis
					}
				},
			},
		}
	  }
	},
{
	"id": "10",
	"title" : "Peak Cadence",
	"type" : "LINE",
	"icon" :<LineAxisIcon/>,
	"chart" : {
		"data": {
			labels: ['12/05/2024', '12/05/2024', '12/05/2024', '12/05/2024', '12/05/2024'],
			datasets: [
				{
					label: '"Peak Cadence',
					data: [10, 20, 30, 40, 50],
					backgroundColor: (context) => {
						const ctx = context.chart.ctx;
						const gradient = ctx.createLinearGradient(0, 0, 0, 200);
						gradient.addColorStop(0, "rgba(70, 79, 235,1)");
						gradient.addColorStop(1, "rgba(131, 48, 233, 1)");
						return gradient;
					},
					borderColor: gradient.borderColor
				},
			],
		},
		"options" :{
			scales: {
				x: {
					type: 'category', 
					grid: {
						display: false // Hide grid lines on x-axis
					}// Specify the scale type as 'category' for the x-axis
				},
				y: {
					beginAtZero: true,
					grid: {
						display: false // Hide grid lines on x-axis
					}
				},
			},
		}
	  }
	},
	// {
	// "id": "11",
	// "title" : "CTL vs FTL",
	// "type" : "BAR",
	// "icon" :<BarChartIcon/>,
	// "chart" : {
	// 	"data": {
	// 		labels: ['12/05/2024', '12/05/2024', '12/05/2024', '12/05/2024', '12/05/2024'],
	// 		datasets: barChart.map(dataset => ({
	// 			label: dataset.title,
	// 			data: dataset.data,
	// 			backgroundColor: (context) => {
	// 				const ctx = context.chart.ctx;
	// 				const gradient = ctx.createLinearGradient(0, 0, 0, 200);
	// 				gradient.addColorStop(0, "rgba(70, 79, 235,1)");
	// 				gradient.addColorStop(1, "rgba(131, 48, 233, 1)");
	// 				return gradient;
	// 			},
	// 			borderColor: gradient.borderColor
	// 		})),
	// 	},
	// 	"options" :{
	// 		scales: {
	// 			x: {
	// 				type: 'category',
	// 				grid: {
	// 					display: false, // Hide x-axis grid lines
	// 				}, // Specify the scale type as 'category' for the x-axis
	// 			},
	// 			y: {
	// 				beginAtZero: true,
	// 				grid: {
	// 					display: false, // Hide x-axis grid lines
	// 				},
	// 			},
	// 		},
	// 	}
	//   }
	// },
	{
	"id": "12",
	"title" : "Peak 20 minute Run Pace",
	"type" : "LINE",
	"icon" :<LineAxisIcon/>,
	"chart" : {
		"data": {
			labels: ['12/05/2024', '12/05/2024', '12/05/2024', '12/05/2024', '12/05/2024'],
			datasets: [
				{
					label: 'Peak 20 minute Run Pace',
					data: [10, 20, 30, 40, 50],
					backgroundColor: (context) => {
						const ctx = context.chart.ctx;
						const gradient = ctx.createLinearGradient(0, 0, 0, 200);
						gradient.addColorStop(0, "rgba(70, 79, 235,1)");
						gradient.addColorStop(1, "rgba(131, 48, 233, 1)");
						return gradient;
					},
					borderColor: gradient.borderColor
				},
			],
		},
		"options" :{
			scales: {
				x: {
					type: 'category',
					grid: {
						display: false, // Hide x-axis grid lines
					}, // Specify the scale type as 'category' for the x-axis
				},
				y: {
					beginAtZero: true,
					grid: {
						display: false, // Hide x-axis grid lines
					},
				},
			},
		}
	  }
	},
	{
	"id": "13",
	"title" : "Peak 20 minute cycling pace",
	"type" : "LINE",
	"icon" :<LineAxisIcon/>,
	"chart": {
		"data": {
			labels: ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6', 'Day 7'],
			datasets: [
				{
					label: 'Dataset 1',
					data: [10, 20, 30, 40, 50, 60, 70],
					backgroundColor: (context) => {
						const ctx = context.chart.ctx;
						const gradient = ctx.createLinearGradient(0, 0, 0, 200);
						gradient.addColorStop(0, "rgba(70, 79, 235,1)");
						gradient.addColorStop(1, "rgba(131, 48, 233, 1)");
						return gradient;
					},
					borderColor: gradient.borderColor,
					borderWidth: 1,
				},
				{
					label: 'Dataset 2',
					data: [20, 30, 40, 50, 60, 70, 80],
					backgroundColor: (context) => {
						const ctx = context.chart.ctx;
						const gradient = ctx.createLinearGradient(0, 0, 0, 200);
						gradient.addColorStop(0, "rgba(70, 79, 235,1)");
						gradient.addColorStop(1, "rgba(131, 48, 233, 1)");
						return gradient;
					},
					borderColor: gradient.borderColor,
					borderWidth: 1,
				},
				// Add more datasets as needed
			],
		},
		"options": {
			scales: {
				x: {
					grid: {
						display: false, // Hide x-axis grid lines
					},
					beginAtZero: true,
				},
				y: {
					grid: {
						display: false, // Hide y-axis grid lines
					},
					beginAtZero: true,
				},
			},
		},
	}}
]

export default chartData;