import React from "react";

import { Input } from "@/components/ui/input";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

const HearRateRangeInput = ({ value, type, setType, onSelect }) => {
  let heartRateRange = [
    { label: "0-100", value: "0-100" },
    { label: "100-120", value: "100-120" },
    { label: "120-140", value: "120-140" },
  ];

  const [selectedValue, setSelectedValue] = React.useState(type || "above");

  return (
    <div>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="above"
        value={selectedValue}
        name="radio-buttons-group"
        onChange={(e) => {
          setSelectedValue(e.target.value);
          onSelect(value, e.target.value);
        }}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <FormControlLabel
          sx={{
            "& .MuiFormControlLabel-label": {
              color: "#424242",
              fontFamily: "DM Sans",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: value === "above" ? 500 : 400,
              lineHeight: "21px",
            },
          }}
          value="above"
          control={<Radio color="royalBlue" />}
          label="Above"
        />
        <FormControlLabel
          sx={{
            "& .MuiFormControlLabel-label": {
              color: "#424242",
              fontFamily: "DM Sans",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: value === "below" ? 500 : 400,
              lineHeight: "21px",
            },
          }}
          value="below"
          control={<Radio color="royalBlue" />}
          label="Below"
        />
      </RadioGroup>
      <div className="flex items-center gap-2">
        <Input
          type="number"
          placeholder="100"
          value={value || ""}
          onChange={(e) => onSelect(e.target.value, selectedValue)}
          className="w-[180px] focus:ring-violet-500 focus-visible:ring-white-pure"
        />
        <span className="text-gray-500">bpm</span>
      </div>
    </div>
  );
};

export default HearRateRangeInput;
