import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Popover,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import React, {
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useState,
} from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useHistory, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ChatIcon from "../../../assets/dashboard/message.svg";
import EditIcon from "../../../assets/workoutBuilder/editIcon.svg";
import Button from "../../../components/Button/button";
import { getCalendarImg } from "../../../components/Calendar/functions";
import SpinnerComponent from "../../../components/layout/spinner";
import "../../../css/GetFont.css";
import "../../LibrariesView/styles.css";
import PlusIcon from "../assests/PlusIcon";
import PlusRoundIcon from "../assests/PlusRoundIcon";
import { AddWorkoutsModal } from "./AddWorkoutsModal";
// var randomstring = require("randomstring");

import {
  CloseRounded,
  ContentPasteGoRounded,
  CopyAllRounded,
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
} from "@mui/icons-material";
import { useSelector } from "react-redux";
import { Chat, Streami18n, enTranslations } from "stream-chat-react";
import { getAllChannelWithProgramID } from "../../../ApiServices/ChannelProgramTracker/Api";
import { CustomAlertContext } from "../../../App";
import { SpurfitCircularProgress } from "../../../components/Dashboard/dashboard";
import AddActivityDialog from "../../../components/DescriptionComp/AddActivityDialog";
import { chatClient } from "../../../utils/chat";
import AddUserIcon from "../assests/AddUserIcon";
import BinIcon from "../assests/BinIcon";
import CalendarIcon from "../assests/CalendarIcon";
import DumblePurple from "../assests/DumblePurple";
import MoreIcon from "../assests/MoreIcon";
import AddCardioDialog from "./AddCardioDialog";
import AddMenu from "./AddMenu";
import AddWeekDialog from "./AddWeekDialog";
import AssignedUsersDialog from "./AssignedUsersDialog";
import AutoMessageDialog from "./AutoMessageDialog";
import DuplicateProgram from "./DuplicateProgram";
import NewAssignProgramModal from "./NewAssignProgramModal";
import ProgramSaveFailAlert from "./ProgramSaveFailAlert";
import { ReplaceWorkout } from "./ReplaceWorkout";
import ShowWorkoutDialog from "./ShowWorkoutDialog";
import SmartBuilder from "./SmartBuilder";
import "./createProgramCF.css";
import {
  RenderWorkoutCardInProgram,
  createWorkout,
  createWorkoutWithID,
  isDaysWorkoutsUpdated,
  isWorkoutsInfoUpdated,
} from "./helpers";
import "./switch.css";
// import { post } from "../../../../../spurfitnodeservice/modules/TaskNotificationsTrainer";

export const ProgramsContext = createContext({
  showModal: "",
  left: "",
  addedWorkouts: [],
  selectedCard: "",
  showCreateWorkoutModal: "",
  setShowModal: () => {},
  setLeft: () => {},
  setAddedWorkouts: () => {},
  setSelectedCard: () => {},
  setShowCreateWorkoutModal: () => {},
  setUnsavedChanges: () => {},
  workouts: [],
});

const i18nInstance = new Streami18n({
  language: "en",
  translationsForLanguage: {
    ...enTranslations,
  },
});

const CreatePrograms = forwardRef(
  (
    {
      programDetails: details,
      setProgramDetails: setDetails,
      duplicateProgram,
      deleteProgram,
      unsavedChanges,
      setUnsavedChanges,
      newProgram,
      setNewProgram,
      addedNewWorkout,
      addedNewWorkoutData,
      getProgramsData,
      row,
      setRows,
      workoutCreatedForPrograms,
      programs,
      setPrograms,
      setActiveProgram,
      getProgramDetails,
      workoutList,
      setWorkoutList,
      createAsTemplate,
      isRightSideOpen,
      setShowUpdateInfoModal,
      setUpdateInfo,
    },
    ref
  ) => {
    const APIURL = useSelector((state) => state.auth.apiURL);
    // console.log("Location", location.state);
    const history = useHistory();
    console.log("piro", details);
    const buttonStyle = {
      color: "#ffffff",
      borderRadius: "10px",
      backgroundColor: "#071148",
      padding: "5px 10px",
      margin: "10px",
    };

    const saveProgramStyle = {
      color: "#ffffff",
      borderRadius: "10px",
      backgroundColor: "#071148",
      padding: "5px 10px",
      margin: "10px",
      float: "right",
    };
    const [canEdit, setCanEdit] = useState(true);
    const [searched, setSearched] = React.useState("");
    const [showModal, setShowModal] = useState(false);
    const [showAddActivityModal, setShowAddActivityModal] = useState(false);
    const [left, setLeft] = useState(false);
    const [column, setColumn] = useState(7);
    const [columnPosition, setColumnPosition] = useState(1);
    const [programDetails, setProgramDetails] = useState({});
    const [showWorkOutBuilder, setShowWorkOutBuilder] = useState(false);
    const [workOutFor, setWorkOutFor] = useState("");
    const [selectedWorkOut, setSelectedWorkOut] = useState([]);
    const [deleteDay, setDeleteDay] = useState(false);
    const [loader, setLoader] = useState(false);
    const [showReplaceWorkout, setShowReplaceWorkout] = useState(false);
    const [replacementData, setReplacementData] = useState({}); //[workoutID, day, index]

    const [weeksLoading, setWeeksLoading] = useState(false);

    const [saveAsTemplate, setSaveAsTemplate] = useState(false);

    const [location, setLocation] = useState(details);

    const [addedWorkouts, setAddedWorkouts] = useState(
      location?.workoutData || {}
    );

    const [showSureDeleteModal, setShowSureDeleteModal] = useState(false);
    const [selectedCard, setSelectedCard] = useState("");
    const [showCreateWorkoutModal, setShowCreateWorkoutModal] = useState(false);
    const [showAssignModal, setShowAssignModal] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

    const [totalWorkouts, setTotalWorkouts] = useState(0);

    const [showDuplicateModal, setShowDuplicateModal] = useState(false);
    const [description, setDescription] = useState("");
    const [programName, setProgramName] = useState([]);
    const [selectedProgram, setSelectedProgram] = useState({});
    const [showAlert, setShowAlert] = useState({
      show: false,
      message: "",
    });
    const [pushLocation, setPushLocation] = useState("");
    const [restArr, setRestArr] = useState([]);
    const [storedData, setStoredData] = useState({});

    const [selectedWeekIndex, setSelectedWeekIndex] = useState([]);
    const [selectedWeek, setSelectedWeek] = useState(-1);

    const [showAddWeekDialog, setShowAddWeekDialog] = useState(false);
    const [showSmartBuilder, setShowSmartBuilder] = useState(false);

    const [workoutsToCreate, setWorkoutsToCreate] = useState({});
    const [showWorkout, setShowWorkout] = useState(false);
    const [selectedToShow, setSelectedToShow] = useState({});

    const [programAssignedUsers, setProgramAssignedUsers] = useState([]);
    const [programAssignedChannels, setProgramAssignedChannels] = useState([]);

    const [showAssignedUsersDialog, setShowAssignedUsersDialog] =
      useState(false);

    const [showAutoMessageDialog, setShowAutoMessageDialog] = useState(false);
    const [daysMessages, setDaysMessages] = useState({});
    const [daysTargets, setDaysTargets] = useState({});
    // this state stores the duplicated workoutIDs corresponding to new workoutIDs
    const [newWorkoutIDsMap, setNewWorkoutIDsMap] = useState({});
    const [reverseMapped, setReverseMapped] = useState({});
    const [newWorkoutIDsArray, setNewWorkoutIDsArray] = useState([]);
    const [showFailAlert, setShowFailAlert] = useState(false);

    const [isTemplate, setIsTemplate] = useState(false);

    const [copiedDay, setCopiedDay] = useState({
      day: null,
      data: null,
    });

    const [expandedWeek, setExpandedWeek] = useState(-1);

    const [orignialWeeks, setOriginalWeeks] = useState(-1);
    const [originalDay, setOriginalDay] = useState(-1);

    const [shouldShowCopyDay, setShouldShowCopyDay] = useState(true);

    const [isSubbordinatesProgram, setIsSubbordinatesProgram] = useState(false);
    const [
      lastWeekIndexForDeletingWorkout,
      setLastWeekIndexForDeletingWorkout,
    ] = useState(0);
    const adminID = useSelector((state) => state.auth?.data?.adminID);
    const coachID = useSelector((state) => state.auth?.trainerUUID);
    const isAdmin = useSelector((state) => state.auth?.data?.isAdmin);

    const { displayAlert } = useContext(CustomAlertContext);

    useEffect(() => {
      if (
        location?.data?.result?.coachID != coachID &&
        location?.data?.result?.coachID != adminID
      ) {
        setIsSubbordinatesProgram(true);
      } else {
        setIsSubbordinatesProgram(false);
      }
    }, [location]);

    useEffect(() => {
      let totalDays = row * 7;
      if (!shouldShowCopyDay && orignialWeeks !== -1 && row > orignialWeeks) {
        if (totalDays > originalDay) {
          setShouldShowCopyDay(true);
        } else {
          setShouldShowCopyDay(false);
        }
      } else if (orignialWeeks === -1 && !shouldShowCopyDay) {
        setShouldShowCopyDay(true);
      } else if (orignialWeeks === row && shouldShowCopyDay) {
        if (totalDays > originalDay) {
          setShouldShowCopyDay(true);
        } else {
          setShouldShowCopyDay(false);
        }
      }

      // return () => {
      //   setShouldShowCopyDay(false);
      // };
    }, [row, orignialWeeks]);

    useEffect(() => {
      // if (
      //   (details?.isTemplate === false &&
      //     !(adminID && details?.data?.result?.coachID == adminID)) ||
      //   (details?.isTemplate &&
      //     details?.data?.result?.coachID == coachID &&
      //     isAdmin)
      // ) {
      //   console.log(
      //     "dita",
      //     adminID,
      //     details?.data?.result?.coachID,
      //     details?.isTemplate
      //   );
      //   setCanEdit(true);
      // } else {
      //   console.log(
      //     "ditaa",
      //     adminID,
      //     details?.data?.result?.coachID,
      //     details?.isTemplate
      //   );
      //   setCanEdit(false);
      // }
      if (
        (!details?.isTemplate || (details?.isTemplate && isAdmin)) &&
        details?.data?.result?.coachID == coachID
      ) {
        console.log(
          "jatin",
          details?.isTemplate,
          details?.data?.result?.coachID,
          coachID,
          isAdmin
        );
        setCanEdit(true);
      } else {
        console.log(
          "jatin",
          details?.isTemplate,
          details?.data?.result?.coachID,
          coachID,
          isAdmin
        );
        setCanEdit(false);
      }
      setIsTemplate(createAsTemplate || details?.isTemplate);
      setLocation(details);
    }, [details]);

    useEffect(() => {
      if (location?.data?.result?.id == 999) {
        setRows(1);
        setAddedWorkouts([]);
        setProgramDetails([]);
      } else {
        try {
          setAddedWorkouts(JSON.parse(JSON.stringify(location?.workoutData)));
        } catch (error) {
          setAddedWorkouts([]);
        }
      }
    }, [location.workoutData]);

    useEffect(() => {
      setDaysMessages(details?.daysMessages);
      setDaysTargets(details?.daysTargets);
    }, [details]);

    const programsStates = useSelector(
      (state) => state.programs.programsStates
    );
    useEffect(() => {
      if (programsStates?.finished && workoutCreatedForPrograms) {
        setSelectedCard(programsStates?.workoutDay);
        setStoredData(programsStates?.location);
        setDetails(programsStates?.location);

        setNewProgram(programsStates?.newProgram);
        setShowModal(true);
        setActiveProgram(programsStates?.location?.data?.result?.id);
        setRows(programsStates?.row);
        setDaysMessages(programsStates?.daysMessages);
        setDaysTargets(programsStates?.daysTargets);
      }
    }, [programsStates?.finished]);

    useEffect(() => {
      getTotalworkouts();
    }, [addedWorkouts]);

    useEffect(() => {
      if (Object.keys(addedWorkouts).length > 0) {
        let totalDays =
          +Object.keys(addedWorkouts)?.[Object.keys(addedWorkouts).length - 1];
        let weeksCount = Math.ceil(parseInt(totalDays) / 7);
        let programDetails = [];
        for (let week = 1; week < weeksCount; week++) {
          for (let i = 1; i <= column; i++) {
            programDetails["Day" + (7 * week + i)] = {};
          }
        }
        console.log("seting bro");
        setProgramDetails(programDetails);
        setOriginalWeeks(weeksCount);
        setOriginalDay(totalDays);
        setRows(weeksCount);
      }

      let temp = {};
      for (let i = row; i <= column; i++) {
        temp["Day" + i] = {};
      }
      setProgramDetails(temp);
      getProgramAssignedUsers();
      getProgramAssignedChannels();
    }, []);

    const unblock = history.block((location, action) => {
      if (
        unsavedChanges &&
        !(
          programsStates?.started && programsStates?.workoutDay === selectedCard
        )
      ) {
        setPushLocation(location.pathname);
        saveCurrentStateToLocalStorage();
        // handleOpen();
        return true;
      } else {
        return true;
      }
    });

    useEffect(() => {
      getTotalworkouts();
      return () => {
        unblock();
      };
    }, [unsavedChanges, history]);

    useEffect(() => {
      const handleBeforeUnload = () => {
        if (unsavedChanges) {
          saveCurrentStateToLocalStorage();
        }
      };

      window.addEventListener("beforeunload", handleBeforeUnload);

      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }, [unsavedChanges, addedWorkouts, daysMessages, daysTargets]);

    const handleDeleteWorkout = (selectedWorkoutId) => {
      console.log("added workouts", addedWorkouts, selectedWorkoutId);
      const tempAddedWorkouts = { ...addedWorkouts };
      console.log("mw", tempAddedWorkouts);
      for (const key in tempAddedWorkouts) {
        if (Object.prototype.hasOwnProperty.call(tempAddedWorkouts, key)) {
          // Search for the object with the given ID in the array
          const index = tempAddedWorkouts[key].findIndex(
            (obj) => obj.id === selectedWorkoutId
          );
          if (index !== -1) {
            // If the object is found, remove it from the array
            tempAddedWorkouts[key].splice(index, 1);
          }
        }
      }
      setAddedWorkouts(tempAddedWorkouts);
      setUnsavedChanges(true);
      setShowWorkout(false);
    };
    const getTotalworkouts = () => {
      const arrayOfKeys = Object.keys(addedWorkouts);
      const arrayOfValues = Object.values(addedWorkouts);

      try {
        let data = 0;
        if (arrayOfValues[0]?.[0] && arrayOfValues[0]?.[0]?.rest == undefined) {
          arrayOfValues[0].map((novalue) => {
            data = data + 1;
          });
        }
        arrayOfKeys.map((novalue) => {
          let key = parseInt(novalue);

          console.log("pokey: ", arrayOfValues[key]?.[0]?.rest);
          if (
            arrayOfValues[key]?.[0] &&
            arrayOfValues[key]?.[0]?.rest == undefined
          ) {
            arrayOfValues[key].map((values) => {
              data = data + 1;
            });
          }
        });

        setTotalWorkouts(data);
        return data;
      } catch (error) {
        console.log("error: ", error);
      }
    };
    const handleOpen = () => {
      // setUnsavedChanges(true);
      setOpenConfirmDialog(true);
    };

    const addWeeks = () => {
      let newRow = row + 1;
      for (let i = 1; i <= column; i++) {
        programDetails["Day" + (7 * (newRow - 1) + i)] = {};
      }
      setRows(row + 1);
      setProgramDetails(programDetails);
    };

    const removeWeeks = (removeWeek) => {
      let continued = false;

      for (let i = 1; i <= column; i++) {
        console.log("removeWeek ", removeWeek, " row:", row);
        console.log("removeWeek1 ", addedWorkouts[7 * (removeWeek - 1) + i]);
        setSelectedWeek(removeWeek);
        // delete auto messages
        if (daysMessages[7 * (removeWeek - 1) + i]) {
          delete daysMessages[7 * (removeWeek - 1) + i];
          setDaysMessages(daysMessages);
        }

        // delete added activities
        if (daysTargets?.[7 * (removeWeek - 1) + i]) {
          delete daysTargets[7 * (removeWeek - 1) + i];
          setDaysTargets(daysTargets);
        }

        if (addedWorkouts[7 * (removeWeek - 1) + i]) {
          console.log("turu");
          setSelectedWeekIndex((prev) => [...prev, i]);
          setOpenConfirmDialog(true);
          continued = true;
        }
      }
      console.log("addedWorkouts ", addedWorkouts, " row: ", row);
      if (!continued && removeWeek == row) {
        delete programDetails["Day" + (7 * (row - 1) + selectedWeekIndex)];
        delete addedWorkouts[7 * (row - 1) + selectedWeekIndex];
        setRows(row - 1);
        getTotalworkouts();
      }

      setProgramDetails(programDetails);
      setAddedWorkouts(addedWorkouts);
    };

    const handleDeleteLastWeekProgramWithSubscribers = async () => {
      setLoader(true);
      try {
        let workoutIDs = [];
        if (location?.data?.result?.workouts?.length > 0) {
          let tempData = location?.data?.result?.workouts;
          console.log("temp data", tempData);
          console.log("last week data", lastWeekIndexForDeletingWorkout);
          let lowerRange = lastWeekIndexForDeletingWorkout * 7 + 1;
          let higherRange = (lastWeekIndexForDeletingWorkout + 1) * 7;
          tempData.map((obj) => {
            if (obj?.dayIdx >= lowerRange && obj?.dayIdx <= higherRange) {
              workoutIDs.push(obj?.id);
            }
          });
        } else {
          let tempData = storedData?.data?.result?.workouts;
          console.log("temp data", tempData);
          console.log("last week data", lastWeekIndexForDeletingWorkout);
          let lowerRange = lastWeekIndexForDeletingWorkout * 7 + 1;
          let higherRange = (lastWeekIndexForDeletingWorkout + 1) * 7;
          tempData.map((obj) => {
            if (obj?.dayIdx >= lowerRange && obj?.dayIdx <= higherRange) {
              workoutIDs.push(obj?.id);
            }
          });
        }
        console.log("workoutIDs", workoutIDs);
        const res = await axios({
          method: "post",
          url: `${APIURL}CF/api/deleteLastWeekProgramWithSubscribers`,
          data: {
            cFProgramsWithWorkoutsIds: workoutIDs,
          },
        });
        getProgramsData();

        setShowAlert({
          show: true,
          message: "Program deleted successfully",
        });

        localStorage.removeItem("programid");

        setTimeout(() => {
          setShowAlert({
            show: false,
            message: "",
          });
        }, 3000);
        setLoader(false);
      } catch (err) {
        console.log(
          "Not able to delete the last week program for the coach with subscribers",
          err
        );
      }
    };

    function uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(12)
      );
    }

    const saveProgram = async () => {
      setLoader(true);
      // if update then update else save

      let value;
      if (Object.entries(storedData)?.length > 0) {
        value = storedData;
      } else {
        value = location;
      }

      if (orignialWeeks !== -1 && !newProgram) {
        transformForUpdates(value, false);
        setLoader(false);
        return;
      }

      let total = getTotalworkouts();
      let data = {
        coachID: coachID,
        name: location?.data?.result?.name,
        description: location?.data?.result?.desc,
        duration: 7 * row,
        programID: location?.data?.result?.id,
        isTemplate: isTemplate ? true : false,
        addedWorkouts: addedWorkouts,
        daysMessages: daysMessages,
        daysTargets: daysTargets,
        creationDate: new Date().toISOString(),
        totalWorkouts: total,
      };

      let res = await axios({
        method: "POST",
        url: `${APIURL}CF/api/saveProgram`,
        data: data,
      });
      setLoader(false);

      console.log("res: ", res);

      if (res?.status == 200) {
        setShowAlert({
          show: true,
          message: "Program saved successfully",
        });

        setTimeout(() => {
          setShowAlert({
            show: false,
            message: "",
          });
        }, 3000);
        setUnsavedChanges(false);
        // getProgramsData();
      } else {
        setShowFailAlert(true);
      }
      if (location?.data?.result?.id == 999) {
        getProgramsData();
        setLoader(false);
      } else {
        getProgramDetails(location?.data?.result?.id);
      }
      return res;
    };

    const programsTransformation = async (location) => {
      try {
        const programs = {
          trainerUUID: location?.data?.result?.coachID,
          name: location?.data?.result?.name,
          description: location?.data?.result?.desc,
          duration: 7 * row,
          creationDate: new Date().toISOString(),
          daysWorkouts: [],
          workouts: [],
          programID:
            location?.data?.result?.id == 999
              ? undefined
              : location?.data?.result?.id,
          isTemplate: isTemplate,
        };

        var maxDay = 0;
        var firstNewWorkoutDay = -1;
        const lastDayIndex = location?.workoutData
          ? Object.entries(location?.workoutData).length
          : null;

        await Promise.all(
          Object.entries(addedWorkouts || {}).map(async (workout) => {
            let workoutDay = parseInt(workout?.[0]);
            if (
              workout?.[0] &&
              workout?.[0] != "" &&
              workout?.[1]?.length > 0
            ) {
              maxDay = Math.max(maxDay, workoutDay);
              if (lastDayIndex != null && workoutDay > lastDayIndex) {
                if (firstNewWorkoutDay == -1) {
                  firstNewWorkoutDay = workoutDay;
                } else {
                  firstNewWorkoutDay = Math.min(firstNewWorkoutDay, workoutDay);
                }
              }

              let Index = programs.workouts.findIndex(
                (workout) => workout?.[0] === workoutDay
              );
              let key = "";
              if (Index != -1) {
                key = programs.workouts[Index].id;
              } else {
                key = uuidv4();
                programs.workouts.push({
                  id: key,
                  dayIdx: workoutDay,
                  daysMessages: daysMessages?.[workoutDay],
                  daysTargets: daysTargets?.[workoutDay],
                });
              }
              for (let index = 0; index < workout[1].length; index++) {
                const item = workout[1][index];
                if (item?.id && typeof item?.id === "string") {
                  programs.daysWorkouts.push({
                    programWithWorkoutsID: key,
                    workoutID: item.id,
                    orderIdx: index,
                  });
                }
              }
            } else if (daysMessages?.[workoutDay]) {
              let key = uuidv4();
              programs.workouts.push({
                id: key,
                dayIdx: workoutDay,
                daysMessages: daysMessages?.[workoutDay],
                daysTargets: daysTargets?.[workoutDay],
              });
            } else if (daysTargets?.[workoutDay]) {
              let key = uuidv4();
              programs.workouts.push({
                id: key,
                dayIdx: workoutDay,
                daysMessages: daysMessages?.[workoutDay],
                daysTargets: daysTargets?.[workoutDay],
              });
            }
          })
        );

        // if program doesn't have workout then check if it has daysMessages or daysTargets
        Object.entries(daysMessages ?? {}).map((workout) => {
          let workoutDay = parseInt(workout?.[0]);
          if (workout?.[0] && workout?.[0] != "") {
            maxDay = Math.max(maxDay, workoutDay);
            if (lastDayIndex != null && workoutDay > lastDayIndex) {
              if (firstNewWorkoutDay == -1) {
                firstNewWorkoutDay = workoutDay;
              } else {
                firstNewWorkoutDay = Math.min(firstNewWorkoutDay, workoutDay);
              }
            }
            let Index = programs.workouts.findIndex(
              (workout) => workout?.dayIdx === workoutDay
            );
            let key = "";
            if (Index == -1) {
              key = uuidv4();
              // already entry doesn't exists for this day
              programs.workouts.push({
                id: key,
                dayIdx: workoutDay,
                daysMessages: daysMessages?.[workoutDay],
                daysTargets: daysTargets?.[workoutDay],
              });
            }
          }
        });

        Object.entries(daysTargets ?? {}).map((workout) => {
          let workoutDay = parseInt(workout?.[0]);
          if (workout?.[0] && workout?.[0] != "") {
            maxDay = Math.max(maxDay, workoutDay);
            if (lastDayIndex != null && workoutDay > lastDayIndex) {
              if (firstNewWorkoutDay == -1) {
                firstNewWorkoutDay = workoutDay;
              } else {
                firstNewWorkoutDay = Math.min(firstNewWorkoutDay, workoutDay);
              }
            }
            let Index = programs.workouts.findIndex(
              (workout) => workout?.dayIdx === workoutDay
            );
            let key = "";
            if (Index == -1) {
              key = uuidv4();
              // already entry doesn't exists for this day
              programs.workouts.push({
                id: key,
                dayIdx: workoutDay,
                daysMessages: daysMessages?.[workoutDay],
                daysTargets: daysTargets?.[workoutDay],
              });
            }
          }
        });

        if (location?.data?.result?.id && firstNewWorkoutDay != -1) {
          programs.updatedDayIdx = firstNewWorkoutDay;
        }

        // loop through all days less than the maximum day
        for (let day = 1; day <= maxDay; day++) {
          let index = programs.workouts.findIndex(
            (workout) => workout.dayIdx === day
          );
          if (index === -1) {
            let key = uuidv4();
            programs.workouts.push({
              id: key,
              dayIdx: day,
            });
          }
        }

        setUnsavedChanges(false);
        return programs;
      } catch (error) {
        console.log("error", error);
        return null;
      }
    };

    const transformForUpdates = async (location, isSilent) => {
      let params = {
        programID: location?.data?.result?.id,
        coachID: coachID,
        name: location?.data?.result?.name,
        description: location?.data?.result?.desc,
        createData: undefined,
        updateWorkouts: undefined,
        updateDaysWorkouts: undefined,
        creationDate: new Date().toISOString(),
        duration: 7 * row,
        updatedDayIdx: row > orignialWeeks ? 7 * orignialWeeks + 1 : undefined,
        isTemplate: isTemplate,
      };

      let oldWorkouts = location?.data?.result?.workouts;

      let updatedWorkouts = [];
      let updatedDaysWorkouts = [];

      oldWorkouts?.map((workout, index) => {
        let dayIdx = workout?.dayIdx;

        let addedWorkout = addedWorkouts?.[dayIdx];

        if (
          isWorkoutsInfoUpdated({
            oldDaysMessages: workout?.daysMessages,
            oldDaysTargets: workout?.daysTargets,
            newDaysMessages: daysMessages?.[dayIdx],
            newDaysTargets: daysTargets?.[dayIdx],
          })
        ) {
          updatedWorkouts.push({
            id: workout?.id,
            dayIdx: workout?.dayIdx,
            daysMessages: daysMessages?.[dayIdx],
            daysTargets: daysTargets?.[dayIdx],
          });
        }

        if (
          isDaysWorkoutsUpdated({
            oldDaysWorkouts: workout?.daysWorkouts,
            newDaysWorkouts: addedWorkout,
          })
        ) {
          if (addedWorkout?.length > 0) {
            addedWorkout.map((item, index) => {
              updatedDaysWorkouts.push({
                id: workout?.daysWorkouts?.[index]?.id,
                programWithWorkoutsID: workout?.id,
                workoutID: item?.id,
                orderIdx: index,
              });
            });
          }
        }
      });

      let newWorkouts = [];
      let newDaysWorkouts = [];

      let dayAfterOriginalWeek = originalDay;

      let newEndDay = dayAfterOriginalWeek;
      await Promise.all(
        Object.entries(addedWorkouts).map(async (workout) => {
          let workoutDay = parseInt(workout?.[0]);
          newEndDay = Math.max(newEndDay, workoutDay);

          if (workoutDay <= dayAfterOriginalWeek) {
            return;
          }

          if (workout?.[0] && workout?.[0] != "" && workout?.[1]?.length > 0) {
            let Index = newWorkouts.findIndex(
              (workout) => workout?.dayIdx === workoutDay
            );
            let key = "";
            if (Index != -1) {
              key = newWorkouts[Index].id;
            } else {
              key = uuidv4();
              newWorkouts.push({
                id: key,
                dayIdx: workoutDay,
                daysMessages: daysMessages?.[workoutDay],
                daysTargets: daysTargets?.[workoutDay],
              });
            }
            for (let index = 0; index < workout[1].length; index++) {
              const item = workout[1][index];
              let workoutToCreateKey = `d:${workoutDay}-idx:${index}`;

              if (workoutsToCreate[workoutToCreateKey]) {
                let workoutInfo = {
                  ...item,
                  id: undefined,
                  workoutContent: undefined,
                  videoLinks: undefined,
                  isCoachWorkout: false,
                };
                let res = await createWorkout({
                  workout: {
                    workoutInfo,
                    workoutContent: item.workoutContent,
                  },
                });

                newDaysWorkouts.push({
                  programWithWorkoutsID: key,
                  workoutID: res?.id,
                  orderIdx: index,
                });
              } else if (item?.id) {
                newDaysWorkouts.push({
                  programWithWorkoutsID: key,
                  workoutID: item.id,
                  orderIdx: index,
                });
              }
            }
          } else if (daysMessages[workoutDay]) {
            let key = uuidv4();
            newWorkouts.push({
              id: key,
              dayIdx: workoutDay,
              daysMessages: daysMessages[workoutDay],
              daysTargets: daysTargets[workoutDay],
            });
          } else if (daysTargets[workoutDay]) {
            let key = uuidv4();
            newWorkouts.push({
              id: key,
              dayIdx: workoutDay,
              daysMessages: daysMessages[workoutDay],
              daysTargets: daysTargets[workoutDay],
            });
          }
        })
      );

      if (newEndDay > dayAfterOriginalWeek) {
        params.updatedDayIdx = dayAfterOriginalWeek + 1;

        for (let day = originalDay + 1; day <= newEndDay; day++) {
          let index = newWorkouts.findIndex(
            (workout) => workout.dayIdx === day
          );
          if (index === -1) {
            let key = uuidv4();
            newWorkouts.push({
              id: key,
              dayIdx: day,
            });
          }
        }
      }

      params.createData = {
        workouts: newWorkouts,
        daysWorkouts: newDaysWorkouts,
      };

      params.updateWorkouts = updatedWorkouts;
      params.updateDaysWorkouts = updatedDaysWorkouts;

      axios({
        method: "post",
        url: `${APIURL}CF/api/updatemodularprogram`,
        data: { ...params },
      })
        .then((res) => {
          console.log("result ", res);
          if (!isSilent) {
            getProgramDetails(location?.data?.result?.id);
            setUnsavedChanges(false);
          }
        })
        .catch((err) => {
          displayAlert({
            type: "error",
            message: "Something went wrong",
          });
          console.log("error ", err);
        });
    };

    const checkifAddedWorkoutsIsEmptyForAllDays = () => {
      let isEmpty = true;
      Object.entries(addedWorkouts).map((workout) => {
        if (workout[1].length > 0) {
          isEmpty = false;
        }
      });
      return isEmpty;
    };

    const saveProgramDetails = async (isSilent) => {
      setNewProgram(false);
      setLoader(true);
      let value;
      if (Object.entries(storedData)?.length > 0) {
        value = storedData;
      } else {
        value = location;
      }

      if (orignialWeeks !== -1 && !newProgram) {
        transformForUpdates(value, isSilent);
        setLoader(false);
        return;
      }

      let dataFromProgs;

      dataFromProgs = await programsTransformation(value);
      if (!dataFromProgs) {
        setLoader(false);
        setShowFailAlert(true);
        return;
      } else if (checkifAddedWorkoutsIsEmptyForAllDays()) {
        setLoader(false);
        setShowFailAlert(true);
        return;
      }
      console.log("dataFromProgs", dataFromProgs);

      try {
        let res = await axios({
          method: "post",
          url: `${APIURL}CF/api/addNewProgram`,
          data: { ...dataFromProgs },
        });

        history.push(`/programsCF`, {
          state: {
            success: true,
          },
        });
        setLoader(false);
        setShowAlert({
          show: true,
          message: "Programs saved successfully",
        });
        setTimeout(() => {
          setShowAlert({
            show: false,
            message: "",
          });
        }, 3000);
        if (!isSilent) {
          if (location?.data?.result?.id == 999) {
            getProgramsData();
            setLoader(false);
          } else {
            getProgramDetails(location?.data?.result?.id);
          }
        }

        localStorage.removeItem("unsavedPrograms");
        return res;
      } catch (err) {
        setLoader(false);
      }
    };

    const saveCurrentStateToLocalStorage = () => {
      let data = {
        location,
        row,
        workoutDay: selectedCard,
        addedWorkouts,
        daysTargets,
        daysMessages,
        newProgram,
      };
      localStorage.setItem("unsavedPrograms", JSON.stringify(data));
    };

    const retriveStateFromLocalStorage = () => {
      let data = JSON.parse(localStorage.getItem("unsavedPrograms"));
      if (data) {
        setProgramDetails(data?.location);
        setRows(data?.row);
        setSelectedCard(data?.workoutDay);
        setAddedWorkouts(data?.addedWorkouts);
        setDaysMessages(data?.daysMessages);
        setDaysTargets(data?.daysTargets);
        setNewProgram(data?.newProgram);
        setUnsavedChanges(true);
      }
      localStorage.removeItem("unsavedPrograms");
    };

    const saveProgramOnEditWorkout = async () => {
      setNewProgram(false);

      let value;
      if (Object.entries(storedData)?.length > 0) {
        value = storedData;
      } else {
        value = location;
      }

      if (orignialWeeks !== -1 && !newProgram) {
        transformForUpdates(value);
        setLoader(false);
        return;
      }

      let dataFromProgs = await programsTransformation(value);

      axios({
        method: "post",
        url: `${APIURL}CF/api/addNewProgram`,
        data: { ...dataFromProgs },
      })
        .then(async (res) => {
          console.log("saved program successfully");
        })
        .catch((err) => {
          console.log("error saving program: ", err);
        });
    };

    React.useImperativeHandle(ref, () => ({
      saveProgramDetails,
      retriveStateFromLocalStorage,
      totalDaysCount,
      row,
    }));

    const removeDayWorkout = (value) => {
      Object.keys(programDetails).map((item) => {
        if (value == item) {
          programDetails[item] = {};
        }
      });
      setProgramDetails(programDetails);
      selectedWorkOut.splice(selectedWorkOut.indexOf(value), 1);
      setSelectedWorkOut(selectedWorkOut);
      setDeleteDay(!deleteDay);
    };

    const { id } = useParams();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const popupId = open ? "simple-popover" : undefined;

    const handleConfirm = () => {
      if (pushLocation != "") {
        unblock();
        setPushLocation("");
        history.push(pushLocation);
      } else {
        setUnsavedChanges(true);
        selectedWeekIndex.map((selectedWeekIndex) => {
          delete programDetails[
            "Day" + (7 * (selectedWeek - 1) + selectedWeekIndex)
          ];
          delete addedWorkouts[7 * (selectedWeek - 1) + selectedWeekIndex];
          // getTotalworkouts();
          removeDataWeek(row - 1);
        });
      }
    };
    const removeDataWeek = (row) => {
      // setUnsavedChanges(true);
      // console.log("selected ",selectedWeekIndex)
      // programDetails["Day" + (7 * (selectedWeek - 1) + selectedWeekIndex)];
      for (let i = selectedWeek; i <= row; i++) {
        for (let index = 1; index <= 7; index++) {
          let data = addedWorkouts[7 * i + index];
          if (data) {
            addedWorkouts[7 * (i - 1) + index] = data;
          } else {
            addedWorkouts[7 * (i - 1) + index] = [];
          }
          setAddedWorkouts(addedWorkouts);
          // if(i==row)
          //   delete addedWorkouts[7 * (selectedWeek) + selectedWeekIndex];
        }
      }
      selectedWeekIndex.map((selectedWeekIndex) => {
        delete addedWorkouts[7 * row + selectedWeekIndex];
      });
      // setAddedWorkouts(addedWorkouts);
      setRows(row);
      getTotalworkouts();
    };

    const handleCancel = () => {
      setOpenConfirmDialog(false);
    };

    const showNewAlert = (message) => {
      setShowAlert({
        show: true,
        message: message,
      });
      setTimeout(() => {
        setShowAlert({
          show: false,
          message: "",
        });
      }, 3000);
    };

    const totalDaysCount = () => {
      console.log("ok trig: ", row);
      return row * 7;
    };

    const getProgramAssignedUsers = async () => {
      await axios({
        method: "get",
        url: `${APIURL}CF/api/getUsersForProgramID`,
        params: {
          programID: location?.data?.result?.id,
        },
      })
        .then((res) => {
          console.log("program assigned users", res?.data?.result);
          setProgramAssignedUsers(res?.data?.result);
          if (!res?.data?.result?.length || res?.data?.result?.length === 0) {
            setOriginalWeeks(-1);
          }
        })
        .catch((err) => {
          console.log("err: ", err);
        });
    };

    const getProgramAssignedChannels = async () => {
      if (location?.data?.result?.id) {
        let res = await getAllChannelWithProgramID(location?.data?.result?.id);
        if (res) {
          setProgramAssignedChannels(res);
        } else {
          setProgramAssignedChannels([]);
        }
      } else {
        setProgramAssignedChannels([]);
      }
    };

    const modifyWorkoutOnPaste = async (prev, dayData, day) => {
      let curDay = prev?.[day] || [];
      if (
        curDay?.length > 0 &&
        (curDay?.[0]?.rest || isThisIDCardio(curDay?.[0]?.id))
      ) {
        curDay = [];
      }
      // don't add duplicate workouts
      let newWorkouts = dayData?.workouts?.filter(
        (workout) =>
          !curDay?.find((curWorkout) => curWorkout?.id === workout?.id)
      );

      let workoutIDsMap = {
        ...newWorkoutIDsMap,
      };
      let workoutIDsArray = [...newWorkoutIDsArray];
      let reverseMappedObj = {
        ...reverseMapped,
      };
      let modifiedWorkouts = [];
      await Promise.all(
        newWorkouts.map(async (workout) => {
          if (workout.id) {
            let newID =
              (workout.id.includes("cardio_workout") ? "cardio_workout_" : "") +
              uuidv4();

            workoutIDsArray.push({
              [workout.id]: newID,
            });

            if (reverseMappedObj[workout.id]) {
              let originalID = reverseMappedObj[workout.id];
              workoutIDsMap[originalID] = [
                ...(newWorkoutIDsMap[originalID] || []),
                newID,
              ];

              reverseMappedObj[newID] = originalID;
            } else {
              workoutIDsMap[workout.id] = [
                ...(newWorkoutIDsMap[workout.id] || []),
                newID,
              ];
              reverseMappedObj[newID] = workout.id;
            }

            modifiedWorkouts.push({
              ...workout,
              id: newID,
            });
            await createWorkoutWithID(workout, newID);
          } else {
            if (workout?.rest) {
            } else {
              modifiedWorkouts.push(workout);
            }
          }
        })
      )
        .then((res) => {
          console.log("Paste workouts res", res);
        })
        .catch((err) => {
          console.log("paste workout error", err);
        });
      setNewWorkoutIDsMap(workoutIDsMap);
      setNewWorkoutIDsArray(workoutIDsArray);
      setReverseMapped(reverseMappedObj);

      if (dayData?.workouts) {
        curDay.push(...modifiedWorkouts);
      }

      let res = { ...prev, [day]: curDay };
      console.log("pasted workout day ", res);
      return res;
    };

    const handlePaste = async (day) => {
      setUnsavedChanges(true);
      // check for workouts, activity, auto messages and targets and add them to the day
      let dayData = copiedDay?.data;
      setWeeksLoading(true);
      if (dayData?.workouts) {
        let modifiedWorkouts = await modifyWorkoutOnPaste(
          addedWorkouts,
          dayData,
          day
        );
        setAddedWorkouts(modifiedWorkouts);
        setWeeksLoading(false);
      }

      if (dayData?.targets) {
        setDaysTargets((prev) => {
          let previous = prev?.[day] || {};
          let res = { ...prev, [day]: dayData?.targets };
          return res;
        });
      }

      if (dayData?.messages) {
        setDaysMessages((prev) => {
          let previous = prev?.[day] || {};
          let res = { ...prev, [day]: dayData?.messages };
          return res;
        });
      }

      setWeeksLoading(false);
    };

    function shiftWeeks(prev, startDay, newWeek) {
      let res = {};
      let i = 1;
      while (i < startDay) {
        res[i] = prev[i];
        i++;
      }
      // while (i <= Object.keys(prev || {}).length + 7) {
      //   res[i] = prev[i - 7];
      //   i++;
      // }
      Object.keys(prev || {}).forEach((key) => {
        if (key >= startDay) {
          res[+key + 7] = prev[key];
        }
      });

      return { ...res, ...newWeek };
    }

    const getModifiedWorkout = async (prev, weekNo) => {
      let startDay = 7 * (weekNo - 1) + 1;
      let endDay = 7 * weekNo;
      let newWeek = {};
      let workoutIDsMap = {
        ...newWorkoutIDsMap,
      };
      let reverseMappedObj = {
        ...reverseMapped,
      };
      let workoutIDsArray = [...newWorkoutIDsArray];
      for (let i = startDay; i <= endDay; i++) {
        let thisDay = [];
        if (addedWorkouts?.[i]?.[0]) {
          await Promise.all(
            addedWorkouts[i]?.map(async (workout) => {
              // Replace forEach with map
              if (workout.id) {
                let newID =
                  (workout.id.includes("cardio_workout")
                    ? "cardio_workout_"
                    : "") + uuidv4();

                workoutIDsArray.push({
                  [workout.id]: newID,
                });

                if (reverseMappedObj[workout.id]) {
                  let originalID = reverseMappedObj[workout.id];
                  workoutIDsMap[originalID] = [
                    ...(newWorkoutIDsMap[originalID] || []),
                    newID,
                  ];

                  reverseMappedObj[newID] = originalID;
                } else {
                  workoutIDsMap[workout.id] = [
                    ...(newWorkoutIDsMap[workout.id] || []),
                    newID,
                  ];
                  reverseMappedObj[newID] = workout.id;
                }
                await createWorkoutWithID(workout, newID);
                thisDay.push({
                  ...workout,
                  id: newID,
                });
              } else {
                thisDay.push(workout);
              }
            })
          );
          newWeek[i] = thisDay;
        } else {
          newWeek[i] = addedWorkouts[i];
        }
      }
      setNewWorkoutIDsMap(workoutIDsMap);
      setNewWorkoutIDsArray(workoutIDsArray);
      setReverseMapped(reverseMappedObj);
      return shiftWeeks(prev, startDay, newWeek);
    };

    const getModifiedWorkoutForAssignedWeek = async (prev, weekNo) => {
      // copy the  selected week after the last week
      let lastWeekNo = Math.ceil(
        parseInt(
          Object.keys(addedWorkouts)?.[Object.keys(addedWorkouts).length - 1]
        ) / 7
      );

      let startDay = 7 * (weekNo - 1) + 1;
      let endDay = 7 * weekNo;
      let newWeek = { ...addedWorkouts };

      let originalStartDay = 7 * lastWeekNo + 1;

      let workoutIDsMap = {
        ...newWorkoutIDsMap,
      };

      let reverseMappedObj = {
        ...reverseMapped,
      };

      let workoutIDsArray = [...newWorkoutIDsArray];

      for (let i = startDay; i <= endDay; i++) {
        let thisDay = [];
        if (addedWorkouts?.[i]?.[0]) {
          await Promise.all(
            addedWorkouts[i]?.map(async (workout) => {
              // Replace forEach with map
              if (workout.id) {
                let newID =
                  (workout.id.includes("cardio_workout")
                    ? "cardio_workout_"
                    : "") + uuidv4();

                workoutIDsArray.push({
                  [workout.id]: newID,
                });

                if (reverseMappedObj[workout.id]) {
                  let originalID = reverseMappedObj[workout.id];
                  workoutIDsMap[originalID] = [
                    ...(newWorkoutIDsMap[originalID] || []),
                    newID,
                  ];

                  reverseMappedObj[newID] = originalID;
                } else {
                  workoutIDsMap[workout.id] = [
                    ...(newWorkoutIDsMap[workout.id] || []),
                    newID,
                  ];
                  reverseMappedObj[newID] = workout.id;
                }
                await createWorkoutWithID(workout, newID);
                thisDay.push({
                  ...workout,
                  id: newID,
                });
              } else {
                thisDay.push(workout);
              }
            })
          );
          newWeek[originalStartDay] = thisDay;
        } else {
          newWeek[originalStartDay] = addedWorkouts[i];
        }
        originalStartDay++;
      }
      setNewWorkoutIDsMap(workoutIDsMap);
      setNewWorkoutIDsArray(workoutIDsArray);
      setReverseMapped(reverseMappedObj);

      return newWeek;
    };

    const copyDaysMesssages = (prev, weekNo) => {
      let startDay = 7 * (weekNo - 1) + 1;
      let endDay = 7 * weekNo;
      let newWeekMessages = {};

      let lastWeekNo = Math.ceil(
        parseInt(
          Object.keys(addedWorkouts)?.[Object.keys(addedWorkouts).length - 1]
        ) / 7
      );

      let originalStartDay = 7 * lastWeekNo + 1;

      if (orignialWeeks !== -1 && weekNo < orignialWeeks) {
        newWeekMessages = { ...daysMessages };
        for (let i = startDay; i <= endDay; i++) {
          newWeekMessages[originalStartDay] = daysMessages?.[i];
          originalStartDay++;
        }
        return newWeekMessages;
      } else {
        for (let i = startDay; i <= endDay; i++) {
          newWeekMessages[i] = daysMessages?.[i];
        }
        // shift weeks
        let res = {};
        let i = 1;
        // sort keys in decending order
        let sortedKeys = Object.keys(prev || {}).sort((a, b) => b - a);

        endDay = sortedKeys[0];
        while (i < startDay) {
          res[i] = prev[i];
          i++;
        }

        while (i <= +endDay + 7) {
          res[i] = prev[i - 7];
          i++;
        }
        return { ...res, ...newWeekMessages };
      }
    };

    const copyDaysTargets = (prev, weekNo) => {
      let startDay = 7 * (weekNo - 1) + 1;
      let endDay = 7 * weekNo;
      let newWeekTargets = {};

      let lastWeekNo = Math.ceil(
        parseInt(
          Object.keys(addedWorkouts)?.[Object.keys(addedWorkouts).length - 1]
        ) / 7
      );

      let originalStartDay = 7 * lastWeekNo + 1;

      if (orignialWeeks !== -1 && weekNo < orignialWeeks) {
        newWeekTargets = { ...daysTargets };
        for (let i = startDay; i <= endDay; i++) {
          newWeekTargets[originalStartDay] = daysTargets?.[i];
          originalStartDay++;
        }
        return newWeekTargets;
      } else {
        for (let i = startDay; i <= endDay; i++) {
          newWeekTargets[i] = daysTargets?.[i];
        }
        // shift weeks
        let res = {};
        let i = 1;
        // sort keys in decending order
        let sortedKeys = Object.keys(prev || {}).sort((a, b) => b - a);

        endDay = sortedKeys[0];
        while (i < startDay) {
          res[i] = prev[i];
          i++;
        }

        while (i <= +endDay + 7) {
          res[i] = prev[i - 7];
          i++;
        }

        return { ...res, ...newWeekTargets };
      }
    };

    const handleDuplicateWeek = async (weekNo) => {
      setWeeksLoading(true);
      setUnsavedChanges(true);

      let modifiedWorkouts;
      if (orignialWeeks !== -1 && weekNo < orignialWeeks) {
        modifiedWorkouts = await getModifiedWorkoutForAssignedWeek(
          addedWorkouts,
          weekNo
        );
      } else {
        modifiedWorkouts = await getModifiedWorkout(addedWorkouts, weekNo);
      }
      setAddedWorkouts(modifiedWorkouts);
      let modifiedDaysMessages = copyDaysMesssages(daysMessages, weekNo);
      setDaysMessages(modifiedDaysMessages);

      let modifiedDaysTargets = copyDaysTargets(daysTargets, weekNo);
      setDaysTargets(modifiedDaysTargets);

      setRows((prev) => prev + 1);

      setWeeksLoading(false);
    };

    const values = {
      showModal,
      left,
      addedWorkouts,
      selectedCard,
      showCreateWorkoutModal,
      setShowModal,
      setLeft,
      setAddedWorkouts,
      setSelectedCard,
      setShowCreateWorkoutModal,
      setUnsavedChanges,
      showWorkout,
      setShowWorkout,
      setSelectedToShow,
      setShowAutoMessageDialog,
      daysMessages,
      setDaysMessages,
      daysTargets,
      setDaysTargets,
      showAddActivityModal,
      setShowAddActivityModal,
      copiedDay,
      setCopiedDay,
      handlePaste,
      workoutList,
      setWorkoutList,
      canEdit,
      setSaveAsTemplate,
      expandedWeek,
      setExpandedWeek,
      weeksLoading,
      setWeeksLoading,
      orignialWeeks,
      setReplacementData,
      shouldShowCopyDay,
      originalDay,
      isSubbordinatesProgram,
    };

    return (
      <div
        style={{
          // maxWidth: "75%",
          // minWidth: "75%",
          width: isRightSideOpen ? "75%" : "100%",
          background: "transparent",
          maxHeight: "calc(100vh - 110px)",
          position: "relative",
        }}
      >
        {weeksLoading && (
          <div
            className="absolute w-full h-full flex flex-col items-center justify-center"
            style={{
              backgroundColor: "rgba(0,0,0,0.5)",
              zIndex: 999,
            }}
          >
            <div
              style={{
                height: "100px",
                width: "200px",
                color: "white",
              }}
              className="flex flex-col items-center justify-center"
            >
              <SpurfitCircularProgress />
              <p>Copying...</p>
            </div>
          </div>
        )}
        {showFailAlert && (
          <ProgramSaveFailAlert
            isOpen={showFailAlert}
            setIsOpen={setShowFailAlert}
          />
        )}
        {showAddActivityModal && (
          <AddActivityDialog
            isOpen={showAddActivityModal}
            setIsOpen={setShowAddActivityModal}
            assignedActivities={daysTargets?.[selectedCard]}
            key={"programs"}
            isUpdate={daysTargets?.[selectedCard] ? true : false}
            onRemoveActivity={() => {
              setDaysTargets((prev) => {
                let res = { ...(prev || {}), [selectedCard]: undefined };
                console.log("activity: ", res);
                return res;
              });
              setUnsavedChanges(true);
            }}
            onSaveActivity={(activity) => {
              setDaysTargets((prev) => {
                let res = { ...(prev || {}), [selectedCard]: activity };
                console.log("activity: ", res);
                return res;
              });
              setUnsavedChanges(true);
            }}
          />
        )}

        {showAutoMessageDialog && (
          <AutoMessageDialog
            isOpen={showAutoMessageDialog}
            setIsOpen={setShowAutoMessageDialog}
            selectedCard={selectedCard}
            programName={programName}
            programId={location?.data?.result?.id}
            addedWorkouts={addedWorkouts}
            setAddedWorkouts={setAddedWorkouts}
            previousMessages={daysMessages?.[selectedCard] || {}}
            setSelectedCard={setSelectedCard}
            daysMessages={daysMessages}
            onDone={(dayMessages, dayNum) => {
              setDaysMessages((prev) => {
                console.log("day messages: ", {
                  ...prev,
                  [dayNum]: dayMessages,
                });
                return { ...prev, [dayNum]: dayMessages };
              });
              setUnsavedChanges(true);
            }}
          />
        )}

        {showWorkout && (
          <ShowWorkoutDialog
            isOpen={showWorkout}
            setIsOpen={setShowWorkout}
            Workout={selectedToShow}
            onEdit={async () => {
              // save workout
              setUnsavedChanges(false);
              await saveProgramOnEditWorkout();
            }}
            onReplacePress={(workout, replaceIndex) => {
              setShowReplaceWorkout(true);
              setShowWorkout(false);
            }}
            addedWworkouts={addedWorkouts}
            setAddedWworkouts={setAddedWorkouts}
            editWorkoutDay={"jatin"}
            handleDeleteWorkout={handleDeleteWorkout}
            programAssignedChannels={programAssignedChannels}
            programAssignedUsers={programAssignedUsers}
            originalDay={originalDay}
            replacementData={replacementData}
          />
        )}

        {showAssignModal && (
          <Chat {...{ client: chatClient, i18nInstance }}>
            <NewAssignProgramModal
              showModal={showAssignModal}
              setShowModal={setShowAssignModal}
              activeProgram={location?.data?.result?.id}
              programName={location?.data?.result?.name}
              key={id}
              showNewAlert={showNewAlert}
              getProgramDetails={getProgramDetails}
            />
          </Chat>
        )}
        {showAssignedUsersDialog && (
          <AssignedUsersDialog
            isOpen={showAssignedUsersDialog}
            setIsOpen={setShowAssignedUsersDialog}
            programName={location?.data?.result?.name}
            day={selectedCard}
            programAssignedUsers={programAssignedUsers}
            setProgramAssignedUsers={setProgramAssignedUsers}
            programAssignedChannels={programAssignedChannels}
            setProgramAssignedChannels={setProgramAssignedChannels}
          />
        )}
        {openConfirmDialog && (
          <CustomModal
            onConfirm={handleConfirm}
            onCancel={handleCancel}
            isOpen={openConfirmDialog}
            close={setOpenConfirmDialog}
          />
        )}
        <div
          style={{
            minHeight: "100%",
            maxHeight: "calc(100vh - 110px)",
            overflowY: "scroll",
            background: "#fff",
            borderBottomRightRadius: 20,
            borderTopRightRadius: 20,
          }}
        >
          <ProgramsContext.Provider value={values}>
            {showModal && (
              <AddWorkoutsModal
                programName={
                  selectedCard === programsStates?.workoutDay
                    ? programsStates?.programName
                    : location?.data?.result?.name
                }
                programId={
                  selectedCard === programsStates?.workoutDay
                    ? programsStates?.programId
                    : location?.data?.result?.id
                }
                programsStates={programsStates}
                setUnsavedChanges={setUnsavedChanges}
                location={location}
                dataToSave={{
                  programs,
                  newProgram,
                  row,
                  daysMessages,
                  daysTargets,
                }}
                orignialWeeks={orignialWeeks}
                selectedDay={selectedCard}
              />
            )}
            {showReplaceWorkout && (
              <ReplaceWorkout
                programName={location?.data?.result?.name}
                programId={location?.data?.result?.id}
                programsStates={programsStates}
                location={location}
                dataToSave={{
                  programs,
                  newProgram,
                  row,
                  daysMessages,
                  daysTargets,
                }}
                showModal={showReplaceWorkout}
                setShowModal={setShowReplaceWorkout}
                replacementData={replacementData}
                onReplaceWorkout={(workout) => {
                  setAddedWorkouts((prev) => {
                    let res = prev || {};
                    let day = replacementData?.workoutDay;
                    let index = replacementData?.workoutIndex;
                    res[day][index] = workout;
                    return res;
                  });
                  setReplacementData(undefined);
                  setUnsavedChanges(true);
                  setShowReplaceWorkout(false);
                }}
              />
            )}

            <div className="programs-container-header">
              <div className="flex">
                <p className="mr-1">{location?.data?.result?.name}</p>
                <div
                  onClick={() => {
                    setShowUpdateInfoModal(true);
                    let tempObj = {};
                    tempObj.id = location?.data?.result?.id;
                    tempObj.name = location?.data?.result?.name;
                    console.log("result ha kya", location?.data?.result);
                    tempObj.desc = location?.data?.result?.description;
                    setUpdateInfo(tempObj);
                  }}
                >
                  <img
                    style={{
                      width: "20px",
                      height: "20px",
                      marginTop: "2px",
                      cursor: "pointer",
                    }}
                    src={EditIcon}
                    alt="Edit icon for editing the program"
                  />
                </div>
              </div>

              {!isSubbordinatesProgram && (
                <div className="programs-container-header-icons">
                  {(programAssignedUsers.length > 0 ||
                    programAssignedChannels?.length > 0) && (
                    <button
                      onClick={() => {
                        if (programAssignedUsers.length > 0) {
                          setShowAssignedUsersDialog(true);
                        }
                      }}
                    >
                      Subscribers
                    </button>
                  )}
                  {!newProgram &&
                    (location?.data?.result?.workouts.length > 0 ||
                      Object.keys(addedWorkouts).length > 0) &&
                    !isTemplate && (
                      <div onClick={() => setShowAssignModal(true)}>
                        <AddUserIcon />
                      </div>
                    )}
                  <div onClick={handleClick}>
                    <MoreIcon />
                  </div>
                  <Popover
                    id={popupId}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    borderRadius={12}
                    style={{
                      padding: "4px",
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: "12px",
                      // boxShadow: "0px 0px 8px 1px rgba(33, 41, 60, 0.1)",
                    }}
                  >
                    {
                      <>
                        {location?.data?.result?.workouts.length > 0 && (
                          <p
                            onClick={() => {
                              setSelectedProgram(location?.data?.result);
                              setProgramName(
                                location?.data?.result?.name + " (Copy)"
                              );
                              setDescription(
                                location?.data?.result?.description
                              );
                              setShowDuplicateModal(true);
                              if (
                                location?.data?.result?.coachID == coachID &&
                                location?.data?.result?.isTemplate
                              ) {
                                setSaveAsTemplate(true);
                              } else {
                                setSaveAsTemplate(false);
                              }
                              handleClose();
                            }}
                            className="more-dialog-items"
                          >
                            Duplicate
                          </p>
                        )}
                        {canEdit && (
                          <p
                            onClick={() => {
                              if (programAssignedUsers.length > 0) {
                                displayAlert({
                                  type: "info",
                                  message:
                                    "Please remove subscribers to delete the program",
                                });
                              } else {
                                deleteProgram(location?.data?.result?.id);
                              }
                              handleClose();
                            }}
                            className="more-dialog-items"
                          >
                            Delete
                          </p>
                        )}
                      </>
                    }

                    {location?.data?.result?.isTemplate && (
                      <p
                        className="more-dialog-items"
                        onClick={() => {
                          setSelectedProgram(location?.data?.result);
                          setProgramName(
                            location?.data?.result?.name + " (Copy)"
                          );
                          setDescription(location?.data?.result?.description);
                          setShowDuplicateModal(true);
                          setSaveAsTemplate(false);
                        }}
                      >
                        Copy to programs
                      </p>
                    )}
                  </Popover>
                </div>
              )}
            </div>
            <div
              className="programs-container-body"
              style={{
                height: "calc(100vh - 210px)",
                padding: 0,
              }}
            >
              <div className="program-conatiner-body-details p-[24px]">
                <div className="program-conatiner-body-details-card">
                  <div>
                    <p className="program-conatiner-body-details-card-title">
                      Total Weeks
                    </p>
                    <p className="program-conatiner-body-details-card-description">
                      {row} Week{row > 1 ? "s" : ""}
                    </p>
                  </div>
                  <CalendarIcon />
                </div>
                <div
                  style={{ background: "#FAFAFF" }}
                  className="program-conatiner-body-details-card "
                >
                  <div>
                    <p className="program-conatiner-body-details-card-title">
                      Total Workouts
                    </p>
                    <p className="program-conatiner-body-details-card-description">
                      {totalWorkouts}
                    </p>
                  </div>
                  <DumblePurple />
                </div>
                {!isSubbordinatesProgram && (
                  <button
                    className="button-new"
                    style={{
                      display:
                        (Object.entries(addedWorkouts)?.length > 0 ||
                          Object.entries(daysMessages || {}).length > 0 ||
                          Object.entries(daysTargets || {}).length > 0) &&
                        unsavedChanges
                          ? "block"
                          : "none",
                    }}
                    onClick={() => saveProgram()}
                    disabled={!unsavedChanges}
                  >
                    {isTemplate || details?.isTemplate
                      ? "Save as template"
                      : "Save program"}
                  </button>
                )}
              </div>
              <div
                style={{
                  height: "1px",
                  opacity: 0.2,
                  width: "100%",
                  background: "#000",
                }}
              ></div>
              <div
                style={{
                  scale: 0.9,
                  height: "100%",
                  overflowY: "scroll",
                  padding: 24,
                }}
              >
                <ColumnMap
                  column={column}
                  row={row}
                  columnPosition={columnPosition}
                  programDetails={programDetails}
                  setShowWorkOutBuilder={setShowWorkOutBuilder}
                  setWorkOutFor={setWorkOutFor}
                  removeDayWorkout={removeDayWorkout}
                  showModal={showModal}
                  setShowModal={setShowModal}
                  setLeft={setLeft}
                  removeWeeks={removeWeeks}
                  getTotalworkouts={getTotalworkouts}
                  handleDuplicateWeek={handleDuplicateWeek}
                  copiedDay={copiedDay}
                  canEdit={canEdit}
                  expandedWeek={expandedWeek}
                  setExpandedWeek={setExpandedWeek}
                  orignialWeeks={orignialWeeks}
                  isSubbordinatesProgram={isSubbordinatesProgram}
                  programAssignedUsers={programAssignedUsers}
                  programAssignedChannels={programAssignedChannels}
                  adminID={adminID}
                  isAdmin={isAdmin}
                  details={details}
                  setShowSureDeleteModal={setShowSureDeleteModal}
                  storedData={storedData}
                  location={location}
                  setLastWeekIndexForDeletingWorkout={
                    setLastWeekIndexForDeletingWorkout
                  }
                />
              </div>
              <DuplicateProgram
                programName={programName}
                setProgramName={setProgramName}
                setDescription={setDescription}
                setShowDuplicateModal={setShowDuplicateModal}
                showDuplicateModal={showDuplicateModal}
                duplicateProgram={duplicateProgram}
                selectedProgram={selectedProgram}
                daysMessages={daysMessages}
                daysTargets={daysTargets}
                canEdit={canEdit}
                saveAsTemplate={saveAsTemplate}
              />
              {!isSubbordinatesProgram && (
                <button
                  className="button-new-outlined"
                  onClick={() => {
                    addWeeks(row);
                  }}
                >
                  <PlusIcon /> Add Week
                </button>
              )}
            </div>
            {showSureDeleteModal && (
              <CustomModal
                onConfirm={handleDeleteLastWeekProgramWithSubscribers}
                isOpen={showSureDeleteModal}
                close={setShowSureDeleteModal}
              />
            )}
            {showAddWeekDialog && (
              <AddWeekDialog
                isOpen={showAddWeekDialog}
                setIsOpen={setShowAddWeekDialog}
                setShowSmartBuilder={setShowSmartBuilder}
                AddWeek={() => addWeeks(row)}
                setUnsavedChanges={setUnsavedChanges}
              />
            )}
            {showSmartBuilder && (
              <SmartBuilder
                isOpen={showSmartBuilder}
                setIsOpen={setShowSmartBuilder}
                setShowWorkOutBuilder={setShowWorkOutBuilder}
                data={addedWorkouts}
                setData={setAddedWorkouts}
                weeks={row}
                programDetails={programDetails}
                setProgramDetails={setProgramDetails}
                addWeeks={addWeeks}
                workoutsToCreate={workoutsToCreate}
                setWorkoutsToCreate={setWorkoutsToCreate}
              />
            )}
            {loader && (
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  background: "#fff",
                  opacity: 0.8,
                  paddingTop: "25%",
                  top: 0,
                }}
              >
                <SpinnerComponent />
              </div>
            )}
          </ProgramsContext.Provider>
        </div>
        {showAlert.show && (
          <div
            style={{
              position: "absolute",
              zindex: 999,
              bottom: "5%",
              display: "flex",
              marginLeft: "23%",
              justifyContent: "center",
            }}
          >
            <Alert severity="success">{showAlert.message}</Alert>
          </div>
        )}
      </div>
    );
  }
);

const ColumnMap = ({
  row,
  column,
  columnPosition,
  programDetails,
  setShowWorkOutBuilder,
  setWorkOutFor,
  removeDayWorkout,
  showModal,
  setShowModal,
  setLeft,
  removeWeeks,
  getTotalworkouts,
  handleDuplicateWeek,
  copiedDay,
  canEdit,
  expandedWeek,
  setExpandedWeek,
  weeksLoading,
  orignialWeeks,
  isSubbordinatesProgram,
  programAssignedChannels,
  programAssignedUsers,
  adminID,
  isAdmin,
  details,
  setShowSureDeleteModal,
  setLastWeekIndexForDeletingWorkout,
}) => {
  const emptyRows = new Array(row).fill([]);
  const emptyCols = new Array(column).fill([]);
  console.log(
    "empty rows",
    emptyRows,
    programAssignedUsers,
    programAssignedChannels
  );
  console.log("can edit", canEdit);
  console.log("details", programDetails);
  console.log("detailss", details);
  console.log("adminId", adminID);
  const programCoachID = details?.data?.result?.coachID;
  return (
    <>
      {emptyRows.map((val, indexRow) => {
        let isNewWeek = indexRow >= orignialWeeks;
        let lastWeekIndex = emptyRows?.length - 1;
        console.log("index row", indexRow);
        return (
          <div className="weeks-conatiner">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
            >
              <div className="flex items-center gap-2">
                <h6 className="weeks-title">Week {indexRow + 1}</h6>
                <div
                  onClick={() => {
                    if (expandedWeek == indexRow) {
                      setExpandedWeek(-1);
                    } else {
                      setExpandedWeek(indexRow);
                    }
                  }}
                >
                  {expandedWeek == indexRow ? (
                    <KeyboardArrowUpRounded />
                  ) : (
                    <KeyboardArrowDownRounded />
                  )}
                </div>
              </div>
              {/* {row > 1 && emptyRows.length == indexRow + 1 && ( */}
              {canEdit && !isSubbordinatesProgram && (
                <>
                  <div className="flex items-center gap-[8px]">
                    {(isNewWeek ||
                      (indexRow == lastWeekIndex &&
                        (programAssignedUsers?.length > 0 ||
                          programAssignedChannels?.length > 0))) &&
                      !(adminID && programCoachID === adminID) && (
                        <div
                          onClick={() => {
                            if (
                              indexRow == lastWeekIndex &&
                              (programAssignedUsers?.length > 0 ||
                                programAssignedChannels?.length > 0) &&
                              programCoachID != adminID
                            ) {
                              setLastWeekIndexForDeletingWorkout(indexRow);
                              setShowSureDeleteModal(true);
                            } else {
                              removeWeeks(indexRow + 1);
                            }
                          }}
                        >
                          <BinIcon />
                        </div>
                      )}
                    {!copiedDay?.day && (
                      <CopyAllRounded
                        sx={{
                          color: "#000",
                          cursor: "pointer",
                          height: "20px",
                          width: "20px",
                        }}
                        onClick={() => {
                          handleDuplicateWeek(indexRow + 1);
                        }}
                      />
                    )}
                  </div>
                </>
              )}
            </div>
            <div
              className="flex showScroll"
              style={{ width: "100%", gap: "16px", overflowX: "scroll" }}
            >
              {weeksLoading?.[indexRow] ? (
                <div
                  style={{
                    height: 100,
                    width: "100%",
                    display: "flex",
                  }}
                >
                  Loading...
                </div>
              ) : (
                emptyCols.map((val, index) => (
                  <ColumnCellMap
                    workOutDay={column * (indexRow + 1 - 1) + (index + 1)}
                    programDetails={programDetails}
                    dayIndex={`Day ${
                      column * (indexRow + 1 - 1) + (index + 1)
                    }`}
                    setShowWorkOutBuilder={setShowWorkOutBuilder}
                    setWorkOutFor={setWorkOutFor}
                    removeDayWorkout={removeDayWorkout}
                    showModal={showModal}
                    setShowModal={setShowModal}
                    setLeft={setLeft}
                    getTotalworkouts={getTotalworkouts}
                    isExpanded={expandedWeek == indexRow}
                    isNewWeek={isNewWeek}
                  />
                ))
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};

const isThisIDCardio = (id) => {
  if (typeof id === "string") {
    return id.includes("cardio_workout");
  }
  return false;
};

const ColumnCellMap = ({
  workOutDay,
  dayIndex,
  getTotalworkouts,
  isExpanded,
  isNewWeek,
}) => {
  const [showAddCardio, setShowAddCardio] = useState(false);
  const {
    showModal,
    left,
    addedWorkouts,
    selectedCard,
    setShowModal,
    setLeft,
    setAddedWorkouts,
    setSelectedCard,
    setUnsavedChanges,
    showWorkout,
    setShowWorkout,
    setSelectedToShow,
    setShowAutoMessageDialog,
    setShowAddActivityModal,
    daysMessages,
    setDaysMessages,
    daysTargets,
    setDaysTargets,
    copiedDay,
    setCopiedDay,
    handlePaste,
    canEdit,
    setReplacementData,
    shouldShowCopyDay,
    orignialWeeks,
    originalDay,
    isSubbordinatesProgram,
  } = useContext(ProgramsContext);

  let currentDayEditable =
    orignialWeeks == -1 || (orignialWeeks !== -1 && workOutDay > originalDay);
  const calendarImg =
    selectedCard === workOutDay
      ? getCalendarImg("partial")
      : getCalendarImg("pending");

  function setModalAlignment(day) {
    if ((day - 1) % 7 >= 4) {
      setLeft(false);
    } else if ((day - 1) % 7 < 4) {
      setLeft(true);
    }
  }

  function handleDragEnd(result) {
    // Check if the drag was successful
    if (!result.destination) {
      return;
    }

    // Get the source and destination indexes
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    // Reorder the workouts in the addedWorkouts object
    const newAddedWorkouts = { ...addedWorkouts };
    const draggedWorkout = newAddedWorkouts[workOutDay][sourceIndex];
    newAddedWorkouts[workOutDay].splice(sourceIndex, 1);
    newAddedWorkouts[workOutDay].splice(destinationIndex, 0, draggedWorkout);

    // Update the state with the new addedWorkouts object
    setAddedWorkouts(newAddedWorkouts);
  }

  const [switchChecked, setSwitchChecked] = useState(
    addedWorkouts?.[workOutDay]?.[0]?.rest
  );

  const [cardioSwitch, setCardioSwitch] = useState(
    isThisIDCardio(addedWorkouts?.[workOutDay]?.[0]?.id)
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElEdit, setAnchorElEdit] = useState(null);

  const handleEditMenuClick = (event) => {
    setAnchorElEdit(event.currentTarget);
  };

  const handleEditMenuClose = () => {
    setAnchorElEdit(null);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (event) => {
    console.log("Option clicked!");
  };

  useEffect(() => {
    setCardioSwitch(isThisIDCardio(addedWorkouts?.[workOutDay]?.[0]?.id));
    setSwitchChecked(addedWorkouts?.[workOutDay]?.[0]?.rest ? true : false);
  }, [addedWorkouts?.[workOutDay]]);

  const colors = ["#FFF0E5", "#E8E6FE", "#EEFDE8"];

  return (
    <div
      className="week-card-conatiner"
      style={{
        position: "relative",
      }}
      onClick={() => {}}
    >
      {copiedDay?.day == workOutDay && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 999,
            background: "rgba(0,0,0,0.8)",
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
            fontSize: "16px",
            fontWeight: "bold",
            fontFamily: "DM Sans",
            borderRadius: "8px",
          }}
        >
          <CloseRounded
            onClick={() =>
              setCopiedDay({
                day: null,
                data: null,
              })
            }
            style={{
              position: "absolute",
              top: 2,
              right: 2,
              color: "#fff",
              cursor: "pointer",
            }}
          />
          Copied
        </div>
      )}
      <>
        <div className="week-card-conatiner-header">
          <p className="week-card-conatiner-header-title">{dayIndex}</p>
          <>
            {canEdit &&
              !isSubbordinatesProgram &&
              (addedWorkouts?.[workOutDay]?.length > 0 ||
                daysMessages?.[workOutDay] ||
                daysTargets?.[workOutDay]) &&
              copiedDay?.day == null && (
                <div className="flex items-center gap-[8px]">
                  {(isNewWeek || currentDayEditable) && (
                    <div
                      style={{
                        height: 16,
                        width: 16,
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        // e.stopPropagation();
                        // remove all workouts, messages, and everything from this day
                        let newAddedWorkouts = { ...addedWorkouts };
                        let newDaysMessages = { ...daysMessages };
                        let newDaysTargets = { ...daysTargets };

                        if (newAddedWorkouts.hasOwnProperty(workOutDay)) {
                          newAddedWorkouts[workOutDay] = [];
                        }

                        if (newDaysMessages.hasOwnProperty(workOutDay)) {
                          delete newDaysMessages[workOutDay];
                        }

                        if (newDaysTargets.hasOwnProperty(workOutDay)) {
                          delete newDaysTargets[workOutDay];
                        }

                        setAddedWorkouts(newAddedWorkouts);
                        setDaysMessages(newDaysMessages);
                        setDaysTargets(newDaysTargets);
                        setUnsavedChanges(true);
                      }}
                    >
                      <BinIcon height={16} width={16} />
                    </div>
                  )}
                  <div
                    style={{
                      height: 16,
                      width: 16,
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedCard(workOutDay);
                      handleEditMenuClick(e);
                      console.log(
                        "onPlusClick",
                        addedWorkouts,
                        addedWorkouts[workOutDay]
                      );
                    }}
                  >
                    <PlusRoundIcon />
                  </div>

                  {shouldShowCopyDay && (
                    <CopyAllRounded
                      sx={{
                        height: 16,
                        width: 16,
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setCopiedDay({
                          day: workOutDay,
                          data: {
                            workouts: addedWorkouts?.[workOutDay],
                            messages: daysMessages?.[workOutDay],
                            targets: daysTargets?.[workOutDay],
                          },
                        });
                      }}
                    />
                  )}
                </div>
              )}
            {(isNewWeek || currentDayEditable) &&
              copiedDay?.day !== null &&
              copiedDay?.day !== workOutDay && (
                <div className="flex items-center gap-[8px]">
                  <ContentPasteGoRounded
                    sx={{
                      height: 16,
                      width: 16,
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handlePaste(workOutDay);
                      console.log("paste", workOutDay);
                    }}
                  />
                </div>
              )}
            <AddMenu
              anchorEl={anchorEl}
              handleClose={handleMenuClose}
              options={[
                ...(isNewWeek || currentDayEditable
                  ? [
                      {
                        label: "Add workout",
                        onClick: () => {
                          setModalAlignment(workOutDay);
                          setSelectedCard(workOutDay);
                          setShowModal(true);
                        },
                      },
                      {
                        label: "Add cardio",
                        onClick: () => {
                          setUnsavedChanges(true);
                          setModalAlignment(workOutDay);
                          setSelectedCard(workOutDay);
                          setShowAddCardio(true);
                          setAddedWorkouts(addedWorkouts);
                          setUnsavedChanges(true);
                          getTotalworkouts();
                        },
                      },
                      {
                        label: "Add rest",
                        onClick: () => {
                          setUnsavedChanges(true);
                          setModalAlignment(workOutDay);
                          setSelectedCard(workOutDay);
                          addedWorkouts[workOutDay] = [
                            {
                              rest: true,
                            },
                          ];
                          setAddedWorkouts(addedWorkouts);
                        },
                      },
                    ]
                  : []),
                {
                  label: "Auto messaging",
                  onClick: () => {
                    setShowAutoMessageDialog(true);
                  },
                },
                {
                  label: "Add habits",
                  onClick: () => {
                    setShowAddActivityModal(true);
                  },
                },
              ]}
            />
            <AddMenu
              anchorEl={anchorElEdit}
              handleClose={handleEditMenuClose}
              options={[
                ...(isNewWeek || currentDayEditable
                  ? [
                      {
                        label:
                          addedWorkouts?.[selectedCard] &&
                          addedWorkouts?.[selectedCard]?.[0]?.rest
                            ? "Add Workout"
                            : (isThisIDCardio(
                                addedWorkouts[selectedCard]?.[0]?.id
                              ) &&
                                addedWorkouts[selectedCard]?.length === 1) ||
                              addedWorkouts[selectedCard]?.length == 0
                            ? "Add Workout"
                            : "Edit Workout",
                        onClick: () => {
                          setModalAlignment(workOutDay);
                          setSelectedCard(workOutDay);
                          setShowModal(true);
                        },
                      },
                      {
                        label: isThisIDCardio(
                          addedWorkouts[selectedCard]?.[0]?.id
                        )
                          ? "Remove cardio"
                          : "Add cardio",
                        onClick: () => {
                          setUnsavedChanges(true);
                          setModalAlignment(workOutDay);
                          setSelectedCard(workOutDay);
                          if (
                            isThisIDCardio(
                              addedWorkouts?.[selectedCard]?.[0]?.id
                            )
                          ) {
                            // remove cardio and keep other workouts
                            let newWorkouts = addedWorkouts[
                              selectedCard
                            ].filter((workout) => !isThisIDCardio(workout?.id));

                            addedWorkouts[selectedCard] = newWorkouts;

                            setAddedWorkouts(addedWorkouts);
                            getTotalworkouts();
                          } else {
                            setShowAddCardio(true);
                            getTotalworkouts();
                          }
                        },
                      },
                      {
                        label: addedWorkouts?.[selectedCard]?.[0]?.rest
                          ? "Remove rest"
                          : "Add rest",
                        onClick: () => {
                          setUnsavedChanges(true);
                          setModalAlignment(workOutDay);
                          setSelectedCard(workOutDay);
                          if (addedWorkouts?.[selectedCard]?.[0]?.rest) {
                            addedWorkouts[selectedCard] = [];
                            setAddedWorkouts(addedWorkouts);
                          } else {
                            addedWorkouts[selectedCard] = [
                              {
                                rest: true,
                              },
                            ];
                            setAddedWorkouts(addedWorkouts);
                          }
                        },
                      },
                    ]
                  : []),
                {
                  label: daysMessages?.[selectedCard]
                    ? "Edit messaging"
                    : "Auto Messaging",
                  onClick: () => {
                    setShowAutoMessageDialog(true);
                  },
                },

                {
                  label: daysTargets?.[selectedCard]
                    ? "Edit habits"
                    : "Add habits",
                  onClick: () => {
                    setShowAddActivityModal(true);
                  },
                },
              ]}
            />
          </>
        </div>
        {/* </div> */}
        {true && (
          <div
            style={{
              overflowY: `${
                addedWorkouts?.[workOutDay]?.length > 9 ? "scroll" : ""
              }`,
              scrollbarWidth: "0",
            }}
            className="week-card-conatiner-draggable"
          >
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="card-list">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{
                      fontSize: "12px",
                      minHeight: "calc(100% + 8px)",
                      display: "flex",
                      justifyContent: "center",
                      gap: "8px",
                      padding: "0 8px 8px 8px",
                      backgroundColor: "transparent",
                    }}
                    className="flex flex-col text-blue-dark font-semibold"
                  >
                    {!switchChecked &&
                      Object.entries(addedWorkouts?.[workOutDay] || [])?.map(
                        (workout, idx) => {
                          console.log("wokrout day", workOutDay);
                          if (
                            isThisIDCardio(workout?.[1]?.id) ||
                            workout?.[1]?.name === "cardio"
                          ) {
                            return;
                          }
                          return (
                            <RenderWorkoutCardInProgram
                              provided={provided}
                              colors={colors}
                              idx={idx}
                              setShowWorkout={(flag) => {
                                setShowWorkout(flag);
                                setReplacementData({
                                  workoutDay: workOutDay,
                                  workoutIndex: idx,
                                });
                              }}
                              setSelectedToShow={setSelectedToShow}
                              workout={workout}
                              isExpanded={isExpanded}
                            />
                          );
                        }
                      )}
                    {addedWorkouts.length > 10 && (
                      <div style={{ width: "100%" }} className="text-center">
                        more...
                      </div>
                    )}

                    {canEdit &&
                      !isSubbordinatesProgram &&
                      !(
                        addedWorkouts?.[workOutDay]?.length > 0 ||
                        daysMessages?.[workOutDay] ||
                        daysTargets?.[workOutDay]
                      ) &&
                      !switchChecked && (
                        <div
                          onClick={(e) => {
                            // setModalAlignment(workOutDay);
                            // setSelectedCard(workOutDay);
                            // setShowModal(true);
                            handleMenuClick(e);
                            setSelectedCard(workOutDay);
                          }}
                          style={{
                            width: "100%",
                            minHeight: "inherit",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "88px",
                          }}
                        >
                          <PlusRoundIcon />
                        </div>
                      )}
                    <AddMenu
                      anchorEl={anchorEl}
                      handleClose={handleMenuClose}
                      options={[
                        ...(isNewWeek || currentDayEditable
                          ? [
                              {
                                label: "Add workout",
                                onClick: () => {
                                  setModalAlignment(workOutDay);
                                  setSelectedCard(workOutDay);
                                  setShowModal(true);
                                },
                              },
                              {
                                label: "Add cardio",
                                onClick: () => {
                                  setUnsavedChanges(true);
                                  setModalAlignment(workOutDay);
                                  setSelectedCard(workOutDay);
                                  setShowAddCardio(true);
                                  setAddedWorkouts(addedWorkouts);
                                  setUnsavedChanges(true);
                                  getTotalworkouts();
                                },
                              },
                              {
                                label: "Add rest",
                                onClick: () => {
                                  setUnsavedChanges(true);
                                  setModalAlignment(workOutDay);
                                  setSelectedCard(workOutDay);
                                  addedWorkouts[workOutDay] = [
                                    {
                                      rest: true,
                                    },
                                  ];
                                  setAddedWorkouts(addedWorkouts);
                                },
                              },
                            ]
                          : []),

                        {
                          label: "Auto messaging",
                          onClick: () => {
                            setShowAutoMessageDialog(true);
                          },
                        },
                        {
                          label: "Add habits",
                          onClick: () => {
                            setShowAddActivityModal(true);
                          },
                        },
                      ]}
                    />
                    {console.log("data: ", addedWorkouts?.[workOutDay])}
                    {switchChecked && (
                      <div className="week-card-conatiner-draggable-rest">
                        <p>Rest</p>
                      </div>
                    )}

                    {(cardioSwitch ||
                      addedWorkouts?.[workOutDay]?.[0]?.name === "cardio") && (
                      <Tooltip
                        title={addedWorkouts?.[workOutDay]?.[0]?.description}
                      >
                        <div
                          style={{
                            padding: "12px 8px",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                            borderRadius: "8px",
                            alignItems: "center",
                            background: colors[0],
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setSelectedCard(workOutDay);
                            setShowAddCardio(true);
                          }}
                        >
                          <img
                            src={
                              "https://img.icons8.com/ios/100/737373/running--v1.png"
                            }
                            style={{
                              width: "24px",
                              height: "24px",
                            }}
                          />
                          <p className="week-card-conatiner-draggable-text">
                            Cardio
                          </p>
                        </div>
                      </Tooltip>
                    )}

                    {daysMessages?.[workOutDay] && (
                      <div
                        style={{
                          padding: "12px 8px",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                          borderRadius: "8px",
                          alignItems: "center",
                          background: colors[1 % 3],
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setSelectedCard(workOutDay);
                          setShowAutoMessageDialog(true);
                        }}
                      >
                        <img
                          src={ChatIcon}
                          style={{
                            width: "24px",
                            height: "24px",
                          }}
                        />
                        <p className="week-card-conatiner-draggable-text">
                          {daysMessages?.[workOutDay]?.name ?? "Auto message"}
                        </p>
                      </div>
                    )}

                    {daysTargets?.[workOutDay] && (
                      <div
                        style={{
                          padding: "12px 8px",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                          borderRadius: "8px",
                          alignItems: "center",
                          background: colors[2 % 3],
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setSelectedCard(workOutDay);
                          setShowAddActivityModal(true);
                        }}
                      >
                        <img
                          width="24px"
                          height="24px"
                          src="https://img.icons8.com/ios/50/functional-training.png"
                          alt="functional-training"
                        />
                        <p className="week-card-conatiner-draggable-text">
                          {daysTargets?.[workOutDay]?.name ?? "Habits"}
                        </p>
                      </div>
                    )}

                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        )}
      </>
      {showAddCardio && (
        <AddCardioDialog
          key={workOutDay}
          isOpen={showAddCardio}
          setIsOpen={setShowAddCardio}
          day={selectedCard}
          info={addedWorkouts?.[selectedCard]?.[0]}
          onAddCardio={(cardio) => {
            // console.log("is cardio added",cardio);
            // setAddedWorkouts((prev) => {
            //   let res = prev || {};
            //   let previousWorkouts = prev?.[selectedCard] || [];
            //   if (addedWorkouts?.[selectedCard]?.[0]?.rest) {

            //     res[selectedCard] = [cardio];
            //   } else {
            //     res[selectedCard] = [cardio, ...previousWorkouts];
            //   }
            //   console.log("cardio: ", res);
            //   return res;
            // });
            // setUnsavedChanges(true);
            setAddedWorkouts((prev) => {
              const newWorkouts = Array.isArray(prev?.[selectedCard])
                ? [...prev[selectedCard]]
                : [];

              if (newWorkouts.length > 0 && newWorkouts[0].rest) {
                newWorkouts[0] = cardio;
              } else {
                newWorkouts.unshift(cardio);
              }

              const updatedState = { ...prev, [selectedCard]: newWorkouts };
              console.log("cardio:", updatedState);
              return updatedState;
            });
            setUnsavedChanges(true);
          }}
        />
      )}

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        zindex="999999"
      />
    </div>
  );
};

function CustomModal({ onConfirm, onCancel, isOpen, close }) {
  const handleClose = (value) => {
    close(false);
    if (value === "no") {
      onCancel();
    } else {
      onConfirm();
    }
  };

  return (
    <Dialog open={isOpen} onClose={() => handleClose("cancel")}>
      <DialogTitle>Unsaved Changes</DialogTitle>
      <DialogContent>
        <p>You have added workouts Are you sure you want to delete them?</p>
      </DialogContent>
      <DialogActions>
        <button
          className="button-new"
          style={{
            width: 100,
          }}
          onClick={() => handleClose("yes")}
          color="primary"
        >
          Yes
        </button>
        <Button
          style={{ color: "#b21e42" }}
          onClick={() => handleClose("no")}
          color="primary"
        >
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreatePrograms;

const cardioWorkout = {
  id: "cardio_workout",
  name: "cardio",
  numRounds: 1,
  runTime: 0,
  restTime: 0,
  visibility: false,
  benchMark: false,
  expectedCalories: 0,
  equipment: null,
  bodyPart: null,
  description: null,
  instructions: null,
  coachID: "PXqGTqMrfblJ",
  workoutType: "NOSCORE",
  workoutKind: null,
  tag: null,
  dateCreated: null,
  workoutContent: {
    rounds: [
      {
        round: {
          name: "",
          numRounds: 1,
          measurements: [
            {
              measurement: {
                movementName: "Do cardio of your choice",
              },
            },
          ],
        },
      },
    ],
  },
  videoLinks: null,
  isCoachWorkout: true,
};
