import { Skeleton } from "@/components/ui/skeleton";
import CalendarIcon from "@/pages/library/assests/CalendarIcon";
import DumblePurple from "@/pages/library/assests/DumblePurple";

const ProgramHeaderLoadingUI = () => {
  return (
    <div className="w-full border-b">
      <div className="w-full h-[52px] flex items-center justify-between px-3 border-b">
        <div className="flex items-center gap-3">
          <div className="font-DMSans text-font16">
            <Skeleton className="h-[25px] w-176 rounded-md" />
          </div>
        </div>
        <div className="flex items-center gap-3">
          <Skeleton className="h-[20px] w-80 rounded-md" />
        </div>
      </div>

      <div className="program-conatiner-body-details p-[24px]">
        <div className="program-conatiner-body-details-card">
          <div>
            <p className="program-conatiner-body-details-card-title">
              Total Weeks
            </p>
            <div className="program-conatiner-body-details-card-description">
              <Skeleton className="h-[20px] w-80 rounded-md" />
            </div>
          </div>
          <CalendarIcon />
        </div>
        <div
          style={{ background: "#FAFAFF" }}
          className="program-conatiner-body-details-card "
        >
          <div>
            <p className="program-conatiner-body-details-card-title">
              Total Workouts
            </p>
            <div className="program-conatiner-body-details-card-description">
              <Skeleton className="h-[20px] w-80 rounded-md" />
            </div>
          </div>
          <DumblePurple />
        </div>
      </div>
    </div>
  );
};

export default ProgramHeaderLoadingUI;
