import { store } from "@/redux/store";
import axios from "axios";

export const getGarminConnect = async (atheleteID) => {
  let res = await axios({
    method: "POST",
    url: `${store.getState().auth.apiURL}CF/api/garmin/startGarminAuth`,
    data: {
      atheleteID: atheleteID,
    },
  });

  return res?.data?.requestOptions;
};

export const getGarminConnectWellness = async (atheleteID) => {
  let res = await axios({
    method: "POST",
    url: `${store.getState().auth.apiURL}CF/api/garmin/startGarminAuthWellness`,
    data: {
      atheleteID: atheleteID,
    },
  });

  return res?.data?.requestOptions;
};

export const handleGarminCallbackRes = async (id, reqToken, oauthVerifier) => {
  let res = await axios({
    method: "POST",
    url: `${
      store.getState().auth.apiURL
    }CF/api/garmin/handleGarminCallbackResponse`,
    data: {
      id: id,
      request_token: reqToken,
      oauth_verifier: oauthVerifier,
    },
  });

  return res?.data;
};

export const handleGarminCallbackResWellness = async (
  id,
  reqToken,
  oauthVerifier
) => {
  let res = await axios({
    method: "POST",
    url: `${
      store.getState().auth.apiURL
    }CF/api/garmin/handleGarminCallbackResponseWellness`,
    data: {
      id: id,
      request_token: reqToken,
      oauth_verifier: oauthVerifier,
    },
  });

  return res?.data;
};

export const computeData = async (summaryID) => {
  try {
    let res = await axios({
      method: "GET",
      url: `${
        store.getState().auth.apiURL
      }CF/api/garminPythonAnalysis/EffortAndFatigueRun`,
      params: {
        summaryID: summaryID,
      },
    });
    // alert("Data computed successfully", JSON.stringify(res.data));
    return res.data.result;
  } catch (err) {
    alert("Error in computing data");
    console.log("Error in computing data: ", err);
  }
};

export const updateFinishedWorkoutName = async (summaryID, name) => {
  try {
    let res = await axios({
      method: "POST",
      url: `${
        store.getState().auth.apiURL
      }CF/api/garmin/updateGarminActivityName`,
      data: {
        summaryID: summaryID,
        name: name,
      },
    });
    return res.data;
  } catch (err) {
    throw err;
  }
};
