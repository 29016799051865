import {calculateWeeklyAggregateData, calculateWeeklyMinMax, calculateOverallMinMaxJson} from "./utils.js";


export const weeklyAggDistance = (activityData, startDate, endDate) => {
  return calculateWeeklyAggregateData(
    activityData,
    startDate,
    endDate,
    "summary.distanceInMeters",
    null // activityType is generic here
  );
}

export const weeklyAggRTSS = (activityData, startDate, endDate) => calculateWeeklyAggregateData(
  activityData,
  startDate,
  endDate,
  "computedMetrics.front_block_metrics.invrTSS",
  null // activityType is generic here
);

export const weeklyAddDistance = (activityData, startDate, endDate) => calculateWeeklyAggregateData(
  activityData,
  startDate,
  endDate,
  "summary.distanceInMeters",
  null // activityType is generic here
);

export const weeklyMaxDistance = (activityData, startDate, endDate) => calculateWeeklyMinMax(
  activityData,
  startDate,
  endDate,
  "summary.distanceInMeters",
  "max",
  null // activityType is generic here
)

export const weeklyMaxDurationInSeconds = (activityData, startDate, endDate) => calculateWeeklyMinMax(
  activityData,
  startDate,
  endDate,
  "summary.durationInSeconds",
  "max",
  null // activityType is generic here
);


export const overallMinPaceWindowedPeakMaxAvg = (activityData, startDate, endDate) => calculateOverallMinMaxJson(
  activityData,
  "computedMetrics.pace_windowed_peak",
  "min",
  null,
  "max_avg"
);

export const overallMaxHeartRateWindowedPeakMaxAvg = (activityData, startDate, endDate) => calculateOverallMinMaxJson(
  activityData,
  "computedMetrics.heart_rate_windowed_peak",
  "max",
  null,
  "max_avg"
);

export const overallMaxSpeedWindowedPeakBikeMaxAvg = (activityData, startDate, endDate) => calculateOverallMinMaxJson(
  activityData,
  "computedMetrics.speed_windowed_peak_bike",
  "max",
  null,
  "max_avg"
);


export  const overallMaxCadenceWindowedPeakMaxAvg =  (activityData, startDate, endDate) =>calculateOverallMinMaxJson(
  activityData,
  "computedMetrics.cadence_windowed_peak",
  "max",
  null,
  "max_avg"
);

export  const weeklyMinPaceWindowedPeak1200MaxAvg = (activityData, startDate, endDate, type) => calculateWeeklyMinMax(
  activityData,
  startDate,
  endDate,
  "computedMetrics.pace_windowed_peak.1200.max_avg",
  "min",
  type // activityType is generic here
);

const testingHelpers = (activityData, startDate, endDate) => {
  // Weekly aggregate 'distanceInMeters'
  const weeklyAggDistance = calculateWeeklyAggregateData(
    activityData,
    startDate,
    endDate,
    "summary.distanceInMeters",
    null // activityType is generic here
  );

  // Weekly aggregate 'rTSS'
  const weeklyAggRTSS = calculateWeeklyAggregateData(
    activityData,
    startDate,
    endDate,
    "computedMetrics.front_block_metrics.rTSS",
    null // activityType is generic here
  );

  // Weekly aggregate 'invrTSS'
  const weeklyAggInvrTSS = calculateWeeklyAggregateData(
    activityData,
    startDate,
    endDate,
    "computedMetrics.front_block_metrics.invrTSS",
    null // activityType is generic here
  );

  // Weekly aggregate 'durationInSeconds'
  const weeklyAggDurationInSeconds = calculateWeeklyAggregateData(
    activityData,
    startDate,
    endDate,
    "summary.durationInSeconds",
    null // activityType is generic here
  );

  // Weekly min/max:
  // ---------------------

  // Weekly maximum 'distanceInMeters'
  const weeklyMaxDistance = calculateWeeklyMinMax(
    activityData,
    startDate,
    endDate,
    "summary.distanceInMeters",
    "max",
    null // activityType is generic here
  );

  // Weekly maximum 'durationInSeconds'
  const weeklyMaxDurationInSeconds = calculateWeeklyMinMax(
    activityData,
    startDate,
    endDate,
    "summary.durationInSeconds",
    "max",
    null // activityType is generic here
  );

  // Weekly minimum '1200' max_avg from 'pace_windowed_peak'
  const weeklyMinPaceWindowedPeak1200MaxAvg = calculateWeeklyMinMax(
    activityData,
    startDate,
    endDate,
    "computedMetrics.pace_windowed_peak.1200.max_avg",
    "min",
    null // activityType is generic here
  );

  // Overall min/max (not necessary for final keys):
  // --------------------------------------------

  // Overall minimum 'max_avg' from 'pace_windowed_peak'
  const overallMinPaceWindowedPeakMaxAvg = calculateOverallMinMaxJson(
    activityData,
    "computedMetrics.pace_windowed_peak",
    "min",
    null,
    "max_avg"
  );

  // Overall maximum 'max_avg' from 'cadence_distance_peak', 'cadence_windowed_peak', 'speed_windowed_peak_bike', 'heart_rate_windowed_peak'
  const overallMaxCadenceDistancePeakMaxAvg = calculateOverallMinMaxJson(
    activityData,
    "computedMetrics.cadence_distance_peak",
    "max",
    null,
    "max_avg"
  );

  const overallMaxCadenceWindowedPeakMaxAvg = calculateOverallMinMaxJson(
    activityData,
    "computedMetrics.cadence_windowed_peak",
    "max",
    null,
    "max_avg"
  );

  const overallMaxSpeedWindowedPeakBikeMaxAvg = calculateOverallMinMaxJson(
    activityData,
    "computedMetrics.speed_windowed_peak_bike",
    "max",
    null,
    "max_avg"
  );

  const overallMaxHeartRateWindowedPeakMaxAvg = calculateOverallMinMaxJson(
    activityData,
    "computedMetrics.heart_rate_windowed_peak",
    "max",
    null,
    "max_avg"
  );

  return {
    // Weekly aggregations:
    // ---------------------
    "weeklyAggDistance": weeklyAggDistance,
    "weeklyAggRTSS": weeklyAggRTSS,
    "weeklyAggInvrTSS": weeklyAggInvrTSS,
    "weeklyAggDurationInSeconds": weeklyAggDurationInSeconds,

    // Weekly min/max:
    // ---------------------
    "weeklyMaxDistance": weeklyMaxDistance,
    "weeklyMaxDurationInSeconds": weeklyMaxDurationInSeconds,
    "weeklyMinPaceWindowedPeak1200MaxAvg": weeklyMinPaceWindowedPeak1200MaxAvg,

    // Overall min/max (not necessary for final keys):
    // --------------------------------------------
    "overallMinPaceWindowedPeakMaxAvg": overallMinPaceWindowedPeakMaxAvg,
    "overallMaxCadenceDistancePeakMaxAvg": overallMaxCadenceDistancePeakMaxAvg,
    "overallMaxCadenceWindowedPeakMaxAvg": overallMaxCadenceWindowedPeakMaxAvg,
    "overallMaxSpeedWindowedPeakBikeMaxAvg": overallMaxSpeedWindowedPeakBikeMaxAvg,
    "overallMaxHeartRateWindowedPeakMaxAvg": overallMaxHeartRateWindowedPeakMaxAvg,
  };
};
