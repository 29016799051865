import { EmojiEmotionsOutlined } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import EmojiPicker from "emoji-picker-react";
import { useRef, useState } from "react";
import { FileUploadButton } from "react-file-utils";
import { useSelector } from "react-redux";
import { SendButton } from "../../pages/getStreamChat/assets";
import { chatClient } from "../../utils/chat";

const BroadCast = ({ selectedUsers }) => {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [fileUploading, setFileUploading] = useState(false);
  const messageContainerRef = useRef(null);

  const gymAffiliation = useSelector(
    (state) => state.auth?.data?.gymAffliation
  );
  const isLivezy = gymAffiliation === "Livezy";

  const sendMessage = async (message) => {
    // get ids of all the selected users

    const userRes = await chatClient.queryChannels({
      members: { $in: [chatClient.userID] },
      type: "messaging",
    });

    console.log("userRes: ", userRes);

    // check if message is template or not
    let isTemplate = message.includes("#u") || message.includes("#U");

    // send message in all  the channels of the selected users
    userRes?.forEach(async (channel) => {
      let members = Object.keys(channel?.state?.members);
      let clientUUID = members.filter(
        (uuid) => uuid !== chatClient.userID
      )?.[0];
      let isClientSelected = selectedUsers.find(
        (user) => user.UserUUID === clientUUID
      );

      let myMessage = message;
      // if template message then replace all the variables with the actual values
      if (isTemplate) {
        let firstName =
          typeof isClientSelected?.Name === "string"
            ? isClientSelected.Name.split(" ")[0]
            : isClientSelected?.Name;

        myMessage = message.replace(/#u/gi, firstName);
      }

      if (isClientSelected) {
        let streamMessage = await channel.sendMessage({
          text: myMessage,
        });
      }
    });
  };

  const sendFile = async (file) => {
    setFileUploading(true);
    const userRes = await chatClient.queryChannels({
      members: { $in: [chatClient.userID] },
      type: "messaging",
    });

    console.log("userRes: ", userRes);

    let uploadedfile;
    let fileType;
    if (file.type.includes("image")) {
      fileType = "image";
    } else if (file.type.includes("video")) {
      fileType = "video";
    } else {
      fileType = "file";
    }

    // send message in all  the channels of the selected users
    userRes?.forEach(async (channel) => {
      let members = Object.keys(channel?.state?.members);
      let clientUUID = members.filter(
        (uuid) => uuid !== chatClient.userID
      )?.[0];
      let isClientSelected = selectedUsers.find(
        (user) => user.UserUUID === clientUUID
      );

      if (isClientSelected) {
        if (!uploadedfile) {
          let uploaded = await channel.sendFile(file);
          uploadedfile = uploaded;
          setMessages([
            ...messages,
            {
              type: fileType,
              file: uploadedfile.file,
              name: file.name,
            },
          ]);
          setFileUploading(false);
          // scroll to bottom
          messageContainerRef.current.scrollTop =
            messageContainerRef.current.scrollHeight;

          // to show message that takes time to render
          let timeOut = setTimeout(() => {
            messageContainerRef.current.scrollTop =
              messageContainerRef.current.scrollHeight;

            clearTimeout(timeOut);
          }, 100);
        }
        let streamMessage = await channel.sendMessage({
          text: "",
          attachments: [
            {
              type: fileType,
              image_url: uploadedfile.file,
              asset_url: uploadedfile.file,
              title: file.name,
              mime_type: file.type,
            },
          ],
        });
      }
    });
  };

  return (
    <div
      className="flex flex-col w-full bg-[#fff]"
      style={{
        height: "calc(100vh - 110px)",
      }}
    >
      <div className="font-DMSans font-bold text-lg border-b border-b-gray-400 p-2 h-[56px] flex items-center">
        #Broadcast
      </div>

      <div
        ref={messageContainerRef}
        className="flex flex-col overflow-y-scroll gap-2 pb-1 relative mt-auto"
      >
        {messages.map((message) => {
          let isFile =
            message.type === "file" ||
            message.type === "image" ||
            message.type === "video";
          return (
            <div class="str-chat__message-bubble ml-auto mr-1">
              <div class="str-chat__message-text" tabindex="0">
                <div
                  class="str-chat__message-text-inner str-chat__message-simple-text-inner"
                  data-testid="message-text-inner-wrapper"
                >
                  <div>
                    {isFile ? (
                      <RenderFilePreview file={message} />
                    ) : (
                      <p>{message}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        {fileUploading && (
          <div className="flex items-center justify-center">
            <CircularProgress size={12} />
          </div>
        )}
      </div>

      <div className="team-message-input__input justify-end w-full ">
        <div className="team-message-input__top">
          <div className="team-message-input__wrapper undefined">
            <div class="rta str-chat__textarea str-chat__message-textarea-react-host">
              <textarea
                data-testid="message-input"
                aria-label="Message hello"
                placeholder="Message hello"
                rows="3"
                className="rta__textarea str-chat__textarea__textarea str-chat__message-textarea"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </div>
            <div className="team-message-input__bottom">
              <div className="flex items-center">
                <EmojiEmotionsOutlined
                  className="cursor-pointer"
                  onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                />
                {showEmojiPicker && (
                  <EmojiPicker
                    style={{
                      position: "absolute",
                      bottom: "150px",
                    }}
                    onEmojiClick={(emoji, event) => {
                      setMessage(message + emoji.emoji);
                      setShowEmojiPicker(false);
                    }}
                  />
                  // <Picker
                  //   autoFocus
                  //   onSelect={(emoji) => {
                  //     setMessage(message + emoji.native);
                  //   }}
                  //   style={{
                  //     position: "absolute",
                  //     bottom: "150px",
                  //   }}
                  // />
                )}
                <FileUploadButton
                  disabled={
                    !selectedUsers?.length || selectedUsers?.length === 0
                  }
                  handleFiles={(files) => {
                    console.log("auto upload ", files);
                    sendFile(files[0]);
                  }}
                />
              </div>
              <div
                id="send-button-stream"
                className="team-message-input__button-gradient ml-auto"
                role="button"
                aria-roledescription="button"
                style={{
                  cursor:
                    selectedUsers.length && message.length > 0
                      ? "pointer"
                      : "not-allowed",
                  opacity: selectedUsers.length && message.length > 0 ? 1 : 0.5,
                }}
                onClick={(e) => {
                  setMessages([...messages, message]);
                  let timeOut = setTimeout(() => {
                    messageContainerRef.current.scrollTop =
                      messageContainerRef.current.scrollHeight;

                    clearTimeout(timeOut);
                  }, 100);
                  sendMessage(message);
                  setMessage("");
                }}
              >
                <p>Send</p>
                <SendButton />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BroadCast;

const RenderFilePreview = ({ file }) => {
  let fileType = getFileTypeFromName(file.name);
  let icon = <FileSvg />;
  if (fileType === "pdf") {
    icon = <PDFSvg />;
  } else if (fileType === "excel") {
    icon = <ExcelSvg />;
  }
  if (fileType === "image") {
    return <RenderImagePreview file={file} />;
  }
  if (fileType === "video") {
    return <RenderVideoPreview file={file} />;
  }
  return (
    <div className="flex items-center gap-2">
      {icon}

      <p
        className="text-sm cursor-pointer"
        onClick={() => {
          window.open(file.file);
        }}
      >
        {file.name}
      </p>
    </div>
  );
};

const RenderImagePreview = ({ file }) => {
  console.log("file: ", file);
  return (
    <div className="flex items-center gap-2">
      <img
        src={file.file}
        alt=""
        className="w-[300px] h-[300px] object-cover rounded-md"
      />
    </div>
  );
};

const RenderVideoPreview = ({ file }) => {
  return (
    <div className="flex items-center gap-2">
      <video
        src={file.file}
        controls
        className="w-[300px] h-[300px] object-cover rounded-md"
      />
    </div>
  );
};

const getFileTypeFromName = (name) => {
  let ext = name.split(".").pop();
  let fileType = "file";
  // check if file is image
  if (ext === "jpg" || ext === "png" || ext === "jpeg") {
    fileType = "image";
  }

  // check if file is video
  if (ext === "mp4" || ext === "mov" || ext === "avi") {
    fileType = "video";
  }

  // check if file is audio
  if (ext === "mp3" || ext === "wav") {
    fileType = "audio";
  }

  // check if file is pdf
  if (ext === "pdf") {
    fileType = "pdf";
  }

  // check if file is xls or xlsx or csv
  if (ext === "xls" || ext === "xlsx" || ext === "csv") {
    fileType = "excel";
  }

  return fileType;
};

const PDFSvg = () => {
  return (
    <svg
      class="rfu-file-icon--small fa-file-pdf"
      height="30"
      viewBox="0 0 384 512"
      width="30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm250.2-143.7c-12.2-12-47-8.7-64.4-6.5-17.2-10.5-28.7-25-36.8-46.3 3.9-16.1 10.1-40.6 5.4-56-4.2-26.2-37.8-23.6-42.6-5.9-4.4 16.1-.4 38.5 7 67.1-10 23.9-24.9 56-35.4 74.4-20 10.3-47 26.2-51 46.2-3.3 15.8 26 55.2 76.1-31.2 22.4-7.4 46.8-16.5 68.4-20.1 18.9 10.2 41 17 55.8 17 25.5 0 28-28.2 17.5-38.7zm-198.1 77.8c5.1-13.7 24.5-29.5 30.4-35-19 30.3-30.4 35.7-30.4 35zm81.6-190.6c7.4 0 6.7 32.1 1.8 40.8-4.4-13.9-4.3-40.8-1.8-40.8zm-24.4 136.6c9.7-16.9 18-37 24.7-54.7 8.3 15.1 18.9 27.2 30.1 35.5-20.8 4.3-38.9 13.1-54.8 19.2zm131.6-5s-5 6-37.3-7.8c35.1-2.6 40.9 5.4 37.3 7.8z"
        fill="#f82903"
      ></path>
    </svg>
  );
};

const ExcelSvg = () => {
  return (
    <svg
      class="rfu-file-icon--small fa-file-excel"
      height="30"
      viewBox="0 0 384 512"
      width="30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm212-240h-28.8c-4.4 0-8.4 2.4-10.5 6.3-18 33.1-22.2 42.4-28.6 57.7-13.9-29.1-6.9-17.3-28.6-57.7-2.1-3.9-6.2-6.3-10.6-6.3H124c-9.3 0-15 10-10.4 18l46.3 78-46.3 78c-4.7 8 1.1 18 10.4 18h28.9c4.4 0 8.4-2.4 10.5-6.3 21.7-40 23-45 28.6-57.7 14.9 30.2 5.9 15.9 28.6 57.7 2.1 3.9 6.2 6.3 10.6 6.3H260c9.3 0 15-10 10.4-18L224 320c.7-1.1 30.3-50.5 46.3-78 4.7-8-1.1-18-10.3-18z"
        fill="#207245"
      ></path>
    </svg>
  );
};

const FileSvg = () => {
  return (
    <svg
      class="rfu-file-icon--small fa-file-fallback"
      height="30"
      viewBox="0 0 384 512"
      width="30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48z"
        fill="#414D54"
      ></path>
    </svg>
  );
};
