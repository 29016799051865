// import { setPaymentProcessingStatus } from "@/ApiServices/CoachSubscriptions/Api";
import Layout from "@/components/layout/Layout";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const CoachPaymentSuccess = () => {
  const coachID = useSelector((state) => state.auth.trainerUUID);
  const countryCode = useSelector((state) => state.auth?.data?.countryCode);

  const redirect = () => {
    let timeout = setTimeout(() => {
      history.push("/coachSubscription");
      clearTimeout(timeout);
    }, 30000);
  };
  useEffect(() => {
    // do api request for updating processing status
    const isRazorPay = countryCode === "IN";
    // setPaymentProcessingStatus({ coachID, isRazorPay });
    redirect();
  }, []);

  const history = useHistory();

  return (
    <Layout>
      <div
        style={{
          height: "calc(100vh - 100px)",
        }}
        className=" flex flex-col items-center justify-center bg-white-pure rounded-xl shadow-xl"
      >
        <div className="flex items-center gap-2">
          <h1 className="font-DMSans text-font32 font-semibold text-[#8330e9]">
            Payment Success!
          </h1>
          <img
            src="https://img.icons8.com/color/48/000000/ok--v1.png"
            alt="success"
          />
        </div>

        <p className="font-DMSans text-font16 text-muted-foreground">
          Your payment was successful. It may take a few minutes to reflect in
          your account.
        </p>

        <p className="font-DMSans text-font16 text-muted-foreground">
          Redirecting you to subscription page in few seconds... or you can go
          to{" "}
          <span
            className="underline cursor-pointer font-semibold"
            onClick={() => history.push("/home")}
          >
            Home
          </span>{" "}
          page
        </p>
      </div>
    </Layout>
  );
};

export default CoachPaymentSuccess;
