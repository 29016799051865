import { CloseRounded } from "@mui/icons-material";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import {
  Checkbox,
  Dialog,
  DialogContent,
  Divider,
  FormControlLabel,
  MenuItem,
  Select,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { store } from "../../redux/store";
import { SpurfitCircularProgress } from "../Dashboard/dashboard";
import TextArea from "../InputComponents/TextArea";
import Input from "./InputForFoodModal";
import "./styles.css";
const AddFoodModal = ({
  isOpen,
  setIsOpen,
  setTrainerFoodItem,
  updateLocalFood,
  currentLocalFoodData,
}) => {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const [displayName, setDisplayName] = useState("");
  const [description, setDescription] = useState("");
  const [metric_serving_amount, setMetric_serving_amount] = useState("");
  const [metric_serving_unit, setMetric_serving_unit] = useState("gm");
  const [protein, setProtein] = useState("");
  const [fat, setFat] = useState("");
  const [carbs, setCarbs] = useState("");
  const [calories, setCalories] = useState("");
  const [image, setImage] = useState("");
  const [btnLoader, setBtnLoader] = useState(false);
  const [imageUploadedUrl, setImageUploadedUrl] = useState();
  const [autoChecked, setAutoChecked] = useState(true);
  const [displayAlert, setDisplayAlert] = useState(false);
  const handleDisplayNameChange = (event) => {
    setDisplayName(event.target.value);
  };
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };
  //  formData sending code
  const addFoodItem = async () => {
    if (
      displayName == "" ||
      protein == "" ||
      fat == "" ||
      carbs == "" ||
      calories == "" ||
      metric_serving_amount == ""
    ) {
      setDisplayAlert(true);
      return;
    } else {
      setDisplayAlert(false);
    }
    setBtnLoader(true);
    const coachID = store.getState().auth.trainerUUID;
    const formData = new FormData();
    formData.append("name", displayName);
    formData.append("descriptions", description);
    if (image) {
      formData.append("image", image);
    }
    formData.append("serving[0][metric_serving_amount]", metric_serving_amount);
    formData.append("serving[0][metric_serving_unit]", metric_serving_unit);
    formData.append("serving[0][protein]", protein);
    formData.append("serving[0][fat]", fat);
    formData.append("serving[0][carbs]", carbs);
    formData.append("serving[0][calories]", calories);

    await axios
      .post(`${APIURL}CF/api/createFood?coachId=${coachID}`, formData)
      .then(async (res) => {
        console.log("res ====>>> ", res);
        setBtnLoader(false);
        setTrainerFoodItem(res.data);
        setIsOpen(false);
      })
      .catch((err) => {
        setBtnLoader(false);
        console.log(["err ===>>> ", err]);
      });
  };
  const updateFoodItem = async () => {
    setBtnLoader(true);
    const updatedFood = {
      id: currentLocalFoodData.id,
      name: displayName,
      food_image: currentLocalFoodData.food_image,
      descriptions: description,
      serving: [
        {
          protein,
          carbs,
          fat,
          metric_serving_amount,
          metric_serving_unit,
          calories,
        },
      ],
    };
    updateLocalFood(updatedFood);
    setBtnLoader(false);
    setIsOpen(false);
  };

  useEffect(() => {
    if (autoChecked && (carbs > 0 || protein > 0 || fat > 0)) {
      setCalories(protein * 4 + carbs * 4 + fat * 9);
    } else if (carbs === 0 && protein === 0 && fat === 0) {
      setCalories(0);
    }
  }, [protein, fat, carbs, autoChecked]);
  useEffect(() => {
    if (currentLocalFoodData) {
      console.log("current local food data", currentLocalFoodData);
      setDisplayName(currentLocalFoodData.name);
      setCalories(currentLocalFoodData.serving[0].calories);
      setProtein(currentLocalFoodData.serving[0].protein);
      setCarbs(currentLocalFoodData.serving[0].carbs);
      setFat(currentLocalFoodData.serving[0].fat);
      setMetric_serving_unit(
        currentLocalFoodData.serving[0].metric_serving_unit
      );
      setImageUploadedUrl(currentLocalFoodData.food_image);
      setDescription(currentLocalFoodData.descriptions);
      setMetric_serving_amount(
        currentLocalFoodData.serving[0].metric_serving_amount
      );
    }
  }, []);
  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      maxWidth={"sm"}
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "20px",
        },
      }}
    >
      {btnLoader && (
        <div
          className="flex absolute w-full h-full justify-center items-center"
          style={{
            background: "rgba(0,0,0,0.5)",
            zIndex: 100,
          }}
        >
          <SpurfitCircularProgress />
        </div>
      )}
      <div className="add-program-modal-head">
        <p className="add-program-modal-head-title">Add Food Item</p>
        <div
          onClick={() => {
            setIsOpen(false);
          }}
          className="cursor-pointer"
        >
          <CloseRounded
            sx={{
              fontSize: "24px",
              color: "#000",
              cursor: "pointer",
            }}
          />
        </div>
      </div>
      <DialogContent>
        <div className="flex w-full justify-between mb-5">
          <div className="left-name-desc w-[65%] gap-2">
            <Input
              label="Food Name*"
              type="text"
              value={displayName}
              onChange={handleDisplayNameChange}
              style={{
                marginBottom: "10px",
              }}
            />
            <TextArea
              placeholder="Description"
              value={description}
              onChange={handleDescriptionChange}
              rows={4}
            />
          </div>
          <div className=" flex right-img w-[30%] h-[auto] items-center justify-center bg-slate-100">
            <div className="img-upload">
              <div className="flex flex-col items-center justify-center">
                {imageUploadedUrl ? (
                  <div className="flex flex-col">
                    <img
                      alt=""
                      src={imageUploadedUrl}
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </div>
                ) : (
                  <div className="flex flex-col items-center justify-center">
                    <FileUploadRoundedIcon />
                    <div className="flex mt-[10px] z-10 cursor-pointer flex-col items-center">
                      <span class="text-font12">Add Photo</span>
                      <input
                        type="file"
                        name="food_image"
                        onChange={(e) => {
                          setImage(e.target.files[0]);
                          setImageUploadedUrl(
                            URL.createObjectURL(e.target.files[0])
                          );
                        }}
                        style={{ visibility: "none", width: "80%" }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Divider />
        <div className="nutrition-info mt-4">
          <div className="cont1 flex  items-center mb-5">
            <p>Macros & Calories for*</p>
            <div className="calculator-macros">
              <input
                type="number"
                value={metric_serving_amount}
                onChange={(event) => {
                  setMetric_serving_amount(event.target.value);
                }}
                style={{
                  outline: "none",
                  marginLeft: "5px",
                }}
              />
              <Select
                variant="standard"
                disableUnderline
                value={metric_serving_unit}
                onChange={(event) => {
                  setMetric_serving_unit(event.target.value);
                }}
              >
                <MenuItem
                  value={"gm"}
                  sx={{
                    color: "#828282",
                    fontFamily: "DM Sans",
                    fontSize: "14px",
                  }}
                >
                  gm
                </MenuItem>
                <MenuItem
                  value={"ml"}
                  sx={{
                    color: "#828282",
                    fontFamily: "DM Sans",
                    fontSize: "14px",
                  }}
                >
                  ml
                </MenuItem>
                <MenuItem
                  value={"fl oz"}
                  sx={{
                    color: "#828282",
                    fontFamily: "DM Sans",
                    fontSize: "14px",
                  }}
                >
                  fl oz
                </MenuItem>
                <MenuItem
                  value={"fl oz(US)"}
                  sx={{
                    color: "#828282",
                    fontFamily: "DM Sans",
                    fontSize: "14px",
                  }}
                >
                  fl oz(US)
                </MenuItem>
                <MenuItem
                  value={"ounce"}
                  sx={{
                    color: "#828282",
                    fontFamily: "DM Sans",
                    fontSize: "14px",
                  }}
                >
                  ounce
                </MenuItem>
              </Select>
            </div>
          </div>
          <div className="cont2 flex items-center justify-evenly mb-5 gap-2">
            <Input
              label="Protein*"
              value={protein}
              onChange={(event) => {
                setProtein(event.target.value);
              }}
            />
            <Input
              label="Fat*"
              value={fat}
              onChange={(event) => {
                setFat(event.target.value);
              }}
            />
            <Input
              label="Carbs*"
              value={carbs}
              onChange={(event) => {
                setCarbs(event.target.value);
              }}
            />
            <Input
              label="Calories*"
              disabled={autoChecked}
              value={calories}
              onChange={(event) => {
                setCalories(event.target.value);
              }}
            />
          </div>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked
                value={autoChecked}
                onChange={(event) => {
                  setAutoChecked(event.target.checked);
                  console.log("checkbox value", event.target.checked);
                }}
                sx={{
                  color: "pink[800]",
                  "&.Mui-checked": {
                    color: "pink[600]",
                  },
                }}
              />
            }
            style={{
              fontSize: 12,
            }}
            label="Automatically Calculate Calories from Protein, Fat & Carbs."
          />
        </div>
        {displayAlert && (
          <div>
            <p
              style={{
                color: "red",
                fontSize: 12,
              }}
            >
              *Please fill all the necessary fields.
            </p>
          </div>
        )}
        <Divider />
        <div
          className="addItemBtnContainer"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button
            className="mt-5 button-new"
            onClick={
              btnLoader
                ? null
                : (e) => {
                    e.stopPropagation();
                    updateLocalFood
                      ? updateFoodItem()
                      : // add food item
                        addFoodItem();
                  }
            }
          >
            Add to My Library
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddFoodModal;
