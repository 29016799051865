import { store } from "@/redux/store";
import axios from "axios";

export const getCoachesCurrentSubscriptionDetails = async (coachID) => {
  let url = `${
    store.getState().auth.apiURL
  }CF/api/SpurfitProdCoachSubscription/GetCoachesCurrentSubscriptionDetails?coachID=${coachID}`;
  try {
    let res = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    console.log(res.data);
    return res.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const createSubscriptionStripe = async (data) => {
  let url = `${
    store.getState().auth.apiURL
  }CF/api/SpurfitProdCoachSubscription/CreateSubscriptionStripe`;
  try {
    let res = await axios.post(url, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    console.log(res.data);
    return res.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const cancelSubscriptionStripe = async (data) => {
  let url = `${
    store.getState().auth.apiURL
  }CF/api/SpurfitProdCoachSubscription/cancelSpurfitSubscriptionStripe`;
  try {
    let res = await axios.post(url, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    console.log(res.data);
    return res.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const resumeSubscriptionStripe = async (data) => {
  let url = `${
    store.getState().auth.apiURL
  }CF/api/SpurfitProdCoachSubscription/resumeSpurfitSubscriptionStripe`;
  try {
    let res = await axios.post(url, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    console.log(res.data);
    return res.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const pauseSubscriptionStripe = async (data) => {
  let url = `${
    store.getState().auth.apiURL
  }CF/api/SpurfitProdCoachSubscription/pauseSpurfitSubscriptionStripe`;
  try {
    let res = await axios.post(url, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    console.log(res.data);
    return res.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const createSubscriptionRazorPay = async (data) => {
  let url = `${
    store.getState().auth.apiURL
  }CF/api/SpurfitProdCoachSubscription/CreateSubscriptionRazorPay`;
  try {
    let res = await axios.post(url, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    console.log(res.data);
    return res.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const cancelSubscriptionRazorPay = async (data) => {
  let url = `${
    store.getState().auth.apiURL
  }CF/api/SpurfitProdCoachSubscription/cancelSpurfitSubscriptionRazorPay`;
  try {
    let res = await axios.post(url, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    console.log(res.data);
    return res.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const resumeSubscriptionRazorPay = async (data) => {
  let url = `${
    store.getState().auth.apiURL
  }CF/api/SpurfitProdCoachSubscription/resumeSpurfitSubscriptionRazorPay`;
  try {
    let res = await axios.post(url, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    console.log(res.data);
    return res.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const pauseSubscriptionRazorPay = async (data) => {
  let url = `${
    store.getState().auth.apiURL
  }CF/api/SpurfitProdCoachSubscription/pauseSpurfitSubscriptionRazorPay`;
  try {
    let res = await axios.post(url, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    console.log(res.data);
    return res.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getBillingHistory = async (coachID) => {
  let url = `${
    store.getState().auth.apiURL
  }CF/api/SpurfitProdCoachSubscription/getBillingHistory?coachID=${coachID}`;
  try {
    let res = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    console.log("billing history ", res.data);
    return res.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
