import { useState } from "react";
import { v4 as uuidv4 } from "uuid";

function Input({
    label,
    type = "text",
    value = "",
    onChange,
    id,
    containerStyle,
    ...props
  }) {
    const [inputValue, setInputValue] = useState(value);
    const inputId = id || uuidv4();

    function handleInputChange(event) {
      onChange && onChange(event);
      // setInputValue(event.target.value);
    }
    // useEffect(()=>{
    //  setInputValue(inputValue);
    // },[inputValue])
    return (
      <div
        style={{
          ...containerStyle,
        }}
        className="input-container"
      >
        <label htmlFor={inputId} {...props}>
          {label}
        </label>
        <input
          type={type}
          id={inputId}
          value={value}
          onChange={handleInputChange}
          {...props}
        />
      </div>
    );
  }

  export default Input;