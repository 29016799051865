import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

// import mealplans from "./examples/mealplanOutputexampleAPI.json";
import { Box, Stack } from "@mui/material";
import MealCardTable from "./MelCardTable";
import { styled } from "@mui/material/styles";
import CloseIcon from "../../assets/closeicon.svg";
import { CopilotContext } from "./copilot";
import { TrackingActions } from "./CopilotTracking";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CustomDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    minWidth: "100%",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function EditMeal({
  open,
  setOpen,
  mealplans,
  setMealplans,
  setResponseData,
}) {
  const [protein, setProtein] = React.useState(0);
  const [fat, setFat] = React.useState(0);
  const [carbs, setCarbs] = React.useState(0);
  const [cheatDay, setCheatDay] = React.useState(0);
  const [edited, setEdited] = React.useState(false);

  const { secondaryActionTracking } = React.useContext(CopilotContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (edited) {
      secondaryActionTracking(
        TrackingActions.UPDATE_MEALPLAN,
        JSON.stringify(mealplans)
      );
    }
  };

  const dailygains = ["Protein", "Fat", "Carbs", "Cheat Day"];

  React.useEffect(() => {
    totalgainsCalculate();
  }, []);

  const totalgainsCalculate = () => {
    let p = 0;
    let c = 0;
    let f = 0;
    mealplans["week-1"]?.map((data) => {
      console.log(data.macros);
      p = p + data.macros.protein;
      c = c + data.macros.carbs;
      f = f + data.macros.fat;
    });
    setProtein(p);
    setFat(f);
    setCarbs(c);
  };

  React.useEffect(() => {
    totalgainsCalculate();
  }, [mealplans]);

  return (
    <div>
      <CustomDialog
        maxWidth="100%"
        PaperProps={{
          style: {
            minHeight: "90vh",
            maxHeight: "90vh",
            padding: "30px 20px",
            borderRadius: 16,
          },
        }}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <Stack
            direction="row"
            spacing={2}
            justifyContent={"space-between"}
            margin={"0px 0px 22px 0px"}
          >
            <p
              style={{
                fontWeight: 500,
                fontSize: 14,
              }}
            >
              Meal plan for 1 Week
            </p>
            <Box
              onClick={handleClose}
              sx={{ marginTop: "-20px", marginLeft: "-10px" }}
            >
              <img src={CloseIcon} alt="close" />
            </Box>
          </Stack>
          <Box margin={"16px 0px 22px 0px"}>
            <Box>
              <Stack direction="row" spacing={"32px"}>
                <Box>
                  <p
                    style={{
                      fontSize: 10.5,
                      color: "#828282",
                    }}
                  >
                    Protiens
                  </p>
                  <p
                    style={{
                      fontSize: 17,
                    }}
                  >
                    {protein} g
                  </p>
                </Box>
                <Box>
                  <p
                    style={{
                      fontSize: 10.5,
                      color: "#828282",
                    }}
                  >
                    Fats
                  </p>
                  <p
                    style={{
                      fontSize: 17,
                    }}
                  >
                    {fat} g
                  </p>
                </Box>
                <Box>
                  <p
                    style={{
                      fontSize: 10.5,
                      color: "#828282",
                    }}
                  >
                    Carbs
                  </p>
                  <p
                    style={{
                      fontSize: 17,
                    }}
                  >
                    {carbs} g
                  </p>
                </Box>
                {/* <Box>
                  <p
                    style={{
                      fontSize: 10.5,
                      color: "#828282",
                    }}
                  >
                    Cheat Day
                  </p>
                  <p
                    style={{
                      fontSize: 17,
                    }}
                  >
                    0
                  </p>
                </Box> */}
              </Stack>
            </Box>
          </Box>
          <MealCardTable
            tabledata={mealplans}
            setMealplans={setMealplans}
            setResponseData={setResponseData}
            edited={edited}
            setEdited={setEdited}
          />
        </DialogContent>
      </CustomDialog>
    </div>
  );
}
