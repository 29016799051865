import { store } from "@/redux/store";
import axios from "axios";

export const createActivity = async (activity) => {
  try {
    const res = await axios({
      method: "post",
      url: `${
        store.getState().auth.apiURL
      }CF/api/activity/createManyActivityTargetDays`,
      data: activity,
    });
    return res.data;
  } catch (error) {
    console.log("error in createActivity", error);
  }
};

export const updateActivity = async (activity, id) => {
  try {
    const res = await axios({
      method: "post",
      url: `${store.getState().auth.apiURL}CF/api/activity/update?id=${id}`,
      data: activity,
    });
    return res.data;
  } catch (error) {
    console.log("error in updateActivity", error);
  }
};
