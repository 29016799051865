import FileCopyRoundedIcon from "@mui/icons-material/FileCopyRounded";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { CustomAlertContext } from "../../App";
import ExitIcon from "../../assets/copilot/exitIcon.svg";
import SendIcon from "../../assets/dashboard/sendIcon.svg";

export default function InviteByReference({
  open,
  setOpen,
  setInviteSuccess,
  copyUrl,
  setCopyLinkAlert,
}) {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const trainerUUID = useSelector((state) => state.auth.trainerUUID);
  const [email, setEmail] = useState("");
  const coachDetails = useSelector((state) => state.auth.data);
  const { displayAlert } = useContext(CustomAlertContext);

  const onSend = async () => {
    await axios({
      method: "post",
      url: `${APIURL}CF/api/affiliateCoaches/affliateInviteEmail`,
      data: {
        email: email,
        coachID: trainerUUID,
      },
    })
      .then((res) => {
        console.log("added successfully", res);
        displayAlert({
          type: "success",
          message: "Invite sent successfully!",
        });
      })
      .catch((err) => {
        console.log("error", err);
        displayAlert({
          type: "error",
          message: "Error sending invite!",
        });
      })
      .finally(() => {
        setInviteSuccess(true);
      });
  };
  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(copyUrl);
      setCopyLinkAlert(true);
      setTimeout(() => {
        setCopyLinkAlert(false);
      }, 3000);
      setOpen(false);
    } catch (err) {
      console.error("Failed to copy link: ", err);
    }
  };

  return (
    <Dialog
      sx={{
        backdropFilter: "blur(5px)",
      }}
      open={open}
      PaperProps={{
        sx: {
          borderRadius: "16px",
          maxWidth: "100%",
          padding: "17px 22px",
        },
      }}
    >
      <DialogTitle
        sx={{
          fontSize: "16px",
          fontWeight: 700,
          lineHeight: "24px",
          fontFamily: "DM Sans",
        }}
      >
        Refer New Trainer
      </DialogTitle>

      <DialogContent>
        <img
          src={ExitIcon}
          alt="exit icon "
          style={{
            height: "28px",
            width: "28px",
            position: "absolute",
            top: "22px",
            right: "22px",
            cursor: "pointer",
          }}
          onClick={() => setOpen(false)}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "642px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              width: "100%",
            }}
          >
            <p
              className="mb-3"
              sx={{
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "20px",
                fontFamily: "DM Sans",
              }}
            >
              Copy Invite Url:
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
                paddding: "5px 10px",
                backgroundColor: "#D5D5F8",
                padding: "10px",
                fontWeight: "600",
                borderRadius: "8px",
              }}
              onMouseOver={(e) => (e.target.style.textDecoration = "underline")}
              onMouseOut={(e) => (e.target.style.textDecoration = "none")}
            >
              <p>{copyUrl}</p>
              <FileCopyRoundedIcon
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  copyToClipboard();
                }}
              />
            </div>
          </div>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "DM Sans",
              mt: "15px",
              fontWeight: 400,
            }}
          >
            OR
          </Typography>

          <input
            style={{
              border: "1px solid #8330E9",
              width: "598px",
              height: "52px",
              borderRadius: "8px",
              width: "100%",
              padding: "10px 20px",
              fontSize: "18px",
              fontFamily: "DM Sans",
              fontWeight: 400,
              marginTop: "10px",
              outline: "none",
            }}
            autoComplete="off"
            placeholder="Enter Email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            type="email"
          />

          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "DM Sans",
              mt: "15px",
              fontWeight: 400,
            }}
          >
            Enter the email addresses of the trainer you want to refer!!
          </Typography>
        </Box>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "center" }}>
        <button
          style={{
            width: "178px",
            height: "44px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "linear-gradient(90deg, #464FEB 10.42%, #8330E9 100%)",
            borderRadius: "100px",
            fontFamily: "DM Sans",
            fontSize: "16px",
            color: "#ffffff",
          }}
          onClick={onSend}
        >
          <img
            style={{
              height: "24px",
              width: "24px",
              marginRight: "8px",
            }}
            src={SendIcon}
          />
          Send Invite
        </button>
      </DialogActions>
    </Dialog>
  );
}
