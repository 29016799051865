import { store } from "@/redux/store";
import axios from "axios";

export const extractProgramData = (programData) => {
  console.log("program data ha kya", programData);
  let toProgramsCreate = {};
  let newDaysMessages = {};
  let newDaysTargets = {};

  // sort workouts by dayIdx
  let sortedWorkouts = programData?.workouts?.sort(
    (a, b) => a.dayIdx - b.dayIdx
  );

  let modifiedWorkouts = [];
  let maxDay = 0;

  // if multiple days have same dayIdx then merge them
  sortedWorkouts?.forEach((workout) => {
    let dayIdx = workout.dayIdx;
    maxDay = Math.max(maxDay, dayIdx);
    let index = modifiedWorkouts.findIndex((item) => item.dayIdx == dayIdx);
    if (index != -1) {
      modifiedWorkouts[index] = {
        ...modifiedWorkouts[index],
        daysMessages:
          modifiedWorkouts[index].daysMessages ?? workout.daysMessages,
        daysTargets: modifiedWorkouts[index].daysTargets ?? workout.daysTargets,
        daysWorkouts:
          modifiedWorkouts[index]?.daysWorkouts?.length > 0
            ? modifiedWorkouts[index]?.daysWorkouts
            : workout?.daysWorkouts,
      };
    } else {
      modifiedWorkouts.push(workout);
    }
  });

  let maxDayIdx = 0;
  modifiedWorkouts?.map((workout) => {
    let dayIdx = workout.dayIdx;
    maxDayIdx = Math.max(maxDayIdx, dayIdx);
    maxDay = Math.max(maxDay, dayIdx);
    toProgramsCreate[dayIdx] = [];

    if (workout.daysWorkouts.length == 0) {
      toProgramsCreate[dayIdx].push({
        rest: true,
      });
    }
    workout?.daysWorkouts.map((dayWorkout) => {
      toProgramsCreate[dayIdx].push(dayWorkout.workout);
    });

    if (
      workout?.daysMessages?.messages &&
      workout?.daysMessages?.messages?.length > 0
    ) {
      newDaysMessages = {
        ...newDaysMessages,
        [dayIdx]: [workout?.daysMessages],
      };
    } else if (workout?.daysMessages?.length > 0) {
      newDaysMessages = {
        ...newDaysMessages,
        [dayIdx]: workout?.daysMessages,
      };
    }

    if (workout?.daysTargets) {
      newDaysTargets = {
        ...newDaysTargets,
        [dayIdx]: workout?.daysTargets,
      };
    }
  });

  // add rest day on days where no workout is added before maxDay
  for (let i = 1; i <= maxDay; i++) {
    if (!toProgramsCreate[i]) {
      toProgramsCreate[i] = [{ rest: true }];
    }
  }

  let maxWeek = Math.ceil(maxDay / 7);

  return {
    toProgramsCreate,
    daysMessages: newDaysMessages,
    daysTargets: newDaysTargets,
    originalDays: maxDay,
    originalWeeks: maxWeek,
  };
};

export const getModifiedWorkoutForAssignedWeek = async ({
  weekNo,
  addedWorkouts,
  weeks,
}) => {
  // copy the  selected week after the last week

  let startDay = 7 * (weekNo - 1) + 1;
  let endDay = 7 * weekNo;
  let newWeek = { ...addedWorkouts };

  let originalStartDay = 7 * weeks + 1;

  for (let i = startDay; i <= endDay; i++) {
    let thisDay = [];
    if (addedWorkouts?.[i]?.[0]) {
      await Promise.all(
        addedWorkouts[i]?.map(async (workout) => {
          if (workout.id) {
            let newID =
              (workout.id.includes("cardio_workout") ? "cardio_workout_" : "") +
              uuidv4();

            await createWorkoutWithID(workout, newID);
            thisDay.push({
              ...workout,
              id: newID,
            });
          } else {
            thisDay.push(workout);
          }
        })
      );
      newWeek[originalStartDay] = thisDay;
    } else {
      newWeek[originalStartDay] = addedWorkouts[i];
    }
    originalStartDay++;
  }

  return newWeek;
};

export const copyDaysMesssages = ({
  prev,
  weekNo,
  daysMessages,
  originalWeeks,
  addedWorkouts,
}) => {
  let startDay = 7 * (weekNo - 1) + 1;
  let endDay = 7 * weekNo;
  let newWeekMessages = {};

  let lastWeekNo = Math.ceil(
    parseInt(
      +Object.keys(addedWorkouts)?.[Object.keys(addedWorkouts).length - 1]
    ) / 7
  );

  let originalStartDay = 7 * lastWeekNo + 1;

  if (originalWeeks !== -1 && weekNo < originalWeeks) {
    newWeekMessages = { ...daysMessages };
    for (let i = startDay; i <= endDay; i++) {
      newWeekMessages[originalStartDay] = daysMessages?.[i];
      originalStartDay++;
    }
    return newWeekMessages;
  } else {
    for (let i = startDay; i <= endDay; i++) {
      newWeekMessages[i] = daysMessages?.[i];
    }
    // shift weeks
    let res = {};
    let i = 1;
    // sort keys in decending order
    let sortedKeys = Object.keys(prev || {}).sort((a, b) => b - a);

    endDay = sortedKeys[0];
    while (i < startDay) {
      res[i] = prev[i];
      i++;
    }

    while (i <= +endDay + 7) {
      res[i] = prev[i - 7];
      i++;
    }
    return { ...res, ...newWeekMessages };
  }
};

export const copyDaysTargets = ({
  prev,
  weekNo,
  daysTargets,
  originalWeeks,
  addedWorkouts,
}) => {
  let startDay = 7 * (weekNo - 1) + 1;
  let endDay = 7 * weekNo;
  let newWeekTargets = {};

  let lastWeekNo = Math.ceil(
    parseInt(
      +Object.keys(addedWorkouts)?.[Object.keys(addedWorkouts).length - 1]
    ) / 7
  );

  let originalStartDay = 7 * lastWeekNo + 1;

  if (originalWeeks !== -1 && weekNo < originalWeeks) {
    newWeekTargets = { ...daysTargets };
    for (let i = startDay; i <= endDay; i++) {
      newWeekTargets[originalStartDay] = daysTargets?.[i];
      originalStartDay++;
    }
    return newWeekTargets;
  } else {
    for (let i = startDay; i <= endDay; i++) {
      newWeekTargets[i] = daysTargets?.[i];
    }
    // shift weeks
    let res = {};
    let i = 1;
    // sort keys in decending order
    let sortedKeys = Object.keys(prev || {}).sort((a, b) => b - a);

    endDay = sortedKeys[0];
    while (i < startDay) {
      res[i] = prev[i];
      i++;
    }

    while (i <= +endDay + 7) {
      res[i] = prev[i - 7];
      i++;
    }

    return { ...res, ...newWeekTargets };
  }
};

export const getModifiedWorkout = async ({ weekNo, addedWorkouts }) => {
  let startDay = 7 * (weekNo - 1) + 1;
  let endDay = 7 * weekNo;
  let newWeek = {};

  for (let i = startDay; i <= endDay; i++) {
    let thisDay = [];
    if (addedWorkouts?.[i]?.[0]) {
      await Promise.all(
        addedWorkouts[i]?.map(async (workout) => {
          // Replace forEach with map
          if (workout.id) {
            let newID =
              (workout.id.includes("cardio_workout") ? "cardio_workout_" : "") +
              uuidv4();

            await createWorkoutWithID(workout, newID);
            thisDay.push({
              ...workout,
              id: newID,
            });
          } else {
            thisDay.push(workout);
          }
        })
      );
      newWeek[i] = thisDay;
    } else {
      newWeek[i] = addedWorkouts[i];
    }
  }

  return shiftWeeks(addedWorkouts, startDay, newWeek);
};

export const createWorkoutWithID = async (workout, workoutID) => {
  let data = {
    workout: {
      workoutInfo: {
        id: workoutID,
        name: workout?.name || "",
        description: workout?.description || "",
        dateCreated: new Date().toISOString(),
        coachID: workout?.coachID,
        workoutType: workout?.workoutType ?? "NOSCORE",
        isCoachWorkout: false,
        runTime: workout?.runTime,
      },
      workoutContent: workout?.workoutContent,
    },
  };

  await axios({
    method: "post",
    url: `${
      store.getState().auth.apiURL
    }CF/api/CoachWeb/Create/addWorkoutWithAllDetailsJSON`,
    data,
  });
};

function shiftWeeks(prev, startDay, newWeek) {
  let res = {};
  let i = 1;
  while (i < startDay) {
    res[i] = prev[i];
    i++;
  }
  // while (i <= Object.keys(prev || {}).length + 7) {
  //   res[i] = prev[i - 7];
  //   i++;
  // }
  Object.keys(prev || {}).forEach((key) => {
    if (key >= startDay) {
      res[+key + 7] = prev[key];
    }
  });

  return { ...res, ...newWeek };
}

function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(12)
  );
}
export const handlePasteSingleWorkout = async (addedWorkout, day, workout) => {
  let newID =
    (workout.id.includes("cardio_workout") ? "cardio_workout_" : "") + uuidv4();

  await createWorkoutWithID(workout, newID);
  let updatedAddedWorkout = { ...addedWorkout };
  if (!updatedAddedWorkout[day] || updatedAddedWorkout[day]?.[0]?.rest) {
    updatedAddedWorkout[day] = [];
  }
  updatedAddedWorkout[day].push({
    ...workout,
    id: newID,
  });
  return updatedAddedWorkout;
};

export const handleReplaceSingleWorkout = async (
  addedWorkout,
  day,
  pasteIndex,
  workout
) => {
  let newID =
    (workout.id.includes("cardio_workout") ? "cardio_workout_" : "") + uuidv4();

  await createWorkoutWithID(workout, newID);
  let updatedAddedWorkout = { ...addedWorkout };
  updatedAddedWorkout[day][pasteIndex] = {
    ...workout,
    id: newID,
  };
  return updatedAddedWorkout;
};

export const modifyWorkoutOnPaste = async (prev, dayData, day) => {
  let curDay = prev?.[day] || [];
  if (
    curDay?.length > 0 &&
    (curDay?.[0]?.rest || isThisIDCardio(curDay?.[0]?.id))
  ) {
    curDay = [];
  }
  // don't add duplicate workouts
  let newWorkouts = dayData?.workouts?.filter(
    (workout) => !curDay?.find((curWorkout) => curWorkout?.id === workout?.id)
  );

  let modifiedWorkouts = [];
  await Promise.all(
    newWorkouts.map(async (workout) => {
      if (workout.id) {
        let newID =
          (workout.id.includes("cardio_workout") ? "cardio_workout_" : "") +
          uuidv4();

        modifiedWorkouts.push({
          ...workout,
          id: newID,
        });
        await createWorkoutWithID(workout, newID);
      } else {
        if (workout?.rest) {
        } else {
          modifiedWorkouts.push(workout);
        }
      }
    })
  )
    .then((res) => {
      console.log("Paste workouts res", res);
    })
    .catch((err) => {
      console.log("paste workout error", err);
    });

  if (dayData?.workouts) {
    if (modifiedWorkouts?.length == 0) {
      return prev;
    }
    curDay.push(...modifiedWorkouts);
  }

  let res = { ...prev, [day]: curDay?.length > 0 ? curDay : undefined };
  console.log("pasted workout day ", res);
  return res;
};

const isThisIDCardio = (id) => {
  if (typeof id === "string") {
    return id.includes("cardio_workout");
  }
  return false;
};

export const transformForUpdates = async ({
  selectedProgramData,
  addedWorkouts,
  daysMessages,
  daysTargets,
  row,
  orignialWeeks,
  originalDay,
  coachID,
  isTemplate,
  getProgramDetails,
  setUnsavedChanges,
  displayAlert,
  isSilent,
  saveCurrentStateToLocalStorage,
}) => {
  let params = {
    programID: selectedProgramData?.id,
    coachID: coachID,
    name: selectedProgramData?.name,
    description: selectedProgramData?.description,
    createData: undefined,
    updateWorkouts: undefined,
    updateDaysWorkouts: undefined,
    creationDate: new Date().toISOString(),
    duration: 7 * row,
    updatedDayIdx: row > orignialWeeks ? 7 * orignialWeeks + 1 : undefined,
    isTemplate: isTemplate,
  };

  let oldWorkouts = selectedProgramData?.workouts;

  let updatedWorkouts = [];
  let updatedDaysWorkouts = [];

  let createDataAssingedWeeks = [];

  oldWorkouts?.map((workout, index) => {
    let dayIdx = workout?.dayIdx;

    let addedWorkout = addedWorkouts?.[dayIdx];

    if (
      isWorkoutsInfoUpdated({
        oldDaysMessages: workout?.daysMessages,
        oldDaysTargets: workout?.daysTargets,
        newDaysMessages: daysMessages?.[dayIdx],
        newDaysTargets: daysTargets?.[dayIdx],
      })
    ) {
      updatedWorkouts.push({
        id: workout?.id,
        dayIdx: workout?.dayIdx,
        daysMessages: daysMessages?.[dayIdx],
        daysTargets: daysTargets?.[dayIdx],
      });
    }

    if (
      isDaysWorkoutsUpdated({
        oldDaysWorkouts: workout?.daysWorkouts,
        newDaysWorkouts: addedWorkout,
      })
    ) {
      if (addedWorkout?.length > 0) {
        let oldDaysWorkoutsLength = workout?.daysWorkouts?.length;
        addedWorkout.map((item, index) => {
          let isEndurance =
            item?.workoutKind === "run" ||
            item?.workoutKind === "bike" ||
            item?.workoutKind === "swim";
          if (item?.id) {
            if (index < oldDaysWorkoutsLength) {
              updatedDaysWorkouts.push({
                id: workout?.daysWorkouts?.[index]?.id,
                programWithWorkoutsID: workout?.id,
                workoutID: item?.id,
                orderIdx: index,
                isEndurance: isEndurance,
              });
            } else {
              createDataAssingedWeeks.push({
                dayIdx: dayIdx,
                data: {
                  programWithWorkoutsID: workout?.id,
                  workoutID: item?.id,
                  orderIdx: index,
                  isEndurance: isEndurance,
                },
              });
            }
          }
        });
      }
    }
  });

  let newWorkouts = [];
  let newDaysWorkouts = [];

  let dayAfterOriginalWeek = originalDay;

  let newEndDay = dayAfterOriginalWeek;
  await Promise.all(
    Object.entries(addedWorkouts).map(async (workout) => {
      let workoutDay = parseInt(workout?.[0]);
      newEndDay = Math.max(newEndDay, workoutDay);

      if (workoutDay <= dayAfterOriginalWeek) {
        return;
      }

      if (workout?.[0] && workout?.[0] != "" && workout?.[1]?.length > 0) {
        let Index = newWorkouts.findIndex(
          (workout) => workout?.dayIdx === workoutDay
        );
        let key = "";
        if (Index != -1) {
          key = newWorkouts[Index].id;
        } else {
          key = uuidv4();
          newWorkouts.push({
            id: key,
            dayIdx: workoutDay,
            daysMessages: daysMessages?.[workoutDay],
            daysTargets: daysTargets?.[workoutDay],
          });
        }
        for (let index = 0; index < workout[1].length; index++) {
          const item = workout[1][index];
          let isEndurance =
            item?.workoutKind === "run" ||
            item?.workoutKind === "bike" ||
            item?.workoutKind === "swim";
          if (item?.id) {
            newDaysWorkouts.push({
              programWithWorkoutsID: key,
              workoutID: item.id,
              orderIdx: index,
              isEndurance: isEndurance,
            });
          }
        }
      } else if (daysMessages[workoutDay]) {
        let key = uuidv4();
        newWorkouts.push({
          id: key,
          dayIdx: workoutDay,
          daysMessages: daysMessages[workoutDay],
          daysTargets: daysTargets[workoutDay],
        });
      } else if (daysTargets[workoutDay]) {
        let key = uuidv4();
        newWorkouts.push({
          id: key,
          dayIdx: workoutDay,
          daysMessages: daysMessages[workoutDay],
          daysTargets: daysTargets[workoutDay],
        });
      }
    })
  );

  if (newEndDay > dayAfterOriginalWeek) {
    params.updatedDayIdx = dayAfterOriginalWeek + 1;

    for (let day = originalDay + 1; day <= newEndDay; day++) {
      let index = newWorkouts.findIndex((workout) => workout.dayIdx === day);
      if (index === -1) {
        let key = uuidv4();
        newWorkouts.push({
          id: key,
          dayIdx: day,
        });
      }
    }
  }

  params.createData = {
    workouts: newWorkouts,
    daysWorkouts: newDaysWorkouts,
  };

  params.updateWorkouts = updatedWorkouts;
  params.updateDaysWorkouts = updatedDaysWorkouts;
  params.createDataAssingedWeeks = createDataAssingedWeeks;

  console.log({ params });

  await axios({
    method: "post",
    url: `${store.getState().auth.apiURL}CF/api/updatemodularprogram`,
    data: { ...params },
  })
    .then((res) => {
      console.log("result ", res);
      if (!isSilent) {
        getProgramDetails(selectedProgramData?.id);
        setUnsavedChanges(false);
      }
    })
    .catch((err) => {
      saveCurrentStateToLocalStorage();
      displayAlert({
        type: "error",
        message: "Something went wrong",
      });
      console.log("error ", err);
    });
};

export const isWorkoutsInfoUpdated = ({
  oldDaysMessages,
  oldDaysTargets,
  newDaysMessages,
  newDaysTargets,
}) => {
  let isUpdated = false;

  // stringified old and new messages
  let oldMessages = oldDaysMessages ? JSON.stringify(oldDaysMessages) : "";
  let newMessages = newDaysMessages ? JSON.stringify(newDaysMessages) : "";

  // stringified old and new targets
  let oldTargets = oldDaysTargets ? JSON.stringify(oldDaysTargets) : "";
  let newTargets = newDaysTargets ? JSON.stringify(newDaysTargets) : "";

  if (oldMessages !== newMessages || oldTargets !== newTargets) {
    isUpdated = true;
  }

  return isUpdated;
};

export const isDaysWorkoutsUpdated = ({ oldDaysWorkouts, newDaysWorkouts }) => {
  console.log("oldDaysWorkouts: ", oldDaysWorkouts);
  console.log("newDaysWorkouts: ", newDaysWorkouts);

  if (newDaysWorkouts?.[0]?.rest) {
    newDaysWorkouts = [];
  }

  if (
    oldDaysWorkouts === newDaysWorkouts ||
    oldDaysWorkouts?.length !== newDaysWorkouts?.length
  ) {
    return true;
  }
  let update = false;
  oldDaysWorkouts?.forEach((oldWorkout, index) => {
    let newWorkout = newDaysWorkouts[index];
    if (oldWorkout?.workoutID !== newWorkout?.id) {
      update = true;
      return true;
    }
  });

  return update;
};

export const getMaxPhaseOrderIndex = (phases) => {
  let max = 0;
  phases?.forEach((phase) => {
    max = Math.max(max, phase.phaseOrderIdx);
  });
  return max;
};

export const sortPhases = (phases) => {
  // if phase order index is not present then sort by name
  return phases.sort((a, b) => {
    if (a.phaseOrderIdx && b.phaseOrderIdx) {
      return a.phaseOrderIdx - b.phaseOrderIdx;
    } else {
      return a.name.localeCompare(b.name);
    }
  });
};
