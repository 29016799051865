/* eslint-disable jsx-a11y/alt-text */
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import { Alert, Box, Button, Grid, Typography } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { CustomAlertContext } from "../../App";
import VideoPlayerModel from "../../components/VideoPlayer/VideoPlayer";
import Layout from "../../components/layout/Layout";
import SpinnerComponent from "../../components/layout/spinner";
import AddExerciseModal from "./AddExerciseModal";
import "./styles.css";

import {
  getAllExercise,
  getGlobalExercisesForCoach,
} from "../../ApiServices/Exercise/Api";
import PlaceholderImage from "../../assets/exercise.png";
import Img1 from "../../assets/library/img1.jpeg";
import Img2 from "../../assets/library/img2.png";
import Img3 from "../../assets/library/img3.jpeg";
import Img4 from "../../assets/library/img4.jpeg";
import toast from "../../components/Toast/toast";
import {
  useGetSpurfitExercisesQuery,
  useGetTrainersExercisesByCoachIDQuery,
} from "../../services/apiSlice";
import { SearchIcon } from "../getStreamChat/assets";
import PlusIcon from "../library/assests/PlusIcon";
import FilterExerciseModal from "./FilterExerciseModal";
import InfiniteScroll from "./InfiniteScroll";
import { ExerciseListItem } from "./RenderExercises";
import ActiveIndicator from "./assests/ActiveIndicator";
import FunnelActiveIcon from "./assests/FunnelActiveIcon";
import FunnelIcon from "./assests/FunnelIcon";
import { getVideoId } from "./exerciseUtil";

const AllExercises = ({}) => {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const isLivezy = useSelector((state) => state.auth.isLivezy);
  const SPUR_FIT = 0;
  const TRAINER = 1;
  const pageSize = 50;
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [videoInfo, setVideoInfo] = useState({});
  const [storeExerciseList, setStoreExerciseList] = useState([]);
  const [storeExerciseListTemp, setStoreExerciseListTemp] = useState([]);
  const [currenPageExercise, setCurrenPageExercise] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [searched, setSearched] = React.useState("");
  const [showAddExerciseModal, setShowAddExerciseModal] = useState(false);
  const [libraryType, setLibraryType] = useState(isLivezy ? TRAINER : SPUR_FIT);
  const [maxHeight, setMaxHeight] = useState(0);
  const [isDeleteEnabled, setIsDeleteEnabled] = useState(false);
  const [selectedForDeletion, setSelectedForDeletion] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showUpdateExerciseModal, setShowUpdateExerciseModal] = useState(false);
  const [displayFilterModal, setDisplayFilterModal] = useState(false);
  const { displayAlert } = useContext(CustomAlertContext);
  const [filteredData, setFilteredData] = useState();
  const [tempFilter, setTempFilter] = useState([]);
  const [showAlert, setShowAlert] = useState({
    show: false,
    message: "",
    type: "success",
  });
  const [selectedFilters, setSelectedFilters] = useState([
    {
      name: "modality",
      label: "Modality",
      value: [],
    },
    {
      name: "subModality",
      label: "Sub Modality",
      value: [],
    },
    {
      name: "equipmentList",
      label: "Equipment List",
      value: [],
    },
    {
      name: "bodyPart",
      label: "Body Part",
      value: [],
    },
    {
      name: "subBodyPart",
      label: "Sub Body Part",
      value: [],
    },
    {
      name: "movementFunction",
      label: "Movement Function",
      value: [],
    },

    {
      name: "tags",
      label: "Tags",
      value: [],
    },
  ]);
  const [isFetching, setIsFetching] = useState(false);
  const [allTrainerExercises, setAllTrainerExercises] = useState([]);
  const coachID = useSelector((state) => state.auth.trainerUUID);
  const adminID = useSelector((state) => state.auth?.data?.adminID);

  const placeholderImages = [Img1, Img2, Img3, Img4];

  const buttonStyle = {
    width: "142px",
    height: "33px",
    fontSize: "14px",
    background: "#071148",
    borderRadius: "8px",
    color: "#fff",
    textTransform: "none",
    marginLeft: 12,
  };

  const customStyles = {
    menu: (base) => ({
      ...base,
      // width: "100%",
      maxWidth: "none",
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "white",
      borderColor: "#071148",
      boxShadow: state.isFocused ? "0 0 0 1px #071148" : provided.boxShadow,
      "&:hover": {
        borderColor: "#071148",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#071148" : "white",
      color: state.isSelected ? "white" : "#071148",
      "&:hover": {
        backgroundColor: state.isSelected ? "#071148" : "#f2f2f2",
        color: state.isSelected ? "white" : "#071148",
      },
    }),
  };

  function formatProperties(input) {
    if (typeof input === "object") {
      return Object.values(input).join(", ");
    } else {
      return input;
    }
  }

  const nextPage = () => {
    setIsLoading(true);
    setCurrenPageExercise(
      paginate(
        tempFilter.length < 1 ? storeExerciseList : filteredData,
        page + 1,
        pageSize
      )
    );
    setPage((prev) => prev + 1);
    setTimeout(() => {
      setIsLoading(false);
    }, 50);
  };

  const previousPage = () => {
    setCurrenPageExercise(
      paginate(
        tempFilter.length < 1 ? storeExerciseList : filteredData,
        page - 1,
        pageSize
      )
    );
    setPage((prev) => prev - 1);
  };

  const onPage = (number) => {
    setIsLoading(true);
    setCurrenPageExercise(
      paginate(
        tempFilter.length < 1 ? storeExerciseList : filteredData,
        number,
        pageSize
      )
    );
    setPage(number);
    setTimeout(() => {
      setIsLoading(false);
    }, 50);
  };

  const paginate = (items, currentPage, pageSize) => {
    setTotalPage(Math.ceil(items.length / pageSize));
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    return items?.slice(startIndex, endIndex);
  };

  console.log("storeExerciseList", storeExerciseList);

  useEffect(() => {
    if (tempFilter.length > 0) {
      let newData = [];
      if (selectedFilters.length > 0) {
        storeExerciseList.map((listData) => {
          selectedFilters.map((filter) => {
            if (filter?.name === "tags" && filter?.value?.length > 0) {
              filter?.value?.map((data) => {
                if (listData?.tags === data) {
                  newData.push(listData);
                }
              });
            }
            Object.values(
              listData[filter.name] != null ? listData[filter.name] : {}
            ).map((data) => {
              if (filter.value.includes(data)) newData.push(listData);
            });
          });
        });
        setFilteredData(newData);

        setCurrenPageExercise(paginate(newData, page, pageSize));
      }
    } else {
      setCurrenPageExercise(paginate(storeExerciseList, page, pageSize));
    }
  }, [selectedFilters]);

  const requestSearch = (searchedVal) => {
    setSearched(searchedVal);
    const filteredRows = storeExerciseListTemp.filter((row) => {
      return row?.Name
        ? row.Name.toLowerCase().includes(searchedVal.toLowerCase())
        : row.name.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setStoreExerciseList(filteredRows);
    setPage(1);
    setTotalPage(Math.ceil(filteredRows.length / pageSize));
    setCurrenPageExercise(paginate(filteredRows, 1, pageSize));
  };

  const cancelSearch = () => {
    setSearched("");
    setStoreExerciseList(storeExerciseListTemp);
    setPage(1);
    setTotalPage(Math.ceil(storeExerciseListTemp.length / pageSize));
    setCurrenPageExercise(paginate(storeExerciseListTemp, 1, pageSize));
  };

  const {
    data: spurfitExercises,
    error: spurfitExercisesError,
    isLoading: spurfitExercisesLoading,
  } = useGetSpurfitExercisesQuery(APIURL);

  const {
    data: trainerExercises,
    error: trainerExercisesError,
    isLoading: trainerExercisesLoading,
    refetch: refetchTrainerExercises,
  } = useGetTrainersExercisesByCoachIDQuery({ coachID, APIURL });
  const {
    data: adminTrainerExercises,
    error: adminTrainerExercisesError,
    isLoading: adminTrainerExercisesLoading,
    refetch: refetchadminTrainerExercises,
  } = useGetTrainersExercisesByCoachIDQuery({ coachID: adminID, APIURL });

  const isValidUrl = (urlString) => {
    var urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // validate protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // validate fragment locator
    return !!urlPattern.test(urlString);
  };

  const getGlobalExercises = async () => {
    setIsLoading(true);
    if (!spurfitExercisesLoading && spurfitExercises?.length > 0) {
      if (spurfitExercises.length > 0) {
        setCurrenPageExercise(paginate(spurfitExercises, page, pageSize));
        setStoreExerciseList(spurfitExercises);
        setStoreExerciseListTemp(spurfitExercises);
      }

      if (searched.length > 0) {
        const filteredRows = spurfitExercises.filter((row) => {
          return row?.Name
            ? row.Name.toLowerCase().includes(searched.toLowerCase())
            : row.name.toLowerCase().includes(searched.toLowerCase());
        });
        setStoreExerciseList(filteredRows);
        setPage(1);
        setTotalPage(Math.ceil(filteredRows.length / pageSize));
        setCurrenPageExercise(paginate(filteredRows, 1, pageSize));
      }

      setTimeout(() => {
        setIsLoading(false);
      }, 100);
    } else {
      let allExercises = await getGlobalExercisesForCoach(coachID);
      setStoreExerciseList(allExercises);
      setStoreExerciseListTemp(allExercises);
      setPage(1);
      setCurrenPageExercise(paginate(allExercises, 1, pageSize));
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // setIsLoading(true);
    if (libraryType == SPUR_FIT) {
      getGlobalExercises();
      setPage(1);
    } else if (libraryType == TRAINER) {
      trainersExercises();
    }
  }, [libraryType, trainerExercises]);

  useEffect(() => {
    if (selectedItem && spurfitExercisesLoading) {
      // find the selecteditem in the spurfit exercises
      const exercise = spurfitExercises.find((e) => e.id === selectedItem.id);
      if (exercise) {
        setVideoInfo(exercise);
      }
    }
  }, [spurfitExercisesLoading]);

  useEffect(() => {
    const heights = Array.from(document.querySelectorAll(".gridItem")).map(
      (item) => item.clientHeight
    );
    const max = Math.max(...heights);
    setMaxHeight(max);
  }, [page, currenPageExercise]);

  const getTrainerExercises = async (shouldGoAhead) => {
    setIsLoading(true);

    let allExercises = allTrainerExercises;
    if (
      !allTrainerExercises ||
      allTrainerExercises?.length < 1 ||
      shouldGoAhead
    ) {
      allExercises = await getAllExercise(coachID, adminID);
    }
    console.log("typeAllExercise", allExercises);
    setAllTrainerExercises(allExercises);
    setStoreExerciseList(allExercises);
    setStoreExerciseListTemp(allExercises);
    setPage(1);
    setCurrenPageExercise(paginate(allExercises, 1, pageSize));

    if (searched.length > 0) {
      const filteredRows = allExercises.filter((row) => {
        return row?.Name
          ? row.Name.toLowerCase().includes(searched.toLowerCase())
          : row.name.toLowerCase().includes(searched.toLowerCase());
      });
      setStoreExerciseList(filteredRows);
      setPage(1);
      setTotalPage(Math.ceil(filteredRows.length / pageSize));
      setCurrenPageExercise(paginate(filteredRows, 1, pageSize));
    }
    setIsLoading(false);
  };

  const trainersExercises = getTrainerExercises;

  const handleMoreClick = (event, card) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedCard(card);
  };
  const handleCloseMore = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
    setSelectedCard(null);
  };

  const deleteSingleExercise = async (id) => {
    await axios({
      method: "post",
      url: `${APIURL}CF/api/CoachWeb/Delete/deleteOneMovement`,
      data: {
        movementID: id,
      },
    })
      .then((res) => {
        // toast("deleted successfully", "success");
        getTrainerExercises(true);
        handleShowAlert("Deleted successfully", "success");
      })
      .catch((err) => {
        console.log("error deleteing exercise", err);
        handleShowAlert("Error deleting exercise", "error");
        // toast("Error deleting exercise", "error");
      });
  };

  const updateAllExercises = async () => {
    await spurfitExercises.forEach(async (exercise) => {
      await updateExerciseImage(exercise);
    });
  };

  function isVimeoLink(url) {
    return url.includes("vimeo.com");
  }

  const updateExerciseImage = async (exercise) => {
    if (exercise?.videoURL == null || exercise?.videoURL?.trim() == "") {
      deleteSingleExercise(exercise.id);
      return;
    }
    let data = {
      movementID: exercise.id,
      videoID: getVideoId(exercise?.videoURL),
      coachID: undefined,
      ...Object.keys(exercise).reduce((acc, key) => {
        acc[key] = exercise[key] === null ? undefined : exercise[key];
        return acc;
      }, {}),
    };
    data.imageLink = undefined;
    data.id = undefined;
    if (handleDeleteExercise(exercise, getVideoId(exercise.videoURL))) {
      await axios({
        method: "post",
        url: `${APIURL}CF/api/CoachWeb/Update/updateOneMovement`,
        data: data,
      })
        .then((res) => {
          // toast("updated successfully", "success");
          handleShowAlert("Updated successfully", "success");
        })
        .catch((err) => {
          console.log("error updating exercise", err);
          handleShowAlert("Error updating exercise", "error");
          // toast("Error updating exercise", "error");
        });
    }
  };

  const handleDeleteExercise = async (exercise, videoId) => {
    try {
      const response = await fetch(
        `https://vimeo.com/api/oembed.json?url=https%3A//vimeo.com/${videoId}`
      );
      const data = await response.json();

      if (data && data.video_id) {
        return true;
      } else {
        await deleteSingleExercise(exercise.id);
      }
    } catch (error) {
      if (exercise.videoURL == null || isVimeoLink(exercise.videoURL)) {
        await deleteSingleExercise(exercise.id);
      }
      console.log("error getting video response", error);
    }
    return false;
  };

  const handleUpdateExercide = () => {
    setShowUpdateExerciseModal(true);
  };
  const handleDeleteExerciseFunc = () => {
    deleteSingleExercise(selectedCard?.id);
    setOpenDialog(false);
  };

  const handleShowAlert = (message, type) => {
    setShowAlert({
      show: true,
      message: message,
      type: type,
    });
    setTimeout(() => {
      setShowAlert({
        show: false,
        message: "",
        type: "success",
      });
    }, 3000);
  };

  useEffect(() => {
    setSearched("");
    setSelectedFilters([
      {
        name: "modality",
        label: "Modality",
        value: [],
      },
      {
        name: "subModality",
        label: "Sub Modality",
        value: [],
      },
      {
        name: "equipmentList",
        label: "Equipment List",
        value: [],
      },
      {
        name: "bodyPart",
        label: "Body Part",
        value: [],
      },
      {
        name: "subBodyPart",
        label: "Sub Body Part",
        value: [],
      },
      {
        name: "movementFunction",
        label: "Movement Function",
        value: [],
      },
      {
        name: "tags",
        label: "Tags",
        value: [],
      },
    ]);
    setTempFilter([]);
  }, [libraryType]);

  const fetchMoreData = () => {
    if (page < totalPage) {
      setIsFetching(true);

      setTimeout(() => {
        setCurrenPageExercise(
          currenPageExercise.concat(
            paginate(storeExerciseList, page + 1, pageSize)
          )
        );
        setPage((prev) => prev + 1);
        setIsFetching(false);
      }, 100);
    }
  };

  return (
    <Layout>
      <div
        style={{
          maxWidth: "calc(100% -110px)",
          minWidth: "calc(100% - 110px)",
          minHeight: "calc(100vh - 110px)",
          borderRadius: "20px",
          background: "#fff",
          borderRadius: "20px",
          overflow: "hidden",
        }}
      >
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          zindex="999999"
        />
        <AddExerciseModal
          open={showAddExerciseModal}
          setOpen={setShowAddExerciseModal}
          handleShowAlert={handleShowAlert}
          info={selectedItem}
          onSuccess={(exercise) => {
            // add to the spurfit exerciseslist and update the page
            // setAllTrainerExercises(allExercises);
            // setStoreExerciseList(allExercises);
            // setStoreExerciseListTemp(allExercises);
            // setPage(1);
            // setCurrenPageExercise(paginate(allExercises, 1, pageSize));

            let allExercises;
            // if exercise already exists in the list, update it
            const index = allTrainerExercises.findIndex(
              (e) => e.id === exercise.id
            );
            if (index > -1) {
              allExercises = [...allTrainerExercises];
              allExercises[index] = exercise;
            } else {
              allExercises = [...allTrainerExercises, exercise];
            }

            setAllTrainerExercises(allExercises);
            setStoreExerciseList(allExercises);
            setStoreExerciseListTemp(allExercises);

            setCurrenPageExercise(paginate(allExercises, page, pageSize));
          }}
        />
        <div>
          <div className="exercise-header">
            <div className="tab-container-main">
              {!isLivezy && (
                <div className="tab-container">
                  <button
                    className={
                      libraryType == SPUR_FIT
                        ? "tab-button-active"
                        : "tab-button"
                    }
                    onClick={() => {
                      setLibraryType(SPUR_FIT);
                      setSelectedForDeletion([]);
                      setIsDeleteEnabled(false);
                    }}
                  >
                    Spur.fit Library
                  </button>
                  {libraryType == SPUR_FIT && (
                    <div className="active-indicator">
                      <ActiveIndicator width={180} />
                    </div>
                  )}
                </div>
              )}
              <div className="tab-container">
                <button
                  className={
                    libraryType == TRAINER ? "tab-button-active" : "tab-button"
                  }
                  onClick={() => setLibraryType(TRAINER)}
                >
                  My Library
                </button>
                {libraryType == TRAINER && (
                  <div className="active-indicator">
                    <ActiveIndicator width={160} />
                  </div>
                )}
              </div>
            </div>
            <div className="exercise-sub-container">
              <div
                className="channel-search__input__wrapper"
                style={{
                  width: "100%",
                }}
              >
                <div className="channel-search__input__icon">
                  <SearchIcon />
                </div>
                <input
                  className="channel-search__input__text"
                  onChange={(e) => requestSearch(e.target.value)}
                  placeholder="Search"
                  type="text"
                  value={searched}
                />
              </div>

              {tempFilter.length > 0 ? (
                <div
                  onClick={() => {
                    setDisplayFilterModal(true);
                  }}
                  className="funnelicon"
                >
                  <FunnelActiveIcon />
                </div>
              ) : (
                <div
                  onClick={() => {
                    setDisplayFilterModal(true);
                  }}
                  className="funnelicon"
                >
                  <FunnelIcon />
                </div>
              )}

              {libraryType != SPUR_FIT && (
                <button
                  className="button-new-outlined"
                  onClick={() => {
                    setShowAddExerciseModal(true);
                  }}
                  style={{
                    maxWidth: "fit-content",
                  }}
                >
                  <PlusIcon /> Add Exercise
                </button>
              )}
            </div>
          </div>
          {isDeleteEnabled && libraryType === TRAINER && (
            <div>
              <span style={{ fontWeight: 600 }}>
                Select exercises to delete
              </span>
              <Button
                style={{
                  width: "206px",
                  height: "33px",
                  fontSize: "14px",
                  background: "#FF9B04",
                  borderRadius: "8px",
                  color: "#071148",
                  textTransform: "none",
                  marginLeft: "30px",
                }}
                onClick={async () => {
                  setIsLoading(true);
                  await axios({
                    method: "post",
                    url: `${APIURL}CF/api/CoachWeb/Delete/deleteMulitpleMovements`,
                    data: {
                      movementIDs: selectedForDeletion,
                    },
                  })
                    .then((res) => {
                      getTrainerExercises(true);
                      setSelectedForDeletion([]);
                      setIsDeleteEnabled(false);
                      setIsLoading(false);
                      toast("Deleted Successfully!", "success");
                    })
                    .catch((err) => {
                      if (err?.message === "Failed to delete exercise!") {
                        displayAlert(err?.message);
                      } else {
                        displayAlert("Network error, please try again");
                      }
                      setIsLoading(false);
                    });
                }}
              >
                <DoneRoundedIcon style={{ marginRight: "10px" }} />
                Delete {selectedForDeletion.length} exercises
              </Button>
              <Button
                onClick={() => {
                  setSelectedForDeletion([]);
                  setIsDeleteEnabled(false);
                }}
                style={{ color: "#071148", marginLeft: "30px" }}
              >
                Cancel
              </Button>
            </div>
          )}
        </div>
        {isLoading ? (
          <div
            style={{
              width: "100%",
              position: "absolute",
              height: "100%",
              background: "rgba(255,255,255, 0.3)",
              zIndex: 999,
              top: 0,
              left: 0,
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "40%",
                left: "45%",
                background: "#eee",
                width: 200,
                zIndex: 9999,
                padding: 50,
                borderRadius: 15,
              }}
            >
              <SpinnerComponent />
            </div>
          </div>
        ) : (
          <div
            style={{
              height: "calc(100vh - 200px)",
              width: "100%",
              overflowY: "scroll",
              padding: 24,
            }}
            // className="exercise-list-container"
          >
            {currenPageExercise && currenPageExercise?.length > 0 ? (
              <Grid container gridAutoRows={1} spacing={2}>
                <InfiniteScroll
                  fetchMoreData={fetchMoreData}
                  length={currenPageExercise?.length}
                  hasMore={page < totalPage}
                  isLoading={isFetching}
                  key={page}
                  scrollOffset={0.1}
                >
                  {currenPageExercise?.map((item, index) => {
                    return (
                      <Grid item xs={6} md={4}>
                        <ExerciseListItem
                          item={item}
                          index={index}
                          isDeleteEnabled={isDeleteEnabled}
                          libraryType={libraryType}
                          selectedForDeletion={selectedForDeletion}
                          setSelectedForDeletion={setSelectedForDeletion}
                          openDialog={openDialog}
                          setOpenDialog={setOpenDialog}
                          selectedCard={selectedCard}
                          setSelectedCard={setSelectedCard}
                          setVideoInfo={setVideoInfo}
                          setShowUpdateExerciseModal={
                            setShowUpdateExerciseModal
                          }
                          handleCloseMore={handleCloseMore}
                          selectedItem={selectedItem}
                          setSelectedItem={setSelectedItem}
                          deleteSingleExercise={deleteSingleExercise}
                          placeholderImages={placeholderImages}
                        />
                      </Grid>
                    );
                  })}
                </InfiniteScroll>
              </Grid>
            ) : (
              <Box className="flex flex-col w-full h-full items-center justify-center">
                <span
                  style={{
                    fontSize: 22,
                    fontWeight: "500",
                    marginBottom: 10,
                    fontFamily: "DM Sans",
                  }}
                >
                  {allTrainerExercises.length > 0
                    ? "No results found"
                    : "Start adding excercises"}
                </span>
                <img
                  src={PlaceholderImage}
                  style={{
                    width: 300,
                    height: 215,
                    marginTop: 20,
                  }}
                />
                <Typography
                  fontSize={"16px"}
                  fontFamily={"DM Sans"}
                  fontWeight={400}
                  textAlign={"center"}
                  className="text-blue-dark"
                  style={{ marginTop: 15, marginBottom: 15 }}
                >
                  <span>
                    {"Click on "}
                    <span class="font-bold">“Add exercise”</span>
                    {" to start."}
                  </span>
                </Typography>

                <button
                  className="button-new"
                  onClick={() => {
                    setShowAddExerciseModal(true);
                  }}
                >
                  Add exercise
                </button>
              </Box>
            )}
          </div>
        )}

        {libraryType === TRAINER ||
        JSON.parse(localStorage.getItem("trainerCredentials")).password ==
          "PXqGTqMrfblJ" ? (
          <VideoPlayerModel
            videoInfo={videoInfo}
            setOpenDialog={setOpenDialog}
            openDialog={openDialog}
            handleUpdateExercise={handleUpdateExercide}
            handleDeleteExercise={handleDeleteExerciseFunc}
            setSelectedItem={setSelectedItem}
          />
        ) : (
          <VideoPlayerModel
            videoInfo={videoInfo}
            setOpenDialog={setOpenDialog}
            openDialog={openDialog}
            setSelectedItem={setSelectedItem}
          />
        )}
        {showUpdateExerciseModal && (
          <AddExerciseModal
            open={showUpdateExerciseModal}
            setOpen={setShowUpdateExerciseModal}
            info={selectedItem}
            libraryType={libraryType}
            handleShowAlert={handleShowAlert}
            setVideoInfo={setVideoInfo}
            onSuccess={(exercise) => {
              let allExercises;
              // if exercise already exists in the list, update it
              const index = allTrainerExercises.findIndex(
                (e) => e.id === exercise.id
              );
              if (index > -1) {
                allExercises = [...allTrainerExercises];
                allExercises[index] = exercise;
              } else {
                allExercises = [...allTrainerExercises, exercise];
              }

              setAllTrainerExercises(allExercises);
              setStoreExerciseList(allExercises);
              setStoreExerciseListTemp(allExercises);

              setCurrenPageExercise(paginate(allExercises, page, pageSize));
            }}
          />
        )}

        <FilterExerciseModal
          openModal={displayFilterModal}
          setOpenModal={setDisplayFilterModal}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          tempFilter={tempFilter}
          setTempFilter={setTempFilter}
          libraryType={libraryType}
          allTrainerExercises={allTrainerExercises}
        />
      </div>
      {showAlert.show && (
        <div
          style={{
            position: "absolute",
            zindex: 999,
            bottom: "5%",
            display: "flex",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <Alert severity={showAlert.type}>{showAlert.message}</Alert>
        </div>
      )}
    </Layout>
  );
};

export default AllExercises;
