import { Button } from "@mui/material";

const CustomButton = ({
  onClick = () => {},
  backgroundColor = "#ffffff",
  color = "#000000",
  textTransform = "none",
  border = "",
  fullWidth,
  size,
  borderRadius,
  disabled = false,
  margin,
  children,
  style = {},
  ...props
}) => {
  return (
    <Button
      fullWidth={fullWidth} // boolean
      size={size} // 'small' | 'medium' | 'large';
      onClick={onClick} // Action
      disabled={disabled} // boolean
      style={{
        color,
        border,
        margin,
        borderRadius,
        textTransform,
        backgroundColor,
        ...style,
      }}
      {...props}
    >
      {children}
    </Button>
  );
};

export default CustomButton;
