import RichTextInput from "@/pages/profile/RichTextInput.jsx";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";

import { Loader, Pencil } from "lucide-react";
import { useEffect, useState } from "react";
import RichTextEditor from "react-rte";
import { updateCoachDetails } from "@/ApiServices/Coach/Api";

const EditBio = ({ trainerInfo, getProfileData, onBioChange }) => {
  const [bio, setBio] = useState(RichTextEditor.createEmptyValue());
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (typeof trainerInfo?.bio === "string") {
      let richTextBio = RichTextEditor.createValueFromString(
        `${trainerInfo?.bio}`,
        "html"
      );
      setBio(richTextBio);
      console.log("Bio", richTextBio);
    }
  }, [trainerInfo]);

  const onSave = async () => {
    console.log("saving", bio.toString("markdown"));
    setLoading(true);
    let res = await updateCoachDetails({
      coachID: trainerInfo?.id,
      bio: bio.toString("html"),
    });
    if (res) {
      onBioChange(bio.toString("html"));
      setOpen(false);
    }
    setLoading(false);
  };

  const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: ["INLINE_STYLE_BUTTONS", "HISTORY_BUTTONS"],
    INLINE_STYLE_BUTTONS: [
      { label: "Bold", style: "BOLD", className: "custom-css-class" },
      { label: "Italic", style: "ITALIC" },
      { label: "Underline", style: "UNDERLINE" },
      { label: "Strikethrough", style: "STRIKETHROUGH" },
    ],
    // BLOCK_TYPE_DROPDOWN: [
    //   { label: "Normal", style: "unstyled" },
    //   { label: "Heading Large", style: "header-one" },
    //   { label: "Heading Medium", style: "header-two" },
    //   { label: "Heading Small", style: "header-three" },
    // ],
    // BLOCK_TYPE_BUTTONS: [
    //   { label: "UL", style: "unordered-list-item" },
    //   { label: "OL", style: "ordered-list-item" },
    // ],
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Pencil color={"#515156"} />
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="font-DMSans text-font20">About</DialogTitle>
          <div>
            {/* <RichTextEditor value={value} onChange={onChange} /> */}
            <RichTextEditor
              toolbarConfig={toolbarConfig}
              value={bio}
              onChange={(value) => {
                console.log("Value", value.toString("markdown"), "\n", value);
                setBio(value);
              }}
            />
          </div>
        </DialogHeader>
        <DialogFooter className="w-full flex items-center justify-center">
          <button onClick={onSave} className="button-new" disabled={loading}>
            {loading ? <Loader color="#fff" /> : "Save"}
          </button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
export default EditBio;
