import { Typography } from "@mui/material";
import axios from "axios";
import moment from "moment";
import { useState } from "react";
import AssignMealScheduleToUser from "../../features/AddClients/AssignMealScheduleToUser";
import AssignProgramToUser from "../../features/AddClients/AssignProgramToUser";
import AthleteStatus from "../ClientsView/AthleteStatus";

import { useSelector } from "react-redux";
import EditIcon from "../../assets/workouts/EditIcon.svg";
import AssignMealInRangeToUser from "@/features/AddClients/AssignMealInRangeToUser";
import { assignPhases } from "@/ApiServices/Programs/Api";
import { assignPhasedProgramDashboard } from "@/ApiServices/Dashboard/Api";

export const HeaderCell = ({ label }) => {
  return (
    <Typography
      sx={{
        fontSize: "13px",
        lineHeight: "20px",
        fontFamily: "DM Sans",
        color: "#333333",
        fontWeight: 500,
      }}
    >
      {label}
    </Typography>
  );
};

export const FirstCell = ({ params, val, onClick }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        minWidth: "120px",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <img
        style={{ height: "20px", width: "20px", borderRadius: "50%" }}
        src={
          params?.row?.UserImageKey?.includes("null") ||
          !params?.row?.UserImageKey
            ? "https://spurfitpublicimages.s3-accelerate.amazonaws.com/AppImages/assets/detailScreen/sportsman.png"
            : params?.row?.UserImageKey
        }
        alt="profile"
      />
      <Typography
        sx={{
          fontSize: "13px",
          lineHeight: "20px",
          fontFamily: "DM Sans",
          ml: "10px",
          color: "#323130",
        }}
      >
        {val ?? params?.row?.Name}
      </Typography>
    </div>
  );
};

export const ProgramCell = ({ params, onEditClick, onProgramClick }) => {
  const [showEditIcon, setShowEditIcon] = useState(false);
  return (
    <Typography
      onClick={
        params.row.ProgramName && params.row.ProgramName != "-"
          ? onProgramClick
          : onEditClick
      }
      sx={{
        cursor: "pointer",
        fontSize: "13px",
        lineHeight: "16px",
        fontFamily: "DM Sans",
        color: "#605E5C",
        fontWeight: 400,
        whiteSpace: "normal",
        width: "100%",
        textOverflow: "ellipsis",
        textAlign: "center",
        "&:hover": {
          // underline
          textDecoration: "underline",
        },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onMouseEnter={() => setShowEditIcon(true)}
      onMouseLeave={() => setShowEditIcon(false)}
    >
      {(params.row.ProgramName && params.row.ProgramName != "-") ||
      params.row?.phasedProgramName
        ? params?.row?.phasedProgramName ?? params.row.ProgramName
        : "Add"}
      {params.row.ProgramName && params.row.ProgramName != "-" && (
        <img
          onClick={(e) => {
            e.stopPropagation();
            onEditClick();
          }}
          src={EditIcon}
          alt="edit"
          style={{
            display: showEditIcon ? "block" : "none",
            width: "15px",
            height: "15px",
            marginLeft: "5px",
          }}
        />
      )}
    </Typography>
  );
};

export const MealCell = ({ params, onEditClick, onMealClick }) => {
  const [showEditIcon, setShowEditIcon] = useState(false);
  return (
    <Typography
      onClick={
        params.row.mealPlanName && params.row.mealPlanName != "-"
          ? onMealClick
          : onEditClick
      }
      sx={{
        cursor: "pointer",
        fontSize: "13px",
        lineHeight: "16px",
        fontFamily: "DM Sans",
        color: "#605E5C",
        fontWeight: 400,
        whiteSpace: "normal",
        width: "100%",
        textOverflow: "ellipsis",
        textAlign: "center",
        "&:hover": {
          // underline
          textDecoration: "underline",
        },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onMouseEnter={() => setShowEditIcon(true)}
      onMouseLeave={() => setShowEditIcon(false)}
    >
      {params.row.mealPlanName && params.row.mealPlanName != "-"
        ? params.row.mealPlanName
        : "Add "}

      {params.row.mealPlanName && params.row.mealPlanName != "-" && (
        <img
          onClick={(e) => {
            e.stopPropagation();
            onEditClick();
          }}
          src={EditIcon}
          alt="edit"
          style={{
            display: showEditIcon ? "block" : "none",
            width: "15px",
            height: "15px",
            marginLeft: "5px",
          }}
        />
      )}
    </Typography>
  );
};

export const StatusCell = ({
  params,
  showText,
  getTrainerDashboard,
  buddyData,
  onboardingData,
}) => {
  let buddyRow = onboardingData.find((item) => {
    return buddyData?.[params.row.buddyClientID_LivEzy] === item.UserUUID;
  });
  return (
    <Typography
      sx={{
        fontSize: "13px",
        lineHeight: "16px",
        fontFamily: "DM Sans",
        color: "#605E5C",
        fontWeight: 400,
        whiteSpace: "normal",
        width: "100%",
        textOverflow: "ellipsis",
        textAlign: "center",
      }}
    >
      {/* {params.row.Status} */}
      <AthleteStatus
        onboardingStatus={
          params.row.onboardingStatus_LivEzy === "Questionaire filled"
            ? "Questionnaire filled"
            : params.row.onboardingStatus_LivEzy
        }
        userUUID={params.row.UserUUID}
        showText={showText}
        changeUserInfo={getTrainerDashboard}
        rowData={params.row}
        buddyRow={buddyRow}
      />
    </Typography>
  );
};

export const GenericCell = ({ label }) => {
  return (
    <Typography
      sx={{
        fontSize: "13px",
        lineHeight: "16px",
        fontFamily: "DM Sans",
        color: "#605E5C",
        fontWeight: 400,
        whiteSpace: "normal",
        width: "100%",
        textOverflow: "ellipsis",
        textAlign: "center",
      }}
    >
      {label}
    </Typography>
  );
};

export const AllModals = ({
  selectedUser,
  setSelectedUser,
  showAddProgramModal,
  setShowAddProgramModal,
  getTrainerDashboard,
  showAddMealModal,
  setShowAddMealModal,
}) => {
  const APIURL = useSelector((state) => state.auth.apiURL);
  //   const [showAddProgramModal, setShowAddProgramModal] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [programStartDate, setProgramStartDate] = useState(new Date());
  const [selectedDay, setSelectedDay] = useState({ value: 1, label: "Day 1" });
  const [showWorkOutBuilder, setShowWorkOutBuilder] = useState(false);
  const [showFinalStep, setShowFinalStep] = useState(false);
  const [openImportMealModal, setOpenImportMealModal] = useState(false);
  const [selectedMeal, setSelectedMeal] = useState(null);
  const [mealStartDate, setMealStartDate] = useState(new Date());
  const [mealEndDate, setMealEndDate] = useState(new Date());
  const [loading, setLoading] = useState(false);

  const resetProgramModalStates = () => {
    setSelectedProgram(null);
    setProgramStartDate(new Date());
    setSelectedDay(1);
  };

  const resetMealModalStates = () => {
    setSelectedMeal(null);
    setMealStartDate(new Date());
    setMealEndDate(new Date());
    setSelectedDay(1);
  };

  const getFormattedDataToAssign = (selectedProgram) => {
    let curProgStartDate =
      moment(programStartDate).format("YYYY-MM-DD") + "T00:00:00.000Z";

    let formattedData = {};
    let progStartDay = parseInt(selectedDay.value);

    selectedProgram.programs.forEach((program, index) => {
      formattedData[program.id] = {
        programID: program.id,
        atheleteIDs: [selectedUser?.UserUUID],
        startDate: curProgStartDate,
        startProgDay: progStartDay,
      };

      let nextProgStartDate =
        moment(curProgStartDate)
          .add(program.duration, "days")
          .format("YYYY-MM-DD") + "T00:00:00.000Z";

      // if progStartDay is more than 1, then decrease progStartDay from nextProgStartDate
      if (progStartDay > 1) {
        nextProgStartDate =
          moment(nextProgStartDate)
            .subtract(progStartDay - 1, "days")
            .format("YYYY-MM-DD") + "T00:00:00.000Z";
      }

      curProgStartDate = nextProgStartDate;

      progStartDay = 1;
    });
    return Object.values(formattedData);
  };

  const assignProgramToUser = async (userUUID, selectedProgram, data) => {
    if (selectedProgram?.programs) {
      // assign phased program to user
      setLoading(true);
      try {
        // let formattedData = getFormattedDataToAssign(selectedProgram);
        await assignPhasedProgramDashboard({
          assignmentData: data,
          atheleteID: selectedUser?.UserUUID,
          phaseContainerID: selectedProgram.id,
        });
        getTrainerDashboard();
      } catch (error) {
        console.log("error in assigning program to user", error);
      } finally {
        setShowAddProgramModal(false);
        setLoading(false);
        resetProgramModalStates();
      }
    } else {
      setLoading(true);
      await axios({
        method: "post",
        url: `${APIURL}CF/api/programs/assignProgramFromDashboard`,
        data: {
          programID: selectedProgram.id,
          athleteID: userUUID,
          startDate: `${moment(programStartDate)
            .startOf("day")
            .format("YYYY-MM-DDTHH:mm:ss")}Z`,
          startProgDay: parseInt(selectedDay.value),
        },
      })
        .then((res) => {
          console.log("assigned program to user", res);
          // close all modals
          getTrainerDashboard();
        })
        .catch((err) => {
          console.log("error in assigning program to user", err);
        })
        .finally(() => {
          setShowAddProgramModal(false);
          setLoading(false);
          resetProgramModalStates();
        });
    }
  };

  const assignMealToUser = async (userUUID, selectedMeal) => {
    setLoading(true);
    await axios({
      method: "post",
      url: `${APIURL}CF/api/meal/assignMealMultipleAthlete`,
      data: {
        athleteIDs: [userUUID],
        mealId: selectedMeal.id,
        startDate:
          moment(mealStartDate)?.format("YYYY-MM-DD") + "T00:00:00.000Z",
        endDate: moment(mealEndDate).format("YYYY-MM-DD") + "T00:00:00.000Z",
      },
    })
      .then((res) => {
        console.log("assigned meal to user");
        getTrainerDashboard();
      })
      .catch((err) => {
        console.log("error in assigning meal to user", err);
      })
      .finally(() => {
        setShowAddMealModal(false);
        setLoading(false);
      });
  };

  const asignMealScheduleToUser = async (userUUID, selectedMeal) => {
    setLoading(true);
    await axios({
      method: "post",
      url: `${APIURL}CF/api/assignMealScheduleSegmentedMultiple`,
      data: {
        mealScheduleID: selectedMeal.id,
        userUUIDs: [userUUID],
        startDate: `${moment(mealStartDate)
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ss")}Z`,
        startProgDay: parseInt(selectedDay.value),
      },
    })
      .then((res) => {
        console.log("assigned meal to user");
        getTrainerDashboard();
      })
      .catch((err) => {
        console.log("error in assigning meal to user", err);
      })
      .finally(() => {
        setShowAddMealModal(false);
        setLoading(false);
      });
  };

  return (
    <>
      <AssignProgramToUser
        loading={loading}
        isOpen={showAddProgramModal}
        setIsOpen={setShowAddProgramModal}
        selectedProgram={selectedProgram}
        setSelectedProgram={setSelectedProgram}
        startDate={programStartDate}
        setStartDate={setProgramStartDate}
        selectedDay={selectedDay}
        setSelectedDay={setSelectedDay}
        selectedUser={selectedUser}
        onSave={(data) => {
          assignProgramToUser(selectedUser?.UserUUID, selectedProgram, data);
        }}
        onBack={() => {
          setShowAddMealModal(false);
          resetProgramModalStates();
        }}
        onClose={resetProgramModalStates}
        onUnassignSuccess={() => {
          getTrainerDashboard();
          setShowAddProgramModal(false);
          resetProgramModalStates();
        }}
      />

      <AssignMealInRangeToUser
        loading={loading}
        isOpen={showAddMealModal}
        setIsOpen={setShowAddMealModal}
        selectedMeal={selectedMeal}
        setSelectedMeal={setSelectedMeal}
        startDate={mealStartDate}
        setStartDate={setMealStartDate}
        endDate={mealEndDate}
        setEndDate={setMealEndDate}
        selectedDay={selectedDay}
        setSelectedDay={setSelectedDay}
        onBack={() => {
          setShowAddMealModal(false);
          resetMealModalStates();
        }}
        onSave={() => {
          assignMealToUser(selectedUser?.UserUUID, selectedMeal);
        }}
        onClose={resetMealModalStates}
        planDuration={selectedUser?.planDuration_LivEzy}
      />

      {/* {showAddMealModal && (
        <AssignMealScheduleToUser
          loading={loading}
          isOpen={showAddMealModal}
          setIsOpen={setShowAddMealModal}
          selectedMealSchedule={selectedMeal}
          setSelectedMealSchedule={setSelectedMeal}
          startDate={mealStartDate}
          setStartDate={setMealStartDate}
          selectedDay={selectedDay}
          setSelectedDay={setSelectedDay}
          onBack={() => {
            setShowAddMealModal(false);
            resetMealModalStates();
          }}
          onSave={() => {
            asignMealScheduleToUser(selectedUser?.UserUUID, selectedMeal);
          }}
          onClose={resetMealModalStates}
        />
      )} */}
    </>
  );
};

export const getDaysLeft = (userInfo) => {
  if (userInfo?.planEndDate_LivEzy) {
    let totalDays = moment(userInfo?.planEndDate_LivEzy).diff(moment(), "days");
    let text = `${totalDays} days`;
    if (totalDays <= 0) text = "-";
    return text;
  } else {
    return "-";
  }
};

export const isPlanExpired = (userInfo) => {
  if (userInfo?.onboardingStatus_LivEzy === "Plan expired") return true;
  if (userInfo?.planEndDate_LivEzy) {
    let totalDays = moment(userInfo?.planEndDate_LivEzy).diff(moment(), "days");
    return totalDays <= 0;
  } else {
    return false;
  }
};
