import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { chatClient, chatInit } from "../../utils/chat";
import { useSelector } from "react-redux";
import { useChatContext } from "stream-chat-react";
import DefaultProfileImg from "../../assets/clientProfile/defaultProfileImage.svg";
import UncheckedIcon from "../../assets/trainerProfile/unchecked.svg";
import CheckedIcon from "../../assets/trainerProfile/checked.svg";
const ClientCard = ({
  client,
  onSelectClient,
  isSelected,
  selectedRef,
  userUnreadMessage,
  isBroadcast,
  onSelectForBroadcast,
  isSelectedForBroadcast,
  unreadMarks,
  unread,
}) => {
  let name = client?.Name;
  let email = client?.Email;
  let programName = client?.ProgramName;
  const coachID = useSelector((state) => state?.auth?.trainerUUID);
  const { channel, setActiveChannel } = useChatContext();
  const [currentUnreadCount, setCurrentUnreadCount] = useState(0);
  const [markedAsUnread, setMarkedAsUnread] = useState(client?.markedAsUnread);

  useEffect(() => {
    if (
      userUnreadMessage?.[client?.UserUUID] &&
      userUnreadMessage?.[client?.UserUUID] != currentUnreadCount
    ) {
      setCurrentUnreadCount(userUnreadMessage?.[client?.UserUUID]);
    }
  }, [userUnreadMessage]);

  useEffect(() => {
    let unreadMarkForClient = unreadMarks?.[client?.UserUUID];
    if (unreadMarkForClient) {
      setMarkedAsUnread(true);
    } else {
      setMarkedAsUnread(false);
    }
  }, [unreadMarks]);

  return (
    <Box
      ref={selectedRef}
      sx={{
        display: "flex",
        alignItems: "center",
        borderRadius: "8px",
        margin: isSelected ? "5px 0px 5px 0px" : "0px",
        // borderBottom: "1px solid #E5E5E5",
        padding: "8px 10px",
        cursor: "pointer",
        background: isSelected
          ? "linear-gradient(90deg, rgba(70, 79, 235, 0.1) 10.42%, rgba(131, 48, 233, 0.1) 100%)"
          : unread > 0
          ? "#F2F2F2"
          : "transparent",
        "&:hover": {
          backgroundColor: "#F5F5F5",
        },
        position: "relative",
      }}
      onClick={() => {
        if (isBroadcast) return;
        if (channel) {
          setActiveChannel(null);
        }
        onSelectClient(client);
        setCurrentUnreadCount(0);
      }}
    >
      <div
        style={{
          display: isSelected ? "flex" : "none",
          position: "absolute",
          left: "0px",
          borderRadius: "1px 3px 3px 1px",
          background: "linear-gradient(360deg, #464FEB 10.42%, #8330E9 100%)",
          height: "70%",
          width: "3px",
        }}
      ></div>
      {unread > 0 ? (
        <span className="font-DMSans text-xs text-center bg-red-400 rounded-full w-[18px] h-[18px] absolute right-[8px] top-1/2 -translate-y-1/2 text-white-pure">
          {unread}
        </span>
      ) : null}

      {unread <= 0 && markedAsUnread ? (
        <span className="font-DMSans text-xs text-center text-red-400 bg-red-400  rounded-full w-[18px] h-[18px] absolute right-1 top-1/2 -translate-y-1/2">
          U
        </span>
      ) : null}

      {isBroadcast ? (
        <img
          src={isSelectedForBroadcast ? CheckedIcon : UncheckedIcon}
          style={{
            height: "18px",
            width: "18px",
            marginRight: "1rem",
            cursor: "pointer",
          }}
          onClick={(e) => {
            e.stopPropagation();
            onSelectForBroadcast(client);
          }}
        />
      ) : null}

      <img
        src={
          client?.UserImageKey ||
          "https://img.icons8.com/color/48/circled-user-male-skin-type-7--v1.png"
        }
        style={{
          width: "32px",
          height: "32px",
          borderRadius: "50%",
          marginRight: "0.5rem",
        }}
      />
      <Box>
        <p
          style={{
            fontFamily: "DM Sans",
            fontWeight: isSelected ? 500 : 400,
            fontSize: "16px",
            lineHeight: "20.83px",
            color: "#00000",
          }}
        >
          {name}
        </p>
        {/* <p
          style={{
            marginTop: 2,
            fontFamily: "DM Sans",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "15px",
            color: "#424242",
          }}
        >
          {" "}
          {email}
        </p>

        <p
          style={{
            fontFamily: "DM Sans",
            fontWeight: 500,
            fontSize: "14px",
            color: "#424242",
          }}
        >
          {" "}
          {programName}
        </p> */}
      </Box>
    </Box>
  );
};

export default ClientCard;
