import { CustomAlertContext } from "@/App";
import AlertComponent from "@/components/AlertComponent";
import AddTargetUserThresold from "@/components/ClientsView/AddTargetUserThresold";
import WorkoutBuilderModal from "@/components/WorkoutComponent/WorkoutBuilderModal";
import { CloseRounded, DeleteRounded } from "@mui/icons-material";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import { Box, Stack, Typography } from "@mui/material";
import axios from "axios";
import { RefreshCcw, Watch } from "lucide-react";
import moment from "moment";
import { forwardRef, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SpurfitCircularProgress } from "../../../components/Dashboard/dashboard";
import { formatSecondsToMMSS } from "../../../components/WorkoutComponent/helpers";
import Round from "./Round";
import UpdateCardioDialog from "./UpdateCardioDialog";
import React from "react";
import { cn } from "@/lib/utils";

const WorkoutContentPage = forwardRef(
  (
    {
      rounds,
      setRounds,
      deleteIndividualWorkout,
      data,
      showBackButton,
      setData,
      isGarminConnected,
      onGarminConnect,
      showEditButton,
      onSuccess,
      thresholdValues,
      fromWorkout,
      onSuccessOfEditWokroutFromWorkout,
      ...props
    },
    ref
  ) => {
    const APIURL = useSelector((state) => state.auth.apiURL);
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [topHeight, setTopHeight] = useState("auto");
    const [showUpdateCardio, setShowUpdateCardio] = useState(false);
    const [showCantAssignModal, setShowCantAssignModal] = useState(false);

    const [isThresholdAdded, setIsThresholdAdded] = useState(false);
    const [showAddThreshold, setShowAddThreshold] = useState(false);

    const [alertMessage, setAlertMessage] = useState("");
    const [showThresholdAlert, setShowThresholdAlert] = useState(false);
    const [syncingGarminWorkouts, setSyncingGarminWorkouts] = useState({});
    const [showWorkoutBuilderModal, setShowWorkoutBuilderModal] =
      useState(false);

    const [editInfo, setEditInfo] = useState({});

    React.useImperativeHandle(ref, () => ({
      setShowWorkoutBuilderModal: () => setShowWorkoutBuilderModal(true),
    }));
    const { displayAlert } = useContext(CustomAlertContext);
    let showEditBtn = showEditButton === false ? false : true;
    const isEnduranceWorkout = (workoutKind) => {
      return (
        workoutKind === "run" ||
        workoutKind === "bike" ||
        workoutKind === "swim"
      );
    };

    useEffect(() => {
      if (props?.topRefToUse?.current?.offsetHeight) {
        setTopHeight(props?.topRefToUse?.current?.offsetHeight);
      } else {
        setTopHeight("auto");
      }
    }, [props?.topRefToUse?.current?.offsetHeight]);

    const postWorkout = async () => {
      let isThresholdValueAvailable =
        (props?.isThresholdAvailable &&
          props?.isThresholdAvailable != "null") ||
        isThresholdAdded;
      if (
        data?.workoutContent?.isThresholdPercentChecked &&
        !isThresholdValueAvailable
      ) {
        setShowThresholdAlert(true);
        // displayAlert({
        //   message: "Please add threshold value of user to assign this workout",
        //   type: "error",
        // });
        return;
      }
      setLoading(true);
      let url = `${APIURL}CF/api/modules`;

      let d = {
        userUUID: `${props?.userUUID}`,
        modulesUUID: `${data.id}`,
        moduleDate: `${moment(props?.date, "DD-MM-YYYY")
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ss")}Z`,
        isEnduranceWorkout:
          data?.workoutKind === "run" ||
          data?.workoutKind === "bike" ||
          data?.workoutKind === "swim",
      };

      await axios({
        method: "post",
        url: url,
        data: d,
      })
        .then((res) => {
          console.log("posted workout: ", res.data);
          // history.push(`/client/${props.userUUID}/`);
          // history.go(-2);
          history.replace(
            `/clientsView?userUUID=${props.userUUID}&assigned=${true}`
          );
        })
        .catch((err) => {
          console.log("error in CF Post workout in", err?.response?.data);
          if (err?.response?.data?.isThresholdError) {
            setAlertMessage(
              err?.response?.data?.message +
                ". Workout assigned but not pushed to garmin."
            );
            setShowThresholdAlert(true);
            // displayAlert({
            //   message: err?.response?.data?.message + ". Workout assigned but not pushed to garmin.",
            //   type: "error",
            //   time: 10000,
            // })
          } else if (err?.response?.data?.code == "P2002") {
            setShowCantAssignModal(true);
          }
        })
        .finally(() => {
          setLoading(false);
        });
      setLoading(false);
    };

    return (
      <Stack
        sx={{
          borderRadius: "12px",
          // marginBottom: "24px",
          // padding: "5px",
          height: "100%",
          width: "100%",
          background: "white",
        }}
        className={cn({ "shadow-xl border": !fromWorkout })}
      >
        {loading && (
          <div
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              height: "100vh",
              width: "100vw",
              background: "rgba(255,255,255,0.5)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: "999",
            }}
          >
            <SpurfitCircularProgress />
          </div>
        )}
        {showCantAssignModal && (
          <div
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              height: "100vh",
              width: "100vw",
              background: "rgba(0,0,0,0.4)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: "999",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                minWidth: "500px",
                color: "black",
                background: "white",
                borderRadius: "10px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  borderBottom: "1px solid grey",
                  width: "100%",
                  padding: "15px 0px",
                }}
              >
                <CloseRounded
                  onClick={() => {
                    setShowCantAssignModal(false);
                    history.goBack();
                  }}
                  style={{
                    color: "black",
                    cursor: "pointer",
                    marginRight: "15px",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  padding: "20px 20px",
                }}
              >
                {/* <ErrorRoundedIcon
                style={{
                  width: "50px",
                  height: "50px",
                  background:
                    "linear-gradient(to right, rgb(70, 79, 235), rgb(131, 48, 233))",
                  marginBottom: "10px",
                }}
              /> */}
                <div
                  style={{
                    width: "50px",
                    height: "50px",
                    background:
                      "linear-gradient(to right, rgb(70, 79, 235), rgb(131, 48, 233))",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "50%",
                    marginBottom: "15px",
                  }}
                >
                  <ErrorRoundedIcon
                    style={{ color: "#FFF", fontSize: "47px" }}
                  />
                </div>
                <p
                  style={{
                    fontSize: "18px",
                    fontFamily: "DM Sans",
                    fontWeight: "500",
                    color: "black",
                  }}
                >
                  You can not assign two same workout to the same user on same
                  day.
                </p>
              </div>
            </div>
          </div>
        )}
        {!fromWorkout && (
          <Stack
            direction={"row"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            // mb={"10px"}
            className="section-header jatin"
            sx={{
              background: "transparent",
              position: "relative",
            }}
            style={{
              padding: "35px 20px",
            }}
          >
            {showBackButton && (
              <ArrowBackIosNewRoundedIcon
                onClick={() => {
                  history.goBack();
                }}
                style={{
                  color: "#000",
                  cursor: "pointer",
                  marginRight: "8px",
                  position: "absolute",
                  left: "0",
                }}
              />
            )}
            <div
              className="section-header-left"
              style={{
                width: "90%",
                padding: "2px 10px",
              }}
            >
              <p
                className="testchannel"
                style={{
                  marginRight: "auto",
                  fontFamily: "DM Sans",
                  fontSize: "18px",
                  fontWeight: "700",
                  marginLeft: showBackButton ? "12px" : "0px",
                }}
              >
                {data?.name}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
                marginRight: "4px",
              }}
            >
              {props?.isEndurance &&
                (isGarminConnected ? (
                  <Watch size={24} className="mr-1" color="#32CD32" />
                ) : (
                  <div
                    onClick={async (e) => {
                      e.stopPropagation();
                      setSyncingGarminWorkouts({
                        ...syncingGarminWorkouts,
                        [data.id]: true,
                      });

                      await onGarminConnect();

                      setSyncingGarminWorkouts({
                        ...syncingGarminWorkouts,
                        [data.id]: false,
                      });
                    }}
                    // if syncing then rotate the icon
                    className={"cursor-pointer"}
                  >
                    {syncingGarminWorkouts[data?.id] ? (
                      <SpurfitCircularProgress size={24} />
                    ) : (
                      <RefreshCcw size={24} className="mr-1" color="red" />
                    )}
                  </div>
                ))}
              {props?.isImport ? (
                <>
                  <button
                    className="button-new-outlined"
                    style={{ width: "60px" }}
                    onClick={postWorkout}
                  >
                    Assign
                  </button>
                  {showEditBtn && (
                    <button
                      className="button-new-outlined"
                      style={{ width: "60px" }}
                      onClick={() => {
                        let isEndurance =
                          data?.workoutKind === "run" ||
                          data?.workoutKind === "bike";

                        if (!data?.isBlockWorkoutBuilder && isEndurance) {
                          history.push(`/EnduranceWorkoutBuilder`, {
                            isUpdate: true,
                            data: data,
                            isImport: props?.isImport,
                            isCalendar: props?.isCalendar,
                            isUpdate: true,
                            extraData: {
                              userUUID: `${props?.userUUID}`,
                              moduleDate: `${moment(props?.date, "DD-MM-YYYY")
                                .startOf("day")
                                .format("YYYY-MM-DDTHH:mm:ss")}Z`,
                              trackingID: props?.workoutID,
                            },
                          });
                        } else {
                          setEditInfo({
                            isUpdate: true,
                            isCalendar: props?.isCalendar,
                            userUUID: `${props?.userUUID}`,
                            moduleDate: `${moment(props?.date, "DD-MM-YYYY")
                              .startOf("day")
                              .format("YYYY-MM-DDTHH:mm:ss")}Z`,
                            trackingID: props?.workoutID,
                          });
                          setShowWorkoutBuilderModal(true);
                        }
                      }}
                    >
                      Edit
                    </button>
                  )}
                </>
              ) : (
                <>
                  {showEditBtn && !props?.isFinished && (
                    <button
                      className="button-new-outlined"
                      style={{ width: "60px" }}
                      onClick={() => {
                        if (isCardio(data?.id)) {
                          setShowUpdateCardio(true);
                        } else {
                          let isEndurance =
                            data?.workoutKind === "run" ||
                            data?.workoutKind === "bike";

                          if (!data?.isBlockWorkoutBuilder && isEndurance) {
                            history.push(`/EnduranceWorkoutBuilder`, {
                              isUpdate: true,
                              data: data,
                              isImport: props?.isImport,
                              isCalendar: props?.isCalendar,
                              isUpdate: true,
                              extraData: {
                                userUUID: `${props?.userUUID}`,
                                moduleDate: `${moment(props?.date, "DD-MM-YYYY")
                                  .startOf("day")
                                  .format("YYYY-MM-DDTHH:mm:ss")}Z`,
                                trackingID: props?.workoutID,
                              },
                            });
                          } else {
                            setShowWorkoutBuilderModal(true);
                          }
                        }
                      }}
                    >
                      Edit
                    </button>
                  )}
                  {!props?.isFinished && !(props.canDelete === false) && (
                    <div className="delete-workouts-btn">
                      <DeleteRounded
                        className="delete-btn-icon-in-showWorkouts"
                        onClick={() => {
                          let isEndurance =
                            data?.workoutKind === "run" ||
                            data?.workoutKind === "swim" ||
                            data?.workoutKind === "bike";
                          deleteIndividualWorkout(
                            props?.workoutID,
                            isEndurance
                          );
                        }}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </Stack>
        )}
        <Box
          sx={{
            position: "relative",
            height: topHeight,
          }}
          ref={props?.topRef}
          // mb={"10px"}
        >
          {props?.feedback && props?.feedback != "" && (
            <Typography
              sx={{
                mt: "16px",
                fontSize: 12,
                fontWeight: 400,
                display: "flex",
                color: "#000",
              }}
            >
              <span style={{ fontWeight: 600, width: "66px" }}>
                {props?.isFinished ? "feedback:" : " "}
              </span>{" "}
              {props?.feedback}
            </Typography>
          )}

          {props?.difficultyLevel && props?.difficultyLevel != "" && (
            <Typography
              sx={{
                mt:
                  props?.feedback && props?.feedback?.trim() != ""
                    ? "2px"
                    : "16px",
                fontSize: 12,
                fontWeight: 400,
                display: "flex",
                color: "#000",
                padding: "0px 16px",
              }}
            >
              <span style={{ fontWeight: 600, width: "66px" }}>
                {props?.isFinished ? "Difficulty:" : " "}
              </span>

              {props?.difficultyLevel}
            </Typography>
          )}

          {props?.dateFinished && props?.dateFinished != "" && (
            <Typography
              sx={{
                mt:
                  props?.difficultyLevel && props?.difficultyLevel != ""
                    ? "2px"
                    : "16px",
                fontSize: 12,
                fontWeight: 400,
                display: "flex",
                color: "#000",
                padding: "0px 16px",
              }}
            >
              <span style={{ fontWeight: 600 }}>
                {props?.isFinished ? "Date Finished:" : ""}&nbsp;&nbsp;
                {props?.isFinished && props?.dateFinished
                  ? moment(props?.dateFinished).format("DD/MM/YYYY")
                  : " "}
              </span>
            </Typography>
          )}
        </Box>
        {!isCardio(data?.id) && !isCardioWorkout(data) ? (
          <Stack gap={"12px"}>
            {rounds?.map((round, index) => {
              return (
                <Stack gap={"12px"}>
                  <Round
                    key={index}
                    index={index}
                    round={round.round}
                    setRounds={setRounds}
                    isEndurance={
                      data?.workoutKind === "run" ||
                      data?.workoutKind === "bike" ||
                      data?.workoutKind === "swim"
                    }
                    thresholdValues={thresholdValues}
                    isRun={data?.workoutKind === "run"}
                  />

                  {
                    // rounds.length > 1 && index !== rounds.length - 1&&
                    <RenderRest
                      key={"rest" + index}
                      rounds={rounds}
                      setRounds={setRounds}
                      index={index}
                    />
                  }
                </Stack>
              );
            })}
          </Stack>
        ) : (
          <div className="flex relative">
            <p
              className="font-DMSans text-font14 font-medium bg-white-pure text-[#616161] p-4 rounded-xl"
              style={{
                overflowWrap: "anywhere",
                width: "100%",
              }}
            >
              <span>Instruction: </span>
              <textarea
                className="bg-white-pure text-[#616161] p-2 rounded-xl"
                style={{
                  overflowWrap: "anywhere",
                  width: "100%",
                  padding: "4px",
                }}
                disabled
              >
                {data?.description ?? "Do a cardio of your choice"}
              </textarea>
            </p>
          </div>
        )}
        {showUpdateCardio && (
          <UpdateCardioDialog
            isOpen={showUpdateCardio}
            setIsOpen={setShowUpdateCardio}
            info={data}
            onUpdateCardio={(newCardioWorkout) => {
              setData(newCardioWorkout);
            }}
            key={data?.id}
          />
        )}

        {showWorkoutBuilderModal && (
          <WorkoutBuilderModal
            open={showWorkoutBuilderModal}
            setOpen={setShowWorkoutBuilderModal}
            data={data}
            onSuccess={onSuccess}
            isImport={props?.isImport}
            isCalendar={props?.isCalendar}
            isUpdate={true}
            PropsData={editInfo}
            onSuccessOfEditWokroutFromWorkout={
              onSuccessOfEditWokroutFromWorkout
            }
            fromWorkout={fromWorkout}
          />
        )}

        {showAddThreshold && (
          <AddTargetUserThresold
            isOpen={showAddThreshold}
            setIsOpen={setShowAddThreshold}
            userUUID={props.userUUID}
            onSuccess={(data) => {
              setIsThresholdAdded(true);
            }}
          />
        )}
        <AlertComponent
          open={showThresholdAlert}
          setOpen={setShowThresholdAlert}
          title={"Add Threshold Value"}
          desciption={
            alertMessage ??
            "Please add threshold value of user to assign this workout"
          }
          action={"Go back and add threshold"}
          onAction={() => {
            // setShowAddThreshold(true);
            history.replace(
              `/clientsView?userUUID=${props.userUUID}&assigned=${true}`
            );
          }}
          cancel={"Cancel"}
          onCancel={() => {
            setShowThresholdAlert(false);
          }}
        />
      </Stack>
    );
  }
);

export default WorkoutContentPage;

const RenderRest = ({ rounds, index }) => {
  let extraStyles = {};
  if (rounds.length !== index + 1) {
    extraStyles = { marginTop: "-6px", marginBottom: "-6px" };
  }
  return rounds[index].round?.restTime ? (
    <div
      style={{
        display: "flex",
        background: "transparent",
        alignItems: "center",
        justifyContent: "center",
        // padding: "8px 16px",
        position: "relative",
        ...extraStyles,
      }}
    >
      <button className="button-new-outlined-rounded">
        <p className="rounded-btn-text">
          {formatSecondsToMMSS(rounds[index].round?.restTime)}
        </p>{" "}
      </button>
    </div>
  ) : (
    <></>
  );
};

const isCardio = (workoutID) => {
  if (typeof workoutID === "string") {
    return workoutID.includes("cardio_workout");
  }
  return false;
};

const isCardioWorkout = (workout) => {
  // rounds will be only 1
  if (workout?.name === "cardio") {
    return true;
  }
  return false;
};
