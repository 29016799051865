import React from "react";

export const SendButton = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M3.40212 6.67343C3.14274 4.33899 5.54532 2.62543 7.66803 3.63092L19.6124 9.28879C21.9001 10.3724 21.9001 13.6276 19.6124 14.7112L7.66803 20.3691C5.54532 21.3746 3.14274 19.661 3.40212 17.3266L3.88285 13H12C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11H3.88285L3.40212 6.67343Z'
      fill='white'
    />
  </svg>
);
