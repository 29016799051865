import { SpurfitCircularProgress } from "@/components/Dashboard/dashboard";
import styled from "@emotion/styled";
import { MenuItem, Select, Switch, Typography } from "@mui/material";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getAllOnboardingList,
  getToolCommunicationData,
  updateIsActiveStatus,
} from "../../ApiServices/Analytics/Api";
import { GenericCell, HeaderCell } from "../../components/Dashboard/helpers";
import ShowTable from "./ShowTable";
import AllAthletesAnalytics from "./allAthletesAnalytics";
import AllAffiliateAnalytics from "./allAffiliateAnalytics";

const Analytics = () => {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const COACHES_DATA = 1;
  const TOOL_COMMUNICATION = 2;
  const ONBOARDING = 3;
  const ATHLETE_DATA = 4;
  const AFFILIATE_DATA = 5;

  const [loadingActiveStatus, setLoadingActiveStatus] = useState({});

  const [loading, setLoading] = useState(false);
  const [loadingToolCommunication, setLoadingToolCommunication] =
    useState(false);
  const [loadingOnboarding, setLoadingOnboarding] = useState(false);

  const [selectedOption, setSelectedOption] = useState(COACHES_DATA);

  const [data, setData] = useState([]);
  const [toolCommunicationData, setToolCommunicationData] = useState([]);
  const [onboardingData, setOnboardingData] = useState([]);

  const getData = async () => {
    setLoading(true);
    await axios({
      method: "GET",
      url: `${APIURL}CF/api/getCoachesStats`,
    })
      .then((res) => {
        // filter all the data which contains email ending with spur.fit
        const newData = res.data.result.filter(
          (item) => !item.email.endsWith("spur.fit")
        );

        setData(newData);
      })
      .catch((err) => {
        console.log("error in getting data", err);
      })
      .finally(() => {
        setLoading(false);
      });

    setLoadingToolCommunication(true);
    let toolCommunicationData = await getToolCommunicationData();
    // remove all the data which contains email ending with spur.fit
    toolCommunicationData = toolCommunicationData.filter(
      (item) => !item.email.endsWith("spur.fit")
    );
    setToolCommunicationData(toolCommunicationData);
    setLoadingToolCommunication(false);

    setLoadingOnboarding(true);
    let onboardingData = await getAllOnboardingList();

    // remove all the data which contains email ending with spur.fit
    onboardingData = onboardingData.filter(
      (item) => !(item?.email?.email ?? "").endsWith("spur.fit")
    );
    setOnboardingData(onboardingData);
    setLoadingOnboarding(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 120,
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <Typography
          sx={{
            fontSize: "13px",
            lineHeight: "20px",
            fontFamily: "DM Sans",
            color: "#333333",
            fontWeight: 500,
          }}
        >
          Name
        </Typography>
      ),
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            minWidth: "120px",
            cursor: "pointer",
          }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              lineHeight: "20px",
              fontFamily: "DM Sans",
              ml: "10px",
              color: "#323130",
            }}
          >
            {params?.row?.name}
          </Typography>
        </div>
      ),
    },
    {
      field: "uuid",
      headerName: "UUID",
      flex: 1,
      minWidth: 250,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => params.row.id,
      renderHeader: () => (
        <Typography
          sx={{
            fontSize: "13px",
            lineHeight: "20px",
            fontFamily: "DM Sans",
            color: "#333333",
            fontWeight: 500,
          }}
        >
          UUID
        </Typography>
      ),
      renderCell: (params) => (
        <Typography
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
          }}
        >
          {params.row.id}
        </Typography>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 250,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <Typography
          sx={{
            fontSize: "13px",
            lineHeight: "20px",
            fontFamily: "DM Sans",
            color: "#333333",
            fontWeight: 500,
          }}
        >
          Email
        </Typography>
      ),
      renderCell: (params) => (
        <Typography
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
          }}
        >
          {params.row.email}
        </Typography>
      ),
    },
    {
      field: "countryCode",
      headerName: "Country",
      flex: 1,
      minWidth: 80,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <Typography
          sx={{
            fontSize: "13px",
            lineHeight: "20px",
            fontFamily: "DM Sans",
            color: "#333333",
            fontWeight: 500,
          }}
        >
          Country
        </Typography>
      ),

      renderCell: (params) => (
        <Typography
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
          }}
        >
          {params.row.countryCode}
        </Typography>
      ),
    },
    {
      field: "dateCreated2",
      headerName: "Created On",
      valueGetter: (params) =>
        params.row.dateCreated
          ? moment(params.row.dateCreated).format("DD-MM-YYYY")
          : "-",
    },
    {
      field: "dateCreated",
      headerName: "Created On",
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <Typography
          sx={{
            fontSize: "13px",
            lineHeight: "20px",
            fontFamily: "DM Sans",
            color: "#333333",
            fontWeight: 500,
          }}
        >
          Created On
        </Typography>
      ),

      renderCell: (params) => (
        <Typography
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
          }}
        >
          {params.row.dateCreated
            ? moment(params.row.dateCreated).format("MMM Do' YY")
            : "-"}
        </Typography>
      ),
    },
    {
      field: "lastAccessed2",
      headerName: "Last Accessed 2",
      valueGetter: (params) =>
        params.row.lastAccessed
          ? moment(params.row.lastAccessed).format("DD-MM-YYYY")
          : "-",
    },
    {
      field: "lastAccessed",
      headerName: "Last Accessed",
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => params.row.lastAccessed,
      renderHeader: () => (
        <Typography
          sx={{
            fontSize: "13px",
            lineHeight: "20px",
            fontFamily: "DM Sans",
            color: "#333333",
            fontWeight: 500,
          }}
        >
          Last Accessed
        </Typography>
      ),

      renderCell: (params) => (
        <Typography
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
          }}
        >
          {params.row.lastAccessed
            ? moment(params.row.lastAccessed).format("MMM Do' YY")
            : "-"}
        </Typography>
      ),
    },
    {
      field: "_count.atheletes",
      headerName: "Client Count",
      type: "number",
      flex: 1,
      minWidth: 80,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => params.row._count.atheletes,

      renderHeader: () => (
        <Typography
          sx={{
            fontSize: "13px",
            lineHeight: "20px",
            fontFamily: "DM Sans",
            color: "#333333",
            fontWeight: 500,
          }}
        >
          Client Count
        </Typography>
      ),

      renderCell: (params) => (
        <Typography
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
          }}
        >
          {params.row._count.atheletes}
        </Typography>
      ),
    },
    {
      field: "_count.workouts",
      headerName: "Total workouts",
      type: "number",
      flex: 1,
      minWidth: 80,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => params.row._count.workouts,

      renderHeader: () => (
        <Typography
          sx={{
            fontSize: "13px",
            lineHeight: "20px",
            fontFamily: "DM Sans",
            color: "#333333",
            fontWeight: 500,
          }}
        >
          Total Workouts
        </Typography>
      ),

      renderCell: (params) => (
        <Typography
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
          }}
        >
          {params.row._count.workouts}
        </Typography>
      ),
    },
    {
      field: "_count.workoutsAssigned",
      headerName: "Workouts Assigned",
      type: "number",
      flex: 1,
      minWidth: 80,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => params.row._count.workoutsAssigned,

      renderHeader: () => (
        <Typography
          sx={{
            fontSize: "13px",
            lineHeight: "20px",
            fontFamily: "DM Sans",
            color: "#333333",
            fontWeight: 500,
          }}
        >
          Workouts <br /> Assigned
        </Typography>
      ),

      renderCell: (params) => (
        <Typography
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
          }}
        >
          {params.row._count.workoutsAssigned}
        </Typography>
      ),
    },
    {
      field: "_count.Programs",
      headerName: "Total Programs",
      type: "number",
      flex: 1,
      minWidth: 80,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => params.row._count.programs,

      renderHeader: () => (
        <Typography
          sx={{
            fontSize: "13px",
            lineHeight: "20px",
            fontFamily: "DM Sans",
            color: "#333333",
            fontWeight: 500,
          }}
        >
          Total Programs
        </Typography>
      ),

      renderCell: (params) => (
        <Typography
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
          }}
        >
          {params.row._count.programs}
        </Typography>
      ),
    },
    {
      field: "_count.programsAssigned",
      headerName: "Assigned Programs",
      type: "number",
      flex: 1,
      minWidth: 80,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => params.row._count.programs,

      renderHeader: () => (
        <Typography
          sx={{
            fontSize: "13px",
            lineHeight: "20px",
            fontFamily: "DM Sans",
            color: "#333333",
            fontWeight: 500,
            width: "100%",
            wordWrap: "break-word",
          }}
        >
          Programs <br />
          Assigned
        </Typography>
      ),

      renderCell: (params) => (
        <Typography
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
          }}
        >
          {params.row._count.programsAssigned}
        </Typography>
      ),
    },
    {
      field: "contactNumber",
      headerName: "Contact Number",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Contact Number" />,
      renderCell: (params) => (
        <GenericCell label={params?.row?.contactNumber} />
      ),
      valueGetter: (params) => params.row?.contactNumber,
    },
    {
      field: "_accountCreationChannel",
      headerName: "Creation Channel",
      flex: 1,
      minWidth: 80,
      renderHeader: () => <HeaderCell label="Creation Channel" />,
      renderCell: (params) => (
        <GenericCell label={params?.row?.accountCreationChannel} />
      ),
    },
    {
      field: "demoCallDate",
      headerName: "Demo Call Date",
      flex: 1,
      minWidth: 80,
      renderHeader: () => <HeaderCell label="Demo Call Date" />,
      renderCell: (params) => <GenericCell label={params?.row?.demoCallDate} />,
    },
    {
      field: "isActive",
      headerName: "Active",
      flex: 1,
      minWidth: 80,
      renderHeader: () => <HeaderCell label="isActive" />,
      renderCell: (params) => (
        <div>
          {loadingActiveStatus?.[params?.row?.id] ? (
            <SpurfitCircularProgress />
          ) : (
            <IOSSwitch
              sx={{ m: 1 }}
              checked={params?.row?.isActive}
              onChange={async (e) => {
                setLoadingActiveStatus((prev) => {
                  return { ...(prev || {}), [params.row.id]: true };
                });

                const newData = data.map((item) => {
                  if (item.id === params.row.id) {
                    return { ...item, isActive: e.target.checked };
                  }
                  return item;
                });
                setData(newData);
                let res = await updateIsActiveStatus(
                  e.target.checked,
                  params.row.id
                );
                // update the coach data
                setLoadingActiveStatus((prev) => {
                  return { ...(prev || {}), [params.row.id]: false };
                });
              }}
            />
          )}
        </div>
      ),
    },
  ];

  // email       String
  // name        String?
  // emailSent   Boolean?
  // toolChannel String?
  // selection   String?

  const toolCommunication = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      headerAlign: "center",
      minWidth: 120,
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Name" />,
      renderCell: (params) => <GenericCell label={params?.row?.name} />,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Email" />,
      renderCell: (params) => <GenericCell label={params?.row?.email} />,
    },
    {
      field: "emailSent",
      headerName: "Email Sent",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Email Sent" />,
      renderCell: (params) => (
        <GenericCell label={params?.row?.emailSent ? "yes" : ""} />
      ),
    },
    {
      field: "toolChannel",
      headerName: "Tool Channel",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Tool Channel" />,
      renderCell: (params) => <GenericCell label={params?.row?.toolChannel} />,
    },
    {
      field: "selection",
      headerName: "Selection",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Selection" />,
      renderCell: (params) => (
        <GenericCell label={selection?.[params?.row?.selection] ?? ""} />
      ),
    },
  ];

  const onboardingColumns = [
    {
      field: "name",
      headerName: "Coach Name",
      flex: 1,
      headerAlign: "center",
      minWidth: 120,
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Coach Name" />,
      renderCell: (params) => <GenericCell label={params?.row?.coach?.name} />,
      valueGetter: (params) => params.row?.coach?.name,
    },
    {
      field: "email",
      headerName: "Coach Email",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Coach Email" />,
      renderCell: (params) => <GenericCell label={params?.row?.coach?.email} />,
      valueGetter: (params) => params.row?.coach?.email,
    },
    {
      field: "emailSentID",
      headerName: "emailSentID",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="emailSentID" />,
      renderCell: (params) => <GenericCell label={params?.row?.emailSentID} />,
      valueGetter: (params) => params.row?.emailSentID,
    },
    {
      field: "dateSent",
      headerName: "Date Sent",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => params.row.dateSend,
      renderHeader: () => <HeaderCell label="Date Sent" />,
      renderCell: (params) => (
        <GenericCell
          label={
            params?.row?.dateSend
              ? moment(params?.row?.dateSend).format("MMM Do' YY")
              : ""
          }
        />
      ),
    },
  ];

  const atheletesColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      headerAlign: "center",
      minWidth: 120,
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Name" />,
      renderCell: (params) => <GenericCell label={params?.row?.name} />,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Email" />,
      renderCell: (params) => <GenericCell label={params?.row?.email} />,
    },
    {
      field: "coachName",
      headerName: "Coach Name",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Coach Name" />,
      renderCell: (params) => <GenericCell label={params?.row?.coachName} />,
    },
    {
      field: "lastLogin",
      headerName: "Last Login",
      flex: 1,
      //   type: "date",
      sortComparator: (v1, v2) =>
        new Date(v1).getDate() - new Date(v2).getDate(),
      minWidth: 120,
      valueGetter: (params) => params?.row?.lastLoggedinAt,
      headerAlign: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Last Login" />,
      renderCell: (params) => (
        <GenericCell
          label={
            params?.row?.lastLoggedinAt
              ? moment(params?.row?.lastLoggedinAt).format("MMM Do' YY")
              : "-"
          }
        />
      ),
    },
  ];

  const selection = {
    1: "Yes, Sign me up!",
    2: "No",
    3: "Already a member",
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        width: "100%",
        padding: "20px",
      }}
    >
      <div
        style={{
          borderBottom: "1px solid #E5E5E5",
          paddingBottom: "10px",
        }}
        className="flex items-center gap-5"
      >
        <Typography
          sx={{
            fontSize: "18px",
            lineHeight: "24px",
            fontFamily: "DM Sans",
            fontStyle: "italic",
            color: "#333333",
            fontWeight: 500,
          }}
        >
          Spur.fit Analytics
        </Typography>

        <Select
          value={selectedOption}
          onChange={(e) => setSelectedOption(e.target.value)}
        >
          <MenuItem value={COACHES_DATA}>Coaches Data</MenuItem>
          <MenuItem value={TOOL_COMMUNICATION}>Tool Communication</MenuItem>
          <MenuItem value={ONBOARDING}>OnBoarding</MenuItem>
          <MenuItem value={ATHLETE_DATA}>Athlete Data</MenuItem>
          <MenuItem value={AFFILIATE_DATA}>Affiliate Data</MenuItem>
        </Select>
      </div>

      <div
        style={{
          height: "calc(100vh - 100px)",
          backgroundColor: "#fff",
        }}
      >
        {/* change below instead of LIVEZY_USERS set the data that we get from the livezy users */}
        {selectedOption === ATHLETE_DATA ? (
          <AllAthletesAnalytics />
        ) : selectedOption === AFFILIATE_DATA ? (
          <AllAffiliateAnalytics />
        ) : (
          <ShowTable
            data={
              selectedOption === COACHES_DATA
                ? data
                : selectedOption === TOOL_COMMUNICATION
                ? toolCommunicationData
                : onboardingData
            }
            columns={
              selectedOption === COACHES_DATA
                ? columns
                : selectedOption === TOOL_COMMUNICATION
                ? toolCommunication
                : onboardingColumns
            }
            loading={
              selectedOption === COACHES_DATA
                ? loading
                : selectedOption === TOOL_COMMUNICATION
                ? loadingToolCommunication
                : loadingOnboarding
            }
            getRowId={(row) => row.id}
          />
        )}
      </div>
    </div>
  );
};

export default Analytics;

const country = {
  AR: "Argentina",
  AU: "Australia",
  AT: "Austria",
  BE: "Belgium",
  BO: "Bolivia",
  BR: "Brazil",
  BG: "Bulgaria",
  CA: "Canada",
  CL: "Chile",
  CO: "Colombia",
  CR: "Costa Rica",
  HR: "Croatia",
  CY: "Cyprus",
  CZ: "Czech Republic",
  DK: "Denmark",
  DO: "Dominican Republic",
  EE: "Estonia",
  FI: "Finland",
  FR: "France",
  DE: "Germany",
  GR: "Greece",
  HK: "Hong Kong SAR China",
  HU: "Hungary",
  IS: "Iceland",
  IN: "India",
  ID: "Indonesia",
  IE: "Ireland",
  IL: "Israel",
  IT: "Italy",
  JP: "Japan",
  LV: "Latvia",
  LI: "Liechtenstein",
  LT: "Lithuania",
  LU: "Luxembourg",
  MT: "Malta",
  MX: "Mexico ",
  NL: "Netherlands",
  NZ: "New Zealand",
  NO: "Norway",
  PY: "Paraguay",
  PE: "Peru",
  PL: "Poland",
  PT: "Portugal",
  RO: "Romania",
  SG: "Singapore",
  SK: "Slovakia",
  SI: "Slovenia",
  ES: "Spain",
  SE: "Sweden",
  CH: "Switzerland",
  TH: "Thailand",
  TT: "Trinidad & Tobago",
  AE: "United Arab Emirates",
  GB: "United Kingdom",
  US: "United States",
  UY: "Uruguay",
};

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#8330e9" : "#8330e9",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
