import React from "react";
import OverviewTab from "./OverviewTab";
import SummarSection2 from "../DescriptionComp/SummarSection2";
import Notes from "../Notes/Notes";

function SummarTab({
  userUUID,
  trainerUUID,
  summaryData,
  isSmallerThanMd,
  isAdminUserDetail,
  reloadParticularClientsProfile,
  isExpandedView,
}) {
  return (
    <div
      className="flex items-start gap-[8px] w-full overflow-y-scroll overflow-hidden"
      style={{
        maxHeight: isExpandedView
        ? "calc(100vh - 140px)"
        : "calc(100vh - 300px)",
      }}
      
    >
      <OverviewTab
        isAdminUserDetail={isAdminUserDetail}
        data={summaryData}
        reloadParticularClientsProfile={reloadParticularClientsProfile}
        userUUID={userUUID}
      />
      <SummarSection2
        data={summaryData}
        hideCompliance={true}
        isSmallerThanMd={isSmallerThanMd}
      />
      {isAdminUserDetail && (
        <div
          style={{
            width: "100%",
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.03)",
            marginRight: "12px",
          }}
        >
          <Notes
            onClose={() => {
              console.log("Close");
            }}
            userUUID={userUUID}
            trainerUUID={trainerUUID}
          />
        </div>
      )}
    </div>
  );
}

export default SummarTab;
