import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { cn } from "@/lib/utils";
import { Check, ChevronRight } from "lucide-react";
import SilverData from "@/assets/coachSubscription/silverbg.svg";
import { SpurfitCircularProgress } from "@/components/Dashboard/dashboard";
import { Button, buttonVariants } from "@/components/ui/button";

const PricingCard = ({
  header,
  price,
  topFeatures,
  includes,
  onUpgrade,
  isSilver,
  fromSubscription,
  isCreatingSubscription,
  intervalInMonths,
}) => {
  return (
    <Card className="w-[400px] hover:shadow-lg transition duration-300 ease-in-out transform hover:scale-105 rounded-xl overflow-hidden ">
      <CardHeader
        className={cn(
          "font-semibold text-white-pure  bg-gradient-to-r from-[#464feb]/70 to-[#8330e9]/60"
        )}
      >
        <CardTitle className="font-DMSans text-font24"> Pro Plan </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="mt-2">
          {/* <p className="font-DMSans text-font14 text-gray-600">
            For fitness pros who are just starting out. Perfect for those
            looking to get their feet wet.
          </p> */}

          <p className="font-DMSans text-font20 font-semibold mt-4">
            {price}{" "}
            <span className="text-font14 font-normal">
              /{intervalInMonths ?? ""} month{intervalInMonths > 1 && "s"}
            </span>
          </p>

          {/* <ul className="font-DMSans text-font14 mt-4">
            {topFeatures?.map((feature, index) => (
              <li key={index} className="flex items-center gap-1">
                <Check color="#8330e9" size={18} />
                {feature}
              </li>
            ))}
          </ul> */}

          {!fromSubscription && (
            <Button
              className={cn(
                header === "Silver"
                  ? buttonVariants({ variant: "outline" })
                  : "bg-violet-500 text-white-pure",
                header === "Silver" ? "text-violet-500" : "bg-violet-500",
                "border-violet-500 w-full mt-4"
              )}
              onClick={onUpgrade}
            >
              {isCreatingSubscription ? (
                <SpurfitCircularProgress size={20} />
              ) : (
                `Upgrade to Pro`
              )}
            </Button>
          )}
        </div>
      </CardContent>
      <CardFooter>
        <div>
          <p className="font-DMSans text-gray-500 text-font16">Includes</p>
          <ul>
            {includes?.map((feature, index) => (
              <li key={index} className="font-DMSans flex items-center gap-1">
                <ChevronRight color="#8330e9" size={18} />
                {feature}
              </li>
            ))}
          </ul>
        </div>
      </CardFooter>
    </Card>
  );
};

export default PricingCard;
