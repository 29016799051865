import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { ScrollText } from "lucide-react";

import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

function Questionaire({ userInfo }) {
  const [questions, setQuestions] = useState({
    "Tell me about your Lifestyle / job in detail with timings.": "",
    "Are you a vegetarian or non-vegetarian?": "",
    "Are you vegetarian on any specific days?": "",
    "Tell me about your Daily Nutrition with timings.": "",
    "Are you aware of tracking calories?": "",
    "Do you use supplements? If yes, specify.": "",
    "Would you be open to taking supplements? (Whey Protein)": "",
    "Kindly list your favorite food sources. This will help us get an idea while preparing your meal plan.":
      "",
    "Do you have any food allergy? If yes, please specify.": "",
    "Do you consume alcohol?": "",
    "Do you weight train?": "",
    "How many years of weight training experience do you have?": "",
    "Tell me about your workout routine in Detail.": "",
    "How many days in a week can you dedicate to working out?": "",
    "What time of the day you prefer working out?": "",
    "Your current activity level.": "",
    "Out of 10, how stressed are you during the day?": "",
    "What is your goal with this program?": "",
    "Which workout would you prefer? (can be modified at any point during the program)":
      "",
    "Any equipment available at home?": "",
    "Are your menstrual cycles regular?": "",
    "Have you been diagnosed with any of the following?": "",
    "Any history of injuries that we need to be aware of?": "",
    "How did you find us?": "",
  });

  const [renewalQuestionnaire, setRenewalQuestionnaire] = useState([]);

  const [renewalAnswers, setRenewalAnswers] = useState({
    "Current Body Weight?": "",
    "Rate your diet plan.": "",
    "Rate your Training plan": "",
    "Feedback on your previous Diet & Training routine?": "",
    "Any suggestions or changes required in your diet or training routine?": "",
    "Continue working with the same coach?": "",
  });

  const nutritionQuestions = Object.keys(questions).slice(0, 10);
  const trainingQuestions = Object.keys(questions).slice(10, 20);
  const healthWellnessQuestions = Object.keys(questions).slice(20, 23);
  const programQuestions = Object.keys(questions).slice(23, 24);

  const [currentStep, setCurrentStep] = useState(1);
  const [selectedRenewalIndex, setSelectedRenewalIndex] = useState(0);

  useEffect(() => {
    let renewalQuestionnaire = [];
    if (
      userInfo?.questionnaire_LivEzy &&
      userInfo?.questionnaire_LivEzy !== "false" &&
      userInfo?.questionnaire_LivEzy !== "true"
    ) {
      let answersobj = userInfo?.questionnaire_LivEzy;
      if (answersobj.length > 0) {
        // filter renewal answers
        renewalQuestionnaire = answersobj?.filter((item) => {
          return item.type !== "new";
        });

        if (renewalQuestionnaire?.length > 0) {
          // by created_at
          renewalQuestionnaire.sort((a, b) => {
            return new Date(b.created_at) - new Date(a.created_at);
          });

          setRenewalQuestionnaire(renewalQuestionnaire);
        }

        answersobj = answersobj?.find((item) => item.type === "new");
      }
      const answers = JSON.parse(answersobj?.answers || answersobj?.answer);
      if (answers.length > 0) {
        let newQestionAnswer = { ...questions };
        Object.keys(questions).forEach((question, index) => {
          if (index === 0) {
            return;
          } else {
            newQestionAnswer[question] = answers[index + 1];
          }
        });
        setQuestions(newQestionAnswer);
      }
    }

    // Set initial selectedRenewalIndex (excluding "new" type)
    if (renewalQuestionnaire) {
      if (renewalQuestionnaire.length > 0) {
        setSelectedRenewalIndex(0);
      }
    }
  }, [userInfo]);

  const handleStepChange = (step) => {
    setCurrentStep(step);
  };

  const handleRenewalIndexChange = (index) => {
    setSelectedRenewalIndex(index);
  };

  return (
    <Dialog>
      <DialogTrigger>
        <ScrollText size={18} color="#605E5C" />
      </DialogTrigger>
      <DialogContent className="max-w-5xl ">
        <Tabs defaultValue="questionnaire">
          <TabsList>
            <TabsTrigger value="questionnaire">Questionnaire</TabsTrigger>
            {renewalQuestionnaire && renewalQuestionnaire?.length > 0 && (
              <TabsTrigger value="renewal">Renewal Questionnaire</TabsTrigger>
            )}
          </TabsList>
          <TabsContent value="questionnaire">
            {/* Stepper */}
            <div className="flex justify-between my-4">
              {["Nutrition", "Training", "Health & Wellness", "Program"].map(
                (stepTitle, index) => (
                  <button
                    key={stepTitle}
                    onClick={() => handleStepChange(index + 1)}
                    className={`px-4 py-2 rounded-md ${
                      currentStep === index + 1
                        ? "gradient-background text-white-pure"
                        : "bg-gray-200 text-gray-700"
                    }`}
                  >
                    {stepTitle}
                  </button>
                )
              )}
            </div>

            {/* Question Display */}
            <div className="min-h-[400px] max-h-[400px] overflow-y-auto">
              {currentStep === 1 &&
                nutritionQuestions.map((question) => (
                  <QuestionAnswerItem
                    key={question}
                    question={question}
                    answer={questions[question]}
                  />
                ))}
              {currentStep === 2 &&
                trainingQuestions.map((question) => (
                  <QuestionAnswerItem
                    key={question}
                    question={question}
                    answer={questions[question]}
                  />
                ))}
              {currentStep === 3 &&
                healthWellnessQuestions.map((question) => (
                  <QuestionAnswerItem
                    key={question}
                    question={question}
                    answer={questions[question]}
                  />
                ))}
              {currentStep === 4 &&
                programQuestions.map((question) => (
                  <QuestionAnswerItem
                    key={question}
                    question={question}
                    answer={questions[question]}
                  />
                ))}
            </div>
          </TabsContent>
          <TabsContent value="renewal">
            {/* Renewal Questionnaire Stepper */}
            {renewalQuestionnaire && renewalQuestionnaire?.length > 0 && (
              <div className="flex gap-4 my-4">
                {renewalQuestionnaire.map((renewal, index) => (
                  <button
                    key={index}
                    onClick={() => handleRenewalIndexChange(index)}
                    className={`px-4 py-2 rounded-md ${
                      selectedRenewalIndex === index
                        ? "gradient-background text-white-pure"
                        : "bg-gray-200 text-gray-700"
                    }`}
                  >
                    {index + 1}
                  </button>
                ))}
              </div>
            )}

            {/* Renewal Question Display */}
            {renewalQuestionnaire && renewalQuestionnaire?.length > 0 && (
              <div className="min-h-[400px] max-h-[400px] overflow-y-auto">
                {Object.keys(renewalAnswers).map((question) => {
                  // const selectedRenewal = Array.isArray(
                  //   userInfo.renewalQuestionnarie_LivEzy
                  // )
                  //   ? userInfo.renewalQuestionnarie_LivEzy.filter(
                  //       (item) => item.type !== "new"
                  //     )[selectedRenewalIndex]
                  //   : userInfo.renewalQuestionnarie_LivEzy;
                  const selectedRenewal =
                    renewalQuestionnaire[selectedRenewalIndex];

                  const answers = JSON.parse(
                    selectedRenewal?.answers || selectedRenewal?.answer || "[]"
                  );
                  const answer =
                    answers?.[
                      Object.keys(renewalAnswers).indexOf(question) + 1
                    ];

                  return (
                    <QuestionAnswerItem
                      key={question}
                      question={question}
                      answer={answer}
                      previousCoach={userInfo?.previousCoach_LivEzy}
                    />
                  );
                })}
              </div>
            )}
          </TabsContent>
        </Tabs>
      </DialogContent>
    </Dialog>
  );
}

export default Questionaire;

const QuestionAnswerItem = ({ question, answer, previousCoach }) => {
  return (
    <div className="flex flex-col border-b-[0.8px] border-[#EBEBEB] w-full min-h-[70px]">
      <div className="flex text-[13px] font-[500]">
        <p className="border-r-[0.8px] border-[#EBEBEB] w-[70px] px-2 py-2">
          Question
        </p>
        <p className="self-start py-2 px-2">{question}</p>
      </div>
      <div className="flex text-[13px] font-[400] w-full">
        <p className="border-r-[0.8px] border-[#EBEBEB] w-[70px] px-3 py-2">
          Answer
        </p>
        <p className="self-start py-2 px-2">{answer}</p>
        {question === "Continue working with the same coach?" &&
          previousCoach && (
            <p className="self-end py-2 px-2 ml-auto">
              Previous Coach: {previousCoach?.split("$")[0]}
            </p>
          )}
      </div>
    </div>
  );
};
