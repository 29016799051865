import { Alert, ThemeProvider } from "@mui/material";
import { createContext, useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import "./App.css";
import "./css/tailwind.css";
import ErrorBoundary from "./utils/ErrorBoundary";

import { theme } from "./common/theme";
// Page Imports
import NewDashboard from "./components/Dashboard/dashboard";
import NoEntry from "./components/NoEntry";
import ChangePassword from "./pages/changePassword";
import Login from "./pages/login/index";
// import Chat from "./pages/chat";
import AllExercises from "./pages/Exercises/AllExercises";
import AddTrainee from "./pages/addTrainee/addTrainee";
import Client from "./pages/client";
import ClientWorkoutView from "./pages/clientWorkoutView/clientWorkoutView";
import Confirmation from "./pages/confirmation";
import Confirmationpage from "./pages/confirmationpage";
import GetStreamChat from "./pages/getStreamChat";
import ShowWorkout from "./pages/library/showWorkouts/showWorkouts";
import PublicProfile from "./pages/profile/PublicProfile";
import UserResetPassword from "./pages/resetUserPassword";
import ResetPassword from "./pages/resetpassword";

import Payments from "./pages/payments";
import CreateSubscription from "./pages/payments/createSubscription";

import CreateProgramsCF from "./pages/library/programsCF/createProgramCF";
import ProgramsCF from "./pages/library/programsCF/programsCF";
import ShowWorkoutsCF from "./pages/library/showWorkoutsCF";

import mixpanel from "mixpanel-browser";
import { isMobileOnly } from "react-device-detect";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer } from "react-toastify";
import { inDevMode } from "./appModeConfig";
import CustomAlert from "./components/Dialog/CustomAlert";
import WorkoutBuilder from "./components/WorkoutComponent/WorkoutBuilder";
import SpurfitLayout from "./components/layout/SpurfitLayout";
import Analytics from "./features/Analytics";
import Feed from "./features/Feed";
import LivezyAnalytics from "./features/LivezyAnalytics";
import Workouts from "./features/Workouts";
import TestLogin from "./pages/TestLogin";
import ForgotPassword from "./pages/forgotPassword";
import PaymentConfrim from "./pages/payments/PaymentConfrim";
import PaymentFailed from "./pages/payments/PaymentFailed";
import TrainerProfile from "./pages/trainerProfile";
import { TrainerSignup } from "./pages/trainerSignup";
import {
  setIsLibraryOpen,
  setIsNextFinish,
  setIsNutritionOpen,
  setPageFinishedStatus,
  setPageStatus,
  setShowBackButton,
  setTourIndex,
  setTourRun,
} from "./redux/popupStateSlice";
import { updateWalkthoughStatus } from "./utils/functions";

import NewAdminDashboard from "@/pages/adminDahboard/NewAdminDashboard.jsx";
import * as Sentry from "@sentry/react";
import moment from "moment";
import { createGlobalStyle } from "styled-components";
import { getCoachesCurrentSubscriptionDetails } from "./ApiServices/CoachSubscriptions/Api";
import BlockAccess from "./components/BlockAccess";
import Garmin from "./features/Garmin";
import Analyzed from "./features/Garmin/FitFileAnalyzer/analyzed";
import GarminOAuthCallBack from "./features/Garmin/GarminOAuthCallBack";
import Programs from "./features/Programs";
import CoachSubscription from "./pages/CoachSubscription";
import CoachPaymentSuccess from "./pages/CoachSubscription/CoachPaymentSuccess";
import Todos from "./pages/Todos";
import AddLeads from "./pages/addLeads/addLeads";
import AdminUserDetail from "./pages/adminUserDetail";
import AffiliateDashboard from "./pages/affiliateDashboard/index";
import ClientsView from "./pages/clientsView";
import TryNowLogin from "./pages/login/tryNowLogin";
import MessageTemplate from "./pages/messageTemplate";
import Mobile from "./pages/mobile";
import { logout, setIsActiveSubscription } from "./redux/authSlice";
import { setUnreadMessages } from "./redux/dataSlice";
import { chatClient } from "./utils/chat";
import AddNewLeads from "./pages/addLeads/AddNewLeads";

// import ChatDashboard from "./pages/chatdashbaord";
import EnduranceWorkoutBuilder from "./features/EnduranceWorkoutBuilder";
import FormControl from "./pages/FormControl";
import DynamicFormControl from "./pages/FormControl/DynamicFormControl";
import Form from "./pages/FormControl/Form";
import FormSubmitSuccess from "./pages/FormControl/formSubmitSuccess";
import AddTrainerExercises from "./scripts/AddTrainerExercises";

import axios from "axios";
import ExportMeal from "./components/Nutrition/ExportView/ExportMeal";
import ExportOneMeal from "./components/Nutrition/ExportView/ExportOneMeal";
import NutritionPage from "./pages/nutritions/NutritionPage";
import { persistor, store } from "./redux/store";
import { getDeviceId, publicRoutesList } from "./utils/authUtils";
import { TrainerSignupLivezy } from "./pages/trainerSignup/livezyInvitedSignup";
import LoginLivezy from "./pages/login/loginLivezy";
import ForgotPasswordLivezy from "./pages/forgotPassword/forgotPasswordLivezy";
import ResetPasswordLivezy from "./pages/resetpassword/resetPasswordLivezy";
import WorkoutEntry from "./features/Workouts/WorkoutEntry";
import GarminWellnessOAuthCallBack from "./features/Garmin/GarminWellnessOAuthCallBack";

export const CustomAlertContext = createContext(null);

const GlobalStyle = createGlobalStyle`
  /* Add your global styles here */
  .str-chat__message-reactions-button {
    display: none !important;
  }

`;
function App() {
  if (!inDevMode) {
    console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
  }

  const history = useHistory();
  const [showAlert, setShowAlert] = useState(false);
  const [alertContent, setAlertContent] = useState("");
  const isActiveSubscription = useSelector(
    (state) => state.auth?.isActiveSubscription
  );
  const profileData = useSelector((state) => state.auth?.data);
  const gymAffiliation = useSelector(
    (state) => state.auth?.data?.gymAffliation
  );
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const trainerUUID = useSelector((state) => state.auth.trainerUUID);
  const apiURL = useSelector((state) => state.auth.apiURL);

  const [blockAccess, setBlockAccess] = useState(false);
  const [canClose, setCanClose] = useState(false);

  const isLivezy = gymAffiliation === "Livezy";

  const pageStatus = useSelector((state) => state.popupState.pageStatus);

  const dispatch = useDispatch();

  chatClient.on((event) => {
    if (event.total_unread_count !== undefined) {
      let totalUnread = event.total_unread_count;
      dispatch(setUnreadMessages(totalUnread));
    }
  });

  useEffect(() => {
    dispatch(setIsLibraryOpen(false));
    dispatch(setIsNutritionOpen(false));
    dispatch(setTourIndex(0));
    dispatch(setTourRun(false));

    document.addEventListener("keydown", function (e) {
      if (
        e.target.tagName === "INPUT" &&
        e.target.type === "number" &&
        (e.key === "ArrowUp" || e.key === "ArrowDown" || e.shiftKey)
      ) {
        e.preventDefault();
      }
    });

    document.addEventListener(
      "wheel",
      function (e) {
        if (e.target.tagName === "INPUT" && e.target.type === "number") {
          e.preventDefault();
        }
      },
      { passive: false }
    );

    return () => {
      document.removeEventListener("keydown", function (e) {
        if (
          e.target.tagName === "INPUT" &&
          e.target.type === "number" &&
          (e.key === "ArrowUp" || e.key === "ArrowDown" || e.shiftKey)
        ) {
          e.preventDefault();
        }
      });

      document.removeEventListener("wheel", function (e) {
        if (e.target.tagName === "INPUT" && e.target.type === "number") {
          e.preventDefault();
        }
      });
    };
  }, []);
  useEffect(() => {
    if (!inDevMode) {
      console.log = () => {};
      // console.error = () => {}
      // console.debug = () => {}

      Sentry.init({
        dsn: "https://45d993a6a79e273f25886df9c6020a4d@o923630.ingest.us.sentry.io/4507463368310784",
        integrations: [Sentry.browserTracingIntegration()],
        // Performance Monitoring
        tracesSampleRate: 0.4, // Capture 40% of the transactions
      });
    }

    mixpanel.init("147a208f5832549c876d94601401033e", {
      api_host: "https://api.mixpanel.com",
      ignore_dnt: true,
    });
    if (!inDevMode) {
      (function (c, l, a, r, i, t, y) {
        c[a] =
          c[a] ||
          function () {
            (c[a].q = c[a].q || []).push(arguments);
          };
        t = l.createElement(r);
        t.async = 1;
        t.src = "https://www.clarity.ms/tag/" + i;
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t, y);
      })(window, document, "clarity", "script", "hvg6tyshun");
    }
  }, []);

  const init = async () => {
    await checkCoachSubscription(profileData?.id);

    if (
      !profileData?.isActive &&
      !isActiveSubscription &&
      profileData?.payNow?.shouldPayNow &&
      profileData?.payNow?.paymentDate
    ) {
      let paymentDate = moment(profileData?.payNow?.paymentDate, "YYYY-MM-DD");
      let daysDiff = paymentDate.diff(moment(), "days");

      if (daysDiff == 2) {
        setBlockAccess(true);
        setCanClose(true);
      } else if (daysDiff <= 0) {
        setBlockAccess(true);
        setCanClose(false);
      }
    } else if (profileData && !profileData?.isActive && !isActiveSubscription) {
      let creationDate = moment(profileData?.dateCreated);
      // if creationDate is after 4 may 2024 and has passed 30 days
      let daysDiff = moment().diff(creationDate, "days");
      if (
        (creationDate.isAfter(moment("2024-05-04")) && daysDiff > 30) ||
        store.getState().auth.trainerUUID === "IErvyWyUJjSUwKIFbF"
      ) {
        setBlockAccess(true);
        setCanClose(false);
      } else if (
        creationDate.isAfter(moment("2024-05-04")) &&
        daysDiff === 12
      ) {
        setBlockAccess(true);
        setCanClose(true);
      }
    }
  };

  useEffect(() => {
    if (profileData?.id) {
      init();
      Sentry.setUser({
        id: profileData?.id,
        email: profileData?.email,
        username: profileData?.name,
      });
    }
  }, [profileData]);

  const displayAlert = (message) => {
    setShowAlert(true);
    setAlertContent(message);
    if (message?.type) {
      let time = 3000;
      if (message?.time) time = message.time;
      let timer = setTimeout(() => {
        setShowAlert(false);
        setAlertContent("");
        clearTimeout(timer);
      }, time);
    }
  };
  const [copilotOpen, setCopilotOpen] = useState(false);
  const tourIndex = useSelector((state) => state.popupState.tourIndex);
  const tourRun = useSelector((state) => state.popupState.tourRun);
  const isNextFinish = useSelector((state) => state.popupState.isNextFinish);
  const showBackButton = useSelector(
    (state) => state.popupState.showBackButton
  );

  const [steps, setSteps] = useState([
    {
      content: (
        <h2>
          Welcome to Spur.Fit, Coach! Your journey to seamless fitness
          management starts here. Let’s take a quick tour together to get you
          familiar with your new fitness command centre.{" "}
        </h2>
      ),
      placement: "center",
      target: "body",
    },
    {
      content: <h2>Let’s dive into your Client Management Dashboard!</h2>,
      placement: "center",
      target: "body",
      title: "Dashboard",
    },
    {
      content: (
        <h2>
          Here, manage your Active Clients, review Pending Clients, and access
          your Archived Client data.
        </h2>
      ),
      placement: "bottom",
      target: "#dashboard-top-bar",
      title: "Layout Tabs",
    },
    {
      content: (
        <h2>
          Adding a new client is just a click away with the 'Add New' button.
          {"\n"}
        </h2>
      ),
      placement: "bottom",
      target: "#dashboard-addnew-button",
      title: "Layout Tabs",
    },
    {
      content: (
        <h2>
          Need to find a client quickly? Use the Search and Filter functionality
          to streamline your view.
        </h2>
      ),
      placement: "bottom",
      target: "#dashboard-search",
      title: "Layout Tabs",
    },
    {
      content: (
        <h2>
          Meet your AI CoPilot, available at the top right! The CoPilot is a
          companion designed to make your planning smoother and smarter. Click
          on the Ask CoPilot to summon it.
        </h2>
      ),
      spotlightClicks: true,
      placement: "left",
      target: "#topbar-copilot-button",
      title: "AI CoPilot",
    },
    {
      content: (
        <h2>
          Create a workout plan by clicking on the create workout button,
          entering a client name and filling in details of how you’d like the
          workout to be.
        </h2>
      ),
      placement: "left",
      target: "#copilot-options",
      title: "AI CoPilot",
      spotlightClicks: true,
    },
    {
      content: (
        <h2>
          Try entering “5 day plan to improve core strength and endurance” Once
          you assign a workout, it will be available to the client in their app;
          more on this later in our tour.
        </h2>
      ),
      target: "#copilot-global",
      title: "AI CoPilot Options",
      spotlightClicks: true,
    },
    {
      content: (
        <h2>
          Keep track, stay informed! Compliance Page helps you to monitor client
          progress and make data-driven adjustments to their plans.
        </h2>
      ),
      placement: "center",
      target: "#spurfit-layout-box",
      title: "Progress and Stats",
    },
    {
      content: <h2>Welcome to your Chats</h2>,
      placement: "right",
      target: "#sidebar-chat",
      title: "Chats",
    },
    {
      content: (
        <h2>
          Communication is key! The Chat Dashboard is your direct line to your
          clients. Send messages, share updates, and keep your communication
          organised and timely to enhance client relationships and engagement
        </h2>
      ),
      placement: "center",
      target: "body",
      title: "Chats",
    },
    {
      content: <h2>Welcome to your Library!</h2>,
      placement: "right",
      target: "#sidebar-library",
      title: "Library",
    },
    {
      content: <h2>Craft personalised Programs with week-by-week workouts</h2>,
      placement: "right",
      target: "#sidebar-library-Programs",
      title: "Library",
      spotlightClicks: true,
    },
    {
      content: <h2>Create dynamic Workouts with custom exercise sets,</h2>,
      placement: "right",
      target: "#sidebar-library-Workouts",
      title: "Library",
      spotlightClicks: true,
    },
    {
      content: (
        <h2>
          Explore a vast library of Exercises. Build your own custom exercise
          sets to bring a personal touch to workouts.
        </h2>
      ),
      placement: "right",
      target: "#sidebar-library-Exercises",
      title: "Library",
      spotlightClicks: true,
    },
    {
      content: <h2>Welcome to the Nutrition Section!</h2>,
      placement: "right",
      target: "#sidebar-nutrition",
      title: "Nutrition",
    },
    {
      content: (
        <h2>
          Explore the Food and Nutrition sections to curate balanced diets.
        </h2>
      ),
      placement: "right",
      target: "#sidebar-nutrition-food",
      title: "Nutrition",
    },
    {
      content: (
        <h2>
          Create, customise, and assign Meal Plans and Schedules to each client,
          ensuring their nutritional needs align perfectly with their workout
          regimes.
        </h2>
      ),
      placement: "right",
      target: "#sidebar-nutrition-meal-plan",
      title: "Nutrition",
    },
    {
      content: (
        <h2>
          Need help or have inquiries? Our Live Chat Support, located at the
          bottom right, is here to assist you anytime.
        </h2>
      ),
      placement: "top",
      target: ".woot-widget-bubble",
      title: "Help and Support",
    },
    {
      content: (
        <h2>
          With Spur.Fit, your clients get a branded app that they can use to
          workout, connect with you and track their progress. The wearable
          integration on the App improves your understanding of how your clients
          are progressing without having to quiz them. This improves your
          compliance and elevates your brand.
          <a
            href="https://apps.apple.com/us/app/spur-fit/id1516471995"
            style={{ color: "Blue", textDecoration: "underline" }}
          >
            {" "}
            Apple{" "}
          </a>
          {"\n"}
          <a
            href="https://play.google.com/store/apps/details?id=com.getfitainative.fitnessassistant&hl=en_US&gl=US"
            style={{ color: "Blue", textDecoration: "underline" }}
          >
            {" "}
            Android{" "}
          </a>
        </h2>
      ),
      placement: "center",
      target: "body",
      title: "Download the Mobile App",
    },
  ]);

  let RouteRange = {
    home: [0, 8],
    chat: [9, 10],
    library: [11, 14],
    nutrition: [15, 18],
  };

  const checkWhichRouteRangeAndSetStatusInRedux = (
    index,
    skipped,
    finished
  ) => {
    let route = "";
    if (index >= RouteRange.home[0] && index <= RouteRange.home[1]) {
      route = "home";
    } else if (index >= RouteRange.chat[0] && index <= RouteRange.chat[1]) {
      route = "chat";
    } else if (
      index >= RouteRange.library[0] &&
      index <= RouteRange.library[1]
    ) {
      route = "library";
    } else if (
      index >= RouteRange.nutrition[0] &&
      index <= RouteRange.nutrition[1]
    ) {
      route = "nutrition";
    }
    if (route) {
      if (skipped) {
        dispatch(
          setPageStatus({
            home: {
              skipped: true,
              finished: false,
            },
            chat: {
              skipped: true,
              finished: false,
            },
            library: {
              skipped: true,
              finished: false,
            },
            nutrition: {
              skipped: true,
              finished: false,
            },
          })
        );
        updateWalkthoughStatus(pageStatus, {
          home: {
            skipped: true,
            finished: false,
          },
          chat: {
            skipped: true,
            finished: false,
          },
          library: {
            skipped: true,
            finished: false,
          },
          nutrition: {
            skipped: true,
            finished: false,
          },
        });
        // skip for all pages
      }
      if (finished) {
        dispatch(setPageFinishedStatus({ page: route, status: true }));
        updateWalkthoughStatus(pageStatus, {
          [route]: {
            skipped: false,
            finished: true,
          },
        });
      }
      dispatch(setTourIndex(-1));
    }
  };

  const isCurrentIndexFirstOrLastIndexOfPage = (index) => {
    if (index == 8 || index == 10 || index == 14 || index == 17) {
      dispatch(setIsNextFinish(true));
      return true;
    } else if (index == 9 || index == 11 || index == 15 || index == 20) {
      dispatch(setShowBackButton(false));
      if (!showBackButton) {
        dispatch(setShowBackButton(true));
      }
      return true;
    }
    if (isNextFinish) {
      dispatch(setIsNextFinish(false));
    }
    if (!showBackButton) {
      dispatch(setShowBackButton(true));
    }
    return false;
  };

  const handleJoyrideCallback = async (data) => {
    const { action, index, status, type, step } = data;
    isCurrentIndexFirstOrLastIndexOfPage(index);

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      dispatch(setTourRun(false));
      // if skipped
      if (status === STATUS.SKIPPED) {
        dispatch(setIsLibraryOpen(false));
        dispatch(setIsNutritionOpen(false));
        checkWhichRouteRangeAndSetStatusInRedux(index, true, false);
        // doNeedfulForSkipping(index);
      } else {
        checkWhichRouteRangeAndSetStatusInRedux(index, false, true);
      }
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      if (nextStepIndex == 15) {
        dispatch(setIsLibraryOpen(false));
      } else if (nextStepIndex == 18) {
        dispatch(setIsNutritionOpen(false));
      }
      if (
        (nextStepIndex == 9 ||
          nextStepIndex == 11 ||
          nextStepIndex == 15 ||
          nextStepIndex == 18) &&
        action !== ACTIONS.PREV
      ) {
        checkWhichRouteRangeAndSetStatusInRedux(index, false, true);
        dispatch(setTourRun(false));
      } else {
        dispatch(setTourIndex(nextStepIndex));
      }
    }

    if (index === 1) {
      // Navigate to the desired route when Step 2 is reached
      await history.push("/home");
    }
    // window.location.href = "/your-route"; // Replace with your actual route
    if (index === 5) {
      // Navigate to the desired route when Step 2 is reached
      // window.location.href = "/your-route"; // Replace with your actual route
      setCopilotOpen(true);
      // get the selector and set onClick function to it
      let element = document.querySelector("#topbar-copilot-button");
      if (element) {
        element.addEventListener("click", () => {
          dispatch(setTourIndex(6));
        });
      }
    }
    if (index === 6) {
      let element = document.querySelector("#copilot-options");
      if (element) {
        element.addEventListener("click", () => {
          dispatch(setTourIndex(7));
        });
      }
    }
    if (index == 8 && tourRun) {
      setCopilotOpen(false);
    }
    if (index === 9) {
      // Navigate to the desired route when Step 2 is reached
      // Replace with your actual route
      // await history.push("/chat");
    }
    if (index === 11 && tourRun && status !== STATUS.SKIPPED) {
      // Navigate to the desired route when Step 2 is reached
      // Replace with your actual route
      dispatch(setIsLibraryOpen(true));
    }
    if ((index === 12 || index === 13 || index === 14) && tourRun) {
      let id = step?.target;
      addOnClickListner(id, index);
    }
    if (index === 13) {
      // Navigate to the desired route when Step 2 is reached
      // await history.push("/workouts");
      // Replace with your actual route
    }
    if (index === 14 && tourRun) {
      // Navigate to the desired route when Step 2 is reached
      // await history.push("/AllExercises");
    }
    if (index === 15 && tourRun && status !== STATUS.SKIPPED) {
      // Replace with your actual route
      dispatch(setIsLibraryOpen(false));
      dispatch(setIsNutritionOpen(true));
    }
    if (index === 16) {
      // Navigate to the desired route when Step 2 is reached
      // Replace with your actual route
      // await history.push("/nutrition/1");
    }
    if (index == 17) {
      // await history.push("/nutrition/1");
      // dispatch(setIsNutritionOpen(false))
      // Add functionality for handling the request updating user profile with completed walkthrough
      // await history.push("/nutrition/3");
    }
    if (index == 18) {
      // Add functionality for handling the request updating user profile with completed walkthrough
      dispatch(setIsNutritionOpen(false));
    }
    if (index == 19 && tourRun) {
      dispatch(setTourRun(false));
    }
  };

  const routeForId = (id) => {
    if (id == "dashboard-top-bar") {
      return "/home";
    } else if (id == "sidebar-library-Programs") {
      return "/programsCF";
    } else if (id == "sidebar-library-Exercises") {
      return "/AllExercises";
    } else if (id == "sidebar-nutrition") {
      return "/nutrition/1";
    } else if (id == "sidebar-nutrition-food") {
      return "/nutrition/1";
    } else if (id == "sidebar-nutrition-meal-plan") {
      return "/nutrition/1";
    } else if (id == "sidebar-nutrition-meal-plan") {
      return "/nutrition/1";
    }
  };

  const addOnClickListner = (id, index) => {
    let element = document.querySelector(id);
    if (element) {
      element.addEventListener("click", () => {
        let route = routeForId(id);
        console.log("route for ", id, " is ", route);
        if (route) {
          history.push(route);
        }
        dispatch(setTourIndex(index + 1));
      });
    }
  };
  const [run, setRun] = useState(true);
  const publicRoutes = [
    "/addtrainee",
    "/trainersignup",
    "/invitedTrainerSignUp",
    "/forgotPassword",
    "/affiliateSignup",
    "/confirmationpage",
    "/profile",
    "/trainwithme",
    "/confirmation",
    "/resetpassword",
    "/resetUserPassword",
    "/paymentFailed",
    "/paymentConfrim",
    "/changePassword",
    "/userform",
  ];
  const currentUrl = window.location.href;
  const isPublicRoute = () => {
    publicRoutes.forEach((pr) => {
      if (currentUrl.toLowerCase().includes(pr)) {
        return true;
      }
    });
    return false;
  };
  if (isPublicRoute() && isMobileOnly) {
    return <Mobile />;
  }

  const checkCoachSubscription = async (trainerUUID) => {
    if (!trainerUUID) return;
    let res = await getCoachesCurrentSubscriptionDetails(trainerUUID);
    if (res?.result?.[0]?.status) {
      dispatch(setIsActiveSubscription(res?.result?.[0]?.status === "active"));
    }
  };

  let isRefreshing = false;
  let failedQueue = [];
  let isLoggingOut = false; // Add a flag to track logout status

  const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });

    failedQueue = [];
  };

  axios.interceptors.request.use(
    function (config) {
      // conditions for private route
      let trainerUUID = store.getState().auth.trainerUUID;
      let route = config.url;
      // remove string till .com/
      route = route.split(".com/")[1];
      if (trainerUUID && !publicRoutesList.includes(route)) {
        if (config.method.toLowerCase() === "post") {
          if (config.data) {
            if (config.data instanceof FormData || Array.isArray(config.data)) {
              if (config.params) {
                config.params.ID = trainerUUID;
              } else {
                config.params = { ID: trainerUUID };
              }
            } else {
              config.data.ID = trainerUUID;
            }
          } else {
            config.data = { ID: trainerUUID };
          }
        } else {
          if (config.params) {
            config.params.ID = trainerUUID;
          } else {
            config.params = { ID: trainerUUID };
          }
        }
        config.withCredentials = true;
      }

      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  const refreshInstance = axios.create();
  async function renewToken() {
    let coachID = store.getState().auth.trainerUUID;
    const response = await refreshInstance.get(
      `${
        store.getState().auth.apiURL
      }api/handleRefreshTokenTrainerBrowser?ID=${coachID}`,
      {
        withCredentials: true,
      }
    );
    const token = response.data.accessToken;
    return token;
  }

  const handleLogoutTrainer = async () => {
    if (isLoggingOut) return; // Prevent multiple logouts
    isLoggingOut = true; // Set the flag

    let coachID = store.getState().auth.trainerUUID;
    await axios({
      method: "post",
      url: `${store.getState().auth.apiURL}api/handleLogoutTrainerBrowser`,
      withCredentials: true,
      data: {
        ID: coachID,
        trainerUUID: coachID,
        deviceID: getDeviceId(),
      },
    });
    dispatch(logout());
    await persistor.flush();
    await persistor.purge();
    localStorage.clear();
    window.location.reload();
  };

  // Response Interceptor
  axios.interceptors.response.use(
    function (response) {
      // If the response is successful, return it as is
      return response;
    },
    async function (error) {
      const originalRequest = error.config;

      if (error.response.status === 403 && !originalRequest._retry) {
        if (isRefreshing) {
          return new Promise(function (resolve, reject) {
            failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              originalRequest.headers["Authorization"] = "Bearer " + token;
              return axios(originalRequest);
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        }

        originalRequest._retry = true;
        isRefreshing = true;

        return new Promise(function (resolve, reject) {
          renewToken()
            .then((token) => {
              axios.defaults.headers.common["Authorization"] =
                "Bearer " + token;
              originalRequest.headers["Authorization"] = "Bearer " + token;
              processQueue(null, token);
              resolve(axios(originalRequest));
            })
            .catch((err) => {
              processQueue(err, null);
              handleLogoutTrainer(); // Call without argument
              reject(err);
            })
            .then(() => {
              isRefreshing = false;
            });
        });
      } else if (error.response.status === 401) {
        handleLogoutTrainer(); // Call without argument
      }

      // If the error is not a 401 or 403, or if the request was already retried, reject the promise
      return Promise.reject(error);
    }
  );

  const validate = async () => {
    let timeout = setTimeout(() => {
      let isLoggedIn = localStorage.getItem("isLoggedIn") === "true";
      if (isAuthenticated || isLoggedIn) {
        // check for required data that need to be present in redux
        let isDataPresent = true;

        // check apiUrl
        let apiUrl = store.getState().auth.apiURL;
        if (!apiUrl) {
          isDataPresent = false;
        }

        // check for trainerUUID
        let trainerUUID = store.getState().auth.trainerUUID;
        if (!trainerUUID) {
          isDataPresent = false;
        }

        // if any of the required data is not present, logout the user
        if (!isDataPresent) {
          localStorage.clear();
          // navigate to login page
          history.push("/");
        }
      }

      clearTimeout(timeout);
    }, 1000);
  };

  useEffect(() => {
    validate();
  }, [isAuthenticated, apiURL, trainerUUID]);

  return (
    <>
      {isLivezy && <GlobalStyle />}
      {!isLivezy && blockAccess && <BlockAccess showClose={!canClose} />}
      {/* {!profileInfo.isActive && coachID === "fHzLMnWkXuXL" && <GauravPayment />} */}
      <ThemeProvider theme={theme}>
        <ErrorBoundary>
          <Joyride
            steps={steps}
            continuous={true}
            showProgress={false}
            showSkipButton={true}
            spotlightClicks={false}
            disableScrollParentFix={true}
            disableScrolling={true}
            hideCloseButton={true}
            run={tourRun}
            stepIndex={tourIndex}
            callback={handleJoyrideCallback}
            disableOverlayClose={true}
            hideBackButton={!showBackButton}
            locale={{
              next: isNextFinish ? "Finish" : "Next",
            }}
            styles={{
              options: {
                fontFamily: "DM Sans",
                fontSize: 16,
                textColor: "#000",
                zIndex: 1000,
              },
              textAlign: "left",
            }}
          />

          <CustomAlertContext.Provider
            value={{
              showAlert,
              setShowAlert,
              alertContent,
              setAlertContent,
              displayAlert,
            }}
          >
            {/* Alert component defined once and can be used anywhere as we are using the conext which can se state from any child compoenent */}
            {showAlert && alertContent?.type && (
              <Alert
                sx={{
                  position: "fixed",
                  left: "50%",
                  transform: "translateX(-50%)",
                  bottom: "10%",
                  zIndex: 9999,
                }}
                severity={alertContent?.type}
              >
                {alertContent?.message}
              </Alert>
            )}
            {showAlert && typeof alertContent == "string" && (
              <CustomAlert
                showAlert={showAlert}
                setShowAlert={setShowAlert}
                alertContent={alertContent}
                setAlertContent={setAlertContent}
              />
            )}

            <Switch>
              <Route exact path="/" component={Login} />
              <Route exact path="/livezy" component={LoginLivezy} />
              <Route exact path="/exportAsPDF" component={ExportOneMeal} />
              <Route exact path="/export" component={ExportMeal} />
              {/* <Route exact path="/test" component={UpdateTrainerExercises} /> */}
              <Route
                exact
                path="/topSecret/uploadExercise"
                component={AddTrainerExercises}
              />
              {/* <Route exact path="/chatdashboard" component={ChatDashboard} /> */}
              <Route
                exact
                path="/7223c965-f2a1-4279-abf9-9be7681f8006/garmin"
                component={Garmin}
              />
              <Route
                exact
                path="/GarminOAuthCallBack"
                component={GarminOAuthCallBack}
              />
              <Route
                exact
                path="/GarminWellnessOAuthCallBack"
                component={GarminWellnessOAuthCallBack}
              />
              <Route exact path="/login/trynow" component={TryNowLogin} />
              <Route
                exact
                path="/d82be845020b563334ce655968152a1f/analytics"
                component={Analytics}
              />
              <Route
                exact
                path="/11f19594-f4a3-4b41-91c8-f96386a01848/livezyAnalytics"
                component={LivezyAnalytics}
              />
              <Route
                exact
                path="/bdf3678b8794c7c034d2399e492bc2a4/debugLogin"
                component={TestLogin}
              />
              <Route exact path="/forgotPassword" component={ForgotPassword} />
              <Route
                exact
                path="/livezy/forgotPassword"
                component={ForgotPasswordLivezy}
              />
              <Route exact path="/noentry" component={NoEntry} />
              <Route exact path="/trainersignup" component={TrainerSignup} />
              <Route exact path="/userform" component={Form} />
              <Route
                exact
                path="/affiliateSignup/:affiliateCoachID"
                component={TrainerSignup}
              />
              <Route
                exact
                path="/livezy/invitedTrainerSignUp/:id"
                component={TrainerSignupLivezy}
              />
              <Route
                exact
                path="/livezy/invitedTrainerSignUp/:id/:affiliation"
                component={TrainerSignupLivezy}
              />
              <Route
                exact
                path="/invitedTrainerSignUp/:id"
                component={TrainerSignup}
              />
              <Route
                exact
                path="/invitedTrainerSignUp/:id/:affiliation"
                component={TrainerSignup}
              />
              <Route
                exact
                path="/confirmationpage"
                component={Confirmationpage}
              />
              <Route exact path="/profile/:id" component={PublicProfile} />
              <Route exact path="/addTrainee/:id" component={AddTrainee} />
              <Route
                exact
                path="/addTrainee/:id/:userUUID/:userEmail"
                component={AddTrainee}
              />
              <Route exact path="/trainwithme/:id" component={AddNewLeads} />
              <Route exact path="/confirmation" component={Confirmation} />
              <Route exact path="/resetpassword" component={ResetPassword} />
              <Route
                exact
                path="/livezy/resetpassword"
                component={ResetPasswordLivezy}
              />
              <Route
                exact
                path="/resetUserPassword"
                component={UserResetPassword}
              />
              <Route
                exact
                path="/livezy/resetUserPassword"
                component={UserResetPassword}
              />

              <Route exact path="/paymentFailed" component={PaymentFailed} />
              <Route exact path="/paymentConfrim" component={PaymentConfrim} />
              <Route exact path="/changePassword" component={ChangePassword} />

              <Route exact path="/form-success" component={FormSubmitSuccess} />

              <SpurfitLayout
                setRun={setRun}
                copilotOpen={copilotOpen}
                setCopilotOpen={setCopilotOpen}
              >
                <PrivateRoute exact path="/feed" component={Feed} />
                <PrivateRoute exact path="/dashboard">
                  <Redirect to="/home" />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/admin"
                  component={NewAdminDashboard}
                />
                {/*<PrivateRoute exact path="/adminDashboard" component={NewAdminDashboard} />*/}
                <PrivateRoute
                  exact
                  path="/EnduranceWorkoutBuilder"
                  component={EnduranceWorkoutBuilder}
                />
                <PrivateRoute
                  exact
                  path="/admin/userDetail"
                  component={AdminUserDetail}
                />
                <PrivateRoute
                  exact
                  path="/affiliate"
                  component={AffiliateDashboard}
                />
                <PrivateRoute exact path="/home" component={NewDashboard} />
                <PrivateRoute exact path="/chat" component={GetStreamChat} />
                <PrivateRoute
                  exact
                  path="/formcontrol"
                  component={FormControl}
                />
                <PrivateRoute
                  exact
                  path="/form/:id"
                  component={DynamicFormControl}
                />
                {/* <PrivateRoute exact path="/report" component={Report} /> */}
                <PrivateRoute
                  exact
                  path="/profile"
                  component={TrainerProfile}
                />
                <PrivateRoute
                  exact
                  path="/leads/edit"
                  component={DynamicFormControl}
                />
                <PrivateRoute
                  exact
                  path="/leads/edit/:id"
                  component={DynamicFormControl}
                />
                <PrivateRoute
                  exact
                  path="/AllExercises"
                  component={AllExercises}
                />
                <PrivateRoute
                  exact
                  path="/library/AllExercises"
                  component={AllExercises}
                />
                <PrivateRoute exact path="/client/:id" component={Client} />

                <PrivateRoute
                  exact
                  path="/library/showWorkouts/:id"
                  component={ShowWorkout}
                />
                <PrivateRoute
                  exact
                  path="/clientWorkoutView/:id"
                  component={ClientWorkoutView}
                />

                <PrivateRoute
                  exact
                  path="/workoutBuilder"
                  component={WorkoutBuilder}
                />

                {/* This is for accessing from any path  */}
                <PrivateRoute
                  exact
                  path="/clientsView"
                  component={ClientsView}
                />
                <PrivateRoute exact path="/workouts" component={WorkoutEntry} />
                <PrivateRoute
                  exact
                  path="/library/showWorkoutsCF/:id"
                  component={ShowWorkoutsCF}
                />
                <PrivateRoute exact path="/analyze" component={Analyzed} />
                <PrivateRoute exact path="/programs" component={Programs} />
                <PrivateRoute
                  exact
                  path="/programs/:programId"
                  component={Programs}
                />
                <PrivateRoute exact path="/programsCF" component={ProgramsCF} />
                <PrivateRoute
                  exact
                  path="/programsCF/:programId"
                  component={ProgramsCF}
                />
                {/* <PrivateRoute
              exact
              path="/library/programsCF"
              component={ProgramsCF}
            /> */}
                <PrivateRoute
                  exact
                  path="/library/addPrograms/createProgramCF"
                  component={CreateProgramsCF}
                />
                <PrivateRoute
                  exact
                  path="/library/editProgramCF/:id"
                  component={CreateProgramsCF}
                />
                <PrivateRoute
                  exact
                  path="/stripeConnect"
                  component={Payments}
                />
                <PrivateRoute
                  exact
                  path="/stripeConnect/:id"
                  component={Payments}
                />
                <PrivateRoute
                  exact
                  path="/createSubscription"
                  component={CreateSubscription}
                />
                <PrivateRoute
                  exact
                  path="/nutrition/:id"
                  component={NutritionPage}
                />
                <PrivateRoute
                  exact
                  path="/nutrition/:id/:selectedMealID"
                  component={NutritionPage}
                />
                <PrivateRoute exact path="/todos" component={Todos} />
                <PrivateRoute
                  exact
                  path="/templateMessages"
                  component={MessageTemplate}
                />
                <PrivateRoute
                  exact
                  path="/coachSubscription"
                  component={CoachSubscription}
                />
                <PrivateRoute
                  exact
                  path="/CoachPaymentSuccess"
                  component={CoachPaymentSuccess}
                />
              </SpurfitLayout>
            </Switch>
          </CustomAlertContext.Provider>

          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            zindex="999999"
            style={{
              marginBottom: "20rem",
            }}
          />
        </ErrorBoundary>
        <div className="fixed right-9 bottom-8 "></div>
      </ThemeProvider>
    </>
  );
}

const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem("isLoggedIn") === "true" ? (
        <Component {...props} />
      ) : (
        <Redirect to="/" />
      )
    }
  />
);

export default App;
