import { CloseRounded, Label } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Select,
  styled,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import axios from "axios";
import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import { chatClient } from "../../utils/chat";
import { SpurfitCircularProgress } from "../Dashboard/dashboard";
import Input from "../InputComponents/Input";

const AddTargetWeight = ({
  isOpen,
  setIsOpen,
  userUUID,
  reloadClientSummary,
  isUpdate,
  setIsUpdateUserWeight,
  setSummaryData,
  summaryData,
  setIsUpdateUserStepsCount,
  isUpdateUserStepsCount,
  name,
}) => {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const [targetWeight, setTargetWeight] = useState("");
  const [targetDays, setTargetDays] = useState(30);
  const [unit, setUnit] = useState("kg"); // ['kg', 'lbs']
  const [loading, setLoading] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  const coachID = useSelector((state) => state.auth?.trainerUUID);
  const gymAffiliation = useSelector(
    (state) => state.auth?.data?.gymAffliation
  );
  const isLivezy = gymAffiliation === "Livezy";

  // this is for the target wight
  const onSave = async () => {
    // checks for the data
    if (targetWeight === "") {
      setShowWarning(true);
      return;
    }
    setLoading(true);
    try {
      let response = await axios({
        method: "post",
        url: `${APIURL}CF/api/CoachWeb/Update/updateAtheleteProperties`,
        data: {
          athleteID: userUUID,
          goalWeight: targetWeight + "-" + unit,
          goalWeightAddedDate: moment().format("YYYY-MM-DD") + "T00:00:00.000Z",
          goalWeightDuration: Number(targetDays) || 30, // in days
        },
      });
      if (response?.status === 200) {
        await reloadClientSummary(userUUID);
        let message = ` Hey ${summaryData?.userInfo?.name}, Your target for the next 30 days is ${targetWeight} ${unit}`;
        let channels = await chatClient.queryChannels({
          type: "messaging",
          members: [userUUID, coachID],
          member_count: 2,
        });

        if (channels.length > 0) {
          let channel = channels[0];
          let sentMessage = await channel.sendMessage({
            text: message,
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
    setIsOpen(false);
    setLoading(false);
  };

  // this is for the update weight
  const onUpdate = async () => {
    // checks for the data
    if (targetWeight === "") {
      setShowWarning(true);
      return;
    }

    setLoading(true);
    try {
      const res = await axios.post(
        `${APIURL}CF/api/updateBodyProgressTracking`,
        {
          atheleteID: userUUID,
          weight: { unit: unit, value: targetWeight },
          dateRecorded: moment().format("YYYY-MM-DD") + "T00:00:00.000Z",
          updatedBy: "trainer",
        }
      );
      if (res.data && res.data.result) {
        console.log("You got some response", res.data.result, summaryData);
        await reloadClientSummary(userUUID);
        // // Create a copy of summaryData
        // const tempSummaryData = { ...summaryData };
        // // Update the bodyStatsData array with the new result
        // tempSummaryData.bodyStatsData[0] = res.data.result;
        // // Update the state with the modified data
        // setSummaryData(tempSummaryData);
      }
    } catch (error) {
      console.log("Not able to update the user weight", error);
    }
    setIsUpdateUserWeight(false);
    setIsOpen(false);
    setLoading(false);
  };
  // steps is updating from here
  const onUpdateStepsCount = async () => {
    if (targetWeight === "") {
      setShowWarning(true);
      return;
    }
    setLoading(true);
    try {
      let todaysDate = moment().format("YYYY-MM-DD");
      let isTodaysDateExistData = summaryData?.activityData?.filter(
        (activity) => {
          return (
            moment(activity.dateAssigned).format("YYYY-MM-DD") == todaysDate
          );
        }
      );
      console.log("todays date", todaysDate, isTodaysDateExistData);
      if (isTodaysDateExistData.length > 0) {
        // update the data which already exist
        let res = await axios({
          method: "post",
          url: `${APIURL}CF/api/activity/update?id=${isTodaysDateExistData[0]?.id}`,
          data: {
            atheleteID: userUUID,
            activitiesFinished: {
              ...isTodaysDateExistData[0].activitiesFinished,
              stepCount: targetWeight,
            },
            dateAssigned:
              moment(todaysDate).format("YYYY-MM-DD") + "T00:00:00.000Z",
          },
        });
        console.log(
          "response after updating the step count",
          res?.data?.result
        );
        await reloadClientSummary(userUUID);
      } else {
        // create a new data in the summary data
        let res = await axios({
          method: "post",
          url: `${APIURL}CF/api/activity/create`,
          data: {
            atheleteID: userUUID,
            activitiesFinished: {
              stepCount: targetWeight,
            },
            dateAssigned:
              moment(todaysDate).format("YYYY-MM-DD") + "T00:00:00.000Z",
          },
        });
        console.log("res after creating an step count", res?.data?.result);
        await reloadClientSummary(userUUID);
      }
    } catch (error) {
      console.log("Not able to update the todays step count due to ", error);
    } finally {
      setIsOpen(false);
      setLoading(false);
      setIsUpdateUserStepsCount(false);
    }
  };

  return (
    <Dialog
      open={isOpen}
      // onClose={() => setIsOpen(false)}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "24px",
        },
      }}
    >
      {loading && (
        <div className="absolute h-full w-full flex items-center justify-center">
          <SpurfitCircularProgress />
        </div>
      )}
      <div className="add-program-modal-head">
        <div style={{ display: "flex", alignItems: "flex-end", gap: "8px" }}>
          <p className="add-program-modal-head-title">
            {isUpdate
              ? "Update User Weight"
              : isUpdateUserStepsCount
              ? "Update Steps"
              : "Add Target Weight"}
          </p>
        </div>
        <div
          onClick={() => {
            setIsOpen(false);
            setIsUpdateUserWeight(false);
            setIsUpdateUserStepsCount(false);
          }}
          className="cursor-pointer"
        >
          <CloseRounded
            sx={{
              fontSize: "24px",
              color: "#000",
              cursor: "pointer",
            }}
          />
        </div>
      </div>
      <DialogContent>
        <div className="flex items-center justify-between gap-5">
          <Input
            label={
              isUpdate
                ? "Update Weight*"
                : isUpdateUserStepsCount
                ? "Steps*"
                : "Target Weight*"
            }
            placeholder="65"
            type={"number"}
            value={targetWeight}
            onChange={(e) => {
              setShowWarning(false);
              setTargetWeight(e.target.value);
            }}
          />
          {!isUpdate && !isUpdateUserStepsCount && (
            <Input
              label={"Add Days*"}
              placeholder={"15"}
              value={targetDays}
              type={"number"}
              onChange={(e) => {
                setShowWarning(false);
                setTargetDays(e.target.value);
              }}
            />
          )}
          <div className="flex items-center justify-between">
            {isUpdateUserStepsCount ? (
              <p className="text-font18 font-bold mr-2 mt-[21px]">Steps</p>
            ) : (
              <>
                <FormControl
                  fullWidth
                  className="outline-none focus:ring-transparent focus:border-indigo-500 border-[1.5px] "
                >
                  <label className="text-font14">Units*</label>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={unit}
                    onChange={(e) => setUnit(e.target.value)}
                    sx={{
                      borderRadius: "12px",
                      width: "80px",
                      height: "48px",
                      marginTop: "5px",
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "2px solid transparent",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                    className="outline-none focus:ring-transparent focus:border-indigo-500 border-[2px] "
                  >
                    <MenuItem value={"kg"}>kg</MenuItem>
                    <MenuItem value={"lbs"}>lbs</MenuItem>
                  </Select>
                </FormControl>
              </>
            )}
          </div>
        </div>
        {showWarning && (
          <p className="text-red-400 text-sm font-light mt-3 text-center">
            Please fill valid number
          </p>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <button
          className="button-new"
          onClick={() => {
            if (isUpdate) {
              onUpdate();
            } else if (isUpdateUserStepsCount) {
              onUpdateStepsCount();
            } else {
              onSave();
            }
          }}
        >
          {isUpdate || isUpdateUserStepsCount ? "Update" : "Save"}
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default AddTargetWeight;

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#464FEB" : "#464FEB",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#464FEB",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#8330E9" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
