import { useState } from "react";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DoneIcon from "@mui/icons-material/Done";
import { Dialog, DialogContent } from "@mui/material";
import axios from "axios";
import Input from "../../components/InputComponents/Input";

import { useSelector } from "react-redux";
import SpinnerComponent from "../../components/layout/spinner";
import toast from "../../components/Toast/toast";

const ChangePasswordModal = ({ open, setOpen }) => {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loader, setLoader] = useState(false);

  const onPasswordChange = async (e) => {
    e.preventDefault();
    if(newPassword !== confirmPassword){
      
      return
    }
    setLoader(true);
    let trainerDetails = JSON.parse(localStorage.getItem("trainerCredentials"));
    await axios({
      method: "post",
      url: `${APIURL}api/trainerpasswordreset`,
      data: {
        trainerEmail: trainerDetails?.email,
        oldPassword: oldPassword,
        newPassword: newPassword,
      },
    })
      .then(async (res) => {
        toast("Password changed successfully", "success");
        setNewPassword("");
        setConfirmPassword("");
        setOldPassword("");
      })
      .catch((err) => {
        toast("Invalid old password", "error");
      })
      .finally(() => {
        setLoader(false);
        setOpen(false);
      });
  };

  return (
    <Dialog
      open={open}
      maxWidth="md"
      PaperProps={{
        sx: { height: "480px", borderRadius: "20px" },
      }}
    >
      <div className="add-program-modal-head">
        <p className="add-program-modal-head-title">Change password</p>
        <div
          style={{
            cursor: "pointer",
          }}
          onClick={() => setOpen(false)}
        >
          <CloseRoundedIcon />
        </div>
      </div>
      <DialogContent
        style={{
          paddingLeft: 148,
          paddingRight: 148,
        }}
        className="flex flex-col items-center "
      >
        <form
          className="flex flex-col items-center"
          onSubmit={onPasswordChange}
          style={{ marginTop: 36 }}
        >
          <Input
            id={"oldPassword"}
            label={"Old Password *"}
            value={oldPassword}
            placeholder={"Old Password"}
            type={"password"}
            onChange={(e) => setOldPassword(e.target.value)}
            containerStyle={{ width: 300 }}
            required
          />

          <Input
            id={"newPassword"}
            label={"New Password *"}
            value={newPassword}
            placeholder={"New Password"}
            type={"password"}
            onChange={(e) => setNewPassword(e.target.value)}
            containerStyle={{ width: 300 }}
            minLength={8}
            maxLength={16}
            required
          />

          <Input
            id={"confirmPassword"}
            label={"Confirm Password *"}
            value={confirmPassword}
            placeholder={"Confirm Password"}
            type={"password"}
            onChange={(e) => setConfirmPassword(e.target.value)}
            containerStyle={{ width: 300 }}
            minLength={8}
            maxLength={16}
            onBlur={()=>{
              if(newPassword !== confirmPassword){
                toast("confirm password is not equal to the new password", "error");
              }
            }}
            required
          />

          <div
            className="flex items-center justify-center"
            style={{ marginTop: 36 }}
          >
            <button className="button-new">
              <DoneIcon />
              <span style={{ marginLeft: 10 }}>Update</span>
            </button>
          </div>
        </form>
        {loader && <SpinnerComponent />}
      </DialogContent>
    </Dialog>
  );
};

export default ChangePasswordModal;
