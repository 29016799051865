import CircleCheckedFilled from "@mui/icons-material/CheckCircle";
import CircleUnchecked from "@mui/icons-material/RadioButtonUnchecked";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {
  Alert,
  Box,
  Checkbox,
  CircularProgress,
  Menu,
  MenuItem,
  Popover,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import moment from "moment";
import React, { memo, useContext, useEffect, useState } from "react";
import AddIcon from "../../assets/dashboard/addIconWhite.svg";
import EditIcon from "../../assets/dashboard/editIconWhite.svg";
import { store } from "../../redux/store";
import Copilot from "../copilot/copilot";

import SparkleLogo from "../../assets/copilot/sparkleLogo.svg";
import DownArrowIcon from "../../assets/dashboard/downArrow.svg";
import MoreIcon from "../../assets/dashboard/moreIcon.svg";

import ActiveFilterIcon from "../../assets/workouts/ActiveFunnel.svg";

import { TabContext, TabPanel } from "@mui/lab";

import mixpanel from "mixpanel-browser";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CustomAlertContext } from "../../App";
import { chatClient } from "../../utils/chat";
import { mixPanelInit } from "../../utils/functions";
import Layout from "../layout/Layout";
import { ShowCopilotContext } from "../layout/SpurfitLayout";
import AddClientModal from "./AddClientModal";
import SearchBar from "./SearchBar";
import "./styles.css";

import FilterIcon from "../../assets/workoutBuilder/filterIcon.svg";

import { ContentCopyRounded } from "@mui/icons-material";
import { deleteLead } from "../../ApiServices/Dashboard/Api";
import AddLeads from "../../features/AddLeads";
import ViewLeads from "../../features/ViewLeads";
import BinIcon from "../../pages/library/assests/BinIcon";
import AssignAthleteToCoach from "./AssignAthleteToCoach";
import ChecklistModal from "./ChecklistModal";
import {
  AllModals,
  FirstCell,
  getDaysLeft,
  HeaderCell,
  isPlanExpired,
  MealCell,
  ProgramCell,
  StatusCell,
} from "./helpers";
import ContactUsButton from "../Button/ContactUsButton";
import { height, minWidth } from "styled-system";
import Questionaire from "../ClientsView/Questionaire";
import ViewLeadsFormModal from "./ViewLeadsFormModal";
import { sendInvitationMail } from "../../redux/clientsSlice";
import toast from "../../components/Toast/toast";

export const SpurfitCircularProgress = ({ size }) => (
  <Box
    sx={{
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <CircularProgress size={size || 20} sx={{ color: "#6242EA" }} />
  </Box>
);

export default function NewDashboard() {
  const dispatch = useDispatch();

  const ACTIVE = "Active";
  const PENDING = "Pending";
  const ARCHIVE = "Archive";
  const UNARCHIVE = "Unarchive";
  const ONBOARDING = "Onboarding";
  const PLANEXPIRED = "Plan Expired";
  const LEADS = "Leads";
  const gymAffiliation = useSelector(
    (state) => state.auth?.data?.gymAffliation
  );
  const isLivezy = gymAffiliation === "Livezy";
  const isAdmin = useSelector((state) => state.auth?.data?.isAdmin);
  const APIURL = useSelector((state) => state.auth.apiURL);

  const [data, setData] = useState([]);
  const [tempUserData, setTempUserData] = useState({
    active: [],
    pending: [],
    archive: [],
    onboarding: [],
    leads: [],
  });
  const [buddyData, setBuddyData] = useState({});
  const [activeData, setActiveData] = useState([]);
  const [archiveData, setArchiveData] = useState([]);
  const [pendingData, setPendingData] = useState([]);
  const [onboarding, setOnboarding] = useState([]);
  const [planExpiredData, setPlanExpiredData] = useState([]);
  const [leadsData, setLeadsData] = useState([]);
  const [activeTab, setActiveTab] = useState(
    isLivezy && isAdmin ? ONBOARDING : ACTIVE
  );
  // const [copilotOpen, setCopilotOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [tabsAnchorEl, setTabsAnchorEl] = useState(null);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [openAddClientsModal, setOpenAddClientsModal] = useState(false);
  const [openAddLeadsModal, setOpenAddLeadsModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [openLeadsModal, setOpenLeadsModal] = useState(false);
  const [leadsUserInfo, setLeadsUserInfo] = useState({});
  const [copyLinkAlert, setCopyLinkAlert] = useState(false);

  // for onboardingonboarding tab
  const [selectedUser, setSelectedUser] = useState(null);
  const [showAddProgramModal, setShowAddProgramModal] = useState(false);
  const [showAddMealModal, setShowAddMealModal] = useState(false);

  const { copilotOpen, setCopilotOpen } = useContext(ShowCopilotContext);
  const pageStatus = useSelector((state) => state.popupState.pageStatus);
  const trainerUUID = useSelector((state) => state.auth.trainerUUID);
  const trainerInfo = useSelector((state) => state.auth?.data);

  const [isFilterActive, setIsFilterActive] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [assignAthleteToCoach, setAssignAthleteToCoach] = useState(false);
  const [emailInvitationModalOpen, setEmailInvitationModalOpen] =
    useState(false);
  const { displayAlert } = useContext(CustomAlertContext);
  const [isEditOpen, setIsEditOpen] = useState({
    id: "",
    isOpen: false,
  });
  const [leadEditedData, setLeadEditedData] = useState({
    name: "",
    email: "",
    contactNumber: "",
    dateCreated: "",
    age: "",
    gender: "",
    height: "",
    weight: "",
    goals: "",
  });
  const [isViewLeadModal, setIsViewLeadModal] = useState(false);
  const [leadId, setLeadId] = useState("");

  const updateLastAccessed = async () => {
    if (!store?.getState()?.auth?.trainerUUID) return;
    await axios({
      method: "post",
      url: `${APIURL}CF/api/updateLastAccessed`,
      data: {
        coachID: store.getState().auth.trainerUUID,
      },
    })
      .then((res) => {
        console.log("updated last accessed");
      })
      .catch((err) => {
        console.log("error updating last accessed");
      });
  };

  const getChannelId = async (UserUUID) => {
    try {
      const filter = {
        type: "messaging",
        members: {
          $eq: [
            JSON.parse(localStorage.getItem("trainerCredentials"))?.password,
            UserUUID,
          ],
        },
      };
      const sort = [{ last_message_at: -1 }];
      const channels = await chatClient.queryChannels(filter, sort, {
        watch: true, // this is the default
        state: true,
      });
      if (channels && channels.length > 0) return channels[0].id;
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeletePendingUser = async (email) => {
    setLoading(true);
    try {
      let res = await axios.post(`${APIURL}api/deletePendingUser`, {
        trainerUUID: trainerUUID,
        email: email,
      });
      // Handle the response here if needed
      console.log("Response:", res.data);
      let filteredPending = pendingData.filter(
        (item) => item?.invitedUser?.email != email
      );
      console.log("filteredPending", filteredPending);
      setPendingData(filteredPending);
      setTempUserData({
        ...tempUserData,
        pending: filteredPending,
      });
    } catch (err) {
      console.log("Not able to delete the pending user", err);
    }
    setLoading(false);
    console.log("email ha kya", email);
  };
  useEffect(() => {
    if (!trainerUUID && gymAffiliation === undefined) {
      return;
    }
    // dispatch(toggle());
    axios({
      method: "get",
      url: `${APIURL}api/trainerinfo`,
      params: {
        trainerUUID: store.getState().auth.trainerUUID,
      },
    })
      .then((res) => {
        localStorage.setItem(
          "trainingType",
          `${JSON.stringify(res.data.result[0].trainingType)}`
        );
        setTrainerinfo(res.data.result[0]);
      })
      .catch((err) => {});
    getTrainerDashboard();
    updateLastAccessed();

    mixPanelInit();
    try {
      (function (d, t) {
        window.chatwootSettings = {
          hideMessageBubble: true,
          // position: "left", // This can be left or right
          // locale: "en", // Language to be set
          // useBrowserLanguage: false, // Set widget language from user's browser
          // type: "standard", // [standard, expanded_bubble]
          // darkMode: "auto", // [light, auto]
        };

        var BASE_URL = "https://app.chatwoot.com";
        var g = d.createElement(t),
          s = d.getElementsByTagName(t)[0];
        g.src = BASE_URL + "/packs/js/sdk.js";
        g.defer = true;
        g.async = true;
        s.parentNode.insertBefore(g, s);
        g.onload = function () {
          window.chatwootSDK.run({
            websiteToken: "5t587U4VtsghJAueyC2nb6oL",
            baseUrl: BASE_URL,
          });
        };
      })(document, "script");
      let name = store.getState().auth?.data?.name;
      let email = store.getState().auth?.data?.email;
      if (!name || !email) return;
      window.$chatwoot.setUser(`${trainerUUID}`, {
        name, // Name of the user
        email, // Email of the user
        // identifier_hash: trainerUUID, // Identifier Hash generated in the previous step
      });
    } catch (error) {
      console.log("error in chat woot", error);
    }
  }, [trainerUUID, gymAffiliation]);

  const onBoardingOptions = [
    "Questionaire filled",
    "Questionnaire filled",
    "Physique pictures received",
    "Plan sent",
    "Intro call done",
  ];

  var getTrainerDashboard = async () => {
    let trainerDetails = JSON.parse(localStorage.getItem("trainerCredentials"));

    const url = `${APIURL}CF/api/CoachWeb/Get/trainerDashboardAll`;

    const params = {
      coachID: trainerUUID,
    };

    setLoading(true);
    await axios({
      method: "get",
      url: url,
      params: params,
    })
      .then(async (res) => {
        setData(res.data.result);
        let filteredPending = res?.data?.pending?.filter(
          (item) => item?.invitedUser?.email != ""
        );
        filteredPending.sort((a, b) => {
          const dateA = new Date(a.dateSent);
          const dateB = new Date(b.dateSent);
          return dateB - dateA;
        });
        setPendingData(filteredPending);
        let active = [];
        let archive = [];
        let onboarding = [];
        let planExpired = [];

        let clientBuddyMap = {};
        let clientIDUserUUIDMap = {};

        let democlientUUID;
        res.data.result?.forEach((item) => {
          if (
            gymAffiliation === "Livezy" &&
            onBoardingOptions.includes(item?.onboardingStatus_LivEzy)
          ) {
            onboarding.push(item);
          } else if (gymAffiliation === "Livezy" && isPlanExpired(item)) {
            planExpired.push(item);
          } else if (item.IsActive === 1 || item.IsActive === true) {
            if (
              res.data?.result?.length > 1 &&
              !(item?.Email || "").includes("@demo.com")
              // item?.Email != "democlient@demo.com"
            ) {
              active.push(item);
            } else if (res.data?.result?.length == 1) {
              active.push(item);
            }
          } else if (item.IsActive === 0 || item.IsActive === false) {
            archive.push(item);
          }

          if ((item?.Email || "").includes("@demo.com")) {
            democlientUUID = item?.UserUUID;
          }

          if (item?.buddyClientID_LivEzy) {
            clientBuddyMap[item?.clientID_LivEzy] = item?.buddyClientID_LivEzy;
            clientIDUserUUIDMap[item?.clientID_LivEzy] = item?.UserUUID;
          }
        });

        // now we want buddyClientId : userUUID
        let buddyData = {};
        Object.keys(clientBuddyMap).forEach((key) => {
          buddyData[key] = clientIDUserUUIDMap[key];
        });
        setBuddyData(buddyData);
        if (democlientUUID && res.data.result?.length > 1) {
          deleteOneAthlete(democlientUUID);
        }
        setActiveData(active);
        setArchiveData(archive);
        setOnboarding(onboarding);
        setPlanExpiredData(planExpired);
        setTempUserData({
          active: active,
          archive: archive,
          pending: filteredPending,
          onboarding: onboarding,
          planExpired: planExpired,
          leads: leadsData,
        });

        console.log("pageStatus", pageStatus);
        // dispatch(setClientsData([...active, ...archive]));
        // if (!pageStatus?.home?.skipped && !pageStatus?.home?.finished) {
        //   let timeOut = setTimeout(() => {
        //     dispatch(setTourRun(true));
        //   }, 3000);
        // }
      })
      .catch((err) => {
        console.log("Home: ", err);
      })
      .finally(() => {
        setLoading(false);

        if (!isLivezy) {
          getTrainerLeads();
        }
      });
  };

  var getTrainerLeads = async () => {
    const url = `${APIURL}CF/api/LeadGen/getCoachLeads`;
    const params = {
      coachID: trainerUUID,
    };
    setLoading(true);
    await axios({
      method: "get",
      url: url,
      params: params,
    })
      .then(async (res) => {
        let result = res.data.result;
        console.log("leads data received", result);
        setLeadsData(result);
        setTempUserData((prev) => ({ ...(prev ?? {}), leads: result }));
      })
      .catch((err) => {
        console.log("Home: ", err);
      })
      .finally(() => {
        setLoading(false);
        // if home is not skipped or finished
      });
  };

  const resendInvite = async (name, email) => {
    setLoading(true);
    await axios({
      method: "post",
      url: `${APIURL}api/resendPendingEmail`,
      data: {
        trainerName: trainerInfo?.name,
        trainerEmail: trainerInfo?.email,
        trainerURL: `https://trainer.spur.fit/addTrainee/${trainerInfo?.id}`,
        userName: name,
        userEmail: email,
        trainerUUID: trainerInfo?.id,
      },
    })
      .then((res) => {
        // update the date of the pending user
        let updatedPending = pendingData.map((item) => {
          if (item.invitedUser.email === email) {
            item.dateSent = new Date();
          }
          return item;
        });
        // sort the pending data according to the date
        updatedPending.sort((a, b) => {
          const dateA = new Date(a.dateSent);
          const dateB = new Date(b.dateSent);
          return dateB - dateA;
        });

        setPendingData(updatedPending);
        setTempUserData({
          ...tempUserData,
          pending: updatedPending,
        });
        displayAlert({
          message: "Invite sent successfully",
          type: "success",
        });
      })
      .catch((err) => {
        displayAlert({
          message: "Error sending invite",
          type: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleSendInvite = async (email) => {
    let trainerDetails = JSON.parse(localStorage.getItem("trainerCredentials"));
    let clients = [];
    let temp = {};
    temp["userName"] = "";
    temp["userEmail"] = email;
    temp["trainerName"] = trainerInfo?.name;
    temp["trainerEmail"] = trainerInfo?.email;
    temp["trainerUUID"] = trainerInfo?.id;
    temp[
      "trainerURL"
    ] = `https://trainer.spur.fit/addTrainee/${trainerInfo?.id}`;

    clients.push(temp);
    console.log("clients: ", clients);
    await axios({
      method: "post",
      url: `${APIURL}api/addtraineeemail`,
      data: clients,
    }).then((res) => {
      console.log("added successfully", res);
      inviteSuccess();
    });

    setOpen(false);
  };
  const inviteSuccess = () => {
    setShowAlert(true);
    let timeOut = setTimeout(() => {
      getTrainerDashboard();
    }, 500);
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };

  const linkCopySuccess = () => {
    setCopyLinkAlert(true);
    setTimeout(() => {
      setCopyLinkAlert(false);
    }, 3000);
  };

  const addLeadSuccess = () => {
    getTrainerLeads();
  };

  const setUserStatus = () => {
    let status = selectedItem?.IsActive;
    let userUUID = selectedItem?.UserUUID;

    axios({
      method: "post",
      url: `${APIURL}api/updateuserprogramactivityAll`,
      data: {
        userUUID: userUUID,
      },
    })
      .then(async (res) => {
        getTrainerDashboard(status);
      })
      .catch((err) => {});
  };

  const goToClient = (row) => {
    if (row?.IsActive == 1 || row?.IsActive == true) {
      history.push(`/clientsView?userUUID=${row.UserUUID}`);
      // window.open(window.origin + `/clientsView?userUUID=${row.UserUUID}`, "_blank")
    }
  };

  const trimText = (text, string) => {
    let maxLength = string.length;
    if (text.length > maxLength) {
      return `${text.slice(0, maxLength)}...`;
    }
    return text;
  };

  const calculateAge = (birthdate) => {
    const birthMoment = moment(birthdate);
    const age = moment().diff(birthMoment, "years");
    return age;
  };
  const deleteOneAthlete = async (AthleteId) => {
    setLoading(true);
    await axios({
      method: "post",
      url: `${APIURL}CF/api/CoachWeb/Delete/deleteAtheleteByID`,
      data: {
        atheleteID: AthleteId,
      },
    })
      .then((res) => {
        displayAlert({
          message: "Athlete deleted successfully",
          type: "success",
        });
        // filter from archive data
        let filteredArchive = archiveData.filter(
          (item) => item.UserUUID != AthleteId
        );
        setArchiveData(filteredArchive);
        setTempUserData({
          ...tempUserData,
          archive: filteredArchive,
        });
      })
      .catch((err) => {
        displayAlert({
          message: "Error deleting athlete",
          type: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getLastWorkoutDate = (params) => {
    const lastWorkoutDate = params.row.LastWorkoutDate
      ? moment(params.row.LastWorkoutDate)
      : null;
    const garminLastWorkoutDate = params.row.garminLastWorkoutDate
      ? moment(params.row.garminLastWorkoutDate)
      : null;

    let maxDate = null;
    if (lastWorkoutDate && garminLastWorkoutDate) {
      maxDate = moment.max(lastWorkoutDate, garminLastWorkoutDate);
    } else if (lastWorkoutDate) {
      maxDate = lastWorkoutDate;
    } else if (garminLastWorkoutDate) {
      maxDate = garminLastWorkoutDate;
    }

    return maxDate ? maxDate.format("MMMM Do' YY") : "";
  };

  const columns = [
    {
      field: "Name",
      headerName: "Name",
      flex: 1,
      minWidth: 120,
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Client Name" />,
      renderCell: (params) => (
        <FirstCell params={params} onClick={() => goToClient(params.row)} />
      ),
    },
    {
      field: "ProgramName",
      headerName: "Program",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Program" />,
      renderCell: (params) => (
        <Typography
          onClick={() => goToClient(params.row)}
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
            whiteSpace: "normal",
            width: "100%",
            textOverflow: "ellipsis",
            textAlign: "center",
          }}
        >
          {params?.row?.phasedProgramName ?? params.row.ProgramName ?? ""}
        </Typography>
      ),
    },
    {
      field: "Adherence",
      headerName: "Compliance",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Compliance" />,
      renderCell: (params) => (
        <Typography
          onClick={() => goToClient(params.row)}
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
          }}
        >
          {params.row.Adherence ? params.row.Adherence + "%" : ""}
        </Typography>
      ),
    },
    {
      field: "LastWorkoutDate",
      headerName: "Last Workout Date",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Last Workout Date" />,

      renderCell: (params) => (
        <Typography
          onClick={() => goToClient(params.row)}
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
          }}
        >
          {getLastWorkoutDate(params)}
        </Typography>
      ),
    },
    {
      field: "LastPlannedWorkoutDate",
      headerName: "Last Login Date",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <Typography
          sx={{
            fontSize: "13px",
            lineHeight: "20px",
            fontFamily: "DM Sans",
            color: "#333333",
            fontWeight: 500,
          }}
        >
          Last Login Date
        </Typography>
      ),

      renderCell: (params) => (
        <Typography
          onClick={() => goToClient(params.row)}
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
          }}
        >
          {params.row.lastAccessed
            ? moment(params.row.lastAccessed).format("MMMM Do' YY")
            : ""}
        </Typography>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <HeaderCell label="Actions" style={{ textAlign: "center" }} />
      ),
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            onClick={() => setCopilotOpen(true)}
            src={SparkleLogo}
            style={{ height: "16px", width: "16px" }}
            alt=""
          />
          <img
            src={MoreIcon}
            alt="more"
            onClick={(e) => handleClick(e, params?.row)}
            style={{ cursor: "pointer", padding: "10px" }}
          />
          <Menu
            id={`item-actions-${params?.row?.UserUUID}`}
            anchorEl={anchorEl}
            open={selectedItem === params?.row && Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              sx={{
                fontSize: "14px",
                fontFamily: "DM Sans",
                fontWeight: 500,
                lineHeight: "20px",
              }}
              onClick={handleArchive}
            >
              {params?.row?.IsActive ? ARCHIVE : UNARCHIVE}
            </MenuItem>
            {/* {activeTab === ACTIVE && (
              <MenuItem
                sx={{
                  fontSize: "14px",
                  fontFamily: "DM Sans",
                  fontWeight: 500,
                  lineHeight: "20px",
                }}
                onClick={handleMessage}
              >
                Message
              </MenuItem>
            )} */}
            {activeTab === ARCHIVE && (
              <MenuItem
                sx={{
                  fontSize: "14px",
                  fontFamily: "DM Sans",
                  fontWeight: 500,
                  lineHeight: "20px",
                }}
                onClick={() => {
                  deleteOneAthlete(params?.row?.UserUUID);
                  console.log("delete: ", params?.row?.UserUUID);
                }}
              >
                Delete
              </MenuItem>
            )}
            {isAdmin && activeTab !== ARCHIVE && (
              <MenuItem
                sx={{
                  fontSize: "14px",
                  fontFamily: "DM Sans",
                  fontWeight: 500,
                  lineHeight: "20px",
                }}
                onClick={() => {
                  console.log("asign: ", params?.row?.UserUUID);
                  setAssignAthleteToCoach(true);
                }}
              >
                Assign to Coach
              </MenuItem>
            )}
          </Menu>
        </div>
      ),
    },
  ];

  const LivEzyColumns = [
    {
      field: "Name",
      headerName: "Name",
      flex: 1,
      minWidth: 200,
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Trainee" />,
      renderCell: (params) => (
        <FirstCell params={params} onClick={() => goToClient(params.row)} />
      ),
    },
    {
      field: "Days Left",
      headerName: "Days Left",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",

      renderHeader: () => <HeaderCell label="Days Left" />,
      valueGetter: (params) => {
        return params.row?.planStartDate_LivEzy ? getDaysLeft(params.row) : "";
      },
      renderCell: (params) => (
        <Typography
          onClick={() => goToClient(params.row)}
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#333333",
            fontWeight: 400,
          }}
        >
          {getDaysLeft(params.row)}
        </Typography>
      ),
    },
    {
      field: "clientID",
      headerName: "Client ID",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Client ID" />,
      valueGetter: (params) => {
        return params.row?.clientID_LivEzy ?? "";
      },
      renderCell: (params) => (
        <Typography
          onClick={() => goToClient(params.row)}
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
            whiteSpace: "normal",
            width: "100%",
            textOverflow: "ellipsis",
            textAlign: "center",
          }}
        >
          {params.row.clientID_LivEzy ?? ""}
        </Typography>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Email" />,
      valueGetter: (params) => {
        return params.row?.Email ?? "";
      },
      renderCell: (params) => (
        <Typography
          onClick={() => goToClient(params.row)}
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
            whiteSpace: "normal",
            width: "100%",
            textOverflow: "ellipsis",
            textAlign: "center",
          }}
        >
          {params.row.Email ?? ""}
        </Typography>
      ),
    },
    {
      field: "Phone",
      headerName: "Phone",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Phone" />,
      valueGetter: (params) => {
        return params.row?.contactNumber ?? "";
      },
      renderCell: (params) => (
        <Typography
          onClick={() => goToClient(params.row)}
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
            whiteSpace: "normal",
            width: "100%",
            textOverflow: "ellipsis",
            textAlign: "center",
          }}
        >
          {params.row.contactNumber ?? ""}
        </Typography>
      ),
    },
    {
      field: "Membership Details",
      headerName: "Compliance",
      flex: 1,
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Membership Details" />,
      valueGetter: (params) => {
        return params.row?.planName_LivEzy ?? "";
      },
      renderCell: (params) => (
        <Typography
          style={{ overflow: "hidden", textOverflow: "ellipsis" }}
          onClick={() => goToClient(params.row)}
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
          }}
        >
          {trimText(params.row?.planName_LivEzy ?? "", "Membership Details")}
        </Typography>
      ),
    },
    {
      field: "renewal_LivEzy",
      headerName: "Renewal",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Renewal" />,
      valueGetter: (params) => {
        return params.row?.renewal_LivEzy ?? "";
      },
      renderCell: (params) => (
        <Typography
          onClick={() => goToClient(params.row)}
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
          }}
        >
          {params.row.renewal_LivEzy === "true" ? "Yes" : "No"}
        </Typography>
      ),
    },
    {
      field: "referredBy_LivEzy",
      headerName: "Referred By",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Referred By" />,
      valueGetter: (params) => {
        return params.row?.referredBy_LivEzy ?? "";
      },
      renderCell: (params) => (
        <Typography
          onClick={() => goToClient(params.row)}
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
          }}
        >
          {params.row.referral_LivEzy ?? ""}
        </Typography>
      ),
    },
    {
      field: "buddyClientID_LivEzy",
      headerName: "Buddy ID",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Buddy ID" />,
      valueGetter: (params) => {
        return params.row?.buddyClientID_LivEzy ?? "";
      },
      renderCell: (params) => (
        <Typography
          onClick={() => goToClient(params.row)}
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
          }}
        >
          {params.row.buddyClientID_LivEzy ?? ""}
        </Typography>
      ),
    },
    {
      field: "dietrypref",
      headerName: "Dietary preference",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => {
        return params.row?.dietaryPreferences_LivEzy ?? "";
      },
      renderHeader: () => <HeaderCell label="Dietary preference" />,
      renderCell: (params) => (
        <Typography
          onClick={() => goToClient(params.row)}
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
          }}
        >
          {params.row?.dietaryPreferences_LivEzy ?? ""}
        </Typography>
      ),
    },
    {
      field: "age",
      headerName: "Age",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Age" />,
      valueGetter: (params) => {
        return params.row?.birthDate ? calculateAge(params.row?.birthDate) : "";
      },
      renderCell: (params) => (
        <Typography
          onClick={() => goToClient(params.row)}
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
          }}
        >
          {(params.row?.birthDate && calculateAge(params.row?.birthDate)) || ""}
        </Typography>
      ),
    },
    {
      field: "gender",
      headerName: "gender",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Gender" />,
      valueGetter: (params) => {
        return params.row?.gender ?? "";
      },
      renderCell: (params) => (
        <Typography
          onClick={() => goToClient(params.row)}
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
          }}
        >
          {params.row.gender ?? ""}
        </Typography>
      ),
    },

    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      sortable: false,
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <HeaderCell label="Actions" style={{ textAlign: "center" }} />
      ),
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Questionaire key={params.row.UserUUID} userInfo={params.row} />
          <img
            src={MoreIcon}
            alt="more"
            onClick={(e) => handleClick(e, params?.row)}
            style={{ cursor: "pointer", padding: "10px" }}
          />
          <Menu
            id={`item-actions-${params?.row?.UserUUID}`}
            anchorEl={anchorEl}
            open={selectedItem === params?.row && Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              sx={{
                fontSize: "14px",
                fontFamily: "DM Sans",
                fontWeight: 500,
                lineHeight: "20px",
              }}
              onClick={handleArchive}
            >
              {params?.row?.IsActive ? ARCHIVE : UNARCHIVE}
            </MenuItem>
            <MenuItem
              sx={{
                fontSize: "14px",
                fontFamily: "DM Sans",
                fontWeight: 500,
                lineHeight: "20px",
              }}
              onClick={() => {
                triggerInvitationMail(params?.row?.UserUUID || "");
                setEmailInvitationModalOpen(true);
              }}
            >
              Send invitation email
            </MenuItem>

            {activeTab === ARCHIVE && (
              <MenuItem
                sx={{
                  fontSize: "14px",
                  fontFamily: "DM Sans",
                  fontWeight: 500,
                  lineHeight: "20px",
                }}
                onClick={() => {
                  deleteOneAthlete(params?.row?.UserUUID);
                  console.log("delete: ", params?.row?.UserUUID);
                }}
              >
                Delete
              </MenuItem>
            )}
            {isAdmin && activeTab !== ARCHIVE && (
              <MenuItem
                sx={{
                  fontSize: "14px",
                  fontFamily: "DM Sans",
                  fontWeight: 500,
                  lineHeight: "20px",
                }}
                onClick={() => {
                  console.log("asign: ", params?.row?.UserUUID);
                  setAssignAthleteToCoach(true);
                }}
              >
                Assign to Coach
              </MenuItem>
            )}
          </Menu>
        </div>
      ),
    },
  ];

  const pendingColumns = [
    // {
    //   field: "trainee",
    //   headerName: "Trainee",
    //   flex: 1,
    //   minWidth: 120,
    //   cellClassName: "super-app-theme--cell",
    //   headerClassName: "super-app-theme--header",
    //   renderHeader: () => (
    //     <Typography
    //       sx={{
    //         fontSize: "13px",
    //         lineHeight: "20px",
    //         fontFamily: "DM Sans",
    //         color: "#333333",
    //         fontWeight: 500,
    //       }}
    //     >
    //       Trainee
    //     </Typography>
    //   ),
    //   renderCell: (params) => (
    //     <Typography
    //       sx={{
    //         fontSize: "13px",
    //         lineHeight: "16px",
    //         fontFamily: "DM Sans",
    //         color: "#605E5C",
    //         fontWeight: 400,
    //       }}
    //     >
    //       {params.row.invitedUser.name ? params.row.invitedUser.name : ""}
    //     </Typography>
    //   ),
    // },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 120,
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <Typography
          sx={{
            fontSize: "13px",
            lineHeight: "20px",
            fontFamily: "DM Sans",
            color: "#333333",
            fontWeight: 500,
          }}
        >
          Email
        </Typography>
      ),

      renderCell: (params) => (
        <Typography
          sx={{
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
          }}
        >
          {params.row.invitedUser.email ? params.row.invitedUser.email : ""}
        </Typography>
      ),
    },
    {
      field: "dateSent",
      headerName: "Date Sent",
      flex: 1,
      minWidth: 120,
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <Typography
          sx={{
            fontSize: "13px",
            lineHeight: "20px",
            fontFamily: "DM Sans",
            color: "#333333",
            fontWeight: 500,
          }}
        >
          Date Sent
        </Typography>
      ),
      valueGetter: (params) => {
        return params.row.dateSent
          ? moment(params.row.dateSent).format("MMMM Do' YY")
          : "";
      },

      renderCell: (params) => (
        <Typography
          sx={{
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
          }}
        >
          {params.row.dateSent
            ? moment(params.row.dateSent).format("MMMM Do' YY")
            : ""}
        </Typography>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      width: 200, // Adjust the width as needed
      sortable: false,
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <Typography
          sx={{
            fontSize: "13px",
            lineHeight: "20px",
            fontFamily: "DM Sans",
            color: "#333333",
            fontWeight: 500,
          }}
        >
          Actions
        </Typography>
      ),
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            paddingRight: "24px",
            width: "100%",
          }}
        >
          <button
            style={{
              fontSize: "13px",
              lineHeight: "16px",
              fontFamily: "DM Sans",
              color: "#605E5C",
              cursor: "pointer",
              marginRight: "38px", // Add margin between buttons
            }}
            onClick={() =>
              resendInvite(
                params?.row?.invitedUser?.name,
                params?.row?.invitedUser?.email
              )
            }
          >
            Resend Invite
          </button>
          <button
            style={{
              fontSize: "13px",
              lineHeight: "16px",
              fontFamily: "DM Sans",
              color: "#605E5C",
              cursor: "pointer",
            }}
            onClick={() =>
              handleDeletePendingUser(params?.row?.invitedUser?.email)
            }
          >
            <BinIcon />
          </button>
        </div>
      ),
    },
  ];

  const leadsColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 180,
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <Typography
          sx={{
            fontSize: "13px",
            lineHeight: "20px",
            fontFamily: "DM Sans",
            color: "#333333",
            fontWeight: 500,
          }}
        >
          Name
        </Typography>
      ),

      renderCell: (params) => (
        <Typography
          sx={{
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
            cursor: "pointer",
          }}
        >
          {params.row.name ? params.row.name : "-"}
        </Typography>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 130,
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <Typography
          sx={{
            fontSize: "13px",
            lineHeight: "20px",
            fontFamily: "DM Sans",
            color: "#333333",
            fontWeight: 500,
          }}
        >
          Email
        </Typography>
      ),

      renderCell: (params) => (
        <Typography
          sx={{
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
          }}
        >
          {params.row.email ? params.row.email : "-"}
        </Typography>
      ),
    },
    {
      field: "contactNumber",
      headerName: "Contact Number",
      flex: 1,
      minWidth: 140,
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <Typography
          sx={{
            fontSize: "13px",
            lineHeight: "20px",
            fontFamily: "DM Sans",
            color: "#333333",
            fontWeight: 500,
          }}
        >
          Contact Number
        </Typography>
      ),
      renderCell: (params) => (
        <Typography
          sx={{
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
          }}
        >
          {params.row.contactNumber ? "+" + params.row.contactNumber : "-"}
        </Typography>
      ),
    },
    {
      field: "dateCreated",
      headerName: "Date Created",
      flex: 1,
      minWidth: 150,
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <Typography
          sx={{
            fontSize: "13px",
            lineHeight: "20px",
            fontFamily: "DM Sans",
            color: "#333333",
            fontWeight: 500,
            marginRight: "5px",
          }}
        >
          Date Created
        </Typography>
      ),
      renderCell: (params) => {
        const formattedDate = params.row.dateCreated
          ? moment(params.row.dateCreated).format("MMMM Do 'YY")
          : "-";
        return (
          <Typography
            sx={{
              fontSize: "13px",
              lineHeight: "16px",
              fontFamily: "DM Sans",
              color: "#605E5C",
              fontWeight: 400,
            }}
          >
            {formattedDate}
          </Typography>
        );
      },
    },

    {
      field: "age",
      headerName: "Age",
      flex: 1,
      minWidth: 50,
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <Typography
          sx={{
            fontSize: "13px",
            lineHeight: "20px",
            fontFamily: "DM Sans",
            color: "#333333",
            fontWeight: 500,
          }}
        >
          Age
        </Typography>
      ),

      renderCell: (params) => (
        <Typography
          sx={{
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
          }}
        >
          {params.row.answeredForm ? params.row.answeredForm.age : "-"}
        </Typography>
      ),
    },
    {
      field: "gender",
      headerName: "Gender",
      flex: 1,
      minWidth: 50,
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <Typography
          sx={{
            fontSize: "13px",
            lineHeight: "20px",
            fontFamily: "DM Sans",
            color: "#333333",
            fontWeight: 500,
          }}
        >
          Gender
        </Typography>
      ),

      renderCell: (params) => (
        <Typography
          sx={{
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
          }}
        >
          {params.row.answeredForm ? params.row.answeredForm.gender : "-"}
        </Typography>
      ),
    },
    {
      field: "height",
      headerName: "Height",
      flex: 1,
      minWidth: 50,
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <Typography
          sx={{
            fontSize: "13px",
            lineHeight: "20px",
            fontFamily: "DM Sans",
            color: "#333333",
            fontWeight: 500,
          }}
        >
          Height
        </Typography>
      ),

      renderCell: (params) => (
        <Typography
          sx={{
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
          }}
        >
          {params.row.answeredForm ? params.row.answeredForm.height : "-"}
        </Typography>
      ),
    },
    {
      field: "weigth",
      headerName: "Weight",
      flex: 1,
      minWidth: 50,
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <Typography
          sx={{
            fontSize: "13px",
            lineHeight: "20px",
            fontFamily: "DM Sans",
            color: "#333333",
            fontWeight: 500,
          }}
        >
          Weight
        </Typography>
      ),

      renderCell: (params) => (
        <Typography
          sx={{
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
          }}
        >
          {params.row.answeredForm ? params.row.answeredForm.weight : "-"}
        </Typography>
      ),
    },
    {
      field: "goals",
      headerName: "Goals",
      flex: 1,
      minWidth: 120,
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <Typography
          sx={{
            fontSize: "13px",
            lineHeight: "20px",
            fontFamily: "DM Sans",
            color: "#333333",
            fontWeight: 500,
          }}
        >
          Goals
        </Typography>
      ),

      renderCell: (params) => (
        <Typography
          sx={{
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
            maxWidth: 120,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            cursor: "pointer",
          }}
        >
          {params.row.answeredForm ? params.row.answeredForm.goals : "-"}
        </Typography>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      width: 50,
      sortable: false,
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <Typography
          sx={{
            fontSize: "13px",
            lineHeight: "20px",
            fontFamily: "DM Sans",
            color: "#333333",
            fontWeight: 500,
          }}
        ></Typography>
      ),

      renderCell: (params) => (
        // <div
        //   style={{
        //     display: "flex",
        //     alignItems: "center",
        //     justifyContent: "flex-end",
        //     paddingRight: "24px",
        //     width: "100%",
        //     cursor: "pointer",
        //   }}
        //   onClick={async () => {
        //     setLoading(true);
        //     try {
        //       await deleteLead(params.row);
        //       // remove from leads data
        //       let filteredLeads = leadsData.filter(
        //         (item) => item.id != params.row.id
        //       );
        //       setLeadsData(filteredLeads);
        //       displayAlert({
        //         message: "Lead deleted successfully",
        //         type: "success",
        //       });
        //     } catch (e) {
        //       displayAlert({
        //         message: "Error deleting lead",
        //         type: "error",
        //       });
        //     } finally {
        //       setLoading(false);
        //     }
        //   }}
        // >
        //   <BinIcon />
        // </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            paddingRight: "24px",
            width: "100%",
            cursor: "pointer",
          }}
        >
          <img
            src={MoreIcon}
            alt="more"
            onClick={(e) => {
              e.stopPropagation();
              handleClick(e, params?.row);
            }}
            style={{ cursor: "pointer", padding: "10px", marginRight: "40px" }}
          />
          <Menu
            id={`item-actions-${params?.row?.UserUUID}`}
            anchorEl={anchorEl}
            open={selectedItem === params?.row && Boolean(anchorEl)}
            onClose={(e) => {
              e.stopPropagation();
              handleClose();
            }}
          >
            <MenuItem
              sx={{
                fontSize: "14px",
                fontFamily: "DM Sans",
                fontWeight: 500,
                lineHeight: "20px",
              }}
              onClick={(e) => {
                e.stopPropagation();
                handleSendInvite(params?.row?.email);
              }}
            >
              Send Invite
            </MenuItem>
            <MenuItem
              sx={{
                fontSize: "14px",
                fontFamily: "DM Sans",
                fontWeight: 500,
                lineHeight: "20px",
              }}
              onClick={async (e) => {
                e.stopPropagation();
                setLoading(true);
                try {
                  await deleteLead(params.row);
                  // remove from leads data
                  let filteredLeads = leadsData.filter(
                    (item) => item.id != params.row.id
                  );
                  setLeadsData(filteredLeads);
                  displayAlert({
                    message: "Lead deleted successfully",
                    type: "success",
                  });
                } catch (e) {
                  displayAlert({
                    message: "Error deleting lead",
                    type: "error",
                  });
                } finally {
                  setLoading(false);
                }
              }}
            >
              Delete
            </MenuItem>
            {/* <MenuItem
              sx={{
                fontSize: "14px",
                fontFamily: "DM Sans",
                fontWeight: 500,
                lineHeight: "20px",
              }}
              onClick={async (e) => {
                 e.stopPropagation();
                setIsEditOpen({
                  id: params.row.id,
                  isOpen: true,
                });
                setLeadEditedData({
                  name: params.row.name ? params.row.name : "",
                  email: params.row.email ? params.row.email : "",
                  contactNumber: params.row.contactNumber
                    ? params.row.contactNumber
                    : "",
                  dateCreated: params.row.dateCreated
                    ? params.row.dateCreated
                    : "",
                  age: params.row.answeredForm
                    ? params.row.answeredForm.age
                    : "",
                  gender: params.row.answeredForm
                    ? params.row.answeredForm.gender
                    : "",
                  height: params.row.answeredForm
                    ? params.row.answeredForm.height
                    : "",
                  weight: params.row.answeredForm
                    ? params.row.answeredForm.weight
                    : "",
                });
                setOpenAddLeadsModal(true);
              }}
            >
              Edit
            </MenuItem> */}
          </Menu>
        </div>
      ),
    },
  ];

  const onboardingColumns = [
    {
      field: "Name",
      headerName: "Name",
      flex: 1,
      minWidth: 200,
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Trainee" />,
      renderCell: (params) => (
        <FirstCell params={params} onClick={() => goToClient(params.row)} />
      ),
    },
    {
      field: "clientID",
      headerName: "Client ID",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Client ID" />,
      valueGetter: (params) => {
        return params.row?.clientID_LivEzy ?? "";
      },
      renderCell: (params) => (
        <Typography
          onClick={() => goToClient(params.row)}
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
            whiteSpace: "normal",
            width: "100%",
            textOverflow: "ellipsis",
            textAlign: "center",
          }}
        >
          {params.row.clientID_LivEzy ?? ""}
        </Typography>
      ),
    },
    {
      field: "ProgramName",
      headerName: "Program",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Program" />,
      renderCell: (params) => (
        <ProgramCell
          params={params}
          onEditClick={() => {
            setSelectedUser(params.row);
            setShowAddProgramModal(true);
          }}
          onProgramClick={() => {
            window.open(
              `${window.location.origin}/programs/${
                params?.row?.phasedProgramID ?? params?.row?.programID
              }`,
              "_blank"
            );
          }}
        />
      ),
    },
    {
      field: "Meal Name",
      headerName: "Meal",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Meal Schedule" />,
      renderCell: (params) => (
        <MealCell
          params={params}
          onEditClick={() => {
            setSelectedUser(params.row);
            setShowAddMealModal(true);
          }}
          onMealClick={() => {
            window.open(
              `${window.location.origin}/nutrition/3/${params.row?.mealPlanID}`,
              "_blank"
            );
          }}
        />
      ),
    },
    {
      field: "Membership",
      headerName: "Plan",
      flex: 1,
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Membership" />,
      renderCell: (params) => (
        <Typography
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
          }}
        >
          {params.row?.planName_LivEzy ?? "-"}
        </Typography>
      ),
    },
    {
      field: "Plan Duration",
      headerName: "Plan",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Plan Duration" />,
      renderCell: (params) => (
        <Typography
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
          }}
        >
          {params.row?.planDuration_LivEzy
            ? `${params.row?.planDuration_LivEzy} days`
            : "-"}
        </Typography>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Email" />,
      valueGetter: (params) => {
        return params.row?.Email ?? "";
      },
      renderCell: (params) => (
        <Typography
          onClick={() => goToClient(params.row)}
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
            whiteSpace: "normal",
            width: "100%",
            textOverflow: "ellipsis",
            textAlign: "center",
          }}
        >
          {params.row.Email ?? ""}
        </Typography>
      ),
    },
    {
      field: "Phone",
      headerName: "Phone",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Phone" />,
      valueGetter: (params) => {
        return params.row?.contactNumber ?? "";
      },
      renderCell: (params) => (
        <Typography
          onClick={() => goToClient(params.row)}
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
            whiteSpace: "normal",
            width: "100%",
            textOverflow: "ellipsis",
            textAlign: "center",
          }}
        >
          {params.row.contactNumber ?? ""}
        </Typography>
      ),
    },
    {
      field: "renewal",
      headerName: "Renewal",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Renewal" />,
      renderCell: (params) => (
        <Typography
          onClick={() => goToClient(params.row)}
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
          }}
        >
          {params.row.renewal_LivEzy === "true" ? "Yes" : "No"}
        </Typography>
      ),
    },
    {
      field: "referredBy_LivEzy",
      headerName: "Referred By",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Referred By" />,
      valueGetter: (params) => {
        return params.row?.referredBy_LivEzy ?? "";
      },
      renderCell: (params) => (
        <Typography
          onClick={() => goToClient(params.row)}
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
          }}
        >
          {params.row.referredBy_LivEzy ?? ""}
        </Typography>
      ),
    },
    {
      field: "buddyClientID_LivEzy",
      headerName: "Buddy ID",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Buddy ID" />,
      valueGetter: (params) => {
        return params.row?.buddyClientID_LivEzy ?? "";
      },
      renderCell: (params) => (
        <Typography
          onClick={() => goToClient(params.row)}
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
          }}
        >
          {params.row.buddyClientID_LivEzy ?? ""}
        </Typography>
      ),
    },
    {
      field: "dietrypref",
      headerName: "Dietary preference",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      valueGetter: (params) => {
        return params.row?.dietaryPreferences_LivEzy ?? "";
      },
      renderHeader: () => <HeaderCell label="Dietary preference" />,
      renderCell: (params) => (
        <Typography
          onClick={() => goToClient(params.row)}
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
          }}
        >
          {params.row?.dietaryPreferences_LivEzy ?? ""}
        </Typography>
      ),
    },
    {
      field: "age",
      headerName: "Age",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Age" />,
      valueGetter: (params) => {
        return params.row?.birthDate ? calculateAge(params.row?.birthDate) : "";
      },
      renderCell: (params) => (
        <Typography
          onClick={() => goToClient(params.row)}
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
          }}
        >
          {params.row?.birthDate ? calculateAge(params.row?.birthDate) : ""}
        </Typography>
      ),
    },
    {
      field: "gender",
      headerName: "gender",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Gender" />,
      valueGetter: (params) => {
        return params.row?.gender ?? "";
      },
      renderCell: (params) => (
        <Typography
          onClick={() => goToClient(params.row)}
          sx={{
            cursor: "pointer",
            fontSize: "13px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
            color: "#605E5C",
            fontWeight: 400,
          }}
        >
          {params.row.gender ?? ""}
        </Typography>
      ),
    },
    {
      field: "Status",
      headerName: "Status",
      flex: 1,
      minWidth: 200,
      sortable: false,
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => <HeaderCell label="Status" />,
      renderCell: (params) => (
        <StatusCell
          params={params}
          onboardingData={onboarding}
          showText={false}
          getTrainerDashboard={getTrainerDashboard}
          buddyData={buddyData}
        />
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      cellClassName: "super-app-theme--cell",
      headerClassName: "super-app-theme--header",
      renderHeader: () => (
        <HeaderCell label="Actions" style={{ textAlign: "center" }} />
      ),
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Questionaire key={params.row.UserUUID} userInfo={params.row} />
          {isAdmin && (
            <>
              <img
                src={MoreIcon}
                alt="more"
                onClick={(e) => handleClick(e, params?.row)}
                style={{ cursor: "pointer", padding: "10px" }}
              />
              <Menu
                id={`item-actions-${params?.row?.UserUUID}`}
                anchorEl={anchorEl}
                open={selectedItem === params?.row && Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  sx={{
                    fontSize: "14px",
                    fontFamily: "DM Sans",
                    fontWeight: 500,
                    lineHeight: "20px",
                  }}
                  onClick={() => {
                    console.log("asign: ", params?.row?.UserUUID);
                    setAssignAthleteToCoach(true);
                  }}
                >
                  Assign to Coach
                </MenuItem>
                <MenuItem
                  sx={{
                    fontSize: "14px",
                    fontFamily: "DM Sans",
                    fontWeight: 500,
                    lineHeight: "20px",
                  }}
                  onClick={() => {
                    triggerInvitationMail(params?.row?.UserUUID || "");
                    setEmailInvitationModalOpen(true);
                  }}
                >
                  Send invitation email
                </MenuItem>
              </Menu>
            </>
          )}
        </div>
      ),
    },
  ];

  const triggerInvitationMail = async (atheleteID) => {
    await dispatch(sendInvitationMail({ atheleteIDs: [atheleteID] }));
  };

  const formatDaysToWeekAndDay = (days) => {
    let weeks = Math.floor(days / 7);
    let remainingDays = days % 7;
    if (weeks === 0) return `${remainingDays} days`;
    if (weeks === 1) return `${weeks} week ${remainingDays} days`;
    return `${weeks} weeks ${remainingDays} days`;
  };

  const handleChange = (event, newValue) => {
    if (!newValue) return;
    // event.preventDefault();
    if (activeTab === ACTIVE) setActiveData(tempUserData?.active);
    if (activeTab === PENDING) setPendingData(tempUserData?.pending);
    if (activeTab === ARCHIVE) setArchiveData(tempUserData?.archive);
    if (activeTab === LEADS) setLeadsData(tempUserData?.leads);
    if (activeTab === ONBOARDING) setOnboarding(tempUserData?.onboarding);

    setActiveTab(newValue);
  };

  useEffect(() => {
    return () => {
      // toogle bubble on unmount
      // window?.$chatwoot?.toggleBubbleVisibility("hide");
    };
  }, []);

  const handleOptionClick = (option) => {
    if (selectedFilter === option) {
      setOnboarding(tempUserData?.onboarding);
      setSelectedFilter("");
      return;
    }
    // filter clients based on option selected of property onboardingStatus_LivEzy
    setSelectedFilter(option);
    // const newSearchedClients = originalClientsList.filter((client) => {
    //   return client?.onboardingStatus_LivEzy === option;
    // });

    if (option === "Renewal") {
      const newSearchedClients = tempUserData?.onboarding.filter((client) => {
        return client?.renewal_LivEzy === "true";
      });
      setOnboarding(newSearchedClients);
    } else if (option === "Delayed Onboarding") {
      const newSearchedClients = tempUserData?.onboarding.filter((client) => {
        let coachAssignedDate = new Date(client?.coachAssignedDate_LivEzy);
        // if assigned date is more than 3 days ago
        return moment().diff(coachAssignedDate, "days") > 3;
      });
      setOnboarding(newSearchedClients);
    } else {
      // filter clients based on option selected of property onboardingStatus_LivEzy
      const newSearchedClients = tempUserData?.onboarding.filter((client) => {
        return client?.onboardingStatus_LivEzy === option;
      });

      setOnboarding(newSearchedClients);
    }
    setIsFilterActive(true);
    handleClose();
  };

  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    setSelectedItem(item);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedItem(null);
  };

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleArchive = () => {
    // Handle archive action for the selected item
    setUserStatus();
    handleClose();
  };

  const history = useHistory();
  const handleMessage = async () => {
    // Handle message action for the selected item
    const channelId = await getChannelId(selectedItem?.UserUUID);
    history.push(`/chat?channelId=${channelId}`);
    handleClose();
  };

  const handleTabsDropdownClick = (event) => {
    event.stopPropagation();
    setTabsAnchorEl(event.currentTarget);
  };

  const handleTabsDropdownClose = (event) => {
    event.stopPropagation();
    setTabsAnchorEl(null);
  };

  const getTabsCount = (tab) => {
    switch (tab) {
      case ACTIVE:
        return activeData?.length;
      case PENDING:
        return pendingData?.length;
      case ARCHIVE:
        return archiveData?.length;
      case ONBOARDING:
        return onboarding?.length;
      case LEADS:
        return leadsData?.length;
      default:
        return 0;
    }
  };

  const requestSearch = (searchedVal) => {
    const searchValueLower = searchedVal.toLowerCase();

    const filterData = (data, fields) => {
      return data.filter((row) => {
        return fields.some((field) => {
          const value = field.split(".").reduce((o, key) => o && o[key], row);
          return value && value.toLowerCase().includes(searchValueLower);
        });
      });
    };

    const searchFields = {
      ACTIVE: isLivezy
        ? ["Name", "Email", "contactNumber", "clientID_LivEzy"]
        : ["Name"],
      ARCHIVE: ["Name"],
      PENDING: ["invitedUser.name", "invitedUser.email"],
      ONBOARDING: isLivezy
        ? ["Name", "Email", "contactNumber", "clientID_LivEzy"]
        : ["Name"],
      LEADS: ["name", "email"],
      PLANEXPIRED: ["Name", "Email", "contactNumber", "clientID_LivEzy"],
    };

    if (activeTab === ACTIVE) {
      setActiveData(filterData(tempUserData.active, searchFields.ACTIVE));
    } else if (activeTab === ARCHIVE) {
      setArchiveData(filterData(tempUserData.archive, searchFields.ARCHIVE));
    } else if (activeTab === PENDING) {
      setPendingData(filterData(tempUserData.pending, searchFields.PENDING));
    } else if (activeTab === ONBOARDING) {
      setOnboarding(
        filterData(tempUserData.onboarding, searchFields.ONBOARDING)
      );
    } else if (activeTab === LEADS) {
      setLeadsData(filterData(tempUserData.leads, searchFields.LEADS));
    } else if (activeTab === PLANEXPIRED) {
      setPlanExpiredData(
        filterData(tempUserData.planExpired, searchFields.PLANEXPIRED)
      );
    }
  };

  const cancelSearch = () => {
    if (activeTab === ACTIVE) setActiveData(tempUserData?.active);
    if (activeTab === PENDING) setPendingData(tempUserData?.pending);
    if (activeTab === ARCHIVE) setArchiveData(tempUserData?.archive);
    if (activeTab === ONBOARDING) setOnboarding(tempUserData?.onboarding);
    if (activeTab === LEADS) setLeadsData(tempUserData?.leads);
  };

  const copyToClipboard = async () => {
    try {
      const getCurrentURl = window.location.href;
      const coplyURL =
        getCurrentURl.split("/home")[0] + "/trainwithme/" + trainerUUID;
      await navigator.clipboard.writeText(coplyURL);
      linkCopySuccess();
    } catch (err) {
      console.error("Failed to copy link: ", err);
    }
  };

  const handleAddNewClick = () => {
    mixpanel.track("Trainer_AddClient");
    setOpenAddClientsModal(true);
  };

  const open = Boolean(filterAnchorEl);
  const id = open ? "simple-popover" : undefined;
  const toggleList = useSelector((state) => state.toggleCheckList.toggleValue);
  return (
    <Layout>
      {!isLivezy && (
        <AddClientModal
          inviteSuccess={inviteSuccess}
          open={openAddClientsModal}
          setOpen={setOpenAddClientsModal}
        />
      )}
      <AddLeads
        isOpen={openAddLeadsModal}
        setIsOpen={setOpenAddLeadsModal}
        addLeadSuccess={addLeadSuccess}
        isEditOpen={isEditOpen}
        setIsEditOpen={setIsEditOpen}
        leadEditedData={leadEditedData}
        setLeadEditedData={setLeadEditedData}
        leadsData={leadsData}
        setLeadsData={setLeadsData}
      />

      <ViewLeads
        data={leadsUserInfo}
        isOpen={openLeadsModal}
        setIsOpen={setOpenLeadsModal}
      />
      <AllModals
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        showAddProgramModal={showAddProgramModal}
        setShowAddProgramModal={setShowAddProgramModal}
        showAddMealModal={showAddMealModal}
        setShowAddMealModal={setShowAddMealModal}
        getTrainerDashboard={getTrainerDashboard}
      />
      <ViewLeadsFormModal
        data={leadsData?.find((data) => data?.id == leadId)}
        isOpen={isViewLeadModal}
        handleClose={() => setIsViewLeadModal(false)}
      />

      {/* {isLivezy && (
        <AddClient
          isOpen={openAddClientsModal}
          setIsOpen={setOpenAddClientsModal}
          inviteSuccess={inviteSuccess}
        />
      )} */}

      {/* <Sidebar />
      <TopBar setOpenCopilot={setCopilotOpen} /> */}
      <Box sx={{ display: "flex", width: "100%", position: "relative" }}>
        <Box
          sx={{
            width: "100%",
            // marginTop: "12px",
            overflow: "scroll",
            backgroundColor: "#ffffff",
            borderRadius: "20px",
            marginRight: copilotOpen ? "7px" : "0px",
            "& .super-app-theme--cell": {
              outline: "none !important",
            },

            "& .super-app-theme--header": {
              outline: "none",
              fontSize: "13px",
              fontWeight: 500,
              lineHeight: "20px",
              color: "#333333",
              fontFamily: "DM Sans",
              position: "relative",
            },
          }}
          id="dashboard-box"
        >
          {!isLivezy && (
            <ChecklistModal
              toggled={toggleList}
              copilotOpen={copilotOpen}
              handleAddNewClick={handleAddNewClick}
              data={data}
            />
          )}
          <TabContext value={activeTab}>
            {copilotOpen ? (
              <Tabs
                sx={{
                  paddingLeft: "12px",
                  position: "relative",
                  paddingTop: "9px",
                }}
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab
                  label={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <TabHeader
                        title={activeTab}
                        count={getTabsCount(activeTab)}
                        activeTab={activeTab}
                      />
                      <img
                        src={DownArrowIcon}
                        style={{
                          height: "24px",
                          width: "24px",
                          marginLeft: "9px",
                        }}
                        onClick={handleTabsDropdownClick}
                        alt=""
                      />
                      <Popover
                        id="user-actions"
                        anchorEl={tabsAnchorEl}
                        open={Boolean(tabsAnchorEl)}
                        onClose={handleTabsDropdownClose}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        {activeTab != ACTIVE && (
                          <MenuItem
                            onClick={(e) => {
                              handleTabsDropdownClose(e);
                              setActiveTab(ACTIVE);
                            }}
                            sx={{
                              fontSize: "14px",
                              fontFamily: "DM Sans",
                              fontWeight: 500,
                              lineHeight: "20px",
                            }}
                          >
                            Active
                          </MenuItem>
                        )}
                        {!isLivezy && activeTab != PENDING && (
                          <MenuItem
                            onClick={(e) => {
                              handleTabsDropdownClose(e);
                              setActiveTab(PENDING);
                            }}
                            sx={{
                              fontSize: "14px",
                              fontFamily: "DM Sans",
                              fontWeight: 500,
                              lineHeight: "20px",
                            }}
                          >
                            Pending
                          </MenuItem>
                        )}
                        {activeTab != ARCHIVE && (
                          <MenuItem
                            onClick={(e) => {
                              handleTabsDropdownClose(e);
                              /* The above code is defining a function called setActiveTab in
                              JavaScript. */
                              setActiveTab(ARCHIVE);
                            }}
                            sx={{
                              fontSize: "14px",
                              fontFamily: "DM Sans",
                              fontWeight: 500,
                              lineHeight: "20px",
                            }}
                          >
                            Archive
                          </MenuItem>
                        )}
                        {isLivezy && activeTab != ONBOARDING && (
                          <MenuItem
                            onClick={(e) => {
                              handleTabsDropdownClose(e);
                              setActiveTab(ONBOARDING);
                            }}
                            sx={{
                              fontSize: "14px",
                              fontFamily: "DM Sans",
                              fontWeight: 500,
                              lineHeight: "20px",
                            }}
                          >
                            Onboarding
                          </MenuItem>
                        )}
                      </Popover>
                    </Box>
                  }
                  value={ACTIVE}
                />
                <div className="flex items-center justify-self-end ml-auto">
                  <>
                    {!isLivezy && (
                      <button
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          background:
                            "linear-gradient(to right, #464FEB, #8330E9)",
                          padding: "16px 10px",
                          height: "32px",
                          lineHeight: "34px",
                          borderRadius: "78px",
                          color: "#fff",
                          marginLeft: "auto",
                          marginTop: "auto",
                          marginBottom: "auto",
                          fontFamily: "DM Sans",
                        }}
                        onClick={() => {
                          console.log("clicked");
                          setOpenAddClientsModal(true);
                        }}
                      >
                        <img
                          src={AddIcon}
                          style={{
                            height: "16px",
                            width: "16px",
                            marginRight: "4px",
                            zIndex: 999,
                          }}
                          onClick={() => {
                            console.log("clicked");
                            mixpanel.track("Trainer_AddClient");
                            setOpenAddClientsModal(true);
                          }}
                        />
                        Add New
                      </button>
                    )}
                    <div
                      style={{
                        width: "1px",
                        height: "32px",
                        backgroundColor: "#E0E0E0",
                        marginTop: "auto",
                        marginBottom: "auto",
                        marginLeft: "8px",
                      }}
                    />
                  </>

                  <SearchBar
                    request={(searchVal) => {
                      requestSearch(searchVal);
                    }}
                    cancel={() => cancelSearch()}
                    height={"32px"}
                    width={"193px"}
                    bgcol={"#F2F2F2"}
                    fontSize={"13px"}
                  />
                  {isLivezy && activeTab === ONBOARDING && (
                    <>
                      <div
                        style={{
                          width: "1px",
                          height: "32px",
                          backgroundColor: "#E0E0E0",
                          marginTop: "auto",
                          marginBottom: "auto",
                          marginLeft: "8px",
                        }}
                      />
                      <img
                        src={isFilterActive ? ActiveFilterIcon : FilterIcon}
                        className="cursor-pointer h-[30px] w-[30px]"
                        onClick={handleFilterClick}
                      />
                    </>
                  )}
                </div>

                {/* <div
                  style={{
                    backgroundImage:
                      "linear-gradient(to right, #464FEB, #8330E9)",
                    height: "34px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    zIndex: 0,
                    borderRadius: "78px",
                    overflow: "hidden",
                    marginRight: "31px",
                  }}
                >
                  <button
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "16px 10px",
                      height: "32px",
                      lineHeight: "34px",
                      borderRadius: "78px",
                      zIndex: 1,
                      margin: "1px",
                      backgroundColor: "#ffffff",
                    }}
                  >
                    <img
                      style={{
                        height: "16px",
                        width: "16px",
                        marginRight: "4px",
                      }}
                      src={FilterIcon}
                    />
                    <Typography
                      className="gradient-text"
                      sx={{ fontSize: "13px" }}
                    >
                      {" "}
                      Filter by{" "}
                    </Typography>
                    <img
                      style={{
                        height: "12px",
                        width: "12px",
                        marginLeft: "4px",
                      }}
                      src={DropdownIcon}
                    />
                  </button>
                </div> */}
              </Tabs>
            ) : (
              <Tabs
                id="dashboard-top-bar"
                sx={{
                  paddingLeft: "12px",
                  position: "relative",
                  paddingTop: "9px",
                }}
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                {!(isLivezy && isAdmin) && (
                  <Tab
                    label={
                      <TabHeader
                        title={ACTIVE}
                        count={activeData?.length || 0}
                        activeTab={activeTab}
                      />
                    }
                    value={ACTIVE}
                  />
                )}
                {!isLivezy && (
                  <Tab
                    label={
                      <TabHeader
                        title={PENDING}
                        count={pendingData?.length || 0}
                        activeTab={activeTab}
                      />
                    }
                    value={PENDING}
                  />
                )}
                {!(isLivezy && isAdmin) && (
                  <Tab
                    label={
                      <TabHeader
                        title={ARCHIVE}
                        count={archiveData?.length || 0}
                        activeTab={activeTab}
                      />
                    }
                    value={ARCHIVE}
                  />
                )}
                {isLivezy && !isAdmin && (
                  <Tab
                    label={
                      <TabHeader
                        title={PLANEXPIRED}
                        count={planExpiredData?.length || 0}
                        activeTab={activeTab}
                      />
                    }
                    value={PLANEXPIRED}
                  />
                )}
                {isLivezy && (
                  <Tab
                    label={
                      <TabHeader
                        title={ONBOARDING}
                        count={onboarding?.length || 0}
                        activeTab={activeTab}
                      />
                    }
                    value={ONBOARDING}
                  />
                )}
                {!isLivezy && (
                  <Tab
                    label={
                      <TabHeader
                        title={LEADS}
                        count={leadsData?.length || 0}
                        activeTab={activeTab}
                      />
                    }
                    value={LEADS}
                  />
                )}
                <div className="flex items-center justify-self-end ml-auto gap-[10px]">
                  <>
                    {activeTab === LEADS && (
                      <button
                        style={{
                          borderWidth: 2,
                          borderRadius: 100,
                          height: "32px",
                          width: "100px",
                          alignSelf: "center",
                          fontSize: "13px",
                        }}
                        className="button-new-outlined"
                        onClick={() => {
                          copyToClipboard();
                        }}
                        id="dashboard-addnew-button"
                      >
                        <ContentCopyRounded
                          style={{
                            height: "16px",
                            width: "16px",
                            marginRight: "4px",
                            fontSize: "13px",
                            zIndex: 999,
                            color: "#8330e9",
                          }}
                        />
                        {/* <img
                          src={CopyIcon}
                          style={{
                            height: "16px",
                            width: "16px",
                            marginRight: "4px",
                            zIndex: 999,
                          }}
                          onClick={() => {
                            copyToClipboard();
                          }}
                        /> */}
                        Copy Link
                      </button>
                    )}
                    {activeTab === LEADS ? (
                      <>
                        {/* <button
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            background:
                              "linear-gradient(to right, #464FEB, #8330E9)",
                            padding: "16px 10px",
                            height: "32px",
                            borderRadius: "78px",
                            color: "#fff",
                            marginLeft: "auto",
                            marginTop: "auto",
                            marginBottom: "auto",
                            fontFamily: "DM Sans",
                            fontWeight: 400,
                            fontSize: "13px",
                            lineHeight: "22px",
                          }}
                          onClick={() => {
                            console.log("clicked");
                            setOpenAddLeadsModal(true);
                          }}
                          id="dashboard-addnew-button"
                        >
                          <img
                            src={AddIcon}
                            style={{
                              height: "16px",
                              width: "16px",
                              marginRight: "4px",
                              zIndex: 999,
                            }}
                            onClick={() => {
                              console.log("clicked");
                              setOpenAddLeadsModal(true);
                            }}
                          />
                          Add Lead
                        </button> */}
                        <button
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            background:
                              "linear-gradient(to right, #464FEB, #8330E9)",
                            padding: "16px 10px",
                            height: "32px",
                            borderRadius: "78px",
                            color: "#fff",
                            marginLeft: "auto",
                            marginTop: "auto",
                            marginBottom: "auto",
                            fontFamily: "DM Sans",
                            fontWeight: 400,
                            fontSize: "13px",
                            lineHeight: "22px",
                          }}
                          onClick={() =>
                            history.push("/leads/edit/" + trainerUUID)
                          }
                        >
                          <img
                            src={EditIcon}
                            style={{
                              height: "16px",
                              width: "16px",
                              marginRight: "4px",
                              zIndex: 999,
                            }}
                            // onClick={() =>
                            //   history.push(
                            //     "/leads/edit"
                            //   )
                            // }
                            // setOpenAddLeadsModal(true);
                          />
                          Edit Form
                        </button>
                      </>
                    ) : (
                      !isLivezy && (
                        <button
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            background:
                              "linear-gradient(to right, #464FEB, #8330E9)",
                            padding: "16px 10px",
                            height: "32px",
                            borderRadius: "78px",
                            color: "#fff",
                            marginLeft: "auto",
                            marginTop: "auto",
                            marginBottom: "auto",
                            fontFamily: "DM Sans",
                            fontWeight: 400,
                            fontSize: "13px",
                            lineHeight: "22px",
                          }}
                          onClick={() => {
                            console.log("clicked");
                            setOpenAddClientsModal(true);
                          }}
                          id="dashboard-addnew-button"
                        >
                          <img
                            src={AddIcon}
                            style={{
                              height: "16px",
                              width: "16px",
                              marginRight: "4px",
                              zIndex: 999,
                            }}
                            onClick={() => {
                              console.log("clicked");
                              setOpenAddClientsModal(true);
                            }}
                          />
                          Add Client
                        </button>
                      )
                    )}
                    <div
                      style={{
                        width: "1px",
                        height: "32px",
                        backgroundColor: "#E0E0E0",
                        marginTop: "auto",
                        marginBottom: "auto",
                        marginLeft: "8px",
                      }}
                    />
                  </>

                  <SearchBar
                    request={(searchVal) => {
                      requestSearch(searchVal);
                    }}
                    cancel={() => cancelSearch()}
                    placeholder={"Search Clients"}
                    height={"32px"}
                    width={"193px"}
                    bgcol={"#F2F2F2"}
                    fontSize={"13px"}
                    id="dashboard-search"
                  />

                  {isLivezy && activeTab === ONBOARDING && (
                    <>
                      <div
                        style={{
                          width: "1px",
                          height: "32px",
                          backgroundColor: "#E0E0E0",
                          marginTop: "auto",
                          marginBottom: "auto",
                          marginLeft: "8px",
                          marginRight: "8px",
                        }}
                      />
                      <img
                        src={isFilterActive ? ActiveFilterIcon : FilterIcon}
                        className="cursor-pointer h-[30px] w-[30px] mr-2"
                        onClick={handleFilterClick}
                        alt=""
                      />
                    </>
                  )}
                </div>
                {/* <div
                  style={{
                    backgroundImage:
                      "linear-gradient(to right, #464FEB, #8330E9)",
                    height: "34px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    zIndex: 0,
                    borderRadius: "78px",
                    overflow: "hidden",
                    marginLeft: "10px",
                    marginRight: "31px",
                  }}
                >
                  <button
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "16px 10px",
                      height: "32px",
                      lineHeight: "34px",
                      borderRadius: "78px",
                      zIndex: 1,
                      margin: "1px",
                      backgroundColor: "#ffffff",
                    }}
                  >
                    <img
                      style={{
                        height: "16px",
                        width: "16px",
                        marginRight: "4px",
                      }}
                      src={FilterIcon}
                    />
                    <Typography
                      className="gradient-text"
                      sx={{ fontSize: "13px" }}
                    >
                      {" "}
                      Filter by{" "}
                    </Typography>
                    <img
                      style={{
                        height: "12px",
                        width: "12px",
                        marginLeft: "4px",
                      }}
                      src={DropdownIcon}
                    />
                  </button>
                </div> */}
              </Tabs>
            )}

            <TabPanel
              sx={{
                padding: "0px",
              }}
              value={ACTIVE}
            >
              <ShowTable
                getRowId={(row) => row.UserUUID}
                data={activeData}
                columns={isLivezy ? LivEzyColumns : columns}
                loading={loading}
                key={ACTIVE}
              />
            </TabPanel>
            <TabPanel
              sx={{
                padding: "0px",
              }}
              value={PENDING}
            >
              <ShowTable
                getRowId={(row) => row.invitedUserID}
                data={pendingData}
                columns={pendingColumns}
                loading={loading}
                key={PENDING}
              />
            </TabPanel>
            <TabPanel
              sx={{
                padding: "0px",
              }}
              value={ARCHIVE}
            >
              <ShowTable
                getRowId={(row) => row.UserUUID}
                data={archiveData}
                columns={columns}
                loading={loading}
                key={ARCHIVE}
              />
            </TabPanel>
            <TabPanel
              sx={{
                padding: "0px",
              }}
              value={LEADS}
            >
              <ShowTable
                getRowId={(row) => row.id}
                data={leadsData}
                columns={leadsColumns}
                loading={loading}
                onCellClick={(cell) => {
                  let data = leadsData?.find((data) => data?.id == cell.row.id);
                  if (data?.answeredForm?.length) {
                    setIsViewLeadModal(true);
                  } else {
                    toast(
                      "This lead does not have any further data",
                      "warning",
                      false,
                      null,
                      2000
                    );
                  }
                  setLeadId(cell.row.id);
                }}
                key={LEADS}
              />
            </TabPanel>
            <TabPanel
              sx={{
                padding: "0px",
              }}
              value={PLANEXPIRED}
            >
              <ShowTable
                getRowId={(row) => row.UserUUID}
                data={planExpiredData}
                columns={LivEzyColumns}
                loading={loading}
                key={PLANEXPIRED}
              />
            </TabPanel>
            <TabPanel
              sx={{
                padding: "0px",
              }}
              value={ONBOARDING}
            >
              <ShowTable
                getRowId={(row) => row.UserUUID}
                data={onboarding}
                columns={onboardingColumns}
                loading={loading}
                key={ONBOARDING}
              />
            </TabPanel>
          </TabContext>
        </Box>
        {copilotOpen && <Copilot open={copilotOpen} setOpen={setCopilotOpen} />}

        <Popover
          id={id}
          open={open}
          anchorEl={filterAnchorEl}
          onClose={handleFilterClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          {options?.map((option) => (
            <MenuItem
              sx={{
                fontSize: "12px",
                fontFamily: "DM Sans",
                fontWeight: 500,
                background:
                  selectedFilter === option &&
                  "linear-gradient(to right, rgba(70, 79, 235, 0.4), rgba(131, 48, 233, 0.4))",
              }}
              key={option}
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </MenuItem>
          ))}
          <MenuItem
            sx={{
              fontSize: "12px",
              fontFamily: "DM Sans",
              fontWeight: 500,
              color: "#e65e5e",
            }}
            key={"clear"}
            onClick={() => {
              setIsFilterActive(false);
              handleFilterClose();
              setOnboarding(tempUserData?.onboarding);
              setSelectedFilter("");
            }}
          >
            Clear filter
          </MenuItem>
        </Popover>
      </Box>
      <Box
        sx={{
          position: "fixed",
          bottom: 100,
          left: 0,
          right: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {showAlert && (
          <Alert severity="success">Invite sent successfully!</Alert>
        )}
        {copyLinkAlert && <Alert severity="success">Link copied!</Alert>}
      </Box>

      {assignAthleteToCoach && (
        <AssignAthleteToCoach
          isOpen={assignAthleteToCoach}
          setIsOpen={setAssignAthleteToCoach}
          selectedUser={selectedItem}
          getTrainerDashboard={getTrainerDashboard}
        />
      )}

      {!copilotOpen && <ContactUsButton />}
    </Layout>
  );
}

const TabHeader = ({ title, count, activeTab }) => {
  let isActiveTab = activeTab === title;

  return (
    <Box
      sx={{
        display: "flex",
        textTransform: "none",
        color: isActiveTab ? "#242424" : "#424242",
        position: "relative",
        textAlign: "center",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          fontSize: "14px",
          fontFamily: "DM Sans",
          fontWeight: 500,
          lineHeight: "20px",
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          padding: "0px 4px",
          fontSize: "14px",
          fontFamily: "DM Sans",
          fontWeight: 400,
          lineHeight: "20px",
          backgroundColor: isActiveTab
            ? "rgba(131, 48, 233, 0.20)"
            : "rgba(66, 66, 66, 0.10)",
          marginLeft: "4px",
          borderRadius: "4px",
        }}
      >
        {count}
      </Typography>
      {isActiveTab && (
        <div
          style={{
            height: "3px",
            width: "100%",
            position: "absolute",
            bottom: -10,
            borderRadius: "4px",
            background: "linear-gradient(90deg, #464FEB, #8330E9)",
          }}
        />
      )}
    </Box>
  );
};

const ShowTable = memo(({ data, columns, loading, getRowId, onCellClick }) => {
  return (
    <>
      <DataGrid
        disableColumnMenu
        getRowId={getRowId}
        rows={data}
        columns={columns}
        onCellClick={onCellClick}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        loading={loading}
        slots={{
          loadingOverlay: SpurfitCircularProgress,
          baseCheckbox: (props) => (
            <Checkbox
              icon={<CircleUnchecked />}
              checkedIcon={<CircleCheckedFilled />}
              indeterminateIcon={<RemoveCircleIcon />}
              {...props}
            />
          ),
        }}
        pageSizeOptions={[10]}
        checkboxSelection
        disableRowSelectionOnClick
        sx={{
          marginTop: "10px",
          minHeight: "100px",
          border: "none",
          ".MuiDataGrid-columnSeparator": {
            display: "none",
          },
          "&.MuiDataGrid-root": {
            border: "none",
          },
          "&.MuiDataGrid-columnHeaderCheckbox": {
            border: "none",
          },
          "& .MuiTablePagination-root:last-child": {
            paddingRight: "68px",
            position: "absolute",
            left: "1px",
          },
        }}
      />
    </>
  );
});

const options = [
  "Questionnaire filled",
  "Physique pictures received",
  "Plan sent",
  "Intro call done",
  "Plan start",
  "Delayed Onboarding",
  "Renewal",
];
