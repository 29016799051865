import {
  Autocomplete,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import axios from "axios";
import { useContext, useEffect, useState } from "react";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CustomAlertContext } from "../../../App";
import colors from "../../../common/theme/appModern/colors";
import {
  TRAINING_TYPES,
  getTrainingType,
} from "../../../common/trainingTypeHelper";
import Input from "../../../components/InputComponents/Input";
import TextArea from "../../../components/InputComponents/TextArea";
const AddNewWorkoutModal = ({
  isOpen,
  setIsOpen,
  isUpdate,
  isProgramsFlow,
  isEdit,
  ...props
}) => {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const [workoutCategories, setWorkoutCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [title, setTitle] = useState(isUpdate ? props?.workoutInfo?.name : "");
  const [type, setType] = useState("");
  const [rounds, setRounds] = useState(1);
  const [hour, setHour] = useState("");
  const [minute, setMinute] = useState("");
  const [second, setSecond] = useState("");
  const [instruction, setInstruction] = useState(
    isUpdate ? props?.workoutInfo?.description : ""
  );
  const [athelete, setAthelete] = useState("");
  const [bodyPart, setBodyPart] = useState("");
  const [equipments, setEquipments] = useState(["Select Equipments"]);
  const [loading, setLoading] = useState(false);
  const { displayAlert } = useContext(CustomAlertContext);
  const isSNC = getTrainingType() === TRAINING_TYPES.SANDC;
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState(second);
  const [workoutInputType, setWorkoutInputType] = useState("structured");

  const [enduranceInputType, setEnduranceInputType] = useState("run");
  const [enduranceInputVisible, setEnduranceInputVisible] = useState(false);

  useEffect(() => {
    console.log("workout info ha kya", props?.workoutinfo);
    const trainerUUIDKey = JSON.parse(
      localStorage.getItem("trainerCredentials")
    );
    async function fetchCategories() {
      setLoading(true);
      await axios({
        method: "get",
        url: `${APIURL}CF/api/CoachWeb/Get/getAllWorkoutCategories`,
        params: {
          coachID: "wAVtiEgqGElN",
        },
      })
        .then(async (res) => {
          setWorkoutCategories(res.data.result);
          setLoading(false);
        })
        .catch((err) => {});
    }

    let cat = [];
    props?.workoutInfo?.workoutCategory?.map((item) => {
      cat.push(item.name);
    });
    setCategories(cat);
    setTitle(props?.workoutInfo?.name);
    setType(props?.workoutInfo?.workoutType);
    setRounds(props?.workoutInfo?.numRounds);
    setHour(props?.hour);
    setMinute(props?.minute);
    setSecond(props?.second);
    setInstruction(props?.workoutInfo?.description);
    setAthelete(props?.workoutInfo?.instructions);

    let tags = props?.workoutInfo?.tag;
    if (tags) {
      tags = tags.split(",");
      setSelectedTags(tags);
    }

    console.log(
      "workoutInfo:",
      props?.workoutInfo,
      props?.workoutInfo?.numRounds
    );
  }, []);

  const handleType = (event) => {
    setType(event.target.value);
  };

  const handleInstruction = (event) => {
    setInstruction(event.target.value);
  };

  const handleEnduranceInput = (event) => {
    setEnduranceInputType(event.target.value);
  };

  const saveWorkOutDetails = () => {
    let isCfwodentry =
      JSON.parse(localStorage.getItem("trainerCredentials"))?.email ===
      "cfwodentry@spur.fit";

    if (title == "") {
      displayAlert({ message: "Workout title is mandatory", type: "error" });
      return false;
    }

    if (!isSNC && type == "") {
      displayAlert({ message: "Workout type is mandatory", type: "error" });
      return false;
    }

    let trainerUUIDKey = JSON.parse(localStorage.getItem("trainerCredentials"));

    let cat = [];
    workoutCategories.map((item) => {
      if (categories.includes(item.name)) {
        let obj = {};
        obj["id"] = item.id;
        cat.push(obj);
      }
    });

    let workoutInfo = {};
    workoutInfo["name"] = title;
    workoutInfo["numRounds"] = parseInt(rounds) || 1;
    workoutInfo["runTime"] = convertToSeconds(hour, minute, second);
    workoutInfo["description"] = instruction;
    workoutInfo["instructions"] = athelete;
    workoutInfo["coachID"] = trainerUUIDKey?.password;
    workoutInfo["workoutType"] = type;
    workoutInfo["workoutCategory"] = cat;
    workoutInfo["tag"] =
      selectedTags.length > 0 ? selectedTags.join(",") : undefined;
    if (isCfwodentry) {
      workoutInfo["bodyPart"] = bodyPart;
      workoutInfo["equipment"] = equipments
        .filter((value) => value !== "Select Equipments")
        .join(",");
    }

    if (workoutInputType === "endurance") {
      workoutInfo["workoutKind"] = enduranceInputType;
    }
    if (isEdit) {
      console.log("isEdit: ", isEdit);
      setIsOpen(false);
      props?.setWorkoutInfo(workoutInfo);
    } else {
      //   history.push("/workoutBuilder", {
      //     workoutInfo: workoutInfo,
      //     inputType: inputType,
      //     isStructured: inputType === "structured",
      //     isProgramsFlow,
      //     ...props?.data,
      //   });
      console.log("ti", title, instruction);
      props?.setShowWorkoutBuilderModal(true);
      props?.setWorkoutInfo({
        workoutInfo: workoutInfo,
        isNewWorkout: true,
        inputType: workoutInputType,
        isStructured: workoutInputType === "structured",
        isProgramsFlow,
        ...props?.data,
      });
      setIsOpen(false);
    }
  };

  function convertToSeconds(hours, minutes, seconds) {
    hours = Number(hours) || 0;
    minutes = Number(minutes) || 0;
    seconds = Number(seconds) || 0;
    return hours * 60 * 60 + minutes * 60 + seconds;
  }

  const handleDeleteCategory = (category) => {
    const updatedCategories = categories.filter((c) => c !== category);
    setCategories(updatedCategories);
  };

  const history = useHistory();

  const bodyPartsList = [
    { label: "Full Body", value: "Full Body" },
    { label: "Upper", value: "Upper" },
    { label: "Lower", value: "Lower" },
    { label: "Core", value: "Core" },
  ];

  const equipmentList = [
    { label: "Kettlebell", value: "Kettlebell" },
    { label: "Dumbbell", value: "Dumbbell" },
    { label: "Jumprope", value: "Jumprope" },
    { label: "Barbell", value: "Barbell" },
    { label: "Rower", value: "Rower" },
    { label: "Pull-up bar", value: "Pull-up bar" },
    { label: "Rings", value: "Rings" },
    { label: "Medicine Ball", value: "Medicine Ball" },
    { label: "Running Area", value: "Running Area" },
    { label: "Plyo Box", value: "Plyo Box" },
    { label: "Air Bike", value: "Air Bike" },
    { label: "Rope", value: "Rope" },
    { label: "Bench", value: "Bench" },
    { label: "GHD machine", value: "GHD machine" },
    { label: "Bike", value: "Bike" },
    { label: "Dip Bar", value: "Dip Bar" },
    { label: "Abmat", value: "Abmat" },
  ];
  return (
    <Dialog
      open={isOpen}
      sx={{
        backdropFilter: "blur(3px)",
        backgroundColor: isProgramsFlow ? "#fff" : "transparent",
      }}
      PaperProps={{
        sx: {
          minWidth: "320px",
          borderRadius: "20px",
        },
      }}
    >
      <DialogContent sx={{ padding: 0 }}>
        <div className="add-program-modal-head">
          <p className="add-program-modal-head-title">
            {isUpdate ? "Update Info" : "Create Workout"}
          </p>
          <div
            style={{
              cursor: "pointer",
            }}
            onClick={() => setIsOpen(false)}
          >
            <CloseRoundedIcon />
          </div>
        </div>
        <div className="add-program-modal-body">
          <div
            className="full-width-container"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            {!isSNC && (
              <Box>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    // marginTop: "34px",
                  }}
                  textAlign={"center"}
                >
                  Select categories
                </Typography>
                <Typography
                  sx={{ fontSize: "10px", fontWeight: 400 }}
                  textAlign={"center"}
                >
                  You can select multiple
                </Typography>
                <Box
                  className="flex flex-wrap items-center justify-center"
                  style={{ marginTop: "22px", gap: "8px" }}
                >
                  {workoutCategories.map((category) => (
                    <Chip
                      onClick={() => {
                        if (categories.includes(category.name)) {
                          handleDeleteCategory(category.name);
                        } else {
                          setCategories([...categories, category.name]);
                        }
                      }}
                      label={category.name}
                      variant="outlined"
                      sx={{
                        height: "28px",
                        fontWeight: "500",
                        fontSize: "12px",
                      }}
                      style={{
                        background: categories?.includes(category.name)
                          ? "#8330E9"
                          : "#fff",
                        color: categories?.includes(category.name)
                          ? "#fff"
                          : colors.blueMain,
                      }}
                    />
                  ))}
                </Box>
              </Box>
            )}
            {isUpdate !== true && (
              <>
                {true ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      marginY: 2,
                    }}
                  >
                    <Box
                      component="legend"
                      sx={{ width: "100%" }}
                      style={{ opacity: "0.5", color: colors.blueMain }}
                    >
                      Workout Type:
                    </Box>
                    <Select
                      value={workoutInputType}
                      onChange={(e) => {
                        setWorkoutInputType(e.target.value);
                        if (e.target.value === "endurance") {
                          setEnduranceInputVisible(true);
                        } else {
                          setEnduranceInputVisible(false);
                        }
                      }}
                      displayEmpty
                      sx={{
                        width: "100%",
                        height: "40px",
                        borderRadius: "12px",
                        marginLeft: "10px",
                      }}
                    >
                      <MenuItem value="structured">Structured Workout</MenuItem>
                      <MenuItem value="video">Follow Along with Video</MenuItem>
                      <MenuItem value="endurance">Endurance Workout</MenuItem>
                    </Select>
                  </Box>
                ) : (
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={(e) => {
                      setWorkoutInputType(e.target.value);
                    }}
                    sx={{
                      overflowX: "scroll",
                      flexWrap: "nowrap",
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <FormControlLabel
                      sx={{
                        minWidth: "80px",
                        "& .MuiFormControlLabel-label": {
                          color: "#424242",
                          fontFamily: "DM Sans",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight:
                            workoutInputType === "structured" ? 500 : 400,
                          lineHeight: "21px",
                        },
                      }}
                      value="structured"
                      checked={workoutInputType === "structured"}
                      control={<Radio color="royalBlue" />}
                      label="Structured Workout"
                    />

                    <FormControlLabel
                      sx={{
                        minWidth: "100px",
                        "& .MuiFormControlLabel-label": {
                          color: "#424242",
                          fontFamily: "DM Sans",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: workoutInputType === "video" ? 500 : 400,
                          lineHeight: "21px",
                        },
                      }}
                      value="video"
                      checked={workoutInputType === "video"}
                      control={<Radio color="royalBlue" />}
                      label="Follow Along With Video"
                    />
                  </RadioGroup>
                )}
              </>
            )}

            {enduranceInputVisible && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  marginY: 2,
                }}
              >
                <Box
                  component="legend"
                  sx={{ width: "100%", opacity: "0.5", color: colors.blueMain }}
                >
                  Endurance Activity:
                </Box>
                <Select
                  value={enduranceInputType}
                  onChange={handleEnduranceInput}
                  variant="outlined"
                  displayEmpty
                  sx={{
                    width: "100%",
                    height: "40px",
                    borderRadius: "12px",
                    marginLeft: "10px",
                  }}
                >
                  <MenuItem value="run">Run</MenuItem>
                  <MenuItem value="bike">Bike</MenuItem>
                  {/* <MenuItem value="swim">Pool Swimming</MenuItem> */}
                </Select>
              </Box>
            )}

            <Box className={"flex justify-between items-end"}>
              <Input
                label={"Workout Name *"}
                value={title}
                onChange={(e) => {
                  console.log("onChange", e.target.value);
                  setTitle(e.target.value);
                }}
                placeholder={"Workout Name"}
                containerStyle={{ width: isSNC ? "100%" : "48%" }}
              />

              {!isSNC && (
                <div
                  style={{
                    width: "48%",
                  }}
                  className="input-container"
                >
                  <label htmlFor={"workoutType"}>Workout Type*</label>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={(event) => handleType(event)}
                    aria-placeholder={"Workout type *"}
                    style={{
                      background: "#fff",
                      width: "100%",
                      height: "48px",
                      borderRadius: "12px",
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          height: 250,
                          width: 250,
                        },
                      },
                    }}
                    sx={{
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "1.5px solid #4339f2",
                        boxShadow: "0px 0px 0px 3px rgba(67, 57, 242, 0.25)",
                      },
                    }}
                    defaultValue={"default"}
                    value={type}
                  >
                    <MenuItem
                      disabled
                      style={{ width: "100%", fontSize: "14px" }}
                      value="default"
                    >
                      <span style={{ opacity: "0.5", color: colors.blueMain }}>
                        Workout type*
                      </span>
                    </MenuItem>
                    <MenuItem
                      style={{ width: "100%", fontSize: "14px" }}
                      value="AMRAP"
                    >
                      As Many Rounds as Possible
                    </MenuItem>
                    <MenuItem
                      style={{ width: "100%", fontSize: "14px" }}
                      value="EMOM"
                    >
                      Every Minute on Minute
                    </MenuItem>
                    <MenuItem
                      style={{ width: "100%", fontSize: "14px" }}
                      value="RFT"
                    >
                      Rounds for Time
                    </MenuItem>
                    <MenuItem
                      style={{ width: "100%", fontSize: "14px" }}
                      value="QUALITY"
                    >
                      For Quality
                    </MenuItem>
                    <MenuItem
                      style={{ width: "100%", fontSize: "14px" }}
                      value="LOAD"
                    >
                      Load
                    </MenuItem>
                    <MenuItem
                      style={{ width: "100%", fontSize: "14px" }}
                      value="MULTILOAD"
                    >
                      Multi-Load
                    </MenuItem>
                    <MenuItem
                      style={{ width: "100%", fontSize: "14px" }}
                      value="NOSCORE"
                    >
                      No Score
                    </MenuItem>
                    <MenuItem
                      style={{ width: "100%", fontSize: "14px" }}
                      value="TABATA"
                    >
                      TABATA
                    </MenuItem>
                  </Select>
                </div>
              )}
            </Box>

            {JSON.parse(
              localStorage.getItem("trainerCredentials")
            )?.email?.toLowerCase() === "cfwodentry@spur.fit" && (
              <Box
                style={{ marginTop: "42px" }}
                className="flex justify-between items-end"
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={(event) => setBodyPart(event.target.value)}
                  aria-placeholder={"Workout type *"}
                  style={{
                    background: "#fff",
                    width: "48%",
                    height: "40px",
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        // height: 250,
                        // width: 250,
                      },
                    },
                  }}
                  defaultValue={"default"}
                >
                  <MenuItem
                    disabled
                    style={{ width: "100%", fontSize: "14px" }}
                    value="default"
                  >
                    <span style={{ opacity: "0.5", color: colors.blueMain }}>
                      Body Part
                    </span>
                  </MenuItem>
                  {bodyPartsList.map((bodyPart) => (
                    <MenuItem
                      style={{ width: "100%", fontSize: "14px" }}
                      value={bodyPart.value}
                    >
                      {bodyPart.label}
                    </MenuItem>
                  ))}
                </Select>

                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={(event) => {
                    const selectedValues = event.target.value;
                    const trimmedValues = selectedValues.filter(
                      (value) => value !== "Select Equipments"
                    ); // Exclude "default" value
                    setEquipments(trimmedValues);
                  }}
                  multiple
                  value={equipments}
                  style={{
                    background: "#fff",
                    width: "48%",
                    height: "40px",
                  }}
                  renderValue={(selected) => selected.join(", ")}
                >
                  {/* <MenuItem
                  disabled
                  style={{ width: "100%", fontSize: "14px" }}
                  value="default"
                >
                  <span style={{ opacity: "0.5", color: colors.blueMain }}>
                    Select Equipments
                  </span>
                </MenuItem> */}
                  {equipmentList.map((equipment) => (
                    <MenuItem
                      key={equipment.value}
                      value={equipment.value}
                      style={{ width: "100%", fontSize: "14px" }}
                    >
                      <Checkbox
                        checked={equipments.includes(equipment.value)}
                        color="primary"
                      />
                      {equipment.label}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            )}

            {!isSNC && (
              <Box
                className="flex justify-between items-end"
                style={{ marginTop: "42px" }}
              >
                <Box
                  className="flex"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Input
                      label={"Rounds"}
                      value={rounds}
                      onChange={(e) => {
                        setRounds(e.target.value);
                      }}
                      type="number"
                      placeholder={"Rounds"}
                      containerStyle={{ width: "100%" }}
                    />
                  </Box>
                  <Box className="input-container">
                    <label
                      htmlFor="inputField"
                      style={{
                        display: "block",
                        fontSize: 12,
                        marginLeft: 24,
                        fontFamily: "DM Sans",
                      }}
                    >
                      RunTime
                    </label>
                    <div
                      style={{
                        display: "flex",
                        borderRadius: 5,
                        width: 150,
                        fontSize: 16,
                        fontWeight: "400",
                        overflow: "visible",
                      }}
                      className="mx-4"
                    >
                      <input
                        type="text"
                        className="inputfiled-new"
                        placeholder="MM"
                        style={{ width: "50%", marginRight: 2 }}
                        // className="py-2 text-center outline-none  border-none inline-flex items-center focus:outline-none  text-gray-700"
                        value={minute}
                        onChange={(e) => {
                          setMinute(e.target.value);
                        }}
                      />
                      <div
                        style={{
                          fontSize: "21px",
                          color: "#a19d9d",
                          marginRight: "2px",
                        }}
                        className="py-2"
                      >
                        :
                      </div>
                      <input
                        type="text"
                        placeholder="SS"
                        style={{ width: "50%" }}
                        className="inputfiled-new"
                        value={second}
                        onChange={(e) => {
                          setSecond(e.target.value);
                        }}
                      />
                    </div>
                  </Box>
                </Box>
              </Box>
            )}
            <div
              style={{
                width: "100%",
                background: "#FFFFFF",
                borderRadius: "8px",
              }}
              className="text-blue-main"
            >
              <TextArea
                placeholder={"Description (Optional)"}
                onChange={(event) => handleInstruction(event)}
                value={instruction}
                numRows={3}
              />
            </div>
            <div>
              <Tooltip
                // open={selectedTags.length < 1}
                title={
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: 400,
                      fontFamily: "DM Sans",
                    }}
                    textAlign={"center"}
                  >
                    Press Enter to add tags.
                  </Typography>
                }
                placement="top"
              >
                <Autocomplete
                  freeSolo
                  multiple
                  id="tags-outlined"
                  options={tags}
                  getOptionLabel={(option) => option}
                  value={selectedTags}
                  onChange={(event, newValue) => {
                    setSelectedTags(newValue);
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#ebebeb",
                      },
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "1.5px solid #4339f2",
                      boxShadow: "0px 0px 0px 3px rgba(67, 57, 242, 0.25)",
                    },
                    borderRadius: "12px",
                  }}
                  limitTags={2}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Add Tags"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "12px",
                          outline: "none",
                          border: "none",
                        },
                        "& .MuiOutlinedInput-root:focus": {
                          outline: "none",
                          border: "none",
                        },
                      }}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          setSelectedTags([
                            ...selectedTags,
                            event.target.value,
                          ]);
                        }
                      }}
                    />
                  )}
                />
              </Tooltip>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", p: 0 }}>
        <button
          disabled={!title || title.length < 2}
          className="button-new mb-[24px]"
          onClick={() => saveWorkOutDetails()}
        >
          {isUpdate ? "Update Info" : "Add Workout"}
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default AddNewWorkoutModal;
