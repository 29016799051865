import React from 'react'

const DragIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
  <path d="M15.8646 11.75H3.13672" stroke="#424242" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M3.13672 7.25H15.8646" stroke="#424242" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  )
}

export default DragIcon