import { useEffect, useState } from "react";

const DurationInput = ({ durationInSeconds, setDurationInSeconds }) => {
  const [duration, setDuration] = useState({
    hours: "00",
    minutes: "00",
    seconds: "00",
  });

  // Helper function to pad single digits with leading zeros
  const padTime = (time) => String(time).padStart(2, "0");

  // Convert seconds to hh:mm:ss
  useEffect(() => {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = Math.round((durationInSeconds % 3600) % 60);

    const formattedDuration = {
      hours: padTime(hours),
      minutes: padTime(minutes),
      seconds: padTime(seconds),
    };

    if (
      duration.hours === formattedDuration.hours &&
      duration.minutes === formattedDuration.minutes &&
      duration.seconds === formattedDuration.seconds
    )
      return;

    setDuration(formattedDuration);
  }, [durationInSeconds]);

  const onDurationChange = (e) => {
    const { name, value } = e.target;
    const formattedValue = value.replace(/\D/, ""); // Remove non-numeric characters

    // if only zeros entered more than 2 digits
    if (formattedValue.length > 2 && parseInt(formattedValue) === 0) return;
    let updatedDuration;
    // if zero is inserted at the beginning of the number
    if (formattedValue.length > 1 && formattedValue[0] === "0") {
      const updatedValue = formattedValue.slice(1);
      updatedDuration = { ...duration, [name]: padTime(updatedValue) };
      setDuration(updatedDuration);
      // return;
    } else {
      if (parseInt(formattedValue) > 59) return;
      updatedDuration = { ...duration, [name]: padTime(formattedValue) };
      setDuration(updatedDuration);
    }
    // Update durationInSeconds only if all fields are numeric
    if (!isNaN(formattedValue) && formattedValue !== "") {
      const hours = isNaN(parseInt(updatedDuration.hours))
        ? 0
        : parseInt(updatedDuration.hours);
      const minutes = isNaN(parseInt(updatedDuration.minutes))
        ? 0
        : parseInt(updatedDuration.minutes);
      const seconds = isNaN(parseInt(updatedDuration.seconds))
        ? 0
        : parseInt(updatedDuration.seconds);
      setDurationInSeconds(hours * 3600 + minutes * 60 + seconds);
    }
  };

  return (
    <div className="flex items-center border rounded-[12px] p-2 h-[48px]">
      <input
        className="w-45 text-center outline-none font-DMSans"
        value={duration?.hours}
        name="hours"
        onChange={onDurationChange}
        // maxLength={2}
      />
      <span>:</span>
      <input
        className="w-45 text-center outline-none font-DMSans"
        value={duration?.minutes}
        name="minutes"
        onChange={onDurationChange}
        // maxLength={2}
      />
      <span>:</span>
      <input
        className="w-45 text-center outline-none font-DMSans"
        value={duration?.seconds}
        name="seconds"
        onChange={onDurationChange}
        // maxLength={2}
      />
    </div>
  );
};

export default DurationInput;
