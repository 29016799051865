import React, { useRef, useEffect, useCallback } from "react";
import SpinnerComponent from "../../components/layout/spinner";

const InfiniteScroll = ({
  children,
  fetchMoreData,
  hasMore,
  isLoading,
  threshold = 0.5,
  length,
}) => {
  const observer = useRef();
  const loaderRef = useRef();

  const lastElementRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasMore) {
            fetchMoreData();
          }
        },
        { threshold }
      );
      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore, fetchMoreData, threshold]
  );

  //   useEffect(() => {
  //     if (isLoading && loaderRef.current && length % 3 === 0) {
  //       loaderRef.current.scrollIntoView({ behavior: "smooth" });
  //     }
  //   }, [isLoading]);

  useEffect(() => {
    return () => {
      if (observer.current) observer.current.disconnect();
    };
  }, []);

  return (
    <>
      {children}
      <div ref={lastElementRef} />
      <div ref={loaderRef} />
      {isLoading && (
        <div
          style={{
            display: "flex",
            height: 100,
            width: 100,
            justifyContent: "center",
          }}
        >
          <SpinnerComponent />
        </div>
      )}
    </>
  );
};

export default InfiniteScroll;
