
import React from 'react'
import { Button } from '@mui/material'

const ActionsButtons = ({ buttonName, sx, handClickEvent, length }) => {
    return (
        <Button sx={sx} onClick={handClickEvent} aria-controls="headlessui-menu-items-3">{buttonName}</Button>
    )
}

export default ActionsButtons