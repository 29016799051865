import React, { Component } from "react";
import NotFound from "../pages/404";
import * as Sentry from "@sentry/react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    // You can also log the error to an error tracking service here
    console.log(`Error : `);
    console.error(error, info);
    // Sentry.captureMessage(JSON.stringify(error));
    Sentry.captureException(error);
  }

  render() {
    if (this.state.hasError) {
      return <NotFound />; // Display your custom 404 component
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
