import {
  handleGarminCallbackRes,
  handleGarminCallbackResWellness,
} from "@/ApiServices/Garmin/Api";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const GarminWellnessOAuthCallBack = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const oauth_token = searchParams.get("oauth_token");
  const oauth_verifier = searchParams.get("oauth_verifier");

  const [loading, setLoading] = useState(false);
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    if (id && oauth_token && oauth_verifier) {
      handleGarminCallbackResponse(id, oauth_token, oauth_verifier);
    }
  }, []);

  const handleGarminCallbackResponse = async (id, reqToken, oauthVerifier) => {
    setLoading(true);
    try {
      let res = await handleGarminCallbackResWellness(
        id,
        reqToken,
        oauthVerifier
      );
      exitWebView();
      setConnected(true);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const exitWebView = () => {
    window["ReactNativeWebView"] &&
      window["ReactNativeWebView"].postMessage(
        JSON.stringify({ closeWebView: "true" })
      );
  };

  return (
    <div className="w-full min-h-screen flex items-center justify-center bg-gray-100">
      <div className="p-8 bg-gray-50 rounded shadow-md w-64">
        <h1 className="text-2xl font-bold mb-4 text-gray-800">
          Connecting to Garmin ...
        </h1>
        {connected ? (
          <div className="flex items-center justify-center">
            <img
              src="https://img.icons8.com/color/48/000000/ok--v1.png"
              alt="connected"
            />
            <p className="ml-2 text-green-500">Connected</p>
          </div>
        ) : (
          <div className="flex items-center justify-center">
            {loading ? (
              <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-gray-900"></div>
            ) : (
              "Connecting ..."
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default GarminWellnessOAuthCallBack;
