import React, { useEffect, useState } from "react";
import { Dialog, DialogContent } from "@mui/material";
import { Chart } from "chart.js/auto";

import { Line } from "react-chartjs-2";
import moment from "moment";

const SleepChart = ({ isOpen, setIsOpen, data }) => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Sleep",
        data: [],
        fill: false,
        borderColor: "rgb(70, 79, 235)",
        tension: 0.1,
      },
    ],
  });

  useEffect(() => {
    if (!data || data.length === 0) {
      return;
    }

    data.sort((a, b) => new Date(a.dateAssigned) - new Date(b.dateAssigned));

    const dateLabels = [];
    const sleepData = [];

    data.forEach((item) => {
      if (item?.activitiesFinished?.sleep) {
        let modifiedDate = moment(item?.dateAssigned).format("Do MM' YY");
        dateLabels.push(modifiedDate);
        sleepData.push(item?.activitiesFinished?.sleep);
      }
    });

    setChartData((prevChartData) => ({
      ...prevChartData,
      labels: dateLabels,
      datasets: [
        {
          ...prevChartData.datasets[0],
          data: sleepData,
        },
      ],
    }));
  }, [data]);

  const options = {
    scales: {
      y: {
        title: {
          display: true,
          text: "Sleep",
        },
      },
      x: {
        title: {
          display: true,
          text: "Date",
        },
        grid: {
          display: false, // Hide vertical grid lines
        },
      },
    },
    plugins: {
      legend: {
        display: false, // Hide the legend (dataset visibility toggle)
      },
    },
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        width: "600px",
        maxHeight: "600px",
      }}
    >
      <DialogContent>
        <p
          style={{
            fontFamily: "DM Sans",
            fontSize: "14px",
            marginBottom: 8,
            fontWeight: 600,
            width: "100%",
            textAlign: "center",
          }}
        >
          Sleep
        </p>
        <Line data={chartData} options={options} />
      </DialogContent>
    </Dialog>
  );
};

export default SleepChart;
