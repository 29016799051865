// /*
// import ToastContainer in the main page and use it in the render block
// import { ToastContainer } from "react-toastify";
// and use the toast while Calling API.
// */

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function (
  message,
  type = "default",
  hideProgressBar = false,
  position = "bottom-center",
  duration = 4000
) {
  const commonOptions = {
    position: position,
    className: `customtoast text-font14 md:text-font16`,
    hideProgressBar: hideProgressBar ?? true,
    closeButton: false,
    autoClose: duration,
  };

  switch (type) {
    case "success":
      toast.dismiss("success");
      toast.success(message, {
        ...commonOptions,
        toastId: "success",
      });
      break;
    case "error":
      toast.dismiss("error");
      toast.error(message, {
        ...commonOptions,
        toastId: "error",
      });
      break;
    case "warning":
      toast.dismiss("warning");
      toast.warning(message, {
        ...commonOptions,
        toastId: "warning",
      });
      break;
    case "top success":
      toast.dismiss("top-success");
      toast.success(message, {
        ...commonOptions,
        position: "top-right",
        toastId: "top-success",
      });
      break;
    case "center":
      toast.dismiss("center");
      toast(message, {
        ...commonOptions,
        position: "top-center", // Position the toast in the top-center for better control
        toastId: "center",
        style: {
          ...commonOptions.style,
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "auto",
          maxWidth: "calc(360px + 50px)",
        },
      });
      break;
    default:
      toast.dismiss("default");
      toast(message, {
        ...commonOptions,
        toastId: "default",
        hideProgressBar: false,
      });
  }
}
