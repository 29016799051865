import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";

export default function ProfileImage(props) {
  const { src, style, className, name } = props;
  const [showInitials, setShowInitials] = useState(false);

  const initials = useMemo(() => {
    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

    let ini = [...name.matchAll(rgx)] || [];

    ini = ((ini.shift()?.[1] || "") + (ini.pop()?.[1] || "")).toUpperCase();
    return ini;
  }, [name]);

  return (
    <span
      style={{ overflow: "hidden" }}
      className="h-[56px] w-[56px] rounded-full "
    >
      {showInitials ? (
        <span className="h-[56px] w-[56px] rounded-full ">
          <div className="text-[30px] h-full w-full rounded-full bg-secondaryOrange flex items-center justify-center text-white-pure">
            {initials}
          </div>
        </span>
      ) : (
        <img
          style={{
            objectFit: "cover",
            ...style,
            borderRadius: "100px",
          }}
          className={className}
          src={src}
          alt="user-profile"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            setShowInitials(true);
          }}
        />
      )}
    </span>
  );
}

ProfileImage.propTypes = {
  src: PropTypes.string.isRequired,
  name: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
};

ProfileImage.defaultProps = {
  src: "",
  name: "",
  className: "",
  style: {},
};
