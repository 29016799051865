import { Backdrop } from "@mui/material";
import axios from "axios";
import moment from "moment";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { Streami18n, enTranslations, useChatContext } from "stream-chat-react";
import { CustomAlertContext } from "../../../App";
import SpinnerComponent from "../../../components/layout/spinner";
import { getStreamclient } from "../../../utils/getStreamclients";
import { SearchIcon } from "../../getStreamChat/assets";
import SmallCloseIcon from "../assests/SmallCloseIcon";
import { UserIcon } from "../assests/UserIcon";

import _debounce from "lodash.debounce";
import { chatClient, chatInit } from "../../../utils/chat";

import { CloseRounded } from "@mui/icons-material";
import Fuse from "fuse.js";
import { useSelector } from "react-redux";

const i18nInstance = new Streami18n({
  language: "en",
  translationsForLanguage: {
    ...enTranslations,
  },
});

function NewAssignProgramModal({
  programName,
  activeProgram,
  showModal,
  setShowModal,
  fromProgramTable,
  mealCard,
  setShowAlert,
  showNewAlert,
  getProgramDetails,
}) {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const [searched, setSearched] = useState("");
  const [userData, setUserData] = useState([]);
  const [tempUserData, setTempUserData] = useState([]);
  const [activeUser, setActiveUser] = useState([]);
  const [startDate, setStartDate] = useState(new Date().setHours(0, 0, 0, 0));
  const [selectedDay, setSelectedDay] = useState({ value: 1, label: "Day 1" });
  const [loading, setLoading] = useState(false);
  const [usersForPage, setUsersForPage] = useState([]);
  const [page, setPage] = useState(1);
  const [selectedList, setSelectedList] = useState([]);

  const menuPortalRef = useRef(null);

  const { displayAlert } = useContext(CustomAlertContext);
  const days = [];

  const { client, setActiveChannel, channel } = useChatContext();

  const [allChannels, setAllChannels] = useState([]);
  const [teamChannels, setTeamChannels] = useState([]);
  const [teamFilteredChannels, setTeamFilteredChannels] = useState([]);
  const [directChannels, setDirectChannels] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState();
  let trainerDetails = JSON.parse(localStorage.getItem("trainerCredentials"));

  const [focused, setFocused] = useState(undefined);
  const [focusedId, setFocusedId] = useState("");
  const [query, setQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "ArrowDown") {
        setFocused((prevFocused) => {
          if (prevFocused === undefined) return 0;
          return prevFocused === allChannels.length - 1 ? 0 : prevFocused + 1;
        });
      } else if (event.key === "ArrowUp") {
        setFocused((prevFocused) => {
          if (prevFocused === undefined) return 0;
          return prevFocused === 0 ? allChannels.length - 1 : prevFocused - 1;
        });
      } else if (event.keyCode === 13) {
        event.preventDefault();
        setActiveChannel(allChannels[focused]);
        setFocused(undefined);
        setFocusedId("");
        setQuery("");
      }
    },
    [allChannels, focused, setActiveChannel]
  );

  const connectClient = async () => {
    if (!chatClient?.userID) {
      await chatInit(); //CONNECTING TO GETSTREAM IO
    }
  };

  useEffect(() => {
    connectClient();
    if (query) {
      document.addEventListener("keydown", handleKeyDown);
    }
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [handleKeyDown, query]);

  useEffect(() => {
    if (!query) {
      // setTeamChannels([]);
      setDirectChannels([]);
    }
  }, [query]);

  useEffect(() => {
    if (focused >= 0) {
      setFocusedId(allChannels[focused]?.id);
    }
  }, [allChannels, focused]);

  const setChannel = (channel) => {
    setQuery("");
    setActiveChannel(channel);
  };

  const getChannels = async (text) => {
    setLoading(true);
    if (teamChannels.length == 0) {
      try {
        const data = await getStreamclient(trainerDetails);
        const channelResponse = client.queryChannels(
          {
            type: "team",
            // name: { $autocomplete: text },
            members: { $in: [trainerDetails.password] },
          },
          {},
          { limit: 5 }
        );

        const userResponse = client.queryUsers(
          {
            id: { $in: data },
            $and: [{ name: { $autocomplete: text } }],
          },
          { id: 1 },
          { limit: 5 }
        );

        const [channels, { users }] = await Promise.all([
          channelResponse,
          userResponse,
        ]);

        if (channels.length) {
          setTeamChannels(channels);
          setTeamFilteredChannels(channels);
          // searchUsers(text)
        }
        if (users.length) {
          setDirectChannels(users);
        }
        setAllChannels(channels.concat(users));
      } catch (e) {
        setQuery("");
        console.log(e);
      }
    }
    setLoading(false);
  };

  const getChannelsDebounce = _debounce(getChannels, 200, {
    trailing: true,
  });

  const onSearch = (event) => {
    event.preventDefault();
    setSearched(event.target.value);

    setLoading(true);
    setFocused(undefined);
    setQuery(event.target.value);
    if (!event.target.value) return;

    getChannelsDebounce(event.target.value);
  };

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 9999, // set a higher z-index value
      position: "absolute",
      // maxHeight: "150px",
      // overflowY: "auto",
      fontFamily: "DM Sans",
      top: 0,
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 99999,
      position: "absolute",
      top: "69%",
      left: "58.5%",
    }),
    control: (provided, state) => ({
      ...provided,
      fontFamily: "DM Sans",
      height: 40,
      minHeight: 40,
      borderWidth: 0,
      "&:hover": {
        borderColor: "##071148",
      },
      "&:focus": {
        border: "2px solid ##071148",
        boxShadow: "0 0 0 2px ##071148",
      },
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      padding: "0 6px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
      borderWith: "2px",
      fontFamily: "DM Sans",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
    }),

    option: (provided, state) => ({
      ...provided,
      fontFamily: "DM Sans",
      backgroundColor: state.isSelected ? "white" : "white",
      color: state.isSelected ? "black" : "#424242",
      "&:hover": {
        backgroundColor: state.isSelected ? "#e3e3e3" : "#e3e3e3",
      },
    }),
  };

  for (let i = 1; i <= 365; i++) {
    let dayNumber = i;
    if (i % 7 === 1) {
      // Add a new option for the start of each week
      let weekNumber = Math.ceil(i / 7);
      days.push({
        value: "week",
        label: `Week ${weekNumber}`,
        isDisabled: true,
      });
    }
    days.push({ value: i, label: `Day ${dayNumber}` });
  }

  let pageSize = 6;
  const fetchUsers = () => {
    setLoading(true);
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const usersForPage = userData.slice(startIndex, endIndex);
    const removedSelectedUser = userData.filter(
      (obj) => !activeUser.includes(obj.id)
    );
    setUsersForPage(removedSelectedUser);
    setLoading(false);
  };

  const searchUsers = (searchedVal) => {
    setSearched(searchedVal);

    const fuseOptions = {
      keys: ["name", "userName"], // Properties to search within
      threshold: 0.1, // Adjust the threshold for fuzzy matching
    };

    const fuseOptions2 = {
      keys: ["data.userName", "data.name"], // Properties to search within
      threshold: 0.1, // Adjust the threshold for fuzzy matching
    };

    if (searchedVal !== "") {
      const fuse = new Fuse(tempUserData, fuseOptions);
      const searchResults = fuse.search(searchedVal);

      const filteredRows = searchResults.map((result) => result.item);

      setUserData(filteredRows);

      const fuse2 = new Fuse(teamChannels.flat(), fuseOptions2);
      const searchResultschannel = fuse2.search(searchedVal);

      const filteredRowschannel = searchResultschannel.map(
        (result) => result.item
      );

      // if (filteredRowschannel.length > 0) {
      setTeamFilteredChannels(filteredRowschannel);
      // }
    } else {
      setUserData(tempUserData);
    }
  };

  const assignCROSSFITProgram = () => {
    setIsLoading(true);
    axios({
      method: "post",
      url: `${APIURL}CF/api/assignProgramSegmentedMultiple`,
      data: {
        programID: activeProgram,
        userUUIDs: activeUser,
        startDate: `${moment(startDate)
          .startOf("day")
          .format("YYYY-MM-DD")}T00:00:00.000Z`,
        startProgDay: parseInt(selectedDay.value),
      },
    })
      .then(async (res) => {
        // toast("Program successfully assigned", "success");

        getProgramDetails(activeProgram);
        createChannelsEntry();
        setLoading(false);
        setShowModal(false);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const createChannelsEntry = async () => {
    if (
      !selectedChannels ||
      selectedChannels.length == 0 ||
      !selectedChannels?.[0]?.id
    ) {
      return;
    }
    axios({
      method: "post",
      url: `${APIURL}CF/api/channelProgramTracker/add`,
      data: {
        id: selectedChannels?.[0]?.id,
        channelID: selectedChannels?.[0]?.id,
        channelName: selectedChannels?.[0]?.name,
        programID: activeProgram,
        progStartDate:
          `${moment(startDate).format("YYYY-MM-DD")}` + "T00:00:00.000Z",
      },
    });

    setSelectedChannels([]);
  };

  const assignCROSSFITMealSchedule = () => {
    axios({
      method: "post",
      url: `${APIURL}CF/api/assignMealScheduleSegmentedMultiple`,
      data: {
        mealScheduleID: activeProgram,
        userUUIDs: activeUser,
        startDate: `${moment(startDate)
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ss")}Z`,
        startProgDay: parseInt(selectedDay.value),
      },
    })
      .then(async (res) => {
        setLoading(false);
        setActiveUser([]);
        setShowModal(false);
        // toast("Meal schedule successfully assigned", "success");
      })
      .catch((err) => {});
  };

  const assignProgramToUser = () => {
    if (!activeUser || activeUser.length == 0) {
      displayAlert({
        type: "error",
        message: "Please select a user to assign this program",
      });
      return;
    }
    setLoading(true);

    const assign = assignCROSSFITProgram;
    assign();
  };

  const assignMealScheduleToUser = () => {
    if (!activeUser || activeUser.length == 0) {
      displayAlert({
        type: "error",
        message: "Please select a user to assign this program",
      });
      return;
    }
    setLoading(true);

    const assign = assignCROSSFITMealSchedule;
    assign();
  };

  const getProgramsUsers = () => {
    let trainerDetails = JSON.parse(localStorage.getItem("trainerCredentials"));
    setLoading(true);
    axios({
      method: "get",
      url: `${APIURL}CF/api/CoachWeb/Get/getAllAtheletesOneCoach`,
      params: {
        coachID: trainerDetails?.password,
      },
    }).then(async (res) => {
      const filteredData = res.data.result?.[0]?.atheletes?.filter(
        (item) => item.active == true
      );

      setUserData(filteredData);
      setTempUserData(filteredData);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchUsers();
    let timeOut = setTimeout(() => {
      getChannels("a");
    }, 50);

    return () => {
      clearTimeout(timeOut);
    };
  }, [userData, page, showModal]);

  const handleDayChange = (newValue, actionMeta) => {
    setSelectedDay(newValue);
  };

  const formatOptionLabel = ({ value, label }) => {
    if (label.startsWith("Week")) {
      return <div style={{ fontWeight: "bold" }}>{label}</div>;
    }
    return <div>{label}</div>;
  };

  const addUserStyle = {
    height: "33px",
    fontSize: "14px",
    background: "#071148",
    borderRadius: "8px",
    color: "#fff",
    textTransform: "none",
    marginBottom: 24,
    paddingLeft: 18,
    paddingRight: 16,
  };

  useEffect(() => {
    getProgramsUsers();
  }, []);

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 2 }}
      open={showModal}
    >
      <div
        style={{
          overflowY: "inherit",
          position: "absolute",
          background: "#fff",
          // boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.12)",
          borderRadius: "12px",
        }}
        ref={menuPortalRef}
      >
        {isLoading && (
          <div
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SpinnerComponent />
          </div>
        )}
        <div>
          <div className="add-program-modal-head">
            <p className="add-program-modal-head-title">Assign to Users</p>
            <div
              onClick={() => {
                setShowModal(false);
                setActiveUser([]);
              }}
            >
              <CloseRounded
                sx={{
                  fontSize: "24px",
                  color: "#000",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
          {/* {loading ? ( */}
          <div className="add-program-modal-body">
            <div
              className="full-width-container"
              style={{
                paddingBottom: "20px",
              }}
            >
              <div>
                <div
                  className="channel-search__input__wrapper"
                  style={{
                    minWidth: "500px",
                    maxWidth: "500px",
                    width: "100%",
                    display: "flex",
                    flexWrap: "wrap",
                    height: "auto",
                    justifyContent: "flex-start",
                  }}
                >
                  {selectedList.length > 0 &&
                    selectedList.map((item, index) => {
                      return (
                        <div
                          style={{
                            color: "#333",
                          }}
                          className="searched-selected"
                        >
                          <div className="selected-user-icon-div">
                            {item?.team ? "#" : <UserIcon />}
                          </div>
                          <p
                            style={{
                              color: "#333",
                            }}
                          >
                            {item?.name}
                          </p>
                          <div
                            onClick={() => {
                              if (item?.team) {
                                setSelectedChannels((prev) => {
                                  if (!prev || prev.length == 0) return [];
                                  return prev.filter(
                                    (channel) => channel.id !== item.id
                                  );
                                });

                                item.user.map((userid) => {
                                  setActiveUser((prevState) => {
                                    return prevState.filter(
                                      (uuid) => uuid !== userid
                                    );
                                  });
                                });
                              } else {
                                setActiveUser((prevState) => {
                                  return prevState.filter(
                                    (uuid) => uuid !== item.id
                                  );
                                });
                              }
                              delete selectedList[index];
                            }}
                          >
                            <SmallCloseIcon />
                          </div>
                        </div>
                      );
                    })}
                  <div
                    style={{
                      width: "100%",
                    }}
                    className="channel-search__input__icon"
                  >
                    <SearchIcon />
                    <input
                      className="channel-search__input__text"
                      onChange={(e) => {
                        onSearch(e);
                        searchUsers(e.target.value);
                      }}
                      placeholder="Search users or groups"
                      type="text"
                      style={{
                        width: "100%",
                      }}
                      value={searched}
                    />
                  </div>
                </div>
                {searched.length > 0 && (
                  <div className="search-result-view-divide">
                    <div>
                      <div className="label-new">Users</div>
                      {usersForPage?.length > 0 &&
                        usersForPage?.map((item) => {
                          return (
                            <div
                              className="search-result-view-item-container"
                              onClick={() => {
                                setSelectedList((prev) => [
                                  ...prev,
                                  {
                                    id: item.id,
                                    name: item?.name || item.userName,
                                    team: false,
                                  },
                                ]);
                                setActiveUser((prevState) => [
                                  ...prevState,
                                  item.id,
                                ]);
                                setSearched("");
                              }}
                            >
                              <div
                                className="channel-preview__item_avatar"
                                style={{
                                  backgroundColor: "rgb(255, 229, 211)",
                                }}
                              >
                                <UserIcon />
                              </div>
                              <p
                                style={{
                                  color: "#333",
                                }}
                              >
                                {item?.name || item?.userName}
                              </p>
                            </div>
                          );
                        })}
                    </div>
                    <span className="filter-modal-divider"></span>
                    <div>
                      <div className="label-new">Groups</div>
                      {teamFilteredChannels.length > 0 &&
                        teamFilteredChannels.map((item) => {
                          return (
                            <div
                              className="search-result-view-item-container"
                              onClick={async () => {
                                let channelData = {
                                  id: item?.data?.id,
                                  name: item?.data?.name,
                                };
                                setSelectedChannels((prev) => {
                                  return [...(prev ?? []), channelData];
                                });
                                setActiveChannel(item);
                                let members = Object.keys(
                                  item.state.members || {}
                                );

                                let listUser = [];
                                members.map((key) => {
                                  if (key != trainerDetails.password) {
                                    listUser.push(key);
                                    setActiveUser((prevState) => [
                                      ...prevState,
                                      key,
                                    ]);
                                  }
                                });
                                let selectData = {
                                  id: item.data.id,
                                  name:
                                    item?.data?.name || item?.data?.userName,
                                  team: true,
                                  user: listUser,
                                };
                                if (!selectedList.includes(selectData)) {
                                  setSelectedList((prev) => [
                                    ...prev,
                                    selectData,
                                  ]);
                                }
                                setSearched("");
                              }}
                            >
                              <div
                                className="channel-preview__item_avatar"
                                style={{
                                  backgroundColor: "#CCF8FE",
                                  color: "#000",
                                }}
                              >
                                #
                              </div>
                              <p
                                style={{
                                  color: "#333",
                                }}
                              >
                                {item?.data?.name || item?.data?.userName}
                              </p>
                            </div>
                          );
                        })}
                      {loading && <SpinnerComponent />}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <label
                  htmlFor="inputField"
                  style={{
                    display: "block",
                    color: "#000",
                    fontSize: 10,
                    marginLeft: 12,
                  }}
                >
                  Program Start Date
                </label>
                <DatePicker
                  dateFormat="dd MMM yyyy"
                  minDate={moment()
                    .set({
                      hour: 0,
                      minute: 0,
                      second: 0,
                      millisecond: 0,
                    })
                    .toDate()}
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  customInput={
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        height: "40px",
                        padding: "10px",
                        outline: "none",
                        fontFamily: "DM Sans",
                        cursor: "pointer",
                        color: "#000",
                      }}
                    />
                  }
                />
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor="inputField"
                  style={{
                    display: "block",
                    fontSize: 10,
                    marginLeft: 12,
                    color: "#000",
                  }}
                >
                  Starting Day
                </label>
                <Select
                  styles={customStyles}
                  // menuPortalTarget={menuPortalRef.current}
                  menuPortalTarget={
                    fromProgramTable ? document.body : menuPortalRef.current
                  }
                  options={days}
                  value={selectedDay}
                  onChange={handleDayChange}
                  formatOptionLabel={formatOptionLabel}
                  placeholder="Select day"
                />
              </div>
            </div>
            <div className="w-full flex items-center justify-center">
              <button
                className="button-new"
                onClick={() => {
                  if (!mealCard) assignProgramToUser();
                  else assignMealScheduleToUser();
                }}
              >
                Assign User
              </button>
            </div>
          </div>
        </div>
      </div>
    </Backdrop>
  );
}

export default NewAssignProgramModal;
