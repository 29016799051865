import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import axios from "axios";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { CustomAlertContext } from "../../App";
import EditIcon from "../../assets/workoutBuilder/editIcon.svg";
import NewAssignWorkoutsModal from "../../features/Workouts/NewAssignWorkoutsModal";
import { store } from "../../redux/store";
import { useGetAllWorkoutsQuery } from "../../services/apiSlice";
import { WorkoutContext } from "./WorkoutBuilder";
import { WorkoutModalContext } from "./WorkoutBuilderModal";
import { toggleRecheckForUnsavedWorkout } from "@/redux/dataSlice";
import toast from "../Toast/toast";

export default function TopBar({
  workoutInfo,
  setWorkoutInfo,
  rounds,
  isUpdate,
  isAssign,
  isLoading,
  setIsLoading,
  setShowWorkoutInfoDialog,
  isStructured,
  videoData,
  isVideoUpdate,
  isEnduranceWorkout,
  comingFromModal,
  workoutKind,
  setIsOpenForWorKoutInfo,
  PropsData,
  data,
  setOpenForWorkoutBuilderModal,
  isFromClientView,
  reloadProfileForWorkoutBuilderModal,
  onSuccess,
  onSelectedExerciseChange,
  saveDataToLocalStorage,
  onSuccessOfEditWokroutFromWorkout,
  fromWorkout,
  creatFromWorkout,
  onSuccessOfNewWorkoutForm,
  ...props
}) {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const { displayAlert } = useContext(CustomAlertContext);
  const { isProgramsFlow } = useContext(
    comingFromModal ? WorkoutModalContext : WorkoutContext
  );
  const { handleSaveClick } = useContext(WorkoutModalContext);
  const coachID = useSelector((state) => state.auth?.trainerUUID);
  const { refetch: refetchWorkouts } = useGetAllWorkoutsQuery({
    coachID,
    APIURL,
  });
  const [estimatedTimeFormatted, setEstimatedTimeFormatted] = useState(0);
  const [estimatedTimeInSeconds, setEstimatedTimeInSeconds] = useState(0);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [workoutDetail, setWorkoutDetail] = useState({});

  const programsStates = useSelector((state) => state.programs.programsStates);

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  // console.log(
  //   "location: ",
  //   location?.state?.state?.data?.workoutContent?.rounds,
  //   rounds
  // );
  // console.log("isFromClientView", isFromClientView, PropsData, isUpdate);
  const isCalendar =
    location?.state?.state?.isCalendar ||
    location?.state?.isCalendar ||
    PropsData?.isCalendar;
  const userUUID =
    location?.state?.state?.extraData?.userUUID ||
    location?.state?.userUUID ||
    PropsData?.userUUID;
  const moduleDate =
    location?.state?.state?.extraData?.moduleDate ||
    location?.state?.date ||
    PropsData?.date ||
    PropsData?.moduleDate;

  // console.log("isCalendar: ", isCalendar);
  // console.log("userUUID: ", userUUID);
  // console.log("moduleDate: ", moduleDate);

  const handleSave = () => {
    setIsLoading(true);
    handleSaveClick();
    onSelectedExerciseChange && onSelectedExerciseChange();
    let timeout = setTimeout(() => {
      onSave();
      clearTimeout(timeout);
    }, 100);
  };

  const onSave = async () => {
    if (!workoutInfo?.name || workoutInfo?.name?.length === 0) {
      displayAlert({
        message: "Please enter a workout name",
        type: "error",
      });
      setIsLoading(false);
      return;
    }

    if (isStructured) {
      for (const round of rounds ?? []) {
        if (!round?.round?.measurements?.length) {
          displayAlert({
            message: "Please add at least one exercise to each section",
            type: "error",
          });
          setIsLoading(false);
          return;
        }
        for (const measurement of round?.round?.measurements ?? []) {
          if (!getIsAnyPropertySelected(measurement?.measurement)) {
            toast(
              "Please select at least one property for each measurement",
              "error",
              false
            );
            setIsLoading(false);
            return;
          }
        }
      }
    }

    setIsLoading(true);

    if (isCalendar == true && !PropsData?.isAssign) {
      saveAndPush(true);

      return;
    }
    if (isUpdate) {
      if (
        isCalendar == "true" ||
        location?.state?.state?.isImport == "true" ||
        location?.state?.state?.isImport == true
      ) {
        saveAndPush();
        return;
      }

      let payload = {
        workoutID: location?.state?.state?.data?.id || data?.id,
        workoutInfo: {
          ...workoutInfo,
          isStructured: isStructured,
          runTime: estimatedTimeInSeconds,
          workoutKind: isEnduranceWorkout ? workoutKind : undefined,
        },
        workoutContent: isStructured
          ? { rounds }
          : { ...videoData, id: undefined },
      };

      // remove all null values from workout info
      for (let key in payload.workoutInfo) {
        if (payload.workoutInfo[key] === null) {
          delete payload.workoutInfo[key];
        }
      }

      if (payload.workoutInfo?.workoutCategory) {
        delete payload.workoutInfo.workoutCategory;
      }
      if (isNaN(payload.workoutInfo.runTime)) {
        delete payload.workoutInfo.runTime;
      }

      await axios({
        method: "post",
        url: `${APIURL}CF/api/CoachWeb/Update/updateWorkoutWithAllDetailsJSON`,
        data: payload,
      })
        .then((res) => {
          //    console.log("this is coming from topbar on workout update",res)
          let timeout = setTimeout(() => {
            localStorage.removeItem("workoutData");
            dispatch(toggleRecheckForUnsavedWorkout());
            clearTimeout(timeout);
          }, 1000);
          if (comingFromModal) {
            if (props?.setSelectedToShow) {
              props?.setSelectedToShow(res?.data?.result);
            }
            if (props?.setWorkoutBuilderModal) {
              console.log("from the topbar updated workout", res?.data?.result);
              const data = res?.data?.result;
              onSuccess && onSuccess(data);
              fromWorkout && onSuccessOfEditWokroutFromWorkout(data);
              props?.setWorkoutBuilderModal(false);
            }
          } else {
            console.log("this is coming from cliets view");
            history.goBack();
          }
        })
        .catch((err) => {
          saveDataToLocalStorage && saveDataToLocalStorage();
          console.log("error updating", err);
        });
    } else {
      var d = {
        workoutInfo: {
          ...workoutInfo,
          coachID: store.getState().auth.trainerUUID,
          workoutType: workoutInfo?.workoutType || "NOSCORE",
          dateCreated: new Date().toISOString(),
          isCoachWorkout: true,
          isStructured: isStructured,
          workoutKind: isEnduranceWorkout ? workoutKind : undefined,
          runTime: estimatedTimeInSeconds,
        },
        workoutContent: isStructured
          ? { rounds }
          : { ...videoData, id: undefined },
      };

      // remove all null values from workout info
      for (let key in d.workoutInfo) {
        if (d.workoutInfo[key] === null) {
          delete d.workoutInfo[key];
        }
      }

      if (d.workoutInfo?.workoutCategory) {
        delete d.workoutInfo.workoutCategory;
      }

      if (isNaN(d.workoutInfo.runTime)) {
        delete d.workoutInfo.runTime;
      }

      await axios({
        method: "post",
        url: `${APIURL}CF/api/CoachWeb/Create/addWorkoutWithAllDetailsJSON`,
        data: { workout: d },
      })
        .then((res) => {
          displayAlert({
            message: "Workout created successfully",
            type: "success",
          });
          let timeout = setTimeout(() => {
            localStorage.removeItem("workoutData");
            dispatch(toggleRecheckForUnsavedWorkout());
            clearTimeout(timeout);
          }, 1000);
          if (isFromClientView) {
            postWorkout(res?.data?.result?.id);
            return;
          }
          refetchWorkouts();
          if (comingFromModal) {
            if (props?.setNewCheckedItems) {
              props?.setNewCheckedItems(res?.data?.result);
            }
            if (props?.setWorkoutBuilderModal) {
              if (creatFromWorkout) {
                onSuccessOfNewWorkoutForm(res?.data?.result);
              }
              props?.setWorkoutBuilderModal(false);
            }
          } else {
            history.goBack();
            return;
          }
        })
        .catch((err) => {
          saveDataToLocalStorage && saveDataToLocalStorage();
          console.log("error creating workout", err);
          displayAlert({
            message: "Error creating workout",
            type: "error",
          });
        });
    }

    setIsLoading(false);
  };

  const handleBeforeUnload = () => {
    if (isLoading) return;
    // if any changes are made then store them in local storage
    // two cases:
    // 1. new workout
    // 2. update workout

    let isWorkoutContentChanged = false;
    let isNewWorkout = false;
    if (isUpdate) {
      // check if any changes are made in workout info or workout content
      let curWorkoutInfo = data;
      let workoutContent = data?.workoutContent;

      if (workoutContent) {
        let prevRounds = data?.workoutContent?.rounds;

        if (JSON.stringify(prevRounds) !== JSON.stringify(rounds)) {
          isWorkoutContentChanged = true;
        }
      }
    } else {
      isNewWorkout = true;
    }

    if (isNewWorkout || isWorkoutContentChanged) {
      let localData = {
        ...data,
        workoutContent: {
          rounds,
        },
      };

      if (workoutInfo.name && workoutInfo.name !== "") {
        localData.name = workoutInfo.name;
      }
      if (workoutInfo.description && workoutInfo.description !== "") {
        localData.description = workoutInfo.description;
      }
      localStorage.setItem("workoutData", JSON.stringify(localData));
    }

    console.log("Navigating away...");
  };

  const updateSNCWorkout = async () => {
    setIsLoading(true);
    await axios({
      method: "post",
      url: `${APIURL}api/updateparticularmodularworkout`,
      data: {
        primaryKey: location?.state?.state?.data?.id,
        workoutInfo: {
          name: workoutInfo?.name,
          description: workoutInfo?.description,
          tag: workoutInfo?.tag,
          workoutKind: isEnduranceWorkout ? workoutKind : undefined,
        },
        moduleDetails: { rounds },
      },
    })
      .then((res) => {
        console.log("updated: ", res);
        history.goBack();
        displayAlert({
          message: "Workout updated successfully",
          type: "success",
        });
      })
      .catch((err) => {
        console.log("error updating", err);
        displayAlert({
          message: "Error updating workout",
          type: "error",
        });
      })
      .finally(() => {
        localStorage.removeItem("workoutData");
        setIsLoading(false);
      });
  };

  const saveAndPush = async (isCoachWorkout) => {
    setIsLoading(true);
    if (isUpdate || isCalendar == true) {
      let d = {
        workoutInfo: {
          ...workoutInfo,
          id: undefined,
          coachID: store.getState().auth.trainerUUID,
          workoutType: workoutInfo?.workoutType || "NOSCORE",
          dateCreated: new Date().toISOString(),
          isCoachWorkout: isCoachWorkout ?? false,
          workoutKind: isEnduranceWorkout ? workoutKind : undefined,
          isStructured: isStructured,
        },
        workoutContent: isStructured
          ? { rounds }
          : { ...videoData, id: undefined },
      };

      if (d.workoutInfo.videoLinks === null) {
        delete d.workoutInfo.videoLinks;
      }

      if (isNaN(d.workoutInfo?.runTime)) {
        delete d.workoutInfo?.runTime;
      }

      await axios({
        method: "post",
        url: `${APIURL}CF/api/CoachWeb/Create/addWorkoutWithAllDetailsJSON`,
        data: { workout: d },
      })
        .then(async (res) => {
          if (location?.state?.state?.isCalendar || PropsData?.isCalendar) {
            console.log("from here");
            let workoutID = res?.data?.result?.id;
            let isEndurance = checkIsEnduranceWorkout(
              res?.data?.result?.workoutKind
            );
            let trackingID =
              location?.state?.state?.extraData?.trackingID ||
              PropsData?.trackingID;

            await updateAssignedWorkout(workoutID, trackingID, isEndurance);
            if (comingFromModal) {
              if (props?.setSelectedToShow) {
                props?.setSelectedToShow(res?.data?.result);
              }
              if (props?.setWorkoutBuilderModal) {
                console.log(
                  "from the topbar updated workout",
                  res?.data?.result
                );
                const data = res?.data?.result;
                onSuccess && onSuccess(data);
                props?.setWorkoutBuilderModal(false);
              }
            } else {
              history.go(-2);
            }
          } else {
            console.log("from here");
            await postWorkout(res.data.result.id);
          }
        })
        .catch((err) => {
          saveDataToLocalStorage && saveDataToLocalStorage();
        });
    }
    console.log("from here");
  };

  const updateAssignedWorkout = async (workoutID, trackingID, isEndurance) => {
    await axios({
      method: "post",
      url: `${APIURL}CF/api/UpdateAssignedWorkout`,
      data: {
        workoutID,
        trackingID,
        isEnduranceWorkout: isEndurance,
      },
    })
      .then((res) => {
        console.log("updated assigned workout: ", res.data);
      })
      .catch((err) => {
        console.log("error in CF update assigned workout in section.js", err);
        saveDataToLocalStorage && saveDataToLocalStorage();
      });
  };

  const postWorkout = async (id) => {
    let url = `${APIURL}CF/api/modules`;

    let data = {
      userUUID: `${userUUID}`,
      modulesUUID: `${id}`,
      moduleDate: `${moduleDate}`,
      isEnduranceWorkout: isEnduranceWorkout,
    };

    await axios({
      method: "post",
      url: url,
      data: data,
    })
      .then(async (res) => {
        if (isFromClientView) {
          setIsOpenForWorKoutInfo(false);
          reloadProfileForWorkoutBuilderModal(userUUID);
          return;
        }
        // history.push(`/client/${data.userUUID}/`);
        if (
          location?.state?.state?.isImport == "true" ||
          location?.state?.state?.isImport == true ||
          location?.state?.isImport == true
        ) {
          history.go(-3);
        } else {
          console.log("from here");
          history.go(-1);
        }
      })
      .catch((err) => {
        saveDataToLocalStorage && saveDataToLocalStorage();
        console.log("error in CF Post workout in section.js", err);
      });
  };

  const updateAndAssign = async (activeUsers, startDate) => {
    let d = {
      workoutInfo: {
        ...workoutInfo,
        id: undefined,
        coachID: store.getState().auth.trainerUUID,
        workoutType: workoutInfo?.workoutType || "NOSCORE",
        workoutKind: isEnduranceWorkout ? workoutKind : undefined,
        dateCreated: new Date().toISOString(),
        isCoachWorkout: false,
        isStructured: isStructured,
      },
      workoutContent: isStructured
        ? { rounds }
        : { ...videoData, id: undefined },
    };

    if (d.workoutInfo.videoLinks === null) {
      delete d.workoutInfo.videoLinks;
    }

    if (isNaN(d.workoutInfo?.runTime)) {
      delete d.workoutInfo?.runTime;
    }

    await axios({
      method: "post",
      url: `${APIURL}CF/api/CoachWeb/Create/addWorkoutWithAllDetailsJSON`,
      data: { workout: d },
    })
      .then(async (res) => {
        assignMultiple(activeUsers, res.data?.result?.id, startDate);
      })
      .catch((err) => {
        saveDataToLocalStorage && saveDataToLocalStorage();
        displayAlert({
          message: "Error updating workout",
          type: "error",
        });
      });
  };

  const assignMultiple = async (activeUsers, workoutId, startDate) => {
    setIsLoading(true);
    let url = `${APIURL}CF/api/modules`;

    const promises = activeUsers?.map((user) => {
      let data = {
        userUUID: user,
        modulesUUID: workoutId,
        moduleDate: `${moment(startDate)
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ss")}Z`,
        isEnduranceWorkout: isEnduranceWorkout,
      };

      return axios({
        method: "post",
        url: url,
        data: data,
      });
    });

    Promise.all(promises)
      .then((results) => {
        results.forEach((res) => {
          displayAlert({
            message: "Workout assigned successfully",
            type: "success",
          });
        });
      })
      .catch((err) => {
        displayAlert({
          message: "Error assigning workout to user",
          type: "error",
        });

        console.log("error assigning workout to user: ", err);
      });

    setShowAssignModal(false);
    history.goBack();
  };
  const formatTime = (totalSeconds) => {
    // console.log("total seconds", totalSeconds);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");
    // console.log(
    //   "formatted time",
    //   formattedHours,
    //   formattedMinutes,
    //   formattedSeconds
    // );

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };
  useEffect(() => {
    let tempEstimatedTime = 0;
    rounds.map((item) => {
      if (item?.round?.restTime) {
        console.log("hit 1", item?.round?.restTime);
        tempEstimatedTime += parseInt(item?.round?.restTime);
      }
      // console.log("item :", item?.round?.measurements);
      let measurementArray = item?.round?.measurements;
      measurementArray.forEach((obj) => {
        // console.log("reps obj", obj?.measurement?.reps);
        let breakObj = {};
        if (obj?.measurement?.break) {
          breakObj = { ...obj?.measurement?.break };
          for (let key in breakObj) {
            console.log("hit 2", breakObj?.[key]);
            tempEstimatedTime += parseInt(breakObj?.[key]);
          }
        }
        let repsObj = obj?.measurement?.reps;
        if (obj?.measurement?.duration) {
          let durationObj = obj?.measurement?.duration;
          for (let key in durationObj) {
            let individualDuration = parseInt(durationObj?.[key]);
            if (individualDuration != "") {
              console.log("hit 3", individualDuration, tempEstimatedTime);
              tempEstimatedTime += individualDuration;
              console.log("hit 33", individualDuration, tempEstimatedTime);
            }
          }
        } else {
          for (let key in repsObj) {
            // console.log("hit 4", repsObj?.[key]);
            let individualRep = repsObj?.[key];
            if (individualRep > 10) {
              tempEstimatedTime += 65;
              console.log("hit 5", tempEstimatedTime);
            } else if (individualRep > 0 && individualRep <= 10) {
              tempEstimatedTime += 50;
              // console.log("hit 6", tempEstimatedTime);
            }
          }
        }
      });
    });
    let formatedEstimatedTime = formatTime(tempEstimatedTime);
    setEstimatedTimeFormatted(formatedEstimatedTime);
    setEstimatedTimeInSeconds(tempEstimatedTime);
  }, [rounds]);

  return (
    <div
      style={{
        height: "77px",
        width: "100vw",
        paddingLeft: isEnduranceWorkout ? "0px" : "65px",
        paddingRight: isEnduranceWorkout ? "98px" : "65px",
        position: "fixed",
        top: 0,
        zIndex: 99,
      }}
    >
      <div
        style={{
          height: "100%",
          width: "100%",
          backgroundColor: "#FFFFFF",
          borderRadius: "0px 0px 16px 16px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          boxShadow: "0px 2px 5px -1px rgba(0,0,0,0.13)",
        }}
      >
        <div
          style={{
            padding: "16px 32px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <ArrowBackRoundedIcon
            style={{
              fontSize: "24px",
              cursor: "pointer",
              marginRight: "16px",
            }}
            onClick={() => {
              if (comingFromModal) {
                try {
                  handleBeforeUnload();
                  setIsOpenForWorKoutInfo(false);
                  dispatch(toggleRecheckForUnsavedWorkout());
                } catch (error) {}

                props?.setWorkoutBuilderModal(false);
              } else {
                history.goBack();
              }
            }}
          />
          <p className="workout-title">{workoutInfo?.name}</p>
          <img
            onClick={() => {
              setShowWorkoutInfoDialog(true);
            }}
            style={{
              cursor: "pointer",
              height: "24px",
              width: "24px",
              marginLeft: "8px",
            }}
            src={EditIcon}
          />
        </div>
        {isEnduranceWorkout || !isStructured ? null : (
          <h1>
            Estimated Time: <span>{estimatedTimeFormatted}</span>{" "}
          </h1>
        )}
        <div
          style={{
            padding: "16px 32px",
          }}
        >
          {isAssign ? (
            <button
              onClick={() => {
                setShowAssignModal(true);
                let d = {
                  workoutInfo: {
                    ...workoutInfo,
                    id: undefined,
                    coachID: store.getState().auth.trainerUUID,
                    workoutKind: isEnduranceWorkout ? "run" : undefined,
                    workoutType: workoutInfo?.workoutType || "NOSCORE",
                    dateCreated: new Date().toISOString(),
                    isCoachWorkout: false,
                  },
                  workoutContent: { rounds },
                };

                if (d.workoutInfo.videoLinks === null) {
                  delete d.workoutInfo.videoLinks;
                }

                setWorkoutDetail(d);
              }}
              className="button-new"
            >
              Assign Workout
            </button>
          ) : (
            <button onClick={handleSave} className="button-new">
              {isUpdate
                ? location?.state?.state?.isImport == "true" ||
                  location?.state?.state?.isImport == true
                  ? "Assign Workout"
                  : "Update Workout"
                : programsStates.started && isProgramsFlow
                ? "Save for Programs"
                : location?.state?.state?.isImport == "true" ||
                  location?.state?.state?.isImport == true ||
                  isCalendar == true
                ? "Assign Workout"
                : "Save Workout"}
            </button>
          )}
        </div>
      </div>

      {showAssignModal && (
        <NewAssignWorkoutsModal
          workoutDetail={workoutDetail}
          showModal={showAssignModal}
          setShowModal={setShowAssignModal}
          isAssignFromWorkoutBuilder={true}
          onAssign={updateAndAssign}
        />
      )}
    </div>
  );
}
export const getIsAnyPropertySelected = (item) => {
  return (
    (item?.reps &&
      Object.keys(item?.reps || {}).length > 0 &&
      checkForTheEmptyValuesInObject(item?.reps)) ||
    (item?.weight &&
      Object.keys(item?.weight || {}).length > 0 &&
      checkForTheEmptyValuesInObject(item?.weight)) ||
    (item?.height &&
      Object.keys(item?.height || {}).length > 0 &&
      checkForTheEmptyValuesInObject(item?.height)) ||
    (item?.distance &&
      Object.keys(item?.distance || {}).length > 0 &&
      checkForTheEmptyValuesInObject(item?.distance)) ||
    (item?.duration &&
      Object.keys(item?.duration || {}).length > 0 &&
      checkForTheEmptyValuesInObject(item?.duration)) ||
    (item?.calorie &&
      Object.keys(item?.calorie || {}).length > 0 &&
      checkForTheEmptyValuesInObject(item?.calorie)) ||
    (item?.heartRate &&
      Object.keys(item?.heartRate || {}).length > 0 &&
      checkForTheEmptyValuesInObject(item?.heartRate)) ||
    (item?.calorie &&
      Object.keys(item?.calorie || {}).length > 0 &&
      checkForTheEmptyValuesInObject(item?.calorie)) ||
    (item?.pace &&
      Object.keys(item?.pace || {}).length > 0 &&
      checkForTheEmptyValuesInObject(item?.pace)) ||
    (item?.intensity &&
      Object.keys(item?.intensity || {}).length > 0 &&
      checkForTheEmptyValuesInObject(item?.intensity)) ||
    (item?.primaryTarget &&
      Object.keys(item?.primaryTarget || {}).length > 0 &&
      checkForTheEmptyValuesInObject(item?.primaryTarget)) ||
    (item?.secondaryTarget &&
      Object.keys(item?.secondaryTarget || {}).length > 0 &&
      checkForTheEmptyValuesInObject(item?.secondaryTarget)) ||
    (item?.power &&
      Object.keys(item?.power || {}).length > 0 &&
      checkForTheEmptyValuesInObject(item?.power))
  );
};

const checkIsEnduranceWorkout = (workoutKind) => {
  return (
    workoutKind === "run" || workoutKind === "bike" || workoutKind === "swim"
  );
};

const checkForTheEmptyValuesInObject = (obj) => {
  let temp = true;
  if (Object.keys(obj || {}).length > 0) {
    Object.keys(obj).map((data) => {
      if (obj[data] === "") {
        temp = false;
      }
    });
  }

  return temp;
};
