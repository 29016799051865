import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import ClientDetails from "../../components/ClientsView/ClientDetails";
// import {useParams} from 'react-router-dom';
import {
  getClientProfileByDateRange,
  getClientProfileSummary,
  getTraineeDetails,
} from "../../ApiServices/ClientProfileApi/Api";
import moment from "moment";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

const AdminUserDetail = ({ id }) => {
  //   const { id } = useParams();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  // const id = urlParams.get("id");
  console.log("id ha kya ", id);
  const [summaryData, setSummaryData] = useState({});
  const [clientProfileData, setClientProfileData] = useState({});
  const [clientsMealPlanData, setClientsMealPlanData] = useState({});
  const [clientsActivityData, setClientsActivityData] = useState({});
  const [clientProfileRaw, setClientProfileRaw] = useState({});
  const [profileData, setProfileData] = useState({});
  const [selectedTab, setSelectedTab] = useState("membership");
  const [loading, setLoading] = useState(false);
  const [
    currentMonthEnduranceFinishedData,
    setCurrentMonthEnduranceFinishedData,
  ] = useState({});

  const fetchData = async () => {
    setLoading(true);
    const tempSummaryData = await getClientProfileSummary(id);
    const tempTraineeDetails = await getClientProfileForSelectedCalendarType(
      id
    );
    // let allProfileData = {};
    // let allMealPlanData = {};
    // let allActivityData = {};
    // let allClientProfileRaw = {};
    // let profileData = tempTraineeDetails;
    // if (id) {
    //     let DATA = [];
    //     let currentMonthmodules = profileData?.currentMonthmodules || {};
    //     Object.entries(currentMonthmodules)?.map(([key, value]) => {
    //         value?.forEach((item) => {
    //             DATA.push({
    //                 ModuleDate: key,
    //                 Complete: item?.finished,
    //                 Name: item?.workoutAssigned?.name,
    //                 PrimaryKey: item?.id,
    //                 WorkoutId: item?.workoutAssigned?.id,
    //             });
    //         });
    //     });
    //     allProfileData[id] = DATA;
    //     allMealPlanData[id] = profileData?.mealData;
    //     allActivityData[id] = profileData?.activityData;
    //     console.log("all profile data", allProfileData);
    //     setClientProfileData(allProfileData);
    //     console.log(
    //         "summary data and trainee Details",
    //         tempSummaryData,
    //         tempTraineeDetails
    //     );
    setSummaryData(tempSummaryData);
    //     setClientsMealPlanData(allMealPlanData);
    //     setClientsActivityData(allActivityData);
    //     setClientProfileRaw(allClientProfileRaw);
    // }
    setLoading(false);
  };

  // const reloadParticularClientsProfile = async (clientUUID) => {
  //   setLoading(true);
  //   const profileData = await getTraineeDetails(clientUUID);
  //   let uuid = profileData?.userInfo?.id;
  //   if (clientUUID) {
  //     let DATA = [];
  //     let currentMonthmodules = profileData?.currentMonthmodules || {};
  //     Object.entries(currentMonthmodules)?.map(([key, value]) => {
  //       value?.forEach((item) => {
  //         DATA.push({
  //           ModuleDate: key,
  //           Complete: item?.finished,
  //           Name: item?.workoutAssigned?.name,
  //           PrimaryKey: item?.id,
  //           WorkoutId: item?.workoutAssigned?.id,
  //         });
  //       });
  //     });
  //     setClientProfileData({
  //       ...clientProfileData,
  //       [clientUUID]: DATA,
  //     });
  //     setClientsMealPlanData({
  //       ...clientsMealPlanData,
  //       [clientUUID]: profileData?.mealData,
  //     });
  //     setClientsActivityData({
  //       ...clientsActivityData,
  //       [clientUUID]: profileData?.activityData,
  //     });
  //     setClientProfileRaw({
  //       ...clientProfileRaw,
  //       [clientUUID]: profileData,
  //     });
  //
  //     setLoading(false);
  //     return {
  //       profileData: DATA,
  //       mealData: profileData?.mealData,
  //       activityData: profileData?.activityData,
  //     };
  //   }
  // };

  const reloadParticularClientsProfile = async (
    clientUUID,
    startDate,
    endDate
  ) => {
    const profileData = await getClientProfileByDateRange(
      clientUUID,
      startDate,
      endDate
    );

    let uuid = profileData?.userInfo?.[0]?.id;
    if (clientUUID) {
      let enduranceFinishedData =
        profileData?.currentMonthEnduranceFinishedData;
      setCurrentMonthEnduranceFinishedData({
        ...(currentMonthEnduranceFinishedData ?? {}),
        [clientUUID]: enduranceFinishedData,
      });

      let DATA = [];
      let currentMonthmodules = profileData?.currentMonthmodules || {};
      Object.entries(currentMonthmodules)?.map(([key, value]) => {
        value?.forEach((item) => {
          DATA.push({
            ModuleDate: key,
            Complete: item?.finished,
            Name: item?.workoutAssigned?.name,
            PrimaryKey: item?.id,
            WorkoutId: item?.workoutAssigned?.id,
          });
        });
      });
      setClientProfileData({
        ...clientProfileData,
        [clientUUID]: currentMonthmodules,
      });
      setClientsMealPlanData({
        ...clientsMealPlanData,
        [clientUUID]: profileData?.mealData,
      });
      setClientsActivityData({
        ...clientsActivityData,
        [clientUUID]: profileData?.activityData,
      });
      setClientProfileRaw((prev) => {
        return { ...(prev ?? {}), [clientUUID]: profileData };
      });

      return {
        profileData: DATA,
        mealData: profileData?.mealData,
        activityData: profileData?.activityData,
      };
    }
  };

  const reloadClientSummary = async (clientUUID) => {
    const summaryData2 = await getClientProfileSummary(clientUUID);

    let markedAsUnread = summaryData2?.userInfo?.markedAsUnread;

    // setUnreadMarks({
    //   ...unreadMarks,
    //   [clientUUID]: markedAsUnread,
    // });

    setSummaryData(summaryData2);
  };

  const getClientProfileForSelectedCalendarType = () => {
    const storedCalendarType = "Monthly";
    // localStorage.getItem("calendarType");
    if (!storedCalendarType || storedCalendarType === "Weekly") {
      const today = moment();
      let startDate = moment(today).startOf("week").add(1, "day");
      let endDate = moment(today).endOf("week").add(1, "day");

      let oneWeekDateRange = localStorage.getItem(`${id}@oneWeekDateRange`);

      if (oneWeekDateRange && assigned === "true") {
        let [start, end] = oneWeekDateRange.split("$");
        if (
          startDate &&
          endDate &&
          moment(start).isSame(startDate) &&
          moment(end).isSame(endDate)
        ) {
        } else {
          startDate = moment(start).toDate();
          endDate = moment(end).toDate();
        }
      }

      reloadParticularClientsProfile(id, startDate, endDate);
    } else if (storedCalendarType === "Semi Monthly") {
      let twoWeekDateRange = localStorage.getItem(`${id}@twoWeekDateRange`);

      const today = moment();
      let startDate = moment(today).startOf("week");
      let endDate = moment(today).endOf("week").add(1, "week");

      if (twoWeekDateRange && assigned === "true") {
        let [start, end] = twoWeekDateRange.split("$");

        if (
          startDate &&
          endDate &&
          moment(start).isSame(startDate) &&
          moment(end).isSame(endDate)
        ) {
        } else {
          startDate = moment(start).toDate();
          endDate = moment(end).toDate();
        }
      }
      reloadParticularClientsProfile(id, startDate, endDate);
    } else if (storedCalendarType === "Monthly") {
      const today = moment();
      let startDate = moment(today).startOf("month");
      let endDate = moment(today).endOf("month");

      let calendarStartDate = localStorage.getItem(id + "@calStartDate");

      if (calendarStartDate) {
        startDate = moment(calendarStartDate).startOf("month");
        endDate = moment(calendarStartDate).endOf("month");
      }

      reloadParticularClientsProfile(id, startDate, endDate);
    }
    let timeout = setTimeout(() => {
      reloadClientSummary(id);
      clearTimeout(timeout);
    }, 0);
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <DragDropContext onDragEnd={() => {}}>
      <Droppable>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{
              height: "calc(100vh - 100px)",
              overflowY: "hidden",
              // margin horizontal 10px
              padding: "0 10px",
              background: "#f5f5f5",
            }}
          >
            <ClientDetails
              key={id}
              summaryData={summaryData}
              setSummaryData={setSummaryData}
              clientsProfileData={clientProfileData?.[id]}
              isAdminUserDetail={true}
              clientsMealPlanData={clientsMealPlanData?.[id]}
              clientsActivityData={clientsActivityData?.[id]}
              profileData={clientProfileRaw?.[id]}
              setClientProfileData={(updatedData) => {
                setClientProfileData(updatedData);
              }}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              reloadParticularClientsProfile={reloadParticularClientsProfile}
              parentLoading={loading}
              reloadClientSummary={reloadClientSummary}
              updateUserInfoOfOneClient={(info) => {
                setSummaryData({
                  ...summaryData,
                  [id]: {
                    ...summaryData[id],
                    userInfo: info,
                  },
                });
              }}
              setProfileData={(updatedData, shouldMerge) => {
                setClientProfileRaw((prevProfile) => {
                  if (!id) {
                    return prevProfile; // Return previous state if selectedClient or UserUUID is undefined
                  }
                  if (shouldMerge) {
                    let curUserPrevData = prevProfile?.[id];
                    let updatedCurrentMonthModules = {
                      ...(curUserPrevData?.currentMonthmodules || {}),
                      ...(updatedData?.currentMonthmodules || {}),
                    };
                    let updatedMonthEnduranceFinishedData = {
                      ...(curUserPrevData?.currentMonthEnduranceFinishedData ||
                        {}),
                      ...(updatedData?.currentMonthEnduranceFinishedData || {}),
                    };

                    let updatedActivity = {
                      ...(curUserPrevData?.activityData || {}),
                      ...(updatedData?.activityData || {}),
                    };

                    let updatedMealData = {
                      ...(curUserPrevData?.mealData || {}),
                      ...(updatedData?.mealData || {}),
                    };
                    updatedData = {
                      currentMonthmodules: updatedCurrentMonthModules,
                      currentMonthEnduranceFinishedData:
                        updatedMonthEnduranceFinishedData,
                      mealData: updatedMealData,
                      activityData: updatedActivity,
                    };
                  }
                  const updatedProfile = {
                    ...prevProfile,
                    [id]: updatedData,
                  };
                  return updatedProfile;
                });
                let curMonthEnduranceFinishData = {
                  ...currentMonthEnduranceFinishedData,
                  ...(updatedData?.currentMonthEnduranceFinishedData || {}),
                };
                setCurrentMonthEnduranceFinishedData({
                  ...currentMonthEnduranceFinishedData,
                  [id]: curMonthEnduranceFinishData,
                });
              }}
            />
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default AdminUserDetail;
