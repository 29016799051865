const theme = {
  palette: {
    tangerine: "#ff9800",
    nightBlue: "#060f49",
    warmGray: "#717171",
    twilight: "#495a86",
    blakish: "#040f2e",
    lightGrey: "#dfdfdf",
    white: "#fff",
    pureBlack: "#000000",
    homeBackground: "#FAFAFA",
    gray: "868689",
  },
};

export default theme;
