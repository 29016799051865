import React, { useEffect, useContext, useState } from "react";
import Layout from "../../components/layout/Layout";
import { Box, CircularProgress, Divider, Popover } from "@mui/material";
import SearchBar from "@/components/Dashboard/SearchBar";
import DeleteIcon from "../../assets/workouts/DeleteIcon.svg";
import { makeStyles } from "@mui/styles"; // Assuming Material-UI for styles
import { store } from "../../redux/store";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import AssignUserIcon from "../../assets/workouts/AssignUserIcon.svg";
import Delete from "../../assets/workouts/DeleteIcon.svg";
import { chatClient } from "../../utils/chat";
import { Chat, Streami18n, enTranslations } from "stream-chat-react";
import moment from "moment";
import {
    useHistory,
    useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import FormDialog from "./components/FormDialog";
import { deleteForm, getCoachForm } from "@/ApiServices/FormControl/Api";
import MenuOptions from "@/features/Workouts/MenuOptions";
import AssignUserModal from "./components/AssignUserModal";
import { CustomAlertContext } from "@/App";
import { getSingleFormDataFunc, resetUserFormFunc, setUserEditFormFunc } from "@/redux/formSlice";
import { useDispatch } from "react-redux";
// import { resetUserFormFunc, getSingleFormDataFunc } from "@/redux/formSlice";

const useStyles = makeStyles({
    listItem: {
        padding: 10,
        display: "flex",
        alignItems: "center", // Ensures vertical centering
        width: "100%", // Sets full width
        borderBottom: "1px solid #ccc", // Adds bottom border
        cursor: "pointer", // Enables hover effect for selecting/navigating
    },
    listItemAvatar: {
        marginRight: "16px", // Adjust margin as needed
    },
});
var formsData = [];

function FormControl() {
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [selectedForm, setSelectedForm] = useState([]);
    const [showSortData, setShowSortData] = useState(false);
    const [sortColumn, setSortColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState(null);
    const [sortCounts, setSortCounts] = useState({});
    const [hoveredColumn, setHoveredColumn] = useState(null);
    const { displayAlert } = useContext(CustomAlertContext);

    const dispatch = useDispatch()

    const classes = useStyles();
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const [forms, setForms] = useState([]);

    const request = (searchTerm) => {
        if (searchTerm === "") {
            setForms(formsData);
            return;
        }
        const _new = forms.filter((form) => {
            return form?.formType
                ?.toLowerCase()
                .includes(searchTerm.toLowerCase());
        });
        setForms(_new);
        console.warn(formsData, "req data");
    };

    const cancel = () => {
        console.warn(formsData, "data");
        setForms(formsData);
    };

    const onSelect = (selected, mid) => {
        console.warn("select id", selectedForm, selected, mid);
        if (selected) {
            setSelectedForm([...selectedForm, mid]);
        } else {
            setSelectedForm(selectedForm.filter((id) => id !== mid));
        }
    };

    useEffect(() => {
        dispatch(setUserEditFormFunc(false))
        getForms();

    }, []);


    useEffect(() => {
        if (sortColumn && showSortData) {
            const sorted = [...forms].sort((a, b) => {
                if (sortColumn === "createdOn") {
                    let aDate = a?.createdDate || "N/A";
                    let bDate = b?.createdDate || "N/A";

                    let aDateFormatted = moment(aDate);
                    let bDateFormatted = moment(bDate);

                    if (moment(aDateFormatted).isBefore(bDateFormatted)) {
                        return sortDirection === "asc" ? -1 : 1;
                    }
                    if (moment(aDateFormatted).isAfter(bDateFormatted)) {
                        return sortDirection === "asc" ? 1 : -1;
                    }
                    return 0;
                }

                if (sortColumn === "name") {
                    let aName = a?.name?.toLowerCase();
                    let bName = b?.name?.toLowerCase();

                    if (aName < bName) {
                        return sortDirection === "asc" ? -1 : 1;
                    }
                    if (aName > bName) {
                        return sortDirection === "asc" ? 1 : -1;
                    }
                }

                if (a[sortColumn] < b[sortColumn]) {
                    return sortDirection === "asc" ? -1 : 1;
                }
                if (a[sortColumn] > b[sortColumn]) {
                    return sortDirection === "asc" ? 1 : -1;
                }
                return 0;
            });
            if (sorted.length > 0 && sorted.length === forms.length) {
                onSort(sorted);
            }
        }
    }, [sortColumn, sortDirection, showSortData]);

    const onSort = (sortedData) => {
        setForms(sortedData);
    };

    /* GET FORM BY COACH ID*/
    async function getForms() {
        const coachID = store.getState().auth.trainerUUID;
        const forms = await getCoachForm(coachID);
        console.warn(forms, "forms data");
        setForms(forms.result); // SETTING FORM DATA 
        formsData = forms.result; // KEEPING COPY OF FORMDATA TO HANDLE SEARCH OPERATION
        setLoading(false);
    }

    /* HANDLE SORT BY NAME AND CREATEDAT */
    const handleSort = (column) => {
        if (!showSortData) setShowSortData(true);
        const count = sortCounts[column] || 0;
        let direction = "asc";
        if (count === 0) {
            direction = "asc";
        } else if (count === 1) {
            direction = "desc";
        } else {
            setSortColumn(null);
            setSortDirection(null);
            setSortCounts({});
            return;
        }
        setSortColumn(column);
        setSortDirection(direction);
        if (sortColumn === column) {
            setSortCounts({ ...sortCounts, [column]: count + 1 });
        } else {
            setSortCounts({ [column]: 1 });
        }
    };

    /* DELETE FORM BY ID */
    const deleteFormById = async (id) => {
        setLoading(true);
        const res = await deleteForm({
            "id": id
        })
        console.warn(res, "response after delete")
        if (res.deletedTemplate) {
            displayAlert({
                message: "Form deleted successfully",
                type: "success",
            });
            getForms()
        } else {
            displayAlert({
                message: "An error occurred while deleting form.",
                type: "error",
            });
            setLoading(false)
        }
    }

    return (
        <Layout>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    gap: "8px",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        height: "calc(100vh - 110px)",
                        width: "100%",
                        borderRadius: "12px",
                        backgroundColor: "#fff",
                        overflow: "hidden",
                    }}
                >
                    {loading && (
                        <div
                            style={{
                                height: "100%",
                                width: "100%",
                                backgroundColor: "rgba(0,0,0,0.3)",
                                borderRadius: "0px 0px 16px 16px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                position: "absolute",
                                top: 0,
                                left: 0,
                                zIndex: 99,
                            }}
                        >
                            <Box
                                sx={{
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <CircularProgress
                                    size={20}
                                    sx={{ color: "#6242EA" }}
                                />
                            </Box>
                        </div>
                    )}
                    <div
                        style={{
                            padding: "16px 20px 0px 20px",
                            width: "100%",
                            borderBottom: "1px solid #E5E5E5",
                        }}
                    >
                        <div
                            style={{
                                borderRadius: 50,
                                backgroundColor: "#F6F6FE",
                                padding: "8px 24px 8px 0px",
                                display: "flex",
                                alignItems: "center",
                                gap: 16,
                                height: "56px",
                                justifyContent: "space-between",
                            }}
                        >
                            {selectedForm && selectedForm.length > 0 ? (
                                <>
                                    <span className="delete-form-text">
                                        Select Forms to delete
                                    </span>
                                    <div className="flex items-center gap-2">
                                        <div
                                            className="delete-cancel-btn"
                                            onClick={() => {
                                                setSelectedForm([]);
                                            }}
                                        >
                                            Cancel
                                        </div>
                                        <div
                                            onClick={() => setSelectedForm([])}
                                            className="delete-workouts-btn"
                                        >
                                            <img
                                                src={DeleteIcon}
                                                alt="Delete Icon"
                                                style={{
                                                    height: "24px",
                                                    width: "24px",
                                                }}
                                            />
                                            Delete {selectedForm.length} Forms
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <SearchBar
                                        placeholder="Search Form"
                                        style={{
                                            borderRadius: "80px",
                                            marginTop: 0,
                                            marginBottom: 0,
                                            boxShadow:
                                                "0px 1px 8px rgba(0, 0, 0, 0.19)",
                                        }}
                                        iconStyle={{
                                            marginLeft: 5,
                                        }}
                                        bgcol={"#FFF"}
                                        request={request}
                                        cancel={cancel}
                                    />
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: 16,
                                        }}
                                    >
                                        <button
                                            type="button"
                                            onClick={handleClickOpen}
                                            className="add-btn-input__button-gradient"
                                        >
                                            {" "}
                                            Add Form
                                        </button>
                                        {/* <img
								src={AddWorkoutBtn}
								onClick={() => console.log("onclick")}
								alt="Add Form"
								style={{
									height: "40px",
									cursor: "pointer",
								}}
								/> */}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div>
                        <ul>
                            {!loading ? (
                                forms.length <= 0 ? (
                                    <div
                                        style={{
                                            justifyContent: "center",
                                            paddingTop: 20,
                                        }}
                                    >
                                        <div style={{ textAlign: "center" }}>
                                            Search data not found
                                        </div>
                                    </div>
                                ) : (
                                    <div
                                        style={{
                                            overflowY: "scroll",
                                            width: "100%",
                                            marginBottom: 80,
                                        }}
                                    >
                                        <div
                                            style={{
                                                height: "calc(100vh - 200px)",
                                                width: "100%",
                                                marginTop: "12px",
                                            }}
                                        >
                                            <table
                                                style={{
                                                    width: "100%",
                                                }}
                                            >
                                                <thead
                                                    style={{
                                                        height: 48,
                                                        borderBottom:
                                                            "1px solid #E5E5E5",
                                                    }}
                                                >
                                                    <tr>
                                                        <th
                                                            onClick={() =>
                                                                handleSort(
                                                                    "formType"
                                                                )
                                                            }
                                                            onMouseEnter={() =>
                                                                setHoveredColumn(
                                                                    "formType"
                                                                )
                                                            }
                                                            onMouseLeave={() =>
                                                                setHoveredColumn(
                                                                    null
                                                                )
                                                            }
                                                            style={{
                                                                cursor: "pointer",
                                                                paddingLeft:
                                                                    "36px",
                                                            }}
                                                            className="table-header"
                                                        >
                                                            Name
                                                            {sortColumn ===
                                                                "formType" &&
                                                                sortDirection ===
                                                                "asc" && (
                                                                    <ArrowUpwardIcon
                                                                        sx={{
                                                                            color: "#605e5c",
                                                                        }}
                                                                        className="sort-arrow"
                                                                    />
                                                                )}
                                                            {sortColumn ===
                                                                "formType" &&
                                                                sortDirection ===
                                                                "desc" && (
                                                                    <ArrowDownwardIcon
                                                                        sx={{
                                                                            color: "#605e5c",
                                                                        }}
                                                                        className="sort-arrow"
                                                                    />
                                                                )}
                                                            {hoveredColumn ===
                                                                "formType" &&
                                                                sortColumn !==
                                                                "formType" && (
                                                                    <ArrowUpwardIcon
                                                                        sx={{
                                                                            color: "#ccc",
                                                                        }}
                                                                        className="sort-arrow"
                                                                    />
                                                                )}
                                                        </th>
                                                        <th className="table-header">
                                                            Description
                                                        </th>
                                                        <th
                                                            onClick={() =>
                                                                handleSort(
                                                                    "dateCreated"
                                                                )
                                                            }
                                                            onMouseEnter={() =>
                                                                setHoveredColumn(
                                                                    "dateCreated"
                                                                )
                                                            }
                                                            onMouseLeave={() =>
                                                                setHoveredColumn(
                                                                    null
                                                                )
                                                            }
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                            className="table-header"
                                                        >
                                                            Created on
                                                            {sortColumn ===
                                                                "dateCreated" &&
                                                                sortDirection ===
                                                                "asc" && (
                                                                    <ArrowUpwardIcon
                                                                        sx={{
                                                                            color: "#605e5c",
                                                                        }}
                                                                        className="sort-arrow"
                                                                    />
                                                                )}
                                                            {sortColumn ===
                                                                "dateCreated" &&
                                                                sortDirection ===
                                                                "desc" && (
                                                                    <ArrowDownwardIcon
                                                                        sx={{
                                                                            color: "#605e5c",
                                                                        }}
                                                                        className="sort-arrow"
                                                                    />
                                                                )}
                                                            {hoveredColumn ===
                                                                "dateCreated" &&
                                                                sortColumn !==
                                                                "dateCreated" && (
                                                                    <ArrowUpwardIcon
                                                                        sx={{
                                                                            color: "#ccc",
                                                                        }}
                                                                        className="sort-arrow"
                                                                    />
                                                                )}
                                                        </th>
                                                        <th className="table-header">
                                                            Tags
                                                        </th>
                                                        <th className="table-header"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {forms.map((item) => (
                                                        <TableRow
                                                            key={item.id}
                                                            item={item}
                                                            deleteFormById={deleteFormById}
                                                        />
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                )
                            ) : null}
                        </ul>
                    </div>
                </div>
            </Box>
            <FormDialog open={open} onClose={handleClose} />
        </Layout>
    );
}

const TableRow = ({ item, deleteFormById }) => {
    const formattedDate = moment(item.dateCreated).format("MMM DD, YY");
    const allTags = item?.tags?.length > 0 ? item.tags.split(",") : [];
    const [anchorElTags, setAnchorElTags] = useState(null);
    const openTags = Boolean(anchorElTags);
    const idTags = openTags ? "simple-popover" : undefined;
    const history = useHistory();
    const dispatch = useDispatch()
    const [showFormAssignModal, setshowFormAssignModal] = useState(false);
    const i18nInstance = new Streami18n({
        language: "en",
        translationsForLanguage: {
            ...enTranslations,
        },
    });

    const handleHoverTags = (event) => {
        setAnchorElTags(event.currentTarget);
    };

    const handleCloseTags = () => {
        setAnchorElTags(null);
    };

    const handleOpenFormEvent = () => {
        dispatch(getSingleFormDataFunc(item))
        history.push(
            "/form/" + item.id + "?formName=" + item.formType
        )
    }


    return (
        <tr key={JSON.stringify(item.id)} className={"workout-table-row"}>
            <td
                onClick={handleOpenFormEvent}
                primary={item.formType}
            >
                <Box
                    sx={{
                        marginLeft: "0",
                    }}
                    className="workout-name-container"
                >
                    <p className="workout-name"> {item.formType}</p>
                </Box>
            </td>
            <td className="workout-table-body-text " onClick={handleOpenFormEvent}>{item.description}</td>
            <td className="workout-table-body-text " onClick={handleOpenFormEvent}>{formattedDate}</td>
            <td className="workout-table-body-text " onClick={handleOpenFormEvent}>
                <div
                    style={{
                        display: "flex",
                        gap: "8px",
                        flexWrap: "wrap",
                        alignItems: "center",
                    }}
                >
                    {allTags?.map((tag, index) => {
                        if (index > 1) return <></>;
                        return <span className="tag-chip">{tag}</span>;
                    })}
                    {allTags.length - 2 > 0 && (
                        <span
                            onMouseEnter={handleHoverTags}
                            className="workout-more-exercises cursor-pointer"
                        >
                            +{allTags.length - 2} more
                        </span>
                    )}

                    <Popover
                        id={idTags}
                        open={openTags}
                        anchorEl={anchorElTags}
                        onClose={handleCloseTags}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                        slotProps={{
                            paper: {
                                sx: {
                                    borderRadius: "12px",
                                    maxHeight: "290px",
                                },
                            },
                        }}
                    >
                        <div
                            key={JSON.stringify(item.id)}
                            onMouseLeave={handleCloseTags}
                            className="popover-container"
                        >
                            {allTags?.map((t, index) => (
                                <>
                                    <p className="popover-exercise">{t}</p>
                                    {index !== allTags.length - 1 && (
                                        <Divider />
                                    )}
                                </>
                            ))}
                        </div>
                    </Popover>
                </div>
            </td>
            <td className="workout-table-body-text">
                <MenuOptions
                    key={item.id}
                    isHoriz={true}
                    options={["Assign User", "Delete form"]}
                    optionIcons={[
                        <img src={AssignUserIcon} alt="Assign User" />,

                        <img src={Delete} alt="Delete" />,
                    ]}
                    menuItemStyles={[
                        null,
                        null,
                        null,
                        null,
                        { color: "#FF3A29" },
                    ]}
                    onOptionClick={(option) => {
                        if (option == "Delete form") {
                            deleteFormById(item.id)
                        } else if (option === "Assign User") {
                            setshowFormAssignModal(true);
                        }
                    }}
                    iconBtnStyles={{
                        borderRadius: "8px",
                    }}
                />
                {showFormAssignModal && (
                    <Chat {...{ client: chatClient, i18nInstance }}>
                        <AssignUserModal
                            form={item}
                            showModal={showFormAssignModal}
                            setShowModal={setshowFormAssignModal}
                        />
                    </Chat>
                )}
            </td>
        </tr>
    );
};
export default FormControl;
