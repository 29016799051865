import SparkleLogo from "../../../assets/copilot/sparkleLogo.svg";
import ThumbsUpIcon from "../../../assets/copilot/thumbsUp.svg";
import ThumbsDownIcon from "../../../assets/copilot/thumbsDown.svg";
import ThumbsUpColoredIcon from "../../../assets/copilot/thumbsUpColored.svg";
import ThumbsDownColoredIcon from "../../../assets/copilot/thumbsDownColored.svg";
import { useContext, useEffect, useState } from "react";
import { ExerciseCard } from "./ExerciseCard";
import { WorkoutCard } from "./WorkoutCard";
import { Typography } from "@mui/material";
import {
  ASSIST_MODIFY_WORKOUTS,
  CREATE_MEAL_PLAN,
  CREATE_WORKOUT_PLAN,
  GENERATE_SINGLE_WORKOUT,
  GENERATE_RECIPE,
  GENERATE_MEAL_PLAN,
} from "../../../constants";
import MealCard from "../MealCard";
import { SingleWorkoutCard } from "./SingleWorkoutCard";
import { CopilotContext } from "../copilot";
import RecipeCard from "./RecipeCard";
import SingleMealPlanCard from "./SingleMealPlanCard";

export const BotResponse = ({
  message,
  selectedOption,
  selectedUser,
  regenerate,
  setOutput,
  showDatePicker,
  setShowDatePicker,
  showActions,
  reaction,
  setReaction,
  customMessage,
  responseIndex,
  loadingResult,
}) => {
  const [response, setResponse] = useState(JSON.parse(message));
  const [selectedIndices, setSelectedIndices] = useState([]);
  const [disableActions, setDisableActions] = useState(false);
  const { onSaveClick } = useContext(CopilotContext);

  let isDisabled =
    selectedOption === GENERATE_RECIPE && selectedIndices.length === 0;

  useEffect(() => {
    setResponse(JSON.parse(message));
  }, [message]);

  if (response?.exercises) {
    return <ExerciseCard exercises={response?.exercises} />;
  } else if (response?.["Day-1"]?.Workout) {
    return (
      <div>
        <WorkoutCard
          index={1}
          workout={response?.["Day-1"]}
          workoutName={"Workout"}
        />
      </div>
    );
  }

  return (
    <div
      style={{
        width: "269px",
        background: "linear-gradient(to right, #3D91FF, #6CEBE2, #9270FF)",
        borderRadius: "12px",
        marginBottom: "18px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "267px",

          borderRadius: "12px",
          backgroundColor: "#F8F8F8",
          padding: "14px",
          margin: "1px",
        }}
      >
        {" "}
        <Typography
          sx={{
            fontSize: "12px",
            fontFamily: "DM Sans",
            fontWeight: 400,
            marginBottom: "9px",
            textAlign: "left",
            width: "100%",
            color: "#242424",
            ml: "2px",
          }}
        >
          {customMessage
            ? customMessage
            : selectedOption === CREATE_MEAL_PLAN
            ? `Here's your generated meal plan for `
            : selectedOption === CREATE_WORKOUT_PLAN
            ? `Here's your generated workout plan for `
            : selectedOption === ASSIST_MODIFY_WORKOUTS
            ? "Here's the modified workouts for "
            : selectedOption === GENERATE_SINGLE_WORKOUT
            ? "Here's your generated workout"
            : selectedOption === GENERATE_RECIPE
            ? "Here's your generated recipe"
            : ""}

          <span
            style={{
              fontFamily: "DM Sans",
              fontWeight: 500,
              fontSize: "12px",
              color: "#242424",

              textAlign: "left",
            }}
          >
            {selectedUser?.name}.
          </span>
        </Typography>
        {selectedOption === GENERATE_MEAL_PLAN ? (
          <SingleMealPlanCard mealPlan={response} />
        ) : selectedOption === GENERATE_SINGLE_WORKOUT ? (
          <SingleWorkoutCard
            index={1}
            workout={response}
            responseIndex={responseIndex}
            workoutName={"Workout"}
            showActions={showActions}
          />
        ) : selectedOption == CREATE_WORKOUT_PLAN ||
          selectedOption == ASSIST_MODIFY_WORKOUTS ? (
          Object?.entries(response["week-1"])?.map(([key, value], index) => {
            let workoutName = value?.Workout?.title || "";
            if (value?.Workout === "Rest Day") {
              workoutName = value?.Workout;
            }
            return (
              <WorkoutCard
                key={key}
                workoutName={workoutName}
                index={index}
                responseIndex={responseIndex}
                day={key}
                workout={value}
                showActions={showActions}
              />
            );
          })
        ) : selectedOption === GENERATE_RECIPE ? (
          response?.map((recipe, index) => {
            return (
              <RecipeCard
                onSelect={(selected) => {
                  if (selected) {
                    setSelectedIndices([...selectedIndices, index]);
                  } else {
                    setSelectedIndices(
                      selectedIndices.filter((i) => i !== index)
                    );
                  }
                }}
                disableActions={disableActions}
                selected={selectedIndices.includes(index)}
                key={index}
                index={index}
                data={recipe}
              />
            );
          })
        ) : (
          Object.entries(response["week-1"]).map(([key, value], index) => {
            return <MealCard key={key} data={value} index={index} day={key} />;
          })
        )}
        {showActions && !loadingResult && !customMessage && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                background:
                  "linear-gradient(to right, rgb(70, 79, 235), rgb(131, 48, 233))",
                borderRadius: "16px",
                height: "24px",
                marginRight: "9px",
                opacity: isDisabled ? 0.5 : 1,
              }}
            >
              <button
                disabled={isDisabled}
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontFamily: "DM Sans",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "16px",
                  color: "#333",
                  padding: "0px 8px",
                  margin: 1,
                  borderRadius: "16px",
                  backgroundColor: "#F8F8F8",
                  height: "22px",
                  opacity: isDisabled ? 0.5 : 1,
                }}
                onClick={() => {
                  if (selectedOption === GENERATE_SINGLE_WORKOUT) {
                    setOutput((output) => [
                      ...output,
                      { type: "user", message: "enter name" },
                    ]);
                    onSaveClick();
                  } else if (selectedOption === GENERATE_RECIPE) {
                    onSaveClick(selectedIndices);
                    setDisableActions(true);
                  } else if (selectedOption === GENERATE_MEAL_PLAN) {
                    setOutput((output) => [
                      ...output,
                      { type: "user", message: "enter name" },
                    ]);
                    onSaveClick();
                  } else {
                    setShowDatePicker(true);
                    if (!showDatePicker) {
                      setOutput((output) => [
                        ...output,
                        { type: "user", message: "date" },
                      ]);
                    }
                  }
                }}
              >
                {selectedOption === GENERATE_SINGLE_WORKOUT ||
                selectedOption === GENERATE_RECIPE ||
                selectedOption === GENERATE_MEAL_PLAN
                  ? "Save"
                  : "Assign"}
              </button>
            </div>
            {!customMessage && (
              <div
                style={{
                  background:
                    "linear-gradient(to right, rgb(70, 79, 235), rgb(131, 48, 233))",
                  borderRadius: "16px",
                  height: "24px",
                }}
              >
                <button
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "DM Sans",
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "16px",
                    color: "#333",
                    padding: "0px 8px",
                    margin: 1,
                    borderRadius: "16px",
                    backgroundColor: "#F8F8F8",
                    height: "22px",
                  }}
                  onClick={regenerate}
                >
                  <img
                    style={{
                      height: "16px",
                      width: "16px",
                      marginRight: "4px",
                    }}
                    src={SparkleLogo}
                  />
                  Regenerate
                </button>
              </div>
            )}

            {!customMessage && (
              <div style={{ display: "flex", marginLeft: "14px" }}>
                <img
                  onClick={() => setReaction("thumbsUp")}
                  src={
                    reaction === "thumbsUp" ? ThumbsUpColoredIcon : ThumbsUpIcon
                  }
                  style={{
                    height: "20px",
                    width: "20px",
                    cursor: "pointer",
                  }}
                />

                <img
                  onClick={() => setReaction("thumbsDown")}
                  src={
                    reaction === "thumbsDown"
                      ? ThumbsDownColoredIcon
                      : ThumbsDownIcon
                  }
                  style={{
                    height: "20px",
                    width: "20px",
                    marginLeft: "8px",
                    cursor: "pointer",
                  }}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
