import { getGarminSummaryByDateRange } from "@/ApiServices/ClientDetails/Api.js";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button } from "@/components/ui/button.jsx";
import { Loader2 } from "lucide-react";
// import "./DatePicker.css"; // Custom CSS for additional styling

const GarminSummary = ({ athleteID }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [loading, setLoading] = useState(false);

  const [garminData, setGarminData] = useState([]);

  const getDataByDateRange = async () => {
    setLoading(true);
    let res = await getGarminSummaryByDateRange(athleteID, startDate, endDate);
    if (res) setGarminData(res);
    setLoading(false);
  };

  const columns = [
    "Name",
    "Date",
    "Distance (km)",
    "Time",
    "Calories",
    "Max Pace",
    "Effort Level",
    "Fatigue Level",
    "rTss",
    "tss",
    "invrTss",
  ];

  return (
    <div className="h-[calc(100vh-300px)] w-full bg-white-pure rounded-xl p-4 shadow-lg">
      <p className="text-xl font-semibold mb-4">Garmin Summary</p>
      <div className="flex space-x-4 items-end">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Start Date
          </label>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            End Date
          </label>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
          />
        </div>

        <Button
          onClick={getDataByDateRange}
          loading={loading}
          className="bg-indigo-600 text-white-pure hover:bg-indigo-700 px-4 py-2 rounded-lg shadow-md focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 w-[100px]"
          disabled={loading}
        >
          {loading ? <Loader2 /> : "Get Data"}
        </Button>
      </div>

      {/*table*/}
      <div
        className={"h-full overflow-y-auto"}
        style={{
          height: "calc(100vh - 550px)",
          overflowY: "auto",
        }}
      >
        <table className="table-auto w-full mt-4">
          <thead>
            {columns.map((col, index) => (
              <th
                key={index}
                className="border border-gray-300 px-4 py-2 text-font14 font-DMSans"
              >
                {col}
              </th>
            ))}
          </thead>
          <tbody>
            {garminData.map((data, index) => {
              let name = data?.summary?.activityName;
              let date = data?.dateRecorded
                ? new Date(data.dateRecorded).toDateString()
                : null;
              let distance = formatDistance(data?.summary?.distanceInMeters);
              let time = formatTime(data?.summary?.durationInSeconds);
              let calories = data?.summary?.activeKilocalories?.toFixed(2);
              let maxPace = formatPace(
                data?.summary?.maxPaceInMinutesPerKilometer
              );
              let effortLevel = data?.computedMetrics?.effort_level?.toFixed(2);
              let fatigueLevel =
                data?.computedMetrics?.fatigue_level?.toFixed(2);
              let rTss =
                data?.computedMetrics?.front_block_metrics?.rTSS?.toFixed(2);
              let tss =
                data?.computedMetrics?.front_block_metrics?.tss?.toFixed(2);
              let invrTss =
                data?.computedMetrics?.front_block_metrics?.invrTSS?.toFixed(2);

              return (
                <tr key={index} className="border border-gray-300">
                  <td className="px-4 py-2 font-DMSans text-font14 border-r">
                    {name}
                  </td>
                  <td className="px-4 py-2 font-DMSans text-font14 border-r">
                    {date}
                  </td>
                  <td className="px-4 py-2 font-DMSans text-font14 border-r">
                    {distance}
                  </td>
                  <td className="px-4 py-2 font-DMSans text-font14 border-r">
                    {time}
                  </td>
                  <td className="px-4 py-2 font-DMSans text-font14 border-r">
                    {calories}
                  </td>
                  <td className="px-4 py-2 font-DMSans text-font14 border-r">
                    {maxPace}
                  </td>
                  <td className="px-4 py-2 font-DMSans text-font14 border-r">
                    {effortLevel}
                  </td>
                  <td className="px-4 py-2 font-DMSans text-font14 border-r">
                    {fatigueLevel}
                  </td>
                  <td className="px-4 py-2 font-DMSans text-font14 border-r">
                    {rTss}
                  </td>
                  <td className="px-4 py-2 font-DMSans text-font14 border-r">
                    {tss}
                  </td>
                  <td className="px-4 py-2 font-DMSans text-font14 border-r">
                    {invrTss}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GarminSummary;

const formatPace = (pace) => {
  if (!pace) return "0:0";
  let min = Math.floor(pace);
  let sec = Math.floor((pace - min) * 60);
  return `${min}:${sec}`;
};

const formatTime = (seconds) => {
  if (!seconds) return "0:0:0";
  let hours = Math.floor(seconds / 3600);
  let minutes = Math.floor((seconds % 3600) / 60);
  let sec = seconds % 60;
  return `${hours}:${minutes}:${sec}`;
};

const formatDistance = (meters) => {
  if (!meters) return "0";
  return (meters / 1000).toFixed(2);
};
