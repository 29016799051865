import React from 'react'

const NoVideo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
  <path d="M10 42.0005H38C40.2091 42.0005 42 40.2097 42 38.0005V10.0001C42 7.79089 40.2091 6 38 6H10C7.79086 6 6 7.79089 6 10.0001V38.0005C6 40.2097 7.79086 42.0005 10 42.0005ZM10 42.0005L31.9999 19.9997L41.9999 29.9999M20.0001 16.9997C20.0001 18.6566 18.6569 19.9997 17.0001 19.9997C15.3432 19.9997 14.0001 18.6566 14.0001 16.9997C14.0001 15.3428 15.3432 13.9996 17.0001 13.9996C18.6569 13.9996 20.0001 15.3428 20.0001 16.9997Z" stroke="black" stroke-width="3.00002" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M10 42.0005H38C40.2091 42.0005 42 40.2097 42 38.0005V10.0001C42 7.79089 40.2091 6 38 6H10C7.79086 6 6 7.79089 6 10.0001V38.0005C6 40.2097 7.79086 42.0005 10 42.0005ZM10 42.0005L31.9999 19.9997L41.9999 29.9999M20.0001 16.9997C20.0001 18.6566 18.6569 19.9997 17.0001 19.9997C15.3432 19.9997 14.0001 18.6566 14.0001 16.9997C14.0001 15.3428 15.3432 13.9996 17.0001 13.9996C18.6569 13.9996 20.0001 15.3428 20.0001 16.9997Z" stroke="url(#paint0_linear_308_17308)" stroke-width="3.00002" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M10 42.0005H38C40.2091 42.0005 42 40.2097 42 38.0005V10.0001C42 7.79089 40.2091 6 38 6H10C7.79086 6 6 7.79089 6 10.0001V38.0005C6 40.2097 7.79086 42.0005 10 42.0005ZM10 42.0005L31.9999 19.9997L41.9999 29.9999M20.0001 16.9997C20.0001 18.6566 18.6569 19.9997 17.0001 19.9997C15.3432 19.9997 14.0001 18.6566 14.0001 16.9997C14.0001 15.3428 15.3432 13.9996 17.0001 13.9996C18.6569 13.9996 20.0001 15.3428 20.0001 16.9997Z" stroke="#B2B2B2" stroke-width="3.00002" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M10 42.0005H38C40.2091 42.0005 42 40.2097 42 38.0005V10.0001C42 7.79089 40.2091 6 38 6H10C7.79086 6 6 7.79089 6 10.0001V38.0005C6 40.2097 7.79086 42.0005 10 42.0005ZM10 42.0005L31.9999 19.9997L41.9999 29.9999M20.0001 16.9997C20.0001 18.6566 18.6569 19.9997 17.0001 19.9997C15.3432 19.9997 14.0001 18.6566 14.0001 16.9997C14.0001 15.3428 15.3432 13.9996 17.0001 13.9996C18.6569 13.9996 20.0001 15.3428 20.0001 16.9997Z" stroke="url(#paint1_linear_308_17308)" stroke-width="3.00002" stroke-linecap="round" stroke-linejoin="round"/>
  <defs>
    <linearGradient id="paint0_linear_308_17308" x1="26" y1="42.0001" x2="26" y2="19.9997" gradientUnits="userSpaceOnUse">
      <stop offset="0.104167" stop-color="#464FEB"/>
      <stop offset="1" stop-color="#8330E9"/>
    </linearGradient>
    <linearGradient id="paint1_linear_308_17308" x1="5.92589" y1="41.6034" x2="38.3112" y2="2.99306" gradientUnits="userSpaceOnUse">
      <stop stop-color="#9AD9FE"/>
      <stop offset="1" stop-color="#E6F6FF"/>
    </linearGradient>
  </defs>
</svg>
  )
}

export default NoVideo