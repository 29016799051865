import React, { useContext, useRef, useState } from "react";
import EditFolderInfo from "./EditFolderInfo";
import DropDownMenu from "../Programs/DropDownMenu";
import { workoutContext } from "./WorkoutContex";
import MenuOptions from "./MenuOptions";
import AssignUserIcon from "../../assets/workouts/AssignUserIcon.svg";
import Delete from "../../assets/workouts/DeleteIcon.svg";
import EditIcon from "../../assets/workouts/EditIcon.svg";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import NewAssignWorkoutsModal from "./NewAssignWorkoutsModal";
import { Chat, Streami18n, enTranslations } from "stream-chat-react";
import { chatClient } from "@/utils/chat";
import { useHistory } from "react-router-dom";
import { CirclePlus } from "lucide-react";
import { UserPlus } from "lucide-react";

import FollowThroughView from "@/pages/library/showWorkoutsCF/FollowThroughView";
import WorkoutContentPage from "@/pages/library/showWorkoutsCF/WorkoutContentPage";
import SelectFolderDialogInWorkout from "./SelectFolderDialogInWorkout";
import { data } from "autoprefixer";
import DuplicateWorkoutDialog from "./DuplicateWorkoutDialog";

function WorkoutInfo({ getWorkout }) {
  const {
    workoutSelected: item,
    deleteWorkout,
    handleAddingToFolder,
    setDialogToSelectFolder,
    dialogToSelectFolder,
    folders,
    setOriginalData,
    setWorkoutSelected,
    setFolders,
    folderSelected,
    setTempFolderData,
    setTempData,
    setShowDuplicateWorkoutModal,
    showDuplicateWorkoutModal,
    coachID,
  } = useContext(workoutContext);
  const workoutContentRef = useRef(null);
  const [showWorkoutAssignModal, setShowWorkoutAssignModal] = useState(false);
  const history = useHistory();
  const i18nInstance = new Streami18n({
    language: "en",
    translationsForLanguage: {
      ...enTranslations,
    },
  });

  const options = [
    "Duplicate Workout",
    "Delete Workout",
    typeof item?.workoutFolderId !== "string" && "Add To Folder",
  ];

  const optionsForAdminWorkout = ["Duplicate Workout"];
  return (
    <div className="w-full">
      <div className="w-full border-b">
        <div className="w-full h-[51px] flex items-center justify-between px-3  ">
          <div className="flex items-center gap-3">
            <p className="font-DMSans text-font16">{item?.name}</p>

            {/* <EditFolderInfo selectedProgramData={item} /> */}
          </div>
          <div className="flex gap-3">
            <div
              className="flex items-center justify-center w-full h-full my-auto"
              onClick={() => {
                setShowWorkoutAssignModal(true);
              }}
            >
              <UserPlus className="text-[#504beb]" />
            </div>
            {item?.coachID === coachID && (
              <button
                className="button-new-outlined"
                style={{ width: "60px" }}
                onClick={() => {
                  let isEndurance = ["run", "bike"].includes(item?.workoutKind);
                  if (!item?.isBlockWorkoutBuilder && isEndurance) {
                    history.push("/EnduranceWorkoutBuilder", {
                      data: item,
                      isUpdate: true,
                    });
                  } else if (item?.isStructured) {
                    if (workoutContentRef?.current) {
                      workoutContentRef.current.setShowWorkoutBuilderModal();
                    }
                  } else {
                    if (workoutContentRef?.current) {
                      workoutContentRef.current.setShowWorkoutInfoModal();
                    }
                  }
                }}
              >
                Edit
              </button>
            )}
            <MenuOptions
              isHoriz={false}
              className={"mt-1 mr-2"}
              options={
                item?.coachID === coachID ? options : optionsForAdminWorkout
              }
              optionIcons={[
                <ContentCopyRoundedIcon />,
                item?.coachID === coachID && <img src={Delete} alt="Delete" />,
                item?.coachID === coachID &&
                  typeof item?.workoutFolderId !== "string" && <CirclePlus />,
              ]}
              menuItemStyles={[null, { color: "#FF3A29" }, null]}
              onOptionClick={(option) => {
                if (option === "Delete Workout") {
                  deleteWorkout(item);
                } else if (option === "Edit Workout") {
                  if (
                    !item?.isBlockWorkoutBuilder &&
                    (item?.workoutKind === "run" ||
                      item?.workoutKind === "bike")
                  ) {
                    history.push("/EnduranceWorkoutBuilder", {
                      data: item,
                      isUpdate: true,
                    });
                  } else {
                    history.push(`/workoutBuilder`, {
                      state: {
                        data: item,
                        isUpdate: true,
                        isStructured:
                          item?.isStructured !== undefined ||
                          item?.isAssign !== null
                            ? item?.isStructured
                            : true,
                      },
                    });
                  }
                } else if (option === "Edit and Assign") {
                  history.push(`/workoutBuilder`, {
                    state: {
                      data: item,
                      isAssign: true,
                    },
                  });
                } else if (option === "Assign User") {
                  setShowWorkoutAssignModal(true);
                } else if (option === "Duplicate Workout") {
                  setShowDuplicateWorkoutModal(true);
                } else if (option === "Add To Folder") {
                  setDialogToSelectFolder(true);
                }
              }}
              iconBtnStyles={{
                borderRadius: "8px",
              }}
            />
          </div>
        </div>
        {showWorkoutAssignModal && (
          <Chat {...{ client: chatClient, i18nInstance }}>
            <NewAssignWorkoutsModal
              workout={item}
              showModal={showWorkoutAssignModal}
              setShowModal={setShowWorkoutAssignModal}
            />
          </Chat>
        )}
      </div>

      <div
        style={{
          maxHeight: "80vh",
          overflowX: "auto",

          position: "relative",
          borderBottom: "1px solid #ebebeb",
        }}
      >
        {Object.keys(item || {}).length > 0 &&
          (item.isStructured !== false ? (
            <WorkoutContentPage
              rounds={item?.workoutContent?.rounds}
              data={item}
              // setData={setworkoutList}
              isImport={false}
              // date={date}
              userUUID={""}
              isThresholdAvailable={false}
              isCalendar={false}
              showBackButton={false}
              canDelete={false}
              fromWorkout={true}
              showEditButton={false}
              ref={workoutContentRef}
              onSuccessOfEditWokroutFromWorkout={(data) => {
                if (typeof data?.workoutFolderId === "string") {
                  setFolders((prev) => {
                    const oldData = [...prev];
                    const newData = oldData?.map((folder) => {
                      if (folder?.id === folderSelected?.id) {
                        if (folder?.workouts) {
                          const workoutsData = [...folder.workouts];
                          const newWorkoutData = workoutsData?.map(
                            (workout) => {
                              if (workout?.id === item?.id) {
                                return data;
                              }
                              return workout;
                            }
                          );
                          folder.workouts = newWorkoutData;
                        }
                        return folder;
                      }
                      return folder;
                    });

                    return newData;
                  });

                  setTempFolderData((prev) => {
                    const oldData = [...prev];
                    const newData = oldData?.map((folder) => {
                      if (folder?.id === folderSelected?.id) {
                        if (folder?.workouts) {
                          const workoutsData = [...folder.workouts];
                          const newWorkoutData = workoutsData?.map(
                            (workout) => {
                              if (workout?.id === item?.id) {
                                return data;
                              }
                              return workout;
                            }
                          );
                          folder.workouts = newWorkoutData;
                        }
                        return folder;
                      }
                      return folder;
                    });
                    return newData;
                  });
                  setWorkoutSelected(data);
                  return;
                }

                setOriginalData((prev) => {
                  let oldData = [...prev];
                  let newData = oldData?.map((workout) => {
                    if (workout?.id === data?.id) {
                      return data;
                    }
                    return workout;
                  });

                  return newData;
                });
                setTempData((prev) => {
                  let oldData = [...prev];
                  let newData = oldData?.map((workout) => {
                    if (workout?.id === data?.id) {
                      return data;
                    }
                    return workout;
                  });
                  setTempData(newData);
                  return newData;
                });
                setWorkoutSelected(data);
              }}
            />
          ) : (
            <FollowThroughView
              data={item}
              userUUID={""}
              showBackButton={false}
              isImport={false}
              // date={""}
              isCalendar={false}
              canDelete={false}
              fromWorkout={true}
              showEditBtn={false}
              ref={workoutContentRef}
              onSuccessOfEditWokroutFromWorkout={(data) => {
                if (typeof data?.workoutFolderId === "string") {
                  setFolders((prev) => {
                    const oldData = [...prev];
                    const newData = oldData?.map((folder) => {
                      if (folder?.id === folderSelected?.id) {
                        if (folder?.workouts) {
                          const workoutsData = [...folder.workouts];
                          const newWorkoutData = workoutsData?.map(
                            (workout) => {
                              if (workout?.id === item?.id) {
                                return data;
                              }
                              return workout;
                            }
                          );
                          folder.workouts = newWorkoutData;
                        }
                        return folder;
                      }
                      return folder;
                    });

                    return newData;
                  });

                  setTempFolderData((prev) => {
                    const oldData = [...prev];
                    const newData = oldData?.map((folder) => {
                      if (folder?.id === folderSelected?.id) {
                        if (folder?.workouts) {
                          const workoutsData = [...folder.workouts];
                          const newWorkoutData = workoutsData?.map(
                            (workout) => {
                              if (workout?.id === item?.id) {
                                return data;
                              }
                              return workout;
                            }
                          );
                          folder.workouts = newWorkoutData;
                        }
                        return folder;
                      }
                      return folder;
                    });

                    return newData;
                  });

                  setWorkoutSelected(data);
                  return;
                }
                setOriginalData((prev) => {
                  let oldData = [...prev];
                  let newData = oldData?.map((workout) => {
                    if (workout?.id === data?.id) {
                      return data;
                    }
                    return workout;
                  });

                  return newData;
                });

                setTempData((prev) => {
                  let oldData = [...prev];
                  let newData = oldData?.map((workout) => {
                    if (workout?.id === data?.id) {
                      return data;
                    }
                    return workout;
                  });

                  return newData;
                });
                setWorkoutSelected(data);
              }}
            />
          ))}
      </div>
      {dialogToSelectFolder && (
        <SelectFolderDialogInWorkout
          open={dialogToSelectFolder}
          setOpen={setDialogToSelectFolder}
          selectedProgram={item}
          folders={folders}
        />
      )}
      {showDuplicateWorkoutModal && (
        <DuplicateWorkoutDialog
          isOpen={showDuplicateWorkoutModal}
          setIsOpen={setShowDuplicateWorkoutModal}
          workout={item}
          getWorkouts={getWorkout}
        />
      )}
    </div>
  );
}

export default WorkoutInfo;
