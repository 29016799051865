import { combineReducers } from "@reduxjs/toolkit";
import exerciseListReducer from "./exerciseListSlice";
import authReducer from "./authSlice";
import workoutsSlice from "./workoutsSlice";
import { getFitApi } from "../services/apiSlice";
import dataSlice from "./dataSlice";
import programsSlice from "./programsSlice";
import popupStateSlice from "./popupStateSlice";
import checkListSlice from "./checkListSlice";
import clientsSlice from "./clientsSlice";
import formSlice from "./formSlice";
const rootReducer = combineReducers({
  exerciseList: exerciseListReducer,
  auth: authReducer,
  data: dataSlice,
  workouts: workoutsSlice,
  [getFitApi.reducerPath]: getFitApi.reducer,
  programs: programsSlice,
  popupState: popupStateSlice,
  toggleCheckList: checkListSlice,
  client: clientsSlice,
  userForms: formSlice
});

export default rootReducer;
