import { Dialog, DialogActions, DialogContent } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import React, { useState } from "react";
import Input from "@/components/InputComponents/Input";
import TextArea from "@/components/InputComponents/TextArea";
import { useSelector } from "react-redux";

const DuplicateProgramsDialog = ({
  isOpen,
  setIsOpen,
  selectedProgramData,
  duplicateOneProgram,
  title,
}) => {
  const [programName, setProgramName] = useState(
    selectedProgramData?.name + "(copy)" || ""
  );
  const [description, setDescription] = useState(
    selectedProgramData?.description || ""
  );

  const coachID = useSelector((state) => state.auth.trainerUUID);

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "20px",
        },
      }}
    >
      <div className="add-program-modal-head">
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <p className="add-program-modal-head-title">
            {title ?? "Create Duplicate Program"}
          </p>
        </div>
        <div onClick={() => setIsOpen(false)}>
          <CloseRoundedIcon sx={{ cursor: "pointer" }} />
        </div>
      </div>
      <DialogContent>
        <div className="flex flex-col w-full gap-2">
          <Input
            label="Program Name"
            value={programName}
            onChange={(e) => setProgramName(e.target.value)}
            key={selectedProgramData?.id}
          />
          <TextArea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            numRows={4}
          />
        </div>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <button
          onClick={() => {
            duplicateOneProgram(
              selectedProgramData?.id,
              programName,
              description,
              coachID,
              selectedProgramData?.cFProgramPhasesContainerId
            );

            setIsOpen(false);
          }}
          className="button-new"
        >
          Save
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default DuplicateProgramsDialog;
