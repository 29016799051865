import React from "react";
import { ShowOneMeal } from "../../../components/Nutrition/NItemDetails/NItemDetails";
import { Stack } from "@mui/material";
import { DeleteRounded } from "@mui/icons-material";

const RenderMeal = ({ meal, feedback, deleteMealPlan, mealID, isFinished }) => {
  console.log("render meal: ", meal);
  if (meal.plandetails) {
    const type = meal.meal_plan_type;
    return (
      <Stack
        sx={{
          bgcolor: "#fff",
          borderRadius: "12px",
          boxShadow: "0px 4px 4px 0px #00000008",
          width: "100%",
        }}
        gap={"1rem"}
      >
        {/* <
          className="section-header"
          sx={{
            background: "transparent",
            position: "relative",
            height: "48px",
          }}
        > */}
        <div className="assigned-meals-outer-div"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0.8rem 1rem",
            borderBottom: "1px solid #EDEDED",
          }}
        >
          <p
            className="testchannel"
            style={{
              fontFamily: "DM Sans",
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            {meal?.reference_name || meal?.day}
          </p>

          <div className={'flex items-center gap-2'}>
            {isFinished && <div className={'flex items-center gap-2.5 [background:#69F38F] px-4 py-[7px] rounded-[18px] text-font12 text-[#242424]'}>
              Completed
            </div>}
            <div className="delete-workouts-btn">
              <DeleteRounded
                style={{
                  color: "#ff3a29",
                  cursor: "pointer",
                }}
                onClick={() => deleteMealPlan(mealID)}
              />
            </div>
          </div>
        </div>




        {meal?.plandetails?.meals?.map((meal, index) => (
          <ShowOneMeal meal={meal} mealType={type} />
        ))}
      </Stack>
    );
  } else {
    return (
      <Stack
        sx={{
          padding: "20px",
          bgcolor: "#fff",
          borderRadius: "12px",
        }}
        gap={"20px"}
      >
        <Stack
          direction={"row"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          mb={"10px"}
          className="section-header"
          p={"0px"}
          sx={{
            background: "transparent",
            position: "relative",
            // height: "48px",
          }}
        >
          <div className="section-header-left">
            <p
              className="testchannel"
              style={{
                marginRight: "auto",
                fontFamily: "DM Sans",
                fontSize: "18px",
              }}
            >
              {meal?.reference_name || meal?.day}
            </p>
          </div>
        </Stack>
        {meal?.meals?.map((meal, index) => (
          <ShowOneMeal meal={meal} mealType={"FoodBased"} />
        ))}
      </Stack>
    );
  }
};

export default RenderMeal;
