import { cn } from "@/lib/utils.js";
import LoadingTrainerList from "./LoadingTrainerList.jsx";
import SearchBar from "@/components/Dashboard/SearchBar.js";
import InviteTrainer from "@/components/TopBar/InviteTrainer.js";
import React, { useState } from "react";
import WhitePlusIcon from "@/pages/library/assests/WhitePlusIcon.js";

import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { MenuItem, Popover } from "@mui/material";

import FilterIcon from "@/assets/workoutBuilder/filterIcon.svg";
import ActiveFilterIcon from "@/assets/workouts/ActiveFunnel.svg";
import { UserPlus } from "lucide-react";

const TrainersList = ({
  isLivezy,
  trainers,
  atheletes,
  selected,
  selectedAthlete,
  onSelect,
  onAthleteSelect,
  loading,
  handleSearch,
  onPendingClick,
  isPending,
  getDataForAdminDashboard,
  selectedTab,
  setSelectedTab,
  handleFilterAtheletes,
  onTransferAthlete,
}) => {
  const [showAddTrainer, setShowAddTrainer] = useState(false);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);

  const open = Boolean(filterAnchorEl);
  const id = open ? "simple-popover" : undefined;

  const [selectedFilter, setSelectedFilter] = useState("");

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleOptionClick = (option) => {
    if (option === selectedFilter) {
      handleFilterAtheletes("clear");
      setSelectedFilter("");
      setIsFilterActive(false);
      handleFilterClose();
      return;
    } else {
      handleFilterAtheletes(option);
      if (option === "clear") {
        setSelectedFilter("");
        setIsFilterActive(false);
        handleFilterClose();
        return;
      }
      setSelectedFilter(option);
      setIsFilterActive(true);
      handleFilterClose();
    }
  };

  return (
    <div>
      <div className="w-full border-b font-DMSans relative h-[48px] flex items-center">
        {/*<p className={'text-font16 font-DMSans font-semibold'}>Trainers</p>*/}
        <SearchBar
          request={handleSearch}
          cancel={() => handleSearch("")}
          bgcol={"#F2F2F2"}
          width={"100%"}
          conatinerStyle={{ width: "100%" }}
          placeholder={
            selectedTab === "trainers" ? "Search Trainers" : "Search Athletes"
          }
        />

        {selectedTab === "trainers" ? (
          !isLivezy && (
            <p
              className={cn(
                "font-DMSans text-font12 bg-gray-200 hover:bg-gray-300 text-black-pure rounded-xl px-2 mr-1 cursor-pointer",
                {
                  "bg-gradient-to-r from-[#464feb] to-[#8330e9] text-white-pure":
                    isPending,
                }
              )}
              onClick={onPendingClick}
            >
              Pending
            </p>
          )
        ) : (
          <img
            src={isFilterActive ? ActiveFilterIcon : FilterIcon}
            className="cursor-pointer h-[30px] w-[30px] mr-2"
            onClick={handleFilterClick}
            alt=""
          />
        )}
      </div>

      <Tabs
        defaultValue="trainers"
        className="w-full"
        onValueChange={(value) => {
          setSelectedTab(value);
          if (value === "trainers") {
            onAthleteSelect(null);
          } else {
            onSelect(null);
          }
        }}
      >
        {isLivezy && (
          <TabsList className="w-full">
            <TabsTrigger className="w-full" value="trainers">
              Trainers
            </TabsTrigger>
            <TabsTrigger className="w-full" value="athletes">
              Athletes
            </TabsTrigger>
          </TabsList>
        )}
        <TabsContent value="trainers">
          <div
            className="p-1 space-y-1 overflow-y-auto"
            style={{
              height: "calc(100vh - 210px)",
            }}
          >
            {loading ? (
              <LoadingTrainerList />
            ) : (
              <>
                {trainers?.map(
                  (
                    trainer,
                    index //linear-gradient(90deg,#464feb1a 6.42%,#8330e91a)
                  ) => (
                    <div
                      key={index}
                      className={cn(
                        "flex gap-2 items-center py-2 px-2 font-DMSans hover:bg-gray-100 cursor-pointer min-h-[52px] rounded-xl",
                        {
                          "bg-gradient-to-r from-[#464feb1a] to-[#8330e91a]":
                            selected?.id === trainer?.id,
                        }
                      )}
                      onClick={() => onSelect(trainer)}
                    >
                      {selected?.id === trainer?.id && (
                        <div className="channel-preview__wrapper_indicator__selected"></div>
                      )}
                      <span className="font-DMSans text-font16">
                        {trainer.name}
                      </span>
                    </div>
                  )
                )}
              </>
            )}
          </div>
        </TabsContent>
        <TabsContent value="athletes">
          <div
            className="p-1 space-y-1 overflow-y-auto"
            style={{
              height: "calc(100vh - 210px)",
            }}
          >
            {loading ? (
              <LoadingTrainerList />
            ) : (
              <>
                {atheletes?.map(
                  (
                    athlete,
                    index //linear-gradient(90deg,#464feb1a 6.42%,#8330e91a)
                  ) => (
                    <div
                      key={index}
                      className={cn(
                        "flex gap-2 items-center py-2 px-2 font-DMSans hover:bg-gray-100 cursor-pointer min-h-[52px] rounded-xl group",
                        {
                          "bg-gradient-to-r from-[#464feb1a] to-[#8330e91a]":
                            selectedAthlete?.id === athlete?.id,
                        }
                      )}
                      onClick={() => onAthleteSelect(athlete)}
                    >
                      {selectedAthlete?.id === athlete?.id && (
                        <div className="channel-preview__wrapper_indicator__selected"></div>
                      )}
                      <span className="font-DMSans text-font16">
                        {athlete.name}
                      </span>
                      <UserPlus
                        size={20}
                        className="hidden group-hover:block cursor-pointer ml-auto"
                        onClick={(e) => {
                          e.stopPropagation();
                          onTransferAthlete(athlete);
                        }}
                      />
                    </div>
                  )
                )}
              </>
            )}
          </div>
        </TabsContent>
      </Tabs>
      <div
        className="gradient-background rounded-full p-2 absolute bottom-1 right-1"
        onClick={() => setShowAddTrainer(true)}
      >
        <WhitePlusIcon />
      </div>

      {showAddTrainer && (
        <InviteTrainer
          open={showAddTrainer}
          setOpen={setShowAddTrainer}
          onSuccess={getDataForAdminDashboard}
        />
      )}

      <Popover
        id={id}
        open={open}
        anchorEl={filterAnchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {options?.map((option) => (
          <MenuItem
            sx={{
              fontSize: "12px",
              fontFamily: "DM Sans",
              fontWeight: 500,
              background:
                selectedFilter === option &&
                "linear-gradient(to right, rgba(70, 79, 235, 0.4), rgba(131, 48, 233, 0.4))",
            }}
            key={option}
            onClick={() => handleOptionClick(option)}
          >
            {option}
          </MenuItem>
        ))}
        <MenuItem
          sx={{
            fontSize: "12px",
            fontFamily: "DM Sans",
            fontWeight: 500,
            color: "#e65e5e",
          }}
          key={"clear"}
          onClick={() => {
            handleOptionClick("clear");
          }}
        >
          Clear filter
        </MenuItem>
      </Popover>
    </div>
  );
};

export default TrainersList;

const options = [
  "Questionnaire filled",
  "Physique pictures received",
  "Plan sent",
  "Intro call done",
  "Plan start",
  "Delay onboarding",
];
