import { Dialog, DialogContent } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import React from "react";
import { ShowOneMeal } from "../Nutrition/NItemDetails/NItemDetails";
import { FOOD_BASED } from "../../constants";

const ShowAIMealPlan = ({ isOpen, setIsOpen, mealPlan }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "20px",
        },
      }}
    >
      <div className="add-program-modal-head">
        <p className="add-program-modal-head-title">Meal Plan</p>
        <div className="flex items-center gap-3">
          <div
            style={{
              cursor: "pointer",
            }}
            onClick={() => setIsOpen(false)}
          >
            <CloseRoundedIcon />
          </div>
        </div>
      </div>
      <DialogContent>
        <ShowTotalMacros
          macros={mealPlan?.macros}
          calories={mealPlan?.calories}
        />
        {mealPlan?.meals?.map((meal, index) => (
          <ShowOneMeal key={index} meal={meal} mealType={FOOD_BASED} />
        ))}
      </DialogContent>
    </Dialog>
  );
};

const ShowTotalMacros = ({ macros, calories }) => {
  return (
    <div className="flex flex-col items-center gap-3">
      <p className="font-DMSans text-xl font-bold">Total Macros</p>
      <div className="flex items-center gap-3">
        <p>Protein: {macros.protein}</p>
        <p>Carbs: {macros.carbs}</p>
        <p>Fats: {macros.fat}</p>
      </div>
      {/* totoal calories */}
      <p className="font-DMSans font-semibold">Total Calories: {calories}</p>
    </div>
  );
};

export default ShowAIMealPlan;
