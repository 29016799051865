import React, { useContext, useEffect, useRef, useState } from "react";
import { ProgramsContext } from "./ProgramsContext";
import { extractProgramData } from "./helper";
import ProgramHeader from "./ProgramHeader";
import ProgramWeeks from "./ProgramWeeks";
import AssignedUsersDialog from "@/pages/library/programsCF/AssignedUsersDialog";
import { Chat, Streami18n, enTranslations } from "stream-chat-react";
import NewAssignProgramModal from "@/pages/library/programsCF/NewAssignProgramModal";
import { chatClient } from "@/utils/chat";
import DuplicateProgramsDialog from "./DuplicateProgramsDialog";
import PlusIcon from "@/pages/library/assests/PlusIcon";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { SpurfitCircularProgress } from "@/components/Dashboard/dashboard";
import NoPrograms from "./NoPrograms";
import ProgramPhaseUi from "./ProgramPhaseUi";
import FolderHeader from "./FolderHeader";
import NoProgramSelected from "./NoProgramSelected";

const i18nInstance = new Streami18n({
  language: "en",
  translationsForLanguage: {
    ...enTranslations,
  },
});

const ProgramInfo = () => {
  const {
    coachID,
    adminID,
    programs,
    templates,
    selectedProgramData,
    isProgramsListOpen,
    addedWorkouts,
    setAddedWorkouts,
    daysMessages,
    setDaysMessages,
    daysTargets,
    setDaysTargets,
    totalWorkouts,
    setTotalWorkouts,
    weeks,
    setWeeks,
    weeksArray,
    setWeeksArray,
    daysArray,
    setDaysArray,
    setOriginalDays,
    setOriginalWeeks,
    selectedCard,
    programSubscribers,
    setProgramSubscribers,
    programChannels,
    setProgramChannels,
    getSubscribers,
    duplicateOneProgram,
    loadingSelectedProgram,
    addProgram,
    selectedTab,
    selectedProgramWithPhases,
    setSelectedProgramWithPhases,
    selectedProgram,
    setShowDuplicateProgramModal,
    showDuplicateProgramModal,
    duplicateData,
    setDuplicateData,
    folderSelected,
    templateFolders,
    folders
  } = useContext(ProgramsContext);

  const [showAssignedUsersDialog, setShowAssignedUsersDialog] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);

  const programsStates = useSelector((state) => state.programs.programsStates);
  const location = useLocation();
  const isSubbordinateProgram =
    selectedProgramData?.coachID !== coachID &&
    selectedProgramData?.coachID !== adminID;

  const isAdminProgram = selectedProgramData?.coachID === adminID;
  const scrollDownRef = useRef();
  const getProgramInfo = () => {
    if (
      selectedProgramData?.isNew ||
      (programsStates?.finished &&
        location?.state?.workoutCreatedForThisProgram) ||
      selectedProgramWithPhases?.id === selectedProgram?.id
    ) {
      setAddedWorkouts([]);
      setDaysMessages({});
      setDaysTargets({});

      return;
    }
    let {
      toProgramsCreate: data,
      daysMessages,
      daysTargets,
      originalDays,
      originalWeeks,
    } = extractProgramData(selectedProgramData);

    setAddedWorkouts(data || []);
    setDaysMessages(daysMessages);
    setDaysTargets(daysTargets);
    setOriginalDays(originalDays);
    setOriginalWeeks(originalWeeks);
    console.log("originalDays", originalDays);
  };

  const getTotalWorkouts = () => {
    let total = 0;
    Object.keys(addedWorkouts).forEach((key) => {
      if (addedWorkouts[key]?.[0]?.rest) {
      } else {
        total += addedWorkouts[key]?.length ?? 0;
      }
    });
    setTotalWorkouts(total);
  };

  useEffect(() => {
    getTotalWorkouts();
  }, [addedWorkouts]);

  useEffect(() => {
    let duration = selectedProgramData?.duration ?? 7;
    if (duration) {
      let weeks = Math.ceil(duration / 7);
      setWeeks(weeks);

      let totalWeekDays = weeks * 7;
      // days array will have days from 1 to totalWeekDays
      let daysArray = Array.from({ length: totalWeekDays }, (_, i) => i + 1);
      let weeksArray = Array.from({ length: weeks }, (_, i) => i + 1);
      setWeeksArray(weeksArray);

      setDaysArray(daysArray);
    }
    if (Object.keys(selectedProgramData).length > 0) {
      getProgramInfo();
    }
  }, [selectedProgramData, selectedProgram]);

  const addWeek = () => {
    let newWeek = weeks + 1;
    setWeeks(newWeek);
    let totalWeekDays = newWeek * 7;
    // days array will have days from 1 to totalWeekDays
    let daysArray = Array.from({ length: totalWeekDays }, (_, i) => i + 1);
    let weeksArray = Array.from({ length: newWeek }, (_, i) => i + 1);
    // console.log("reff",scrollDownRef?.current);
    let timeOut = setTimeout(() => {
      if (scrollDownRef.current) {
        scrollDownRef.current.scrollTop = scrollDownRef.current.scrollHeight;
      }
      clearTimeout(timeOut);
    }, 100);
    setWeeksArray(weeksArray);
    setDaysArray(daysArray);
  };
  if (
    selectedTab === "templates" &&
    templates?.length === 0 &&
    templateFolders?.length === 0
  ) {
     return <NoPrograms addProgram={addProgram} selectedTab={selectedTab} />;
  }else if(
    selectedTab === "programs" && programs?.length === 0 &&  folders?.length === 0 
  ){
    return <NoPrograms addProgram={addProgram} selectedTab={selectedTab} />;
  }

  // if (
  //   programs?.length === 0 &&
  //   templates?.length === 0 &&
  //   folders?.length === 0 && 
  //   templateFolder?.length === 0
  // ) {
  //   return <NoPrograms addProgram={addProgram} selectedTab={selectedTab} />;
  // }
  //this will render the phases
  if (selectedProgram?.programs) {
    return (
      <>
        <ProgramPhaseUi
          isProgramsListOpen={isProgramsListOpen}
          name={selectedProgram?.name}
          totalWorkouts={totalWorkouts}
          setShowAssignedUsersDialog={setShowAssignedUsersDialog}
          setShowAssignModal={setShowAssignModal}
          setShowDuplicateProgramModal={setShowDuplicateProgramModal}
          weeks={weeks}
          isSubbordinateProgram={isSubbordinateProgram}
          isAdminProgram={isAdminProgram}
        />
        {showAssignModal && (
          <Chat {...{ client: chatClient, i18nInstance }}>
            <NewAssignProgramModal
              showModal={showAssignModal}
              setShowModal={setShowAssignModal}
              activeProgram={selectedProgramData?.id}
              programName={selectedProgramData?.name}
              key={selectedProgramData?.id}
              getProgramDetails={() => getSubscribers(selectedProgramData?.id)}
            />
          </Chat>
        )}
        {showDuplicateProgramModal && (
          <DuplicateProgramsDialog
            isOpen={showDuplicateProgramModal}
            setIsOpen={setShowDuplicateProgramModal}
            // the go will be different from here
            selectedProgramData={duplicateData}
            duplicateOneProgram={duplicateOneProgram}
          />
        )}

        {showAssignedUsersDialog && (
          <AssignedUsersDialog
            isOpen={showAssignedUsersDialog}
            setIsOpen={setShowAssignedUsersDialog}
            programName={selectedProgramData?.name}
            day={selectedCard}
            programAssignedUsers={programSubscribers}
            setProgramAssignedUsers={setProgramSubscribers}
            programAssignedChannels={programChannels}
            setProgramAssignedChannels={setProgramChannels}
            isPhasedProgram={true}
          />
        )}
      </>
    );
  }

  if (
    Object.keys(folderSelected || {})?.length > 0 &&
    !(Object.keys(selectedProgram || {})?.length > 0)
  ) {
    return (
      <>
        <FolderHeader
          name={folderSelected?.name}
          isAdminFolder={folderSelected?.coachID === adminID}
        />
      </>
    );
  }

  if (Object.keys(selectedProgram || {})?.length === 0) {
    return (
      <NoProgramSelected addProgram={addProgram} selectedTab={selectedTab} />
    );
  }

  return (
    <div
      style={{
        width: isProgramsListOpen ? "75%" : "100%",
      }}
      className="h-full"
      key={selectedProgramData?.id}
    >
      <ProgramHeader
        key={selectedProgramData?.id}
        name={selectedProgramData?.name}
        totalWorkouts={totalWorkouts}
        setShowAssignedUsersDialog={setShowAssignedUsersDialog}
        setShowAssignModal={setShowAssignModal}
        setShowDuplicateProgramModal={setShowDuplicateProgramModal}
        weeks={weeks}
        isSubbordinateProgram={isSubbordinateProgram}
        isAdminProgram={isAdminProgram}
      />

      <ProgramWeeks
        key={selectedProgramData?.id}
        daysArray={daysArray}
        weeksArray={weeksArray}
        weeks={weeks}
        setWeeks={setWeeks}
        addedWorkouts={addedWorkouts}
        daysMessages={daysMessages}
        daysTargets={daysTargets}
        disableActions={isSubbordinateProgram || isAdminProgram}
        scrollDownRef={scrollDownRef}
      />

      {/* add weeks */}
      {loadingSelectedProgram ? (
        <div className="flex justify-center items-center mt-4 px-3">
          <SpurfitCircularProgress />
        </div>
      ) : (
        !isSubbordinateProgram &&
        !isAdminProgram && (
          <div className="flex justify-center items-center mt-4 px-3">
            <button className="button-new-outlined" onClick={addWeek}>
              <PlusIcon /> Add Week
            </button>
          </div>
        )
      )}

      {showAssignedUsersDialog && (
        <AssignedUsersDialog
          isOpen={showAssignedUsersDialog}
          setIsOpen={setShowAssignedUsersDialog}
          programName={selectedProgramData?.name}
          day={selectedCard}
          programAssignedUsers={programSubscribers}
          setProgramAssignedUsers={setProgramSubscribers}
          programAssignedChannels={programChannels}
          setProgramAssignedChannels={setProgramChannels}
        />
      )}

      {showAssignModal && (
        <Chat {...{ client: chatClient, i18nInstance }}>
          <NewAssignProgramModal
            showModal={showAssignModal}
            setShowModal={setShowAssignModal}
            activeProgram={selectedProgramData?.id}
            programName={selectedProgramData?.name}
            key={selectedProgramData?.id}
            getProgramDetails={() => getSubscribers(selectedProgramData?.id)}
          />
        </Chat>
      )}

      {showDuplicateProgramModal && (
        <DuplicateProgramsDialog
          isOpen={showDuplicateProgramModal}
          setIsOpen={setShowDuplicateProgramModal}
          selectedProgramData={
            selectedProgram?.cFProgramPhasesContainerId
              ? duplicateData
              : selectedProgramData
          }
          duplicateOneProgram={duplicateOneProgram}
        />
      )}
    </div>
  );
};

export default ProgramInfo;
