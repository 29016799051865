import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import {
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { FileUploadButton } from "react-file-utils";
import { X } from "lucide-react";
import { v4 as uuidv4 } from "uuid";

import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import moment from "moment";
import { useSelector } from "react-redux";
import { CustomAlertContext } from "../../../App";
import PaginationForAutoMsg from "./PaginationForAutoMsg";
import { Plus } from "lucide-react";
import { uploadPdf } from "@/ApiServices/Programs/Api";
import { SpurfitCircularProgress } from "@/components/Dashboard/dashboard";

const AutoMessageDialog = ({
  isOpen,
  setIsOpen,
  selectedCard,
  programName,
  programId,
  addedWorkouts,
  onDone,
  previousMessages,
  setSelectedCard,
  setAddedWorkouts,
  daysMessages,
}) => {
  const [selectedTime, setSelectedTime] = useState(null);
  const [allMessages, setAllMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState("");
  const [messageIndex, setMessageIndex] = useState(0);
  const [messageSquenceName, setMessageSquenceName] = useState("");
  const [loading, setloading] = useState(false);
  const [allDays, setAllDays] = useState([]);
  const [dayNum, setDayNum] = useState(selectedCard);
  const [daysMessageIndex, setDaysMessageIndex] = useState(0);

  const [uploadingPdf, setUploadingPdf] = useState(false);
  const [uploadedPdf, setUploadedPdf] = useState(null);

  const [currentDaysMessages, setCurrentDaysMessages] = useState([]);

  const trainerProfile = useSelector((state) => state.auth.data);

  const { displayAlert } = useContext(CustomAlertContext);

  useEffect(() => {
    let lastDay = getLastDayWithWorkout();
    let days = [];
    for (let i = 1; i <= lastDay; i++) {
      days.push(i);
    }
    setAllDays(days);
  }, []);

  useEffect(() => {
    let lastDay = getLastDayWithWorkout();
    let days = [];
    for (let i = 1; i <= lastDay; i++) {
      days.push(i);
    }

    if (!days.includes(selectedCard)) {
      days.push(selectedCard);
      setAllDays(days);
      // create empty entry in addedWorkouts
      let newAddedWorkouts = { ...addedWorkouts };
      newAddedWorkouts[selectedCard] = [];
      setAddedWorkouts(newAddedWorkouts);

      return;
    }
  }, [selectedCard]);

  useEffect(() => {
    setCurrentDaysMessages(daysMessages?.[selectedCard] ?? []);
    setMessageSquenceName(daysMessages?.[selectedCard]?.[0]?.name ?? "");
    setDaysMessageIndex(0);
    setData(0);
    setDayNum(selectedCard);
  }, [selectedCard]);

  const setData = (daysMessageIndex) => {
    let previousMessages = daysMessages?.[selectedCard]?.[daysMessageIndex];
    if (
      !isNaN(previousMessages?.hour) &&
      !isNaN(previousMessages?.minute) &&
      previousMessages?.messages
    ) {
      if (previousMessages?.hour && previousMessages?.minute) {
        setSelectedTime(
          moment()
            .hour(previousMessages?.hour)
            .minute(previousMessages?.minute)
            .second(0)
        );
      } else {
        setSelectedTime(null);
      }
      // if messages is array of strings then convert it to array of objects
      if (previousMessages?.messages?.length > 0) {
        let newMessages = previousMessages?.messages?.map((msg) => {
          if (typeof msg === "string") {
            return {
              text: msg,
            };
          }
          return msg;
        });
        setAllMessages(newMessages);
      } else {
        setAllMessages([]);
      }
      setMessageIndex(previousMessages?.messages?.length);
    } else {
      setSelectedTime(null);
      setAllMessages([]);
      setMessageIndex(0);
      if (daysMessageIndex === 0) {
        setMessageSquenceName("");
      }
    }
  };

  useEffect(() => {
    setData(daysMessageIndex);
  }, [daysMessageIndex]);

  const getLastDayWithWorkout = () => {
    let lastDay = 0;
    Object.keys(addedWorkouts).map((key) => {
      if (addedWorkouts?.[key]?.length > 0 || daysMessages?.[key]) {
        lastDay = Math.max(lastDay, parseInt(key));
      }
    });
    return lastDay;
  };

  const deleteOneMessage = (index) => {
    let newMessages = [...allMessages];
    newMessages.splice(index, 1);
    setAllMessages(newMessages);
    setMessageIndex(messageIndex - 1);

    // update currentDaysMessages
    let newDaysMessages = [...currentDaysMessages];
    newDaysMessages[daysMessageIndex] = {
      ...(currentDaysMessages[daysMessageIndex] ?? {}),
      messages: newMessages,
    };
    setCurrentDaysMessages(newDaysMessages);
  };

  const isDisabled = () => {
    let lastMessageIndex = currentDaysMessages?.length - 1;
    if (lastMessageIndex < 0) return true;
    return (
      !selectedTime ||
      !messageSquenceName ||
      !currentDaysMessages?.[lastMessageIndex]?.name ||
      !currentDaysMessages?.[lastMessageIndex]?.hour ||
      !currentDaysMessages?.[lastMessageIndex]?.minute ||
      !currentDaysMessages?.[lastMessageIndex]?.messages?.length
    );
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        style: {
          borderRadius: "20px",
          height: "720px",
        },
      }}
    >
      <div className="add-program-modal-head">
        <div
          style={{ display: "flex", alignItems: "center", gap: "8px" }}
        >
          <p className="add-program-modal-head-title">Add Messages </p>

          <p className="sub-text">
            {programName} - Day {selectedCard}
          </p>

          <Select
            SelectDisplayProps={{
              style: {
                color: "#242424",
                fontFeatureSettings: "'clig' off, 'liga' off",
                fontFamily: "DM Sans",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "21px",
              },
            }}
            sx={{
              height: "40px",
              borderRadius: "8px",
            }}
            variant="outlined"
            color="royalBlue"
            IconComponent={ExpandMoreRoundedIcon}
            value={selectedCard}
            onChange={(e) => {
              if (allMessages?.length > 0) {
                let newCurrentDaysMessages = (
                  currentDaysMessages ?? []
                )?.filter(
                  (day) =>
                    day?.name &&
                    day?.hour &&
                    day?.minute &&
                    day?.messages?.length > 0
                );

                onDone(newCurrentDaysMessages, dayNum);
              }

              setSelectedCard(e.target.value);
              setloading(true);
              setTimeout(() => {
                setloading(false);
              }, 200);
            }}
          >
            {allDays.map((day) => {
              return (
                <MenuItem
                  sx={{
                    color: "#242424",
                    fontFeatureSettings: "'clig' off, 'liga' off",
                    fontFamily: "DM Sans",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "21px",
                  }}
                  value={day}
                >
                  Day {day}
                </MenuItem>
              );
            })}
          </Select>
        </div>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <CloseRoundedIcon />
        </div>
      </div>
      <DialogContent sx={{ p: 0, overflow: "hidden", overflowY: 'auto' }}>
        {!loading && (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                padding: "0px 24px",
                paddingTop: '10px'
              }}
              key={selectedCard}
            >
              <div className="flex items-center gap-2">
                <PaginationForAutoMsg
                  currentDaysMessages={currentDaysMessages}
                  daysMessageIndex={daysMessageIndex}
                  setDaysMessageIndex={setDaysMessageIndex}
                />
                <button
                  // disable if current message sequence is empty
                  disabled={isDisabled()}
                  // if disabled make opacity 0.5
                  className={`bg-gray-100 ${isDisabled() ? "opacity-50" : "cursor-pointer"
                    } hover:bg-gray-200 rounded-full p-1
                  `}
                  title={
                    isDisabled()
                      ? "Make sure you have selected time and added messages"
                      : "Add new message sequence for different time."
                  }
                  onClick={() => {
                    let newDaysMessages = [...currentDaysMessages];
                    newDaysMessages.push({
                      name: messageSquenceName,
                    });
                    setCurrentDaysMessages(newDaysMessages);
                    setDaysMessageIndex(newDaysMessages.length - 1);
                    setMessageSquenceName(messageSquenceName);
                  }}
                >
                  <Plus size={18} />
                </button>
              </div>
              <div
                style={{
                  width: "100%",
                }}
              >
                <Input
                  label={"Name of message sequence"}
                  inputValue={messageSquenceName}
                  setInputValue={setMessageSquenceName}
                  onChange={(e) => {
                    let value = e.target.value ?? "  ";
                    setMessageSquenceName(value);
                    // update currentDaysMessages
                    let newDaysMessages = [...currentDaysMessages];
                    newDaysMessages[daysMessageIndex] = {
                      ...(currentDaysMessages[daysMessageIndex] ?? {}),
                      name: value,
                    };
                    setCurrentDaysMessages(newDaysMessages);
                  }}
                  info={
                    "This won't be visible to users. This is just for your reference"
                  }
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "16px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontFamily: "DM Sans",
                        fontWeight: 400,
                        color: "#000000",
                        mt: "8px",
                      }}
                    >
                      Select user time
                    </Typography>

                    <TimePicker
                      value={selectedTime}
                      key={selectedTime?.format("HH:mm:ss")}
                      onChange={(e) => {
                        setSelectedTime(
                          moment(e.target.value, "HH:mm:ss").second(0)
                        );
                        // update currentDaysMessages
                        let newDaysMessages = [...currentDaysMessages];
                        newDaysMessages[daysMessageIndex] = {
                          ...(currentDaysMessages[daysMessageIndex] ?? {}),
                          hour: moment(e.target.value, "HH:mm:ss").hour(),
                          minute: moment(e.target.value, "HH:mm:ss").minute(),
                        };
                        setCurrentDaysMessages(newDaysMessages);
                      }}
                    />
                  </div>
                </div>
              </div>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "DM Sans",
                  fontWeight: 500,
                  color: "#000000",
                  margin: "0px 21px",
                }}
              >
                Add messages
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                  overflowY: "auto",
                  border: "1px solid #E5E5E5",
                  borderRadius: "12px",
                  padding: "16px",
                  height: "300px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    overflowY: "auto",
                    height: "250px",
                  }}
                >
                  {allMessages.map((message, index) => {
                    return (
                      <RenderOneMessage
                        trainerProfile={trainerProfile}
                        message={message}
                        index={index}
                        deleteOneMessage={deleteOneMessage}
                      />
                    );
                  })}
                </div>
                <div className="w-full">
                  {/* uploading pdf ui */}
                  {(uploadingPdf || uploadedPdf) && (
                    <div className="w-full border-b">
                      <div className="p-2 relative">
                        <PDFSvg />
                        {!uploadingPdf && (
                          <div
                            className="absolute top-0 right-0 cursor-pointer"
                            onClick={() => setUploadedPdf(null)}
                          >
                            <X />
                          </div>
                        )}
                        {/* overlay with loader */}
                        {uploadingPdf && (
                          <div className="absolute top-0 left-0 w-[49px] h-full bg-black-pure bg-opacity-50 flex items-center justify-center">
                            <SpurfitCircularProgress />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div
                    style={{
                      width: "100%",
                      // margin: "0px 21px",
                      position: "relative",
                    }}
                  >
                    <Input
                      containerStyle={{
                        marginTop: uploadingPdf ? "0px" : "20px",
                      }}
                      inputValue={currentMessage}
                      setInputValue={setCurrentMessage}
                      onChange={(e) => {
                        setCurrentMessage(e.target.value);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          setMessageIndex(messageIndex + 1);
                          let newMessages = [...allMessages];
                          if (newMessages[messageIndex]) {
                            newMessages[messageIndex] = {
                              text: currentMessage,
                            };
                          } else {
                            newMessages.push({ text: currentMessage });
                          }
                          setAllMessages(newMessages);
                          setCurrentMessage("");
                          // update currentDaysMessages
                          let newDaysMessages = [...currentDaysMessages];
                          newDaysMessages[daysMessageIndex] = {
                            ...(currentDaysMessages[daysMessageIndex] ?? {}),
                            messages: newMessages,
                          };
                          setCurrentDaysMessages(newDaysMessages);
                        }
                      }}
                      placeholder={"Enter message here."}
                    />
                    <div
                      style={{
                        position: "absolute",
                        right: "40px",
                        top: "68%",
                        transform: "translateY(-50%)",
                      }}
                    >
                      <FileUploadButton
                        handleFiles={async (files) => {
                          setUploadingPdf(true);
                          let data = new FormData();
                          data.append("pdf", files[0]);
                          const res = await uploadPdf(data);
                          const url = res?.location;

                          setUploadingPdf(false);

                          setMessageIndex(messageIndex + 1);
                          let newMessages = [...allMessages];
                          let msg = {
                            text: currentMessage,
                            pdf: url,
                          };
                          if (newMessages[messageIndex]) {
                            newMessages[messageIndex] = msg;
                          } else {
                            newMessages.push(msg);
                          }
                          setAllMessages(newMessages);
                          setCurrentMessage("");

                          // // update currentDaysMessages
                          // let newDaysMessages = [...currentDaysMessages];
                          // newDaysMessages[daysMessageIndex] = {
                          //   ...(currentDaysMessages[daysMessageIndex] ?? {}),
                          //   pdf: url,
                          // };
                          // setCurrentDaysMessages(newDaysMessages);
                          return url;
                        }}
                        accepts={["application/pdf"]}
                      />
                    </div>
                    {uploadingPdf ? (
                      <div className="absolute right-[10px] top-[68%] transform translate-y-[-50%]">
                        <SpurfitCircularProgress />
                      </div>
                    ) : (
                      <SendRoundedIcon
                        sx={{
                          position: "absolute",
                          right: "10px",
                          top: "68%",
                          transform: "translateY(-50%)",
                          color: "#424242",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          // if (allMessages?.length === 3) return;
                          setMessageIndex(messageIndex + 1);
                          let newMessages = [...allMessages];
                          if (newMessages[messageIndex]) {
                            newMessages[messageIndex] = {
                              text: currentMessage,
                              pdf: uploadedPdf
                                ? {
                                  url: uploadedPdf?.location,
                                  name: uploadedPdf?.originalname,
                                }
                                : undefined,
                            };
                          } else {
                            newMessages.push({
                              text: currentMessage,
                              pdf: uploadedPdf
                                ? {
                                  url: uploadedPdf?.location,
                                  name: uploadedPdf?.originalname,
                                }
                                : undefined,
                            });
                          }
                          setAllMessages(newMessages);
                          setCurrentMessage("");

                          // update currentDaysMessages
                          let newDaysMessages = [...currentDaysMessages];
                          newDaysMessages[daysMessageIndex] = {
                            ...(currentDaysMessages[daysMessageIndex] ?? {}),
                            messages: newMessages,
                          };
                          setCurrentDaysMessages(newDaysMessages);
                          setUploadedPdf(null);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <button
          onClick={() => {
            if (!selectedTime || allMessages?.length <= 0) {
              displayAlert({
                type: "error",
                message: "Make sure you have selected time and added messages",
              });
              return;
            }
            // check currentDaysMessages if some day is empty or doesn't have the three things the remove that day from currentDaysMessages
            let newCurrentDaysMessages = (currentDaysMessages ?? [])?.filter(
              (day) =>
                !isNaN(day?.hour) &&
                !isNaN(day?.minute) &&
                day?.messages?.length > 0
            );

            onDone(newCurrentDaysMessages, selectedCard);

            setIsOpen(false);
          }}
          className="button-new"
        >
          Done
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default AutoMessageDialog;

const RenderOneMessage = ({
  trainerProfile,
  message,
  index,
  deleteOneMessage,
}) => {
  let name = trainerProfile?.name;
  let trainerImage = trainerProfile?.imageKye;
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "8px",
      }}
      key={index}
    >
      <Avatar alt={name} src={trainerImage} />
      <div>
        <p
          style={{
            fontFamily: "DM Sans",
            fontSize: "14px",
            fontWeight: 600,
          }}
        >
          {name}
        </p>
        {message.pdf && (
          <div
            className="cursor-pointer"
            onClick={() => {
              window.open(message.pdf, "_blank");
            }}
          >
            {" "}
            <PDFSvg height={20} width={20} />{" "}
          </div>
        )}
        <p
          style={{
            fontFamily: "DM Sans",
            fontSize: "14px",
            fontWeight: 500,
          }}
        >
          {message?.text}
        </p>
      </div>
      <CloseRoundedIcon
        sx={{
          cursor: "pointer",
          ml: "auto",
          color: "#424242",
        }}
        onClick={() => {
          deleteOneMessage(index);
        }}
      />
    </div>
  );
};

function Input({
  label,
  type = "text",
  inputValue = "",
  setInputValue,
  onChange,
  id,
  containerStyle,
  info,
  ...props
}) {
  const inputId = id || uuidv4();

  function handleInputChange(event) {
    onChange && onChange(event);
    setInputValue(event.target.value);
  }

  return (
    <div
      style={{
        ...containerStyle,
      }}
      className="input-container"
    >
      <label htmlFor={inputId} {...props}>
        {label}{" "}
        {info && (
          <Tooltip placement="right" title={info}>
            {" "}
            <InfoRoundedIcon
              sx={{
                color: "#fff",
                backgroundColor: "#4339F2",
                borderRadius: "50%",
                fontSize: "16px",
              }}
            />
          </Tooltip>
        )}
      </label>
      <input
        type={type}
        id={inputId}
        value={inputValue}
        onChange={handleInputChange}
        {...props}
      />
    </div>
  );
}

const TimePicker = ({ value, onChange }) => {
  return (
    <div
      style={{
        border: "1px solid #E5E5E5",
        width: "120px",
        padding: "6px",
        overflow: "hidden",
        borderRadius: "10px",
      }}
    >
      <input
        type="time"
        onKeyDown={(e) => e.preventDefault()}
        value={value?.format("HH:mm:ss")}
        onChange={onChange}
      />
    </div>
  );
};

const PDFSvg = ({ height, width }) => {
  return (
    <svg
      class="rfu-file-icon--small fa-file-pdf"
      height={height || "30"}
      viewBox="0 0 384 512"
      width={width || "30"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm250.2-143.7c-12.2-12-47-8.7-64.4-6.5-17.2-10.5-28.7-25-36.8-46.3 3.9-16.1 10.1-40.6 5.4-56-4.2-26.2-37.8-23.6-42.6-5.9-4.4 16.1-.4 38.5 7 67.1-10 23.9-24.9 56-35.4 74.4-20 10.3-47 26.2-51 46.2-3.3 15.8 26 55.2 76.1-31.2 22.4-7.4 46.8-16.5 68.4-20.1 18.9 10.2 41 17 55.8 17 25.5 0 28-28.2 17.5-38.7zm-198.1 77.8c5.1-13.7 24.5-29.5 30.4-35-19 30.3-30.4 35.7-30.4 35zm81.6-190.6c7.4 0 6.7 32.1 1.8 40.8-4.4-13.9-4.3-40.8-1.8-40.8zm-24.4 136.6c9.7-16.9 18-37 24.7-54.7 8.3 15.1 18.9 27.2 30.1 35.5-20.8 4.3-38.9 13.1-54.8 19.2zm131.6-5s-5 6-37.3-7.8c35.1-2.6 40.9 5.4 37.3 7.8z"
        fill="#f82903"
      ></path>
    </svg>
  );
};
