import React, { useState } from "react";
import { useChatContext } from "stream-chat-react";

import { TextField, IconButton, Box, Popover, MenuItem } from "@mui/material";
import { Clear } from "@mui/icons-material";
import SearchIcon from "../../assets/dashboard/searchIcon.svg";

import Typography from "@mui/material/Typography";
import { Paper } from "@mui/material";

import ActiveFilterIcon from "../../assets/workouts/ActiveFunnel.svg";
import FilterIcon from "../../assets/workoutBuilder/filterIcon.svg";
import { useSelector } from "react-redux";
import moment from "moment";

const ClientsSearch = ({
  clients,
  setClientsList,
  clientsSummary,
  selectedClient,
  setSelectedClient,
  setShowClientDetails,
  originalClientsList,
  userLastMessaged,
  userUnreadMessage,
  unreadMarks,
  dashboardData,
}) => {
  const [searchedClients, setSearchedClients] = useState([]);
  const [searchedChannels, setSearchedChannels] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [isFilterActive, setIsFilterActive] = useState(false);

  const [popoverOpen, setPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [selectedFilter, setSelectedFilter] = useState("");
  const gymAffiliation = useSelector(
    (state) => state.auth?.data?.gymAffliation
  );
  const isLivezy = gymAffiliation === "Livezy";

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const { channels, setActiveChannel } = useChatContext();

  const request = (searchTerm) => {
    if (searchTerm === "") {
      setSearchedClients([]);
      setSearchedChannels([]);
      setPopoverOpen(false);
      return;
    }
    const newSearchedClients = clients.filter((client) => {
      return client?.Name?.toLowerCase().includes(searchTerm.toLowerCase());
    });
    setSearchedClients(newSearchedClients);

    // const newSearchedChannels = channels.filter((channel) => {
    //   return channel?.data?.name
    //     ?.toLowerCase()
    //     .includes(searchTerm.toLowerCase());
    // });
    // setSearchedChannels(newSearchedChannels);

    if (newSearchedClients.length > 0) {
      setPopoverOpen(true);
    } else {
      setPopoverOpen(false);
    }
  };

  const cancel = () => {
    setSearchedClients([]);
    setSearchedChannels([]);
  };

  const doesWeightExist = (bodyStatsData) => {
    let { latestWeight, latestDate } =
      getLatestWeightFromBodyStats(bodyStatsData);
    // if latest date is more than 7 days old, return false
    if (!latestDate || (latestDate && moment().diff(latestDate, "days") > 7)) {
      return false;
    }
    return true;
  };

  const didLastMessagedInDays = (days, userId) => {
    let lastMessagedClient = userLastMessaged?.[userId]
      ? moment(userLastMessaged?.[userId])
      : undefined;
    let didMessagedInLastTwoDays =
      lastMessagedClient && moment().diff(lastMessagedClient, "days") <= days;
    return didMessagedInLastTwoDays;
  };

  const handleOptionClick = (option) => {
    if (option === selectedFilter) {
      setSelectedFilter("");
      setClientsList(originalClientsList);
      setIsFilterActive(false);
      handleClose();
      return;
    }
    setSelectedFilter(option);
    if (option === "Not messaged in 2 days") {
      const newSearchedClients = originalClientsList.filter((client) => {
        return !didLastMessagedInDays(2, client?.UserUUID);
      });
      setClientsList(newSearchedClients);
    }

    if (option === "Not messaged in 4 days") {
      const newSearchedClients = originalClientsList.filter((client) => {
        return !didLastMessagedInDays(4, client?.UserUUID);
      });
      setClientsList(newSearchedClients);
    }

    if (option === "Not messaged in 7 days") {
      const newSearchedClients = originalClientsList.filter((client) => {
        return !didLastMessagedInDays(7, client?.UserUUID);
      });

      setClientsList(newSearchedClients);
    }

    if (option === "Unread chats") {
      const newSearchedClients = originalClientsList.filter((client) => {
        return (
          (userUnreadMessage?.[client?.UserUUID] &&
            userUnreadMessage?.[client?.UserUUID] > 0) ||
          unreadMarks?.[client?.UserUUID]
        );
      });
      setClientsList(newSearchedClients);
    }

    if (option === "Physeek pictures not updated in last one week") {
      const newSearchedClients = originalClientsList.filter((client) => {
        return !isUpdatedPhyseekPicturesUploadedInLastWeek(
          clientsSummary?.[client?.UserUUID]?.bodyStatsData
        );
      });
      setClientsList(newSearchedClients);
    }

    if (option === "Weight not updated in last one week") {
      const newSearchedClients = originalClientsList.filter((client) => {
        let bodyStatsData = clientsSummary?.[client?.UserUUID]?.bodyStatsData;
        return !doesWeightExist(bodyStatsData);
      });
      setClientsList(newSearchedClients);
    }

    if (option === "Membership expiring in 7 days") {
      const newClientList = originalClientsList.filter((client) => {
        const planEndDate =
          clientsSummary?.[client?.UserUUID]?.userInfo?.planEndDate_LivEzy;
        if (planEndDate) {
          // Check if planEndDate exists
          const endDate = moment(planEndDate);
          const todayDate = moment();
          const diffInDays = endDate.diff(todayDate, "days");
          return diffInDays <= 7 && diffInDays >= 0; // Filter clients with memberships expiring within 7 days
        }
        return false; // Exclude clients with null or undefined planEndDate
      });
      setClientsList(newClientList); // Update the client list state with the new filtered list
    }

    if (option === "Clients without Workout and Meal plans") {
      console.log("clients summary data full", clientsSummary, dashboardData);
      const newClientList = originalClientsList.filter((client) => {
        // Check if any of the items in dashboardData matches the client's UserUUID
        const matchingItem = dashboardData?.result.find(
          (item) => item?.UserUUID === client?.UserUUID
        );

        // If no matching item found or matching item has missing plans, include client in newClientList
        if (
          !matchingItem ||
          !(
            matchingItem.mealPlanID &&
            matchingItem.mealPlanID != "-" &&
            matchingItem.mealPlanScheduleID &&
            matchingItem.mealPlanScheduleID != "-" &&
            matchingItem.programID &&
            matchingItem.programID != "-"
          )
        ) {
          return true; // Include the client in the filtered list
        }

        // Otherwise, exclude the client from the filtered list
        return false;
      });
      setClientsList(newClientList);
    }

    setIsFilterActive(true);
    handleClose();
  };

  return (
    <Box
      sx={{
        width: "100%",
        padding: "16px 10px",
        position: "relative",
      }}
    >
      <div className="flex items-center gap-2">
        <SearchBar
          placeholder={"Search for clients"}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          bgcol={"#f2f2f2"}
          request={request}
          cancel={cancel}
          style={{ flex: 1 }}
          width={"100%"}
          fontSize={"16px"}
          iconStyle={{ width: "20px", height: "20px" }}
        />

        {isLivezy && (
          <img
            src={isFilterActive ? ActiveFilterIcon : FilterIcon}
            className="cursor-pointer h-[30px] w-[30px]"
            onClick={handleClick}
          />
        )}
      </div>

      {popoverOpen && (
        <div
          style={{
            position: "absolute",
            top: 55,
            left: 0,
            width: "100%",
            zIndex: 1000,
          }}
        >
          <Paper
            sx={{
              mx: "16px",
            }}
            elevation={8}
          >
            {searchedClients.length > 0 && (
              <div
                style={{
                  backgroundColor: "white",
                  padding: 10,
                  borderRadius: 5,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    borderBottom: "1px solid #E5E5E5",
                  }}
                  gutterBottom
                >
                  Users
                </Typography>
                {searchedClients.map((client, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setSelectedClient(client);
                        setActiveChannel(null);
                        setShowClientDetails(true);
                        setPopoverOpen(false);
                        setSearchTerm("");
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          padding: "5px 10px",
                          fontWeight: "500",
                          "&:hover": {
                            color: "rgb(131, 48, 233)",
                            backgroundColor: "#F5F5F5",
                            borderRadius: "5px",
                          },
                        }}
                        gutterBottom
                      >
                        {client.Name}
                      </Typography>
                    </div>
                  );
                })}
              </div>
            )}

            {searchedChannels.length > 0 && (
              <div
                style={{
                  backgroundColor: "white",
                  padding: 10,
                  borderRadius: 5,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    borderBottom: "1px solid #E5E5E5",
                  }}
                  gutterBottom
                >
                  Channels
                </Typography>
                {searchedChannels.map((channel, index) => {
                  return (
                    <div
                      key={index}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setActiveChannel(channel);
                        setPopoverOpen(false);
                        setSearchTerm("");
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          padding: "5px 10px",
                          fontWeight: "500",
                          "&:hover": {
                            color: "rgb(131, 48, 233)",
                            backgroundColor: "#F5F5F5",
                            borderRadius: "5px",
                          },
                        }}
                        gutterBottom
                      >
                        {channel.data.name}
                      </Typography>
                    </div>
                  );
                })}
              </div>
            )}
          </Paper>
        </div>
      )}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {options?.map((option) => (
          <MenuItem
            sx={{
              fontSize: "12px",
              fontFamily: "DM Sans",
              fontWeight: 500,
              background:
                selectedFilter === option &&
                "linear-gradient(to right, rgba(70, 79, 235, 0.4), rgba(131, 48, 233, 0.4))",
            }}
            key={option}
            onClick={() => handleOptionClick(option)}
          >
            {option}
          </MenuItem>
        ))}
        <MenuItem
          sx={{
            fontSize: "12px",
            fontFamily: "DM Sans",
            fontWeight: 500,
            color: "#e65e5e",
          }}
          key={"clear"}
          onClick={() => {
            setIsFilterActive(false);
            handleClose();
            setClientsList(originalClientsList);
            setSelectedFilter("");
          }}
        >
          Clear filter
        </MenuItem>
      </Popover>
    </Box>
  );
};

export default ClientsSearch;

function SearchBar({
  searchTerm,
  setSearchTerm,
  request,
  cancel,
  height,
  width,
  bgcol,
  fontSize,
  icon,
  placeholder,
  style,
  iconStyle,
}) {
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    request(event.target.value);
  };

  const handleClear = () => {
    setSearchTerm("");
    cancel();
  };

  return (
    <TextField
      variant="standard"
      sx={{
        width: width || "auto",
        marginBottom: "auto",
        marginTop: "auto",
        mx: "5px",
        display: "flex",
        alignItems: "center",
        ...style,
      }}
      autoComplete="off"
      placeholder={placeholder || "Search anything"}
      value={searchTerm}
      onChange={handleSearch}
      InputProps={{
        style: {
          height: height,
          width: width || "100%",
          borderRadius: 50,
          padding: height != "40px" ? "0px 0px 0px 0px" : 0,
          background: bgcol,
          fontSize: fontSize,
          fontFamily: "DM Sans",
        },
        startAdornment: (
          <IconButton
            disabled
            style={{
              padding: "0px !important",
            }}
          >
            <img
              style={{
                marginLeft: "8px",
                marginRight: "2px",
                padding: "0px !important",
                ...iconStyle,
              }}
              src={icon ? icon : SearchIcon}
            />
          </IconButton>
        ),
        endAdornment: searchTerm && (
          <IconButton onClick={handleClear} disabled={!searchTerm}>
            <Clear fontSize="small" />
          </IconButton>
        ),
        disableUnderline: true,
      }}
    />
  );
}
const options = [
  // "Not messaged in 2 days",
  // "Not messaged in 4 days",
  // "Not messaged in 7 days",
  "Unread chats",
  "Physeek pictures not updated in last one week",
  "Weight not updated in last one week",
  "Membership expiring in 7 days",
  "Clients without Workout and Meal plans",
];

const getLatestWeightFromBodyStats = (bodyStatsData) => {
  bodyStatsData?.sort(
    (a, b) => new Date(b.dateRecorded) - new Date(a.dateRecorded)
  );
  let latestWeight = null;
  let latestDate = null;
  bodyStatsData?.forEach((item) => {
    if (
      !latestWeight &&
      item?.weight?.value !== undefined &&
      item?.weight?.value !== null &&
      item?.weight?.value !== ""
    ) {
      let date = moment(item?.dateRecorded).format("Do MMM YYYY");
      const weightValue = parseFloat(item?.weight?.value); // Convert to number
      latestWeight = weightValue.toFixed(1); // Restrict to one decimal place
      if (item?.weight?.unit) {
        latestWeight += ` ${item?.weight?.unit}`;
      } else {
        latestWeight += ` kg`;
      }

      latestDate = date;
      return;
    }
  });
  console.log("latestWeight", latestWeight);
  return { latestWeight, latestDate };
};

const isUpdatedPhyseekPicturesUploadedInLastWeek = (bodyStatsData) => {
  let sortedBodyStatsData = bodyStatsData?.sort(
    (a, b) => new Date(b.dateRecorded) - new Date(a.dateRecorded)
  );

  let isUpdatedPhyseekPicturesUploadedInLastWeek = false;

  sortedBodyStatsData?.forEach((item) => {
    if (
      item?.progressImages?.length > 0 &&
      item?.progressImages?.[0] &&
      moment().diff(item?.dateRecorded, "days") <= 7
    ) {
      isUpdatedPhyseekPicturesUploadedInLastWeek = true;
      return;
    }
  });

  return isUpdatedPhyseekPicturesUploadedInLastWeek;
};
