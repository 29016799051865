import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import MarkEmailUnreadOutlinedIcon from "@mui/icons-material/MarkEmailUnreadOutlined";
import {
  Box,
  CircularProgress,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import EventAvailableSharpIcon from "@mui/icons-material/EventAvailableSharp";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Arrow from "../Calendar/Arrow";
import Calendar from "../Calendar/Calendar";
import AddActivityDialog from "../DescriptionComp/AddActivityDialog";
import AddWorkoutAndMealModal from "../DescriptionComp/AddWorkoutAndMealModal";
import { getLatestWeightFromBodyStats } from "../DescriptionComp/ClientProfileSummary";
import CreateMealPlanModal from "../DescriptionComp/CreateMealPlanModal";
import ImportMealModal from "../DescriptionComp/ImportMealModal";
import SummarSection2 from "../DescriptionComp/SummarSection2";
import { createActivity, updateActivity } from "../DescriptionComp/util";
import Notes from "../Notes/Notes";
import WorkoutInfoDialog from "../WorkoutComponent/WorkoutInfoDialog";
import ChatView from "./ChatView";
import GarminSummary from "./GarminSummary.jsx";
import OverviewTab from "./OverviewTab";
import SummarTab from "./SummarTab";
import PlusRoundIcon from "@/pages/library/assests/PlusRoundIcon";
import AddClientUnavailability from "./AddClientUnavailability";

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import axios from "axios";
import CalendarGray from "../../assets/clientProfile/CalendarGray.png";
import MealIcon from "../../assets/clientProfile/mealGray.svg";
import CalendarV2 from "../Calendar/CalendarOneWeek";
import CalendarTwoWeek from "../Calendar/CalendarTwoWeek";
import { SpurfitCircularProgress } from "../Dashboard/dashboard";
import AssignCardioModal from "../DescriptionComp/AssignCardioModal";
import AddTagsDialog from "./AddTagsDialog";
import AddTargetUserThresold from "./AddTargetUserThresold";
import AddTargetWeight from "./AddTargetWeightDialog";
import AthleteStatus from "./AthleteStatus";

import { Maximize2, Minimize2 } from "lucide-react";

import { getClientProfileByDateRange } from "@/ApiServices/ClientProfileApi/Api";
import ChatDashboard from "@/components/ClientsView/chatdashbaord";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import CalendarMonth from "../Calendar/CalendarMonth";
import AddMacrosBasedFood from "../Nutrition/AddMealPlans/AddMacrosBasedFood";
import AddMealsModal from "../Nutrition/AddMealPlans/AddMealsModal";
import AllUsersSelect from "./AllUsersSelect";
import AssignedForms from "./AssignedForms";
import Questionaire from "./Questionaire";
import {
  UserMetric,
  getDaysLeft,
  getLastWorkoutDate,
  getLatestProgressImageDateFromBodyStats,
} from "./helper";
import MembershipDetails from "./MembershipDetails";
import { convertDateToYearMonthDayFormat } from "@/utils/functions";
import StrengthDashboard from "./StrengthDashboard";
import { getFormByathleteID } from "@/ApiServices/ClientDetails/Api";

const ClientDetails = ({
  summaryData = {},
  setSummaryData,
  updateUserInfoOfOneClient,
  onBack,
  selectedClient,
  clientsProfileData,
  clientsMealPlanData,
  clientsActivityData,
  reloadParticularClientsProfile,
  reloadClientSummary,
  showBackBtn,
  parentLoading,
  selectedTab,
  setSelectedTab,
  unreadMarks,
  setUnreadMarks,
  profileData,
  setProfileData,
  isAdminUserDetail,
  garminActivityData,
  key,
  setShowWorkoutLibrary,
  showWorkoutLibrary,
  calenderRef,
  setOpenWorkoutInfoModal,
  openWorkoutInfoModal,
  setSelectedClient,
  clientsList,
  isExpandedView,
  setIsExpandedView,
  activeUser,
}) => {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const dispatch = useDispatch();
  const { bodyStatsData, globalStats, userInfo, lastWorkoutAssignedDate } =
    summaryData;

  const addMealPlanRef = useRef();

  const [notesCount, setNotesCount] = useState(0);
  const trainerUUID = useSelector((state) => state.auth.trainerUUID);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [showWorkOutBuilder, setShowWorkOutBuilder] = useState(false);
  const [disableArrow, setDisableArrow] = useState(false);
  const [isUpdateUserWeight, setIsUpdateUserWeight] = useState(false);
  const [isUpdateUserStepsCount, setIsUpdateUserStepsCount] = useState(false);
  const [newWorkoutModel, setNewWorkoutModel] = useState(null);
  const [openImportMealModal, setOpenImportMealModal] = useState(false);
  const [openCreateMealModal, setOpenCreateMealModal] = useState(false);
  //const [openWorkoutInfoModal, setOpenWorkoutInfoModal] = useState(false);
  const [showAddActivityModal, setShowAddActivityModal] = useState(false);
  const [selectedCalendarType, setSelectedCalendarType] = useState("Weekly");
  const [showAddCardioModal, setShowAddCardioModal] = useState(false);
  const [existingCardioInfo, setExistingCardioInfo] = useState(null);
  const [currentClientProfile, setCurrentClientProfile] = useState(
    profileData || {}
  );
  const [daysLeft, setDaysLeft] = useState("");

  const [loading, setLoading] = useState(false);
  const [weekStartDate, setWeekStartDate] = useState("");
  const [weekEndDate, setWeekEndDate] = useState();
  const [isHighlighted, setIsHighlighted] = useState(true);
  const [mealDetails, setmealDetails] = useState(null);
  const [updateData, setUpdateData] = useState(false);

  const [mealData, setMealData] = useState(null);
  const [openCreateMacrosModal, setOpenCreateMacrosModal] = useState(false);

  const [openCreateFoodModal, setOpenCreateFoodModal] = useState(false);
  const [initializeData, setInitializeData] = useState(false);

  const [showAddTagsModal, setShowAddTagsModal] = useState(false);
  const [showAddTargetWeightModal, setShowAddTargetWeightModal] =
    useState(false);

  const [showAddThreshold, setShowAddThreshold] = useState(false);
  const [showThresholdButton, setShowThresholdButton] = useState(false);
  const [isUpdateUserThreshold, setIsUpdateUserThreshold] = useState(false);

  const [isPaused, setIsPaused] = useState(userInfo?.isPaused_LivEzy);

  const [isPlanExpired, setIsPlanExpired] = useState(false);

  const [loadingCalendar, setLoadingCalendar] = useState(false);
  const [isMonthExpanded, setIsMonthExpanded] = useState(false);

  const [showThresholdValues, setShowThresholdValues] = useState(false);
  const gymAffiliation = useSelector(
    (state) => state.auth?.data?.gymAffliation
  );

  const coachID = useSelector((state) => state.auth?.trainerUUID);

  const trainerType = useSelector((state) => state.auth?.data?.trainingType);

  const isLivezy = gymAffiliation === "Livezy";

  const [calStartDate, setCalStartDate] = useState(
    moment().startOf("month").toDate()
  );
  const [movements, setMovements] = useState(0);

  const [markReadLoading, setMarkReadLoading] = useState(false);

  const [targetWeight, setTargetWeight] = useState("");
  const [weekStartDateOne, setWeekStartDateOne] = useState(
    moment().startOf("week").add(1, "day")
  );
  const [weekEndDateOne, setWeekEndDateOne] = useState(
    moment().endOf("week").add(1, "day")
  );
  const [currentWeekOfMonth, setCurrentWeekOfMonth] = useState();

  const copiedWorkout = useSelector((state) => state.client.copiedWorkout);
  const copiedDay = useSelector((state) => state.client.copiedDay);
  const [openDashboard, setOpenDashboard] = useState(false);
  //for unavailability option
  const [isClientAvailable, setIsClientAvailable] = useState(false);
  const [form, setForms] = useState([]);
  const [clickArrowDetails, setClickArrowDetails] = useState({
    left: false,
    right: false,
    count: 0,
  });

  const isSmallerThanMd = useMediaQuery((theme) =>
    theme.breakpoints.down("md")
  );

  const tabs = isAdminUserDetail
    ? [
        ...(isLivezy
          ? [{ label: "Membership Details", value: "membership" }]
          : []),
        { label: "Summary", value: "summary" },
        { label: "Schedule", value: "schedule" },
      ]
    : coachID == "ErzsyqqduVWs" || coachID == "nqFaQiUwcNcR"
    ? [
        { label: "Chat", value: "chat" },
        { label: "Notes", value: "notes" },
        { label: "Summary", value: "summary" },
        { label: "Schedule", value: "schedule" },
        { label: "Dashboard", value: "Dashboard" },
        form?.length > 0 && { label: "Answered Forms", value: "answeredForms" },

        ...(coachID == "ErzsyqqduVWs"
          ? [{ label: "Strength Dashboard", value: "strengthDashboard" }]
          : []),
      ]
    : isLivezy
    ? [
        { label: "Notes", value: "notes" },
        { label: "Summary", value: "summary" },
        { label: "Schedule", value: "schedule" },
        // { label: "Questionnaire", value: "questionnaire" },
      ]
    : [
        { label: "Chat", value: "chat" },
        { label: "Notes", value: "notes" },
        { label: "Summary", value: "summary" },
        { label: "Schedule", value: "schedule" },
        form?.length > 0 && { label: "Answered Forms", value: "answeredForms" },
      ];

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let todayDate = moment().format("DD-MM-YYYY");

  const assigned = queryParams.get("assigned");
  const getActivitiesToBeDeleted = (totalDay, selectedDate) => {
    const assignedDate = moment(selectedDate, "DD-MM-YYYY").format(
      "YYYY-MM-DD"
    );
    let totalDays = Number(totalDay);
    let idToBeDeleted = [];
    let arrayOfKeys = Object.keys(clientsActivityData);

    for (let i = 0; i < totalDays; i++) {
      let tempDate = moment(assignedDate).add(i, "day").format("YYYY-MM-DD");
      if (arrayOfKeys.includes(tempDate)) {
        idToBeDeleted.push(clientsActivityData[tempDate]?.[0]?.id);
      }
    }
    return idToBeDeleted;
  };

  const handleTodayClick = () => {
    setIsHighlighted(false);
    const timeOut = setTimeout(() => {
      setIsHighlighted(true);
      clearTimeout(timeOut);
    }, 500);
  };

  const onActivitiesSave = async (data, userUUID, selectedDate, totalDays) => {
    let idToBeDeleted = getActivitiesToBeDeleted(totalDays, selectedDate);
    let payload = {
      dateAssigned:
        moment(selectedDate, "DD-MM-YYYY").format("YYYY-MM-DD") +
        "T00:00:00.000Z",
      atheleteID: userUUID,
      activitiesAssigned: data,
      totalDays,
      idToBeDeleted,
    };
    let isUpdate = false;
    let activityId = "";
    if (
      selectedActivity?.[0] &&
      Object.entries(selectedActivity?.[0])?.length !== 0 &&
      selectedActivity?.[0]?.activitiesAssigned &&
      Object.entries(selectedActivity?.[0]?.activitiesAssigned)?.length !== 0
    ) {
      isUpdate = true;
      activityId = selectedActivity?.[0]?.id;
      let activitiesAssigned = data;
      payload = {
        ...payload,
        activitiesAssigned: {
          ...payload.activitiesAssigned,
          ...activitiesAssigned,
        },
      };
    }

    if (isUpdate) {
      delete payload?.totalDays;
      delete payload?.idToBeDeleted;
      await updateActivity(payload, activityId);
    } else {
      await createActivity(payload);
    }

    reloadProfile(userInfo?.id);
  };

  /* CLOSE THE DASHBOARD DIALOG*/
  const handleDashboardClose = () => setSelectedTab("chat");

  const reloadProfile = async (atheleteID) => {
    setLoadingCalendar(true);

    let startDate;
    let endDate;
    if (selectedCalendarType === "Weekly") {
      startDate = moment(weekStartDateOne);
      endDate = moment(weekEndDateOne);
    } else if (selectedCalendarType === "Semi Monthly") {
      startDate = moment(weekStartDate);
      endDate = moment(weekEndDate);
    } else {
      startDate = moment(calStartDate);
      endDate = moment(calStartDate).endOf("month");
    }

    let res = await reloadParticularClientsProfile(
      atheleteID,
      startDate,
      endDate
    );
    setLoadingCalendar(false);
  };

  function reloadProfileForWorkoutBuilderModal(atheleteID) {
    reloadProfile(atheleteID);
  }
  async function getForm() {
    const res = await getFormByathleteID(userInfo?.id);
    if (res && res.length > 0) {
      setForms(res);
    }
  }

  useEffect(() => {
    let startDate = moment().startOf("week");
    setWeekStartDate(startDate);
    setWeekEndDate(moment().startOf("week").add(2, "weeks"));
    const storedCalendarType = localStorage.getItem("calendarType");
    if (isAdminUserDetail) {
      setSelectedCalendarType("Monthly");
    } else if (storedCalendarType) {
      setSelectedCalendarType(storedCalendarType);
    }
    if (trainerType === "ENDURENCE") {
      setShowThresholdButton(true);
    }
  }, []);

  const getDataForSelectedMonth = async (date, calType) => {
    let startDate;
    let endDate;
    let calendarType = calType || selectedCalendarType;
    if (calendarType === "Weekly") {
      startDate = moment(date).startOf("week").add(1, "day");
      endDate = moment(date).endOf("week").add(1, "day");
    } else if (calendarType === "Semi Monthly") {
      startDate = moment(date).startOf("week").add(1, "day");
      endDate = moment(date).endOf("week").add(1, "week").add(1, "day");
    } else {
      startDate = moment(date).startOf("month");
      endDate = moment(date).endOf("month");
    }
    setLoadingCalendar(true);
    let data = await getClientProfileByDateRange(
      userInfo?.id,
      startDate,
      endDate
    );
    setLoadingCalendar(false);
    setProfileData(data, true);
  };

  const parseAndSetDates = () => {
    try {
      let twoWeekDateRange = localStorage.getItem(
        `${userInfo?.id}@twoWeekDateRange`
      );
      let oneWeekDateRange = localStorage.getItem(
        `${userInfo?.id}@oneWeekDateRange`
      );

      if (twoWeekDateRange && assigned === "true") {
        let [start, end] = twoWeekDateRange.split("$");
        if (
          weekStartDate &&
          weekEndDate &&
          moment(start).isSame(weekStartDate) &&
          moment(end).isSame(weekEndDate)
        ) {
        } else {
          setWeekStartDate(moment(start).toDate());
          setWeekEndDate(moment(end).toDate());

          getDataForSelectedMonth(moment(start).toDate());
        }
      }
      if (oneWeekDateRange && assigned === "true") {
        let [start, end] = oneWeekDateRange.split("$");
        if (
          weekStartDateOne &&
          weekEndDateOne &&
          moment(start).isSame(weekStartDateOne) &&
          moment(end).isSame(weekEndDateOne)
        ) {
        } else {
          setWeekStartDateOne(moment(start).toDate());
          setWeekEndDateOne(moment(end).toDate());

          getDataForSelectedMonth(moment(start).toDate());
        }
      }
    } catch (error) {
      console.log("error parsing and setting dates", error);
    }
  };

  useEffect(() => {
    // setLoading(true);
    setCurrentClientProfile(profileData);

    let calendarStartDate = localStorage.getItem(
      userInfo?.id + "@calStartDate"
    );

    parseAndSetDates();
    if (calendarStartDate) {
      if (moment(calendarStartDate).isSame(moment(calStartDate))) {
        return;
      } else {
        setCalStartDate(moment(calendarStartDate).toDate());
        let endDate = moment(calendarStartDate).endOf("month");
        getClientProfileByDateRange(userInfo?.id, calendarStartDate, endDate);
      }
      // if cal start date lies in current month
      if (moment(calendarStartDate).isSame(moment(), "month")) {
      } else {
        setMovements(movements + 1);
      }
    }
  }, [profileData]);

  useEffect(() => {
    // let presentWeekNo =
    //   moment(weekStartDate).isoWeek() % 4 === 0
    //     ? 4
    //     : moment(weekStartDate).isoWeek() % 4;
    const startOfMonth = moment(weekStartDateOne).startOf("month");
    // const diffInDays = moment(weekStartDate).diff(startOfMonth, 'days');
    const weekOfMonth = Math.ceil((startOfMonth.day() + 1) / 7);
    setCurrentWeekOfMonth(weekOfMonth);
  }, []);
  useEffect(() => {
    if (!initializeData) {
      setInitializeData(true);
      return;
    } else {
      if (reloadParticularClientsProfile) {
        reloadProfile(userInfo?.id);
      }
    }
    // getSteps();
  }, [updateData]);

  useEffect(() => {
    setLoading(true);
    if (userInfo) {
      getDaysLeft(userInfo, setDaysLeft, true);
      setLoading(false);
    }
  }, [selectedClient, summaryData]);

  useEffect(() => {
    if (userInfo?.id) {
      getForm();
    }
  }, [userInfo]);
  useEffect(() => {
    let targetWeight = getTargetWeight(userInfo);
    setTargetWeight(targetWeight);
  }, [userInfo?.goalWeight]);

  const getTrainerAddedWeight = () => {
    if (typeof userInfo?.weight === "string") {
      let weight = userInfo?.weight?.includes("-")
        ? userInfo?.weight?.replace("-", " ")
        : userInfo?.weight + " kg";
      return weight;
    } else {
      return null;
    }
  };

  const getWeekFunction = (direction) => {
    setDisableArrow("");
    let updatedMonth;
    if (direction === "left") {
      updatedMonth = moment(calStartDate).subtract(1, "months").toDate();
    } else {
      updatedMonth = moment(calStartDate).add(1, "months").toDate();
    }
    setCalStartDate(updatedMonth);
    if (direction === "right") {
      getCurrentMonthDataForTwoWeek(updatedMonth);
    } else {
      getCurrentMonthDataForTwoWeek(updatedMonth);
    }
    // add calStartDate to cache
    let key = userInfo?.id + "@calStartDate";
    localStorage.setItem(key, updatedMonth);
    let timeout = setTimeout(() => {
      setMovements(movements + 1);
      clearTimeout(timeout);
    }, 200);
  };

  const getCurrentMonthDataForOneWeek = async (startDate) => {
    return getDataForSelectedMonth(startDate);

    const currentDate = moment(startDate);
    const calStartDate = currentDate.startOf("month");
    await axios({
      method: "post",
      url: `${APIURL}CF/api/clientProfileByPage`,
      data: {
        date: moment(calStartDate).format("YYYY-MM-DD"),
      },
      params: {
        userUUID: userInfo?.id,
      },
    })
      .then((res) => {
        setProfileData(res.data.result, true);
      })
      .catch((err) => {
        console.log("error fetching client profile by page: ", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getWeekFunctionForOneWeek = (direction) => {
    setDisableArrow("");

    let updatedWeekStart, updatedWeekEnd;
    if (direction === "left") {
      updatedWeekStart = moment(weekStartDateOne).subtract(1, "week");
      updatedWeekEnd = moment(weekEndDateOne).subtract(1, "week");
    } else {
      updatedWeekStart = moment(weekStartDateOne).add(1, "week");
      updatedWeekEnd = moment(weekEndDateOne).add(1, "week");
    }

    let prevWeekEndDate = moment(weekEndDateOne);
    let curWeekEndDate = moment(updatedWeekEnd);

    let prevWeekStartDate = moment(weekStartDateOne);
    let curWeekStartDate = moment(updatedWeekStart);

    // if (
    //   prevWeekEndDate.month() != curWeekEndDate.month() ||
    //   prevWeekStartDate.month() != curWeekStartDate.month()
    // ) {
    //   // prevWeekEndDate falls in the previous month of curWeekEndDate
    //   // Your code logic here
    // }
    getCurrentMonthDataForOneWeek(curWeekStartDate);

    let key = `${userInfo?.id}@oneWeekDateRange`;
    let value = `${updatedWeekStart.toDate()}$${updatedWeekEnd.toDate()}`;
    localStorage.setItem(key, value);
    // Log the start date of the updated week
    const startOfMonth = moment(weekStartDateOne).startOf("month");
    // const diffInDays = moment(weekStartDate).diff(startOfMonth, 'days');
    const weekOfMonth = Math.ceil((startOfMonth.day() + 1) / 7);
    // let presentWeekNo =
    //   moment(weekStartDate).isoWeek() % 4 === 0
    //     ? 4
    //     : moment(weekStartDate).isoWeek() % 4;
    setCurrentWeekOfMonth(weekOfMonth);
    setWeekStartDateOne(updatedWeekStart.toDate());
    setWeekEndDateOne(updatedWeekEnd.toDate());
    setTimeout(() => {
      setMovements(movements + 1);
    }, 200);
  };

  const getCurrentMonthDataForTwoWeek = async (startDate) => {
    return getDataForSelectedMonth(startDate);

    const currentDate = moment(startDate);
    const calStartDate = currentDate.startOf("month");
    await axios({
      method: "post",
      url: `${APIURL}CF/api/clientProfileByPage`,
      data: {
        date: moment(calStartDate).format("YYYY-MM-DD"),
      },
      params: {
        userUUID: userInfo?.id,
      },
    })
      .then((res) => {
        setProfileData(res.data.result, true);
      })
      .catch((err) => {
        console.log("error fetching client profile by page: ", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getWeekFunctionForTwoWeek = (direction) => {
    setDisableArrow("");
    let updatedWeekStart, updatedWeekEnd;
    if (direction === "left") {
      updatedWeekStart = moment(weekStartDate).subtract(2, "week");
      updatedWeekEnd = moment(weekEndDate).subtract(2, "week");
    } else {
      updatedWeekStart = moment(weekStartDate).add(2, "week");
      updatedWeekEnd = moment(weekEndDate).add(2, "week");
    }

    let prevWeekEndDate = moment(weekEndDate);
    let curWeekEndDate = moment(updatedWeekEnd);

    let prevWeekStartDate = moment(weekStartDate);
    let curWeekStartDate = moment(updatedWeekStart);

    getCurrentMonthDataForTwoWeek(curWeekStartDate);

    let key = `${userInfo?.id}@twoWeekDateRange`;
    let value = `${updatedWeekStart.toDate()}$${updatedWeekEnd.toDate()}`;
    localStorage.setItem(key, value);
    // Log the start date of the updated week
    setWeekStartDate(updatedWeekStart.toDate());
    setWeekEndDate(updatedWeekEnd.toDate());
    setTimeout(() => {
      setMovements(movements + 1);
    }, 200);
  };

  let referral = isLivezy && userInfo?.referral;

  const trainerAddedWeight = getTrainerAddedWeight();
  const latestWeight =
    getLatestWeightFromBodyStats(bodyStatsData, true) ?? trainerAddedWeight;
  let latestProgressImageDate =
    getLatestProgressImageDateFromBodyStats(bodyStatsData);

  const markAsUnread = async (mark) => {
    if (!userInfo?.id || unreadMarks?.[userInfo?.id] === mark) return;
    setMarkReadLoading(true);
    setUnreadMarks((prev) => {
      if (prev) {
        return {
          ...prev,
          [userInfo?.id]: mark,
        };
      } else {
        return {
          [userInfo?.id]: mark,
        };
      }
    });

    await axios({
      method: "post",
      url: `${APIURL}CF/api/CoachWeb/Update/updateAtheleteProperties`,
      data: {
        athleteID: userInfo?.id,
        markedAsUnread: mark,
      },
    })
      .then((res) => {
        updateUserInfoOfOneClient(res.data.result);
        // reloadClientSummary(userInfo?.id);
      })
      .catch((err) => {
        console.log("error marking read", err);
      })
      .finally(() => {
        setMarkReadLoading(false);
      });
  };
  // console.log("jatin", clientsActivityData);
  const gradientTextStyle = {
    background: "linear-gradient(90deg, #464FEB 10.42%, #8330E9 100%)",
    WebkitBackgroundClip: "text" /* For Safari */,
    backgroundClip: "text",
    color: "transparent",
    fontWeight: "600",
  };

  const gradientBorderStyle = {
    borderWidth: "2px",
    borderStyle: "solid",
    borderColor: "#8330E9",
    borderRadius: "20px",
  };

  const arrowFunction = (direction) => {
    if (selectedCalendarType === "Weekly") {
      getWeekFunctionForOneWeek(direction);
    } else if (selectedCalendarType === "Semi Monthly") {
      getWeekFunctionForTwoWeek(direction);
    } else {
      getWeekFunction(direction);
    }
  };

  const handleCalendarTypeChange = (type) => {
    // if type chagnes from weekly to semi monthly or monthly then set the start date of the month
    if (selectedCalendarType === "Weekly" && type === "Semi Monthly") {
      let startDate = moment(weekStartDate);
      getDataForSelectedMonth(startDate, type);
    } else if (selectedCalendarType === "Weekly" && type === "Monthly") {
      let startDate = moment(calStartDate);
      getDataForSelectedMonth(startDate, type);
    } else if (selectedCalendarType === "Semi Monthly" && type === "Monthly") {
      let startDate = moment(calStartDate);
      getDataForSelectedMonth(startDate, type);
    } else if (type === "Weekly") {
      let startDate = moment(weekStartDateOne);
      getDataForSelectedMonth(startDate, type);
    }

    setSelectedCalendarType(type);
    setClickArrowDetails({ count: 0, left: false, right: false });
  };

  const handleTodayDateTriggerEvent = async () => {
    const directionTo =
      clickArrowDetails?.count && clickArrowDetails?.left ? "right" : "left";

    if (selectedCalendarType == "Semi Monthly") {
      let startDate = moment(new Date()).startOf("week");
      setWeekStartDate(startDate);
      let updatedWeekStart, updatedWeekEnd;
      if (directionTo === "left") {
        updatedWeekStart = moment(weekStartDate).subtract(
          clickArrowDetails?.count * 2,
          "week"
        );
        updatedWeekEnd = moment(weekEndDate).subtract(
          clickArrowDetails?.count * 2,
          "week"
        );
      } else {
        updatedWeekStart = moment(weekStartDate).add(
          clickArrowDetails?.count * 2,
          "week"
        );
        updatedWeekEnd = moment(weekEndDate).add(
          clickArrowDetails?.count * 2,
          "week"
        );
      }
      let curWeekStartDate = moment(updatedWeekStart);
      getDataForSelectedMonth(curWeekStartDate);
      setWeekStartDate(updatedWeekStart.toDate());
      setWeekEndDate(updatedWeekEnd.toDate());
    } else if (selectedCalendarType === "Monthly") {
      setCalStartDate(moment(new Date()).startOf("month").toDate());
      makeApiCallForRedirectingToToday({ calendarType: "Monthly", dateToSet });
      getDataForSelectedMonth(moment(new Date()).startOf("month").toDate());
    } else {
      let updatedWeekStart, updatedWeekEnd;
      if (directionTo === "left") {
        updatedWeekStart = moment(weekStartDateOne).subtract(
          clickArrowDetails?.count,
          "week"
        );
        updatedWeekEnd = moment(weekEndDateOne).subtract(
          clickArrowDetails?.count,
          "week"
        );
      } else {
        updatedWeekStart = moment(weekStartDateOne).add(
          clickArrowDetails?.count,
          "week"
        );
        updatedWeekEnd = moment(weekEndDateOne).add(
          clickArrowDetails?.count,
          "week"
        );
      }
      let curWeekStartDate = moment(updatedWeekStart);
      getDataForSelectedMonth(curWeekStartDate);
      const startOfMonth = moment(weekStartDateOne).startOf("month");
      const weekOfMonth = Math.ceil((startOfMonth.day() + 1) / 7);
      setCurrentWeekOfMonth(weekOfMonth);
      setWeekStartDateOne(updatedWeekStart.toDate());
      setWeekEndDateOne(updatedWeekEnd.toDate());
    }

    setClickArrowDetails({ count: 0, left: false, right: false });
  };

  const getDetailsOnDaysOnArrowClickEvent = ({ direction }) => {
    if (!clickArrowDetails?.count) {
      setClickArrowDetails({
        ...clickArrowDetails,
        [direction]: true,
        count: clickArrowDetails.count + 1,
      });
    } else if (clickArrowDetails?.count && clickArrowDetails[direction]) {
      setClickArrowDetails({
        ...clickArrowDetails,
        count: clickArrowDetails.count + 1,
      });
    } else if (clickArrowDetails?.count && !clickArrowDetails[direction]) {
      const isLeft = direction === "left" ? "right" : "left";
      setClickArrowDetails({
        ...clickArrowDetails,
        ...(clickArrowDetails.count - 1 == 0 ? { [isLeft]: false } : {}),
        count: clickArrowDetails.count - 1,
      });
    }
  };

  useEffect(() => {
    setClickArrowDetails({ count: 0, left: false, right: false });
  }, []);

  return (
    <Box
      style={{
        width: isAdminUserDetail ? "100%" : "79%",
      }}
      key={selectedClient?.id}
      sx={{ overflow: "visible" }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          borderBottom: "1px solid #E5E5E5",
          // padding: "0rem 1.5rem",
          // paddingBottom: "0.7rem",
          backgroundColor: "#fff",
          position: "relative",
          borderRadius: "15px",
          boxShadow: "rgba(0, 0, 0, 0.13) 0px 2px 5px -1px",
          overflow: "visible",
        }}
      >
        {parentLoading ? (
          <div className="flex items-center gap-5 pt-[0.7rem]">
            <Skeleton variant="circular" width={50} height={50} />
            <Skeleton
              variant="rectangular"
              width={"100%"}
              height={180}
              sx={{
                borderRadius: "10px",
              }}
            />
          </div>
        ) : (
          <>
            {!isMonthExpanded && !isExpandedView && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  // height: "160px",
                  position: "relative",
                  overflow: "visible",
                }}
              >
                <Box className="w-full jatin p-[1rem]">
                  <Box
                    className="guest-user-div-1"
                    sx={{
                      fontFamily: "DM Sans",
                      width: "100%",
                      fontWeight: 500,
                      fontSize: "20px",
                      lineHeight: "18px",
                      color: "#231F20",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      marginBottom: 2,
                      flexWrap: "wrap",
                      gap: "12px",
                      paddingLeft: "8px",
                    }}
                  >
                    <div>
                      <img
                        src={
                          userInfo?.userImageKey ||
                          "https://img.icons8.com/color/48/circled-user-male-skin-type-7--v1.png"
                        }
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "50%",
                        }}
                      />
                    </div>
                    <div className="flex items-center gap-3 flex-wrap">
                      <p
                        style={{
                          fontFamily: "DM Sans",
                          fontWeight: 500,
                          fontSize: "20px",
                          lineHeight: "22px",
                          color: "#231F20",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {userInfo?.userName}
                      </p>
                      {!userInfo?.markedAsUnread ? (
                        markReadLoading ? (
                          <CircularProgress size={12} />
                        ) : (
                          <MarkEmailUnreadOutlinedIcon
                            style={{
                              fontFamily: "DM Sans",
                              fontWeight: 500,
                              fontSize: "20px",
                              lineHeight: "15px",
                              color: "#424242",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              markAsUnread(true);
                            }}
                          />
                        )
                      ) : null}
                      <Tooltip
                        style={{
                          marginBottom: "16px",
                        }}
                        title={
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              padding: "5px",
                            }}
                          >
                            <p>email: {userInfo?.email}</p>
                            {userInfo?.contactNumber &&
                              userInfo?.contactNumber !== " " && (
                                <p>mob no: {userInfo?.contactNumber}</p>
                              )}
                          </div>
                        }
                        placement="top"
                      >
                        <InfoRoundedIcon
                          style={{
                            fontSize: "20px",
                            cursor: "pointer",
                          }}
                        />
                      </Tooltip>
                      {userInfo?.thresholdValues &&
                        coachID === "ErzsyqqduVWs" && (
                          <button
                            className="font-DMSans text-[#464feb] font-medium rounded focus:outline-none text-font16"
                            onClick={() => {
                              setShowAddThreshold(true);
                              setIsUpdateUserThreshold(true);
                            }}
                          >
                            Thresholds
                          </button>
                        )}
                      {/*{*/}
                      {/*  userInfo?.thresholdValues && coachID === "ErzsyqqduVWs" &&*/}
                      {/*  <ThresholdViewDialog*/}
                      {/*    thresholdValues={userInfo?.thresholdValues}*/}
                      {/*  />*/}
                      {/*}*/}
                      {isLivezy && getIsPaused(userInfo) ? (
                        <CustomChip
                          info={"Paused"}
                          style={{ background: "#cb2416", color: "#fff" }}
                        />
                      ) : (
                        <>
                          {isPlanExpired && (
                            <CustomChip
                              info={"Plan Expired"}
                              style={{ background: "#cb2416", color: "#fff" }}
                            />
                          )}
                          {isLivezy &&
                            selectedClient?.ProgramName &&
                            selectedClient?.ProgramName !== "-" && (
                              <CustomChip
                                icon={CalendarGray}
                                info={selectedClient?.ProgramName}
                                onClick={() => {
                                  // open in new tab
                                  window.open(
                                    `${window.location.origin}/programs/${userInfo?.programID}`,
                                    "_blank"
                                  );
                                }}
                              />
                            )}

                          {isLivezy &&
                            userInfo?.mealSchedule &&
                            userInfo?.mealSchedule !== "-" && (
                              <CustomChip
                                icon={MealIcon}
                                info={userInfo?.mealSchedule}
                                onClick={() => {
                                  // open in new tab
                                  window.open(
                                    `${window.location.origin}/nutrition/4/${userInfo?.mealScheduleID}`,
                                    "_blank"
                                  );
                                }}
                              />
                            )}
                          {isLivezy &&
                            userInfo?.planName_LivEzy &&
                            userInfo?.planName_LivEzy !== "-" && (
                              <CustomChip
                                info={userInfo?.planName_LivEzy || ""}
                              />
                            )}
                        </>
                      )}
                    </div>
                    {/* user's related update  update  buttons */}
                    {!isAdminUserDetail && (
                      <div className="flex items-center gap-2 ml-auto">
                        {showThresholdButton && (
                          <div
                            className="font-DMSans border rounded-2xl border-gray-600 px-[12px] py-[6px] text-font14 cursor-pointer hover:shadow-md hover:border-gray-800 hover:text-gray-800"
                            onClick={() => {
                              setShowAddThreshold(true);
                              setIsUpdateUserThreshold(true);
                            }}
                            style={gradientBorderStyle}
                          >
                            <p style={gradientTextStyle}>
                              Update Threshold Values
                            </p>
                          </div>
                        )}

                        <div
                          className="font-DMSans border rounded-2xl border-gray-600 px-[12px] py-[6px] text-font14 cursor-pointer hover:shadow-md hover:border-gray-800 hover:text-gray-800"
                          onClick={() => {
                            setShowAddTargetWeightModal(true);
                            setIsUpdateUserStepsCount(true);
                          }}
                          style={gradientBorderStyle}
                        >
                          <p style={gradientTextStyle}>Update Steps</p>
                        </div>
                        <div
                          className="font-DMSans border rounded-2xl border-gray-600 px-[12px] py-[6px] text-font14 cursor-pointer hover:shadow-md hover:border-gray-800 hover:text-gray-800"
                          onClick={() => {
                            setShowAddTargetWeightModal(true);
                            setIsUpdateUserWeight(true);
                          }}
                          style={gradientBorderStyle}
                        >
                          <p style={gradientTextStyle}>Update User Weight</p>
                        </div>
                        <div
                          className="font-DMSans border rounded-2xl border-gray-600 px-[12px] py-[6px] text-font14 cursor-pointer hover:shadow-md hover:border-gray-800 hover:text-gray-800  font-medium"
                          onClick={() => setShowAddTargetWeightModal(true)}
                          style={gradientBorderStyle}
                        >
                          <p style={gradientTextStyle}>Add Target Weight</p>
                        </div>
                        {isLivezy && (
                          <div
                            className="font-DMSans border rounded-2xl border-gray-600 px-[12px] py-[6px] text-font14 cursor-pointer hover:shadow-md hover:border-gray-800 hover:text-gray-800  font-medium"
                            onClick={() => setShowAddTagsModal(true)}
                            style={gradientBorderStyle}
                          >
                            <p style={gradientTextStyle}>Add Tag</p>
                          </div>
                        )}
                      </div>
                    )}
                  </Box>

                  {isLivezy && userInfo?.userTags_LivEzy?.tags && (
                    <p
                      style={{
                        fontFamily: "DM Sans",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "22px",
                        width: "fit-content",
                        marginTop: "-10px",
                        marginBottom: "10px",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: "DM Sans",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 700,
                          lineHeight: "22px",
                          width: "fit-content",
                          marginLeft: "5px",
                        }}
                      >
                        {userInfo?.userTags_LivEzy?.tags?.join(", ")}
                      </span>
                    </p>
                  )}
                  {isLivezy &&
                    (userInfo?.onboardingStatus_LivEzy !== "Plan start" ||
                      isPlanExpired) && (
                      <AthleteStatus
                        userUUID={userInfo?.id}
                        onboardingStatus={userInfo?.onboardingStatus_LivEzy}
                        changeUserInfo={(info) => {
                          updateUserInfoOfOneClient(info);
                        }}
                      />
                    )}

                  <div className="flex justify-start items-center gap-4 ">
                    {isLivezy && (
                      <>
                        <UserMetric label={"Days Left"} value={daysLeft} />
                        <UserMetric
                          label={"Last Physique update"}
                          value={latestProgressImageDate}
                        />
                      </>
                    )}
                    <UserMetric
                      label={"Latest Weight"}
                      value={
                        targetWeight
                          ? `${latestWeight ?? "Not added"}`
                          : `${latestWeight ?? "Not added"}`
                      }
                    />
                    {!isLivezy && globalStats?.globalAdherence != undefined && (
                      <div
                        className="flex flex-col"
                        style={{
                          width: "210px",
                          padding: "12px 16px 12px 16px",
                          borderRadius: "12px",
                          borderColor: "#EBEBEB",
                          borderWidth: "1px",
                        }}
                      >
                        <p
                          style={{
                            fontFamily: "DM Sans",
                            fontWeight: 500,
                            fontSize: "13px",
                            lineHeight: "21px",
                            color: "#424242",
                          }}
                        >
                          Adherence
                        </p>
                        <p
                          style={{
                            fontFamily: "DM Sans",
                            fontWeight: 700,
                            fontSize: "16px",
                            color: "#000000",
                          }}
                        >
                          {globalStats?.globalAdherence} %
                        </p>
                      </div>
                    )}
                    {!isLivezy && selectedClient?.LastWorkoutDate && (
                      <div
                        className="flex flex-col"
                        style={{
                          width: "210px",
                          padding: "12px 16px 12px 16px",
                          borderRadius: "12px",
                          borderColor: "#EBEBEB",
                          borderWidth: "1px",
                        }}
                      >
                        <p
                          style={{
                            fontFamily: "DM Sans",
                            fontWeight: 500,
                            fontSize: "13px",
                            lineHeight: "21px",
                            color: "#424242",
                          }}
                        >
                          Last workout date
                        </p>
                        <p
                          style={{
                            fontFamily: "DM Sans",
                            fontWeight: 700,
                            fontSize: "16px",
                            color: "#000000",
                          }}
                        >
                          {" "}
                          {getLastWorkoutDate(selectedClient)}
                        </p>
                      </div>
                    )}
                    {!isLivezy && lastWorkoutAssignedDate && (
                      <div
                        className="flex flex-col"
                        style={{
                          width: "210px",
                          padding: "12px 16px 12px 16px",
                          borderRadius: "12px",
                          borderColor: "#EBEBEB",
                          borderWidth: "1px",
                        }}
                      >
                        <p
                          style={{
                            fontFamily: "DM Sans",
                            fontWeight: 500,
                            fontSize: "13px",
                            lineHeight: "21px",
                            color: "#424242",
                          }}
                        >
                          Last workout assigned on
                        </p>
                        <p
                          style={{
                            fontFamily: "DM Sans",
                            fontWeight: 700,
                            fontSize: "16px",
                            color: "#000000",
                          }}
                        >
                          {moment(lastWorkoutAssignedDate).format("Do MMM' YY")}
                        </p>
                      </div>
                    )}
                    {referral ? (
                      <p
                        style={{
                          marginTop: 2,
                          fontFamily: "DM Sans",
                          fontWeight: 400,
                          fontSize: "13px",
                          lineHeight: "15px",
                          color: "#424242",
                        }}
                      >
                        Referral:{" "}
                        <span
                          style={{
                            fontFamily: "DM Sans",
                            fontWeight: 600,
                            color: "#242424",
                          }}
                        >
                          {" "}
                          {referral}
                        </span>
                      </p>
                    ) : null}
                    {userInfo?.garminConnectStatus ? (
                      <div
                        className="flex flex-col"
                        style={{
                          width: "210px",
                          padding: "12px 16px 12px 16px",
                          borderRadius: "12px",
                          borderColor: "#EBEBEB",
                          borderWidth: "1px",
                        }}
                      >
                        <p
                          style={{
                            fontFamily: "DM Sans",
                            fontWeight: 500,
                            fontSize: "13px",
                            lineHeight: "21px",
                            color: "#424242",
                          }}
                        >
                          Garmin
                        </p>
                        {userInfo?.garminConnectStatus ? (
                          <p
                            style={{
                              fontFamily: "DM Sans",
                              fontWeight: 700,
                              fontSize: "16px",
                              color: "#000000",
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            Connected
                            <div
                              style={{
                                width: "12px",
                                height: "12px",
                                backgroundColor: "#09A033",
                                borderRadius: "50%",
                                marginLeft: "8px",
                              }}
                            ></div>
                          </p>
                        ) : // <p
                        //   style={{
                        //     fontFamily: "DM Sans",
                        //     fontWeight: 700,
                        //     fontSize: "16px",
                        //     color: "#000000",
                        //     display: "flex",
                        //     justifyContent: "flex-start",
                        //     alignItems: "center",
                        //   }}
                        // >
                        //   Disconnected
                        //   <div
                        //     style={{
                        //       width: "12px",
                        //       height: "12px",
                        //       backgroundColor: "#A00933",
                        //       borderRadius: "50%",
                        //       marginLeft: "8px",
                        //     }}
                        //   ></div>
                        // </p>
                        null}
                      </div>
                    ) : null}
                  </div>
                </Box>
              </Box>
            )}

            <Box
              name="tabs-container"
              sx={{
                display: "flex",
                width: "100%",
                gap: "2.5rem",
                padding: "0.6rem 1.5rem",
                borderTop: "1px solid #E7E7E7",
                alignItems: "center",
              }}
            >
              {tabs.map((tab) => {
                if (tab.value === "schedule" && isSmallerThanMd) return null;
                return (
                  <Tab
                    key={tab.value}
                    label={tab.label}
                    value={tab.value}
                    selected={selectedTab === tab.value}
                    onClick={setSelectedTab}
                    notesCount={tab.value === "notes" ? notesCount : null}
                  />
                );
              })}
              {!isAdminUserDetail && (
                <div className="flex items-center gap-2 ml-auto">
                  {isExpandedView && showWorkoutLibrary && (
                    <AllUsersSelect
                      selected={selectedClient}
                      setSelectedClient={setSelectedClient}
                      allAtheletes={clientsList}
                    />
                  )}
                  <div
                    className={
                      "flex items-center border rounded-lg h-[36px] w-[40px] justify-center cursor-pointer"
                    }
                    onClick={() => setIsExpandedView(!isExpandedView)}
                  >
                    {isExpandedView ? (
                      <Minimize2 size={16} />
                    ) : (
                      <Maximize2 size={16} />
                    )}
                  </div>
                </div>
              )}
            </Box>
          </>
        )}
      </Box>

      {isAdminUserDetail ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: !isSmallerThanMd
              ? selectedTab === "chat"
                ? " 0 "
                : "1rem 0rem"
              : selectedTab === "chat"
              ? "0"
              : "14px 0px",
            maxHeight: isExpandedView
              ? "calc(100vh - 60px)"
              : "calc(100vh - 190px)",
            overflowY: selectedTab === "schedule" ? "none" : "scroll",
            width: "100%",
            paddingBottom: selectedTab === "chat" ? "0" : "20px",
          }}
        >
          {selectedTab === "membership" && (
            <MembershipDetails userInfo={userInfo} />
          )}
          {selectedTab === "overview" && (
            <OverviewTab
              data={summaryData}
              reloadParticularClientsProfile={reloadProfile}
            />
          )}
          {selectedTab === "progress" && (
            <SummarSection2
              data={summaryData}
              hideCompliance={true}
              isSmallerThanMd={isSmallerThanMd}
            />
          )}

          <Box
            sx={{
              display: selectedTab === "notes" ? "block" : "none",
              width: ["100%", "100%", "calc(100% - 12px)", "100%"],
              backgroundColor: "#FFFFFF",
              borderRadius: "10px",
              padding: "5px",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.03)",
              marginRight: "12px",
              height: "calc(100vh - 190px)",
              overflowY: "scroll",
            }}
          >
            <Notes
              setNotesCount={setNotesCount}
              userUUID={userInfo?.id}
              trainerUUID={userInfo?.coachID}
            />
          </Box>

          {selectedTab === "chat" && (
            <ChatView
              userInfo={userInfo}
              coachID={userInfo?.coachID}
              userUUID={userInfo?.id}
              markAsUnread={markAsUnread}
            />
          )}

          {selectedTab === "schedule" && (
            <Box
              sx={{
                width: ["100%", "100%", "calc(100% - 12px)"],
                backgroundColor: "#FFFFFF",
                // backgroundColor: "blue",
                borderRadius: "10px",
                padding: "5px",
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.03)",
                marginRight: "12px",
              }}
              key={isAdminUserDetail ? key : selectedClient?.id}
            >
              {isAdminUserDetail ? (
                <div className="flex flex-col mx-auto flex-1 bg-white-pure p-4 drop-shadow-md shadow-lg rounded-3xl">
                  <div>
                    <Arrow
                      getWeekFunction={getWeekFunction}
                      disableArrow={disableArrow}
                    >
                      {moment(calStartDate).format("MMMM YYYY")}
                    </Arrow>
                  </div>

                  {/* {loading ? (
                    <SpurfitCircularProgress />
                  ) : (
                    <Calendar
                      DATA={clientsProfileData}
                      mealData={clientsMealPlanData}
                      profileData={profileData ?? currentClientProfile}
                      activityData={clientsActivityData}
                      userUUID={userInfo?.id}
                      setShowWorkOutBuilder={setShowWorkOutBuilder}
                      selectedDate={selectedDate}
                      setSelectedDate={setSelectedDate}
                      setSelectedActivity={setSelectedActivity}
                      movements={movements}
                      calStartDate={calStartDate}
                      setDisableArrow={setDisableArrow}
                    />
                  )} */}
                  {loading ? (
                    <SpurfitCircularProgress />
                  ) : selectedCalendarType == "Semi Monthly" ? (
                    <CalendarTwoWeek
                      loadingCalendar={loadingCalendar}
                      profileData={currentClientProfile}
                      userUUID={userInfo.id}
                      userInfo={userInfo}
                      setSelectedDate={setSelectedDate}
                      setSelectedActivity={setSelectedActivity}
                      setShowWorkOutBuilder={setShowWorkOutBuilder}
                      weekStartDate={weekStartDate}
                      weekEndDate={weekEndDate}
                      isHighlighted={isHighlighted}
                      selectedCalendarType={selectedCalendarType}
                    />
                  ) : selectedCalendarType == "Monthly" ? (
                    <CalendarMonth
                      loadingCalendar={loadingCalendar}
                      profileData={currentClientProfile}
                      setProfileData={setProfileData}
                      userUUID={userInfo?.id}
                      userInfo={userInfo}
                      setSelectedDate={setSelectedDate}
                      setSelectedActivity={setSelectedActivity}
                      setShowWorkOutBuilder={setShowWorkOutBuilder}
                      monthStartDate={calStartDate}
                      isHighlighted={isHighlighted}
                      selectedCalendarType={selectedCalendarType}
                      garminActivityData={garminActivityData}
                      ref={calenderRef}
                      isAdminUserDetail={isAdminUserDetail}
                      todayDate={todayDate}
                    />
                  ) : null}
                </div>
              ) : (
                <div className="flex flex-col mx-auto flex-1 bg-white-pure  drop-shadow-md shadow-lg rounded-3xl max-w-screen-xl">
                  <div>
                    <Arrow
                      getWeekFunction={getWeekFunction}
                      disableArrow={disableArrow}
                    >
                      {moment(calStartDate).format("MMMM YYYY")}
                    </Arrow>
                  </div>

                  {/* {loading ? (
                    <SpurfitCircularProgress />
                  ) : (
                    <CalendarTwoWeek
                      profileData={currentClientProfile}
                      userUUID={userInfo.id}
                      setSelectedDate={setSelectedDate}
                      setSelectedActivity={setSelectedActivity}
                      setShowWorkOutBuilder={setShowWorkOutBuilder}
                      weekStartDate={weekStartDate}
                      weekEndDate={weekEndDate}
                      isHighlighted={isHighlighted}
                      selectedCalendarType={selectedCalendarType}
                    />
                  )} */}
                  {loading ? (
                    <SpurfitCircularProgress />
                  ) : selectedCalendarType == "Semi Monthly" ? (
                    <CalendarTwoWeek
                      loadingCalendar={loadingCalendar}
                      profileData={currentClientProfile}
                      userUUID={userInfo.id}
                      userInfo={userInfo}
                      setSelectedDate={setSelectedDate}
                      setSelectedActivity={setSelectedActivity}
                      setShowWorkOutBuilder={setShowWorkOutBuilder}
                      weekStartDate={weekStartDate}
                      weekEndDate={weekEndDate}
                      isHighlighted={isHighlighted}
                      selectedCalendarType={selectedCalendarType}
                      todayDate={todayDate}
                    />
                  ) : selectedCalendarType == "Monthly" ? (
                    <Calendar
                      DATA={clientsProfileData}
                      mealData={clientsMealPlanData}
                      profileData={profileData ?? currentClientProfile}
                      activityData={clientsActivityData}
                      userUUID={userInfo?.id}
                      setShowWorkOutBuilder={setShowWorkOutBuilder}
                      setSelectedDate={setSelectedDate}
                      setSelectedActivity={setSelectedActivity}
                      movements={movements}
                      calStartDate={calStartDate}
                      setDisableArrow={setDisableArrow}
                      selectedCalendarType={selectedCalendarType}
                      todayDate={todayDate}
                    />
                  ) : null}
                </div>
              )}
            </Box>
          )}

          {/*{selectedTab === "schedule" && (*/}
          {/*  <Box*/}
          {/*    sx={{*/}
          {/*      width: ["100%", "100%", "calc(100% - 12px)"],*/}
          {/*      backgroundColor: "#FFFFFF",*/}
          {/*      borderRadius: "10px",*/}
          {/*      padding: "5px",*/}
          {/*      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.03)",*/}
          {/*      marginRight: "12px",*/}
          {/*      overflow: "scroll",*/}
          {/*    }}*/}
          {/*    key={selectedClient?.id}*/}
          {/*  >*/}
          {/*    <div className="flex justify-between p-3 w-full">*/}
          {/*      <Arrow*/}
          {/*        getWeekFunction={getWeekFunction}*/}
          {/*        disableArrow={disableArrow}*/}
          {/*      >*/}
          {/*        {`${moment(weekStartDate).format("MMM DD")} - ${moment(*/}
          {/*          weekEndDate*/}
          {/*        ).format("MMM DD")}`}*/}
          {/*      </Arrow>*/}
          {/*      <button*/}
          {/*        onClick={handleTodayClick}*/}
          {/*        className="todayBtn"*/}
          {/*        style={{*/}
          {/*          fontFamily: "DM Sans",*/}
          {/*          fontSize: "14px",*/}
          {/*          fontStyle: "normal",*/}
          {/*          fontWeight: 500,*/}
          {/*          lineHeight: "22px",*/}
          {/*          background:*/}
          {/*            "linear-gradient(90deg, #464FEB 10.42%, #8330E9 100%)",*/}
          {/*          backgroundClip: "text",*/}
          {/*          color: "transparent",*/}
          {/*          padding: "0px 8px",*/}
          {/*          // border related css is in inside calendar folder Style.css*/}
          {/*        }}*/}
          {/*      >*/}
          {/*        Today*/}
          {/*      </button>*/}
          {/*    </div>*/}
          {/*    {loading ? (*/}
          {/*      <SpurfitCircularProgress />*/}
          {/*    ) : selectedCalendarType === "Semi Monthly" ? (*/}
          {/*      <CalendarTwoWeek*/}
          {/*        loadingCalendar={loadingCalendar}*/}
          {/*        profileData={currentClientProfile}*/}
          {/*        userUUID={userInfo.id}*/}
          {/*        userInfo={userInfo}*/}
          {/*        setSelectedDate={setSelectedDate}*/}
          {/*        setSelectedActivity={setSelectedActivity}*/}
          {/*        setShowWorkOutBuilder={setShowWorkOutBuilder}*/}
          {/*        weekStartDate={weekStartDate}*/}
          {/*        weekEndDate={weekEndDate}*/}
          {/*        isHighlighted={isHighlighted}*/}
          {/*        selectedCalendarType={selectedCalendarType}*/}
          {/*      />*/}
          {/*    ) : selectedCalendarType === "Monthly" ? (*/}
          {/*      <Calendar*/}
          {/*        DATA={clientsProfileData}*/}
          {/*        mealData={clientsMealPlanData}*/}
          {/*        profileData={profileData ?? currentClientProfile}*/}
          {/*        activityData={clientsActivityData}*/}
          {/*        userUUID={userInfo?.id}*/}
          {/*        userInfo={userInfo}*/}
          {/*        setShowWorkOutBuilder={setShowWorkOutBuilder}*/}
          {/*        setSelectedDate={setSelectedDate}*/}
          {/*        setSelectedActivity={setSelectedActivity}*/}
          {/*        movements={movements}*/}
          {/*        calStartDate={calStartDate}*/}
          {/*        setDisableArrow={setDisableArrow}*/}
          {/*        selectedCalendarType={selectedCalendarType}*/}
          {/*      />*/}
          {/*    ) : null}*/}
          {/*  </Box>*/}
          {/*)}*/}

          {selectedTab === "summary" && (
            <SummarTab
              userUUID={userInfo?.id}
              trainerUUID={trainerUUID}
              isAdminUserDetail={isAdminUserDetail}
              summaryData={summaryData}
              isSmallerThanMd={isSmallerThanMd}
              reloadParticularClientsProfile={reloadProfile}
              isExpandedView={isExpandedView}
            />
          )}
          {showAddTagsModal && (
            <AddTagsDialog
              isOpen={showAddTagsModal}
              setIsOpen={setShowAddTagsModal}
              userUUID={userInfo?.id}
              reloadClientSummary={reloadClientSummary}
              previousTags={userInfo?.userTags_LivEzy?.tags}
            />
          )}
          {showAddTargetWeightModal && (
            <AddTargetWeight
              isOpen={showAddTargetWeightModal}
              setIsOpen={setShowAddTargetWeightModal}
              userUUID={userInfo?.id}
              reloadClientSummary={reloadClientSummary}
              setIsUpdateUserWeight={setIsUpdateUserWeight}
              isUpdate={isUpdateUserWeight}
              setSummaryData={setSummaryData}
              summaryData={summaryData}
              setIsUpdateUserStepsCount={setIsUpdateUserStepsCount}
              isUpdateUserStepsCount={isUpdateUserStepsCount}
              setIsUpdateUserThreshold={setIsUpdateUserThreshold}
              isUpdateUserThreshold={isUpdateUserThreshold}
            />
          )}
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: !isSmallerThanMd
              ? selectedTab === "chat"
                ? " 0 "
                : "1rem 0rem 0rem 0rem"
              : selectedTab === "chat"
              ? "0"
              : "14px 0px",
            maxHeight: isExpandedView
              ? "calc(100vh - 60px)"
              : "calc(100vh - 300px)",
            overflowY: selectedTab === "schedule" ? "none" : "visible",
            width: "100%",
            paddingBottom: selectedTab === "chat" ? "0" : "20px",
          }}
        >
          {selectedTab === "overview" && (
            <OverviewTab
              data={summaryData}
              reloadParticularClientsProfile={reloadProfile}
            />
          )}
          {selectedTab === "progress" && (
            <SummarSection2
              data={summaryData}
              hideCompliance={true}
              isSmallerThanMd={isSmallerThanMd}
            />
          )}

          <Box
            sx={{
              display: selectedTab === "notes" ? "block" : "none",
              width: ["100%", "100%", "calc(100% - 12px)", "100%"],
              backgroundColor: "#FFFFFF",
              borderRadius: "10px",
              padding: "5px",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.03)",
              marginRight: "12px",
              height: "calc(100vh - 300px)",
              overflowY: "scroll",
            }}
          >
            <Notes
              setNotesCount={setNotesCount}
              userUUID={userInfo?.id}
              trainerUUID={userInfo?.coachID}
            />
          </Box>
          {selectedTab === "questionnaire" && (
            <Box
              sx={{
                display: selectedTab === "questionnaire" ? "block" : "none",
                width: ["100%", "100%", "calc(100% - 12px)", "100%"],
                backgroundColor: "#FFFFFF",
                borderRadius: "10px",
                padding: "5px",
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.03)",
                marginRight: "12px",
                height: "calc(100vh - 300px)",
                overflowY: "scroll",
              }}
            >
              <Questionaire userInfo={userInfo} />
            </Box>
          )}

          {selectedTab === "chat" && (
            <ChatView
              userInfo={userInfo}
              coachID={userInfo?.coachID}
              userUUID={userInfo?.id}
              markAsUnread={markAsUnread}
              isExpandedView={isExpandedView}
            />
          )}
          {selectedTab === "schedule" && (
            <Box
              sx={{
                width: ["100%", "100%", "calc(100% - 0px)"],
                backgroundColor: "#FFFFFF",
                borderRadius: "10px",
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.03)",
                zIndex: "1",
                height: "calc(100vh - 300px)",
                // marginRight: "12px",
              }}
              key={selectedClient?.id}
            >
              <div className="flex flex-col  flex-1 bg-white-pure drop-shadow-md shadow-lg rounded-xl ">
                {selectedCalendarType === "Semi Monthly" ? (
                  <div
                    className="flex justify-center items-center w-full"
                    style={{
                      position: "relative",
                      padding: "5px 0px",
                      paddingTop: "14px",
                      zIndex: "1",
                    }}
                  >
                    <Arrow
                      getWeekFunction={(direction) => {
                        arrowFunction(direction);
                        getDetailsOnDaysOnArrowClickEvent({ direction });
                      }}
                      disableArrow={disableArrow}
                    >
                      {`${moment(weekStartDate).format("MMM DD")} - ${moment(
                        weekEndDate
                      ).format("MMM DD")}`}
                    </Arrow>

                    <div
                      style={{
                        position: "absolute",
                        right: "20px",
                        marginTop: "auto",
                        marginBottom: "auto",
                        zIndex: "1",
                      }}
                    >
                      <div className="flex gap-2">
                        <Stack sx={{ cursor: "pointer" }}>
                          <Typography
                            margin={"auto .4rem"}
                            onClick={handleTodayDateTriggerEvent}
                          >
                            <EventAvailableSharpIcon />
                            Today
                          </Typography>
                        </Stack>
                        <Select
                          value={selectedCalendarType}
                          onValueChange={(value) => {
                            localStorage.setItem("calendarType", value);
                            handleCalendarTypeChange(value);
                          }}
                        >
                          <SelectTrigger className="w-[180px] py-[8px] px-[10px] focus:ring-white-pure font-normal">
                            <SelectValue placeholder={selectedCalendarType} />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              <SelectItem value="Weekly">Weekly</SelectItem>
                              <SelectItem value="Semi Monthly">
                                Semi Monthly
                              </SelectItem>
                              <SelectItem value="Monthly">Monthly</SelectItem>
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </div>
                    </div>
                  </div>
                ) : selectedCalendarType === "Monthly" ? (
                  <div
                    className="flex justify-center items-center w-full jatin"
                    style={{
                      position: "relative",
                      padding: "5px 0px",
                      paddingTop: "14px",
                    }}
                  >
                    <Arrow
                      getWeekFunction={(direction) => {
                        arrowFunction(direction);
                        getDetailsOnDaysOnArrowClickEvent({ direction });
                      }}
                      disableArrow={disableArrow}
                    >
                      {moment(calStartDate).format("MMMM YYYY")}
                    </Arrow>

                    <div
                      style={{
                        position: "absolute",
                        right: "20px",
                        marginTop: "auto",
                        marginBottom: "auto",
                      }}
                    >
                      <div className="flex gap-2">
                        <Stack sx={{ cursor: "pointer" }}>
                          <Typography
                            margin={"auto .4rem"}
                            onClick={handleTodayDateTriggerEvent}
                          >
                            <EventAvailableSharpIcon />
                            Today
                          </Typography>
                        </Stack>
                        <Select
                          value={selectedCalendarType}
                          onValueChange={(value) => {
                            localStorage.setItem("calendarType", value);
                            handleCalendarTypeChange(value);
                          }}
                        >
                          <SelectTrigger className="w-[180px] py-[8px] px-[10px] focus:ring-white-pure font-normal">
                            <SelectValue placeholder="Monthly" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              <SelectItem value="Weekly">Weekly</SelectItem>
                              <SelectItem value="Semi Monthly">
                                Semi Monthly
                              </SelectItem>
                              <SelectItem value="Monthly">Monthly</SelectItem>
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "center",
                      borderBottom: "2px solid #F2F2F2",
                      padding: "15px 0px 15px 15px",
                    }}
                  >
                    <Arrow
                      getWeekFunction={(direction) => {
                        arrowFunction(direction);
                        getDetailsOnDaysOnArrowClickEvent({ direction });
                      }}
                      disableArrow={disableArrow}
                    >
                      {`${moment(weekStartDateOne).format("MMM DD")} - ${moment(
                        weekEndDateOne
                      ).format("MMM DD")}`}
                    </Arrow>

                    {/* <button
                        onClick={handleTodayClick}
                        className="todayBtn mr-5"
                        style={{
                          fontFamily: "DM Sans",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "22px",
                          background:
                            "linear-gradient(90deg, #464FEB 10.42%, #8330E9 100%)",
                          backgroundClip: "text",
                          color: "transparent",
                          padding: "0px 8px",
                          // border related css is in inside calendar folder Style.css
                        }}
                      >
                        Today
                      </button> */}
                    <div
                      style={{
                        position: "absolute",
                        right: "20px",
                        marginTop: "auto",
                        marginBottom: "auto",
                      }}
                    >
                      <div className="flex gap-2">
                        <Stack sx={{ cursor: "pointer" }}>
                          <Typography
                            margin={"auto .4rem"}
                            onClick={handleTodayDateTriggerEvent}
                          >
                            <EventAvailableSharpIcon />
                            Today
                          </Typography>
                        </Stack>
                        <Select
                          value={selectedCalendarType}
                          onValueChange={(value) => {
                            localStorage.setItem("calendarType", value);
                            handleCalendarTypeChange(value);
                          }}
                        >
                          <SelectTrigger className="w-[180px] py-[8px] px-[10px] focus:ring-white-pure font-normal">
                            <SelectValue placeholder={selectedCalendarType} />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              <SelectItem value="Weekly">Weekly</SelectItem>
                              <SelectItem value="Semi Monthly">
                                Semi Monthly
                              </SelectItem>
                              <SelectItem value="Monthly">Monthly</SelectItem>
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </div>
                    </div>
                  </div>
                )}

                {loading ? (
                  <SpurfitCircularProgress />
                ) : selectedCalendarType === "Semi Monthly" ? (
                  <CalendarTwoWeek
                    profileData={currentClientProfile}
                    setProfileData={setProfileData}
                    userUUID={userInfo.id}
                    userInfo={userInfo}
                    setSelectedDate={setSelectedDate}
                    setSelectedActivity={setSelectedActivity}
                    setShowWorkOutBuilder={setShowWorkOutBuilder}
                    weekStartDate={weekStartDate}
                    weekEndDate={weekEndDate}
                    isHighlighted={isHighlighted}
                    selectedCalendarType={selectedCalendarType}
                    garminActivityData={garminActivityData}
                    loadingCalendar={loadingCalendar}
                    ref={calenderRef}
                    profileDataFromClientsView={profileData}
                    setProfileDataFromClientsView={setProfileData}
                    reloadProfile={reloadProfile}
                    isExpanded={isExpandedView}
                    isAdminUserDetail={isAdminUserDetail}
                  />
                ) : selectedCalendarType === "Monthly" ? (
                  <CalendarMonth
                    loadingCalendar={loadingCalendar}
                    profileDataFromClientsView={profileData}
                    setProfileDataFromClientsView={setProfileData}
                    profileData={currentClientProfile}
                    setProfileData={setProfileData}
                    userUUID={userInfo.id}
                    userInfo={userInfo}
                    setSelectedDate={setSelectedDate}
                    setSelectedActivity={setSelectedActivity}
                    setShowWorkOutBuilder={setShowWorkOutBuilder}
                    monthStartDate={calStartDate}
                    isHighlighted={isHighlighted}
                    selectedCalendarType={selectedCalendarType}
                    garminActivityData={garminActivityData}
                    ref={calenderRef}
                    isExpanded={isExpandedView}
                    reloadProfile={reloadProfile}
                    isAdminUserDetail={isAdminUserDetail}
                  />
                ) : (
                  <CalendarV2
                    loadingCalendar={loadingCalendar}
                    profileData={currentClientProfile}
                    setProfileData={setProfileData}
                    startDate={weekStartDateOne}
                    endDate={weekEndDateOne}
                    userUUID={userInfo?.id}
                    userInfo={userInfo}
                    setSelectedDate={setSelectedDate}
                    setShowWorkOutBuilder={setShowWorkOutBuilder}
                    setSelectedActivity={setSelectedActivity}
                    currentWeekOfMonth={currentWeekOfMonth}
                    isHighlighted={isHighlighted}
                    garminActivityData={garminActivityData}
                    selectedCalendarType={selectedCalendarType}
                    profileDataFromClientsView={profileData}
                    setProfileDataFromClientsView={setProfileData}
                    ref={calenderRef}
                    reloadProfile={reloadProfile}
                    isExpanded={isExpandedView}
                    isAdminUserDetail={isAdminUserDetail}
                  />
                )}
              </div>
            </Box>
          )}
          {selectedTab === "strengthDashboard" && (
            <StrengthDashboard
              handleStrengthDashboardClose={handleDashboardClose}
              openStrengthDashboard={selectedTab === "strengthDashboard"}
              trainerUUID={trainerUUID}
              userInfo={userInfo}
              activeUser={activeUser}
            />
          )}
          {selectedTab === "garminSummary" && (
            <GarminSummary athleteID={userInfo?.id} />
          )}
          {selectedTab === "summary" && (
            <SummarTab
              userUUID={userInfo?.id}
              trainerUUID={trainerUUID}
              isAdminUserDetail={isAdminUserDetail}
              summaryData={summaryData}
              isSmallerThanMd={isSmallerThanMd}
              reloadParticularClientsProfile={reloadProfile}
              isExpandedView={isExpandedView}
            />
          )}
          {selectedTab === "answeredForms" && userInfo?.id && (
            <AssignedForms
              athleteID={userInfo?.id}
              userUUID={userInfo?.id}
              trainerUUID={trainerUUID}
              isAdminUserDetail={isAdminUserDetail}
              formsAssigned={[
                {
                  formType: "Application form",
                  dateCreated: "2024-05-22T12:24:36.646Z",
                },
              ]}
              formAnswered={[]}
              setForms={setForms}
              form={form}
            />
          )}
          {selectedTab === "Dashboard" && (
            <ChatDashboard
              handleDashboardClose={handleDashboardClose}
              openDashboard={selectedTab === "Dashboard"}
              trainerUUID={trainerUUID}
              userInfo={userInfo}
              activeUser={activeUser}
            />
          )}
          {showAddTagsModal && (
            <AddTagsDialog
              isOpen={showAddTagsModal}
              setIsOpen={setShowAddTagsModal}
              userUUID={userInfo?.id}
              reloadClientSummary={reloadClientSummary}
              previousTags={userInfo?.userTags_LivEzy?.tags}
            />
          )}

          {showAddTargetWeightModal && (
            <AddTargetWeight
              isOpen={showAddTargetWeightModal}
              setIsOpen={setShowAddTargetWeightModal}
              userUUID={userInfo?.id}
              reloadClientSummary={reloadClientSummary}
              setIsUpdateUserWeight={setIsUpdateUserWeight}
              isUpdate={isUpdateUserWeight}
              setSummaryData={setSummaryData}
              summaryData={summaryData}
              setIsUpdateUserStepsCount={setIsUpdateUserStepsCount}
              isUpdateUserStepsCount={isUpdateUserStepsCount}
            />
          )}

          {showAddThreshold && (
            <AddTargetUserThresold
              isOpen={showAddThreshold}
              setIsOpen={setShowAddThreshold}
              userUUID={userInfo?.id}
              reloadClientSummary={reloadClientSummary}
              setIsUpdateUserThreshold={setIsUpdateUserThreshold}
              isUpdate={isUpdateUserThreshold}
              setSummaryData={setSummaryData}
              summaryData={summaryData}
            />
          )}
        </Box>
      )}

      {showAddCardioModal && (
        <AssignCardioModal
          isOpen={showAddCardioModal}
          setIsOpen={setShowAddCardioModal}
          userUUID={userInfo?.id}
          isThresholdAvailable={userInfo?.thresholdValues}
          startDate={selectedDate}
          setOpenImportMealModal={setOpenImportMealModal}
          setOpenCreateMealModal={setOpenCreateMealModal}
          setOpenWorkoutInfoModal={setOpenWorkoutInfoModal}
          setShowAddActivityModal={setShowAddActivityModal}
          setExistingCardioInfo={setExistingCardioInfo}
          clientProfile={profileData}
          setShowWorkoutLibrary={setShowWorkoutLibrary}
          setIsClientAvailable={setIsClientAvailable}
          onAssign={(newCardioWorkout) => {
            // update the profile data
            reloadProfile(userInfo?.id);
          }}
        />
      )}
      {showWorkOutBuilder && (
        <>
          <AddWorkoutAndMealModal
            showWorkOutBuilder={showWorkOutBuilder}
            setShowWorkOutBuilder={setShowWorkOutBuilder}
            setNewWorkoutModel={setNewWorkoutModel}
            newWorkoutModel={newWorkoutModel}
            setShowAddCardioModal={setShowAddCardioModal}
            userUUID={userInfo?.id}
            isThresholdAvailable={userInfo?.thresholdValues}
            selectedDate={selectedDate}
            setOpenImportMealModal={setOpenImportMealModal}
            setOpenCreateMealModal={setOpenCreateMealModal}
            setOpenWorkoutInfoModal={setOpenWorkoutInfoModal}
            setShowAddActivityModal={setShowAddActivityModal}
            setExistingCardioInfo={setExistingCardioInfo}
            clientProfile={profileData}
            setShowWorkoutLibrary={setShowWorkoutLibrary}
            setIsClientAvailable={setIsClientAvailable}
          />
        </>
      )}
      {showAddActivityModal && (
        <AddActivityDialog
          isOpen={showAddActivityModal}
          setIsOpen={setShowAddActivityModal}
          assignedActivities={selectedActivity?.[0]?.activitiesAssigned}
          onSaveActivity={(data, totalDays) => {
            onActivitiesSave(data, userInfo?.id, selectedDate, totalDays);
          }}
        />
      )}

      {openWorkoutInfoModal && (
        <WorkoutInfoDialog
          onSuccess={reloadProfileForWorkoutBuilderModal}
          reloadProfileForWorkoutBuilderModal={
            reloadProfileForWorkoutBuilderModal
          }
          isFromClientView={true}
          isOpen={openWorkoutInfoModal}
          setIsOpen={setOpenWorkoutInfoModal}
          data={{
            isCalendar: true,
            isAssign: true,
            userUUID: userInfo?.id,
            date: `${moment(selectedDate, "DD-MM-YYYY")
              .startOf("day")
              .format("YYYY-MM-DDTHH:mm:ss")}Z`,
            userInfo: userInfo,
          }}
        />
      )}

      {openImportMealModal && (
        <ImportMealModal
          showModal={openImportMealModal}
          setShowModal={setOpenImportMealModal}
          setmealDetails={setmealDetails}
          selectedDate={selectedDate}
          setShowWorkOutBuilder={setShowWorkOutBuilder}
          userUUID={userInfo?.id}
          setUpdateData={setUpdateData}
          updateData={updateData}
        />
      )}

      {openCreateMealModal && (
        <CreateMealPlanModal
          isOpen={openCreateMealModal}
          setIsOpen={setOpenCreateMealModal}
          setNutritionData={setMealData}
          openMacros={setOpenCreateMacrosModal}
          openFood={setOpenCreateFoodModal}
        />
      )}

      {openCreateMacrosModal && (
        // <CreateMacrosModal
        //   isOpen={openCreateMacrosModal}
        //   setIsOpen={setOpenCreateMacrosModal}
        //   setMealData={setMealData}
        //   item={mealData}
        //   selectedDate={selectedDate}
        //   userUUID={userInfo?.id}
        //   setUpdateData={setUpdateData}
        //   updateData={updateData}
        // />
        <AddMacrosBasedFood
          isOpen={openCreateMacrosModal}
          setIsOpen={setOpenCreateMacrosModal}
          item={mealData}
          getMealPlans={() => {
            reloadProfile(userInfo?.id);
          }}
          isTemplate={false}
          isCalendar={true}
          selectedDate={selectedDate}
          userUUID={userInfo?.id}
        />
      )}
      {openCreateFoodModal && (
        <AddMealsModal
          isOpen={openCreateFoodModal}
          setIsOpen={setOpenCreateFoodModal}
          item={mealData}
          getMealPlans={() => {
            reloadProfile(userInfo?.id);
          }}
          isUpdate={false}
          isTemplate={false}
          selectedDate={selectedDate}
          userUUID={userInfo?.id}
          isCalendar={true}
          ref={addMealPlanRef}
        />
        // <CreateFoodModal
        //   isOpen={openCreateFoodModal}
        //   setIsOpen={setOpenCreateFoodModal}
        //   setMealData={setMealData}
        //   item={mealData}
        //   selectedDate={selectedDate}
        //   userUUID={userInfo?.id}
        //   setUpdateData={setUpdateData}
        //   updateData={updateData}
        // />
      )}

      {
        //for unavailability of clients
        isClientAvailable && (
          <AddClientUnavailability
            isOpen={isClientAvailable}
            setIsOpen={setIsClientAvailable}
            assignedActivities={selectedActivity?.[0]?.activitiesAssigned}
            onSaveUnavailability={(data, totalDays) =>
              onActivitiesSave(data, userInfo?.id, selectedDate, totalDays)
            }
            selectedDate={selectedDate}
          />
        )
      }
    </Box>
  );
};
// data, userUUID, selectedDate, totalDays

export default ClientDetails;

function Tab({ label, value, selected, onClick, notesCount }) {
  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <p
        style={{
          fontFamily: "DM Sans",
          fontWeight: selected ? "700" : "500",
          fontSize: "14px",
          color: selected ? "#242424" : "#424242",
          cursor: "pointer",
          display: "flex",
          alignItems: "flex-end",
        }}
        onClick={() => onClick(value)}
      >
        {label}{" "}
        {notesCount ? (
          <span className="ml-1 w-[22px] text-center bg-gray-300 rounded-full">
            {notesCount}
          </span>
        ) : (
          ""
        )}
      </p>
      {selected && (
        <div
          style={{
            height: "3px",
            width: "100%",
            position: "absolute",
            bottom: -10,
            borderRadius: "4px",
            background: "linear-gradient(90deg, #464FEB, #8330E9)",
            transition: "all 0.3s ease-in-out",
          }}
        />
      )}
    </Box>
  );
}

const CustomChip = ({ icon, info, style, onClick }) => {
  let text = info.length > 16 ? info.substring(0, 16) + "..." : info;
  return (
    <Tooltip title={info} placement="top">
      <div
        style={{
          display: "flex",
          padding: "4px 16px",
          justifyContent: "center",
          alignItems: "center",
          gap: "4px",
          borderRadius: "16px",
          background: "#F2F2F2",
          fontFamily: "DM Sans",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "22px",
          width: "fit-content",
          cursor: "pointer",
          ...style,
        }}
        onClick={onClick}
      >
        {icon ? (
          <img
            src={icon}
            style={{
              width: "16px",
              height: "16px",
              marginRight: "4px",
            }}
          />
        ) : null}
        {text}
      </div>
    </Tooltip>
  );
};

const getTargetWeight = (userInfo) => {
  if (!userInfo) return null;
  let goalWeightAddedDate = moment(userInfo?.goalWeightAddedDate);
  let isMoreThan30DaysAgo = moment()
    .subtract(30, "days")
    .isAfter(goalWeightAddedDate);
  if (!isMoreThan30DaysAgo) {
    return userInfo?.goalWeight;
  }
  return null;
};

export const getIsPaused = (userSummary) => {
  let breakStartDate = userSummary?.breakStart_LivEzy;
  let breakEndDate = userSummary?.breakEnd_LivEzy;

  if (breakStartDate && breakEndDate) {
    breakStartDate = moment(breakStartDate);
    breakEndDate = moment(breakEndDate);

    // if today is between break start and end date then paused
    if (moment().isBetween(breakStartDate, breakEndDate)) {
      return true;
    }
  }
  return false;
};

// const AddWorkoutButton=({showWorkoutLibrary,setShowWorkoutLibrary})=>(
//   <div
//    className="font-DMSans border-2 rounded-[20px] mt-1 border-[#8330E9] px-[6px] h-[32px] flex items-center text-font14 cursor-pointer "
//   onClick={() => {
//     setShowWorkoutLibrary(!showWorkoutLibrary)
//   }}

// >
//  {!showWorkoutLibrary &&<span>Add Workout</span>}
//  {showWorkoutLibrary &&<span>Done</span>}
// </div>
// )
