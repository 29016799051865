import React, { useEffect, useState } from "react";
import {
  Avatar,
  useChannelActionContext,
  useChannelStateContext,
  useChatContext,
} from "stream-chat-react";

import "./TeamChannelHeader.css";
import CalendarGray from "../../../../assets/clientProfile/CalendarGray.png";
import { ChannelInfo, PinIcon, UserIcon } from "../../assets";
import {
  getChannelPrograms,
  getChannelProgramsWithProgram,
} from "../../../../ApiServices/ChannelProgramTracker/Api";

export const TeamChannelHeader = ({ setIsEditing, setPinsOpen }) => {
  const { client } = useChatContext();
  const { closeThread } = useChannelActionContext();
  const { channel, watcher_count } = useChannelStateContext();

  const [programAssigned, setProgramAssigned] = useState(null);

  const teamHeader = `# ${channel.data.name || channel.data.id || "random"}`;
  const colors = [
    "#CCF8FE",
    "#FFE5D3",
    "#DAD7FE",
    "#E2FBD7",
    "#FFE5D3",
    "#DAD7FE",
    "#E2FBD7",
    "#CCF8FE",
  ];

  const getMessagingHeader = () => {
    const members = Object.values(channel.state.members).filter(
      ({ user }) => user.id !== client.userID
    );
    const additionalMembers = members.length - 3;

    if (!members.length) {
      return (
        <div className="team-channel-header__name-wrapper">
          <Avatar image={null} size={32} />
          <p className="team-channel-header__name user">Johnny Blaze</p>
        </div>
      );
    }

    return (
      <div className="team-channel-header__name-wrapper">
        {members.map(({ user }, i) => {
          if (i > 2) return null;
          return (
            <div key={i} className="team-channel-header__name-multi">
              {user.image ? (
                <Avatar
                  image={user.image}
                  name={user.name || user.id}
                  size={32}
                />
              ) : (
                <div
                  className="channel-preview__item_avatar"
                  style={{
                    backgroundColor: colors[Math.round(parseInt(user.id) % 7)],
                  }}
                >
                  <UserIcon />
                </div>
              )}
              <p className="team-channel-header__name user">
                {user.name || user.id || "Johnny Blaze"}
              </p>
            </div>
          );
        })}
        {additionalMembers > 0 && (
          <p className="team-channel-header__name user">{`and ${additionalMembers} more`}</p>
        )}
      </div>
    );
  };

  const getWatcherText = (watchers) => {
    if (!watchers) return "No users online";
    if (watchers === 1) return "1 user online";
    return `${watchers} users online`;
  };

  useEffect(() => {
    if (channel.type === "team") {
      getProgramAssigned(channel.id);
    }

    return () => {
      setProgramAssigned(null);
    };
  }, [channel]);

  const getProgramAssigned = async (channelID) => {
    let channelProgram = await getChannelProgramsWithProgram(channel.id);
    if (channelProgram && channelProgram?.program?.name) {
      setProgramAssigned({
        name: channelProgram.program.name,
        id: channelProgram.program.id,
      });
    } else {
      setProgramAssigned(null);
    }
  };

  return (
    <div className="team-channel-header__container">
      {channel.type === "messaging" ? (
        getMessagingHeader()
      ) : (
        <div className="team-channel-header__channel-wrapper">
          <p className="team-channel-header__name">{teamHeader}</p>
          <span style={{ display: "flex" }} onClick={() => setIsEditing(true)}>
            <ChannelInfo />
          </span>
        </div>
      )}
      <div className="team-channel-header__right">
        {programAssigned && (
          <div
            onClick={() => {
              // open in new tab
              window.open(
                `${window.location.origin}/programs/${programAssigned?.id}`,
                "_blank"
              );
            }}
            className="team-channel-header__right-pin-wrapper"
          >
            <img
              style={{
                width: "16px",
                height: "16px",
              }}
              src={CalendarGray}
              alt="calendar"
            />
            <p className="team-channel-header__right-text">
              {programAssigned?.name}
            </p>
          </div>
        )}
        {/* <div className='team-channel-header__right-online'>
          <p className='team-channel-header__right-text'>
            {getWatcherText(watcher_count)}
          </p>
        </div> */}

        <div
          className="team-channel-header__right-pin-wrapper"
          onClick={(e) => {
            closeThread(e);
            setPinsOpen((prevState) => !prevState);
          }}
        >
          <PinIcon />
          <p className="team-channel-header__right-text">Pinned Messages</p>
        </div>
      </div>
    </div>
  );
};
