import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const authPersistConfig = {
  key: "auth",
  storage,
};

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuthenticated: false,
    trainerUUID: null,
    trainingType: null,
    timeZone: null,
    data: null,
    // one trainer data
    clientsData: null,
    seenNotification: false,
    name: "",
    mail: "",
    templates: [],
    subbordinateCoachIDs: [],
    athleteCount: 0,
    isActiveSubscription: false,
    isLivezy: false,
    apiURL: "https://getfitaiserver.com/",
  },
  reducers: {
    login: (state, action) => {
      state.isAuthenticated = true;
      state.trainerUUID = action.payload.trainerUUID;
      state.trainingType = action.payload.trainingType;
      state.timeZone = action.payload.timeZone;
      state.name = action.payload.name;
      state.mail = action.payload.mail;
      state.athleteCount = action.payload.athleteCount;
      state.isActiveSubscription = action.payload.isActiveSubscription;
      state.isLivezy = action.payload.isLivezy;
      state.apiURL = action.payload.apiURL;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.trainerUUID = null;
      state.trainingType = null;
      state.timeZone = null;
      state.data = null;
      state.clientsData = null;
      state.seenNotification = false;
      state.name = "";
      state.mail = "";
      state.templates = [];
      state.subbordinateCoachIDs = [];
      state.athleteCount = 0;
      state.isActiveSubscription = false;
      state.isLivezy = false;
      state.apiURL = "https://getfitaiserver.com/";
    },
    setTrainerUUID: (state, action) => {
      state.trainerUUID = action.payload;
    },
    setApiURL: (state, action) => {
      state.apiURL = action.payload;
    },
    setTrainerData: (state, action) => {
      state.data = action.payload;
    },
    setisActiveSubscription: (state, action) => {
      state.isActiveSubscription = action.payload;
    },
    setAthleteCount: (state, action) => {
      state.athleteCount = action.payload;
    },
    updateTrainerData: (state, action) => {
      state.data = {
        ...state.data,
        ...action.payload,
      };
    },
    setClientsData: (state, action) => {
      state.clientsData = action.payload;
    },
    setSeenNotification: (state, action) => {
      state.seenNotification = action.payload;
    },
    setWalkthroughStatus: (state, action) => {
      state.data = {
        ...state.data,
        walkthroughStatus: action.payload,
      };
    },
    setTemplateData: (state, action) => {
      state.templates = action.payload;
    },
    setSubbordinateCoachIDs: (state, action) => {
      state.subbordinateCoachIDs = action.payload;
    },
    setIsActiveSubscription: (state, action) => {
      state.isActiveSubscription = action.payload;
    },
  },
});

export const {
  login,
  logout,
  setTrainerData,
  setClientsData,
  setSeenNotification,
  setWalkthroughStatus,
  updateTrainerData,
  setTemplateData,
  setSubbordinateCoachIDs,
  setisActiveSubscription,
  setAthleteCount,
  setIsActiveSubscription,
  setApiURL,
  setTrainerUUID,
} = authSlice.actions;

export default persistReducer(authPersistConfig, authSlice.reducer);
