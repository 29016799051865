import axios from "axios";
import mixpanel from "mixpanel-browser";
import { Component } from "react";
import { setWalkthroughStatus } from "../redux/authSlice";
import { store } from "../redux/store";
import moment from "moment";

export const withWindowDimensions = (WrappedComponent) => {
  return class extends Component {
    state = { width: 0, height: 0 };

    componentDidMount() {
      this.updateWindowDimensions();
      window.addEventListener("resize", this.updateWindowDimensions);
    }

    componentWillUnmount() {
      window.removeEventListener("resize", this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
      this.setState({ width: window.innerWidth, height: window.innerHeight });
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          windowWidth={this.state.width}
          windowHeight={this.state.height}
          isMobileSized={this.state.width < 700}
        />
      );
    }
  };
};

export const updateWalkthoughStatus = async (pageStatus, status) => {
  let prevWalkthroughStatus = pageStatus;
  let newWalkthroughStatus = status;
  if (prevWalkthroughStatus) {
    newWalkthroughStatus = { ...prevWalkthroughStatus, ...status };
  }
  console.log({ newWalkthroughStatus, prevWalkthroughStatus });
  await axios({
    method: "post",
    url: `${store.getState().auth.apiURL
      }CF/api/CoachWeb/Update/updateCoachWalkthroughStatus`,
    data: {
      coachID: store.getState().auth?.trainerUUID,
      walkthroughStatus: newWalkthroughStatus,
    },
  })
    .then((response) => {
      if (response.data.success) {
        store.dispatch(setWalkthroughStatus(newWalkthroughStatus));
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
export const mixPanelInit = () => {
  mixpanel.init("147a208f5832549c876d94601401033e", {
    api_host: "https://api.mixpanel.com",
    ignore_dnt: true,
  });
};

//for date converting in YYYY-MM-DD format

export const convertDateToYearMonthDayFormat = (date) => moment(date).format("YYYY-MM-DD")


//for check of today or future 

export const funcTodayOrFutureDate = (dateString) => {

  const today = new Date();
  const inputDate = new Date(dateString);
  if (moment(today).format("YYYY-MM-DD") == dateString)
    return true
  else
    return (inputDate > today)
}
