import React from "react";
import DumbellIcon from "../../assets/library/dumbell.png";
import { ClipboardCheck, Files, X } from "lucide-react";

import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from "@/components/ui/context-menu";

const RenderOneWorkout = ({
  workout,
  index,
  onClick,
  onCopy,
  onCopyCancel,
  isCopied,
  copiedWorkout,
  onPaste,
}) => {
  const colors = ["#FFF0E5", "#E8E6FE", "#EEFDE8"];

  let restColor = "#F7F7F7";
  const isRest = workout?.rest;
  const isCardio = workout?.name === "cardio";
  const isEndurance =
    workout?.workoutKind === "run" ||
    workout?.workoutKind === "bike" ||
    workout?.workoutKind === "swim";

  const convertSecondsToMinute = (seconds) => {
    return Math.floor(seconds / 60);
  };
  const calculateRemainingSeconds = (seconds) => {
    return seconds % 60;
  };
  return (
    <ContextMenu>
      <ContextMenuTrigger>
        <div
          className={`group w-full h-[48px] rounded-md flex items-center cursor-pointer relative ${
            isRest ? "justify-center" : ""
          }`}
          style={{
            background: isRest ? restColor : colors[index % 3],
            padding: "6px 8px",
          }}
          onClick={onClick}
        >
          {isCopied && (
            <div className="absolute top-0 left-0 w-full h-full bg-black-pure bg-opacity-80 rounded-md flex items-center justify-center">
              <p className="text-white-pure">Copied</p>
              <div
                className="absolute top-0 right-0 cursor-pointer z-50"
                onClick={(e) => {
                  e.stopPropagation();
                  onCopyCancel();
                }}
              >
                <X color="#fff" />
              </div>
            </div>
          )}
          {copiedWorkout?.workout && !isCopied ? (
            <div
              className="absolute top-0 right-0 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                onPaste();
              }}
            >
              <ClipboardCheck size={16} />
            </div>
          ) : (
            !isRest && (
              <div
                className="absolute top-1 right-1 cursor-pointer hidden group-hover:flex"
                onClick={(e) => {
                  e.stopPropagation();
                  onCopy();
                }}
              >
                <Files size={16} color="#737373" />
              </div>
            )
          )}
          <div className={`flex items-center gap-2 `}>
            {!isRest && (
              <img
                src={
                  isCardio
                    ? "https://img.icons8.com/ios/100/737373/running--v1.png"
                    : DumbellIcon
                }
                style={{ width: "20px", height: "20px", marginLeft: "10px" }}
              />
            )}
            <div>
              <p className="week-card-conatiner-draggable-text">
                {workout?.name} {isRest ? "Rest" : ""}
              </p>
              {workout?.runTime > 0 && !isEndurance && (
                <p className="week-card-conatiner-draggable-description">
                  {convertSecondsToMinute(workout?.runTime)} mins{" "}
                  {calculateRemainingSeconds(workout?.runTime)} secs
                </p>
              )}
            </div>
          </div>
        </div>
      </ContextMenuTrigger>
      <ContextMenuContent>
        {copiedWorkout?.workout && !isCopied ? (
          <ContextMenuItem
            className="flex items-center gap-2 cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              onPaste();
            }}
          >
            <ClipboardCheck size={16} color="#737373" />
            Paste
          </ContextMenuItem>
        ) : (
          !isRest && (
            <ContextMenuItem
              className="flex items-center gap-2 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                onCopy();
              }}
            >
              <Files size={16} color="#737373" />
              Copy
            </ContextMenuItem>
          )
        )}
      </ContextMenuContent>
    </ContextMenu>
  );
};

export default RenderOneWorkout;
