import APIURL from "@/APIDetails";
import axios from "axios";

export const generateWorkoutPlan = async (prompt) => {
  try {
    let res = await axios({
      method: "post",
      url: `${APIURL}CF/api/claude/getWorkoutPlan`,
      data: {
        prompt: prompt,
      },
    });

    return res.data.result;
  } catch (error) {
    return null;
  }
};

export const generateDietPlan = async (prompt) => {
  try {
    let res = await axios({
      method: "post",
      url: `${APIURL}CF/api/claude/getDietPlan`,
      data: {
        prompt: prompt,
      },
    });

    return res.data.result;
  } catch (error) {
    return null;
  }
};

export const generateSingleWorkout = async (prompt) => {
  try {
    let res = await axios({
      method: "post",
      url: `${APIURL}CF/api/claude/getSingleWorkout`,
      data: {
        prompt: prompt,
      },
    });

    return res.data.result;
  } catch (error) {
    return null;
  }
};

export const generateSingleMealPlan = async (prompt) => {
  try {
    let res = await axios({
      method: "post",
      url: `${APIURL}CF/api/claude/getSingleMealPlan`,
      data: {
        prompt: prompt,
      },
    });

    return res.data.result;
  } catch (error) {
    return null;
  }
};
