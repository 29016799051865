import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useRef, useState } from "react";
import "react-phone-number-input/style.css";
import Select from "react-select";
import CloseIcon from "../../assets/trainerProfile/closeIcon.svg";

import CustomInputField from "./CustomInputField";
import SocialMediaInputField from "./SocialMediaInputField";

import { useSelector } from "react-redux";
import CameraIcon from "../../assets/trainerProfile/cameraIcon.svg";
import FbIcon from "../../assets/trainerProfile/fbIcon.svg";
import InstaIcon from "../../assets/trainerProfile/instaIcon.svg";
import PlaceholderImage from "../../assets/trainerProfile/placeholder.svg";
import TwitterIcon from "../../assets/trainerProfile/twitterIcon.svg";
import ImageUploadWithCrop from "./ImageUploadWithCrop";

const EditInfoModal = ({ open, setOpen, trainerInfo, getProfileData }) => {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const [phone, setPhone] = useState(trainerInfo?.contactNumber || "");
  const [name, setName] = useState(
    trainerInfo?.name || trainerInfo?.firstName + trainerInfo?.lastName || ""
  );

  const [trainerTag, setTrainerTag] = useState(trainerInfo?.trainerTag || "");

  const [email, setEmail] = useState(trainerInfo?.email || "");
  const [bio, setBio] = useState(trainerInfo?.bio || "");

  const [experience, setExperience] = useState(trainerInfo?.experience || "");
  const [clientCount, setClientCount] = useState(
    trainerInfo?.numberOfClients || ""
  );
  const [totalPlans, setTotalPlans] = useState(
    trainerInfo?.totalPlannedWorkouts || ""
  );

  const [instaHandle, setInstaHandle] = useState(
    trainerInfo?.socailHandles?.insta || null
  );
  const [twitterHandle, setTwitterHandle] = useState(
    trainerInfo?.socailHandles?.twitter || null
  );

  const [fbHandle, setFbHandle] = useState(
    trainerInfo?.socailHandles?.fb || null
  );

  const [linkedinHandle, setLinkedinHandle] = useState(
    trainerInfo?.socailHandles?.linkedin || null
  );

  const [linkTreeHandle, setLinkTreeHandle] = useState(
    trainerInfo?.socailHandles?.linktree || null
  );

  const [achievements, setAchievements] = useState(
    trainerInfo?.achivements || []
  );
  const [numAchievements, setNumAchievements] = useState(
    trainerInfo?.achivements?.length || 1
  );
  const [loading, setLoading] = useState(false);

  const [coverImage, setCoverImage] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const coverImageRef = useRef(null);

  const onSave = async () => {
    setLoading(true);
    let trainerDetails = JSON.parse(localStorage.getItem("trainerCredentials"));
    await uploadCoverImage();
    await uploadProfilePic();
    await axios({
      method: "post",
      url: `${APIURL}CF/api/CoachWeb/Update/UpdateCoachFields`,
      data: {
        coachID: trainerDetails?.password,
        name: name,
        email: email,
        contactNumber: phone,
        bio: bio,
        socailHandles: {
          insta: instaHandle,
          twitter: twitterHandle,
          fb: fbHandle,
          linkedin: linkedinHandle,
          linktree: linkTreeHandle,
        },
        achivements: achievements,
        trainerTag: trainerTag,
        experience: experience,
        totalPlannedWorkouts: totalPlans,
        numberOfClients: clientCount,
      },
    })
      .then(async (res) => {
        getProfileData();
      })
      .catch((err) => {
        console.log("error updating details: ", err);
      })
      .finally(() => {
        setOpen(false);
        setLoading(false);
      });
  };

  const handleCoverPicSelect = async (event) => {
    let file = event.target.files[0];
    console.log(file);
    setCoverImage(file);
  };

  const handleProfilePicSelect = async (file) => {
    setProfileImage(file);
    setOpenDialog(false);
  };

  const uploadCoverImage = async () => {
    if (!coverImage || coverImage === "") {
      return;
    }
    console.log("cover image: ", coverImage);
    let trainerDetails = JSON.parse(localStorage.getItem("trainerCredentials"));
    const formData = new FormData();
    formData.append("image", coverImage);

    await axios({
      method: "post",
      url: `${APIURL}api/uploadtrainercoverprofilepic?trainerUUID=${trainerDetails?.password}`,
      data: formData,
    })
      .then(async (res) => {
        // toast("Successfully uploaded", "success");
      })
      .catch((err) => {
        // toast("Incorrect Image dimension. Need to be square", "error");
      });
  };

  const uploadProfilePic = async () => {
    if (!profileImage || profileImage === "") {
      return;
    }
    const formData = new FormData();
    formData.append("image", profileImage);

    let trainerDetails = JSON.parse(localStorage.getItem("trainerCredentials"));

    await axios({
      method: "post",
      url: `${APIURL}api/uploadtrainerprofilepic?trainerUUID=${trainerDetails?.password}`,
      data: formData,
    })
      .then(async (res) => {
        // toast("Successfully Uploaded!", "success");
      })
      .catch((err) => {
        // toast("Incorrect Image dimension. Need to be square", "error");
      });
  };

  return (
    <Dialog
      open={open}
      maxWidth="md"
      PaperProps={{
        sx: {
          maxHeight: "786px",
          width: "723px",
          borderRadius: "16px",
          pb: "30px",
        },
      }}
      className="zoomedBody"
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          position: "relative",
          justifyContent: "space-between",

          mt: "12px",
        }}
      >
        <DialogTitle
          sx={{
            ml: "9px",
            fontSize: "24px",
            fontFamily: "DM Sans",
            fontWeight: 700,
            color: "#121314",
          }}
        >
          Edit Profile
        </DialogTitle>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            // position: "absolute",
            // top: "23px",
            // right: "30px",
            mr: "30px",
          }}
        >
          {/* <button
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "linear-gradient(to right, #464FEB, #8330E9)",
              borderRadius: "80px",
              color: "#fff",
              fontFamily: "DM Sans",
              fontWeight: "400",
              fontSize: "18px",
              padding: "6px 14px",
              height: "35px",
              marginRight: "20px",
            }}
            onClick={onSave}
          >
            Save
          </button> */}
          <img
            src={CloseIcon}
            style={{
              cursor: "pointer",
              width: "28px",
              height: "28px",
            }}
            onClick={() => {
              setOpen(false);
            }}
          />
        </Box>
      </Box>
      <DialogContent
        sx={{
          px: "30px",
          overflowX: "hidden",
        }}
      >
        <Box
          sx={{
            width: "663px",
            height: "214px",
            backgroundImage: `url(${
              (coverImage
                ? URL.createObjectURL(coverImage)
                : trainerInfo?.coverimageKey) || PlaceholderImage
            })`,
            boxShadow: "inset 0 0 0 2000px rgba(0, 0, 0, 0.6)",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            position: "relative",
            mb: "73px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              height: "38px",
              width: "38px",
              padding: "10px",
              background: "rgba(62, 62, 62, 0.72)",
              borderRadius: "50%",
              display: "flex",
            }}
          >
            <img
              src={CameraIcon}
              style={{
                height: "100%",
                width: "100%",
                backdropFilter: "blur(7px)",
                borderRadius: "38px",
                cursor: "pointer",
              }}
              onClick={() => coverImageRef.current.click()}
            />
            <input
              ref={coverImageRef}
              id="file-input"
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={(e) => handleCoverPicSelect(e)}
            />
          </div>

          <Box sx={{ position: "absolute", top: "169px", left: "21px" }}>
            <img
              style={{
                height: "108px",
                width: "108px",
                borderRadius: "50%",
                border: "4px solid #fff",
              }}
              src={
                profileImage
                  ? URL.createObjectURL(profileImage)
                  : trainerInfo?.imageKey
              }
              alt="trainer"
            />

            <div
              style={{
                position: "absolute",
                height: "100%",
                width: "100%",
                bottom: 0,
                top: 0,
                right: 0,
                left: 0,
                backgroundColor: "rgba(0, 0, 0, 0.53)",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "4px solid #fff",
              }}
            >
              <img
                src={CameraIcon}
                alt="camera"
                style={{
                  hegiht: "30px",
                  width: "30px",
                }}
                onClick={() => setOpenDialog(true)}
              />
            </div>
          </Box>
        </Box>

        <CustomInputField
          label={"Name"}
          value={name}
          onChange={(e) => setName(e.target.value)}
          fontWeight={500}
          color={"#434547"}
        />

        <CustomInputField
          style={{ marginTop: 14 }}
          label={"Trainer Tag"}
          value={trainerTag}
          onChange={(e) => setTrainerTag(e.target.value)}
          fontWeight={400}
          color={"#434547"}
        />

        <Box className="flex" style={{ marginTop: 14, gap: "30px" }}>
          {/* Phone Input  */}
          {/* <div
            style={{
              width: "257px",
              background: "#FFFFFF",
              marginRight: 19,
            }}
          >
            <label
              htmlFor="inputField"
              style={{ display: "block", fontSize: 10, marginLeft: 12 }}
            >
              Mobile
            </label>
            <PhoneInput
              style={{
                width: "100%",
                height: "41px",
                borderRadius: "5px",
                border: "1px solid #071148",
                padding: "10px",
              }}
              placeholder="Enter phone number"
              defaultCountry="US"
              value={phone}
              onChange={setPhone}
              focusInputOnCountrySelection
            />
          </div> */}

          <CustomInputField
            style={{
              flex: 1,
            }}
            label={"Email"}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fontWeight={400}
            color={"#333338"}
          />

          <CustomInputField
            label={"Phone"}
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            style={{ flex: 1 }}
            fontWeight={400}
            color={"#333338"}
          />
        </Box>

        {/*<CustomInputField*/}
        {/*  label={"About"}*/}
        {/*  value={bio}*/}
        {/*  onChange={(e) => setBio(e.target.value)}*/}
        {/*  style={{ marginTop: 14 }}*/}
        {/*  color={"#434547"}*/}
        {/*  fontWeight={400}*/}
        {/*/>*/}

        <Box className="flex" style={{ marginTop: 14, gap: "30px" }}>
          <CustomInputField
            label={"Years of experience"}
            value={experience}
            onChange={(e) => setExperience(e.target.value)}
            fontWeight={400}
            color={"#333338"}
            style={{ flex: 1 }}
          />

          <CustomInputField
            label={"Number of clients"}
            value={clientCount}
            onChange={(e) => setClientCount(e.target.value)}
            fontWeight={400}
            color={"#333338"}
            style={{ flex: 1 }}
          />
        </Box>

        <CustomInputField
          label={"Total workout plans"}
          value={totalPlans}
          onChange={(e) => setTotalPlans(e.target.value)}
          fontWeight={400}
          color={"#333338"}
          style={{ width: "calc(50% - 15px)", marginTop: "14px" }}
        />

        {/* social media handles  */}
        <Box
          sx={{
            mt: "30px",
            display: "flex",
            flexDirection: "column",
            gap: "19px",
          }}
        >
          <Typography
            sx={{
              color: "#303032",
              fontWeight: 500,
              fontFamily: "DM Sans",
              fontSize: "18px",
            }}
          >
            Add your social profiles
          </Typography>

          <SocialMediaInputField
            label={"Instagram Link"}
            value={instaHandle}
            onChange={(e) => setInstaHandle(e.target.value)}
            style={{ marginRight: 19 }}
            img={InstaIcon}
          />

          <SocialMediaInputField
            label={"Twitter Link"}
            value={twitterHandle}
            onChange={(e) => setTwitterHandle(e.target.value)}
            style={{ marginRight: 19 }}
            img={TwitterIcon}
          />
          <SocialMediaInputField
            label={"Facebook Link"}
            value={fbHandle}
            onChange={(e) => setFbHandle(e.target.value)}
            style={{ marginRight: 19 }}
            img={FbIcon}
          />

          <SocialMediaInputField
            label={"Linkedin Link"}
            value={linkedinHandle}
            onChange={(e) => setLinkedinHandle(e.target.value)}
            style={{ marginRight: 19 }}
            img={
              "https://upload.wikimedia.org/wikipedia/commons/thumb/8/81/LinkedIn_icon.svg/1200px-LinkedIn_icon.svg.png"
            }
          />

          <SocialMediaInputField
            label={"Linktree Link"}
            value={linkTreeHandle}
            onChange={(e) => setLinkTreeHandle(e.target.value)}
            style={{ marginRight: 19 }}
            img={
              "https://img.utdstc.com/icon/0d4/e93/0d4e9331c3b8346858e1e5c4f77e9dfd92dccf8c38db0b280dba00076e5d5dc0:200"
            }
          />
        </Box>
        <div style={{ width: "100%" }}>
          <div style={{ marginTop: 30, width: "100%" }}>
            <p
              style={{
                fontSize: "18px",
                fontFamily: "DM Sans",
                fontWeight: 500,
                color: "#303032",
              }}
            >
              Add additional achievement{" "}
              <span style={{ fontSize: "10px", fontFamily: "DM Sans" }}>
                (optional)
              </span>
            </p>
            <p
              style={{
                color: "#FF8C1A",
                fontSize: "12px",
                fontFamily: "DM Sans",
              }}
            >
              You can add up to 3 achievements
            </p>
          </div>
          {Array.from({ length: numAchievements }, (_, index) => (
            <AchievementInput
              achievements={achievements}
              setAchievements={setAchievements}
              numAchievements={numAchievements}
              setNumAchievements={setNumAchievements}
              index={index}
            />
          ))}
        </div>
      </DialogContent>

      <DialogActions>
        <button
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "linear-gradient(to right, #464FEB, #8330E9)",
            borderRadius: "80px",
            color: "#fff",
            fontFamily: "DM Sans",
            fontWeight: "400",
            fontSize: "18px",
            padding: "6px 14px",
            height: "35px",
            marginRight: "auto",
            marginLeft: "auto",
          }}
          onClick={onSave}
        >
          Save
        </button>
      </DialogActions>

      <ImageUploadWithCrop
        open={openDialog}
        setOpen={setOpenDialog}
        uploadProfilePic={handleProfilePicSelect}
      />
    </Dialog>
  );
};

export default EditInfoModal;

const AchievementInput = ({
  achievements,
  setAchievements,
  numAchievements,
  setNumAchievements,
  index,
}) => {
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 51 }, (_, i) => ({
    value: currentYear - i,
    label: `${currentYear - i}`,
  }));

  const customStyles = {
    menu: (base) => ({
      ...base,
      width: "100%",
      maxWidth: "none",
      fontFamily: "DM Sans",
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "white",
      borderColor: "#071148",
      boxShadow: state.isFocused ? "0 0 0 1px #071148" : provided.boxShadow,
      fontFamily: "DM Sans",
      "&:hover": {
        borderColor: "#071148",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      fontFamily: "DM Sans",
      backgroundColor: state.isSelected ? "#071148" : "white",
      color: state.isSelected ? "white" : "#071148",
      "&:hover": {
        backgroundColor: state.isSelected ? "#071148" : "#f2f2f2",
        color: state.isSelected ? "white" : "#071148",
      },
    }),
  };

  return (
    <>
      <div className="flex items-center" style={{ marginTop: 20 }}>
        <div
          style={{
            // width: "auto",
            flex: 1,
            background: "#FFFFFF",
            borderRadius: "8px",
            marginRight: "24px",
          }}
          className="text-blue-main"
        >
          <label
            htmlFor="inputField"
            style={{
              display: "block",
              fontSize: 14,
              marginLeft: 3,
              fontFamily: "DM Sans",
            }}
          >
            Achievement name
          </label>
          <input
            type="text"
            id="inputField"
            placeholder={"Achievement name "}
            className="placeholder-blue-main placeholder-opacity-50"
            style={{
              width: "100%",
              height: "40px",
              borderRadius: "5px",
              border: "1px solid #071148",
              padding: "10px",
              outline: "none",
              fontFamily: "DM Sans",
              fontSize: "16px",
            }}
            value={achievements?.[index]?.name || ""}
            onChange={(e) =>
              setAchievements((prevState) => {
                let newState = [...prevState];
                if (!newState[index]) {
                  newState[index] = { name: e.target.value };
                } else {
                  newState[index].name = e.target.value;
                }
                return newState;
              })
            }
          />
        </div>
        <div style={{ width: "190px" }}>
          <label
            htmlFor="inputField"
            style={{
              display: "block",
              fontSize: 14,
              marginLeft: 3,
              fontFamily: "DM Sans",
            }}
          >
            Achievement Year
          </label>
          <Select
            styles={customStyles}
            options={years}
            // menuPortalTarget={document.body}
            value={{
              label: achievements?.[index]?.year,
              value: achievements?.[index]?.year,
            }}
            onChange={(y) =>
              setAchievements((prevState) => {
                let newState = [...prevState];
                if (!newState[index]) {
                  newState[index] = { year: y.value };
                } else {
                  newState[index].year = y.value;
                }
                console.log(newState);
                return newState;
              })
            }
            menuPlacement="top" // Set the placement of the menu
            menuPosition={{ top: "auto", bottom: "100%", left: 0 }} // Set the position of the menu
          />
        </div>
        {index == numAchievements - 1 && index != 2 ? (
          <AddCircleRoundedIcon
            onClick={() =>
              setNumAchievements((prevState) =>
                prevState < 3 ? prevState + 1 : prevState
              )
            }
            style={{ marginTop: "12px", marginLeft: 28, cursor: "pointer" }}
            fontSize={"large"}
          />
        ) : (
          <RemoveCircleRoundedIcon
            onClick={() => {
              let newAchievements = [...achievements];
              newAchievements.splice(index, 1);
              setAchievements(newAchievements);
              setNumAchievements((prevState) =>
                prevState > 0 ? prevState - 1 : prevState
              );
              console.log(achievements);
            }}
            style={{ marginTop: "12px", marginLeft: 28, cursor: "pointer" }}
            fontSize={"large"}
          />
        )}
      </div>
    </>
  );
};
