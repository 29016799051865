import moment from "moment";
import { useState } from "react";
import { StatsCard } from "../DescriptionComp/ClientProfileSummary";

const UserForms = ({ data, setSelectedForm }) => {
  const [forms, setForms] = useState(data);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#FFFFFF",
        borderRadius: "10px",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.03)",
        height: "fit-content",
        width: "100%",
        minWidth: 300,
      }}
    >
      <div
        style={{
          padding: "12px 24px",
          borderBottom: "1px solid #EBEBEB",
        }}
        className="flex justify-between items-center"
      >
        <p
          style={{
            fontFamily: "DM Sans",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "29px",
          }}
        >
          Forms
        </p>
      </div>
      {!forms || forms.length == 0 ? (
        <p
          style={{
            fontFamily: "DM Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "29px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px 0px",
          }}
        >
          No forms assigned yet!
        </p>
      ) : (
        <div className="flex flex-col overflow-scroll max-h-[330px]">
          {forms?.map((_form) => {
            return (
              <div
                className="w-full cursor-pointer"
                onClick={() => {
                  setSelectedForm(_form);
                }}
              >
                <StatsCard
                  title={_form?.form?.formType}
                  value={moment(_form?.form?.dateCreated)?.format("Do MMM YY")}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default UserForms;
