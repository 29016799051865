import { Typography } from "@mui/material";
import React, { useContext } from "react";
import UncheckedIcon from "../../../assets/trainerProfile/unchecked.svg";
import CheckedIcon from "../../../assets/trainerProfile/checked.svg";

import { CopilotContext } from "../copilot";

let colorsArray = ["#00D43B", "#464FEB", "#FF88DE", "#9237E3", "#FF9B04"];
export default function RecipeCard({
  data,
  index,
  day,
  onSelect,
  selected,
  disableActions,
}) {
  const { onRecipeClick } = useContext(CopilotContext);
  const recipe = data;
  let calories = recipe?.serving_sizes?.calories;
  let macros = recipe?.serving_sizes;
  let serving_size = recipe?.serving_sizes?.serving_size;

  return (
    <div
      style={{
        borderRadius: "8px",
        width: "235px",
        minHeight: "90px",
        boxShadow:
          "0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 4px 8px 0px rgba(0, 0, 0, 0.14)",
        textAlign: "left",
        paddingLeft: "16px",
        marginBottom: "9px",
        fontFamily: "DM Sans",
        position: "relative",
        overflow: "hidden",
        backgroundColor: "#ffffff",
        cursor: "pointer",
      }}
      onClick={() => onRecipeClick(index)}
    >
      {selected ? (
        <img
          style={{
            height: "12px",
            width: "12px",
            position: "absolute",
            right: "9px",
            top: "8px",
            cursor: "pointer",
            pointerEvents: disableActions ? "none" : "auto",
          }}
          src={CheckedIcon}
          onClick={(e) => {
            e.stopPropagation();
            onSelect(false);
          }}
        />
      ) : (
        <img
          style={{
            height: "12px",
            width: "12px",
            position: "absolute",
            right: "9px",
            top: "8px",
            cursor: "pointer",
            pointerEvents: disableActions ? "none" : "auto",
          }}
          src={UncheckedIcon}
          onClick={(e) => {
            e.stopPropagation();
            onSelect(true);
          }}
        />
      )}

      <div
        style={{
          position: "absolute",
          left: 0,
          height: "100%",
          width: "5px",
          backgroundColor: colorsArray[index % colorsArray.length],
        }}
      />
      {/* <img
        style={{
          height: "12px",
          width: "12px",
          position: "absolute",
          right: "9px",
          top: "8px",
          cursor: "pointer",
        }}
        src={PencilIcon}
        onClick={() => onEditMealPlan(data)}
      /> */}
      {calories && (
        <Typography
          sx={{
            fontSize: "8px",
            fontWeight: 700,
            color: "#09A033",
            paddingTop: "14px",
            lineHeight: "10px",
            fontFamily: "DM Sans",
          }}
        >
          {calories} CAL | {serving_size}
        </Typography>
      )}
      {recipe?.recipe_name && (
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: 500,
            color: "#000",
            paddingTop: "1px",
            lineHeight: "16px",
            fontFamily: "DM Sans",
          }}
        >
          {recipe?.recipe_name}
        </Typography>
      )}

      <div
        style={{
          marginTop: "4px",
          marginBottom: "17px",
          marginRight: "5px",
          marginLeft: "5px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "DM Sans",
            fontWeight: 500,
            color: "rgba(36, 36, 36, 0.75)",
            fontSize: "10px",
          }}
        >
          P: {macros?.protein}g | C: {macros?.carbs}g | F: {macros?.fat}g
        </Typography>
        {/* {meals?.map((meal, index) => {
          const foods = meal.foods;
          let foodString = foods?.map((food) => food.name).join(", ");

          return (
            <li style={{ display: "flex" }}>
              <Typography
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 500,
                  color: "rgba(36, 36, 36, 0.75)",
                  fontSize: "10px",
                  marginRight: "5px",
                }}
              >
                •
              </Typography>
              <Typography
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 500,
                  color: "rgba(36, 36, 36, 0.75)",
                  fontSize: "10px",
                }}
              >
                {foodString}
              </Typography>
            </li>
          );
        })} */}
      </div>
    </div>
  );
}
