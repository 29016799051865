import axios from "axios";
import Papa from "papaparse";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useSelector } from "react-redux";
import { getVideoId } from "../pages/Exercises/exerciseUtil";

const AddTrainerExercises = () => {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const [exercises, setExercises] = useState([]);
  const [excelData, setExcelData] = useState(null);
  const [isExcelUploaded, setIsExcelUploaded] = useState(false);
  const [columnMappings, setColumnMappings] = useState({});
  const [coachID, setCoachID] = useState("ASSfdohNVUcC");

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        Papa.parse(e.target.result, {
          header: true,
          complete: (results) => {
            setExcelData(results.data);
            setIsExcelUploaded(true);

            // Initialize column mappings based on header names
            const initialMappings = {};
            results.meta.fields.forEach((field) => {
              initialMappings[field] = field;
            });
            setColumnMappings(initialMappings);
          },
        });
      };
      fileReader.readAsText(acceptedFiles[0]);
    },
    accept: ".csv",
  });

  useEffect(() => {
    if (excelData) {
      setExercises(excelData);
    }
  }, [excelData]);

  const onExerciseUpdate = () => {
    exercises.map((exercise, index) => {
      updateSingleExercise(exercise);
    });
  };

  const updateSingleExercise = async (exercise) => {
    let videoURL = exercise[columnMappings["Video Link"]];
    let videoID;
    if (typeof videoURL === "string") {
      videoURL = videoURL.trim();
      videoID = getVideoId(videoURL);
    }

    let bodyPart = {
      1: exercise[columnMappings["Body part "]],
    };
    let subBodyPart = exercise?.[columnMappings["sub part body "]] ?? {};
    let equipmentList = exercise[columnMappings["equipment "]] ?? {};

    let modality = exercise?.[columnMappings["modality "]] ?? {};

    let subModality = exercise?.[columnMappings["sub modality "]] ?? {};

    let data = {
      coachID: coachID,
      nameID:
        exercise[columnMappings["Category"]].trim() +
        " - " +
        exercise?.[columnMappings["Exercise Name"]],
      name:
        exercise[columnMappings["Category"]].trim() +
        " - " +
        exercise?.[columnMappings["Exercise Name"]],
      videoURL: exercise[columnMappings["Video Link"]],
      tags: `${exercise[columnMappings["Category"]].trim()}`,
      imageLink: exercise[columnMappings["Thumbnail Image"]],
      equipmentList,
      bodyPart,
      subBodyPart,
      movementFunction: exercise.movementFunction ?? {},
      modality,
      subModality,
      categoryKind:
        exercise?.[columnMappings["skill level (basic, complex, advanced) "]] ??
        "",
    };

    let updatedExercise = {
      ...exercise,
      nameID: exercise.name,
      videoID,
      videoURL,
    };
    // remove all null fields in exercise
    Object.keys(updatedExercise).forEach(
      (key) => updatedExercise[key] == null && delete updatedExercise[key]
    );
    console.log({ data });

    let res = await axios({
      method: "post",
      url: `${APIURL}CF/api/CoachWeb/Create/addNewMovement`,
      data: data,
    });
  };

  const handleColumnMappingChange = (event) => {
    const { name, value } = event.target;
    setColumnMappings({ ...columnMappings, [name]: value });
  };

  const handleCoachIDChange = (event) => {
    setCoachID(event.target.value);
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md max-w-screen-md w-full">
        <h1 className="text-2xl font-bold mb-4 text-center">
          Add Trainer Exercises
        </h1>

        <div className="mb-4">
          <label
            htmlFor="coachID"
            className="block text-gray-700 font-bold mb-2"
          >
            Coach ID:
          </label>
          <input
            type="text"
            id="coachID"
            value={coachID}
            onChange={handleCoachIDChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>

        <div className="flex justify-center mb-4">
          <button
            onClick={onExerciseUpdate}
            className="px-4 py-2 bg-blue-500 text-white rounded-md w-full max-w-xs"
          >
            Update Exercises
          </button>
        </div>

        {!isExcelUploaded ? (
          <div
            {...getRootProps()}
            className="border-2 border-dashed border-gray-400 rounded-lg p-10 text-center cursor-pointer mb-4"
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the CSV file here...</p>
            ) : (
              <p>Drag & Drop your CSV file here, or click to select file</p>
            )}
          </div>
        ) : (
          <>
            <div className="mb-4">
              <h2>Column Mappings:</h2>
              <div className="grid grid-cols-2 gap-4">
                {Object.entries(columnMappings).map(([key, value]) => (
                  <div key={key}>
                    <label
                      htmlFor={key}
                      className="block text-gray-700 font-bold mb-2"
                    >
                      {key}:
                    </label>
                    <select
                      name={key}
                      value={value}
                      onChange={handleColumnMappingChange}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    >
                      {excelData[0] &&
                        Object.keys(excelData[0]).map((header) => (
                          <option key={header} value={header}>
                            {header}
                          </option>
                        ))}
                    </select>
                  </div>
                ))}
              </div>
            </div>

            <div className="overflow-x-auto">
              <table className="table-auto w-full text-left">
                <thead>
                  <tr>
                    {excelData[0] &&
                      Object.keys(excelData[0]).map((key) => (
                        <th
                          key={key}
                          className="px-4 py-2 font-medium text-gray-700"
                        >
                          {key}
                        </th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {exercises.map((exercise, index) => (
                    <tr key={index}>
                      {Object.keys(exercise).map((key) => (
                        <td key={key} className="px-4 py-2">
                          {exercise[key]}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AddTrainerExercises;
