import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import React, { useRef, useState } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

export default function ImageUploadWithCrop({
  open,
  setOpen,
  uploadProfilePic,
  title,
}) {
  const [imgSrc, setImgSrc] = useState("");
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(16 / 16);

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  const clearState = () => {
    setImgSrc("");
    setCrop(null);
  };

  function onSave() {
    if (
      completedCrop &&
      imgRef.current &&
      completedCrop.width &&
      completedCrop.height
    ) {
      const canvas = document.createElement("canvas");
      let scaleX = imgRef.current.naturalWidth / imgRef.current.width;
      let scaleY = imgRef.current.naturalHeight / imgRef.current.height;
      if (scaleX != scaleY) {
        scaleX = scaleY;
      }
      canvas.width = completedCrop.width * scaleX;
      canvas.height = completedCrop.height * scaleY;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(
        imgRef.current,
        completedCrop.x * scaleX,
        completedCrop.y * scaleY,
        completedCrop.width * scaleX,
        completedCrop.height * scaleY,
        0,
        0,
        canvas.width,
        canvas.height
      );
      canvas.toBlob((blob) => uploadProfilePic(blob));
    }
  }
  return (
    <Dialog
      PaperProps={{
        sx: {
          borderRadius: "20px",
        },
      }}
      open={open}
      onClose={() => {
        clearState();
        setOpen(false);
      }}
    >
      <div className="add-program-modal-head">
        <p className="add-program-modal-head-title">
          {title || "Select Profile Pic"}
        </p>
        <CloseIcon
          sx={{
            cursor: "pointer",
          }}
          onClick={() => {
            clearState();
            setOpen(false);
          }}
        />
      </div>

      <DialogContent>
        <div style={{ marginBottom: "24px" }}>
          <input type="file" accept="image/*" onChange={onSelectFile} />
        </div>
        {!!imgSrc && (
          <ReactCrop
            style={{ height: "350px", objectFit: "contain" }}
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            aspect={aspect}
          >
            <img
              ref={imgRef}
              alt="Crop me"
              src={imgSrc}
              style={{
                transform: `scale(${scale}) rotate(${rotate}deg)`,
                height: "350px",
                objectFit: "contain",
              }}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <button className="button-new" onClick={() => onSave()}>
          Save
        </button>
      </DialogActions>
    </Dialog>
  );
}
