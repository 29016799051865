import moment from "moment";
import React from "react";
import SummarSection2 from "./SummarSection2";
import Notes from "../Notes/Notes";

const ClientProfileSummary = ({ data, userImage }) => {
  console.log("Data from client summary: ", data);

  const bodyStatsData = data?.bodyStatsData;

  const totalWorkouts = data?.globalStats?.daysWorkedout;
  const currentStreak = data?.globalStats?.currentStreak;
  const adherence = data?.globalStats?.globalAdherence
    ? `${data?.globalStats?.globalAdherence}%`
    : null;

  const latestWeight = getLatestWeightFromBodyStats(bodyStatsData);

  let todaysActivityData = null;
  if (data?.activityData?.[0]?.dateAssigned) {
    todaysActivityData = data?.activityData?.filter(
      (item) =>
        moment(item?.dateAssigned).format("DD-MM-YYYY") ===
        moment().format("DD-MM-YYYY")
    );
  }

  const steps = getStepsFromWearableData(todaysActivityData);
  const sleep = getSleepFromWearableData(todaysActivityData);

  const programName = data?.programData?.[0]?.programAssigned?.name;
  const userName = data?.userInfo?.userName;
  const email = data?.userInfo?.email;
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1.8fr 1fr",
        alignItems: "flex-start",
        backgroundColor: "#f8f8f8",
        borderRadius: "10px",
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#FFFFFF",
          borderRadius: "10px",
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.03)",
        }}
      >
        <div
          style={{
            padding: "12px 24px",
            borderBottom: "1px solid #EBEBEB",
          }}
        >
          <p
            style={{
              fontFamily: "DM Sans",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "29px",
            }}
          >
            Statistics
          </p>
        </div>
        <StatsCard
          title={"Total Workouts"}
          value={totalWorkouts}
          icon={
            <img
              width="20px"
              height={"20px"}
              src="https://img.icons8.com/ios/50/deadlift--v1.png"
              alt="deadlift--v1"
            />
          }
        />

        <StatsCard
          title={"Current Streak"}
          value={currentStreak}
          icon={
            <img
              width="20px"
              height={"20px"}
              src="https://img.icons8.com/ios/50/fire-element--v1.png"
              alt="fire-element--v1"
            />
          }
        />

        <StatsCard
          title={"Adherence"}
          value={adherence}
          icon={
            <img
              width="20px"
              height={"20px"}
              src="https://img.icons8.com/ios/50/checked--v1.png"
              alt="checked--v1"
            />
          }
        />

        <StatsCard
          title={"Body Weight"}
          value={latestWeight}
          icon={
            <img
              width="20px"
              height={"20px"}
              src="https://img.icons8.com/ios/50/weight.png"
              alt="weight"
            />
          }
        />

        <StatsCard
          title={"Steps"}
          value={Math.round(steps ?? 0)}
          icon={
            <img
              width="20px"
              height={"20px"}
              src="https://img.icons8.com/pastel-glyph/64/boot-print--v1.png"
              alt="boot-print--v1"
            />
          }
        />

        <StatsCard
          title={"Sleep"}
          value={sleep > 0 ? `${sleep} hours` : sleep}
          icon={
            <img
              width="20px"
              height={"20px"}
              src="https://img.icons8.com/ios/50/sleeping-in-bed--v1.png"
              alt="sleeping-in-bed--v1"
            />
          }
        />
      </div>

      <SummarSection2 data={data} />
      <div
        style={{
          width: "100%",
          backgroundColor: "#FFFFFF",
          borderRadius: "10px",
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.03)",
          marginRight: "12px",
        }}
      >
        <Notes
          onClose={() => {
            console.log("Close");
          }}
          userUUID={data?.userInfo?.id}
          trainerUUID={data?.userInfo?.coachID}
        />
      </div>
    </div>
  );
};

export default ClientProfileSummary;
export const getLatestWeightFromBodyStats = (bodyStatsData, addDate) => {
  console.log("You got some response",bodyStatsData);
  bodyStatsData?.sort(
    (a, b) => new Date(b.dateRecorded) - new Date(a.dateRecorded)
  );
  let latestWeight = null;
  bodyStatsData?.forEach((item) => {
    if (
      !latestWeight &&
      item?.weight?.value !== undefined &&
      item?.weight?.value !== null &&
      item?.weight?.value !== ""
    ) {
      let date = moment(item?.dateRecorded).format("Do MMM YYYY");
      const weightValue = parseFloat(item?.weight?.value); // Convert to number
      latestWeight = weightValue.toFixed(0); // Restrict to one decimal place
      if (item?.weight?.unit) {
        latestWeight += ` ${item?.weight?.unit}`;
      } else {
        latestWeight += ` kg`;
      }
      if (addDate) {
        let daysAgo = moment().diff(moment(item?.dateRecorded), "days");
        if (daysAgo === 0) {
          date = "Today";
        } else if (daysAgo === 1) {
          date = "Yesterday";
        } else {
          date = `${daysAgo} days ago`;
        }
        latestWeight += ` (${date})`;
      }
      return;
    }
  });
  console.log("latestWeight", latestWeight);
  return latestWeight;
};
export const getStepsFromWearableData = (todaysActivityData) => {
  let steps = 0;
  if (todaysActivityData?.[0]) {
    steps = todaysActivityData?.[0]?.activitiesFinished?.stepCount;
  }
  return steps;
};

export const getSleepFromWearableData = (todaysActivityData) => {
  let sleep = 0;
  if (todaysActivityData?.[0]) {
    sleep = todaysActivityData?.[0]?.activitiesFinished?.sleep;
  }
  return sleep;
};

export const StatsCard = ({ title, value, icon }) => {
  if (value === null || value === undefined) {
    return null;
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
        padding: "12px 24px",
        borderBottom: "1px solid #EBEBEB",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: 8,
        }}
      >
        {icon}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              fontFamily: "DM Sans",
              fontWeight: 400,
              fontSize: "16px",
              color: "#424242",
              lineHeight: "21px",
              alignItems: "center",
            }}
          >
            {title} &nbsp;
          </div>
          <div
            style={{
              fontFamily: "DM Sans",
              fontWeight: 700,
              fontSize: "16px",
              color: "black",
              alignItems: "center",
              lineHeight: "29px",
            }}
          >
            {value}
          </div>
        </div>
      </div>
    </div>
  );
};
