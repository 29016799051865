import { DialogHeader } from "@/components/ui/dialog";
import { ProgramsContext } from "./ProgramsContext";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { FolderSearch } from "lucide-react";
import React, { useContext, useEffect, useState } from "react";
import { CloseRounded, RoundedCorner } from "@mui/icons-material";
import { Folder } from "lucide-react";
import CustomSelect from "@/components/WorkoutComponent/Endurance/util/CustomSelect";

function FolderToSelectDialog({ open, setOpen, selectedProgram, folders }) {
  const {
    selectedTab,
    setTemplateFolders,
    setFolders,
    moveItemToFolder,
    setFolderSelected,
    setOpenFolderId,
    setSelectedProgram,
    setSelectedProgramData,
    setSelectedProgramWithPhases,
    setTemplates,
    setPrograms,
    setOriginalState,
    originalState,
    setOpenPhaseId,
  } = useContext(ProgramsContext);
  const [selected, setSelected] = useState("");

  const handleAddingToFolder = async (Id) => {
    let data = {
      folderID: Id,
    };
    if (selectedProgram?.programs) {
      data["containerIDs"] = [selectedProgram?.id];
    } else {
      data["programIDs"] = [selectedProgram?.id];
    }
    let res = await moveItemToFolder(data);
    if (res) {
      if (selectedTab === "templates") {
        let updatedDataForTemplateFolders;
        let updatedDataForTemplate;
        setTemplateFolders((prev) => {
          const data = [...originalState?.templateFolders];
          if (data?.length > 0) {
            const newData = data.map((folder) => {
              if (folder?.id === Id) {
                if (selectedProgram?.programs) {
                  if (folder?.containers) {
                    const program = { ...selectedProgram };
                    if (program?.programFolderId) {
                      program.programFolderId = Id;
                    } else {
                      program["programFolderId"] = Id;
                    }
                    folder.containers?.push(program);
                  } else {
                    let containersArr = [];
                    const program = { ...selectedProgram };
                    if (program?.programFolderId) {
                      program.programFolderId = Id;
                    } else {
                      program["programFolderId"] = Id;
                    }
                    containersArr.push(program);
                    folder["containers"] = containersArr;
                  }
                } else {
                  if (folder?.programs) {
                    const program = { ...selectedProgram };
                    if (program?.programFolderId) {
                      program.programFolderId = Id;
                    } else {
                      program["programFolderId"] = Id;
                    }

                    folder.programs?.push(program);
                  } else {
                    let programArr = [];
                    const program = { ...selectedProgram };
                    if (program?.programFolderId) {
                      program.programFolderId = Id;
                    } else {
                      program["programFolderId"] = Id;
                    }
                    programArr.push(program);
                    folder["programs"] = programArr;
                  }
                }
                setFolderSelected(folder);
                return folder;
              }
              return folder;
            });
            console.log("this is for the folders", newData);
            updatedDataForTemplateFolders = newData;
            return newData;
          }
        });
        setTemplates((prev) => {
          const tempdata = [...originalState.templates];

          let newdata = tempdata?.filter(
            (program) => program?.id !== selectedProgram?.id
          );
          console.log("this is for newdata", newdata);
          updatedDataForTemplate = newdata;
          return newdata;
        });

        setOriginalState((prev) => {
          return {
            ...prev,
            templates: [...updatedDataForTemplate],
            templateFolders: [...updatedDataForTemplateFolders],
          };
        });
      } else {
        let updatedDataForFolders;
        let updatedDataForPrograms;
        setFolders((prev) => {
          const data = [...originalState?.folders];
          if (data?.length > 0) {
            const newData = data.map((folder) => {
              if (folder?.id === Id) {
                if (selectedProgram?.programs) {
                  if (folder?.containers) {
                    const program = { ...selectedProgram };
                    if (program?.programFolderId) {
                      program.programFolderId = Id;
                    } else {
                      program["programFolderId"] = Id;
                    }
                    folder.containers?.push(program);
                  } else {
                    let containersArr = [];
                    const program = { ...selectedProgram };
                    if (program?.programFolderId) {
                      program.programFolderId = Id;
                    } else {
                      program["programFolderId"] = Id;
                    }
                    containersArr.push(program);
                    folder["containers"] = containersArr;
                  }
                } else {
                  if (folder?.programs) {
                    const program = { ...selectedProgram };
                    if (program?.programFolderId) {
                      program.programFolderId = Id;
                    } else {
                      program["programFolderId"] = Id;
                    }
                    folder.programs?.push(program);
                  } else {
                    let programArr = [];
                    const program = { ...selectedProgram };
                    if (program?.programFolderId) {
                      program.programFolderId = Id;
                    } else {
                      program["programFolderId"] = Id;
                    }
                    programArr.push(program);
                    folder["programs"] = programArr;
                  }
                }
                setFolderSelected(folder);
                return folder;
              }
              return folder;
            });
            console.log("this is for the folders", newData);
            updatedDataForFolders = newData;
            return newData;
          }
        });
        setPrograms((prev) => {
          const tempdata = [...originalState?.programs];

          let newdata = tempdata?.filter(
            (program) => program?.id !== selectedProgram?.id
          );
          updatedDataForPrograms = newdata;
          return newdata;
        });
        setOriginalState((prev) => {
          return {
            ...prev,
            programs: [...updatedDataForPrograms],
            folders: [...updatedDataForFolders],
          };
        });
      }
      setOpenFolderId(Id);
      setSelectedProgram({});
      setOpenPhaseId(null);
      setSelectedProgramData({});
      setSelectedProgramWithPhases(null);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "24px",
        },
      }}
    >
      <DialogHeader className="border-b-2 border-gray-200 flex flex-row justify-between p-1 items-center">
        <DialogTitle className="text-font18">Select A Folder</DialogTitle>
        <CloseRounded className="mr-2" onClick={() => setOpen(false)} />
      </DialogHeader>
      <DialogContent className=" w-[100%] font-DMSans h-[400px] overflow-y-scroll">
        <div className="flex flex-col gap-1 items-center justify-center">
          <label className="text-[14px] font-[500] self-start  pl-[12px] text-[#424242]">
            Select Any Folder
          </label>
          <CustomSelect
            isFolder={true}
            triggerClassName={
              "w-full text-font14 focus:ring-transparent focus:border-indigo-500 border-[1.5px]  "
            }
            onValueChange={(folder) => {
              setSelected(folder?.id);
              handleAddingToFolder(folder?.id);
            }}
            options={folders}
            placeholder={"----"}
          />
        </div>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
}

export default FolderToSelectDialog;
