import { Box, Typography } from "@mui/material";
import React from "react";

export default function CustomInputField({
  label,
  value,
  onChange,
  style,
  color,
  fontWeight,
  placeholder,
}) {
  return (
    <Box
      style={style}
      sx={{
        border: "1px solid #A7AAAE",
        borderRadius: "7px",
        padding: "10px 16px 8px 16px",
        "&:focus-within": {
          borderColor: "#1468E7",
          "& > :first-child": {
            color: "#1468E7",
          },
        },
      }}
    >
      <Typography
        sx={{
          fontFamily: "DM Sans",
          color: "#8E99A1",
          fontSize: "16px",
          fontWeight: 400,
        }}
      >
        {label}
      </Typography>
      {label !== "About" ? (
        <input
          style={{
            width: "100%",
            height: "23px",
            fontFamily: "DM Sans",
            fontSize: "18px",
            fontWeight: fontWeight,
            color: color,
            outline: "none",
            marginTop: "10px",
          }}
          autoComplete="off"
          onChange={onChange}
          value={value}
          type="text"
        />
      ) : (
        <textarea
          placeholder={"Write something intersting about yourself"}
          style={{
            width: "100%",
            height: "auto",
            minHeight: "200px",
            fontFamily: "DM Sans",
            fontSize: "18px",
            fontWeight: fontWeight,
            color: color,
            outline: "none",
            boxSizing: "border-box",
          }}
          onChange={onChange}
          value={value}
          type="text"
        />
      )}
    </Box>
  );
}
