import { CustomAlertContext } from "@/App";
import { LinearProgress } from "@mui/material";
import axios from "axios";
import moment from "moment";
import React, { forwardRef, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import styles from "./calendar.module.css";
import { AssignMeal } from "./functions";
import ShowAssignedPlans from "./ShowAssignedPlans";

const CalendarV2 = forwardRef(
  (
    {
      profileData,
      startDate,
      endDate,
      userUUID,
      setSelectedDate,
      setShowWorkOutBuilder,
      setSelectedActivity,
      currentWeekOfMonth,
      isHighlighted,
      garminActivityData,
      selectedCalendarType,
      setProfileData,
      loadingCalendar,
      userInfo,
      setProfileDataFromClientsView,
      profileDataFromClientsView,
      reloadProfile,
      isExpanded,
    },
    ref
  ) => {
    const APIURL = useSelector((state) => state.auth.apiURL);
    const [currentWeek, setCurrentWeek] = useState([]);
    const [isDragging, setIsDragging] = useState(false);
    const [workoutData, setWorkoutData] = useState(
      profileData?.currentMonthmodules || {}
    );
    const [mealData, setMealData] = useState(profileData?.mealData || {});
    const [activityData, setActivityData] = useState(
      profileData?.activityData || {}
    );
    const [eventData, setEventData] = useState(profileData?.eventData || {});
    const [enduranceData, setEnduranceData] = useState([]);

    const [loadingDays, setLoadingDays] = useState({});
    const { displayAlert } = useContext(CustomAlertContext);

    // below function prepares the current week
    const prepareCurrentWeek = () => {
      let start = moment(startDate);
      let currWeek = [];
      while (start.isBefore(endDate)) {
        currWeek.push(start.format("YYYY-MM-DD"));
        start.add(1, "day");
      }
      console.log("current week data:-", currWeek);
      setCurrentWeek(currWeek);
    };

    // this will handle dragStart
    function handleDragStart() {
      setIsDragging(true);
    }

    // this is handling the dragEnd
    async function handleDragEnd(result) {
      setIsDragging(false);
      let draggableId = result?.draggableId;
      let droppableId = result?.destination?.droppableId;
      console.log("result from draggable", result);

      if (droppableId && droppableId !== "droppable-1") {
        if (draggableId?.includes("workoutLib@")) {
          console.log("this is coming from is workoutlib");
          let parts = draggableId.split("@");
          let result = parts.slice(1).join("@");
          const workout = JSON.parse(result);
          let newWorkoutId = uuidv4();
          let updatedWorkoutData = { ...workoutData };
          let destinationModuleDate = droppableId.split("_")[1];

          let updatedDestinationWorkout = [
            ...(workoutData?.[destinationModuleDate] ?? []),
          ];

          let isEndurance =
            workout?.workoutKind === "run" ||
            workout?.workoutKind === "bike" ||
            workout?.workoutKind === "swim";

          setLoadingDays({ ...loadingDays, [destinationModuleDate]: true });

          let assignedWorkout = await assignWorkoutForWorkoutLibrary(
            workout.id,
            destinationModuleDate,
            newWorkoutId,
            isEndurance
          );
          if (assignedWorkout === "P2002") {
            displayAlert({
              message: "You have already assigned this workout to this date",
              type: "error",
            });
          } else if (!assignedWorkout) {
            displayAlert({
              message: "Something went wrong, please try again",
              type: "error",
            });
          } else {
            let newWorkout = {
              ...assignedWorkout,
              workoutAssigned: { ...workout },
            };
            updatedDestinationWorkout.push({
              ...newWorkout,
              id: newWorkoutId,
            });
            updatedWorkoutData[destinationModuleDate] =
              updatedDestinationWorkout;
            setWorkoutData(updatedWorkoutData);
            const newState = { ...profileData };
            console.log("this is from the calender2", newState);
            newState.currentMonthmodules = updatedWorkoutData;
            setProfileData(newState, true);
          }
          setLoadingDays({ ...loadingDays, [destinationModuleDate]: false });
        } else if (draggableId?.includes("meal@")) {
          let mealCopied = JSON.parse(draggableId.split("@")[1]);
          let sourceModuleDate = draggableId.split("@")[2].split("_")[1];
          let destinationModuleDate = droppableId.split("_")[1];

          if (sourceModuleDate === destinationModuleDate) {
            return;
          } else {
            let updatedMealData = { ...mealData };
            let updatedSourceMeals = [...(mealData?.[sourceModuleDate] ?? [])];
            let updatedDestinationMeals = [
              ...(mealData?.[destinationModuleDate] ?? []),
            ];
            let newAssignedId = uuidv4();
            let indexToRemove = updatedSourceMeals.findIndex(
              (item) => item.id === mealCopied.assignedId
            );
            let newCopiedMeal = {
              ...(updatedSourceMeals[indexToRemove] ?? {}),
            };
            updatedSourceMeals.splice(indexToRemove, 1);
            updatedMealData[sourceModuleDate] = updatedSourceMeals;
            updatedDestinationMeals.push({
              ...newCopiedMeal,
              id: newAssignedId,
            });
            updatedMealData[destinationModuleDate] = updatedDestinationMeals;
            setMealData(updatedMealData);

            AssignMeal(
              newAssignedId,
              userUUID,
              mealCopied,
              destinationModuleDate
            );
          }
        } else {
          // const name = JSON.parse(draggableId.split("_")[0].split("-")[1]).Name;
          const sourceModuleDate = draggableId.split("_")[2].split("@")[1];
          let PrimaryKey = draggableId.split("_")[3].split("@")[1];
          const workoutId = draggableId.split("_")[4].split("@")[1];
          const destinationModuleDate = droppableId.split("_")[1];
          PrimaryKey = JSON.parse(PrimaryKey);
          //  console.log("jatin bisht",sourceModuleDate,PrimaryKey,workoutId,destinationModuleDate);

          if (sourceModuleDate === destinationModuleDate) {
            return;
          } else {
            let newWorkoutId = uuidv4();
            let updatedWorkoutData = { ...workoutData };

            let updatedSourceWorkout = [
              ...(workoutData?.[sourceModuleDate] ?? []),
            ];
            let updatedDestinationWorkout = [
              ...(workoutData?.[destinationModuleDate] ?? []),
            ];
            let indexToRemove = updatedSourceWorkout.findIndex(
              (item) => item.id === workoutId
            );

            let workoutKind =
              workoutData?.[sourceModuleDate]?.[indexToRemove]?.workoutAssigned
                ?.workoutKind;
            let isEndurance =
              workoutKind === "run" ||
              workoutKind === "bike" ||
              workoutKind === "swim";

            let workoutAssignedId =
              updatedSourceWorkout?.[indexToRemove]?.workoutAssignedID;

            let newCopiedWorkout = {
              ...(updatedSourceWorkout[indexToRemove] ?? {}),
            };
            updatedSourceWorkout.splice(indexToRemove, 1);
            updatedWorkoutData[sourceModuleDate] =
              updatedSourceWorkout.length === 0
                ? undefined
                : updatedSourceWorkout;
            updatedDestinationWorkout.push({
              ...newCopiedWorkout,
              id: newWorkoutId,
            });
            console.log("this is new workout", newCopiedWorkout);
            updatedWorkoutData[destinationModuleDate] =
              updatedDestinationWorkout;
            setWorkoutData(updatedWorkoutData);

            assignWorkout(
              workoutAssignedId,
              destinationModuleDate,
              newWorkoutId,
              isEndurance
            );
            deleteIndividualWorkout(workoutId, PrimaryKey, isEndurance);
          }
        }
      } else {
        return;
      }
    }

    React.useImperativeHandle(ref, () => ({
      handleDragEnd,
      handleDragStart,
    }));
    // this function deletes the individual workout

    const deleteIndividualWorkout = async (
      assignedWorkoutID,
      primaryKeyArray,
      isEndurance
    ) => {
      await axios({
        method: "post",
        url: `${APIURL}CF/api/deleteAssignedWorkoutAthelete`,
        data: {
          assignedWorkoutID: assignedWorkoutID,
          newOrderWorkoutIDs: primaryKeyArray,
          isEnduranceWorkout: isEndurance,
        },
      })
        .then((res) => {
          console.log("deleteAssignedWorkoutAthelete: ", res);
        })
        .catch((err) => {
          console.log("Network error, please try again", err);
        });
    };
    const assignWorkout = async (workoutId, date, primaryKey, isEndurance) => {
      await axios({
        method: "post",
        url: `${APIURL}CF/api/modules`,
        data: {
          userUUID: userUUID,
          id: primaryKey,
          modulesUUID: workoutId,
          moduleDate: `${moment(date, "YYYY-MM-DD")
            .startOf("day")
            .format("YYYY-MM-DDTHH:mm:ss")}Z`,
          isEnduranceWorkout: isEndurance,
        },
      })
        .then((res) => {
          console.log("assignWorkout success ");
        })
        .catch((err) => {
          console.log("assignWorkout error ", err);
        });
    };

    const assignWorkoutForWorkoutLibrary = async (
      workoutId,
      date,
      primaryKey,
      isEndurance
    ) => {
      try {
        let userID = userUUID ?? userInfo?.id;
        let res = await axios({
          method: "post",
          url: `${APIURL}CF/api/assignWorkoutOnDrop`,
          data: {
            userUUID: userID,
            id: primaryKey,
            modulesUUID: workoutId,
            moduleDate: `${moment(date, "YYYY-MM-DD")
              .startOf("day")
              .format("YYYY-MM-DDTHH:mm:ss")}Z`,
            isEnduranceWorkout: isEndurance,
          },
        });

        return res?.data?.result;
      } catch (error) {
        console.log("error in assignWorkoutForWorkoutLibrary", error);
        return error?.response?.data?.code === "P2002" ? "P2002" : null;
      }
    };
    useEffect(() => {
      console.log("profile data:-", profileData);
      prepareCurrentWeek();
    }, [startDate, endDate]);
    useEffect(() => {
      setWorkoutData(profileData?.currentMonthmodules);
      setMealData(profileData?.mealData);
      setEventData(profileData?.eventData);
      setActivityData(profileData?.activityData);
      setEnduranceData(garminActivityData);
    }, [profileData]);
    console.log(
      "CalendarOneWeek.jsx workout data",
      profileData?.currentMonthmodules
    );
    return (
      <>
        {loadingCalendar && (
          <LinearProgress style={{ marginTop: 10 }} sx={{ color: "#6242EA" }} />
        )}

        <div
          className={styles.CalendarV2}
          style={{
            height: isExpanded && "calc(100vh - 200px)",
          }}
        >
          {currentWeek.map((currentDate, index) => {
            let workouts = workoutData?.[currentDate];

            // let statusColor = getCalendarImg(status)?.backgroundColor;
            let m = mealData?.[currentDate];
            let meals = [];
            if (m) {
              m?.forEach((item) => {
                let calories =
                  item?.unsavedMeals?.calories ||
                  item?.mealPlans?.plandetails?.calories ||
                  0;
                let Name =
                  item?.mealPlans?.reference_name ||
                  item?.unsavedMeals?.day ||
                  "";
                let assignedId = item?.id;
                let mealId = item?.mealId;
                meals.push({
                  Name,
                  calories,
                  assignedId,
                  mealId,
                  dateAssigned: item?.dateAssigned,
                  item,
                });
              });
            }
            let activities = activityData?.[currentDate];
            let events = eventData?.[currentDate];
            let currentDateMoment = moment(currentDate);
            let currentDay = currentDateMoment.format("dddd");
            let currentDD = currentDateMoment.format("DD");
            let todayDate = moment().format("YYYY-MM-DD");
            let shouldHighlight = false;
            if (isHighlighted) {
              if (todayDate === currentDate) {
                shouldHighlight = true;
              } else {
                shouldHighlight = false;
              }
            }
            let tempPrimaryKey = [];
            workouts?.forEach((workout) => {
              tempPrimaryKey.push(workout.id);
            });
            console.log("PRIMARY KEY TEMP", tempPrimaryKey);
            let primaryKey = JSON.stringify(tempPrimaryKey);
            console.log("PRIMARY KEY", primaryKey);
            let status = [];
            workouts?.forEach((workout) => {
              if (currentDate <= todayDate && workout?.finished) {
                status.push("done");
              } else if (currentDate <= todayDate && !workout?.finished) {
                status.push("not done");
              }
            });
            let todaysFormattedDate = moment(currentDate).format("YYYY-MM-DD");
            let currentEnduranceData =
              garminActivityData?.[todaysFormattedDate];
            console.log("Current Data:", currentEnduranceData);
            console.log({ workouts, meals, activities, events });
            return (
              <div className={styles.calendarV2row}>
                <div
                  className={`${
                    shouldHighlight ? styles.dayDateHighlighted : styles.dayDate
                  }`}
                >
                  {shouldHighlight ? (
                    <div>
                      <p className={`gradient-text`}>TODAY</p>
                      <div
                        className={`${
                          shouldHighlight
                            ? styles.currentDateHighlighted
                            : styles.currentDateNormal
                        }`}
                      >
                        <p className={styles.currentDD}>{currentDD}</p>
                      </div>
                    </div>
                  ) : (
                    <>
                      <p className={styles.currentDay}>{currentDay}</p>
                      <div
                        className={`${
                          shouldHighlight
                            ? styles.currentDateHighlighted
                            : styles.currentDateNormal
                        }`}
                      >
                        <p className={styles.currentDD}>{currentDD}</p>
                      </div>
                    </>
                  )}
                </div>
                <div className={styles.seperationLine}></div>
                <div
                  className={`w-full ${
                    shouldHighlight && styles.dayHighlightedBorder
                  } h-[70px]`}
                >
                  <ShowAssignedPlans
                    workouts={workouts}
                    setWorkouts={(data) => {
                      // currentmonthmodules will be workouts array for the current date
                      let updatedWorkoutData = { ...workoutData };
                      updatedWorkoutData[currentDate] = data;
                      setWorkoutData(updatedWorkoutData);
                    }}
                    meals={meals}
                    events={events}
                    activities={activities}
                    status={status}
                    primaryKey={primaryKey}
                    moduleDate={currentDate}
                    userUUID={userUUID}
                    userInfo={userInfo}
                    isThresholdAvailable={
                      userInfo?.thresholdValues ? true : false
                    }
                    setSelectedDate={setSelectedDate}
                    setShowWorkOutBuilder={setShowWorkOutBuilder}
                    setSelectedActivity={setSelectedActivity}
                    currentDate={currentDate}
                    currentEnduranceData={currentEnduranceData}
                    selectedCalendarType={selectedCalendarType}
                    setProfileData={setProfileData}
                    index={index}
                    currentMonthEnduranceData={enduranceData}
                    isDragging={isDragging}
                    loadingDay={loadingDays[currentDate]}
                    setProfileDataFromClientsView={setProfileData}
                    profileDataFromClientsView={profileData}
                    reloadProfile={reloadProfile}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  }
);

export default CalendarV2;
