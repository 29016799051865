// export let movementData=[
//   {
//     "id": "1ba9ac53-88f4-44c3-8c3d-9f8f5b2c4ac6",
//     "workoutPerformedID": null,
//     "dateFinished": "2024-01-01T00:00:00.000Z",
//     "trackedMovementID": "HBYbyRRGCnde",
//     "atheleteID": "105067632829997666848",
//     "measurement": {
//       "movementName": "3 Way Raise (13)",
//       "reps": {
//         "1": 5,
//         "2": 5
//       },
//       "weight": {
//         "1": 12,
//         "2": 12
//       },
//       "weightUnits": "Pounds",
//       "movementID": "umtGtDIrNkBW",
//       "recordFromUser": {
//         "reps": true,
//         "weight": true,
//         "height": false,
//         "distance": false,
//         "calorie": false,
//         "duration": false,
//         "heartRate": false
//       },
//       "imageLink": "https://spurfitpublicimages.s3.us-east-2.amazonaws.com/AppVideosLatest/Images/4KmY44Xsg2w.jpg",
//       "videoLink": "https://www.youtube.com/watch?v=4KmY44Xsg2w&pp=ygUUc3F1YXRzIGZvciBiZWdpbm5lcnM%3D",
//       "movementInstr": "",
//       "paceUnit": "min/mi",
//       "sideInfo": "",
//       "heartRateType": "above",
//       "durationMax": {
//         "1": "",
//         "2": ""
//       },
//       "rounds": 2
//     }
//   },
//   {
//     "id": "2ba9ac53-88f4-44c3-8c3d-9f8f5b2c4ac6",
//     "workoutPerformedID": null,
//     "dateFinished": "2024-01-02T00:00:00.000Z",
//     "trackedMovementID": "HBYbyRRGCnde",
//     "atheleteID": "105067632829997666848",
//     "measurement": {
//       "movementName": "3 Way Raise (13)",
//       "reps": {
//         "1": 6,
//         "2": 6
//       },
//       "weight": {
//         "1": 13,
//         "2": 13
//       },
//       "weightUnits": "Pounds",
//       "movementID": "umtGtDIrNkBW",
//       "recordFromUser": {
//         "reps": true,
//         "weight": true,
//         "height": false,
//         "distance": false,
//         "calorie": false,
//         "duration": false,
//         "heartRate": false
//       },
//       "imageLink": "https://spurfitpublicimages.s3.us-east-2.amazonaws.com/AppVideosLatest/Images/4KmY44Xsg2w.jpg",
//       "videoLink": "https://www.youtube.com/watch?v=4KmY44Xsg2w&pp=ygUUc3F1YXRzIGZvciBiZWdpbm5lcnM%3D",
//       "movementInstr": "",
//       "paceUnit": "min/mi",
//       "sideInfo": "",
//       "heartRateType": "above",
//       "durationMax": {
//         "1": "",
//         "2": ""
//       },
//       "rounds": 2
//     }
//   },
//   {
//     "id": "3ba9ac53-88f4-44c3-8c3d-9f8f5b2c4ac6",
//     "workoutPerformedID": null,
//     "dateFinished": "2024-01-03T00:00:00.000Z",
//     "trackedMovementID": "HBYbyRRGCnde",
//     "atheleteID": "105067632829997666848",
//     "measurement": {
//       "movementName": "3 Way Raise (13)",
//       "reps": {
//         "1": 7,
//         "2": 7
//       },
//       "weight": {
//         "1": 14,
//         "2": 14
//       },
//       "weightUnits": "Pounds",
//       "movementID": "umtGtDIrNkBW",
//       "recordFromUser": {
//         "reps": true,
//         "weight": true,
//         "height": false,
//         "distance": false,
//         "calorie": false,
//         "duration": false,
//         "heartRate": false
//       },
//       "imageLink": "https://spurfitpublicimages.s3.us-east-2.amazonaws.com/AppVideosLatest/Images/4KmY44Xsg2w.jpg",
//       "videoLink": "https://www.youtube.com/watch?v=4KmY44Xsg2w&pp=ygUUc3F1YXRzIGZvciBiZWdpbm5lcnM%3D",
//       "movementInstr": "",
//       "paceUnit": "min/mi",
//       "sideInfo": "",
//       "heartRateType": "above",
//       "durationMax": {
//         "1": "",
//         "2": ""
//       },
//       "rounds": 2
//     }
//   },
//   {
//     "id": "4ba9ac53-88f4-44c3-8c3d-9f8f5b2c4ac6",
//     "workoutPerformedID": null,
//     "dateFinished": "2024-01-04T00:00:00.000Z",
//     "trackedMovementID": "HBYbyRRGCnde",
//     "atheleteID": "105067632829997666848",
//     "measurement": {
//       "movementName": "3 Way Raise (13)",
//       "reps": {
//         "1": 8,
//         "2": 8
//       },
//       "weight": {
//         "1": 15,
//         "2": 15
//       },
//       "weightUnits": "Pounds",
//       "movementID": "umtGtDIrNkBW",
//       "recordFromUser": {
//         "reps": true,
//         "weight": true,
//         "height": false,
//         "distance": false,
//         "calorie": false,
//         "duration": false,
//         "heartRate": false
//       },
//       "imageLink": "https://spurfitpublicimages.s3.us-east-2.amazonaws.com/AppVideosLatest/Images/4KmY44Xsg2w.jpg",
//       "videoLink": "https://www.youtube.com/watch?v=4KmY44Xsg2w&pp=ygUUc3F1YXRzIGZvciBiZWdpbm5lcnM%3D",
//       "movementInstr": "",
//       "paceUnit": "min/mi",
//       "sideInfo": "",
//       "heartRateType": "above",
//       "durationMax": {
//         "1": "",
//         "2": ""
//       },
//       "rounds": 2
//     }
//   },
//   {
//     "id": "5ba9ac53-88f4-44c3-8c3d-9f8f5b2c4ac6",
//     "workoutPerformedID": null,
//     "dateFinished": "2024-01-05T00:00:00.000Z",
//     "trackedMovementID": "HBYbyRRGCnde",
//     "atheleteID": "105067632829997666848",
//     "measurement": {
//       "movementName": "3 Way Raise (13)",
//       "reps": {
//         "1": 9,
//         "2": 9
//       },
//       "weight": {
//         "1": 16,
//         "2": 16
//       },
//       "weightUnits": "Pounds",
//       "movementID": "umtGtDIrNkBW",
//       "recordFromUser": {
//         "reps": true,
//         "weight": true,
//         "height": false,
//         "distance": false,
//         "calorie": false,
//         "duration": false,
//         "heartRate": false
//       },
//       "imageLink": "https://spurfitpublicimages.s3.us-east-2.amazonaws.com/AppVideosLatest/Images/4KmY44Xsg2w.jpg",
//       "videoLink": "https://www.youtube.com/watch?v=4KmY44Xsg2w&pp=ygUUc3F1YXRzIGZvciBiZWdpbm5lcnM%3D",
//       "movementInstr": "",
//       "paceUnit": "min/mi",
//       "sideInfo": "",
//       "heartRateType": "above",
//       "durationMax": {
//         "1": "",
//         "2": ""
//       },
//       "rounds": 2
//     }
//   },
//   {
//     "id": "6ba9ac53-88f4-44c3-8c3d-9f8f5b2c4ac6",
//     "workoutPerformedID": null,
//     "dateFinished": "2024-01-06T00:00:00.000Z",
//     "trackedMovementID": "HBYbyRRGCnde",
//     "atheleteID": "105067632829997666848",
//     "measurement": {
//       "movementName": "3 Way Raise (13)",
//       "reps": {
//         "1": 10,
//         "2": 10
//       },
//       "weight": {
//         "1": 17,
//         "2": 17
//       },
//       "weightUnits": "Pounds",
//       "movementID": "umtGtDIrNkBW",
//       "recordFromUser": {
//         "reps": true,
//         "weight": true,
//         "height": false,
//         "distance": false,
//         "calorie": false,
//         "duration": false,
//         "heartRate": false
//       },
//       "imageLink": "https://spurfitpublicimages.s3.us-east-2.amazonaws.com/AppVideosLatest/Images/4KmY44Xsg2w.jpg",
//       "videoLink": "https://www.youtube.com/watch?v=4KmY44Xsg2w&pp=ygUUc3F1YXRzIGZvciBiZWdpbm5lcnM%3D",
//       "movementInstr": "",
//       "paceUnit": "min/mi",
//       "sideInfo": "",
//       "heartRateType": "above",
//       "durationMax": {
//         "1": "",
//         "2": ""
//       },
//       "rounds": 2
//     }
//   },
//   {
//     "id": "7ba9ac53-88f4-44c3-8c3d-9f8f5b2c4ac6",
//     "workoutPerformedID": null,
//     "dateFinished": "2024-01-07T00:00:00.000Z",
//     "trackedMovementID": "HBYbyRRGCnde",
//     "atheleteID": "105067632829997666848",
//     "measurement": {
//       "movementName": "3 Way Raise (13)",
//       "reps": {
//         "1": 11,
//         "2": 11
//       },
//       "weight": {
//         "1": 18,
//         "2": 18
//       },
//       "weightUnits": "Pounds",
//       "movementID": "umtGtDIrNkBW",
//       "recordFromUser": {
//         "reps": true,
//         "weight": true,
//         "height": false,
//         "distance": false,
//         "calorie": false,
//         "duration": false,
//         "heartRate": false
//       },
//       "imageLink": "https://spurfitpublicimages.s3.us-east-2.amazonaws.com/AppVideosLatest/Images/4KmY44Xsg2w.jpg",
//       "videoLink": "https://www.youtube.com/watch?v=4KmY44Xsg2w&pp=ygUUc3F1YXRzIGZvciBiZWdpbm5lcnM%3D",
//       "movementInstr": "",
//       "paceUnit": "min/mi",
//       "sideInfo": "",
//       "heartRateType": "above",
//       "durationMax": {
//         "1": "",
//         "2": ""
//       },
//       "rounds": 2
//     }
//   },
//   {
//     "id": "8ba9ac53-88f4-44c3-8c3d-9f8f5b2c4ac6",
//     "workoutPerformedID": null,
//     "dateFinished": "2024-01-08T00:00:00.000Z",
//     "trackedMovementID": "HBYbyRRGCnde",
//     "atheleteID": "105067632829997666848",
//     "measurement": {
//       "movementName": "3 Way Raise (13)",
//       "reps": {
//         "1": 12,
//         "2": 12
//       },
//       "weight": {
//         "1": 19,
//         "2": 19
//       },
//       "weightUnits": "Pounds",
//       "movementID": "umtGtDIrNkBW",
//       "recordFromUser": {
//         "reps": true,
//         "weight": true,
//         "height": false,
//         "distance": false,
//         "calorie": false,
//         "duration": false,
//         "heartRate": false
//       },
//       "imageLink": "https://spurfitpublicimages.s3.us-east-2.amazonaws.com/AppVideosLatest/Images/4KmY44Xsg2w.jpg",
//       "videoLink": "https://www.youtube.com/watch?v=4KmY44Xsg2w&pp=ygUUc3F1YXRzIGZvciBiZWdpbm5lcnM%3D",
//       "movementInstr": "",
//       "paceUnit": "min/mi",
//       "sideInfo": "",
//       "heartRateType": "above",
//       "durationMax": {
//         "1": "",
//         "2": ""
//       },
//       "rounds": 2
//     }
//   },
//   {
//     "id": "9ba9ac53-88f4-44c3-8c3d-9f8f5b2c4ac6",
//     "workoutPerformedID": null,
//     "dateFinished": "2024-01-09T00:00:00.000Z",
//     "trackedMovementID": "HBYbyRRGCnde",
//     "atheleteID": "105067632829997666848",
//     "measurement": {
//       "movementName": "3 Way Raise (13)",
//       "reps": {
//         "1": 13,
//         "2": 13
//       },
//       "weight": {
//         "1": 20,
//         "2": 20
//       },
//       "weightUnits": "Pounds",
//       "movementID": "umtGtDIrNkBW",
//       "recordFromUser": {
//         "reps": true,
//         "weight": true,
//         "height": false,
//         "distance": false,
//         "calorie": false,
//         "duration": false,
//         "heartRate": false
//       },
//       "imageLink": "https://spurfitpublicimages.s3.us-east-2.amazonaws.com/AppVideosLatest/Images/4KmY44Xsg2w.jpg",
//       "videoLink": "https://www.youtube.com/watch?v=4KmY44Xsg2w&pp=ygUUc3F1YXRzIGZvciBiZWdpbm5lcnM%3D",
//       "movementInstr": "",
//       "paceUnit": "min/mi",
//       "sideInfo": "",
//       "heartRateType": "above",
//       "durationMax": {
//         "1": "",
//         "2": ""
//       },
//       "rounds": 2
//     }
//   },
//   {
//     "id": "10ba9ac53-88f4-44c3-8c3d-9f8f5b2c4ac6",
//     "workoutPerformedID": null,
//     "dateFinished": "2024-01-10T00:00:00.000Z",
//     "trackedMovementID": "HBYbyRRGCnde",
//     "atheleteID": "105067632829997666848",
//     "measurement": {
//       "movementName": "3 Way Raise (13)",
//       "reps": {
//         "1": 14,
//         "2": 14
//       },
//       "weight": {
//         "1": 21,
//         "2": 21
//       },
//       "weightUnits": "Pounds",
//       "movementID": "umtGtDIrNkBW",
//       "recordFromUser": {
//         "reps": true,
//         "weight": true,
//         "height": false,
//         "distance": false,
//         "calorie": false,
//         "duration": false,
//         "heartRate": false
//       },
//       "imageLink": "https://spurfitpublicimages.s3.us-east-2.amazonaws.com/AppVideosLatest/Images/4KmY44Xsg2w.jpg",
//       "videoLink": "https://www.youtube.com/watch?v=4KmY44Xsg2w&pp=ygUUc3F1YXRzIGZvciBiZWdpbm5lcnM%3D",
//       "movementInstr": "",
//       "paceUnit": "min/mi",
//       "sideInfo": "",
//       "heartRateType": "above",
//       "durationMax": {
//         "1": "",
//         "2": ""
//       },
//       "rounds": 2
//     }
//   },
//   {
//     "id": "11ba9ac53-88f4-44c3-8c3d-9f8f5b2c4ac6",
//     "workoutPerformedID": null,
//     "dateFinished": "2024-01-11T00:00:00.000Z",
//     "trackedMovementID": "HBYbyRRGCnde",
//     "atheleteID": "105067632829997666848",
//     "measurement": {
//       "movementName": "3 Way Raise (13)",
//       "reps": {
//         "1": 15,
//         "2": 15
//       },
//       "weight": {
//         "1": 22,
//         "2": 22
//       },
//       "weightUnits": "Pounds",
//       "movementID": "umtGtDIrNkBW",
//       "recordFromUser": {
//         "reps": true,
//         "weight": true,
//         "height": false,
//         "distance": false,
//         "calorie": false,
//         "duration": false,
//         "heartRate": false
//       },
//       "imageLink": "https://spurfitpublicimages.s3.us-east-2.amazonaws.com/AppVideosLatest/Images/4KmY44Xsg2w.jpg",
//       "videoLink": "https://www.youtube.com/watch?v=4KmY44Xsg2w&pp=ygUUc3F1YXRzIGZvciBiZWdpbm5lcnM%3D",
//       "movementInstr": "",
//       "paceUnit": "min/mi",
//       "sideInfo": "",
//       "heartRateType": "above",
//       "durationMax": {
//         "1": "",
//         "2": ""
//       },
//       "rounds": 2
//     }
//   },
//   {
//     "id": "12ba9ac53-88f4-44c3-8c3d-9f8f5b2c4ac6",
//     "workoutPerformedID": null,
//     "dateFinished": "2024-01-12T00:00:00.000Z",
//     "trackedMovementID": "HBYbyRRGCnde",
//     "atheleteID": "105067632829997666848",
//     "measurement": {
//       "movementName": "3 Way Raise (13)",
//       "reps": {
//         "1": 16,
//         "2": 16
//       },
//       "weight": {
//         "1": 23,
//         "2": 23
//       },
//       "weightUnits": "Pounds",
//       "movementID": "umtGtDIrNkBW",
//       "recordFromUser": {
//         "reps": true,
//         "weight": true,
//         "height": false,
//         "distance": false,
//         "calorie": false,
//         "duration": false,
//         "heartRate": false
//       },
//       "imageLink": "https://spurfitpublicimages.s3.us-east-2.amazonaws.com/AppVideosLatest/Images/4KmY44Xsg2w.jpg",
//       "videoLink": "https://www.youtube.com/watch?v=4KmY44Xsg2w&pp=ygUUc3F1YXRzIGZvciBiZWdpbm5lcnM%3D",
//       "movementInstr": "",
//       "paceUnit": "min/mi",
//       "sideInfo": "",
//       "heartRateType": "above",
//       "durationMax": {
//         "1": "",
//         "2": ""
//       },
//       "rounds": 2
//     }
//   },
//   {
//     "id": "13ba9ac53-88f4-44c3-8c3d-9f8f5b2c4ac6",
//     "workoutPerformedID": null,
//     "dateFinished": "2024-01-13T00:00:00.000Z",
//     "trackedMovementID": "HBYbyRRGCnde",
//     "atheleteID": "105067632829997666848",
//     "measurement": {
//       "movementName": "3 Way Raise (13)",
//       "reps": {
//         "1": 17,
//         "2": 17
//       },
//       "weight": {
//         "1": 24,
//         "2": 24
//       },
//       "weightUnits": "Pounds",
//       "movementID": "umtGtDIrNkBW",
//       "recordFromUser": {
//         "reps": true,
//         "weight": true,
//         "height": false,
//         "distance": false,
//         "calorie": false,
//         "duration": false,
//         "heartRate": false
//       },
//       "imageLink": "https://spurfitpublicimages.s3.us-east-2.amazonaws.com/AppVideosLatest/Images/4KmY44Xsg2w.jpg",
//       "videoLink": "https://www.youtube.com/watch?v=4KmY44Xsg2w&pp=ygUUc3F1YXRzIGZvciBiZWdpbm5lcnM%3D",
//       "movementInstr": "",
//       "paceUnit": "min/mi",
//       "sideInfo": "",
//       "heartRateType": "above",
//       "durationMax": {
//         "1": "",
//         "2": ""
//       },
//       "rounds": 2
//     }
//   },
//   {
//     "id": "14ba9ac53-88f4-44c3-8c3d-9f8f5b2c4ac6",
//     "workoutPerformedID": null,
//     "dateFinished": "2024-01-14T00:00:00.000Z",
//     "trackedMovementID": "HBYbyRRGCnde",
//     "atheleteID": "105067632829997666848",
//     "measurement": {
//       "movementName": "3 Way Raise (13)",
//       "reps": {
//         "1": 18,
//         "2": 18
//       },
//       "weight": {
//         "1": 25,
//         "2": 25
//       },
//       "weightUnits": "Pounds",
//       "movementID": "umtGtDIrNkBW",
//       "recordFromUser": {
//         "reps": true,
//         "weight": true,
//         "height": false,
//         "distance": false,
//         "calorie": false,
//         "duration": false,
//         "heartRate": false
//       },
//       "imageLink": "https://spurfitpublicimages.s3.us-east-2.amazonaws.com/AppVideosLatest/Images/4KmY44Xsg2w.jpg",
//       "videoLink": "https://www.youtube.com/watch?v=4KmY44Xsg2w&pp=ygUUc3F1YXRzIGZvciBiZWdpbm5lcnM%3D",
//       "movementInstr": "",
//       "paceUnit": "min/mi",
//       "sideInfo": "",
//       "heartRateType": "above",
//       "durationMax": {
//         "1": "",
//         "2": ""
//       },
//       "rounds": 2
//     }
//   },
//   {
//     "id": "15ba9ac53-88f4-44c3-8c3d-9f8f5b2c4ac6",
//     "workoutPerformedID": null,
//     "dateFinished": "2024-01-15T00:00:00.000Z",
//     "trackedMovementID": "HBYbyRRGCnde",
//     "atheleteID": "105067632829997666848",
//     "measurement": {
//       "movementName": "3 Way Raise (13)",
//       "reps": {
//         "1": 19,
//         "2": 19
//       },
//       "weight": {
//         "1": 26,
//         "2": 26
//       },
//       "weightUnits": "Pounds",
//       "movementID": "umtGtDIrNkBW",
//       "recordFromUser": {
//         "reps": true,
//         "weight": true,
//         "height": false,
//         "distance": false,
//         "calorie": false,
//         "duration": false,
//         "heartRate": false
//       },
//       "imageLink": "https://spurfitpublicimages.s3.us-east-2.amazonaws.com/AppVideosLatest/Images/4KmY44Xsg2w.jpg",
//       "videoLink": "https://www.youtube.com/watch?v=4KmY44Xsg2w&pp=ygUUc3F1YXRzIGZvciBiZWdpbm5lcnM%3D",
//       "movementInstr": "",
//       "paceUnit": "min/mi",
//       "sideInfo": "",
//       "heartRateType": "above",
//       "durationMax": {
//         "1": "",
//         "2": ""
//       },
//       "rounds": 2
//     }
//   },
//   {
//     "id": "16ba9ac53-88f4-44c3-8c3d-9f8f5b2c4ac6",
//     "workoutPerformedID": null,
//     "dateFinished": "2024-01-16T00:00:00.000Z",
//     "trackedMovementID": "HBYbyRRGCnde",
//     "atheleteID": "105067632829997666848",
//     "measurement": {
//       "movementName": "3 Way Raise (13)",
//       "reps": {
//         "1": 20,
//         "2": 20
//       },
//       "weight": {
//         "1": 27,
//         "2": 27
//       },
//       "weightUnits": "Pounds",
//       "movementID": "umtGtDIrNkBW",
//       "recordFromUser": {
//         "reps": true,
//         "weight": true,
//         "height": false,
//         "distance": false,
//         "calorie": false,
//         "duration": false,
//         "heartRate": false
//       },
//       "imageLink": "https://spurfitpublicimages.s3.us-east-2.amazonaws.com/AppVideosLatest/Images/4KmY44Xsg2w.jpg",
//       "videoLink": "https://www.youtube.com/watch?v=4KmY44Xsg2w&pp=ygUUc3F1YXRzIGZvciBiZWdpbm5lcnM%3D",
//       "movementInstr": "",
//       "paceUnit": "min/mi",
//       "sideInfo": "",
//       "heartRateType": "above",
//       "durationMax": {
//         "1": "",
//         "2": ""
//       },
//       "rounds": 2
//     }
//   },
// ]

export let chartViewData = [
  {
    date: "17 Jan 2024",
    reps: 5,
    weight: 87,
  },
  {
    date: "18 Jan 2024",
    reps: 6,
    weight: 56,
  },
  {
    date: "19 Jan 2024",
    reps: 8,
    weight: 68,
  },
];


export function getMovementData(startDate, endDate, movementData) {
  // Convert start and end dates to Date objects
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Ensure end date includes the whole day
  end.setHours(23, 59, 59, 999);

  // Initialize an empty array to store the results
  const result = [];

  // Iterate through the movement data
  movementData.forEach((data) => {
    const dateFinished = new Date(data?.dateFinished);

    // Check if the dateFinished is within the date range
    if (dateFinished >= start && dateFinished <= end) {
      // Calculate total reps and total weight for the day
      const totalReps = Object.values(data?.measurement?.reps||{}).reduce(
        (sum, reps) => sum + reps,
        0
      );
      const totalWeight = Object.values(data?.measurement?.weight||{}).reduce(
        (sum, weight) => sum + weight,
        0
      );

      // Push the result to the result array
      result.push({
        date: dateFinished.toISOString().split("T")[0],
        "Total Reps": totalReps,
        "Total Weight": totalWeight,
      });
    }
  });

  return result;
}







 function getmovementData(startDate, endDate, movementData) {
  // Convert start and end dates to Date objects
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Format the dates to YYYY-MM-DD
  const startDateStr = start.toISOString().split("T")[0];
  const endDateStr = end.toISOString().split("T")[0];

  // Initialize an empty array to store the results
  const result = [];

  // Iterate through the movement data
  movementData.forEach((data) => {
    const dateFinished = new Date(data.dateFinished);
    const dateFinishedStr = dateFinished.toISOString().split("T")[0];

    // Check if the dateFinished is within the date range
    if (dateFinishedStr >= startDateStr && dateFinishedStr <= endDateStr) {
      // Calculate total reps and total weight for the day
      const totalReps = Object.values(data.measurement.reps).reduce(
        (sum, reps) => sum + reps,
        0
      );
      const totalWeight = Object.values(data.measurement.weight).reduce(
        (sum, weight) => sum + weight,
        0
      );

      // Push the result to the result array
      result.push({
        date: dateFinishedStr,
        reps: totalReps,
        weight: totalWeight,
      });
    }
  });

  return result;
}