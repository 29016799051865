import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Dialog, DialogActions, DialogContent, Divider } from "@mui/material";
import moment from "moment";
import { useContext, useState } from "react";

import axios from "axios";
import { useSelector } from "react-redux";
import { CustomAlertContext } from "../../../App";
import checked from "../../../assets/trainerProfile/checked.svg";
import unchecked from "../../../assets/trainerProfile/unchecked.svg";
import {
  deleteAssignedPhasedPrograms,
  deleteAssignedPrograms,
} from "@/ApiServices/Programs/Api";

const AssignedUsersDialog = ({
  isOpen,
  setIsOpen,
  programName,
  day,
  programAssignedUsers,
  setProgramAssignedUsers,
  programAssignedChannels,
  isPhasedProgram,
}) => {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);

  const { displayAlert } = useContext(CustomAlertContext);
  console.log("programAssignedChannels: ", programAssignedChannels);

  // Define a separate function for the API call

  // Refactor deleteSelected to use the new deleteAssignedPrograms function
  const deleteSelected = async () => {
    if (selected.length === 0) return;
    setLoading(true);

    const { success, message } = isPhasedProgram
      ? await deleteAssignedPhasedPrograms(selected, true)
      : await deleteAssignedPrograms(selected);

    if (success) {
      let newProgramAssignedUsers = programAssignedUsers.filter(
        (programAssignedUser) => !selected.includes(programAssignedUser.id)
      );
      setProgramAssignedUsers(newProgramAssignedUsers);
      displayAlert({
        type: "success",
        message: message,
      });
      if (newProgramAssignedUsers.length === 0) setIsOpen(false);
    } else {
      displayAlert({
        type: "error",
        message: message,
      });
    }

    setLoading(false);
    setSelected([]);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: {
          borderRadius: "20px",
        },
      }}
    >
      <div className="add-program-modal-head">
        <div style={{ display: "flex", alignItems: "flex-end", gap: "8px" }}>
          <p className="add-program-modal-head-title">Delete subscribers</p>
        </div>
        <div
          onClick={() => {
            setIsOpen(false);
          }}
          style={{
            cursor: "pointer",
          }}
        >
          <CloseRoundedIcon />
        </div>
      </div>
      <DialogContent sx={{ padding: 0, overflowY: "scroll" }}>
        <div className="add-program-modal-body">
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                padding: "10px 24px 10px 44px",
                borderBottom: "1px solid #E0E0E0",
              }}
            >
              <span
                style={{ width: "40%", marginLeft: "16px", fontWeight: 600 }}
                className="select-workouts_item-description"
              >
                User Name
              </span>
              <span
                style={{ width: "30%", marginLeft: "8px", fontWeight: 600 }}
                className="select-workouts_item-description"
              >
                Date Assigned
              </span>
            </div>
            {programAssignedUsers?.map((assignedProgram, index) => {
              console.log("user: ", assignedProgram);
              const userName = assignedProgram?.athelete?.userName;
              let programAssignedId = assignedProgram?.id;
              let dateAssigned = assignedProgram?.dateAssigned
                ? moment(assignedProgram?.dateAssigned).format("MMMM Do' YY")
                : "";

              let selectionId = programAssignedId;

              return (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    padding: "10px 24px",
                  }}
                >
                  {selected?.includes(selectionId) ? (
                    <img
                      src={checked}
                      alt="checked"
                      style={{ width: "20px", height: "20px" }}
                      onClick={() => {
                        setSelected(
                          selected.filter((item) => item !== selectionId)
                        );
                      }}
                    />
                  ) : (
                    <img
                      src={unchecked}
                      alt="unchecked"
                      style={{ width: "20px", height: "20px" }}
                      onClick={() => {
                        setSelected([...selected, selectionId]);
                      }}
                    />
                  )}
                  <span
                    style={{ width: "40%", marginLeft: "16px" }}
                    className="select-workouts_item-title"
                  >
                    {userName}
                  </span>
                  <span
                    style={{ width: "30%" }}
                    className="select-workouts_item-description"
                  >
                    {dateAssigned}
                  </span>
                </div>
              );
            })}

            <Divider style={{ width: "100%", marginTop: "20px" }} />

            {programAssignedChannels?.length > 0 && (
              <>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    padding: "10px 24px 10px 44px",
                    borderBottom: "1px solid #E0E0E0",
                  }}
                >
                  <span
                    style={{
                      width: "40%",
                      marginLeft: "16px",
                      fontWeight: 600,
                    }}
                    className="select-workouts_item-description"
                  >
                    Channel Name
                  </span>
                  <span
                    style={{ width: "30%", marginLeft: "8px", fontWeight: 600 }}
                    className="select-workouts_item-description"
                  >
                    Date Assigned
                  </span>
                </div>
                {programAssignedChannels?.map((assignedProgram, index) => {
                  let channelName = assignedProgram?.channelName;
                  let dateAssigned = assignedProgram?.progStartDate
                    ? moment(assignedProgram?.progStartDate).format(
                        "MMMM Do' YY"
                      )
                    : "";
                  return (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        padding: "10px 24px",
                        marginLeft: "22px",
                      }}
                    >
                      <span
                        style={{ width: "40%", marginLeft: "16px" }}
                        className="select-workouts_item-title"
                      >
                        {channelName}
                      </span>
                      <span
                        style={{ width: "30%" }}
                        className="select-workouts_item-description"
                      >
                        {dateAssigned}
                      </span>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "center",
          paddingBottom: "16px",
        }}
      >
        <button
          disabled={loading || selected.length === 0}
          className="button-new"
          onClick={deleteSelected}
        >
          {loading ? "Deleting..." : "Delete"}
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignedUsersDialog;
