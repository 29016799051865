import AddUserIcon from "@/pages/library/assests/AddUserIcon";
import CalendarIcon from "@/pages/library/assests/CalendarIcon";
import DumblePurple from "@/pages/library/assests/DumblePurple";
import { CirclePlus, MoreHorizontal, MoreVertical, NotebookPen } from "lucide-react";
import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import DropDownMenu from "./DropDownMenu";
import EditProgramInfo from "./EditProgramInfo";
import { ProgramsContext } from "./ProgramsContext";
import ProgramHeaderLoadingUI from "./ProgramHeaderLoadingUI";
import AddNotesDialogeForPrograms from "./AddNotesDialogeForPrograms";
import FolderToSelectDialog from "./FolderToSelectDialog";
import MenuOptions from "../Workouts/MenuOptions";
import Delete from "../../assets/workouts/DeleteIcon.svg";
import AssignUserIcon from "../../assets/workouts/AssignUserIcon.svg";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";

const ProgramHeader = ({
  name,
  weeks,
  totalWorkouts,
  setShowAssignedUsersDialog,
  setShowAssignModal,
  setShowDuplicateProgramModal,
  isSubbordinateProgram,
  isAdminProgram,
}) => {
  const {
    addedWorkouts,
    daysMessages,
    daysTargets,
    unsavedChanges,
    saveProgramDetails,
    isTemplate,
    selectedProgramData,
    setSelectedProgramData,
    programSubscribers,
    deleteProgramByID,
    duplicateAsTemplate,
    setDuplicateAsTemplate,
    updateProgramInformation,
    saveProgram,
    loadingSelectedProgram,
    updateContainerInfo,
    selectedProgram,
    folderSelected,
    setDialogToSelectFolder,
    dialogToSelectFolder,
    folderDelete,
    folders,
    templateFolders,
    selectedTab,
    originalState,
  } = useContext(ProgramsContext);

  const [openNotesDialoge, setOpenNotesDialoge] = useState(false);
  const coachID = useSelector((state) => state.auth.trainerUUID);
  const isLivezy = useSelector((state) => state.auth.isLivezy);

  const isTemplateProgram = selectedProgramData?.isTemplate;

  if (loadingSelectedProgram) {
    return <ProgramHeaderLoadingUI />;
  }

  const templateOptions = [
    "Duplicate template",
    "Delete template",
    typeof selectedProgramData?.programFolderId !== "string" && "Add To Folder",
  ];
  const templateOptionIcon=[
    <ContentCopyRoundedIcon />,
    <img src={Delete} alt="Delete" />,
    typeof selectedProgramData?.programFolderId !== "string" ? <CirclePlus/> : null 
  ]
  const programOptions = [
    "Duplicate program",
    ...(coachID == selectedProgram?.coachID
      ? [
          "Delete program",
          typeof selectedProgramData?.programFolderId !== "string" &&
            "Add To Folder",
        ]
      : []),
  ];
  const programOptionIcon=[
    <ContentCopyRoundedIcon />,
    ...(coachID == selectedProgram?.coachID
      ?[
        <img src={Delete} alt="Delete" />,
        typeof selectedProgramData?.programFolderId !== "string" ? <CirclePlus/> : null 
          
      ]:[])
  ]

  return (
    <div className="w-full border-b">
      <div className="w-full h-[52px] flex items-center justify-between px-3 border-b">
        <div className="flex items-center gap-3">
          <p className="font-DMSans text-font16">{name}</p>
          {!isSubbordinateProgram && !isAdminProgram && (
            <EditProgramInfo
              selectedProgramData={selectedProgramData}
              updateProgramInformation={(id, name, description) => {
                if (selectedProgramData?.isNew) {
                  setSelectedProgramData({
                    ...selectedProgramData,
                    name,
                    description,
                  });
                } else {
                  updateProgramInformation(id, name, description);
                }
              }}
            />
          )}
        </div>

        {!isSubbordinateProgram && (
          <div className="flex items-center gap-4">
            {!isAdminProgram && (
              <>
                {programSubscribers?.length > 0 && (
                  <div className="flex items-center">
                    <button
                      className="font-DMSans text-font14 font-[500]"
                      onClick={() => {
                        if (programSubscribers.length > 0) {
                          setShowAssignedUsersDialog(true);
                        }
                      }}
                    >
                      Subscribers
                    </button>
                  </div>
                )}
                {!selectedProgramData?.isTemplate &&
                  !selectedProgramData?.isNew && (
                    <>
                      
                        <button
                          onClick={() => {
                            setShowAssignModal(true);
                          }}
                        >
                          <AddUserIcon className="h-[18px] w-[18px]" />
                        </button>
                  

                      <p
                        style={{
                          fontFamily: "DM Sans",
                          fontWeight: "500",
                          fontSize: "14px",
                          color: "#424242",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "flex-end",
                        }}
                        onClick={() => setOpenNotesDialoge(true)}
                      >
                        Notes{" "}
                        {selectedProgram?.notes?.length > 0 ? (
                          <span className="ml-1 w-[22px] text-center text-[13px] font-[400] bg-gray-300 rounded-full">
                            {selectedProgram?.notes?.length}
                          </span>
                        ) : (
                          ""
                        )}
                      </p>
                    </>
                  )}
              </>
            )}

            {!selectedProgramData.cFProgramPhasesContainerId &&
              !selectedProgramData?.isNew && (
                <MenuOptions
                isHoriz={false}
                className={"mr-3"}
                options={  
                  isTemplateProgram
                    ? [
                        "Copy to program",
                        ...(selectedProgramData?.coachID === coachID
                          ? templateOptions
                          : []),
                      ]
                    : programOptions
                }
             
                optionIcons={
                  isTemplateProgram ?
                  [
                    <ContentCopyRoundedIcon />,
                    ...(selectedProgramData?.coachID === coachID
                      ? templateOptionIcon
                      :[]
                    )]
                    : programOptionIcon
                  }
                // menuItemStyles={[{ color: "#FF3A29" }]}
                onOptionClick={(option) => {
                  if (
                          option === "Delete program" ||
                          option === "Delete template"
                        ) {
                          deleteProgramByID(selectedProgramData?.id);
                        } else if (
                          option === "Duplicate program" ||
                          option === "Duplicate template"
                        ) {
                          setShowDuplicateProgramModal(true);
                          if (
                            option === "Delete program" ||
                            option === "Delete template"
                          ) {
                            deleteProgramByID(selectedProgramData?.id);
                          } else if (
                            option === "Duplicate program" ||
                            option === "Duplicate template"
                          ) {
                            setShowDuplicateProgramModal(true);
                            if (
                              selectedProgramData?.isTemplate &&
                              selectedProgramData?.coachID == coachID
                            ) {
                              setDuplicateAsTemplate(true);
                            } else {
                              setDuplicateAsTemplate(false);
                            }
                          }
                        } else if (option === "Copy to program") {
                          setShowDuplicateProgramModal(true);
                          setDuplicateAsTemplate(false);
                        } else if (option === "Copy to program") {
                          setShowDuplicateProgramModal(true);
                          setDuplicateAsTemplate(false);
                        } else if (option === "Add To Folder") {
                          setDialogToSelectFolder(true);
                        }
                }}
              />
              )}
          </div>
        )}
      </div>
      {openNotesDialoge && (
        <AddNotesDialogeForPrograms
          open={openNotesDialoge}
          setIsOpen={setOpenNotesDialoge}
          tittle={"Notes"}
          selectedProgramData={selectedProgram}
          updateProgramInformation={updateProgramInformation}
          isNotesForPrograms={true}
        />
      )}
      {
        <div className="program-conatiner-body-details p-[24px]">
          <div className="program-conatiner-body-details-card">
            <div>
              <p className="program-conatiner-body-details-card-title">
                Total Weeks
              </p>
              <p className="program-conatiner-body-details-card-description">
                {weeks} Week{weeks > 1 ? "s" : ""}
              </p>
            </div>
            <CalendarIcon />
          </div>
          <div
            style={{ background: "#FAFAFF" }}
            className="program-conatiner-body-details-card "
          >
            <div>
              <p className="program-conatiner-body-details-card-title">
                Total Workouts
              </p>
              <p className="program-conatiner-body-details-card-description">
                {totalWorkouts}
              </p>
            </div>
            <DumblePurple />
          </div>
          {/* {!isSubbordinateProgram && (
                 
                )} */}
          <button
            className="button-new"
            style={{
              display:
                (Object.entries(addedWorkouts)?.length > 0 ||
                  Object.entries(daysMessages || {}).length > 0 ||
                  Object.entries(daysTargets || {}).length > 0) &&
                unsavedChanges
                  ? "block"
                  : "none",
            }}
            onClick={() => saveProgram()}
            disabled={!unsavedChanges}
          >
            {isTemplate || selectedProgramData?.isTemplate
              ? "Save as template"
              : "Save program"}
          </button>
        </div>
      }

      {dialogToSelectFolder && (
        <FolderToSelectDialog
          open={dialogToSelectFolder}
          setOpen={setDialogToSelectFolder}
          selectedProgram={selectedProgram}
          folders={selectedTab === "templates" ?  originalState?.templateFolders
              : originalState?.folders}
        />
      )}
    </div>
  );
};

export default ProgramHeader;
