import React, { useState } from "react";

const RenderYAxis = ({ totalBlocksContainerRef }) => {
  return (
    <div
      style={{
        height: "350px",
        minWidth: "40px",
      }}
      className="flex flex-col-reverse mb-[24px]"
    >
      {Array.from({ length: 7 }).map((_, index) => {
        return (
          <div
            key={index}
            className="flex items-end justify-center h-8 font-DMSans text-[#ccc] text-font12 font-medium relative"
            style={{ minHeight: "50px", maxHeight: "50px" }}
          >
            <p className="absolute -bottom-1">{(index) * 25} %</p>
          </div>
        );
      })}
    </div>
  );
};

export default RenderYAxis;
