import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

function BasicTextFields({
  value,
  placeholder = '',
  label = '',
  style,
  width,
  onChange,
  showOnly,
  defaultValue,
  min
}) {
  console.log("min",min)
  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': {
          m: 0,
          width,
        },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField
        InputProps={{ disableUnderline: true }}
        disabled={showOnly}
        placeholder={placeholder}
        value={value}
        id="outlined-basic"
        type="number"
        onChange={(e) => onChange(e)}
        // label={label}
        defaultValue={defaultValue}
        variant="outlined"
        inputProps={{ min: min? min : 0, style }}
      />
    </Box>
  );
}

export default BasicTextFields;
