import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import { useContext, useState } from "react";
import { FOOD_BASED, MACOS_BASED } from "../../../constants";
import { DescriptionInput, NutritionInput } from "../AddRecipieModal";
import { display } from "styled-system";
import { NutritionContext } from "../NutritionContext";
import { v4 as uuidv4 } from "uuid";

function AddMealPlanModal({
  isOpen,
  setIsOpen,
  setNutritionData,
  setdefaultNItem,
  isTemplate = false,
  openAddModal,
  fromFolderInfo,
  isCopyToMeal,
}) {
  const [referenceName, setReferenceName] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [description, setDescription] = useState("");
  const [mealType, setMealType] = useState("folder");
  const [dietType, setDietType] = useState(null);
  const { createNewFolder, setFolderSelected, setOpenFolderId } =
    useContext(NutritionContext);

  const updateNutritionDataState = () => {
    const id = uuidv4();
    setNutritionData((prev) => {
      return [
        {
          id: id,
          isNew: true,
          reference_name: referenceName,
          display_name: displayName,
          description,
          meal_plan_type: mealType,
          diet_type: dietType,
          isTemplate,
        },
        ...prev,
      ];
    });
    setdefaultNItem({
      id: id,
      isNew: true,
      reference_name: referenceName,
      display_name: displayName,
      description,
      meal_plan_type: mealType,
      diet_type: dietType,
      isTemplate,
    });
    setFolderSelected({});
    setOpenFolderId("");
    let timeout = setTimeout(() => {
      openAddModal();
      clearTimeout(timeout);
    }, 1000);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        setReferenceName("");
        setDescription("");
        setIsOpen(false);
      }}
      PaperProps={{
        sx: {
          // padding: "24px",
          width: "520px",
          borderRadius: "20px",
        },
      }}
    >
      <div className="add-program-modal-head">
        <p className="add-program-modal-head-title">
          {isTemplate ? "Add Template" : "Create"}
        </p>
        <CloseRoundedIcon
          sx={{
            cursor: "pointer",
          }}
          onClick={() => {
            setIsOpen(false);
          }}
        />
      </div>
      <DialogContent>
        <Stack spacing={2}>
          <RadioGroup
            row
            aria-labelledby="mealplans-row-radio-buttons-group-label"
            name="meal-plan-radio-buttons-group"
            value={mealType}
            onChange={(e) => setMealType(e.target.value)}
            className="flex items-center justify-between"
          >
            {!fromFolderInfo && (
              <FormControlLabel
                value={"folder"}
                control={<Radio />}
                label="Folder"
              />
            )}
            <FormControlLabel
              value={FOOD_BASED}
              control={<Radio />}
              label="Food Based"
            />

            <FormControlLabel
              value={MACOS_BASED}
              control={<Radio />}
              label={"Macros Based"}
            />
          </RadioGroup>
          {mealType !== "folder" && (
            <NutritionInput
              placeholder={"Vegan low fat plan"}
              label={"Meal Name*"}
              value={referenceName}
              onChange={(value) => setReferenceName(value)}
              style={{ width: "100%" }}
            />
          )}
          {mealType === "folder" && (
            <NutritionInput
              placeholder={"Name"}
              label={"Folder Name*"}
              value={referenceName}
              onChange={(value) => setReferenceName(value)}
              style={{ width: "100%" }}
            />
          )}

          <DescriptionInput
            label={"Description*"}
            placeholder={"Description (optional)"}
            value={description}
            onChange={(value) => setDescription(value)}
          />
          {mealType !== "folder" && (
            <div
              style={{
                minWidth: "100%",
                display: "flex",
                flexDirection: "column",
                paddingLeft: "12px",
                paddingRight: "12px",
              }}
            >
              <label
                style={{
                  fontFamily: "DM Sans",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "20px",
                  textAlign: "left",
                }}
              >
                Diet Type
              </label>
              <RadioGroup
                row
                aria-labelledby="diettype-row-radio-buttons-group-label"
                name="diet-type-radio-buttons-group"
                value={dietType}
                onChange={(e) => setDietType(e.target.value)}
              >
                <FormControlLabel
                  value={"Veg"}
                  control={<Radio />}
                  label="Veg"
                />
                <FormControlLabel
                  value={"Non-Veg"}
                  control={<Radio />}
                  label="Non-Veg"
                />
                <FormControlLabel
                  value={"Vegan"}
                  control={<Radio />}
                  label="Vegan"
                />
              </RadioGroup>
            </div>
          )}
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          pb: "24px",
        }}
      >
        {mealType !== "folder" && (
          <button
            disabled={referenceName.length < 2}
            style={{ marginTop: "8px" }}
            className="button-new"
            onClick={() => {
              updateNutritionDataState();
              setIsOpen(false);
            }}
          >
            Done
          </button>
        )}
        {mealType === "folder" && (
          <button
            disabled={referenceName.length < 2}
            style={{ marginTop: "8px" }}
            className="button-new"
            onClick={async () => {
              await createNewFolder(referenceName, description);
              setIsOpen(false);
            }}
          >
            Create Folder
          </button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default AddMealPlanModal;
