import { store } from "@/redux/store";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import ReactPlayer from "react-player";
import { SpurfitCircularProgress } from "../../components/Dashboard/dashboard";

const BookCallDialog = ({
  isOpen,
  setIsOpen,
  setShowCalendlyWidget,
  email,
  name,
  trainerUUID,
}) => {
  const [showCalendly, setShowCalendly] = useState(false);
  const [noScheduledCall, setNoScheduledCall] = useState(true);

  useCalendlyEventListener({
    onProfilePageViewed: () => console.log("onProfilePageViewed"),
    onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
    onEventTypeViewed: () => console.log("onEventTypeViewed"),
    onEventScheduled: (e) => {
      console.log(e.data.payload);
      setNoScheduledCall(false);
      updateBookedCall(trainerUUID);
    },
  });

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      maxWidth={"md"}
      PaperProps={{
        sx: {
          borderRadius: "20px",
          width: "600px",
          minHeight: "400px",
        },
      }}
    >
      <div className="add-program-modal-head">
        <p className="add-program-modal-head-title">Welcome to Spur.fit</p>
        {/* <div
          onClick={() => {
            setIsOpen(false);
          }}
          className="cursor-pointer"
        >
          <CloseRoundedIcon />
        </div> */}
      </div>
      <DialogContent>
        {showCalendly ? (
          <div className="calendly-container">
            {/* <div
              class="calendly-inline-widget"
              data-url="https://calendly.com/rahulaluri/connectwithspurfit"
              style={{ minWidth: "320px", height: "700px" }}
            ></div> */}
            <InlineWidget
              prefill={{
                name: name,
                email: email,
              }}
              LoadingSpinner={() => <SpurfitCircularProgress />}
              url="https://calendly.com/rahulaluri/connectwithspurfit"
            />
            {/* <PopupWidget
              url="https://calendly.com/rahulaluri/connectwithspurfit"
              
              rootElement={document.getElementById("root")}
              text="Click here to schedule!"
              textColor="#ffffff"
              color="#00a2ff"
            /> */}
          </div>
        ) : (
          <ReactPlayer
            width={"550px"}
            controls
            url="https://youtu.be/7my5hr6qrKw"
          />
        )}
      </DialogContent>

      <DialogActions
        sx={{
          justifyContent: showCalendly ? "space-between" : "center",
        }}
      >
        {showCalendly && (
          <button
            style={{
              borderWidth: 2,
              borderRadius: 100,
              width: "148px",
              height: "44px",
            }}
            className="button-new-outlined"
            onClick={() => {
              setShowCalendly(false);
            }}
          >
            Back
          </button>
        )}
        {showCalendly && noScheduledCall ? (
          <button
            style={{
              borderWidth: 2,
              borderRadius: 100,
              width: "148px",
              height: "44px",
            }}
            className="button-new-outlined"
            onClick={() => {
              setIsOpen(false);
              setShowCalendlyWidget(true);
            }}
          >
            Skip
          </button>
        ) : (
          <button
            className="button-new"
            onClick={() => {
              if (showCalendly) {
                setIsOpen(false);
              }
              setShowCalendly(true);
            }}
          >
            {showCalendly ? "Get Started" : "Next"}
          </button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default BookCallDialog;

export const updateBookedCall = async (trainerUUID) => {
  try {
    await axios({
      method: "POST",
      url: `${
        store.getState().auth.apiURL
      }CF/api/CoachWeb/Update/UpdateCoachFields`,
      data: {
        coachID: trainerUUID,
        demoCallDate: new Date(),
      },
    });
  } catch (error) {}
};
