import React, { useState } from "react";
import { Dialog, DialogContent } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function VideoPlayer({ videoUrl, open, setOpen }) {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      fullWidth
      PaperProps={{
        sx: { minHeight: "50vh", overflow: "hidden" }, // Add overflow: hidden to prevent scrolling
      }}
      disableScrollLock
    >
      <CloseIcon
        onClick={() => {
          setOpen(false);
        }}
        sx={{
          fontSize: 25,
          position: "absolute",
          right: 0,
          top: 0,
          cursor: "pointer",
          zIndex: 1, // Add zIndex to ensure the icon is visible
        }}
      />
      <DialogContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        {/* Add styles to the DialogContent component */}
        <video
          controls
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "50vh",
            paddingTop: "25px",
            paddingBottom: "25px",
          }}
          src={videoUrl}
        />
      </DialogContent>
    </Dialog>
  );
}

export default VideoPlayer;
