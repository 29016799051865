import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  Autocomplete,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import axios from "axios";
import mixpanel from "mixpanel-browser";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import APIURL from "../../APIDetails";
import { CustomAlertContext } from "../../App";
import PencilIcon from "../../assets/login/PencilIcon.svg";
import Logo from "../../assets/spurfit.svg";
import DefaultImage from "../../assets/trainerProfile/defaultImage.svg";
import SpinnerComponent from "../../components/layout/spinner";
import ImageUploadWithCrop from "../profile/ImageUploadWithCrop";
import "./style.css";
import Seyma from "../../assets/seyma.jpg";
import { useFormik } from "formik";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import * as Yup from "yup";
import img1 from "../../assets/one-line-features/aiCopilot.png";
import img2 from "../../assets/one-line-features/leadGen.png";
import img3 from "../../assets/one-line-features/hierarchy.png";
import img4 from "../../assets/one-line-features/communicate.png";
import img5 from "../../assets/one-line-features/track.png";
import img6 from "../../assets/one-line-features/mealPlans.png";
import img7 from "../../assets/one-line-features/videoLibrary.png";
import img8 from "../../assets/one-line-features/workout.png";
import img9 from "../../assets/one-line-features/payments.png";
import img10 from "../../assets/one-line-features/customBranding.png";
import reviewTrainerIcon from "../../assets/signupTrainerIcon.png";
export const TrainerSignup = () => {
  const reviews = [
    {
      name: "Seyma",
      review:
        "Spur.Fit is a one-stop solution for me & my clients. Creating workouts, meal plans, communication, managing payments, custom branded app & is just so easy to use.",
      designation: "Online fitness coach",
    },
  ];
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryParamEmail = queryParams.get("email");
  console.log("query param email", queryParamEmail);
  const history = useHistory();
  const { id, affiliation, affiliateCoachID } = useParams();
  const [activityLoaderOn, setActivityLoader] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [openImagePicker, setOpenImagePicker] = useState(false);
  const [trainerImage, setTrainerImage] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [trainerInfo, setTrainerInfo] = useState({
    name: "",
    email: "",
    gender: "",
  });

  useEffect(() => {
    document.title = "Spur.Fit- AI Assistant for fitness trainers | Sign Up";
    const metaDescriptionTag = document.querySelector(
      'meta[name="description"]'
    );
    if (metaDescriptionTag) {
      metaDescriptionTag.setAttribute(
        "content",
        " Spur.Fit is an AI-based coaching platform designed to help fitness & wellness trainers to save their time by creating personalised workout & meal plans. Sign up now & get started for free"
      );
    }
    console.log("use params feild", id, affiliation);
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    mixpanel.init("147a208f5832549c876d94601401033e", {
      //debug: true,
      api_host: "https://api.mixpanel.com",
      ignore_dnt: true,
    });
    if (
      localStorage.getItem("isLoggedIn") !== null &&
      JSON.parse(localStorage.getItem("isLoggedIn"))
    ) {
      let trainerDetails = JSON.parse(
        localStorage.getItem("trainerCredentials")
      );

      mixpanel.identify(trainerDetails?.password);
      mixpanel.people.set_once({
        Name: trainerDetails?.name,
        Email: trainerDetails?.email,
      });
      mixpanel.track("Trainer_Register");

      // navigate('/home');
      history.push("/home");
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      document.title = "Spur.fit";
    };
  }, []);
  const { displayAlert } = useContext(CustomAlertContext);

  const onRegister = ({ trainerInfo }) => {
    let url =
      id || affiliateCoachID
        ? `${APIURL}CF/api/CoachWeb/Create/trainerSignUpAdminRef`
        : `${APIURL}CF/api/CoachWeb/Create/trainerSignUp`;
    axios
      .post(url, trainerInfo)
      .then((res) => {
        history.push("/", {
          origin: "trainerSignup",
        });
        displayAlert({
          message: "Registered Successfully! Please check your email.",
          type: "success",
        });
        // uploadProfilePic(res.data.data.primaryKey);
      })
      .catch((err) => {
        displayAlert({
          message: "Something went wrong! Please try again later.",
          type: "error",
        });
      })
      .finally(() => {
        setActivityLoader(false);
      });
  };

  // upload profile pic
  const handleProfilePic = async (profilePic) => {
    setTrainerImage(profilePic);
    setOpenImagePicker(false);
  };

  const uploadProfilePic = async (id) => {
    if (!trainerImage || trainerImage === "") {
      return;
    }
    const formData = new FormData();
    formData.append("image", trainerImage);

    await axios({
      method: "post",
      url: `${APIURL}api/uploadtrainerprofilepic?trainerUUID=${id}`,
      data: formData,
    })
      .then(async (res) => {
        // toast("Successfully Uploaded!", "success");
        console.log("res", res);
      })
      .catch((err) => {
        // toast("Incorrect Image dimension. Need to be square", "error");
      });
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters")
      .max(25, "Password must be at most 16 characters"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
    gender: Yup.string().required("Gender is required"),
    country: Yup.string().required("Country is required"),
    unitSystem: Yup.string().required("Unit System is required"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: queryParamEmail || "",
      password: "",
      confirmPassword: "",
      gender: "",
      country: "",
      unitSystem: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      setActivityLoader(true);
      let data = {
        name: values.firstName + " " + values.lastName,
        email: values.email,
        password: values.password,
        gender: values.gender,
        country: values.country,
        unitSystem: values.unitSystem,
      };
      let formData = new FormData();
      formData.append("profilePic", trainerImage);
      formData.append("name", values.firstName + " " + values.lastName);
      formData.append("email", values.email);
      formData.append("password", values.password);
      formData.append("gender", values.gender);
      formData.append("country", values.country);
      formData.append("unitSystem", values.unitSystem);

      if (id) {
        formData.append("adminUUID", id);
      }

      if (affiliation) {
        formData.append("gymAffliation", affiliation);
      }
      if (affiliateCoachID) {
        formData.append("affiliateCoachID", affiliateCoachID);
      }

      onRegister({ trainerInfo: formData });
    },
  });
  // display signup form
  const renderForm = () => {
    return (
      <div className="main-div">
        <div className="form-container">
          <div className="welcome-cont">
            <img src={Logo} alt="" className="logo-image" />
            <h3 className="welcome-msg">Welcome Trainer!</h3>
            <span
              className="font-DMSans text-font16 text-center self-center"
              style={{
                color: "#424242",
                fontWeight: 400,
              }}
            >
              Register here to get started
            </span>
          </div>

          <div
            style={{ gap: "16px" }}
            className=" flex flex-col items-center p-2 md:p-0"
          >
            {/* Basic info section  */}
            <section className="section-container">
              <div className="section-header">
                <div className="section-header-text">Basic Info</div>
              </div>
              <div className="section-body">
                <div className="flex items-center justify-center">
                  <div
                    style={{
                      backgroundColor: "#EEE",
                      borderRadius: "50%",
                      height: "120px",
                      width: "120px",
                      position: "relative",
                    }}
                    className="flex items-center justify-center"
                  >
                    <img
                      src={
                        trainerImage
                          ? URL.createObjectURL(trainerImage)
                          : DefaultImage
                      }
                      alt="defaultImage"
                      style={{
                        borderRadius: "50%",
                      }}
                    />

                    <div
                      style={{
                        display: "flex",
                        width: "36px",
                        height: "36px",
                        padding: "8px",
                        justifyContent: "center",
                        alignItems: "center",
                        flexShrink: 0,
                        borderRadius: "18px",
                        background: "#F2F2F2",
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                        cursor: "pointer",
                      }}
                    >
                      <img
                        src={PencilIcon}
                        alt="edit"
                        onClick={() => setOpenImagePicker(true)}
                        style={{
                          height: "100%",
                          width: "100%",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "flex-start",
                    gap: 16,
                  }}
                >
                  <InputComponent
                    label="First Name*"
                    placeholder="Mike"
                    type="text"
                    {...formik.getFieldProps("firstName")}
                    error={formik.touched.firstName && formik.errors.firstName}
                    errorMessage={formik.errors.firstName}
                  />

                  <InputComponent
                    label="Last Name*"
                    placeholder="Tyson"
                    type="text"
                    {...formik.getFieldProps("lastName")}
                    error={formik.touched.lastName && formik.errors.lastName}
                    errorMessage={formik.errors.lastName}
                  />
                </div>
              </div>
            </section>

            {/* Account info section  */}
            <section className="section-container">
              <div className="section-header">
                <div className="section-header-text">Account Info</div>
              </div>
              <div
                style={{
                  width: "100%",
                }}
                className="section-body"
              >
                {!queryParamEmail && (
                  <InputComponent
                    label="Email Address*"
                    placeholder="mike@spur.fit"
                    type="email"
                    containerStyle={{
                      width: "100%",
                    }}
                    {...formik.getFieldProps("email")}
                    error={formik.touched.email && formik.errors.email}
                    errorMessage={formik.errors.email}
                  />
                )}
                {queryParamEmail && (
                  <InputComponent
                    label="Email Address*"
                    placeholder="mike@spur.fit"
                    type="email"
                    containerStyle={{
                      width: "100%",
                    }}
                    {...formik.getFieldProps("email")}
                    error={formik.touched.email && formik.errors.email}
                    errorMessage={formik.errors.email}
                    value={queryParamEmail}
                    disabled={true}
                  />
                )}

                <InputComponent
                  label="Password*"
                  placeholder="Password"
                  type={showPassword ? "text" : "password"}
                  containerStyle={{
                    width: "100%",
                  }}
                  {...formik.getFieldProps("password")}
                  error={formik.touched.password && formik.errors.password}
                  errorMessage={formik.errors.password}
                  rightIcon={
                    showPassword ? (
                      <VisibilityOutlinedIcon
                        onClick={() => setShowPassword(false)}
                      />
                    ) : (
                      <VisibilityOffOutlinedIcon
                        onClick={() => setShowPassword(true)}
                      />
                    )
                  }
                />

                <InputComponent
                  label="Confirm Password*"
                  placeholder="Confirm Password"
                  type={showConfirmPassword ? "text" : "password"}
                  containerStyle={{
                    width: "100%",
                  }}
                  {...formik.getFieldProps("confirmPassword")}
                  error={
                    formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                  }
                  errorMessage={formik.errors.confirmPassword}
                  rightIcon={
                    showConfirmPassword ? (
                      <VisibilityOutlinedIcon
                        onClick={() => setShowConfirmPassword(false)}
                      />
                    ) : (
                      <VisibilityOffOutlinedIcon
                        onClick={() => setShowConfirmPassword(true)}
                      />
                    )
                  }
                />
                {affiliation && (
                  <InputComponent
                    label={"Affiliation"}
                    placeholder={"Affiliation"}
                    value={affiliation}
                    type={"text"}
                    disabled={true}
                    containerStyle={{
                      width: "100%",
                    }}
                  />
                )}
              </div>
            </section>

            {/* Personal info section  */}
            <section className="section-container">
              <div className="section-header">
                <div className="section-header-text">Personal Info</div>
              </div>
              <div
                style={{
                  width: "100%",
                }}
                className="section-body"
              >
                <div
                  style={{
                    marginLeft: "12px",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      fontSize: 14,
                      color: "#424242",
                    }}
                    className="text-sm tracking-wide font-DMSans"
                  >
                    Gender*
                  </div>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    onChange={formik.handleChange}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                    name="gender"
                  >
                    <FormControlLabel
                      value="male"
                      control={<Radio color="royalBlue" />}
                      label="Male"
                    />
                    <FormControlLabel
                      value="female"
                      control={<Radio color="royalBlue" />}
                      label="Female"
                    />
                    <FormControlLabel
                      value="other"
                      control={<Radio color="royalBlue" />}
                      label="Other"
                    />
                  </RadioGroup>
                  <ShowError
                    error={formik.touched.gender && formik.errors.gender}
                    errorMessage={formik.errors.gender}
                  />
                </div>

                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      fontSize: 14,
                      marginLeft: "12px",
                      color: "#424242",
                    }}
                    className="text-sm tracking-wide font-DMSans"
                  >
                    Country*
                  </div>
                  {/* <Select
                      sx={{
                        height: "41px",
                        mt: "8px",
                        fontSize: "16px",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#ebebeb",
                          },
                        },
                        "&.MuiSelect-outlined": {
                          border: "1.5px solid #ebebeb",
                        },

                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: "1.5px solid #4339f2",
                          boxShadow: "0px 0px 0px 3px rgba(67, 57, 242, 0.25)",
                        },
                        borderRadius: "12px",
                        fontFamily: "DM Sans",
                      }}
                      variant="outlined"
                      onChange={formik.handleChange}
                      value={formik.values.country || "select"}
                      name="country"
                    >
                      <MenuItem
                        sx={{
                          fontFamily: "DM Sans",
                          fontSize: "14px",
                        }}
                        disabled
                        value={"select"}
                      >
                        Select Country
                      </MenuItem>
                      {countryCodes.map((country) => (
                        <MenuItem
                          sx={{
                            fontFamily: "DM Sans",
                            fontSize: "14px",
                          }}
                          value={country.code}
                        >
                          {country.country}
                        </MenuItem>
                      ))}
                    </Select> */}
                  <Autocomplete
                    id="combo-box-demo"
                    options={countryCodes}
                    getOptionLabel={(option) => option.country}
                    clearIcon={null}
                    sx={{
                      height: "41px",
                      backgroundColor: "#fff",
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "1.5px solid #4339f2",
                        boxShadow: "0px 0px 0px 3px rgba(67, 57, 242, 0.25)",
                      },
                      borderRadius: "12px",
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          sx={{
                            height: "100%",
                            borderRadius: "12px",
                            fontSize: "16px",
                            color: "#000",
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "#ebebeb",
                                borderRadius: "12px",
                              },
                            },

                            "&.MuiSelect-outlined": {
                              border: "1.5px solid #ebebeb",
                            },
                            fontFamily: "DM Sans",
                          }}
                          placeholder="Select Country"
                          size="small"
                        />
                      );
                    }}
                    name="country"
                    value={countryCodes.find(
                      (country) => country.code === formik.values.country
                    )}
                    onChange={(e, value) => {
                      formik.setFieldValue("country", value?.code);
                    }}
                  />

                  <ShowError
                    error={formik.touched.country && formik.errors.country}
                    errorMessage={formik.errors.country}
                  />
                </div>
              </div>
            </section>

            {/* Units for weight Selecting section */}
            <section className="section-container">
              <div className="section-header">
                <div className="section-header-text">Unit System</div>
              </div>
              <div
                style={{
                  width: "100%",
                }}
                className="section-body"
              >
                <div
                  style={{
                    marginLeft: "12px",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      fontSize: 14,
                      color: "#424242",
                    }}
                    className="text-sm tracking-wide font-DMSans"
                  >
                    Units*
                  </div>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    onChange={formik.handleChange}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                    }}
                    name="unitSystem"
                  >
                    <FormControlLabel
                      value="KGS"
                      control={<Radio color="royalBlue" />}
                      label="Kilograms (KGS)"
                      className="unit-system kgs"
                    />
                    <FormControlLabel
                      value="LBS"
                      control={<Radio color="royalBlue" />}
                      label="Pounds (LBS)"
                    />
                  </RadioGroup>
                  <ShowError
                    error={
                      formik.touched.unitSystem && formik.errors.unitSystem
                    }
                    errorMessage={formik.errors.unitSystem}
                  />
                </div>
              </div>
            </section>
            <div
              style={{
                width: "100%",
                zIndex: 1,
              }}
            >
              <button
                style={{
                  width: "100%",
                }}
                className="button-new "
                onClick={formik.handleSubmit}
              >
                {activityLoaderOn ? (
                  <SpinnerComponent />
                ) : (
                  <span
                    style={{
                      fontSize: 14,
                    }}
                  >
                    {"Register"}
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
        <div className="review-container">
          <div className="main-cont">
            <div
              style={{
                width: "100%",
                padding: "12px 50px",
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <div
                className="left-div"
                style={{
                  width: "30%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                  marginRight: "40px",
                }}
              >
                <div
                  style={{
                    width: "230px",
                    height: "230px",
                    transform: "rotate(0.02turn)",
                    background: "royalblue",
                    borderRadius: "4px",
                  }}
                ></div>
                <div
                  style={{
                    width: "230px",
                    height: "230px",
                    background: "white",
                    borderRadius: "4px",
                    position: "absolute",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={Seyma}
                    alt="reviewer"
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "100%",
                      borderRadius: "4px",
                    }}
                  />
                </div>
              </div>
              <div
                className="right-div"
                style={{
                  width: "60%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  className="review-div"
                  style={{
                    marginBottom: "25px",
                  }}
                >
                  <p
                    style={{
                      fontSize: "19px",
                      fontWeight: "500",
                      fontStyle: "italic",
                      letterSpacing: "1px",
                    }}
                    className="font-DMSans"
                  >
                    {reviews[0]?.review}
                  </p>
                </div>
                <div
                  className="reviewer-info-div"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={reviewTrainerIcon}
                    style={{
                      width: "40px",
                      height: "40px",
                    }}
                  />
                  <div
                    className="detail-div"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      marginLeft: "15px",
                    }}
                  >
                    <p
                      style={{
                        color: "royalblue",
                        fontSize: "18px",
                        fontWeight: "600",
                        color: "white",
                      }}
                      className="font-DMSans"
                    >
                      {reviews[0]?.name}
                    </p>
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                      className="font-DMSans"
                    >
                      {reviews[0]?.designation}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                padding: "10px 10px",
                flexDirection: "column",
                marginBottom: "10px",
                marginTop: "-50px",
              }}
            >
              <div
                className="trainer-signup-feature-heading"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "0px 40px",
                }}
              >
                <h3
                  style={{
                    fontSize: "21px",
                    textAlign: "center",
                    fontWeight: "600",
                  }}
                  className="font-DMSans"
                >
                  AI-powered workouts & meals, client chat, easy payments:
                  <br />
                  Grow your fitness business 5x faster with Spur.Fit
                </h3>
              </div>

              <div
                className="trainer-signup-features"
                style={{
                  display: "grid",
                  gridTemplateColumns: "auto auto auto auto",
                  gap: "17px",
                  marginTop: "24px",
                }}
              >
                <div className="flex flex-col-reverse gap-2 items-center justify-center ">
                  <p className="font-DMSans text-font14">AI Co-Pilot</p>
                  <img
                    src={img1}
                    alt=""
                    style={{
                      width: "28px",
                      height: "28px",
                    }}
                  />
                </div>
                <div className="flex flex-col-reverse gap-2 items-center justify-center ">
                  <p className="font-DMSans text-font14">Lead Capture CRM</p>
                  <img
                    src={img2}
                    alt=""
                    style={{
                      width: "28px",
                      height: "28px",
                    }}
                  />
                </div>
                <div className="flex flex-col-reverse gap-2 items-center justify-center ">
                  <p className="font-DMSans text-font14">Trainer Roles*</p>
                  <img
                    src={img3}
                    alt=""
                    style={{
                      width: "28px",
                      height: "28px",
                    }}
                  />
                </div>
                <div className="flex flex-col-reverse gap-2 items-center justify-center ">
                  <p className="font-DMSans text-font14">In-App Messaging</p>
                  <img
                    src={img4}
                    alt=""
                    style={{
                      width: "28px",
                      height: "28px",
                    }}
                  />
                </div>
                <div className="flex flex-col-reverse gap-2 items-center justify-center ">
                  <p className="font-DMSans text-font14">Progress Tracking</p>
                  <img
                    src={img5}
                    alt=""
                    style={{
                      width: "28px",
                      height: "28px",
                    }}
                  />
                </div>
                <div className="flex flex-col-reverse gap-2 items-center justify-center ">
                  <p className="font-DMSans text-font14">Nutrition Coaching</p>
                  <img
                    src={img6}
                    alt=""
                    style={{
                      width: "28px",
                      height: "28px",
                    }}
                  />
                </div>
                <div className="flex flex-col-reverse gap-2 items-center justify-center ">
                  <p className="font-DMSans text-font14">
                    Video Exercise Library
                  </p>
                  <img
                    src={img7}
                    alt=""
                    style={{
                      width: "28px",
                      height: "28px",
                    }}
                  />
                </div>
                <div className="flex flex-col-reverse gap-2 items-center justify-center ">
                  <p className="font-DMSans text-font14">Workout Builder</p>
                  <img
                    src={img8}
                    alt=""
                    style={{
                      width: "28px",
                      height: "28px",
                    }}
                  />
                </div>
              </div>
              <div
                className="last-grid"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "8px",
                }}
              >
                <div className="flex flex-col-reverse gap-2 items-center justify-center mr-8">
                  <p className="font-DMSans text-font14">Automated Payments</p>
                  <img
                    src={img9}
                    alt=""
                    style={{
                      width: "28px",
                      height: "28px",
                    }}
                  />
                </div>
                <div className="flex flex-col-reverse gap-2 items-center justify-center ">
                  <p className="font-DMSans text-font14">Custom Branding*</p>
                  <img
                    src={img10}
                    alt=""
                    style={{
                      width: "28px",
                      height: "28px",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const dummy = () => {
    history.push("/", {
      origin: "trainerSignup",
    });
  };

  return (
    <>
      <ToastContainer />
      {/* <div
        className="lg:flex"
        style={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          padding: "48px 0px",
          height: "100%",
          position: "relative",
        }} */}
      {/* > */}
      {renderForm()}
      {/* <img
          className="hidden md:block"
          src={BlueEffect}
          style={{
            position: "absolute",
            opacity: 0.6,
            bottom: "50px",
            left: 52,
            zIndex: 0,
          }}
        />

        <img
          className="hidden md:block"
          src={VioletEffect}
          style={{
            position: "absolute",
            opacity: 0.6,
            top: "54px",
            right: -159,
            zIndex: 0,
          }}
        />

        <img
          className="hidden md:block"
          src={OrangeEffect}
          style={{
            position: "absolute",
            opacity: 0.6,
            bottom: "100px",
            right: -68,
            zIndex: 0,
          }}
        />

        <img
          className="hidden md:block"
          src={YellowEffect}
          style={{
            position: "absolute",
            opacity: 0.6,
            top: "150px",
            left: -159,
            zIndex: 0,
          }}
        /> */}
      {/* </div> */}
      {/* <button onClick={dummy}>Go to login</button> */}
      <ImageUploadWithCrop
        open={openImagePicker}
        setOpen={setOpenImagePicker}
        uploadProfilePic={handleProfilePic}
      />
    </>
  );
};

// export const TrainerSignupOne = () => {
//   const reviews = [
//     {
//       name: "Seyma",
//       review:
//         "Spur.Fit is a one-stop solution for me & my clients. Creating workouts, meal plans, communication, managing payments, custom branded app & is just so easy to use.",
//       designation: "Online fitness coach",
//     },
//   ];
//   const location = useLocation();
//   const queryParams = new URLSearchParams(location.search);
//   const queryParamEmail = queryParams.get("email");
//   console.log("query param email", queryParamEmail);
//   const history = useHistory();
//   const { id, affiliation, affiliateCoachID } = useParams();
//   const [activityLoaderOn, setActivityLoader] = useState(false);
//   const [screenWidth, setScreenWidth] = useState(window.innerWidth);
//   const [openImagePicker, setOpenImagePicker] = useState(false);
//   const [trainerImage, setTrainerImage] = useState(null);
//   const [showPassword, setShowPassword] = useState(false);
//   const [showConfirmPassword, setShowConfirmPassword] = useState(false);

//   const [trainerInfo, setTrainerInfo] = useState({
//     name: "",
//     email: "",
//     gender: "",
//   });

//   useEffect(() => {
//     console.log("use params feild", id, affiliation);
//     const handleResize = () => {
//       setScreenWidth(window.innerWidth);
//     };

//     mixpanel.init("147a208f5832549c876d94601401033e", {
//       //debug: true,
//       api_host: "https://api.mixpanel.com",
//       ignore_dnt: true,
//     });
//     if (
//       localStorage.getItem("isLoggedIn") !== null &&
//       JSON.parse(localStorage.getItem("isLoggedIn"))
//     ) {
//       let trainerDetails = JSON.parse(
//         localStorage.getItem("trainerCredentials")
//       );

//       mixpanel.identify(trainerDetails?.password);
//       mixpanel.people.set_once({
//         Name: trainerDetails?.name,
//         Email: trainerDetails?.email,
//       });
//       mixpanel.track("Trainer_Register");

//       // navigate('/home');
//       history.push("/home");
//     }

//     document.title = "Spur.fit - Signup";

//     window.addEventListener("resize", handleResize);

//     return () => {
//       window.removeEventListener("resize", handleResize);
//       document.title = "Spur.fit";
//     };
//   }, []);
//   const { displayAlert } = useContext(CustomAlertContext);

//   const onRegister = ({ trainerInfo }) => {
//     let url =
//       id || affiliateCoachID
//         ? `${APIURL}CF/api/CoachWeb/Create/trainerSignUpAdminRef`
//         : `${APIURL}CF/api/CoachWeb/Create/trainerSignUp`;
//     axios
//       .post(url, trainerInfo)
//       .then((res) => {
//         history.push("/", {
//           origin: "trainerSignup",
//         });
//         displayAlert({
//           message: "Registered Successfully! Please check your email.",
//           type: "success",
//         });
//         // uploadProfilePic(res.data.data.primaryKey);
//       })
//       .catch((err) => {
//         displayAlert({
//           message: "Something went wrong! Please try again later.",
//           type: "error",
//         });
//       })
//       .finally(() => {
//         setActivityLoader(false);
//       });
//   };

//   // upload profile pic
//   const handleProfilePic = async (profilePic) => {
//     setTrainerImage(profilePic);
//     setOpenImagePicker(false);
//   };

//   const uploadProfilePic = async (id) => {
//     if (!trainerImage || trainerImage === "") {
//       return;
//     }
//     const formData = new FormData();
//     formData.append("image", trainerImage);

//     await axios({
//       method: "post",
//       url: `${APIURL}api/uploadtrainerprofilepic?trainerUUID=${id}`,
//       data: formData,
//     })
//       .then(async (res) => {
//         // toast("Successfully Uploaded!", "success");
//         console.log("res", res);
//       })
//       .catch((err) => {
//         // toast("Incorrect Image dimension. Need to be square", "error");
//       });
//   };

//   const validationSchema = Yup.object().shape({
//     firstName: Yup.string().required("First Name is required"),
//     lastName: Yup.string().required("Last Name is required"),
//     email: Yup.string()
//       .email("Invalid email address")
//       .required("Email is required"),
//     password: Yup.string()
//       .required("Password is required")
//       .min(6, "Password must be at least 6 characters")
//       .max(25, "Password must be at most 16 characters"),
//     confirmPassword: Yup.string()
//       .oneOf([Yup.ref("password"), null], "Passwords must match")
//       .required("Confirm Password is required"),
//     gender: Yup.string().required("Gender is required"),
//     country: Yup.string().required("Country is required"),
//     unitSystem: Yup.string().required("Unit System is required"),
//   });

//   const formik = useFormik({
//     initialValues: {
//       firstName: "",
//       lastName: "",
//       email: queryParamEmail || "",
//       password: "",
//       confirmPassword: "",
//       gender: "",
//       country: "",
//       unitSystem: "",
//     },
//     validationSchema: validationSchema,
//     onSubmit: (values, { setSubmitting }) => {
//       setActivityLoader(true);
//       let data = {
//         name: values.firstName + " " + values.lastName,
//         email: values.email,
//         password: values.password,
//         gender: values.gender,
//         country: values.country,
//         unitSystem: values.unitSystem,
//       };
//       let formData = new FormData();
//       formData.append("profilePic", trainerImage);
//       formData.append("name", values.firstName + " " + values.lastName);
//       formData.append("email", values.email);
//       formData.append("password", values.password);
//       formData.append("gender", values.gender);
//       formData.append("country", values.country);
//       formData.append("unitSystem", values.unitSystem);

//       if (id) {
//         formData.append("adminUUID", id);
//       }

//       if (affiliation) {
//         formData.append("gymAffliation", affiliation);
//       }
//       if (affiliateCoachID) {
//         formData.append("affiliateCoachID", affiliateCoachID);
//       }

//       onRegister({ trainerInfo: formData });
//     },
//   });
//   // display signup form
//   const renderForm = () => {
//     return (
//       <div className="main-div">
//         <div className="form-container">
//           <div className="welcome-cont">
//             <img src={Logo} alt="" className="logo-image" />
//             <h3 className="welcome-msg">Welcome Trainer!</h3>
//             <span
//               className="font-DMSans text-font16 text-center self-center"
//               style={{
//                 color: "#424242",
//                 fontWeight: 400,
//               }}
//             >
//               Register here to get started
//             </span>
//           </div>

//           <div
//             style={{ gap: "16px" }}
//             className=" flex flex-col items-center p-2 md:p-0"
//           >
//             {/* Basic info section  */}
//             <section className="section-container">
//               <div className="section-header">
//                 <div className="section-header-text">Basic Info</div>
//               </div>
//               <div className="section-body">
//                 <div className="flex items-center justify-center">
//                   <div
//                     style={{
//                       backgroundColor: "#EEE",
//                       borderRadius: "50%",
//                       height: "120px",
//                       width: "120px",
//                       position: "relative",
//                     }}
//                     className="flex items-center justify-center"
//                   >
//                     <img
//                       src={
//                         trainerImage
//                           ? URL.createObjectURL(trainerImage)
//                           : DefaultImage
//                       }
//                       alt="defaultImage"
//                       style={{
//                         borderRadius: "50%",
//                       }}
//                     />

//                     <div
//                       style={{
//                         display: "flex",
//                         width: "36px",
//                         height: "36px",
//                         padding: "8px",
//                         justifyContent: "center",
//                         alignItems: "center",
//                         flexShrink: 0,
//                         borderRadius: "18px",
//                         background: "#F2F2F2",
//                         position: "absolute",
//                         bottom: 0,
//                         right: 0,
//                         cursor: "pointer",
//                       }}
//                     >
//                       <img
//                         src={PencilIcon}
//                         alt="edit"
//                         onClick={() => setOpenImagePicker(true)}
//                         style={{
//                           height: "100%",
//                           width: "100%",
//                         }}
//                       />
//                     </div>
//                   </div>
//                 </div>
//                 <div
//                   style={{
//                     display: "flex",
//                     justifyContent: "space-between",
//                     width: "100%",
//                     alignItems: "flex-start",
//                     gap: 16,
//                   }}
//                 >
//                   <InputComponent
//                     label="First Name"
//                     placeholder="Mike"
//                     type="text"
//                     {...formik.getFieldProps("firstName")}
//                     error={formik.touched.firstName && formik.errors.firstName}
//                     errorMessage={formik.errors.firstName}
//                   />

//                   <InputComponent
//                     label="Last Name"
//                     placeholder="Tyson"
//                     type="text"
//                     {...formik.getFieldProps("lastName")}
//                     error={formik.touched.lastName && formik.errors.lastName}
//                     errorMessage={formik.errors.lastName}
//                   />
//                 </div>
//               </div>
//             </section>

//             {/* Account info section  */}
//             <section className="section-container">
//               <div className="section-header">
//                 <div className="section-header-text">Account Info</div>
//               </div>
//               <div
//                 style={{
//                   width: "100%",
//                 }}
//                 className="section-body"
//               >
//                 {!queryParamEmail && (
//                   <InputComponent
//                     label="Email Address"
//                     placeholder="mike@spur.fit"
//                     type="email"
//                     containerStyle={{
//                       width: "100%",
//                     }}
//                     {...formik.getFieldProps("email")}
//                     error={formik.touched.email && formik.errors.email}
//                     errorMessage={formik.errors.email}
//                   />
//                 )}
//                 {queryParamEmail && (
//                   <InputComponent
//                     label="Email Address"
//                     placeholder="mike@spur.fit"
//                     type="email"
//                     containerStyle={{
//                       width: "100%",
//                     }}
//                     {...formik.getFieldProps("email")}
//                     error={formik.touched.email && formik.errors.email}
//                     errorMessage={formik.errors.email}
//                     value={queryParamEmail}
//                     disabled={true}
//                   />
//                 )}

//                 <InputComponent
//                   label="Password"
//                   placeholder="Password"
//                   type={showPassword ? "text" : "password"}
//                   containerStyle={{
//                     width: "100%",
//                   }}
//                   {...formik.getFieldProps("password")}
//                   error={formik.touched.password && formik.errors.password}
//                   errorMessage={formik.errors.password}
//                   rightIcon={
//                     showPassword ? (
//                       <VisibilityOutlinedIcon
//                         onClick={() => setShowPassword(false)}
//                       />
//                     ) : (
//                       <VisibilityOffOutlinedIcon
//                         onClick={() => setShowPassword(true)}
//                       />
//                     )
//                   }
//                 />

//                 <InputComponent
//                   label="Confirm Password"
//                   placeholder="Confirm Password"
//                   type={showConfirmPassword ? "text" : "password"}
//                   containerStyle={{
//                     width: "100%",
//                   }}
//                   {...formik.getFieldProps("confirmPassword")}
//                   error={
//                     formik.touched.confirmPassword &&
//                     formik.errors.confirmPassword
//                   }
//                   errorMessage={formik.errors.confirmPassword}
//                   rightIcon={
//                     showConfirmPassword ? (
//                       <VisibilityOutlinedIcon
//                         onClick={() => setShowConfirmPassword(false)}
//                       />
//                     ) : (
//                       <VisibilityOffOutlinedIcon
//                         onClick={() => setShowConfirmPassword(true)}
//                       />
//                     )
//                   }
//                 />
//                 {affiliation && (
//                   <InputComponent
//                     label={"Affiliation"}
//                     placeholder={"Affiliation"}
//                     value={affiliation}
//                     type={"text"}
//                     disabled={true}
//                     containerStyle={{
//                       width: "100%",
//                     }}
//                   />
//                 )}
//               </div>
//             </section>

//             {/* Personal info section  */}
//             <section className="section-container">
//               <div className="section-header">
//                 <div className="section-header-text">Personal Info</div>
//               </div>
//               <div
//                 style={{
//                   width: "100%",
//                 }}
//                 className="section-body"
//               >
//                 <div
//                   style={{
//                     marginLeft: "12px",
//                     width: "100%",
//                   }}
//                 >
//                   <div
//                     style={{
//                       fontSize: 14,
//                       color: "#424242",
//                     }}
//                     className="text-sm tracking-wide font-DMSans"
//                   >
//                     Gender
//                   </div>
//                   <RadioGroup
//                     row
//                     aria-labelledby="demo-row-radio-buttons-group-label"
//                     onChange={formik.handleChange}
//                     sx={{
//                       display: "flex",
//                       flexDirection: "row",
//                       justifyContent: "space-between",
//                     }}
//                     name="gender"
//                   >
//                     <FormControlLabel
//                       value="male"
//                       control={<Radio color="royalBlue" />}
//                       label="Male"
//                     />
//                     <FormControlLabel
//                       value="female"
//                       control={<Radio color="royalBlue" />}
//                       label="Female"
//                     />
//                     <FormControlLabel
//                       value="other"
//                       control={<Radio color="royalBlue" />}
//                       label="Other"
//                     />
//                   </RadioGroup>
//                   <ShowError
//                     error={formik.touched.gender && formik.errors.gender}
//                     errorMessage={formik.errors.gender}
//                   />
//                 </div>

//                 <div
//                   style={{
//                     width: "100%",
//                   }}
//                 >
//                   <div
//                     style={{
//                       fontSize: 14,
//                       marginLeft: "12px",
//                       color: "#424242",
//                     }}
//                     className="text-sm tracking-wide font-DMSans"
//                   >
//                     Country
//                   </div>
//                   {/* <Select
//                       sx={{
//                         height: "41px",
//                         mt: "8px",
//                         fontSize: "16px",
//                         "& .MuiOutlinedInput-root": {
//                           "& fieldset": {
//                             borderColor: "#ebebeb",
//                           },
//                         },
//                         "&.MuiSelect-outlined": {
//                           border: "1.5px solid #ebebeb",
//                         },

//                         "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
//                           border: "1.5px solid #4339f2",
//                           boxShadow: "0px 0px 0px 3px rgba(67, 57, 242, 0.25)",
//                         },
//                         borderRadius: "12px",
//                         fontFamily: "DM Sans",
//                       }}
//                       variant="outlined"
//                       onChange={formik.handleChange}
//                       value={formik.values.country || "select"}
//                       name="country"
//                     >
//                       <MenuItem
//                         sx={{
//                           fontFamily: "DM Sans",
//                           fontSize: "14px",
//                         }}
//                         disabled
//                         value={"select"}
//                       >
//                         Select Country
//                       </MenuItem>
//                       {countryCodes.map((country) => (
//                         <MenuItem
//                           sx={{
//                             fontFamily: "DM Sans",
//                             fontSize: "14px",
//                           }}
//                           value={country.code}
//                         >
//                           {country.country}
//                         </MenuItem>
//                       ))}
//                     </Select> */}
//                   <Autocomplete
//                     id="combo-box-demo"
//                     options={countryCodes}
//                     getOptionLabel={(option) => option.country}
//                     clearIcon={null}
//                     sx={{
//                       height: "41px",
//                       backgroundColor: "#fff",
//                       "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
//                         border: "1.5px solid #4339f2",
//                         boxShadow: "0px 0px 0px 3px rgba(67, 57, 242, 0.25)",
//                       },
//                       borderRadius: "12px",
//                     }}
//                     renderInput={(params) => {
//                       return (
//                         <TextField
//                           {...params}
//                           sx={{
//                             height: "100%",
//                             borderRadius: "12px",
//                             fontSize: "16px",
//                             color: "#000",
//                             "& .MuiOutlinedInput-root": {
//                               "& fieldset": {
//                                 borderColor: "#ebebeb",
//                                 borderRadius: "12px",
//                               },
//                             },

//                             "&.MuiSelect-outlined": {
//                               border: "1.5px solid #ebebeb",
//                             },
//                             fontFamily: "DM Sans",
//                           }}
//                           placeholder="Select Country"
//                           size="small"
//                         />
//                       );
//                     }}
//                     name="country"
//                     value={countryCodes.find(
//                       (country) => country.code === formik.values.country
//                     )}
//                     onChange={(e, value) => {
//                       formik.setFieldValue("country", value?.code);
//                     }}
//                   />

//                   <ShowError
//                     error={formik.touched.country && formik.errors.country}
//                     errorMessage={formik.errors.country}
//                   />
//                 </div>
//               </div>
//             </section>

//             {/* Units for weight Selecting section */}
//             <section className="section-container">
//               <div className="section-header">
//                 <div className="section-header-text">Unit System</div>
//               </div>
//               <div
//                 style={{
//                   width: "100%",
//                 }}
//                 className="section-body"
//               >
//                 <div
//                   style={{
//                     marginLeft: "12px",
//                     width: "100%",
//                   }}
//                 >
//                   <div
//                     style={{
//                       fontSize: 14,
//                       color: "#424242",
//                     }}
//                     className="text-sm tracking-wide font-DMSans"
//                   >
//                     Units
//                   </div>
//                   <RadioGroup
//                     row
//                     aria-labelledby="demo-row-radio-buttons-group-label"
//                     onChange={formik.handleChange}
//                     sx={{
//                       display: "flex",
//                       flexDirection: "row",
//                       justifyContent: "flex-start",
//                     }}
//                     name="unitSystem"
//                   >
//                     <FormControlLabel
//                       value="KGS"
//                       control={<Radio color="royalBlue" />}
//                       label="Kilograms (KGS)"
//                       className="unit-system kgs"
//                     />
//                     <FormControlLabel
//                       value="LBS"
//                       control={<Radio color="royalBlue" />}
//                       label="Pounds (LBS)"
//                     />
//                   </RadioGroup>
//                   <ShowError
//                     error={
//                       formik.touched.unitSystem && formik.errors.unitSystem
//                     }
//                     errorMessage={formik.errors.unitSystem}
//                   />
//                 </div>
//               </div>
//             </section>
//             <div
//               style={{
//                 width: "100%",
//                 zIndex: 1,
//               }}
//             >
//               <button
//                 style={{
//                   width: "100%",
//                 }}
//                 className="button-new "
//                 onClick={formik.handleSubmit}
//               >
//                 {activityLoaderOn ? (
//                   <SpinnerComponent />
//                 ) : (
//                   <span
//                     style={{
//                       fontSize: 14,
//                     }}
//                   >
//                     {"Register"}
//                   </span>
//                 )}
//               </button>
//             </div>
//           </div>
//         </div>
//         <div className="review-container">
//           <div className="main-cont">
//             <div
//               className="headline"
//               style={{
//                 width: "100%",
//                 padding: "10px 40px",
//               }}
//             >
//               <h1
//                 style={{
//                   fontSize: "26px",
//                   textAlign: "center",
//                   fontWeight: "700",
//                 }}
//                 className="font-DMSans"
//               >
//                 Coach Seyma saw 10x increase in her revenue while still keeping
//                 every client happy and motivated
//               </h1>
//             </div>
//             <div
//               style={{
//                 width: "100%",
//                 padding: "12px 30px",
//                 display: "flex",
//                 justifyContent: "center",
//                 alignItems: "center",
//                 marginTop: "-50px",
//               }}
//             >
//               <div
//                 className="left-div"
//                 style={{
//                   width: "30%",
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "center",
//                   alignItems: "center",
//                   position: "relative",
//                   marginRight: "30px",
//                 }}
//               >
//                 <div
//                   style={{
//                     width: "220px",
//                     height: "220px",
//                     transform: "rotate(0.02turn)",
//                     background: "royalblue",
//                     borderRadius: "4px",
//                   }}
//                 ></div>
//                 <div
//                   style={{
//                     width: "220px",
//                     height: "220px",
//                     background: "white",
//                     borderRadius: "4px",
//                     position: "absolute",
//                     overflow: "hidden",
//                   }}
//                 >
//                   <img
//                     src={Seyma}
//                     alt="reviewer"
//                     style={{
//                       objectFit: "cover",
//                       width: "100%",
//                       height: "100%",
//                       borderRadius: "4px",
//                     }}
//                   />
//                 </div>
//               </div>
//               <div
//                 className="right-div"
//                 style={{
//                   width: "60%",
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "flex-start",
//                 }}
//               >
//                 <div
//                   className="review-div"
//                   style={{
//                     marginBottom: "15px",
//                   }}
//                 >
//                   <p
//                     style={{
//                       fontSize: "20px",
//                       fontWeight: "500",
//                       fontStyle: "italic",
//                     }}
//                     className="font-DMSans"
//                   >
//                     {reviews[0]?.review}
//                   </p>
//                 </div>
//                 <div
//                   className="reviewer-info-div"
//                   style={{
//                     display: "flex",
//                     justifyContent: "flex-start",
//                     alignItems: "center",
//                   }}
//                 >
//                   <img
//                     src={reviewTrainerIcon}
//                     style={{
//                       width: "40px",
//                       height: "40px",
//                     }}
//                   />
//                   <div
//                     className="detail-div"
//                     style={{
//                       display: "flex",
//                       flexDirection: "column",
//                       justifyContent: "flex-start",
//                       marginLeft: "15px",
//                     }}
//                   >
//                     <p
//                       style={{
//                         color: "royalblue",
//                         fontSize: "18px",
//                         fontWeight: "600",
//                         color: "white",
//                       }}
//                       className="font-DMSans"
//                     >
//                       {reviews[0]?.name}
//                     </p>
//                     <p
//                       style={{
//                         fontSize: "14px",
//                         fontWeight: "500",
//                       }}
//                       className="font-DMSans"
//                     >
//                       {reviews[0]?.designation}
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "center",
//                 alignItems: "center",
//                 width: "100%",
//                 padding: "10px 30px",
//                 flexDirection: "column",
//               }}
//             >
//               <div
//                 className="trainer-signup-feature-heading"
//                 style={{
//                   display: "flex",
//                   justifyContent: "center",
//                   alignItems: "center",
//                   padding: "0px 40px",
//                   marginTop: "-50px",
//                 }}
//               >
//                 <h3
//                   style={{
//                     fontSize: "22px",
//                     textAlign: "center",
//                     fontWeight: "600",
//                   }}
//                   className="font-DMSans"
//                 >
//                   AI-powered workouts & meals, client chat, easy payments: Grow
//                   your fitness business 5x faster with Spur.Fit t
//                 </h3>
//               </div>

//               {/* <div
//                 className="trainer-signup-features"
//                 style={{
//                   display: "grid",
//                   gridTemplateColumns: "auto auto auto auto",
//                   gap: "12px",
//                   marginTop: "20px",
//                 }}
//               >
//                 <div className="flex flex-col-reverse gap-2 items-center justify-center ">
//                   <p className="font-DMSans text-font14">AI Co-Pilot</p>
//                   <img
//                     src={img1}
//                     alt=""
//                     style={{
//                       width: "24px",
//                       height: "24px",
//                     }}
//                   />
//                 </div>
//                 <div className="flex flex-col-reverse gap-2 items-center justify-center ">
//                   <p className="font-DMSans text-font14">Lead Capture CRM</p>
//                   <img
//                     src={img2}
//                     alt=""
//                     style={{
//                       width: "24px",
//                       height: "24px",
//                     }}
//                   />
//                 </div>
//                 <div className="flex flex-col-reverse gap-2 items-center justify-center ">
//                   <p className="font-DMSans text-font14">Trainer Roles*</p>
//                   <img
//                     src={img3}
//                     alt=""
//                     style={{
//                       width: "24px",
//                       height: "24px",
//                     }}
//                   />
//                 </div>
//                 <div className="flex flex-col-reverse gap-2 items-center justify-center ">
//                   <p className="font-DMSans text-font14">In-App Messaging</p>
//                   <img
//                     src={img4}
//                     alt=""
//                     style={{
//                       width: "24px",
//                       height: "24px",
//                     }}
//                   />
//                 </div>
//                 <div className="flex flex-col-reverse gap-2 items-center justify-center ">
//                   <p className="font-DMSans text-font14">Progress Tracking</p>
//                   <img
//                     src={img5}
//                     alt=""
//                     style={{
//                       width: "24px",
//                       height: "24px",
//                     }}
//                   />
//                 </div>
//                 <div className="flex flex-col-reverse gap-2 items-center justify-center ">
//                   <p className="font-DMSans text-font14">Nutrition Coaching</p>
//                   <img
//                     src={img6}
//                     alt=""
//                     style={{
//                       width: "24px",
//                       height: "24px",
//                     }}
//                   />
//                 </div>
//                 <div className="flex flex-col-reverse gap-2 items-center justify-center ">
//                   <p className="font-DMSans text-font14">
//                     Video Exercise Library
//                   </p>
//                   <img
//                     src={img7}
//                     alt=""
//                     style={{
//                       width: "24px",
//                       height: "24px",
//                     }}
//                   />
//                 </div>
//                 <div className="flex flex-col-reverse gap-2 items-center justify-center ">
//                   <p className="font-DMSans text-font14">Workout Builder</p>
//                   <img
//                     src={img8}
//                     alt=""
//                     style={{
//                       width: "24px",
//                       height: "24px",
//                     }}
//                   />
//                 </div>
//                 <div className="flex flex-col-reverse gap-2 items-center justify-center ">
//                   <p className="font-DMSans text-font14">Automated Payments</p>
//                   <img
//                     src={img9}
//                     alt=""
//                     style={{
//                       width: "24px",
//                       height: "24px",
//                     }}
//                   />
//                 </div>
//                 <div className="flex flex-col-reverse gap-2 items-center justify-center ">
//                   <p className="font-DMSans text-font14">Custom Branding*</p>
//                   <img
//                     src={img10}
//                     alt=""
//                     style={{
//                       width: "24px",
//                       height: "24px",
//                     }}
//                   />
//                 </div>
//               </div> */}
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   };

//   const dummy = () => {
//     history.push("/", {
//       origin: "trainerSignup",
//     });
//   };

//   return (
//     <>
//       <ToastContainer />
//       {/* <div
//         className="lg:flex"
//         style={{
//           flex: 1,
//           display: "flex",
//           alignItems: "center",
//           padding: "48px 0px",
//           height: "100%",
//           position: "relative",
//         }} */}
//       {/* > */}
//       {renderForm()}
//       {/* <img
//           className="hidden md:block"
//           src={BlueEffect}
//           style={{
//             position: "absolute",
//             opacity: 0.6,
//             bottom: "50px",
//             left: 52,
//             zIndex: 0,
//           }}
//         />

//         <img
//           className="hidden md:block"
//           src={VioletEffect}
//           style={{
//             position: "absolute",
//             opacity: 0.6,
//             top: "54px",
//             right: -159,
//             zIndex: 0,
//           }}
//         />

//         <img
//           className="hidden md:block"
//           src={OrangeEffect}
//           style={{
//             position: "absolute",
//             opacity: 0.6,
//             bottom: "100px",
//             right: -68,
//             zIndex: 0,
//           }}
//         />

//         <img
//           className="hidden md:block"
//           src={YellowEffect}
//           style={{
//             position: "absolute",
//             opacity: 0.6,
//             top: "150px",
//             left: -159,
//             zIndex: 0,
//           }}
//         /> */}
//       {/* </div> */}
//       {/* <button onClick={dummy}>Go to login</button> */}
//       <ImageUploadWithCrop
//         open={openImagePicker}
//         setOpen={setOpenImagePicker}
//         uploadProfilePic={handleProfilePic}
//       />
//     </>
//   );
// };
// export const TrainerSignupTwo = () => {
//   const reviews = [
//     {
//       name: "Seyma",
//       review:
//         "Spur.Fit is a one-stop solution for me & my clients. Creating workouts, meal plans, communication, managing payments, custom branded app & is just so easy to use.",
//       designation: "Online fitness coach",
//     },
//   ];
//   const location = useLocation();
//   const queryParams = new URLSearchParams(location.search);
//   const queryParamEmail = queryParams.get("email");
//   console.log("query param email", queryParamEmail);
//   const history = useHistory();
//   const { id, affiliation, affiliateCoachID } = useParams();
//   const [activityLoaderOn, setActivityLoader] = useState(false);
//   const [screenWidth, setScreenWidth] = useState(window.innerWidth);
//   const [openImagePicker, setOpenImagePicker] = useState(false);
//   const [trainerImage, setTrainerImage] = useState(null);
//   const [showPassword, setShowPassword] = useState(false);
//   const [showConfirmPassword, setShowConfirmPassword] = useState(false);

//   const [trainerInfo, setTrainerInfo] = useState({
//     name: "",
//     email: "",
//     gender: "",
//   });

//   useEffect(() => {
//     console.log("use params feild", id, affiliation);
//     const handleResize = () => {
//       setScreenWidth(window.innerWidth);
//     };

//     mixpanel.init("147a208f5832549c876d94601401033e", {
//       //debug: true,
//       api_host: "https://api.mixpanel.com",
//       ignore_dnt: true,
//     });
//     if (
//       localStorage.getItem("isLoggedIn") !== null &&
//       JSON.parse(localStorage.getItem("isLoggedIn"))
//     ) {
//       let trainerDetails = JSON.parse(
//         localStorage.getItem("trainerCredentials")
//       );

//       mixpanel.identify(trainerDetails?.password);
//       mixpanel.people.set_once({
//         Name: trainerDetails?.name,
//         Email: trainerDetails?.email,
//       });
//       mixpanel.track("Trainer_Register");

//       // navigate('/home');
//       history.push("/home");
//     }

//     document.title = "Spur.fit - Signup";

//     window.addEventListener("resize", handleResize);

//     return () => {
//       window.removeEventListener("resize", handleResize);
//       document.title = "Spur.fit";
//     };
//   }, []);
//   const { displayAlert } = useContext(CustomAlertContext);

//   const onRegister = ({ trainerInfo }) => {
//     let url =
//       id || affiliateCoachID
//         ? `${APIURL}CF/api/CoachWeb/Create/trainerSignUpAdminRef`
//         : `${APIURL}CF/api/CoachWeb/Create/trainerSignUp`;
//     axios
//       .post(url, trainerInfo)
//       .then((res) => {
//         history.push("/", {
//           origin: "trainerSignup",
//         });
//         displayAlert({
//           message: "Registered Successfully! Please check your email.",
//           type: "success",
//         });
//         // uploadProfilePic(res.data.data.primaryKey);
//       })
//       .catch((err) => {
//         displayAlert({
//           message: "Something went wrong! Please try again later.",
//           type: "error",
//         });
//       })
//       .finally(() => {
//         setActivityLoader(false);
//       });
//   };

//   // upload profile pic
//   const handleProfilePic = async (profilePic) => {
//     setTrainerImage(profilePic);
//     setOpenImagePicker(false);
//   };

//   const uploadProfilePic = async (id) => {
//     if (!trainerImage || trainerImage === "") {
//       return;
//     }
//     const formData = new FormData();
//     formData.append("image", trainerImage);

//     await axios({
//       method: "post",
//       url: `${APIURL}api/uploadtrainerprofilepic?trainerUUID=${id}`,
//       data: formData,
//     })
//       .then(async (res) => {
//         // toast("Successfully Uploaded!", "success");
//         console.log("res", res);
//       })
//       .catch((err) => {
//         // toast("Incorrect Image dimension. Need to be square", "error");
//       });
//   };

//   const validationSchema = Yup.object().shape({
//     firstName: Yup.string().required("First Name is required"),
//     lastName: Yup.string().required("Last Name is required"),
//     email: Yup.string()
//       .email("Invalid email address")
//       .required("Email is required"),
//     password: Yup.string()
//       .required("Password is required")
//       .min(6, "Password must be at least 6 characters")
//       .max(25, "Password must be at most 16 characters"),
//     confirmPassword: Yup.string()
//       .oneOf([Yup.ref("password"), null], "Passwords must match")
//       .required("Confirm Password is required"),
//     gender: Yup.string().required("Gender is required"),
//     country: Yup.string().required("Country is required"),
//     unitSystem: Yup.string().required("Unit System is required"),
//   });

//   const formik = useFormik({
//     initialValues: {
//       firstName: "",
//       lastName: "",
//       email: queryParamEmail || "",
//       password: "",
//       confirmPassword: "",
//       gender: "",
//       country: "",
//       unitSystem: "",
//     },
//     validationSchema: validationSchema,
//     onSubmit: (values, { setSubmitting }) => {
//       setActivityLoader(true);
//       let data = {
//         name: values.firstName + " " + values.lastName,
//         email: values.email,
//         password: values.password,
//         gender: values.gender,
//         country: values.country,
//         unitSystem: values.unitSystem,
//       };
//       let formData = new FormData();
//       formData.append("profilePic", trainerImage);
//       formData.append("name", values.firstName + " " + values.lastName);
//       formData.append("email", values.email);
//       formData.append("password", values.password);
//       formData.append("gender", values.gender);
//       formData.append("country", values.country);
//       formData.append("unitSystem", values.unitSystem);

//       if (id) {
//         formData.append("adminUUID", id);
//       }

//       if (affiliation) {
//         formData.append("gymAffliation", affiliation);
//       }
//       if (affiliateCoachID) {
//         formData.append("affiliateCoachID", affiliateCoachID);
//       }

//       onRegister({ trainerInfo: formData });
//     },
//   });
//   // display signup form
//   const renderForm = () => {
//     return (
//       <div className="main-div">
//         <div className="form-container">
//           <div className="welcome-cont">
//             <img src={Logo} alt="" className="logo-image" />
//             <h3 className="welcome-msg">Welcome Trainer!</h3>
//             <span
//               className="font-DMSans text-font16 text-center self-center"
//               style={{
//                 color: "#424242",
//                 fontWeight: 400,
//               }}
//             >
//               Register here to get started
//             </span>
//           </div>

//           <div
//             style={{ gap: "16px" }}
//             className=" flex flex-col items-center p-2 md:p-0"
//           >
//             {/* Basic info section  */}
//             <section className="section-container">
//               <div className="section-header">
//                 <div className="section-header-text">Basic Info</div>
//               </div>
//               <div className="section-body">
//                 <div className="flex items-center justify-center">
//                   <div
//                     style={{
//                       backgroundColor: "#EEE",
//                       borderRadius: "50%",
//                       height: "120px",
//                       width: "120px",
//                       position: "relative",
//                     }}
//                     className="flex items-center justify-center"
//                   >
//                     <img
//                       src={
//                         trainerImage
//                           ? URL.createObjectURL(trainerImage)
//                           : DefaultImage
//                       }
//                       alt="defaultImage"
//                       style={{
//                         borderRadius: "50%",
//                       }}
//                     />

//                     <div
//                       style={{
//                         display: "flex",
//                         width: "36px",
//                         height: "36px",
//                         padding: "8px",
//                         justifyContent: "center",
//                         alignItems: "center",
//                         flexShrink: 0,
//                         borderRadius: "18px",
//                         background: "#F2F2F2",
//                         position: "absolute",
//                         bottom: 0,
//                         right: 0,
//                         cursor: "pointer",
//                       }}
//                     >
//                       <img
//                         src={PencilIcon}
//                         alt="edit"
//                         onClick={() => setOpenImagePicker(true)}
//                         style={{
//                           height: "100%",
//                           width: "100%",
//                         }}
//                       />
//                     </div>
//                   </div>
//                 </div>
//                 <div
//                   style={{
//                     display: "flex",
//                     justifyContent: "space-between",
//                     width: "100%",
//                     alignItems: "flex-start",
//                     gap: 16,
//                   }}
//                 >
//                   <InputComponent
//                     label="First Name"
//                     placeholder="Mike"
//                     type="text"
//                     {...formik.getFieldProps("firstName")}
//                     error={formik.touched.firstName && formik.errors.firstName}
//                     errorMessage={formik.errors.firstName}
//                   />

//                   <InputComponent
//                     label="Last Name"
//                     placeholder="Tyson"
//                     type="text"
//                     {...formik.getFieldProps("lastName")}
//                     error={formik.touched.lastName && formik.errors.lastName}
//                     errorMessage={formik.errors.lastName}
//                   />
//                 </div>
//               </div>
//             </section>

//             {/* Account info section  */}
//             <section className="section-container">
//               <div className="section-header">
//                 <div className="section-header-text">Account Info</div>
//               </div>
//               <div
//                 style={{
//                   width: "100%",
//                 }}
//                 className="section-body"
//               >
//                 {!queryParamEmail && (
//                   <InputComponent
//                     label="Email Address"
//                     placeholder="mike@spur.fit"
//                     type="email"
//                     containerStyle={{
//                       width: "100%",
//                     }}
//                     {...formik.getFieldProps("email")}
//                     error={formik.touched.email && formik.errors.email}
//                     errorMessage={formik.errors.email}
//                   />
//                 )}
//                 {queryParamEmail && (
//                   <InputComponent
//                     label="Email Address"
//                     placeholder="mike@spur.fit"
//                     type="email"
//                     containerStyle={{
//                       width: "100%",
//                     }}
//                     {...formik.getFieldProps("email")}
//                     error={formik.touched.email && formik.errors.email}
//                     errorMessage={formik.errors.email}
//                     value={queryParamEmail}
//                     disabled={true}
//                   />
//                 )}

//                 <InputComponent
//                   label="Password"
//                   placeholder="Password"
//                   type={showPassword ? "text" : "password"}
//                   containerStyle={{
//                     width: "100%",
//                   }}
//                   {...formik.getFieldProps("password")}
//                   error={formik.touched.password && formik.errors.password}
//                   errorMessage={formik.errors.password}
//                   rightIcon={
//                     showPassword ? (
//                       <VisibilityOutlinedIcon
//                         onClick={() => setShowPassword(false)}
//                       />
//                     ) : (
//                       <VisibilityOffOutlinedIcon
//                         onClick={() => setShowPassword(true)}
//                       />
//                     )
//                   }
//                 />

//                 <InputComponent
//                   label="Confirm Password"
//                   placeholder="Confirm Password"
//                   type={showConfirmPassword ? "text" : "password"}
//                   containerStyle={{
//                     width: "100%",
//                   }}
//                   {...formik.getFieldProps("confirmPassword")}
//                   error={
//                     formik.touched.confirmPassword &&
//                     formik.errors.confirmPassword
//                   }
//                   errorMessage={formik.errors.confirmPassword}
//                   rightIcon={
//                     showConfirmPassword ? (
//                       <VisibilityOutlinedIcon
//                         onClick={() => setShowConfirmPassword(false)}
//                       />
//                     ) : (
//                       <VisibilityOffOutlinedIcon
//                         onClick={() => setShowConfirmPassword(true)}
//                       />
//                     )
//                   }
//                 />
//                 {affiliation && (
//                   <InputComponent
//                     label={"Affiliation"}
//                     placeholder={"Affiliation"}
//                     value={affiliation}
//                     type={"text"}
//                     disabled={true}
//                     containerStyle={{
//                       width: "100%",
//                     }}
//                   />
//                 )}
//               </div>
//             </section>

//             {/* Personal info section  */}
//             <section className="section-container">
//               <div className="section-header">
//                 <div className="section-header-text">Personal Info</div>
//               </div>
//               <div
//                 style={{
//                   width: "100%",
//                 }}
//                 className="section-body"
//               >
//                 <div
//                   style={{
//                     marginLeft: "12px",
//                     width: "100%",
//                   }}
//                 >
//                   <div
//                     style={{
//                       fontSize: 14,
//                       color: "#424242",
//                     }}
//                     className="text-sm tracking-wide font-DMSans"
//                   >
//                     Gender
//                   </div>
//                   <RadioGroup
//                     row
//                     aria-labelledby="demo-row-radio-buttons-group-label"
//                     onChange={formik.handleChange}
//                     sx={{
//                       display: "flex",
//                       flexDirection: "row",
//                       justifyContent: "space-between",
//                     }}
//                     name="gender"
//                   >
//                     <FormControlLabel
//                       value="male"
//                       control={<Radio color="royalBlue" />}
//                       label="Male"
//                     />
//                     <FormControlLabel
//                       value="female"
//                       control={<Radio color="royalBlue" />}
//                       label="Female"
//                     />
//                     <FormControlLabel
//                       value="other"
//                       control={<Radio color="royalBlue" />}
//                       label="Other"
//                     />
//                   </RadioGroup>
//                   <ShowError
//                     error={formik.touched.gender && formik.errors.gender}
//                     errorMessage={formik.errors.gender}
//                   />
//                 </div>

//                 <div
//                   style={{
//                     width: "100%",
//                   }}
//                 >
//                   <div
//                     style={{
//                       fontSize: 14,
//                       marginLeft: "12px",
//                       color: "#424242",
//                     }}
//                     className="text-sm tracking-wide font-DMSans"
//                   >
//                     Country
//                   </div>
//                   {/* <Select
//                       sx={{
//                         height: "41px",
//                         mt: "8px",
//                         fontSize: "16px",
//                         "& .MuiOutlinedInput-root": {
//                           "& fieldset": {
//                             borderColor: "#ebebeb",
//                           },
//                         },
//                         "&.MuiSelect-outlined": {
//                           border: "1.5px solid #ebebeb",
//                         },

//                         "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
//                           border: "1.5px solid #4339f2",
//                           boxShadow: "0px 0px 0px 3px rgba(67, 57, 242, 0.25)",
//                         },
//                         borderRadius: "12px",
//                         fontFamily: "DM Sans",
//                       }}
//                       variant="outlined"
//                       onChange={formik.handleChange}
//                       value={formik.values.country || "select"}
//                       name="country"
//                     >
//                       <MenuItem
//                         sx={{
//                           fontFamily: "DM Sans",
//                           fontSize: "14px",
//                         }}
//                         disabled
//                         value={"select"}
//                       >
//                         Select Country
//                       </MenuItem>
//                       {countryCodes.map((country) => (
//                         <MenuItem
//                           sx={{
//                             fontFamily: "DM Sans",
//                             fontSize: "14px",
//                           }}
//                           value={country.code}
//                         >
//                           {country.country}
//                         </MenuItem>
//                       ))}
//                     </Select> */}
//                   <Autocomplete
//                     id="combo-box-demo"
//                     options={countryCodes}
//                     getOptionLabel={(option) => option.country}
//                     clearIcon={null}
//                     sx={{
//                       height: "41px",
//                       backgroundColor: "#fff",
//                       "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
//                         border: "1.5px solid #4339f2",
//                         boxShadow: "0px 0px 0px 3px rgba(67, 57, 242, 0.25)",
//                       },
//                       borderRadius: "12px",
//                     }}
//                     renderInput={(params) => {
//                       return (
//                         <TextField
//                           {...params}
//                           sx={{
//                             height: "100%",
//                             borderRadius: "12px",
//                             fontSize: "16px",
//                             color: "#000",
//                             "& .MuiOutlinedInput-root": {
//                               "& fieldset": {
//                                 borderColor: "#ebebeb",
//                                 borderRadius: "12px",
//                               },
//                             },

//                             "&.MuiSelect-outlined": {
//                               border: "1.5px solid #ebebeb",
//                             },
//                             fontFamily: "DM Sans",
//                           }}
//                           placeholder="Select Country"
//                           size="small"
//                         />
//                       );
//                     }}
//                     name="country"
//                     value={countryCodes.find(
//                       (country) => country.code === formik.values.country
//                     )}
//                     onChange={(e, value) => {
//                       formik.setFieldValue("country", value?.code);
//                     }}
//                   />

//                   <ShowError
//                     error={formik.touched.country && formik.errors.country}
//                     errorMessage={formik.errors.country}
//                   />
//                 </div>
//               </div>
//             </section>

//             {/* Units for weight Selecting section */}
//             <section className="section-container">
//               <div className="section-header">
//                 <div className="section-header-text">Unit System</div>
//               </div>
//               <div
//                 style={{
//                   width: "100%",
//                 }}
//                 className="section-body"
//               >
//                 <div
//                   style={{
//                     marginLeft: "12px",
//                     width: "100%",
//                   }}
//                 >
//                   <div
//                     style={{
//                       fontSize: 14,
//                       color: "#424242",
//                     }}
//                     className="text-sm tracking-wide font-DMSans"
//                   >
//                     Units
//                   </div>
//                   <RadioGroup
//                     row
//                     aria-labelledby="demo-row-radio-buttons-group-label"
//                     onChange={formik.handleChange}
//                     sx={{
//                       display: "flex",
//                       flexDirection: "row",
//                       justifyContent: "flex-start",
//                     }}
//                     name="unitSystem"
//                   >
//                     <FormControlLabel
//                       value="KGS"
//                       control={<Radio color="royalBlue" />}
//                       label="Kilograms (KGS)"
//                       className="unit-system kgs"
//                     />
//                     <FormControlLabel
//                       value="LBS"
//                       control={<Radio color="royalBlue" />}
//                       label="Pounds (LBS)"
//                     />
//                   </RadioGroup>
//                   <ShowError
//                     error={
//                       formik.touched.unitSystem && formik.errors.unitSystem
//                     }
//                     errorMessage={formik.errors.unitSystem}
//                   />
//                 </div>
//               </div>
//             </section>
//             <div
//               style={{
//                 width: "100%",
//                 zIndex: 1,
//               }}
//             >
//               <button
//                 style={{
//                   width: "100%",
//                 }}
//                 className="button-new "
//                 onClick={formik.handleSubmit}
//               >
//                 {activityLoaderOn ? (
//                   <SpinnerComponent />
//                 ) : (
//                   <span
//                     style={{
//                       fontSize: 14,
//                     }}
//                   >
//                     {"Register"}
//                   </span>
//                 )}
//               </button>
//             </div>
//           </div>
//         </div>
//         <div className="review-container">
//           <div className="main-cont">
//             {/* <div
//               className="headline"
//               style={{
//                 width: "100%",
//                 padding: "10px 40px",
//               }}
//             >
//               <h1
//                 style={{
//                   fontSize: "26px",
//                   textAlign: "center",
//                   fontWeight: "700",
//                 }}
//                 className="font-DMSans"
//               >
//                 Coach Seyma saw 10x increase in her revenue while still keeping
//                 every client happy and motivated
//               </h1>
//             </div> */}
//             <div
//               style={{
//                 width: "100%",
//                 padding: "12px 30px",
//                 display: "flex",
//                 justifyContent: "center",
//                 alignItems: "center",
//                 marginTop: "10px",
//               }}
//             >
//               <div
//                 className="left-div"
//                 style={{
//                   width: "30%",
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "center",
//                   alignItems: "center",
//                   position: "relative",
//                   marginRight: "30px",
//                 }}
//               >
//                 <div
//                   style={{
//                     width: "230px",
//                     height: "230px",
//                     transform: "rotate(0.02turn)",
//                     background: "royalblue",
//                     borderRadius: "4px",
//                   }}
//                 ></div>
//                 <div
//                   style={{
//                     width: "230px",
//                     height: "230px",
//                     background: "white",
//                     borderRadius: "4px",
//                     position: "absolute",
//                     overflow: "hidden",
//                   }}
//                 >
//                   <img
//                     src={Seyma}
//                     alt="reviewer"
//                     style={{
//                       objectFit: "cover",
//                       width: "100%",
//                       height: "100%",
//                       borderRadius: "4px",
//                     }}
//                   />
//                 </div>
//               </div>
//               <div
//                 className="right-div"
//                 style={{
//                   width: "60%",
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "flex-start",
//                 }}
//               >
//                 <div
//                   className="review-div"
//                   style={{
//                     marginBottom: "25px",
//                   }}
//                 >
//                   <p
//                     style={{
//                       fontSize: "20px",
//                       fontWeight: "500",
//                       fontStyle: "italic",
//                     }}
//                     className="font-DMSans"
//                   >
//                     {reviews[0]?.review}
//                   </p>
//                 </div>
//                 <div
//                   className="reviewer-info-div"
//                   style={{
//                     display: "flex",
//                     justifyContent: "flex-start",
//                     alignItems: "center",
//                   }}
//                 >
//                   <img
//                     src={reviewTrainerIcon}
//                     style={{
//                       width: "40px",
//                       height: "40px",
//                     }}
//                   />
//                   <div
//                     className="detail-div"
//                     style={{
//                       display: "flex",
//                       flexDirection: "column",
//                       justifyContent: "flex-start",
//                       marginLeft: "15px",
//                     }}
//                   >
//                     <p
//                       style={{
//                         color: "royalblue",
//                         fontSize: "18px",
//                         fontWeight: "600",
//                         color: "white",
//                       }}
//                       className="font-DMSans"
//                     >
//                       {reviews[0]?.name}
//                     </p>
//                     <p
//                       style={{
//                         fontSize: "14px",
//                         fontWeight: "500",
//                       }}
//                       className="font-DMSans"
//                     >
//                       {reviews[0]?.designation}
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "center",
//                 alignItems: "center",
//                 width: "100%",
//                 padding: "10px 10px",
//                 flexDirection: "column",
//                 marginBottom: "10px",
//               }}
//             >
//               <div
//                 className="trainer-signup-feature-heading"
//                 style={{
//                   display: "flex",
//                   justifyContent: "center",
//                   alignItems: "center",
//                   padding: "0px 40px",
//                 }}
//               >
//                 <h3
//                   style={{
//                     fontSize: "21px",
//                     textAlign: "center",
//                     fontWeight: "600",
//                   }}
//                   className="font-DMSans"
//                 >
//                   AI-powered workouts & meals, client chat, easy payments: Grow
//                   your fitness business 5x faster with Spur.Fit
//                 </h3>
//               </div>

//               <div
//                 className="trainer-signup-features"
//                 style={{
//                   display: "grid",
//                   gridTemplateColumns: "auto auto auto auto",
//                   gap: "12px",
//                   marginTop: "24px",
//                 }}
//               >
//                 <div className="flex flex-col-reverse gap-2 items-center justify-center ">
//                   <p className="font-DMSans text-font14">AI Co-Pilot</p>
//                   <img
//                     src={img1}
//                     alt=""
//                     style={{
//                       width: "28px",
//                       height: "28px",
//                     }}
//                   />
//                 </div>
//                 <div className="flex flex-col-reverse gap-2 items-center justify-center ">
//                   <p className="font-DMSans text-font14">Lead Capture CRM</p>
//                   <img
//                     src={img2}
//                     alt=""
//                     style={{
//                       width: "28px",
//                       height: "28px",
//                     }}
//                   />
//                 </div>
//                 <div className="flex flex-col-reverse gap-2 items-center justify-center ">
//                   <p className="font-DMSans text-font14">Trainer Roles*</p>
//                   <img
//                     src={img3}
//                     alt=""
//                     style={{
//                       width: "28px",
//                       height: "28px",
//                     }}
//                   />
//                 </div>
//                 <div className="flex flex-col-reverse gap-2 items-center justify-center ">
//                   <p className="font-DMSans text-font14">In-App Messaging</p>
//                   <img
//                     src={img4}
//                     alt=""
//                     style={{
//                       width: "28px",
//                       height: "28px",
//                     }}
//                   />
//                 </div>
//                 <div className="flex flex-col-reverse gap-2 items-center justify-center ">
//                   <p className="font-DMSans text-font14">Progress Tracking</p>
//                   <img
//                     src={img5}
//                     alt=""
//                     style={{
//                       width: "28px",
//                       height: "28px",
//                     }}
//                   />
//                 </div>
//                 <div className="flex flex-col-reverse gap-2 items-center justify-center ">
//                   <p className="font-DMSans text-font14">Nutrition Coaching</p>
//                   <img
//                     src={img6}
//                     alt=""
//                     style={{
//                       width: "28px",
//                       height: "28px",
//                     }}
//                   />
//                 </div>
//                 <div className="flex flex-col-reverse gap-2 items-center justify-center ">
//                   <p className="font-DMSans text-font14">
//                     Video Exercise Library
//                   </p>
//                   <img
//                     src={img7}
//                     alt=""
//                     style={{
//                       width: "28px",
//                       height: "28px",
//                     }}
//                   />
//                 </div>
//                 <div className="flex flex-col-reverse gap-2 items-center justify-center ">
//                   <p className="font-DMSans text-font14">Workout Builder</p>
//                   <img
//                     src={img8}
//                     alt=""
//                     style={{
//                       width: "28px",
//                       height: "28px",
//                     }}
//                   />
//                 </div>
//               </div>
//               <div
//                 className="last-grid"
//                 style={{
//                   display: "flex",
//                   justifyContent: "center",
//                   alignItems: "center",
//                   marginTop: "8px",
//                 }}
//               >
//                 <div className="flex flex-col-reverse gap-2 items-center justify-center mr-7">
//                   <p className="font-DMSans text-font14">Automated Payments</p>
//                   <img
//                     src={img9}
//                     alt=""
//                     style={{
//                       width: "28px",
//                       height: "28px",
//                     }}
//                   />
//                 </div>
//                 <div className="flex flex-col-reverse gap-2 items-center justify-center ">
//                   <p className="font-DMSans text-font14">Custom Branding*</p>
//                   <img
//                     src={img10}
//                     alt=""
//                     style={{
//                       width: "28px",
//                       height: "28px",
//                     }}
//                   />
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   };

//   const dummy = () => {
//     history.push("/", {
//       origin: "trainerSignup",
//     });
//   };

//   return (
//     <>
//       <ToastContainer />
//       {/* <div
//         className="lg:flex"
//         style={{
//           flex: 1,
//           display: "flex",
//           alignItems: "center",
//           padding: "48px 0px",
//           height: "100%",
//           position: "relative",
//         }} */}
//       {/* > */}
//       {renderForm()}
//       {/* <img
//           className="hidden md:block"
//           src={BlueEffect}
//           style={{
//             position: "absolute",
//             opacity: 0.6,
//             bottom: "50px",
//             left: 52,
//             zIndex: 0,
//           }}
//         />

//         <img
//           className="hidden md:block"
//           src={VioletEffect}
//           style={{
//             position: "absolute",
//             opacity: 0.6,
//             top: "54px",
//             right: -159,
//             zIndex: 0,
//           }}
//         />

//         <img
//           className="hidden md:block"
//           src={OrangeEffect}
//           style={{
//             position: "absolute",
//             opacity: 0.6,
//             bottom: "100px",
//             right: -68,
//             zIndex: 0,
//           }}
//         />

//         <img
//           className="hidden md:block"
//           src={YellowEffect}
//           style={{
//             position: "absolute",
//             opacity: 0.6,
//             top: "150px",
//             left: -159,
//             zIndex: 0,
//           }}
//         /> */}
//       {/* </div> */}
//       {/* <button onClick={dummy}>Go to login</button> */}
//       <ImageUploadWithCrop
//         open={openImagePicker}
//         setOpen={setOpenImagePicker}
//         uploadProfilePic={handleProfilePic}
//       />
//     </>
//   );
// };
// export const TrainerSignupThree = () => {
//   const reviews = [
//     {
//       name: "Seyma",
//       review:
//         "Spur.Fit is a one-stop solution for me & my clients. Creating workouts, meal plans, communication, managing payments, custom branded app & is just so easy to use.",
//       designation: "Online fitness coach",
//     },
//   ];
//   const location = useLocation();
//   const queryParams = new URLSearchParams(location.search);
//   const queryParamEmail = queryParams.get("email");
//   console.log("query param email", queryParamEmail);
//   const history = useHistory();
//   const { id, affiliation, affiliateCoachID } = useParams();
//   const [activityLoaderOn, setActivityLoader] = useState(false);
//   const [screenWidth, setScreenWidth] = useState(window.innerWidth);
//   const [openImagePicker, setOpenImagePicker] = useState(false);
//   const [trainerImage, setTrainerImage] = useState(null);
//   const [showPassword, setShowPassword] = useState(false);
//   const [showConfirmPassword, setShowConfirmPassword] = useState(false);

//   const [trainerInfo, setTrainerInfo] = useState({
//     name: "",
//     email: "",
//     gender: "",
//   });

//   useEffect(() => {
//     console.log("use params feild", id, affiliation);
//     const handleResize = () => {
//       setScreenWidth(window.innerWidth);
//     };

//     mixpanel.init("147a208f5832549c876d94601401033e", {
//       //debug: true,
//       api_host: "https://api.mixpanel.com",
//       ignore_dnt: true,
//     });
//     if (
//       localStorage.getItem("isLoggedIn") !== null &&
//       JSON.parse(localStorage.getItem("isLoggedIn"))
//     ) {
//       let trainerDetails = JSON.parse(
//         localStorage.getItem("trainerCredentials")
//       );

//       mixpanel.identify(trainerDetails?.password);
//       mixpanel.people.set_once({
//         Name: trainerDetails?.name,
//         Email: trainerDetails?.email,
//       });
//       mixpanel.track("Trainer_Register");

//       // navigate('/home');
//       history.push("/home");
//     }

//     document.title = "Spur.fit - Signup";

//     window.addEventListener("resize", handleResize);

//     return () => {
//       window.removeEventListener("resize", handleResize);
//       document.title = "Spur.fit";
//     };
//   }, []);
//   const { displayAlert } = useContext(CustomAlertContext);

//   const onRegister = ({ trainerInfo }) => {
//     let url =
//       id || affiliateCoachID
//         ? `${APIURL}CF/api/CoachWeb/Create/trainerSignUpAdminRef`
//         : `${APIURL}CF/api/CoachWeb/Create/trainerSignUp`;
//     axios
//       .post(url, trainerInfo)
//       .then((res) => {
//         history.push("/", {
//           origin: "trainerSignup",
//         });
//         displayAlert({
//           message: "Registered Successfully! Please check your email.",
//           type: "success",
//         });
//         // uploadProfilePic(res.data.data.primaryKey);
//       })
//       .catch((err) => {
//         displayAlert({
//           message: "Something went wrong! Please try again later.",
//           type: "error",
//         });
//       })
//       .finally(() => {
//         setActivityLoader(false);
//       });
//   };

//   // upload profile pic
//   const handleProfilePic = async (profilePic) => {
//     setTrainerImage(profilePic);
//     setOpenImagePicker(false);
//   };

//   const uploadProfilePic = async (id) => {
//     if (!trainerImage || trainerImage === "") {
//       return;
//     }
//     const formData = new FormData();
//     formData.append("image", trainerImage);

//     await axios({
//       method: "post",
//       url: `${APIURL}api/uploadtrainerprofilepic?trainerUUID=${id}`,
//       data: formData,
//     })
//       .then(async (res) => {
//         // toast("Successfully Uploaded!", "success");
//         console.log("res", res);
//       })
//       .catch((err) => {
//         // toast("Incorrect Image dimension. Need to be square", "error");
//       });
//   };

//   const validationSchema = Yup.object().shape({
//     firstName: Yup.string().required("First Name is required"),
//     lastName: Yup.string().required("Last Name is required"),
//     email: Yup.string()
//       .email("Invalid email address")
//       .required("Email is required"),
//     password: Yup.string()
//       .required("Password is required")
//       .min(6, "Password must be at least 6 characters")
//       .max(25, "Password must be at most 16 characters"),
//     confirmPassword: Yup.string()
//       .oneOf([Yup.ref("password"), null], "Passwords must match")
//       .required("Confirm Password is required"),
//     gender: Yup.string().required("Gender is required"),
//     country: Yup.string().required("Country is required"),
//     unitSystem: Yup.string().required("Unit System is required"),
//   });

//   const formik = useFormik({
//     initialValues: {
//       firstName: "",
//       lastName: "",
//       email: queryParamEmail || "",
//       password: "",
//       confirmPassword: "",
//       gender: "",
//       country: "",
//       unitSystem: "",
//     },
//     validationSchema: validationSchema,
//     onSubmit: (values, { setSubmitting }) => {
//       setActivityLoader(true);
//       let data = {
//         name: values.firstName + " " + values.lastName,
//         email: values.email,
//         password: values.password,
//         gender: values.gender,
//         country: values.country,
//         unitSystem: values.unitSystem,
//       };
//       let formData = new FormData();
//       formData.append("profilePic", trainerImage);
//       formData.append("name", values.firstName + " " + values.lastName);
//       formData.append("email", values.email);
//       formData.append("password", values.password);
//       formData.append("gender", values.gender);
//       formData.append("country", values.country);
//       formData.append("unitSystem", values.unitSystem);

//       if (id) {
//         formData.append("adminUUID", id);
//       }

//       if (affiliation) {
//         formData.append("gymAffliation", affiliation);
//       }
//       if (affiliateCoachID) {
//         formData.append("affiliateCoachID", affiliateCoachID);
//       }

//       onRegister({ trainerInfo: formData });
//     },
//   });
//   // display signup form
//   const renderForm = () => {
//     return (
//       <div className="main-div">
//         <div className="form-container">
//           <div className="welcome-cont">
//             <img src={Logo} alt="" className="logo-image" />
//             <h3 className="welcome-msg">Welcome Trainer!</h3>
//             <span
//               className="font-DMSans text-font16 text-center self-center"
//               style={{
//                 color: "#424242",
//                 fontWeight: 400,
//               }}
//             >
//               Register here to get started
//             </span>
//           </div>

//           <div
//             style={{ gap: "16px" }}
//             className=" flex flex-col items-center p-2 md:p-0"
//           >
//             {/* Basic info section  */}
//             <section className="section-container">
//               <div className="section-header">
//                 <div className="section-header-text">Basic Info</div>
//               </div>
//               <div className="section-body">
//                 <div className="flex items-center justify-center">
//                   <div
//                     style={{
//                       backgroundColor: "#EEE",
//                       borderRadius: "50%",
//                       height: "120px",
//                       width: "120px",
//                       position: "relative",
//                     }}
//                     className="flex items-center justify-center"
//                   >
//                     <img
//                       src={
//                         trainerImage
//                           ? URL.createObjectURL(trainerImage)
//                           : DefaultImage
//                       }
//                       alt="defaultImage"
//                       style={{
//                         borderRadius: "50%",
//                       }}
//                     />

//                     <div
//                       style={{
//                         display: "flex",
//                         width: "36px",
//                         height: "36px",
//                         padding: "8px",
//                         justifyContent: "center",
//                         alignItems: "center",
//                         flexShrink: 0,
//                         borderRadius: "18px",
//                         background: "#F2F2F2",
//                         position: "absolute",
//                         bottom: 0,
//                         right: 0,
//                         cursor: "pointer",
//                       }}
//                     >
//                       <img
//                         src={PencilIcon}
//                         alt="edit"
//                         onClick={() => setOpenImagePicker(true)}
//                         style={{
//                           height: "100%",
//                           width: "100%",
//                         }}
//                       />
//                     </div>
//                   </div>
//                 </div>
//                 <div
//                   style={{
//                     display: "flex",
//                     justifyContent: "space-between",
//                     width: "100%",
//                     alignItems: "flex-start",
//                     gap: 16,
//                   }}
//                 >
//                   <InputComponent
//                     label="First Name"
//                     placeholder="Mike"
//                     type="text"
//                     {...formik.getFieldProps("firstName")}
//                     error={formik.touched.firstName && formik.errors.firstName}
//                     errorMessage={formik.errors.firstName}
//                   />

//                   <InputComponent
//                     label="Last Name"
//                     placeholder="Tyson"
//                     type="text"
//                     {...formik.getFieldProps("lastName")}
//                     error={formik.touched.lastName && formik.errors.lastName}
//                     errorMessage={formik.errors.lastName}
//                   />
//                 </div>
//               </div>
//             </section>

//             {/* Account info section  */}
//             <section className="section-container">
//               <div className="section-header">
//                 <div className="section-header-text">Account Info</div>
//               </div>
//               <div
//                 style={{
//                   width: "100%",
//                 }}
//                 className="section-body"
//               >
//                 {!queryParamEmail && (
//                   <InputComponent
//                     label="Email Address"
//                     placeholder="mike@spur.fit"
//                     type="email"
//                     containerStyle={{
//                       width: "100%",
//                     }}
//                     {...formik.getFieldProps("email")}
//                     error={formik.touched.email && formik.errors.email}
//                     errorMessage={formik.errors.email}
//                   />
//                 )}
//                 {queryParamEmail && (
//                   <InputComponent
//                     label="Email Address"
//                     placeholder="mike@spur.fit"
//                     type="email"
//                     containerStyle={{
//                       width: "100%",
//                     }}
//                     {...formik.getFieldProps("email")}
//                     error={formik.touched.email && formik.errors.email}
//                     errorMessage={formik.errors.email}
//                     value={queryParamEmail}
//                     disabled={true}
//                   />
//                 )}

//                 <InputComponent
//                   label="Password"
//                   placeholder="Password"
//                   type={showPassword ? "text" : "password"}
//                   containerStyle={{
//                     width: "100%",
//                   }}
//                   {...formik.getFieldProps("password")}
//                   error={formik.touched.password && formik.errors.password}
//                   errorMessage={formik.errors.password}
//                   rightIcon={
//                     showPassword ? (
//                       <VisibilityOutlinedIcon
//                         onClick={() => setShowPassword(false)}
//                       />
//                     ) : (
//                       <VisibilityOffOutlinedIcon
//                         onClick={() => setShowPassword(true)}
//                       />
//                     )
//                   }
//                 />

//                 <InputComponent
//                   label="Confirm Password"
//                   placeholder="Confirm Password"
//                   type={showConfirmPassword ? "text" : "password"}
//                   containerStyle={{
//                     width: "100%",
//                   }}
//                   {...formik.getFieldProps("confirmPassword")}
//                   error={
//                     formik.touched.confirmPassword &&
//                     formik.errors.confirmPassword
//                   }
//                   errorMessage={formik.errors.confirmPassword}
//                   rightIcon={
//                     showConfirmPassword ? (
//                       <VisibilityOutlinedIcon
//                         onClick={() => setShowConfirmPassword(false)}
//                       />
//                     ) : (
//                       <VisibilityOffOutlinedIcon
//                         onClick={() => setShowConfirmPassword(true)}
//                       />
//                     )
//                   }
//                 />
//                 {affiliation && (
//                   <InputComponent
//                     label={"Affiliation"}
//                     placeholder={"Affiliation"}
//                     value={affiliation}
//                     type={"text"}
//                     disabled={true}
//                     containerStyle={{
//                       width: "100%",
//                     }}
//                   />
//                 )}
//               </div>
//             </section>

//             {/* Personal info section  */}
//             <section className="section-container">
//               <div className="section-header">
//                 <div className="section-header-text">Personal Info</div>
//               </div>
//               <div
//                 style={{
//                   width: "100%",
//                 }}
//                 className="section-body"
//               >
//                 <div
//                   style={{
//                     marginLeft: "12px",
//                     width: "100%",
//                   }}
//                 >
//                   <div
//                     style={{
//                       fontSize: 14,
//                       color: "#424242",
//                     }}
//                     className="text-sm tracking-wide font-DMSans"
//                   >
//                     Gender
//                   </div>
//                   <RadioGroup
//                     row
//                     aria-labelledby="demo-row-radio-buttons-group-label"
//                     onChange={formik.handleChange}
//                     sx={{
//                       display: "flex",
//                       flexDirection: "row",
//                       justifyContent: "space-between",
//                     }}
//                     name="gender"
//                   >
//                     <FormControlLabel
//                       value="male"
//                       control={<Radio color="royalBlue" />}
//                       label="Male"
//                     />
//                     <FormControlLabel
//                       value="female"
//                       control={<Radio color="royalBlue" />}
//                       label="Female"
//                     />
//                     <FormControlLabel
//                       value="other"
//                       control={<Radio color="royalBlue" />}
//                       label="Other"
//                     />
//                   </RadioGroup>
//                   <ShowError
//                     error={formik.touched.gender && formik.errors.gender}
//                     errorMessage={formik.errors.gender}
//                   />
//                 </div>

//                 <div
//                   style={{
//                     width: "100%",
//                   }}
//                 >
//                   <div
//                     style={{
//                       fontSize: 14,
//                       marginLeft: "12px",
//                       color: "#424242",
//                     }}
//                     className="text-sm tracking-wide font-DMSans"
//                   >
//                     Country
//                   </div>
//                   {/* <Select
//                       sx={{
//                         height: "41px",
//                         mt: "8px",
//                         fontSize: "16px",
//                         "& .MuiOutlinedInput-root": {
//                           "& fieldset": {
//                             borderColor: "#ebebeb",
//                           },
//                         },
//                         "&.MuiSelect-outlined": {
//                           border: "1.5px solid #ebebeb",
//                         },

//                         "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
//                           border: "1.5px solid #4339f2",
//                           boxShadow: "0px 0px 0px 3px rgba(67, 57, 242, 0.25)",
//                         },
//                         borderRadius: "12px",
//                         fontFamily: "DM Sans",
//                       }}
//                       variant="outlined"
//                       onChange={formik.handleChange}
//                       value={formik.values.country || "select"}
//                       name="country"
//                     >
//                       <MenuItem
//                         sx={{
//                           fontFamily: "DM Sans",
//                           fontSize: "14px",
//                         }}
//                         disabled
//                         value={"select"}
//                       >
//                         Select Country
//                       </MenuItem>
//                       {countryCodes.map((country) => (
//                         <MenuItem
//                           sx={{
//                             fontFamily: "DM Sans",
//                             fontSize: "14px",
//                           }}
//                           value={country.code}
//                         >
//                           {country.country}
//                         </MenuItem>
//                       ))}
//                     </Select> */}
//                   <Autocomplete
//                     id="combo-box-demo"
//                     options={countryCodes}
//                     getOptionLabel={(option) => option.country}
//                     clearIcon={null}
//                     sx={{
//                       height: "41px",
//                       backgroundColor: "#fff",
//                       "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
//                         border: "1.5px solid #4339f2",
//                         boxShadow: "0px 0px 0px 3px rgba(67, 57, 242, 0.25)",
//                       },
//                       borderRadius: "12px",
//                     }}
//                     renderInput={(params) => {
//                       return (
//                         <TextField
//                           {...params}
//                           sx={{
//                             height: "100%",
//                             borderRadius: "12px",
//                             fontSize: "16px",
//                             color: "#000",
//                             "& .MuiOutlinedInput-root": {
//                               "& fieldset": {
//                                 borderColor: "#ebebeb",
//                                 borderRadius: "12px",
//                               },
//                             },

//                             "&.MuiSelect-outlined": {
//                               border: "1.5px solid #ebebeb",
//                             },
//                             fontFamily: "DM Sans",
//                           }}
//                           placeholder="Select Country"
//                           size="small"
//                         />
//                       );
//                     }}
//                     name="country"
//                     value={countryCodes.find(
//                       (country) => country.code === formik.values.country
//                     )}
//                     onChange={(e, value) => {
//                       formik.setFieldValue("country", value?.code);
//                     }}
//                   />

//                   <ShowError
//                     error={formik.touched.country && formik.errors.country}
//                     errorMessage={formik.errors.country}
//                   />
//                 </div>
//               </div>
//             </section>

//             {/* Units for weight Selecting section */}
//             <section className="section-container">
//               <div className="section-header">
//                 <div className="section-header-text">Unit System</div>
//               </div>
//               <div
//                 style={{
//                   width: "100%",
//                 }}
//                 className="section-body"
//               >
//                 <div
//                   style={{
//                     marginLeft: "12px",
//                     width: "100%",
//                   }}
//                 >
//                   <div
//                     style={{
//                       fontSize: 14,
//                       color: "#424242",
//                     }}
//                     className="text-sm tracking-wide font-DMSans"
//                   >
//                     Units
//                   </div>
//                   <RadioGroup
//                     row
//                     aria-labelledby="demo-row-radio-buttons-group-label"
//                     onChange={formik.handleChange}
//                     sx={{
//                       display: "flex",
//                       flexDirection: "row",
//                       justifyContent: "flex-start",
//                     }}
//                     name="unitSystem"
//                   >
//                     <FormControlLabel
//                       value="KGS"
//                       control={<Radio color="royalBlue" />}
//                       label="Kilograms (KGS)"
//                       className="unit-system kgs"
//                     />
//                     <FormControlLabel
//                       value="LBS"
//                       control={<Radio color="royalBlue" />}
//                       label="Pounds (LBS)"
//                     />
//                   </RadioGroup>
//                   <ShowError
//                     error={
//                       formik.touched.unitSystem && formik.errors.unitSystem
//                     }
//                     errorMessage={formik.errors.unitSystem}
//                   />
//                 </div>
//               </div>
//             </section>
//             <div
//               style={{
//                 width: "100%",
//                 zIndex: 1,
//               }}
//             >
//               <button
//                 style={{
//                   width: "100%",
//                 }}
//                 className="button-new "
//                 onClick={formik.handleSubmit}
//               >
//                 {activityLoaderOn ? (
//                   <SpinnerComponent />
//                 ) : (
//                   <span
//                     style={{
//                       fontSize: 14,
//                     }}
//                   >
//                     {"Register"}
//                   </span>
//                 )}
//               </button>
//             </div>
//           </div>
//         </div>
//         <div className="review-container">
//           <div className="main-cont">
//             <div
//               className="headline"
//               style={{
//                 width: "100%",
//                 padding: "15px 30px",
//               }}
//             >
//               <h1
//                 style={{
//                   fontSize: "24px",
//                   textAlign: "center",
//                   fontWeight: "700",
//                 }}
//                 className="font-DMSans"
//               >
//                 Coach Seyma saw 10x increase in her revenue while still keeping
//                 every client happy and motivated
//               </h1>
//             </div>
//             <div
//               style={{
//                 width: "100%",
//                 padding: "12px 20px",
//                 display: "flex",
//                 justifyContent: "center",
//                 alignItems: "center",
//               }}
//             >
//               <div
//                 className="left-div"
//                 style={{
//                   width: "35%",
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "center",
//                   alignItems: "center",
//                   position: "relative",
//                   marginRight: "15px",
//                 }}
//               >
//                 <div
//                   style={{
//                     width: "230px",
//                     height: "230px",
//                     transform: "rotate(0.02turn)",
//                     background: "royalblue",
//                     borderRadius: "4px",
//                   }}
//                 ></div>
//                 <div
//                   style={{
//                     width: "230px",
//                     height: "230px",
//                     background: "white",
//                     borderRadius: "4px",
//                     position: "absolute",
//                     overflow: "hidden",
//                   }}
//                 >
//                   <img
//                     src={Seyma}
//                     alt="reviewer"
//                     style={{
//                       objectFit: "cover",
//                       width: "100%",
//                       height: "100%",
//                       borderRadius: "4px",
//                     }}
//                   />
//                 </div>
//               </div>
//               <div
//                 className="right-div"
//                 style={{
//                   width: "30%",
//                   display: "flex",
//                   flexDirection: "column",
//                   justifyContent: "flex-start",
//                 }}
//               >
//                 {/* <div
//                   className="review-div"
//                   style={{
//                     marginBottom: "10px",
//                   }}
//                 >
//                   <p
//                     style={{
//                       fontSize: "18px",
//                       fontWeight: "500",
//                       fontStyle: "italic",
//                     }}
//                     className="font-DMSans"
//                   >
//                     {reviews[0]?.review}
//                   </p>
//                 </div> */}
//                 <div
//                   className="reviewer-info-div"
//                   style={{
//                     display: "flex",
//                     justifyContent: "flex-start",
//                     alignItems: "center",
//                   }}
//                 >
//                   <img
//                     src={reviewTrainerIcon}
//                     style={{
//                       width: "35px",
//                       height: "35px",
//                     }}
//                   />
//                   <div
//                     className="detail-div"
//                     style={{
//                       display: "flex",
//                       flexDirection: "column",
//                       justifyContent: "flex-start",
//                       marginLeft: "15px",
//                     }}
//                   >
//                     <p
//                       style={{
//                         color: "royalblue",
//                         fontSize: "16px",
//                         fontWeight: "600",
//                         color: "white",
//                       }}
//                       className="font-DMSans"
//                     >
//                       {reviews[0]?.name}
//                     </p>
//                     <p
//                       style={{
//                         fontSize: "12px",
//                         fontWeight: "500",
//                       }}
//                       className="font-DMSans"
//                     >
//                       {reviews[0]?.designation}
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "center",
//                 alignItems: "center",
//                 width: "100%",
//                 padding: "20px 20px",
//                 flexDirection: "column",
//               }}
//             >
//               <div
//                 className="trainer-signup-feature-heading"
//                 style={{
//                   display: "flex",
//                   justifyContent: "center",
//                   alignItems: "center",
//                   padding: "0px 40px",
//                 }}
//               >
//                 <h3
//                   style={{
//                     fontSize: "20px",
//                     textAlign: "center",
//                     fontWeight: "600",
//                   }}
//                   className="font-DMSans"
//                 >
//                   AI-powered workouts & meals, client chat, easy payments: Grow
//                   your fitness business 5x faster with Spur.Fit
//                 </h3>
//               </div>

//               <div
//                 className="trainer-signup-features"
//                 style={{
//                   display: "grid",
//                   gridTemplateColumns: "auto auto auto auto",
//                   gap: "15px",
//                   marginTop: "20px",
//                   justifyContent: "center",
//                 }}
//               >
//                 <div className="flex flex-col-reverse gap-2 items-center justify-center ">
//                   <p className="font-DMSans text-font14">AI Co-Pilot</p>
//                   <img
//                     src={img1}
//                     alt=""
//                     style={{
//                       width: "28px",
//                       height: "28px",
//                     }}
//                   />
//                 </div>
//                 <div className="flex flex-col-reverse gap-2 items-center justify-center ">
//                   <p className="font-DMSans text-font14">Lead Capture CRM</p>
//                   <img
//                     src={img2}
//                     alt=""
//                     style={{
//                       width: "28px",
//                       height: "28px",
//                     }}
//                   />
//                 </div>
//                 <div className="flex flex-col-reverse gap-2 items-center justify-center ">
//                   <p className="font-DMSans text-font14">Trainer Roles*</p>
//                   <img
//                     src={img3}
//                     alt=""
//                     style={{
//                       width: "28px",
//                       height: "28px",
//                     }}
//                   />
//                 </div>
//                 <div className="flex flex-col-reverse gap-2 items-center justify-center ">
//                   <p className="font-DMSans text-font14">In-App Messaging</p>
//                   <img
//                     src={img4}
//                     alt=""
//                     style={{
//                       width: "28px",
//                       height: "28px",
//                     }}
//                   />
//                 </div>
//                 <div className="flex flex-col-reverse gap-2 items-center justify-center ">
//                   <p className="font-DMSans text-font14">Progress Tracking</p>
//                   <img
//                     src={img5}
//                     alt=""
//                     style={{
//                       width: "28px",
//                       height: "28px",
//                     }}
//                   />
//                 </div>
//                 <div className="flex flex-col-reverse gap-2 items-center justify-center ">
//                   <p className="font-DMSans text-font14">Nutrition Coaching</p>
//                   <img
//                     src={img6}
//                     alt=""
//                     style={{
//                       width: "28px",
//                       height: "28px",
//                     }}
//                   />
//                 </div>
//                 <div className="flex flex-col-reverse gap-2 items-center justify-center ">
//                   <p className="font-DMSans text-font14">
//                     Video Exercise Library
//                   </p>
//                   <img
//                     src={img7}
//                     alt=""
//                     style={{
//                       width: "28px",
//                       height: "28px",
//                     }}
//                   />
//                 </div>
//                 <div className="flex flex-col-reverse gap-2 items-center justify-center ">
//                   <p className="font-DMSans text-font14">Workout Builder</p>
//                   <img
//                     src={img8}
//                     alt=""
//                     style={{
//                       width: "28px",
//                       height: "28px",
//                     }}
//                   />
//                 </div>
//               </div>
//               <div
//                 className="last-grid"
//                 style={{
//                   display: "flex",
//                   justifyContent: "center",
//                   alignItems: "center",
//                   marginTop: "10px",
//                 }}
//               >
//                 <div className="flex flex-col-reverse gap-2 items-center justify-center mr-7">
//                   <p className="font-DMSans text-font14">Automated Payments</p>
//                   <img
//                     src={img9}
//                     alt=""
//                     style={{
//                       width: "28px",
//                       height: "28px",
//                     }}
//                   />
//                 </div>
//                 <div className="flex flex-col-reverse gap-2 items-center justify-center ">
//                   <p className="font-DMSans text-font14">Custom Branding*</p>
//                   <img
//                     src={img10}
//                     alt=""
//                     style={{
//                       width: "28px",
//                       height: "28px",
//                     }}
//                   />
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   };

//   const dummy = () => {
//     history.push("/", {
//       origin: "trainerSignup",
//     });
//   };

//   return (
//     <>
//       <ToastContainer />
//       {/* <div
//         className="lg:flex"
//         style={{
//           flex: 1,
//           display: "flex",
//           alignItems: "center",
//           padding: "48px 0px",
//           height: "100%",
//           position: "relative",
//         }} */}
//       {/* > */}
//       {renderForm()}
//       {/* <img
//           className="hidden md:block"
//           src={BlueEffect}
//           style={{
//             position: "absolute",
//             opacity: 0.6,
//             bottom: "50px",
//             left: 52,
//             zIndex: 0,
//           }}
//         />

//         <img
//           className="hidden md:block"
//           src={VioletEffect}
//           style={{
//             position: "absolute",
//             opacity: 0.6,
//             top: "54px",
//             right: -159,
//             zIndex: 0,
//           }}
//         />

//         <img
//           className="hidden md:block"
//           src={OrangeEffect}
//           style={{
//             position: "absolute",
//             opacity: 0.6,
//             bottom: "100px",
//             right: -68,
//             zIndex: 0,
//           }}
//         />

//         <img
//           className="hidden md:block"
//           src={YellowEffect}
//           style={{
//             position: "absolute",
//             opacity: 0.6,
//             top: "150px",
//             left: -159,
//             zIndex: 0,
//           }}
//         /> */}
//       {/* </div> */}
//       {/* <button onClick={dummy}>Go to login</button> */}
//       <ImageUploadWithCrop
//         open={openImagePicker}
//         setOpen={setOpenImagePicker}
//         uploadProfilePic={handleProfilePic}
//       />
//     </>
//   );
// };
export const InputComponent = ({
  label,
  placeholder,
  type,
  containerStyle,
  error,
  errorMessage,
  rightIcon,
  inputStyle,
  ...props
}) => {
  return (
    <div
      className="input-container"
      style={{
        ...containerStyle,
      }}
    >
      <div
        style={{
          fontSize: 14,
          color: "#424242",
          marginLeft: "12px",
        }}
        className="text-sm tracking-wide font-DMSans"
      >
        {label}
      </div>
      <div
        style={{
          display: "flex",
          position: "relative",
        }}
      >
        <input
          style={{
            borderRadius: "12px",
            backgroundColor: "#fff",
            border: "1px solid #CCC",
            paddingRight: rightIcon ? "42px" : "0px",
            ...inputStyle,
          }}
          className="text-placeholder h-[48px] px-4 w-full text-lg mt-[8px] font-DMSans border focus:outline-none focus:border-indigo-500"
          type={type}
          placeholder={placeholder}
          {...props}
        />

        {rightIcon && (
          <div
            style={{
              position: "absolute",
              right: "12px",
              top: "50%",
              transform: "translateY(-28%)",
              cursor: "pointer",
            }}
          >
            {rightIcon}
          </div>
        )}
      </div>
      <ShowError error={error} errorMessage={errorMessage} />
    </div>
  );
};

export const MultilineInputComponent = ({
  label,
  placeholder,
  type,
  containerStyle,
  error,
  errorMessage,
  rightIcon,
  inputStyle,
  numberOfLines,
  disabled,
  value,
  ...props
}) => {

  return (
    <div
      className="input-container"
      style={{
        ...containerStyle,
      }}
    >
      <div
        style={{
          fontSize: 14,
          color: "#424242",
          marginLeft: "12px",
        }}
        className="text-sm tracking-wide font-DMSans"
      >
        {label}
      </div>
      <div
        style={{
          display: "flex",
          position: "relative",
        }}
      >
        <textarea
          style={{
            borderRadius: "12px",
            backgroundColor: "#fff",
            border: "1px solid #CCC",
            paddingRight: rightIcon ? "42px" : "0px",
            minHeight: "96px",
            ...inputStyle,
          }}
          className="text-placeholder h-[96px] py-2 px-4 w-full text-lg mt-[8px] font-DMSans border focus:outline-none focus:border-indigo-500"
          type={type}
          placeholder={placeholder}
          disabled={disabled ? true : false}
          numberOfLines={numberOfLines ?? 2}
          {...props}
          value={value}
        />

        {rightIcon && (
          <div
            style={{
              position: "absolute",
              right: "12px",
              top: "50%",
              transform: "translateY(-28%)",
              cursor: "pointer",
            }}
          >
            {rightIcon}
          </div>
        )}
      </div>
      <ShowError error={error} errorMessage={errorMessage} />
    </div>
  );
};

export const ShowError = ({ error, errorMessage }) => {
  return error ? (
    <div
      style={{
        fontSize: 14,
        color: "#ff0000",
        marginLeft: "12px",
      }}
      className="text-sm tracking-wide font-DMSans"
    >
      {errorMessage}
    </div>
  ) : null;
};

const countryCodes = [
  { country: "Argentina", code: "AR" },
  { country: "Australia", code: "AU" },
  { country: "Austria", code: "AT" },
  { country: "Belgium", code: "BE" },
  { country: "Bolivia", code: "BO" },
  { country: "Brazil", code: "BR" },
  { country: "Bulgaria", code: "BG" },
  { country: "Canada", code: "CA" },
  { country: "Chile", code: "CL" },
  { country: "Colombia", code: "CO" },
  { country: "Costa Rica", code: "CR" },
  { country: "Croatia", code: "HR" },
  { country: "Cyprus", code: "CY" },
  { country: "Czech Republic", code: "CZ" },
  { country: "Denmark", code: "DK" },
  { country: "Dominican Republic", code: "DO" },
  { country: "Egypt", code: "EG" },
  { country: "Estonia", code: "EE" },
  { country: "Egypt", code: "EG" },
  { country: "Finland", code: "FI" },
  { country: "France", code: "FR" },
  { country: "Germany", code: "DE" },
  { country: "Greece", code: "GR" },
  { country: "Hong Kong SAR China", code: "HK" },
  { country: "Hungary", code: "HU" },
  { country: "Iceland", code: "IS" },
  { country: "India", code: "IN" },
  { country: "Indonesia", code: "ID" },
  { country: "Ireland", code: "IE" },
  { country: "Israel", code: "IL" },
  { country: "Italy", code: "IT" },
  { country: "Japan", code: "JP" },
  { country: "Latvia", code: "LV" },
  { country: "Liechtenstein", code: "LI" },
  { country: "Lithuania", code: "LT" },
  { country: "Luxembourg", code: "LU" },
  { country: "Malta", code: "MT" },
  { country: "Mexico ", code: "MX" },
  { country: "Netherlands", code: "NL" },
  { country: "New Zealand", code: "NZ" },
  { country: "Norway", code: "NO" },
  { country: "Paraguay", code: "PY" },
  { country: "Peru", code: "PE" },
  { country: "Philippines", code: "PH" },
  { country: "Poland", code: "PL" },
  { country: "Portugal", code: "PT" },
  { country: "Romania", code: "RO" },
  { country: "Singapore", code: "SG" },
  { country: "Slovakia", code: "SK" },
  { country: "Slovenia", code: "SI" },
  { country: "Spain", code: "ES" },
  { country: "Sweden", code: "SE" },
  { country: "Switzerland", code: "CH" },
  { country: "Thailand", code: "TH" },
  { country: "Trinidad & Tobago", code: "TT" },
  { country: "United Arab Emirates", code: "AE" },
  { country: "United Kingdom", code: "GB" },
  { country: "United States", code: "US" },
  { country: "Uruguay", code: "UY" },
  { country: "South Africa", code: "ZA" },

];
