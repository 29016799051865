import React from "react";
import { useSelector } from "react-redux";

const SummaryInfo = ({ data, computedMetrics }) => {
  const coachID = useSelector((state) => state.auth.trainerUUID);

  let effortLevel = computedMetrics?.effort_level;
  let fatigueLevel = computedMetrics?.fatigue_level;

  let rtss = computedMetrics?.front_block_metrics?.rTSS;
  let tss = computedMetrics?.front_block_metrics?.tss;
  let invrtss = computedMetrics?.front_block_metrics?.invrTSS;

  return (
    <div className="rounded-xl p-4 bg-gradient-to-r from-[#F5FEF1] to-[#FFFFFF] border border-[#EBEBEB]">
      <div className="flex max-w-fit font-DMSans text-font16 font-normal text-[#424242] border border-[#EBEBEB] rounded-lg px-4 py-1 bg-white-pure">
        {data?.activityName}
      </div>
      {/* run icon */}
      <div className="flex items-center gap-5 mt-2">
        <img
          width="50"
          height="50"
          src="https://img.icons8.com/ios-filled/50/running.png"
          alt="running"
        />

        <div className="flex items-center gap-2">
          <p className="font-DMSans text-font24 font-bold">
            {formatTime(data?.durationInSeconds)}
          </p>
          <p className="font-DMSans text-font16 font-normal text-[#424242]">
            Total Time
          </p>
        </div>

        <div className="flex items-center gap-2">
          <p className="font-DMSans text-font24 font-bold">
            {formatDistance(data?.distanceInMeters)}
          </p>
          <p className="font-DMSans text-font16 font-normal text-[#424242]">
            kms
          </p>
        </div>

        {fatigueLevel && (
          <div className="flex items-center gap-2">
            <p className="font-DMSans text-font24 font-bold">
              {fatigueLevel.toFixed(2)}
            </p>
            <p className="font-DMSans text-font16 font-normal text-[#424242]">
              Fatigue
            </p>
          </div>
        )}
        {/* 
        {effortLevel && (
          <div className="flex items-center gap-2">
            <p className="font-DMSans text-font24 font-bold">
              {Math.round(effortLevel)}
            </p>
            <p className="font-DMSans text-font16 font-normal text-[#424242]">
              Effort
            </p>
          </div>
        )} */}
        {invrtss && (
          <div className="flex items-center gap-2">
            <p className="font-DMSans text-font24 font-bold">
              {invrtss.toFixed(2)}
            </p>
            <p className="font-DMSans text-font16 font-normal text-[#424242]">
              rTSS
            </p>
          </div>
        )}
        {/* {coachID === "ErzsyqqduVWs" && tss && (
          <div className="flex items-center gap-2">
            <p className="font-DMSans text-font24 font-bold">
              {tss.toFixed(2)}
            </p>
            <p className="font-DMSans text-font16 font-normal text-[#424242]">
              TSS
            </p>
          </div>
        )}
        {coachID === "ErzsyqqduVWs" && invrtss && (
          <div className="flex items-center gap-2">
            <p className="font-DMSans text-font24 font-bold">
              {invrtss.toFixed(2)}
            </p>
            <p className="font-DMSans text-font16 font-normal text-[#424242]">
              invrTSS
            </p>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default SummaryInfo;

const formatTime = (seconds) => {
  if (!seconds) return null;
  let hours = Math.floor(seconds / 3600);
  let minutes = Math.floor((seconds % 3600) / 60);
  let remainingSeconds = seconds % 60;

  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
};
const formatDistance = (meters) => {
  if (!meters) return null;
  return (meters / 1000).toFixed(2);
};
