import { CustomAlertContext } from "@/App";
import { RenderWorkouts } from "@/pages/library/showWorkoutsCF";
import FollowThroughView from "@/pages/library/showWorkoutsCF/FollowThroughView";
import RenderMeal from "@/pages/library/showWorkoutsCF/RenderMeal";
import ShowEnduranceWorkouts from "@/pages/library/showWorkoutsCF/ShowEnduranceWorkouts";
import WorkoutContentPage from "@/pages/library/showWorkoutsCF/WorkoutContentPage";
import { DeleteRounded } from "@mui/icons-material";
import { Dialog, DialogContent, Typography } from "@mui/material";
import axios from "axios";
import { debounce } from "lodash";
import { ArrowLeft } from "lucide-react";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import toast from "../../components/Toast/toast";
import SpinnerComponent from "../layout/spinner";
import SearchWorkoutDialogeForCompare from "./SearchWorkoutDialogeForCompare";
import CompareWorkoutDialoge from "./CompareWorkoutDialoge";

function ShowAssignedwWorkoutDialoge({
  open,
  setShowDialoge,
  primaryKey: id,
  isCalendar,
  date,
  userUUID,
  isThresholdAvailable: ita,
  meals,
  assignedhabits,
  enduranceData: propsEnduranceData,
  setProfileDataFromClientsView,
  profileDataFromClientsView,
  userInfo,
  workoutToBeShown,
  currentMonthEnduranceData,
}) {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const [workoutList, setworkoutList] = useState(
    workoutToBeShown ? workoutToBeShown : []
  );
  const [anyCompletedWorkout, setAnyCompletedWorkout] = useState(false);
  const [Meals, setMeals] = useState(meals || null);
  const [habits, setHabits] = useState(
    assignedhabits ? assignedhabits?.[0]?.activitiesAssigned : null
  );
  const idOfHabits = assignedhabits?.[0]?.id;
  const [enduranceData, setEnduranceData] = useState(
    propsEnduranceData ? propsEnduranceData : null
  );
  const [activityArray, setActivityArray] = useState(
    assignedhabits ? assignedhabits : []
  );
  const [dailyTargets, setDailyTargets] = useState(null);
  const [mF, setMF] = useState(null);
  const [sleep, setSleep] = useState(null);
  const [customActivities, setCustomActivites] = useState({});
  const [enduranceWorkouts, setEnduranceWorkouts] = useState([]);
  const isImport = false;
  const { displayAlert } = useContext(CustomAlertContext);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [selectedTag, setSelectedTag] = useState("Workout Details");
  const [goingBack, setGoingBack] = useState(false);
  const [compareSearchWorkout, setCompareSearchWorkout] = useState(false);
  const [selectedWorkoutToCompare, setSelectedWorkoutToCompare] = useState({});
  const [openCompareWorkoutDialoge, setOpenCompareWorkoutDialoge] =
    useState(false);
  const [workoutFromCalenderForCompare, setWorkoutFromCalenderForCompare] =
    useState({});
  let filterTa = ["Workout Details", "Meals", "Habits"];
  let filterTag = [
    { Label: "Workout Details", value: "Workout Details" },
    { Label: "Meals", value: "Meals" },
    { Label: "Habits", value: "Habbits" },
  ];
  let PrimaryKey = id;
  const Date = moment(date);

  const deleteMealPlan = async (mealID) => {
    setLoading(true);
    await axios({
      method: "post",
      url: `${APIURL}CF/api/deleteMealAssigned`,
      data: {
        id: mealID,
      },
    })
      .then((res) => {
        console.log("deleteMealPlan: ", res);
        updatingTheProfileDataOFClientsViewForDeleteMealPlans(mealID);
        let remainingMealPlans = res.data?.result?.remainingMealPlans ?? [];
        setMeals(remainingMealPlans);

        if (!remainingMealPlans || remainingMealPlans.length <= 0) {
          if (workoutList?.length <= 0) {
            debounceGoBack();
          }
        }

        toast("Meal Plan Deleted!", "success");
      })
      .catch((err) => {
        displayAlert("Network error, please try again");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function updatingTheProfileDataOFClientsViewForDeleteWorkout(workoutId) {
    const newProfileData = { ...profileDataFromClientsView };
    const workouts = newProfileData?.currentMonthmodules?.[date];
    console.log("Workoutsdel", workouts);
    if (workouts) {
      const newWorkouts = workouts.filter((workout) => {
        return workout?.id !== workoutId;
      });
      console.log("Workoutsdel", newWorkouts);
      newProfileData.currentMonthmodules[date] = newWorkouts;
    } else {
      console.log("Workouts not found in profile data");
    }
    setProfileDataFromClientsView(newProfileData);
  }

  function updatingTheProfileDataOFClientsViewForEditWorkout(UpdatedWorkout) {
    const newProfileData = { ...profileDataFromClientsView };
    const workouts = newProfileData?.currentMonthmodules?.[date];
    console.log("Workoutsdel", UpdatedWorkout, workouts);
    if (workouts) {
      // Map through the workouts and update the one with the matching ID
      const newWorkouts = workouts.map((workout) => {
        if (workout.workoutAssignedID === UpdatedWorkout.id) {
          return {
            ...workout,
            workoutAssigned: UpdatedWorkout,
          };
        }
        return workout;
      });

      // Log the new workouts array
      console.log("Workouts after update:", newWorkouts);

      // Update the profile data with the new workouts array
      newProfileData.currentMonthmodules[date] = newWorkouts;
    } else {
      console.log("Workouts not found in profile data");
    }
    setProfileDataFromClientsView(newProfileData);
  }

  const updatingTheProfileDataOfClientViewForUpdatedWorkout = (
    updatedWorkout
  ) => {
    const newProfileData = { ...profileDataFromClientsView };
    const workouts = newProfileData?.currentMonthmodules?.[date];
    if (workouts) {
      const newWorkouts = workouts.map((workout) => {
        if (workout?.id === updatedWorkout?.id) {
          return updatedWorkout;
        }
        return workout;
      });
      newProfileData.currentMonthmodules[date] = newWorkouts;
    } else {
      console.log("Workouts not found in profile data");
    }
    setProfileDataFromClientsView(newProfileData);
  };

  function updatingTheProfileDataOFClientsViewForDeleteHabits(habitsId) {
    const newProfileData = { ...profileDataFromClientsView };
    const habits = newProfileData?.activityData?.[date];
    if (habits) {
      console.log("habitIdsin", habits);
      const newHabits = habits.filter((habit) => {
        return habit.id !== habitsId;
      });
      newProfileData.activityData[date] = newHabits;
    } else {
      console.log("Workouts not found in profile data");
    }
    setProfileDataFromClientsView(newProfileData);
  }

  function updatingTheProfileDataOFClientsViewForDeleteMealPlans(MealId) {
    const newProfileData = { ...profileDataFromClientsView };
    const meals = newProfileData?.mealData?.[date];
    if (meals) {
      console.log("habitIdsin", meals);
      const newMeals = meals.filter((meal) => {
        return meal.id !== MealId;
      });
      newProfileData.mealData[date] = newMeals;
    } else {
      console.log("Workouts not found in profile data");
    }
    setProfileDataFromClientsView(newProfileData);
  }

  const deleteIndividualWorkout = async (workoutID, isEndurance) => {
    console.log("primaryKey", id);
    let primaryKeyArray = JSON.parse(id).filter((pk) => pk !== workoutID);
    console.log("Workoutsdel", workoutList);

    setLoading(true);
    console.log("PK arr", workoutID, primaryKeyArray, isEndurance);
    await axios({
      method: "post",
      url: `${APIURL}CF/api/deleteAssignedWorkoutAthelete`,
      data: {
        assignedWorkoutID: workoutID,
        newOrderWorkoutIDs: primaryKeyArray,
        isEnduranceWorkout: isEndurance,
      },
    })
      .then((res) => {
        updatingTheProfileDataOFClientsViewForDeleteWorkout(workoutID);
        setReload(!reload);
        toast("Workout Deleted!", "success");
      })
      .catch((err) => {
        console.error("Error deleting workout:", err);
        displayAlert("Network error, please try again");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  async function handleDeleteAssignedActivity() {
    setLoading(true);
    try {
      const res = await axios({
        method: "post",
        url: `${APIURL}cf/api/activit/DeleteActivitiesbyIDs`,
        data: {
          ids: [idOfHabits],
        },
      });
      if (res) {
        console.log("idsOfHabbit", idOfHabits, profileDataFromClientsView);
        updatingTheProfileDataOFClientsViewForDeleteHabits(idOfHabits);
        toast("Activities Deleted!", "success");
        setHabits(null);
      }
    } catch (error) {
      displayAlert("Network error, please try again");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const debounceGoBack = debounce(() => {
    if (!goingBack) {
      setGoingBack(true);
      history.goBack();
    }
  }, 1000);

  async function setApiData(primaryKeys, source) {
    if (
      primaryKeys.length <= 0 &&
      Meals?.length <= 0 &&
      enduranceData?.length <= 0
    ) {
      debounceGoBack();
    } else if (!primaryKeys || primaryKeys.length <= 0) {
      setworkoutList([]);
      setLoading(false);
    } else {
      setLoading(true);
      try {
        let res = await axios({
          method: "get",
          url: `${APIURL}CF/api/getMultiModuleDetails`,
          params: {
            primaryKeys: primaryKeys,
          },
          cancelToken: source.token,
        });
        console.log("CF modules:  : ", res.data.result);
        // separate workouts with workout kind as run, swim and bike
        let curEnduranceWorkouts = [];
        let otherWorkouts = [];

        res.data.result.forEach((workout) => {
          let workoutKind = workout?.workoutAssigned?.workoutKind;
          if (
            workoutKind == "run" ||
            workoutKind == "swim" ||
            workoutKind == "bike"
          ) {
            curEnduranceWorkouts.push(workout);
          } else {
            otherWorkouts.push(workout);
          }
        });
        console.log("CF endurance workouts: ", curEnduranceWorkouts);
        console.log("CF other workouts: ", otherWorkouts);
        setEnduranceWorkouts(curEnduranceWorkouts);
        setworkoutList(otherWorkouts);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
  }

  const getIds = () => {
    try {
      return JSON.parse(id);
    } catch (err) {
      return false;
    }
  };

  const init = async () => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    setLoading(true);
    let ids = getIds();
    isCalendar === "true"
      ? setApiData(ids, source)
      : await axios({
          method: "get",
          url: `${APIURL}CF/api/CoachWeb/Get/getWorkoutWithAllDetailsJSON`,
          params: {
            workoutID: PrimaryKey,
          },
          cancelToken: source.token,
        })
          .then((res) => {
            console.log("CF workouts: ", res.data);
            setworkoutList(res.data.result);
          })
          .catch((err) => {
            displayAlert(err);
          })
          .finally(() => {
            setLoading(false);
          });
    return () => {
      setworkoutList([]);
      source.cancel();
    };
  };

  const getLatestActivites = (sortedArray) => {
    let tempDT = {};
    let tempSleep = [];
    let tempMF = [];
    let tempCustomActivity = {};
    let keysInHabits = [];

    let assigned = sortedArray?.[0]?.activitiesAssigned;
    if (Object.keys(assigned?.dailyTargets || {}).length > 0) {
      tempDT = { ...assigned?.dailyTargets };
      console.log("sorted ha daily target", tempDT);
    }
    if (
      assigned?.habbits?.mindfulness &&
      assigned?.habbits?.mindfulness?.length > 0
    ) {
      tempMF = [...assigned?.habbits?.mindfulness];
    }
    if (assigned?.habbits?.sleep && assigned?.habbits?.sleep?.length > 0) {
      tempSleep = [...assigned?.habbits?.sleep];
    }
    keysInHabits = [...Object.keys(assigned?.habbits || {})];
    // console.log("sorted keys in habits",keysInHabits);
    keysInHabits.map((key) => {
      if (key != "sleep" && key != "mindfulness") {
        tempCustomActivity = {
          ...tempCustomActivity,
          [key]: assigned?.habbits?.[key],
        };
      }
    });

    // sortedArray.forEach((item, index) => {
    //   if (index > 0) return;
    //   console.log("sorted item in activites", item);

    // });

    setCustomActivites(tempCustomActivity);
    setDailyTargets(tempDT);
    setMF(tempMF);
    setSleep(tempSleep);
  };

  function sortObjectsByCreatedAt(array) {
    // Define a comparison function

    // Sort the array based on createdAt value
    let sortedArray = array;
    console.log("sorted array ha kya", sortedArray);
    getLatestActivites(sortedArray);
    setActivityArray(sortedArray);
  }

  useEffect(() => {
    // console.log("data ha kya", data, Meals, habits);
    init();
    sortObjectsByCreatedAt(activityArray);
  }, [reload || PrimaryKey]);

  return (
    <Dialog
      open={open}
      sx={{ padding: "0px" }}
      onClose={() => setShowDialoge(false)}
      fullScreen
    >
      <div
        style={{
          height: "77px",
          width: "100vw",
          paddingLeft: "30px",
          paddingRight: "32px",
          backgroundColor: "#f8f8f8",
          position: "relative",
        }}
      >
        <div
          className="add-program-modal-head"
          style={{
            height: "100%",
            width: "100%",
            backgroundColor: "#FFFFFF",
            borderRadius: "0px 0px 16px 16px",
            display: "flex",
            alignItems: "center",

            boxShadow: "0px 2px 5px -1px rgba(0,0,0,0.13)",
          }}
        >
          <div className="flex items-center">
            <ArrowLeft
              sx={{
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                left: 38,
                cursor: "pointer",
              }}
              onClick={() => setShowDialoge(false)}
            />

            {isCalendar && (
              <>
                {" "}
                <p
                  style={{
                    marginLeft: "32px",
                  }}
                  className="add-program-modal-head-title"
                >
                  {userInfo?.name}
                </p>
                <p
                  style={{
                    marginLeft: "32px",
                    alignSelf: "end",
                  }}
                  className="add-program-modal-head-title"
                >
                  {Date.format("D MMMM YYYY")}
                </p>
              </>
            )}
          </div>
          {isCalendar && (
            <div className="flex gap-4">
              {filterTag?.map((tagobj) => (
                <div
                  className="chip"
                  style={{
                    cursor: "pointer",
                    background:
                      selectedTag === tagobj?.value
                        ? "linear-gradient(90deg, #464feb 10.42%, #8330e9 100%)"
                        : "#F7F7F7",
                    color: selectedTag === tagobj?.value ? "#fff" : "#000",
                    minWidth: "76px",
                  }}
                  onClick={() => setSelectedTag(tagobj?.value)}
                >
                  {tagobj?.Label}{" "}
                  {/* { selectedTag === tag ? (
                    <RemoveRoundedIcon fontSize="16" />
                ) : (
                    <AddRoundedIcon fontSize="16" />
                )} */}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <DialogContent sx={{ padding: "0px" }}>
        <div className="flex flex-col w-full bg-[#F8F8F8] rounded-xl shadow-lg font-semibold text-font24 px-10 relative">
          <div className="flex w-full justify-between text-blue-main mt-4">
            {selectedTag === "Workout Details" &&
              isCalendar === "true" &&
              (workoutList?.length > 0 || enduranceWorkouts.length > 0) && (
                <>
                  <Typography
                    textAlign={"center"}
                    sx={{
                      width: "100%",
                      fontWeight: 600,
                      fontSize: "20px",
                    }}
                    align="right"
                  >
                    Assigned
                  </Typography>

                  <Typography
                    textAlign={"center"}
                    sx={{
                      width: "100%",
                      fontFamily: "DM Sans",
                      marginBottom: "16px",
                      fontWeight: 600,
                      fontSize: "20px",
                    }}
                    align="right"
                  >
                    Completed
                  </Typography>
                </>
              )}
          </div>
          {selectedTag === "Workout Details" && (
            <div
              className="flex flex-col justify-center items-center mx-auto"
              style={{
                width: "100%",
                justifyContent: "center",
              }}
            >
              {isCalendar === "true" ? (
                <>
                  <ShowEnduranceWorkouts
                    workouts={enduranceWorkouts}
                    isImport={isImport}
                    date={date}
                    userUUID={userUUID}
                    isCalendar={isCalendar}
                    deleteIndividualWorkout={deleteIndividualWorkout}
                    init={init}
                    setLoading={setLoading}
                    finishedData={enduranceData}
                    currentMonthEnduranceData={currentMonthEnduranceData}
                    updateWorkout={(data) => {
                      updatingTheProfileDataOfClientViewForUpdatedWorkout(data);
                      // update endurace workout

                      let updatedEnduranceWorkouts = enduranceWorkouts.map(
                        (workout) => {
                          if (workout?.id === data?.id) {
                            return data;
                          }
                          return workout;
                        }
                      );
                      updatingTheProfileDataOFClientsViewForEditWorkout(data);
                      setEnduranceWorkouts(updatedEnduranceWorkouts);
                      setReload(!reload);
                    }}
                    thresholdValues={userInfo?.thresholdValues}
                    compareSearchWorkout={compareSearchWorkout}
                    setCompareSearchWorkout={setCompareSearchWorkout}
                    setWorkoutFromCalenderForCompare={
                      setWorkoutFromCalenderForCompare
                    }
                  />
                  {workoutList.map((workout, index) => {
                    if (!anyCompletedWorkout && workout?.workoutFinished) {
                      setAnyCompletedWorkout(true);
                    }
                    return workout?.workoutAssigned?.isStructured !== false ? (
                      <RenderWorkouts
                        workout={workout}
                        isImport={isImport}
                        date={date}
                        setReload={setReload}
                        userUUID={userUUID}
                        isCalendar={isCalendar}
                        deleteIndividualWorkout={deleteIndividualWorkout}
                        index={index}
                        initCall={init}
                        setLoading={setLoading}
                        ita={ita}
                        key={workout?.id}
                        updateWorkoutInfoForCalender={
                          updatingTheProfileDataOFClientsViewForEditWorkout
                        }
                      />
                    ) : (
                      <RenderUnstructuredWorkouts
                        workout={workout}
                        isImport={isImport}
                        date={date}
                        userUUID={userUUID}
                        isCalendar={isCalendar}
                        deleteIndividualWorkout={deleteIndividualWorkout}
                        index={index}
                        key={workout?.id}
                        setReload={setReload}
                        updateWorkoutInfoForCalender={
                          updatingTheProfileDataOFClientsViewForEditWorkout
                        }
                        // location={props?.location}
                      />
                    );
                  })}
                </>
              ) : (
                <>
                  {workoutList.isStructured !== false ? (
                    <WorkoutContentPage
                      rounds={workoutList?.workoutContent?.rounds}
                      data={workoutList}
                      setData={setworkoutList}
                      isImport={isImport === "true"}
                      date={date}
                      userUUID={userUUID}
                      isThresholdAvailable={ita}
                      isCalendar={isCalendar}
                      showBackButton={false}
                      canDelete={false}
                      showEditButton={false}
                    />
                  ) : (
                    <FollowThroughView
                      data={workoutList}
                      userUUID={userUUID}
                      showBackButton={false}
                      isImport={isImport === "true"}
                      date={date}
                      isCalendar={isCalendar}
                      canDelete={false}
                      showEditButton={false}
                    />
                  )}
                </>
              )}
            </div>
          )}

          {selectedTag === "Meals" &&
            !loading &&
            Meals &&
            Meals?.length > 0 && (
              <div
                style={{
                  marginTop: "16px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                {Meals.map((meal, index) => {
                  let item = meal.item;
                  console.log("meal: ", item);
                  let feedback = item?.mealFinishedDetails?.meals
                    ? item?.mealFinishedDetails?.meals[index]?.feedback
                    : item?.mealFinishedDetails?.plandetails?.meals[index]
                        ?.feedback;
                  return item?.unsavedMeals ? (
                    <RenderMeal
                      meal={item?.unsavedMeals}
                      feedback={item?.feedback}
                      mealID={item?.id}
                      isFinished={item?.isMealFinished}
                    />
                  ) : (
                    <RenderMeal
                      deleteMealPlan={deleteMealPlan}
                      meal={item?.mealFinishedDetails ?? item?.mealPlans}
                      feedback={feedback}
                      mealID={item?.id}
                      isFinished={item?.isMealFinished}
                    />
                  );
                })}
              </div>
            )}
          {selectedTag === "Habbits" &&
            !loading &&
            habits &&
            activityArray &&
            activityArray?.length > 0 && (
              <div
                style={{
                  marginTop: "16px",
                  display: "flex",
                  flexDirection: "column",
                  // backgroundColor:"black",
                  background: "white",
                  borderRadius: "8px",
                }}
              >
                <div className="flex justify-between w-full  border-b-2 border-[#D7D7D7]">
                  <Typography
                    sx={{
                      fontFamily: "DM Sans",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: "18px",
                      lineHeight: "20px",
                      color: "black",
                      padding: "15px 20px",
                    }}
                  >
                    Assigned Habits
                  </Typography>
                  <div
                    className="delete-workouts-btn mr-3 my-2 "
                    onClick={handleDeleteAssignedActivity}
                  >
                    <DeleteRounded className="delete-btn-icon-in-showWorkouts" />
                  </div>
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "auto auto auto",
                  }}
                >
                  {dailyTargets && Object.keys(dailyTargets).length > 0 && (
                    <div
                      className="flex justify-start items-left flex-col"
                      style={{
                        padding: "8px 20px",
                        borderLeft: "2px solid #D7D7D7",
                        borderBottom: "2px solid #D7D7D7",
                      }}
                    >
                      <h3
                        style={{
                          color: "#8330e9",
                          fontFamily: "DM Sans",
                          fontSize: "16px",
                          fontWeight: "550",
                          marginBottom: "2px",
                        }}
                      >
                        Daily Targets
                      </h3>
                      {Object.keys(dailyTargets).map((key, index) => {
                        const obj = dailyTargets[key];
                        return (
                          <div
                            key={index}
                            className="flex items-center w-full justify-start"
                          >
                            <input
                              type="checkbox"
                              name="daily targets"
                              id="daily-targets"
                              checked={obj.completed}
                              className="mr-3"
                            />
                            <p
                              style={{
                                color: "black",
                                fontFamily: "DM Sans",
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              {key == "sleep" && `${obj.todo}hrs of Sleep`}
                              {key == "steps" && `${obj.todo} Steps`}
                              {key == "water" && `${obj.todo} ltr of Water`}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {mF && mF.length > 0 && (
                    <div
                      className="flex justify-start items-left flex-col"
                      style={{
                        borderLeft: "2px solid #D7D7D7",
                        padding: "8px 20px",
                        borderBottom: "2px solid #D7D7D7",
                      }}
                    >
                      <h3
                        style={{
                          color: "#8330e9",
                          fontFamily: "DM Sans",
                          fontSize: "16px",
                          fontWeight: "550",
                          marginBottom: "2px",
                        }}
                      >
                        Mind Fulness
                      </h3>
                      <div className="flex items-start justify-start flex-col">
                        {mF?.map((obj, index) => {
                          return (
                            <div
                              key={index}
                              className="flex items-left w-full justify-start"
                            >
                              <input
                                type="checkbox"
                                name="daily targets"
                                id="daily-targets"
                                checked={obj?.completed}
                                className="mr-3"
                              />
                              <p
                                style={{
                                  color: "black",
                                  fontFamily: "DM Sans",
                                  fontSize: "16px",
                                  fontWeight: "500",
                                }}
                              >
                                {obj?.todo}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                  {sleep && sleep.length > 0 && (
                    <div
                      className="flex justify-start items-left flex-col"
                      style={{
                        borderLeft: "2px solid #D7D7D7",
                        padding: "8px 20px",
                        borderBottom: "2px solid #D7D7D7",
                      }}
                    >
                      <h3
                        style={{
                          color: "#8330e9",
                          fontFamily: "DM Sans",
                          fontSize: "16px",
                          fontWeight: "550",
                          marginBottom: "2px",
                        }}
                      >
                        Sleep
                      </h3>
                      {sleep?.map((obj, index) => {
                        return (
                          <div
                            key={index}
                            className="flex items-center w-full justify-start"
                          >
                            <input
                              type="checkbox"
                              name="daily targets"
                              id="daily-targets"
                              checked={obj?.completed}
                              className="mr-3"
                            />
                            <p
                              style={{
                                color: "black",
                                fontFamily: "DM Sans",
                                fontSize: "16px",
                                fontWeight: "500",
                              }}
                            >
                              {obj?.todo}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {/* showing custom activities */}
                  {Object.keys(customActivities).map((objc) => {
                    console.log("habbits ke andar ke andar", objc);
                    return (
                      customActivities?.[objc]?.length > 0 && (
                        <div
                          className="flex justify-start items-left flex-col"
                          style={{
                            borderLeft: "2px solid #D7D7D7",
                            padding: "8px 20px",
                            borderBottom: "2px solid #D7D7D7",
                          }}
                        >
                          <h3
                            style={{
                              color: "#8330e9",
                              fontFamily: "DM Sans",
                              fontSize: "16px",
                              fontWeight: "550",
                              marginBottom: "2px",
                            }}
                          >
                            {objc}
                          </h3>
                          <div className="flex items-start justify-start flex-col">
                            {customActivities?.[objc].map((obj, index) => {
                              return (
                                <div
                                  key={index}
                                  className="flex items-left w-full justify-start"
                                >
                                  <input
                                    type="checkbox"
                                    name="daily targets"
                                    id="daily-targets"
                                    checked={obj?.completed}
                                    className="mr-3"
                                  />
                                  <p
                                    style={{
                                      color: "black",
                                      fontFamily: "DM Sans",
                                      fontSize: "16px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {obj?.todo}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )
                    );
                  })}
                </div>
              </div>
            )}
        </div>
        {loading && (
          <div
            style={{
              width: "100%",
              position: "absolute",
              height: "100vh",
              background: "rgba(255,255,255, 0.3)",
              zIndex: 999,
              top: 0,
              left: 0,
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "40%",
                left: "45%",
                background: "#eee",
                width: 200,
                zIndex: 9999,
                padding: 50,
                borderRadius: 15,
              }}
            >
              <SpinnerComponent />
            </div>
          </div>
        )}
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          zindex="999999"
        />
        {selectedTag === "Workout Details" &&
          workoutList?.length === 0 &&
          enduranceWorkouts.length === 0 && (
            <div className=" flex  mt-5 justify-center font-bold text-[16px] text-[#464feb]">
              There is no Assigned Workout
            </div>
          )}
        {selectedTag === "Meals" && Meals?.length === 0 && (
          <div className=" flex   justify-center font-bold text-[16px] text-[#464feb] mt-5">
            There is no Assigned Meal Plan
          </div>
        )}
        {selectedTag === "Habbits" && activityArray?.length === 0 && (
          <div className=" flex   justify-center font-bold text-[16px] text-[#464feb] mt-5">
            There is no Assigned Activity{" "}
          </div>
        )}
        {compareSearchWorkout && (
          <SearchWorkoutDialogeForCompare
            open={compareSearchWorkout}
            setOpen={setCompareSearchWorkout}
            currentMonthEnduranceData={currentMonthEnduranceData}
            setSelectedWorkoutToCompare={setSelectedWorkoutToCompare}
            userUUID={userUUID}
            date={date}
            onSuccess={() => {
              setCompareSearchWorkout(false);
              setOpenCompareWorkoutDialoge(true);
            }}
          />
        )}
        {openCompareWorkoutDialoge && (
          <CompareWorkoutDialoge
            open={openCompareWorkoutDialoge}
            setOpen={setOpenCompareWorkoutDialoge}
            selectedWorkoutToCompare={selectedWorkoutToCompare}
            workoutFromCalender={workoutFromCalenderForCompare}
            userInfo={userInfo}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}

export default ShowAssignedwWorkoutDialoge;

const RenderUnstructuredWorkouts = ({
  workout,
  deleteIndividualWorkout,
  index,
  isImport,
  date,
  userUUID,
  isCalendar,
  setReload,
  updateWorkoutInfoForCalender,
}) => {
  const finishedRef = React.useRef(null);

  return (
    <div className="flex relative justify-between w-full">
      <FollowThroughView
        data={workout?.workoutAssigned}
        deleteIndividualWorkout={deleteIndividualWorkout}
        workoutID={workout?.id}
        key={"assigned"}
        showBackButton={false}
        topRefToUse={finishedRef}
        isImport={isImport}
        date={date}
        userUUID={userUUID}
        isCalendar={isCalendar}
        isFinished={workout?.workoutFinished}
        setReload={setReload}
        updateWorkoutInfoForCalender={updateWorkoutInfoForCalender}
        showEditBtn={true}
      />
    </div>
  );
};

const checkIsActivityAvialable = (activities) => {
  if (activities && activities[0]?.activitiesAssigned) {
    let activityAssigned = activities[0]?.activitiesAssigned;
    if (
      activityAssigned &&
      (Object.keys(activityAssigned?.dailyTarget ?? {}).length > 0 ||
        Object.keys(activityAssigned?.habbits ?? {}).length > 0)
    ) {
      return true;
    }
  }
  return false;
};
