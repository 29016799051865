import React from "react";
import NoWorkoutsImage from "../../assets/workouts/NoWorkouts.svg";

const NoAffiliateView = ({
    setShowAddTrainer,
  message = "",
  subMessage,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "auto",
        marginBottom: "auto",
        padding: "24px",
        width:"100%",
        height:"100% ",
      }}
    >
      <img
        style={{
          width: "128px",
          height: "128px",
        }}
        src={NoWorkoutsImage}
        alt="No affiliates coaches"
      />
      <div style={{ marginTop: "24px" }} className="exercise-library-title">
        {message ?? "No Affiliate Added"}
      </div>
      <div className="no-affiliates-text">
        {subMessage ??
          "There are no invites to display, click the below button to add a affiliate coach"}
      </div>

      <button
        style={{
          marginTop: "16px",
        }}
        className="button-new"
        onClick={() => setShowAddTrainer(true)}
      >
        Add Affiliate 
      </button>
    </div>
  );
};

export default NoAffiliateView;
