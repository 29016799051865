import React from "react";
import Checkbox from "@mui/material/Checkbox";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { NativeSelect, TextareaAutosize } from "@mui/material";
import BasicTextFields from "../../components/TextFeild/BasicTextFields";
import isEmpty from "lodash/isEmpty";
const style = { textAlign: "center" };
export const ExercisesListTable = ({
  uuidDropdown,
  row,
  handleChange,
  showOnly,
  isDropDown,
  index,
  header,
  provided,
  superSet,
  isDragging,
}) => {
  const workoutName = row?.Name || row?.name || row?.exercise;

  const disableMapperReps = (row) => {
    if (showOnly) {
      return true;
    } else if (row.duration > 0 && row.reps > 0) {
      return false;
    } else if (row.duration > 0) {
      return true;
    }
  };

  const disableMapperDuration = (row) => {
    if (showOnly) {
      return true;
    } else if (row.duration > 0 && row.reps > 0) {
      return false;
    } else if (row.reps > 0) {
      return true;
    }
  };
  return (
    <TableRow
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      key={row.Name || row.name}
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
        backgroundColor: isDropDown || row.isSet ? "#FAFAFF" : "white",
        cursor: "move",
        height: "2px",
        border: isDragging ? "1px solid gray" : "",
      }}
    >
      <TableCell component="th" scope="row">
        <div className="flex items-center cursor-move">
          {!isDropDown ? (
            <>
              {!showOnly && (
                <>
                  <MoreVertIcon />
                  <Checkbox
                    onChange={handleChange({
                      id: row?.uuidv4,
                      name: "checkbox",
                      uuidDropdown,
                    })}
                    color="primary"
                    checked={row?.checkbox}
                  />
                </>
              )}
              <img
                src={row?.ImageLink || row?.imageLink || row?.imageKey}
                style={{
                  width: "50px",
                  height: "50px",
                  margin: "0px 15px",
                  borderRadius: 5,
                  objectFit: "cover",
                }}
              />
            </>
          ) : (
            <p className="mx-12 px-2 py-1 rounded-full w-21 h-22 text-white-pure bg-blue-dark text-center relative">
              <span
                className="absolute"
                style={{ marginTop: "-3px", left: "5px" }}
              >
                {index}
              </span>
            </p>
          )}

          <p>{row?.Name || row?.name || row?.exercise}</p>
        </div>
      </TableCell>
      <TableCell align="right">
        {workoutName && workoutName !== "Break" && (
          <BasicTextFields
            showOnly={showOnly == true}
            style={style}
            value={row.sets}
            onChange={handleChange({
              id: row?.uuidv4,
              name: "sets",
              isSet: header,
              uuidDropdown,
              superSet,
            })}
            width={50}
          />
        )}
      </TableCell>
      <TableCell align="right">
        {!row.isSet &&
          workoutName &&
          workoutName !== "Break" &&
          workoutName !== "SuperSet" && (
            <BasicTextFields
              showOnly={disableMapperReps(row)}
              style={style}
              value={row.reps || row.Reps}
              onChange={handleChange({
                id: row?.uuidv4,
                name: "reps",
                uuidDropdown,
              })}
              width={50}
            />
          )}
      </TableCell>
      <TableCell align="right">
        {!row.isSet && workoutName && workoutName !== "SuperSet" && (
          <BasicTextFields
            showOnly={disableMapperDuration(row)}
            style={style}
            value={row.duration || row.Duration}
            onChange={handleChange({
              id: row?.uuidv4,
              name: "duration",
              uuidDropdown,
              workoutName,
            })}
            width={50}
            min={workoutName == "Break" ? 1 : 0}
          />
        )}
      </TableCell>
      <TableCell align="right">
        {!row.isSet &&
          workoutName &&
          workoutName !== "Break" &&
          workoutName !== "SuperSet" && (
            <BasicTextFields
              showOnly={showOnly == true}
              style={style}
              value={row.weights || row.Weights}
              onChange={handleChange({
                id: row?.uuidv4,
                name: "weights",
                uuidDropdown,
              })}
              width={50}
            />
          )}
      </TableCell>
      <TableCell align="right">
        {!row.isSet &&
          workoutName &&
          workoutName !== "Break" &&
          workoutName !== "SuperSet" && (
            <NativeSelect
              disabled={showOnly === true}
              value={row?.weightUnits || row?.WeightUnits}
              onChange={handleChange({
                id: row?.uuidv4,
                name: "weightUnits",
                uuidDropdown,
              })}
              id="select"
              style={{ width: 60, outline: "none" }}
            >
              <option style={{ textAlign: "center" }} value="lbs">
                lbs
              </option>
              <option style={{ textAlign: "center" }} value="Kg">
                Kg
              </option>
            </NativeSelect>
          )}
      </TableCell>
      <TableCell align="right">
        {!row.isSet &&
          workoutName &&
          workoutName !== "Break" &&
          workoutName !== "SuperSet" && (
            <TextareaAutosize
              disabled={showOnly === true}
              aria-label="minimum height"
              minRows={3}
              placeholder="Notes"
              value={row?.notes}
              style={{ width: 60, backgroundColor: "#F7F7FF", outline: "none" }}
              onChange={handleChange({
                id: row?.uuidv4,
                name: "notes",
                uuidDropdown,
              })}
            />
          )}
      </TableCell>
    </TableRow>
  );
};

export const ExercisesListDropdown = ({
  data,
  handleChange,
  showOnly,
  provided,
  isDragging,
}) => {
  return (
    <>
      <ExercisesListTable
        header={true}
        row={data}
        handleChange={handleChange}
        showOnly={showOnly}
        provided={provided}
        superSet={data?.superSet}
        isDragging={isDragging}
      />
      {data?.set &&
        data?.set?.map((row, idx) => {
          return (
            <>
              <ExercisesListTable
                provided={provided}
                isDropDown={true}
                uuidDropdown={data?.uuidv4}
                row={row}
                index={idx + 1}
                handleChange={handleChange}
                showOnly={showOnly}
                superSet={data?.superSet}
                isDragging={isDragging}
              />
            </>
          );
        })}
    </>
  );
};
