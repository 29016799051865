import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const ViewLeads = ({ isOpen, setIsOpen, data }) => {
  console.log("leads ", data);
  return (
    <>
      <Dialog
        sx={{
          backdropFilter: "blur(5px)",
        }}
        PaperProps={{
          sx: { borderRadius: "16px" },
        }}
        fullWidth
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <div className="add-program-modal-head">
          <p className="add-program-modal-head-title">Lead Details</p>
          <CloseRoundedIcon
            sx={{
              position: "absolute",
              right: 24,
              top: 24,
              cursor: "pointer",
            }}
            onClick={() => {
              setIsOpen(false);
            }}
          />
        </div>
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", gap: 3 }}
        >
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "33% 33% 33%",
              rowGap: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 10,
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "16px",
                  fontFamily: "DM Sans",
                  color: "#000",
                  fontWeight: 500,
                  cursor: "pointer",
                }}
              >
                Name
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  lineHeight: "16px",
                  fontFamily: "DM Sans",
                  color: "#605E5C",
                  fontWeight: 500,
                  cursor: "pointer",
                }}
              >
                {data.name}
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 10,
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "16px",
                  fontFamily: "DM Sans",
                  color: "#000",
                  fontWeight: 500,
                  cursor: "pointer",
                }}
              >
                Contact Number
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  lineHeight: "16px",
                  fontFamily: "DM Sans",
                  color: "#605E5C",
                  fontWeight: 500,
                  cursor: "pointer",
                }}
              >
                {data.contactNumber}
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 10,
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "16px",
                  fontFamily: "DM Sans",
                  color: "#000",
                  fontWeight: 500,
                  cursor: "pointer",
                }}
              >
                Email Address
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  lineHeight: "16px",
                  fontFamily: "DM Sans",
                  color: "#605E5C",
                  fontWeight: 500,
                  cursor: "pointer",
                }}
              >
                {data.email}
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                gap: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 10,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    lineHeight: "16px",
                    fontFamily: "DM Sans",
                    color: "#000",
                    fontWeight: 500,
                    cursor: "pointer",
                  }}
                >
                  Age
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    lineHeight: "16px",
                    fontFamily: "DM Sans",
                    color: "#605E5C",
                    fontWeight: 500,
                    cursor: "pointer",
                  }}
                >
                  {data.answeredForm ? data.answeredForm.age : "-"}
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 10,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    lineHeight: "16px",
                    fontFamily: "DM Sans",
                    color: "#000",
                    fontWeight: 500,
                    cursor: "pointer",
                  }}
                >
                  Gender
                </Typography>

                <Typography
                  sx={{
                    fontSize: "16px",
                    lineHeight: "16px",
                    fontFamily: "DM Sans",
                    color: "#605E5C",
                    fontWeight: 500,
                    cursor: "pointer",
                  }}
                >
                  {data.answeredForm ? data.answeredForm.gender : "-"}
                </Typography>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 10,
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "16px",
                  fontFamily: "DM Sans",
                  color: "#000",
                  fontWeight: 500,
                  cursor: "pointer",
                }}
              >
                Weight
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  lineHeight: "16px",
                  fontFamily: "DM Sans",
                  color: "#605E5C",
                  fontWeight: 500,
                  cursor: "pointer",
                }}
              >
                {data.answeredForm ? data.answeredForm.weight : "-"}
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 10,
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "16px",
                  fontFamily: "DM Sans",
                  color: "#000",
                  fontWeight: 500,
                  cursor: "pointer",
                }}
              >
                Height
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  lineHeight: "16px",
                  fontFamily: "DM Sans",
                  color: "#605E5C",
                  fontWeight: 500,
                  cursor: "pointer",
                }}
              >
                {data.answeredForm ? data.answeredForm.height : "-"}
              </Typography>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                lineHeight: "16px",
                fontFamily: "DM Sans",
                color: "#000",
                fontWeight: 500,
                cursor: "pointer",
              }}
            >
              Goals
            </Typography>

            <Typography
              sx={{
                fontSize: "16px",
                lineHeight: "16px",
                fontFamily: "DM Sans",
                color: "#605E5C",
                fontWeight: 500,
                cursor: "pointer",
              }}
            >
              {data.answeredForm ? data.answeredForm.goals : "-"}
            </Typography>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ViewLeads;
