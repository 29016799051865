import React, { memo, useState } from "react";
import OneExercise from "./OneExercise";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DropDownMenu from "../Programs/DropDownMenu";

const OneSection = ({
  block,
  onExerciseSelect,
  blockIndex,
  selectedExercise,
  selectedIntensityType,
  onRepeatChange,
  onDeleteBlock,
  onDuplicateBlock,
  onAddSubstep,
  deleteOneExercise,
  onExerciseDuplicate,
}) => {
  const [repeat, setRepeat] = useState(block?.repeat);

  return (
    <div className="bg-white-pure rounded-xl flex flex-col items-center self-stretch">
      <div
        name="header"
        className="flex items-center w-full h-[56px] px-6 border-b border-[#E5E5E5] justify-end"
      >
        {block?.repeat && (
          <div className="flex h-10 px-4 py-1.5 items-center gap-2.5 rounded-lg border border-gray-300 bg-white">
            <div className="text-gray-700 text-sm font-medium not-italic leading-normal font-DMSans flex items-center gap-1">
              <span className="text-[#828282] text-font14 font-medium font-DMSans">
                Repeat
              </span>
              <input
                key={blockIndex}
                type="number"
                min={"1"}
                className="bg-[#edecec] w-[30px] text-center outline-none rounded-sm"
                value={repeat}
                onChange={(e) => {
                  let value = e.target.value;
                  setRepeat(value);
                  if (value) {
                    onRepeatChange(value);
                  }
                }}
              />
              &nbsp;
              <span className="text-[#828282] text-font14 font-medium font-DMSans">
                times
              </span>
            </div>
          </div>
        )}

        {/* <MoreVertIcon /> */}
        <DropDownMenu
          triggerElement={
            <div className="h-10 px-2 py-1.5 bg-white-pure rounded-xl">
              <MoreVertIcon />
            </div>
          }
          options={["Delete", "Duplicate"]}
          onSelect={(option) => {
            if (option === "Delete") {
              onDeleteBlock();
            } else if (option === "Duplicate") {
              onDuplicateBlock();
            }
          }}
        />
      </div>
      <div className="px-4 py-6 w-full">
        {block?.data?.map((b, exerciseIndex) => {
          let exercise = b.exercise;
          return (
            <div>
              <OneExercise
                key={exercise?.name}
                exercise={exercise}
                isSelected={
                  selectedExercise.blockIndex === blockIndex &&
                  selectedExercise.exerciseIndex === exerciseIndex
                }
                onExerciseSelect={() => onExerciseSelect(exerciseIndex)}
                onDeleteExercise={() => deleteOneExercise(exerciseIndex)}
                selectedIntensityType={selectedIntensityType}
                onExerciseDuplicate={() => onExerciseDuplicate(exerciseIndex)}
              />

              <AddSubstepButton onClick={() => onAddSubstep(exerciseIndex)} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default memo(OneSection);

const AddSubstepButton = ({ onClick }) => {
  return (
    <div className="w-full h-[32px] relative flex items-center justify-center my-[16px]">
      <div className="h-[1px] w-full bg-[#E5E5E5] absolute z-0"></div>

      <div
        className="z-10 flex min-w-fit mx-auto h-[32px] p-2 items-center space-x-2 rounded-full border-2 border-[#464FEB] bg-white-pure cursor-pointer"
        onClick={onClick}
      >
        <div className="font-medium text-transparent bg-clip-text bg-gradient-to-r from-[#464FEB] to-[#8330E9] text-base">
          Add Substep
        </div>
      </div>
    </div>
  );
};
