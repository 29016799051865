import { store } from "@/redux/store";
import axios from "axios";

export const getToolCommunicationData = async () => {
  try {
    let res = await axios({
      method: "POST",
      url: `${
        store.getState().auth.apiURL
      }CF/api/webflow/getallToolInformationData`,
    });

    return res?.data?.result ?? [];
  } catch (err) {
    console.log(err);
  }
};

export const getLivezyClientsDashboard = async () => {
  try {
    let res = await axios({
      method: "get",
      url: `${
        store.getState().auth.apiURL
      }CF/api/LivEzy/getLivezyClientsDashboard`,
    });

    return res?.data?.result ?? [];
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const getAllOnboardingList = async () => {
  try {
    let res = await axios({
      method: "get",
      url: `${
        store.getState().auth.apiURL
      }CF/api/OnBoardingEmailFlows/getAllOnboardingList`,
    });

    return res?.data?.result ?? [];
  } catch (err) {
    console.log(err);
  }
};

export const getAllUsersDashboard = async () => {
  try {
    let res = await axios({
      method: "get",
      url: `${
        store.getState().auth.apiURL
      }CF/api/internalAnalytics/allAtheletesDashboard`,
    });

    return res?.data?.result ?? [];
  } catch (err) {
    console.log(err);
  }
};

export const getAllAffiliatesDashboard = async () => {
  try {
    let res = await axios({
      method: "get",
      url: `${
        store.getState().auth.apiURL
      }CF/api/internalAnalytics/allAffiliatesDashboard`,
    });

    return res?.data?.result ?? [];
  } catch (err) {
    console.log(err);
  }
};

export const updateIsActiveStatus = async (isActive, trainerUUID) => {
  try {
    await axios({
      method: "post",
      url: `${
        store.getState().auth.apiURL
      }CF/api/CoachWeb/Update/UpdateCoachFields`,
      data: {
        coachID: trainerUUID,
        isActive: isActive,
      },
    });
    return true;
  } catch (error) {
    return false;
  }
};
