import { Typography } from "@mui/material";
import React, { useContext } from "react";
import { CopilotContext } from "../copilot";
import { colorsArray } from "../../../constants";

const SingleMealPlanCard = ({ mealPlan }) => {
  let allFoods = mealPlan?.meals?.map((meal) => meal?.foods).flat();
  let allFoodsNames = allFoods?.map((food) => food?.name);
  let allFoodStrings = allFoodsNames?.join(", ");
  allFoodStrings =
    allFoodStrings?.length > 50
      ? allFoodStrings?.slice(0, 50) + "..."
      : allFoodStrings;

  const { onMealClick } = useContext(CopilotContext);
  return (
    <div
      style={{
        borderRadius: "8px",
        width: "235px",
        minHeight: "90px",
        boxShadow:
          "0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 4px 8px 0px rgba(0, 0, 0, 0.14)",
        textAlign: "left",
        paddingLeft: "16px",
        // marginBottom: "9px",
        fontFamily: "DM Sans",
        position: "relative",
        overflow: "hidden",
        backgroundColor: "#ffffff",
        paddingRight: "5px",
        marginBottom: "9px",
        cursor: "pointer",
      }}
      onClick={() => {
        onMealClick();
      }}
    >
      <div
        style={{
          position: "absolute",
          left: 0,
          height: "100%",
          width: "5px",
          backgroundColor:
            colorsArray[(allFoods || []).length % colorsArray.length],
        }}
      />
      <Typography
        sx={{
          fontSize: "12px",
          fontWeight: 500,
          color: "#000",
          lineHeight: "16px",
          fontFamily: "DM Sans",
          paddingTop: "14px",
        }}
      >
        Meal Plan
      </Typography>
      <Typography
        sx={{
          color: "rgba(36, 36, 36, 0.75)",
          fontSize: "10px",
          marginTop: "4px",
          fontFamily: "DM Sans",
          fontWeight: 400,
          display: "-webkit-box",
          "-webkit-line-clamp": 2,
          "-webkit-box-orient": "vertical",
          overflow: "hidden",
        }}
      >
        {allFoodStrings}
      </Typography>
    </div>
  );
};

export default SingleMealPlanCard;
