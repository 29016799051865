import { createContext, useEffect, useRef, useState } from "react";

import AddIcon from "../../assets/copilot/addIcon.svg";
import AnalyseIcon from "../../assets/copilot/analyse.svg";
import GradientBG from "../../assets/copilot/BG.svg";
import BulbIcon from "../../assets/copilot/bulb.svg";
import ChatSparkle from "../../assets/copilot/chatSparkle.svg";
import ClientIcon from "../../assets/copilot/clients.svg";
import CloseIcon from "../../assets/copilot/closeIcon.svg";
import DietPlanIcon from "../../assets/copilot/dietPlanIcon.svg";
import EditIcon from "../../assets/copilot/editIcon.svg";
import InfoIcon from "../../assets/copilot/info.svg";
import InsightIcon from "../../assets/copilot/insight.svg";
import SparkleLogo from "../../assets/copilot/sparkleLogo.svg";

import { Typography } from "@mui/material";
import axios from "axios";
import EditWorkouts from "./EditWorkouts";
import { Recipes } from "./Recipes.ts";
import "./styles.css";
import { trainingSet } from "./TrainingSet.ts";

import { DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import WorkoutPlanIcon from "../../assets/copilot/workoutPlanIcon.svg";
import { store } from "../../redux/store";
import { assistModifyWorkout } from "./AssistModifyWorkout.ts";
import {
  CreateConverstaionTracking,
  PerformSecondaryActionTracking,
  TrackingActions,
} from "./CopilotTracking";
import EditMeal from "./EditMeal";
import { ScaleDownSingleWorkout } from "./ScaleDownSingleWorkout.ts";
import { ScaleUpSingleWorkout } from "./ScaleUpSingleWorkout.ts";
import FormComponent from "./utils/FormComponent";
import {
  conversionFunction,
  convertOneDay,
  getWorkoutInOriginalFormGivenRange,
} from "./workoutAssignFunctions";

import {
  generateDietPlan,
  generateSingleMealPlan,
  generateSingleWorkout,
  generateWorkoutPlan,
} from "@/ApiServices/Copilot/Api";
import mixpanel from "mixpanel-browser";
import { useSelector } from "react-redux";
import { createMealPlan } from "../../ApiServices/Meals/Api.js";
import {
  GENERATE_MEAL_PLAN,
  GENERATE_RECIPE,
  GENERATE_SINGLE_WORKOUT,
} from "../../constants";
import ShowAIMealPlan from "../ShowAIMealPlan.js/index.js";
import SpurfitOptions from "./SpurfitOptions";
import { BotResponse } from "./utils/BotResponse";
import { doesResultMatchesTheJSONStructure } from "./utils/helper.js";
import RecipeView from "./utils/RecipeView";

export const CopilotContext = createContext(null);

const CREATE_WORKOUT_PLAN = "Create new workout plan";
const CREATE_MEAL_PLAN = "Create new diet plan";
const CREATE_SINGLE_WORKOUT = "Create single workout";
const CREATE_TRAINING_SET = "Create a training set";
const ASSIST_MODIFY_WORKOUTS = "Assist modify workouts";
const ADD_AND_MANAGE_CLIENTS = "Add and manage clients";
const ANALYSE_CLIENT_MEDICAL_PROFILE = "Analyse client medical profile";
const GET_INSIGHTS_ON_CLIENT_WORKOUTS = "Get insights on client workouts";
const KNOW_MORE = "Know more";
const HELP_US_BE_BETTER = "Help us be better";

export default function Copilot({ open, setOpen, ...props }) {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const [loadingResult, setLoadingResult] = useState(false);
  const [query, setQuery] = useState("");
  const [output, setOutput] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [userData, setUserData] = useState([]);
  const [responseData, setResponseData] = useState("");
  const [showOptions, setShowOptions] = useState(true);
  const [showEditWorkouts, setShowEditWorkouts] = useState(false);
  const [editWorkoutDay, setEditWorkoutDay] = useState(1);
  const [showEditMealPlan, setShowEditMealPlan] = useState(false);

  const [mealplans, setMealplans] = useState("");
  const [mealPlan, setMealPlan] = useState("");
  const [showBottomMenu, setShowBottomMenu] = useState(true);
  const [showWelcomeMsg, setShowWelcomeMsg] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null);

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [assignDate, setAssignDate] = useState(null);
  const [assignSuccess, setAssignSuccess] = useState(false);
  const [reaction, setReaction] = useState("");
  const [exerciseIDs, setExerciseIDs] = useState({});
  const [shouldEdit, setShouldEdit] = useState(false);
  const [selectedResponseIndex, setSelectedResponseIndex] = useState(1);
  const [showNameInput, setShowNameInput] = useState(false);
  const [workoutName, setWorkoutName] = useState("");

  const [showMealPlan, setShowMealPlan] = useState(false);

  const [assignInProgress, setAssignInProgress] = useState(false);

  const [trackingData, setTrackingData] = useState({});
  const [isRegenerate, setIsRegenerate] = useState(false);
  const [levelTag, setLevelTag] = useState("Beginner");

  const [showRecipe, setshowRecipe] = useState(false);
  const [selectedRecipe, setSelectedRecipe] = useState(null);

  const scrollRef = useRef(null);

  const printOutput = () => {
    console.log(output);
  };

  const updateTrackingData = (response) => {
    setTrackingData(response);
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    const div = scrollRef.current;
    if (!showDatePicker) {
      div.scrollTop = 0;
    } else if (showDatePicker) {
      div.scrollTop = div.scrollHeight;
    } else if (selectedOption === ASSIST_MODIFY_WORKOUTS) {
      div.scrollTop = div.scrollHeight - div.clientHeight - 500;
    } else {
      div.scrollTop = div.scrollHeight;
    }
  }, [output]);

  useEffect(() => {
    const div = scrollRef.current;
    div.scrollTop = div.scrollHeight;
  }, [showBottomMenu]);

  const CreateTrainingSet = async () => {
    console.log("CreateTrainingSet");
    setLoadingResult(true);
    triggerTextChanges();
    let training = await trainingSet();
    training.llmChainTrainingSets.run(query).then((result) => {
      isJSONStringWithMissingBracket(result)
        ? setResponseData(result + "}")
        : setResponseData(result);
      setOutput([
        ...output,
        { type: "user", message: query },
        { type: "bot", message: result },
      ]);
    });

    setLoadingResult(false);
    stopChangingText();
  };

  const onEditWorkout = (day, responseIndex, shouldEdit) => {
    setSelectedResponseIndex(responseIndex);
    setShowEditWorkouts(true);
    setShouldEdit(shouldEdit);
    setEditWorkoutDay(day + 1);
  };

  const onMealClick = () => {
    setShowMealPlan(true);
  };

  const onRecipeClick = (index) => {
    setshowRecipe(true);
    let recipes = JSON.parse(responseData);
    setSelectedRecipe(recipes[index]);
  };

  const onEditMealPlan = () => {
    setShowEditMealPlan(true);
  };

  const saveMealPlan = async () => {
    setAssignInProgress(true);

    let data = {
      coachId: store.getState().auth.trainerUUID,
      description: "",
      meal_plan_type: "FoodBased",
      reference_name: workoutName,
      plandetails: mealPlan,
    };

    await createMealPlan(data)
      .then((response) => {
        console.log(response);
        setAssignSuccess(true);
        setOutput([
          ...output,
          {
            type: "bot",
            message: `Meal plan saved successfully`,
          },
        ]);

        if (props?.onSuccess) {
          props.onSuccess(GENERATE_MEAL_PLAN, response);
        }
      })
      .catch((error) => {
        console.log("error Assign", error);
      })
      .finally(() => {
        setAssignInProgress(false);
      });
  };

  const onAssignClick = () => {
    switch (selectedOption) {
      case CREATE_WORKOUT_PLAN:
      case ASSIST_MODIFY_WORKOUTS:
        assignWorkout();
        break;
      case GENERATE_MEAL_PLAN:
        saveMealPlan();
        break;
      case CREATE_MEAL_PLAN:
        assignMealPlan();
        break;
      default:
        break;
    }
    secondaryActionTracking(TrackingActions.ASSIGN);
  };

  const assignMealPlan = () => {
    setAssignInProgress(true);
    mixpanel.track("Trainer_AskCopilot_AssignMealPlan");
    let meals = JSON.parse(responseData)["week-1"];

    let data = [];

    meals.map((meal, index) => {
      let oneMealData = {
        atheleteID: selectedUser.id,
        dateAssigned: new Date(
          moment(assignDate).add(index, "days").format("YYYY-MM-DD")
        ),
        unsavedMeals: meal,
      };
      data.push(oneMealData);
    });

    axios({
      method: "post",
      url: `${APIURL}CF/api/meal/assignUnsavedMealsForWeek`,
      data: { data },
    })
      .then((response) => {
        console.log(response);
        setAssignSuccess(true);
        setOutput([
          ...output,
          {
            type: "bot",
            message: `Meal plan assigned successfully`,
          },
        ]);
      })
      .catch((error) => {
        console.log("error Assign", error);
      })
      .finally(() => {
        setAssignInProgress(false);
      });
  };

  const assignWorkout = () => {
    mixpanel.track("Trainer_AskCopilot_AssignWorkout");
    setAssignInProgress(true);
    let data = JSON.parse(responseData);
    let promises = [];
    conversionFunction(data, selectedUser, promises, assignDate, exerciseIDs);
    Promise.all(promises)
      .then((results) => {
        console.log("All API requests completed successfully");
        // Do something after all the API requests have completed
        setAssignSuccess(true);
        setOutput([
          ...output,
          {
            type: "bot",
            message: `Workouts assigned successfully`,
          },
        ]);
      })
      .catch((error) => {
        console.log("Error occurred while making API requests", error);
      })
      .finally(() => {
        setAssignInProgress(false);
      });
  };

  const onSaveClick = (selectedIndices) => {
    if (selectedOption === GENERATE_SINGLE_WORKOUT) {
      setShowNameInput(true);
    } else if (selectedOption === GENERATE_MEAL_PLAN) {
      setShowNameInput(true);
    } else {
      onSaveRecipe(selectedIndices);
    }
  };

  const onSaveRecipe = async (selectedIndices) => {
    setAssignInProgress(true);
    let recipes = JSON.parse(responseData);

    const promises = recipes?.map(async (recipe, index) => {
      if (selectedIndices && !selectedIndices.includes(index)) return;
      let data = {
        name: recipe.recipe_name,
        serving: recipe,
      };

      try {
        const response = await axios({
          method: "post",
          url: `${APIURL}CF/api/createReceipe?coachId=${
            store.getState().auth.trainerUUID
          }`,
          data: data,
        });
        console.log("recipe saved successfully: ", response);
      } catch (error) {
        console.log("error", error);
        return {
          type: "bot",
          message: `Error saving recipe: ${error.message}`,
        };
      }
    });

    const results = await Promise.all(promises);
    setAssignInProgress(false);
    setAssignSuccess(true);
    setOutput([
      ...output,
      {
        type: "bot",
        message: `Recipe saved successfully`,
      },
    ]);
    if (props?.reloadRecipes) {
      props.reloadRecipes();
    }
  };

  const onSaveWorkout = () => {
    if (selectedOption === GENERATE_MEAL_PLAN) {
      saveMealPlan();
      return;
    }
    setAssignInProgress(true);
    let data = JSON.parse(responseData);
    let promises = [];
    convertOneDay(data, promises, exerciseIDs, workoutName);
    Promise.all(promises)
      .then((results) => {
        console.log("All API requests completed successfully");
        // Do something after all the API requests have completed
        setAssignSuccess(true);

        setOutput([
          ...output,
          {
            type: "bot",
            message: `Workouts saved successfully`,
          },
        ]);

        if (props?.getWorkouts) {
          props.getWorkouts();
        }
      })
      .catch((error) => {
        console.log("Error occurred while making API requests", error);
        setOutput([
          ...output,
          {
            type: "bot",
            message: `Error occurred while saving workouts`,
          },
        ]);
      })
      .finally(() => {
        setAssignInProgress(false);
      });
  };

  const clearStates = () => {
    setAssignDate(null);
    setAssignSuccess(false);
    setShowOptions(false);
    setOutput([]);
    setSelectedUser(null);
    setReaction("");
  };

  const unrollData = (oneDay) => {
    let unrolledArray = [];
    Object.entries(oneDay).map(([key, value]) => {
      if (key === "title") return;
      value.exercises?.map((exercise) => {
        let rounds = 0;
        if (exercise?.reps) {
          rounds = Object.entries(exercise?.reps).length;
        } else if (exercise?.weights) {
          rounds = Object.entries(exercise?.weights).length;
        } else if (exercise?.duration) {
          rounds = Object.entries(exercise?.duration).length;
        } else if (exercise?.distance) {
          rounds = Object.entries(exercise?.distance).length;
        }

        for (let i = 0; i < rounds; i++) {
          let unrolledMes = {};
          unrolledMes.category = null;
          unrolledMes.equipment = null;
          unrolledMes.imageKey = "";
          unrolledMes.videoKey = "";
          unrolledMes.exercise = exercise.name;
          unrolledMes.primaryKey = "";
          unrolledMes.id = "";
          unrolledMes.duration = exercise?.duration?.[i + 1] ?? 0;
          unrolledMes.reps = exercise?.reps?.[i + 1] ?? 0;
          unrolledMes.sets = 1;
          unrolledMes.weightUnits = exercise.units?.weights;
          unrolledMes.weights = exercise?.weights?.[i + 1] ?? 0;
          unrolledMes.heightUnits = exercise?.units?.heights;
          unrolledMes.heights = exercise?.heights?.[i + 1] ?? 0;
          unrolledMes.exerciseType = "";
          unrolledMes.instructions = "";

          unrolledArray.push(unrolledMes);
        }
      });
    });
    return unrolledArray;
  };

  const generateMealPlan = async (query, isRegenerate) => {
    setQuery(query);
    setLoadingResult(true);
    setShowOptions(true);
    setShowBottomMenu(false);
    triggerTextChanges();

    const result = await generateSingleMealPlan(query);

    if (isRegenerate) {
      secondaryActionTracking(TrackingActions.REGENERATE, result);
      setIsRegenerate(false);
    } else {
      CreateConverstaionTracking({
        actionName: TrackingActions.SINGLE_MEALPLAN,
        prompt: query,
        aiResponse: result,
        updateTrackingData: updateTrackingData,
      });
    }

    setResponseData(result);
    setMealPlan(JSON.parse(result));
    setOutput([
      { type: "user", message: query },
      { type: "bot", message: result },
    ]);

    setLoadingResult(false);
    stopChangingText();

    // let mealPlanLLM = await singleMealPlan();

    // mealPlanLLM.llmChainSingleMealPlan
    //   .run(query)
    //   .then((result) => {
    //     if (isRegenerate) {
    //       secondaryActionTracking(TrackingActions.REGENERATE, result);
    //       setIsRegenerate(false);
    //     } else {
    //       CreateConverstaionTracking({
    //         actionName: TrackingActions.SINGLE_MEALPLAN,
    //         prompt: query,
    //         aiResponse: result,
    //         updateTrackingData: updateTrackingData,
    //       });
    //     }

    //     setResponseData(result);
    //     setMealPlan(JSON.parse(result));
    //     setOutput([
    //       { type: "user", message: query },
    //       { type: "bot", message: result },
    //     ]);

    //     setLoadingResult(false);
    //     stopChangingText();
    //   })
    //   .catch((error) => {
    //     console.log({ error });
    //   })
    //   .finally(() => {
    //     setLoadingResult(false);
    //     stopChangingText();
    //   });
  };

  const CreateDietPlan = async (query, isRegenerate) => {
    setQuery(query);
    console.log("CreateDietPlan ->", query);
    setLoadingResult(true);
    setShowOptions(true);
    setShowBottomMenu(false);
    triggerTextChanges();

    // let diet = await DietPlan();

    let result = await generateDietPlan(query);
    result = JSON.stringify(result);
    // Tracking on creation of diet plan

    if (isRegenerate) {
      secondaryActionTracking(TrackingActions.REGENERATE, result);
      setIsRegenerate(false);
    } else {
      CreateConverstaionTracking({
        actionName: TrackingActions.MEALPLAN,
        prompt: query,
        aiResponse: result,
        updateTrackingData: updateTrackingData,
      });
    }

    let isStructureCorrect = false;
    if (isJSONString(result)) {
      let res = JSON.parse(result);
      setMealplans(JSON.parse(result));
      isStructureCorrect = doesResultMatchesTheJSONStructure(
        res,
        selectedOption
      );
    }
    setResponseData(result);
    if (isStructureCorrect) {
      setOutput([
        { type: "user", message: query },
        { type: "bot", message: result },
      ]);
    } else {
      setOutput([
        { type: "user", message: query },
        {
          type: "bot",
          message: "Response taking too long please try again.",
        },
      ]);
    }

    // diet.llmChainDietPlan
    //   .run(query)
    //   .then((result) => {
    //     // Tracking on creation of diet plan

    //     if (isRegenerate) {
    //       secondaryActionTracking(TrackingActions.REGENERATE, result);
    //       setIsRegenerate(false);
    //     } else {
    //       CreateConverstaionTracking({
    //         actionName: TrackingActions.MEALPLAN,
    //         prompt: query,
    //         aiResponse: result,
    //         updateTrackingData: updateTrackingData,
    //       });
    //     }

    //     let isStructureCorrect = false;
    //     if (isJSONString(result)) {
    //       let res = JSON.parse(result);
    //       setMealplans(JSON.parse(result));
    //       isStructureCorrect = doesResultMatchesTheJSONStructure(
    //         res,
    //         selectedOption
    //       );
    //     }
    //     setResponseData(result);
    //     if (isStructureCorrect) {
    //       setOutput([
    //         { type: "user", message: query },
    //         { type: "bot", message: result },
    //       ]);
    //     } else {
    //       setOutput([
    //         { type: "user", message: query },
    //         {
    //           type: "bot",
    //           message: "Response taking too long please try again.",
    //         },
    //       ]);
    //     }
    //   })
    //   .finally(() => {
    //     setLoadingResult(false);
    //     stopChangingText();
    //   });

    setLoadingResult(false);
    stopChangingText();
  };

  const CreateSingleWorkout = async (query, isRegenerate) => {
    console.log("CreateSingleWorkout", query);
    setQuery(query);
    triggerTextChanges();
    setLoadingResult(true);
    setShowOptions(true);
    setShowBottomMenu(false);

    let result = await generateSingleWorkout(query);

    console.log("single workout result: ", result);

    // Tracking on creation of single workout
    if (isRegenerate) {
      secondaryActionTracking(TrackingActions.REGENERATE_WORKOUT, result);
      setIsRegenerate(false);
    } else {
      CreateConverstaionTracking({
        actionName: TrackingActions.GENERATE_WORKOUT,
        prompt: query,
        aiResponse: result,
        updateTrackingData: updateTrackingData,
      });
    }

    setResponseData(result);
    getIdForExercises(result, true);
    setOutput([
      { type: "user", message: query },
      { type: "bot", message: result },
    ]);

    setLoadingResult(false);
    stopChangingText();
  };

  const GenerateRecipies = async (query) => {
    console.log("GenerateRecipies", query);
    setQuery(query);
    triggerTextChanges();
    setLoadingResult(true);
    setShowOptions(true);
    setShowBottomMenu(false);

    let recipe = await Recipes();
    recipe.llmChainRecipes
      .run(query)
      .then((result) => {
        console.log("recipe result: ", result);

        // Tracking on creation of single workout
        if (isRegenerate) {
          secondaryActionTracking(TrackingActions.REGENERATE_RECIPE, result);
          setIsRegenerate(false);
        } else {
          CreateConverstaionTracking({
            actionName: TrackingActions.GENERATE_RECIPE,
            prompt: query,
            aiResponse: result,
            updateTrackingData: updateTrackingData,
          });
        }

        setResponseData(result);
        setOutput([
          { type: "user", message: query },
          { type: "bot", message: result },
        ]);
      })
      .catch((err) => {
        console.log("error in single workout", err);
      })
      .finally(() => {
        setLoadingResult(false);
        stopChangingText();
      });
  };

  const workoutLevels = {
    Beginner:
      "keep the repetitions in the range 3 and 7, number of sets to 2, weight range should be between 25 to 45 lbs for lower body exercises. 15 to 30 for upper body exercises",
    "Advanced Beginner":
      "keep the repetitions in the range between 3 and 7, number of sets to 2, weight range should be between 35 to 55 lbs for lower body exercises. 20 to 35 lbs for upper body exercises",
    "Intermediate Beginner":
      "keep the repetitions in the range between 4 and 8, number of sets to 2, weight range should be between 45 to 65 lbs for lower body exercises. 25 to 45 lbs for upper body exercises",
    "Novice Intermediate":
      "keep the repetitions in the range between 4 and 8, number of sets to 2, weight range should be between 55 to 75 lbs for lower body exercises. 30 to 55 lbs for upper body exercises",
    Intermediate:
      "keep the repetitions in the range between 6 and 10, number of sets to 3, weight range should be between 65 to 90 lbs for lower body exercises. 40 to 55 lbs for upper body exercises",
    "Upper Intermediate":
      "keep the repetitions in the range between 6 and 10, number of sets to 3, weight range should be between 65 to 110 lbs for lower body exercises. 40 to 55 lbs for upper body exercises",
    "Advanced Intermediate":
      "keep the repetitions in the range between 8 and 12, number of sets to 4, weight range should be between 80 to 125 lbs for lower body exercises. 50 to 65 lbs for upper body exercises",
    Advanced:
      "keep the repetitions in the range between 8 and 12, number of sets to 4, weight range should be between 95 to 150 lbs for lower body exercises. 50 to 65 lbs for upper body exercises",
  };

  const CreateNewWorkoutPlan = async (query, isRegenerate) => {
    console.log("CreateNewWorkoutPlan", query);
    setQuery(query);
    triggerTextChanges();
    setLoadingResult(true);
    setShowOptions(true);
    setShowBottomMenu(false);
    let queryWithLevel = query;

    if (workoutLevels[levelTag]) {
      queryWithLevel += " " + workoutLevels[levelTag];
    }

    let result = await generateWorkoutPlan(queryWithLevel);
    console.log("isRegenerate", isRegenerate);
    if (isRegenerate) {
      secondaryActionTracking(TrackingActions.REGENERATE, result);
      setIsRegenerate(false);
    } else {
      CreateConverstaionTracking({
        actionName: TrackingActions.WORKOUTPLAN,
        prompt: query,
        aiResponse: result,
        updateTrackingData: updateTrackingData,
      });
    }

    setLoadingResult(false);
    stopChangingText();

    let isStructureCorrect = false;
    // if second last character of string is ] then remove only that character
    if (
      typeof result === "string" &&
      result.length > 1 &&
      result[result.length - 2] === "]"
    ) {
      // remove only second last character
      result = removeSecondLastChar(result);
    } else if (isJSONStringWithMissingBracket(result)) {
      result = result + "}";
    } else {
      result = result;
    }
    if (isJSONString(result)) {
      let res = JSON.parse(result);
      isStructureCorrect = doesResultMatchesTheJSONStructure(
        res,
        selectedOption
      );
    }
    setResponseData(result);
    getIdForExercises(result, false);

    if (isStructureCorrect) {
      setOutput([
        { type: "user", message: query },
        { type: "bot", message: result },
      ]);
    } else {
      setOutput([
        { type: "user", message: query },
        {
          type: "bot",
          message: "Response taking too long please try again.",
        },
      ]);
    }

    // workout.llmChainTrainingSets.run(queryWithLevel).then((result) => {
    //   // Tracking on creation of workout plan
    //   console.log("isRegenerate", isRegenerate);
    //   if (isRegenerate) {
    //     secondaryActionTracking(TrackingActions.REGENERATE, result);
    //     setIsRegenerate(false);
    //   } else {
    //     CreateConverstaionTracking({
    //       actionName: TrackingActions.WORKOUTPLAN,
    //       prompt: query,
    //       aiResponse: result,
    //       updateTrackingData: updateTrackingData,
    //     });
    //   }

    //   setLoadingResult(false);
    //   stopChangingText();

    //   let isStructureCorrect = false;
    //   // if second last character of string is ] then remove only that character
    //   if (
    //     typeof result === "string" &&
    //     result.length > 1 &&
    //     result[result.length - 2] === "]"
    //   ) {
    //     // remove only second last character
    //     result = removeSecondLastChar(result);
    //   } else if (isJSONStringWithMissingBracket(result)) {
    //     result = result + "}";
    //   } else {
    //     result = result;
    //   }
    //   if (isJSONString(result)) {
    //     let res = JSON.parse(result);
    //     isStructureCorrect = doesResultMatchesTheJSONStructure(
    //       res,
    //       selectedOption
    //     );
    //   }
    //   setResponseData(result);
    //   getIdForExercises(result, false);

    //   if (isStructureCorrect) {
    //     setOutput([
    //       { type: "user", message: query },
    //       { type: "bot", message: result },
    //     ]);
    //   } else {
    //     setOutput([
    //       { type: "user", message: query },
    //       {
    //         type: "bot",
    //         message: "Response taking too long please try again.",
    //       },
    //     ]);
    //   }
    // });
  };

  const scaleUpPrompts = [
    "increase the number of sets",
    "increase reps and lower the load",
    "increase the load keep sets same",
    "Add in extra movements ",
    "Modify the movements to advanced version of the movements.",
  ];
  function getRandomScaleUpPrompt() {
    const randomIndex = Math.floor(Math.random() * scaleUpPrompts.length);
    return scaleUpPrompts[randomIndex];
  }

  const AssistModifyWorkout = async (query, isRegenerate) => {
    if (!isRegenerate && query !== "scale up" && query !== "scale down") {
      query = query + ", " + responseData;
    }
    setQuery(query);
    triggerTextChanges();
    setLoadingResult(true);
    setShowOptions(true);
    setShowBottomMenu(false);

    if (
      query === "scale up" ||
      (isRegenerate && query.includes("Scale up the following workouts:"))
    ) {
      setOutput([
        ...output,
        { type: "user", message: "Scaling up the workouts." },
      ]);

      let scaleUp = await ScaleUpSingleWorkout();
      let newQuery = isRegenerate
        ? query
        : "Scale up the following workouts: " + responseData;
      console.log("query: ", newQuery);
      setQuery(newQuery);

      scaleUp.llmChainScaleWorkout
        .run(newQuery)
        .then((result) => {
          console.log("scale up result: ", result);
          setResponseData(result);
          if (output[output.length - 1].type !== "user") {
            output.push({ type: "user", message: "Scaling up the workouts." });
          }
          output.push({ type: "bot", message: result });
          setOutput(output);
        })
        .catch((err) => {
          console.log("error in scale up", err);
        })
        .finally(() => {
          setLoadingResult(false);
          stopChangingText();
        });
    } else if (
      query === "scale down" ||
      (isRegenerate && query.includes("Scale down the following workouts:"))
    ) {
      setOutput([
        ...output,
        { type: "user", message: "Scaling down the workouts." },
      ]);

      let scaleDown = await ScaleDownSingleWorkout();
      let newQuery = isRegenerate
        ? query
        : "Scale down the following workouts: " + responseData;
      setQuery(newQuery);

      scaleDown.llmChainScaleWorkout
        .run(newQuery)
        .then((result) => {
          console.log("scale down result: ", result);
          setResponseData(result);
          if (output[output.length - 1].type !== "user") {
            output.push({
              type: "user",
              message: "Scaling down the workouts.",
            });
          }
          output.push({ type: "bot", message: result });
          setOutput(output);
        })
        .catch((err) => {
          console.log("error in scale down", err);
        })
        .finally(() => {
          setLoadingResult(false);
          stopChangingText();
        });
    } else {
      let assist = await assistModifyWorkout();

      assist.llmChainModifyWorkout
        .run(query)
        .then((result) => {
          console.log("assist result: ", result);
          setResponseData(result);
          setOutput([
            ...output,
            { type: "user", message: query.split(", ")[0] },
            { type: "bot", message: result },
          ]);
        })
        .catch((err) => {
          console.log("error in assist modify workout", err);
        })
        .finally(() => {
          setLoadingResult(false);
          stopChangingText();
        });
    }
  };

  const getAthleteWorkoutsByDateRange = (
    query,
    fromDate,
    toDate,
    setLoading,
    setFetched
  ) => {
    setLoading(true);
    axios({
      method: "get",
      url: `${APIURL}CF/api/getAthleteWorkoutsByDateRange`,
      params: {
        athleteID: selectedUser?.id,
        startDate: moment(fromDate).format("YYYY-MM-DD"),
        endDate: moment(toDate).format("YYYY-MM-DD"),
      },
    })
      .then((res) => {
        console.log("got response:", res.data.result);
        let result = getWorkoutInOriginalFormGivenRange(
          res.data.result,
          fromDate,
          toDate
        );

        setResponseData(JSON.stringify(result));
        setOutput([
          { type: "user", message: "workouts for selected dates" },
          {
            type: "bot",
            message: JSON.stringify(result),
            customMessage: `Here's the workouts from ${moment(fromDate).format(
              "D MMMM"
            )} to ${moment(toDate).format("D MMMM")} for `,
          },
        ]);
        getIdForExercises(JSON.stringify(result), false);
        setFetched(true);
      })
      .catch((err) => {
        console.log("error in getting athlete workouts by date range", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getIdForExercises = (responseData, isSingleDay) => {
    console.log("getIdForExercises", responseData);
    if (!isJSONString(responseData)) {
      return;
    }
    let data = JSON.parse(responseData);
    axios({
      method: "post",
      url: `${APIURL}CF/api/CoachWeb/Create/getExercisesForGeneratedWorkout?isSingleDay=${isSingleDay}`,
      data: data,
    })
      .then((res) => {
        setExerciseIDs(res.data.result);
        console.log("exercise ids: ", res.data.result);
      })
      .catch((err) => {
        console.log(err, "error in getting id for exercises");
      });
  };

  const regenerate = () => {
    let triggerFunction = getTriggerFunction(selectedOption);
    if (selectedOption === ASSIST_MODIFY_WORKOUTS) {
      triggerFunction[1](query, true);
    } else {
      clearStates();
      triggerFunction(query, true);
    }
  };

  /* 
  Create new workout plan - fullweek
  Create single workout -  prompt workout
  Create a training set - prompt with example
  */

  function isJSONString(str) {
    try {
      let res = JSON.parse(str);
      if (res.error) return false;
      return true;
    } catch (e) {
      return false;
    }
  }

  function isJSONStringWithMissingBracket(str) {
    try {
      str = str + "}";
      let res = JSON.parse(str);
      if (res.error) return false;
      return true;
    } catch (e) {
      return false;
    }
  }

  const handleSend = () => {
    if (query === "") return;
    switch (selectedOption) {
      case CREATE_WORKOUT_PLAN:
        CreateNewWorkoutPlan();
        break;
      case CREATE_SINGLE_WORKOUT:
        CreateSingleWorkout();
        break;
      case CREATE_TRAINING_SET:
        CreateTrainingSet();
        break;
      default:
        return;
    }
  };

  const getTriggerFunction = (selectedOption) => {
    switch (selectedOption) {
      case GENERATE_SINGLE_WORKOUT:
        return CreateSingleWorkout;
      case CREATE_WORKOUT_PLAN:
        return CreateNewWorkoutPlan;
      case CREATE_MEAL_PLAN:
        return CreateDietPlan;
      case ASSIST_MODIFY_WORKOUTS:
        return [getAthleteWorkoutsByDateRange, AssistModifyWorkout];
      case GENERATE_RECIPE:
        return GenerateRecipies;
      case GENERATE_MEAL_PLAN:
        return generateMealPlan;
      default:
        return defaultTriggerFunction;
    }
  };

  const defaultTriggerFunction = (query) => {
    setShowOptions(true);
    setShowBottomMenu(true);
  };

  const getUsers = () => {
    let trainerDetails = JSON.parse(localStorage.getItem("trainerCredentials"));
    axios({
      method: "get",
      url: `${APIURL}CF/api/CoachWeb/Get/getAllAtheletesOneCoach`,
      params: {
        coachID: trainerDetails?.password,
      },
    }).then(async (res) => {
      console.log("Dashboard query on assign: ", res);
      const filteredData = res.data.result?.[0]?.atheletes?.filter(
        (item) => item.active == true
      );

      setUserData(filteredData);
      console.log("filteredData", filteredData);
    });
  };

  const updateAthleteLevelTag = async (athleteID, levelTag) => {
    await axios({
      method: "post",
      url: `${APIURL}CF/api/CoachWeb/Update/updateAthleteLevelTag`,
      data: {
        athleteID: athleteID,
        levelTag: levelTag,
      },
    })
      .then(async (res) => {
        await getUsers();
      })
      .catch((err) => {
        console.log("error in updating athlete level tag", err);
      });
  };

  const textArr = [
    "You’re only one workout away from a good mood",
    "You can have results or excuses, but not both",
    "Great coaches build great athletes",
  ];
  const [intervalId, setIntervalId] = useState(null);
  const [text, setText] = useState(textArr[0]);

  const triggerTextChanges = () => {
    if (intervalId) {
      // Clear the interval if it's already running
      clearInterval(intervalId);
      setIntervalId(null);
    } else {
      // Start the interval to change the text every second
      let i = 0;
      const id = setInterval(() => {
        setText(textArr[i % textArr.length]);
        i++;
      }, 3000);
      setIntervalId(id);
    }
  };

  useEffect(() => {
    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, [intervalId]);

  const stopChangingText = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  };

  const secondaryActionTracking = (actionName, responseData) => {
    PerformSecondaryActionTracking({
      id: trackingData?.id,
      secondaryActionName: actionName,
      secondaryActions: trackingData?.secondaryActions,
      response: responseData,
      updateTrackingData: updateTrackingData,
    });
  };

  return (
    <CopilotContext.Provider
      value={{
        onEditWorkout,
        onMealClick,
        onEditMealPlan,
        onSaveClick,
        setSelectedUser,
        secondaryActionTracking,
        updateAthleteLevelTag,
        levelTag,
        setLevelTag,
        onRecipeClick,
      }}
    >
      <div
        style={{
          display: open ? "flex" : "none",
          flexDirection: "column",
          width: "322px",
          height: "calc(100vh - 89px)",
          // marginTop: "12px",
          borderRadius: "20px",
          boxShadow:
            "0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 4px 8px 0px rgba(0, 0, 0, 0.14)",
          // backgroundColor: "#ffffff",
          transition: "all 0.3s ease",
          background:
            "linear-gradient(to bottom, white, white),radial-gradient(circle at top left, red, blue)",
          // marginRight: "20px",
          position: "relative",
        }}
        id="copilot-global"
      >
        {showEditWorkouts && (
          <EditWorkouts
            open={showEditWorkouts}
            setOpen={setShowEditWorkouts}
            data={
              selectedResponseIndex != output.length - 1 &&
              output[selectedResponseIndex]?.type == "bot" &&
              output[selectedResponseIndex]?.message
                ? output[selectedResponseIndex]?.message
                : responseData
            }
            setData={setResponseData}
            editWorkoutDay={editWorkoutDay}
            shouldEdit={shouldEdit}
            selectedOption={selectedOption}
            onResponseChange={(value) => {
              output[selectedResponseIndex].message = value;
              setOutput(output);
            }}
            showEditButton={false}
          />
        )}

        {showEditMealPlan && (
          <EditMeal
            open={showEditMealPlan}
            setOpen={setShowEditMealPlan}
            mealplans={mealplans}
            setMealplans={setMealplans}
            setResponseData={setResponseData}
          />
        )}
        {showRecipe && (
          <RecipeView
            isOpen={showRecipe}
            setIsOpen={setshowRecipe}
            recipe={selectedRecipe}
            key={"recipe"}
          />
        )}

        {showMealPlan && (
          <ShowAIMealPlan
            isOpen={showMealPlan}
            setIsOpen={setShowMealPlan}
            mealPlan={mealPlan}
            key={"mealPlan"}
          />
        )}

        <img
          src={GradientBG}
          style={{ position: "absolute", top: 0, width: "100%" }}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "transparent",
            margin: "16px 16px 0",
            zIndex: 1,
          }}
        >
          <img
            style={{ height: "24px", width: "24px", marginRight: "9px" }}
            src={SparkleLogo}
            alt="logo"
          />
          <Typography
            sx={{
              fontSize: "18px",
              fontFamily: "DM Sans",
              lineHeight: "23.44px",
              fontWeight: 500,
            }}
          >
            Ask Copilot
          </Typography>
          <img
            style={{
              height: "32px",
              width: "32px",
              marginLeft: "auto",
              cursor: "pointer",
            }}
            onClick={() => {
              setOutput([]);
              setShowWelcomeMsg(true);
              setShowOptions(true);
              setOpen(false);
            }}
            src={CloseIcon}
            alt="close"
          />
        </div>
        <div
          ref={scrollRef}
          style={{
            display: "flex",
            flexDirection: "column",
            overflow: "scroll",
            flex: 1,
            backgroundColor: "#FFFFFF",
            // margin: "0 16px",
          }}
        >
          {showWelcomeMsg && (
            <div
              style={{
                width: "288px",
                padding: "12px",
                // border: "1px solid black",
                marginTop: "auto",
                borderRadius: "8px",
                alignSelf: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                style={{ height: "62px", width: "62px" }}
                src={ChatSparkle}
                alt="welcome"
              />
              <Typography
                style={{
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "22px",
                  fontFamily: "DM Sans",
                }}
                className="gradient-text"
              >
                Welcome to Spurfit Copilot
              </Typography>
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "16px",
                  fontFamily: "DM Sans",
                  mt: "8px",
                }}
              >
                World’s first AI Assistant for fitness and wellness coaches.
                Your assistant writes personalized workout routines, assigns
                meal plans, and handles the heavy lifting, allowing you to focus
                on scaling your business.
              </Typography>
            </div>
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "auto",
              // height: "500px",
            }}
          >
            {output &&
              output?.map((item, index) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {item.type === "bot" ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "start",
                          marginTop: "12px",
                          justifyContent: "flex-start",
                          marginRight: "16px",
                        }}
                      >
                        {isJSONString(item?.message) ||
                        isJSONStringWithMissingBracket(item?.message) ? (
                          <BotResponse
                            reaction={reaction}
                            setReaction={setReaction}
                            selectedUser={selectedUser}
                            selectedOption={selectedOption}
                            message={
                              index == output.length - 1
                                ? responseData
                                : output?.[index]?.message
                            }
                            customMessage={item?.customMessage}
                            showActions={index == output.length - 1}
                            regenerate={regenerate}
                            setOutput={setOutput}
                            showDatePicker={showDatePicker}
                            setShowDatePicker={setShowDatePicker}
                            responseIndex={index}
                            loadingResult={loadingResult}
                            secondaryActionTracking={secondaryActionTracking}
                          />
                        ) : (
                          <>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                marginRight: "8px",
                                marginBottom: "8px",
                                background:
                                  "linear-gradient(to right, rgba(70, 79, 235, 0.1), rgba(131, 48, 233, 0.1))",
                                borderRadius: "8px",
                                padding: "16px",
                              }}
                            >
                              {assignSuccess ? (
                                <HyperLinkComponent
                                  message={item?.message}
                                  userUUID={selectedUser?.id}
                                  shouldShowLink={
                                    selectedOption !==
                                      GENERATE_SINGLE_WORKOUT &&
                                    selectedOption !== GENERATE_RECIPE &&
                                    selectedOption !== GENERATE_MEAL_PLAN
                                  }
                                />
                              ) : (
                                <Typography
                                  sx={{
                                    fontSize: "13px",
                                    fontWeight: 400,
                                    lineHeight: "20px",
                                    width: "250px",
                                    wordWrap: "break-word",
                                    textAlign: "left",
                                    fontFamily: "DM Sans",
                                  }}
                                >
                                  "Response taking too long please try again."
                                </Typography>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginTop: "12px",
                          marginLeft: "16px",
                          justifyContent: "flex-end",
                          background:
                            "linear-gradient(to right, rgba(70, 79, 235, 0.1), rgba(131, 48, 233, 0.1))",
                          borderRadius: "8px",
                          padding: "16px",
                          maxWidth: "267px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginRight: "8px",
                            alignItems: "flex-end",
                          }}
                        >
                          {item.message === "date" ? (
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                              <DatePicker
                                disabled={assignSuccess}
                                sx={{
                                  height: "26px",
                                  width: "80%",
                                  fontFamily: "DM Sans",
                                  "& input": {
                                    fontFamily: "DM Sans",
                                    fontSize: "14px",
                                    padding: "6px",
                                    backgroundColor: "#FFFFFF",
                                  },
                                  "& .MuiInputLabel-root": {
                                    fontFamily: "DM Sans",
                                    fontSize: "14px",
                                    transform: "translate(0, 10px) scale(1)",
                                  },
                                  "& .MuiInputBase-root": {
                                    fontFamily: "DM Sans",
                                    borderRadius: "4px",
                                    outline: "none",
                                    "&:hover": {
                                      borderColor: "rgba(0, 0, 0, 0.23)",
                                    },
                                    "&.Mui-focused": {
                                      // borderColor: "#1976d2",
                                      fontFamily: "DM Sans",
                                    },
                                  },
                                }}
                                // value={assignDate}
                                onChange={(newValue) => {
                                  setAssignDate(newValue);
                                }}
                              />
                              {assignDate && !assignSuccess && (
                                <button
                                  disabled={assignInProgress}
                                  style={{
                                    fontFamily: "DM Sans",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    marginTop: "12px",
                                    alignSelf: "flex-end",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    background:
                                      "linear-gradient(to right, #464FEB, #8330E9)",
                                    borderRadius: "24px",
                                    padding: "2px 15px",
                                    color: "#fff",
                                  }}
                                  onClick={onAssignClick}
                                >
                                  confirm
                                </button>
                              )}
                            </LocalizationProvider>
                          ) : item.message === "enter name" ? (
                            <div>
                              <input
                                style={{
                                  width: "100%",
                                  padding: "6px",
                                  borderRadius: "4px",
                                  outline: "none",
                                  border: "1px solid #828282",
                                  fontFamily: "DM Sans",
                                  fontSize: "14px",
                                  fontWeight: 400,
                                }}
                                type="text"
                                placeholder={
                                  selectedOption === GENERATE_SINGLE_WORKOUT
                                    ? "Enter Workout Name"
                                    : "Enter Meal Plan Name"
                                }
                                onChange={(e) => setWorkoutName(e.target.value)}
                              />
                              <button
                                disabled={assignInProgress || assignSuccess}
                                style={{
                                  fontFamily: "DM Sans",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  marginTop: "12px",
                                  alignSelf: "flex-end",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  background:
                                    "linear-gradient(to right, #464FEB, #8330E9)",
                                  borderRadius: "24px",
                                  padding: "2px 15px",
                                  color: "#fff",
                                }}
                                onClick={onSaveWorkout}
                              >
                                confirm
                              </button>
                            </div>
                          ) : (
                            <Typography
                              sx={{
                                fontSize: "13px",
                                fontWeight: 400,
                                lineHeight: "20px",
                                width: "250px",
                                wordWrap: "break-word",
                                textAlign: "left",
                                width: "100%",
                                fontFamily: "DM Sans",
                              }}
                            >
                              {item.message}
                            </Typography>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
          {!loadingResult && showBottomMenu && (
            <>
              {!showOptions ? (
                // <div>Loading</div>
                <FormComponent
                  users={userData}
                  selectedOption={selectedOption}
                  getTriggerFunction={getTriggerFunction}
                  onAssignClick={() => {
                    setShowBottomMenu(false);
                    setShowDatePicker(true);
                    if (!showDatePicker) {
                      setOutput((output) => [
                        ...output,
                        { type: "user", message: "date" },
                      ]);
                    }
                  }}
                  goBack={() => {
                    setShowOptions(true);
                    setShowBottomMenu(true);
                  }}
                />
              ) : (
                <SpurfitOptions
                  showWelcomeMsg={showWelcomeMsg}
                  setShowWelcomeMsg={setShowWelcomeMsg}
                  setSelectedOption={setSelectedOption}
                  clearStates={clearStates}
                  selectedOption={selectedOption}
                  WorkoutPlanIcon={WorkoutPlanIcon}
                  DietPlanIcon={DietPlanIcon}
                  EditIcon={EditIcon}
                  ClientIcon={ClientIcon}
                  AnalyseIcon={AnalyseIcon}
                  InsightIcon={InsightIcon}
                  InfoIcon={InfoIcon}
                  BulbIcon={BulbIcon}
                />
              )}
            </>
          )}
        </div>
        {loadingResult && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "6px 4px",
              marginBottom: "8px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="image-container">
              <img src={ChatSparkle} alt="logo" />
            </div>
            <Typography
              style={{
                fontSize: "11px",
                fontWeight: 400,
                lineHeight: "20px",
                color: "#828282",
                fontStyle: "italic",
                fontFamily: "DM Sans",
                marginTop: "4px",
              }}
            >
              {text}
            </Typography>
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            borderRadius: "8px",
            background: "linear-gradient(to right, #464FEB, #8330E9)",
            overflow: "hidden",
            marginBottom: "24px",
            width: "288px",
            marginLeft: "16px",
            marginRight: "16px",
            position: "relative",
            overflow: "hidden",
            height: "44px",
          }}
        >
          {/* {!showOptions && (
          <img
            src={AddIcon}
            style={{
              height: "20px",
              width: "20px",
              position: "absolute",
              top: 5,
              left: 5,
              cursor: "pointer",
            }}
            alt="add"
            onClick={() => setShowOptions(!showOptions)}
          />
        )} */}
          <div
            style={{
              width: "286px",
              borderRadius: "8px",
              backgroundColor: "#ffffff",
              margin: "1px",
              padding: "12px 12px 8px 12px",
              height: "42px",
            }}
          >
            {/* <input
              autoComplete="off"
              type="text"
              placeholder="Ask anything..."
              onChange={(e) => setQuery(e.target.value)}
              style={{
                width: "100%",
                // padding: "12px",
                outline: "none",
                border: "none",
                fontFamily: "DM Sans",
                fontSize: "14px",
                fontWeight: 400,
                marginBottom: "4px",
              }}
            /> */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <img
                style={{ height: "20px", width: "20px", cursor: "pointer" }}
                src={AddIcon}
                alt="logo"
                onClick={() => {
                  setShowOptions(true);
                  setShowBottomMenu(true);
                }}
              />
              {/* <img
                src={SendIcon}
                alt="send"
                style={{ height: "20px", width: "20px", cursor: "pointer" }}
                onClick={handleSend}
              /> */}
            </div>
          </div>
        </div>
      </div>
    </CopilotContext.Provider>
  );
}

function HyperLinkComponent({ message, userUUID, shouldShowLink }) {
  return (
    <>
      <Typography
        sx={{
          fontSize: "13px",
          fontWeight: 400,
          lineHeight: "20px",
          width: "250px",
          wordWrap: "break-word",
          textAlign: "left",
          fontFamily: "DM Sans",
        }}
      >
        {message}. &nbsp;
        {shouldShowLink && (
          <>
            <a
              target="_blank"
              href={`https://trainer.spur.fit/clientsView?userUUID=${userUUID}`}
              style={{ color: "#464FEB", textDecoration: "underline" }}
            >
              Click here
            </a>
            &nbsp;to view.
          </>
        )}
      </Typography>
    </>
  );
}

function removeSecondLastChar(str) {
  if (str.length < 2) {
    // If the string is too short to have a second-to-last character, return it as is.
    return str;
  }

  // Get the part of the string before the second-to-last character.
  const before = str.slice(0, -2);

  // Get the part of the string after the second-to-last character.
  const after = str.slice(-1);

  // Concatenate the parts together.
  return before + after;
}
