import {
  CloseRounded,
  ContentPasteGoRounded,
  CopyAllRounded,
} from "@mui/icons-material";
import React, { useContext, useEffect, useState } from "react";
import BinIcon from "../../pages/library/assests/BinIcon";
import BowlWhite from "../../pages/library/assests/BowlWhite";
import PlusRoundIcon from "../../pages/library/assests/PlusRoundIcon";
import AddMacrosBasedFood from "../Nutrition/AddMealPlans/AddMacrosBasedFood";
import AddMealsModal from "../Nutrition/AddMealPlans/AddMealsModal";
import { MealsContext } from "../Nutrition/MealSheduleWeek";

const WeekMealContainer = ({
  mealDetails,
  workOutDay,
  dayIndex,
  setShowModal,
  setWorkOutFor,
  removeDayWorkout,
  setUnsavedChanges,
  copiedDay,
  setCopiedDay,
  handlePasteDay,
  canEdit,
}) => {
  const { lastAssignedDay, mealScheduleAssignedUsers, row } =
    useContext(MealsContext);

  const colors = ["#FFF0E5", "#E8E6FE", "#EEFDE8"];

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mealToRender, setMealToRender] = useState(mealDetails?.[workOutDay]);

  let shouldShowAddAndDelete =
    !mealScheduleAssignedUsers.length > 0 ||
    (mealScheduleAssignedUsers?.length > 0 &&
      lastAssignedDay > 0 &&
      workOutDay > lastAssignedDay);

  let lastDay = row * 7;
  let shouldShowCopy =
    !mealScheduleAssignedUsers?.length > 0 ||
    (mealScheduleAssignedUsers?.length > 0 && lastDay > lastAssignedDay);

  let shouldPaste =
    !mealScheduleAssignedUsers?.length > 0 ||
    (mealScheduleAssignedUsers?.length > 0 && workOutDay > lastAssignedDay);

  useEffect(() => {
    setMealToRender(mealDetails?.[workOutDay]);
  }, [mealDetails?.[workOutDay]]);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = async () => {
    setAnchorEl(null);
  };

  const handleCopyDay = (day, data) => {
    setCopiedDay({
      day: day,
      data: data,
    });
  };

  const [showAddMealModal, setShowAddMealModal] = useState(false);

  const updateMealData = (data) => {
    setUnsavedChanges(true);
    let temp = { ...mealToRender };
    temp[0].dayMealPlan = data;
    setMealToRender(temp);
  };

  const getColorForDietType = (dietType) => {
    if (typeof dietType === "string") {
      dietType = dietType.toLowerCase();
    }
    switch (dietType) {
      case "vegan":
        return "#006400";
      case "veg":
        return "#12a612";
      case "non-veg":
        return "#d61313";
      default:
        return "#fff";
    }
  };

  let dietType = mealToRender?.[0]?.dayMealPlan?.diet_type;

  return (
    <div
      className="week-card-conatiner"
      style={{
        position: "relative",
      }}
      onClick={() => {}}
    >
      {showAddMealModal &&
        (mealToRender?.[0]?.dayMealPlan?.meal_plan_type == "FoodBased" ? (
          <AddMealsModal
            isOpen={showAddMealModal}
            setIsOpen={setShowAddMealModal}
            item={mealToRender?.[0]?.dayMealPlan}
            getMealPlans={() => {}}
            isUpdate={true}
            onSuccess={(data) => {
              updateMealData(data);
            }}
          />
        ) : (
          mealToRender?.[0]?.dayMealPlan?.meal_plan_type == "MacrosBased" && (
            <AddMacrosBasedFood
              isOpen={showAddMealModal}
              setIsOpen={setShowAddMealModal}
              item={mealToRender?.[0]?.dayMealPlan}
              getMealPlans={() => {}}
              meals={mealToRender?.[0]?.dayMealPlan?.meals}
              onSuccess={(data) => {
                updateMealData(data);
              }}
            />
          )
        ))}
      <>
        {copiedDay?.day == workOutDay && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 999,
              background: "rgba(0,0,0,0.8)",
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#fff",
              fontSize: "16px",
              fontWeight: "bold",
              fontFamily: "DM Sans",
              borderRadius: "8px",
            }}
          >
            <CloseRounded
              sx={{
                cursor: "pointer",
                position: "absolute",
                top: 2,
                right: 2,
              }}
              onClick={() => setCopiedDay(null)}
            />
            Copied
          </div>
        )}
        <div className="week-card-conatiner-header">
          <p className="week-card-conatiner-header-title">{dayIndex}</p>
          <>
            {/* {addedWorkouts?.[workOutDay]?.length > 0 && ( */}
            {!(
              mealToRender == undefined || Object.keys(mealToRender).length == 0
            ) && (
              <>
                {canEdit && (
                  <div className="flex items-center gap-[8px]">
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        setWorkOutFor(workOutDay);
                        setUnsavedChanges(true);
                        setShowModal(true);
                      }}
                    >
                      <PlusRoundIcon />
                    </div>
                    {shouldShowAddAndDelete && (
                      <>
                        <div
                          className="cursor-pointer"
                          onClick={(e) => {
                            // setModalAlignment(workOutDay);
                            // handleClick(e);
                            removeDayWorkout(workOutDay);
                            setUnsavedChanges(true);
                          }}
                        >
                          <BinIcon height={16} width={16} />
                        </div>
                      </>
                    )}
                    {copiedDay?.day == null && shouldShowCopy && (
                      <CopyAllRounded
                        sx={{
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleCopyDay(workOutDay, mealToRender)}
                      />
                    )}
                  </div>
                )}
                {/* <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  sx={{}}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  PaperProps={{
                    style: {
                      margin: 0,
                      minWidth: "fit-content",
                      borderRadius: 10,
                    },
                  }}
                >
                  <MenuItem
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      backgroundColor: "#fff",
                    }}
                    onClick={() => {
                      removeDayWorkout(workOutDay);
                      setUnsavedChanges(true);
                      setAnchorEl(null);
                    }}
                  >
                    <p style={{ padding: 0 }} className="label-new font-DMSans">
                      Delete
                    </p>
                  </MenuItem>
                </Menu> */}
              </>
            )}
          </>
          {copiedDay?.day != null &&
            copiedDay?.day != workOutDay &&
            shouldPaste && (
              <ContentPasteGoRounded
                sx={{
                  height: 16,
                  width: 16,
                  cursor: "pointer",
                }}
                onClick={() => handlePasteDay(workOutDay)}
              />
            )}
        </div>
        {mealToRender == undefined || Object.keys(mealToRender).length == 0 ? (
          <div
            style={{
              // overflowY: `${
              //   addedWorkouts?.[workOutDay]?.length > 9 ? "scroll" : ""
              // }`,
              scrollbarWidth: "0",
            }}
            className="week-card-conatiner-draggable"
          >
            {canEdit && (
              <div
                style={{
                  height: "96px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={(e) => {
                    setWorkOutFor(workOutDay);
                    setUnsavedChanges(true);
                    setShowModal(true);
                  }}
                >
                  <PlusRoundIcon />
                </div>
              </div>
            )}
          </div>
        ) : (
          <div
            style={{
              fontSize: "12px",
              minHeight: "calc(100% + 8px)",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              gap: "8px",
              padding: "0 8px 8px 8px",
              backgroundColor: "transparent",
            }}
            className="flex flex-col text-blue-dark font-semibold"
            onClick={(e) => {
              setShowAddMealModal(true);
            }}
          >
            <div
              style={{
                // width: "13vw",
                // height: "4vh",
                padding: "12px 8px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                display: "flex",
                justifyContent: "space-between",
                background: colors[workOutDay % 3],
                borderRadius: "8px",
                alignItems: "center",
                position: "relative",
              }}
            >
              {dietType ? (
                <div
                  style={{ backgroundColor: getColorForDietType(dietType) }}
                  className="absolute top-0 right-0 text-font12 min-w-[50px] text-center px-1 text-white-pure"
                >
                  {dietType}
                </div>
              ) : null}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  flexDirection: "column",
                  zIndex: 1,
                }}
              >
                <>
                  <p className="week-card-conatiner-draggable-text">
                    {mealToRender[0]?.dayMealPlan?.reference_name}
                  </p>
                  {/* {workout?.[1]?.runTime > 0 && ( */}
                  <p className="week-card-conatiner-draggable-description">
                    {Math.round(
                      mealToRender[0]?.dayMealPlan?.plandetails?.calories
                    )}{" "}
                    CALS
                  </p>
                </>
                <div
                  style={{
                    display: "flex",
                    gap: "2px",
                  }}
                >
                  <p className="week-card-conatiner-draggable-description">
                    P:{" "}
                    {Math.round(
                      mealToRender[0]?.dayMealPlan?.plandetails?.macros?.protein
                    )}{" "}
                  </p>
                  <p className="week-card-conatiner-draggable-description">
                    C:{" "}
                    {Math.round(
                      mealToRender[0]?.dayMealPlan?.plandetails?.macros?.carbs
                    )}{" "}
                  </p>
                  <p className="week-card-conatiner-draggable-description">
                    F:{" "}
                    {Math.round(
                      mealToRender[0]?.dayMealPlan?.plandetails?.macros?.fat
                    )}{" "}
                  </p>
                </div>
                {/* )} */}
              </div>
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                }}
              >
                <BowlWhite />
              </div>
              {/* <img
                style={{
                  zIndex: 0,
                  height: 42,
                  width: 42,
                }}
                src={Bowl}
                alt="Bowl"
              /> */}
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default WeekMealContainer;
