import { useContext, useEffect, useState } from "react";
import SearchBar from "../../components/Dashboard/SearchBar";
import WorkoutInfoDialog from "../../components/WorkoutComponent/WorkoutInfoDialog";
import Layout from "../../components/layout/Layout";

import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import FilterIcon from "../../assets/workoutBuilder/filterIcon.svg";
import FilterActiveIcon from "../../assets/workouts/ActiveFunnel.svg";
import AddWorkoutBtn from "../../assets/workouts/AddWorkoutsBtn.svg";
import DeleteIcon from "../../assets/workouts/DeleteIcon.svg";

import { CustomAlertContext } from "../../App";
import NoWorkoutsView from "./NoWorkoutsView";
import WorkoutsTable from "./WorkoutsTable";

import { setWorkoutLibaryData } from "@/redux/dataSlice";
import { usePrefetch } from "@/services/apiSlice";
import { Box, Typography } from "@mui/material";
import axios from "axios";
import Fuse from "fuse.js";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  getAllWorkouts,
  getWorkoutsbyCoach,
} from "../../ApiServices/Workouts/Api";
import { SpurfitCircularProgress } from "../../components/Dashboard/dashboard";
import Copilot from "../../components/copilot/copilot";
import { ShowCopilotContext } from "../../components/layout/SpurfitLayout";
import FilterWorkoutDialog from "./FilterWorkoutDialog";
import { workoutContext } from "./WorkoutContex";
import WorkoutFolderPage from "./WorkoutFolderPage";

const Workouts = ({ location }) => {
  const {
    setData,
    data,
    setTempData,
    tempData,
    originalData,
    setOriginalData,
    setOpenWorkoutBuilder,
    openWorkoutBuilder,
    handleDataSettingInTable,
    setIsLoading,
    isLoading,
    setAllTags,
    allTags,
    selectedFilterOptions,
    setSelectedFilterOptions,
    setTempFolderData,
    folders,
    isLoadingForWorkout,
    setIsLoadingForWorkout,
    setWorkoutSelected,
    setShowContinueEditing,
    showContinueEditing,
  } = useContext(workoutContext);
  const APIURL = useSelector((state) => state.auth.apiURL);
  let preftechGlobalExercise = usePrefetch("getSpurfitExercises");
  const [showFolderTab, setShowFolderTab] = useState(false);

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [page, setPage] = useState(1);

  const [deleteWorkoutsEnabled, setDeleteWorkoutsEnabled] = useState(false);
  const [selectedWorkouts, setSelectedWorkouts] = useState([]);

  const [showFilterDialog, setShowFilterDialog] = useState(false);

  const [pageLimit, setPageLimit] = useState(10);
 

  const coachID = useSelector((state) => state.auth.trainerUUID);
  const adminID = useSelector((state) => state.auth?.data?.adminID);

  const gymAffiliation = useSelector(
    (state) => state.auth?.data?.gymAffliation
  );
  const isLivezy = gymAffiliation === "Livezy";

  const { displayAlert } = useContext(CustomAlertContext);

  const dispatch = useDispatch();

  const listDataSource = (data, currpage, limit) => {
    const start = currpage === 1 ? 0 : currpage * limit - limit;
    console.log(start);
    return new Promise((resolve) => {
      // console.log("_data ", data, " | ",typeof data)
      let _data = [...data];
      const result = _data.splice(start, limit);
      resolve(result);
    });
  };

  const getWorkout = async () => {
    setIsLoadingForWorkout(true);

    try {
      let trainerWorkouts = isLivezy
        ? await getWorkoutsbyCoach(coachID)
        : await getAllWorkouts(coachID, adminID);
      const res = await Promise.all([trainerWorkouts]);
      // here workout library drag and drop redux state is updating
      const workoutsNotConnectedToFolder = (res[0] || []).filter(
        (workout) => typeof workout?.workoutFolderId !== "string"
      );

      const trainer = workoutsNotConnectedToFolder;
      const sortedData = trainer.sort((a, b) => {
        const dateA = new Date(a.dateCreated || a.ModuleDate);
        const dateB = new Date(b.dateCreated || b.ModuleDate);
        return dateB - dateA || a?.name?.localeCompare(b.name || b.Name);
      });
      const _res = await listDataSource(sortedData, 1, pageLimit);

      setData(_res);
      setTempData(sortedData);
      setOriginalData(sortedData);
      setTotalPages(Math.ceil(sortedData.length / pageLimit));
      getTagsOfAllWorkouts(sortedData);
      if (res) {
        dispatch(setWorkoutLibaryData(res?.[0]));
      }
    } catch (err) {
      console.log(err);
      displayAlert({
        message: "An error occurred while fetching workouts.",
        type: "error",
      });
    } finally {
      setIsLoadingForWorkout(false);
    }
  };

  const requestSearch = async (searchedVal) => {
    try {
      if (typeof searchedVal !== "string") {
        displayAlert({
          message: "Invalid input",
          type: "error",
        });
        throw new Error("Invalid input");
      }

      if (searchedVal.trim() === "") {
        setData(paginate(originalData, currentPage, pageLimit));
        setTempData(originalData);
        setTotalPages(Math.ceil(originalData.length / pageLimit));
        setLoading(false);
        return;
      }

      setLoading(true);

      const fuseOptions = {
        keys: ["name", "Name"], // Properties to search within
        threshold: 0.1, // Adjust the threshold for fuzzy matching
      };

      const fuse = new Fuse(originalData, fuseOptions);
      const searchResults = fuse.search(searchedVal);

      const filteredRows = searchResults.map((result) => result.item);
      const paginatedRows = paginate(filteredRows, currentPage, pageLimit);
      setPage(1);

      setTempData(filteredRows);
      setTotalPages(Math.ceil(filteredRows.length / pageLimit));
      setData(paginatedRows);

      setLoading(false);
    } catch (error) {
      console.error(error);
      displayAlert({
        message: "An error occurred while searching. Please try again later.",
        type: "error",
      });
      setLoading(false);
    }
  };

  const paginate = (items, currentPage, pageSize) => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    return items.slice(startIndex, endIndex);
  };

  const cancelSearch = async () => {
    try {
      setLoading(true);

      setTempData([]);
      setTotalPages(Math.ceil(originalData.length / pageLimit));
      setData(paginate(originalData, currentPage, pageLimit));
      setPage(1);
      setLoading(false);
    } catch (error) {
      console.error(error);
      displayAlert({
        message: "An error occurred while searching. Please try again later.",
        type: "error",
      });
      setLoading(false);
    }
  };

  const onSort = (sortedData) => {
    setTempData(sortedData);
    setTotalPages(Math.ceil(sortedData.length / pageLimit));
    setData(paginate(sortedData, currentPage, pageLimit));
    setPage(1);
  };

  const onDeleteWorkouts = async () => {
    if (selectedWorkouts.length === 0) {
      setDeleteWorkoutsEnabled(false);
      return;
    }
    setLoading(true);
    await axios({
      method: "post",
      url: `${APIURL}CF/api/CoachWeb/Delete/deleteWorkoutWithAllDetailsJSON`,
      data: {
        workoutIDs: selectedWorkouts,
      },
    })
      .then((res) => {
        displayAlert({
          message: "Workouts deleted successfully.",
          type: "success",
        });

        getWorkout();
      })
      .catch((err) => {
        if (err.response.data.error === "P2003") {
          displayAlert({
            message: err?.response?.data?.message,
            type: "error",
          });
          getWorkout();
        } else {
          displayAlert({
            message: "An error occurred while deleting workouts.",
            type: "error",
          });
        }
      })
      .finally(() => {
        setDeleteWorkoutsEnabled(false);
        setLoading(false);
        setSelectedWorkouts([]);
      });
  };

  const onPage = (page) => {
    setPage(page);
    setData(paginate(tempData, page, pageLimit));
  };

  const nextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
      setData(paginate(tempData, page + 1, pageLimit));
    }
  };

  const previousPage = () => {
    if (page > 1) {
      setPage(page - 1);
      setData(paginate(tempData, page - 1, pageLimit));
    }
  };

  const getTagsOfAllWorkouts = async (originalData) => {
    let _allTags = [];
    originalData.forEach((workout) => {
      if (
        workout?.tag &&
        typeof workout?.tag === "string" &&
        workout?.tag?.trim() !== ""
      ) {
        let tags = workout?.tag?.split(",");
        // add only unique tags (case-insensitive)
        tags.forEach((tag) => {
          const trimmedTag = tag.trim().toLowerCase(); // Convert to lowercase
          if (
            !_allTags.some((existingTag) => existingTag === trimmedTag) &&
            trimmedTag !== ""
          ) {
            _allTags.push(trimmedTag);
          }
        });
      }
    });
    setAllTags(_allTags);
    console.log("All Unique tags", _allTags);
  };

  const filterWorkoutsByTags = async (tags) => {
    setSelectedFilterOptions(tags);
    if (tags.length === 0) {
      setData(paginate(originalData, currentPage, pageLimit));
      setTempFolderData(folders);
      setTempData(originalData);
      setLoading(false);
      setTotalPages(Math.ceil(originalData.length / pageLimit));
      return;
    }
    let filteredWorkouts = originalData.filter((workout) => {
      if (
        workout?.tag &&
        typeof workout?.tag === "string" &&
        workout?.tag?.trim() !== ""
      ) {
        let workoutTags = workout?.tag
          ?.split(",")
          .map((tag) => tag.trim().toLowerCase()); // Convert to lowercase
        // Check if any of the provided tags exist in the workout's tags
        if (tags.some((tag) => workoutTags.includes(tag.toLowerCase()))) {
          // Convert provided tag to lowercase
          return true;
        }
      }
      return false;
    });

    // filter the workouts which are in the folders

    let filteredFolderWorkouts = folders.filter((folder) => {
      if (folder?.workouts?.length > 0) {
        let unfilteredWorkouts = [...folder?.workouts];
        let filteredWorkouts = unfilteredWorkouts?.filter((workout) => {
          if (
            workout?.tag &&
            typeof workout?.tag === "string" &&
            workout?.tag?.trim() !== ""
          ) {
            let workoutTags = workout?.tag
              ?.split(",")
              .map((tag) => tag.trim().toLowerCase()); // Convert to lowercase
            // Check if any of the provided tags exist in the workout's tags
            if (tags.some((tag) => workoutTags.includes(tag.toLowerCase()))) {
              // Convert provided tag to lowercase
              return true;
            }
          }
          return false;
        });
        folder.workouts = filteredWorkouts;
        return folder;
      }
    });

    setTempFolderData(filteredFolderWorkouts);
    setTempData(filteredWorkouts);
    setTotalPages(Math.ceil(filteredWorkouts.length / pageLimit));
    setData(paginate(filteredWorkouts, currentPage, pageLimit));
  };

  useEffect(() => {
    const workoutInLocalStorage = localStorage.getItem("workoutData");
    if (workoutInLocalStorage) {
      setShowContinueEditing(true);
    } else {
      setShowContinueEditing(false);
    }
    getWorkout();
  }, []);

  useEffect(() => {
    if (coachID) {
      preftechGlobalExercise({ coachID, APIURL });
    }
  }, [coachID]);
  const { copilotOpen, setCopilotOpen } = useContext(ShowCopilotContext);
 

  const history = useHistory();
  // let unsavedWorkout = JSON.parse(workoutInLocalStorage || "{}");

  // const onResumeEditing = () => {
  //   if (
  //     !unsavedWorkout?.isBlockWorkoutBuilder &&
  //     (unsavedWorkout?.workoutKind === "run" ||
  //       unsavedWorkout?.workoutKind === "bike")
  //   ) {
  //     history.push("/EnduranceWorkoutBuilder", {
  //       retriveWorkout: true,
  //     });
  //   } else {
  //     history.push("/workoutBuilder", {
  //       state: {
  //         data: unsavedWorkout,
  //         isUpdate: unsavedWorkout?.id ? true : false,
  //       },
  //     });
  //   }
  // };
  return (
    <Layout>
      {!true ? (
        <>
          {workoutInLocalStorage && showContinueEditing && (
            <div className="flex w-full bg-red-300 mx-1 px-3 mb-1 rounded-md font-DMSans">
              Unsaved changes in &nbsp;
              <span className="font-semibold">
                {" "}
                {unsavedWorkout?.name}
              </span>{" "}
              workout
              <div className="ml-auto gap-4 flex items-center">
                <button
                  className="font-DMSans font-semibold"
                  onClick={onResumeEditing}
                >
                  Continue
                </button>
                <button
                  className="font-DMSans font-semibold"
                  onClick={() => {
                    setShowContinueEditing(false);
                    localStorage.removeItem("workoutData");
                  }}
                >
                  Discard
                </button>
              </div>
            </div>
          )}
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
              gap: "8px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent:
                  !loading && originalData?.length === 0
                    ? "center"
                    : "flex-start",
                height: "calc(100vh - 110px)",
                width: "100%",
                borderRadius: "12px",
                backgroundColor: "#fff",
                overflow: "hidden",
              }}
            >
              {loading && (
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    backgroundColor: "rgba(0,0,0,0.3)",
                    borderRadius: "0px 0px 16px 16px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 99,
                  }}
                >
                  <SpurfitCircularProgress />
                </div>
              )}
              <div
                style={{
                  padding: "16px 20px 0px 20px",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    borderRadius: "80px",
                    backgroundColor: "#F6F6FE",
                    padding: deleteWorkoutsEnabled
                      ? "8px 24px"
                      : "8px 24px 8px 0px",
                    display: "flex",
                    alignItems: "center",
                    gap: 16,
                    height: "56px",
                    justifyContent: "space-between",
                  }}
                >
                  {deleteWorkoutsEnabled ? (
                    <>
                      <span className="delete-workouts-text ">
                        Select workouts to delete
                      </span>

                      <div className="flex items-center gap-2">
                        <div
                          className="delete-workouts-cancel-btn"
                          onClick={() => {
                            setDeleteWorkoutsEnabled(false);
                            setSelectedWorkouts([]);
                          }}
                        >
                          Cancel
                        </div>
                        <div
                          onClick={onDeleteWorkouts}
                          className="delete-workouts-btn"
                        >
                          <img
                            src={DeleteIcon}
                            alt="Delete Icon"
                            style={{
                              height: "24px",
                              width: "24px",
                            }}
                          />
                          Delete {selectedWorkouts.length} Workouts
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <SearchBar
                        placeholder="Search Workouts"
                        style={{
                          borderRadius: "80px",
                          marginTop: 0,
                          marginBottom: 0,
                          boxShadow: "0px 1px 8px rgba(0, 0, 0, 0.19)",
                        }}
                        iconStyle={{
                          marginLeft: 5,
                        }}
                        bgcol={"#FFF"}
                        request={requestSearch}
                        cancel={cancelSearch}
                      />
                      <div className="flex gap-3">
                        <button
                          className="button-new "
                          onClick={() => setShowFolderTab(true)}
                        >
                          Folders
                        </button>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 16,
                          }}
                        >
                          {allTags?.length > 0 && (
                            <img
                              style={{
                                height: "40px",
                                width: "40px",
                                cursor: "pointer",
                              }}
                              onClick={() => setShowFilterDialog(true)}
                              src={
                                selectedFilterOptions.length > 0
                                  ? FilterActiveIcon
                                  : FilterIcon
                              }
                              alt="Filter Icon"
                            />
                          )}

                          <img
                            src={AddWorkoutBtn}
                            onClick={() => setOpenWorkoutBuilder(true)}
                            alt="Add Workout"
                            style={{
                              height: "40px",
                              cursor: "pointer",
                            }}
                          />

                          {/* {!isImport && (
                      <img
                        style={{
                          height: "40px",
                          width: "40px",
                          cursor: "pointer",
                        }}
                        src={DeleteBtn}
                        alt="Delete Btn"
                        onClick={() => {
                          if (
                            loading ||
                            originalData.length === 0 ||
                            data.length === 0
                          )
                            return;
                          setDeleteWorkoutsEnabled(!deleteWorkoutsEnabled);
                        }}
                      />
                    )} */}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              {!loading && originalData.length === 0 ? (
                <NoWorkoutsView setOpenWorkoutBuilder={setOpenWorkoutBuilder} />
              ) : !loading && data.length === 0 ? (
                <NoWorkoutsView
                  setOpenWorkoutBuilder={setOpenWorkoutBuilder}
                  message={"Can't found searched workout ..."}
                  subMessage={
                    "Try adding a new workout by clicking the button below"
                  }
                />
              ) : (
                <div
                  style={{
                    overflowY: "scroll",
                    width: "100%",
                    marginBottom: 80,
                  }}
                >
                  <WorkoutsTable
                    data={data}
                    deleteWorkoutsEnabled={deleteWorkoutsEnabled}
                    setDeleteWorkoutsEnabled={setDeleteWorkoutsEnabled}
                    selectedWorkouts={selectedWorkouts}
                    setSelectedWorkouts={setSelectedWorkouts}
                    getWorkouts={getWorkout}
                    originalData={originalData}
                    onSort={onSort}
                  />

                  <div
                    style={{
                      display: "flex",
                      width: "calc(100vw - 110px)",
                      position: "absolute",
                      justifyContent: "center",
                      bottom: "30px",
                      // margin: "auto",
                    }}
                  >
                    <div className="pagination-page-indicator">
                      <ArrowBackIosNewOutlinedIcon
                        className={`cursor-pointer ${
                          page !== 1 ? "" : "text-gray-400 cursor-not-allowed"
                        }`}
                        disabled={page == 1}
                        onClick={() => (page !== 1 ? previousPage() : () => {})}
                      />
                    </div>
                    <div className="pagination-page-container">
                      <div
                        className={
                          page == 1
                            ? "pagination-page-ui-active"
                            : "pagination-page-ui"
                        }
                        onClick={() => onPage(1)}
                      >
                        {1}
                      </div>
                      {page < 4 && totalPages > 1 && (
                        <div
                          className={
                            page == 2
                              ? "pagination-page-ui-active"
                              : "pagination-page-ui"
                          }
                          onClick={() => onPage(2)}
                        >
                          {2}
                        </div>
                      )}
                      {page < 4 && totalPages > 2 && (
                        <div
                          className={
                            page == 3
                              ? "pagination-page-ui-active"
                              : "pagination-page-ui"
                          }
                          onClick={() => onPage(3)}
                        >
                          {3}
                        </div>
                      )}
                      {page > 3 && totalPages > 3 && (
                        <div className="pagination-page-ui">{"..."}</div>
                      )}
                      {/* <div className={page + 1 > 1 ? "pagination-page-ui-active" :"pagination-page-ui"}>{page > 1 ? page : page +1}</div> */}
                      {page > 3 && page < totalPages - 1 && (
                        <div
                          className={
                            page > 3
                              ? "pagination-page-ui-active"
                              : "pagination-page-ui"
                          }
                          onClick={() => onPage(page)}
                        >
                          {page}
                        </div>
                      )}
                      {page < totalPages - 1 && totalPages > 4 && (
                        <div className="pagination-page-ui">{"..."}</div>
                      )}
                      {page > totalPages - 2 && totalPages > 4 && (
                        <div
                          className={
                            page == totalPages - 1
                              ? "pagination-page-ui-active"
                              : "pagination-page-ui"
                          }
                          onClick={() => onPage(totalPages - 1)}
                        >
                          {totalPages - 1}
                        </div>
                      )}
                      {totalPages > 3 && (
                        <div
                          className={
                            totalPages == page
                              ? "pagination-page-ui-active"
                              : "pagination-page-ui"
                          }
                          onClick={() => onPage(totalPages)}
                        >
                          {totalPages}
                        </div>
                      )}
                    </div>
                    <div>
                      <div className="pagination-page-indicator">
                        <ArrowForwardIosOutlinedIcon
                          className={`cursor-pointer ${
                            page < totalPages
                              ? ""
                              : "text-gray-400 cursor-not-allowed"
                          }`}
                          disabled={page >= totalPages}
                          onClick={() =>
                            page < totalPages ? nextPage() : () => {}
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {openWorkoutBuilder && (
              <WorkoutInfoDialog
                isOpen={openWorkoutBuilder}
                setIsOpen={setOpenWorkoutBuilder}
                onSuccess={handleDataSettingInTable}
              />
            )}
            {copilotOpen && (
              <Copilot
                open={copilotOpen}
                setOpen={setCopilotOpen}
                getWorkouts={getWorkout}
              />
            )}
          </Box>

          {showFilterDialog && (
            <FilterWorkoutDialog
              isOpen={showFilterDialog}
              setIsOpen={setShowFilterDialog}
              filterOptions={allTags}
              filterWorkoutsByTag={filterWorkoutsByTags}
              selectedFilterOptions={selectedFilterOptions}
            />
          )}
        </>
      ) : (
        
        <WorkoutFolderPage
          setShowFolderTab={setShowFolderTab}
          getWorkout={getWorkout}
          filterWorkoutsByTags={filterWorkoutsByTags}
        />
      )}
    </Layout>
  );
};

export default Workouts;
