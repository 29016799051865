import React, { useEffect, useState } from "react";
import { Avatar, useChatContext } from "stream-chat-react";
import { chatClient } from "../../../../utils/chat";
import SearchBar from "../../../../components/Dashboard/SearchBar";
import Fuse from "fuse.js";

import "./UserList.css";

import { InviteIcon, UserIcon } from "../../assets";
import { getStreamclient } from "../../../../utils/getStreamclients";
import toast from "../../../../components/Toast/toast";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import Dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { Alert, Box, Tab, Tabs, Typography } from "@mui/material";
import { getChannelPrograms } from "../../../../ApiServices/ChannelProgramTracker/Api";
import { deleteAssignedProgram } from "../../../../ApiServices/Programs/Api";

Dayjs.extend(relativeTime);

const ListContainer = (props) => {
  const { children } = props;

  return (
    <div className="user-list__container">
      <div className="user-list__header">
        <p>User</p>
        <p>Status</p>
        <p>Invite</p>
      </div>
      <div className="user-list__wrapper-container">{children}</div>
    </div>
  );
};

const UserItem = (props) => {
  const {
    index,
    setSelectedUsers,
    user,
    alreadyUser,
    handleRemoveMember,
    selectedUsers,
  } = props;

  const [selected, setSelected] = useState(false);
  const [disable, setDisable] = useState(false);

  const handleClick = () => {
    if (selected) {
      setSelectedUsers((prevUsers) =>
        prevUsers.filter((prevUser) => prevUser !== user.id)
      );
    } else {
      setSelectedUsers((prevUsers) => [...prevUsers, user.id]);
    }
    setSelected(!selected);
  };

  const getUserActivityStatus = (user) => {
    if (!user) return "";

    if (user.online) {
      return "Online";
    }

    if (Dayjs(user.last_active).isBefore(Dayjs())) {
      return `Last seen ${Dayjs(user?.last_active).fromNow()}`;
    }
  };

  const colors = [
    "#CCF8FE",
    "#FFE5D3",
    "#DAD7FE",
    "#E2FBD7",
    "#FFE5D3",
    "#DAD7FE",
    "#E2FBD7",
    "#CCF8FE",
  ];

  return (
    <div
      className={
        disable ? "user-item__wrapper-disable_list" : "user-item__wrapper"
      }
    >
      <div className="user-item__name-wrapper">
        {!user.image ? (
          <div
            className="channel-preview__item_avatar"
            style={{
              backgroundColor: colors[Math.round(parseInt(user.id) % 7)],
            }}
          >
            <UserIcon />
          </div>
        ) : (
          <Avatar image={user.image} name={user.name || user.id} size={32} />
        )}
        <p className="user-item__name">{user.name || user.id}</p>
      </div>
      <p className="user-item__last-active">{getUserActivityStatus(user)}</p>
      {alreadyUser ? (
        <div
          className={
            disable ? "user-item__delete disable" : "user-item__delete"
          }
        >
          <DeleteOutlineRoundedIcon
            sx={{ cursor: "pointer", color: "#b21e42" }}
            onClick={() => {
              handleRemoveMember(user.id);
              setDisable(true);
            }}
          />
        </div>
      ) : selected ? (
        <div onClick={handleClick}>
          <InviteIcon />
        </div>
      ) : (
        <div onClick={handleClick} className="user-item__invite-empty" />
      )}
    </div>
  );
};

export const UserList = (props) => {
  const {
    filters,
    setSelectedUsers,
    selectedUsers,
    editing,
    setFullUsersLists,
    users,
    setUsers,
  } = props;

  const { channel } = useChatContext();

  const NEW_USERS = 1;
  const EXISTING_USERS = 0;

  const [error, setError] = useState(false);
  const [listEmpty, setListEmpty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alreadyUser, setAlreadyUser] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [activeTab, setActiveTab] = useState(EXISTING_USERS);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const init = async () => {
    const getUsers = async () => {
      if (loading) return;
      setLoading(true);

      try {
        let trainerDetails = await JSON.parse(
          localStorage.getItem("trainerCredentials")
        );
        const data = await getStreamclient(trainerDetails);
        const sort = { user_id: -1 };
        if (channel?.type === "team" && editing) {
          const alreadyUser = await channel.queryMembers({}, sort, {});
          let arr = [];
          // console.log("memebers, ",channel.data.created_by)
          alreadyUser.members.map((key) => {
            arr.push(key.user_id);
          });
          setAlreadyUser(arr);
          console.log("alreadyUser", alreadyUser);
        } else {
          setActiveTab(NEW_USERS);
        }
        const response = await chatClient.queryUsers({ id: { $in: data } });

        if (response.users.length) {
          setUsers(response.users);
          setFullUsersLists(response.users);
          console.log(response.users);
        } else {
          setListEmpty(true);
        }
      } catch (err) {
        console.log(err);
        setError(true);
      }

      setLoading(false);
    };

    if (chatClient) getUsers();
  };

  useEffect(() => {
    init();
  }, [filters]); // eslint-disable-line react-hooks/exhaustive-deps

  const deleteAssignedProgramIfAny = async (userId) => {
    let channelProgram = await getChannelPrograms(channel.id);
    if (channelProgram && channelProgram.programID) {
      deleteAssignedProgram(
        userId,
        channelProgram.programID,
        channelProgram.progStartDate
      );
    }
  };

  const handleRemoveMember = async (userId) => {
    deleteAssignedProgramIfAny(userId);
    const removeMember = await channel.removeMembers([userId]);
    if (removeMember) {
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  };

  if (error) {
    return (
      <ListContainer>
        <div className="user-list__message">
          Error loading, please refresh and try again.
        </div>
      </ListContainer>
    );
  }

  if (listEmpty) {
    return (
      <ListContainer>
        <div className="user-list__message">No users found.</div>
      </ListContainer>
    );
  }

  return (
    <>
      {alreadyUser.length > 0 && (
        <Tabs
          sx={{
            ml: 2,
          }}
          value={activeTab}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Existing Users" {...a11yProps(0)} />
          <Tab label="New Users" {...a11yProps(1)} />
        </Tabs>
      )}
      <CustomTabPanel
        style={{
          width: "100%",
          height: "calc(100vh - 200px)",
          overflowY: "scroll",
        }}
        value={activeTab}
        index={NEW_USERS}
      >
        <ListContainer>
          {loading ? (
            <div className="user-list__message">Loading users...</div>
          ) : (
            <>
              {users?.length && users?.length > 0 ? (
                users.map((user, i) => {
                  if (alreadyUser.includes(user.id)) return null;
                  return (
                    <UserItem
                      index={i}
                      key={user.id}
                      setSelectedUsers={setSelectedUsers}
                      user={user}
                      alreadyUser={alreadyUser.includes(user.id)}
                      handleRemoveMember={handleRemoveMember}
                      selectedUsers={selectedUsers}
                    />
                  );
                })
              ) : (
                <></>
              )}
            </>
          )}
          <Box
            sx={{
              position: "fixed",
              bottom: 100,
              left: 0,
              right: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {showAlert && (
              <Alert severity="success">User removed successfully</Alert>
            )}
          </Box>
        </ListContainer>
      </CustomTabPanel>
      <CustomTabPanel
        style={{
          width: "100%",
          height: "calc(100vh - 200px)",
          overflowY: "scroll",
        }}
        value={activeTab}
        index={EXISTING_USERS}
      >
        <ListContainer>
          {loading ? (
            <div className="user-list__message">Loading users...</div>
          ) : (
            <>
              {users?.length && users?.length > 0 ? (
                users.map((user, i) => {
                  if (!alreadyUser.includes(user.id)) return null;
                  return (
                    <UserItem
                      index={i}
                      key={user.id}
                      setSelectedUsers={setSelectedUsers}
                      user={user}
                      alreadyUser={alreadyUser.includes(user.id)}
                      handleRemoveMember={handleRemoveMember}
                      selectedUsers={selectedUsers}
                    />
                  );
                })
              ) : (
                <></>
              )}
            </>
          )}
          <Box
            sx={{
              position: "fixed",
              bottom: 100,
              left: 0,
              right: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {showAlert && (
              <Alert severity="success">User removed successfully</Alert>
            )}
          </Box>
        </ListContainer>
      </CustomTabPanel>
    </>
  );
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    sx: {
      fontFamily: "DM Sans",
      textTransform: "none",
    },
  };
}
