import React, { useState } from "react";
import { MoreVert as MoreVertIcon } from "@mui/icons-material";
import { IconButton, Menu, MenuItem } from "@mui/material";

function CustomMenu({ options, onOptionClick, iconBtnStyles }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (option) => {
    onOptionClick(option);
    handleClose();
  };

  return (
    <>
      <IconButton
        sx={{
          ...iconBtnStyles,
        }}
        variant="square"
        className={"hover-effect"}
        onClick={handleClick}
      >
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Menu
        sx={{
          zIndex: 999999999,
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option) => (
          <MenuItem
            sx={{
              fontSize: "12px",
              fontFamily: "DM Sans",
              fontWeight: 500,
            }}
            key={option}
            onClick={() => handleOptionClick(option)}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default CustomMenu;
