import React, { useEffect, useState, useRef } from "react";
import ClientCard from "./ClientCard";
import {
  Box,
  Typography,
  useMediaQuery,
  Collapse,
  Tooltip,
} from "@mui/material";
import UncheckedIcon from "../../assets/trainerProfile/unchecked.svg";
import CheckedIcon from "../../assets/trainerProfile/checked.svg";
import {
  KeyboardArrowDownRounded,
  KeyboardArrowRightRounded,
  Podcasts,
} from "@mui/icons-material";
import { useSelector } from "react-redux";
import ClientsChannelList from "./ClientsChannelList";

const ClientList = ({
  clients,
  selectedClient,
  setSelectedClient,
  setShowClientDetails,
  isBoxOpen,
  setIsBoxOpen,
  userUnreadMessage,
  userWiseChannel,
  isBroadcast,
  setIsBroadcast,
  selectedClientsForBroadcast,
  setSelectedClientsForBroadcast,
  unreadMarks,
  setShowTemplates,
}) => {
  const gymAffiliation = useSelector(
    (state) => state.auth?.data?.gymAffliation
  );
  const onSelectClient = (client) => {
    setSelectedClient(client);
    setShowClientDetails(true);
    // set userUUID as client.UserUUID in url
    window.history.pushState(null, null, `?userUUID=${client.UserUUID}`);
  };

  const toggleBox = () => {
    setIsBoxOpen(!isBoxOpen);
  };

  const isSmallerThanMd = useMediaQuery((theme) =>
    theme.breakpoints.down("md")
  );
  const isLivezy = gymAffiliation === "Livezy";
  const selectedClientRef = useRef(null);

  useEffect(() => {
    if (selectedClientRef.current) {
      selectedClientRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [selectedClient]);

  return (
    <>
      <div className="w-[90%] h-[2px] bg-[#F2F2F2] rounded-xl ml-auto mr-auto"></div>
      <Box
        className="w-full  flex items-center justify-between"
        sx={{
          padding: "0px 10px",
        }}
      >
        <div className="flex items-center">
          {isBroadcast && (
            <img
              src={
                // if all selected
                selectedClientsForBroadcast.length === clients.length
                  ? CheckedIcon
                  : UncheckedIcon
              }
              alt="broadcast"
              className="cursor-pointer ml-4"
              onClick={() => {
                if (selectedClientsForBroadcast.length === clients.length) {
                  setSelectedClientsForBroadcast([]);
                } else {
                  setSelectedClientsForBroadcast(clients);
                }
              }}
            />
          )}
          <Typography
            sx={{
              padding: "10px 0px",
              backgroundColor: "#fff",
              fontSize: "12px",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              fontWeight: "bold",
            }}
            className="team-channel-list__header__title"
            onClick={toggleBox}
          >
            {isBoxOpen ? (
              <KeyboardArrowDownRounded />
            ) : (
              <KeyboardArrowRightRounded />
            )}
            Users
          </Typography>
        </div>
        {isLivezy && (
          <div
            onClick={() => setShowTemplates(true)}
            className="DMSans text-font14 font-semibold cursor-pointer underline underline-offset-1 ml-auto mr-3"
          >
            Templates
          </div>
        )}
        <Tooltip title="Toggle Broadcast">
          <Podcasts
            styleapp={{
              fontSize: "19px",
            }}
            className="cursor-pointer mr-1"
            onClick={() => {
              setIsBroadcast(!isBroadcast);
              setSelectedClientsForBroadcast([]);
            }}
          />
        </Tooltip>
      </Box>
      <Collapse
        in={isBoxOpen}
        timeout={500}
        sx={{
          transitionDuration: "500ms",
          transitionTimingFunction: "ease",
          height: isLivezy ? "100%" : isSmallerThanMd ? "100%" : "45%",
          overflowY: "scroll",
          padding: "0px 10px",
        }}
      >
        <Box
          sx={{
            // height: isSmallerThanMd ? "100%" : "70%",
            backgroundColor: "#fff",
          }}
        >
          {!isBroadcast && !isLivezy ? (
            <ClientsChannelList
              clients={clients}
              key={clients?.length}
              onSelectClient={onSelectClient}
              selectedClient={selectedClient}
              userUnreadMessage={userUnreadMessage}
              unreadMarks={unreadMarks}
              userWiseChannel={userWiseChannel}
            />
          ) : (
            <>
              {clients?.map((client) => {
                return (
                  <ClientCard
                    key={client?.UserUUID}
                    client={client}
                    onSelectClient={onSelectClient}
                    isSelected={selectedClient?.UserUUID === client?.UserUUID}
                    selectedRef={
                      selectedClient?.UserUUID === client?.UserUUID
                        ? selectedClientRef
                        : null
                    }
                    userUnreadMessage={userUnreadMessage}
                    unreadMarks={unreadMarks}
                    userWiseChannel={userWiseChannel}
                    isBroadcast={isBroadcast}
                    isSelectedForBroadcast={selectedClientsForBroadcast?.includes(
                      client
                    )}
                    onSelectForBroadcast={() => {
                      if (selectedClientsForBroadcast.includes(client)) {
                        setSelectedClientsForBroadcast(
                          selectedClientsForBroadcast.filter(
                            (item) => item.UserUUID !== client.UserUUID
                          )
                        );
                      } else {
                        setSelectedClientsForBroadcast([
                          ...selectedClientsForBroadcast,
                          client,
                        ]);
                      }
                    }}
                  />
                );
              })}
            </>
          )}
        </Box>
      </Collapse>
    </>
  );
};

export default ClientList;
