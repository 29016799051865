import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Form from "../Form";

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
      style={{
        transformOrigin: "bottom",
      }}
    />
  );
});

const CustomDialog = styled(Dialog)(({ theme }) => ({
  bottom: 0,
  left: 0,
  right: 0,
  margin: theme.spacing(2),
}));

const FormPreviewDialog = ({ open, handleClose, fields }) => {
  return (
    <React.Fragment>
      <CustomDialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
      >
        <Stack height={"100vh"} p={2} overflow={"auto"}>
          <Form fieldsData={fields} handleClose={handleClose} />
        </Stack>
      </CustomDialog>
    </React.Fragment>
  );
};

export default FormPreviewDialog;
