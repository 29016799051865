import { createContext, useState } from "react";

import axios from "axios";
import { useSelector } from "react-redux";

// Storage Box
export const NotificationContext = createContext({
  notifications: [],
  unreadCount: null,
  updateNotifications: () => {},
  readNotification: () => {},
});

function NotificationProvider({ children }) {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [readCount, setReadCount] = useState(0);

  const trainerUUID = useSelector((state) => state.auth.trainerUUID);

  const updateNotifications = async () => {
    const postData = {
      coachID: trainerUUID,
    };

    const allNotifications = await axios.post(
      `${APIURL}CF/api/trainerTasks/runTasksForOneCoach`,
      postData
    );

    // const allNotifications = await axios.get(
    //   `${APIURL}CF/api/trainerTasks/getAllTrainerTasks?coachID=${trainerUUID}`
    // );

    const notificationsData = allNotifications?.data?.result || [];
    // console.log(allNo);
    setNotifications(notificationsData);

    var notificationsCount = 0;

    notificationsData?.forEach((element) => {
      notificationsCount += 1;
    });

    setUnreadCount(notificationsCount);
  };

  const readNotification = async (notificationId) => {
    const postData = {
      taskID: notificationId,
    };
    await axios.post(
      `${APIURL}CF/api/trainerTasks/updateTrainerTasks`,
      postData
    );
  };

  const value = {
    notifications,
    updateNotifications,
    unreadCount,
    readCount,
    readNotification,
  };
  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
}

export default NotificationProvider;
