
import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { setIsAlertFormDialog } from '@/redux/popupStateSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";

const AlertExitDialog = () => {

    const dispatch = useDispatch()
    const history = useHistory()

    const isAlertDialog = useSelector((state) => state.popupState.isAlertDialog);

    const handleClickOpen = () => {
        dispatch(setIsAlertFormDialog(false));
        history.push('/formcontrol')
    };

    const handleClose = () => {
        dispatch(setIsAlertFormDialog(false));
    };

    return (
        <Dialog
            open={isAlertDialog}
            onClose={handleClose}
        >
            <DialogTitle sx={{ margin: '2rem' }}>
                Are you sure to exit ?
            </DialogTitle>
            <DialogActions>
                <Button onClick={handleClose} variant='outlined' className="add-program-modal-head">No</Button>
                <Button onClick={handleClickOpen} variant='contained' sx={{
                    borderRadius: 2,
                    alignSelf: "center",
                    fontSize: ".9rem",
                    wordSpace: "nowrap",
                    padding: ".5rem 1.5rem",
                    color: "#FFFFFF",
                    background:
                        "linear-gradient(to right, rgb(70, 79, 235), rgb(131, 48, 233))",
                    textTransform: "capitalize",
                }}>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AlertExitDialog
