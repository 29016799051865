import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useState } from "react";
import EditIcon from "../../assets/workoutBuilder/editIcon.svg";
import RemoveIcon from "../../assets/workouts/DeleteIcon.svg";

const TemplateBlock = ({ template, onEdit, onDelete, isAdmin }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [finished, setFinished] = useState(false);

  const open = Boolean(anchorEl);

  const handleTodoOptionsClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      className="py-2 font-semibold"
      style={{
        borderBottom: "1px solid #D8D6D6",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 3px",
        position: "relative",
      }}
    >
      <div
        className="grid gap-6 items-center"
        style={{
          gridTemplateColumns: "150px 1fr 100px",
        }}
      >
        {/* 3 columns todo, dateCreated, finishedAd */}
        {/* <img
          style={{
            height: "20px",
            width: "20px",
            marginRight: "12px",
            cursor: "pointer",
          }}
          key={template.id}
          src={finished ? CheckIcon : UncheckedIcon}
          alt=""
          onClick={() => {}}
        /> */}
        {/* finished at */}
        <div className="w-100 mr-2">
          <p
            className={`text-black-pure font-normal text-font12 font-DMSans `}
            style={{
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "21px",
            }}
          >
            {template.templateName}
          </p>
        </div>
        {/* todoText */}
        <div className="flex items-center gap-2 w-176 mr-4">
          <p
            className={`text-black-pure text-font14 font-DMSans `}
            style={{
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "21px",
              marginBottom: "3px",
            }}
          >
            {template?.templateMessage}
          </p>
        </div>
        {/* created at
        <div className="w-100 mr-2">
          <p
            className="text-black-pure font-normal text-font12 font-DMSans"
            style={{
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "21px",
            }}
          >
            {moment(dateCreated).utc().format("Do MMM YYYY")}
          </p>
        </div> */}
        {isAdmin && (
          <div className="flex items-center gap-2">
            <img
              className="cursor-pointer"
              onClick={() => {
                onEdit();
              }}
              src={EditIcon}
              alt=""
            />
            <img
              className="cursor-pointer"
              onClick={() => {
                onDelete();
              }}
              src={RemoveIcon}
              alt=""
            />
          </div>
        )}
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleTodoOptionsClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          "& .MuiPaper-root": {
            zIndex: 100,
            background: "#FFFFFF",
            borderRadius: "12px",
            boxShadow: "0px 4px 24px 2px rgba(33, 41, 60, 0.16)",
            padding: "4px",
          },
        }}
      >
        <MenuItem
          onClick={() => {
            onEdit();
          }}
          sx={{
            fontFamily: "DM Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "700",
            marginBottom: "4px",
          }}
        >
          Edit Template
        </MenuItem>
        <MenuItem
          onClick={() => {
            onDelete();
          }}
          sx={{
            fontFamily: "DM Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "700",
            color: "#FF3A29",
          }}
        >
          Delete Template
        </MenuItem>
      </Menu>
    </div>
  );
};

export default TemplateBlock;
