import React from "react";
import _ from "lodash";
import { Avatar, Box, Chip, Tooltip, Typography } from "@mui/material";
import { FOOD_BASED } from "../../../constants";
import { setToOneFixedDecimal } from "../AddMealPlans/AddMealsModal";
import { useSelector } from "react-redux";

const NItem = (props) => {
  const {
    defaultNItem = {},
    itf = {},
    setdefaultNItem = () => {},
    defaultTab,
    isAdmin,
    isOthersTab,
  } = props;

  const adminID = useSelector((state) => state.auth?.data?.adminID);
  let isAdminItem = adminID === (itf?.coachId || itf?.coachID);
  console.log("isAdminItem", { isAdminItem, adminID, itf });

  const getInitials = (string) => {
    const names = string?.split(" ");
    if (_.isEmpty(names)) return;

    let initials = names[0].substring(0, 1).toUpperCase();
    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  const renderAvatar = () => {
    const getImage = () => {
      switch (defaultTab) {
        case 1:
          return itf?.image || itf?.food_image;
        case 2:
          return itf?.recipe_images?.[0];
        case 3:
          return itf?.image;
        case 4:
          return itf?.image;
        default:
          return "";
      }
    };

    let currentImage = getImage();

    if (currentImage) {
      return <Avatar alt="Remy Sharp" src={currentImage} />;
    } else {
      return (
        <Avatar
          sx={{
            height: "40px",
            width: "40px",
          }}
        >
          {getInitials(
            itf?.food_name ||
              itf?.recipe_name ||
              itf?.reference_name ||
              itf?.display_name ||
              itf?.name
          )}
        </Avatar>
      );
    }
  };

  const renderDetails = () => {
    const getDetails = () => {
      switch (defaultTab) {
        case 1:
          let serving =
            itf?.servings?.[0] ||
            itf?.servings?.serving?.[0] ||
            itf?.serving?.[0];
          return (
            setToOneFixedDecimal(serving?.metric_serving_amount) +
              " " +
              serving?.metric_serving_unit || ""
          );
        case 2:
          return itf?.serving_sizes?.serving_size || "";
        case 3:
          return "";
      }
    };
    if (defaultTab === 4 || defaultTab === 5) {
      return (
        <div className="flex flex-row items-center">
          <span className="text-font12">{itf?.serving}</span>
          {itf.link ? (
            <>
              <div
                className="bg-[#000] h-[3px] w-[3px] mx-2"
                style={{
                  borderRadius: 50,
                }}
              />
              <a href={itf.link} className="text-font12">
                {"Link"}
              </a>
            </>
          ) : null}
        </div>
      );
    } else {
      return <span className="text-font12">{getDetails()}</span>;
    }
  };

  const renderCalories = () => {
    const getCalories = () => {
      switch (defaultTab) {
        case 1:
          let serving =
            itf?.servings?.[0] ||
            itf?.servings?.serving?.[0] ||
            itf?.serving?.[0];
          let calories = serving?.calories;
          if (calories) return Math.round(calories);
          else {
            let carbs = +serving?.carbohydrate;
            let protein = +serving?.protein;
            let fat = +serving?.fat;

            if (carbs && protein && fat) {
              return Math.round(carbs * 4 + protein * 4 + fat * 9);
            }
          }
          return 0;
        case 2:
          return Math.round(itf?.serving_sizes?.calories || 0);
        case 3:
          return Math.round(itf?.plandetails?.calories || 0);
        default:
          return 0;
      }
    };
    let currentCalories = getCalories();
    if (currentCalories === 0) return null;
    const mealPlanType = itf?.meal_plan_type;
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {defaultTab != 3 && (
          <div
            className="bg-[#000] h-[3px] w-[3px] mx-2"
            style={{
              borderRadius: 50,
            }}
          />
        )}
        <span
          style={{
            fontSize: 12,
            fontWeight: 600,
            color: "#6F8099",
          }}
          className="text-font12"
        >{`${getCalories()} Cals`}</span>
        {defaultTab === 3 && (
          <Tooltip
            title={
              mealPlanType === FOOD_BASED
                ? "Food Based Meal"
                : "Macro Based Meal"
            }
          >
            <Typography
              sx={{
                border: "1px solid #6F8099",
                fontSize: "8px",
                fontWeight: 500,
                color: "#6F8099",
                height: "14px",
                width: "16px",
                textAlign: "center",
                ml: 1,
              }}
            >
              {mealPlanType === FOOD_BASED ? "FB" : "MB"}
            </Typography>
          </Tooltip>
        )}
      </Box>
    );
  };

  const renderFromLibrary = () => {
    if (itf?.fromLibrary) {
      return (
        <div
          className="p-[2px] h-[15px] w-[15px] items-center justify-center flex ml-[10px]"
          style={{
            border: "1px solid #d9d9d9",
            borderRadius: 2,
          }}
        >
          <span className="text-font12 text-gray-600">{"F"}</span>
        </div>
      );
    }
  };

  return (
    <div
      className={
        itf === defaultNItem
          ? "program-card-container-selected"
          : "program-card-container"
      }
      // style={{
      //   backgroundColor: itf === defaultNItem ? "#6F809950" : "white",
      // }}
      onClick={() => {
        setdefaultNItem(itf);
      }}
    >
      <div
        className={
          itf === defaultNItem
            ? "channel-preview__wrapper_indicator__selected"
            : "channel-preview__wrapper_indicator"
        }
        style={{
          marginLeft: -10,
        }}
      ></div>
      <div
        style={{
          width: "72px",
          height: "48px",
          backgroundColor: "#F2F2F2",
          borderRadius: "8px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <NoImage /> */}
        {renderAvatar()}
        {/* <NoImageFound name={data.name} /> */}
      </div>
      <div
        style={{
          width: "100%",
        }}
        className="program-card-container-text"
      >
        <p className="program-card-container-text-heading">
          {itf?.name ||
            itf.food_name ||
            itf.recipe_name ||
            itf?.reference_name ||
            itf.display_name}
          {isAdminItem && (
            <span
              style={{
                fontSize: "8px",
                fontWeight: 700,
                color: "#09A033",
                padding: "5px",
                lineHeight: "10px",
                fontFamily: "DM Sans",
                backgroundColor: "#F7F7F7",
                borderRadius: "10px",
                marginLeft: "5px",
              }}
            >
              {"ADMIN"}
            </span>
          )}
        </p>

        <p
          className="program-card-container-text-discription"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {renderDetails()}
          {renderCalories()}
          {renderFromLibrary()}
        </p>
        {isOthersTab && (
          <span
            style={{
              fontSize: "8px",
              fontWeight: 700,
              color: "#09A033",
              padding: "5px",
              lineHeight: "10px",
              fontFamily: "DM Sans",
              backgroundColor: "#F7F7F7",
              borderRadius: "10px",
              marginLeft: "5px",
            }}
          >
            {itf?.coach?.name || "-"}
          </span>
        )}
      </div>
    </div>
  );
};

export default NItem;
