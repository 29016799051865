import { motion } from "framer-motion";
import React from "react";
import {
  Area,
  Bar,
  CartesianGrid,
  ComposedChart,
  Line,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { Label } from "@/components/ui/label";
import { LTTB } from "downsample";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Tokens } from "../styles/tokens";
import { toHHMMSS, useWindowSize } from "../utils";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { cn } from "@/lib/utils";
import moment from "moment";

const CustomTooltip = ({ active, payload, label }) => {
  let listItems = payload.map((item) => {
    if (item.name == "Elevation") {
      return (
        <li
          key={item.name}
          style={{ listStyle: "none", display: "flex", alignItems: "center" }}
        >
          <div
            style={{
              width: 8,
              height: 8,
              borderRadius: "50%",
              marginRight: 8,
              display: "block",
              backgroundColor: item.color,
            }}
          />
          {item.name}:{" "}
          <strong>
            {Math.round(item.value * 1000)} {item.unit || ""}
          </strong>
        </li>
      );
    } else {
      return (
        <li
          key={item.name}
          style={{ listStyle: "none", display: "flex", alignItems: "center" }}
        >
          <div
            style={{
              width: 8,
              height: 8,
              borderRadius: "50%",
              marginRight: 8,
              display: "block",
              backgroundColor: item.color,
            }}
          />
          {item.name}:{" "}
          <strong>
            {item.name === "Pace"
              ? item.value.toFixed(2)
              : Math.round((item.value * 100) / 100)}{" "}
            {item.unit || ""}
          </strong>
        </li>
      );
    }
  });
  if (active && payload && payload.length) {
    let timerTime = payload[0].payload.elapsed_time;
    return (
      <div className="p-4 bg-white-pure rounded-md shadow-md border border-gray-600">
        <span className="text-gray-600 text-xs mb-2 block">
          {toHHMMSS(timerTime)}
        </span>
        <hr className="mb-1 border-gray-500"></hr>
        {listItems}
      </div>
    );
  }

  return null;
};

const container = {
  hidden: { opacity: 0 },
  show: { opacity: 1, transition: { staggerChildren: 0.15 } },
};

const item = { hidden: { opacity: 0, y: 24 }, show: { opacity: 1, y: 0 } };

export default function ChartStacked({
  data,
  recs,
  selectedLap,
  selectedWindows,
  setSelectedWindows,
  simplified,
  xAxisDataKey,
  setXAxisDataKey,
}) {
  const size = useWindowSize();
  const [resolution, setResolution] = React.useState(200);
  const [scale, setScale] = React.useState(1);
  const [brush, setBrush] = React.useState({ startIndex: 0, endIndex: 1000 });

  const location = useLocation();

  React.useEffect(() => {
    if (size.width > 500) {
      setResolution(500);
    } else if (size.width > 1000) {
      setResolution(1000);
    } else if (size.width > 2000) {
      setResolution(2000);
    } else {
      setResolution(200);
    }
    // if(size.width > )
  }, [size]);

  // this is a workaround until I figure out how to use the advanced API from https://github.com/janjakubnanista/downsample#advanced-api

  console.log("simplified", simplified);

  const isPowerAvailable = simplified?.some((item) => item.power > 0);
  const isPaceAvailable = simplified?.some(
    (item) => item.speed > 0 || item.enhanced_speed > 0
  );
  const isCadenceAvailable = simplified?.some((item) => item.cadence > 0);

  const isHeartRateAvailable = simplified?.some((item) => item.heart_rate > 0);
  const isEnhancedHeartRateAvailable = simplified?.some(
    (item) => item.enhanced_heart_rate > 0
  );
  const isStrideLengthAvailable = simplified?.some(
    (item) => item.step_length > 0
  );
  const isAltitudeAvailable = simplified?.some((item) => item.altitude > 0);

  const isRun = location?.state?.data.activityType === "RUNNING";

  const charts = [
    {
      condition: isPowerAvailable,
      datakey: "power",
      name: "Power",
      unit: "W",
      color: Tokens.power,
    },
    {
      condition: isPaceAvailable,
      datakey: "pace",
      name: "Pace",
      unit: "min/km",
      color: Tokens.speed,
    },
    {
      condition: isHeartRateAvailable || isEnhancedHeartRateAvailable,
      datakey: isHeartRateAvailable ? "heart_rate" : "enhanced_heart_rate",
      name: "Heart rate",
      unit: "bpm",
      color: Tokens.heartrate,
      max:
        selectedLap?.max_heart_rate ??
        location?.state?.data?.maxHeartRateInBeatsPerMinute,
      avg:
        selectedLap?.avg_heart_rate ??
        location?.state?.data?.averageHeartRateInBeatsPerMinute,
    },
    {
      condition: isCadenceAvailable,
      datakey: "cadence",
      name: "Cadence",
      unit: "rpm",
      color: Tokens.cadence,
      max: selectedLap?.max_cadence
        ? isRun
          ? selectedLap?.max_cadence * 2
          : selectedLap?.max_cadence
        : location?.state?.data?.maxRunCadenceInStepsPerMinute,
      avg: selectedLap?.avg_cadence
        ? isRun
          ? selectedLap?.avg_cadence * 2
          : selectedLap?.avg_cadence
        : location?.state?.data?.averageRunCadenceInStepsPerMinute,
    },
    {
      condition: isAltitudeAvailable,
      datakey: "altitude",
      name: "Elevation",
      unit: "km",
      color: Tokens.elevation,
      max: location?.state?.data?.maxAltitudeInMeters,
      avg: location?.state?.data?.averageAltitudeInMeters,
    },
    {
      condition: isStrideLengthAvailable,
      datakey: "step_length",
      name: "Stride length",
      unit: "m",
      color: Tokens.stridelength,
    },
    // Add other charts here...
  ];

  // if is run then the order of charts will be Pace, HR, Cadence and Power
  // if is cycling then the order will be Power, HR, Cadence and Pace
  if (isRun) {
    charts.sort((a, b) => {
      if (a.name === "Pace") {
        return -1;
      }
      if (b.name === "Pace") {
        return 1;
      }
      if (a.name === "Power") {
        return 1;
      }
      if (b.name === "Power") {
        return -1;
      }
      return 0;
    });
  } else {
    charts.sort((a, b) => {
      if (a.name === "Power") {
        return -1;
      }
      if (b.name === "Power") {
        return 1;
      }
      if (a.name === "Pace") {
        return 1;
      }
      if (b.name === "Pace") {
        return -1;
      }
      return 0;
    });
  }

  return (
    <motion.div
      variants={container}
      initial="hidden"
      animate="show"
      className="overflow-hidden rounded-[16px] shadow-lg bg-white-pure"
    >
      <div className="flex w-full items-center justify-between p-5 border-b ">
        <h2 className="font-DMSans text-font16 font-bold text-[#242424]">
          {" "}
          Entire Workout{" "}
        </h2>
        <div className="flex items-center rounded-full overflow-hidden bg-[#F8F8F8] p-1">
          <div
            className={cn(
              "cursor-pointer transition-colors duration-200 rounded-full w-[100px] text-center text-font14 font-semibold",
              xAxisDataKey === "elapsed_time"
                ? "text-white-pure bg-[#6360F9]"
                : ""
            )}
            onClick={() => setXAxisDataKey("elapsed_time")}
            style={{
              padding: "8px 10px 8px 10px",
            }}
          >
            Time
          </div>
          <div
            className={cn(
              "cursor-pointer  transition-colors duration-200 rounded-full w-[100px] text-center text-font14 font-semibold",
              xAxisDataKey === "distance"
                ? "text-white-pure bg-[#6360F9]"
                : "text-gray-600"
            )}
            onClick={() => setXAxisDataKey("distance")}
            style={{
              padding: "8px 10px 8px 10px",
            }}
          >
            Distance
          </div>
        </div>
      </div>
      {charts.map(
        (chart) =>
          chart.condition && (
            <ChartCard
              data={simplified}
              datakey={chart.datakey}
              name={chart.name}
              unit={chart.unit}
              color={chart.color}
              brushStartIndex={brush.startIndex}
              brushEndIndex={brush.endIndex}
              max={chart.max}
              avg={chart.avg}
              xAxisDataKey={xAxisDataKey}
              windowedPeaks={selectedWindows}
            />
          )
      )}
    </motion.div>
  );
}

const ChartCard = ({
  data,
  datakey,
  name,
  unit,
  color,
  brushStartIndex,
  brushEndIndex,
  max,
  avg,
  xAxisDataKey,
  windowedPeaks,
}) => {
  const [toggleAltitude, setToggleAltitude] = React.useState(false);
  const [selectedForOverlay, setOverlayOptions] = React.useState(null);
  const [selectedData, setSelectedData] = React.useState(null);

  const overlayOptions = ["Power", "Speed", "Cadence", "Heart rate"];

  const selectedOptionData = {
    Power: {
      datakey: "power",
      name: "Power",
      color: Tokens.power,
      unit: " W",
    },
    Speed: {
      datakey: "speed",
      name: "Speed",
      color: Tokens.speed,
      unit: " kph",
    },
    Cadence: {
      datakey: "cadence",
      name: "Cadence",
      color: Tokens.cadence,
      unit: " rpm",
    },
    "Heart rate": {
      datakey: "heart_rate",
      name: "Heart rate",
      color: Tokens.heartrate,
      unit: " bpm",
    },
  };

  const getStart = (startTime) => {
      let startValue = data.reduce((prev, curr) => {
        return Math.abs(new Date(curr.timestamp).getTime() - startTime * 1000) <
          Math.abs(new Date(prev.timestamp).getTime() - startTime * 1000)
          ? curr
          : prev;
      });

       if(xAxisDataKey === "distance"){
        return startValue?.distance
       }else{
        return startValue?.elapsed_time;
       }
      
    }
  

  const getEnd = (endTime) => {
   
      let endValue = data.reduce((prev, curr) => {
        return Math.abs(new Date(curr.timestamp).getTime() - endTime * 1000) <
          Math.abs(new Date(prev.timestamp).getTime() - endTime * 1000)
          ? curr
          : prev;
      });

     
      if(xAxisDataKey === "distance"){
        return endValue?.distance
       }else{
        return endValue?.elapsed_time;
       }
       
    }
  

  return (
    <motion.div
      variants={item}
      style={{ width: "100%", height: "400px" }}
      className="p-6 bg-white-pure"
    >
      <div className="flex items-center justify-between pb-4">
        <div className="mr-5">
          <h1 className="text-xl font-semibold ">{name}</h1>
          <div className="flex items-center gap-2">
            {max && (
              <div className="flex items-center gap-1">
                <span className="text-xs">Max:</span>
                <span className="text-xs font-semibold">{Math.round(max)}</span>
                <span className="text-xs">{unit}</span>
              </div>
            )}
            {avg && (
              <>
                <div className="h-[16px] w-[2px] bg-gray-500"></div>
                <div className="flex items-center gap-1">
                  <span className="text-xs">Avg:</span>
                  <span className="text-xs font-semibold">
                    {Math.round(avg)}
                  </span>
                  <span className="text-xs">{unit}</span>
                </div>
              </>
            )}
          </div>
        </div>

        <div className="flex flex-1 items-center justify-end gap-1 min-w-fit">
          {name !== "Elevation" && (
            <>
              <input
                type="checkbox"
                onChange={() => setToggleAltitude(!toggleAltitude)}
              />
              <Label className="text-sm">Show Elevation</Label>
            </>
          )}
          <Select
            onValueChange={(value) => {
              if (value === "clear") {
                setOverlayOptions(null);
                setSelectedData(null);
              } else {
                setOverlayOptions(value);
                setSelectedData(selectedOptionData[value]);
              }
            }}
          >
            <SelectTrigger className="w-[180px] focus:ring-transparent">
              <SelectValue
                className="font-DMSans text-font16"
                placeholder="Show overlay"
              />
            </SelectTrigger>
            <SelectContent>
              {overlayOptions.map((option) => {
                if (option === name) return null;
                return (
                  <SelectItem
                    className="font-DMSans text-font16"
                    key={option}
                    value={option}
                  >
                    {option}
                  </SelectItem>
                );
              })}
              {selectedData && (
                <SelectItem
                  className="font-DMSans text-font16"
                  key={"clear"}
                  value={"clear"}
                >
                  Clear
                </SelectItem>
              )}
            </SelectContent>
          </Select>
        </div>
      </div>
      <ResponsiveContainer debounce={0.2} height={"100%"}>
        <ComposedChart
          syncId={"stacked"}
          data={data}
          margin={{ top: 24, bottom: 44 }}
        >
          <YAxis
            dataKey={datakey}
            domain={["auto", "dataMax"]}
            width={80}
            stroke={"#ccc"}
            tickCount={5}
            axisLine={false}
            tickSize={0}
            mirror={true}
            tick={{ fontSize: 14, dy: -12, dx: -2 }}
            tickFormatter={(val, i) => {
              if (val > 0 && i == 4) {
                return `${val} ${unit}`;
              } else if (val > 0) {
                return `${val}`;
              }

              return "";
            }}
          />
          {toggleAltitude && (
            <YAxis
              yAxisId="altitude"
              dataKey={"altitude"}
              domain={["auto", "dataMax"]}
              tickCount={5}
              axisLine={false}
              tickSize={0}
              hide={true}
              tickFormatter={(value) => Math.round(value * 1000)}
              unit=" m"
              tick={{ fontSize: 14, dy: -10, dx: -2 }}
            />
          )}
          {selectedData && (
            <YAxis
              yAxisId={selectedData.datakey}
              dataKey={selectedData.datakey}
              domain={["auto", "dataMax"]}
              tickCount={5}
              axisLine={false}
              tickSize={0}
              hide={true}
              tickFormatter={(value) => Math.round(value * 1000)}
              unit={selectedOptionData[selectedForOverlay].unit}
              tick={{ fontSize: 14, dy: -10, dx: -2 }}
            />
          )}

          <Tooltip
            cursor={{ stroke: "black" }}
            content={<CustomTooltip />}
            isAnimationActive={false}
          />
          <CartesianGrid
            vertical={false}
            strokeDasharray="4"
            strokeOpacity={0.9}
          />

          <defs>
            <linearGradient
              id={`${color}_gradient`}
              x1="0"
              y1="0"
              x2="0"
              y2="1"
            >
              <stop offset="20%" stopColor={color} stopOpacity={1} />
              <stop offset="95%" stopColor={color} stopOpacity={0} />
            </linearGradient>
          </defs>

          <Line
            name={name}
            isAnimationActive={false}
            dataKey={datakey}
            stroke={color}
            fill={"rgba(131, 48, 233, .1)"}
            dot={false}
            activeDot={{ r: 4 }}
          />

          <XAxis
            // type={"number"}
            dataKey={xAxisDataKey}
            interval={"preserveStartEnd"}
            // orientation={"bottom"}
            padding={{ left: 40 }}
            type="number"
            minTickGap={200}
            tickCount={3}
            // axisLine={false}
            domain={[1000, "auto"]}
            tick={{
              fontSize: 14,
              fill: "#CCC",
              color: "#FFF",
            }}
            tickFormatter={(val) => {
              if (xAxisDataKey === "elapsed_time") {
                return toHHMMSS(val);
              }
              return val.toFixed(2) + " km";
            }}
          />

          {toggleAltitude && (
            <Line
              yAxisId="altitude"
              name="Elevation"
              isAnimationActive={false}
              dataKey="altitude"
              stroke={"rgba(131, 48, 233, 1)"}
              fill={"rgba(131, 48, 233, .1)"}
              dot={false}
              unit={"m"}
              activeDot={{ r: 4 }}
              tickFormatter={(val) => val * 1000}
            />
          )}
          {selectedData && (
            <Line
              // type="monotone"
              yAxisId={selectedData.datakey}
              name={selectedData.name}
              isAnimationActive={false}
              dataKey={selectedOptionData[selectedForOverlay].datakey}
              stroke={selectedOptionData[selectedForOverlay].color}
              fill={`url(#${selectedOptionData[selectedForOverlay].color}_gradient)`}
              dot={false}
              unit={selectedData.unit}
              activeDot={{ r: 4 }}
              tickFormatter={(val) => val * 1000}
            />
          )}

          {Object.keys(windowedPeaks ?? {})?.map((key) => {
            let peaks = windowedPeaks[key];
            return (
              <>
                {peaks?.map((peak) => {
                  return (
                    <ReferenceArea
                      x1={getStart(peak[0])}
                      x2={getEnd(peak[1])}
                      stroke="red"
                      strokeOpacity={0.3}
                    />
                  );
                })}
              </>
            );
          })}

          {/* <ReferenceArea
            x1={getStart()}
            x2={getEnd()}
            stroke="red"
            strokeOpacity={0.3}
          /> */}
        </ComposedChart>
      </ResponsiveContainer>
    </motion.div>
  );
};

{
  /* {isPowerAvailable && (
        <ChartCard
          data={simplified}
          datakey={"power"}
          name={"Power"}
          unit={"W"}
          color={Tokens.power}
          brushStartIndex={brush.startIndex}
          brushEndIndex={brush.endIndex}
          xAxisDataKey={xAxisDataKey}
          windowedPeaks={selectedWindows}
        />
      )}

      {isPaceAvailable && (
        <>
          <ChartCard
            data={simplified}
            datakey={"pace"}
            name={"Pace"}
            unit={"min/km"}
            color={Tokens.speed}
            brushStartIndex={brush.startIndex}
            brushEndIndex={brush.endIndex}
            xAxisDataKey={xAxisDataKey}
            windowedPeaks={selectedWindows}
          />
        </>
      )}

      {isHeartRateAvailable ? (
        <ChartCard
          data={simplified}
          datakey={"heart_rate"}
          name={"Heart rate"}
          unit={"bpm"}
          color={Tokens.heartrate}
          brushStartIndex={brush.startIndex}
          brushEndIndex={brush.endIndex}
          max={
            selectedLap?.max_heart_rate ??
            location?.state?.data?.maxHeartRateInBeatsPerMinute
          }
          avg={
            selectedLap?.avg_heart_rate ??
            location?.state?.data?.averageHeartRateInBeatsPerMinute
          }
          xAxisDataKey={xAxisDataKey}
          windowedPeaks={selectedWindows}
        />
      ) : isEnhancedHeartRateAvailable ? (
        <ChartCard
          data={simplified}
          datakey={"enhanced_heart_rate"}
          name={"Heart rate"}
          unit={"bpm"}
          color={Tokens.heartrate}
          brushStartIndex={brush.startIndex}
          brushEndIndex={brush.endIndex}
          max={
            selectedLap?.max_heart_rate ??
            location?.state?.data?.maxHeartRateInBeatsPerMinute
          }
          avg={
            selectedLap?.avg_heart_rate ??
            location?.state?.data?.averageHeartRateInBeatsPerMinute
          }
          xAxisDataKey={xAxisDataKey}
          windowedPeaks={selectedWindows}
        />
      ) : null}

      {isCadenceAvailable && (
        <ChartCard
          data={simplified}
          datakey={"cadence"}
          name={"Cadence"}
          unit={"rpm"}
          color={Tokens.cadence}
          brushStartIndex={brush.startIndex}
          brushEndIndex={brush.endIndex}
          max={
            selectedLap?.max_cadence
              ? isRun
                ? selectedLap?.max_cadence * 2
                : selectedLap?.max_cadence
              : location?.state?.data?.maxRunCadenceInStepsPerMinute
          }
          avg={
            selectedLap?.avg_cadence
              ? isRun
                ? selectedLap?.avg_cadence * 2
                : selectedLap?.avg_cadence
              : location?.state?.data?.averageRunCadenceInStepsPerMinute
          }
          xAxisDataKey={xAxisDataKey}
          windowedPeaks={selectedWindows}
        />
      )}

      {isAltitudeAvailable && (
        <ChartCard
          data={simplified}
          datakey={"altitude"}
          name={"Elevation"}
          unit={"km"}
          color={Tokens.elevation}
          brushStartIndex={brush.startIndex}
          brushEndIndex={brush.endIndex}
          max={location?.state?.data?.maxAltitudeInMeters}
          avg={location?.state?.data?.averageAltitudeInMeters}
          xAxisDataKey={xAxisDataKey}
          windowedPeaks={selectedWindows}
        />
      )}

      {isStrideLengthAvailable && (
        <ChartCard
          data={simplified}
          datakey={"step_length"}
          name={"Stride length"}
          unit={"m"}
          color={Tokens.stridelength}
          brushStartIndex={brush.startIndex}
          brushEndIndex={brush.endIndex}
          xAxisDataKey={xAxisDataKey}
          windowedPeaks={selectedWindows}
        />
      )} */
}
