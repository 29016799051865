import { toggleLayoutWidth } from "@/redux/dataSlice";
import HelpIcon from "@mui/icons-material/Help";
import {
  Alert,
  Avatar,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import SparkleWhiteLogo from "../../assets/copilot/chatSparkleWhite.svg";
import DashboardIcon from "../../assets/dashboard/dashboard.png";
import DollarSign from "../../assets/dashboard/dollarIcon1.png";
import ChangePasswordModal from "../../pages/profile/ChangePasswordModal";
import { logout, setTrainerData } from "../../redux/authSlice";
import {
  setIsNextFinish,
  setPageStatus,
  setShowBackButton,
  setTourIndex,
  setTourRun,
  setIsAlertFormDialog
} from "../../redux/popupStateSlice";
import { persistor, store } from "../../redux/store";
import LockIcon from "./assests/lock.svg";
import LogoutIcon from "./assests/logout.svg";
import UserIcon from "./assests/user.svg";
// import { NotificationContext } from "../Context/NotificationContext/NotificationContext";
import { logOutCoach } from "@/ApiServices/Coach/Api";
import { ArrowLeft } from "lucide-react";
import mixpanel from "mixpanel-browser";
import { mixPanelInit } from "../../utils/functions";
import { chatClient } from "@/utils/chat";
import Button from "@mui/material/Button";
import { setUserEditFormFunc, resetUserFormFunc } from "@/redux/formSlice";

import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

export default function TopBar({ setOpenCopilot }) {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const [profileImage, setProfileImage] = useState("");
  const [copyLinkAlert, setCopyLinkAlert] = useState(false);
  const trainerUUID = useSelector((state) => state.auth.trainerUUID);
  const isAdmin = useSelector((state) => state.auth?.data?.isAdmin);
  const trainerName = useSelector((state) => state.auth?.data?.name);
  const adminID = useSelector((state) => state.auth?.data?.adminID);
  // const seenNotification = useSelector((state) => state.auth?.seenNotification);
  const tourIndex = useSelector((state) => state.popupState.tourIndex);
  const tourRun = useSelector((state) => state.popupState.tourRun);
  const pageStatus = useSelector((state) => state.popupState.pageStatus);
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);
  const [dynamicPadding, setPadding] = useState("110px");
  const dispatch = useDispatch();
  const { pathname, search } = useLocation()

  let isExpandedSideBar = useSelector((state) => state.data.isExpanded);
  console.log("admin id", adminID);
  useEffect(() => {
    if (isExpandedSideBar) {
      setPadding("214px");
    } else {
      setPadding("110px");
    }
  }, [isExpandedSideBar]);
  // const { unreadCount, updateNotifications } = useContext(NotificationContext);
  // Function to handle button click and open the notification popover
  // const handleNotificationClick = (event) => {
  //   setNotificationAnchorEl(event.currentTarget);
  //   if (!seenNotification) {
  //     dispatch(setSeenNotification(true));
  //   }
  // };

  // Function to handle notification popover close
  const handleNotificationClose = () => {
    setNotificationAnchorEl(null);
  };

  // const notificationOpen = Boolean(notificationAnchorEl);
  // const notificationId = notificationOpen ? "notification-popover" : undefined;

  const getProfileData = async () => {
    await axios({
      method: "get",
      url: `${APIURL}CF/api/CoachWeb/Get/oneTrainerProfile`,
      params: {
        coachID: store.getState().auth.trainerUUID,
      },
    })
      .then((res) => {
        console.log("profiledata", res);
        let walkthroughStatus = res.data.result?.walkthroughStatus;
        if (walkthroughStatus) {
          let newPageStatus = {
            ...pageStatus,
            ...walkthroughStatus,
          };

          dispatch(setPageStatus(newPageStatus));
          let pageName = getPageName();
          if (
            pageName === "home" &&
            !(
              walkthroughStatus?.["home"]?.finished ||
              walkthroughStatus?.["home"]?.skipped
            )
          ) {
            dispatch(setTourIndex(0));
            dispatch(setTourRun(true));
          }
        } else {
          dispatch(setTourIndex(0));
          dispatch(setTourRun(true));
        }

        setProfileImage(res.data.result?.imageKey);
        dispatch(setTrainerData(res.data.result));
      })
      .catch((err) => {
        console.log("error dashboard: ", err);
      });
  };

  useEffect(() => {
    if (!trainerUUID) return;
    getProfileData();
    // updateNotifications();
    mixPanelInit();
  }, [trainerUUID]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [changePasswordModalOpen, setChangePasswordModalOpen] =
    React.useState(false);
  const history = useHistory();
  const location = window.location.pathname;

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = async (link) => {
    setAnchorEl(null);
    if (link == "/logout") {
      let isLivezy = store.getState().auth.isLivezy;
      logOutCoach(trainerUUID);
      dispatch(logout());
      await persistor.flush();
      await persistor.purge();
      localStorage.clear();
      chatClient.disconnect();
      // go to /livezy
      if (isLivezy) {
        window.location.href = "/livezy";
      } else {
        window.location.href = "/";
      }
      // window.location.reload();
    } else if (link == "/changepassword") {
      setChangePasswordModalOpen(true);
    } else {
      history.push(link);
    }
  };
  const copyToClipboard = async () => {
    try {
      const getCurrentURl = window.location.origin;
      const coplyURL = getCurrentURl + "/affiliateSignup/" + trainerUUID;
      await navigator.clipboard.writeText(coplyURL);
      setCopyLinkAlert(true);
      setTimeout(() => {
        setCopyLinkAlert(false);
      }, 3000);
    } catch (err) {
      console.error("Failed to copy link: ", err);
    }
  };

  const data = [
    {
      name: "View Profile",
      link: "/profile",
      icon: <img src={UserIcon} alt="user icon" />,
    },
    {
      name: "Change Password",
      link: "/changepassword",
      icon: <img src={LockIcon} alt="lock icon" />,
    },
    {
      name: "Logout",
      link: "/logout",
      icon: <img src={LogoutIcon} alt="logout icon" />,
    },
  ];

  let RouteRange = {
    home: [0, 8],
    chat: [9, 10],
    library: [11, 14],
    nutrition: [15, 18],
  };

  const getPageName = () => {
    let cleanedLocation = location.replace(/\/+$/, "");
    switch (cleanedLocation) {
      case "/home":
        return "home";
      case "/chat":
        return "chat";
      case "/workouts":
      case "/programs":
      case "/AllExercises":
        return "library";
      case "/nutrition/1":
      case "/nutrition/2":
      case "/nutrition/3":
      case "/nutrition/4":
        return "nutrition";
      default:
        return "";
    }
  };

  const startWalkthroughForCurrentPage = () => {
    let pageName = getPageName();
    let currentPageStatus = pageStatus?.[pageName];

    dispatch(setIsNextFinish(false));
    dispatch(setShowBackButton(true));

    let pageStart = RouteRange[pageName]?.[0];
    let pageEnd = RouteRange[pageName]?.[1];

    if (isNaN(pageStart)) return;

    dispatch(setTourIndex(pageStart));
    dispatch(setTourRun(true));
    return true;
  };

  const shouldStartWalkthrough = () => {
    let pageName = getPageName();

    // if tourRun is true and tourIndex lies in the range of current page, then return

    if (
      (pageName === "home" && tourIndex === 0) ||
      (tourRun &&
        tourIndex >= RouteRange[pageName]?.[0] &&
        tourIndex <= RouteRange[pageName]?.[1])
    ) {
      return;
    }

    let currentPageStatus = pageStatus?.[pageName];

    let pageStart = RouteRange[pageName]?.[0];
    let pageEnd = RouteRange[pageName]?.[1];

    if (isNaN(pageStart) || tourIndex === pageEnd) return;

    if (currentPageStatus?.skipped) {
      return false;
    } else if (currentPageStatus?.finished) {
      return false;
    } else {
      dispatch(setIsNextFinish(false));
      dispatch(setShowBackButton(true));
      dispatch(setTourIndex(pageStart));
      dispatch(setTourRun(true));
      return true;
    }
  };

  const getRouteName = () => {
    console.log("location", location);
    let cleanedLocation = location.replace(/\/+$/, ""); // Remove trailing slashes
    // Use a regular expression to match the "/client/id" pattern
    const clientIdRegex = /^\/client\/\d+$/;

    shouldStartWalkthrough();

    if (clientIdRegex.test(cleanedLocation)) {
      return "User Profile";
    }
    switch (cleanedLocation) {
      case "/home":
        return "Home";
      case "/chat":
        return "Chat";
      case "/workouts":
        return "Workouts";
      case "/programs":
        return "Programs";
      case "/AllExercises":
        return "Exercises";
      case "/stripeConnect":
        return "Payments";
      case "/profile":
        return "Profile";
      case "/admin":
        return "Admin Dashboard";
      case "/admin/userDetail":
        return "User Profile";
      case "/client":
        return "User Profile";
      case "/affiliate":
        return "Affiliate Dashboard";
      case "/formcontrol":
        return "Forms"
      default:
        return "";
    }
  };

  // this function will take you back to the previous route
  const goBack = () => {
    if (pathname.includes('form') && search) {
      dispatch(setIsAlertFormDialog(true))
    }
    else
      history.goBack();
  };

  const isSmallerThanMd = useMediaQuery((theme) =>
    theme.breakpoints.down("md")
  );
  const isSmallerThanEqualToSm = useMediaQuery((theme) =>
    theme.breakpoints.down("sm")
  );

  const subscriptionEnabledCoaches = [
    "PXqGTqMrfblJ",
    "ErzsyqqduVWs",
    "gXCxEGYQpJlI",
    "QEqdADkMAWcpXbVvjm",
    "VJnuCaQhjbvwJlJImG",
    "rdbZZzNAJSAT",
    "IafGHEpcmkQlSoyQpv",
    "dQiIaoPFeJzqTgTxUq",
    "rdbZZzNAJSAT",
    "IErvyWyUJjSUwKIFbF",
    "LcmTrYrnoxIF",
    "wvVOGLnGSUtDYQHDRw",
    "IyRNsvRlTjIcYSHacK",
    "jUUlxenyTTYeLfRijm",
    "KxwiDeScRcXl",
  ];

  const shouldShowBackIcon = () => {
    let cleanedLocation = location.replace(/\/+$/, ""); // Remove trailing slashes
    // Use a regular expression to match the "/client/id" pattern
    const clientIdRegex = /^\/client\/\d+$/;
    const formregex = /^\/form(?:\/|$)/;
    if (clientIdRegex.test(cleanedLocation)) {
      return true;
    }

    if (formregex.test(cleanedLocation)) {
      cleanedLocation = "/form";
    }
    console.warn(cleanedLocation, "cleanedLocation");
    switch (cleanedLocation) {
      case "/analyze":
        return true;
      case "/form":
        return true;
      default:
        return false;
    }
  };

  return (
    <div
      style={{
        height: "77px",
        width: "100%",
        paddingLeft: isSmallerThanMd ? "20px" : dynamicPadding,
        paddingRight: "20px",
        position: "fixed",
        top: 0,
        zIndex: 100
      }}
    >
      <div
        style={{
          height: "100%",
          width: "100%",
          backgroundColor: "#ffffff",
          borderRadius: "0px 0px 16px 16px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          boxShadow: "0px 2px 5px -1px rgba(0,0,0,0.13)",
        }}

      > <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          {shouldShowBackIcon() && (

            <Typography
              style={{
                marginLeft: "26px",
                gap: 10,
                cursor: "pointer",
              }}
              onClick={goBack}
            >
              <Button>
                <ArrowLeft />
              </Button>
            </Typography>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: 'flex-start',
              marginLeft: "33px",
            }}
          >
            <Typography
              sx={{
                fontSize: "22px",
                fontWeight: 500,
                color: "#231F20",
                fontFamily: "DM Sans",
                ml: "10px",
                display: "flex",
                alignItems: 'center'
              }}
            >
              {pathname.includes('form') && search ? decodeURIComponent(search?.split('=')[1]) : getRouteName()}
            </Typography>
            {pathname.includes('form') && search && <Typography
              variant="caption"
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              ml={1}
              onClick={() => dispatch(setUserEditFormFunc(true))}
            >
              <EditOutlinedIcon sx={{ color: 'gray', fontSize: '1rem  ' }} />
            </Typography>}
          </div>
        </div>
        {/* <div style={{ backgroundColor: "#fff", borderRadius: "100px" }}>
          <SearchBar
            request={() => {}}
            cancel={() => {}}
            height={"40px"}
            width={"381px"}
            bgcol={
              "linear-gradient(to right, rgba(70, 79, 235, 0.1), rgba(131, 48, 233, 0.1))"
            }
            fontSize={"16px"}
            icon={DarkSearchIcon}
          />
        </div> */}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginRight: "26px",
            gap: 10,
          }}
        >
          {!isSmallerThanEqualToSm && (
            <>
              {/* <div
                style={{
                  height: "24px",
                  width: "24px",
                  marginRight: 10,
                  cursor: "pointer",
                  position: "relative",
                }}
                onClick={handleNotificationClick}
              >
                <img
                  src={BellIcon}
                  alt="bell icon"
                  style={{
                    height: "24px",
                    width: "24px",
                  }}
                  aria-describedby={notificationId}
                />
                {unreadCount != 0 && (
                  <p
                    style={{
                      backgroundColor: "red",
                      borderRadius: "10px",
                      position: "absolute",
                      top: "-2px",
                      right: "-2px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "10px",
                      height: "14px",
                      width: "14px",
                      color: "#fff",
                    }}
                  >
                    {unreadCount}
                  </p>
                )}
              </div> */}

              <HelpIcon
                style={{
                  cursor: "pointer",
                  marginTop: "2px",
                  height: "24px",
                  width: "24px",
                }}
                onClick={() => {
                  // start walkthrough for this page
                  startWalkthroughForCurrentPage();
                }}
              />
              {/* <Feed /> */}
            </>
          )}
          {(location.startsWith("/home") ||
            location.startsWith("/workouts") ||
            location.includes("nutrition/2") ||
            location.includes("nutrition/3")) && (
              <button
                style={{
                  background: "linear-gradient(to right, #464FEB, #8330E9)",
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "100px",
                  marginRight: "18px",
                  marginLeft: "18px",
                  height: "44px",
                  width: "148px",
                  color: "#fff",
                  justifyContent: "center",
                  fontFamily: "DM Sans",
                  fontWeight: 500,
                  fontSize: "16px",
                }}
                onClick={() => {
                  console.log("settingOpenCopilot");
                  mixpanel.track("Trainer_AskCopilot");
                  dispatch(toggleLayoutWidth(false));
                  setOpenCopilot(true);
                }}
                id="topbar-copilot-button"
              >
                <img
                  style={{ height: "24px", width: "24px", marginRight: "8px" }}
                  src={SparkleWhiteLogo}
                  alt=""
                />
                Ask Copilot
              </button>
            )}

          <Avatar
            onClick={handleClick}
            alt="Profile Pic"
            src={
              profileImage ||
              "https://t4.ftcdn.net/jpg/00/84/67/19/360_F_84671939_jxymoYZO8Oeacc3JRBDE8bSXBWj0ZfA9.jpg"
            }
            sx={{ width: "44px", height: "44px", cursor: "pointer" }}
          />
        </div>
      </div>
      <ChangePasswordModal
        open={changePasswordModalOpen}
        setOpen={setChangePasswordModalOpen}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{}}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          style: {
            margin: 0,
            maxWidth: "258px",
            minWidth: "258px",
            borderRadius: 10,
            padding: 1,
            border: "1px solid #ECEEF1",
          },
        }}
      >
        <MenuItem
          style={{
            padding: "6px 16px",
            display: "flex",
            justifyContent: "flex-start",
            borderBottom: "1px solid #ECEEF1",
            backgroundColor: "#fff",
          }}
        >
          <Stack direction={"row"} spacing={2}>
            <Avatar
              alt="Profile Pic"
              src={
                profileImage ||
                "https://t4.ftcdn.net/jpg/00/84/67/19/360_F_84671939_jxymoYZO8Oeacc3JRBDE8bSXBWj0ZfA9.jpg"
              }
              sx={{ width: "44px", height: "44px" }}
            />
            <Stack>
              <h3
                style={{
                  color: "#1A1B24",
                  fontSize: 16,
                  fontFamily: "DM Sans",
                  fontWeight: 700,
                }}
              >
                {trainerName ||
                  JSON.parse(localStorage.getItem("trainerCredentials"))?.name}
              </h3>
              <p
                style={{
                  color: "#424551",
                  fontSize: 12,
                  fontStyle: "normal",
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                }}
              >
                Fitness Trainer
              </p>
            </Stack>
          </Stack>
        </MenuItem>

        {subscriptionEnabledCoaches.includes(trainerUUID) && (
          <MenuItem
            style={{
              padding: "4px 16px",
              display: "flex",
              justifyContent: "flex-start",
            }}
            onClick={() => history.push("/coachSubscription")}
          >
            <Stack
              spacing={2}
              paddingTop={"4px"}
              paddingBottom={"4px"}
              direction={"row"}
            >
              <img
                src={"https://img.icons8.com/ios/50/7950F2/guarantee--v1.png"}
                alt="dashboard icon"
                style={{ height: "20px", width: "20px" }}
              />
              <p
                style={{
                  color: "#5E43EA",
                  fontSize: "13px",
                  fontFamily: "DM Sans",
                  fontStyle: "normal",
                  fontWeight: 500,
                }}
              >
                Subscription
              </p>
            </Stack>
          </MenuItem>
        )}

        {isAdmin && (
          <>
            <MenuItem
              style={{
                padding: "4px 16px",
                display: "flex",
                justifyContent: "flex-start",
              }}
              onClick={() => handleClose("/admin")}
            >
              <Stack
                spacing={2}
                paddingTop={"4px"}
                paddingBottom={"4px"}
                direction={"row"}
              >
                <img
                  src={DashboardIcon}
                  alt="dashboard icon"
                  style={{ height: "20px", width: "20px" }}
                />
                <p
                  style={{
                    color: "#5E43EA",
                    fontSize: "13px",
                    fontFamily: "DM Sans",
                    fontStyle: "normal",
                    fontWeight: 500,
                  }}
                >
                  Admin Dashboard
                </p>
              </Stack>
            </MenuItem>
          </>
        )}
        {!isAdmin && !adminID && (
          <>
            <MenuItem
              style={{
                padding: "4px 16px",
                display: "flex",
                justifyContent: "flex-start",
              }}
              onClick={() => handleClose("/affiliate")}
            >
              <Stack
                spacing={1}
                paddingTop={"4px"}
                paddingBottom={"4px"}
                direction={"row"}
              >
                <img
                  src={DollarSign}
                  alt="dashboard icon"
                  style={{ height: "25px", width: "25px" }}
                />
                <p
                  style={{
                    color: "#5E43EA",
                    fontSize: "13px",
                    marginTop: "4px",

                    fontFamily: "DM Sans",
                    fontStyle: "normal",
                    fontWeight: 500,
                  }}
                >
                  Refer & Earn
                </p>
              </Stack>
            </MenuItem>
          </>
        )}
        {data.map((data) => (
          <MenuItem
            style={{
              padding: "4px 16px",
              display: "flex",
              justifyContent: "flex-start",
            }}
            onClick={() => handleClose(data.link)}
          >
            <Stack
              spacing={2}
              paddingTop={"4px"}
              paddingBottom={"4px"}
              direction={"row"}
            >
              {data.icon}
              <p
                style={{
                  ...(data.name == "Logout"
                    ? { color: "red" }
                    : { color: "#5E43EA" }),
                  fontSize: "13px",
                  fontFamily: "DM Sans",
                  fontStyle: "normal",
                  fontWeight: 500,
                }}
              >
                {data.name}
              </p>
            </Stack>
          </MenuItem>
        ))}
      </Menu>
      {copyLinkAlert && (
        <Alert
          severity="success"
          sx={{
            position: "absolute",
            zIndex: "10",
            top: "900px",
            left: "49%",
          }}
        >
          Link copied!
        </Alert>
      )}
      {/* <Popover
        id={notificationId}
        open={notificationOpen}
        anchorEl={notificationAnchorEl}
        onClose={handleNotificationClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{
          borderRadius: "16px",
        }}
        slotProps={{
          paper: {
            sx: {
              height: "500px",
              minWidth: "380px",
              borderRadius: "16px",
              mt: "32px",
            },
          },
        }}
      >
        <Notification />
      </Popover> */}
    </div>
  );
}
