import React, { useContext, useRef, useEffect, useState } from "react";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { getPropertyToShow, getTimePropertyToShow } from "./helpers";
import { WorkoutContext } from "./WorkoutBuilder";
import { WorkoutModalContext } from "./WorkoutBuilderModal";
import CustomMenu from "./CustomMenu";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import { Avatar } from "@mui/material";
import { getIsAnyPropertySelected } from "./TopBar";
import errorWarning from "../../assets/errorWarning.svg";

const Exercise = ({
  selected,
  measurement,
  onSelect,
  selectedExerciseInfo,
  isDragEnabled,
  roundIndex,
  exerciseIndex,
  exerciseId,
  comingFromModal,
  updateFirstErrorRef,
  triggerRecheck,
  showErrorWarning,
}) => {
  const exerciseRef = useRef(null);
  const [isPropertiesToBeAdded, setIsPropertiesToBeAdded] = useState(
    !getIsAnyPropertySelected(measurement)
  );

  useEffect(() => {
    setIsPropertiesToBeAdded(!getIsAnyPropertySelected(measurement));
  }, [measurement, triggerRecheck]);

  useEffect(() => {
    if (isPropertiesToBeAdded) {
      updateFirstErrorRef(exerciseRef.current, roundIndex, exerciseIndex);
    } else {
      updateFirstErrorRef(null, roundIndex, exerciseIndex);
    }
  }, [
    isPropertiesToBeAdded,
    updateFirstErrorRef,
    roundIndex,
    exerciseIndex,
    triggerRecheck,
  ]);

  const getRecordFromUserProperties = () => {
    if (
      measurement?.recordFromUser &&
      typeof measurement?.recordFromUser === "object"
    ) {
      let res = Object.entries(measurement?.recordFromUser).map(
        ([key, value]) => {
          if (value) {
            return `${key}`;
          }
        }
      );
      return res.filter((item) => item).join(" | ");
    }
  };

  const workoutContent = useContext(
    comingFromModal ? WorkoutModalContext : WorkoutContext
  );
  return (
    <div className="flex flex-col">
      {isPropertiesToBeAdded && (
        <div className="flex gap-2 items-center">
          <img src={errorWarning} style={{ width: "14px" }} />
          <p
            style={{
              fontSize: "10px",
              color: "#C70039",
            }}
          >
            Select at least one property
          </p>
        </div>
      )}

      <div
        ref={exerciseRef}
        style={{
          cursour: "pointer",
        }}
        className={
          isPropertiesToBeAdded && showErrorWarning
            ? "exercise-items-error"
            : selected
            ? "exercise-items-selected"
            : "exercise-items"
        }
        onClick={() => onSelect()}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            alignSelf: "stretch",
            gap: "8px",
            position: "relative",
            padding: "8px",
          }}
        >
          {isDragEnabled && (
            <DragIndicatorIcon
              sx={{
                fontSize: "24px",
                color: "##828282",
              }}
            />
          )}
          {measurement?.imageLink && measurement?.imageLink !== "" ? (
            <img
              src={measurement?.imageLink}
              style={{ width: 48, height: 48, borderRadius: "8px" }}
            />
          ) : (
            <Avatar
              alt={measurement?.movementName}
              sx={{ width: 48, height: 48, borderRadius: "8px" }}
            >
              {measurement?.movementName?.[0] || ""}
            </Avatar>
          )}
          <div className="info7">
            {/* dropdown */}
            <div className="testchannel">{measurement.movementName}</div>
            <div className="days">{getRecordFromUserProperties()}</div>
          </div>
          <div className="allProperties">
            <RenderOneProperty
              value={measurement?.reps}
              maxValue={measurement?.repsMax}
              property="reps"
            />

            <RenderOneProperty
              unit={measurement?.weightUnits}
              displayUnits={weightDisplayUnits}
              value={measurement?.weight}
              maxValue={measurement?.weightMax}
              property="weight"
            />

            <RenderOneProperty
              unit={measurement?.heightUnits}
              displayUnits={heightDisplayUnits}
              value={measurement?.height}
              maxValue={measurement?.heightMax}
              property="height"
            />

            <RenderOneProperty
              unit={measurement?.distanceUnits}
              displayUnits={distanceDisplayUnits}
              value={measurement?.distance}
              maxValue={measurement?.distanceMax}
              property="distance"
            />

            <RenderOneProperty
              unit={"cal"}
              value={measurement?.calorie}
              maxValue={measurement?.calorieMax}
              property="calorie"
            />

            <RenderOneProperty
              unit=" "
              value={measurement?.duration}
              property="duration"
            />
          </div>
          {selected && (
            <div
              className={`active-bar ${
                isPropertiesToBeAdded && showErrorWarning
                  ? "active-bar-red"
                  : "active-bar-default"
              }`}
            />
          )}
          {!isDragEnabled && (
            <RemoveCircleOutlineRoundedIcon
              style={{
                color: "#C70039",
                cursor: "pointer",
                fontSize: "20px",
              }}
              onClick={(e) => {
                e.stopPropagation();
                workoutContent?.removeParticularExercise(
                  roundIndex,
                  exerciseIndex,
                  exerciseId
                );
              }}
            />

            // <CustomMenu
            //   className={"moreIcon"}
            //   iconBtnStyles={{
            //     borderRadius: "10px",
            //     backgroundColor: "#fff",
            //   }}
            //   options={["Delete"]}
            //   onOptionClick={(option) => {
            //     if (option === "Delete") {
            //       removeParticularExercise(
            //         selectedExerciseInfo?.roundIndex,
            //         selectedExerciseInfo?.exerciseIndex,
            //         selectedExerciseInfo?.exerciseId
            //       );
            //     }
            //   }}
            // />
          )}
        </div>
        {measurement?.movementInstr && (
          <div
            style={{
              padding: "8px",
              color: "#616161",
              fontFeatureSettings: "'clig' off, 'liga' off",
              fontFamily: "DM Sans",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
            }}
          >
            Instructions:{" "}
            <span
              style={{
                fontWeight: 400,
                fontFamily: "DM Sans",
                color: "#242424",
              }}
            >
              {measurement?.movementInstr}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Exercise;

const RenderOneProperty = ({
  unit,
  value,
  property,
  displayUnits,
  maxValue,
}) => {
  if (!value) return <></>;

  return (
    <div className="property">
      <div className="reps2">
        <span>
          {property == "duration"
            ? getTimePropertyToShow(value)
            : getPropertyToShow(value, maxValue)}
        </span>
        <span className="span">{` `}</span>
        <span className="reps3">
          {unit ? (displayUnits ? displayUnits[unit] : unit) : property}
        </span>
      </div>
    </div>
  );
};

let weightDisplayUnits = {
  Pounds: "LBS",
  Kilograms: "KGS",
  Poods: "PODS",
  RepMaxFraction: "%1RM",
  BodyWeight: "BW",
  RPE: "RPE",
};
let heightDisplayUnits = {
  Inches: "Inch",
  Feet: "Ft",
  Centimeters: "cm",
  Meters: "m",
};
let distanceDisplayUnits = {
  Meters: "m",
  Kilometers: "KM",
  Feet: "Ft",
  Yards: "Yds",
  Miles: "mi",
  Inches: "Inch",
};
