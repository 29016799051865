import { createTheme } from "@mui/material";

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1440, // customize the XL breakpoint to 1400px
    },
  },
  typography: {
    fontFamily: ["DM Sans", "Poppins", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: "#071148",
    },
    secondary: {
      main: "#ff9800",
    },
    royalBlue: {
      main: "#464FEB",
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: 0, // Set the padding to 0
        },
      },
    },
  },
});
