import React, { useState, useEffect } from "react";
import { LoadScript, GoogleMap, Polyline } from "@react-google-maps/api";

const MapPlot = ({ parsedData, lapData }) => {
  const [pathCoordinates, setPathCoordinates] = useState([]);
  const [mapCenter, setMapCenter] = useState({ lat: 37.7749, lng: -122.4194 }); // Example center
  const [lapColors, setLapColors] = useState([]);

  useEffect(() => {
    // Parse your .fit file here (using fitdecode.js or react-fit-parser)
    // and set the pathCoordinates state with the extracted coordinates
    let data = [];

    parsedData?.forEach((point) => {
      if (point.position_lat && point.position_long)
        data.push({ lat: point.position_lat, lng: point.position_long });
    });

    console.log("Lap Data: ", lapData);
    // let lapColors = [];
    // lapData?.forEach((lap, index) => {
    //   let records = lap.records;
    //   console.log("Lap Data: records", records);
    //   let lapColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;

    //   records.forEach((point) => {
    //     if (point.position_lat && point.position_long) {
    //       lapColors.push(lapColor);
    //       data.push({
    //         lat: point.position_lat,
    //         lng: point.position_long,
    //       });
    //     }
    //   });
    // });

    // setLapColors(lapColors);
    // find map center by calculating the average of all latitudes and longitudes
    let latSum = 0;
    let lngSum = 0;
    data.forEach((point) => {
      latSum += point.lat;
      lngSum += point.lng;
    });
    // setMapCenter({ lat: latSum / data.length, lng: lngSum / data.length });

    console.log("Data: ", data);
    setPathCoordinates(data);
  }, [parsedData]);

  const libraries = ["places"];

  return (
    <LoadScript
      googleMapsApiKey="AIzaSyCwvXMxzPGmxCS1TqG8eBQKbeYaZAKlVew"
      libraries={libraries}
    >
      <GoogleMap
        mapContainerStyle={{ width: "100%", height: "400px" }} // Adjust height as needed
        zoom={17} // Adjust zoom level as needed
        center={mapCenter} // Set center based on your data or user preference
        options={function (maps) {
          return { mapTypeId: "satellite" };
        }}
      >
        <Polyline path={pathCoordinates} options={{ strokeColor: "#FF0000" }} />
        {/* {pathCoordinates.map((path, index) => {
          console.log("Lap Colors: ", lapColors[index + 1]);
          return (
            <Polyline
              key={index} // Add unique key for each Polyline
              path={[path, {}]} // Connect two points
              options={{
                strokeColor: lapColors[index + 1] || "#FF0000", // Access color based on index (adjust if needed)
              }}
            />
          );
        })} */}
      </GoogleMap>
    </LoadScript>
  );
};

export default MapPlot;
