import React from "react";

const CalendarIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Property 1=calendar">
        <path
          id="Vector"
          d="M26.6665 3.33594V10.0026M13.3335 3.33594V10.0026M5 16.6644H35M8.33333 6.66436H31.6667C33.5076 6.66436 35 8.15674 35 9.99769V33.331C35 35.172 33.5076 36.6644 31.6667 36.6644H8.33333C6.49238 36.6644 5 35.172 5 33.331V9.99769C5 8.15674 6.49238 6.66436 8.33333 6.66436Z"
          stroke="url(#paint0_linear_366_19276)"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_366_19276"
          x1="4.93824"
          y1="36.2967"
          x2="35.3086"
          y2="3.70414"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC299" />
          <stop offset="1" stopColor="#FFF0E6" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default CalendarIcon;
