import React, { useState } from "react";

import {
  defaultPinPermissions,
  MessageInput,
  MessageList,
  Thread,
  useChannelActionContext,
  useChannelStateContext,
  Window,
} from "stream-chat-react";

import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setTrainerData } from "../../../../redux/authSlice";
import { store } from "../../../../redux/store";
import { PinnedMessageList } from "../PinnedMessageList/PinnedMessageList";
import { TeamChannelHeader } from "../TeamChannelHeader/TeamChannelHeader";

export const GiphyContext = React.createContext({});

export const ChannelInner = (props) => {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const { pinsOpen, setIsEditing, setPinsOpen } = props;
  const trainerUUID = store.getState().auth.trainerUUID;
  const responseTimes = useSelector((state) => state.auth?.data?.responseTimes);
  const gymAffiliation = useSelector(
    (state) => state.auth?.data?.gymAffliation
  );
  const isLivezy = gymAffiliation === "Livezy";
  const dispatch = useDispatch();

  const [giphyState, setGiphyState] = useState(false);

  const giphyStateObj = {
    giphyState: giphyState,
    setGiphyState,
  };

  const { sendMessage } = useChannelActionContext();
  const { messages, members, channel } = useChannelStateContext();

  const teamPermissions = { ...defaultPinPermissions.team, user: true };
  const messagingPermissions = {
    ...defaultPinPermissions.messaging,
    user: true,
  };
  const pinnedPermissions = {
    ...defaultPinPermissions,
    team: teamPermissions,
    messaging: messagingPermissions,
  };

  const overrideSubmitHandler = (message) => {
    console.log("messages", messages, members);
    // search for the latest message not sent by current user
    const latestMessage = messages.reverse()?.[0];

    let timeDiffFromLastMessage = 0;
    if (latestMessage && latestMessage?.user?.id !== trainerUUID) {
      const todaysDate = new Date();
      const lastMessageDate = new Date(latestMessage?.created_at);
      const timeDiffInMs = todaysDate.getTime() - lastMessageDate.getTime();
      timeDiffFromLastMessage = (timeDiffInMs / (1000 * 60)).toFixed(2);

      let channelId = latestMessage?.cid;
      let newResponseTimes = { ...responseTimes } || {};

      if (!newResponseTimes[channelId]) {
        newResponseTimes[channelId] = {
          avgResponseTime: +timeDiffFromLastMessage,
          n: 1,
        };
      } else {
        let avgResponseTime = newResponseTimes[channelId]?.avgResponseTime || 0;
        let n = +newResponseTimes[channelId]?.n || 0;
        let newAvgResponseTime =
          (Number(avgResponseTime) * n + Number(timeDiffFromLastMessage)) /
          (n + 1);
        newResponseTimes[channelId] = {
          avgResponseTime: newAvgResponseTime,
          n: n + 1,
        };
        console.log("newResponseTimes", newResponseTimes);
      }

      console.log(
        "latestMessage",
        latestMessage,
        "timeDiffFromLastMessage",
        timeDiffFromLastMessage
      );
      updateAvgResponseTime(newResponseTimes);
    }
    if (sendMessage) {
      const sendMessagePromise = sendMessage(message);
    }
  };

  const updateAvgResponseTime = async (responseTimes) => {
    await axios({
      method: "post",
      url: `${APIURL}CF/api/CoachWeb/Update/UpdateResponseTimes`,
      data: {
        responseTimes: responseTimes,
        coachID: trainerUUID,
      },
    })
      .then((res) => {
        console.log("responseTimes updated successfully");
        dispatch(setTrainerData(res.data.result));
      })
      .catch((err) => {
        console.log("error while updating responseTimes", err);
      });
  };

  return (
    <GiphyContext.Provider value={giphyStateObj}>
      <div style={{ display: "flex", width: "100%" }}>
        <Window>
          <TeamChannelHeader {...{ setIsEditing, setPinsOpen }} />
          <MessageList
            disableQuotedMessages
            pinPermissions={pinnedPermissions}
          />
          <MessageInput overrideSubmitHandler={overrideSubmitHandler} grow />
        </Window>
        <Thread additionalMessageInputProps={{ grow: true }} />
        {pinsOpen && <PinnedMessageList setPinsOpen={setPinsOpen} />}
      </div>
    </GiphyContext.Provider>
  );
};
