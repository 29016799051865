import React, { useContext, useState } from 'react';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Pencil } from "lucide-react";
import Input from '@/components/InputComponents/Input';
import TextArea from '@/components/InputComponents/TextArea';
import { workoutContext } from './WorkoutContex';

function EditFolderInfo({ selectedProgramData }) {
  const [folderName, setFolderName] = useState(selectedProgramData?.name || "");
  const [folderDescription, setFolderDescription] = useState(selectedProgramData?.description||"");
  const { updateFolder, setFolderSelected, setFolders, setTempFolderData } =
    useContext(workoutContext);


  const handleNameUpdateOfFolder = async () => {
    let data = {
      folderID: selectedProgramData?.id,
      name: folderName,
      description: folderDescription,
    };

     
    let res = await updateFolder(data);
 
    if (res) {
      // this will update state when name update is from template
   

      setFolderSelected((prev) => {
        const data = { ...prev };
        if (data?.name) {
          data.name = folderName;
          data["description"] = folderDescription;
        }
        setFolders((folder) => {
          const oldFolders = [...folder];
          const newFolders = oldFolders.map((folder) => {
            if (folder?.id === data?.id) {
              return data;
            } else {
              return folder;
            }
          });
      setTempFolderData(newFolders);
          return newFolders;
        });
         
        
        return data;
      });
   setFolderName("")
   setFolderDescription("");
    }
  };

  return (
    <Dialog
  
    >
      <DialogTrigger asChild>
        <button>
          <Pencil size={18} color="#828282" />
        </button>
      </DialogTrigger>
      {/* overlay */}
      <DialogPortal>
        <DialogOverlay className="bg-black-pure bg-opacity-40 z-[9999]" />
      </DialogPortal>
      <DialogContent className="sm:max-w-[425px] z-[9999] font-DMSans">
        <DialogHeader>
          <DialogTitle className="text-font18">Edit Folder Info</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <Input
            label="Folder Name"
            value={folderName}
            onChange={(e) => setFolderName(e.target.value)}
          />

          <TextArea
            placeholder="Folder Description"
            value={folderDescription}
            onChange={(e) => setFolderDescription(e.target.value)}
            numRows={4}
          />
        </div>
        <DialogFooter
          className={
            "flex w-full items-center justify-center sm:justify-center"
          }
        >
          <DialogClose>
            <button
              className="button-new" 
                onClick={() => {
                  handleNameUpdateOfFolder();
                }}
            >
              Update
            </button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export default EditFolderInfo;