import { CloseRounded } from "@mui/icons-material";
import { Backdrop, Box, DialogActions, DialogContent } from "@mui/material";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import { SearchIcon, UserIcon } from "../../../pages/getStreamChat/assets";
import SmallCloseIcon from "../../../pages/library/assests/SmallCloseIcon";
import { SpurfitCircularProgress } from "../../Dashboard/dashboard";

const AssignMeals = ({ isOpen, setIsOpen, mealId, item }) => {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const gymAffiliation = useSelector(
    (state) => state.auth?.data?.gymAffliation
  );
  const isLivezy = gymAffiliation === "Livezy";
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [userData, setUserData] = useState([]);
  const [tempUserData, setTempUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searched, setSearched] = useState("");
  const [selectedList, setSelectedList] = useState([]);
  const [activeUser, setActiveUser] = useState([]);
  const [monthSelected, setMonthSelected] = useState(null);
  const [startDate, setStartDate] = useState(
    moment()
      .set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
      .toDate()
  );
  const [endDate, setEndDate] = useState(
    moment()
      .set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
      .toDate()
  );

  const onSearch = (e) => {
    setSearched(e.target.value);
  };

  const searchUsers = (searched) => {
    if (searched.length > 0) {
      const filteredData = userData.filter((item) => {
        return item.name.toLowerCase().includes(searched.toLowerCase());
      });
      setTempUserData(filteredData);
    } else {
      setTempUserData(userData);
    }
  };

  const coachID = useSelector((state) => state.auth.trainerUUID);

  const getusers = async () => {
    setLoading(true);
    await axios({
      method: "get",
      url: `${APIURL}CF/api/CoachWeb/Get/getAllAtheletesOneCoach`,
      params: {
        coachID: coachID,
      },
    }).then(async (res) => {
      console.log("Dashboard query on assign: ", res);
      const filteredData = res.data.result?.[0]?.atheletes?.filter(
        (item) => item.active == true
      );

      setUserData(filteredData);
      setTempUserData(filteredData);
      setLoading(false);
    });
  };

  const assignMeal = async () => {
    setLoading(true);
    let res = await axios({
      method: "post",
      url: `${APIURL}CF/api/meal/assignMealMultipleAthlete`,
      data: {
        athleteIDs: activeUser,
        mealId: item.id,
        dateAssigned: moment(selectedDate, "DD-MM-YYYY")
          ?.set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          })
          ?.format("YYYY-MM-DD"),
        startDate: moment(startDate)?.format("YYYY-MM-DD") + "T00:00:00.000Z",
        endDate: isLivezy
          ? moment(startDate).add(3, "months").toDate()
          : moment(endDate).format("YYYY-MM-DD") + "T00:00:00.000Z",
      },
    })
      .then((res) => {})
      .catch((err) => {});
    setLoading(false);
    setIsOpen(false);
    return res;
  };

  useEffect(() => {
    getusers();
  }, []);

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isOpen}
      onClick={() => {
        setIsOpen(false);
        setActiveUser([]);
      }}
    >
      <Box
        onClick={(e) => {
          e.stopPropagation();
        }}
        sx={{
          overflowY: "inherit",
          position: "absolute",
          background: "#fff",
          boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.12)",
          borderRadius: "12px",
        }}
      >
        {loading && (
          <div className="absolute w-full h-full bg-black-pure opacity-50 z-50">
            <SpurfitCircularProgress />
          </div>
        )}
        <div className="add-program-modal-head">
          <p className="add-program-modal-head-title">Assign to users</p>
          <div
            onClick={() => {
              setIsOpen(false);
              setActiveUser([]);
            }}
          >
            <CloseRounded
              sx={{
                fontSize: "24px",
                color: "#000",
                cursor: "pointer",
              }}
            />
          </div>
        </div>
        <DialogContent sx={{ p: 0 }}>
          <div className="add-program-modal-body">
            <div
              className="full-width-container"
              style={{
                paddingBottom: "20px",
              }}
            >
              <div>
                <div
                  className="channel-search__input__wrapper"
                  style={{
                    minWidth: "500px",
                    maxWidth: "500px",
                    width: "100%",
                    display: "flex",
                    flexWrap: "wrap",
                    height: "auto",
                    justifyContent: "flex-start",
                  }}
                >
                  {selectedList.length > 0 &&
                    selectedList.map((item, index) => {
                      return (
                        <div
                          style={{
                            color: "#333",
                          }}
                          className="searched-selected"
                        >
                          <div className="selected-user-icon-div">
                            <UserIcon />
                          </div>
                          <p
                            style={{
                              color: "#333",
                            }}
                          >
                            {item?.name}
                          </p>
                          <div
                            onClick={() => {
                              if (item?.team) {
                                item.user.map((userid) => {
                                  setActiveUser((prevState) => {
                                    return prevState.filter(
                                      (uuid) => uuid !== userid
                                    );
                                  });
                                });
                              } else {
                                setActiveUser((prevState) => {
                                  return prevState.filter(
                                    (uuid) => uuid !== item.id
                                  );
                                });
                              }
                              delete selectedList[index];
                            }}
                          >
                            <SmallCloseIcon />
                          </div>
                        </div>
                      );
                    })}
                  <div
                    style={{
                      width: "100%",
                    }}
                    className="channel-search__input__icon"
                  >
                    <SearchIcon />
                    <input
                      className="channel-search__input__text"
                      onChange={(e) => {
                        onSearch(e);
                        searchUsers(e.target.value);
                      }}
                      placeholder="Search users or groups"
                      type="text"
                      style={{
                        width: "100%",
                      }}
                      value={searched}
                    />
                  </div>
                </div>
                {searched.length > 0 && (
                  <div
                    className="search-result-view"
                    style={{
                      maxHeight: "200px",
                      //   width: 554,
                      // marginTop: 10 + searchContainerRef?.current?.clientHeight,
                    }}
                  >
                    <>
                      <div className="label-new">Users</div>
                      {tempUserData.length > 0 &&
                        tempUserData.map((item) => {
                          return (
                            <div
                              className="search-result-view-item-container w-full cursor-pointer"
                              onClick={() => {
                                setSelectedList((prev) => [
                                  ...prev,
                                  {
                                    id: item.id,
                                    name: item.name || item.userName,
                                    team: false,
                                  },
                                ]);
                                setActiveUser((prevState) => [
                                  ...prevState,
                                  item.id,
                                ]);
                                setSearched("");
                              }}
                            >
                              <div
                                className="channel-preview__item_avatar"
                                style={{
                                  backgroundColor: "rgb(255, 229, 211)",
                                }}
                              >
                                <UserIcon />
                              </div>
                              <p
                                style={{
                                  color: "#333",
                                }}
                              >
                                {item.name || item.userName}
                              </p>
                            </div>
                          );
                        })}
                    </>
                  </div>
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <label
                  htmlFor="inputField"
                  style={{
                    display: "block",
                    color: "#000",
                    fontSize: 10,
                    marginLeft: 12,
                  }}
                >
                  Start date
                </label>
                <DatePicker
                  dateFormat="dd MMM yyyy"
                  minDate={moment()
                    .set({
                      hour: 0,
                      minute: 0,
                      second: 0,
                      millisecond: 0,
                    })
                    .toDate()}
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                    // if end date is less than start date then set end date to start date
                    if (moment(date).isAfter(endDate)) {
                      setEndDate(date);
                    }
                  }}
                  customInput={
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        height: "40px",
                        padding: "10px",
                        outline: "none",
                        fontFamily: "DM Sans",
                        cursor: "pointer",
                        color: "#000",
                      }}
                    />
                  }
                />
              </div>
              {isLivezy ? (
                // <div
                //   style={{
                //     width: "50%",
                //   }}
                // >
                //   <label
                //     htmlFor="inputField"
                //     style={{
                //       display: "block",
                //       color: "#000",
                //       fontSize: 10,
                //       marginLeft: 12,
                //     }}
                //   >
                //     Duration
                //   </label>
                //   <div className="flex">
                //     <button
                //     className="button-new"
                //       style={{
                //         width: "100%",
                //         height: "37px",
                //         padding: "10px",
                //         outline: "none",
                //         fontFamily: "DM Sans",
                //         cursor: "pointer",
                //         color: monthSelected == "6" ? "white" : "black",
                //         background:
                //           monthSelected == "6" ? "rgb(70, 79, 235)" : "none",
                //       }}
                //       onClick={() => {
                //         setEndDate(moment(startDate).add(6, "months").toDate());
                //         setMonthSelected("6");
                //       }}
                //     >
                //       6 months
                //     </button>
                //     <button
                //     className="button-new"
                //       style={{
                //         width: "100%",
                //         height: "37px",
                //         padding: "10px",
                //         outline: "none",
                //         fontFamily: "DM Sans",
                //         cursor: "pointer",
                //         color: monthSelected == "3" ? "white" : "black",
                //         background:
                //           monthSelected == "3" ? "rgb(70, 79, 235)" : "none",
                //       }}
                //       onClick={() => {
                //         setEndDate(moment(startDate).add(3, "months").toDate());
                //         setMonthSelected("3");
                //       }}
                //     >
                //       3 months
                //     </button>
                //   </div>
                // </div>
                <></>
              ) : (
                <div>
                  <label
                    htmlFor="inputField"
                    style={{
                      display: "block",
                      color: "#000",
                      fontSize: 10,
                      marginLeft: 12,
                    }}
                  >
                    End date
                  </label>
                  <DatePicker
                    dateFormat="dd MMM yyyy"
                    minDate={moment()
                      .set({
                        hour: 0,
                        minute: 0,
                        second: 0,
                        millisecond: 0,
                      })
                      .toDate()}
                    selected={endDate}
                    onChange={(date) => {
                      console.log("date ha kya", date);
                      setEndDate(date);
                    }}
                    customInput={
                      <input
                        type="text"
                        style={{
                          width: "100%",
                          height: "40px",
                          padding: "10px",
                          outline: "none",
                          fontFamily: "DM Sans",
                          cursor: "pointer",
                          color: "#000",
                        }}
                      />
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
          }}
        >
          <button
            onClick={() => {
              // setIsOpen(false);
              // setActiveUser([]);
              assignMeal();
            }}
            disabled={activeUser.length == 0}
            className="button-new"
          >
            Assign
          </button>
        </DialogActions>
      </Box>
    </Backdrop>
  );
};

export default AssignMeals;
