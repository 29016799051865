import React from "react";

const RenderOneCard = ({ day, name, icon, num, onClick }) => {
  const colors = ["#FFF0E5", "#E8E6FE", "#EEFDE8"];
  return (
    <div
      className={`w-full h-[48px] rounded-md flex items-center cursor-pointer`}
      style={{
        background: colors[num % 3],
        padding: "6px 8px",
      }}
      onClick={onClick}
    >
      <div className={`flex items-center gap-2 `}>
        <img
          src={icon}
          style={{ width: "20px", height: "20px", marginLeft: "10px" }}
        />

        <div>
          <p className="week-card-conatiner-draggable-text">{name}</p>
        </div>
      </div>
    </div>
  );
};

export default RenderOneCard;
