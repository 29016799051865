import React, { createContext, useEffect, useState } from "react";
import Sidebar from "../SideBar";
import TopBar from "../TopBar";
import Copilot from "../copilot/copilot";
import { useLocation } from "react-router-dom";
import NotificationProvider from "../Context/NotificationContext/NotificationContext";
import { useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";

export const ShowCopilotContext = createContext(null);

export default function SpurfitLayout({
  children,
  setRun,
  copilotOpen,
  setCopilotOpen,
}) {
  const isSmallerThanMd = useMediaQuery((theme) =>
    theme.breakpoints.down("md")
  );
  const [route, setRoute] = useState("/home");
  const coachID = useSelector((state) => state.auth.trainerUUID);
  const logoutIfDataIsCleared = (pathname) => {
    // if not coachID and the page is not login page then logout
    if (!coachID && pathname !== "/") {
      localStorage.clear();
      // reload
      window.location.reload();
    }
  };

  useEffect(() => {
    setRun(true);
  }, []);
  const location = useLocation();
  useEffect(() => {
    setRoute(window.location.pathname);
    logoutIfDataIsCleared(location.pathname);
  }, [location.pathname]);

  if (
    location.pathname?.includes("workoutBuilder") ||
    location.pathname?.includes("EnduranceWorkoutBuilder")
  ) {
    return (
      <div
        style={{
          minHeight: "100vh",
          minWidth: "99vw",
          backgroundColor: "#F8F8F8",
          marginTop: -12,
        }}
      >
        {children}
      </div>
    );
  }

  return (
    <ShowCopilotContext.Provider value={{ copilotOpen, setCopilotOpen }}>
      <div
        style={{
          minHeight: "100vh",
          minWidth: "99vw",
          backgroundColor: "#F8F8F8",
          marginTop: -12,
        }}
        id="spurfit-layout-box"
      >
        {!isSmallerThanMd && <Sidebar />}
        <NotificationProvider>
          <TopBar setOpenCopilot={setCopilotOpen} />
        </NotificationProvider>
        {children}
      </div>
    </ShowCopilotContext.Provider>
  );
}
