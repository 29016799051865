export const CustomInputComponent = ({
  label,
  placeholder,
  style,
  state,
  type,
  onChange,
}) => {
  return (
    <div
      style={{
        width: "257px",
        background: "#FFFFFF",
        borderRadius: "8px",
        ...style,
      }}
      className="text-blue-main"
    >
      {label && (
        <label
          htmlFor="inputField"
          style={{ display: "block", fontSize: 10, marginLeft: 12 }}
        >
          {label}
        </label>
      )}
      <input
        type={type ?? "text"}
        id="inputField"
        placeholder={placeholder}
        className="placeholder-blue-main placeholder-opacity-50"
        autoComplete="off"
        style={{
          width: "100%",
          height: "40px",
          borderRadius: "5px",
          border: "1px solid #071148",
          padding: "10px",
          outline: "none",
        }}
        value={state}
        onChange={onChange}
      />
    </div>
  );
};
