import React, { useEffect, useState } from "react";
import { Dialog, DialogContent } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllMessageTemplate,
  deleteMessageTemplate,
} from "../../pages/messageTemplate/Api";
import TemplateBlock from "./TemplateBlock";
import { AddRounded } from "@mui/icons-material";
import CreateTemplateModal from "../../pages/messageTemplate/CreateTemplateModal";
import { SpurfitCircularProgress } from "../Dashboard/dashboard";
import { setTemplateData } from "../../redux/authSlice";

const TemplatesDialog = ({ isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const oneTrainerData = useSelector((state) => state.auth?.data);
  const coachID = useSelector((state) => state.auth?.trainerUUID);
  const [messageTemplates, setMessageTemplates] = useState([]);
  const [showCreateTemplate, setShowCreateTemplate] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [loading, setLoading] = useState(false);
  const [updateStateChange, setUpdateStateChange] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const getAllTemplates = async () => {
    let idToBeSended;
    if (oneTrainerData?.isAdmin) {
      console.log("is admin");
      if (coachID) idToBeSended = coachID;
    } else if (oneTrainerData?.adminID) {
      console.log("admin id", oneTrainerData.adminID);
      idToBeSended = oneTrainerData?.adminID;
    } else {
      return;
    }
    try {
      setLoading(true);
      let res = await getAllMessageTemplate(idToBeSended);
      dispatch(setTemplateData(res));
      setMessageTemplates(res);
    } catch (error) {
      console.error("Error fetching message templates:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleDeleteTemplate = async (templateId) => {
    try {
      await deleteMessageTemplate(templateId);
      setUpdateStateChange((prev) => !prev);
    } catch (error) {
      console.error("Error deleting message template:", error);
    }
  };

  useEffect(() => {
    if (oneTrainerData?.isAdmin) {
      setIsAdmin(true);
    }
    if (coachID) {
      getAllTemplates(coachID);
    }
  }, [coachID, updateStateChange]);

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      sx={{
        backdropFilter: "blur(3px)",
      }}
      PaperProps={{
        sx: {
          minWidth: "320px",
          borderRadius: "20px",
        },
      }}
    >
      <div className="add-program-modal-head">
        <p className="add-program-modal-head-title">Message Templates</p>
        <div className="flex items-center gap-3">
          {isAdmin && (
            <div
              className="cursor-pointer border border-black-pure rounded-full flex items-center justify-center"
              onClick={() => setShowCreateTemplate(true)}
            >
              <AddRounded />
            </div>
          )}
          <div
            style={{
              cursor: "pointer",
            }}
            onClick={() => setIsOpen(false)}
          >
            <CloseRoundedIcon />
          </div>
        </div>
      </div>
      {!loading && (
        <DialogContent>
          {messageTemplates?.map((template) => (
            <TemplateBlock
              template={template}
              onEdit={() => {
                setSelectedItem(template);
                setShowCreateTemplate(true);
              }}
              onDelete={() => {
                handleDeleteTemplate(template.id);
              }}
              isAdmin={isAdmin}
            />
          ))}
        </DialogContent>
      )}
      {loading && <SpurfitCircularProgress />}

      {showCreateTemplate && (
        <CreateTemplateModal
          isOpen={showCreateTemplate}
          setIsOpen={setShowCreateTemplate}
          selectedItem={selectedItem}
          onSuccess={() => {
            getAllTemplates(coachID);
            setSelectedItem({});
          }}
        />
      )}
    </Dialog>
  );
};

export default TemplatesDialog;
