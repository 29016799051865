import { Dialog } from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

const FilterWorkoutDialog = ({
  isOpen,
  setIsOpen,
  filterOptions,
  filterWorkoutsByTag,
  selectedFilterOptions,
}) => {
  const [selectedTags, setSelectedTags] = useState(selectedFilterOptions || []);
  const [allFilterOptions, setAllFilterOptions] = useState([]);

  useEffect(() => {
    // Sort the allFilterOptions array every time selectedTags changes
    const sortedOptions = filterOptions?.sort((a, b) => {
      if (selectedTags.includes(a) && !selectedTags.includes(b)) {
        return -1;
      } else if (!selectedTags.includes(a) && selectedTags.includes(b)) {
        return 1;
      } else {
        return 0;
      }
    });

    // Update the allFilterOptions state with the sorted array
    setAllFilterOptions(sortedOptions);
  }, []);

  const sortOptions = (selectedTags) => {
    const sortedOptions = filterOptions.sort((a, b) => {
      if (selectedTags.includes(a) && !selectedTags.includes(b)) {
        return -1;
      } else if (!selectedTags.includes(a) && selectedTags.includes(b)) {
        return 1;
      } else {
        return 0;
      }
    });

    setAllFilterOptions(sortedOptions);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      aria-labelledby="assign workouts modal"
      maxWidth="md"
      PaperProps={{
        sx: {
          borderRadius: "12px",
          minHeight: "500px",
        },
      }}
    >
      <div className="add-program-modal-head">
        <p className="add-program-modal-head-title">Filter</p>

        <div
          onClick={() => {
            setIsOpen(false);
          }}
          style={{
            cursor: "pointer",
          }}
        >
          <CloseRoundedIcon />
        </div>
      </div>
      <div className="add-program-modal-body">
        <div
          style={{
            display: "flex",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "232px",
              height: "420px",
              flexDirection: "column",
              alignItems: "flex-start",
              borderRight: "1px solid #EBEBEB",
              paddingRight: "16px",
            }}
          >
            <div
              style={{
                display: "flex",
                height: "48px",
                padding: "11px 16px",
                alignItems: "center",
                gap: "4px",
                flexShrink: 0,
                alignSelf: "stretch",

                borderRadius: "8px",
                background:
                  "linear-gradient(90deg, rgba(70, 79, 235, 0.10) 10.42%, rgba(131, 48, 233, 0.10) 100%)",
              }}
            >
              <p
                style={{
                  color: "#000",
                  fontFamily: "DM Sans",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "20px",
                  letterSpacing: "-0.16px",
                }}
              >
                Tags
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              alignSelf: "stretch",
              minWidth: "480px",
              paddingLeft: "16px",
              gap: "12px",
              width: "100%",
              height: "100%",
              flexWrap: "wrap",
            }}
          >
            {allFilterOptions.map((option) => (
              <div
                className="chip"
                style={{
                  cursor: "pointer",
                  background: selectedTags.includes(option)
                    ? "linear-gradient(90deg, #464feb 10.42%, #8330e9 100%)"
                    : "#F7F7F7",
                  color: selectedTags.includes(option) ? "#fff" : "#000",
                  minWidth: "76px",
                }}
                onClick={() => {
                  if (selectedTags.includes(option)) {
                    let currentSelectedTags = selectedTags.filter(
                      (tag) => tag !== option
                    );
                    sortOptions(currentSelectedTags);
                    setSelectedTags(currentSelectedTags);
                  } else {
                    let currentSelectedTags = [...selectedTags, option];
                    sortOptions(currentSelectedTags);
                    setSelectedTags(currentSelectedTags);
                  }
                }}
              >
                {option}{" "}
                {selectedTags.includes(option) ? (
                  <RemoveRoundedIcon fontSize="16" />
                ) : (
                  <AddRoundedIcon fontSize="16" />
                )}
              </div>
            ))}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            width: "100%",
          }}
        >
          <button
            style={{
              color: "#FFF",
              textAlign: "center",
              fontFamily: "DM Sans",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "22px",
              letterSpacing: "-0.43px",
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              color: "transparent",
              backgroundImage: "linear-gradient(90deg, #464FEB, #8330E9)",
              marginRight: "16px",
            }}
            onClick={() => {
              setSelectedTags([]);
            }}
          >
            Clear
          </button>
          <button
            // disabled={selectedTags.length === 0}
            className="button-new"
            onClick={() => {
              filterWorkoutsByTag(selectedTags);
              setIsOpen(false);
            }}
          >
            Apply
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default FilterWorkoutDialog;
