import { DeleteRounded } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import axios from "axios";
import { debounce } from "lodash";
import { ArrowLeft } from "lucide-react";
import moment from "moment";
import queryString from "query-string";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { CustomAlertContext } from "../../../App";
import Bro from "../../../assets/bro.svg";
import Layout from "../../../components/layout/Layout";
import SpinnerComponent from "../../../components/layout/spinner";
import toast from "../../../components/Toast/toast";
import FollowThroughView from "./FollowThroughView";
import RenderMeal from "./RenderMeal";
import ShowEnduranceWorkouts from "./ShowEnduranceWorkouts";
import WorkoutContentPage from "./WorkoutContentPage";

export default function ShowWorkouts(props) {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const history = useHistory();
  const [workoutList, setworkoutList] = useState([]);
  const [data] = useState(props?.location?.state?.data);
  const [anyCompletedWorkout, setAnyCompletedWorkout] = useState(false);
  const [reload, setReload] = useState(false);
  const [goingBack, setGoingBack] = useState(false);
  const [Meals, setMeals] = useState(props?.location?.state?.meals ?? null);
  const [habits, setHabits] = useState(
    props?.location?.state?.habits
      ? props?.location?.state?.habits?.[0]?.activitiesAssigned
      : null
  );
  const idOfHabits = props?.location?.state?.habits?.[0]?.id;
  console.log("activitiesAssigned", props?.location?.state?.habits);
  const [enduranceData, setEnduranceData] = useState(
    props?.location?.state?.enduranceData
      ? props?.location?.state?.enduranceData
      : null
  );
  const [activityArray, setActivityArray] = useState(
    props?.location?.state?.habits || []
  );
  const [dailyTargets, setDailyTargets] = useState(null);
  // mF = mind full ness
  const [mF, setMF] = useState(null);
  const [sleep, setSleep] = useState(null);
  const [customActivities, setCustomActivites] = useState({});
  const [enduranceWorkouts, setEnduranceWorkouts] = useState([]);

  const {
    isImport = false,
    date,
    userUUID,
    ita,
    isCalendar,
  } = queryString.parse(props?.location?.search);
  const { displayAlert } = useContext(CustomAlertContext);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const PrimaryKey = props?.location?.state?.state?.data?.id || id;

  console.log("meals: ", Meals);

  const deleteMealPlan = async (mealID) => {
    setLoading(true);
    await axios({
      method: "post",
      url: `${APIURL}CF/api/deleteMealAssigned`,
      data: {
        id: mealID,
      },
    })
      .then((res) => {
        console.log("deleteMealPlan: ", res);
        let remainingMealPlans = res.data?.result?.remainingMealPlans ?? [];
        setMeals(remainingMealPlans);

        if (!remainingMealPlans || remainingMealPlans.length <= 0) {
          if (workoutList?.length <= 0) {
            debounceGoBack();
          }
        }

        toast("Meal Plan Deleted!", "success");
      })
      .catch((err) => {
        displayAlert("Network error, please try again");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const deleteIndividualWorkout = async (workoutID, isEndurance) => {
    let primaryKeyArray = JSON.parse(id).filter((pk) => pk !== workoutID);
    console.log("PK arr", primaryKeyArray);
    setLoading(true);
    await axios({
      method: "post",
      url: `${APIURL}CF/api/deleteAssignedWorkoutAthelete`,
      data: {
        assignedWorkoutID: workoutID,
        newOrderWorkoutIDs: primaryKeyArray,
        isEnduranceWorkout: isEndurance,
      },
    })
      .then((res) => {
        console.log("deleteAssignedWorkoutAthelete: ", res);
        toast("Workout Deleted!", "success");
        history.replace(
          `/library/showWorkoutsCF/${JSON.stringify(
            primaryKeyArray
          )}?isCalendar=${true}`,
          {
            state: {
              data: data,
            },
            meals: Meals,
          }
        );

        // setReload(!reload);
      })
      .catch((err) => {
        console.error("Error deleting workout:", err);
        displayAlert("Network error, please try again");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  async function handleDeleteAssignedActivity() {
    setLoading(true);
    try {
      const res = await axios({
        method: "post",
        url: `${APIURL}cf/api/activit/DeleteActivitiesbyIDs`,
        data: {
          ids: [idOfHabits],
        },
      });
      if (res) {
        toast("Activities Deleted!", "success");
        setHabits(null);
      }
    } catch (error) {
      displayAlert("Network error, please try again");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function handleDeleteAssignedActivity() {
    setLoading(true);
    try {
      const res = await axios({
        method: "post",
        url: `${APIURL}cf/api/activit/DeleteActivitiesbyIDs`,
        data: {
          ids: [idOfHabits],
        },
      });
      if (res) {
        toast("Activities Deleted!", "success");
        setHabits(null);
      }
    } catch (error) {
      displayAlert("Network error, please try again");
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const debounceGoBack = debounce(() => {
    if (!goingBack) {
      setGoingBack(true);
      history.goBack();
    }
  }, 1000);

  async function setApiData(primaryKeys, source) {
    if (
      primaryKeys.length <= 0 &&
      Meals?.length <= 0 &&
      enduranceData?.length <= 0
    ) {
      debounceGoBack();
    } else if (!primaryKeys || primaryKeys.length <= 0) {
      setworkoutList([]);
      setLoading(false);
    } else {
      setLoading(true);
      try {
        let res = await axios({
          method: "get",
          url: `${APIURL}CF/api/getMultiModuleDetails`,
          params: {
            primaryKeys: primaryKeys,
          },
          cancelToken: source.token,
        });
        console.log("CF modules:  : ", res.data.result);
        // separate workouts with workout kind as run, swim and bike
        let curEnduranceWorkouts = [];
        let otherWorkouts = [];

        res.data.result.forEach((workout) => {
          let workoutKind = workout?.workoutAssigned?.workoutKind;
          if (
            workoutKind == "run" ||
            workoutKind == "swim" ||
            workoutKind == "bike"
          ) {
            curEnduranceWorkouts.push(workout);
          } else {
            otherWorkouts.push(workout);
          }
        });
        console.log("CF endurance workouts: ", curEnduranceWorkouts);
        console.log("CF other workouts: ", otherWorkouts);
        setEnduranceWorkouts(curEnduranceWorkouts);
        setworkoutList(otherWorkouts);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
  }

  const getIds = () => {
    try {
      return JSON.parse(id);
    } catch (err) {
      return false;
    }
  };

  const init = async () => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    setLoading(true);
    let ids = getIds();
    isCalendar === "true"
      ? setApiData(ids, source)
      : await axios({
          method: "get",
          url: `${APIURL}CF/api/CoachWeb/Get/getWorkoutWithAllDetailsJSON`,
          params: {
            workoutID: PrimaryKey,
          },
          cancelToken: source.token,
        })
          .then((res) => {
            console.log("CF workouts: ", res.data);
            setworkoutList(res.data.result);
          })
          .catch((err) => {
            displayAlert(err);
          })
          .finally(() => {
            setLoading(false);
          });
    return () => {
      setworkoutList([]);
      source.cancel();
    };
  };
  const getLatestActivites = (sortedArray) => {
    let tempDT = {};
    let tempSleep = [];
    let tempMF = [];
    let tempCustomActivity = {};
    let keysInHabits = [];

    let assigned = sortedArray?.[0]?.activitiesAssigned;
    if (Object.keys(assigned?.dailyTargets || {}).length > 0) {
      tempDT = { ...assigned?.dailyTargets };
      console.log("sorted ha daily target", tempDT);
    }
    if (
      assigned?.habbits?.mindfulness &&
      assigned?.habbits?.mindfulness?.length > 0
    ) {
      tempMF = [...assigned?.habbits?.mindfulness];
    }
    if (assigned?.habbits?.sleep && assigned?.habbits?.sleep?.length > 0) {
      tempSleep = [...assigned?.habbits?.sleep];
    }
    keysInHabits = [...Object.keys(assigned?.habbits || {})];
    // console.log("sorted keys in habits",keysInHabits);
    keysInHabits.map((key) => {
      if (key != "sleep" && key != "mindfulness") {
        tempCustomActivity = {
          ...tempCustomActivity,
          [key]: assigned?.habbits?.[key],
        };
      }
    });

    // sortedArray.forEach((item, index) => {
    //   if (index > 0) return;
    //   console.log("sorted item in activites", item);

    // });

    setCustomActivites(tempCustomActivity);
    setDailyTargets(tempDT);
    setMF(tempMF);
    setSleep(tempSleep);
  };
  function sortObjectsByCreatedAt(array) {
    // Define a comparison function

    // Sort the array based on createdAt value
    let sortedArray = array;
    console.log("sorted array ha kya", sortedArray);
    getLatestActivites(sortedArray);
    setActivityArray(sortedArray);
  }
  useEffect(() => {
    console.log("data ha kya", data, Meals, habits);
    init();
    sortObjectsByCreatedAt(activityArray);
  }, [reload || PrimaryKey]);
  return (
    <Layout>
      <div className="flex flex-col w-full bg-[#F8F8F8] rounded-xl shadow-lg font-semibold text-font24 px-10 relative">
        {isCalendar === "true" && (
          <ArrowLeft
            color="black"
            onClick={() => {
              history.goBack();
            }}
            style={{
              color: "#000",
              position: "absolute",
              top: "-63px",
            }}
            className="cursor-pointer absolute top-5 left-5"
          />
        )}
        <div className="flex w-full justify-between text-blue-main mt-4">
          {isCalendar === "true" &&
            (workoutList?.length > 0 || enduranceWorkouts.length > 0) && (
              <>
                <Typography
                  textAlign={"center"}
                  sx={{
                    width: "100%",
                    fontWeight: 600,
                    fontSize: "20px",
                  }}
                  align="right"
                >
                  Assigned
                </Typography>

                <Typography
                  textAlign={"center"}
                  sx={{
                    width: "100%",
                    fontFamily: "DM Sans",
                    marginBottom: "16px",
                    fontWeight: 600,
                    fontSize: "20px",
                  }}
                  align="right"
                >
                  Completed
                </Typography>
              </>
            )}
        </div>
        <div
          className="flex flex-col justify-center items-center mx-auto"
          style={{
            width: "100%",
            justifyContent: "center",
          }}
        >
          {isCalendar === "true" ? (
            <>
              <ShowEnduranceWorkouts
                workouts={enduranceWorkouts}
                isImport={isImport}
                date={date}
                userUUID={userUUID}
                isCalendar={isCalendar}
                deleteIndividualWorkout={deleteIndividualWorkout}
                init={init}
                setLoading={setLoading}
                finishedData={enduranceData}
                thresholdValues={props?.location?.state?.thresholdValues}
              />
              {workoutList.map((workout, index) => {
                if (!anyCompletedWorkout && workout?.workoutFinished) {
                  setAnyCompletedWorkout(true);
                }
                return workout?.workoutAssigned?.isStructured !== false ? (
                  <RenderWorkouts
                    workout={workout}
                    isImport={isImport}
                    date={date}
                    userUUID={userUUID}
                    isCalendar={isCalendar}
                    deleteIndividualWorkout={deleteIndividualWorkout}
                    index={index}
                    location={props?.location}
                    initCall={init}
                    setLoading={setLoading}
                    ita={ita}
                  />
                ) : (
                  <RenderUnstructuredWorkouts
                    workout={workout}
                    isImport={isImport}
                    date={date}
                    userUUID={userUUID}
                    isCalendar={isCalendar}
                    deleteIndividualWorkout={deleteIndividualWorkout}
                    index={index}
                    location={props?.location}
                  />
                );
              })}
            </>
          ) : (
            <>
              {workoutList.isStructured !== false ? (
                <WorkoutContentPage
                  rounds={workoutList?.workoutContent?.rounds}
                  data={workoutList}
                  setData={setworkoutList}
                  isImport={isImport === "true"}
                  date={date}
                  userUUID={userUUID}
                  isThresholdAvailable={ita}
                  isCalendar={isCalendar}
                  showBackButton={true}
                  canDelete={false}
                  onSuccess={(data) => {
                    // update the workoutList with the new data
                    setworkoutList(data);
                  }}
                />
              ) : (
                <FollowThroughView
                  data={workoutList}
                  userUUID={userUUID}
                  showBackButton={true}
                  isImport={isImport === "true"}
                  date={date}
                  isCalendar={isCalendar}
                  canDelete={false}
                />
              )}
            </>
          )}
        </div>

        {!loading && Meals && Meals?.length > 0 && (
          <div
            style={{
              marginTop: "16px",
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            {/* <Typography
              sx={{
                fontFamily: "DM Sans",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "20px",
                color: "#000000",
                padding: "0px 20px",
              }}
            >
              Assigned Meals
            </Typography> */}
            {Meals.map((meal, index) => {
              let item = meal.item;
              console.log("meal: ", item);
              let mealFinished = item?.mealFinishedDetails;
              console.log("mealFinished: ", mealFinished);
              let feedback = item?.mealFinishedDetails?.meals
                ? item?.mealFinishedDetails?.meals[index]?.feedback
                : item?.mealFinishedDetails?.plandetails?.meals[index]
                    ?.feedback;
              return item?.unsavedMeals ? (
                <RenderMeal
                  meal={item?.unsavedMeals}
                  feedback={item?.feedback}
                  mealID={item?.id}
                  isFinished={item?.isMealFinished}
                />
              ) : mealFinished ? (
                <RenderMeal
                  deleteMealPlan={deleteMealPlan}
                  meal={mealFinished}
                  feedback={feedback}
                  mealID={item?.id}
                  isFinished={item?.isMealFinished}
                />
              ) : (
                <RenderMeal
                  deleteMealPlan={deleteMealPlan}
                  meal={item?.mealPlans}
                  feedback={feedback}
                  mealID={item?.id}
                />
              );
            })}
          </div>
        )}
        {!loading && habits && activityArray && activityArray?.length > 0 && (
          <div
            style={{
              marginTop: "16px",
              display: "flex",
              flexDirection: "column",
              // backgroundColor:"black",
              background: "white",
              borderRadius: "8px",
            }}
          >
            <div className="flex justify-between w-full  border-b-2 border-[#D7D7D7]">
              <Typography
                sx={{
                  fontFamily: "DM Sans",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "18px",
                  lineHeight: "20px",
                  color: "black",
                  padding: "15px 20px",
                }}
              >
                Assigned Habits
              </Typography>
              <div
                className="delete-workouts-btn mr-3 my-2 "
                onClick={handleDeleteAssignedActivity}
              >
                <DeleteRounded className="delete-btn-icon-in-showWorkouts" />
              </div>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "auto auto auto",
              }}
            >
              {dailyTargets && Object.keys(dailyTargets).length > 0 && (
                <div
                  className="flex justify-start items-left flex-col"
                  style={{
                    padding: "8px 20px",
                    borderLeft: "2px solid #D7D7D7",
                    borderBottom: "2px solid #D7D7D7",
                  }}
                >
                  <h3
                    style={{
                      color: "#8330e9",
                      fontFamily: "DM Sans",
                      fontSize: "16px",
                      fontWeight: "550",
                      marginBottom: "2px",
                    }}
                  >
                    Daily Targets
                  </h3>
                  {Object.keys(dailyTargets).map((key, index) => {
                    const obj = dailyTargets[key];
                    return (
                      <div
                        key={index}
                        className="flex items-center w-full justify-start"
                      >
                        <input
                          type="checkbox"
                          name="daily targets"
                          id="daily-targets"
                          checked={obj.completed}
                          className="mr-3"
                        />
                        <p
                          style={{
                            color: "black",
                            fontFamily: "DM Sans",
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          {key == "sleep" && `${obj.todo}hrs of Sleep`}
                          {key == "steps" && `${obj.todo} Steps`}
                          {key == "water" && `${obj.todo} ltr of Water`}
                        </p>
                      </div>
                    );
                  })}
                </div>
              )}
              {mF && mF.length > 0 && (
                <div
                  className="flex justify-start items-left flex-col"
                  style={{
                    borderLeft: "2px solid #D7D7D7",
                    padding: "8px 20px",
                    borderBottom: "2px solid #D7D7D7",
                  }}
                >
                  <h3
                    style={{
                      color: "#8330e9",
                      fontFamily: "DM Sans",
                      fontSize: "16px",
                      fontWeight: "550",
                      marginBottom: "2px",
                    }}
                  >
                    Mind Fulness
                  </h3>
                  <div className="flex items-start justify-start flex-col">
                    {mF?.map((obj, index) => {
                      return (
                        <div
                          key={index}
                          className="flex items-left w-full justify-start"
                        >
                          <input
                            type="checkbox"
                            name="daily targets"
                            id="daily-targets"
                            checked={obj?.completed}
                            className="mr-3"
                          />
                          <p
                            style={{
                              color: "black",
                              fontFamily: "DM Sans",
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            {obj?.todo}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {sleep && sleep.length > 0 && (
                <div
                  className="flex justify-start items-left flex-col"
                  style={{
                    borderLeft: "2px solid #D7D7D7",
                    padding: "8px 20px",
                    borderBottom: "2px solid #D7D7D7",
                  }}
                >
                  <h3
                    style={{
                      color: "#8330e9",
                      fontFamily: "DM Sans",
                      fontSize: "16px",
                      fontWeight: "550",
                      marginBottom: "2px",
                    }}
                  >
                    Sleep
                  </h3>
                  {sleep?.map((obj, index) => {
                    return (
                      <div
                        key={index}
                        className="flex items-center w-full justify-start"
                      >
                        <input
                          type="checkbox"
                          name="daily targets"
                          id="daily-targets"
                          checked={obj?.completed}
                          className="mr-3"
                        />
                        <p
                          style={{
                            color: "black",
                            fontFamily: "DM Sans",
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          {obj?.todo}
                        </p>
                      </div>
                    );
                  })}
                </div>
              )}
              {/* showing custom activities */}
              {Object.keys(customActivities).map((objc) => {
                console.log("habbits ke andar ke andar", objc);
                return (
                  customActivities?.[objc]?.length > 0 && (
                    <div
                      className="flex justify-start items-left flex-col"
                      style={{
                        borderLeft: "2px solid #D7D7D7",
                        padding: "8px 20px",
                        borderBottom: "2px solid #D7D7D7",
                      }}
                    >
                      <h3
                        style={{
                          color: "#8330e9",
                          fontFamily: "DM Sans",
                          fontSize: "16px",
                          fontWeight: "550",
                          marginBottom: "2px",
                        }}
                      >
                        {objc}
                      </h3>
                      <div className="flex items-start justify-start flex-col">
                        {customActivities?.[objc].map((obj, index) => {
                          return (
                            <div
                              key={index}
                              className="flex items-left w-full justify-start"
                            >
                              <input
                                type="checkbox"
                                name="daily targets"
                                id="daily-targets"
                                checked={obj?.completed}
                                className="mr-3"
                              />
                              <p
                                style={{
                                  color: "black",
                                  fontFamily: "DM Sans",
                                  fontSize: "16px",
                                  fontWeight: "500",
                                }}
                              >
                                {obj?.todo}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )
                );
              })}
            </div>
          </div>
        )}
      </div>
      {loading && (
        <div
          style={{
            width: "100%",
            position: "absolute",
            height: "100vh",
            background: "rgba(255,255,255, 0.3)",
            zIndex: 999,
            top: 0,
            left: 0,
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "40%",
              left: "45%",
              background: "#eee",
              width: 200,
              zIndex: 9999,
              padding: 50,
              borderRadius: 15,
            }}
          >
            <SpinnerComponent />
          </div>
        </div>
      )}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        zindex="999999"
      />
    </Layout>
  );
}

export const RenderWorkouts = ({
  workout,
  isImport,
  date,
  userUUID,
  isCalendar,
  deleteIndividualWorkout,
  index,
  initCall,
  setLoading,
  ita,
  setReload,
  updateWorkoutInfoForCalender,
  ...props
}) => {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const finishedRef = React.useRef(null);

  console.log("workout id: from rendrworkout: ", workout?.id);
  const [data, setData] = useState(workout);
  console.log("data ha kya", workout);

  const handleMarkCompleteWorkout = async (e) => {
    if (e.target.checked) {
      let tempWorkoutContent = workout?.workoutAssigned?.workoutContent;
      const tempWorkoutInfo = {};
      for (const key in workout?.workoutAssigned) {
        if (key !== "workoutContent" && key != "workoutCategory") {
          tempWorkoutInfo[key] = workout?.workoutAssigned[key];
        }
      }
      const filteredWorkoutInfo = Object.fromEntries(
        Object.entries(tempWorkoutInfo).filter(([key, value]) => value !== null)
      );
      const tempWorkoutFinishedDetails = {
        workoutInfo: { ...filteredWorkoutInfo },
        workoutContent: { ...tempWorkoutContent },
      };
      const payload = {
        atheleteID: workout?.atheleteID,
        workoutCompleteInfo: [
          {
            dateAssigned: workout?.dateAssigned,
            workoutTrackingID: workout?.id,
            // workoutFinishedDetails: tempWorkoutFinishedDetails,
            workoutAssignedID: workout?.workoutAssigned?.id,
            timeToFinish: 0,
            finished: true,
            dateFinished: `${moment().format("YYYY-MM-DD")}` + "T00:00:00.000Z",
            percievedDifficultyLevel: workout?.difficultyLevel || "",
            feedback: workout?.feedback || "",
            rating: workout?.rating || "",
          },
        ],
      };
      try {
        setLoading(true);
        let res = await axios({
          method: "post",
          url: `${APIURL}CF/api/CoachWeb/Create/atheleteFinishedWorkout`,
          data: payload,
        });
        initCall();

        return res.data.meals;
      } catch (err) {
        console.log(["err ===>>> ", err]);
        return [];
      } finally {
        setLoading(false);
      }
    } else {
      console.log("nitin");
    }
  };
  function handleSetData(newData) {
    console.log("this is from the handlesetdata", newData, data);
    updateWorkoutInfoForCalender(newData);
    setData((pre) => {
      const preData = { ...pre };
      if (preData?.workoutAssigned) {
        preData.workoutAssigned = newData;
      }
      return preData;
    });

    setReload((reload) => !reload);
  }
  return (
    <div className="flex relative justify-between w-full">
      <Box
        width={"50%"}
        style={{
          backgroundColor: "white",
          boxShadow: "0px 4px 4px 0px #00000008",
          marginBottom: "2.5rem",
        }}
      >
        <WorkoutContentPage
          rounds={workout?.workoutAssigned?.workoutContent?.rounds}
          data={data?.workoutAssigned}
          setData={(data) =>
            setData({
              ...data,
              workoutAssigned: data,
            })
          }
          onSuccess={handleSetData}
          deleteIndividualWorkout={deleteIndividualWorkout}
          workoutID={workout?.id}
          key={"assigned"}
          showBackButton={false}
          topRefToUse={finishedRef}
          isImport={isImport}
          date={date}
          userUUID={userUUID}
          isCalendar={isCalendar}
          isThresholdAvailable={ita}
        />
      </Box>
      <div style={{ width: "2rem" }} />
      <Box
        width={"50%"}
        style={{
          marginBottom: "2.5rem",
        }}
      >
        {workout?.workoutFinished ? (
          <WorkoutContentPage
            width={"100%"}
            isFinished={true}
            rounds={workout.workoutFinished?.workoutContent?.rounds}
            data={workout.workoutFinished}
            setData={(data) =>
              setData({
                ...data,
                workoutFinished: data,
              })
            }
            feedback={workout?.feedback}
            dateFinished={workout?.dateFinished}
            difficultyLevel={workout?.percievedDifficultyLevel}
            isImport={isImport}
            date={date}
            userUUID={userUUID}
            isCalendar={isCalendar}
            deleteIndividualWorkout={deleteIndividualWorkout}
            index={index}
            workoutID={workout?.id}
            showBackButton={false}
            topRef={finishedRef}
            isThresholdAvailable={ita}
          />
        ) : (
          <div
            className="flex flex-col w-full h-full bg-white-pure rounded-xl text-center shadow-xl"
            style={{
              border: "1px solid #e2e8f0",
            }}
          >
            <div className="flex justify-end items-center h-32 w-full">
              <div className="flex items-center">
                <p
                  style={{
                    color: "black",
                    fontSize: "13px",
                    fontWeight: "500",
                    marginRight: "5px",
                  }}
                >
                  Mark Complete
                </p>
                <input
                  type="checkbox"
                  style={{
                    width: "12px",
                    height: "12px",
                    marginLeft: "auto",
                    marginRight: "16px",
                    cursor: "pointer",
                  }}
                  name="mark-as-completed"
                  id=""
                  onChange={(e) => handleMarkCompleteWorkout(e)}
                />
              </div>
            </div>
            <div className="flex justify-center w-full items-center h-full bg-white-pure  rounded text-center">
              <img src={Bro} alt="not completed image" />
              {/* <p className="text-red-light">Not Completed!</p> */}
            </div>
          </div>
        )}
      </Box>
    </div>
  );
};

const RenderUnstructuredWorkouts = ({
  workout,
  deleteIndividualWorkout,
  index,
  ...props
}) => {
  const finishedRef = React.useRef(null);
  const {
    isImport = false,
    date,
    userUUID,
    isCalendar,
  } = queryString.parse(props?.location?.search);
  return (
    <div className="flex relative justify-between w-full">
      <FollowThroughView
        data={workout?.workoutAssigned}
        deleteIndividualWorkout={deleteIndividualWorkout}
        workoutID={workout?.id}
        key={"assigned"}
        showBackButton={false}
        topRefToUse={finishedRef}
        isImport={isImport}
        date={date}
        userUUID={userUUID}
        isCalendar={isCalendar}
        isFinished={workout?.workoutFinished}
      />
    </div>
  );
};
