import { v4 as uuidv4 } from "uuid";

export const getDeviceId = () => {
  let deviceId = localStorage.getItem("device_id");
  if (!deviceId) {
    deviceId = uuidv4();
    localStorage.setItem("device_id", deviceId);
  }
  return deviceId;
};

export const publicRoutesList = [
  "api/usersignup",
  "api/trainerProfile",
  "CF/api/CoachWeb/Create/trainerSignUpAdminRef",
  "CF/api/CoachWeb/Create/trainerSignUp",
  "api/userlogin",
  "api/authtrainer",
  "api/authtrainerLiveDebug",
  "api/handleRefreshTokenTrainer",
  "api/handleRefreshTokenUser",
  "api/handleLogoutTrainer",
  "api/handleLogoutUser",
  "CF/api/whatsapp/webhook",
  "CF/api//stripeconnect/stripe-webhookslinks",
  "CF/api/getWorkoutOpenAPI",
  "api/forgetpassword",
  "api/resettrainerpassword",
  "api/resetuserpassword",
  "api/userforgetpassword",
  "CF/api/getCoachesStats",
  "CF/api/CoachWeb/Get/oneTrainerProfile",
  "CF/api/LeadGen/insertNewLeadFromForm"
];
