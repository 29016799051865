import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, IconButton, TextField } from "@mui/material";
import FilterIcon from "../../assets/filter.svg";
import useOutsideClick from "./useOutsideClick";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontFamily: "DM Sans",
    backgroundColor: "#F5F5F5",
    color: theme.palette.common.black,
    maxWidth: "123px",
    minWidth: "100px",
    minHeight: "32px",
    padding: "7.081px 10.621px",
    // border: '0.8px solid #E1DFDD',
    borderLeft: "0.8px solid #E1DFDD",
    borderRight: "0.8px solid #E1DFDD",
    fontSize: 12.391,
  },
  [`&.${tableCellClasses.body}`]: {
    fontFamily: "DM Sans",
    alignItems: "flex-start",
    fontSize: 12,
    maxWidth: "auto",
    minWidth: "123px",
    padding: "7px 10px",
    borderLeft: "0.8px solid #E1DFDD",
    borderRight: "0.8px solid #E1DFDD",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    lineHeight: "21.24px",
    borderRadius: 0,
    textAlign: "start",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  //   '&:nth-of-type(odd)': {
  //     backgroundColor: theme.palette.action.hover,
  //   },
  //   hide last border
  "&:last-child td, &:last-child th": {
    borderBottom: "0.8px solid #E1DFDD",
  },
}));

export default function MealCardTable(props) {
  const weekDays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const mealType = [];

  const meals = React.useState(props.tabledata["week-1"]);

  meals?.[0]?.map((data) => {
    data?.meals?.map((food, key) => {
      mealType[key] = food.name;
    });
  });

  const [editingId, setEditingId] = React.useState("");
  const [editingCell, setEditingCell] = React.useState({});

  const calculateMacrosFromMeals = (meals, key) => {
    let protein = 0;
    let carbs = 0;
    let fat = 0;
    let calories = 0;
    meals?.map((meal) => {
      let macros = meal.macros;
      protein += +macros.protein;
      carbs += +macros.carbs;
      fat += +macros.fat;
      calories += calculateCalories(macros.protein, macros.fat, macros.carbs);
    });

    if (props?.tabledata["week-1"][key].calories !== calories) {
      let changed = { ...props?.tabledata };
      changed["week-1"][key].macros = {
        protein: protein,
        carbs: carbs,
        fat: fat,
      };
      changed["week-1"][key].calories = calories;
      props?.setMealplans(changed);
    }
    return { protein, carbs, fat, calories };
  };

  return (
    <TableContainer
      component={Paper}
      sx={{
        maxHeight: "65vh",
        overflow: "scroll",
        boxShadow: "none",
        borderRadius: 0,
        borderTop: "0.8px solid #E1DFDD",
        borderRadius: "10.6px",
      }}
    >
      <Table sx={{ minWidth: 700 }}>
        <TableHead style={{ position: "sticky", top: 0, zIndex: 1 }}>
          <TableRow>
            {/* <StyledTableCell></StyledTableCell> */}
            <StyledTableCell align="left">
              <IconButton sx={{ padding: 0, marginRight: 1 }}>
                {/* <FilterIcon /> */}
                <img src={FilterIcon} alt="filter" />
              </IconButton>
              Day
            </StyledTableCell>
            {mealType?.map((data) => (
              <StyledTableCell align="left">{data}</StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.tabledata?.["week-1"]?.map((data, key) => {
            let macros = calculateMacrosFromMeals(data.meals, key);
            return (
              <StyledTableRow sx={{ verticalAlign: "top" }}>
                {/* <StyledTableCell component="th" scope="row">
                  {data.day.split(" ")[1]}
                </StyledTableCell> */}
                <StyledTableCell component="th" scope="row">
                  {weekDays[key]}
                  <p style={{ color: "#616161", fontWeight: 600 }}>
                    {"\nP: " +
                      macros.protein +
                      " C: " +
                      macros.carbs +
                      " F: " +
                      macros.fat}
                  </p>
                  <p
                    style={{
                      color: "#616161",
                      fontSize: 12,
                      fontWeight: 600,
                    }}
                  >
                    {"CAL: " + data.calories}
                  </p>
                </StyledTableCell>
                {data?.meals?.map((food, index) => {
                  return (
                    <CustomTableCell
                      editingId={editingId}
                      setEditingId={setEditingId}
                      food={food}
                      index={index}
                      rowKey={key}
                      editingCell={editingCell}
                      setEditingCell={setEditingCell}
                      setMealPlan={props?.setMealplans}
                      mealPlan={props?.tabledata}
                      setResponseData={props?.setResponseData}
                      edited={props?.edited}
                      setEdited={props?.setEdited}
                    />
                  );
                })}
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const CustomTableCell = ({
  food,
  editingId,
  setEditingId,
  index,
  rowKey,
  editingCell,
  setEditingCell,
  mealPlan,
  setMealPlan,
  setResponseData,
  edited,
  setEdited,
}) => {
  const activeRef = React.useRef(null);
  const onOutsideClick = () => {
    if (editingId === food.name + index + rowKey) {
      setEditingId("");
      let changed = { ...mealPlan };
      changed["week-1"][rowKey].meals[index] = editingCell;
      setMealPlan(changed);
      setResponseData(JSON.stringify(changed));
    }
  };

  useOutsideClick(activeRef, onOutsideClick);

  return editingId === food.name + index + rowKey ? (
    <StyledTableCell
      onDoubleClick={() => setEditingId("")}
      ref={activeRef}
      component="th"
      align={"top"}
      scope="row"
    >
      {editingCell?.foods?.map((name, key) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mt: 1,
            }}
          >
            <input
              type="text"
              defaultValue={name.name}
              onChange={(e) => {
                let changed = { ...editingCell };
                changed.foods[key].name = e.target.value;
                setEditingCell(changed);
              }}
              style={{
                width: "100%",
                border: "none",
                outline: "none",
                fontSize: 12,
                fontFamily: "DM Sans",
                fontWeight: 400,
                color: "#616161",
                padding: "7px 10px",
                border: "0.8px solid #E1DFDD",
                whiteSpace: "pre-wrap",
                wordBreak: "break-word",
                lineHeight: "14px",
                borderRadius: "5px",
                textAlign: "start",
                marginRight: "2px",
              }}
            />

            <input
              type="text"
              defaultValue={name.quantity}
              onChange={(e) => {
                let changed = { ...editingCell };
                changed.foods[key].quantity = e.target.value;
                setEditingCell(changed);
              }}
              style={{
                width: "80%",
                border: "none",
                outline: "none",
                fontSize: 12,
                fontFamily: "DM Sans",
                fontWeight: 400,
                color: "#616161",
                padding: "7px 10px",
                border: "0.8px solid #E1DFDD",
                whiteSpace: "pre-wrap",
                wordBreak: "break-word",
                lineHeight: "14px",
                borderRadius: "5px",
                textAlign: "start",
              }}
            />
          </Box>
        );
      })}

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mt: 1,
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            border: "none",
            outline: "none",
            fontSize: 12,
            fontFamily: "DM Sans",
            fontWeight: 400,
            color: "#616161",
            padding: "7px 10px",
            border: "0.8px solid #E1DFDD",
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
            lineHeight: "14px",
            borderRadius: "5px",
            textAlign: "start",
            marginRight: "2px",
          }}
        >
          <label style={{ width: "30px", color: "#616161", fontWeight: 600 }}>
            P&nbsp;:
          </label>

          <input
            type="text"
            defaultValue={editingCell?.macros?.protein}
            onChange={(e) => {
              let changed = { ...editingCell };
              changed.macros.protein = e.target.value;
              setEditingCell(changed);
            }}
            style={{
              width: "100%",
              border: "none",
              outline: "none",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            border: "none",
            outline: "none",
            fontSize: 12,
            fontFamily: "DM Sans",
            fontWeight: 400,
            color: "#616161",
            padding: "7px 10px",
            border: "0.8px solid #E1DFDD",
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
            lineHeight: "14px",
            borderRadius: "5px",
            textAlign: "start",
            marginRight: "2px",
          }}
        >
          <label style={{ width: "30px", color: "#616161", fontWeight: 600 }}>
            F&nbsp;:
          </label>

          <input
            type="text"
            defaultValue={editingCell?.macros?.fat}
            onChange={(e) => {
              let changed = { ...editingCell };
              changed.macros.fat = e.target.value;
              setEditingCell(changed);
            }}
            style={{
              width: "100%",
              border: "none",
              outline: "none",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",

            fontSize: 12,
            fontFamily: "DM Sans",
            fontWeight: 400,
            color: "#616161",
            padding: "7px 10px",
            border: "0.8px solid #E1DFDD",
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
            lineHeight: "14px",
            borderRadius: "5px",
            textAlign: "start",
            marginRight: "2px",
          }}
        >
          <label style={{ width: "30px", color: "#616161", fontWeight: 600 }}>
            C&nbsp;:
          </label>

          <input
            type="text"
            defaultValue={editingCell?.macros?.carbs}
            onChange={(e) => {
              let changed = { ...editingCell };
              changed.macros.carbs = e.target.value;
              setEditingCell(changed);
            }}
            style={{
              width: "100%",
              border: "none",
              outline: "none",
            }}
          />
        </div>
      </Box>
    </StyledTableCell>
  ) : (
    <StyledTableCell
      onDoubleClick={() => {
        if (!edited) setEdited(true);
        setEditingId(food.name + index + rowKey);
        setEditingCell(food);
      }}
      ref={activeRef}
      component="th"
      align={"top"}
      scope="row"
    >
      <p>
        {food?.foods?.map((name, key) => {
          return (
            (key == food.foods.length - 1 && food.foods.length - 1 != 0
              ? "& "
              : "") +
            name.name +
            " " +
            "(" +
            name.quantity +
            ")" +
            (key >= food.foods.length - 2 ? "" : ",") +
            "  "
          );
        })}
      </p>
      <p style={{ color: "#616161", fontWeight: 600 }}>
        {"\nP: " +
          food.macros.protein +
          " C: " +
          food.macros.carbs +
          " F: " +
          food.macros.fat +
          " CAL: " +
          calculateCalories(
            food.macros.protein,
            food.macros.fat,
            food.macros.carbs
          )}
      </p>
    </StyledTableCell>
  );
};

const calculateCalories = (Protiens, Fats, Carbs) => {
  return Protiens * 4 + Fats * 9 + Carbs * 4;
};
