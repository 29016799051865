import React from "react";
import TodoList from "../../components/Todos";
import { useSelector } from "react-redux";
import Layout from "../../components/layout/Layout";

const Todos = () => {
  const trainerUUID = useSelector((state) => state.auth.trainerUUID);
  return (
    <Layout>
      <div
        style={{
          background: "#fff",
          height: "calc(100vh - 110px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          borderRadius: "20px",
        }}
      >
        <div
          style={{
            maxHeight: "calc(100vh - 150px)",
          }}
          className="my-8 w-[660px] relative shadow-2xl rounded-lg mx-auto overflow-hidden"
        >
          <TodoList key={Math.random()} trainerUUID={trainerUUID} />
        </div>
      </div>
    </Layout>
  );
};

export default Todos;
