import React from "react";
import { ChannelList } from "stream-chat-react";
import { chatClient } from "../../utils/chat";
import { useSelector } from "react-redux";
import ClientCard from "./ClientCard";

const ClientsChannelList = ({
  clients,
  onSelectClient,
  selectedClient,
  userUnreadMessage,
  unreadMarks,
  userWiseChannel,
}) => {
  const coachID = useSelector((state) => state.auth?.trainerUUID);
  const filters = { type: "messaging", members: { $in: [coachID] } };
  const sort = { last_message_at: -1 };
  const customChannelTeamFilter = (channels) => {
    // return channels.filter((channel) => channel.type === "messaging");
    let channelsToShow = channels.filter((channel) => {
      let members = Object.keys(channel?.state?.members);

      // check if exist in channelist
      if (
        channel.type === "messaging" &&
        clients.find(
          (client) =>
            client.UserUUID == members[0] || client.UserUUID == members[1]
        )
      ) {
        return true;
      }
      return false;
    });
    // set first channel as active
    console.log({ channelsToShow });
    return channelsToShow;
  };
  return (
    <div>
      <ChannelList
        LoadingIndicator={() => <div>Loading...</div>}
        filters={filters}
        sort={sort}
        channelRenderFilterFn={customChannelTeamFilter}
        Preview={(previewProps) => {
          return (
            <CustomPreview
              {...previewProps}
              clients={clients}
              onSelectClient={onSelectClient}
              selectedClient={selectedClient}
              userUnreadMessage={userUnreadMessage}
              unreadMarks={unreadMarks}
              userWiseChannel={userWiseChannel}
            />
          );
        }}
      />
    </div>
  );
};

export default ClientsChannelList;

const CustomPreview = (props) => {
  const {
    channel,
    setActiveChannel,
    watchers,
    unread,
    clients,
    onSelectClient,
    selectedClient,
    active,
    userUnreadMessage,
    unreadMarks,
    userWiseChannel,
  } = props;

  let members = Object.keys(channel?.state?.members);
  let user = members.find((member) => member !== chatClient.userID);

  let currentClient = clients.find(
    (client) => client.UserUUID == user || client.UserUUID == members[1]
  );
  if (unread > 0) console.log({ unread, currentClient });

  return (
    <div
      onClick={() => {
        setActiveChannel(channel, watchers);
      }}
    >
      <ClientCard
        isSelected={selectedClient?.UserUUID === currentClient?.UserUUID}
        onSelectClient={onSelectClient}
        client={currentClient}
        key={currentClient?.UserUUID}
        unread={unread}
        userUnreadMessage={userUnreadMessage}
        unreadMarks={unreadMarks}
        userWiseChannel={userWiseChannel}
      />
    </div>
  );
};
