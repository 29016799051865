
export const FOOD_BASED = "FoodBased";
export const MACOS_BASED = "MacrosBased";
export const CREATE_WORKOUT_PLAN = "Create new workout plan";
export const CREATE_MEAL_PLAN = "Create new diet plan";
export const CREATE_SINGLE_WORKOUT = "Create single workout";
export const CREATE_TRAINING_SET = "Create a training set";
export const ASSIST_MODIFY_WORKOUTS = "Assist modify workouts";
export const ADD_AND_MANAGE_CLIENTS = "Add and manage clients";
export const ANALYSE_CLIENT_MEDICAL_PROFILE = "Analyse client medical profile";
export const GET_INSIGHTS_ON_CLIENT_WORKOUTS =
  "Get insights on client workouts";
export const KNOW_MORE = "Know more";
export const HELP_US_BE_BETTER = "Help us be better";
export const GENERATE_SINGLE_WORKOUT = "Generate workout";
export const GENERATE_RECIPE = "Generate recipe";
export const GENERATE_MEAL_PLAN = "Generate meal plan";

export const colorsArray = [
  "#00D43B",
  "#464FEB",
  "#FF88DE",
  "#9237E3",
  "#FF9B04",
];

export const COACHES_BLOCKED = [""];

export const formButtonsData = [
  { name: 'save', background: 'linear-gradient(to right, rgb(70, 79, 235), rgb(131, 48, 233))', color: '#ffffff', hover: '#0F63A3' },
  { name: 'preview', background: 'rgb(107 114 128)', color: '#ffffff', hover: '#374151' },
  // { name: 'copy', background: 'rgb(235 245 255)', color: '#2383CD', hover: '#E1EFFE' },
]

//leads form preset data

export const leadPresetData = [
  {
    id: 1,
    type: 'Multi-line type',
    value: 'Your Name',
    possibleAnswers: [''],
    required: true,
    presetData: true
  },
  {
    id: 2,
    type: 'Multi-line type',
    value: 'Your Email',
    possibleAnswers: [''],
    required: true,
    presetData: true
  },
  {
    id: 3,
    type: 'Single Select',
    value: 'How can I help you?',
    possibleAnswers: ['Lose weight', 'Build muscle', 'Tone up', 'Gain confidence'],
    required: false,
    presetData: false
  },
  {
    id: 4,
    type: 'Single Select',
    value: 'Your gender',
    possibleAnswers: ['Male', 'Female', 'Nonbinary', 'Prefer not to say'],
    required: false,
    presetData: false

  },
  {
    id: 5,
    type: 'Number Select',
    value: 'Your age',
    possibleAnswers: [''],
    required: false,
    presetData: false
  },
  {
    id: 6,
    type: 'Number Select',
    value: 'Your Height',
    possibleAnswers: [''],
    required: false,
    presetData: false
  },
  {
    id: 7,
    type: 'Number Select',
    value: 'Your Weight',
    possibleAnswers: [''],
    required: false,
    presetData: false
  },
  {
    id: 8,
    type: 'Multi-line type',
    value: 'What are your main goals?',
    possibleAnswers: [''],
    required: false,
    presetData: false

  },
  {
    id: 9,
    type: 'Multi-line type',
    value: 'What is your motivation to start now?',
    possibleAnswers: [''],
    required: false,
    presetData: false

  },
  {
    id: 10,
    type: 'Multi-line type',
    value: 'What is your monthly budget for your goals?',
    possibleAnswers: [''],
    required: false,
    presetData: false

  },
  // {
  //   id: 11,
  //   type: 'Single Select',
  //   value: 'What makes us a good fit to work together?',
  //   possibleAnswers: ['$120 to $300 per month', '$300 to $600 per month', 'Over $1000 per month'],
  //   required: false,
  //   presetData: false

  // },
]

