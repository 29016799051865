import React, { useState } from "react";
import { useChatContext } from "stream-chat-react";
import Fuse from "fuse.js";

import "./EditChannel.css";

import { UserList } from "../CreateChannel/UserList";

import { CloseCreateChannel } from "../../assets";
import toast from "../../../../components/Toast/toast";
import SearchBar from "../../../../components/Dashboard/SearchBar";
import {
  deleteChannelProgram,
  getChannelPrograms,
} from "../../../../ApiServices/ChannelProgramTracker/Api";
import {
  assignPrograms,
  deleteAssignedProgramMultiple,
} from "../../../../ApiServices/Programs/Api";

const ChannelNameInput = (props) => {
  const { channelName = "", setChannelName, request, cancel } = props;

  const handleChange = (event) => {
    event.preventDefault();
    setChannelName(event.target.value);
  };

  return (
    <div className="channel-name-input__wrapper">
      <p>Name</p>
      <input
        className="channel-name-input__input"
        onChange={handleChange}
        placeholder="channel-name"
        type="text"
        value={channelName}
      />
      <div className="flex items-end justify-between w-full py-2">
        <p>Edit Members</p>
        <SearchBar
          placeholder={"Search for clients"}
          request={request}
          cancel={cancel}
          bgcol={"#F2F2F2"}
          style={{
            height: "40px",
            width: "200px",
          }}
        />
      </div>
    </div>
  );
};

const DeleteChannelModal = () => {
  return <div className="delete-channel-modal__container"></div>;
};

export const EditChannel = (props) => {
  const { filters, setIsEditing } = props;
  const { channel } = useChatContext();
  const editing = true;

  const [channelName, setChannelName] = useState(
    channel?.data.name || channel?.data.id
  );
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [fullUsersLists, setFullUsersLists] = useState([]);

  const addProgramsForSelectedUsers = async (selectedUsers) => {
    let channelProgram = await getChannelPrograms(channel.id);
    if (channelProgram && channelProgram.programID) {
      let programID = channelProgram.programID;
      let startDate = channelProgram.progStartDate;
      await assignPrograms(programID, selectedUsers, startDate);
    }
  };

  const updateChannel = async (event) => {
    event.preventDefault();

    const nameChanged = channelName !== (channel.data.name || channel.data.id);

    if (nameChanged) {
      await channel.update(
        { name: channelName },
        { text: `Channel name changed to ${channelName}` }
      );
    }

    if (selectedUsers.length) {
      addProgramsForSelectedUsers(selectedUsers);
      await channel.addMembers(selectedUsers);
    }

    setChannelName(null);
    setIsEditing(false);
    setSelectedUsers([]);
  };

  const deleteProgramsForAllUsers = async () => {
    let channelProgram = await getChannelPrograms(channel.id);
    if (channelProgram && channelProgram.programID) {
      const sort = { user_id: -1 };
      const alreadyUser = await channel.queryMembers({}, sort, {});
      let arr = [];
      // console.log("memebers, ",channel.data.created_by)
      alreadyUser.members.map((key) => {
        arr.push(key.user_id);
      });
      let programID = channelProgram.programID;
      let startDate = channelProgram.progStartDate;
      await deleteAssignedProgramMultiple(arr, programID, startDate);
      await deleteChannelProgram(channel.id);
    }
  };

  const deleteChannel = async (event) => {
    event.preventDefault();
    await deleteProgramsForAllUsers();
    const destroy = await channel.delete();
    if (destroy) {
      toast("Channel Deleted", "success");
      setChannelName(null);
      setIsEditing(false);
      setSelectedUsers([]);
    } else {
      alert("Error in Deleteing channel");
    }
  };

  const request = async (searchedVal) => {
    const fuseOptions = {
      keys: ["name", "userName"], // Properties to search within
      threshold: 0.1, // Adjust the threshold for fuzzy matching
    };

    if (searchedVal !== "") {
      const fuse = new Fuse(fullUsersLists, fuseOptions);
      const searchResults = fuse.search(searchedVal);

      const filteredRows = searchResults.map((result) => result.item);

      setUsers(filteredRows);

      console.log("teamChannels ", fullUsersLists);
    } else {
      setUsers(fullUsersLists);
    }
  };

  const cancel = () => {
    setUsers(fullUsersLists);
  };

  return (
    <div className="edit-channel__container">
      <div className="edit-channel__header">
        <p>Edit Channel</p>
        <CloseCreateChannel {...{ setIsEditing }} />
      </div>
      <ChannelNameInput {...{ channelName, setChannelName, request, cancel }} />
      <UserList
        {...{
          filters,
          setSelectedUsers,
          editing,
          selectedUsers,
          users,
          setFullUsersLists,
          setUsers,
        }}
      />
      <div className="edit-channel__button-group">
        <div className="edit-channel__button-wrapper" onClick={updateChannel}>
          <p>Save Changes</p>
        </div>
        <div
          className="edit-channel__button-wrapper-delete"
          onClick={deleteChannel}
        >
          <p>Delete Channel</p>
        </div>
      </div>
    </div>
  );
};
