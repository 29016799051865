import { store } from "@/redux/store";
import axios from "axios";

export const getMealScheduleByCoachID = async (coachID) => {
  try {
    let res = await axios({
      method: "get",
      url: `${store.getState().auth.apiURL}CF/api/getAllMealSchedulesByCoach`,
      params: {
        coachID: coachID,
      },
    });
    return res.data.result;
  } catch (error) {
    console.log("error in getMealScheduleByCoachID", error);
    return [];
  }
};

export const getAllMealSchedules = async (coachID, adminID) => {
  let coachMealSchedules = (await getMealScheduleByCoachID(coachID)) || [];
  let adminMealSchedules = adminID
    ? await getMealScheduleByCoachID(adminID)
    : [] || [];
  return coachMealSchedules.concat(adminMealSchedules);
};

export const getMealsByCoachId = async (trainerUUID) => {
  try {
    let res = await axios({
      method: "get",
      url: `${store.getState().auth.apiURL}CF/api/meal/getMealsByCoachId`,
      params: {
        coachId: trainerUUID,
      },
    });
    return res.data.meals;
  } catch (err) {
    console.log(["err ===>>> ", err]);
    return [];
  }
};
// create mealPlan with ID
export const createMealPlanWithID = async (mealPlan, mealPlanID) => {
  console.log("new meal plan: ", mealPlan);
  // remove null values from mealPlan
  Object.keys(mealPlan).forEach(
    (key) => mealPlan[key] == null && delete mealPlan[key]
  );

  await createMealPlan(mealPlan);
};

export const createMealPlan = async (data) => {
  let res = await axios({
    method: "post",
    url: `${store.getState().auth.apiURL}CF/api/meal/create`,
    data: data,
  });
  return res?.data?.meal;
};

export const copyTemplateAsMealPlan = async (mealPlan, coachID) => {
  let data = {
    id: undefined,
    ...mealPlan,
    coachId: coachID,
  };

  // remove null values from mealPlan
  Object.keys(data).forEach((key) => data[key] == null && delete data[key]);
  return await createMealPlan(data);
};

// get meal plan by coachID

export const getMealPlanByCoachID = async (coachID) => {
  let res = await axios({
    method: "get",
    url: `${store.getState().auth.apiURL}CF/api/meal/getMealsByCoachId`,
    params: {
      coachId: coachID,
    },
  });
  return res?.data?.meals;
};

// get all meal plans
export const getAllMealPlans = async (coachID, adminID) => {
  let coachMealPlans = (await getMealPlanByCoachID(coachID)) || [];
  let adminMealPlans = adminID ? await getMealPlanByCoachID(adminID) : [] || [];
  return coachMealPlans.concat(adminMealPlans);
};

// update meal plan
export const updateMealPlan = async (id, data) => {
  await axios({
    method: "post",
    url: `${store.getState().auth.apiURL}CF/api/meal/update?id=${id}`,
    data: data,
  });
};

// update meal schedule
export const updateMealSchedule = async (data) => {
  let res = await axios({
    method: "post",
    url: `${store.getState().auth.apiURL}CF/api/updateMealSchedule`,
    data: data,
  });

  return res?.data?.result;
};

// getting all meal schedules for subordinate
export const getMealSchedulesByCoachIDs = async (coachIDs) => {
  try {
    let res = await axios({
      method: "post",
      url: `${store.getState().auth.apiURL
        }CF/api/mealSchedules/getMealSchedulesForAllCoaches`,
      data: {
        coachIDs: coachIDs,
      },
    });

    return res?.data?.result || [];
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const deleteAssignedMealPlan = async (mealPlanID) => {
  let res = await axios({
    method: "post",
    url: `${store.getState().auth.apiURL}CF/api/deleteMealAssigned`,
    data: {
      id: mealPlanID,
    },
  });
  return res.status === 200;
};

