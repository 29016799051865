import {Skeleton} from "@/components/ui/skeleton.jsx";

const LoadingTrainerList = () => {
    return (
        <div className={'flex flex-col gap-1 p-2'}>
            {
                [1, 2, 3, 4, 5].map((item, index) => (
                    <Skeleton className={'h-[52px] w-full '} key={index}/>
                ))
            }
        </div>
    )
}

export default LoadingTrainerList;