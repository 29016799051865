import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { useSelector } from "react-redux";
import ExitIcon from "../../assets/copilot/exitIcon.svg";
import SendIcon from "../../assets/dashboard/sendIcon.svg";

export default function AddClientModal({ open, setOpen, inviteSuccess }) {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const [emails, setEmails] = useState("");

  const trainerInfo = useSelector((state) => state.auth?.data);

  const onSend = async () => {
    let trainerDetails = JSON.parse(localStorage.getItem("trainerCredentials"));
    const clientEmails = emails
      .split(",")
      .filter((email) => /\S+@\S+\.\S+/.test(email));
    let clients = [];
    if (clientEmails.length <= 0) {
      return;
    }

    clientEmails.map((email) => {
      let trimmedEmail = email.trim();
      let temp = {};
      temp["userName"] = "";
      temp["userEmail"] = trimmedEmail;
      temp["trainerName"] = trainerInfo?.name;
      temp["trainerEmail"] = trainerInfo?.email;
      temp["trainerUUID"] = trainerInfo?.id;
      temp[
        "trainerURL"
      ] = `https://trainer.spur.fit/addTrainee/${trainerInfo?.id}?email=${trimmedEmail}`;

      clients.push(temp);
    });
    console.log("clients: ", clients);
    await axios({
      method: "post",
      url: `${APIURL}api/addtraineeemail`,
      data: clients,
    }).then((res) => {
      console.log("added successfully", res);
      inviteSuccess();
    });

    setOpen(false);
  };

  return (
    <Dialog
      sx={{
        backdropFilter: "blur(5px)",
      }}
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        sx: {
          borderRadius: "16px",
          maxWidth: "100%",
          padding: "17px 22px",
        },
      }}
    >
      <DialogTitle
        sx={{
          fontSize: "16px",
          fontWeight: 500,
          lineHeight: "20px",
          fontFamily: "DM Sans",
        }}
      >
        Add New Member
      </DialogTitle>

      <DialogContent>
        <img
          src={ExitIcon}
          style={{
            height: "28px",
            width: "28px",
            position: "absolute",
            top: "22px",
            right: "22px",
            cursor: "pointer",
          }}
          onClick={() => setOpen(false)}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "642px",
          }}
        >
          <input
            style={{
              border: "1px solid #8330E9",
              width: "598px",
              height: "52px",
              borderRadius: "8px",
              width: "100%",
              padding: "10px 20px",
              fontSize: "18px",
              fontFamily: "DM Sans",
              fontWeight: 400,
              marginTop: "16px",
              outline: "none",
            }}
            autoComplete="off"
            placeholder="Enter Email, comma separated"
            onChange={(e) => {
              setEmails(e.target.value);
            }}
          />

          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "DM Sans",
              mt: "29px",
              fontWeight: 400,
            }}
          >
            Enter the email addresses of your clients in the given text field
            and watch them join your fitness journey! You can add multiple
            emails by separating them with commas.
          </Typography>
        </Box>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "center" }}>
        <button
          style={{
            width: "178px",
            height: "44px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "linear-gradient(90deg, #464FEB 10.42%, #8330E9 100%)",
            borderRadius: "100px",
            fontFamily: "DM Sans",
            fontSize: "16px",
            color: "#ffffff",
          }}
          onClick={onSend}
        >
          <img
            style={{
              height: "24px",
              width: "24px",
              marginRight: "8px",
            }}
            src={SendIcon}
          />
          Send Invite
        </button>
      </DialogActions>
    </Dialog>
  );
}
