import { Stack } from "@mui/material";
import React, { useContext, useEffect, useState, useRef } from "react";
import Round from "./Round";
import AddIcon from "../../assets/workoutBuilder/addIcon.svg";
import RemoveIcon from "../../assets/workoutBuilder/removeIcon.svg";
import { formatSecondsToMMSS } from "./helpers";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { WorkoutContext } from "./WorkoutBuilder";
import { WorkoutModalContext } from "./WorkoutBuilderModal";
const WorkoutContentPage = ({
  rounds,
  setRounds,
  setSelectedExerciseInfo,
  comingFromModal,
  shouldScrollToError,
  triggerRecheck,
  showErrorWarning,
}) => {
  const [firstErrorExerciseInfo, setFirstErrorExerciseInfo] = useState(null);
  const firstErrorExerciseRef = useRef(null);
  const { isDraggingSection } = useContext(
    comingFromModal ? WorkoutModalContext : WorkoutContext
  );

  useEffect(() => {
    if (shouldScrollToError && firstErrorExerciseRef.current) {
      firstErrorExerciseRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [shouldScrollToError, firstErrorExerciseInfo]);

  const updateFirstErrorRef = (ref, roundIndex, exerciseIndex) => {
    if (ref) {
      firstErrorExerciseRef.current = ref;
    }
    setFirstErrorExerciseInfo((prevInfo) => {
      if (
        !prevInfo ||
        roundIndex < prevInfo.roundIndex ||
        (roundIndex === prevInfo.roundIndex &&
          exerciseIndex < prevInfo.exerciseIndex)
      ) {
        return { roundIndex, exerciseIndex };
      }
      return prevInfo;
    });
  };

  const AddRounds = () => {
    setRounds([
      ...rounds,
      {
        round: {
          numRounds: 1,
          measurements: [],
        },
      },
    ]);
  };

  const addRoundRestTime = (roundIndex, seconds) => {
    setRounds((prevRounds) => {
      const updatedRounds = [...prevRounds];
      updatedRounds[roundIndex].round.restTime = seconds;
      return updatedRounds;
    });
  };

  const incrementOrDecrementRestTimeBy30Seconds = (
    roundIndex,
    isIncrement = true
  ) => {
    setRounds((prevRounds) => {
      const updatedRounds = [...prevRounds];
      const currentRestTime = +updatedRounds[roundIndex].round.restTime;
      if (isNaN(currentRestTime)) {
        updatedRounds[roundIndex].round.restTime = 30;
        return updatedRounds;
      }
      if (isIncrement) {
        updatedRounds[roundIndex].round.restTime = currentRestTime + 15;
      } else {
        updatedRounds[roundIndex].round.restTime = currentRestTime - 15;
      }
      return updatedRounds;
    });
  };

  return (
    <Stack
      sx={{
        // backgroundColor: "#fff",
        borderRadius: "12px",
        marginBottom: "500px",
      }}
    >
      <Droppable
        droppableId="my-droppable-section"
        isDropDisabled={!isDraggingSection}
      >
        {(provided, snapshot) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {rounds.map((round, index) => {
              return (
                <Draggable
                  key={"round-" + index}
                  draggableId={"round-" + index}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Round
                        key={index}
                        index={index}
                        round={round.round}
                        setRounds={setRounds}
                        setSelectedExerciseInfo={setSelectedExerciseInfo}
                        comingFromModal={comingFromModal}
                        updateFirstErrorRef={updateFirstErrorRef}
                        triggerRecheck={triggerRecheck}
                        showErrorWarning={showErrorWarning}
                      />
                      {
                        // rounds.length > 1 && index !== rounds.length - 1&&
                        <RenderRest
                          key={"rest" + index}
                          rounds={rounds}
                          setRounds={setRounds}
                          index={index}
                          incrementOrDecrementRestTimeBy30Seconds={
                            incrementOrDecrementRestTimeBy30Seconds
                          }
                        />
                      }
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>

      <div
        style={{
          display: "flex",
          justifyContent: "end",
          padding: "24px",
        }}
      >
        <div
          style={{
            backgroundColor: "#fff",
            borderRadius: "12px",
          }}
        >
          <button
            style={{
              cursor: "pointer",
              width: "148px",
              borderWidth: 2,
            }}
            className="button-new-outlined"
            onClick={AddRounds}
          >
            Add Section
          </button>
        </div>
      </div>
    </Stack>
  );
};

export default WorkoutContentPage;

const RenderRest = ({
  rounds,
  setRounds,
  index,
  incrementOrDecrementRestTimeBy30Seconds,
}) => {
  let extraStyles = {};
  if (rounds.length !== index + 1) {
    extraStyles = { marginTop: "-6px", marginBottom: "-6px" };
  }
  return (
    <div
      style={{
        display: "flex",
        background: "transparent",
        alignItems: "center",
        justifyContent: "center",
        // padding: "8px 16px",
        position: "relative",
        // ...extraStyles,
      }}
      className="my-3"
    >
      {rounds[index].round?.restTime ? (
        <button className="button-new-outlined-rounded">
          <img
            src={RemoveIcon}
            alt="remove icon"
            style={{ width: "24px", height: "24px", cursor: "pointer" }}
            onClick={() => {
              incrementOrDecrementRestTimeBy30Seconds(index, false);
            }}
          />
          <p className="rounded-btn-text">
            {formatSecondsToMMSS(rounds[index].round?.restTime)}
          </p>{" "}
          <img
            src={AddIcon}
            alt="add icon"
            style={{ width: "24px", height: "24px", cursor: "pointer" }}
            onClick={() => {
              incrementOrDecrementRestTimeBy30Seconds(index, true);
            }}
          />
        </button>
      ) : rounds?.length !== index + 1 ? (
        <button
          onClick={() => incrementOrDecrementRestTimeBy30Seconds(index, true)}
          className="button-new-outlined-rounded"
        >
          <p className="rounded-btn-text">Add Break</p>
        </button>
      ) : (
        <></>
      )}
    </div>
  );
};
