import React from 'react';
import Workouts from '.';
import { WorkoutProvider } from './WorkoutContex';



function WorkoutEntry(props) {
    return (
      <WorkoutProvider>
        <div>
          <Workouts />
        </div>
      </WorkoutProvider>
    );
}

export default WorkoutEntry;