import React, { useEffect, useState } from "react";
import { RenderWorkouts } from ".";
import WorkoutContentPage from "./WorkoutContentPage";
import GarminStatsCard from "@/features/Garmin/GarminStatsCard";
import { cn } from "@/lib/utils";
import Bro from "../../../assets/bro.svg";
import { resyncGarminWorkout } from "@/ApiServices/Workouts/Api";

const ShowEnduranceWorkouts = ({
  workouts,
  finishedData,
  isImport,
  date,
  userUUID,
  isCalendar,
  deleteIndividualWorkout,
  init,
  setLoading,
  setData,
  updateWorkout,
  thresholdValues,
  compareSearchWorkout,
  setCompareSearchWorkout,
  setWorkoutFromCalenderForCompare,
}) => {
  console.log("ShowEnduranceWorkouts data:", { workouts, finishedData });
  return (
    <div
      className={cn(
        "w-full grid ",
        workouts.length > 0 ? "grid-cols-2 gap-5 mb-10" : "grid-cols-1 mb-10"
      )}
    >
      {/* original workouts container */}
      <div>
        {workouts?.map((workout, index) => {
          let garminConnected = workout?.garminWorkout?.[0]?.scheduleId;
          return (
            <div>
              <WorkoutContentPage
                rounds={workout?.workoutAssigned?.workoutContent?.rounds}
                data={workout?.workoutAssigned}
                deleteIndividualWorkout={deleteIndividualWorkout}
                workoutID={workout?.id}
                key={"assigned"}
                showBackButton={false}
                isImport={isImport}
                date={date}
                userUUID={userUUID}
                isCalendar={isCalendar}
                isEndurance={true}
                isGarminConnected={garminConnected}
                onGarminConnect={async () => {
                  let res = await resyncGarminWorkout({
                    workout: workout?.workoutAssigned,
                    dateAssigned: date,
                    userUUID: userUUID,
                    atheleteID: userUUID,
                    workoutTrackerId: workout?.id,
                  });

                  if (res?.result) {
                    let updatedWorkout = { ...workout };
                    updatedWorkout.garminWorkout = [
                      { scheduleId: res?.result },
                    ];
                    updateWorkout(updatedWorkout);
                  }
                }}
                onSuccess={updateWorkout}
                thresholdValues={thresholdValues}
              />
            </div>
          );
        })}
      </div>
      {!finishedData && !finishedData?.[0] && workouts?.length > 0 && (
        <div
          className="flex flex-col w-full h-full bg-white-pure rounded-xl text-center shadow-xl"
          style={{
            border: "1px solid #e2e8f0",
          }}
        >
          <div className="flex justify-end items-center h-32 w-full"></div>
          <div className="flex justify-center w-full items-center h-full bg-white-pure  rounded text-center">
            <img src={Bro} alt="not completed image" />
            {/* <p className="text-red-light">Not Completed!</p> */}
          </div>
        </div>
      )}
      {/* finished workouts container */}
      {finishedData && finishedData?.[0] && (
        <div className="space-y-4 text-black">
          {finishedData?.map((workout, index) => {
            return (
              <GarminStatsCard
                key={index}
                data={workout}
                index={index}
                compareSearchWorkout={compareSearchWorkout}
                setCompareSearchWorkout={setCompareSearchWorkout}
                setWorkoutFromCalenderForCompare={
                  setWorkoutFromCalenderForCompare
                }
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ShowEnduranceWorkouts;
