import { Dialog, DialogActions, DialogContent } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ProgramSaveFailAlert = ({ isOpen, setIsOpen }) => {
  const history = useHistory();
  return (
    <Dialog
      open={isOpen}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "20px",
        },
      }}
    >
      <div className="add-program-modal-head">
        <p className="add-program-modal-head-title">Oops!</p>
        {/* <div
          onClick={() => {
            setIsOpen(false);
          }}
          className="cursor-pointer"
        >
          <CloseRoundedIcon />
        </div> */}
      </div>
      <DialogContent>
        <div className="text-center">
          <p className="font-DMSans text-font16 font-medium">
            Something went wrong. Please try again.
          </p>
        </div>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <button
          className="button-new"
          onClick={() => {
            setIsOpen(false);
            history.push("/home");
          }}
        >
          Go to home
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default ProgramSaveFailAlert;
