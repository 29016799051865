import React, { useState } from "react";
import { useChatContext } from "stream-chat-react";
import Fuse from "fuse.js";
import "./CreateChannel.css";

import { UserList } from "./UserList";

import { CloseCreateChannel } from "../../assets";
import SearchBar from "../../../../components/Dashboard/SearchBar";
import { v4 as uuidv4 } from "uuid";

const ChannelNameInput = (props) => {
  const { channelName = "", setChannelName, error, cancel, request } = props;

  const handleChange = (event) => {
    event.preventDefault();
    setChannelName(event.target.value);
  };

  return (
    <div className="channel-name-input__wrapper">
      <p>Name</p>
      <input
        className="channel-name-input__input"
        onChange={handleChange}
        placeholder="channel-name (no spaces)"
        type="text"
        value={channelName}
      />
      <span className="create_channel_error_message">{error}</span>
      <div className="flex items-end justify-between w-full py-2">
        <p>Add Members</p>
        <SearchBar
          placeholder={"Search for clients"}
          request={request}
          cancel={cancel}
          bgcol={"#F2F2F2"}
          style={{
            height: "40px",
            width: "200px",
          }}
        />
      </div>
    </div>
  );
};

export const CreateChannel = (props) => {
  const { createType, filters, setIsCreating } = props;

  const { client, setActiveChannel } = useChatContext();

  const [channelName, setChannelName] = useState("");
  const [error, setError] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([client.userID || ""]);
  const [users, setUsers] = useState([]);
  const [fullUsersLists, setFullUsersLists] = useState([]);

  const createChannel = async (event) => {
    if (channelName.length === 0) return;
    // if (selectedUsers.length > 1 ) {
    setError("");
    event.preventDefault();
    let channelID = channelName + "_" + uuidv4();
    try {
      const newChannel = await client.channel(createType, channelID, {
        name: channelName,
        members: selectedUsers,
        demo: "team",
      });

      await newChannel.watch();

      setChannelName("");
      setIsCreating(false);
      setSelectedUsers([client.userID]);
      setActiveChannel(newChannel);
    } catch (err) {
      setError("No whitespaces are allowed");
      console.log(err);
    }
    // }
  };

  const request = async (searchedVal) => {
    const fuseOptions = {
      keys: ["name", "userName"], // Properties to search within
      threshold: 0.1, // Adjust the threshold for fuzzy matching
    };

    if (searchedVal !== "") {
      const fuse = new Fuse(fullUsersLists, fuseOptions);
      const searchResults = fuse.search(searchedVal);

      const filteredRows = searchResults.map((result) => result.item);

      setUsers(filteredRows);

      console.log("teamChannels ", fullUsersLists);
    } else {
      setUsers(fullUsersLists);
    }
  };

  const cancel = () => {
    setUsers(fullUsersLists);
  };

  return (
    <div className="create-channel__container">
      <div className="create-channel__header">
        <p>
          {createType === "team"
            ? "Create a New Channel"
            : "Send a Direct Message"}
        </p>
        <CloseCreateChannel {...{ setIsCreating }} />
      </div>
      {createType === "team" && (
        <ChannelNameInput
          {...{
            channelName,
            setChannelName,
            error,
            cancel,
            request,
          }}
        />
      )}
      <UserList
        {...{
          filters,
          setSelectedUsers,
          createType,
          selectedUsers,
          users,
          setFullUsersLists,
          setUsers,
        }}
      />
      <div
        className={
          channelName.length === 0
            ? "create-channel__button-wrapper disabled"
            : "create-channel__button-wrapper"
        }
        onClick={createChannel}
      >
        <p>
          {createType === "team" ? "Create Channel" : "Create Message Group"}
        </p>
      </div>
    </div>
  );
};
