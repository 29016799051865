import React from 'react'

const SmallCloseIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
<path d="M16 8.5L8 16.5" stroke="#424242" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8 8.5L16 16.5" stroke="#424242" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  )
}

export default SmallCloseIcon