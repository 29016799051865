import React from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";

const PaginationForAutoMsg = ({
  currentDaysMessages,
  daysMessageIndex,
  setDaysMessageIndex,
}) => {
  return currentDaysMessages?.length > 0 ? (
    <div className="flex items-center gap-3">
      {/* previous */}
      <div
        className={`rounded-full ${
          daysMessageIndex === 0
            ? "pointer-events-none"
            : "cursor-pointer hover:bg-gray-200"
        } p-1`}
        onClick={() => {
          setDaysMessageIndex(daysMessageIndex - 1);
        }}
      >
        <ChevronLeft size={18} />
      </div>

      {currentDaysMessages.map((msg, index) => {
        return (
          <div
            key={index}
            onClick={() => setDaysMessageIndex(index)}
            className={`font-DMSans cursor-pointer w-[30px] h-[30px] flex items-center justify-center hover:bg-gray-200 p-2 rounded-full ${
              index === daysMessageIndex ? "bg-gray-200" : ""
            }`}
          >
            {index + 1}
          </div>
        );
      })}
      {/* next */}
      <div
        className={`rounded-full ${
          daysMessageIndex === currentDaysMessages.length - 1
            ? "pointer-events-none"
            : "cursor-pointer hover:bg-gray-200 p-1"
        } `}
        onClick={() => {
          setDaysMessageIndex(daysMessageIndex + 1);
        }}
      >
        <ChevronRight size={18} />
      </div>
    </div>
  ) : (
    <></>
  );
};

export default PaginationForAutoMsg;
