import { CloseRounded } from "@mui/icons-material";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import React, { useContext, useState } from "react";
import Input from "../InputComponents/Input";
import TextArea from "../InputComponents/TextArea";
import { copyTemplateAsMealPlan } from "../../ApiServices/Meals/Api";
import { useSelector } from "react-redux";
import { SpurfitCircularProgress } from "../Dashboard/dashboard";
import { NutritionContext } from "./NutritionContext";

const DuplicateMealPlan = ({
  isOpen,
  setIsOpen,
  meal,
  onSuccess,
  copyData,
}) => {
  const [name, setName] = useState(meal?.reference_name + " (copy)");
  const [description, setDescription] = useState(meal?.description);
  const coachID = useSelector((state) => state.auth?.trainerUUID);
  const [loading, setLoading] = useState(false);
  const {setOpenFolderId, setFolderSelected}=useContext(NutritionContext);

  const onSave = async () => {
    let data = {
      ...meal,
      id: undefined,
      reference_name: name,
      description,
      isTemplate: copyData?.isTemplate ? true : false,
     mealPlanFolderId:null
    };

    // remove nulls from updated item
    Object.keys(data).forEach((key) => data[key] == null && delete data[key]);
    setLoading(true);
    let res = await copyTemplateAsMealPlan(data, coachID);
    onSuccess(res?.id);
    if (meal?.mealPlanFolderId) {
      setFolderSelected({});
      setOpenFolderId("");
    }
    setLoading(false);
    setIsOpen(false);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "20px",
        },
      }}
    >
      {loading && (
        <div className="absolute flex items-center justify-center w-full h-full">
          <SpurfitCircularProgress />
        </div>
      )}
      <DialogContent sx={{ padding: 0 }}>
        <div className="add-program-modal-head">
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <p className="add-program-modal-head-title">
              {copyData?.isTemplate
                ? "Copy template"
                : copyData?.isMealPlan
                ? "Copy Meal Plan"
                : "Copy as Meal Plan"}
            </p>
          </div>
          <div onClick={() => setIsOpen(false)}>
            <CloseRounded sx={{ cursor: "pointer" }} />
          </div>
        </div>

        <div className="add-program-modal-body">
          <Input
            label={"Meal Name"}
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="text"
          />

          <TextArea
            label={"Description"}
            value={description}
            placeholder="Enter description"
            onChange={(e) => setDescription(e.target.value)}
            numRows={3}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <button
          disabled={!name}
          className="button-new"
          onClick={() => {
            onSave();
          }}
        >
          Save
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default DuplicateMealPlan;
