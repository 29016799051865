import { AddRounded, InfoRounded } from "@mui/icons-material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { Box, MenuItem, Paper, Select } from "@mui/material";
import axios from "axios";
import { forwardRef, useEffect, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { getAllExercise } from "../../ApiServices/Exercise/Api";
import AddExerciseModal from "../../pages/Exercises/AddExerciseModal";
import { modifyExerciseObject } from "../../redux/dataSlice";
import { useGetSpurfitExercisesQuery } from "../../services/apiSlice";
import { SpurfitCircularProgress } from "../Dashboard/dashboard";
import VideoPlayerModel from "../VideoPlayer/VideoPlayer";
import SearchBar from "./SearchBar";
import FilterExerciseModal from "./FilterExerciseModal";
import FilterActiveIcon from "../../assets/workouts/ActiveFunnel.svg";
import FilterIcon from "../../assets/workoutBuilder/filterIcon.svg";

const SelectExercises = ({
  rounds,
  addExerciseToParticularRound,
  disabled,
}) => {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const [Exercises, setExercises] = useState([]);
  const [trainerExercise, setTrainerExercise] = useState([]);
  const [spurfitExercises, setSpurfitExercises] = useState([]);
  const [allExercises, setAllExercises] = useState([]);
  const [selectedLibraryType, setSelectedLibraryType] = useState("all");
  const [loading, setLoading] = useState(false);
  const [pageLimit, setPageLimit] = useState(10);
  const [showAddExercise, setShowAddExercise] = useState(false);
  const exercisesObj = useSelector((state) => state.data.exerciseObject);
  const [searchedVal, setSearchedVal] = useState("");
  const [showExerciseInfo, setShowExerciseInfo] = useState(false);
  const [selectedExercise, setSelectedExercise] = useState({});
  const [tempFilter, setTempFilter] = useState([]);
  const [displayFilterModal, setDisplayFilterModal] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([
    {
      name: "modality",
      label: "Modality",
      value: [],
    },
    {
      name: "subModality",
      label: "Sub Modality",
      value: [],
    },
    {
      name: "equipmentList",
      label: "Equipment List",
      value: [],
    },
    {
      name: "bodyPart",
      label: "Body Part",
      value: [],
    },
    {
      name: "subBodyPart",
      label: "Sub Body Part",
      value: [],
    },
    {
      name: "movementFunction",
      label: "Movement Function",
      value: [],
    },

    {
      name: "tags",
      label: "Tags",
      value: [],
    },
  ]);

  const [visibleExercises, setVisibleExercises] = useState([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  useEffect(() => {
    const initialExercises = Exercises.slice(0, pageLimit);
    setVisibleExercises(initialExercises);
  }, [Exercises]);

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver((entries) => {
      console.log("isIntersecting", entries);
      if (
        entries.some(
          (entry) =>
            entry.isIntersecting && Exercises.length > visibleExercises.length
        )
      ) {
        setIsLoadingMore(true);

        setVisibleExercises((prevExercises) => [
          ...prevExercises,
          ...Exercises.slice(
            prevExercises?.length,
            prevExercises?.length + pageLimit
          ),
        ]);
        setIsLoadingMore(false);
      }
    });

    const target = document.querySelector("#exerciseListEnd");
    if (target) {
      intersectionObserver.observe(target);
    }

    return () => {
      if (target) intersectionObserver.disconnect();
    };
  }, [visibleExercises]);

  // create array of numRounds
  const roundsArr = Array.from({ length: rounds }, (_, index) => index + 1);

  const coachID = useSelector((state) => state.auth.trainerUUID);
  const adminID = useSelector((state) => state.auth?.data?.adminID);

  const { data } = useGetSpurfitExercisesQuery({ coachID, APIURL });

  const getTrainerExercises = async () => {
    let allExercises = [];

    allExercises = await getAllExercise(coachID, adminID);

    setTrainerExercise(allExercises);
    return allExercises;
  };

  useEffect(() => {
    if (tempFilter.length > 0) {
      let newData = [];
      if (selectedFilters.length > 0) {
        let allTheExercises = [];
        if (selectedLibraryType === "all") {
          allTheExercises = [...allExercises];
        } else if (selectedLibraryType === "trainer") {
          allTheExercises = [...trainerExercise];
        } else if (selectedLibraryType === "spurfit") {
          allTheExercises = [...spurfitExercises];
        }

        allTheExercises.map((listData) => {
          selectedFilters.map((filter) => {
            if (filter?.name === "tags" && filter?.value?.length > 0) {
              filter?.value?.map((data) => {
                if (listData?.tags === data) {
                  newData.push(listData);
                }
              });
            }
            Object.values(
              listData[filter.name] != null ? listData[filter.name] : {}
            ).map((data) => {
              if (filter.value.includes(data)) newData.push(listData);
            });
          });
        });
        setExercises(newData);
      }
    } else {
      if (selectedLibraryType === "all") {
        setExercises(allExercises);
      } else if (selectedLibraryType === "trainer") {
        setExercises(trainerExercise);
      } else if (selectedLibraryType === "spurfit") {
        setExercises(spurfitExercises);
      }
    }
  }, [selectedFilters]);

  const getExercises = async () => {
    try {
      if (data?.[0]) {
        setSpurfitExercises(data);
        return data ?? [];
      } else {
        const res = await axios({
          method: "GET",
          url: `${APIURL}CF/api/CoachWeb/Get/getGlobalMovementLibrary?coachID=${coachID}`,
        });
        setSpurfitExercises(res?.data?.result || []);
        return res?.data?.result || [];
      }
    } catch (err) {
      console.log("error getting exercise: ", err);
    }
  };

  let options = roundsArr.map((round) => {
    return "Section " + round;
  });

  const dispatch = useDispatch();
  const getAllExercises = async () => {
    setLoading(true);

    let trainer = await getTrainerExercises();
    let spurfit = await getExercises();

    let all = [...trainer, ...spurfit];
    setExercises(all);
    setAllExercises(all);

    let exerciseObjectWithIdAsKey = {};
    all.forEach((exercise) => {
      exerciseObjectWithIdAsKey[exercise.id] = exercise;
    });
    dispatch(modifyExerciseObject(exerciseObjectWithIdAsKey));
    if (trainer.length > 0 || spurfit.length > 0) {
      let timeout = setTimeout(() => {
        setLoading(false);
        clearTimeout(timeout);
      }, 1000);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllExercises();
  }, []);

  const requestSearch = (searchedVal) => {
    setSearchedVal(searchedVal);
    if (searchedVal === "") {
      cancelSearch();
      return;
    }
    setPageLimit(25);
    if (selectedLibraryType === "all") {
      const filteredRows = allExercises.filter((row) => {
        return row.name.toLowerCase().includes(searchedVal.toLowerCase());
      });
      setExercises(filteredRows);
    } else if (selectedLibraryType === "trainer") {
      const filteredRows = trainerExercise.filter((row) => {
        return row.name.toLowerCase().includes(searchedVal.toLowerCase());
      });
      setExercises(filteredRows);
    } else if (selectedLibraryType === "spurfit") {
      const filteredRows = spurfitExercises.filter((row) => {
        return row.name.toLowerCase().includes(searchedVal.toLowerCase());
      });
      setExercises(filteredRows);
    }
  };

  const cancelSearch = () => {
    setSearchedVal("");
    if (selectedLibraryType === "all") {
      setExercises(allExercises);
    } else if (selectedLibraryType === "trainer") {
      setExercises(trainerExercise);
    } else if (selectedLibraryType === "spurfit") {
      setExercises(spurfitExercises);
    }
    setPageLimit(10);
  };

  const onLibraryTypeChange = (e) => {
    setSelectedLibraryType(e.target.value);
    if (e.target.value === "all") {
      setExercises(allExercises);
    } else if (e.target.value === "trainer") {
      setExercises(trainerExercise);
    } else if (e.target.value === "spurfit") {
      setExercises(spurfitExercises);
    }
  };

  const rowRenderer = ({ index, key, style }) => {
    const exercise = visibleExercises[index];

    return (
      <Draggable
        key={exercise?.id + " -> " + index}
        draggableId={exercise?.id}
        index={index}
        isDragDisabled={disabled}
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                borderRadius: "12px",
                overflow: "hidden",
                alignItems: "center",
                gap: "8px",
                justifyContent: "space-between",
                background: "#fff",
                boxShadow: snapshot?.isDragging
                  ? "0px 12px 32px 0px rgba(0, 0, 0, 0.20)"
                  : "none",
              }}
            >
              <img
                src={exercise?.imageLink}
                style={{
                  width: 40,
                  minHeight: 40,
                  borderRadius: "12px 0px 0px 12px",
                }}
              />
              <div
                style={{
                  padding: "8px 0 8px 8px",
                  flex: 1,
                }}
              >
                <div className="exercise-title text-left">{exercise?.name}</div>
              </div>
              <InfoRounded
                fontSize="small"
                style={{ color: "#424242" }}
                onClick={() => {
                  setSelectedExercise(exercise);
                  setShowExerciseInfo(true);
                }}
              />
              <div
                style={{
                  borderRadius: "0px 12px 12px 0px",
                  padding: "8px 8px",
                  backgroundColor: "rgba(67, 57, 242, 0.10)",
                }}
              >
                <DragIndicatorIcon
                  fontSize="medium"
                  sx={{
                    color: "#424242",
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </Draggable>
    );
  };

  return (
    <Paper
      sx={{
        height: "calc(100vh - 110px)",
        paddingBottom: "10px",
        overflow: "hidden",
        borderRadius: "12px",
        width: "calc((100vw - 110px)/100 * 21)",
        position: "fixed",
      }}
    >
      {loading && (
        <div
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 100,
            backgroundColor: "rgba(0,0,0,0.5)",
          }}
        >
          <SpurfitCircularProgress />
        </div>
      )}
      <Box
        style={{
          height: "56px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          paddingLeft: "24px",
          borderBottom: "1px solid #E5E5E5",
        }}
      >
        <Select
          SelectDisplayProps={{
            style: {
              color: "#242424",
              fontFeatureSettings: '"clig" off, "liga" off',
              fontFamily: "DM Sans",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "22px",
            },
          }}
          variant="standard"
          disableUnderline
          IconComponent={ExpandMoreRoundedIcon}
          value={selectedLibraryType}
          onChange={onLibraryTypeChange}
        >
          <MenuItem value="all">All Libraries</MenuItem>
          <MenuItem value="trainer">My Library</MenuItem>
          <MenuItem value="spurfit">Spurfit Library</MenuItem>
        </Select>

        <button
          style={{
            borderWidth: 2,
            borderRadius: 100,
            width: "24px",
            height: "24px",
          }}
          className="button-new-outlined ml-auto mr-3 my-auto"
        >
          <AddRounded
            style={{
              color: "#8330e9",
            }}
            fontSize="16"
            onClick={() => setShowAddExercise(true)}
          />
        </button>
      </Box>
      <Box
        sx={{
          padding: "0 12px",
          marginTop: "10px",
          display: "flex",
          alignItems: "center",
          gap: "8px",
        }}
      >
        <SearchBar
          request={requestSearch}
          cancel={cancelSearch}
          placeholder={"Search Exercises"}
          bgcol={"#F2F2F2"}
          height={40}
          style={{
            width: "100%",
            marginLeft: "4px",
          }}
        />
        <div
          className=" flex gap-1 items-center"
          onClick={() => {
            // handleClear()
            setDisplayFilterModal(true);
          }}
        >
          <img
            style={{
              height: "40px",
              width: "40px",

              cursor: "pointer",
            }}
            // onClick={() => setShowFilterDialog(true)}
            src={tempFilter.length > 0 ? FilterActiveIcon : FilterIcon}
            alt="Filter Icon"
          />
        </div>

        <FilterExerciseModal
          openModal={displayFilterModal}
          setOpenModal={setDisplayFilterModal}
          setSelectedFilters={setSelectedFilters}
          selectedFilters={selectedFilters}
          tempFilter={tempFilter}
          setTempFilter={setTempFilter}
        />

        <VideoPlayerModel
          videoInfo={selectedExercise}
          setOpenDialog={setShowExerciseInfo}
          openDialog={showExerciseInfo}
          setSelectedItem={() => {}}
        />

        {showAddExercise && (
          <AddExerciseModal
            open={showAddExercise}
            setOpen={setShowAddExercise}
            libraryType={"1"}
            handleShowAlert={(error, type) => {
              console.log(type, error);
            }}
            updateExerciseToParticularRound={async (exercise) => {
              // set trainer exercise

              await getAllExercises();
              if (
                selectedLibraryType === "trainer" ||
                selectedLibraryType === "all"
              ) {
                setExercises([exercise, ...Exercises]);
              }
            }}
          />
        )}

        {/* <img
          src={FilterIcon}
          style={{
            height: 38,
            width: 38,
            cursor: "pointer",
          }}
        /> */}
      </Box>

      <Droppable droppableId="droppable-1">
        {(provided) => (
          <Box
            {...provided.droppableProps}
            ref={provided.innerRef}
            sx={{
              px: "12px",
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              mt: "12px",
              height: "calc(100vh - 250px)",
              overflowY: "scroll",
            }}
          >
            {visibleExercises.length > 0 ? (
              visibleExercises.map((exercise, index) =>
                rowRenderer({ index, key: index, style: {} })
              )
            ) : !loading ? (
              <div className="h-full w-full flex flex-col items-center justify-center -mt-5">
                <p className="font-DMSans text-font12 mb-2 text-center">
                  Nothing found in this library. Add exercise to your library.
                </p>
                <button
                  style={{
                    borderWidth: 2,
                    borderRadius: 100,
                    width: "148px",
                    height: "44px",
                    alignSelf: "center",
                  }}
                  className="button-new-outlined"
                  onClick={() => {
                    setShowAddExercise(true);
                  }}
                >
                  Add Exercise
                </button>
              </div>
            ) : (
              <></>
            )}
            <div id="exerciseListEnd" className="h-[20px] w-32 mb-[20px]" />{" "}
            {/* Target for IntersectionObserver */}
            {isLoadingMore && (
              <div className="flex justify-center mt-auto">
                <SpurfitCircularProgress />{" "}
                {/* Or your preferred loading indicator */}
              </div>
            )}
          </Box>
        )}
      </Droppable>
    </Paper>
  );
};

export default SelectExercises;

const innerElementType = forwardRef(({ style, ...rest }, ref) => (
  <div
    ref={ref}
    style={{
      ...style,
      height: `${parseFloat(style.height)}px`,
    }}
    {...rest}
  />
));
