import { existingBlocks } from "../UnitBlocks";

let data = {
  name: "Running Workout",
  description: "This is a running workout",
  totalDuration: 3844,
  totalDistance: 10,
  selectedIntensityType: "precentThresholdHeartRateRangeInBpm",
  type: "run",
  workout: [
    {
      data: [
        {
          blockIdx: 2,
          exerciseIdx: 0,
          width: 200,
          height: 200,
          exercise: {
            name: "Active",
            type: "work",
            distance: 2,
            distanceUnit: "Kilometers",
            durationInSeconds: 3844,
            precentThresholdPaceRangeInMinPerKm: {
              min: 80,
              max: 100,
              heartRateZone: 2,
            },
            precentThresholdHeartRateRangeInBpm: {
              min: 50,
              max: 85,
              heartRateZone: "5C",
            },
            precentMaximumHeartRateRangeInBpm: {
              min: 89,
              max: 98,
              heartRateZone: "5C",
            },
            percentFTPRangeInW: {
              min: 105,
              max: 115,
              powerZone: 5,
            },
          },
        },
        {
          blockIdx: 2,
          exerciseIdx: 1,
          width: 100,
          height: 300,
          exercise: {
            name: "Active",
            type: "work",
            distance: 1,
            distanceUnit: "Kilometers",
            durationInSeconds: 3844,
            precentThresholdPaceRangeInMinPerKm: {
              min: 40,
              max: 60,
              heartRateZone: 2,
            },
            precentThresholdHeartRateRangeInBpm: {
              min: 105,
              max: 95,
              heartRateZone: "5C",
            },
            precentMaximumHeartRateRangeInBpm: {
              min: 89,
              max: 98,
              heartRateZone: "5C",
            },
            percentFTPRangeInW: {
              min: 105,
              max: 115,
              powerZone: 5,
            },
          },
        },
      ],
      repeat: 2,
    },
    {
      data: [
        {
          blockIdx: 3,
          exerciseIdx: 0,
          width: 200,
          height: 200,
          exercise: {
            name: "Active",
            type: "work",
            distance: 2,
            distanceUnit: "Kilometers",
            durationInSeconds: 3844,
            precentThresholdPaceRangeInMinPerKm: {
              min: 80,
              max: 100,
              heartRateZone: 2,
            },
            precentThresholdHeartRateRangeInBpm: {
              min: 50,
              max: 85,
              heartRateZone: "5C",
            },
            precentMaximumHeartRateRangeInBpm: {
              min: 89,
              max: 98,
              heartRateZone: "5C",
            },
            percentFTPRangeInW: {
              min: 105,
              max: 115,
              powerZone: 5,
            },
          },
        },
        {
          blockIdx: 3,
          exerciseIdx: 1,
          width: 100,
          height: 300,
          exercise: {
            name: "Active",
            type: "work",
            distance: 1,
            distanceUnit: "Kilometers",
            durationInSeconds: 3844,
            precentThresholdPaceRangeInMinPerKm: {
              min: 40,
              max: 60,
              heartRateZone: 2,
            },
            precentThresholdHeartRateRangeInBpm: {
              min: 105,
              max: 95,
              heartRateZone: "5C",
            },
            precentMaximumHeartRateRangeInBpm: {
              min: 89,
              max: 98,
              heartRateZone: "5C",
            },
            percentFTPRangeInW: {
              min: 105,
              max: 115,
              powerZone: 5,
            },
          },
        },
      ],
      repeat: 2,
    },
  ],
};

// spurfit structure
// {
//     "id": "vQZaQxIJFrUYqPpQINAxPxpoR",
//     "name": "Zone 2 base building",
//     "numRounds": 1,
//     "runTime": 3000,
//     "restTime": 0,
//     "visibility": false,
//     "benchMark": false,
//     "expectedCalories": 0,
//     "equipment": null,
//     "bodyPart": null,
//     "isStructured": true,
//     "description": null,
//     "instructions": null,
//     "coachID": "ErzsyqqduVWs",
//     "workoutType": "NOSCORE",
//     "workoutKind": "run",
//     "tag": "Easy,Zone2",
//     "dateCreated": "2024-03-12T00:00:00.000Z",
//     "workoutContent": {
//         "rounds": [
//             {
//                 "round": {
//                     "type": "Warm up",
//                     "numRounds": 1,
//                     "measurements": [
//                         {
//                             "measurement": {
//                                 "type": "substep",
//                                 "rounds": 1,
//                                 "duration": {
//                                     "1": 600
//                                 },
//                                 "durationF": {
//                                     "1": ""
//                                 },
//                                 "heartRate": {
//                                     "1": "130"
//                                 },
//                                 "durationMax": {},
//                                 "heightUnits": "Inches",
//                                 "weightUnits": "Pounds",
//                                 "movementName": "Warm Up",
//                                 "substepIndex": 0,
//                                 "distanceUnits": "Meters",
//                                 "heartRateType": "below",
//                                 "movementInstr": "",
//                                 "recordFromUser": {
//                                     "reps": false,
//                                     "height": false,
//                                     "weight": false,
//                                     "calorie": false,
//                                     "distance": false,
//                                     "duration": false,
//                                     "heartRate": false
//                                 }
//                             }
//                         }
//                     ]
//                 }
//             },
//             {
//                 "round": {
//                     "type": "Actual Run",
//                     "numRounds": 1,
//                     "measurements": [
//                         {
//                             "measurement": {
//                                 "type": "substep",
//                                 "rounds": 1,
//                                 "duration": {
//                                     "1": 2400
//                                 },
//                                 "durationF": {
//                                     "1": ""
//                                 },
//                                 "heartRate": {
//                                     "1": "155"
//                                 },
//                                 "durationMax": {},
//                                 "heightUnits": "Inches",
//                                 "weightUnits": "Pounds",
//                                 "movementName": "Run",
//                                 "substepIndex": 0,
//                                 "distanceUnits": "Meters",
//                                 "heartRateType": "below",
//                                 "movementInstr": "",
//                                 "recordFromUser": {
//                                     "reps": false,
//                                     "height": false,
//                                     "weight": false,
//                                     "calorie": false,
//                                     "distance": false,
//                                     "duration": false,
//                                     "heartRate": false
//                                 }
//                             }
//                         }
//                     ]
//                 }
//             },
//             {
//                 "round": {
//                     "type": "Cool Down",
//                     "numRounds": 1,
//                     "measurements": [
//                         {
//                             "measurement": {
//                                 "type": "substep",
//                                 "rounds": 1,
//                                 "distance": {
//                                     "1": 300
//                                 },
//                                 "durationF": {
//                                     "1": ""
//                                 },
//                                 "heartRate": {
//                                     "1": "120"
//                                 },
//                                 "durationMax": {
//                                     "1": ""
//                                 },
//                                 "heightUnits": "Inches",
//                                 "weightUnits": "Pounds",
//                                 "movementName": "Cooldown",
//                                 "substepIndex": 0,
//                                 "distanceUnits": "Meters",
//                                 "heartRateType": "above",
//                                 "movementInstr": "",
//                                 "recordFromUser": {
//                                     "reps": false,
//                                     "height": false,
//                                     "weight": false,
//                                     "calorie": false,
//                                     "distance": false,
//                                     "duration": false,
//                                     "heartRate": false
//                                 }
//                             }
//                         }
//                     ]
//                 }
//             }
//         ]
//     },
//     "videoLinks": null,
//     "isCoachWorkout": true
// }

const MOVEMENTCATEGORY = {
  ACTIVE: "ACTIVE",
  RUN: "ACTIVE",
  SWIM: "ACTIVE",
  BIKE: "ACTIVE",
  REST: "REST",
  RECOVERY: "RECOVERY",
  WARMUP: "WARMUP",
  RECOVER: "RECOVER",
  COOLDOWN: "COOLDOWN",
  INTERVAL: "INTERVAL",
  "WARM UP": "WARMUP",
  "COOL DOWN": "COOLDOWN",
};

export const convertToSpurfitStructure = (data, coachID) => {
  let spurfitData = {
    coachID: coachID,
    name: data.name,
    description: data.description,
    numRounds: 1,
    runTime: data.totalDuration,
    workoutType: "NOSCORE",
    workoutKind: data.type,
    dateCreated: new Date().toISOString(),
    workoutContent: {
      rounds: [],
    },
    isCoachWorkout: true,
  };

  data.workout.forEach((workout) => {
    let round = {
      round: {
        numRounds: workout.repeat,
        measurements: [],
      },
    };

    workout.data.forEach((exercise) => {
      let movementName = exercise.exercise.name;
      let nameToConcatinate = [
        "Warm Up",
        "Cool Down",
        "Run",
        "Bike",
        "Swim",
      ]?.includes(exercise.exercise.name)
        ? ""
        : exercise.exercise.name;

      // if (data.type === "run") {
      //   movementName = !["Warm Up", "Cool Down"]?.includes(
      //     exercise.exercise.name
      //   )
      //     ? "Run"
      //     : exercise.exercise.name;
      // } else if (data.type === "bike") {
      //   movementName = !["Warm Up", "Cool Down"]?.includes(
      //     exercise.exercise.name
      //   )
      //     ? "Bike"
      //     : exercise.exercise.name;
      // } else {
      //   movementName = !["Warm Up", "Cool Down"]?.includes(
      //     exercise.exercise.name
      //   )
      //     ? "Swim"
      //     : exercise.exercise.name;
      // }

      let movementInstr = exercise.exercise.movementInstr;
      if (movementInstr === undefined) {
        movementInstr = nameToConcatinate;
      } else {
        movementInstr = nameToConcatinate + " " + movementInstr;
      }
      let lapButtonPressed = exercise.exercise.lapButtonPress;

      let specifiedProperty = exercise.exercise.specifiedProperty;
      let property = {};

      if (!lapButtonPressed) {
        if (specifiedProperty === "distance") {
          property.distance = { 1: exercise.exercise.distance };
          property.distanceUnits = exercise.exercise.distanceUnit;
        } else {
          property.duration = { 1: exercise.exercise.durationInSeconds };
        }
      }

      let movementCategory =
        MOVEMENTCATEGORY[(exercise?.exercise?.type ?? "").toUpperCase()] ??
        "ACTIVE";
      let measurement = {
        measurement: {
          type: "substep",
          rounds: workout.repeat,
          heightUnits: "Inches",
          weightUnits: "Pounds",
          movementName: movementName,
          substepIndex: 0,
          distanceUnits: exercise.exercise.distanceUnit,
          movementInstr: movementInstr,
          blockIndex: exercise.blockIdx,
          exerciseIndex: exercise.exerciseIdx,
          category: movementCategory,
          recordFromUser: {
            reps: false,
            height: false,
            weight: false,
            calorie: false,
            distance: false,
            duration: false,
            heartRate: false,
          },
          intensity: getIntensity(
            exercise.exercise,
            data.selectedIntensityType
          ),
          secondaryTarget: exercise?.exercise?.secondaryTarget,
          lapButtonPressed,
          ...property,
        },
      };

      round.round.measurements.push(measurement);
    });

    spurfitData.workoutContent.rounds.push(round);
  });

  return spurfitData;
};

export const getIntensity = (exercise, selectedIntensityType) => {
  switch (selectedIntensityType) {
    case "precentThresholdPaceRangeInMinPerKm":
      return {
        type: "thresholdPace",
        value: exercise.precentThresholdPaceRangeInMinPerKm.min,
        maxValue: exercise.precentThresholdPaceRangeInMinPerKm.max,
      };
    case "precentThresholdHeartRateRangeInBpm":
      return {
        type: "thresholdCustomHeartRate",
        value: exercise.precentThresholdHeartRateRangeInBpm.min,
        maxValue: exercise.precentThresholdHeartRateRangeInBpm.max,
      };
    case "precentMaximumHeartRateRangeInBpm":
      return {
        type: "maxCustomHeartRate",
        value: exercise.precentMaximumHeartRateRangeInBpm.min,
        maxValue: exercise.precentMaximumHeartRateRangeInBpm.max,
      };
    case "percentFTPRangeInW":
      return {
        type: "FTP",
        value: exercise.percentFTPRangeInW.min,
        maxValue: exercise.percentFTPRangeInW.max,
      };
  }
};
const convertDistanceToKilometers = (distance, unit) => {
  switch (unit) {
    case "Meters":
      return distance / 1000;
    case "Miles":
      return distance * 1.60934;
    case "Kilometers":
    default:
      return distance;
  }
};

export const convertSpurfitStructureToData = (data) => {
  let selectedIntensityType = getSelectedIntensityTypeFromIntensity(
    data.workoutContent?.rounds?.[0]?.round?.measurements?.[0]?.measurement
      ?.intensity
  );
  let workout = {
    name: data.name,
    description: data.description,
    totalDuration: data.runTime,
    totalDistance: 0,
    selectedIntensityType,
    type: data.workoutKind,
    workout: [],
  };

  data.workoutContent.rounds.forEach((round) => {
    let workoutRound = {
      data: [],
      repeat: round.round.numRounds,
    };

    round.round.measurements.forEach((m) => {
      let measurement = m.measurement;
      // get the exercise from the existing blocks using blockIdx and exerciseIdx
      let matchingExercise = findExercise(
        measurement.blockIndex,
        measurement.exerciseIndex
      );
      let width = matchingExercise.width;
      let distance = matchingExercise.exercise.distance;
      let duration = matchingExercise.exercise.durationInSeconds;
      let specifiedProperty = "duration";
      if (matchingExercise) {
        if (measurement?.distance?.[1]) {
          let distanceInKm = convertDistanceToKilometers(
            measurement.distance[1],
            measurement.distanceUnits
          );
          // if the distance is not equal then update the width according to the ratio of the distance
          width =
            (matchingExercise.width / matchingExercise.exercise.distance) *
            distanceInKm;
          // also update the duration according to the ratio of the distance
          duration =
            (matchingExercise.exercise.durationInSeconds /
              matchingExercise.exercise.distance) *
            distanceInKm;
          distance = measurement.distance[1];
          specifiedProperty = "distance";
        } else if (measurement?.duration?.[1]) {
          // if the duration is not equal then update the width according to the ratio of the duration
          width =
            (matchingExercise.width /
              matchingExercise.exercise.durationInSeconds) *
            measurement.duration[1];
          // also update the distance according to the ratio of the duration
          distance =
            (matchingExercise.exercise.distance /
              matchingExercise.exercise.durationInSeconds) *
            measurement.duration[1];
          duration = measurement.duration[1];
          specifiedProperty = "duration";
        }
      }

      let exercise = {
        blockIdx: measurement.blockIndex,
        exerciseIdx: measurement.exerciseIndex,
        ...(matchingExercise || {}),
        width,
        exercise: {
          ...(matchingExercise?.exercise || {}),
          name: measurement.movementName,
          type: "work",
          distance: distance || 0,
          distanceUnit: measurement.distanceUnits,
          durationInSeconds: duration || 0,
          [selectedIntensityType]: getSelectedIntensityFromIntensity(
            measurement?.intensity
          ),
          specifiedProperty,
        },
      };

      workoutRound.data.push(exercise);
    });

    workout.workout.push(workoutRound);
  });

  console.log(JSON.stringify(workout, null, 2));
  return workout;
};

const getSelectedIntensityTypeFromIntensity = (intensity) => {
  switch (intensity?.type) {
    case "thresholdPace":
      return "precentThresholdPaceRangeInMinPerKm";
    case "thresholdCustomHeartRate":
      return "precentThresholdHeartRateRangeInBpm";
    case "maxCustomHeartRate":
      return "precentMaximumHeartRateRangeInBpm";
    case "FTP":
      return "percentFTPRangeInW";
  }
};

const getSelectedIntensityFromIntensity = (intensity) => {
  console.log("getting intensity:", intensity);
  switch (intensity?.type) {
    case "thresholdPace":
      return {
        min: intensity.value,
        max: intensity.maxValue,
      };
    case "thresholdCustomHeartRate":
      return {
        min: intensity.value,
        max: intensity.maxValue,
      };
    case "maxCustomHeartRate":
      return {
        min: intensity.value,
        max: intensity.maxValue,
      };
    case "FTP":
      return {
        min: intensity.value,
        max: intensity.maxValue,
      };
  }
};

convertToSpurfitStructure(data);

function findExercise(blockIdx, exerciseIdx) {
  // Find the block matching the given blockIdx
  const block = existingBlocks.find(
    (block) =>
      block.data && block.data.some((item) => item.blockIdx === blockIdx)
  );

  // If the block is found, find the exercise within the block
  if (block) {
    const exercise = block.data.find(
      (item) => item.exerciseIdx === exerciseIdx
    );
    return exercise; // Return the exercise object or undefined if not found
  }

  // Return undefined if the block is not found
  return undefined;
}
