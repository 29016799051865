import React from "react";

const PlusRoundIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        stroke="url(#paint0_linear_391_17832)"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 8V16"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 8V16"
        stroke="url(#paint1_linear_391_17832)"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 12H16"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 12H16"
        stroke="url(#paint2_linear_391_17832)"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_391_17832"
          x1="2"
          y1="12"
          x2="22"
          y2="12"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.104167" stop-color="#464FEB" />
          <stop offset="1" stop-color="#8330E9" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_391_17832"
          x1="12"
          y1="12"
          x2="13"
          y2="12"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.104167" stop-color="#464FEB" />
          <stop offset="1" stop-color="#8330E9" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_391_17832"
          x1="8"
          y1="12.5"
          x2="16"
          y2="12.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.104167" stop-color="#464FEB" />
          <stop offset="1" stop-color="#8330E9" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default PlusRoundIcon;
