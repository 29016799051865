import { store } from "@/redux/store";
import axios from "axios";

export const duplicateWorkout = async (
  workout,
  title,
  description,
  getWorkouts,
  displayAlert,
  closeDialog,
  coachID
) => {
  const {
    name,
    workoutContent,
    workoutKind,
    dateCreated,
    ModuleDate,
    tag,
    workoutType,
    isCoachWorkout,
  } = workout;

  let d = {
    workoutInfo: {
      name: title,
      description: description,
      dateCreated: new Date().toISOString(),
      tag: tag ?? "",
      coachID: coachID,
      workoutType: workoutType,
      workoutKind: workoutKind,
      isCoachWorkout: isCoachWorkout,
      isStructured: workout?.isStructured === false ? false : true,
    },
    workoutContent: workoutContent,
  };

  await axios({
    method: "post",
    url: `${
      store.getState().auth.apiURL
    }CF/api/CoachWeb/Create/addWorkoutWithAllDetailsJSON`,
    data: { workout: d },
  })
    .then((res) => {
      displayAlert({
        type: "success",
        message: "Workout duplicated successfully",
      });
      getWorkouts();
    })
    .catch((err) => {
      displayAlert({
        type: "error",
        message: "Workout could not be duplicated",
      });
    })
    .finally(() => {
      closeDialog();
    });
};
