import { MenuItem, Select as MuiSelect, Dialog } from "@mui/material";
import axios from "axios";
import { useContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { CustomAlertContext } from "../../App";
import { SpurfitCircularProgress } from "../Dashboard/dashboard";
import "react-datepicker/dist/react-datepicker.css";
import { styled } from "@mui/material/styles";
import PlanStartForUser from "@/features/AddClients/PlanStartForUser";
import { getAllProgramsWithPhasesByCoachID } from "@/ApiServices/Programs/Api";
import moment from "moment";

const AthleteStatus = ({
  userUUID,
  onboardingStatus,
  changeUserInfo,
  showText = true,
  rowData,
  buddyRow,
}) => {
  let APIURL = useSelector((state) => state.auth.apiURL);
  const coachData = useSelector((state) => state.auth.data);
  const [status, setStatus] = useState(
    (onboardingStatus === "Questionaire filled"
      ? "Questionnaire filled"
      : onboardingStatus) || "Questionnaire filled"
  );
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedOption, setSelectedOption] = useState("All Phases");
  const [selectedPhase, setSelectedPhase] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [selectedDay, setSelectedDay] = useState({ value: 1, label: "Day 1" });

  const { displayAlert } = useContext(CustomAlertContext);
  const trainerUUID = useSelector((state) => state.auth.trainerUUID);

  const toggleModalState = () => {
    setOpenDialog(!openDialog);
  };

  const formatData = (updatedPrograms) => {
    let updatedData = {};
    let curProgStartDate = startDate;
    updatedPrograms.forEach((program, index) => {
      let startDay = selectedDay.value;
      let duration = program.duration;
      if (index == 0 && selectedOption !== "All Phase")
        duration = duration - (startDay - 1);

      updatedData[program.id] = {
        startProgDay:
          index == 0 && selectedOption !== "All Phase" ? startDay : 1,
        startDate:
          moment(curProgStartDate).format("YYYY-MM-DD") + "T00:00:00.000Z",
      };

      // Calculate the end date for the current program
      let curProgEndDate = moment(curProgStartDate)
        .add(duration - 1, "days")
        .toDate();

      // Set the next program's start date
      if (index < updatedPrograms.length - 1) {
        curProgStartDate = moment(curProgEndDate).add(1, "days").toDate();
      }
    });
    return updatedData;
  };

  const getAllPrograms = async (isBuddy = false) => {
    try {
      const res = await getAllProgramsWithPhasesByCoachID(trainerUUID);

      const programID = isBuddy
        ? buddyData?.phasedProgramID
        : rowData?.phasedProgramID;

      if (!programID) {
        return null;
      }

      const filteredPrograms = res?.filter((obj) => obj.id === programID) || [];
      return filteredPrograms[0] || null;
    } catch (error) {
      console.error("Error in getAllPrograms:", error);
      return null;
    }
  };

  const soretedPrograms = (programs) => {
    return programs?.sort((a, b) => a.phaseOrderIdx - b.phaseOrderIdx);
  };

  const updatePlanStartDate = async () => {
    let selectedProgramData = await getAllPrograms();
    let selectedPrograms = soretedPrograms(selectedProgramData?.programs || []);

    if (selectedOption !== "All Phases") {
      let selectedPhaseData = (selectedProgramData?.programs ?? [])?.find(
        (phase) => phase.id == selectedPhase
      );
      if (selectedPhaseData) {
        const startIndex = (selectedProgramData?.programs ?? []).indexOf(
          selectedPhaseData
        );
        selectedPrograms = (selectedProgramData?.programs ?? []).slice(
          startIndex
        );
      }
    }
    const assignmentOfProgramData = formatData(selectedPrograms);

    if (buddyRow) {
      updatePlanStartWithBuddy();
      return;
    }
    setLoading(true);
    setStatus("Plan start");
    try {
      await axios({
        method: "post",
        url: `${APIURL}CF/api/reassignProgramAndMealPlanOnPlanStart`,
        data: {
          atheleteID: userUUID,
          // programID: rowData.programID,
          mealPlanID: rowData.mealPlanID,
          startDate: startDate,
          startDay: selectedDay.value,
          duration: rowData?.planDuration_LivEzy,
          phasedProgramID: rowData?.phasedProgramID,
          assignmentProgramData: assignmentOfProgramData,
        },
      });

      let response = await axios({
        method: "post",
        url: `${APIURL}CF/api/Livezy/updateAthleteOnBoardingStatus`,
        data: {
          athleteID: userUUID,
          onboardingStatus: "Plan start",
          coachEmail: coachData?.email,
          planStartDate: startDate,
        },
      });
      changeUserInfo(response.data.result);
      toggleModalState();
    } catch (err) {
      console.log(err);
      displayAlert({
        message: "An error occurred while updating plan start date.",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const updatePlanStartWithBuddy = async () => {
    // if onboardingStatus of both athletes is not Intro call done, then return
    if (
      onboardingStatus != "Intro call done" ||
      buddyRow.onboardingStatus_LivEzy != "Intro call done"
    ) {
      displayAlert({
        message: "Please complete the intro call for buddy as well.",
        type: "warning",
      });
      return;
    }

    setLoading(true);
    setStatus("Plan start");

    const selectedProgramData = await getAllPrograms();
    const selectedPrograms = soretedPrograms(
      selectedProgramData?.programs || []
    );
    const assignmentOfProgramData = formatData(selectedPrograms);

    const selectedBuddyProgramData = await getAllPrograms(true);
    const selectedBuddyPrograms = soretedPrograms(
      selectedBuddyProgramData?.programs || []
    );
    const assignmentOfBuddyProgramData = formatData(selectedBuddyPrograms);

    try {
      await axios({
        method: "post",
        url: `${APIURL}CF/api/reassignProgramAndMealPlanOnPlanStart`,
        data: {
          atheleteID: userUUID,
          // programID: rowData.programID,
          mealPlanID: rowData.mealPlanID,
          startDate: startDate,
          startDay: selectedDay.value,
          duration: rowData?.planDuration_LivEzy,
          phasedProgramID: rowData?.phasedProgramID,
          assignmentProgramData: assignmentOfProgramData,
        },
      });

      await axios({
        method: "post",
        url: `${APIURL}CF/api/reassignProgramAndMealPlanOnPlanStart`,
        data: {
          atheleteID: buddyRow.UserUUID,
          // programID: buddyRow.programID,
          mealPlanID: buddyRow.mealPlanID,
          startDate: startDate,
          startDay: selectedDay.value,
          duration: buddyRow?.planDuration_LivEzy,
          phasedProgramID: buddyRow?.phasedProgramID,
          assignmentProgramData: assignmentOfBuddyProgramData,
        },
      });

      let response = await axios({
        method: "post",
        url: `${APIURL}CF/api/Livezy/updateAthleteOnBoardingStatus`,
        data: {
          athleteID: userUUID,
          onboardingStatus: "Plan start",
          coachEmail: coachData?.email,
          planStartDate: startDate,
        },
      });
      changeUserInfo(response.data.result);

      let response4 = await axios({
        method: "post",
        url: `${APIURL}CF/api/Livezy/updateAthleteOnBoardingStatus`,
        data: {
          athleteID: buddyRow.UserUUID,
          onboardingStatus: "Plan start",
          coachEmail: coachData?.email,
          planStartDate: startDate,
        },
      });
      changeUserInfo(response4.data.result);
    } catch (err) {
      console.log(err);
      displayAlert({
        message:
          "An error occurred while updating plan start date for buddies.",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const updateStatus = async (status) => {
    if (status === "Plan sent" && !programAndMealExist(rowData)) {
      displayAlert({
        message: "Please assign a program and meal plan first",
        type: "warning",
      });
      return;
    }
    setLoading(true);
    setStatus(status);
    try {
      let response = await axios({
        method: "post",
        url: `${APIURL}CF/api/Livezy/updateAthleteOnBoardingStatus`,
        data: {
          athleteID: userUUID,
          onboardingStatus: status,
          coachEmail: coachData?.email,
        },
      });
      if (response.data?.result?.onboardingStatus_LivEzy) {
        changeUserInfo(response.data.result);
      }
    } catch (error) {
      console.log("error in updating onboarding status");
      displayAlert({
        message: "An error occurred while updating onboarding status.",
        type: "error",
      });
    }
    setLoading(false);
  };

  const programAndMealExist = (rowData) => {
    let isProgramExist =
      (rowData?.ProgramName &&
        rowData?.ProgramName != "" &&
        rowData?.ProgramName != "-") ||
      (rowData?.phasedProgramName &&
        rowData?.phasedProgramName != "" &&
        rowData?.phasedProgramName != "-");
    let isMealPlanExist =
      rowData?.mealPlanID &&
      rowData?.mealPlanID != "" &&
      rowData?.mealPlanID != "-";
    return isProgramExist && isMealPlanExist;
  };

  const isOptionDisabled = (option) => {
    let isDisabled =
      options.indexOf(option) != options.indexOf(onboardingStatus) + 1;
    return isDisabled;
  };

  const days = [];
  for (let i = 1; i <= 365; i++) {
    let dayNumber = i;
    if (i % 7 === 1) {
      let weekNumber = Math.ceil(i / 7);
      days.push({
        value: "week",
        label: `Week ${weekNumber}`,
        isDisabled: true,
      });
    }
    days.push({ value: i, label: `Day ${dayNumber}` });
  }

  return (
    <div className="flex items-end gap-3 mb-1">
      {showText && (
        <p
          style={{
            fontFamily: "DM Sans",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "22px",
            width: "fit-content",
          }}
        >
          Onboarding Status:{" "}
          <span
            style={{
              fontFamily: "DM Sans",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "22px",
              width: "fit-content",
              marginLeft: "5px",
            }}
          >
            {" "}
            {status}
          </span>
        </p>
      )}

      {!showText && loading ? (
        <SpurfitCircularProgress />
      ) : (
        <MuiSelect
          variant="standard"
          disableUnderline
          sx={{
            height: "24px",
            fontSize: "14px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#ebebeb",
              },
            },
            "&.MuiSelect-outlined": {
              border: "1.5px solid #ebebeb",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: "1.5px solid #4339f2",
              boxShadow: "0px 0px 0px 3px rgba(67, 57, 242, 0.25)",
            },
            borderRadius: "12px",
            fontFamily: "DM Sans",
          }}
          id="demo-simple-select"
          value={status}
          onChange={(e) => {
            if (e.target.value === "Plan start") {
              toggleModalState();
            } else {
              updateStatus(e.target.value);
            }
          }}
          disabled={options.indexOf(status) < options.indexOf(onboardingStatus)}
        >
          {options.map((option) => (
            <MenuItem
              disabled={isOptionDisabled(option)}
              sx={{
                pointerEvents: isOptionDisabled(option) ? "none" : "auto",
                opacity: isOptionDisabled(option) ? 0.5 : 1,
                fontFamily: "DM Sans",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "22px",
                color: "#000000",
              }}
              key={option}
              value={option}
            >
              {option}
            </MenuItem>
          ))}
        </MuiSelect>
      )}
      <CustomDialog
        open={openDialog}
        onClose={toggleModalState}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <PlanStartForUser
          athleteData={rowData}
          onSave={updatePlanStartDate}
          onClose={toggleModalState}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          selectedPhase={selectedPhase}
          setSelectedPhase={setSelectedPhase}
          startDate={startDate}
          setStartDate={setStartDate}
          selectedDay={selectedDay}
          setSelectedDay={setSelectedDay}
        />
      </CustomDialog>
    </div>
  );
};

export default AthleteStatus;

const options = [
  "Questionnaire filled",
  "Physique pictures received",
  "Plan sent",
  "Intro call done",
  "Plan start",
];

const CustomDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    width: "600px",
    maxWidth: "90%",
    borderRadius: "12px",
    padding: theme.spacing(2),
    overflow: "visible",
    minHeight: "420px",
  },
  "& .MuiDialogTitle-root": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(2),
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
    overflow: "visible",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2),
  },
}));

const DatePickerWrapper = styled("div")({
  "& .react-datepicker-popper": {
    zIndex: 9999,
  },
});
