
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
} from "@/components/ui/dialog";

import React, { useContext, useEffect, useState } from "react";
import { NutritionContext } from "./NutritionContext";
import { Box, Typography } from "@mui/material";
import EditMealInfoModal from "./AddMealPlans/EditMealInfoModal";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { Files, MoreVertical } from "lucide-react";
import DropDownMenu from "@/features/Programs/DropDownMenu";
import EmptyProgramImg from "@/assets/library/NoProgram.png";
import AddMealPlanModal from "./AddMealPlans/AddMealPlanModal";

function FolderInfo(props) {
  const [openAddMeal, setOpenAddMeal] = useState(false);

  const [ShowConfirmationDialoge,setShowConfirmationDialoge]=useState(false)
  const {
    folderSelected,
    setShowEditMealInfoModal,
    showEditMealInfoModal,
    deleteTheFolder,
    setNutritionData,
    setdefaultNItem,
    isTemplate,
    setOpenAddMealModal,
    adminID
  } = useContext(NutritionContext);

  function onDiscard (){
   setShowConfirmationDialoge(false)
  }

  function onContinue (){
    setShowConfirmationDialoge(false);
     deleteTheFolder();
  }

 

 
  return (
    <div id="recipe-content-saved">
      <Box
        sx={{
          gap: "10px",
          display: "flex",
          justifyContent: "space-between",
          padding: "16px 20px",
          borderBottom: "1px solid #E5E5E5",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: 600,
            }}
          >
            {folderSelected?.name}
          </Typography>

          {adminID ? (
            folderSelected?.coachID === adminID ? (
              <></>
            ) : (
              <EditRoundedIcon
                className="cursor-pointer"
                onClick={() => setShowEditMealInfoModal(true)}
              />
            )
          ) : (
            <EditRoundedIcon
              className="cursor-pointer"
              onClick={() => setShowEditMealInfoModal(true)}
            />
          )}

          {showEditMealInfoModal && (
            <EditMealInfoModal
              isOpen={showEditMealInfoModal}
              setIsOpen={setShowEditMealInfoModal}
              item={folderSelected}
              forFolder={true}
            />
          )}
        </Box>
        {adminID ? (
          adminID === folderSelected?.coachID ? (
            <> </>
          ) : (
            <DropDownMenu
              className={"mr-10 w-21 cursor-pointer"}
              triggerElement={<MoreVertical size={20} />}
              options={["Delete Folder"]}
              onSelect={(option) => {
                if (option === `Delete Folder`) {
                  if (folderSelected?.mealPlans?.length > 0) {
                    setShowConfirmationDialoge(true);
                    return;
                  }
                  deleteTheFolder();
                }
              }}
            />
          )
        ) : (
          <DropDownMenu
            className={"mr-10 w-21 cursor-pointer"}
            triggerElement={<MoreVertical size={20} />}
            options={["Delete Folder"]}
            onSelect={(option) => {
              if (option === `Delete Folder`) {
                if (folderSelected?.mealPlans?.length > 0) {
                  setShowConfirmationDialoge(true);
                  return;
                }
                deleteTheFolder();
              }
            }}
          />
        )}
      </Box>

      <div className="w-full flex justify-between w-[100%] gap-3 px-3 py-8 border-b-[1px] border-[#e2e8f0]  ">
        <div className="flex flex-col w-[50%]">
          <div className="program-conatiner-body-details-card w-full max-h-[83px]">
            <div>
              <p className="program-conatiner-body-details-card-title">
                Total Files
              </p>
              <p className="program-conatiner-body-details-card-description">
                {folderSelected?.mealPlans?.length || 0}
              </p>
            </div>
            <Files className="h-[30px] w-[30px]" />
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-3 items-center justify-center mt-[7rem]">
        <img src={EmptyProgramImg} className="w-[100px] h-[100px]" alt="" />
        <p
          className="text-gradient"
          style={{
            fontSize: "1.2rem",
            fontWeight: "600",
          }}
        >
          Select Any Meal Plan To View Or Click To Add Meal Plan
        </p>
        {/* <div className="flex gap-10 ">
          <button
            className="button-new"
            onClick={() => {
              setOpenAddMeal(true);
            }}
          >
            Add Meal Plan
          </button>
        </div> */}
      </div>
      {openAddMeal && (
        <AddMealPlanModal
          isOpen={openAddMeal}
          setIsOpen={setOpenAddMeal}
          fromFolderInfo={false}
          isCopyToMeal={true}
          setNutritionData={setNutritionData}
          setdefaultNItem={setdefaultNItem}
          isTemplate={false}
          openAddModal={() => setOpenAddMealModal(true)}
        />
      )}
      {ShowConfirmationDialoge && (
        <ConfirmDialog
          isOpen={ShowConfirmationDialoge}
          onDiscard={onDiscard}
          onContinue={onContinue}
        />
      )}
    </div>
  );
}

export default FolderInfo;



 

const ConfirmDialog = ({ isOpen, onDiscard, onSave, onContinue }) => {
  return (
    <Dialog open={isOpen}>
      <DialogPortal>
        <DialogOverlay className="bg-black-pure bg-opacity-40 z-[9999]" />
      </DialogPortal>
      <DialogContent
        hideClose={true}
        className="sm:max-w-md z-[9999] font-DMSans outline-none"
      >
        <DialogHeader>
          <DialogTitle className="font-DMSans text-font18">
            Delete Alert
          </DialogTitle>
        </DialogHeader>

        <div className="">
          <p className="font-DMSans text-font14">
            This folder contains meal plans. Are you sure you want to delete
            it?
          </p>
        </div>
        <DialogFooter className={"flex w-full items-center gap-3"}>
          <DialogClose>
            <p
              className="font-DMSans cursor-pointer text-[#ED6C02] text-font14"
              onClick={onDiscard}
            >
              Discard
            </p>
          </DialogClose>

          <DialogClose>
            <button
              className="button-new text-font14 h-[38px] w-[100px]"
              onClick={onContinue}
            >
              Continue
            </button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

 
