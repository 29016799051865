import { Skeleton } from "@/components/ui/skeleton";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import { Collapse } from "@mui/material";
import { CalendarDays, Layers, MoreVertical } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import DropDownMenu from "./DropDownMenu";
import { ExpandableCardForPhasesInFolder } from "./ProgramFolderList";
import { ProgramsContext } from "./ProgramsContext";
import ImportPhaseModal from "./utils/ImportPhaseModal";

const ProgramList = ({ programs, isSubbordinate, adminID }) => {
  const {
    selectedProgram,
    onProgramSelect,
    deleteProgramByID,
    addNewPhase,
    selectedProgramWithPhases,
    isNewPhaseLoading,
    setShowDuplicateProgramModal,
    showDuplicateProgramModal,
    setDuplicateData,
    openPhaseId,
    setOpenPhaseId,
    setFolderSelected,
    folders,
    setOpenFolderId,
    setSelectedProgram,
    selectedTab,
  } = useContext(ProgramsContext);
  const [isDown, setIsDown] = useState(false);
  const handleTogglePhase = (phaseId) => {
    setOpenPhaseId((prevId) => (prevId === phaseId ? null : phaseId));
    setIsDown((prev) => !prev);
  };
  useEffect(() => {
    if (selectedTab === "others") {
      setFolderSelected({});
    }
  }, [selectedTab]);
  return (
    <>
      {programs?.map((program, index) => {
        let weeks = Math.ceil((program?.duration ?? 0) / 7);
        let isSelect = selectedProgram?.id === program?.id;
        let isPhaseSelected = selectedProgramWithPhases?.id === program?.id;
        return (
          <div
            key={`program-${program.id}-${index}`}
            id={program.id}
            className={`flex flex-col cursor-pointer pt-1 px-2 ${
              isSelect ? "folder-collapseCard-container-selected" : ""
            } ${
              isPhaseSelected ? "folder-collapseCard-container-selected" : ""
            }`}
            onClick={() => {
              setFolderSelected({});
              setOpenFolderId(null);
              handleTogglePhase(program?.id);
              onProgramSelect(program);
            }}
          >
            <div
              className="mx-2"
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                className={
                  isSelect
                    ? "channel-preview__wrapper_indicator__selected"
                    : "channel-preview__wrapper_indicator"
                }
                style={{
                  marginLeft: -5,
                }}
              ></div>
              <div
                className="pb-1"
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  width: "90%",
                  gap: "7px",
                }}
              >
                <span
                  style={{
                    fontSize: "16px",
                    fontWeight: 700,
                    color: "#8330e9",
                    // padding: "5px",
                    lineHeight: "10px",
                    fontFamily: "DM Sans",
                    // backgroundColor: "#ffffff",
                    // borderRadius: "10px",
                    // marginLeft: "18px",
                    cursor: "pointer",
                    textDecoration: "underline",
                    visibility: program.programs ? "visible" : "hidden",
                  }}
                >
                  {openPhaseId === program?.id ? (
                    <ArrowDropDownRoundedIcon
                      sx={{
                        fontSize: "35px",
                      }}
                    />
                  ) : (
                    <PlayArrowRoundedIcon
                      sx={{
                        fontSize: "20px",
                      }}
                    />
                  )}
                </span>

                <div className="w-[40px] h-[40px] flex items-center justify-center rounded-lg ">
                  {program?.programs ? <Layers /> : <CalendarDays />}
                </div>
                <div className="flex-col min-w-full max-w-full mr-2">
                  <p
                    className="font-DMSans text-font14 font-medium min-w-[85%] max-w-[85%]"
                    style={{ whiteSpace: "normal", overflowWrap: "break-word" }}
                  >
                    {program.name}
                  </p>

                  {program?.programs ? (
                    <p className="font-DMSans text-font12 text-gray-500">
                      {program?.programs?.length} Phases
                      {adminID && program?.coachID === adminID && (
                        <span
                          style={{
                            fontSize: "10px",
                            fontWeight: 700,
                            color: "#09A033",
                            padding: "5px",
                            lineHeight: "10px",
                            fontFamily: "DM Sans",
                            backgroundColor: "#F7F7F7",
                            borderRadius: "10px",
                            marginLeft: "5px",
                          }}
                        >
                          {"ADMIN"}
                        </span>
                      )}
                    </p>
                  ) : (
                    <p className="font-DMSans text-font12 text-gray-500 flex items-center">
                      {program?.duration ? Math.ceil(program?.duration / 7) : 1}{" "}
                      {Math.ceil(program?.duration / 7) === 1
                        ? "Week"
                        : "Weeks"}
                      {isSubbordinate && (
                        <span
                          style={{
                            fontSize: "10px",
                            fontWeight: 700,
                            color: "#09A033",
                            padding: "5px",
                            lineHeight: "10px",
                            fontFamily: "DM Sans",
                            backgroundColor: "#F7F7F7",
                            borderRadius: "10px",
                            marginLeft: "5px",
                          }}
                        >
                          {program?.coach?.name}
                        </span>
                      )}
                      {adminID && program?.coachID === adminID && (
                        <span
                          style={{
                            fontSize: "10px",
                            fontWeight: 700,
                            color: "#09A033",
                            padding: "5px",
                            lineHeight: "10px",
                            fontFamily: "DM Sans",
                            backgroundColor: "#F7F7F7",
                            borderRadius: "10px",
                            marginLeft: "5px",
                          }}
                        >
                          {"ADMIN"}
                        </span>
                      )}
                    </p>
                  )}
                </div>
              </div>
            </div>
            {program?.programs && openPhaseId === program?.id && (
              <ExpandableCardForPhasesInFolder
                onProgramSelect={onProgramSelect}
                weeks={weeks}
                isPhaseOpen={openPhaseId}
                program={program}
                deleteProgramByID={deleteProgramByID}
                addNewPhase={addNewPhase}
                selectedProgram={selectedProgram}
                loading={isNewPhaseLoading}
                setShowDuplicateProgramModal={setShowDuplicateProgramModal}
                // setIsPhaseSelected={setIsPhaseSelected}
                setDuplicateData={setDuplicateData}
              />
              // <ExpandableCard

              // />
            )}
          </div>
        );
      })}
    </>
  );
};

export default ProgramList;

export const NoImageFound = ({ name }) => {
  const extractNames = (name) => {
    const names = name?.split(" ");
    const firstName = names?.[0];
    const lastName = names?.length > 1 ? names[1] : names[0];

    return { firstName, lastName };
  };

  const { firstName, lastName } = extractNames(name);

  return (
    <>
      <div
        style={{
          fontWeight: "700",
          fontFamily: "DM Sans",
          textTransform: "uppercase",
          color: "black",
        }}
      >
        {firstName?.split("")[0]}
        {lastName?.split("")[0]}
      </div>
    </>
  );
};

export const ExpandableCard = ({
  onProgramSelect,
  deleteProgramByID,
  weeks,
  isPhaseOpen,
  program,
  addNewPhase,
  selectedProgram,
  loading,
  setShowDuplicateProgramModal,
  setDuplicateData,
}) => {
  const [phases, setPhases] = useState([]);
  useEffect(() => {
    if (program?.programs) {
      // sort the programs by phaseOrderIdx  and if the phaseOrderIdx is not present then by name of the phase and names can be Phase 1, Phase 2, Phase 3, etc.
      let sortedPrograms = [...(program?.programs || [])]?.sort((a, b) => {
        if (a?.phaseOrderIdx && b?.phaseOrderIdx) {
          return a?.phaseOrderIdx - b?.phaseOrderIdx;
        }
        const phaseA = parseInt(a?.name?.split(" ")[1]);
        const phaseB = parseInt(b?.name?.split(" ")[1]);
        return phaseA - phaseB;
      });
      setPhases(sortedPrograms);
    }
  }, [program]);
  return (
    <div className="expandable-card w-full ">
      <Collapse
        in={isPhaseOpen}
        timeout={500}
        sx={{
          transitionDuration: "500ms",
          transitionTimingFunction: "ease",
          // minHeight: "100px",
          // maxHeight: "200px",
          // overflowY: "scroll",
          width: "100%",
          // marginLeft: "5%",
          scrollbarWidth: "0px",
          // scrollbarGutter: "unset",
          background: "#fffcfa",
          marginTop: "10px",
          borderRadius: "12px",
          padding: "10px 12px",
        }}
      >
        {phases?.map((item, index) => {
          let totalWeeks = Math.ceil(item?.duration / 7);
          let leftRange, rightRange;
          if (index == 0) {
            leftRange = index + 1;
            rightRange = totalWeeks;
          } else {
            leftRange = index * totalWeeks + 1;
            rightRange = leftRange + totalWeeks - 1;
          }
          let isPhaseSelected = selectedProgram?.id === item?.id;
          return (
            <div
              key={index}
              className={`flex px-2 py-2 gap-3   cursor-pointer w-full items-center rounded-lg ${
                index == 0 ? "mt-0" : "mt-3"
              }
              ${isPhaseSelected ? " text-white-pure" : ""}
              `}
              style={{
                border: "1px solid #d0cdcd",

                background: `${
                  isPhaseSelected
                    ? "linear-gradient(90deg, #464feb 10.42%, #8330e9 100%)"
                    : ""
                }`,
              }}
              onClick={(e) => {
                e.stopPropagation();
                onProgramSelect(item);
              }}
            >
              <div className="w-[22px] h-[22px] flex items-center justify-center rounded-lg">
                <CalendarDays />
              </div>
              <div className="flex-col">
                <p className="font-DMSans text-font14">{item?.name}</p>
                <p className="font-DMSans text-font12 mt-1 font-medium">
                  {totalWeeks} weeks
                </p>
              </div>
              <div
                className="ml-auto mr-1 items-center"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <DropDownMenu
                  className={"mr-24 w-21 cursor-pointer"}
                  triggerElement={<MoreVertical size={20} />}
                  options={["Duplicate Phase", `Delete Phase`]}
                  onSelect={(option) => {
                    if (option === `Delete Phase`) {
                      deleteProgramByID(item?.id);
                    } else if (option === "Duplicate Phase") {
                      setShowDuplicateProgramModal(true);
                      setDuplicateData(item);
                    }
                  }}
                />
              </div>
            </div>
          );
        })}
      </Collapse>
      {loading && (
        <div
          className="w-[100%] h-[60px] rounded-lg bg-[#fffcfa] flex justify-between items-center px-2 py-2 mt-2"
          style={{
            border: "1px solid #d0cdcd",
          }}
        >
          <div
            style={{
              fontWeight: "700",
              fontFamily: "DM Sans",
              textTransform: "uppercase",
              color: "black",
              width: "72px",
              height: "54px",
              maringRight: "5px",
            }}
          >
            <Skeleton className="w-[100%] h-[100%]" />
          </div>
          <div className="w-[70%]">
            <Skeleton className="w-[100%] h-[54px]" />
          </div>
        </div>
      )}
      <div className="w-full px-[10px] py-[5px] flex justify-between items-center  mt-2">
        <ImportPhaseModal />
        <span
          className="ml-auto font-semibold bg-[#6241EA] text-white-pure px-[8px] py-[4px] flex items-center rounded-md text-[14px] cursor-pointer"
          onClick={() => {
            addNewPhase();
          }}
        >
          + Add Phase
        </span>
      </div>
    </div>
  );
};
