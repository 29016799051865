import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import { useContext, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { CustomAlertContext } from "../../App";
import copyIcon from "../../assets/copyIcon.svg";
import deleteIcon from "../../assets/deleteIcon.svg";
import hand from "../../assets/hand.svg";
import Button from "../Button/button";
import {
  ExercisesListDropdown,
  ExercisesListTable,
} from "./ExercisesListTableRow";

const ExercisesList = ({
  workOutList = [],
  setWorkOutList,
  showOnly,
  isEdit,
  isImport,
  handleClick,
  data,
}) => {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const [checked, setChecked] = useState(false);
  const [isApiRunning, setIsApiRunning] = useState(false);
  const [isDraggingMoment, setIsDraggingMoment] = useState(false);
  const { displayAlert } = useContext(CustomAlertContext);

  const buttonStyle = {
    color: "#979797",
    backgroundColor: "#ffffff",
    border: "1px solid #C4C4C4",
    borderRadius: 10,
    margin: "0px 10px",
  };

  const handleSuperSetChange = ({ value, name, id }) => {
    // TO BE FIXED;
    let index = 0;
    let foundArray = workOutList?.find((item, idx) => {
      if (item?.uuidv4 === id) {
        index = idx;
        item.sets = value;
      }
      return item?.uuidv4 === id;
    });
    let sortedArray = foundArray?.set?.filter(
      (item) => item?.superSetUUID === id
    );
    let array = workOutList?.filter((item) => item?.uuidv4 !== id);
    if (isEmpty(value) || value == 0 || value == "") {
      foundArray.set = sortedArray;
      foundArray.sets = "";
    } else {
      foundArray.set = [];
      for (let i = 0; i < value; i++) {
        sortedArray.forEach((_) => {
          _.superSetUUID = id;
          const abc = { ..._ };
          if (i > 0) {
            abc.uuidv4 = uuidv4();
            delete abc.superSetUUID;
          }
          foundArray.set.push(abc);
        });
      }
    }
    foundArray.superSetUUID = id;
    const result = [...array];
    result.splice(index, 0, foundArray);
    setWorkOutList(result);
  };

  const handleChange =
    ({ name, id, isSet, uuidDropdown, superSet }) =>
    (event) => {
      const value = event?.target?.value;
      console.log("onvalue change event got", value, name);
      // if(name == 'duration' ) {
      //   displayAlert("duration can't be 0, please change.")
      // }
      if (superSet) {
        handleSuperSetChange({ value, name, id });
        return;
      }
      let array = [];
      if (name && id) {
        if (uuidDropdown) {
          dropdownChanges({ name, id, uuidDropdown, value });
          return;
        }
        if ((name === "sets" && value >= 2) || isSet) {
          let index = 0;
          let foundArray = workOutList?.find((item, idx) => {
            if (item?.uuidv4 === id) {
              index = idx;
            }
            return item?.uuidv4 === id;
          });
          let multiply = [];
          let i = value;
          while (i > 0) {
            multiply.push({
              ...foundArray,
              isSet: false,

              sets: "1",
              uuidv4: uuidv4(),
            });
            i--;
          }
          if (value > 0 && foundArray) {
            foundArray["isSet"] = true;
            foundArray.set = [...multiply];
            foundArray.sets = value;
          } else {
            delete foundArray.isSet;
            delete foundArray.set;
            delete foundArray.sets;
          }
          array = workOutList?.filter((item) => item?.uuidv4 !== id);
          const result = [...array];
          result.splice(index, 0, foundArray);
          setWorkOutList(result);
        } else {
          array = workOutList?.map((item) => {
            if (item?.uuidv4 === id && name !== "checkbox") {
              item[name] = value;
              if (name === "duration") {
                item["reps"] = "";
                item["Reps"] = "";
              } else if (name === "reps") {
                item["duration"] = "";
                item["Duration"] = "";
              }
            } else if (name === "checkbox" && item?.uuidv4 === id) {
              // FOR CHECKBOX CONDITION;foundArray
              if (item.checkbox) {
                delete item[name];
              } else item[name] = true;
            }
            return item;
          });
          setWorkOutList(array);
        }
      }
    };

  const dropdownChanges = ({ name, id, uuidDropdown, value }) => {
    let index = 0;
    const foundArray = workOutList?.find((item, idx) => {
      if (item?.uuidv4 === uuidDropdown) {
        index = idx;
      }
      return item?.uuidv4 === uuidDropdown;
    });
    const set = foundArray?.set || [];
    const array = set?.map((item) => {
      if (item?.uuidv4 === id) item[name] = value;
      if (name === "duration") {
        item["reps"] = "";
        item["Reps"] = "";
      } else if (name === "reps") {
        item["duration"] = "";
        item["Duration"] = "";
      }
      return item;
    });
    foundArray.set = array;
    const uniqueVal = workOutList?.filter(
      (item) => item?.uuidv4 !== uuidDropdown
    );
    const result = [...uniqueVal];
    result.splice(index, 0, foundArray);
    setWorkOutList(result);
  };

  const onclick =
    ({ name }) =>
    (event) => {
      if (name === "checkbox") {
        const array = workOutList?.map((item, idx) => {
          item["checkbox"] = true;
          return item;
        });
        setWorkOutList(array);
        setChecked(!checked);
      }

      if (name === "delete") {
        const array = workOutList?.filter(
          (item) => item["checkbox"] === undefined
        );

        setWorkOutList(array);
        setChecked(false);
      }
    };

  const handleSuperSet = () => {
    const superSetUUID = uuidv4();
    const notSelected = workOutList?.filter((item) => !item?.checkbox);
    const selected = workOutList
      ?.filter((item) => item?.checkbox)
      ?.map((item, idx) => {
        if (item.checkbox) {
          item.sets = "1";
          item.superSetUUID = superSetUUID;
          item.isSuperset = true;
          delete item.checkbox;
        }
        return item;
      });

    if (!isEmpty(selected)) {
      var rest = {
        duration: "",
        Name: "SuperSet",
        uuidv4: superSetUUID,
        ImageLink: "",
        reps: "",
        sets: "1",
        videoKey: "",
        weightUnits: "",
        set: selected,
        size: selected?.length || 0,
        superSet: true,
      };
      setWorkOutList([...notSelected, rest]);
    }
  };

  const payLoadCamelCase = (moduleData) => {
    const result = moduleData?.map((item) => {
      const payloadValue = {
        category: item?.category || item?.Category,
        equipment: item?.equipment || item?.Equipment,
        imageKey: item?.imageLink || item?.ImageLink || item?.imageKey,
        videoKey: item?.videoURL || item?.VideoURL || item?.videoKey,
        exercise: item?.name || item?.Name || item?.exercise || "Walking",
        primaryKey: item?.primaryKey || item?.PrimaryKey,
        duration: item?.duration || item?.Duration || 0,
        reps: item?.reps || item?.Reps || 0,
        sets: item?.sets || item?.Sets,
        weightUnits: item?.weightUnits || item?.WeightUnits || "lbs",
        notes: item?.notes || item?.Notes,
        weights: item?.weights || item?.Weights || 0,
        exerciseType: "Warm up",
      };
      return payloadValue;
    });
    return result;
  };

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    console.log("on drag end***", removed, result);
    if (result[0].Name == "Break") {
      displayAlert("Break cannot be the first movement, please change");
      return list;
    } else return result;
  };

  const onDragEnd = (result) => {
    console.log(
      "on drag end",
      workOutList[result.source.index].Name,
      result.destination.index
    );
    // dropped outside the list
    if (
      workOutList[result.source.index].Name == "Break" &&
      result.destination.index == 0
    ) {
      displayAlert("Break cannot be the first movement, please change");
      return;
    }

    if (!result.destination) {
      return;
    }

    const items = reorder(
      workOutList,
      result.source.index,
      result.destination.index
    );
    setWorkOutList(items);
  };

  const getmoduleDuration = (item) => {
    const array = item?.map((item) => (item?.duration ? item?.duration : 0));
    const count = array?.reduce((total, x) => parseInt(total) + parseInt(x));
    return count / 60;
  };
  const history = useHistory();

  const pushWorkout = async () => {
    let moduleData = data?.moduleDetails || data?.ModuleDetails;
    let moduleDurationCount = getmoduleDuration(moduleData);
    let dateVal = data?.moduleDate || data?.ModuleDate;
    let moduleDateVal = moment(dateVal, "DD-MM-YYYY")
      .set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
      .format("YYYY-MM-DD");

    setIsApiRunning(true);

    await axios({
      method: "post",
      url: `${APIURL}api/modules`,
      data: {
        calories: data?.calories || data?.Calories,
        modulesUUID: payLoadCamelCase(moduleData),
        moduleDuration: moduleDurationCount,
        name: data?.name || data?.Name,
        tag: data?.tag || data?.Tag,
        trainerUUID: data?.trainerUUID || data?.TrainerUUID,
        userUUID: data?.userUUID,
        primaryKey: data?.primaryKey || data?.PrimaryKey,
        moduleDate: moduleDateVal,
      },
    })
      .then((res) => {
        history.push(`/client/${data?.userUUID}/`);
        displayAlert("Sent");
      })
      .catch((err) => {
        console.log(err);
        displayAlert("Network error, please try again");
      })
      .finally(() => {
        setIsApiRunning(false);
      });
  };

  return (
    <>
      {!isEmpty(workOutList) && (
        <>
          <div
            className="text-font16 text-blue-light px-6 pb-2 flex justify-between"
            // style={{ overflowY: 'scroll' }}
          >
            <div>
              {isEdit && (
                <Button
                  onClick={() => handleClick(true)}
                  color="#ffffff"
                  backgroundColor="#FF9800"
                >
                  <b>EDIT</b>
                </Button>
              )}
              {isImport === "true" && (
                <Button
                  onClick={pushWorkout}
                  color="#ffffff"
                  backgroundColor="#FF9800"
                  style={{ marginLeft: "10px" }}
                >
                  <b>PUSH</b>
                </Button>
              )}
            </div>
          </div>
          {/* <div className="text-font16 font-semibold text-black-blackish px-6 py-4 bg-white-ghost">
            EXERCISES
          </div> */}

          {!showOnly && (
            <div className="p-4">
              <Checkbox
                onChange={onclick({ name: "checkbox" })}
                color="primary"
                checked={checked}
              />
              <Button onClick={handleSuperSet} style={buttonStyle}>
                <b className="px-4 py-2 text-font12">SUPERSET</b>
              </Button>
              {/* <Button onClick={() => console.log('handle click')} style={buttonStyle}>
            <b className="px-4 py-2 text-font12">CIRCUIT</b>
          </Button> */}
              <Button
                onClick={() => {
                  const array = workOutList.filter(
                    (item) => item["checkbox"] === true
                  );
                  array.map((item, idx) => {
                    array[idx] = {
                      ...item,
                      uuidv4: uuidv4(),
                      checkbox: undefined,
                    };
                  });
                  setWorkOutList([...workOutList, ...array]);
                }}
                style={{ ...buttonStyle, padding: "14px 0px", margin: "0 5px" }}
              >
                <img src={copyIcon} alt="copyIcon" />
              </Button>
              <Button
                onClick={onclick({ name: "delete" })}
                style={{ ...buttonStyle, padding: "14px 0px", margin: "0 5px" }}
              >
                <img src={deleteIcon} alt="deleteIcon" />
              </Button>
              <Button
                onClick={() => {
                  var rest = {
                    duration: "30",
                    Name: "Break",
                    uuidv4: uuidv4(),
                    ImageLink:
                      "https://img.icons8.com/ios/50/000000/time-out.png",
                    reps: "",
                    sets: "1",
                    videoKey: "",
                    weightUnits: "",
                  };
                  var temp = [...workOutList, rest];
                  setWorkOutList(temp);
                }}
                backgroundColor="#F7F7FF"
                borderRadius="6px"
                margin="0px 8px"
              >
                <img src={hand} alt="rest img" />
                <span className="p-2 text-blue-light"> ADD REST </span>
              </Button>
            </div>
          )}
          <div
            style={{
              maxHeight: "55vh",
              overflowX: "auto",
              position: "relative",
              border: "1px solid #ebebeb",
            }}
          >
            <TableContainer component={Paper}>
              <Table
                stickyHeader
                aria-label="sticky table"
                sx={{
                  minWidth: 650,
                  overflowX: "hidden",
                }}
              >
                <TableHead className="">
                  <TableRow>
                    <TableCell style={{ width: 200 }}>
                      &nbsp;&nbsp;&nbsp;Exercise Name
                    </TableCell>
                    <TableCell align="right">Sets</TableCell>
                    <TableCell align="right">Reps</TableCell>
                    <TableCell align="right">Duration (sec)</TableCell>
                    <TableCell align="right">Weight&nbsp;</TableCell>
                    <TableCell align="right">Units&nbsp;</TableCell>
                    <TableCell align="right">Notes&nbsp;</TableCell>
                  </TableRow>
                </TableHead>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <TableBody
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {workOutList &&
                          workOutList?.map((item, idx) => {
                            return (
                              <Draggable
                                key={item.uuidv4}
                                draggableId={item.uuidv4}
                                index={idx}
                              >
                                {(provided, snapshot) => (
                                  <>
                                    {setIsDraggingMoment(snapshot?.isDragging)}
                                    {item?.isSet || item?.superSet ? (
                                      <>
                                        <ExercisesListDropdown
                                          provided={provided}
                                          data={item}
                                          handleChange={handleChange}
                                          showOnly={showOnly}
                                          isDragging={snapshot?.isDragging}
                                        />
                                      </>
                                    ) : (
                                      <ExercisesListTable
                                        provided={provided}
                                        row={item}
                                        handleChange={handleChange}
                                        showOnly={showOnly}
                                        isDragging={snapshot?.isDragging}
                                      />
                                    )}
                                  </>
                                )}
                              </Draggable>
                            );
                          })}
                      </TableBody>
                    )}
                  </Droppable>
                </DragDropContext>
              </Table>
            </TableContainer>
          </div>
        </>
      )}
    </>
  );
};

export default ExercisesList;
