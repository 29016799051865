import axios from "axios";
import DumbellIcon from "../../../assets/library/dumbell.png";

import { store } from "@/redux/store";
import { Divider, Popover } from "@mui/material";
import { useState } from "react";

export const createWorkoutWithID = async (workout, workoutID) => {
  let data = {
    workout: {
      workoutInfo: {
        id: workoutID,
        name: workout?.name || "",
        description: workout?.description || "",
        dateCreated: new Date().toISOString(),
        coachID: workout?.coachID,
        workoutType: workout?.workoutType ?? "NOSCORE",
        isCoachWorkout: false,
        runTime: workout?.runTime,
      },
      workoutContent: workout?.workoutContent,
    },
  };

  await axios({
    method: "post",
    url: `${
      store.getState().auth.apiURL
    }CF/api/CoachWeb/Create/addWorkoutWithAllDetailsJSON`,
    data,
  });
};

const updateMeasurementsForRounds = (
  measurements,
  newNumRounds,
  roundIndex
) => {
  return measurements.map((m) => {
    let measurement = m.measurement;
    if (measurement.reps) {
      let reps = measurement.reps;
      let numReps = Object.keys(reps).length;
      let newReps = {};
      let prevRep = 0;
      for (let i = 1; i <= newNumRounds; i++) {
        if (reps[i]) {
          newReps[i] = reps[i];
          prevRep = reps[i];
        } else {
          newReps[i] = prevRep;
        }
      }
      measurement.reps = newReps;
    }
    if (measurement.weight) {
      let weight = measurement.weight;
      let numWeight = Object.keys(weight).length;
      let newWeight = {};
      let prevWeight = 0;
      for (let i = 1; i <= newNumRounds; i++) {
        if (weight[i]) {
          newWeight[i] = weight[i];
          prevWeight = weight[i];
        } else {
          newWeight[i] = prevWeight;
        }
      }
      measurement.weight = newWeight;
    }

    if (measurement.height) {
      let height = measurement.height;
      let numHeight = Object.keys(height).length;
      let newHeight = {};
      let prevHeight = 0;
      for (let i = 1; i <= newNumRounds; i++) {
        if (height[i]) {
          newHeight[i] = height[i];
          prevHeight = height[i];
        } else {
          newHeight[i] = prevHeight;
        }
      }
      measurement.height = newHeight;
    }
    return { measurement };
  });
};

export const decrementCurrentRound = (
  currentData,
  setCurrentData,
  day,
  workoutIdx,
  rIdx,
  numRounds,
  measurements
) => {
  if (numRounds > 1) {
    let updatedData = { ...currentData };
    let newNumRounds = numRounds - 1;
    updatedData[day][workoutIdx].workoutContent.rounds[rIdx].round.numRounds =
      newNumRounds;

    // Update measurements
    updatedData[day][workoutIdx].workoutContent.rounds[
      rIdx
    ].round.measurements = updateMeasurementsForRounds(
      measurements,
      newNumRounds,
      rIdx
    );

    setCurrentData(updatedData);
    return updatedData;
  }
};

export const incrementCurrentRound = (
  currentData,
  setCurrentData,
  day,
  workoutIdx,
  rIdx,
  numRounds,
  measurements
) => {
  let updatedData = { ...currentData };
  let newNumRounds = numRounds + 1;
  updatedData[day][workoutIdx].workoutContent.rounds[rIdx].round.numRounds =
    newNumRounds;

  // Update measurements
  updatedData[day][workoutIdx].workoutContent.rounds[rIdx].round.measurements =
    updateMeasurementsForRounds(measurements, newNumRounds, rIdx);

  setCurrentData(updatedData);
  return updatedData;
};

export const arrayOfAllWorkouts = (data) => {
  let workouts = [];
  let days = Object.keys(data);
  days.forEach((day) => {
    data[day]?.forEach((workout) => {
      if (!workout?.rest) {
        workouts.push(JSON.stringify(workout));
      }
    });
  });
  console.log("workouts: aArray ", workouts);
  return workouts;
};

export const createWorkout = async (workout) => {
  try {
    const response = await axios({
      method: "post",
      url: `${
        store.getState().auth.apiURL
      }CF/api/CoachWeb/Create/addWorkoutWithAllDetailsJSON`,
      data: workout,
    });
    console.log("create workout response: ", response.data);
    return response.data.result;
  } catch (error) {
    console.log("error in create workout: ", error);
    throw error;
  }
};

export const updateWorkout = async (workout) => {
  try {
    const response = await axios({
      method: "post",
      url: `${
        store.getState().auth.apiURL
      }CF/api/CoachWeb/Update/updateWorkoutWithAllDetailsJSON`,
      data: workout,
    });
    console.log("update workout response: ", response.data);
    return response.data.result;
  } catch (error) {
    console.log("error in update workout: ", error);
    throw error;
  }
};

export const RenderWorkoutCardInProgram = ({
  provided,
  colors,
  idx,
  setShowWorkout,
  setSelectedToShow,
  workout,
  isExpanded,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const exercises = [];

  if (workout?.[1]?.workoutContent?.rounds) {
    let rounds = workout?.[1]?.workoutContent?.rounds;
    rounds.forEach((round) => {
      let measurements = round?.round?.measurements;
      if (measurements) {
        measurements.forEach((m) => {
          exercises.push(m.measurement.movementName);
        });
      }
    });
  }

  let exerciseString = exercises.join(", ");

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div
      // {...provided.draggableProps}
      style={{
        padding: "12px 8px",
        overflow: "hidden",
        // whiteSpace: "nowrap",
        // textOverflow: "ellipsis",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        background: colors[idx % 3],
        borderRadius: "8px",
        alignItems: "center",
        minHeight: 50,
      }}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      onClick={() => {
        setShowWorkout(true);
        console.log("jatin workout", workout);
        setSelectedToShow(workout);
      }}
    >
      <div className="flex items-center gap-2">
        <img
          src={DumbellIcon}
          style={{ width: "20px", height: "20px", marginLeft: "-10px" }}
        />
        <div
          style={
            {
              // maxWidth: "50%",
            }
          }
        >
          <p className="week-card-conatiner-draggable-text">
            {workout?.[1]?.name}
          </p>
          {workout?.[1]?.runTime > 0 && (
            <p className="week-card-conatiner-draggable-description">
              {workout?.[1]?.runTime} MINS
            </p>
          )}
        </div>
      </div>

      {isExpanded && (
        <div>
          {/* All exerecises in workout */}
          {exerciseString.length > 24 ? (
            <div className="flex items-center flex-wrap w-[120px]">
              <p className=" w-[100px] font-DMSans font-medium text-sm text-ellipsis overflow-hidden">
                {exerciseString.slice(0, 24)}...{" "}
                <span
                  onMouseEnter={handleClick}
                  className="workout-more-exercises cursor-pointer"
                >
                  more
                </span>
              </p>

              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                slotProps={{
                  paper: {
                    sx: {
                      borderRadius: "12px",
                      maxHeight: "290px",
                    },
                  },
                }}
              >
                <div onMouseLeave={handleClose} className="popover-container">
                  {exercises?.map((e, index) => (
                    <>
                      <p className="popover-exercise">{e}</p>
                      {index !== exercises.length - 1 && <Divider />}
                    </>
                  ))}
                </div>
              </Popover>
            </div>
          ) : (
            <p className="week-card-conatiner-draggable-description">
              {exerciseString}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

// export const RenderWorkoutCardInProgram = ({
//   provided,
//   colors,
//   idx,
//   setShowWorkout,
//   setSelectedToShow,
//   workout,
//   isExpanded,
// }) => {
//   return renderWorkoutCard(
//     provided,
//     colors,
//     idx,
//     setShowWorkout,
//     setSelectedToShow,
//     workout,
//     isExpanded
//   );
// };

export const isWorkoutsInfoUpdated = ({
  oldDaysMessages,
  oldDaysTargets,
  newDaysMessages,
  newDaysTargets,
}) => {
  let isUpdated = false;

  // stringified old and new messages
  let oldMessages = oldDaysMessages ? JSON.stringify(oldDaysMessages) : "";
  let newMessages = newDaysMessages ? JSON.stringify(newDaysMessages) : "";

  // stringified old and new targets
  let oldTargets = oldDaysTargets ? JSON.stringify(oldDaysTargets) : "";
  let newTargets = newDaysTargets ? JSON.stringify(newDaysTargets) : "";

  if (oldMessages !== newMessages || oldTargets !== newTargets) {
    isUpdated = true;
  }

  return isUpdated;
};

export const isDaysWorkoutsUpdated = ({ oldDaysWorkouts, newDaysWorkouts }) => {
  console.log("oldDaysWorkouts: ", oldDaysWorkouts);
  console.log("newDaysWorkouts: ", newDaysWorkouts);

  if (newDaysWorkouts?.[0]?.rest) {
    newDaysWorkouts = [];
  }

  if (
    oldDaysWorkouts === newDaysWorkouts ||
    oldDaysWorkouts?.length !== newDaysWorkouts?.length
  ) {
    return true;
  }
  let update = false;
  oldDaysWorkouts?.forEach((oldWorkout, index) => {
    let newWorkout = newDaysWorkouts[index];
    if (oldWorkout?.workoutID !== newWorkout?.id) {
      update = true;
      return true;
    }
  });

  return update;
};
