//const APIURL = "http://localhost:8080/";

// const APIURL = "https://clam-working-unduly.ngrok-free.app/";
const APIURL = "https://getfitaiserver.com/";

export const APIURLLivezy = "https://livezy.getfitaiserver.com/";

export const OPEN_API_KEY =
  "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";

export default APIURL;

// <!-- Global site tag (gtag.js) - Google Analytics -->
// <script async src="https://www.googletagmanager.com/gtag/js?id=G-TDZ2YKD0FL"></script>
// <script>
//   window.dataLayer = window.dataLayer || [];
//   function gtag(){dataLayer.push(arguments);}
//   gtag('js', new Date());

//   gtag('config', 'G-TDZ2YKD0FL');
// </script>
