import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import axios from "axios";
import mixpanel from "mixpanel-browser";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { CustomAlertContext } from "../../../App";
import Layout from "../../../components/layout/Layout";
import SpinnerComponent from "../../../components/layout/spinner";
import EmptyProgramImg from "../assests/NoProgram.png";
import DuplicatePrograms from "./DuplicateProgramFunction";

import {
  KeyboardDoubleArrowLeftRounded,
  KeyboardDoubleArrowRightRounded,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  getAllProgram,
  getProgramsByCoachIDs,
} from "../../../ApiServices/Programs/Api";
import {
  getAllWorkouts,
  getWorkoutsbyCoach,
} from "../../../ApiServices/Workouts/Api";
import { setWorkoutsData } from "../../../redux/dataSlice";
import { SearchIcon } from "../../getStreamChat/assets";
import CreatePrograms from "./createProgramCF";
import "./programsCF.css";
import ProgramsList from "./ProgramsList";

export default function ProgramsCF({ location }) {
  const buttonStyle = {
    width: "142px",
    height: "33px",
    fontSize: "14px",
    background: "#071148",
    borderRadius: "8px",
    color: "#fff",
    textTransform: "none",
    marginLeft: 12,
  };
  const APIURL = useSelector((state) => state.auth.apiURL);
  const { programId } = useParams();
  const [value, setValue] = useState(0);
  const [searched, setSearched] = React.useState("");
  const [showModal, setShowModal] = useState(false);
  const [showUpdateInfoModal, setShowUpdateInfoModal] = useState(false);
  const [updateInfo, setUpdateInfo] = useState({});
  const [programName, setProgramName] = useState("");
  const [programDesc, setProgramDesc] = useState("");
  const [trainerUUID, setTrainerUUID] = useState("");
  const [programs, setPrograms] = useState([]);
  const [tempPrograms, setTempPrograms] = useState([]);
  const [apiReceivedPrograms, setApiReceivedPrograms] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loaderNew, setLoaderNew] = useState(false);
  const { displayAlert } = useContext(CustomAlertContext);
  const history = useHistory();
  const [displayPopup, setDisplayPopup] = useState(false);
  const [reload, setReload] = useState(false);
  const [programDetails, setProgramDetails] = useState([]);

  const [activeProgram, setActiveProgram] = useState(-1);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [addProgramConfirmation, setaddProgramConfirmation] = useState(false);
  const [subbordinateCoachPrograms, setSubborinateCoachPrograms] = useState([]);

  const [newProgram, setNewProgram] = useState(false);
  const [row, setRows] = useState(1);

  const [workouts, setWorkouts] = useState([]);
  const [showContinueEditing, setShowContinueEditing] = useState(false);
  const [isRightSideOpen, setIsRightSideOpen] = useState(true);
  const [programsWorkoutId, setProgramsWorkoutId] = useState({});
  const coachID = useSelector((state) => state.auth.trainerUUID);
  const adminID = useSelector((state) => state.auth?.data?.adminID);
  const isAdmin = useSelector((state) => state.auth?.data?.isAdmin);
  const subbordinateCoachIDs = useSelector(
    (state) => state.auth.subbordinateCoachIDs
  );

  const childRef = useRef(null);

  const triggerChildFunction = (isSilent) => {
    if (childRef.current) {
      return childRef.current.saveProgramDetails(isSilent);
    }
  };
  const triggerChildFunctionForCount = () => {
    if (childRef.current) {
      return childRef.current.row;
    }
  };

  console.log("trgger: ", triggerChildFunctionForCount());

  const [showAlert, setShowAlert] = useState({
    show: false,
    message: "",
  });

  const gymAffiliation = useSelector(
    (state) => state.auth?.data?.gymAffliation
  );
  const isLivezy = gymAffiliation === "Livezy";

  const dispatch = useDispatch();

  const init = async () => {
    let trainerDetails = JSON.parse(localStorage.getItem("trainerCredentials"));
    let unsavedData = localStorage.getItem("unsavedPrograms");
    if (unsavedData) {
      setShowContinueEditing(true);
    }

    mixpanel.init("147a208f5832549c876d94601401033e", {
      api_host: "https://api.mixpanel.com",
      ignore_dnt: true,
    });
    mixpanel.identify(trainerDetails?.password);
    mixpanel.people.set_once({
      Name: trainerDetails?.Name,
      Email: trainerDetails?.Email,
    });

    setTrainerUUID(trainerDetails?.password);
    getProgramsData();

    if (location?.state) {
      if (location?.state?.success) {
        setShowAlert({
          show: true,
          message: "Programs fetched successfully",
        });
        setTimeout(() => {
          setShowAlert({
            show: false,
            message: "",
          });
        }, 3000);
        window.history.replaceState(null, "");
      }
    }

    let workouts = isLivezy
      ? await getWorkoutsbyCoach(coachID)
      : await getAllWorkouts(coachID, adminID);
    setWorkouts(workouts);

    dispatch(setWorkoutsData(workouts));
  };

  useEffect(() => {
    init();
  }, [reload]);

  const programsStates = useSelector((state) => state.programs.programsStates);
  const getProgramsData = async () => {
    setLoader(true);
    setLoaderNew(true);
    let trainerDetails = JSON.parse(localStorage.getItem("trainerCredentials"));

    if (programsStates?.finished) {
      setPrograms(programsStates?.programs);
      setLoader(false);
      setLoaderNew(false);
      return;
    }

    let allPrograms = await getAllProgram(coachID, adminID);
    if (isAdmin && subbordinateCoachIDs?.length > 0) {
      let subbordinateCoachPrograms = await getProgramsByCoachIDs(
        subbordinateCoachIDs
      );
      setSubborinateCoachPrograms(subbordinateCoachPrograms);
    }

    let sorted = allPrograms.sort(
      (a, b) => new Date(b.creationDate) - new Date(a.creationDate)
    );

    let notTemplate = sorted.filter((program) => !program.isTemplate);
    let template = sorted.filter((program) => program.isTemplate);

    if (programId) {
      // get the program id on top and select it
      getTheProgramOnTop(programId, sorted);
    } else {
      setPrograms(sorted);
      setLoader(false);
      setTimeout(() => {
        let selectedProgram =
          (value == 1 ? template?.[0]?.id : notTemplate?.[0]?.id) ||
          sorted[0]?.id;
        if (localStorage.getItem("programid"))
          if (localStorage.getItem("programid") == 999)
            getProgramDetails(selectedProgram);
          else getProgramDetails(localStorage.getItem("programid"));
        else getProgramDetails(selectedProgram);
      }, 1000);
      // localStorage.removeItem("programid");
      // console.log("notTemplate ",notTemplate)
      setTempPrograms(sorted);
    }
    setLoader(false);
    setLoaderNew(false);
  };

  const getTheProgramOnTop = (id, programs) => {
    let temp = programs;
    let index = temp.findIndex((item) => item.id == id);
    if (index != -1) {
      let temp1 = temp[index];
      temp.splice(index, 1);
      temp.unshift(temp1);

      // set this program as selected program
      setActiveProgram(id);
      getProgramDetails(id);
    } else if (programs?.[0].id) {
      // if program is not found then select the first program
      setActiveProgram(programs?.[0].id);
      getProgramDetails(programs?.[0].id);
    }
    setPrograms(temp);
    setTempPrograms(temp);
  };

  const requestSearch = useCallback((searchedVal) => {
    setSearched(searchedVal);
    if (searchedVal !== "") {
      const filteredRows = tempPrograms.filter((row) => {
        return row.name.toLowerCase().includes(searchedVal.toLowerCase());
      });
      if (filteredRows.length > 0) {
        setPrograms(filteredRows);
      }
    } else {
      setPrograms(tempPrograms);
    }
  });

  const cancelSearch = () => {
    setSearched("");
    setPrograms(tempPrograms);
  };

  const duplicateProgram = (
    key,
    name,
    description,
    daysMessages,
    daysTargets,
    isTemplate
  ) => {
    setLoader(true);
    DuplicatePrograms(
      key,
      name,
      description,
      setLoader,
      setReload,
      daysMessages,
      daysTargets,
      isTemplate,
      coachID
    );
  };

  const deleteProgram = (key) => {
    setLoader(true);
    axios({
      method: "post",
      url: `${APIURL}CF/api/deletemodularprogram`,
      data: {
        programID: key,
      },
    })
      .then(async (res) => {
        getProgramsData();
        setShowAlert({
          show: true,
          message: "Program deleted successfully",
        });
        localStorage.removeItem("programid");
        setTimeout(() => {
          setShowAlert({
            show: false,
            message: "",
          });
        }, 3000);
      })
      .catch((err) => {
        if (programDetails?.data?.result?.id == 999) {
          getProgramsData();
        } else {
          displayAlert("Network Error. Please try again");
        }
      });
  };

  const transformProgram = (programData) => {
    let toProgramsCreate = {};
    let newDaysMessages = {};
    let newDaysTargets = {};

    // sort workouts by dayIdx
    let sortedWorkouts = programData?.workouts?.sort(
      (a, b) => a.dayIdx - b.dayIdx
    );

    let modifiedWorkouts = [];

    // if multiple days have same dayIdx then merge them
    sortedWorkouts.forEach((workout) => {
      let dayIdx = workout.dayIdx;
      let index = modifiedWorkouts.findIndex((item) => item.dayIdx == dayIdx);
      if (index != -1) {
        modifiedWorkouts[index] = {
          ...modifiedWorkouts[index],
          daysMessages:
            modifiedWorkouts[index].daysMessages ?? workout.daysMessages,
          daysTargets:
            modifiedWorkouts[index].daysTargets ?? workout.daysTargets,
          daysWorkouts:
            modifiedWorkouts[index]?.daysWorkouts?.length > 0
              ? modifiedWorkouts[index]?.daysWorkouts
              : workout?.daysWorkouts,
        };
      } else {
        modifiedWorkouts.push(workout);
      }
    });

    modifiedWorkouts?.map((workout) => {
      let dayIdx = workout.dayIdx;
      toProgramsCreate[dayIdx] = [];

      if (workout.daysWorkouts.length == 0) {
        toProgramsCreate[dayIdx].push({
          rest: true,
        });
      }
      workout?.daysWorkouts.map((dayWorkout) => {
        toProgramsCreate[dayIdx].push(dayWorkout.workout);
      });

      if (
        workout?.daysMessages?.messages &&
        workout?.daysMessages?.messages?.length > 0
      ) {
        newDaysMessages = {
          ...newDaysMessages,
          [dayIdx]: workout?.daysMessages,
        };
      }

      if (workout?.daysTargets) {
        newDaysTargets = {
          ...newDaysTargets,
          [dayIdx]: workout?.daysTargets,
        };
      }
    });
    return {
      toProgramsCreate,
      daysMessages: newDaysMessages,
      daysTargets: newDaysTargets,
    };
  };

  const getProgramDetails = async (key) => {
    localStorage.removeItem("programid");
    if (key) {
      setLoaderNew(true);
      localStorage.setItem("programid", key);
      setActiveProgram(key);
      if (key != 999) {
        axios({
          method: "get",
          url: `${APIURL}CF/api/getProgramByID`,
          params: {
            programID: key,
          },
        })
          .then(async (res) => {
            let {
              toProgramsCreate: data,
              daysMessages,
              daysTargets,
            } = transformProgram(res.data.result);
            let isTemplate = res.data.result?.isTemplate;
            console.log("workoutdata: ", data);
            console.log("workoutdata:1 ", res.data);
            setProgramDetails({
              workoutData: data,
              data: res.data,
              daysMessages,
              daysTargets,
              isTemplate,
            });
            // history.push(`/library/editProgramCF/${res.data.result.id}`, {
            //   state: {
            //     data,
            //     name: res.data.result.name,
            //     desc: res.data.result.description,
            //     trainerUUID: res.data.result.coachID,
            //   },
            // });
            setLoaderNew(false);
          })
          .catch((err) => {
            displayAlert("Network Error. Please try again");
            setLoaderNew(false);
          });
      } else {
        setLoaderNew(false);
      }
    }
  };

  console.log("find data ", programDetails?.data?.result?.id);

  const handleConfirm = async () => {
    setUnsavedChanges(false);
    localStorage.removeItem("programid");
    console.log("delet ", programDetails);
    if (programDetails?.data?.result?.id != 999) {
      if (Object.keys(programDetails?.workoutData).length === 0) {
        console.log("deleteing");
        deleteProgram(programDetails?.data?.result?.id);
      }
    } else {
      setUnsavedChanges(false);
      setPrograms(apiReceivedPrograms);
    }
    if (addProgramConfirmation) {
      setShowModal(true);
      setaddProgramConfirmation(false);
      getProgramDetails(apiReceivedPrograms[0].id);
    }
  };

  const handleCancel = () => {
    setOpenConfirmDialog(false);
  };

  let localUnsavedProgram = JSON.parse(localStorage.getItem("unsavedPrograms"));

  const onResumeEditing = async () => {
    // getProgramDetails(localUnsavedProgram?.location?.data?.result?.id);
    if (localUnsavedProgram?.newProgram) {
      setNewProgram(true);
      setProgramDetails(localUnsavedProgram?.location);

      // insert the program in the programs list
      let dataFromProgs = {
        trainerUUID: trainerUUID,
        name: localUnsavedProgram?.location?.data?.result?.name,
        description: localUnsavedProgram?.location?.data?.result?.desc,
        duration: 7,
        creationDate: new Date().toISOString(),
        daysWorkouts: [],
        workouts: [],
        id: localUnsavedProgram?.location?.data?.result?.id,
        isTemplate: localUnsavedProgram?.location?.data?.result?.isTemplate,
      };

      setPrograms((prev) => [dataFromProgs, ...prev]);
      setActiveProgram(localUnsavedProgram?.location?.data?.result?.id);

      let timeout = setTimeout(() => {
        if (childRef.current) {
          childRef.current.retriveStateFromLocalStorage();
        }
        clearTimeout(timeout);
      }, 1000);
    } else if (
      activeProgram != localUnsavedProgram?.location?.data?.result?.id
    ) {
      let res = await getProgramDetails(
        localUnsavedProgram?.location?.data?.result?.id
      );
      let timeout = setTimeout(() => {
        if (childRef.current) {
          childRef.current.retriveStateFromLocalStorage();
        }
        clearTimeout(timeout);
      }, 1000);
    } else {
      if (childRef.current) {
        childRef.current.retriveStateFromLocalStorage();
      }
    }
    setShowContinueEditing(false);
    // localStorage.removeItem("unsavedPrograms");
  };

  const updateProgramInfo = async () => {
    try {
      let res = await axios({
        method: "post",
        url: `${APIURL}CF/api/programs/updateProgramInfo`,
        data: {
          id: updateInfo.id,
          name: updateInfo.name,
          description: updateInfo.desc,
        },
      });
      if (res.data.result) {
        console.log("update progrm result", res.data.result);
        const updatedArray = programs.map((obj) => {
          // If the object's id matches, update the name and description
          if (obj.id === updateInfo?.id) {
            return {
              ...obj,
              name: updateInfo?.name,
              description: updateInfo?.desc,
            };
          }
          return obj; // Otherwise, return the object as it is
        });
        console.log("updated array", updatedArray);
        setPrograms(updatedArray);
        setProgramDetails((prevState) => ({
          ...prevState,
          data: {
            ...prevState.data,
            result: {
              ...prevState.data.result,
              name: updateInfo.name,
              description: updateInfo.desc,
            },
          },
        }));
        setTempPrograms(updatedArray);
        console.log("programs details", programDetails);
        console.log("active program", activeProgram);
      }
    } catch (err) {
      console.log("not able to update the program", err);
    }
  };
  return (
    <Layout>
      <div
        style={{
          maxHeight: "calc(100vh - 110px)",
          borderRadius: 20,
          maxWidth: "100vw",
        }}
      >
        {localUnsavedProgram && showContinueEditing && (
          <div className="flex w-full bg-red-300 mx-1 px-3 mb-1 rounded-md font-DMSans">
            Unsaved changes in &nbsp;
            <span className="font-semibold">
              {localUnsavedProgram?.location?.data?.result?.name}
            </span>
            &nbsp; program
            <div className="ml-auto gap-4 flex items-center">
              <button
                className="font-DMSans font-semibold"
                onClick={onResumeEditing}
              >
                Continue
              </button>
              <button
                className="font-DMSans font-semibold"
                onClick={() => {
                  setShowContinueEditing(false);
                  localStorage.removeItem("unsavedPrograms");
                }}
              >
                Discard
              </button>
            </div>
          </div>
        )}
        {loader && (
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100vh",
              left: 0,
              zIndex: 1,
              opacity: 0.8,
              alignSelf: "center",
              background: "#fff",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <SpinnerComponent />
            </div>
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            // justifyContent: "space-between",
            position: "relative",
          }}
        >
          {programs.length != 0 && isRightSideOpen ? (
            <div
              className="absolute top-1 left-[24.5%]  z-50 bg-white-pure cursor-pointer"
              style={{
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.1)",
              }}
              onClick={() => setIsRightSideOpen(!isRightSideOpen)}
            >
              <KeyboardDoubleArrowLeftRounded
                style={{
                  width: "20px",
                  height: "20px",
                }}
              />
            </div>
          ) : !(programs.length == 0) ? (
            <div
              className="absolute top-1 -left-2 z-50 bg-white-pure cursor-pointer"
              style={{
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.1)",
              }}
              onClick={() => setIsRightSideOpen(!isRightSideOpen)}
            >
              <KeyboardDoubleArrowRightRounded
                style={{
                  width: "20px",
                  height: "20px",
                }}
              />
            </div>
          ) : null}
          {programs.length > 0 ? (
            <>
              {/* <ProgramTable
                  programs={programs}
                  deleteProgram={deleteProgram}
                  duplicateProgram={duplicateProgram}
                  /> */}
              <div
                className="program-sidebar"
                style={{
                  borderTopLeftRadius: 20,
                  borderBottomLeftRadius: 20,
                  overflowY: "scroll",
                  maxHeight: "calc(100vh - 110px)",
                  minHeight: "calc(100vh - 110px)",
                  // minWidth: "25%",
                  // maxWidth: "25%",
                  width: "25%",
                  display: isRightSideOpen ? "block" : "none",
                  position: "relative",
                }}
              >
                {/* <div
                  className="add-program-fav-container"
                  onClick={() => {
                    if (unsavedChanges) {
                      // setOpenConfirmDialog(true);
                      triggerChildFunction();
                      handleConfirm();
                      setaddProgramConfirmation(true);
                      setShowModal(true);
                    } else {
                      setShowModal(true);
                    }
                  }}
                >
                  <WhitePlusIcon />
                </div> */}
                <div
                  className="channel-search__input__wrapper"
                  style={{
                    width: "100%",
                  }}
                >
                  <div className="channel-search__input__icon">
                    <SearchIcon />
                  </div>
                  <input
                    className="channel-search__input__text"
                    onChange={(e) => requestSearch(e.target.value)}
                    placeholder="Search"
                    type="text"
                    value={searched}
                  />
                </div>
                <div
                  style={{
                    height: "1px",
                    opacity: 0.2,
                    width: "100%",
                    background: "#000",
                  }}
                ></div>

                <ProgramsList
                  programs={programs}
                  loaderNew={loaderNew}
                  activeProgram={activeProgram}
                  row={row}
                  unsavedChanges={unsavedChanges}
                  setUnsavedChanges={setUnsavedChanges}
                  triggerChildFunction={triggerChildFunction}
                  getProgramDetails={getProgramDetails}
                  setPrograms={setPrograms}
                  adminID={adminID}
                  handleConfirm={handleConfirm}
                  setaddProgramConfirmation={setaddProgramConfirmation}
                  setShowModal={setShowModal}
                  value={value}
                  setValue={setValue}
                  subbordinateCoachPrograms={subbordinateCoachPrograms}
                  setShowContinueEditing={setShowContinueEditing}
                />
              </div>
              {loaderNew ? (
                <div
                  style={{
                    position: "fixed",
                    width: "100%",
                    height: "100vh",
                    top: "50%",
                    left: 0,
                    zIndex: 1,
                  }}
                >
                  {!loader && <SpinnerComponent />}
                </div>
              ) : (
                <CreatePrograms
                  key={programDetails?.data?.result?.id}
                  deleteProgram={deleteProgram}
                  duplicateProgram={duplicateProgram}
                  programDetails={programDetails}
                  setProgramDetails={setProgramDetails}
                  unsavedChanges={unsavedChanges}
                  setUnsavedChanges={setUnsavedChanges}
                  newProgram={newProgram}
                  setNewProgram={setNewProgram}
                  programs={programs}
                  setPrograms={setPrograms}
                  setActiveProgram={setActiveProgram}
                  getProgramDetails={getProgramDetails}
                  getProgramsData={getProgramsData}
                  ref={childRef}
                  row={row}
                  setRows={setRows}
                  workoutCreatedForPrograms={
                    location?.state?.workoutCreatedForThisProgram
                  }
                  workoutList={workouts}
                  setWorkoutList={setWorkouts}
                  createAsTemplate={value == 1}
                  isRightSideOpen={isRightSideOpen}
                  setShowUpdateInfoModal={setShowUpdateInfoModal}
                  setUpdateInfo={setUpdateInfo}
                />
              )}
            </>
          ) : (
            <div
              style={{
                minHeight: "calc(100vh - 110px)",
                minWidth: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "16px",
                background: "#fff",
                borderRadius: "20px",
              }}
              className="flex flex-col items-center justify-center"
            >
              <img
                src={EmptyProgramImg}
                className="w-[128px] h-[128px]"
                alt=""
              />
              <div
                style={{
                  textAlign: "center",
                }}
              >
                <p className="text-bold">No Programs Added</p>
                <p className="text-para">
                  There are no programs to display, click the below button to
                  add a program
                </p>
              </div>
              <button className="button-new" onClick={() => setShowModal(true)}>
                Add Program
              </button>
            </div>
          )}
        </div>
      </div>

      <Dialog
        open={showModal}
        PaperProps={{
          sx: {
            // padding: "24px",
            width: "320px",
            borderRadius: "20px",
          },
        }}
      >
        <DialogContent sx={{ padding: 0 }}>
          <div className="add-program-modal-head">
            <p className="add-program-modal-head-title">
              {value == 1 ? "Add Program Template" : "Add Program"}
            </p>
            <CloseIcon
              className="cursor-pointer"
              onClick={() => {
                setProgramName("");
                setShowModal(false);
                setProgramDesc("");
              }}
            />
          </div>
          <div className="add-program-modal-body">
            <div className="full-width-container">
              <label className="label-new">
                {value == 1 ? "Program Template Name*" : "Program Name*"}
              </label>
              <input
                autoFocus
                placeholder={
                  value == 1 ? "Program Template Name" : "Program Name"
                }
                value={programName}
                onChange={(e) => setProgramName(e.target.value)}
                className="inputfiled-new"
              />
            </div>
            <textarea
              id="programs description"
              className="textarea-new"
              placeholder="Description (Optional)"
              type="text"
              onChange={(e) => {
                setProgramDesc(e.target.value);
              }}
              rows={3}
            />
            <button
              // style={{ ...buttonStyle }}
              disabled={programName.length < 2}
              style={{ marginTop: "8px" }}
              className="button-new"
              onClick={() => {
                setLoaderNew(true);
                mixpanel.track("Trainer_NewProgram_Start");
                localStorage.removeItem("programid");

                setUnsavedChanges(true);
                const dataFromProgs = {
                  trainerUUID: trainerUUID,
                  name: programName,
                  description: programDesc,
                  duration: 7,
                  creationDate: new Date().toISOString(),
                  daysWorkouts: [],
                  workouts: [],
                  id: 999,
                  isTemplate: value == 1,
                };

                getProgramDetails(999);
                setProgramDetails({
                  data: {
                    result: {
                      id: 999,
                      coachID: trainerUUID,
                      workouts: [],
                      name: programName,
                      desc: programDesc,
                    },
                  },
                });
                setApiReceivedPrograms(programs);
                setPrograms((prev) => [dataFromProgs, ...prev]);
                setTempPrograms((prev) => [dataFromProgs, ...prev]);
                setProgramName("");
                setProgramDesc("");
                setShowAlert({
                  show: true,
                  message: "Programs created successfully",
                });
                setTimeout(() => {
                  setShowAlert({
                    show: false,
                    message: "",
                  });
                }, 3000);

                setShowModal(false);
                setLoaderNew(false);
                setNewProgram(true);
              }}
            >
              {value == 1 ? "Add Template" : "Add Program"}
            </button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={showUpdateInfoModal}
        PaperProps={{
          sx: {
            // padding: "24px",
            width: "320px",
            borderRadius: "20px",
          },
        }}
      >
        <DialogContent sx={{ padding: 0 }}>
          <div className="add-program-modal-head">
            <p className="add-program-modal-head-title">
              {value == 1 ? "Update Program Template" : "Update Program"}
            </p>
            <CloseIcon
              className="cursor-pointer"
              onClick={() => {
                setShowUpdateInfoModal(false);
              }}
            />
          </div>
          <div className="add-program-modal-body">
            <div className="full-width-container">
              <label className="label-new">
                {value == 1 ? "Program Template Name*" : "Program Name*"}
              </label>
              <input
                autoFocus
                placeholder={
                  value == 1 ? "Program Template Name" : "Program Name"
                }
                value={updateInfo?.name || ""}
                onChange={(e) => {
                  let newValue = e.target.value;
                  setUpdateInfo((prev) => ({
                    ...prev,
                    name: newValue,
                  }));
                }}
                className="inputfiled-new"
              />
            </div>
            <textarea
              id="programs description"
              className="textarea-new"
              placeholder="Description (Optional)"
              type="text"
              style={{
                color: "black",
              }}
              onChange={(e) => {
                let newValue = e.target.value;
                setUpdateInfo((prev) => ({
                  ...prev,
                  desc: newValue,
                }));
              }}
              value={updateInfo?.desc || ""}
              rows={3}
            />
            <button
              style={{ marginTop: "8px" }}
              className="button-new"
              onClick={() => {
                setLoaderNew(true);
                updateProgramInfo();
                setShowAlert({
                  show: true,
                  message: "Programs Updated successfully",
                });
                setTimeout(() => {
                  setShowAlert({
                    show: false,
                    message: "",
                  });
                }, 3000);

                setShowUpdateInfoModal(false);
                setLoaderNew(false);
              }}
            >
              {value == 1 ? "Update Template" : "Update Program"}
            </button>
          </div>
        </DialogContent>
      </Dialog>
      {showAlert.show && (
        <div
          style={{
            position: "absolute",
            zindex: 999,
            bottom: "5%",
            display: "flex",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <Alert severity="success">{showAlert.message}</Alert>
        </div>
      )}

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        zindex="999999"
      />
      <CustomModal
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        isOpen={openConfirmDialog}
        close={setOpenConfirmDialog}
      />
    </Layout>
  );
}

function CustomModal({ onConfirm, onCancel, isOpen, close }) {
  const handleOpen = () => {
    close(true);
  };

  const handleClose = (value) => {
    close(false);
    if (value === "no") {
      onCancel();
    } else {
      onConfirm();
    }
  };

  return (
    <Dialog open={isOpen}>
      <DialogTitle>Unsaved Changes</DialogTitle>
      <DialogContent>
        <p>
          You have unsaved changes. Are you sure you want to leave this page?
        </p>
      </DialogContent>
      <DialogActions>
        <button
          className="button-new"
          style={{
            width: 100,
          }}
          onClick={() => handleClose("yes")}
          color="primary"
        >
          Yes
        </button>
        <Button
          style={{ color: "#b21e42" }}
          onClick={() => handleClose("no")}
          color="primary"
        >
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}
