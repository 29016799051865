import { useState, useEffect } from "react";
import {
  getTodos,
  addTodo,
  updateTodo,
  deleteTodo,
} from "../../ApiServices/ClientProfileApi/Api";
import { useDispatch } from "react-redux";
import { setPendingTasks } from "../../redux/dataSlice";

const useTodosHook = ({ trainerUUID }) => {
  const [todos, setTodos] = useState([]);
  const [errorMessage, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const getTodosApi = async () => {
    setLoading(true);
    try {
      const result = await getTodos({ trainerUUID });
      // all the finished todos to the bottom and sort others by dateCreated
      result.sort((a, b) => {
        if (a.isFinished && !b.isFinished) return 1;
        if (!a.isFinished && b.isFinished) return -1;
        return new Date(b.dateCreated) - new Date(a.dateCreated);
      });

      setTodos(result);

      let unfinishedTodos = result.filter((todo) => !todo.isFinished);
      dispatch(setPendingTasks(unfinishedTodos.length));
    } catch (err) {
      console.log(err);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const getTodosWithoutLoading = async () => {
    try {
      const result = await getTodos({ trainerUUID });
      result.sort((a, b) => {
        if (a.isFinished && !b.isFinished) return 1;
        if (!a.isFinished && b.isFinished) return -1;
        return new Date(b.dateCreated) - new Date(a.dateCreated);
      });
      setTodos(result);

      let unfinishedTodos = result.filter((todo) => !todo.isFinished);
      dispatch(setPendingTasks(unfinishedTodos.length));
    } catch (err) {
      console.log(err);
      setError(true);
    }
  };

  const addTodoApi = async ({ todo }) => {
    setLoading(true);
    try {
      const response = await addTodo({ trainerUUID, todo });
      if (response) await getTodosApi();
    } catch (err) {
      console.log(err);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const editTodoApi = async ({ todo, todoId, isFinished }) => {
    setLoading(true);
    try {
      const response = await updateTodo({ todoID: todoId, todo, isFinished });
      if (response) await getTodosApi();
    } catch (err) {
      console.log(err);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const deleteTodoApi = async ({ todoId }) => {
    setLoading(true);
    try {
      const response = await deleteTodo({ todoId });
      if (response) await getTodosApi();
    } catch (err) {
      console.log(err);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const finishTodoApi = async ({ todoId, isFinished }) => {
    try {
      const response = await updateTodo({
        todoID: todoId,
        isFinished,
        dateFinished: isFinished ? new Date() : null,
      });
      if (response) await getTodosWithoutLoading();
    } catch (err) {
      console.log(err);
      setError(true);
    } finally {
    }
  };

  const finishAllTodosAPI = async () => {
    setLoading(true);
    try {
      await Promise.all(
        todos.map((todo) =>
          updateTodo({
            todoID: todo.id,
            isFinished: true,
            dateFinished: new Date(),
          })
        )
      );
      await getTodosApi();
    } catch (err) {
      console.log(err);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleTodoAction = ({ type, todo, todoId, isFinished }) => {
    switch (type) {
      case "get":
        getTodosApi();
        break;
      case "add":
        addTodoApi({ todo });
        break;
      case "edit":
        editTodoApi({ todo, todoId });
        break;
      case "delete":
        deleteTodoApi({ todoId });
        break;
      case "finish":
        finishTodoApi({ todoId, isFinished });
        break;
      case "finishAll":
        finishAllTodosAPI();
        break;
    }
  };

  useEffect(() => {
    getTodosApi();
  }, []);

  return [todos, errorMessage, loading, handleTodoAction];
};

export default useTodosHook;
