import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { APIURLLivezy } from "../APIDetails";
import axios from "axios";

const initialState = {
  copiedWorkout: null,
  copiedDay: null,
};

export const sendInvitationMail = createAsyncThunk(
  "user/sendInvitationMail",
  async (userData, thunkAPI) => {
    try {
      const response = await axios.post(
        `${APIURLLivezy}/sendExplicitOnBoardingEmailInvite`,
        userData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const clientsSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    copyWorkout: (state, action) => {
      state.copiedWorkout = action.payload;
    },
    clearCopiedWorkout: (state) => {
      state.copiedWorkout = null;
    },
    copyDay: (state, action) => {
      state.copiedDay = action.payload;
    },
    clearCopiedDay: (state) => {
      state.copiedDay = null;
    },
    setCoaches: (state, action) => {
      state.coaches = action.payload;
    },
  },
  extraReducers: (builder) => {
    // builder
    //   .addCase(fetchUserData.pending, (state) => {
    //   })
    //   .addCase(fetchUserData.fulfilled, (state, action) => {
    //     state.status = 'succeeded';
    //     state.user = action.payload;
    //   })
    //   .addCase(fetchUserData.rejected, (state, action) => {
    //     state.status = 'failed';
    //     state.error = action.payload;
    //   });
  },
});

export const {
  copyWorkout,
  clearCopiedWorkout,
  copyDay,
  clearCopiedDay,
  setCoaches,
} = clientsSlice.actions;

export default clientsSlice.reducer;
