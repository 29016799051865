import { store } from "../redux/store";

//Add training types here
export const TRAINING_TYPES = {
  CROSSFIT: "CROSSFIT",
  SANDC: "SANDC",
};

export const getTrainingType = () => {
  const trainingType = store.getState().auth.trainingType;
  return TRAINING_TYPES[trainingType];
};

export const getTrainerBasedValue = (data = {}) => {
  const trainingType = JSON.parse(localStorage.getItem("trainingType"));
  if (!(trainingType in data)) {
    // throw new Error("Invalid trainer type!");
  }
  return data[trainingType];
};

export const trainerBasedCall = (data = {}) => {
  const trainingType = JSON.parse(localStorage.getItem("trainingType"));
  if (!(trainingType in data)) {
    throw new Error("Invalid trainer type!");
  }
  if (typeof data[trainingType] !== "function") {
    throw new Error("Invalid call type! Call must be a function");
  }
  return data[trainingType]();
};
