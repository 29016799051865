import {
  CircularProgress,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";
import Container from "@mui/material/Container";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { CustomAlertContext } from "../../App";
import "./style.css";

import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputComponent, ShowError } from "../trainerSignup";

import { useSelector } from "react-redux";
import BlueEffect from "../../assets/login/BlueEffect.svg";
import VioletEffect from "../../assets/login/VioletEffect.svg";
import APIURL from "@/APIDetails";

// Define an array of phone number extensions
const phoneExtensions = [
  { country: "Afghanistan", extension: "+93" },
  { country: "Albania", extension: "+355" },
  { country: "Algeria", extension: "+213" },
  { country: "American Samoa", extension: "+1684" },
  { country: "Andorra", extension: "+376" },
  { country: "Angola", extension: "+244" },
  { country: "Anguilla", extension: "+1264" },
  { country: "Antigua and Barbuda", extension: "+1268" },
  { country: "Argentina", extension: "+54" },
  { country: "Armenia", extension: "+374" },
  { country: "Aruba", extension: "+297" },
  { country: "Australia", extension: "+61" },
  { country: "Austria", extension: "+43" },
  { country: "Azerbaijan", extension: "+994" },
  { country: "Bahamas", extension: "+1242" },
  { country: "Bahrain", extension: "+973" },
  { country: "Bangladesh", extension: "+880" },
  { country: "Barbados", extension: "+1246" },
  { country: "Belarus", extension: "+375" },
  { country: "Belgium", extension: "+32" },
  { country: "Belize", extension: "+501" },
  { country: "Benin", extension: "+229" },
  { country: "Bermuda", extension: "+1441" },
  { country: "Bhutan", extension: "+975" },
  { country: "Bolivia", extension: "+591" },
  { country: "Bosnia and Herzegovina", extension: "+387" },
  { country: "Botswana", extension: "+267" },
  { country: "Brazil", extension: "+55" },
  { country: "British Indian Ocean Territory", extension: "+246" },
  { country: "Brunei Darussalam", extension: "+673" },
  { country: "Bulgaria", extension: "+359" },
  { country: "Burkina Faso", extension: "+226" },
  { country: "Burundi", extension: "+257" },
  { country: "Cambodia", extension: "+855" },
  { country: "Cameroon", extension: "+237" },
  { country: "Canada", extension: "+1" },
  { country: "Cape Verde", extension: "+238" },
  { country: "Cayman Islands", extension: "+1345" },
  { country: "Central African Republic", extension: "+236" },
  { country: "Chad", extension: "+235" },
  { country: "Chile", extension: "+56" },
  { country: "China", extension: "+86" },
  { country: "Christmas Island", extension: "+61" },
  { country: "Cocos (Keeling) Islands", extension: "+61" },
  { country: "Colombia", extension: "+57" },
  { country: "Comoros", extension: "+269" },
  { country: "Congo", extension: "+242" },
  { country: "Congo, The Democratic Republic of the", extension: "+243" },
  { country: "Cook Islands", extension: "+682" },
  { country: "Costa Rica", extension: "+506" },
  { country: "Cote d'Ivoire", extension: "+225" },
  { country: "Croatia", extension: "+385" },
  { country: "Cuba", extension: "+53" },
  { country: "Cyprus", extension: "+357" },
  { country: "Czech Republic", extension: "+420" },
  { country: "Denmark", extension: "+45" },
  { country: "Djibouti", extension: "+253" },
  { country: "Dominica", extension: "+1767" },
  { country: "Dominican Republic", extension: "+1809" },
  { country: "Ecuador", extension: "+593" },
  { country: "Egypt", extension: "+20" },
  { country: "El Salvador", extension: "+503" },
  { country: "Equatorial Guinea", extension: "+240" },
  { country: "Eritrea", extension: "+291" },
  { country: "Estonia", extension: "+372" },
  { country: "Ethiopia", extension: "+251" },
  { country: "Falkland Islands (Malvinas)", extension: "+500" },
  { country: "Faroe Islands", extension: "+298" },
  { country: "Fiji", extension: "+679" },
  { country: "Finland", extension: "+358" },
  { country: "France", extension: "+33" },
  { country: "French Guiana", extension: "+594" },
  { country: "French Polynesia", extension: "+689" },
  { country: "Gabon", extension: "+241" },
  { country: "Gambia", extension: "+220" },
  { country: "Georgia", extension: "+995" },
  { country: "Germany", extension: "+49" },
  { country: "Ghana", extension: "+233" },
  { country: "Gibraltar", extension: "+350" },
  { country: "Greece", extension: "+30" },
  { country: "Greenland", extension: "+299" },
  { country: "Grenada", extension: "+1473" },
  { country: "Guadeloupe", extension: "+590" },
  { country: "Guam", extension: "+1671" },
  { country: "Guatemala", extension: "+502" },
  { country: "Guinea", extension: "+224" },
  { country: "Guinea-Bissau", extension: "+245" },
  { country: "Guyana", extension: "+592" },
  { country: "Haiti", extension: "+509" },
  { country: "Holy See (Vatican City State)", extension: "+379" },
  { country: "Honduras", extension: "+504" },
  { country: "Hong Kong", extension: "+852" },
  { country: "Hungary", extension: "+36" },
  { country: "Iceland", extension: "+354" },
  { country: "India", extension: "+91" },
  { country: "Indonesia", extension: "+62" },
  { country: "Iran, Islamic Republic of", extension: "+98" },
  { country: "Iraq", extension: "+964" },
  { country: "Ireland", extension: "+353" },
  { country: "Israel", extension: "+972" },
  { country: "Italy", extension: "+39" },
  { country: "Jamaica", extension: "+1876" },
  { country: "Japan", extension: "+81" },
  { country: "Jordan", extension: "+962" },
  { country: "Kazakhstan", extension: "+7" },
  { country: "Kenya", extension: "+254" },
  { country: "Kiribati", extension: "+686" },
  { country: "Korea, Democratic People's Republic of", extension: "+850" },
  { country: "Korea, Republic of", extension: "+82" },
  { country: "Kuwait", extension: "+965" },
  { country: "Kyrgyzstan", extension: "+996" },
  { country: "Lao People's Democratic Republic", extension: "+856" },
  { country: "Latvia", extension: "+371" },
  { country: "Lebanon", extension: "+961" },
  { country: "Lesotho", extension: "+266" },
  { country: "Liberia", extension: "+231" },
  { country: "Libyan Arab Jamahiriya", extension: "+218" },
  { country: "Liechtenstein", extension: "+423" },
  { country: "Lithuania", extension: "+370" },
  { country: "Luxembourg", extension: "+352" },
  { country: "Macao", extension: "+853" },
  { country: "Macedonia, The Former Yugoslav Republic of", extension: "+389" },
  { country: "Madagascar", extension: "+261" },
  { country: "Malawi", extension: "+265" },
  { country: "Malaysia", extension: "+60" },
  { country: "Maldives", extension: "+960" },
  { country: "Mali", extension: "+223" },
  { country: "Malta", extension: "+356" },
  { country: "Marshall Islands", extension: "+692" },
  { country: "Martinique", extension: "+596" },
  { country: "Mauritania", extension: "+222" },
  { country: "Mauritius", extension: "+230" },
  { country: "Mayotte", extension: "+262" },
  { country: "Mexico", extension: "+52" },
  { country: "Micronesia, Federated States of", extension: "+691" },
  { country: "Moldova, Republic of", extension: "+373" },
  { country: "Monaco", extension: "+377" },
  { country: "Mongolia", extension: "+976" },
  { country: "Montserrat", extension: "+1664" },
  { country: "Morocco", extension: "+212" },
  { country: "Mozambique", extension: "+258" },
  { country: "Myanmar", extension: "+95" },
  { country: "Namibia", extension: "+264" },
  { country: "Nauru", extension: "+674" },
  { country: "Nepal", extension: "+977" },
  { country: "Netherlands", extension: "+31" },
  { country: "Netherlands Antilles", extension: "+599" },
  { country: "New Caledonia", extension: "+687" },
  { country: "New Zealand", extension: "+64" },
  { country: "Nicaragua", extension: "+505" },
  { country: "Niger", extension: "+227" },
  { country: "Nigeria", extension: "+234" },
  { country: "Niue", extension: "+683" },
  { country: "Norfolk Island", extension: "+672" },
  { country: "Northern Mariana Islands", extension: "+1670" },
  { country: "Norway", extension: "+47" },
  { country: "Oman", extension: "+968" },
  { country: "Pakistan", extension: "+92" },
  { country: "Palau", extension: "+680" },
  { country: "Palestinian Territory, Occupied", extension: "+970" },
  { country: "Panama", extension: "+507" },
  { country: "Papua New Guinea", extension: "+675" },
  { country: "Paraguay", extension: "+595" },
  { country: "Peru", extension: "+51" },
  { country: "Philippines", extension: "+63" },
  { country: "Pitcairn", extension: "+64" },
  { country: "Poland", extension: "+48" },
  { country: "Portugal", extension: "+351" },
  { country: "Puerto Rico", extension: "+1787" },
  { country: "Qatar", extension: "+974" },
  { country: "Reunion", extension: "+262" },
  { country: "Romania", extension: "+40" },
  { country: "Russian Federation", extension: "+7" },
  { country: "Rwanda", extension: "+250" },
  { country: "Saint Helena", extension: "+290" },
  { country: "Saint Kitts and Nevis", extension: "+1869" },
  { country: "Saint Lucia", extension: "+1758" },
  { country: "Saint Pierre and Miquelon", extension: "+508" },
  { country: "Saint Vincent and the Grenadines", extension: "+1784" },
  { country: "Samoa", extension: "+685" },
  { country: "San Marino", extension: "+378" },
  { country: "Sao Tome and Principe", extension: "+239" },
  { country: "Saudi Arabia", extension: "+966" },
  { country: "Senegal", extension: "+221" },
  { country: "Serbia and Montenegro", extension: "+381" },
  { country: "Seychelles", extension: "+248" },
  { country: "Sierra Leone", extension: "+232" },
  { country: "Singapore", extension: "+65" },
  { country: "Slovakia", extension: "+421" },
  { country: "Slovenia", extension: "+386" },
  { country: "Solomon Islands", extension: "+677" },
  { country: "Somalia", extension: "+252" },
  { country: "South Africa", extension: "+27" },
  {
    country: "South Georgia and the South Sandwich Islands",
    extension: "+500",
  },
  { country: "Spain", extension: "+34" },
  { country: "Sri Lanka", extension: "+94" },
  { country: "Sudan", extension: "+249" },
  { country: "Suriname", extension: "+597" },
  { country: "Svalbard and Jan Mayen", extension: "+47" },
  { country: "Swaziland", extension: "+268" },
  { country: "Sweden", extension: "+46" },
  { country: "Switzerland", extension: "+41" },
  { country: "Syrian Arab Republic", extension: "+963" },
  { country: "Taiwan, Province of China", extension: "+886" },
  { country: "Tajikistan", extension: "+992" },
  { country: "Tanzania, United Republic of", extension: "+255" },
  { country: "Thailand", extension: "+66" },
  { country: "Timor-Leste", extension: "+670" },
  { country: "Togo", extension: "+228" },
  { country: "Tokelau", extension: "+690" },
  { country: "Tonga", extension: "+676" },
  { country: "Trinidad and Tobago", extension: "+1868" },
  { country: "Tunisia", extension: "+216" },
  { country: "Turkey", extension: "+90" },
  { country: "Turkmenistan", extension: "+993" },
  { country: "Turks and Caicos Islands", extension: "+1649" },
  { country: "Tuvalu", extension: "+688" },
  { country: "Uganda", extension: "+256" },
  { country: "Ukraine", extension: "+380" },
  { country: "United Arab Emirates", extension: "+971" },
  { country: "United Kingdom", extension: "+44" },
  { country: "United States", extension: "+1" },
  { country: "Uruguay", extension: "+598" },
  { country: "Uzbekistan", extension: "+998" },
  { country: "Vanuatu", extension: "+678" },
  { country: "Venezuela", extension: "+58" },
  { country: "Viet Nam", extension: "+84" },
  { country: "Virgin Islands, British", extension: "+1284" },
  { country: "Virgin Islands, U.S.", extension: "+1340" },
  { country: "Wallis and Futuna", extension: "+681" },
  { country: "Western Sahara", extension: "+212" },
  { country: "Yemen", extension: "+967" },
  { country: "Zambia", extension: "+260" },
  { country: "Zimbabwe", extension: "+263" },
];

const AddTrainee = () => {
  const [weightUnit, setWeightUnit] = useState("lbs");
  const [heightUnit, setHeightUnit] = useState("cms");

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const [step, setStep] = useState(1);

  const { displayAlert } = useContext(CustomAlertContext);

  const { id, userUUID, userEmail } = useParams();
  const trainerUUID = id;

  // get userEmail from query params
  const query = new URLSearchParams(window.location.search);
  const email = query.get("email");

  const history = useHistory();

  const [trainerInfo, setTrainerInfo] = useState({
    trainerUUID: "",
    name: "",
    email: "",
    image:
      "https://spurfitpublicimages.s3.us-east-2.amazonaws.com/TrainerContent/Coach.jpg",
  });
  const [phoneExtension, setPhoneExtension] = useState("+1");
  useEffect(() => {
    axios({
      method: "get",
      url: `${APIURL}CF/api/CoachWeb/Get/oneTrainerProfile`,
      params: {
        coachID: trainerUUID,
      },
    })
      .then((res) => {
        setTrainerInfo(res.data.result);
        localStorage.setItem(
          "localTrainerInfo",
          `${JSON.stringify(res.data.result[0])}`
        );
      })
      .catch((err) => {
        displayAlert(err);
      });
  }, []);

  const registerUser = async (data) => {
    console.log("in register trainee");
    if (data.password !== data.confirmPassword) {
      displayAlert("Password & confirm password doesn't matched");
      return false;
    }
    setLoading(true);
    // Remove the '+' sign from the contactNumber field
    const contactNumberWithoutPlus = (
      phoneExtension + data.contactNumber
    ).replace("+", "");
    console.log(contactNumberWithoutPlus);
    await axios({
      method: "post",
      url: `${APIURL}api/usersignup`,
      data: {
        userName: data.firstName + " " + data.lastName,
        name: data.firstName + " " + data.lastName,
        email: data.email,
        age: `${data.age}`,
        weight: data.weight + "-" + weightUnit,
        height:
          heightUnit === "in"
            ? data.feet + "'" + data.inches + "-" + heightUnit
            : data.height + "-" + heightUnit,
        gender: data?.gender,
        password: data.password,
        trainerUUID: trainerUUID,
        trainingType: trainerInfo.trainingType,
        contactNumber: contactNumberWithoutPlus,
        userUUID: userUUID ?? undefined,
      },
    })
      .then((res) => {
        console.log("res from addTrainee : " + res);
        if (res?.data?.CODE === "User is not invited.") {
          displayAlert(
            "Please use the email on which the invitation is sent or ask your coach to re-send the invitation on this email. Thanks."
          );
        } else {
          history.push(`/confirmation`);
        }
      })
      .catch((err) => {
        displayAlert(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: userEmail
        ? // replace - with . in email
          decodeURIComponent(userEmail).replaceAll(/-/g, ".")
        : email
        ? decodeURIComponent(email)
        : "",
      password: "",
      confirmPassword: "",
      age: "",
      weight: "",
      weightUnit: "lbs",
      height: "",
      heightUnit: "cms",
      feet: 0,
      inches: 0,
      gender: "",
      contactNumber: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      password: Yup.string()
        .required("Password is required")
        .min(6, "Password must be at least 6 characters")
        .max(25, "Password must be at most 16 characters"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm Password is required"),
      age: Yup.string().required("Age is required"),
      weight: Yup.string().required("Weight is required"),
      weightUnit: Yup.string().required("Weight Unit is required"),
      height:
        heightUnit === "in"
          ? Yup.string()
          : Yup.string().required("Height is required"),
      heightUnit: Yup.string().required("Height Unit is required"),
      feet: Yup.number().required("Feet is required"),
      inches: Yup.number().required("Inches is required"),
      gender: Yup.string().required("Gender is required"),
      contactNumber: Yup.string().required("Contact Number is required"),
    }),
    onSubmit: (values) => {
      console.log("values: ", values);
      registerUser(values);
    },
  });

  let step1Keys = ["firstName", "lastName", "email", "contactNumber"];
  let step2Keys = Object.keys(formik.values).slice(3, 5);
  let step3Keys = Object.keys(formik.values).slice(5, 8);
  let step4Keys = Object.keys(formik.values).slice(8, 10);

  const handleNextStep = () => {
    // Validate the current step's fields using Formik
    formik.validateForm().then((errors) => {
      let curStepKeys = [];
      if (step === 1) {
        curStepKeys = step1Keys;
      } else if (step === 2) {
        curStepKeys = step2Keys;
      } else if (step === 3) {
        curStepKeys = step3Keys;
      } else if (step === 4) {
        curStepKeys = step4Keys;
      }
      let hasError = false;
      curStepKeys.forEach((key) => {
        if (errors[key]) {
          hasError = true;
          // set error message for the field
          formik.setFieldError(key, errors[key]);
          formik.setFieldTouched(key, true);
        }
      });
      if (!hasError) {
        setStep(step + 1);
      } else {
        displayAlert({
          message: "Please fill all the required fields",
          type: "error",
        });
      }
    });
  };

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
      }}
      maxWidth="xl"
    >
      {trainerInfo && (
        <div
          style={{
            zIndex: 1,
            paddingBottom: "116px",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
          className="register-container"
        >
          <h3 className="welcome-msg ">Onboard the Fitness Train!</h3>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "8px",
            }}
          >
            <span
              className="font-DMSans text-font16 text-center self-center"
              style={{
                color: "#424242",
                fontWeight: 400,
              }}
            >
              Register here to train with {trainerInfo.name}
            </span>
          </div>
          <img src={trainerInfo.imageKey} className="trainer-img" />

          <div className="form-container">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              {step === 1 && (
                <Step1
                  formik={formik}
                  userEmail={userEmail}
                  email={email}
                  phoneExtension={phoneExtension}
                  setPhoneExtension={setPhoneExtension}
                />
              )}
              {step === 2 && (
                <Step2
                  formik={formik}
                  showPassword={showPassword}
                  setShowPassword={setShowPassword}
                  showConfirmPassword={showConfirmPassword}
                  setShowConfirmPassword={setShowConfirmPassword}
                />
              )}
              {step === 3 && (
                <Step3
                  formik={formik}
                  heightUnit={heightUnit}
                  setHeightUnit={setHeightUnit}
                  weightUnit={weightUnit}
                  setWeightUnit={setWeightUnit}
                />
              )}
            </div>
          </div>

          {/* previous and next button */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "16px",
              marginTop: "16px",
            }}
          >
            {step > 1 && (
              <button
                className="button-new-outlined"
                style={{
                  borderRadius: "30px",
                  width: "148px",
                  height: "43px",
                }}
                onClick={() => setStep(step - 1)}
              >
                {"Previous"}
              </button>
            )}
            {step < 3 && (
              <button className="button-new ml-auto" onClick={handleNextStep}>
                {"Next"}
              </button>
            )}
            {step === 3 && (
              <button className="button-new" onClick={formik.handleSubmit}>
                {loading ? (
                  <CircularProgress color="info" />
                ) : (
                  <span
                    style={{
                      fontSize: 14,
                    }}
                  >
                    {"Register"}
                  </span>
                )}
              </button>
            )}
          </div>
        </div>
      )}

      {/* <img
        className="hidden md:block"
        src={BlueEffect}
        style={{
          position: "absolute",
          opacity: 0.6,
          bottom: -50,
          left: "52px",
          zIndex: 0,
        }}
      />

      <img
        className="hidden md:block"
        src={VioletEffect}
        style={{
          position: "absolute",
          opacity: 0.6,
          top: -50,
          right: -159,
          zIndex: 0,
        }}
      /> */}
    </Container>
  );
};

export default AddTrainee;

const Step1 = ({
  formik,
  userEmail,
  email,
  phoneExtension,
  setPhoneExtension,
}) => {
  // take first name, last name and email as input
  return (
    <div className="space-y-2">
      <div
        style={{
          gap: 16,
          width: "100%",
        }}
        className="flex items-start"
      >
        <InputComponent
          inputStyle={{
            height: "48px",
          }}
          label="First Name"
          name="firstName"
          type="text"
          placeholder="John"
          containerStyle={{ width: "50%" }}
          {...formik.getFieldProps("firstName")}
          error={formik.touched.firstName && formik.errors.firstName}
          errorMessage={formik.errors.firstName}
        />

        <InputComponent
          inputStyle={{
            height: "48px",
          }}
          label="Last Name"
          name="lastName"
          type="text"
          placeholder="Doe"
          containerStyle={{ width: "50%" }}
          {...formik.getFieldProps("lastName")}
          error={formik.touched.lastName && formik.errors.lastName}
          errorMessage={formik.errors.lastName}
        />
      </div>

      <InputComponent
        inputStyle={{
          height: "48px",
        }}
        label="Email"
        name="email"
        type="email"
        placeholder="mike@spur.fit"
        {...formik.getFieldProps("email")}
        value={formik.values.email}
        error={formik.touched.email && formik.errors.email}
        errorMessage={formik.errors.email}
        disabled={userEmail || email ? true : false}
      />

      <div className="space-y-2">
        <div
          style={{
            fontSize: 14,
            color: "#424242",
            marginLeft: "12px",
          }}
          className="text-sm tracking-wide font-DMSans"
        >
          Contact
        </div>
        <div
          style={{
            width: "100%",
            border: "1px solid #CCC",
            borderRadius: "12px",
            backgroundColor: "#fff",
            overflow: "hidden",
          }}
          className="flex flex-row "
        >
          <Select
            style={{
              marginRight: "10px",
              marginLeft: "12px",
              height: "46px",
            }}
            SelectDisplayProps={{
              style: {
                backgroundColor: "#fff",
                color: "#828282",
              },
            }}
            IconComponent={ExpandMoreRoundedIcon}
            variant="standard"
            disableUnderline
            value={phoneExtension}
            onChange={(e) => setPhoneExtension(e.target.value)}
            name="phoneExtension"
          >
            <MenuItem
              sx={{
                fontFamily: "DM Sans",
                fontSize: "14px",
              }}
              disabled
              value={"select"}
            >
              Select Country
            </MenuItem>
            {phoneExtensions.map((option) => (
              <MenuItem
                sx={{
                  color: "#828282",
                  fontFamily: "DM Sans",
                  fontSize: "14px",
                }}
                key={option.extension}
                value={option.extension}
              >
                {`${option.country} ${option.extension}`}
              </MenuItem>
            ))}
          </Select>
          <input
            type="number"
            className="text-input"
            placeholder={"123-456-789"}
            onChange={formik.handleChange}
            name="contactNumber"
            min="0"
            style={{
              color: "black",
            }}
          />
        </div>
        <ShowError
          error={formik.touched.contactNumber && formik.errors.contactNumber}
          errorMessage={formik.errors.contactNumber}
        />
      </div>
    </div>
  );
};

const Step2 = ({
  formik,
  showPassword,
  setShowPassword,
  showConfirmPassword,
  setShowConfirmPassword,
}) => {
  return (
    <div className="space-y-2">
      <InputComponent
        inputStyle={{
          height: "48px",
        }}
        label="Password"
        name="password"
        type={showPassword ? "text" : "password"}
        placeholder="Password"
        {...formik.getFieldProps("password")}
        error={formik.touched.password && formik.errors.password}
        errorMessage={formik.errors.password}
        rightIcon={
          showPassword ? (
            <VisibilityOutlinedIcon onClick={() => setShowPassword(false)} />
          ) : (
            <VisibilityOffOutlinedIcon onClick={() => setShowPassword(true)} />
          )
        }
      />

      <InputComponent
        inputStyle={{
          height: "48px",
        }}
        label="Confirm Password"
        name="confirmPassword"
        type={showConfirmPassword ? "text" : "password"}
        placeholder="Confirm Password"
        {...formik.getFieldProps("confirmPassword")}
        error={formik.touched.confirmPassword && formik.errors.confirmPassword}
        errorMessage={formik.errors.confirmPassword}
        rightIcon={
          showConfirmPassword ? (
            <VisibilityOutlinedIcon
              onClick={() => setShowConfirmPassword(false)}
            />
          ) : (
            <VisibilityOffOutlinedIcon
              onClick={() => setShowConfirmPassword(true)}
            />
          )
        }
      />
    </div>
  );
};

const Step3 = ({
  formik,
  weightUnit,
  setWeightUnit,
  heightUnit,
  setHeightUnit,
}) => {
  return (
    <div className="space-y-2">
      <div
        style={{
          marginLeft: "12px",
          width: "100%",
        }}
      >
        <div
          style={{
            fontSize: 14,
            color: "#424242",
          }}
          className="text-sm tracking-wide font-DMSans"
        >
          Gender
        </div>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          onChange={formik.handleChange}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
          name="gender"
        >
          <FormControlLabel
            value="male"
            control={<Radio color="royalBlue" />}
            label="Male"
          />
          <FormControlLabel
            value="female"
            control={<Radio color="royalBlue" />}
            label="Female"
          />
          <FormControlLabel
            value="other"
            control={<Radio color="royalBlue" />}
            label="Other"
          />
        </RadioGroup>
        <ShowError
          error={formik.touched.gender && formik.errors.gender}
          errorMessage={formik.errors.gender}
        />
      </div>
      <InputComponent
        inputStyle={{
          height: "48px",
        }}
        label="Age"
        name="age"
        type="number"
        min="0"
        placeholder="Eg: 24"
        {...formik.getFieldProps("age")}
        error={formik.touched.age && formik.errors.age}
        errorMessage={formik.errors.age}
      />

      <div
        style={{
          gap: 16,
          width: "100%",
        }}
        className="flex items-start"
      >
        <div>
          <div
            style={{
              fontSize: 14,
              color: "#424242",
              marginLeft: "12px",
            }}
            className="text-sm tracking-wide font-DMSans"
          >
            Weight
          </div>
          <div
            style={{
              width: "100%",
              border: "1px solid #CCC",
              borderRadius: "12px",
              backgroundColor: "#fff",
              overflow: "hidden",
            }}
            className="flex flex-row "
          >
            <input
              type="number"
              className="text-input"
              placeholder={"110"}
              onChange={formik.handleChange}
              name="weight"
              min="0"
              style={{
                color: "black",
              }}
            />
            <Select
              style={{
                marginRight: "10px",
                height: "46px",
              }}
              SelectDisplayProps={{
                style: {
                  backgroundColor: "#fff",
                  color: "#828282",
                },
              }}
              IconComponent={ExpandMoreRoundedIcon}
              variant="standard"
              disableUnderline
              value={weightUnit}
              onChange={(e) => setWeightUnit(e.target.value)}
              name="weightUnit"
            >
              <MenuItem
                sx={{
                  color: "#828282",
                  fontFamily: "DM Sans",
                  fontSize: "14px",
                }}
                value="lbs"
              >
                lbs
              </MenuItem>
              <MenuItem
                sx={{
                  color: "#828282",
                  fontFamily: "DM Sans",
                  fontSize: "14px",
                }}
                value="kg"
              >
                kg
              </MenuItem>
            </Select>
          </div>
          <ShowError
            error={formik.touched.weight && formik.errors.weight}
            errorMessage={formik.errors.weight}
          />
        </div>

        <div className={"w-[60%] md:w-[50%]"}>
          <div
            style={{
              fontSize: 14,
              color: "#424242",
              marginLeft: "12px",
            }}
            className="text-sm tracking-wide font-DMSans"
          >
            Height
          </div>
          <div
            style={{
              width: "100%",
              border: "1px solid #CCC",
              borderRadius: "12px",
              backgroundColor: "#fff",
              overflow: "hidden",
            }}
            className="flex flex-row"
          >
            {heightUnit === "in" ? (
              <>
                <div className="relative flex items-baseline">
                  <input
                    type="number"
                    className="text-input"
                    placeholder={"6"}
                    onChange={formik.handleChange}
                    name="feet"
                    style={{
                      minWidth: "52px",
                      color: "black",
                    }}
                    min="0"
                  />
                  <span
                    style={{ color: "#424242" }}
                    className="md:ml-1 mt-[10px] font-DMSans"
                  >
                    ft
                  </span>
                </div>
                <div className="relative flex items-baseline ml-2">
                  <input
                    type="number"
                    className="text-input"
                    placeholder={"0"}
                    onChange={formik.handleChange}
                    name="inches"
                    style={{
                      minWidth: "60px",
                      color: "black",
                    }}
                    min="0"
                  />
                  <span
                    style={{ color: "#424242" }}
                    className="md:ml-1 mt-[10px] font-DMSans"
                  >
                    in
                  </span>
                </div>
              </>
            ) : (
              <input
                type="number"
                className="text-input"
                placeholder={"175"}
                onChange={formik.handleChange}
                name="height"
                min="0"
                style={{
                  color: "black",
                }}
              />
            )}
            <Select
              style={{
                marginRight: "10px",
                marginLeft: "10px",
                height: "46px",
              }}
              MenuProps={{
                style: { zIndex: "2147483000 !important" },
              }}
              SelectDisplayProps={{
                style: {
                  backgroundColor: "#fff",
                  color: "#828282",
                },
              }}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              IconComponent={ExpandMoreRoundedIcon}
              variant="standard"
              disableUnderline
              value={heightUnit}
              onChange={(e) => setHeightUnit(e.target.value)}
              name="heightUnit"
            >
              <MenuItem
                sx={{
                  color: "#828282",
                  fontFamily: "DM Sans",
                  fontSize: "14px",
                }}
                value="cms"
              >
                cms
              </MenuItem>
              <MenuItem
                sx={{
                  color: "#828282",
                  fontFamily: "DM Sans",
                  fontSize: "14px",
                }}
                value="in"
              >
                ft-in
              </MenuItem>
            </Select>
          </div>
          <ShowError
            error={formik.touched.height && formik.errors.height}
            errorMessage={formik.errors.height}
          />
        </div>
      </div>
    </div>
  );
};
