import React, { useContext, useEffect, useState } from "react";
import { NutritionContext } from "./NutritionContext";
import { Collapse } from "@mui/material";
import {
  CalendarDays,
  ClipboardPaste,
  CopyX,
  Folder,
  FolderOpen,
  MoreVertical,
} from "lucide-react";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import DropDownMenu from "@/features/Programs/DropDownMenu";
import { Salad } from "lucide-react";

function FolderListForMealPlan({ folders }) {
  const {
    setOpenFolderId,
    openFolderId,
    onFolderSelected,
    folderSelected,
    setdefaultNItem,
    defaultNItem,
    setFolderSelected,
    removeItemFromTheFolder,
    isShifting,
    setIsShifting,
    itemToShift,
    setItemToShift,
    transferItemBetWeenTheFolder,
    myMealPlans,
    templateMealPlans,
    selectedTabInMeaLPlan,
    adminID,
  } = useContext(NutritionContext);

   


  const [isDown, setIsDown] = useState(false);
  const handleToggleFolder = (folderId) => {
    setOpenFolderId((prevId) => (prevId === folderId ? null : folderId));
    setIsDown((prev) => !prev);
  };
 
  return (
    <>
      {folders?.map((folder) => {
        let isSelect = openFolderId === folder?.id || folderSelected?.id === folder?.id;

        return (
          <>
            <div
              key={folder.id}
              className={`flex flex-col py-1 px-2 cursor-pointer relative ${
                isSelect
                  ? "folder-card-container-selected "
                  : "folder-card-container-unselected "
              } 
              
              `}
              onClick={async (e) => {
                if (isShifting?.id) {
                  if (isShifting?.id !== folder?.id) {
                    const res = await transferItemBetWeenTheFolder(
                      isShifting,
                      folder,
                      itemToShift
                    );
                    if (res) {
                      setOpenFolderId(folder?.id);
                      setFolderSelected(folder);
                      setIsShifting({});
                      setItemToShift({});
                    }
                  } else {
                    setIsShifting({});
                  }

                  return;
                }
              handleToggleFolder(folder?.id);
                onFolderSelected(folder);
                
              }}
            >
              {isShifting?.id && isShifting?.id === folder?.id && (
                <div className="absolute top-0 right-0 z-50 bg-black-pure bg-opacity-50 p-1 rounded-md  h-full w-full flex items-center justify-center">
                  <p className="font-DMSans text-font14 text-[#fff]">
                    Click To Cancel Transfer
                  </p>
                  <button
                    className="absolute top-1 right-1 p-1 rounded-full bg-[#F2F2F2]"
                    onClick={() => setIsShifting({})}
                  >
                    {" "}
                    <CopyX />
                  </button>
                </div>
              )}
              <div
                className=" mx-1"
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  className={
                    isSelect
                      ? "channel-preview__wrapper_indicator__selected"
                      : "channel-preview__wrapper_indicator"
                  }
                  style={{
                    marginLeft: -7,
                  }}
                ></div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: 700,
                      color: "#8330e9",
                      // padding: "5px",
                      lineHeight: "10px",
                      fontFamily: "DM Sans",
                      // backgroundColor: "#ffffff",
                      // borderRadius: "10px",

                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    {openFolderId === folder?.id ? (
                      <ArrowDropDownRoundedIcon
                        sx={{
                          fontSize: "35px",
                        }}
                      />
                    ) : (
                      <PlayArrowRoundedIcon
                        sx={{
                          fontSize: "20px",
                        }}
                      />
                    )}
                  </span>
                  <div className="w-[30px] h-[40px] flex items-center justify-center  rounded-lg mx-2">
                    {openFolderId === folder?.id ? <FolderOpen /> : <Folder />}
                  </div>

                  <p className="font-DMSans text-font14 font-medium w-full break-words flex-wrap">
                    {folder?.name}
                  </p>
                </div>
                {isShifting?.id && isShifting?.id !== folder?.id && (
                  <ClipboardPaste />
                )}
                {adminID && adminID === folder?.coachID && (
                  <span
                    style={{
                      fontSize: "8px",
                      fontWeight: 700,
                      color: "#09A033",
                      padding: "5px",
                      lineHeight: "10px",
                      fontFamily: "DM Sans",
                      backgroundColor: "#F7F7F7",
                      borderRadius: "10px",
                      marginLeft: "5px",
                    }}
                  >
                    {"ADMIN"}
                  </span>
                )}
              </div>
              {openFolderId === folder?.id && folder?.mealPlans?.length > 0 && (
                <ExpandableCardForFolder
                  isFolderOpen={openFolderId}
                  totalFolders={folders}
                  folder={folder}
                  removeItemFromTheFolder={removeItemFromTheFolder}
                  setIsShifting={setIsShifting}
                  setItemToShift={setItemToShift}
                  setdefaultNItem={setdefaultNItem}
                  defaultNItem={defaultNItem}
                  adminID={adminID}
                />
              )}
            </div>
          </>
        );
      })}
    </>
  );
}

export default FolderListForMealPlan;

const ExpandableCardForFolder = ({
 
  isFolderOpen,
  removeItemFromTheFolder,
  setIsShifting,
  totalFolders,
  setItemToShift,
  setdefaultNItem,
  defaultNItem, 
  folder,
  adminID
}) => {
 
  return (
    <div className="expandable-card bg-white-pure w-full rounded-b-md  mb-4 pb-2 px-2">
       
      <Collapse
        in={isFolderOpen}
        timeout={500}
        sx={{
          transitionDuration: "500ms",
          transitionTimingFunction: "ease",
          scrollbarWidth: "0px",
          background: "white",
          borderRadius: "12px",
          width: "100%",
        }}
      >
        {folder?.mealPlans?.length > 0 &&
          folder?.mealPlans.map((item, index) => {
            let isSelect = defaultNItem?.id === item?.id;
            return (
              <div
                key={index}
                className={`flex px-2 py-1 gap-3 cursor-pointer   items-strech rounded-lg ${
                  index == 0 ? "mt-2" : "mt-1"
                }

              `}
                style={{
                  border: "1px solid #d0cdcd",
                  color: `${isSelect ? "white" : "black"}`,
                  background: `${isSelect ? "rgb(125 131 241)" : "white"}`,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setdefaultNItem(item);
                }}
              >
                <div className="w-[22px] h-[22px] flex items-center justify-center   rounded-lg">
                  <Salad />
                </div>

                <div className="flex-col w-full">
                  <p className="font-DMSans text-font14 font-medium break-words flex-wrap">
                    {item?.reference_name}
                  </p>
                </div>

                <div
                  className="ml-auto mr-1 items-center"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {adminID ? (
                    adminID === folder?.coachID ? (
                      <></>
                    ) : (
                      <DropDownMenu
                        className={"mr-24 w-21 cursor-pointer"}
                        triggerElement={<MoreVertical size={20} />}
                        options={
                          totalFolders?.length > 1
                            ? ["Shift To Another", `Remove From The Folder`]
                            : [`Remove From The Folder`]
                        }
                        onSelect={(option) => {
                          if (option === `Shift To Another`) {
                            setIsShifting(folder);
                            setItemToShift(item);
                          } else if (option === "Remove From The Folder") {
                            removeItemFromTheFolder(folder, item);
                          }
                        }}
                      />
                    )
                  ) : (
                    <DropDownMenu
                      className={"mr-24 w-21 cursor-pointer"}
                      triggerElement={<MoreVertical size={20} />}
                      options={
                        totalFolders?.length > 1
                          ? ["Shift To Another", `Remove From The Folder`]
                          : [`Remove From The Folder`]
                      }
                      onSelect={(option) => {
                        if (option === `Shift To Another`) {
                          setIsShifting(folder);
                          setItemToShift(item);
                        } else if (option === "Remove From The Folder") {
                          removeItemFromTheFolder(folder, item);
                        }
                      }}
                    />
                  )}
                </div>
              </div>
            );
          })}
      </Collapse>
    </div>
  );
};
