import { SpurfitCircularProgress } from "@/components/Dashboard/dashboard";
import { DataGrid } from "@mui/x-data-grid";
import { Checkbox } from "@mui/material";

import CircleCheckedFilled from "@mui/icons-material/CheckCircle";
import CircleUnchecked from "@mui/icons-material/RadioButtonUnchecked";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import React from "react";

const ShowPendingTable = ({ data, columns, loading, getRowId }) => {
  return (
    <DataGrid
      disableColumnMenu
      getRowId={getRowId}
      rows={data ?? []}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
          },
        },
      }}
      loading={loading}
      slots={{
        loadingOverlay: SpurfitCircularProgress,
        baseCheckbox: (props) => (
          <Checkbox
            icon={<CircleUnchecked />}
            checkedIcon={<CircleCheckedFilled />}
            indeterminateIcon={<RemoveCircleIcon />}
            {...props}
          />
        ),
      }}
      pageSizeOptions={[10]}
      checkboxSelection
      disableRowSelectionOnClick
      sx={{
        marginTop: "10px",
        minHeight: "100px",
        border: "none",
        ".MuiDataGrid-columnSeparator": {
          display: "none",
        },
        "&.MuiDataGrid-root": {
          border: "none",
        },
        "&.MuiDataGrid-columnHeaderCheckbox": {
          border: "none",
        },
      }}
    />
  );
};

export default ShowPendingTable;
