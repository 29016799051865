import { CloseRounded } from "@mui/icons-material";
import { Dialog, DialogActions, DialogContent, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useState, useEffect } from "react";
import Input from "../InputComponents/Input";
import { convertDateToYearMonthDayFormat } from "@/utils/functions";
import moment from "moment";

const dateStyles = {
  labelStyles: {
    color: "#424242",
    fontFamily: "DM Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px",
    textAlign: "left",
    paddingLeft: "12px",
    marginBottom: "8px",
  },
  datePickerStyles: {
    border: "none",
    outline: "none",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#ccc",
        borderRadius: "12px",
        outline: "none",
      },
      "&:hover fieldset": {
        borderColor: "#4339f2",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#4339f2",
        boxShadow: "0px 0px 0px 3px rgba(67, 57, 242, 0.25)",
      },
    },
  },
};

const AddClientUnavailability = ({
  isOpen,
  setIsOpen,
  onSaveUnavailability,
  assignedActivities,
}) => {
  const [unavailableDateAndReason, setUnavailableAndReason] = useState({
    startDate: moment(),
    endDate: moment(),
    reason: "",
  });

  const AddClientUnavailabilityFunc = () => {
    const data = {
      ...(unavailableDateAndReason?.reason
        ? {
            unavailableDateAndReason: {
              ...unavailableDateAndReason,
              startDate: convertDateToYearMonthDayFormat(
                unavailableDateAndReason.startDate
              ),
              endDate: convertDateToYearMonthDayFormat(
                unavailableDateAndReason.endDate
              ),
            },
          }
        : {}),
      ...(assignedActivities && Object.keys(assignedActivities).length
        ? assignedActivities
        : {}),
    };
    setIsOpen(false);
    let totalDays =
      moment(unavailableDateAndReason.endDate).diff(
        moment(unavailableDateAndReason.startDate),
        "days"
      ) + 1;
    onSaveUnavailability(data, totalDays);
  };

  useEffect(() => {
    return () => {
      setUnavailableAndReason({
        startDate: moment(),
        endDate: moment(),
        reason: "",
      });
    };
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        maxWidth="xs"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: "24px",
          },
        }}
      >
        <div className="add-program-modal-head">
          <div style={{ display: "flex", alignItems: "flex-end", gap: "8px" }}>
            <p className="add-program-modal-head-title">Mark Unavailability</p>
          </div>
          <div
            onClick={() => {
              setIsOpen(false);
            }}
            className="cursor-pointer"
          >
            <CloseRounded
              sx={{
                fontSize: "24px",
                color: "#000",
                cursor: "pointer",
              }}
            />
          </div>
        </div>
        <DialogContent>
          <Input
            label={"Reason*"}
            placeholder={"Enter Reason"}
            value={unavailableDateAndReason?.reason}
            onChange={(e) =>
              setUnavailableAndReason({
                ...unavailableDateAndReason,
                reason: e.target.value,
              })
            }
          />

          <div className="flex flex-col mt-2">
            <label style={dateStyles.labelStyles}>Start Date*</label>
            <DatePicker
              sx={dateStyles.datePickerStyles}
              value={unavailableDateAndReason?.startDate}
              onChange={(newValue) =>
                setUnavailableAndReason({
                  ...unavailableDateAndReason,
                  startDate: newValue,
                })
              }
              renderInput={(params) => (
                <TextField variant="standard" {...params} />
              )}
            />
          </div>
          <div className="flex flex-col mt-2">
            <label style={dateStyles.labelStyles}>End Date*</label>
            <DatePicker
              sx={dateStyles.datePickerStyles}
              value={unavailableDateAndReason?.endDate}
              onChange={(newValue) =>
                setUnavailableAndReason({
                  ...unavailableDateAndReason,
                  endDate: newValue,
                })
              }
              renderInput={(params) => (
                <TextField variant="standard" {...params} />
              )}
            />
          </div>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
          }}
        >
          <button
            disabled={!unavailableDateAndReason.reason}
            className="button-new"
            onClick={AddClientUnavailabilityFunc}
          >
            Add
          </button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};

export default AddClientUnavailability;
