import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button } from "@mui/material";
import axios from "axios";
import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CustomAlertContext } from "../../App";
import SpinnerComponent from "../../components/layout/spinner";
import LogoIcon from "../../svg/spurfitLogo.svg";

const ChangePassword = ({}) => {
  const APIURL = useSelector((state) => state.auth.apiURL);
  const buttonStyle = {
    color: "#ffffff",
    borderRadius: "10px",
    backgroundColor: "#FF9800",
    padding: "7px 30px",
    width: "100%",
    padding: 10,
  };
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const { displayAlert } = useContext(CustomAlertContext);

  const saveNewPassowrd = async () => {
    setLoader(true);
    displayAlert(email);
    await axios({
      method: "post",
      url: `${APIURL}api/forgetpassword`,
      data: {
        email: email,
      },
    })
      .then(async (res) => {
        setLoader(false);
        setSuccess(true);
      })
      .catch((err) => {
        displayAlert("Invalid email or old password");
        setLoader(false);
      });
  };

  return (
    <>
      <div class="flex">
        <div
          class="flex col"
          style={{
            justifyContent: "center",
            width: "100%",
            height: "100vh",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              height: 100,
              width: 100,
              padding: 10,
              borderWidth: 3,
              borderRadius: 80,
            }}
            className="flex items-center justify-center border-blue-dark"
          >
            <img
              src={LogoIcon}
              style={{
                width: 80,
                height: 45.55,
                marginTop: 5,
                marginBottom: 5,
              }}
              alt="logo"
            />
          </div>
          <h1 class="mb-5" style={{ fontSize: 25 }}>
            Get Pasword Reset Link{" "}
          </h1>
          <div
            style={{
              width: "500px",
              background: "#F7F7F7",
              justifyContent: "center",
              padding: "1.5em",
              borderRadius: 5,
            }}
          >
            <form>
              <div class="row mb-5">
                <div class="col">
                  <label>Email</label>
                  <input
                    type="text"
                    class="form-control"
                    style={{
                      width: "100%",
                      height: "50px",
                      border: "1px solid #ccc",
                      borderRadius: 8,
                      padding: "5px",
                    }}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>

              <div class="row mb-5">
                <div class="col">
                  {!loader && (
                    <Button
                      style={{ ...buttonStyle }}
                      onClick={() => saveNewPassowrd()}
                    >
                      Send
                    </Button>
                  )}

                  {error && (
                    <p style={{ textAlign: "center", marginTop: 10 }}>
                      {error}
                    </p>
                  )}

                  {success && (
                    <p style={{ textAlign: "center", marginTop: 10 }}>
                      Please check your email for password reset link
                    </p>
                  )}

                  {loader && <SpinnerComponent />}
                </div>
              </div>
              <div
                className="flex items-center justify-center"
                style={{ cursor: "pointer" }}
                onClick={() => history.goBack()}
              >
                <ArrowBackIcon /> &nbsp;&nbsp;
                <span className="underline text-blue-dark">Go Back</span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
