import { CloseRounded } from "@mui/icons-material";
import { Box, Dialog, DialogActions, DialogContent } from "@mui/material";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getAllWorkouts } from "../../ApiServices/Workouts/Api";
import { CustomAlertContext } from "../../App";
import WorkoutInfoDialog from "../../components/WorkoutComponent/WorkoutInfoDialog";
import SpinnerComponent from "../../components/layout/spinner";
import {
  createProgramsStates,
  removeProgramsStates,
} from "../../redux/programsSlice";
import { SearchIcon } from "@/pages/getStreamChat/assets";
import DragIcon from "@/pages/library/assests/DragIcon";
import PlusIcon from "@/pages/library/assests/PlusIcon";
import { createWorkoutWithID } from "@/pages/library/programsCF/helpers";
import { ProgramsContext } from "./ProgramsContext";
import AddNewWorkoutModal from "./utils/AddNewWorkoutModal";

export const AddWorkoutsModal = forwardRef(
  (
    {
      programName,
      programId,
      programsStates,
      location, //this is not actual location it is a variable containing data
      dataToSave,
      orignialWeeks,
      selectedDay,
      ...props
    },
    ref
  ) => {
    const isLivezy = useSelector((state) => state.auth.isLivezy);
    const [workoutOuts, setWorkoutOuts] = useState("");
    const [searched, setSearched] = React.useState("");
    const [tempData, setTempData] = useState("");
    const [checkedItems, setCheckedItems] = useState([]);
    const [openAddWorkoutModal, setopenAddWorkoutModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [newWeek, setNewWeek] = useState(false);
    const [canRemovePreviousWorkouts, setCanRemovePreviousWorkouts] =
      useState(true);
    const [previousWorkoutsLastIndex, setPreviousWorkoutsLastIndex] =
      useState(0);

    const {
      showAddWorkoutModal,
      addedWorkouts,
      selectedCard,
      setShowAddWorkoutModal,
      setAddedWorkouts,
      setSelectedCard,
      workoutList,
      setWorkoutList,
      setUnsavedChanges,
      programSubscribers,
      originalDays,
    } = useContext(ProgramsContext);

    useEffect(() => {
      setNewWeek(isNewWeek());

      return () => {
        setNewWeek(false);
      };
    }, [selectedDay, orignialWeeks]);

    const isNewWeek = () => {
      if (orignialWeeks === -1) return true;
      let weekNo = Math.ceil(selectedDay / 7);
      return weekNo > orignialWeeks;
    };

    const coachID = useSelector((state) => state.auth.trainerUUID);
    const adminID = useSelector((state) => state.auth?.data?.adminID);

    const history = useHistory();

    const addWorkoutToCheckList = (workout) => {
      setCheckedItems((prev) => {
        return [...prev, workout];
      });
    };

    useImperativeHandle(ref, () => ({
      addWorkoutToCheckList,
    }));

    const init = async () => {
      if (isLivezy) return;
      setLoading(true);

      if (programSubscribers?.length > 0 && originalDays >= selectedCard) {
        setCanRemovePreviousWorkouts(false);
      }

      let allItems = addedWorkouts?.[selectedCard] || [];
      let filterRest = allItems?.filter((item) => !item.rest);
      setPreviousWorkoutsLastIndex(filterRest?.length || 0);
      setCheckedItems(filterRest ?? []);

      if (workoutList?.length > 0) {
        setWorkoutOuts(workoutList);
        setTempData(workoutList);
      } else {
        let allWorkouts = await getAllWorkouts(coachID, adminID);
        let sortedData = allWorkouts.sort(function (a, b) {
          var dateA = new Date(a.dateCreated);
          var dateB = new Date(b.dateCreated);
          if (dateA.getTime() === dateB.getTime()) {
            // Sort by name if dates are equal
            return a.name.localeCompare(b.name);
          }
          return dateB - dateA;
        });

        setWorkoutOuts(sortedData);
        setTempData(sortedData);
        setWorkoutList(sortedData);
      }
      let items = [];
      addedWorkouts?.[selectedCard] &&
        Object.entries(addedWorkouts?.[selectedCard])?.map((workout) => {
          if (!workout[1].rest) {
            items.push(workout[1]);
          }
        });
      let newWorkoutInStorage = localStorage
        ?.getItem("createdWorkout")
        ?.split("@");
      if (newWorkoutInStorage && newWorkoutInStorage[0] == selectedCard) {
        items.push(JSON.parse(newWorkoutInStorage[1]));
        localStorage.removeItem("createdWorkout");
      }
      setCheckedItems(items);
      setAddedWorkouts({
        ...addedWorkouts,
        [selectedCard]: items,
      });

      console.log("CheckedItems: ", checkedItems);

      if (!Array.isArray(addedWorkouts?.[`${selectedCard}`])) {
        setAddedWorkouts({ ...addedWorkouts, [selectedCard]: [] });
      }
      localStorage.setItem("workoutDay", selectedCard);

      // let newworkoutadded = localStorage.getItem("newworkout")
      // console.log(newworkoutadded)
      if (programsStates?.workoutDay == selectedCard) {
        setCheckedItems(programsStates?.checkedItems);
        let addedWorkouts = programsStates?.addedWorkouts;
        setAddedWorkouts({
          ...addedWorkouts,
          [selectedCard]: programsStates?.checkedItems,
        });
        dispatch(removeProgramsStates());
      }
      setLoading(false);
    };

    useEffect(() => {
      init();
    }, [selectedCard]);

    const requestSearch = (searchedVal) => {
      setSearched(searchedVal);
      const filteredRows = tempData.filter((row) => {
        if (!row.name) return false;
        return row.name.toLowerCase().includes(searchedVal.toLowerCase());
      });
      setWorkoutOuts(filteredRows);
      //  setRowsPerPage(filteredRows);
    };

    const cancelSearch = () => {
      setSearched("");
      setWorkoutOuts(tempData);
    };

    function handleDragEnd(result) {
      // Check if the drag was successful
      if (!result.destination) {
        return;
      }

      // Get the source and destination indexes
      const sourceIndex = result.source.index;
      const destinationIndex = result.destination.index;

      // Reorder the workouts in the addedWorkouts object
      const newAddedWorkouts = { ...addedWorkouts };
      const draggedWorkout = newAddedWorkouts[selectedCard][sourceIndex];
      newAddedWorkouts[selectedCard].splice(sourceIndex, 1);
      newAddedWorkouts[selectedCard].splice(
        destinationIndex,
        0,
        draggedWorkout
      );

      // Update the state with the new addedWorkouts object
      setAddedWorkouts(newAddedWorkouts);
    }

    console.log({ addedWorkouts: addedWorkouts?.[selectedCard] });

    const deleteSelectedWorkout = (item) => {
      const filteredIitems = checkedItems.filter((i) => i.id !== item.id);
      setCheckedItems(filteredIitems);
    };

    const dispatch = useDispatch();
    const { displayAlert } = useContext(CustomAlertContext);

    const Save = () => {
      setAddedWorkouts({
        ...addedWorkouts,
        [selectedCard]: checkedItems,
      });

      setUnsavedChanges(true);

      setShowAddWorkoutModal(false);
      setSelectedCard("");
      localStorage.removeItem("newworkout");
      localStorage.removeItem("cardSelected");
      cancelSearch();
    };

    function uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(12)
      );
    }

    return (
      <Dialog
        open={showAddWorkoutModal}
        onClose={() => {
          setShowAddWorkoutModal(false);
        }}
        sx={{
          backdropFilter: "blur(5px)",
        }}
        PaperProps={{
          sx: {
            //   maxHeight: "90vh",
            borderRadius: "20px",
            display: "flex",
            minWidth: "60%",
          },
        }}
      >
        {/* {openAddWorkoutModal && (
        <WorkoutInfoDialog
          isOpen={openAddWorkoutModal}
          setIsOpen={setopenAddWorkoutModal}
          isProgramsFlow={true}
        />
      )} */}
        {openAddWorkoutModal && (
          <AddNewWorkoutModal
            isOpen={openAddWorkoutModal}
            setIsOpen={setopenAddWorkoutModal}
            isProgramsFlow={true}
            setShowWorkoutBuilderModal={props?.setShowWorkoutBuilderModal}
            setWorkoutInfo={props?.setWorkoutInfo}
            setIsFreshWorkout={props?.setIsFreshWorkout}
            setCheckedItems={setCheckedItems}
          />
        )}
        <DialogContent sx={{ padding: 0, overflow: "hidden" }}>
          <div className="add-program-modal-head">
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <p className="add-program-modal-head-title">Select Workouts</p>
              {Object.entries(addedWorkouts?.[selectedCard] || []).length >
                0 && (
                <p className="sub-text">
                  {programName} - Day {selectedCard}
                </p>
              )}
            </div>
            <div
              onClick={() => {
                setShowAddWorkoutModal(false);
              }}
              className="cursor-pointer"
            >
              <CloseRounded
                sx={{
                  fontSize: "24px",
                  color: "#000",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
          <div className="add-program-modal-body">
            <div
              className="full-width-container"
              style={{
                paddingBottom: "20px",
              }}
            >
              <div
                className="channel-search__input__wrapper"
                style={{
                  width: "100%",
                }}
              >
                <div className="channel-search__input__icon">
                  <SearchIcon />
                </div>
                <input
                  className="channel-search__input__text"
                  onChange={(e) => requestSearch(e.target.value)}
                  placeholder="Search"
                  type="text"
                  value={searched}
                />
              </div>
              {loading ? (
                <Box>
                  <SpinnerComponent />
                </Box>
              ) : (
                <Box
                  style={{
                    display: "flex",
                    gap: "8px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "50%",
                    }}
                  >
                    <Box
                      className="workout-list-container"
                      sx={{
                        overflowY: "scroll",
                        maxHeight: "340px",
                        width: "100%",
                      }}
                    >
                      {workoutOuts &&
                        [
                          // ...checkedItems,
                          ...workoutOuts?.filter(
                            (item) =>
                              !checkedItems.some(
                                (checkedItem) => checkedItem.id === item.id
                              )
                          ),
                        ].map((item, index) => {
                          if (item?.name == "cardio") return;
                          let isSelected = checkedItems.some(
                            (checkedItem) => checkedItem.id === item.id
                          );
                          let exerciseCount = 0;
                          item?.workoutContent?.rounds?.forEach((r) => {
                            let round = r?.round;
                            exerciseCount += round?.measurements?.length;
                          });

                          return (
                            <div
                              className={`${
                                isSelected
                                  ? "select-workouts_item-container-selected"
                                  : "select-workouts_item-container"
                              } cursor-pointer`}
                              key={index}
                              onClick={() => {
                                if (isSelected) {
                                  setCheckedItems(
                                    checkedItems.filter((i) => i.id !== item.id)
                                  );
                                } else {
                                  let newID = uuidv4();
                                  createWorkoutWithID(item, newID);
                                  let newItem = {
                                    ...item,
                                    id: newID,
                                  };
                                  setCheckedItems((prevState) => {
                                    return [...prevState, newItem];
                                  });

                                  console.log("checkedItems ", checkedItems, [
                                    ...checkedItems,
                                    newItem,
                                  ]);
                                }
                              }}
                            >
                              {isSelected ? (
                                <div
                                // onClick={() => {
                                //   setCheckedItems(
                                //     checkedItems.filter((i) => i.id !== item.id)
                                //   );
                                // }}
                                >
                                  <CheckBoxIcon sx={{ color: "#6241ea" }} />
                                </div>
                              ) : (
                                <div
                                // onClick={() => {
                                //   setCheckedItems((prevState) => {
                                //     return [...prevState, item];
                                //   });

                                //   console.log("checkedItems ", checkedItems, [
                                //     ...checkedItems,
                                //     item,
                                //   ]);
                                //   setAddedWorkouts({
                                //     ...addedWorkouts,
                                //     [selectedCard]: checkedItems,
                                //   });
                                // }}
                                >
                                  <CheckBoxOutlineBlankIcon />
                                </div>
                              )}
                              <div className="select-workouts_item-text">
                                <span className="select-workouts_item-title">
                                  {item.name} &nbsp;
                                </span>
                                <span className="select-workouts_item-description">
                                  {exerciseCount} Exercises
                                </span>
                              </div>
                            </div>
                          );
                        })}
                    </Box>
                    <div
                      style={{
                        background: "#F2F2F2",
                        borderRadius: "12px",
                        display: "flex",
                        gap: "8px",
                        flexDirection: "column",
                        padding: "10px 0",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <p
                        style={{
                          fontFamily: "DM Sans",
                          fontSize: 14,
                          fontWeight: 500,
                        }}
                      >
                        Couldn't find the workout?
                      </p>
                      <button
                        onClick={() => {
                          // localStorage.setItem("cardSelected", selectedCard)
                          // localStorage.setItem("programid", programId)
                          dispatch(
                            createProgramsStates({
                              started: true,
                              finished: false,
                              workoutDay: selectedCard,
                              programId: programId,
                              programName: programName,
                              addedWorkouts: addedWorkouts,
                              checkedItems: checkedItems,
                              location: location,
                              ...dataToSave,
                            })
                          );

                          setopenAddWorkoutModal(true);
                          setUnsavedChanges(false);
                        }}
                        style={{
                          width: "max-content",
                          margin: "auto",
                        }}
                        className="button-new-outlined-small"
                      >
                        <PlusIcon /> Add Workout
                      </button>
                    </div>
                  </div>
                  <div style={{ width: "1px", background: "grey" }}></div>
                  <div
                    style={{
                      width: "45%",
                      maxHeight: "400px",
                      overflowY: "scroll",
                      overflowX: "hidden",
                      paddingRight: "8px",
                    }}
                  >
                    <DragDropContext onDragEnd={handleDragEnd}>
                      <Droppable droppableId="card-list">
                        {(provided) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={{
                              fontSize: "14px",
                              display: "flex",
                              flexDirection: "column",
                              gap: "8px",
                            }}
                          >
                            {(checkedItems || [])?.map((workout, idx) => {
                              if (workout?.name == "cardio") return;
                              let exerciseCount = 0;
                              workout?.workoutContent?.rounds?.forEach((r) => {
                                let round = r?.round;
                                exerciseCount += round?.measurements?.length;
                              });

                              return (
                                <Draggable
                                  key={workout[0]}
                                  draggableId={workout.id}
                                  index={idx}
                                >
                                  {(provided) => (
                                    <div
                                      {...provided.draggableProps}
                                      className={
                                        "selected-workouts_item-container"
                                      }
                                      style={{
                                        alignItems: "center",
                                      }}
                                      // {...provided.dragHandleProps}
                                      ref={provided.innerRef}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "12px",
                                          minWidth: "95%",
                                          alignItems: "center",
                                        }}
                                      >
                                        <div style={{}}>
                                          <DragIcon />
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                          }}
                                        >
                                          <p className="select-workouts_item-title">
                                            {workout?.name}
                                          </p>
                                          <p className="select-workouts_item-description">
                                            {exerciseCount} Exercises
                                          </p>
                                        </div>
                                      </div>
                                      {((!canRemovePreviousWorkouts &&
                                        idx >= previousWorkoutsLastIndex) ||
                                        canRemovePreviousWorkouts) && (
                                        <div
                                          onClick={() => {
                                            deleteSelectedWorkout(workout);
                                            setCheckedItems(
                                              checkedItems.filter(
                                                (i) => i.id !== workout.id
                                              )
                                            );
                                          }}
                                          className="cursor-pointer"
                                        >
                                          <CloseRounded
                                            sx={{
                                              fontSize: "24px",
                                              color: "#000",
                                              cursor: "pointer",
                                            }}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}
                            {addedWorkouts.length > 10 && (
                              <div
                                style={{ width: "100%" }}
                                className="text-center"
                              >
                                more...
                              </div>
                            )}

                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                </Box>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", paddingBottom: "24px" }}>
          <button
            className="button-new"
            onClick={() => {
              Save();
            }}
            // disabled={checkedItems.length == 0}
          >
            Save
          </button>
        </DialogActions>
      </Dialog>
    );
  }
);
